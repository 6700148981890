import { TableLayoutHeader } from '../../../../../constants';
import { ComplaintModel } from '../models';
import { Management } from '../Management';
import { TextBox } from '../../../../../components/forms/TextBox';
import { CheckBox } from '../../../../../components/forms/CheckBox';

// 불만내용
const Complaint: TableLayoutHeader<ComplaintModel, Management>[] = [
  {
    id: 'disconcd',
    text: '코드',
    width: 50,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.disconcd}
        onChange={(value) => rowUpdate({ disconcd: value })}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'disconnm',
    text: '불만내용',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.disconnm}
        onChange={(value) => rowUpdate({ disconnm: value })}
      />
    ),
  },
  {
    id: 'useyn',
    text: '사용유무',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.useyn === '1'}
        onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
];

export default Complaint;
