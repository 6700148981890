import { computed } from 'mobx';

/**
 * 경비사용내역 모델
 * @window w_tb_da003_01w_view
 * @category 프로젝트현황
 */
export class ExpenseModel {
  /**
   * 거래처코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** char(1)
   */
  state: string;

  /**
   * 프로젝트일자
   *
   * **data type** char(8)
   */
  prodate: string;

  /**
   * 수주금액
   *
   * **data type** number
   */
  camt: string;

  /**
   * 예상지출금액
   *
   * **data type** number
   */
  ex_mijamt: string;

  /**
   * 발주금액
   *
   * **data type** number
   */
  balamt: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 소요경비
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 구분
   *
   * **data type** varchar(5)
   */
  gubun: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 매입일자
   *
   * **data type** char(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** char(4)
   */
  mijnum: string;

  /**
   * 순번
   *
   * **data type** char(3)
   */
  seq: string;

  /**
   * 계정과목
   *
   * **data type** char(13)
   */
  actcd: string;

  /**
   * 지급처명
   *
   * **data type** char(13)
   */
  mijcltcd: string;

  /**
   * 지급처명
   *
   * **data type** char(100)
   */
  mijcltnm: string;

  /**
   * 품목코드
   *
   * **data type** char(20)
   */
  pcode: string;

  /**
   * 품목명
   *
   * **data type** char(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** char(100)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** char(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 매입가액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 년
   *
   * **data type** string
   */
  styyyy: string;

  /**
   * 월
   *
   * **data type** string
   */
  stmm: string;

  /**
   * 년
   *
   * **data type** string
   */
  endyyyy: string;

  /**
   * 월
   *
   * **data type** string
   */
  endmm: string;

  /**
   * 프로젝트현장코드
   *
   * **data type** varchar(13)
   */
  proactcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 계정과목
   *
   * **data type** varchar(13)
   */
  acccd: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  actgubun: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  projno2: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  appnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.state = data.state || '';
    this.prodate = data.prodate || '';
    this.appnum = data.appnum || '';
    this.projno2 = data.projno2 || '';
    this.actgubun = data.actgubun || '';
    this.camt = data.camt || '';
    this.ex_mijamt = data.ex_mijamt || '';
    this.balamt = data.balamt || '';
    this.misamt = data.misamt || '';
    this.mijamt = data.mijamt || '';
    this.spjangcd = data.spjangcd || '';
    this.gubun = data.gubun || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.seq = data.seq || '';
    this.actcd = data.actcd || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.styyyy = data.styyyy || '';
    this.stmm = data.stmm || '';
    this.endyyyy = data.endyyyy || '';
    this.endmm = data.endmm || '';
    this.proactcd = data.proactcd || '';
    this.cltcd = data.cltcd || '';
    this.acccd = data.acccd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
