import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { MonthProjectTemplate } from './MonthProject.template';
import { MonthProjectModel } from './MonthProject.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils/layout';

interface MonthProjectState {
  searchQuery: string;
  yearmon: string;
  data: Array<MonthProjectModel>;
  focused?: MonthProjectModel;
}

/**
 * 컨트롤러
 * @window  w_tb_monend_incentive_notuseamt
 * @category 월프로젝트이익금체크
 */
export class MonthProject extends PageComponent<PageProps, MonthProjectState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MonthProjectModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['rcvdate', 'projno'],
        [this.state.focused?.rcvdate, this.state.focused?.projno], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  onRowFocusEvent(item: MonthProjectModel) {
    this.setState({ focused: item });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.yearmon,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      as_nm: this.state.searchQuery,
    });
  }

  render() {
    return (
      <MonthProjectTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
