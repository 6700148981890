import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  BankModel,
  MainModel,
  DetailModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout, TableLayout } from '../../../../components';
import { BankStatementTemplate } from './BankStatement.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderBank,
  TabHeaderDetail,
} from './tabs';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { ShortModalModel } from './models/ShortModal.model';
import { Today } from '../../../../utils/time';

export enum BankStatementTabId {
  Detail,
  Bank,
}

export const BankStatementTabTitles = [
  '상세내역',
  '은행내역',
];

export const BankStatementTabModels = [
  DetailModel,
  BankModel,
];

export const BankStatementTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const BankStatementTabDataStateNames = [
  'details',
  'banks',
];

export interface BankStatementState {
  // 상세내역
  details?: Array<DetailModel>;

  // 은행내역
  banks?: Array<BankModel>;

  data: Array<MainModel>;
  popupData: MainModel;
  popupbank: BankModel;
  popupdetail: DetailModel;
  focusedTab?: TabModel;
  focused?: MainModel;
  focusIndex: number;
  stdate: string;
  enddate: string;
  bankcds: string;
  banknms: string;
  accnums: string;
  textDetailModal: boolean;

  // 단기차입금
  shortModal: boolean;
  shortModalData: Array<ShortModalModel>;
  shortModalYear: string;

  // trail
  beamt_tot: string;
  misamt_tot: string;
  mijamt_tot: string;
  resuamt_tot: string;

  // tab1 trail
  tab1_mijamt_tot: string;
  tab1_misamt_tot: string;
  tab1_resuamt_tot: string;

  // tab2 trail
  tab2_rcvamt_tot: string;
  tab2_resuamt_tot: string;
  tab2_sndamt_tot: string;

  // popup trail
  pmisamt_tot: string;
  pmijamt_tot: string;
  presuamt_tot: string;
  pcmsamt_tot: string;
  pjiroamt_tot: string;
  pmisamt_tot2: string;
  pmijamt_tot2: string;
  presuamt_tot2: string;

  // shortModal trail
  bamt_st_tot: string;
  sndamt_tot_tot: string;
  sndamt01_tot: string;
  sndamt02_tot: string;
  sndamt03_tot: string;
  sndamt04_tot: string;
  sndamt05_tot: string;
  sndamt06_tot: string;
  sndamt07_tot: string;
  sndamt08_tot: string;
  sndamt09_tot: string;
  sndamt10_tot: string;
  sndamt11_tot: string;
  sndamt12_tot: string;
  sndamt_tot_tot_1: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_10
 * @category 입출금현황
 */
export class BankStatement extends PageComponent<PageProps, BankStatementState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  shortModalGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  // 전일잔액
  beamtSum?: number;

  // 잔액
  resuamtSum?: number;

  popupData?: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = BankStatementTabId;
    const titles = BankStatementTabTitles;

    this.tabs = [
      new TabModel(id.Detail.toString(), MdPublish, titles[id.Detail]),
      new TabModel(id.Bank.toString(), MdReorder, titles[id.Bank]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      bankcds: '',
      accnum: '',
      focusIndex: 0,
      data: [],
      popupbank: [],
      popupdetail: [],

      // 단기차입금
      shortModal: false,
      shortModalData: [],
      shortModalYear: Today.year(),

      // trail
      beamt_tot: '0',
      misamt_tot: '0',
      mijamt_tot: '0',
      resuamt_tot: '0',
      tab1_mijamt_tot: '0',
      tab1_misamt_tot: '0',
      tab1_resuamt_tot: '0',
      tab2_rcvamt_tot: '0',
      tab2_resuamt_tot: '0',
      tab2_sndamt_tot: '0',

      // popup trail
      pmisamt_tot: '0',
      pmijamt_tot: '0',
      presuamt_tot: '0',
      pcmsamt_tot: '0',
      pjiroamt_tot: '0',
      pmisamt_tot2: '0',
      pmijamt_tot2: '0',
      presuamt_tot2: '0',

      // shortModal trail
      bamt_st_tot: '0',
      sndamt_tot_tot: '0',
      sndamt01_tot: '0',
      sndamt02_tot: '0',
      sndamt03_tot: '0',
      sndamt04_tot: '0',
      sndamt05_tot: '0',
      sndamt06_tot: '0',
      sndamt07_tot: '0',
      sndamt08_tot: '0',
      sndamt09_tot: '0',
      sndamt10_tot: '0',
      sndamt11_tot: '0',
      sndamt12_tot: '0',
      sndamt_tot_tot_1: '0',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 상세내역
      TabHeaderDetail,
      // 은행내역
      TabHeaderBank,
    ];
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
    await this.onTabChange(this.tabs[BankStatementTabId.Detail]);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[BankStatementTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${BankStatementTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        bankcd: this.state.focused?.bankcd,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[BankStatementTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${BankStatementTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        bankcd: this.state.focused?.bankcd,
      },
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => {
      if (this.state.data.length) {
        this.onRowFocusEvent(this.state.focused, this.state.focusIndex);
      }
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        bankcd: this.state.bankcds,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        if (items) {
          this.setState({
            data: [
              ...this.state.data,
              ...items.map((x: any) => new MainModel(x, false)),
            ],
          }, next);
        }

        // 전일잔액, 잔액 계산
        this.beamtSum = 0;
        this.resuamtSum = 0;
        // eslint-disable-next-line no-shadow,no-plusplus,for-direction
        for (let i = 0; i < items.length; i++) {
          if (items[i].acccd !== '2112') {
            this.beamtSum += parseInt(items[i].beamt, 10);
            this.resuamtSum += parseInt(items[i].resuamt, 10);
          }
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          data: [],
          [BankStatementTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      data: [],
      [BankStatementTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['acccd', 'beamt'],
        [this.state.focused?.acccd, this.state.focused?.beamt], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
      await this.SS({
        beamt_tot: this.infinity?.box?.beamt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    this.popupData = item;

    this.setState({
      focused: item, focusIndex: index,
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve({
        stdate: this.state.stdate || '0', enddate: this.state.enddate || '0', bankcd: item?.bankcd || '0', accnum: item?.accnum || '0',
      }, (params) => api.fxExec(`tab_${i + 1}_dw_1_RowFocuschanged`, params), (items) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BankStatementTabDataStateNames[i]]: [// @ts-ignore
              ...this.state[BankStatementTabDataStateNames[i]], ...items.map((x: any) => new BankStatementTabModels[i](x))],
          });
        }
      }, async () => {
        // @ts-ignore
        await this.SS({
          [BankStatementTabDataStateNames[i]]: [],
        });
        await this.infinity2?.retrieveAll();
        // @ts-ignore
        if (this.state[BankStatementTabDataStateNames[i]] && this.state[BankStatementTabDataStateNames[i]]?.length > 0) {
          this.grid2.current?.setFocus(0);
        }
      });

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      // @ts-ignore
      this.setState({
        [BankStatementTabDataStateNames[i]]: [],
      }, async () => {
        const dataDate = await this.infinity2?.retrieveAll();
        // @ts-ignore
        if (this.state[BankStatementTabDataStateNames[i]] && this.state[BankStatementTabDataStateNames[i]]?.length > 0) {
          this.grid2.current?.setFocus(0);
        }

        await this.SS({
          // 상세내역탭
          tab1_resuamt_tot: dataDate?.resuamt_tot || '0',
          tab1_mijamt_tot: dataDate?.mijamt_tot || '0',
          tab1_misamt_tot: dataDate?.misamt_tot || '0',

          // 은행내역탭
          tab2_rcvamt_tot: dataDate?.rcvamt_tot || '0',
          tab2_resuamt_tot: dataDate?.resuamt_tot || '0',
          tab2_sndamt_tot: dataDate?.sndamt_tot || '0',
        });
      });
    });
  }

  @computed
  get tabIndex(): BankStatementTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  async textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });

    await this.modalRetrive();
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_ca642w_10',
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        bankcd: this.state.focused?.bankcd,
        accnum: this.state.focused?.accnum,
      });

    if (data) {
      this.setState({
        popupbank: data?.items || [],
        popupdetail: data?.items2 || [],
        popupData: data,
        // popup trail
        pmisamt_tot: data?.misamt_tot || '0',
        pmijamt_tot: data?.mijamt_tot || '0',
        presuamt_tot: data?.resuamt_tot || '0',
        pcmsamt_tot: data?.cmsamt_tot || '0',
        pjiroamt_tot: data?.jiroamt_tot || '0',
        pmisamt_tot2: data?.misamt_tot2 || '0',
        pmijamt_tot2: data?.mijamt_tot2 || '0',
        presuamt_tot2: data?.resuamt_tot2 || '0',
      });
    }
  }

  @action
  async shortModal(isOpen: boolean) {
    this.setState({ shortModal: isOpen });

    await this.shortModalRetrieve();
  }

  @action
  async shortModalRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_ca642w_10_2112',
        year: this.state.shortModalYear,
      });

    if (data) {
      this.setState({
        shortModalData: data?.items,
        sndamt_tot_tot: data?.sndamt_tot_tot || '0',
        bamt_st_tot: data?.bamt_st_tot || '0',
        sndamt01_tot: data?.sndamt01_tot || '0',
        sndamt02_tot: data?.sndamt02_tot || '0',
        sndamt03_tot: data?.sndamt03_tot || '0',
        sndamt04_tot: data?.sndamt04_tot || '0',
        sndamt05_tot: data?.sndamt05_tot || '0',
        sndamt06_tot: data?.sndamt06_tot || '0',
        sndamt07_tot: data?.sndamt07_tot || '0',
        sndamt08_tot: data?.sndamt08_tot || '0',
        sndamt09_tot: data?.sndamt09_tot || '0',
        sndamt10_tot: data?.sndamt10_tot || '0',
        sndamt11_tot: data?.sndamt11_tot || '0',
        sndamt12_tot: data?.sndamt12_tot || '0',
        sndamt_tot_tot_1: data?.sndamt_tot_tot_1 || '0',
      });
    }

    this.shortModalGrid?.current?.forceRepaint(true);
  }

  @action
  async shortModalExcel() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      sub: 'w_popup_ca642w_10_2112',
      year: this.state.shortModalYear,
    });
  }

  render() {
    return (
      <BankStatementTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
