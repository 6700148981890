import * as React from 'react';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { BenefitItem } from './BenefitItem';

/**
 * 화면
 * @window w_tb_pb300_basic
 * @category 급여항목등록
 */
export const BenefitItemTemplate: React.FC<TemplateProps<BenefitItem>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      weight={0.02}
      style={{ marginLeft: 10 }}
    >
      <div style={{ color: 'var(--color-red)' }}>* 비과세채크시 해당항목은 공제금액에서 제외됩니다.</div>
      <div style={{ color: 'var(--color-blue)' }}>* 고정구분이 고정일시 전달의 금액을 자동으로 따라갑니다 .</div>
    </FlexLayout>

    <FlexLayout>
      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'pay_cd',
            text: '급여항목코드',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.flag === '1' ? 'var(--color-blue)' : '' }}
              >
                {x.pay_cd}
              </FlexLayout>
            ),
          },
          {
            id: 'pay_nm',
            text: '급여항목명',
            width: 30,
            render: (x, rowUpdate, ref) => (
              x.flag === '1'
                ? <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.pay_nm}
                </FlexLayout>
                : <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.pay_nm}
                  onChange={(value) => rowUpdate({ pay_nm: value })}
                  isDisabledTrackingStateChange={true}
                />
            ),
          },
          {
            id: 'taxflag',
            text: '비과세',
            width: 10,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.taxflag === '1'}
                onChange={x.flag === '1' ? () => {} : (value) => rowUpdate({ taxflag: value ? '1' : '0' })}
                isDisabledTrackingStateChange={true}
              />
            ),
          },
          {
            id: 'gubun',
            text: '고정구분',
            width: 10,
            render: (x, rowUpdate) => (
              x.flag === '1'
                ? <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.gubun === '1' ? 'var(--color-blue)' : '' }}
                >
                  {x.gubun === '1' ? '고정비용' : '변동비용'}
                </FlexLayout>
                : <ComboBox
                  value={x.gubun}
                  textAlign="center"
                  style={{ color: x.gubun === '1' ? 'var(--color-blue)' : '' }}
                  data={[
                    { value: '1', remark: '고정비용' },
                    { value: '0', remark: '변동비용' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ gubun: option.value })}
                />
            ),
          },
          {
            id: 'reader',
            text: '직책수당',
            width: 10,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.reader === '1'}
                onChange={x.flag === '1' ? () => {} : (value) => rowUpdate({ reader: value ? '1' : '0' })}
                isDisabledTrackingStateChange={true}
              />
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 30,
            render: (x, rowUpdate, ref) => (
              x.flag === '1'
                ? <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.remark}
                </FlexLayout>
                : <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                  isDisabledTrackingStateChange={true}
                />
            ),
          },
        ]}
        data={scope.state.data}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      />
    </FlexLayout>
  </FlexLayout>
);
