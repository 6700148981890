import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { ComplaintTemplate } from './Complaint.template';
import { ComplaintModel } from './Complaint.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface ComplaintState {
  searchQuery: string;
  data: Array<ComplaintModel>;
  focused?: ComplaintModel;
}

/**
 * 컨트롤러
 * @window w_tb_e300
 * @category 민원관리코드
 */
export class Complaint extends PageComponent<PageProps, ComplaintState>
  implements PageToolEvents {
  updatedRows?: Array<ComplaintModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve();

    await this.SS({
      data: data?.items?.map((x: any) => new ComplaintModel(x)) || [],
    });
    await this.table.current?.update(true);
    data?.items?.length > 0 && await this.table.current?.setFocus(0);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        new ComplaintModel(data, true),
        ...this.state.data,
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.civilcd} - ${this.state.focused?.civilnm}`;
    await api.delete(text, this.state.focused) && await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel();
  }

  @action
  onRowFocusEvent(item: ComplaintModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<ComplaintModel>, updatedRows: Array<ComplaintModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <ComplaintTemplate
        scope={this}
      />
    );
  }
}
