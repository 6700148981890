import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { CMSAuto } from './CMSAuto';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';

const flags = [
  { value: '0', remark: '잔액부족' },
  { value: '1', remark: '출금성공' },
];

const rcvflags = [
  { value: '0', remark: '미처리' },
  { value: '1', remark: '매출' },
  { value: '2', remark: '입금' },
];

const erp_rcvflags = [
  { value: '0', remark: '미매출' },
  { value: '1', remark: '매출' },
  { value: '2', remark: '입금' },
];

const cltflags = [
  { value: '0', remark: 'X' },
  { value: '1', remark: '○' },
];

/**
 * 화면
 * @window w_tb_da026_cms
 * @category CMS자동이체
 */
export const CMSAutoTemplate: React.FC<TemplateProps<CMSAuto>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={600}>
          <SearchLayout>
            <FlexLayout size={210}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    setData({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    setData({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    setData({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    setData({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onSubExcelEvent()}>
              <div style={{ width: 72 }}>EXCEL 자료</div>
            </Button>
          </SearchLayout>
        </FlexLayout>

        <FlexLayout />
        <FlexLayout size={420}>
          <SearchLayout>
            <FlexLayout size={210}>
              <span>입금일</span>
              <DateTextBox
                value={scope.state.cmsdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ cmsdate: value })}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.cmsdate));
                    date.setDate(date.getDate() - 1);
                    setData({ cmsdate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.cmsdate));
                    date.setDate(date.getDate() + 1);
                    setData({ cmsdate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
              <Button onClick={() => scope.onBtnEvent()}>
              <div style={{ width: 72 }}>{scope.state.isReported ? '입금처리' : '입금취소'}</div>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'savedate',
                text: '저장일자',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.savedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cnt',
                text: '건수',
                width: 40,
              },
            ]}
            data={scope.state.cmsAutoList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout weight={6}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 30,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'flag',
                text: '출금여부',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.flag === '1'
                        ? 'var(--color-blue)'
                        : 'var(--color-red)',
                    }}
                  >
                    {flags.filter((y) => y.value === x.flag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvflag',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      CMS연결
                    </FlexLayout>

                    <FlexLayout>
                      매출/입금
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 70,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.total}건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: x.rcvflag === '0' ? 'var(--color-red)'
                        : x.rcvflag === '1' ? '#7F7A2C'
                          : 'var(--color-blue)',
                    }}
                  >
                    {rcvflags.filter((y) => y.value === x.rcvflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'erp_rcvflag',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      ERP
                    </FlexLayout>

                    <FlexLayout>
                      매출/입금
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: x.erp_rcvflag === '0' ? 'var(--color-red)'
                        : x.erp_rcvflag === '1' ? '#7F7A2C'
                          : 'var(--color-blue)',
                    }}
                  >
                    {erp_rcvflags.filter((y) => y.value === x.erp_rcvflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'misdate',
                text: '매출일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misnum',
                text: '번호',
                width: 70,
              },
              {
                id: 'rcvdate',
                text: '입금일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltflag',
                text: '거래처매칭',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.cltflag === '0' ? 'var(--color-red)'
                        : 'var(--color-blue)',
                      fontSize: 30,
                    }}
                  >
                    {cltflags.filter((y) => y.value === x.cltflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 150,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'autonum',
                text: '납부자번호',
                width: 100,
              },
              {
                id: 'jamt',
                text: '정기금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.jamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.jamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '미납금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '출금신청금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.jamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'notamt',
                text: '불능금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.notamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.notamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '출금성공금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.rcvamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'bmar',
                text: '수수료',
                width: 70,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.bmar_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.bmar)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvseq',
                text: '납입회차',
                width: 70,
              },
              {
                id: 'outdate',
                text: '출금일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.outdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 70,
              },
              {
                id: 'saupnum',
                text: '사업자번호',
                width: 100,
              },
              {
                id: 'banknm',
                text: '은행',
                width: 70,
              },
              {
                id: 'accnum',
                text: '계좌번호',
                width: 150,
              },
              {
                id: 'accnm',
                text: '예금주명',
                width: 150,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.accnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'tel',
                text: '연락처',
                width: 100,
              },
              {
                id: 'hpnum',
                text: '휴대폰',
                width: 100,
              },
              {
                id: 'remark',
                text: '에러사항',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'address',
                text: '주소',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.address}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.cmsAutoListDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item, index) => scope.onRowFocusEvent2(item, index)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
