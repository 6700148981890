import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  FlexLayout,
  LabelBox,
  TextBox,
  GridLayout,
  SearchLayout,
  DateTextBox,
  ComboBox,
  ComboBoxModel,
  OptionBox,
} from '../../../../components';
import { ProjectCode } from './ProjectCode';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { SearchBinding } from '../../../../models/common';

const gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '기타수입' },
  { value: '18', remark: '기타환불' },
];

/**
 * 화면
 * @window w_tb_da003_01
 * @category 프로젝트코드등록
 */
export const ProjectCodeTemplate: React.FC<TemplateProps<ProjectCode>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.setState({ year: value })}
            onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>구분</span>
          <ComboBox
            value={scope.state.searchGubun}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.gubun?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
            onSelect={(option) => scope.setState({ searchGubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '프로젝트, 현장으로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'projno',
              text: '코드',
              width: 9,
            },
            {
              id: 'gubun',
              text: '구분',
              width: 9,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'projnm',
              text: '프로젝트명',
              width: 31,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.projnm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 31,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.textOftenList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />

        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1} >
            <LabelBox
              title="프로젝트번호"
              isNeed={true}
            >
              <TextBox
                textAlign="center"
                value={model.projno}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="프로젝트명"
              isNeed={true}
            >
              <TextBox
                textAlign="left"
                value={model.projnm}
                onChange={(value) => setData({ projnm: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1} >
            <LabelBox title="구분">
              <ComboBox
                value={model.gubun}
                data={[
                  { value: '11', remark: '유지보수' },
                  { value: '12', remark: '수리공사' },
                  { value: '13', remark: '부품교체' },
                  { value: '14', remark: '설치공사' },
                  { value: '15', remark: '리모델링' },
                  { value: '16', remark: '현대엘리베이터(공)' },
                  { value: '17', remark: '전기공사' },
                  { value: '18', remark: '통장거래' },
                  { value: '19', remark: '기타수입' },
                  { value: '20', remark: '기타환불' },
                  { value: '21', remark: '가지급정산' },
                  { value: '22', remark: '개발매출' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setData({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox
              title="현장명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.actnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="계약일자">
              <DateTextBox
                value={model.prodate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ prodate: value })}
              />
            </LabelBox>

            <LabelBox title="입력자">
              <TextBox
                textAlign="center"
                value={model.pernm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="공사시작일자">
              <DateTextBox
                value={model.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ stdate: value })}
              />
            </LabelBox>

            <LabelBox title="공사만료일">
              <DateTextBox
                value={model.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ enddate: value })}
              />
            </LabelBox>

            <LabelBox
              weight={2}
              title="상태"
            >
              <OptionBox
                value={model.state}
                data={[
                  { value: '0', remark: '진행중' },
                  { value: '1', remark: '완료' },
                  { value: '2', remark: '취소' },
                ]}
                onChange={(v) => setData({ state: v.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={400}>
            <LabelBox title="비고">
              <TextBox
                isMultiline={true}
                value={model.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
