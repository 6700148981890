import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { TransportationFee } from '../TransportationFee';
import { LastMonthGrid } from '../headers/LastMonthGrid';

/**
 * 화면 > 탭 A
 * @window w_tb_ca640_pa105_02w
 * @category 월교통비/통행료내역
 */
export const LastMonthTemplate: React.FC<TemplateProps<TransportationFee>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <GridLayout
        ref={scope.grid}
        header={LastMonthGrid(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.lastMonths}
        infinityHandler={scope.infinityLastMonths}
      />
    </FlexLayout>
  );
};
