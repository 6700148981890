import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdMoneyOff,
  MdReorder,
} from 'react-icons/md';
import {
  FiCalendar,
  FiClipboard,
  FiFile,
  GrMoney,
  HiOutlineTrendingDown,
  HiOutlineTrendingUp,
} from 'react-icons/all';
import {
  ConfirmType,
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  AccountDueModel,
  AimModel,
  CancelModel,
  ConstructionModel,
  FmModel,
  FreeModel,
  IndividualModel,
  DepartmetModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { TableLayout } from '../../../../components';
import { MonthWorkTemplate } from './MonthWork.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderAccountDue,
  TabHeaderAim,
  TabHeaderCancel,
  TabHeaderConstruction,
  TabHeaderDepartmet,
  TabHeaderFm,
  TabHeaderFree,
  TabHeaderIndividual,
} from './tabs';
import { PageComponent } from '../../../../utils/layout';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { Today } from '../../../../utils/time';
import { PopupModel } from './models/popup.model';

export enum MonthWorkTabId {
  Departmet,
  Individual,
  Fm,
  Aim,
  Cancel,
  Construction,
  Free,
  AccountDue,
}

export const MonthWorkTabTitles = [
  '부서별 업무보고',
  '개인별 업무보고',
  'FM계획예정',
  '계획대비실적[계약]',
  '보수해지현장',
  '계획대비실적[공사]',
  '무상관리현장',
  '미수금현황',
];

export const MonthWorkTabModels = [
  DepartmetModel,
  IndividualModel,
  FmModel,
  AimModel,
  CancelModel,
  ConstructionModel,
  FreeModel,
  AccountDueModel,
];

export const MonthWorkTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
  'tab_7',
  'tab_8',
];

export const MonthWorkTabDataStateNames = [
  'departmets',
  'individuals',
  'fms',
  'aims',
  'cancels',
  'constructions',
  'frees',
  'accountDues',
];

export const MonthWorkTabUpdatesStateNames = [
  'departmetsUpdates',
  'individualsUpdates',
  'fmsUpdates',
  'aimsUpdates',
  'cancelsUpdates',
  'constructionsUpdates',
  'freesUpdates',
  'accountDuesUpdates',
];

export const MonthWorkFocusedStateNames = [
  'departmetsFocused',
  'individualsFocused',
  'fmsFocused',
  'aimsFocused',
  'cancelsFocused',
  'constructionsFocused',
  'freesFocused',
  'accountDuesFocused',
];

export interface MonthWorkState {
  // 부서별업무보고
  departmets?: Array<DepartmetModel>;
  departmetsUpdates?: Array<DepartmetModel>;
  departmetsFocused: DepartmetModel;

  // 개인별업무보고
  individuals?: Array<IndividualModel>;
  individualsUpdates?: Array<IndividualModel>;
  individualsFocused: IndividualModel;

  // FM계획예정
  fms?: Array<FmModel>;
  fmsUpdates?: Array<FmModel>;
  focused: FmModel;
  // FM계획예정 팝업
  fmPlanModal: boolean;
  fmModalData: Array<FmModel>;
  modalFocused: FmModel;
  historyModal?: boolean;
  modalData: Array<FmModel>;
  textDetailModal: boolean;
  year: string;
  remark?: string;

  // 계획대비실적[계약]
  aims?: Array<AimModel>;
  aimsUpdates?: Array<AimModel>;
  aimsFocused: AimModel;

  // 보수해지현장
  cancels?: Array<CancelModel>;
  cancelsUpdates?: Array<CancelModel>;
  cancelsFocused: CancelModel;

  // 계획대비실적[공사]
  constructions?: Array<ConstructionModel>;
  constructionsUpdates?: Array<ConstructionModel>;
  constructionsFocused: ConstructionModel;
  // FM계획예정 팝업
  constructionModal: boolean;
  constructionModalData: Array<FmModel>;

  // 무상관리현장
  frees?: Array<FreeModel>;
  freesUpdates?: Array<FreeModel>;
  freesFocused: FreeModel;

  // 미수금현황
  accountDues?: Array<AccountDueModel>;
  accountDuesUpdates?: Array<AccountDueModel>;
  accountDuesFocused: AccountDueModel;

  yearmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string
  focusedTab?: TabModel;

  // trail
  total: string;
  compcnt_tot: string;
  notcnt_tot: string;
  fmcnt_tot: string;
  qtyamt_tot: string;
  qtysamt_tot: string;
  chaamt_tot: string;
  longcnt_tot: string;
  pogcnt_tot: string;
  fmplancnt_tot: string;
  longplancnt_tot: string;
  pogplancnt_tot: string;
  qty_tot: string;
  plancnt_tot: string;
  costcnt_tot: string;
  planamt_tot: string;
  costamt_tot: string;
  amt_tot: string;
  beamt_tot: string;
  rcvamt_tot: string;
  resuamt_tot: string;

  // popup
  modalState: boolean;
  popupList: Array<PopupModel>;
  popupDate: FmModel,
  flags: Array<any>;
  popupFocused?: PopupModel,
}

/**
 * 컨트롤러
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class MonthWork extends PageComponent<PageProps, MonthWorkState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<PopupModel>;

  modalUpdated?: Array<FmModel>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  planTable: React.RefObject<TableLayout> = React.createRef();

  mainTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinityHistory?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = MonthWorkTabId;
    const titles = MonthWorkTabTitles;

    this.tabs = [
      new TabModel(id.Departmet.toString(), MdReorder, titles[id.Departmet]),
      new TabModel(id.Individual.toString(), FiClipboard, titles[id.Individual]),
      new TabModel(id.Fm.toString(), FiCalendar, titles[id.Fm]),
      new TabModel(id.Aim.toString(), FiFile, titles[id.Aim]),
      new TabModel(id.Cancel.toString(), HiOutlineTrendingDown, titles[id.Cancel]),
      new TabModel(id.Construction.toString(), HiOutlineTrendingUp, titles[id.Construction]),
      new TabModel(id.Free.toString(), GrMoney, titles[id.Free]),
      new TabModel(id.AccountDue.toString(), MdMoneyOff, titles[id.AccountDue]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      divicd: '',
      perid: '',
      pernm: '',
      popupList: [],
      modalData: [],
      year: Today.yearMon(),

      // trail
      total: '0',
      compcnt_tot: '0',
      notcnt_tot: '0',
      fmcnt_tot: '0',
      qtyamt_tot: '0',
      qtysamt_tot: '0',
      chaamt_tot: '0',
      longcnt_tot: '0',
      pogcnt_tot: '0',
      fmplancnt_tot: '0',
      longplancnt_tot: '0',
      pogplancnt_tot: '0',
      qty_tot: '0',
      planamt_tot: '0',
      costamt_tot: '0',
      amt_tot: '0',
      beamt_tot: '0',
      rcvamt_tot: '0',
      resuamt_tot: '0',
      plancnt_tot: '0',
      costcnt_tot: '0',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      TabHeaderDepartmet, // 부서별 업무보고
      TabHeaderIndividual, // 개인별 업무보고
      TabHeaderFm, // FM계획예정
      TabHeaderAim, // 계획대비실적[계약]
      TabHeaderCancel, // 보수해지현장
      TabHeaderConstruction, // 계확대비실적[공사]
      TabHeaderFree, // 무상관리현장
      TabHeaderAccountDue, // 미수금현황
    ];
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[MonthWorkTabId.Departmet]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    if (i === MonthWorkTabId.Departmet && (this.state.accountDues?.length || 0) === 0) {
      await this.doRetrieve(MonthWorkTabId.Departmet);
    }
    await this.doRetrieve(i);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[MonthWorkTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${MonthWorkTabFunctionNames[i]}_print`,
      {
        stmon: this.state.yearmon,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[MonthWorkTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${MonthWorkTabFunctionNames[i]}_excel`,
      {
        stmon: this.state.yearmon,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
    );
  }

  @action
  async onMessageEvent(topic: string, message: string) {
    const { user } = this.props.publicStore;

    if (topic === `/elman/web/${user.custcd}/${user.perid}`) {
      const json = JSON.parse(message);
      if (json?.response.messagebox) {
        ConfirmWarning.show('확인', json?.response.messagebox);
      }
      const dataDate = json.response;

      // @ts-ignore
      await this.SS({
        compcnt_tot: dataDate?.compcnt_tot || '0',
        notcnt_tot: dataDate?.notcnt_tot || '0',
        fmcnt_tot: dataDate?.fmcnt_tot || '0',
        qtyamt_tot: dataDate?.qtyamt_tot || '0',
        qtysamt_tot: dataDate?.qtysamt_tot || '0',
        chaamt_tot: dataDate?.chaamt_tot || '0',
        longcnt_tot: dataDate?.longcnt_tot || '0',
        pogcnt_tot: dataDate?.pogcnt_tot || '0',
        fmplancnt_tot: dataDate?.fmplancnt_tot || '0',
        longplancnt_tot: dataDate?.longplancnt_tot || '0',
        pogplancnt_tot: dataDate?.pogplancnt_tot || '0',
        qty_tot: dataDate?.qty_tot || '0',
        planamt_tot: dataDate?.planamt_tot || '0',
        costamt_tot: dataDate?.costamt_tot || '0',
        amt_tot: dataDate?.amt_tot || '0',
        beamt_tot: dataDate?.beamt_tot || '0',
        rcvamt_tot: dataDate?.rcvamt_tot || '0',
        resuamt_tot: dataDate?.resuamt_tot || '0',
        total: dataDate?.total || '0',
        plancnt_tot: dataDate?.plancnt_tot || '0',
        costcnt_tot: dataDate?.costcnt_tot || '0',
        [MonthWorkTabDataStateNames[this.tabIndex]]: [
          // @ts-ignore
          ...this.state[MonthWorkTabDataStateNames[this.tabIndex]],
          ...dataDate?.items?.map((x: any) => new MonthWorkTabModels[this.tabIndex](x)) || [],
        ],
      });
      await this.mainTable.current?.update(true);
      await this.props.publicStore.stopLoading();
    }
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
  }

  @action
  async doRetrieve(i: MonthWorkTabId) {
    const { actionStore: api } = this.props;

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.SS({
      [MonthWorkTabDataStateNames[i]]: [],
    });

    await api.fxExec(
      `${MonthWorkTabFunctionNames[i]}_retrieve_lazytask`,
      {
        stmon: this.state.yearmon,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
    );

    this.props.publicStore.doLoading();
  }

  @action
  onRowFocusEvent(item: any) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    const i = this.tabIndex;
    this.updatedRows = updatedRows;
    // @ts-ignore
    this.setState({ [MonthWorkTabUpdatesStateNames[i]]: rows });
  }

  @action
  async historyPopupModal(isOpen: boolean) {
    await this.historyRetrieve();
    this.setState({ historyModal: isOpen });
  }

  @action
  async historyRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityHistory = new InfinityRetrieve(
      {
        sub: 'w_tb_e101_history',
        actcd: this.state.focused?.actcd,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          modalData: [...items],
        });
      },
      async () => {
        await this.SS({
          modalData: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.modalData && this.state.modalData?.length > 0) {
          await this.planTable.current?.update(true);
          this.planTable.current?.setFocus(0, 1);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      modalData: [],
    }, async () => {
      await this.infinityHistory?.retrieveAll();
      await this.planTable.current?.update(true);
      this.planTable.current?.setFocus(0, 1);
    });
  }

  @action
  async onNewHistoryEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e101_history',
      actcd: this.state.focused?.actcd,
    });

    const newModel = new FmModel(data, true);

    if (data) {
      this.setState({
        modalData: [...this.state.modalData, new FmModel(data, true)],
        modalFocused: newModel,
      }, () => this.planTable.current?.update());
      this.planTable.current?.setFocus(this.state.modalData.length - 1, 2);
    }
  }

  @action
  async onHistorySaveEvent() {
    const { actionStore: api } = this.props;

    const checkData: any = [];
    const checkData2 = this.state.modalFocused;
    if (!this.modalUpdated) {
      // @ts-ignore
      checkData2.new = this.modalUpdated[0].new || '0';
      checkData2.remark = this.state.remark;
    } else {
      this.modalUpdated?.forEach((x: any) => {
        checkData.push({
          new: x.new || '0',
          actcd: this.state.focused?.actcd || '',
          actnm: this.state.focused?.actnm || '',
          seq: x.seq || '',
          perid: x.perid || '',
          pernm: x.pernm || '',
          hdate: x.hdate || '',
          title: x.title || '',
          remark: this.state.remark || '',
          wakeflag: x.wakeflag || '',
          wakedate: x.wakedate || '',
          okflag: x.okflag || '',
        });
      });
    }

    const data = await api.save({
      sub: 'w_tb_e101_history',
      items: checkData || checkData2,
    }, true);
    if (data) {
      this.setState({
      }, () => this.historyRetrieve());
    }
  }

  @action
  async historyDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e101_history',
      actcd: this.state.focused?.actcd,
      seq: this.state.modalFocused?.seq,
    }) && await this.historyRetrieve();

    this.modalUpdated = [];
  }

  @action
  async historyRowFocusEvent(item:FmModel) {
    await this.setState({
      modalFocused: item,
      remark: item.remark,
    });
  }

  @action
  onModalUpdatedRows(rows2: Array<FmModel>, modalUpdated: Array<FmModel>) {
    this.modalUpdated = modalUpdated;
    this.setState({ modalData: rows2 });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ modalState: isOpen });

    this.modalRetrieve();
  }

  @action
  constructionModalEvent(isOpen: boolean) {
    this.setState({ constructionModal: isOpen });

    this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_da052_detail',
        perid: this.state.focused?.perid,
        pernm: this.state.focused?.pernm,
        mon: this.state.yearmon,
        gubun: this.state.focused?.gubun,
      });
    if (data) {
      this.setState({
        popupList: data?.items || [],
        popupDate: data,
      }, async () => {
        await this.table.current?.update();
        this.table.current?.setFocus(0, 2);
      });
    }
  }

  @action
  popupRowFocusEvent(item: PopupModel) {
    this.setState({ popupFocused: item });
  }

  @action
  popupUpdatedRows(rows: Array<PopupModel>, updatedRows: Array<PopupModel>) {
    this.updatedRows2 = updatedRows;
    this.setState({ popupList: rows });
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;

    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_da052_detail',
      mon: this.state.yearmon,
      perid: this.state.popupFocused?.perid,
      gubun: this.state.popupFocused?.gubun,
      seq: this.state.popupFocused?.seq,
    }) && await this.modalRetrieve();
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;

    const checkData: any = [];
    this.updatedRows2?.forEach((x: any) => {
      checkData.push({
        new: x.new || '0',
        perid: this.state.focused?.perid,
        mon: this.state.yearmon,
        gubun: this.state.focused?.gubun,
        seq: x.seq,
        actcd: x.actcd,
        actnm: x.actnm,
        besamt: x.besamt,
        samt: x.samt,
        remark: x.remark,
        compflag: x.compflag,
        contgubun: x.contgubun,
        flag: x.flag,
        equpcd: x.equpcd,
        equpnm: x.equpnm,
        usemon: x.usemon,
        costdate: x.costdate,
        costnum: x.costnum,
        state_remark: x.state_remark,
        qty: x.qty,
      });
    });

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_da052_detail',
        items: checkData,
      });
    if (data) {
      this.setState({
        popupList: data?.items || [],
      });
    }
  }

  @action
  async modalNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'new',
      {
        sub: 'w_popup_da052_detail',
        mon: this.state?.yearmon || '',
        perid: this.state.popupFocused?.perid,
        gubun: this.state.popupFocused?.gubun,
      },
    );

    this.setState({
      popupList: [...this.state.popupList, new PopupModel(data, true)],
    }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(this.state.popupList.length - 1, 2);
    });
  }

  @action
  texDowntModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });
  }

  @action
  async modalDownEvent() {
    const { actionStore: api } = this.props;
    this.texDowntModal(false);

    await api.fxExec(
      'wb_down',
      {
        sub: 'w_popup_da052_detail',
        mon: this.state?.year || '',
        perid: this.state.popupFocused?.perid || '',
        gubun: this.state.popupFocused?.gubun || '',
      },
    );

    await this.table.current?.update();
  }

  @action
  async modalContractEvent() {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const warning = await Confirm.show('확인', `현계약갱신을 하면 계획내역은 삭제되고 ${this.state.yearmon}월 계약만료건으로 갱신됩니다 그래도 계약갱신을 하시겠습니까?`, ConfirmType.QUESTION);
    if (warning === false) return;

    await api.fxExec(
      'wb_e101',
      {
        sub: 'w_popup_da052_detail',
        mon: this.state?.yearmon || '',
        perid: this.state.popupFocused?.perid || '',
        gubun: this.state.popupFocused?.gubun || '',
      },
    );
    await this.table.current?.update();
  }

  @computed
  get tabIndex(): MonthWorkTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <MonthWorkTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
