import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 기본정보 모델
 * @window w_p2110
 * @category 인사등록
 */
export class BasicModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 외국인여부
   *
   * **data type** char(3)
   */
  readonly perclasifi: string;

  /**
   * 한글이름
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 주민번호
   *
   * **data type** varchar(13)
   */
  readonly rnum: string;

  /**
   * 성별
   *
   * **data type** varchar(1)
   */
  readonly sex: string;

  /**
   * 양/음
   *
   * **data type** varchar(1)
   */
  readonly yuclafi: string;

  /**
   * 생년월일
   *
   * **data type** varchar(8)
   */
  readonly birthday: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서2
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly kukcd: string;

  /**
   * 부서2
   *
   * **data type** varchar(40)
   */
  readonly kuknm: string;

  /**
   * 직급
   *
   * **data type** varchar(3)
   */
  readonly clacd: string;

  /**
   * 직급2
   *
   * **data type** varchar(40)
   */
  readonly clanm: string;

  /**
   * 모름
   *
   * **data type** varchar(3)
   */
  readonly laborarea: string;

  /**
   * 전화번호
   *
   * **data type** varchar(30)
   */
  readonly telnum: string;

  /**
   * 구분
   *
   * **data type** varchar(3)
   */
  readonly rtclafi: string;

  /**
   * 입사일
   *
   * **data type** char(8)
   */
  readonly entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  readonly rtdate: string;

  /**
   * 주민등록증주소
   *
   * **data type** varchar(6)
   */
  readonly rzipcd: string;

  /**
   * 현주소3
   *
   * **data type** varchar(90)
   */
  readonly rzipadres: string;

  /**
   * 주민등록증주소3
   *
   * **data type** varchar(90)
   */
  readonly radres: string;

  /**
   * 현주소
   *
   * **data type** varchar(6)
   */
  readonly zipcd: string;

  /**
   * 모름
   *
   * **data type** varchar(90)
   */
  readonly zipadres: string;

  /**
   * 모름
   *
   * **data type** varchar(90)
   */
  readonly adres: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly stmanpwr: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ssdate: string;

  /**
   * 거주구분
   *
   * **data type** char(1)
   */
  readonly resiclafi: string;

  /**
   * 건수
   *
   * **data type** 모름
   */
  readonly attcnt: number;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly foreryn: string;

  /**
   * 외국인번호
   *
   * **data type** varchar(20)
   */
  readonly forerrnum: string;

  /**
   * 결혼
   *
   * **data type** char(1)
   */
  readonly mrgyn: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly mrgdate: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly mateyn: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly resyn: string;

  /**
   * 모름
   *
   * **data type** float
   */
  readonly resnum: string;

  /**
   * 장애
   *
   * **data type** char(1)
   */
  readonly disperyn: string;

  /**
   * 모름
   *
   * **data type** float
   */
  readonly dispernum: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly wohldr: string;

  /**
   * 모름
   *
   * **data type** float
   */
  readonly childnum: string;

  /**
   * 가족[본인제외]
   *
   * **data type** float
   */
  readonly fami60: string;

  /**
   * 20세미만자녀
   *
   * **data type** float
   */
  readonly fami20: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly overinco: string;

  /**
   * 이체은행
   *
   * **data type** varchar(2)
   */
  readonly bankcd: string;

  /**
   * 이체은행명
   *
   * **data type** varchar(50)
   */
  readonly banknm: string;

  /**
   * 이체은행2
   *
   * **data type** varchar(50)
   */
  readonly accnum: string;

  /**
   * 모름
   *
   * **data type** varchar(255)
   */
  readonly picpath: string;

  /**
   * 이메일
   *
   * **data type** varchar(30)
   */
  readonly email: string;

  /**
   * 영문이름
   *
   * **data type** varchar(30)
   */
  readonly pernme: string;

  /**
   * 한문이름
   *
   * **data type** varchar(30)
   */
  readonly pernmc: string;

  /**
   * 직위
   *
   * **data type** char(2)
   */
  readonly rspcd: string;

  /**
   * 직위이름
   *
   * **data type** ?
   */
  readonly rspnm: string;

  /**
   * 호봉
   *
   * **data type** char(2)
   */
  readonly stpcd: string;

  /**
   * 전화번호
   *
   * **data type** varchar(20)
   */
  readonly tel: string;

  /**
   * 핸드폰번호
   *
   * **data type** varchar(20)
   */
  handphone: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly payiden: string;

  /**
   * 모름
   *
   * **data type** char(2)
   */
  readonly gracd: string;

  /**
   * 직영구분
   *
   * **data type** char(1)
   */
  readonly mpclafi: string;

  /**
   * 직군
   *
   * **data type** char(2)
   */
  readonly groupcd: string;

  /**
   * 직무
   *
   * **data type** char(2)
   */
  readonly marketcd: string;

  /**
   * 근속기준
   *
   * **data type** varchar(8)
   */
  readonly badate: string;

  /**
   * 기타사항(특이사항)
   *
   * **data type** text
   */
  readonly remark: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly gubun: string;

  /**
   * 승강기관리원아이디체크
   *
   * **data type** char(1)
   */
  readonly grpflag: string;

  /**
   * 승강기관리원아이디
   *
   * **data type** varchar(20)
   */
  readonly groupid: string;

  /**
   * 승강기관리원비밀번호
   *
   * **data type** varchar(20)
   */
  readonly grouppw: string;

  /**
   * 모름
   *
   * **data type** char(2)
   */
  readonly officecd: string;

  /**
   * 퇴직연금시작일체크
   *
   * **data type** char(1)
   */
  readonly retireflag: string;

  /**
   * 퇴직연금시작일
   *
   * **data type** char(8)
   */
  readonly retiredate: string;

  /**
   * 익일계획
   *
   * **data type** varchar(1)
   */
  readonly overchk: string;

  /**
   * 모름
   *
   * **data type** int
   */
  readonly concd: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  readonly hsflag: string;

  /**
   * 팀장
   *
   * **data type** varchar(1)
   */
  readonly reader: string;

  /**
   * 4대보험취득일
   *
   * **data type** varchar(8)
   */
  readonly insudate: string;

  /**
   * 4대보험만료일
   *
   * **data type** varchar(8)
   */
  readonly inoutdate: string;

  /**
   * 모름
   *
   * **data type** varchar(10)
   */
  readonly readerid: string;

  /**
   * 모름
   *
   * **data type** varchar(10)
   */
  readonly readernm: string;

  /**
   * 명함발매
   *
   * **data type** varchar(1)
   */
  readonly cardflag: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly jspjangcd: string;

  /**
   * 모름
   *
   * **data type** varchar(3)
   */
  readonly overseq: string;

  /**
   * 우편번호
   *
   * **data type** varchar(6)
   */
  readonly oldrzipcd: string;

  /**
   * 현우편번호
   *
   * **data type** varchar(6)
   */
  readonly oldzipcd: string;

  /**
   * 위도
   *
   * **data type** varchar(16)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(16)
   */
  readonly lng: string;

  /**
   * 현위도
   *
   * **data type** varchar(16)
   */
  readonly rlat: string;

  /**
   * 현경도
   *
   * **data type** varchar(16)
   */
  readonly rlng: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly rnumflag: string;

  /**
   * 주민등록증주소2
   *
   * **data type** varchar(255)
   */
  readonly old_rzipadres: string;

  /**
   * 현주소2
   *
   * **data type** varchar(255)
   */
  readonly old_zipadres: string;

  /**
   * 공지참여여부
   *
   * **data type** varchar(1)
   */
  readonly noticeflag: string;

  /**
   * 사무실우편번호
   *
   * **data type** varchar(6)
   */
  readonly office_zipcd: string;

  /**
   * 사무실주소
   *
   * **data type** varchar(255)
   */
  readonly office_address: string;

  /**
   * 사무실상세주소
   *
   * **data type** varchar(255)
   */
  readonly office_address2: string;

  /**
   * 사무실위도
   *
   * **data type** varchar(16)
   */
  readonly office_lat: string;

  /**
   * 사무실경도
   *
   * **data type** varchar(16)
   */
  readonly office_lng: string;

  /**
   * 자동계산
   *
   * **data type** varchar(1)
   */
  readonly auto_commute: string;

  /**
   * 권한점수
   *
   * **data type** float
   */
  readonly talent: string;

  /**
   * 급여대상
   *
   * **data type** varchar(1)
   */
  readonly payment: string;

  /**
   * 직책수당
   *
   * **data type** float
   */
  readonly pay_serallow: string;

  /**
   * GPS대상X
   *
   * **data type** varchar(1)
   */
  readonly notgps: string;

  /**
   * 퇴직금대상
   *
   * **data type** varchar(1)
   */
  readonly retire: string;

  /**
   * 연장대상
   *
   * **data type** varchar(1)
   */
  readonly pay_overflag: string;

  /**
   * 연차산정대상
   *
   * **data type** varchar(1)
   */
  readonly yearly_flag: string;

  /**
   * 기본갯수
   *
   * **data type** float
   */
  readonly yearly_default: string;

  /**
   * 구우편번호
   *
   * **data type** varchar(6)
   */
  readonly old_office_zipcd: string;

  /**
   * 구우편주소
   *
   * **data type** varchar(255)
   */
  readonly old_office_address: string;

  /**
   * 구우편상세주소
   *
   * **data type** varchar(255)
   */
  readonly old_office_address2: string;

  /**
   * 엑타스연동아이디
   *
   * **data type** varchar(10)
   */
  readonly actas_perid: string;

  readonly dz_emp_cd: string;

  /**
   * data new 를 위함
   */
  readonly new: string;

  /**
   * 체크박스
   */
  readonly chk: string;



  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.perclasifi = data.perclasifi || '';
    this.pernm = data.pernm || '';
    this.rnum = data.rnum || '';
    this.sex = data.sex || '';
    this.yuclafi = data.yuclafi || '';
    this.birthday = data.birthday || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.kukcd = data.kukcd || '';
    this.kuknm = data.kuknm || '';
    this.clacd = data.clacd || '';
    this.clanm = data.clanm || '';
    this.laborarea = data.laborarea || '';
    this.telnum = data.telnum || '';
    this.rtclafi = data.rtclafi || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.rzipcd = data.rzipcd || '';
    this.rzipadres = data.rzipadres || '';
    this.radres = data.radres || '';
    this.zipcd = data.zipcd || '';
    this.zipadres = data.zipadres || '';
    this.adres = data.adres || '';
    this.stmanpwr = data.stmanpwr || '';
    this.ssdate = data.ssdate || '';
    this.resiclafi = data.resiclafi || '';
    this.attcnt = data.attcnt || 0;
    this.foreryn = data.foreryn || '';
    this.forerrnum = data.forerrnum || '';
    this.mrgyn = data.mrgyn || '';
    this.mrgdate = data.mrgdate || '';
    this.mateyn = data.mateyn || '';
    this.resyn = data.resyn || '';
    this.resnum = data.resnum || '';
    this.disperyn = data.disperyn || '';
    this.dispernum = data.dispernum || '';
    this.wohldr = data.wohldr || '';
    this.childnum = data.childnum || '';
    this.fami60 = data.fami60 || '';
    this.fami20 = data.fami20 || '';
    this.overinco = data.overinco || '';
    this.bankcd = data.bankcd || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.picpath = data.picpath || '';
    this.email = data.email || '';
    this.pernme = data.pernme || '';
    this.pernmc = data.pernmc || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.stpcd = data.stpcd || '';
    this.tel = data.tel || '';
    this.handphone = data.handphone || '';
    this.payiden = data.payiden || '';
    this.gracd = data.gracd || '';
    this.mpclafi = data.mpclafi || '';
    this.groupcd = data.groupcd || '';
    this.marketcd = data.marketcd || '';
    this.badate = data.badate || '';
    this.remark = Fix.newline(data.remark) || '';
    this.gubun = data.gubun || '';
    this.grpflag = data.grpflag || '';
    this.groupid = data.groupid || '';
    this.grouppw = data.grouppw || '';
    this.officecd = data.officecd || '';
    this.retireflag = data.retireflag || '';
    this.retiredate = data.retiredate || '';
    this.overchk = data.overchk || '';
    this.concd = data.concd || '';
    this.hsflag = data.hsflag || '';
    this.reader = data.reader || '';
    this.insudate = data.insudate || '';
    this.inoutdate = data.inoutdate || '';
    this.readerid = data.readerid || '';
    this.readernm = data.readernm || '';
    this.cardflag = data.cardflag || '';
    this.jspjangcd = data.jspjangcd || '';
    this.overseq = data.overseq || '';
    this.oldzipcd = data.oldzipcd || '';
    this.oldrzipcd = data.oldrzipcd || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.rlat = data.rlat || '';
    this.rlng = data.rlng || '';
    this.rnumflag = data.rnumflag || '';
    this.old_rzipadres = data.old_rzipadres || '';
    this.old_zipadres = data.old_zipadres || '';
    this.zipadres = data.zipadres || '';
    this.noticeflag = data.noticeflag || '';
    this.office_zipcd = data.office_zipcd || '';
    this.office_address = data.office_address || '';
    this.office_address2 = data.office_address2 || '';
    this.office_lat = data.office_lat || '';
    this.office_lng = data.office_lng || '';
    this.auto_commute = data.auto_commute || '';
    this.talent = data.talent || '';
    this.payment = data.payment || '';
    this.pay_serallow = data.pay_serallow || '';
    this.notgps = data.notgps || '';
    this.retire = data.retire || '';
    this.pay_overflag = data.pay_overflag || '';
    this.yearly_flag = data.yearly_flag || '';
    this.yearly_default = data.yearly_default || '';
    this.old_office_zipcd = data.old_office_zipcd || '';
    this.old_office_address = data.old_office_address || '';
    this.old_office_address2 = data.old_office_address2 || '';
    this.actas_perid = data.actas_perid || '';
    this.dz_emp_cd = data.dz_emp_cd || '';
    this.new = isNew ? '1' : '0';
    this.chk = data.chk || '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
