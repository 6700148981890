import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import {
  SupplyStatement,
  SupplyStatementTabDataStateNames,
} from './SupplyStatement';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8 } from '../../../../utils/time';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TabView } from '../../../../components/layout/TabView';

/**
 * 메인화면
 * @window w_tb_da034w_01
 * @category 불출현황
 */
export const SupplyStatementTemplate: React.FC<TemplateProps<SupplyStatement>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={210}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={180}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={300}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="제품명, 제품규격으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={300}>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            현장
          </span>
        <TextBox
          weight={1}
          size={100}
          value={scope.state.actcd}
          textAlign="center"
          onChange={(value) => scope.setState({ actcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              scope.setState({
                actcd: item.cd,
                actnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={2}
          value={scope.state.actnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>담당자</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.pernm}
          weight={1.5}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <TabView
        tabs={scope.tabs}
        focused={scope.state.focusedTab}
        onChange={(tab) => scope.onTabChange(tab)}
      />
    </FlexLayout>
    <GridLayout
      header={scope.tabHeaders[scope.tabIndex]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      scope={scope}
      // @ts-ignore
      data={scope.state[SupplyStatementTabDataStateNames[scope.tabIndex]] || [] }
    />
  </FlexLayout>

);
