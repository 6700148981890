import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { MonthlyIncomeStatement } from '../MonthlyIncomeStatement';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { DepositPaymentGridHeader } from '../headers/DepositPaymentGridHeader';

/**
 * 화면 > 탭 2 입금/지급
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서
 */
export const DepositPaymentTemplate: React.FC<TemplateProps<MonthlyIncomeStatement>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridTab2}
      infinityHandler={scope.infinityTab2}
      header={DepositPaymentGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.depositPaymentData}
      headerHeight={68}
      onRowClick={(item) => scope.onTab2RowClickEvent(item)}
    />
  </FlexLayout>
);
