import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da038_01
 * @category 반납등록 (하단)
 */

export class ReturnDetailRegistrationDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly chqty: string;

  /**
   * 반납일자
   *
   * **data type** varchar(8)
   */
  readonly bandate: string;

  /**
   * 반납번호
   *
   * **data type** varchar(8)
   */
  readonly bannum: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly banseq: string;

  /**
   * 유무상
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   * 품목명
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   * 규격
   *
   * **data type** varchar(8)
   */
  readonly psize: string;

  /**
   * 단위
   *
   * **data type** varchar(8)
   */
  readonly punit: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;

  /**
   * 공급금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 불출일자
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly delnum: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly delqty: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly delseq: string;

  /**
   * 창고
   *
   * **data type** varchar(8)
   */
  readonly store: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.chqty = data.chqty || '';
    this.bandate = data.bandate || '';
    this.bannum = data.bannum || '';
    this.banseq = data.banseq || '';
    this.myn = data.myn || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.mamt = data.mamt || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.delseq = data.delseq || '';
    this.delqty = data.delqty || '';
    this.store = data.store || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
