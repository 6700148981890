import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca510
 * @category 공통코드등록
 */
export class PublicModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 대분류코드
   *
   * **data type** varchar(5)
   */
  com_cls: string;

  /**
   * 코드
   *
   * **data type** varchar(10)
   */
  com_code: string;

  /**
   * 대분류명칭
   *
   * **data type** varchar(100)
   */
  com_cnam: string;

  /**
   * 분류항목
   *
   * **data type** varchar(100)
   */
  com_rem1: string;

  /**
   * 분류항목2
   *
   * **data type** varchar(50)
   */
  com_rem2: string;

  /**
   * 분류항목순번
   *
   * **data type** int
   */
  com_order : string;

  /**
   * 코드커서제어
   *
   * **data type** varchar(2)
   */
  new : string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.com_cls = data.com_cls || '';
    this.com_code = data.com_code || '';
    this.com_cnam = data.com_cnam || '';
    this.com_rem1 = data.com_rem1 || '';
    this.com_rem2 = data.com_rem2 || '';
    this.com_order = data.com_order || '';
    this.new = data.new || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
