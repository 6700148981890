import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_tb_e521
 * @category 안전교육일지
 */

export class SafetyEducationModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly prtchk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly safedate: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly saferemark: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly safepernm: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate1: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate3: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate4: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate5: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly safedatetime: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly indivinm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly inrspnm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly r_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gs_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly sttime: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly endtime: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly newrow: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.prtchk = data.prtchk || '';
    this.safedate = data.safedate || '';
    this.title = data.title || '';
    this.saferemark = Fix.newline(data.saferemark) || '';
    this.safepernm = data.safepernm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.appdate1 = data.appdate1 || '';
    this.appdate2 = data.appdate2 || '';
    this.appdate3 = data.appdate3 || '';
    this.appdate4 = data.appdate4 || '';
    this.appdate5 = data.appdate5 || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.safedatetime = data.safedatetime || '';
    this.inpernm = data.inpernm || '';
    this.indivinm = data.indivinm || '';
    this.inrspnm = data.inrspnm || '';
    this.r_color = data.r_color || '';
    this.gs_color = data.gs_color || '';
    this.sttime = data.sttime || '';
    this.endtime = data.endtime || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.newrow = data.newrow || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
