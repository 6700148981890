import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ServerancePayRegisterTemplate } from './ServerancePayRegister.template';
import { ServerancePayRegisterModel } from './ServerancePayRegister.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface ServerancePayRegisterState {
  // search
  yearmon: string;
  perid: string;
  pernm: string;

  // data
  data: Array<ServerancePayRegisterModel>;
  focused?: ServerancePayRegisterModel;
  focusIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_pb301_01
 * @category 퇴직금등록
 */
export class ServerancePayRegister extends PageComponent<PageProps, ServerancePayRegisterState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    let year: string | number;

    today.getMonth() === 0 ? year = today.getFullYear() - 1
      : year = today.getFullYear(); // 년도

    let month: string | number;
    today.getMonth() === 0 ? month = 12
      : month = today.getMonth(); // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: `${year}${month}`,
      perid: '',

      // data
      data: [],
      focusIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        perid: this.state.perid,
      },
      (params) => (
        api.retrieve(params)
      ),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new ServerancePayRegisterModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 1);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['seq', 'perid'],
        [this.state.focused?.seq, this.state.focused?.perid], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update(true);
        await this.table.current?.setFocus(index, 1);
        this.onRowFocusEvent(this.state.data[0], 0);
      }
    });
    this.table.current?.update(true);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      stmon: this.state.yearmon,
      perid: this.state.perid,
      seq: this.state.focused?.seq,
    });

    data && this.setState({
      data: [
        new ServerancePayRegisterModel(data, true),
        ...this.state.data,
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `순서: ${this.state.focused?.seq} 사원명: ${this.state.focused?.pernm}`;
    await api.delete(text, {
      ...this.state.focused,
      stmon: this.state.focused?.mon,
    }) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onButtonEvent() {
    const { actionStore: api } = this.props;
    await api.excel({
      stmon: this.state.yearmon,
      perid: this.state.perid,
    });
  }

  @action
  async onBtnRetriveEvent() {
    const { actionStore: api } = this.props;

    if (!this.state.data?.length) {
      await Confirm.show('확인', '퇴직적립금 계산된 내역이 있습니다. 다시 계산하시겠습니까?', ConfirmType.QUESTION);
    } else {
      this.onRetrieveEvent();
    }

    const data = await api.fxExec('wb_refresh',
      {
        stmon: this.state.yearmon,
        perid: this.state.perid,
      });
    if (data?.items) {
      this.setState({
        data: data?.items,
      }, async () => {
        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 1);
      });
    }
    this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.yearmon,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      perid: this.state.perid,
    });
  }

  @action
  async onButtonExcel() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel('wb_excel', {
      stmon: this.state.yearmon,
      perid: this.state.perid,
    });
  }

  @action
  async onIntemChange(name: any, value: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_itemchanged', {
      itemname: name,
      data: value,
      mon: this.state.yearmon,
      perid: this.state.focused?.perid,
    });

    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      if (x.perid === this.state.focused?.perid) {
        this.updatedRows?.push({
          ...x,
          tot_samt: data?.tot_samt,
          retireamt: data?.retireamt,
          remark: data?.remark,
        });
        checkData.push({
          ...x,
          tot_samt: data?.tot_samt,
          retireamt: data?.retireamt,
          remark: data?.remark,
        });
      } else {
        checkData.push({
          ...x,
        });
      }
    });
    this.setState({
      data: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  onRowFocusEvent(item: ServerancePayRegisterModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<ServerancePayRegisterModel>, updatedRows: Array<ServerancePayRegisterModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <ServerancePayRegisterTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
