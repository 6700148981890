/**
 * 모델
 * @window w_xa011_input
 * @category 회사정보등록
 */
export class CustModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 회사명
   *
   * **data type** varchar(30)
   */
  custnm: string;

  /**
   * 패스워드
   *
   * **data type** varchar(8)
   */
  passwd: string;

  /**
   * 이메일주소
   *
   * **data type** varchar(30)
   */
  emailadres: string;

  /**
   * 이메일패스워드
   *
   * **data type** varchar(8)
   */
  emailpasswd: string;

  /**
   * 회사구분(법인, 개인)
   *
   * **data type** varchar(1)
   */
  custperclsf: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  genpayyn: string;

  /**
   * 모름
   *
   * **data type** varchar(7)
   */
  genpayapprnum: string;

  /**
   * 대표구분
   *
   * **data type** varchar(6)
   */
  agnerlta: string;

  /**
   * 대표자명
   *
   * **data type** varchar(30)
   */
  agnernm: string;

  /**
   * 회사번호
   *
   * **data type** varchar(30)
   */
  agnertel1: string;

  /**
   * 회사번호2
   *
   * **data type** varchar(30)
   */
  agnertel2: string;

  /**
   * 팩스
   *
   * **data type** varchar(30)
   */
  fax: string;

  /**
   * 모름
   *
   * **data type** float
   */
  ttlusenum: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  contdate: string;

  /**
   * 계약해지일
   *
   * **data type** varchar(8)
   */
  cacldate: string;

  /**
   * 사용유무
   *
   * **data type** varchar(8)
   */
  useyn: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  inptdate: string;

  /**
   * 등급
   *
   * **data type** varchar(100)
   */
  grade: string;

  /**
   * 메일
   *
   * **data type** varchar(100)
   */
  mail: string;

  /**
   * 사용자등급
   *
   * **data type** varchar(50)
   */
  user_grade: string;

  /**
   * 회사구분(내국, 외국)
   *
   * **data type** varchar(1)
   */
  nationyn: string;

  /**
   * 회사구분(중소, 일반)
   *
   * **data type** varchar(1)
   */
  busineyn: string;

  /**
   * 회사구분(영리, 비영리)
   *
   * **data type** varchar(1)
   */
  commeryn: string;

  /**
   * 메일구분
   *
   * **data type** varchar(1)
   */
  mailflag: string;

  /**
   * 국가승강기 정보센터 회사아이디
   *
   * **data type** varchar(50)
   */
  gosiid: string;

  /**
   * 국가승강기 정보센터 회사비번
   *
   * **data type** varchar(50)
   */
  gosipasswd: string;

  /**
   * 사원코드
   *
   * **data type** varchar(5)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(3)
   */
  pernm: string;

  /**
   * 모름
   *
   * **data type** text
   */
  e451text: string;

  /**
   * 견적서 하단 특이사항명
   *
   * **data type** text
   */
  e451remark: string;

  /**
   * 점검하단특이사항
   *
   * **data type** text
   */
  e035bottom: string;

  /**
   * 회사홈페이지
   *
   * **data type** varchar(255)
   */
  homepage: string;

  /**
   * 라이센스
   *
   * **data type** varchar(50)
   */
  license: string;

  /**
   * 회사문서코드
   *
   * **data type** varchar(10)
   */
  papernum: string;

  /**
   * 계약사항표기(보수팀)
   *
   * **data type** varchar(1)
   */
  contflag: string;

  /**
   * 금일계획전송시간
   *
   * **data type** varchar(4)
   */
  smstime: string;

  /**
   * 급여생성타입
   *
   * **data type** varchar(1)
   */
  paytype: string;

  /**
   * 고장알림타입(보수팀)
   *
   * **data type** varchar(1)
   */
  pushtype: string;

  /**
   * 장기미수매출생성제외
   *
   * **data type** varchar(1)
   */
  longmisskip: string;

  /**
   * 은행연동사용
   *
   * **data type** varchar(1)
   */
  bankda_flag: string;

  /**
   * 어플GPS사용
   *
   * **data type** varchar(1)
   */
  notgps: string;

  /**
   * GPS위치표시
   *
   * **data type** varchar(1)
   */
  allgps: string;

  /**
   * 제품최근단가적용
   *
   * **data type** varchar(1)
   */
  pcode_uamt: string;

  /**
   * 이메일 하단 소갯말
   *
   * **data type** varchar(255)
   */
  email_bottom: string;

  /**
   * 은행계좌연동중복체크
   *
   * **data type** varchar(1)
   */
  account_sw: string;

  /**
   * 회사구분별 점검전송
   *
   * **data type** varchar(1)
   */
  wkactcd_send: string;

  /**
   * 웹ERP접속유지시간
   *
   * **data type** float
   */
  authmin: string;

  /**
   * 더존관련
   *
   * **data type** ??
   */
  dz_flag: string;

  /**
   * 점검시간
   *
   * **data type** float
   */
  e035_time: string;

  /**
   * 모름
   *
   * **data type** float
   */
  stn: string;

  /**
   * 점검텀시간
   *
   * **data type** float
   */
  e035_traveltime: string;

  // 출퇴근 위치확인
  notgps_commute: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.custnm = data.custnm || '';
    this.passwd = data.passwd || '';
    this.emailadres = data.emailadres || '';
    this.emailpasswd = data.emailpasswd || '';
    this.custperclsf = data.custperclsf || '';
    this.genpayyn = data.genpayyn || '';
    this.genpayapprnum = data.genpayapprnum || '';
    this.agnerlta = data.agnerlta || '';
    this.agnernm = data.agnernm || '';
    this.agnertel1 = data.agnertel1 || '';
    this.agnertel2 = data.agnertel2 || '';
    this.fax = data.fax || '';
    this.ttlusenum = data.ttlusenum || '';
    this.contdate = data.contdate || '';
    this.cacldate = data.cacldate || '';
    this.useyn = data.useyn || '';
    this.inptdate = data.inptdate || '';
    this.grade = data.grade || '';
    this.mail = data.mail || '';
    this.user_grade = data.user_grade || '';
    this.nationyn = data.nationyn || '';
    this.busineyn = data.busineyn || '';
    this.commeryn = data.commeryn || '';
    this.mailflag = data.mailflag || '';
    this.gosiid = data.gosiid || '';
    this.gosipasswd = data.gosipasswd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.e451text = data.e451text || '';
    this.e451remark = data.e451remark || '';
    this.e035bottom = data.e035bottom || '';
    this.homepage = data.homepage || '';
    this.license = data.license || '';
    this.papernum = data.papernum || '';
    this.contflag = data.contflag || '';
    this.smstime = data.smstime || '';
    this.paytype = data.paytype || '';
    this.pushtype = data.pushtype || '';
    this.longmisskip = data.longmisskip || '';
    this.bankda_flag = data.bankda_flag || '';
    this.notgps = data.notgps || '';
    this.allgps = data.allgps || '';
    this.pcode_uamt = data.pcode_uamt || '';
    this.email_bottom = data.email_bottom || '';
    this.account_sw = data.account_sw || '';
    this.wkactcd_send = data.wkactcd_send || '';
    this.authmin = data.authmin || '';
    this.dz_flag = data.dz_flag || '';
    this.e035_time = data.e035_time || '';
    this.e035_traveltime = data.e035_traveltime || '';
    this.stn = data.stn || '';
    this.notgps_commute = data.notgps_commute || '0';
  }
}
