import * as React from 'react';
import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import style from './TabLayout.module.scss';
import { TabModel } from '../../../models';
import { FlexLayout } from '../FlexLayout';
import { Global, TabLayoutDataItem } from '../../../constants';
import { TabView } from '../TabView';

interface TabLayoutActions {
  onChange: (tab: TabModel, index: number) => any;
  onShouldChange?: (tab: TabModel, index: number) => boolean;
}

interface TabLayoutProps<C = any> extends TabLayoutActions {
  scope: C;
  tabs: Array<TabLayoutDataItem<C>>;
  footer?: ReactNode;
}

interface TabLayoutStates {
  focusedIndex: number;
}

@observer
export class TabLayout extends React.Component<TabLayoutProps, TabLayoutStates> {
  constructor(props: TabLayoutProps, context: any) {
    super(props, context);
    this.state = {
      focusedIndex: 0,
    };
  }

  componentDidMount() {
    const {
      tabs,
      onChange,
    } = this.props;

    if (tabs.length > 0) {
      const tab = tabs[0];
      this.setState({ focusedIndex: 0 });
      onChange && onChange(tab.tab, 0);
    }
  }

  selectTabById(id: string): boolean {
    const {
      tabs,
      onChange,
      onShouldChange,
    } = this.props;

    for (let i = 0; i < tabs.length; i += 1) {
      if (id === tabs[i].tab.id) {
        if (onShouldChange && !onShouldChange(tabs[i].tab, i)) {
          return false;
        }
        this.setState({ focusedIndex: i });
        onChange && onChange(tabs[i].tab, i);
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      tabs,
      onChange,
      onShouldChange,
      footer,
    } = this.props;

    return (
      <FlexLayout isVertical={true}>
        <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
          <FlexLayout>
            <TabView
              tabs={tabs.map((tab) => tab.tab)}
              focused={this.props?.tabs[this.state.focusedIndex || 0]?.tab}
              onChange={(tab, index) => {
                if (onShouldChange && !onShouldChange(tab, index)) {
                  return;
                }
                this.setState({ focusedIndex: index });
                onChange && onChange(tab, index);
              }}
            />
          </FlexLayout>

          {footer && <div
            className={style.footer}
          >
            {footer}
          </div>}
        </FlexLayout>

        <FlexLayout>
          {this.props.tabs.map((x, i) => (
            <div
              key={x.tab.id}
              className={style.screen}
              style={{
                zIndex: i === this.state.focusedIndex ? 1 : 0,
                opacity: i === this.state.focusedIndex ? 1 : 0,
                pointerEvents: i === this.state.focusedIndex ? 'auto' : 'none',
              }}
            >
              {x.template && x.template(this.props.scope)}
            </div>
          ))}
        </FlexLayout>
      </FlexLayout>
    );
  }
}
