import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdAttachMoney,
} from 'react-icons/md';
import {
  FaMoneyCheck,
  FaRegMoneyBillAlt,
  GiPayMoney,
  GiReceiveMoney,
  GrMoney,
} from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  SalesModel,
  GiveModel,
  CollectionModel,
  OderModel,
  AttachModel,
  ExpenseModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { ProjectTemplate } from './Project.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderCollection,
  TabHeaderExpense,
  TabHeaderGive,
  TabHeaderOrder,
  TabHeaderSales,
} from './tabs';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

export enum ProjectTabId {
  Expense,
  Give,
  Sales,
  Collection,
  Order,
  Attach,
}

export const ProjectTabTitles = [
  '경비사용내역',
  '지급내역',
  '매출내역',
  '수금내역',
  '발주내역',
  '첨부내역',
];

export const ProjectTabModels = [
  ExpenseModel,
  GiveModel,
  SalesModel,
  CollectionModel,
  OderModel,
  AttachModel,
];

export const ProjectTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
];

export const ProjectTabDataStateNames = [
  'expenses',
  'gives',
  'saless',
  'collections',
  'orders',
  'attachs',
];

export interface ProjectState {
  // 경비사용내역
  expenses?: Array<ExpenseModel>;

  // 지급내역
  gives?: Array<GiveModel>;

  // 매출내역
  saless?: Array<SalesModel>;

  // 수금내역
  collections?: Array<CollectionModel>;

  // 발주내역
  orders?: Array<OderModel>;
  orderFocused?: OderModel;
  ordersDetail: Array<OderModel>;

  // 첨부내역
  attachs?: Array<AttachModel>;

  // data
  focusedTab?: TabModel;
  data: Array<SalesModel>;
  focusIndex: number;
  focused?: SalesModel;
  rowData?: Array<any>;

  // search
  stdate: string;
  enddate: string;
  searchQuery: string;
  gubuns?: Array<SalesModel>;
  searchDateflag?: string;
  state?: string;
  proactcd?: string;
  acccds?: Array<any>;
  pushTotalCheck: boolean;

  // trail
  qty_tot: string;
  samt_tot: string;
  tamt_tot: string;
  mijamt_tot: string;
  camt_tot: string;
  ex_mijamt_tot: string;
  balamt_tot: string;
  misamt_tot: string;
  resuamt_tot: string;
  resupercnt_tot: string;
  resuamt2_tot: string;
  mamt_tot: string;
  iamt_tot: string;
  rcvamt_tot: string;
  addamt_tot: string;
  sndamt_tot: string;

  // tap
  tab_misamt_tot: string;
  tab_rcvamt_tot: string;
  tab_iamt_tot: string;
  tab_addamt_tot: string;
  tab_samt_tot: string;
  tab_mijamt_tot: string;
  tab_tamt_tot: string;
  tab_qty_tot: string;
  tab_sndamt_tot: string;
  tab_balamt_tot: string;
  tab_resuamt_tot: string;
  tab_mamt_tot2: string;
  tab_samt_tot2: string;
  tab_tamt_tot2: string;
}

/**
 * 컨트롤러
 * @window w_tb_da003_01w
 * @category 프로젝트현황
 */
export class Project extends PageComponent<PageProps, ProjectState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  grid3: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const id = ProjectTabId;
    const titles = ProjectTabTitles;

    this.tabs = [
      new TabModel(id.Expense.toString(), MdAttachMoney, titles[id.Expense]),
      new TabModel(id.Give.toString(), GiPayMoney, titles[id.Give]),
      new TabModel(id.Sales.toString(), GiReceiveMoney, titles[id.Sales]),
      new TabModel(id.Collection.toString(), GrMoney, titles[id.Collection]),
      new TabModel(id.Order.toString(), FaRegMoneyBillAlt, titles[id.Order]),
      new TabModel(id.Attach.toString(), FaMoneyCheck, titles[id.Attach]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: '197001',
      enddate: `${year}${month}`,
      searchDateflag: '1',
      searchQuery: pageParams.projno || '',
      state: '0',

      // data
      focusIndex: 0,
      data: [],
      ordersDetail: [],

      // trail
      camt_tot: '0',
      ex_mijamt_tot: '0',
      balamt_tot: '0',
      misamt_tot: '0',
      mijamt_tot: '0',
      qty_tot: '0',
      samt_tot: '0',
      tamt_tot: '0',
      resuamt_tot: '0',
      resupercnt_tot: '0',
      resuamt2_tot: '0',
      pushTotalCheck: true,

      // tab
      tab_misamt_tot: '0',
      tab_rcvamt_tot: '0',
      tab_iamt_tot: '0',
      tab_addamt_tot: '0',
      tab_samt_tot: '0',
      tab_mijamt_tot: '0',
      tab_tamt_tot: '0',
      tab_qty_tot: '0',
      tab_sndamt_tot: '0',
      tab_balamt_tot: '0',
      tab_resuamt_tot: '0',
      tab_mamt_tot2: '0',
      tab_samt_tot2: '0',
      tab_tamt_tot2: '0',
    };

    this.tabHeaders = [
      // 경비사용내역
      TabHeaderExpense,
      // 지급내역
      TabHeaderGive,
      // 매출내역
      TabHeaderSales,
      // 수금내역
      TabHeaderCollection,
      // 발주내역
      TabHeaderOrder,
    ];
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams.projno || this.state.searchQuery,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 하단탭의 계정과목
    const data2 = await api.dropdown('wf_dd_ac001');
    if (!data2) return;
    this.setState({ acccds: data2.items });

    // 구분
    const data3 = await api.dropdown('wf_da003_code');
    if (!data3) return;
    this.setState({ gubuns: data3.items }, async () => {
      await this.table.current?.update();
    });

    await this.onRetrieveEvent();
    this.onTabChange(this.tabs[ProjectTabId.Expense]);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[ProjectTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${ProjectTabFunctionNames[i]}_print`,
      {
        projno: this.state.focused?.projno,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${ProjectTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        dateflag: this.state.searchDateflag,
        as_nm: this.state.searchQuery,
        state: this.state.state,
        items: this.state.gubuns,
      },
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => {
      if (this.state.data.length) {
        this.onRowFocusEvent(this.state.focused, this.state.focusIndex);
      }
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const gubunData: any = [];
    this.state.gubuns?.forEach((x: any) => {
      if (x.chk === '1') {
        gubunData.push({
          ...x,
        });
      }
    });

    if (gubunData.length < 1) {
      ConfirmWarning.show('확인', '구분을 선택하세요!');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        dateflag: this.state.searchDateflag,
        as_nm: this.state.searchQuery,
        state: this.state.state,
        items: this.state.gubuns,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        if (items) {
          this.setState({
            data: [...this.state.data, ...items],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          data: [],
          [ProjectTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      data: [],
      [ProjectTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['projno', 'orddate'],
        [this.state.focused?.projno, this.state.focused?.orddate], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(0);
      }
      this.SS({
        camt_tot: this.infinity?.box?.camt_tot || '0',
        ex_mijamt_tot: this.infinity?.box?.ex_mijamt_tot || '0',
        balamt_tot: this.infinity?.box?.balamt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
        qty_tot: this.infinity?.box?.qty_tot || '0',
        samt_tot: this.infinity?.box?.samt_tot || '0',
        tamt_tot: this.infinity?.box?.tamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
        resupercnt_tot: this.infinity?.box?.resupercnt_tot || '0',
        resuamt2_tot: this.infinity?.box?.resuamt2_tot || '0',
      });
    });
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    const { actionStore: api } = this.props;
    if (!this.state.data.length) {
      return;
    }
    const i = this.tabIndex;
    this.updatedRows = [];

    await this.setState({
      focused: item,
      focusIndex: index,
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        {
          projno: item?.projno,
          proactcd: item?.actgubun === '1' ? item?.projno : '%',
        },
        (params) => api.fxExec(`tab_${i + 1}_dw_1_RowFocuschanged`, params),
        (items) => {
          if (items) {
            // @ts-ignore
            this.SS({
              [ProjectTabDataStateNames[i]]: [
                // @ts-ignore
                ...this.state[ProjectTabDataStateNames[i]],
                ...items.map((x: any) => new ProjectTabModels[i](x)),
              ],
            });
          }
        },
        async () => {
          // @ts-ignore
          await this.SS({
            [ProjectTabDataStateNames[i]]: [],
            ordersDetail: [],
          });

          await this.infinity2?.retrieveAll();
          // @ts-ignore
          if (this.state[ProjectTabDataStateNames[i]] && this.state[ProjectTabDataStateNames[i]]?.length > 0) {
            await this.grid2.current?.setFocus(0);
          }
        },
      );
      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      // @ts-ignore
      this.setState({
        [ProjectTabDataStateNames[i]]: [],
        ordersDetail: [],
      }, async () => {
        const dataDate = await this.infinity2?.retrieveAll();

        this.SS({
          tab_misamt_tot: this.infinity2?.box?.misamt_tot || '0',
          tab_rcvamt_tot: this.infinity2?.box?.rcvamt_tot || '0',
          tab_iamt_tot: this.infinity2?.box?.iamt_tot || '0',
          tab_addamt_tot: this.infinity2?.box?.addamt_tot || '0',
          tab_samt_tot: this.infinity2?.box?.samt_tot || '0',
          tab_mijamt_tot: this.infinity2?.box?.mijamt_tot || '0',
          tab_tamt_tot: this.infinity2?.box?.tamt_tot || '0',
          tab_qty_tot: this.infinity2?.box?.qty_tot || '0',
          tab_sndamt_tot: this.infinity2?.box?.sndamt_tot || '0',
          tab_balamt_tot: this.infinity2?.box?.balamt_tot || '0',
          tab_resuamt_tot: this.infinity2?.box?.resuamt_tot || '0',
        });

        if (i + 1 === 5 && dataDate.items?.length > 0) {
          this.onRowFocusEvent2(dataDate.items[0]);
        }
      });
    });
  }

  @action
  async onRowFocusEvent2(item: any) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity3 = new InfinityRetrieve(
      {
        projno: item?.projno,
        proactcd: this.state.focused?.actgubun === '1' ? item?.projno : '%',
        cltcd: item?.cltcd,
      },
      (params) => api.fxExec(
        'tab_5_dw_6_RowFocuschanged',
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            ordersDetail: [...this.state.ordersDetail, ...items],
          });
        }
      },
      async () => {
        await this.SS({
          ordersDetail: [],
        });

        await this.infinity3?.retrieveAll();
        if (this.state.ordersDetail && this.state.ordersDetail?.length > 0) {
          await this.grid3.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      ordersDetail: [],
    }, async () => {
      const dataDate = await this.infinity3?.retrieveAll();

      this.SS({
        tab_mamt_tot2: dataDate?.mamt_tot || '0',
        tab_samt_tot2: dataDate?.samt_tot || '0',
        tab_tamt_tot2: dataDate?.tamt_tot || '0',
      });
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];

    this.state.gubuns?.forEach((x: any) => {
      checkData.push(({
        ...x,
        chk: checked ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      gubuns: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  // eslint-disable-next-line consistent-return
  async itemchanged(item: any) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex + 1;

    const data = await api.fxExec(
      `tab_${i}_dw_1_itemchanged`,
      {
        itemname: 'state',
        data: item,
        projno: this.state.focused?.projno,
      },
    );

    if (data) {
      ConfirmWarning.show('저장', '저장되었습니다.');
      return this.onRetrieveEvent();
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ rowData: rows });
  }

  @action
  onUpdatedRows2(rows: any, updatedRows: any) {
    this.updatedRows2 = updatedRows;
    this.setState({ gubuns: rows });
  }

  @action
  async onButtonExcel() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex + 1;

    // @ts-ignore
    if ((this.state[ProjectTabDataStateNames[i]]?.length || 0) < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(`tab_${i}_wb_2excel`, {
      projno: this.state.focused?.actgubun === '1' ? this.state.focused?.projno2 : this.state.focused?.projno,
      proactcd: this.state.focused?.actgubun === '1' ? this.state.focused?.projno : '%',
      cltcd: this.state.orderFocused?.cltcd || '',
    });
  }

  @action
  onClickOpenBalEnrollment() {
    this.props.publicStore.go(
      '/w_tb_da003_01',
      {
        searchQuery: this.state.focused?.projno,
      },
    );
  }

  @computed
  get tabIndex(): ProjectTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <ProjectTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
