import { TextBox } from '../../../../../components';
import { GridLayoutHeader } from '../../../../../constants';
import { InfoModel } from '../models';
import { ElevatorInfo } from '../ElevatorInfo';


// 담당자이력정보
const Info: GridLayoutHeader<InfoModel, ElevatorInfo>[] = [
  {
    id: 'actcd',
    text: '현장코드',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.actcd}
        textAlign="center"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 100,
    render: (x) => (
      <TextBox
        value={x.actnm}
        textAlign="center"
      />
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.equpnm}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'elno',
    text: '승강기번호',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.elno}
        transparent={true}
        textAlign="center"
      />
    ),
  },
];

export default Info;
