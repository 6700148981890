import {
  CheckBox,
  FlexLayout,
  FormatNumberTextBox,
  TextBox,
} from '../../../../../components';
import { TLHF } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 > 테이블 - 부품내역등록
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTabMaterialsTableHeader: TLHF<any, Enrollment> = (scope) => ([
  {
    id: 'pcode',
    text: '품번',
    width: 150,
    color: 'var(--color-red)',
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pcode}
        textAlign="center"
        onDoubleClick={async () => {
          const result = await scope.props.modalStore.openProductSelector(x.pcode);
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
          });
        }}
        onChange={(value) => rowUpdate({ pcode: value })}
        onEnter={async (value) => {
          const result = await scope.props.modalStore.openProductSelector(value);
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
          });
        }}
      />
    ),
  },
  {
    id: 'pname',
    text: '품명',
    width: 350,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pname}
        onChange={(value) => rowUpdate({ pname: value })}
      />
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 350,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.psize}
        onChange={(value) => rowUpdate({ psize: value })}
      />
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 50,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.punit}
        onChange={(value) => rowUpdate({ punit: value })}
      />
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.myn === '1'}
        onChange={(value) => rowUpdate({ myn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 80,
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 150,
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, true)}
      />
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 150,
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumTamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.tamt}
        onChange={(value) => rowUpdate({ tamt: value })}
      />
    ),
  },
  {
    id: 'mamt',
    text: '합계금액',
    width: 150,
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumMamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.mamt}
        onChange={(value) => rowUpdate({ mamt: value })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 500,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
]);
