import { computed } from 'mobx';

/**
 * 상세모델
 * @window w_tb_e063
 * @category 업무결재라인등록
 */

export class EnrollDetailModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly papercd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly no: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly sort: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly kcperid: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly kcpernm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly kcchk: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.papercd = data.papercd || '';
    this.no = data.no || '';
    this.seq = data.seq || '';
    this.sort = data.sort || '';
    this.kcperid = data.kcperid || '';
    this.kcpernm = data.kcpernm || '';
    this.gubun = data.gubun || '';
    this.remark = data.remark || '';
    this.kcchk = data.kcchk || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
