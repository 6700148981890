import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w
 * @category 고장처리
 */

export class BreakSituationProcessorModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(3)
   */
  readonly equpnm: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   * 접수일자
   *
   * **data type** varchar(15)
   */
  readonly recedate: string;

  /**
   * 접수시간
   *
   * **data type** varchar(100)
   */
  readonly recetime: string;

  /**
   * 도착일자
   *
   * **data type** varchar(10)
   */
  readonly arrivdate: string;

  /**
   * 도착시간
   *
   * **data type** varchar(100)
   */
  readonly arrivtime: string;

  /**
   * 완료일자
   *
   * **data type** varchar(3)
   */
  readonly compdate: string;

  /**
   * 완료시간
   *
   * **data type** varchar(3)
   */
  readonly comptime: string;

  /**
   * 대응시간
   *
   * **data type** varchar(100)
   */
  readonly resutime: string;

  /**
   * 처리시간
   *
   * **data type** varchar(0)
   */
  readonly resulttime: string;

  /**
   * 사람갇힘
   *
   * **data type** varchar(1)
   */
  readonly trouble: string;

  /**
   * 고장내용
   *
   * **data type** varchar(?)
   */
  readonly contnm: string;

  /**
   * 고장내용상세
   *
   * **data type** varchar(?)
   */
  readonly contremark: string;

  /**
   * 처리내용
   *
   * **data type** varchar(8)
   */
  readonly resunm: string;

  /**
   * 처리내용상세
   *
   * **data type** varchar(8)
   */
  readonly resuremark: string;

  /**
   * 고장요인
   *
   * **data type** varchar(10)
   */
  readonly remonm: string;

  /**
   * 고장요인상세
   *
   * **data type** varchar(8)
   */
  readonly remoremark: string;

  /**
   * 고장원인
   *
   * **data type** varchar(4)
   */
  readonly facnm: string;

  /**
   * 고장부위
   *
   * **data type** varchar(8)
   */
  readonly greginm: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar(4)
   */
  readonly reginm: string;

  /**
   * 처리방법
   *
   * **data type** varchar(4)
   */
  readonly resultnm: string;

  /**
   * 처리부서명
   *
   * **data type** varchar(?)
   */
  readonly divinm: string;

  /**
   *  담당자
   *
   * **data type** varchar(?)
   */
  readonly actpernm: string;

  /**
   *  처리자
   *
   * **data type** varchar(?)
   */
  readonly pernm: string;

  /**
   *  접수자
   *
   * **data type** varchar(?)
   */
  readonly repernm: string;

  /**
   *  처리
   *
   * **data type** varchar(?)
   */
  readonly seqnm: string;

  /**
   *  처리자 2
   *
   * **data type** varchar(?)
   */
  readonly pernm2: string;

  /**
   *  배정자
   *
   * **data type** varchar(?)
   */
  readonly recepernm: string;

  /**
   *  배정자
   *
   * **data type** varchar(?)
   */
  readonly rececnt_tot: string;

  /**
   *  설치년도
   *
   * **data type** varchar(?)
   */
  readonly reyyyymm: string;

  /**
   *  계약구분
   *
   * **data type** varchar(?)
   */
  readonly contgubun: string;

  /**
   *  다발고장
   *
   * **data type** varchar(?)
   */
  readonly wadcnt: string;

  /**
   *  동일고장
   *
   * **data type** varchar(?)
   */
  readonly lastcnt: string;

  /**
   *  순번
   *
   * **data type** varchar(?)
   */
  readonly seq: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.arrivdate = data.arrivdate || '';
    this.actcd = data.actcd || '';
    this.equpcd = data.equpcd || '';
    this.arrivtime = data.arrivtime || '';
    this.recedate = data.recedate || '';
    this.compdate = data.compdate || '';
    this.comptime = data.comptime || '';
    this.resutime = data.resutime || '';
    this.resulttime = data.resulttime || '';
    this.trouble = data.trouble || '';
    this.contremark = data.contremark || '';
    this.resunm = data.resunm || '';
    this.resuremark = data.resuremark || '';
    this.remonm = data.remonm || '';
    this.remoremark = data.remoremark || '';
    this.facnm = data.facnm || '';
    this.greginm = data.greginm || '';
    this.reginm = data.reginm || '';
    this.resultnm = data.resultnm || '';
    this.divinm = data.divinm || '';
    this.equpnm = data.equpnm || '';
    this.recetime = data.recetime || '';
    this.actpernm = data.actpernm || '';
    this.equpnm = data.equpnm || '';
    this.contnm = data.contnm || '';
    this.repernm = data.repernm || '';
    this.pernm = data.pernm || '';
    this.seqnm = data.seqnm || '';
    this.seq = data.seq || '';
    this.pernm2 = data.pernm2 || '';
    this.recepernm = data.recepernm || '';
    this.rececnt_tot = data.rececnt_tot || '';
    this.reyyyymm = data.reyyyymm || '';
    this.contgubun = data.contgubun || '';
    this.wadcnt = data.wadcnt || '';
    this.lastcnt = data.lastcnt || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
