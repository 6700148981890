import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { GiveState } from './GiveState';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';

/**
 * 화면
 * @window w_tb_ca642w_01
 * @category 지급현황
 */
export const GiveStateTemplate: React.FC<TemplateProps<GiveState>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={400}>
          <span style={{ color: 'var(--color-red)' }}>거래처</span>
          <TextBox
            textAlign="center"
            value={scope.state.cltcd}
            onChange={(value) => setData({ cltcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_XCLIENT',
              { uv_arg1: '1' },
              true,
              (item) => {
                setData({
                  cltcd: item.cd,
                  cltnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.cltnm}
            readonly={true}
            onChange={(value) => setData({ cltnm: value })}
          />
        </FlexLayout>

        <FlexLayout
          style={{ marginLeft: 28 }}
          size={400}
        >
          <span style={{ color: 'var(--color-red)' }}>현장</span>
          <TextBox
            textAlign="center"
            value={scope.state.actcd}
            onChange={(value) => setData({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setData({
                  actcd: item.cd,
                  actnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.actnm}
            readonly={true}
            onChange={(value) => setData({ actnm: value })}
          />
        </FlexLayout>
      </SearchLayout>

      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout
          style={{ marginLeft: 11 }}
          size={346}
        >
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout
          style={{ marginLeft: 25 }}
          size={399}
        >
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 적요로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={427}>
          <span style={{ color: 'var(--color-red)' }}>프로젝트</span>
          <TextBox
            textAlign="center"
            value={scope.state.projno}
            onChange={(value) => setData({ projno: value })}
            bindSearchModal={new SearchBindingProjects(
              scope.props.modalStore,
              {},
              true,
              (item) => {
                setData({
                  projno: item.projno,
                  projnm: item.projnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope?.state.projnm}
            onChange={(value) => setData({ projnm: value })}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout size={200}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'cnam',
                text: '비용항목그룹',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      value={scope.state.allChk}
                      onChange={(value) => scope.onCehckAll(value)}
                    >
                    </CheckBox>
                  </FlexLayout>
                ),
                width: 60,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    >
                    </CheckBox>
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            data={scope.state.leftData}
          >
          </TableLayout>
        </FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'snddate',
              text: '지급일자',
              width: 6,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.snddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'sndnum',
              text: '번호',
              width: 5,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  건수
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 20,
              trail: (_) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {Format.number(scope?.state.total)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'sndamt',
              text: '지급액',
              width: 7,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.sndamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '지급구분',
              width: 5,
            },
            {
              id: 'banknm',
              text: '은행명',
              width: 7,
            },
            {
              id: 'accnum',
              text: '계좌번호',
              width: 10,
            },
            {
              id: 'accnm',
              text: '계정명',
              width: 7,
            },
            {
              id: 'acc_spdate',
              text: '전표일자',
              width: 6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.acc_spdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'acc_spnum',
              text: '번호',
              width: 7,
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
