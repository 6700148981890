import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca602
 * @category 재고실사등록
 */

export class WalkDownModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly xqty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly iqty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly cqty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly bqty: string;

  /**
   * 일련번호
   *
   * **data type** varchar(255)
   */
  readonly remarks: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.xqty = data.xqty || '';
    this.iqty = data.iqty || '';
    this.cqty = data.cqty || '';
    this.bqty = data.bqty || '';
    this.remarks = data.remarks || '';
    this.remark = data.remark || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
