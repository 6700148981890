import { computed } from 'mobx';

/**
 * 모델
 * @window w_input_da026_all
 * @category 입금현황
 */
export class DepositInputDetail2Model {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 번호
   *
   * **data type** varchar(13)
   */
  misnum: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 적요
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * 입금액
   *
   * **data type** char(8)
   */
  iamt: string;

  /**
   * 예금
   *
   * **data type** varchar(8)
   */
  bamt: string;

  /**
   * 지로입금
   *
   * **data type** varchar(5)
   */
  jamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 현금
   *
   * **data type** varchar(3)
   */
  hamt: string;

  /**
   * 카드
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드수수료
   *
   * **data type** varchar(13)
   */
  dcamt: string;

  /**
   * 어음
   *
   * **data type** varchar(1)
   */
  eamt: string;

  /**
   * 수표
   *
   * **data type** varchar(8)
   */
  samt: string;

  /**
   * 기타
   *
   * **data type** varchar(16)
   */
  gamt: string;

  /**
   * 전표일자
   *
   * **data type** float
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  acc_spnum: string;

  /**
   * 고객명
   *
   * **data type** varchar(8)
   */
  cltcd: string;

  /**
   * 입금일자
   *
   * **data type** varchar(50)
   */
  rcvdate: string;

  /**
   * 입금번호
   *
   * **data type** float
   */
  rcvnum: string;

  /**
   * -
   *
   * **data type** number
   */
  rcvseq: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.rcvseq = data.rcvseq || '';
    this.spjangcd = data.spjangcd || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.hamt = data.hamt || '';
    this.cltcd = data.cltcd || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvnum = data.rcvnum || '';
    this.eamt = data.eamt || '';
    this.bamt = data.bamt || '';
    this.damt = data.damt || '';
    this.gamt = data.gamt || '';
    this.jamt = data.jamt || '';
    this.jmar = data.jmar || '';
    this.dcamt = data.dcamt || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.remark = data.remark || '';
    this.iamt = data.iamt || '';
    this.samt = data.samt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
