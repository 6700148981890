import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
  MdSentimentVeryDissatisfied,
  MdBusiness,
} from 'react-icons/md';
import {
  Category,
  ConfirmType,
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import {
  ExpenseModel,
  ImportModel,
  CashModel,
  DepositModel,
  MainModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout, TableLayout } from '../../../../components';
import { OneDayDeadlineTemplate } from './OneDayDeadline.template';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import {
  TabHeaderCash,
  TabHeaderDeposit,
  TabHeaderExpense,
  TabHeaderImport,
} from './tabs';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

export enum OneDayDeadlineTabId {
  Import,
  Expense,
  Deposit,
  Cash,
}

export const OneDayDeadlineTabTitles = [
  '수입내역',
  '지출내역',
  '예금내역',
  '현금내역',
];

export const OneDayDeadlineTabModels = [
  ImportModel,
  ExpenseModel,
  DepositModel,
  CashModel,
];

export const OneDayDeadlineTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
];

export const OneDayDeadlineTabDataStateNames = [
  'imports',
  'expenses',
  'deposits',
  'cashs',
];

export interface OneDayDeadlineState {
  // 수입내역
  imports?: Array<ImportModel>;

  // 지출내역
  expenses?: Array<ExpenseModel>;

  // 예금내역
  deposits?: Array<DepositModel>;

  // 현금내역
  cashs?: Array<CashModel>;

  focusedTab?: TabModel;
  data: Array<MainModel>;
  focused?: MainModel;
  openChk?: Array<any>;
  yearmon: string;
  pushTotalCheck: boolean;
  focusIndex: number;
  isReported: boolean; // 결재상신

  // trail
  rcvamt_tot: string;
  sndamt_tot: string;
  total: string;
  iamt_tot: string;
  misamt_tot: string;
  mijamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_11
 * @category 일일마감
 */
export class OneDayDeadline extends PageComponent<PageProps, OneDayDeadlineState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = OneDayDeadlineTabId;
    const titles = OneDayDeadlineTabTitles;

    this.tabs = [
      new TabModel(id.Import.toString(), MdReorder, titles[id.Import]),
      new TabModel(id.Expense.toString(), MdPublish, titles[id.Expense]),
      new TabModel(id.Deposit.toString(), MdSentimentVeryDissatisfied, titles[id.Deposit]),
      new TabModel(id.Cash.toString(), MdBusiness, titles[id.Cash]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      data: [],
      isReported: false,
      focusIndex: 0,
      pushTotalCheck: true,

      // trail
      rcvamt_tot: '0',
      sndamt_tot: '0',
      total: '0',
      iamt_tot: '0',
      misamt_tot: '0',
      mijamt_tot: '0',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 수입내역
      TabHeaderImport,
      // 지출내역
      TabHeaderExpense,
      // 예금내역
      TabHeaderDeposit,
      // 현금내역
      TabHeaderCash,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_spjangcd_code', { year: Today.year() });

    if (!data) return;

    this.setState({ openChk: data.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });

    this.onRetrieveEvent();
    await this.onTabChange(this.tabs[OneDayDeadlineTabId.Import]);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => {
      if (this.state.data.length) {
        this.onRowFocusEvent(this.state.focused, this.state.focusIndex);
      }
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        items: this.state.openChk,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MainModel(x, false)),
          ],
        }, next);
      },
      async () => {
        // @ts-ignore
        await this.SS({
          data: [],
          [OneDayDeadlineTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieveAll();
        this.state.data && this.state.data?.length > 0 && await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      data: [],
      [OneDayDeadlineTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['beamt', 'to_amt'],
        [this.state.focused?.beamt, this.state.focused?.to_amt], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
      this.SS({
        rcvamt_tot: this.infinity?.box?.rcvamt_tot || '0',
        sndamt_tot: this.infinity?.box?.sndamt_tot || '0',
      });
    });
  }

  @action
  async onRowFocusEvent(item: MainModel | undefined, index: number) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    this.updatedRows = [];

    await this.setState({
      focused: item,
      focusIndex: index,
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        {
          today: item?.today,
          items: this.state.openChk,
        },
        (params) => api.fxExec(`tab_${i + 1}_dw_1_RowFocuschanged`, params),
        (items) => {
          if (items) {
            // @ts-ignore
            this.setState({
              [OneDayDeadlineTabDataStateNames[i]]: [
                // @ts-ignore
                ...this.state[OneDayDeadlineTabDataStateNames[i]],
                ...items.map((x: any) => new OneDayDeadlineTabModels[i](x)),
              ],
            });
          }
        },
        async () => {
          // @ts-ignore
          await this.SS({
            [OneDayDeadlineTabDataStateNames[i]]: [],
          });
          await this.infinity2?.retrieveAll();
          // @ts-ignore
          if (this.state[OneDayDeadlineTabDataStateNames[i]] && this.state[OneDayDeadlineTabDataStateNames[i]]?.length > 0) {
            await this.grid2.current?.setFocus(0);
          }
        },
      );
      // 결재상신 여부확인
      if (this.state.focused?.appgubun === null
        || this.state.focused?.appgubun === ''
        || this.state.focused?.appgubun === '131') {
        this.setState({ isReported: false });
      } else {
        this.setState({ isReported: true });
      }
      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      // @ts-ignore
      this.setState({
        [OneDayDeadlineTabDataStateNames[i]]: [],
      }, async () => {
        const dataDate = await this.infinity2?.retrieveAll();
        // @ts-ignore
        if (this.state[OneDayDeadlineTabDataStateNames[i]] && this.state[OneDayDeadlineTabDataStateNames[i]]?.length > 0) {
          await this.grid2.current?.setFocus(0);
        }

        this.SS({
          total: dataDate?.total || '0',
          iamt_tot: dataDate?.iamt_tot || '0',
          misamt_tot: dataDate?.misamt_tot || '0',
          mijamt_tot: dataDate?.mijamt_tot || '0',
        });
      });
    });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ openChk: rows });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[OneDayDeadlineTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${OneDayDeadlineTabFunctionNames[i]}_print`,
      {
        today: this.state.focused?.today,
        beamt: this.state.focused?.beamt,
        rcvamt: this.state.focused?.rcvamt,
        sndamt: this.state.focused?.sndamt,
        to_bamt: this.state.focused?.to_bamt,
        to_hamt: this.state.focused?.to_hamt,
        to_gamt: this.state.focused?.to_gamt,
        items: this.state.openChk,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[OneDayDeadlineTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${OneDayDeadlineTabFunctionNames[i]}_excel`,
      {
        stmon: this.state.yearmon,
        items: this.state.openChk,
      },
    );
  }

  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 팝업
    if (await modalStore.openApprovalLine(PAPERCD.DAYMONEY)) {
      // 결재상신
      await api.exec(Category.ACCOUNTING, 'wb_appreport', {
        papercd: PAPERCD.DAYMONEY.toString(),
        appflag,
        ...this.state.focused,
        items: this.state.openChk,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck: checked,
      openChk: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @computed
  get tabIndex(): OneDayDeadlineTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <OneDayDeadlineTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
