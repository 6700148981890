import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { IncompleteBoxTemplate } from './IncompleteBox.template';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { IncompleteBoxModel, IncompleteBoxPopupModel } from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { FunctionRepository } from '../../../../repositories';
import { PageComponent } from '../../../../utils';
import { CompleteBoxPopupModel } from '../CompleteBox/models';

interface UnpaidBoxState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  papercd: string; // 문서구분
  perid: string;
  pernm: string;

  // 데이터 객체
  focusedIncompleteBox?: IncompleteBoxModel;
  incompleteBoxList: Array<IncompleteBoxModel>;
  data: IncompleteBoxModel;
  popupData: CompleteBoxPopupModel;
  pushTotalCheck: boolean;

  papercds: Array<any>;

  // 팝업
  remark: string;
  attcnt: number;
  isAttcnt: boolean;
  isReportModalVisible: boolean;
  isFileModalVisible: boolean;
  url?: string;
}

/*
 * 컨트롤러
 * @window w_tb_e080_list
 * @category 미결함
*/

export class IncompleteBox extends PageComponent<PageProps, UnpaidBoxState>
  implements PageToolEvents {
  updatedRows?: Array<IncompleteBoxModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const { user } = this.props.publicStore;

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    const lastDate = new Date(2019, month, 0).getDate();

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}0101`, // 기간(시작)
      enddate: `${year}${month}${lastDate}`, // 기간(끝)
      papercd: '%', // 문서구분
      searchQuery: '',
      perid: user.perid,
      pernm: user.pernm,
      data: new IncompleteBoxModel(),
      incompleteBoxList: [],
      popupData: new CompleteBoxPopupModel(),
      remark: '',
      attcnt: 0,
      isAttcnt: false,
      isEduperModalVisible: false,
      isFileModalVisible: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ca510_620_02');

    if (!data) return;

    this.setState({ papercds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    const data = await api.retrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        pernm: this.state.pernm,
        papercd: this.state.papercd,
      },
    );
    if (data?.items) {
      this.setState({
        data: new IncompleteBoxModel(data),
        incompleteBoxList: data?.items,
      });
    } else {
      this.setState({
        data: new IncompleteBoxModel(data),
        incompleteBoxList: [],
      });
    }
    this.table.current?.update();
    if (this.state.incompleteBoxList.length > 0) {
      await this.table.current?.setFocus(0);
    }

    // // 무한 스크롤바 헬퍼 초기화
    // this.infinity = new InfinityRetrieve(
    //   {
    //     stdate: this.state.stdate,
    //     enddate: this.state.enddate,
    //     as_nm: this.state.searchQuery,
    //     perid: this.state.perid,
    //     pernm: this.state.pernm,
    //     papercd: this.state.papercd,
    //   },
    //   (params) => api.retrieve(params),
    //   (items, next) => {
    //     this.setState({
    //       incompleteBoxList: [...this.state.incompleteBoxList, ...items],
    //     }, next);
    //   },
    // );
    //
    // // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // this.setState({
    //   incompleteBoxList: [],
    // }, async () => {
    //   await this.infinity?.retrieve(async () => {
    //     if (this.state.incompleteBoxList.length > 0) {
    //       await this.table.current?.setFocus(0);
    //       await this.table.current?.update();
    //     }
    //   });
    // });
  }

  @action
  onRowFocusEvent(item: IncompleteBoxModel) {
    this.setState({ focusedIncompleteBox: item });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.state.incompleteBoxList,
    }, this.state.focusedIncompleteBox?.new === '1')) {
      this.updatedRows = [];
      this.state.incompleteBoxList.length > 2
        ? ConfirmSuccess.show('저장', '일괄 저장되었습니다.') : ConfirmSuccess.show('저장', '저장되었습니다.');
    }
    await this.onRetrieveEvent();
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      incompleteBoxList: this.state.incompleteBoxList?.map((x) => new IncompleteBoxModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    }, () => this.table.current?.update(true));
  }

  @action
  onUpdatedRows(rows: Array<IncompleteBoxModel>, updatedRows: Array<IncompleteBoxModel>) {
    this.updatedRows = updatedRows;
    this.setState({ incompleteBoxList: rows });
  }

  @action
  async openReportModal() {
    const { user } = this.props.publicStore;
    const paper = this.state.focusedIncompleteBox;

    if (!paper) {
      ConfirmWarning.show('오류', '결재할 문서가 없습니다');
      return;
    }

    this.setState({
      isReportModalVisible: true,
      url: `https://api2.elmansoft.com/paper/ND${paper.papercd}/view?custcd=${user.custcd}&appnum=${paper.appnum}`,
    }, () => this.modalRetrieve());
  }

  @action
  async closeReportModal() {
    this.setState({
      isReportModalVisible: false,
    }, () => {
      this.updatedRows = [];
      this.onRetrieveEvent();
    });
  }

  @action
  async modalRetrieve() {
    const { actionStore: api, publicStore } = this.props;
    const { user } = publicStore;

    const data = await api.retrieve(
      {
        sub: 'w_popup_e080',
        papercd: this.state.focusedIncompleteBox?.papercd,
        appnum: this.state.focusedIncompleteBox?.appnum,
        perid: this.state.focusedIncompleteBox?.repoperid,
      },
    );

    // appremark 값 가져오기
    if (data) {
      const { data: result } = await FunctionRepository.request(
        false,
        'https://api.elmansoft.com/mail/getMail.php', {
          database: user.custcd,
          custcd: user.custcd,
          papercd: data.papercd,
          appnum: data.appnum,
        },
      );

      await this.SS({
        popupData: new IncompleteBoxPopupModel({
          ...data,
          remark: result?.appremark || '',
        }),
      });
    }
  }

  @action
  async modalApprove() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_101',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  async modalReject() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_131',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  async modalPrint() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager({
      sub: 'w_popup_e080',
      papercd: this.state.focusedIncompleteBox?.papercd,
      appnum: this.state.focusedIncompleteBox?.appnum,
      perid: this.state.focusedIncompleteBox?.repoperid,
    });
  }

  @action
  async modalArbitrary() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_301',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  async modalSubstitute() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_401',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  openFileModal() {
    this.setState({ isFileModalVisible: true },
      () => {

      });
  }

  @action
  closeFileModal() {
    this.setState({ isFileModalVisible: false },
      () => {

      });
  }

  render() {
    return (
      <IncompleteBoxTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            popupData: new CompleteBoxPopupModel({
              ...this.state.popupData,
              ...change,
            }, this.state.popupData.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
