import * as React from 'react';
import { action, computed } from 'mobx';
import update from 'react-addons-update';
import { PageProps, PageToolEvents, RowUpdate } from '../../../../constants';
import { CalendarLayoutDataModel, InfinityRetrieve } from '../../../../models';
import PlanModel from './models/PlanModel';
import { PlanTemplate } from './Plan.template';
import { PageComponent } from '../../../../utils';
import { Date6 } from '../../../../utils/time';
import { TableLayout } from '../../../../components';
import PlanActModel from './models/PlanActModel';
import PlanPerModel from './models/PlanPerModel';
import PlanEqupModel from './models/PlanEqupModel';
import { ConfirmWarning } from '../../../../utils/confirm';
import { FunctionRepository } from '../../../../repositories';

interface PlanState {
  stmon: string;
  perid: string;
  pernm: string;
  actcd: string;
  actnm: string;
  equpcd: string;
  equpnm: string;

  data: Array<CalendarLayoutDataModel<PlanModel>>;
  gubuns: Array<any>;

  isVisibleModal: boolean;
  modalDate?: string;
  dataAct: Array<PlanActModel>;
  dataPer: Array<PlanPerModel>;
  dataEqup: Array<PlanEqupModel>;
}

/**
 * 컨트롤러
 * @window w_tb_e470
 * @category 공사계획등록
 */
export class Plan extends PageComponent<PageProps, PlanState>
  implements PageToolEvents {
  infinityAct?: InfinityRetrieve;

  refAct: React.RefObject<TableLayout> = React.createRef();

  refPer: React.RefObject<TableLayout> = React.createRef();

  refEqup: React.RefObject<TableLayout> = React.createRef();

  focusActIndex: number = -1;

  focusPerIndex: number = -1;

  focusEqupIndex: number = -1;

  dayRemarks: Record<string, { g12: number, g13: number, g15: number }>;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.dayRemarks = {};
    this.state = props.state || {
      stmon: Date6.make(),
      perid: '',
      pernm: '',
      actcd: '',
      actnm: '',
      equpcd: '',
      equpnm: '',

      data: [],
      gubuns: [],

      isVisibleModal: false,
      dataAct: [],
      dataPer: [],
      dataEqup: [],
    };
  }


  @action
  async onFirstOpenEvent() {
    const { publicStore } = this.props;
    const { user } = publicStore;
    const { data } = await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/est/getDa020.php', {
        custcd: user.custcd,
        spjangcd: user.spjangcd,
        database: user.spjangcd,
      },
    );
    const { response } = data;
    await this.SS({
      gubuns: response.map((x: any) => ({
        value: x.artcd,
        remark: x.artnm,
        mini: x.artcd === '12' ? '공사' : x.artnm.substr(0, 2),
      })),
    });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    this.focusActIndex = -1;
    this.focusPerIndex = -1;
    this.focusEqupIndex = -1;

    this.setState({
      data: [],
    }, async () => {
      // 달력
      const { actionStore: api } = this.props;
      const data = await api.retrieve({
        stmon: this.state.stmon || Date6.make(),
        perid: this.state.perid || '%',
        actcd: this.state.actcd || '%',
        equpcd: this.state.equpcd || '%',
      });

      this.dayRemarks = {};
      data?.items?.forEach((x: any) => {
        if (!this.dayRemarks[x.plandate]) {
          this.dayRemarks[x.plandate] = { g12: 0, g13: 0, g15: 0 };
        }

        switch (x.gubun) {
          case '12': this.dayRemarks[x.plandate].g12 += 1; break;
          case '13': this.dayRemarks[x.plandate].g13 += 1; break;
          case '15': this.dayRemarks[x.plandate].g15 += 1; break;
        }
      });

      data?.items && this.setState({
        data: data.items.map((x: any) => (
          new CalendarLayoutDataModel(x.plandate, new PlanModel(x)))),
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stmon: this.state.stmon || Date6.make(),
      perid: this.state.perid || '%',
      actcd: this.state.actcd || '%',
      gubun: 'a4',
    });
  }


  // eslint-disable-next-line class-methods-use-this
  getItemColor(item: PlanModel): string {
    switch (item.gubun) {
      case '12': return 'var(--color-blue)';
      case '13': return 'var(--color-green)';
      case '15': return 'var(--color-red)';
      default: return 'var(--color-black)';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getItemPrefix(item: PlanModel): string {
    return this.state.gubuns.filter((x) => x.value === item.gubun)[0]?.mini;
  }


  @action
  async openModal(date: string) {
    const { actionStore: api } = this.props;

    await this.setState({
      dataAct: [],
      modalDate: date,
      isVisibleModal: true,
    });

    this.infinityAct = new InfinityRetrieve(
      {
        sub: 'w_popup_e470_callrender',
        date,
        perid: this.state.perid || '%',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          dataAct: [
            ...this.state.dataAct,
            ...items.map((x: any) => new PlanActModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataAct: [],
        });

        await this.infinityAct?.retrieve();
        if (this.state.dataAct && this.state.dataAct?.length > 0) {
          await this.refAct.current?.update(true);
          this.refAct.current?.setFocus(0, 1);
        }
      },
    );

    await this.infinityAct.retrieve();
    if (this.state.dataAct && this.state.dataAct?.length > 0) {
      await this.refAct.current?.update(true);
      this.refAct.current?.setFocus(0, 1);
      this.onActRowFocusEvent(this.state.dataAct[0], 0);
    }
  }

  @action
  async onModalSave() {
    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_e470_callrender',
      items: this.state.dataEqup,
      items2: this.state.dataPer,
    }, false)) {
      this.onRetrieveEvent();
    }
  }


  // 현장
  @action
  async onModalActAdd() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_dw_1_new', {
      sub: 'w_popup_e470_callrender',
      date: this.state.modalDate,
    });

    if (data) {
      const one = new PlanActModel(data, true);

      await this.SS({
        dataAct: [one, ...this.state.dataAct],
        dataPer: [],
        dataEqup: [],
      });

      await this.refAct.current?.update(false);
      await this.refPer.current?.update(false);
      await this.refEqup.current?.update(false);
      await this.refAct.current?.setFocus(0, 0);
    }
  }

  @action
  async onModalActRemove() {
    if (!ConfirmWarning.assert(this.focusAct, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.focusAct?.actnm}`;

    if (await api.fxDelete('wb_dw_1_delete', text, {
      sub: 'w_popup_e470_callrender',
      date: this.focusAct?.plandate,
      actcd: this.focusAct?.actcd,
    })) {
      await this.SS({
        dataAct: update(this.state.dataAct, {
          $splice: [[this.focusActIndex, 1]],
        }),
      });

      await this.refAct.current?.update(false);

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.dataAct.length) {
        const next = this.focusActIndex === 0 ? 0 : this.focusActIndex - 1;
        await this.refAct.current?.setFocus(next);
        this.onActRowFocusEvent(this.state.dataAct[next], next);
      } else {
        this.focusActIndex = -1;
        this.SS({ isVisibleModal: false });
        this.onRetrieveEvent();
      }
    }
  }

  @action
  async onActRowFocusEvent(row: PlanActModel, index: number) {
    this.focusActIndex = index;

    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'dw_1_RowFocuschanged',
      {
        sub: 'w_popup_e470_callrender',
        plandate: row.plandate,
        actcd: row.actcd,
      },
    );

    await this.SS({
      dataEqup: data?.items2?.map((x: any) => new PlanEqupModel(x)) || [],
      dataPer: data?.items3?.map((x: any) => new PlanPerModel(x)) || [],
    });

    await this.refPer.current?.update(true);
    if (this.state.dataPer.length > 0) {
      this.refPer.current?.setFocus(this.focusPerIndex, 0);
    }

    await this.refEqup.current?.update(true);
    if (this.state.dataEqup.length > 0) {
      this.refEqup.current?.setFocus(this.focusEqupIndex, 0);
    }
  }

  @action
  async actItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      ...this.state.dataAct[this.focusActIndex],
      [key]: value,
      itemname: key,
      data: value,
      sub: 'w_popup_e045_callrender',
    }, this.focusAct?.isNew);

    if (result) {
      rowUpdate(result);
    }
  }


  // 공사자
  @action
  async onModalPerAdd() {
    const { actionStore: api } = this.props;

    const seq = `000${parseInt(this.state.dataPer[this.state.dataPer.length - 1]?.seq || '000', 10) + 1}`
      .substr(-3);

    const data = await api.fxExec('wb_dw_3_new', {
      sub: 'w_popup_e470_callrender',
      date: this.state.modalDate,
      actcd: this.focusAct?.actcd,
      seq,
    });

    if (data) {
      const one = new PlanPerModel(data, true);

      await this.SS({
        dataPer: [one, ...this.state.dataPer],
      });

      await this.refPer.current?.update(false);
      await this.refPer.current?.setFocus(0, 0);
    }
  }

  @action
  async onModalPerRemove() {
    if (!ConfirmWarning.assert(this.focusPer, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.focusPer?.pernm}`;

    if (await api.fxDelete('wb_dw_3_delete', text, {
      sub: 'w_popup_e470_callrender',
      date: this.focusPer?.plandate,
      actcd: this.focusPer?.actcd,
      seq: this.focusPer?.seq,
    })) {
      await this.SS({
        dataPer: update(this.state.dataPer, {
          $splice: [[this.focusPerIndex, 1]],
        }),
      });

      await this.refPer.current?.update(false);

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.dataPer.length) {
        const next = this.focusPerIndex === 0 ? 0 : this.focusPerIndex - 1;
        await this.refPer.current?.setFocus(next);
      } else {
        this.focusPerIndex = -1;
      }
    }
  }

  onPerRowFocusEvent(_: PlanPerModel, index: number) {
    this.focusPerIndex = index;
  }


  // 호기
  @action
  async onModalEqupAdd() {
    const { actionStore: api } = this.props;

    const seq = `000${parseInt(this.state.dataEqup[this.state.dataEqup.length - 1]?.seq || '000', 10) + 1}`
      .substr(-3);

    const data = await api.fxExec('wb_dw_2_new', {
      sub: 'w_popup_e470_callrender',
      date: this.state.modalDate,
      actcd: this.focusAct?.actcd,
      actnm: this.focusAct?.actnm,
      seq,
    });

    if (data) {
      const one = new PlanEqupModel(data, true);

      await this.SS({
        dataEqup: [one, ...this.state.dataEqup],
      });

      await this.refEqup.current?.update(false);
      await this.refEqup.current?.setFocus(0, 0);
    }
  }

  @action
  async onModalEqupRemove() {
    if (!ConfirmWarning.assert(this.focusEqup, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.focusEqup?.equpnm}`;

    if (await api.fxDelete('wb_dw_2_delete', text, {
      sub: 'w_popup_e470_callrender',
      date: this.focusEqup?.plandate,
      actcd: this.focusEqup?.actcd,
      seq: this.focusEqup?.seq,
      perid: this.focusEqup?.perid,
    })) {
      await this.SS({
        dataEqup: update(this.state.dataEqup, {
          $splice: [[this.focusEqupIndex, 1]],
        }),
      });

      await this.refEqup.current?.update(false);

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.dataEqup.length) {
        const next = this.focusEqupIndex === 0 ? 0 : this.focusEqupIndex - 1;
        await this.refEqup.current?.setFocus(next);
      } else {
        this.focusEqupIndex = -1;
      }
    }
  }

  @action
  isDupEqup(equpcd: string): boolean {
    if (this.state.dataEqup.filter((x) => x.equpcd === equpcd).length > 0) {
      ConfirmWarning.show('오류', '이미 해당 호기가 등록되어 있습니다');
      return true;
    }
    return false;
  }

  onEqupRowFocusEvent(_: PlanEqupModel, index: number) {
    this.focusEqupIndex = index;
  }

  @computed
  get focusAct(): PlanActModel | undefined {
    if (this.focusActIndex < 0) return undefined;
    return this.state.dataAct[this.focusActIndex];
  }

  @computed
  get focusPer(): PlanPerModel | undefined {
    if (this.focusPerIndex < 0) return undefined;
    return this.state.dataPer[this.focusPerIndex];
  }

  @computed
  get focusEqup(): PlanEqupModel | undefined {
    if (this.focusEqupIndex < 0) return undefined;
    return this.state.dataEqup[this.focusEqupIndex];
  }


  render() {
    return <PlanTemplate
      scope={this}
    />;
  }
}
