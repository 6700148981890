import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  EducationPopupModel,
  GeneralJournalDetailModel,
  GeneralJournalModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { GeneralJournalTemplate } from './GeneralJournal.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Date8, Today } from '../../../../utils/time';
import { SchedulePopupModel } from './models/SchedulePopup.model';
import { PageComponent } from '../../../../utils';

export enum GeneralJournalItemChangeTypes {
  WKCD,
}

export enum GeneralJournalItemChangeTypeNames {
  wkcd,
}

const upflags = [
  { value: '001', color: '#000000' },
  { value: '002', color: '#5B1A86' },
  { value: '003', color: '#7F7A2C' },
  { value: '004', color: '#ED709B' },
  { value: '005', color: '#A77450' },
  { value: '006', color: '#881B20' },
  { value: '007', color: '#FAC96C' },
];

interface GeneralJournalState {

  // 검색조건
  stmon: string; // 기간
  perid: string; // 기간
  pernm: string

  // 데이터 객체

  // 테이블 포커싱 데이터
  focusedGeneralJournal?: GeneralJournalModel;
  focusedDetailGeneralJournal?: GeneralJournalDetailModel;
  focusedYesterdayGeneralJournal?: GeneralJournalDetailModel;

  // 레이블 박스 데이터
  data: GeneralJournalModel;

  // 테이블 데이터 리스트
  generalJournalList: Array<GeneralJournalModel>;
  detailList: Array<GeneralJournalDetailModel>;
  yesterdayList: Array<GeneralJournalDetailModel>;

  // 구분종류
  wkcds: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isEducationModalVisible: boolean; // 교육보고서
  popupEducationList: Array<EducationPopupModel>;
  focusedEducationPopup?: EducationPopupModel;

  isReported: boolean; // 결재상신 여부

  // 스케쥴 등록
  isScheduleMaintenanceModalVisible?: boolean;
  isScheduleGreetingModalVisible?: boolean;
  popupScheduleList?: Array<SchedulePopupModel>;
  schedulePopupDate: string;
  focusedSchedule?: SchedulePopupModel,
  popupPerid: string;
  popupPernm: string;

  total: string;
  total2: string;
  focusIndex: number;
}

/*
* 컨트롤러
* @window w_tb_e037_type1
* @category 개별업무일지[총무팀]
*/

export class GeneralJournal extends PageComponent<PageProps, GeneralJournalState>
  implements PageToolEvents {
  updatedRows?: Array<GeneralJournalDetailModel>;

  updatedRows2?: Array<SchedulePopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  popupScheduleTable: React.RefObject<TableLayout> = React.createRef();

  detailTable: React.RefObject<TableLayout> = React.createRef();

  updatedScheduleRows?: Array<SchedulePopupModel>;

  yesterdayGrid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  focusedDetailGeneralJournalIndex: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.focusedDetailGeneralJournalIndex = 0;

    const { user } = this.props.publicStore;

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      perid: user.perid,
      pernm: user.pernm,
      data: new GeneralJournalModel(),
      generalJournalList: [],
      detailList: [],
      yesterdayList: [],
      popupEducationList: [],
      popupScheduleList: [],

      focusedEducationPopup: '',
      focusedSchedulePopup: '',
      focusedDetailGeneralJournal: '',
      focusedYesterdayGeneralJournal: '',

      wkcds: [],
      isReported: false,

      total: '',
      focusIndex: 0,
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          sndflag: this.state.focusedGeneralJournal?.sndflag,
          rptdate: this.state.focusedGeneralJournal?.rptdate,
          perid: this.state.focusedGeneralJournal?.perid,
          appnum: this.state.focusedGeneralJournal?.appnum,
          appgubun: this.state.focusedGeneralJournal?.appgubun,
          title: this.state.focusedGeneralJournal?.title,
        }, false,
      );

      data && this.setState({
        data: new GeneralJournalModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }


  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.popupScheduleList?.findIndex((x) => x.plannum === item.plannum);
    let data = { new: undefined };
    const params = {
      itemname: GeneralJournalItemChangeTypeNames[type],
      data: '',
      new: this.state.focusedSchedule?.new,
      sub: 'w_popup_e501',
    };
    if (type === GeneralJournalItemChangeTypeNames.wkcd) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          ...params,
          data: item.wkcd,
        },
      );
    }

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedSchedule: new SchedulePopupModel({
        ...this.state.focusedSchedule,
      }, false),
      // eslint-disable-next-line max-len
      popupScheduleList: this.state.popupScheduleList?.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new SchedulePopupModel({ ...x, ...data }, data.new === '1') : new SchedulePopupModel(x)
      )),
    }, () => this.popupScheduleTable.current?.update(false));
  }

  // eslint-disable-next-line class-methods-use-this
  getItemColor(item: string): string {
    return upflags.filter((y) => y.value === item)[0]?.color;
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          generalJournalList: [...this.state.generalJournalList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ generalJournalList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      generalJournalList: [],
      detailList: [],
      yesterdayList: [],
      data: new GeneralJournalModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['perid', 'rptdate'],
        [this.state.focusedGeneralJournal?.perid, this.state.focusedGeneralJournal?.rptdate], type, true) || 0;
      if (this.state.generalJournalList && this.state.generalJournalList.length > index) {
        await this.grid.current?.setFocus(index);
      }
    });
    await this.detailTable.current?.update(true);
  }

  @action
  onRowFocusEvent(item: GeneralJournalModel, index: number) {
    if (item?.new === '1') {
      this.setState({
        isReported: false,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedGeneralJournal: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            rptdate: this.state.focusedGeneralJournal?.rptdate,
            perid: this.state.focusedGeneralJournal?.perid,
          });

        await data && this.setState({
          data: new GeneralJournalModel(data),
          total: data.total,
          total2: data.total2,
        });

        if (data?.items) {
          this.setState({
            detailList: data?.items,
          });
        } else {
          this.setState({
            detailList: [],
          });
        }

        if (data?.items2) {
          this.setState({
            yesterdayList: data?.items2,
          });
        } else {
          this.setState({
            yesterdayList: [],
          });
        }

        // 결재상신 여부확인
        if (this.state.focusedGeneralJournal?.appgubun === null
          || this.state.focusedGeneralJournal?.appgubun === ''
          || this.state.focusedGeneralJournal?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
        await this.detailTable.current?.update();
        this.detailTable.current?.setFocus(0, 0);
      },
    );
  }

  @action
  onDetailRowFocusEvent(item: GeneralJournalDetailModel, index: number) {
    this.focusedDetailGeneralJournalIndex = index;
    this.setState({ focusedDetailGeneralJournal: item });
  }

  @action
  onYesterdayRowFocusEvent(item: GeneralJournalDetailModel) {
    this.setState({ focusedYesterdayGeneralJournal: item });
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedGeneralJournal!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.JOURNAL, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedGeneralJournal!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.JOURNAL);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.JOURNAL.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.data.new,
      custcd: this.state.data.custcd,
      spjangcd: this.state.data.spjangcd,
      rptdate: this.state.data.rptdate,
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      divicd: this.state.data.divicd,
      divinm: this.state.data.divinm,
      rspcd: this.state.data.rspcd,
      rspnm: this.state.data.rspnm,
      rptremark: this.state.data.rptremark,
      overwk: this.state.data.overwk,
      upmuremark: this.state.data.upmuremark,
      endperid: this.state.data.endperid,
      endpernm: this.state.data.endpernm,
      endflag: this.state.data.endflag,
      endtext: this.state.data.endtext,
      chk: this.state.data.chk,
      stdate: this.state.data.stdate,
      sttime: this.state.data.sttime,
      enddate: this.state.data.enddate,
      endtime: this.state.data.endtime,
      carcd1: this.state.data.carcd1,
      carnum1: this.state.data.carnum1,
      soyou1: this.state.data.soyou1,
      model1: this.state.data.model1,
      gubun1: this.state.data.gubun1,
      carcd2: this.state.data.carcd2,
      carnum2: this.state.data.carnum2,
      soyou2: this.state.data.soyou2,
      model2: this.state.data.model2,
      gubun2: this.state.data.gubun2,
      sjik: this.state.data.sjik,
      djik: this.state.data.djik,
      syn: this.state.data.syn,
      gubun: this.state.data.gubun,
      appdate: this.state.data.appdate,
      apptime: this.state.data.apptime,
      appgubun: this.state.data.appgubun,
      appnum: this.state.data.appnum,
      appperid: this.state.data.appperid,
      appremark: this.state.data.appremark,
      picflag: this.state.data.picflag,
      refer: this.state.data.refer,
      samt1: this.state.data.samt1,
      samt2: this.state.data.samt2,
      samt: this.state.data.samt,
      attcnt: this.state.data.attcnt,
      type: '1',
      items: this.state.detailList,
    }, this.state.data.new === '1')) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new GeneralJournalModel(data, true);

      this.setState({
        data: newModel,
        generalJournalList: [
          newModel,
          ...this.state.generalJournalList,
        ],
        focusedGeneralJournal: newModel,
        detailList: [],
        yesterdayList: [],
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
        await this.detailTable.current?.update();
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          sndflag: this.state.focusedGeneralJournal?.sndflag,
          rptdate: this.state.focusedGeneralJournal?.rptdate,
          perid: this.state.focusedGeneralJournal?.perid,
          appnum: this.state.focusedGeneralJournal?.appnum,
          appgubun: this.state.focusedGeneralJournal?.appgubun,
          title: this.state.focusedGeneralJournal?.title,
        }, false);
    } else {
      // eslint-disable-next-line max-len
      const text = `작성일자: ${this.state.focusedGeneralJournal?.rptdate}를 모두 삭제하시겠습니까?`;
      await api.delete(text, {
        sndflag: this.state.focusedGeneralJournal?.sndflag,
        rptdate: this.state.focusedGeneralJournal?.rptdate,
        perid: this.state.focusedGeneralJournal?.perid,
        appnum: this.state.focusedGeneralJournal?.appnum,
        appgubun: this.state.focusedGeneralJournal?.appgubun,
        title: this.state.focusedGeneralJournal?.title,
      }) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onNewEvent2() {
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재된 문서입니다.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        rptdate: this.state.data.rptdate,
        rptnum: '',
      },
    );

    await this.SS({
      detailList: [
        ...this.state.detailList.map((x, i) => new GeneralJournalDetailModel({
          ...x,
          rptnum: `000${i + 1}`.substr(-3),
        }, x.isNew)),
        new GeneralJournalDetailModel({
          ...data,
          rptnum: `000${(this.state.detailList.length || 0) + 1}`.substr(-3),
          wkcd: this.state.detailList.length > this.focusedDetailGeneralJournalIndex
            ? this.state.detailList[this.focusedDetailGeneralJournalIndex].wkcd
            : '001',
        }, true),
      ],
    });

    await this.detailTable.current?.update(true);
    this.detailTable.current?.setFocus(this.state.detailList.length - 1, 2);
  }

  @action
  async onDeleteEvent2() {
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재된 문서입니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (this.state.detailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 항목이 없습니다.');
      return;
    }

    const text = `순번 : ${this.state.focusedDetailGeneralJournal?.rptnum}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        rptdate: this.state.focusedDetailGeneralJournal?.rptdate,
        perid: this.state.data?.perid,
        rptnum: this.state.focusedDetailGeneralJournal?.rptnum,
        appgubun: this.state.data.appgubun,
        wkcd: this.state.focusedDetailGeneralJournal?.wkcd,
        plandate: this.state.focusedDetailGeneralJournal?.plandate,
        actcd: this.state.focusedDetailGeneralJournal?.actcd,
        equpcd: this.state.focusedDetailGeneralJournal?.equpcd,
        lkdate: this.state.focusedDetailGeneralJournal?.lkdate,
        lknum: this.state.focusedDetailGeneralJournal?.lknum,
      },
    );

    const checkData: any = [];
    this.state.detailList?.forEach((x: any) => {
      if (this.state.focusedDetailGeneralJournal?.rptnum !== x.rptnum) {
        checkData.push({
          ...x,
        });
      }
    });

    this.setState({
      detailList: checkData,
    }, async () => {
      await this.detailTable.current?.update();
      this.onRetrieveEvent();
    });
  }


  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.rptdate, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      rptdate: this.state.data.rptdate,
      perid: this.state.perid,
    });
  }

  @action
  onRowFocusEventEducationModal(item:EducationPopupModel) {
    this.setState({ focusedEducationPopup: item });
  }

  @action
  async openEducationModal() {
    this.setState({
      isEducationModalVisible: true,
    }, () => this.modalEducationRetrieve());
  }

  @action
  async closeEducationModal() {
    this.setState({
      popupEducationList: [],
      isEducationModalVisible: false,
    });
  }

  @action
  async modalEducationRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_education',
      {
        rptdate: this.state.data.rptdate,
        perid: this.state.data.perid,
        edudate: this.state.data.edudate,
        edunum: this.state.data.edunum,
        title: this.state.data.title,
      }, false);

    if (data.items) {
      this.setState({
        popupEducationList: data.items?.map((x: any) => new EducationPopupModel(x)),
      }, async () => {
        await this.popupGrid.current?.setFocus(0);
      });
    } else {
      this.setState({
        popupEducationList: [],
      }, () => this.popupTable.current?.update());
    }
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async modalEducationSave() {
    if (!await Confirm.show('확인', '선택한 내역으로 교육받음 처리 하시겠습니까?', ConfirmType.QUESTION)) {
      return;
    }

    // 교육 불러오기 버튼 임시 보류
    ConfirmWarning.show('확인', '해당 기능 준비중입니다.');
    // return;
    //
    // const { actionStore: api } = this.props;
    // if (this.state.popupEducationList?.length! < 1) {
    //   await this.setState({ isEducationModalVisible: false });
    //   return;
    // }
    //
    // const data = await api.fxExec('save',
    //   {
    //     sub: 'w_popup_pb404_perid',
    //     items: this.state.popupEducationList,
    //   });
    //
    // if (data) {
    //   this.closeEducationModal();
    // }
  }

  @action
  onScheduleRowFocusEvent(item: SchedulePopupModel) {
    this.setState({ focusedSchedule: item });
  }


  @action
  async openScheduleModal() {
    // 익일 계획
    const date = new Date(Date8.withDash(this.state.data.rptdate));
    date.setDate(date.getDate() + 1);

    if (this.state.data.kukcd === '01') {
      // 총무팀
      this.setState({
        isScheduleGreetingModalVisible: true,
        schedulePopupDate: Date8.make(date),
      }, () => this.modalSchduleRetrieve());
    } else {
      // 그 외
      this.setState({
        isScheduleMaintenanceModalVisible: true,
        schedulePopupDate: Date8.make(date),
      }, () => this.modalSchduleRetrieve());
    }
  }

  @action
  async closeScheduleModal() {
    this.setState({
      schedulePopupDate: '',
      popupScheduleList: [],
      isScheduleGreetingModalVisible: false,
      isScheduleMaintenanceModalVisible: false,
    });
    this.updatedRows = [];
    this.onRetrieveEvent();
  }

  @action
  async modalSchduleRetrieve() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e501',
        plandate: this.state.schedulePopupDate,
        perid: this.state.data.perid,
      },
    );

    if (data?.items) {
      this.setState({
        popupScheduleList: data?.items,
      }, async () => {
        await this.popupScheduleTable.current?.update();
        this.popupScheduleTable.current?.setFocus(0, 0);
      });
    } else {
      this.setState({
        popupScheduleList: [],
      }, () => this.popupScheduleTable.current?.update());
    }
  }

  @action
  async modalSchduleDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_e501',
      plandate: this.state.focusedSchedule?.plandate,
      plannum: this.state.focusedSchedule?.plannum,
      upflag: this.state.focusedSchedule?.upflag,
    }) && await this.modalSchduleRetrieve();

    this.updatedRows = [];
  }

  @action
  async modalSchduleSave() {
    const { actionStore: api } = this.props;
    if (this.state.popupScheduleList?.length) {
      await api.save(
        {
          sub: 'w_popup_e501',
          items: this.state.popupScheduleList,
        }, this.state.data.new === '1',
      );
    }

    this.closeScheduleModal();
  }

  @action
  async modalSchduleNew() {
    const { actionStore: api, publicStore } = this.props;
    const seq:number | undefined = this.state.popupScheduleList?.length;

    const data = await api.fxExec('new',
      {
        sub: 'w_popup_e501',
        planmon: this.state.schedulePopupDate.substring(0, 6),
      });

    if (data) {
      const newModel = new SchedulePopupModel({
        ...data,
        perid: data.perid || publicStore.user.perid,
        pernm: data.pernm || publicStore.user.pernm,
        plandate: this.state.schedulePopupDate,
        // eslint-disable-next-line no-nested-ternary
        plannum: seq! < 10 ? `00${seq! + 1}` : seq! < 100 ? `0${seq! + 1}` : (seq! + 1).toString(),
      }, true);

      this.setState({
        popupScheduleList: [
          newModel,
          ...this.state.popupScheduleList!,
        ],
        focusedSchedule: newModel,
      }, async () => {
        await this.popupScheduleTable.current?.update(false);
        this.popupScheduleTable.current?.setFocus(0, 0);
      });
    }
  }

  @action
  // 부서계획 불러오기
  // eslint-disable-next-line class-methods-use-this
  async roadSchedule() {
    // 서버 측 기능 구현 후 테스트
    ConfirmWarning.show('경고', '해당 기능 준비중입니다.');
    //
    // const { actionStore: api } = this.props;
    //
    // const data = await api.fxExec('wb_diviplan',
    //   {
    //     sub: 'w_popup_e501',
    //     plandate: this.state.schedulePopupDate,
    //     perid: this.state.popupPerid,
    //     pernm: this.state.popupPernm,
    //   });
    //
    // if (data && data.items) {
    //   this.setState({
    //     popupScheduleList: [
    //       ...this.state.popupScheduleList!,
    //       ...data.items,
    //     ],
    //   }, () => this.popupTable.current?.update());
    // }
  }


  @action
  async onLoadSchedule() {
    const { actionStore: api } = this.props;

    if (!await Confirm.show('선택', '스케쥴(계획) 등록한 내용을 불러옵니다. \n불러오시겠습니까?', ConfirmType.QUESTION)) {
      return;
    }

    const data = await api.fxExec('wb_e501',
      {
        rptdate: this.state.data.rptdate,
        perid: this.state.data.perid,
      });

    if (data.items) {
      this.setState({
        detailList: [...this.state.detailList, ...data.items],
      }, () => this.detailTable.current?.update());
    }
  }

  @action
  async onLoadToday() {
    const { actionStore: api } = this.props;

    if (!await Confirm.show('선택', '스케쥴(계획) 등록한 내용을 불러옵니다. \n불러오시겠습니까?', ConfirmType.QUESTION)) {
      return;
    }

    const data = await api.fxExec('wb_today',
      {
        rptdate: this.state.data.rptdate,
        perid: this.state.data.perid,
      });

    if (data.items) {
      this.setState({
        detailList: [...this.state.detailList, ...data.items],
      }, () => this.detailTable.current?.update());
    }
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<GeneralJournalDetailModel>, updatedRows: Array<GeneralJournalDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ detailList: rows });
  }

  @action
  onUpdatedPopupRows(rows: Array<SchedulePopupModel>, updatedRows: Array<SchedulePopupModel>) {
    this.updatedRows2 = updatedRows;
    this.setState({ popupScheduleList: rows });
  }


  @action
  onScheduleUpdateRows(rows: Array<SchedulePopupModel>, updatedRows: Array<SchedulePopupModel>) {
    this.updatedScheduleRows = updatedRows;
    this.setState({ popupScheduleList: rows });
  }


  render() {
    return (
      <GeneralJournalTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new GeneralJournalModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
