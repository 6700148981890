import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { DailyCheckTemplate } from './DailyCheck.template';
import { DailyCheckModel } from './DailyCheck.model';
import { InfinityRetrieve } from '../../../../models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Today } from '../../../../utils/time';
import { PageComponent } from '../../../../utils';

interface DailyCheckState {
  src: string;
  stdate: string;
  enddate: string;
  actcd: string;
  actnm: string;
  gigan: string;
  searchQuery: string;
  result: string;
  data: Array<DailyCheckModel>;
  focusedData?: DailyCheckModel;
  contgs: Array<any>;
  wkactcds: Array<any>;
  evcds: Array<any>;
  url?: string;

  // trail
  total: string;
  equpcnt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e034
 * @category 일상점검등록
 */
export class DailyCheck extends PageComponent<PageProps, DailyCheckState>
  implements PageToolEvents {
  updatedRows?: Array<DailyCheckModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: Today.date(),
      searchQuery: '',
      gigan: '',
      result: '',
      pernm: '',
      perid: '',
      data: [],
      // trail
      total: '0',
      equpcnt_tot: '0',
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 점검기종 리스트
    data = await api.dropdown('wf_dd_e030');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ evcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new DailyCheckModel(x))],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          await this.table.current?.update(false);
          this.table.current?.setFocus(0);
        } else {
          await this.table.current?.update(false);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['rptdate', 'actnm'],
        [this.state.focusedData?.rptdate, this.state.focusedData?.actnm], type, true) || 0;
      const data = await this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        equpcnt_tot: data?.equpcnt_tot || '0',
      });
      if (this.state.data.length) {
        await this.table.current?.update(false);
        this.table.current?.setFocus(index);
      } else {
        await this.table.current?.update(false);
      }
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [...this.state.data, new DailyCheckModel(data, true)],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.data.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save({
      items: this.state.data,
    }, false);
    this.updatedRows = [];
    this.table.current?.resetUpdates();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `점검일자: ${this.state.focusedData?.plandate} 
    현장명: ${this.state.focusedData?.actnm} 
    호기명: ${this.state.focusedData?.equpnm}`;
    await api.fxDelete(
      'delete',
      text,
      {
        plandate: this.state.focusedData?.plandate,
        actcd: this.state.focusedData?.actcd,
        equpcd: this.state.focusedData?.equpcd,
      },
    ) && await this.onRetrieveEvent();
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: DailyCheckModel) {
    this.setState({ focusedData: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<DailyCheckModel>, updatedRows: Array<DailyCheckModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <DailyCheckTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
