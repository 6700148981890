import * as React from 'react';
import {
  AiFillMinusSquare,
  AiFillPlusSquare,
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { FormatTextBox } from '../../../../components/forms/FormatTextBox';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import {
  Journal,
  JournalButtonClickedTypes,
  JournalItemChangeTypes,
  JournalTabDataStateNames,
  JournalTabId,
} from './Journal';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { Button, BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Global, TemplateProps } from '../../../../constants';
import { Date6Calculator, Date8, Today } from '../../../../utils/time';
import { TabView } from '../../../../components/layout/TabView';
import { SearchBinding } from '../../../../models/common';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ComboBox, ComboBoxModel } from '../../../../components/forms/ComboBox';
import { ModalView } from '../../../../components/layout/ModalView';
import { AttachmentBox } from '../../../../components/forms/AttachmentBox';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { IFrame } from '../../../../components/forms/IFrame';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import {
  Sum,
  Update,
} from '../../../../utils/array';
import { Format } from '../../../../utils/string';
import { ScheduleItemChangeTypes } from '../Schedule/Schedule';

/*
* 화면
 * @window w_tb_e037_type2
 * @category 개별업무일지작성
*/

const states = [
  { value: '1', remark: '계획' },
  { value: '2', remark: '완료' },
];

const notices = [
  { value: '0', remark: '사용' },
  { value: '1', remark: '미사용' },
];

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const JournalTemplate: React.FC<TemplateProps<Journal>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.stmon}
              textAlign="center"
              format="####-##"
              onChange={(value) => { scope.setState({ stmon: value }); }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          {user.kukcd === '01'
            ? <FlexLayout size={300}>
              <span style={{ color: 'var(--color-red)' }}>사원명</span>
              <TextBox
                value={scope.state.perid}
                textAlign="center"
                onChange={(value) => scope.SS({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  async (item) => {
                    await scope.SS({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.pernm}
                textAlign="center"
                onChange={(value) => setData({ pernm: value })}
                readonly={true}
              />
            </FlexLayout>
            : null
          }

          <FlexLayout/>
          <Button onClick={() => scope.onReport()}>
            <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout size={200}>
        <FlexLayout
          weight={1}
          isVertical={true}
        >
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'rptdate',
                text: '일자',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rptdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '사원명',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'rspnm',
                text: '직위',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rspnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 6,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 4,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 27 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={25}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'attcnt',
                text: '첨부',
                width: 6,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {`${x.attcnt} 건`}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.journalList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          weight={1.5}
          isVertical={true}
          isScroll={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="업무일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.rptdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ rptdate: value })}
                // onBlur={() => scope.itemChanged(JournalItemChangeTypes.RPTDATE)}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format={model?.appnum && '##-######-###-####'}
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="출발지"
              weight={2}
              style={{ color: 'var(--color-red)' }}
              footer={
                <TextBox
                  value={`위도: ${model?.st_lat}`}
                  readonly={true}
                  transparent={true}
                  size={180}
                />
              }
            >
              <TextBox
                value={model?.st_actnm}
                textAlign="left"
                onChange={(value) => setData({ st_actnm: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      st_actcd: item.cd,
                      st_actnm: item.cdnm,
                    }, () => scope.itemChanged(JournalItemChangeTypes.ST_ACTNM));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
            <LabelBox
              title="도착지"
              weight={2}
              style={{ color: 'var(--color-red)' }}
              footer={
                <TextBox
                  value={`경도: ${model?.end_lat}`}
                  readonly={true}
                  transparent={true}
                  size={180}
                />
              }
            >
              <TextBox
                value={`${model?.end_actnm}`}
                textAlign="left"
                onChange={(value) => setData({ end_actnm: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      end_actcd: item.cd,
                      end_actnm: item.cdnm,
                    }, () => scope.itemChanged(JournalItemChangeTypes.END_ACTNM));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
            <LabelBox
              title="총이동거리"
            >
              <TextBox
                value={`${model?.km} Km`}
                textAlign="right"
                onChange={(value) => setData({ km: value })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="비고(현장요구)" >
              <TextBox
                isMultiline={true}
                value={model?.rptremark}
                onChange={(value) => setData({ rptremark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: (model?.attcnt) === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={(model?.attcnt) === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LayoutTitle weight={0.5}>
          <FlexLayout>
            익일계획
            <Button
              isIcon={true}
              onClick={() => scope.openScheduleModal()}
            >
              <FiArrowRightCircle size={17}/>
            </Button>
          </FlexLayout>
        </LayoutTitle>
        <LabelBox weight={9.5}>
          <TextBox
            isMultiline={true}
            readonly={true}
            value={model?.overwk}
          />
        </LabelBox>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout
          justify='left'
          align='center'
        >
          <TabView
            tabs={scope.tabs}
            focused={scope.state.focusedTab}
            onChange={(tab) => scope.onTabChange(tab)}
          />
        </FlexLayout>
        <FlexLayout
          justify='right'
          align='center'
        >
          <Button
            style={{ height: '25px', width: '80px' }}
            onClick={() => scope.openRecordModal()}
          >
            계약기록
          </Button>
          <Button
            style={{ height: '25px', width: '80px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '80px' }}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout isVertical={true}>
        {scope.tabIndex === JournalTabId.Detail
        && <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
                justify='left'
                align='center'
            >
                <Button
                    style={{ marginTop: '10px', height: '25px', width: '80px' }}
                    onClick={() => scope.onLoadToday()}
                >
                    금일업무
                </Button>
            </FlexLayout>
            <FlexLayout />
            <FlexLayout
                justify='right'
                align='center'
            >
                <LayoutTitle
                    style={{ color: 'var(--color-red)' }}
                    weight={1}
                >
                    현장코드
                </LayoutTitle>
                <LabelBox weight={7}>
                    <TextBox
                        value={scope.state.actcd}
                        textAlign="center"
                        onChange={(value) => scope.setState({ actcd: value })}
                        bindSearchModal={new SearchBinding(
                          scope.props.modalStore,
                          'TB_E601_1',
                          {},
                          true,
                          (item) => {
                            scope.setState({
                              actcd: item.cd,
                              actnm: item.cdnm,
                            });
                          },
                        )}
                        isDisabledTrackingStateChange={true}
                    />
                    <TextBox
                        value={scope.state.actnm}
                        weight={3}
                        textAlign="center"
                        isDisabledTrackingStateChange={true}
                        readonly={true}
                    />
                </LabelBox>
                <Button
                    onClick={() => scope.openEqupModal()}
                    style={{ marginTop: '10px', height: '25px', width: '80px' }}
                >
                    호기생성
                </Button>
            </FlexLayout>
        </FlexLayout>
        }
        {scope.tabIndex === JournalTabId.Self
        && <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
                justify='left'
                align='center'
            >
                <LayoutTitle
                    weight={1}
                    style={{ color: 'var(--color-red)' }}
                >
                    처리상태
                </LayoutTitle>
                <LabelBox weight={4}>
                  <ComboBox
                      value={scope.state.tab2_state}
                      data={[
                        { value: '%', remark: '전체' },
                        { value: '0', remark: '미처리' },
                        { value: '1', remark: '처리' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => { scope.setState({ tab2_state: option.value }); }}
                  />
                </LabelBox>
                <Button
                    style={{ width: '80px' }}
                    onClick={() => scope.openChargeModal()}
                >
                    담당별합계
                </Button>
            </FlexLayout>
            <FlexLayout
                weight={4}
                justify='right'
                align='center'
            >
                <span style={{ color: 'var(--color-blue' }}>*담당현장이 없는 경우 팀장의 점검현황이 보입니다.</span>
            </FlexLayout>
        </FlexLayout>
        }
        {scope.tabIndex === JournalTabId.Routine
        && <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
                justify='left'
                align='center'
            >
                <LayoutTitle
                    weight={1}
                    style={{ color: 'var(--color-red)' }}
                >
                    처리상태
                </LayoutTitle>
                <LabelBox weight={4}>
                    <ComboBox
                        value={scope.state.tab3_state}
                        data={[
                          { value: '%', remark: '전체' },
                          { value: '0', remark: '미처리' },
                          { value: '1', remark: '처리' },
                        ].map((y) => new ComboBoxModel(y.value, y.remark))}
                        onSelect={(option) => { scope.setState({ tab3_state: option.value }); }}
                    />
                </LabelBox>
                <Button
                    style={{ width: '80px' }}
                    onClick={() => scope.openChargeModal()}
                >
                    담당별합계
                </Button>
            </FlexLayout>
            <FlexLayout
                weight={4}
                justify='right'
                align='center'
            >
                <span style={{ color: 'var(--color-blue' }}>*담당현장이 없는 경우 팀장의 점검현황이 보입니다.</span>
            </FlexLayout>
        </FlexLayout>
        }
        <FlexLayout>
          <TableLayout
            scope={scope}
            ref={scope.detailTable}
            header={scope.tabHeaders[scope.tabIndex]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            // @ts-ignore
            infinityHandler={scope[`infinity${scope.tabIndex + 2}`]}
            // @ts-ignore
            data={scope.state[JournalTabDataStateNames[scope.tabIndex]] || [] }
            isScroll={true}
            onRowFocusEvent={
              (item) => {
                if (scope.tabIndex === JournalTabId.Detail) {
                  scope.onDetailRowFocusEvent(item);
                }
              }
            }
            onChange={(rows, updatedRows) => {
              if (scope.tabIndex === JournalTabId.Detail) {
                scope.onUpdatedRows(rows, updatedRows);
              }
            }}
          />
        </FlexLayout>
      </FlexLayout>

      <ModalView
        width={1300}
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
      >
        <FlexLayout
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            appnum={scope.state.data?.appnum}
            appnm={'업무일지'}
            isEnabledPreview={true}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });

              // grid
              const index = scope.state.journalList.findIndex((x) => x.rptdate === model?.rptdate);
              await Update.byIndex(scope, 'journalList', index, 'attcnt', count.toString());
              scope.grid.current?.forceRepaint(true);
            }}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isEducationModalVisible}
        onClose={() => scope.closeEducationModal()}
        width={900}
        height={600}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              교육보고서에 올라온 내역
            </LayoutTitle>
          </FlexLayout>
          <GridLayout
            ref={scope.popupEducationGrid}
            header={[
              {
                id: 'edudate',
                text: '교육일자',
                width: 8,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.edudate}
                  />
                ),
              },
              {
                id: 'title',
                text: '교육제목',
                width: 35,
                render: (x) => (
                  <TextBox
                    value={x.title}
                    textAlign="left"
                  />
                ),
              },
              {
                id: 'edupernm',
                text: '교육자',
                width: 7,
                render: (x) => (
                  <TextBox
                    value={x.edupernm}
                    textAlign="left"
                  />
                ),
              },
            ]}
            data={scope.state.popupEducationList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            // onRowClick={(item) => scope.onYesterdayRowFocusEvent(item)}
          />
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout>
              <FlexLayout weight={1}>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.BLUE}
                  onClick={() => scope.modalEducationSave()}
                >
                  확인
                </Button>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.closeEducationModal()}
                >
                  닫기
                </Button>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isChargeModalVisible}
        onClose={() => scope.closeChargeModal()}
        zIndex={-1}
        width={1600}
        height={1200}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              담당별합계
            </LayoutTitle>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchLayout size={300}>
              <FlexLayout size={200}>
                <span>월</span>
                <DateTextBox
                  value={scope.state.popupStmon}
                  textAlign="center"
                  format="####-##"
                  onChange={(value) => { scope.setState({ popupStmon: value }); }}
                  onEnter={() => scope.modalChargeRetrieve()}
                  head={(<button
                    onClick={() => {
                      scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add(-1).toString() },
                        () => scope.modalChargeRetrieve());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                  trail={(<button
                    onClick={() => {
                      scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add().toString() },
                        () => scope.modalChargeRetrieve());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            </SearchLayout>
            <FlexLayout
              justify='right'
              align='center'
            >
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.modalChargePrint()}
              >
                출력
              </Button>
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.closeChargeModal()}
              >
                확인
              </Button>
            </FlexLayout>
          </FlexLayout>

          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={1772}
          >
            <FlexLayout size={202}/>
            <FlexLayout
              size={281}
              justify="center"
              align="center"
            >
              관리 대수
            </FlexLayout>
            <FlexLayout
              size={154}
              justify="center"
              align="center"
            >
              03월 점검
            </FlexLayout>
            <FlexLayout
              size={102}
              justify="center"
              align="center"
            >
              1주
            </FlexLayout>
            <FlexLayout
              size={102}
              justify="center"
              align="center"
            >
              2주
            </FlexLayout>
            <FlexLayout
              size={102}
              justify="center"
              align="center"
            >
              3주
            </FlexLayout>
            <FlexLayout
              size={102}
              justify="center"
              align="center"
            >
              4주
            </FlexLayout>
            <FlexLayout
              size={102}
              justify="center"
              align="center"
            >
              5주
            </FlexLayout>
            <FlexLayout
              size={595}
              justify="center"
              align="center"
            >
            </FlexLayout>
          </GridLayoutAdditionalHeader>

          <GridLayout
            ref={scope.popupChargeGrid}
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 125,
                trail: () => (
                    <FlexLayout
                      style={{ color: 'var(--color-pink)' }}
                      justify="center"
                      align="center"
                    >
                      합계
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout>
                    {(x.chk === '0' && x.open === '0')
                    && <Button
                        onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.ADD, x)}
                        isIcon={true}
                    >
                        <AiFillPlusSquare size={17}/>
                    </Button>
                    }
                    {
                    (x.chk === '0' && x.open === '1')
                    && <Button
                        onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.MINUS, x)}
                        isIcon={true}
                    >
                        <AiFillMinusSquare size={17}/>
                    </Button>
                    }
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ fontWeight: x.chk === '0' && 'bold' }}
                    >
                      {x.divinm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 75,
                trail: (data) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {`${data.length} 명`}
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'endqty2',
                text: '평균대수',
                width: 75,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.endqty2 !== '0' && x.endqty2}
                  </FlexLayout>
                ),
              },
              {
                id: 'totqty',
                text: '현재대수',
                width: 75,
                trail: (data) => {
                  const sumTotqty = Sum.all(data, 'totqty');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumTotqty !== 0 && Format.number(sumTotqty)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.totqty !== '0' && x.totqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'chaqty',
                text: '변경대수',
                width: 75,
                trail: (data) => {
                  const sumChaqty = Sum.all(data, 'chaqty');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumChaqty !== 0 && Format.number(sumChaqty)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.chaqty !== '0' && x.chaqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'notkccnt',
                text: '미검사',
                width: 50,
                trail: (data) => {
                  const sumNotkccnt = Sum.all(data, 'notkccnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumNotkccnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-violet)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.notkccnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'miscnt',
                text: '미계획',
                width: 50,
                trail: (data) => {
                  const sumMiscnt = Sum.all(data, 'miscnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-red)' }}
                      justify="center"
                      align="center"
                    >
                      {sumMiscnt !== 0 && Format.number(sumMiscnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.miscnt !== '0' && x.miscnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'cnt',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumCnt = Sum.all(data, 'cnt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumCnt !== 0 && Format.number(sumCnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.cnt !== '0' && x.cnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'compcnt',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumCompcnt = Sum.all(data, 'compcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumCompcnt !== 0 && Format.number(sumCompcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.compcnt !== '0' && x.compcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt1',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt1 = Sum.all(data, 'weekcnt1');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt1 !== 0 && Format.number(sumWeekcnt1)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt1 !== '0' && x.weekcnt1}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt1',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt1 = Sum.all(data, 'weekcompcnt1');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt1 !== 0 && Format.number(sumWeekcompcnt1)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt1 !== '0' && x.weekcompcnt1}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt2',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt2 = Sum.all(data, 'weekcnt2');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt2 !== 0 && Format.number(sumWeekcnt2)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt2 !== '0' && x.weekcnt2}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt2',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt2 = Sum.all(data, 'weekcompcnt2');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt2 !== 0 && Format.number(sumWeekcompcnt2)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt2 !== '0' && x.weekcompcnt2}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt3',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt3 = Sum.all(data, 'weekcnt3');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt3 !== 0 && Format.number(sumWeekcnt3)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt3 !== '0' && x.weekcnt3}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt3',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt3 = Sum.all(data, 'weekcompcnt3');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt3 !== 0 && Format.number(sumWeekcompcnt3)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt3 !== '0' && x.weekcompcnt3}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt4',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt4 = Sum.all(data, 'weekcnt4');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt4 !== 0 && Format.number(sumWeekcnt4)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt4 !== '0' && x.weekcnt4}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt4',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt4 = Sum.all(data, 'weekcompcnt4');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt4 !== 0 && Format.number(sumWeekcompcnt4)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt4 !== '0' && x.weekcompcnt4}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt5',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt5 = Sum.all(data, 'weekcnt5');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt5 !== 0 && Format.number(sumWeekcnt5)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt5 !== '0' && x.weekcnt5}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt5',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt5 = Sum.all(data, 'weekcompcnt5');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt5 !== 0 && Format.number(sumWeekcompcnt5)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-blue)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.weekcompcnt5 !== '0' && x.weekcompcnt5}
                  </FlexLayout>
                ),
              },
              {
                id: 'jrececnt',
                text: '지원받음',
                width: 100,
                trail: (data) => {
                  const sumJrececnt = Sum.all(data, 'jrececnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-green)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJrececnt !== 0 && Format.number(sumJrececnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      color: 'var(--color-green)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jrececnt !== '0' && x.jrececnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'jmiscnt',
                text: '미점검',
                width: 100,
                trail: (data) => {
                  const sumJmiscnt = Sum.all(data, 'jmiscnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-red)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJmiscnt !== 0 && Format.number(sumJmiscnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-red)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jmiscnt !== '0' && x.jmiscnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'jcompcnt',
                text: '지원점검',
                width: 100,
                trail: (data) => {
                  const sumJcompcnt = Sum.all(data, 'jcompcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-green)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJcompcnt !== 0 && Format.number(sumJcompcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-green)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jcompcnt !== '0' && x.jcompcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'totcnt',
                text: '담당점검',
                width: 100,
                trail: (data) => {
                  const sumTotcnt = Sum.all(data, 'totcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumTotcnt !== 0 && Format.number(sumTotcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-blue)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.totcnt !== '0' && x.totcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'totyul',
                text: '진행률',
                width: 60,
                trail: (data) => {
                  // const sumPlanyul = Sum.all(data, 'planyul');
                  const sumTodayplan = Sum.all(data, 'todayplan');
                  const sumPlancomp = Sum.all(data, 'plancomp');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="right"
                      align="center"
                    >
                      {sumTodayplan !== 0 && sumPlancomp !== 0
                      && `${Format.number(((sumPlancomp / sumTodayplan) * 100).toFixed(0))} %`
                      }
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-violet)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.totyul !== 0 && x.totyul}%
                  </FlexLayout>
                ),
              },
              {
                id: 'send5cnt',
                text: '5일이상전송초과',
                width: 125,
                trail: (data) => {
                  const sumSend5cnt = Sum.all(data, 'send5cnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="right"
                      align="center"
                    >
                      {sumSend5cnt !== 0 && Format.number(sumSend5cnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      color: 'var(--color-red)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                    justify="right"
                    align="center"
                  >
                    {x.send5cnt !== '0' && x.send5cnt}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupChargeList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onChargeRowFocusEvent(item)}
            onHorizontalScroll={(x) => { scope.additionalTitle.current?.scrollTo(x); }}
          />

          <FlexLayout>
            {scope.state.isGraphVisible
            && <IFrame src={scope.graph}/>
            }
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isRecordModalVisible}
        onClose={() => scope.closeRecordModal()}
        width={900}
        height={600}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              계약기록
            </LayoutTitle>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              justify='left'
              align='center'
            >
              {scope.state.focusedJournal?.rptdate}
            </FlexLayout>
            <FlexLayout
              justify='right'
              align='center'
            >
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.modalRecordNew()}
              >
                신규
              </Button>
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.modalRecordSave()}
              >
                저장
              </Button>
              <Button
                theme={BUTTON_THEMES.RED}
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.modalRecordDelete()}
              >
                삭제
              </Button>
              <Button
                theme={BUTTON_THEMES.RED}
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.closeRecordModal()}
              >
                닫기
              </Button>
            </FlexLayout>
          </FlexLayout>
          <TableLayout
            ref={scope.popupRecordTable}
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '작성자',
                width: 8,
                render: (x, rowdupdate) => (
                  <TextBox
                    value={x.perid}
                    textAlign="center"
                    onChange={(value) => rowdupdate({ perid: value })}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '작성자명',
                width: 8,
                render: (x, rowdupdate) => (
                  <TextBox
                    value={x.pernm}
                    textAlign="center"
                    onChange={(value) => rowdupdate({ pernm: value })}
                  />
                ),
              },
              {
                id: 'hdate',
                text: '기록일자',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.hdate}
                  />
                ),
              },
              {
                id: 'wakedate',
                text: '알림만료일',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.wakedate}
                  />
                ),
              },
              {
                id: 'title',
                text: '교육제목',
                width: 35,
                render: (x, rowdupdate) => (
                  <TextBox
                    value={x.title}
                    textAlign="left"
                    onChange={(value) => rowdupdate({ title: value })}
                  />
                ),
              },
              {
                id: 'chk',
                text: '답변확인',
                width: 8,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      value={x.chk === '1'}
                      onChange={(value) => { rowUpdate({ chk: value ? '1' : '0' }); }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupRecordList}
            onRowFocusEvent={(item) => scope.onRecordRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => { scope.onRecordUpdateRows(rows, updatedRows); }}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isEqupModalVisible}
        onClose={() => scope.closeEqupModal()}
        width={600}
        height={600}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              호기생성
            </LayoutTitle>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout align='center'>
              <LayoutTitle
                weight={1}
                style={{ color: 'var(--color-red)' }}
              >
                업무구분
              </LayoutTitle>
              <LabelBox weight={4}>
                <ComboBox
                  value={scope.state.popupWkcd}
                  data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm || '전체'))}
                  onSelect={(option) => scope.setState({ popupWkcd: option.value })}
                />
              </LabelBox>
              <LayoutTitle
                weight={1}
                style={{ color: 'var(--color-red)' }}
              >
                세부내용
              </LayoutTitle>
              <LabelBox weight={4}>
                <TextBox
                  value={scope.state.popupRemark}
                  onChange={(value) => scope.setState({ popupRemark: value })}
                  isDisabledTrackingStateChange={true}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <TableLayout
            ref={scope.popupEqupTable}
            header={[
              {
                id: 'chk',
                text: '선택',
                width: 5,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      value={x.chk === '1'}
                      onChange={(value) => { rowUpdate({ chk: value ? '1' : '0' }); }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'equpcd',
                text: '호기코드',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 20,
                render: (x, rowdupdate) => (
                  <TextBox
                    value={x.equpnm}
                    textAlign="center"
                    onChange={(value) => rowdupdate({ equpnm: value })}
                  />
                ),
              },
            ]}
            data={scope.state.popupEqupList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => { scope.onEqupUpdateRows(rows, updatedRows); }}
          />
          <FlexLayout
            justify='right'
            align='center'
          >
            <Button
              style={{ marginTop: '10px', height: '25px', width: '60px' }}
              onClick={() => scope.modalEqupConfirm()}
            >
              확인
            </Button>
            <Button
              theme={BUTTON_THEMES.RED}
              style={{ marginTop: '10px', height: '25px', width: '60px' }}
              onClick={() => scope.closeEqupModal()}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      {/* 보수팀 */}
      <ModalView
        isVisible={scope.state.isScheduleMaintenanceModalVisible}
        onClose={() => scope.closeScheduleModal()}
        width={1200}
        height={900}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              스케쥴 등록
            </LayoutTitle>
          </FlexLayout>
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            justify='left'
          >
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalSchduleNew()}
            >
              추 가
            </Button>
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalSchduleDelete()}
            >
              삭 제
            </Button>
            <FlexLayout
              style={{ color: 'var(--color-blue)' }}
              align='center'
              justify='left'
            >
            </FlexLayout>
            <FlexLayout size={250}>
              <LayoutTitle
                style={{ color: 'var(--color-red)' }}
                weight={1}
              >
                담당자
              </LayoutTitle>
              <LabelBox weight={4}>
                <TextBox
                  value={scope.state.popupPerid}
                  textAlign="center"
                  onChange={(value) => scope.setState({ popupPerid: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      scope.setState({
                        popupPerid: item.cd,
                        popupPernm: item.cdnm,
                      });
                    },
                  )}
                  isDisabledTrackingStateChange={true}
                />
                <TextBox
                  value={scope.state.popupPernm}
                  weight={1.5}
                  textAlign="center"
                  isDisabledTrackingStateChange={true}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>
            <Button
              style={{ width: '80px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.roadSchedule()}
            >
              부서계획
            </Button>
          </FlexLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupScheduleTable}
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'upflagnm',
                  text: '구분',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: scope.getItemColor(x.wkcd) }}
                    >
                      {x.upflagnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'wkcd',
                  text: '업무구분',
                  width: 15,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.wkcd}
                      style={{ color: scope.getItemColor(x.wkcd) }}
                      data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                      onSelect={(option) => {
                        rowUpdate({ wkcd: option.value });
                        scope.popupItemChanged(JournalItemChangeTypes.WKCD,
                          {
                            ...x,
                            wkcd: option.value,
                          });
                      }}
                    />
                  ),
                },
                {
                  id: 'plandate',
                  text: '계획일자',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.plandate || Today.date()}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ plandate: value })}
                    />
                  ),
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 30,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'equpcd',
                  text: '호기코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E611',
                        { uv_arg1: x.actcd },
                        true,
                        (item) => {
                          rowUpdate({
                            equpcd: item.cd,
                            equpnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기명',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpnm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpnm: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '업무내용',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'perid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid || x.u}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 15,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                    />
                  ),
                },
              ]}
              data={scope.state.popupScheduleList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onScheduleUpdateRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onScheduleRowFocusEvent(item)}
            />
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout justify='right'>
              <Button
                style={{ width: '60px' }}
                theme={BUTTON_THEMES.BLUE}
                onClick={() => scope.modalSchduleSave()}
              >
                저장
              </Button>
              <Button
                style={{ width: '60px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.closeScheduleModal()}
              >
                닫기
              </Button>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      {/* 총무팀 */}
      <ModalView
        isVisible={scope.state.isScheduleGreetingModalVisible}
        onClose={() => scope.closeScheduleModal()}
        width={1500}
        height={900}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              스케쥴 등록
            </LayoutTitle>
          </FlexLayout>
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            justify='left'
          >
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalSchduleNew()}
            >
              추 가
            </Button>
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalSchduleDelete()}
            >
              삭 제
            </Button>
          </FlexLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupScheduleTable}
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'upflagnm',
                  text: '구분',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: scope.getItemColor(x.wkcd) }}>
                      {x.upflagnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'wkcd',
                  text: '업무구분',
                  width: 15,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.wkcd}
                      style={{ color: scope.getItemColor(x.wkcd) }}
                      data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                      onSelect={(option) => {
                        rowUpdate({ wkcd: option.value });
                        scope.itemChanged(ScheduleItemChangeTypes.WKCD,
                          {
                            ...x,
                            wkcd: option.value,
                          });
                      }}
                    />
                  ),
                },
                {
                  id: 'plandate',
                  text: '발송일',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.plandate || `${Today.yearMon()}${Today.date()}`}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ plandate: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '업무내용',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'notice',
                  text: '알림구분',
                  width: 10,
                  render: (x, rowUpdate, _) => (
                    <ComboBox
                      value={x.gubun}
                      data={notices.map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => { rowUpdate({ notice: option.value }); }}
                    />
                  ),
                },
                {
                  id: 'noticedate',
                  text: '알림일자',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.noticedate || `${Today.yearMon()}${Today.date()}`}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ noticedate: value })}
                    />
                  ),
                },
                {
                  id: 'noticetime',
                  text: '접수시간',
                  width: 10,
                  render: (x, rowUpdate, ref) => (
                    <FormatTextBox
                      ref={ref}
                      textAlign="center"
                      format="##:##"
                      value={x.noticetime}
                      onChange={(value) => rowUpdate({ noticetime: value })}
                    />
                  ),
                },
                {
                  id: 'perid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid || x.u}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 15,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'equpcd',
                  text: '호기코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E611',
                        { uv_arg1: x.actcd },
                        true,
                        (item) => {
                          rowUpdate({
                            equpcd: item.cd,
                            equpnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기명',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpnm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpnm: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.popupScheduleList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onScheduleUpdateRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onScheduleRowFocusEvent(item)}
            />
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout justify='right'>
              <Button
                style={{ width: '60px' }}
                theme={BUTTON_THEMES.BLUE}
                onClick={() => scope.modalSchduleSave()}
              >
                저장
              </Button>
              <Button
                style={{ width: '60px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.closeScheduleModal()}
              >
                닫기
              </Button>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
