import { TextBox, CheckBox } from '../../../../../components';
import { TableLayoutHeader } from '../../../../../constants';
import { EnrollmentCheckModel } from '../models';
import { Enrollment } from '../Enrollment';


// 검사이력정보
const Check: TableLayoutHeader<EnrollmentCheckModel, Enrollment>[] = [
  {
    id: 'equpcd',
    text: '코드',
    width: 10,
    render: (x) => (
      <TextBox
        value={x.equpcd}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 30,
    render: (x) => (
      <TextBox
        value={x.equpnm}
        readonly={true}
        transparent={true}
        textAlign="left"
      />
    ),
  },
  {
    id: 'contyn',
    text: '계약',
    width: 10,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.contyn === '1'}
        onChange={(value) => rowUpdate({ contyn: value ? '1' : '0' })}
      />
    ),
  },
];

export default Check;
