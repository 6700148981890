import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { ElectronCheckTemplate } from './ElectronCheck.template';
import { ElectronCheckModel } from './ElectronCheck.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface ElectronCheckState {
  src: string;
  stmon: string;
  perid: string;
  pernm: string;
  gigan: string;
  searchQuery: string;
  result: string;
  data: Array<ElectronCheckModel>;
  focusedData?: ElectronCheckModel;
  contgs: Array<any>;
  wkactcds: Array<any>;
  url?: string;

  // trail
  total: string;
  equpcnt_tot: string;
  sendcnt1_tot: string;
  sendcnt2_tot: string;
  gkccnt1_tot: string;
  gkccnt2_tot: string;
  gremaincnt1_tot: string;
  gremaincnt2_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e035_gosi
 * @category 전자점검연동내역
 */
export class ElectronCheck extends PageComponent<PageProps, ElectronCheckState>
  implements PageToolEvents {
  updatedRows?: Array<ElectronCheckModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }
    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      searchQuery: pageParams.searchQuery || '',
      gigan: '',
      result: pageParams.result || '%',
      pernm: bosuPernm || '',
      perid: bosuPerid || '',
      data: [],
      // trail
      total: '0',
      equpcnt_tot: '0',
      sendcnt1_tot: '0',
      sendcnt2_tot: '0',
      gkccnt1_tot: '0',
      gkccnt2_tot: '0',
      gremaincnt1_tot: '0',
      gremaincnt2_tot: '0',
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        result: pageParams.result || this.state.result,
      });
      this.onRetrieveEvent();
    }
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_e018_1');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ wkactcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        result: this.state.result,
        gigan: this.state.gigan,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new ElectronCheckModel(x))],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['rptdate', 'actnm'],
        [this.state.focusedData?.rptdate, this.state.focusedData?.actnm], type, true) || 0;
      const data = await this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        equpcnt_tot: data?.equpcnt_tot || '0',
        sendcnt1_tot: data?.sendcnt1_tot || '0',
        sendcnt2_tot: data?.sendcnt2_tot || '0',
        gkccnt1_tot: data?.gkccnt1_tot || '0',
        gkccnt2_tot: data?.gkccnt2_tot || '0',
        gremaincnt1_tot: data?.gremaincnt1_tot || '0',
        gremaincnt2_tot: data?.gremaincnt2_tot || '0',
      });
      if (this.state.data.length) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onClickWarp() {
    this.props.publicStore.go(
      '/w_tb_e035_gosi_02',
      {
      },
    );
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stmon: this.state.stmon,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      result: this.state.result,
      gigan: this.state.gigan,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stmon: this.state.stmon,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      result: this.state.result,
      gigan: this.state.gigan,
    });
  }



  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: ElectronCheckModel) {
    this.setState({ focusedData: item });
  }

  render() {
    return <ElectronCheckTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
