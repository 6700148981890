import { makeObservable, observable } from 'mobx';
import { MenuModel } from '../models';

interface IMenuStore {
  menus: MenuModel[];
}

export default class MenuStore implements IMenuStore {
  @observable menus: MenuModel[];

  constructor() {
    this.menus = [];

    makeObservable(this);
  }
}
