import * as React from 'react';
import { FiArrowRightCircle, FiFolderPlus, FiMonitor } from 'react-icons/fi';
import {
  IoIosPin,
  IoMdCamera,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiLinksFill,
} from 'react-icons/all';
import { MdAddCircle } from 'react-icons/md';
import {
  AddressButton,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  EditTitleLabelBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TabView,
  TextBox,
  AttachmentBox as GlobalAttachmentBox,
  TelnumTextBox,
  DragAndDropLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import {
  Enrollment,
  EnrollmentItemChangeTypes,
  EnrollmentTabDataStateNames,
  EnrollmentTabFocusedStateNames,
  EnrollmentTabId,
  EnrollmentTabUpdatesStateNames,
  BtnGubun,
} from './Enrollment';
import { SearchBinding, SearchBindingProjects } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import {
  EnrollmentContractInformationModel,
  EnrollmentDivisionModel,
  EnrollmentUnitModel,
} from './models';
import {
  Date6Calculator,
  Today,
} from '../../../../utils/time';
import { AttachmentBox } from './components/AttachmentBox';
import { Format } from '../../../../utils/string';
import { ConfirmWarning } from '../../../../utils/confirm';

/**
 * 화면
 * @window w_tb_e601_new
 * @category 보수현장등록
 */

const Contgs = [
  { value: '01', remark: '신규', color: 'var(--color-blue)' },
  { value: '02', remark: '연장', color: 'var(--color-black)' },
  { value: '03', remark: '재계약', color: 'var(--color-black)' },
  { value: '04', remark: '해지', color: 'var(--color-red)' },
  { value: '05', remark: '회수', color: 'var(--color-black)' },
  { value: '06', remark: '타사', color: 'var(--color-black)' },
];

const taxgubuns = [
  { value: '01', remark: '과세' },
  { value: '02', remark: '영세' },
  { value: '03', remark: '면세' },
];

const results = [
  { value: '002', remark: '불합격' },
  { value: '003', remark: '조건부' },
  { value: '010', remark: '0' },
  { value: '011', remark: '조건부합격(1년)' },
  { value: '012', remark: '2개월조건부조건후불합격' },
  { value: '001', remark: '합격' },
  { value: '007', remark: '보완후합격' },
  { value: '008', remark: '현장시정조치합격' },
  { value: '009', remark: '보완' },
  { value: '013', remark: '보완후불합격' },
  { value: '014', remark: '승인' },
  { value: '004', remark: '조건후합격' },
  { value: '005', remark: '조건후불합격' },
  { value: '006', remark: '조건부합격' },
];


export const EnrollmentTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
  // update,
  dataUpdate,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);
  const model = scope.state.actInfo;
  const model2 = scope.state.unitFocused;
  const model3 = scope.state.contractinformationsFocused
    || new EnrollmentContractInformationModel();
  const model3new = scope.state.contractinformationsFocusedNew;
  const model4 = scope.state.divisionsFocused;
  const setData2 = dataUpdate!;

  const setModel2State = (changes: any) => {
    scope.setState({
      unitFocused: new EnrollmentUnitModel({
        ...scope.state.unitFocused,
        ...changes,
      }, scope.state.unitFocused?.isNew),
    });
  };

  const setModel2StateForElno = (changes: any) => {
    scope.changedElno();
    scope.setState({
      unitFocused: new EnrollmentUnitModel({
        ...scope.state.unitFocused,
        ...changes,
      }, scope.state.unitFocused?.isNew),
    });
  };

  // 계약정보 탭
  const setModel3State = (changes2: any) => {
    scope.setState({
      contractinformationsFocused: new EnrollmentContractInformationModel({
        ...scope.state.contractinformationsFocused,
        ...changes2,
      }, model3?.isNew || model3new?.isNew),
    });
  };

  // 분할납부 정보 탭
  const setModel4State = (changes2: any) => {
    scope.setState({
      divisionsFocused: new EnrollmentDivisionModel({
        ...scope.state.divisionsFocused,
        ...changes2,
      }, scope.state.divisionsFocused?.isNew),
    });
  };

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처, 현장담당자, 주소, 승강기번호로 검색하세요"
            value={scope.state.searchQuery}
            textAlign="left"
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.contflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ contflag: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value }, () => scope.onRetrieveEvent)}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1}
            value={scope.state.pernm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value }, () => scope.onRetrieveEvent)}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({ wkactcd: item.cd, wkactnm: item.cdnm });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.4}
            value={scope.state.wkactnm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <Button onClick={() => scope.contractSelectModal(true)}>
         <div style={{ width: 60 }}>계약연장</div>
        </Button>

        <Button onClick={() => scope.changePersonModal(true)}>
          <div style={{ width: 60 }}>담당자변경</div>
        </Button>

        <Button onClick={() => scope.getGPS()}>
          <div style={{ width: 60 }}>위치GPS</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout size={400}>
          <GridLayout
            header={[
              {
                id: 'actcd',
                text: '현장코드',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={
                      x?.contg === '04' ? { color: 'var(--color-red)' }
                        : x?.contg === '06' ? { color: '#b84a5b' }
                          : x.mgubun === '0' ? { color: 'var(--color-violet)' } : { color: 'var(--color-black)' }}
                  >
                    {x?.actcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={
                    x?.contg === '04' ? { color: 'var(--color-red)' }
                      : x?.contg === '06' ? { color: '#b84a5b' }
                        : x.mgubun === '0' ? { color: 'var(--color-violet)' } : { color: 'var(--color-black)' }}
                  >
                    {x?.actnm}
                    {x?.isRemFlag && <div
                      style={{
                        position: 'absolute',
                        right: 8,
                      }}
                    >
                      <FiMonitor size={22}/>
                    </div>}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x?.pernm}
                  </FlexLayout>
                ),
              },
            ]}
            ref={scope.grid}
            data={scope.state.placeList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(x) => scope.onRowFocusEvent(x)}
          />
        </FlexLayout>

        <FlexLayout isVertical={true}>
          <FlexLayout isVertical={true} weight={1.6} isScroll={true}>
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <FlexLayout size={630}>
                <LabelBox
                  title="현장"
                  isNeed={true}
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.onAddressButton(model?.actnm)}
                    >
                      주소찾기
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    size={90}
                    textAlign="center"
                    value={model?.actcd}
                    onChange={(value) => setData2({ actcd: value })}
                    readonly={!scope.state.focuseddata?.isNew}
                  />

                  <TextBox
                    value={model?.actnm}
                    onChange={(value) => setData2({ actnm: value })}
                    weight={5}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout >
                <LabelBox
                  title="설치현장"
                >
                  <TextBox
                    value={model?.scactnm}
                    onChange={(value) => setData2({ scactnm: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={100}>
                <LabelBox
                  title="계약상태"
                >
                  <TextBox
                    style={{
                      color: Contgs.filter((y) => y.value === model?.contg)[0]?.color || 'inherit',
                    }}
                    color={Contgs.filter((y) => y.value === model?.contg)[0]?.color || 'inherit'}
                    textAlign="center"
                    value={Contgs.filter((y) => y.value === model?.contg)[0]?.remark}
                    onChange={(value) => setData2({ contg: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={100}>
                <LabelBox title="시작구분">
                  <ComboBox
                    value={model?.state}
                    data={[
                      { value: '0', remark: '보수시작' },
                      { value: '1', remark: '설치시작' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setData({ state: option.value })}
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <FlexLayout size={100}>
                <LabelBox
                  title="관리수"
                  isNeed={true}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.qty}
                    onChange={(value) => setData2({ qty: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={330}>
                <LabelBox
                  title="회사구분"
                  style={{ color: 'var(--color-red)' }}
                  isNeed={true}
                >
                  <TextBox
                    value={model?.wkactcd}
                    textAlign="center"
                    onChange={(value) => setData2({ wkactcd: value })}
                    onEnter={() => scope.onRetrieveEvent()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E018_1',
                      {},
                      true,
                      (item) => {
                        setData2({ wkactcd: item.cd, wkactnm: item.cdnm });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    weight={1.5}
                    textAlign="left"
                    value={model?.wkactnm}
                    onChange={(value) => setData2({ wkactnm: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={196}>
                <LabelBox
                  title="프로젝트No(협력사코드)"
                >
                  <TextBox
                    textAlign="center"
                    value={model?.cltnum}
                    onChange={(value) => setData2({ cltnum: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={150}>
                <LabelBox
                  title="건물용도"
                >
                  <ComboBox
                    value={model?.bildyd}
                    data={scope.state.bildyds?.map((y) => new ComboBoxModel(y.buildcd, y.buildnm))}
                    onSelect={
                      (option) => {
                        setData2({ bildyd: option.value });
                        scope.itemChanged(EnrollmentItemChangeTypes.bildyd, {
                          bildyd: option.value,
                        });
                      }
                    }
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={150}>
                <LabelBox
                  title="유지관리비별 건물구분"
                >
                  <TextBox
                    textAlign="center"
                    value= {scope.state.contbilds?.filter((y) => y.com_code === model?.contbild)[0]?.com_cnam || '-'}
                    onChange={(value) => setData2({ contbild: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={350}>
                <LabelBox
                  title="지역(검사담당관할)"
                  style={{ color: 'var(--color-red)' }}
                >
                  <TextBox
                    value={model?.areacd}
                    textAlign="center"
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E028',
                      {},
                      true,
                      (item) => {
                        setData2({
                          areacd: item.cd,
                          areanm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    textAlign="left"
                    value= {model?.areanm}
                    onChange={(value) => setData2({ areanm: value })}
                    readonly={true}
                  />
                  <TextBox
                    textAlign="left"
                    value={model?.gareanm}
                    onChange={(value) => setData2({ gareanm: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <FlexLayout size={200}>
                <LabelBox
                  title="담당(점검자)"
                  isNeed={true}
                  style={{ color: 'var(--color-red)' }}
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.checkModal(true)}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      <FiArrowRightCircle size={25}/>
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    value={model?.divinm}
                    textAlign="center"
                    onChange={(value) => setData2({ divinm: value })}
                    readonly={true}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.pernm}
                    onChange={(value) => setData2({ pernm: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={230}>
                <LabelBox
                  title="보조자"
                  style={{ color: 'var(--color-red)' }}
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.onButtonclick2(model?.pernm2, BtnGubun.b_perid2all)}
                    >
                      담당자일괄상동
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    value={model?.perid2}
                    textAlign="center"
                    onChange={(value) => setData2({ perid2: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        setData2({
                          perid2: item.cd,
                          pernm2: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.pernm2}
                    onChange={(value) => setData2({ pernm2: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout size={93}>
                <LabelBox
                  title="점검공문"
                >
                  <CheckBox
                    value={model?.e035_official === '1'}
                    onChange={(v) => setData2({ e035_official: v ? '1' : '0' })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={100}>
                <LabelBox
                  title="문서번호"
                >
                  <TextBox
                    textAlign="center"
                    value={model?.gpaper}
                    onChange={(value) => setData2({ gpaper: value })}
                  />

                  <TextBox
                    textAlign="center"
                    value={model?.paper}
                    onChange={(value) => setData2({ paper: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={130}>
                <LabelBox
                  title="전화번호"
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.openTelnum()}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      <FiArrowRightCircle size={25}/>
                    </Button>
                  </FlexLayout>}
                >
                  <TelnumTextBox
                    textAlign="center"
                    value={model?.tel}
                    onChange={(value) => setData2({ tel: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={130}>
                <LabelBox
                  title="팩스번호"
                >
                  <TelnumTextBox
                    textAlign="center"
                    value={model?.fax}
                    onChange={(value) => setData2({ fax: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={100}>
                <LabelBox
                  title="현장담당자"
                >
                  <TextBox
                    textAlign="center"
                    value={model?.actpernm}
                    onChange={(value) => setData2({ actpernm: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={150}>
                <LabelBox
                  title="담당자메일"
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.personEmailModal(true)}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      <FiArrowRightCircle size={25}/>
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.actmail}
                    onChange={(value) => setData2({ actmail: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={130}>
                <LabelBox
                  title="담당자휴대폰번호"
                >
                  <TelnumTextBox
                    textAlign="center"
                    value={model?.hp}
                    onChange={(value) => setData2({ hp: value })}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>

              <FlexLayout size={630}>
                <LabelBox
                  title="거래처(연결된거래처)"
                  isNeed={true}
                  style={{ color: 'var(--color-red)' }}
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.taxModal(true)}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      <FiArrowRightCircle size={25}/>
                    </Button>

                    <DefaultLayout
                      size={192}
                      style={{
                        marginLeft: 16,
                        color: model?.cltcd === model?.cltcd11
                          ? 'var(--color-blue)'
                          : 'var(--color-red)',
                      }}
                    >
                    </DefaultLayout>

                    <DefaultLayout size={200} style={{
                      marginLeft: 16,
                      color: model?.cltcd === model?.cltcd11
                        ? 'var(--color-blue)'
                        : 'var(--color-red)',
                    }}>
                      {`유지보수거래처: ${model?.cltcd === model?.cltcd11 ? '같음' : '다름'}`}
                    </DefaultLayout>
                  </FlexLayout>}
                >
                  <TextBox
                    size={100}
                    value={model?.cltcd}
                    textAlign="center"
                    onChange={(item) => setData2({ cltcd: item.value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_XCLIENT',
                      {
                        uv_arg1: '1',
                      },
                      true,
                      (item) => {
                        setData2({
                          cltcd: item.cd,
                          pubcltnm: item.cdnm,
                          cltnm: item.cdnm,
                        }, () => scope.itemChangedActcd(item.cd, item.cdnm));
                      },
                    )}
                    readonly={!scope.state.data?.isNew}
                  />

                  <TextBox
                    textAlign="left"
                    value={model?.cltnm}
                    onChange={(value) => setData2({ cltnm: value })}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={200}>
                <LabelBox
                  title="거래처담당자"
                >
                  <TextBox
                    weight={1.4}
                    textAlign="left"
                    value={model?.pubpernm}
                    onChange={(value) => setData2({ pubpernm: value })}
                    readonly={!scope.state.data?.isNew}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout>
                <LabelBox
                  title="거래처주소"
                  footer={<FlexLayout>
                    <Button
                      onClick={() => {
                        setData({
                          basicAddressToggle: !scope.state.basicAddressToggle,
                        });
                      }}
                    >
                      {scope.state.basicAddressToggle ? '구' : '신'}주소보기
                    </Button>
                    <DefaultLayout size={110}>
                      <TextBox
                        value={`위도: ${model?.publat || ''}`}
                        readonly={true}
                        transparent={true}
                        size={180}
                      />
                    </DefaultLayout>
                    <DefaultLayout size={110}>
                      <TextBox
                        value={`경도: ${model?.publng || ''}`}
                        readonly={true}
                        transparent={true}
                        size={180}
                      />
                    </DefaultLayout>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 24 }}
                      onClick={() => window.open(`http://map.daum.net/link/map/${model?.pubcltnm},${model?.publat},${model?.publng}`)}
                    >
                      <IoIosPin/>
                    </Button>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 24 }}
                      onClick={() => window.open(`http://map.daum.net/link/roadview/${model?.pubcltnm},${model?.publat},${model?.publng}`)}
                    >
                      <IoMdCamera/>
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.pubzipcd}
                    onChange={(value) => setData2({ pubzipcd: value })}
                    readonly={!scope.state.data?.isNew}
                    size={100}
                  />
                  <TextBox
                    textAlign="left"
                    value={scope.state.basicAddressToggle
                      ? model?.pubaddress
                      : model?.oldpubaddress
                    }
                    onChange={(value) => setData2({
                      [scope.state.basicAddressToggle ? 'pubaddress' : 'oldpubaddress']: value,
                    })}
                    readonly={!scope.state.data?.isNew}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <FlexLayout size={630}>
                <LabelBox
                  title="승강기소재지"
                  isNeed={true}
                  style={{ color: 'var(--color-red)' }}
                  footer={<FlexLayout>
                    <AddressButton
                      publicStore={scope.props.publicStore}
                      modalStore={scope.props.modalStore}
                      onChange={(address) => setData2({
                        zipcode: address.zonecode,
                        address: address.address,
                        address2: address.jibunAddress,
                        lat: address.y,
                        lng: address.x,
                      })}
                      isIcon={true}
                    >
                      <FiArrowRightCircle size={25}/>
                    </AddressButton>
                    <Button
                      onClick={() => {
                        setData({
                          basicAddressToggle2: !scope.state.basicAddressToggle2,
                        });
                      }}
                    >
                      {scope.state.basicAddressToggle2 ? '구' : '신'}주소보기
                    </Button>
                    <DefaultLayout size={150}>
                      <TextBox
                        value={`위도: ${model?.lat || ''}`}
                        readonly={true}
                        transparent={true}
                        size={180}
                      />
                    </DefaultLayout>
                    <DefaultLayout size={150}>
                      <TextBox
                        value={`경도: ${model?.lng || ''}`}
                        readonly={true}
                        transparent={true}
                        size={180}
                      />
                    </DefaultLayout>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 24 }}
                      onClick={() => window.open(`http://map.daum.net/link/map/${model?.pubcltnm},${model?.publat},${model?.publng}`)}
                    >
                      <IoIosPin/>
                    </Button>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 24 }}
                      onClick={() => window.open(`http://map.daum.net/link/roadview/${model?.pubcltnm},${model?.publat},${model?.publng}`)}
                    >
                      <IoMdCamera/>
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.zipcode}
                    onChange={(value) => setData2({ zipcode: value })}
                    size={100}
                    readonly={true}
                  />
                  <TextBox
                    textAlign="left"
                    value={scope.state.basicAddressToggle2
                      ? model?.address
                      : model?.oldaddress
                    }
                    onChange={(value) => setData2({
                      [scope.state.basicAddressToggle2 ? 'address' : 'oldaddress']: value,
                    })}
                  />
                  <TextBox
                    textAlign="left"
                    value={model?.address2}
                    onChange={(value) => setData2({ address2: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={380}>
                <LabelBox
                  title="지로발송처"
                  footer={<FlexLayout
                    style={{
                      marginLeft: 90,
                    }}
                  >
                      <Button
                      onClick={() => setData2({
                        anzipcd: model?.zipcode,
                        anaddress: model?.address,
                        anaddress2: model?.address2,
                        anlat: model?.lat,
                        anlng: model?.lng,
                      })}
                    >
                      상동
                    </Button>
                    <AddressButton
                      publicStore={scope.props.publicStore}
                      modalStore={scope.props.modalStore}
                      onChange={(address) => setData2({
                        anzipcd: address.zonecode,
                        anaddress: address.address,
                        anaddress2: address.jibunAddress,
                        publat: address.y,
                        publng: address.x,
                      })}
                      isIcon={true}
                    >
                      <FiArrowRightCircle size={25}/>
                    </AddressButton>
                    <Button
                      onClick={() => {
                        setData({
                          basicAddressToggle3: !scope.state.basicAddressToggle3,
                        });
                      }}
                    >
                      {scope.state.basicAddressToggle3 ? '구' : '신'}주소보기
                    </Button>
                    <DefaultLayout size={70}>
                    </DefaultLayout>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 24 }}
                      onClick={() => window.open(`http://map.daum.net/link/map/${model?.pubcltnm},${model?.publat},${model?.publng}`)}
                    >
                      <IoIosPin/>
                    </Button>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 24 }}
                      onClick={() => window.open(`http://map.daum.net/link/roadview/${model?.pubcltnm},${model?.publat},${model?.publng}`)}
                    >
                      <IoMdCamera/>
                    </Button>
                  </FlexLayout>}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.anzipcd}
                    onChange={(value) => setData2({ anzipcd: value })}
                    size={70}
                    readonly={true}
                  />
                  <TextBox
                    textAlign="left"
                    value={scope.state.basicAddressToggle3
                      ? model?.anaddress
                      : model?.oldanaddress
                    }
                    onChange={(value) => setData2({
                      [scope.state.basicAddressToggle3 ? 'anaddress' : 'oldanaddress']: value,
                    })}
                  />
                  <TextBox
                    textAlign="left"
                    value={model?.anaddress2}
                    onChange={(value) => setData2({ anaddress2: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={150}>
                <LabelBox
                  title="지로발송처명"
                >
                  <TextBox
                    textAlign="left"
                    value={model?.ancltnm}
                    onChange={(value) => setData2({ ancltnm: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={110}>
                <LabelBox
                  title="담당"
                >
                  <TextBox
                    textAlign="center"
                    value={model?.anpernm}
                    onChange={(value) => setData2({ anpernm: value })}
                  />
                </LabelBox>
              </FlexLayout>

            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <FlexLayout>
                <FlexLayout size={850}>
                  <LabelBox title="비고(전달사항)">
                    <TextBox
                      textAlign="left"
                      value={model?.remark}
                      onChange={(value) => setData2({ remark: value })}
                      isMultiline={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout isVertical={true}>
                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <LabelBox title="보수업체명">
                      <TextBox
                        textAlign="left"
                        value={model?.bosunm}
                        onChange={(value) => setData2({ bosunm: value })}
                      />
                    </LabelBox>

                    <LabelBox
                      title="보수업체연락처"
                    >
                      <TelnumTextBox
                        textAlign="center"
                        value={model?.bosutelnum}
                        onChange={(value) => setData2({ bosutelnum: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <LabelBox
                      title="현장아이디"
                    >
                      <TextBox
                        textAlign="left"
                        value={model?.actid}
                        onChange={(value) => setData2({ actid: value })}
                      />
                    </LabelBox>

                    <LabelBox
                      title="현장비밀번호"
                    >
                      <TextBox
                        textAlign="left"
                        value={model?.actpw}
                        onChange={(value) => setData2({ actpw: value })}
                      />
                    </LabelBox>
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>

          {scope.tabIndex === EnrollmentTabId.UNIT
          && <FlexLayout
            size={10}
            justify="right"
          >
            <FlexLayout justify="left" style={{ paddingLeft: 10 }}>
              <Button onClick={() => scope.openChangeUnit()}>호기 코드변경</Button>
            </FlexLayout>
            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.BLUE}
            >
                <FiMonitor></FiMonitor>
              원격제어
            </Button>

            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.BLUE}
                onClick={() => scope.openUnitRemoteControl()}
            >
                <RiLinksFill></RiLinksFill>
              정보센터연동
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.unitCreate()}
            >
              호기생성
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.unitAdd()}
            >
              추가
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.RED}
                  onClick={() => scope.unitDelete()}
            >
              삭제
            </Button>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.UNIT
          && <FlexLayout>
              <FlexLayout
                  weight={0.27}
                  isVertical={true}
                  disabled={!model2?.actcd}
                  disabledText="호기생성을 해주세요"
              >
            <FlexLayout>
              <TableLayout
                ref={scope.tableUnit}
                scope={scope}
                header={scope.tabHeaders[scope.tabIndex]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={scope.infinity2}
                data={scope.state.units}
                onRowFocusEvent={(x, i) => scope.onDetailRowFocusEvent(x, i)}
                onChange={(rows, updatedRows) => {
                  setData({
                    [EnrollmentTabDataStateNames[scope.tabIndex]]: rows,
                    [EnrollmentTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
                  });
                }}
              />
            </FlexLayout>

            </FlexLayout>
              <FlexLayout
                isVertical={true}
                disabled={!model2?.actcd}
                disabledText="호기생성을 해주세요"
              >
              <FlexLayout
                isVertical={true}
                isScroll={true}
              >
                <LayoutTitle>
                  <FlexLayout>
                    승강기 기본정보
                      <div style={{ position: 'relative', left: '359px' }}>
                        고장인센적용제외
                          <CheckBox
                            value={model2?.incentive === '1'}
                            onChange={(value) => {
                              setModel2State({ incentive: value ? '1' : '0' });
                              scope.dw_6_itemChanged(value, 'incentive');
                            }}
                            style={{ height: '3px', transform: 'translateX(4px) translateY(4px)' }}
                          />
                      </div>
                  </FlexLayout>
                </LayoutTitle>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <FlexLayout size={167}>
                    <LabelBox
                      title="호기"
                      isNeed={true}
                  >
                    <TextBox
                      textAlign="center"
                      value={model2?.equpcd}
                      onChange={(value) => setModel2State({ equpcd: value })}
                      readonly={true}
                      size={70}
                    />
                      <TextBox
                        textAlign="left"
                        value={model2?.equpnm}
                        onChange={(value) => {
                          setModel2State({ equpnm: value });
                          scope.dw_6_itemChanged(value, 'equpnm');
                        }}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={167}>
                    <LabelBox
                      title="승강기번호"
                    >
                      <FormatTextBox
                        textAlign="center"
                        format="####-###"
                        value={model2?.elno}
                        onChange={(value) => setModel2StateForElno({ elno: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="구분"
                      >
                        <ComboBox
                          value={model2?.gubun}
                          data={scope.state.gubuns2?.map((y) => new ComboBoxModel(y.eligcd, y.elignm))}
                          onSelect={(option) => setModel2State({ gubun: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="종류"
                      >
                        <ComboBox
                          value={model2?.kind}
                          data={scope.state.kinds?.map((y) => new ComboBoxModel(y.elijcd, y.elijnm))}
                          onSelect={(option) => setModel2State({ kind: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="형식"
                      >
                        <ComboBox
                          value={model2?.form}
                          data={scope.state.forms?.map((y) => new ComboBoxModel(y.elifcd, y.elifnm))}
                          onSelect={(option) => setModel2State({ form: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="도어방식"
                      >
                        <ComboBox
                          value={model2?.inver}
                          data={scope.state.invers?.map((y) => new ComboBoxModel(y.invercd, y.invernm))}
                          onSelect={(option) => {
                            setModel2State({ inver: option.value });
                            scope.dw_6_itemChanged(option.value, 'inver');
                          }}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <FlexLayout size={167}>
                    <LabelBox
                      title="점검기종"
                      isNeed={true}
                      footer={<FlexLayout>
                        <Button
                          onClick={() => scope.goChkPreset()}
                          isIcon={true}
                        >
                          <FiArrowRightCircle size={25}/>
                        </Button>
                      </FlexLayout>}
                    >
                      <ComboBox
                        value={model2?.evcd_new}
                        data={scope.state.evcds?.map((y) => new ComboBoxModel(y.evlcd, y.evlnm))}
                        onSelect={(option) => {
                          setModel2State({ evcd_new: option.value });
                          scope.dw_6_itemChanged(option.value, 'evcd_new');
                        }}
                      />
                      </LabelBox>
                  </FlexLayout>

                    <FlexLayout size={83}>
                      <LabelBox
                        title="제어반"
                      >
                        <ComboBox
                          value={model2?.jeoban}
                          data={[
                            { value: '01', remark: 'MR' },
                            { value: '02', remark: 'MRL' },
                          ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={(option) => setModel2State({ jeoban: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={84}>
                      <LabelBox
                        title="인화물구분"
                      >
                        <ComboBox
                          value={model2?.class2}
                          data={[
                            { value: 'P', remark: '인승용' },
                            { value: 'F', remark: '화물용' },
                          ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={(option) => {
                            setModel2State({ class2: option.value });
                            scope.dw_6_itemChanged(option.value, 'class2');
                          }}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="모델명"
                        style={{ color: 'var(--color-red)' }}
                      >
                        <TextBox
                          textAlign="center"
                          value={model2?.model}
                          onChange={(value) => setModel2State({ model: value })}
                          size={50}
                        />
                        <TextBox
                          textAlign="left"
                          value={model2?.mdnm}
                          onChange={(value) => setModel2State({ mdnm: value })}
                          readonly={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="제조업체"
                        style={{ color: 'var(--color-red)' }}
                      >
                        <TextBox
                          textAlign="center"
                          value={model2?.manucd}
                          onChange={(value) => setModel2State({ manucd: value })}
                          size={50}
                        />
                        <TextBox
                          textAlign="left"
                          value={model2?.manunm}
                          onChange={(value) => setModel2State({ manunm: value })}
                          readonly={true}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={56}>
                      <LabelBox
                        title="층수"
                      >
                        <TextBox
                          textAlign="center"
                          value={model2?.number}
                          onChange={(value) => {
                            setModel2State({ number: value });
                            scope.dw_6_itemChanged(value, 'number');
                          }}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={56}>
                      <LabelBox
                        title="지상"
                      >
                        <TextBox
                          textAlign="center"
                          value={model2?.number_gr}
                          onChange={(value) => setModel2State({ number_gr: value })}
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={56}>
                      <LabelBox
                        title="지하"
                      >
                        <TextBox
                          textAlign="center"
                          value={model2?.number_ungr}
                          onChange={(value) => setModel2State({ number_ungr: value })}
                        />
                      </LabelBox>
                    </FlexLayout>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <FlexLayout size={83}>
                    <LabelBox
                      title="최대정원"
                    >
                      <FormatTextBox
                        value={model2?.nperson}
                        format="##인승"
                        onChange={(value) => {
                          setModel2State({ nperson: value });
                          scope.dw_6_itemChanged(value, 'nperson');
                        }}
                        weight={2}
                      />
                      </LabelBox>
                  </FlexLayout>

                    <FlexLayout size={83}>
                      <LabelBox
                        title="적재하중"
                      >
                        <FormatTextBox
                          value={model2?.height}
                          format="####kg"
                          onChange={(value) => setModel2State({ height: value })}
                          weight={2}
                        />
                      </LabelBox>
                    </FlexLayout>

                  <FlexLayout size={167}>
                    <LabelBox
                      title="정격속도"
                    >
                      <ComboBox
                        value={model2?.speed}
                        data={scope.state.speeds?.map((y) => new ComboBoxModel(y.prospcd, y.prospnm))}
                        onSelect={(option) => {
                          setModel2State({ speed: option.value });
                          scope.dw_6_itemChanged(option.value, 'speed');
                        }}
                        isDisabledTrackingStateChange={true}
                      />

                      <TextBox
                        textAlign="center"
                        value={model2?.speednm}
                        onChange={(value) => setModel2State({ speednm: value })}
                        readonly={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="사양"
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.sizecd}
                        onChange={(value) => setModel2State({ sizecd: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="점검대상 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;원격"
                    >
                      <CheckBox
                        value={model2?.kcflag === '1'}
                        onChange={(v) => setModel2State({ kcflag: v ? '1' : '0' })}
                      />
                      <CheckBox
                        value={model2?.remflag === 'Y'}
                        onChange={(v) => {
                          setModel2State({ remflag: v ? 'Y' : 'N' });
                          scope.dw_6_itemChanged(v, 'remflag');
                        }}
                      />
                    </LabelBox>
                  </FlexLayout>
                    <FlexLayout>
                      <LabelBox
                        title="도면"
                          footer={<FlexLayout>
                            <Button
                              onClick={() => scope.copyDP()}
                            >
                              전체
                            </Button>
                            <Button
                              onClick={() => {
                                setData({
                                  isDPModalVisible: true,
                                });
                              }}
                              isIcon={true}
                              theme={BUTTON_THEMES.BLUE}
                            >
                              <FiFolderPlus size={25}/>
                            </Button>
                          </FlexLayout>}
                      >
                        <TextBox
                          textAlign="center"
                          color={Number(model2?.dpattcnt) > 0 ? 'var(--color-blue)'
                            : 'var(--color-red)'}
                          value={Number(model2?.dpattcnt) > 0 ? '도면 있음' : '도면 없음'}
                        />
                      </LabelBox>
                    </FlexLayout>

                  <FlexLayout size={100}>
                    <LabelBox
                      title="CCTV"
                      footer={<FlexLayout>
                        <Button
                          onClick={() => scope.copyCCTV()}
                        >
                          전체
                        </Button>
                      </FlexLayout>}
                    >

                      <ComboBox
                        style={Number(model2?.cctv) > 0 ? { color: 'var(--color-blue)' }
                          : { color: 'var(--color-red)' }}
                        value={model2?.cctv}
                        data={[
                          { value: '0', remark: '없음' },
                          { value: '1', remark: '있음' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => {
                          setModel2State({ cctv: option.value });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="PLC/TAC"
                        footer={<FlexLayout>
                          <Button
                            onClick={() => scope.openPLC()}
                            isIcon={true}
                            theme={BUTTON_THEMES.BLUE}
                          >
                            <FiArrowRightCircle size={25}/>
                          </Button>
                          <Button
                            onClick={() => {
                              if (model2?.plc !== '1') {
                                ConfirmWarning.show('오류', '먼저 사용여부를 사용으로 설정하세요');
                                return;
                              }

                              setData({
                                isPLCModalVisible: true,
                              });
                            }}
                            isIcon={true}
                            theme={BUTTON_THEMES.BLUE}
                          >
                            <FiFolderPlus size={25}/>
                          </Button>
                        </FlexLayout>}
                      >
                        <ComboBox
                          style={Number(model2?.plc) > 0 ? { color: 'var(--color-blue)' }
                            : { color: 'var(--color-red)' }}
                          value={model2?.plc}
                          data={[
                            { value: '0', remark: '미사용' },
                            { value: '1', remark: '사용' },
                          ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={(option) => {
                            setModel2State({ plc: option.value });
                            scope.dw_6_itemChanged(option.value, 'plc');
                          }}
                          isDisabledTrackingStateChange={true}
                        />
                        <TextBox
                          textAlign="center"
                          color={Number(model2?.attcnt) > 0 ? 'var(--color-blue)'
                            : 'var(--color-red)'}
                          value={Number(model2?.attcnt) > 0 ? '파일 있음' : '파일 없음'}
                        />
                      </LabelBox>
                    </FlexLayout>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <FlexLayout size={168}>
                    <LabelBox
                      title="최초설치일자"
                    >
                      <DateTextBox
                        value={model2?.yyyymm}
                        textAlign="center"
                        format="####-##-##"
                        onChange={
                          (value) => setModel2State({ yyyymm: value })
                        }
                      />
                    </LabelBox>
                  </FlexLayout>

                    <FlexLayout size={167}>
                      <LabelBox
                        title="설치일자"
                      >
                        <DateTextBox
                          value={model2?.yyyymm2}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => setModel2State({ yyyymm2: value })
                          }
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="운행유효기간-시작일"
                      >
                        <DateTextBox
                          value={model2?.kcstdate}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => setModel2State({ kcstdate: value })
                          }
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="종료일"
                      >
                        <DateTextBox
                          value={model2?.kcenddate}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => setModel2State({ kcenddate: value })
                          }
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox
                        title="최종검사일"
                      >
                        <DateTextBox
                          value={model2?.totkcdate}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => setModel2State({ totkcdate: value })
                          }
                        />
                      </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox title="최종검사종류 / 결과">
                        <ComboBox
                          value={model2?.kcgubun}
                          data={scope.state.kcgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={(option) => setModel2State({ kcgubun: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                        <ComboBox
                          value={model2?.kcresult}
                          data={scope.state.kcresults?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={(option) => setModel2State({ kcresult: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>


              </FlexLayout>

              <LayoutTitle>
                승강기 재원정보
              </LayoutTitle>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename01}
                    onChange={(value) => setModel2State({ typename01: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type01}
                      onChange={(value) => setModel2State({ type01: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename02}
                    onChange={(value) => setModel2State({ typename02: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type02}
                      onChange={(value) => setModel2State({ type02: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename03}
                    onChange={(value) => setModel2State({ typename03: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type03}
                      onChange={(value) => setModel2State({ type03: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename04}
                    onChange={(value) => setModel2State({ typename04: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type04}
                      onChange={(value) => setModel2State({ type04: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename05}
                    onChange={(value) => setModel2State({ typename05: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type05}
                      onChange={(value) => setModel2State({ type05: value })}
                    />
                  </EditTitleLabelBox>

                    <EditTitleLabelBox
                      value={model2?.typename06}
                      onChange={(value) => setModel2State({ typename06: value })}
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.type06}
                        onChange={(value) => setModel2State({ type06: value })}
                      />
                    </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename07}
                    onChange={(value) => setModel2State({ typename07: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type07}
                      onChange={(value) => setModel2State({ type07: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename08}
                    onChange={(value) => setModel2State({ typename08: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type08}
                      onChange={(value) => setModel2State({ type08: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename09}
                    onChange={(value) => setModel2State({ typename09: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type09}
                      onChange={(value) => setModel2State({ type09: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename10}
                    onChange={(value) => setModel2State({ typename10: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type10}
                      onChange={(value) => setModel2State({ type10: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename11}
                    onChange={(value) => setModel2State({ typename11: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type11}
                      onChange={(value) => setModel2State({ type11: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename12}
                    onChange={(value) => setModel2State({ typename12: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type12}
                      onChange={(value) => setModel2State({ type12: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename13}
                    onChange={(value) => setModel2State({ typename13: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type13}
                      onChange={(value) => setModel2State({ type13: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename14}
                    onChange={(value) => setModel2State({ typename14: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type14}
                      onChange={(value) => setModel2State({ type14: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename15}
                    onChange={(value) => setModel2State({ typename15: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type15}
                      onChange={(value) => setModel2State({ type15: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename16}
                    onChange={(value) => setModel2State({ typename16: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type16}
                      onChange={(value) => setModel2State({ type16: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename17}
                    onChange={(value) => setModel2State({ typename17: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type17}
                      onChange={(value) => setModel2State({ type17: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename18}
                    onChange={(value) => setModel2State({ typename18: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type18}
                      onChange={(value) => setModel2State({ type18: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename19}
                    onChange={(value) => setModel2State({ typename19: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type19}
                      onChange={(value) => setModel2State({ type19: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename20}
                    onChange={(value) => setModel2State({ typename20: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type20}
                      onChange={(value) => setModel2State({ type20: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename21}
                    onChange={(value) => setModel2State({ typename21: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type21}
                      onChange={(value) => setModel2State({ type21: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename22}
                    onChange={(value) => setModel2State({ typename22: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type22}
                      onChange={(value) => setModel2State({ type22: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename23}
                    onChange={(value) => setModel2State({ typename23: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type23}
                      onChange={(value) => setModel2State({ type23: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename24}
                    onChange={(value) => setModel2State({ typename24: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type24}
                      onChange={(value) => setModel2State({ type24: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename25}
                    onChange={(value) => setModel2State({ typename25: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type25}
                      onChange={(value) => setModel2State({ type25: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename26}
                    onChange={(value) => setModel2State({ typename26: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type26}
                      onChange={(value) => setModel2State({ type26: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename27}
                    onChange={(value) => setModel2State({ typename27: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type27}
                      onChange={(value) => setModel2State({ type27: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename28}
                    onChange={(value) => setModel2State({ typename28: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type28}
                      onChange={(value) => setModel2State({ type28: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename29}
                    onChange={(value) => setModel2State({ typename29: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type29}
                      onChange={(value) => setModel2State({ type29: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename30}
                    onChange={(value) => setModel2State({ typename30: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type30}
                      onChange={(value) => setModel2State({ type30: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename31}
                    onChange={(value) => setModel2State({ typename31: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type31}
                      onChange={(value) => setModel2State({ type31: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename32}
                    onChange={(value) => setModel2State({ typename32: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type32}
                      onChange={(value) => setModel2State({ type32: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename33}
                    onChange={(value) => setModel2State({ typename33: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type33}
                      onChange={(value) => setModel2State({ type33: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename34}
                    onChange={(value) => setModel2State({ typename34: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type34}
                      onChange={(value) => setModel2State({ type34: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename35}
                    onChange={(value) => setModel2State({ typename35: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type35}
                      onChange={(value) => setModel2State({ type35: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename36}
                    onChange={(value) => setModel2State({ typename36: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type36}
                      onChange={(value) => setModel2State({ type36: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename37}
                    onChange={(value) => setModel2State({ typename37: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type37}
                      onChange={(value) => setModel2State({ type37: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename38}
                    onChange={(value) => setModel2State({ typename38: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type38}
                      onChange={(value) => setModel2State({ type38: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename39}
                    onChange={(value) => setModel2State({ typename39: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type39}
                      onChange={(value) => setModel2State({ type39: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename40}
                    onChange={(value) => setModel2State({ typename40: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type40}
                      onChange={(value) => setModel2State({ type40: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename41}
                    onChange={(value) => setModel2State({ typename41: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type41}
                      onChange={(value) => setModel2State({ type41: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename42}
                    onChange={(value) => setModel2State({ typename42: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type42}
                      onChange={(value) => setModel2State({ type42: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename43}
                    onChange={(value) => setModel2State({ typename43: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type43}
                      onChange={(value) => setModel2State({ type43: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename44}
                    onChange={(value) => setModel2State({ typename44: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type44}
                      onChange={(value) => setModel2State({ type44: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename45}
                    onChange={(value) => setModel2State({ typename45: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type45}
                      onChange={(value) => setModel2State({ type45: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename46}
                    onChange={(value) => setModel2State({ typename46: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type46}
                      onChange={(value) => setModel2State({ type46: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename47}
                    onChange={(value) => setModel2State({ typename47: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type47}
                      onChange={(value) => setModel2State({ type47: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename48}
                    onChange={(value) => setModel2State({ typename48: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type48}
                      onChange={(value) => setModel2State({ type48: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename49}
                    onChange={(value) => setModel2State({ typename49: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type49}
                      onChange={(value) => setModel2State({ type49: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename50}
                    onChange={(value) => setModel2State({ typename50: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type50}
                      onChange={(value) => setModel2State({ type50: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename51}
                    onChange={(value) => setModel2State({ typename51: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type51}
                      onChange={(value) => setModel2State({ type51: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename52}
                    onChange={(value) => setModel2State({ typename52: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type52}
                      onChange={(value) => setModel2State({ type52: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename53}
                    onChange={(value) => setModel2State({ typename53: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type53}
                      onChange={(value) => setModel2State({ type53: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename54}
                    onChange={(value) => setModel2State({ typename54: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type54}
                      onChange={(value) => setModel2State({ type54: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename55}
                    onChange={(value) => setModel2State({ typename55: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type55}
                      onChange={(value) => setModel2State({ type55: value })}
                  />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename56}
                    onChange={(value) => setModel2State({ typename56: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type56}
                      onChange={(value) => setModel2State({ type56: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename57}
                    onChange={(value) => setModel2State({ typename57: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type57}
                      onChange={(value) => setModel2State({ type57: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename58}
                    onChange={(value) => setModel2State({ typename58: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type58}
                      onChange={(value) => setModel2State({ type58: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename59}
                    onChange={(value) => setModel2State({ typename59: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type59}
                      onChange={(value) => setModel2State({ type59: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename60}
                    onChange={(value) => setModel2State({ typename60: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type60}
                      onChange={(value) => setModel2State({ type60: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename61}
                    onChange={(value) => setModel2State({ typename61: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type61}
                      onChange={(value) => setModel2State({ type61: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename62}
                    onChange={(value) => setModel2State({ typename62: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type62}
                      onChange={(value) => setModel2State({ type62: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename63}
                    onChange={(value) => setModel2State({ typename63: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type63}
                      onChange={(value) => setModel2State({ type63: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename64}
                    onChange={(value) => setModel2State({ typename64: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type64}
                      onChange={(value) => setModel2State({ type64: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename65}
                    onChange={(value) => setModel2State({ typename65: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type65}
                      onChange={(value) => setModel2State({ type65: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename66}
                    onChange={(value) => setModel2State({ typename66: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type66}
                      onChange={(value) => setModel2State({ type66: value })}
                    />
                  </EditTitleLabelBox>

                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename67}
                    onChange={(value) => setModel2State({ typename67: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type67}
                      onChange={(value) => setModel2State({ type67: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename68}
                    onChange={(value) => setModel2State({ typename68: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type68}
                      onChange={(value) => setModel2State({ type68: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename69}
                    onChange={(value) => setModel2State({ typename69: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type69}
                      onChange={(value) => setModel2State({ type69: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename70}
                    onChange={(value) => setModel2State({ typename70: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type70}
                      onChange={(value) => setModel2State({ type70: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename71}
                    onChange={(value) => setModel2State({ typename71: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type71}
                      onChange={(value) => setModel2State({ type71: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename72}
                    onChange={(value) => setModel2State({ typename72: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type72}
                      onChange={(value) => setModel2State({ type72: value })}
                    />
                  </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename73}
                    onChange={(value) => setModel2State({ typename73: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type73}
                      onChange={(value) => setModel2State({ type73: value })}
                    />
                  </EditTitleLabelBox>

                    <EditTitleLabelBox
                      value={model2?.typename74}
                      onChange={(value) => setModel2State({ typename74: value })}
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.type74}
                        onChange={(value) => setModel2State({ type74: value })}
                      />
                    </EditTitleLabelBox>

                    <EditTitleLabelBox
                      value={model2?.typename75}
                      onChange={(value) => setModel2State({ typename75: value })}
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.type75}
                        onChange={(value) => setModel2State({ type75: value })}
                      />
                    </EditTitleLabelBox>

                    <EditTitleLabelBox
                      value={model2?.typename76}
                      onChange={(value) => setModel2State({ typename76: value })}
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.type76}
                        onChange={(value) => setModel2State({ type76: value })}
                      />
                    </EditTitleLabelBox>

                    <EditTitleLabelBox
                      value={model2?.typename77}
                      onChange={(value) => setModel2State({ typename77: value })}
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.type77}
                        onChange={(value) => setModel2State({ type77: value })}
                      />
                    </EditTitleLabelBox>

                    <EditTitleLabelBox
                      value={model2?.typename78}
                      onChange={(value) => setModel2State({ typename78: value })}
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.type78}
                        onChange={(value) => setModel2State({ type78: value })}
                      />
                    </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename79}
                    onChange={(value) => setModel2State({ typename79: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type67}
                      onChange={(value) => setModel2State({ type67: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                      value={model2?.typename80}
                      onChange={(value) => setModel2State({ typename80: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type80}
                      onChange={(value) => setModel2State({ type80: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename81}
                    onChange={(value) => setModel2State({ typename81: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type81}
                      onChange={(value) => setModel2State({ type81: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename82}
                    onChange={(value) => setModel2State({ typename82: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type82}
                      onChange={(value) => setModel2State({ type82: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename83}
                    onChange={(value) => setModel2State({ typename83: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type83}
                      onChange={(value) => setModel2State({ type83: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename84}
                    onChange={(value) => setModel2State({ typename84: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type84}
                      onChange={(value) => setModel2State({ type84: value })}
                    />
                  </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename85}
                    onChange={(value) => setModel2State({ typename85: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type85}
                      onChange={(value) => setModel2State({ type85: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename86}
                    onChange={(value) => setModel2State({ typename86: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type86}
                      onChange={(value) => setModel2State({ type86: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename87}
                    onChange={(value) => setModel2State({ typename87: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type87}
                      onChange={(value) => setModel2State({ type87: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename88}
                    onChange={(value) => setModel2State({ typename88: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type88}
                      onChange={(value) => setModel2State({ type88: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename89}
                    onChange={(value) => setModel2State({ typename89: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type89}
                      onChange={(value) => setModel2State({ type89: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename90}
                    onChange={(value) => setModel2State({ typename90: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type90}
                      onChange={(value) => setModel2State({ type90: value })}
                    />
                  </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename91}
                    onChange={(value) => setModel2State({ typename91: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type91}
                      onChange={(value) => setModel2State({ type91: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename92}
                    onChange={(value) => setModel2State({ typename92: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type92}
                      onChange={(value) => setModel2State({ type92: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename93}
                    onChange={(value) => setModel2State({ typename93: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type93}
                      onChange={(value) => setModel2State({ type93: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename94}
                    onChange={(value) => setModel2State({ typename94: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type94}
                      onChange={(value) => setModel2State({ type94: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename95}
                    onChange={(value) => setModel2State({ typename95: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type95}
                      onChange={(value) => setModel2State({ type95: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename96}
                    onChange={(value) => setModel2State({ typename96: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type96}
                      onChange={(value) => setModel2State({ type96: value })}
                    />
                  </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename97}
                    onChange={(value) => setModel2State({ typename97: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type97}
                      onChange={(value) => setModel2State({ type97: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename98}
                    onChange={(value) => setModel2State({ typename98: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type98}
                      onChange={(value) => setModel2State({ type98: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename99}
                    onChange={(value) => setModel2State({ typename99: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type99}
                      onChange={(value) => setModel2State({ type99: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename100}
                    onChange={(value) => setModel2State({ typename100: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type100}
                      onChange={(value) => setModel2State({ type100: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename101}
                    onChange={(value) => setModel2State({ typename101: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type101}
                      onChange={(value) => setModel2State({ type101: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename102}
                    onChange={(value) => setModel2State({ typename102: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type102}
                      onChange={(value) => setModel2State({ type102: value })}
                    />
                  </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename103}
                    onChange={(value) => setModel2State({ typename103: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type103}
                      onChange={(value) => setModel2State({ type103: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename104}
                    onChange={(value) => setModel2State({ typename104: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type104}
                      onChange={(value) => setModel2State({ type104: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename105}
                    onChange={(value) => setModel2State({ typename105: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type105}
                      onChange={(value) => setModel2State({ type105: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename106}
                    onChange={(value) => setModel2State({ typename106: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type106}
                      onChange={(value) => setModel2State({ type106: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename107}
                    onChange={(value) => setModel2State({ typename107: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type107}
                      onChange={(value) => setModel2State({ type107: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename108}
                    onChange={(value) => setModel2State({ typename108: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type108}
                      onChange={(value) => setModel2State({ type108: value })}
                    />
                  </EditTitleLabelBox>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <EditTitleLabelBox
                    value={model2?.typename109}
                    onChange={(value) => setModel2State({ typename109: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type109}
                      onChange={(value) => setModel2State({ type109: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox
                    value={model2?.typename110}
                    onChange={(value) => setModel2State({ typename110: value })}
                  >
                    <TextBox
                      textAlign="left"
                      value={model2?.type110}
                      onChange={(value) => setModel2State({ type110: value })}
                    />
                  </EditTitleLabelBox>

                  <EditTitleLabelBox>
                    <TextBox/>
                  </EditTitleLabelBox>

                  <EditTitleLabelBox>
                    <TextBox/>
                  </EditTitleLabelBox>

                  <EditTitleLabelBox>
                    <TextBox/>
                  </EditTitleLabelBox>

                  <EditTitleLabelBox>
                    <TextBox/>
                  </EditTitleLabelBox>

                </FlexLayout>

                <LayoutTitle>
                  승강기 보험 / 비고(세대수)
                </LayoutTitle>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <LabelBox
                      title="보험사명"
                    >
                      <TextBox
                        textAlign="left"
                        value={model2?.company_nm}
                        onChange={(value) => setModel2State({ company_nm: value })}
                      />
                    </LabelBox>

                      <LabelBox
                        title="보험종류"
                      >
                        <TextBox
                          textAlign="left"
                          value={model2?.insurance_kind}
                          onChange={(value) => setModel2State({ insurance_kind: value })}
                        />
                      </LabelBox>

                        <LabelBox
                          title="계약기간-시작일"
                        >
                          <DateTextBox
                            value={model2?.cont_st_de}
                            textAlign="center"
                            format="####-##-##"
                            onChange={
                              (value) => setModel2State({ cont_st_de: value })
                            }
                          />
                        </LabelBox>

                        <LabelBox
                          title="종료일"
                        >
                          <DateTextBox
                            value={model2?.cont_en_de}
                            onChange={
                              (value) => setModel2State({ cont_en_de: value })
                            }
                            textAlign="center"
                            format="####-##-##"
                          />
                        </LabelBox>

                    <LabelBox title="비고(세대수)">
                      <TextBox
                        textAlign="left"
                        value={model2?.remark}
                        onChange={(value) => setModel2State({ remark: value })}
                      />
                    </LabelBox>

                  </FlexLayout>
              </FlexLayout>
              </FlexLayout>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.CONTRACKINFORMATION
          && <FlexLayout
            size={10}
            justify="right"
          >
            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.informationCenterSend()}
            >
              <RiLinksFill />
              정보센터전송
            </Button>

            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.historyPopupModal(true)}
            >
              계약기록
            </Button>

            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.contractionResister()}
            >
              계약등록
            </Button>

            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.RED}
                onClick={() => scope.contractionDelete()}
            >
              계약삭제
            </Button>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.CONTRACKINFORMATION
          && model3?.contdate === '' && scope.state.contractVisible === false && <FlexLayout>
            <LabelBox
              title="등록된 계약 내용이 없습니다. 계약내용을 등록해주세요!"
              style={{ color: 'var(--color-red)' }}
            >
            </LabelBox>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.CONTRACKINFORMATION
          && (model3?.contdate !== '' || scope.state.contractVisible === true) && <FlexLayout>
            <FlexLayout
              isVertical={true}
              isScroll={true}
            >
              <LayoutTitle>
                현재 계약정보
              </LayoutTitle>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="계약일자"
                  isNeed={true}
                >
                  <DateTextBox
                    value={model3?.contdate || ''}
                    textAlign="center"
                    format="####-##-##"
                    onChange={
                      (value) => setModel3State({ contdate: value })
                    }
                  />
                </LabelBox>

                  <FlexLayout>
                    <LabelBox
                      title="계약접수일자"
                    >
                      <DateTextBox
                        textAlign="center"
                        format="####-##-##"
                        value={model3?.recedate || model3new?.recedate}
                        onChange={(value) => setModel3State({ recedate: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약분류"
                    >
                      <ComboBox
                        value={model3?.contg}
                        data={scope.state.contgs?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={(option) => {
                            // 계약분류 해지로 변경 시 당일 날짜로.
                            if (option.value === '04') {
                              setModel3State({
                                clsdate: Today.date(),
                                clsrecedate: Today.date(),
                                contg: option.value,
                              });
                            } else {
                              setModel3State({
                                contg: option.value,
                                clsdate: '00000000',
                                clsrecedate: '00000000',
                              });
                            }
                          }}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약구분"
                    >
                      <ComboBox
                        value={model3?.contgubun || model3new?.contgubun}
                        data={scope.state.contgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                        onSelect={(option) => setModel3State({ contgubun: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="매출구분"
                    >
                      <ComboBox
                        value={model3?.gubun}
                        data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
                        onSelect={(option) => setModel3State({ gubun: option.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="매출분류"
                    >
                      <ComboBox
                        value={model3?.billgubun}
                        data={[
                          { value: '1', remark: '매출' },
                          { value: '0', remark: '비매출' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={
                            (option) => {
                              setModel3State({ billgubun: option.value });
                              scope.billgubunChange('billgubun', {
                                billgubun: option.value,
                              });
                            }
                          }
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="세금구분"
                    >
                      <ComboBox
                        value={model3?.taxgubun}
                        data={scope.state.taxgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                        onSelect={(option) => setModel3State({ taxgubun: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="유무상"
                    >
                      <OptionBox
                        value={model3?.mgubun || '1'}
                        data={[
                          { value: '1', remark: '유상' },
                          { value: '0', remark: '무상' },
                        ]}
                        onChange={(item) => setModel3State({ mgubun: item.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="무상계약진행자"
                      style={{ color: 'var(--color-red)' }}
                    >
                      <TextBox
                        textAlign="center"
                        value={model3?.muperid}
                        onChange={(value) => setModel3State({ muperid: value })}
                          onEnter={() => scope.onRetrieveEvent()}
                          bindSearchModal={new SearchBinding(
                            scope.props.modalStore,
                            'TB_JA001',
                            {},
                            true,
                            (item) => {
                              setModel3State({ muperid: item.cd, mupernm: item.cdnm });
                            },
                          )}
                          isDisabledTrackingStateChange={true}
                      />
                      <TextBox
                        textAlign="center"
                        value={model3?.mupernm}
                        onChange={(value) => setModel3State({ mupernm: value })}
                        readonly={true}
                      />
                    </LabelBox>
                  </FlexLayout>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout>
                  <LabelBox
                    title="계약시작일"
                    isNeed={true}
                  >
                    <DateTextBox
                      value={model3?.stdate || model3new?.stdate}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => setModel3State({ stdate: value })
                      }
                    />
                  </LabelBox>
                </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약만료일"
                    >
                      <DateTextBox
                        value={model3?.enddate || model3new?.enddate}
                        textAlign="center"
                        format="####-##-##"
                        onChange={
                          (value) => setModel3State({ enddate: value })
                        }
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약금액"
                    >
                      <FormatNumberTextBox
                        char={','}
                        charAt={3}
                        textAlign="right"
                        value={model3?.amt}
                        onChange={
                          (value) => setModel3State({ amt: value })
                        }
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="월대당가"
                      footer={
                        <FlexLayout style={Global.STYLE_COLOR_RED}>
                          {model3?.uamt_per}
                        </FlexLayout>
                      }
                    >
                      <FormatNumberTextBox
                        char={','}
                        charAt={3}
                        textAlign="right"
                        value={model3?.uamt}
                        onChange={
                          (value) => setModel3State({ uamt: value })
                        }
                        readonly={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약서금액(협력)"
                    >
                      <FormatNumberTextBox
                        char={','}
                        charAt={3}
                        textAlign="right"
                        value={model3?.samt}
                        onChange={(value) => setModel3State({ samt: value })}
                        onEnter={(value) => setModel3State({ samt: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="부가세여부"
                    >
                      <OptionBox
                        value={model3?.addyn || '1'}
                        data={[
                          { value: '1', remark: '포함' },
                          { value: '0', remark: '별도' },
                        ]}
                        onChange={(item) => setModel3State({ addyn: item.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="청구주기"
                      footer={<FlexLayout>
                        <Button
                          onClick={() => scope.openRecipeIntervalModal()}
                          isIcon={true}
                          theme={BUTTON_THEMES.BLUE}
                        >
                          <FiArrowRightCircle size={25}/>
                        </Button>
                      </FlexLayout>}
                    >
                      <ComboBox
                        value={model3?.deljugi}
                        data={scope.state.deljugis?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                        onSelect={(option) => setModel3State({ deljugi: option.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약서여부"
                      footer={
                        <Button
                          isIcon={true}
                          onClick={() => scope.setState({ isVisibleAttachment: true })}
                        >
                          <MdAddCircle size={24}/>
                        </Button>
                      }
                    >
                      <TextBox
                        textAlign="center"
                        color={Number(model3?.attcnt) > 0 ? 'var(--color-blue)'
                          : 'var(--color-red)'}
                        value={(parseInt(model3?.attcnt, 10) || 0) > 0 ? '있음' : '없음'}
                        onChange={(value) => setModel3State({ attcnt: value })}
                      />
                      <TextBox
                        textAlign="left"
                        value={model3?.sitename}
                        onChange={(value) => setModel3State({ sitename: value })}
                      >
                       </TextBox>
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="계약자"
                      style={{ color: 'var(--color-red)' }}
                    >
                      <TextBox
                        textAlign="center"
                        value={model3?.perid || model3new?.perid}
                          onChange={(value) => setModel3State({ perid: value })}
                          onEnter={() => scope.onRetrieveEvent()}
                          bindSearchModal={new SearchBinding(
                            scope.props.modalStore,
                            'TB_JA001',
                            {},
                            true,
                            (item) => {
                              setModel3State({ perid: item.cd, pernm: item.cdnm });
                            },
                          )}
                      />
                      <TextBox
                        textAlign="center"
                        value={model3?.pernm || model3new?.pernm}
                        onChange={(value) => setModel3State({ pernm: value })}
                        readonly={true}
                      />
                    </LabelBox>
                  </FlexLayout>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout weight={2.01}>
                  <LabelBox
                    title={`건물:${model3?.contbildnm}`}
                  >
                    <ComboBox
                      value={model3?.evercd || model3?.evercd}
                      data={scope.state.evercds?.map((y) => new ComboBoxModel(y.evercd, y.evernm))}
                      onSelect={(option) => setModel3State({ evercd: option.value })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout weight={0.5}>
                  <LabelBox
                    title="표준층수"
                  >
                    <TextBox
                      value={model3?.evernumber}
                      onChange={(value) => setModel3State({ evernumber: value })}
                      textAlign="center"
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout weight={0.5}>
                  <LabelBox
                    title="휴야적용"
                  >
                    <CheckBox
                      value={model3?.evernight === '1'}
                      onChange={(v) => setModel3State({ evernight: v ? '1' : '0' })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox
                    title="표준유지관리비"
                  >
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={model3?.everamt}
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout weight={0.7}>
                  <LabelBox
                    title="표준체증요금"
                  >
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={model3?.everaddamt}
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout weight={0.3}>
                  <LabelBox
                    title="평균층수"
                  >
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        backgroundColor: '#F0F0F0',
                      }}
                    >
                      {Format.number(model3.iAvernumber)}
                    </FlexLayout>
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                <LabelBox
                  title="초과체증층수/금액"
                >
                  <TextBox
                    textAlign="center"
                    value={
                      (model3.iAvernumber - model3.iEvernumber) < 1
                        ? '0'
                        : model3?.iAvernumber - model3?.iEvernumber
                    }
                    readonly={true}
                  />

                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={model3?.toteveraddamt}
                    readonly={true}
                  />
                </LabelBox>
                </FlexLayout>

                <FlexLayout >
                  <EditTitleLabelBox
                    value={model3?.salenm}
                  >
                      <FormatNumberTextBox
                          color={'var(--color-red)'}
                          char={','}
                          charAt={3}
                          textAlign="right"
                          value={model3?.saleamt}
                          readonly={true}
                      />
                  </EditTitleLabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox
                    title="총 표준유지관리비"
                  >
                      <FormatNumberTextBox
                          color={'var(--color-blue)'}
                          char={','}
                          charAt={3}
                          textAlign="right"
                          value={Format.number((model3?.iSum + model3?.iSum2) - model3?.iSum3)}
                          readonly={true}
                      />
                  </LabelBox>
                </FlexLayout>


                <FlexLayout >
                  <LabelBox
                    title="인수일(협력업체)"
                  >
                    <DateTextBox
                      value={model3?.insudate || '19700101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => setModel3State({ insudate: value })
                      }
                    />
                  </LabelBox>
                </FlexLayout>


              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout size={300}>
                  <LabelBox
                    title="전자계산서발행"
                    footer={<Button
                      isMini={true}
                      onClick={() => scope.onButtonclick(model3.taxflag, BtnGubun.b_taxall)}
                    >
                      <FlexLayout>
                        전체적용
                      </FlexLayout>
                    </Button>}
                  >
                    <FlexLayout
                      role="overflow"
                      size={80}
                    >
                      <ComboBox
                        style={model3?.taxflag === '1' ? { color: 'var(--color-blue)' } : ''}
                        value={model3?.taxflag || model3new?.taxflag}
                        data={[
                          { value: '1', remark: '발행' },
                          { value: '0', remark: '미발행' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => setModel3State({ taxflag: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>


                    <div role="overflow">
                      <FlexLayout>
                        <ComboBox
                          value={model3?.taxchk}
                          data={[
                            { value: '1', remark: '당월' },
                            { value: '2', remark: '익월' },
                          ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={(option) => setModel3State({ taxchk: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>

                      <FlexLayout>
                        <FormatTextBox
                          textAlign="center"
                          format="## 일,"
                          onChange={
                            (value) => setModel3State({ dedate: value })
                          }
                          value={model3?.dedate}
                        />
                      </FlexLayout>

                      <div>
                        말일체크
                      </div>

                      <div>
                        <CheckBox
                          value={model3?.delchk === '1'}
                          onChange={(v) => setModel3State({ delchk: v ? '1' : '0' })}
                        />
                      </div>
                    </div>

                  </LabelBox>
                </FlexLayout>

                <FlexLayout size={100}>
                  <LabelBox
                    title="휴일구분"
                  >
                    <ComboBox
                      value={model3?.taxweek}
                      data={[
                        { value: '0', remark: '전일발행' },
                        { value: '1', remark: '당일발행' },
                      ].map((x) => new ComboBoxModel(x.value, x.remark))}
                      onSelect={(option) => setModel3State({ taxweek: option.value })}
                      isDisabledTrackingStateChange={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout size={300}>
                  <LabelBox
                    title="지로발행"
                    footer={<Button
                      isMini={true}
                      onClick={() => scope.onButtonclick(model3.jirogubun, BtnGubun.b_jiroall)}
                    >
                      <FlexLayout>
                        전체적용
                      </FlexLayout>
                    </Button>}
                  >
                    <FlexLayout
                      size={80}
                      role="overflow"
                    >
                      <ComboBox
                        style={model3?.jirogubun === '1' ? { color: 'var(--color-blue)' } : ''}
                        value={model3?.jirogubun || model3new?.jirogubun}
                        data={[
                          { value: '1', remark: '발행' },
                          { value: '0', remark: '미발행' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => setModel3State({ jirogubun: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>


                    <div role="overflow">
                      <FlexLayout>
                        <ComboBox
                          value={model3?.ungijun}
                          data={[
                            { value: '1', remark: '당월' },
                            { value: '2', remark: '익월' },
                          ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={(option) => setModel3State({ ungijun: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>

                      <FlexLayout>
                        <FormatTextBox
                          textAlign="center"
                          format="##일,"
                          value={model3?.jirodate}
                          onChange={
                            (value) => setModel3State({ jirodate: value })
                          }
                        />
                      </FlexLayout>

                      <div>
                          말일체크
                      </div>

                      <div>
                        <CheckBox
                          value={model3?.jirochk === '1'}
                          onChange={(v) => setModel3State({ jirochk: v ? '1' : '0' })}
                        />
                      </div>
                    </div>

                  </LabelBox>
                </FlexLayout>

                <FlexLayout size={120}>
                  <LabelBox
                    title="유지보수거래처"
                      style={{ color: 'var(--color-red)' }}
                  >
                    <TextBox
                      value={model3?.cltcd}
                      textAlign="center"
                        onChange={(value) => setModel3State({ cltcd: value })}
                        bindSearchModal={new SearchBinding(
                          scope.props.modalStore,
                          'TB_XCLIENT',
                          {
                            uv_arg1: '2',
                          },
                          true,
                          (item) => {
                            setModel3State({
                              cltcd: item.cd,
                              cltnm: item.cdnm,
                            });
                          },
                        )}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout >
                  <LabelBox
                    title="자동이체여부"
                    footer={
                      <FlexLayout>
                        <FlexLayout style={model3?.autoflag === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}>
                          {model3?.autoflag === '1' ? 'O' : 'X'}
                        </FlexLayout>
                        <FlexLayout style={model3?.autoflag === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}>
                          번호: {model3?.autonum !== '' ? model3?.autonum : ''}
                        </FlexLayout>
                      </FlexLayout>
                    }
                  >
                    <TextBox
                      value={model3?.cltnm}
                      textAlign="left"
                      onChange={(value) => setModel3State({ cltnm: value })}
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout >
                  <LabelBox
                    title="매출통합발행"
                    style={{ color: 'var(--color-red)' }}
                    footer={<FlexLayout>
                      (통합발행 체크)
                      <CheckBox
                        value={model3?.tax_combine === '1'}
                        onChange={(value) => setModel3State({ tax_combine: value ? '1' : '0' })}
                      /></FlexLayout>}
                      >
                    <TextBox
                      textAlign="center"
                      value={model3?.tax_actcd}
                        onChange={(value) => setModel3State({ tax_actcd: value })}
                        bindSearchModal={new SearchBinding(
                          scope.props.modalStore,
                          'TB_E601_1',
                          {
                            uv_arg1: '1',
                          },
                          true,
                          (item) => {
                            setModel3State({
                              tax_actcd: item.cd,
                              tax_actnm: item.cdnm,
                            });
                          },
                        )}
                      readonly={model3?.tax_combine !== '1'}
                    />
                    <TextBox
                      textAlign="center"
                      value={model3?.tax_actnm}
                      onChange={(value) => setModel3State({ tax_actnm: value })}
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>


              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout size={168}>
                  <LabelBox
                    title="계약해지"
                  >
                    <DateTextBox
                      value={model3?.clsdate}
                      textAlign="center"
                      format="####-##-##"
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_XCLIENT',
                        {
                          uv_arg1: '1',
                        },
                        true,
                        (item) => {
                          setModel3State({
                            tax_actcd: item.cd,
                            tax_actnm: item.cdnm,
                          });
                        },
                      )}
                      onChange={
                        (value) => setModel3State({ clsdate: value })
                      }
                      readonly={model3?.contg !== '04'}
                    />
                  </LabelBox>
                </FlexLayout>

                  <FlexLayout size={167}>
                    <LabelBox
                      title="계약해지접수일"
                    >
                      <DateTextBox
                        value={model3?.clsrecedate}
                        textAlign="center"
                        format="####-##-##"
                        onChange={
                          (value) => {
                            setModel3State({ clsrecedate: value });
                          }
                        }
                        readonly={model3?.contg !== '04'}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="해지구분"
                      style={{ color: 'var(--color-red)' }}
                    >
                      <TextBox
                        value={model3?.cls}
                        textAlign="center"
                        bindSearchModal={new SearchBinding(
                          scope.props.modalStore,
                          'TB_E018',
                          {},
                          true,
                          (item) => {
                            setModel3State({ cls: item.cd, clsnm: item.cdnm });
                          },
                        )}
                        onChange={
                          (value) => setModel3State({ cls: value })
                        }
                        readonly={model3?.contg !== '04'}
                      />
                        <TextBox
                          value={model3?.clsnm}
                          textAlign="center"
                          onChange={
                            (value) => setModel3State({ clsnm: value })
                          }
                          readonly={model3?.contg !== '04'}
                        />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="해지사유상세"
                    >
                      <TextBox
                        value={model3?.clsremark}
                        textAlign="left"
                        onChange={
                          (value) => setModel3State({ clsremark: value })
                        }
                        readonly={model3?.contg !== '04'}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="보류시작일"
                      footer={<FlexLayout>
                        (보류 체크)
                        <CheckBox
                          value={model3?.boryu === '1'}
                          onChange={(value) => setModel3State({ boryu: value ? '1' : '0' })}
                        /></FlexLayout>}
                    >
                      <DateTextBox
                        value={model3?.boryudate}
                        textAlign="center"
                        format="####-##-##"
                        onChange={
                          (value) => setModel3State({ boryudate: value })
                        }
                        readonly={model3?.boryu === '0'}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox title="보류만료일">
                      <DateTextBox
                        value={model3?.boryudate2}
                        textAlign="center"
                        format="####-##-##"
                        onChange={
                          (value) => setModel3State({ boryudate2: value })
                        }
                        readonly={model3?.boryu === '0'}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox title="보류사유">
                      <TextBox
                        value={model3?.boryusayoo}
                        textAlign="left"
                        onChange={
                          (value) => setModel3State({ boryusayoo: value })
                        }
                        readonly={model3?.boryu === '0'}
                      />
                    </LabelBox>
                  </FlexLayout>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="하도급구분"
                  size={100}
                >
                  <ComboBox
                    value={model3?.subflag || '3'}
                    data={[
                      { value: '3', remark: '없음' },
                      { value: '2', remark: '하도급' },
                      { value: '1', remark: '공동수급' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setModel3State({ subflag: option.value })}
                  />
                </LabelBox>

                <LabelBox
                  title="하도급사업자번호"
                  size={120}
                >
                  <TextBox
                    readonly={model3?.subflag === '3'}
                    textAlign="center"
                    value={model3?.subsaupnum}
                    onChange={(value) => setModel3State({ subsaupnum: value })}
                  />
                </LabelBox>

                <LabelBox
                  title="적요(매출품목적요)"
                  footer={
                    <FlexLayout style={{ color: 'var(--color-red)' }}>
                    년도는 yyyy, 월은mm으로 써주세요!
                  </FlexLayout>}
                >
                  <TextBox
                    value={model3?.remark || model3new?.remark}
                    textAlign="left"
                    onChange={
                      (value) => setModel3State({ remark: value })
                    }
                    weight={3}
                  />

                  <Button
                    onClick={() => scope.onButtonclick(model3.remark, BtnGubun.b_remarkall)}
                  >
                    <FlexLayout>
                      전체적용
                    </FlexLayout>
                  </Button>
                </LabelBox>

                <LabelBox
                  title="비고(세금계산서)"
                  size={260}
                >
                  <TextBox
                    value={model3?.bigo}
                    textAlign="left"
                    onChange={
                      (value) => setModel3State({ bigo: value })
                    }
                  />
                </LabelBox>

                <LabelBox
                  title="비고"
                  size={260}
                >
                  <TextBox
                    value={model3?.bigo2}
                    textAlign="left"
                    onChange={
                      (value) => setModel3State({ bigo2: value })
                    }
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={72}>
                <LabelBox
                  title="선진기법도입"
                  size={600}
                >
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    <CheckBox
                      value={model3?.remflag === 'Y'}
                      onChange={(v) => setModel3State({ remflag: v ? 'Y' : 'N' })}
                    />
                    원격감시기능(EMRS)
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    <CheckBox
                      value={model3?.remflag_wkactcd === 'Y'}
                      onChange={(v) => setModel3State({ remflag_wkactcd: v ? 'Y' : 'N' })}
                    />
                    원격감시기능(협력사표기용)
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    <CheckBox
                      value={model3?.exclflag === 'Y' || model3new?.extflag === 'Y'}
                      onChange={(v) => setModel3State({ exclflag: v ? 'Y' : 'N' })}
                    />
                    보수품질우수업체
                  </FlexLayout>
                </LabelBox>

                <LabelBox />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.DIVISION
          && <FlexLayout
              size={10}
              justify="right"
          >
            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.divisionResister()}
            >
              분할납부
            </Button>

            <Button
              style={{
                height: 25,
                width: 100,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.divisionDelete()}
            >
              분할삭제
            </Button>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.DIVISION
          && (scope.state.divisionVisible === true) && <FlexLayout>
            <FlexLayout
              isVertical={true}
              isScroll={true}
            >
              <LayoutTitle>
                분할납부정보
              </LayoutTitle>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout>
                  <LabelBox
                    title="접수일자"
                    isNeed={true}
                  >
                    <DateTextBox
                      value={model4?.recedate || '19700101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => setModel4State({ recedate: value })
                      }
                    />
                  </LabelBox>
                </FlexLayout>


                <FlexLayout>
                  <LabelBox title="매출구분">
                    <ComboBox
                      value={model4?.gubun}
                      data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
                      onSelect={(option) => setModel4State({ gubun: option.value })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox
                    title="매출분류"
                  >
                    <ComboBox
                      value={model4?.billgubun}
                      data={[
                        { value: '1', remark: '매출' },
                        { value: '0', remark: '비매출' },
                      ].map((x) => new ComboBoxModel(x.value, x.remark))}
                      onSelect={(option) => setModel4State({ billgubun: option.value })}
                      isDisabledTrackingStateChange={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox
                    title="회사구분"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <TextBox
                      weight={1.4}
                      value={model4?.wkactcd}
                      textAlign="center"
                      onChange={(value) => setModel4State({ wkactcd: value })}
                      onEnter={() => scope.onRetrieveEvent()}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E018_1',
                        {},
                        true,
                        (item) => {
                          setModel4State({ wkactcd: item.cd, wkactnm: item.cdnm });
                        },
                      )}
                      isDisabledTrackingStateChange={true}
                    />
                    <TextBox
                      weight={1.6}
                      textAlign="center"
                      value={model4?.wkactnm}
                      onChange={(value) => setModel4State({ wkactnm: value })}
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout weight={2}>
                  <LabelBox
                    title="분할납부거래처"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <TextBox
                      textAlign="center"
                      value={model3?.cltcd}
                      onChange={(value) => setModel4State({ cltcd: value })}
                    />

                    <TextBox
                      textAlign="center"
                      value={model3?.cltnm}
                      onChange={(value) => setModel4State({ cltnm: value })}
                      readonly={true}
                        weight={3}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox
                    title="세금구분"
                  >
                    <ComboBox
                      value={model4?.taxgubun}
                      data={taxgubuns?.map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => setModel4State({ taxgubun: option.value })}
                    />
                  </LabelBox>
                </FlexLayout>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout>
                  <LabelBox
                    title="분할시작일"
                    isNeed={true}
                  >
                    <DateTextBox
                      value={model4?.stdate || '19700101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => setModel4State({ stdate: value })
                      }
                    />
                  </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="분할만료일"
                    >
                      <DateTextBox
                        value={model4?.enddate || '19700101'}
                        textAlign="center"
                        format="####-##-##"
                        onChange={
                          (value) => setModel4State({ enddate: value })
                        }
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout weight={0.7}>
                    <LabelBox
                      title="분할금액"
                    >
                      <TextBox
                        textAlign="right"
                        value={model4?.amt}
                        onChange={(value) => setModel4State({ amt: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout weight={0.3}>
                    <LabelBox
                      title="총회차"
                    >
                      <TextBox
                        textAlign="left"
                        value={model4?.totqty}
                        onChange={(value) => setModel4State({ totqty: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="부가세여부"
                    >
                      <OptionBox
                        value={model4?.addyn || '1'}
                        data={[
                          { value: '1', remark: '포함' },
                          { value: '0', remark: '별도' },
                        ]}
                        onChange={(item) => setModel4State({ addyn: item.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout weight={2}>
                    <LabelBox
                      title="프로젝트"
                      style={{ color: 'var(--color-red)' }}
                    >
                      <TextBox
                        textAlign="center"
                        value={model4?.projno}
                        onChange={(value) => setModel4State({ projno: value, isChanged: 'true' })}
                        bindSearchModal={new SearchBindingProjects(
                          scope.props.modalStore,
                          {},
                          true,
                          (item) => {
                            setModel4State({
                              projno: item.projno,
                              projnm: item.projnm,
                            });
                          },
                        )}
                      />
                      <TextBox
                        value={model4?.projnm}
                        weight={3}
                        textAlign="left"
                        isDisabledTrackingStateChange={true}
                        readonly={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="유무상"
                    >
                      <OptionBox
                        value={model4?.addyn || '1'}
                        data={[
                          { value: '1', remark: '포함' },
                          { value: '0', remark: '별도' },
                        ]}
                        onChange={(item) => setModel4State({ addyn: item.value })}
                      />
                    </LabelBox>
                  </FlexLayout>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>

                <FlexLayout weight={2}>
                  <LabelBox
                    title="전자계산서발행"
                  >
                    <FlexLayout weight={0.5}>
                      <ComboBox
                        value={model4?.taxflag}
                        data={[
                          { value: '1', remark: '발행' },
                          { value: '0', remark: '미발행' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => setModel4State({ taxflag: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>


                      <div>
                        <FlexLayout>
                          <ComboBox
                            value={model4?.taxchk}
                            data={[
                              { value: '1', remark: '당월' },
                              { value: '2', remark: '익월' },
                            ].map((x) => new ComboBoxModel(x.value, x.remark))}
                            onSelect={(option) => setModel4State({ taxchk: option.value })}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>

                          <FlexLayout>
                            <FormatTextBox
                              textAlign="left"
                              format="## 일,"
                              value={model4?.dedate}
                            />
                          </FlexLayout>

                          <div>
                              말일체크
                          </div>

                          <div>
                            <CheckBox
                              value={model4?.evernight === '1'}
                              onChange={(v) => setModel4State({ evernight: v ? '1' : '0' })}
                            />
                          </div>
                      </div>

                  </LabelBox>
                </FlexLayout>

                <FlexLayout weight={2}>
                  <LabelBox
                    title="지로구분"
                  >
                    <FlexLayout weight={0.5}>
                      <ComboBox
                        value={model4?.jirogubun}
                        data={[
                          { value: '1', remark: '발행' },
                          { value: '0', remark: '미발행' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => setModel4State({ jirogubun: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>


                    <div>
                      <FlexLayout>
                        <ComboBox
                          value={model4?.ungijirodate}
                          data={[
                            { value: '1', remark: '당월' },
                            { value: '2', remark: '익월' },
                          ].map((x) => new ComboBoxModel(x.value, x.remark))}
                          onSelect={(option) => setModel4State({ ungijirodate: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>

                      <FlexLayout>
                        <FormatTextBox
                          textAlign="left"
                          format="## 일,"
                          value={model4?.ungijirodate}
                        />
                      </FlexLayout>

                      <div>
                        말일체크
                      </div>

                      <div>
                        <CheckBox
                          value={model4?.jirochk === '1'}
                          onChange={(v) => setModel4State({ jirochk: v ? '1' : '0' })}
                        />
                      </div>
                    </div>

                  </LabelBox>
                </FlexLayout>

                <FlexLayout >
                  <LabelBox
                    title="보류시작일"
                    footer={<FlexLayout>
                      <CheckBox
                        value={model4?.boryu === '1'}
                        onChange={(value) => setModel4State({ boryu: value ? '1' : '0' })}
                      /></FlexLayout>}
                  >
                    <DateTextBox
                      value={model4?.boryudate || '20210101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => setModel4State({ boryudate: value })
                      }
                      readonly={model4?.boryu !== '1'}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox title="보류만료일">
                    <DateTextBox
                      value={model4?.boryudate2 || '20210101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => setModel4State({ boryudate2: value })
                      }
                      readonly={model4?.boryu !== '1'}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout>
                  <LabelBox title="보류사유">
                    <TextBox
                      value={model4?.boryusayoo}
                      textAlign="center"
                      onChange={
                        (value) => setModel4State({ boryusayoo: value })
                      }
                      readonly={model4?.boryu !== '1'}
                    />
                  </LabelBox>
                </FlexLayout>

                  <FlexLayout>
                    <LabelBox
                      title="청구주기"
                      footer={<FlexLayout>
                        <Button
                          onClick={() => scope.openDivideIntervalModal()}
                          isIcon={true}
                          theme={BUTTON_THEMES.BLUE}
                        >
                          <FiArrowRightCircle size={25}/>
                        </Button>
                      </FlexLayout>}
                    >
                      <ComboBox
                        value={model4?.deljugi || model4?.deljugi}
                        data={scope.state.deljugis?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                        onSelect={(option) => setModel4State({ deljugi: option.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>

                <FlexLayout >
                  <LabelBox
                    title="비고(세금계산서)"
                  >
                    <TextBox
                      value={model4?.bigo}
                      textAlign="left"
                      onChange={(value) => setModel4State({ bigo: value })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout >
                  <LabelBox
                    title="적요(매출품목적요)"
                  >
                    <TextBox
                      textAlign="left"
                      value={model4?.remark}
                      onChange={(value) => setModel4State({ remark: value })}
                    />
                  </LabelBox>
                </FlexLayout>
              </FlexLayout>

            </FlexLayout>
          </FlexLayout>}
          {scope.tabIndex === EnrollmentTabId.DIVISION
          && scope.state.divisionVisible === false && <FlexLayout>
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.CHECK
          && <FlexLayout
            size={10}
            justify="right"
          >
            <Button
              style={{
                height: 25,
                width: 120,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => {
                // eslint-disable-next-line no-param-reassign
                scope.isWholeGosiSync = false;
                scope.syncAdChk();
              }}
            >
                <RiLinksFill></RiLinksFill>
              정보센터 연동
            </Button>
          </FlexLayout>}


          {scope.tabIndex === EnrollmentTabId.CHECK
          && <FlexLayout>
            <FlexLayout weight={0.3}>
              <TableLayout
                ref={scope.tableCheck}
                scope={scope}
                header={scope.tabHeaders[scope.tabIndex]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={scope.state.checks}
                onRowFocusEvent={(x) => scope.onDetailCheckRowFocusEvent(x)}
                onChange={(rows, updatedRows) => setData({
                  [EnrollmentTabDataStateNames[scope.tabIndex]]: rows,
                  [EnrollmentTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
                })}
              />
            </FlexLayout>
            <TableLayout
              ref={scope.tableCheckDetail}
              scope={scope}
              header={scope.tabHeaders2[0]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowFocusEvent={(x) => scope.onCheckDetailRowFocusEvent(x)}
              data={scope.state.checkDetail}
              onChange={(rows, updatedRows) => setData({
                [EnrollmentTabDataStateNames[scope.tabIndex]]: rows,
                [EnrollmentTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
              })}
              onRowDoubleClick={(item) => scope.openInspectDetailModal(item)}
            />
          </FlexLayout>}

          {scope.tabIndex === EnrollmentTabId.RECEIVABLE
          && <GridLayout
            ref={scope.gridReceivable}
            scope={scope}
            // @ts-ignore
            header={scope.tabHeaders2[1]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            // @ts-ignore
            data={scope.state[EnrollmentTabDataStateNames[scope.tabIndex]]}
            onRowClick={(item) => setData({
              [EnrollmentTabFocusedStateNames[scope.tabIndex]]: item,
            })}
          />}

          {scope.tabIndex !== EnrollmentTabId.UNIT
          && scope.tabIndex !== EnrollmentTabId.CONTRACKINFORMATION
          && scope.tabIndex !== EnrollmentTabId.DIVISION
          && scope.tabIndex !== EnrollmentTabId.CHECK
          && scope.tabIndex !== EnrollmentTabId.RECEIVABLE
          && <TableLayout
            ref={scope.table}
            scope={scope}
            header={scope.tabHeaders[scope.tabIndex]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity2}
            // @ts-ignore
            data={scope.state[EnrollmentTabDataStateNames[scope.tabIndex]]}
            onRowFocusEvent={(item) => setData({
              [EnrollmentTabFocusedStateNames[scope.tabIndex]]: item,
            })}
            onChange={(rows, updatedRows) => setData({
              [EnrollmentTabDataStateNames[scope.tabIndex]]: rows,
              [EnrollmentTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
            })}
          />}
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.checkPersonDetailModal}
        onClose={() => scope.checkModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              히스토리
            </div>

            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onNewCheckPersonEvent()}
              >
                신규
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onCheckPersonSaveEvent()}
              >
                저장
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.onCheckPersonDelete()}
              >
                삭제
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.checkModal(false)}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
          <FlexLayout weight={14}>
            <TableLayout
              header={[
                {
                  id: 'seq',
                  text: '순번',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'perid',
                  text: '담당자코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.perid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ perid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '시작일자',
                  width: 15,
                  render: (x, rowUpdate) => (
                    <DateTextBox
                      value={x.stdate || '19700101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => rowUpdate({ stdate: value })
                      }
                    />
                  ),
                },
                {
                  id: 'enddate',
                  text: '종료일자',
                  width: 15,
                  render: (x, rowUpdate) => (
                    <DateTextBox
                      value={x.enddate || ''}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => rowUpdate({ enddate: value })
                      }
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 40,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'inpernm',
                  text: '변경자명',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.inpernm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.PersonPopUpData || []}
              infinityHandler={scope.infinityModal2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              ref={scope.tableCheckPerson}
            />
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.personEmailDetailModal}
        onClose={() => scope.personEmailModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              히스토리
            </div>

            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onNewPersonEmailEvent()}
              >
                신규
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onPersonEmailSaveEvent()}
              >
                저장
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.onPersonEmailDelete()}
              >
                삭제
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.personEmailModal(false)}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
          <FlexLayout weight={14}>
            <TableLayout
              header={[
                {
                  id: 'seq',
                  text: '순번',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actmail',
                  text: '담당자메일',
                  width: 45,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.actmail}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actmail: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 45,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.PersonPopUpData || []}
              infinityHandler={scope.infinityModal2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              ref={scope.tableCheckPerson}
            />
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.taxDetailModal}
        onClose={() => scope.taxModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              히스토리
            </div>

            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onNewTaxEvent()}
              >
                신규
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onTaxSaveEvent()}
              >
                저장
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.onTaxDelete()}
              >
                삭제
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.taxModal(false)}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
          <FlexLayout weight={14}>
            <TableLayout
              header={[
                {
                  id: 'seq',
                  text: '순번',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltcd',
                  text: '거래처코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate) => (
                    <TextBox
                      textAlign="center"
                      value={x.cltcd}
                      onChange={(value) => rowUpdate({ cltcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_XCLIENT',
                        {
                          uv_arg1: '2',
                        },
                        true,
                        (item) => {
                          rowUpdate({
                            cltcd: item.cd,
                            cltnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명',
                  width: 30,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.cltnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '시작일자',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <DateTextBox
                      value={x.stdate || '19700101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => rowUpdate({ stdate: value })
                      }
                    />
                  ),
                },
                {
                  id: 'enddate',
                  text: '종료일자',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <DateTextBox
                      value={x.enddate || ''}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => rowUpdate({ enddate: value })
                      }
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 35,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'inpernm',
                  text: '변경자명',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.inpernm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.PersonPopUpData || []}
              infinityHandler={scope.infinityModal2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              ref={scope.tableCheckPerson}
            />
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.selectModal}
        onClose={() => scope.contractSelectModal(false)}
        width={500}
        height={100}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약연장
          </LayoutTitle>
          <FlexLayout>
            <OptionBox
              value={scope.state.contractflag || '1'}
              data={[
                { value: '0', remark: '개별' },
                { value: '1', remark: '전체' },
              ]}
              onChange={(v) => setData({ contractflag: v.value })}
            />
          </FlexLayout>
          <FlexLayout justify="right">
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={ async () => {
                if (scope.state.contractflag === '0') {
                  await scope.contractSelectModal(false);
                  await scope.contractOnlyModal(true);
                } else {
                  await scope.contractSelectModal(false);
                  await scope.contractAllModal(true);
                }
              }}
            >
              확인
            </Button>

            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.contractSelectModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>


      </ModalView>

      <ModalView
        isVisible={scope.state.onlyModal}
        onClose={() => scope.contractOnlyModal(false)}
        width={1500}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약연장
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <SearchLayout
                size={Global.LAYOUT_SEARCH_HEIGHT_1}
              >
                <FlexLayout>
                  <span>기간</span>
                  <DateTextBox
                    value={scope.state.stmon}
                    textAlign="center"
                    format="####-##"
                    onChange={
                      (value) => {
                        scope.setState({ stmon: value });
                      }
                    }
                    onEnter={() => scope.contractRetrieveClicked()}
                    head={(<button
                      onClick={() => {
                        scope.setState({
                          stmon: new Date6Calculator(scope.state.stmon).add(-1).toString(),
                        });
                      }}
                    >
                      <MdKeyboardArrowLeft size={24}/>
                    </button>)}
                    trail={(<button
                      onClick={() => {
                        scope.setState({
                          stmon: new Date6Calculator(scope.state.stmon).add().toString(),
                        });
                      }}
                    >
                      <MdKeyboardArrowRight size={24}/>
                    </button>)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
                <FlexLayout>
                  <span style={{ color: 'var(--color-red)' }}>현장코드</span>
                  <TextBox
                    value={scope.state.actcd2}
                    textAlign="center"
                    onChange={(value) => scope.setState({ actcd2: value })}
                    onEnter={() => scope.contractRetrieveClicked()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          actcd2: item.cd,
                          actnm2: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.actnm2}
                    weight={2}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                <FlexLayout>
                  <span style={{ color: 'var(--color-red)' }}>회사구분</span>
                  <TextBox
                    value={scope.state.wkactcd2}
                    textAlign="center"
                    onChange={(value) => scope.setState({ wkactcd2: value })}
                    onEnter={() => scope.contractRetrieveClicked()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E018_1',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          wkactcd2: item.cd,
                          wkactnm2: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.wkactnm2}
                    weight={1.5}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                <Button
                  onClick={() => scope.contractRetrieveClicked()
                  }>
                  조회
                </Button>
              </SearchLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableContractOnly}
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 15,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'mgubun',
                      text: '유무상',
                      width: 5,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.mgubun === '0' ? '무상' : '유상'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {x.amt}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'addyn',
                      text: '부가세',
                      width: 5,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.addyn === '0' ? '별도' : '포함'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contg',
                      text: '계약구분',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {scope.state.contgs?.filter((y) => y.com_code === x.contg)[0]?.com_cnam || '-'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'stdate',
                      text: '시작일자',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.stdate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ stdate: value })
                          }
                          readonly={true}
                        />
                      ),
                    },
                    {
                      id: 'enddate',
                      text: '만료일자',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.enddate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ enddate: value })
                          }
                          readonly={true}
                        />
                      ),
                    },
                    {
                      id: 'bcontg',
                      text: '변경계약',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <ComboBox
                          value={x.bcontg}
                          data={scope.state.contgs?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={
                            (option) => {
                              rowUpdate({ bcontg: option.value });
                              // @ts-ignore
                              scope.itemChanged(EnrollmentItemChangeTypes.bcontg, {
                                ...x,
                                bcontg: option.value,
                              });
                            }
                          }
                          isDisabledTrackingStateChange={true}
                        />
                      ),
                    },
                    {
                      id: 'bamt',
                      text: '변경금액',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <TextBox
                          value={x.bamt}
                          textAlign="right"
                          onChange={
                            (value) => rowUpdate({ bamt: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'baddyn',
                      text: '부가세',
                      width: 5,
                      render: (x, rowUpdate) => (
                        <ComboBox
                          value={x.baddyn}
                          data={[
                            { value: '1', remark: '포함' },
                            { value: '0', remark: '별도' },
                          ].map((y) => new ComboBoxModel(y.value, y.remark))}
                          onSelect={(option) => rowUpdate({ baddyn: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      ),
                    },
                    {
                      id: 'bstdate',
                      text: '변경시작일',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.bstdate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ bstdate: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'benddate',
                      text: '변경만료일',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.benddate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ benddate: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'bcontdate',
                      text: '계약일자',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.bcontdate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ bcontdate: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'chk',
                      text: (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          확인
                          <CheckBox
                            noMargin
                            value={scope.state.pushTotalCheck}
                            onChange={async (value) => scope.updateCheckAllToggle(value)}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                      width: 10,
                      render: (x, rowUpdate) => (
                        <CheckBox
                          value={x.chk === '1'}
                          onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                        />
                      ),
                    },
                  ]}
                  onChange={(rows4, updatedRows4) => {
                    scope.onUpdatedRows4(rows4, updatedRows4);
                  }}
                  data={scope.state.contractLongData || []}
                  infinityHandler={scope.infinityModal}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout justify='right'>
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={() => scope.contractSave()}
            >
              저장
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.contractOnlyModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.allModal}
        onClose={() => scope.contractAllModal(false)}
        width={1500}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약연장
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <SearchLayout
                size={Global.LAYOUT_SEARCH_HEIGHT_1}
              >
                <FlexLayout>
                  <span>기간</span>
                  <DateTextBox
                    value={scope.state.stmon}
                    textAlign="center"
                    format="#### - ##"
                    onChange={
                      (value) => {
                        scope.setState({ stmon: value });
                      }
                    }
                    onEnter={() => scope.contractRetrieve2Clicked()}
                    head={(<button
                      onClick={() => {
                        scope.setState({
                          stmon: new Date6Calculator(scope.state.stmon).add(-1).toString(),
                        });
                      }}
                    >
                      <MdKeyboardArrowLeft size={24}/>
                    </button>)}
                    trail={(<button
                      onClick={() => {
                        scope.setState({
                          stmon: new Date6Calculator(scope.state.stmon).add().toString(),
                        });
                      }}
                    >
                      <MdKeyboardArrowRight size={24}/>
                    </button>)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
                <FlexLayout>
                  <span style={{ color: 'var(--color-red)' }}>현장코드</span>
                  <TextBox
                    value={scope.state.actcd3}
                    textAlign="center"
                    onChange={(value) => scope.setState({ actcd3: value })}
                    onEnter={() => scope.contractRetrieve2Clicked()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          actcd3: item.cd,
                          actnm3: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.actnm3}
                    weight={2}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                <FlexLayout>
                  <span style={{ color: 'var(--color-red)' }}>회사구분</span>
                  <TextBox
                    value={scope.state.wkactcd3}
                    textAlign="center"
                    onChange={(value) => scope.setState({ wkactcd3: value })}
                    onEnter={() => scope.contractRetrieve2Clicked()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E018_1',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          wkactcd3: item.cd,
                          wkactnm3: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.wkactnm3}
                    weight={1.5}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                <Button
                  onClick={() => scope.contractRetrieve2Clicked()
                  }>
                  조회
                </Button>
              </SearchLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableContractAll}
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 15,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'mgubun',
                      text: '유무상',
                      width: 5,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.mgubun === '0' ? '무상' : '유상'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {x.amt}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'addyn',
                      text: '부가세',
                      width: 5,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.addyn === '0' ? '별도' : '포함'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contg',
                      text: '계약구분',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {scope.state.contgs?.filter((y) => y.com_code === x.contg)[0]?.com_cnam || '-'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'stdate',
                      text: '시작일자',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.stdate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ stdate: value })
                          }
                          readonly={true}
                        />
                      ),
                    },
                    {
                      id: 'enddate',
                      text: '만료일자',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.enddate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ enddate: value })
                          }
                          readonly={true}
                        />
                      ),
                    },
                    {
                      id: 'bcontg',
                      text: '변경계약',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <ComboBox
                          value={x?.bcontg}
                          data={scope.state.contgs?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={
                            (option) => {
                              rowUpdate({ bcontg: option.value });
                              // @ts-ignore
                              scope.itemChanged(EnrollmentItemChangeTypes.bcontg, {
                                ...x,
                                bcontg: option.value,
                              });
                            }
                          }
                          isDisabledTrackingStateChange={true}
                        />
                      ),
                    },
                    {
                      id: 'bamt',
                      text: '변경금액',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <TextBox
                          value={x.bamt}
                          textAlign="right"
                          onChange={
                            (value) => rowUpdate({ bamt: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'baddyn',
                      text: '부가세',
                      width: 5,
                      render: (x, rowUpdate) => (
                        <ComboBox
                          value={x.baddyn}
                          data={[
                            { value: '1', remark: '포함' },
                            { value: '0', remark: '별도' },
                          ].map((y) => new ComboBoxModel(y.value, y.remark))}
                          onSelect={(option) => rowUpdate({ baddyn: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      ),
                    },
                    {
                      id: 'bstdate',
                      text: '변경시작일',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.bstdate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ bstdate: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'benddate',
                      text: '변경만료일',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.benddate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ benddate: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'bcontdate',
                      text: '계약일자',
                      width: 10,
                      render: (x, rowUpdate) => (
                        <DateTextBox
                          value={x.bcontdate || '19700101'}
                          textAlign="center"
                          format="####-##-##"
                          onChange={
                            (value) => rowUpdate({ bcontdate: value })
                          }
                        />
                      ),
                    },
                    {
                      id: 'chk',
                      text: (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          확인
                          <CheckBox
                            noMargin
                            value={scope.state.pushTotalCheck}
                            onChange={async (value) => scope.updateCheckAllToggle(value)}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                      width: 10,
                      render: (x, rowUpdate) => (
                        <CheckBox
                          value={x.chk === '1'}
                          onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                        />
                      ),
                    },
                  ]}
                  onChange={(rows4, updatedRows4) => {
                    scope.onUpdatedRows4(rows4, updatedRows4);
                  }}
                  data={scope.state.contractLongData || []}
                  infinityHandler={scope.infinityModal}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout justify='right'>
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={() => scope.contractSave2()}
            >
              저장
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.contractAllModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.changeModal}
        onClose={() => scope.changePersonModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            담당자변경
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <SearchLayout
                size={Global.LAYOUT_SEARCH_HEIGHT_1}
              >
                <FlexLayout>
                  <span style={{ color: 'var(--color-red)' }}>전담당자</span>
                  <TextBox
                    value={scope.state.beperid}
                    textAlign="center"
                    onChange={(value) => scope.setState({ beperid: value })}
                    onEnter={() => scope.changeRetrieve()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          beperid: item.cd,
                          bepernm: item.cdnm,
                        }, () => {
                          scope.changeRetrieve();
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.bepernm}
                    weight={2}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                <FlexLayout>
                  <span>계약구분</span>
                  <ComboBox
                    value={scope.state.changeGubun}
                    data={[
                      { value: '%', remark: '전체' },
                      { value: '1', remark: '계약중' },
                      { value: '2', remark: '해지' },
                      { value: '3', remark: '미계약' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setData({ changeGubun: option.value })}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
                <FlexLayout>
                  <span style={{ color: 'var(--color-red)' }}>후담당자</span>
                  <TextBox
                    value={scope.state.perid2}
                    textAlign="center"
                    onChange={(value) => scope.setState({ perid2: value })}
                    onEnter={() => scope.contractRetrieve()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          perid2: item.cd,
                          pernm2: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.pernm2}
                    weight={1.5}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                <Button
                  onClick={() => scope.changeRetrieve()
                  }>
                  조회
                </Button>
              </SearchLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableChange}
                  header={[
                    {
                      id: 'actcd',
                      text: '현장코드',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.actcd}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 25,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'qty',
                      text: '대수',
                      width: 5,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.qty}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'areanm',
                      text: '지역명',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {scope.state.areanms?.filter((y) => y.areacd === x.areanm)[0]?.areanm || '-'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'address',
                      text: '주소',
                      width: 30,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.address}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'perid',
                      text: '담당자코드',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.perid}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'pernm',
                      text: '담당자명',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.pernm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'chk',
                      text: (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          확인
                          <CheckBox
                            noMargin
                            value={scope.state.pushTotalCheck}
                            onChange={async (value) => scope.updateCheckAllToggle(value)}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                      width: 10,
                      render: (x, rowUpdate) => (
                        <CheckBox
                          value={x.chk === '1'}
                          onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                        />
                      ),
                    },
                  ]}
                  data={scope.state.changePopUpData || []}
                  infinityHandler={scope.infinityModal}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onChange={(rows5, updatedRows5) => {
                    scope.onUpdatedRows5(rows5, updatedRows5);
                  }}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <SubHeader>
            <div>
              <span>일자</span>
              <span>
                <DateTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={scope.state.modalDate}
                  onChange={(v) => setData({ modalDate: v })}
                />
              </span>
            </div>
            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.changeSave()}
              >
                확인
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.changePersonModalClose()}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>


      {/* 계약서 여부 - 첨부파일 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            custcd={scope.state.focuseddata?.custcd || ''}
            spjangcd={scope.state.focuseddata?.spjangcd || ''}
            actcd={scope.state.focuseddata?.actcd || ''}
            contdate={model3?.contdate || ''}
            isEnabledTitle={true}
            isEnabledPreview={true}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      {/* 계약서 여부 - 첨부파일2 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleAttachment2}
        onClose={() => setData({ isVisibleAttachment2: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            custcd={scope.state.focuseddata?.custcd || ''}
            spjangcd={scope.state.focuseddata?.spjangcd || ''}
            actcd={scope.state.focuseddata?.actcd || ''}
            contdate={scope.state.tabContdate || ''}
            isEnabledTitle={true}
            isEnabledPreview={true}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment2: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.unitRemoteModal}
        onClose={() => scope.closeRemoteControlModal(false, false)}
        width={800}
        height={200}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            정보센터 연동
          </LayoutTitle>

          <FlexLayout
            align="center"
            justify="center"
            isVertical={true}
          >
            <FlexLayout
              align="center"
              justify="center"
              style={{
                fontSize: 22,
              }}
            >
              정보센터 일괄 연동을 하시겠습니까?
            </FlexLayout>

            <FlexLayout
              align="center"
              justify="center"
            >
              일괄연동을 원하시면 '예' 아니면(단일호기) '아니오'를 눌러주세요.<br />
              일괄연동시에는 중도 실패할 수 있습니다.
            </FlexLayout>

            <FlexLayout
              align="center"
              justify="center"
              style={{
                color: 'var(--color-red)',
              }}
            >
              * 실패시 실패한 호기부터 다시 돌려주세요
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              onClick={() => scope.closeRemoteControlModal(true, true)}
            >
              예 (일괄연동)
            </Button>

            <Button
              isFull={true}
              onClick={() => scope.closeRemoteControlModal(true, false)}
            >
              아니오 (단일호기)
            </Button>

            <Button
              isFull={true}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.closeRemoteControlModal(false, false)}
            >
              취소
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      {/* 도면 */}
      <ModalView
        isVisible={scope.state.isDPModalVisible}
        onClose={() => scope.closeDPModal()}
        width={1366}
      >
        <FlexLayout style={{ padding: 8 }}>
          <GlobalAttachmentBox
            appnum={`TB_E611_DP_${scope.state.actInfo?.actcd}${scope.state.unitFocused?.equpcd}`}
            appnm={'도면'}
            isEnabledPreview={true}
          />
        </FlexLayout>
      </ModalView>

      {/* PLC */}
      <ModalView
        isVisible={scope.state.isPLCModalVisible}
        onClose={() => scope.closePLCModal()}
        width={1366}
      >
        <FlexLayout style={{ padding: 8 }}>
          <GlobalAttachmentBox
            appnum={`TB_E611_PLC_${scope.state.actInfo?.actcd}${scope.state.unitFocused?.equpcd}`}
            appnm={'PLC'}
            isEnabledPreview={true}
          />
        </FlexLayout>
      </ModalView>

      {/* 청구주기 */}
      <ModalView
        isVisible={scope.state.isRecipeIntervalVisible}
        onClose={() => scope.closeRecipeIntervalModal()}
        width={1200}
        height={172}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            청구주기 ({ scope.state.deljugis?.filter((x) => x.com_code === scope.state.contractinformationsFocused?.deljugi)[0]?.com_cnam })
          </LayoutTitle>

          <FlexLayout>
            <LabelBox title="전체">
              <CheckBox
                value={scope.state.delmonAll || false}
                onChange={(v) => scope.onToggleRecipeDelmon(v)}
              />
            </LabelBox>

            <LabelBox title="1월">
              <CheckBox
                value={scope.state.delmon1 || false}
                onChange={(v) => setData({ delmon1: v })}
              />
            </LabelBox>

            <LabelBox title="2월">
              <CheckBox
                value={scope.state.delmon2 || false}
                onChange={(v) => setData({ delmon2: v })}
              />
            </LabelBox>

            <LabelBox title="3월">
              <CheckBox
                value={scope.state.delmon3 || false}
                onChange={(v) => setData({ delmon3: v })}
              />
            </LabelBox>

            <LabelBox title="4월">
              <CheckBox
                value={scope.state.delmon4 || false}
                onChange={(v) => setData({ delmon4: v })}
              />
            </LabelBox>

            <LabelBox title="5월">
              <CheckBox
                value={scope.state.delmon5 || false}
                onChange={(v) => setData({ delmon5: v })}
              />
            </LabelBox>

            <LabelBox title="6월">
              <CheckBox
                value={scope.state.delmon6 || false}
                onChange={(v) => setData({ delmon6: v })}
              />
            </LabelBox>

            <LabelBox title="7월">
              <CheckBox
                value={scope.state.delmon7 || false}
                onChange={(v) => setData({ delmon7: v })}
              />
            </LabelBox>

            <LabelBox title="8월">
              <CheckBox
                value={scope.state.delmon8 || false}
                onChange={(v) => setData({ delmon8: v })}
              />
            </LabelBox>

            <LabelBox title="9월">
              <CheckBox
                value={scope.state.delmon9 || false}
                onChange={(v) => setData({ delmon9: v })}
              />
            </LabelBox>

            <LabelBox title="10월">
              <CheckBox
                value={scope.state.delmon10 || false}
                onChange={(v) => setData({ delmon10: v })}
              />
            </LabelBox>

            <LabelBox title="11월">
              <CheckBox
                value={scope.state.delmon11 || false}
                onChange={(v) => setData({ delmon11: v })}
              />
            </LabelBox>

            <LabelBox title="12월">
              <CheckBox
                value={scope.state.delmon12 || false}
                onChange={(v) => setData({ delmon12: v })}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.saveRecipeIntervalModal()}
              >
                저장
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.closeRecipeIntervalModal()}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      {/* 분할납부 청구주기 */}
      <ModalView
        isVisible={scope.state.isDivideIntervalVisible}
        onClose={() => scope.closeDivideIntervalModal()}
        width={1200}
        height={172}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            청구주기 ({ scope.state.deljugis?.filter((x) => x.com_code === scope.state.divisionsFocused?.deljugi)[0]?.com_cnam })
          </LayoutTitle>

          <FlexLayout>
            <LabelBox title="전체">
              <CheckBox
                value={scope.state.divideDelmonAll}
                onChange={(v) => scope.onToggleDivideDelmon(v)}
              />
            </LabelBox>

            <LabelBox title="1월">
              <CheckBox
                value={scope.state.divideDelmon1}
                onChange={(v) => scope.SS({ divideDelmon1: v })}
              />
            </LabelBox>

            <LabelBox title="2월">
              <CheckBox
                value={scope.state.divideDelmon2}
                onChange={(v) => scope.SS({ divideDelmon2: v })}
              />
            </LabelBox>

            <LabelBox title="3월">
              <CheckBox
                value={scope.state.divideDelmon3}
                onChange={(v) => scope.SS({ divideDelmon3: v })}
              />
            </LabelBox>

            <LabelBox title="4월">
              <CheckBox
                value={scope.state.divideDelmon4}
                onChange={(v) => scope.SS({ divideDelmon4: v })}
              />
            </LabelBox>

            <LabelBox title="5월">
              <CheckBox
                value={scope.state.divideDelmon5}
                onChange={(v) => scope.SS({ divideDelmon5: v })}
              />
            </LabelBox>

            <LabelBox title="6월">
              <CheckBox
                value={scope.state.divideDelmon6}
                onChange={(v) => scope.SS({ divideDelmon6: v })}
              />
            </LabelBox>

            <LabelBox title="7월">
              <CheckBox
                value={scope.state.divideDelmon7}
                onChange={(v) => scope.SS({ divideDelmon7: v })}
              />
            </LabelBox>

            <LabelBox title="8월">
              <CheckBox
                value={scope.state.divideDelmon8}
                onChange={(v) => scope.SS({ divideDelmon8: v })}
              />
            </LabelBox>

            <LabelBox title="9월">
              <CheckBox
                value={scope.state.divideDelmon9}
                onChange={(v) => scope.SS({ divideDelmon9: v })}
              />
            </LabelBox>

            <LabelBox title="10월">
              <CheckBox
                value={scope.state.divideDelmon10}
                onChange={(v) => scope.SS({ divideDelmon10: v })}
              />
            </LabelBox>

            <LabelBox title="11월">
              <CheckBox
                value={scope.state.divideDelmon11}
                onChange={(v) => scope.SS({ divideDelmon11: v })}
              />
            </LabelBox>

            <LabelBox title="12월">
              <CheckBox
                value={scope.state.divideDelmon12}
                onChange={(v) => scope.SS({ divideDelmon12: v })}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.saveDivideIntervalModal()}
              >
                확인
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.closeDivideIntervalModal()}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      {/* 계약기록 */}
      <ModalView
        isVisible={scope.state.historyModal}
        onClose={() => scope.historyPopupModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약내용기록
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
                <FlexLayout>
                  {scope.state.focuseddata?.actnm} 계약내역
                </FlexLayout>
                <FlexLayout justify='right'>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    onClick={() => scope.onNewHistoryEvent()}
                  >
                    신규
                  </Button>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    onClick={() => scope.onHistorySaveEvent()}
                  >
                    저장
                  </Button>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.historyDelete()}
                  >
                    삭제
                  </Button>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.historyPopupModal(false)}
                  >
                    닫기
                  </Button>
                </FlexLayout>
              </FlexLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableHistory}
                  header={[
                    {
                      id: 'seq',
                      text: '순서',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.seq}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'perid',
                      text: '작성자',
                      width: 8,
                      color: 'var(--color-red)',
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <TextBox
                            value={x.perid}
                            textAlign="center"
                            onChange={(value) => rowUpdate({ perid: value })}
                            bindSearchModal={new SearchBinding(
                              scope.props.modalStore,
                              'TB_JA001',
                              {},
                              true,
                              (item) => {
                                rowUpdate({
                                  perid: item.cd,
                                  pernm: item.cdnm,
                                });
                              },
                            )}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'pernm',
                      text: '작성자명',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.pernm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'hdate',
                      text: '기록일자',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.hdate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'wakedate',
                      text: '알림만료일',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.wakedate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'title',
                      text: '제목',
                      width: 40,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          <TextBox
                            value={x.title}
                            textAlign="left"
                            onChange={(value) => rowUpdate({ title: value })}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'okflag',
                      text: '답변확인',
                      width: 6,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <CheckBox
                            noMargin
                            value={x.okflag === '1'}
                            onChange={(value) => {
                              rowUpdate({ okflag: value ? '1' : '0' });
                            }}
                          />
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.historyData || []}
                  infinityHandler={scope.infinityHistory}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onChange={(rows3, updatedRows3) => {
                    scope.onUpdatedRows3(rows3, updatedRows3);
                  }}
                  onRowFocusEvent={(item, i) => scope.onHistoryRowFocusEvent(item, i)}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout>
          <LabelBox title="계약내용">
            <TextBox
              value={scope.state.remark}
              onChange={(value) => scope.setState({ remark: value }, () => scope.updateHistoryRemark(value))}
              isMultiline={true}
            />
          </LabelBox>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={850}
        isVisible={scope.state.isInspectDetailModalVisible}
        onClose={() => scope.setState({ isInspectDetailModalVisible: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              <span>상세현황</span>
            </div>

            <FlexLayout></FlexLayout>
          </SubHeader>
          <FlexLayout size={10}>
            <LabelBox title="현장명">
              <TextBox
                value={scope.state.actInfo?.actnm}
                readonly={true}
                transparent={true}
              />
            </LabelBox>

            <LabelBox title="호기명">
              <TextBox
                value={scope.state.checksFocused?.equpnm}
                readonly={true}
                transparent={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={10}>
            <LabelBox title="검사기관">
              <TextBox
                value={scope.state.checkDetailFocused?.kcpernm}
                readonly={true}
                transparent={true}
              />
            </LabelBox>

            <LabelBox title="검사결과">
              <TextBox
                value={results?.filter((y) => y.value === scope.state.checkDetailFocused?.result)[0]?.remark}
                readonly={true}
                transparent={true}
              />
            </LabelBox>
          </FlexLayout>

          <GridLayout
            header={[
              {
                id: 'article',
                text: '검사항목',
                width: 10,
              },
              {
                id: 'title',
                text: '검사항목명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'descript',
                text: '불합내역',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.descript}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '검사자 불합의견',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={100}
            data={scope.state.inspectDetailData}
          />
          <SubHeader>
            <FlexLayout
              justify="end"
            >
              <Button
                onClick={() => {
                  scope.setState({ isInspectDetailModalVisible: false });
                }}
              >
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={850}
        isVisible={scope.state.changeUnitModal}
        onClose={() => scope.closeChangeUnit()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              <span style={{ lineHeight: 2.3 }}>코드변경</span>
            </div>
          </SubHeader>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <span style={{ padding: 8 }}>*변경 전</span>
              <GridLayout
                header={[
                  {
                    id: 'equpcd_old',
                    text: '코드',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpcd_old}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm_old',
                    text: '호기명',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={scope.state.currentUnitData}
              >
              </GridLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <span style={{ padding: 8, color: 'var(--color-red)' }}>*변경 후</span>

              <DragAndDropLayout
                ref={scope.tableChangeUnit}
                header={[
                  {
                    id: 'equpcd_old',
                    text: '코드',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpcd}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm_old',
                    text: '호기명',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={scope.state.changeUnitData}
                onChange={async (rows) => {
                  await scope.SS({
                    changeUnitData: rows.map((row, index) => ({
                      ...row,
                      equpcd: `00${index + 1}`.substr(-3),
                    })),
                  });
                  scope.tableChangeUnit.current?.update(true);
                }}
              >
              </DragAndDropLayout>
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="end"
            >
              <Button
                onClick={() => scope.onSaveChangeUnit()}
              >
                저장
              </Button>

              <Button
                onClick={() => scope.closeChangeUnit()}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
