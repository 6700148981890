import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e101_aver_sale
 * @category 표준유지관리비-할인
 */
export class DiscountModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 년도
   *
   * **data type** varchar(4)
   */
  year: string;

  /**
   * 2~3할인명
   *
   * **data type** varchar(100)
   */
  salenm23: string;

  /**
   * 2~3할인금액
   *
   * **data type** float
   */
  saleamt23: string;

  /**
   * 4~5할인명
   *
   * **data type** varchar(100)
   */
  salenm45: string;

  /**
   * 4~5할인금액
   *
   * **data type** float
   */
  saleamt45: string;

  /**
   * 6~7할인명
   *
   * **data type** varchar(100)
   */
  salenm67: string;

  /**
   * 6~7할인금액
   *
   * **data type** float
   */
  saleamt67: string;

  /**
   * 8할인명
   *
   * **data type** varchar(100)
   */
  salenm8: string;

  /**
   * 8할인금액
   *
   * **data type** float
   */
  saleamt8: string;

  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.year = data.year || '';
    this.salenm23 = data.salenm23 || '';
    this.saleamt23 = data.saleamt23 || '';
    this.salenm45 = data.salenm45 || '';
    this.saleamt45 = data.saleamt45 || '';
    this.salenm67 = data.salenm67 || '';
    this.saleamt67 = data.saleamt67 || '';
    this.salenm8 = data.salenm8 || '';
    this.saleamt8 = data.saleamt8 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
