import * as React from 'react';
import mStyle from './ModalView.module.scss';
import { ModalViewProps } from '../../../constants';
import { FlexLayout } from '../FlexLayout';
import { DefaultLayout } from '../DefaultLayout';
import { JoinClassName } from '../../../utils/string';

export class ModalView extends React.Component<ModalViewProps> {
  render() {
    const {
      width,
      height,
      style,
      children,
      isVisible,
      onClose,
      zIndex,
    } = this.props;

    return (
      <DefaultLayout
        className={JoinClassName.make([
          mStyle.container,
          isVisible ? mStyle.visible : false,
        ])}
        style={{
          zIndex: 88888 + (zIndex ?? 0),
        }}
      >
        <DefaultLayout
          onClick={() => onClose && onClose()}
          className={mStyle.background}
        />
        {isVisible && <FlexLayout
          isVertical={true}
          size={width || 600}
          className={mStyle.contents}
          style={{
            height: height || 600,
            ...style,
          }}
          align="init"
          justify="init"
        >
          {children}
        </FlexLayout>}
      </DefaultLayout>
    );
  }
}
