import { computed } from 'mobx';

/**
 * 보수해지현장모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class CancelModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 구분
   *
   * **data type** char(10)
   */
  contgubun: string;

  /**
   * 해지사유
   *
   * **data type** char(3)
   */
  cls: string;

  /**
   * 해지사유상세
   *
   * **data type** char(100)
   */
  clsremark: string;

  /**
   * 보수료
   *
   * **data type** number
   */
  amt: string;

  /**
   * 보고서
   *
   * **data type** char(1)
   */
  appflag: string;

  /**
   * 타사업체
   *
   * **data type** char(255)
   */
  tasacltnm: string;

  /**
   * 타사보수료
   *
   * **data type** number
   */
  tasauamt: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.actnm = data.actnm || '';
    this.contgubun = data.contgubun || '';
    this.cls = data.cls || '';
    this.clsremark = data.clsremark || '';
    this.amt = data.amt || '';
    this.appflag = data.appflag || '';
    this.tasacltnm = data.tasacltnm || '';
    this.tasauamt = data.tasauamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
