import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ConfirmWarning } from '../../utils/confirm';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import { ComboBox } from '../../components/forms/ComboBox';
import { cltGubuns, cltRtclafis } from '../../stores/ModalStore';

interface ModalCltProps {
  modalStore: ModalStore;
}

@observer
export class ModalClt extends React.Component<ModalCltProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1100}
        isVisible={modalStore.isVisibleCltSelector}
        onClose={() => modalStore.closeCltSelector()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              align="center"
              justify="center"
              weight={3}
            >
              <span style={{ margin: 8, fontSize: 12 }}>검색어</span>
              <TextBox
                value={modalStore.cltSelectorSearchQuery}
                onChange={(value: any) => modalStore.updateCltSelectorSearchQuery(value)}
                onEnter={() => modalStore.searchCltSelector()}
              />
            </FlexLayout>

            <FlexLayout
              align="center"
              justify="center"
            >
              <span style={{ margin: 8, fontSize: 12 }}>상태</span>
              <ComboBox
                value={modalStore.cltSelectorSearchRtclafi}
                data={cltRtclafis}
                onSelect={(option) => modalStore.updateCltSelectorSearchRtclafi(option.value)}
              />
            </FlexLayout>
          </SearchLayout>

          <GridLayout
            header={[
              {
                id: 'gubun',
                text: '구분',
                width: 10,
                render: (x: any) => {
                  // @ts-ignore
                  const gubunnm = cltGubuns[x.gubun];
                  return (
                    <FlexLayout justify="center" align="center">
                      {gubunnm}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'cltcd',
                text: '코드',
                width: 10,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {x.cltcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'saupnum',
                text: '사업자번호',
                width: 10,
                render: (x: any) => {
                  const saupnum = x.saupnum && x.saupnum.length > 9
                    ? `${x.saupnum.substr(0, 3)}-${x.saupnum.substr(3, 2)}-${x.saupnum.substr(5)}`
                    : x.saupnum;

                  return (
                    <FlexLayout justify="center" align="center">
                      {saupnum}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'prenm',
                text: '대표자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {x.prenm}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={modalStore.cltSelectorInfinity}
            data={modalStore.cltSelectorData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item: any) => modalStore.onCltSelectorRowFocusEvent(item)}
            onRowDoubleClick={(item: any) => modalStore.successCltSelector(item)}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeCltSelector()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (modalStore.selectedCltData) {
                  modalStore.successCltSelector(modalStore.selectedCltData);
                } else {
                  ConfirmWarning.show('오류', '항목을 먼저 선택해주세요.');
                }
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
