import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Enrollment } from '../Enrollment';
import EnrollmentModel from '../models/EnrollmentModel';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentGridHeader: GLHF<EnrollmentModel, Enrollment> = (scope) => ([
  {
    id: 'compdate',
    text: '완료일자',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.compdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'compnum',
    text: '번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.compnum}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '공사구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.Gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
      </FlexLayout>
    ),
  },
]);
