import * as React from 'react';
import { action, computed } from 'mobx';
import { MdReceipt, MdReorder } from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  AreaModel,
  BreakCauseModel,
  BreakContentModel,
  BreakFactorsModel,
  BreakPartModel,
  FloorModel,
  ProcessContentModel,
  TypeModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { BreakAnalysisAllTemplate } from './BreakAnalysisAll.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderArea,
  TabHeaderCause,
  TabHeaderContent,
  TabHeaderFactors,
  TabHeaderFloor,
  TabHeaderPart,
  TabHeaderProcess,
  TabHeaderType,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum BreakAnalysisAllTabId {
  Part,
  Content,
  Factors,
  Cause,
  Process,
  Type,
  Floor,
  Area,
}

export const BreakAnalysisAllTabTitles = [
  '고장부위별',
  '고장내용별',
  '고장요인별',
  '고장원인별',
  '처리내용별',
  '기종별',
  '층수별',
  '지역별',
];

export const BreakAnalysisAllTabModels = [
  BreakPartModel,
  BreakContentModel,
  BreakFactorsModel,
  BreakCauseModel,
  ProcessContentModel,
  TypeModel,
  FloorModel,
  AreaModel,
];

export const BreakAnalysisAllTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
  'tab_7',
  'tab_8',
];

export const BreakAnalysisAllTabDataStateNames = [
  'partList',
  'contentlist',
  'factorslist',
  'causelist',
  'processlist',
  'typelist',
  'floorlist',
  'arealist',
];

export interface BreakAnalysisAllState {
  partList?: Array<BreakPartModel>; // 고장부위별 리스트
  contentlist?: Array<BreakContentModel>; // 고장내용별 리스트
  factorslist?: Array<BreakFactorsModel>; // 고장요인별 리스트
  causelist?: Array<BreakCauseModel>; // 고장원인별 리스트
  processlist?: Array<ProcessContentModel>; // 처리내용별 리스트
  typelist?: Array<TypeModel>; // 기종별 리스트
  floorlist?: Array<FloorModel>; // 층수별 리스트
  arealist?: Array<AreaModel>; // 지역별 리스트

  focusedTab?: TabModel;

  focuseddata?: any;

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  perid: string; // 담당자
  pernm: string; // 담당자명
  divicd: string; // 부서코드
  divinm: string; // 부서명
  gubun: string;

  holderName: string; // placeholder 내용

  total: string;
  tot: string;
  qty_tot: string;
  banqty_tot: string;
  tot_tot: string;

  // URL
  url: string;
  url2: string;
  url3: string;
  url4: string;
  url5: string;
  url6: string;
  url7: string;
  url8: string;
}
/**
 * 컨트롤러
 * @window w_tb_e411w_07
 * @category 고장분석현황[전체]
 */
export class BreakAnalysisAll extends PageComponent<PageProps, BreakAnalysisAllState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = BreakAnalysisAllTabId;
    const titles = BreakAnalysisAllTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    this.tabs = [
      new TabModel(id.Part.toString(), MdReorder, titles[id.Part]),
      new TabModel(id.Content.toString(), MdReceipt, titles[id.Content]),
      new TabModel(id.Factors.toString(), MdReceipt, titles[id.Factors]),
      new TabModel(id.Cause.toString(), MdReceipt, titles[id.Cause]),
      new TabModel(id.Process.toString(), MdReceipt, titles[id.Process]),
      new TabModel(id.Type.toString(), MdReceipt, titles[id.Type]),
      new TabModel(id.Floor.toString(), MdReceipt, titles[id.Floor]),
      new TabModel(id.Area.toString(), MdReceipt, titles[id.Area]),
    ];

    this.tabHeaders = [
      TabHeaderPart,
      TabHeaderContent,
      TabHeaderFactors,
      TabHeaderCause,
      TabHeaderProcess,
      TabHeaderType,
      TabHeaderFloor,
      TabHeaderArea,
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      stdate: `${year - 1}${month}${date}`,
      enddate: `${year}${month}${date}`,
      partList: [],
      contentlist: [],
      factorslist: [],
      causelist: [],
      processlist: [],
      typelist: [],
      floorlist: [],
      arealist: [],
      searchQuery: '', // 검색어
      holderName: '고장부위를 선택하세요',
      perid: bosuPerid || '%', // 담당자
      pernm: bosuPernm || '', // 담당자명
      actcd: '', // 현장
      actnm: '', // 현장명
      divicd: bosuDivicd || '%',
      divinm: bosuDivinm || '',
      gubun: '%',

      total: '',
      tot_tot: '',
      tot: '',
      qty_tot: '',
      banqty_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[BreakAnalysisAllTabId.Part]);
    await this.urlRetrieve();
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i, RetrieveFocusType.DEFAULT);
    await this.onHolderNameMake();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakAnalysisAllTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${BreakAnalysisAllTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;


    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakAnalysisAllTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${BreakAnalysisAllTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
    );
  }

  @computed
  get tabIndex(): BreakAnalysisAllTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab },
      () => this.onRetrieveEvent());
  }

  @action
  async onHolderNameMake() {
    let text = '';
    switch ((this.tabIndex)) {
      case 0:
        text = '고장부위를 선택하세요';
        break;

      case 1:
        text = '고장내용을 선택하세요';
        break;

      case 2:
        text = '고장요인을 선택하세요';
        break;

      case 3:
        text = '고장원인을 선택하세요';
        break;

      case 4:
        text = '처리내용을 선택하세요';
        break;

      case 5:
        text = '기종을 선택하세요';
        break;

      case 6:
        text = '층수를 선택하세요';
        break;

      case 7:
        text = '지역을 선택하세요';
        break;

      default:
        text = '';
    }
    this.setState({ holderName: text });
  }

  @action
  async doRetrieve(i: BreakAnalysisAllTabId, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
      (params) => api.fxExec(
        `${BreakAnalysisAllTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BreakAnalysisAllTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[BreakAnalysisAllTabDataStateNames[i]],
              ...items.map((x: any) => new BreakAnalysisAllTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [BreakAnalysisAllTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [BreakAnalysisAllTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['tot', 'facnm'],
        [this.state.focuseddata?.tot, this.state.focuseddata?.facnm], type, true) || 0;
      // @ts-ignore
      this.state[BreakAnalysisAllTabDataStateNames[i]]
      // @ts-ignore
      && this.state[BreakAnalysisAllTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);
      const data = this.infinity?.box;
      this.setState({
        tot_tot: data?.tot_tot || '0',
        qty_tot: data?.qty_tot || '0',
        banqty_tot: data?.banqty_tot || '0',
      });
    });
  }

  @action
  async onRowFocusEvent(item: any) {
    this.setState({
      focuseddata: item,
    }, async () => {
      await this.urlRetrieve();
    });
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=0&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url2: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=1&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url3: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=2&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url4: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=3&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url5: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=4&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url6: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=5&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url7: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=6&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
      url8: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_07&type=7&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&as_nm=${this.state.searchQuery}`,
    });
  }

  render() {
    return (
      <BreakAnalysisAllTemplate
        scope={this}
      />
    );
  }
}
