import { computed } from 'mobx';

/**
 * 모델
 * @window tb_e503
 * @category 안전관리코드등록
 */
export class SafetyManageModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 그룹코드
   *
   * **data type** varchar(3)
   */
  gsafecd: string;

  /**
   * 그룹코드
   *
   * **data type** varchar(3)
   */
  gsafenm: string;

  /**
   * 안전코드
   *
   * **data type** varchar(3)
   */
  safecd: string;

  /**
   * 안전내용
   *
   * **data type** varchar(255)
   */
  safenm: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.gsafecd = data.gsafecd || '';
    this.gsafenm = data.gsafenm || '';
    this.safecd = data.safecd || '';
    this.safenm = data.safenm || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
