/**
 * 모델
 * @window w_tb_e050w
 * @category 매출현황
 */
import { computed } from 'mobx';

export class SalesStateModel {
  /**
   * x
   *
   * **data type** int
   */
  MSG_ID: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  USER_ID: string;

  /**
   * x
   *
   * **data type** int
   */
  JOB_ID: string;

  /**
   * x
   *
   * **data type** int
   */
  MSG_SUBTYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  SCHEDULE_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  DEST_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  MENT_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  VOICE_TYPE: string;

  /**
   * 제목
   *
   * **data type** varchar(50)
   */
  SUBJECT: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  NOW_DATE: string;

  /**
   * 전송일자
   *
   * **data type** varchar(20)
   */
  SEND_DATE: string;

  /**
   * 보낸이연락처
   *
   * **data type** varchar(20)
   */
  CALLBACK: string;

  /**
   * x
   *
   * **data type** int
   */
  REPLY_TYPE: string;

  /**
   * x
   *
   * **data type** int
   */
  REPLY_COUNT: string;

  /**
   * x
   *
   * **data type** int
   */
  COUNSELOR_DTMF: string;

  /**
   * x
   *
   * **data type** varchar(20)
   */
  COUNSELOR_NUMBER: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 적요
   *
   * **data type** varchar(200)
   */
  remark: string;

  /**
   * 지로번호
   *
   * **data type** varchar(20)
   */
  pubnum: string;

  /**
   * 지로구분
   *
   * **data type** varchar(8)
   */
  jirogubun: string;

  /**
   * 과세구분
   *
   * **data type** varchar(8)
   */
  taxgubun: string;

  /**
   * x
   *
   * **data type** text
   */
  TAIL_MSG: string;

  /**
   * 전자세금계산서상태
   *
   * **data type** varchar(8)
   */
  recivstatus: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(8)
   */
  billkind: string;

  /**
   * 귀속년월
   *
   * **data type** varchar(8)
   */
  yyyymm: string;

  /**
   * 보낸이
   *
   * **data type** varchar(50)
   */
  RESERVED1: string;

  /**
   * 받는이
   *
   * **data type** varchar(50)
   */
  RESERVED2: string;

  /**
   * 미수금
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 매출액(합계)
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 공급가액
   *
   * **data type** number
   */
  amt: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(20)
   */
  taxcls: string;

  /**
   * 관리대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 전송여부
   *
   * **data type** int
   */
  SUCC_COUNT: string;

  /**
   * 담당자명
   *
   * **data type** varchar(20)
   */
  pernm: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  /**
   * 매출분류
   *
   * **data type** char(3)
   */
  billgubun2: string;

  /**
   * 매출구분
   *
   * **data type** char(3)
   */
  gubun: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  misnum: string;

  /**
   * 그룹코드
   *
   * **data type** varchar(50)
   */
  groupcd: string;

  /**
   * 전화구분
   * **data type** varchar(10)
   */
  telcd: string;

  /**
   * 회사구분
   * **data type** varchar(10)
   */
  wkactcd: string;

  /**
   * 회사구분2
   * **data type** varchar(10)
   */
  wkactnm: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 현장코드
   *
   * **data type** char(4)
   */
  actcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.misdate = data.misdate || '';
    this.actcd = data.actcd || '';
    this.cltcd = data.cltcd || '';
    this.MSG_ID = data.MSG_ID || '';
    this.USER_ID = data.USER_ID || '';
    this.JOB_ID = data.JOB_ID || '';
    this.MSG_SUBTYPE = data.MSG_SUBTYPE || '';
    this.SCHEDULE_TYPE = data.SCHEDULE_TYPE || '';
    this.DEST_TYPE = data.DEST_TYPE || '';
    this.MENT_TYPE = data.MENT_TYPE || '';
    this.VOICE_TYPE = data.VOICE_TYPE || '';
    this.SUBJECT = data.SUBJECT || '';
    this.NOW_DATE = data.NOW_DATE || '';
    this.SEND_DATE = data.SEND_DATE || '';
    this.CALLBACK = data.CALLBACK || '';
    this.REPLY_TYPE = data.REPLY_TYPE || '';
    this.REPLY_COUNT = data.REPLY_COUNT || '';
    this.COUNSELOR_DTMF = data.COUNSELOR_DTMF || '';
    this.COUNSELOR_NUMBER = data.COUNSELOR_NUMBER || '';
    this.projnm = data.projnm || '';
    this.remark = data.remark || '';
    this.pubnum = data.pubnum || '';
    this.jirogubun = data.jirogubun || '';
    this.taxgubun = data.taxgubun || '';
    this.TAIL_MSG = data.TAIL_MSG || '';
    this.recivstatus = data.recivstatus || '';
    this.billkind = data.billkind || '';
    this.yyyymm = data.yyyymm || '';
    this.RESERVED1 = data.RESERVED1 || '';
    this.RESERVED2 = data.RESERVED2 || '';
    this.iamt = data.iamt || '';
    this.rcvamt = data.rcvamt || '';
    this.misamt = data.misamt || '';
    this.addamt = data.addamt || '';
    this.amt = data.amt || '';
    this.taxcls = data.taxcls || '';
    this.qty = data.qty || '';
    this.SUCC_COUNT = data.SUCC_COUNT || '';
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.cltnm = data.cltnm || '';
    this.billgubun2 = data.billgubun2 || '';
    this.gubun = data.gubun || '';
    this.misnum = data.misnum || '';
    this.groupcd = data.groupcd || '';
    this.telcd = data.telcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
