import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdAvTimer,
  MdWork,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { SiteModel, DepartmentModel } from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { RepairSiteTemplate } from './RepairSite.template';
import { InfinityRetrieve, SpjangItem } from '../../../../models/common';
import { TabHeaderSite, TabHeaderDepartment } from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { HistoryPopupModel } from '../MonthContract/HistoryPopup.model';
import { EndContractTabFocusedStateNames } from '../EndContract/EndContract';
import { TableLayout } from '../../../../components';
import { PageComponent } from '../../../../utils';


export enum RepairSiteTabId {
  Period,
  Department,
}

export const RepairSiteTabTitles = [
  '회사구분별',
  '부서별',
];

export const RepairSiteTabModels = [
  SiteModel,
  DepartmentModel,
];

export const RepairSiteTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const RepairSiteTabDataStateNames = [
  'Periods',
  'Departments',
];

export const RepairSiteTabUpdatesStateNames = [
  'PeriodUpdates',
  'DepartmentUpdates',
];

export const RepairSiteTabFocusedStateNames = [
  'PeriodUpdatesFocused',
  'DepartmentUpdatesFocused',
];

export interface RepairSiteState {
  // 회사구분별
  Sites?: Array<SiteModel>;
  SiteUpdates?: Array<SiteModel>;
  SitesFocused?: SiteModel;

  // 부서별
  Departments?: Array<DepartmentModel>;
  DepartmentUpdates?: Array<DepartmentModel>;
  DepartmentsFocused?: DepartmentModel;

  focusedTab?: TabModel;
  spjangcds?: Array<SpjangItem>;
  contgs?: Array<any>;
  bildyds?: Array<any>;
  contgubuns?: Array<any>;
  taxgubuns?: Array<any>;
  spjangcd?: string;
  cltcd?: string;
  wkactcd?: string;
  wkactnm?: string;
  taxflag?: string;
  contg?: string;
  bildyd?: string;
  gubun?: string;
  stmon?: string;
  endmon?: string;
  contgubun?: string;
  deljugi?: string;
  mgubun?: string;
  divicd?: string;
  divinm?: string;
  perid?: string;
  pernm?: string;
  contperid?: string;
  contpernm?: string;
  boryu?: string;
  boryustmon?: string;
  boryuendmon?: string;
  billgubun?: string;
  areacd?: string;

  deljugis?: Array<any>;
  gubuns?: Array<any>;

  // 계약기록 모달
  historyFocusedData?: HistoryPopupModel;
  historyData?: Array<HistoryPopupModel>;
  historyModal?: boolean;
  remark?: string;

  // trail
  amt_tot?: string;
  averamt_tot?: string;
  equpamt_tot?: string;
  monamt_tot?: string;
  nowamt_tot?: string;
  qty_tot?: string;
  total?: string;
}

/**
 * 컨트롤러
 * @window w_tb_e101w
 * @category 유지보수계약현황
 */
export class RepairSite extends PageComponent<PageProps, RepairSiteState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  updatedRows2?: Array<HistoryPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  tableHistory: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinityHistory?: InfinityRetrieve;



  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = RepairSiteTabId;
    const titles = RepairSiteTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    this.tabs = [
      new TabModel(id.Period.toString(), MdAvTimer, titles[id.Period]),
      new TabModel(id.Department.toString(), MdWork, titles[id.Department]),
    ];
    // state 기본값 정의
    this.state = props.state || {
      spjangcd: user.spjangcd,
      cltcd: '',
      wkactcd: '%',
      taxflag: '%',
      contg: '%',
      bildyd: '%',
      gubun: '1',
      stmon: '',
      endmon: '',
      contgubun: '%',
      deljugi: '%',
      mgubun: '%',
      divinm: bosuDivinm || '',
      divicd: bosuDivicd || '%',
      perid: bosuPerid || '%',
      pernm: bosuPernm || '',
      contperid: '%',
      boryu: '%',
      billgubun: '%',
      areacd: '%',
      boryustmon: '',
      boryuendmon: '',
      data: [],
    };

    this.grid = React.createRef();

    this.tabHeaders = [
      // 기간별
      TabHeaderSite,

      // 부서별
      TabHeaderDepartment,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트
    let data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 건물용도 리스트 가져오기
    data = await api.dropdown('wf_dd_e029');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ bildyds: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    data = await api.dropdown('wf_dd_ca510_007_01');
    if (!data) return;
    this.setState({ deljugis: data.items });

    // 매출 구분
    data = await api.dropdown('wf_dd_da020');
    if (!data) return;

    // 세금 구분
    data = await api.dropdown('wf_dd_ca510_006_01');
    this.setState({
      taxgubuns: data.items,
    });
    if (!data) return;

    this.setState({ gubuns: data?.items || [] });

    await this.onTabChange(this.tabs[RepairSiteTabId.Period]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab) {
      this.setState({
        [RepairSiteTabUpdatesStateNames[i]]: [],
        [RepairSiteTabFocusedStateNames[i]]: undefined,
      });
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: RepairSiteTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd,
        as_nm: this.state.cltcd,
        wkactcd: this.state.wkactcd,
        taxflag: this.state.taxflag,
        contg: this.state.contg,
        bildyd: this.state.bildyd,
        billgubun: '%',
        gubun: this.state.gubun,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        contgubun: this.state.contgubun,
        deljugi: this.state.deljugi,
        mgubun: this.state.mgubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
        contperid: this.state.contperid,
        boryu: this.state.boryu,
        boryustmon: this.state.boryustmon,
        boryuendmon: this.state.boryuendmon,
      },
      (params) => api.fxExec(
        `${RepairSiteTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          this.setState({
            [RepairSiteTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[RepairSiteTabDataStateNames[i]],
                ...items.map((x: any) => new RepairSiteTabModels[i](x))],
          }, next);
        }
      },
      async () => {
        await this.SS({ [RepairSiteTabDataStateNames[i]]: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [RepairSiteTabDataStateNames[i]]: [],
      [RepairSiteTabUpdatesStateNames[i]]: [],
      [RepairSiteTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.infinity?.retrieveAll();
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        amt_tot: data?.amt_tot || '0',
        averamt_tot: data?.averamt_tot || '0',
        equpamt_tot: data?.equpamt_tot || '0',
        monamt_tot: data?.monamt_tot || '0',
        nowamt_tot: data?.nowamt_tot || '0',
        qty_tot: data?.qty_tot || '0',
      });
      this.grid.current?.setFocus(0);
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[RepairSiteTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${RepairSiteTabFunctionNames[i]}_print`,
      {
        spjangcd: this.state.spjangcd,
        as_nm: this.state.cltcd,
        wkactcd: this.state.wkactcd,
        taxflag: this.state.taxflag,
        contg: this.state.contg,
        bildyd: this.state.bildyd,
        gubun: this.state.gubun,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        contgubun: this.state.contgubun,
        deljugi: this.state.deljugi,
        mgubun: this.state.mgubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
        contperid: this.state.contperid,
        boryu: this.state.boryu,
        boryustmon: this.state.boryustmon,
        boryuendmon: this.state.boryuendmon,
        billgubun: this.state.billgubun,
        areacd: this.state.areacd,
      },
    );
  }

  @action
  async historyRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화

    this.infinityHistory = new InfinityRetrieve(
      {
        sub: 'w_tb_e101_history',
        // @ts-ignore
        actcd: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          historyData: [...items],
        });
      },
      async () => {
        await this.SS({ historyData: [] });
        await this.infinityHistory?.retrieveAll();
        await this.tableHistory.current?.update(false);
        await this.tableHistory.current?.setFocus(0, 1);
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      historyData: [],
    }, async () => {
      await this.infinityHistory?.retrieveAll();
      await this.tableHistory.current?.update(false);
      await this.tableHistory.current?.setFocus(0, 1);
    });
  }

  @action
  async onNewHistoryEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e101_history',
      // @ts-ignore
      actcd: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
    });

    const newModel = new HistoryPopupModel(data, true);

    if (data) {
      this.setState({
        historyData: [...this.state.historyData || [], data],
        historyFocusedData: newModel,
      }, () => this.tableHistory.current?.update(false));
      // @ts-ignore
      this.tableHistory.current?.setFocus(this.state.historyData?.length - 1, 1);
    }
  }

  @action
  async onHistorySaveEvent() {
    const { actionStore: api } = this.props;
    // const params = [{
    //   new: this.state.historyFocusedData?.new,
    //   actcd: this.state.historyFocusedData?.actcd,
    //   actnm: this.state.historyFocusedData?.actnm,
    //   seq: this.state.historyFocusedData?.seq,
    //   perid: this.state.historyFocusedData?.perid,
    //   pernm: this.state.historyFocusedData?.pernm,
    //   hdate: this.state.historyFocusedData?.hdate,
    //   title: this.state.historyFocusedData?.title,
    //   remark: this.state.remark,
    //   wakeflag: '',
    //   wakedate: this.state.historyFocusedData?.wakedate,
    //   okflag: this.state.historyFocusedData?.okflag,
    // }];

    const data = await api.save({
      sub: 'w_tb_e101_history',
      items: this.updatedRows2,
    }, true);
    if (data) {
      this.setState({

      }, () => this.historyRetrieve());
    }
  }

  @action
  async historyDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e101_history',
      // @ts-ignore
      actcd: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
      seq: this.state.historyFocusedData?.seq,
    }) && await this.historyRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[RepairSiteTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxExcel(
      `${RepairSiteTabFunctionNames[i]}_excel`,
      {
        spjangcd: this.state.spjangcd,
        as_nm: this.state.cltcd,
        wkactcd: this.state.wkactcd,
        taxflag: this.state.taxflag,
        contg: this.state.contg,
        bildyd: this.state.bildyd,
        gubun: this.state.gubun,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        contgubun: this.state.contgubun,
        deljugi: this.state.deljugi,
        mgubun: this.state.mgubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
        contperid: this.state.contperid,
        boryu: this.state.boryu,
        boryustmon: this.state.boryustmon,
        boryuendmon: this.state.boryuendmon,
        billgubun: this.state.billgubun,
        areacd: this.state.areacd,
      },
    );
  }

  @computed
  get tabIndex(): RepairSiteTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  async historyPopupModal(isOpen: boolean) {
    setTimeout(() => this.historyRetrieve(), 50);
    this.setState({ historyModal: isOpen });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onRowFocusEvent2(item:HistoryPopupModel) {
    await this.setState({
      historyFocusedData: item,
      remark: item.remark,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [RepairSiteTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [RepairSiteTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<HistoryPopupModel>, updatedRows2: Array<HistoryPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ historyData: rows2 });
  }

  render() {
    return (
      <RepairSiteTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
