import { computed } from 'mobx';

/**
 * 모델
 * 승강기이력카드
 * @window w_tb_e611_type
 * @category 승강기제원정보[이력카드]
 */
export class HistoryModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  recorddate: string;

  /**
   * 발생구분
   *
   * **data type** varchar(100)
   */
  gubun: string;

  /**
   * 작업내용
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 작업자
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.recorddate = data.recorddate || '';
    this.gubun = data.gubun || '';
    this.remark = data.remark || '';
    this.pernm = data.pernm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
