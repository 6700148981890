import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { BuyNationalTaxService } from './BuyNationalTaxService';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

const mijchks = [
  { value: '0', remark: '미처리' },
  { value: '1', remark: '매입처리' },
  { value: '2', remark: '지급처리' },
];

const gubuns = [
  { value: '01', remark: '매입세금계산서' },
  { value: '02', remark: '매입계산서' },
  { value: '03', remark: '매입카드' },
  { value: '04', remark: '기타' },
  { value: '05', remark: '수정세금계산서' },
];

/**
 * 화면
 * @window w_tb_ca640_import
 * @category 매입등록[국세청]
 */
export const BuyNationalTaxServiceTemplate: React.FC<TemplateProps<BuyNationalTaxService>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onSubExcelEvent()}>
          <div style={{ width: 80 }}>EXCEL자료</div>
        </Button>

        <Button onClick={() => scope.onNationalTaxEvent()}>
          <div style={{ width: 80 }}>국세청자료</div>
        </Button>

        <Button onClick={() => scope.onSalesEvent()}>
          <div style={{ width: 80 }}>매입처리</div>
        </Button>

        <Button onClick={() => scope.onSalesCancelEvent()}>
          <div style={{ width: 80 }}>매입취소</div>
        </Button>
      </SearchLayout>
    </FlexLayout>

    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'chk',
          text: (
            <FlexLayout
              justify="center"
              align="center"
            >
              <CheckBox
                noMargin
                value={scope.state.pushTotalCheck}
                onChange={async (value) => scope
                  .updateCheckAllToggle(value)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
          width: 50,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              합계
            </FlexLayout>
          ),
          render: (x, rowUpdate) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              <CheckBox
                noMargin
                value={x.chk === '1'}
                onChange={(value) => {
                  rowUpdate({
                    chk: value ? '1' : '0',
                    // 미처리 chk 시 계정과목 등등 넣기 위한 값
                    acccd: value === true && x.mijchk === '0' ? scope.state.acccd : '',
                    accnm: value === true && x.mijchk === '0' ? scope.state.accnm : '',
                    artcd: value === true && x.mijchk === '0' ? scope.state.artcd : '',
                    artnm: value === true && x.mijchk === '0' ? scope.state.artnm : '',
                  });
                }}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
        },
        {
          id: 'mijchk',
          text: '매입여부',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.total}건
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{ color: x.mijchk === '0' ? 'var(--color-red)' : '' }}
            >
              {mijchks.filter((y) => y.value === x.mijchk)[0]?.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'mijflag',
          text: '매입/처리',
          width: 70,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{ color: x.mijflag === '1' ? 'var(--color-blue)' : '' }}
            >
              {x.mijflag === '0' ? '수동처리' : '엑셀연동'}
            </FlexLayout>
          ),
        },
        {
          id: 'gubun',
          text: '구분',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'mijdate',
          text: '작성일자',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.mijdate)}
            </FlexLayout>
          ),
        },
        {
          id: 'seq',
          text: '승인번호',
          width: 200,
        },
        {
          id: 'cltcd',
          text: '거래처코드',
          width: 100,
        },
        {
          id: 'cltnm',
          text: '거래처명',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.cltnm}
            </FlexLayout>
          ),
        },
        {
          id: 'totsamt',
          text: '총공급가액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.totsamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.totsamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'tottamt',
          text: '총부가세액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.tottamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.tottamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'totmamt',
          text: '총금액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.totmamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.totmamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'remark',
          text: '품목명',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'size',
          text: '규격',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.size === null ? '0' : Format.number(x.size)}
            </FlexLayout>
          ),
        },
        {
          id: 'qty',
          text: '수량',
          width: 50,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.qty_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.uamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {x.uamt === null ? '0' : Format.number(x.uamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'samt',
          text: '공급가액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.samt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.samt)}
            </FlexLayout>
          ),
        },
        {
          id: 'tamt',
          text: '부가세액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.tamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.tamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'mijamt',
          text: '합계',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.mijamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.mijamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'tax_spdate',
          text: '발급일자',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.tax_spdate)}
            </FlexLayout>
          ),
        },
        {
          id: 'saupnum',
          text: '사업자번호',
          width: 100,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="###-##-#####"
              value={x.saupnum}
            />
          ),
        },
        {
          id: 'prenm',
          text: '대표자명',
          width: 100,
        },
        {
          id: 'taxmail',
          text: '메일',
          width: 150,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.taxmail}
            </FlexLayout>
          ),
        },
        {
          id: 'artcd',
          text: '항목코드',
          width: 100,
          color: 'var(--color-red)',
          render: (x, rowUpdate, refs) => (
            <TextBox
              ref={refs}
              value={x.artcd}
              textAlign="center"
              onChange={(value) => rowUpdate({ artcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_CA648_ARG',
                {},
                true,
                (item) => {
                  rowUpdate({
                    artcd: item.cd,
                    artnm: item.cdnm,
                    acccd: item.uv_arg1,
                    accnm: item.uv_arg2,
                  });
                },
              )}
            />
          ),
        },
        {
          id: 'artnm',
          text: '항목명',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.artnm}
            </FlexLayout>
          ),
        },
        {
          id: 'acccd',
          text: '계정코드',
          width: 100,
          color: 'var(--color-red)',
          render: (x, rowUpdate, refs) => (
            <TextBox
              ref={refs}
              value={x.acccd}
              textAlign="center"
              onChange={(value) => rowUpdate({ acccd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AC001',
                {},
                true,
                (item) => {
                  rowUpdate({
                    acccd: item.cd,
                    accnm: item.cdnm,
                  });
                },
              )}
            />
          ),
        },
        {
          id: 'accnm',
          text: '항목명',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.accnm}
            </FlexLayout>
          ),
        },
        {
          id: 'gubun2',
          text: '종류',
          width: 70,
        },
        {
          id: 'bhflag',
          text: '발행구분',
          width: 70,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.bhflag === 'OD' ? '전자' : '수기'}
            </FlexLayout>
          ),
        },
        {
          id: 'receiptyn',
          text: '청구구분',
          width: 70,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.receiptyn === '1' ? '영수' : '청구'}
            </FlexLayout>
          ),
        },
        {
          id: 'bigo',
          text: '비고',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.bigo}
            </FlexLayout>
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      data={scope.state.data}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
    />
  </FlexLayout>
);
