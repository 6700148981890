import { TLHF } from '../../../../../constants';
import { DateTextBox, TextBox } from '../../../../../components';
import { Plan } from '../Plan';
import { SearchBinding } from '../../../../../models';
import PlanActModel from '../models/PlanActModel';

/**
 * 화면 > 팝업 - 현장 테이블 레이아웃
 * @window w_tb_e470
 * @category 공사계획등록
 */
export const PlanActTableHeader: TLHF<PlanActModel, Plan> = (scope) => ([
  {
    id: 'plandate',
    text: '공사일자',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        format="####-##-##"
        textAlign="center"
        value={x.plandate}
        onChange={(v) => rowUpdate({ plandate: v })}
      />
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 20,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actcd}
        textAlign="center"
        onChange={(v) => rowUpdate({ actcd: v })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });

            scope.actItemChanged(rowUpdate, 'actcd', item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 50,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actnm}
        onChange={(v) => rowUpdate({ actnm: v })}
      />
    ),
  },
]);
