import * as React from 'react';
import {
  AttachmentBox, FlexLayout,
} from '../../../../../components';
import { TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';

/**
 * 화면 > 탭 A
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export const EnrollmentAttachmentTabTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout
    isVertical={true}
  >
    <AttachmentBox
      appnum={scope.state.content?.appnum}
      appnm="설치견적서"
      isEnabledTitle={true}
      isEnabledPreview={true}
    />
  </FlexLayout>
);
