import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { ChargeReceivable } from '../ChargeReceivable';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { HyunDaeTabGridHeader } from '../heades/HyunDaeTabGridHeader';


export const HyunDaeTemplate: React.FC<TemplateProps<ChargeReceivable>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridHyunDae}
      header={HyunDaeTabGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      headerHeight={48}
      data={scope.state.dataHyunDae}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
