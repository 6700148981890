import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ConfirmWarning } from '../../utils/confirm';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import { ComboBox } from '../../components/forms/ComboBox';
import { projectGubuns, projectStates, projectStatesForInstall } from '../../stores/ModalStore';
import { Date8 } from '../../utils/time';

interface ModalProjectsProps {
  modalStore: ModalStore;
}

@observer
export class ModalProjects extends React.Component<ModalProjectsProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1100}
        isVisible={modalStore.isVisibleProjectSelector}
        onClose={() => modalStore.closeProjectSelector()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              align="center"
              justify="center"
              weight={3}
            >
              <span style={{ margin: 8, fontSize: 12 }}>검색어</span>
              <TextBox
                value={modalStore.projectSelectorSearchQuery}
                onChange={(value: any) => modalStore.updateProjectSelectorSearchQuery(value)}
                onEnter={() => modalStore.searchProjectSelector()}
              />
            </FlexLayout>

            <FlexLayout
              align="center"
              justify="center"
            >
              <span style={{ margin: 8, fontSize: 12 }}>상태</span>
              <ComboBox
                value={modalStore.projectSelectorSearchGubun}
                data={modalStore.isInstallProjectSelector ? projectStatesForInstall : projectStates}
                onSelect={(option) => modalStore.updateProjectSelectorSearchGubun(option.value)}
              />
            </FlexLayout>
          </SearchLayout>

          <GridLayout
            header={[
              {
                id: 'gubun',
                text: '구분',
                width: 10,
                render: (x: any) => {
                  // @ts-ignore
                  const gubunnm = projectGubuns[x.gubun];
                  return (
                    <FlexLayout justify="center" align="center">
                      {gubunnm}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'projno',
                text: '번호',
                width: 10,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {x.projno}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'state',
                text: '상태',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: x.state === '0' ? 'var(--color-blue)' : 'inherit',
                    }}
                  >
                    {x.state === '0' ? '진행중' : '완료'}
                  </FlexLayout>
                ),
              },
              {
                id: 'prodate',
                text: '계약일자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {Date8.withDash(x.prodate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={modalStore.projectSelectorInfinity}
            data={modalStore.projectSelectorData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item: any) => modalStore.onProjectSelectorRowFocusEvent(item)}
            onRowDoubleClick={(item: any) => modalStore.successProjectSelector(item)}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeProjectSelector()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (modalStore.selectedProjectData) {
                  modalStore.successProjectSelector(modalStore.selectedProjectData);
                } else {
                  ConfirmWarning.show('오류', '항목을 먼저 선택해주세요.');
                }
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
