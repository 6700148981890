import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { BreakRefusalTemplate } from './BreakRefusal.template';
import { BreakRefusalModel } from './BreakRefusal.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface BreakOftenState {
  src: string;
  stdate: string;
  enddate: string;
  endnm: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  gubun: string;
  muperid: string;
  mupernm: string;
  searchQuery: string;
  cltcd: string;
  data: Array<BreakRefusalModel>;
  focusedData?: BreakRefusalModel;
  contgs: Array<any>;
  contgubuns: Array<any>;
  url?: string;

  // trail
  total: string;
  equpcnt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e401w_cancle
 * @category 고장배정거절내역
 */
export class BreakRefusal extends PageComponent<PageProps, BreakOftenState>
  implements PageToolEvents {
  updatedRows?: Array<BreakRefusalModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    function dateToYYYYMMDD(date: Date) {
      function pad(num: string | number | any[]) {
        // eslint-disable-next-line no-param-reassign
        num += '';
        return typeof num !== 'number' && num?.length < 2 ? `0${num}` : num;
      }
      return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;
    }

    const today = new Date();
    const oneMonthAgo: Date = new Date(today.setMonth(today.getMonth() - 1));

    const oneMonthAgoDate = dateToYYYYMMDD(oneMonthAgo);

    // state 기본값 정의
    this.state = props.state || {
      url: '',
      stdate: `${oneMonthAgoDate}`,
      enddate: Today.date(),
      searchQuery: '',
      gubun: '%',
      divicd: '',
      divinm: '',
      pernm: '',
      perid: '',
      cltcd: '',
      data: [],
      // trail
      total: '0',
      equpcnt_tot: '0',
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new BreakRefusalModel(x))],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const data = await this.infinity?.retrieve();
      this.setState({
        total: data?.total || '0',
        equpcnt_tot: data?.equpcnt_tot || '0',
      });
      if (this.state.data.length) {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: BreakRefusalModel) {
    this.setState({ focusedData: item });
  }

  render() {
    return <BreakRefusalTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
