import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { TableLayoutHeader } from '../../../../../constants';
import { TextBox } from '../../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../../models/common';
import { Journal } from '../Journal';
import { DetailModel } from '../models';
import {
  ComboBox,
  ComboBoxModel,
} from '../../../../../components/forms/ComboBox';

// 부품내역
const Detail: TableLayoutHeader<DetailModel, Journal>[] = [
  {
    id: 'rptnum',
    text: '순번',
    width: 50,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.rptnum}
      </FlexLayout>
    ),
  },
  {
    id: 'wkcd',
    text: '구분',
    width: 50,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total_tot} 건
      </FlexLayout>
    ),
    render: (x, rowUpdate, _, scope) => (
      <ComboBox
        value={x.wkcd}
        data={scope?.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
        onSelect={(option) => { rowUpdate({ wkcd: option.value }); }}
      />
    ),
  },
  {
    id: 'actcd',
    text: '코드',
    width: 75,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.actcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ actcd: value })}
        bindSearchModal={new SearchBinding(
          // @ts-ignore
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actnm}
        textAlign="left"
        onChange={(value) => rowUpdate({ actnm: value })}
      />
    ),
  },
  {
    id: 'equpcd',
    text: '코드',
    width: 75,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.equpcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ equpcd: value })}
        bindSearchModal={new SearchBinding(
          // @ts-ignore
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: x?.actcd },
          true,
          (item) => {
            rowUpdate({
              equpcd: item.cd,
              equpnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '동호기',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'frtime',
    text: '도착시간',
    width: 75,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="##:##"
        value={x.frtime}
        onChange={(value) => rowUpdate({ frtime: value })}
      />
    ),
  },
  {
    id: 'totime',
    text: '완료시간',
    width: 75,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="##:##"
        value={x.totime}
        onChange={(value) => rowUpdate({ totime: value })}
      />
    ),
  },
  {
    id: 'remark',
    text: '세부내용(방문일지)',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        textAlign="left"
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
  {
    id: 'prekm',
    text: '예상거리(KM)',
    width: 75,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.prekm_tot}
      </FlexLayout>
    ),
    render: (x) => {
      // eslint-disable-next-line no-restricted-globals
      const prekm = isNaN(parseFloat(x.prekm)) ? '0.0' : parseFloat(x.prekm).toFixed(1);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {prekm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'km',
    text: '이동거리(KM)',
    width: 75,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.km_tot}
      </FlexLayout>
    ),
    render: (x) => {
      // eslint-disable-next-line no-restricted-globals
      const km = isNaN(parseFloat(x.km)) ? '0.0' : parseFloat(x.km).toFixed(1);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {km}
        </FlexLayout>
      );
    },
  },
];

export default Detail;
