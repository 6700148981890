import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Status } from '../Status';
import StatusModel from '../models/StatusModel';
import { Date8 } from '../../../../../utils/time';


const states = [
  { key: '0', value: '', color: 'inherit' },
  { key: '1', value: '설치', color: 'var(--color-olive)' },
  { key: '2', value: '보수', color: 'var(--color-blue)' },
  { key: '3', value: '설치종료', color: 'var(--color-red)' },
];

const process = [
  { key: '0', value: '대기', color: 'var(--color-red)' },
  { key: '1', value: '진행중', color: 'inherit' },
  { key: '2', value: '완료', color: 'var(--color-blue)' },
];


/**
 * 화면 > 그리드
 * @window w_tb_e601w_sulchi_04
 * @category 설치공정현황
 */
export const StatusGridHeader: GLHF<StatusModel, Status> = () => ([
  {
    id: 'state',
    text: '상태',
    width: 70,
    render: (x) => {
      const fp = states.filter((y) => y.key === x.state);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'scactnm',
    text: '설치현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scactnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'sizecd',
    text: '사양',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.sizecd}
      </FlexLayout>
    ),
  },
  {
    id: 'orddate',
    text: '계약일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.orddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '납기일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.enddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'process_per',
    text: '진행률',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          color: 'var(--color-violet)',
        }}
      >
        <b>{x.process_per}%</b>
      </FlexLayout>
    ),
  },
  {
    id: 'process001',
    text: '형판작업',
    width: 100,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process001);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process002',
    text: '레일양중',
    width: 100,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process002);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process003',
    text: '기계',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process003);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process004',
    text: '저속',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process004);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process005',
    text: '1단',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process005);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process006',
    text: '체대',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process006);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process007',
    text: '로핑',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process007);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process008',
    text: '레일작업',
    width: 100,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process008);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process009',
    text: '실',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process009);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process010',
    text: '삼방틀',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process010);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process011',
    text: '헷다',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process011);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process012',
    text: '도어',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process012);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process013',
    text: '배선',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process013);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process014',
    text: '판넬',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process014);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process015',
    text: '준비',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process015);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process016',
    text: '고속',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process016);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'process017',
    text: '검사',
    width: 70,
    render: (x) => {
      const fp = process.filter((y) => y.key === x.process017);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
]);
