import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e470
 * @category 공사계획등록
 */
export default class PlanActModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장코드1
   *
   * **data type** varchar(8)
   */
  readonly actcd1: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 완료수
   *
   * **data type** varchar(8)
   */
  readonly compcnt: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 계획수
   *
   * **data type** varchar(8)
   */
  readonly plancnt: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actcd1 = data.actcd1 || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.compcnt = data.compcnt || '';
    this.equpcd = data.equpcd || '';
    this.perid = data.perid || '';
    this.plancnt = data.plancnt || '';
    this.plandate = data.plandate || '';
    this.state = data.state || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
