import * as React from 'react';
import {
  Button, FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentDetailTableHeader } from '../headers/EnrollmentDetailTableHeader';

/**
 * 화면 > 탭 - 견적항목
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentTabDetailTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <FlexLayout />

      <Button onClick={() => scope.onClickDetailNew()}>추가</Button>
      <Button onClick={() => scope.onClickDetailDelete()}>삭제</Button>
    </FlexLayout>

    <TableLayout
      ref={scope.tableDetail}
      infinityHandler={scope.infinityDetail}
      header={EnrollmentDetailTableHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.detail}
      onRowFocusEvent={(item, index) => scope.onTableDetailRowFocusEvent(item, index)}
      onChange={(rows) => scope.SS({ detail: rows })}
    />
  </FlexLayout>
);
