import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Regist } from './Regist';
import { TabModel } from '../../../../models';
import {
  RegistTabAttachment,
  RegistTabBal,
  RegistTabConference,
  RegistTabEstimate,
  RegistTabProcess,
  RegistTabSales,
  RegistTabScAmt,
  RegistTabSuju,
  RegistTabTel,
} from './tabs';

/**
 * 화면 > 상세 > 탭
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTab: Array<TabLayoutDataItem<Regist>> = [
  {
    tab: new TabModel('REGIST_PROCESS', FaCodeBranch, '공정현황'),
    template: (scope) => <RegistTabProcess scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_CONFERENCE', FaCodeBranch, '현장협의사항'),
    template: (scope) => <RegistTabConference scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_TEL', FaCodeBranch, '담당연락처'),
    template: (scope) => <RegistTabTel scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_SCAMT', FaCodeBranch, '설치비내역'),
    template: (scope) => <RegistTabScAmt scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_SUJU', FaCodeBranch, '수주내역'),
    template: (scope) => <RegistTabSuju scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_BAL', FaCodeBranch, '발주내역'),
    template: (scope) => <RegistTabBal scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_ATTACHMENT', FaCodeBranch, '첨부내역'),
    template: (scope) => <RegistTabAttachment scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_ESTIMATE', FaCodeBranch, '견적내역'),
    template: (scope) => <RegistTabEstimate scope={scope} />,
  },
  {
    tab: new TabModel('REGIST_SALES', FaCodeBranch, '영업내역'),
    template: (scope) => <RegistTabSales scope={scope} />,
  },
];
