import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { SpjangModel } from './Spjang.model';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout, ImageView } from '../../../../components';
import { SpjangTemplate } from './Spjang.template';
import {
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  FileReader,
  FileSelector,
} from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { PageComponent } from '../../../../utils';
import { Fix } from '../../../../utils/string';

export enum SpjangImages {
  CUST,
  SEAL,
  E018_1_PIC
}

// 화면에 있는 state 이름
const imgStateNames = [
  'img_cust',
  'img_seal',
  'img_e018_1_pic',
];

// 업로드 진행바 퍼센트
const imgStatePercentNames = [
  'percentImageCust',
  'percentImageSeal',
  'percentImageE018',
];

// PB 백엔드와 약속한 함수 이름(호출명)
const imgFunctionNames = [
  'tb_cust',
  'tb_seal',
  'tb_e018_1_pic',
];

export interface SpjangState {
  focusedSpjang?: SpjangModel;
  spjangList: Array<SpjangModel>;
  data: SpjangModel;
  lastNewData: SpjangModel,
  img_cust?: ArrayBuffer;
  img_seal?: ArrayBuffer;
  img_e018_1_pic?: ArrayBuffer;
  percentImageCust?: number;
  percentImageSeal?: number;
  percentImageE018?: number;
  searchQuery: string;
  dz_flag: string;
}

/**
 * 컨트롤러
 * @window w_xa012
 * @category 사업자정보등록
 */
export class Spjang extends PageComponent<PageProps, SpjangState>
  implements PageToolEvents {
  updatedRows?: Array<SpjangModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  ImageView: React.RefObject<ImageView> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      data: new SpjangModel(),
      spjangList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.open();
    await this.onRetrieveEvent();
  }

  @action
  async open() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('open',
      {
      });

    this.setState({ dz_flag: data.dz_flag });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          spjangList: [...this.state.spjangList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ spjangList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      spjangList: [],
    });
    if (!autoLoad) return;
    const index = await this.infinity?.retrieveTo('spjangcd', this.state.data.spjangcd, type, true) || 0;
    this.state.spjangList.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new SpjangModel(data, true);
      const buffer = new ArrayBuffer(0);

      this.setState({
        data: newModel,
        spjangList: [...this.state.spjangList, newModel],
        focusedSpjang: newModel,
        img_cust: buffer,
        img_seal: buffer,
        img_e018_1_pic: buffer,
        lastNewData: new SpjangModel(data, true),
      }, async () => {
        this.grid.current?.setFocus(this.state.spjangList.length - 1);
      });
    }
  }

  @action
  async imageRequest(i: number) {
    const { actionStore: api } = this.props;
    const blob = await api.fxBinary(
      `${imgFunctionNames[i]}_retrieve`,
      {
        custcd: this.state.data.custcd,
        spjangcd: this.state.focusedSpjang?.spjangcd,
      },
    );

    // @ts-ignore
    this.setState({
      [imgStateNames[i]]: blob,
    });
  }


  @action
  async imageDelete(i: number) {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      `${imgFunctionNames[i]}_delete`,
      {
        custcd: this.state.data.custcd,
        spjangcd: this.state.focusedSpjang?.spjangcd,
      },
    )) {
      this.imageRequest(i);
      ConfirmSuccess.show('저장', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_save`,
        {
          custcd: publicStore.user.custcd,
          spjangcd: this.state.focusedSpjang?.spjangcd,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        this.imageRequest(i);
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  onRowFocusEvent(item: any) {
    if (item?.isNew) {
      this.setState({
        data: new SpjangModel(),
      });
      return;
    }
    this.setState({
      focusedSpjang: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.exec(
        Category.GENERAL,
        'dw_1_RowFocuschanged',
        item,
      );

      if (data) {
        this.setState({
          data: new SpjangModel({
            ...data,
            e451text: Fix.newline(data.e451text),
            e451remark: Fix.newline(data.e451remark),
            email_bottom: Fix.newline(data.email_bottom),
          }),
        });
        await this.imageRequest(SpjangImages.CUST);
        await this.imageRequest(SpjangImages.SEAL);
        await this.imageRequest(SpjangImages.E018_1_PIC);
      }
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save(this.state.data, false)) {
      await this.onRetrieveEvent();

      // 전역 사업장 리스트 갱신
      this.props.publicStore.retrieveSpjangs();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focusedSpjang?.spjangcd} - ${this.state.focusedSpjang?.spjangnm}`;
    await api.delete(text, this.state.focusedSpjang) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<SpjangModel>, updatedRows: Array<SpjangModel>) {
    this.updatedRows = updatedRows;
    this.setState({ spjangList: rows });
  }

  render() {
    return (
      <SpjangTemplate
        scope={this}
        update={(change, callback) => { // 텍스트 박스 수정
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
