import { TLHF } from '../../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout, TelnumTextBox,
  TextBox,
} from '../../../../../components';
import { ManageStatus } from '../ManageStatus';

const Gubuns = [
  { value: '0', remark: '담당감독' },
  { value: '1', remark: '현장소장' },
  { value: '2', remark: '업체소장' },
  { value: '3', remark: '기타' },
];

/**
 * 화면 > 하단 탭 > 상세내역 > 테이블 - 담당자연락처
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusTableHeaderTelnum: TLHF<any, ManageStatus> = () => ([
  {
    id: 'seq',
    text: '순서',
    width: 8,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 12,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.gubun}
        data={Gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ gubun: option.value })}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자명(거래처명)',
    width: 30,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.pernm}
        onChange={(v) => rowUpdate({ pernm: v })}
      />
    ),
  },
  {
    id: 'telnum',
    text: '전화번호',
    width: 12,
    render: (x, rowUpdate) => (
      <TelnumTextBox
        textAlign="center"
        value={x.telnum}
        onChange={(v) => rowUpdate({ telnum: v })}
      />
    ),
  },
  {
    id: 'faxnum',
    text: '팩스번호',
    width: 12,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.faxnum}
        onChange={(v) => rowUpdate({ faxnum: v })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 20,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
