import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_03
 * @category 년급여현황
 */
export class YearPayModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 년도
   *
   * **data type** varchar(6)
   */
  year: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd : string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 입사일
   *
   * **data type** varchar(8)
   */
  entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  rtdate: string;

  /**
   * 성별
   *
   * **data type** varchar(1)
   */
  sex: string;

  /**
   * 직책코드
   *
   * **data type** varchar(2)
   */
  rspcd: string;

  /**
   * 직책
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 재직자구분
   *
   * **data type** varchar(3)
   */
  rtclafi: string;

  /**
   * 월간연봉급여액02
   *
   * **data type** number
   */
  tot_samt02: string;

  /**
   * 월간연봉급여액03
   *
   * **data type** number
   */
  tot_samt03: string;

  /**
   * 월간연봉급여액04
   *
   * **data type** number
   */
  tot_samt04: string;

  /**
   * 월간연봉급여액05
   *
   * **data type** number
   */
  tot_samt05: string;

  /**
   * 월간연봉급여액06
   *
   * **data type** number
   */
  tot_samt06: string;

  /**
   * 월간연봉급여액07
   *
   * **data type** number
   */
  tot_samt07: string;

  /**
   * 월간연봉급여액08
   *
   * **data type** number
   */
  tot_samt08: string;

  /**
   * 월간연봉급여액09
   *
   * **data type** number
   */
  tot_samt09: string;

  /**
   * 월간연봉급여액10
   *
   * **data type** number
   */
  tot_samt10: string;

  /**
   * 월간연봉급여액11
   *
   * **data type** number
   */
  tot_samt11: string;

  /**
   * 월간연봉급여액12
   *
   * **data type** number
   */
  tot_samt12: string;

  /**
   * 월간연봉급여액01
   *
   * **data type** number
   */
  tot_samt01: string;

  /**
   * 공제금액01
   *
   * **data type** number
   */
  tot_tamt01: string;

  /**
   * 공제금액02
   *
   * **data type** number
   */
  tot_tamt02: string;

  /**
   * 공제금액03
   *
   * **data type** number
   */
  tot_tamt03: string;

  /**
   * 공제금액04
   *
   * **data type** number
   */
  tot_tamt04: string;

  /**
   * 공제금액05
   *
   * **data type** number
   */
  tot_tamt05: string;

  /**
   * 공제금액06
   *
   * **data type** number
   */
  tot_tamt06: string;

  /**
   * 공제금액07
   *
   * **data type** number
   */
  tot_tamt07: string;

  /**
   * 공제금액08
   *
   * **data type** number
   */
  tot_tamt08: string;

  /**
   * 공제금액09
   *
   * **data type** number
   */
  tot_tamt09: string;

  /**
   * 공제금액10
   *
   * **data type** number
   */
  tot_tamt10: string;

  /**
   * 공제금액11
   *
   * **data type** number
   */
  tot_tamt11: string;

  /**
   * 공제금액12
   *
   * **data type** number
   */
  tot_tamt12: string;

  /**
   * 실수령금액01
   *
   * **data type** number
   */
  tot_mamt01: string;

  /**
   * 실수령금액02
   *
   * **data type** number
   */
  tot_mamt02: string;

  /**
   * 실수령금액03
   *
   * **data type** number
   */
  tot_mamt03: string;

  /**
   * 실수령금액04
   *
   * **data type** number
   */
  tot_mamt04: string;

  /**
   * 실수령금액05
   *
   * **data type** number
   */
  tot_mamt05: string;

  /**
   * 실수령금액06
   *
   * **data type** number
   */
  tot_mamt06: string;

  /**
   * 실수령금액07
   *
   * **data type** number
   */
  tot_mamt07: string;

  /**
   * 실수령금액08
   *
   * **data type** number
   */
  tot_mamt08: string;

  /**
   * 실수령금액09
   *
   * **data type** number
   */
  tot_mamt09: string;

  /**
   * 실수령금액10
   *
   * **data type** number
   */
  tot_mamt10: string;

  /**
   * 실수령금액11
   *
   * **data type** number
   */
  tot_mamt11: string;

  /**
   * 실수령금액12
   *
   * **data type** number
   */
  tot_mamt12: string;

  /**
   * 순서
   *
   * **data type** number
   */
  no: string;

  /**
   * 합계
   *
   * **data type** number
   */
  ttot_samt: string;

  /**
   * 합계
   *
   * **data type** number
   */
  ttot_mamt: string;

  /**
   * 합계
   *
   * **data type** number
   */
  ttot_tamt: string;

  /**
   * 평균
   *
   * **data type** number
   */
  atot_samt: string;

  /**
   * 평균
   *
   * **data type** number
   */
  atot_tamt: string;

  /**
   * 평균
   *
   * **data type** number
   */
  atot_mamt: string;

  /**
   * -
   *
   * **data type** number
   */
  mijflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.mijflag = data.mijflag || '';
    this.ttot_samt = data.ttot_samt || '';
    this.ttot_tamt = data.ttot_tamt || '';
    this.atot_samt = data.atot_samt || '';
    this.atot_tamt = data.atot_tamt || '';
    this.atot_mamt = data.atot_mamt || '';
    this.ttot_mamt = data.ttot_mamt || '';
    this.spjangnm = data.spjangnm || '';
    this.year = data.year || '';
    this.no = data.no || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.sex = data.sex || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.rtclafi = data.rtclafi || '';
    this.tot_samt01 = data.tot_samt01 || '';
    this.tot_samt02 = data.tot_samt02 || '';
    this.tot_samt03 = data.tot_samt03 || '';
    this.tot_samt04 = data.tot_samt04 || '';
    this.tot_samt05 = data.tot_samt05 || '';
    this.tot_samt06 = data.tot_samt06 || '';
    this.tot_samt07 = data.tot_samt07 || '';
    this.tot_samt08 = data.tot_samt08 || '';
    this.tot_samt09 = data.tot_samt09 || '';
    this.tot_samt10 = data.tot_samt10 || '';
    this.tot_samt11 = data.tot_samt11 || '';
    this.tot_samt12 = data.tot_samt12 || '';
    this.tot_tamt01 = data.tot_tamt01 || '';
    this.tot_tamt02 = data.tot_tamt02 || '';
    this.tot_tamt03 = data.tot_tamt03 || '';
    this.tot_tamt04 = data.tot_tamt04 || '';
    this.tot_tamt05 = data.tot_tamt05 || '';
    this.tot_tamt06 = data.tot_tamt06 || '';
    this.tot_tamt07 = data.tot_tamt07 || '';
    this.tot_tamt08 = data.tot_tamt08 || '';
    this.tot_tamt09 = data.tot_tamt09 || '';
    this.tot_tamt10 = data.tot_tamt10 || '';
    this.tot_tamt11 = data.tot_tamt11 || '';
    this.tot_tamt12 = data.tot_tamt12 || '';
    this.tot_mamt01 = data.tot_mamt01 || '';
    this.tot_mamt02 = data.tot_mamt02 || '';
    this.tot_mamt03 = data.tot_mamt03 || '';
    this.tot_mamt04 = data.tot_mamt04 || '';
    this.tot_mamt05 = data.tot_mamt05 || '';
    this.tot_mamt06 = data.tot_mamt06 || '';
    this.tot_mamt07 = data.tot_mamt07 || '';
    this.tot_mamt08 = data.tot_mamt08 || '';
    this.tot_mamt09 = data.tot_mamt09 || '';
    this.tot_mamt10 = data.tot_mamt10 || '';
    this.tot_mamt11 = data.tot_mamt11 || '';
    this.tot_mamt12 = data.tot_mamt12 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
