import * as React from 'react';
import { action, computed } from 'mobx';
import { RefObject } from 'react';
import {
  PageProps, PageToolEvents, RetrieveFocusType, RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import DefectModel from './models/DefectModel';
import { DefectTemplate } from './Defect.template';
import { PageComponent } from '../../../../utils';
import { TableLayout } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Date6 } from '../../../../utils/time';

export const Appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];


interface DefectState {
  stmon: string;
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;
  spjangcd: string;
  state: string;
  searchQuery: string;
  searchQueryGrid: string;
  remark: string;

  // trail
  cnt_tot: string;
  cntb_tot: string;
  cntc_tot: string;

  wkactcds: Array<any>;
  evcds: Array<any>;

  data: Array<DefectModel>;
  focusedData: DefectModel;
  dataGrid: Array<DefectModel>;

  isVisibleAttachment: boolean;
  isVisibleGrid: boolean;
  isVisibleDetail: boolean;
  remarkDetailModal: boolean;

  tableFocusItem?: DefectModel;

  printType: string,
  printTypeModal: boolean,
}

/**
 * 컨트롤러
 * @window w_tb_e035w_02
 * @category 자체점검중대결함현황
 */
export class Defect extends PageComponent<PageProps, DefectState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  infinity?: InfinityRetrieve;

  table: RefObject<TableLayout> = React.createRef();

  tableDetail: RefObject<TableLayout> = React.createRef();

  tableUpdates: Array<DefectModel> = [];

  gridFocusIndex: number = -1;

  tableFocusIndex: number = -1;

  tableDetailFocusIndex: number = -1;

  spjangcds: Array<any> = [];


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    this.state = props.state || {
      stmon: Date6.make(),
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      spjangcd: '%',
      state: '%',
      searchQuery: '',
      searchQueryGrid: '',
      cnt_tot: '',
      cntb_tot: '',
      cntc_tot: '',
      remark: '',

      data: [],
      dataGrid: [],
      dataDetail: [],

      isVisibleAttachment: false,
      isVisibleGrid: false,
      isVisibleDetail: false,
      remarkDetailModal: false,

      tableFocusItem: undefined,

      printType: '1', // 출력 type 기본값 1
      printTypeModal: false,
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;

    this.spjangcds = data.items;

    data = await api.dropdown('wf_dd_e018_1');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ wkactcds: data.items });

    // 점검기종 리스트
    data = await api.dropdown('wf_dd_e030');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ evcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon || Date6.make(),
        as_nm: this.state.searchQuery || '',
        perid: this.state.perid || '%',
        divicd: this.state.divicd || '%',
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new DefectModel(item)),
          ],
        });
        await this.table.current?.update(false);
        next && next();
        await this.onRowFocusEvent(this.state.data[0], 0);
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    const index = await this.infinity?.retrieveTo(['rptdate', 'actnm'],
      [this.state.focusedData?.rptdate, this.state.focusedData?.actnm], type, true) || 0;
    const trailData = await this.infinity?.box;

    await this.SS({
      cnt_tot: trailData?.cnt_tot || '0',
      cntb_tot: trailData?.cntb_tot || '0',
      cntc_tot: trailData?.cntc_tot || '0',
    });

    this.tableFocusIndex = -1;
    this.gridFocusIndex = -1;
    this.tableDetailFocusIndex = -1;

    this.tableUpdates = [];
    await this.table.current?.update(true);
    this.table.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const one = new DefectModel(data, true);

      await this.SS({
        data: [one, ...this.state.data],
      });

      await this.table.current?.update(false);
      this.table.current?.setFocus(0);
    }
  }

  /* 저장기능 없는거같음 erp에서는 */
  // @action
  // async onSaveEvent() {
  //   if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
  //     return;
  //   }
  //
  //   const { actionStore: api } = this.props;
  //   if (await api.save({
  //     items: this.tableUpdates,
  //   }, false)) {
  //     this.onRetrieveEvent();
  //   }
  // }

  @action
  async printTypeSelect() {
    const { actionStore: api } = this.props;

    await this.SS({
      printTypeModal: false,
    });

    await api.printWithElmanManager({
      stmon: this.state.stmon,
      actcd: this.state.tableFocusItem?.actcd,
      equpcd: this.state.tableFocusItem?.equpcd,
      as_nm: this.state.searchQuery,
      divicd: this.state.divicd,
      perid: this.state.perid,
      gubun: this.state.printType,
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await this.SS({
      printTypeModal: true,
    });
  }


  @action
  async onEmailButtonClicked() {
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    api.fxEmail(
      '',
      '',
      '',
      '',
      '',
      '',
      {
        stmon: this.state.stmon,
        actcd: this.tableFocus?.actcd,
        equpcd: this.tableFocus?.equpcd,
      },
    );
  }


  // Main
  getGubunnm(gubuncd: string) {
    const f = Appgubuns.filter((x) => x.value === gubuncd);
    return f.length ? f[0].remark : '';
  }

  @action
  async onRowFocusEvent(item: DefectModel | undefined, index: number) {
    if (!item) {
      this.tableFocusIndex = 0;
      await this.SS({ tableFocusItem: undefined });
      return;
    }

    this.tableFocusIndex = index;
    await this.SS({ tableFocusItem: item });
  }

  @action
  onTableChange(rows: Array<DefectModel>, updates: Array<DefectModel>) {
    this.tableUpdates = updates;
    this.SS({ data: rows });
  }

  @action
  remarkModal(isOpen: boolean) {
    this.setState({ remarkDetailModal: isOpen, remark: String(this.state.tableFocusItem?.remark) });
  }

  @action
  async remarkModalsave() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_e035w_02_remark',
        plandate: this.state.tableFocusItem?.plandate,
        actcd: this.state.tableFocusItem?.actcd,
        equpcd: this.state.tableFocusItem?.equpcd,
        remark: this.state.remark,
      });

    if (data) {
      this.setState({ remarkDetailModal: false });
      await this.onRetrieveEvent();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getGridRowBackgroundColor(item: any): string {
    if (item.gubun === '1') {
      return 'var(--color-secondary)';
    }

    return '';
  }

  @action
  async onTableItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      ...this.tableFocus,
      [key]: value,
      itemname: key,
      data: value,
    }, this.tableFocus?.isNew);

    if (result) {
      rowUpdate(result);
    }
  }

  // Detail
  @action
  async onDetailRowFocusEvent(item: DefectModel | undefined, index: number) {
    if (!item) {
      this.tableFocusIndex = 0;
      return;
    }

    this.tableFocusIndex = index;
  }

  @action
  async listItemChanged(x: DefectModel, rowUpdate: RowUpdate, name: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      itemname: name,
      data: value,
      plandate: this.state.tableFocusItem?.plandate,
      actcd: this.state.tableFocusItem?.actcd,
      equpcd: this.state.tableFocusItem?.equpcd,
      resultcd: this.state.tableFocusItem?.resultcd,
      feedback: name === 'feedback' ? value : this.state.tableFocusItem?.feedback,
      feedback_remark: name === 'feedback_remark' ? value : this.state.tableFocusItem?.feedback_remark,
      remark: this.state.tableFocusItem?.remark,
    });
    rowUpdate(new DefectModel({
      ...x,
      ...result,
    }, x.isNew));
  }


  @computed
  get gridFocus(): DefectModel | undefined {
    if (this.state.dataGrid.length < this.gridFocusIndex) return undefined;
    return this.state.dataGrid[this.gridFocusIndex];
  }

  @computed
  get tableFocus(): DefectModel | undefined {
    if (this.state.data.length < this.tableFocusIndex) return undefined;
    return this.state.data[this.tableFocusIndex];
  }

  @action
  async onUpdatedRows(rows: Array<DefectModel>, updatedRows: Array<DefectModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  // @action
  // onUpdatedRows(rows: Array<DefectModel>, updatedRows: Array<DefectModel>) {
  //   this.tableUpdates = updatedRows;
  //   this.setState({ data: rows });
  // }


  render() {
    return <DefectTemplate
      scope={this}
    />;
  }
}
