import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentHistoryGridHeader } from '../headers/EnrollmentHistoryGridHeader';

/**
 * 화면 > 탭 - 견적이력정보
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentTabHistoryTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={EnrollmentHistoryGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataHistory}
    />
  </FlexLayout>
);
