import { TLHF } from '../../../../../constants';
import {
  ComboBox, ComboBoxModel, FlexLayout, FormatTextBox, TextBox,
} from '../../../../../components';
import { Plan } from '../Plan';
import { SearchBinding } from '../../../../../models';
import PlanEqupModel from '../models/PlanEqupModel';

/**
 * 화면 > 팝업 - 호기 테이블 레이아웃
 * @window w_tb_e470
 * @category 공사계획등록
 */
export const PlanEqupTableHeader: TLHF<PlanEqupModel, Plan> = (scope) => ([
  {
    id: 'state',
    text: '상태',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.state === '1' ? '계획' : '완료'}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '코드',
    width: 60,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.equpcd}
        onChange={(v) => rowUpdate({ equpcd: v })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: x.actcd },
          true,
          (item) => {
            if (!scope.isDupEqup(item.cd)) {
              rowUpdate({
                equpcd: item.cd,
                equpnm: item.cdnm,
              });
            }
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 90,
  },
  {
    id: 'plantime',
    text: '시간',
    width: 90,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        format="##:##"
        textAlign="center"
        value={x.plantime}
        onChange={(v) => rowUpdate({ plantime: v })}
      />
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.gubun}
        data={scope.state.gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => { rowUpdate({ gubun: option.value }); }}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 90,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
