import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Individual } from './Individual';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_da051
 * @category 회사->개인목표계획등록
 */
export const IndividualTemplate: React.FC<TemplateProps<Individual>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onCopyBtn()}>
            <div style={{ width: 80 }}>전체복사</div>
          </Button>

          <Button onClick={() => scope.onEndBtn()}>
            <div style={{ width: 80 }}>마감</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'planmon',
                text: '계획월',
                width: 7,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##"
                    value={x.planmon}
                  />
                ),
              },
              {
                id: 'besamt',
                text: '전년도실적',
                width: 13,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.besamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.besamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'samt',
                text: '당해년도계획',
                width: 13,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.samt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'addsamt',
                text: '변동금액',
                width: 13,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.addsamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.addsamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'addyul',
                text: '변동율',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.addyul} %
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 67,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'gubunnm',
                text: '매출구분',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.gubunnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'besamt',
                text: '전년도실적',
                width: 30,
                render: (x, rowUpdate, ref) => (
                  <FormatNumberTextBox
                    ref={ref}
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.besamt}
                    onChange={(value) => rowUpdate({ besamt: value })}
                  />
                ),
              },
              {
                id: 'samt',
                text: '매출목표',
                width: 30,
                render: (x, rowUpdate, ref) => (
                  <FormatNumberTextBox
                    ref={ref}
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.samt}
                    onChange={(value) => rowUpdate({ samt: value })}
                  />
                ),
              },
              {
                id: 'addyul',
                text: '증감액',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.addyul)}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.dataDetail}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows2, updatedRows2) => {
              scope.onUpdatedRows(rows2, updatedRows2);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
