import * as React from 'react';
import { action } from 'mobx';
import printJS from 'print-js';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ProductCodeTemplate } from './ProductCode.template';
import { ProductCodeModel } from './ProductCode.model';
import { InfinityRetrieve } from '../../../../models/common';
import {
  ConfirmDelete,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  ProductBigModel,
  ProductChoiceAllModel,
  ProductMiddleModel,
  ProductSmallModel,
  ProductTypeModel,
} from '../ProductAccount/ProductAccount.model';
import {
  FileReader,
  FileSelector,
} from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils';

export enum ProductCodeItemChangeTypes {
  WKACTCD,
  CHOICE,
  PHM_PCOD,
  AGE_YEAR,
  AGE_MONTH,
}

export const ProductCodeItemChangeTypeNames = [
  'wkactcd',
  'choice',
  'phm_pcod',
  'age_year',
  'age_month',
];

export enum ProductCodeImages {
  CUST,
  SEAL,
}

const imgFunctionNames = [
  'tb_pic',
  'tb_pic2',
];

const imgStateNames = [
  'img_cust',
  'img_seal',
];

const imgStatePercentNames = [
  'percentImageCust',
  'percentImageSeal',
];

interface ProductCodeState {
  img_cust?: ArrayBuffer;
  img_seal?: ArrayBuffer;
  searchQuery: string;
  searchQuery2: string;
  productCodeList: Array<ProductCodeModel>;
  manucds?: Array<any>;
  choices?: Array<any>;
  choices2?: Array<any>;
  phm_modes?: Array<any>;
  sulchis?: Array<any>;
  agrbs?: Array<any>;
  bgrbs?: Array<any>;
  cgrbs?: Array<any>;
  store?: Array<any>;
  data: ProductCodeModel;
  lastNewData: ProductCodeModel;
  lastNew: boolean;
  percentImageCust: number;
  percentImageSeal: number;
  searchWkactcd: string;
  searchChoice: string;
  searchPhm_mode: string;
  searchAgrb: string;
  searchBgrb: string;
  searchCgrb: string;
  sulchi: string;
  open: boolean;
  // 선택을 잠그기위한 변수
  firstOpen: boolean;

  // 조회조건
  cltcd: string;
  cltnm: string;
  wkactcds: Array<any>;
  mtyn: string;

}

/**
 * 컨트롤러
 * @window w_tb_ca501_01
 * @category 제품코드등록
 */
export class ProductCode extends PageComponent<PageProps, ProductCodeState>
  implements PageToolEvents {
  updatedRows?: Array<ProductCodeModel>;

  infinity?: InfinityRetrieve;

  table: React.RefObject<GridLayout> = React.createRef();

  itemname: string;

  focusedIndex: number = 0;

  lastNew: boolean = false;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      productCodeList: [],
      data: new ProductCodeModel(),
      lastNewData: [],
      lastNew: false,
      searchQuery: '',
      searchQuery2: '',
      searchWkactcd: '001',
      searchChoice: '%',
      searchPhm_mode: '%',
      searchAgrb: '%',
      searchBgrb: '%',
      searchCgrb: '%',
      sulchi: '%',

      // 조회조건
      cltcd: '',
      cltnm: '',
      wkactcds: [],
      mtyn: '%',

    };
    this.itemname = '';
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    [
      async () => {
        // 선택
        const data = await api.dropdown('wf_dd_ca503_03_choice_all');
        this.setState({ firstOpen: true, choices: data?.items.map((x: any) => new ProductChoiceAllModel(x)) });
      },
      async () => {
        // 선택2
        const data = await api.dropdown('wf_dd_ca503_03_choice');
        this.setState({ choices2: data?.items });
      },
      async () => {
        // 품목분류
        const data = await api.dropdown('wf_dd_ca541_02');
        this.setState({ phm_modes: data?.items.map((x: any) => new ProductTypeModel(x)) });
      },
      async () => {
        // 대분류
        const data = await api.dropdown('wf_dd_ca503_01_02', { mode: this.state.searchPhm_mode });
        this.setState({ agrbs: data?.items.map((x: any) => new ProductBigModel(x)) });
      },
      async () => {
        // 중분류
        const data = await api.dropdown('wf_dd_ca503_02_02', { pgr_hcod: this.state.searchAgrb });
        this.setState({ bgrbs: data?.items.map((x: any) => new ProductMiddleModel(x)) });
      },
      async () => {
        // 소분류
        const data = await api.dropdown('wf_dd_ca503_03_02',
          {
            pgr_hcod: this.state.searchAgrb,
            bgroup: this.state.searchBgrb,
          });
        this.setState({ cgrbs: data?.items.map((x: any) => new ProductSmallModel(x)) });
      },
      async () => {
        const data = await api.dropdown('wf_dd_ca504_01');
        this.setState({ store: data?.items.map((x: any) => new ProductCodeModel(x)) });
      },
      async () => {
        // 제작사
        const data = await api.dropdown('wf_dd_e001_01');
        this.setState({ manucds: data?.items });
      },
      async () => {
        // 회사 구분
        const data = await api.dropdown('wf_dd_e018_1');
        this.setState({ wkactcds: [{ wkactcd: '%', wkactnm: '전체' }, ...(data?.items)] });
      },
    ].forEach((x) => x());

    await this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        wkactcd: this.state.searchWkactcd,
        as_nm: this.state.searchQuery,
        psize: this.state.searchQuery2,
        choice: this.state.searchChoice,
        phm_mode: this.state.searchPhm_mode,
        agroup: this.state.searchAgrb,
        bgroup: this.state.searchBgrb,
        cgroup: this.state.searchCgrb,
        sulchi: this.state.sulchi,
        cltcd: this.state.cltcd,
        mtyn: this.state.mtyn,
      },
      (params) => api.retrieve(params, true),
      (items, next) => {
        this.setState({
          productCodeList: [...this.state.productCodeList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          productCodeList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.productCodeList.length) this.table.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      productCodeList: [],
      data: new ProductCodeModel(),
      img_cust: new ArrayBuffer(0),
      img_seal: new ArrayBuffer(0),
    });

    if (!autoLoad) return;

    let index = 0;
    if (this.lastNew) {
      index = await this.infinity?.retrieveTo('phm_pcod', this.state.lastNewData.phm_pcod, type, true) || 0;
      this.state.productCodeList.length > index && this.table.current?.setFocus(index);
      this.lastNew = false;
    } else {
      await this.infinity?.retrieveAll();
      this.state.productCodeList.length > 0 && this.table.current?.setFocus(this.focusedIndex);
    }

    await this.table.current?.forceRepaint();
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한 번에 한 행만 추가 할 수 있습니다.');
      return;
    }

    this.setState({
      img_cust: new ArrayBuffer(0),
      img_seal: new ArrayBuffer(0),
    });

    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const data = await api.new({
      wkactcd: '001',
    });

    this.setState({ open: true });
    this.setState({ firstOpen: false });

    if (data) {
      this.lastNew = true;
      this.setState({
        productCodeList: [...this.state.productCodeList, new ProductCodeModel(data, true)],
        lastNewData: new ProductCodeModel(data, true),
      }, () => {
        requestAnimationFrame(() => {
          this.table.current?.setFocus(this.state.productCodeList.length - 1);
        });
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.phm_pnam.trim() === '') {
      ConfirmWarning.show('경고', '제품명을 입력하십시요');
      return;
    }

    if (this.state.data.storenm.trim() === '') {
      ConfirmWarning.show('경고', '보관창고를 더블클릭하여 입력해 주세요');
      return;
    }

    if (this.state.data.isNew) {
      this.setState({
        lastNewData: this.state.data,
      });
    }

    if (await api.save(this.state.data, this.state.data.isNew)) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `품목코드: ${this.state.data?.phm_pcod} 품목명:${this.state.data?.phm_pnam}`;
    await api.delete(text, this.state.data) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      wkactcd: this.state.searchWkactcd,
      psize: this.state.searchQuery2,
      phm_mode: this.state.searchPhm_mode,
      agroup: this.state.searchAgrb,
      bgroup: this.state.searchBgrb,
      cgroup: this.state.searchCgrb,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    await api.excel({
      as_nm: this.state.searchQuery,
      wkactcd: this.state.searchWkactcd,
      psize: this.state.searchQuery2,
      phm_mode: this.state.searchPhm_mode,
      agroup: this.state.searchAgrb,
      bgroup: this.state.searchBgrb,
      cgroup: this.state.searchCgrb,
    });
  }

  @action
  async onRowFocusEvent(item: ProductCodeModel, index: number) {
    const { actionStore: api } = this.props;

    this.focusedIndex = index;

    if (item?.new === '1') {
      this.setState({
        data: this.state.lastNewData,
      });
      return;
    }

    const data = await api.fxExec('dw_1_RowFocuschanged',
      {
        wkactcd: item?.wkactcd,
        phm_pcod: item?.phm_pcod,
        sulchi: item?.sulchi,
      });

    if (data) {
      this.setState({
        data: new ProductCodeModel(data, false),
      });
    }
    this.imageRequest(ProductCodeImages.CUST);
    this.imageRequest(ProductCodeImages.SEAL);
  }

  async imageRequest(i: number) {
    const { publicStore, actionStore: api } = this.props;

    const blob = await api.fxBinary(
      `${imgFunctionNames[i]}_retrieve`,
      {
        spjangcd: publicStore.user.spjangcd,
        wkactcd: this.state.data.wkactcd,
        phm_pcod: this.state.data.phm_pcod,
      },
    );

    // @ts-ignore
    this.setState({
      [imgStateNames[i]]: blob,
    });
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async onPrintEventImg(i: number) {
    try {
      printJS({
        // @ts-ignore
        printable: this.state[imgStateNames[i]],
        type: 'image',
        base64: true,
        showModal: true,
      });
    } catch {
      ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
    }
  }

  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_save`,
        {
          custcd: publicStore.user.custcd,
          wkactcd: this.state.data?.wkactcd,
          phm_pcod: this.state.data?.phm_pcod,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        this.imageRequest(i);
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  async imageDelete(i: number) {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '저장 후에 이미지 삭제가 가능합니다.');
      return;
    }

    const { publicStore, actionStore: api } = this.props;
    const text = '삭제 후에 되돌릴수 없습니다.';
    if (await ConfirmDelete.show(text)) {
      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_delete`,
        {
          custcd: publicStore.user.custcd,
          wkactcd: this.state.data?.wkactcd,
          phm_pcod: this.state.data?.phm_pcod,
        },
      )) {
        this.imageRequest(i);
        ConfirmSuccess.show('삭제', '삭제하였습니다.');
      }
    }
  }


  @action
  async imageSelect(i: number) {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '저장 후에 이미지 등록이 가능합니다.');
      return;
    }

    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async itemChanged(item: any, type: number) {
    const { actionStore: api } = this.props;
    let data;

    if (ProductCodeItemChangeTypeNames[type] === 'choice') {
      data = await api.fxExec('dw_2_itemchanged', {
        itemname: ProductCodeItemChangeTypeNames[type],
        data: item,
        // data: new ProductCodeModel(item, this.state.data.isNew),
        wkactcd: this.state.data.wkactcd,
      });
    } else if (ProductCodeItemChangeTypeNames[type] === 'wkactcd') {
      data = await api.fxExec('dw_2_itemchanged', {
        itemname: ProductCodeItemChangeTypeNames[type],
        data: item,
      });
    } else if (ProductCodeItemChangeTypeNames[type] === 'phm_pcod') {
      data = await api.fxExec('dw_2_itemchanged', {
        itemname: ProductCodeItemChangeTypeNames[type],
        data: item,
        wkactcd: this.state.data.wkactcd,
      });
    } else if (ProductCodeItemChangeTypeNames[type] === 'age_year' || ProductCodeItemChangeTypeNames[type] === 'age_month') {
      data = await api.fxExec('dw_2_itemchanged', {
        itemname: ProductCodeItemChangeTypeNames[type],
        data: item,
        age_year: this.state.data.age_year,
        age_month: this.state.data.age_month,
      });
    }


    data && this.setState({
      data: new ProductCodeModel({
        ...this.state.data,
        ...data,
      }, true),
    });
  }

  // 신규 저장 후 검색할 때 이전focusIndex가 남아있어 강제로 0으로 가도록 하기위한 함수
  async initFocusIndex() {
    this.focusedIndex = 0;
  }

  render() {
    return (
      <ProductCodeTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new ProductCodeModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
