import * as React from 'react';
import { MdAddCircle, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TabLayout, TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Report } from './Report';
import { ReportTab } from './Report.tab';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { ReportGridHeader } from './headers/ReportGridHeader';
import { SearchBinding, SearchBindingProjects } from '../../../../models';
import ReportModel from './models/ReportModel';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { AttachmentBox } from './components/AttachmentBox';

/**
 * 화면
 * @window w_tb_b035
 * @category 공정일보
 */
export const ReportTemplate: React.FC<TemplateProps<Report>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);
  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new ReportModel({
      ...content,
      ...data,
    }, content?.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        maxWidth={250}
      >
        <FlexLayout>
          <span>월</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ yearmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout
          size={372}
        >
          <FlexLayout>
            <GridLayout
              ref={scope.grid}
              header={ReportGridHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.data}
              infinityHandler={scope.infinity}
              onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
            />
          </FlexLayout>

          <FlexLayout
            isVertical={true}
            weight={2}
            size={372}
            isScroll={true}
            disabled={!content?.custcd}
            disabledText="항목을 선택하거나 신규로 추가해주세요"
          >
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="작성일자"
                isNeed={true}
                size={166}
              >
                <DateTextBox
                  value={content?.ctdate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => setContent({ ctdate: value })}
                  readonly={!content?.isNew}
                />
              </LabelBox>

              <LabelBox
                title="책임자"
                style={{
                  color: 'var(--color-red)',
                }}
                footer={
                  <FlexLayout
                    align="center"
                    justify="end"
                    style={{
                      color: 'var(--color-blue)',
                    }}
                  >
                    직영
                  </FlexLayout>
                }
              >
                <TextBox
                  value={content?.perid}
                  size={150}
                  textAlign="center"
                  onChange={(value) => setContent({ perid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      setContent({
                        perid: item.cd,
                        pernm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  value={content?.pernm}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="작업자"
                weight={2}
                footer={
                  <Button
                    isIcon={true}
                    onClick={() => scope.modifyWorkers()}
                  >
                    <MdAddCircle size={24}/>
                  </Button>
                }
              >
                <TextBox
                  value={content?.dperid}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="프로젝트"
                style={{
                  color: 'var(--color-red)',
                }}
              >
                <TextBox
                  size={150}
                  textAlign="center"
                  value={content?.projno}
                  onChange={(value) => setContent({ projno: value })}
                  bindSearchModal={new SearchBindingProjects(
                    scope.props.modalStore,
                    {},
                    true,
                    (item) => {
                      setContent({
                        projno: item.projno,
                        projnm: item.projnm,
                      }, () => scope.headerItemChanged('projno', item.projno));
                    },
                  )}
                />

                <TextBox
                  weight={2}
                  value={content?.projnm}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="현장"
                style={{
                  color: 'var(--color-red)',
                }}
              >
                <TextBox
                  value={content?.actcd}
                  size={150}
                  textAlign="center"
                  onChange={(value) => setContent({ actcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E601_1',
                    {},
                    true,
                    (item) => {
                      setContent({
                        actcd: item.cd,
                        actnm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  weight={2}
                  value={content?.actnm}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
              <LabelBox title="안전지시사항">
                <TextBox
                  value={content?.saferemark}
                  onChange={(v) => setContent({ saferemark: v })}
                  isMultiline={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={100}>
              <LabelBox
                title="특이사항"
                weight={2}
              >
                <TextBox
                  value={content?.remark}
                  onChange={(v) => setContent({ remark: v })}
                  isMultiline={true}
                />
              </LabelBox>

              <LabelBox
                title="공사(첨부)사진"
                footer={
                  <Button
                    isIcon={true}
                    onClick={() => scope.SS({ isVisibleAttachment: true })}
                  >
                    <MdAddCircle size={24}/>
                  </Button>
                }
              >
                <TextBox
                  textAlign="center"
                  color={content?.attcnt === '0' ? 'inherit' : 'var(--color-blue)'}
                  value={`첨부파일 ${content?.attcnt === '0' ? '없음' : '있음'} ${content?.attcnt}건`}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout
          disabled={!content?.custcd}
          disabledText="항목을 선택하거나 신규로 추가해주세요"
        >
          <TabLayout
            scope={scope}
            tabs={ReportTab}
            onChange={() => {}}
            footer={
              <FlexLayout
                justify="right"
              >
                <Button
                  onClick={() => scope.onNewDetailEvent()}
                >
                  추가
                </Button>
                <Button
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.onDeleteDetailEvent()}
                >
                  삭제
                </Button>
              </FlexLayout>
            }
          />
        </FlexLayout>
      </FlexLayout>


      {/* 첨부파일 */}
      <ModalView
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        width={1300}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            perid={content?.perid}
            ctdate={content?.ctdate}
            mpclafi={content?.mpclafi}
            isEnabledTitle={true}
            isEnabledPreview={true}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 작업자 */}
      <ModalView
        width={365}
        height={540}
        isVisible={scope.state.isVisibleWorkers}
        onClose={() => setData({ isVisibleWorkers: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            style={{
              marginBottom: 4,
            }}
          >
            <LayoutTitle weight={1}>
              {Date8.withDash(scope.state.content.ctdate)} 작업자
            </LayoutTitle>

            <Button onClick={() => scope.addWorker()}>
              <span>추가</span>
            </Button>

            <Button onClick={() => scope.removeWorker()}>
              <span>삭제</span>
            </Button>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <TableLayout
              ref={scope.tableWorker}
              header={[
                {
                  id: 'wperid',
                  text: '작업자',
                  width: 50,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.wperid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ wperid: value })}
                      bindSearchModal={new SearchBinding(
                        // @ts-ignore
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            wperid: item.cd,
                            wpernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'wpernm',
                  text: '작업자명',
                  width: 50,
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.workers}
              onChange={(rows) => setData({ workers: rows })}
              onRowFocusEvent={(_, i) => setData({ workerSelectedIndex: i })}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.saveWorkers()}
            >
              저장
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
