/**
 * 모델
 * @window w_tb_e451w
 * @category 견적현황
 */
export default class StatusModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 발주일
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 발주상태
   *
   * **data type** varchar(8)
   */
  readonly balflag: string;

  /**
   * 발주번호
   *
   * **data type** varchar(8)
   */
  readonly balnum: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  /**
   * 발주구분
   *
   * **data type** varchar(8)
   */
  readonly billkind: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 완료구분
   *
   * **data type** varchar(8)
   */
  readonly compyn: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly costamt: string;

  /**
   * 견적일
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 견적자
   *
   * **data type** varchar(8)
   */
  readonly costperid: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly janamt: string;

  /**
   * 지로구분
   *
   * **data type** varchar(8)
   */
  readonly jirogubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ks1flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ks2flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ks3flag: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly misamt: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 자재청구일
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   * 자재청구상태
   *
   * **data type** varchar(8)
   */
  readonly pumflag: string;

  /**
   * 자재청구번호
   *
   * **data type** varchar(8)
   */
  readonly pumnum: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 팀장여부
   *
   * **data type** varchar(8)
   */
  readonly reader: string;

  /**
   * 매출여부
   *
   * **data type** varchar(8)
   */
  readonly receflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly recivstatus: string;

  /**
   * 참조자
   *
   * **data type** varchar(8)
   */
  readonly refnm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s1flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s2flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s3flag: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태코드
   *
   * **data type** varchar(8)
   */
  readonly statecd: string;

  /**
   * 특기사항
   *
   * **data type** varchar(200)
   */
  remark: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actnm = data.actnm || '';
    this.remark = data.remark || '';
    this.baldate = data.baldate || '';
    this.balflag = data.balflag || '';
    this.balnum = data.balnum || '';
    this.bigo = data.bigo || '';
    this.billkind = data.billkind || '';
    this.cltnm = data.cltnm || '';
    this.compyn = data.compyn || '';
    this.constnm = data.constnm || '';
    this.costamt = data.costamt || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.costperid = data.costperid || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.gubun = data.gubun || '';
    this.janamt = data.janamt || '';
    this.jirogubun = data.jirogubun || '';
    this.ks1flag = data.ks1flag || '';
    this.ks2flag = data.ks2flag || '';
    this.ks3flag = data.ks3flag || '';
    this.misamt = data.misamt || '';
    this.pernm = data.pernm || '';
    this.pumdate = data.pumdate || '';
    this.pumflag = data.pumflag || '';
    this.pumnum = data.pumnum || '';
    this.rcvamt = data.rcvamt || '';
    this.reader = data.reader || '';
    this.receflag = data.receflag || '';
    this.recivstatus = data.recivstatus || '';
    this.refnm = data.refnm || '';
    this.s1flag = data.s1flag || '';
    this.s2flag = data.s2flag || '';
    this.s3flag = data.s3flag || '';
    this.spjangcd = data.spjangcd || '';
    this.statecd = data.statecd || '';
  }
}
