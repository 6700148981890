import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { GoalTemplate } from './Goal.template';
import { GoalModel } from './Goal.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { HistoryPopupModel } from '../../../maintenance/management/MonthContract/HistoryPopup.model';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface GoalState {
  year: string;
  mon: string;
  bungi: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // data
  data: GoalModel;
  goalList: Array<GoalModel>;
  focused?: GoalModel;

  // modal
  modalState: boolean;
  modalData: Array<GoalModel>;
  modalTitle: string;

  // trail
  cnt_tot: string;
  flag0_tot: string;
  flag1_tot: string;
  planamt_tot: string;
  misamt_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_08
 * @category 목표대비계획및실적
 */
export class Goal extends PageComponent<PageProps, GoalState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalTitle2: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  updatedRows?: Array<HistoryPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      mon: '01',
      bungi: '%',
      divicd: '',
      perid: '',
      goalList: [],

      // modal
      modalData: [],
      modalTitle: '',

      // trail
      cnt_tot: '0',
      flag0_tot: '0',
      flag1_tot: '0',
      planamt_tot: '0',
      misamt_tot: '0',
      total: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        bungi: this.state.bungi,
        divicd: this.state.divicd,
        perid: this.state.perid,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          goalList: [
            ...this.state.goalList,
            ...items.map((x: any) => new GoalModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          goalList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.goalList && this.state.goalList?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      goalList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.grid.current?.setFocus(0);
      await this.SS({
        total: this.infinity?.box?.total,
      });
      // await this.onRetrieveEvent2();
    });
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve(
      {
        year: this.state.year,
        bungi: this.state.bungi,
        divicd: this.state.divicd,
        perid: this.state.perid,
        mon: this.state.mon,
      },
    );

    if (data) {
      this.setState({
        data,
      });
    }
  }

  @action
  onRowFocusEvent(item: GoalModel) {
    this.setState({ focused: item });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.goalList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      bungi: this.state.bungi,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.goalList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel('excel',
      {
        year: this.state.year,
        bungi: this.state.bungi,
        divicd: this.state.divicd,
        perid: this.state.perid,
      });
  }

  @action
  onModalEvent(isOpen: boolean, item: string) {
    this.setState({ modalState: isOpen });

    isOpen === true && this.onMondalRetrive(item);
  }

  @action
  async onMondalRetrive(item: string) {
    const { actionStore: api } = this.props;

    let stdates;
    let enddates;
    let modalTitles;

    if (item === '11') {
      modalTitles = '유지보수 내역';
    } else if (item === '12') {
      modalTitles = '수리공사 내역';
    } else if (item === '13') {
      modalTitles = '부품교체 내역';
    } else if (item === '14') {
      modalTitles = '리모델링 내역';
    }

    switch (this.state.bungi) {
      case '%':
        stdates = `${this.state.year}01`;
        enddates = `${this.state.year}12`;
        modalTitles = `${modalTitles}[전체]`;
        break;

      case '1':
        stdates = `${this.state.year}01`;
        enddates = `${this.state.year}03`;
        modalTitles = `${modalTitles}[1분기]`;
        break;

      case '2':
        stdates = `${this.state.year}04`;
        enddates = `${this.state.year}06`;
        modalTitles = `${modalTitles}[2분기]`;
        break;

      case '3':
        stdates = `${this.state.year}07`;
        enddates = `${this.state.year}09`;
        modalTitles = `${modalTitles}[3분기]`;
        break;

      case '4':
        stdates = `${this.state.year}10`;
        enddates = `${this.state.year}12`;
        modalTitles = `${modalTitles}[4분기]`;
        break;

      case '5':
        stdates = `${this.state.year}${this.state.mon}`;
        enddates = `${this.state.year}${this.state.mon}`;
        modalTitles = `${modalTitles}[${this.state.year}년${this.state.mon}월]`;
        break;
    }

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_da052w_08',
        stdate: stdates,
        enddate: enddates,
        perid: this.state.focused?.perid,
        gubun: item,
      },
    );
    this.setState({
      cnt_tot: data?.cnt_tot || '0',
      flag0_tot: data?.flag0_tot || '0',
      flag1_tot: data?.flag1_tot || '0',
      planamt_tot: data?.planamt_tot || '0',
      misamt_tot: data?.misamt_tot || '0',
      modalTitle: modalTitles || '',
    });
    if (data?.items) {
      this.setState({
        modalData: data.items || [],
      });
    } else {
      this.setState({
        modalData: [],
      });
    }
  }

  render() {
    return (
      <GoalTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
