import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { IoIosMail } from 'react-icons/all';
import {
  Badge, Button, CheckBox, DateTextBox, FlexLayout, GridLayout, SearchLayout, TableLayout, TabView,
} from '../../../../components';
import {
  OneDayDeadline,
  OneDayDeadlineTabDataStateNames,
} from './OneDayDeadline';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

/**
 * 화면
 * @window w_tb_ca642w_11
 * @category 일일마감
 */
export const OneDayDeadlineTemplate: React.FC<TemplateProps<OneDayDeadline>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>월</span>
              <DateTextBox
                value={scope.state.yearmon}
                textAlign="center"
                format="####년 ##월"
                onChange={(value) => {
                  setData({ yearmon: value },
                    () => scope.onRetrieveEvent());
                }}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onReport()}>
              <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'spjangnm',
                text: '사업자명',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.spjangnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.openChk}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>

        <FlexLayout weight={4.5}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'appgubun',
                text: '결재상태',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    {x?.appremark && <Badge>
                      <IoIosMail color="var(--color-red)" size={25} />
                    </Badge>}
                  </FlexLayout>
                ),
              },
              {
                id: 'today',
                text: '일자',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.today)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전일잔액',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '수입',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.rcvamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'sndamt',
                text: '지출',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.sndamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.sndamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'to_amt',
                text: '금일잔액',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.to_amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'to_bamt',
                text: '예금잔액',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.to_bamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'to_hamt',
                text: '현금잔액',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.to_hamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'to_gamt',
                text: '기타잔액',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.to_gamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'appnum',
                text: '문서번호',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.appnum}
                  </FlexLayout>
                ),
              },
            ]}
             data={scope.state.data}
             infinityHandler={scope.infinity}
             rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
             onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout></FlexLayout>
        <FlexLayout
          isVertical={true}
          weight={4.5}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>
          <FlexLayout weight={1.5}>
            <GridLayout
              ref={scope.grid2}
              scope={scope}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity2}
              // @ts-ignore
              data={scope.state[OneDayDeadlineTabDataStateNames[scope.tabIndex]] || [] }
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
