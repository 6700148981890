import * as React from 'react';
import { action } from 'mobx';
import { Category, PageProps, PageToolEvents } from '../../../../constants';
import { CustTemplate } from './Cust.template';
import { CustModel } from './Cust.model';
import { FileReader, FileSelector } from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { TableLayout } from '../../../../components';
import {
  ConfirmDelete,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { Fix } from '../../../../utils/string';

export enum CustImages {
  CUST,
  SEAL,
  E018_1_PIC
}

export enum BoardTimeTypes {
  TIME,
  LOOP,
}

export const BoardWindowTypeNames = {
  dash: '고장현황',
  event: '아침조회',
  chk: '점검현황',
  plantoday: '금일계획',
  calendar: '달력(출장, 공사)',
};

// PB 백엔드와 약속한 함수 이름(호출명)
const imgFunctionNames = [
  'tb_cust',
  'tb_seal',
  'tb_e018_1_pic',
];

// 화면에 있는 state 이름
const imgStateNames = [
  'img_cust',
  'img_seal',
  'img_e018_1_pic',
];

// 업로드 진행바 퍼센트
const imgStatePercentNames = [
  'percentImageCust',
  'percentImageSeal',
  'percentImageE018',
];

interface CustState {
  data: CustModel;
  img_cust?: ArrayBuffer;
  img_seal?: ArrayBuffer;
  img_e018_1_pic?: ArrayBuffer;
  modal: boolean;
  timeList: Array<any>;
  loopList: Array<any>;
  focusedTime?: any;
  focusedLoop?: any;
  percentImageCust?: number;
  percentImageSeal?: number;
  percentImageE018?: number;
}

/**
 * 컨트롤러
 * @window w_xa011_input
 * @category 회사정보등록
 */
export class Cust extends PageComponent<PageProps, CustState>
  implements PageToolEvents {
  storedData?: CustState;

  updatedTimeRows?: Array<any>;

  updatedLoopRows?: Array<any>;

  tableTime: React.RefObject<TableLayout>;

  tableLoop: React.RefObject<TableLayout>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      timeList: [],
      loopList: [],
      data: new CustModel(),
      modal: false,
    };

    this.tableTime = React.createRef();
    this.tableLoop = React.createRef();
  }

  @action
  onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  /**
   * 상단 [조회] 버튼 이벤트 핸들러
   *
   * @brief 서버 요청에 대한 기본 예시 코드
   */
  @action
  async onRetrieveEvent() {
    // 서버에 데이터 조회를 요청
    const { actionStore: api } = this.props;
    const data = await api.retrieve();

    // 성공시 상태를 반영하고, 다른 작업을 계속한다
    if (data) {
      this.setState({
        data: new CustModel({
          ...data,
          e451text: Fix.newline(data.e451text),
          e451remark: Fix.newline(data.e451remark),
          email_bottom: Fix.newline(data.email_bottom),
        }),
      });
      await this.imageRequest(CustImages.CUST);
      await this.imageRequest(CustImages.SEAL);
      await this.imageRequest(CustImages.E018_1_PIC);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save(this.state.data, false);
  }

  async imageRequest(i: number) {
    const { actionStore: api } = this.props;
    const blob = await api.fxBinary(
      `${imgFunctionNames[i]}_retrieve`,
    );

    // @ts-ignore
    this.setState({
      [imgStateNames[i]]: blob,
    });
  }

  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_save`,
        {
          custcd: publicStore.user.custcd,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        await this.imageRequest(i);
        await ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  async imageDelete(i: number) {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      `${imgFunctionNames[i]}_delete`,
      {
        custcd: this.state.data.custcd,
        spjangcd: 'ZZ',
      },
    )) {
      await this.imageRequest(i);
      await ConfirmSuccess.show('저장', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      await this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  onRowFocusEvent(listIndex: number, item: CustModel) {
    const updated = listIndex === BoardTimeTypes.TIME
      ? { focusedTime: item }
      : { focusedLoop: item };

    this.setState(updated);
  }

  @action
  toggleSettingsModal(isOpen: boolean) {
    this.setState({ modal: isOpen });
    if (isOpen) {
      this.retrieveBoard(BoardTimeTypes.TIME);
      this.retrieveBoard(BoardTimeTypes.LOOP);
    }
  }

  @action
  async b_bankda() {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      'b_bankda',
      {
        custcd: this.state.data.custcd,
        spjangcd: 'ZZ',
        bankda_flag: '1',
      },
    )) {
      this.setState({
        data: {
          ...this.state.data,
          bankda_flag: '1',
        },
      });
      await ConfirmSuccess.show('성공', '연동되었습니다.');
    } else {
      ConfirmFail.show('실패', '연동에 실패하였습니다.');
    }
  }

  @action
  async new_time(listIndex: number) {
    const { actionStore: api } = this.props;
    let selectList: Array<any> = [];
    if (listIndex === BoardTimeTypes.TIME) {
      selectList = this.state.timeList;
    } else {
      selectList = this.state.loopList;
    }

    const data = await api.custBoard(
      listIndex === BoardTimeTypes.TIME ? 'new_time' : 'new_loop',
      {
        custcd: this.state.data.custcd,
        spjangcd: 'ZZ',
      },
    );

    if (listIndex === BoardTimeTypes.TIME) {
      data && this.setState({
        timeList: [
          ...selectList,
          data,
        ],
      }, async () => {
        await this.tableTime.current?.update(false);
        this.tableTime.current?.setFocus(selectList.length, 1);
      });
    } else {
      data && this.setState({
        loopList: [
          ...selectList,
          data,
        ],
      }, async () => {
        await this.tableLoop.current?.update(false);
        this.tableLoop.current?.setFocus(selectList.length, 1);
      });
    }
  }

  @action
  async retrieveBoard(listIndex: number) {
    const { actionStore: api } = this.props;
    const data = await api.custBoard(
      listIndex === BoardTimeTypes.TIME ? 'retrieve_time' : 'retrieve_loop',
      {
        custcd: this.state.data.custcd,
        spjangcd: 'ZZ',
      },
    );

    if (listIndex === BoardTimeTypes.TIME) {
      data && this.setState({
        timeList: data.items,
      }, async () => {
        await this.tableTime.current?.update(false);
      });
    } else {
      data && this.setState({
        loopList: data.items,
      }, async () => {
        await this.tableLoop.current?.update(false);
      });
    }
  }

  @action
  async save_time(listIndex: number): Promise<boolean> {
    const { actionStore: api } = this.props;
    let selectList: Array<any> = [];
    if (listIndex === BoardTimeTypes.TIME) {
      selectList = this.updatedTimeRows || [];
    } else {
      selectList = this.updatedLoopRows || [];
    }

    if (await api.custBoard(
      listIndex === BoardTimeTypes.TIME ? 'save_time' : 'save_loop',
      {
        custcd: this.state.data.custcd,
        spjangcd: 'ZZ',
        items: selectList,
      },
    )) {
      return true;
    }
    return false;
  }

  @action
  async delete_time(listIndex: number) {
    const focusedItem = listIndex === BoardTimeTypes.TIME
      ? this.state.focusedTime
      : this.state.focusedLoop;

    if (focusedItem) {
      // @ts-ignore
      const text = `${focusedItem.seq} ${BoardWindowTypeNames[focusedItem.window]}을(를) 삭제하시겠습니까?`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        if (await api.custBoard(
          listIndex === BoardTimeTypes.TIME ? 'delete_time' : 'delete_loop',
          {
            custcd: this.state.data.custcd,
            spjangcd: 'ZZ',
            seq: focusedItem.seq,
          },
        )) {
          this.retrieveBoard(BoardTimeTypes.TIME);
          this.retrieveBoard(BoardTimeTypes.LOOP);
          // eslint-disable-next-line no-template-curly-in-string
          await ConfirmSuccess.show('삭제', `${focusedItem.seq}행을 삭제하였습니다.`);
        }
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(listIndex: number, rows: any[], updatedRows: any[]) {
    if (listIndex === BoardTimeTypes.TIME) {
      this.updatedTimeRows = updatedRows;
      this.setState({ timeList: rows });
    } else {
      this.updatedLoopRows = updatedRows;
      this.setState({ loopList: rows });
    }
  }

  render() {
    return (
      <CustTemplate
        scope={this}
        update={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
