import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  HumanCageDayModel,
  HumanCageMonthModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { HumanCageTemplate } from './HumanCage.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderDay,
  TabHeaderMonth,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Date6 } from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components';
import { PageComponent } from '../../../../utils';

export enum HumanCageTabId {
  Day,
  Act,
}

export const HumanCageTabTitles = [
  '일자별',
  '월별통계',
];

export const HumanCageTabModels = [
  HumanCageDayModel,
  HumanCageMonthModel,
];

export const HumanCageTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const HumanCageTabDataStateNames = [
  'dayList',
  'monthlist',
];

export interface HumanCageState {
  dayList?: Array<HumanCageDayModel>; // 부품내역 리스트
  monthlist?: Array<HumanCageMonthModel>; // 사용내역 리스트

  focusedTab?: TabModel;

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  perid: string; // 담당자
  pernm: string; // 담당자명
  actcd: string; // 현장
  actnm: string; // 현장명
  divicd: string; // 부서코드
  divinm: string; // 부서명
  gubun: string;

  total: string;
  aver_tot: string; // 평균총관리대수
  trouble_tot: string; // 사람갇힘건수
  troubleyul_tot: string; // 평균갇힘고장률
  mon01_tot: string;
  compmon01_tot: string;
  yul01_tot: string;
  mon02_tot: string;
  compmon02_tot: string;
  yul02_tot: string;
  mon03_tot: string;
  compmon03_tot: string;
  yul03_tot: string;
  mon04_tot: string;
  compmon04_tot: string;
  yul04_tot: string;
  mon05_tot: string;
  compmon05_tot: string;
  yul05_tot: string;
  mon06_tot: string;
  compmon06_tot: string;
  yul06_tot: string;
  mon07_tot: string;
  compmon07_tot: string;
  yul07_tot: string;
  mon08_tot: string;
  compmon08_tot: string;
  yul08_tot: string;
  mon09_tot: string;
  compmon09_tot: string;
  yul09_tot: string;
  mon10_tot: string;
  compmon10_tot: string;
  yul10_tot: string;
  mon11_tot: string;
  compmon11_tot: string;
  yul11_tot: string;
  mon12_tot: string;
  compmon12_tot: string;
  yul12_tot: string;
  montot_tot: string;
  compmontot_tot: string;
  yultot_tot: string;

  // URL
  url: string;
  focuseddata?: HumanCageDayModel;
}
/**
 * 컨트롤러
 * @window w_tb_e411w_02
 * @category 사람갇힘현황
 */
export class HumanCage extends PageComponent<PageProps, HumanCageState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = HumanCageTabId;
    const titles = HumanCageTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    this.tabs = [
      new TabModel(id.Day.toString(), MdReorder, titles[id.Day]),
      new TabModel(id.Act.toString(), MdReceipt, titles[id.Act]),
    ];

    this.tabHeaders = [
      TabHeaderDay,
      TabHeaderMonth,
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    const oneYearAgo: Date = new Date(today.setFullYear(today.getFullYear() - 1));

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      stdate: `${Date6.make(oneYearAgo).substr(0, 4)}${month}${date}`,
      enddate: `${year}${month}${date}`,
      dayList: [],
      monthlist: [],
      searchQuery: '', // 검색어
      perid: bosuPerid || '%', // 담당자
      pernm: bosuPernm || '', // 담당자명
      actcd: '%', // 현장
      actnm: '', // 현장명
      divicd: bosuDivicd || '%',
      divinm: bosuDivinm || '',
      gubun: '%',

      total: '',
      qty_tot: '',
      banqty_tot: '',
      mon01_tot: '',
      compmon01_tot: '',
      yul01_tot: '',
      mon02_tot: '',
      compmon02_tot: '',
      yul02_tot: '',
      mon03_tot: '',
      compmon03_tot: '',
      yul03_tot: '',
      mon04_tot: '',
      compmon04_tot: '',
      yul04_tot: '',
      mon05_tot: '',
      compmon05_tot: '',
      yul05_tot: '',
      mon06_tot: '',
      compmon06_tot: '',
      yul06_tot: '',
      mon07_tot: '',
      compmon07_tot: '',
      yul07_tot: '',
      mon08_tot: '',
      compmon08_tot: '',
      yul08_tot: '',
      mon09_tot: '',
      compmon09_tot: '',
      yul09_tot: '',
      mon10_tot: '',
      compmon10_tot: '',
      yul10_tot: '',
      mon11_tot: '',
      compmon11_tot: '',
      yul11_tot: '',
      mon12_tot: '',
      compmon12_tot: '',
      yul12_tot: '',
      url: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[HumanCageTabId.Day]);
    // await this.doRetrieve(HumanCageTabId.Day);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[HumanCageTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxPrint(
      `${HumanCageTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[HumanCageTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxExcel(
      `${HumanCageTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onRowFocusEvent(item: any) {
    this.setState({
      focuseddata: item,
    }, async () => {
      await this.urlRetrieve();
    });
  }

  @computed
  get tabIndex(): HumanCageTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: HumanCageTabId, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
      (params) => api.fxExec(
        `${HumanCageTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [HumanCageTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[HumanCageTabDataStateNames[i]],
              ...items.map((x: any) => new HumanCageTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [HumanCageTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [HumanCageTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('id', this.state.focuseddata?.actnm, type, true) || 0;
      const data = await this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        aver_tot: data?.aver_tot || '0',
        trouble_tot: data?.trouble_tot || '0',
        troubleyul_tot: data?.troubleyul_tot || '0',
        mon01_tot: data?.mon01_tot || '0',
        compmon01_tot: data?.compmon01_tot || '0',
        yul01_tot: data?.yul01_tot || '0',
        mon02_tot: data?.mon02_tot || '0',
        compmon02_tot: data?.compmon02_tot || '0',
        yul02_tot: data?.yul02_tot || '0',
        mon03_tot: data?.mon03_tot || '0',
        compmon03_tot: data?.compmon03_tot || '0',
        yul03_tot: data?.yul03_tot || '0',
        mon04_tot: data?.mon04_tot || '0',
        compmon04_tot: data?.compmon04_tot || '0',
        yul04_tot: data?.yul04_tot || '0',
        mon05_tot: data?.mon05_tot || '0',
        compmon05_tot: data?.compmon05_tot || '0',
        yul05_tot: data?.yul05_tot || '0',
        mon06_tot: data?.mon06_tot || '0',
        compmon06_tot: data?.compmon06_tot || '0',
        yul06_tot: data?.yul06_tot || '0',
        mon07_tot: data?.mon07_tot || '0',
        compmon07_tot: data?.compmon07_tot || '0',
        yul07_tot: data?.yul07_tot || '0',
        mon08_tot: data?.mon08_tot || '0',
        compmon08_tot: data?.compmon08_tot || '0',
        yul08_tot: data?.yul08_tot || '0',
        mon09_tot: data?.mon09_tot || '0',
        compmon09_tot: data?.compmon09_tot || '0',
        yul09_tot: data?.yul09_tot || '0',
        mon10_tot: data?.mon10_tot || '0',
        compmon10_tot: data?.compmon10_tot || '0',
        yul10_tot: data?.yul10_tot || '0',
        mon11_tot: data?.mon11_tot || '0',
        compmon11_tot: data?.compmon11_tot || '0',
        yul11_tot: data?.yul11_tot || '0',
        mon12_tot: data?.mon12_tot || '0',
        compmon12_tot: data?.compmon12_tot || '0',
        yul12_tot: data?.yul12_tot || '0',
        montot_tot: data?.montot_tot || '0',
        compmontot_tot: data?.compmontot_tot || '0',
        yultot_tot: data?.yultot_tot || '0',
      });
      this.grid.current?.setFocus(index);
      await this.onRowFocusEvent(this.state.focuseddata);
    });
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/stock.php?window=w_tb_e411w_02&type=0&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_cltcd=%&as_comp=${this.state.gubun}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&title=사람갇힘`,
    });
  }

  render() {
    return (
      <HumanCageTemplate
        scope={this}
      />
    );
  }
}
