import { FormatTextBox, FlexLayout } from '../../../../../components';
import { GridLayoutHeader } from '../../../../../constants';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { BankModel } from '../models';
import { BankStatement } from '../BankStatement';

// 은행내역
const Bank: GridLayoutHeader<BankModel, BankStatement>[] = [
  {
    id: 'accdate',
    text: '발생일자',
    width: 13,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.accdate}
      />
    ),
  },
  {
    id: 'mismon',
    text: '발생월',
    isHidden: true,
    group: true,
    value: (x) => x.misdate?.substr(0, 6),
  },
  {
    id: 'acctime',
    text: '시간',
    width: 10,
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        월 계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.acctime}
      />
    ),
  },
  {
    id: 'remark',
    text: '적요',
    width: 27,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 13,
    sum: (x, data) => {
      const sumRcvamt = Sum.byGroup(data, 'rcvamt', x, ['mismon']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumRcvamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab2_rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt',
    text: '지급액',
    width: 13,
    sum: (x, data) => {
      const sumSndamt = Sum.byGroup(data, 'sndamt', x, ['mismon']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSndamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab2_resuamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.sndamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '잔액',
    width: 13,
    sum: (x, data) => {
      const sumResuamt = Sum.byGroup(data, 'resuamt', x, ['mismon']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumResuamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab2_sndamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'content',
    text: '거래유형',
    width: 13,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.content}
      </FlexLayout>
    ),
  },
  {
    id: 'etc',
    text: '비고',
    width: 23,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.etc}
      </FlexLayout>
    ),
  },
];

export default Bank;
