import { computed } from 'mobx';

/**
 * 수정세금계산서 버튼팝업 모델
 * @window w_tb_da023
 * @category 매출등록
 */
export class TexPopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 명칭
   *
   * **data type** varchar(2)
   */
  occuclafi: string;

  /**
   * 영세율체크
   *
   * **data type** varchar(9)
   */
  eclafi: string;

  /**
   * 권
   *
   * **data type** varchar(6)
   */
  booknum: string;

  /**
   * 호
   *
   * **data type** varchar(6)
   */
  bookho: string;

  /**
   * 발급선택
   *
   * **data type** varchar(2)
   */
  saleflag: string;

  /**
   * 귀속부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 귀속부서2
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 일련번호
   *
   * **data type** varchar(6)
   */
  serinum: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  tb_xa012_prenm: string;

  /**
   * 상호(법입명)
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 상호(법입명)2
   *
   * **data type** varchar(60)
   */
  tb_ia011_cltnm: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  tb_ia090_prenm: string;

  /**
   * 사업장주소
   *
   * **data type** varchar(100)
   */
  tb_ia011_biztypenm: string;

  /**
   * 업태
   *
   * **data type** varchar(40)
   */
  tb_xa012_biztype: string;

  /**
   * 종목
   *
   * **data type** varchar(40)
   */
  tb_xa012_item: string;

  /**
   * 종목
   *
   * **data type** varchar(100)
   */
  tb_ia011_bizitemnm: string;

  /**
   * 년
   *
   * **data type** varchar(4)
   */
  pubdatey: string;

  /**
   * 월
   *
   * **data type** varchar(2)
   */
  pubdatem: string;

  /**
   * 일
   *
   * **data type** varchar(8)
   */
  pubdated: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remarks: string;

  /**
   * 월
   *
   * **data type** varchar(2)
   */
  pubdatem_1: string;

  /**
   * 월2
   *
   * **data type** varchar(2)
   */
  pubdatem_2: string;

  /**
   * 월3
   *
   * **data type** varchar(2)
   */
  pubdatem_3: string;

  /**
   * 월4
   *
   * **data type** varchar(2)
   */
  pubdatem_4: string;

  /**
   * 일
   *
   * **data type** varchar(2)
   */
  pubdated_1: string;

  /**
   * 일2
   *
   * **data type** varchar(2)
   */
  pubdated_2: string;

  /**
   * 일3
   *
   * **data type** varchar(2)
   */
  pubdated_3: string;

  /**
   * 일4
   *
   * **data type** varchar(2)
   */
  pubdated_4: string;

  /**
   * 품목
   *
   * **data type** varchar(40)
   */
  artclnm1: string;

  /**
   * 품목2
   *
   * **data type** varchar(40)
   */
  artclnm2: string;

  /**
   * 품목3
   *
   * **data type** varchar(40)
   */
  artclnm3: string;

  /**
   * 품목4
   *
   * **data type** varchar(40)
   */
  artclnm4: string;

  /**
   * 규격
   *
   * **data type** varchar(40)
   */
  standard1: string;

  /**
   * 규격2
   *
   * **data type** varchar(40)
   */
  standard2: string;

  /**
   * 규격3
   *
   * **data type** varchar(40)
   */
  standard3: string;

  /**
   * 규격4
   *
   * **data type** varchar(40)
   */
  standard4: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty1: string;

  /**
   * 수량2
   *
   * **data type** number
   */
  qty2: string;

  /**
   * 수량3
   *
   * **data type** number
   */
  qty3: string;

  /**
   * 수량4
   *
   * **data type** number
   */
  qty4: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uprice1: string;

  /**
   * 단가2
   *
   * **data type** number
   */
  uprice2: string;

  /**
   * 단가3
   *
   * **data type** number
   */
  uprice3: string;

  /**
   * 단가4
   *
   * **data type** number
   */
  uprice4: string;

  /**
   * 공급가액
   *
   * **data type** number
   */
  suprice1: string;

  /**
   * 공급가액2
   *
   * **data type** number
   */
  suprice2: string;

  /**
   * 공급가액3
   *
   * **data type** number
   */
  suprice3: string;

  /**
   * 공급가액4
   *
   * **data type** number
   */
  suprice4: string;

  /**
   * 세액
   *
   * **data type** number
   */
  taxamt1: string;

  /**
   * 세액2
   *
   * **data type** number
   */
  taxamt2: string;

  /**
   * 세액3
   *
   * **data type** number
   */
  taxamt3: string;

  /**
   * 세액4
   *
   * **data type** number
   */
  taxamt4: string;

  /**
   * 합계
   *
   * **data type** varchar(20)
   */
  compute_total: string;

  /**
   * 현금
   *
   * **data type** number
   */
  cash: string;

  /**
   * 수표
   *
   * **data type** number
   */
  chk: string;

  /**
   * 어음
   *
   * **data type** number
   */
  draft: string;

  /**
   * 외상미수금
   *
   * **data type** number
   */
  compute_trureci: string;

  /**
   * 청구체크
   *
   * **data type** char(1)
   */
  n_receiptyn: string;

  /**
   * 매출일자2
   *
   * **data type** char(4)
   */
  n_misnum: string;

  /**
   * 현장명
   *
   * **data type** char(15)
   */
  n_actcd: string;

  /**
   * 현장명2
   *
   * **data type** char(255)
   */
  n_actnm: string;

  /**
   * 청구
   *
   * **data type** char(1)
   */
  receiptyn: string;

  /**
   * 사유
   *
   * **data type** char(100)
   */
  n_sayoo: string;

  /**
   * 거래처명2
   *
   * **data type** char(255)
   */
  n_cltnm: string;

  /**
   * 대표자명
   *
   * **data type** char(13)
   */
  n_prenm: string;

  /**
   * 사업자번호
   *
   * **data type** char(13)
   */
  n_saupnum: string;

  /**
   * 업태
   *
   * **data type** char(100)
   */
  n_biztypenm: string;

  /**
   * 종목
   *
   * **data type** char(100)
   */
  n_bizitemnm: string;

  /**
   * 비고
   *
   * **data type** char(255)
   */
  n_bigo: string;

  /**
   * 순서
   *
   * **data type** char(3)
   */
  seq: string;

  /**
   * 품명
   *
   * **data type** char(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** char(255)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** char(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uamt: string;

  /**
   * 금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 비고
   *
   * **data type** char(255)
   */
  remark: string;

  /**
   * 등록번호
   *
   * **data type** number
   */
  tb_xa012_saupnum: string;

  /**
   * 등록번호2
   *
   * **data type** number
   */
  tb_ia011_cltadres: string;

  /**
   * 상호(법인명)
   *
   * **data type** char(20)
   */
  n_cltcd: string;

  /**
   * 사업장주소
   *
   * **data type** char(255)
   */
  tb_xa012_address: string;

  /**
   * 공급가액(백)
   *
   * **data type** char(1)
   */
  samt0: string;

  /**
   * 공급가액(십)
   *
   * **data type** char(1)
   */
  samt1: string;

  /**
   * 공급가액(억)
   *
   * **data type** char(1)
   */
  samt2: string;

  /**
   * 공급가액(천)
   *
   * **data type** char(1)
   */
  samt3: string;

  /**
   * 공급가액(백)2
   *
   * **data type** char(1)
   */
  samt4: string;

  /**
   * 공급가액(십)2
   *
   * **data type** char(1)
   */
  samt5: string;

  /**
   * 공급가액(만)2
   *
   * **data type** char(1)
   */
  samt6: string;

  /**
   * 공급가액(천)2
   *
   * **data type** char(1)
   */
  samt7: string;

  /**
   * 공급가액(백)3
   *
   * **data type** char(1)
   */
  samt8: string;

  /**
   * 공급가액(십)3
   *
   * **data type** char(1)
   */
  samt9: string;

  /**
   * 공급가액(일)3
   *
   * **data type** char(1)
   */
  samt10: string;

  /**
   * 공급가액
   *
   * **data type** char(1)
   */
  samt11: string;

  /**
   * 세액(십)
   *
   * **data type** char(1)
   */
  tamt1: string;

  /**
   * 세액(억)
   *
   * **data type** char(1)
   */
  tamt2: string;

  /**
   * 세액(천)
   *
   * **data type** char(1)
   */
  tamt3: string;

  /**
   * 세액(백)
   *
   * **data type** char(1)
   */
  tamt4: string;

  /**
   * 세액(십)
   *
   * **data type** char(1)
   */
  tamt5: string;

  /**
   * 세액(만)
   *
   * **data type** char(1)
   */
  tamt6: string;

  /**
   * 세액(천)
   *
   * **data type** char(1)
   */
  tamt7: string;

  /**
   * 세액(백)
   *
   * **data type** char(1)
   */
  tamt8: string;

  /**
   * 세액(십)
   *
   * **data type** char(1)
   */
  tamt9: string;

  /**
   * 합계
   *
   * **data type** char(20)
   */
  totamt: string;

  /**
   * 계산서발행
   *
   * **data type** char(1)
   */
  n_billkind: string;

  /**
   * 부가세여부
   *
   * **data type** char(1)
   */
  n_taxcls: string;

  /**
   * 매출일자
   *
   * **data type** char(10)
   */
  n_datetime: string;

  /**
   * 상호(법인명)
   *
   * **data type** char(50)
   */
  tb_xa012_spjangnm: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * -
   *
   * **data type** char(50)
   */
  amt: string;

  /**
   * 외상미수금
   *
   * **data type** number
   */
  trureciamt: string;

  /**
   * 공사등록일자
   *
   * **data type** char(50)
   */
  compdate: string;

  /**
   * 번호
   *
   * **data type** char(50)
   */
  compnum: string;

  /**
   * 구분
   *
   * **data type** char(10)
   */
  gubun: string;

  /**
   * 현장명
   *
   * **data type** char(50)
   */
  actnm: string;

  /**
   * 공사명
   *
   * **data type** char(50)
   */
  offinm: string;

  /**
   * 공사담당자
   *
   * **data type** char(20)
   */
  pernm: string;

  /**
   * 매출실적담당자
   *
   * **data type** char(20)
   */
  perid: string;

  /**
   * 매출실적담당자
   *
   * **data type** char(20)
   */
  misperid: string;

  /**
   * 매출실적담당자명
   *
   * **data type** char(20)
   */
  mispernm: string;

  /**
   * 계산서발행
   *
   * **data type** char(20)
   */
  taxcls: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  billkind: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  camt: string;

  /**
   * 할인금액
   *
   * **data type** number
   */
  saleamt: string;

  /**
   * 거래처명
   *
   * **data type** char(20)
   */
  cltnm: string;

  /**
   * 번호
   *
   * **data type** char(20)
   */
  ordnum: string;

  /**
   * 구분
   *
   * **data type** char(50)
   */
  gubunnm: string;

  /**
   * 프로젝트명
   *
   * **data type** char(50)
   */
  projnm: string;

  /**
   * 합계금액
   *
   * **data type** char(50)
   */
  mamt: string;

  /**
   * 계약담당자
   *
   * **data type** number
   */
  contpernm: string;

  /**
   * 등록번호
   *
   * **data type** char(50)
   */
  tb_ia011_saupnum: string;

  /**
   * 성명
   *
   * **data type** char(50)
   */
  tb_ia011_prenm: string;

  /**
   * -
   *
   * **data type** char(50)
   */
  saleyul: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 회사
   *
   * **data type** varchar(13)
   */
  wkactcd: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  orddate: string;

  /**
   * -
   *
   * **data type** varchar(?)
   */
  taxgubun: string;

  /**
   * -
   *
   * **data type** varchar(?)
   */
  billgubun: string;

  /**
   * -
   *
   * **data type** varchar(?)
   */
  projno: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.trureciamt = data.trureciamt || '';
    this.custcd = data.custcd || '';
    this.projno = data.projno || '';
    this.taxgubun = data.taxgubun || '';
    this.billgubun = data.billgubun || '';
    this.orddate = data.orddate || '';
    this.actcd = data.actcd || '';
    this.wkactcd = data.wkactcd || '';
    this.gubun = data.gubun || '';
    this.saleyul = data.saleyul || '';
    this.tb_ia011_saupnum = data.tb_ia011_saupnum || '';
    this.mamt = data.mamt || '';
    this.contpernm = data.contpernm || '';
    this.ordnum = data.ordnum || '';
    this.projnm = data.projnm || '';
    this.gubunnm = data.gubunnm || '';
    this.tamt = data.tamt || '';
    this.cltnm = data.cltnm || '';
    this.camt = data.camt || '';
    this.saleamt = data.saleamt || '';
    this.billkind = data.billkind || '';
    this.taxcls = data.taxcls || '';
    this.offinm = data.offinm || '';
    this.misperid = data.misperid || '';
    this.perid = data.perid || '';
    this.mispernm = data.mispernm || '';
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.misamt = data.misamt || '';
    this.amt = data.amt || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.tb_xa012_saupnum = data.tb_xa012_saupnum || '';
    this.tb_xa012_spjangnm = data.tb_xa012_spjangnm || '';
    this.n_datetime = data.n_datetime || '';
    this.tb_ia011_prenm = data.tb_ia011_prenm || '';
    this.n_cltcd = data.n_cltcd || '';
    this.tb_xa012_address = data.tb_xa012_address || '';
    this.occuclafi = data.occuclafi || '';
    this.eclafi = data.eclafi || '';
    this.receiptyn = data.receiptyn || '';
    this.booknum = data.booknum || '';
    this.bookho = data.bookho || '';
    this.saleflag = data.saleflag || '';
    this.n_taxcls = data.n_taxcls || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.serinum = data.serinum || '';
    this.tb_xa012_prenm = data.tb_xa012_prenm || '';
    this.cltcd = data.cltcd || '';
    this.tb_ia011_cltnm = data.tb_ia011_cltnm || '';
    this.tb_ia090_prenm = data.tb_ia090_prenm || '';
    this.tb_ia011_cltadres = data.tb_ia011_cltadres || '';
    this.tb_xa012_biztype = data.tb_xa012_biztype || '';
    this.tb_xa012_item = data.tb_xa012_item || '';
    this.tb_ia011_biztypenm = data.tb_ia011_biztypenm || '';
    this.tb_ia011_bizitemnm = data.tb_ia011_bizitemnm || '';
    this.pubdatey = data.pubdatey || '';
    this.pubdatem = data.pubdatem || '';
    this.pubdated = data.pubdated || '';
    this.remarks = data.remarks || '';
    this.pubdatem_1 = data.pubdatem_1 || '';
    this.pubdatem_2 = data.pubdatem_2 || '';
    this.pubdatem_3 = data.pubdatem_3 || '';
    this.pubdatem_4 = data.pubdatem_4 || '';
    this.pubdated_1 = data.pubdated_1 || '';
    this.pubdated_2 = data.pubdated_2 || '';
    this.pubdated_3 = data.pubdated_3 || '';
    this.pubdated_4 = data.pubdated_4 || '';
    this.artclnm1 = data.artclnm1 || '';
    this.artclnm2 = data.artclnm2 || '';
    this.artclnm3 = data.artclnm3 || '';
    this.artclnm4 = data.artclnm4 || '';
    this.standard1 = data.standard1 || '';
    this.standard2 = data.standard2 || '';
    this.standard3 = data.standard3 || '';
    this.standard4 = data.standard4 || '';
    this.qty1 = data.qty1 || '';
    this.qty2 = data.qty2 || '';
    this.qty3 = data.qty3 || '';
    this.qty4 = data.qty4 || '';
    this.uprice1 = data.uprice1 || '';
    this.uprice2 = data.uprice2 || '';
    this.uprice3 = data.uprice3 || '';
    this.uprice4 = data.uprice4 || '';
    this.suprice1 = data.suprice1 || '';
    this.suprice2 = data.suprice2 || '';
    this.suprice3 = data.suprice3 || '';
    this.suprice4 = data.suprice4 || '';
    this.taxamt1 = data.taxamt1 || '';
    this.taxamt2 = data.taxamt2 || '';
    this.taxamt3 = data.taxamt3 || '';
    this.taxamt4 = data.taxamt4 || '';
    this.compute_total = data.compute_total || '';
    this.cash = data.cash || '';
    this.chk = data.chk || '';
    this.draft = data.draft || '';
    this.compute_trureci = data.compute_trureci || '';
    this.n_receiptyn = data.n_receiptyn || '';
    this.n_misnum = data.n_misnum || '';
    this.n_actcd = data.n_actcd || '';
    this.n_actnm = data.n_actnm || '';
    this.n_sayoo = data.n_sayoo || '';
    this.n_cltnm = data.n_cltnm || '';
    this.n_prenm = data.n_prenm || '';
    this.n_saupnum = data.n_saupnum || '';
    this.n_billkind = data.n_billkind || '';
    this.n_biztypenm = data.n_biztypenm || '';
    this.n_bizitemnm = data.n_bizitemnm || '';
    this.n_bigo = data.n_bigo || '';
    this.seq = data.seq || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.remark = data.remark || '';
    this.addamt = data.addamt || '';
    this.samt0 = data.samt0 || '';
    this.samt1 = data.samt1 || '';
    this.samt2 = data.samt2 || '';
    this.samt3 = data.samt3 || '';
    this.samt4 = data.samt4 || '';
    this.samt5 = data.samt5 || '';
    this.samt6 = data.samt6 || '';
    this.samt7 = data.samt7 || '';
    this.samt8 = data.samt8 || '';
    this.samt9 = data.samt9 || '';
    this.samt10 = data.samt10 || '';
    this.samt11 = data.samt11 || '';
    this.tamt1 = data.tamt1 || '';
    this.tamt2 = data.tamt2 || '';
    this.tamt3 = data.tamt3 || '';
    this.tamt4 = data.tamt4 || '';
    this.tamt5 = data.tamt5 || '';
    this.tamt6 = data.tamt6 || '';
    this.tamt7 = data.tamt7 || '';
    this.tamt8 = data.tamt8 || '';
    this.tamt9 = data.tamt9 || '';
    this.totamt = data.totamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
