import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
  GridLayoutAdditionalHeader,
  ModalView,
  LayoutTitle,
  Button,
  CircleBadge,
  SubHeader,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SiteVisit } from './SiteVisit';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e501w_01
 * @category 현장방문숙지리스트
 */

const atdcds = [
  { value: '00', remark: '출근전' },
  { value: '01', remark: '정상' },
  { value: '02', remark: '지각' },
  { value: '03', remark: '연차' },
  { value: '04', remark: '반차' },
  { value: '05', remark: '결근' },
  { value: '06', remark: '출장' },
  { value: '07', remark: '조퇴' },
  { value: '08', remark: '훈련' },
  { value: '09', remark: '교육' },
  { value: '10', remark: '출산휴가' },
  { value: '11', remark: '특별휴가' },
  { value: '12', remark: '휴일' },
];

const checkModalStates = [
  { value: '0', remark: '만료' },
  { value: '1', remark: '계획' },
  { value: '2', remark: '검사' },
  { value: '3', remark: '조치' },
];

const checkoModalGubuns = [
  { value: '001', remark: '정기' },
  { value: '002', remark: '정밀' },
  { value: '003', remark: '자체' },
  { value: '004', remark: '완성' },
  { value: '005', remark: '수시' },
  { value: '006', remark: '검사' },
  { value: '007', remark: '설치' },
  { value: '008', remark: '0' },
];

const checkModalResults = [
  { value: '002', remark: '불합격' },
  { value: '003', remark: '조건부' },
  { value: '010', remark: '0' },
  { value: '011', remark: '조건부합격(1년)' },
  { value: '012', remark: '2개월조건부조건후불합격' },
  { value: '001', remark: '합격' },
  { value: '007', remark: '보완후합격' },
  { value: '008', remark: '현장시정조치합격' },
  { value: '009', remark: '보완' },
  { value: '013', remark: '보완후불합격' },
  { value: '014', remark: '승인' },
  { value: '004', remark: '조건후합격' },
  { value: '005', remark: '조건후불합격' },
  { value: '006', remark: '조건부합격' },
];

export const SiteVisitTemplate: React.FC<TemplateProps<SiteVisit>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={225}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={200}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={120}>
        <span>주차</span>
        <TextBox
          value={scope.state.divinm}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          value={scope.state.divicd}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.divinm}
          weight={1.5}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={270}>
        <span style={{ color: 'var(--color-red)' }}>사용자</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.pernm}
          weight={1.7}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
      <Button onClick={() => scope.refresh()}>새로고침</Button>
    </SearchLayout>

    <GridLayoutAdditionalHeader
      ref={scope.additionalTitle}
      height={Global.LAYOUT_TITLE_HEIGHT_1}
      contentWidth={1960}
    >
      <FlexLayout size={200}/>
      <FlexLayout
        size={1188}
        justify="center"
        align="center"
      >
        {/* eslint-disable-next-line max-len */}
        {`기간내 ${scope.state.stdate.substr(4, 2)}-${scope.state.stdate.substr(6, 2)} ~ ${scope.state.enddate.substr(4, 2)}-${scope.state.enddate.substr(6, 2)} 내역`}
      </FlexLayout>
      <FlexLayout
        size={418}
        justify="center"
        align="center"
      >
        {`${scope.state.endmon} 월`}
      </FlexLayout>
      <FlexLayout
        size={138}
        justify="center"
        align="center"
      >
        누적
      </FlexLayout>
    </GridLayoutAdditionalHeader>

    <GridLayoutAdditionalHeader
      ref={scope.additionalSubTitle}
      height={Global.LAYOUT_TITLE_HEIGHT_1}
      contentWidth={1960}
    >
      <FlexLayout size={200}/>
      <FlexLayout
        size={388}
        justify="center"
        align="center"
      >
        점검
      </FlexLayout>
      <FlexLayout
        size={178}
        justify="center"
        align="center"
      >
        점검시 계약
      </FlexLayout>
      <FlexLayout
        size={138}
        justify="center"
        align="center"
      >
        점검시 장기미수
      </FlexLayout>
      <FlexLayout
        size={58}
        justify="center"
        align="center"
      >
        노후된
      </FlexLayout>
      <FlexLayout
        size={58}
        justify="center"
        align="center"
      >
        고장
      </FlexLayout>
      <FlexLayout
        size={238}
        justify="center"
        align="center"
      >
        검사
      </FlexLayout>
      <FlexLayout
        size={178}
        justify="center"
        align="center"
      >
        계약
      </FlexLayout>
      <FlexLayout
        size={238}
        justify="center"
        align="center"
      >
        공사
      </FlexLayout>
      <FlexLayout
        size={138}
        justify="center"
        align="center"
      >
        장기미수(3개월이상)
      </FlexLayout>
    </GridLayoutAdditionalHeader>

    <FlexLayout isVertical={true}>
      <GridLayout
        ref={scope.grid}
        headerHeight={Global.LAYOUT_GRID_HEIGHT_1}
        isScroll={true}
        header={[
          {
            id: 'divinm',
            text: '부서별그룹',
            isHidden: true,
            group: true,
            value: (x) => x.divinm,
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 70,
            sum: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자명',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd',
            text: '근태',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  // eslint-disable-next-line no-nested-ternary,no-constant-condition
                  color: x.atdcd === '00' ? '#fed330'
                    // eslint-disable-next-line no-nested-ternary
                    : x.atdcd === '02' ? 'var(--color-violet)'
                      // eslint-disable-next-line no-nested-ternary
                      : x.atdcd === '03' ? 'var(--color-skyblue)'
                        // eslint-disable-next-line no-nested-ternary
                        : x.atdcd === '04' ? 'var(--color-skyblue)'
                          // eslint-disable-next-line no-nested-ternary
                          : x.atdcd === '05' ? 'var(--color-red)'
                            // eslint-disable-next-line no-nested-ternary
                            : x.atdcd === '06' ? 'var(--color-blue)'
                              // eslint-disable-next-line no-nested-ternary
                              : x.atdcd === '07' ? 'var(--color-pink)'
                                // eslint-disable-next-line no-nested-ternary
                                : x.atdcd === '08' ? 'var(--color-gray)'
                                  // eslint-disable-next-line no-nested-ternary
                                  : x.atdcd === '09' ? 'var(--color-green)'
                                    // eslint-disable-next-line no-nested-ternary
                                    : x.atdcd === '10' ? 'var(--color-skyblue)'
                                      // eslint-disable-next-line no-nested-ternary
                                      : x.atdcd === '11' ? 'var(--color-skyblue)'
                                        : x.atdcd === '12' ? '#fed330' : 'var(--color-black)',
                }}
              >
                {atdcds?.filter((y) => y.value === x.atdcd)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'todayplan',
            text: '계획',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionModal(x, true, '3', '0', '계획')}
              >
                {x.todayplan !== '0' && x.todayplan}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumTodayplan = Sum.byGroup(data, 'todayplan', x, ['divinm']);
              return (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {sumTodayplan !== 0 && Format.number(sumTodayplan)}
                </FlexLayout>
              );
            },
            trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.todayplan_tot}
                </FlexLayout>
            ),
          },
          {
            id: 'plancomp',
            text: '계획처리',
            width: 70,
            render: (x) => (
              <FlexLayout
                style={{ color: parseInt(x.plancomp, 10) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionModal(x, true, '3-plancomp', '1', '계획점검처리')}
              >
                {x.plancomp !== '0' && x.plancomp}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumPlancomp = Sum.byGroup(data, 'plancomp', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: sumPlancomp > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumPlancomp !== 0 && Format.number(sumPlancomp)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(scope.state.plancomp_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.plancomp_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'notplancomp',
            text: '미계획처리',
            width: 80,
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.notplancomp) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionModal(x, true, '3-notplancomp', '1', '미계획점검처리')}
              >
                {x.notplancomp !== '0' && x.notplancomp}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumNotplancomp = Sum.byGroup(data, 'notplancomp', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: sumNotplancomp < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumNotplancomp !== 0 && Format.number(sumNotplancomp)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(scope.state.notplancomp_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.notplancomp_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'todaycompplan',
            text: '처리',
            width: 60,
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.todaycompplan) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionModal(x, true, '3-comp', '1', '점검처리')}
              >
                {x.todaycompplan !== '0' && x.todaycompplan}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumTodaycompplan = Sum.byGroup(data, 'todaycompplan', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: sumTodaycompplan > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumTodaycompplan !== 0 && Format.number(sumTodaycompplan)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'misplan',
            text: '미점검',
            width: 60,
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.misplan) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionModal(x, true, '2', '0', '미점검')}
              >
                {x.misplan !== '0' && x.misplan}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumMisplan = Sum.byGroup(data, 'misplan', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: sumMisplan < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumMisplan !== 0 && Format.number(sumMisplan)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'planyul',
            text: '처리율',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.planyul !== '0' && x.planyul}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumTodayplan = Sum.byGroup(data, 'todayplan', x, ['divinm']);
              const sumPlancomp = Sum.byGroup(data, 'plancomp', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-violet)' }}
                  justify="right"
                  align="center"
                >
                  {sumTodayplan !== 0 && sumPlancomp !== 0
                  && `${Format.number(((sumPlancomp / sumTodayplan) * 100).toFixed(0))} %`
                  }
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}%
              </FlexLayout>
            ),
          },
          {
            id: 'plancontcnt',
            text: '건수',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-green)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionContractModal(x, true, '4-plancontcnt', '점검시 계약')}
              >
                {x.plancontcnt !== '0' && x.plancontcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumPlancontcnt = Sum.byGroup(data, 'plancontcnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-green)' }}
                  justify="right"
                  align="center"
                >
                  {sumPlancontcnt !== 0 && Format.number(sumPlancontcnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-green)' }}
              >
                {scope.state.plancontcnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'plancont001cnt',
            text: '처리',
            width: 60,
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.plancont001cnt) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionContractModal(x, true, '4-plancontcomp', '점검시 계약처리')}
              >
                {x.plancont001cnt !== '0' && x.plancont001cnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumPlancont001cnt = Sum.byGroup(data, 'plancont001cnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: sumPlancont001cnt > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumPlancont001cnt !== 0 && Format.number(sumPlancont001cnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.plancont001cnt_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.plancont001cnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'plancont002cnt',
            text: '미처리',
            width: 60,
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.plancont002cnt) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionContractModal(x, true, '4-plancontnot', '점검시 계약미처리')}
              >
                {x.plancont002cnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumPlancont002cnt = Sum.byGroup(data, 'plancont002cnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: sumPlancont002cnt < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumPlancont002cnt !== 0 && Format.number(sumPlancont002cnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.plancont002cnt_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.plancont002cnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'planmiscnt',
            text: '미수건',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-pink)' }}
                justify="right"
                align="center"
              >
                {x.planmiscnt !== '0' && x.planmiscnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumPlanmiscnt = Sum.byGroup(data, 'planmiscnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-pink)' }}
                  justify="right"
                  align="center"
                >
                  {sumPlanmiscnt !== 0 && Format.number(sumPlanmiscnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-pink)' }}
              >
                {scope.state.planmiscnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'planmisamt',
            text: '장기미수액',
            width: 80,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-pink)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onInspectionLongModal(x, true, '11', '점검시 장기미수액')}
              >
                {x.planmisamt !== '0' && Format.number(x.planmisamt)}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumPlanmisamt = Sum.byGroup(data, 'planmisamt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-pink)' }}
                  justify="right"
                  align="center"
                >
                  {sumPlanmisamt !== 0 && Format.number(sumPlanmisamt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-pink)' }}
              >
                {scope.state.planmisamt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'oldequpcnt',
            text: '호기',
            width: 60,
            render: (x) => (
              <FlexLayout
                align="center"
                justify="right"
                style={{ color: 'var(--color-red)' }}
                onDoubleClick={() => scope.onOldEqupmentModal(x, true, '4-oldequpcnt', '점검시 오래된승강기')}
              >
                {x.oldequpcnt !== '0' && x.oldequpcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumOldEqup = Sum.byGroup(data, 'oldequpcnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumOldEqup !== 0 && Format.number(sumOldEqup)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.oldequpcnt_tot}
              </FlexLayout>
            ),
          },
          // {
          //   id: 'rececompcnt',
          //   text: '처리',
          //   width: 60,
          //   render: (x) => (
          //     <FlexLayout
          //       style={{ color: 'var(--color-green)' }}
          //       justify="right"
          //       align="center"
          //       onDoubleClick={() => scope.onBreakDownModal(x, true, '6', '고장내역[처리자]')}
          //     >
          //       {x.rececompcnt !== '0' && x.rececompcnt}
          //     </FlexLayout>
          //   ),
          //   sum: (x, data) => {
          //     const sumRececompcnt = Sum.byGroup(data, 'rececompcnt', x, ['divinm']);
          //     return (
          //       <FlexLayout
          //         style={{ color: 'var(--color-green)' }}
          //         justify="right"
          //         align="center"
          //       >
          //         {sumRececompcnt !== 0 && Format.number(sumRececompcnt)}
          //       </FlexLayout>
          //     );
          //   },
          //   trail: () => (
          //     <FlexLayout
          //       justify="right"
          //       align="center"
          //       style={{ color: 'var(--color-green)' }}
          //     >
          //       {scope.state.rececompcnt_tot}
          //     </FlexLayout>
          //   ),
          // },
          // {
          //   id: 'rececnt',
          //   text: '담당',
          //   width: 60,
          //   render: (x) => (
          //     <FlexLayout
          //       style={{ color: 'var(--color-blue)' }}
          //       justify="right"
          //       align="center"
          //       onDoubleClick={() => scope.onBreakDownModal(x, true, '7', '고장내역[담당자]')}
          //     >
          //       {x.rececnt !== '0' && x.rececnt}
          //     </FlexLayout>
          //   ),
          //   sum: (x, data) => {
          //     const sumRececnt = Sum.byGroup(data, 'rececnt', x, ['divinm']);
          //     return (
          //       <FlexLayout
          //         style={{ color: 'var(--color-blue)' }}
          //         justify="right"
          //         align="center"
          //       >
          //         {sumRececnt !== 0 && Format.number(sumRececnt)}
          //       </FlexLayout>
          //     );
          //   },
          //   trail: (x) => {
          //     const sumRece = Sum.all(x, 'rececnt');
          //     return (
          //       <FlexLayout
          //         justify="right"
          //         align="center"
          //         style={{ color: 'var(--color-blue)' }}
          //       >
          //         {sumRece}
          //       </FlexLayout>
          //     );
          //   },
          // },
          {
            id: 'wadcnt',
            text: '다발',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onBreakDownModal(x, true, '8', '고장내역[다발고장]')}
              >
                {x.wadcnt !== '0' && x.wadcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumWadcnt = Sum.byGroup(data, 'wadcnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumWadcnt !== 0 && Format.number(sumWadcnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.wadcnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'notkccnt',
            text: '만료',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onCheckModal(x, true, '9-000', '검사만료')}
              >
                {x.notkccnt !== '0' && x.notkccnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumNotkccnt = Sum.byGroup(data, 'notkccnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumNotkccnt !== 0 && Format.number(sumNotkccnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.notkccnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'kccnt',
            text: '계획',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-green)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onCheckModal(x, true, '9-001', '검사계획')}
              >
                {x.kccnt !== '0' && x.kccnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumKccnt = Sum.byGroup(data, 'kccnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-green)' }}
                  justify="right"
                  align="center"
                >
                  {sumKccnt !== 0 && Format.number(sumKccnt)}
                </FlexLayout>
              );
            },
            trail: (x) => {
              const sumKccnt = Sum.all(x, 'kccnt');
              return (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {sumKccnt}
                </FlexLayout>
              );
            },
          },
          {
            id: 'kc001cnt',
            text: '합격',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-blue)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onCheckModal(x, true, '9-002', '검사합격')}
              >
                {x.kc001cnt !== '0' && x.kc001cnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumKc001cnt = Sum.byGroup(data, 'kc001cnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-blue)' }}
                  justify="right"
                  align="center"
                >
                  {sumKc001cnt !== 0 && Format.number(sumKc001cnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.kc001cnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'kc002cnt',
            text: '불합격',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onCheckModal(x, true, '9-003', '검사불합격')}
              >
                {x.kc002cnt !== '0' && x.kc002cnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumKc002cnt = Sum.byGroup(data, 'kc002cnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumKc002cnt !== 0 && Format.number(sumKc002cnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.kc002cnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'contcnt',
            text: '만료',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-green)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onContractModal(x, true, 'contcnt', '계약만료')}
              >
                {x.contcnt !== '0' && x.contcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumContcnt = Sum.byGroup(data, 'contcnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-green)' }}
                  justify="right"
                  align="center"
                >
                  {sumContcnt !== 0 && Format.number(sumContcnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-green)' }}
              >
                {scope.state.contcnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'cont001cnt',
            text: '처리',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-blue)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onContractModal(x, true, 'cont001cnt', '계약처리')}
              >
                {x.cont001cnt !== '0' && x.cont001cnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumCont001cnt = Sum.byGroup(data, 'cont001cnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-blue)' }}
                  justify="right"
                  align="center"
                >
                  {sumCont001cnt !== 0 && Format.number(sumCont001cnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.cont001cnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'cont002cnt',
            text: '해지',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-red)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onContractModal(x, true, 'cont002cnt', '계약해지')}
              >
                {x.cont002cnt !== '0' && x.cont002cnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumCont002cnt = Sum.byGroup(data, 'cont002cnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumCont002cnt !== 0 && Format.number(sumCont002cnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.cont002cnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'compcnt',
            text: '계획',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {x.compcnt !== '0' && x.compcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumCompcnt = Sum.byGroup(data, 'compcnt', x, ['divinm']);
              return (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {sumCompcnt !== 0 && Format.number(sumCompcnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.compcnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'costcnt',
            text: '견적',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-green)' }}
                justify="right"
                align="center"
                onDoubleClick={() => scope.onConsturctionModal(x, true, '10', '견적')}
              >
                {x.costcnt !== '0' && x.costcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumCostcnt = Sum.byGroup(data, 'costcnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-green)' }}
                  justify="right"
                  align="center"
                >
                  {sumCostcnt !== 0 && Format.number(sumCostcnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-green)' }}
              >
                {scope.state.costcnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'costcompcnt',
            text: '공사',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
                onDoubleClick={() => scope.onConsturctionModal(x, true, '10-comp', '공사')}
              >
                {x.costcompcnt !== '0' && x.costcompcnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumCostCompcnt = Sum.byGroup(data, 'costcompcnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-blue)' }}
                  justify="right"
                  align="center"
                >
                  {sumCostCompcnt !== 0 && Format.number(sumCostCompcnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.costcompcnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'compnotmiscnt',
            text: '미매출',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
                onDoubleClick={() => scope.onConsturctionModal(x, true, '10-compnotmis', '공사미매출')}
              >
                {x.compnotmiscnt !== '0' && x.compnotmiscnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumCompnotMiscnt = Sum.byGroup(data, 'compnotmiscnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-red)' }}
                  justify="right"
                  align="center"
                >
                  {sumCompnotMiscnt !== 0 && Format.number(sumCompnotMiscnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.compnotmiscnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'miscnt',
            text: '미수건',
            width: 60,
            render: (x) => (
              <FlexLayout
                style={{ color: 'var(--color-pink)' }}
                justify="right"
                align="center"
              >
                {x.miscnt !== '0' && x.miscnt}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumMiscnt = Sum.byGroup(data, 'miscnt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-pink)' }}
                  justify="right"
                  align="center"
                >
                  {sumMiscnt !== 0 && Format.number(sumMiscnt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-pink)' }}
              >
                {scope.state.miscnt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '장기미수총액',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-pink)' }}
                onDoubleClick={() => scope.onInspectionLongModal(x, true, '12', '장기미수총액')}
              >
                {x.misamt !== '0' && Format.number(x.misamt)}
              </FlexLayout>
            ),
            sum: (x, data) => {
              const sumMisamt = Sum.byGroup(data, 'misamt', x, ['divinm']);
              return (
                <FlexLayout
                  style={{ color: 'var(--color-pink)' }}
                  justify="right"
                  align="center"
                >
                  {sumMisamt !== 0 && Format.number(sumMisamt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-pink)' }}
              >
                {scope.state.misamt_tot}
              </FlexLayout>
            ),
          },
        ]}
        contentWidth={1948}
        data={scope.state.siteVisitList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        onHorizontalScroll={(x) => {
          scope.additionalTitle.current?.scrollTo(x);
          scope.additionalSubTitle.current?.scrollTo(x);
        }}
      />
    </FlexLayout>

    <ModalView
      isVisible={scope.state.inspectionModal}
      onClose={() => scope.SS({ inspectionModal: false, inspectionModalData: [] })}
      width={1200}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

        <FlexLayout>
          <GridLayout
            ref={scope.inspectionGrid}
            header={[
              {
                id: 'pernm',
                text: '담당자명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
                trail: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x?.length} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '계획일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              scope.state.popups === '1'
                ? {
                  id: 'rptdate',
                  text: '처리일자',
                  width: 1,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Date8.withDash(x.rptdate)}
                    </FlexLayout>
                  ),
                } : {
                  id: 'rpdate',
                  text: '처리일자',
                  width: 1,
                  isHidden: true,
                },
              {
                id: 'state',
                text: '상태',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.state === '1' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                  >
                    {x.state === '1' ? '계획' : '완료'}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.inspectionInfinity}
            data={scope.state.inspectionModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.inspectionContractModal}
      onClose={() => scope.SS({ inspectionContractModal: false, inspectionContractModalData: [] })}
      width={1200}
      zIndex={-2}
    >
    <FlexLayout
      isVertical={true}
      style={{ padding: 8 }}
    >
      <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

      <FlexLayout>
        <GridLayout
          ref={scope.inspectionContractGrid}
          header={[
            {
              id: 'pernm',
              text: '담당자명',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 5,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'contgubun',
              text: '계약구분',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.contgubuns?.filter((y) => y.code === x.contgubun)[0]?.cnam}
                </FlexLayout>
              ),
            },
            {
              id: 'enddate',
              text: '만료일',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.enddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'plandate',
              text: '점검계획일자',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.plandate)}
                </FlexLayout>
              ),
            },
            {
              id: 'contg',
              text: '계약분류',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam}
                </FlexLayout>
              ),
            },
            {
              id: 'state',
              text: '상태',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.state === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                >
                  {x.state === '0' ? '미처리' : '처리'}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.inspectionContractInfinity}
          data={scope.state.inspectionContractModalData}
        >
        </GridLayout>
      </FlexLayout>
    </FlexLayout>
  </ModalView>

  <ModalView
    isVisible={scope.state.inspectionLongModal}
    onClose={() => scope.SS({ inspectionLongModal: false, inspectionLongModalData: [] })}
    width={1200}
    zIndex={-2}
  >
    <FlexLayout
      isVertical={true}
      style={{ padding: 8 }}
    >
      <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

      <FlexLayout>
        <GridLayout
          ref={scope.inspectionLongGrid}
          header={[
            {
              id: 'actnmgroup',
              text: '그룹',
              isHidden: true,
              group: true,
              value: (x) => x.actnm,
            },
            {
              id: 'pernm',
              text: '담당자명',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 4,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumActnm = Sum.groupLength(data, 'actnm', x, ['actnm']);
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actnm} {sumActnm} 건
                  </FlexLayout>
                );
              },
              trail: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x?.length} 건
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'misdate',
              text: '미수일자',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '장기미수금액',
              width: 1,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
              sum: (x, data) => {
                const sumMisamt = Sum.byGroup(data, 'misamt', x, ['actnm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMisamt)}
                  </FlexLayout>
                );
              },
              trail: (data) => {
                const sumMisamt = Sum.all(data, 'misamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMisamt)}
                  </FlexLayout>
                );
              },
            },
          ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.inspectionLongInfinity}
            data={scope.state.inspectionLongModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.oldEqupmentModal}
      onClose={() => scope.SS({ oldEqupmentModal: false, oldEqupmentModalData: [] })}
      width={1200}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

        <FlexLayout>
          <GridLayout
            ref={scope.oldEqupmentGrid}
            header={[
              {
                id: 'pernm',
                text: '담당자명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
                trail: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x?.length} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '계획일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yyyymm2',
                text: '설치년도',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.yyyymm2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'gigan2',
                text: '년수',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gigan2}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun002',
                text: '정밀검사(회)',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gubun002}
                  </FlexLayout>
                ),
              },
              {
                id: 'yyyymm',
                text: '최초설치년도',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.yyyymm)}
                  </FlexLayout>
                ),
              },
              {
                id: 'gigan',
                text: '년수',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gigan}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.oldEqupmentInfinity}
            data={scope.state.oldEqupmentModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.breakDownModal}
      onClose={() => scope.SS({ breakDownModal: false, breakDownModalData: [] })}
      width={1400}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

        <FlexLayout isVertical={true}>
          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle2}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={1400}
          >
            <FlexLayout
              size={1600}
            >
            </FlexLayout>
          </GridLayoutAdditionalHeader>

          <GridLayout
            onHorizontalScroll={(x) => { scope.additionalTitle2.current?.scrollTo(x); }}
            ref={scope.breakDownGrid}
            header={[
              {
                id: 'pernm',
                text: '처리자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actpernm',
                text: '담당자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.comptime.substr(0, 2)}:{x.comptime.substr(2, 2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.arrivtime.substr(0, 2)}:{x.arrivtime.substr(2, 2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'resunm',
                text: '처리내용',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.resunm}
                  </FlexLayout>
                ),
              },
              {
                id: 'resuremark',
                text: '처리내용상세',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.resuremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'remonm',
                text: '고장요인',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remonm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remoremark',
                text: '고장요인상세',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remoremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'facnm',
                text: '고장원인',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.facnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'greginm',
                text: '고장부위',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.greginm}
                  </FlexLayout>
                ),
              },
              {
                id: 'reginm',
                text: '고장부위상세',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.reginm}
                  </FlexLayout>
                ),
              },
              {
                id: 'resultnm',
                text: '처리방법',
                width: 250,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resultnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'resultime',
                text: '대응시간',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {x.resultime} 분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {x.resulttime} 분
                  </FlexLayout>
                ),
              },
              {
                id: 'result',
                text: '진행내역',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.result === '0' ? '미완료' : '완료'}
                  </FlexLayout>
                ),
              },
            ]}
            contentWidth={1388}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.breakDownInfinity}
            data={scope.state.breakDownModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.checkModal}
      onClose={() => scope.SS({ checkModal: false, checkModalData: [] })}
      width={1200}
      height={700}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

        <FlexLayout>
          <GridLayout
            ref={scope.checkGrid}
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'state',
                text: '진행',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.state === '0' ? { color: 'var(--color-red)' }
                      : x.state === '1' ? { color: 'var(--color-green)' }
                        : x.state === '2' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }}
                  >
                    {x.state === '0' ? '만료'
                      : x.state === '1' ? '계획'
                        : x.state === '2' ? '검사' : '조치'}
                  </FlexLayout>
                ),
              },
              scope.state.popupTitles === '검사만료'
                ? {
                  id: 'enddate',
                  text: '만료일자',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Date8.withDash(x.enddate)}
                    </FlexLayout>
                  ),
                }
                : {
                  id: 'plandate',
                  text: '검사일자',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.plandate)}
                    </FlexLayout>
                  ),
                },
              {
                id: 'result',
                text: '결과',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.results?.filter((y) => y.com_code === x.result)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '구분',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.checkGubuns?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일자',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '검사자',
                width: 3,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcspnm',
                text: '검사기관',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.kcspnms?.filter((y) => y.com_code === x.kcspnm)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.checkInfinity}
            data={scope.state.checkModalData}
            onRowClick={(item) => scope.checkModalRowfocused(item)}
          >
          </GridLayout>

        </FlexLayout>

        <FlexLayout
          isVertical={true}
          minHeight={360}
        >
          <SubHeader>
            <FlexLayout
              align="center"
              style={{ color: 'var(--color-blue)' }}
            >
              검사이력정보
            </FlexLayout>
          </SubHeader>

          <GridLayout
            ref={scope.checkFocusedGrid}
            header={[
              {
                id: 'seq',
                text: '순서',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'state',
                text: '상태',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.state === '2' ? 'var(--color-blue)' : 'var(--color-gray)' }}
                  >
                    {checkModalStates?.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '계획일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '검사기관',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '검사구분',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {checkoModalGubuns?.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '운행시작일',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '운행종료일',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'result',
                text: '합격유무(결과)',
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    <FlexLayout
                      align='center'
                      justify='center'
                      style={{
                        color: x.result === '002' || x.result === '005' ? 'var(--color-red)' : 'var(--color-blue)',
                      }}
                    >
                      {checkModalResults?.filter((y) => y.value === x.result)[0]?.remark}
                      <CircleBadge>
                        {Number(x.failcnt) > 0 && <span
                          style={{ backgroundColor: 'var(--color-red)' }}
                        >
                        {Number(x.failcnt) > 0 ? x.failcnt : ''}
                      </span>}
                      </CircleBadge>
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.inspectModalData}
            onRowDoubleClick={(item) => scope.openInspectDetailModal(item)}
          />
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.SS({ checkModal: false, inspectModalData: [] })}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.inspectModal}
      onClose={() => scope.SS({ inspectModal: false, inspectionModalData: [] })}
      width={1000}
      zIndex={-2}
    >
      <LayoutTitle>상세현황</LayoutTitle>
      <FlexLayout style={{ flex: 0, marginBottom: 24, fontWeight: 1000 }}>
        <FlexLayout>*현장명 : {scope.state.checkModalFocused?.actnm}</FlexLayout>
        <FlexLayout>*호기명 : {scope.state.checkModalFocused?.equpnm}[{scope.state.checkModalFocused?.elno}]</FlexLayout>
      </FlexLayout>

      <FlexLayout style={{ flex: 0, marginBottom: 24, fontWeight: 1000 }}>
        <FlexLayout>*검사기관 : {scope.state.checkModalFocused?.kcpernm}</FlexLayout>
        <FlexLayout>*검사결과 : {checkModalResults?.filter((y) => y.value === scope.state.checkModalFocused?.result)[0]?.remark}</FlexLayout>
      </FlexLayout>

      <GridLayout
        ref={scope.inspectGrid}
        header={[
          {
            id: 'article',
            text: '검사항목',
            width: 30,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.article}
              </FlexLayout>
            ),
          },
          {
            id: 'title',
            text: '검사항목명',
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.title}
              </FlexLayout>
            ),
          },
          {
            id: 'descript',
            text: '불합내역',
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.descript}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '검사자 불합의견',
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
                style={{ color: 'var(--color-red)' }}>
                {x.remark}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.inspectModalDetailData}
      >
      </GridLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.SS({ inspectModal: false, inspectModalDetailData: [] })}>확인</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>

    <ModalView
      isVisible={scope.state.contractModal}
      onClose={() => scope.SS({ contractModal: false, contractModalData: [] })}
      width={1200}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

        <FlexLayout>
          <GridLayout
            ref={scope.contractGrid}
            header={[
              {
                id: 'pernm',
                text: '담당자명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
                trail: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x?.length} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.contgubuns?.filter((y) => y.code === x.contgubun)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '만료일',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '계약분류',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'state',
                text: '상태',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.state === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                  >
                    {x.state === '0' ? '미처리' : '처리'}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.contractInfinity}
            data={scope.state.contractModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.constructionModal}
      onClose={() => scope.SS({ constructionModal: false, constructionModalData: [] })}
      width={1200}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>{Date8.withDash(scope.state.stdate)} {scope.state.popupTitles} 상세현황</LayoutTitle>

        <FlexLayout>
          <GridLayout
            ref={scope.constructionGrid}
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'costdate',
                text: '견적일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.costdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '구분',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.consGubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'constnm',
                text: '공사명',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.constnm}
                  </FlexLayout>
                ),
                trail: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x?.length} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'costamt',
                text: '견적금액',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.costamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumCostamt = Sum.all(data, 'costamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumCostamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'compyn',
                text: '상태',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.compyn === '0' ? 'var(--color-black)'
                        : x.compyn === '1' ? 'var(--color-green)' : 'var(--color-blue)',
                    }}
                  >
                    {x.compyn === '0' ? '견적'
                      : x.compyn === '1' ? '공사' : '매출'}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '매출금액',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumMisamt = Sum.all(data, 'misamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMisamt)}
                    </FlexLayout>
                  );
                },
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.constructionInfinity}
            data={scope.state.constructionModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
