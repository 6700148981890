import * as React from 'react';
import { Button, FlexLayout, TableLayout } from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentDetailTableHeader } from '../headers/EnrollmentDetailTableHeader';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 탭 - 견적항목
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentTabDetailTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
      <FlexLayout />

      <FlexLayout size={150}>
        <Button
          isFull={true}
          onClick={() => scope.onClickDetailNew()}
        >
          추가
        </Button>

        <Button
          isFull={true}
          theme={BUTTON_THEMES.RED}
          onClick={() => scope.onClickDetailRemove()}
        >
          삭제
        </Button>
      </FlexLayout>
    </FlexLayout>

    <FlexLayout>
      <TableLayout
        ref={scope.tableDetail}
        header={EnrollmentDetailTableHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataDetail}
        onChange={(rows) => scope.onChangeTableDetail(rows)}
        onRowFocusEvent={(row, index) => scope.onDetailRowFocusEvent(row, index)}
      />
    </FlexLayout>
  </FlexLayout>
);
