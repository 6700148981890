import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e037w
 * @category 업무일지작성현황
 */

export class JournalStatementModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 직위
   *
   * **data type** varchar(40)
   */
  readonly rspnm: string;

  /**
   * 기준일
   *
   * **data type** varchar(40)
   */
  readonly deday: string;

  /**
   * 미작성일
   *
   * **data type** varchar(2)
   */
  readonly totnotday: string;

  /**
   * 작성일
   *
   * **data type** varchar(20)
   */
  readonly totday: string;


  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date3: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date4: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date5: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date6: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date7: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date8: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date9: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date10: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date11: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date12: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date13: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date14: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date15: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date16: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date17: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date18: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date19: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date20: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date21: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date22: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date23: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date24: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date25: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date26: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date27: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date28: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date29: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date30: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date31: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday1: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday2: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday3: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday4: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday5: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday6: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday7: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday8: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday9: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday10: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday11: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday12: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday13: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday14: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday15: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday16: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday17: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday18: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday19: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday20: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday21: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday22: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday23: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday24: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday25: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday26: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday27: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday28: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday29: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday30: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday31: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly seq: number;

  /**
   * 이동거리km
   *
   * **data type** number
   */
  totkm: number;

  /**
   * 합계
   *
   * **data type** number
   */
  totkm_tot: number;

  /**
   * 1일
   *
   * **data type** number
   */
  km1: number;

  /**
   * 2일
   *
   * **data type** number
   */
  km2: number;

  /**
   * 3일
   *
   * **data type** number
   */
  km3: number;

  /**
   * 4일
   *
   * **data type** number
   */
  km4: number;

  /**
   * 5일
   *
   * **data type** number
   */
  km5: number;

  /**
   * 6일
   *
   * **data type** number
   */
  km6: number;

  /**
   * 7일
   *
   * **data type** number
   */
  km7: number;

  /**
   * 8일
   *
   * **data type** number
   */
  km8: number;

  /**
   * 9일
   *
   * **data type** number
   */
  km9: number;

  /**
   * 10일
   *
   * **data type** number
   */
  km10: number;

  /**
   * 11일
   *
   * **data type** number
   */
  km11: number;

  /**
   * 12일
   *
   * **data type** number
   */
  km12: number;

  /**
   * 13일
   *
   * **data type** number
   */
  km13: number;

  /**
   * 14일
   *
   * **data type** number
   */
  km14: number;

  /**
   * 15일
   *
   * **data type** number
   */
  km15: number;

  /**
   * 16일
   *
   * **data type** number
   */
  km16: number;

  /**
   * 17일
   *
   * **data type** number
   */
  km17: number;

  /**
   * 18일
   *
   * **data type** number
   */
  km18: number;

  /**
   * 19일
   *
   * **data type** number
   */
  km19: number;

  /**
   * 20일
   *
   * **data type** number
   */
  km20: number;

  /**
   * 21일
   *
   * **data type** number
   */
  km21: number;

  /**
   * 22일
   *
   * **data type** number
   */
  km22: number;

  /**
   * 23일
   *
   * **data type** number
   */
  km23: number;

  /**
   * 24일
   *
   * **data type** number
   */
  km24: number;

  /**
   * 25일
   *
   * **data type** number
   */
  km25: number;

  /**
   * 26일
   *
   * **data type** number
   */
  km26: number;

  /**
   * 27일
   *
   * **data type** number
   */
  km27: number;

  /**
   * 28일
   *
   * **data type** number
   */
  km28: number;

  /**
   * 29일
   *
   * **data type** number
   */
  km29: number;

  /**
   * 30일
   *
   * **data type** number
   */
  km30: number;

  /**
   * 31일
   *
   * **data type** number
   */
  km31: number;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.totkm = data.totkm || '';
    this.totkm_tot = data.totkm_tot || '';
    this.km1 = data.km1 || '';
    this.km2 = data.km2 || '';
    this.km3 = data.km3 || '';
    this.km4 = data.km4 || '';
    this.km5 = data.km5 || '';
    this.km6 = data.km6 || '';
    this.km7 = data.km7 || '';
    this.km8 = data.km8 || '';
    this.km9 = data.km9 || '';
    this.km10 = data.km10 || '';
    this.km11 = data.km11 || '';
    this.km12 = data.km12 || '';
    this.km13 = data.km13 || '';
    this.km14 = data.km14 || '';
    this.km15 = data.km15 || '';
    this.km16 = data.km16 || '';
    this.km17 = data.km17 || '';
    this.km18 = data.km18 || '';
    this.km19 = data.km19 || '';
    this.km20 = data.km20 || '';
    this.km21 = data.km21 || '';
    this.km22 = data.km22 || '';
    this.km23 = data.km23 || '';
    this.km24 = data.km24 || '';
    this.km25 = data.km25 || '';
    this.km26 = data.km26 || '';
    this.km27 = data.km27 || '';
    this.km28 = data.km28 || '';
    this.km29 = data.km29 || '';
    this.km30 = data.km30 || '';
    this.km31 = data.km31 || '';
    this.spjangcd = data.spjangcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.deday = data.deday || '';
    this.totnotday = data.totnotday || '';
    this.totday = data.totday || '';
    this.date1 = data.date1 || '';
    this.date2 = data.date2 || '';
    this.date3 = data.date3 || '';
    this.date4 = data.date4 || '';
    this.date5 = data.date5 || '';
    this.date6 = data.date6 || '';
    this.date7 = data.date7 || '';
    this.date8 = data.date8 || '';
    this.date9 = data.date9 || '';
    this.date10 = data.date10 || '';
    this.date11 = data.date11 || '';
    this.date12 = data.date12 || '';
    this.date13 = data.date13 || '';
    this.date14 = data.date14 || '';
    this.date15 = data.date15 || '';
    this.date16 = data.date16 || '';
    this.date17 = data.date17 || '';
    this.date18 = data.date18 || '';
    this.date19 = data.date19 || '';
    this.date20 = data.date20 || '';
    this.date21 = data.date21 || '';
    this.date22 = data.date22 || '';
    this.date23 = data.date23 || '';
    this.date24 = data.date24 || '';
    this.date25 = data.date25 || '';
    this.date26 = data.date26 || '';
    this.date27 = data.date27 || '';
    this.date28 = data.date28 || '';
    this.date29 = data.date29 || '';
    this.date30 = data.date30 || '';
    this.date31 = data.date31 || '';
    this.weekday1 = data.weekday1 || '';
    this.weekday2 = data.weekday2 || '';
    this.weekday3 = data.weekday3 || '';
    this.weekday4 = data.weekday4 || '';
    this.weekday5 = data.weekday5 || '';
    this.weekday6 = data.weekday6 || '';
    this.weekday7 = data.weekday7 || '';
    this.weekday8 = data.weekday8 || '';
    this.weekday9 = data.weekday9 || '';
    this.weekday10 = data.weekday10 || '';
    this.weekday11 = data.weekday11 || '';
    this.weekday12 = data.weekday12 || '';
    this.weekday13 = data.weekday13 || '';
    this.weekday14 = data.weekday14 || '';
    this.weekday15 = data.weekday15 || '';
    this.weekday16 = data.weekday16 || '';
    this.weekday17 = data.weekday17 || '';
    this.weekday18 = data.weekday18 || '';
    this.weekday19 = data.weekday19 || '';
    this.weekday20 = data.weekday20 || '';
    this.weekday21 = data.weekday21 || '';
    this.weekday22 = data.weekday22 || '';
    this.weekday23 = data.weekday23 || '';
    this.weekday24 = data.weekday24 || '';
    this.weekday25 = data.weekday25 || '';
    this.weekday26 = data.weekday26 || '';
    this.weekday27 = data.weekday27 || '';
    this.weekday28 = data.weekday28 || '';
    this.weekday29 = data.weekday29 || '';
    this.weekday30 = data.weekday30 || '';
    this.weekday31 = data.weekday31 || '';
    this.seq = data.seq || 0;
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
