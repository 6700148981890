import * as React from 'react';
import { observer } from 'mobx-react';
import {
  AiOutlineClose,
  AiOutlineSave,
} from 'react-icons/ai';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { Global } from '../../constants';
import { Button } from '../../components/forms/Button';
import { TableLayout } from '../../components/layout/TableLayout';
import { CheckBox } from '../../components/forms/CheckBox';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';

interface ModalPeridProps {
  modalStore: ModalStore;
}

@observer
export class ModalPerid extends React.Component<ModalPeridProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={600}
        height={500}
        isVisible={modalStore.isVisiblePerid}
        onClose={() => modalStore.closeAccountSelector(false)}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            style={{
              marginBottom: 4,
            }}
          >
            <LayoutTitle weight={1}>
              {modalStore.peridTitle || '선택'}
            </LayoutTitle>

            <Button onClick={() => modalStore.closeAccountSelector(true)}>
              <AiOutlineSave style={{ marginLeft: 2 }} />
              <span>확인</span>
            </Button>

            <Button onClick={() => modalStore.closeAccountSelector(false)}>
              <AiOutlineClose style={{ marginLeft: 2 }} />
              <span>닫기</span>
            </Button>
          </FlexLayout>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={modalStore.peridSearchQuery}
                onChange={(value) => {
                  modalStore.peridSearchQuery = value;
                  modalStore.peridSearchPosition = -1;
                }}
                onEnter={() => modalStore.searchPerid()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button onClick={() => modalStore.searchPerid()}>조회</Button>
          </SearchLayout>

          <TableLayout
            ref={modalStore.peridTable}
            infinityHandler={modalStore.peridInfinity}
            header={[
              {
                id: 'check',
                text: (
                  <FlexLayout justify="center" align="center">
                    <CheckBox
                      noMargin
                      value={modalStore.peridTotalCheck}
                      onChange={async (value) => modalStore
                        .peridCheckAllToggle(value)}
                    />
                  </FlexLayout>
                ),
                width: 10,
                render: (x, rowUpdate) => (
                  <FlexLayout justify="center" align="center">
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '코드',
                width: 80,
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 120,
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={modalStore.peridData}
            onRowFocusEvent={(item) => {
              modalStore.peridSelected = item;
            }}
            onChange={(rows) => {
              modalStore.peridData = rows;
            }}
          />
        </FlexLayout>
      </ModalView>
    );
  }
}
