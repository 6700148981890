export class ActionItem {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 사용자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 계정
   *
   * **data type** varchar(8)
   */
  readonly userid: string;

  /**
   * IP
   *
   * **data type** varchar(8)
   */
  readonly ipaddr: string;

  /**
   * 윈도우
   *
   * **data type** varchar(8)
   */
  readonly window_name: string;

  /**
   * action/function
   *
   * **data type** varchar(8)
   */
  readonly type: string;

  /**
   * name
   *
   * **data type** varchar(8)
   */
  readonly action_name: string;

  /**
   * name
   *
   * **data type** varchar(8)
   */
  readonly function_name: string;

  /**
   * params
   *
   * **data type** varchar(8)
   */
  readonly data: string | Object;

  /**
   * datetime
   *
   * **data type** varchar(8)
   */
  readonly created: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.userid = data.userid || '';
    this.ipaddr = data.ipaddr || '';
    this.window_name = data.window_name || '';
    this.type = data.type || '';
    this.action_name = data.action_name || '';
    this.function_name = data.function_name || '';
    this.data = data.data || '';
    this.created = data.created || '';
  }
}
