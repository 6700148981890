import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { TellReceiveTemplate } from './TellReceive.template';
import { TellReceiveModel } from './TellReceive.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmDelete, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

export interface CostClauseListItem {
  custcd: string;
  spjangcd: string;
  gartcd: string;
  gartnm: string;
  new: string;
  artcd: string;
  seq: string;
}

export interface CostClauseDetailListItem {
  gartcd: string;
  new: string;
  artcd: string;
  artnm: string;
  useyn: string;
  callmemo: string;
  callendmemo: string;
}

interface CostClauseState {
  searchQuery: string;
  stdate?: string;
  enddate?: string;
  telcd: string;
  timeflag: string;
  regflag: string;
  telcds?: Array<any>;
  aiflag: string;
  costClauseList: Array<CostClauseListItem>;
  costClauseDetailList: Array<CostClauseDetailListItem>;
  focusedCostClause?: CostClauseListItem;
  data: TellReceiveModel;
  focused?: CostClauseDetailListItem;
}

/**
 * 컨트롤러
 * @window w_tb_callmainw
 * @category 전화수신목록
 */
export class TellReceive extends PageComponent<PageProps, CostClauseState>
  implements PageToolEvents {
  updatedRows?: Array<TellReceiveModel>;

  updatedRows2?: Array<CostClauseDetailListItem>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      costClauseList: [],
      costClauseDetailList: [],
      data: new TellReceiveModel(),
      searchQuery: '',
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      telcd: '%',
      timeflag: '%',
      regflag: '%',
      aiflag: '%',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 전화구분
    const datas = await api.dropdown('wf_dd_e902_02');

    if (!datas) return;
    this.setState({ telcds: datas.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        timeflag: this.state.timeflag,
        regflag: this.state.regflag,
        telcd: this.state.telcd,
        aiflag: this.state.aiflag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          costClauseList: [...this.state.costClauseList, ...items],
        });
      },
      async () => {
        await this.SS({
          costClauseList: [],
        });

        await this.infinity?.retrieveAll();
        this.table.current?.update(true);
        this.state.costClauseList.length > 0 && this.onRowFocusEvent(this.state.costClauseList[0]);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      costClauseList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.table.current?.update(true);
      this.state.costClauseList.length > 0 && this.onRowFocusEvent(this.state.costClauseList[0]);
    });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `${this.state.focusedCostClause?.gartcd} 코드 번호의 - ${this.state.focusedCostClause?.gartnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedCostClause) && this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.costClauseList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      timeflag: this.state.timeflag,
      regflag: this.state.regflag,
      telcd: this.state.telcd,
      aiflag: this.state.aiflag,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.costClauseList?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      timeflag: this.state.timeflag,
      regflag: this.state.regflag,
      telcd: this.state.telcd,
      aiflag: this.state.aiflag,
    });
  }


  @action
  onRowFocusEvent(item: CostClauseListItem) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState({
      focusedCostClause: item,
      costClauseDetailList: [],
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        this.state.focusedCostClause,
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            costClauseDetailList: [...this.state.costClauseDetailList,
              ...items.map((x: any) => new TellReceiveModel(x))],
          }, async () => {
            await this.tableDetail.current?.update(false);
          });
        },
        async () => {
          await this.SS({
            costClauseDetailList: [],
          });
          await this.infinity2?.retrieve();
        },
      );
      await this.infinity2.retrieve();
      this.tableDetail.current?.setFocus(0, 1);
    });
  }

  @action
  async onDetailRowFocusEvent(item: CostClauseDetailListItem) {
    this.setState({
      focused: item,
    });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        callnum: this.state.data.callnum,
      },
    );

    if (data) {
      const newData: CostClauseDetailListItem = {
        gartcd: data.gartcd,
        new: '1',
        artcd: data.artcd,
        artnm: data.artnm,
        useyn: data.useyn,
        callendmemo: data.callendmemo,
        callmemo: data.callmemo,
      };

      if (!this.state.costClauseDetailList) {
        this.setState({
          focused: newData,
          costClauseDetailList: [
            newData,
          ],
        }, async () => {
          await this.table.current?.update(true);
          this.table.current?.setFocus(this.state.costClauseDetailList.length - 1, 0);
        });
      } else {
        this.setState({
          focused: newData,
          costClauseDetailList: [
            ...this.state.costClauseDetailList,
            newData,
          ],
        }, async () => {
          await this.table.current?.update(true);
          this.table.current?.setFocus(this.state.costClauseDetailList.length - 1, 1);
        });
      }
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focused;

    if (focusedItem) {
      const text = `항목코드: ${this.state.focused?.artcd}, 항목명: ${this.state.focused?.artnm}`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            callnum: this.state.data.callnum,
            artcd: this.state.focused?.artcd,
          },
        );
        this.onRetrieveEvent();
        // eslint-disable-next-line no-template-curly-in-string
        ConfirmSuccess.show('삭제', `${focusedItem.artnm}를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <TellReceiveTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
        // @ts-ignore
        modelUpdate={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
