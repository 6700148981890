/**
 * 모델
 * @window w_tb_e035
 * @category 점검계획등록
 */
export default class PlanYetModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;


  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 현장담당자
   *
   * **data type** varchar(8)
   */
  readonly actpernm: string;

  /**
   * 체크
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   * 거래처
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 호기
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 점검기종
   *
   * **data type** varchar(8)
   */
  readonly evcd: string;

  /**
   * 휴대전화번호
   *
   * **data type** varchar(8)
   */
  readonly hp: string;

  /**
   * 점검일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid2: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.actpernm = data.actpernm || '';
    this.chk = data.chk || '';
    this.cltcd = data.cltcd || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.evcd = data.evcd || '';
    this.hp = data.hp || '';
    this.kcdate = data.kcdate || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
  }
}
