import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { SafetyManageModel } from './models/SafetyManage.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  ConfirmDelete,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { SafetyManageTemplate } from './SafetyManage.template';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';
import { SafetyManageDetailModel } from './models/SafetyManageDetail.model';

export interface SafetyManageListItem {
  custcd: string;
  spjangcd: string;
  gsafecd: string;
  gsafenm: string;
  safecd: string;
  new: string;
}

export interface SafetyManageListDetailItem {
  gsafecd: string;
  safecd: string;
  new: string;
  safenm: string;
}

interface SafetyManageState {
  searchQuery: string;
  timeList: Array<any>;
  focusedTime?: any;
  safetyManageList: Array<SafetyManageModel>;
  safetyManageDetailList: Array<SafetyManageDetailModel>;
  focusedSafetyManage?: SafetyManageModel;
  focusedDetailSafetyManage?: SafetyManageDetailModel;
  data: SafetyManageModel;
  focused?: SafetyManageModel;
}

/**
 * 컨트롤러
 * @window tb_e503
 * @category 안전관리코드등록
 */
export class SafetyManage extends PageComponent<PageProps, SafetyManageState>
  implements PageToolEvents {
  updatedRows?: Array<SafetyManageModel>;

  updatedRows2?: Array<SafetyManageDetailModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  tableIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      data: new SafetyManageModel(),
      safetyManageDetailList: [],
      safetyManageList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          safetyManageList: [...this.state.safetyManageList, ...items],
        });
      },
      async () => {
        await this.SS({
          safetyManageList: [],
        });

        await this.infinity?.retrieve();
        if (this.state.safetyManageList.length > 0) {
          this.table.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      safetyManageList: [],
    });
    if (!autoLoad) return;
    await this.infinity?.retrieve();
    const index = await this.infinity?.retrieveTo('safecd', this.state.focusedSafetyManage?.safecd, type) || 0;
    this.state.safetyManageList.length > index && this.table.current?.setFocus(index);
  }

  @action
  onRowFocusEvent(item: SafetyManageModel, index: number) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];
    this.tableIndex = index;

    this.setState({ focusedSafetyManage: item, safetyManageDetailList: [] }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        this.state.focusedSafetyManage,
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            safetyManageDetailList:
              [...this.state.safetyManageDetailList, ...items.map((x: any) => new SafetyManageDetailModel(x))],
          }, async () => {
            await this.tableDetail.current?.update(false);
          });
        },
        async () => {
          await this.SS({
            safetyManageDetailList: [],
          });

          // @ts-ignore
          await this.infinity2.retrieve();
          this.tableDetail.current?.setFocus(0, 1);
        },
      );

      await this.infinity2.retrieve();
      await this.tableDetail.current?.update(true);
      this.tableDetail.current?.setFocus(0, 1);
    });
  }

  @action
  async onDetailRowFocusEvent(item: SafetyManageDetailModel) {
    this.setState({
      focusedDetailSafetyManage: item,
    });
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      data.new = '1';
      this.setState({
        safetyManageList: [
          data,
          ...this.state.safetyManageList,
        ],
        safetyManageDetailList: [],
      }, async () => {
        await this.table.current?.update(false);
        await this.tableDetail.current?.update(true);
        await this.table.current?.setFocus(0, 1);
        this.setState({ focusedSafetyManage: data });
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
      items2: this.updatedRows2,
      gsafenm: this.state.focusedSafetyManage?.gsafenm,
    }, true)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      await this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `안전그룹코드: ${this.state.focusedSafetyManage?.gsafecd
    }, 안전그룹명: ${this.state.focusedSafetyManage?.gsafenm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedSafetyManage) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.safetyManageList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.safetyManageList?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        gsafecd: this.state.focusedSafetyManage?.gsafecd,
      },
    );

    if (data) {
      const newData = {
        custcd: data.custcd,
        inperid: data.inperid,
        indate: data.indate,
        isNew: true,
        remark: data.remark,
        gsafecd: data.gsafecd,
        new: '1',
        safecd: data.safecd,
        safenm: data.safenm,
      };

      this.setState({
        data: new SafetyManageModel(data),
        focusedDetailSafetyManage: newData,
        safetyManageDetailList: [
          newData,
          ...this.state.safetyManageDetailList,
        ],
      }, async () => {
        this.tableDetail.current?.update(true);
        await this.tableDetail.current?.setFocus(0, 0);
      });
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focusedDetailSafetyManage;

    if (focusedItem) {
      const text = `안전코드: ${focusedItem.safecd}, 안전내용: ${focusedItem.safenm} 을(를) 삭제하시겠습니까?`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            gsafecd: this.state.focusedSafetyManage?.gsafecd,
            gsafenm: this.state.focusedSafetyManage?.gsafenm,
            safecd: this.state.focusedDetailSafetyManage?.safecd,
          },
        );
        this.onRetrieveEvent();
        ConfirmSuccess.show('삭제', `${focusedItem.safenm} 를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: Array<SafetyManageModel>, updatedRows: Array<SafetyManageModel>) {
    this.updatedRows = updatedRows;
    this.setState({ safetyManageList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<SafetyManageDetailModel>, updatedRows2: Array<SafetyManageDetailModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ safetyManageDetailList: rows2 });
  }

  render() {
    return (
      <SafetyManageTemplate
        scope={this}
        update={(change) => {
          this.setState({
            ...this.state.safetyManageList,
            ...this.state.safetyManageDetailList,
            ...this.state.focusedSafetyManage,
            ...change,
          });
        }}
      />
    );
  }
}
