import { action } from 'mobx';
import * as React from 'react';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PlanTemplate } from './Plan.template';
import { PlanModel } from './Plan.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import Confirm from '../../../../utils/confirm/Confirm';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface PlanState {
  year: string;
  bungi: string;
  gubun: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // data
  data: Array<PlanModel>;
  focused?: PlanModel;

  // 계획내용팝업
  detailModal: boolean;
  remark: string;

  // 월팝업
  monthModal: boolean;
  yearmon: string;

  // 진행내용 팝업
  progressModal: boolean;
  content: string;

  // trail
  samt01_tot: string;
  samt02_tot: string;
  samt03_tot: string;
  costamt01_tot: string;
  costamt02_tot: string;
  costamt03_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_09
 * @category 계획대비추진리스트
 */
export class Plan extends PageComponent<PageProps, PlanState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      bungi: '1',
      gubun: '12',
      divicd: '',
      perid: '',
      data: [],
      yearmon: Today.yearMon(),

      // trail
      samt01_tot: '0',
      samt02_tot: '0',
      samt03_tot: '0',
      costamt01_tot: '0',
      costamt02_tot: '0',
      costamt03_tot: '0',
      total: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        bungi: this.state.bungi,
        gubun: this.state.gubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new PlanModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.grid.current?.setFocus(0);

      this.SS({
        samt01_tot: this.infinity?.box?.samt01_tot || '0',
        samt02_tot: this.infinity?.box?.samt02_tot || '0',
        total: this.infinity?.box?.total || '0',
        samt03_tot: this.infinity?.box?.samt03_tot || '0',
        costamt01_tot: this.infinity?.box?.costamt01_tot || '0',
        costamt02_tot: this.infinity?.box?.costamt02_tot || '0',
        costamt03_tot: this.infinity?.box?.costamt03_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent(item: PlanModel) {
    this.setState({
      focused: item,
      remark: item?.remark,
      content: item?.state_remark,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      bungi: this.state.bungi,
      gubun: this.state.gubun,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      bungi: this.state.bungi,
      gubun: this.state.gubun,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  onDetailModal(isOpen: boolean) {
    this.setState({ detailModal: isOpen });
  }

  @action
  async onDetailPopup() {
    const { actionStore: api } = this.props;

    await api.fxExec(
      'save',
      {
        sub: 'w_popup_da052w_09_remark',
        stmon: this.state.focused?.mon,
        gubun: this.state.gubun,
        seq: this.state.focused?.seq,
        remark: this.state.remark,
        perid: this.state.focused?.perid,
      },
    );
    this.onDetailModal(false);
    this.onRetrieveEvent();
  }

  @action
  onMonthModal(isOpen: boolean) {
    this.setState({ monthModal: isOpen });
  }

  @action
  async onMonthPopup() {
    const { actionStore: api } = this.props;

    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인', '위 내용으로 계획 월을 이동(수정)하시겠습니까? ', ConfirmType.QUESTION);
    if (warning === false) return;

    await api.fxExec(
      'save',
      {
        sub: 'w_popup_da052w_09_month',
        stmon: this.state.focused?.mon,
        nmon: this.state.yearmon,
        gubun: this.state.gubun,
        seq: this.state.focused?.seq,
        perid: this.state.focused?.perid,
      },
    );
    this.onMonthModal(false);
    this.onRetrieveEvent();
  }

  @action
  onProgressModalModal(isOpen: boolean) {
    this.setState({ progressModal: isOpen });
  }

  @action
  async onProgressPopup() {
    const { actionStore: api } = this.props;

    await api.fxExec(
      'save',
      {
        sub: 'w_popup_da052w_09_state_remark',
        stmon: this.state.focused?.mon,
        gubun: this.state.gubun,
        seq: this.state.focused?.seq,
        state_remark: this.state.content,
        perid: this.state.focused?.perid,
      },
    );
    this.onProgressModalModal(false);
    this.onRetrieveEvent();
  }

  render() {
    return (
      <PlanTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
