import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, FlexLayout, ModalView } from '../../components';
import { ModalStore } from '../../stores';

interface ModalElmNoticeProps {
  modalStore: ModalStore;
}

@observer
export class ModalElmNotice extends React.Component<ModalElmNoticeProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={677}
        height={886}
        style={{
          minHeight: 886,
        }}
        isVisible={modalStore.isVisibleModalElmNotice}
        onClose={() => { modalStore.isVisibleModalElmNotice = false; }}
      >
        <FlexLayout
          isVertical={true}
          style={{
            background: 'url(https://api.elmansoft.com/img/elm-notice.png) no-repeat',
          }}
        >
          <FlexLayout
            style={{
              position: 'absolute',
              bottom: 80,
              left: 114,
              right: 114,
              height: 49,
              width: 'initial',
            }}
          >
            <Button
              isFull={true}
              onClick={() => window.open('https://api.elmansoft.com/elman-manager-installer.msi')}
            >
              설치파일 다운로드
            </Button>

            <Button
              isFull={true}
              onClick={() => modalStore.reTryElmanManagerNotice()}
            >
              다시 실행
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
