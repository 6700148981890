import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { DepartmentCodeModel } from './DepartmentCode.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { DepartmentCodeTemplate } from './DepartmentCode.template';
import { ModalStore } from '../../../../stores';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface DepartmentCodeState {
  searchQuery: string;
  data: Array<DepartmentCodeModel>;
  focused?: DepartmentCodeModel;
  divicds?: DepartmentCodeModel;
  dz_dept_cd?: string;
  dz_flag?: string;
}

/**
 * 컨트롤러
 * @window w_jc002
 * @category 부서코드
 */
export class DepartmentCode extends PageComponent<PageProps, DepartmentCodeState>
  implements PageToolEvents {
  updatedRows?: Array<DepartmentCodeModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  modalStore?: ModalStore;

  cdData: string;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
    this.cdData = '';
  }

  @action
  async onFirstOpenEvent() {
    // await this.open();
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new DepartmentCodeModel(x))],
        }, async () => {
          this.table.current?.update();
          await this.table.current?.setFocus(0, 2);
        });
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        this.state.data.length > 0 && await this.table.current?.setFocus(0, 2);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    const data = await this.infinity?.retrieve();
    this.setState({
      dz_dept_cd: data.dz_dept_cd,
    });
    await this.table.current?.update(true);
    this.state.data.length > 0 && await this.table.current?.setFocus(0, 2);
  }

  @action
  async open() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('open',
      {
      });

    this.setState({ dz_flag: data.dz_flag });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new();
    data && this.setState({
      data: [...this.state.data, new DepartmentCodeModel(data)],
    }, async () => {
      this.state.data[this.state.data.length - 1].divicd = data.divigb;
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.data.length - 1, 1);
    });
    // @ts-ignore
    this.state.data[this.state.data.length - 1].newCount = '1';
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `부서코드: ${this.state.focused?.divicd} 부서명: ${this.state.focused?.divinm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: DepartmentCodeModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<DepartmentCodeModel>, updatedRows: Array<DepartmentCodeModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <DepartmentCodeTemplate
      scope={this}
    />;
  }
}
