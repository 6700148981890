import { GLHF } from '../../../../../constants';
import { MonthOil } from '../MonthOil';
import MonthOilModel from '../models/MonthOilModel';

/**
 * 화면 > 탭  > 그리드
 * @window w_tb_ca640_pa105_01w
 * @category 월유류비내역
 */
export const LastMonthGrid: GLHF<MonthOilModel, MonthOil> = () => ([
]);
