import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da026_import
 * @category 지로자동이체
 */
export class JiroAutoModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 파일명
   *
   * **data type** varchar(20)
   */
  filename: string;

  /**
   * 저장일자
   *
   * **data type** varchar(8)
   */
  savedate: string;

  /**
   * 건수
   *
   * **data type** float
   */
  count: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 수금여부
   *
   * **data type** varchar(1)
   */
  rcvflag: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 선택
   *
   * **data type** varchar(2)
   */
  chk: string;

  /**
   * 순번
   *
   * **data type** varchar(10)
   */
  seq: string;

  /**
   * 순번
   *
   * **data type** varchar(30)
   */
  vseq: string;

  /**
   * 고객조회번호
   *
   * **data type** varchar(15)
   */
  cltnum: string;

  /**
   * 고객명
   *
   * **data type** char(15)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 발행년월
   *
   * **data type** varchar(6)
   */
  mismon: string;

  /**
   * 청구일
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 납입금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 연결거래처코드
   *
   * **data type** varchar(8)
   */
  miscltcd: string;

  /**
   * 지로거래처코드
   *
   * **data type** varchar(8)
   */
  jcltcd: string;

  /**
   * 납입일
   *
   * **data type** varchar(8)
   */
  sunapdate: string;

  /**
   * 이체일
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  rcvnum: string;

  /**
   * 이체금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 수수료
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 순번
   *
   * **data type** varchar(30)
   */
  seqnum: string;

  /**
   * 수납구분
   *
   * **data type** varchar(2)
   */
  misflag: string;

  /**
   * 미수잔액
   *
   * **data type** number
   */
  misuamt: string;

  /**
   * 결재상태
   *
   * **data type** varchar(8)
   */
  appgubun: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.filename = data.filename || '';
    this.appgubun = data.appgubun || '';
    this.savedate = data.savedate || '';
    this.count = data.count || '';
    this.remark = data.remark || '';
    this.rcvflag = data.rcvflag || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.chk = data.chk || '';
    this.seq = data.seq || '';
    this.vseq = data.vseq || '';
    this.cltnum = data.cltnum || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.mismon = data.mismon || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.misamt = data.misamt || '';
    this.miscltcd = data.miscltcd || '';
    this.jcltcd = data.jcltcd || '';
    this.sunapdate = data.sunapdate || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvnum = data.rcvnum || '';
    this.rcvamt = data.rcvamt || '';
    this.tamt = data.tamt || '';
    this.seqnum = data.edate || '';
    this.misflag = data.misflag || '';
    this.misamt = data.misamt || '';
    this.misuamt = data.misuamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
