import * as React from 'react';
import {
  FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentTabConditionsTableHeader } from '../headers/EnrollmentTabConditionsTableHeader';

/**
 * 화면 > 탭 - 결제조건
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTabConditionsTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.tableConditions}
      header={EnrollmentTabConditionsTableHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataConditions}
      onChange={(rows, updatedRows) => scope.onChangeTableConditions(rows, updatedRows)}
      onRowFocusEvent={(row, index) => scope.onRowFocusConditions(row, index)}
    />
  </FlexLayout>
);
