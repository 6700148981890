import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { GoalResultTemplate } from './GoalResult.template';
import { GoalResultModel } from './GoalResult.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface GoalResultState {
  year: string;
  perid: string;
  pernm: string;

  // data
  GoalResultList: Array<GoalResultModel>;
  data: GoalResultModel;
  focused?: GoalResultModel;

  // trail
  planamt_tot: string;
  totamt_tot: string;
  amt11_tot: string;
  amt12_tot: string;
  amt13_tot: string;
  amt14_tot: string;
  amtelse_tot: string;
  qty_tot: string;
  yul_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_01
 * @category 목표대비실적[개인별]
 */
export class GoalResult extends PageComponent<PageProps, GoalResultState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const { user } = this.props.publicStore;

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      perid: user.perid,
      pernm: user.pernm,
      GoalResultList: [],

      // trail
      planamt_tot: '0',
      totamt_tot: '0',
      amt11_tot: '0',
      amt12_tot: '0',
      amt13_tot: '0',
      amt14_tot: '0',
      amtelse_tot: '0',
      qty_tot: '0',
      yul_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          GoalResultList: [
            ...this.state.GoalResultList,
            ...items.map((x: any) => new GoalResultModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          GoalResultList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.GoalResultList && this.state.GoalResultList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      GoalResultList: [],
    }, async () => {
      await this.infinity?.retrieveAll();

      this.SS({
        planamt_tot: this.infinity?.box?.planamt_tot || '0',
        totamt_tot: this.infinity?.box?.totamt_tot || '0',
        amt11_tot: this.infinity?.box?.amt11_tot || '0',
        amt12_tot: this.infinity?.box?.amt12_tot || '0',
        amt13_tot: this.infinity?.box?.amt13_tot || '0',
        amt14_tot: this.infinity?.box?.amt14_tot || '0',
        amtelse_tot: this.infinity?.box?.amtelse_tot || '0',
        qty_tot: this.infinity?.box?.qty_tot || '0',
        yul_tot: this.infinity?.box?.yul_tot || '0',
      });

      if (this.state.GoalResultList && this.state.GoalResultList?.length > 0) {
        await this.grid.current?.setFocus(0);
        this.onRetrieveEvent2();
      }
    });
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve(
      {
        year: this.state.year,
        perid: this.state.perid,
      },
    );

    if (data) {
      this.setState({
        data,
      });
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.GoalResultList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.GoalResultList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      perid: this.state.perid,
    });
  }

  render() {
    return (
      <GoalResultTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
