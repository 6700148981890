import { computed } from 'mobx';

/**
 * 수금내역 모델
 * @window w_tb_da003_01w
 * @category 프로젝트현황
 */
export class CollectionModel {
  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** char(4)
   */
  misnum: string;

  /**
   * 순번
   *
   * **data type** char(3)
   */
  seq: string;

  /**
   * 계정과목
   *
   * **data type** varchar(20)
   */
  acccd: string;

  /**
   * 품목명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** char(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 매입가액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 전자세금계산서
   *
   * **data type** char(50)
   */
  recivstatus: string;

  /**
   * 수금액
   *
   * **data type** char(50)
   */
  rcvamt: string;

  /**
   * 잔액
   *
   * **data type** char(50)
   */
  iamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.seq = data.seq || '';
    this.acccd = data.acccd || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.addamt = data.addamt || '';
    this.misamt = data.misamt || '';
    this.rcvamt = data.rcvamt || '';
    this.recivstatus = data.recivstatus || '';
    this.iamt = data.iamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
