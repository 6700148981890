import axios from 'axios/index';
import { ApiService } from '../utils/string';

class MenuRepository {
  URL: string;

  constructor() {
    this.URL = `${process.env.REACT_APP_API_SERVICE_HOST}`;
  }

  fetch(params: any) {
    return axios.post(
      `${this.URL}${ApiService.make(
        'common',
        'w_xusers',
        'action',
        'retrieve',
      )}`,
      params,
    );
  }
}

export default new MenuRepository();
