import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdReorder,
  MdPriorityHigh,
  MdUpdate,
  MdPanTool,
  MdReportProblem,
  MdPlaylistAddCheck,
} from 'react-icons/md';
import {
  PageProps,
  PageToolEvents,
  TableLayoutHeader,
} from '../../../../constants';
import { FailureTemplate } from './Failure.template';
import {
  FailureDetailModel,
  FailureFactorModel,
  FailurePartModel,
  FailurePartModel2,
  ProcessingDetailModel,
  ProcessingResultModel,
  FailureCauseModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { ConfirmWarning } from '../../../../utils/confirm';
import { TextBox } from '../../../../components/forms/TextBox';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Finder } from '../../../../utils/array';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';


export enum FailureTabId {
  F_Detail,
  Factor,
  Part,
  P_Detail,
  Result,
  Cause,
}

export const FailureTabTitles = [
  '고장내용',
  '고장요인',
  '고장부위',
  '처리내용',
  '처리결과',
  '고장원인',
];

export const FailureTabModels = [
  FailureDetailModel,
  FailureFactorModel,
  FailurePartModel,
  ProcessingDetailModel,
  ProcessingResultModel,
  FailureCauseModel,
];

export const FailureTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
];

export const FailureTabDataStateNames = [
  'fdetails',
  'factors',
  'parts',
  'pdetails',
  'results',
  'causes',
];

export const FailureTabUpdatesStateNames = [
  'fdetailUpdates',
  'factorUpdates',
  'partUpdates',
  'pdetailUpdates',
  'resultUpdates',
  'causeUpdates',
];

export const FailureTabFocusedStateNames = [
  'fdetailsFocused',
  'factorsFocused',
  'partsFocused',
  'pdetailsFocused',
  'resultsFocused',
  'causesFocused',
];

export interface FailureState {
  // 고장내용
  fdetails?: Array<FailureDetailModel>;
  fdetailUpdates?: Array<FailureDetailModel>;
  fdetailsFocused?: FailureDetailModel;

  // 고장요인
  factors?: Array<FailureFactorModel>;
  factorUpdates?: Array<FailureFactorModel>;
  factorsFocused?: FailureFactorModel;

  // 고장부위
  parts?: Array<FailurePartModel>;
  partUpdates?: Array<FailurePartModel>;
  partsFocused?: FailurePartModel;
  partsDetail?: Array<FailurePartModel2>
  partsDetailUpdates?: Array<FailurePartModel2>;
  partsFocusedDetail?: FailurePartModel2;

  // 처리내용
  pdetails?: Array<ProcessingDetailModel>;
  pdetailUpdates?: Array<ProcessingDetailModel>;
  pdetailsFocused?: ProcessingDetailModel;

  // 처리결과
  results?: Array<ProcessingResultModel>;
  resultUpdates?: Array<ProcessingResultModel>;
  resultsFocused?: ProcessingResultModel;

  // 고장원인
  causes?: Array<FailureCauseModel>;
  causeUpdates?: Array<FailureCauseModel>;
  causesFocused?: FailureCauseModel;

  focusedTab?: TabModel;
}

/**
 * 컨트롤러
 * @window w_tb_e010
 * @category 고장관리코드
 */
export class Failure extends PageComponent<PageProps, FailureState>
  implements PageToolEvents {
  updatedRows2?: Array<FailurePartModel2>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout>;

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = FailureTabId;
    const titles = FailureTabTitles;

    this.tabs = [
      new TabModel(id.F_Detail.toString(), MdReorder, titles[id.F_Detail]),
      new TabModel(id.Factor.toString(), MdPriorityHigh, titles[id.Factor]),
      new TabModel(id.Part.toString(), MdPanTool, titles[id.Part]),
      new TabModel(id.P_Detail.toString(), MdPlaylistAddCheck, titles[id.P_Detail]),
      new TabModel(id.Result.toString(), MdUpdate, titles[id.Result]),
      new TabModel(id.Cause.toString(), MdReportProblem, titles[id.Cause]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };

    this.table = React.createRef();

    this.tableDetail = React.createRef();

    this.tabHeaders = [
      // 고장내용
      [
        {
          id: 'contcd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.contcd}
              onChange={(value) => rowUpdate({ contcd: value })}
              readonly={x.new === '0'}
            />
          ),
        },
        {
          id: 'contnm',
          text: '고장내용',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.contnm}
              onChange={(value) => rowUpdate({ contnm: value })}
            />
          ),
        },
        {
          id: 'stopflag',
          text: '승강기정지여부',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.stopflag === '1'}
              onChange={(value) => rowUpdate({ stopflag: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 고장요인
      [
        {
          id: 'remocd',
          text: '코드',
          width: 50,
        },
        {
          id: 'remonm',
          text: '고장요인',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remonm}
              onChange={(value) => rowUpdate({ remonm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 고장부위
      [
        {
          id: 'gregicd',
          text: '코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.gregicd}
              onChange={(value) => rowUpdate({ gregicd: value })}
              readonly={x?.new !== '1'}
              transparent={true}
              textAlign="center"
            />
          ),
        },
        {
          id: 'greginm',
          text: '고장부위명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.greginm}
              onChange={(value) => rowUpdate({ greginm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 처리내용
      [
        {
          id: 'resucd',
          text: '코드',
          width: 50,
        },
        {
          id: 'resunm',
          text: '엘리베이터용도명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.resunm}
              onChange={(value) => rowUpdate({ resunm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 처리결과
      [
        {
          id: 'resultcd',
          text: '코드',
          width: 50,
        },
        {
          id: 'resultnm',
          text: '처리결과명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.resultnm}
              onChange={(value) => rowUpdate({ resultnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 고장원인
      [
        {
          id: 'faccd',
          text: '코드',
          width: 50,
        },
        {
          id: 'facnm',
          text: '고장원인명',
          width: 150,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.facnm}
              onChange={(value) => rowUpdate({ facnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 300,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],
    ];
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[FailureTabId.F_Detail]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    this.updatedRows2 = [];

    await this.doRetrieve(i);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `${FailureTabFunctionNames[i]}_new`,
    );

    // @ts-ignore
    data && this.setState({
      [FailureTabDataStateNames[i]]: [
        // @ts-ignore
        ...this.state[FailureTabDataStateNames[i]],
        new FailureTabModels[i](data, true),
      ],
    }, async () => {
      await this.table.current?.update(false);
      // @ts-ignore
      this.table.current?.setFocus(this.state[FailureTabDataStateNames[i]].length - 1, 1);
    });
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;

    const partsDetail = await api.fxExec(
      'tab_3_dw_2_new',
      {
        gregicd: this.state.partsFocused?.gregicd,
        greginm: this.state.partsFocused?.greginm,
      },
    );

    partsDetail && this.setState({
      partsDetail: [...(this.state.partsDetail || []), new FailurePartModel2(partsDetail)],
    }, async () => {
      await this.tableDetail.current?.update(false);
      if (this.state.partsDetail?.length) {
        this.tableDetail.current?.setFocus(this.state.partsDetail.length - 1, 1);
      }
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    let sameContCheck = false;
    console.log(this.state.fdetailsFocused?.contcd);
    return;

    if (this.tabIndex === 0) {
      // @ts-ignore
      this.state[FailureTabDataStateNames[0]].forEach((x) => {
        if (x.contcd === this.state.fdetailsFocused?.contcd) {
          sameContCheck = true;
        }
      });
    }

    if (sameContCheck) {
      ConfirmWarning.show('경고', '코드번호가 중복입니다');
      return;
    }

    await api.fxSave(
      `${FailureTabFunctionNames[i]}_save`,
      {
        // @ts-ignore
        items: i === 2 ? [this.state.partsFocused] : this.state[FailureTabUpdatesStateNames[i]],
        items2: this.updatedRows2,
      },
      true,
    );
    this.updatedRows2 = [];
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[FailureTabFocusedStateNames[i]];

    if (!item) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case FailureTabId.F_Detail:
        text = `${item.contcd} - ${item.contnm}`;
        break;

      case FailureTabId.Factor:
        text = `${item.remocd} - ${item.remonm}`;
        break;

      case FailureTabId.Part:
        text = `${item.gregicd} - ${item.greginm}`;
        break;

      case FailureTabId.P_Detail:
        text = `${item.resucd} - ${item.resunm}`;
        break;

      case FailureTabId.Result:
        text = `${item.resultcd} - ${item.resultnm}`;
        break;

      case FailureTabId.Cause:
        text = `${item.faccd} - ${item.facnm}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }

    await api.fxDelete(
      `${FailureTabFunctionNames[i]}_delete`,
      text,
      // @ts-ignore
      this.state[FailureTabFocusedStateNames[i]],
    );

    this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    const text = `${this.state.partsFocusedDetail?.regicd} - ${this.state.partsFocusedDetail?.reginm}`;
    await api.fxDelete(
      'dw_2_delete',
      text,
      this.state.partsFocusedDetail,
    ) && this.onRowFocusEvent(this.state.partsFocused!);

    this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[FailureTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${FailureTabFunctionNames[i]}_print`,
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[FailureManagementCodeTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${FailureTabFunctionNames[i]}_excel`,
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    // if (this.state.focusedTab
    //   // @ts-ignore
    //   && (this.state[FailureTabUpdatesStateNames[i]]?.length || 0) > 0) {
    //   const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
    //   if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
    //     this.setState({
    //       [FailureTabUpdatesStateNames[i]]: [],
    //       [FailureTabFocusedStateNames[i]]: undefined,
    //     });
    //   } else {
    //     return;
    //   }
    // }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: FailureTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => api.fxExec(
        `${FailureTabFunctionNames[i]}_retrieve`,
        params,
      ),
      async (items) => {
        if (items) {
          await this.SS({
            [FailureTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[FailureTabDataStateNames[i]],
                ...items.map((x: any) => new FailureTabModels[i](x))],
          });
          await this.table.current?.update(false);
        }
      },
      async () => {
        await this.SS({
          [FailureTabDataStateNames[i]]: [],
        });

        await this.table.current?.update(true);
        await this.infinity?.retrieve();
        this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [FailureTabDataStateNames[i]]: [],
      [FailureTabUpdatesStateNames[i]]: [],
      [FailureTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.table.current?.update(true);
      await this.infinity?.retrieve();
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  onRowFocusEvent(item: FailurePartModel) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState({ partsFocused: item }, async () => {
      const partsDetail = await api.fxExec('tab_3_dw_1_RowFocuschanged', this.state.partsFocused);
      if (partsDetail?.items) {
        this.setState({
          partsDetail: partsDetail.items.map((x: any) => new FailurePartModel2(x)),
        }, () => this.tableDetail.current?.update());
      } else {
        this.setState({
          partsDetail: [],
        }, () => this.tableDetail.current?.update());
      }
    });
  }

  @action
  onRowFocusEvent2(item: FailurePartModel2) {
    if (item.new === '1') return;
    this.setState({ partsFocusedDetail: item });
  }

  @computed
  get tabIndex(): FailureTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [FailureTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [FailureTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows2
   * @param updatedRows2
   */
  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({
      partsDetail: rows2,
    });
  }

  render() {
    return (
      <FailureTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
        requestRowFocusEvent={() => {
          this.state.parts && this.onRowFocusEvent(this.state.parts[Finder.index(
            this.state.parts,
            this.state.partsFocused,
            'gregicd',
          )]);
        }}
      />
    );
  }
}
