import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Company } from './Company';
import { Format } from '../../../../utils/string';
import { Sum } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_da050
 * @category 회사목표계획등록
 */
export const CompanyTemplate: React.FC<TemplateProps<Company>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onCopyBtn()}>
            <div style={{ width: 80 }}>전체복사</div>
          </Button>

          <Button onClick={() => scope.onEndBtn()}>
            <div style={{ width: 80 }}>마감</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'planmon',
                text: '월',
                width: 20,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##"
                    value={x.planmon}
                  />
                ),
              },
              {
                id: 'samt',
                text: '목표금액',
                width: 30,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.samt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '매출금액',
                width: 30,
                trail: () => (
                  <FlexLayout
                    style={{ color: '#3BE7FF' }}
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'notamt',
                text: '차액(비달성금액)',
                width: 30,
                trail: () => (
                  <FlexLayout
                    style={{ color: '#FF18B8' }}
                    justify="right"
                    align="center"
                  >
                    {scope.state.notamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.notamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yul',
                text: '달성률',
                width: 15,
                trail: () => (
                    <FlexLayout
                      style={{ color: '#FFFF00' }}
                      justify="center"
                      align="center"
                    >
                      {scope.state.yul_tot}
                    </FlexLayout>
                ),
                render: (x) => {
                  const yul = parseFloat(x.yul).toFixed(2);
                  return (
                    <FlexLayout
                      style={{ color: '#BD3895' }}
                      justify="center"
                      align="center"
                    >
                      {Format.number(yul)} %
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'remark',
                text: '비고',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'gubunnm',
                text: '매출구분',
                width: 20,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.gubunnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'samt',
                text: '목표금액',
                width: 30,
                trail: (data) => {
                  const sumBesamt = Sum.all(data, 'samt');
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumBesamt)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                render: (x, rowUpdate, ref) => (
                  <FormatNumberTextBox
                    ref={ref}
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.samt}
                    onChange={(value) => rowUpdate({ samt: value })}
                  />
                ),
              },
              {
                id: 'misamt',
                text: '매출금액',
                width: 30,
                trail: (data) => {
                  const sumSamt = Sum.all(data, 'misamt');
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        style={{ color: '#3BE7FF' }}
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumSamt)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'notamt',
                text: '차액(비달성금액)',
                width: 30,
                trail: (data) => {
                  const sumSamt = Sum.all(data, 'samt');
                  const sumMisamt = Sum.all(data, 'misamt');
                  const notamt_tot = sumSamt - sumMisamt;
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        style={{ color: '#FF18B8' }}
                        justify="right"
                        align="center"
                      >
                        {Format.number(notamt_tot)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.notamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yul',
                text: '달성률',
                width: 15,
                trail: () => (
                  <FlexLayout
                    style={{ color: '#FFFF00' }}
                    justify="center"
                    align="center"
                  >
                    {Format.number(Number.parseFloat(scope.state.detail_yul_tot).toFixed(2))} %
                  </FlexLayout>
                ),
                render: (x) => {
                  const yul = parseFloat(x.yul).toFixed(2);
                  return (
                    <FlexLayout
                      style={{ color: '#BD3895' }}
                      justify="center"
                      align="center"
                    >
                      {Format.number(yul)} %
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'remark',
                text: '비고',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.dataDetail}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows2, updatedRows2) => {
              scope.onUpdatedRows(rows2, updatedRows2);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
