import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { WorkTemplate } from './Work.template';
import {
  WorkHumanModel,
  WorkModel,
} from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmDelete } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

interface WorkState {
  // 구분
  data: Array<WorkModel>;
  // 점검내용
  workhumandata: Array<WorkHumanModel>;
  focuseddata?: WorkModel;
  focusedworkhumandata?: WorkHumanModel;
  searchStep: string;
}

/**
 * 컨트롤러
 * @window w_tb_e601_step
 * @category 설치업무담당등록
 */
export class Work extends PageComponent<PageProps, WorkState>
  implements PageToolEvents {
  updatedRows?: Array<WorkModel>;

  updatedRows2?: Array<WorkHumanModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      data: [],
      workhumandata: [],
      searchStep: '%',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];
    this.updatedRows2 = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        step: this.state.searchStep,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new WorkModel(x))],
        }, () => {
          this.table.current?.update();
          next && next();
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        const index = await this.infinity?.retrieveTo('cd', this.state.focuseddata?.cd, type) || 0;
        this.state.data.length > index && this.table.current?.setFocus(index, 1);
        await this.infinity?.retrieve();
        await this.table.current?.update(false);
        if (this.state.data.length) {
          this.table.current?.setFocus(0, 1);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieve();
      await this.table.current?.update(false);
      if (this.state.data.length) {
        this.table.current?.setFocus(0, 1);
      }
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      step: this.state.searchStep,
    });
    if (data) {
      data.new = '1';

      this.setState({
        data: [...this.state.data, new WorkModel(data, true)],
      }, async () => {
        await this.table.current?.update(false);
        this.table.current?.setFocus(this.state.data.length - 1, 0);
      });
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const workhumandata = await api.fxExec(
      'dw_2_new',
      {
        step: this.state.searchStep,
        cd: this.state.focuseddata?.cd,
        spjangcd: this.state.focuseddata?.spjangcd,
      },
    );

    workhumandata && this.setState({
      workhumandata: [
        new WorkHumanModel(workhumandata, true),
        ...this.state.workhumandata,
      ],
    }, async () => {
      await this.tableDetail.current?.update(false);
      this.tableDetail.current?.setFocus(0, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.focuseddata,
      items: this.updatedRows2,
    }, true)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focuseddata?.cd} - ${this.state.focuseddata?.nm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '우측 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focuseddata) && this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focusedworkhumandata?.perid} - ${this.state.focusedworkhumandata?.pernm}`;
    await api.fxDelete(
      'dw_2_delete',
      text,
      {
        seq: this.state.focusedworkhumandata?.seq,
        step: this.state.focusedworkhumandata?.step,
        cd: this.state.focusedworkhumandata?.cd,
      },
    ) && this.onRowFocusEvent(this.state.focuseddata!);
  }

  @action
  onRowFocusEvent(item: WorkModel) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState({ focuseddata: item }, async () => {
      const workhumandata = await api.fxExec('dw_1_RowFocuschanged', this.state.focuseddata);
      if (workhumandata?.items) {
        this.setState({
          workhumandata: workhumandata.items.map((x: any) => new WorkHumanModel(x)),
        }, async () => {
          await this.tableDetail.current?.update();
          workhumandata?.items?.length > 0 && this.tableDetail.current?.setFocus(0, 1);
        });
      } else {
        this.setState({
          workhumandata: [],
        }, () => this.tableDetail.current?.update());
      }
    });
  }

  @action
  onRowFocusEvent2(item:WorkHumanModel) {
    if (item.new === '1') return;
    this.setState({ focusedworkhumandata: item });
  }

  @action
  async itemchanged(item: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_1_itemchanged', { step: item });
    data && this.setState({ focuseddata: new WorkModel(data) });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<WorkModel>, updatedRows: Array<WorkModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<WorkHumanModel>, updatedRows2: Array<WorkHumanModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ workhumandata: rows2 });
  }

  render() {
    return (
      <WorkTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state.data,
            ...this.state.workhumandata,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
