import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { DealModel } from '../models';
import { MonthSales } from '../MonthSales';

// 거래처별
const Deal: GridLayoutHeader<DealModel, MonthSales>[] = [
  {
    id: 'cltnm',
    text: '거래처명',
    width: 18,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계: {scope?.state.total}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: '1월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon01_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon01)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: '2월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon02_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon02)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: '3월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon03_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon03)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: '4월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon04_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon04)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: '5월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon05_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon05)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: '6월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon06_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon06)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: '7월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon07_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon07)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: '8월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon08_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon08)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: '9월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon09_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon09)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: '10월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon10_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon10)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon11',
    text: '11월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon11_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon11)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: '12월',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon12_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mon12)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '년합계',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.iamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.iamt)}
      </FlexLayout>
    ),
  },
];

export default Deal;
