import { computed } from 'mobx';

/**
 * 고장요인 모델
 * @window w_tb_e010
 * @category 고장관리코드
 */
export class FailureFactorModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(3)
   */
  readonly remocd: string;

  /**
   * 고장요인
   *
   * **data type** varchar(20)
   */
  readonly remonm: string;

  /**
   * 순번
   *
   * **data type** char(2)
   */
  readonly seq: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  readonly useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  readonly remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.remocd = data.remocd || '';
    this.remonm = data.remonm || '';
    this.seq = data.seq || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
