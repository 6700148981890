/**
 * 모델
 * @window w_tb_b502
 * @category 영업보고서
 */

export class CallHistoryModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly calladdr: string;

  /**
   * 통화일자
   *
   * **data type** varchar(8)
   */
  readonly calldate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly callemail: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly callendmemo: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly callfax: string;

  /**
   * 통화이력
   *
   * **data type** varchar(8)
   */
  readonly callmemo: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly callnm: string;

  /**
   * 전화번호
   *
   * **data type** varchar(8)
   */
  readonly callnum: string;

  /**
   *  통화시간
   *
   * **data type** varchar(8)
   */
  readonly calltime: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly custflag: string;

  /**
   * 접수
   *
   * **data type** varchar(8)
   */
  readonly flag: string;

  /**
   * 접수자코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 접수자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly port: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly rcvflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly recedate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly recenum: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly regdate: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  constructor(data: any = {}) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.calladdr = data.calladdr || '';
    this.calldate = data.calldate || '';
    this.callemail = data.callemail || '';
    this.callendmemo = data.callendmemo || '';
    this.callfax = data.callfax || '';
    this.callmemo = data.callmemo || '';
    this.callnm = data.callnm || '';
    this.callnum = data.callnum || '';
    this.calltime = data.calltime || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.custflag = data.custflag || '';
    this.flag = data.flag || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.port = data.port || '';
    this.rcvflag = data.rcvflag || '';
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.regdate = data.regdate || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';
  }
}
