import * as React from 'react';
import { FiUpload } from 'react-icons/fi';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  GridLayout,
  ImageView,
  LabelBox,
  LoaderButton,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ElevatorErrorCode } from './ElevatorErrorCode';


/**
 * 화면
 * @window w_tb_e006_err
 * @category 승강기에러코드
 */
export const ElevatorErrorCodeTemplate: React.FC<TemplateProps<ElevatorErrorCode>> = ({
  scope,
  update,
  dataUpdate,
}) => {
  const setData = update!;
  const setData2 = dataUpdate!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 1675 }}
      >
        <FlexLayout>
          <span>제조사</span>
          <ComboBox
            value={scope.state.searchManucd}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.manucds?.map((x) => new ComboBoxModel(x.manucd, x.manunm))}
            onSelect={(option) => setData({ searchManucd: option.value }, () => scope.getMdcds())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>기종[모델]</span>
          <ComboBox
            value={scope.state.searchMdcd}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.mdcds?.map((x) => new ComboBoxModel(x.mdcd, x.mdnm))}
            onSelect={(option) => setData({ searchMdcd: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="제조사, 기종, 에러코드, 제목으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'manucd',
              text: '제조사',
              width: 25,
              render: (x) => (
                <FlexLayout justify="left" align="center">
                  {x.manunm}
                </FlexLayout>
              ),
            },
            {
              id: 'mdnm',
              text: '기종',
              width: 20,
            },
            {
              id: 'errcd',
              text: '에러코드',
              width: 15,
            },
            {
              id: 'title',
              text: '제목',
              width: 30,
              render: (x) => (
                <FlexLayout justify="left" align="center">
                  {x.title}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.ElevatorErrorCodeList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowClick={(item, i) => scope.onRowFocusEvent(item, i)}
        />

        <FlexLayout isVertical={true}>
          <FlexLayout
            isVertical={true}
            weight={2}
          >
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="제조사"
                isNeed={true}
              >
                <ComboBox
                  value={model.manucd}
                  style={{
                    padding: '0 0 0 6px',
                    fontSize: 12,
                  }}
                  data={scope.state.manucds?.map((x) => new ComboBoxModel(x.manucd, x.manunm))}
                  onSelect={(option) => setData2({ manucd: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="기종"
                isNeed={true}
              >
                <ComboBox
                  value={model.mdcd}
                  style={{
                    padding: '0 0 0 6px',
                    fontSize: 12,
                  }}
                  data={scope.state.mdcds?.map((x) => new ComboBoxModel(x.mdcd, x.mdnm))}
                  onSelect={(option) => setData2({ mdcd: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="순번"
                isNeed={true}
              >
                <TextBox
                  readonly={true}
                  value={model.seq}
                  textAlign="center"
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="에러코드"
                weight={1}
                isNeed={true}
              >
                <TextBox
                  value={model.errcd}
                  onChange={(value) => setData({ errcd: value },
                    () => setData2({ errcd: value }))}
                />
              </LabelBox>
              <LabelBox title="제목" weight={2}>
                <TextBox
                  value={model.title}
                  onChange={(value) => setData({ title: value },
                    () => setData2({ title: value }))}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout justify={'right'} size={Global.LAYOUT_BUTTON_HEIGHT_1}>
              <LabelBox title="내용" />
              <LabelBox title="*더블클릭시 출력이 가능합니다" style={{ color: 'var(--color-blue)' }} />
              <LoaderButton
                style={{ width: '80px' }}
                onClick={() => scope.imageSelect()}
                percent={scope.state.percentImage}
              >
                <FiUpload />
                <span>추가</span>
              </LoaderButton>
              <Button
                style={{ width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.imageDelete()}
              >
                삭제
              </Button>
            </FlexLayout>
            <FlexLayout onDoubleClick={() => scope.onPrintEvent()}>
              <ImageView src={scope.state.img} />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
