import { computed } from 'mobx';

/**
 * 하단탭모델
 * @window w_tb_da050
 * @category 회사목표계획등록
 */
export class DetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 매출구분
   *
   * **data type** char(50)
   */
  gubunnm: string;

  /**
   * 전년도실적
   *
   * **data type** number
   */
  besamt: string;

  /**
   * 매출목표
   *
   * **data type** number
   */
  samt: string;

  /**
   * 증감액
   *
   * **data type** number
   */
  addyul: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.besamt = data.besamt || '';
    this.samt = data.samt || '';
    this.gubunnm = data.gubunnm || '';
    this.addyul = data.addyul || '';
    this.remark = data.remark || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
