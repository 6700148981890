import * as React from 'react';
import { action, computed } from 'mobx';
import {
  FaCar,
  FaAddressCard,
} from 'react-icons/fa';
import {
  ConfirmType,
  TableLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { CarModel } from './Car.model';
import { TabModel } from '../../../../models/component';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { CarTemplate } from './Car.template';
import { InfinityRetrieve } from '../../../../models/common';
import { TabHeaderPerson, TabHeaderCompany } from './tabs';
import { PageComponent } from '../../../../utils';

export enum CarTabId {
  Person,
  Company,
}

export const CarTabTitles = [
  '개인소유',
  '회사소유',
];

export const CarTabModels = [
  CarModel,
  CarModel,
];

export const CarTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const CarTabDataStateNames = [
  'Users',
  'UserErps',
  'UserApps',
];

export const CarTabUpdatesStateNames = [
  'UserUpdates',
  'UserErpUpdates',
  'UserAppUpdates',
];

export const CarTabFocusedStateNames = [
  'PersonUpdatesFocused',
  'CompanyUpdatesFocused',
];

export interface CarState {
  // 사용자별 접속시간
  Persons?: Array<CarModel>;
  PersonUpdates?: Array<CarModel>;
  PersonsFocused?: CarModel;

  // 사용자별 접속시간
  UserErps?: Array<CarModel>;
  UserErpUpdates?: Array<CarModel>;
  UserErpsFocused?: CarModel;

  focusedTab?: TabModel;
  stdate?: string;
  enddate?: string;
  searchQuery?: string;
  divicd?: string;
  divinm?: string;
  perid?: string;
  pernm?: string;
  kmflag?: string;
  spjangcd?: string;
  kmflags?: Array<any>;
}


/**
 * 컨트롤러
 * @window w_tb_pa105
 * @category 차량등록
 */
export class Car extends PageComponent<PageProps, CarState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = CarTabId;
    const titles = CarTabTitles;

    this.tabs = [
      new TabModel(id.Person.toString(), FaCar, titles[id.Person]),
      new TabModel(id.Company.toString(), FaAddressCard, titles[id.Company]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      divicd: '',
      perid: '',
      searchQuery: '',
      kmflag: '%',
      data: [],
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 개인소유
      TabHeaderPerson,

      // 회사소유
      TabHeaderCompany,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 매입구분
    const data = await api.dropdown('wf_dd_ca510_024_02');

    if (!data) return;

    this.setState({ kmflags: data.items });

    await this.onTabChange(this.tabs[(CarTabId.Person)]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[CarTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        this.setState({
          [CarTabUpdatesStateNames[i]]: [],
          [CarTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: CarTabId, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        divicd: this.state.divicd,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
        kmflag: this.state.kmflag,
      },
      (params) => api.fxExec(
        `${CarTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [CarTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[CarTabDataStateNames[i]],
                ...items.map((x: any) => new CarModel(x))],
          }, () => this.table.current?.update(false));
        }
      },
      async () => {
        await this.SS({
          [CarTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [CarTabDataStateNames[i]]: [],
      [CarTabUpdatesStateNames[i]]: [],
      [CarTabFocusedStateNames[i]]: undefined,
    });
    if (!autoLoad) return;
    await this.infinity?.retrieve();
    await this.table.current?.update(true);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `${CarTabFunctionNames[i]}_new`,
      {
        divicd: this.state.divicd,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
        kmflag: this.state.kmflag,
      },
    );

    data && this.setState({
      [CarTabDataStateNames[i]]: [
        new CarTabModels[i](data, true),
        // @ts-ignore
        ...this.state[CarTabDataStateNames[i]],
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    await api.fxSave(
      `${CarTabFunctionNames[i]}_save`,
      {
        // @ts-ignore
        items: this.state[CarTabUpdatesStateNames[i]],
      },
      true,
    );
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[CarTabFocusedStateNames[i]];

    if (!item) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case CarTabId.Person:
        text = `${item.seq} - ${item.pernm}`;
        break;

      case CarTabId.Company:
        text = `${item.seq} - ${item.pernm}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }

    await api.fxDelete(
      `${CarTabFunctionNames[i]}_delete`,
      text,
      // @ts-ignore
      this.state[CarTabFocusedStateNames[i]],
    );

    await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[CarTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${CarTabFunctionNames[i]}_print`,
      {
        divicd: this.state.divicd,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
        kmflag: this.state.kmflag,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[CarTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${CarTabFunctionNames[i]}_excel`,
      {
        divicd: this.state.divicd,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
        kmflag: this.state.kmflag,
      },
    );
  }

  @computed
  get tabIndex(): CarTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [CarTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [CarTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <CarTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
