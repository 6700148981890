import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Status } from '../Status';
import { StatusTabDetailGridHeader } from '../headers/StatusTabDetailGridHeader';

/**
 * 화면 > 탭 상세현황
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTabDetailTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridDetail}
      infinityHandler={scope.infinityDetail}
      header={StatusTabDetailGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataDetail}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      onRowDoubleClick={() => scope.onClickOpenResist()}
    />
  </FlexLayout>
);
