import { computed } from 'mobx';

/**
 * 첨부내역 모델
 * @window w_tb_da003_01w
 * @category 프로젝트현황
 */
export class AttachModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 삭제
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 문서명
   *
   * **data type** varchar(100)
   */
  appnm: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  title: string;

  /**
   * 순번
   *
   * **data type** number
   */
  fileseq: string;

  /**
   * 파일명
   *
   * **data type** varchar(255)
   */
  filename: string;

  /**
   * 확장자
   *
   * **data type** varchar(5)
   */
  fileext: string;

  /**
   * 파일경로
   *
   * **data type** varchar(255)
   */
  filepath: string;

  /**
   * 파일크기
   *
   * **data type** number
   */
  filesize: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.chk = data.chk || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.appnum = data.appnum || '';
    this.appnm = data.appnm || '';
    this.title = data.title || '';
    this.fileseq = data.fileseq || '';
    this.filename = data.filename || '';
    this.fileext = data.fileext || '';
    this.filepath = data.filepath || '';
    this.filesize = data.filesize || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
