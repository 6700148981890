import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_insu
 * @category 년공제보험요율등록
 */
export class YearInsuranceRatesRegisterModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *  년도
   *
   *  **data type** varchar(4)
   */
  year: string;

  /**
   * 고용보험
   *
   * **data type** float
   */
  insu_unem_def: string;

  /**
   *
   *건강보험
   *
   * **data type** float
   */
  insu_health_def: string;

  /**
   *
   *장기요양보험료
   *
   * **data type** float
   */
  insu_longterm_def: string;

  /**
   *
   *국민연금보험료
   *
   * **data type** float
   */
  insu_national_def: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.year = data.year || '';
    this.insu_unem_def = data.insu_unem_def || '';
    this.insu_health_def = data.insu_health_def || '';
    this.insu_longterm_def = data.insu_longterm_def || '';
    this.insu_national_def = data.insu_national_def || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}

