import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_tb_pb214
 * @category 휴무신청근무서
 */

export class HolidayWorkModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly weekdatetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly weekdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly weeknum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly stdatetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly enddatetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly gigan: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly worknum: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly sttime: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly endtime: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly attcnt: number

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly rpt_appgubun: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly r_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gs_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly newrow: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.weekdate = data.weekdate || '';
    this.weekdatetime = data.weekdatetime || '';
    this.weeknum = data.weeknum || '0';
    this.gubun = data.gubun || '1';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.title = data.title || '';
    this.stdatetime = data.stdatetime || '';
    this.stdate = data.stdate || '';
    this.enddatetime = data.enddatetime || '';
    this.enddate = data.enddate || '';
    this.gigan = data.gigan || '';
    this.worknum = data.worknum || '';
    this.sttime = data.sttime || '';
    this.endtime = data.endtime || '';
    this.remark = Fix.newline(data.remark) || '';
    this.appdate = data.appdate || '';
    this.apptime = data.apptime || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appperid = data.appperid || '';
    this.picflag = data.picflag || '0';
    this.attcnt = data.attcnt || 0;
    this.refer = data.refer || '';
    this.rpt_appgubun = data.rpt_appgubun || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.r_color = data.r_color || '';
    this.gs_color = data.gs_color || '';
    this.newrow = data.newrow || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
