import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import {
  BreakSituation,
  BreakSituationTabDataStateNames, BreakSituationTabId,
} from './BreakSituation';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8 } from '../../../../utils/time';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TabView } from '../../../../components/layout/TabView';
import {
  Button, CircleBadge,
  ComboBox,
  ComboBoxModel,
  FormatTextBox,
  IFrame, LayoutTitle, ModalView,
} from '../../../../components';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 메인화면
 * @window w_tb_e411w
 * @category 고장처리현황
 */
export const BreakSituationTemplate: React.FC<TemplateProps<BreakSituation>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={316}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          weight={1.3}
          isDisabledTrackingStateChange={true}
          value={scope.state.divicd}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
            weight={1.7}
          isDisabledTrackingStateChange={true}
          value={scope.state.divinm}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={340}>
        <span style={{ color: 'var(--color-red)', marginLeft: 30 }}>담당</span>
        <TextBox
          weight={1.3}
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              });
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.7}
          value={scope.state.pernm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout weight={0.6}>
        <span style={{ marginLeft: 50 }}>처리</span>
        <ComboBox
          value={scope.state.gubun}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '미처리' },
            { value: '1', remark: '처리' },
            { value: '2', remark: '처리중' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ gubun: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span style={{ color: 'var(--color-red)' }}>회사구분</span>
        <TextBox
          value={scope.state.wkactcd}
          textAlign="center"
          onChange={(value) => scope.setState({ wkactcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E018_1',
            {},
            true,
            (item) => {
              scope.setState({
                wkactcd: item.cd,
                wkactnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          value={scope.state.wkactnm}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>
    <SearchLayout
      isSub={true}
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>

      <FlexLayout
        size={176}
      >
        <span>
          시간
        </span>
        <FormatTextBox
          style={{
            marginLeft: 25,
          }}
          value={scope.state.sttime}
          textAlign="center"
          format="##:##"
          onChange={(value) => scope.setState({ sttime: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={176}>
        <span style={{ marginLeft: 32 }}>~</span>
        <FormatTextBox
          style={{ marginLeft: 25 }}
          value={scope.state.endtime}
          textAlign="center"
          format="##:##"
          onChange={(value) => scope.setState({ endtime: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span
          style={{
            color: 'var(--color-red)',
            marginLeft: 25,
          }}
        >현장명
        </span>
        <TextBox
          weight={1.3}
          isDisabledTrackingStateChange={true}
          textAlign="center"
          value={scope.state.actcd}
          onChange={(value) => scope.setState({ actcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              scope.setState({
                actcd: item.cd,
                actnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={1.7}
          value={scope.state.actnm}
          readonly={true}
          onChange={(value) => scope.setState({ actnm: value })}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout
      >
        <span
          style={{
            color: 'var(--color-red)',
          }}
        >
          호기명
        </span>
        <TextBox
          weight={1.3}
          textAlign="center"
          value={scope.state.equpcd}
          onChange={(value) => scope.setState({ equpcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E611',
            { uv_arg1: scope.state.actcd },
            true,
            (item) => {
              scope.setState({
                equpcd: item.cd,
                equpnm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />

        <TextBox
          weight={1.7}
          isDisabledTrackingStateChange={true}
          value={scope.state.equpnm}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={584}>
        <span
          style={{
            marginLeft: 35,
          }}
        >
          검색어
        </span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="고장내용, 처리내용, 고장요인, 고장원인, 고장부위, 처리방법으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <TabView
        tabs={scope.tabs}
        focused={scope.state.focusedTab}
        onChange={(tab) => scope.onTabChange(tab)}
      />
    </FlexLayout>

    <FlexLayout weight={3}>
      <GridLayout
        scope={scope}
        header={scope.tabHeaders[scope.tabIndex]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        // @ts-ignore
        data={scope.state[BreakSituationTabDataStateNames[scope.tabIndex]] || [] }
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        onRowDoubleClick={() => scope.popupModal(true)}
      />
    </FlexLayout>

    {(scope.tabIndex === BreakSituationTabId.Charge) && <FlexLayout>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
    {(scope.tabIndex === BreakSituationTabId.Processor) && <FlexLayout>
        <IFrame src={scope.state.url2} />
    </FlexLayout>}
    {(scope.tabIndex === BreakSituationTabId.Period) && <FlexLayout>
        <IFrame src={scope.state.url3} />
    </FlexLayout>}
    {(scope.tabIndex === BreakSituationTabId.Many) && <FlexLayout>
        <IFrame src={scope.state.url4} />
    </FlexLayout>}
    {(scope.tabIndex === BreakSituationTabId.Same) && <FlexLayout>
        <IFrame src={scope.state.url5} />
    </FlexLayout>}
    {(scope.tabIndex === BreakSituationTabId.Act) && <FlexLayout>
        <IFrame src={scope.state.url6} />
    </FlexLayout>}
    <ModalView
      isVisible={scope.state.popupDetailModal}
      onClose={() => scope.popupModal(false)}
      width={1500}
      height={600}
    >
      <FlexLayout>
        <FlexLayout isVertical={true}>
          <LayoutTitle>상세현황</LayoutTitle>
          <GridLayout
            header={[
              {
                id: 'equpnm',
                text: '호기명',
                width: 120,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    총 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="left"
                  >
                    {x.equpnm}
                    <CircleBadge>
                      {Number(x.wadcnt) > 1 && <span
                        style={{ backgroundColor: 'var(--color-red)' }}
                      >
                        {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
                      </span>}
                      {Number(x.lastcnt) > 1 && <span
                        style={{ backgroundColor: 'var(--color-blue)' }}
                      >
                        {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
                      </span>}
                    </CircleBadge>
                  </FlexLayout>
                ),
              },
              {
                id: 'actdivinm',
                text: '담당부서명',
                width: 80,
              },
              {
                id: 'actpernm',
                text: '담당자',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.rececnt_tot2}
                  </FlexLayout>
                ),
              },
              {
                id: 'seq',
                text: '처리',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}차
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 90,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compcnt_tot2}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 90,
              },
              {
                id: 'repernm',
                text: '접수자',
                width: 90,
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.notcnt_tot2}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.recedate}
                  />
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.compdate}
                  />
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.arrivdate}
                  />
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 170,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 100,
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 250,
              },
              {
                id: 'resunm',
                text: '처리내용',
                width: 100,
              },
              {
                id: 'resuremark',
                text: '처리내용상세',
                width: 250,
              },
              {
                id: 'remonm',
                text: '고장요인',
                width: 100,
              },
              {
                id: 'remoremark',
                text: '고장원인상세',
                width: 250,
              },
              {
                id: 'facnm',
                text: '고장원인',
                width: 100,
              },
              {
                id: 'greginm',
                text: '고장부위',
                width: 100,
              },
              {
                id: 'reginm',
                text: '고장부위상세',
                width: 200,
              },
              {
                id: 'resultnm',
                text: '처리방법',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="### 분"
                    textAlign="center"
                    value={x.resutime}
                  />
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="### 분"
                    textAlign="center"
                    value={x.resulttime}
                  />
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="### 분"
                    textAlign="center"
                    value={x.stoptime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData || []}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            ref={scope.popupGrid}
          />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
        weight={0.01}
      >
        <FlexLayout>
          <FlexLayout justify="right">
            <Button
              style={{
                height: 25,
                width: 80,
                marginTop: 10,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.popupModal(false)}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>

);
