import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642w_17
 * @category 거래처월별 매입/지급현황
 */
export class ClientMonthModel {
  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(40)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(40)
   */
  cltnm: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt01: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt01: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt01: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt02: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt02: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt02: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt03: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt03: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt03: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt04: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt04: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt04: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt05: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt05: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt05: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt06: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt06: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt06: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt07: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt07: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt07: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt08: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt08: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt08: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt09: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt09: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt09: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt10: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt10: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt10: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt11: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt11: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt11: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  mijamt12: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt12: string;

  /**
   * 매입금액
   *
   * **data type** varchar(40)
   */
  iamt12: string;

  /**
   * 미지급금액총액
   *
   * **data type** varchar(40)
   */
  mijamt_tot: string;

  /**
   * 지급금액총액
   *
   * **data type** varchar(40)
   */
  sndamt_tot: string;

  /**
   * 매입금액총액
   *
   * **data type** varchar(40)
   */
  iamt_tot: string;

  beamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangnm = data.spjangnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.mijamt01 = data.mijamt01 || '0';
    this.sndamt01 = data.sndamt01 || '0';
    this.iamt01 = data.iamt01 || '0';
    this.mijamt02 = data.mijamt02 || '0';
    this.sndamt02 = data.sndamt02 || '0';
    this.iamt02 = data.iamt02 || '0';
    this.mijamt03 = data.mijamt03 || '0';
    this.sndamt03 = data.sndamt03 || '0';
    this.iamt03 = data.iamt03 || '0';
    this.mijamt04 = data.mijamt04 || '0';
    this.sndamt04 = data.sndamt04 || '0';
    this.iamt04 = data.iamt04 || '0';
    this.mijamt05 = data.mijamt05 || '0';
    this.sndamt05 = data.sndamt05 || '0';
    this.iamt05 = data.iamt05 || '0';
    this.mijamt06 = data.mijamt06 || '0';
    this.sndamt06 = data.sndamt06 || '0';
    this.iamt06 = data.iamt06 || '0';
    this.mijamt07 = data.mijamt07 || '0';
    this.sndamt07 = data.sndamt07 || '0';
    this.iamt07 = data.iamt07 || '0';
    this.mijamt08 = data.mijamt08 || '0';
    this.sndamt08 = data.sndamt08 || '0';
    this.iamt08 = data.iamt08 || '0';
    this.mijamt09 = data.mijamt09 || '0';
    this.sndamt09 = data.sndamt09 || '0';
    this.iamt09 = data.iamt09 || '0';
    this.mijamt10 = data.mijamt10 || '0';
    this.sndamt10 = data.sndamt10 || '0';
    this.iamt10 = data.iamt10 || '0';
    this.mijamt11 = data.mijamt11 || '0';
    this.sndamt11 = data.sndamt11 || '0';
    this.iamt11 = data.iamt11 || '0';
    this.mijamt12 = data.mijamt12 || '0';
    this.sndamt12 = data.sndamt12 || '0';
    this.iamt12 = data.iamt12 || '0';
    this.mijamt_tot = data.mijamt_tot || '0';
    this.sndamt_tot = data.sndamt_tot || '0';
    this.iamt_tot = data.iamt_tot || '0';
    this.beamt = data.beamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
