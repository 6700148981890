import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ProductAccountTemplate } from './ProductAccount.template';
import {
  ProductAccountModel,
  ProductBigModel,
  ProductChoiceAllModel,
  ProductMiddleModel,
  ProductSmallModel,
  ProductTypeModel,
} from './ProductAccount.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmDelete, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export interface ProductAccountListItem {
  phm_pnam: string;
  phm_pcod: string;
  cltcd: string;
  phm_uamt: string;
  phm_samt: string;
  phm_mamt: string;
}

export interface ProductAccountDetailListItem {
  new: string;
  pcode: string;
  cltcd: string;
  cltnm: string;
  uamt: string;
  samt: string;
  mamt: string;
  remark: string;
  wkactcd: string;
}

interface ProductAccountState {
  searchQuery: string;
  searchQuery2: string;
  productAccountList: Array<ProductAccountListItem>;
  productAccountDetailList: Array<ProductAccountDetailListItem>;
  focusedProductAccount?: ProductAccountListItem;
  focused?: ProductAccountDetailListItem;
  choices?: Array<any>;
  phm_modes?: Array<any>;
  agrbs?: Array<any>;
  bgrbs?: Array<any>;
  cgrbs?: Array<any>;
  data: ProductAccountModel;
}

/**
 * 컨트롤러
 * @window w_tb_ca501_cltcd
 * @category 제품거래처별단가등록
 */
export class ProductAccount extends PageComponent<PageProps, ProductAccountState>
  implements PageToolEvents {
  updatedRows?: Array<ProductAccountModel>;

  updatedRows2?: Array<ProductAccountModel>;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  valueChk: string;

  mamt: string;

  samt: string;

  uamt: string;

  cltmValue: string;

  value?: Array<ProductAccountModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      productAccountList: [],
      productAccountDetailList: [],
      data: new ProductAccountModel(),
      searchQuery: '',
    };
    this.valueChk = '';
    this.mamt = '';
    this.samt = '';
    this.uamt = '';
    this.cltmValue = '';
    this.state.data.wkactcd = '001';
    this.state.data.choice = '%';
    this.state.data.phm_mode = '%';
    this.state.data.agrb = '%';
    this.state.data.bgrb = '%';
    this.state.data.cgrb = '%';
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 선택
    let data = await api.dropdown('wf_dd_ca503_03_choice_all');

    if (!data) return;
    this.setState({ choices: data.items.map((x: any) => new ProductChoiceAllModel(x)) });

    // 품목분류
    data = await api.dropdown('wf_dd_ca541_02');

    if (!data) return;
    this.setState({ phm_modes: data.items.map((x: any) => new ProductTypeModel(x)) });

    // 대분류
    data = await api.dropdown('wf_dd_ca503_01_02', { mode: this.state.data.phm_mode });

    if (!data) return;
    this.setState({ agrbs: data.items.map((x: any) => new ProductBigModel(x)) });

    // 중분류
    data = await api.dropdown('wf_dd_ca503_02_02', { pgr_hcod: this.state.data.agrb });

    if (!data) return;
    this.setState({ bgrbs: data.items.map((x: any) => new ProductMiddleModel(x)) });

    // 소분류
    data = await api.dropdown('wf_dd_ca503_03_02',
      {
        pgr_hcod: this.state.data.agrb,
        bgroup: this.state.data.bgrb,
      });
    if (!data) return;
    this.setState({ cgrbs: data.items.map((x: any) => new ProductSmallModel(x)) });

    this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        wkactcd: this.state.data.wkactcd,
        as_nm: this.state.searchQuery,
        psize: this.state.searchQuery2,
        choice: this.state.data.choice,
        phm_mode: this.state.data.phm_mode,
        agroup: this.state.data.agrb,
        bgroup: this.state.data.bgrb,
        cgroup: this.state.data.cgrb,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          productAccountList: [...this.state.productAccountList, ...items],
        }, async () => {
          this.table.current?.update(false);
          next && next();
        });
      },
      async () => {
        await this.SS({
          productAccountList: [],
        });

        const index = await this.infinity?.retrieveTo('phm_pcod', this.state.focusedProductAccount?.phm_pcod, type, true) || 0;
        this.state.productAccountList.length > index && this.table.current?.setFocus(index);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      productAccountList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.table.current?.update(true);
      // eslint-disable-next-line max-len
      this.state.productAccountList.length > 0 && this.onRowFocusEvent(this.state.productAccountList[0]);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (await api.save({
      items: this.updatedRows2,
    }, true)) {
      this.table.current?.resetUpdates();
    }
    this.updatedRows2 = [];
  }

  @action
  onRowFocusEvent(item: ProductAccountListItem) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];
    this.valueChk = item.phm_pcod;
    this.uamt = item.phm_uamt;
    this.samt = item.phm_samt;
    this.mamt = item.phm_mamt;

    this.setState({ focusedProductAccount: item }, async () => {
      const data2 = await api.fxExec('dw_1_RowFocuschanged', item);
      if (data2.items) {
        data2 && this.setState({
          productAccountDetailList: data2.items,
        }, () => {
          this.tableDetail.current?.update();
          data2?.items?.length > 0 && this.tableDetail.current?.setFocus(0, 0);
        });
      } else {
        this.setState({
          productAccountDetailList: [],
        }, () => {
          this.tableDetail.current?.update();
        });
      }
    });
  }

  @action
  onRowFocusEvent2(item: ProductAccountDetailListItem) {
    this.setState({ focused: item });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        wkactcd: this.state.focused?.wkactcd,
        pcode: this.state.focused?.pcode,
        cltcd: this.state.focused?.cltcd,
      },
    );

    if (data) {
      const newData: ProductAccountDetailListItem = {
        pcode: this.valueChk,
        uamt: this.uamt,
        samt: this.samt,
        mamt: this.mamt,
        wkactcd: this.state.data.wkactcd,
        remark: '',
        new: '1',
        cltcd: data.cltcd,
        cltnm: data.cltnm,
      };
      if (!this.state.productAccountDetailList) {
        this.setState({
          focused: newData,
          productAccountDetailList: [
            newData,
          ],
        }, async () => {
          await this.tableDetail.current?.update(false);
          this.tableDetail.current?.setFocus(0, 0);
        });
      } else {
        this.setState({
          focused: newData,
          productAccountDetailList: [
            newData,
            ...this.state.productAccountDetailList,
          ],
        }, async () => {
          await this.tableDetail.current?.update(false);
          this.tableDetail.current?.setFocus(0, 0);
        });
      }
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focused;

    if (focusedItem) {
      const text = `코드: ${this.state.focused?.cltcd}, 거래처명: ${this.state.focused?.cltnm}`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            phm_pcod: this.state.data.phm_pcod,
            cltcd: this.state.data.cltcd,
          },
        );
        this.onRetrieveEvent();
        // eslint-disable-next-line no-template-curly-in-string
        ConfirmSuccess.show('삭제', `${focusedItem.cltnm}를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ productAccountList: rows });
    this.cltmValue = updatedRows.cltcd;
  }

  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({ productAccountDetailList: rows2 });
    this.cltmValue = updatedRows2.cltcd;
  }

  render() {
    return (
      <ProductAccountTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
