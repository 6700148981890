import { computed } from 'mobx';

/**
 * 메인 모델
 * @window w_tb_ca608w_02
 * @category 발주현황
 */
export class MainModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly balno: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly baldv: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly cltnm: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pumdivicd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly taxcls: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly taxgubun: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly setcls: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly store: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly domcls: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly moncls: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly monrate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly sunflag: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly sunamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ischdate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly ibgflag: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly qcflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly pumdv: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(3276)
   */
  readonly bigo: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly maildv: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly projnm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly cdflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly taxmail: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly email: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly compflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly hagigan: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly sogigan: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly mijiflag: string;

  /**
   *
   *
   * **data type** number()
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly newrow: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.datetime = data.datetime || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balno = data.balno || '';
    this.baldv = data.baldv || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.actcd = data.actcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.pumdivicd = data.pumdivicd || '';
    this.taxcls = data.taxcls || '';
    this.taxgubun = data.taxgubun || '';
    this.setcls = data.setcls || '';
    this.store = data.store || '';
    this.domcls = data.domcls || '';
    this.moncls = data.moncls || '';
    this.monrate = data.monrate || '';
    this.sunflag = data.sunflag || '';
    this.sunamt = data.sunamt || '';
    this.ischdate = data.ischdate || '';
    this.ibgflag = data.ibgflag || '';
    this.qcflag = data.qcflag || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.pumdv = data.pumdv || '';
    this.remark = data.remark || '';
    this.bigo = data.bigo || '';
    this.maildv = data.maildv || '';
    this.projno = data.projno || '';
    this.refer = data.refer || '';
    this.projnm = data.projnm || '';
    this.cdflag = data.cdflag || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.taxmail = data.taxmail || '';
    this.email = data.email || '';
    this.compflag = data.compflag || '';
    this.compdate = data.compdate || '';
    this.hagigan = data.hagigan || '';
    this.sogigan = data.sogigan || '';
    this.state = data.state || '';
    this.chk = data.chk || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appremark = data.appremark || '';
    this.appperid = data.appperid || '';
    this.mijiflag = data.mijiflag || '';
    this.mamt = data.mamt || '';
    this.newrow = data.newrow || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
