import * as React from 'react';
import { action, computed } from 'mobx';
import { inject } from 'mobx-react';
import style from './FormatNumberTextBox.module.scss';
import { Format, JoinClassName } from '../../../utils/string';
import { FlexLayout } from '../../layout';
import { LayoutProps } from '../../../constants';
import { SearchBinding } from '../../../models/common';
import { PublicStore } from '../../../stores';

interface FormatNumberTextBoxActions {
  onChange?: (value: any) => any;
  onBlur?: (value: any) => any;
  onEnter?: (value: any) => any;
}

interface FormatNumberTextBoxProps extends FormatNumberTextBoxActions, LayoutProps {
  publicStore?: PublicStore;
  refs?: React.RefObject<any>;
  value?: any;
  color?: string;
  bold?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  char?: string;
  charAt?: number;
  placeholder?: string;
  className?: any;
  style?: any;
  readonly?: boolean;
  transparent?: boolean;
  isDisabledTrackingStateChange?: boolean;
  bindSearchModal?: SearchBinding;
  head?: React.ReactNode;
  trail?: React.ReactNode;
}

interface FormatNumberTextBoxState {
  value: string;
  isFocused: boolean;
  editable?: boolean;
}

@inject('publicStore')
export class FormatNumberTextBox
  extends React.Component<FormatNumberTextBoxProps, FormatNumberTextBoxState> {
  ref: React.RefObject<any> = React.createRef();

  lastValue?: string;

  constructor(props: FormatNumberTextBoxProps, context: any) {
    super(props, context);
    this.state = {
      value: this.result(this.props.value ?? ''),
      // eslint-disable-next-line no-prototype-builtins
      editable: true, // !this.props.hasOwnProperty('ref'),
      isFocused: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<FormatNumberTextBoxProps>) {
    this.props.value !== prevProps.value
      && this.setState({ value: this.result(this.props.value ?? '') }, () => {
        this.lastValue = this.value;
      });
  }

  @action
  public forceUpdate() {
    this.setState({
      value: this.props.value,
    });
  }

  @computed
  get value(): string {
    return this.state.value;
  }

  @computed
  get formatValue(): string {
    return this.result(this.state.value);
  }

  @action
  public focus() {
    this.setState({
      editable: true,
    }, () => requestAnimationFrame(() => this.ref.current?.focus()));
  }

  // eslint-disable-next-line class-methods-use-this
  result(value: string): string {
    const { char, charAt } = this.props;
    const v = value.replace(new RegExp(char || ',', 'g'), '');
    return Format.make(v, char || ',', charAt || 3);
  }

  render() {
    return (
      <FlexLayout
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.transparent ? style.transparent : false,
        ])}
        style={this.props.style}
        weight={this.props.weight}
        size={this.props.size}
        justify="center"
        align="center"
        onClick={() => this.focus()}
        // onUnFocus={() => this.setState({ editable: false })}
      >
        {this.state.editable ? (<React.Fragment>
          {this.props.head}
          {!this.props.value && this.props.placeholder && (
            <label>{this.props.placeholder}</label>
          )}
          <input
            ref={this.ref}
            className={this.props.readonly ? style.readonly : undefined}
            style={{
              textAlign: this.props?.textAlign,
              color: this.props?.color,
              fontWeight: this.props?.bold ? 600 : 'inherit',
            }}
            value={this.state.isFocused ? this.value : this.formatValue}
            readOnly={this.props.readonly}
            onChange={(e) => this.setState({ value: this.result(e.target.value) })}
            onDoubleClick={() => this.props.bindSearchModal
              && !this.props.readonly && this.props.bindSearchModal.open()}
            onBlur={() => {
              this.setState({ isFocused: false });

              if (this.lastValue === this.value) return;

              this.props.onChange && this.props.onChange(this.value);
              setTimeout(() => {
                this.props.onBlur && this.props.onBlur(this.value);
              });

              if (!this.props.isDisabledTrackingStateChange) {
                this.props.publicStore?.setStateChanged(true);
              }
            }}
            onFocus={() => {
              this.lastValue = this.value;
              this.setState(
                { isFocused: true },
                () => this.ref.current?.setSelectionRange(0, this.value.length),
              );
            }}
            onKeyDown={async (e) => {
              if (this.props.readonly) {
                return;
              }
              if (e.keyCode === 13) {
                if (this.props.bindSearchModal && !this.props.readonly) {
                  this.props.bindSearchModal.open({
                    as_nm: this.props.value,
                  });
                } else {
                  if (this.lastValue === this.value) return;

                  this.props.onChange && this.props.onChange(this.value);
                  setTimeout(() => {
                    this.props.onEnter && this.props.onEnter(this.value);
                  });

                  if (!this.props.isDisabledTrackingStateChange) {
                    this.props.publicStore?.setStateChanged(true);
                  }
                  this.lastValue = this.value;
                }
              } else if (e.key.toLocaleLowerCase() === 'v' && e.ctrlKey) {
                const text = await navigator.clipboard.readText();
                const val = text?.replaceAll(/\D/ig, '');
                if (val) {
                  this.props.onChange && this.props.onChange(val);
                }
              } else if (e.key === '-') {
                this.setState({ value: this.result(`-${this.value}`) });
              } else if (
                e.keyCode !== 45
                && e.keyCode !== 8
                && e.keyCode !== 9
                && e.keyCode !== 36
                && e.keyCode !== 35
                && e.keyCode !== 46
                && e.key !== '+'
                && e.key !== '.'
                && (e.keyCode < 48 || e.keyCode > 57) // number
                && (e.keyCode < 96 || e.keyCode > 105) // numpad number
                && (e.keyCode < 37 || e.keyCode > 40) // arrows
              ) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
          {this.props.trail}
        </React.Fragment>) : (
          <FlexLayout
            justify={this.props?.textAlign}
            className={this.props.readonly ? style.readonly : undefined}
            style={{
              color: this.props?.color || '#000000',
              fontWeight: this.props?.bold ? 600 : 'inherit',
              padding: '0 8px',
            }}
          >
            {this.value || ''}
          </FlexLayout>
        )}
      </FlexLayout>
    );
  }
}
