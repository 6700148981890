import { PurchasingStatistics } from '../index';
import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';

export const PurchasingStatisticsTabActcdGridHeader: GLHF<any, PurchasingStatistics> = (scope) => ([
  {
    id: 'cltnm',
    text: '거래처명',
    width: 14,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'beamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout style={{ color: 'var(--color-green)' }}>전년도이월</FlexLayout>
        <FlexLayout style={{ color: '#940035' }}>미지급 금액</FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="end"
        align="end"
        style={{ color: '#940035' }}
      >
        {Format.number(x.beamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="end" style={{ color: '#940035' }}>
        {scope.state.beamt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          01월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt01)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt01)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt01)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt01_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt01_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt01_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          02월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt02)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt02)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt02)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt02_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt02_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt02_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          03월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt03)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt03)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt03)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt03_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt03_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt03_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          04월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt04)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt04)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt04)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt04_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt04_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt04_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          05월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt05)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt05)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt05)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt05_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt05_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt05_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          06월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt06)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt06)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt06)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt06_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt06_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt06_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          07월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt07)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt07)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt07)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt07_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt07_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt07_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          08월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt08)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt08)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt08)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt08_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt08_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt08_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          09월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt09)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt09)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt09)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt09_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt09_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt09_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          10월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt10)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt10)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt10)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt10_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt10_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt10_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon1',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          11월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt11)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt11)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt11)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt11_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt11_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt11_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#008080' }}
        >
          12월
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt12)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt12)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt12)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt12_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt12_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt12_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'monTot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          합계
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          매입금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          지급금액
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미지금액
        </FlexLayout>
      </FlexLayout>
    ),
    width: 10,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        style={{ backgroundColor: '#A6CAF0' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: x.cltcd,
          modalTopTitles: x.cltnm,
          modalTitles: x.cltnm,
          tabNames: 'cltcd',
          mons: scope.state.year,
        }, () => scope.openModal(true))}
      >
        <FlexLayout
          justify="right"
          align="start"
        >
          {Format.number(x.mijamt_tot)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(x.sndamt_tot)}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          {Format.number(x.iamt_tot)}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tab_mijamt_tot_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#60C5F1' }}
        >
          {scope?.state.tab_sndamt_tot_tot}
        </FlexLayout>
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#FF00FF' }}
        >
          {scope?.state.tab_iamt_tot_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
