import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight, RiFolder2Line,
} from 'react-icons/all';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { FormatTextBox } from '../../../../components/forms/FormatTextBox';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Global, TemplateProps } from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import {
  ComboBox,
  ComboBoxModel,
} from '../../../../components/forms/ComboBox';
import { CompleteBox } from './CompleteBox';
import { TableLayout } from '../../../../components/layout/TableLayout';
import style from './CompleteBox.module.scss';
import { ModalView } from '../../../../components/layout/ModalView';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { Button } from '../../../../components/forms/Button';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { AttachmentBox } from '../../../../components/forms/AttachmentBox';
import { IFrame, SubHeader } from '../../../../components';

/*
 * 화면
 * @window w_tb_e080_list_02
 * @category 기결함
*/

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const CompleteBoxTemplate: React.FC<TemplateProps<CompleteBox>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const model2 = scope.state.popupData;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={350}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="제목, 사유, 작성자명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
          <span>문서구분</span>
          <ComboBox
            value={scope.state.papercd}
            data={scope.state.papercds?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
            onSelect={(option) => scope.setState({ papercd: option.value },
              () => {
                scope.onRetrieveEvent();
              })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span>결재자명</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            readonly={true}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={2}
            value={scope.state.pernm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout
        size={Global.LAYOUT_GRID_HEIGHT_1}
        className={style.pageLayout}
      >
        <table className={style.headerTable}>
          <tbody>
          <tr>
            <th>결재대기</th>
            <th>결재문서</th>
            <th>반려문서</th>
          </tr>
          <tr>
            <td>{model?.appgubun1}건</td>
            <td>{model?.appgubun2}건</td>
            <td>{model?.appgubun3}건</td>
          </tr>
          </tbody>
        </table>
      </FlexLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout weight={3}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'no',
                text: 'No',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.no}
                  </FlexLayout>
                ),
              },
              {
                id: 'appnum',
                text: '문서번호',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="######-###-####"
                    value={x.appnum}
                  />
                ),
              },
              {
                id: 'repodate',
                text: '상신일자',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.repodate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'repopernm',
                text: '작성자명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.repopernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'papercd',
                text: '문서',
                width: 15,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      align='center'
                      justify='center'
                    >
                      {scope.state.papercds?.filter((y) => y.com_code === x.papercd)[0]?.com_cnam}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '결재구분',
                width: 10,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='right'
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 10 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>

                    <FlexLayout
                      weight={1.2}
                      align='center'
                      justify='left'
                      style={{
                        marginRight: x.appremark?.length > 0 ? 10 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {x.appremark && <IoIosMail size={22}/>}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'appdate',
                text: '결재일자',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.appdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '제목',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '사유',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },

            ]}
            data={scope.state.completeBoxList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => { scope.onUpdatedRows(rows, updatedRows); }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
            onRowDoubleClick={() => scope.openReportModal()}
          />
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.isReportModalVisible}
        onClose={() => scope.closeReportModal()}
        width={864}
        height={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            결재승인[{scope.state.papercds?.filter((y) => y.com_code === model2?.papercd)[0]?.com_cnam}]
          </LayoutTitle>

          <FlexLayout>
            <IFrame src={scope.state.url} />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            { model2?.attcnt > 0 && <FlexLayout align='center'>
                <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.openFileModal()}
                >
                    <RiFolder2Line size={17} />
                </Button>
                <span style={{ color: 'var(--color-red)' }}>
                      * 첨부파일이 있습니다
                  </span>
            </FlexLayout>
            }
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LayoutTitle weight={1}>
              지시사항
            </LayoutTitle>
            <LabelBox weight={3}>
              <TextBox
                value={model2?.remark}
                textAlign="left"
                isMultiline={true}
                onChange={(value) => setData({ remark: value })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onPopupPrintEvent()}>출력</Button>
              <Button onClick={() => scope.closeReportModal()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isFileModalVisible}
        onClose={() => scope.closeFileModal()}
        zIndex={-1}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={model2?.appnum}
            appnm={scope.state.papercds?.filter((y) => y.com_code === model2?.papercd)[0]?.com_cnam}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
