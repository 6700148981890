import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ChargeReceivable } from './ChargeReceivable';
import {
  Date6Calculator,
  Date8, Today,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { ChargeReceivableTab } from './ChargeReceivable.tab';

const recivstatuss = [
  { value: 'A', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'R', remark: '열람' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
];

/**
 * 화면
 * @window w_input_da023w_04
 * @category 담당별미수체크
 */
export const ChargeReceivableTemplate: React.FC<TemplateProps<ChargeReceivable>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => {
                setData({ yearmon: value },
                  () => scope.onRetrieveEvent());
              }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={410}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="현장으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              weight={1.5}
              value={scope.state.divicds}
              textAlign="center"
              onChange={(value) => setData({ divicds: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicds: item.cd,
                    divinms: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.divinms}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              weight={1.5}
              value={scope.state.perids}
              textAlign="center"
              onChange={(value) => setData({ perids: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perids: item.cd,
                    pernms: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernms}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={ChargeReceivableTab}
          onChange={(item, index) => scope.onTabChange(item, index)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.textModal(false)}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>상세현황</LayoutTitle>

          <FlexLayout weight={14} >
            <GridLayout
              ref={scope.taxDetailGrid}
              onRowClick={(item) => scope.taxDetailModalRowFocused(item)}
              onRowDoubleClick={() => scope.salesDetailModalOpen(true)}
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misdate',
                  text: '발생일자',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      단기미수:{scope.state.shotmis_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misnum',
                  text: '번호',
                  width: 6,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.misnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 30,
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      장기미수: {scope.state.longmis3_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recivstatus',
                  text: '전자세금계산서',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.recivstatus === 'F'
                          // eslint-disable-next-line no-nested-ternary
                          ? 'var(--color-blue)' : x.recivstatus === 'FF'
                            ? '#7A297B' : x.recivstatus === 'B'
                              ? 'var(--color-red)' : '',
                      }}
                    >
                      {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '청구금액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.mijamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'iamt',
                  text: '입금액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.iamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resuamt',
                  text: '미수잔액',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.resuamt_tot2}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'longmisnm',
                  text: '장기미수구분',
                  width: 14,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.longmisnm === '단기미수 1개월'
                          ? '' : x.longmisnm === '단기미수 2개월'
                            ? '' : 'var(--color-red)',
                      }}
                    >
                      {x.longmisnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.textModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.textDetailModal2}
        onClose={() => scope.textModal2(false)}
        width={1000}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.focused?.actnm}미수답변내역</LayoutTitle>

          <FlexLayout weight={14}>
            <TableLayout
              ref={scope.table}
              header={[
                {
                  id: 'seq',
                  text: '순서',
                  width: 10,
                },
                {
                  id: 'inperid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      textAlign="center"
                      value={x.perid}
                      onChange={(value) => rowUpdate({ perid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 10,
                },
                {
                  id: 'indate',
                  text: '기록일자',
                  width: 10,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.indate || Today.date()}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ indate: value })}
                    />
                  ),
                },
                {
                  id: 'saflag',
                  text: '미수사유',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.saflag}
                      data={[
                        { value: '001', remark: '계약조건' },
                        { value: '002', remark: '자금부족' },
                        { value: '003', remark: '법정관리(워크아웃)' },
                        { value: '004', remark: '부도' },
                        { value: '005', remark: '고객불만' },
                        { value: '006', remark: '고객행불' },
                        { value: '007', remark: '계산서오류' },
                        { value: '008', remark: '기타' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ saflag: option.value })}
                    />
                  ),
                },
                {
                  id: 'buildflag',
                  text: '건물상태',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.buildflag}
                      data={[
                        { value: '0', remark: '정상' },
                        { value: '1', remark: '폐쇄' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ buildflag: option.value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '진행현황',
                  width: 40,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.popupData2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.dw_2_new()}>신규</Button>
              <Button onClick={() => scope.onModalSaveEvent()}>저장</Button>
              <Button onClick={() => scope.dw_2_delete()}>삭제</Button>
              <Button onClick={() => scope.textModal2(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.salesDetailModal}
        onClose={() => scope.SS({ salesDetailModal: false, salesDetailModalData: [] })}
        width={1000}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>매출상세</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.saleDetatilGrid}
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 260,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: ' misdate',
                  text: '발생일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misnum',
                  text: '번호',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.misnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seq',
                  text: '순번',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '매출상세',
                  width: 260,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recivstatus',
                  text: '전자세금계산서',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.recivstatus.trim() === 'F'
                          // eslint-disable-next-line no-nested-ternary
                          ? 'var(--color-blue)' : x.recivstatus === 'FF'
                            ? '#7A297B' : x.recivstatus === 'B'
                              ? 'var(--color-red)' : '',
                      }}
                    >
                      {recivstatuss.filter((y) => y.value === x.recivstatus.trim())[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '청구금액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.salesDetailModalData}
            >
            </GridLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
