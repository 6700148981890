import * as React from 'react';
import { FaSearch } from 'react-icons/fa';
import style from './SearchLayout.module.scss';
import { FlexLayoutProps } from '../../../constants';
import { JoinClassName } from '../../../utils/string';
import { FlexLayout } from '../FlexLayout';

interface SearchLayoutProps extends FlexLayoutProps {
  isSub?: boolean;
  noMarginTop?: boolean;
  maxWidth?: number;
}

export class SearchLayout extends React.Component<SearchLayoutProps> {
  render() {
    const {
      children,
      className,
      isSub,
      style: pStyle,
      size,
      maxWidth,
      align,
      justify,
      noMarginTop,
    } = this.props;

    return (
      <FlexLayout
        className={JoinClassName.make([
          style.container,
          isSub ? style.sub : false,
          className,
        ])}
        style={{
          ...pStyle,
          marginTop: noMarginTop ? 0 : -2,
          maxWidth: maxWidth || 'unset',
        }}
        size={size}
        align={align}
        justify={justify}
      >
        <FlexLayout className={style.icon}>
          {!isSub && <FaSearch />}
        </FlexLayout>
        {children}
      </FlexLayout>
    );
  }
}
