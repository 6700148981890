import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e101_monend
 * @category 계약월마감
 */
export class ContractModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 회사구분
   *
   * **data type** char(10)
   */
  wkactcd: string;

  /**
   * 코드
   *
   * **data type** char(13)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 계약일자
   *
   * **data type** char(8)
   */
  contdate: string;

  /**
   * 시작일자
   *
   * **data type** char(8)
   */
  stdate: string;

  /**
   * 만료일자
   *
   * **data type** char(8)
   */
  enddate: string;

  /**
   * 개월
   *
   * **data type** number
   */
  moncnt: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 계약조건
   *
   * **data type** char(4)
   */
  contgubun: string;

  /**
   * 금액
   *
   * **data type** number
   */
  amt: string;

  /**
   * 담당
   *
   * **data type** char(100)
   */
  pernm: string;

  /**
   * 계약분류
   *
   * **data type** char(4)
   */
  contg: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.pernm = data.pernm || '';
    this.wkactcd = data.wkactcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.contdate = data.contdate || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.moncnt = data.moncnt || '';
    this.qty = data.qty || '';
    this.contgubun = data.contgubun || '';
    this.amt = data.amt || '';
    this.pernm = data.pernm || '';
    this.contg = data.contg || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
