import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { BuildingUseTemplate } from './BuildingUse.template';
import { BuildingUseModel } from './BuildingUse.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


interface BuildingUseState {
  data: Array<BuildingUseModel>;
  contbilds?: Array<any>;
  focused?: BuildingUseModel;
}

/**
 * 컨트롤러
 * @window w_tb_e029
 * @category 건물용도등록
 */
export class BuildingUse extends PageComponent<PageProps, BuildingUseState>
  implements PageToolEvents {
  updatedRows?: Array<BuildingUseModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 첫번째 서버 요청
    // 구분코드 리스트 가져오기
    // request 함수를 호출
    // 실패시 data에 undefined가 들어옴
    const data = await api.dropdown('wf_dd_ca510_028_01');

    // 서버 요청 실패시 빠져나감
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contbilds: data.items });

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new BuildingUseModel(x))],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        await this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.SS({
      data: [],
    });
    await this.infinity?.retrieve();
    await this.table.current?.update(true);
    await this.table.current?.setFocus(0, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        ...this.state.data,
        new BuildingUseModel(data, true),
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.data.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.buildcd} - ${this.state.focused?.buildnm}`;
    await api.delete(text, this.state.focused) && await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel();
  }

  @action
  onRowFocusEvent(item: BuildingUseModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<BuildingUseModel>, updatedRows: Array<BuildingUseModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <BuildingUseTemplate
       scope={this}
      />
    );
  }
}
