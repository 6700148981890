import * as React from 'react';
import { observer } from 'mobx-react';
import style from './TabView.module.scss';
import { TabModel } from '../../../models';
import { JoinClassName } from '../../../utils/string';
import { DefaultLayout } from '../DefaultLayout';

interface TabViewActions {
  onChange: (tab: TabModel, index: number) => any;
}

interface TabViewProps extends TabViewActions {
  tabs: TabModel[];
  focused?: TabModel;
}

@observer
export class TabView extends React.Component<TabViewProps> {
  render() {
    const {
      tabs,
      focused,
      onChange,
    } = this.props;

    const hasFocused = focused !== undefined;

    return (
      <DefaultLayout className={style.container} isScroll={true}>
        {tabs.map((x, i) => (
          <div
            key={x.id}
            className={JoinClassName.make([
              style.tab,
              // eslint-disable-next-line no-nested-ternary, max-len
              hasFocused && x.id === focused?.id ? style.active : x.isEnabled ? style.active : false,
            ])}
            onClick={(e) => {
              // @ts-ignore
              if (e.target.className.indexOf('closer') > 0) {
                //
              } else {
                onChange(x, i);
              }
            }}
          >
            <div className={style.tab__cover}>
              <x.icon />
              <p>{x.text}</p>
            </div>
          </div>
        ))}
      </DefaultLayout>
    );
  }
}
