import * as React from 'react';
import {
  AiFillMinusSquare,
  AiFillPlusSquare,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { RiKakaoTalkFill } from 'react-icons/ri';
import {
  AttachmentBox,
  Button,
  ComboBox,
  ComboBoxModel,
  CustomAttachmentBox,
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  IFrame,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Result } from './Result';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { ResultTableHeader } from './headers/ResultTableHeader';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ResultDetailTableHeader } from './headers/ResultDetailTableHeader';
import { JournalButtonClickedTypes } from '../../../electronic/preparation/Journal/Journal';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e035_03
 * @category 점검결과등록
 */
export const ResultTemplate: React.FC<TemplateProps<Result>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={130}>
          <span>결과</span>
          <ComboBox
            value={scope.state.state}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '2', remark: '완료' },
              { value: '1', remark: '계획' },
            ].map((x: any) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(v) => setData({ state: v.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.planmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ planmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ planmon: new Date6Calculator(scope.state.planmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ planmon: new Date6Calculator(scope.state.planmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            value={scope.state.pernm}
            weight={1.4}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>현장</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        {
          scope.state.kakaoyn === '1'
            ? <Button onClick={() => scope.onKakaoButtonClicked()}>
              <FlexLayout style={{ width: 80 }}>
                <RiKakaoTalkFill size={30}></RiKakaoTalkFill>
                <span style={{ paddingLeft: 8 }}>전송</span>
              </FlexLayout>
            </Button>
            : null
        }

        <Button onClick={() => scope.onSendEmail()}>
          <div style={{ width: 80 }}>EMAIL</div>
        </Button>
        <Button onClick={() => scope.openModalCharge()}>
          <div style={{ width: 80 }}>담당별합계</div>
        </Button>
        <Button onClick={() => scope.openGosi()}>
          <div style={{ width: 80 }}>정보센터확인</div>
        </Button>
        <Button onClick={() => scope.onGosiEmail()}>
          <div style={{ width: 80 }}>정보센터전송</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={ResultTableHeader(scope)}
          headerHeight={52}
          rowHeight={58}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item, index) => scope.onRowFocused(item, index)}
          onChange={(rows, updates) => scope.onChanged(rows, updates)}
        />
      </FlexLayout>


      {/* 팝업 점검일지 */}
      <ModalView
        width={1100}
        isVisible={scope.state.isVisibleDetail}
        onClose={() => setData({ isVisibleDetail: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <FlexLayout isVertical={true}>
            <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
              <FlexLayout>
                <span>검색어</span>
                <TextBox
                  placeholderColor="#CACACA"
                  placeholderAlign="left"
                  placeholder="현장으로 검색하세요"
                  value={scope.state.detailSearchQuery}
                  onChange={(value) => setData({ detailSearchQuery: value })}
                  onEnter={() => scope.onDetailReroll()}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>

              <Button onClick={() => scope.onDetailReroll()}>점검일지생성</Button>
            </SearchLayout>

            <TableLayout
              ref={scope.tableDetail}
              header={ResultDetailTableHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.dataDetail}
              infinityHandler={scope.infinityDetail}
              onChange={(rows) => scope.SS({ dataDetail: rows })}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.RED}
              onClick={() => setData({ isVisibleDetail: false })}
            >
              닫기
            </Button>

            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onSaveDetail()}
            >
              저장
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 첨부파일 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            appnum={'1'}
            appnm="점검결과등록"
            isEnabledTitle={true}
            isEnabledPreview={true}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 담당별 합계 */}
      <ModalView
        isVisible={scope.state.isChargeModalVisible}
        onClose={() => scope.closeModalCharge()}
        zIndex={-1}
        width={1600}
        height={1200}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              담당별합계
            </LayoutTitle>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchLayout size={300}>
              <FlexLayout size={200}>
                <span>월</span>
                <DateTextBox
                  value={scope.state.popupStmon}
                  textAlign="center"
                  format="#### - ##"
                  onChange={(value) => { scope.setState({ popupStmon: value }); }}
                  onEnter={() => scope.chargeRetrieve()}
                  head={(<button
                    onClick={() => {
                      scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add(-1).toString() },
                        () => scope.chargeRetrieve());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                  trail={(<button
                    onClick={() => {
                      scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add().toString() },
                        () => scope.chargeRetrieve());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            </SearchLayout>
            <FlexLayout
              justify='right'
              align='center'
            >
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.printCharge()}
              >
                출력
              </Button>
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.closeModalCharge()}
              >
                확인
              </Button>
            </FlexLayout>
          </FlexLayout>

          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={1772}
          >
            <FlexLayout size={200}/>
            <FlexLayout
              size={273}
              justify="center"
              align="center"
            >
              관리 대수
            </FlexLayout>
            <FlexLayout
              size={148}
              justify="center"
              align="center"
            >
              03월 점검
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              1주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              2주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              3주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              4주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              5주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
            </FlexLayout>
          </GridLayoutAdditionalHeader>

          <GridLayout
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 125,
                trail: () => (
                  <FlexLayout
                    style={{ color: 'var(--color-pink)' }}
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout>
                    {(x.chk === '0' && x.open === '0')
                    && <Button
                      onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.ADD, x)}
                      isIcon={true}
                    >
                      <AiFillPlusSquare size={17}/>
                    </Button>
                    }
                    {
                      (x.chk === '0' && x.open === '1')
                      && <Button
                        onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.MINUS, x)}
                        isIcon={true}
                      >
                        <AiFillMinusSquare size={17}/>
                      </Button>
                    }
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ fontWeight: x.chk === '0' && 'bold' }}
                    >
                      {x.divinm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 75,
                trail: (data) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {`${data.length} 명`}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'endqty2',
                text: '평균대수',
                width: 75,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.endqty2 !== '0' && x.endqty2}
                  </FlexLayout>
                ),
              },
              {
                id: 'totqty',
                text: '현재대수',
                width: 75,
                trail: (data) => {
                  const sumTotqty = Sum.all(data, 'totqty');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumTotqty !== 0 && Format.number(sumTotqty)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.totqty !== '0' && x.totqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'chaqty',
                text: '변경대수',
                width: 75,
                trail: (data) => {
                  const sumChaqty = Sum.all(data, 'chaqty');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumChaqty !== 0 && Format.number(sumChaqty)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.chaqty !== '0' && x.chaqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'notkccnt',
                text: '미검사',
                width: 50,
                trail: (data) => {
                  const sumNotkccnt = Sum.all(data, 'notkccnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumNotkccnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-violet)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.notkccnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'miscnt',
                text: '미계획',
                width: 50,
                trail: (data) => {
                  const sumMiscnt = Sum.all(data, 'miscnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-red)' }}
                      justify="center"
                      align="center"
                    >
                      {sumMiscnt !== 0 && Format.number(sumMiscnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.miscnt !== '0' && x.miscnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'cnt',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumCnt = Sum.all(data, 'cnt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumCnt !== 0 && Format.number(sumCnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.cnt !== '0' && x.cnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'compcnt',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumCompcnt = Sum.all(data, 'compcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumCompcnt !== 0 && Format.number(sumCompcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.compcnt !== '0' && x.compcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt1',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt1 = Sum.all(data, 'weekcnt1');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt1 !== 0 && Format.number(sumWeekcnt1)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt1 !== '0' && x.weekcnt1}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt1',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt1 = Sum.all(data, 'weekcompcnt1');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt1 !== 0 && Format.number(sumWeekcompcnt1)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt1 !== '0' && x.weekcompcnt1}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt2',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt2 = Sum.all(data, 'weekcnt2');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt2 !== 0 && Format.number(sumWeekcnt2)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt2 !== '0' && x.weekcnt2}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt2',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt2 = Sum.all(data, 'weekcompcnt2');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt2 !== 0 && Format.number(sumWeekcompcnt2)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt2 !== '0' && x.weekcompcnt2}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt3',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt3 = Sum.all(data, 'weekcnt3');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt3 !== 0 && Format.number(sumWeekcnt3)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt3 !== '0' && x.weekcnt3}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt3',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt3 = Sum.all(data, 'weekcompcnt3');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt3 !== 0 && Format.number(sumWeekcompcnt3)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt3 !== '0' && x.weekcompcnt3}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt4',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt4 = Sum.all(data, 'weekcnt4');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt4 !== 0 && Format.number(sumWeekcnt4)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt4 !== '0' && x.weekcnt4}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt4',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt4 = Sum.all(data, 'weekcompcnt4');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt4 !== 0 && Format.number(sumWeekcompcnt4)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt4 !== '0' && x.weekcompcnt4}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt5',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt5 = Sum.all(data, 'weekcnt5');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt5 !== 0 && Format.number(sumWeekcnt5)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt5 !== '0' && x.weekcnt5}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt5',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt5 = Sum.all(data, 'weekcompcnt5');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt5 !== 0 && Format.number(sumWeekcompcnt5)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-blue)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.weekcompcnt5 !== '0' && x.weekcompcnt5}
                  </FlexLayout>
                ),
              },
              {
                id: 'jrececnt',
                text: '지원받음',
                width: 100,
                trail: (data) => {
                  const sumJrececnt = Sum.all(data, 'jrececnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-green)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJrececnt !== 0 && Format.number(sumJrececnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      color: 'var(--color-green)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jrececnt !== '0' && x.jrececnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'jmiscnt',
                text: '미점검',
                width: 100,
                trail: (data) => {
                  const sumJmiscnt = Sum.all(data, 'jmiscnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-red)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJmiscnt !== 0 && Format.number(sumJmiscnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-red)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jmiscnt !== '0' && x.jmiscnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'jcompcnt',
                text: '지원점검',
                width: 100,
                trail: (data) => {
                  const sumJcompcnt = Sum.all(data, 'jcompcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-green)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJcompcnt !== 0 && Format.number(sumJcompcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-green)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jcompcnt !== '0' && x.jcompcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'totcnt',
                text: '담당점검',
                width: 100,
                trail: (data) => {
                  const sumTotcnt = Sum.all(data, 'totcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumTotcnt !== 0 && Format.number(sumTotcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-blue)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.totcnt !== '0' && x.totcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'totyul',
                text: '진행률',
                width: 60,
                trail: (data) => {
                  // const sumPlanyul = Sum.all(data, 'planyul');
                  const sumTodayplan = Sum.all(data, 'todayplan');
                  const sumPlancomp = Sum.all(data, 'plancomp');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="right"
                      align="center"
                    >
                      {sumTodayplan !== 0 && sumPlancomp !== 0
                      && `${Format.number(((sumPlancomp / sumTodayplan) * 100).toFixed(0))} %`
                      }
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-violet)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.totyul !== 0 && x.totyul}%
                  </FlexLayout>
                ),
              },
              {
                id: 'send5cnt',
                text: '5일이상전송초과',
                width: 125,
                trail: (data) => {
                  const sumSend5cnt = Sum.all(data, 'send5cnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="right"
                      align="center"
                    >
                      {sumSend5cnt !== 0 && Format.number(sumSend5cnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      color: 'var(--color-red)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                    justify="right"
                    align="center"
                  >
                    {x.send5cnt !== '0' && x.send5cnt}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupChargeList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onChargeRowFocusEvent(item)}
            onHorizontalScroll={(x) => { scope.additionalTitle.current?.scrollTo(x); }}
          />

          <FlexLayout>
            {scope.state.isGraphVisible && <IFrame src={scope.state.graph} />}
          </FlexLayout>
        </FlexLayout>
      </ModalView>

        <ModalView
        width={800}
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        >
        <CustomAttachmentBox
          isEnabledPreview={true}
          window="w_popup_e035_img"
          params={{
            plandate: scope.tableFocus?.plandate,
            actcd: scope.tableFocus?.actcd,
            equpcd: scope.tableFocus?.equpcd,
          }}
        />
        </ModalView>
    </FlexLayout>
  );
};
