import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca644
 * @category 가지급정산등록
 */
export class PaymentCalculateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  snddate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  sndnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 적요
   *
   * **data type** text
   */
  remark: string;

  /**
   * 비고
   *
   * **data type** text
   */
  bigo: string;

  /**
   * 지급금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** float
   */
  tamt: string;

  /**
   * 정산금액
   *
   * **data type** float
   */
  mamt: string;

  /**
   * 잔액(미지급금액)
   *
   * **data type** tamt
   */
  iamt: string;

  /**
   * 부서
   *
   * **data type** varchar(10)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사용자
   *
   * **data type** char(30)
   */
  pernm: string;

  /**
   * 사용자아이디
   *
   * **data type** char(10)
   */
  sndperid: string;

  /**
   * 년월
   *
   * **data type** varchar(6)
   */
  yyyymm: string;

  /**
   * 계정과목
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 지급유형
   *
   * **data type** varchar(1)
   */
  amtflag: string;

  /**
   * 지급구분
   *
   * **data type** varchar(1)
   */
  calflag: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  compflag: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 지급자
   *
   * **data type** varchar(30)
   */
  inpernm: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(20)
   */
  accnum: string;

  /**
   * 지급은행
   *
   * **data type** varchar(20)
   */
  banknm: string;

  /**
   * 은행
   *
   * **data type** varchar(20)
   */
  bankcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.banknm = data.banknm || '';
    this.bankcd = data.bankcd || '';
    this.snddate = data.snddate || '';
    this.accnum = data.accnum || '';
    this.sndnum = data.sndnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.remark = data.remark || '';
    this.bigo = data.bigo || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.divicd = data.divicd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.sndperid = data.sndperid || '';
    this.yyyymm = data.yyyymm || '';
    this.acccd = data.acccd || '';
    this.amtflag = data.amtflag || '';
    this.calflag = data.calflag || '';
    this.compflag = data.compflag || '';
    this.inperid = data.inperid || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.inpernm = data.inpernm || '';
    this.indate = data.indate || '';
    this.divinm = data.divinm || '';
    this.iamt = data.iamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
