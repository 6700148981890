/**
 * 모델
 * @window w_tb_e601w_sulchi_06
 * @category 월공수현황
 */
export default class EffortModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 년
   *
   * **data type** varchar(8)
   */
  readonly endyear: string;

  /**
   * 1월
   *
   * **data type** varchar(8)
   */
  readonly mon01: string;

  /**
   * 2월
   *
   * **data type** varchar(8)
   */
  readonly mon02: string;

  /**
   * 3월
   *
   * **data type** varchar(8)
   */
  readonly mon03: string;

  /**
   * 4월
   *
   * **data type** varchar(8)
   */
  readonly mon04: string;

  /**
   * 5월
   *
   * **data type** varchar(8)
   */
  readonly mon05: string;

  /**
   * 6월
   *
   * **data type** varchar(8)
   */
  readonly mon06: string;

  /**
   * 7월
   *
   * **data type** varchar(8)
   */
  readonly mon07: string;

  /**
   * 8월
   *
   * **data type** varchar(8)
   */
  readonly mon08: string;

  /**
   * 9월
   *
   * **data type** varchar(8)
   */
  readonly mon09: string;

  /**
   * 10월
   *
   * **data type** varchar(8)
   */
  readonly mon10: string;

  /**
   * 11월
   *
   * **data type** varchar(8)
   */
  readonly mon11: string;

  /**
   * 12월
   *
   * **data type** varchar(8)
   */
  readonly mon12: string;

  /**
   * 직영구분
   *
   * **data type** varchar(8)
   */
  readonly mpclafi: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자이름
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 연월
   *
   * **data type** varchar(8)
   */
  readonly yearnm: string;


  constructor(data: any = {}) {
    this.endyear = data.endyear || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.mpclafi = data.mpclafi || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.yearnm = data.yearnm || '';
    this.custcd = data.custcd || '';
  }
}
