import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { YearSeverancePayTemplate } from './YearSeverancePay.template';
import { YearSeverancePayModel } from './YearSeverancePay.model';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface YearSeverancePayState {
  // search
  year: string;
  perid: string;
  pernm: string;

  // data
  data: Array<YearSeverancePayModel>;
  focused?: YearSeverancePayModel;
}

/**
 * 컨트롤러
 * @window w_tb_pb301_02
 * @category 년퇴직금현황
 */
export class YearSeverancePay extends PageComponent<PageProps, YearSeverancePayState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      year: Today.year(),
      perid: '',

      // data
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items,
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['no', 'perid'],
        [this.state.focused?.no, this.state.focused?.perid], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  // grid 색상
  getColotEvent(rtdate: any, year: any, date: any) {
    if (rtdate && rtdate.slice(0, 6) === year + date) {
      return { backgroundColor: '#FFEAEA' };
    } if (rtdate && rtdate.slice(0, 6) < year + date) {
      return { backgroundColor: '' };
    }
    return { backgroundColor: '#E6FFFF' };
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      perid: this.state.perid,
    });
  }

  @action
  onRowFocusEvent(item: YearSeverancePayModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<YearSeverancePayModel>, updatedRows: Array<YearSeverancePayModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <YearSeverancePayTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
