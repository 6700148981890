import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_pb204
 * @category 휴가신청서
 */

export class HolidayModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 년도
   *
   * **data type** varchar(4)
   */
  readonly vayear: string;

  /**
   * 번호
   *
   * **data type** varchar(2)
   */
  readonly vanum: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   * 작성일자
   *
   * **data type** varchar(30)
   */
  readonly rspnm: string;

  /**
   * 시작일
   *
   * **data type** varchar(20)
   */
  readonly divinm: string;

  /**
   * 종료일
   *
   * **data type** varchar(40)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly reqdate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly vafrdate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime3: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly vatodate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly vafrtime: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly vatotime: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly gigan: number;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly atdcd: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly daynum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly worknum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly workdaynum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly workdaynum2: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly usenum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly daynum2: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly yearnum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly varnum: number;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly flag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly flagdate: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly response: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly telnum: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly gowhere: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly yearflag: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly attcnt: number;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly holinum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly totdaynum: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly daynum_tot : string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly daynum_use_tot : string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly daynum_remain_tot : string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly workdaynum_tot : string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly workdaynum_use_tot : string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly workdaynum_remain_tot : string;

  autoflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.vayear = data.vayear || '';
    this.vanum = data.vanum || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.datetime = data.datetime || '';
    this.reqdate = data.reqdate || '';
    this.datetime2 = data.datetime2 || '';
    this.vafrdate = data.vafrdate || '';
    this.datetime3 = data.datetime3 || '';
    this.vatodate = data.vatodate || '';
    this.vafrtime = data.vafrtime || '';
    this.vatotime = data.vatotime || '';
    this.gigan = data.gigan || 0;
    this.atdcd = data.atdcd || '';
    this.daynum = data.daynum || 0;
    this.daynum2 = data.daynum2 || 0;
    this.worknum = data.worknum || 0;
    this.workdaynum = data.workdaynum || 0;
    this.workdaynum2 = data.workdaynum2 || '0';
    this.usenum = data.usenum || 0;
    this.yearnum = data.yearnum || 0;
    this.varnum = data.varnum || 0;
    this.remark = Fix.newline(data.remark) || '';
    this.flag = data.flag || '';
    this.flagdate = data.flagdate || '';
    this.response = data.response || '';
    this.telnum = data.telnum || '';
    this.gowhere = data.gowhere || '';
    this.appdate = data.appdate || '';
    this.apptime = data.apptime || '';
    this.appnum = data.appnum || '';
    this.appgubun = data.appgubun || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.yearflag = data.yearflag || '';
    this.attcnt = data.attcnt || 0;
    this.refer = data.refer || '';
    this.picflag = data.picflag || '';
    this.holinum = data.holinum || 0;
    this.totdaynum = data.totdaynum || 0;
    this.daynum_tot = data.daynum_tot || '';
    this.daynum_use_tot = data.daynum_use_tot || '';
    this.daynum_remain_tot = data.daynum_remain_tot || '';
    this.workdaynum_tot = data.workdaynum_tot || '';
    this.workdaynum_use_tot = data.workdaynum_use_tot || '';
    this.autoflag = data.autoflag || '0';
    this.workdaynum_remain_tot = data.workdaynum_remain_tot || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
