import { TextBox } from '../../../../../components/forms/TextBox';
import { TableLayoutHeader } from '../../../../../constants';
import { EducationModel } from '../models';
import {
  PersonnelResistration,
} from '../PersonnelResistration';
import { DateTextBox } from '../../../../../components/forms/DateTextBox';

// 교육
const Education: TableLayoutHeader<EducationModel, PersonnelResistration>[] = [
  {
    id: 'educd',
    text: '순번',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.educd}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'orgnm',
    text: '교육기관',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.orgnm}
        onChange={(value) => rowUpdate({ orgnm: value })}
      />
    ),
  },
  {
    id: 'edunm',
    text: '교육명',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.edunm}
        onChange={(value) => rowUpdate({ edunm: value })}
      />
    ),
  },
  {
    id: 'sdate',
    text: '시작일',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        format="####-##-##"
        textAlign="center"
        ref={ref}
        value={x.sdate}
        onChange={(value) => rowUpdate({ sdate: value })}
      />
    ),
  },
  {
    id: 'edate',
    text: '종료일',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        format="####-##-##"
        textAlign="center"
        ref={ref}
        value={x.edate}
        onChange={(value) => rowUpdate({ edate: value })}
      />
    ),
  },
  {
    id: 'ref',
    text: '비고',
    width: 300,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.ref}
        onChange={(value) => rowUpdate({ ref: value })}
      />
    ),
  },
];

export default Education;
