import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import {
  GridLayout,
  GridLayoutAdditionalHeader,
  TableLayout,
} from '../../../../components';
import { AchievementStatusModel } from './AchievementStatus.model';
import { AchievementStatusTemplate } from './AchievementStatus.template';
import { Today } from '../../../../utils/time';
import { BusiPopupModel } from './models/BusiPopup.model';
import { CostPopupModel } from './models/CostPopup.model';
import { CompPopupModel } from './models/CompPopup.model';
import { CompPeridsPopupModel } from './models/CompPeridsPopup.model';
import { KcqtyPopupModel } from './models/KcqtyPopup.model';
import { MisPopupModel } from './models/MisPopup.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { SurPopupModel } from './models/SurPopup.model';
import { SurPeridsPopupModel } from './models/SurPeridsPopup.model';
import { BalPopupModel } from './models/BalPopupModel';
import { RemarkPopupModel } from './models/RemarkPopup.model';

interface AchievementStatusState {
  year: string;
  mon: string;

  data: AchievementStatusModel;
  dataList: Array<AchievementStatusModel>;
  stdate: string;

  // 공통코드
  gubuns: Array<any> // 매출구분
  bildjus: Array<any> // 모델인증서

  // Modal Data
  busiModalData: Array<BusiPopupModel>
  costModalData: Array<CostPopupModel>
  compModalData: Array<CompPopupModel>
  compPeridsModalData: Array<CompPeridsPopupModel>
  compPeridsModalMonData: Array<CompPeridsPopupModel>
  surPeridsModalData: Array<SurPeridsPopupModel>
  surPeridsModalMonData: Array<SurPeridsPopupModel>
  desPeridsModalData: Array<SurPeridsPopupModel>
  desPeridsModalMonData: Array<SurPeridsPopupModel>
  surModalData: Array<SurPopupModel>
  balModalData: Array<BalPopupModel>
  desModalData: Array<SurPopupModel>
  kcqtyModalData: Array<KcqtyPopupModel>
  misModalData: Array<MisPopupModel>
  misModalFocusedData: MisPopupModel

  // 영업 trail
  busi_tot?: string;
  busi_state0_tot?: string;
  busi_state1_tot?: string;

  // 견적 trail
  cost_cnt0_tot?: string;
  cost_amt0_tot?: string;
  cost_cnt1_tot?: string;
  cost_amt1_tot?: string;
  cost_cnt_tot?: string;
  cost_amt_tot?: string;

  // 계약 trail
  comp_cnt0_tot?: string;
  comp_qty_cnt0_tot?: string;
  comp_amt0_tot?: string;
  comp_cnt1_tot?: string;
  comp_qty_cnt1_tot?: string;
  comp_amt1_tot?: string;
  comp_cnt_tot?: string;
  comp_qty_cnt_tot?: string;
  comp_amt_tot?: string;

  // 계약자 trail
  comp_perids_tot?: string;

  // 계약달성율 trail
  comp_yul0_tot?: string;
  comp_yul1_tot?: string;

  // 계약공사구분 trail
  comp_tot?: string;
  comp_gubun14_tot?: string;
  comp_gubun15_tot?: string;

  // 설치수금 trail
  kcqty_tot?: string;
  misamt2_tot?: string;
  rcvamt2_tot?: string;
  iamt2_tot?: string;

  // 실측자 trail
  sur_cnt0_tot?: string
  sur_cnt1_tot?: string
  sur_cnt_tot?: string
  sur_notcnt0_tot?: string
  sur_notcnt1_tot?: string
  sur_notcnt_tot?: string
  sur_perids_tot?: string
  sur_qty_cnt0_tot?: string
  sur_qty_cnt1_tot?: string
  sur_qty_cnt_tot?: string
  sur_qty_notcnt0_tot?: string
  sur_qty_notcnt1_tot?: string
  sur_qty_notcnt_tot?: string

  // 설계 trail
  des_cnt0_tot?: string
  des_cnt1_tot?: string
  des_cnt_tot?: string
  des_notcnt0_tot?: string
  des_notcnt1_tot?: string
  des_notcnt_tot?: string
  des_perids_tot?: string
  des_qty_cnt0_tot?: string
  des_qty_cnt1_tot?: string
  des_qty_cnt_tot?: string
  des_qty_notcnt0_tot?: string
  des_qty_notcnt1_tot?: string
  des_qty_notcnt_tot?: string

  // 착공 trail
  bal_cnt0_tot?: string
  bal_cnt1_tot?: string
  bal_cnt_tot?: string
  bal_ingcnt_tot?: string
  bal_qty_cnt0_tot?: string
  bal_qty_cnt1_tot?: string
  bal_qty_cnt_tot?: string
  bal_qty_ingcnt_tot?: string

  planamt_tot?: string
  plan_yul_tot?: string


  // 팝업
  busiModal: boolean;
  costModal: boolean;
  compModal: boolean;
  compPeridsModal: boolean;
  compPeridsMonModal: boolean;
  surPeridsModal: boolean;
  surPeridsMonModal: boolean;
  surModal: boolean;
  desModal: boolean;
  desPeridsModal: boolean;
  desPeridsMonModal: boolean;
  kcqtyModal: boolean;
  misModal: boolean;
  balModal: boolean;
  peridsModalType?: boolean;
  peridsModalTypeCheck?: string;
  surPeridsModalType?: boolean;
  surPeridsModalTypeCheck?: string;
  desPeridsModalType?: boolean;
  desPeridsModalTypeCheck?: string;

  // 팝업 trail
  cnt_tot?: string;
  mamt_tot?: string;
  samt_tot?: string;
  tamt_tot?: string;

  contamt_tot?: string;
  endamt_tot?: string;
  iamt_tot?: string;
  midamt_tot?: string;
  qty_tot?: string;
  rcvamt_tot?: string;
  stamt_tot?: string;
  balamt_tot?: string;

  cnttot_tot?: string,
  cnt01_tot?: string,
  cnt02_tot?: string,
  cnt03_tot?: string,
  cnt04_tot?: string,
  cnt05_tot?: string,
  cnt06_tot?: string,
  cnt07_tot?: string,
  cnt08_tot?: string,
  cnt09_tot?: string,
  cnt10_tot?: string,
  cnt11_tot?: string,
  cnt12_tot?: string,

  qtytot_tot?: string,
  qty01_tot?: string,
  qty02_tot?: string,
  qty03_tot?: string,
  qty04_tot?: string,
  qty05_tot?: string,
  qty06_tot?: string,
  qty07_tot?: string,
  qty08_tot?: string,
  qty09_tot?: string,
  qty10_tot?: string,
  qty11_tot?: string,
  qty12_tot?: string,

  mamttot_tot?: string,
  mamt01_tot?: string,
  mamt02_tot?: string,
  mamt03_tot?: string,
  mamt04_tot?: string,
  mamt05_tot?: string,
  mamt06_tot?: string,
  mamt07_tot?: string,
  mamt08_tot?: string,
  mamt09_tot?: string,
  mamt10_tot?: string,
  mamt11_tot?: string,
  mamt12_tot?: string,

  // 더블클릭시 tab 이름
  tabNames?: string;

  modalNames?: string;

  // 미수금 팝업창 -> 진행현황 팝업창
  remarkModal: boolean;
  remarkModalData: Array<RemarkPopupModel>;
  remarkModalFocusedData: RemarkPopupModel;
}

/**
 *
 * 컨트롤러
 * @window w_tb_e601w_sulchi_09
 * @category 설치계약달성현황.
 */
export class AchievementStatus extends PageComponent<PageProps, AchievementStatusState>
  implements PageToolEvents {
  updatedRows?: Array<RemarkPopupModel>;

  infinity?: InfinityRetrieve;

  busiPopupInfinity?: InfinityRetrieve;

  costPopupInfinity?: InfinityRetrieve;

  compPopupInfinity?: InfinityRetrieve;

  compPeridsPopupInfinity?: InfinityRetrieve;

  compPeridsMonPopupInfinity?: InfinityRetrieve;

  surPopupInfinity?: InfinityRetrieve;

  balPopupInfinity?: InfinityRetrieve;

  surPeridsPopupInfinity?: InfinityRetrieve;

  surPeridsMonPopupInfinity?: InfinityRetrieve;

  desPopupInfinity?: InfinityRetrieve;

  desPeridsPopupInfinity?: InfinityRetrieve;

  desPeridsMonPopupInfinity?: InfinityRetrieve;

  kcqtyPopupInfinity?: InfinityRetrieve;

  misPopupInfinity?: InfinityRetrieve;

  remarkPopupInfinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  busiGrid: React.RefObject<GridLayout> = React.createRef();

  costGrid: React.RefObject<GridLayout> = React.createRef();

  compGrid: React.RefObject<GridLayout> = React.createRef();

  surGrid: React.RefObject<GridLayout> = React.createRef();

  surPeridsGrid: React.RefObject<GridLayout> = React.createRef();

  surPeridsMonGrid: React.RefObject<GridLayout> = React.createRef();

  desGrid: React.RefObject<GridLayout> = React.createRef();

  desPeridsGrid: React.RefObject<GridLayout> = React.createRef();

  desPeridsMonGrid: React.RefObject<GridLayout> = React.createRef();

  compPeridsGrid: React.RefObject<GridLayout> = React.createRef();

  compPeridsMonGrid: React.RefObject<GridLayout> = React.createRef();

  compGubunGrid: React.RefObject<GridLayout> = React.createRef();

  kcqtyGrid: React.RefObject<GridLayout> = React.createRef();

  misGrid: React.RefObject<GridLayout> = React.createRef();

  remarkTable: React.RefObject<TableLayout> = React.createRef();

  balGrid: React.RefObject<GridLayout> = React.createRef();

  mainGridAdditionalHeader: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  mainGridAdditionalHeader2: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  surPopupGridAdditionalHeader: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  desPopupGridAdditionalHeader: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // data
      data: [],
      dataList: [],
      year: Today.year(),
      mon: '',

      // Modal Data
      busiModalData: [],
      costModalData: [],
      compModalData: [],
      compPeridsModalData: [],
      compPeridsModalMonData: [],
      surModalData: [],
      surPeridsModalData: [],
      surPeridsModalMonData: [],
      desModalData: [],
      desPeridsModalData: [],
      desPeridsModalMonData: [],
      kcqtyModalData: [],
      misModalData: [],

      // 영업 trail
      busi_tot: '0',
      busi_state0_tot: '0',
      busi_state1_tot: '0',

      // 견적 trail
      cost_cnt0_tot: '0',
      cost_amt0_tot: '0',
      cost_cnt1_tot: '0',
      cost_amt1_tot: '0',
      cost_cnt_tot: '0',
      cost_amt_tot: '0',

      // 계약 trail
      comp_cnt0_tot: '0',
      comp_qty_cnt0_tot: '0',
      comp_amt0_tot: '0',
      comp_cnt1_tot: '0',
      comp_qty_cnt1_tot: '0',
      comp_amt1_tot: '0',
      comp_cnt_tot: '0',
      comp_qty_cnt_tot: '0',
      comp_amt_tot: '0',

      // 계약자 trail
      comp_perids_tot: '0',

      // 계약달성율 trail
      comp_yul0_tot: '0',
      comp_yul1_tot: '0',

      // 계약공사구분 trail
      comp_tot: '0',
      comp_gubun14_tot: '0',
      comp_gubun15_tot: '0',

      // 설치수금 trail
      kcqty_tot: '0',
      misamt2_tot: '0',
      rcvamt2_tot: '0',
      iamt2_tot: '0',

      // 실측 trail
      sur_cnt0_tot: '0',
      sur_cnt1_tot: '0',
      sur_cnt_tot: '0',
      sur_notcnt0_tot: '0',
      sur_notcnt1_tot: '0',
      sur_notcnt_tot: '0',
      sur_perids_tot: '0',
      sur_qty_cnt0_tot: '0',
      sur_qty_cnt1_tot: '0',
      sur_qty_cnt_tot: '0',
      sur_qty_notcnt0_tot: '0',
      sur_qty_notcnt1_tot: '0',
      sur_qty_notcnt_tot: '0',

      // 설계 trail
      des_cnt0_tot: '0',
      des_cnt1_tot: '0',
      des_cnt_tot: '0',
      des_notcnt0_tot: '0',
      des_notcnt1_tot: '0',
      des_notcnt_tot: '0',
      des_perids_tot: '0',
      des_qty_cnt0_tot: '0',
      des_qty_cnt1_tot: '0',
      des_qty_cnt_tot: '0',
      des_qty_notcnt0_tot: '0',
      des_qty_notcnt1_tot: '0',
      des_qty_notcnt_tot: '0',

      // 착공 trail
      bal_cnt0_tot: '0',
      bal_cnt1_tot: '0',
      bal_cnt_tot: '0',
      bal_ingcnt_tot: '0',
      bal_qty_cnt0_tot: '0',
      bal_qty_cnt1_tot: '0',
      bal_qty_cnt_tot: '0',
      bal_qty_ingcnt_tot: '0',

      planamt_tot: '0',
      plan_yul_tot: '0',

      // Modal open default
      busiModal: false,
      costModal: false,
      compModal: false,
      compPeridsModal: false,
      compPeridsMonModal: false,
      surPeridsModal: false,
      surPeridsMonModal: false,
      desPeridsModal: false,
      desPeridsMonModal: false,
      surModal: false,
      desModal: false,
      kcqtyModal: false,
      misModal: false,
      misModalFocusedData: [],
      peridsModalType: false,
      peridsModalTypeCheck: '1',
      surPeridsModalType: false,
      surPeridsModalTypeCheck: '1',
      desPeridsModalType: false,
      desPeridsModalTypeCheck: '1',

      // Modal tab Name
      tabNames: '',

      // 팝업 trail
      cnt_tot: '0', // 합계
      mamt_tot: '0', // 합계금액
      samt_tot: '0', // 공급가액
      tamt_tot: '0', // 부가세액

      contamt_tot: '0', // 계약금
      stamt_tot: '0', // 선금
      midamt_tot: '0', // 중도금
      endamt_tot: '0', // 잔금
      rcvamt_tot: '0', // 입금액
      iamt_tot: '0', // 잔액
      qty_tot: '0', // 총 대수
      balamt_tot: '0',

      cnttot_tot: '0',
      cnt01_tot: '0',
      cnt02_tot: '0',
      cnt03_tot: '0',
      cnt04_tot: '0',
      cnt05_tot: '0',
      cnt06_tot: '0',
      cnt07_tot: '0',
      cnt08_tot: '0',
      cnt09_tot: '0',
      cnt10_tot: '0',
      cnt11_tot: '0',
      cnt12_tot: '0',

      qtytot_tot: '0',
      qty01_tot: '0',
      qty02_tot: '0',
      qty03_tot: '0',
      qty04_tot: '0',
      qty05_tot: '0',
      qty06_tot: '0',
      qty07_tot: '0',
      qty08_tot: '0',
      qty09_tot: '0',
      qty10_tot: '0',
      qty11_tot: '0',
      qty12_tot: '0',

      mamttot_tot: '0',
      mamt01_tot: '0',
      mamt02_tot: '0',
      mamt03_tot: '0',
      mamt04_tot: '0',
      mamt05_tot: '0',
      mamt06_tot: '0',
      mamt07_tot: '0',
      mamt08_tot: '0',
      mamt09_tot: '0',
      mamt10_tot: '0',
      mamt11_tot: '0',
      mamt12_tot: '0',

      modalName: '',

      // 미수금팝업창 -> 진행현황 팝업창
      remarkModal: false,
      remarkModalData: [],
      remarkModalFocusedData: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 매출구분 코드
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ gubuns: data?.items });

    const data2 = await api.dropdown('wf_dd_ca510_301_01');
    if (!data2) return;
    this.setState({ bildjus: data2?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    await this.SS({ dataList: [] });
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
      },
      (params) => (api.retrieve(params)),
      (items) => {
        this.setState({
          dataList: [
            ...this.state.dataList,
            ...items.map((x: any) => new AchievementStatusModel(x)),
          ],
        });
      },
    );
    await this.infinity?.retrieveAll();

    // items 밖에있는 total값
    await this.SS({
      planamt_tot: this.infinity?.box?.planamt_tot || '0',
      plan_yul_tot: this.infinity?.box?.plan_yul_tot || '0',
      busi_tot: this.infinity?.box?.busi_tot || '0',
      busi_state0_tot: this.infinity?.box?.busi_state0_tot || '0',
      busi_state1_tot: this.infinity?.box?.busi_state1_tot || '0',
      cost_cnt0_tot: this.infinity?.box?.cost_cnt0_tot || '0',
      cost_amt0_tot: this.infinity?.box?.cost_amt0_tot || '0',
      cost_cnt1_tot: this.infinity?.box?.cost_cnt1_tot || '0',
      cost_amt1_tot: this.infinity?.box?.cost_amt1_tot || '0',
      cost_cnt_tot: this.infinity?.box?.cost_cnt_tot || '0',
      cost_amt_tot: this.infinity?.box?.cost_amt_tot || '0',
      comp_cnt0_tot: this.infinity?.box?.comp_cnt0_tot || '0',
      comp_qty_cnt0_tot: this.infinity?.box?.comp_qty_cnt0_tot || '0',
      comp_amt0_tot: this.infinity?.box?.comp_amt0_tot || '0',
      comp_cnt1_tot: this.infinity?.box?.comp_cnt1_tot || '0',
      comp_qty_cnt1_tot: this.infinity?.box?.comp_qty_cnt1_tot || '0',
      comp_amt1_tot: this.infinity?.box?.comp_amt1_tot || '0',
      comp_cnt_tot: this.infinity?.box?.comp_cnt_tot || '0',
      comp_qty_cnt_tot: this.infinity?.box?.comp_qty_cnt_tot || '0',
      comp_amt_tot: this.infinity?.box?.comp_amt_tot || '0',
      comp_perids_tot: this.infinity?.box?.comp_perids_tot || '0',
      comp_yul0_tot: this.infinity?.box?.comp_yul0_tot || '0',
      comp_yul1_tot: this.infinity?.box?.comp_yul1_tot || '0',
      comp_tot: this.infinity?.box?.comp_tot || '0',
      comp_gubun14_tot: this.infinity?.box?.comp_gubun14_tot || '0',
      comp_gubun15_tot: this.infinity?.box?.comp_gubun15_tot || '0',
      kcqty_tot: this.infinity?.box?.kcqty_tot || '0',
      misamt2_tot: this.infinity?.box?.misamt2_tot || '0',
      rcvamt2_tot: this.infinity?.box?.rcvamt2_tot || '0',
      iamt2_tot: this.infinity?.box?.iamt2_tot || '0',
      sur_cnt0_tot: this.infinity?.box?.sur_cnt0_tot || '0',
      sur_cnt1_tot: this.infinity?.box?.sur_cnt1_tot || '0',
      sur_cnt_tot: this.infinity?.box?.sur_cnt_tot || '0',
      sur_notcnt0_tot: this.infinity?.box?.sur_notcnt0_tot || '0',
      sur_notcnt1_tot: this.infinity?.box?.sur_notcnt1_tot || '0',
      sur_notcnt_tot: this.infinity?.box?.sur_notcnt_tot || '0',
      sur_perids_tot: this.infinity?.box?.sur_perids_tot || '0',
      sur_qty_cnt0_tot: this.infinity?.box?.sur_qty_cnt0_tot || '0',
      sur_qty_cnt1_tot: this.infinity?.box?.sur_qty_cnt1_tot || '0',
      sur_qty_cnt_tot: this.infinity?.box?.sur_qty_cnt_tot || '0',
      sur_qty_notcnt0_tot: this.infinity?.box?.sur_qty_notcnt0_tot || '0',
      sur_qty_notcnt1_tot: this.infinity?.box?.sur_qty_notcnt1_tot || '0',
      sur_qty_notcnt_tot: this.infinity?.box?.sur_qty_notcnt_tot || '0',
      des_cnt0_tot: this.infinity?.box?.des_cnt0_tot || '0',
      des_cnt1_tot: this.infinity?.box?.des_cnt1_tot || '0',
      des_cnt_tot: this.infinity?.box?.des_cnt_tot || '0',
      des_notcnt0_tot: this.infinity?.box?.des_notcnt0_tot || '0',
      des_notcnt1_tot: this.infinity?.box?.des_notcnt1_tot || '0',
      des_notcnt_tot: this.infinity?.box?.des_notcnt_tot || '0',
      des_perids_tot: this.infinity?.box?.des_perids_tot || '0',
      des_qty_cnt0_tot: this.infinity?.box?.des_qty_cnt0_tot || '0',
      des_qty_cnt1_tot: this.infinity?.box?.des_qty_cnt1_tot || '0',
      des_qty_cnt_tot: this.infinity?.box?.des_qty_cnt_tot || '0',
      des_qty_notcnt0_tot: this.infinity?.box?.des_qty_notcnt0_tot || '0',
      des_qty_notcnt1_tot: this.infinity?.box?.des_qty_notcnt1_tot || '0',
      des_qty_notcnt_tot: this.infinity?.box?.des_qty_notcnt_tot || '0',
      bal_cnt0_tot: this.infinity?.box?.bal_cnt0_tot || '0',
      bal_cnt1_tot: this.infinity?.box?.bal_cnt1_tot || '0',
      bal_cnt_tot: this.infinity?.box?.bal_cnt_tot || '0',
      bal_ingcnt_tot: this.infinity?.box?.bal_ingcnt_tot || '0',
      bal_qty_cnt0_tot: this.infinity?.box?.bal_qty_cnt0_tot || '0',
      bal_qty_cnt1_tot: this.infinity?.box?.bal_qty_cnt1_tot || '0',
      bal_qty_cnt_tot: this.infinity?.box?.bal_qty_cnt_tot || '0',
      bal_qty_ingcnt_tot: this.infinity?.box?.bal_qty_ingcnt_tot || '0',
    });

    this.grid.current?.forceRepaint();
    this.grid2.current?.forceRepaint();
  }

  @action
  async onRowFocusEvent(item:AchievementStatusModel) {
    this.setState({ mon: item.mon });
  }

  @action
  async onBusiModal(item: AchievementStatusModel, isOpen: boolean, name: string) {
    let modalName = '';

    if (name === 'busi' || name === 'busi_state0') {
      modalName = '[영업]';
    } else if (name === 'busi_state1') {
      modalName = '[]';
    }

    await this.SS({
      mon: item.mon,
      busiModal: isOpen,
      modalNames: modalName,
    });
    await this.onBusiModalRetrieve();
  }

  @action
  async onBusiModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ busiModalData: [] });

    this.busiPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: 'busi',
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          busiModalData: [...this.state.busiModalData || [], ...items.map((x) => new BusiPopupModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          busiModalData: [],
        });

        await this.busiPopupInfinity?.retrieveAll();
        this.busiGrid.current?.setFocus(0);
      },
    );
    await this.busiPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.busiPopupInfinity?.box?.cnt_tot || '0',
    });
    this.busiGrid.current?.forceRepaint();
  }

  @action
  async onCostModal(item: AchievementStatusModel, isOpen: boolean, tabName: string) {
    let modalName = '';
    if (tabName === 'cost0') {
      modalName = '[견적-관급]';
    } else if (tabName === 'cost1') {
      modalName = '[견적-사급]';
    } else if (tabName === 'cost') {
      modalName = '[견적-관급+사급]';
    }
    this.setState({
      mon: item.mon, costModal: isOpen, tabNames: tabName, modalNames: modalName,
    });

    await this.onCostModalRetrieve();
  }

  @action
  async onCostModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ costModalData: [] });

    this.costPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          costModalData: [...this.state.costModalData || [], ...items.map((x) => new CostPopupModel(x)),
          ],
        });
      },
    );
    await this.costPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.costPopupInfinity?.box?.cnt_tot || '0',
      mamt_tot: this.costPopupInfinity?.box?.mamt_tot || '0',
      samt_tot: this.costPopupInfinity?.box?.samt_tot || '0',
      tamt_tot: this.costPopupInfinity?.box?.tamt_tot || '0',
    });
    this.costGrid.current?.forceRepaint();
  }

  @action
  async onCompModal(item: AchievementStatusModel, isOpen: boolean, tabName: string) {
    let modalName = '';
    if (tabName === 'comp0') {
      modalName = '[계약-관급]';
    } else if (tabName === 'comp1') {
      modalName = '[계약-사급]';
    } else if (tabName === 'comp') {
      modalName = '[게약-관급-사급]';
    } else if (tabName === 'comp_gubun14') {
      modalName = '[계약-설치]';
    } else if (tabName === 'comp_gubun15') {
      modalName = '[계약-리모델링]';
    }
    this.setState({
      mon: item.mon, compModal: isOpen, tabNames: tabName, modalNames: modalName,
    });

    await this.onCompModalRetrieve();
  }

  @action
  async onCompModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ compModalData: [] });

    this.compPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          compModalData: [...this.state.compModalData || [], ...items.map((x) => new CompPopupModel(x)),
          ],
        });
      },
    );
    await this.compPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.compPopupInfinity?.box?.cnt_tot || '0',
      mamt_tot: this.compPopupInfinity?.box?.mamt_tot || '0',
      samt_tot: this.compPopupInfinity?.box?.samt_tot || '0',
      tamt_tot: this.compPopupInfinity?.box?.tamt_tot || '0',
    });
    this.compGrid.current?.forceRepaint();
  }

  @action
  async peridsModalType(item: AchievementStatusModel, isOpen: boolean) {
    this.setState({
      peridsModalType: isOpen,
      data: item,
      peridsModalTypeCheck: '1',
    });
  }

  @action
  async surPeridsModalType(item: AchievementStatusModel, isOpen: boolean) {
    this.setState({
      surPeridsModalType: isOpen,
      data: item,
      surPeridsModalTypeCheck: '1',
    });
  }

  @action
  async onSurPeridsMonModal(isOpen: boolean) {
    let tabName = '';
    let modalName = '';

    tabName = 'sur_perids';
    modalName = '[실측자]';


    this.setState({
      mon: this.state.data.mon, surPeridsMonModal: isOpen, tabNames: tabName, modalNames: modalName, surPeridsModalType: false,
    });

    await this.onSurPeridsModalMonRetrieve();
  }

  @action
  async onSurPeridsModal(isOpen: boolean) {
    let tabName = '';
    let modalName = '';

    tabName = 'sur_perids_tot';
    modalName = '[실측자-총합계]';

    await this.setState({
      mon: this.state.data.mon,
      surPeridsModal: isOpen,
      tabNames: tabName,
      modalNames: modalName,
      surPeridsModalType: false,
    });

    await this.onSurPeridsModalRetrieve();
  }

  @action
  async onSurPeridsModalMonRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ surPeridsModalMonData: [] });

    this.surPeridsMonPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          surPeridsModalMonData: [...this.state.surPeridsModalMonData || [], ...items.map((x) => new SurPeridsPopupModel(x)),
          ],
        });
      },
    );
    await this.surPeridsMonPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.compPeridsMonPopupInfinity?.box?.cnt_tot || '',
      qty_tot: this.compPeridsMonPopupInfinity?.box?.qty_tot || '',
    });
    this.surPeridsMonGrid.current?.forceRepaint();
  }

  @action
  async onSurPeridsModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ surPeridsModalData: [] });

    this.surPeridsPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          surPeridsModalData: [...this.state.surPeridsModalData || [], ...items.map((x) => new SurPeridsPopupModel(x)),
          ],
        });
      },
    );
    await this.surPeridsPopupInfinity?.retrieveAll();
    await this.SS({
      cnttot_tot: this.surPeridsPopupInfinity?.box?.cnttot_tot || '',
      cnt01_tot: this.surPeridsPopupInfinity?.box?.cnt01_tot || '',
      cnt02_tot: this.surPeridsPopupInfinity?.box?.cnt02_tot || '',
      cnt03_tot: this.surPeridsPopupInfinity?.box?.cnt03_tot || '',
      cnt04_tot: this.surPeridsPopupInfinity?.box?.cnt04_tot || '',
      cnt05_tot: this.surPeridsPopupInfinity?.box?.cnt05_tot || '',
      cnt06_tot: this.surPeridsPopupInfinity?.box?.cnt06_tot || '',
      cnt07_tot: this.surPeridsPopupInfinity?.box?.cnt07_tot || '',
      cnt08_tot: this.surPeridsPopupInfinity?.box?.cnt08_tot || '',
      cnt09_tot: this.surPeridsPopupInfinity?.box?.cnt09_tot || '',
      cnt10_tot: this.surPeridsPopupInfinity?.box?.cnt10_tot || '',
      cnt11_tot: this.surPeridsPopupInfinity?.box?.cnt11_tot || '',
      cnt12_tot: this.surPeridsPopupInfinity?.box?.cnt12_tot || '',
      qtytot_tot: this.surPeridsPopupInfinity?.box?.qtytot_tot || '',
      qty01_tot: this.surPeridsPopupInfinity?.box?.qty01_tot || '',
      qty02_tot: this.surPeridsPopupInfinity?.box?.qty02_tot || '',
      qty03_tot: this.surPeridsPopupInfinity?.box?.qty03_tot || '',
      qty04_tot: this.surPeridsPopupInfinity?.box?.qty04_tot || '',
      qty05_tot: this.surPeridsPopupInfinity?.box?.qty05_tot || '',
      qty06_tot: this.surPeridsPopupInfinity?.box?.qty06_tot || '',
      qty07_tot: this.surPeridsPopupInfinity?.box?.qty07_tot || '',
      qty08_tot: this.surPeridsPopupInfinity?.box?.qty08_tot || '',
      qty09_tot: this.surPeridsPopupInfinity?.box?.qty09_tot || '',
      qty10_tot: this.surPeridsPopupInfinity?.box?.qty10_tot || '',
      qty11_tot: this.surPeridsPopupInfinity?.box?.qty11_tot || '',
      qty12_tot: this.surPeridsPopupInfinity?.box?.qty12_tot || '',
    });
    this.surPeridsGrid.current?.forceRepaint();
  }

  @action
  async onSurModal(item: AchievementStatusModel, isOpen: boolean, tabName: string) {
    let modalName = '';

    await this.SS({ surModalData: [] });

    if (tabName === 'sur_cnt0') {
      modalName = '[관급실측]';
    } else if (tabName === 'sur_notcnt0') {
      modalName = '[관급미실측]';
    } else if (tabName === 'sur_cnt1') {
      modalName = '[사급실측]';
    } else if (tabName === 'sur_notcnt1') {
      modalName = '[사급미실측]';
    } else if (tabName === 'sur_cnt') {
      modalName = '[총실측]';
    } else if (tabName === 'sur_notcnt') {
      modalName = '[총미실측]';
    }

    await this.setState({
      mon: item.mon,
      surModal: isOpen,
      modalNames: modalName,
      tabNames: tabName,
    });

    await this.onSurModalRetrieve();
  }

  @action
  async onSurModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ surModalData: [] });

    this.surPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          surModalData: [...this.state.surModalData || [], ...items.map((x) => new SurPopupModel(x)),
          ],
        });
      },
    );
    await this.surPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.surPopupInfinity?.box?.cnt_tot || '0',
      qty_tot: this.surPopupInfinity?.box?.qty_tot || '0',
      mamt_tot: this.surPopupInfinity?.box?.mamt_tot || '0',
      samt_tot: this.surPopupInfinity?.box?.samt_tot || '0',
      tamt_tot: this.surPopupInfinity?.box?.tamt_tot || '0',
      balamt_tot: this.surPopupInfinity?.box?.balamt_tot || '0',
      contamt_tot: this.surPopupInfinity?.box?.contamt_tot || '0',
      endamt_tot: this.surPopupInfinity?.box?.endamt_tot || '0',
      iamt_tot: this.surPopupInfinity?.box?.iamt_tot || '0',
      midamt_tot: this.surPopupInfinity?.box?.midamt_tot || '0',
      rcvamt_tot: this.surPopupInfinity?.box?.rcvamt_tot || '0',
      stamt_tot: this.surPopupInfinity?.box?.stamt_tot || '0',
    });
    this.surGrid.current?.forceRepaint();
  }

  @action
  async onDesModal(item: AchievementStatusModel, isOpen: boolean, tabName: string) {
    let modalName = '';

    await this.SS({ desModalData: [] });

    if (tabName === 'des_cnt0') {
      modalName = '[관급설계]';
    } else if (tabName === 'des_notcnt0') {
      modalName = '[관급미설계]';
    } else if (tabName === 'des_cnt1') {
      modalName = '[사급설계]';
    } else if (tabName === 'desnotcnt1') {
      modalName = '[사급미설계]';
    } else if (tabName === 'des_cnt') {
      modalName = '[총설계]';
    } else if (tabName === 'des_notcnt') {
      modalName = '[총미설계]';
    }

    await this.setState({
      mon: item.mon,
      desModal: isOpen,
      modalNames: modalName,
      tabNames: tabName,
    });

    await this.onDesModalRetrieve();
  }

  @action
  async onDesModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ desModalData: [] });

    this.desPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          desModalData: [...this.state.desModalData || [], ...items.map((x) => new SurPopupModel(x)),
          ],
        });
      },
    );
    await this.desPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.desPopupInfinity?.box?.cnt_tot || '0',
      qty_tot: this.desPopupInfinity?.box?.qty_tot || '0',
      mamt_tot: this.desPopupInfinity?.box?.mamt_tot || '0',
      samt_tot: this.desPopupInfinity?.box?.samt_tot || '0',
      tamt_tot: this.desPopupInfinity?.box?.tamt_tot || '0',
      balamt_tot: this.desPopupInfinity?.box?.balamt_tot || '0',
      contamt_tot: this.desPopupInfinity?.box?.contamt_tot || '0',
      endamt_tot: this.desPopupInfinity?.box?.endamt_tot || '0',
      iamt_tot: this.desPopupInfinity?.box?.iamt_tot || '0',
      midamt_tot: this.desPopupInfinity?.box?.midamt_tot || '0',
      rcvamt_tot: this.desPopupInfinity?.box?.rcvamt_tot || '0',
      stamt_tot: this.desPopupInfinity?.box?.stamt_tot || '0',
    });
    this.desGrid.current?.forceRepaint();
  }

  @action
  async desPeridsModalType(item: AchievementStatusModel, isOpen: boolean) {
    this.setState({
      desPeridsModalType: isOpen,
      data: item,
      desPeridsModalTypeCheck: '1',
    });
  }

  @action
  async onBalModal(item: AchievementStatusModel, isOpen: boolean, tabName: string) {
    let modalName = '';

    await this.SS({ balModalData: [] });

    if (tabName === 'bal_cnt0') {
      modalName = '[관급착공]';
    } else if (tabName === 'bal_cnt1') {
      modalName = '[사급착공]';
    } else if (tabName === 'bal_cnt') {
      modalName = '[총착공]';
    } else if (tabName === 'bal_ingcnt') {
      modalName = '[착공진행중[누적]]';
    } else if (tabName === 'bal_notcnt') {
      modalName = '[미착공]';
    }

    await this.setState({
      mon: item.mon,
      balModal: isOpen,
      modalNames: modalName,
      tabNames: tabName,
    });

    await this.onBalModalRetrieve();
  }

  @action
  async onBalModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ balModalData: [] });

    this.balPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          balModalData: [...this.state.balModalData || [], ...items.map((x) => new BalPopupModel(x)),
          ],
        });
      },
    );
    await this.balPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.balPopupInfinity?.box?.cnt_tot || '0',
      qty_tot: this.balPopupInfinity?.box?.qty_tot || '0',
      mamt_tot: this.balPopupInfinity?.box?.mamt_tot || '0',
      samt_tot: this.balPopupInfinity?.box?.samt_tot || '0',
      tamt_tot: this.balPopupInfinity?.box?.tamt_tot || '0',
      balamt_tot: this.balPopupInfinity?.box?.balamt_tot || '0',
      contamt_tot: this.balPopupInfinity?.box?.contamt_tot || '0',
      endamt_tot: this.balPopupInfinity?.box?.endamt_tot || '0',
      iamt_tot: this.balPopupInfinity?.box?.iamt_tot || '0',
      midamt_tot: this.balPopupInfinity?.box?.midamt_tot || '0',
      rcvamt_tot: this.balPopupInfinity?.box?.rcvamt_tot || '0',
      stamt_tot: this.balPopupInfinity?.box?.stamt_tot || '0',
    });
    this.balGrid.current?.forceRepaint();
  }

  @action
  async onDesPeridsMonModal(isOpen: boolean) {
    let tabName = '';
    let modalName = '';

    tabName = 'des_perids';
    modalName = '[설계자]';

    await this.setState({
      mon: this.state.data.mon,
      desPeridsMonModal: isOpen,
      tabNames: tabName,
      modalNames: modalName,
      desPeridsModalType: false,
    });

    await this.onDesPeridsMonModalRetrieve();
  }

  @action
  async onDesPeridsModal(isOpen: boolean) {
    let tabName = '';
    let modalName = '';

    tabName = 'des_perids_tot';
    modalName = '[설계자-총합계]';

    await this.setState({
      mon: this.state.data.mon,
      desPeridsModal: isOpen,
      tabNames: tabName,
      modalNames: modalName,
      desPeridsModalType: false,
    });

    await this.onDesPeridsModalRetrieve();
  }

  @action
  async onDesPeridsMonModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ desPeridsModalMonData: [] });

    this.desPeridsMonPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          desPeridsModalMonData: [...this.state.desPeridsModalMonData || [], ...items.map((x) => new SurPeridsPopupModel(x)),
          ],
        });
      },
    );
    await this.desPeridsMonPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.desPeridsMonPopupInfinity?.box?.cnt_tot || '',
      qty_tot: this.desPeridsMonPopupInfinity?.box?.qty_tot || '',
    });
    this.desPeridsMonGrid.current?.forceRepaint();
  }

  @action
  async onDesPeridsModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ desPeridsModalData: [] });

    this.desPeridsPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          desPeridsModalData: [...this.state.desPeridsModalData || [], ...items.map((x) => new SurPeridsPopupModel(x)),
          ],
        });
      },
    );
    await this.desPeridsPopupInfinity?.retrieveAll();
    await this.SS({
      cnttot_tot: this.desPeridsPopupInfinity?.box?.cnttot_tot || '',
      cnt01_tot: this.desPeridsPopupInfinity?.box?.cnt01_tot || '',
      cnt02_tot: this.desPeridsPopupInfinity?.box?.cnt02_tot || '',
      cnt03_tot: this.desPeridsPopupInfinity?.box?.cnt03_tot || '',
      cnt04_tot: this.desPeridsPopupInfinity?.box?.cnt04_tot || '',
      cnt05_tot: this.desPeridsPopupInfinity?.box?.cnt05_tot || '',
      cnt06_tot: this.desPeridsPopupInfinity?.box?.cnt06_tot || '',
      cnt07_tot: this.desPeridsPopupInfinity?.box?.cnt07_tot || '',
      cnt08_tot: this.desPeridsPopupInfinity?.box?.cnt08_tot || '',
      cnt09_tot: this.desPeridsPopupInfinity?.box?.cnt09_tot || '',
      cnt10_tot: this.desPeridsPopupInfinity?.box?.cnt10_tot || '',
      cnt11_tot: this.desPeridsPopupInfinity?.box?.cnt11_tot || '',
      cnt12_tot: this.desPeridsPopupInfinity?.box?.cnt12_tot || '',
      qtytot_tot: this.desPeridsPopupInfinity?.box?.qtytot_tot || '',
      qty01_tot: this.desPeridsPopupInfinity?.box?.qty01_tot || '',
      qty02_tot: this.desPeridsPopupInfinity?.box?.qty02_tot || '',
      qty03_tot: this.desPeridsPopupInfinity?.box?.qty03_tot || '',
      qty04_tot: this.desPeridsPopupInfinity?.box?.qty04_tot || '',
      qty05_tot: this.desPeridsPopupInfinity?.box?.qty05_tot || '',
      qty06_tot: this.desPeridsPopupInfinity?.box?.qty06_tot || '',
      qty07_tot: this.desPeridsPopupInfinity?.box?.qty07_tot || '',
      qty08_tot: this.desPeridsPopupInfinity?.box?.qty08_tot || '',
      qty09_tot: this.desPeridsPopupInfinity?.box?.qty09_tot || '',
      qty10_tot: this.desPeridsPopupInfinity?.box?.qty10_tot || '',
      qty11_tot: this.desPeridsPopupInfinity?.box?.qty11_tot || '',
      qty12_tot: this.desPeridsPopupInfinity?.box?.qty12_tot || '',
    });
    this.desPeridsGrid.current?.forceRepaint();
  }

  @action
  async onCompPeridsMonModal(isOpen: boolean) {
    let tabName = '';
    let modalName = '';

    tabName = 'comp_perids';
    modalName = '[계약자]';

    await this.setState({
      mon: this.state.data.mon,
      compPeridsMonModal: isOpen,
      tabNames: tabName,
      modalNames: modalName,
      peridsModalType: false,
    });

    await this.onCompPeridsModalMonRetrieve();
  }

  @action
  async onCompPeridsModal(isOpen: boolean) {
    let tabName = '';
    let modalName = '';

    tabName = 'comp_perids_tot';
    modalName = '[계약자-총합계]';

    await this.setState({
      mon: this.state.data.mon,
      compPeridsModal: isOpen,
      tabNames: tabName,
      modalNames: modalName,
      peridsModalType: false,
    });

    await this.onCompPeridsModalRetrieve();
  }

  @action
  async onCompPeridsModalMonRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ compPeridsModalMonData: [] });

    this.compPeridsMonPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          compPeridsModalMonData: [...this.state.compPeridsModalMonData || [], ...items.map((x) => new CompPeridsPopupModel(x)),
          ],
        });
      },
    );
    await this.compPeridsMonPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.compPeridsMonPopupInfinity?.box?.cnt_tot || '',
      qty_tot: this.compPeridsMonPopupInfinity?.box?.qty_tot || '',
      mamt_tot: this.compPeridsMonPopupInfinity?.box?.mamt_tot || '',
      tamt_tot: this.compPeridsMonPopupInfinity?.box?.tamt_tot || '',
      samt_tot: this.compPeridsMonPopupInfinity?.box?.samt_tot || '',
    });
    this.compPeridsMonGrid.current?.forceRepaint();
  }

  @action
  async onCompPeridsModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ compPeridsModalData: [] });

    this.compPeridsPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: this.state.tabNames,
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          compPeridsModalData: [...this.state.compPeridsModalData || [], ...items.map((x) => new CompPeridsPopupModel(x)),
          ],
        });
      },
    );
    await this.compPeridsPopupInfinity?.retrieveAll();
    await this.SS({
      cnttot_tot: this.compPeridsPopupInfinity?.box?.cnttot_tot || '',
      cnt01_tot: this.compPeridsPopupInfinity?.box?.cnt01_tot || '',
      cnt02_tot: this.compPeridsPopupInfinity?.box?.cnt02_tot || '',
      cnt03_tot: this.compPeridsPopupInfinity?.box?.cnt03_tot || '',
      cnt04_tot: this.compPeridsPopupInfinity?.box?.cnt04_tot || '',
      cnt05_tot: this.compPeridsPopupInfinity?.box?.cnt05_tot || '',
      cnt06_tot: this.compPeridsPopupInfinity?.box?.cnt06_tot || '',
      cnt07_tot: this.compPeridsPopupInfinity?.box?.cnt07_tot || '',
      cnt08_tot: this.compPeridsPopupInfinity?.box?.cnt08_tot || '',
      cnt09_tot: this.compPeridsPopupInfinity?.box?.cnt09_tot || '',
      cnt10_tot: this.compPeridsPopupInfinity?.box?.cnt10_tot || '',
      cnt11_tot: this.compPeridsPopupInfinity?.box?.cnt11_tot || '',
      cnt12_tot: this.compPeridsPopupInfinity?.box?.cnt12_tot || '',
      qtytot_tot: this.compPeridsPopupInfinity?.box?.qtytot_tot || '',
      qty01_tot: this.compPeridsPopupInfinity?.box?.qty01_tot || '',
      qty02_tot: this.compPeridsPopupInfinity?.box?.qty02_tot || '',
      qty03_tot: this.compPeridsPopupInfinity?.box?.qty03_tot || '',
      qty04_tot: this.compPeridsPopupInfinity?.box?.qty04_tot || '',
      qty05_tot: this.compPeridsPopupInfinity?.box?.qty05_tot || '',
      qty06_tot: this.compPeridsPopupInfinity?.box?.qty06_tot || '',
      qty07_tot: this.compPeridsPopupInfinity?.box?.qty07_tot || '',
      qty08_tot: this.compPeridsPopupInfinity?.box?.qty08_tot || '',
      qty09_tot: this.compPeridsPopupInfinity?.box?.qty09_tot || '',
      qty10_tot: this.compPeridsPopupInfinity?.box?.qty10_tot || '',
      qty11_tot: this.compPeridsPopupInfinity?.box?.qty11_tot || '',
      qty12_tot: this.compPeridsPopupInfinity?.box?.qty12_tot || '',
      mamttot_tot: this.compPeridsPopupInfinity?.box?.mamttot_tot || '',
      mamt01_tot: this.compPeridsPopupInfinity?.box?.mamt01_tot || '',
      mamt02_tot: this.compPeridsPopupInfinity?.box?.mamt02_tot || '',
      mamt03_tot: this.compPeridsPopupInfinity?.box?.mamt03_tot || '',
      mamt04_tot: this.compPeridsPopupInfinity?.box?.mamt04_tot || '',
      mamt05_tot: this.compPeridsPopupInfinity?.box?.mamt05_tot || '',
      mamt06_tot: this.compPeridsPopupInfinity?.box?.mamt06_tot || '',
      mamt07_tot: this.compPeridsPopupInfinity?.box?.mamt07_tot || '',
      mamt08_tot: this.compPeridsPopupInfinity?.box?.mamt08_tot || '',
      mamt09_tot: this.compPeridsPopupInfinity?.box?.mamt09_tot || '',
      mamt10_tot: this.compPeridsPopupInfinity?.box?.mamt10_tot || '',
      mamt11_tot: this.compPeridsPopupInfinity?.box?.mamt11_tot || '',
      mamt12_tot: this.compPeridsPopupInfinity?.box?.mamt12_tot || '',
    });
    this.compPeridsGrid.current?.forceRepaint();
  }

  @action
  async onKcqtyModal(item: AchievementStatusModel, isOpen: boolean) {
    const modalName = '[검사대수]';

    await this.setState({
      mon: item.mon,
      kcqtyModal: isOpen,
      modalNames: modalName,
    });

    await this.onKcqtyModalRetrieve();
  }

  @action
  async onKcqtyModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ kcqtyModalData: [] });

    this.kcqtyPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: 'kcqty',
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          kcqtyModalData: [...this.state.kcqtyModalData || [], ...items.map((x) => new KcqtyPopupModel(x)),
          ],
        });
      },
    );
    await this.kcqtyPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.kcqtyPopupInfinity?.box?.cnt_tot || '0',
      qty_tot: this.kcqtyPopupInfinity?.box?.qty_tot || '0',
      contamt_tot: this.kcqtyPopupInfinity?.box?.contamt_tot || '0',
      stamt_tot: this.kcqtyPopupInfinity?.box?.stamt_tot || '0',
      midamt_tot: this.kcqtyPopupInfinity?.box?.midamt_tot || '0',
      endamt_tot: this.kcqtyPopupInfinity?.box?.endamt_tot || '0',
      rcvamt_tot: this.kcqtyPopupInfinity?.box?.rcvamt_tot || '0',
      iamt_tot: this.kcqtyPopupInfinity?.box?.iamt_tot || '0',
    });
    this.kcqtyGrid.current?.forceRepaint();
  }

  @action
  async onMisModal(item: AchievementStatusModel, isOpen: boolean) {
    const modalName = '[매출및수금]';
    await this.setState({
      mon: item.mon,
      misModal: isOpen,
      modalNames: modalName,
    });

    await this.onMisModalRetrieve();
  }

  @action
  async onMisModalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ misModalData: [] });

    this.misPopupInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_sulchi_09',
        tab: 'mis',
        mon: this.state.mon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          misModalData: [...this.state.misModalData || [], ...items.map((x) => new MisPopupModel(x)),
          ],
        });
      },
    );
    await this.misPopupInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.misPopupInfinity?.box?.cnt_tot || '0',
      iamt2_tot: this.misPopupInfinity?.box?.iamt2_tot || '0',
      misamt2_tot: this.misPopupInfinity?.box?.misamt2_tot || '0',
      rcvamt2_tot: this.misPopupInfinity?.box?.rcvamt2_tot || '0',
    });
    await this.misGrid.current?.setFocus(0);
    await this.misGrid.current?.forceRepaint();
  }

  @action
  async onMisModalRowFocused(item: MisPopupModel) {
    await this.SS({
      misModalFocusedData: new MisPopupModel(item),
    });
  }

  @action
  async remarkModal(isOpen: boolean) {
    await this.setState({ remarkModal: isOpen });

    if (isOpen) {
      await this.remarkModalRetrieve();
    } else {
      await this.SS({
        remarkModalData: [],
      });
    }
  }

  @action
  async remarkModalRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_da023_mis_history',
        actcd: this.state.misModalFocusedData?.actcd,
      });
    if (data?.items) {
      this.setState({
        remarkModalData: data?.items,
      }, async () => {
        await this.remarkTable.current?.update(true);
        await this.remarkTable.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async remarkModalFocusedData(item: RemarkPopupModel) {
    await this.SS({
      remarkModalFocusedData: new RemarkPopupModel(item, item?.isNew),
    });
  }

  @action
  async onUpdatedRows(rows: Array<RemarkPopupModel>, updatedRows: Array<RemarkPopupModel>) {
    this.updatedRows = updatedRows;
    await this.SS({
      remarkModalData: rows,
    });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'new',
      {
        sub: 'w_tb_da023_mis_history',
        actcd: this.state.misModalFocusedData?.actcd,
      },
    );

    data && this.setState({
      remarkModalData: [...this.state.remarkModalData, new RemarkPopupModel(data, true)],
    }, async () => {
      await this.remarkTable.current?.update(true);
      await this.remarkTable.current?.setFocus(this.state.remarkModalData.length - 1, 1);
    });
  }

  @action
  async onRemarkModalSaveEvent() {
    const { actionStore: api } = this.props;

    if (await api.save({
      sub: 'w_tb_da023_mis_history',
      items: this.state.remarkModalData,
      chk: '1',
    }, true)) {
      this.updatedRows = [];
      this.remarkTable.current?.resetUpdates();
    }
  }

  @action
  async dw_2_delete() {
    const { actionStore: api } = this.props;

    const text = `순서: ${this.state.remarkModalFocusedData?.seq}`;
    await api.fxDelete(
      'delete',
      text,
      {
        sub: 'w_tb_da023_mis_history',
        seq: this.state.remarkModalFocusedData?.seq,
        actcd: this.state.remarkModalFocusedData?.actcd,
      },
    );
    await this.remarkModalRetrieve();
    await this.onMisModalRetrieve();
  }

  @action
  async onExcelEvent() {
    ConfirmWarning.show('확인', '설치계약달성현황은 준비중입니다..');
  }

  @action
  async onPrintEvent() {
    ConfirmWarning.show('확인', '설치계약달성현황은 준비중입니다..');
  }

  render() {
    return (
      <AchievementStatusTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}

