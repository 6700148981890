import * as React from 'react';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { TeamEnroll } from './TeamEnroll';
import { SearchBinding } from '../../../../models/common';

const gubuns = [
  { value: '0', remark: '' },
  { value: '1', remark: '당직' },
  { value: '2', remark: '통신' },
  { value: '3', remark: '상주' },
];

/**
 * 화면
 * @window w_tb_e509
 * @category 당직팀등록
 */
export const TeamEnrollTemplate: React.FC<TemplateProps<TeamEnroll>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={425}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'team',
              text: '팀코드',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.team}
                </FlexLayout>
              ),
            },
            {
              id: 'teamnm',
              text: '팀명',
              width: 60,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.teamnm}
                  onChange={(value) => rowUpdate({ teamnm: value })}
                />
              ),
            },
            {
              id: 'sort',
              text: '반복순서',
              width: 20,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  value={x.sort}
                  onChange={(value) => rowUpdate({ sort: value })}
                />
              ),
            },
            {
              id: 'useyn',
              text: '사용유무',
              width: 20,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.useyn === '1'}
                  onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
          ]}
          data={scope.state.teamEnrollList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
        />

        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            <FlexLayout justify="right" weight={3}>
              <Button
                style={{ height: '25px', width: '60px' }}
                onClick={() => scope.onNewEvent2()}>
                추가
              </Button>
              <Button
                style={{ height: '25px', width: '60px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.onDeleteEvent2()}
              >
                삭제
              </Button>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.tableDetail}
              header={[
                {
                  id: 'seq',
                  text: '코드',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'perid',
                  text: '당직자코드',
                  width: 30,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ perid: value })}
                      onEnter={() => scope.onRetrieveEvent()}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                      isDisabledTrackingStateChange={true}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '당직자명',
                  width: 30,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '구분',
                  width: 60,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.gubun}
                      style={{
                        padding: '0 0 0 6px',
                        fontSize: 12,
                      }}
                    eslint-disable-next-line max-len
                      data={gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ gubun: option.value })}
                    />
                  ),
                },
              ]}
              data={scope.state.teamEnrollDetailList}
              infinityHandler={scope.infinity2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
);
