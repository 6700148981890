import * as React from 'react';
import { action, computed } from 'mobx';
import { inject } from 'mobx-react';
import style from './FormatTextBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import { FlexLayout } from '../../layout';
// @ts-ignore
import { Global, LayoutProps } from '../../../constants';
import { PublicStore } from '../../../stores';

interface FormatTextBoxActions {
  onChange?: (value: any) => any;
  onBlur?: (value: any) => any;
  onEnter?: (value: any) => any;
}

interface FormatTextBoxProps extends FormatTextBoxActions, LayoutProps {
  publicStore?: PublicStore;
  refs?: React.RefObject<any>;
  value?: any;
  color?: string;
  bold?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  format?: string;
  placeholder?: string;
  className?: any;
  style?: any;
  readonly?: boolean;
  transparent?: boolean;
  isDisabledTrackingStateChange?: boolean;
  // @ts-ignore
  bindSearchModal?: Global.SearchBindingTypes;
  head?: React.ReactNode;
  trail?: React.ReactNode;
}

interface FormatTextBoxState {
  view: React.ReactNodeArray;
}

@inject('publicStore')
export class FormatTextBox extends React.Component<FormatTextBoxProps, FormatTextBoxState> {
  refInput: React.RefObject<HTMLInputElement>;

  position: number;

  isPressedBackSpace: boolean;

  store: string;

  constructor(props: FormatTextBoxProps, context: any) {
    super(props, context);
    this.refInput = props.refs || React.createRef();
    this.position = 0;
    this.isPressedBackSpace = false;
    this.store = this.props.value;
    this.state = {
      view: [],
    };
  }

  componentDidMount() {
    this.preRender();
  }

  componentDidUpdate(prevProps: Readonly<FormatTextBoxProps>): void {
    this.isPressedBackSpace = false;
    if (prevProps.value !== this.props.value) {
      this.store = this.props.value;
      this.preRender();
    }

    requestAnimationFrame(() => {
      this.refInput.current?.setSelectionRange(this.position, this.position);
    });
  }

  shouldComponentUpdate(nextProps: Readonly<FormatTextBoxProps>, nextStates: Readonly<FormatTextBoxState>): boolean {
    return this.props.value !== nextProps.value
      || this.props.readonly !== nextProps.readonly
      || this.props.format !== nextProps.format
      || this.state.view !== nextStates.view;
  }

  @action
  public forceUpdate() {
    this.store = this.props.value;
    this.preRender();
  }

  @computed
  get value(): string {
    const { format } = this.props;
    const formatString = format ?? '';
    const eValue = this.store ?? '';

    const result = formatString.split('');
    let inputIndex = 1;

    for (let i = formatString.length - 1; i >= 0; i -= 1) {
      if (formatString[i] === '#') {
        if (eValue[eValue.length - inputIndex]) {
          result[i] = eValue[eValue.length - inputIndex];
          inputIndex += 1;
        } else {
          result[i] = '*';
        }
      } else if (formatString[i] === '$') {
        if (eValue[eValue.length - inputIndex]) {
          result[i] = eValue[eValue.length - inputIndex];
          inputIndex += 1;
        } else {
          result[i] = '*';
        }
      } else if (formatString[i] === '@') {
        if (eValue.length - inputIndex) {
          result[i] = eValue[eValue.length - inputIndex];
          inputIndex += 1;
        } else {
          result[i] = ' ';
        }
      }
    }

    return result.join('')
      .replace(/#/ig, '0')
      .replace(/\$/ig, '*')
      .replace(/@/ig, ' ');
  }

  result(values: string[]): string {
    const { format } = this.props;
    const formatString = format ?? '';
    const splittedValue = values;

    const updatedValue = splittedValue.join('');

    let result = '';

    for (let i = 0; i < formatString.length; i += 1) {
      if (formatString[i] === '#') {
        result += updatedValue[i] ?? '0';
      } else if (formatString[i] === '$') {
        result += updatedValue[i] ?? '0';
      } else if (formatString[i] === '@') {
        result += updatedValue[i] ?? ' ';
      }
    }

    return result;
  }

  @action
  preRender() {
    const { format } = this.props;
    const formatString = format ?? '';
    const updatedValue = this.value;

    const result = [];

    for (let i = 0; i < formatString.length; i += 1) {
      if (formatString[i] === '#') {
        result.push(updatedValue[i] !== '*' ? updatedValue[i] : <span key={i} style={{ color: '#AEAEAE' }}>0</span>);
      } else if (formatString[i] === '$') {
        result.push(
          updatedValue[i] !== '*'
            ? <span key={i} style={{ color: '#333333' }}>*</span>
            : <span key={i} style={{ color: '#AEAEAE' }}>*</span>,
        );
      } else if (formatString[i] === '@') {
        result.push(updatedValue[i] !== ' ' ? updatedValue[i] : <span key={i} style={{ color: '#AEAEAE' }}> </span>);
      } else if (formatString[i] === ' ') {
        result.push(<span key={i}>&nbsp;</span>);
      } else {
        result.push(<span key={i}>{formatString[i]}</span>);
      }
    }

    this.setState({
      view: result,
    });
  }

  @action
  callOnChange(value: string) {
    const change = (value || '').replace(/\*/ig, '');
    if (change !== this.props.value) {
      this.props.onChange && this.props.onChange(change);

      if (!this.props.isDisabledTrackingStateChange) {
        this.props.publicStore?.setStateChanged(true);
      }
    } else {
      requestAnimationFrame(() => {
        this.refInput.current?.setSelectionRange(this.position, this.position);
      });
    }
  }

  checkPosition(isReverse: boolean = false) {
    const { format } = this.props;
    const formatString = format ?? '';

    do {
      this.position += 1 * (isReverse ? -1 : 1);
    } while (this.position < formatString.length
      && formatString[this.position] !== '#'
      && formatString[this.position] !== '$'
      && formatString[this.position] !== '@');
  }

  @action
  processChar(c: string) {
    const { format } = this.props;
    const formatString = format ?? '';
    const splittedValue = this.value.split('');

    if (splittedValue[this.position] === c) {
      this.checkPosition();
      requestAnimationFrame(() => {
        this.refInput.current?.setSelectionRange(this.position, this.position);
      });
      return;
    }

    if ((formatString[this.position] === '#' && this.isNumberChar(c))
        || (formatString[this.position] === '$' && this.isNumberChar(c))
        || (formatString[this.position] === '@' && this.isChar(c))) {
      splittedValue[this.position] = c;
    }

    this.checkPosition();
    this.store = this.result(splittedValue);
    this.preRender();
  }

  @action
  backPressed() {
    const { format } = this.props;
    const formatString = format ?? '';
    const splittedValue = this.value.split('');

    if (this.position === 0) {
      return;
    }

    this.checkPosition(true);
    splittedValue[this.position] = formatString[this.position] === '#' ? '0' : ' ';

    if (this.position < 0) {
      this.position = 0;
    }

    this.store = this.result(splittedValue);
    this.preRender();
  }

  @action
  public focus() {
    requestAnimationFrame(() => this.refInput.current?.focus());
  }

  // eslint-disable-next-line class-methods-use-this
  isNumberChar(ch: string): boolean {
    return (ch.charCodeAt(0) > 47 && ch.charCodeAt(0) < 58);
  }

  // eslint-disable-next-line class-methods-use-this
  isChar(ch: string): boolean {
    return (ch.charCodeAt(0) > 31
      && ch.charCodeAt(0) < 127);
  }

  @computed
  get isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  render() {
    return (
      <FlexLayout
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.transparent ? style.transparent : false,
        ])}
        style={this.props.style}
        weight={this.props.weight}
        size={this.props.size}
        justify="center"
        align="center"
        onClick={() => this.focus()}
      >
        <React.Fragment>
          {this.props.head}
          {!this.props.value && this.props.placeholder && (
            <label>{this.props.placeholder}</label>
          )}
          <input
            ref={this.refInput}
            className={this.props.readonly ? style.readonly : undefined}
            style={{
              textAlign: this.props?.textAlign,
              fontWeight: this.props?.bold ? 600 : 'inherit',
            }}
            value={this.value || ''}
            readOnly={this.props.readonly}
            onChange={() => {}}
            onClick={() => this.props.onClick && this.props.onClick()}
            onDoubleClick={() => this.props.bindSearchModal
              && !this.props.readonly && this.props.bindSearchModal.open()}
            onFocus={() => setTimeout(() => this.refInput.current?.setSelectionRange(0, 0), 30)}
            onBlur={(e) => {
              this.callOnChange(this.store);
              setTimeout(() => {
                this.props.onBlur && this.props.onBlur(e.target.value);
              });
            }}
            onInput={(e) => {
              if (this.props.readonly || !this.isMobile) {
                return false;
              }

              e.preventDefault();

              // @ts-ignore
              if (e.nativeEvent.inputType === 'deleteContentBackward') {
                this.backPressed();
                return false;
              }

              // @ts-ignore
              const char = e.nativeEvent.data;

              if (!char) {
                return false;
              }

              this.position = this.refInput.current?.selectionStart || 0;
              if (this.position > 0) {
                this.position -= 1;
              }

              if (this.position === this.props.format?.length) {
                return false;
              }

              if (!this.isChar(char)
                && !this.isNumberChar(char)) {
                return false;
              }

              this.processChar(char);
              return false;
            }}
            onKeyDown={(e) => {
              if (this.props.readonly || this.isMobile) {
                return false;
              }

              if (e.keyCode === 229) {
                return true;
              }

              if (e.ctrlKey) {
                return false;
              }

              if (e.keyCode === 17) { // ctrl
                return false;
              }

              if (e.keyCode === 39) { // right
                return false;
              }

              if (e.keyCode === 37) { // left
                return false;
              }

              if (e.keyCode === 9) { // tab
                return false;
              }

              e.preventDefault();

              const char = e.nativeEvent.key;
              this.position = this.refInput.current?.selectionStart || 0;

              if (e.keyCode === 13) {
                if (this.props.bindSearchModal && !this.props.readonly) {
                  this.props.bindSearchModal.open({
                    as_nm: this.store.replace(/\*/ig, ''),
                  });
                } else {
                  this.props.onEnter && this.props.onEnter(this.store.replace(/\*/ig, ''));
                }
                return false;
              }

              if (e.keyCode === 8) {
                this.backPressed();
                return false;
              }

              if (this.position === this.props.format?.length) {
                return false;
              }

              if (!this.isChar(char)
                && !this.isNumberChar(char)) {
                return false;
              }

              this.processChar(char);
              return false;
            }}
          />
          <FlexLayout
            align="center"
            justify={this.props?.textAlign}
            className={JoinClassName.make([
              'format',
              style.viewer,
            ])}
            style={{
              color: this.props?.color,
              fontWeight: this.props?.bold ? 600 : 'inherit',
            }}
          >
            {this.state.view}
          </FlexLayout>
          {this.props.trail}
        </React.Fragment>
      </FlexLayout>
    );
  }
}
