import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ContractTemplate } from './Contract.template';
import { ContractModel } from './Contract.model';
import { InfinityRetrieve } from '../../../../models';
import { PageComponent } from '../../../../utils';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface ContractState {
  yearmon: string;
  data: Array<ContractModel>;
  focused?: ContractModel;

  // trail
  qty_tot: string;
  amt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e101_monend
 * @category 계약월마감
 */
export class Contract extends PageComponent<PageProps, ContractState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      data: [],
      qty_tot: '0',
      amt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new ContractModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      await this.grid.current?.setFocus(0);

      this.SS({
        qty_tot: this.infinity?.box?.qty_tot || '0',
        amt_tot: this.infinity?.box?.amt_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.yearmon,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
    });
  }

  @action
  async onBtnEvent() {
    const { waitQueueStore, modalStore } = this.props;
    const title = '계약월마감 마감작업';
    const key = 'WB_END';
    waitQueueStore.append(title, key, async () => {
      await this.onRetrieveEvent();
    }, () => this.onBtnEvent());
    modalStore?.openWaitQueue();

    const { actionStore: api } = this.props;
    await api.fxExec('wb_end_lazytask', {
      stmon: this.state.yearmon,
      gubun: !this.state.data.length ? 'ok' : 'cancle',
    });
  }

  render() {
    return (
      <ContractTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
