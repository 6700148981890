/**
 * 모델
 * @window w_tb_e101w_05
 * @category 월별 관리대수변동내역
 */
export default class StatusDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly amt: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contg: string;

  /**
   * 계약구분 color
   *
   * **data type** varchar(8)
   */
  readonly contg01: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 사용자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 접수일
   *
   * **data type** varchar(8)
   */
  readonly recedate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 계약자
   *
   * **data type** varchar(8)
   */
  readonly contperid: string;

  /**
   * 계약자명
   *
   * **data type** varchar(8)
   */
  readonly contpernm: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.amt = data.amt || '';
    this.contdate = data.contdate || '';
    this.contg = data.contg || '';
    this.contg01 = data.contg01 || '';
    this.enddate = data.enddate || '';
    this.gubun = data.gubun || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.qty = data.qty || '';
    this.recedate = data.recedate || '';
    this.state = data.state || '';
    this.stdate = data.stdate || '';
    this.contperid = data.contperid || '';
    this.contpernm = data.contpernm || '';
  }
}
