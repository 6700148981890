import * as React from 'react';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatNumberTextBox,
  GridLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { ConfirmWarning } from '../../../../utils/confirm';
import { ProductAccount } from './ProductAccount';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

const wkactcds = [
  { value: '001', remark: '자사' },
  { value: '002', remark: '현대' },
  { value: '003', remark: '오티스' },
  { value: '004', remark: '티센' },
  { value: '005', remark: '미쓰비시' },
];

/**
 * 화면
 * @window w_tb_ca501_cltcd
 * @category 제품거래처별단가등록
 */
export const ProductAccountTemplate: React.FC<TemplateProps<ProductAccount>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      style={{ width: 1500 }}
    >
        <FlexLayout size={230}>
        <span style={{ width: 60 }}>회사구분</span>
        <ComboBox
          value={model.wkactcd}
          data={[
            { value: '%', remark: '전체' },
            { value: '001', remark: '자사' },
            { value: '002', remark: '현대' },
            { value: '003', remark: '오티스' },
            { value: '004', remark: '티센' },
            { value: '005', remark: '미쓰비시' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ wkactcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
        </FlexLayout>

        <FlexLayout size={500}>
        <span>검색어(품명)</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="(검색어품명) 품명, 품명(한글명), 특이사항으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        />
        </FlexLayout>

      <FlexLayout size={690}>
        <span>규격</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="(규격) 규격으로 검색하세요"
          value={scope.state.searchQuery2}
          onChange={(value) => scope.setState({ searchQuery2: value })}
          onEnter={() => scope.onRetrieveEvent()}
        />
      </FlexLayout>
    </SearchLayout>

      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 1500 }}
      >
        <FlexLayout size={400}>
          <span>선택</span>
          <ComboBox
            value={model.choice}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.choices?.map((y) => new ComboBoxModel(y.chcd, y.chnm))}
            onSelect={(option) => setData({
              choice: option.value,
              phm_mode: option.value.substr(0, 3),
              agrb: option.value.substr(3, 2),
              bgrb: option.value.substr(5, 2),
              cgrb: option.value.substr(7, 2),
            },
            () => scope.onFirstOpenEvent())}
          />
        </FlexLayout>

      <FlexLayout size={230}>
        <span>품목분류</span>
          <ComboBox
            value={model.phm_mode}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.phm_modes?.map((y) => new ComboBoxModel(y.phm_mode, y.phm_modenm))}
            onSelect={(option) => setData({ phm_mode: option.value, phm_modeCheck: '1' },
              () => scope.onFirstOpenEvent())}
          />
        </FlexLayout>

        <FlexLayout size={230}
          onClick={() => {
            if (!model.phm_modeCheck) {
              ConfirmWarning.show('경고', '먼저 품목분류를 선택하세요!');
            }
          }}>
        <span>대분류</span>
         <ComboBox
            value={model.agrb}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.agrbs?.map((y) => new ComboBoxModel(y.pgr_hcod, y.pgr_hnam))}
            onSelect={(option) => setData({ agrb: option.value, agrbCheck: '1' },
              () => scope.onFirstOpenEvent())}
          />
        </FlexLayout>

        <FlexLayout size={230} onClick={() => {
          if (!model.agrbCheck) {
            ConfirmWarning.show('경고', '먼저 대분류를 선택하세요!');
          }
        }}>
        <span>중분류</span>
          <ComboBox
            value={model.bgrb}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.bgrbs?.map((y) => new ComboBoxModel(y.bgroup, y.bgrpnm))}
            onSelect={(option) => setData({ bgrb: option.value, bgrbCheck: '1' },
              () => scope.onFirstOpenEvent())}
          />
        </FlexLayout>

        <FlexLayout size={230} onClick={() => {
          if (!model.bgrbCheck) {
            ConfirmWarning.show('경고', '먼저 중분류를 선택하세요!');
          }
        }}>
        <span>소분류</span>
          <ComboBox
            value={model.cgrb}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.cgrbs?.map((y) => new ComboBoxModel(y.cgroup, y.cgrpnm))}
            onSelect={(option) => setData({ cgrb: option.value },
              () => scope.onFirstOpenEvent())}
          />
        </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout weight={1.5}>
        <GridLayout
          header={[
            {
              id: 'wkactcd',
              text: '회사구분',
              width: 8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'phm_pcod',
              text: '제품코드',
              width: 11,
            },
            {
              id: 'phm_pnam',
              text: '품목명',
              width: 24,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.phm_pnam}
                </FlexLayout>
              ),
            },
            {
              id: 'phm_size',
              text: '규격',
              width: 24,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.phm_size}
                </FlexLayout>
              ),
            },
            {
              id: 'psize',
              text: '크기/치수',
              width: 11,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.psize}
                </FlexLayout>
              ),
            },
            {
              id: 'phm_mamt',
              text: '기본매입단가',
              width: 11,
              render: (x) => (
                <FormatNumberTextBox
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.phm_mamt}
                />
              ),
            },
            {
              id: 'phm_samt',
              text: '기본매출단가',
              width: 11,
              render: (x) => (
                <FormatNumberTextBox
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.phm_samt}
                />
              ),
            },
          ]}
          data={scope.state.productAccountList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
          <FlexLayout justify="right" weight={3}>
            <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.dw_2_new()}>추가</Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.RED} onClick={() => scope.dw_2_delete()}
            >
              삭제
            </Button>
          </FlexLayout>
        </FlexLayout>

        <TableLayout
          ref={scope.tableDetail}
          header={[
            {
              id: 'cltcd',
              text: '거래처',
              color: 'var(--color-red)',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.cltcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ cltcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_XCLIENT',
                    {
                      as_nm: scope.state.searchQuery,
                      uv_arg1: '1',
                    },
                    true,
                    (item) => {
                      rowUpdate({
                        cltcd: item.cd,
                        cltnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 56,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'mamt',
              text: '매입단가',
              width: 17,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.mamt}
                  onChange={(value) => rowUpdate({ mamt: value })}
                />
              ),
            },
            {
              id: 'samt',
              text: '매출단가',
              width: 17,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt}
                  onChange={(value) => rowUpdate({ samt: value })}
                />
              ),
            },
          ]}
          data={scope.state.productAccountDetailList}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows2, updatedRows2) => {
            scope.onUpdatedRows2(rows2, updatedRows2);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
