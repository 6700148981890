import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_pb420
 * @category 시간외근무보고서
 */

export class OvertimeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 일자
   *
   * **data type** varchar(13)
   */
  readonly overdate: string;

  /**
   * 번호
   *
   * **data type** varchar(100)
   */
  readonly overnum: string;

  /**
   * 제목
   *
   * **data type** varchar(3)
   */
  readonly title: string;

  /**
   * 작성자
   *
   * **data type** varchar(13)
   */
  readonly perid: string;

  /**
   * 작성자2
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   * 비고
   *
   * **data type** varchar(30)
   */
  readonly remark: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 상태
   *
   * **data type** varchar(255)
   */
  readonly appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(255)
   */
  readonly appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(1)
   */
  readonly appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 참조
   *
   * **data type** varchar(1)
   */
  readonly refer: string;

  /**
   * 상신시간
   *
   * **data type** varchar(1)
   */
  readonly apptime: string;

  /**
   * 상신시간
   *
   * **data type** varchar(1)
   */
  readonly attcnt: number;

  /**
   * 상신시간
   *
   * **data type** varchar(1)
   */
  readonly seq: number;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.overdate = data.overdate || '';
    this.overnum = data.overnum || '';
    this.title = data.title || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.apptime = data.apptime || '';
    this.attcnt = data.attcnt || 0;

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
