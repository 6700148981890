import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { JiroBundle } from './JiroBundle';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

const gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '기타수입' },
  { value: '18', remark: '기타환불' },
];

const taxgubuns = [
  { value: '01', remark: '과세' },
  { value: '02', remark: '영세' },
  { value: '03', remark: '면세' },
];

/**
 * 화면
 * @window w_tb_e050w_02
 * @category 지로일괄발행
 */
export const JiroBundleTemplate: React.FC<TemplateProps<JiroBundle>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={220}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => setData({ yearmon: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="거래처, 현장으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>회사구분</span>
            <TextBox
              value={scope.state.wkactcd}
              textAlign="center"
              onChange={(value) => setData({ wkactcd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_E018_1',
                {},
                true,
                (item) => {
                  setData({
                    wkactcd: item.cd,
                    wkactnm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.wkactnm}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>구분</span>
            <ComboBox
              value={scope.state.searchGubun}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.gubun?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
              onSelect={(option) => setData({ searchGubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>계산서발행</span>
            <ComboBox
              value={scope.state.billgubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '발행' },
                { value: '0', remark: '미발행' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ billgubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.jiroRetrive()}>전자지로생성</Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'chk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={scope.state.pushTotalCheck}
                    onChange={async (value) => scope
                      .updateCheckAllToggle(value)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
              width: 30,
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.chk === '1'}
                    onChange={(value) => {
                      rowUpdate({ chk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
            {
              id: 'prtchk',
              text: '출력구분',
              width: 50,
              render: (x) => (
                <CheckBox
                  value={x.prtchk === '1'}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'misdate',
              text: '매출일자',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  건수 {scope.state.total}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'misnum',
              text: '번호',
              width: 50,
            },
            {
              id: 'pubnum',
              text: '지로번호',
              width: 100,
            },
            {
              id: 'gubun',
              text: '매출분류',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                >
                  {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 200,
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  출력건수 {scope.state.prtcnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  미출력건수 {scope.state.notcnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'zipcd',
              text: '우편번호',
              width: 100,
            },
            {
              id: 'cltadres',
              text: '주소',
              width: 400,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltadres}
                </FlexLayout>
              ),
            },
            {
              id: 'taxcls',
              text: '부가세여부',
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.taxcls === '1' ? '포함' : '별도'}
                </FlexLayout>
              ),
            },
            {
              id: 'amt',
              text: '매출액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt)}
                </FlexLayout>
              ),
            },
            {
              id: 'addamt',
              text: '부가세',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.addamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.addamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '합계',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.misamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'yyyymm',
              text: '귀속년월',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  format="####-##"
                  textAlign="center"
                  value={x.yyyymm}
                />
              ),
            },
            {
              id: 'billkind',
              text: '계산서발행',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.billkind === '1' ? '발행' : '미발행'}
                </FlexLayout>
              ),
            },
            {
              id: 'taxgubun',
              text: '과세구분',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {taxgubuns.filter((y) => y.value === x.taxgubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'jirogubun',
              text: '지로구분',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.jirogubun === '1' ? '발행' : '미발행'}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          data={scope.state.data}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          onRowDoubleClick={() => scope.onClickOpenBalEnrollment()}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
