import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import { Global, TemplateProps } from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { BreakUnit, BreakUnitTabId } from './BreakUnit';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { Date6Calculator } from '../../../../utils/time';
import { IFrame, TabLayout } from '../../../../components';
import { BreakUnitTab } from './BreakUnit.tab';

/**
 * 메인화면
 * @window w_tb_e401w_01
 * @category 호기별고장통계
 */
export const BreakUnitTemplate: React.FC<TemplateProps<BreakUnit>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.stmon}
          textAlign="center"
          format="#### - ##"
          onChange={(value) => { scope.setState({ stmon: value }, () => scope.onRetrieveEvent()); }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              // eslint-disable-next-line max-len
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              // eslint-disable-next-line max-len
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.endmon}
          textAlign="center"
          format="#### - ##"
          onChange={(value) => { scope.setState({ endmon: value }, () => scope.onRetrieveEvent()); }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              // eslint-disable-next-line max-len
              scope.setState({ endmon: new Date6Calculator(scope.state.endmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              // eslint-disable-next-line max-len
              scope.setState({ endmon: new Date6Calculator(scope.state.endmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={300}>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            현장
          </span>
        <TextBox
          weight={1}
          size={100}
          value={scope.state.actcd}
          textAlign="center"
          onChange={(value) => scope.setState({ actcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              scope.setState({
                actcd: item.cd,
                actnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={2}
          value={scope.state.actnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <TabLayout
        scope={scope}
        tabs={BreakUnitTab}
        onChange={(item, index) => scope.onTabChange(item, index)}
      />
    </FlexLayout>
    {/* <GridLayout */}
    {/*  header={scope.tabHeaders[scope.tabIndex]} */}
    {/*  rowHeight={Global.LAYOUT_GRID_HEIGHT_1} */}
    {/*  infinityHandler={scope.infinity} */}
    {/*  // @ts-ignore */}
    {/*  data={scope.state[BreakUnitTabDataStateNames[scope.tabIndex]] || [] } */}
    {/*  onRowClick={(item) => scope.onRowFocusEvent(item)} */}
    {/* /> */}
    {(scope.tabIndex === BreakUnitTabId.All) && <FlexLayout>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
    {(scope.tabIndex === BreakUnitTabId.Month) && <FlexLayout>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
  </FlexLayout>

);
