import {
  TextBox,
  ComboBox,
  ComboBoxModel,
  CheckBox,
  FormatTextBox, DateTextBox,
} from '../../../../../components';
import { TableLayoutHeader } from '../../../../../constants';
import { FamilyModel } from '../models';
import {
  PersonnelResistration,
  PersonnelResistrationItemChangeTypes,
} from '../PersonnelResistration';

// 가족
const Familly: TableLayoutHeader<FamilyModel, PersonnelResistration>[] = [
  {
    id: 'seq',
    text: '순번',
    width: 40,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.seq}
        onChange={(value) => rowUpdate({ seq: value })}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'concd',
    text: '관계',
    width: 80,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.concd || ''}
        style={{
          fontSize: 12,
        }}
        data={[
          { value: '1', remark: '조부' },
          { value: '2', remark: '조모' },
          { value: '3', remark: '부' },
          { value: '4', remark: '모' },
          { value: '5', remark: '배우자' },
          { value: '6', remark: '자녀' },
          { value: '7', remark: '기타' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ concd: option.value })}
      />
    ),
  },
  {
    id: 'resyn',
    text: '내국인',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.resyn === '1'}
        onChange={(value) => rowUpdate({ resyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'faminm',
    text: '성명',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.faminm}
        onChange={(value) => rowUpdate({ faminm: value })}
      />
    ),
  },
  {
    id: 'faminum',
    text: '주민등록번호',
    width: 100,
    render: (x, rowUpdate, ref, scope) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="######-#######"
        value={x.faminum}
        onChange={(value) => rowUpdate({ faminum: value })}
        onBlur={async (value) => {
          const birthdayData = await scope?.getItem(
            value,
            PersonnelResistrationItemChangeTypes.FAMINUM,
          );

          birthdayData && rowUpdate({ birthday: birthdayData.birthday });
        }}
      />
    ),
  },
  {
    id: 'sureyn',
    text: '기본공제',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.sureyn === '1'}
        onChange={(value) => rowUpdate({ sureyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'disperyn',
    text: '장애',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.disperyn === '1'}
        onChange={(value) => rowUpdate({ disperyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'childBryn',
    text: '자녀양육',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.childBryn === '1'}
        onChange={(value) => rowUpdate({ childBryn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'jd001_1',
    text: '보험',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.jd001_1 === '1'}
        onChange={(value) => rowUpdate({ jd001_1: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'jd001_2',
    text: '의료',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.jd001_2 === '1'}
        onChange={(value) => rowUpdate({ jd001_2: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'jd001_3',
    text: '교육',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.jd001_3 === '1'}
        onChange={(value) => rowUpdate({ jd001_3: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'jd001_4',
    text: '신용카드',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.jd001_4 === '1'}
        onChange={(value) => rowUpdate({ jd001_4: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'jobnm',
    text: '직업',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.jobnm}
        onChange={(value) => rowUpdate({ jobnm: value })}
      />
    ),
  },
  {
    id: 'compnm',
    text: '직장명',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.compnm}
        onChange={(value) => rowUpdate({ compnm: value })}
      />
    ),
  },
  {
    id: 'schnm',
    text: '학력',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.schnm}
        onChange={(value) => rowUpdate({ schnm: value })}
      />
    ),
  },
  {
    id: 'birthday',
    text: '생일',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        ref={ref}
        value={x.birthday}
        onChange={(value) => rowUpdate({ birthday: value })}
      />
    ),
  },
];

export default Familly;
