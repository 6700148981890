import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Status } from '../Status';
import StatusMonthModel from '../models/StatusMonthModel';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 월별건수 > 그리드 레이아웃
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTabMonthGridHeader: GLHF<StatusMonthModel, Status> = (scope) => ([
  {
    id: 'divicd',
    text: '부서명',
    width: 100,
    isHidden: true,
    group: true,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'rpsnm',
    text: '직책',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.rspnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '성명',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'montot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
        <FlexLayout isVertical={true}>
          <FlexLayout
            justify="center"
            align="center"
            onDoubleClick={() => scope.onPopupChk(true, 'mon', 'all')}
          >
            {String(Number(x.mon01) + Number(x.mon02) + Number(x.mon03) + Number(x.mon04) + Number(x.mon05) + Number(x.mon06)
            + Number(x.mon07) + Number(x.mon08) + Number(x.mon09) + Number(x.mon10) + Number(x.mon11) + Number(x.mon12))}
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
            style={{ color: 'var(--color-blue)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', 'all')}
          >
            {String(Number(x.compmon01) + Number(x.compmon02) + Number(x.compmon03) + Number(x.compmon04) + Number(x.compmon05) + Number(x.compmon06)
            + Number(x.compmon07) + Number(x.compmon08) + Number(x.compmon09) + Number(x.compmon10) + Number(x.compmon11) + Number(x.compmon12))}
          </FlexLayout>
        </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMonAll = Sum.byGroup(data, 'mon01', x, ['divinm']) + Sum.byGroup(data, 'mon02', x, ['divinm'])
        + Sum.byGroup(data, 'mon03', x, ['divinm']) + Sum.byGroup(data, 'mon04', x, ['divinm'])
        + Sum.byGroup(data, 'mon05', x, ['divinm']) + Sum.byGroup(data, 'mon06', x, ['divinm'])
        + Sum.byGroup(data, 'mon07', x, ['divinm']) + Sum.byGroup(data, 'mon08', x, ['divinm'])
        + Sum.byGroup(data, 'mon09', x, ['divinm']) + Sum.byGroup(data, 'mon10', x, ['divinm'])
        + Sum.byGroup(data, 'mon11', x, ['divinm']) + Sum.byGroup(data, 'mon12', x, ['divinm']);

      const sumCompMonAll = Sum.byGroup(data, 'compmon01', x, ['divinm']) + Sum.byGroup(data, 'compmon02', x, ['divinm'])
        + Sum.byGroup(data, 'compmon03', x, ['divinm']) + Sum.byGroup(data, 'compmon04', x, ['divinm'])
        + Sum.byGroup(data, 'compmon05', x, ['divinm']) + Sum.byGroup(data, 'compmon06', x, ['divinm'])
        + Sum.byGroup(data, 'compmon07', x, ['divinm']) + Sum.byGroup(data, 'compmon08', x, ['divinm'])
        + Sum.byGroup(data, 'compmon09', x, ['divinm']) + Sum.byGroup(data, 'compmon10', x, ['divinm'])
        + Sum.byGroup(data, 'compmon11', x, ['divinm']) + Sum.byGroup(data, 'compmon12', x, ['divinm']);
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
          >
            {sumMonAll}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-blue)' }}
          >
            {sumCompMonAll}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon_tot}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_compmon_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'totyul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet)' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 총 견적
      const sumMon = Number(x.mon01) + Number(x.mon02) + Number(x.mon03) + Number(x.mon04) + Number(x.mon05) + Number(x.mon06)
        + Number(x.mon07) + Number(x.mon08) + Number(x.mon09) + Number(x.mon10) + Number(x.mon11) + Number(x.mon12);

      // 총 완료
      const sumCompMon = Number(x.compmon01) + Number(x.compmon02) + Number(x.compmon03) + Number(x.compmon04) + Number(x.compmon05)
        + Number(x.compmon06) + Number(x.compmon07) + Number(x.compmon08) + Number(x.compmon09) + Number(x.compmon10)
        + Number(x.compmon11) + Number(x.compmon12);

      // 성사율
      let sumYul = 0;
      if (sumCompMon > 0) {
        sumYul = Math.round((sumCompMon / sumMon) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {sumYul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMonAll = Sum.byGroup(data, 'mon01', x, ['divinm']) + Sum.byGroup(data, 'mon02', x, ['divinm'])
        + Sum.byGroup(data, 'mon03', x, ['divinm']) + Sum.byGroup(data, 'mon04', x, ['divinm'])
        + Sum.byGroup(data, 'mon05', x, ['divinm']) + Sum.byGroup(data, 'mon06', x, ['divinm'])
        + Sum.byGroup(data, 'mon07', x, ['divinm']) + Sum.byGroup(data, 'mon08', x, ['divinm'])
        + Sum.byGroup(data, 'mon09', x, ['divinm']) + Sum.byGroup(data, 'mon10', x, ['divinm'])
        + Sum.byGroup(data, 'mon11', x, ['divinm']) + Sum.byGroup(data, 'mon12', x, ['divinm']);

      const sumCompMonAll = Sum.byGroup(data, 'compmon01', x, ['divinm']) + Sum.byGroup(data, 'compmon02', x, ['divinm'])
        + Sum.byGroup(data, 'compmon03', x, ['divinm']) + Sum.byGroup(data, 'compmon04', x, ['divinm'])
        + Sum.byGroup(data, 'compmon05', x, ['divinm']) + Sum.byGroup(data, 'compmon06', x, ['divinm'])
        + Sum.byGroup(data, 'compmon07', x, ['divinm']) + Sum.byGroup(data, 'compmon08', x, ['divinm'])
        + Sum.byGroup(data, 'compmon09', x, ['divinm']) + Sum.byGroup(data, 'compmon10', x, ['divinm'])
        + Sum.byGroup(data, 'compmon11', x, ['divinm']) + Sum.byGroup(data, 'compmon12', x, ['divinm']);
      let sumYul = 0;
      if (sumCompMonAll > 0) {
        sumYul = Math.round((sumCompMonAll / sumMonAll) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', 'all')}
          >
            {sumYul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', 'all')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout
          style={{ color: 'var(--color-violet)' }}
        >
          {scope.state.tab_3_tot_compyul_tot}
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet)' }}
        >
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'totmon',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          완료상세
        </FlexLayout>

        <FlexLayout>
          <FlexLayout style={{ color: 'var(--color-green)' }}>
            무상
          </FlexLayout>

          <FlexLayout style={{ color: 'var(--color-red)' }}>
            미매출
          </FlexLayout>

          <FlexLayout>
            매출
          </FlexLayout>

          <FlexLayout style={{ color: 'var(--color-blue)' }}>
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => {
      const sumMusang = String(Number(x.musang01) + Number(x.musang02) + Number(x.musang03) + Number(x.musang04)
        + Number(x.musang05) + Number(x.musang06) + Number(x.musang07) + Number(x.musang08)
        + Number(x.musang09) + Number(x.musang10) + Number(x.musang11) + Number(x.musang12));

      const sumNotMis = String(Number(x.notmismon01) + Number(x.notmismon02) + Number(x.notmismon03) + Number(x.notmismon04)
        + Number(x.notmismon05) + Number(x.notmismon06) + Number(x.notmismon07) + Number(x.notmismon08)
        + Number(x.notmismon09) + Number(x.notmismon10) + Number(x.notmismon11) + Number(x.notmismon12));

      const sumMis = String(Number(x.mismon01) + Number(x.mismon02) + Number(x.mismon03) + Number(x.mismon04)
        + Number(x.mismon05) + Number(x.mismon06) + Number(x.mismon07) + Number(x.mismon08)
        + Number(x.mismon09) + Number(x.mismon10) + Number(x.mismon11) + Number(x.mismon12));

      const sumRcv = String(Number(x.rcvmon01) + Number(x.rcvmon02) + Number(x.rcvmon03) + Number(x.rcvmon04)
        + Number(x.rcvmon05) + Number(x.rcvmon06) + Number(x.rcvmon07) + Number(x.rcvmon08)
        + Number(x.rcvmon09) + Number(x.rcvmon10) + Number(x.rcvmon11) + Number(x.rcvmon12));
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-green)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'musang', 'all')}
          >
            {sumMusang}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-red)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'notmismon', 'all')}
          >
            {sumNotMis}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            onDoubleClick={() => scope.onPopupChk(true, 'mismon', 'all')}
          >
            {sumMis}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-blue)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', 'all')}
          >
            {sumRcv}
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMusangAll = Sum.byGroup(data, 'musang01', x, ['divinm']) + Sum.byGroup(data, 'musang02', x, ['divinm'])
        + Sum.byGroup(data, 'musang03', x, ['divinm']) + Sum.byGroup(data, 'musang04', x, ['divinm'])
        + Sum.byGroup(data, 'musang05', x, ['divinm']) + Sum.byGroup(data, 'musang06', x, ['divinm'])
        + Sum.byGroup(data, 'musang07', x, ['divinm']) + Sum.byGroup(data, 'musang08', x, ['divinm'])
        + Sum.byGroup(data, 'musang09', x, ['divinm']) + Sum.byGroup(data, 'musang10', x, ['divinm'])
        + Sum.byGroup(data, 'musang11', x, ['divinm']) + Sum.byGroup(data, 'musang12', x, ['divinm']);

      const sumNotMisAll = Sum.byGroup(data, 'notmismon01', x, ['divinm']) + Sum.byGroup(data, 'notmismon02', x, ['divinm'])
        + Sum.byGroup(data, 'notmismon03', x, ['divinm']) + Sum.byGroup(data, 'notmismon04', x, ['divinm'])
        + Sum.byGroup(data, 'notmismon05', x, ['divinm']) + Sum.byGroup(data, 'notmismon06', x, ['divinm'])
        + Sum.byGroup(data, 'notmismon07', x, ['divinm']) + Sum.byGroup(data, 'notmismon08', x, ['divinm'])
        + Sum.byGroup(data, 'notmismon09', x, ['divinm']) + Sum.byGroup(data, 'notmismon10', x, ['divinm'])
        + Sum.byGroup(data, 'notmismon11', x, ['divinm']) + Sum.byGroup(data, 'notmismon12', x, ['divinm']);

      const sumMisAll = Sum.byGroup(data, 'mismon01', x, ['divinm']) + Sum.byGroup(data, 'mismon02', x, ['divinm'])
        + Sum.byGroup(data, 'mismon03', x, ['divinm']) + Sum.byGroup(data, 'mismon04', x, ['divinm'])
        + Sum.byGroup(data, 'mismon05', x, ['divinm']) + Sum.byGroup(data, 'mismon06', x, ['divinm'])
        + Sum.byGroup(data, 'mismon07', x, ['divinm']) + Sum.byGroup(data, 'mismon08', x, ['divinm'])
        + Sum.byGroup(data, 'mismon09', x, ['divinm']) + Sum.byGroup(data, 'mismon10', x, ['divinm'])
        + Sum.byGroup(data, 'mismon11', x, ['divinm']) + Sum.byGroup(data, 'mismon12', x, ['divinm']);

      const sumRcvAll = Sum.byGroup(data, 'rcvmon01', x, ['divinm']) + Sum.byGroup(data, 'rcvmon02', x, ['divinm'])
        + Sum.byGroup(data, 'rcvmon03', x, ['divinm']) + Sum.byGroup(data, 'rcvmon04', x, ['divinm'])
        + Sum.byGroup(data, 'rcvmon05', x, ['divinm']) + Sum.byGroup(data, 'rcvmon06', x, ['divinm'])
        + Sum.byGroup(data, 'rcvmon07', x, ['divinm']) + Sum.byGroup(data, 'rcvmon08', x, ['divinm'])
        + Sum.byGroup(data, 'rcvmon09', x, ['divinm']) + Sum.byGroup(data, 'rcvmon10', x, ['divinm'])
        + Sum.byGroup(data, 'rcvmon11', x, ['divinm']) + Sum.byGroup(data, 'rcvmon12', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout style={{ color: 'var(--color-green)' }}>
            {sumMusangAll}
          </FlexLayout>

          <FlexLayout style={{ color: 'var(--color-red)' }}>
            {sumNotMisAll}
          </FlexLayout>

          <FlexLayout>
            {sumMisAll}
          </FlexLayout>

          <FlexLayout style={{ color: 'var(--color-blue)' }}>
            {sumRcvAll}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon01tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '01')}
        >
          {x.mon01}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '01')}
        >
          {x.compmon01}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon01', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon01', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon01_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon01_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon01yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon01 = Number(x.mon01);

      // 완료
      const common01 = Number(x.compmon01);

      // 성사율
      let mon01Yul = 0;
      if (common01 > 0) {
        mon01Yul = Math.round((common01 / mon01) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '01')}
          >
            {mon01Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '01')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon01 = Sum.byGroup(data, 'mon01', x, ['divicd']);
      const sumCompMon01 = Sum.byGroup(data, 'compmon01', x, ['divicd']);
      let mon01yul = 0;
      if (sumCompMon01 > 0) {
        mon01yul = Math.round((sumCompMon01 / sumMon01) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon01yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul01_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '01')}
        >
          {x.musang01}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '01')}
        >
          {x.notmismon01}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '01')}
        >
          {x.mismon01}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '01')}
        >
          {x.rcvmon01}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang01 = Sum.byGroup(data, 'musang01', x, ['divinm']);
      const sumNotMis01 = Sum.byGroup(data, 'notmismon01', x, ['divinm']);
      const sumMis01 = Sum.byGroup(data, 'mismon01', x, ['divinm']);
      const sumRcv01 = Sum.byGroup(data, 'rcvmon01', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang01}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis01}
          </FlexLayout>
          <FlexLayout>
            {sumMis01}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv01}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang01_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon01_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon01_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon01_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon02tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '02')}
        >
          {x.mon02}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '02')}
        >
          {x.compmon02}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon02', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon02', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon02_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon02_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon02yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon02 = Number(x.mon02);

      // 완료
      const common02 = Number(x.compmon02);

      // 성사율
      let mon02Yul = 0;
      if (common02 > 0) {
        mon02Yul = Math.round((common02 / mon02) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '02')}
          >
            {mon02Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '02')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon02 = Sum.byGroup(data, 'mon02', x, ['divicd']);
      const sumCompMon02 = Sum.byGroup(data, 'compmon02', x, ['divicd']);
      let mon02yul = 0;
      if (sumCompMon02 > 0) {
        mon02yul = Math.round((sumCompMon02 / sumMon02) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon02yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul02_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '02')}
        >
          {x.musang02}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '02')}
        >
          {x.notmismon02}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '02')}
        >
          {x.mismon02}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '02')}
        >
          {x.rcvmon02}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang02 = Sum.byGroup(data, 'musang02', x, ['divinm']);
      const sumNotMis02 = Sum.byGroup(data, 'notmismon02', x, ['divinm']);
      const sumMis02 = Sum.byGroup(data, 'mismon02', x, ['divinm']);
      const sumRcv02 = Sum.byGroup(data, 'rcvmon02', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang02}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis02}
          </FlexLayout>
          <FlexLayout>
            {sumMis02}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv02}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang02_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon02_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon02_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon02_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon03tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '03')}
        >
          {x.mon03}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '03')}
        >
          {x.compmon03}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon03', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon03', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon03_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon03_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon03yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon03 = Number(x.mon03);

      // 완료
      const common03 = Number(x.compmon03);

      // 성사율
      let mon03Yul = 0;
      if (common03 > 0) {
        mon03Yul = Math.round((common03 / mon03) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '03')}
          >
            {mon03Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '03')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon03 = Sum.byGroup(data, 'mon03', x, ['divicd']);
      const sumCompMon03 = Sum.byGroup(data, 'compmon03', x, ['divicd']);
      let mon03yul = 0;
      if (sumCompMon03 > 0) {
        mon03yul = Math.round((sumCompMon03 / sumMon03) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon03yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul03_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '03')}
        >
          {x.musang03}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '03')}
        >
          {x.notmismon03}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '03')}
        >
          {x.mismon03}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '03')}
        >
          {x.rcvmon03}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang03 = Sum.byGroup(data, 'musang03', x, ['divinm']);
      const sumNotMis03 = Sum.byGroup(data, 'notmismon03', x, ['divinm']);
      const sumMis03 = Sum.byGroup(data, 'mismon03', x, ['divinm']);
      const sumRcv03 = Sum.byGroup(data, 'rcvmon03', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang03}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis03}
          </FlexLayout>
          <FlexLayout>
            {sumMis03}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv03}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang03_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon03_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon03_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon03_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon04tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '04')}
        >
          {x.mon04}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '04')}
        >
          {x.compmon04}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon04', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon04', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon04_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon04_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon04yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon04 = Number(x.mon04);

      // 완료
      const common04 = Number(x.compmon04);

      // 성사율
      let mon04Yul = 0;
      if (common04 > 0) {
        mon04Yul = Math.round((common04 / mon04) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '04')}
          >
            {mon04Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '04')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon04 = Sum.byGroup(data, 'mon04', x, ['divicd']);
      const sumCompMon04 = Sum.byGroup(data, 'compmon04', x, ['divicd']);
      let mon04yul = 0;
      if (sumCompMon04 > 0) {
        mon04yul = Math.round((sumCompMon04 / sumMon04) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon04yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul04_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '04')}
        >
          {x.musang04}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '04')}
        >
          {x.notmismon04}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '04')}
        >
          {x.mismon04}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '04')}
        >
          {x.rcvmon04}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang04 = Sum.byGroup(data, 'musang04', x, ['divinm']);
      const sumNotMis04 = Sum.byGroup(data, 'notmismon04', x, ['divinm']);
      const sumMis04 = Sum.byGroup(data, 'mismon04', x, ['divinm']);
      const sumRcv04 = Sum.byGroup(data, 'rcvmon04', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang04}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis04}
          </FlexLayout>
          <FlexLayout>
            {sumMis04}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv04}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang04_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon04_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon04_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon04_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon05tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '05')}
        >
          {x.mon05}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '05')}
        >
          {x.compmon05}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon05', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon05', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon05_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon05_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon05yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon05 = Number(x.mon05);

      // 완료
      const common05 = Number(x.compmon05);

      // 성사율
      let mon05Yul = 0;
      if (common05 > 0) {
        mon05Yul = Math.round((common05 / mon05) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '05')}
          >
            {mon05Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '05')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon05 = Sum.byGroup(data, 'mon05', x, ['divicd']);
      const sumCompMon05 = Sum.byGroup(data, 'compmon05', x, ['divicd']);
      let mon05yul = 0;
      if (sumCompMon05 > 0) {
        mon05yul = Math.round((sumCompMon05 / sumMon05) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '05')}
          >
            {mon05yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul05_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '05')}
        >
          {x.musang05}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '05')}
        >
          {x.notmismon05}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '05')}
        >
          {x.mismon05}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '05')}
        >
          {x.rcvmon05}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang05 = Sum.byGroup(data, 'musang05', x, ['divinm']);
      const sumNotMis05 = Sum.byGroup(data, 'notmismon05', x, ['divinm']);
      const sumMis05 = Sum.byGroup(data, 'mismon05', x, ['divinm']);
      const sumRcv05 = Sum.byGroup(data, 'rcvmon05', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang05}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis05}
          </FlexLayout>
          <FlexLayout>
            {sumMis05}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv05}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang05_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon05_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon05_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon05_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon06tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '06')}
        >
          {x.mon06}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '06')}
        >
          {x.compmon06}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon06', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon06', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon06_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon06_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon06yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon06 = Number(x.mon06);

      // 완료
      const common06 = Number(x.compmon06);

      // 성사율
      let mon06Yul = 0;
      if (common06 > 0) {
        mon06Yul = Math.round((common06 / mon06) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '06')}
          >
            {mon06Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '06')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon06 = Sum.byGroup(data, 'mon06', x, ['divicd']);
      const sumCompMon06 = Sum.byGroup(data, 'compmon06', x, ['divicd']);
      let mon06yul = 0;
      if (sumCompMon06 > 0) {
        mon06yul = Math.round((sumCompMon06 / sumMon06) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon06yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul06_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '06')}
        >
          {x.musang06}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '06')}
        >
          {x.notmismon06}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '06')}
        >
          {x.mismon06}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '06')}
        >
          {x.rcvmon06}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang06 = Sum.byGroup(data, 'musang02', x, ['divinm']);
      const sumNotMis06 = Sum.byGroup(data, 'notmismon02', x, ['divinm']);
      const sumMis06 = Sum.byGroup(data, 'mismon02', x, ['divinm']);
      const sumRcv06 = Sum.byGroup(data, 'rcvmon02', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang06}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis06}
          </FlexLayout>
          <FlexLayout>
            {sumMis06}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv06}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang06_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon06_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon06_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon06_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon07tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '07')}
        >
          {x.mon07}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '07')}
        >
          {x.compmon07}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon07', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon07', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon07_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon07_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon07yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon07 = Number(x.mon07);

      // 완료
      const common07 = Number(x.compmon07);

      // 성사율
      let mon07Yul = 0;
      if (common07 > 0) {
        mon07Yul = Math.round((common07 / mon07) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '07')}
          >
            {mon07Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '07')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon07 = Sum.byGroup(data, 'mon07', x, ['divicd']);
      const sumCompMon07 = Sum.byGroup(data, 'compmon07', x, ['divicd']);
      let mon07yul = 0;
      if (sumCompMon07 > 0) {
        mon07yul = Math.round((sumCompMon07 / sumMon07) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon07yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul07_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '07')}
        >
          {x.musang07}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '07')}
        >
          {x.notmismon07}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '07')}
        >
          {x.mismon07}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '07')}
        >
          {x.rcvmon07}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang07 = Sum.byGroup(data, 'musang07', x, ['divinm']);
      const sumNotMis07 = Sum.byGroup(data, 'notmismon07', x, ['divinm']);
      const sumMis07 = Sum.byGroup(data, 'mismon07', x, ['divinm']);
      const sumRcv07 = Sum.byGroup(data, 'rcvmon07', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang07}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis07}
          </FlexLayout>
          <FlexLayout>
            {sumMis07}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv07}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang07_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon07_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon07_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon07_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon08tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '08')}
        >
          {x.mon08}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '08')}
        >
          {x.compmon08}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon08', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon08', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon08_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon08_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon08yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon08 = Number(x.mon08);

      // 완료
      const common08 = Number(x.compmon08);

      // 성사율
      let mon08Yul = 0;
      if (common08 > 0) {
        mon08Yul = Math.round((common08 / mon08) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '08')}
          >
            {mon08Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '08')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon08 = Sum.byGroup(data, 'mon08', x, ['divicd']);
      const sumCompMon08 = Sum.byGroup(data, 'compmon08', x, ['divicd']);
      let mon08yul = 0;
      if (sumCompMon08 > 0) {
        mon08yul = Math.round((sumCompMon08 / sumMon08) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon08yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul08_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '08')}
        >
          {x.musang08}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon08', '08')}
        >
          {x.notmismon08}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '08')}
        >
          {x.mismon08}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '08')}
        >
          {x.rcvmon08}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang08 = Sum.byGroup(data, 'musang08', x, ['divinm']);
      const sumNotMis08 = Sum.byGroup(data, 'notmismon08', x, ['divinm']);
      const sumMis08 = Sum.byGroup(data, 'mismon08', x, ['divinm']);
      const sumRcv08 = Sum.byGroup(data, 'rcvmon08', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang08}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis08}
          </FlexLayout>
          <FlexLayout>
            {sumMis08}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv08}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang08_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon08_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon08_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon08_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon09tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '09')}
        >
          {x.mon09}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '09')}
        >
          {x.compmon09}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon09', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon09', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon09_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon09_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon09yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon09 = Number(x.mon09);

      // 완료
      const common09 = Number(x.compmon09);

      // 성사율
      let mon09Yul = 0;
      if (common09 > 0) {
        mon09Yul = Math.round((common09 / mon09) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '09')}
          >
            {mon09Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '09')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon09 = Sum.byGroup(data, 'mon09', x, ['divicd']);
      const sumCompMon09 = Sum.byGroup(data, 'compmon09', x, ['divicd']);
      let mon09yul = 0;
      if (sumCompMon09 > 0) {
        mon09yul = Math.round((sumCompMon09 / sumMon09) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon09yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul09_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '09')}
        >
          {x.musang09}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '09')}
        >
          {x.notmismon09}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '09')}
        >
          {x.mismon09}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '09')}
        >
          {x.rcvmon09}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang09 = Sum.byGroup(data, 'musang09', x, ['divinm']);
      const sumNotMis09 = Sum.byGroup(data, 'notmismon09', x, ['divinm']);
      const sumMis09 = Sum.byGroup(data, 'mismon09', x, ['divinm']);
      const sumRcv09 = Sum.byGroup(data, 'rcvmon09', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang09}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis09}
          </FlexLayout>
          <FlexLayout>
            {sumMis09}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv09}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang09_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon09_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon09_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon09_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon10tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '10')}
        >
          {x.mon10}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '10')}
        >
          {x.compmon10}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon10', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon10', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon10_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon10_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon10yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon10 = Number(x.mon10);

      // 완료
      const common10 = Number(x.compmon10);

      // 성사율
      let mon10Yul = 0;
      if (common10 > 0) {
        mon10Yul = Math.round((common10 / mon10) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '10')}
          >
            {mon10Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '10')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon10 = Sum.byGroup(data, 'mon10', x, ['divicd']);
      const sumCompMon10 = Sum.byGroup(data, 'compmon10', x, ['divicd']);
      let mon10yul = 0;
      if (sumCompMon10 > 0) {
        mon10yul = Math.round((sumCompMon10 / sumMon10) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon10yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul10_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '10')}
        >
          {x.musang10}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '10')}
        >
          {x.notmismon10}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '10')}
        >
          {x.mismon10}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '10')}
        >
          {x.rcvmon10}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang10 = Sum.byGroup(data, 'musang10', x, ['divinm']);
      const sumNotMis10 = Sum.byGroup(data, 'notmismon10', x, ['divinm']);
      const sumMis10 = Sum.byGroup(data, 'mismon10', x, ['divinm']);
      const sumRcv10 = Sum.byGroup(data, 'rcvmon10', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang10}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis10}
          </FlexLayout>
          <FlexLayout>
            {sumMis10}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv10}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang10_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon10_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon10_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon10_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon11tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '11')}
        >
          {x.mon11}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '11')}
        >
          {x.compmon11}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon11', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon11', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon11_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon11_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon11yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon11 = Number(x.mon11);

      // 완료
      const common11 = Number(x.compmon11);

      // 성사율
      let mon11Yul = 0;
      if (common11 > 0) {
        mon11Yul = Math.round((common11 / mon11) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '11')}
          >
            {mon11Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '11')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon11 = Sum.byGroup(data, 'mon11', x, ['divicd']);
      const sumCompMon11 = Sum.byGroup(data, 'compmon11', x, ['divicd']);
      let mon11yul = 0;
      if (sumCompMon11 > 0) {
        mon11yul = Math.round((sumCompMon11 / sumMon11) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon11yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul11_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon11',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '11')}
        >
          {x.musang11}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '11')}
        >
          {x.notmismon11}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '11')}
        >
          {x.mismon11}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '11')}
        >
          {x.rcvmon11}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang11 = Sum.byGroup(data, 'musang11', x, ['divinm']);
      const sumNotMis11 = Sum.byGroup(data, 'notmismon11', x, ['divinm']);
      const sumMis11 = Sum.byGroup(data, 'mismon11', x, ['divinm']);
      const sumRcv11 = Sum.byGroup(data, 'rcvmon11', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang11}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis11}
          </FlexLayout>
          <FlexLayout>
            {sumMis11}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv11}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang11_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon11_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon11_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon11_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon12tot',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          견적
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          justify="center"
          align="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '12')}
        >
          {x.mon12}
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'mon', '12')}
        >
          {x.compmon12}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {Format.number(Sum.byGroup(data, 'mon12', x, ['divicd']))}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(Sum.byGroup(data, 'compmon12', x, ['divicd']))}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout>
          {scope.state.tab_3_tot_mon12_tot || '0'}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          {scope.state.tab_3_tot_compmon12_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon12yul',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          성사율
        </FlexLayout>
        <FlexLayout
          style={{ color: 'var(--color-violet' }}
        >
          (%)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => {
      // 견적
      const mon12 = Number(x.mon12);

      // 완료
      const common12 = Number(x.compmon12);

      // 성사율
      let mon12Yul = 0;
      if (common12 > 0) {
        mon12Yul = Math.round((common12 / mon12) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '12')}
          >
            {mon12Yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
            onDoubleClick={() => scope.onPopupChk(true, 'mon', '12')}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    sum: (x, data) => {
      const sumMon12 = Sum.byGroup(data, 'mon12', x, ['divicd']);
      const sumCompMon12 = Sum.byGroup(data, 'compmon12', x, ['divicd']);
      let mon12yul = 0;
      if (sumCompMon12 > 0) {
        mon12yul = Math.round((sumCompMon12 / sumMon12) * 100);
      }
      return (
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            {mon12yul}
          </FlexLayout>
          <FlexLayout
            align="center"
            justify="center"
            style={{ color: 'var(--color-violet)' }}
          >
            %
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          {scope.state.tab_3_tot_compyul12_tot || '0'}
        </FlexLayout>

        <FlexLayout style={{ color: 'var(--color-violet)' }}>
          %
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          style={{ color: 'var(--color-blue)' }}
        >
          완료상세
        </FlexLayout>
        <FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            무상
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            미매출
          </FlexLayout>
          <FlexLayout>
            매출
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            수금
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-green)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'musang', '12')}
        >
          {x.musang12}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-red)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'notmismon', '12')}
        >
          {x.notmismon12}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          onDoubleClick={() => scope.onPopupChk(true, 'mismon', '12')}
        >
          {x.mismon12}
        </FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
          style={{ color: 'var(--color-blue)' }}
          onDoubleClick={() => scope.onPopupChk(true, 'rcvmon', '12')}
        >
          {x.rcvmon12}
        </FlexLayout>
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumMusang12 = Sum.byGroup(data, 'musang12', x, ['divinm']);
      const sumNotMis12 = Sum.byGroup(data, 'notmismon12', x, ['divinm']);
      const sumMis12 = Sum.byGroup(data, 'mismon12', x, ['divinm']);
      const sumRcv12 = Sum.byGroup(data, 'rcvmon12', x, ['divinm']);
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout
            style={{ color: 'var(--color-green)' }}
          >
            {sumMusang12}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-red)' }}
          >
            {sumNotMis12}
          </FlexLayout>
          <FlexLayout>
            {sumMis12}
          </FlexLayout>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
          >
            {sumRcv12}
          </FlexLayout>
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout>
        <FlexLayout style={{ color: 'var(--color-green)' }}>
          {scope.state.tab_3_tot_musang12_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-red)' }}>
          {scope.state.tab_3_tot_notmismon12_tot || '0'}
        </FlexLayout>
        <FlexLayout>
          {scope.state.tab_3_tot_mismon12_tot || '0'}
        </FlexLayout>
        <FlexLayout style={{ color: 'var(--color-blue)' }}>
          {scope.state.tab_3_tot_rcvmon12_tot || '0'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
