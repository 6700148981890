import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { SelfInspectionTemplate } from './SelfInspection.template';
import {
  SelfInspectionModel,
  SelfInspectionModel2,
} from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  ConfirmDelete,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';


export interface evlcdItem {
  custcd: string;
  spjangcd: string;
  evlcd: string;
  evlnm: string;
  gevcd: string;
  gevnm: string;
  useyn: string;
  remark: string;
  inperid: string;
  indate: string;
  new: string;
  SEL_CHK_ITEM_CD: string;
}


interface SelfInspectionState {
  // 구분
  data: Array<SelfInspectionModel>;
  // 점검내용
  inspectiondata: Array<SelfInspectionModel2>;
  evlcds?: Array<evlcdItem>;
  focuseddata?: SelfInspectionModel;
  focusedinspectiondata?: SelfInspectionModel2;
  searchEvlcd: string;
}

/**
 * 컨트롤러
 * @window w_tb_e032_new
 * @category 자체점검항목등록
 */
export class SelfInspection extends PageComponent<PageProps, SelfInspectionState>
  implements PageToolEvents {
  updatedRows?: Array<SelfInspectionModel>;

  updatedRows2?: Array<SelfInspectionModel2>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      data: [],
      inspectiondata: [],
      searchEvlcd: '1',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 첫번째 서버 요청
    // 구분코드 리스트 가져오기
    // request 함수를 호출
    // 실패시 data에 undefined가 들어옴
    const data = await api.dropdown('wf_dd_e030_new');

    // 서버 요청 실패시 빠져나감
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ evlcds: data.items });

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];
    this.updatedRows2 = [];


    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        evlcd: this.state.searchEvlcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new SelfInspectionModel(x))],
        }, async () => {
          await this.table.current?.update();
          next && next();
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('evlcd', this.state.focuseddata?.evlcd, type) || 0;
      this.state.data.length > index && this.table.current?.setFocus(index);
    });
  }


  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      evlcd: this.state.searchEvlcd,
    });
    if (data) {
      data.new = '1';

      this.setState({
        data: [
          new SelfInspectionModel(data, true),
          ...this.state.data,
        ],
      }, async () => {
        await this.table.current?.update(false);
        this.table.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const inspectiondata = await api.fxExec(
      'dw_2_new',
      {
        evlcd: this.state.searchEvlcd,
        gevcd: this.state.focuseddata?.gevcd,
      },
    );

    inspectiondata && this.setState({
      inspectiondata: [
        new SelfInspectionModel2(inspectiondata, true),
        ...this.state.inspectiondata,
      ],
    }, async () => {
      await this.tableDetail.current?.update(false);
      this.tableDetail.current?.setFocus(0, 1);
    });
  }


  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
      items2: this.updatedRows2,
    }, true)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focuseddata?.gevcd} - ${this.state.focuseddata?.gevnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focuseddata) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onDeleteEvent2() {
    if (!this.state.focusedinspectiondata?.seq) {
      ConfirmWarning.show('오류', '항목을 먼저 선택해주세요');
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.state.focusedinspectiondata?.evcd} - ${this.state.focusedinspectiondata?.evnm}`;
    await api.fxDelete(
      'dw_2_delete',
      text,
      {
        seq: this.state.focusedinspectiondata?.seq,
        evlcd: this.state.focusedinspectiondata?.evlcd,
        evcd: this.state.focusedinspectiondata?.evcd,
        gevcd: this.state.focusedinspectiondata?.gevcd,
      },
    ) && this.onRowFocusEvent(this.state.focuseddata!);
  }


  @action
  onRowFocusEvent(item: SelfInspectionModel) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState({ focuseddata: item }, async () => {
      const inspectiondata = await api.fxExec('dw_1_RowFocuschanged', this.state.focuseddata);
      if (inspectiondata?.items) {
        this.setState({
          inspectiondata: inspectiondata.items.map((x: any) => new SelfInspectionModel2(x)),
          focusedinspectiondata: undefined,
        }, async () => {
          this.tableDetail.current?.update(true);
          this.tableDetail.current?.setFocus(0, 1);
        });
      } else {
        this.setState({
          inspectiondata: [],
          focusedinspectiondata: undefined,
        }, async () => {
          this.tableDetail.current?.update(true);
        });
      }
    });
  }


  @action
  onRowFocusEvent2(item:SelfInspectionModel2) {
    if (item.new === '1') return;
    this.setState({ focusedinspectiondata: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<SelfInspectionModel>, updatedRows: Array<SelfInspectionModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<SelfInspectionModel2>, updatedRows2: Array<SelfInspectionModel2>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ inspectiondata: rows2 });
  }

  render() {
    return (
      <SelfInspectionTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state.data,
            ...this.state.inspectiondata,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
