import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e031_01
 * @category 일상점검항목등록-중분류
 */

export class DailyInspectionModel2 {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 점검종류
   *
   * **data type** varchar(2)
   */
  evlcd: string;

  /**
   * 구분코드
   *
   * **data type** varchar(2)
   */
  gevcd: string;

  /**
   * No
   *
   * **data type** varchar(2)
   */
  evcd: string;

  /**
   * 점검내용
   *
   * **data type** varchar(100)
   */
  evnm: string;

  /**
   * 점검주기(월)
   *
   * **data type** varchar(2)
   */
  evmon: string;

  /**
   * 점검주기(주)
   *
   * **data type** varchar(2)
   */
  evju: string;

  /**
   * 검사주기
   *
   * **data type** char(1)
   */
  kcyn: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  useyn: string;

  /**
   * 상태값A
   *
   * **data type** char(1)
   */
  statea: string;

  /**
   * 상태값B
   *
   * **data type** char(1)
   */
  stateb: string;

  /**
   * 상태값C
   *
   * **data type** char(1)
   */
  statec: string;

  /**
   * 상태값기타
   *
   * **data type** char(1)
   */
  stateelse: string;

  /**
   * 주기
   *
   * **data type** char(3)
   */
  jugi: string;

  /**
   * 상태
   *
   * **data type** char(3)
   */
  state: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 점검1월
   *
   * **data type** varchar(1)
   */
  mon1: string;

  /**
   * 점검2월
   *
   * **data type** varchar(1)
   */
  mon2: string;

  /**
   * 점검3월
   *
   * **data type** varchar(1)
   */
  mon3: string;

  /**
   * 점검4월
   *
   * **data type** varchar(1)
   */
  mon4: string;

  /**
   * 점검5월
   *
   * **data type** varchar(1)
   */
  mon5: string;

  /**
   * 점검6월
   *
   * **data type** varchar(1)
   */
  mon6: string;

  /**
   * 점검7월
   *
   * **data type** varchar(1)
   */
  mon7: string;

  /**
   * 점검8월
   *
   * **data type** varchar(1)
   */
  mon8: string;

  /**
   * 점검9월
   *
   * **data type** varchar(1)
   */
  mon9: string;

  /**
   * 점검10월
   *
   * **data type** varchar(1)
   */
  mon10: string;

  /**
   * 점검11월
   *
   * **data type** varchar(1)
   */
  mon11: string;

  /**
   * 점검12월
   *
   * **data type** varchar(1)
   */
  mon12: string;

  /**
   * 무한반복 제거를 위한 변수
   *
   *
   */
  new: string;

  isnew: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.evlcd = data.evlcd || '';
    this.gevcd = data.gevcd || '';
    this.evcd = data.evcd || '';
    this.evnm = data.evnm || '';
    this.evmon = data.evmon || '';
    this.evju = data.evju || '';
    this.kcyn = data.kcyn || '';
    this.useyn = data.useyn || '';
    this.statea = data.statea || '';
    this.stateb = data.stateb || '';
    this.statec = data.statec || '';
    this.stateelse = data.stateelse || '';
    this.jugi = data.jugi || '';
    this.state = data.state || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.mon1 = data.mon1 || '';
    this.mon2 = data.mon2 || '';
    this.mon3 = data.mon3 || '';
    this.mon4 = data.mon4 || '';
    this.mon5 = data.mon5 || '';
    this.mon6 = data.mon6 || '';
    this.mon7 = data.mon7 || '';
    this.mon8 = data.mon8 || '';
    this.mon9 = data.mon9 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.new = data.new || '';
    this.isnew = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
