import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e037_type1
 * @category 개별업무일지[총무팀]
 */

export class GeneralJournalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly kukcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly edunum: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly rspcd: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly rptremark: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly overwk: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly upmuremark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly endperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly endpernm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly endflag: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly endtext: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly sttime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly endtime: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly carcd1: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly carnum1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly soyou1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly model1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly gubun1: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly carcd2: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly carnum2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly soyou2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly model2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly gubun2: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt2: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly sjik: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly djik: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly syn: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly attcnt: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly type: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly sndflag: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly title: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rptdate = data.rptdate || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.kukcd = data.kukcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.rptremark = Fix.newline(data.rptremark) || '';
    this.overwk = data.overwk || '';
    this.upmuremark = Fix.newline(data.upmuremark) || '';
    this.endperid = data.endperid || '';
    this.endpernm = data.endpernm || '';
    this.endflag = data.endflag || '';
    this.endtext = data.endtext || '';
    this.chk = data.chk || '';
    this.stdate = data.stdate || '';
    this.sttime = data.sttime || '';
    this.enddate = data.enddate || '';
    this.endtime = data.endtime || '';
    this.carcd1 = data.carcd1 || '';
    this.carnum1 = data.carnum1 || '';
    this.soyou1 = data.soyou1 || '';
    this.model1 = data.model1 || '';
    this.gubun1 = data.gubun1 || '';
    this.samt1 = data.samt1 || '';
    this.carcd2 = data.carcd2 || '';
    this.carnum2 = data.carnum2 || '';
    this.soyou2 = data.soyou2 || '';
    this.model2 = data.model2 || '';
    this.gubun2 = data.gubun2 || '';
    this.samt2 = data.samt2 || '';
    this.sjik = data.sjik || '';
    this.djik = data.djik || '';
    this.syn = data.syn || '';
    this.samt = data.samt || '';
    this.gubun = data.gubun || '';
    this.appdate = data.appdate || '';
    this.apptime = data.apptime || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appperid = data.appperid || '';
    this.picflag = data.picflag || '';
    this.attcnt = data.attcnt || '0';
    this.refer = data.refer || '';
    this.type = data.type || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.sndflag = data.sndflag || '';
    this.title = data.title || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
