import * as React from 'react';
import { RefObject } from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { EnrollmentTemplate } from './Enrollment.template';
import { PageComponent } from '../../../../utils';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Date6,
  Date8,
  Today,
} from '../../../../utils/time';
import EnrollmentTableModel from './models/EnrollmentTableModel';
import { Sum } from '../../../../utils/array';

interface EnrollmentState {
  searchQuery: string;
  stdate: string;
  enddate: string;

  data: Array<EnrollmentModel>;
  content: EnrollmentModel;
  lastSaveData: EnrollmentModel;

  dataTable: Array<EnrollmentTableModel>

  gubuns?: Array<any>;

  isVisibleDetailItemSelector: boolean;
  detailItemSelectorSearchQuery: string;
  detailItemSelectorData: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export class Enrollment extends PageComponent<PageProps, EnrollmentState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef()

  table: RefObject<TableLayout> = React.createRef();

  gridFocus?: EnrollmentModel;

  gridFocusIndex: number = 0;

  tableFocus?: EnrollmentTableModel;

  tableFocusIndex: number = 0;

  tabIndex: number = 0;

  gridDetailItemSelector: RefObject<GridLayout> = React.createRef()

  infinityDetailItemSelector?: InfinityRetrieve;

  detailItemSelected?: any;

  detailItemSelectorResolver?: (item: any) => void;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const params = this.props.publicStore?.getPageParams();

    this.state = props.state || {
      searchQuery: params?.searchQuery || '',
      stdate: params?.costdate || `${Date6.make().substr(0, 6)}01`,
      enddate: params?.costdate || Date8.make(),

      data: [],
      content: new EnrollmentModel({
        costdate: params?.costdate || '',
        costnum: params?.costnum || '',
      }),

      isVisibleDetailItemSelector: false,
      detailItemSelectorSearchQuery: '',
      detailItemSelectorData: [],
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubuns: data.items });

    this.onRetrieveEvent();
  }

  @action
  async componentDidRecover() {
    const params = await this.props.publicStore?.getPageParams();

    if (params && params?.costdate) {
      await this.SS({
        content: new EnrollmentModel({
          ...this.state.content,
          costdate: params.costdate,
          costnum: params.costnum,
        }, this.state.content.isNew),
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.filter((x) => x.costdate).map((item) => new EnrollmentModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        if (this.state.data.length > 0) {
          this.grid.current?.setFocus(this.gridFocusIndex);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
      content: new EnrollmentModel(),
      dataTable: [],
    });

    const index = await this.infinity?.retrieveTo(['costdate', 'costnum'],
      [this.state.content?.costdate, this.state.content?.costnum], type, true) || 0;

    if (!autoLoad) return;
    this.state.data.length > index && this.grid.current?.setFocus(index);
    await this.table.current?.update(true);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (this.state.searchQuery !== '' || this.state.enddate < Today.date()) {
      await this.SS({
        searchQuery: '',
        stdate: `${Date8.make().substr(0, 6)}01`,
        enddate: Date8.make(),
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST, false);
    }
    if (data) {
      const one = new EnrollmentModel(data, true);

      await this.SS({
        content: one,
        lastSaveData: one,
        data: [one, ...this.state.data],
        dataTable: [],
      });

      await this.grid.current?.setFocus(0);
      await this.table.current?.update(true);
    }
  }

  @action
  async onSaveEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!this.state.dataTable?.length) {
      ConfirmWarning.show('오류', '하단 부품내역등록에 최소 한개 이상 입력해주세요');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save(
      {
        ...this.state.content,
        samt: Sum.all(this.state.dataTable, 'samt'),
        tamt: Sum.all(this.state.dataTable, 'tamt'),
        mamt: Sum.all(this.state.dataTable, 'mamt'),
        items: this.state.dataTable,
      },
      this.state.content.isNew,
    )) {
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.state.content?.isReported) {
      this.onSubmitButtonClicked(true);
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.gridFocus?.custcd}`;

    if (await api.delete(text, this.state.content)) {
      this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    let sgroup = '0';

    if (this.state.content.contflag === '0') {
      if (AskType.YES === await Confirm.ask(
        '소계 포함',
        '소계를 출력할때 표기할까요?',
        '예',
        '아니요',
      )) {
        sgroup = '1';
      }
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      sgroup,
      costdate: this.state.content.costdate,
      costnum: this.state.content.costnum,
      remark: this.state.content.remark,
      wkactcd: this.state.content.wkactcd,
      state: this.state.content.state,
      appgubun: this.state.content.appgubun,
      gubun: this.state.content.gubun,
      evidyn: this.state.content.evidyn,
    });
  }


  @action
  async onRowFocusEvent(item: EnrollmentModel | undefined, index: number) {
    this.gridFocus = item;
    this.gridFocusIndex = index;

    if (!item) {
      await this.SS({ content: new EnrollmentModel() });
      return;
    }

    if (item.isNew) {
      this.SS({
        content: this.state.lastSaveData,
      });
      return;
    }
    await this.SS({ content: item, dataTable: [] });
    await this.table.current?.update(true);

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', item);

    if (data) {
      this.SS({
        content: new EnrollmentModel(data),
        dataTable: data?.items?.map((x: any) => new EnrollmentTableModel(x)) || [],
      });

      if (this.state.dataTable.length > 0) {
        this.table.current?.setFocus(0);
      }
    }
    await this.table.current?.update(true);
  }

  @action
  async openReferSelector() {
    if (!this.state.content?.appgubun || !this.state.content?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.INSTALL, this.state.content?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.gridFocus, this.gridFocusIndex);
  }

  @action
  async headerItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...content,
      [key]: value,
      itemname: key,
      data: value,
      new: content.isNew ? '1' : '0',
    });

    const one = new EnrollmentModel({ ...content, ...result }, content.isNew);
    this.setState({ content: one });
  }


  @action
  onTabChange(index: number) {
    this.tabIndex = index;
  }


  // Detail
  @action
  async onTableRowFocusEvent(item: EnrollmentTableModel | undefined, index: number) {
    if (!item) {
      this.tableFocus = item;
      this.tableFocusIndex = 0;
      return;
    }

    this.tableFocus = item;
    this.tableFocusIndex = index;
  }

  @action
  async onTableChange(rows: Array<EnrollmentTableModel>) {
    await this.SS({ dataTable: rows });
  }

  @action
  async tableItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_3_itemchanged', {
      ...this.state.dataTable[this.tableFocusIndex],
      [key]: value,
      itemname: key,
      data: value,
    }, this.state.dataTable[this.tableFocusIndex].isNew);

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  async tableProductItemChanged(rowUpdate: RowUpdate, pcodeChange: any) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_3_itemchanged', {
      ...this.state.dataTable[this.tableFocusIndex],
      ...pcodeChange,
      itemname: 'uamt',
      data: pcodeChange.uamt || '0',
    }, this.state.dataTable[this.tableFocusIndex].isNew);

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  async onClickDetailNew() {
    const { actionStore: api } = this.props;

    const costseq = `00${parseInt(this.state.dataTable[this.state.dataTable.length - 1]?.costseq || '00', 10) + 1}`
      .substr(-2);

    const data = await api.fxExec('dw_3_new', {
      costdate: this.state.content?.costdate || '',
      costnum: this.state.content?.costnum || '',
      costseq,
    });

    // 신규 추가 시 qty 1 기본값으로
    data.qty = '1';

    this.setState({
      dataTable: [...this.state.dataTable, new EnrollmentTableModel(data, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.dataTable.length - 1);
    });
  }

  @action
  async onClickDetailRemove() {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const result = await Confirm.ask('확인', '삭제하시겠습니까?', '예', '아니오');

    if (result) return;

    const item = this.state.dataTable[this.tableFocusIndex];

    const { actionStore: api } = this.props;
    await api.fxExec('dw_3_delete', item);

    this.setState({
      dataTable: update(this.state.dataTable, {
        $splice: [[this.tableFocusIndex, 1]],
      }),
    });

    await this.table.current?.update(false);

    // Focus next row
    if (this.state.dataTable.length) {
      const next = this.tableFocusIndex === 0 ? 0 : this.tableFocusIndex - 1;
      this.tableFocusIndex = next;
      this.table.current?.setFocus(next);
    }
  }


  // Button
  @action
  async onSubmitButtonClicked(isForce: boolean = false) {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.state.content?.appgubun === '101') {
      return;
    }

    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (user.perid !== this.state.content?.inperid) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다');
      return;
    }

    if (!isForce && this.state.content?.isReported && !this.state.content?.isReportedCancelable) {
      ConfirmWarning.show('오류', '현재 결재 상태에서는 취소할 수 없습니다');
      return;
    }

    if (this.state.content?.isReportedCancelable) {
      appflag = 'cancel';
      text = `${this.state.content?.actnm} 결재상신을 취소하시겠습니까?`;
    } else {
      appflag = 'ok';
      text = `${this.state.content?.actnm} 결재를 상신하시겠습니까?`;
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    if (appflag === 'ok') {
      if (await modalStore.openApprovalLine(PAPERCD.INSTALL)) {
        await api.fxExec('wb_appreport', {
          papercd: PAPERCD.INSTALL.toString(),
          ...this.state.content,
          appflag,
        }) && this.onRetrieveEvent();
      }
    } else {
      await api.fxExec('wb_appreport', {
        papercd: PAPERCD.INSTALL.toString(),
        ...this.state.content,
        appflag,
      }) && this.onRetrieveEvent();
    }
  }

  @action
  async onEmailButtonClicked() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content.permail, '오류', '메일주소를 입력해주세요')) {
      return;
    }

    let sgroup = '0';

    if (this.state.content.contflag === '0') {
      if (AskType.YES === await Confirm.ask(
        '소계 포함',
        '소계를 출력할때 표기할까요?',
        '예',
        '아니요',
      )) {
        sgroup = '1';
      }
    }

    const { actionStore: api } = this.props;
    api.fxEmail(
      this.state.content.permail,
      this.state.content.actnm,
      '',
      '',
      '',
      '',
      {
        sgroup,
        costdate: this.state.content.costdate,
        costnum: this.state.content.costnum,
        remark: this.state.content.remark,
        wkactcd: this.state.content.wkactcd,
        state: this.state.content.state,
        appgubun: this.state.content.appgubun,
        gubun: this.state.content.gubun,
        evidyn: this.state.content.evidyn,
      },
    );
  }

  @action
  async onFaxButtonClicked() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content.perfax, '오류', '팩스번호를 입력해주세요')) {
      return;
    }

    let sgroup = '0';

    if (this.state.content.contflag === '0') {
      if (AskType.YES === await Confirm.ask(
        '소계 포함',
        '소계를 출력할때 표기할까요?',
        '예',
        '아니요',
      )) {
        sgroup = '1';
      }
    }

    const { actionStore: api } = this.props;
    api.fxFax(
      this.state.content.perfax,
      '',
      {
        sgroup,
        costdate: this.state.content.costdate,
        costnum: this.state.content.costnum,
        remark: this.state.content.remark,
        wkactcd: this.state.content.wkactcd,
        state: this.state.content.state,
        appgubun: this.state.content.appgubun,
        gubun: this.state.content.gubun,
        evidyn: this.state.content.evidyn,
      },
    );
  }

  @action
  async onCopyButtonClicked() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      return;
    }

    if (AskType.YES === await Confirm.ask(
      '견적서 복사',
      `견적일자 : ${Date8.withDash(this.state.content.costdate)}\n견적번호 : ${this.state.content.costnum}\n복사 하시겠습니까?`,
      '예',
      '아니요',
    )) {
      const { actionStore: api } = this.props;
      const data = await api.fxExec('wb_copy', {
        ...this.state.content,
        appgubun: '',
        appnum: '',
        textnum: '',
        costnum: '',
        items: this.state.dataTable || [],
      });

      if (data) {
        const one = new EnrollmentModel(data, true);

        await this.SS({
          content: one,
          lastSaveData: one,
          data: [one, ...this.state.data],
          dataTable: data?.items?.map((x: any) => new EnrollmentTableModel(x, true)) || [],
        });

        await this.grid.current?.setFocus(0);
        await this.table.current?.update(true);
      }
    }
  }


  @action
  async openDetailitemSelector(searchQuery: string): Promise<any> {
    this.detailItemSelected = undefined;
    await this.SS({
      isVisibleDetailItemSelector: true,
      detailItemSelectorData: [],
    });

    return new Promise<any>((resolve) => {
      this.detailItemSelectorResolver = resolve;
      this.retrieveDetailItemSelector(searchQuery);
    });
  }

  @action
  async retrieveDetailItemSelector(searchQuery: string) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityDetailItemSelector = new InfinityRetrieve(
      {
        sub: 'w_popup_da450',
        as_nm: searchQuery,
        contflag: this.state.content?.contflag === '2' ? '%' : this.state.content?.contflag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          detailItemSelectorSearchQuery: searchQuery,
          detailItemSelectorData: [
            ...this.state.detailItemSelectorData,
            ...items,
          ],
        });
      },
      async () => {
        await this.SS({
          detailItemSelectorData: [],
        });

        await this.infinityDetailItemSelector?.retrieve();
        if (this.state.detailItemSelectorData.length > 0) {
          this.gridDetailItemSelector.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      detailItemSelectorData: [],
    });

    await this.infinityDetailItemSelector?.retrieve();
    if (this.state.detailItemSelectorData.length > 0) {
      this.gridDetailItemSelector.current?.setFocus(0);
    }
  }

  @action
  async onSelectedDetailItem(item: any) {
    this.detailItemSelected = item;
  }

  @action
  async onSelectDetailItem() {
    if (!this.detailItemSelected) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    this.detailItemSelectorResolver && this.detailItemSelectorResolver(this.detailItemSelected);
    this.closeDetailItemSelector();
  }

  @action
  async closeDetailItemSelector() {
    this.SS({
      isVisibleDetailItemSelector: false,
    });
  }



  render() {
    return <EnrollmentTemplate
      scope={this}
    />;
  }
}
