import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640_all
 * @category 정기매입자료생성
 */
export class RegularBuyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 삭제
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 매입일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 지급처명
   *
   * **data type** varchar(13)
   */
  mijcltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  mijcltnm: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세액
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 증빙구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 비용항목
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 항목구분
   *
   * **data type** varchar(100)
   */
  artnm: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 귀속부서
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 자산구분
   *
   * **data type** varchar(3)
   */
  jsflag: string;

  /**
   * 발행구분
   *
   * **data type** varchar(3)
   */
  bhflag: string;

  /**
   * 영세율
   *
   * **data type** varchar(3)
   */
  osflag: string;

  /**
   * 내수구분
   *
   * **data type** varchar(3)
   */
  cdflag: string;

  /**
   * 세액공제
   *
   * **data type** varchar(2)
   */
  taxreclafi: string;

  /**
   * 세액공제
   *
   * **data type** varchar(40)
   */
  taxrenm: string;

  /**
   * 회계적용
   *
   * **data type** varchar(3)
   */
  accyn: string;

  /**
   * 계정과목
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 지급예정일
   *
   * **data type** varchar(8)
   */
  schdate: string;

  /**
   * 제목
   *
   * **data type** varchar(100)
   */
  title: string;

  /**
   * 비고
   *
   * **data type** varchar(200)
   */
  bigo: string;

  /**
   * 구분
   *
   * **data type** varchar(200)
   */
  flag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltcd = data.cltcd || '';
    this.bigo = data.bigo || '';
    this.flag = data.flag || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.chk = data.chk || '';
    this.remark = data.remark || '';
    this.cltnm = data.cltnm || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.billkind = data.billkind || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.gubun = data.gubun || '';
    this.mijamt = data.mijamt || '';
    this.artcd = data.artcd || '';
    this.artnm = data.artnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.jsflag = data.jsflag || '';
    this.bhflag = data.bhflag || '';
    this.osflag = data.osflag || '';
    this.cdflag = data.cdflag || '';
    this.taxreclafi = data.taxreclafi || '';
    this.taxrenm = data.taxrenm || '';
    this.accyn = data.accyn || '';
    this.acccd = data.acccd || '';
    this.schdate = data.schdate || '';
    this.title = data.title || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
