import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { ConstructionModel } from '../models';
import { MonthConstruction } from '../MonthConstruction';
import Format from '../../../../../utils/string/Format';

// 매출별
const Construction: GridLayoutHeader<ConstructionModel, MonthConstruction>[] = [
  {
    id: 'gubun',
    text: '코드',
    width: 3,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {x.gubun}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'gubunnm',
    text: '구분',
    width: 9,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {x.gubunnm}
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: '1월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon01)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon01_tot !== '0' && scope?.state.mon01_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: '2월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon02)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon02_tot !== '0' && scope?.state.mon02_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: '3월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon03)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon03_tot !== '0' && scope?.state.mon03_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: '4월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon04)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon04_tot !== '0' && scope?.state.mon04_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: '5월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon05)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon05_tot !== '0' && scope?.state.mon05_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: '6월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon06)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon06_tot !== '0' && scope?.state.mon06_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: '7월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon07)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon07_tot !== '0' && scope?.state.mon07_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: '8월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon08)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon08_tot !== '0' && scope?.state.mon08_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: '9월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon09)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon09_tot !== '0' && scope?.state.mon09_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: '10월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon10)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon10_tot !== '0' && scope?.state.mon10_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon11',
    text: '11월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon11)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon11_tot !== '0' && scope?.state.mon11_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: '12월',
    width: 6,
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={scope?.getColotEvent(x.gubunnm)}
      >
        {Format.number(x.mon12)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mon12_tot !== '0' && scope?.state.mon12_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'totmon',
    text: '합계',
    width: 6,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.totmon)}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totmon_tot !== '0' && scope?.state.totmon_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'aver',
    text: '평균',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(Math.round(Number(x.aver)))}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.aver_tot !== '0' && scope?.state.aver_tot}
      </FlexLayout>
    ),
  },
];

export default Construction;
