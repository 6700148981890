import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ElmanDataTemplate } from './ElmanData.template';
import { ElmanDataModel } from './ElmanData.model';
import { GridLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface ElmanDataState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;

  // data
  data: Array<ElmanDataModel>;
  focused?: ElmanDataModel;
}

/**
 * 컨트롤러
 * @window w_tb_e050w_elman
 * @category 엘맨자료
 */
export class ElmanData extends PageComponent<PageProps, ElmanDataState>
  implements PageToolEvents {
  updatedRows?: Array<ElmanDataModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',

      // data
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new ElmanDataModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['date', 'seq'],
        [this.state.focused?.date, this.state.focused?.seq], type, true) || 0;
      this.state.data.length > index && await this.grid.current?.setFocus(index);
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  onRowFocusEvent(item: ElmanDataModel) {
    this.setState({ focused: item });
  }

  render() {
    return (
      <ElmanDataTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
