import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { YearArticle } from './YearArticle';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_ca640w_03
 * @category 년항목별 매입/지급현황
 */
export const YearArticleTemplate: React.FC<TemplateProps<YearArticle>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => setData({ year: value })}
          onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                setData({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                setData({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => setData({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span>구분</span>
        <ComboBox
          value={scope.state?.gubun}
          textAlign="center"
          data={scope.state.gubuns?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
          onSelect={(option) => setData({ gubun: option.value }, () => scope.onRetrieveEvent())}>
        </ComboBox>
      </FlexLayout>

      <FlexLayout size={300}>
        <span style={{ color: 'var(--color-red)' }}>항목</span>
        <TextBox
          textAlign="center"
          value={scope.state.artcd}
          onChange={(value) => setData({ artcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_CA648_ARG',
            {},
            true,
            (item) => {
              setData({
                artcd: item.cd,
                artnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={2}
          value={scope.state.artnm}
          readonly={true}
          onChange={(value) => setData({ artnm: value })}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <GridLayout
        ref={scope.grid}
        headerHeight={77}
        header={[
          {
            id: 'spjangnm',
            text: '사업장명',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'artnm',
            text: '항목명',
            width: 6,
          },
          {
            id: 'accnm',
            text: '계정과목',
            width: 9,
          },
          {
            id: 'beamt',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  합계
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.totbemon_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.totmijmon_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.totsndmon_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tottmijmon_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.beamt)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.totmijamt)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.totsndamt)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tottmijamt)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon01',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  01월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon01_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon01_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon01_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon01_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon01)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon01)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon01)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon01)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon02',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  02월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon02_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon02_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon02_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon02_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon01)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon01)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon01)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon01)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon03',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  03월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon03_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon03_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon03_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon03_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon03)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon03)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon03)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon03)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon04',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  04월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon04_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon04_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon04_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon04_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon04)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon04)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon04)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon04)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon05',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  05월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon05_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon05_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon05_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon05_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon05)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon05)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon05)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon05)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon06',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  06월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon06_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon06_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon06_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon06_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon06)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon06)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon06)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon06)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon07',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  07월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon07_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon07_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon07_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon07_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon07)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon07)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon07)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon07)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon08',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  08월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon08_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon08_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon08_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon08_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon08)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon08)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon08)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon08)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon09',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  09월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon09_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon09_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon09_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon09_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon09)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon09)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon09)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon09)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon10',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  10월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon10_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon10_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon10_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon10_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon10)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon10)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon10)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon10)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon11',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  11월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon11_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon11_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon11_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon11_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon11)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon11)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon11)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon11)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'bemon12',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout align="start">
                  12월
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  이월미지급
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: '#7F7A2C' }}
                >
                  매입금액
                </FlexLayout>

                <FlexLayout align="end">
                  지급금액
                </FlexLayout>

                <FlexLayout
                  align="end"
                  style={{ color: 'var(--color-red)' }}
                >
                  미지급금액
                </FlexLayout>
              </FlexLayout>
            ),
            width: 6,
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.bemon12_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijmon12_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sndmon12_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.tmijmon12_tot}
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {Format.number(x.bemon12)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijmon12)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.sndmon12)}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tmijmon12)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        rowHeight={77}
      />
    </FlexLayout>
  </FlexLayout>
  );
};
