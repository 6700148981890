import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FieldPartsReplace } from './FieldPartsReplace';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  GridLayout,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

const Compyns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '0', remark: '견적', color: 'inherit' },
  { value: '1', remark: '공사', color: 'var(--color-green)' },
  { value: '2', remark: '매출', color: 'var(--color-blue)' },
  { value: '3', remark: '폐기', color: 'var(--color-red)' },
  { value: '4', remark: '수주', color: 'var(--color-red)' },
  { value: '5', remark: '수금', color: 'var(--color-violet)' },
];

/**
 * 화면
 * @window w_tb_e471w_05
 * @category 현장별 부품교체[수명] 가능현황
 */
export const FieldPartsReplaceTemplate: React.FC<TemplateProps<FieldPartsReplace>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={300}>
        <span>검색어</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          weight={1.5}
          value={scope.state.divicd}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          isDisabledTrackingStateChange={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          value={scope.state.divinm}
          weight={1.5}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>사용자</span>
        <TextBox
          weight={1.5}
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />

        <TextBox
          value={scope.state.pernm}
          textAlign="center"
          weight={1.5}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span>유효기간</span>
        <ComboBox
          value={scope.state.gijun}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '만료(지남)' },
            { value: '1', remark: '유효기간내' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ gijun: option.value }, () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span style={{ color: 'var(--color-red)' }}>채크품목</span>
        <TextBox
          value={scope.state.agecode}
          textAlign="center"
          onChange={(value) => scope.setState({ agecode: value })}
          bindSearchModal={
            new SearchBinding(
              scope.props.modalStore,
              'TB_CA501_AGE',
              {
              },
              true,
              (item) => {
                scope.setState({
                  agecode: item.cd,
                  agename: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )
          }
        >
        </TextBox>
        <TextBox
          value={scope.state.agename}
          readonly={true}
        >
        </TextBox>
      </FlexLayout>

      <span>
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="end"
            justify="start"
            style={{ color: 'var(--color-blue)' }}
          >
            *채크품목을 먼저 선택하세요
          </FlexLayout>
          <FlexLayout
            align="start"
            justify="start"
            style={{ color: 'var(--color-blue)' }}
          >
            &nbsp;&nbsp;선택한 채크항목으로 교체가능한 호기검출
          </FlexLayout>
        </FlexLayout>
      </span>
    </SearchLayout>

    <FlexLayout minHeight={500}>
      <FlexLayout size={600}>
        <GridLayout
          ref={scope.leftGrid}
          data={scope.state.leftData}
          header={[
            {
              id: 'divinm',
              text: '부서',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  합계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 250,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpcnt',
              text: '호기건수',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.stflag === '2' ? 'var(--color-black)' : 'var(--color-red)' }}
                >
                  {x.equpcnt}
                </FlexLayout>
              ),
            },
          ]}
          infinityHandler={scope.leftInfinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        >
        </GridLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.rightGrid}
          data={scope.state.rightData}
          header={[
            {
              id: 'equpcd',
              text: '호기코드',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpcd}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'elno',
              text: '승강기번호',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.elno.substr(0, 4)}-{x.elno.substr(4, 3)}
                </FlexLayout>
              ),
            },
            {
              id: 'agename',
              text: '선택부품',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ fontWeight: 'bold' }}
                >
                  {x.agename}
                </FlexLayout>
              ),
            },
            {
              id: 'age_life',
              text: '수명(유효기간)',
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {x.age_year} 년
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ marginRight: 32 }}
                  >
                    {x.age_month} 개월
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'gigan',
              text: '미교체기간',
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red' }}
                  >
                    {x.gigan_year} 년
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ marginRight: 32, color: 'var(--color-red)' }}
                  >
                    {x.gigan_month} 개월
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'stflag',
              text: '검출기준일',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.stflag === '2' ? 'var(--color-black)' : 'var(--color-red)' }}
                >
                  {
                    x.stflag === '0' ? '계약일자'
                      : x.stflag === '1' ? '설치일자' : '공사일자'
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'stdate',
              text: '검출기준일자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.stflag === '2' ? 'var(--color-black)' : 'var(--color-red)' }}
                >
                  {x.stdate.substr(0, 10)}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.rightRowFocusEvent(item)}
        >
        </GridLayout>
      </FlexLayout>
    </FlexLayout>

    <FlexLayout isVertical={true}>
      <SubHeader>
        <FlexLayout align="center">
          <span>선택부품교체이력</span>
        </FlexLayout>
      </SubHeader>
      <GridLayout
        header={[
          {
            id: 'divinm',
            text: '부서명',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'comppernm',
            text: '처리자',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.comppernm}
              </FlexLayout>
            ),
          },
          {
            id: 'compdate',
            text: '공사일자',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.compdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'compnum',
            text: '번호',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compnum}
              </FlexLayout>
            ),
          },
          {
            id: 'wkactcd',
            text: '회사구분',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={x.wkactcd === '001' ? { color: 'var(--color-violet)' } : ''}
              >
                {x.wkactnm}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'equpnm',
            text: '호기명',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.equpnm}
              </FlexLayout>
            ),
          },
          {
            id: 'myn',
            text: '유무상',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.myn === '1' ? '무상' : '유상'}
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '구분',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
              </FlexLayout>
            ),
          },
          {
            id: 'pname',
            text: '품목명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.pname}
              </FlexLayout>
            ),
          },
          {
            id: 'pserial',
            text: '품목시리얼번호',
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pserial}
              </FlexLayout>
            ),
          },
          {
            id: 'qty',
            text: '수량',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.qty}
              </FlexLayout>
            ),
          },
          {
            id: 'samt',
            text: '공급가액',
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'tamt',
            text: '부가세',
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.tamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'compamt',
            text: '공사금액',
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.compamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'compyn',
            text: '상태',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: Compyns.filter((y) => y.value === x.compyn)[0]?.color }}
              >
                {Compyns.filter((y) => y.value === x.compyn)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '매출금액',
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.misamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'offinm',
            text: '공사명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.offinm}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.detailData}
      />
    </FlexLayout>
  </FlexLayout>
);
