import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { OrderMonthlyTemplate } from './OrderMonthly.template';
import { Today } from '../../../../utils/time';
import { OrderMonthlyModel } from './OrderMonthly.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components';
import { OrderMonthlyModalModel } from './OrderMonthlyModal.model';

interface OrderMonthlyState {
  // 조회조건
  year: string;
  spjangcds?: Array<any>;
  spjangcd?: string;
  gubuns?: Array<any>;

  // List
  orderList: Array<OrderMonthlyModel>;

  // Modal
  isModal: boolean,
  isModal2: boolean,
  modalName: string,
  modalSearch: string,
  modalList: Array<OrderMonthlyModalModel>,
  modalStmon: string,
  modalEndmon: string,
  tabType: string,

  // Modal1 Trail
  cnt_tot: string,
  compamt_tot: string,
  notamt_tot: string,
  qty_tot: string,
  rcvamt_tot: string,

  // Modal2 Trail
  iamt_tot: string,
  mijamt_tot: string,
  sndamt_tot: string,
}

export class OrderMonthly extends PageComponent<PageProps, OrderMonthlyState> implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  modalGrid: React.RefObject<GridLayout> = React.createRef();

  modalGrid2: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 조회 조건
      year: Today.year(),
      spjangcds: [],
      spjangcd: 'ZZ',

      // List
      orderList: [],

      // Modal
      isModal: false,
      isModal2: false,
      modalName: '',
      modalSearch: '',
      modalStmon: '',
      modalEndmon: '',

      iamt_tot: '',
      mijamt_tot: '',
      sndamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 사업장
    const data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    // 매입세금계산서
    const data2 = await api.dropdown('wf_dd_ca510_113_02');
    if (!data) return;
    this.setState({ gubuns: data2.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        spjangcd: this.state.spjangcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          orderList: [
            ...this.state.orderList,
            ...items.map((x: any) => new OrderMonthlyModel(x)),
          ],
        }, async () => {
        });
      },
      async () => {
        await this.SS({
          orderList: [],
        });

        await this.infinity?.retrieveAll();
      },
    );

    await this.SS({
      orderList: [],
    });

    await this.infinity?.retrieveAll();
  }

  @action
  async popupDoubleClicked(tabName: string, stmon: string, endmon: string, modalName: string) {
    if (tabName === 'rcv' || tabName === 'rcvex' || tabName === 'rcvtot') {
      this.setState({
        isModal: true,
        isModal2: false,
      });
    }

    if (tabName === 'mij' || tabName === 'snd' || tabName === 'i') {
      this.setState({
        isModal: false,
        isModal2: true,
      });
    }
    this.setState({
      tabType: tabName,
      modalName,
      modalStmon: stmon,
      modalEndmon: endmon,
    });
    // 탭, 월 뒷자리
    await this.modalRetrieve(tabName, stmon, endmon);
  }

  @action
  async modalRetrieve(tabName: string, stmon: string, endmon: string) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e601w_sulchi_11',
      tab: tabName,
      spjangcd: this.state.spjangcd,
      stmon: `${this.state.year}${stmon}`,
      endmon: `${this.state.year}${endmon}`,
      as_nm: this.state.modalSearch,
    });

    if (data) {
      this.setState({
        modalList: data?.items.map((x: any) => new OrderMonthlyModalModel(x)),
        cnt_tot: data?.cnt_tot || '0',
        compamt_tot: data?.compamt_tot || '0',
        notamt_tot: data?.notamt_tot || '0',
        qty_tot: data?.qty_tot || '0',
        rcvamt_tot: data?.rcvamt_tot || '0',
        iamt_tot: data?.iamt_tot || '0',
        mijamt_tot: data?.mijamt_tot || '0',
        sndamt_tot: data?.sndamt_tot || '0',
      });
    }
  }

  @action
  async modalSearchRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e601w_sulchi_11',
      tab: this.state.tabType,
      spjangcd: this.state.spjangcd,
      stmon: `${this.state.year}${this.state.modalStmon}`,
      endmon: `${this.state.year}${this.state.modalEndmon}`,
      as_nm: this.state.modalSearch,
    });

    if (data) {
      this.setState({
        modalList: data?.items.map((x: any) => new OrderMonthlyModalModel(x)),
        cnt_tot: data?.cnt_tot || '0',
        compamt_tot: data?.compamt_tot || '0',
        notamt_tot: data?.notamt_tot || '0',
        qty_tot: data?.qty_tot || '0',
        rcvamt_tot: data?.rcvamt_tot || '0',
        iamt_tot: data?.iamt_tot || '0',
        mijamt_tot: data?.mijamt_tot || '0',
        sndamt_tot: data?.sndamt_tot || '0',
      });
    }
  }

  @action
  async modalClose() {
    this.setState({
      isModal: false,
      isModal2: false,
      modalList: [],
    });
  }

  render() {
    return (
      <OrderMonthlyTemplate scope={this}></OrderMonthlyTemplate>
    );
  }
}
