import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { FreeTemplate } from './Free.template';
import { FreeModel } from './Free.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface FreeState {
  src: string;
  stmon: string;
  endmon: string;
  endnm: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  gubun: string;
  muperid: string;
  mupernm: string;
  searchQuery: string;
  clttype: string;
  data: Array<FreeModel>;
  focusedData?: FreeModel;
  contgs: Array<any>;
  contgubuns: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e101w_03
 * @category 무상현장관리현황
 */
export class Free extends PageComponent<PageProps, FreeState>
  implements PageToolEvents {
  updatedRows?: Array<FreeModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      stmon: '',
      endmon: '',
      searchQuery: '',
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      muperid: '',
      data: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        as_nm: this.state.searchQuery,
        divicd: this.state.divicd,
        perid: this.state.perid,
        muperid: this.state.muperid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new FreeModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      if (this.state.data.length) {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      as_nm: this.state.searchQuery,
      divicd: this.state.divicd,
      perid: this.state.perid,
      muperid: this.state.muperid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      as_nm: this.state.searchQuery,
      divicd: this.state.divicd,
      perid: this.state.perid,
      muperid: this.state.muperid,
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: FreeModel) {
    this.setState({ focusedData: item });
  }

  render() {
    return <FreeTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
