import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Status } from './Status';
import { TabModel } from '../../../../models';
import { StatusDateTabTemplate } from './tabs/StatusDateTab.template';
import { StatusDetailTabTemplate } from './tabs/StatusDetailTab.template';

/**
 * 화면 > 탭
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export const StatusTab: Array<TabLayoutDataItem<Status>> = [
  {
    tab: new TabModel('DATE', FaCodeBranch, '일자별'),
    template: (scope) => <StatusDateTabTemplate scope={scope} />,
  },
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '상세현황(공사별)'),
    template: (scope) => <StatusDetailTabTemplate scope={scope} />,
  },
];
