/**
 * 모델
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서 매출 팝업창
 */
export class MisModalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  actnm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  billkind: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  cltnm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  gubun: string;

  /**
   * 구분명
   *
   * **data type** varchar(8)
   */
  gubunnm: string;

  /**
   * 매출금액
   *
   * **data type** varchar(8)
   */
  misamt: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 매출번호
   *
   * **data type** varchar(8)
   */
  misnum: string;

  /**
   * 담당자 코드
   *
   * **data type** varchar(8)
   */
  perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  pernm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  projno: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * tax구분
   *
   * **data type** varchar(8)
   */
  taxcls: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.billkind = data.billkind || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.gubun = data.gubun || '';
    this.gubunnm = data.gubunnm || '';
    this.misamt = data.misamt || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.remark = data.remark || '';
    this.taxcls = data.taxcls || '';
  }
}
