import * as React from 'react';
import {
  FiTrash,
  FiUpload,
} from 'react-icons/fi';
import {
  Button,
  CheckBox,
  FlexLayout,
  ImageView,
  LabelBox,
  LoaderButton,
  TableLayout,
  TabView,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  Management,
  ManagementTabId,
  ManagementTabDataStateNames,
  ManagementTabFocusedStateNames,
  ManagementTabUpdatesStateNames,
  ManagementImages,
} from './Management';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { CompanyModel } from './models';

/**
 * 화면
 * @window w_tb_e015
 * @category 유지보수관리코드
 */
export const ManagementTemplate: React.FC<TemplateProps<Management>> = ({
  scope,
  update,
  requestRowFocusEvent,
}) => {
  const setData = update!;
  const request = requestRowFocusEvent!;
  return (

    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout isVertical={true}>
        {scope.tabIndex !== ManagementTabId.COMPANY
        && <TableLayout
            ref={scope.table}
            header={scope.tabHeaders[scope.tabIndex]}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            // @ts-ignore
            data={scope.state[ManagementTabDataStateNames[scope.tabIndex]]}
            onRowFocusEvent={(item) => setData({
              [ManagementTabFocusedStateNames[scope.tabIndex]]: item,
            })}
            onChange={(rows, updatedRows) => setData({
              [ManagementTabDataStateNames[scope.tabIndex]]: rows,
              [ManagementTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
            })}
        />}

        {scope.tabIndex === ManagementTabId.COMPANY
        && <FlexLayout>
            <FlexLayout>
              <TableLayout
                  ref={scope.table}
                  header={scope.tabHeaders[scope.tabIndex]}
                  infinityHandler={scope.infinity}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  // @ts-ignore
                  data={scope.state[ManagementTabDataStateNames[scope.tabIndex]]}
                  onRowFocusEvent={(item:CompanyModel) => {
                    setData({
                      [ManagementTabFocusedStateNames[scope.tabIndex]]: item,
                    }, () => {
                      request();
                    });
                  }}
                  onChange={(rows, updatedRows) => setData({
                    [ManagementTabDataStateNames[scope.tabIndex]]: rows,
                    [ManagementTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
                  })}
              />
            </FlexLayout>
            <FlexLayout isVertical={true}>
                <FlexLayout>
                  <ImageView
                      src={scope.state.img_e018_1_pic}
                      footer={
                        <React.Fragment>
                          <LoaderButton
                            isFull={true}
                            onClick={() => scope.imageSelect(ManagementImages.E018_1_PIC)}
                            percent={scope.state.percentImageE018}
                          >
                            <FiUpload />
                            <span>등록</span>
                          </LoaderButton>

                          <Button
                            isFull={true}
                            theme={BUTTON_THEMES.RED}
                            onClick={() => scope.imageDelete(ManagementImages.E018_1_PIC)}
                          >
                            <FiTrash />
                            <span>삭제</span>
                          </Button>
                        </React.Fragment>
                      }
                  />
                </FlexLayout>
                <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
                  <FlexLayout>
                    <LabelBox
                        title="회사 구분 담당자 연락처"
                        style={{
                          color: 'var(--color-blue)',
                        }}
                    />
                  </FlexLayout>
                  <FlexLayout justify="right">
                    <Button
                      style={{ height: '25px', width: '60px' }}
                      onClick={() => scope.onNewEvent2()}
                    >
                        추가
                    </Button>
                    <Button
                      style={{ height: '25px', width: '60px' }}
                      theme={BUTTON_THEMES.RED}
                      onClick={() => scope.onDeleteEvent2()}
                    >
                        삭제
                    </Button>
                  </FlexLayout>
                </FlexLayout>
                <TableLayout
                    ref={scope.tableDetail}
                    header={[
                      {
                        id: 'seq',
                        text: '순번',
                        width: 50,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            ref={ref}
                            value={x.seq}
                            onChange={(value) => rowUpdate({ seq: value })}
                            readonly={true}
                            transparent={true}
                            textAlign="center"
                          />
                        ),
                      },
                      {
                        id: 'pernm',
                        text: '담당자',
                        width: 100,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            ref={ref}
                            value={x.pernm}
                            textAlign="center"
                            onChange={(value) => rowUpdate({ pernm: value })}
                          />
                        ),
                      },
                      {
                        id: 'tel',
                        text: '연락처',
                        width: 200,
                        render: (x, rowUpdate, ref) => (
                          <TelnumTextBox
                            ref={ref}
                            textAlign="center"
                            value={x.tel}
                            onChange={(value) => rowUpdate({ tel: value })}
                          />
                        ),
                      },
                      {
                        id: 'trouble_sms',
                        text: '사람갇힘문자발송',
                        width: 150,
                        render: (x, rowUpdate) => (
                          <CheckBox
                            value={x.trouble_sms === '1'}
                            onChange={(value) => rowUpdate({ trouble_sms: value ? '1' : '0' })}
                          />
                        ),
                      },
                    ]}
                    infinityHandler={scope.infinity}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    data={scope.state.companyDetail}
                    onChange={(rows2, updatedRows2) => {
                      scope.onUpdatedRows2(rows2, updatedRows2);
                    }}
                    onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
                />
            </FlexLayout>
        </FlexLayout>}
      </FlexLayout>
    </FlexLayout>
  );
};
