import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_tb_e101_cls
 * @category 해지보고서
 */

export class TerminationModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actpernm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly amt: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly sayoo: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly result: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly misamt: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly tel: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly attcnt: number;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly bigo: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly tasacltnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly tasacontdate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tasaamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tasauamt: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly r_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gs_color: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.seq = data.seq || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.actpernm = data.actpernm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.amt = data.amt || '';
    this.sayoo = Fix.newline(data.sayoo) || '';
    this.result = Fix.newline(data.result) || '';
    this.remark = Fix.newline(data.remark) || '';
    this.misamt = data.misamt || '';
    this.tel = data.tel || '';
    this.datetime = data.datetime || '';
    this.appdate = data.appdate || '';
    this.apptime = data.apptime || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.picflag = data.picflag || '0';
    this.attcnt = data.attcnt || 0;
    this.refer = data.refer || '';
    this.bigo = Fix.newline(data.bigo) || '';
    this.tasacltnm = data.tasacltnm || '';
    this.tasacontdate = data.tasacontdate || '';
    this.tasaamt = data.tasaamt || '';
    this.tasauamt = data.tasauamt || '';
    this.r_color = data.r_color || '';
    this.gs_color = data.gs_color || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
