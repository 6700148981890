class Resizer {
  /**
   * 이미지 파일 크기 조절
   * @param base64Image base64 인코딩된 이미지
   * @param maxSize 최대 파일 크기(bytes)
   * @param isTransparent 배경이 투명할지(원본이 투명하지 않으면 의미 없음)
   * @param backgroundColor 배경이 투명하지 않을때 색상
   */
  byRoughSize(
    base64Image: string,
    maxSize: number,
    isTransparent: boolean = true,
    backgroundColor: string = '#FFFFFF',
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();

      if (base64Image.length < maxSize) {
        image.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = image.naturalWidth;
          elem.height = image.naturalHeight;
          const ctx = elem.getContext('2d');
          if (ctx) {
            if (!isTransparent) {
              ctx.fillStyle = backgroundColor;
              ctx.fillRect(0, 0, image.naturalWidth, image.naturalHeight);
            } else {
              ctx.clearRect(0, 0, image.naturalWidth, image.naturalHeight);
            }
            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
            resolve(ctx.canvas.toDataURL('image/png', 100));
          } else {
            reject();
          }
        };
      } else {
        image.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = Math.round(image.naturalWidth / 2.0);
          elem.height = Math.round(image.naturalHeight / 2.0);
          const ctx = elem.getContext('2d');
          if (ctx) {
            ctx.drawImage(image, 0, 0, elem.width, elem.height);
            resolve(
              this.byRoughSize(ctx.canvas.toDataURL('image/png', 100), maxSize),
            );
          } else {
            reject();
          }
        };
      }

      image.src = base64Image;
    });
  }

  async byRoughSizeToFile(
    base64Image: string,
    maxSize: number,
    isTransparent: boolean = true,
    backgroundColor: string = '#FFFFFF',
  ): Promise<Blob> {
    const dataURL = await this.byRoughSize(
      base64Image,
      maxSize,
      isTransparent,
      backgroundColor,
    );

    const blobBin = atob(dataURL.substr(dataURL.indexOf(',') + 1));
    const array = [];
    for (let i = 0; i < blobBin.length; i += 1) {
      array.push(blobBin.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], { type: 'image/png' });
  }
}

export default new Resizer();
