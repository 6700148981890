import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { SettingsTemplate } from './Settings.template';
import { SettingsModel } from './Settings.model';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';
import { ConfirmWarning } from '../../../../utils/confirm';

export interface SettingsListItem {
  spjangcd: string;
  spjangnm: string;
  userid: string;
  pernm: string;
}

interface SettingsState {
  settingsList: Array<SettingsListItem>;
  focusedSettings?: SettingsListItem;
  data: SettingsModel;
  settingModal: boolean;
}

/**
 * 컨트롤러
 * @window w_xusers
 * @category 개인환경설정
 */
export class Settings extends PageComponent<PageProps, SettingsState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      settingsList: [],
      data: new SettingsModel(),
      settingModal: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const response = await api.retrieve();
    this.setState({
      settingsList: response.items.map((x: any) => ({
        spjangcd: x.spjangcd,
        spjangnm: x.spjangnm,
        userid: x.userid,
        pernm: x.pernm,
      })),
    });

    if (response.items.length) {
      this.setState({
        data: new SettingsModel(response.items[0]),
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save(this.state.data, false);
  }

  @action
  toggleSettingsModal(isOpen: boolean) {
    this.setState({ settingModal: isOpen });
  }

  @action
  openLBSJoin() {
    this.props.publicStore.withManager(() => {
      this.props.publicStore.publish({
        command: 'lbs-join',
      });
    });
  }

  @action
  async onAlert() {
    ConfirmWarning.show('확인', '해당 내용 적용 다음 로그인시부터 적용됩니다.');
  }

  render() {
    return (
      <SettingsTemplate
        scope={this}
        update={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
