import { GLHF } from '../../../../../constants';
import { TransportationFee } from '../TransportationFee';
import { TransportationFeeModel } from '../models/TransportationFee.model';

/**
 * 화면 > 탭  > 그리드
 * @window w_tb_ca640_pa105_02w
 * @category 월교통비/통행료내역
 */
export const LastMonthGrid: GLHF<TransportationFeeModel, TransportationFee> = () => ([
]);
