import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640w
 * @category 매입현황
 */
export class PurchaseStateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 이월미지급
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 발생금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 지급금액
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(102)
   */
  cltnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 프로젝트건수
   *
   * **data type** long
   */
  projcnt: string;

  /**
   * 프로젝트대분류구분
   *
   * **data type** varchar(1)
   */
  projflag: string;

  /**
   * 프로젝트구분
   *
   * **data type** varchar(1)
   */
  projgubun: string;

  /**
   * 항목명
   *
   * **data type** varchar(50)
   */
  artnm: string;

  /**
   * 경비증빙
   *
   * **data type** varchar(1)
   */
  proof: string;

  /**
   * 증빙구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 귀속부서
   *
   * **data type** varchar(100)
   */
  divinm: string;

  /**
   * 세액공제
   *
   * **data type** varchar(2)
   */
  taxreclafi: string;

  /**
   * 세액공제
   *
   * **data type** varchar(40)
   */
  taxrenm: string;

  /**
   * 발행구분
   *
   * **data type** varchar(3)
   */
  bhflag: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 공급가액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 미지급금액
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 비용항목그룹
   *
   * **data type** number
   */
  code: string;

  /**
   * 비용항목그룹명
   *
   * **data type** number
   */
  cnam: string;

  /**
   * 선택
   *
   * **data type** number
   */
  chk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.beamt = data.beamt || '';
    this.mijamt = data.mijamt || '';
    this.iamt = data.iamt || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.projcnt = data.projcnt || '';
    this.projflag = data.projflag || '';
    this.projgubun = data.projgubun || '';
    this.artnm = data.artnm || '';
    this.proof = data.proof || '';
    this.gubun = data.gubun || '';
    this.divinm = data.divinm || '';
    this.taxreclafi = data.taxreclafi || '';
    this.taxrenm = data.taxrenm || '';
    this.bhflag = data.bhflag || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.resuamt = data.resuamt || '';
    this.code = data.code || '';
    this.cnam = data.cnam || '';
    this.chk = data.chk || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
