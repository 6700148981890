import { TLHF } from '../../../../../constants';
import {
  FlexLayout, OptionBox, TextBox,
} from '../../../../../components';
import { Result } from '../Result';
import ResultDetailModel from '../models/ResultDetailModel';


/**
 * 화면 > 메인 테이블
 * @window w_tb_e035_03
 * @category 점검결과등록
 */
export const ResultDetailTableHeader: TLHF<ResultDetailModel, Result> = (scope) => ([
  {
    id: 'resultcd',
    text: 'No',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.resultcd}
      </FlexLayout>
    ),
  },
  {
    id: 'resultnm',
    text: '검사항목, 장치',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.resultnm}
      </FlexLayout>
    ),
  },
  {
    id: 'resultdtlnm',
    text: '세부사항',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.resultdtlnm}
      </FlexLayout>
    ),
  },
  {
    id: 'evmon',
    text: '주기',
    width: 120,
    render: (x) => {
      if (x.gubun === '1') return <FlexLayout />;

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {x.evmon} 개월
          &nbsp;(<span
          style={{ color: x.evmon === '1' ? 'var(--color-blue)' : 'inherit' }}
        >
          {scope.getSerialText(x)}
        </span>)
        </FlexLayout>
      );
    },
  },
  {
    id: 'state',
    text: '상태',
    width: 350,
    render: (x, rowUpdate) => {
      if (x.gubun === '1') return <FlexLayout />;

      return (
        <OptionBox
          value={x.state}
          data={[
            { value: 'A', remark: '양호' },
            { value: 'B', remark: '주의' },
            { value: 'C', remark: '긴급' },
            { value: 'D', remark: '제외' },
            { value: 'E', remark: '없음' },
          ]}
          onChange={(item) => rowUpdate({ state: item.value.toString() })}
        />
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 300,
    render: (x, rowUpdate, ref) => {
      if (x.gubun === '1') return <FlexLayout />;

      return (
        <TextBox
          ref={ref}
          value={x.remark}
          onChange={(v) => rowUpdate({ remark: v })}
        />
      );
    },
  },
]);
