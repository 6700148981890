import * as React from 'react';
import {
  IoIosMail, MdKeyboardArrowLeft, MdKeyboardArrowRight, RiFolder2Line,
} from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Overtime, OvertimeItemChangeTypes } from './Overtime';
import { Date8, Today } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Update } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_pb420
 * @category 시간외근무보고서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const OvertimeTemplate: React.FC<TemplateProps<Overtime>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={210}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={300}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder={scope.state.data?.new === '0' ? '제목, 비고로 검색하세요' : ''}
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>담당</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onReport()}>
            <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout size={250}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'overdate',
              text: '일자',
              width: 15,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.overdate}
                />
              ),
            },
            {
              id: 'overnum',
              text: '번호',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.overnum}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '작성자',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'title',
              text: '제목',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'appgubun',
              text: '상태',
              width: 10,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={
                      () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                    }
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark?.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'attcnt',
              text: '첨부',
              width: 15,
              render: (x) => (
                <TextBox
                  textAlign='center'
                  value={`${x.attcnt} 건`}
                />
              ),
            },
          ]}
          data={scope.state.overtimeList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
          disabled={!model?.appnum && !model?.overnum}
          disabledText="신규로 추가하거나 시간외근무보고서를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.overdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ overdate: value },
                  () => scope.itemChanged(OvertimeItemChangeTypes.OVERDATE))}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.overnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="##-######-###-####"
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="제목"
              isNeed={true}
            >
              <TextBox
                textAlign="left"
                value={model?.title}
                onChange={(value) => setData({ title: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="비고"
              weight={4}
            >
              <TextBox
                isMultiline={true}
                textAlign="left"
                value={model.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={3}
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                color={model?.attcnt < 1 ? 'var(--color-black)' : 'var(--color-blue)'}
                value={model?.attcnt < 1 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

        <LayoutTitle size={230}>시간외근무(야근)내역</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '80px' }}
            onClick={() => scope.onCreate()}
          >
            호기생성
          </Button>
          <Button
            style={{ height: '25px', width: '80px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '80px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.appnum && !model?.overnum}
        disabledText="신규로 추가하거나 시간외근무보고서를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'seq',
              text: 'No',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq}
                </FlexLayout>
              ),
            },
            {
              id: 'overperid',
              text: '처리자코드',
              width: 75,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.overperid}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ overperid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        overperid: item.cd,
                        overpernm: item.cdnm,
                        // divinm: item.uv_arg3,
                      });
                      scope.detailItemChanged(OvertimeItemChangeTypes.PERID, {
                        ...x,
                        overperid: item.cd,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'overpernm',
              text: '근무자명',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.overpernm}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ overpernm: value })}
                />
              ),
            },
            {
              id: 'overrspnm',
              text: '직위',
              width: 75,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.overrspnm}
                </FlexLayout>
              ),
            },
            {
              id: 'divinm',
              text: '부서명',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
            },
            {
              id: 'stdate',
              text: '근무시작일',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <FlexLayout isVertical={true}>
                  <DateTextBox
                    ref={ref}
                    format="####-##-##"
                    value={(!x.stdate) ? `${Today.date()}` : x.stdate }
                    textAlign="center"
                    onChange={(value) => {
                      const year = value.substr(0, 4);
                      const month = value.substr(4, 2);
                      const day = value.substr(6, 2);
                      let daynm = '';

                      const days = `${Number(year)}-${Number(month)}-${Number(day)}`;
                      const getDate = new Date(days).getDay();

                      if (getDate === 0) {
                        daynm = '일';
                      } else if (getDate === 1) {
                        daynm = '월';
                      } else if (getDate === 2) {
                        daynm = '화';
                      } else if (getDate === 3) {
                        daynm = '수';
                      } else if (getDate === 4) {
                        daynm = '목';
                      } else if (getDate === 5) {
                        daynm = '금';
                      } else if (getDate === 6) {
                        daynm = '토';
                      }

                      rowUpdate({
                        stdate: value,
                        stweekname: daynm,
                      });
                      scope.detailItemChanged(OvertimeItemChangeTypes.STDATE, {
                        ...x,
                        stdate: value,
                      });
                    }}
                  />
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={
                      x.stweekname === '일' ? { color: 'var(--color-red)' }
                        : x.stweekname === '토' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }
                    }
                  >
                    {x.stweekname}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'sttime',
              text: '처리시간',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  textAlign="center"
                  format="##:##"
                  value={x.sttime}
                  onChange={(value) => {
                    rowUpdate({ sttime: value });
                    scope.detailItemChanged(OvertimeItemChangeTypes.STTIME, {
                      ...x,
                      sttime: value,
                    });
                  }}
                />
              ),
            },
            {
              id: 'enddate',
              text: '근무종료일',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <FlexLayout isVertical={true}>
                  <DateTextBox
                    ref={ref}
                    format="####-##-##"
                    value={(!x.enddate) ? `${Today.date()}` : x.enddate }
                    textAlign="center"
                    onChange={(value) => {
                      const year = value.substr(0, 4);
                      const month = value.substr(4, 2);
                      const day = value.substr(6, 2);
                      let daynm = '';

                      const days = `${Number(year)}-${Number(month)}-${Number(day)}`;
                      const getDate = new Date(days).getDay();

                      if (getDate === 0) {
                        daynm = '일';
                      } else if (getDate === 1) {
                        daynm = '월';
                      } else if (getDate === 2) {
                        daynm = '화';
                      } else if (getDate === 3) {
                        daynm = '수';
                      } else if (getDate === 4) {
                        daynm = '목';
                      } else if (getDate === 5) {
                        daynm = '금';
                      } else if (getDate === 6) {
                        daynm = '토';
                      }
                      rowUpdate({
                        enddate: value,
                        endweekname: daynm,
                      });
                      scope.detailItemChanged(OvertimeItemChangeTypes.ENDDATE, {
                        ...x,
                        enddate: value,
                      });
                    }}
                  />
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={
                      x.endweekname === '일' ? { color: 'var(--color-red)' }
                        : x.endweekname === '토' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }
                    }
                  >
                    {x.endweekname}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'endtime',
              text: '종료시간',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  textAlign="center"
                  format="##:##"
                  value={x.endtime}
                  onChange={(value) => {
                    rowUpdate({ endtime: value });
                    scope.detailItemChanged(OvertimeItemChangeTypes.ENDTIME, {
                      ...x,
                      endtime: value,
                    });
                  }}
                />
              ),
            },
            {
              id: 'overtime',
              text: '근무시간(분)',
              width: 100,
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout>
                    <FlexLayout
                      justify='right'
                      align='center'
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {x.overtime}
                    </FlexLayout>
                    <FlexLayout
                      justify='center'
                      align='center'
                    >
                      분
                    </FlexLayout>
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.overtime_hour}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '근무사유',
              width: 300,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.overtimeDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data.appnum}
            appnm={'시간외근무보고서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });

              // grid
              const index = scope.state.overtimeList.findIndex((x) => x.overdate === model?.overdate);
              await Update.byIndex(scope, 'journalList', index, 'attcnt', count.toString());
              scope.grid.current?.forceRepaint(true);
            }}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
