import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { TaxInvoiceTemplate } from './TaxInvoice.template';
import { TaxInvoiceModel } from './TaxInvoice.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface TaxInvoiceState {
  // search
  searchQuery: string;
  stdate: string;
  enddate: string;
  gubun?: Array<any>;
  searchGubun?: string;

  // data
  data: Array<TaxInvoiceModel>;
  focused?: TaxInvoiceModel;
  pushTotalCheck: boolean;
  pushData: Array<TaxInvoiceModel>;
}

/**
 * 컨트롤러
 * @window w_tb_e050w_03
 * @category 세금계산서일괄출력
 */
export class TaxInvoice extends PageComponent<PageProps, TaxInvoiceState>
  implements PageToolEvents {
  updatedRows?: Array<TaxInvoiceModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  allData?: Array<TaxInvoiceModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      searchGubun: '%',

      // data
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 구분
    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubun: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        billkind: '%',
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new TaxInvoiceModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          this.table.current?.update(true);
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['pubnum', 'misnum'],
        [this.state.focused?.pubnum, this.state.focused?.misnum], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 0);
      }
      // 전체 체크
      this.allData = await this.infinity?.retrieveAll();
    });
    await this.table.current?.update(true);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    const chkData: TaxInvoiceModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new TaxInvoiceModel({
          ...x,
        }));
      }
    });

    if (chkData.length < 1) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.stdate.slice(0, 6),
      as_nm: this.state.searchQuery,
      items: chkData,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.searchGubun,
      billkind: '%',
    });
  }

  @action
  onRowFocusEvent(item: TaxInvoiceModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<TaxInvoiceModel>, updatedRows: Array<TaxInvoiceModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.allData?.forEach((x: any) => {
      checkData.push(new TaxInvoiceModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  render() {
    return (
      <TaxInvoiceTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
