import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import { Global, TemplateProps } from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { BreakNumber, BreakNumberTabDataStateNames, BreakNumberTabId } from './BreakNumber';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TabView } from '../../../../components/layout/TabView';
import { ComboBox, ComboBoxModel, IFrame } from '../../../../components';

/**
 * 메인화면
 * @window w_tb_e401w_01
 * @category 고장건수통계
 */
export const BreakNumberTemplate: React.FC<TemplateProps<BreakNumber>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.styear}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ styear: value })}
          onEnter={(value) => scope.setState({ styear: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.styear}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ styear: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.styear}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ styear: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.endyear}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ endyear: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.endyear}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ endyear: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.styear}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ styear: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
          />
      </FlexLayout>
      <FlexLayout>
        <span>주야간</span>
        <ComboBox
          value={scope.state.nflag}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '주간' },
            { value: '2', remark: '야간' },
            { value: '3', remark: '주말' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ nflag: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>
      <FlexLayout>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            현장
          </span>
        <TextBox
          size={100}
          value={scope.state.actcd === '%' ? '' : scope.state.actcd}
          textAlign="center"
          onChange={(value) => scope.setState({ actcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              scope.setState({
                actcd: item.cd,
                actnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.actnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout>
        <span style={{ color: 'var(--color-red)' }}>호기명</span>
        <TextBox
          value={scope.state.equpcd === '%' ? '' : scope.state.equpcd}
          textAlign="center"
          onChange={(value) => scope.setState({ equpcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E611',
            { uv_arg1: scope.state.actcd },
            true,
            (item) => {
              scope.setState({
                equpcd: item.cd,
                equpnm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.equpnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <TabView
        tabs={scope.tabs}
        focused={scope.state.focusedTab}
        onChange={(tab) => scope.onTabChange(tab)}
      />
    </FlexLayout>
    <GridLayout
      header={scope.tabHeaders[scope.tabIndex]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      // @ts-ignore
      data={scope.state[BreakNumberTabDataStateNames[scope.tabIndex]] || [] }
      scope={scope}
    />
    {(scope.tabIndex === BreakNumberTabId.All) && <FlexLayout>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
    {(scope.tabIndex === BreakNumberTabId.Field) && <FlexLayout>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
    {(scope.tabIndex === BreakNumberTabId.Unit) && <FlexLayout>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
  </FlexLayout>

);
