import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_06
 * @category 전월대비급여현황
 */
export class LastMonthPayModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 월간급여총액
   *
   * **data type** number
   */
  tot_samt: string;

  /**
   * 공제내역총액
   *
   * **data type** number
   */
  tot_tamt : string;

  /**
   * 실수령액
   *
   * **data type** number
   */
  tot_mamt: string;

  /**
   * 월간급여총액
   *
   * **data type** number
   */
  betot_samt: string;

  /**
   * 공제내역총액
   *
   * **data type** number
   */
  betot_tamt: string;

  /**
   * 실수령액
   *
   * **data type** number
   */
  betot_mamt: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 입사일
   *
   * **data type** varchar(8)
   */
  entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  rtdate: string;

  /**
   * 직책
   *
   * **data type** varchar(40)
   */
  rspnm: string;

  /**
   * 성별
   *
   * **data type** varchar(1)
   */
  offiyn: string;

  /**
   * 지급구분
   *
   * **data type** varchar(1)
   */
  mijflag: string;

  /**
   * 지급구분
   *
   * **data type** varchar(1)
   */
  bemijflag: string;

  /**
   * 순번
   *
   * **data type** number
   */
  no: string;

  /**
   * 실수령액
   *
   * **data type** number
   */
  ttot_mamt: string;

  /**
   * 공제내역총액
   *
   * **data type** number
   */
  ttot_tamt: string;

  /**
   * 월간급여총액
   *
   * **data type** number
   */
  ttot_samt: string;

  /**
   * 담당자명
   *
   * **data type** varchar(10)
   */
  perid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.no = data.no || '';
    this.perid = data.perid || '';
    this.ttot_mamt = data.ttot_mamt || '';
    this.ttot_tamt = data.ttot_tamt || '';
    this.ttot_samt = data.ttot_samt || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.tot_samt = data.tot_samt || '';
    this.tot_tamt = data.tot_tamt || '';
    this.tot_mamt = data.tot_mamt || '';
    this.betot_samt = data.betot_samt || '';
    this.betot_tamt = data.betot_tamt || '';
    this.betot_mamt = data.betot_mamt || '';
    this.pernm = data.pernm || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.rspnm = data.rspnm || '';
    this.offiyn = data.offiyn || '';
    this.mijflag = data.mijflag || '';
    this.bemijflag = data.bemijflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
