import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { HolidayCode } from './HolidayCode';


/**
 * 화면
 * @window w_pz010
 * @category 휴일등록
 */
export const HolidayCodeTemplate: React.FC<TemplateProps<HolidayCode>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout weight={0.1}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.searchQuery}
            textAlign="center"
            format="####"
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={(value) => setData({ searchQuery: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.searchQuery}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  setData({ searchQuery: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.searchQuery}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  setData({ searchQuery: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'holiday',
            text: '휴일일자(시작)',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <DateTextBox
                ref={ref}
                format="####-##-##"
                value={x.holiday}
                textAlign="center"
                onChange={(value) => rowUpdate({ holiday: value })}
                onBlur={() => rowUpdate({ holiday2: x.holiday })}
              />
            ),
          },
          {
            id: 'holiday2',
            text: '휴일일자(끝)',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <DateTextBox
                ref={ref}
                format="####-##-##"
                value={x.holiday2}
                textAlign="center"
                onChange={(value) => rowUpdate({ holiday2: value })}
              />
            ),
          },
          {
            id: 'holiflag',
            text: '휴일구분',
            width: 15,
            render: (x, rowUpdate) => {
              const color = x.holiflag === '0' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED;
              return (
              <ComboBox
                value={x.holiflag}
                style={color}
                data={[
                  { value: '1', remark: '회사내규휴무일' },
                  { value: '0', remark: '국가지정휴무일' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => rowUpdate({ holiflag: option.value })}
              />
              );
            },
          },
          {
            id: 'remarks',
            text: '비고',
            width: 30,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.remarks}
                onChange={(value) => rowUpdate({ remarks: value })}
              />
            ),
          },
        ]}
        data={scope.state.data}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
