import * as React from 'react';
import { ChargeReceivable } from '../ChargeReceivable';
import { GLHF } from '../../../../../constants';
import {
  Button,
  FlexLayout,
  RowCover,
} from '../../../../../components';
import {
  Date6Calculator,
  Date8,
} from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';
import { Sum } from '../../../../../utils/array';

const buildflags = [
  { value: '0', remark: '양호' },
  { value: '1', remark: '폐쇄' },
];

const saflags = [
  { value: '001', remark: '계약조건' },
  { value: '002', remark: '자금부족' },
  { value: '003', remark: '법정관리(워크아웃)' },
  { value: '004', remark: '부도' },
  { value: '005', remark: '고객불만' },
  { value: '006', remark: '고객행불' },
  { value: '007', remark: '계산서오류' },
  { value: '008', remark: '기타' },
];

export const HyunDaeTabGridHeader: GLHF<any, ChargeReceivable> = (scope) => ([
  {
    id: 'cltnum',
    text: '현대번호',
    width: 3,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.cltnum}
      </FlexLayout>
    ),
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        소 계
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 2,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.actcd}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 5,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.actnm}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumActnm = Sum.groupLength(data, 'actnm', x, ['wkactcd', 'pernm']);
      return (<FlexLayout
          justify="center"
          align="center"
        >
          {sumActnm} 건
        </FlexLayout>);
    },
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.total} 건
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.pernm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 2,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 2,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'bemon03',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          {new Date6Calculator(scope.state.yearmon).add(-3).toString().slice(0, 4)}년
          {new Date6Calculator(scope.state.yearmon).add(-3).toString().slice(4, 6)}월
        </FlexLayout>
        <FlexLayout>
          (장기미수3개월이상)
        </FlexLayout>
        <FlexLayout>
          미수금
        </FlexLayout>
      </FlexLayout>
    ),
    width: 3,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.bemon03)}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBemon03 = Sum.byGroup(data, 'bemon03', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBemon03)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.bemon03_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'bemon02',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          {new Date6Calculator(scope.state.yearmon).add(-2).toString().slice(0, 4)}년
          {new Date6Calculator(scope.state.yearmon).add(-2).toString().slice(4, 6)}월
        </FlexLayout>
        <FlexLayout>
          (단기미수2개월)
        </FlexLayout>
        <FlexLayout>
          미수금
        </FlexLayout>
      </FlexLayout>
    ),
    width: 3,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.bemon02)}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBemon02 = Sum.byGroup(data, 'bemon02', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBemon02)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.bemon02_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'bemon01',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          {new Date6Calculator(scope.state.yearmon).add(-1).toString().slice(0, 4)}년
          {new Date6Calculator(scope.state.yearmon).add(-1).toString().slice(4, 6)}월
        </FlexLayout>
        <FlexLayout>
          (단기미수1개월)
        </FlexLayout>
        <FlexLayout>
          미수금
        </FlexLayout>
      </FlexLayout>
    ),
    width: 3,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.bemon01)}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBemon01 = Sum.byGroup(data, 'bemon01', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBemon01)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.bemon01_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'sum',
    text: '합계',
    width: 3,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number((parseInt(x.bemon01, 10) + parseInt(x.bemon02, 10) + parseInt(x.bemon03, 10)))}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBemon01 = Sum.byGroup(data, 'bemon01', x, ['pernm']);
      const sumBemon02 = Sum.byGroup(data, 'bemon02', x, ['pernm']);
      const sumBemon03 = Sum.byGroup(data, 'bemon03', x, ['pernm']);
      const sumAll = sumBemon01 + sumBemon02 + sumBemon03;
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAll)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.totamt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'saflag',
    text: '미수사유',
    width: 3,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {saflags.filter((y) => y.value === x.saflag)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'buildflag',
    text: '건물상태',
    width: 2,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {buildflags.filter((y) => y.value === x.buildflag)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          진행현황
        </FlexLayout>
      </FlexLayout>
    ),
    width: 8,
    render: (x) => (
      <React.Fragment>
        <FlexLayout
          align="center"
          justify="center"
        >
          <RowCover color="#FAFA80" />
          <Button
            isMini={true}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.textModal2(true)}
          >
            조회
          </Button>
        </FlexLayout>
        <FlexLayout weight={5}>
          <RowCover color="#FAFA80" />
          <FlexLayout
            justify="left"
            align="center"
            style={{ marginRight: 10 }}
          >
            {x.remark}
          </FlexLayout>
        </FlexLayout>
      </React.Fragment>
    ),
  },
  {
    id: 'hdate',
    text: '답변작성일',
    width: 3,
    render: (x) => (
      !x.hdate
        ? <FlexLayout>
          <RowCover color="#FAFA80" />
        </FlexLayout>
        : <FlexLayout
          justify="center"
          align="center"
        >
          <RowCover color="#FAFA80" />
          {Date8.withDash(x.hdate)}
        </FlexLayout>
    ),
  },
]);
