/**
 * 모델
 * @window w_tb_xclient_01
 * @category 거래처등록
 */
import { computed } from 'mobx';

export class StatusModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명칭
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 사업자등록번호
   *
   * **data type** varchar(13)
   */
  saupnum: string;

  /**
   * 사업자등록번호
   *
   * **data type** varchar(13)
   */
  saupnum2: string;

  /**
   * 대표자명
   *
   * **data type** varchar(?)
   */
  prenm: string;

  /**
   * 주민(법인)번호
   *
   * **data type** varchar(30)
   */
  prenum: string;

  /**
   * 업태
   *
   * **data type** varchar(100)
   */
  biztypenm: string;

  /**
   * 종목
   *
   * **data type** varchar(100)
   */
  bizitemnm: string;

  /**
   * 사업장우편번호
   *
   * **data type** varchar(6)
   */
  zipcd: string;

  /**
   * 담당자명
   *
   * **data type** varchar(30)
   */
  agnernm: string;

  /**
   * 구분3
   *
   * **data type** varchar(1)
   */
  upriceclafi: string;

  /**
   * 인쇄할 거래처명(전자세금계산서 공급받는자명)
   *
   * **data type** varchar(200)
   */
  prtcltnm: string;

  /**
   * 구분4
   *
   * **data type** varchar(1)
   */
  foreyn: string;

  /**
   * 구분2
   *
   * **data type** varchar(1)
   */
  corpperclafi: string;

  /**
   * 팩스
   *
   * **data type** varchar(30)
   */
  faxnum: string;

  /**
   * 전화번호
   *
   * **data type** varchar(30)
   */
  telnum: string;

  /**
   * 핸드폰번호
   *
   * **data type** varchar(30)
   */
  hptelnum: string;

  /**
   * 홈페이지
   *
   * **data type** varchar(30)
   */
  homepage: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  clttype: string;

  /**
   * 은행
   *
   * **data type** varchar(2)
   */
  bankcd: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(20)
   */
  accnum: string;

  /**
   * 예금주
   *
   * **data type** varchar(30)
   */
  deposit: string;

  /**
   * 입력일자
   *
   * **data type** varchar(8)
   */
  inputdate: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 거래처타입
   *
   * **data type** varchar(5)
   */
  grade: string;

  /**
   * 계산서메일
   *
   * **data type** varchar(100)
   */
  taxmail: string;

  /**
   * 전화번호? 팩스번호
   *
   * **data type** varchar(30)
   */
  taxtelnum: string;

  /**
   * 현장 거래처 동일함
   *
   * **data type** varchar(1)
   */
  actflag: string;

  /**
   * cms 자동이체
   *
   * **data type** varchar(1)
   */
  autoflag: string;

  /**
   * cms 자동이체일
   *
   * **data type** varchar(8)
   */
  autodate: string;

  /**
   * 종사업장우편번호
   *
   * **data type** varchar(4)
   */
  jspjangcd: string;

  /**
   * 사업장주소
   *
   * **data type** varchar(255)
   */
  cltadres: string;

  /**
   * 종사업장주소
   *
   * **data type** varchar(100)
   */
  jsaupnum: string;

  /**
   * 종사업장상세주소
   *
   * **data type** varchar(100)
   */
  jspjangnm: string;

  /**
   * 자동이체번호
   *
   * **data type** varchar(10)
   */
  autonum: string;

  /**
   * 위도
   *
   * **data type** varchar(16)
   */
  lat: string;

  /**
   * 경도
   *
   * **data type** varchar(16)
   */
  lng: string;

  /**
   * 계산서메일 체크란
   *
   * **data type** varchar(1)
   */
  taxflag: string;

  /**
   * 사업장주소2
   *
   * **data type** varchar(255)
   */
  oldcltadres: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  inperid: string;

  /**
   * 장기미수문자 전송(금지)
   *
   * **data type** varchar(1)
   */
  missms: string;

  /**
   * 장기미수문자 전송(금지)사유
   *
   * **data type** varchar(255)
   */
  missmsremark: string;

  /**
   * 현장코드
   *
   * **data type** char(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 관리대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 장기미수거래처
   *
   * **data type** varchar(1)
   */
  longmis: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.saupnum = data.saupnum || '';
    this.saupnum2 = data.saupnum2 || '';
    this.spjangcd = data.spjangcd || '';
    this.prenm = data.prenm || '';
    this.prenum = data.prenum || '';
    this.biztypenm = data.biztypenm || '';
    this.bizitemnm = data.bizitemnm || '';
    this.zipcd = data.zipcd || '';
    this.agnernm = data.agnernm || '';
    this.upriceclafi = data.upriceclafi || '';
    this.prtcltnm = data.prtcltnm || '';
    this.foreyn = data.foreyn || '';
    this.corpperclafi = data.corpperclafi || '';
    this.faxnum = data.faxnum || '';
    this.telnum = data.telnum || '';
    this.hptelnum = data.hptelnum || '';
    this.homepage = data.homepage || '';
    this.clttype = data.clttype || '';
    this.bankcd = data.bankcd || '';
    this.accnum = data.accnum || '';
    this.deposit = data.deposit || '';
    this.inputdate = data.inputdate || '';
    this.remark = data.remark || '';
    this.grade = data.grade || '';
    this.taxmail = data.taxmail || '';
    this.taxtelnum = data.taxtelnum || '';
    this.actflag = data.actflag || '';
    this.autoflag = data.autoflag || '';
    this.autodate = data.autodate || '';
    this.jspjangcd = data.jspjangcd || '';
    this.cltadres = data.cltadres || '';
    this.jsaupnum = data.jsaupnum || '';
    this.jspjangnm = data.jspjangnm || '';
    this.autonum = data.autonum || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.taxflag = data.taxflag || '';
    this.oldcltadres = data.oldcltadres || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.missms = data.missms || '';
    this.missmsremark = data.missmsremark || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.longmis = data.longmis || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
