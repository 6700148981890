import { action } from 'mobx';
import React, { RefObject } from 'react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve, TabModel } from '../../../../models';
import CollectModel from './models/CollectModel';
import { CollectTemplate } from './Collect.template';
import { Date6 } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';


// 설치구분
export const ScGubuns = [
  { key: '%', value: '전체' },
  { key: '1', value: '설치' },
  { key: '2', value: '보수' },
];

export const ScGubunsLabel = [
  { key: '1', value: '설치', color: 'var(--color-green)' },
  { key: '2', value: '보수', color: 'var(--color-blue)' },
  { key: '3', value: '종료', color: 'var(--color-red)' },
];

// 계약상태
export const Conts = [
  { key: '%', value: '전체' },
  { key: '3', value: '진행,완료' },
  { key: '0', value: '진행중' },
  { key: '1', value: '완료', color: 'var(--color-blue)' },
  { key: '2', value: '취소', color: 'var(--color-red)' },
];

// 계약구분
export const Gubuns = [
  { key: '%', value: '전체' },
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];

// 매출발생여부
export const Das = [
  { key: '%', value: '전체' },
  { key: '1', value: '발생' },
  { key: '0', value: '미발생' },
];

// 발주발생여부
export const Bals = [
  { key: '%', value: '전체' },
  { key: '1', value: '발생' },
  { key: '0', value: '미발생' },
];

// 검사일여부
export const Kcs = [
  { key: '%', value: '전체' },
  { key: '0', value: '없음' },
  { key: '1', value: '있음' },
];


interface CollectState {
  // Search
  searchQuery: string;
  stflag: string;
  stmon: string;
  endmon: string;
  gubun: string;
  contstate: string;
  contflag: string;
  okbook: string;
  compbook: string;
  kcdateflag: string;
  balcltcd: string;
  balcltcm: string;
  perid: string;
  pernm: string;
  rcvflag: string;

  // Header
  data: Array<CollectModel>;
  focusIndex: number;
  content: CollectModel;

  // Tab - da
  dataDa: Array<any>;

  // Tab - Collect
  dataCollect: Array<any>;

  // Tab - Bal
  dataBal: Array<any>;

  // Tab - Purchase
  dataPurchase: Array<any>;

  // Tab - Provision
  dataProvision: Array<any>;

  scdivided: string;
}


/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export class Collect extends PageComponent<PageProps, CollectState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  tabIndex: number = 0;

  acccds: Array<any> = [];

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // Search
      searchQuery: '',
      stflag: '%',
      stmon: '197001',
      endmon: Date6.make(),
      gubun: '%',
      contstate: '3',
      contflag: '%',
      okbook: '%',
      compbook: '%',
      kcdateflag: '%',
      balcltcd: '',
      balcltcm: '',
      perid: '',
      pernm: '',
      rcvflag: '%',

      // Header
      data: [],
      focusIndex: 0,
      content: new CollectModel(),

      // Tab - Da
      dataDa: [],

      // Tab - Collect
      dataCollect: [],

      // Tab - Bal
      dataBal: [],

      scdivided: '%',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ac001');
    if (data) {
      this.acccds = data.items;
    }

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery || '',
        stflag: this.state.stflag || '%',
        stmon: this.state.stmon || '',
        endmon: this.state.endmon || '',
        gubun: this.state.gubun || '%',
        contstate: this.state.contstate || '3',
        contflag: this.state.contflag || '%',
        okbook: this.state.okbook || '%',
        compbook: this.state.compbook || '%',
        kcdateflag: this.state.kcdateflag || '%',
        balcltcd: this.state.balcltcd || '',
        perid: this.state.perid || '',
        scdivided: this.state.scdivided || '%',
        rcvflag: this.state.rcvflag || '%',
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new CollectModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        if (this.state.data.length > 0) {
          this.onRowFocusEvent(this.state.data[0], 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('actcd', this.state.content?.actcd, type, true) || 0;
      this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery || '',
      stflag: this.state.stflag || '%',
      stmon: this.state.stmon || '',
      endmon: this.state.endmon || '',
      gubun: this.state.gubun || '%',
      contstate: this.state.contstate || '3',
      contflag: this.state.contflag || '%',
      okbook: this.state.okbook || '%',
      compbook: this.state.compbook || '%',
      kcdateflag: this.state.kcdateflag || '%',
      balcltcd: this.state.balcltcd || '',
      perid: this.state.perid || '',
      scdivided: this.state.scdivided || '%',
      rcvflag: this.state.rcvflag || '%',
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      as_nm: this.state.searchQuery || '',
      stflag: this.state.stflag || '%',
      stmon: this.state.stmon || '',
      endmon: this.state.endmon || '',
      gubun: this.state.gubun || '%',
      contstate: this.state.contstate || '3',
      contflag: this.state.contflag || '%',
      okbook: this.state.okbook || '%',
      compbook: this.state.compbook || '%',
      kcdateflag: this.state.kcdateflag || '%',
      balcltcd: this.state.balcltcd || '',
      perid: this.state.perid || '',
      scdivided: this.state.scdivided || '%',
      rcvflag: this.state.rcvflag || '%',
    });
  }

  @action
  onRowFocusEvent(item: CollectModel, index: number) {
    this.setState({
      content: item,
      focusIndex: index,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_1_RowFocuschanged`, {
        projno: item.projno,
      });

      switch (this.tabIndex) {
        case 0:
          this.setState({
            dataDa: data?.items || [],
          });
          break;

        case 1:
          this.setState({
            dataCollect: data?.items || [],
          });
          break;

        case 2:
          this.setState({
            dataBal: data?.items || [],
          });
          break;

        case 3:
          this.setState({
            dataPurchase: data?.items || [],
          });
          break;

        case 4:
          this.setState({
            dataProvision: data?.items || [],
          });
          break;
      }
    });
  }

  @action
  async headerItemChanged(item: CollectModel, key: string, value: string) {
    const { actionStore: api } = this.props;
    await api.fxExec('dw_1_itemchanged', {
      ...item,
      itemname: key,
      data: value,
      projno: item.projno,
    });

    this.onRetrieveEvent();
  }

  @action
  onTabChange(_: TabModel, index: number) {
    this.tabIndex = index;
    if (this.state.content?.projno) {
      this.onRowFocusEvent(this.state.content, this.state.focusIndex);
    }
  }

  render() {
    return <CollectTemplate
      scope={this}
    />;
  }
}
