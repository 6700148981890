import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e470
 * @category 공사계획등록
 */
export default class PlanEqupModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 이전계획일
   *
   * **data type** varchar(8)
   */
  readonly beplandate: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 처리자
   *
   * **data type** varchar(8)
   */
  readonly kcperid: string;

  /**
   * 처리자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 계획월
   *
   * **data type** varchar(8)
   */
  readonly planmon: string;

  /**
   * 계획시간
   *
   * **data type** varchar(8)
   */
  readonly plantime: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 처리일
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.beplandate = data.beplandate || '';
    this.cltcd = data.cltcd || '';
    this.custcd = data.custcd || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.gubun = data.gubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.kcperid = data.kcperid || '';
    this.kcpernm = data.kcpernm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.plandate = data.plandate || '';
    this.planmon = data.planmon || '';
    this.plantime = data.plantime || '';
    this.remark = data.remark || '';
    this.rptdate = data.rptdate || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
