import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Status } from './Status';
import { TabModel } from '../../../../models';
import { StatusTabPeridTemplate } from './tabs/StatusTabPerid.template';
import { StatusTabMonthTemplate } from './tabs/StatusTabMonth.template';
import { StatusTabDetailTemplate } from './tabs/StatusTabDetail.template';

/**
 * 화면 > 탭
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTab: Array<TabLayoutDataItem<Status>> = [
  {
    tab: new TabModel('MATS', FaCodeBranch, '담당별'),
    template: (scope) => <StatusTabPeridTemplate scope={scope} />,
  },
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '상세현황(견적별)'),
    template: (scope) => <StatusTabDetailTemplate scope={scope} />,
  },
  {
    tab: new TabModel('MONTH', FaCodeBranch, '월별건수'),
    template: (scope) => <StatusTabMonthTemplate scope={scope} />,
  },
];
