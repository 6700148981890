import { GLHF, Global } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import {
  Comps,
  Gubuns,
  KtnetStates,
  Receflags,
  Status,
} from '../Status';
import StatusModel from '../models/StatusModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 담당별 > 그리드레이아웃
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTabPeridGridHeader: GLHF<StatusModel, Status> = (scope) => ([
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    group: true,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 80,
    group: true,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'costdate',
    text: '견적일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 70,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '공사명',
    width: 250,
    sum: (x, data) => {
      const sumCostnm = Sum.groupLength(data, 'constnm', x, ['pernm']);
      return (
        <FlexLayout
          justify="end"
          align="center"
          style={{ color: 'var(--color-green)' }}
        >
          {Format.number(sumCostnm)} 건
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.constnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tab1_cnt_tot || '', true)} 건
      </FlexLayout>
    ),
  },
  {
    id: 'costamt',
    text: '견적금액',
    width: 100,
    sum: (x, data) => {
      const sumCostamt = Sum.byGroup(data, 'costamt', x, ['pernm', 'divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCostamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.costamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tab1_costamt_tot || '', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'compyn',
    text: '상태',
    width: 60,
    render: (x) => {
      let color = x.compyn === '3' && { color: 'var(--color-red)' };
      color = x.compyn === '2' && { color: 'var(--color-blue)' };
      color = x.compyn === '1' && { color: 'var(--color-gray)' };
      color = x.compyn === '5' && { color: 'var(--color-violet)' };

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={color}
        >
          {Comps.filter((y) => y.value === x.compyn)[0]?.remark}
        </FlexLayout>
      );
    },
  },
  {
    id: 'pumflag',
    text: '청구내역',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.pumflag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.pumflag === '1' ? '있음' : '없음'}
      </FlexLayout>
    ),
  },
  {
    id: 'balflag',
    text: '발주내역',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.balflag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.balflag === '1' ? '있음' : '없음'}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출금액',
    width: 100,
    sum: (x, data) => {
      const sumMisamt = Sum.byGroup(data, 'misamt', x, ['pernm', 'divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMisamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tab1_misamt_tot || '', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'recivstatus',
    text: '전자세금계산서',
    width: 100,
    render: (x) => {
      let color = x.recivstatus === 'B' && { color: 'var(--color-red)' };
      color = x.recivstatus === 'F' && { color: 'var(--color-blue)' };

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={color}
        >
          {KtnetStates.filter((y) => y.value === x.recivstatus)[0]?.remark}
        </FlexLayout>
      );
    },
  },
  {
    id: 'jirogubun',
    text: '지로여부',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.jirogubun === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.jirogubun === '1' ? '있음' : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamtyn',
    text: '수금여부',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.jirogubun === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.rcvamt === x.misamt ? '수금' : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'receflag',
    text: '확인서수령여부',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.receflag === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
      >
        {Receflags.filter((y) => y.value === x.receflag)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 's1flag',
    text: '견적담당',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s1flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks1flag',
    text: '견적고객',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.ks1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.ks1flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 's2flag',
    text: '부품담당',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s2flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks2flag',
    text: '부품고객',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.ks2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.ks2flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 's3flag',
    text: '공사담당',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s3flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s3flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'ks3flag',
    text: '공사고객',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.ks3flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.ks3flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '특기사항',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'bigo',
    text: '매출비고',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.bigo}
      </FlexLayout>
    ),
  },
]);
