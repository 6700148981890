import * as React from 'react';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox, ModalView,
  OptionBox,
  SearchLayout, SubHeader,
  TabLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Enrollment } from './Enrollment';
import { SearchDateRange } from '../../../../components/search';
import { EnrollmentMainGridHeader } from './headers/EnrollmentMainGridHeader';
import { EnrollmentTab } from './Enrollment.tab';
import { SearchBinding } from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { EnrollmentPullGridHeader } from './headers/EnrollmentPullGridHeader';

/**
 * 화면
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => {
  const data = scope.state.content;
  const setData = (d: any, callback?: () => void) => scope?.setState({
    content: new EnrollmentModel({ ...data, ...d }, data.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      {/* 검색 */}
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={500}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="고객, 현장, 프로젝트로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      {/* 헤더 */}
      <FlexLayout size={(Global.LAYOUT_LABEL_BOX_HEIGHT_1 + 2) * 6}>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={EnrollmentMainGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            data={scope.state.data}
            onRowClick={(row) => scope.onRowFocusEvent(row)}
          />
        </FlexLayout>

        <FlexLayout
          weight={2}
          isVertical={true}
          disabled={!data?.custcd && !data?.isNew}
          disabledText="신규로 추가하거나 수주를 선택해주세요"
        >
          <FlexLayout>
            <LabelBox
              title="수주일자"
              isNeed={true}
              footer={<Button isMini={true} onClick={() => scope.SS({
                isModifyDate: true,
                beorddate: scope.state.content.orddate,
                beordnum: scope.state.content.ordnum,
              })}>
                변경
              </Button>}
            >
              <DateTextBox
                format="####-##-##"
                textAlign="center"
                value={data.orddate}
                onChange={(value) => setData({ orddate: value }, () => {
                  scope.headerItemChanged('orddate', value);
                  scope.SS({ isModifyDate: false });
                })}
                readonly={!(data.isNew || scope.state.isModifyDate)}
              />

              <TextBox
                textAlign="center"
                value={data.ordnum}
                onChange={(value) => setData({ ordnum: value })}
                readonly={true}
                weight={0.5}
              />
            </LabelBox>

            <LabelBox
              title="프로젝트명"
              weight={2}
            >
              <TextBox
                textAlign="center"
                value={data.projno}
                onChange={(value) => setData({ projno: value })}
                readonly={true}
              />

              <TextBox
                weight={2}
                textAlign="left"
                value={data.projnm}
                onChange={(value) => setData({ projnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="수주종류"
            >
              <ComboBox
                textAlign="center"
                value={data.gubun}
                data={[
                  { value: '14', remark: '설치공사' },
                  { value: '15', remark: '리모델링' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox
              title="담당자"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={data.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                      divicd: item.uv_arg2,
                      divinm: item.uv_arg3,
                    }, () => scope.headerItemChanged('perid', data.perid));
                  },
                )}
              />

              <TextBox
                textAlign="center"
                value={data.pernm}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="부서명"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={data.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({ divicd: item.cd, divinm: item.cdnm });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                textAlign="center"
                value={data.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox
              title="현장명"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={data.actcd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                      cltcd: item.uv_arg1,
                      cltnm: item.uv_arg3,
                    }, () => {
                      scope.headerItemChanged('actcd', data.actcd);
                    });
                  },
                )}
              />

              <TextBox
                value={data.actnm}
                weight={3}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="고객명"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={data.cltcd}
                onChange={(value) => setData({ cltcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  {},
                  true,
                  (item) => {
                    setData({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={data.cltnm}
                weight={3}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox
              title="제목"
              weight={3}
              isNeed={true}
            >
              <TextBox
                value={data.title}
                onChange={(v) => setData({ title: v })}
              />
            </LabelBox>

            <LabelBox
              title="유무상구분"
            >
              <OptionBox
                value={data.myn}
                data={[
                  { value: '1', remark: '유상' },
                  { value: '0', remark: '무상' },
                ]}
                onChange={(v) => setData({ myn: v.value })}
              />
            </LabelBox>

            <LabelBox
              title="공사시작일"
            >
              <DateTextBox
                value={data.stdate}
                format="####-##-##"
                textAlign="center"
                onChange={(v) => setData({ stdate: v })}
              />
            </LabelBox>

            <LabelBox
              title="공사만료일"
            >
              <DateTextBox
                value={data.enddate}
                format="####-##-##"
                textAlign="center"
                onChange={(v) => setData({ enddate: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox
              title="부가세구분"
            >
              <OptionBox
                value={data.taxcls}
                data={[
                  { value: '1', remark: '포함' },
                  { value: '0', remark: '별도' },
                ]}
                onChange={(v) => setData({ taxcls: v.value },
                  () => scope.headerItemChanged('taxcls', v.value.toString()))}
              />
            </LabelBox>

            <LabelBox
              title="공급가액"
            >
              <FormatNumberTextBox
                textAlign="right"
                value={data.samt}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="부가세액"
            >
              <FormatNumberTextBox
                textAlign="right"
                value={data.tamt}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="합계금액"
            >
              <FormatNumberTextBox
                textAlign="right"
                value={data.mamt}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="할인적용"
              footer={<span>할인율</span>}
            >
              <ComboBox
                value={data.saleflag}
                textAlign="center"
                data={[
                  { value: '0', remark: '무' },
                  { value: '1', remark: '유' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ saleflag: option.value })}
              />

              <FormatTextBox
                format="##%"
                textAlign="right"
                value={data.saleyul}
                onChange={(v) => setData({ saleyul: v }, () => scope.headerItemChanged('saleyul', v))}
                readonly={data.saleflag === '0'}
              />
            </LabelBox>

            <LabelBox
              title="할인금액"
            >
              <FormatNumberTextBox
                textAlign="right"
                value={data.saleamt}
                readonly={data.saleflag === '0'}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox
              title="특이사항"
              weight={4}
            >
              <TextBox
                value={data.worknm}
                onChange={(v) => setData({ worknm: v })}
              />
            </LabelBox>

            <LabelBox
              title="미수여부"
            >
              <OptionBox
                value={data.misflag}
                data={[
                  { value: '1', remark: '유' },
                  { value: '0', remark: '무' },
                ]}
                onChange={(v) => setData({ misflag: v.value })}
              />
            </LabelBox>

            <LabelBox
              title="하자보수기간"
            >
              <FormatTextBox
                format="##"
                textAlign="center"
                value={data.givedd}
                onChange={(v) => setData({ givedd: v })}
              />

              <ComboBox
                value={data.giveflag}
                data={[
                  { value: '3', remark: '일' },
                  { value: '2', remark: '월' },
                  { value: '1', remark: '년' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ giveflag: option.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox
              title="비고"
              weight={5}
            >
              <TextBox
                value={data.remark}
                onChange={(v) => setData({ remark: v })}
              />
            </LabelBox>

            <LabelBox
              title="수주통보"
              footer={<Button isMini={true} onClick={() => scope.sendNotice()}>보내기</Button>}
            >
              <OptionBox
                value={data.notice}
                data={[
                  { value: '1', remark: '통보' },
                  { value: '0', remark: '미통보' },
                ]}
                onChange={(v) => setData({ notice: v.value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      {/* 상세 - 탭 */}
      <FlexLayout
        isVertical={true}
        weight={5}
        disabled={!data?.custcd && !data?.isNew}
        disabledText="신규로 추가하거나 수주를 선택해주세요"
      >
        <FlexLayout>
          <TabLayout
            scope={scope}
            tabs={EnrollmentTab}
            onChange={(_, index) => scope.onTabChange(index)}
            footer={
              <React.Fragment>
                <Button
                  style={{
                    height: 25,
                    width: 80,
                    marginTop: 3,
                  }}
                  onClick={() => scope.tabButtonAdd()}
                >
                  추가
                </Button>

                <Button
                  style={{
                    height: 25,
                    width: 80,
                    marginTop: 3,
                  }}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.tabButtonRemove()}
                >
                  삭제
                </Button>
              </React.Fragment>
            }
          />
        </FlexLayout>
      </FlexLayout>

      {/* 견적 불러오기 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisiblePull}
        onClose={() => setData({ isVisiblePull: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SubHeader>
            <div>
              <span>견적 불러오기</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.donePull()}>확인</Button>
              <Button onClick={() => setData({ isVisiblePull: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>

          <GridLayout
            header={EnrollmentPullGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinityPull}
            data={scope.state.dataPull}
            onRowClick={(_, index) => scope.onPullRowFocusEvent(index)}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
