import * as React from 'react';
import {
  IoIosMail, MdKeyboardArrowLeft, MdKeyboardArrowRight, RiFolder2Line,
} from 'react-icons/all';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { AttachmentBox } from '../../../../components/forms/AttachmentBox';
import { Button, BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { ModalView } from '../../../../components/layout/ModalView';
import { FormatTextBox } from '../../../../components/forms/FormatTextBox';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Global, TemplateProps } from '../../../../constants';
import { Education } from './Education';
import { Date8, Today } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { ComboBox, ComboBoxModel } from '../../../../components/forms/ComboBox';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { SubHeader } from '../../../../components/layout/SubHeader';

/*
* 모델
 * @window w_tb_pb404
 * @category 교육보고서
*/

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

const gubuns = [
  { value: '1', remark: '자체교육' },
  { value: '2', remark: '외부교육' },
];

export const EducationTemplate: React.FC<TemplateProps<Education>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={210}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={400}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder={scope.state.data?.new === '0' ? '제목, 교육내용으로 검색하세요' : ''}
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span style={{ color: 'var(--color-red)' }}>담당자</span>
            <TextBox
              textAlign="center"
              style={{ marginLeft: 10 }}
              value={scope.state.perid}
              onChange={(value) => scope.setState({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.pernm}
              readonly={true}
              onChange={(value) => scope.setState({ pernm: value })}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onReport()}>
            <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'edudate',
                text: '일자',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.edudate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'edunum',
                text: '번호',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.edunum}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '교육자',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '제목',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 4,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 27 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={25}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'attcnt',
                text: '첨부',
                width: 10,
                render: (x) => (
                  <TextBox
                    textAlign='center'
                    value={`${x.attcnt} 건`}
                  />
                ),
              },
            ]}
            data={scope.state.educationList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          weight={1.5}
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.edudate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ edudate: value })}
                // onBlur={() => scope.itemChanged(BillItemChangeTypes.PUMDATA)}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.edunum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format={model?.appnum && '##-######-###-####'}
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="교육분류"
            >
              <ComboBox
                value={model?.gubun}
                textAlign="center"
                data={gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={
                  (option) => setData({ gubun: option.value })
                }
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="제목"
              weight={3}
            >
              <TextBox
                textAlign="left"
                value={model?.title}
                onChange={(value) => setData({ title: value })}
              />
            </LabelBox>
            <LabelBox
              title="교육기간"
              weight={2}
            >
              <DateTextBox
                value={model?.stdate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ stdate: value })}
              />
              <span style={{
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}> ~
              </span>
              <DateTextBox
                value={model?.enddate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ enddate: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="교육자"
              weight={1}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              weight={4}
              title="교육받은자"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openWorkerModal()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.edupernm}
                onChange={(value) => setData({ edupernm: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={300}>
            <LabelBox title="교육내용" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: (model?.attcnt) === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={(model?.attcnt) === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data?.appnum}
            appnm={'교육보고서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });
            }}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isEduperModalVisible}
        onClose={() => scope.closeEduperModal()}
        width={500}
        height={400}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>교육받는자</LayoutTitle>

          <FlexLayout weight={5}>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'perid',
                  text: '교육받는자',
                  width: 75,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ perid: value })}
                      onEnter={() => scope.onRetrieveEvent()}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                      isDisabledTrackingStateChange={true}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '교육받는자명',
                  width: 250,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ pernm: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.popupList}
              infinityHandler={scope.infinity2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onPopupRowFocusEvent(item)}
            />
          </FlexLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.modalNew()}>추가</Button>
            <Button onClick={() => scope.modalDelete()}>삭제</Button>
            <Button onClick={() => scope.modalSave()}>저장</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  );
};
