import * as React from 'react';
import { inject } from 'mobx-react';
import { ExternalPageProps } from '../../../../constants';

/**
 * 컨트롤러
 * @window w_tb_xgw010_01
 * @category 공지사항
 */
@inject('toolStore')
export class Notice extends React.Component<ExternalPageProps> {
  componentDidMount(): void {
    window.open('https://api.elmansoft.com/board/index.php?20180831092701#/splash');
    this.props.toolStore.onCloseHandler();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <React.Fragment />
    );
  }
}
