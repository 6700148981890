

export class OldEqupmentModalModel {
  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 계획일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 설치년도
   *
   * **data type** varchar(8)
   */
  readonly yyyymm2: string;

  /**
   * 년수2
   *
   * **data type** varchar(8)
   */
  readonly gigan2: string;

  /**
   * 정밀검사
   *
   * **data type** varchar(8)
   */
  readonly gubun002: string;

  /**
   * 최초설치년도
   *
   * **data type** varchar(8)
   */
  readonly yyyymm: string;

  /**
   * 년수
   *
   * **data type** varchar(8)
   */
  readonly gigan: string;

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.plandate = data.plandate || '';
    this.yyyymm2 = data.yyyymm2 || '';
    this.gigan2 = data.gigan2 || '';
    this.gubun002 = data.gubun002 || '';
    this.yyyymm = data.yyyymm || '';
    this.gigan = data.gigan || '';
  }
}
