import * as React from 'react';
import { Global, TemplateProps } from '../../../../constants';
import { Unitinspect } from './Unitinspect';
import {
  Button,
  CircleBadge,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  ModalView,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e075w_01
 * @category 호기별검사현황[최종검사]
 */

export const UnitinspectTemplate: React.FC<TemplateProps<Unitinspect>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      style={{ width: 1180 }}
    >
      <FlexLayout size={540}>
        <span style={{ marginLeft: 44 }}>현장명</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={240} style={{ marginLeft: 40 }}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          value={scope.state.divicd}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={1.5}
          value={scope.state.divinm}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={240} style={{ marginLeft: 30 }}>
        <span style={{ color: 'var(--color-red)' }}>사용자</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={1.5}
          value={scope.state.pernm}
          onChange={(value) => scope.setState({ pernm: value })}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>
    <SearchLayout
      isSub={true}
      style={{ width: 1180 }}
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={240}>
        <span>운행종료월</span>
        <DateTextBox
          value={scope.state.endmon}
          textAlign="center"
          format="####-##"
          onChange={(value) => scope.setState({ endmon: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={220} style={{ marginLeft: 80 }}>
        <span>결과</span>
        <ComboBox
          value={scope.state.result}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '합격' },
            { value: '2', remark: '불합격' },
            { value: '3', remark: '조건부' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ result: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>
      <FlexLayout size={270} style={{ marginLeft: 12 }}>
        <span>계약구분</span>
        <ComboBox
          value={scope.state.gubun}
          textAlign="center"
          // style={}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '계약중' },
            { value: '2', remark: '해지' },
            { value: '3', remark: '미계약' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ gubun: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>
      <FlexLayout size={254} style={{ marginLeft: 14 }}>
        <span>진행상태</span>
        <ComboBox
          value={scope.state.state}
          textAlign="center"
          // style={}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '만료' },
            { value: '1', remark: '계획' },
            { value: '2', remark: '완료' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ state: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>
    </SearchLayout>
    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'divinm',
          text: '부서명',
          width: 8,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.divinm}
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              총계
            </FlexLayout>
          ),
        },
        {
          id: 'pernm',
          text: '담당자',
          width: 8,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.pernm}
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.cnt_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'actnm',
          text: '현장명',
          width: 32,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.actnm}
            </FlexLayout>
          ),
        },
        {
          id: 'equpnm',
          text: '호기명',
          width: 14,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.equpnm}
            </FlexLayout>
          ),
        },
        {
          id: 'elno',
          text: '승강기번호',
          width: 14,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
            </FlexLayout>
          ),
        },
        {
          id: 'mdnm',
          text: '모델명',
          width: 16,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.mdnm}
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
              style={{ color: 'var(--color-blue)' }}
            >
              처리 {scope.state.compcnt_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'numbergugan',
          text: '운행구간',
          width: 14,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.numbergugan}
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
              style={{ color: 'var(--color-blue)' }}
            >
              합격 {scope.state.result01_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'state',
          text: '진행',
          width: 7,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{
                color: x.state === '2' ? 'var(--color-blue)'
                  : x.state === '1' ? 'var(--color-green)' : 'var(--color-black)',
              }}
            >
              {x.state === '0' ? '만료' : x.state === '1' ? '계획' : x.state === '2' ? '검사' : ''}
            </FlexLayout>
          ),
        },
        {
          id: 'resultnm',
          text: '결과',
          width: 12,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{
                color: x.resultnm === '합격' || x.resultnm === '조건부합격' ? 'var(--color-blue)'
                  : x.resultnm === '불합격' ? 'var(--color-red)' : 'var(--color-black)',
              }}
            >
              {x.resultnm}
              <CircleBadge>
                {Number(x.failcnt) > 0 && <span
                  style={{ backgroundColor: 'var(--color-red)' }}
                >
                  {Number(x.failcnt) > 0 ? x.failcnt : ''}
                </span>}
              </CircleBadge>
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout
              justify="left"
              align="center"
              style={{ color: 'var(--color-green)' }}
            >
              조건부 {scope.state.result03_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'plandate',
          text: '검사일자',
          width: 14,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.plandate)}
            </FlexLayout>
          ),
          trail: () => (
            <FlexLayout
              justify="left"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              불합격 {scope.state.result02_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'stdate',
          text: '운행시작일',
          width: 14,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.stdate)}
            </FlexLayout>
          ),
        },
        {
          id: 'enddate',
          text: '운행종료일',
          width: 14,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.enddate)}
            </FlexLayout>
          ),
        },
        {
          id: 'kcpernm',
          text: '검사기관',
          width: 28,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.kcpernm}
            </FlexLayout>
          ),
        },
      ]}
      data={scope.state.UnitinspectList}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      onRowDoubleClick={() => scope.onModalopenEvent()}
    />

    <ModalView
      width={850}
      isVisible={scope.state.isModal}
      onClose={() => scope.setState({ isModal: false })}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <SubHeader>
          <div>
            <span>상세현황</span>
          </div>
          <FlexLayout></FlexLayout>
        </SubHeader>
        <FlexLayout size={10} style={{ marginBottom: 16 }}>
          <FlexLayout style={{ fontSize: 15 }}>
            *현장명 : {scope.state.data?.actnm}
          </FlexLayout>

          <FlexLayout style={{ fontSize: 15 }}>
            *호기명 : {scope.state.data?.equpnm} [{scope.state.data?.elno}]
          </FlexLayout>
        </FlexLayout>

        <FlexLayout size={10}>
          <FlexLayout style={{ fontSize: 15 }}>
            *검사기관 : {scope.state.data?.kcpernm}
          </FlexLayout>

          <FlexLayout style={{ fontSize: 15 }}>
            *검사결과 : {scope.state.data?.result}
          </FlexLayout>
        </FlexLayout>

        <GridLayout
          header={[
            {
              id: 'article',
              text: '검사항목',
              width: 10,
            },
            {
              id: 'title',
              text: '검사항목명',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="start"
                  style={{ padding: 8 }}
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'descript',
              text: '불합내역',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="start"
                  style={{ padding: 8 }}
                >
                  {x.descript}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '검사자 불합의견',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="start"
                  align="start"
                  style={{ color: 'var(--color-red)', padding: 8 }}
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={100}
          data={scope.state.popupData}
        />
        <SubHeader>
          <FlexLayout
            justify="end"
          >
            <Button
              onClick={() => {
                scope.setState({
                  isModal: false,
                  popupData: [],
                });
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
