import { computed } from 'mobx';

/**
 * 모델
 * @window w_pb209
 * @category 연차생성
 */
export class YearModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 모름
   *
   * **data type** varchar(4)
   */
  hyear: string;

  /**
   * 모름
   *
   * **data type** varchar(30)
   */
  hseq: string;

  /**
   * 사원코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사원코드 (p빠진거)
   *
   * **data type** varchar(10)
   */
  nperid: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  hfdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  htdate: string;

  /**
   * 연차사용일수
   *
   * **data type** decimal(3,1)
   */
  daynum: string;

  /**
   * 이월일수
   *
   * **data type** decimal(3,1)
   */
  iwolnum: string;

  /**
   * 연차생성
   *
   * **data type** decimal(3,1)
   */
  holinum: string;

  /**
   * 연차잔여일수
   *
   * **data type** decimal(3,1)
   */
  restnum: string;

  /**
   * 모름
   *
   * **data type** char(1)
   */
  hflag: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 대체근무일수
   *
   * **data type** decimal(3, 1)
   */
  worknum: string;

  /**
   * 대체사용일수
   *
   * **data type** decimal(3, 1)
   */
  workdaynum: string;

  /**
   * 대체잔여일수
   *
   * **data type** decimal(3, 1)
   */
  workrestnum: string;

  /**
   * 기본연차
   *
   * **data type** decimal(3, 1)
   */
  holinum_basic: string;

  /**
   * 사원명
   *
   * **data type** ?
   */
  pernm: string;

  /**
   * 직위
   *
   * **data type** ?
   */
  rspnm: string;

  /**
   * 부서명
   *
   * **data type** ?
   */
  divinm: string;

  /**
   * 입사일
   *
   * **data type** ?
   */
  entdate: string

  /**
   * 연차급여
   *
   * **data type** ?
   */
  yearly_flag: string

  /**
   * 이월확정일수
   *
   * **data type** decimal(3, 1)
   */
  iwolnum_comp: string

  /**
   * 연차+대체일수
   *
   * **data type** decimal(3, 1)
   */
  totnum: string

  /**
   * 총사용일수
   *
   * **data type** decimal(3, 1)
   */
  totdaynum: string

  /**
   * 총잔여일수
   *
   * **data type** decimal(3, 1)
   */
  totrestnum: string

  /**
   * ?
   *
   * **data type** decimal(3, 1)
   */
  yearterm: string

  /**
   * data new 를 위함
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.nperid = data.nperid || '';
    this.hyear = data.hyear || '';
    this.hseq = data.hseq || '';
    this.hfdate = data.hfdate || '';
    this.htdate = data.htdate || '';
    this.daynum = data.daynum || '';
    this.iwolnum = data.iwolnum || '';
    this.holinum = data.holinum || '';
    this.restnum = data.restnum || '';
    this.hflag = data.hflag || '';
    this.yearly_flag = data.yearly_flag || '';
    this.remark = data.remark || '';
    this.worknum = data.worknum || '';
    this.workdaynum = data.workdaynum || '';
    this.workrestnum = data.workrestnum || '';
    this.holinum_basic = data.holinum_basic || '';
    this.iwolnum_comp = data.iwolnum_comp || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.entdate = data.entdate || '';
    this.totnum = data.totnum || '';
    this.totdaynum = data.totdaynum || '';
    this.totrestnum = data.totrestnum || '';
    this.yearterm = data.yearterm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
