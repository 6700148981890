import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  GridLayout,
  IFrame,
  ModalView,
  SearchLayout,
  SubHeader,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Status } from './Status';
import { StatusGridHeader } from './headers/StatusGridHeader';
import { StatusDetailGridHeader } from './headers/StatusDetailGridHeader';

/**
 * 화면
 * @window w_tb_e101w_05
 * @category 월별 관리대수변동내역
 */
export const StatusTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => setData({ year: value })}
            onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  setData({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  setData({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout
          size={300}
          align="center"
          justify="start"
          style={{
            paddingLeft: 12,
            fontSize: 13,
          }}
        >
          {scope.state.bemon12}
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={StatusGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
          />
        </FlexLayout>

        <FlexLayout>
          <IFrame src={scope.state.graph} />
        </FlexLayout>
      </FlexLayout>


      {/* 상세 */}
      <ModalView
        isVisible={scope.state.isVisibleModal}
        width={1600}
        height={1000}
        onClose={() => scope.SS({ isVisibleModal: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout>
                <FlexLayout isVertical={true}>
                  <SubHeader>
                    <span style={{ color: 'var(--color-green)' }}>* 만료</span>
                  </SubHeader>

                  <GridLayout
                    header={StatusDetailGridHeader(scope, 0)}
                    data={scope.state.dataModalExpire}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  />
                </FlexLayout>

                <FlexLayout isVertical={true}>
                  <SubHeader>
                    <span style={{ color: 'var(--color-violet)' }}>* 무상</span>
                  </SubHeader>

                  <GridLayout
                    header={StatusDetailGridHeader(scope, 1)}
                    data={scope.state.dataModalFree}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  />
                </FlexLayout>
              </FlexLayout>

              <FlexLayout>
                <FlexLayout isVertical={true}>
                  <SubHeader>
                    <span style={{ color: 'var(--color-blue)' }}>* 신규</span>
                  </SubHeader>

                  <GridLayout
                    header={StatusDetailGridHeader(scope, 2)}
                    data={scope.state.dataModalNew}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  />
                </FlexLayout>

                <FlexLayout isVertical={true}>
                  <SubHeader>
                    <span style={{ color: 'var(--color-red)' }}>* 해지</span>
                  </SubHeader>

                  <GridLayout
                    header={StatusDetailGridHeader(scope, 3)}
                    data={scope.state.dataModalCancel}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            <Button
              isFull={true}
              onClick={() => scope.SS({ isVisibleModal: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
