import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType, PageProps, PageToolEvents, RetrieveFocusType,
} from '../../../../constants';
import { InspectionNoticeTemplate } from './InspectionNotice.template';
import { InspectionNoticeModel } from './InspectionNotice.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import Confirm from '../../../../utils/confirm/Confirm';
import { Today } from '../../../../utils/time';

interface InspectionNoticeState {
  yearmon: string;
  searchQuery: string;
  prtchk?: string;
  emailchk?: string;

  data: Array<InspectionNoticeModel>;
  focused?: InspectionNoticeModel;
  pushTotalCheck: boolean;
  pushTotalCheck2: boolean;

  // 모달
  modalState: boolean;
  modalData: Array<InspectionNoticeModel>;
  modalFocused?: InspectionNoticeModel;

  // trail
  total: string;
  notemailcnt_tot: string;
  emailcnt_tot: string;
  notprtcnt_tot: string;
  prtcnt_tot: string;
  modalTotal: string;
}

/**
 * 컨트롤러
 * @window w_tb_e035w_03
 * @category 점검시행안내문
 */
export class InspectionNotice extends PageComponent<PageProps, InspectionNoticeState>
  implements PageToolEvents {
  updatedRows?: Array<InspectionNoticeModel>;

  modalUpdatedRows?: Array<InspectionNoticeModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  modalTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      searchQuery: '',
      prtchk: '%',
      emailchk: '%',
      data: [],
      modalData: [],

      // trail
      total: '0',
      notemailcnt_tot: '0',
      emailcnt_tot: '0',
      notprtcnt_tot: '0',
      prtcnt_tot: '0',
      modalTotal: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        as_nm: this.state.searchQuery,
        prtchk: this.state.prtchk,
        emailchk: this.state.emailchk,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new InspectionNoticeModel(x)),
          ],
        });
        await this.table.current?.update(false);
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
          pushTotalCheck: false,
          pushTotalCheck2: false,
        });
        await this.infinity?.retrieveAll();
        await this.table.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
      pushTotalCheck: false,
      pushTotalCheck2: false,
    });
    const index = await this.infinity?.retrieveTo(['actcd', 'equpnm'],
      [this.state.focused?.actcd, this.state.focused?.equpnm], type) || 0;

    const dataDate = await this.infinity?.box;

    await this.SS({
      total: dataDate?.total || '0',
      notemailcnt_tot: dataDate?.notemailcnt_tot || '0',
      emailcnt_tot: dataDate?.emailcnt_tot || '0',
      notprtcnt_tot: dataDate?.notprtcnt_tot || '0',
      prtcnt_tot: dataDate?.prtcnt_tot || '0',
    });

    // if (!this.state.data.length) {
    //   await ConfirmWarning.show('경고', '먼저 보수현장등록에서 공문을 전송할 현장을 점검안내공문 채크해주세요!');
    //   return;
    // }

    await this.table.current?.update(true);
    if (this.state.data && this.state.data.length > index) {
      await this.table.current?.setFocus(index);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    let chkData: any[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.prtchk === '1') {
        chkData.push({
          prtchk: x.prtchk,
          actcd: x.actcd,
        });
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.printWithElmanManager({
        stmon: this.state.yearmon,
        as_nm: this.state.searchQuery,
        prtchk: this.state.prtchk,
        emailchk: this.state.emailchk,
        items: chkData,
      });
      chkData = [];
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      as_nm: this.state.searchQuery,
      prtchk: this.state.prtchk,
      emailchk: this.state.emailchk,
    });
  }

  @action
  onRowFocusEvent(item: InspectionNoticeModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<InspectionNoticeModel>, updatedRows: Array<InspectionNoticeModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      data: this.state.data?.map((x) => new InspectionNoticeModel({
        ...x,
        prtchk: checked === true ? '1' : '0',
      })),
    });
    this.table.current?.update(true);
  }

  @action
  async updateCheckAllToggle2(checked: boolean) {
    this.setState({
      pushTotalCheck2: checked,
      data: this.state.data?.map((x) => new InspectionNoticeModel({
        ...x,
        emailchk: checked === true ? '1' : '0',
      })),
    });
    this.table.current?.update(true);
  }

  @action
  async onEmailBtn() {
    const chkData: any[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.emailchk === '1') {
        chkData.push({
          emailchk: x.emailchk,
          actcd: x.actcd,
        });
      }
    });

    if (!chkData.length) {
      await ConfirmWarning.show('경고', '선택을 하나 이상 하세요');
      return;
    }

    const warning = await Confirm.show('확인', '선택한 내역을 메일전송 하시겠습니까? 메일주소가 없는 것은 전송되지 않습니다.', ConfirmType.QUESTION);
    if (warning === false) return;

    const { actionStore: api } = this.props;
    api.fxEmail(
      '',
      '',
      '',
      '',
      'wb_email',
      '',
      {
        stmon: this.state.yearmon,
        as_nm: this.state.searchQuery,
        prtchk: this.state.prtchk,
        emailchk: this.state.emailchk,
        items: chkData,
      },
    );
  }

  @action
  async onitemChange(datas: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_itemchanged',
      {
        new: '0',
        itemname: 'email',
        data: datas,
        actcd: datas,
      });

    data && this.setState({
      data: [...this.state.data, new InspectionNoticeModel(data, true)],
    });
  }

  @action
  async modalOpen(isOpen: boolean) {
    this.setState({ modalState: isOpen });

    this.modalRetrive();
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_e035w_03',
        stmon: this.state.yearmon,
        actcd: this.state.focused?.actcd,
      });
    this.setState({
      modalData: data?.items || '',
      modalTotal: data?.total || '',
    }, async () => {
      await this.modalTable.current?.update(true);
    });
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;

    const checkData: any = [];
    // @ts-ignore
    this.modalUpdatedRows.forEach((x: any) => {
      checkData.push({
        new: x.new,
        actcd: x.actcd,
        actnm: x.actnm,
        equpcd: x.equpcd,
        equpnm: x.equpnm,
        plandate: x.plandate,
        plantime: x.plantime,
        plantime2: x.plantime2,
      });
    });

    if (await api.save({
      sub: 'w_popup_e035w_03',
      stmon: this.state.yearmon,
      items: checkData,
    }, false)) {
      this.modalRetrive();
    }
  }

  @action
  onModalRowFocusEvent(item: InspectionNoticeModel) {
    this.setState({ modalFocused: item });
  }

  @action
  onModalUpdatedRows(rows: Array<InspectionNoticeModel>, updatedRows: Array<InspectionNoticeModel>) {
    this.modalUpdatedRows = updatedRows;
    this.setState({ modalData: rows });
  }

  render() {
    return (
      <InspectionNoticeTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
