import { Date8 } from '../time';

export interface CalendarItem {
  year: number;
  month?: number;
  date?: number;
  day?: number;
  data: string;
  other?: boolean;
}

export default class Calendar {
  static years(value: string): Array<CalendarItem> {
    if (value.length !== 4) return [];

    const year = parseInt(value, 10);
    const lstYear: Array<CalendarItem> = [];

    for (let i = year - 9; i < year + 7; i += 1) {
      lstYear.push({
        year: i,
        data: `${i}`,
      });
    }

    return lstYear;
  }

  static months(value: string): Array<CalendarItem> {
    if (value.length !== 4) return [];

    const year = parseInt(value, 10);
    const lstMonth: Array<CalendarItem> = [];

    for (let i = 1; i < 13; i += 1) {
      lstMonth.push({
        year,
        month: i,
        data: `${value}${i < 10 ? `${0}${i}` : i}`,
      });
    }

    return lstMonth;
  }

  static dates(value: string): Array<CalendarItem> {
    if (value.length !== 6) return [];

    const lstDate: Array<CalendarItem> = [];
    const date = new Date(`${value.substr(0, 4)}-${value.substr(4, 2)}-01`);
    const last = new Date(
      parseInt(`${value.substr(0, 4)}`, 10),
      parseInt(`${value.substr(4, 2)}`, 10),
      0,
    );
    date.setDate(date.getDate() - (date.getDay() % 7));
    last.setDate(last.getDate() + (7 - (last.getDay() % 7)));
    const diffTime = Math.abs(last.getTime() - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    for (let i = 0; i < diffDays; i += 1) {
      const data = Date8.make(date);
      lstDate.push({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate(),
        day: date.getDay(),
        data,
        other: data.substr(0, 6) !== value,
      });
      date.setDate(date.getDate() + 1);
    }

    return lstDate;
  }
}
