import * as React from 'react';
import {
  IoIosPin,
  IoMdCamera,
} from 'react-icons/all';
import {
  FiTrash,
  FiUpload,
} from 'react-icons/fi';
import {
  AddressButton,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DefaultLayout,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  ImageView,
  LabelBox,
  LayoutTitle,
  LoaderButton,
  OptionBox,
  SearchLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Spjang,
  SpjangImages,
} from './Spjang';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_xa012
 * @category 사업자정보등록
 */
export const SpjangTemplate: React.FC<TemplateProps<Spjang>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout
      isVertical={true}
      isScroll={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout weight={0.25}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'spjangcd',
              text: '사업장코드',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.spjangcd}
                </FlexLayout>
              ),
            },
            {
              id: 'spjangnm',
              text: '사업장명',
              width: 70,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.spjangnm}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.spjangList}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
        <FlexLayout
          isVertical={true}
          weight={3}>
          <LayoutTitle>기본 정보</LayoutTitle>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="사업장코드"
              isNeed={true}
            >
              <TextBox
                textAlign="center"
                value={model.spjangcd}
                onChange={(value) => setData({ spjangcd: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="사업장명"
              weight={2}
            >
              <TextBox
                value={model.spjangnm}
                onChange={(value) => setData({ spjangnm: value })}
              />
            </LabelBox>
            <LabelBox title="인쇄할 사업장명">
              <TextBox
                value={model.Pspjangnm}
                onChange={(value) => setData({ Pspjangnm: value })}
              />
            </LabelBox>
            <LabelBox title="단축사업자명">
              <TextBox
                value={model.compnm}
                onChange={(value) => setData({ compnm: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="대표자명">
              <TextBox
                textAlign="center"
                value={model.prenm}
                onChange={(value) => setData({ prenm: value })}
              />
            </LabelBox>
            <LabelBox
              title="사업자등록번호"
              isNeed={true}
            >
              <FormatTextBox
                format="###-##-#####"
                textAlign="center"
                value={model.saupnum}
                onChange={(value) => setData({ saupnum: value })}
              />
            </LabelBox>
            <LabelBox title="법인(주민)번호">
              <FormatTextBox
                format="######-#######"
                textAlign="center"
                value={model.corpnum}
                onChange={(value) => setData({ corpnum: value })}
              />
            </LabelBox>
            <LabelBox
              title="업태"
              isNeed={true}
            >
              <TextBox
                textAlign="center"
                value={model.biztype}
                onChange={(value) => setData({ biztype: value })}
              />
            </LabelBox>
            <LabelBox
              title="종목"
              isNeed={true}
            >
              <TextBox
                textAlign="center"
                value={model.item}
                onChange={(value) => setData({ item: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="개업일자"
            >
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={model.openymd}
                onChange={(value) => setData({ openymd: value })}
              />
            </LabelBox>
            <LabelBox title="메일주소(세금계산서)">
              <TextBox
                value={model.emailadres}
                onChange={(value) => setData({ emailadres: value })}
              />
            </LabelBox>
            <LabelBox title="메일주소(담당자)">
              <TextBox
                textAlign="center" // 컬럼명 확인필요
                value={model.agnertel1}
                onChange={(value) => setData({ agnertel1: value })}
              />
            </LabelBox>
            <LabelBox
              title="전화번호"
              isNeed={true}
            >
              <TelnumTextBox
                value={model.tel1}
                onChange={(value) => setData({ tel1: value })}
              />
            </LabelBox>
            <LabelBox title="팩스">
              <TelnumTextBox
                value={model.tel3}
                onChange={(value) => setData({ tel3: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="세금계산서소재지"
              style={{ color: 'var(--color-red)' }}
              isNeed={true}
              weight={4}
              footer={
                <FlexLayout>
                  <AddressButton
                    publicStore={scope.props.publicStore}
                    modalStore={scope.props.modalStore}
                    onChange={(address) => setData({
                      zipcd: address.zonecode,
                      adresa: address.address,
                      adresb: address.jibunAddress,
                      lat: address.y,
                      lng: address.x,
                    })}
                  >
                    주소검색
                  </AddressButton>
                  <DefaultLayout size={175}>
                    <TextBox
                      value={`위도: ${model?.lat}`}
                      readonly={true}
                      transparent={true}
                      size={150}
                    />
                  </DefaultLayout>
                  <DefaultLayout size={235}>
                    <TextBox
                      value={`경도: ${model?.lng}`}
                      readonly={true}
                      transparent={true}
                      size={150}
                    />
                  </DefaultLayout>
                </FlexLayout>
              }>
              <TextBox
                value={model.zipcd}
                onChange={(value) => setData({ zipcd: value })}
                readonly={true}
              />
              <TextBox
                value={model.adresa}
                onChange={(value) => setData({ adresa: value })}
                readonly={true}
                weight={2}
              />
              <TextBox
                value={model.adresb}
                onChange={(value) => setData({ adresb: value })}
                weight={2}
              />
            </LabelBox>
            <LabelBox
              title="출근사용[기준시간]"
              footer={
                <CheckBox
                  value={model.Commute === '1'}
                  onChange={(value) => setData({ Commute: value ? '1' : '0' })} />
              }>
              <FormatTextBox
                textAlign="center"
                format="##:##"
                value={model.st_worktime}
                onChange={(value) => setData({ st_worktime: value })}
                readonly={model.Commute !== '1'}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1} >
            <LabelBox
              title="사업장소재지"
              style={{ color: 'var(--color-red)' }}
              isNeed={true}
              weight={4}
              footer={
                <FlexLayout>
                  <AddressButton
                    publicStore={scope.props.publicStore}
                    modalStore={scope.props.modalStore}
                    onChange={(address) => setData({
                      zipcd2: address.zonecode,
                      adres2a: address.address,
                      adres2b: address.jibunAddress,
                      lat2: address.y,
                      lng2: address.x,
                    })}
                  >
                    주소검색
                  </AddressButton>
                  <Button
                    onClick={() => setData({
                      zipcd2: model.zipcd,
                      adres2a: model.adresa,
                      adres2b: model.adresb,
                      lat2: model.lat2,
                      lng2: model.lng2,
                    })}
                  >
                    상동
                  </Button>

                  <DefaultLayout size={175}>
                    <TextBox
                      value={`위도: ${model?.lat2}`}
                      readonly={true}
                      transparent={true}
                      size={150}
                    />
                  </DefaultLayout>
                  <DefaultLayout size={175}>
                    <TextBox
                      value={`경도: ${model?.lng2}`}
                      readonly={true}
                      transparent={true}
                      size={150}
                    />
                  </DefaultLayout>
                  <Button
                    isIcon={true}
                    style={{ fontSize: 24 }}
                    onClick={() => window.open(`http://map.daum.net/link/map/${model.spjangnm},${model.lat2},${model.lng2}`)}
                  >
                    <IoIosPin/>
                  </Button>
                  <Button
                    isIcon={true}
                    style={{ fontSize: 24 }}
                    onClick={() => window.open(`http://map.daum.net/link/roadview/${model.lat2},${model.lng2}`)}
                  >
                    <IoMdCamera/>
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model.zipcd2}
                onChange={(value) => setData({ zipcd2: value })}
                readonly={true}
              />
              <TextBox
                value={model.adres2a}
                onChange={(value) => setData({ adres2a: value })}
                readonly={true}
                weight={2}
              />
              <TextBox
                value={model.adres2b}
                onChange={(value) => setData({ adres2b: value })}
                weight={2}
              />
            </LabelBox>
            <LabelBox title="출근사용시[급여기준일]" >
              <FormatTextBox
                textAlign="center"
                format="##일"
                value={model.st_paydate}
                onChange={(value) => setData({ st_paydate: value })}
                readonly={model.Commute !== '1'}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="이메일연동"
              footer={
                <FlexLayout size={80} justify="right">
                  <ComboBox
                    textAlign="center"
                    value={model?.emailuseyn}
                    style={Global.STYLE_COLOR_BLUE}
                    data={[
                      { value: '0', remark: '미사용' },
                      { value: '1', remark: '다음' },
                      { value: '2', remark: '카카오' },
                      { value: '3', remark: '네이버' },
                      { value: '4', remark: '구글' },
                      { value: '5', remark: '네이트' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setData({ emailuseyn: option.value })}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              }
            >
              <TextBox
                value={model.emailid}
                onChange={(value) => setData({ emailid: value })}
              />
            </LabelBox>
            <LabelBox title="비밀번호">
              <TextBox
                type="password"
                value={model.emailpw}
                onChange={(value) => setData({ emailpw: value })}
              />
            </LabelBox>
            <LabelBox
              title="전자계산서 아이디"
              isNeed={true}>
              <TextBox
                value={model.guchung}
                onChange={(value) => setData({ guchung: value })}
              />
            </LabelBox>
            <LabelBox title="유지관리품질우수업체">
              <CheckBox
                value={model.quality === '1'}
                onChange={(value) => setData({ quality: value ? '1' : '0' })}
              />
            </LabelBox>
            <LabelBox title="사용유무" >
              <CheckBox
                value={model.useyn === '1'}
                onChange={(value) => setData({ useyn: value ? '1' : '0' })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="정보센터 아이디">
              <TextBox
                value={model.gosiid}
                onChange={(value) => setData({ gosiid: value })}
              />
            </LabelBox>
            <LabelBox title="정보센터 비밀번호">
              <TextBox
                type="password"
                value={model.gosipasswd}
                onChange={(value) => setData({ gosipasswd: value })}
              />
            </LabelBox>
            <LabelBox title="업태코드">
              <TextBox
                value={model.busicd}
                onChange={(value) => setData({ busicd: value })}
              />
            </LabelBox>
            <LabelBox title="발신전화번호(가상)">
              <TelnumTextBox
                value={model.tel2}
                onChange={(value) => setData({ tel2: value })}
              />
            </LabelBox>
            <LabelBox title="실번호">
              <TelnumTextBox
                value={model.tel3}
                onChange={(value) => setData({ tel3: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout>
            <LabelBox title="견적서 타이틀명">
              <TextBox
                value={model.e451text}
                onChange={(value) => setData({ e451text: value })}
                isMultiline={true}
              />
            </LabelBox>

            <LabelBox title="견적서 하단 특이사항명">
              <TextBox
                value={model.e451remark}
                onChange={(value) => setData({ e451remark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>
          {(scope.state.dz_flag === '1')
          && <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="더존연동 회사구분">
              <OptionBox
                value={model.dz_custflag}
                data={[
                  { value: '1', remark: '회사' },
                  { value: '0', remark: '사업장' },
                ]}
                onChange={(item) => setData({ dz_custflag: item.value })}
              />
            </LabelBox>
            <LabelBox title="회사코드">
              <TextBox
                value={model.dz_co_cd}
                onChange={(value) => setData({ dz_co_cd: value })}
              />
            </LabelBox>
            <LabelBox title="사업장코드">
              <TextBox
                value={model.dz_div_cd}
                onChange={(value) => setData({ dz_div_cd: value })}
              />
            </LabelBox>
          </FlexLayout>}
        </FlexLayout>
        <FlexLayout
          isVertical={true}
          weight={2}>
          <FlexLayout>
          <FlexLayout isVertical={true}>
            <LayoutTitle>회사마크등록</LayoutTitle>
            <ImageView
              src={scope.state.img_cust}
              footer={
                <React.Fragment>
                  <LoaderButton
                    isFull={true}
                    onClick={() => scope.imageSelect(SpjangImages.CUST)}
                    percent={scope.state.percentImageCust}
                  >
                    <FiUpload />
                    <span>등록</span>
                  </LoaderButton>

                  <Button
                    isFull={true}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.imageDelete(SpjangImages.CUST)}
                  >
                    <FiTrash />
                    <span>삭제</span>
                  </Button>
                </React.Fragment>
              }
            />
          </FlexLayout>
          <FlexLayout isVertical={true}>
            <LayoutTitle>회사직인등록</LayoutTitle>
            <ImageView
              src={scope.state.img_seal}
              footer={
                <React.Fragment>
                  <LoaderButton
                    isFull={true}
                    onClick={() => scope.imageSelect(SpjangImages.SEAL)}
                    percent={scope.state.percentImageSeal}
                  >
                    <FiUpload />
                    <span>등록</span>
                  </LoaderButton>

                  <Button
                    isFull={true}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.imageDelete(SpjangImages.SEAL)}
                  >
                    <FiTrash />
                    <span>삭제</span>
                  </Button>
                </React.Fragment>
              }
            />
          </FlexLayout>
          </FlexLayout>
          <FlexLayout isVertical={true}>
            <LayoutTitle>회사마크등록(상호명포함마크)</LayoutTitle>
            <ImageView
              src={scope.state.img_e018_1_pic}
              footer={
                <React.Fragment>
                  <LoaderButton
                    isFull={true}
                    onClick={() => scope.imageSelect(SpjangImages.E018_1_PIC)}
                    percent={scope.state.percentImageE018}
                  >
                    <FiUpload />
                    <span>등록</span>
                  </LoaderButton>

                  <Button
                    isFull={true}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.imageDelete(SpjangImages.E018_1_PIC)}
                  >
                    <FiTrash />
                    <span>삭제</span>
                  </Button>
                </React.Fragment>
              }
            />
          </FlexLayout>
          <FlexLayout
            isVertical={true}
          >
            <LabelBox
              size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}
              title="정보센터 API인증키"
            >
              <TextBox
                value={model.gosi_apikey}
                onChange={(value) => setData({ gosi_apikey: value })}
                isMultiline={true}
              />
            </LabelBox>
            <LabelBox
              size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}
              title="정보센터 API암호화키"
            >
              <TextBox
                value={model.gosi_apikey_secret}
                onChange={(value) => setData({ gosi_apikey_secret: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>
            <LabelBox title="이메일 하단 소갯말 (강조를 원하는 부분은 각 시작, 끝에 `를 넣어주세요)">
              <TextBox
                value={model.email_bottom}
                onChange={(value) => setData({ email_bottom: value })}
                isMultiline={true}
              />
            </LabelBox>
          {(scope.state.dz_flag === '1')
          && <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="홈페이지 주소">
              <TextBox
                value={model.dz_homepage}
                onChange={(value) => setData({ dz_homepage: value })}
              />
            </LabelBox>
          </FlexLayout>}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
