import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Enrollment } from './Enrollment';
import { TabModel } from '../../../../models';
import { EnrollmentTabDetailTemplate } from './tabs/EnrollmentTabDetail.template';
import { EnrollmentTabMaterialsTemplate } from './tabs/EnrollmentTabMaterials.template';

/**
 * 화면 > 탭
 * @window w_tb_e033w
 * @category 공사등록
 */
export const EnrollmentTab: Array<TabLayoutDataItem<Enrollment>> = [
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '견적항목'),
    template: (scope) => <EnrollmentTabDetailTemplate scope={scope} />,
  },
  {
    tab: new TabModel('MATERIALS', FaCodeBranch, '부품내역등록'),
    template: (scope) => <EnrollmentTabMaterialsTemplate scope={scope} />,
  },
];
