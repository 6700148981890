import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  DateTextBox, FormatTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BreakRefusal } from './BreakRefusal';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e401w_cancle
 * @category 고장배정거절내역
 */
export const BreakRefusalTemplate: React.FC<TemplateProps<BreakRefusal>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={250}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>담당</span>
          <TextBox
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.4}
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'pernm',
              text: '담당자',
              width: 50,
              group: true,

              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'recedate',
              text: '접수일자',
              width: 80,
              sum: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.total}건
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.total}건
                </FlexLayout>
              ),
              render: (x) => (
                <FormatTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={x.recedate}
                />
              ),
            },
            {
              id: 'recenum',
              text: '번호',
              width: 50,
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.actnm}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 80,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.equpnm}
                />
              ),
            },
            {
              id: 'contnm',
              text: '고장내용',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.contnm}
                />
              ),
            },
            {
              id: 'remark',
              text: '배정거절사유',
              width: 300,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.remark}
                />
              ),
            },
            {
              id: 'repernm',
              text: '배정자',
              width: 50,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.repernm}
                />
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          data={scope.state.data}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
