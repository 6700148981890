import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { MonthlyIncomeStatement } from '../MonthlyIncomeStatement';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { SalesPurchaseGridHeader } from '../headers/SalesPurchaseGridHeader';

/**
 * 화면 > 탭 1 매출/매입
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서
 */
export const SalesPurchaseTemplate: React.FC<TemplateProps<MonthlyIncomeStatement>> = ({
  scope,
}) => (
  <FlexLayout
    isVertical={true}
  >
    <GridLayout
      ref={scope.gridTab1}
      infinityHandler={scope.infinityTab1}
      header={SalesPurchaseGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.salesPurchaseData}
      headerHeight={68}
      onRowClick={(item) => scope.onTab1RowClickEvent(item)}
    />
  </FlexLayout>
);
