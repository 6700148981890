import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { SystemTemplate } from './System.template';
import { SystemModel } from './System.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface SystemState {
  // 구분
  data: Array<SystemModel>;
  systems?: Array<SystemModel>;
  focuseddata?: SystemModel;
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  winid: string;
  buton: string;
  log: string;
}

/**
 * 컨트롤러
 * @window w_tb_xlogin
 * @category 시스템접속현황
 */
export class System extends PageComponent<PageProps, SystemState>
  implements PageToolEvents {
  updatedRows?: Array<SystemModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const { user } = this.props.publicStore;

    // state 기본값 정의
    this.state = props.state || {
      data: [],
      stdate: `${year}${month}0${1}`,
      enddate: `${year}${month}${date}`,
      winid: '%',
      buton: '%',
      log: '%',
      divicd: '%',
      perid: user.perid,
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    if (this.state.perid === '') {
      ConfirmWarning.show('확인', '사용자를 선택하세요');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        perid: this.state.perid,
        as_nm: this.state.winid,
        buton: this.state.buton,
        logname: this.state.log,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new SystemModel(x))],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          data: [],
        });
        this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.table.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      systems: [],
    }, async () => this.infinity?.retrieveAll());
    await this.table.current?.update(true);
    if (this.state.data.length) {
      this.table.current?.setFocus(0);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.winid,
      buton: this.state.buton,
      logname: this.state.log,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀변환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.winid,
      buton: this.state.buton,
      logname: this.state.log,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  onRowFocusEvent(item: SystemModel) {
    this.setState({ focuseddata: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<SystemModel>, updatedRows: Array<SystemModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <SystemTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state.data,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
