import * as React from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import {
  AskType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ReportModel from './models/ReportModel';
import { ReportTemplate } from './Report.template';
import ReportDetailModel from './models/ReportDetailModel';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { PageComponent } from '../../../../utils';
import { Date6 } from '../../../../utils/time';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';

interface SampleState {
  yearmon: string;
  focusIndex: number;

  data: Array<ReportModel>;
  content: ReportModel;

  dataDetail: Array<ReportDetailModel>;

  isVisibleAttachment: boolean;

  isVisibleWorkers: boolean;
  workers: Array<any>;
  workerSelectedIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_b035
 * @category 샘플페이지
 */
export class Report extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  wkcds: Array<any> = [];

  updatesDetail: Array<ReportDetailModel> = [];

  focusedDetail?: ReportDetailModel;

  indexDetailTable: number = 0;

  workerInfinity?: InfinityRetrieve;

  tableWorker: React.RefObject<TableLayout> = React.createRef();


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      yearmon: Date6.make(),
      data: [],
      focusIndex: 0,
      content: new ReportModel(),
      dataDetail: [],
      isVisibleAttachment: false,
      workers: [],
      isVisibleWorkers: false,
      workerSelectedIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    this.wkcds = (await api.dropdown('wf_dd_ca510_035_01')).items;

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new ReportModel(item)),
          ],
        }, next);
      },
      async () => {
        this.focusedDetail = undefined;
        await this.SS({
          data: [],
          dataDetail: [],
          content: new ReportModel(),
        });

        this.updatesDetail = [];
        await this.table.current?.update(true);

        await this.infinity?.retrieveAll();
        if (this.state.data.length > 0) {
          this.onRowFocusEvent(this.state.data[0], 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.focusedDetail = undefined;
    await this.SS({
      data: [],
      dataDetail: [],
    });

    this.updatesDetail = [];
    await this.table.current?.update(true);
    const index = await this.infinity?.retrieveTo(['ctdate', 'projno'], [this.state.content?.ctdate, this.state.content?.projno], type) || 0;
    this.state.data.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      await this.SS({
        data: [new ReportModel(data, true), ...this.state.data],
        dataDetail: [],
      });

      this.updatesDetail = [];
      this.focusedDetail = undefined;
      await this.table.current?.update(true);

      this.grid.current?.setFocus(0);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save(
      {
        ...this.state.content,
        items: this.state.dataDetail,
      },
      this.state.content.isNew,
    )) {
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.content?.ctdate} ${this.state.content?.projnm}`;

    if (await api.delete(text, {
      perid: this.state.content?.perid,
      mpclafi: this.state.content?.mpclafi,
      ctdate: this.state.content?.ctdate,
      actcd: this.state.content?.actcd,
      actnm: this.state.content?.actnm,
    })) {
      this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.dataDetail.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    let gubun = '0';

    if (AskType.YES === await Confirm.ask(
      '사진포함',
      '사진이 첨부된 공정일보입니다.\n공정사진을 첨부하여 출력하시겠습니까?',
      '예',
      '아니오',
    )) {
      gubun = '1';
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      perid: this.state.content?.perid,
      mpclafi: this.state.content?.mpclafi,
      ctdate: this.state.content?.ctdate,
      attcnt: this.state.content?.attcnt,
      gubun,
    });
  }

  @action
  async onRowFocusEvent(item: ReportModel, index: number) {
    this.updatesDetail = [];
    await this.SS({
      content: item,
      focusIndex: index,
    });

    if (item.isNew) return;

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', {
      ctdate: item.ctdate,
    });

    await this.SS({
      content: new ReportModel(data),
      dataDetail: data?.items?.map((x: any) => new ReportDetailModel(x)) || [],
    });

    await this.table.current?.update(true);
    this.table.current?.setFocus(0, 1);
  }


  @action
  async onChangedDetailTable(rows: Array<ReportDetailModel>, updates: Array<ReportDetailModel>) {
    await this.SS({ dataDetail: rows });
    this.updatesDetail = updates;
  }

  onFocusedDetailTableRow(item: ReportDetailModel, index: number) {
    this.focusedDetail = item;
    this.indexDetailTable = index;
  }


  @action
  async onNewDetailEvent() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_3_new', {
      perid: this.state.content?.perid,
      mpclafi: this.state.content?.mpclafi,
      ctdate: this.state.content?.ctdate,
      actcd: this.state.content?.actcd,
      actnm: this.state.content?.actnm,
      projno: this.state.content?.projno,
      projnm: this.state.content?.projnm,
      wkcd: '001',
      seq: `000${this.state.dataDetail.length + 1}`.substr(-3),
      new: '1',
    });

    await this.SS({
      dataDetail: [
        ...this.state.dataDetail,
        data,
      ],
    });

    await this.table.current?.update(false);
    this.table.current?.setFocus(this.state.dataDetail.length - 1, 1);
  }

  @action
  async onDeleteDetailEvent() {
    if (!this.focusedDetail?.seq) {
      ConfirmWarning.show('오류', '삭제할 작업을 먼저 선택해주세요');
      return;
    }

    const { actionStore: api } = this.props;
    const text = `순서 ${this.focusedDetail?.seq}`;

    if (await api.fxDelete('dw_3_delete', text, {
      perid: this.state.content?.perid,
      mpclafi: this.state.content?.mpclafi,
      ctdate: this.state.content?.ctdate,
      actcd: this.state.content?.actcd,
      actnm: this.state.content?.actnm,
      equpcd: this.focusedDetail?.equpcd,
      wkcd: this.focusedDetail?.wkcd,
      seq: this.focusedDetail?.seq,
    })) {
      await this.SS({
        dataDetail: update(this.state.dataDetail, {
          $splice: [[this.indexDetailTable, 1]],
        }),
      });

      this.table?.current?.update(false);
    }
  }

  @action
  async headerItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...this.state.content,
      [key]: value,
      itemname: key,
      data: value,
    }, this.state.content.isNew);

    this.SS({
      content: new ReportModel({
        ...this.state.content,
        ...result,
      }, this.state.content.isNew),
    });
  }


  @action
  async modifyWorkers() {
    if (!ConfirmWarning.assert(this.state.content.custcd, '오류', '공정일보를 선택 후 다시 시도해주세요')) {
      return;
    }

    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '공정일보를 저장 후 다시 시도해주세요')) {
      return;
    }

    await this.SS({
      workers: [],
      workerSelectedIndex: 0,
    });

    const { actionStore: api } = this.props;

    this.workerInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_b035_perid',
        perid: this.state.content.perid,
        ctdate: this.state.content.ctdate,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          workers: [...this.state.workers, ...items],
        });
      },
      async () => {
        await this.SS({ isVisibleWorkers: true });

        await this.workerInfinity?.retrieve();
        await this.tableWorker.current?.update(true);
        if (this.state.workers.length) {
          this.tableWorker.current?.setFocus(0);
        }
      },
    );

    this.SS({ isVisibleWorkers: true });

    await this.workerInfinity.retrieve();
    await this.tableWorker.current?.update(true);
    if (this.state.workers.length) {
      this.tableWorker.current?.setFocus(0);
    }
  }

  @action
  async addWorker() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      sub: 'w_popup_b035_perid',
      perid: this.state.content.perid,
      ctdate: this.state.content.ctdate,
    });

    await this.SS({
      workers: [...this.state.workers, {
        ...data,
        new: '1',
      }],
    });
    await this.tableWorker.current?.update(false);
    this.tableWorker.current?.setFocus(this.state.workers.length - 1, 0);
  }

  @action
  async removeWorker() {
    if (this.state.workers.length < this.state.workerSelectedIndex) {
      ConfirmWarning.show('오류', '작업자를 선택해주세요');
      return;
    }

    const item = this.state.workers[this.state.workerSelectedIndex];
    const message = `${item.wpernm}님을 작업자 명단에서 삭제합니까?`;
    if (item.seq) {
      const { actionStore: api } = this.props;
      if (!await api.delete(message, {
        sub: 'w_popup_b035_perid',
        perid: this.state.content.perid,
        ctdate: this.state.content.ctdate,
        seq: item.seq,
      })) {
        return;
      }
    } else if (AskType.YES !== await Confirm.ask('삭제', message, '삭제', '취소')) {
      return;
    }

    await this.SS({
      workers: update(this.state.workers, {
        $splice: [[this.state.workerSelectedIndex, 1]],
      }),
    });

    await this.tableWorker.current?.update(false);
  }

  @action
  async saveWorkers() {
    if (!ConfirmWarning.assert(this.state.workers.length, '오류', '작업자를 한명 이상 추가해야 합니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_b035_perid',
      projno: this.state.content.projno,
      items: this.state.workers.map((x, y) => ({
        new: x.new || '0',
        perid: x.perid || this.state.content.perid,
        ctdate: x.ctdate || this.state.content.ctdate,
        seq: `000${y + 1}`.substr(-3),
        wperid: x.wperid || '',
        wpernm: x.wpernm || '',
        remark: x.remark || '',
      })),
    }, true)) {
      await this.SS({ isVisibleWorkers: false });
      this.onRowFocusEvent(this.state.content, this.state.focusIndex);
    }
  }



  render() {
    return <ReportTemplate
      scope={this}
    />;
  }
}
