import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import {
  Compyns,
  Gubuns,
  Status,
} from '../Status';
import StatusTabModel from '../models/StatusTabModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e471w
 * @category 공사현황
 */
export const StatusGridDetailHeader: GLHF<StatusTabModel, Status> = (scope) => ([
  {
    id: 'comp',
    value: (x) => `${x.compdate}${x.compnum}`,
    text: '부서',
    group: true,
    isHidden: true,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    sum: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'comppernm',
    text: '처리자',
    width: 80,
  },
  {
    id: 'compdate',
    text: '공사일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.compdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'compnum',
    text: '번호',
    width: 70,
  },
  {
    id: 'wkactcd',
    text: '회사구분',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
      >
        {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        align="center"
        justify="start"
        style={{
          color: 'var(--color-green)',
        }}
      >
        공사명 : {x.offinm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.myn === '1' ? '무상' : '유상'}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.value === x.gubun)[0].remark}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'pserial',
    text: '품목시리얼번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pserial}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'samt', x, ['compdate', 'compnum']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.detailSamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'tamt', x, ['compdate', 'compnum']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.detailAddamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'compamt',
    text: '공사금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.compamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'compamt', x, ['compdate', 'compnum']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.detailCompamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'compyn',
    text: '상태',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: Compyns.filter((y) => y.value === x.compyn)[0]?.color,
        }}
      >
        {Compyns.filter((y) => y.value === x.compyn)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'misamt', x, ['compdate', 'compnum']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.detailMisamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'offinm',
    text: '공사명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.offinm}
      </FlexLayout>
    ),
  },
]);
