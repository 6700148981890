/**
 * 모델
 * @window w_tb_e035w_04
 * @category 점검자별[주/부]점검현황
 */
export class InspectorStatusModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 사원코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
   readonly mon: string;

  /**
   * 주점검 1일
   *
   * **data type** varchar(8)
   */
  readonly ju01: string;

  /**
   * 주점검 2일
   *
   * **data type** varchar(8)
   */
  readonly ju02: string;

  /**
   * 주점검 3일
   *
   * **data type** varchar(8)
   */
  readonly ju03: string;

  /**
   * 주점검 4일
   *
   * **data type** varchar(8)
   */
  readonly ju04: string;

  /**
   * 주점검 5일
   *
   * **data type** varchar(8)
   */
  readonly ju05: string;

  /**
   * 주점검 6일
   *
   * **data type** varchar(8)
   */
  readonly ju06: string;

  /**
   * 주점검 7일
   *
   * **data type** varchar(8)
   */
  readonly ju07: string;

  /**
   * 주점검 8일
   *
   * **data type** varchar(8)
   */
  readonly ju08: string;

  /**
   * 주점검 9일
   *
   * **data type** varchar(8)
   */
  readonly ju09: string;

  /**
   * 주점검 10일
   *
   * **data type** varchar(8)
   */
  readonly ju10: string;

  /**
   * 주점검 11일
   *
   * **data type** varchar(8)
   */
  readonly ju11: string;

  /**
   * 주점검 12일
   *
   * **data type** varchar(8)
   */
  readonly ju12: string;

  /**
   * 주점검 13일
   *
   * **data type** varchar(8)
   */
  readonly ju13: string;

  /**
   * 주점검 14일
   *
   * **data type** varchar(8)
   */
  readonly ju14: string;

  /**
   * 주점검 15일
   *
   * **data type** varchar(8)
   */
  readonly ju15: string;

  /**
   * 주점검 16일
   *
   * **data type** varchar(8)
   */
  readonly ju16: string;

  /**
   * 주점검 17일
   *
   * **data type** varchar(8)
   */
  readonly ju17: string;

  /**
   * 주점검 18일
   *
   * **data type** varchar(8)
   */
  readonly ju18: string;

  /**
   * 주점검 19일
   *
   * **data type** varchar(8)
   */
  readonly ju19: string;

  /**
   * 주점검 20일
   *
   * **data type** varchar(8)
   */
  readonly ju20: string;

  /**
   * 주점검 21일
   *
   * **data type** varchar(8)
   */
  readonly ju21: string;

  /**
   * 주점검 22일
   *
   * **data type** varchar(8)
   */
  readonly ju22: string;

  /**
   * 주점검 23일
   *
   * **data type** varchar(8)
   */
  readonly ju23: string;

  /**
   * 주점검 24일
   *
   * **data type** varchar(8)
   */
  readonly ju24: string;

  /**
   * 주점검 25일
   *
   * **data type** varchar(8)
   */
  readonly ju25: string;

  /**
   * 주점검 26일
   *
   * **data type** varchar(8)
   */
  readonly ju26: string;

  /**
   * 주점검 27일
   *
   * **data type** varchar(8)
   */
  readonly ju27: string;

  /**
   * 주점검 28일
   *
   * **data type** varchar(8)
   */
  readonly ju28: string;

  /**
   * 주점검 29일
   *
   * **data type** varchar(8)
   */
  readonly ju29: string;

  /**
   * 주점검 30일
   *
   * **data type** varchar(8)
   */
  readonly ju30: string;

  /**
   * 주점검 31일
   *
   * **data type** varchar(8)
   */
  readonly ju31: string;

  /**
   *부점검 1일
   *
   * **data type** varchar(8)
   */
  readonly bu01: string;

  /**
   *부점검 2일
   *
   * **data type** varchar(8)
   */
  readonly bu02: string;

  /**
   *부점검 3일
   *
   * **data type** varchar(8)
   */
  readonly bu03: string;

  /**
   *부점검 4일
   *
   * **data type** varchar(8)
   */
  readonly bu04: string;

  /**
   *부점검 5일
   *
   * **data type** varchar(8)
   */
  readonly bu05: string;

  /**
   *부점검 6일
   *
   * **data type** varchar(8)
   */
  readonly bu06: string;

  /**
   *부점검 7일
   *
   * **data type** varchar(8)
   */
  readonly bu07: string;

  /**
   *부점검 8일
   *
   * **data type** varchar(8)
   */
  readonly bu08: string;

  /**
   *부점검 9일
   *
   * **data type** varchar(8)
   */
  readonly bu09: string;

  /**
   *부점검 10일
   *
   * **data type** varchar(8)
   */
  readonly bu10: string;

  /**
   *부점검 11일
   *
   * **data type** varchar(8)
   */
  readonly bu11: string;

  /**
   *부점검 12일
   *
   * **data type** varchar(8)
   */
  readonly bu12: string;

  /**
   *부점검 13일
   *
   * **data type** varchar(8)
   */
  readonly bu13: string;

  /**
   *부점검 14일
   *
   * **data type** varchar(8)
   */
  readonly bu14: string;

  /**
   *부점검 15일
   *
   * **data type** varchar(8)
   */
  readonly bu15: string;

  /**
   *부점검 16일
   *
   * **data type** varchar(8)
   */
  readonly bu16: string;

  /**
   *부점검 17일
   *
   * **data type** varchar(8)
   */
  readonly bu17: string;

  /**
   *부점검 18일
   *
   * **data type** varchar(8)
   */
  readonly bu18: string;

  /**
   *부점검 19일
   *
   * **data type** varchar(8)
   */
  readonly bu19: string;

  /**
   *부점검 20일
   *
   * **data type** varchar(8)
   */
  readonly bu20: string;

  /**
   *부점검 21일
   *
   * **data type** varchar(8)
   */
  readonly bu21: string;

  /**
   *부점검 22일
   *
   * **data type** varchar(8)
   */
  readonly bu22: string;

  /**
   *부점검 23일
   *
   * **data type** varchar(8)
   */
  readonly bu23: string;

  /**
   *부점검 24일
   *
   * **data type** varchar(8)
   */
  readonly bu24: string;

  /**
   *부점검 25일
   *
   * **data type** varchar(8)
   */
  readonly bu25: string;

  /**
   *부점검 26일
   *
   * **data type** varchar(8)
   */
  readonly bu26: string;

  /**
   *부점검 27일
   *
   * **data type** varchar(8)
   */
  readonly bu27: string;

  /**
   *부점검 28일
   *
   * **data type** varchar(8)
   */
  readonly bu28: string;

  /**
   *부점검 29일
   *
   * **data type** varchar(8)
   */
  readonly bu29: string;

  /**
   *부점검 30일
   *
   * **data type** varchar(8)
   */
  readonly bu30: string;

  /**
   *부점검 31일
   *
   * **data type** varchar(8)
   */
  readonly bu31: string;

  /**
   *총 1일
   *
   * **data type** varchar(8)
   */
  readonly day01: string;

  /**
   *총 2일
   *
   * **data type** varchar(8)
   */
  readonly day02: string;

  /**
   *총 3일
   *
   * **data type** varchar(8)
   */
  readonly day03: string;

  /**
   *총 4일
   *
   * **data type** varchar(8)
   */
  readonly day04: string;

  /**
   *총 5일
   *
   * **data type** varchar(8)
   */
  readonly day05: string;

  /**
   *총 6일
   *
   * **data type** varchar(8)
   */
  readonly day06: string;

  /**
   *총 7일
   *
   * **data type** varchar(8)
   */
  readonly day07: string;

  /**
   *총 8일
   *
   * **data type** varchar(8)
   */
  readonly day08: string;

  /**
   *총 9일
   *
   * **data type** varchar(8)
   */
  readonly day09: string;

  /**
   *총 10일
   *
   * **data type** varchar(8)
   */
  readonly day10: string;

  /**
   *총 11일
   *
   * **data type** varchar(8)
   */
  readonly day11: string;

  /**
   *총 12일
   *
   * **data type** varchar(8)
   */
  readonly day12: string;

  /**
   *총 13일
   *
   * **data type** varchar(8)
   */
  readonly day13: string;

  /**
   *총 14일
   *
   * **data type** varchar(8)
   */
  readonly day14: string;

  /**
   *총 15일
   *
   * **data type** varchar(8)
   */
  readonly day15: string;

  /**
   *총 16일
   *
   * **data type** varchar(8)
   */
  readonly day16: string;

  /**
   *총 17일
   *
   * **data type** varchar(8)
   */
  readonly day17: string;

  /**
   *총 18일
   *
   * **data type** varchar(8)
   */
  readonly day18: string;

  /**
   *총 19일
   *
   * **data type** varchar(8)
   */
  readonly day19: string;

  /**
   *총 20일
   *
   * **data type** varchar(8)
   */
  readonly day20: string;

  /**
   *총 21일
   *
   * **data type** varchar(8)
   */
  readonly day21: string;

  /**
   *총 22일
   *
   * **data type** varchar(8)
   */
  readonly day22: string;

  /**
   *총 23일
   *
   * **data type** varchar(8)
   */
  readonly day23: string;

  /**
   *총 24일
   *
   * **data type** varchar(8)
   */
  readonly day24: string;

  /**
   *총 25일
   *
   * **data type** varchar(8)
   */
  readonly day25: string;

  /**
   *총 26일
   *
   * **data type** varchar(8)
   */
  readonly day26: string;

  /**
   *총 27일
   *
   * **data type** varchar(8)
   */
  readonly day27: string;

  /**
   *총 28일
   *
   * **data type** varchar(8)
   */
  readonly day28: string;

  /**
   *총 29일
   *
   * **data type** varchar(8)
   */
  readonly day29: string;

  /**
   *총 30일
   *
   * **data type** varchar(8)
   */
  readonly day30: string;

  /**
   *총 31일
   *
   * **data type** varchar(8)
   */
  readonly day31: string;

  /**
   * 주점검 총합
   *
   * **data type** varchar(8)
   */
  readonly jutot: string;

  /**
   * 부점검 총합
   *
   * **data type** varchar(8)
   */
  readonly butot: string;

  /**
   * 총점검 총합
   *
   * **data type** varchar(8)
   */
  readonly daytot: string;

  seq: string;

  constructor(data: any = {}) {
    this.seq = data.seq || '0';
    this.custcd = data.custcd || '0';
    this.spjangcd = data.spjangcd || '0';
    this.divicd = data.divicd || '0';
    this.divinm = data.divinm || '0';
    this.perid = data.perid || '0';
    this.pernm = data.pernm || '0';
    this.mon = data.mon || '0';
    this.ju01 = data.ju01 || '0';
    this.ju02 = data.ju02 || '0';
    this.ju03 = data.ju03 || '0';
    this.ju04 = data.ju04 || '0';
    this.ju05 = data.ju05 || '0';
    this.ju06 = data.ju06 || '0';
    this.ju07 = data.ju07 || '0';
    this.ju08 = data.ju08 || '0';
    this.ju09 = data.ju09 || '0';
    this.ju10 = data.ju10 || '0';
    this.ju11 = data.ju11 || '0';
    this.ju12 = data.ju12 || '0';
    this.ju13 = data.ju13 || '0';
    this.ju14 = data.ju14 || '0';
    this.ju15 = data.ju15 || '0';
    this.ju16 = data.ju16 || '0';
    this.ju17 = data.ju17 || '0';
    this.ju18 = data.ju18 || '0';
    this.ju19 = data.ju19 || '0';
    this.ju20 = data.ju20 || '0';
    this.ju21 = data.ju21 || '0';
    this.ju22 = data.ju22 || '0';
    this.ju23 = data.ju23 || '0';
    this.ju24 = data.ju24 || '0';
    this.ju25 = data.ju25 || '0';
    this.ju26 = data.ju26 || '0';
    this.ju27 = data.ju27 || '0';
    this.ju28 = data.ju28 || '0';
    this.ju29 = data.ju29 || '0';
    this.ju30 = data.ju30 || '0';
    this.ju31 = data.ju31 || '0';
    this.jutot = data.jutot || '0';
    this.bu01 = data.bu01 || '0';
    this.bu02 = data.bu02 || '0';
    this.bu03 = data.bu03 || '0';
    this.bu04 = data.bu04 || '0';
    this.bu05 = data.bu05 || '0';
    this.bu06 = data.bu06 || '0';
    this.bu07 = data.bu07 || '0';
    this.bu08 = data.bu08 || '0';
    this.bu09 = data.bu09 || '0';
    this.bu10 = data.bu10 || '0';
    this.bu11 = data.bu11 || '0';
    this.bu12 = data.bu12 || '0';
    this.bu13 = data.bu13 || '0';
    this.bu14 = data.bu14 || '0';
    this.bu15 = data.bu15 || '0';
    this.bu16 = data.bu16 || '0';
    this.bu17 = data.bu17 || '0';
    this.bu18 = data.bu18 || '0';
    this.bu19 = data.bu19 || '0';
    this.bu20 = data.bu20 || '0';
    this.bu21 = data.bu21 || '0';
    this.bu22 = data.bu22 || '0';
    this.bu23 = data.bu23 || '0';
    this.bu24 = data.bu24 || '0';
    this.bu25 = data.bu25 || '0';
    this.bu26 = data.bu26 || '0';
    this.bu27 = data.bu27 || '0';
    this.bu28 = data.bu28 || '0';
    this.bu29 = data.bu29 || '0';
    this.bu30 = data.bu30 || '0';
    this.bu31 = data.bu31 || '0';
    this.butot = data.butot || '0';
    this.day01 = data.day01 || '0';
    this.day02 = data.day02 || '0';
    this.day03 = data.day03 || '0';
    this.day04 = data.day04 || '0';
    this.day05 = data.day05 || '0';
    this.day06 = data.day06 || '0';
    this.day07 = data.day07 || '0';
    this.day08 = data.day08 || '0';
    this.day09 = data.day09 || '0';
    this.day10 = data.day10 || '0';
    this.day11 = data.day11 || '0';
    this.day12 = data.day12 || '0';
    this.day13 = data.day13 || '0';
    this.day14 = data.day14 || '0';
    this.day15 = data.day15 || '0';
    this.day16 = data.day16 || '0';
    this.day17 = data.day17 || '0';
    this.day18 = data.day18 || '0';
    this.day19 = data.day19 || '0';
    this.day20 = data.day20 || '0';
    this.day21 = data.day21 || '0';
    this.day22 = data.day22 || '0';
    this.day23 = data.day23 || '0';
    this.day24 = data.day24 || '0';
    this.day25 = data.day25 || '0';
    this.day26 = data.day26 || '0';
    this.day27 = data.day27 || '0';
    this.day28 = data.day28 || '0';
    this.day29 = data.day29 || '0';
    this.day30 = data.day30 || '0';
    this.day31 = data.day31 || '0';
    this.daytot = data.daytot || '0';
  }
}
