import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb301_01
 * @category 퇴직금등록
 */
export class ServerancePayRegisterModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  gubun: string;

  /**
   * 적용일자
   *
   * **data type** varchar(13)
   */
  retiredate: string;

  /**
   * 퇴직적립금액
   *
   * **data type** float
   */
  retireamt: string;

  /**
   * 부서
   *
   * **data type** varchar(10)
   */
  divicd: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 급여(세전)
   *
   * **data type** float
   */
  tot_samt: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 적용일자
   *
   * **data type** datetime
   */
  retiredatetime: string;

  /**
   * 누적퇴직적립금액
   *
   * **data type** number
   */
  be_retireamt: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 직책코드
   *
   * **data type** varchar(3)
   */
  rspcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.mon = data.mon || '';
    this.pernm = data.pernm || '';
    this.rspcd = data.rspcd || '';
    this.mon = data.mon || '';
    this.tot_samt = data.tot_samt || '';
    this.seq = data.seq || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.divinm = data.divinm || '';
    this.rspcd = data.rspcd || '';
    this.inperid = data.inperid || '';
    this.remark = data.remark || '';
    this.perid = data.perid || '';
    this.perid = data.perid || '';
    this.gubun = data.gubun || '';
    this.retiredate = data.retiredate || '';
    this.retireamt = data.retireamt || '';
    this.indate = data.indate || '';
    this.retiredatetime = data.retiredatetime || '';
    this.be_retireamt = data.be_retireamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
