import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { TabModel } from '../../../../models';
import { MonthlyIncomeStatement } from './MonthlyIncomeStatement';
import { SalesPurchaseTemplate } from './tabs/SalesPurchase.template';
import { DepositPaymentTemplate } from './tabs/DepositPayment.template';

/**
 * 화면 > 탭
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서
 */
export const MonthlyIncomeStatementTab: Array<TabLayoutDataItem<MonthlyIncomeStatement>> = [
  {
    tab: new TabModel('DEPOSITPAYMENT', FaCodeBranch, '매입/매출'),
    template: (scope) => <SalesPurchaseTemplate scope={scope} />,
  },
  {
    tab: new TabModel('SALESPUIRCHASE', FaCodeBranch, '입금/지급'),
    template: (scope) => <DepositPaymentTemplate scope={scope} />,
  },
];
