import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import { OrderCollectionTemplate } from './OrderCollection.template';
import { Today } from '../../../../utils/time';
import { OrderCollectionLeftModel } from './OrderCollectionLeft.model';
import { InfinityRetrieve } from '../../../../models';
import { OrderCollectionModel } from './OrderCollection.model';
import { GridLayout } from '../../../../components';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';

interface OrderCollectionState {
  // 조회조건
  stmon: string;
  endmon: string;
  searchQuery: string;
  misflag: string;
  rcvexflag: string;

  // 왼쪽 List
  leftData: Array<OrderCollectionLeftModel>;
  leftFocused: OrderCollectionLeftModel;

  // 왼쪽 List Trail
  notmisamt_tot: string;

  // List
  orderCollectionList: Array<OrderCollectionModel>;
  orderFocused: OrderCollectionModel;

  // List trail
  cnt_tot: string;
  compamt_tot: string;
  notamt_tot: string;
  qty_tot: string;
  rcvamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_10
 * @category 설치수주/수금현황
 */
export class OrderCollection extends PageComponent<PageProps, OrderCollectionState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  leftGrid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 조회조건
      stmon: `${Today.year()}01`,
      endmon: `${Today.year()}12`,
      searchQuery: '',
      misflag: '%', // default값
      rcvexflag: '%',

      // 왼쪽 List
      leftData: [],
      leftFocused: [],

      // 왼쪽 List trail
      notmisamt_tot: '',

      // List
      orderCollectionList: [],
      orderFocused: [],

      // List trail
      cnt_tot: '',
      compamt_tot: '',
      notamt_tot: '',
      qty_tot: '',
      rcvamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        as_nm: this.state.searchQuery,
        misflag: this.state.misflag,
        rcvexflag: this.state.rcvexflag,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          orderCollectionList: items.map((x: any) => new OrderCollectionModel(x)),
        }, next);
      },
      async () => {
        await this.SS({
          orderCollectionList: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    await this.SS({
      orderCollectionList: [],
    });

    await this.infinity?.retrieveAll();

    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot || '0',
      compamt_tot: this.infinity?.box?.compamt_tot || '0',
      notamt_tot: this.infinity?.box?.notamt_tot || '0',
      qty_tot: this.infinity?.box?.qty_tot || '0',
      rcvamt_tot: this.infinity?.box?.rcvamt_tot || '0',
    });

    if (this.state.orderCollectionList && this.state.orderCollectionList?.length > 0) {
      this.grid.current?.setFocus(0);
    }
    await this.leftOnRetrieveEvent();
  }

  @action
  async leftOnRetrieveEvent() {
    const { actionStore: api } = this.props;

    await this.SS({
      leftData: [],
    });

    const data = await api.fxExec('retrieve2',
      {
        stmon: this.state.stmon, // 왼쪽 List의 월
        endmon: this.state.endmon,
      });

    await this.SS({
      leftData: data?.items,
      leftFocused: new OrderCollectionLeftModel(),
    });

    await this.SS({
      notmisamt_tot: data?.notmisamt_tot || '0',
    });

    this.leftGrid.current?.setFocus(0);
  }

  // 더블 클릭시에는 Retrieve1 만 실행
  @action
  async onDoubleClicked() {
    const { actionStore: api } = this.props;

    const result = await Confirm.ask('선택', '선택한 월을 조회하시겠습니까?', '예', '아니오');

    if (result === 1) return;

    await this.SS({
      orderCollectionList: [],
    });

    // 이전내역 조회시 0000년00월부터 조회
    // 이전내역아니면 해당 월만 조회
    const data = await api.fxExec('retrieve',
      {
        stmon: this.state.leftFocused?.actnm === '이전내역' ? '000000' : this.state.leftFocused?.actcd,
        endmon: this.state.leftFocused?.actcd,
        as_nm: this.state.searchQuery,
        misflag: this.state.misflag,
      });

    await this.SS({
      orderCollectionList: data?.items,
    });

    await this.SS({
      cnt_tot: data?.cnt_tot || '0',
      compamt_tot: data?.compamt_tot || '0',
      notamt_tot: data?.notamt_tot || '0',
      qty_tot: data?.qty_tot || '0',
      rcvamt_tot: data?.rcvamt_tot || '0',
    });

    this.grid.current?.setFocus(0);
  }

  @action
  async onLeftRowFocusEvent(item: OrderCollectionLeftModel) {
    await this.SS({
      leftFocused: new OrderCollectionLeftModel(item, item?.isNew),
    });
  }

  @action
  async onRowFocusEvent(item: OrderCollectionModel) {
    console.log(item);
    await this.SS({
      orderFocused: new OrderCollectionModel(item, item?.isNew),
    });
  }

  @action
  async onItemChanged(name: string, value: string) {
    const { actionStore: api } = this.props;

    await api.fxExec('dw_1_itemchanged', {
      itemname: name,
      data: value,
      actcd: this.state.orderFocused?.actcd,
      gubun: this.state.orderFocused?.gubun,
      misflag: this.state.orderFocused?.misflag,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.orderCollectionList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      as_nm: this.state.searchQuery,
      misflag: this.state.misflag,
      rcvexflag: this.state.rcvexflag,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.orderCollectionList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      as_nm: this.state.searchQuery,
      misflag: this.state.misflag,
      rcvexflag: this.state.rcvexflag,
    });
  }

  render() {
    return (
      <OrderCollectionTemplate
        scope={this}
      >
      </OrderCollectionTemplate>
    );
  }
}
