import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { PayCalculateModel } from './PayCalculate.model';
import { PayCalculateTemplate } from './PayCalculate.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  GridLayoutAdditionalHeader,
  TableLayout,
} from '../../../../components';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { Format } from '../../../../utils/string';

interface PayCalculateState {
  // search
  yearmon: string;
  spjangcd: string;
  offiyn: string;
  searchName: string;
  sequence: string;
  index: string;
  custcd: string;

  // 연봉계약서 버튼
  img?: string;
  textModal: boolean;

  // data
  data: Array<PayCalculateModel>;
  focused?: PayCalculateModel;
  focusIndex: number;

  // modal
  modalVisible?: boolean;
  titleName: string;
  // 평일당직비 modal
  modalPayVisible?: boolean;
  payTitleName: string;

  // trail
  pay_other_random_t: string;
  pay_month_tot: string;
  pay_year_tot: string;
  pay_day_tot: string;
  pay_hour_tot: string;
  pay_tot_tot: string;
  pay_basic_tot: string;
  pay_food_tot: string;
  pay_serallow_tot: string;
  pay_older_tot: string;
  pay_night_tot: string;
  pay_holiday_tot: string;
  pay_yearly_tot: string;
  over_older_time_cal_tot: string;
  over_older_time_tot: string;
  over_older_pay_tot: string;
  over_duty_time_cal_tot: string;
  over_duty_time_tot: string;
  over_duty_pay_tot: string;
  over_holi_time_cal_tot: string;
  over_holi_time_tot: string;
  over_holi_pay_tot: string;
  pay_leader_tot: string;
  pay_bonus_tot: string;
  pay_incentive_tot: string;
  pay_other_tot: string;
  pay_other_random_tot: string;
  month_minus_tot: string;
  minuspay_basic_tot: string;
  minuspay_older_tot: string;
  minuspay_late_time_tot: string;
  minuspay_late_pay_tot: string;
  minuspay_late_tot_tot: string;
  deduction_tot: string;
  payamt_tax_tot: string;
  payamt_tax2_tot: string;
  insu_maf_tot: string;
  insu_unem_tot: string;
  insu_health_tot: string;
  insu_longterm_tot: string;
  insu_national_tot: string;
  tax_national_tot: string;
  tax_suspense_tot: string;
  tax_other_tot: string;
  tax_income_tot: string;
  tax_health_tot: string;
  rest_day_tot: string;
  rest_pay_tot: string;
  pay_tax0_01_tot: string;
  pay_tax0_02_tot: string;
  pay_tax0_03_tot: string;
  pay_tax0_04_tot: string;
  pay_tax0_05_tot: string;
  pay_tax0_06_tot: string;
  pay_tax0_07_tot: string;
  pay_tax0_08_tot: string;
  pay_tax1_01_tot: string;
  pay_tax1_02_tot: string;
  pay_tax1_03_tot: string;
  pay_tax1_04_tot: string;
  pay_tax1_05_tot: string;
  pay_tax1_06_tot: string;
  pay_tax1_07_tot: string;
  insu_health_def: string;
  insu_longterm_def: string;
  insu_national_def: string;
  insu_unem_def: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_01
 * @category 급여계산
 */
export class PayCalculate extends PageComponent<PageProps, PayCalculateState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  infinity?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  private check: number | undefined;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // @ts-ignore
    const data = year + month;

    // state 기본값 정의
    this.state = props.state || {
      // eslint-disable-next-line max-len
      yearmon: `${year}${month}`,
      spjangcd: 'ZZ',
      offiyn: '%',
      searchName: '',
      sequence: '0',
      index: '0',

      // data
      focusIndex: 0,
      data: [],
      itemName: '0',
      itemValue: '0',
      insu_health_def: '0',
      insu_longterm_def: '0',
      insu_national_def: '0',
      insu_unem_def: '0',

      // trail
      pay_month_tot: '0',
      pay_year_tot: '0',
      pay_day_tot: '0',
      pay_hour_tot: '0',
      pay_tot_tot: '0',
      pay_basic_tot: '0',
      pay_food_tot: '0',
      pay_serallow_tot: '0',
      pay_older_tot: '0',
      pay_night_tot: '0',
      pay_holiday_tot: '0',
      pay_yearly_tot: '0',
      over_older_time_cal_tot: '0',
      over_older_time_tot: '0',
      over_older_pay_tot: '0',
      over_duty_time_cal_tot: '0',
      over_duty_time_tot: '0',
      over_duty_pay_tot: '0',
      over_holi_time_cal_tot: '0',
      over_holi_time_tot: '0',
      over_holi_pay_tot: '0',
      pay_leader_tot: '0',
      pay_bonus_tot: '0',
      pay_incentive_tot: '0',
      pay_other_tot: '0',
      pay_other_random_tot: '0',
      month_minus_tot: '0',
      minuspay_basic_tot: '0',
      minuspay_older_tot: '0',
      minuspay_late_time_tot: '0',
      minuspay_late_pay_tot: '0',
      minuspay_late_tot_tot: '0',
      deduction_tot: '0',
      payamt_tax_tot: '0',
      payamt_tax2_tot: '0',
      insu_maf_tot: '0',
      insu_unem_tot: '0',
      insu_health_tot: '0',
      insu_longterm_tot: '0',
      insu_national_tot: '0',
      tax_national_tot: '0',
      tax_suspense_tot: '0',
      tax_other_tot: '0',
      tax_income_tot: '0',
      tax_health_tot: '0',
      rest_day_tot: '0',
      rest_pay_tot: '0',
      pay_tax0_01_tot: '0',
      pay_tax0_02_tot: '0',
      pay_tax0_03_tot: '0',
      pay_tax0_04_tot: '0',
      pay_tax0_05_tot: '0',
      pay_tax0_06_tot: '0',
      pay_tax0_07_tot: '0',
      pay_tax0_08_tot: '0',
      pay_tax1_01_tot: '0',
      pay_tax1_02_tot: '0',
      pay_tax1_03_tot: '0',
      pay_tax1_04_tot: '0',
      pay_tax1_05_tot: '0',
      pay_tax1_06_tot: '0',
      pay_tax1_07_tot: '0',
      pay_other_random_t: '',

      // modal
      payTitleName: '0',
      titleName: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        mon: this.state.yearmon,
        spjangcd: this.state.spjangcd,
        offiyn: this.state.offiyn,
        as_nm: this.state.searchName,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new PayCalculateModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();

        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update(true);
          await this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      custcd: user.custcd,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['seq', 'perid'],
        [this.state.focused?.seq, this.state.focused?.perid], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update(true);
        await this.table.current?.setFocus(index, 0);
        // this.onRowFocusEvent(this.state.data[0], 0);
      }
      this.check = 0;
      this.updatedRows = [];

      await this.SS({
        titleName: this.infinity?.box?.pay_other_random_t || '0',
        pay_month_tot: this.infinity?.box?.pay_month_tot || '0',
        pay_year_tot: this.infinity?.box?.pay_year_tot || '0',
        pay_day_tot: this.infinity?.box?.pay_day_tot || '0',
        pay_hour_tot: this.infinity?.box?.pay_hour_tot || '0',
        pay_tot_tot: this.infinity?.box?.pay_tot_tot || '0',
        pay_basic_tot: this.infinity?.box?.pay_basic_tot || '0',
        pay_food_tot: this.infinity?.box?.pay_food_tot || '0',
        pay_serallow_tot: this.infinity?.box?.pay_serallow_tot || '0',
        pay_older_tot: this.infinity?.box?.pay_older_tot || '0',
        pay_night_tot: this.infinity?.box?.pay_night_tot || '0',
        pay_holiday_tot: this.infinity?.box?.pay_holiday_tot || '0',
        pay_yearly_tot: this.infinity?.box?.pay_yearly_tot || '0',
        over_older_time_cal_tot: this.infinity?.box?.over_older_time_cal_tot || '0',
        over_older_time_tot: this.infinity?.box?.over_older_time_tot || '0',
        over_older_pay_tot: this.infinity?.box?.over_older_pay_tot || '0',
        over_duty_time_cal_tot: this.infinity?.box?.over_duty_time_cal_tot || '0',
        over_duty_time_tot: this.infinity?.box?.over_duty_time_tot || '0',
        over_duty_pay_tot: this.infinity?.box?.over_duty_pay_tot || '0',
        over_holi_time_cal_tot: this.infinity?.box?.over_holi_time_cal_tot || '0',
        over_holi_time_tot: this.infinity?.box?.over_holi_time_tot || '0',
        over_holi_pay_tot: this.infinity?.box?.over_holi_pay_tot || '0',
        pay_leader_tot: this.infinity?.box?.pay_leader_tot || '0',
        pay_bonus_tot: this.infinity?.box?.pay_bonus_tot || '0',
        pay_incentive_tot: this.infinity?.box?.pay_incentive_tot || '0',
        pay_other_tot: this.infinity?.box?.pay_other_tot || '0',
        pay_other_random_tot: this.infinity?.box?.pay_other_random_tot || '0',
        month_minus_tot: this.infinity?.box?.month_minus_tot || '0',
        minuspay_basic_tot: this.infinity?.box?.minuspay_basic_tot || '0',
        minuspay_older_tot: this.infinity?.box?.minuspay_older_tot || '0',
        minuspay_late_time_tot: this.infinity?.box?.minuspay_late_time_tot || '0',
        minuspay_late_pay_tot: this.infinity?.box?.minuspay_late_pay_tot || '0',
        minuspay_late_tot_tot: this.infinity?.box?.minuspay_late_tot_tot || '0',
        deduction_tot: this.infinity?.box?.deduction_tot || '0',
        payamt_tax_tot: this.infinity?.box?.payamt_tax_tot || '0',
        payamt_tax2_tot: this.infinity?.box?.payamt_tax2_tot || '0',
        insu_maf_tot: this.infinity?.box?.insu_maf_tot || '0',
        insu_unem_tot: this.infinity?.box?.insu_unem_tot || '0',
        insu_health_tot: this.infinity?.box?.insu_health_tot || '0',
        insu_longterm_tot: this.infinity?.box?.insu_longterm_tot || '0',
        insu_national_tot: this.infinity?.box?.insu_national_tot || '0',
        tax_national_tot: this.infinity?.box?.tax_national_tot || '0',
        tax_suspense_tot: this.infinity?.box?.tax_suspense_tot || '0',
        tax_other_tot: this.infinity?.box?.tax_other_tot || '0',
        tax_income_tot: this.infinity?.box?.tax_income_tot || '0',
        tax_health_tot: this.infinity?.box?.tax_health_tot || '0',
        rest_day_tot: this.infinity?.box?.rest_day_tot || '0',
        rest_pay_tot: this.infinity?.box?.rest_pay_tot || '0',
        pay_tax0_01_tot: this.infinity?.box?.pay_tax0_01_tot || '0',
        pay_tax0_02_tot: this.infinity?.box?.pay_tax0_02_tot || '0',
        pay_tax0_03_tot: this.infinity?.box?.pay_tax0_03_tot || '0',
        pay_tax0_04_tot: this.infinity?.box?.pay_tax0_04_tot || '0',
        pay_tax0_05_tot: this.infinity?.box?.pay_tax0_05_tot || '0',
        pay_tax0_06_tot: this.infinity?.box?.pay_tax0_06_tot || '0',
        pay_tax0_07_tot: this.infinity?.box?.pay_tax0_07_tot || '0',
        pay_tax0_08_tot: this.infinity?.box?.pay_tax0_08_tot || '0',
        pay_tax1_01_tot: this.infinity?.box?.pay_tax1_01_tot || '0',
        pay_tax1_02_tot: this.infinity?.box?.pay_tax1_02_tot || '0',
        pay_tax1_03_tot: this.infinity?.box?.pay_tax1_03_tot || '0',
        pay_tax1_04_tot: this.infinity?.box?.pay_tax1_04_tot || '0',
        pay_tax1_05_tot: this.infinity?.box?.pay_tax1_05_tot || '0',
        pay_tax1_06_tot: this.infinity?.box?.pay_tax1_06_tot || '0',
        pay_tax1_07_tot: this.infinity?.box?.pay_tax1_07_tot || '0',
        insu_health_def: this.infinity?.box?.insu_health_def || '0',
        insu_longterm_def: this.infinity?.box?.insu_longterm_def || '0',
        insu_national_def: this.infinity?.box?.insu_national_def || '0',
        insu_unem_def: this.infinity?.box?.insu_unem_def || '0',
        pay_other_random_t: this.infinity?.box?.pay_tax1_07_tot || '',
      });
      await this.table.current?.update(true);
    });
  }

  async onRefresh() {
    const { actionStore: api } = this.props;

    await api.fxExec(
      'wb_refresh',
      {
        mon: this.state.yearmon,
        spjangcd: this.state.spjangcd,
        offiyn: this.state.offiyn,
        as_nm: this.state.searchName,
      },
    );
    await this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  onRowFocusEvent(item: PayCalculateModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  // 검색어 사원명 찾고 포커스
  @action
  async onNameFocusBtn(data: string) {
    let end = 0;
    let chkData = 0;

    this.state.data.forEach((x: any, n) => {
      if (x.pernm.includes(data)) {
        chkData = n;
      }
    });

    this.state.data.forEach((x: any, n) => {
      if (x.pernm.includes(data)) {
        // @ts-ignore
        if (chkData === this.check) {
          ConfirmWarning.show('확인', '찾는 검색이 없습니다.');
          this.table.current?.setFocus(0, 8);
          this.check = 0;
          chkData = 0;
        }
        if (end === 1) return;
        // @ts-ignore
        if (n > this.check && n !== this.check) {
          this.check = n;
          this.table.current?.setFocus(this.check, 0);
          this.table.current?.update(true);
          end = 1;
        }
      }
    });
  }

  @action
  async onSequenceBtn(item: string) {
    // 오름차순, 내림차순 정렬
    let sequenceData;
    switch (this.state.sequence) {
      case '0':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.seq < b.seq ? -1 : a.seq > b.seq ? 1 : 0;
          }
          return a.seq > b.seq ? -1 : a.seq < b.seq ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '1':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.perid < b.perid ? -1 : a.perid > b.perid ? 1 : 0;
          }
          return a.perid > b.perid ? -1 : a.perid < b.perid ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '2':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.pernm < b.pernm ? -1 : a.pernm > b.pernm ? 1 : 0;
          }
          return a.pernm > b.pernm ? -1 : a.pernm < b.pernm ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '3':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.pay_year < b.pay_year ? -1 : a.pay_year > b.pay_year ? 1 : 0;
          }
          return a.pay_year > b.pay_year ? -1 : a.pay_year < b.pay_year ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '4':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.pay_year < b.pay_year ? -1 : a.pay_year > b.pay_year ? 1 : 0;
          }
          return a.pay_year > b.pay_year ? -1 : a.pay_year < b.pay_year ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '5':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.rspnm < b.rspnm ? -1 : a.rspnm > b.rspnm ? 1 : 0;
          }
          return a.rspnm > b.rspnm ? -1 : a.rspnm < b.rspnm ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '6':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.entdate < b.entdate ? -1 : a.entdate > b.entdate ? 1 : 0;
          }
          return a.entdate > b.entdate ? -1 : a.entdate < b.entdate ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '7':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.pay_year < b.pay_year ? -1 : a.pay_year > b.pay_year ? 1 : 0;
          }
          return a.pay_year > b.pay_year ? -1 : a.pay_year < b.pay_year ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '8':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.pernm < b.pernm ? -1 : a.pernm > b.pernm ? 1 : 0;
          }
          return a.pernm > b.pernm ? -1 : a.pernm < b.pernm ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
      case '9':
        sequenceData = this.state.data.sort((a, b) => {
          if (item === '0') {
            return a.divicd < b.divicd ? -1 : a.divicd > b.divicd ? 1 : 0;
          }
          return a.divicd > b.divicd ? -1 : a.divicd < b.divicd ? 1 : 0;
        });
        this.setState({ data: sequenceData });
        break;
    }
    await this.table.current?.update(true);
  }

  async imageRequest() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      'wb_yearcont',
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.yearmon,
        perid: this.state.focused?.perid,
      },
    );
  }

  @action
  async onReloadBtn(item: string) {
    const { actionStore: api } = this.props;
    this.textModal(false);

    let warning: boolean;
    // 재계산
    if (item === 'p_refresh') {
      warning = await Confirm.show('확인', `${this.state.focused?.pernm}님을 재계산하시겠습니까?`, ConfirmType.QUESTION);
      if (warning === false) return;
    }

    // 인센티브2
    if (item === 'p_incentive') {
      warning = await Confirm.show('확인', '인센티브를 다시가져오시겠습니까?', ConfirmType.QUESTION);
      if (warning === false) return;
    }

    // 인센티브3
    if (item === 'p_change' && this.updatedRows?.length) {
      ConfirmWarning.show('확인', '먼저 새로고침을 하세요!');
      return;
    }

    // 당직휴일
    if (item === 'p_over_holi_time' && this.updatedRows?.length) {
      ConfirmWarning.show('확인', '먼저 새로고침을 하세요!');
      return;
    }

    // 당직평일
    if (item === 'p_over_duty_time' && this.updatedRows?.length) {
      ConfirmWarning.show('확인', '먼저 새로고침을 하세요!');
      return;
    }

    let itemData = {};

    switch (item) {
      case 'p_incentive':
        itemData = {
          over_holi_time: this.state.focused?.over_holi_time,
        };
        break;

      case 'p_change':
        itemData = {
          pay_other_random_t: this.state.titleName,
        };
        break;

      case 'p_refresh':
        itemData = {
          entdate: this.state.focused?.entdate,
        };
        break;

      case 'p_over_holi_time':
        itemData = {
          over_holi_time: this.state.focused?.over_holi_time,
        };
        break;

      case 'p_over_duty_time':
        itemData = {
          over_duty_time: this.state.focused?.over_duty_time,
        };
        break;
    }

    await api.fxExec(
      'dw_1_buttonclicked',
      {
        new: '0',
        itemname: item,
        spjangcd: this.state.spjangcd,
        mon: this.state.yearmon,
        offiyn: this.state.focused?.offiyn,
        perid: this.state.focused?.perid,
        ...itemData,
      },
    );
    await this.onRetrieveEvent();
  }

  @action
  async onItemChange(row: PayCalculateModel, item: any, name: string, rowUpdate: RowUpdate, callback?: VoidFunction) {
    const { actionStore: api } = this.props;

    /*
    if (item === 'p_incentive') {
      Confirm.show('확인', `${this.state.focused?.pernm}님을 재계산하시겠습니까?`, ConfirmType.QUESTION);
    }

    if (item === 'p_change') {
      ConfirmWarning.show('확인', '먼저 새로고침을 하세요!');
    }
    */

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    row[name] = item;

    const data = await api.fxExec(
      'dw_1_itemchanged',
      {
        itemname: name,
        data: item,
        new: row.new,
        spjangcd: this.state.spjangcd,
        mon: this.state.yearmon,
        perid: row.perid,
        entdate: row.entdate,
        preyn: row.preyn,
        offiyn: row.offiyn,
        yearly_default: row.yearly_default,
        yearly_add: row.yearly_add,
        yearly_cnt: row.yearly_cnt,
        pay_month: row.pay_month,
        time_basic: row.time_basic,
        time_older: row.time_older,
        time_night: row.time_night,
        time_holiday: row.time_holiday,
        time_yearly: row.time_yearly,
        pay_food: row.pay_food,
        pay_serallow: row.pay_serallow,
        month_minus: row.month_minus,
        minuspay_late_time: row.minuspay_late_time,
        over_older_time_cal: row.over_older_time_cal,
        over_duty_time_cal: row.over_duty_time_cal,
        insu_unem_flag: row.insu_unem_flag,
        insu_health_flag: row.insu_health_flag,
        insu_longterm_flag: row.insu_longterm_flag,
        insu_national_flag: row.insu_national_flag,
        insu_maf_flag: row.insu_maf_flag,
        pay_serallow_flag: row.pay_serallow_flag,
        pay_food_flag: row.pay_food_flag,
        over_holi_time_cal: row.over_holi_time_cal,
        month_basic: row.month_basic,
        pay_older: row.pay_older,
        pay_holiday: row.pay_holiday,
        pay_leader: row.pay_leader,
        pay_bonus: row.pay_bonus,
        pay_incentive: row.pay_incentive,
        pay_other: row.pay_other,
        pay_other_random: row.pay_other_random,
        rest_day: row.rest_day,
        pay_day: row.pay_day,
        insu_unem: row.insu_unem,
        insu_longterm: row.insu_longterm,
        insu_national: row.insu_national,
        payamt_tax: row.payamt_tax,
        payamt_tax2: row.payamt_tax2,
        insu_maf: Format.number(row.insu_maf),
        tax_income: row.tax_income,
        tax_health: row.tax_health,
        tax_national: row.tax_national,
        tax_suspense: row.tax_suspense,
        tax_other: row.tax_other,
        over_duty_time: row.over_duty_time,
        over_holi_time: row.over_holi_time,
        pay_hour: row.pay_hour,
        pay_tax0_01: row.pay_tax0_01,
        pay_tax0_02: row.pay_tax0_02,
        pay_tax0_03: row.pay_tax0_03,
        pay_tax0_04: row.pay_tax0_04,
        pay_tax0_05: row.pay_tax0_05,
        pay_tax0_06: row.pay_tax0_06,
        pay_tax0_07: row.pay_tax0_07,
        pay_tax0_08: row.pay_tax0_08,
        pay_tax1_01: row.pay_tax1_01,
        pay_tax1_02: row.pay_tax1_02,
        pay_tax1_03: row.pay_tax1_03,
        pay_tax1_04: row.pay_tax1_04,
        pay_tax1_05: row.pay_tax1_05,
        pay_tax1_06: row.pay_tax1_06,
        pay_tax1_07: row.pay_tax1_07,
        pay_tax1_08: row.pay_tax1_08,
        insu_health: row.insu_health,
      },
    );

    await rowUpdate({
      ...row, ...data,
    });
    callback && callback();
  }

  @action
  onUpdatedRows(rows: Array<any>, updatedRows: Array<any>) {
    this.updatedRows = updatedRows;
    this.setState({
      data: rows,
    });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ modalVisible: isOpen });
  }

  @action
  payModal(isOpen: boolean) {
    this.setState({ modalPayVisible: isOpen });
  }

  render() {
    return (
      <PayCalculateTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
