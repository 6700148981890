import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_ae200
 * @category 지출결의서
 */

export class ExpenditureModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly inputdate: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  readonly inputnum: string;

  /**
   * 작성자코드
   *
   * **data type** varchar(10)
   */
  readonly inputsabun: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly divinm: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly rspnm: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly subject: string;

  /**
   * 비고
   *
   * **data type** varchar(0)
   */
  readonly remark: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly sndflag: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 첨부파일 건수
   *
   * **data type** varchar(1)
   */
  readonly attcnt: number;


  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  readonly mijdate: string;

  /**
   * 지급번호
   *
   * **data type** varchar(4)
   */
  readonly mijnum: string;

  /**
   * 지급여부
   *
   * **data type** varchar(1)
   */
  readonly mijflag: string;

  /**
   * 지급여부
   *
   * **data type** varchar(1)
   */
  readonly amount: string;

  /**
   * 출력
   *
   * **data type** varchar(1)
   */
  readonly prtchk: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   * 승인구분
   *
   * **data type** varchar(20)
   */
  readonly okgubun: string;

  /**
   * 승인번호
   *
   * **data type** varchar(20)
   */
  readonly okfloat: string;

  /**
   * 승인번호
   *
   * **data type** varchar(20)
   */
  readonly oknumber: string;

  /**
   * 일괄선택
   *
   * **data type** varchar(1)
   */
  readonly allok: string;

  /**
   * 일괄선택
   *
   * **data type** varchar(1)
   */
  readonly delflag: string;

  /**
   * 일괄선택
   *
   * **data type** varchar(1)
   */
  readonly snddate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.inputdate = data.inputdate || '';
    this.inputnum = data.inputnum || '';
    this.inputsabun = data.inputsabun || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.subject = data.subject || '';
    this.remark = Fix.newline(data.remark) || '';
    this.divicd = data.divicd || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.delflag = data.delflag || '';
    this.snddate = data.snddate || '';
    this.sndflag = data.sndflag || '';
    this.picflag = data.picflag || '';
    this.attcnt = data.attcnt || 0;
    this.refer = data.refer || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.mijflag = data.mijflag || '';
    this.prtchk = data.prtchk || '';
    this.apptime = data.apptime || '';
    this.amount = data.amount || '';
    this.okgubun = data.okgubun || '';
    this.oknumber = data.oknumber || '';
    this.okfloat = data.okfloat || '';
    this.allok = data.allok || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
