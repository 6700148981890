import { computed } from 'mobx';

/**
 * 모델
 * 거래처이력정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentAccountModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순서
   *
   * **data type** varchar(2)
   */
  seq: string;

  /**
   * 순서
   *
   * **data type** varchar(2)
   */
  cltcd: string;

  /**
   * 순서
   *
   * **data type** varchar(2)
   */
  cltnm: string;

  /**
   * 대표자명
   *
   * **data type** varchar(100)
   */
  prenm: string;

  /**
   * 시작일자
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 종료일자
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(100)
   */
  saupnum: string;

  /**
   * 법인번호
   *
   * **data type** varchar(100)
   */
  prenum: string;

  /**
   * 담당자
   *
   * **data type** varchar(100)
   */
  agnernm: string;

  /**
   * 계산서메일
   *
   * **data type** varchar(100)
   */
  taxmail: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.seq = data.seq || '';
    this.prenm = data.prenm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.saupnum = data.saupnum || '';
    this.prenum = data.prenum || '';
    this.agnernm = data.agnernm || '';
    this.taxmail = data.taxmail || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
