import { computed } from 'mobx';

/**
 * 거래처별 모델
 * @window w_tb_e050w_05
 * @category 월별매출현황
 */
export class DealModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 1월
   *
   * **data type** number
   */
  mon01: string;

  /**
   * 2월
   *
   * **data type** number
   */
  mon02: string;

  /**
   * 3월
   *
   * **data type** number
   */
  mon03: string;

  /**
   * 4월
   *
   * **data type** number
   */
  mon04: string;

  /**
   * 5월
   *
   * **data type** number
   */
  mon05: string;

  /**
   * 6월
   *
   * **data type** number
   */
  mon06: string;

  /**
   * 7월
   *
   * **data type** number
   */
  mon07: string;

  /**
   * 8월
   *
   * **data type** number
   */
  mon08: string;

  /**
   * 9월
   *
   * **data type** number
   */
  mon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  mon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  mon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  mon12: string;

  /**
   * 년합계
   *
   * **data type** varchar(100)
   */
  iamt: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltnm = data.cltnm || '';
    this.actcd = data.actcd || '';
    this.iamt = data.iamt || '';
    this.actnm = data.actnm || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
