import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  Receivable,
  ReceivableTabDataStateNames,
} from './Receivable';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_input_da023w
 * @category 미수금현황
 */
export const ReceivableTemplate: React.FC<TemplateProps<Receivable>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={567}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder={scope.state.holderName}
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={357}>
            <span>매출분류</span>
            <ComboBox
              value={scope.state.billgubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '매출' },
                { value: '0', remark: '비매출' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ billgubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout
            style={{ marginLeft: 38 }}
            size={225}
          >
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.divinm}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout
            size={327}
            align="center"
          >
            <span style={{ color: 'var(--color-red)' }}>담당자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />

            <TextBox
              weight={1.5}
              value={scope.state.pernm}
              readonly={true}
            />

            <FlexLayout
              size={100}
              style={{
                height: '67%',
                backgroundColor: '#FFFFFF',
                border: 'solid 1px var(--color-tools-overlay)',
              }}
            >
              <CheckBox
                style={{
                  width: 0,
                  border: 0,
                }}
                value={scope.state.resuck === '1'}
                onChange={(v) => setData({ resuck: v ? '1' : '0' },
                  () => scope.onRetrieveEvent())}
                isDisabledTrackingStateChange={true}
              />

              <FlexLayout weight={2.5}>
                잔액체크
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'cnam',
                text: '매출구분',
                width: 80,

              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.openChk}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={5}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>
          <FlexLayout weight={1.5}>
            <GridLayout
              // @ts-ignore
              scope={scope}
              ref={scope.grid}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity2}
              onRowClick={(item) => scope.onRowFocusEvent(item)}
              onRowDoubleClick={() => scope.textModal(true)}
              // @ts-ignore
              data={scope.state[ReceivableTabDataStateNames[scope.tabIndex]] || [] }
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.textModal(false)}
        width={1400}
        height={800}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>미수내역</LayoutTitle>
          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'cltnm',
                  text: scope.tabIndex === 0 ? '거래처명' : '현장명',
                  width: 21,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      단기미수: {scope.state?.shotmis_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {scope.tabIndex === 0 ? x.cltnm : x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misdate',
                  text: '청구일자',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misnum',
                  text: '번호',
                  width: 6,
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 24,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      장기미수: {scope.state?.longmis3_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '청구금액',
                  width: 9,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      합계: {scope.state?.p_misamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'iamt',
                  text: '입금액',
                  width: 9,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state?.iamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '미수잔액',
                  width: 9,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state?.p_resuamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'longmisnm',
                  text: '장기미수구분',
                  width: 14,
                  render: (x) => (
                    x.resuamt !== '0'
                      ? <FlexLayout
                        justify="left"
                        align="center"
                        style={x.longmisnm.includes('장기미수') ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                      >
                        {x.longmisnm}
                      </FlexLayout>
                      : <FlexLayout
                        justify="center"
                        align="center"
                      >
                      </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <LayoutTitle>상세내역</LayoutTitle>
          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'cltnm',
                  text: scope.tabIndex === 0 ? '거래처명' : '현장명',
                  width: 22,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      단기미수: {scope.state?.shotmis_tot2}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {scope.tabIndex === 0 ? x.cltnm : x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misdate',
                  text: '발생일자',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misnum',
                  text: '번호',
                  width: 6,
                },
                {
                  id: 'rcvdate',
                  text: '입금일자',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.rcvdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 18,
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-red)' }}>
                      장기미수: {scope.state?.longmis3_tot2}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '청구금액',
                  width: 9,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      >
                      합계: {scope.state?.misamt_tot2}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'rcvamt',
                  text: '입금액',
                  width: 9,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                     >
                      {scope.state?.rcvamt_tot2}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rcvamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resuamt',
                  text: '미수잔액',
                  width: 9,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state?.resuamt_tot2}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'longmisnm',
                  text: '장기미수구분',
                  width: 14,
                  render: (x) => (
                    x.resuamt !== '0'
                      ? <FlexLayout
                        justify="left"
                        align="center"
                        style={x.longmisnm.includes('장기미수') ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                      >
                        {x.longmisnm}
                      </FlexLayout>
                      : <FlexLayout
                        justify="center"
                        align="center"
                      >
                      </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData2}
              infinityHandler={scope.infinity4}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button
                onClick={() => scope.modalExcelEvent()}
                style={{ width: 100 }}
              >
                EXCEL 자료
              </Button>

              <Button
                onClick={() => scope.modalPrintEvent()}
                style={{ width: 100 }}
              >
                출력
              </Button>

              <Button
                onClick={() => scope.textModal(false)}
                style={{ width: 100 }}
              >
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
