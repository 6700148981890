import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdReorder,
  MdPublish,
  MdSentimentVeryDissatisfied,
  MdBusiness,
} from 'react-icons/md';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  TableLayoutHeader,
} from '../../../../constants';
import {
  GovermentItemModel,
  GovermentCommonModel,
  GovermentOptionModel,
  CompanyItemModel,
  CompanyCommonModel,
  CompanyOptionModel,
} from './models';
import { TabModel } from '../../../../models/component';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { TextBox } from '../../../../components/forms/TextBox';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ItemTemplate } from './Item.template';
import { InfinityRetrieve, SearchBinding } from '../../../../models/common';
import { ComboBox, ComboBoxModel } from '../../../../components/forms/ComboBox';
import { FormatNumberTextBox } from '../../../../components/forms/FormatNumberTextBox';
import { Today } from '../../../../utils/time';
import { PageComponent } from '../../../../utils';

export enum ItemTabId {
  G_Item,
  G_Common,
  G_Option,
  C_Item,
  C_Common,
  C_Option,
}

export const ItemTabTitles = [
  '관급품목',
  '관급승강기-기본단가',
  '관급승강기-옵션단가',
  '사급품목',
  '사급승강기-기본단가',
  '사급승강기-옵션단가',
];

export const ItemTabModels = [
  GovermentItemModel,
  GovermentCommonModel,
  GovermentOptionModel,
  CompanyItemModel,
  CompanyCommonModel,
  CompanyOptionModel,
];

export const ItemTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
];

export const ItemTabDataStateNames = [
  'g_Items',
  'g_Commons',
  'g_Options',
  'c_Items',
  'c_Commons',
  'c_Options',
];

export const ItemTabUpdatesStateNames = [
  'g_ItemUpdates',
  'g_CommonUpdates',
  'g_OptionUpdates',
  'c_ItemUpdates',
  'c_CommonUpdates',
  'c_OptionUpdates',
];

export const ItemTabFocusedStateNames = [
  'g_ItemsFocused',
  'g_CommonsFocused',
  'g_OptionsFocused',
  'c_ItemsFocused',
  'c_CommonsFocused',
  'c_OptionsFocused',
];

export interface ItemState {
  // 관급품목
  g_Items?: Array<GovermentItemModel>;
  g_ItemUpdates?: Array<GovermentItemModel>;
  g_ItemsFocused?: GovermentItemModel;

  // 관급승강기-기본단가
  g_Commons?: Array<GovermentCommonModel>;
  g_CommonUpdates?: Array<GovermentCommonModel>;
  g_CommonsFocused?: GovermentCommonModel;

  // 관급승강기-옵션단가
  g_Options?: Array<GovermentOptionModel>;
  g_OptionUpdates?: Array<GovermentOptionModel>;
  g_OptionsFocused?: GovermentOptionModel;

  // 사급품목
  c_Items?: Array<CompanyItemModel>;
  c_ItemUpdates?: Array<CompanyItemModel>;
  c_ItemsFocused?: CompanyItemModel;

  // 사급승강기-기본단가
  c_Commons?: Array<CompanyCommonModel>;
  c_CommonUpdates?: Array<CompanyCommonModel>;
  c_CommonsFocused?: CompanyCommonModel;

  // 사급승강기-옵션단가
  c_Options?: Array<CompanyOptionModel>;
  c_OptionUpdates?: Array<CompanyOptionModel>;
  c_OptionsFocused?: CompanyOptionModel;

  focusedTab?: TabModel;
  year?: string;
  searchQuery?: string;
  jeoban?: string;
  nperson?: string;
  thickness?: string;
  spjangcd?: string;
}

/**
 * 컨트롤러
 * @window w_tb_da450
 * @category 설치품목등록
 */
export class Item extends PageComponent<PageProps, ItemState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = ItemTabId;
    const titles = ItemTabTitles;

    this.tabs = [
      new TabModel(id.G_Item.toString(), MdReorder, titles[id.G_Item]),
      new TabModel(id.G_Common.toString(), MdPublish, titles[id.G_Common]),
      new TabModel(id.G_Option.toString(), MdSentimentVeryDissatisfied, titles[id.G_Option]),
      new TabModel(id.C_Item.toString(), MdBusiness, titles[id.C_Item]),
      new TabModel(id.C_Common.toString(), MdPublish, titles[id.C_Common]),
      new TabModel(id.C_Option.toString(), MdSentimentVeryDissatisfied, titles[id.C_Option]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      searchQuery: '',
      jeoban: '%',
      nperson: '%',
      thickness: '%',
      data: [],
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 관급품목
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pcode}
              onChange={(value) => rowUpdate({ pcode: value })}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pname}
              onChange={(value) => rowUpdate({ pname: value })}
            />
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.psize}
              onChange={(value) => rowUpdate({ psize: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'lk_gubun',
          text: '단가연결구분',
          width: 40,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.lk_gubun}
              data={[
                { value: '0', remark: '기본단가' },
                { value: '1', remark: '옵션단가' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ lk_gubun: option.value })
              }
            />
          ),
        },
        {
          id: 'lk_pcode',
          text: '단가연결품목코드',
          width: 40,
          color: 'var(--color-red)',
          render: (x, rowUpdate) => (
            <TextBox
              textAlign="center"
              value={x.lk_pcode}
              onChange={(value) => rowUpdate({ lk_pcode: value })}
              bindSearchModal={new SearchBinding(
                this.props.modalStore,
                'TB_DA450_DEFAULT0',
                {},
                true,
                (item) => {
                  rowUpdate({ lk_pcode: item.cd, lk_pname: item.uv_arg1 });
                },
              )}
            />
          ),
        },
        {
          id: 'lk_pname',
          text: '연결품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.lk_pname}
              onChange={(value) => rowUpdate({ lk_pname: value })}
            />
          ),
        },
        {
          id: 'lk_psize',
          text: '연결규격',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.lk_psize}
              onChange={(value) => rowUpdate({ lk_psize: value })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 관급승강기-기본단가
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'jeoban',
          text: '제어반',
          width: 30,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.jeoban}
              style={{ color: x.jeoban === '01' ? 'var(--color-red)' : 'var(--color-blue)' }}
              data={[
                { value: '01', remark: 'MR' },
                { value: '02', remark: 'MRL' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ jeoban: option.value })}
            />
          ),
        },
        {
          id: 'nperson',
          text: '인승',
          width: 15,
          render: (x, rowUpdate) => (
        <ComboBox
          value={x.nperson}
          data={[
            { value: 'P06', remark: '6인승' },
            { value: 'P08', remark: '8인승' },
            { value: 'P09', remark: '9인승' },
            { value: 'P10', remark: '10인승' },
            { value: 'P11', remark: '11인승' },
            { value: 'P13', remark: '13인승' },
            { value: 'P15', remark: '15인승' },
            { value: 'P17', remark: '17인승' },
            { value: 'P20', remark: '20인승' },
            { value: 'P24', remark: '24인승' },
          ].map((y) => new ComboBoxModel(y.value, y.remark))}
          onSelect={(option) => rowUpdate({ nperson: option.value })}
        />
          ),
        },
        {
          id: 'thickness',
          text: '두께',
          width: 20,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.thickness}
              data={[
                { value: '01', remark: '2STOP' },
                { value: '02', remark: '2STOP' },
                { value: '03', remark: '2STOP' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ thickness: option.value })}
            />
          ),
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 30,
        },
        {
          id: 'pname',
          text: '품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pname}
              onChange={(value) => rowUpdate({ pname: value })}
            />
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 75,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.psize}
              onChange={(value) => rowUpdate({ psize: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 관급승강기-옵션단가
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'jeoban',
          text: '제어반',
          width: 30,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.jeoban}
              style={{ color: x.jeoban === '01' ? 'var(--color-red)' : 'var(--color-blue)' }}
              data={[
                { value: '01', remark: 'MR' },
                { value: '02', remark: 'MRL' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ jeoban: option.value })}
            />
          ),
        },
        {
          id: 'nperson',
          text: '인승',
          width: 15,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.nperson}
              data={[
                { value: 'P06', remark: '6인승' },
                { value: 'P08', remark: '8인승' },
                { value: 'P09', remark: '9인승' },
                { value: 'P10', remark: '10인승' },
                { value: 'P11', remark: '11인승' },
                { value: 'P13', remark: '13인승' },
                { value: 'P15', remark: '15인승' },
                { value: 'P17', remark: '17인승' },
                { value: 'P20', remark: '20인승' },
                { value: 'P24', remark: '24인승' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ nperson: option.value })}
            />
          ),
        },
        {
          id: 'thickness',
          text: '두께',
          width: 15,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.thickness}
              data={[
                { value: '01', remark: '2STOP' },
                { value: '02', remark: '2STOP' },
                { value: '03', remark: '2STOP' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ thickness: option.value })}
            />
          ),
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pcode}
              onChange={(value) => rowUpdate({ pcode: value })}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pname}
              onChange={(value) => rowUpdate({ pname: value })}
            />
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.psize}
              onChange={(value) => rowUpdate({ psize: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 40,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 사급품목
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pcode}
              onChange={(value) => rowUpdate({ pcode: value })}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pname}
              onChange={(value) => rowUpdate({ pname: value })}
            />
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 90,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.psize}
              onChange={(value) => rowUpdate({ psize: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'lk_gubun',
          text: '단가연결구분',
          width: 40,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.lk_gubun}
              data={[
                { value: '0', remark: '기본단가' },
                { value: '1', remark: '옵션단가' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ lk_gubun: option.value })}
            />
          ),
        },
        {
          id: 'lk_pcode',
          text: '단가연결품목코드',
          width: 40,
          color: 'var(--color-red)',
          render: (x, rowUpdate) => (
            <TextBox
              textAlign="center"
              value={x.lk_pcode}
              onChange={(value) => rowUpdate({ lk_pcode: value })}
              bindSearchModal={new SearchBinding(
                this.props.modalStore,
                'TB_DA450_DEFAULT1',
                {},
                true,
                (item) => {
                  rowUpdate({ lk_pcode: item.cd, lk_pname: item.cdnm });
                },
              )}
            />
          ),
        },
        {
          id: 'lk_pname',
          text: '연결품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.lk_pname}
              onChange={(value) => rowUpdate({ lk_pname: value })}
            />
          ),
        },
        {
          id: 'lk_psize',
          text: '연결규격',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.lk_psize}
              onChange={(value) => rowUpdate({ lk_psize: value })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 사급승강기-기본단가
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'jeoban',
          text: '제어반',
          width: 30,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.jeoban}
              style={{ color: x.jeoban === '01' ? 'var(--color-red)' : 'var(--color-blue)' }}
              data={[
                { value: '01', remark: 'MR' },
                { value: '02', remark: 'MRL' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ jeoban: option.value })}
            />
          ),
        },
        {
          id: 'nperson',
          text: '인승',
          width: 15,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.nperson}
              data={[
                { value: 'P06', remark: '6인승' },
                { value: 'P08', remark: '8인승' },
                { value: 'P09', remark: '9인승' },
                { value: 'P10', remark: '10인승' },
                { value: 'P11', remark: '11인승' },
                { value: 'P13', remark: '13인승' },
                { value: 'P15', remark: '15인승' },
                { value: 'P17', remark: '17인승' },
                { value: 'P20', remark: '20인승' },
                { value: 'P24', remark: '24인승' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ nperson: option.value })}
            />
          ),
        },
        {
          id: 'thickness',
          text: '두께',
          width: 15,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.thickness}
              style={{ color: x.thickness === '01' ? 'var(--color-blue)' : 'var(--color-red)' }}
              data={[
                { value: '01', remark: '304-1.5T' },
                { value: '02', remark: '334-1.2T ' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ thickness: option.value })}
            />
          ),
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pcode}
              onChange={(value) => rowUpdate({ pcode: value })}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pname}
              onChange={(value) => rowUpdate({ pname: value })}
            />
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.psize}
              onChange={(value) => rowUpdate({ psize: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 40,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],

      // 사급승강기-옵션단가
      [
        {
          id: 'seq',
          text: '순번',
          width: 20,
        },
        {
          id: 'jeoban',
          text: '제어반',
          width: 30,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.jeoban}
              style={{ color: x.jeoban === '01' ? 'var(--color-red)' : 'var(--color-blue)' }}
              data={[
                { value: '01', remark: 'MR' },
                { value: '02', remark: 'MRL' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ jeoban: option.value })}
            />
          ),
        },
        {
          id: 'nperson',
          text: '인승',
          width: 15,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.nperson}
              data={[
                { value: 'P06', remark: '6인승' },
                { value: 'P08', remark: '8인승' },
                { value: 'P09', remark: '9인승' },
                { value: 'P10', remark: '10인승' },
                { value: 'P11', remark: '11인승' },
                { value: 'P13', remark: '13인승' },
                { value: 'P15', remark: '15인승' },
                { value: 'P17', remark: '17인승' },
                { value: 'P20', remark: '20인승' },
                { value: 'P24', remark: '24인승' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ nperson: option.value })}
            />
          ),
        },
        {
          id: 'thickness',
          text: '두께',
          width: 15,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.thickness}
              data={[
                { value: '01', remark: '304-1.5T' },
                { value: '02', remark: '334-1.2T ' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ thickness: option.value })}
            />
          ),
        },
        {
          id: 'pcode',
          text: '품명코드',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pcode}
              onChange={(value) => rowUpdate({ pcode: value })}
            />
          ),
        },
        {
          id: 'pname',
          text: '품명',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pname}
              onChange={(value) => rowUpdate({ pname: value })}
            />
          ),
        },
        {
          id: 'psize',
          text: '규격',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.psize}
              onChange={(value) => rowUpdate({ psize: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              textAlign="right"
              ref={ref}
              value={x.uamt}
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 50,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ],
    ];
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[ItemTabId.G_Item]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `${ItemTabFunctionNames[i]}_new`,
    );

    // @ts-ignore
    data && this.setState({
      [ItemTabDataStateNames[i]]: [
        new ItemTabModels[i](data, true),
        // @ts-ignore
        ...this.state[ItemTabDataStateNames[i]],
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    await api.fxSave(
      `${ItemTabFunctionNames[i]}_save`,
      {
        // @ts-ignore
        items: this.state[ItemTabUpdatesStateNames[i]],
      },
      true,
    );
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[ItemTabFocusedStateNames[i]];

    if (!item) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case ItemTabId.G_Item:
        text = `${item.pcode} - ${item.pname}`;
        break;

      case ItemTabId.G_Common:
        text = `품목코드:${item.pcode}  
        품명:${item.pname}`;
        break;

      case ItemTabId.G_Option:
        text = `품목코드:${item.pcode} - 품명:${item.pname}`;
        break;

      case ItemTabId.C_Item:
        text = `품목코드:${item.pcode}  품명:${item.pname}`;
        break;

      case ItemTabId.C_Common:
        text = `${item.pcode} - ${item.pname}`;
        break;

      case ItemTabId.C_Option:
        text = `${item.pcode} - ${item.pname}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }

    await api.fxDelete(
      `${ItemTabFunctionNames[i]}_delete`,
      text,
      // @ts-ignore
      this.state[ItemTabFocusedStateNames[i]],
    );

    this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (i === ItemTabId.G_Item) {
      await api.fxPrint(
        `${ItemTabFunctionNames[i]}_print`,
        {
          spjangcd: this.props.publicStore.user.spjangcd,
          year: '',
          as_nm: '',
          jeoban: '',
          nperson: '',
          thickness: '',
        },
      );
    }

    if (i === ItemTabId.G_Common) {
      await api.fxPrint(
        `${ItemTabFunctionNames[i]}_print`,
        {
          spjangcd: this.props.publicStore.user.spjangcd,
          year: this.state.year,
          as_nm: this.state.searchQuery,
          jeoban: this.state.jeoban,
          nperson: this.state.nperson,
          thickness: this.state.thickness,
        },
      );
    }

    if (i === ItemTabId.G_Option) {
      await api.fxPrint(
        `${ItemTabFunctionNames[i]}_print`,
        {
          spjangcd: this.props.publicStore.user.spjangcd,
          year: this.state.year,
          as_nm: this.state.searchQuery,
          jeoban: '',
          nperson: this.state.nperson,
          thickness: '',
        },
      );
    }

    if (i === ItemTabId.C_Item) {
      await api.fxPrint(
        `${ItemTabFunctionNames[i]}_print`,
        {
          spjangcd: this.props.publicStore.user.spjangcd,
          year: '',
          as_nm: '',
          jeoban: '',
          nperson: '',
          thickness: '',
        },
      );
    }

    if (i === ItemTabId.C_Common) {
      await api.fxPrint(
        `${ItemTabFunctionNames[i]}_print`,
        {
          spjangcd: this.props.publicStore.user.spjangcd,
          year: this.state.year,
          as_nm: this.state.searchQuery,
          jeoban: this.state.jeoban,
          nperson: this.state.nperson,
          thickness: this.state.thickness,
        },
      );
    }

    if (i === ItemTabId.C_Option) {
      await api.fxPrint(
        `${ItemTabFunctionNames[i]}_print`,
        {
          spjangcd: this.props.publicStore.user.spjangcd,
          year: this.state.year,
          as_nm: this.state.searchQuery,
          jeoban: '',
          nperson: this.state.nperson,
          thickness: '',
        },
      );
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    await api.fxExcel(
      `${ItemTabFunctionNames[i]}_excel`,
      {
        spjangcd: this.props.publicStore.user.spjangcd,
        year: this.state.year,
        as_nm: this.state.searchQuery,
        jeoban: this.state.jeoban,
        nperson: this.state.nperson,
        thickness: this.state.thickness,
      },
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[ItemTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        this.setState({
          [ItemTabUpdatesStateNames[i]]: [],
          [ItemTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: ItemTabId, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        spjangcd: this.props.publicStore.user.spjangcd,
        year: this.state.year,
        as_nm: this.state.searchQuery,
        jeoban: this.state.jeoban,
        nperson: this.state.nperson,
        thickness: this.state.thickness,
      },
      (params) => api.fxExec(
        `${ItemTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [ItemTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[ItemTabDataStateNames[i]],
                ...items.map((x: any) => new ItemTabModels[i](x))],
          }, () => this.table.current?.update(false));
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [ItemTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieve();
        await this.table.current?.update(true);

        if (i === ItemTabId.G_Item && this.state.g_Items?.length) {
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [ItemTabDataStateNames[i]]: [],
      [ItemTabUpdatesStateNames[i]]: [],
      [ItemTabFocusedStateNames[i]]: undefined,
    });
    if (!autoLoad) return;
    await this.infinity?.retrieve();
    await this.table.current?.update(true);

    if (i === ItemTabId.G_Item && this.state.g_Items?.length) {
      this.table.current?.setFocus(0, 0);
    }
  }

  @computed
  get tabIndex(): ItemTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [ItemTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [ItemTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <ItemTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
