import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { YearArticleModel } from './YearArticle.model';
import { YearArticleTemplate } from './YearArticle.template';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface YearArticleState {
  // search
  year: string;
  spjangcd: string;
  artcd: string;
  artnm: string;
  gubun: string;
  gubuns: Array<any>;

  // data
  data: Array<YearArticleModel>;
  focused?: YearArticleModel;

  // trail
  totbemon_tot: string;
  totmijmon_tot: string;
  totsndmon_tot: string;
  tottmijmon_tot: string;
  bemon01_tot: string;
  bemon02_tot: string;
  bemon03_tot: string;
  bemon04_tot: string;
  bemon05_tot: string;
  bemon06_tot: string;
  bemon07_tot: string;
  bemon08_tot: string;
  bemon09_tot: string;
  bemon10_tot: string;
  bemon11_tot: string;
  bemon12_tot: string;
  sndmon01_tot: string;
  sndmon02_tot: string;
  sndmon03_tot: string;
  sndmon04_tot: string;
  sndmon05_tot: string;
  sndmon06_tot: string;
  sndmon07_tot: string;
  sndmon08_tot: string;
  sndmon09_tot: string;
  sndmon10_tot: string;
  sndmon11_tot: string;
  sndmon12_tot: string;
  tmijmon01_tot: string;
  tmijmon02_tot: string;
  tmijmon03_tot: string;
  tmijmon04_tot: string;
  tmijmon05_tot: string;
  tmijmon06_tot: string;
  tmijmon07_tot: string;
  tmijmon08_tot: string;
  tmijmon09_tot: string;
  tmijmon10_tot: string;
  tmijmon11_tot: string;
  tmijmon12_tot: string;
  mijmon01_tot: string;
  mijmon02_tot: string;
  mijmon03_tot: string;
  mijmon04_tot: string;
  mijmon05_tot: string;
  mijmon06_tot: string;
  mijmon07_tot: string;
  mijmon08_tot: string;
  mijmon09_tot: string;
  mijmon10_tot: string;
  mijmon11_tot: string;
  mijmon12_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca640w_03
 * @category 년항목별 매입/지급현황
 */
export class YearArticle extends PageComponent<PageProps, YearArticleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      year: Today.year(),
      spjangcd: 'ZZ',
      artcd: '',
      gubun: '%',

      // data
      data: [],
      data2: [],
      gubuns: [],

      // trail
      totbemon_tot: '0',
      totmijmon_tot: '0',
      totsndmon_tot: '0',
      tottmijmon_tot: '0',
      bemon01_tot: '0',
      bemon02_tot: '0',
      bemon03_tot: '0',
      bemon04_tot: '0',
      bemon05_tot: '0',
      bemon06_tot: '0',
      bemon07_tot: '0',
      bemon08_tot: '0',
      bemon09_tot: '0',
      bemon10_tot: '0',
      bemon11_tot: '0',
      bemon12_tot: '0',
      sndmon01_tot: '0',
      sndmon02_tot: '0',
      sndmon03_tot: '0',
      sndmon04_tot: '0',
      sndmon05_tot: '0',
      sndmon06_tot: '0',
      sndmon07_tot: '0',
      sndmon08_tot: '0',
      sndmon09_tot: '0',
      sndmon10_tot: '0',
      sndmon11_tot: '0',
      sndmon12_tot: '0',
      tmijmon01_tot: '0',
      tmijmon02_tot: '0',
      tmijmon03_tot: '0',
      tmijmon04_tot: '0',
      tmijmon05_tot: '0',
      tmijmon06_tot: '0',
      tmijmon07_tot: '0',
      tmijmon08_tot: '0',
      tmijmon09_tot: '0',
      tmijmon10_tot: '0',
      tmijmon11_tot: '0',
      tmijmon12_tot: '0',
      mijmon01_tot: '0',
      mijmon02_tot: '0',
      mijmon03_tot: '0',
      mijmon04_tot: '0',
      mijmon05_tot: '0',
      mijmon06_tot: '0',
      mijmon07_tot: '0',
      mijmon08_tot: '0',
      mijmon09_tot: '0',
      mijmon10_tot: '0',
      mijmon11_tot: '0',
      mijmon12_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const data = await api.dropdown('wf_dd_ca510_113_02');
    if (!data) return;
    this.setState({ gubuns: data.items });
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        spjangcd: this.state.spjangcd,
        artcd: this.state.artcd,
        gubun: this.state.gubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new YearArticleModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['beamt', 'tottmijamt'],
        [this.state.focused?.beamt, this.state.focused?.tottmijamt], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.data[0]);
      }
      this.SS({
        totbemon_tot: this.infinity?.box?.totbemon_tot || '0',
        totmijmon_tot: this.infinity?.box?.totmijmon_tot || '0',
        totsndmon_tot: this.infinity?.box?.totsndmon_tot || '0',
        tottmijmon_tot: this.infinity?.box?.tottmijmon_tot || '0',
        bemon01_tot: this.infinity?.box?.bemon01_tot || '0',
        bemon02_tot: this.infinity?.box?.bemon02_tot || '0',
        bemon03_tot: this.infinity?.box?.bemon03_tot || '0',
        bemon04_tot: this.infinity?.box?.bemon04_tot || '0',
        bemon05_tot: this.infinity?.box?.bemon05_tot || '0',
        bemon06_tot: this.infinity?.box?.bemon06_tot || '0',
        bemon07_tot: this.infinity?.box?.bemon07_tot || '0',
        bemon08_tot: this.infinity?.box?.bemon08_tot || '0',
        bemon09_tot: this.infinity?.box?.bemon09_tot || '0',
        bemon10_tot: this.infinity?.box?.bemon10_tot || '0',
        bemon11_tot: this.infinity?.box?.bemon11_tot || '0',
        bemon12_tot: this.infinity?.box?.bemon12_tot || '0',
        sndmon01_tot: this.infinity?.box?.sndmon01_tot || '0',
        sndmon02_tot: this.infinity?.box?.sndmon02_tot || '0',
        sndmon03_tot: this.infinity?.box?.sndmon03_tot || '0',
        sndmon04_tot: this.infinity?.box?.sndmon04_tot || '0',
        sndmon05_tot: this.infinity?.box?.sndmon05_tot || '0',
        sndmon06_tot: this.infinity?.box?.sndmon06_tot || '0',
        sndmon07_tot: this.infinity?.box?.sndmon07_tot || '0',
        sndmon08_tot: this.infinity?.box?.sndmon08_tot || '0',
        sndmon09_tot: this.infinity?.box?.sndmon09_tot || '0',
        sndmon10_tot: this.infinity?.box?.sndmon10_tot || '0',
        sndmon11_tot: this.infinity?.box?.sndmon11_tot || '0',
        sndmon12_tot: this.infinity?.box?.sndmon12_tot || '0',
        tmijmon01_tot: this.infinity?.box?.tmijmon01_tot || '0',
        tmijmon02_tot: this.infinity?.box?.tmijmon02_tot || '0',
        tmijmon03_tot: this.infinity?.box?.tmijmon03_tot || '0',
        tmijmon04_tot: this.infinity?.box?.tmijmon04_tot || '0',
        tmijmon05_tot: this.infinity?.box?.tmijmon05_tot || '0',
        tmijmon06_tot: this.infinity?.box?.tmijmon06_tot || '0',
        tmijmon07_tot: this.infinity?.box?.tmijmon07_tot || '0',
        tmijmon08_tot: this.infinity?.box?.tmijmon08_tot || '0',
        tmijmon09_tot: this.infinity?.box?.tmijmon09_tot || '0',
        tmijmon10_tot: this.infinity?.box?.tmijmon10_tot || '0',
        tmijmon11_tot: this.infinity?.box?.tmijmon11_tot || '0',
        tmijmon12_tot: this.infinity?.box?.tmijmon12_tot || '0',
        mijmon01_tot: this.infinity?.box?.mijmon01_tot || '0',
        mijmon02_tot: this.infinity?.box?.mijmon02_tot || '0',
        mijmon03_tot: this.infinity?.box?.mijmon03_tot || '0',
        mijmon04_tot: this.infinity?.box?.mijmon04_tot || '0',
        mijmon05_tot: this.infinity?.box?.mijmon05_tot || '0',
        mijmon06_tot: this.infinity?.box?.mijmon06_tot || '0',
        mijmon07_tot: this.infinity?.box?.mijmon07_tot || '0',
        mijmon08_tot: this.infinity?.box?.mijmon08_tot || '0',
        mijmon09_tot: this.infinity?.box?.mijmon09_tot || '0',
        mijmon10_tot: this.infinity?.box?.mijmon10_tot || '0',
        mijmon11_tot: this.infinity?.box?.mijmon11_tot || '0',
        mijmon12_tot: this.infinity?.box?.mijmon12_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      spjangcd: this.state.spjangcd,
      artcd: this.state.artcd,
      gubun: this.state.gubun,
    });
  }

  @action
  onRowFocusEvent(item: YearArticleModel) {
    this.setState({ focused: item });
  }

  render() {
    return (
      <YearArticleTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
