import { computed } from 'mobx';

/**
 * 모델
 * @window w_p1110
 * @category 직위코드
 */
export class PositionCodeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직위코드
   *
   * **data type** varchar(2)
   */
  rspcd: string;

  /**
   * 직위명
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 적용일
   *
   * **data type** varchar(8)
   */
  sdate: string;

  /**
   * 만료일
   *
   * **data type** varchar(8)
   */
  endate: string;

  /**
   * 적용여부
   *
   * **data type** varchar(1)
   */
  applyn: string;

  /**
   * 대표이사
   *
   * **data type** varchar(1)
   */
  preyn: string;

  /**
   * 임원
   *
   * **data type** varchar(1)
   */
  offiyn: string;

  /**
   * 출력순서
   *
   * **data type** int
   */
  prtseq: string;

  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.sdate = data.sdate || '';
    this.endate = data.endate || '';
    this.applyn = data.applyn || '';
    this.preyn = data.preyn || '';
    this.offiyn = data.offiyn || '';
    this.prtseq = data.prtseq || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
