import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e512
 * @category 당직근무보고서
 */

export class WorkReportModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   * 통신담당
   *
   * **data type** varchar(20)
   */
  readonly telperid: string;

  /**
   * 당직자코드
   *
   * **data type** varchar(255)
   */
  readonly dperid: string;

  /**
   * 설치작업
   *
   * **data type** varchar(255)
   */
  readonly sremark: string;

  /**
   * 공사작업
   *
   * **data type** varchar(255)
   */
  readonly gremark: string;

  /**
   * 접수건수
   *
   * **data type** varchar(10)
   */
  readonly rececnt: string;

  /**
   * 접수비고
   *
   * **data type** varchar(255)
   */
  readonly receremark: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 결재자1
   *
   * **data type** varchar(8)
   */
  readonly appdate1: string;

  /**
   * 결재자2
   *
   * **data type** varchar(8)
   */
  readonly appdate2: string;

  /**
   * 결재자3
   *
   * **data type** varchar(8)
   */
  readonly appdate3: string;

  /**
   * 결재자4
   *
   * **data type** varchar(8)
   */
  readonly appdate4: string;

  /**
   * 결재자5
   *
   * **data type** varchar(8)
   */
  readonly appdate5: string;

  /**
   * 특이사항
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 안전지시사항
   *
   * **data type** varchar(0)
   */
  readonly saferemark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inpernm: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly rspcd: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(40)
   */
  readonly divicd: string;

  /**
   * 통신담당2
   *
   * **data type** varchar(30)
   */
  readonly telpernm: string;

  /**
   * 통신담당2
   *
   * **data type** varchar(30)
   */
  readonly telpercd: string;

  /**
   * 통신담당2
   *
   * **data type** varchar(30)
   */
  readonly telrspnm: string;

  /**
   * 부서
   *
   * **data type** varchar(40)
   */
  readonly teldivicd: string;

  /**
   * 부서
   *
   * **data type** varchar(40)
   */
  readonly teldivinm: string;

  /**
   * 통신담당2
   *
   * **data type** varchar(30)
   */
  readonly picflag: string;

  /**
   * 부서
   *
   * **data type** varchar(40)
   */
  readonly refer: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rptdate = data.rptdate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.telperid = data.telperid || '';
    this.dperid = data.dperid || '';
    this.sremark = data.sremark || '';
    this.gremark = data.gremark || '';
    this.rececnt = data.rececnt || '';
    this.receremark = data.receremark || '';
    this.apptime = data.apptime || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.appdate1 = data.appdate1 || '';
    this.appdate2 = data.appdate2 || '';
    this.appdate3 = data.appdate3 || '';
    this.appdate4 = data.appdate4 || '';
    this.appdate5 = data.appdate5 || '';
    this.remark = Fix.newline(data.remark) || '';
    this.saferemark = Fix.newline(data.saferemark) || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.indate = data.indate || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.divicd = data.divicd || '';
    this.telpernm = data.telpernm || '';
    this.telpercd = data.telpercd || '';
    this.telrspnm = data.telrspnm || '';
    this.teldivicd = data.teldivicd || '';
    this.teldivinm = data.teldivinm || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
