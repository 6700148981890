import { MenuModel } from '../../models';
import { Category } from '../../constants';

export default class Menu {
  static parse(data: any): any {
    return Menu.getChild(data, new MenuModel('#', 'MdInfo'), '1', '');
  }

  static getChild(
    data: any,
    parent: MenuModel,
    level: string,
    parentSort: string,
  ): any {
    const menus = data
      .filter((x: any) => x.mlevel === level && x.msort.startsWith(parentSort))
      .sort((x: any, y: any) => (x.msort < y.msort ? -1 : 1));

    if (!menus || !menus.length) return [];

    return menus.map((x: any) => {
      const p = new MenuModel(
        x.msort,
        Menu.getIcon(x.msort),
        x.mtext,
        `/${x.windowname}`,
        x.mlevel,
        Menu.getCategory(x.msort),
        false,
        parent,
      );

      p.children = this.getChild(data, p, `${Number(x.mlevel) + 1}`, x.msort);
      return p;
    });
  }

  static getIcon(msort: string): string {
    switch (msort.substr(0, 1)) {
      case 'A':
        return 'MdInfo';
      case 'B':
        return 'MdPeople';
      case 'C':
        return 'MdMonetizationOn';
      case 'D':
        return 'MdExtension';
      case 'E':
        return 'MdBuild';
      case 'F':
        return 'MdAssignment';
      case 'G':
        return 'MdSettingsInputComponent';
      case 'H':
        return 'MdAssessment';
      default:
        return 'MdInfo';
    }
  }

  static getCategory(msort: string): number {
    switch (msort.substr(0, 1)) {
      case 'A':
        return Category.GENERAL;
      case 'B':
        return Category.HR;
      case 'C':
        return Category.ACCOUNTING;
      case 'D':
        return Category.INSTALL;
      case 'E':
        return Category.MAINTENANCE;
      case 'F':
        return Category.EMAIL;
      case 'G':
        return Category.MATERIAL;
      case 'H':
        return Category.PLAN;
      default:
        return Category.COMMON;
    }
  }
}
