import * as React from 'react';
import { action } from 'mobx';
import { RefObject } from 'react';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import StatusModel from './models/StatusModel';
import StatusDetailModel from './models/StatusDetailModel';
import StatusMonthModel from './models/StatusMonthModel';
import { StatusTemplate } from './Status.template';
import { PageComponent } from '../../../../utils';
import { GridLayout, GridLayoutAdditionalHeader } from '../../../../components';
import { Date8 } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { StatusMonthModalModel } from './models/StatusMonthModal.model';


export const Gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '기타수입' },
  { value: '18', remark: '기타환불' },
];

export const Receflags = [
  { value: '0', remark: '미수령' },
  { value: '1', remark: '어플수령' },
  { value: '2', remark: '파일수령' },
];

export const Comps = [
  { value: '0', remark: '견적' },
  { value: '1', remark: '공사' },
  { value: '2', remark: '매출' },
  { value: '3', remark: '폐기' },
  { value: '5', remark: '수금' },
];

export const KtnetStates = [
  { value: '%', remark: '전체' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'B', remark: '발송전' },
  { value: 'A', remark: 'KTNET접수' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
];


interface StatusState {
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  compyn: string;
  searchQuery: string;
  popupSearchQuery: string;
  wkactcd: string;
  wkactnm: string;
  projno: string;
  projnm: string;
  gubun: string;
  firstFlag: boolean;
  gubuns: Array<any>;
  gubuns2: Array<any>;
  recivstatus: Array<any>;

  dataPerid: Array<StatusModel>;
  dataDetail: Array<StatusDetailModel>;
  dataMonth: Array<StatusMonthModel>;
  focused?: any;

  tab1_cnt_tot?: string;
  tab1_costamt_tot?: string;
  tab1_misamt_tot?: string;

  tab_2_costamt_tot?: string;
  tab_2_samt_tot?: string;
  tab_2_tamt_tot?: string;

  tab_3_tot_compmon_tot?: string;
  tab_3_tot_compyul_tot?: string;
  tab_3_tot_mismon_tot?: string;
  tab_3_tot_mon_tot?: string;
  tab_3_tot_musang_tot?: string;
  tab_3_tot_notmismon_tot?: string;
  tab_3_tot_rcvmon_tot?: string;

  // 견적
  tab_3_tot_mon01_tot?: string,
  tab_3_tot_mon02_tot?: string,
  tab_3_tot_mon03_tot?: string,
  tab_3_tot_mon04_tot?: string,
  tab_3_tot_mon05_tot?: string,
  tab_3_tot_mon06_tot?: string,
  tab_3_tot_mon07_tot?: string,
  tab_3_tot_mon08_tot?: string,
  tab_3_tot_mon09_tot?: string,
  tab_3_tot_mon10_tot?: string,
  tab_3_tot_mon11_tot?: string,
  tab_3_tot_mon12_tot?: string,

  // 완료
  tab_3_tot_compmon01_tot?: string;
  tab_3_tot_compmon02_tot?: string;
  tab_3_tot_compmon03_tot?: string;
  tab_3_tot_compmon04_tot?: string;
  tab_3_tot_compmon05_tot?: string;
  tab_3_tot_compmon06_tot?: string;
  tab_3_tot_compmon07_tot?: string;
  tab_3_tot_compmon08_tot?: string;
  tab_3_tot_compmon09_tot?: string;
  tab_3_tot_compmon10_tot?: string;
  tab_3_tot_compmon11_tot?: string;
  tab_3_tot_compmon12_tot?: string;

  // 성사율
  tab_3_tot_compyul01_tot?: string,
  tab_3_tot_compyul02_tot?: string,
  tab_3_tot_compyul03_tot?: string,
  tab_3_tot_compyul04_tot?: string,
  tab_3_tot_compyul05_tot?: string,
  tab_3_tot_compyul06_tot?: string,
  tab_3_tot_compyul07_tot?: string,
  tab_3_tot_compyul08_tot?: string,
  tab_3_tot_compyul09_tot?: string,
  tab_3_tot_compyul10_tot?: string,
  tab_3_tot_compyul11_tot?: string,
  tab_3_tot_compyul12_tot?: string,

  // 무상
  tab_3_tot_musang01_tot?: string,
  tab_3_tot_musang02_tot?: string,
  tab_3_tot_musang03_tot?: string,
  tab_3_tot_musang04_tot?: string,
  tab_3_tot_musang05_tot?: string,
  tab_3_tot_musang06_tot?: string,
  tab_3_tot_musang07_tot?: string,
  tab_3_tot_musang08_tot?: string,
  tab_3_tot_musang09_tot?: string,
  tab_3_tot_musang10_tot?: string,
  tab_3_tot_musang11_tot?: string,
  tab_3_tot_musang12_tot?: string,

  // 미매출
  tab_3_tot_notmismon01_tot?: string,
  tab_3_tot_notmismon02_tot?: string,
  tab_3_tot_notmismon03_tot?: string,
  tab_3_tot_notmismon04_tot?: string,
  tab_3_tot_notmismon05_tot?: string,
  tab_3_tot_notmismon06_tot?: string,
  tab_3_tot_notmismon07_tot?: string,
  tab_3_tot_notmismon08_tot?: string,
  tab_3_tot_notmismon09_tot?: string,
  tab_3_tot_notmismon10_tot?: string,
  tab_3_tot_notmismon11_tot?: string,
  tab_3_tot_notmismon12_tot?: string,

  // 매출
  tab_3_tot_mismon01_tot?: string,
  tab_3_tot_mismon02_tot?: string,
  tab_3_tot_mismon03_tot?: string,
  tab_3_tot_mismon04_tot?: string,
  tab_3_tot_mismon05_tot?: string,
  tab_3_tot_mismon06_tot?: string,
  tab_3_tot_mismon07_tot?: string,
  tab_3_tot_mismon08_tot?: string,
  tab_3_tot_mismon09_tot?: string,
  tab_3_tot_mismon10_tot?: string,
  tab_3_tot_mismon11_tot?: string,
  tab_3_tot_mismon12_tot?: string,

  // 수금
  tab_3_tot_rcvmon01_tot?: string,
  tab_3_tot_rcvmon02_tot?: string,
  tab_3_tot_rcvmon03_tot?: string,
  tab_3_tot_rcvmon04_tot?: string,
  tab_3_tot_rcvmon05_tot?: string,
  tab_3_tot_rcvmon06_tot?: string,
  tab_3_tot_rcvmon07_tot?: string,
  tab_3_tot_rcvmon08_tot?: string,
  tab_3_tot_rcvmon09_tot?: string,
  tab_3_tot_rcvmon10_tot?: string,
  tab_3_tot_rcvmon11_tot?: string,
  tab_3_tot_rcvmon12_tot?: string,

  tab_3_modalChk?: boolean,
  tab_3_modal?: boolean,
  tab_3_modalData?: Array<StatusMonthModalModel>,
  tab_3_modalTotal?: string,

  popupDataAll?: string,
  popupDataDivinm?: string,
  popupDataPernm?: string,
  popupStdate?: string,
  popupEnddate?: string,
  popupPerid?: string,
  popupPernm?: string,
  popupDivicd?: string,
  popupDivinm?: string,
  chkState?: string,
  state0?: string,
  state1?: string,
  state2?: string,
  state5?: string,
  myn?: string,
  mons?: string,
}


/**
 * 컨트롤러
 * @window w_tb_e451w
 * @category 견적현황
 */
export class Status extends PageComponent<PageProps, StatusState>
  implements PageToolEvents {
  infinityPerid?: InfinityRetrieve;

  infinityDetail?: InfinityRetrieve;

  infinityMonth?: InfinityRetrieve;

  gridPerid: RefObject<GridLayout> = React.createRef();

  gridDetail: RefObject<GridLayout> = React.createRef();

  gridMonth: RefObject<GridLayout> = React.createRef();

  tab_3_modalGrid: RefObject<GridLayout> = React.createRef();

  additionalHeader: RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalMonthHeader: RefObject<GridLayoutAdditionalHeader> = React.createRef();

  tabIndex: number = 0;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    this.state = props.state || {
      stdate: `${Date8.make().substr(0, 6)}01`,
      enddate: Date8.make(),
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      compyn: '%',
      searchQuery: '',
      popupSearchQuery: '',
      gubun: '%',
      gubuns: [],
      gubuns2: [],
      recivstatus: [],

      dataPerid: [],
      dataDetail: [],
      dataMonth: [],

      firstFlag: true,

      tab_3_tot_compmon_tot: '',
      tab_3_tot_compyul_tot: '',
      tab_3_tot_mismon_tot: '',
      tab_3_tot_mon_tot: '',
      tab_3_tot_musang_tot: '',
      tab_3_tot_notmismon_tot: '',
      tab_3_tot_rcvmon_tot: '',

      // 견적
      tab_3_tot_mon01_tot: '',
      tab_3_tot_mon02_tot: '',
      tab_3_tot_mon03_tot: '',
      tab_3_tot_mon04_tot: '',
      tab_3_tot_mon05_tot: '',
      tab_3_tot_mon06_tot: '',
      tab_3_tot_mon07_tot: '',
      tab_3_tot_mon08_tot: '',
      tab_3_tot_mon09_tot: '',
      tab_3_tot_mon10_tot: '',
      tab_3_tot_mon11_tot: '',
      tab_3_tot_mon12_tot: '',

      // 완료
      tab_3_tot_compmon01_tot: '',
      tab_3_tot_compmon02_tot: '',
      tab_3_tot_compmon03_tot: '',
      tab_3_tot_compmon04_tot: '',
      tab_3_tot_compmon05_tot: '',
      tab_3_tot_compmon06_tot: '',
      tab_3_tot_compmon07_tot: '',
      tab_3_tot_compmon08_tot: '',
      tab_3_tot_compmon09_tot: '',
      tab_3_tot_compmon10_tot: '',
      tab_3_tot_compmon11_tot: '',
      tab_3_tot_compmon12_tot: '',

      // 성사율
      tab_3_tot_compyul01_tot: '',
      tab_3_tot_compyul02_tot: '',
      tab_3_tot_compyul03_tot: '',
      tab_3_tot_compyul04_tot: '',
      tab_3_tot_compyul05_tot: '',
      tab_3_tot_compyul06_tot: '',
      tab_3_tot_compyul07_tot: '',
      tab_3_tot_compyul08_tot: '',
      tab_3_tot_compyul09_tot: '',
      tab_3_tot_compyul10_tot: '',
      tab_3_tot_compyul11_tot: '',
      tab_3_tot_compyul12_tot: '',

      tab_3_tot_musang01_tot: '',
      tab_3_tot_musang02_tot: '',
      tab_3_tot_musang03_tot: '',
      tab_3_tot_musang04_tot: '',
      tab_3_tot_musang05_tot: '',
      tab_3_tot_musang06_tot: '',
      tab_3_tot_musang07_tot: '',
      tab_3_tot_musang08_tot: '',
      tab_3_tot_musang09_tot: '',
      tab_3_tot_musang10_tot: '',
      tab_3_tot_musang11_tot: '',
      tab_3_tot_musang12_tot: '',

      // 미매출
      tab_3_tot_notmismon01_tot: '',
      tab_3_tot_notmismon02_tot: '',
      tab_3_tot_notmismon03_tot: '',
      tab_3_tot_notmismon04_tot: '',
      tab_3_tot_notmismon05_tot: '',
      tab_3_tot_notmismon06_tot: '',
      tab_3_tot_notmismon07_tot: '',
      tab_3_tot_notmismon08_tot: '',
      tab_3_tot_notmismon09_tot: '',
      tab_3_tot_notmismon10_tot: '',
      tab_3_tot_notmismon11_tot: '',
      tab_3_tot_notmismon12_tot: '',

      // 매출
      tab_3_tot_mismon01_tot: '',
      tab_3_tot_mismon02_tot: '',
      tab_3_tot_mismon03_tot: '',
      tab_3_tot_mismon04_tot: '',
      tab_3_tot_mismon05_tot: '',
      tab_3_tot_mismon06_tot: '',
      tab_3_tot_mismon07_tot: '',
      tab_3_tot_mismon08_tot: '',
      tab_3_tot_mismon09_tot: '',
      tab_3_tot_mismon10_tot: '',
      tab_3_tot_mismon11_tot: '',
      tab_3_tot_mismon12_tot: '',

      // 수금
      tab_3_tot_rcvmon01_tot: '',
      tab_3_tot_rcvmon02_tot: '',
      tab_3_tot_rcvmon03_tot: '',
      tab_3_tot_rcvmon04_tot: '',
      tab_3_tot_rcvmon05_tot: '',
      tab_3_tot_rcvmon06_tot: '',
      tab_3_tot_rcvmon07_tot: '',
      tab_3_tot_rcvmon08_tot: '',
      tab_3_tot_rcvmon09_tot: '',
      tab_3_tot_rcvmon10_tot: '',
      tab_3_tot_rcvmon11_tot: '',
      tab_3_tot_rcvmon12_tot: '',

      tab_3_modalChk: false,
      tab_3_modal: false,
      tab_3_modalData: '',
      tab_3_modalTotal: '0',
      popupDataAll: '1',
      popupDataDivinm: '0',
      popupDataPernm: '0',
      popupStdate: '',
      popupEnddate: '',
      chkState: '',
      state0: '0',
      state1: '0',
      state2: '0',
      state5: '0',
      myn: '0',
      mons: '',
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 구분
    const data = await api.dropdown('wf_dd_da020');
    await this.SS({
      gubuns: data?.items || [],
    });

    // 전자세금계산서
    const data2 = await api.dropdown('wf_dd_ca510_031_01');
    await this.SS({
      recivstatus: data2?.items || [],
    });

    const data3 = await api.dropdown('wf_dd_da020_all');
    await this.SS({
      gubuns2: data3?.items || [],
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    if (this.tabIndex === 0) {
      this.infinityPerid = new InfinityRetrieve(
        {
          stdate: this.state.stdate,
          enddate: this.state.enddate,
          as_nm: this.state.searchQuery || '',
          perid: this.state.perid || '',
          divicd: this.state.divicd || '',
          compyn: this.state.compyn || '%',
          gubun: this.state.gubun || '%',
        },
        (params) => api.fxExec('tab_1_retrieve', params),
        (items, next) => {
          this.SS({
            dataPerid: [
              ...this.state.dataPerid,
              ...items.map((item) => new StatusModel(item)),
            ],
          });
          next && next();
        },
        async () => {
          await this.SS({
            dataPerid: [],
          });
          await this.infinityPerid?.retrieveAll();
        },
      );
    } else if (this.tabIndex === 1) {
      this.infinityDetail = new InfinityRetrieve(
        {
          stdate: this.state.stdate,
          enddate: this.state.enddate,
          as_nm: this.state.searchQuery || '',
          perid: this.state.perid || '',
          divicd: this.state.divicd || '',
          compyn: this.state.compyn || '%',
          gubun: this.state.gubun || '%',
        },
        (params) => api.fxExec('tab_2_retrieve', params),
        (items, next) => {
          this.SS({
            dataDetail: [
              ...this.state.dataDetail,
              ...items.map((item) => new StatusDetailModel(item)),
            ],
          });
          next && next();
        },
        async () => {
          await this.SS({
            dataDetail: [],
          });
          await this.infinityDetail?.retrieveAll();
        },
      );
    } else if (this.tabIndex === 2) {
      this.infinityMonth = new InfinityRetrieve(
        {
          stdate: this.state.stdate,
          enddate: this.state.enddate,
          as_nm: this.state.searchQuery || '',
          perid: this.state.perid || '',
          divicd: this.state.divicd || '',
          compyn: this.state.compyn || '%',
          gubun: this.state.gubun || '%',
        },
        (params) => api.fxExec('tab_3_retrieve', params),
        (items, next) => {
          this.SS({
            dataMonth: [
              ...this.state.dataMonth,
              ...items.map((item) => new StatusMonthModel(item)),
            ],
          });
          next && next();
        },
        async () => {
          await this.SS({
            dataMonth: [],
          });
          await this.infinityMonth?.retrieveAll();
        },
      );
    }

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataPerid: [],
      dataDetail: [],
      dataMonth: [],
    });

    const index = await this.infinityPerid?.retrieveTo(['divinm', 'pernm'],
      [this.state.focused?.divinm, this.state.focused?.pernm], type, true) || 0;
    const index2 = await this.infinityDetail?.retrieveTo(['divinm', 'costpernm'],
      [this.state.focused?.divinm, this.state.focused?.costpernm], type, true) || 0;
    const index3 = await this.infinityMonth?.retrieveTo(['divinm', 'pernm'],
      [this.state.focused?.divinm, this.state.focused?.pernm], type, true) || 0;
    const dataTab1 = await this.infinityPerid?.box;
    const dataTab2 = await this.infinityDetail?.box;
    const dataTab3 = await this.infinityMonth?.box;

    await this.SS({
      tab1_cnt_tot: dataTab1?.cnt_tot,
      tab1_costamt_tot: dataTab1?.costamt_tot,
      tab1_misamt_tot: dataTab1?.misamt_tot,

      tab_2_costamt_tot: dataTab2?.costamt_tot,
      tab_2_samt_tot: dataTab2?.samt_tot,
      tab_2_tamt_tot: dataTab2?.tamt_tot,

      tab_3_tot_compmon_tot: dataTab3?.tot_compmon_tot,
      tab_3_tot_compyul_tot: dataTab3?.tot_compyul_tot,
      tab_3_tot_mismon_tot: dataTab3?.tot_mismon_tot,
      tab_3_tot_mon_tot: dataTab3?.tot_mon_tot,
      tab_3_tot_musang_tot: dataTab3?.tot_musang_tot,
      tab_3_tot_notmismon_tot: dataTab3?.tot_notmismon_tot,
      tab_3_tot_rcvmon_tot: dataTab3?.tot_rcvmon_tot,

      tab_3_tot_mon01_tot: dataTab3?.mon01_tot,
      tab_3_tot_mon02_tot: dataTab3?.mon02_tot,
      tab_3_tot_mon03_tot: dataTab3?.mon03_tot,
      tab_3_tot_mon04_tot: dataTab3?.mon04_tot,
      tab_3_tot_mon05_tot: dataTab3?.mon05_tot,
      tab_3_tot_mon06_tot: dataTab3?.mon06_tot,
      tab_3_tot_mon07_tot: dataTab3?.mon07_tot,
      tab_3_tot_mon08_tot: dataTab3?.mon08_tot,
      tab_3_tot_mon09_tot: dataTab3?.mon09_tot,
      tab_3_tot_mon10_tot: dataTab3?.mon10_tot,
      tab_3_tot_mon11_tot: dataTab3?.mon11_tot,
      tab_3_tot_mon12_tot: dataTab3?.mon12_tot,

      tab_3_tot_compmon01_tot: dataTab3?.compmon01_tot,
      tab_3_tot_compmon02_tot: dataTab3?.compmon02_tot,
      tab_3_tot_compmon03_tot: dataTab3?.compmon03_tot,
      tab_3_tot_compmon04_tot: dataTab3?.compmon04_tot,
      tab_3_tot_compmon05_tot: dataTab3?.compmon05_tot,
      tab_3_tot_compmon06_tot: dataTab3?.compmon06_tot,
      tab_3_tot_compmon07_tot: dataTab3?.compmon07_tot,
      tab_3_tot_compmon08_tot: dataTab3?.compmon08_tot,
      tab_3_tot_compmon09_tot: dataTab3?.compmon09_tot,
      tab_3_tot_compmon10_tot: dataTab3?.compmon10_tot,
      tab_3_tot_compmon11_tot: dataTab3?.compmon11_tot,
      tab_3_tot_compmon12_tot: dataTab3?.compmon12_tot,

      tab_3_tot_compyul01_tot: dataTab3?.compyul01_tot,
      tab_3_tot_compyul02_tot: dataTab3?.compyul02_tot,
      tab_3_tot_compyul03_tot: dataTab3?.compyul03_tot,
      tab_3_tot_compyul04_tot: dataTab3?.compyul04_tot,
      tab_3_tot_compyul05_tot: dataTab3?.compyul05_tot,
      tab_3_tot_compyul06_tot: dataTab3?.compyul06_tot,
      tab_3_tot_compyul07_tot: dataTab3?.compyul07_tot,
      tab_3_tot_compyul08_tot: dataTab3?.compyul08_tot,
      tab_3_tot_compyul09_tot: dataTab3?.compyul09_tot,
      tab_3_tot_compyul10_tot: dataTab3?.compyul10_tot,
      tab_3_tot_compyul11_tot: dataTab3?.compyul11_tot,
      tab_3_tot_compyul12_tot: dataTab3?.compyul12_tot,

      tab_3_tot_musang01_tot: dataTab3?.musang01_tot,
      tab_3_tot_musang02_tot: dataTab3?.musang02_tot,
      tab_3_tot_musang03_tot: dataTab3?.musang03_tot,
      tab_3_tot_musang04_tot: dataTab3?.musang04_tot,
      tab_3_tot_musang05_tot: dataTab3?.musang05_tot,
      tab_3_tot_musang06_tot: dataTab3?.musang06_tot,
      tab_3_tot_musang07_tot: dataTab3?.musang07_tot,
      tab_3_tot_musang08_tot: dataTab3?.musang08_tot,
      tab_3_tot_musang09_tot: dataTab3?.musang09_tot,
      tab_3_tot_musang10_tot: dataTab3?.musang10_tot,
      tab_3_tot_musang11_tot: dataTab3?.musang11_tot,
      tab_3_tot_musang12_tot: dataTab3?.musang12_tot,

      tab_3_tot_notmismon01_tot: dataTab3?.notmismon01_tot,
      tab_3_tot_notmismon02_tot: dataTab3?.notmismon02_tot,
      tab_3_tot_notmismon03_tot: dataTab3?.notmismon03_tot,
      tab_3_tot_notmismon04_tot: dataTab3?.notmismon04_tot,
      tab_3_tot_notmismon05_tot: dataTab3?.notmismon05_tot,
      tab_3_tot_notmismon06_tot: dataTab3?.notmismon06_tot,
      tab_3_tot_notmismon07_tot: dataTab3?.notmismon07_tot,
      tab_3_tot_notmismon08_tot: dataTab3?.notmismon08_tot,
      tab_3_tot_notmismon09_tot: dataTab3?.notmismon09_tot,
      tab_3_tot_notmismon10_tot: dataTab3?.notmismon10_tot,
      tab_3_tot_notmismon11_tot: dataTab3?.notmismon11_tot,
      tab_3_tot_notmismon12_tot: dataTab3?.notmismon12_tot,

      tab_3_tot_mismon01_tot: dataTab3?.mismon01_tot,
      tab_3_tot_mismon02_tot: dataTab3?.mismon02_tot,
      tab_3_tot_mismon03_tot: dataTab3?.mismon03_tot,
      tab_3_tot_mismon04_tot: dataTab3?.mismon04_tot,
      tab_3_tot_mismon05_tot: dataTab3?.mismon05_tot,
      tab_3_tot_mismon06_tot: dataTab3?.mismon06_tot,
      tab_3_tot_mismon07_tot: dataTab3?.mismon07_tot,
      tab_3_tot_mismon08_tot: dataTab3?.mismon08_tot,
      tab_3_tot_mismon09_tot: dataTab3?.mismon09_tot,
      tab_3_tot_mismon10_tot: dataTab3?.mismon10_tot,
      tab_3_tot_mismon11_tot: dataTab3?.mismon11_tot,
      tab_3_tot_mismon12_tot: dataTab3?.mismon12_tot,

      tab_3_tot_rcvmon01_tot: dataTab3?.rcvmon01_tot,
      tab_3_tot_rcvmon02_tot: dataTab3?.rcvmon02_tot,
      tab_3_tot_rcvmon03_tot: dataTab3?.rcvmon03_tot,
      tab_3_tot_rcvmon04_tot: dataTab3?.rcvmon04_tot,
      tab_3_tot_rcvmon05_tot: dataTab3?.rcvmon05_tot,
      tab_3_tot_rcvmon06_tot: dataTab3?.rcvmon06_tot,
      tab_3_tot_rcvmon07_tot: dataTab3?.rcvmon07_tot,
      tab_3_tot_rcvmon08_tot: dataTab3?.rcvmon08_tot,
      tab_3_tot_rcvmon09_tot: dataTab3?.rcvmon09_tot,
      tab_3_tot_rcvmon10_tot: dataTab3?.rcvmon10_tot,
      tab_3_tot_rcvmon11_tot: dataTab3?.rcvmon11_tot,
      tab_3_tot_rcvmon12_tot: dataTab3?.rcvmon12_tot,
    });
    if (this.state.dataPerid && this.tabIndex === 0 && this.state.dataPerid.length > index) {
      this.gridPerid.current?.setFocus(index);
    }
    if (this.state.dataDetail && this.tabIndex === 1 && this.state.dataDetail.length > index) {
      this.gridDetail.current?.setFocus(index2);
    }
    if (this.state.dataMonth && this.tabIndex === 2 && this.state.dataMonth.length > index) {
      this.gridMonth.current?.setFocus(index3);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.tabIndex === 0 && !ConfirmWarning.assert(this.state.dataPerid.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    if (this.tabIndex === 1 && !ConfirmWarning.assert(this.state.dataDetail.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    if (this.tabIndex === 2 && !ConfirmWarning.assert(this.state.dataMonth.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(`tab_${this.tabIndex + 1}_print`, {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery || '',
      perid: this.state.perid || '',
      divicd: this.state.divicd || '',
      compyn: this.state.compyn || '%',
      gubun: this.state.gubun || '%',
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.tabIndex === 0 && !ConfirmWarning.assert(this.state.dataPerid.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    if (this.tabIndex === 1 && !ConfirmWarning.assert(this.state.dataDetail.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    if (this.tabIndex === 2 && !ConfirmWarning.assert(this.state.dataMonth.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(`tab_${this.tabIndex + 1}_excel`, {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery || '',
      perid: this.state.perid || '',
      divicd: this.state.divicd || '',
      compyn: this.state.compyn || '%',
      gubun: this.state.gubun || '%',
    });
  }

  @action
  onRowFocusEvent(item: any) {
    this.setState({ focused: item });
  }

  @action
  onMonthRowFocusEvent(item: StatusMonthModel) {
    this.setState({ focused: item });
  }

  @action
  async onTabChange(index: number) {
    this.tabIndex = index;

    // 처음 화면 open시 조회2번되는 현상 방지
    if (this.state.firstFlag) {
      await this.SS({ firstFlag: false });
    } else {
      await this.onRetrieveEvent();
    }
  }

  @action
  onClickOpenResist() {
    this.props.publicStore.go(
      '/w_tb_e451',
      {
        stdate: this.state.focused.costdate,
        enddate: this.state.focused.costdate,
        searchQuery: this.state.focused.costdate + this.state.focused?.costnum,
      },
    );
  }

  @action
  async onPopupChk(isOpen: boolean, chkStates: string, mon: string) {
    if (chkStates === 'mon') {
      await this.SS({
        state0: '1',
        state1: '1',
        state2: '1',
        state5: '1',
        myn: '%',
      });
    } else if (chkStates === 'musang') {
      await this.SS({
        state0: '0',
        state1: '1',
        state2: '0',
        state5: '0',
        myn: '1',
      });
    } else if (chkStates === 'notmismon') {
      await this.SS({
        state0: '0',
        state1: '1',
        state2: '0',
        state5: '0',
        myn: '2',
      });
    } else if (chkStates === 'mismon') {
      await this.SS({
        state0: '0',
        state1: '0',
        state2: '1',
        state5: '0',
        myn: '%',
      });
    } else if (chkStates === 'rcvmon') {
      await this.SS({
        state0: '0',
        state1: '0',
        state2: '0',
        state5: '1',
        myn: '%',
      });
    }
    await this.SS({
      popupDataAll: '1',
      tab_3_modalChk: isOpen,
      mons: mon,
    });
  }

  @action
  async onMonthModal(isOpen: boolean) {
    // 부서, 담당 체크시 팝업창에 부서, 담당 입력되야함
    if (this.state.popupDataAll === '1') {
      await this.SS({
        popupDivicd: '',
        popupDivinm: '',
        popupPerid: '',
        popupPernm: '',
      });
    } else if (this.state.popupDataDivinm === '2') {
      await this.SS({
        popupDivicd: this.state.focused?.divicd || '',
        popupDivinm: this.state.focused?.divinm || '',
        popupPerid: this.state.focused?.perid || '',
        popupPernm: this.state.focused?.pernm || '',
      });
    } else if (this.state.popupDataPernm === '3') {
      await this.SS({
        popupDivicd: this.state.focused?.divicd || '',
        popupDivinm: this.state.focused?.divinm || '',
        popupPerid: this.state.focused?.perid || '',
        popupPernm: this.state.focused?.pernm || '',
      });
    }

    // 총 -> 해당년도 1월1일 ~ 12월31일, mon -> 해당월 1일 ~ 해당월 말일
    if (this.state.mons === 'all') {
      await this.SS({
        popupStdate: `${this.state.stdate.substr(0, 4)}0101`,
        popupEnddate: `${this.state.enddate.substr(0, 4)}1231`,
      });
    } else {
      let lastDate = String(new Date(Number(this.state.stdate.substr(0, 4)), Number(this.state.mons), 0).getDate());
      lastDate = String(this.state.enddate.substr(0, 4)) + this.state.mons + String(lastDate);
      await this.SS({
        popupStdate: `${this.state.stdate.substr(0, 4)}${this.state.mons}01`,
        popupEnddate: lastDate,
      });
    }

    // 이전에 체크했던거 해제
    await this.SS({
      popupDataDivinm: '0',
      popupDataPernm: '0',
      tab_3_modal: isOpen,
      tab_3_modalChk: false,
    });
    await this.onMonthModalRetrieve();
  }

  @action
  async onMonthModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      tab_3_modalData: [],
      tab_3_modalTotal: '0',
      searchQuery: '',
    });

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_e451w_03',
        stdate: this.state.popupStdate,
        enddate: this.state.popupEnddate,
        as_nm: this.state.popupSearchQuery,
        divicd: this.state.popupDivicd,
        perid: this.state.popupPerid,
        state0: this.state.state0,
        state1: this.state.state1,
        state2: this.state.state2,
        state5: this.state.state5,
        myn: this.state.myn,
      },
    );
    await this.SS({
      tab_3_modalData: data?.items || [],
      tab_3_modalTotal: data?.total || '0',
    });
  }

  render() {
    return <StatusTemplate
      scope={this}
    />;
  }
}
