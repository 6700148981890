import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { BreakPercent } from './BreakPercent';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8 } from '../../../../utils/time';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ComboBox, ComboBoxModel, GridLayoutAdditionalHeader } from '../../../../components';

/**
 * 메인화면
 * @window w_tb_e411w_06
 * @category 담당별고장처리율
 */
export const BreakPercentTemplate: React.FC<TemplateProps<BreakPercent>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout>
        <span>검색어</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout>
        <span>처리</span>
        <ComboBox
          value={scope.state.gubun}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '처리' },
            { value: '2', remark: '미처리' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ gubun: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          textAlign="center"
          value={scope.state.divicd}
          onChange={(value) => scope.setState({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />

        <TextBox
          value={scope.state.divinm}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout>
        <span style={{ color: 'var(--color-red)' }}>담당</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.pernm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <GridLayoutAdditionalHeader
      ref={scope.additionalTitle}
      height={Global.LAYOUT_TITLE_HEIGHT_1}
      contentWidth={2624}
    >
      <FlexLayout size={70}/>
      <FlexLayout size={68}/>
      <FlexLayout
        size={276}
        justify="center"
        align="center"
      >
        {`${scope.state.stdate.substr(0, 4)}년 합계`}
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
       1월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        2월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        3월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        4월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        5월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        6월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        7월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        8월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        9월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        10월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        11월
      </FlexLayout>
      <FlexLayout
        size={184}
        justify="center"
        align="center"
      >
        12월
      </FlexLayout>
    </GridLayoutAdditionalHeader>
    <FlexLayout>
      <GridLayout
        header={[
          {
            id: 'divinm',
            text: '부서명',
            width: 70,
            sum: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자명',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_4',
            text: '목표',
            width: 60,
            render: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-gray)' }}
              >
                15%
              </FlexLayout>
            ),
          },
          {
            id: 'compute_7',
            text: '달성율',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.todayplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {x.compute_7}
              </FlexLayout>
            ),
          },
          {
            id: 'totcompmon',
            text: '고장',
            width: 70,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(scope.state.plancomp_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.plancomp_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.plancomp) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="center"
                align="center"
              >
                {x.totcompmon}
              </FlexLayout>
            ),
          },
          {
            id: 'notplancomp',
            text: '고장율',
            width: 88,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(scope.state.notplancomp_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.notplancomp_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                /* eslint-disable-next-line radix */
                style={{ color: parseInt(x.notplancomp) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                justify="right"
                align="center"
              >
                {x.notplancomp !== '0' && x.notplancomp}
              </FlexLayout>
            ),
          },
          {
            id: 'mon01',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon01}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon01',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon01}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_01',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_01}
              </FlexLayout>
            ),
          },
          {
            id: 'mon02',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon02}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon02',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon02}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_02',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_02}
              </FlexLayout>
            ),
          },
          {
            id: 'mon03',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon03}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon03',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon03}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_03',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_03}
              </FlexLayout>
            ),
          },
          {
            id: 'mon04',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon04}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon04',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon04}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_04',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_04}
              </FlexLayout>
            ),
          },
          {
            id: 'mon05',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon05}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon05',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon05}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_05',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_05}
              </FlexLayout>
            ),
          },
          {
            id: 'mon06',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon06}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon06',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon06}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_06',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_06}
              </FlexLayout>
            ),
          },
          {
            id: 'mon07',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon07}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon07',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon01}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_07',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_07}
              </FlexLayout>
            ),
          },
          {
            id: 'mon08',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon08}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon08',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon08}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_08',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_08}
              </FlexLayout>
            ),
          },
          {
            id: 'mon09',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon09}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon09',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon09}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_09',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_09}
              </FlexLayout>
            ),
          },
          {
            id: 'mon10',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon10}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon10',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon10}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_10',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_10}
              </FlexLayout>
            ),
          },
          {
            id: 'mon11',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon11}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon11',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon11}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_11',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_11}
              </FlexLayout>
            ),
          },
          {
            id: 'mon12',
            text: '관리대수',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.todaycompplan_tot) > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.todaycompplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mon12}
              </FlexLayout>
            ),
          },
          {
            id: 'compmon12',
            text: '고장',
            width: 60,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                /* eslint-disable-next-line radix,max-len */
                style={{ color: parseInt(scope.state.misplan_tot) < 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {scope.state.misplan_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.compmon12}
              </FlexLayout>
            ),
          },
          {
            id: 'compute_12',
            text: '고장율',
            width: 66,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {scope.state.planyul_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-violet)' }}
              >
                {x.compute_12}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.BreakPercentList || [] }
        onHorizontalScroll={(x) => {
          scope.additionalTitle.current?.scrollTo(x);
        }}
      />
    </FlexLayout>
  </FlexLayout>

);
