import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w_07
 * @category 고장분석현황[전체] - 고장내용별
 */

export class BreakContentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *  고장내용
   *
   * **data type** varchar(?)
   */
  readonly contnm : string;

  /**
   *  현장코드
   *
   * **data type** varchar(?)
   */
  readonly actcd: string;

  /**
   *  현장명
   *
   * **data type** varchar(?)
   */
  readonly equpnm: string;

  /**
   *  현장명
   *
   * **data type** varchar(?)
   */
  readonly actnm: string;

  /**
   *  고장건수
   *
   * **data type** varchar(?)
   */
  readonly tot: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.equpnm = data.equpnm || '';
    this.actnm = data.actnm || '';
    this.contnm = data.contnm || '';
    this.tot = data.tot || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
