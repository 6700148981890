import * as React from 'react';
import { observer } from 'mobx-react';
import {
  ModalView,
} from '../../components';
import { ModalStore } from '../../stores';

interface ModalAddressProps {
  modalStore: ModalStore;
}

@observer
export class ModalAddress extends React.Component<ModalAddressProps> {
  isOpen?: boolean;

  componentDidUpdate() {
    const { modalStore } = this.props;

    if (!this.isOpen && modalStore.isVisibleAddress) {
      this.isOpen = true;

      const ref = modalStore.refAddressButton;
      ref?.doPostcode();
    } else if (this.isOpen && !modalStore.isVisibleAddClt) {
      this.isOpen = false;
    }
  }

  render() {
    const { modalStore } = this.props;
    const ref = modalStore.refAddressButton;

    return (
      <ModalView
        onClose={() => modalStore.closeAddress()}
        isVisible={modalStore.isVisibleAddress}
        width={450}
        height={600}
      >
        <div
          ref={ref?.postcode}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </ModalView>
    );
  }
}
