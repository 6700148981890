import * as React from 'react';
import {
  BsFillStarFill,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { RiKakaoTalkFill } from 'react-icons/ri';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
  ComboBox,
  ComboBoxModel,
  Button,
  TelnumTextBox,
  CheckBox,
  TableLayout,
  ModalView,
  LayoutTitle,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BreakCustomer } from './BreakCustomer';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import { Fix } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e401w_recvscore
 * @category 고장처리고객만족도
 */

export const BreakCustomerTemplate: React.FC<TemplateProps<BreakCustomer>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={600}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="현장으로 검색하세요"
          value={scope.state.searchQuery}
          textAlign="left"
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        />
      </FlexLayout>
      <FlexLayout size={270}>
        <span style={{ color: 'var(--color-red)' }}>담당</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.pernm}
          weight={1.2}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={210}>
        <span>전송여부</span>

        <ComboBox
          value={scope.state.rcvflag}
          onSelect={(option) => scope.setState({ rcvflag: option.value }, () => scope.onRetrieveEvent())}
          textAlign="left"
          data={[
            { value: '1', remark: '전송' },
            { value: '0', remark: '미전송' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          isDisabledTrackingStateChange={true}
        >
        </ComboBox>
      </FlexLayout>

      {
        scope.state.kakaoyn === '1' && scope.state.rcvflag === '0'
          ? <Button
          style={{ backgroundColor: '#f9e000' }}
          onClick={() => scope.onCheckList('kakao')}
        >
          <RiKakaoTalkFill size={26}/>

          <span>전송</span>
        </Button> : null
      }

      {
        scope.state.rcvflag === '0'
          ? <Button onClick={() => scope.onCheckList('sms')}>
          문자전송
        </Button> : null
      }
    </SearchLayout>

    <FlexLayout>
    {/* 조회조건 전송여부 전송이면 첫번째 FlexLayout */}
    {
      scope.state.rcvflag === '1'
        ? <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'seq',
            text: '처리',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.seq}차
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '처리자',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'pernm2',
            text: '처리자2',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm2}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.BreakCustomerList.length} 건
              </FlexLayout>
            ),
          },
          {
            id: 'recedate',
            text: '처리일자',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.recedate)}
              </FlexLayout>
            ),
          },
          {
            id: 'recenum',
            text: '번호',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.recenum}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'equpnm',
            text: '호기명',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.equpnm}
              </FlexLayout>
            ),
          },
          {
            id: 'contents',
            text: '고장상세내용',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
                onDoubleClick={() => scope.onDetailText(true, x.contents, '고장상세내용')}
              >
                {Fix.newline(x.contents).replace(/\n/ig, ' ').substr(0, 40)}
                {x.contents.length > 40 && '...'}
              </FlexLayout>
            ),
          },
          {
            id: 'resuremark',
            text: '처리내용',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
                onDoubleClick={() => scope.onDetailText(true, x.resuremark, '처리내용')}
              >
                {Fix.newline(x.resuremark).replace(/\n/ig, ' ').substr(0, 40)}
                {x.resuremark.length > 40 && '...'}
              </FlexLayout>
            ),
          },
          {
            id: 'recvflag',
            text: '답변구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={x.recvflag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
              >
                {x.recvflag === '1' ? '응답' : '미응답'}
              </FlexLayout>
            ),
          },
          {
            id: 'recetelnum',
            text: '고객연락처(전송자)',
            width: 120,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.recetelnum}
              </FlexLayout>
            ),
          },
          {
            id: 'recvscore',
            text: '고객만족도',
            width: 100,
            render: (x) => {
              const Rate = [];
              for (let i = 0; i < parseInt(x.recvscore, 10) || 0; i += 1) {
                Rate.push(<BsFillStarFill key={i} />);
                Rate.push(' ');
              }

              if (Rate.length) Rate.pop();

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    fontSize: 16,
                    color: 'rgb(255, 170, 62)',
                  }}
                >
                  {Rate}
                </FlexLayout>
              );
            },
          },
          {
            id: 'recvmsg',
            text: '고객한마디',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
                onDoubleClick={() => scope.onDetailText(true, x.recvmsg, '고객한마디')}
              >
                {Fix.newline(x.recvmsg).replace(/\n/ig, ' ').substr(0, 40)}
                {x.contents.recvmsg > 40 && '...'}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.BreakCustomerList}
      />
        : <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'chk',
              text: '선택',
              width: 50,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.chk === '1'}
                  onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                >
                </CheckBox>
              ),
            },
            {
              id: 'hp',
              text: '신고자번호',
              width: 100,
              render: (x, rowUpdate) => (
                <TelnumTextBox
                  textAlign="center"
                  value={x.hp}
                  onChange={(value) => rowUpdate({ hp: value })}
                />
              ),
            },
            {
              id: 'acthp',
              text: '담당자휴대폰번호',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.acthp}
                </FlexLayout>
              ),
            },
            {
              id: 'seq',
              text: '처리',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq}차
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '처리자',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm2',
              text: '처리자2',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm2}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'recedate',
              text: '처리일자',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.recedate)}
                </FlexLayout>
              ),
            },
            {
              id: 'recenum',
              text: '번호',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.recenum}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'contents',
              text: '고장상세내용',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  onDoubleClick={() => scope.onDetailText(true, x.contents, '고장상세내용')}
                >
                  {Fix.newline(x.contents).replace(/\n/ig, ' ').substr(0, 40)}
                  {x.contents.length > 40 && '...'}
                </FlexLayout>
              ),
            },
            {
              id: 'resuremark',
              text: '처리내용',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  onDoubleClick={() => scope.onDetailText(true, x.resuremark, '처리내용')}
                >
                  {Fix.newline(x.resuremark).replace(/\n/ig, ' ').substr(0, 40)}
                  {x.resuremark.length > 40 && '...'}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
          data={scope.state.BreakCustomerList}
        />
      }
    </FlexLayout>

    <ModalView
      width={600}
      height={600}
      isVisible={scope.state.detailModal}
      onClose={() => scope.onCloseDetailModal()}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 10 }}
      >
        <LayoutTitle>상세보기</LayoutTitle>
        <FlexLayout style={{ fontWeight: 500 }}>
          <LayoutTitle size={80}>문자내용</LayoutTitle>
          <FlexLayout style={{ paddingTop: 8 }}>{Fix.newline(scope.state.detailText)}</FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
