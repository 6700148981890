export default class Format {
  static number(subject: string | number, unVisibleZero: boolean = false): string {
    return unVisibleZero && (!subject || subject === '0') ? '' : Format.make(subject, ',', 3);
  }

  static make(subject: string | number, char: string, charAt: number): string {
    if (subject === '') return '';

    const isMinus = `${subject}`.startsWith('-');
    const v = (Number(`${subject || '0'}`.replace(/[,-]/ig, '')) || 0).toString(10);
    const regstr = new RegExp(`(\\d{${charAt}}(?!.*\\.|$))`, 'g');
    const r = v.split('').reverse().join('').replace(regstr, `$1${char}`)
      .split('')
      .reverse()
      .join('');
    return isMinus ? `-${r}` : r;
  }

  static toNumber(subject: string): number {
    if (!subject) return 0;
    return parseInt(`${subject}`.replace(/,/ig, ''), 10);
  }
}
