import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { BillTemplate } from './Bill.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  BillDetailModel,
  BillModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { Format } from '../../../../utils/string';
import { PageComponent } from '../../../../utils/layout';
import { DateStabilizer } from '../../../../utils/time';

export enum BillItemChangeTypes {
  PUMDATA,
  PERID,
  UAMT,
  QTY,
  SAMT,
  TAMT,
  PCODE
}

export enum BillItemChangeTypeNames {
  pumdate,
  perid,
  uamt,
  qty,
  samt,
  tamt,
  pcode
}

interface BillState {

  // 검색 조건
  searchQuery: string;
  stdate: string;
  enddate: string;
  perid: string;
  pernm: string;

  // 데이터 객체
  focusedBill?: BillModel;
  focusedBillDetail?: BillDetailModel;
  data: BillModel;
  lastNewData: BillModel;
  billList: Array<BillModel>;
  billDetailList: Array<BillDetailModel>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부
  isCopy: boolean;

  focusIndex: number;

  jqty_tot: string;
  qty_tot: string;
  uamt_tot: string;
  samt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca606_01_2
 * @category 자재청구서
 */
export class Bill extends PageComponent<PageProps, BillState>
  implements PageToolEvents {
  updatedRows?: Array<BillModel>;

  updatedRows2?: Array<BillDetailModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      data: new BillModel(),
      billList: [],
      billDetailList: [],
      isCopy: false,
      isReported: false,
      jqty_tot: '',
      qty_tot: '',
      uamt_tot: '',
      samt_tot: '',
      focusIndex: 0,
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          ...this.state.data,
        }, false,
      );

      data && this.setState({
        data: new BillModel({
          ...this.state.data,
          ...data,
        }, data.new),
      });

      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          billList: [...this.state.billList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          billList: [],
          billDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.billList && this.state.billList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      billList: [],
      billDetailList: [],
      data: new BillModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['pumdate', 'pumnum'],
        [lastSelected?.pumdate, lastSelected?.pumnum], type, true) || 0;
      if (this.state.billList && this.state.billList.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.billList[this.state.focusIndex], this.state.focusIndex);
      }
    });
  }

  @action
  onRowFocusEvent(item: BillModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        focusedBillDetail: undefined,
        isCopy: false,
        isReported: false,
      });
      return;
    }
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        focusedBillDetail: undefined,
        billDetailList: [],
        isReported: false,
        data: lastNewData,
        jqty_tot: '',
        qty_tot: '',
        uamt_tot: '',
        samt_tot: '',
      });
      this.table.current?.update(false);
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState(
      {
        focusedBill: item,
        focusIndex: index,
      },
      async () => {
        this.infinity2 = new InfinityRetrieve(item,
          (params) => api.fxExec('dw_1_RowFocuschanged', params),
          (items) => {
            this.setState({
              billDetailList: items.map((x: any, index: number) => new BillDetailModel({
              // 순번 생성
                ...x,
                // eslint-disable-next-line no-nested-ternary
                pumseq: index < 9 ? `00${index + 1}` : index < 99 ? `0${index + 1}` : (index + 1).toString(),
              })),
            });

            // 결재상신 여부확인
            if (this.state.focusedBill?.appgubun === null
            || this.state.focusedBill?.appgubun === ''
            || this.state.focusedBill?.appgubun === '131') {
              this.setState({ isReported: false });
            } else {
              this.setState({ isReported: true });
            }
            this.table.current?.update(false);
          },
          async () => {
            await this.SS({
              billDetailList: [],
            });

            await this.infinity2?.retrieve();
            if (this.state.billDetailList && this.state.billDetailList?.length > 0) {
              await this.table.current?.update(true);
              this.table.current?.setFocus(0, 0);
            }
          });

        if (!this.infinity2.count) {
          this.setState({ billDetailList: [] }, () => this.table.current?.update());
        }

        const data = await this.infinity2?.retrieve();
        data && this.setState({
          data: new BillModel(data, false),
          jqty_tot: this.infinity2?.box?.jqty_tot || '0',
          qty_tot: this.infinity2?.box?.qty_tot || '0',
          uamt_tot: this.infinity2?.box?.uamt_tot || '0',
          samt_tot: this.infinity2?.box?.samt_tot || '0',
        });

        if (!this.state.billDetailList.length) {
          const data = await api.fxExec(
            'dw_1_RowFocuschanged',
            { ...item },
          );
          this.setState({
            data: new BillModel(data, false),
            jqty_tot: data?.jqty_tot || '0',
            qty_tot: data?.qty_tot || '0',
            uamt_tot: data?.uamt_tot || '0',
            samt_tot: data?.samt_tot || '0',
          });
        }

        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 0);
      },
    );
  }

  @action
  onRowFocusEvent2(item:BillDetailModel) {
    // if (item.new === '1') return;
    this.setState({ focusedBillDetail: item });
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    if (type === BillItemChangeTypeNames.pumdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: BillItemChangeTypeNames[type],
          data: this.state.data.pumdate,
          pumdate: this.state.data.pumdate,
          pumnum: this.state.data.pumnum,
          row: this.state.billDetailList.length, // detail list count
          new: this.state.data.new,
        },
      );
    }

    if (type === BillItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: BillItemChangeTypeNames[type],
          pumdate: this.state.data.pumdate,
          pumnum: this.state.data.pumnum,
          data: this.state.data.perid,
          new: this.state.data.new,
        },
      );
    }

    data && this.setState({
      data: new BillModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const uamt = Format.toNumber(item.uamt);
    const tamt = Format.toNumber(item.tamt);
    const samt = Format.toNumber(item.samt);

    let sum = 0;
    let tax = 0;
    let amount = 0;
    if (qty === 0 && name !== 'qty') {
      amount = Math.round(uamt);
    } else {
      amount = Math.round(qty * uamt);
    }

    if (name !== 'samt') {
      if (qty === 0 && name !== 'qty') {
        tax = Math.round((uamt) / 10);
      } else {
        tax = Math.round((qty * uamt) / 10);
      }
    } else {
      amount = samt;
      tax = Math.round(samt / 10);
    }

    if (name !== 'tamt') {
      sum = Math.round(samt + tax);
    } else {
      tax = tamt;
      sum = Math.round(samt + tax);
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: amount.toString(),
      tamt: tax.toString(),
      mamt: sum.toString(),
    });
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedBill!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.REQUIRE, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedBill!, this.state.focusIndex);
  }

  @action // 품목코드
  async openProductSelector() {
    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.billDetailList.findIndex((x) => x.pumseq === this.state.focusedBillDetail?.pumseq);
    const { modalStore } = this.props;
    const data = await modalStore.openProductSelector();

    // if (!data) {
    //   ConfirmWarning.show('취소', '취소하셨습니다');
    // }

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedBillDetail: new BillDetailModel({
        ...this.state.focusedBillDetail,
        pcode: data.phm_pcod,
      }, false),
      // eslint-disable-next-line max-len
      billDetailList: this.state.billDetailList.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new BillDetailModel({
            ...x,
            pcode: data.phm_pcod,
            pname: data.phm_pnam,
            psize: data.phm_size,
            punit: data.phm_unit,
            uamt: data.phm_mamt,
          }, data.new === '1') : new BillDetailModel(x)
      )),
    }, () => this.table.current?.update(false));
  }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `청구일자: ${this.state.focusedBill?.pumdate}, 청구번호: ${this.state.focusedBill?.pumnum}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.exec(Category.EMAIL, 'wb_copy', {
      ...this.state.data,
      pumdate: '',
      pumnum: '',
      appnum: '',
      items: this.state.billDetailList,
    });

    if (data) {
      const newModel = new BillModel(data, true);

      this.setState({
        data: newModel,
        billList: [newModel, ...this.state.billList],
        focusedBill: newModel,
        isCopy: true,
      }, async () => {
        await this.table.current?.update(false);
      });
      this.grid.current?.setFocus(0);
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.REQUIRE);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.REQUIRE.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }
    if (this.state.billDetailList.length < 1) {
      ConfirmWarning.show('경고', '청구할 품목이 없습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      title: this.state.data.title,
      pumdate: this.state.data.pumdate,
      pumnum: this.state.data.pumnum,
      divicd: this.state.data.divicd,
      divinm: this.state.data.divinm,
      actcd: this.state.data.actcd,
      actnm: this.state.data.actnm,
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      remark: this.state.data.remark,
      refer: this.state.data.refer,
      items: this.state.billDetailList,
    }, this.state.data.new === '1')) {
      this.updatedRows2 = [];
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.pumdate);
      await this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new BillModel(data, true);

      this.setState({
        jqty_tot: '',
        qty_tot: '',
        uamt_tot: '',
        samt_tot: '',
        data: newModel,
        lastNewData: newModel,
        billList: [
          newModel,
          ...this.state.billList,
        ],
        billDetailList: [],
        focusedBill: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update();
        this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `청구일자: ${this.state.focusedBill?.pumdate} 청구번호: ${this.state.focusedBill?.pumnum}`;
    await api.delete(text, this.state.focusedBill) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        pumdate: this.state.data?.pumdate,
        pumnum: this.state.data?.pumnum,
        pumseq: this.state.focusedBillDetail?.pumseq,
        actcd: this.state.data?.actcd,
      },
    );

    const seq = this.state.billDetailList.length;
    data && this.setState({
      billDetailList: [...this.state.billDetailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new BillDetailModel({ ...data, pumseq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString() }, true)],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.billDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.billDetailList.length === 0) {
      ConfirmWarning.show('경고', '청구할 항목이 없습니다.');
      return;
    }
    const text = `${this.state.focusedBillDetail?.pumseq}`;
    await api.fxDelete(
      'dw_2_delete',
      text,
      {
        pumdate: this.state.data?.pumdate,
        pumnum: this.state.data?.pumnum,
        pumseq: this.state.focusedBillDetail?.pumseq,
        actcd: this.state.data?.actcd,
      },
    ) && this.onRowFocusEvent(this.state.focusedBill!, this.state.focusIndex);
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.focusedBill?.pumdate, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      pumdate: this.state.focusedBill?.pumdate,
      pumnum: this.state.focusedBill?.pumnum,
      pumseq: this.state.focusedBillDetail?.pumseq,
      actcd: this.state.focusedBill?.actcd,
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */
  @action
  onUpdatedRows(rows: Array<BillModel>, updatedRows: Array<BillModel>) {
    this.updatedRows = updatedRows;
    this.setState({ billList: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows2      전체 행 (변경 행 반영된 상태)
   * @param updatedRows2 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<BillDetailModel>, updatedRows2: Array<BillDetailModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ billDetailList: rows2 });
  }

  render() {
    return (
      <BillTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new BillModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
