import * as React from 'react';
import {
  FlexLayout,
  LabelBox,
  TextBox,
  GridLayout,
  SearchLayout,
} from '../../../../components';
import { TextOften } from './TextOften';
import { Global, TemplateProps } from '../../../../constants';

/**
 * 화면
 * @window w_tb_sms_repeat
 * @category 문자자주쓰는문구
 */
export const TextOftenTemplate: React.FC<TemplateProps<TextOften>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
   <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 360 }}>
      <FlexLayout>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="문구제목, 문구내용으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

   <FlexLayout>
    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'seq',
          text: '순번',
          width: 20,
          render: (x) => (
            <FlexLayout justify="center" align="center">
              {x.seq}
            </FlexLayout>
          ),
        },
        {
          id: 'title',
          text: '문구제목',
          width: 80,
          render: (x) => (
            <FlexLayout justify="center" align="center">
              {x.title}
            </FlexLayout>
          ),
        },
      ]}
      data={scope.state.textOftenList}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onRowClick={(item, i) => scope.onRowFocusEvent(item, i)}
    />
    <FlexLayout isVertical={true} weight={1}>
      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1} >
        <LabelBox title="순번">
          <TextBox
            textAlign="center"
            value={model.seq}
            onChange={(value) => setData({ seq: value })}
            readonly={true}
          />
        </LabelBox>

        <LabelBox title="문구제목">
          <TextBox
            textAlign="center"
            value={model.title}
            onChange={(value) => setData({ title: value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={400}>
        <LabelBox title="문구내용">
          <TextBox
            isMultiline={true}
            value={model.remark}
            onChange={(value) => setData({ remark: value })}
          />
        </LabelBox>
      </FlexLayout>
    </FlexLayout>
   </FlexLayout>
  </FlexLayout>
  );
};
