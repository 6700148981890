import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { SiteVisitTemplate } from './SiteVisit.template';
import { InfinityRetrieve } from '../../../../models/common';
import { SiteVisitModel } from './models/SiteVisit.model';
import { GridLayout, GridLayoutAdditionalHeader } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { InspectionModalModel } from './models/InspectionModal.model';
import { InspectionContractModalModel } from './models/InspectionContractModal.model';
import { InspectionLongModalModel } from './models/InspectionLongModal.model';
import { OldEqupmentModalModel } from './models/OldEqupmentModal.model';
import { BreakDownModalModel } from './models/BreakDownModal.model';
import { CheckModalModel } from './models/CheckModal.model';
import { ContractModalModel } from './models/ContractModal.model';
import { ConstructionModalModel } from './models/ConstructionModal.model';
import { CheckModalFocusedModel } from './models/CheckModalFocused.model';
import { InspectDetailModalModel } from './models/InspectDetailModal.model';

interface HolidayState {

  // 검색조건
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;

  stdate: string;
  enddate: string;
  stmon: string;
  endmon: string;
  stday: string;
  endday: string;

  atdcds: Array<any>;
  contgubuns: Array<any>;
  contgs: Array<any>;
  checkGubuns: Array<any>;
  kcspnms: Array<any>;
  results: Array<any>;
  consGubuns: Array<any>;

  siteVisitList: Array<SiteVisitModel>;
  focusedsiteVisit: SiteVisitModel;

  inspectionModal: boolean;
  inspectionContractModal: boolean;
  inspectionLongModal: boolean;
  oldEqupmentModal: boolean;
  breakDownModal: boolean;
  checkModal: boolean;
  inspectModal: boolean
  contractModal: boolean;
  constructionModal: boolean;

  inspectionModalData: Array<InspectionModalModel>
  inspectionContractModalData: Array<InspectionContractModalModel>
  inspectionLongModalData: Array<InspectionLongModalModel>
  oldEqupmentModalData: Array<OldEqupmentModalModel>
  breakDownModalData: Array<BreakDownModalModel>
  checkModalData: Array<CheckModalModel>
  inspectModalData: Array<CheckModalModel>
  inspectModalDetailData: Array<InspectDetailModalModel>
  contractModalData: Array<ContractModalModel>
  constructionModalData: Array<ConstructionModalModel>

  checkModalFocused: CheckModalModel;

  gubuns: string,
  popups: string,
  popupTitles: string,

  // 통계
  compcnt_tot: string;
  compnotmiscnt_tot: string;
  cont001cnt_tot: string;
  cont002cnt_tot: string;
  contcnt_tot: string;
  costcnt_tot: string;
  costcompcnt_tot: string;
  kc001cnt_tot: string;
  kc002cnt_tot: string;
  misamt_tot: string;
  miscnt_tot: string;
  misplan_tot: string;
  notkcnt_tot: string;
  notplancomp_tot: string;
  oldequpcnt_tot: string;
  rececompcnt_tot: string;
  notkccnt_tot: string
  plancomp_tot: string;
  plancont001cnt_tot: string;
  plancont002cnt_tot: string;
  plancontcnt_tot: string;
  planmisamt_tot: string;
  planmiscnt_tot: string;
  planyul_tot: string;
  todaycompplan_tot: string;
  todayplan_tot: string;
  compnotmiscnt: string;
  wadcnt_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_e501w_01
 * @category 현장방문숙지리스트
 */
export class SiteVisit extends PageComponent<PageProps, HolidayState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  inspectionGrid: React.RefObject<GridLayout> = React.createRef();

  inspectionContractGrid: React.RefObject<GridLayout> = React.createRef();

  inspectionLongGrid: React.RefObject<GridLayout> = React.createRef();

  oldEqupmentGrid: React.RefObject<GridLayout> = React.createRef();

  breakDownGrid: React.RefObject<GridLayout> = React.createRef();

  checkGrid: React.RefObject<GridLayout> = React.createRef();

  checkFocusedGrid: React.RefObject<GridLayout> = React.createRef();

  inspectGrid: React.RefObject<GridLayout> = React.createRef();

  contractGrid: React.RefObject<GridLayout> = React.createRef();

  constructionGrid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalTitle2: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalSubTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  infinity?: InfinityRetrieve;

  inspectionInfinity?: InfinityRetrieve;

  inspectionContractInfinity?: InfinityRetrieve;

  inspectionLongInfinity?: InfinityRetrieve;

  oldEqupmentInfinity?: InfinityRetrieve;

  breakDownInfinity?: InfinityRetrieve;

  checkInfinity?: InfinityRetrieve;

  contractInfinity?: InfinityRetrieve;

  constructionInfinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {

      perid: '',
      pernm: '',
      divicd: '',
      divinm: '',

      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      stmon: `${month}`,
      endmon: `${month}`,
      stday: `${date}`,
      endday: `${date}`,

      atdcds: [],
      contgubuns: [],
      contgs: [],
      checkgubuns: [],
      kcspnms: [],
      results: [],
      consGubuns: [],

      data: new SiteVisitModel(),
      siteVisitList: [],

      inspectionModal: false, // 점검 팝업
      inspectionContractModal: false, // 점검시 계약 팝업
      inspectionLongModal: false, // 점검시 장기미수 팝업
      oldEqupmentModal: false, // 노후된 호기 팝업
      breakDownModal: false, // 고장 팝업
      checkModal: false, // 검사 팝업
      inspectModal: false, // 검사 팝업 -> 거래처이력 팝업
      constructionModal: false, // 계약 팝업

      inspectionModalData: [], // 점검 팝업 data
      inspectionContractModalData: [], // 점검시 계약 팝업 data
      inspectionLongModalData: [], // 점검시 장기미수 팝업 data
      oldEqupmentModalData: [], // 노후된 호기 팝업 data
      breakDownModalData: [], // 고장 팝업 data
      checkModalData: [], // 검사 팝업 data
      inspectModalData: [], // 검사 팝업 -> 거래처이력 팝업 data
      inspectModalDetailData: [],
      constructionModalData: [], // 계약 팝업 data

      gubuns: '',
      popups: '',
      popupTitles: '',

      compcnt_tot: '',
      compnotmiscnt_tot: '',
      cont001cnt_tot: '',
      cont002cnt_tot: '',
      contcnt_tot: '',
      costcnt_tot: '',
      costcompcnt_tot: '',
      kc001cnt_tot: '',
      kc002cnt_tot: '',
      misamt_tot: '',
      miscnt_tot: '',
      misplan_tot: '',
      notkcnt_tot: '',
      notplancomp_tot: '',
      oldequpcnt_tot: '',
      rececompcnt_tot: '',
      notkccnt_tot: '',
      plancomp_tot: '',
      plancont001cnt_tot: '',
      plancont002cnt_tot: '',
      plancontcnt_tot: '',
      planmisamt_tot: '',
      planmiscnt_tot: '',
      planyul_tot: '',
      todaycompplan_tot: '',
      todayplan_tot: '',
      compnotmiscnt: '',
      wadcnt_tot: '',
      total: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_pb002_03');
    if (!data) return;

    // 계약분류
    const data2 = await api.dropdown('wf_dd_ca510_001_02');
    if (!data2) return;

    await this.SS({
      contgs: data2?.items,
    });

    // 계약 구분
    const data3 = await api.dropdown('wf_dd_ca510_008_02');
    if (!data3) return;

    await this.SS({
      contgubuns: data3?.items,
    });

    // 검사팝업창 구분
    const data4 = await api.dropdown('wf_dd_ca510_012_01');
    if (!data4) return;

    await this.SS({
      checkGubuns: data4?.items,
    });

    // 검사기관
    const data5 = await api.dropdown('wf_dd_ca510_011_01');
    if (!data5) return;

    await this.SS({
      kcspnms: data5?.items,
    });

    // 결과
    const data6 = await api.dropdown('wf_dd_ca510_018_01');
    if (!data6) return;

    await this.SS({
      results: data6?.items,
    });

    // 결과
    const data7 = await api.dropdown('wf_dd_da020');
    if (!data7) return;

    await this.SS({
      consGubuns: data7?.items,
    });

    await this.refresh();
  }

  @action
  async refresh() {
    const { actionStore: api } = this.props;

    await api.fxExec('wb_refresh', {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          siteVisitList: items,
        }, next);
      },
      async () => {
        await this.SS({ siteVisitList: [] });
        await this.infinity?.retrieveAll();
        await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      siteVisitList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['perid', 'stdate'],
        [this.state.focusedsiteVisit?.perid, this.state.focusedsiteVisit?.stdate], type, true) || 0;
      this.state.siteVisitList && this.state.siteVisitList.length > index && this.grid.current?.setFocus(index);
      await this.setState({
        compcnt_tot: this.infinity?.box?.compcnt_tot || '0',
        compnotmiscnt_tot: this.infinity?.box?.compnotmiscnt_tot || '0',
        cont001cnt_tot: this.infinity?.box?.cont001cnt_tot || '0',
        cont002cnt_tot: this.infinity?.box?.cont002cnt_tot || '0',
        contcnt_tot: this.infinity?.box?.contcnt_tot || '0',
        costcnt_tot: this.infinity?.box?.costcnt_tot || '0',
        costcompcnt_tot: this.infinity?.box?.costcompcnt_tot || '0',
        kc001cnt_tot: this.infinity?.box?.kc001cnt_tot || '0',
        kc002cnt_tot: this.infinity?.box?.kc002cnt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        miscnt_tot: this.infinity?.box?.miscnt_tot || '0',
        misplan_tot: this.infinity?.box?.misplan_tot || '0',
        notkcnt_tot: this.infinity?.box?.notkcnt_tot || '0',
        notplancomp_tot: this.infinity?.box?.notplancomp_tot || '0',
        oldequpcnt_tot: this.infinity?.box?.oldequpcnt_tot || '0',
        rececompcnt_tot: this.infinity?.box?.rececompcnt_tot || '0',
        notkccnt_tot: this.infinity?.box?.notkccnt_tot || '0',
        plancomp_tot: this.infinity?.box?.plancomp_tot || '0',
        plancont001cnt_tot: this.infinity?.box?.plancont001cnt_tot || '0',
        plancont002cnt_tot: this.infinity?.box?.plancont002cnt_tot || '0',
        plancontcnt_tot: this.infinity?.box?.plancontcnt_tot || '0',
        planmisamt_tot: this.infinity?.box?.planmisamt_tot || '0',
        planmiscnt_tot: this.infinity?.box?.planmiscnt_tot || '0',
        planyul_tot: this.infinity?.box?.planyul_tot || '0',
        todaycompplan_tot: this.infinity?.box?.todaycompplan_tot || '0',
        todayplan_tot: this.infinity?.box?.todayplan_tot || '0',
        wadcnt_tot: this.infinity?.box?.wadcnt_tot || '0',
        total: this.infinity?.box?.total || '0',
      });
    });

    await this.grid.current?.forceRepaint();
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.siteVisitList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      perid: this.state.perid,
      divicd: this.state.divicd,
    });
  }

  @action
  async onRowFocusEvent(item:SiteVisitModel) {
    await this.setState({ focusedsiteVisit: item });
  }

  @action
  async onInspectionModal(item: InspectionModalModel, isOpen: boolean, gubun: string, popup: string, popupTitle: string) {
    await this.SS({
      inspectionModal: isOpen,
      gubuns: gubun,
      popups: popup,
      popupTitles: popupTitle,
    });
    await this.onInspectionModalRetrieve(item);
  }

  @action
  async onInspectionModalRetrieve(_item: InspectionModalModel) {
    const { actionStore: api } = this.props;

    this.inspectionInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          // eslint-disable-next-line new-cap
          inspectionModalData: [...this.state.inspectionModalData || [], ...items.map((x) => new InspectionModalModel(x)),
          ],
        });
      },
    );
    await this.inspectionInfinity?.retrieveAll();
    await this.inspectionGrid.current?.forceRepaint();
  }

  @action
  async onInspectionContractModal(item: InspectionContractModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      inspectionContractModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onInspectionContractModalRetrieve(item);
  }

  @action
  async onInspectionContractModalRetrieve(_item: InspectionContractModalModel) {
    const { actionStore: api } = this.props;

    this.inspectionContractInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          // eslint-disable-next-line new-cap
          inspectionContractModalData: [...this.state.inspectionContractModalData || [], ...items.map((x) => new InspectionContractModalModel(x)),
          ],
        });
      },
    );
    await this.inspectionContractInfinity?.retrieveAll();
    await this.inspectionContractGrid.current?.forceRepaint();
  }

  @action
  async onInspectionLongModal(item: InspectionLongModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      inspectionLongModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onInspectionLongModalRetrieve(item);
  }

  @action
  async onInspectionLongModalRetrieve(_item: InspectionLongModalModel) {
    const { actionStore: api } = this.props;

    this.inspectionLongInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          inspectionLongModalData: [...this.state.inspectionLongModalData || [], ...items.map((x) => new InspectionLongModalModel(x)),
          ],
        });
      },
    );
    await this.inspectionLongInfinity?.retrieveAll();
    await this.inspectionLongGrid.current?.forceRepaint();
  }

  @action
  async onOldEqupmentModal(item: OldEqupmentModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      oldEqupmentModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onOldEqupmentModalRetrieve(item);
  }

  @action
  async onOldEqupmentModalRetrieve(_item: OldEqupmentModalModel) {
    const { actionStore: api } = this.props;

    this.oldEqupmentInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          oldEqupmentModalData: [...this.state.oldEqupmentModalData || [], ...items.map((x) => new OldEqupmentModalModel(x)),
          ],
        });
      },
    );
    await this.oldEqupmentInfinity?.retrieveAll();
    await this.oldEqupmentGrid.current?.forceRepaint();
  }

  @action
  async onBreakDownModal(item: BreakDownModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      breakDownModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onBreakDownModalRetrieve(item);
  }

  @action
  async onBreakDownModalRetrieve(_item: BreakDownModalModel) {
    const { actionStore: api } = this.props;

    this.breakDownInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          breakDownModalData: [...this.state.breakDownModalData || [], ...items.map((x) => new BreakDownModalModel(x)),
          ],
        });
      },
    );
    await this.breakDownInfinity?.retrieveAll();
    await this.breakDownGrid.current?.forceRepaint();
  }

  @action
  async onCheckModal(item: CheckModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      checkModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onCheckModalRetrieve(item);
  }

  @action
  async onCheckModalRetrieve(_item: CheckModalModel) {
    const { actionStore: api } = this.props;

    this.checkInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          checkModalData: [...this.state.checkModalData || [], ...items.map((x) => new CheckModalModel(x)),
          ],
        });
      },
    );
    await this.checkInfinity?.retrieveAll();
    await this.checkGrid.current?.setFocus(0);
    await this.checkGrid.current?.forceRepaint();
  }

  @action
  async checkModalRowfocused(item: CheckModalModel) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_RowFocuschanged', {
      sub: 'w_popup_e501w_01',
      actcd: item?.actcd,
      equpcd: item?.equpcd,
    });

    await this.SS({
      checkModalFocused: item,
      inspectModalData: data?.items1,
    });
  }

  @action
  async openInspectDetailModal(item: CheckModalFocusedModel) {
    const { actionStore: api } = this.props;

    const data = await api.retrieve({
      sub: 'w_popup_e073_fail',
      ...item,
    });

    // data가 없을 시 return
    if (data === undefined) {
      return;
    }

    await this.SS({
      checkModalFocused: new CheckModalModel({
        ...this.state.checkModalFocused,
        ...data,
      }),
      inspectModal: true,
      inspectModalDetailData: data?.items,
    });
  }

  @action
  async onContractModal(item: ContractModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      contractModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onContractModalRetrieve(item);
  }

  @action
  async onContractModalRetrieve(_item: ContractModalModel) {
    const { actionStore: api } = this.props;

    this.contractInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          contractModalData: [...this.state.contractModalData || [], ...items.map((x) => new ContractModalModel(x)),
          ],
        });
      },
    );
    await this.contractInfinity?.retrieveAll();
    await this.contractGrid.current?.forceRepaint();
  }

  @action
  async onConsturctionModal(item: ConstructionModalModel, isOpen: boolean, gubun: string, popupTitle: string) {
    await this.SS({
      constructionModal: isOpen,
      gubuns: gubun,
      popupTitles: popupTitle,
    });
    await this.onConsturctionModalRetrieve(item);
  }

  @action
  async onConsturctionModalRetrieve(_item: ConstructionModalModel) {
    const { actionStore: api } = this.props;

    this.constructionInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e501w_01',
        gubun: this.state.gubuns,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.focusedsiteVisit.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          constructionModalData: [...this.state.constructionModalData || [], ...items.map((x) => new ConstructionModalModel(x)),
          ],
        });
      },
    );
    await this.constructionInfinity?.retrieveAll();
    await this.constructionGrid.current?.forceRepaint();
  }

  render() {
    return (
      <SiteVisitTemplate scope={this} />
    );
  }
}
