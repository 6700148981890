import * as React from 'react';
import { FiArrowRightCircle } from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  ModalView,
  OptionBox,
  SearchLayout, SubHeader,
  TabLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Enrollment } from './Enrollment';
import { EnrollmentTab } from './Enrollment.tab';
import { SearchDateRange } from '../../../../components/search';
import { EnrollmentGridHeader } from './headers/EnrollmentGridHeader';
import { SearchBinding } from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { Format } from '../../../../utils/string';



// 계약구분
const Gubuns = [
  { key: '%', value: '전체' },
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];


/**
 * 화면
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export const EnrollmentTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);
  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new EnrollmentModel({
      ...content,
      ...data,
    }, content?.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 공사명, 품목, 품목규격으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <Button onClick={() => scope.onCopyButtonClicked()}>
          견적서복사
        </Button>

        <Button onClick={() => scope.onEmailButtonClicked()}>
          {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}EMAIL{'\u00A0'}{'\u00A0'}{'\u00A0'}
        </Button>

        <Button onClick={() => scope.onFaxButtonClicked()}>
          {'\u00A0'}{'\u00A0'} {'\u00A0'}{'\u00A0'}
          {'\u00A0'}{'\u00A0'}FAX{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
        </Button>

        <Button
          onClick={() => scope.onSubmitButtonClicked()}
          style={scope.state.content?.appgubun === '101' ? {
            opacity: 0.5,
          } : {}}
        >
          {'\u00A0'}{'\u00A0'}
          {scope.state.content?.isReported ? '상신취소' : '결재상신'}
          {'\u00A0'}{'\u00A0'}
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout size={500}>
          <GridLayout
            ref={scope.grid}
            infinityHandler={scope.infinity}
            header={EnrollmentGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={1.5}
          disabled={!content?.custcd && !content?.isNew}
          disabledText="신규로 추가하거나 견적서를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="견적일자"
              isNeed={true}
            >
              <DateTextBox
                format="####-##-##"
                textAlign="center"
                value={content?.costdate}
                onChange={(v) => setContent({ costdate: v })}
                readonly={!content?.isNew}
              />

              <TextBox
                textAlign="center"
                value={content?.costnum}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="문서번호">
              <FormatTextBox
                textAlign="center"
                format="######-###-####"
                value={content?.appnum}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="계약구분">
              <ComboBox
                value={content?.contflag}
                textAlign="center"
                data={Gubuns.map((x) => new ComboBoxModel(x.key, x.value))}
                onSelect={(option) => scope.headerItemChanged('contflag', option.value)}
              />
            </LabelBox>

            <LabelBox title="매출구분">
              <ComboBox
                value={content?.gubun}
                data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
                onSelect={(option) => setContent({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox title="견적요청자">
              <TextBox
                textAlign="center"
                value={content?.recostpernm}
                onChange={(v) => setContent({ recostpernm: v })}
              />
            </LabelBox>

            <LabelBox
              title="견적담당"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content?.costperid}
                textAlign="center"
                onChange={(value) => setContent({ costperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      costperid: item.cd,
                      costpernm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                textAlign="center"
                value={content?.costpernm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              style={Global.STYLE_COLOR_RED}
              title="거래처명(설치현장명)"
              weight={2}
            >
              <TextBox
                weight={0.4}
                value={content?.actcd}
                textAlign="center"
                onChange={(value) => setContent({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_ALL',
                  {},
                  true,
                  (item) => {
                    setContent({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.7}
                value={content?.actnm}
                onChange={(v) => setContent({ actnm: v })}
              />
            </LabelBox>

            <LabelBox
              title="공사명"
              size={392}
            >
              <TextBox
                value={content?.constnm}
                onChange={(v) => setContent({ constnm: v })}
              />
            </LabelBox>

            <LabelBox title="email" size={195}>
              <TextBox
                value={content?.permail}
                onChange={(v) => setContent({ permail: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="부가세구분">
              <OptionBox
                value={content?.taxflag}
                data={[
                  { value: '0', remark: '포함' },
                  { value: '1', remark: '별도' },
                ]}
                onChange={(item) => setContent({ taxflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="공급가액">
              <FormatNumberTextBox
                textAlign="right"
                value={content?.samt}
                onChange={(v) => setContent({ samt: v })}
              />
            </LabelBox>

            <LabelBox title="부가세액">
              <FormatNumberTextBox
                textAlign="right"
                value={content?.tamt}
                onChange={(v) => setContent({ tamt: v })}
              />
            </LabelBox>

            <LabelBox title="견적금액(합계금액)">
              <FormatNumberTextBox
                textAlign="right"
                value={content?.mamt}
                onChange={(v) => setContent({ mamt: v })}
              />
            </LabelBox>

            <LabelBox
              title="수신참조자"
              weight={2}
              footer={
                <Button
                  isIcon={true}
                  onClick={() => scope.openReferSelector()}
                >
                  <FiArrowRightCircle size={17} />
                </Button>
              }
            >
              <TextBox
                value={content?.refer}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="fax" size={195}>
              <TextBox
                value={content?.perfax}
                onChange={(v) => setContent({ perfax: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="납품장소"
              weight={2}
            >
              <TextBox
                value={content?.place}
                onChange={(v) => setContent({ place: v })}
              />
            </LabelBox>

            <LabelBox
              title="대금지불방법"
              weight={2}
            >
              <TextBox
                value={content?.iremark}
                onChange={(v) => setContent({ iremark: v })}
              />
            </LabelBox>

            <LabelBox title="납품방법">
              <TextBox
                value={content?.customer}
                onChange={(v) => setContent({ customer: v })}
              />
            </LabelBox>

            <LabelBox title="납품기간">
              <FormatTextBox
                format="주문후  ### 일"
                value={content?.ramt}
                onChange={(v) => setContent({ ramt: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1 - 10}>
            <LabelBox title="특기사항">
              <TextBox
                value={content?.remark}
                onChange={(v) => setContent({ remark: v })}
                isMultiline={true}
              />
            </LabelBox>

            <LabelBox title="비고">
              <TextBox
                value={content?.kcremark}
                onChange={(v) => setContent({ kcremark: v })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="유효기간">
              <TextBox
                value={content?.exgigan}
                onChange={(v) => setContent({ exgigan: v })}
                isMultiline={true}
              />
            </LabelBox>

            <FlexLayout>
              <LabelBox title="기타사항">
                <TextBox
                  value={content?.gita}
                  onChange={(v) => setContent({ gita: v })}
                  isMultiline={true}
                />
              </LabelBox>

              <LabelBox
                title="작성자명"
                size={80}
              >
                <TextBox
                  value={content?.inpernm}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        weight={0.59}
        disabled={!content?.custcd}
        disabledText="신규로 추가하거나 견적서를 선택해주세요"
      >
        <TabLayout
          scope={scope}
          tabs={EnrollmentTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>


      {/* 품목 선택 모달 */}
      <ModalView
        width={800}
        height={500}
        isVisible={scope.state.isVisibleDetailItemSelector}
        onClose={() => scope.closeDetailItemSelector()}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.detailItemSelectorSearchQuery}
                onChange={(value) => setData({ detailItemSelectorSearchQuery: value })}
                onEnter={() => scope.retrieveDetailItemSelector(scope.state.detailItemSelectorSearchQuery)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              ref={scope.gridDetailItemSelector}
              header={[
                {
                  id: 'pcode',
                  text: '품목코드',
                  width: 10,
                },
                {
                  id: 'pname',
                  text: '품목명',
                  width: 15,
                  render: (x) => (
                    <FlexLayout
                      align="center"
                      justify="start"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 35,
                  render: (x) => (
                    <FlexLayout
                      align="center"
                      justify="start"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      align="center"
                      justify="end"
                    >
                      {Format.number(x.uamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      align="center"
                      justify="start"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinityDetailItemSelector}
              data={scope.state.detailItemSelectorData}
              onRowClick={(item) => scope.onSelectedDetailItem(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onSelectDetailItem()}>확인</Button>
              <Button onClick={() => scope.closeDetailItemSelector()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
