import * as React from 'react';
import { action } from 'mobx';
import {
  Category, PageProps, PageToolEvents,
} from '../../../../constants';
import { InvoiceTemplate } from './Invoice.template';
import { InfinityRetrieve } from '../../../../models/common';
import { InvoiceModel } from './models/Invoice.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


interface InvoiceState {

  // 검색조건
  searchQuery: string; // 검색어
  year: string; // 년도

  invoiceList: Array<InvoiceModel>;
  data: InvoiceModel;

  fileDetailModal: boolean; // 첨부파일
}

/**
 * 컨트롤러
 * @window w_tb_ca900
 * @category 송장등록
 */
export class Invoice extends PageComponent<PageProps, InvoiceState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  updatedRows?: Array<InvoiceModel>;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      year: `${year}`,
      invoiceList: [],
      data: new InvoiceModel(),
      fileDetailModal: false,
    };
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        year: this.state.year,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          invoiceList: [
            ...this.state.invoiceList,
            ...items.map((item) => new InvoiceModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: new InvoiceModel(),
          invoiceList: [],
        });
        await this.infinity?.retrieve();
        this.table.current?.update(true);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: new InvoiceModel(),
      invoiceList: [],
    }, async () => {
      await this.infinity?.retrieve();
      await this.table.current?.setFocus(0);
      await this.table.current?.update(true);
    });
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }

    const { actionStore: api } = this.props;
    const data = await api.new({
      year: this.state.year,
    });
    if (data) {
      const newModel = new InvoiceModel(data, true);

      this.setState({
        data: newModel,
        invoiceList: [
          newModel,
          ...this.state.invoiceList,
        ],
      }, async () => {
        this.table.current?.setFocus(0, 0);
        this.table.current?.update(false);
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      year: this.state.year,
      items: this.updatedRows,
    }, this.state.data?.new === '1')) {
      await this.onRetrieveEvent();
      this.updatedRows = [];
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `번호: ${this.state.data?.seq}, 송장번호: ${this.state.data?.num}`;
    await api.delete(text, this.state.data) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.invoiceList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      year: this.state.year,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.invoiceList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      year: this.state.year,
    });
  }

  @action
  async smsEvent() {
    // const { actionStore: api } = this.props;

    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    const data = await api.exec(Category.EMAIL, 'wb_sms', {

      callcc: this.state.data?.tel,
      pernm: this.state.data?.toname,
    });


    modalStore.openSMS(data?.callcc, data?.text);

    await api.smsSend(
      {
        callcc: this.state.data?.tel,
        pernm: this.state.data?.toname,
      },
    );
  }

  @action
  onUpdatedRows(rows: Array<InvoiceModel>, updatedRows: Array<InvoiceModel>) {
    this.updatedRows = updatedRows;
    this.setState({ invoiceList: rows });
  }

  @action
  onRowFocusEvent(item:any) {
    this.setState({ data: item });
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen });
  }

  render() {
    return (
      <InvoiceTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new InvoiceModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
