/**
 * 모델
 * @window w_pa108w
 * @category 자체점검자격현황
 */
import { computed } from 'mobx';

export class TrueModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 자격종류
   *
   * **data type** char(800)
   */
  licence: string;

  /**
   * 발생기관
   *
   * **data type** char(800)
   */
  licencewhere: string;


  /**
   * 자격증번호
   *
   * **data type** char(800)
   */
  licencenum: string;


  /**
   * 취득일
   *
   * **data type** char(8)
   */
  licenceday1: string;


  /**
   * 만료일
   *
   * **data type** char(8)
   */
  licenceday2: string;

  /**
   * 모름
   *
   * **data type** 모름
   */
  getdate: string;

  /**
   * 입사일
   *
   * **data type** 모름
   */
  entdate: string;

  /**
   * 자격취등예정일
   *
   * **data type** 모름
   */
  gooddate: string;

  /**
   * 자격만료월
   *
   * **data type** 모름
   */
  stmon: string;

  /**
   * 자체점검
   *
   * **data type** 모름
   */
  checkflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.licence = data.licence || '';
    this.licencewhere = data.licencewhere || '';
    this.licencenum = data.licencenum || '';
    this.licenceday1 = data.licenceday1 || '';
    this.licenceday2 = data.licenceday2 || '';
    this.getdate = data.getdate || '';
    this.entdate = data.entdate || '';
    this.gooddate = data.gooddate || '';
    this.stmon = data.stmon || '';
    this.checkflag = data.checkflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
