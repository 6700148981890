import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Status } from '../Status';
import { StatusGridDetailHeader } from '../headers/StatusGridDetailHeader';

/**
 * 화면 > 탭 A
 * @window w_tb_e471w
 * @category 공사현황
 */
export const StatusTabDetailTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridDetail}
      infinityHandler={scope.infinityDetail}
      header={StatusGridDetailHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataDetail}
      onRowClick={(item, index) => scope.onDetailRowFocusEvent(item, index)}
    />
  </FlexLayout>
);
