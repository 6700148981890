import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { Maintenance } from '../Maintenance';
import {
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
} from '../../../../../components';
import { MaintenanceTabMonthGridHeader } from '../headers/MaintenanceTabMonthGridHeader';

export const MaintenanceMonthTemplate: React.FC<TemplateProps<Maintenance>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayoutAdditionalHeader
      ref={scope.additionalMonthHeader}
      height={50}
    >
      <FlexLayout
        justify="center"
        align="center"
        size={160}
      >
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={138}
        style={{ fontSize: 12, fontWeight: 1000 }}
      >
        관리대수
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={1506}
        style={{ fontSize: 13, fontWeight: 1000 }}
        isVertical={true}
      >
        <FlexLayout>유지보수/부품교체/수리공사 실적내역</FlexLayout>
        <FlexLayout>
          <FlexLayout style={{ borderTop: '2px solid white', borderRight: '2px solid white' }}>유지보수</FlexLayout>
          <FlexLayout style={{ borderTop: '2px solid white', borderRight: '2px solid white' }}>부품교체</FlexLayout>
          <FlexLayout style={{ borderTop: '2px solid white' }}>수리공사</FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={68}
        style={{ fontSize: 13, fontWeight: 1000 }}
        isVertical={true}
      >
        <FlexLayout>점검일지</FlexLayout>
        <FlexLayout>(건수)</FlexLayout>
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={348}
        style={{ fontSize: 13, fontWeight: 1000 }}
        isVertical={true}
      >
        <FlexLayout>고장</FlexLayout>
        <FlexLayout>(건수)</FlexLayout>
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={278}
        style={{ fontSize: 13, fontWeight: 1000 }}
        isVertical={true}
      >
        <FlexLayout>검사</FlexLayout>
        <FlexLayout>(건수)</FlexLayout>
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={278}
        style={{ fontSize: 13, fontWeight: 1000 }}
        isVertical={true}
      >
        <FlexLayout>계약</FlexLayout>
        <FlexLayout>(건수)</FlexLayout>
      </FlexLayout>
      <FlexLayout
        justify="center"
        align="center"
        size={193}
        style={{ fontSize: 13, fontWeight: 1000 }}
        isVertical={true}
      >
        <FlexLayout>장기미수금</FlexLayout>
        <FlexLayout>(금액)</FlexLayout>
      </FlexLayout>
    </GridLayoutAdditionalHeader>
    <GridLayout
      ref={scope.grid}
      header={MaintenanceTabMonthGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      // headerHeight={50}
      data={scope.state.MaintenanceList}
      infinityHandler={scope.infinity}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      onHorizontalScroll={(x) => { scope.additionalMonthHeader.current?.scrollTo(x); }}
    />
  </FlexLayout>
);
