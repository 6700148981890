import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642_ats
 * @category 예금관리
 */
export class ManageDepositModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래일자
   *
   * **data type** varchar(8)
   */
  snddate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  sndnum: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입출금
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 예금금액
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 은행코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 은행번호
   *
   * **data type** varchar(20)
   */
  bankno: string;

  /**
   * 항목코드
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 입력일
   *
   * **data type** char(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  input: string;

  /**
   * 현금금액
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 그룹매입일자
   *
   * **data type** varchar(8)
   */
  gmijdate: string;

  /**
   * 그룹매일번호
   *
   * **data type** varchar(4)
   */
  gmijnum: string;

  /**
   * 그룹지급일자
   *
   * **data type** varchar(8)
   */
  gsnddate: string;

  /**
   * 그룹지급번호
   *
   * **data type** varchar(4)
   */
  gsndnum: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  compflag: string;

  /**
   * 은행연동
   *
   * **data type** varchar(1)
   */
  interlock: string;

  /**
   * 예금연동매입일자
   *
   * **data type** varchar(8)
   */
  mismijdate: string;

  /**
   * 예금연동매입번호
   *
   * **data type** varchar(4)
   */
  mismijnum: string;

  /**
   * 예금연동지급일자
   *
   * **data type** varchar(8)
   */
  rcvsnddate: string;

  /**
   * 예금연동지급번호
   *
   * **data type** varchar(4)
   */
  rcvsndnum: string;

  /**
   * 입출금처리
   *
   * **data type** varchar(1)
   */
  mismijchk: string;

  /**
   * 은행연동일자
   *
   * **data type** varchar(8)
   */
  interdate: string;

  /**
   * 은행연동번호
   *
   * **data type** varchar(4)
   */
  interseq: string;

  /**
   * 연동은행
   *
   * **data type** varchar(10)
   */
  interbank: string;

  /**
   * 계정코드
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 항목명
   *
   * **data type** varchar(50)
   */
  artnm: string;

  /**
   * 항목명
   *
   * **data type** varchar(4)
   */
  accnm: string;

  /**
   * 예금금액
   *
   * **data type** number
   */
  st_totamt: string;

  /**
   * 정산금액
   *
   * **data type** number
   */
  st_resuamt: string;

  /**
   * 잔액
   *
   * **data type** number
   */
  st_janamt: string;

  /**
   * -
   *
   * **data type** number
   */
  janamt: string;

  /**
   * -
   *
   * **data type** number
   */
  misdate: string;

  /**
   * -
   *
   * **data type** number
   */
  misnum: string;

  /**
   * -
   *
   * **data type** number
   */
  actcd: string;

  /**
   * -
   *
   * **data type** number
   */
  whamt: string;

  /**
   * -
   *
   * **data type** number
   */
  wbamt: string;

  /**
   * -
   *
   * **data type** number
   */
  chk: string;

  /**
   * -*
   *
   * **data type** varchar(1)
   */
  billkind: string;

  /**
   * -*
   *
   * **data type** varchar(1)
   */
  divicd: string;

  /**
   * -*
   *
   * **data type** varchar(1)
   */
  billgubun: string;

  /**
   * -*
   *
   * **data type** varchar(1)
   */
  projno: string;

  /**
   * 은행
   *
   * **data type** varchar(8)
   */
  banknm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(8)
   */
  accnum: string;

  /**
   * 발생거래처
   *
   * **data type** varchar(8)
   */
  mijcltnm: string;

  /**
   * 발생금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 임시금액저장
   *
   * **data type** number
   */
  winamt: string;

  /**
   * 기지급액
   *
   * **data type** number
   */
  inamt: string;

  /**
   * 기타
   *
   * **data type** number
   */
  wgamt: string;

  /**
   * 지급예정일
   *
   * **data type** number
   */
  schdate: string;

  /**
   * 게산서일자
   *
   * **data type** number
   */
  tax_spdate: string;

  /**
   * 번호
   *
   * **data type** number
   */
  tax_spnum: string;

  /**
   * 현장명
   *
   * **data type** varchar(50)
   */
  actnm: string;

  /**
   * 상태
   *
   * **data type** varchar(50)
   */
  recivstatus: string;

  /**
   * 상태
   *
   * **data type** varchar(50)
   */
  gubun2: string;

  /**
   * 매출일자
   *
   * **data type** number
   */
  mijdate: string;

  /**
   * 매출번호
   *
   * **data type** number
   */
  mijnum: string;

  /**
   * 매출액
   *
   * **data type** number
   */
  misamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || 'ZZ';
    this.tax_spnum = data.tax_spnum || '';
    this.actnm = data.actnm || '';
    this.misamt = data.misamt || '';
    this.recivstatus = data.recivstatus || '';
    this.mijcltnm = data.mijcltnm || '';
    this.schdate = data.schdate || '';
    this.tax_spdate = data.tax_spdate || '';
    this.inamt = data.inamt || '';
    this.wgamt = data.wgamt || '';
    this.billkind = data.billkind || '';
    this.banknm = data.banknm || '';
    this.mijamt = data.mijamt || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.accnum = data.accnum || '';
    this.projno = data.projno || '';
    this.billgubun = data.billgubun || '';
    this.divicd = data.divicd || '';
    this.janamt = data.janamt || '';
    this.misdate = data.misdate || '';
    this.chk = data.chk || '';
    this.misnum = data.misnum || '';
    this.whamt = data.whamt || '';
    this.wbamt = data.wbamt || '';
    this.actcd = data.actcd || '';
    this.cltcd = data.cltcd || '';
    this.snddate = data.snddate || '';
    this.sndnum = data.sndnum || '';
    this.remark = data.remark || '';
    this.gubun = data.gubun || '1';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.bankno = data.bankno || '';
    this.artcd = data.artcd || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.cltnm = data.cltnm || '';
    this.input = data.input || '';
    this.hamt = data.hamt || '';
    this.winamt = data.winamt || '';
    this.gmijdate = data.gmijdate || '';
    this.gmijnum = data.gmijnum || '';
    this.gsnddate = data.gsnddate || '';
    this.gsndnum = data.gsndnum || '';
    this.compflag = data.compflag || '';
    this.interlock = data.interlock || '';
    this.mismijdate = data.mismijdate || '';
    this.mismijnum = data.mismijnum || '';
    this.rcvsnddate = data.rcvsnddate || '';
    this.rcvsndnum = data.rcvsndnum || '';
    this.mismijchk = data.mismijchk || '';
    this.interdate = data.interdate || '';
    this.interseq = data.interseq || '';
    this.interbank = data.interbank || '';
    this.acccd = data.acccd || '';
    this.artnm = data.artnm || '';
    this.accnm = data.accnm || '';
    this.st_totamt = data.st_totamt || '';
    this.st_resuamt = data.st_resuamt || '';
    this.st_janamt = data.st_janamt || '';
    this.gubun2 = data.gubun2 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
