import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { ManageStatus } from './ManageStatus';
import { TabModel } from '../../../../models';
import {
  ManageStatusDetailTabDetailTemplate,
  ManageStatusDetailTabBalTemplate,
  ManageStatusDetailTabMemoTemplate,
  ManageStatusDetailTabEstimateTemplate,
  ManageStatusDetailTabSalesTemplate,
} from './tabs';

/**
 * 화면 > 탭 - 하단
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusDetailTabTemplate: Array<TabLayoutDataItem<ManageStatus>> = [
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '상세내역'),
    template: (scope) => <ManageStatusDetailTabDetailTemplate scope={scope} />,
  },
  {
    tab: new TabModel('BAL', FaCodeBranch, '수주/발주내역'),
    template: (scope) => <ManageStatusDetailTabBalTemplate scope={scope} />,
  },
  {
    tab: new TabModel('MEMO', FaCodeBranch, '현장협의사항'),
    template: (scope) => <ManageStatusDetailTabMemoTemplate scope={scope} />,
  },
  {
    tab: new TabModel('ESTIMATE', FaCodeBranch, '견적내역'),
    template: (scope) => <ManageStatusDetailTabEstimateTemplate scope={scope} />,
  },
  {
    tab: new TabModel('SALES', FaCodeBranch, '영업내역'),
    template: (scope) => <ManageStatusDetailTabSalesTemplate scope={scope} />,
  },
];
