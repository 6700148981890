/**
 * 모델
 * @window w_tb_e601w_03
 * @category 보수마감통계
 */
export class MaintenanceModel {
  /**
   * 회사코드
   *
   * **data type** varchar()
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar()
   */
  readonly spjangcd: string;

  /**
   * 부서코드
   *
   * **data type** varchar()
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar()
   */
  readonly divinm: string;

  /**
   * 담당자코드
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar()
   */
  readonly pernm: string;

  /**
   * 관리대수(현장)
   *
   * **data type** varchar()
   */
  readonly cnt: string;

  /**
   * 관리대수(대수)
   *
   * **data type** varchar()
   */
  readonly qty: string;

  /**
   * 부품교체-계획금액
   *
   * **data type** varchar()
   */
  readonly planamt11: string;

  /**
   * 부품교체-견적건수
   *
   * **data type** varchar()
   */
  readonly costcnt11: string;

  /**
   * 부품교체-견적금액
   *
   * **data type** varchar()
   */
  readonly costamt11: string;

  /**
   * 부품교체-매출(실적)
   *
   * **data type** varchar()
   */
  readonly misamt11: string;

  /**
   * 부품교체-미달성금액
   *
   * **data type** varchar()
   */
  readonly notmisamt11: string;

  /**
   * 부품교체-달성율
   *
   * **data type** varchar()
   */
  readonly planyul11: string;

  /**
   * 부품교체-계획금액
   *
   * **data type** varchar()
   */
  readonly planamt13: string;

  /**
   * 부품교체-견적건수
   *
   * **data type** varchar()
   */
  readonly costcnt13: string;

  /**
   * 부품교체-견적금액
   *
   * **data type** varchar()
   */
  readonly costamt13: string;

  /**
   * 부품교체-매출(실적)
   *
   * **data type** varchar()
   */
  readonly misamt13: string;

  /**
   * 부품교체-미달성금액
   *
   * **data type** varchar()
   */
  readonly notmisamt13: string;

  /**
   * 부품교체-달성율
   *
   * **data type** varchar()
   */
  readonly planyul13: string;

  /**
   * 수리공사-계획금액
   *
   * **data type** varchar()
   */
  readonly planamt12: string;

  /**
   * 수리공사-견적건수
   *
   * **data type** varchar()
   */
  readonly costcnt12: string;

  /**
   * 수리공사-견적금액
   *
   * **data type** varchar()
   */
  readonly costamt12: string;

  /**
   * 수리공사-매출(실적)
   *
   * **data type** varchar()
   */
  readonly misamt12: string;

  /**
   * 수리공사-미달성금액
   *
   * **data type** varchar()
   */
  readonly notmisamt12: string;

  /**
   * 수리공사-달성율
   *
   * **data type** varchar()
   */
  readonly planyul12: string;

  /**
   * 점검일지 B,C
   *
   * **data type** varchar()
   */
  readonly bccnt: string;

  /**
   * 고장-고장
   *
   * **data type** varchar()
   */
  readonly rececnt: string;

  /**
   * 고장-처리
   *
   * **data type** varchar()
   */
  readonly rececompcnt: string;

  /**
   * 고장-미처리
   *
   * **data type** varchar()
   */
  readonly recenotcnt: string;

  /**
   * 고장-다발
   *
   * **data type** varchar()
   */
  readonly wadcnt: string;

  /**
   * 고장-사람갇힘
   *
   * **data type** varchar()
   */
  readonly troublecnt: string;

  /**
   * 검사-검사
   *
   * **data type** varchar()
   */
  readonly kccnt: string;

  /**
   * 검사-합격
   *
   * **data type** varchar()
   */
  readonly kc001cnt: string;

  /**
   * 검사-불합격
   *
   * **data type** varchar()
   */
  readonly kc002cnt: string;

  /**
   * 검사-조건부
   *
   * **data type** varchar()
   */
  readonly kc003cnt: string;

  /**
   * 계약-일반
   *
   * **data type** varchar()
   */
  readonly contpog: string;

  /**
   * 계약-FM
   *
   * **data type** varchar()
   */
  readonly contfm: string;

  /**
   * 계약-다년
   *
   * **data type** varchar()
   */
  readonly contlong: string;

  /**
   * 계약-해지
   *
   * **data type** varchar()
   */
  readonly contcls: string;

  /**
   * 장기미수금-미수금
   *
   * **data type** varchar()
   */
  readonly misamt: string;

  /**
   * 장기미수금-수금
   *
   * **data type** varchar()
   */
  readonly rcvamt: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.cnt = data.cnt || '';
    this.qty = data.qty || '';
    this.planamt11 = data.planamt11 || '';
    this.costcnt11 = data.costcnt11 || '';
    this.costamt11 = data.costamt11 || '';
    this.misamt11 = data.misamt11 || '';
    this.notmisamt11 = data.notmisamt11 || '';
    this.planyul11 = data.planyul11 || '';
    this.planamt13 = data.planamt13 || '';
    this.costcnt13 = data.costcnt13 || '';
    this.costamt13 = data.costamt13 || '';
    this.misamt13 = data.misamt13 || '';
    this.notmisamt13 = data.notmisamt13 || '';
    this.planyul13 = data.planyul13 || '';
    this.planamt12 = data.planamt12 || '';
    this.costcnt12 = data.costcnt12 || '';
    this.costamt12 = data.costamt12 || '';
    this.misamt12 = data.misamt12 || '';
    this.notmisamt12 = data.notmisamt12 || '';
    this.planyul12 = data.planyul12 || '';
    this.bccnt = data.bccnt || '';
    this.rececnt = data.rececnt || '';
    this.rececompcnt = data.rececompcnt || '';
    this.recenotcnt = data.recenotcnt || '';
    this.wadcnt = data.wadcnt || '';
    this.troublecnt = data.troublecnt || '';
    this.kccnt = data.kccnt || '';
    this.kc001cnt = data.kc001cnt || '';
    this.kc002cnt = data.kc002cnt || '';
    this.kc003cnt = data.kc003cnt || '';
    this.contpog = data.contpog || '';
    this.contfm = data.contfm || '';
    this.contlong = data.contlong || '';
    this.contcls = data.contcls || '';
    this.misamt = data.misamt || '';
    this.rcvamt = data.rcvamt || '';
  }
}
