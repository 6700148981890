import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { OneDayDeadline } from '../OneDayDeadline';
import { ImportModel } from '../models';

// 수입내역
const Import: GridLayoutHeader<ImportModel, OneDayDeadline>[] = [
  {
    id: 'misdate',
    text: '발생일자',
    width: 13,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 10,
  },
  {
    id: 'remark',
    text: '적요',
    width: 30,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '지급처명',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '수금액',
    width: 13,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.iamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.iamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '미수잔액',
    width: 13,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
];

export default Import;
