import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { MonthProjectsTemplate } from './MonthProjects.template';
import { MonthProjectsModel } from './MonthProjects.model';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface MonthProjectsState {
  // search
  year: string;
  searchGubun?: string;
  gubuns?: Array<any>;
  state: string;

  // data
  data: Array<MonthProjectsModel>;
  monthProjectList: Array<MonthProjectsModel>;
  focused?: MonthProjectsModel;

  // trail
  camt_tot: string;
  balamt_tot: string;
  misamt_tot: string;
  mijamt_tot: string;
  resuamt_tot: string;
  resuyul_tot: string;
  resuamt2_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da003_03w
 * @category 월별프로젝트현황
 */
export class MonthProjects extends PageComponent<PageProps, MonthProjectsState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // search
      year: Today.year(),
      searchGubun: '%',
      state: '%',

      // data
      data: [],
      monthProjectList: [],

      // trail
      camt_tot: '0',
      balamt_tot: '0',
      misamt_tot: '0',
      mijamt_tot: '0',
      resuamt_tot: '0',
      resuyul_tot: '0',
      resuamt2_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 구분
    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubuns: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        gubun: this.state.searchGubun,
        state: this.state.state,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MonthProjectsModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
          monthProjectList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      monthProjectList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['gubunnm', 'totmon'],
        [this.state.focused?.gubunnm, this.state.focused?.totmon], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        this.grid.current?.setFocus(index);
        this.onRetrieveEvent2();
      }
    });
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        year: this.state.year,
        gubun: this.state.searchGubun,
        state: this.state.state,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.setState({
          monthProjectList: [
            ...this.state.monthProjectList,
            ...items.map((x: any) => new MonthProjectsModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          monthProjectList: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.monthProjectList && this.state.monthProjectList?.length > 0) {
          await this.grid2.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      monthProjectList: [],
    }, async () => {
      await this.infinity2?.retrieveAll();

      this.SS({
        camt_tot: this.infinity2?.box?.camt_tot || '0',
        balamt_tot: this.infinity2?.box?.balamt_tot || '0',
        misamt_tot: this.infinity2?.box?.misamt_tot || '0',
        mijamt_tot: this.infinity2?.box?.mijamt_tot || '0',
        resuamt_tot: this.infinity2?.box?.resuamt_tot || '0',
        resuyul_tot: this.infinity2?.box?.resuyul_tot || '0',
        resuamt2_tot: this.infinity2?.box?.resuamt2_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent(item: MonthProjectsModel) {
    this.setState({ focused: item });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      gubun: this.state.searchGubun,
      state: this.state.state,
    });
  }

  // 상단의 grid 해당월 클릭시 하단 grid2의 로우포커스 해당월로 이동
  @action
  onRowColorFocusEvent(item: any) {
    let monthData = '';
    let row: number = 0;

    this.state.monthProjectList.forEach((x: any) => {
      if (item === x.orddate.substr(0, 6)) {
        monthData = x.projno;
      }
    });

    this.state.monthProjectList.forEach((x: any, n) => {
      if (monthData === x.projno) {
        row = n;
      }
    });
    this.grid2.current?.setFocus(row);
  }

  // 상단 grid 색상
  getColotEvent(name: any) {
    switch (name) {
      case '수주금액':
        return { color: '#7F7A2C' };

      case '손익액':
        return { color: '#5B1A86' };

      case '손익률':
        return { color: '#5B1A86' };

      case '매출금액':
        return { color: 'var(--color-blue)' };

      case '소요경비':
        return { color: 'var(--color-red)' };

      default:
        return {};
    }
  }

  render() {
    return (
      <MonthProjectsTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
