import { GridLayoutHeader } from '../../../../../constants';
import { TrueModel } from '../models';
import { SelfCertificate } from '../SelfCertificate';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { TextBox } from '../../../../../components/forms/TextBox';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';


// 자격증 소지자

const True: GridLayoutHeader<TrueModel, SelfCertificate>[] = [
  {
    id: 'spjangcd',
    text: '사업장',
    width: 10,
    render: (x, scope) => {
      const find = scope?.state.spjangcds?.filter((y) => y.spjangcd === x.spjangcd);
      const spjangnm = find?.length ? find[0].spjangnm : x.spjangcd;
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {spjangnm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'perid',
    text: '코드',
    width: 5,
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 5,
  },
  {
    id: 'licence',
    text: '자격종류',
    width: 20,
    render: (x) => (
      <TextBox
        textAlign="left"
        value={x.licence}
      />
    ),
  },
  {
    id: 'licencewhere',
    text: '발생기관',
    width: 15,
    render: (x) => (
      <TextBox
        textAlign="left"
        value={x.licencewhere}
      />
    ),
  },
  {
    id: 'licencenum',
    text: '자격증번호',
    width: 15,
  },
  {
    id: 'licenceday1',
    text: '취득일',
    width: 8,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.licenceday1}
      />
    ),
  },
  {
    id: 'licenceday2',
    text: '만료일',
    width: 10,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.licenceday2}
      />
    ),
  },
];


export default True;
