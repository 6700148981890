import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e037_type2
 * @category 개별업무일지
 */

export class SelfModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly plantime: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly planmon: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly kcspnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly result: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly kcperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly kcpernm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly plangubun: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly contg: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.plandate = data.plandate || '';
    this.plantime = data.plantime || '';
    this.planmon = data.planmon || '';
    this.rptdate = data.rptdate || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.kcspnm = data.kcspnm || '';
    this.gubun = data.gubun || '002';
    this.result = data.result || '';
    this.remark = data.remark || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.kcperid = data.kcperid || '';
    this.kcpernm = data.kcpernm || '승강기관리원';
    this.plangubun = data.plangubun || '1';
    this.state = data.state || '1';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.contg = data.contg || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
