import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_b502
 * @category 영업보고서
 */
export default class ReportModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 상담자연락처
   *
   * **data type** varchar(8)
   */
  readonly advicehpnum: string;

  /**
   * 상담자메일
   *
   * **data type** varchar(8)
   */
  readonly advicemail: string;

  /**
   * 상담자명
   *
   * **data type** varchar(8)
   */
  readonly advicepernm: string;

  /**
   * 상담내용
   *
   * **data type** varchar(8)
   */
  readonly adviceremark: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly amt: string;

  /**
   * 결재일
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 결재구분
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   * 문서번호
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 결재자
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   * 결재권자 지시사항
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   * 결재시간
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   * 첨부파일갯수
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  /**
   * 보수업체 번호
   *
   * **data type** varchar(8)
   */
  readonly bosuhpnum: string;

  /**
   * 보수업체명
   *
   * **data type** varchar(8)
   */
  readonly bosunm: string;

  /**
   * 보수업체 담당자명
   *
   * **data type** varchar(8)
   */
  readonly bosupernm: string;

  /**
   * 보수업체 번호
   *
   * **data type** varchar(8)
   */
  readonly bosutelnum: string;

  /**
   * 완료일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 종료시간
   *
   * **data type** varchar(8)
   */
  readonly endtime: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 입력자명
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 위도
   *
   * **data type** varchar(8)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(8)
   */
  readonly lng: string;

  /**
   * 모델명
   *
   * **data type** varchar(8)
   */
  readonly mdnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 사진유무
   *
   * **data type** varchar(8)
   */
  readonly picflag: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 계획번호
   *
   * **data type** varchar(8)
   */
  readonly plannum: string;

  /**
   * 댓수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 수신참조자
   *
   * **data type** varchar(8)
   */
  readonly refer: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 접수자
   *
   * **data type** varchar(8)
   */
  readonly reperid: string;

  /**
   * 접수자명
   *
   * **data type** varchar(8)
   */
  readonly repernm: string;

  /**
   * 조치내용
   *
   * **data type** varchar(8)
   */
  readonly resultremark: string;

  /**
   * 사업자
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 현장보수담당자사번
   *
   * **data type** varchar(8)
   */
  readonly actperid: string;

  /**
   * 현장보수담당자
   *
   * **data type** varchar(8)
   */
  readonly actpernm: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 시작시간
   *
   * **data type** varchar(8)
   */
  readonly sttime: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcd: string;

  /**
   * 최초접수일자
   *
   * **data type** varchar(8)
   */
  readonly recedate: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.advicehpnum = data.advicehpnum || '';
    this.advicemail = data.advicemail || '';
    this.advicepernm = data.advicepernm || '';
    this.adviceremark = Fix.newline(data.adviceremark) || '';
    this.amt = data.amt || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.apptime = data.apptime || '';
    this.attcnt = data.attcnt || '';
    this.bigo = data.bigo || '';
    this.bosuhpnum = data.bosuhpnum || '';
    this.bosunm = data.bosunm || '';
    this.bosupernm = data.bosupernm || '';
    this.bosutelnum = data.bosutelnum || '';
    this.compdate = data.compdate || '';
    this.contdate = data.contdate || '';
    this.enddate = data.enddate || '';
    this.endtime = data.endtime || '';
    this.gubun = data.gubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.actperid = data.actperid || '';
    this.actpernm = data.actpernm || '';
    this.inpernm = data.inpernm || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.mdnm = data.mdnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.picflag = data.picflag || '';
    this.plandate = data.plandate || '';
    this.plannum = data.plannum || '';
    this.qty = data.qty || '';
    this.refer = data.refer || '';
    this.remark = Fix.newline(data.remark) || '';
    this.reperid = data.reperid || '';
    this.repernm = data.repernm || '';
    this.resultremark = Fix.newline(data.resultremark) || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.stdate = data.stdate || '';
    this.sttime = data.sttime || '';
    this.zipcd = data.zipcd || '';
    this.recedate = data.recedate || '';
    this.contflag = data.contflag || '0';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get isReported() {
    return this.appgubun && (this.appgubun !== '' && this.appgubun !== '131');
  }

  @computed
  get isReportedCancelable() {
    return this.isReported && this.appgubun !== '%';
  }
}
