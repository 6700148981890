import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da052w_02
 * @category 월매출실적현황
 */
export class MonthSaleModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 합계
   *
   * **data type** number
   */
  ordmontot: string;

  /**
   * 평균
   *
   * **data type** number
   */
  average: string;

  /**
   * 01월 매출예상금액
   *
   * **data type** number
   */
  ordmon01: string;

  /**
   * 02월 매출예상금액
   *
   * **data type** number
   */
  ordmon02: string;

  /**
   * 03월 매출예상금액
   *
   * **data type** number
   */
  ordmon03: string;

  /**
   * 04월 매출예상금액
   *
   * **data type** number
   */
  ordmon04: string;

  /**
   * 05월 매출예상금액
   *
   * **data type** number
   */
  ordmon05: string;

  /**
   * 06월 매출예상금액
   *
   * **data type** number
   */
  ordmon06: string;

  /**
   * 07월 매출예상금액
   *
   * **data type** number
   */
  ordmon07: string;

  /**
   * 08월 매출예상금액
   *
   * **data type** number
   */
  ordmon08: string;

  /**
   * 09월 매출예상금액
   *
   * **data type** number
   */
  ordmon09: string;

  /**
   * 10월 매출예상금액
   *
   * **data type** number
   */
  ordmon10: string;

  /**
   * 11월 매출예상금액
   *
   * **data type** number
   */
  ordmon11: string;

  /**
   * 12월 매출예상금액
   *
   * **data type** number
   */
  ordmon12: string;

  /**
   * 01월 매출발행금액
   *
   * **data type** number
   */
  mon01: string;

  /**
   * 02월 매출발행금액
   *
   * **data type** number
   */
  mon02: string;

  /**
   * 03월 매출발행금액
   *
   * **data type** number
   */
  mon03: string;

  /**
   * 04월 매출발행금액
   *
   * **data type** number
   */
  mon04: string;

  /**
   * 05월 매출발행금액
   *
   * **data type** number
   */
  mon05: string;

  /**
   * 06월 매출발행금액
   *
   * **data type** number
   */
  mon06: string;

  /**
   * 07월 매출발행금액
   *
   * **data type** number
   */
  mon07: string;

  /**
   * 08월 매출발행금액
   *
   * **data type** number
   */
  mon08: string;

  /**
   * 09월 매출발행금액
   *
   * **data type** number
   */
  mon09: string;

  /**
   * 10월 매출발행금액
   *
   * **data type** number
   */
  mon10: string;

  /**
   * 11월 매출발행금액
   *
   * **data type** number
   */
  mon11: string;

  /**
   * 12월 매출발행금액
   *
   * **data type** number
   */
  mon12: string;

  /**
   * 년
   *
   * **data type** char(4)
   */
  year: string;

  /**
   * 월목표
   *
   * **data type** number
   */
  monamt: string;

  /**
   * 년목표
   *
   * **data type** number
   */
  yearamt: string;

  /**
   * 달성률
   *
   * **data type** number
   */
  ordmonyul: string;

  /**
   * 달성률
   *
   * **data type** number
   */
  monyul: string;

  /**
   * 합계
   *
   * **data type** number
   */
  montot: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.pernm = data.pernm || '';
    this.ordmonyul = data.ordmonyul || '';
    this.monyul = data.monyul || '';
    this.montot = data.montot || '';
    this.divinm = data.divinm || '';
    this.ordmontot = data.ordmontot || '';
    this.average = data.average || '';
    this.year = data.year || '';
    this.monamt = data.monamt || '';
    this.yearamt = data.yearamt || '';
    this.ordmon01 = data.ordmon01 || '';
    this.ordmon02 = data.ordmon02 || '';
    this.ordmon03 = data.ordmon03 || '';
    this.ordmon04 = data.ordmon04 || '';
    this.ordmon05 = data.ordmon05 || '';
    this.ordmon06 = data.ordmon06 || '';
    this.ordmon07 = data.ordmon07 || '';
    this.ordmon08 = data.ordmon08 || '';
    this.ordmon09 = data.ordmon09 || '';
    this.ordmon10 = data.ordmon10 || '';
    this.ordmon11 = data.ordmon11 || '';
    this.ordmon12 = data.ordmon12 || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
