import * as React from 'react';
import { action, computed } from 'mobx';
import {
  RiBodyScanLine,
  RiBuildingLine,
  RiCarLine,
  RiFolder2Line,
  RiPassportLine,
  RiPencilRulerLine,
  RiQuillPenLine,
  RiTeamLine,
  RiUserLine,
  RiVipCrown2Line,
} from 'react-icons/ri';
import {
  AskType, Category, ConfirmType, PageProps, PageToolEvents, RetrieveFocusType, TableLayoutHeader,
} from '../../../../constants';
import { PersonnelResistrationTemplate } from './PersonnelResistration.template';
import {
  AcademicBackgroundModel,
  AwardModel,
  BasicModel,
  BodyModel,
  CareerModel,
  CarModel,
  CertificateModel,
  EducationModel,
  FamilyModel,
  FileModel,
  UserModalModel,
} from './models';
import { TabModel } from '../../../../models/component';
import {
  Confirm, ConfirmFail, ConfirmSuccess, ConfirmWarning,
} from '../../../../utils/confirm';
import { InfinityRetrieve, SpjangItem } from '../../../../models/common';
import { GridLayout, TableLayout } from '../../../../components';
import { FileReader, FileSelector } from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';

import {
  TabHeaderAcademicBackground, TabHeaderAward, TabHeaderCar, TabHeaderCareer, TabHeaderCertificate, TabHeaderEducation, TabHeaderFamilly,
} from './tabs';
import { ModalModel } from './models/Modal.model';
import { PageComponent } from '../../../../utils';
import { FunctionRepository } from '../../../../repositories';

export enum PersonnelResistrationImages {
  PIC,
  SIGN,
}

export enum PersonnelResistrationTabId {
  BASIC,
  FAMILY,
  BODY,
  CAREER,
  ACADEMICBACKGROUND,
  EDUCATION,
  CERTIFICATE,
  AWARD,
  CAR,
  FILE,
}

export enum PersonnelResistrationItemChangeTypes {
  RNUMFLAG,
  RNUM,
  IS_SEX,
  ENTDATE,
  PAYIDEN,
  SPJANGCD,
  FAMINUM,
  LICENCE,
}

export const PersonnelResistrationItemChangeTypeNames = [
  'rnumflag',
  'rnum',
  'is_sex',
  'entdate',
  'payiden',
  'spjangcd',
  'faminum',
  'licence',
];

export const PersonnelResistrationTabTitles = [
  '기본정보',
  '가족',
  '신체병역',
  '경력',
  '학력',
  '교육',
  '자격증',
  '상벌사항',
  '차량등록',
  '첨부파일',
];

export const PersonnelResistrationTabModels = [
  BasicModel,
  FamilyModel,
  BodyModel,
  CareerModel,
  AcademicBackgroundModel,
  EducationModel,
  CertificateModel,
  AwardModel,
  CarModel,
  FileModel,
];

export const PersonnelResistrationTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
  'tab_7',
  'tab_8',
  'tab_9',
  'tab_10',
];

export const PersonnelResistrationTabDataStateNames = [
  'basic',
  'families',
  'body',
  'careers',
  'arcademicbackgrounds',
  'educations',
  'certificates',
  'awards',
  'cars',
  'files',
];

export const PersonnelResistrationTabUpdatesStateNames = [
  '',
  'familyUpdates',
  '',
  'careerUpdates',
  'arcademicbackgroundUpdates',
  'educationUpdates',
  'certificateUpdates',
  'awardUpdates',
  'carUpdates',
  'fileUpdates',
];

export const PersonnelResistrationTabFocusedStateNames = [
  '',
  'familiesFocused',
  '',
  'careersFocused',
  'arcademicbackgroundsFocused',
  'educationsFocused',
  'certificatesFocused',
  'awardsFocused',
  'carsFocused',
  'filesFocused',
];

export const imgFunctionNames = [
  'pic',
  'sign',
];

export const imgStateNames = [
  'img_pic',
  'img_sign',
];

export const imgStatePercentNames = [
  'percentImagePic',
  'percentImageSign',
];

export interface BankItem {
  bankcd: string;
  banknm: string;
}

export interface staffListItem{
  divicd?: string;
  pernm?: string;
}


export interface PersonnelResistrationState {
  // 기본정보
  basic?: BasicModel;
  lastNewData?: BasicModel;

  // 가족
  families?: Array<FamilyModel>;
  familyUpdates?: Array<FamilyModel>;
  familiesFocused?: FamilyModel;

  // 신체병역
  body?: BodyModel;

  // 경력
  careers?: Array<CareerModel>;
  careerUpdates?: Array<CareerModel>;
  careersFocused?: CareerModel;

  // 학력
  arcademicbackgrounds?: Array<AcademicBackgroundModel>;
  arcademicbackgroundUpdates?: Array<AcademicBackgroundModel>;
  arcademicbackgroundsFocused?: AcademicBackgroundModel;

  // 교육
  educations?: Array<EducationModel>;
  educationUpdates?: Array<EducationModel>;
  educationsFocused?: EducationModel;

  // 자격증
  certificates?: Array<CertificateModel>;
  certificateUpdates?: Array<CertificateModel>;
  certificatesFocused?: CertificateModel;

  // 상벌사항
  awards?: Array<AwardModel>;
  awardUpdates?: Array<AwardModel>;
  awardsFocused?: AwardModel;

  // 차량등록
  cars?: Array<CarModel>;
  carUpdates?: Array<CarModel>;
  carsFocused?: CarModel;

  // 첨부파일
  files?: Array<FileModel>;
  fileUpdates?: Array<FileModel>;
  filesFocused?: FileModel;

  focusedTab?: TabModel;

  searchQuery?: string;
  focuseddata?: any;
  spjangcd?: string;
  bankcd?: string;
  rtclafi?: string;
  spjangcds?: Array<SpjangItem>;
  bankcds?: Array<BankItem>;
  staffList?: Array<BasicModel>;
  img_pic?: ArrayBuffer;
  img_sign?: ArrayBuffer;
  percentImagePic?: number;
  percentImageSign?: number;
  basicAddressToggle?: boolean;
  basicAddressToggle2?: boolean;
  basicAddressToggle3?: boolean;
  fileDetailModal?: boolean;
  popupData?: string;
  faminum?: any;

  // 관리자권한 버튼 팝업
  modalVisible?: boolean;
  modalMenuState?: boolean;
  modalMenuChoice?: any;
  managerId?: string;
  managerPassword?: string;

  passwdChange?: boolean;
  passwdChangeFocus?: ModalModel;
  userid?: string;
  password1?: string;
  password2?: string;

  userRegistration?: boolean;
  settingModal?: boolean;
  userRegistrationData?: Array<ModalModel>;
  userRegistrationData2?: ModalModel;
  userRegistrationFocus?: ModalModel;

  menuAuthority?: boolean;
  menuAuthorityData?: Array<ModalModel>;
  menuAuthorityDetailData?: Array<ModalModel>;
  menuAuthorityFocus?: ModalModel;

  // 사용자등록 버튼 팝업
  userModalData?: UserModalModel;
  userDetailModal?: boolean;
  perid?: string;
  pernm?: string;

  // new 상태 확인
  checkNew?: boolean;
  lastSaveData?: BasicModel;
}

/**
 * 컨트롤러
 * @window w_p2100
 * @category 인사등록
 */
export class PersonnelResistration extends PageComponent<PageProps, PersonnelResistrationState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  modalTable: React.RefObject<TableLayout> = React.createRef();

  modalDetailTable: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  gridIndex: number = 0;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  updatedRows?: Array<BasicModel>;

  updatedRows2?: Array<BodyModel>;

  updatedModalRows?: Array<ModalModel>;

  updatedModalRows2?: Array<ModalModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = PersonnelResistrationTabId;
    const titles = PersonnelResistrationTabTitles;

    this.tabs = [
      new TabModel(id.BASIC.toString(), RiUserLine, titles[id.BASIC]),
      new TabModel(id.FAMILY.toString(), RiTeamLine, titles[id.FAMILY]),
      new TabModel(id.BODY.toString(), RiBodyScanLine, titles[id.BODY]),
      new TabModel(id.CAREER.toString(), RiBuildingLine, titles[id.CAREER]),
      new TabModel(id.ACADEMICBACKGROUND.toString(), RiPencilRulerLine,
        titles[id.ACADEMICBACKGROUND]),
      new TabModel(id.EDUCATION.toString(), RiQuillPenLine, titles[id.EDUCATION]),
      new TabModel(id.CERTIFICATE.toString(), RiPassportLine, titles[id.CERTIFICATE]),
      new TabModel(id.AWARD.toString(), RiVipCrown2Line, titles[id.AWARD]),
      new TabModel(id.CAR.toString(), RiCarLine, titles[id.CAR]),
      new TabModel(id.FILE.toString(), RiFolder2Line, titles[id.FILE]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      staffList: [],
      searchQuery: '',
      spjangcd: 'ZZ',
      rtclafi: '001',
      bankcd: '',
      data: [],
      focusedTab: this.tabs[PersonnelResistrationTabId.BASIC],
      basicAddressToggle: true,
      basicAddressToggle2: true,
      basicAddressToggle3: true,

      modalMenuChoice: '1',
      userRegistrationData: [],
      menuAuthorityData: [],
      menuAuthorityDetailData: [],

      // popup
      managerId: '',
      managerPassword: '',
      userid: '',
      password1: '',
      password2: '',

      // 사용자등록
      perid: '',
      pernm: '',
    };

    this.tabHeaders = [
      // 기본정보
      [
      ],
      // 가족
      TabHeaderFamilly,

      // 신체병역
      [
      ],

      // 경력
      TabHeaderCareer,

      // 학력
      TabHeaderAcademicBackground,

      // 교육
      TabHeaderEducation,

      // 자격증
      TabHeaderCertificate,

      // 상벌사항
      TabHeaderAward,

      // 차량등록
      TabHeaderCar,

      // 첨부파일
      [
      ],
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트 가져오기
    let data = await api.dropdown('wf_dd_spjangcd_02');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data.items });

    // 은행 리스트 가져오기
    data = await api.dropdown('wf_dd_s004');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ bankcds: data.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    this.gridIndex = 0;
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        spjangcd: this.state.spjangcd,
        rtclafi: this.state.rtclafi,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          staffList: [...this.state.staffList || [], ...items],
          [PersonnelResistrationTabDataStateNames[this.tabIndex]]:
            new PersonnelResistrationTabModels[this.tabIndex]([], false),
        }, next);
      },
      async () => {
        await this.SS({ staffList: [] });
        await this.infinity2?.retrieveAll();
        if ((this.state.staffList?.length || 0) > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      staffList: [],
      [PersonnelResistrationTabUpdatesStateNames[this.tabIndex]]: [],
      [PersonnelResistrationTabFocusedStateNames[this.tabIndex]]: undefined,
      lastSaveData: new BasicModel(),
    });
    if (!autoLoad) return;
    const index = await this.infinity2?.retrieveTo('perid', this.state.focuseddata?.perid, type, true) || 0;
    (this.state.staffList?.length || 0) > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (this.state.lastSaveData?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const data = await api.fxExec(
      `${PersonnelResistrationTabFunctionNames[i]}_new`,
      {
        perid: this.state.focuseddata?.perid,
        spjangcd: this.state.focuseddata.spjangcd,
      },
    );
    if (this.tabIndex === PersonnelResistrationTabId.BASIC) {
      const basicData = new BasicModel(data, true);
      const buffer = new ArrayBuffer(0);
      data && this.setState({
        lastSaveData: basicData,
        staffList: [
          basicData,
          ...this.state.staffList || [],
        ],
        focuseddata: basicData,
        basic: basicData,
        img_pic: buffer,
        img_sign: buffer,
      }, async () => {
        if (this.state.staffList) {
          this.grid.current?.setFocus(0);
        }
      });
      this.setState({ checkNew: true });
    } else if (this.tabIndex === PersonnelResistrationTabId.BODY) {
      // eslint-disable-next-line consistent-return
      return 0;
    } else {
      data && this.setState({
        [PersonnelResistrationTabDataStateNames[i]]: [
          // @ts-ignore
          ...this.state[PersonnelResistrationTabDataStateNames[i]],
          new PersonnelResistrationTabModels[i](data, true),
        ],
      }, async () => {
        await this.table.current?.update(false);
        // @ts-ignore
        this.table.current?.setFocus(this.state[PersonnelResistrationTabDataStateNames[i]].length - 1, 0);
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // 핸드폰 번호 하이픈까지 저장되게 해야함.
    const hy = this.state.basic?.handphone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

    // @ts-ignore
    let isSuccess = true;
    if (this.tabIndex === PersonnelResistrationTabId.BASIC) {
      isSuccess = await api.fxSave(
        'tab_1_save',
        {
          ...this.state.basic,
          handphone: hy,
        },
        this.state.basic?.new === '1',
      );
    } else if (this.tabIndex === PersonnelResistrationTabId.BODY) {
      isSuccess = await api.fxSave(
        'tab_3_save',
        this.state.body,
        false,
      );
    } else {
      isSuccess = await api.fxSave(
        `${PersonnelResistrationTabFunctionNames[i]}_save`,
        {
          // @ts-ignore
          items: this.state[PersonnelResistrationTabUpdatesStateNames[i]],
        },
        true,
      );
    }
    // 신규 저장시 근태생성
    if (this.tabIndex === PersonnelResistrationTabId.BASIC) {
      if (this.state.basic?.new === '1' && isSuccess) {
        const result = await Confirm.ask('확인', '입사일로부터 근태를 전부 생성하시겠습니까?\n전부생성시 시간이 오래걸립니다.', '예', '아니오');
        if (result !== AskType.CANCEL) {
          await this.onRetrieveEvent();
        } else {
          await api.fxExec(
            'save_new_pb201',
            {
              perid: this.state.focuseddata?.perid,
              spjancgcd: this.state.focuseddata?.spjangcd,
              entdate: this.state.focuseddata?.entdate,
            },
          );
        }
        const modal = await Confirm.ask('확인', '사용자 등록 페이지로 넘어가겠습니까?', '예', '아니오');
        if (modal === AskType.YES) {
          await this.userClicked();
        }
      }
    }
    if (isSuccess) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[PersonnelResistrationTabFocusedStateNames[i]];

    if (!this.state.focuseddata && this.tabIndex === PersonnelResistrationTabId.BASIC) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    if (!this.state.focuseddata && this.tabIndex === PersonnelResistrationTabId.BODY) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    if (!item
      && this.tabIndex !== PersonnelResistrationTabId.BASIC
      && this.tabIndex !== PersonnelResistrationTabId.BODY) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case PersonnelResistrationTabId.BASIC:
        text = '사원 삭제 시 사원의 모든 인사자료와 급여자료가 삭제됩니다. '
               + '신중하게 판단하신 후 삭제하십시오';
        break;

      case PersonnelResistrationTabId.FAMILY:
        text = `${item.seq} - ${item.faminm}`;
        break;

      case PersonnelResistrationTabId.BODY:
        text = `${this.state.body?.stan} - ${this.state.body?.weig}`;
        break;

      case PersonnelResistrationTabId.CAREER:
        text = `${item.carcd} - ${item.comnm}`;
        break;

      case PersonnelResistrationTabId.ACADEMICBACKGROUND:
        text = `${item.elijcd} - ${item.elijnm}`;
        break;

      case PersonnelResistrationTabId.EDUCATION:
        text = `${item.educd} - ${item.orgnm}`;
        break;

      case PersonnelResistrationTabId.CERTIFICATE:
        text = `${item.seq} - ${item.licence}`;
        break;

      case PersonnelResistrationTabId.AWARD:
        text = `${item.panaltycd} - ${item.panaltycnm}`;
        break;

      case PersonnelResistrationTabId.CAR:
        text = `${item.seq} - ${item.pernm}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }


    await api.fxDelete(
      `${PersonnelResistrationTabFunctionNames[i]}_delete`,
      text,
      this.tabIndex === PersonnelResistrationTabId.BASIC
       || this.tabIndex === PersonnelResistrationTabId.BODY
        ? this.state.focuseddata
        // @ts-ignore
        : this.state[PersonnelResistrationTabFocusedStateNames[i]],
    );
    this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  async imageRequest(i: number) {
    const { actionStore: api } = this.props;
    const blob = await api.fxBinary(
      `${imgFunctionNames[i]}_retrieve`,
      {
        spjangcd: this.state.focuseddata?.spjangcd,
        perid: this.state.focuseddata?.perid,
      },
    );

    // @ts-ignore
    this.setState({
      [imgStateNames[i]]: blob,
    });
  }

  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_save`,
        {
          custcd: publicStore.user.custcd,
          spjangcd: this.state.basic?.spjangcd,
          perid: this.state.basic?.perid,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        await this.imageRequest(i);
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  async imageDelete(i: number) {
    const { publicStore, actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      `${imgFunctionNames[i]}_delete`,
      {
        custcd: publicStore.user.custcd,
        spjangcd: this.state.basic?.spjangcd,
        perid: this.state.basic?.perid,
      },
    )) {
      await this.imageRequest(i);
      ConfirmSuccess.show('삭제', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      await this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[PersonnelResistrationTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        this.setState({
          [PersonnelResistrationTabUpdatesStateNames[i]]: [],
          [PersonnelResistrationTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }

    this.setState({
      focusedTab,
      [PersonnelResistrationTabUpdatesStateNames[i]]: [],
      [PersonnelResistrationTabFocusedStateNames[i]]: undefined,
    },
    () => {
      this.onRowFocusEvent(this.state.focuseddata, this.gridIndex);
    });
  }

  @action
  async itemChanged(item: any, type: number) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(`${PersonnelResistrationTabFunctionNames[this.tabIndex]}_wf_itemchanged`, {
      itemname: PersonnelResistrationItemChangeTypeNames[type],
      data: item,
    });

    if (this.tabIndex === PersonnelResistrationTabId.BASIC) {
      data && this.setState({
        basic: new BasicModel({
          ...this.state.basic,
          ...data,
        }, this.state.basic?.new === '1'),
      });
    } else if (data?.items) {
      this.setState({
        // @ts-ignore
        [PersonnelResistrationTabDataStateNames[this.tabIndex]]:
          data.items.map(
            (x: any) => new PersonnelResistrationTabModels[this.tabIndex](x),
          ),
      }, () => this.table.current?.update());
    }
  }

  async getItem(item: any, type: number): Promise<any> {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(`${PersonnelResistrationTabFunctionNames[this.tabIndex]}_wf_itemchanged`, {
      itemname: PersonnelResistrationItemChangeTypeNames[type],
      data: item,
    });

    return data;
  }

  @action
  onRowFocusEvent(item: any, index: number) {
    this.gridIndex = index;
    if (this.tabIndex === PersonnelResistrationTabId.BASIC && item.isNew) {
      this.setState({
        basic: this.state.lastSaveData,
        [PersonnelResistrationTabUpdatesStateNames[this.tabIndex]]: [],
        [PersonnelResistrationTabFocusedStateNames[this.tabIndex]]: undefined,
        img_pic: undefined,
        img_sign: undefined,
      }, () => this.table.current?.update());
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];
    this.setState({
      focuseddata: item,
    }, async () => {
      await this.imageRequest(PersonnelResistrationImages.PIC);

      if (this.tabIndex === PersonnelResistrationTabId.FILE) {
        return;
      }
      const tabData = await api.fxExec(`${
        PersonnelResistrationTabFunctionNames[this.tabIndex]}_dw_1_RowFocuschanged`, item);
      if (this.tabIndex === PersonnelResistrationTabId.BASIC
        || this.tabIndex === PersonnelResistrationTabId.BODY) {
        this.setState({
          [PersonnelResistrationTabDataStateNames[this.tabIndex]]:
            new PersonnelResistrationTabModels[this.tabIndex](tabData),
        });
        await this.imageRequest(PersonnelResistrationImages.SIGN);
      } else if (tabData?.items) {
        this.setState({
          // @ts-ignore
          [PersonnelResistrationTabDataStateNames[this.tabIndex]]:
            tabData.items.map(
              (x: any) => new PersonnelResistrationTabModels[this.tabIndex](x),
            ),
        }, () => this.table.current?.update(true));
      } else {
        this.setState({
          // @ts-ignore
          [PersonnelResistrationTabDataStateNames[this.tabIndex]]: [],
          [PersonnelResistrationTabUpdatesStateNames[this.tabIndex]]: [],
          [PersonnelResistrationTabFocusedStateNames[this.tabIndex]]: undefined,
        }, () => this.table.current?.update());
      }
    });
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ modalVisible: isOpen });
  }

  @action
  async modalLoginEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_xadmin_login',
        userid: this.state.managerId,
        passwd: this.state.managerPassword,
      });
    if (data?.titlebox === '성공') {
      this.textModal(false);
      this.modalMenuChoice(true);
    }
  }

  @action
  async modalMenuChoice(isOpen: boolean) {
    this.setState({ modalMenuState: isOpen });
  }

  @action
  async modalMenuChoice2() {
    this.modalMenuChoice(false);

    switch (this.state.modalMenuChoice) {
      case '1':
        this.passwdChange(true);
        break;
      case '2':
        this.userRegistration(true);
        break;
      case '3':
        this.menuAuthority(true);
        break;
    }
  }

  @action
  async passwdChange(isOpen: boolean) {
    this.modalMenuChoice(false);
    this.setState({ passwdChange: isOpen });

    this.passwdChangeRetrive();
  }

  @action
  async passwdChangeRetrive() {
    const { actionStore: api } = this.props;

    await api.fxExec('retrieve',
      {
        sub: 'w_popup_xadmin',
      });
  }

  @action
  async passwdChangeSave() {
    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_xadmin',
      userid: this.state.userid,
      password1: this.state.password1,
      password2: this.state.password2,
    }, true)) {
      this.passwdChange(false);
    }
  }

  @action
  async userRegistration(isOpen: boolean) {
    this.setState({ userRegistration: isOpen });

    this.userRegistrationRetrive();
  }

  @action
  async userRegistrationRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_admin_xusers',
        as_nm: '',
      });
    this.setState({ userRegistrationData: data?.items || '' });

    // @ts-ignore
    this.state.userRegistrationData.length > 0 && this.userRegistrationRowfocus(this.state.userRegistrationData[0]);
  }

  @action
  async userRegistrationRowfocus(item: ModalModel) {
    const { actionStore: api } = this.props;

    this.setState({ userRegistrationFocus: item }, async () => {
      const data = await api.fxExec('dw_1_RowFocuschanged',
        {
          sub: 'w_popup_admin_xusers',
          userid: this.state.userRegistrationFocus?.userid,
          pernm: this.state.userRegistrationFocus?.pernm,
        });

      if (data) {
        this.setState({
          userRegistrationData2: data,
        });
      }
      await this.table.current?.update(true);
    });
  }

  @action
  async userRegistrationSave() {
    const { actionStore: api } = this.props;

    if (await api.save({
      sub: 'w_popup_admin_xusers',
      new: '0',
      custcd: this.state.userRegistrationData2?.custcd,
      userid: this.state.userRegistrationData2?.userid,
      rnum: this.state.userRegistrationData2?.rnum,
      password1: this.state.userRegistrationData2?.password1,
      password2: this.state.userRegistrationData2?.password2,
      custnm: this.state.userRegistrationData2?.custnm,
      pernm: this.state.userRegistrationData2?.pernm,
      useyn: this.state.userRegistrationData2?.useyn,
      perid: this.state.userRegistrationData2?.perid,
      sysmain: this.state.userRegistrationData2?.sysmain,
      grpid: this.state.userRegistrationData2?.grpid,
      menu: this.state.userRegistrationData2?.menu,
      ordermenu: this.state.userRegistrationData2?.ordermenu,
      ordermenuon: this.state.userRegistrationData2?.ordermenuon,
      taxreserv: this.state.userRegistrationData2?.taxreserv,
      contyn: this.state.userRegistrationData2?.contyn,
      sulchi: this.state.userRegistrationData2?.sulchi,
      search: this.state.userRegistrationData2?.search,
      addmin: this.state.userRegistrationData2?.addmin,
      color: this.state.userRegistrationData2?.color,
      noticeflag: this.state.userRegistrationData2?.noticeflag,
      e035_autoflag: this.state.userRegistrationData2?.e035_autoflag,
      tax_funccode: this.state.userRegistrationData2?.tax_funccode,
      warning: this.state.userRegistrationData2?.warning,
      passwd1: this.state.userRegistrationData2?.passwd1,
      passwd2: this.state.userRegistrationData2?.passwd2,
      auth: this.state.userRegistrationData2?.auth,
    }, true)) {
      this.passwdChangeRetrive();
    }
  }

  @action
  async menuAuthority(isOpen: boolean) {
    this.setState({ menuAuthority: isOpen });

    this.menuAuthorityRetrive();
  }

  @action
  async menuAuthorityRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_xusermenu',
      });
    this.setState({ menuAuthorityData: data?.items }, async () => {
      await this.modalTable.current?.update(true);
      await this.modalTable.current?.setFocus(0, 0);
      // @ts-ignore
      this.state.menuAuthorityData.length > 0 && this.menuAuthorityRowFocus(this.state.menuAuthorityData[0]);
    });
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      await api.fxExec(
        'wb_xusers',
        {
          perid: this.state.basic?.perid.slice(1, this.state.basic?.perid.length),
          pernm: this.state.basic?.pernm,
          rnum: this.state.basic?.rnum,
          handphone: this.state.basic?.handphone,
        }, false,
      );
    }
  }

  @action
  async userClicked() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_xusers',
      {
        perid: this.state.basic?.perid.slice(1, this.state.basic?.perid.length),
        pernm: this.state.basic?.pernm,
        rnum: this.state.basic?.rnum,
        handphone: this.state.basic?.handphone,
      });
    if (data?.new === '0') {
      const warning = await Confirm.show('확인',
        `${this.state.basic?.pernm}는(은) 이미 사용자등록이 되어 있습니다. 확인하시겠습니까?`, ConfirmType.QUESTION);
      if (warning === false) return;
      await this.userRetrieve();
    }
    if (!this.state.basic?.rnum) {
      ConfirmWarning.show('오류', '사용자등록시엔 먼저 주민번호를 입력해주세요.');
    }
    if (!this.state.basic?.handphone) {
      ConfirmWarning.show('오류', '사용자등록시엔 먼저 핸드폰번호를 입력해주세요.');
    } if (this.state.basic?.rnum && this.state.basic?.handphone) {
      await this.userRetrieve();
    }
  }

  // 사용자 등록
  @action
  async userRetrieve() {
    const { publicStore } = this.props;

    FunctionRepository.exec(
      'hr',
      'retrieve',
      await publicStore.makeParams({
        sub: 'w_popup_xusers',
        perid: this.state.basic?.perid.slice(1, this.state.basic?.perid.length),
      }),
    ).then((response) => {
      this.setState({ userModalData: new UserModalModel(response.data) }, () => this.userModal(true));
    }).catch(() => {
      // 신규 계정의 경우 값을 임의로 지정
      const perid = this.state.basic?.perid
        ? this.state.basic?.perid.slice(1, this.state.basic?.perid.length)
        : this.state.focuseddata?.perid.slice(1);

      const data = {
        auth: '',
        color: 'blue',
        contyn: '0',
        custcd: this.state.basic?.custcd,
        custnm: '',
        e035_autoflag: '0',
        grpid: '',
        menu: '0',
        noticeflag: '0',
        ordermenu: '1',
        ordermenuon: '1',
        page: '0',
        passwd1: '1',
        passwd2: '1',
        password1: '',
        password2: '',
        perid,
        pernm: this.state.basic?.pernm,
        rnum: this.state.basic?.rnum,
        search: '1',
        state: '0',
        sulchi: '0',
        sysmain: '',
        tax_funccode: '',
        taxreserv: '0',
        total: '1',
        userid: this.state.basic?.perid,
        useyn: '1',
      };
      this.setState({ userModalData: new UserModalModel(data) }, () => this.userModal(true));
    });
  }

  @action
  userModal(isOpen: boolean) {
    this.setState({ userDetailModal: isOpen });
  }

  @action
  async userSave() {
    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_xusers',
      rnum: this.state.basic?.rnum,
      userid: this.state.basic?.perid.slice(1),
      pernm: this.state.basic?.pernm,
      passwd1: this.state.userModalData?.passwd1,
      passwd2: this.state.userModalData?.passwd2,
      vperid: this.state.perid,
      handphone: this.state.basic?.handphone,
      perid: this.state.basic?.perid.slice(1),
    }, true)) {
      await this.passwdChange(false);
      await this.SS({
        userDetailModal: false,
        perid: '',
        pernm: '',
      });
    }
  }

  @action
  async menuAuthorityRowFocus(item: any) {
    this.setState({
      menuAuthorityFocus: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.fxExec(
        'dw_1_RowFocuschanged',
        {
          sub: 'w_popup_xusermenu',
          spjangcd: this.state.menuAuthorityFocus?.spjangcd,
          userid: this.state.menuAuthorityFocus?.userid,
        },
      );
      this.setState({ menuAuthorityDetailData: data?.items }, async () => {
        await this.modalDetailTable.current?.update(true);
        await this.modalDetailTable.current?.setFocus(0, 0);
      });
    });
  }

  @action
  async menuAuthorityBtn() {
    const { actionStore: api, waitQueueStore, modalStore } = this.props;

    const chkData: any[] = [];
    // @ts-ignore
    this.state.menuAuthorityData.forEach((x: any) => {
      if (x?.flag === '1') {
        chkData.push({
          userid: x.userid,
          pernm: x.pernm,
          spjangcd: x.spjangcd,
          flag: x.flag,
        });
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '아무것도 선택되지 않았습니다.');
      return;
    }

    waitQueueStore.append('메뉴권한 설정', 'LAZYTASK-wb_menu', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
    }, () => this.menuAuthorityBtn());
    modalStore?.openWaitQueue();

    await api.fxExec('wb_menu_lazytask',
      {
        sub: 'w_popup_xusermenu',
        items: chkData,
      });

    await this.menuAuthorityRetrive();
  }

  @action
  async menuAuthoritySaveBtn() {
    const { actionStore: api, waitQueueStore, modalStore } = this.props;

    const chkData: any[] = [];
    // @ts-ignore
    this.state.menuAuthorityData.forEach((x: any) => {
      if (x?.flag === '1') {
        chkData.push({
          flag: x.flag,
          userid: x.userid,
        });
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '왼쪽 리스트에 아무것도 선택되지 않았습니다.');
      return;
    }

    const chkData2: any[] = [];
    // @ts-ignore
    this.state.menuAuthorityDetailData.forEach((x: any) => {
      chkData2.push({
        msort: x.msort,
        visible: x.visible,
      });
    });

    waitQueueStore.append('관리자암호 변경', 'LAZYTASK-save', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
    }, () => this.menuAuthoritySaveBtn());
    modalStore?.openWaitQueue();

    await api.fxExec('save_lazytask',
      {
        sub: 'w_popup_xusermenu',
        userid: this.state.menuAuthorityFocus?.userid,
        items: chkData,
        items2: chkData2,
      });
    await this.menuAuthorityRetrive();
  }

  @action
  toggleSettingsModal(isOpen: boolean) {
    this.setState({ settingModal: isOpen });
  }

  @action
  onModalUpdatedRows(rows: Array<ModalModel>, updatedRows: Array<ModalModel>) {
    this.updatedModalRows = updatedRows;
    this.setState({ menuAuthorityData: rows });
  }

  @action
  onModalUpdatedRows2(rows: Array<ModalModel>, updatedRows: Array<ModalModel>) {
    this.updatedModalRows2 = updatedRows;
    this.setState({ menuAuthorityDetailData: rows });
  }

  @computed
  get tabIndex(): PersonnelResistrationTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [PersonnelResistrationTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [PersonnelResistrationTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <PersonnelResistrationTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
        dataUpdate={(change, callback) => {
          this.setState({
            basic: {
              ...this.state.basic,
              ...change,
            },
            body: {
              ...this.state.body,
              ...change,
            },
            userModalData: {
              ...this.state.userModalData,
              ...change,
            },
            userRegistrationData2: {
              ...this.state.userRegistrationData2,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
