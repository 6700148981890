import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Collect, Conts, ScGubunsLabel } from '../Collect';
import CollectModel from '../models/CollectModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 상단 그리드 레이아웃
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectGridHeader: GLHF<CollectModel, Collect> = () => ([
  {
    id: 'state',
    text: '설치',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => {
      const fp = ScGubunsLabel.filter((y) => y.key === x.state);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'projno',
    text: '프로젝트코드',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.projno}
      </FlexLayout>
    ),
  },
  {
    id: 'scactnm',
    text: '설치현장명',
    width: 450,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        계약 설치 보수
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scactnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Format.number(x.qty) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'contstate',
    text: '계약상태',
    width: 100,
    render: (x) => {
      const fp = Conts.filter((y) => y.key === x.contstate);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0]?.color || '#000000' : '#000000';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'contdate',
    text: '계약일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.contdate) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'kcdate',
    text: '검사일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.kcdate ? 'inherit' : 'var(--color-red)',
        }}
      >
        {Date8.withDash(x.kcdate) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'sugm',
    text: '수금여부',
    width: 70,
    render: (x) => {
      const ok = (parseInt(x.contamt, 10) || 0) - (parseInt(x.rcvamt, 10) || 0) === 0;
      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color: ok ? 'var(--color-blue)' : 'var(--color-red)',
          }}
        >
          {ok ? '수금' : '미수'}
        </FlexLayout>
      );
    },
  },
  {
    id: 'pernm',
    text: '계약자',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'contamt',
    text: '계약금(수주)',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.contamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'jango',
    text: '잔액(계약-수금)',
    width: 120,
    render: (x) => {
      const amt = (parseInt(x.contamt, 10) || 0) - (parseInt(x.rcvamt, 10) || 0);
      return (
        <FlexLayout
          align="center"
          justify="end"
          style={{
            color: amt > -1 ? 'var(--color-red)' : 'var(--color-blue)',
          }}
        >
          {Format.number(amt) || ''}
        </FlexLayout>
      );
    },
  },
  {
    id: 'misamt',
    text: '매출금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.misamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.rcvamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '미수금',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.iamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'ex_mijamt',
    text: '예상지출금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.ex_mijamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'balamt',
    text: '발주금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.balamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '매입금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mijamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt',
    text: '지급액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.sndamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'jamt',
    text: '미지급액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.jamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'iamtA',
    text: '예상이윤(수주-예상지출)',
    width: 150,
    render: (x) => {
      const amt = (parseInt(x.contamt, 10) || 0) - (parseInt(x.ex_mijamt, 10) || 0);
      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {Format.number(amt) || ''}
        </FlexLayout>
      );
    },
  },
  {
    id: 'iamtB',
    text: '예상이윤(수주-발주)',
    width: 150,
    render: (x) => {
      const amt = (parseInt(x.contamt, 10) || 0) - (parseInt(x.balamt, 10) || 0);
      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {Format.number(amt) || ''}
        </FlexLayout>
      );
    },
  },
  {
    id: 'iamtC',
    text: '실제이윤(매출-매입)',
    width: 150,
    render: (x) => {
      const amt = (parseInt(x.misamt, 10) || 0) - (parseInt(x.mijamt, 10) || 0);
      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {Format.number(amt) || ''}
        </FlexLayout>
      );
    },
  },
  {
    id: 'iamtD',
    text: '남은예상이윤(수주-매입)',
    width: 150,
    render: (x) => {
      const amt = (parseInt(x.contamt, 10) || 0) - (parseInt(x.mijamt, 10) || 0);
      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {Format.number(amt) || ''}
        </FlexLayout>
      );
    },
  },
  {
    id: 'balcltnm',
    text: '발주예정처',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.balcltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'scpernm',
    text: '설치작업자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'jeoban',
    text: '기종',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.jeoban === '02' ? 'MRL' : 'MR'}
      </FlexLayout>
    ),
  },
  {
    id: 'sizecd',
    text: '사양',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.sizecd}
      </FlexLayout>
    ),
  },
  {
    id: 'scoption',
    text: '옵션',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scoption}
      </FlexLayout>
    ),
  },
  {
    id: 'okflag',
    text: '승인유무',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.okflag === '1' ? 'var(--color-blue)' : 'var(--color-red)',
        }}
      >
        {x.okflag === '1' ? '승인' : '미승인'}
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '준공일',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.kcdate ? 'inherit' : 'var(--color-red)',
        }}
      >
        {Date8.withDash(x.enddate) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'bilddate',
    text: '건축허가일',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.kcdate ? 'inherit' : 'var(--color-red)',
        }}
      >
        {Date8.withDash(x.bilddate) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'baldate',
    text: '출하예정일',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.kcdate ? 'inherit' : 'var(--color-red)',
        }}
      >
        {Date8.withDash(x.baldate) || ''}
      </FlexLayout>
    ),
  },
]);
