/**
 * 모델
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export default class StatusDateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 견적담당자
   *
   * **data type** varchar(8)
   */
  readonly costperid: string;

  /**
   * 견적담당자명
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 제어반
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  readonly mon: string;

  /**
   * 인승
   *
   * **data type** varchar(8)
   */
  readonly nperson: string;

  /**
   * 층수
   *
   * **data type** varchar(8)
   */
  readonly number: string;

  /**
   * 규격
   *
   * **data type** varchar(8)
   */
  readonly psize: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 대수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actnm = data.actnm || '';
    this.constnm = data.constnm || '';
    this.contflag = data.contflag || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.costperid = data.costperid || '';
    this.costpernm = data.costpernm || '';
    this.gubun = data.gubun || '';
    this.jeoban = data.jeoban || '';
    this.mamt = data.mamt || '';
    this.mon = data.mon || '';
    this.nperson = data.nperson || '';
    this.number = data.number || '';
    this.psize = data.psize || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.state = data?.state || '%';
    this.qty = data.qty || '';
  }
}
