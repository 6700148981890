/**
 * 모델
 * @window w_tb_e035w_03
 * @category 점검시행안내문
 */
import { computed } from 'mobx';

export class InspectionNoticeModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 출력선택
   *
   * **data type** varchar(1)
   */
  prtflag: string;

  /**
   * 메일선택
   *
   * **data type** char(8)
   */
  emailflag: string;

  /**
   * 코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(201)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 점검자
   *
   * **data type** varchar(30)
   */
  pernms: string;

  /**
   * 점검시작일
   *
   * **data type** varchar(8)
   */
  plandate_min: string;

  /**
   * 점검종료일
   *
   * **data type** varchar(8)
   */
  plandate_max: string;

  /**
   * 메일주소
   *
   * **data type** varchar(30)
   */
  email: string;

  /**
   * 주소
   *
   * **data type** varchar(200)
   */
  address: string;

  /**
   * -
   *
   * **data type** varchar(3)
   */
  prtchk: string;

  /**
   * -
   *
   * **data type** varchar(3)
   */
  emailchk: string;

  /**
   * 시작시간
   *
   * **data type** varchar(4)
   */
  plantime_min: string;

  /**
   * 종료시간
   *
   * **data type** varchar(4)
   */
  plantime_max: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  equpnm: string;

  /**
   * 점검일자
   *
   * **data type** varchar(8)
   */
  plandate: string;

  /**
   * 시작시간
   *
   * **data type** varchar(8)
   */
  plantime: string;

  /**
   * 종료시간
   *
   * **data type** varchar(8)
   */
  plantime2: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.plantime_min = data.plantime_min || '';
    this.plantime_max = data.plantime_max || '';
    this.plantime = data.plantime || '';
    this.plantime2 = data.plantime2 || '';
    this.plandate = data.plandate || '';
    this.equpnm = data.equpnm || '';
    this.prtflag = data.prtflag || '';
    this.emailflag = data.emailflag || '';
    this.prtchk = data.prtchk || '';
    this.emailchk = data.emailchk || '';
    this.prtflag = data.prtflag || '';
    this.emailflag = data.emailflag || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.pernms = data.pernms || '';
    this.plandate_min = data.plandate_min || '';
    this.plandate_max = data.plandate_max || '';
    this.email = data.email || '';
    this.address = data.address || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
