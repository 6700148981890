import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export default class RegistModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 메일주소
   *
   * **data type** varchar(8)
   */
  readonly actmail: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly actperid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly actpernm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly actrespon: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 주소2
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly anaddress: string;

  /**
   * 주소2
   *
   * **data type** varchar(8)
   */
  readonly anaddress2: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly ancltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly ancltnm: string;

  /**
   * 위도
   *
   * **data type** varchar(8)
   */
  readonly anlat: string;

  /**
   * 경도
   *
   * **data type** varchar(8)
   */
  readonly anlng: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly anpernm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly anzipcd: string;

  /**
   * 지역코드
   *
   * **data type** varchar(8)
   */
  readonly areacd: string;

  /**
   * 발주거래처코드
   *
   * **data type** varchar(8)
   */
  readonly balcltcd: string;

  /**
   * 발주거래처명
   *
   * **data type** varchar(8)
   */
  readonly balcltnm: string;

  /**
   * 발주일
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 발주상태
   *
   * **data type** varchar(8)
   */
  readonly balflag: string;

  /**
   * 이전 검사일
   *
   * **data type** varchar(8)
   */
  readonly be_kcdate: string;

  /**
   * 매출일
   *
   * **data type** varchar(8)
   */
  readonly bilddate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly bildju: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly bildlv: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly bildyd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly class: string;

  /**
   * 거래처 주소
   *
   * **data type** varchar(8)
   */
  readonly cltadres: string;

  /**
   * 거래처 코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처 위도
   *
   * **data type** varchar(8)
   */
  readonly cltlat: string;

  /**
   * 거래처 경도
   *
   * **data type** varchar(8)
   */
  readonly cltlng: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 거래처번호
   *
   * **data type** varchar(8)
   */
  readonly cltnum: string;

  /**
   * 준공도서
   *
   * **data type** varchar(8)
   */
  readonly compbook: string;

  /**
   * 계약금
   *
   * **data type** varchar(8)
   */
  readonly contamt: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 계약상태
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;

  /**
   * 계약상태
   *
   * **data type** varchar(8)
   */
  readonly contstate: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 전기공사금액
   *
   * **data type** varchar(8)
   */
  readonly elecamt: string;

  /**
   * 전기공사율
   *
   * **data type** varchar(8)
   */
  readonly elecyul: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly emactcd: string;

  /**
   * 잔금
   *
   * **data type** varchar(8)
   */
  readonly endamt: string;

  /**
   * 준공일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 완료율
   *
   * **data type** varchar(8)
   */
  readonly endyul: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly ex_mijamt: string;

  /**
   * 팩스번호
   *
   * **data type** varchar(8)
   */
  readonly fax: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly gactcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly gareacd: string;

  /**
   * 기간
   *
   * **data type** varchar(8)
   */
  readonly gigan: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly gokflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly gpaper: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 히자기간 종료일
   *
   * **data type** varchar(8)
   */
  readonly haja_enddate: string;

  /**
   * 하자기간 시작일
   *
   * **data type** varchar(8)
   */
  readonly haja_stdate: string;

  /**
   * 휴대폰번호
   *
   * **data type** varchar(8)
   */
  readonly hp: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 입선일
   *
   * **data type** varchar(8)
   */
  readonly ipsundate: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 위도
   *
   * **data type** varchar(8)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(8)
   */
  readonly lng: string;

  /**
   * 중도금
   *
   * **data type** varchar(8)
   */
  readonly midamt: string;

  /**
   * 중도금율
   *
   * **data type** varchar(8)
   */
  readonly midyul: string;

  /**
   * 미수구분
   *
   * **data type** varchar(8)
   */
  readonly mijflag: string;

  /**
   * 잔금납기일
   *
   * **data type** varchar(8)
   */
  readonly misenddate: string;

  /**
   * 잔금납기상태
   *
   * **data type** varchar(8)
   */
  readonly misendflag: string;

  /**
   * 잔금 매출 번호
   *
   * **data type** varchar(8)
   */
  readonly misendnum: string;

  /**
   * 중도금 매출 번호
   *
   * **data type** varchar(8)
   */
  readonly mismiddate: string;

  /**
   * 중도금 매출 상태
   *
   * **data type** varchar(8)
   */
  readonly mismidflag: string;

  /**
   * 중도금 매출 번호
   *
   * **data type** varchar(8)
   */
  readonly mismidnum: string;

  /**
   * 선급금 매출 일자
   *
   * **data type** varchar(8)
   */
  readonly misstdate: string;

  /**
   * 선급금 매출 상태
   *
   * **data type** varchar(8)
   */
  readonly misstflag: string;

  /**
   * 선급금 매출 번호
   *
   * **data type** varchar(8)
   */
  readonly misstnum: string;

  /**
   * 승인도서
   *
   * **data type** varchar(8)
   */
  readonly okbook: string;

  /**
   * 승인상태
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   * 구주소
   *
   * **data type** varchar(8)
   */
  readonly oldaddress: string;

  /**
   * 구주소
   *
   * **data type** varchar(8)
   */
  readonly oldanaddress: string;

  /**
   * 구 우편번호
   *
   * **data type** varchar(8)
   */
  readonly oldanzipcd: string;

  /**
   * 구 우편번호
   *
   * **data type** varchar(8)
   */
  readonly oldzipcode: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 지급번호
   *
   * **data type** varchar(8)
   */
  readonly ordnum: string;

  /**
   * 문서
   *
   * **data type** varchar(8)
   */
  readonly paper: string;

  /**
   * 비밀번호
   *
   * **data type** varchar(8)
   */
  readonly passwd: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pperid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly ppernm: string;

  /**
   * 고객명
   *
   * **data type** varchar(8)
   */
  readonly prenm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly pubaddress: string;

  /**
   * 주소2
   *
   * **data type** varchar(8)
   */
  readonly pubaddress2: string;

  /**
   * 거래처
   *
   * **data type** varchar(8)
   */
  readonly pubcltcd: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pubpernm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly pubzipcd: string;

  /**
   * 댓수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly randering: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   * 설치금액
   *
   * **data type** varchar(8)
   */
  readonly scamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly sccls: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly scclsdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly scclsremark: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly scgremark: string;

  /**
   * 설치검사일
   *
   * **data type** varchar(8)
   */
  readonly sckcdate: string;

  /**
   * 설치검사 상태
   *
   * **data type** varchar(8)
   */
  readonly sckcflag: string;

  /**
   * 설치 승인도서
   *
   * **data type** varchar(8)
   */
  readonly scokflag: string;

  /**
   * 설치담당자명
   *
   * **data type** varchar(8)
   */
  readonly scpernm: string;

  /**
   * 설치 특이사항
   *
   * **data type** varchar(8)
   */
  readonly scremark: string;

  /**
   * 설치율
   *
   * **data type** varchar(8)
   */
  readonly scyul: string;

  /**
   * 지급일
   *
   * **data type** varchar(8)
   */
  readonly senddate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly special: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 선급금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly styul: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 부가세 구분
   *
   * **data type** varchar(8)
   */
  readonly taxcls: string;

  /**
   * 전화번호
   *
   * **data type** varchar(8)
   */
  readonly tel: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total1: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total2: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total3: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total4: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total5: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total6: string;

  /**
   * 담당업체
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcd: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcode: string;

  /**
   * 실측일
   *
   * **data type** varchar(8)
   */
  readonly surdate: string;

  /**
   * 실측자
   *
   * **data type** varchar(8)
   */
  readonly surperid: string;

  /**
   * 실측자명
   *
   * **data type** varchar(8)
   */
  readonly surpernm: string;

  /**
   * 설계일
   *
   * **data type** varchar(8)
   */
  readonly desdate: string;

  /**
   * 설계자
   *
   * **data type** varchar(8)
   */
  readonly desperid: string;

  /**
   * 설계자명
   *
   * **data type** varchar(8)
   */
  readonly despernm: string;

  /**
   * 실측여부
   *
   * **data type** varchar(8)
   */
  readonly surflag: string;

  /**
   * 설계여부
   *
   * **data type** varchar(8)
   */
  readonly desflag: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * jeoban
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 층수
   *
   * **data type** varchar(8)
   */
  readonly number: string;

  /**
   * 인승
   *
   * **data type** varchar(8)
   */
  readonly nperson: string;

  /**
   * 계획(작성)일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly plannum: string;

  /**
   * 계획(작성)일자 2
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 상담자연락처
   *
   * **data type** varchar(8)
   */
  readonly advicehpnum: string;

  /**
   * 상담내용
   *
   * **data type** varchar(8)
   */
  readonly adviceremark: string;

  /**
   * 협의내용 및 조치내용
   *
   * **data type** varchar(8)
   */
  readonly resultmark: string;

  /**
   * 기타내용
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  /**
   * 분할납부
   *
   * **data type** varchar(8)
   */
  scdivided: string;

  /**
   * 분할납부 (내부적으로 가짐)
   *
   * **data type** varchar(8)
   */
  scdivided_be: string;

  /**
   * 자재출하예정일
   *
   * **data type** varchar(8)
   */
  bal_okflag: string;

  /**
   * 영업방문예정일
   *
   * **data type** varchar(8)
   */
  visitdate: string;

  /**
   * 영업방문구분
   *
   * **data type** varchar(8)
   */
  visitflag: string;

  /**
   * 영업방문담당자코드
   *
   * **data type** varchar(8)
   */
  visitperid: string;

  /**
   * 영업방문담당자명
   *
   * **data type** varchar(8)
   */
  visitpernm: string;

  /**
   * 설치자팝업창 (구분)
   *
   * **data type** varchar(8)
   */
  mpclafi: string;

  /**
   * seq
   *
   * **data type** varchar(8)
   */
  seq: string;

  /**
   * rcvstdate
   *
   * **data type** varchar(8)
   */
  rcvstdate: string;

  /**
   * rcvmiddate
   *
   * **data type** varchar(8)
   */
  rcvmiddate: string;

  /**
   * rcvenddate
   *
   * **data type** varchar(8)
   */
  rcvenddate: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data?.actcd || '';
    this.actmail = data?.actmail || '';
    this.actnm = data?.actnm || '';
    this.actperid = data?.actperid || '';
    this.actpernm = data?.actpernm || '';
    this.actrespon = data?.actrespon || '';
    this.address = data?.address || '';
    this.address2 = data?.address2 || '';
    this.anaddress = data?.anaddress || '';
    this.anaddress2 = data?.anaddress2 || '';
    this.ancltcd = data?.ancltcd || '';
    this.ancltnm = data?.ancltnm || '';
    this.anlat = data?.anlat || '';
    this.anlng = data?.anlng || '';
    this.anpernm = data?.anpernm || '';
    this.anzipcd = data?.anzipcd || '';
    this.areacd = data?.areacd || '';
    this.balcltcd = data?.balcltcd || '';
    this.balcltnm = data?.balcltnm || '';
    this.baldate = data?.baldate || '';
    this.balflag = data?.balflag || '';
    this.be_kcdate = data?.be_kcdate || '';
    this.bilddate = data?.bilddate || '';
    this.bildju = data?.bildju || '';
    this.bildlv = data?.bildlv || '';
    this.bildyd = data?.bildyd || '';
    this.class = data?.class || '';
    this.cltadres = data?.cltadres || '';
    this.cltcd = data?.cltcd || '';
    this.cltlat = data?.cltlat || '';
    this.cltlng = data?.cltlng || '';
    this.cltnm = data?.cltnm || '';
    this.cltnum = data?.cltnum || '';
    this.compbook = data?.compbook || '';
    this.contamt = data?.contamt || '';
    this.contdate = data?.contdate || '';
    this.contflag = data?.contflag || '';
    this.contstate = data?.contstate || '';
    this.custcd = data?.custcd || '';
    this.divicd = data?.divicd || '';
    this.elecamt = data?.elecamt || '';
    this.elecyul = data?.elecyul || '';
    this.emactcd = data?.emactcd || '';
    this.endamt = data?.endamt || '';
    this.enddate = data?.enddate || '';
    this.endyul = data?.endyul || '';
    this.ex_mijamt = data?.ex_mijamt || '';
    this.fax = data?.fax || '';
    this.gactcd = data?.gactcd || '';
    this.gareacd = data?.gareacd || '';
    this.gigan = data?.gigan || '';
    this.gokflag = data?.gokflag || '';
    this.gpaper = data?.gpaper || '';
    this.gubun = data?.gubun || '';
    this.haja_enddate = data?.haja_enddate || '';
    this.haja_stdate = data?.haja_stdate || '';
    this.hp = data?.hp || '';
    this.indate = data?.indate || '';
    this.inperid = data?.inperid || '';
    this.inpernm = data?.inpernm || '';
    this.ipsundate = data?.ipsundate || '';
    this.kcdate = data?.kcdate || '';
    this.lat = data?.lat || '';
    this.lng = data?.lng || '';
    this.midamt = data?.midamt || '';
    this.midyul = data?.midyul || '';
    this.mijflag = data?.mijflag || '';
    this.misenddate = data?.misenddate || '';
    this.misendflag = data?.misendflag || '';
    this.misendnum = data?.misendnum || '';
    this.mismiddate = data?.mismiddate || '';
    this.mismidflag = data?.mismidflag || '';
    this.mismidnum = data?.mismidnum || '';
    this.misstdate = data?.misstdate || '';
    this.misstflag = data?.misstflag || '';
    this.misstnum = data?.misstnum || '';
    this.okbook = data?.okbook || '';
    this.okflag = data?.okflag || '';
    this.oldaddress = data?.oldaddress || '';
    this.oldanaddress = data?.oldanaddress || '';
    this.oldanzipcd = data?.oldanzipcd || '';
    this.oldzipcode = data?.oldzipcode || '';
    this.orddate = data?.orddate || '';
    this.ordnum = data?.ordnum || '';
    this.paper = data?.paper || '';
    this.passwd = data?.passwd || '';
    this.perid = data?.perid || '';
    this.pernm = data?.pernm || '';
    this.pperid = data?.pperid || '';
    this.ppernm = data?.ppernm || '';
    this.prenm = data?.prenm || '';
    this.projnm = data?.projnm || '';
    this.projno = data?.projno || '';
    this.pubaddress = data?.pubaddress || '';
    this.pubaddress2 = data?.pubaddress2 || '';
    this.pubcltcd = data?.pubcltcd || '';
    this.pubpernm = data?.pubpernm || '';
    this.pubzipcd = data?.pubzipcd || '';
    this.qty = data?.qty || '';
    this.randering = data?.randering || '';
    this.remark = data?.remark || '';
    this.scactnm = this.actnm || '';
    this.scamt = data?.scamt || '';
    this.sccls = data?.sccls || '';
    this.scclsdate = data?.scclsdate || '';
    this.scclsremark = data?.scclsremark || '';
    this.scgremark = data?.scgremark || '';
    this.sckcdate = data?.sckcdate || '';
    this.sckcflag = data?.sckcflag || '';
    this.scokflag = data?.scokflag || '';
    this.scpernm = data?.scpernm || '';
    this.scremark = data?.scremark || '';
    this.scyul = data?.scyul || '';
    this.senddate = data?.senddate || '';
    this.special = data?.special || '';
    this.spjangcd = data?.spjangcd || '';
    this.stamt = data?.stamt || '';
    this.state = data?.state || '';
    this.stdate = data?.stdate || '';
    this.styul = data?.styul || '';
    this.tamt = data?.tamt || '';
    this.taxcls = data?.taxcls || '';
    this.tel = data?.tel || '';
    this.total1 = data?.total1 || '';
    this.total2 = data?.total2 || '';
    this.total3 = data?.total3 || '';
    this.total4 = data?.total4 || '';
    this.total5 = data?.total5 || '';
    this.total6 = data?.total6 || '';
    this.wkactcd = data?.wkactcd || '';
    this.zipcd = data?.zipcd || '';
    this.zipcode = data?.zipcode || '';
    this.surdate = data?.surdate || '';
    this.surperid = data?.surperid || '';
    this.surpernm = data?.surpernm || '';
    this.desdate = data?.desdate || '';
    this.desperid = data?.desperid || '';
    this.despernm = data?.despernm || '';
    this.surflag = data?.surflag || '';
    this.desflag = data?.desflag || '';
    this.costdate = data?.costdate || '';
    this.costnum = data?.costnum || '';
    this.costpernm = data?.costpernm || '';
    this.constnm = data?.constnm || '';
    this.samt = data?.samt || '';
    this.mamt = data?.mamt || '';
    this.jeoban = data?.jeoban || '';
    this.nperson = data?.nperson || '';
    this.number = data?.number || '';
    this.plandate = data?.plandate || '';
    this.plannum = data?.plannum || '';
    this.compdate = data?.compdate || '';
    this.advicehpnum = data?.advicehpnum || '';
    this.adviceremark = data?.adviceremark || '';
    this.resultmark = data?.resultmark || '';
    this.bigo = data?.bigo || '';
    this.scdivided = data?.scdivided || '0';
    this.scdivided_be = data?.scdivided_be || '';
    this.bal_okflag = data?.bal_okflag || '';
    this.visitdate = data?.visitdate || '';
    this.visitflag = data?.visitflag || '';
    this.visitperid = data?.visitperid || '';
    this.visitpernm = data?.visitpernm || '';
    this.mpclafi = data?.mpclafi || '';
    this.seq = data?.seq || '';
    this.rcvstdate = data?.rcvstdate || '';
    this.rcvmiddate = data?.rcvmiddate || '';
    this.rcvenddate = data?.rcvenddate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew(): boolean {
    return this.new === '1';
  }

  @computed
  get appnum(): string {
    return `SC-${this.actcd}`;
  }
}
