/**
 * 팝업창모델
 * @window w_tb_e101w_08
 * @category 계약마감통계
 */
export class ModalDataModel {
  /**
   * 사업장코드
   *
   * **data type** varchar()
   */
  readonly spjangcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar()
   */
  readonly spjangnm: string;

  /**
   * 구분
   *
   * **data type** varchar()
   */
  readonly state: string;

  /**
   * 계약
   *
   * **data type** varchar()
   */
  readonly contg: string;

  /**
   * 현장코드
   *
   * **data type** varchar()
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar()
   */
  readonly actnm: string;

  /**
   * 대수
   *
   * **data type** varchar()
   */
  readonly qty: string;

  /**
   * 금액
   *
   * **data type** varchar()
   */
  readonly amt: string;

  /**
   * 접수일자
   *
   * **data type** varchar()
   */
  readonly recedate: string;

  /**
   * 시작일자
   *
   * **data type** varchar()
   */
  readonly stdate: string;

  /**
   * 종료일자
   *
   * **data type** varchar()
   */
  readonly enddate: string;

  /**
   * 담당자
   *
   * **data type** varchar()
   */
  readonly pernm: string;

  /**
   * 계약자
   *
   * **data type** varchar()
   */
  readonly contpernm: string;

  constructor(data: any = {}) {
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.state = data.state || '';
    this.contg = data.contg || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.amt = data.amt || '';
    this.recedate = data.recedate || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.pernm = data.pernm || '';
    this.contpernm = data.contpernm || '';
  }
}
