import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FiTrash, FiUpload } from 'react-icons/fi';
import { IoIosMail } from 'react-icons/all';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  DateTextBox,
  LabelBox,
  FormatTextBox,
  Button,
  ImageView,
  LoaderButton,
  DefaultLayout,
  ModalView,
  LayoutTitle,
  ComboBox,
  ComboBoxModel,
  Badge, CircleBadge,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BreakReport, BreakReportImages, BreakReportItemChangeTypes } from './BreakReport';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';
import { Appgubuns } from '../../../install/sales/Report/headers/ReportGridHeader';
import { SearchDateRange } from '../../../../components/search';

/**
 * 화면
 * @window w_tb_e411_wad
 * @category 고장다발처리보고서
 */
export const BreakReportTemplate: React.FC<TemplateProps<BreakReport>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.focusedDetailBreakReport;
  const { user } = scope.props.publicStore;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>

        <FlexLayout size={280}>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            weight={1.4}
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => scope.setState({ divicd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002_2',
              { uv_arg1: model?.spjangcd },
              true,
              (item) => {
                scope.setState({
                  divicd: item.cd,
                  divinm: item.cdnm,
                  divicdPopup: item.cd,
                  divinmPopup: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={280}>
          <span style={{ color: 'var(--color-red)' }}>입력자</span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm === '%' ? '' : scope.state.pernm}
            textAlign="center"
            onChange={(value) => scope.setState({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                  peridPopup: item.cd,
                  pernmPopup: item.cdnm,
                });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid === '%' ? '' : scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout />

        <Button onClick={() => scope.PopupModal(true)}>
          <div style={{ width: 104 }}>다발고장 불러오기</div>
        </Button>
        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 104 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout weight={1}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'waddate',
                text: '다발보고일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={x.waddate}
                  />
                ),
              },
              {
                id: 'wadnum',
                text: '번호',
                width: 50,
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 150,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.actnm}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.equpnm}
                  />
                ),
              },
              {
                id: 'wad',
                text: '다발횟수',
                width: 70,
                color: 'var(--color-red)',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.wad}회
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '결재상태',
                width: 70,
                render: (x) => {
                  const fp = Appgubuns.filter((y: any) => y.value === x.appgubun);
                  const nm = fp.length > 0 ? fp[0].remark : '';
                  const color = fp.length > 0 ? fp[0].color : '#000000';

                  return (
                    <FlexLayout
                      align="center"
                      justify="center"
                      style={{
                        color,
                      }}
                    >
                      {nm}
                      {x?.appremark && <Badge>
                        <IoIosMail color="var(--color-red)" size={25} />
                      </Badge>}
                    </FlexLayout>
                  );
                },
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
            data={scope.state.BreakReportList || []}
          />
        </FlexLayout>
        <FlexLayout
          weight={2}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 현장을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="다발보고일자"
              isNeed={true}
              size={150}
            >
              <DateTextBox
                value={model?.waddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ waddate: value })}
              />
            </LabelBox>
            <LabelBox
              title="보고시간"
              size={150}
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.wadtime}
                onChange={(value) => setData({ wadtime: value })}
              />
            </LabelBox>
            <LabelBox title="보고자명"
              style={{ color: 'var(--color-red)' }}
              size={206}
            >
              <TextBox
                value={model?.inperid}
                textAlign="center"
                onChange={(value) => setData({ inperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      inperid: item.cd,
                      inpernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model?.inpernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="현장명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                style={{ marginLeft: 2 }}
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    }, () => scope.itemChanged(BreakReportItemChangeTypes.ACTCD));
                  },
                )}
              />
              <TextBox
                value={model?.actnm}
                weight={3}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="담당자"
              size={100}
            >
              <TextBox
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="호기명"
              style={{ color: 'var(--color-red)' }}
              size={200}
            >
              <TextBox
                value={model?.equpcd}
                textAlign="center"
                onChange={(value) => setData({ equpcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E611',
                  { uv_arg1: scope.state.focusedDetailBreakReport?.actcd },
                  true,
                  (item) => {
                    setData({
                      equpcd: item.cd,
                      equpnm: item.cdnm,
                      elno: item.uv_arg1,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.equpnm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="접수(고장)일시"
              isNeed={true}
              size={150}
            >
              <DateTextBox
                value={model?.recedate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ recedate: value })}
              />
            </LabelBox>
            <LabelBox
              title="접수시간"
              size={150}
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.recetime}
                onChange={(value) => setData({ recetime: value })}
              />
            </LabelBox>
            <LabelBox title="접수자명"
              style={{ color: 'var(--color-pink)' }}
            >
              <TextBox
                value={model?.receperid}
                textAlign="center"
                onChange={(value) => setData({ receperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      receperid: item.cd,
                      recepernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model?.recepernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장내용"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model?.contcd}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E010',
                  {},
                  true,
                  (item) => {
                    setData({
                      contcd: item.cd,
                      contnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.contnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장상세내용"
              size={400}
            >
              <TextBox
                value={model?.contents}
                textAlign="left"
                onChange={(value) => setData({ contents: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="도착일자"
              isNeed={true}
              size={150}
            >
              <DateTextBox
                value={model?.arrivdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ arrivdate: value })}
              />
            </LabelBox>
            <LabelBox
              title="도착시간"
              size={150}
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.arrivtime}
                onChange={(value) => setData({ arrivtime: value })}
                onBlur={() => scope.itemChanged(BreakReportItemChangeTypes.ARRIVTIME)}
              />
            </LabelBox>
            <LabelBox
              title="대응시간"
            >
              <TextBox
                textAlign="center"
                value={model?.resutime}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="완료일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.compdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ compdate: value })}
              />
              <TextBox
                textAlign="center"
                value={model?.compnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="완료시간"
              size={150}
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.comptime}
                onChange={(value) => setData({ comptime: value })}
                onBlur={() => scope.itemChanged(BreakReportItemChangeTypes.COMPTIME)}
              />
            </LabelBox>
            <LabelBox
              title="처리시간"
              size={150}
            >
              <TextBox
                textAlign="center"
                value={model?.resulttime}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="처리자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    }, () => scope.itemChanged(BreakReportItemChangeTypes.PERID));
                  },
                )}
              />
              <TextBox
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="처리자부서"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                textAlign="center"
                value={model?.divicd}
                onChange={(value) => setData({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="(지원)처리자2"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid2}
                textAlign="center"
                onChange={(value) => setData({ perid2: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid2: item.cd,
                      pernm2: item.cdnm,
                    }, () => scope.itemChanged(BreakReportItemChangeTypes.PERID2));
                  },
                )}
              />
              <TextBox
                value={model?.pernm2}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="(지원)처리자부서"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                textAlign="center"
                value={model?.divicd2}
                onChange={(value) => setData({ divicd2: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd2: item.cd,
                      divinm2: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm2}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="##-######-###-####"
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="다발고장횟수"
              size={200}
              style={{ color: 'var(--color-red)' }}
            >
              <FormatTextBox
                format="#회"
                textAlign="center"
                value={model?.wad}
                onChange={(value) => setData({ wad: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="처리결과"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.6}
                value={model?.resultcd}
                textAlign="center"
                onChange={(value) => setData({ resultcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E015',
                  {},
                  true,
                  (item) => {
                    setData({
                      resultcd: item.cd,
                      resultnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.resultnm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장부위"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                weight={0.6}
                textAlign="center"
                value={model?.gregicd}
                onChange={(value) => setData({ gregicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E013',
                  {},
                  true,
                  (item) => {
                    setData({
                      gregicd: item.cd,
                      greginm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                textAlign="left"
                value={model?.greginm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="고장부위상세"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.6}
                value={model?.regicd}
                textAlign="center"
                onChange={(value) => setData({ regicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E014',
                  { uv_arg1: model?.gregicd },
                  true,
                  (item) => {
                    setData({
                      regicd: item.cd,
                      reginm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.reginm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="고장요인"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.6}
                value={model?.remocd}
                textAlign="center"
                onChange={(value) => setData({ remocd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E011',
                  {},
                  true,
                  (item) => {
                    setData({
                      remocd: item.cd,
                      remonm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.remonm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장원인"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                weight={0.6}
                textAlign="center"
                value={model?.faccd}
                onChange={(value) => setData({ faccd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E019',
                  {},
                  true,
                  (item) => {
                    setData({
                      faccd: item.cd,
                      facnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                textAlign="left"
                value={model?.facnm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="처리내용"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.6}
                value={model?.resucd}
                textAlign="center"
                onChange={(value) => setData({ resucd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E012',
                  {},
                  true,
                  (item) => {
                    setData({
                      resucd: item.cd,
                      resunm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.resunm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={150}>
            <LabelBox title="고장원인상세">
              <TextBox
                value={model?.remoremark}
                onChange={(value) => setData({ remoremark: value })}
                isMultiline={true}
              />
            </LabelBox>

            <LabelBox title="처리내용상세">
              <TextBox
                value={model?.resuremark}
                onChange={(value) => setData({ resuremark: value })}
                isMultiline={true}
              />
            </LabelBox>

            <LabelBox title="사후관리사항(원인및조치내용)">
              <TextBox
                value={model?.customer}
                onChange={(value) => setData({ customer: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={170}>
            <LabelBox
              title="조사내용사진"
              footer={
                <DefaultLayout size={120}>
                  <FlexLayout>
                    <React.Fragment>
                      <LoaderButton
                        onClick={() => scope.imageSelect(BreakReportImages.SEARCH)}
                        percent={scope.state.percentImageSearch}
                      >
                        <FiUpload />
                        <span>등록</span>
                      </LoaderButton>

                      <Button
                        isFull={true}
                        theme={BUTTON_THEMES.RED}
                        onClick={() => scope.imageDelete(BreakReportImages.SEARCH)}
                      >
                        <FiTrash />
                        <span>삭제</span>
                      </Button>
                    </React.Fragment>
                  </FlexLayout>
                </DefaultLayout>
            }>
              <ImageView
                src={scope.state.img}
              />
            </LabelBox>

            <LabelBox
              title="처리내용사진"
              footer={
                <DefaultLayout size={120}>
                  <FlexLayout>
                    <React.Fragment>
                      <LoaderButton
                        onClick={() => scope.imageSelect2(BreakReportImages.ACT)}
                        percent={scope.state.percentImageAct}
                      >
                        <FiUpload />
                        <span>등록</span>
                      </LoaderButton>

                      <Button
                        isFull={true}
                        theme={BUTTON_THEMES.RED}
                        onClick={() => scope.imageDelete2(BreakReportImages.ACT)}
                      >
                        <FiTrash />
                        <span>삭제</span>
                      </Button>
                    </React.Fragment>
                  </FlexLayout>
                </DefaultLayout>
              }>
              <ImageView
                src={scope.state.img2}
              />
            </LabelBox>

            <LabelBox title="고객요구사항">
              <TextBox
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>


        </FlexLayout>
      </FlexLayout>
      <ModalView
        isVisible={scope.state.popupModal}
        onClose={() => scope.PopupModal(false)}
        width={1500}
        height={1000}
        zIndex={-2}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              다발고장 불러오기
            </LayoutTitle>
          </FlexLayout>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ width: 1300 }}
          >
            <FlexLayout size={250}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdatePopup}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdatePopup: value })}
                onEnter={() => scope.popupRetrieve()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdatePopup));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdatePopup));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={220}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddatePopup}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddatePopup: value })}
                onEnter={() => scope.popupRetrieve()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddatePopup));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddatePopup));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span>고장다발기준</span>
              <FormatTextBox
                format={'월 # 회 이상 호기'}
                color= {'var(--color-red)'}
                value={scope.state.wadcntPopup}
                onChange={(value) => scope.setState({ wadcntPopup: value })}
                onEnter={() => scope.popupRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span>처리</span>
              <ComboBox
                value={scope.state.gubunPopup}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '0', remark: '미처리' },
                  { value: '1', remark: '처리' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ gubunPopup: option.value },
                  () => scope.popupRetrieve())}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ width: 1300 }}
          >
            <FlexLayout size={446}>
              <span>현장명</span>
              <TextBox
                value={scope.state.cltcdPopup}
                style={{ marginLeft: 10 }}
                onChange={(value) => scope.setState({ cltcdPopup: value })}
                onEnter={() => scope.popupRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout size={326} style={{ marginLeft: 80 }}>
              <span
                style={{
                  color: 'var(--color-red)',
                }}>
                부서
              </span>
              <TextBox
                textAlign="center"
                value={scope.state.divicdPopup}
                onChange={(value) => scope.setState({ divicdPopup: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      divicdPopup: item.cd,
                      divinmPopup: item.cdnm,
                    });
                    scope.popupRetrieve();
                  },
                )}
              />
              <TextBox
                value={scope.state.divinmPopup}
                readonly={true}
                onChange={(value) => scope.setState({ divinmPopup: value })}
              />
            </FlexLayout>
            <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>담당</span>
              <TextBox
                value={scope.state.pernmPopup}
                textAlign="center"
                onChange={(value) => scope.setState({ pernmPopup: value })}
                onEnter={() => scope.popupRetrieve()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      peridPopup: item.cd,
                      pernmPopup: item.cdnm,
                    });
                    scope.popupRetrieve();
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.peridPopup}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>
          </SearchLayout>
          <GridLayout
            ref={scope.popupGrid}
            header={[
              {
                id: 'actcd',
                text: '현장코드',
                isHidden: true,
                group: true,
              },
              {
                id: 'equpcd',
                text: '호기코드',
                isHidden: true,
                group: true,
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.actnm}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 120,
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.equpcnt_tot}대
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="left"
                  >
                    {x.equpnm}
                    <CircleBadge>
                      {Number(x.wadcnt) > 1 && <span
                        style={{ backgroundColor: 'var(--color-red)' }}
                      >
                        {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
                      </span>}
                      {Number(x.lastcnt) > 1 && <span
                        style={{ backgroundColor: 'var(--color-blue)' }}
                      >
                        {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
                      </span>}
                    </CircleBadge>
                  </FlexLayout>
                ),
              },
              {
                id: 'actdivinm',
                text: '담당부서명',
                width: 80,
                sum: (x, data) => {
                  const sumActdivinm = Sum.groupLength(data, 'actdivinm', x, ['equpnm', 'actdivinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: '#8106aa' }}
                    >
                      {Format.number(sumActdivinm)} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.cnt_tot}건
                  </FlexLayout>
                ),
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.actdivinm}
                  />
                ),
              },
              {
                id: 'actpernm',
                text: '담당자',
                width: 80,
              },
              {
                id: 'seq',
                text: '처리',
                width: 50,
                render: (x) => (
                  <FormatTextBox
                    format="#차"
                    textAlign="center"
                    value={x.seq}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'repernm',
                text: '접수자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.repernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    format="####-##-##"
                    textAlign='center'
                    value={x.recedate}
                  />
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={x.compdate}
                  />
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={x.arrivdate}
                  />
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 80,
                render: (x) => (
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={x.arrivtime}
                  />
                ),
              },
              {
                id: 'cnt',
                text: '대수',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'ascnt',
                text: '건수',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.ascnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.contnm}
                  />
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.contremark}
                  />
                ),
              },
              {
                id: 'resunm',
                text: '처리내용',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.resunm}
                  />
                ),
              },
              {
                id: 'resuremark',
                text: '처리내용상세',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.resuremark}
                  />
                ),
              },
              {
                id: 'remonm',
                text: '고장요인',
                width: 150,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.remonm}
                  />
                ),
              },
              {
                id: 'remoremark',
                text: '고장요인상세',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.remoremark}
                  />
                ),
              },
              {
                id: 'facnm',
                text: '고장원인',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.facnm}
                  />
                ),
              },
              {
                id: 'greginm',
                text: '고장부위',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.greginm}
                  />
                ),
              },
              {
                id: 'reginm',
                text: '고장부위상세',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.reginm}
                  />
                ),
              },
              {
                id: 'resultnm',
                text: '처리방법',
                width: 100,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.resultnm}
                  />
                ),
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 80,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.resutime}
                  />
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 80,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.resulttime}
                  />
                ),
              },
              {
                id: 'result',
                text: '진행내역',
                width: 100,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.result === '0' ? '미완료' : '완료'}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinityPopup}
            onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
          />
          <FlexLayout size={130}>
            <LabelBox
              title="고장내용상세"
            >
               <TextBox
                value={scope.state.focusedPopupData?.contremark}
                onChange={(value) => setData({ contremark: value })}
                isMultiline={true}
               />
            </LabelBox>
            <LabelBox
              title="고장요인상세"
            >
              <TextBox
                value={scope.state.focusedPopupData?.remoremark}
                isMultiline={true}
              />
            </LabelBox>
            <LabelBox
              title="처리내용상세"
            >
              <TextBox
                value={scope.state.focusedPopupData?.resuremark}
                onChange={(value) => setData({ resuremark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout>
              <FlexLayout weight={1}>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.BLUE}
                  onClick={() => scope.popupSave()}
                >
                  확인
                </Button>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.onPopupClose()}
                >
                  닫기
                </Button>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
