import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Worker } from './Worker';
import { TabModel } from '../../../../models';
import { WorkerTabDefaultTemplate } from './tabs/WorkerTabDefault.template';
import { WorkerTabContractTemplate } from './tabs/WorkerTabContract.template';
import { WorkerTabPaymentsTemplate } from './tabs/WorkerTabPayments.template';

/**
 * 화면 > 탭
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTab: Array<TabLayoutDataItem<Worker>> = [
  {
    tab: new TabModel('DEFAULT', FaCodeBranch, '기본정보'),
    template: (scope) => <WorkerTabDefaultTemplate scope={scope} />,
  },
  {
    tab: new TabModel('CONTRACT', FaCodeBranch, '계약사항'),
    template: (scope) => <WorkerTabContractTemplate scope={scope} />,
  },
  {
    tab: new TabModel('PAYMENTS', FaCodeBranch, '지급현황'),
    template: (scope) => <WorkerTabPaymentsTemplate scope={scope} />,
  },
];
