import { FiArrowRightCircle } from 'react-icons/fi';
import { TLHF } from '../../../../../constants';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatNumberTextBox,
  TextBox,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import EnrollmentMaterialsModel from '../models/EnrollmentMaterialsModel';
import { SearchBinding } from '../../../../../models';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 탭 - 부품내역등록
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentMaterialsTableHeader: TLHF<EnrollmentMaterialsModel, Enrollment> = (scope) => ([
  {
    id: 'pcode',
    text: '품번',
    width: 100,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pcode}
        textAlign="center"
        onDoubleClick={async () => {
          const result = await scope.props.modalStore.openProductSelector('');
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
            samt: '0',
            costamt: '0',
            addamt: '0',
            qty: '0',
          });
        }}
        onChange={(value) => rowUpdate({ pcode: value })}
        onEnter={async (value) => {
          const result = await scope.props.modalStore.openProductSelector(value);
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
            samt: '0',
            costamt: '0',
            addamt: '0',
            qty: '0',
          });
        }}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품명',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pname}
        onChange={(v) => rowUpdate({ pname: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.psize}
        onChange={(v) => rowUpdate({ psize: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.punit}
        onChange={(v) => rowUpdate({ punit: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'equpcd',
    text: '동호기',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.equpcd}
        textAlign="center"
        onChange={(v) => rowUpdate({ equpcd: v })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: scope.state.content?.actcd },
          true,
          (item) => {
            rowUpdate({
              equpcd: item.cd,
              equpnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '동호기명',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        textAlign="center"
        ref={ref}
        value={x.equpnm}
        onChange={(v) => rowUpdate({ equpnm: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 60,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.myn}
        data={[
          { value: '1', remark: '무상' },
          { value: '2', remark: '유상' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => scope.tabAutoCalc({ ...x, myn: option.value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 60,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.qty}
        onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
        onBlur={() => scope.tableMaterialsItemChanged(rowUpdate, 'qty', x.qty, x)}
        onEnter={() => scope.tableMaterialsItemChanged(rowUpdate, 'qty', x.qty, x)}
      />
    ),
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FlexLayout>
        <FormatNumberTextBox
          ref={ref}
          textAlign="right"
          value={x.uamt}
          onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
          onBlur={() => scope.tableMaterialsItemChanged(rowUpdate, 'uamt', x.uamt, x)}
          onEnter={() => scope.tableMaterialsItemChanged(rowUpdate, 'uamt', x.uamt, x)}
        />
          <Button
            onClick={() => scope.materialModal(true)}
            isIcon={true}
            theme={BUTTON_THEMES.BLUE}
          >
            <FiArrowRightCircle size={25}/>
          </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, true)}
        onEnter={() => scope.tableMaterialsItemChanged(rowUpdate, 'samt', x.samt, x)}
      />
    ),
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.addamt}
        onChange={(v) => scope.tableMaterialsItemChanged(rowUpdate, 'addamt', v, x)}
        onEnter={() => scope.tableMaterialsItemChanged(rowUpdate, 'addamt', x.addamt, x)}
      />
    ),
    trail: (data) => {
      const sumAddamt = Sum.all(data, 'addamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAddamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'costamt',
    text: '견적금액',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.costamt}
        onChange={(v) => rowUpdate({ costamt: v })}
      />
    ),
    trail: (data) => {
      const sumCostamt = Sum.all(data, 'costamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCostamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
