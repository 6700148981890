import { computed } from 'mobx';

/**
 *  프로젝트 팝업 모델
 * @window w_tb_ca640
 * @category 매입등록
 */
export class ProjectModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 증빙구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * -
   *
   * **data type** varchar(13)
   */
  costnum: string;

  /**
   * -
   *
   * **data type** varchar(13)
   */
  costdate: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 할인금액
   *
   * **data type** number
   */
  saleamt: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  mamt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 공급가액
   *
   * **data type** number
   */
  samt: string;

  /**
   * -
   *
   * **data type** varchar(13)
   */
  constnm: string;

  /**
   * 버튼상태
   *
   * **data type** varchar(1)
   */
  stateBtn: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.stateBtn = data.stateBtn || '';
    this.actnm = data.actnm || '';
    this.constnm = data.constnm || '';
    this.costnum = data.costnum || '';
    this.costdate = data.costdate || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.gubun = data.gubun || '';
    this.mamt = data.mamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.saleamt = data.saleamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
