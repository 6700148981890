import { TLHF } from '../../../../../constants';
import { DateTextBox, FlexLayout, TextBox } from '../../../../../components';
import { Regist } from '../Regist';

/**
 * 화면 > 상세 > 탭 - 현장협의사항 > 테이블
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabTableConferenceHeader: TLHF<any, Regist> = (scope) => ([
  {
    id: 'seq',
    text: '순서',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'scdate',
    text: '협의날짜',
    width: 100,
    render: (x, rowUpdate, refs) => (
      <DateTextBox
        refs={refs}
        textAlign="center"
        value={x.scdate}
        format="####-##-##"
        onChange={(v) => rowUpdate({ scdate: v })}
      />
    ),
  },
  {
    id: 'remark',
    text: '현장협의사항',
    width: 400,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.remark}
        textAlign="left"
        onChange={(v) => {
          rowUpdate({ remark: v });
          scope.SS({ detailConferenceRemark: v });
        }}
      />
    ),
  },
  {
    id: 'inpernm',
    text: '작성자',
    width: 100,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        textAlign="center"
        value={x.inpernm}
        onChange={(v) => rowUpdate({ inpernm: v })}
      />
    ),
  },
]);
