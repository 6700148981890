import * as React from 'react';
import {
  FlexLayout,
  LabelBox,
  TextBox,
  GridLayout,
  LayoutTitle,
  OptionBox,
  CheckBox,
  Button,
  ComboBox,
  ComboBoxModel,
  ModalView, FormatTextBox,
} from '../../../../components';
import { Settings } from './Settings';
import { Global, TemplateProps } from '../../../../constants';
import { SettingsModel } from './Settings.model';


/**
 * 화면
 * @window w_xusers
 * @category 개인환경설정
 */
export const SettingsTemplate: React.FC<TemplateProps<Settings>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  const setContent = (data: any, callback?: () => void) => scope?.setState({
    data: new SettingsModel({
      ...scope.state.data,
      ...data,
    }, scope.state.data.isNew),
  }, callback);
  return (
    <FlexLayout>
      <GridLayout
        header={[
          {
            id: 'userid',
            text: '사용자ID',
            width: 50,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.userid}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '사용자명',
            width: 30,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.pernm}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.settingsList}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      />
      <FlexLayout isVertical={true} weight={3}>
        <LayoutTitle>항목</LayoutTitle>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1} >
          <LabelBox title="주민번호" isNeed={true} >
            <FormatTextBox
              textAlign="center"
              format="######-#$$$$$$"
              value={model.rnum}
              onChange={(value) => setData({ rnum: value })}
              readonly={model.rnum !== ''}
            />
          </LabelBox>

          <LabelBox title="사원번호">
            <TextBox
              textAlign="center"
              value={model.perid}
              onChange={(value) => setData({ perid: value })}
              readonly={true}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="사용자ID" isNeed={true}>
            <TextBox
              textAlign="center"
              value={model.userid}
              onChange={(value) => setData({ userid: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox title="사용자성명" isNeed={true}>
            <TextBox
              textAlign="center"
              value={model.pernm}
              onChange={(value) => setData({ pernm: value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="비밀번호" isNeed={true}>
            <TextBox
              textAlign="center"
              type="password"
              value={model.passwd1}
              onChange={(value) => setData({ passwd1: value })}
            />
          </LabelBox>

          <LabelBox title="비밀번호확인" isNeed={true}>
            <TextBox
              textAlign="center"
              type="password"
              value={model.passwd2}
              onChange={(value) => setData({ passwd2: value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="연관검색설정">
            <OptionBox
              value={model.search}
              data={[
                { value: '1', remark: '사용' },
                { value: '0', remark: '미사용' },
              ]}
              onChange={(item) => setData({ search: item.value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="전자세금계산서 예약전송권한">
            <OptionBox
              value={model.taxreserv}
              data={[
                { value: '1', remark: '사용' },
                { value: '0', remark: '미사용' },
              ]}
              onChange={(item) => setData({ taxreserv: item.value })}
            />
          </LabelBox>

          <LabelBox title="전송선택여부">
            <ComboBox
              value={model.tax_funccode}
              data={[
                { value: 'DD', remark: '즉시신고' },
                { value: 'CD', remark: '승인후신고' },
                { value: 'DE', remark: '익일신고' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(item) => setData({ paytype: item.value })}
            />
          </LabelBox>

          <LabelBox title="보수관리권한">
            <OptionBox
              value={model.contyn}
              data={[
                { value: '1', remark: '사용' },
                { value: '0', remark: '미사용' },
              ]}
              onChange={(item) => setData({ contyn: item.value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="설치관리권한">
            <OptionBox
              value={model.sulchi}
              data={[
                { value: '1', remark: '사용' },
                { value: '0', remark: '미사용' },
              ]}
              onChange={(item) => setData({ sulchi: item.value })}
            />
          </LabelBox>

          <LabelBox title="위치정보열람이용신청">
            <Button onClick={() => scope.openLBSJoin()}>신청</Button>

            <ComboBox
              value={model.state}
              data={[
                { value: '0', remark: '미신청' },
                { value: '1', remark: '거부' },
                { value: '2', remark: '신청' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={() => {}}
            />
          </LabelBox>

          <LabelBox title="공지사항관리자권한">
            <OptionBox
              value={model.noticeflag}
              data={[
                { value: '1', remark: '사용' },
                { value: '0', remark: '미사용' },
              ]}
              onChange={(item) => setData({ noticeflag: item.value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="자체점검계획생성">
            <OptionBox
              value={model.e035_autoflag}
              data={[
                { value: '0', remark: '위치기반생성' },
                { value: '1', remark: '전월기반생성' },
              ]}
              onChange={(item) => setData({ e035_autoflag: item.value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="테마설정">
            <OptionBox
              value={model.color}
              data={[
                { value: 'blue', remark: '블루' },
                { value: 'brown', remark: '브라운' },
                { value: 'pink', remark: '핑크' },
                { value: 'gray', remark: '그레이' },
              ]}
              onChange={(item) => {
                setData({ color: item.value });
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                scope.props.publicStore.themeName = item.value;
              }}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="읽기 전용란 배경색 진하게">
            <CheckBox
              value={model.contrast === '1'}
              onChange={(value) => setContent({ contrast: value ? '1' : '0' }, () => {
                scope.onAlert();
              })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="사용유무">
            <CheckBox
              value={model.useyn === '1'}
              onChange={(value) => setData({ useyn: value ? '1' : '0' })}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout isVertical={false} weight={2}>
        <FlexLayout isVertical={true}>
          <LayoutTitle>설명</LayoutTitle>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>사용할 직원의 주민번호를 입력합니다. <br/>
                사번이 나오지 않을경우 주민번호, 직원등록을 확인합니다.</p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>사용할 아이디를 입력합니다. <br/>
                사용자의 성명을 입력합니다.</p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>변경할 비밀번호를 입력합니다. <br/>
                비밀번호를 한번더 입력해주세요.</p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>
                체크시 기본 연관검색을 실시합니다.
              </p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>
                전자세금계산서 관련 알림받기를 설정하는곳입니다.
              </p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>
                설치, 위지, 공지사항의 알림받기를 설정하는곳입니다.
              </p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>
                자체점검은 매월 25일 익월 계확을 자동 생성합니다. <br/>
                위치기반선택시 배정받은 현장이 가까운 순으로 자동계산되어 생성됩니다. <br/>
                전월기반선택시 전월에 계획한 내용을 토대로 생섭됩니다.
              </p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>색상선택시 프로그램 테마색상이 변경됩니다.</p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>연한 색상을 더 진하게 보이게 합니다.</p>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout align="center">
              <p>체크시 아이디가 사용됩니다.</p>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <ModalView
        isVisible={scope.state.settingModal}
        onClose={() => scope.toggleSettingsModal(false)}
      >
        <FlexLayout isVertical={true} weight={3} style={{ padding: 8 }}>
          <LayoutTitle>위치정보시스템 사용 신청</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="부서">
              <TextBox
                value={model.divinm}
                onChange={(value) => setData({ divinm: value })}
              />
            </LabelBox>

            <LabelBox title="직위">
              <TextBox
                value={model.rspnm}
                onChange={(value) => setData({ rspnm: value })}
              />
            </LabelBox>

            <LabelBox title="이름">
              <TextBox
                value={model.pernm}
                onChange={(value) => setData({ pernm: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="시작일자">
              <TextBox
                value={model.stdate}
                onChange={(value) => setData({ stdate: value })}
              />
            </LabelBox>

            <LabelBox title="종료일자">
              <TextBox
                value={model.enddate}
                onChange={(value) => setData({ enddate: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="용도">
              <TextBox
                value={model.state2}
                onChange={(value) => setData({ state2: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="특이사항" >
              <TextBox
                value={model.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="컴퓨터이름">
              <TextBox
                value={model.pcname}
                onChange={(value) => setData({ pcname: value })}
              />
            </LabelBox>

            <LabelBox title="MAC주소">
              <TextBox
                value={model.mac}
                onChange={(value) => setData({ mac: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout align="center">
            <p style={{ color: 'var(--color-red)' }}> 이 프로그램을 사용하여 신청한 본인과 PC단말에서만 <br/>
              위치정보시스템을 사용할 수 있습니다.</p>
          </FlexLayout>

          <FlexLayout
            className="modal-buttons"
            size={Global.LAYOUT_GRID_HEIGHT_1}
          >
            <Button
              className="btn-success"
              isFull={true}
              onClick={() => false}
            >
              신청완료
            </Button>
          </FlexLayout>

        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
