import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PreviousMonth } from './PreviousMonth';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { Date6Calculator } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_e101w_07
 * @category 전월대비 계약변동내역
 */
export const PreviousMonthTemplate: React.FC<TemplateProps<PreviousMonth>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>당월</span>
          <DateTextBox
            value={scope.state.mon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => scope.setState({ mon: value, bemon: new Date6Calculator(value).add(-1).toString() })}
            head={(<button
              onClick={() => {
                scope.setState({
                  mon: new Date6Calculator(scope.state.mon).add(-1).toString(),
                  bemon: new Date6Calculator(scope.state.bemon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                scope.setState({
                  mon: new Date6Calculator(scope.state.mon).add().toString(),
                  bemon: new Date6Calculator(scope.state.bemon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          >
          </DateTextBox>
        </FlexLayout>

        <FlexLayout size={160}>
          <span>전월</span>
          <DateTextBox
            value={scope.state.bemon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => scope.SS({ bemon: value })}
            isDisabledTrackingStateChange={true}
            readonly={true}
          >
          </DateTextBox>
        </FlexLayout>

        <FlexLayout size={400}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            textAlign="left"
            onChange={(value) => scope.SS({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          >
          </TextBox>
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            weight={0.8}
            value={scope.state.perid}
            textAlign="center"
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.SS({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            onChange={(value) => scope.SS({ perid: value })}
            isDisabledTrackingStateChange={true}
          >
          </TextBox>
          <TextBox
            value={scope.state.pernm}
            textAlign="center"
            readonly={true}
          >
          </TextBox>
        </FlexLayout>

        <FlexLayout size={200}>
          <span>변동유무</span>
          <ComboBox
            value={scope.state.chflag}
            textAlign="left"
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '변동내역만' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ chflag: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          >
          </ComboBox>
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'actcd',
              text: '코드',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 360,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '대수',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty}
                </FlexLayout>
              ),
            },
            {
              id: 'amt',
              text: '보수료',
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt)}
                </FlexLayout>
              ),
            },
            {
              id: 'contgubunnm',
              text: '계약구분',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.contgubunnm !== x.becontgubunnm ? { color: 'var(--color-violet)' } : { color: 'var(--color-black)' }}
                >
                  {x.contgubunnm}
                </FlexLayout>
              ),
            },
            {
              id: 'contgnm',
              text: '분류',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.contgnm !== x.becontgnm ? { color: 'var(--color-violet)' } : { color: 'var(--color-black)' }}
                >
                  {x.contgnm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.pernm !== x.bepernm ? { color: 'var(--color-violet)' } : { color: 'var(--color-black)' }}
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'chqty',
              text: '대수변동',
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={Number(x.chqty) > 0 ? { color: 'var(--color-blue)' }
                      : Number(x.chqty) < 0 ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                  >
                    {x.chqty_arrow}
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                    style={Number(x.chqty) > 0 ? { color: 'var(--color-blue)' }
                      : Number(x.chqty) < 0 ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                  >
                    {x.chqty}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'chamt',
              text: '금액변동',
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={Number(x.chamt) > 0 ? { color: 'var(--color-blue)' }
                      : Number(x.chamt) < 0 ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                  >
                    {x.chamt_arrow}
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                    style={Number(x.chamt) > 0 ? { color: 'var(--color-blue)' }
                      : Number(x.chamt) < 0 ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                  >
                    {Format.number(x.chamt)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'beqty',
              text: '전대수',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {x.beqty}
                </FlexLayout>
              ),
            },
            {
              id: 'beamt',
              text: '전보수료',
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {Format.number(x.beamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'becontgubunnm',
              text: '전계약구분',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.becontgubunnm === x.contgubunnm ? { color: 'var(--color-green)' } : { color: 'var(--color-violet)' }}
                >
                  {x.becontgubunnm}
                </FlexLayout>
              ),
            },
            {
              id: 'becontgnm',
              text: '전분류',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.becontgnm === x.contgnm ? { color: 'var(--color-green)' } : { color: 'var(--color-violet)' }}
                >
                  {x.becontgnm}
                </FlexLayout>
              ),
            },
            {
              id: 'bepernm',
              text: '전담당자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.bepernm === x.pernm ? { color: 'var(--color-green)' } : { color: 'var(--color-violet)' }}
                >
                  {x.bepernm}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.data}
        >
        </GridLayout>
      </FlexLayout>
    </FlexLayout>
);
