import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  SearchLayout,
  Button,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SafetyManage } from './SafetyManage';

/**
 * 화면
 * @window tb_e503
 * @category 안전관리코드등록
 */
export const SafetyManageTemplate: React.FC<TemplateProps<SafetyManage>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout style={{ width: 230 }} justify="left" weight={1}>
          <FlexLayout>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="그룹으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
        <FlexLayout justify="right" weight={3.5}>
          <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.dw_2_new()}>추가</Button>
          <Button style={{ height: '25px', width: '60px' }} theme={BUTTON_THEMES.RED}
                  onClick={() => scope.dw_2_delete()}>삭제</Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout weight={1.5}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'gsafecd',
                text: '안전그룹코드',
                width: 20,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    textAlign="center"
                    ref={ref}
                    value={x.gsafecd}
                    onChange={(value) => rowUpdate({ gsafecd: value })}
                  />
                ),
              },
              {
                id: 'gsafenm',
                text: '그룹명',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    textAlign="left"
                    ref={ref}
                    value={x.gsafenm}
                    onChange={(value) => rowUpdate({ gsafenm: value })}
                  />
                ),
              },
            ]}
            data={scope.state.safetyManageList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item, i) => scope.onRowFocusEvent(item, i)}
          />
        </FlexLayout>

        <FlexLayout weight={3.5}>
          <TableLayout
            ref={scope.tableDetail}
            header={[
              {
                id: 'safecd',
                text: '안전코드',
                width: 10,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    textAlign="center"
                    ref={ref}
                    value={x.safecd}
                    onChange={(value) => rowUpdate({ safecd: value })}
                  />
                ),
              },
              {
                id: 'safenm',
                text: '안전내용',
                width: 90,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    textAlign="left"
                    ref={ref}
                    value={x.safenm}
                    onChange={(value) => rowUpdate({ safenm: value })}
                  />
                ),
              },
            ]}
            data={scope.state.safetyManageDetailList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity2}
            onChange={(rows2, updatedRows2) => {
              scope.onUpdatedRows2(rows2, updatedRows2);
            }}
            onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
