import { IconType } from 'react-icons';
import { AiOutlineQuestion } from 'react-icons/ai';
import { action, makeObservable, observable } from 'mobx';
import { ToolExecuteHandler } from '../../constants';

export default class ToolModel {
  readonly id: string;

  readonly iconType?: IconType;

  readonly text?: string

  @observable badge?: string;

  onExecute?: ToolExecuteHandler;

  constructor(
    id: string,
    icon: IconType,
    text: string,
    onExecute?: ToolExecuteHandler,
  ) {
    this.id = id;
    this.iconType = icon;
    this.text = text || '##';
    this.badge = '';
    this.onExecute = onExecute || (() => false);

    makeObservable(this);
  }

  @action
  public setBadgeText(text: string) {
    if (text === '0') this.badge = '';
    else {
      this.badge = text;
    }
  }

  get icon(): IconType {
    return this.iconType || AiOutlineQuestion;
  }
}
