import { TextBox, DateTextBox } from '../../../../../components';
import { TableLayoutHeader } from '../../../../../constants';
import { EnrollmentInchargeModel } from '../models';
import { Enrollment } from '../Enrollment';


// 담당자이력정보
const Incharge: TableLayoutHeader<EnrollmentInchargeModel, Enrollment>[] = [
  {
    id: 'seq',
    text: '순번',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.seq}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'perid',
    text: '담당자코드',
    width: 50,
    render: (x, _, ref) => (
      <TextBox
        ref={ref}
        value={x.perid}
        textAlign="center"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 70,
    render: (x) => (
      <TextBox
        value={x.pernm}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 80,
    render: (x, _, ref) => (
      <DateTextBox
        ref={ref}
        value={x.stdate}
        textAlign="center"
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'enddate',
    text: '종료일자',
    width: 80,
    render: (x, _, ref) => (
      <DateTextBox
        ref={ref}
        value={x.enddate}
        textAlign="center"
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 250,
    render: (x) => (
      <TextBox
        value={x.remark}
        transparent={true}
        textAlign="left"
      />
    ),
  },
  {
    id: 'inpernm',
    text: '변경자명',
    width: 80,
    render: (x) => (
      <TextBox
        value={x.inpernm}
        transparent={true}
        textAlign="center"
      />
    ),
  },
];

export default Incharge;
