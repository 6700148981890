import { FormatTextBox, FlexLayout } from '../../../../../components';
import { GridLayoutHeader } from '../../../../../constants';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { DetailModel } from '../models';
import { BankStatement } from '../BankStatement';

// 상세내역
const Detail: GridLayoutHeader<DetailModel, BankStatement>[] = [
  {
    id: 'misdate',
    text: '발생일자',
    width: 13,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.misdate}
      />
    ),
  },
  {
    id: 'mismon',
    text: '발생월',
    isHidden: true,
    group: true,
    value: (x) => x.misdate?.substr(0, 6),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 10,
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        월 계
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '적요',
    width: 25,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '지급처명',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '입금액',
    width: 13,
    sum: (x, data) => {
      const sumMisamt = Sum.byGroup(data, 'misamt', x, ['mismon']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMisamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab1_misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '지급액',
    width: 13,
    sum: (x, data) => {
      const sumMijamt = Sum.byGroup(data, 'mijamt', x, ['mismon']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMijamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab1_mijamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '잔액',
    width: 13,
    sum: (x, data) => {
      const sumResuamt = Sum.byGroup(data, 'resuamt', x, ['mismon']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumResuamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab1_resuamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
  {
    id: '',
    text: '비고',
    width: 18,
  },
];

export default Detail;
