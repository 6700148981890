import * as React from 'react';
import {
  FlexLayout,
  TextBox,
  ComboBoxModel,
  ComboBox,
  TelnumTextBox,
  GridLayout,
  SearchLayout,
  CheckBox, FormatTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Status } from './Status';

/**
 * 화면
 * @window w_tb_xclient_01
 * @category 거래처현황
 */
export const StatusTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout weight={2}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="거래처, 사업자번호, 주민(법인)번호, 대표자명, 주소, 비고로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={300}>
        <span>구분</span>
        <ComboBox
          value={scope.state.clttype}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '매입' },
            { value: '2', remark: '매출' },
            { value: '3', remark: '공통' },
          ].map((y) => new ComboBoxModel(y.value, y.remark))}
          onSelect={(option) => scope.setState({ clttype: option.value })}
        />
      </FlexLayout>
    </SearchLayout>

    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'cltcd',
          text: '코드',
          width: 50,
        },
        {
          id: 'cltnm',
          text: '거래처명',
          width: 220,
          render: (x) => (
           <FlexLayout
             justify="left"
             align="center"
           >
             {x.cltnm}
           </FlexLayout>
          ),
        },
        {
          id: 'saupnum',
          text: '사업자번호',
          width: 120,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="###-##-#####"
              value={x.saupnum}
            />
          ),
        },
        {
          id: 'prenm',
          text: '담당자명',
          width: 100,
        },
        {
          id: 'prenum',
          text: '주민(법인번호)',
          width: 120,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="######-#######"
              value={x.prenum}
            />
          ),
        },
        {
          id: 'biztypenm',
          text: '업태',
          width: 150,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.biztypenm}
            </FlexLayout>
          ),
        },
        {
          id: 'bizitemnm',
          text: '종목',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.biztypenm}
            </FlexLayout>
          ),
        },
        {
          id: 'jspjangcd',
          text: '종사업자',
          width: 50,
        },
        {
          id: 'jspjangnm',
          text: '종사업자명',
          width: 150,
        },
        {
          id: 'majo',
          text: '전공',
          width: 100,
        },
        {
          id: 'zipcd',
          text: '우편번호',
          width: 100,
        },
        {
          id: 'cltadres',
          text: '주소',
          width: 300,
          render: (x) => (
            <TextBox
              textAlign="left"
              value={x.cltadres}
            />
          ),
        },
        {
          id: 'telnum',
          text: '전화번호',
          width: 100,
          render: (x) => (
            <TelnumTextBox
              value={x.telnum}
            />
          ),
        },
        {
          id: 'faxnum',
          text: '팩스번호',
          width: 100,
          render: (x) => (
            <TelnumTextBox
              value={x.faxnum}
            />
          ),
        },
        {
          id: 'autoflag',
          text: '자동이체',
          width: 100,
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              <CheckBox
                value={x.autoflag === '1'}
              />
            </FlexLayout>
          ),
        },
        {
          id: 'agnernm',
          text: '계산서담당',
          width: 100,
        },
        {
          id: 'taxmail',
          text: '계산서메일',
          width: 200,
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
      onRowDoubleClick={(item) => scope.openResister(item)}
    />
  </FlexLayout>
);
