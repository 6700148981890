import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Proposal } from '../Proposal';
import ProposalModel from '../models/ProposalModel';
import { Date8 } from '../../../../../utils/time';


const Gubuns = [
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '15', remark: '리모델링' },
];


/**
 * 화면 > 그리드 레이아웃 A
 * @window w_sample
 * @category 샘플페이지
 */
export const ProposalGridHeader: GLHF<ProposalModel, Proposal> = (scope) => ([
  {
    id: 'propodate',
    text: '제안일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.propodate)}
      </FlexLayout>
    ),
  },
  {
    id: 'proponum',
    text: '번호',
    width: 60,
  },
  {
    id: 'gubun',
    text: '구분',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'part',
    text: '부품파트',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.parts.filter((y) => y.com_code === x.part)[0]?.com_cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
]);
