import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve, TabModel } from '../../../../models';
import { PaySalesTemplate } from './PaySales.template';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { MainModel } from './models/Main.model';
import { ModalModel } from './models/Modal.model';
import { FiveYearModel } from './models/FiveYear.model';
import { MonthModel } from './models/Month.model';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils/layout';
import { TableLayout } from '../../../../components';

interface PaySalesState {
  // Search
  year: string;

  // Header
  data: Array<MainModel>;
  focusIndex: number;
  focused?: MainModel;

  // Tab - FiveYear
  dataFiveYear: Array<FiveYearModel>;
  fiveYearFocused?: FiveYearModel;

  // Tab - Month
  dataMonth: Array<MonthModel>;
  monthFocused?: MonthModel;

  // modal
  textDetailModal: boolean;
  modalData: Array<ModalModel>;
  modalFocused?: ModalModel;

  // trail
  averamt_tot: string;
  contamt_tot: string;
  iamt2_tot: string;
  misamt2_tot: string;
  qty_aver_tot: string;
  qty_cls_aver_tot: string;
  qty_cls_tot: string;
  qty_fm_aver_tot: string;
  qty_fm_tot: string;
  qty_long_aver_tot: string;
  qty_long_tot: string;
  qty_new_aver_tot: string;
  qty_new_tot: string;
  qty_pog_aver_tot: string;
  qty_pog_tot: string;
  qty_tot: string;
  rcvamt2_tot: string;
  jamt_tot: string;
  averamt_aver_tot: string;

  // tab_trail
  tab_averamt_tot: string;
  tab_contamt_tot: string;
  tab_iamt2_tot: string;
  tab_misamt2_tot: string;
  tab_qty_aver_tot: string;
  tab_qty_cls_aver_tot: string;
  tab_qty_cls_tot: string;
  tab_qty_fm_aver_tot: string;
  tab_qty_fm_tot: string;
  tab_qty_long_aver_tot: string;
  tab_qty_long_tot: string;
  tab_qty_new_aver_tot: string;
  tab_qty_new_tot: string;
  tab_qty_pog_aver_tot: string;
  tab_qty_pog_tot: string;
  tab_qty_tot: string;
  tab_rcvamt2_tot: string;
  tab_jamt_tot: string;
  tab_contamt12_tot: string;

  // modal state
  notmis_okflag: string;
  notmis_remark: string;
  notmis_pernm: string;

  // modal trail
  modal_contamt_tot: string;
  modal_iamt2_tot: string;
  modal_jamt_tot: string;
  modal_misamt2_tot: string;
  modal_notmisamt_tot: string;
  modal_qty_tot: string;
  modal_rcvamt2_tot: string;

  // dropdown
  deljugis: Array<any>;
}


/**
 * 컨트롤러
 * @window w_tb_e101w_06
 * @category 보수수주매출통계
 */
export class PaySales extends PageComponent<PageProps, PaySalesState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  modalTable: React.RefObject<TableLayout> = React.createRef();

  tabIndex: number = 0;

  modalTab: string | undefined;

  updatedRows?: Array<ModalModel>; // 월별 수주매출내역탭 팝업

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // Search
      year: Today.year(),

      // Header
      data: [],
      focusIndex: 0,

      // Tab - FiveYear
      dataFiveYear: [],

      // Tab - Month
      dataMonth: [],

      // trail
      averamt_tot: '',
      contamt_tot: '',
      iamt2_tot: '',
      misamt2_tot: '',
      qty_aver_tot: '',
      qty_cls_aver_tot: '',
      qty_cls_tot: '',
      qty_fm_aver_tot: '',
      qty_fm_tot: '',
      qty_long_aver_tot: '',
      qty_long_tot: '',
      qty_new_aver_tot: '',
      qty_new_tot: '',
      qty_pog_aver_tot: '',
      qty_pog_tot: '',
      qty_tot: '',
      rcvamt2_tot: '',
      jamt_tot: '',
      averamt_aver_tot: '',

      // tab1_trail
      tab_averamt_tot: '',
      tab_contamt_tot: '',
      tab_iamt2_tot: '',
      tab_misamt2_tot: '',
      tab_qty_aver_tot: '',
      tab_qty_cls_aver_tot: '',
      tab_qty_cls_tot: '',
      tab_qty_fm_aver_tot: '',
      tab_qty_fm_tot: '',
      tab_qty_long_aver_tot: '',
      tab_qty_long_tot: '',
      tab_qty_new_aver_tot: '',
      tab_qty_new_tot: '',
      tab_qty_pog_aver_tot: '',
      tab_qty_pog_tot: '',
      tab_qty_tot: '',
      tab_rcvamt2_tot: '',
      tab_jamt_tot: '',
      tab_contamt12_tot: '',

      // modal state
      modalData: [],

      notmis_okflag: '',
      notmis_remark: '',
      notmis_pernm: '',

      // modal trail
      modal_contamt_tot: '',
      modal_iamt2_tot: '',
      modal_jamt_tot: '',
      modal_misamt2_tot: '',
      modal_notmisamt_tot: '',
      modal_qty_tot: '',
      modal_rcvamt2_tot: '',

      delgugis: [],
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const json = JSON.parse(JSON.parse(message));

    if (json?.key === 'wb_miscreate_lazytask') {
      ConfirmWarning.show('확인', json?.response.messagebox);
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 청구주기
    const data = await api.dropdown('wf_dd_ca510_007_01');
    this.setState({ deljugis: data?.items || [] });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year || '',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new MainModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
          dataFiveYear: [],
          dataMonth: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      dataFiveYear: [],
      dataMonth: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      const dataDate = await this.infinity?.box;
      this.setState({
        averamt_tot: dataDate?.averamt_tot || '0',
        contamt_tot: dataDate?.contamt_tot || '0',
        iamt2_tot: dataDate?.iamt2_tot || '0',
        misamt2_tot: dataDate?.misamt2_tot || '0',
        qty_aver_tot: dataDate?.qty_aver_tot || '0',
        qty_cls_aver_tot: dataDate?.qty_cls_aver_tot || '0',
        qty_cls_tot: dataDate?.qty_cls_tot || '0',
        qty_fm_aver_tot: dataDate?.qty_fm_aver_tot || '0',
        qty_fm_tot: dataDate?.qty_fm_tot || '0',
        qty_long_aver_tot: dataDate?.qty_long_aver_tot || '0',
        qty_long_tot: dataDate?.qty_long_tot || '0',
        qty_new_aver_tot: dataDate?.qty_new_aver_tot || '0',
        qty_new_tot: dataDate?.qty_new_tot || '0',
        qty_pog_aver_tot: dataDate?.qty_pog_aver_tot || '0',
        qty_pog_tot: dataDate?.qty_pog_tot || '0',
        qty_tot: dataDate?.qty_tot || '0',
        rcvamt2_tot: dataDate?.rcvamt2_tot || '0',
        jamt_tot: dataDate?.jamt_tot || '0',
        averamt_aver_tot: dataDate?.averamt_aver_tot || '0',
      });

      if (this.state.data && this.state.data?.length > 0) {
        await this.grid.current?.setFocus(0);
        this.onRowFocusEvent(this.state.data[0], 0);
      }
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      year: this.state.year || '',
      spjangcd: this.state.focused?.spjangcd || '',
    });
  }

  @action
  onRowFocusEvent(item: MainModel, index: number) {
    this.setState({
      focusIndex: index,
      focused: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_list_RowFocuschanged`, {
        year: this.state.year || '',
        spjangcd: this.state.focused?.spjangcd || '',
      });

      switch (this.tabIndex) {
        case 0:
          this.setState({ dataFiveYear: data?.items || [] });
          break;

        case 1:
          this.setState({ dataMonth: data?.items || [] });
          break;
      }
      this.setState({
        tab_averamt_tot: data?.averamt_tot || '0',
        tab_contamt_tot: data?.contamt_tot || '0',
        tab_iamt2_tot: data?.iamt2_tot || '0',
        tab_misamt2_tot: data?.misamt2_tot || '0',
        tab_qty_aver_tot: data?.qty_aver_tot || '0',
        tab_qty_cls_aver_tot: data?.qty_cls_aver_tot || '0',
        tab_qty_cls_tot: data?.qty_cls_tot || '0',
        tab_qty_fm_aver_tot: data?.qty_fm_aver_tot || '0',
        tab_qty_fm_tot: data?.qty_fm_tot || '0',
        tab_qty_long_aver_tot: data?.qty_long_aver_tot || '0',
        tab_qty_long_tot: data?.qty_long_tot || '0',
        tab_qty_new_aver_tot: data?.qty_new_aver_tot || '0',
        tab_qty_new_tot: data?.qty_new_tot || '0',
        tab_qty_pog_aver_tot: data?.qty_pog_aver_tot || '0',
        tab_qty_pog_tot: data?.qty_pog_tot || '0',
        tab_qty_tot: data?.qty_tot || '0',
        tab_rcvamt2_tot: data?.rcvamt2_tot || '0',
        tab_jamt_tot: data?.jamt_tot || '0',
        tab_contamt12_tot: data?.contamt12_tot || '0',
      });
    });
  }

  @action
  async onRefreshEvent() {
    const { actionStore: api } = this.props;

    ConfirmWarning.show('확인', '새로고침시에 상당시간이 소요(약5분미만)됩니다!\n조금만 기다리셨다가 조회를 눌러주세요.');
    await api.exec(
      Category.GENERAL,
      'wb_refresh_lazytask',
      {
        year: this.state.year || '',
      },
    );
  }

  @action
  onRowFocusEvent2(item: any) {
    this.tabIndex === 0
      ? this.setState({ fiveYearFocused: item }) : this.setState({ monthFocused: item });
  }

  @action
  textModal(isOpen: boolean, item: string) {
    if (item === '1' || item === '2') {
      ConfirmWarning.show('확인', '상세보기는 월별 수주매출내역에서 확인해주세요');
      return;
    }
    this.setState({ textDetailModal: isOpen });
    isOpen === true && this.modalRetrive(item);
  }

  @action
  async modalItemChanged(rowUpdate: RowUpdate, x: ModalModel, value: any, name: string) {
    const { actionStore: api } = this.props;

    let data = {};
    if (name === 'notmis_okflag' || name === 'notmis_remark') {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          sub: 'w_popup_e101w_06',
          itemname: name,
          data: value,
          spjangcd: this.state.monthFocused?.spjangcd,
          mon: this.state.monthFocused?.mon,
          actcd: x.actcd,
          notmis_okflag: name === 'notmis_okflag' ? value : x.notmis_okflag,
          notmis_remark: name === 'notmis_remark' ? value : x.notmis_remark,
        },
      );
    }

    rowUpdate({
      [name]: value,
      ...data,
      notmis_pernm: '',
    });
  }

  @action
  async modalRetrive(item: string) {
    const { actionStore: api } = this.props;
    this.modalTab = item;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_e101w_06',
        tab: item,
        mon: this.state.monthFocused?.mon,
        spjangcd: this.state.monthFocused?.spjangcd,
      });

    await this.SS({
      modalData: data?.items || [],
      modal_contamt_tot: data?.contamt_tot || '0',
      modal_iamt2_tot: data?.iamt2_tot || '0',
      modal_jamt_tot: data?.jamt_tot || '0',
      modal_misamt2_tot: data?.misamt2_tot || '0',
      modal_notmisamt_tot: data?.notmisamt_tot || '0',
      modal_qty_tot: data?.qty_tot || '0',
      modal_rcvamt2_tot: data?.rcvamt2_tot || '0',
    });

    this.modalTable.current?.update(true);
    this.modalTable.current?.setFocus(0);
  }

  @action
  async popupUpdateRows(rows: Array<ModalModel>, updatedRows: Array<ModalModel>) {
    this.updatedRows = updatedRows;
    this.setState({ modalData: rows });
  }

  @action
  async popupRowFocusEvent(item: ModalModel) {
    this.setState({ modalFocused: item });
  }

  @action
  async onModalExcel() {
    const { actionStore: api } = this.props;

    if (this.state.modalData.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      sub: 'w_popup_e101w_06',
      tab: this.modalTab,
      mon: this.state.monthFocused?.mon,
      spjangcd: this.state.monthFocused?.spjangcd,
    });
  }

  @action
  async headerItemChanged(item: MainModel, key: string, value: string) {
    const { actionStore: api } = this.props;
    await api.fxExec('dw_1_itemchanged', {
      ...item,
      itemname: key,
      data: value,
      projno: item.spjangcd,
    });

    this.onRetrieveEvent();
  }

  @action
  onTabChange(_: TabModel, index: number) {
    this.tabIndex = index;
    if (this.state.focused?.spjangcd) {
      this.onRowFocusEvent(this.state.focused, this.state.focusIndex);
    }
  }

  render() {
    return <PaySalesTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...this.state.data,
          ...this.state.dataFiveYear,
          ...this.state.dataMonth,
          ...this.state.modalData,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
