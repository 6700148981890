import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { PatrolTemplate } from './Patrol.template';
import { InfinityRetrieve } from '../../../../models/common';
import { PatrolModel } from './Patrol.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface PatrolState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)

  data: PatrolModel;
  patrolList: Array<PatrolModel>;
  focused: PatrolModel;

  gubun: string | number; // 프린트 구분
  wkcds: Array<any>; // 업무구분 리스트

  selectGubunModal: boolean // 프린트 구분 팝업

  focusIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_e039_01
 * @category 순찰일지
 */
export class Patrol extends PageComponent<PageProps, PatrolState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  updatedRows?: Array<PatrolModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }
    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,

      data: new PatrolModel(),
      patrolList: [],
      wkcds: [],

      focusIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 문서코드
    const data = await api.dropdown('wf_dd_e021_kukcd02');
    if (!data) return;
    this.setState({
      wkcds: data.items,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          patrolList: [...this.state.patrolList, ...items],
        });
        await this.table.current?.update(false);
        next && next();
      },
      async () => {
        await this.SS({ patrolList: [] });
        await this.infinity?.retrieveAll();
        this.table.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      patrolList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['equpcd', 'perid'],
        [this.state.focused?.equpcd, this.state.focused?.perid], type, true) || 0;
      if (this.state.patrolList && this.state.patrolList.length > index) {
        await this.table.current?.setFocus(index, 2);
        this.onRowFocusEvent(this.state.patrolList[this.state.focusIndex], this.state.focusIndex);
      }
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new PatrolModel(data, true);

      this.setState({
        data: newModel,
        patrolList: [
          newModel,
          ...this.state.patrolList,
        ],
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.state.patrolList,
    }, this.state.data?.new === '1')) {
      this.updatedRows = [];
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `순찰일자: ${this.state.data?.indate}, 번호: ${this.state.data?.seq}`;
    await api.delete(text, this.state.data) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.patrolList.length > 1, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    this.setState({ selectGubunModal: true });
  }

  @action
  async onPrint() {
    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      gubun: this.state.gubun,
    });

    this.setState({ gubun: '1' });
  }


  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  onUpdatedRows(rows: Array<PatrolModel>, updatedRows: Array<PatrolModel>) {
    this.updatedRows = updatedRows;
    this.setState({ patrolList: rows });
  }

  @action
  onRowFocusEvent(item:any, index: number) {
    this.setState({
      data: item,
      focused: item,
      focusIndex: index,
    });
  }

  @action
  selectGubunModal(isOpen: boolean) {
    this.setState({ selectGubunModal: isOpen });
  }

  render() {
    return (
      <PatrolTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new PatrolModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
