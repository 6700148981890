import { TLHF } from '../../../../../constants';
import {
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  OptionBox,
  TextBox,
} from '../../../../../components';
import { ManageStatus } from '../ManageStatus';
import { SearchBinding } from '../../../../../models';
import { Date8 } from '../../../../../utils/time';

const Results = [
  { value: '0', remark: '미처리' },
  { value: '1', remark: '매입처리' },
  { value: '2', remark: '지급처리' },
];

/**
 * 화면 > 하단 탭 > 상세내역 > 테이블 - 설치비지급내역
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusTableHeaderScAmt: TLHF<any, ManageStatus> = (scope) => ([
  {
    id: 'seq',
    text: '순차',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'cltcd',
    text: '코드',
    color: 'var(--color-red)',
    width: 100,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.cltcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ cltcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_XCLIENT',
          {},
          true,
          (item) => {
            rowUpdate({
              cltcd: item.cd,
              cltnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'cltnm',
    text: '담당자명(거래처명)',
    width: 300,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.cltnm}
        onChange={(v) => rowUpdate({ cltnm: v })}
      />
    ),
  },
  {
    id: 'amt',
    text: '금액',
    width: 150,
    render: (x, rowUpdate) => (
      <FormatNumberTextBox
        textAlign="right"
        value={x.amt}
        onChange={(v) => rowUpdate({ amt: v })}
      />
    ),
  },
  {
    id: 'schdate',
    text: '지급예정일자',
    width: 120,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.schdate || Date8.make()}
        onChange={(v) => rowUpdate({ schdate: v })}
      />
    ),
  },
  {
    id: 'mijflag',
    text: '지급요청',
    width: 150,
    render: (x, rowUpdate) => (
      <OptionBox
        value={x.mijflag}
        data={[
          { value: '0', remark: '대기' },
          { value: '1', remark: '요청' },
        ]}
        onChange={(item) => rowUpdate({ mijflag: item.value })}
        readonly={x.resultflag !== '1'}
      />
    ),
  },
  {
    id: 'resultflag',
    text: '구분',
    width: 100,
    render: (x) => {
      const fp = Results.filter((y) => y.value === x.resultflag);
      const nm = fp.length > 0 ? fp[0].remark : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 200,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
