import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e080_list_02
 * @category 기결함
 */
export class CompleteBoxModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  readonly flag: string;

  /**
   * 상신일자
   *
   * **data type** varchar(8)
   */
  readonly repodate: string;

  /**
   * 문서명
   *
   * **data type** varchar(3)
   */
  readonly papercd: string;

  /**
   * 상신자
   *
   * **data type** varchar(10)
   */
  readonly repoperid: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 결재구분
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 사유
   *
   * **data type** varchar(0)
   */
  readonly remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 결재
   *
   * **data type** varchar(1)
   */
  readonly referflag: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   * 결재대기
   *
   * **data type** varchar(3)
   */
  readonly appgubun1: string;

  /**
   * 결재문서
   *
   * **data type** varchar(3)
   */
  readonly appgubun2: string;

  /**
   * 반려문서
   *
   * **data type** varchar(3)
   */
  readonly appgubun3: string;

  /**
   * 반려문서
   *
   * **data type** varchar(3)
   */
  readonly repopernm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.appnum = data.appnum || '';
    this.appperid = data.appperid || '';
    this.seq = data.seq || '';
    this.flag = data.flag || '';
    this.repodate = data.repodate || '';
    this.papercd = data.papercd || '';
    this.repoperid = data.repoperid || '';
    this.title = data.title || '';
    this.appgubun = data.appgubun || '';
    this.appdate = data.appdate || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.referflag = data.referflag || '';
    this.apptime = data.apptime || '';
    this.appgubun1 = data.appgubun1 || '';
    this.appgubun2 = data.appgubun2 || '';
    this.appgubun3 = data.appgubun3 || '';
    this.repopernm = data.repopernm || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
