import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_da052w_07
 * @category 개인목표계획등록
 */
export class PopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 매출구분
   *
   * **data type** char(5)
   */
  gubun: string;

  /**
   * No
   *
   * **data type** char(5)
   */
  seq: string;

  /**
   * 구분
   *
   * **data type** char(4)
   */
  contnm: string;

  /**
   * 현장코드
   *
   * **data type** char(5)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** char(50)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 청구주기
   *
   * **data type** char(4)
   */
  cnam: string;

  /**
   * 구분
   *
   * **data type** char(4)
   */
  flag: string;

  /**
   * 이전계약
   *
   * **data type** char(4)
   */
  contgubun: string;

  /**
   * 이전보수료
   *
   * **data type** number
   */
  besamt: string;

  /**
   * 이전보수(대당가)
   *
   * **data type** number
   */
  qtyamt: string;

  /**
   * 인상보수료
   *
   * **data type** number
   */
  samt: string;

  /**
   * 증가액
   *
   * **data type** number
   */
  chasamt: string;

  /**
   * 계획내용
   *
   * **data type** char(255)
   */
  remark: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  perid: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  mon: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  compflag: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  equpcd: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  equpnm: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  usemon: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  costdate: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  costnum: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  state_remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangnm = data.spjangnm || '';
    this.gubun = data.gubun || '';
    this.seq = data.seq || '';
    this.equpnm = data.equpnm || '';
    this.usemon = data.usemon || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.equpcd = data.equpcd || '';
    this.perid = data.perid || '';
    this.mon = data.mon || '';
    this.compflag = data.compflag || '';
    this.state_remark = data.state_remark || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.cnam = data.cnam || '';
    this.flag = data.flag || '';
    this.contgubun = data.contgubun || '';
    this.besamt = data.besamt || '';
    this.qtyamt = data.qtyamt || '';
    this.samt = data.samt || '';
    this.chasamt = data.chasamt || '';
    this.remark = data.remark || '';
    this.contnm = data.contnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
