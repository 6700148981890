import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  ReportDetailModel,
  ReportModel,
} from './models';
import {
  AskType,
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { ReportTemplate } from './Report.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

// import { ConfirmWarning } from '../../../../utils/confirm';

export enum ReportItemChangeTypes {
  ACTCD,
  CIVILCD,
  PERID,
}

export enum ReportItemChangeTypeNames {
  actcd,
  civilcd,
  perid,
}

interface ReportState {

  // 검색조건
  stdate: string; // 기간
  enddate: string; // 기간
  searchQuery: string; // 검색어
  perid: string; // 사용자 아이디
  pernm: string;
  state: string; // 결과

  // 데이터 객체

  // 포커싱 데이터
  focusedReport?: ReportModel;
  focusedDetailReport: ReportDetailModel;
  lastSaveData: ReportDetailModel;

  // 하단 테이블 데이터
  data: ReportDetailModel;

  // 테이블 데이터 리스트
  ReportList: Array<ReportModel>;

  // 구분종류
  wkcds: Array<any>;

  // 모달
  filePhotoModal: boolean; // 사진
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부
}

/*
* 컨트롤러
* @window w_tb_e311
* @category 민원처리보고서
*/

export class Report extends PageComponent<PageProps, ReportState>
  implements PageToolEvents {
  updatedRows?: Array<ReportModel>;

  updatedRows2?: Array<ReportModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: pageParams.stdate || `${year}${month}01`,
      enddate: pageParams.enddate || Today.date(),
      searchQuery: '',
      perid: pageParams.perid || '',
      pernm: '',
      state: '%',
      data: new ReportDetailModel(),
      ReportList: [],
      focusedDetailReport: {},

      wkcds: [],
      isReported: false,
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        stdate: pageParams.stdate || this.state.stdate,
        enddate: pageParams.enddate || this.state.enddate,
        perid: pageParams.perid || this.state.perid,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data.items });

    await this.onRetrieveEvent(RetrieveFocusType.DEFAULT, true);
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = false) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        state: this.state.state,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          ReportList: [...this.state.ReportList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          ReportList: [],
        });
        await this.infinity?.retrieveAll();
        await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.focusedDetailReport;
    this.setState({
      data: new ReportDetailModel([], false),
      focusedDetailReport: new ReportDetailModel([], false),
      ReportList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['recedate', 'recenum'],
        [lastSelected?.recedate, lastSelected?.recenum], type, true) || 0;
      if (!autoLoad) return;
      if (this.state.ReportList && this.state.ReportList.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: ReportDetailModel) {
    if (item?.new === '1') {
      this.setState({
        isReported: false,
        focusedDetailReport: this.state.lastSaveData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      { focusedDetailReport: item },
      async () => {
        const data = await api.exec(Category.MAINTENANCE, 'dw_1_RowFocuschanged',
          {
            recedate: this.state.focusedDetailReport?.recedate,
            recenum: this.state.focusedDetailReport?.recenum,
          });
        await data && this.setState({
          focusedDetailReport: new ReportDetailModel(data),
        });

        // 결재상신 여부확인
        if (this.state.focusedDetailReport?.appgubun === null
          || this.state.focusedDetailReport?.appgubun === ''
          || this.state.focusedDetailReport?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  // 결재상신
  @action
  async onReport() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (user.perid !== this.state.focusedDetailReport?.inperid) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다.');
      return;
    }

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    if (appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.COMPLAIN)) {
      // 결재상신
      const data = await api.exec(Category.MAINTENANCE, 'wb_appreport', {
        papercd: PAPERCD.COMPLAIN.toString(),
        appnum: this.state.focusedDetailReport.appnum,
        appgubun: this.state.focusedDetailReport.appgubun,
        recedate: this.state.focusedDetailReport.recedate,
        recenum: this.state.focusedDetailReport.recenum,
        perid: this.state.focusedDetailReport.perid,
        actnm: this.state.focusedDetailReport.actnm,
        refer: this.state.focusedDetailReport.refer,
        appflag,
      });
      data && this.onRetrieveEvent();
    } if (appflag === 'cancel') {
      const data = await api.exec(Category.MAINTENANCE, 'wb_appreport', {
        papercd: PAPERCD.COMPLAIN.toString(),
        appnum: this.state.focusedDetailReport.appnum,
        appgubun: this.state.focusedDetailReport.appgubun,
        recedate: this.state.focusedDetailReport.recedate,
        recenum: this.state.focusedDetailReport.recenum,
        perid: this.state.focusedDetailReport.perid,
        actnm: this.state.focusedDetailReport.actnm,
        refer: this.state.focusedDetailReport.refer,
        appflag,
      });
      data && this.onRetrieveEvent();
    }
  }

  @action
  async openReferSelector() {
    if (!this.state.focusedDetailReport?.appgubun || !this.state.focusedDetailReport?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.ELECCONTRACT, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedDetailReport!);
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    let data:any = {
      new: undefined,
      items: [],
    };

    const params = {
      itemname: ReportItemChangeTypeNames[type],
      data: item,
      recedate: this.state.focusedDetailReport?.recedate,
      recenum: this.state.focusedDetailReport?.recenum,
      new: this.state.data.new,
    };

    if (type === ReportItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailReport: new ReportDetailModel({
          ...this.state.focusedDetailReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === ReportItemChangeTypeNames.civilcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailReport: new ReportDetailModel({
          ...this.state.focusedDetailReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === ReportItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailReport: new ReportDetailModel({
          ...this.state.focusedDetailReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.focusedDetailReport?.new,
      custcd: this.state.focusedDetailReport?.custcd,
      spjangcd: this.state.focusedDetailReport?.spjangcd,
      recedate: this.state.focusedDetailReport?.recedate,
      recenum: this.state.focusedDetailReport?.recenum,
      recetime: this.state.focusedDetailReport?.recetime,
      compdate: this.state.focusedDetailReport?.compdate,
      sttime: this.state.focusedDetailReport?.sttime,
      endtime: this.state.focusedDetailReport?.endtime,
      actcd: this.state.focusedDetailReport?.actcd,
      actnm: this.state.focusedDetailReport?.actnm,
      acttelnum: this.state.focusedDetailReport?.acttelnum,
      actperid: this.state.focusedDetailReport?.actperid,
      actpernm: this.state.focusedDetailReport?.actpernm,
      divicd: this.state.focusedDetailReport?.divicd,
      divinm: this.state.focusedDetailReport?.divinm,
      perid: this.state.focusedDetailReport?.perid,
      pernm: this.state.focusedDetailReport?.pernm,
      reperid: this.state.focusedDetailReport?.reperid,
      repernm: this.state.focusedDetailReport?.repernm,
      civilcd: this.state.focusedDetailReport?.civilcd,
      civilnm: this.state.focusedDetailReport?.civilnm,
      civilflag: this.state.focusedDetailReport?.civilflag,
      okflag: this.state.focusedDetailReport?.okflag,
      resultcd: this.state.focusedDetailReport?.resultcd,
      resultnm: this.state.focusedDetailReport?.resultnm,
      remark: this.state.focusedDetailReport?.remark,
      compremark: this.state.focusedDetailReport?.compremark,
      bigo: this.state.focusedDetailReport?.bigo,
      appdate: this.state.focusedDetailReport?.appdate,
      apptime: this.state.focusedDetailReport?.apptime,
      appgubun: this.state.focusedDetailReport?.appgubun,
      appperid: this.state.focusedDetailReport?.appperid,
      appnum: this.state.focusedDetailReport?.appnum,
      inperid: this.state.focusedDetailReport?.inperid,
      indate: this.state.focusedDetailReport?.indate,
      rspnm: this.state.focusedDetailReport?.rspnm,
      appremark: this.state.focusedDetailReport?.appremark,
      picflag: this.state.focusedDetailReport?.picflag,
      refer: this.state.focusedDetailReport?.refer,
      attcnt: this.state.focusedDetailReport?.attcnt,
      attcnt2: this.state.focusedDetailReport?.attcnt2,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.focusedDetailReport.compdate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        focusedDetailReport: new ReportDetailModel(),
      });
      await this.onRetrieveEvent();
    }
  }


  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new ReportModel(data, true);
      const newModel2 = new ReportDetailModel(data, true);

      this.setState({
        // @ts-ignore
        data: newModel,
        lastSaveData: newModel2,
        ReportList: [
          newModel,
          ...this.state.ReportList,
        ],
        focusedReport: newModel,
        focusedDetailReport: newModel2,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onClickSMS() {
    if (!ConfirmWarning.assert(this.state?.focusedDetailReport, '확인', '문자전송할 고장내용이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.focusedDetailReport?.civilcd, '확인', '민원내용을 입력하세요!')) {
      return;
    }

    const result = await Confirm.ask(
      '문자 전송',
      `${this.state.focusedDetailReport?.acttelnum} 번호로 조치내용을 전송하시겠습니까?`,
      '보내기',
      '취소',
    );

    if (result === AskType.YES) {
      const { actionStore: api } = this.props;
      ConfirmSuccess.showPbMessage(await api.fxExec('wb_sms', {
        recedate: this.state.focusedDetailReport?.recedate,
        recenum: this.state.focusedDetailReport?.recenum,
        perid: this.state.focusedDetailReport?.perid,
        actcd: this.state.focusedDetailReport?.actcd,
        actnm: this.state.focusedDetailReport?.actnm,
        civilcd: this.state.focusedDetailReport?.civilcd,
        civilnm: this.state.focusedDetailReport?.civilnm,
        recetime: this.state.focusedDetailReport?.recetime,
      }));
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.ReportList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      recedate: this.state.focusedDetailReport?.recedate,
      recenum: this.state.focusedDetailReport?.recenum,
      attcnt: this.state.focusedDetailReport?.attcnt,
    });
  }

  @action
  photoModal(isOpen: boolean) {
    this.setState({
      filePhotoModal: isOpen,
    }, () => {
      if (!isOpen) { this.onRowFocusEvent(this.state.focusedDetailReport!); }
    });
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedDetailReport!); }
      });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `접수일자: ${this.state.focusedDetailReport?.recedate} 번호: ${this.state.focusedDetailReport?.recenum}를 삭제하시겠습니까?`;
    await api.delete(text, {
      recedate: this.state.focusedDetailReport?.recedate,
      recenum: this.state.focusedDetailReport?.recenum,
    }) && await this.onRetrieveEvent();
  }

  @action
  async onOkFlagEvent() {
    const result = await Confirm.ask(
      '문자 전송',
      `${this.state.focusedDetailReport?.acttelnum} 번호로 조치내용을 전송하시겠습니까?`,
      '보내기',
      '취소',
    );
    if (result === AskType.YES) {
      const { actionStore: api } = this.props;
      ConfirmSuccess.showPbMessage(await api.fxExec('wb_sms', {
        recedate: this.state.focusedDetailReport?.recedate,
        recenum: this.state.focusedDetailReport?.recenum,
        perid: this.state.focusedDetailReport?.perid,
        actcd: this.state.focusedDetailReport?.actcd,
        actnm: this.state.focusedDetailReport?.actnm,
        civilcd: this.state.focusedDetailReport?.civilcd,
        civilnm: this.state.focusedDetailReport?.civilnm,
        recetime: this.state.focusedDetailReport?.recetime,
      }));
    }
  }


  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<ReportModel>, updatedRows: Array<ReportModel>) {
    this.updatedRows = updatedRows;
    this.setState({ ReportList: rows });
  }

  @action
  onUpdatedRows2(rows: Array<ReportModel>, updatedRows: Array<ReportModel>) {
    this.updatedRows2 = updatedRows;
    this.setState({ ReportList: rows });
  }

  render() {
    return (
      <ReportTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focusedDetailReport: {
              ...this.state.focusedDetailReport,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
