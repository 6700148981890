import * as React from 'react';
import {
  ComboBox, ComboBoxModel, FlexLayout, SearchLayout, TabLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Status } from './Status';
import { StatusTab } from './Status.tab';
import { SearchDateRange } from '../../../../components/search';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export const StatusTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 공사명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
          />

          <TextBox
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>제어반</span>
          <ComboBox
            value={scope.state.jeoban}
            style={{
              padding: '0 0 0 6px', fontSize: 12,
            }}
            data={[
              { value: '%', remark: '전체' },
              { value: 'MR', remark: 'MR' },
              { value: 'MRL', remark: 'MRL' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ jeoban: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={150}>
          <span>인승</span>
          <TextBox
            textAlign="center"
            value={scope.state.people}
            onChange={(v) => scope.setState({ people: v })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={150}>
          <span>층수</span>
          <TextBox
            textAlign="center"
            value={scope.state.stage}
            onChange={(v) => scope.setState({ stage: v })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>상태</span>
          <ComboBox
            value={scope.state.state}
            style={{
              padding: '0 0 0 6px', fontSize: 12,
            }}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '견적' },
              { value: '1', remark: '계약' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ state: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={StatusTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
