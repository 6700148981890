import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { CustomerRegisterTemplate } from './CustomerRegister.template';
import { CustomerRegisterModel } from './CustomerRegister.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  ConfirmDelete,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import { Update } from '../../../../utils/array';

export interface CustomerRegisterListItem {
  regflag: string;
  actmail: string;
  actcd: string;
}

export interface CustomerRegisterDetailListItem {
  new: string;
  actcd: string;
  seq: string;
  tel: string;
  telcd: string;
  remark: string;
  keynum: string;
}

interface CustomerRegisterState {
  searchQuery: string;
  telcd: string;
  groupcd: string;
  telcds?: Array<any>;
  telcds2?: Array<any>;
  customerRegisterList: Array<CustomerRegisterModel>;
  customerRegisterDetailList: Array<CustomerRegisterModel>;
  focusedCustomerRegister: CustomerRegisterModel;
  data: CustomerRegisterModel;
  focusedData: CustomerRegisterModel;
  lastSaveData: CustomerRegisterModel;
  focused: CustomerRegisterModel;
}

/**
 * 컨트롤러
 * @window w_tb_e911
 * @category 고객등록
 */
export class CustomerRegister extends PageComponent<PageProps, CustomerRegisterState>
  implements PageToolEvents {
  updatedRows?: Array<CustomerRegisterModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  focusedRegisterIndex: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    this.focusedRegisterIndex = 0;
    this.state = props.state || {
      customerRegisterList: [],
      customerRegisterDetailList: [],
      data: new CustomerRegisterModel({ telcd: 'x' }),
      focusedDAta: new CustomerRegisterModel({}),
      searchQuery: pageParams?.searchQuery || '',
      newCheck: '0',
      telcd: '%',
      groupcd: '%',
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams?.searchQuery || this.state.searchQuery,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 전화분류
    const datas = await api.dropdown('wf_dd_e902_02');

    if (!datas) return;
    this.setState({ telcds: datas.items });

    // 분류
    const datas2 = await api.dropdown('wf_dd_e902_01', { telcd: this.state.data.telcd });

    if (!datas2) return;
    this.setState({ telcds2: datas2.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    if (!this.state.data.groupcd) {
      this.state.data.groupcd = '%';
    }
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        groupcd: this.state.groupcd,
        telcd: this.state.telcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          customerRegisterList: [...this.state.customerRegisterList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          customerRegisterList: [],
          data: new CustomerRegisterModel({}, false),
          focusedData: new CustomerRegisterModel({}, false),
        });

        const index = await this.infinity?.retrieveTo('actcd', this.state.focusedCustomerRegister?.actcd, type, true) || 0;
        this.state.customerRegisterList.length > index && this.grid.current?.setFocus(index);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const last = this.state.data;
    this.setState({
      customerRegisterList: [],
      data: new CustomerRegisterModel({}, false),
      focusedData: new CustomerRegisterModel({}, false),
    });

    if (!autoLoad) return;

    const index = await this.infinity?.retrieveTo(['regflag', 'actcd'],
      [last?.regflag, last?.actcd], type, true) || 0;
    this.state.customerRegisterList && this.state.customerRegisterList.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    // 검색어 초기화
    // @deprecated 2024-09-10 저장할때 처리
    // if (this.state.searchQuery !== '') {
    //   await this.SS({
    //     searchQuery: '',
    //   });
    // }

    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const data = new CustomerRegisterModel(await api.new(), true);

    if (data) {
      data && await this.SS({
        customerRegisterList: [...this.state.customerRegisterList, data],
        data,
        focusedData: data,
        customerRegisterDetailList: [],
        lastSaveData: data,
        focusedCustomerRegister: data,
      });
      this.table.current?.update(true);
    }

    // await this.table.current?.update();
    this.grid.current?.setFocus(this.state.customerRegisterList.length - 1);
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    const telHyphenChangeItems: any = [];

    // 저장 시 tel에 하이픈 문자까지 넣어서 items에 넘겨주기 위한 함수
    this.state.customerRegisterDetailList.forEach((x) => {
      telHyphenChangeItems.push({
        ...x,
        tel: x.tel.replaceAll('-', ''),
      });
    });

    telHyphenChangeItems.forEach((x: CustomerRegisterModel) => {
      // 지역번호 02인경우
      if (x.tel.substring(0, 2).includes('02')) {
        // ex) 02-123-1234
        if (x.tel.length < 10) {
          // eslint-disable-next-line no-param-reassign
          x.tel = `${x.tel.substring(0, 2)}-${x.tel.substring(2, 5)}-${x.tel.substring(5)}`;
        } else {
          // ex) 02-1234-1234
          // eslint-disable-next-line no-param-reassign
          x.tel = `${x.tel.substring(0, 2)}-${x.tel.substring(2, 6)}-${x.tel.substring(6)}`;
        }
      }

      // 지역번호 02가 아닌경우
      if (!x.tel.substring(0, 2).includes('02')) {
        // ex) 010-123-1234, 062-123-1234
        if (x.tel.length < 11) {
          // eslint-disable-next-line no-param-reassign
          x.tel = `${x.tel.substring(0, 3)}-${x.tel.substring(3, 6)}-${x.tel.substring(6)}`;
        } else {
          // ex) 010-1234-1234, 062-1234-1234
          // eslint-disable-next-line no-param-reassign
          x.tel = `${x.tel.substring(0, 3)}-${x.tel.substring(3, 7)}-${x.tel.substring(7)}`;
        }
      }
    });

    if (await api.save({
      regflag: this.state.focusedData.regflag,
      actcd: this.state.focusedData.actcd,
      cltcd: this.state.focusedData.cltcd,
      actmail: this.state.focusedData.actmail,
      nicknm: this.state.focusedData.nicknm,
      divinm: this.state.focusedData.divinm,
      rspnm: this.state.focusedData.rspnm,
      perid: this.state.focusedData.perid,
      fax: this.state.focusedData.fax,
      zipcd: this.state.focusedData.zipcd,
      oldaddress: this.state.focusedData.oldaddress,
      address2: this.state.focusedData.address2,
      email: this.state.focusedData.email,
      memail: this.state.focusedData.memail,
      remark: this.state.focusedData.remark,
      birthday: this.state.focusedData.birthday,
      homepage: this.state.focusedData.homepage,
      items: telHyphenChangeItems,
    }, this.state.data.isNew)) {
      if (this.state.data.isNew) {
        await this.SS({
          searchQuery: '',
        });
      }
      this.state.data.isChanged = 'false';
      this.table.current?.update(true);
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `고객번호: ${this.state.focusedCustomerRegister?.actcd
    } 고객명: ${this.state.focusedCustomerRegister?.actmail}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedCustomerRegister) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onRowFocusEvent(item: CustomerRegisterModel, index: number) {
    const { actionStore: api } = this.props;
    this.focusedRegisterIndex = index;

    if (item.isNew) {
      await this.onItemChanged();
      return;
    }

    if (this.state.focusedData.isNew && !item.isNew) {
      await this.SS({
        customerRegisterList: this.state.customerRegisterList.filter((x) => x.actcd !== this.state.focusedData.actcd),
      });
      this.grid.current?.forceRepaint(true);
    }

    this.SS({
      data: new CustomerRegisterModel(item, item?.isNew),
    });
    const data = await api.exec(
      Category.GENERAL,
      'dw_1_RowFocuschanged',
      item,
    );
    data && this.setState({
      customerRegisterDetailList: data?.items || [],
      focusedData: new CustomerRegisterModel(data),
      focusedCustomerRegister: new CustomerRegisterModel(data),
    });
    await this.table.current?.update(true);
    if (data.items?.length > 0) {
      this.table.current?.setFocus(0, 1);
    }

    // 뭔지 모르겠음
    // if (item.isNew && !this.state.data.cltcd && this.state.lastSaveData) {
    //   this.setState({
    //     data: new CustomerRegisterModel(this.state.lastSaveData, true),
    //   });
    // }
  }

  @action
  async onDetailRowFocusEvent(item: CustomerRegisterModel) {
    this.setState({
      focused: item,
    });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    this.state.data.isChanged = 'true';

    const data = await api.fxExec(
      'dw_2_new',
      {
        actcd: this.state.data.actcd,
      },
    );

    if (data) {
      // const newData: CustomerRegisterModel = {
      //   new: '1',
      //   actcd: data.actcd,
      //   seq: data.seq,
      //   tel: data.tel,
      //   telcd: data.telcd,
      //   remark: data.remark,
      //   keynum: data.keynum,
      // };
      this.setState({
        focused: new CustomerRegisterModel(data, true),
        customerRegisterDetailList: [...this.state.customerRegisterDetailList, new CustomerRegisterModel(data, true)],
      }, async () => {
        await this.table.current?.update(true);
        this.table.current?.setFocus(this.state.customerRegisterDetailList.length - 1, 1);
      });
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focused;

    if (focusedItem) {
      const text = `순번: ${this.state.focused?.seq}, 전화번호: ${this.state.focused?.tel}`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            actcd: this.state.data.actcd,
            seq: this.state.focused?.seq,
          },
        );
        await this.SS({ searchQuery: this.state.data.actcd });
        this.onRetrieveEvent();
        // eslint-disable-next-line no-template-curly-in-string
        ConfirmSuccess.show('삭제', `${focusedItem.seq}를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ customerRegisterDetailList: rows });
  }

  @action
  async onItemChanged() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_1_itemchanged',
      {
        itemname: 'regflag',
        data: this.state.focusedData.regflag,
        new: this.state.data.new,
      },
    );
    await this.SS({
      focusedData: new CustomerRegisterModel({
        ...this.state.data,
        actcd: data.actcd,
        regflag: this.state.focusedData.regflag,
      }, this.state.focusedData.isNew),
    });

    await Update.byIndex(this, 'customerRegisterList', this.focusedRegisterIndex, 'actcd', this.state.focusedData.actcd);
    await Update.byIndex(this, 'customerRegisterList', this.focusedRegisterIndex, 'regflag', this.state.focusedData.regflag);
    this.grid.current?.forceRepaint();
  }

  @action
  async updateActmailFocusedRegisterRow(actmail: string) {
    await Update.byIndex(this, 'customerRegisterList', this.focusedRegisterIndex, 'actmail', actmail);
    this.grid.current?.forceRepaint();
  }

  render() {
    return (
      <CustomerRegisterTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focusedData: new CustomerRegisterModel({
              ...this.state.focusedData,
              ...change,
            }, this.state.focusedData?.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
