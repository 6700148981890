import * as React from 'react';
import {
  FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentTabMaterialsTableHeader } from '../headers/EnrollmentTabMaterialsTableHeader';

/**
 * 화면 > 탭 - 부품내역등록
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTabMaterialsTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.tableMaterials}
      header={EnrollmentTabMaterialsTableHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataMaterials}
      onChange={(rows, updatedRows) => scope.onChangeTableMaterials(rows, updatedRows)}
      onRowFocusEvent={(row, index) => scope.onRowFocusMaterials(row, index)}
    />
  </FlexLayout>
);
