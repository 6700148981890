import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { ManageStatus } from '../ManageStatus';
import { ManageStatusGridHeaderMemo } from '../headers';

/**
 * 화면 > 탭 - 하단 > 상세내역
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusDetailTabMemoTemplate: React.FC<TemplateProps<ManageStatus>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <GridLayout
        header={ManageStatusGridHeaderMemo(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataHeadTabGridMemo}
      />
    </FlexLayout>
  );
};
