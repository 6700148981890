import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da052w_05
 * @category 월평균보수료
 */
export class MonthAverageModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 01월 유지보수료
   *
   * **data type** number
   */
  monamt01: string;

  /**
   * 02월 유지보수료
   *
   * **data type** number
   */
  monamt02: string;

  /**
   * 03월 유지보수료
   *
   * **data type** number
   */
  monamt03: string;

  /**
   * 04월 유지보수료
   *
   * **data type** number
   */
  monamt04: string;

  /**
   * 05월 유지보수료
   *
   * **data type** number
   */
  monamt05: string;

  /**
   * 06월 유지보수료
   *
   * **data type** number
   */
  monamt06: string;

  /**
   * 07월 유지보수료
   *
   * **data type** number
   */
  monamt07: string;

  /**
   * 08월 유지보수료
   *
   * **data type** number
   */
  monamt08: string;

  /**
   * 09월 유지보수료
   *
   * **data type** number
   */
  monamt09: string;

  /**
   * 10월 유지보수료
   *
   * **data type** number
   */
  monamt10: string;

  /**
   * 11월 유지보수료
   *
   * **data type** number
   */
  monamt11: string;

  /**
   * 12월 유지보수료
   *
   * **data type** number
   */
  monamt12: string;

  /**
   * 01월 평균보수료
   *
   * **data type** number
   */
  averamt01: string;

  /**
   * 02월 평균보수료
   *
   * **data type** number
   */
  averamt02: string;

  /**
   * 03월 평균보수료
   *
   * **data type** number
   */
  averamt03: string;

  /**
   * 04월 평균보수료
   *
   * **data type** number
   */
  averamt04: string;

  /**
   * 05월 평균보수료
   *
   * **data type** number
   */
  averamt05: string;

  /**
   * 06월 평균보수료
   *
   * **data type** number
   */
  averamt06: string;

  /**
   * 07월 평균보수료
   *
   * **data type** number
   */
  averamt07: string;

  /**
   * 08월 평균보수료
   *
   * **data type** number
   */
  averamt08: string;

  /**
   * 09월 평균보수료
   *
   * **data type** number
   */
  averamt09: string;

  /**
   * 10월 평균보수료
   *
   * **data type** number
   */
  averamt10: string;

  /**
   * 11월 평균보수료
   *
   * **data type** number
   */
  averamt11: string;

  /**
   * 12월 평균보수료
   *
   * **data type** number
   */
  averamt12: string;

  /**
   * 01월 관리대수
   *
   * **data type** number
   */
  mon01: string;

  /**
   * 02월 관리대수
   *
   * **data type** number
   */
  mon02: string;

  /**
   * 03월 관리대수
   *
   * **data type** number
   */
  mon03: string;

  /**
   * 04월 관리대수
   *
   * **data type** number
   */
  mon04: string;

  /**
   * 05월 관리대수
   *
   * **data type** number
   */
  mon05: string;

  /**
   * 06월 관리대수
   *
   * **data type** number
   */
  mon06: string;

  /**
   * 07월 관리대수
   *
   * **data type** number
   */
  mon07: string;

  /**
   * 08월 관리대수
   *
   * **data type** number
   */
  mon08: string;

  /**
   * 09월 관리대수
   *
   * **data type** number
   */
  mon09: string;

  /**
   * 10월 관리대수
   *
   * **data type** number
   */
  mon10: string;

  /**
   * 11월 관리대수
   *
   * **data type** number
   */
  mon11: string;

  /**
   * 12월 관리대수
   *
   * **data type** number
   */
  mon12: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.monamt01 = data.monamt01 || '';
    this.monamt02 = data.monamt02 || '';
    this.monamt03 = data.monamt03 || '';
    this.monamt04 = data.monamt04 || '';
    this.monamt05 = data.monamt05 || '';
    this.monamt06 = data.monamt06 || '';
    this.monamt07 = data.monamt07 || '';
    this.monamt08 = data.monamt08 || '';
    this.monamt09 = data.monamt09 || '';
    this.monamt10 = data.monamt10 || '';
    this.monamt11 = data.monamt11 || '';
    this.monamt12 = data.monamt12 || '';
    this.averamt01 = data.averamt01 || '';
    this.averamt02 = data.averamt02 || '';
    this.averamt03 = data.averamt03 || '';
    this.averamt04 = data.averamt04 || '';
    this.averamt05 = data.averamt05 || '';
    this.averamt06 = data.averamt06 || '';
    this.averamt07 = data.averamt07 || '';
    this.averamt08 = data.averamt08 || '';
    this.averamt09 = data.averamt09 || '';
    this.averamt10 = data.averamt10 || '';
    this.averamt11 = data.averamt11 || '';
    this.averamt12 = data.averamt12 || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
