import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { BreakSituationManyModel } from '../models';
import { BreakSituation } from '../BreakSituation';
import { CircleBadge, FormatTextBox, TextBox } from '../../../../../components';

// 다발고장별
const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];
const Many: GridLayoutHeader<BreakSituationManyModel, BreakSituation>[] = [
  {
    id: 'recedate',
    text: '접수일자',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
       총계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.recedate}
      />
    ),
  },
  {
    id: 'recetime',
    text: '접수시간',
    width: 80,
    render: (x) => (
      <FormatTextBox
        format="##:##"
        textAlign="center"
        value={x.recetime}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 170,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.rececnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.compcnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.equpnm}
        <CircleBadge>
          {Number(x.wadcnt) > 1 && <span
            style={{ backgroundColor: 'var(--color-red)' }}
          >
            {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
          </span>}
          {Number(x.lastcnt) > 1 && <span
            style={{ backgroundColor: 'var(--color-blue)' }}
          >
            {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
          </span>}
        </CircleBadge>
      </FlexLayout>
    ),
  },
  {
    id: 'repernm',
    text: '접수자',
    width: 80,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.notcnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'actpernm',
    text: '담당자',
    width: 80,
  },
  {
    id: 'recepernm',
    text: '배정자',
    width: 80,
  },
  {
    id: 'seqnm',
    text: '처리',
    width: 60,
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        접수
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '처리자',
    width: 80,
  },
  {
    id: 'pernm2',
    text: '처리자2',
    width: 80,
  },
  {
    id: 'compdate',
    text: '완료일자',
    width: 100,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.compdate}
      />
    ),
  },
  {
    id: 'comptime',
    text: '완료시간',
    width: 80,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.comptime}
      />
    ),
  },
  {
    id: 'arrivdate',
    text: '도착일자',
    width: 100,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.arrivdate}
      />
    ),
  },
  {
    id: 'arrivtime',
    text: '도착시간',
    width: 80,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.arrivtime}
      />
    ),
  },
  {
    id: 'contnm',
    text: '고장내용',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.contnm}
      </FlexLayout>
    ),
  },
  {
    id: 'contremark',
    text: '고장내용상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.contremark}
      </FlexLayout>
    ),
  },
  {
    id: 'resunm',
    text: '처리내용',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.resunm}
      </FlexLayout>
    ),
  },
  {
    id: 'resuremark',
    text: '처리내용상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.resuremark}
      </FlexLayout>
    ),
  },
  {
    id: 'remonm',
    text: '고장요인',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remonm}
      </FlexLayout>
    ),
  },
  {
    id: 'remoremark',
    text: '고장원인상세',
    width: 200,

    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remoremark}
      </FlexLayout>
    ),
  },
  {
    id: 'facnm',
    text: '고장원인',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.facnm}
      </FlexLayout>
    ),
  },
  {
    id: 'greginm',
    text: '고장부위',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.greginm}
      </FlexLayout>
    ),
  },
  {
    id: 'reginm',
    text: '고장부위상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.reginm}
      </FlexLayout>
    ),
  },
  {
    id: 'resultnm',
    text: '처리방법',
    width: 100,
  },
  {
    id: 'resutime',
    text: '대응(분)',
    width: 80,
    render: (x) => (
      <TextBox
        textAlign="right"
        value={x.resutime}
      />
    ),
  },
  {
    id: 'resulttime',
    text: '처리(분)',
    width: 80,
    render: (x) => (
      <TextBox
        textAlign="right"
        value={x.resulttime}
      />
    ),
  },
  {
    id: 'stoptime',
    text: '비운행(분)',
    width: 80,
    render: (x) => (
      <TextBox
        textAlign="right"
        value={x.resulttime}
      />
    ),
  },
  {
    id: 'reyyyymm',
    text: '설치년도',
    width: 100,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.reyyyymm}
      />
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
];

export default Many;
