import {
  FaCodeBranch, FaDashcube, FaDeviantart, FaDiaspora, FaFontAwesomeFlag, FaGgCircle, FaHouzz, FaMaxcdn,
} from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Contract } from './Contract';
import { TabModel } from '../../../../models';
import { ContractTabDefaultTemplate } from './tabs/ContractTabDefault.template';
import { ContractTabAgreementTemplate } from './tabs/ContractTabAgreement.template';
import { ContractTabSpecialTemplate } from './tabs/ContractTabSpecial.template';
import { ContractTabBreakTemplate } from './tabs/ContractTabBreak.template';
import { ContractTabDiscardTemplate } from './tabs/ContractTabDiscard.template';
import { ContractTabCashTemplate } from './tabs/ContractTabCash.template';
import { ContractTabSafeTemplate } from './tabs/ContractTabSafe.template';
import { ContractTabResponsibilityTemplate } from './tabs/ContractTabResponsibility.template';

/**
 * 화면 > 탭
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export const ContractTab: Array<TabLayoutDataItem<Contract>> = [
  {
    tab: new TabModel('DEFAULT', FaDeviantart, '하도급계약서[기본]'),
    template: (scope) => <ContractTabDefaultTemplate scope={scope} />,
  },
  {
    tab: new TabModel('AGREEMENT', FaDiaspora, '특약조건'),
    template: (scope) => <ContractTabAgreementTemplate scope={scope} />,
  },
  {
    tab: new TabModel('SPECIAL', FaDashcube, '특수조건'),
    template: (scope) => <ContractTabSpecialTemplate scope={scope} />,
  },
  {
    tab: new TabModel('RESPONSIBILITY', FaGgCircle, '책임각서'),
    template: (scope) => <ContractTabResponsibilityTemplate scope={scope} />,
  },
  {
    tab: new TabModel('BREAK', FaHouzz, '공사타절합의서'),
    template: (scope) => <ContractTabBreakTemplate scope={scope} />,
  },
  {
    tab: new TabModel('DISCARD', FaFontAwesomeFlag, '공사포기각서'),
    template: (scope) => <ContractTabDiscardTemplate scope={scope} />,
  },
  {
    tab: new TabModel('CASH', FaMaxcdn, '직불동의서'),
    template: (scope) => <ContractTabCashTemplate scope={scope} />,
  },
  {
    tab: new TabModel('SAFE', FaCodeBranch, '노무안전관리서약서'),
    template: (scope) => <ContractTabSafeTemplate scope={scope} />,
  },
];
