import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { OptionBox, SubHeader } from '../../components';

interface ModalKSProps {
  modalStore: ModalStore;
}

@observer
export class ModalKS extends React.Component<ModalKSProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={580}
        height={145}
        isVisible={modalStore.isVisibleKS}
        onClose={() => modalStore.closeKS()}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>작업선택</LayoutTitle>
          <FlexLayout>
            <OptionBox
              value={modalStore.modalKSAnswer}
              data={[
                { value: 0, remark: '기업신용평가 개선컨설팅' },
                { value: 1, remark: '산업보건안전교육' },
                { value: 2, remark: 'KS계약상담신청하기' },
              ]}
              onChange={(item) => {
                modalStore.modalKSAnswer = Number(item.value);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => modalStore.closeKS(true)}>확인</Button>
              <Button onClick={() => modalStore.closeKS()}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
