import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { DisbursementTemplate } from './Disbursement.template';
import { DisbursementModel } from './Disbursement.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface DisbursementState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;
  useyn?: string;
  appgubun?: string;

  // data
  data: Array<DisbursementModel>;
  focused?: DisbursementModel;
  pushTotalCheck: boolean;

  // trail
  prtcnt_tot: string;
  notcnt_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_ae200w_01
 * @category 지출결의서일괄출력
 */
export class Disbursement extends PageComponent<PageProps, DisbursementState>
  implements PageToolEvents {
  updatedRows?: Array<DisbursementModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}01`,
      enddate: date === '30' ? `${year}${month}30` : `${year}${month}31`,
      searchQuery: '',
      useyn: '%',
      appgubun: '101',

      // data
      data: [],

      // trail
      prtcnt_tot: '0',
      notcnt_tot: '0',
      total: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        useyn: this.state.useyn,
        appgubun: this.state.appgubun,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new DisbursementModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['inputnum', 'inputsabun'],
        [this.state.focused?.inputnum, this.state.focused?.inputsabun], type) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 0);
      }
      // 전체 체크
      this.SS({
        prtcnt_tot: this.infinity?.box?.prtcnt_tot || '0',
        notcnt_tot: this.infinity?.box?.notcnt_tot || '0',
        total: this.infinity?.box?.total || '0',
      });
      await this.table.current?.update();
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    let chkData: DisbursementModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new DisbursementModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.printWithElmanManager({
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        useyn: this.state.useyn,
        appgubun: this.state.appgubun,
        items: chkData,
      });
      chkData = [];
    }
    this.onRetrieveEvent();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      useyn: this.state.useyn,
      appgubun: this.state.appgubun,
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new DisbursementModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  onRowFocusEvent(item: DisbursementModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<DisbursementModel>, updatedRows: Array<DisbursementModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <DisbursementTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
