import * as React from 'react';
import { observer } from 'mobx-react';
import { IoMdTrash } from 'react-icons/all';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { TextBox } from '../../components/forms/TextBox';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { ComboBox, ComboBoxModel } from '../../components/forms/ComboBox';
import { CheckBox } from '../../components/forms/CheckBox';
import { TableLayout } from '../../components/layout/TableLayout';
import { TelnumTextBox } from '../../components/forms/TelnumTextBox';

interface ModalKakaoProps {
  modalStore: ModalStore;
}

const searchGubun = [
  { groupcd: '%', groupnm: '전체' },
  { groupcd: '0', groupnm: '현장' },
  { groupcd: '1', groupnm: '거래처' },
  { groupcd: '2', groupnm: '직원' },
  { groupcd: '3', groupnm: '일반' },
  { groupcd: '4', groupnm: '기타' },
  { groupcd: '5', groupnm: '영업' },
];

@observer
export class ModalKakao extends React.Component<ModalKakaoProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={900}
        height={800}
        isVisible={modalStore.isVisibleKakao}
        onClose={() => modalStore.closeKakao()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>카카오톡 전송</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>그룹분류</span>
              <ComboBox
                value={modalStore.kakaoSearchGubun}
                data={searchGubun.map((x) => new ComboBoxModel(x.groupcd, x.groupnm))}
                onSelect={(item) => {
                  modalStore.kakaoSearchGubun = item.value;
                  modalStore.retrieveKakao();
                }}
              />
            </FlexLayout>

            {(modalStore.kakaoSearchGubun === '0' || modalStore.kakaoSearchGubun === '2') && <FlexLayout size={106}>
              <span>{modalStore.kakaoSearchGubun === '0' ? '계약중' : '재직자'}</span>
              <div>
                <CheckBox
                  noMargin
                  value={modalStore.kakaoSearchGubunCheck}
                  onChange={(value) => {
                    modalStore.kakaoSearchGubunCheck = value;
                    modalStore.retrieveKakao();
                  }}
                />
              </div>
            </FlexLayout>}

            <FlexLayout weight={2}>
              <span>검색어</span>
              <TextBox
                value={modalStore.kakaoSearchQuery}
                onChange={(value) => {
                  modalStore.kakaoSearchQuery = value;
                  modalStore.kakaoSearchPosition = -1;
                }}
                onEnter={() => modalStore.searchKakao()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={136}>
              <span>선택만 보기</span>
              <div>
                <CheckBox
                  noMargin
                  value={modalStore.kakaoSearchCheck}
                  onChange={async (value) => {
                    modalStore.kakaoSearchCheck = value;
                    await modalStore.kakaoInfinity?.retrieveAll();

                    if (value) {
                      modalStore.kakaoTempData = [...modalStore.kakaoData];
                      modalStore.kakaoData = modalStore.kakaoData.filter((x) => x.check);
                    } else {
                      modalStore.kakaoData = modalStore.kakaoTempData;
                      modalStore.kakaoTempData = [];
                    }
                    modalStore.kakaoTableUsers.current?.update(true);
                  }}
                />
              </div>
            </FlexLayout>

            <Button onClick={() => modalStore.searchKakao()}>조회</Button>
          </SearchLayout>

          <FlexLayout isVertical={false} weight={2}>
            <FlexLayout weight={2}>
              <TableLayout
                ref={modalStore.kakaoTableUsers}
                header={[
                  {
                    id: 'check',
                    text: (
                      <FlexLayout justify="center" align="center">
                        <CheckBox
                          noMargin
                          value={modalStore.kakaoTotalCheck}
                          onChange={async (value) => modalStore.kakaoUpdateCheckAllToggle(value)}
                        />
                      </FlexLayout>
                    ),
                    width: 10,
                    render: (x, rowUpdate) => (
                      <FlexLayout justify="center" align="center">
                        <CheckBox
                          noMargin
                          value={x.check}
                          onChange={(value) => {
                            rowUpdate({ check: value });
                            modalStore.kakaoUpdateCheck(x, value);
                          }}
                        />
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'regflag',
                    text: '고객구분',
                    width: 20,
                    render: (x) => {
                      const item = searchGubun.filter((y) => y.groupcd === x.regflag);
                      const text = item.length ? item[0].groupnm : '';
                      return (
                        <FlexLayout justify="center" align="center">
                          {text}
                        </FlexLayout>
                      );
                    },
                  },
                  {
                    id: 'actmail',
                    text: '고객명',
                    width: 30,
                    render: (x) => (
                      <FlexLayout justify="start" align="center">
                        {x.actmail}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tel',
                    text: '전화번호',
                    width: 40,
                    render: (x) => (
                      <TelnumTextBox
                        value={x.tel || ''}
                        readonly={true}
                        transparent={true}
                      />
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={modalStore.kakaoInfinity}
                data={modalStore.kakaoData}
                onChange={(rows) => { modalStore.kakaoData = rows; }}
              />
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
                <LayoutTitle weight={1}>
                  본문
                </LayoutTitle>
                <Button onClick={async () => {
                  const boilerplate = await modalStore.openBoilerplate();
                  if (boilerplate) {
                    modalStore.kakaoRemark = `${modalStore.kakaoRemark}\n\n${boilerplate.remark}`;
                  }
                }}>
                  <span>자주쓰는 문구</span>
                </Button>
              </FlexLayout>

              <TextBox
                value={modalStore.kakaoRemark}
                placeholder="여기에 내용을 입력하세요."
                isMultiline={true}
                minHeight={120}
                onChange={(value) => { modalStore.kakaoRemark = value; }}
              />

              <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
                <LayoutTitle weight={1}>
                  받는 사람
                </LayoutTitle>
                <Button onClick={async () => {
                  modalStore.kakaoTo = [...modalStore.kakaoTo, { tel: '' }];
                  await modalStore.kakaoTableTo.current?.update(false);
                  modalStore.kakaoTableTo.current?.setFocus(modalStore.kakaoTo.length - 1, 1);
                }}>
                  <span>추가</span>
                </Button>
              </FlexLayout>

              <TableLayout
                ref={modalStore.kakaoTableTo}
                header={[
                  {
                    id: 'delete',
                    text: '삭제',
                    width: 30,
                    render: (x: any) => (
                      <FlexLayout justify="center" align="center">
                        <Button
                          isIcon={true}
                          onClick={() => {
                            modalStore.kakaoTo = modalStore
                              .kakaoTo.filter((y) => y.tel !== x.tel);
                            modalStore.kakaoTableTo.current?.update(false);
                          }}
                        >
                          <IoMdTrash size={19} />
                        </Button>
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tel',
                    text: '휴대폰 번호',
                    render: (x, rowUpdate, ref) => (
                      <TelnumTextBox
                        ref={ref}
                        value={x.tel || ''}
                        onChange={(value) => rowUpdate({ tel: value })}
                      />
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={modalStore.kakaoTo}
                onChange={(rows) => { modalStore.kakaoTo = rows; }}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
