import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FiArrowRightCircle } from 'react-icons/fi';
import {
  AddressButton,
  AttachmentBox,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Regist } from './Regist';
import { RegistTab } from './Regist.tab';
import { RegistGridMainHeader } from './headers';
import { Date6, Date8 } from '../../../../utils/time';
import { SearchBinding, SearchBindingProjects } from '../../../../models';
import { Format } from '../../../../utils/string';
import RegistModel from './models/RegistModel';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';


// 설치구분
export const ScGubuns = [
  { key: '%', value: '전체', color: 'inherit' },
  { key: '1', value: '설치', color: 'var(--color-green)' },
  { key: '2', value: '보수', color: 'var(--color-blue)' },
];

// 계약상태
const States = [
  { key: '0', value: '진행중' },
  { key: '1', value: '완료' },
  { key: '2', value: '취소' },
];

// 계약구분
export const Gubuns = [
  { key: '%', value: '전체' },
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];

// 계약구분
export const Gubuns2 = [
  { key: '0', value: '조달계약' },
  { key: '1', value: '사급계약' },
  { key: '2', value: 'LH계약' },
];

// 승인/준공도셔
export const RowBooks = [
  { key: '0', value: '미제작' },
  { key: '1', value: '제작완료' },
  { key: '2', value: '제출완료' },
  { key: '3', value: '완료' },
];

// 합격필증
export const RowResults = [
  { key: '', value: '', color: 'inherit' },
  { key: '1', value: '불합격', color: 'var(--color-red)' },
  { key: '2', value: '보완', color: 'inherit' },
  { key: '3', value: '합격', color: 'var(--color-blue)' },
];

// 발주상태
export const BalGubuns = [
  { key: '0', value: '미발주', color: 'var(--color-red)' },
  { key: '1', value: '발주중', color: 'inherit' },
  { key: '2', value: '발주완료', color: 'var(--color-blue)' },
];

// 검사신청구분
export const KcGubuns = [
  { key: '0', value: '미신청' },
  { key: '1', value: '검사신청' },
  { key: '2', value: '턴키신청' },
  { key: '3', value: '비검사' },
];

// 매출구분
export const MisGubuns = [
  { key: '', value: '', color: 'inherit' },
  { key: '0', value: '매출전', color: 'var(--color-red)' },
  { key: '1', value: '매출완료', color: 'var(--color-blue)' },
];

export const balFlags = [
  { key: '0', value: '예정', color: 'var(--color-black)' },
  { key: '1', value: '확정', color: 'var(--color-blue)' },
];

/**
 * 화면
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTemplate: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => {
  const { content } = scope.state;
  const setData = (data: any) => scope?.SS(data);
  const setContent = (data: any) => scope?.SS({
    content: new RegistModel({ ...content, ...data }, content.isNew),
  });

  const ftStatenm = ScGubuns.filter((x) => x.key === content.state);
  const statenm = ftStatenm.length > 0 ? ftStatenm[0].value : '';

  const stfp = MisGubuns.filter((y) => y.key === content?.misstflag);
  const stnm = stfp.length > 0 ? stfp[0].value : '';
  const stcolor = stfp.length > 0 ? stfp[0].color : '';

  const midfp = MisGubuns.filter((y) => y.key === content?.mismidflag);
  const midnm = midfp.length > 0 ? midfp[0].value : '';
  const midcolor = midfp.length > 0 ? midfp[0].color : '';

  const endfp = MisGubuns.filter((y) => y.key === content?.misendflag);
  const endnm = endfp.length > 0 ? endfp[0].value : '';
  const endcolor = endfp.length > 0 ? endfp[0].color : '';

  const musangDate = new Date(Date8.withDash(content?.enddate || '19700101'));
  musangDate.setMonth(musangDate.getMonth() + 3);
  const endMusangDate = Date8.make(musangDate);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>검색년도</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.stmon}
            onChange={(v) => scope.setState({ stmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ stmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ stmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={170}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.endmon}
            onChange={(v) => scope.setState({ endmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ endmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ endmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={ 270 }>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처, 주소로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>설치구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={ScGubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.contflag}
            textAlign="center"
            data={Gubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ contflag: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>승인도서</span>
          <ComboBox
            value={scope.state.okbook}
            textAlign="center"
            data={[{ key: '%', value: '전체' }, ...RowBooks].map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ okbook: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>준공도서</span>
          <ComboBox
            value={scope.state.compbook}
            textAlign="center"
            data={[{ key: '%', value: '전체' }, ...RowBooks].map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ compbook: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout size={100} />

        <Button onClick={() => scope.onClickOpenBosu()}>{content?.state === '2' ? '설치' : '보수'}현장</Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={RegistGridMainHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout
          weight={3.8}
          isVertical={true}
        >
          <FlexLayout
            size={72 * 6 + 2 * 6}
            isScroll={true}
            isVertical={true}
          >
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="설치현장"
                isNeed={true}
                footer={(
                  <FlexLayout>
                    현재상태
                  </FlexLayout>
                )}
              >
                <TextBox
                  textAlign="center"
                  weight={0.85}
                  value={content?.actcd}
                  onChange={(value) => setContent({ actcd: value })}
                  readonly={!content?.isNew}
                />

                <TextBox
                  weight={4.6}
                  value={content?.actnm}
                  onChange={(value) => setContent({ actnm: value })}
                />

                <TextBox
                  size={70}
                  textAlign="center"
                  value={statenm}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="승인도서"
                size={100}
              >
                <ComboBox
                  textAlign="center"
                  value={content.okbook}
                  data={RowBooks.map((y) => new ComboBoxModel(y.key, y.value))}
                  onSelect={(option) => setContent({ okbook: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="준공도서"
                size={100}
              >
                <ComboBox
                  textAlign="center"
                  value={content.compbook}
                  data={RowBooks.map((y) => new ComboBoxModel(y.key, y.value))}
                  onSelect={(option) => setContent({ compbook: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="합격필증여부"
                size={100}
              >
                <ComboBox
                  textAlign="center"
                  value={content.okflag}
                  data={RowResults.map((y) => new ComboBoxModel(y.key, y.value))}
                  onSelect={(option) => setContent({ okflag: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="하자기간"
                size={238}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.haja_stdate}
                  onChange={(value) => setContent({ haja_stdate: value })}
                />

                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.haja_enddate}
                  onChange={(value) => setContent({ haja_enddate: value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="관리수"
                size={114}
                isNeed={true}
              >
                <TextBox
                  textAlign="center"
                  value={content?.qty}
                  onChange={(value) => setContent({ qty: value })}
                />
              </LabelBox>

              <LabelBox
                title="설치작업자"
                style={Global.STYLE_COLOR_RED}
                footer={
                  <FlexLayout>
                    <Button
                      onClick={() => scope.scperModal(true)}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      <FiArrowRightCircle size={25}/>
                    </Button>
                  </FlexLayout>
                }
              >
                <TextBox
                  textAlign="center"
                  value={content?.scpernm}
                  onChange={(value) => setContent({ scpernm: value })}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="계약구분"
                size={173}
              >
                <ComboBox
                  textAlign="center"
                  value={content.contflag}
                  data={Gubuns2.map((y) => new ComboBoxModel(y.key, y.value))}
                  onSelect={(option) => setContent({ contflag: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="계약일"
                size={180}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.contdate}
                  onChange={(value) => scope.onHeaderItemChanged('contdate', value)}
                />
              </LabelBox>

              <LabelBox
                title="계약자"
                size={180}
                style={Global.STYLE_COLOR_RED}
              >
                <TextBox
                  value={content?.pperid}
                  textAlign="center"
                  onChange={(value) => setContent({ pperid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      setContent({
                        pperid: item.cd,
                        ppernm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  value={content?.ppernm}
                  textAlign="center"
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="영업방문예정일"
                size={180}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.visitdate}
                >
                </DateTextBox>

                <ComboBox
                  value={content?.visitflag}
                  style={{ color: content?.visitflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  data={[
                    { value: '0', remark: 'X' },
                    { value: '1', remark: 'O' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => setContent({ visitflag: option.value })}
                >
                </ComboBox>
              </LabelBox>

              <LabelBox
                title="영업방문담당자(PM)"
                size={180}
                style={Global.STYLE_COLOR_RED}
              >
                <TextBox
                  value={content?.visitperid}
                  weight={0.9}
                  textAlign="center"
                  onChange={(value) => setContent({ visitperid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      setContent({
                        visitperid: item.cd,
                        visitpernm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  value={content?.visitpernm}
                  textAlign="center"
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="발주예정처"
                style={Global.STYLE_COLOR_RED}
                footer={(
                  <FlexLayout>
                    발주상태
                  </FlexLayout>
                )}
              >
                <TextBox
                  textAlign="center"
                  value={content?.balcltcd}
                  onChange={(value) => setContent({ balcltcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_XCLIENT',
                    {},
                    true,
                    (item) => {
                      setContent({
                        balcltcd: item.cd,
                        balcltnm: item.cdnm,
                      });
                    },
                  )}
                />
                <TextBox
                  weight={2}
                  value={content?.balcltnm}
                  readonly={true}
                />

                <ComboBox
                  textAlign="center"
                  value={content.balflag}
                  data={BalGubuns.map((y) => new ComboBoxModel(y.key, y.value))}
                  onSelect={(option) => setContent({ balflag: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="자재출하예정일"
                size={173}
                footer={(
                  <FlexLayout size={60}>
                    <ComboBox
                      style={{ color: content?.bal_okflag === '0' ? 'var(--color-black)' : 'var(--color-blue)' }}
                      value={content?.bal_okflag}
                      textAlign="center"
                      data={balFlags.map((y) => new ComboBoxModel(y.key, y.value))}
                      onSelect={(option) => scope.onHeaderItemChanged('bal_okflag', option.value)}
                    >
                    </ComboBox>
                  </FlexLayout>
                )}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.baldate}
                  onChange={(value) => setContent({ baldate: value })}
                />
              </LabelBox>

              <LabelBox
                title="납기일"
                size={180}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.enddate}
                  onChange={(value) => setContent({ enddate: value })}
                />
              </LabelBox>

              <LabelBox
                size={180}
                title="검사신청여부/일"
              >
                <ComboBox
                  value={content.sckcflag}
                  data={KcGubuns.map((y) => new ComboBoxModel(y.key, y.value))}
                  onSelect={(option) => scope.onHeaderItemChanged('sckcflag', option.value)}
                />

                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.sckcdate}
                  onChange={(value) => setContent({ sckcdate: value })}
                />
              </LabelBox>

              <LabelBox
                title="검사예정일"
                size={180}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.stdate}
                  onChange={(value) => setContent({ stdate: value })}
                />
              </LabelBox>

              <LabelBox
                title="검사일"
                size={180}
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.kcdate}
                  onChange={(value) => setContent({ kcdate: value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="예상지출금" size={110}>
                <FormatNumberTextBox
                  textAlign="right"
                  value={content?.ex_mijamt}
                  onChange={(value) => setContent({ ex_mijamt: value })}
                />
              </LabelBox>

              <LabelBox title="부가세여부" size={140}>
                <OptionBox
                  value={content?.taxcls}
                  data={[
                    { value: '1', remark: '포함' },
                    { value: '0', remark: '별도' },
                  ]}
                  onChange={(item) => setContent({ taxcls: item.value })}
                />
              </LabelBox>

              <LabelBox title="계약금" size={122}>
                <FormatNumberTextBox
                  textAlign="right"
                  value={content?.contamt}
                  onChange={(value) => setContent({ contamt: value })}
                />
              </LabelBox>

              <LabelBox
                size={320}
                title="선급금"
                footer={
                  <FlexLayout style={{ color: stcolor }}>
                    {content?.stamt && parseInt(content?.stamt, 10) > 0 ? stnm : ''}
                  </FlexLayout>
                }
              >
                <FormatTextBox
                  weight={1}
                  format="###%"
                  textAlign="center"
                  value={content?.styul}
                  onChange={(value) => scope.onHeaderItemChanged('styul', `${parseInt(value, 10)}`)}
                />

                <FormatNumberTextBox
                  weight={2.1}
                  textAlign="right"
                  value={content?.stamt}
                  onChange={(value) => scope.onHeaderItemChanged('stamt', value)}
                />

                <FlexLayout
                  weight={2}
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#DDDDDD',
                  }}
                >
                  {Date8.withDash(content?.misstdate)}
                </FlexLayout>

                <FlexLayout
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#DDDDDD',
                  }}
                >
                  {content?.misstnum}
                </FlexLayout>
              </LabelBox>

              <LabelBox
                size={320}
                title="중도금"
                footer={
                  <FlexLayout style={{ color: midcolor }}>
                    {content?.midamt && parseInt(content?.midamt, 10) > 0 ? midnm : ''}
                  </FlexLayout>
                }
              >
                <FormatTextBox
                  weight={1}
                  format="###%"
                  textAlign="center"
                  value={content?.midyul}
                  onChange={(value) => scope.onHeaderItemChanged('midyul', `${parseInt(value, 10)}`)}
                />

                <FormatNumberTextBox
                  weight={2.1}
                  textAlign="right"
                  value={content?.midamt}
                  onChange={(value) => scope.onHeaderItemChanged('midamt', value)}
                />

                <FlexLayout
                  weight={2}
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#DDDDDD',
                  }}
                >
                  {Date8.withDash(content?.mismiddate)}
                </FlexLayout>

                <FlexLayout
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#DDDDDD',
                  }}
                >
                  {content?.mismidnum}
                </FlexLayout>
              </LabelBox>

              <LabelBox
                size={320}
                title="잔금"
                footer={
                  <FlexLayout style={{ color: endcolor }}>
                    {content?.endamt && parseInt(content?.endamt, 10) > 0 ? endnm : ''}
                  </FlexLayout>
                }
              >
                <FormatTextBox
                  weight={1}
                  format="###%"
                  textAlign="center"
                  value={content?.endyul}
                  onChange={(value) => scope.onHeaderItemChanged('endyul', `${parseInt(value, 10)}`)}
                />

                <FormatNumberTextBox
                  weight={2.1}
                  textAlign="right"
                  value={content?.endamt}
                  onChange={(value) => scope.onHeaderItemChanged('endamt', value)}
                />

                <FlexLayout
                  weight={2}
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#DDDDDD',
                  }}
                >
                  {Date8.withDash(content?.misenddate)}
                </FlexLayout>

                <FlexLayout
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#DDDDDD',
                  }}
                >
                  {content?.misendnum}
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="승강기소재지"
                weight={0.7}
                isNeed={true}
                style={Global.STYLE_COLOR_RED}
                footer={
                  <FlexLayout weight={0.7}>
                    <FlexLayout>
                      <TextBox
                        value={`위도: ${content?.lat}`}
                        readonly={true}
                        transparent={true}
                      />
                    </FlexLayout>
                    <FlexLayout>
                      <TextBox
                        value={`경도: ${content?.lng}`}
                        readonly={true}
                        transparent={true}
                      />
                    </FlexLayout>
                    <AddressButton
                      publicStore={scope.props.publicStore}
                      modalStore={scope.props.modalStore}
                      onChange={(address) => setContent({
                        zipcode: address.zonecode,
                        address: address.address,
                        address2: address.jibunAddress,
                        lat: address.y,
                        lng: address.x,
                      })}
                    >
                      주소검색
                    </AddressButton>
                  </FlexLayout>
                }
              >
                <TextBox
                  size={70}
                  textAlign="center"
                  value={content?.zipcode}
                  readonly={true}
                />

                <TextBox
                  textAlign="left"
                  value={content?.address}
                  onChange={(value) => setContent({ address: value })}
                />

                <TextBox
                  textAlign="left"
                  value={content?.address2}
                  onChange={(value) => setContent({ address2: value })}
                />
              </LabelBox>

              <LabelBox
                title="프로젝트코드"
                style={Global.STYLE_COLOR_RED}
              >
                <TextBox
                  textAlign="center"
                  weight={0.4}
                  value={content?.projno}
                  onChange={(value) => setContent({ projno: value })}
                  bindSearchModal={new SearchBindingProjects(
                    scope.props.modalStore,
                    {},
                    true,
                    (item) => {
                      setContent({
                        projno: item.projno,
                        projnm: item.projnm,
                      });

                      scope.onHeaderItemChanged('projno', item.projno);
                    },
                  )}
                />

                <TextBox
                  weight={2}
                  value={content?.projnm}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="거래처(연결된거래처)"
                isNeed={true}
                weight={2.82}
                style={Global.STYLE_COLOR_RED}
                footer={<FlexLayout>
                  <Button
                    onClick={() => scope.taxModal(true)}
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                  >
                    <FiArrowRightCircle size={25}/>
                  </Button>
                </FlexLayout>}
              >
                <TextBox
                  value={content?.cltcd}
                  textAlign="center"
                  onChange={(value) => setContent({ cltcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_XCLIENT',
                    {},
                    true,
                    (item) => {
                      setContent({
                        cltcd: item.cd,
                        cltnm: item.cdnm,
                      });

                      scope.onHeaderItemChanged('cltcd', item.cd);
                    },
                  )}
                />

                <TextBox
                  textAlign="left"
                  weight={4}
                  value={content?.cltnm}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="실측일"
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.surdate}
                  onChange={(value) => setContent({ surdate: value })}
                />
                <ComboBox
                  style={{ color: content?.surflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  value={content?.surflag === '1' ? 'O' : 'X'}
                  data={[
                    { value: '0', remark: 'X' },
                    { value: '1', remark: 'O' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => setContent({ surflag: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="실측자"
                style={Global.STYLE_COLOR_RED}
              >
                <TextBox
                  value={content?.surperid}
                  weight={0.9}
                  textAlign="center"
                  onChange={(value) => setContent({ surperid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      setContent({
                        surperid: item.cd,
                        surpernm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  value={content?.surpernm}
                  textAlign="center"
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="설계일"
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.desdate}
                  onChange={(value) => setContent({ desdate: value })}
                />
                <ComboBox
                  style={{ color: content?.desflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  value={content?.desflag === '1' ? 'O' : 'X'}
                  data={[
                    { value: '0', remark: 'X' },
                    { value: '1', remark: 'O' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => setContent({ desflag: option.value })}
                />
              </LabelBox>

              <LabelBox
                title="설계자"
                style={Global.STYLE_COLOR_RED}
              >
                <TextBox
                  value={content?.desperid}
                  weight={0.9}
                  textAlign="center"
                  onChange={(value) => setContent({ desperid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      setContent({
                        desperid: item.cd,
                        despernm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  value={content?.despernm}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="사전검사일">
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.be_kcdate}
                  onChange={(value) => setContent({ be_kcdate: value })}
                />
              </LabelBox>

              <LabelBox
                title="사전검사자"
                style={Global.STYLE_COLOR_RED}
              >
                <TextBox
                  value={content?.pperid}
                  textAlign="center"
                  onChange={(value) => setContent({ pperid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      setContent({
                        pperid: item.cd,
                        ppernm: item.cdnm,
                      });
                    },
                  )}
                />

                <TextBox
                  value={content?.ppernm}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                weight={2}
                title="실적구분"
                footer={(
                  <FlexLayout>
                    {Format.number(content?.contamt)}
                  </FlexLayout>
                )}
              >
                <span style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                }}>
                  전기
                </span>
                <FormatTextBox
                  format="###%"
                  textAlign="center"
                  value={content?.elecyul}
                  onChange={(value) => scope.onHeaderItemChanged('elecyul', value)}
                />
                <TextBox
                  weight={2}
                  textAlign="right"
                  value={content?.elecamt}
                  onChange={(value) => setContent({ elecamt: value })}
                />

                <span style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                }}>
                  설치
                </span>
                <FormatTextBox
                  format="###%"
                  textAlign="center"
                  value={content?.scyul}
                  onChange={(value) => scope.onHeaderItemChanged('scyul', value)}
                />
                <TextBox
                  weight={2}
                  textAlign="right"
                  value={content?.scamt}
                  onChange={(value) => setContent({ scamt: value })}
                />
              </LabelBox>

              <LabelBox
                title="모델개별인증서"
              >
                <ComboBox
                  value={content?.bildju}
                  textAlign="center"
                  data={scope.dataBildju.map((x) => new ComboBoxModel(x.code, x.cnam))}
                  onSelect={(option) => scope.onHeaderItemChanged('contstate', option.value)}
                />
              </LabelBox>

              <LabelBox title="분할납부" size={80}>
                <CheckBox
                  value={content?.scdivided === '1'}
                  onChange={(v) => scope.onHeaderItemChanged('scdivided', v ? '1' : '0')}
                  isDisabledTrackingStateChange={true}
                />
              </LabelBox>

              <LabelBox
                title="계약상태"
              >
                <ComboBox
                  value={content?.contstate}
                  textAlign="center"
                  data={States.map((x) => new ComboBoxModel(x.key, x.value))}
                  onSelect={(option) => scope.onHeaderItemChanged('contstate', option.value)}
                  style={{ color: content?.contstate === '1' ? 'var(--color-blue)' : 'var(--color-black)' }}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="설치종료"
                footer={
                  <CheckBox
                    value={content?.sccls === '1'}
                    onChange={(v) => scope.onHeaderItemChanged('sccls', v ? '1' : '0')}
                  />
                }
              >
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={content?.scclsdate}
                  onChange={(v) => setContent({ scclsdate: v })}
                  readonly={content?.sccls !== '1'}
                />
              </LabelBox>

              <LabelBox
                title="설치에서 종료 사유"
                weight={7}
                footer={
                  <FlexLayout>
                    <Button onClick={() => scope.onClickScEnd('b_sccls')}>설치종료</Button>
                    <Button
                      onClick={() => scope.onClickScEnd('b_scclscancle')}
                      disabled={content?.sccls !== '1'}
                    >
                      설치종료취소
                    </Button>
                  </FlexLayout>
                }
              >
                <TextBox
                  value={content?.scclsremark}
                  onChange={(v) => setContent({ scclsremark: v })}
                  readonly={content?.sccls !== '1'}
                />
              </LabelBox>

            </FlexLayout>
          </FlexLayout>

          <FlexLayout weight={2}>
            <TabLayout
              scope={scope}
              tabs={RegistTab}
              onChange={(_, index) => scope.onTabChange(index)}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>


      {/* 탭 - 공정현황 > 도면 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleProcessDrawing}
        onClose={() => setData({ isVisibleProcessDrawing: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            appnm="도면"
            appnum={`TB_E611_DP_${content?.actcd}${scope.state.processDrawingEqupcd}`}
            isEnabledTitle={true}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleProcessDrawing: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 검색바 > 버튼- 보수현장 */}
      <ModalView
        width={500}
        height={222}
        isVisible={scope.state.isVisibleBosu}
        onClose={() => setData({ isVisibleBosu: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>설치 현장을 유지보수로 이관</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="납기일자"
              isNeed={true}
            >
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={content?.enddate}
                onChange={(v) => setContent({ enddate: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout align="center">
            설치현장명 : {content?.scactnm}을(를)<br />
            아래 납기일로 보수현장으로 넘기시겠습니까?
          </FlexLayout>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            isVertical={true}
          >
            <SubHeader>
              <div>
                <span>무상계약일</span>
                <span>
                  {Date8.withDash(content?.enddate || '19700101')} ~ {Date8.withDash(endMusangDate)}
                </span>
              </div>

              <FlexLayout justify="end">
                <Button onClick={() => setData({ isVisibleBosu: false })}>취소</Button>
                <Button onClick={() => scope.onClickBosu()}>확인</Button>
              </FlexLayout>
            </SubHeader>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.taxDetailModal}
        onClose={() => scope.taxModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SubHeader>
            <div>
              히스토리
            </div>

            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onNewTaxEvent()}
              >
                신규
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onTaxSaveEvent()}
              >
                저장
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                onClick={() => scope.onTaxDelete()}
              >
                삭제
              </Button>
              <Button
                style={{ height: '25px', width: '80px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.taxModal(false)}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
          <FlexLayout weight={14}>
            <TableLayout
              header={[
                {
                  id: 'seq',
                  text: '순번',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltcd',
                  text: '거래처코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate) => (
                    <TextBox
                      textAlign="center"
                      value={x.cltcd}
                      onChange={(value) => rowUpdate({ cltcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_XCLIENT',
                        {
                          uv_arg1: '2',
                        },
                        true,
                        (item) => {
                          rowUpdate({
                            cltcd: item.cd,
                            cltnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명',
                  width: 30,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.cltnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ cltnm: value })}
                    />
                  ),
                },
                {
                  id: 'stdate',
                  text: '시작일자',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <DateTextBox
                      value={x.stdate || '19700101'}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => rowUpdate({ stdate: value })
                      }
                    />
                  ),
                },
                {
                  id: 'enddate',
                  text: '종료일자',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <DateTextBox
                      value={x.enddate || ''}
                      textAlign="center"
                      format="####-##-##"
                      onChange={
                        (value) => rowUpdate({ enddate: value })
                      }
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 35,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'inpernm',
                  text: '변경자명',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.inpernm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.PersonPopUpData || []}
              infinityHandler={scope.infinityModal2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2) => {
                scope.onUpdatedRows2(rows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              ref={scope.tableCheckPerson}
            />
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.scPerModal}
        onClose={() => scope.scperModalClose()}
        width={600}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.content?.actnm} 설치자</LayoutTitle>

          <FlexLayout
            justify="right"
            size={Global.LAYOUT_GRID_HEIGHT_1}
          >
            <Button onClick={() => scope.scPerModalNewEvent()}>추가</Button>
            <Button onClick={() => scope.scPerModalDeleteEvent()}>삭제</Button>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.tableScPerModal}
              header={[
                {
                  id: 'perid',
                  text: (
                    <FlexLayout style={{ color: 'var(--color-red)' }}>설치자</FlexLayout>
                  ),
                  render: (x, rowUpdate) => (
                    <FlexLayout>
                      <TextBox
                        textAlign="center"
                        value={x.perid}
                        onChange={(value) => rowUpdate({ perid: value })}
                        bindSearchModal={new SearchBinding(
                          scope.props.modalStore,
                          'TB_JA001_SHARE',
                          {},
                          true,
                          (item) => {
                            let mpclafis = '';

                            if (item?.cdnm.substr(0, 4) === '[직영]') {
                              mpclafis = '0';
                            } else if (item?.cdnm.substr(0, 4) === '[외주]') {
                              mpclafis = '1';
                            }

                            rowUpdate({
                              perid: item?.cd,
                              pernm: item?.cdnm.substr(4).trim(),
                              mpclafi: mpclafis,
                            });
                          },
                        )}
                      >
                      </TextBox>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '설치자명',
                  render: (x, rowUpdate) => (
                    <TextBox
                      textAlign="left"
                      value={x.pernm}
                      onChange={(value) => rowUpdate({ pernm: value })}
                    >
                    </TextBox>
                  ),
                },
                {
                  id: 'mpclafi',
                  text: '구분',
                  render: (x, rowUpdate) => (
                    <FlexLayout>
                      <ComboBox
                        textAlign="center"
                        value={x.mpclafi}
                        style={{ color: x.mpclafi === '0' ? 'var(--color-blue)' : 'var(--color-red)' }}
                        data={[
                          { value: '0', remark: '직영' },
                          { value: '1', remark: '외주' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => rowUpdate({ mpclafi: option.value })}
                      >
                      </ComboBox>
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.scPerModalData}
              onChange={(rows) => scope.scPerModalUpdateEvent(rows)}
              onRowFocusEvent={(item) => scope.scPerModalRowfocusEvent(item)}
            >
            </TableLayout>
          </FlexLayout>

          <FlexLayout
            justify="right"
            size={Global.LAYOUT_GRID_HEIGHT_1}
          >
            <Button onClick={() => scope.scPerModalSaveEvent()}>저장</Button>
            <Button onClick={() => scope.scperModalClose()}>닫기</Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
