import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { PurchasingStatistics } from '../PurchasingStatistics';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { PurchasingStatisticsTabTenYearGridHeader } from '../headers/PurchasingStatisticsTabTenYearGridHeader';

export const PurchasingStatisticsTenYearTemplate: React.FC<TemplateProps<PurchasingStatistics>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridTenYear}
      header={PurchasingStatisticsTabTenYearGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataTenYear}
    />
  </FlexLayout>
);
