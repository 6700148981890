import * as React from 'react';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  LabelBox,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { WorkCode } from './WorkCode';

/**
 * 화면
 * @window TB_E021
 * @category 업무코드등록
 */
export const WorkCodeTemplate: React.FC<TemplateProps<WorkCode>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>

        <FlexLayout>
          <FlexLayout justify="left" weight={1}>
            <LabelBox
              title="상위부서"
              size={250}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={scope.state.kukcd}
                textAlign="center"
                onChange={(value) => scope.setState({ kukcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_PZ008',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      kukcd: item.cd,
                      kuknm: item.cdnm,
                      searchQuery: item.cd,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.kuknm}
                textAlign="center"
                onChange={(value) => scope.setState({ kuknm: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="상위업무코드를 먼저 선택하세요"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
            >

            </LabelBox>
          </FlexLayout>

        </FlexLayout>

      </FlexLayout>
      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'kukcd',
            color: 'var(--color-red)',
            text: '상위부서코드',
            width: 7,
            render: (x, rowUpdate, ref) => (
              x.modalCheck === '1'
                ? <TextBox
                  ref={ref}
                  value={x.kukcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ kukcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_PZ008',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        kukcd: item.cd,
                        kuknm: item.cdnm,
                        searchQuery: item.cd,
                      });
                    },
                  )}
                />
                : <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.kukcd}
                </FlexLayout>
            ),
          },
          {
            id: 'kuknm',
            text: '상위부서명',
            width: 7,
          },
          {
            id: 'busicd',
            text: '업무코드',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.busicd}
                textAlign="center"
                onChange={(value) => rowUpdate({ busicd: value })}
              />
            ),
          },
          {
            id: 'businm',
            text: '업무명',
            width: 30,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign="left"
                value={x.businm}
                onChange={(value) => rowUpdate({ businm: value })}/>
            ),
          },
          {
            id: 'upflag',
            text: '업무구분',
            width: 16,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.upflag}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.state.upflags?.map((y) => new ComboBoxModel(y.upflag, y.upflagnm))}
                onSelect={(option) => rowUpdate({ upflag: option.value })}
              />
            ),
          },
          {
            id: 'eduflag',
            text: '교육',
            width: 6,
            render: (x, rowUpdate) => (
              <CheckBox value={x.eduflag === '1'} onChange={(v) => rowUpdate({ eduflag: v ? '1' : '0' })}/>
            ),
          },
          {
            id: 'useyn',
            text: '사용유무',
            width: 6,
            render: (x, rowUpdate) => (
              <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })}/>
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 25,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign="left"
                value={x.remark}
                onChange={(value) => rowUpdate({ remark: value })}/>
            ),
          },
        ]}
        data={scope.state.workCodekList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
);
