import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export default class EnrollmentTableModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costseq: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly dong: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ho: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pgubun: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly prtseq: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.costseq = data.costseq || '';
    this.dong = data.dong || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.ho = data.ho || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.mamt = data.mamt || '';
    this.myn = data.myn || '';
    this.pcode = data.pcode || '';
    this.pgubun = data.pgubun || '';
    this.pname = data.pname || '';
    this.prtseq = data.prtseq || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.spjangcd = data.spjangcd || '';
    this.tamt = data.tamt || '';
    this.uamt = data.uamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
