import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { DailyChartTemplate } from './DailyChart.template';
import { DailyChartModel } from './DailyChart.model';
import { InfinityRetrieve } from '../../../../models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface DailyChartState {
  src: string;
  stmon: string;
  actcd: string;
  actnm: string;
  gigan: string;
  searchQuery: string;
  result: string;
  data: Array<DailyChartModel>;
  focusedData?: DailyChartModel;
  contgs: Array<any>;
  contgubuns: Array<any>;
  url?: string;

  evcdList: Array<any>;

  // 요일 및 색상

  weekday: Array<any>;
  color: Array<any>;

  // trail
  total: string;
  equpcnt_tot: string;

  // 출력체크
  chk: string;
}

/**
 * 컨트롤러
 * @window w_tb_e034w_01
 * @category 일상점검표
 */
export class DailyChart extends PageComponent<PageProps, DailyChartState>
  implements PageToolEvents {
  updatedRows?: Array<DailyChartModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      searchQuery: '',
      gigan: '',
      result: '',
      pernm: '',
      perid: '',
      actcd: '',
      actnm: '',
      data: [],
      // trail
      total: '0',
      equpcnt_tot: '0',

      // 출력체크
      chk: '0',

      // 색상
      color: [],

      // 요일
      weekday: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        actcd: this.state.actcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new DailyChartModel(x))],
        }, next);
        this.table.current?.update(false);
        this.getWeekDay();
        this.getColor();
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.table.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.table.current?.update(true);

      const index = await this.infinity?.retrieveTo(['rptdate', 'actnm'],
        [this.state.focusedData?.rptdate, this.state.focusedData?.actnm], type, true) || 0;
      const data = await this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        equpcnt_tot: data?.equpcnt_tot || '0',
      });
      if (this.state.data.length) {
        this.table.current?.setFocus(index);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    // const params = {
    //   chk: this.state.focusedData?.chk,
    //   actcd: this.state.focusedData?.actcd,
    //   equpcd: this.state.focusedData?.equpcd,
    // };
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.stmon,
      items: this.updatedRows,
    });
  }

  @action
  async getWeekDay() {
    for (let i = 1; i < 32; i += 1) {
      if (i < 10) {
        i = (i < 10) ? (+`0${i}`) : i;
      }
      const list: any = Object.keys(this.state.data[0] || []).filter((k) =>
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        k.startsWith('weekday')).map((k, text) => ({ id: k, text: this.state.data[0][k] }));
      this.setState({ weekday: list });
    }
  }

  @action
  async getColor() {
    for (let i = 0; i < 31; i += 1) {
      if (i < 10) {
        i = (i < 10) ? (+`0${i}`) : i;
      }
      if (this.state.weekday[i].text === '토') {
        this.setState({ color: [...this.state.color, 'var(--color-blue)'] });
      } else if (this.state.weekday[i].text === '일') {
        this.setState({ color: [...this.state.color, 'var(--color-red)'] });
      } else {
        this.setState({ color: [...this.state.color, ''] });
      }
    }
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: DailyChartModel) {
    this.setState({ focusedData: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<DailyChartModel>, updatedRows: Array<DailyChartModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <DailyChartTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
