import * as React from 'react';
import { action } from 'mobx';
import fileDownload from 'js-file-download';
import {
  AskType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { JiroBundleTemplate } from './JiroBundle.template';
import { JiroBundleModel } from './JiroBundle.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmFail, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { MD5 } from '../../../../utils/string';
import { FunctionRepository } from '../../../../repositories';
import Confirm from '../../../../utils/confirm/Confirm';

interface JiroBundleState {
  // search
  searchQuery: string;
  yearmon: string;
  gubun?: Array<any>;
  searchGubun?: string;
  billgubun?: string;
  wkactcd: string;
  wkactnm: string;

  // data
  data: Array<JiroBundleModel>;
  focused?: JiroBundleModel;
  pushTotalCheck: boolean;
  img?: string;

  // trail
  prtcnt_tot: string;
  notcnt_tot: string;
  total: string;
  amt_tot: string;
  addamt_tot: string;
  misamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e050w_02
 * @category 지로일괄발행
 */
export class JiroBundle extends PageComponent<PageProps, JiroBundleState>
  implements PageToolEvents {
  updatedRows?: Array<JiroBundleModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      searchQuery: '',
      wkactcd: '',
      searchGubun: '%',
      billgubun: '%',

      // data
      data: [],

      // trail
      prtcnt_tot: '0',
      notcnt_tot: '0',
      total: '0',
      amt_tot: '0',
      addamt_tot: '0',
      misamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 구분
    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubun: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        wkactcd: this.state.wkactcd,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        billkind: this.state.billgubun,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new JiroBundleModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 1);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltnm', 'misnum'],
        [this.state.focused?.cltnm, this.state.focused?.misnum], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 1);
      }
      // 전체 체크
      this.SS({
        prtcnt_tot: this.infinity?.box?.prtcnt_tot || '0',
        notcnt_tot: this.infinity?.box?.notcnt_tot || '0',
        total: this.infinity?.box?.total || '0',
        amt_tot: this.infinity?.box?.amt_tot || '0',
        addamt_tot: this.infinity?.box?.addamt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
      });
      await this.table.current?.update(true);
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    let chkData: JiroBundleModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new JiroBundleModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      let result;
      if (user.custcd !== 'kpen_ph') {
        result = await Confirm.ask('확인', '미수내역을 출력하시겠습니까?', '예', '아니오');
        if (result === AskType.CANCEL) {
          ConfirmWarning.show('닫기', '취소되었습니다');
          return;
        }
      }

      await api.printWithElmanManager({
        stmon: this.state.yearmon,
        wkactcd: this.state.wkactcd,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        billkind: this.state.billgubun,
        misflag: result === AskType.NO ? '0' : '1',
        items: chkData.map((x) => ({
          chk: x.chk,
          cltcd: x.cltcd,
          pubnum: x.pubnum,
          misdate: x.misdate,
          misnum: x.misnum,
        })),
      });
      chkData = [];
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      wkactcd: this.state.wkactcd,
      as_nm: this.state.searchQuery,
      gubun: this.state.searchGubun,
      billkind: this.state.billgubun,
    });
  }

  @action
  onRowFocusEvent(item: JiroBundleModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<JiroBundleModel>, updatedRows: Array<JiroBundleModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new JiroBundleModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async jiroRetrive() {
    const {
      actionStore: api,
      waitQueueStore,
      modalStore,
      publicStore,
    } = this.props;
    const { user } = publicStore;

    const chkData: any[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1' && x?.prtchk === '1') {
        chkData.push({
          chk: x.chk,
          prtchk: x.prtchk,
          cltcd: x.cltcd,
          pubnum: x.pubnum,
        });
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나라도 해주세요.');
      return;
    }

    if (this.state.focused?.misamt === '0') {
      ConfirmWarning.show('확인', '합계가 0 인것은 지로생성이 불가합니다.');
      return;
    }

    const result = await Confirm.ask('선택', '전자지로생성은 출력이 한번이상 된것만 선택됩니다. \n 전자지로를 생성하시겠습니까?', '아니오', '예');

    if (!result) {
      ConfirmWarning.show('닫기', '취소되었습니다');
      return;
    }

    waitQueueStore.append('지로일괄발행', 'LAZYTASK-wb_elecgiro', async (response) => {
      const file = await FunctionRepository.tempDownloadRaw(response);

      try {
        const newArrayBuffer = new Uint8Array(file.raw!);
        let trk = 0;

        for (let i = newArrayBuffer.length - 1; i > 0; i -= 1) {
          if (newArrayBuffer[i] === 0x0D || newArrayBuffer[i] === 0x0A) {
            trk += 1;
          } else {
            break;
          }
        }

        const trimed = newArrayBuffer.slice(0, newArrayBuffer.length - trk);
        fileDownload(trimed, file.filename.trim());
      } catch {
        ConfirmFail.show('오류', '다운로드에 실패하였습니다.');
      }
    }, () => this.jiroRetrive());
    modalStore?.openWaitQueue();

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    await api.fxExec('wb_elecgiro_lazytask',
      {
        stmon: this.state.yearmon,
        wkactcd: this.state.wkactcd,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        billkind: this.state.billgubun,
        items: chkData,
        src,
      });
  }

  @action
  onClickOpenBalEnrollment() {
    this.props.publicStore.go(
      '/w_tb_da023',
      {
        projno: this.state.focused?.projno,
        misdate: this.state.focused?.misdate,
      },
    );
  }

  render() {
    return (
      <JiroBundleTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
