import * as React from 'react';
import {
  Button, FlexLayout, SubHeader, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { ManageStatus } from '../ManageStatus';
import {
  ManageStatusTableHeaderScAmt,
  ManageStatusTableHeaderTelnum,
} from '../headers';

/**
 * 화면 > 탭 - 하단 > 상세내역
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusDetailTabDetailTemplate: React.FC<TemplateProps<ManageStatus>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout
          size={Global.LAYOUT_TITLE_HEIGHT_1}
          isVertical={true}
        >
          <SubHeader>
            <div>
              <span>담당자 연락처</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onClickTelnumNew()}>추가</Button>
              <Button onClick={() => scope.onClickTelnumDelete()}>삭제</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>

        <TableLayout
          ref={scope.refTableTelnum}
          header={ManageStatusTableHeaderTelnum(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.dataHeadTabTableTelnum}
          onRowFocusEvent={(x) => scope.onRowFocusEventTelnum(x)}
          onChange={(_, updates) => scope.onDetailTelnumChange(updates)}
        />
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout
          size={Global.LAYOUT_TITLE_HEIGHT_1}
          isVertical={true}
        >
          <SubHeader>
            <div>
              <span>설치비 지급내역</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onClickScAmtNew()}>추가</Button>
              <Button onClick={() => scope.onClickScAmtDelete()}>삭제</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>

        <TableLayout
          ref={scope.refTableScAmt}
          header={ManageStatusTableHeaderScAmt(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.dataHeadTabTableScAmt}
          onRowFocusEvent={(x) => scope.onRowFocusEventScAmt(x)}
          onChange={(_, updates) => scope.onDetailScAmtChange(updates)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
