import { TableLayoutHeader } from '../../../../../constants';
import { Management } from '../Management';
import { TextBox } from '../../../../../components/forms/TextBox';
import { CancelModel } from '../models';
import { CheckBox } from '../../../../../components/forms/CheckBox';

// 해지사유
const Cancel: TableLayoutHeader<CancelModel, Management>[] = [
  {
    id: 'outcd',
    text: '코드',
    width: 50,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.outcd}
        onChange={(value) => rowUpdate({ outcd: value })}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'outnm',
    text: '해지사유명',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.outnm}
        onChange={(value) => rowUpdate({ outnm: value })}
      />
    ),
  },
  {
    id: 'useyn',
    text: '사용유무',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.useyn === '1'}
        onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
];

export default Cancel;
