import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  CheckBox,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { BillBundle } from './BillBundle';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';

const taxclss = [
  { value: '1', remark: '포함' },
  { value: '0', remark: '별도' },
];

const billkinds = [
  { value: '1', remark: '발행' },
  { value: '0', remark: '미발행' },
];

const taxgubuns = [
  { value: '01', remark: '과세' },
  { value: '02', remark: '영세' },
  { value: '03', remark: '면세' },
];

/**
 * 화면
 * @window w_tb_e050w_04
 * @category 청구서일괄발행
 */
export const BillBundleTemplate: React.FC<TemplateProps<BillBundle>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={280}>
          <span>설치-{'>'}보수년월</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ yearmon: value, endYearmon: value })}
            onEnter={() => scope.onDateEvent()}
            head={(<button
              onClick={() => {
                setData({
                  yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                },
                () => scope.onDateEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({
                  yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                },
                () => scope.onDateEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={240}>
          <span>만료년월</span>
          <DateTextBox
            value={scope.state.endYearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ endYearmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({
                  endYearmon: new Date6Calculator(scope.state.endYearmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({
                  endYearmon: new Date6Calculator(scope.state.endYearmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={scope.state.pushTotalCheck}
                  onChange={async (value) => scope
                    .updateCheckAllToggle(value)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
            width: 50,
            render: (x, rowUpdate) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={x.chk === '1'}
                  onChange={(value) => {
                    rowUpdate({ chk: value ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'misdate',
            text: '매출일자',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                건수 {scope.state.total}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.misdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'misnum',
            text: '번호',
            width: 70,
          },
          {
            id: 'cltnm',
            text: '거래처명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.cltnm}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'zipcd',
            text: '우편번호',
            width: 70,
          },
          {
            id: 'cltadres',
            text: '주소',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.cltadres}
              </FlexLayout>
            ),
          },
          {
            id: 'taxcls',
            text: '부가세여부',
            width: 70,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {taxclss.filter((y) => y.value === x.taxcls)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'amt',
            text: '매출액',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.amt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.amt)}
              </FlexLayout>
            ),
          },
          {
            id: 'addamt',
            text: '부가세',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.addamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.addamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '합계',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.misamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.misamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'yyyymm',
            text: '귀속년월',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##"
                textAlign="center"
                value={x.yyyymm}
              />
            ),
          },
          {
            id: 'billkind',
            text: '계산서발행',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {billkinds.filter((y) => y.value === x.billkind)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'taxgubun',
            text: '과세구분',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {taxgubuns.filter((y) => y.value === x.taxgubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'jirogubun',
            text: '지로구분',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {billkinds.filter((y) => y.value === x.jirogubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '적요',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        onRowDoubleClick={() => scope.onClickOpenBalEnrollment()}
      />
    </FlexLayout>
  );
};
