import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';

// 사용내역
const Use: GridLayoutHeader[] = [
  {
    id: 'balseq',
    text: '순번',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.balseq}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 300,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '발주수량',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.qty > 0 && x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'qcqty',
    text: '남은수량',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.qcqty > 0 && x.qcqty}
      </FlexLayout>
    ),
  },
  {
    id: 'ibgpernm',
    text: '입고자',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.ibgpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'ibgqty',
    text: '입고수량',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.ibgqty > 0 && x.ibgqty}
      </FlexLayout>
    ),
  },
  {
    id: 'delpernm',
    text: '불출자',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.delpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'delqty',
    text: '불출수량',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.delqty > 0 && x.delqty}
      </FlexLayout>
    ),
  },
  {
    id: 'banqty',
    text: '반납수량',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.banqty > 0 && x.banqty}
      </FlexLayout>
    ),
  },
];

export default Use;
