import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_popup_e501
 * @category 스케쥴관리
 */

export class SchedulePopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly kukcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly compdate: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly compnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly upflag: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly upflagnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly wkcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly wknm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly plannum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly beplandate: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly kcspnm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly lkflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly lkdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly lknum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly notice: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly noticedate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly noticetime: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.kukcd = data.kukcd || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.upflag = data.upflag || '';
    this.upflagnm = data.upflagnm || '';
    this.wkcd = data.wkcd || '001';
    this.wknm = data.wknm || '';
    this.plandate = data.plandate || '';
    this.plannum = data.plannum || '';
    this.beplandate = data.beplandate || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.kcspnm = data.kcspnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.state = data.state || '1';
    this.remark = data.remark || '';
    this.lkflag = data.lkflag || '';
    this.lkdate = data.lkdate || '';
    this.lknum = data.lknum || '';
    this.notice = data.notice || '0';
    this.noticedate = data.noticedate || '';
    this.noticetime = data.noticetime || '0700';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';

    if (this.isNew) {
      this.plandate = this.getNextMonday(this.plandate);
    }
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  getNextMonday(dateString: string) {
    // 입력된 날짜를 Date 객체로 변환
    const inputDate = new Date(Number(dateString.slice(0, 4)), // 년도
      parseInt(dateString.slice(4, 6), 10) - 1, // 월 (0부터 시작하므로 1을 빼줌)
      Number(dateString.slice(6, 8))); // 일

    // 입력된 날짜가 주말(토요일이나 일요일)인 경우
    if (inputDate.getDay() === 0 || inputDate.getDay() === 6) {
      // 다음주 월요일로 이동
      inputDate.setDate(inputDate.getDate() + (8 - inputDate.getDay()));
    }

    // 결과를 8자리 문자열로 반환 (년도, 월, 일)
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = String(inputDate.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  }
}
