import * as React from 'react';
import {
  AiFillMinusSquare,
  AiFillPlusSquare,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CalendarLayout,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  IFrame,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Plan } from './Plan';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { CalendarLayoutDataModel, SearchBinding } from '../../../../models';
import PlanModel from './models/PlanModel';
import { PlanYetTableHeader } from './headers/PlanYetTableHeader';
import { PlanExpireGridHeader } from './headers/PlanExpireGridHeader';
import { PlanActTableHeader } from './headers/PlanActTableHeader';
import { PlanDetailTableHeader } from './headers/PlanDetailTableHeader';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { JournalButtonClickedTypes } from '../../../electronic/preparation/Journal/Journal';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';
import { PlanEqupTableHeader } from './headers/PlanEqupTableHeader';

/**
 * 화면
 * @window w_tb_e035
 * @category 점검계획등록
 */
export const PlanTemplate: React.FC<TemplateProps<Plan>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>기간</span>
          <DateTextBox
            value={scope.state.planmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ planmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ planmon: new Date6Calculator(scope.state.planmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ planmon: new Date6Calculator(scope.state.planmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span style={Global.STYLE_COLOR_RED}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                  searchQuery: item.cd,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.4}
            value={scope.state.divinm}
            textAlign="center"
            onChange={(value) => setData({ divinm: value })}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                  searchQuery: item.cd,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout weight={1.6}>
          <span style={Global.STYLE_COLOR_RED}>현장코드</span>
          <TextBox
            textAlign="center"
            value={scope.state.actcd}
            onChange={(value) => setData({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setData({
                  actcd: item.cd,
                  actnm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            value={scope.state.actnm}
            readonly={true}
            weight={2}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>호기코드</span>
          <TextBox
            textAlign="center"
            value={scope.state.equpcd}
            onChange={(value) => setData({ equpcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E611',
              { uv_arg1: scope.state.actcd },
              true,
              (item) => {
                setData({
                  equpcd: item.cd,
                  equpnm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.3}
            value={scope.state.equpnm}
            readonly={true}
          />
        </FlexLayout>

        <Button
          onClick={() => scope.openModalYet()}
          badge={scope.state.notplan}
        >
          <div style={{ width: 60 }}>미계획</div>
        </Button>
        <Button>
          <div style={{ width: 60 }}>위치(GPS)</div>
        </Button>
        <Button
          onClick={() => scope.openModalExpire()}
          badge={scope.state.endcnt}
        >
          <div style={{ width: 60 }}>만료</div>
        </Button>
        <Button onClick={() => scope.openModalCharge()}>
          <div style={{ width: 60 }}>담당별합계</div>
        </Button>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout size={32}>
          <FlexLayout size={500}/>

          <FlexLayout
            weight={1}
            style={{ fontSize: 20 }}
            align="center"
            justify="center"
          >
            {scope.state.planmon.substr(0, 4)}년 {scope.state.planmon.substr(4, 2)}월 점검계획
          </FlexLayout>

          <FlexLayout
            size={500}
            align="end"
            justify="center"
            isVertical={true}
          >
            {scope.state.tot.split('\n').map((x) => <div>{x}</div>)}
          </FlexLayout>
        </FlexLayout>

        <CalendarLayout
          month={scope.state.planmon}
          data={scope.state.data}
          render={(c, items: CalendarLayoutDataModel<PlanModel>[]) => (
            <DefaultLayout style={{ cursor: 'pointer' }}>
              <div>
                <span style={Global.STYLE_COLOR_RED}>계획 {scope.dayRemarks[c.data]?.plan || 0} </span>
                <span style={Global.STYLE_COLOR_BLUE}>완료 {scope.dayRemarks[c.data]?.done || 0} </span>
              </div>
              {items.slice(0, 6).map((x, i) => (
                <div key={i}>
                  <span style={{ color: scope.getItemColor(x.data) }}>
                    [{scope.getItemPrefix(x.data)}]
                  </span>
                  {x.data.actnm}
                </div>
              ))}
            </DefaultLayout>
          )}
          onDateClick={(date) => scope.openModalDetail(date.data)}
        />
      </FlexLayout>


      {/* 상세 */}
      <ModalView
        isVisible={scope.state.isVisibleModalDetail}
        onClose={() => scope.SS({ isVisibleModalDetail: false })}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout>
            <FlexLayout
              isVertical={true}
              weight={0.5}
            >
              <SubHeader>
                <div>
                  <span>{Date8.withDash(scope.detailDate)} 점검</span>
                </div>

                <FlexLayout justify="end">
                  <Button onClick={() => scope.addModalAct()}>추가</Button>
                  <Button onClick={() => scope.removeModalAct()}>삭제</Button>
                </FlexLayout>
              </SubHeader>

              <FlexLayout>
                <TableLayout
                  ref={scope.tableAct}
                  header={PlanActTableHeader(scope)}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  data={scope.state.dataAct}
                  infinityHandler={scope.infinityAct}
                  onChange={(rows) => scope.SS({ dataAct: rows })}
                  onRowFocusEvent={(_, index) => scope.onTableModalActFocused(index)}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <SubHeader>
                <div>
                  <span>호기</span>
                </div>

                <FlexLayout justify="end">
                  <Button onClick={() => scope.removeModalDetail()}>삭제</Button>
                </FlexLayout>
              </SubHeader>

              <FlexLayout>
                <TableLayout
                  ref={scope.tableDetail}
                  header={PlanDetailTableHeader(scope)}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  data={scope.state.dataDetail}
                  infinityHandler={scope.infinityDetail}
                  onChange={(rows) => scope.SS({ dataDetail: rows })}
                  onRowFocusEvent={(_, index) => scope.onTableModalDetailFocused(index)}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout weight={0.5}>
              <Button
                isFull={true}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.SS({ isVisibleModalDetail: false })}
              >
                닫기
              </Button>
            </FlexLayout>

            <Button
              isFull={true}
              onClick={() => scope.saveModalDetail()}
            >
              저장
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 상세 호기선택 */}
      <ModalView
        isVisible={scope.state.isVisibleModalEqup}
        onClose={() => scope.SS({ isVisibleModalEqup: false })}
        width={600}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout>
            <TableLayout
              ref={scope.tableEqup}
              header={PlanEqupTableHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.dataEqup}
              infinityHandler={scope.infinityEqup}
              onChange={(rows) => scope.SS({ dataEqup: rows })}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              onClick={() => scope.setState({ isVisibleModalEqup: false })}
              theme={BUTTON_THEMES.RED}
            >
              닫기
            </Button>

            <Button
              isFull={true}
              onClick={() => scope.saveModalEqup()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 미계획 */}
      <ModalView
        isVisible={scope.state.isVisibleModalYet}
        onClose={() => scope.SS({ isVisibleModalYet: false })}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout>
            <TableLayout
              ref={scope.tableYet}
              header={PlanYetTableHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.dataYet}
              infinityHandler={scope.infinityYet}
              onChange={(rows) => scope.SS({ dataYet: rows })}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              onClick={() => scope.setState({ isVisibleModalYet: false }, () => scope.onRetrieveEvent())}
              theme={BUTTON_THEMES.RED}
            >
              닫기
            </Button>

            <Button
              isFull={true}
              onClick={() => scope.saveModalYet()}
            >
              저장
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 만료 */}
      <ModalView
        isVisible={scope.state.isVisibleModalExpire}
        onClose={() => scope.SS({ isVisibleModalExpire: false })}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout>
            <GridLayout
              scope={scope}
              ref={scope.gridExpire}
              header={PlanExpireGridHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.dataExpire}
              infinityHandler={scope.infinityExpire}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              onClick={() => scope.SS({ isVisibleModalExpire: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 담당별 합계 */}
      <ModalView
        isVisible={scope.state.isChargeModalVisible}
        onClose={() => scope.closeModalCharge()}
        zIndex={-1}
        width={1600}
        height={1200}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              담당별합계
            </LayoutTitle>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchLayout size={300}>
              <FlexLayout size={200}>
                <span>월</span>
                <DateTextBox
                  value={scope.state.popupStmon}
                  textAlign="center"
                  format="#### - ##"
                  onChange={(value) => { scope.setState({ popupStmon: value }); }}
                  onEnter={() => scope.chargeRetrieve()}
                  head={(<button
                    onClick={() => {
                      scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add(-1).toString() },
                        () => scope.chargeRetrieve());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                  trail={(<button
                    onClick={() => {
                      scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add().toString() },
                        () => scope.chargeRetrieve());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            </SearchLayout>
            <FlexLayout
              justify='right'
              align='center'
            >
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.printCharge()}
              >
                출력
              </Button>
              <Button
                style={{ marginTop: '10px', height: '25px', width: '60px' }}
                onClick={() => scope.closeModalCharge()}
              >
                확인
              </Button>
            </FlexLayout>
          </FlexLayout>

          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={1772}
          >
            <FlexLayout size={200}/>
            <FlexLayout
              size={273}
              justify="center"
              align="center"
            >
              관리 대수
            </FlexLayout>
            <FlexLayout
              size={148}
              justify="center"
              align="center"
            >
              03월 점검
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              1주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              2주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              3주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              4주
            </FlexLayout>
            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
              5주
            </FlexLayout>
            <FlexLayout
              size={595}
              justify="center"
              align="center"
            >
            </FlexLayout>
          </GridLayoutAdditionalHeader>

          <GridLayout
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 125,
                trail: () => (
                  <FlexLayout
                    style={{ color: 'var(--color-pink)' }}
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout>
                    {(x.chk === '0' && x.open === '0')
                    && <Button
                      onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.ADD, x)}
                      isIcon={true}
                    >
                      <AiFillPlusSquare size={17}/>
                    </Button>
                    }
                    {
                      (x.chk === '0' && x.open === '1')
                      && <Button
                        onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.MINUS, x)}
                        isIcon={true}
                      >
                        <AiFillMinusSquare size={17}/>
                      </Button>
                    }
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ fontWeight: x.chk === '0' && 'bold' }}
                    >
                      {x.divinm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 75,
                trail: (data) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {`${data.length} 명`}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'endqty2',
                text: '평균대수',
                width: 75,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.endqty2 !== '0' && x.endqty2}
                  </FlexLayout>
                ),
              },
              {
                id: 'totqty',
                text: '현재대수',
                width: 75,
                trail: (data) => {
                  const sumTotqty = Sum.all(data, 'totqty');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumTotqty !== 0 && Format.number(sumTotqty)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.totqty !== '0' && x.totqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'chaqty',
                text: '변경대수',
                width: 75,
                trail: (data) => {
                  const sumChaqty = Sum.all(data, 'chaqty');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumChaqty !== 0 && Format.number(sumChaqty)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.chaqty !== '0' && x.chaqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'notkccnt',
                text: '미검사',
                width: 50,
                trail: (data) => {
                  const sumNotkccnt = Sum.all(data, 'notkccnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumNotkccnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-violet)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.notkccnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'miscnt',
                text: '미계획',
                width: 50,
                trail: (data) => {
                  const sumMiscnt = Sum.all(data, 'miscnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-red)' }}
                      justify="center"
                      align="center"
                    >
                      {sumMiscnt !== 0 && Format.number(sumMiscnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.miscnt !== '0' && x.miscnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'cnt',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumCnt = Sum.all(data, 'cnt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumCnt !== 0 && Format.number(sumCnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.cnt !== '0' && x.cnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'compcnt',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumCompcnt = Sum.all(data, 'compcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumCompcnt !== 0 && Format.number(sumCompcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.compcnt !== '0' && x.compcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt1',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt1 = Sum.all(data, 'weekcnt1');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt1 !== 0 && Format.number(sumWeekcnt1)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt1 !== '0' && x.weekcnt1}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt1',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt1 = Sum.all(data, 'weekcompcnt1');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt1 !== 0 && Format.number(sumWeekcompcnt1)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt1 !== '0' && x.weekcompcnt1}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt2',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt2 = Sum.all(data, 'weekcnt2');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt2 !== 0 && Format.number(sumWeekcnt2)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt2 !== '0' && x.weekcnt2}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt2',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt2 = Sum.all(data, 'weekcompcnt2');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt2 !== 0 && Format.number(sumWeekcompcnt2)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt2 !== '0' && x.weekcompcnt2}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt3',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt3 = Sum.all(data, 'weekcnt3');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt3 !== 0 && Format.number(sumWeekcnt3)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt3 !== '0' && x.weekcnt3}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt3',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt3 = Sum.all(data, 'weekcompcnt3');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt3 !== 0 && Format.number(sumWeekcompcnt3)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt3 !== '0' && x.weekcompcnt3}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt4',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt4 = Sum.all(data, 'weekcnt4');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt4 !== 0 && Format.number(sumWeekcnt4)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt4 !== '0' && x.weekcnt4}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt4',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt4 = Sum.all(data, 'weekcompcnt4');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt4 !== 0 && Format.number(sumWeekcompcnt4)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.weekcompcnt4 !== '0' && x.weekcompcnt4}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcnt5',
                text: '계획',
                width: 50,
                trail: (data) => {
                  const sumWeekcnt5 = Sum.all(data, 'weekcnt5');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumWeekcnt5 !== 0 && Format.number(sumWeekcnt5)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ fontWeight: x.chk === '0' && 'bold' }}
                  >
                    {x.weekcnt5 !== '0' && x.weekcnt5}
                  </FlexLayout>
                ),
              },
              {
                id: 'weekcompcnt5',
                text: '점검',
                width: 50,
                trail: (data) => {
                  const sumWeekcompcnt5 = Sum.all(data, 'weekcompcnt5');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumWeekcompcnt5 !== 0 && Format.number(sumWeekcompcnt5)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-blue)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.weekcompcnt5 !== '0' && x.weekcompcnt5}
                  </FlexLayout>
                ),
              },
              {
                id: 'jrececnt',
                text: '지원받음',
                width: 100,
                trail: (data) => {
                  const sumJrececnt = Sum.all(data, 'jrececnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-green)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJrececnt !== 0 && Format.number(sumJrececnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      color: 'var(--color-green)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jrececnt !== '0' && x.jrececnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'jmiscnt',
                text: '미점검',
                width: 100,
                trail: (data) => {
                  const sumJmiscnt = Sum.all(data, 'jmiscnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-red)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJmiscnt !== 0 && Format.number(sumJmiscnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-red)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jmiscnt !== '0' && x.jmiscnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'jcompcnt',
                text: '지원점검',
                width: 100,
                trail: (data) => {
                  const sumJcompcnt = Sum.all(data, 'jcompcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-green)' }}
                      justify="center"
                      align="center"
                    >
                      {sumJcompcnt !== 0 && Format.number(sumJcompcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-green)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.jcompcnt !== '0' && x.jcompcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'totcnt',
                text: '담당점검',
                width: 100,
                trail: (data) => {
                  const sumTotcnt = Sum.all(data, 'totcnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-blue)' }}
                      justify="center"
                      align="center"
                    >
                      {sumTotcnt !== 0 && Format.number(sumTotcnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      fontWeight: x.chk === '0' && 'bold',
                      color: 'var(--color-blue)',
                    }}
                    justify="center"
                    align="center"
                  >
                    {x.totcnt !== '0' && x.totcnt}
                  </FlexLayout>
                ),
              },
              {
                id: 'totyul',
                text: '진행률',
                width: 60,
                trail: (data) => {
                  // const sumPlanyul = Sum.all(data, 'planyul');
                  const sumCnt = Sum.all(data, 'totcnt');
                  const sumQty = Sum.all(data, 'totqty');
                  let sumTotyul = 0;
                  sumTotyul = Math.round(Number(sumCnt / sumQty) * 100);
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="right"
                      align="center"
                    >
                      {sumTotyul}%
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-violet)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                  >
                    {x.totqty === '0' ? '0' : String(Math.round(Number((x.totcnt / x.totqty) * 100)))}%
                  </FlexLayout>
                ),
              },
              {
                id: 'send5cnt',
                text: '5일이상전송초과',
                width: 125,
                trail: (data) => {
                  const sumSend5cnt = Sum.all(data, 'send5cnt');
                  return (
                    <FlexLayout
                      style={{ color: 'var(--color-violet)' }}
                      justify="right"
                      align="center"
                    >
                      {sumSend5cnt !== 0 && Format.number(sumSend5cnt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    style={{
                      color: 'var(--color-red)',
                      fontWeight: x.chk === '0' && 'bold',
                    }}
                    justify="right"
                    align="center"
                  >
                    {x.send5cnt !== '0' && x.send5cnt}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupChargeList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onChargeRowFocusEvent(item)}
            onHorizontalScroll={(x) => { scope.additionalTitle.current?.scrollTo(x); }}
          />

          <FlexLayout>
            {scope.state.isGraphVisible && <IFrame src={scope.state.graph} />}
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
