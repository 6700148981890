import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  ComboBoxModel,
  ComboBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models';
import { SortationCode } from './SortationCode';


/**
 * 화면
 * @window w_tb_da020
 * @category 매출구분코드 등록
 */
export const SortationCodeTemplate: React.FC<TemplateProps<SortationCode>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'artcd',
          text: '구분코드',
          width: 50,
        },
        {
          id: 'artnm',
          text: '구분명',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.artnm}
              onChange={(value) => rowUpdate({ artnm: value })}
            />
          ),
        },
        {
          id: 'acccd',
          text: '대변계정',
          color: 'var(--color-red)',
          width: 70,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="center"
              value={x.acccd}
              onChange={(value) => rowUpdate({ acccd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AC001',
                {},
                true,
                (item) => {
                  rowUpdate({ acccd: item.cd, accnm: item.cdnm });
                },
              )}
            />
          ),
        },
        {
          id: 'accnm',
          text: '대변계정명',
          width: 80,
        },
        {
          id: 'acccd2',
          text: '차변계정',
          color: 'var(--color-red)',
          width: 70,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="center"
              value={x.acccd2}
              onChange={(value) => rowUpdate({ acccd2: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AC001',
                {},
                true,
                (item) => {
                  rowUpdate({ acccd2: item.cd, accnm2: item.cdnm });
                },
              )}
            />
          ),
        },
        {
          id: 'accnm2',
          text: '차변계정명',
          width: 80,
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 45,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'jgubun',
          text: '정기유무',
          width: 45,
          render: (x, rowUpdate) => (
            <CheckBox value={x.jgubun === '1'} onChange={(v) => rowUpdate({ jgubun: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'bankcd',
          text: '코드',
          color: 'var(--color-red)',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="center"
              value={x.bankcd}
              onChange={(value) => rowUpdate({ bankcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AA040',
                {},
                true,
                (item) => {
                  rowUpdate({
                    bankcd: item.cd,
                    banknm: item.uv_arg3,
                    accnum: item.uv_arg1,
                  });
                },
              )}
            />
          ),
        },
        {
          id: 'banknm',
          text: '은행명',
          width: 80,
        },
        {
          id: 'accnum',
          text: '계좌번호',
          width: 100,
        },
        {
          id: 'bumun',
          text: '부문',
          width: 150,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.bumun || ''}
              style={{
                padding: '0 0 0 20px',
                fontSize: 12,
              }}
              data={[
                { value: '', remark: '' },
                { value: '1', remark: '유지관리부문' },
                { value: '2', remark: '설치부문' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => rowUpdate({ bumun: option.value })}
            />
          ),
        },
        {
          id: 'wkgubun',
          text: '매입구분',
          width: 150,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.wkgubun}
              data={scope.state.wkgubuns?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
              onSelect={(option) => {
                rowUpdate({ wkgubun: option.value });
              }}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
