import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e611_035_03
 * @category 호기별점검항목등록
 */
export class UnitModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 유무
   *
   * **data type** varchar(1)
   */
  readonly kcflag: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(13)
   */
  readonly actnm: string;

  /**
   * 점검기종
   *
   * **data type** varchar(5)
   */
  readonly evcd: string;

  /**
   * 호기코드
   *
   * **data type** varchar(5)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(50)
   */
  readonly elno: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.kcflag = data.kcflag || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.evcd = data.evcd || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
