/**
 * 모델
 * @window w_tb_e101w_02
 * @category 월 계약변동내역
 */
import { computed } from 'mobx';

export class HistoryPopupModel {
  /**
   * 순서
   *
   * **data type** varchar(?)
   */
  seq: string;

  /**
   * 작성자
   *
   * **data type** varchar(?)
   */
  perid: string;

  /**
   * 작성자명
   *
   * **data type** varchar(?)
   */
  pernm: string;

  /**
   * 기록일자
   *
   * **data type** varchar(?)
   */
  hdate: string;

  /**
   * 알림만료일
   *
   * **data type** varchar(?)
   */
  wakedate: string;

  /**
   * 제목
   *
   * **data type** varchar(?)
   */
  title: string;

  /**
   * 답변확인
   *
   * **data type** varchar(?)
   */
  okflag: string;

  /**
   * 현장코드
   *
   * **data type** varchar(?)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(?)
   */
  actnm: string;

  /**
   * 계약내용
   *
   * **data type** varchar(?)
   */
  remark: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.hdate = data.hdate || '';
    this.wakedate = data.wakedate || '';
    this.title = data.title || '';
    this.okflag = data.okflag || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
