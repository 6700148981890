import * as React from 'react';
import { RefObject } from 'react';
import { action } from 'mobx';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ReportModel from './models/ReportModel';
import { ReportTemplate } from './Report.template';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Date8,
  Today,
} from '../../../../utils/time';
import { CallHistoryModel } from './models/CallHistoryModel';


export enum BtnGubun {
  b_adviceremark,
  b_resultremark,
}


interface SampleState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  perid: string;
  pernm: string;
  state: string;
  searchGubun?: string;

  data: Array<ReportModel>;
  content: ReportModel;

  callHistoryData: Array<CallHistoryModel>;
  callHistoryFocusedData: CallHistoryModel;
  callHistoryRowFocusedData: CallHistoryModel;

  gridFocus?: ReportModel;

  isVisibleAttachment: boolean;

  focusState: string;

  reportVisible: boolean;

  // trail
  cnt_tot: string;
  state1_tot: string;
  state2_tot: string;

  // 변경전 계획일자
  beforeplandate: string;

  // 변경 버튼 flag
  planDateBtnFlag: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_b502
 * @category 영업보고서
 */
export class Report extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  callHistoryGrid: RefObject<GridLayout> = React.createRef();

  gridFocusIndex: number = 0;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    this.state = props.state || {
      // stdate: pageParams.stdate || '20000101',
      stdate: `${year}${month}01`,
      enddate: pageParams.enddate || Date8.make(),
      searchQuery: '',
      searchGubun: '%',
      perid: pageParams.perid || '',
      pernm: '',
      state: '%',
      data: [],
      content: new ReportModel(),

      // 통화이력 List
      callHistoryData: [],
      callHistoryFocusedData: [], // 통화 이력 focus Data
      callHistoryRowFocusedData: [], // 통화 이력 dw_2_RowFocus Data

      isVisibleAttachment: false,
      reportVisible: false,

      // trail
      cnt_tot: '',
      state1_tot: '',
      state2_tot: '',

      // 변경전 계획일자
      beforeplandate: '',

      // 변경 버튼 flag
      planDateBtnFlag: false,
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        stdate: pageParams.stdate || this.state.stdate,
        enddate: pageParams.enddate || this.state.enddate,
        perid: pageParams.perid || this.state.perid,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        // stdate: this.state.stdate || '20000101',
        stdate: this.state.stdate,
        enddate: this.state.enddate || Date8.make(),
        as_nm: this.state.searchQuery || '',
        perid: this.state.perid || '%',
        state: this.state.state || '%',
        gubun: this.state.searchGubun || '%',
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new ReportModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        this.state.data.length && this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
      gridFocus: undefined,
      planDateBtnFlag: false, // 영업일자 변경버튼 false 로 초기화 (변경버튼 눌러야 변경가능하게 하기 위함)
    });

    const index = await this.infinity?.retrieveTo(['plandate', 'plannum'],
      [this.state.content.plandate, this.state.content.plannum], type, true) || 0;

    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot || '0',
      state1_tot: this.infinity?.box?.state1_tot || '0',
      state2_tot: this.infinity?.box?.state2_tot || '0',
    });
    if (!autoLoad) return;
    this.state.data.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new();

    if (this.state.searchQuery !== '' || this.state.enddate < Today.date()
      || this.state.perid !== '' || this.state.pernm !== '' || this.state.state !== '%') {
      await this.SS({
        searchQuery: '',
        stdate: '20000101',
        enddate: Date8.make(),
        perid: '',
        pernm: '',
        state: '%',
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST, true);
    }
    if (data) {
      const one = new ReportModel(data, true);

      await this.SS({
        content: one,
        data: [one, ...this.state.data],
      });

      this.grid.current?.setFocus(0);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state?.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.state.content?.appgubun === '101') {
      ConfirmWarning.show('경고', '이미 결재가 상신되었습니다.');
      return;
    }

    if (await api.save(
      this.state.content,
      this.state.content.isNew,
    )) {
      await this.SS({ focusState: '1' });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state?.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    let result: boolean;

    if (this.state.content?.appgubun === '101') {
      result = await Confirm.show('확인', '이미 결재가 상신되어있는 문서입니다. 다른이(상급자)가  이문서에 대해 승인이 있습니다.그래도 상신 취소 하시겠습니까?', ConfirmType.QUESTION);
      if (!result) return;
    }

    if (this.state.gridFocus?.isReported) {
      await this.onSubmitButtonClicked(true);
      return;
    }

    const text = `${this.state?.gridFocus?.actnm}`;

    if (await api.delete(text, this.state.content)) {
      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 출력이 가능합니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.gridFocus, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager(this.state?.gridFocus);
  }


  @action
  async onRowFocusEvent(item: ReportModel | undefined, index: number) {
    if (item?.isNew) {
      return;
    }

    if (!item) {
      await this.SS({
        content: new ReportModel(),
        gridFocus: item,
      });
      this.gridFocusIndex = 0;
      return;
    }

    await this.SS({
      content: new ReportModel(),
      gridFocus: item,
    });
    this.gridFocusIndex = index;

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', {
      plandate: this.state?.gridFocus?.plandate,
      plannum: this.state?.gridFocus?.plannum,
      actperid: this.state?.gridFocus?.actperid,
      actpernm: this.state?.gridFocus?.actpernm,
    });

    if (data) {
      this.SS({
        content: new ReportModel(data),
        callHistoryData: data?.items,
      });
    }

    if (this.state?.gridFocus?.appgubun === '001' || this.state?.gridFocus?.appgubun === '131' || this.state.gridFocus?.appgubun.trim() === '') {
      this.setState({
        reportVisible: false,
      });
    } else {
      this.setState({
        reportVisible: true,
      });
    }

    await this.callHistoryGrid.current?.setFocus(0);
  }

  @action
  async callHistoryOnRowFocusEvent(item: CallHistoryModel) {
    const { actionStore: api } = this.props;

    await this.SS({
      callHistoryFocusedData: new CallHistoryModel(item),
    });

    if (this.state.callHistoryData?.length > 0) {
      const data = await api.fxExec('dw_2_RowFocuschanged',
        {
          seq: this.state.callHistoryFocusedData.seq,
        });
      if (data) {
        await this.SS({
          callHistoryRowFocusedData: new CallHistoryModel(data),
        });
      }
    }
  }

  @action
  async itemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...content,
      [key]: value,
      itemname: key,
      data: value,
      actperid: this.state.gridFocus?.actperid,
      actpernm: this.state.gridFocus?.actpernm,
      bosuperm: this.state.gridFocus?.bosupernm,
      bosuhpnum: this.state.gridFocus?.bosuhpnum,
    }, content.isNew);

    const one = new ReportModel({ ...content, ...result }, content.isNew);
    this.setState({ content: one });
  }

  @action
  async onButtonclick(name: any) {
    const { actionStore: api } = this.props;
    const { content } = this.state;

    this.setState({ focusState: '1' });

    const result = await api.fxExec('dw_2_buttonclicked',
      {
        new: this.state.content.isNew ? '0' : '1',
        itemname: BtnGubun[name],
        plandate: this.state.content?.plandate,
        plannum: this.state.content?.plannum,
        adviceremark: this.state.content?.adviceremark,
      });

    const one = new ReportModel({ ...content, ...result }, content.isNew);
    this.setState({ content: one });

    await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }

  @action
  async onClickSMS() {
    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 출력이 가능합니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.content?.perid, '오류', '처리자를 먼저 선택하세요')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.content?.adviceremark, '오류', '먼저 상담내용을 입력하세요')) {
      return;
    }

    const result = await Confirm.ask(
      '문자 전송',
      `${this.state.content.advicehpnum} 번호로 조치내용을 전송하시겠습니까?`,
      '보내기',
      '취소',
    );

    if (result === AskType.YES) {
      const { actionStore: api } = this.props;
      ConfirmSuccess.showPbMessage(await api.fxExec('wb_sms', {
        perid: this.state.content.perid,
        actnm: this.state.content.actnm,
        advicehpnum: this.state.content.advicehpnum,
        adviceremark: this.state.content.adviceremark,
        advicepernm: this.state.content.advicepernm,
        address: this.state.content.address,
        address2: this.state.content.address2,
      }));
    }
  }

  @action
  async onSubmitButtonClicked(isForce: boolean = false) {
    if (!ConfirmWarning.assert(this.state?.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    // if (this.state?.gridFocus?.appgubun === '101') {
    //   return;
    // }

    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (user.perid !== this.state?.content?.perid) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다');
      return;
    }

    if (!isForce && this.state?.gridFocus?.isReported && !this.state?.gridFocus?.isReportedCancelable) {
      ConfirmWarning.show('오류', '현재 결재 상태에서는 취소할 수 없습니다');
      return;
    }

    if (this.state?.gridFocus?.isReportedCancelable) {
      appflag = 'cancel';
      text = `${this.state?.gridFocus?.actnm} 결재상신을 취소하시겠습니까?`;
    } else {
      appflag = 'ok';
      text = `${this.state?.gridFocus?.actnm} 결재를 상신하시겠습니까?`;
    }
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    if (appflag === 'ok') {
      if (await modalStore.openApprovalLine(PAPERCD.SALES)) {
        await api.fxExec('wb_appreport', {
          papercd: PAPERCD.SALES.toString(),
          ...this.state?.content,
          appflag,
        }) && await this.onRetrieveEvent();
      }
    } else {
      await api.fxExec('wb_appreport', {
        papercd: PAPERCD.SALES.toString(),
        ...this.state?.content,
        appflag,
      }) && await this.onRetrieveEvent();
    }
    await this.onSaveEvent();
  }


  // Refer
  @action
  async openReferSelector() {
    if (!this.state.content?.appgubun || !this.state.content?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.SALES, this.state.content?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    await this.onRowFocusEvent(this.state?.gridFocus, this.gridFocusIndex);
  }

  @action
  async plandateButtonClick() {
    const { actionStore: api } = this.props;

    await api.fxExec('dw_2_buttonclicked',
      {
        new: this.state.content.isNew ? '1' : '0',
        itemname: 'b_plandate',
        data: this.state.content.plandate, // 변경 전 날짜
        plandate: this.state.beforeplandate, // 변경 전 날짜
        plannum: this.state.content.plannum,
        newplandate: this.state.content.plandate, // 변경한 날짜
      });

    await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }


  render() {
    return <ReportTemplate
      scope={this}
    />;
  }
}
