import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  FormatTextBox,
  GridLayout,
  Button,
  LabelBox,
  FormatNumberTextBox,
  ComboBox,
  ComboBoxModel,
  TableLayout,
  LayoutTitle,
  ModalView,
  AttachmentBox, SubHeader,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Trip,
  TripButtonClickedTypes,
  TripItemChangeTypes,
} from './Trip';
import { Date8, Today } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_pb401
 * @category 출장보고서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

const sndflags = [
  { value: '0', remark: '미지급' },
  { value: '1', remark: '지급' },
];

const weekflags = [
  { value: '0', remark: '휴일제외' },
  { value: '1', remark: '휴일포함' },
];

const kmflags = [
  { value: '0', remark: '시내출장' },
  { value: '1', remark: '시외출장' },
];

export const TripTemplate: React.FC<TemplateProps<Trip>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '제목, 출장사유로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 80 }}>문서복사</div>
        </Button>

        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout size={546}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'outdate',
              text: '일자',
              width: 15,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.outdate}
                />
              ),
            },
            {
              id: 'outnum',
              text: '번호',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.outnum}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '작성자',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'title',
              text: '제목',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'appgubun',
              text: '상태',
              width: 10,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={
                      () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                    }
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'sndflag',
              text: '지급',
              width: 10,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                  style={{ color: x.sndflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                >
                  {sndflags.filter((y) => y.value === x.sndflag)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'attcnt',
              text: '첨부',
              width: 15,
              render: (x) => (
                <TextBox
                  textAlign='center'
                  value={`${x.attcnt} 건`}
                />
              ),
            },
          ]}
          data={scope.state.tripList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 출장보고서를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.outdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ outdate: value })}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.outnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="##-######-###-####"
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="출장거리">
              <ComboBox
                style={{ color: model?.kmflag === '1' ? 'var(--color-blue)' : '' }}
                value={model?.kmflag}
                data={kmflags.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ kmflag: option.value })}
              />
            </LabelBox>
            <LabelBox title="출장분류">
              <ComboBox
                value={model?.gubun}
                data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.cd, y.nm))}
                onSelect={(option) => setData({ gubun: option.value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="제목"
              weight={3}
            >
              <TextBox
                value={model.title}
                onChange={(value) => setData({ title: value })}
              />
            </LabelBox>
            <LabelBox
              title="출장기간"
              weight={2}
              footer={
                <FlexLayout size={80}>
                  <ComboBox
                    value={model?.weekflag}
                    data={weekflags.map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => setData({ weekflag: option.value },
                      () => scope.itemChanged(TripItemChangeTypes.WEEKFLAG))
                    }
                  />
                </FlexLayout>
              }
            >
              <DateTextBox
                value={model?.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ stdate: value },
                  () => scope.itemChanged(TripItemChangeTypes.STDATE))}
              />
              <span style={{ margin: 8 }}>~</span>
              <DateTextBox
                value={model?.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ enddate: value })}
                onBlur={() => scope.itemChanged(TripItemChangeTypes.ENDDATE)}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              weight={3}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.actnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
            <LabelBox
              title="출장자"
              weight={2}
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    onClick={() => {
                      scope.onButtonClicked(TripButtonClickedTypes.PERID);
                    }}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.outpernm}
                onChange={(value) => setData({ outpernm: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={250}>
            <LabelBox
              title="출장사유"
              weight={3}
            >
              <TextBox
                isMultiline={true}
                onChange={(value) => setData({ remark: value })}
                value={model?.remark}
              />
            </LabelBox>
            <LabelBox
              title="결과보완사항"
              weight={2}
            >
              <TextBox
                isMultiline={true}
                onChange={(value) => setData({ result: value })}
                value={model?.result}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={3}
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => { (scope.onButtonClicked(TripButtonClickedTypes.REFER)); }}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => { scope.onButtonClicked(TripButtonClickedTypes.PIC); }}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                color={model?.attcnt < 1 ? 'var(--color-black)' : 'var(--color-blue)'}
                value={model?.attcnt < 1 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="지급여부">
              <TextBox
                style={{ color: model?.sndflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                value={model?.sndflag === '1' ? '지급' : '미지급'}
                readonly={true}
              />

              <FormatTextBox
                textAlign="center"
                format="####-##"
                value={model?.snddate}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

        <LayoutTitle size={230}>경비지출내역</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 출장보고서를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'seq',
              text: 'No',
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq}
                </FlexLayout>
              ),
            },
            {
              id: 'accdate',
              text: '사용일자',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  value={(!x.accdate) ? `${Today.date()}` : x.accdate }
                  textAlign="center"
                  onChange={(value) => rowUpdate({ accdate: value })}
                />
              ),
            },
            {
              id: 'productnm',
              text: '품명',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.productnm}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ productnm: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '사용내역',
              width: 300,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
            {
              id: 'ouncnt',
              text: '인원',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.ouncnt}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ ouncnt: value })}
                />
              ),
            },
            {
              id: 'qty',
              text: '수량',
              width: 50,
              trail: () => (
                <FlexLayout
                  align='center'
                  justify='center'
                >
                  {scope.state.qty_tot}
                </FlexLayout>
              ),
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.qty}
                  textAlign="center"
                  onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
                />
              ),
            },
            {
              id: 'unit',
              text: '단위',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.unit}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ unit: value })}
                />
              ),
            },
            {
              id: 'uamt',
              text: '단가',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.uamt || ''}
                  onChange={(value) => scope?.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                />
              ),
            },
            {
              id: 'samt',
              text: '금액',
              width: 150,
              trail: () => (
                <FlexLayout
                  align='center'
                  justify='right'
                >
                  {scope.state.samt_tot}
                </FlexLayout>
              ),
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt || ''}
                  onChange={(value) => { rowUpdate({ samt: value }); }
                  }
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.tripDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data.appnum}
            appnm={'출장보고서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });
            }}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.loadTriperModal}
        onClose={() => scope.loadTriperModal(false)}
        width={500}
        height={400}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>출장자</LayoutTitle>

          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'perid',
                  text: '출장자',
                  width: 75,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ perid: value })}
                      onEnter={() => scope.onRetrieveEvent()}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                      isDisabledTrackingStateChange={true}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '출장자명',
                  width: 250,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ pernm: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.popupList}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onPopupRowFocusEvent(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalNew()}>추가</Button>
              <Button onClick={() => scope.modalDelete()}>삭제</Button>
              <Button onClick={() => scope.onButtonClicked(TripButtonClickedTypes.PERID_OK)}>저장</Button>
              <Button onClick={() => scope.loadTriperModal(false)}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
