import * as React from 'react';
import { RefObject } from 'react';
import { observer } from 'mobx-react';
import { FlexLayout } from '../FlexLayout';
import { Global, GridLayoutAdditionalHeaderProps } from '../../../constants';
import mStyle from './GridLayoutAdditionalHeader.module.scss';
import { JoinClassName } from '../../../utils/string';

@observer
export class GridLayoutAdditionalHeader extends React.Component<
  GridLayoutAdditionalHeaderProps
  > {
  body: RefObject<HTMLDivElement>;

  constructor(props: GridLayoutAdditionalHeaderProps, context?: any) {
    super(props, context);
    this.body = React.createRef();
  }

  public scrollTo(x: number) {
    this.body.current?.scrollTo({
      left: x,
      top: 0,
    });
  }

  render() {
    const {
      className,
      style,
      height,
      contentWidth,
      children,
    } = this.props;

    return (
      <div
        ref={this.body}
        className={JoinClassName.make([mStyle.container, className])}
        style={{
          flex: `0 0 ${height || Global.LAYOUT_TITLE_HEIGHT_1}px`,
        }}
      >
        <div
          style={{
            width: contentWidth || 'initial',
            height: height || Global.LAYOUT_GRID_HEIGHT_1,
          }}
        >
          <FlexLayout style={style}>
            {children}
            <FlexLayout size={8} />
          </FlexLayout>
        </div>
      </div>
    );
  }
}
