/**
 * 모델
 * @window w_tb_e050w_03
 * @category 세금계산서일괄출력
 */
import { computed } from 'mobx';

export class TaxInvoiceModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 회사코드
   *
   * **data type** char(8)
   */
  custcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 출고거래처명
   *
   * **data type** varchar(80000)
   */
  delcltnm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(6)
   */
  zipcd: string;

  /**
   * 주소
   *
   * **data type** varchar(201)
   */
  cltadres: string;

  /**
   * 0
   *
   * **data type** varchar(1)
   */
  misgubun: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 지로번호2
   *
   * **data type** varchar(10)
   */
  pubnum1: string;

  /**
   * 지로번호
   *
   * **data type** varchar(15)
   */
  pubnum: string;

  /**
   * 검색어
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 회사구분코드
   *
   * **data type** varchar(3)
   */
  wkactcd: string;

  /**
   * 회사구분
   *
   * **data type** varchar(100)
   */
  wkactnm: string;

  /**
   * 계약금액
   *
   * **data type** number
   */
  contamt: string;

  /**
   * 매출분류
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 귀속년월
   *
   * **data type** varchar(6)
   */
  yyyymm: string;

  /**
   * 회계적용
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 매출액
   *
   * **data type** number
   */
  amt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 합계
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  taxcls: string;

  /**
   * 과세구분
   *
   * **data type** varchar(3)
   */
  taxgubun: string;

  /**
   * 지로구분
   *
   * **data type** varchar(1)
   */
  jirogubun: string;

  /**
   * 계산서일자
   *
   * **data type** varchar(8)
   */
  tax_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  tax_spnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 지로납기일
   *
   * **data type** varchar(8)
   */
  jirodate: string;

  /**
   * 출력구분
   *
   * **data type** varchar(1)
   */
  prtchk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.chk = data.chk || '';
    this.spjangcd = data.spjangcd || '';
    this.custcd = data.custcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.delcltnm = data.delcltnm || '';
    this.zipcd = data.zipcd || '';
    this.cltadres = data.cltadres || '';
    this.misgubun = data.misgubun || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.pubnum1 = data.pubnum1 || '';
    this.pubnum = data.pubnum || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.contamt = data.contamt || '';
    this.gubun = data.gubun || '';
    this.yyyymm = data.yyyymm || '';
    this.accyn = data.accyn || '';
    this.amt = data.amt || '';
    this.addamt = data.addamt || '';
    this.misamt = data.misamt || '';
    this.billkind = data.billkind || '';
    this.taxcls = data.taxcls || '';
    this.taxgubun = data.taxgubun || '';
    this.jirogubun = data.jirogubun || '';
    this.tax_spdate = data.tax_spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.remark = data.remark || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.jirodate = data.jirodate || '';
    this.prtchk = data.prtchk || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
