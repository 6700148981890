import { computed } from 'mobx';

/**
 * 모델
 * 계약이력정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentHistoryModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  contdate: string;

  /**
   * 년차
   *
   * **data type** varchar(?)
   */
  yy: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 순번
   *
   * **data type** varchar(13)
   */
  seq: string;

  /**
   * 거래처명
   *
   * **data type** varchar(?)
   */
  cltnm: string;

  /**
   * 계약분류
   *
   * **data type** varchar(2)
   */
  contg: string;

  /**
   * 대수
   *
   * **data type** varchar(2)
   */
  qty: string;

  /**
   * 계약금액
   *
   * **data type** float
   */
  amt: string;

  /**
   * 부가세
   *
   * **data type** varchar(?)
   */
  addyn: string;

  /**
   * 시작일자
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 종료일자
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 해지일자
   *
   * **data type** varchar(?)
   */
  clsdate: string;

  /**
   * 청구주기
   *
   * **data type** varchar(?)
   */
  deljugi: string;

  /**
   * 계약구분
   *
   * **data type** varchar(?)
   */
  contgubun: string;

  /**
   * 보류시작일
   *
   * **data type** varchar(?)
   */
  boryudate: string;

  /**
   * 보류종료일
   *
   * **data type** varchar(?)
   */
  boryudate2: string;

  /**
   * 보류사유
   *
   * **data type** varchar(?)
   */
  boryusayoo: string;

  attcnt: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.contdate = data.contdate || '';
    this.actcd = data.actcd || '';
    this.seq = data.seq || '';
    this.yy = data.yy || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.contg = data.contg || '';
    this.qty = data.qty || '';
    this.amt = data.amt || '';
    this.addyn = data.addyn || '';
    this.attcnt = data.attcnt || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.clsdate = data.clsdate || '';
    this.deljugi = data.deljugi || '';
    this.contgubun = data.contgubun || '';
    this.boryudate = data.boryudate || '';
    this.boryudate2 = data.boryudate2 || '';
    this.boryusayoo = data.boryusayoo || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
