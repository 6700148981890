/**
 * 모델
 * @window w_tb_e451w
 * @category 견적현황
 */
export default class StatusMonthModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 직책
   *
   * **data type** varchar(8)
   */
  readonly rspnm: string;

  /**
   * 1월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon01: string;

  /**
   * 2월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon02: string;

  /**
   * 3월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon03: string;

  /**
   * 4월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon04: string;

  /**
   * 5월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon05: string;

  /**
   * 6월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon06: string;

  /**
   * 7월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon07: string;

  /**
   * 8월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon08: string;

  /**
   * 9월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon09: string;

  /**
   * 10월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon10: string;

  /**
   * 11월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon11: string;

  /**
   * 12월 완료
   *
   * **data type** varchar(8)
   */
  readonly compmon12: string;

  /**
   * 1월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul01: string;

  /**
   * 2월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul02: string;

  /**
   * 3월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul03: string;

  /**
   * 4월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul04: string;

  /**
   * 5월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul05: string;

  /**
   * 6월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul06: string;

  /**
   * 7월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul07: string;

  /**
   * 8월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul08: string;

  /**
   * 9월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul09: string;

  /**
   * 10월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul10: string;

  /**
   * 11월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul11: string;

  /**
   * 12월 성사율
   *
   * **data type** varchar(8)
   */
  readonly compyul12: string;

  /**
   * 01월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang01: string;

  /**
   * 02월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang02: string;

  /**
   * 03월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang03: string;

  /**
   * 04월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang04: string;

  /**
   * 05월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang05: string;

  /**
   * 06월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang06: string;

  /**
   * 07월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang07: string;

  /**
   * 08월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang08: string;

  /**
   * 09월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang09: string;

  /**
   * 10월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang10: string;

  /**
   * 11월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang11: string;

  /**
   * 12월 무상
   *
   * **data type** varchar(8)
   */
  readonly musang12: string;

  /**
   * 01월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon01: string;

  /**
   * 02월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon02: string;

  /**
   * 03월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon03: string;

  /**
   * 04월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon04: string;

  /**
   * 05월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon05: string;

  /**
   * 06월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon06: string;

  /**
   * 07월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon07: string;

  /**
   * 08월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon08: string;

  /**
   * 09월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon09: string;

  /**
   * 10월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon10: string;

  /**
   * 11월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon11: string;

  /**
   * 12월 미매출
   *
   * **data type** varchar(8)
   */
  readonly notmismon12: string;

  /**
   * 01월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon01: string;

  /**
   * 02월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon02: string;

  /**
   * 03월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon03: string;

  /**
   * 04월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon04: string;

  /**
   * 05월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon05: string;

  /**
   * 06월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon06: string;

  /**
   * 07월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon07: string;

  /**
   * 08월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon08: string;

  /**
   * 09월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon09: string;

  /**
   * 10월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon10: string;

  /**
   * 11월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon11: string;

  /**
   * 12월 매출
   *
   * **data type** varchar(8)
   */
  readonly mismon12: string;

  /**
   * 01월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon01: string;

  /**
   * 02월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon02: string;

  /**
   * 03월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon03: string;

  /**
   * 04월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon04: string;

  /**
   * 05월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon05: string;

  /**
   * 06월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon06: string;

  /**
   * 07월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon07: string;

  /**
   * 08월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon08: string;

  /**
   * 09월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon09: string;

  /**
   * 10월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon10: string;

  /**
   * 11월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon11: string;

  /**
   * 12월 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvmon12: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly costperid: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 1월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon01: string;

  /**
   * 2월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon02: string;

  /**
   * 3월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon03: string;

  /**
   * 4월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon04: string;

  /**
   * 5월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon05: string;

  /**
   * 6월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon06: string;

  /**
   * 7월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon07: string;

  /**
   * 8월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon08: string;

  /**
   * 9월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon09: string;

  /**
   * 10월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon10: string;

  /**
   * 11월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon11: string;

  /**
   * 12월 견적
   *
   * **data type** varchar(8)
   */
  readonly mon12: string;

  /**
   * 담당자 코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 팀장
   *
   * **data type** varchar(8)
   */
  readonly reader: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.rspnm = data.rspnm || '';
    this.compmon01 = data.compmon01 || '';
    this.compmon02 = data.compmon02 || '';
    this.compmon03 = data.compmon03 || '';
    this.compmon04 = data.compmon04 || '';
    this.compmon05 = data.compmon05 || '';
    this.compmon06 = data.compmon06 || '';
    this.compmon07 = data.compmon07 || '';
    this.compmon08 = data.compmon08 || '';
    this.compmon09 = data.compmon09 || '';
    this.compmon10 = data.compmon10 || '';
    this.compmon11 = data.compmon11 || '';
    this.compmon12 = data.compmon12 || '';
    this.compyul01 = data.compyul01 || '';
    this.compyul02 = data.compyul02 || '';
    this.compyul03 = data.compyul03 || '';
    this.compyul04 = data.compyul04 || '';
    this.compyul05 = data.compyul05 || '';
    this.compyul06 = data.compyul06 || '';
    this.compyul07 = data.compyul07 || '';
    this.compyul08 = data.compyul08 || '';
    this.compyul09 = data.compyul09 || '';
    this.compyul10 = data.compyul10 || '';
    this.compyul11 = data.compyul11 || '';
    this.compyul12 = data.compyul12 || '';
    this.musang01 = data.musang01 || '';
    this.musang02 = data.musang02 || '';
    this.musang03 = data.musang03 || '';
    this.musang04 = data.musang04 || '';
    this.musang05 = data.musang05 || '';
    this.musang06 = data.musang06 || '';
    this.musang07 = data.musang07 || '';
    this.musang08 = data.musang08 || '';
    this.musang09 = data.musang09 || '';
    this.musang10 = data.musang10 || '';
    this.musang11 = data.musang11 || '';
    this.musang12 = data.musang12 || '';
    this.rcvmon01 = data.rcvmon01 || '';
    this.rcvmon02 = data.rcvmon02 || '';
    this.rcvmon03 = data.rcvmon03 || '';
    this.rcvmon04 = data.rcvmon04 || '';
    this.rcvmon05 = data.rcvmon05 || '';
    this.rcvmon06 = data.rcvmon06 || '';
    this.rcvmon07 = data.rcvmon07 || '';
    this.rcvmon08 = data.rcvmon08 || '';
    this.rcvmon09 = data.rcvmon09 || '';
    this.rcvmon10 = data.rcvmon10 || '';
    this.rcvmon11 = data.rcvmon11 || '';
    this.rcvmon12 = data.rcvmon12 || '';
    this.notmismon01 = data.notmismon01 || '';
    this.notmismon02 = data.notmismon02 || '';
    this.notmismon03 = data.notmismon03 || '';
    this.notmismon04 = data.notmismon04 || '';
    this.notmismon05 = data.notmismon05 || '';
    this.notmismon06 = data.notmismon06 || '';
    this.notmismon07 = data.notmismon07 || '';
    this.notmismon08 = data.notmismon08 || '';
    this.notmismon09 = data.notmismon09 || '';
    this.notmismon10 = data.notmismon10 || '';
    this.notmismon11 = data.notmismon11 || '';
    this.notmismon12 = data.notmismon12 || '';
    this.mismon01 = data.mismon01 || '';
    this.mismon02 = data.mismon02 || '';
    this.mismon03 = data.mismon03 || '';
    this.mismon04 = data.mismon04 || '';
    this.mismon05 = data.mismon05 || '';
    this.mismon06 = data.mismon06 || '';
    this.mismon07 = data.mismon07 || '';
    this.mismon08 = data.mismon08 || '';
    this.mismon09 = data.mismon09 || '';
    this.mismon10 = data.mismon10 || '';
    this.mismon11 = data.mismon11 || '';
    this.mismon12 = data.mismon12 || '';
    this.costperid = data.costperid || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.reader = data.reader || '';
  }
}
