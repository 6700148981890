import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  IFrame,
  LayoutTitle,
  SearchLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { MonthMoney } from './MonthMoney';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ca642w_12
 * @category 월시재현황
 */
export const MonthMoneyTemplate: React.FC<TemplateProps<MonthMoney>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'mon',
          text: '월',
          width: 10,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              합 계
            </FlexLayout>
          ),
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="####-##"
              value={x.mon}
            />
          ),
        },
        {
          id: 'beamt',
          text: '전월잔액',
          width: 18,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(String(scope.beamts))}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.beamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'rcvamt',
          text: '입금금액',
          width: 18,
          trail: (data) => {
            const sumRcvamt = Sum.all(data, 'rcvamt');
            return (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(sumRcvamt)}
              </FlexLayout>
            );
          },
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
              style={{ color: 'var(--color-blue)' }}
            >
              {Format.number(x.rcvamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'sndamt',
          text: '지급금액',
          width: 18,
          trail: (data) => {
            const sumSndamt = Sum.all(data, 'sndamt');
            return (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(sumSndamt)}
              </FlexLayout>
            );
          },
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              {Format.number(x.sndamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'addamt',
          text: '흑자',
          width: 18,
          trail: (data) => {
            const sumAddamt = Sum.all(data, 'addamt');
            return (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(sumAddamt)}
              </FlexLayout>
            );
          },
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
              style={{ color: '#8106AA' }}
            >
              {Format.number(x.addamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'resuamt',
          text: '잔액',
          width: 18,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Format.number(String(scope.resuamts))}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.resuamt)}
            </FlexLayout>
          ),
        },
      ]}
      data={scope.state.data}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    />

    <FlexLayout>
      <IFrame src={scope.graph} />
    </FlexLayout>
    </FlexLayout>

    <FlexLayout
      isVertical={true}
      weight={1.3}
    >
      <LayoutTitle>사업장별 상세내역</LayoutTitle>
      <FlexLayout>
        <GridLayout
          ref={scope.grid2}
          headerHeight={76}
          header={[
            {
              id: 'spjangnm',
              text: '사업장명',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt01',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    01월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt01 = Sum.all(data, 'rcvamt01');
                const sumSndamt01 = Sum.all(data, 'sndamt01');
                const sumAddamt01 = Sum.all(data, 'addamt01');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt01)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt01)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt01)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt01)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt02',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    02월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt02 = Sum.all(data, 'rcvamt02');
                const sumSndamt02 = Sum.all(data, 'sndamt02');
                const sumAddamt02 = Sum.all(data, 'addamt02');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt02)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt02)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt02)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt02)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt03',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    03월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt03 = Sum.all(data, 'rcvamt03');
                const sumSndamt03 = Sum.all(data, 'sndamt03');
                const sumAddamt03 = Sum.all(data, 'addamt03');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt03)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt03)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt03)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt03)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt04',
              text: (
                <FlexLayout
                  isVertical={true}
                >
                  <FlexLayout align="center"style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    04월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt04 = Sum.all(data, 'rcvamt04');
                const sumSndamt04 = Sum.all(data, 'sndamt04');
                const sumAddamt04 = Sum.all(data, 'addamt04');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt04)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt04)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt04)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt04)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt05',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    05월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt05 = Sum.all(data, 'rcvamt05');
                const sumSndamt05 = Sum.all(data, 'sndamt05');
                const sumAddamt05 = Sum.all(data, 'addamt05');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt05)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt05)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt05)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt05)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt06',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    06월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt06 = Sum.all(data, 'rcvamt06');
                const sumSndamt06 = Sum.all(data, 'sndamt06');
                const sumAddamt06 = Sum.all(data, 'addamt06');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt06)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt06)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt06)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt06)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt07',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    07월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt07 = Sum.all(data, 'rcvamt07');
                const sumSndamt07 = Sum.all(data, 'sndamt07');
                const sumAddamt07 = Sum.all(data, 'addamt07');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt07)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt07)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt07)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt07)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt08',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    08월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt08 = Sum.all(data, 'rcvamt08');
                const sumSndamt08 = Sum.all(data, 'sndamt08');
                const sumAddamt08 = Sum.all(data, 'addamt08');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt08)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt08)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt08)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt08)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt09',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    09월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt9 = Sum.all(data, 'rcvamt9');
                const sumSndamt9 = Sum.all(data, 'sndamt9');
                const sumAddamt9 = Sum.all(data, 'addamt9');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt9)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt9)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt9)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt09)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt10',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    10월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt10 = Sum.all(data, 'rcvamt10');
                const sumSndamt10 = Sum.all(data, 'sndamt10');
                const sumAddamt10 = Sum.all(data, 'addamt10');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt10)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt10)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt10)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt10)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt11',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    11월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt11 = Sum.all(data, 'rcvamt11');
                const sumSndamt11 = Sum.all(data, 'sndamt11');
                const sumAddamt11 = Sum.all(data, 'addamt11');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt11)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt11)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt11)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt11)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt12',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C', padding: '8px 0 3px 0' }}
                  >
                    12월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6.5,
              trail: (data) => {
                const sumRcvamt12 = Sum.all(data, 'rcvamt12');
                const sumSndamt12 = Sum.all(data, 'sndamt12');
                const sumAddamt12 = Sum.all(data, 'addamt12');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamt12)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamt12)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamt12)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamt12)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamttot',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)', padding: '8px 0 3px 0' }}
                  >
                    합계
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입금금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    지급금액
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ paddingBottom: 8 }}
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 11,
              trail: (data) => {
                const sumRcvamttot = Sum.all(data, 'rcvamttot');
                const sumSndamttot = Sum.all(data, 'sndamttot');
                const sumAddamttot = Sum.all(data, 'addamttot');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumRcvamttot)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)', paddingRight: 8 }}
                    >
                      {Format.number(sumSndamttot)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ paddingBottom: 8, paddingRight: 8 }}
                    >
                      {Format.number(sumAddamttot)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  style={{ backgroundColor: '#B2EBF4' }}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingTop: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.rcvamttot)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)', paddingRight: 8 }}
                  >
                    {Format.number(x.sndamttot)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ paddingBottom: 8, paddingRight: 8 }}
                  >
                    {Format.number(x.addamttot)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data2}
          infinityHandler={scope.infinity2}
          rowHeight={68}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
