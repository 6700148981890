import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Maintenance } from './Maintenance';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

const taxclss = [
  { value: '1', remark: '포함' },
  { value: '0', remark: '별도' },
];

const recivstatuss = [
  { value: 'A', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'R', remark: '열람' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
];

const taxgubuns = [
  { value: '01', remark: '과세' },
  { value: '02', remark: '영세' },
  { value: '03', remark: '면세' },
];

const jirogubuns = [
  { value: '1', remark: '발행' },
  { value: '0', remark: '미발행' },
];

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면
 * @window w_tb_e050
 * @category 유지관리매출생성
 */
export const MaintenanceTemplate: React.FC<TemplateProps<Maintenance>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <label style={{ color: '#7A297B' }}>* 전체삭제 선택시 국세청최종접수, 지로가 출력된 내역은 자동 선택되지 않습니다.</label>
      <FlexLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ marginTop: 10 }}>
        <SearchLayout>
          <FlexLayout size={220}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => {
                setData({ yearmon: value },
                  () => scope.onRetrieveEvent());
              }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>회사구분</span>
            <TextBox
              value={scope.state.wkactcd}
              textAlign="center"
              onChange={(value) => setData({ wkactcd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_E018_1',
                {},
                true,
                (item) => {
                  setData({
                    wkactcd: item.cd,
                    wkactnm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.wkactnm}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="거래처, 현장으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>구분</span>
            <ComboBox
              value={scope.state.searchGubun}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.gubun?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
              onSelect={(option) => setData({ searchGubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>매출분류</span>
            <ComboBox
              value={scope.state.billgubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '매출' },
                { value: '0', remark: '비매출' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ billgubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onSalesEvent()}>
            <div style={{ width: 80 }}>매출생성</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={scope.state.pushTotalCheck}
                  onChange={async (value) => scope
                    .updateCheckAllToggle(value)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
            width: 50,
            render: (x, rowUpdate) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={x.chk === '1'}
                  onChange={(value) => {
                    rowUpdate({ chk: value ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'saupnum',
            text: '사업자등록번호',
            width: 120,
            render: (x) => (
              <FormatTextBox
                format="###-##-#####"
                textAlign="center"
                value={x.saupnum}
              />
            ),
          },
          {
            id: 'cltnm',
            text: '거래처명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.cltnm}
              </FlexLayout>
            ),
          },
          {
            id: 'misdate',
            text: '매출일자',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.misdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'misnum',
            text: '번호',
            width: 70,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
               건수 {scope.state.total}
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '매출분류',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'taxcls',
            text: '부가세여부',
            width: 70,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
               합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {taxclss.filter((y) => y.value === x.taxcls)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'contamt',
            text: '계약금액',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.contamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.contamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'amt',
            text: '매출액',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.amt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.amt)}
              </FlexLayout>
            ),
          },
          {
            id: 'addamt',
            text: '부가세',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.addamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.addamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '합계',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.misamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.misamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'yyyymm',
            text: '귀속년월',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##"
                textAlign="center"
                value={x.yyyymm}
              />
            ),
          },
          {
            id: 'billkind',
            text: '계산서발행',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {jirogubuns.filter((y) => y.value === x.billkind)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'recivstatus',
            text: '상태',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  color: x.recivstatus.trim() === 'F'
                    // eslint-disable-next-line no-nested-ternary
                    ? 'var(--color-blue)' : x.recivstatus.trim() === 'FF'
                      ? '#7A297B' : x.recivstatus.trim() === 'B'
                        ? 'var(--color-red)' : '',
                }}>
                {recivstatuss.filter((y) => y.value === x.recivstatus.trim())[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'jirogubun',
            text: '지로구분',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {jirogubuns.filter((y) => y.value === x.jirogubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'prtchk',
            text: '지로출력',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  color: x.prtchk === '1'
                    ? 'var(--color-blue)' : '',
                }}
              >
                {x.prtchk === '1' ? '출력' : ''}
              </FlexLayout>
            ),
          },
          {
            id: 'pubnum',
            text: '지로번호',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="######-####"
                textAlign="center"
                value={x.pubnum}
              />
            ),
          },
          {
            id: 'taxgubun',
            text: '과세구분',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {taxgubuns.filter((y) => y.value === x.taxgubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '적요',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      />
    </FlexLayout>
  );
};
