import { computed } from 'mobx';
/**
 * 모델
 * @window w_tb_pb300_basic
 * @category 급여항목등록
 */
export class BenefitItemModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 급여항목코드
   *
   * **data type** varchar(4)
   */
  pay_cd: string;

  /**
   * 급여항목명
   *
   * **data type** varchar(255)
   */
  pay_nm: string;

  /**
   * 비과세
   *
   * **data type** varchar(1)
   */
  taxflag: string;

  /**
   * 직책수당
   *
   * **data type** varchar(1)
   */
  reader: string;

  /**
   * 고정구분
   *
   * **data type** varchar(1)
   */
  gubun: string;

  /**
   * 여부
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(32766)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  flag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.pay_cd = data.pay_cd || '';
    this.pay_nm = data.pay_nm || '';
    this.taxflag = data.taxflag || '';
    this.flag = data.flag || '';
    this.reader = data.reader || '';
    this.gubun = data.gubun || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
