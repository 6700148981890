import * as React from 'react';
import { ReactNode } from 'react';
import { action } from 'mobx';
import { IoImage } from 'react-icons/all';
import { inject } from 'mobx-react';
import style from './ImageView.module.scss';
import { JoinClassName } from '../../../utils/string';
import { FlexLayout } from '../../layout';
import { Button } from '../Button';
import { ModalStore } from '../../../stores';

interface ImageViewActions {
  onLoad?: (key: any) => any;
}

interface ImageViewProps extends ImageViewActions {
  modalStore?: ModalStore;
  src?: string | ArrayBuffer;
  text?: any;
  textAlign?: string;
  className?: any;
  style?: any;
  footer?: ReactNode;
  onImageUpdate?: (b64: string) => void;
}

interface ImageViewState {
  isLoaded: boolean;
  data?: string | ArrayBuffer;
}

@inject('modalStore')
export class ImageView extends React.Component<ImageViewProps, ImageViewState> {
  blob?: Blob;

  constructor(props: ImageViewProps, context: any) {
    super(props, context);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount(): void {
    this.update();
  }

  componentDidUpdate(
    prevProps: Readonly<ImageViewProps>,
    _prevState: Readonly<ImageViewState>,
    _snapshot?: any,
  ): void {
    if (prevProps.src !== this.props.src) {
      this.update();
    }
  }

  componentWillUnmount() {
    if (typeof this.props.src === 'object' && typeof this.state.data === 'string') {
      URL.revokeObjectURL(this.state.data);
    }

    this.blob = undefined;
  }

  @action
  update() {
    const { src } = this.props;
    if (!src) {
      this.setState({
        isLoaded: false,
      });
      return;
    }

    if (typeof src === 'object') {
      this.blob = new Blob([src], {
        type: 'image/jpeg',
      });

      this.setState({
        isLoaded: true,
        data: URL.createObjectURL(this.blob),
      });
    } else if (src.startsWith('http')) {
      const image = new Image();
      image.onload = () => {
        this.setState({
          isLoaded: true,
          data: src,
        });
      };

      image.src = src;
    } else if (this.props.src) {
      this.setState({
        isLoaded: true,
        data: src,
      });
    }
  }

  onImageUpdate = (b64: string) => {
    this.props.onImageUpdate && this.props.onImageUpdate(b64);
  }

  render() {
    return (
      <div
        className={JoinClassName.make([
          style.container,
          this.props.className,
        ])}
      >
        {this.state.isLoaded ? (
          <div
            className={style.contents}
            style={this.props.style}
          >
            {this.props.text
              && !this.props.textAlign
              && this.props.textAlign?.toLowerCase() === 'top' && (
                <label>{this.props.text}</label>
            )}
            <div
              className={style.image}
              style={{
                backgroundImage: `url('${this.state.data}')`,
              }}
            />
            {this.props.text
              && (!this.props.textAlign
                || this.props.textAlign?.toLowerCase() === 'bottom') && (
                <label>{this.props.text}</label>
            )}
            {this.props.onImageUpdate && <div className={style.edit}>
              <Button
                isIcon={true}
                onClick={() => this.props.modalStore?.openImageEditor(this.state.data, this.onImageUpdate)}
              >
                <IoImage size={22} />
              </Button>
            </div>}
          </div>
        ) : (
          <FlexLayout
            className={style.nothing}
            justify="center"
            align="center"
          >
            <span>사진이 없습니다.</span>
          </FlexLayout>
        )}
        {this.props.footer && (
          <FlexLayout
            className={style.footer}
            size={42}
          >
            {this.props.footer}
          </FlexLayout>
        )}
      </div>
    );
  }
}
