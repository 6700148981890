import { computed } from 'mobx';

/**
 * 모델
 * @window  w_tb_ca642w_09
 * @category 현금출납장
 */
export class CashBookModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 지급처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 발행년월
   *
   * **data type** varchar(8)
   */
  mismon: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 적요
   *
   * **data type** varchar(303)
   */
  remark: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 잔액
   *
   * **data type** long
   */
  resuamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.misdate = data.misdate || '';
    this.remark = data.remark || '';
    this.mismon = data.mismon || '';
    this.misamt = data.misamt || '';
    this.misnum = data.misnum || '';
    this.mijamt = data.mijamt || '';
    this.resuamt = data.resuamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
