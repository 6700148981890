import { computed } from 'mobx';

/**
 * 사급품목 모델
 * @window w_tb_da450
 * @category 설치품목등록
 */
export class CompanyItemModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 관급,사급구분
   *
   * **data type** varchar(1)
   */
  contflag: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 품명코드
   *
   * **data type** varchar(50)
   */
  pcode: string;

  /**
   * 규격
   *
   * **data type** varchar(255)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** varchar(50)
   */
  punit: string;

  /**
   * 품명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 단가
   *
   * **data type** float
   */
  uamt: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력일
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  appdate: string;

  /**
   * 모름
   *
   * **data type** varchar(3)
   */
  appgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(10)
   */
  appperid: string;

  /**
   * 모름
   *
   * **data type** varchar(255)
   */
  appremark: string;

  /**
   * 모름
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 단가연결품목코드
   *
   * **data type** varchar(50)
   */
  lk_pcode: string;

  /**
   * 단가연결구분
   *
   * **data type** varchar(1)
   */
  lk_gubun: string;

  /**
   * 연결품명
   *
   * **data type** varchar(255)
   */
  lk_pname: string;

  /**
   * 연결규격
   *
   * **data type** varchar(255)
   */
  lk_psize: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.contflag = data.contflag || '';
    this.seq = data.seq || '';
    this.pcode = data.pcode || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.pname = data.pname || '';
    this.uamt = data.uamt || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.appnum = data.appnum || '';
    this.lk_pcode = data.lk_pcode || '';
    this.lk_gubun = data.lk_gubun || '';
    this.lk_pname = data.lk_pname || '';
    this.lk_psize = data.lk_psize || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
