import { computed } from 'mobx';

/**
 * 해지사유 모델
 * @window w_tb_e015
 * @category 유지보수관리코드
 */
export class CancelModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(3)
   */
  readonly outcd: string;

  /**
   * 해지사유명
   *
   * **data type** varchar(60)
   */
  readonly outnm: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  readonly useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  readonly remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;


  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.outcd = data.outcd || '';
    this.outnm = data.outnm || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
