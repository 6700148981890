import * as React from 'react';
import { RiFolder2Line } from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  CircleBadge,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  ImageView,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Enrollment } from './Enrollment';
import { EnrollmentTab } from './Enrollment.tab';
import { SearchDateRange } from '../../../../components/search';
import EnrollmentModel from './models/EnrollmentModel';
import { EnrollmentGridHeader } from './headers/EnrollmentGridHeader';
import { EnrollmentEstimateTableHeader } from './headers/EnrollmentEstimateTableHeader';
import { SearchBinding, SearchBindingProjects } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { EnrollmentPullGridHeader } from './headers/EnrollmentPullGridHeader';


const Receflags = [
  { value: '0', remark: '미수령' },
  { value: '1', remark: '어플수령' },
  { value: '2', remark: '파일수령' },
  { value: '3', remark: '문서수령' },
];


/**
 * 화면
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  const content = scope.state?.content;
  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new EnrollmentModel({
      ...content,
      ...data,
    }, content.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => setData({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처, 공사명, 처리상세로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={scope.state.Gubuns?.map((x) => new ComboBoxModel(x.artcd, x.artnm))}
            isDisabledTrackingStateChange={true}
            onSelect={(option) => setData({ gubun: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout size={150}>
          <span>완료여부</span>
          <ComboBox
            value={scope.state.compyn}
            data={[
              { value: '%', remark: '전체' },
              { value: '4', remark: '미완료' },
              { value: '1', remark: '완료' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ compyn: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <Button onClick={() => scope.openEstimateModal()}>
          <FlexLayout>
            <FlexLayout>확인된 견적서</FlexLayout>
            <CircleBadge>
              <span
                style={{
                  backgroundColor: 'var(--color-red)',
                  borderRadius: 24,
                  color: '#FFF',
                  fontWeight: 300,
                  padding: '0 3px',
                  fontSize: 11,
                  transform: 'translate(4px, 0px)',
                }}
              >
                {scope.state.e451_ok}
              </span>
            </CircleBadge>
          </FlexLayout>
        </Button>
      </SearchLayout>

      <FlexLayout size={72 * 6}>
        <FlexLayout size={420}>
          <GridLayout
            ref={scope.grid}
            header={EnrollmentGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            onRowClick={(row, index) => scope.onRowFocusEvent(row, index)}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={5}
          disabled={!content?.custcd}
          disabledText="신규로 추가하거나 현장을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              size={180}
              title="공사일자"
              isNeed={true}
            >
              <DateTextBox
                format="####-##-##"
                textAlign="center"
                value={content?.compdate || '19700101'}
                onChange={(v) => setContent({ compdate: v }, () => scope.headerItemChanged('compdate', v))}
                readonly={!content?.isNew}
              />

              <TextBox
                textAlign="center"
                value={content.compnum}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="공사구분" size={130}>
              <ComboBox
                value={content?.gubun}
                textAlign="center"
                data={scope.state.compGubuns?.map((x) => new ComboBoxModel(x.artcd, x.artnm))}
                onSelect={(option) => setContent({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox title="매출분류">
              <OptionBox
                value={content.billgubun}
                data={[
                  { value: '1', remark: '매출' },
                  { value: '0', remark: '미매출' },
                ]}
                onChange={(item) => setContent({ billgubun: item.value })}
              />
            </LabelBox>

            <LabelBox title="과세구분">
              <ComboBox
                value={content.taxgubun}
                textAlign="center"
                data={scope.state.taxGubuns.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
                onSelect={(option) => setContent({ taxgubun: option.value })}
              />
            </LabelBox>

            <LabelBox title="계산서발행">
              <OptionBox
                value={content.billkind}
                data={[
                  { value: '1', remark: '발행' },
                  { value: '0', remark: '미발행' },
                ]}
                onChange={(item) => setContent({ billkind: item.value })}
              />
            </LabelBox>

            <LabelBox
              title="회사구분"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={content.wkactcd}
                textAlign="center"
                onChange={(value) => setContent({ wkactcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E018_1',
                  {},
                  true,
                  (item) => {
                    setContent({
                      wkactcd: item.cd,
                      wkactnm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={content.wkactnm}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="주처리자"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content.compperid}
                textAlign="center"
                readonly={content?.appgubun !== ''}
                onChange={(value) => setContent({ compperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      compperid: item.cd,
                      comppernm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={content.comppernm}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="주계약자"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content.contperid}
                textAlign="center"
                readonly={content?.appgubun !== ''}
                onChange={(value) => setContent({ contperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      contperid: item.cd,
                      contpernm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={content.contpernm}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              style={Global.STYLE_COLOR_RED}
              isNeed={true}
              weight={3}
            >
              <TextBox
                value={content.actcd}
                textAlign="center"
                onChange={(value) => setContent({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setContent({
                      actcd: item.cd,
                      actnm: item.cdnm,
                      cltcd: item.uv_arg1,
                      cltnm: item.uv_arg3,
                    });
                    scope.headerItemChanged('actcd', item.cd);
                  },
                )}
              />

              <TextBox
                value={content.actnm}
                weight={2}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="고객명"
              style={Global.STYLE_COLOR_RED}
              weight={3}
            >
              <TextBox
                value={content.cltcd}
                textAlign="center"
                onChange={(value) => setContent({ cltcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  {},
                  true,
                  (item) => {
                    setContent({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={content.cltnm}
                weight={2}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="처리자2"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content.compperid2}
                textAlign="center"
                readonly={content?.appgubun !== ''}
                onChange={(value) => setContent({ compperid2: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      compperid2: item.cd,
                      comppernm2: item.cdnm,
                    });
                    scope.headerItemChanged('compperid2', item.cd);
                  },
                )}
              />

              <TextBox
                value={content.comppernm2}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="계약자2"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content.contperid2}
                textAlign="center"
                readonly={content?.appgubun !== ''}
                onChange={(value) => setContent({ contperid2: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      contperid2: item.cd,
                      contpernm2: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={content.contpernm2}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="공사명"
              isNeed={true}
              weight={3}
            >
              <TextBox
                value={content.offinm}
                onChange={(v) => setContent({ offinm: v })}
              />
            </LabelBox>

            <LabelBox
              title="프로젝트번호"
              style={{ color: 'var(--color-red)' }}
              weight={3}
            >
              <TextBox
                textAlign="center"
                value={content.projno}
                readonly={true}
                onChange={(value) => setContent({ projno: value })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setContent({
                      projno: item.projno,
                      projnm: item.projnm,
                      actcd: item.actcd,
                      actnm: item.actnm,
                    });
                    scope.headerItemChanged('projno', item.projno);
                  },
                )}
              />

              <TextBox
                value={content.projnm}
                weight={2}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="처리자3"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content.compperid3}
                textAlign="center"
                readonly={content?.appgubun !== ''}
                onChange={(value) => setContent({ compperid3: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      compperid3: item.cd,
                      comppernm3: item.cdnm,
                    });
                    scope.headerItemChanged('compperid3', item.cd);
                  },
                )}
              />

              <TextBox
                value={content.comppernm3}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="계약자3"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content.contperid3}
                textAlign="center"
                readonly={content?.appgubun !== ''}
                onChange={(value) => setContent({ contperid3: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      contperid3: item.cd,
                      contpernm3: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                value={content.contpernm3}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="부가세구분">
              <OptionBox
                value={content.taxcls}
                data={[
                  { value: '1', remark: '포함' },
                  { value: '0', remark: '별도' },
                ]}
                onChange={(item) => setContent({ taxcls: item.value },
                  () => scope.headerItemChanged('taxcls', item.value.toString()))}
              />
            </LabelBox>

            <LabelBox title="공급가액">
              <FormatNumberTextBox
                textAlign="right"
                readonly={true}
                value={content.samt}
                onChange={(v) => setContent({ samt: v },
                  () => scope.headerItemChanged('samt', v))}
              />
            </LabelBox>

            <LabelBox title="부가세액">
              <FormatNumberTextBox
                textAlign="right"
                readonly={true}
                value={content.tamt}
                onChange={(v) => setContent({ tamt: v },
                  () => scope.headerItemChanged('tamt', v))}
              />
            </LabelBox>

            <LabelBox title="공사금액">
              <FormatNumberTextBox
                textAlign="right"
                readonly={true}
                value={content.camt}
                onChange={(v) => setContent({ camt: v },
                  () => scope.headerItemChanged('camt', v))}
              />
            </LabelBox>

            <LabelBox title="할인율">
              <FormatTextBox
                format="### %"
                textAlign="center"
                value={content.saleyul}
                onChange={(v) => setContent({ saleyul: v },
                  () => scope.headerItemChanged('saleyul', v))}
              />
            </LabelBox>

            <LabelBox title="할인(최종)금액">
              <FormatNumberTextBox
                textAlign="right"
                value={content.saleamt}
                onChange={(v) => setContent({ saleamt: v },
                  () => scope.headerItemChanged('saleamt', v))}
              />
            </LabelBox>

            <LabelBox title="매출여부">
              <ComboBox
                value={content.compflag}
                textAlign="center"
                style={{
                  color: content.compflag === '0'
                    ? 'var(--color-red)' : 'var(--color-blue)',
                }}
                data={[
                  { value: '0', remark: '미처리' },
                  { value: '1', remark: '처리' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setContent({ compflag: option.value },
                  () => scope.headerItemChanged('compflag', option.value))}
              />
            </LabelBox>

            <LabelBox title="매출일자/번호">
              <DateTextBox
                format="####-##-##"
                value={content.misdate}
                textAlign="center"
                readonly={true}
              />

              <TextBox
                value={content.misnum}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="처리상세"
              weight={3}
            >
              <TextBox
                value={content.compremark}
                onChange={(v) => setContent({ compremark: v })}
              />
            </LabelBox>

            <LabelBox title="공사시작일">
              <DateTextBox
                format="####-##-##"
                value={content.stdate}
                textAlign="center"
                onChange={(v) => setContent({ stdate: v })}
              />
            </LabelBox>

            <LabelBox title="공사종료일">
              <DateTextBox
                format="####-##-##"
                value={content.enddate}
                textAlign="center"
                onChange={(v) => setContent({ enddate: v })}
              />
            </LabelBox>

            <LabelBox title="싸인여부(견적)">
              <FlexLayout
                align="center"
                justify="center"
              >
                담당&nbsp;
                <span style={content.ks1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}>
                  {content.ks1flag === '1' ? 'O' : 'X'}
                </span>
              </FlexLayout>

              <FlexLayout
                align="center"
                justify="center"
              >
                고객&nbsp;
                <span style={content.s1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}>
                  {content.s1flag === '1' ? 'O' : 'X'}
                </span>
              </FlexLayout>
            </LabelBox>

            <LabelBox title="싸인여부(부품)">
              <FlexLayout
                align="center"
                justify="center"
              >
                담당&nbsp;
                <span style={content.ks2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}>
                  {content.ks2flag === '1' ? 'O' : 'X'}
                </span>
              </FlexLayout>

              <FlexLayout
                align="center"
                justify="center"
              >
                고객&nbsp;
                <span style={content.s2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}>
                  {content.s2flag === '1' ? 'O' : 'X'}
                </span>
              </FlexLayout>
            </LabelBox>

            <LabelBox
              title={`확인서수령여부 ${content.attflag === '1' ? 'O' : 'X'}`}
              style={content.attflag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.openAttachment()}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <ComboBox
                value={content.receflag}
                textAlign="center"
                style={content.receflag === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
                data={Receflags.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setContent({ receflag: option.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="품명"
              weight={3}
            >
              <TextBox
                value={content.remark}
                onChange={(v) => setContent({ remark: v })}
              />
            </LabelBox>

            <LabelBox
              title="견적담당전달내용(견적특기사항)"
              weight={3}
            >
              <TextBox
                value={content.e451remark}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="완료여부(미완료시 수주)">
              <ComboBox
                value={content.compyn}
                textAlign="center"
                style={content.compyn === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
                data={[
                  { value: '1', remark: '완료' },
                  { value: '4', remark: '미완료(수주)' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setContent({ compyn: option.value })}
              />
            </LabelBox>

            <LabelBox
              title="작성자명"
            >
              <TextBox
                value={content.inpernm}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        weight={5}
        disabled={!content?.custcd}
        disabledText="신규로 추가하거나 현장을 선택해주세요"
      >
        <TabLayout
          scope={scope}
          tabs={EnrollmentTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>


      {/* 견적불러오기 */}
      <ModalView
        width={1100}
        height={700}
        isVisible={scope.state.isVisibleEstimate}
        onClose={() => setData({ isVisibleEstimate: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.modalSearchQuery}
                onChange={(value) => setData({ modalSearchQuery: value })}
                onEnter={() => scope.retreiveEstimateModal()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span style={{ color: 'var(--color-blue)' }}>
                구분이 공사일시 이미 공사등록한내역입니다, 견적서에만 싸인이 된경우에는 계속 목록에 나타납니다
                완료가 된 내역은 공사등록에서 완료여부를 완료해주세요
              </span>
            </FlexLayout>

            <Button onClick={() => scope.removeModalEstimateItem()}>견적내역삭제</Button>
          </SearchLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout>
              <TableLayout
                ref={scope.tableEsimate}
                data={scope.state.dataModalEstimate}
                header={EnrollmentEstimateTableHeader(scope)}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowFocusEvent={(item, index) => scope.onEstimateRowFocusEvent(item, index)}
                onChange={(rows) => setData({ dataModalEstimate: rows })}
              />
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
              <LabelBox title="담당자">
                <TextBox
                  textAlign="center"
                  value={scope.tableEstimateFocus?.costpernm}
                  readonly={true}
                  transparent={true}
                />
              </LabelBox>

              <LabelBox title="고객">
                <TextBox
                  textAlign="center"
                  value={scope.tableEstimateFocus?.pernm}
                  readonly={true}
                  transparent={true}
                />
              </LabelBox>

              <LabelBox
                title="고객"
                weight={3}
              >
                <TextBox
                  textAlign="center"
                  value={scope.tableEstimateFocus?.remark}
                  readonly={true}
                  transparent={true}
                  isMultiline={true}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={180}>
            <FlexLayout isVertical={true}>
              <LayoutTitle>견적서</LayoutTitle>

              <FlexLayout>
                <FlexLayout isVertical={true}>
                  <LayoutTitle>담당싸인</LayoutTitle>
                  <ImageView
                    src={scope.state.img_ks1}
                  />
                  <TextBox
                      textAlign="center"
                      value={scope.tableEstimateFocus?.ks1indate || '0000-00-00'}
                      readonly={true}
                  />
                </FlexLayout>

                <FlexLayout isVertical={true}>
                  <LayoutTitle>고객싸인</LayoutTitle>
                  <ImageView
                    src={scope.state.img_s1}
                  />
                  <TextBox
                      textAlign="center"
                      value={scope.tableEstimateFocus?.s1indate || '0000-00-00'}
                      readonly={true}
                  />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <LayoutTitle>부품교체확인서</LayoutTitle>

              <FlexLayout>
                <FlexLayout isVertical={true}>
                  <LayoutTitle>담당싸인</LayoutTitle>
                  <ImageView
                    src={scope.state.img_ks2}
                  />
                  <TextBox
                      textAlign="center"
                      value={scope.tableEstimateFocus?.ks2indate || '0000-00-00'}
                      readonly={true}
                  />
                </FlexLayout>

                <FlexLayout isVertical={true}>
                  <LayoutTitle>고객싸인</LayoutTitle>
                  <ImageView
                    src={scope.state.img_s2}
                  />
                  <TextBox
                      textAlign="center"
                      value={scope.tableEstimateFocus?.s2indate || '0000-00-00'}
                      readonly={true}
                  />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <LayoutTitle>공사완료확인서</LayoutTitle>

              <FlexLayout>
                <FlexLayout isVertical={true}>
                  <LayoutTitle>담당싸인</LayoutTitle>
                  <ImageView
                    src={scope.state.img_ks3}
                  />
                  <TextBox
                      textAlign="center"
                      value={scope.tableEstimateFocus?.ks3indate || '0000-00-00'}
                      readonly={true}
                  />
                </FlexLayout>

                <FlexLayout isVertical={true}>
                  <LayoutTitle>고객싸인</LayoutTitle>
                  <ImageView
                    src={scope.state.img_s3}
                  />
                  <TextBox
                      textAlign="center"
                      value={scope.tableEstimateFocus?.s3indate || '0000-00-00'}
                      readonly={true}
                  />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <div>
              <span>일자</span>
              <span>
                <DateTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={scope.state.modalDate}
                  onChange={(v) => setData({ modalDate: v })}
                />
              </span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.saveModalEstimate()}>확인</Button>
              <Button onClick={() => setData({ isVisibleEstimate: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>


      {/* 첨부파일 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            appnum={`E471-${scope.state.content?.compdate}${scope.state.content?.compnum}`}
            appnm="공사등록"
            isEnabledTitle={true}
            isEnabledPreview={true}
            onChangeCount={async (count) => {
              if (count > 0) {
                setContent({ attflag: '1' });
              } else {
                setContent({ attflag: '0' });
              }
            }}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 견적 불러오기 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisiblePull}
        onClose={() => setData({ isVisiblePull: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SubHeader>
            <div>
              <span>견적 불러오기</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.donePull()}>확인</Button>
              <Button onClick={() => setData({ isVisiblePull: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>

          <GridLayout
            header={EnrollmentPullGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinityPull}
            data={scope.state.dataPull}
            onRowClick={(_, index) => scope.onPullRowFocusEvent(index)}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
