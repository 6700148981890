import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { MobileTemplate } from './Mobile.template';
import { MobileModel } from './Mobile.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

interface MobileState {
  data: Array<MobileModel>;
  saveData: Array<MobileModel>;
  focused: MobileModel;
  searchQuery?: string;
}

/**
 * 컨트롤러
 * @window w_tb_xappnum
 * @category 어플사용자등록
 */
export class Mobile extends PageComponent<PageProps, MobileState>
  implements PageToolEvents {
  updatedRows?: Array<MobileModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      data: [],
      searchQuery: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      { as_nm: this.state.searchQuery },
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new MobileModel(x))],
        }, () => this.table.current?.update(false));
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        this.state.data.length > 0 && this.table.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });
    if (!autoLoad) return;
    await this.infinity?.retrieve();
    await this.table.current?.update(true);
    this.state.data.length > 0 && this.table.current?.setFocus(0, 0);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [...this.state.data, new MobileModel(data, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.data.length - 1, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    const behandphone = {
      behandphone: this.state.focused.handphone,
    };
    const saveData = this.updatedRows;
    const newSaveData: MobileModel[] = [];

    saveData?.forEach((x: any) => newSaveData.push(new MobileModel({
      ...x,
      ...behandphone,
    })));
    if (await api.save({
      items: newSaveData,
    }, this.state.focused.isNew)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.pernm} - ${this.state.focused?.handphone}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  onRowFocusEvent(item: MobileModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<MobileModel>, updatedRows: Array<MobileModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <MobileTemplate
        scope={this}
        update={(change) => {
          this.setState({
            ...this.state,
            ...change,
          });
        }}
      />
    );
  }
}
