import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { SalesNationalTaxService } from './SalesNationalTaxService';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

const recivstatuss = [
  { value: 'A', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'R', remark: '열람' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
];

/**
 * 화면
 * @window w_td_da023_import
 * @category 매출등록[국세청]
 */
export const SalesNationalTaxServiceTemplate: React.FC<TemplateProps<SalesNationalTaxService>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onSubExcelEvent()}>
         <div style={{ width: 80 }}>EXCEL 자료</div>
        </Button>

        <Button onClick={() => scope.onNationalTaxEvent()}>
          <div style={{ width: 80 }}>국세청자료</div>
        </Button>

        <Button onClick={() => scope.onSalesEvent()}>
          <div style={{ width: 80 }}>매출처리</div>
        </Button>

        <Button onClick={() => scope.onSalesCancelEvent()}>
          <div style={{ width: 80 }}>매출취소</div>
        </Button>
      </SearchLayout>
    </FlexLayout>

    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'delflag',
          text: (
            <FlexLayout
              justify="center"
              align="center"
            >
              삭제
              <CheckBox
                noMargin
                value={scope.state.pushTotalCheck}
                onChange={async (value) => scope
                  .updateCheckAllToggle(value, 'delflag')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
          width: 50,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              합계
            </FlexLayout>
          ),
          render: (x, rowUpdate) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              <CheckBox
                noMargin
                value={x.delflag === '1'}
                readonly={x.misflag2 === '1'}
                onChange={(value) => {
                  rowUpdate({ delflag: value ? '1' : '0' });
                }}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
        },
        {
          id: 'chk',
          text: (
            <FlexLayout
              justify="center"
              align="center"
            >
              처리
              <CheckBox
                noMargin
                value={scope.state.pushTotalCheck2}
                onChange={async (value) => scope
                  .updateCheckAllToggle(value, 'chk')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
          width: 50,
          render: (x, rowUpdate) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              <CheckBox
                noMargin
                value={x.chk === '1'}
                readonly={x.misflag2 === '1'}
                onChange={(value) => scope.onChkChanged(rowUpdate, x, value)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
        },
        {
          id: 'delchk',
          text: (
            <FlexLayout
              justify="center"
              align="center"
            >
              취소
              <CheckBox
                noMargin
                value={scope.state.pushTotalCheck3}
                onChange={async (value) => scope
                  .updateCheckAllToggle(value, 'delchk')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
          width: 50,
          render: (x, rowUpdate) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              <CheckBox
                noMargin
                value={x.delchk === '1'}
                readonly={x.misflag2 === '0'}
                onChange={(value) => {
                  rowUpdate({ delchk: value ? '1' : '0' });
                }}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          ),
        },
        {
          id: 'spjangcd',
          text: '사업장',
          width: 150,
          trail: () => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {scope.state.total} 건
            </FlexLayout>
          ),
          render: (x, rowUpdate) => (
            x.chk === '1'
              ? <ComboBox
                value={x.spjangcd}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.props.publicStore.spjangs.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
                onSelect={(option) => rowUpdate({ spjangcd: option.value })}
              />
              : <FlexLayout
                justify="left"
                align="center"
              >
                {scope.props.publicStore.spjangs.filter((y) => y.spjangcd === x.spjangcd)[0]?.spjangnm}
              </FlexLayout>
          ),
        },
        {
          id: 'misflag2',
          text: '매출여부',
          width: 55,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{
                color: x.misflag2 === '0'
                  ? 'var(--color-red)' : 'var(--color-blue)',
              }}
            >
              {x.misflag2 === '1' ? '매출' : '매출X'}
            </FlexLayout>
          ),
        },
        {
          id: 'recivstatus',
          text: '상태',
          width: 150,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
              style={{
                // eslint-disable-next-line no-nested-ternary
                color: x.recivstatus === 'F'
                  // eslint-disable-next-line no-nested-ternary
                  ? 'var(--color-blue)' : x.recivstatus === 'FF'
                    ? '#7A297B' : x.recivstatus === 'B'
                      ? 'var(--color-red)' : '',
              }}>
              {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'misdate',
          text: '작성일자',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.misdate)}
            </FlexLayout>
          ),
        },
        {
          id: 'seq',
          text: '승인번호',
          width: 200,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign='left'
              ref={ref}
              value={x.seq}
              onChange={(value) => rowUpdate({ seq: value })}
            />
          ),
        },
        {
          id: 'cltcd',
          text: '거래처코드',
          width: 100,
        },
        {
          id: 'cltnm',
          text: '거래처명',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.cltnm}
            </FlexLayout>
          ),
        },
        {
          id: 'actcd',
          text: '현장코드',
          width: 100,
          color: 'var(--color-red)',
          render: (x, rowUpdate, ref) => (
            x.chk === '1'
              ? <TextBox
                ref={ref}
                value={x.actcd}
                textAlign="center"
                onChange={(value) => rowUpdate({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    rowUpdate({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              : <FlexLayout
                justify="center"
                align="center"
              >
                {x.actcd}
              </FlexLayout>
          ),
        },
        {
          id: 'actnm',
          text: '현장명',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.actnm}
            </FlexLayout>
          ),
        },
        {
          id: 'totsamt',
          text: '총공급가액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.totsamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.totsamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'tottamt',
          text: '총부가세액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.tottamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.tottamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'totmamt',
          text: '총금액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.totmamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.totmamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'remark',
          text: '품목명',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'size',
          text: '규격',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.qty_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'qty',
          text: '수량',
          width: 80,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.uamt_tot}
            </FlexLayout>
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.samt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {x.uamt === null ? '0' : Format.number(x.uamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'samt',
          text: '공급가액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.tamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.samt)}
            </FlexLayout>
          ),
        },
        {
          id: 'tamt',
          text: '부가세액',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.tamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.tamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'misamt',
          text: '합계',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.misamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'tax_spdate',
          text: '발급일자',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {Date8.withDash(x.tax_spdate)}
            </FlexLayout>
          ),
        },
        {
          id: 'saupnum',
          text: '사업자번호',
          width: 100,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="###-##-#####"
              value={x.saupnum}
            />
          ),
        },
        {
          id: 'prenm',
          text: '대표자명',
          width: 100,
        },
        {
          id: 'taxmail',
          text: '메일',
          width: 200,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.taxmail}
            </FlexLayout>
          ),
        },
        {
          id: 'gubun',
          text: '구분',
          width: 150,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.gubun === '01' ? '세금계산서' : '수정세금계산서'}
            </FlexLayout>
          ),
        },
        {
          id: 'gubun2',
          text: '종류',
          width: 100,
        },
        {
          id: 'bhflag',
          text: '발행구분',
          width: 100,
        },
        {
          id: 'receiptyn',
          text: '청구구분',
          width: 55,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.receiptyn === '1' ? '영수' : '청구'}
            </FlexLayout>
          ),
        },
        {
          id: 'bigo',
          text: '비고',
          width: 250,
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.bigo}
            </FlexLayout>
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      data={scope.state.data}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
    />
  </FlexLayout>
);
