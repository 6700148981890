import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e101_elec
 * @category 전자계약서
 */

export class ContractModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly contmon: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly contdeliv: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly contgubun: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly contgubunnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly becontgubun: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm_mini: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly beqty: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly zipcode: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly address: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly address2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly bildyd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly contbild: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly evercd: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly evernight: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly evernumber: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly number: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly everamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly everaddamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly contdate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(17)
   */
  readonly gigan: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly yearflag: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly amt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly beamt: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly addyn: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm1: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd1: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty1: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt1: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo1: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm2: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd2: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty2: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt2: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo2: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm3: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd3: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty3: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt3: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo3: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm4: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd4: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty4: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt4: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo4: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm5: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd5: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty5: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt5: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo5: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm6: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd6: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty6: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt6: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo6: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm7: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd7: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty7: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt7: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo7: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly mdnm8: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly sizecd8: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty8: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly equpamt8: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly bigo8: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly cltadres: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly cltadres2: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly clttelnum: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly clttaxmail: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly dedate: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly clthpnum: string;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly zipcd: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly cltnm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltsaupnum: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly cltprenm: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly actpernm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly indiv_agree: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly number_agree: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly cmsflag: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly cms_banknm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly cms_accnum: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly cms_accname: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly cms_saupnum: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly cms_dedate: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly cms_name: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly cms_indiv_agree: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly cms_number_agree: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly cms_number_flag: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly okflag: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly attcnt: number;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly saleamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly salenm: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly apptime: string;


  /**
   *
   *
   * **data type** number(0)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly toteveraddamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly addnumber: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly tot_averamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.contmon = data.contmon || '';
    this.seq = data.seq || '';
    this.contdeliv = data.contdeliv || '01';
    this.contgubun = data.contgubun || '01';
    this.contgubunnm = data.contgubunnm || '';
    this.becontgubun = data.becontgubun || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.actnm_mini = data.actnm_mini || '';
    this.qty = data.qty || '0';
    this.uamt = data.uamt || '0';
    this.beqty = data.beqty || '0';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.bildyd = data.bildyd || '';
    this.contbild = data.contbild || '01';
    this.evercd = data.evercd || '';
    this.evernight = data.evernight || '';
    this.evernumber = data.evernumber || '0';
    this.number = data.number || '0';
    this.everamt = data.everamt || '0';
    this.everaddamt = data.everaddamt || '0';
    this.contdate = data.contdate || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.gigan = data.gigan || '';
    this.yearflag = data.yearflag || '0';
    this.amt = data.amt || '0';
    this.beamt = data.beamt || '0';
    this.addyn = data.addyn || '';
    this.mdnm1 = data.mdnm1 || '';
    this.sizecd1 = data.sizecd1 || '';
    this.qty1 = data.qty1 || '';
    this.equpamt1 = data.equpamt1 || '';
    this.bigo1 = data.bigo1 || '';
    this.mdnm2 = data.mdnm2 || '';
    this.sizecd2 = data.sizecd2 || '';
    this.qty2 = data.qty2 || '';
    this.equpamt2 = data.equpamt2 || '';
    this.bigo2 = data.bigo2 || '';
    this.mdnm3 = data.mdnm3 || '';
    this.sizecd3 = data.sizecd3 || '';
    this.qty3 = data.qty3 || '';
    this.equpamt3 = data.equpamt3 || '';
    this.bigo3 = data.bigo3 || '';
    this.mdnm4 = data.mdnm4 || '';
    this.sizecd4 = data.sizecd4 || '';
    this.qty4 = data.qty4 || '';
    this.equpamt4 = data.equpamt4 || '';
    this.bigo4 = data.bigo4 || '';
    this.mdnm5 = data.mdnm5 || '';
    this.sizecd5 = data.sizecd5 || '';
    this.qty5 = data.qty5 || '';
    this.equpamt5 = data.equpamt5 || '';
    this.bigo5 = data.bigo5 || '';
    this.mdnm6 = data.mdnm6 || '';
    this.sizecd6 = data.sizecd6 || '';
    this.qty6 = data.qty6 || '';
    this.equpamt6 = data.equpamt6 || '';
    this.bigo6 = data.bigo6 || '';
    this.mdnm7 = data.mdnm7 || '';
    this.sizecd7 = data.sizecd7 || '';
    this.qty7 = data.qty7 || '';
    this.equpamt7 = data.equpamt7 || '';
    this.bigo7 = data.bigo7 || '';
    this.mdnm8 = data.mdnm8 || '';
    this.sizecd8 = data.sizecd8 || '';
    this.qty8 = data.qty8 || '';
    this.equpamt8 = data.equpamt8 || '';
    this.bigo8 = data.bigo8 || '';
    this.cltadres = data.cltadres || '';
    this.cltadres2 = data.cltadres2 || '';
    this.clttelnum = data.clttelnum || '';
    this.clttaxmail = data.clttaxmail || '';
    this.dedate = data.dedate || '';
    this.clthpnum = data.clthpnum || '';
    this.zipcd = data.zipcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.cltsaupnum = data.cltsaupnum || '';
    this.cltprenm = data.cltprenm || '';
    this.actpernm = data.actpernm || '';
    this.indiv_agree = data.indiv_agree || '0';
    this.number_agree = data.number_agree || '0';
    this.cmsflag = data.cmsflag || '';
    this.cms_banknm = data.cms_banknm || '';
    this.cms_accnum = data.cms_accnum || '';
    this.cms_accname = data.cms_accname || '';
    this.cms_saupnum = data.cms_saupnum || '';
    this.cms_dedate = data.cms_dedate || '';
    this.cms_name = data.cms_name || '';
    this.cms_indiv_agree = data.cms_indiv_agree || '0';
    this.cms_number_agree = data.cms_number_agree || '0';
    this.cms_number_flag = data.cms_number_flag || '';
    this.remark = data.remark || '';
    this.okflag = data.okflag || '';
    this.appnum = data.appnum || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.inperid = data.inperid || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.indate = data.indate || '';
    this.attcnt = data.attcnt || 0;
    this.refer = data.refer || '';
    this.saleamt = data.saleamt || '0';
    this.salenm = data.salenm || '';
    this.apptime = data.apptime || '';
    this.toteveraddamt = data.toteveraddamt || '0';
    this.addnumber = data.addnumber || '0';
    this.tot_averamt = data.tot_averamt || '0';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
