import {
  DateTextBox,
  TextBox,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { TLHF } from '../../../../../constants';
import EnrollmentConferenceModel from '../models/EnrollmentConferenceModel';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentConferencelTableHeader: TLHF<EnrollmentConferenceModel, Enrollment> = () => ([
  {
    id: 'seq',
    text: '순서',
    width: 10,
  },
  {
    id: 'compdate',
    text: '협의날짜',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        format="####-##-##"
        textAlign="center"
        value={x.hisdate}
        onChange={(v) => rowUpdate({ hisdate: v })}
      />
    ),
  },
  {
    id: 'remark',
    text: '현장협의사항',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
