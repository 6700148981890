import { GLHF, Global } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import {
  Compyns,
  Status,
} from '../Status';
import StatusTabModel from '../models/StatusTabModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';
import { Receflags } from '../../../estimate/Status/Status';


const Recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'B ', remark: '발송전' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'R ', remark: '열람' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
];


/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e471w
 * @category 공사현황
 */
export const StatusGridDivicdHeader: GLHF<StatusTabModel, Status> = (scope) => ([
  {
    id: 'divicd',
    text: '부서',
    group: true,
    isHidden: true,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'comppernm',
    text: '처리자',
    width: 80,
  },
  {
    id: 'compdate',
    text: '공사일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.compdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'compnum',
    text: '번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.compnum}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactcd',
    text: '회사구분',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.wkactcd === '001' ? { color: 'var(--color-violet)' } : ''}
      >
        {x.wkactnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'offinm',
    text: '공사명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.offinm}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'samt', x, ['divicd']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.divicdSamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'tamt', x, ['divicd']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.divicdTamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'compamt',
    text: '공사금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.compamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'compamt', x, ['divicd']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.divicdCompamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'saleamt',
    text: '최종(할인)금액',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.saleamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'saleamt', x, ['divicd']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.divicdSaleamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'compyn',
    text: '상태',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: Compyns.filter((y) => y.value === x.compyn)[0]?.color,
        }}
      >
        {Compyns.filter((y) => y.value === x.compyn)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'receflag',
    text: '확인서수령여부',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.receflag === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
      >
        {Receflags.filter((y) => y.value === x.receflag)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
    sum: (x, items) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.byGroup(items, 'misamt', x, ['divicd']), true)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.detailMisamt_tot || '0', true)}
      </FlexLayout>
    ),
  },
  {
    id: 'recivstatus',
    text: '전자세금계산서',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{
          // eslint-disable-next-line no-nested-ternary
          color: x.recivstatus === 'F'
            // eslint-disable-next-line no-nested-ternary
            ? 'var(--color-blue)' : x.recivstatus === 'FF'
              ? '#7A297B' : x.recivstatus === 'B'
                ? 'var(--color-red)' : '',
        }}
      >
        {Recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'jirogubun',
    text: '지로여부',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.jirogubun === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.jirogubun === '1' ? '있음' : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt2',
    text: '수금여부',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.jirogubun === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.rcvamt === x.misamt ? '수금' : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'bigo',
    text: '비고',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.bigo}
      </FlexLayout>
    ),
  },

]);
