import {
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import { WaitQueueCallback } from '../constants';
import { ToolStore } from './index';

interface WaitAsyncQueueItem {
  key: string; // MQTT message for check on finish
  isXml: boolean;
  callback: WaitQueueCallback;
  fn: Function;
  inTime: number;
}

interface WaitAsyncQueueResponse {
  key: string;
  result: any;
}

interface IWaitQueueStore {
  data: WaitAsyncQueueItem[];
  toolStore?: ToolStore;
}

export default class WaitAsyncQueueStore implements IWaitQueueStore {
  @observable data: WaitAsyncQueueItem[];

  toolStore?: ToolStore;

  constructor() {
    this.data = [];
    this.timeoutCheck = this.timeoutCheck.bind(this);

    makeObservable(this);
  }

  setToolStore(toolStore: ToolStore) {
    this.toolStore = toolStore;
  }

  @action
  public append(
    key: string,
    isXml: boolean,
    callback: WaitQueueCallback,
    fn: Function,
  ) {
    this.data = [
      {
        key,
        isXml,
        callback,
        fn,
        inTime: Date.now(),
      },
      ...this.data,
    ];
    fn();
  }

  @action
  public onEvent(data: WaitAsyncQueueResponse) {
    const item = this.data.filter((x) => x.key === data.key);
    if (item.length) {
      if (item[0].isXml) {
        item[0].callback(data.result);
      } else {
        item[0].callback(JSON.parse(data.result));
      }
      this.data = this.data.filter((x) => x.key !== data.key);
    }
  }

  @computed
  public get queue(): WaitAsyncQueueItem[] {
    return this.data;
  }

  public timeoutCheck() {
    this.data.forEach((item) => {
      if (Date.now() - item.inTime > 10000) {
        // eslint-disable-next-line no-param-reassign
        item.inTime = Date.now();
        item.fn();
      }
    });
  }
}
