import {
  FaInfoCircle,
  FaChartLine,
  FaHireAHelper,
  FaCommentsDollar,
} from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { ChargeReceivable } from './ChargeReceivable';
import { TabModel } from '../../../../models';
import { IntegrationTemplate } from './tabs/Integration.template';
import { HyunDaeTemplate } from './tabs/HyunDae.template';
import { WeekendLongReceivablesTemplate } from './tabs/WeekendLongReceivables.template';
import { WeekendReceivablesTemplate } from './tabs/WeekendReceivables.template';


export const ChargeReceivableTab: Array<TabLayoutDataItem<ChargeReceivable>> = [
  {
    tab: new TabModel('Integration', FaInfoCircle, '통합'),
    template: (scope) => <IntegrationTemplate scope={scope} />,
  },
  {
    tab: new TabModel('HyunDae', FaHireAHelper, '현대'),
    template: (scope) => <HyunDaeTemplate scope={scope} />,
  },
  {
    tab: new TabModel('WeekendLongReceivable', FaChartLine, '수금현황-장기미수금'),
    template: (scope) => <WeekendLongReceivablesTemplate scope={scope} />,
  },
  {
    tab: new TabModel('WeekendReceivable', FaCommentsDollar, '수금현황-미수금'),
    template: (scope) => <WeekendReceivablesTemplate scope={scope} />,
  },
];
