import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import * as React from 'react';
import { FiUpload } from 'react-icons/fi';
import { HolidayGift } from './HolidayGift';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  AddressButton,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  ImageView,
  LabelBox,
  LoaderButton,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_gift_history
 * @category 명절선물등록
 */
export const HolidayGiftTemplate: React.FC<TemplateProps<HolidayGift>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const sendFlags = [
    { value: '0', remark: '미전송' },
    { value: '1', remark: '전송' },
  ];
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.setState({ year: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(<button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
          >
          </DateTextBox>
        </FlexLayout>

        <FlexLayout size={158}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={[
              { value: '1', remark: '설날' },
              { value: '2', remark: '추석' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout size={210}>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            textAlign="center"
            data={scope.props.publicStore.spjangs.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => scope.setState({ spjangcd: option.value })}
          />
        </FlexLayout>

        <FlexLayout size={380}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          >
          </TextBox>
        </FlexLayout>
        <FlexLayout
          size={160}
          align="center"
          style={{
            color: 'var(--color-blue)',
            marginLeft: 16,
          }}
        >
          * 처음 재직자 전부불러올시 일괄적용을 눌러주세요
        </FlexLayout>
        <Button
          onClick={() => scope.peridAllBtn()}
        >
          일괄적용
        </Button>
        <Button
          style={{ width: 76 }}
          onClick={() => scope.endBtn()}
        >
          {scope.state.holidayGiftList[0]?.endflag === '1' ? '취소' : '마감'}
        </Button>
        <FlexLayout
          align="center"
          style={{
            color: 'var(--color-blue)',
            marginLeft: 16,
          }}
        >
          * 선물 목록 [필수]
        </FlexLayout>
        <FlexLayout
        >
          <Button onClick={() => scope.onDetailNewEvent()}>추가</Button>
          <Button theme={BUTTON_THEMES.RED} onClick={() => scope.onDetailDeleteEvent()}>삭제</Button>
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout
          weight={4}
          disabledText="해당건은 마감되었습니다"
          disabled={scope.state.holidayGiftList[0]?.endflag === '1'}
        >
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'seq',
                text: 'No',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'chk',
                text: <FlexLayout
                  justify="center"
                  align="center"
                  onClick={() => scope.toggleCheckbox()}
                >
                  선택
                </FlexLayout>,
                width: 40,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                    >
                    </CheckBox>
                  </FlexLayout>
                ),
              },
              {
                id: 'b_sms',
                text: <FlexLayout
                  justify="center"
                  align="center"
                >
                  <Button onClick={() => scope.smsCheckAll()}>문자</Button>
                </FlexLayout>,
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <Button
                      style={{ width: 38, height: 32, marginTop: 3 }}
                      onClick={() => scope.sendSMS(x)}
                    >
                      문자
                    </Button>
                  </FlexLayout>
                ),
              },
              {
                id: 'sendflag',
                text: '전송',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.sendflag === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                  >
                    {sendFlags.filter((y) => y.value === x.sendflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '사원코드',
                width: 70,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <TextBox
                      ref={ref}
                      value={x.perid}
                      textAlign="center"
                      onChange={(value) => setData({ perid: x.perid, pernm: x.pernm }, () => rowUpdate({ perid: value }))}
                      onEnter={() => scope.onRetrieveEvent()}
                      bindSearchModal={
                        new SearchBinding(
                          scope.props.modalStore,
                          'TB_JA001',
                          {},
                          true,
                          (item) => {
                            setData({ perid: item.cd, pernm: item.cdnm },
                              () => rowUpdate({ perid: item.cd, pernm: item.cdnm }));
                          },
                        )
                      }
                    >
                    </TextBox>
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '사원명',
                width: 70,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.pernm}
                    weight={1.5}
                    textAlign="center"
                    onChange={(value) => setData({ pernm: value }, () => rowUpdate({ pernm: value }))}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'same',
                text: '상동',
                width: 50,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <Button
                      style={{ width: 38, height: 32, marginTop: 3 }}
                      onClick={() => setData({
                        rzipcd: x.zipcd,
                        radres: x.adres,
                        radres2: x.adres2,
                      }, () => rowUpdate({
                        rzipcd: x.zipcd,
                        radres: x.adres,
                        radres2: x.adres2,
                      }))}
                    >
                      상동
                    </Button>
                  </FlexLayout>
                ),
              },
              {
                id: 'rzipcd',
                text: '받는주소',
                width: 120,
                color: 'var(--color-red)',
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    <div>
                      <AddressButton
                        publicStore={scope.props.publicStore}
                        modalStore={scope.props.modalStore}
                        onChange={(address) => rowUpdate({
                          rzipcd: address.zonecode,
                          radres: address.address,
                          radres2: address.jibunAddress,
                          rlat: address.y,
                          rlng: address.x,
                        })}
                        style={{
                          width: 42,
                          height: 32,
                          lineHeight: '12px',
                        }}
                      >
                        주소검색
                      </AddressButton>
                    </div>
                    <div
                      style={{ marginLeft: 8 }}
                    >
                      {x.rzipcd}
                    </div>
                  </FlexLayout>
                ),
              },
              {
                id: 'radres',
                text: '주소',
                width: 250,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    textAlign="left"
                    value={x.radres}
                    onChange={(value) => rowUpdate({ radres: value })}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'radres2',
                text: '상세주소',
                width: 200,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    textAlign="left"
                    value={x.radres2}
                    onChange={(value) => rowUpdate({ radres2: value })}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'giftcd',
                text: '선물코드',
                width: 60,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    textAlign="center"
                    ref={ref}
                    value={x.giftcd}
                    onChange={(value) => rowUpdate({ giftcd: value })}
                    onEnter={() => scope.onRetrieveEvent()}
                    bindSearchModal={
                      new SearchBinding(
                        scope.props.modalStore,
                        'TB_GIFT_HISTORY_GIFT',
                        {
                          uv_arg1: scope.state.year,
                          uv_arg2: scope.state.gubun,
                        },
                        true,
                        (item) => {
                          rowUpdate({
                            giftcd: item.cd,
                            giftnm: item.cdnm,
                          });
                        },
                      )
                    }
                  >
                  </TextBox>
                ),
              },
              {
                id: 'giftnm',
                text: '선물',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.giftnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'handphone',
                text: '연락처',
                width: 120,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.handphone}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ handphone: value })}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'email',
                text: '이메일',
                width: 150,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.email}
                    textAlign="left"
                    onChange={(value) => rowUpdate({ email: value })}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'zipcd',
                text: '기본주소',
                width: 120,
                color: 'var(--color-red)',
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    <div>
                      <AddressButton
                        publicStore={scope.props.publicStore}
                        modalStore={scope.props.modalStore}
                        onChange={(address) => rowUpdate({
                          zipcd: address.zonecode,
                          adres: address.address,
                          adres2: address.jibunAddress,
                          lat: address.y,
                          lng: address.x,
                        })}
                        style={{
                          width: 42,
                          height: 32,
                          lineHeight: '12px',
                        }}
                      >
                        주소검색
                      </AddressButton>
                    </div>
                    <div
                      style={{ marginLeft: 8 }}
                    >
                      {x.zipcd}
                    </div>
                  </FlexLayout>
                ),
              },
              {
                id: 'adres',
                text: '주소',
                width: 250,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.adres}
                    textAlign="left"
                    onChange={(value) => rowUpdate({ adres: value })}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'adres2',
                text: '상세주소',
                width: 200,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.adres2}
                    textAlign="left"
                    onChange={(value) => rowUpdate({ adres2: value })}
                  >
                  </TextBox>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.holidayGiftList}
            infinityHandler={scope.infinity}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
            onChange={(rows, updated) => scope.onUserTableChange(rows, updated)}
          />
        </FlexLayout>

        <FlexLayout isVertical={true}>
          <TableLayout
            ref={scope.table2}
            header={[
              {
                id: 'giftcd',
                text: '코드',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.giftcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'giftnm',
                text: '선물명',
                width: 100,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.giftnm}
                    textAlign="left"
                    onChange={(value) => setData({ giftnm: value }, () => rowUpdate({ giftnm: value }))}
                  >
                  </TextBox>
                ),
              },
              {
                id: 'imgflag',
                text: '사진',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.imgflag === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                  >
                    {x.imgflag === '0' ? '사진없음' : '사진있음'}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.giftDetailtList}
            infinityHandler={scope.infinity2}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
            onChange={(rows) => scope.onGiftTableChange(rows)}
          />
          <FlexLayout
            isVertical={true}
          >
            <LabelBox
              style={{ color: 'var(--color-blue)' }}
            >
              * 상품사진
            </LabelBox>
            <ImageView
              src={scope.state.img_pic}
              footer={
                <React.Fragment>
                  <LoaderButton
                    isFull={true}
                    onClick={() => scope.imageSelect()}
                  >
                    <FiUpload />
                    <span>등록</span>
                  </LoaderButton>

                  <LoaderButton
                    isFull={true}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.imageDelete()}
                  >
                    <span>삭제</span>
                  </LoaderButton>
                </React.Fragment>
              }
            >
            </ImageView>
          </FlexLayout>

        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
