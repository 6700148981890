import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { MdKeyboardArrowRight } from 'react-icons/all';
import { InspectorStatus } from './InspectorStatus';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_e035w_04
 * @category 점검자별[주/부]점검현황
 */
export const InspectorStatusTemplate: React.FC<TemplateProps<InspectorStatus>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={244}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##"
          onChange={(value) => scope.SS({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              scope.setState({ stdate: new Date6Calculator(scope.state.stdate).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              scope.setState({ stdate: new Date6Calculator(scope.state.stdate).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={286}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          textAlign="center"
          weight={0.8}
          value={scope.state.divicd}
          onChange={(value) => scope.SS({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
        >
        </TextBox>
        <TextBox
          value={scope.state.divinm}
          readonly={true}
          textAlign="center"
          onChange={(value) => scope.SS({ divinm: value })}
        >
        </TextBox>

      </FlexLayout>

      <FlexLayout size={286}>
        <span style={{ color: 'var(--color-red)' }}>사용자</span>
        <TextBox
          weight={0.8}
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.SS({ perid: value })}
          isDisabledTrackingStateChange={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
        />
        <TextBox
          value={scope.state.pernm}
          textAlign="center"
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <GridLayout
        header={[
          {
            id: 'seq',
            text: 'No',
            width: 1,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.seq}
              </FlexLayout>
            ),
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 3,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '사원명',
            width: 2,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  주점검
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  부점검
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  총점검
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('mal')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.jutot}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.butot}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.daytot}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.jutot_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.butot_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.daytot_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday1',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  1일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('01')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju01}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu01}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day01}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju01_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu01_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day01_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday2',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  2일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('02')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju02}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu02}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day02}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju02_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu02_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day02_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday3',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  3일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('03')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju03}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu03}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day03}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju03_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu03_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day03_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday4',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  4일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('04')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju04}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu04}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day04}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju04_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu04_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day04_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday5',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  5일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('05')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju05}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu05}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day05}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju05_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu05_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day05_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday6',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  6일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('06')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju06}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu06}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day06}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju06_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu06_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day06_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday7',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  7일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('07')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju07}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu07}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day07}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju07_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu07_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day07_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday8',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  8일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('08')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju08}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu08}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day08}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju08_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu08_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day08_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday9',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  9일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('09')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju09}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu09}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day09}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju09_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu09_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day09_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday10',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  10일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('10')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju10}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu10}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day10}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju010_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu010_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day10_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday11',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  11일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('11')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju11}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu11}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day11}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju11_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu11_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day11_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday12',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  12일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('12')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju12}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu12}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day12}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju12_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu12_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day12_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday13',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  13일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('13')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju13}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu13}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day13}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju13_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu13_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day13_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday14',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  14일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('14')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju14}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu14}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day14}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju14_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu14_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day14_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday15',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  15일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('15')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju15}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu15}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day15}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju15_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu15_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day15_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday16',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  16일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('16')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju16}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu16}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day16}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju16_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu16_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day16_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday17',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  17일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('17')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju17}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu17}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day17}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju17_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu17_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day17_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday18',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  18일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('18')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju18}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu18}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day18}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju18_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu18_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day18_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday19',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  19일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('19')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju19}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu19}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day19}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju19_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu19_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day19_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday20',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  20일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('20')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju20}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu20}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day20}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju20_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu20_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day20_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday21',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  21일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('21')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju21}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu21}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day21}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju21_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu21_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day21_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday22',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  22일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('22')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju22}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu22}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day22}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju22_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu22_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day22_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday23',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  23일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('23')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju23}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu23}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day23}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju23_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu23_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day23_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday24',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  24일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('24')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju24}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu24}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day24}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju24_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu24_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day24_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday25',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  25일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('25')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju25}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu25}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day25}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju25_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu25_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day25_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuda26',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  26일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('26')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju26}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu26}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day26}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju26_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu26_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day26_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday27',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  27일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('27')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju27}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu27}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day27}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju27_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu27_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day27_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday28',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  28일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('28')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju28}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu28}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day28}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju28_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu28_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day28_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday29',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  29일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('29')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju29}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu29}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day29}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju29_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu29_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day29_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday30',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  30일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('30')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju30}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu30}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day30}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju30_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu30_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day30_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'jubuday31',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  31일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 1,
            render: (x) => (
              <FlexLayout
                isVertical={true}
                onClick={() => scope.onSetEndDate('31')}
                onDoubleClick={() => scope.openPopup(true)}
              >
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.ju31}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {x.bu31}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.day31}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.trailTotals?.ju31_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {scope.state.trailTotals?.bu31_tot || '0'}
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.trailTotals?.day31_tot || '0'}
                </FlexLayout>
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={48}
        headerHeight={48}
        data={scope.state.data}
        ref={scope.grid}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>

    <ModalView
      isVisible={scope.state.popup}
      width={1400}
      onClose={() => scope.SS({ popup: false })}
      zIndex={-1}
    >
      <FlexLayout
        isVertical={true}
        style={{
          padding: 8,
        }}
      >
        <LayoutTitle>점검자별[주/부]점검현황</LayoutTitle>

        <FlexLayout>
          <GridLayout
            data={scope.state.popupData}
            header={[
              {
                id: 'state',
                text: '결과',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.state === '1' ? 'var(--color-red)'
                        : x.state === '2' ? 'var(--color-blue)' : 'var(--color-black)',
                    }}
                  >
                    {
                      x.state === '1' ? '미처리'
                        : x.state === '2' ? '처리' : '원격'
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'rpdate',
                text: '점검일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rpdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '부점검자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '회사구분',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '계약',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'plantime',
                text: '시작시간',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.plantime.substr(0, 2)}:{x.plantime.substr(2, 2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'plantime2',
                text: '끝시간',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.plantime2.substr(0, 2)}:{x.plantime2.substr(2, 2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '결과',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 3,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.popupInfinity}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
