import * as React from 'react';
import {
  Button, FlexLayout, GridLayout, SubHeader,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { ManageStatus } from '../ManageStatus';
import { ManageStatusGridHeaderSuju, ManageStatusGridHeaderBal } from '../headers';

/**
 * 화면 > 탭 - 하단 > 수주/발주내역
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusDetailTabBalTemplate: React.FC<TemplateProps<ManageStatus>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout
          size={Global.LAYOUT_TITLE_HEIGHT_1}
          isVertical={true}
        >
          <SubHeader>
            <div>
              <span>수주내역</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onClickOpenEnrollment()}>수주서등록</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>

        <GridLayout
          header={ManageStatusGridHeaderSuju(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.dataHeadTabGridSuju}
          onRowClick={(item) => scope.setState({ focusedSuju: item })}
        />
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout
          size={Global.LAYOUT_TITLE_HEIGHT_1}
          isVertical={true}
        >
          <SubHeader>
            <div>
              <span>발주내역</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onClickOpenBalEnrollment()}>발주서</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>

        <GridLayout
          header={ManageStatusGridHeaderBal(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.dataHeadTabGridBal}
          onRowClick={(item) => scope.setState({ focusedBal: item })}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
