import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { OrderMonthly } from './OrderMonthly';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';

export const OrderMonthlyTemplate: React.FC<TemplateProps<OrderMonthly>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={240}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
          onSelect={(option) => scope.setState({ spjangcd: option.value })}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'boonRyu',
            text: '분류',
            width: 8,
            render: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ backgroundColor: '#E6F0FB', color: 'var(--color-blue)' }}
                >
                  수입
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ backgroundColor: '#FFEAEA', color: 'var(--color-red)' }}
                >
                  지출
                </FlexLayout>
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ backgroundColor: '#F0F0F0' }}
                >
                  예산
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '구분명',
            width: 12,
            render: () => (
              <FlexLayout isVertical={true}>
                <FlexLayout isVertical={true} style={{ backgroundColor: '#E6F0FB' }}>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    수금예정
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    수금
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    예정+수금
                  </FlexLayout>
                </FlexLayout>
                <FlexLayout isVertical={true} style={{ backgroundColor: '#FFEAEA' }}>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    지급
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    미지급
                  </FlexLayout>
                </FlexLayout>
                <FlexLayout isVertical={true} style={{ backgroundColor: '#F0F0F0' }}>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    예정+수금
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    차액
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '01',
            text: '01월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB', color: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '01', '01', '수금예정')}
                  >
                    {Format.number(x.rcvexmon01)}
                  </FlexLayout>

                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '01', '01', '수금')}
                  >
                    {Format.number(x.rcvmon01)}
                  </FlexLayout>

                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '01', '01', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon01)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA', color: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '01', '01', '매입')}
                  >
                    {Format.number(x.mijamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '01', '01', '지급')}
                  >
                    {Format.number(x.sndamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '01', '01', '미지급')}
                  >
                    {Format.number(x.iamt01)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0', color: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '01', '01', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon01_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '01', '01', '매입')}
                  >
                    {Format.number(x.mijamt01_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex01)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '02',
            text: '02월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '02', '02', '수금예정')}
                  >
                    {Format.number(x.rcvexmon02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '02', '02', '수금')}
                  >
                    {Format.number(x.rcvmon02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '02', '02', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon02)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '02', '02', '매입')}
                  >
                    {Format.number(x.mijamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '02', '02', '지급')}
                  >
                    {Format.number(x.sndamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '02', '02', '미지급')}
                  >
                    {Format.number(x.iamt02)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '02', '02', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon02_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '02', '02', '매입')}
                  >
                    {Format.number(x.mijamt02_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex02)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '03',
            text: '03월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '03', '03', '수금예정')}
                  >
                    {Format.number(x.rcvexmon03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '03', '03', '수금')}
                  >
                    {Format.number(x.rcvmon03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '03', '03', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon03)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '03', '03', '매입')}
                  >
                    {Format.number(x.mijamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '03', '03', '지급')}
                  >
                    {Format.number(x.sndamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '03', '03', '미지급')}
                  >
                    {Format.number(x.iamt03)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '03', '03', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon03_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '03', '03', '매입')}
                  >
                    {Format.number(x.mijamt03_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex03)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '04',
            text: '04월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '04', '04', '수금예정')}
                  >
                    {Format.number(x.rcvexmon04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '04', '04', '수금')}
                  >
                    {Format.number(x.rcvmon04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '04', '04', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon04)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '04', '04', '매입')}
                  >
                    {Format.number(x.mijamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '04', '04', '지급')}
                  >
                    {Format.number(x.sndamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '04', '04', '미지급')}
                  >
                    {Format.number(x.iamt04)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '04', '04', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon04_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '04', '04', '매입')}
                  >
                    {Format.number(x.mijamt04_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex04)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '05',
            text: '05월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '05', '05', '수금예정')}
                  >
                    {Format.number(x.rcvexmon05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '05', '05', '수금')}
                  >
                    {Format.number(x.rcvmon05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '05', '05', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon05)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '05', '05', '매입')}
                  >
                    {Format.number(x.mijamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '05', '05', '지급')}
                  >
                    {Format.number(x.sndamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '05', '05', '미지급')}
                  >
                    {Format.number(x.iamt05)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '05', '05', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon05_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '05', '05', '매입')}
                  >
                    {Format.number(x.mijamt05_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex05)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '06',
            text: '06월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '06', '06', '수금예정')}
                  >
                    {Format.number(x.rcvexmon06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '06', '06', '수금')}
                  >
                    {Format.number(x.rcvmon06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '06', '06', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon06)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '06', '06', '매입')}
                  >
                    {Format.number(x.mijamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '06', '06', '지급')}
                  >
                    {Format.number(x.sndamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '06', '06', '미지급')}
                  >
                    {Format.number(x.iamt06)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '06', '06', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon06_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '06', '06', '매입')}
                  >
                    {Format.number(x.mijamt06_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex06)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '07',
            text: '07월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '07', '07', '수금예정')}
                  >
                    {Format.number(x.rcvexmon07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '07', '07', '수금')}
                  >
                    {Format.number(x.rcvmon07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '07', '07', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon07)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '07', '07', '매입')}
                  >
                    {Format.number(x.mijamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '07', '07', '지급')}
                  >
                    {Format.number(x.sndamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '07', '07', '미지급')}
                  >
                    {Format.number(x.iamt07)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '07', '07', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon07_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '07', '07', '매입')}
                  >
                    {Format.number(x.mijamt07_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex07)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '08',
            text: '08월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '08', '08', '수금예정')}
                  >
                    {Format.number(x.rcvexmon08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '08', '08', '수금')}
                  >
                    {Format.number(x.rcvmon08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '08', '08', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon08)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '08', '08', '매입')}
                  >
                    {Format.number(x.mijamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '08', '08', '지급')}
                  >
                    {Format.number(x.sndamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '08', '08', '미지급')}
                  >
                    {Format.number(x.iamt08)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '08', '08', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon08_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '08', '08', '매입')}
                  >
                    {Format.number(x.mijamt08_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex08)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '09',
            text: '09월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '09', '09', '수금예정')}
                  >
                    {Format.number(x.rcvexmon09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '09', '09', '수금')}
                  >
                    {Format.number(x.rcvmon09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '09', '09', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon09)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '09', '09', '매입')}
                  >
                    {Format.number(x.mijamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '09', '09', '지급')}
                  >
                    {Format.number(x.sndamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '09', '09', '미지급')}
                  >
                    {Format.number(x.iamt09)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '09', '09', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon09_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '09', '09', '매입')}
                  >
                    {Format.number(x.mijamt09_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex09)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '10',
            text: '10월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '10', '10', '수금예정')}
                  >
                    {Format.number(x.rcvexmon10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '10', '10', '수금')}
                  >
                    {Format.number(x.rcvmon10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '10', '10', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon10)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '10', '10', '매입')}
                  >
                    {Format.number(x.mijamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '10', '10', '지급')}
                  >
                    {Format.number(x.sndamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '10', '10', '미지급')}
                  >
                    {Format.number(x.iamt10)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '10', '10', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon10_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '10', '10', '매입')}
                  >
                    {Format.number(x.mijamt10_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex10)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '11',
            text: '11월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '11', '11', '수금예정')}
                  >
                    {Format.number(x.rcvexmon11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '11', '11', '수금')}
                  >
                    {Format.number(x.rcvmon11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '11', '11', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon11)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '11', '11', '매입')}
                  >
                    {Format.number(x.mijamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '11', '11', '지급')}
                  >
                    {Format.number(x.sndamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '11', '11', '미지급')}
                  >
                    {Format.number(x.iamt11)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '11', '11', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon11_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '11', '11', '매입')}
                  >
                    {Format.number(x.mijamt11_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex11)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '12',
            text: '12월',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '12', '12', '수금예정')}
                  >
                    {Format.number(x.rcvexmon12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '12', '12', '수금')}
                  >
                    {Format.number(x.rcvmon12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '12', '12', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon12)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '12', '12', '매입')}
                  >
                    {Format.number(x.mijamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '12', '12', '지급')}
                  >
                    {Format.number(x.sndamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '12', '12', '미지급')}
                  >
                    {Format.number(x.iamt12)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '12', '12', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmon12_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '12', '12', '매입')}
                  >
                    {Format.number(x.mijamt12_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totex12)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'tot',
            text: '년합계',
            width: 15,
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#E6F0FB' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvex', '01', '12', '수금예정')}
                  >
                    {Format.number(x.rcvexmontot)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('rcv', '01', '12', '수금')}
                  >
                    {Format.number(x.rcvmontot)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '01', '12', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmontot)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#FFEAEA' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '01', '12', '매입')}
                  >
                    {Format.number(x.mijamttot)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '01', '12', '지급')}
                  >
                    {Format.number(x.sndamttot)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    onDoubleClick={() => scope.popupDoubleClicked('snd', '01', '12', '미지급')}
                  >
                    {Format.number(x.iamttot)}
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout
                  isVertical={true}
                  style={{ paddingRight: 6, backgroundColor: '#F0F0F0' }}
                >
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('rcvtot', '01', '12', '예정+수금')}
                  >
                    {Format.number(x.rcvtotmontot_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.popupDoubleClicked('mij', '01', '12', '매입')}
                  >
                    {Format.number(x.mijamttot_2)}
                  </FlexLayout>
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    {Format.number(x.totextot)}
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={200}
        data={scope.state.orderList}
      >
      </GridLayout>
    </FlexLayout>

    <ModalView
      width={1600}
      height={600}
      onClose={() => scope.modalClose()}
      isVisible={scope.state.isModal}
    >
      <FlexLayout isVertical={true}>
        <LayoutTitle>설치월별수입/지출내역[{scope.state.modalName}]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={160}>
            <span>월</span>
            <TextBox
              textAlign="center"
              value={scope.state.year}
              readonly={true}
            >
            </TextBox>
            <TextBox
              textAlign="center"
              value={scope.state.modalStmon}
              readonly={true}
            >
            </TextBox>
          </FlexLayout>

          <FlexLayout size={20}>
            <span>~</span>
          </FlexLayout>

          <FlexLayout size={120}>
            <TextBox
              textAlign="center"
              value={scope.state.year}
              readonly={true}
            >
            </TextBox>
            <TextBox
              textAlign="center"
              value={scope.state.modalEndmon}
              readonly={true}
            >
            </TextBox>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.modalGrid}
            header={[
              {
                id: 'rcvexflag',
                text: '수금예정구분',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={
                    x.rcvexflag === '1' ? { color: 'var(--color-red)' }
                      : x.rcvexflag === '2' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }
                    }
                  >
                    {
                      x.rcvexflag === '1' ? '예정지연'
                        : x.rcvexflag === '2' ? '수금' : ''
                    }
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvdate',
                text: '수금/예정일자',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '구분일자',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '구분',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.gubun === '1' ? 'var(--color-green)'
                        : x.gubun === '2' ? 'var(--color-violet)' : 'var(--color-black)',
                    }}
                  >
                    {
                      x.gubun === '1' ? '선급금-계약'
                        : x.gubun === '2' ? '중도금-자재출하' : '잔금-검사예정'
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'actcd',
                text: '현장코드',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'qty',
                text: '대수',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(x.qty)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    {scope.state.qty_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'compamt',
                text: '수주금액',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(x.compamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    {Format.number(scope.state.compamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '수금액',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout style={{ color: 'var(--color-green)' }}>
                    {Format.number(scope.state.rcvamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'notamt',
                text: '미수금',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.notamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {Format.number(scope.state.notamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misflag',
                text: '진행',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.misflag === '1' ? { color: 'var(--color-black)' }
                      : x.misflag === '2' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }
                  }
                  >
                    {
                      x.misflag === '1' ? '매출'
                        : x.misflag === '2' ? '수금' : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'contdate',
                text: '계약일',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.contdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '납기일',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'ppernm',
                text: '계약자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.ppernm}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.modalList}
          ></GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      width={1600}
      height={600}
      onClose={() => scope.modalClose()}
      isVisible={scope.state.isModal2}
    >
      <FlexLayout isVertical={true}>
        <LayoutTitle>설치월별수입/지출내역[{scope.state.modalName}]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={160}>
            <span>월</span>
            <TextBox
              textAlign="center"
              value={scope.state.year}
              readonly={true}
            >
            </TextBox>
            <TextBox
              textAlign="center"
              value={scope.state.modalStmon}
              readonly={true}
            >
            </TextBox>
          </FlexLayout>

          <FlexLayout size={20}>
            <span>~</span>
          </FlexLayout>

          <FlexLayout size={120}>
            <TextBox
              textAlign="center"
              value={scope.state.year}
              readonly={true}
            >
            </TextBox>
            <TextBox
              textAlign="center"
              value={scope.state.modalEndmon}
              readonly={true}
            >
            </TextBox>
          </FlexLayout>

          <FlexLayout size={320}>
            <span>검색어</span>
            <TextBox
              value={scope.state.modalSearch}
              onChange={(value) => scope.setState({ modalSearch: value })}
              onEnter={() => scope.modalSearchRetrieve()}
            >
            </TextBox>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.modalGrid2}
            header={[
              {
                id: 'mijdate',
                text: '발생일자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mijnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'artnm',
                text: '항목명',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.artnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 160,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    건수 {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '발생금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    {Format.number(scope.state.mijamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'sndamt',
                text: '지급금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.sndamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout style={{ color: 'var(--color-green)' }}>
                    {Format.number(scope.state.sndamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '미지급금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout style={{ color: 'var(--color-violet)' }}>
                    {Format.number(scope.state.iamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '증빙구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.gubuns?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.modalList}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
