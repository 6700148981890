import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Effort } from '../Effort';
import { EffortGridKc } from '../headers/EffortGridKc';

/**
 * 화면 > 탭 A
 * @window w_tb_e601w_sulchi_06
 * @category 월공수현황
 */
export const EffortTabKcTemplate: React.FC<TemplateProps<Effort>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <GridLayout
        header={EffortGridKc(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataKc}
        infinityHandler={scope.infinityKc}
      />
    </FlexLayout>
  );
};
