import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e301
 * @category 민원접수등록
 */

export class ReportDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 민원접수일자
   *
   * **data type** varchar(3)
   */
  readonly recedate: string;

  /**
   * 민원접수일자
   *
   * **data type** varchar(3)
   */
  readonly recetime: string;

  /**
   * 접수자
   *
   * **data type** varchar(3)
   */
  readonly reperid: string;

  /**
   * 접수자명
   *
   * **data type** varchar(3)
   */
  readonly repernm: string;

  /**
   * 민원처리일자
   *
   * **data type** varchar(3)
   */
  readonly compdate: string;

  /**
   * 민원처리일시
   *
   * **data type** varchar(3)
   */
  readonly sttime: string;

  /**
   * 민원처리일시(끝)
   *
   * **data type** varchar(3)
   */
  readonly endtime: string;

  /**
   * 문서번호
   *
   * **data type** varchar(3)
   */
  readonly appnum: string;

  /**
   * ?
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 현장담당자
   *
   * **data type** varchar(5)
   */
  readonly actperid: string;

  /**
   * 민원접수일자
   *
   * **data type** varchar(100)
   */
  readonly actpernm: string;

  /**
   * 고객명
   *
   * **data type** varchar(5)
   */
  readonly actcd: string;

  /**
   * 민원신고자
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 고객연락처
   *
   * **data type** varchar(100)
   */
  readonly acttelnum: string;

  /**
   * 민원구분
   *
   * **data type** varchar(100)
   */
  readonly civilcd: string;

  /**
   * 민원구분
   *
   * **data type** varchar(100)
   */
  readonly civilnm: string;

  /**
   * 민원처리자
   *
   * **data type** varchar(100)
   */
  readonly perid: string;

  /**
   * 민원처리자
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 민원처리자부서
   *
   * **data type** varchar(100)
   */
  readonly divicd: string;

  /**
   * 민원처리자부서
   *
   * **data type** varchar(100)
   */
  readonly divinm: string;

  /**
   * 처리자배정
   *
   * **data type** varchar(100)
   */
  readonly okflag: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  readonly recenum: string;

  /**
   * 민원상세내용
   *
   * **data type** varchar(3)
   */
  readonly remark: string;

  /**
   * ? 모름
   *
   * **data type** varchar(3)
   */
  readonly civilflag: string;

  /**
   * ? 모름
   *
   * **data type** varchar(3)
   */
  readonly recetelnum: string;

  /**
   * ? 처리결과
   *
   * **data type** varchar(3)
   */
  readonly resultcd: string;

  /**
   * ? 처리결과
   *
   * **data type** varchar(3)
   */
  readonly resultnm: string;

  /**
   * ? 첨부파일
   *
   * **data type** varchar(3)
   */
  readonly attcnt: string;

  /**
   * ? 첨부사진
   *
   * **data type** varchar(3)
   */
  readonly attcnt2: string;

  /**
   * ? 민원처리결과내용
   *
   * **data type** varchar(3)
   */
  readonly compremark: string;

  /**
   * ? 특이사항
   *
   * **data type** varchar(3)
   */
  readonly bigo: string;

  /**
   * ? 참조
   *
   * **data type** varchar(3)
   */
  readonly refer: string;

  /**
   * 결재일
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 결재자
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   * 결재권자 지시사항
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   * 결재시간
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   * 입력일
   *
   * **data type** char(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  picflag: string;

  /**
   * 직위
   *
   * **data type** varchar(10)
   */
  rspnm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.recetime = data.recetime || '';
    this.reperid = data.reperid || '';
    this.repernm = data.repernm || '';
    this.compdate = data.compdate || '';
    this.sttime = data.sttime || '';
    this.endtime = data.endtime || '';
    this.appnum = data.appnum || '';
    this.appgubun = data.appgubun || '';
    this.actperid = data.actperid || '';
    this.actpernm = data.actpernm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.acttelnum = data.acttelnum || '';
    this.civilcd = data.civilcd || '';
    this.civilnm = data.civilnm || '';
    this.civilflag = data.civilflag || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.okflag = data.okflag || '';
    this.remark = Fix.newline(data.remark) || '';
    this.recetelnum = data.recetelnum || '';
    this.resultcd = data.resultcd || '';
    this.resultnm = data.resultnm || '';
    this.attcnt = data.attcnt || '';
    this.attcnt2 = data.attcnt2 || '';
    this.compremark = Fix.newline(data.compremark) || '';
    this.bigo = Fix.newline(data.bigo) || '';
    this.refer = data.refer || '';
    this.appdate = data.appdate || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.apptime = data.apptime || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.picflag = data.picflag || '';
    this.rspnm = data.rspnm || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
