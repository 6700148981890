import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { RetrieveElevatorTemplate } from './RetrieveElevator.template';
import { RetrieveElevatorModel, RetrieveElevatorDetailModel } from './models';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils';
import { ConfirmWarning } from '../../../../utils/confirm';

interface RetrieveElevatorState {
  gubun: string;
  sido: string;
  sigungu: string;
  buldnm: string;
  elno: string;
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  contg: string;
  searchQuery: string;
  clttype: string;
  data: Array<RetrieveElevatorModel>;
  contet: RetrieveElevatorModel;
  focusedData?: RetrieveElevatorModel;
  detailData?: RetrieveElevatorDetailModel;
  contgs: Array<any>;
  contgubuns: Array<any>;
  sidos: Array<any>;
  sigungus: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e101w_04
 * @category 승강기조회
 */
export class RetrieveElevator extends PageComponent<PageProps, RetrieveElevatorState>
  implements PageToolEvents {
  updatedRows?: Array<RetrieveElevatorModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      gubun: '0',
      sido: '01',
      sigungu: '',
      buldnm: '',
      elno: '',
      stdate: `${year}0101`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      contg: '%',
      contgubun: '%',
      divicd: '',
      divinm: '',
      pernm: '',
      perid: '',
      clttype: '%',
      data: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 리스트 (계약분류)1
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    data = await api.dropdown('wf_dd_xpost');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ sidos: data.items });
    await this.onSigunguRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    if (this.state.gubun === '0' && this.state.buldnm === '') {
      ConfirmWarning.show('확인', '건물명을 입력하세요!');
      return;
    }

    if (this.state.gubun === '1' && this.state.elno === '') {
      ConfirmWarning.show('확인', '승강기번호를 입력하세요!');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        gubun: this.state.gubun,
        sido: this.state.sido,
        sigungu: this.state.sigungu,
        buldnm: this.state.buldnm,
        elno: this.state.elno,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new RetrieveElevatorModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      if (this.state.data.length) {
        this.grid.current?.setFocus(0);
      }
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item:RetrieveElevatorModel) {
    this.setState({ focusedData: item });
  }

  @action
  async onSigunguRetrieveEvent() {
    const { actionStore: api } = this.props;
    const data = await api.dropdown(
      'wf_dd_xpost_detail',
      {
        sidocd: this.state.sido,
      },
    );

    if (!data) return;

    this.setState({ sigungus: data.items });
  }

  render() {
    return <RetrieveElevatorTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
