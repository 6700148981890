import { IoIosMail } from 'react-icons/all';
import { GLHF } from '../../../../../constants';
import { Badge, FlexLayout } from '../../../../../components';
import { Enrollment } from '../Enrollment';
import EnrollmentModel from '../models/EnrollmentModel';
import { Date8 } from '../../../../../utils/time';


export const Appgubuns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '101', remark: '결재', color: 'var(--color-blue)' },
  { value: '121', remark: '참조', color: 'inherit' },
  { value: '001', remark: '대기', color: 'var(--color-blue)' },
  { value: '131', remark: '반려', color: 'var(--color-red)' },
  { value: '111', remark: '결재중', color: 'inherit' },
  { value: '301', remark: '전결', color: 'var(--color-blue)' },
  { value: '401', remark: '대결', color: 'var(--color-blue)' },
];


/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export const EnrollmentGridHeader: GLHF<EnrollmentModel, Enrollment> = () => ([
  {
    id: 'costdate',
    text: '견적일자',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 70,
  },
  {
    id: 'appgubun',
    text: '상태',
    width: 80,
    render: (x) => {
      const fp = Appgubuns.filter((y) => y.value === x.appgubun);
      const nm = fp.length > 0 ? fp[0].remark : '';
      const color = fp.length > 0 ? fp[0].color : '#000000';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
          {x?.appremark && <Badge>
            <IoIosMail color="var(--color-red)" size={25} />
          </Badge>}
        </FlexLayout>
      );
    },
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
]);
