import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { UnpaidModel } from './Unpaid.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models';
import { UnpaidTemplate } from './Unpaid.template';
import { MainModel } from '../Receivable/models';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface UnpaidState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;
  resuck: string;
  gubun?: Array<any>;
  searchGubun?: string;

  // data
  data: Array<UnpaidModel>;
  focused?: UnpaidModel;

  // modal
  textDetailModal: boolean;
  popupData: Array<MainModel>;
  popupData2: Array<MainModel>;
  popupFocused?: MainModel;

  // trail
  beamt_tot: string;
  mijamt_tot: string;
  total: string;
  sndamt_tot: string;
  resuamt_tot: string;

  // popup trail
  pmijamt_tot: string;
  piamt_tot: string;
  presuamt_tot: string;
  pmijamt_tot2: string;
  prcvamt_tot2: string;
  presuamt_tot2: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_02
 * @category 미지급현황
 */
export class Unpaid extends PageComponent<PageProps, UnpaidState>
  implements PageToolEvents {
  updatedRows?: Array<UnpaidModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: '19700101',
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      data: [],
      resuck: '1',
      popupData: [],
      popupData2: [],
      searchGubun: '%',

      // trail
      beamt_tot: '0',
      mijamt_tot: '0',
      total: '0',
      sndamt_tot: '0',
      resuamt_tot: '0',

      // popup trail
      pmijamt_tot: '0',
      piamt_tot: '0',
      presuamt_tot: '0',
      pmijamt_tot2: '0',
      prcvamt_tot2: '0',
      presuamt_tot2: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 매입구분
    const data = await api.dropdown('wf_dd_ca510_113_02');

    if (!data) return;

    this.setState({ gubun: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        resuck: this.state.resuck,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new UnpaidModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltcd', 'mijamt'],
        [this.state.focused?.cltcd, this.state.focused?.mijamt], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
      this.SS({
        beamt_tot: this.infinity?.box?.beamt_tot || '0',
        mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
        total: this.infinity?.box?.total || '0',
        sndamt_tot: this.infinity?.box?.sndamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.searchGubun,
      resuck: this.state.resuck,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.searchGubun,
      resuck: this.state.resuck,
    });
  }

  @action
  onRowFocusEvent(item: UnpaidModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<UnpaidModel>, updatedRows: Array<UnpaidModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });

    this.modalRetrive();
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_ca642w_02',
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.focused?.cltcd,
        gubun: this.state.searchGubun,
        actcd: '',
        cltnm: this.state.focused?.cltnm,
      });
    if (data?.items) {
      this.setState({
        popupData: data?.items,
        // popup trail
        pmijamt_tot: data?.mijamt_tot || '0',
        piamt_tot: data?.iamt_tot || '0',
        presuamt_tot: data?.resuamt_tot || '0',
      });
    } else {
      this.setState({
        popupData: [],
        pmijamt_tot2: '0',
        prcvamt_tot2: '0',
        presuamt_tot2: '0',
      });
    }
    if (data?.items2) {
      this.setState({
        popupData2: data?.items2,
        // popup trail
        pmijamt_tot2: data?.mijamt_tot2 || '0',
        prcvamt_tot2: data?.rcvamt_tot2 || '0',
        presuamt_tot2: data?.resuamt_tot2 || '0',
      });
    } else {
      this.setState({
        popupData2: [],
        pmijamt_tot2: '0',
        prcvamt_tot2: '0',
        presuamt_tot2: '0',
      });
    }
  }

  @action
  async modalExcelEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    await api.fxExcel(
      'excel',
      {
        sub: 'w_popup_ca642w_02',
        spjangcd: user.spjangcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.focused?.cltcd,
        gubun: this.state.searchGubun,
        actcd: '',
      },
    );
  }

  render() {
    return (
      <UnpaidTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
