import * as React from 'react';
import { inject } from 'mobx-react';
import {
  action,
  computed,
} from 'mobx';
import { RefObject } from 'react';
import style from './ComboBox.module.scss';
import { ComboBoxModel } from './ComboBox.model';
import { JoinClassName } from '../../../utils/string';
import { PublicStore, ModalStore } from '../../../stores';

interface ComboBoxActions {
  onSelect: (key: ComboBoxModel) => any;
  onChangeSearchQuery?: (val: string) => any;
}

interface ComboBoxProps extends ComboBoxActions {
  publicStore?: PublicStore;
  modalStore?: ModalStore;
  value: any;
  textAlign?: 'left' | 'center' | 'right';
  data?: Partial<ComboBoxModel[]>;
  className?: any;
  style?: any;
  isDisabledTrackingStateChange?: boolean;
  readonly?: boolean;
}

interface ComboBoxStates {
  isVisibleDropDown: boolean;
  popupLeft: number;
  isPopupForward: boolean;
}

export const getGravityString = (value?: string) => {
  if (!value) return 'flex-start';

  switch (value.toUpperCase()) {
    case 'LEFT':
    case 'START':
      return 'flex-start';
    case 'RIGHT':
    case 'END':
      return 'flex-end';
    case 'INIT':
    case 'INITIAL':
      return 'initial';
    default:
      return 'center';
  }
};

export const getGravityPadding = (value?: string) => {
  if (!value) return '0 0 0 12px';

  switch (value.toUpperCase()) {
    case 'LEFT':
    case 'START':
      return '0 0 0 12px';
    case 'RIGHT':
    case 'END':
      return '0 36px 0 12px';
    case 'INIT':
    case 'INITIAL':
      return '0 0 0 12px';
    default:
      return '0px';
  }
};

@inject('publicStore', 'modalStore')
export class ComboBox extends React.Component<ComboBoxProps, ComboBoxStates> {
  ref: RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ComboBoxProps, context: any) {
    super(props, context);
    this.state = {
      isVisibleDropDown: false,
      popupLeft: 0,
      isPopupForward: true,
    };
  }

  componentDidUpdate(prevProps: Readonly<ComboBoxProps>) {
    if (prevProps.data?.length !== this.props.data?.length) {
      const { modalStore } = this.props;
      modalStore?.refComboBoxContainer.current?.update();
    }
  }

  onSelect(value: string) {
    const { publicStore } = this.props;
    const data = this.props.data || [];
    this.toggle();

    for (let i = 0; i < data.length; i += 1) {
      if (data[i]?.value === value) {
        this.props.onSelect(data[i] || new ComboBoxModel('', ''));

        if (!this.props.isDisabledTrackingStateChange) {
          publicStore?.setStateChanged(true);
        }
        return;
      }
    }
  }

  @computed
  get remark() {
    const rawData = (this.props.data || []);
    const fp = rawData.filter((x) => x?.value === this.props.value);
    if (fp.length > 0) {
      return fp[0]?.remark;
    }
    return this.props.value;
  }

  @action
  toggle() {
    const { modalStore } = this.props;
    if (this.props.readonly) return;
    this.showComboBox();
    this.setState({
      isVisibleDropDown: !this.state.isVisibleDropDown,
    }, () => {
      if (this.state.isVisibleDropDown) {
        modalStore?.refComboBoxInput.current?.focus();
      } else {
        this.closeComboBox();
      }
    });
  }

  @action
  async showComboBox() {
    const { modalStore } = this.props;

    if (!modalStore?.isVisibleComboBox || modalStore?.refComboBox !== this) {
      modalStore?.openComboBox(this, this.ref.current?.clientWidth || 0, this.ref.current?.clientHeight || 0);
      this.setState({
        popupLeft: (this.ref.current?.offsetLeft || 0),
        isPopupForward: (this.ref.current?.parentElement?.getBoundingClientRect().y || 0)
          / window.innerHeight <= 0.6,
      });
    }
  }

  @action
  closeComboBox() {
    const { modalStore } = this.props;

    if (modalStore?.isVisibleComboBox && modalStore?.refComboBox === this) {
      modalStore.closeComboBox();
    }
  }

  render() {
    const gravity = getGravityString(this.props.textAlign);
    const textPadding = getGravityPadding(this.props.textAlign);

    return (
      <div
        role="overflow"
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.readonly ? style.readonly : null,
        ])}
      >
        <div
          ref={this.ref}
          className={style.view}
          style={{
            ...{
              padding: textPadding,
              fontSize: 12,
              justifyContent: gravity,
            },
            ...this.props.style,
          }}
          onClick={() => this.toggle()}
        >
          {this.remark}
        </div>
      </div>
    );
  }
}
