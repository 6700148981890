import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ManagementTemplate } from './Management.template';
import { ManagementModel } from './Management.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface ManagementState {
  year: string;
  mpclafi: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // data
  data: ManagementModel;
  ManagementList: Array<ManagementModel>;
  focused?: ManagementModel;

  // trail
  totamt_tot: string;
  average_tot: string;
  mon01_tot: string;
  mon02_tot: string;
  mon03_tot: string;
  mon04_tot: string;
  mon05_tot: string;
  mon06_tot: string;
  mon07_tot: string;
  mon08_tot: string;
  mon09_tot: string;
  mon10_tot: string;
  mon11_tot: string;
  mon12_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_03
 * @category 월관리대수현황-담당별
 */
export class Management extends PageComponent<PageProps, ManagementState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      mpclafi: '0',
      divicd: '',
      perid: '',
      ManagementList: [],

      // trail
      totamt_tot: '0',
      average_tot: '0',
      mon01_tot: '0',
      mon02_tot: '0',
      mon03_tot: '0',
      mon04_tot: '0',
      mon05_tot: '0',
      mon06_tot: '0',
      mon07_tot: '0',
      mon08_tot: '0',
      mon09_tot: '0',
      mon10_tot: '0',
      mon11_tot: '0',
      mon12_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        mpclafi: this.state.mpclafi,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          ManagementList: [
            ...this.state.ManagementList,
            ...items.map((x: any) => new ManagementModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          ManagementList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.ManagementList && this.state.ManagementList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      ManagementList: [],
    }, async () => {
      await this.infinity?.retrieveAll();

      this.SS({
        totamt_tot: this.infinity?.box?.totamt_tot || '0',
        average_tot: this.infinity?.box?.average_tot || '0',
        mon01_tot: this.infinity?.box?.mon01_tot || '0',
        mon02_tot: this.infinity?.box?.mon02_tot || '0',
        mon03_tot: this.infinity?.box?.mon03_tot || '0',
        mon04_tot: this.infinity?.box?.mon04_tot || '0',
        mon05_tot: this.infinity?.box?.mon05_tot || '0',
        mon06_tot: this.infinity?.box?.mon06_tot || '0',
        mon07_tot: this.infinity?.box?.mon07_tot || '0',
        mon08_tot: this.infinity?.box?.mon08_tot || '0',
        mon09_tot: this.infinity?.box?.mon09_tot || '0',
        mon10_tot: this.infinity?.box?.mon10_tot || '0',
        mon11_tot: this.infinity?.box?.mon11_tot || '0',
        mon12_tot: this.infinity?.box?.mon12_tot || '0',
      });

      this.onRetrieveEvent2();
    });
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        year: this.state.year,
        mpclafi: this.state.mpclafi,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
    );
    if (data) {
      this.setState({
        data,
      });
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.ManagementList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      mpclafi: this.state.mpclafi,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.ManagementList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      mpclafi: this.state.mpclafi,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  render() {
    return (
      <ManagementTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
