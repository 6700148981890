// eslint-disable-next-line max-classes-per-file
import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ia090
 * @category 전자세금계산서발행
 */
export class ElectronicTaxModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 세금계산서일자
   *
   * **data type** varchar(8)
   */
  spdate: string;

  /**
   * 세금계산서번호
   *
   * **data type** varchar(4)
   */
  spnum: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 금액
   *
   * **data type** float
   */
  suprice: string;

  /**
   * 부가세
   *
   * **data type** float
   */
  taxamt: string;

  /**
   * 현금
   *
   * **data type** float
   */
  cash: string;

  /**
   * 수표
   *
   * **data type** float
   */
  chk: string;

  /**
   * 어름
   *
   * **data type** float
   */
  draft: string;

  /**
   * 전자수기발급
   *
   * **data type** float
   */
  trureci: string;

  /**
   * 영세율
   *
   * **data type** varchar(3)
   */
  eclafi: string;

  /**
   * 세액공제
   *
   * **data type** varchar(2)
   */
  taxreclafi: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remarks: string;

  /**
   * 발행일자
   *
   * **data type** varchar(8)
   */
  pubdate: string;

  /**
   * 책번호(권)
   *
   * **data type** varchar(6)
   */
  booknum: string;

  /**
   * 책번호(호)
   *
   * **data type** varchar(6)
   */
  bookho: string;

  /**
   * 일련번호
   *
   * **data type** varchar(6)
   */
  serinum: string;

  /**
   * 일반고정자산
   *
   * **data type** varchar(2)
   */
  occuclafi: string;

  /**
   * 발행구분(영수청구)
   *
   * **data type** varchar(1)
   */
  receiptyn: string;

  /**
   * 품목발행년
   *
   * **data type** varchar(4)
   */
  pubdatey: string;

  /**
   * 품목발행월
   *
   * **data type** varchar(2)
   */
  pubdatem: string;

  /**
   * 품목발행일
   *
   * **data type** varchar(2)
   */
  pubdated: string;

  /**
   * 품목거래처1
   *
   * **data type** varchar(40)
   */
  artclnm1: string;

  /**
   * 품목거래처2
   *
   * **data type** varchar(40)
   */
  artclnm2: string;

  /**
   * 품목거래처3
   *
   * **data type** varchar(40)
   */
  artclnm3: string;

  /**
   * 품목규격1
   *
   * **data type** varchar(40)
   */
  standard1: string;

  /**
   * 품목규격2
   *
   * **data type** varchar(40)
   */
  standard2: string;

  /**
   * 품목규격3
   *
   * **data type** varchar(40)
   */
  standard3: string;

  /**
   * 품목규격4
   *
   * **data type** varchar(40)
   */
  standard4: string;

  /**
   * 품목수량1
   *
   * **data type** float
   */
  qty1: string;

  /**
   * 품목수량2
   *
   * **data type** float
   */
  qty2: string;

  /**
   * 품목수량3
   *
   * **data type** float
   */
  qty3: string;

  /**
   * 품목수량4
   *
   * **data type** float
   */
  qty4: string;

  /**
   * 품목단가1
   *
   * **data type** float
   */
  uprice1: string;

  /**
   * 품목단가2
   *
   * **data type** float
   */
  uprice2: string;

  /**
   * 품목단가3
   *
   * **data type** float
   */
  uprice3: string;

  /**
   * 품목단가4
   *
   * **data type** float
   */
  uprice4: string;

  /**
   * 품목금액1
   *
   * **data type** float
   */
  suprice1: string;

  /**
   * 품목금액2
   *
   * **data type** float
   */
  suprice2: string;

  /**
   * 품목금액3
   *
   * **data type** float
   */
  suprice3: string;

  /**
   * 품목금액4
   *
   * **data type** float
   */
  suprice4: string;

  /**
   * 품목부가세1
   *
   * **data type** float
   */
  taxamt1: string;

  /**
   * 품목부가세2
   *
   * **data type** float
   */
  taxamt2: string;

  /**
   * 품목부가세3
   *
   * **data type** float
   */
  taxamt3: string;

  /**
   * 품목부가세4
   *
   * **data type** float
   */
  taxamt4: string;

  /**
   * 연결분개일자
   *
   * **data type** varchar(8)
   */
  orgspdate: string;

  /**
   * 연결분개번호
   *
   * **data type** varchar(8)
   */
  orgspnum: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 계정과목
   *
   * **data type** char(3)
   */
  gacccd: string;

  /**
   * 출고구분
   *
   * **data type** char(1)
   */
  delflag: string;

  /**
   * 할인구분
   *
   * **data type** char(2)
   */
  saleflag: string;

  /**
   * 현장코드
   *
   * **data type** varchar(12)
   */
  actcd: string;

  /**
   * 연결분개순번
   *
   * **data type** varchar(4)
   */
  orgspseq: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 대표자명
   *
   * **data type** varchar(30)
   */
  prenm: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(13)
   */
  saupnum: string;

  /**
   * 주민번호
   *
   * **data type** varchar(13)
   */
  prenum: string;

  /**
   * 업태
   *
   * **data type** varchar(100)
   */
  biztypenm: string;

  /**
   * 종목
   *
   * **data type** varchar(100)
   */
  bizitemnm: string;

  /**
   * 주소
   *
   * **data type** varchar(6)
   */
  zipcd: string;

  /**
   * 주소
   *
   * **data type** varchar(100)
   */
  cltadres: string;

  /**
   * 메일
   *
   * **data type** varchar(100)
   */
  taxmail: string;

  /**
   * 품목비고1
   *
   * **data type** varchar(255)
   */
  remark1: string;

  /**
   * 품목비고2
   *
   * **data type** varchar(255)
   */
  remark2: string;

  /**
   * 품목비고3
   *
   * **data type** varchar(255)
   */
  remark3: string;

  /**
   * 품목비고4
   *
   * **data type** varchar(255)
   */
  remark4: string;

  /**
   * 계산서발급번호
   *
   * **data type** varchar(70)
   */
  MSGNUMBER_ORG: string;

  /**
   * 계산서구분
   *
   * **data type** varchar(2)
   */
  taxflag: string;

  /**
   * 수정코드
   *
   * **data type** varchar(2)
   */
  AmendmentStatusCode: string;

  /**
   * 발급코드
   *
   * **data type** varchar(35)
   */
  MAINKEY: string;

  /**
   * 제출번호
   *
   * **data type** varchar(35)
   */
  DocumentIdentifier: string;

  /**
   * 응답유형
   *
   * **data type** varchar(17)
   */
  ResponseType: string;

  /**
   * 오류(거부)메시지1
   *
   * **data type** varchar(70)
   */
  MessageInformation1: string;

  /**
   * 오류(거부)메시지2
   *
   * **data type** varchar(70)
   */
  MessageInformation2: string;

  /**
   * 오류(거부)메시지3
   *
   * **data type** varchar(70)
   */
  MessageInformation3: string;

  /**
   * 오류(거부)메시지4
   *
   * **data type** varchar(70)
   */
  MessageInformation4: string;

  /**
   * 오류(거부)메시지5
   *
   * **data type** varchar(70)
   */
  MessageInformation5: string;

  /**
   * 오류(거부)메시지6
   *
   * **data type** varchar(70)
   */
  MessageInformation6: string;

  /**
   * 오류(거부)메시지7
   *
   * **data type** varchar(70)
   */
  MessageInformation7: string;

  /**
   * 오류(거부)메시지8
   *
   * **data type** varchar(70)
   */
  MessageInformation8: string;

  /**
   * 오류(거부)메시지9
   *
   * **data type** varchar(70)
   */
  MessageInformation9: string;

  /**
   * 오류(거부)메시지10
   *
   * **data type** varchar(70)
   */
  MessageInformation10: string;

  /**
   * 발신일
   *
   * **data type** varchar(35)
   */
  SendDate: string;

  /**
   * 수신자번호
   *
   * **data type** varchar(35)
   */
  RecipientIdentifier: string;

  /**
   * 발신자번호
   *
   * **data type** varchar(35)
   */
  SenderIdentifier: string;

  /**
   * 응답수신시간
   *
   * **data type** varchar(14)
   */
  RegistDate: string;

  /**
   * 발급종류
   *
   * **data type** varchar(14)
   */
  classcode: string;

  /**
   * 신고구분
   *
   * **data type** varchar(3)
   */
  documentfunccode: string;

  /**
   * 계산서종류
   *
   * **data type** varchar(2)
   */
  taxbilltypecode: string;

  /**
   * 역발행
   *
   * **data type** varchar(1)
   */
  reverseissue: string;

  /**
   * 승인번호
   *
   * **data type** varchar(70)
   */
  msgnumber: string;

  /**
   * 응답상태
   *
   * **data type** varchar(2)
   */
  recivstatus: string;

  /**
   * 발급자
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 분류
   *
   * **data type** varchar(2)
   */
  regflag: string;

  /**
   * 발급일자
   *
   * **data type** varchar(8)
   */
  regdate: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 담당핸드폰번호
   *
   * **data type** varchar(30)
   */
  taxsms: string;

  /**
   * 담당이메일
   *
   * **data type** varchar(201)
   */
  agneremail: string;

  /**
   * 합계금액
   *
   * **data type** varchar(3)
   */
  mamt: string;

  /**
   * no
   *
   * **data type** varchar(3)
   */
  no: string;

  /**
   * 품목거래처4
   *
   * **data type** varchar(40)
   */
  artclnm4: string;

  /**
   * 발급코드
   *
   * **data type** varchar(35)
   */
  mainkey: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  selchk: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  docuno: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  pname: string;

  /**
   * 상태
   *
   * **data type** varchar(1)
   */
  state: string;

  /**
   * 폐업날짜
   *
   * **data type** varchar(1)
   */
  state_enddate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.docuno = data.docuno || '';
    this.selchk = data.selchk || '';
    this.mainkey = data.mainkey || '';
    this.spdate = data.spdate || '';
    this.spnum = data.spnum || '';
    this.cltcd = data.cltcd || '';
    this.suprice = data.suprice || '';
    this.taxamt = data.taxamt || '';
    this.cash = data.cash || '';
    this.chk = data.chk || '';
    this.draft = data.draft || '';
    this.trureci = data.trureci || '';
    this.eclafi = data.eclafi || '';
    this.taxreclafi = data.taxreclafi || '';
    this.remarks = data.remarks || '';
    this.pubdate = data.pubdate || '';
    this.booknum = data.booknum || '';
    this.bookho = data.bookho || '';
    this.serinum = data.serinum || '';
    this.occuclafi = data.occuclafi || '';
    this.receiptyn = data.receiptyn || '';
    this.pubdatey = data.pubdatey || '';
    this.pubdatem = data.pubdatem || '';
    this.pubdated = data.pubdated || '';
    this.artclnm1 = data.artclnm1 || '';
    this.artclnm2 = data.artclnm2 || '';
    this.artclnm3 = data.artclnm3 || '';
    this.standard1 = data.standard1 || '';
    this.standard2 = data.standard2 || '';
    this.artclnm4 = data.artclnm4 || '';
    this.standard3 = data.standard3 || '';
    this.standard4 = data.standard4 || '';
    this.qty1 = data.qty1 || '';
    this.qty2 = data.qty2 || '';
    this.qty3 = data.qty3 || '';
    this.qty4 = data.qty4 || '';
    this.uprice1 = data.uprice1 || '';
    this.uprice2 = data.uprice2 || '';
    this.uprice3 = data.uprice3 || '';
    this.uprice4 = data.uprice4 || '';
    this.suprice1 = data.suprice1 || '';
    this.suprice2 = data.suprice2 || '';
    this.suprice3 = data.suprice3 || '';
    this.suprice4 = data.suprice4 || '';
    this.taxamt1 = data.taxamt1 || '';
    this.taxamt2 = data.taxamt2 || '';
    this.taxamt3 = data.taxamt3 || '';
    this.taxamt4 = data.taxamt4 || '';
    this.orgspdate = data.orgspdate || '';
    this.orgspnum = data.orgspnum || '';
    this.divicd = data.divicd || '';
    this.gacccd = data.gacccd || '';
    this.delflag = data.delflag || '';
    this.saleflag = data.saleflag || '';
    this.actcd = data.actcd || '';
    this.orgspseq = data.orgspseq || '';
    this.cltnm = data.cltnm || '';
    this.prenm = data.prenm || '';
    this.saupnum = data.saupnum || '';
    this.prenum = data.prenum || '';
    this.biztypenm = data.biztypenm || '';
    this.bizitemnm = data.bizitemnm || '';
    this.zipcd = data.zipcd || '';
    this.cltadres = data.cltadres || '';
    this.taxmail = data.taxmail || '';
    this.remark1 = data.remark1 || '';
    this.remark2 = data.remark2 || '';
    this.remark3 = data.remark3 || '';
    this.remark4 = data.remark4 || '';
    this.MSGNUMBER_ORG = data.MSGNUMBER_ORG || '';
    this.taxflag = data.taxflag || '';
    this.AmendmentStatusCode = data.AmendmentStatusCode || '';
    this.MAINKEY = data.MAINKEY || '';
    this.DocumentIdentifier = data.DocumentIdentifier || '';
    this.ResponseType = data.ResponseType || '';
    this.MessageInformation1 = data.MessageInformation1 || '';
    this.MessageInformation2 = data.MessageInformation2 || '';
    this.MessageInformation3 = data.MessageInformation3 || '';
    this.MessageInformation4 = data.MessageInformation4 || '';
    this.MessageInformation5 = data.MessageInformation5 || '';
    this.MessageInformation6 = data.MessageInformation6 || '';
    this.MessageInformation7 = data.MessageInformation7 || '';
    this.MessageInformation8 = data.MessageInformation8 || '';
    this.MessageInformation9 = data.MessageInformation9 || '';
    this.MessageInformation10 = data.MessageInformation10 || '';
    this.SendDate = data.SendDate || '';
    this.RecipientIdentifier = data.RecipientIdentifier || '';
    this.SenderIdentifier = data.SenderIdentifier || '';
    this.RegistDate = data.RegistDate || '';
    this.classcode = data.classcode || '';
    this.documentfunccode = data.documentfunccode || '';
    this.taxbilltypecode = data.taxbilltypecode || '';
    this.reverseissue = data.reverseissue || '';
    this.msgnumber = data.msgnumber || '';
    this.recivstatus = data.recivstatus || '';
    this.pernm = data.pernm || '';
    this.regflag = data.regflag || '';
    this.regdate = data.regdate || '';
    this.bigo = data.bigo || '';
    this.taxsms = data.taxsms || '';
    this.agneremail = data.agneremail || '';
    this.mamt = data.mamt || '';
    this.no = data.no || '';
    this.pname = data.pname || '';
    this.state = data.state || '';
    this.state_enddate = data.state_enddate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  updateChk(selchk: string) {
    this.selchk = selchk;
    return this;
  }
}

export class ProductChoiceAllModel {
  readonly chcd: string;

  readonly chnm: string;

  constructor(item: any) {
    this.chcd = (item.chcd || '').trim();
    this.chnm = (item.chnm || '').trim();
  }
}

export class ProductTypeModel {
  readonly phm_mode: string;

  readonly phm_modenm: string;

  constructor(item: any) {
    this.phm_mode = (item.phm_mode || '').trim();
    this.phm_modenm = (item.phm_modenm || '').trim();
  }
}

export class ProductBigModel {
  readonly pgr_hcod: string;

  readonly pgr_hnam: string;

  constructor(item: any) {
    this.pgr_hcod = (item.pgr_hcod || '').trim();
    this.pgr_hnam = (item.pgr_hnam || '').trim();
  }
}

export class ProductMiddleModel {
  readonly bgroup: string;

  readonly bgrpnm: string;

  constructor(item: any) {
    this.bgroup = (item.bgroup || '').trim();
    this.bgrpnm = (item.bgrpnm || '').trim();
  }
}

export class ProductSmallModel {
  readonly cgroup: string;

  readonly cgrpnm: string;

  constructor(item: any) {
    this.cgroup = (item.cgroup || '').trim();
    this.cgrpnm = (item.cgrpnm || '').trim();
  }
}
