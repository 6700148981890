import {
  FaCodeBranch,
  FaMaxcdn,
  FaYoast,
} from 'react-icons/all';
import { TabModel } from '../../../../models';
import { PurchasingStatisticsTenYearTemplate } from './tabs/PurchasingStatisticsTenYear.template';
import { PurchasingStatistics } from './PurchasingStatistics';
import { TabLayoutDataItem } from '../../../../constants';
import { PurchasingStatisticsMonthTemplate } from './tabs/PurchasingStatisticsMonth.template';
import { PurchasingStatisticsActcdTemplate } from './tabs/PurchasingStatisticsActcd.template';

export const PurchasingStatisticsTab: Array<TabLayoutDataItem<PurchasingStatistics>> = [
  {
    tab: new TabModel('Ten', FaYoast, '10년간 매입지급내역'),
    template: (scope) => <PurchasingStatisticsTenYearTemplate scope={scope} />,
  },
  {
    tab: new TabModel('Month', FaMaxcdn, '월별 매입지급내역'),
    template: (scope) => <PurchasingStatisticsMonthTemplate scope={scope} />,
  },
  {
    tab: new TabModel('Actcd', FaCodeBranch, '거래처별 매입지급내역'),
    template: (scope) => <PurchasingStatisticsActcdTemplate scope={scope} />,
  },
];
