import * as React from 'react';
import { action } from 'mobx';
import { TodayPlanTemplate } from './TodayPlan.template';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { TodayPlanModel } from './models';
import { InfinityRetrieve } from '../../../../models/common';
import { DragAndDropLayout } from '../../../../components/layout/DragAndDropLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface UnpaidBoxState {

  // 검색조건
  stdate: string; // 기간
  searchQuery: string; // 검색어

  // 데이터 객체
  focusedTodayPlan?: TodayPlanModel;
  todayPlanList: Array<TodayPlanModel>;
  data: TodayPlanModel;
}

/*
 * 컨트롤러
 * @window w_tb_e037w_03
 * @category 금일계획 조회
*/

export class TodayPlan extends PageComponent<PageProps, UnpaidBoxState>
  implements PageToolEvents {
  updatedRows?: Array<TodayPlanModel>;

  dragAndDrop: React.RefObject<DragAndDropLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}${date}`, // 기간
      searchQuery: '',
      data: new TodayPlanModel(),
      todayPlanList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          todayPlanList: [...this.state.todayPlanList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ todayPlanList: [] });
        await this.infinity?.retrieveAll();
        await this.dragAndDrop.current?.setFocus(0);
        await this.dragAndDrop.current?.update();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      todayPlanList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['seq', 'perid'],
        [this.state.focusedTodayPlan?.seq, this.state.focusedTodayPlan?.perid], type, true) || 0;
      this.setState({ data: new TodayPlanModel(this.infinity?.box) });
      await this.dragAndDrop.current?.update();
      if (this.state.todayPlanList && this.state.todayPlanList.length > index) {
        this.dragAndDrop.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: TodayPlanModel) {
    this.setState({ focusedTodayPlan: item });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      stdate: this.state.stdate,
      items: this.state.todayPlanList.map((x, i) => new TodayPlanModel({
        ...x,
        seq: `00${i + 1}`.substr(-3),
        sort: `0${i + 1}`.substr(-2),
        input: '1',
      }, x.isNew)),
    }, false)) {
      this.updatedRows = [];
      await this.onRetrieveEvent();
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    await api.excel({
      stdate: this.state.stdate,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.todayPlanList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onUpdatedRows(rows: Array<TodayPlanModel>, updatedRows: Array<TodayPlanModel>) {
    this.updatedRows = updatedRows;
    this.setState({ todayPlanList: rows });
  }

  render() {
    return (
      <TodayPlanTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new TodayPlanModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
