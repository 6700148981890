import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_tb_da003_01
 * @category 프로젝트코드등록
 */
export class ProjectCodeModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(8)
   */
  prodate: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 프로젝트명2
   *
   * **data type** varchar(100)
   */
  worknm: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 출고일자
   *
   * **data type** char(8)
   */
  deldate: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 공금가금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 부가세액
   *
   * **data type** float
   */
  tamt: string;

  /**
   * 금액
   *
   * **data type** float
   */
  mamt: string;

  /**
   * 입력일
   *
   * **data type** char(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  taxcls: string;

  /**
   * 현장명
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 공사만료일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  orddate: string;

  /**
   * 계약번호
   *
   * **data type** varchar(4)
   */
  ordnum: string;

  /**
   * 상태
   *
   * **data type** varchar(1)
   */
  state: string;

  /**
   * 구분
   *
   * **data type** varchar(5)
   */
  gubun: string;

  /**
   * 연결견적일자
   *
   * **data type** varchar(8)
   */
  costdate: string;

  /**
   * 연결견적번호
   *
   * **data type** varchar(4)
   */
  costnum: string;

  /**
   * 연결공사일자
   *
   * **data type** varchar(8)
   */
  compdate: string;

  /**
   * 연결공사번호
   *
   * **data type** varchar(4)
   */
  compnum: string;

  /**
   * 연결입금일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 연결입금번호
   *
   * **data type** varchar(8)
   */
  rcvnum: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  appdate: string;

  /**
   * 결재상태
   *
   * **data type** varchar(8)
   */
  appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  appperid: string;

  /**
   * 결재비고
   *
   * **data type** text
   */
  appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  refer: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  apptime: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 계약일자
   *
   * **data type** datetime
   */
  datetime: string;

  /**
   * 공사시작일자
   *
   * **data type** datetime
   */
  datetime2: string;

  /**
   * 공사만료일
   *
   * **data type** datetime
   */
  datetime3: string;

  /**
   * 입력자
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 거래처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.projno = data.projno || '';
    this.prodate = data.prodate || '';
    this.projnm = data.projnm || '';
    this.worknm = data.worknm || '';
    this.cltcd = data.cltcd || '';
    this.deldate = data.deldate || '';
    this.divicd = data.divicd || '';
    this.perid = data.perid || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.taxcls = data.taxcls || '';
    this.actcd = data.actcd || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.state = data.state || '';
    this.gubun = data.gubun || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvnum = data.rcvnum || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.refer = data.refer || '';
    this.apptime = data.apptime || '';
    this.actnm = data.actnm || '';
    this.datetime = data.datetime || '';
    this.datetime2 = data.datetime2 || '';
    this.datetime3 = data.datetime3 || '';
    this.pernm = data.pernm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.cltnm = data.cltnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
