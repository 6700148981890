import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';
import { PurchaseTax } from './PurchaseTax';

/**
 * 화면
 * @window w_tb_ca640w_02
 * @category 매입세금계산서[미지급현황]
 */
export const PurchaseTaxTemplate: React.FC<TemplateProps<PurchaseTax>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout
          style={{ marginLeft: 27 }}
          size={200}
        >
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>항목</span>
          <TextBox
            textAlign="center"
            value={scope.state.artcd}
            onChange={(value) => setData({ artcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_CA648_ARG',
              {},
              true,
              (item) => {
                setData({
                  artcd: item.cd,
                  artnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.artnm}
            readonly={true}
            onChange={(value) => setData({ artnm: value })}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span>경비증빙구분</span>
          <ComboBox
            value={scope.state.proof}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '미증빙' },
              { value: '1', remark: '증빙' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ proof: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout
          style={{ marginLeft: 10 }}
          size={330}
        >
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 프로젝트, 참고(비고)로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout weight={8}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'mijdate',
              text: '매입일자',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.mijdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'icdate',
              text: '만료일자',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  건수: {scope.state.total}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.icdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'mijamt',
              text: '매입금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {scope.state.mijamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.mijamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'sndamt',
              text: '지급액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.sndamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.sndamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt',
              text: '미지급금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.iamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.iamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'mijcltnm',
              text: '거래처명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.mijcltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'artnm',
              text: '항목명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.artnm}
                </FlexLayout>
              ),
            },
            {
              id: 'proof',
              text: '경비증빙',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.proof === '1' ? '증빙' : '미증빙'}
                </FlexLayout>
              ),
            },
            {
              id: 'projnm',
              text: '프로젝트명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.projnm}
                </FlexLayout>
              ),
            },
            {
              id: 'ordamt',
              text: '수주금액',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.ordamt === null ? '0' : Format.number(x.ordamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '매출금액',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.misamt === null ? '0' : Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt',
              text: '입금액',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.rcvamt === null ? '0' : Format.number(x.rcvamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'ording',
              text: '수주진행률',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {x.ording}
                </FlexLayout>
              ),
            },
            {
              id: 'misfer',
              text: '매출진행률',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.misfer}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onRowDoubleClick={() => scope.onClickOpenBalEnrollment()}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
