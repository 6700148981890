import * as React from 'react';
import {
  FlexLayout, TableLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Card } from './Card';
import { CardModel } from './Card.model';


/**
 * 화면
 * @window w_card_insert
 * @category 카드사등록
 */
export const CardTemplate: React.FC<TemplateProps<Card>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'cd',
          text: '카드사코드',
          width: 10,
        },
        {
          id: 'nm',
          text: '카드사명',
          width: 45,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.nm}
              onChange={(value) => {
                rowUpdate({ nm: value });
                scope.SS({
                  focused: new CardModel({
                    ...scope.state.focused,
                    nm: value,
                  }, scope.state.focused?.isNew),
                });
              }
              }
            />
          ),
        },
        {
          id: 'persent',
          text: '요율',
          width: 10,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.persent}
              onChange={(value) => rowUpdate({ persent: value })}
            />
          ),
        },
        {
          id: 'custnm',
          text: '비고',
          width: 45,
        },
      ]}
      data={scope.state.cardList}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
