export default class CalendarLayoutDataModel<T> {
  readonly date: string;

  readonly data: T;

  /**
   * 생성자
   * @param date 8자리 날짜 (ex: 20201105)
   * @param data 달력에서 사용할 데이터
   */
  constructor(date: string, data: T) {
    this.date = date;
    this.data = data;
  }
}
