import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642w_13
 * @category 월매출매입현황
 */
export class SalesPurchaseModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  mon: string;

   /**
   * 매출금액
   *
   * **data type** number
   */
   misamt: string;

   /**
   * 매입금액
   *
   * **data type** number
   */
   mijamt: string;

   /**
   * 차액
   *
   * **data type** number
   */
   resuamt: string;

   /**
   * 코드
   *
   * **data type** char(3)
   */
   code: string;

   /**
   * 매출구분
   *
   * **data type** char(50)
   */
   cnam: string;

   /**
   * 해제
   *
   * **data type** char(1)
   */
   chk: string;

   /**
   * 사업장코드
   *
   * **data type** char(2)
   */
   spjangcd: string;

   /**
   * 사업장명
   *
   * **data type** char(40)
   */
   spjangnm: string;

  /**
   * 입금금액01
   *
   * **data type** number
   */
  rcvamt01: string;

  /**
   * 입금금액02
   *
   * **data type** number
   */
  rcvamt02: string;

  /**
   * 입금금액03
   *
   * **data type** number
   */
  rcvamt03: string;

  /**
   * 입금금액04
   *
   * **data type** number
   */
  rcvamt04: string;

  /**
   * 입금금액05
   *
   * **data type** number
   */
  rcvamt05: string;

  /**
   * 입금금액06
   *
   * **data type** number
   */
  rcvamt06: string;

  /**
   * 입금금액07
   *
   * **data type** number
   */
  rcvamt07: string;

  /**
   * 입금금액08
   *
   * **data type** number
   */
  rcvamt08: string;

  /**
   * 입금금액09
   *
   * **data type** number
   */
  rcvamt09: string;

  /**
   * 입금금액10
   *
   * **data type** number
   */
  rcvamt10: string;

  /**
   * 입금금액11
   *
   * **data type** number
   */
  rcvamt11: string;

  /**
   * 입금금액12
   *
   * **data type** number
   */
  rcvamt12: string;

  /**
   * 지급금액01
   *
   * **data type** number
   */
  sndamt01: string;

  /**
   * 지급금액02
   *
   * **data type** number
   */
  sndamt02: string;

  /**
   * 지급금액03
   *
   * **data type** number
   */
  sndamt03: string;

  /**
   * 지급금액04
   *
   * **data type** number
   */
  sndamt04: string;

  /**
   * 지급금액05
   *
   * **data type** number
   */
  sndamt05: string;

  /**
   * 지급금액06
   *
   * **data type** number
   */
  sndamt06: string;

  /**
   * 지급금액07
   *
   * **data type** number
   */
  sndamt07: string;

  /**
   * 지급금액08
   *
   * **data type** number
   */
  sndamt08: string;

  /**
   * 지급금액09
   *
   * **data type** number
   */
  sndamt09: string;

  /**
   * 지급금액10
   *
   * **data type** number
   */
  sndamt10: string;

  /**
   * 지급금액11
   *
   * **data type** number
   */
  sndamt11: string;

  /**
   * 지급금액12
   *
   * **data type** number
   */
  sndamt12: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt01: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt02: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt03: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt04: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt05: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt06: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt07: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt08: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt09: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt10: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt11: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt12: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt01: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt02: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt03: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt04: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt05: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt06: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt07: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt08: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt09: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt10: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt11: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt12: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt01: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt02: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt03: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt04: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt05: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt06: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt07: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt08: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt09: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt10: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt11: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt12: string;

  /**
   * 합계
   *
   * **data type** number
   */
  mijamttot: string;

  /**
   * 합계
   *
   * **data type** number
   */
  misamttot: string;

  /**
   * 합계
   *
   * **data type** number
   */
  resuamttot: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.mon = data.mon || '';
    this.misamt = data.misamt || '';
    this.mijamt = data.mijamt || '';
    this.resuamt = data.resuamt || '';
    this.code = data.code || '';
    this.cnam = data.cnam || '';
    this.chk = data.chk || '';
    this.mijamttot = data.mijamttot || '';
    this.misamttot = data.misamttot || '';
    this.resuamttot = data.resuamttot || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.rcvamt01 = data.rcvamt01 || '';
    this.rcvamt02 = data.rcvamt02 || '';
    this.rcvamt03 = data.rcvamt03 || '';
    this.rcvamt04 = data.rcvamt04 || '';
    this.rcvamt05 = data.rcvamt05 || '';
    this.rcvamt06 = data.rcvamt06 || '';
    this.rcvamt07 = data.rcvamt07 || '';
    this.rcvamt08 = data.rcvamt08 || '';
    this.rcvamt09 = data.rcvamt09 || '';
    this.rcvamt10 = data.rcvamt10 || '';
    this.rcvamt11 = data.rcvamt11 || '';
    this.rcvamt12 = data.rcvamt12 || '';
    this.sndamt01 = data.sndamt01 || '';
    this.sndamt02 = data.sndamt02 || '';
    this.sndamt03 = data.sndamt03 || '';
    this.sndamt04 = data.sndamt04 || '';
    this.sndamt05 = data.sndamt05 || '';
    this.sndamt06 = data.sndamt06 || '';
    this.sndamt07 = data.sndamt07 || '';
    this.sndamt08 = data.sndamt08 || '';
    this.sndamt09 = data.sndamt09 || '';
    this.sndamt10 = data.sndamt10 || '';
    this.sndamt11 = data.sndamt11 || '';
    this.sndamt12 = data.sndamt12 || '';
    this.mijamt01 = data.mijamt01 || '';
    this.mijamt02 = data.mijamt02 || '';
    this.mijamt03 = data.mijamt03 || '';
    this.mijamt04 = data.mijamt04 || '';
    this.mijamt05 = data.mijamt05 || '';
    this.mijamt06 = data.mijamt06 || '';
    this.mijamt07 = data.mijamt07 || '';
    this.mijamt08 = data.mijamt08 || '';
    this.mijamt09 = data.mijamt09 || '';
    this.mijamt10 = data.mijamt10 || '';
    this.mijamt11 = data.mijamt11 || '';
    this.mijamt12 = data.mijamt12 || '';
    this.misamt01 = data.misamt01 || '';
    this.misamt02 = data.misamt02 || '';
    this.misamt03 = data.misamt03 || '';
    this.misamt04 = data.misamt04 || '';
    this.misamt05 = data.misamt05 || '';
    this.misamt06 = data.misamt06 || '';
    this.misamt07 = data.misamt07 || '';
    this.misamt08 = data.misamt08 || '';
    this.misamt09 = data.misamt09 || '';
    this.misamt10 = data.misamt10 || '';
    this.misamt11 = data.misamt11 || '';
    this.misamt12 = data.misamt12 || '';
    this.resuamt01 = data.resuamt01 || '';
    this.resuamt02 = data.resuamt02 || '';
    this.resuamt03 = data.resuamt03 || '';
    this.resuamt04 = data.resuamt04 || '';
    this.resuamt05 = data.resuamt05 || '';
    this.resuamt06 = data.resuamt06 || '';
    this.resuamt07 = data.resuamt07 || '';
    this.resuamt08 = data.resuamt08 || '';
    this.resuamt09 = data.resuamt09 || '';
    this.resuamt10 = data.resuamt10 || '';
    this.resuamt11 = data.resuamt11 || '';
    this.resuamt12 = data.resuamt12 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
