import { computed } from 'mobx';

/**
 * 미수금현황모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class AccountDueModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 현장코드
   *
   * **data type** char(15)
   */
  actcd: string;

  /**
   * 전월미수금
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 수금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 미수금
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 진행
   *
   * **data type** char(8)
   */
  state: string;

  /**
   * 진행사항
   *
   * **data type** char(255)
   */
  remark: string;

  /**
   * 담당자
   *
   * **data type** char(100)
   */
  pernm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.actcd = data.actcd || '';
    this.beamt = data.beamt || '';
    this.rcvamt = data.rcvamt || '';
    this.resuamt = data.resuamt || '';
    this.remark = data.remark || '';
    this.state = data.state || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
