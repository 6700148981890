import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca606_01_2
 * @category 자재청구서
 */

export class BillModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  pumdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  pumnum: string;

  /**
   * 상태
   *
   * **data type** varchar(20)
   */
  readonly appgubun: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 작성자명
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   * 계약현장명
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(4)
   */
  readonly contgubun: string;

  /**
   * 청구자번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 청구자명
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 청구자부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 청구자부서명
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 청구금액
   *
   * **data type** float
   */
  readonly samt: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 첨부파일
   *
   * **data type** int
   */
  readonly attcnt: number;

  /**
   *
   *
   * **data type** int
   */
  readonly taxcls: number;

  /**
   *
   *
   * **data type**
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type**
   */
  readonly year: string;

  /**
   *
   *
   * **data type**
   */
  readonly num: string;

  /**
   *
   *
   * **data type**
   */
  readonly flag: string;

  /**
   *
   *
   * **data type**
   */
  readonly reqdt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.new = isNew ? '1' : '0';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.indate = data.indate || '';
    this.title = data.title || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.contgubun = data.contgubun || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.refer = data.refer || '';
    this.attcnt = data.attcnt || '';
    this.taxcls = data.taxcls || '';
    this.datetime = data.datetime || '';
    this.year = data.year || '';
    this.num = data.num || '';
    this.flag = data.flag || '';
    this.appnum = data.appnum || '';
    this.appgubun = data.appgubun || '';
    this.reqdt = data.reqdt || '';
    this.flag = data.flag || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.title = data.title || '';
    this.actnm = data.actnm || '';
    this.refer = data.refer || '';
    this.pumnum = data.pumnum || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
