import axios from 'axios/index';

export default class ToBase64 {
  static async make(url: string, params: any): Promise<string> {
    const response = await axios.post(url, params, {
      responseType: 'arraybuffer',
    });

    return Buffer.from(response.data, 'binary').toString('base64');
  }

  static async fromArrayBuffer(buf: ArrayBuffer): Promise<string> {
    if (!buf || !buf?.byteLength) {
      return '';
    }

    let binary = '';
    const bytes = new Uint8Array(buf);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static async raw(url: string, params: any): Promise<ArrayBuffer> {
    const response = await axios.post(url, params, {
      responseType: 'arraybuffer',
    });

    return Buffer.from(response.data, 'binary');
  }

  static dataURLtoFile = (dataurl: string, fileName: string) => {
    const arr = dataurl.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }
}
