import { Global, TLHF } from '../../../../../constants';
import { FlexLayout, FormatTextBox, TextBox } from '../../../../../components';
import { Plan } from '../Plan';
import PlanModel from '../models/PlanModel';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 달력 > 우측 점검 그리드 레이아웃
 * @window w_tb_e035
 * @category 점검계획등록
 */
export const PlanDetailTableHeader: TLHF<PlanModel, Plan> = () => ([
  {
    id: 'state',
    text: '상태',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.state === '1' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
      >
        {x.state === '1' ? '계획' : '완료'}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '코드',
    width: 80,
    color: 'var(--color-red)',
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 80,
  },
  {
    id: 'custcd',
    text: '점검시작',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="##:##"
        value={x.plantime}
        onChange={(v) => rowUpdate({ plantime: v })}
      />
    ),
  },
  {
    id: 'plantime2',
    text: '점검끝',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="##:##"
        value={x.plantime2}
        onChange={(v) => rowUpdate({ plantime2: v })}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 100,
  },
  {
    id: 'pernm2',
    text: '보조담당자',
    width: 100,
  },
  {
    id: 'kcpernm',
    text: '처리자',
    width: 100,
  },
  {
    id: 'kcpernm2',
    text: '보조처리자',
    width: 100,
  },
  {
    id: 'rptdate',
    text: '처리일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.rptdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 300,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
