import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  DayModel,
  ActModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { StockStatementTemplate } from './StockStatement.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderAct,
  TabHeaderDay,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum StockStatementTabId {
  Day,
  Act,
}

export const StockStatementTabTitles = [
  '일자별',
  '현장담당자별',
];

export const StockStatementTabModels = [
  DayModel,
  ActModel,
];

export const StockStatementTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const StockStatementTabDataStateNames = [
  'dayList',
  'actList',
];

export interface StockStatementState {
  dayList?: Array<DayModel>; // 부품내역 리스트
  actList?: Array<ActModel>; // 사용내역 리스트

  focusedTab?: TabModel;

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  perid: string; // 담당자
  pernm: string; // 담당자명

  total: string;
  qty_tot: string;
  balqty_tot: string;
}
/**
 * 컨트롤러
 * @window w_tb_ca611w_01
 * @category 입고현황
 */
export class StockStatement extends PageComponent<PageProps, StockStatementState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = StockStatementTabId;
    const titles = StockStatementTabTitles;

    this.tabs = [
      new TabModel(id.Day.toString(), MdReorder, titles[id.Day]),
      new TabModel(id.Act.toString(), MdReceipt, titles[id.Act]),
    ];

    this.tabHeaders = [
      TabHeaderDay,
      TabHeaderAct,
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      dayList: [],
      actList: [],
      searchQuery: '', // 검색어
      perid: '', // 담당자
      pernm: '', // 담당자명

      total: '',
      qty_tot: '',
      balqty_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[StockStatementTabId.Day]);
    // await this.doRetrieve(StockStatementTabId.Day);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    if (i === StockStatementTabId.Day && (this.state.dayList?.length || 0) === 0) {
      await this.doRetrieve(StockStatementTabId.Day);
    } else {
      await this.doRetrieve(i);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[StockStatementTabFunctionNames[i]]?.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${StockStatementTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[StockStatementTabFunctionNames[i]]?.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${StockStatementTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @computed
  get tabIndex(): StockStatementTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: StockStatementTabId) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
      },
      (params) => api.fxExec(
        `${StockStatementTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [StockStatementTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[StockStatementTabDataStateNames[i]],
              ...items.map((x: any) => new StockStatementTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({ [StockStatementTabDataStateNames[i]]: [] });
        await this.infinity?.retrieveAll();
      },
    );


    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [StockStatementTabDataStateNames[i]]: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.setState({
        total: this.infinity?.box?.total,
        qty_tot: this.infinity?.box?.qty_tot,
        balqty_tot: this.infinity?.box?.balqty_tot,
      });
    });
  }

  // @action
  // onRowFocusEvent(item: any) {
  //   this.setState({ popupFocused: item });
  // }

  render() {
    return (
      <StockStatementTemplate
        scope={this}
      />
    );
  }
}
