import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { ManageStatus } from '../ManageStatus';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 하단 탭 > 그리드 - 수주/발주내역 > 발주내역
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusGridHeaderBal: GLHF<any, ManageStatus> = (scope) => ([
  {
    id: 'baldate',
    text: '발주일자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.baldate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'balnum',
    text: '번호',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.balnum}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '발주처명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '제목',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '발주금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.mamt_tot || 0, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'deldate',
    text: '납기일자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.deldate)}
      </FlexLayout>
    ),
  },
  {
    id: 're_ibgdate',
    text: '입고요청일자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.re_ibgdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'balpernm',
    text: '발주처담당자',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.balpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'telnum',
    text: '발주연락처',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.telnum}
      </FlexLayout>
    ),
  },
  {
    id: 'faxnum',
    text: '발주 FAX',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.faxnum}
      </FlexLayout>
    ),
  },
  {
    id: 'email',
    text: '발주 E-Mail',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.email}
      </FlexLayout>
    ),
  },
]);
