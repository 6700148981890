import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { Global, TemplateProps } from '../../../../constants';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  LayoutTitle,
  ModalView,
  RowCover,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Date8,
  Today,
} from '../../../../utils/time';
import { AchievementStatus } from './AchievementStatus';
import {
  Fix,
  Format,
} from '../../../../utils/string';
import { Sum } from '../../../../utils/array';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchBinding } from '../../../../models';



/**
 *
 * 화면
 * @window w_tb_e601w_sulchi_09
 * @category 설치계약달성현황.
 */
export const AchievementStatusTemplate: React.FC<TemplateProps<AchievementStatus>> = ({
  scope,
}) => {
  const okFlags = [
    { value: '0', remark: '불합격', color: 'var(--color-red)' },
    { value: '1', remark: '보완', color: 'var(--color-black)' },
    { value: '2', remark: '합격', color: 'var(--color-blue)' },
  ];

  const contFlags = [
    { value: '0', remark: '관급계약' },
    { value: '1', remark: '사급계약' },
    { value: '2', remark: 'LH계약' },
  ];

  const okBooks = [
    { value: '0', remark: '미제작', color: 'var(--color-red)' },
    { value: '1', remark: '제작완료', color: 'var(--color-green)' },
    { value: '2', remark: '제출완료', color: 'var(--color-black)' },
    { value: '3', remark: '완료', color: 'var(--color-blue)' },
  ];

  const compBooks = [
    { value: '0', remark: '미제작', color: 'var(--color-red)' },
    { value: '1', remark: '제작완료', color: 'var(--color-green)' },
    { value: '2', remark: '제출완료', color: 'var(--color-black)' },
    { value: '3', remark: '완료', color: 'var(--color-blue)' },
  ];

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.setState({ year: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(<button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <LayoutTitle justify="left">
          <span style={{ color: 'var(--color-blue)' }}>영업팀</span>
        </LayoutTitle>
        <GridLayoutAdditionalHeader
          ref={scope.mainGridAdditionalHeader}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
          contentWidth={1500}
        >
          <FlexLayout
            justify="center"
            align="center"
            size={80}
          >
            월
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={128}
          >
            목표금액
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={208}
          >
            영업
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={808}
          >
            계약
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={68}
          >
            계약자
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={98}
          >
            목표대비달성율
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={208}
          >
            계약공사구분
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={808}
          >
            견적
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={198}
          >
            견적대비달성율
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={596}
          >
            설치수금
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'mon',
              text: '',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDashMon(x.mon)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
            },
            {
              id: 'planamt',
              text: '',
              width: 130,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.planamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.planamt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'busi',
              text: '영업건',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  onDoubleClick={() => scope.onBusiModal(x, true, 'busi')}
                >
                  {x.busi}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope?.state.busi_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'busi_state0',
              text: '견적제출',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.onBusiModal(x, true, 'busi_state0')}
                >
                  {x.busi_state0}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.busi_state0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'busi_state1',
              text: '미제출',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                  onDoubleClick={() => scope.onBusiModal(x, true, 'busi_state1')}
                >
                  {x.busi_state1}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#843BE7' }}
                >
                  {scope?.state.busi_state1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_cnt0',
              text: '관급계약',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingLeft: 12 }}
                    onDoubleClick={() => scope.onCompModal(x, true, 'comp0')}
                  >
                    {x.comp_cnt0}
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onCompModal(x, true, 'comp0')}
                  >
                    {x.comp_qty_cnt0_2}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#00FFCF' }}
                  >
                    {scope?.state.comp_cnt0_tot}
                  </FlexLayout>
                  <FlexLayout
                    style={{ color: '#00FFCF' }}
                  >
                    {scope?.state.comp_qty_cnt0_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'comp_amt0',
              text: '관급계약금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.onCompModal(x, true, 'comp0')}
                >
                  {Format.number(x.comp_amt0)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.comp_amt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_cnt1',
              text: '사급계약',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingLeft: 12 }}
                    onDoubleClick={() => scope.onCompModal(x, true, 'comp1')}
                  >
                    {x.comp_cnt1}
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onCompModal(x, true, 'comp1')}
                  >
                    {x.comp_qty_cnt1_2}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#00FFCF' }}
                  >
                    {scope?.state.comp_cnt1_tot}
                  </FlexLayout>
                  <FlexLayout
                    style={{ color: '#00FFCF' }}
                  >
                    {scope?.state.comp_qty_cnt1_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'comp_amt1',
              text: '사급계약금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.onCompModal(x, true, 'comp1')}
                >
                  {Format.number(x.comp_amt1)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.comp_amt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_cnt',
              text: '총계약',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)', paddingLeft: 12 }}
                    onDoubleClick={() => scope.onCompModal(x, true, 'comp')}
                  >
                    {x.comp_cnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onCompModal(x, true, 'comp')}
                  >
                    {x.comp_qty_cnt_2}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#00FFCF' }}
                  >
                    {scope?.state.comp_cnt_tot}
                  </FlexLayout>
                  <FlexLayout
                    style={{ color: '#00FFCF' }}
                  >
                    {scope?.state.comp_qty_cnt_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'comp_amt',
              text: '총계약금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.onCompModal(x, true, 'comp')}
                >
                  {Format.number(x.comp_amt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.comp_amt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_perids',
              text: '(명)',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.peridsModalType(x, true)}
                >
                  {x.comp_perids}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.comp_perids_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'plan_yul',
              text: '',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {parseFloat(x.plan_yul).toFixed(2)} %
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {parseFloat(scope.state.plan_yul_tot as string).toFixed(2)} %
                </FlexLayout>
              ),
            },
            {
              id: 'comp',
              text: '총건수',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  onDoubleClick={() => scope.onCompModal(x, true, 'comp')}
                >
                  {x.comp}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope?.state.comp_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_gubun14',
              text: '설치',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCompModal(x, true, 'comp_gubun14')}
                >
                  {x.comp_gubun14}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.comp_gubun14_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_gubun15',
              text: '리모델링',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.onCompModal(x, true, 'comp_gubun15')}
                >
                  {x.comp_gubun15}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.comp_gubun15_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_cnt0',
              text: '관급견적',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCostModal(x, true, 'cost0')}
                >
                  {x.cost_cnt0}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.cost_cnt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_amt0',
              text: '관급견적금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCostModal(x, true, 'cost0')}
                >
                  {Format.number(x.cost_amt0)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.cost_amt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_cnt1',
              text: '사급견적',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCostModal(x, true, 'cost1')}
                >
                  {x.cost_cnt1}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.cost_cnt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_amt1',
              text: '사급견적금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCostModal(x, true, 'cost1')}
                >
                  {Format.number(x.cost_amt1)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.cost_amt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_cnt',
              text: '총견적',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCostModal(x, true, 'cost')}
                >
                  {x.cost_cnt}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.cost_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_amt',
              text: '총견적금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#3A8080' }}
                  onDoubleClick={() => scope.onCostModal(x, true, 'cost')}
                >
                  {Format.number(x.cost_amt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FF00' }}
                >
                  {scope?.state.cost_amt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'comp_yul0',
              text: '관급',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#D66680' }}
                >
                  {parseFloat(x.comp_yul0).toFixed(2)} %
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#843BE7' }}
                >

                  {scope?.state.comp_yul0_tot} %
                </FlexLayout>
              ),
            },
            {
              id: 'comp_yul1',
              text: '사급',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#D66680' }}
                >
                  {parseFloat(x.comp_yul1).toFixed(2)} %
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#843BE7' }}
                >
                  {scope?.state.comp_yul1_tot} %
                </FlexLayout>
              ),
            },
            {
              id: 'misamt2',
              text: '매출금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  onDoubleClick={() => scope.onMisModal(x, true)}
                >
                  {Format.number(x.misamt2)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope?.state.misamt2_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt2',
              text: '수금액',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                  onDoubleClick={() => scope.onMisModal(x, true)}
                >
                  {Format.number(x.rcvamt2)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#00FFCF' }}
                >
                  {scope?.state.rcvamt2_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt2',
              text: '미수금',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                  onDoubleClick={() => scope.onMisModal(x, true)}
                >
                  {Format.number(x.iamt2)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#843BE7' }}
                >
                  {scope?.state.iamt2_tot}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.dataList}
          isScroll={true}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onHorizontalScroll={(x) => { scope.mainGridAdditionalHeader.current?.scrollTo(x); }}
          contentWidth={3216}
        />
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <LayoutTitle justify="left">
          <span style={{ color: 'var(--color-blue)' }}>설계, PM</span>
        </LayoutTitle>

        <GridLayoutAdditionalHeader
          ref={scope.mainGridAdditionalHeader2}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
          contentWidth={2500}
        >
          <FlexLayout
            justify="center"
            align="center"
            size={70}
          >
            월
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={478}
          >
            착공
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={558}
          >
            실측
          </FlexLayout>
          <FlexLayout
            justify="center"
            align="center"
            size={558}
          >
            설계
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayout
          ref={scope.grid2}
          header={[
            {
              id: 'mon',
              text: '',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDashMon(x.mon)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
            },
            {
              id: 'bal_cnt0',
              text: '관급착공',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_cnt0')}
                  >
                    {x.bal_cnt0}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_cnt0')}
                  >
                    ({x.bal_qty_cnt0})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.bal_cnt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'bal_cnt1',
              text: '사급착공',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_cnt1')}
                  >
                    {x.bal_cnt1}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_cnt1')}
                  >
                    ({x.bal_qty_cnt1})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {scope.state.bal_cnt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'bal_cnt',
              text: '총착공',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_cnt')}
                  >
                    {x.bal_cnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_cnt')}
                  >
                    ({x.bal_qty_cnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.bal_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'bal_ingcnt',
              text: '진행중[누적]',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_ingcnt')}
                  >
                    {x.bal_ingcnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_ingcnt')}
                  >
                    ({x.bal_qty_ingcnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              // trail: () => (
              //   <FlexLayout
              //     justify="right"
              //     align="center"
              //     style={{ color: 'var(--color-red)' }}
              //   >
              //     {scope.state.bal_ingcnt_tot}
              //   </FlexLayout>
              // ),
            },
            {
              id: 'bal_notcnt',
              text: '미착공',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_notcnt')}
                  >
                    {x.bal_notcnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onBalModal(x, true, 'bal_notcnt')}
                  >
                    ({x.bal_qty_notcnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: (data) => {
                const sumnNotCnt = Sum.all(data, 'bal_notcnt');
                const sumnQtyNotCnt = Sum.all(data, 'bal_qty_notcnt');
                return (
                  <FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumnNotCnt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumnQtyNotCnt)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'kcqty',
              text: '검사대수',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  onDoubleClick={() => scope.onKcqtyModal(x, true)}
                >
                  {x.kcqty}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope?.state.kcqty_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_cnt0',
              text: '관급실측',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_cnt0')}
                  >
                    {x.sur_cnt0}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_cnt0')}
                  >
                    ({x.sur_qty_cnt0})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.sur_cnt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_notcnt0',
              text: '관급미실측',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_notcnt0')}
                  >
                    {x.sur_notcnt0}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_notcnt0')}
                  >
                    ({x.sur_qty_notcnt0})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.sur_notcnt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_cnt1',
              text: '사급실측',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_cnt1')}
                  >
                    {x.sur_cnt1}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_cnt1')}
                  >
                    ({x.sur_qty_cnt1})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.sur_cnt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_notcnt1',
              text: '사급미실측',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_notcnt1')}
                  >
                    {x.sur_notcnt1}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_notcnt1')}
                  >
                    ({x.sur_qty_notcnt1})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.sur_notcnt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_cnt',
              text: '총실측',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_cnt')}
                  >
                    {x.sur_cnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_cnt')}
                  >
                    ({x.sur_qty_cnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sur_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_notcnt',
              text: '총미실측',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_notcnt')}
                  >
                    {x.sur_notcnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onSurModal(x, true, 'sur_notcnt')}
                  >
                    ({x.sur_qty_notcnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.sur_notcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'sur_perid',
              text: '실측자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  onDoubleClick={() => scope.surPeridsModalType(x, true)}
                >
                  {x.sur_perids}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.sur_perids_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_cnt0',
              text: '관급설계',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_cnt0')}
                  >
                    {x.des_cnt0}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_cnt0')}
                  >
                    ({x.des_qty_cnt0})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {scope.state.des_cnt0_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_notcnt0',
              text: '관급미설계',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_notcnt0')}
                  >
                    {x.des_notcnt0}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_notcnt0')}
                  >
                    ({x.des_qty_notcnt0})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.des_notcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_cnt1',
              text: '사급설계',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_cnt1')}
                  >
                    {x.des_cnt1}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_cnt1')}
                  >
                    ({x.des_qty_cnt1})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {scope.state.des_cnt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_notcnt1',
              text: '사급미설계',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_notcnt1')}
                  >
                    {x.des_notcnt1}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_notcnt1')}
                  >
                    ({x.des_qty_notcnt1})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.des_notcnt1_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_cnt',
              text: '총설계',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_cnt')}
                  >
                    {x.des_cnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_cnt')}
                  >
                    ({x.des_qty_cnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.des_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_notcnt',
              text: '총미설계',
              width: 70,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_notcnt')}
                  >
                    {x.des_notcnt}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                    onDoubleClick={() => scope.onDesModal(x, true, 'des_notcnt')}
                  >
                    ({x.des_qty_notcnt})
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.des_notcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'des_perids',
              text: '설계자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  onDoubleClick={() => scope.desPeridsModalType(x, true)}
                >
                  {x.des_perids}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.des_perids_tot}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.dataList}
          isScroll={true}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onHorizontalScroll={(x) => { scope.mainGridAdditionalHeader2.current?.scrollTo(x); }}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.busiModal}
        onClose={() => scope.SS({ busiModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.busiGrid}
              header={[
                {
                  id: 'plandate',
                  text: '계획일자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.plandate)}
                    </FlexLayout>
                  ),
                  sum: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout>
                      합계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'plannum',
                  text: '번호',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.plannum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recedate',
                  text: '최초접수일자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.recedate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'group',
                  text: '상태그룹',
                  isHidden: true,
                  group: true,
                  value: (x) => x.pernm,
                },
                {
                  id: 'state',
                  text: '상태',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}
                    >
                      {x.state === '1' ? '미처리' : '처리'}
                    </FlexLayout>
                  ),
                  sum: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}
                    >
                      { x.state === '1' ? '미처리' : '처리' }
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout>
                      {scope.state.cnt_tot} 건
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                  sum: (x, data) => {
                    const sumState1 = Sum.groupValue(data, 'state', x, '1', ['group']);
                    const sumState2 = Sum.groupValue(data, 'state', x, '2', ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {sumState1 || sumState2} 건
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'pernm',
                  text: '담당자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compdate',
                  text: '방문처리일자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.compdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'inpernm',
                  text: '작성자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.inpernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'advicepernm',
                  text: '상담자',
                  width: 4,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.advicepernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'adviceremark',
                  text: '상담내용',
                  width: 11,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {Fix.newline(x.adviceremark).replace(/\n/ig, ' ').substr(0, 60)}
                      {x.adviceremark.length > 60 && '...'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resultremark',
                  text: '협의내용 및 조치내용',
                  width: 11,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {Fix.newline(x.resultremark).replace(/\n/ig, ' ').substr(0, 60)}
                      {x.resultremark.length > 60 && '...'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bigo',
                  text: '기타내용 및 승강기상태',
                  width: 11,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {Fix.newline(x.bigo).replace(/\n/ig, ' ').substr(0, 60)}
                      {x.bigo.length > 60 && '...'}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.busiModalData}
              infinityHandler={scope.busiPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}>
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ busiModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.costModal}
        onClose={() => scope.SS({ costModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.costGrid}
              header={[
                {
                  id: 'group',
                  text: '견적그룹',
                  isHidden: true,
                  group: true,
                  value: (x) => x.contflag,
                },
                {
                  id: 'costdate',
                  text: '견적일자',
                  width: 1.8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.costdate)}
                    </FlexLayout>
                  ),
                  sum: (item) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {contFlags?.filter((x) => x.value === item.contflag)[0]?.remark}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'costnum',
                  text: '번호',
                  width: 1,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.costnum}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.cnt_tot} 건
                    </FlexLayout>
                  ),
                },
                {
                  id: 'costpernm',
                  text: '담당자',
                  width: 1.3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.costpernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contflag',
                  text: '계약구분',
                  width: 1.4,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {contFlags?.filter((y) => y.value === x.contflag)[0]?.remark}
                    </FlexLayout>
                  ),
                  sum: (x, data) => {
                    const sumContflag0 = Sum.groupValue(data, 'contflag', x, '0', ['group']);
                    const sumContflag1 = Sum.groupValue(data, 'contflag', x, '1', ['group']);
                    const sumContflag2 = Sum.groupValue(data, 'contflag', x, '2', ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {sumContflag0 || sumContflag1 || sumContflag2} 건
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'gubun',
                  text: '매출구분',
                  width: 1.4,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.gubuns?.filter((y) => y.code === x.gubun)[0]?.cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'constnm',
                  text: '공사명',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.constnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '거래처명',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'state',
                  text: '상태',
                  width: 1.3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.state === '1' ? '' : 'var(--color-blue)' }}
                    >
                      {x.state === '1' ? '견적' : '계약'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                  sum: (x, data) => {
                    const sumSamt = Sum.byGroup(data, 'samt', x, ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Format.number(sumSamt)}
                      </FlexLayout>
                    );
                  },
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  ),
                  sum: (x, data) => {
                    const sumTamt = Sum.byGroup(data, 'tamt', x, ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Format.number(sumTamt)}
                      </FlexLayout>
                    );
                  },
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.tamt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mamt',
                  text: '공사금액',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.mamt)}
                    </FlexLayout>
                  ),
                  sum: (x, data) => {
                    const sumMamt = Sum.byGroup(data, 'mamt', x, ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Format.number(sumMamt)}
                      </FlexLayout>
                    );
                  },
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.mamt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'jeoban',
                  text: '제어반',
                  width: 1.3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.jeoban}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'nperson',
                  text: '인승',
                  width: 1.3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.nperson}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'number',
                  text: '층수',
                  width: 1.3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.number}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 9,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {Fix.newline(x.remark).replace(/\n/ig, ' ').substr(0, 80)}
                      {x.remark.length > 80 && '...'}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.costModalData}
              infinityHandler={scope.costPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}>
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ costModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.compModal}
        onClose={() => scope.SS({ compModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>
          <FlexLayout>
            <GridLayout
              ref={scope.compGrid}
              header={[
                {
                  id: 'orddate',
                  text: '수주일자',
                  width: 1.8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.orddate)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      합계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ordnum',
                  text: '번호',
                  width: 1,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.ordnum}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.cnt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자',
                  width: 1.3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '수주종류',
                  width: 1.5,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.gubuns?.filter((y) => y.code === x.gubun)[0]?.cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'title',
                  text: '제목',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.title}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 1,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumQty = Sum.all(data, 'qty');

                    return (
                      <FlexLayout
                      >
                        {sumQty}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세액',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.tamt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mamt',
                  text: '합계금액',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.mamt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.mamt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '공사시작일',
                  width: 1.8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.stdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'enddate',
                  text: '공사완료일',
                  width: 1.8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.enddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 6,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'worknm',
                  text: '특기사항',
                  width: 6,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.worknm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.compModalData}
              infinityHandler={scope.compPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            >
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ compModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.peridsModalType}
        onClose={() => scope.SS({ peridsModalType: false })}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align="center"
            justify="center"
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.peridsModalTypeCheck === '1'}
                onChange={(v) => scope.setState({ peridsModalTypeCheck: v ? '1' : '0' })}
              >
              </CheckBox>
                해당월
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.peridsModalTypeCheck === '2'}
                onChange={(v) => scope.setState({ peridsModalTypeCheck: v ? '2' : '0' })}
              >
              </CheckBox>
                전체[년]
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => (scope.state.peridsModalTypeCheck === '1'
                ? scope.onCompPeridsMonModal(true)
                : scope.onCompPeridsModal(true))}
              >
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.compPeridsMonModal}
        onClose={() => scope.SS({ compPeridsMonModal: false })}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.compPeridsMonGrid}
              header={[
                {
                  id: 'mon',
                  text: '월',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDashMon(x.mon)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cnt',
                  text: '건수',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.cnt}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.cnt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '대수',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.qty_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.tamt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mamt',
                  text: '합계금액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.mamt)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.mamt_tot}
                    </FlexLayout>
                  ),
                },
              ]}
              infinityHandler={scope.compPeridsMonPopupInfinity}
              rowHeight={48}
              headerHeight={48}
              data={scope.state.compPeridsModalMonData}
              >
             </GridLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.compPeridsModal}
        onClose={() => scope.SS({ compPeridsModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.compPeridsGrid}
              header={[
                {
                  id: 'pernm',
                  text: '담당자',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '구분',
                  width: 2,
                  render: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout justify="center" align="center">
                        건수
                      </FlexLayout>
                      <FlexLayout
                        style={{ color: 'var(--color-violet)' }}
                        justify="center"
                        align="center"
                      >
                        대수
                      </FlexLayout>
                      <FlexLayout
                        style={{ color: 'var(--color-blue)' }}
                        justify="center"
                        align="center"
                      >
                        총금액
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tot',
                  text: '년합계',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnttot)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qtytot)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamttot)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnttot_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qtytot_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamttot_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon01',
                  text: '01월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt01)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty01)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt01)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt01_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty01_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt01_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon02',
                  text: '02월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt02)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty02)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt02)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt02_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty02_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt02_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon03',
                  text: '03월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt03)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty03)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt03)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt03_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty03_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt03_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon04',
                  text: '04월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt04)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty04)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt04)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt04_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty04_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt04_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon05',
                  text: '05월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt05)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty05)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt05)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt05_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty05_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt05_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon06',
                  text: '06월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt06)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty06)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt06)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt06_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty06_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt06_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon07',
                  text: '07월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt07)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty07)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt07)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt07_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty07_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt07_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon08',
                  text: '08월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt08)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty08)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt08)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt08_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty08_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt08_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon9',
                  text: '09월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt09)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty09)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt09)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt09_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty09_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt09_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon10',
                  text: '10월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt10)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty10)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt10)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt10_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty10_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt10_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon11',
                  text: '11월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt11)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty11)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt11)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt11_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty11_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt11_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mon12',
                  text: '12월',
                  width: 2,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt12)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty12)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.mamt12)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.cnt12_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {scope.state.qty12_tot}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.mamt12_tot}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
              ]}
              infinityHandler={scope.compPeridsPopupInfinity}
              rowHeight={48}
              headerHeight={48}
              data={scope.state.compPeridsModalData}>
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ compPeridsModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.surModal}
        onClose={() => scope.SS({ surModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <GridLayoutAdditionalHeader
            ref={scope.surPopupGridAdditionalHeader}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={1790}
          >
            <FlexLayout
              justify="center"
              align="center"
            >
              설치계약달성현황 {scope.state.modalNames}
            </FlexLayout>
          </GridLayoutAdditionalHeader>
          <FlexLayout>
            <GridLayout
              ref={scope.surGrid}
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumActnm = data?.length;
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        합 계 {sumActnm}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumQty = Sum.all(data, 'qty');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {sumQty}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'surdate',
                  text: '실측일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.surdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'surpernm',
                  text: '실측자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.surpernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'surflag',
                  text: '여부',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.surflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.surflag === '1' ? 'O' : 'X'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'desdate',
                  text: '설계일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.desdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'despernm',
                  text: '설계자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.despernm)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'desflag',
                  text: '여부',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.desflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.desflag === '1' ? 'O' : 'X'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contdate',
                  text: '계약일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.contdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'enddate',
                  text: '납기일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.enddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contamt',
                  text: '계약금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      ({Format.number(x.contamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumContamt = Sum.all(data, 'contamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumContamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'stamt',
                  text: '선급금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      ({Format.number(x.stamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumStamt = Sum.all(data, 'stamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumStamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'midamt',
                  text: '중도금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.midamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumMidamt = Sum.all(data, 'midamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumMidamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'endamt',
                  text: '잔금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.endamt)})
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumEndamt = Sum.all(data, 'endamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumEndamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'balamt',
                  text: '발주금액',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.balamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumbalamt = Sum.all(data, 'balamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumbalamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'rcvamt',
                  text: '입금액',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rcvamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumRcvamt = Sum.all(data, 'rcvamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumRcvamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'iamt',
                  text: '잔액',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumIamt = Sum.all(data, 'iamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumIamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'sizecd',
                  text: '사양',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.sizecd}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scoption',
                  text: '옵션',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.scoption}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ipsundate',
                  text: '본전원입선일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.ipsundate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'baldate',
                  text: '자재현장도착일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.baldate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scpernm',
                  text: '설치작업자',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.scpernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scremark',
                  text: '현재진행사항',
                  width: 400,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {Fix.newline(x.scremark).replace(/\n/ig, ' ')}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '검사예정일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.stdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'kcdate',
                  text: '검사일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.kcdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okflag',
                  text: '합격필증',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: okFlags.filter((y) => y.value === x.okflag)[0]?.color }}
                    >
                      {okFlags?.filter((y) => y.value === x.okflag)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bilddate',
                  text: '건축허가일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.bilddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okbook',
                  text: '승인도서',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: okBooks?.filter((y) => y.value === x.okbook)[0]?.color }}
                    >
                      {okBooks?.filter((y) => y.value === x.okbook)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compbook',
                  text: '준공도서',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: compBooks?.filter((y) => y.value === x.compbook)[0]?.color }}
                    >
                      {compBooks?.filter((y) => y.value === x.compbook)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bildju',
                  text: '모델인증서',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {scope.state.bildjus?.filter((y) => y.code === x.bildju)[0]?.cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'state',
                  text: '설치',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.state === '1' ? 'var(--color-green)' : 'var(--color-blue)' }}
                    >
                      {x.state === '1' ? '설치' : '보수'}
                    </FlexLayout>
                  ),
                },
              ]}
              infinityHandler={scope.surPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.surModalData}
              onHorizontalScroll={(x) => { scope.surPopupGridAdditionalHeader.current?.scrollTo(x); }}
              contentWidth={4180}
            >
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ surModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.surPeridsModalType}
        onClose={() => scope.SS({ surPeridsModalType: false })}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align="center"
            justify="center"
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.surPeridsModalTypeCheck === '1'}
                onChange={(v) => scope.setState({ surPeridsModalTypeCheck: v ? '1' : '0' })}
              >
              </CheckBox>
              해당월
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.surPeridsModalTypeCheck === '2'}
                onChange={(v) => scope.setState({ surPeridsModalTypeCheck: v ? '2' : '0' })}
              >
              </CheckBox>
              전체[년]
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => (scope.state.surPeridsModalTypeCheck === '1'
                ? scope.onSurPeridsMonModal(true)
                : scope.onSurPeridsModal(true))}
              >
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.surPeridsMonModal}
        onClose={() => scope.SS({ surPeridsMonModal: false })}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.surPeridsMonGrid}
              header={[
                {
                  id: 'mon',
                  text: '월',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDashMon(x.mon)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cnt',
                  text: '건수',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.cnt}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const cntTot = Sum.all(data, 'cnt');
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {cntTot}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'qty',
                  text: '대수',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const qtyTot = Sum.all(data, 'qty');
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {qtyTot}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'etc',
                  text: '기타',
                  width: 600,
                  render: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                    </FlexLayout>
                  ),
                },
              ]}
              infinityHandler={scope.surPeridsMonPopupInfinity}
              rowHeight={48}
              headerHeight={48}
              data={scope.state.surPeridsModalMonData}
            >
            </GridLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.surPeridsModal}
        onClose={() => scope.SS({ surPeridsModal: false })}
        width={1600}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.surPeridsGrid}
              header={[
                {
                  id: 'pernm',
                  text: '담당자',
                  width: 1,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout>
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '구분',
                  width: 1,
                  render: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        건수
                      </FlexLayout>
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        대수
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tot',
                  text: '년합계',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnttot)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qtytot)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnttot');
                    const sumQty = Sum.all(data, 'qtytot');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon01',
                  text: '01월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt01)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty01)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt01');
                    const sumQty = Sum.all(data, 'qty01');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon02',
                  text: '02월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt02)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty02)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt02');
                    const sumQty = Sum.all(data, 'qty02');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon03',
                  text: '03월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt03)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty03)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt03');
                    const sumQty = Sum.all(data, 'qty03');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon04',
                  text: '04월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt04)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty04)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt04');
                    const sumQty = Sum.all(data, 'qty04');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon05',
                  text: '05월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt05)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty05)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt05');
                    const sumQty = Sum.all(data, 'qty05');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon06',
                  text: '06월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt06)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty06)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt06');
                    const sumQty = Sum.all(data, 'qty06');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon07',
                  text: '07월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt07)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty07)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt07');
                    const sumQty = Sum.all(data, 'qty07');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon08',
                  text: '08월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt08)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty08)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt08');
                    const sumQty = Sum.all(data, 'qty08');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon09',
                  text: '09월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt09)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty09)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt09');
                    const sumQty = Sum.all(data, 'qty09');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon10',
                  text: '10월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt10)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty10)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt10');
                    const sumQty = Sum.all(data, 'qty10');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon11',
                  text: '11월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt11)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty11)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt11');
                    const sumQty = Sum.all(data, 'qty11');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon12',
                  text: '12월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt12)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty12)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt12');
                    const sumQty = Sum.all(data, 'qty12');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
              ]}
              infinityHandler={scope.surPeridsPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.surPeridsModalData}>
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ surPeridsModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.desModal}
        onClose={() => scope.SS({ desModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <GridLayoutAdditionalHeader
            ref={scope.desPopupGridAdditionalHeader}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={1790}
          >
            <FlexLayout
              justify="center"
              align="center"
            >
              설치계약달성현황 {scope.state.modalNames}
            </FlexLayout>
          </GridLayoutAdditionalHeader>
          <FlexLayout>
            <GridLayout
              ref={scope.desGrid}
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumActnm = data?.length;
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        합 계 {sumActnm}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumQty = Sum.all(data, 'qty');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {sumQty}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'desdate',
                  text: '설계일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.desdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'despernm',
                  text: '설계자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      onDoubleClick={() => scope.desPeridsModalType(x, true)}
                    >
                      {Date8.withDash(x.despernm)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'desflag',
                  text: '여부',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.desflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.desflag === '1' ? 'O' : 'X'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'surdate',
                  text: '실측일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.surdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'surpernm',
                  text: '실측자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.surpernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'surflag',
                  text: '여부',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.surflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.surflag === '1' ? 'O' : 'X'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contdate',
                  text: '계약일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.contdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'enddate',
                  text: '납기일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.enddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contamt',
                  text: '계약금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      ({Format.number(x.contamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumContamt = Sum.all(data, 'contamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumContamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'stamt',
                  text: '선급금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      ({Format.number(x.stamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumStamt = Sum.all(data, 'stamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumStamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'midamt',
                  text: '중도금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.midamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumMidamt = Sum.all(data, 'midamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumMidamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'endamt',
                  text: '잔금',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.endamt)})
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumEndamt = Sum.all(data, 'endamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumEndamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'balamt',
                  text: '발주금액',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.balamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumbalamt = Sum.all(data, 'balamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumbalamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'rcvamt',
                  text: '입금액',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rcvamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumRcvamt = Sum.all(data, 'rcvamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumRcvamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'iamt',
                  text: '잔액',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumIamt = Sum.all(data, 'iamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumIamt)}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'sizecd',
                  text: '사양',
                  width: 140,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.sizecd}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scoption',
                  text: '옵션',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.scoption}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ipsundate',
                  text: '본전원입선일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.ipsundate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'baldate',
                  text: '자재현장도착일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.baldate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scpernm',
                  text: '설치작업자',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.scpernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scremark',
                  text: '현재진행사항',
                  width: 400,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {Fix.newline(x.scremark).replace(/\n/ig, ' ')}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '검사예정일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.stdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'kcdate',
                  text: '검사일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.kcdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okflag',
                  text: '합격필증',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: okFlags.filter((y) => y.value === x.okflag)[0]?.color }}
                    >
                      {okFlags?.filter((y) => y.value === x.okflag)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bilddate',
                  text: '건축허가일',
                  width: 120,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.bilddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okbook',
                  text: '승인도서',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: okBooks?.filter((y) => y.value === x.okbook)[0]?.color }}
                    >
                      {okBooks?.filter((y) => y.value === x.okbook)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compbook',
                  text: '준공도서',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: compBooks?.filter((y) => y.value === x.compbook)[0]?.color }}
                    >
                      {compBooks?.filter((y) => y.value === x.compbook)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bildju',
                  text: '모델인증서',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {scope.state.bildjus?.filter((y) => y.code === x.bildju)[0]?.cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'state',
                  text: '설치',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.state === '1' ? 'var(--color-green)' : 'var(--color-blue)' }}
                    >
                      {x.state === '1' ? '설치' : '보수'}
                    </FlexLayout>
                  ),
                },
              ]}
              infinityHandler={scope.desPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.desModalData}
              onHorizontalScroll={(x) => { scope.desPopupGridAdditionalHeader.current?.scrollTo(x); }}
              contentWidth={4180}
            >
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ desModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.desPeridsModalType}
        onClose={() => scope.SS({ desPeridsModalType: false })}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align="center"
            justify="center"
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.desPeridsModalTypeCheck === '1'}
                onChange={(v) => scope.setState({ desPeridsModalTypeCheck: v ? '1' : '0' })}
              >
              </CheckBox>
              해당월
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.desPeridsModalTypeCheck === '2'}
                onChange={(v) => scope.setState({ desPeridsModalTypeCheck: v ? '2' : '0' })}
              >
              </CheckBox>
              전체[년]
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => (scope.state.desPeridsModalTypeCheck === '1'
                ? scope.onDesPeridsMonModal(true)
                : scope.onDesPeridsModal(true))}
              >
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.desPeridsMonModal}
        onClose={() => scope.SS({ desPeridsMonModal: false })}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.desPeridsMonGrid}
              header={[
                {
                  id: 'mon',
                  text: '월',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDashMon(x.mon)}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cnt',
                  text: '건수',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.cnt}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const cntTot = Sum.all(data, 'cnt');
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {cntTot}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'qty',
                  text: '대수',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const qtyTot = Sum.all(data, 'qty');
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {qtyTot}
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'etc',
                  text: '기타',
                  width: 600,
                  render: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                    </FlexLayout>
                  ),
                },
              ]}
              infinityHandler={scope.desPeridsMonPopupInfinity}
              rowHeight={48}
              headerHeight={48}
              data={scope.state.desPeridsModalMonData}
            >
            </GridLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.desPeridsModal}
        onClose={() => scope.SS({ desPeridsModal: false })}
        width={1600}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.desPeridsGrid}
              header={[
                {
                  id: 'pernm',
                  text: '담당자',
                  width: 1,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout>
                      합 계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '구분',
                  width: 1,
                  render: () => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        건수
                      </FlexLayout>
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        대수
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tot',
                  text: '년합계',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnttot)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qtytot)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnttot');
                    const sumQty = Sum.all(data, 'qtytot');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon01',
                  text: '01월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt01)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty01)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt01');
                    const sumQty = Sum.all(data, 'qty01');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon02',
                  text: '02월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt02)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty02)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt02');
                    const sumQty = Sum.all(data, 'qty02');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon03',
                  text: '03월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt03)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty03)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt03');
                    const sumQty = Sum.all(data, 'qty03');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon04',
                  text: '04월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt04)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty04)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt04');
                    const sumQty = Sum.all(data, 'qty04');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon05',
                  text: '05월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt05)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty05)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt05');
                    const sumQty = Sum.all(data, 'qty05');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon06',
                  text: '06월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt06)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty06)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt06');
                    const sumQty = Sum.all(data, 'qty06');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon07',
                  text: '07월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt07)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty07)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt07');
                    const sumQty = Sum.all(data, 'qty07');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon08',
                  text: '08월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt08)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty08)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt08');
                    const sumQty = Sum.all(data, 'qty08');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon09',
                  text: '09월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt09)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty09)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt09');
                    const sumQty = Sum.all(data, 'qty09');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon10',
                  text: '10월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt10)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty10)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt10');
                    const sumQty = Sum.all(data, 'qty10');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon11',
                  text: '11월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt11)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty11)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt11');
                    const sumQty = Sum.all(data, 'qty11');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
                {
                  id: 'mon12',
                  text: '12월',
                  width: 1,
                  render: (x) => (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.cnt12)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-violet)' }}
                      >
                        {Format.number(x.qty12)}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  trail: (data) => {
                    const sumCnt = Sum.all(data, 'cnt12');
                    const sumQty = Sum.all(data, 'qty12');
                    return (
                      <FlexLayout isVertical={true}>
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {sumCnt}
                        </FlexLayout>
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-violet)' }}
                        >
                          {sumQty}
                        </FlexLayout>
                      </FlexLayout>
                    );
                  },
                },
              ]}
              infinityHandler={scope.desPeridsPopupInfinity}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.desPeridsModalData}>
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ desPeridsModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.kcqtyModal}
        onClose={() => scope.SS({ kcqtyModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>
          <GridLayout
            ref={scope.kcqtyGrid}
            header={[
              {
                id: 'scactnm',
                text: '현장명',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.scactnm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
              },
              {
                id: 'qty',
                text: '대수',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.qty}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.qty_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일자',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '검사예정일',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'okflag',
                text: '합격필증',
                width: 1.5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: okFlags?.filter((y) => y.value === x.okflag)[0]?.color }}
                  >
                    {okFlags.filter((y) => y.value === x.okflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contdate',
                text: '계약일',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.contdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '납기일',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'jeoban',
                text: '기종',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.jeoban === '01' ? 'MR' : 'MRL'}
                  </FlexLayout>
                ),
              },
              {
                id: 'contamt',
                text: '계약금',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.contamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.contamt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'stamt',
                text: '( 선급금',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.stamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.stamt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'midamt',
                text: '중도금',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.midamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.midamt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'endamt',
                text: '잔금 )',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.endamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.endamt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '입금액',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#00FFE7' }}
                  >
                    {scope.state.rcvamt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '잔액',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#B818FF' }}
                  >
                    {scope.state.iamt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'sizecd',
                text: '사양',
                width: 2.5,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.sizecd}
                  </FlexLayout>
                ),
              },
              {
                id: 'scoption',
                text: '옵션',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.scoption}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.kcqtyPopupInfinity}
            data={scope.state.kcqtyModalData}
          >
          </GridLayout>
          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
              <Button onClick={() => scope.SS({ kcqtyModal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.balModal}
        onClose={() => scope.SS({ balModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <GridLayout
            ref={scope.balGrid}
            header={[
              {
                id: 'actnm',
                text: '현장명',
                width: 300,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumActnm = data?.length;
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계 {sumActnm}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'qty',
                text: '대수',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.qty}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumQty = Sum.all(data, 'qty');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {sumQty}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'state',
                text: '설치',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.state === '1' ? 'var(--color-green)' : 'var(--color-blue)' }}
                  >
                    {x.state === '1' ? '설치' : '보수'}
                  </FlexLayout>
                ),
              },
              {
                id: 'baldate',
                text: '자재현장도착일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.baldate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'scpernm',
                text: '설치작업자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.scpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'scremark',
                text: '현재진행사항',
                width: 300,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {Fix.newline(x.scremark).replace(/\n/ig, ' ')}
                  </FlexLayout>
                ),
              },
              {
                id: 'contdate',
                text: '계약일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.contdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '납기일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'jeoban',
                text: '기종',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.jeoban === '01' ? 'MR' : 'MRL'}
                  </FlexLayout>
                ),
              },
              {
                id: 'contamt',
                text: '계약금',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.contamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumContamt = Sum.all(data, 'contamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumContamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'stamt',
                text: '( 선급금',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    ({Format.number(x.stamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumStamt = Sum.all(data, 'stamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumStamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'midamt',
                text: '중도금',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.midamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumMidamt = Sum.all(data, 'midamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMidamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'endamt',
                text: '잔금 )',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.endamt)})
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumEndamt = Sum.all(data, 'endamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumEndamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'balamt',
                text: '발주금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.balamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumbalamt = Sum.all(data, 'balamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumbalamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'rcvamt',
                text: '입금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
                trail: (data) => {
                  const sumRcvamt = Sum.all(data, 'rcvamt');
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumRcvamt)}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'iamt',
                text: '잔액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'sizecd',
                text: '사양',
                width: 120,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.sizecd}
                  </FlexLayout>
                ),
              },
              {
                id: 'scoption',
                text: '옵션',
                width: 120,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.scoption}
                  </FlexLayout>
                ),
              },
              {
                id: 'ipsundate',
                text: '본전원입선일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.ipsundate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '검사예정일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'okflag',
                text: '합격필증',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: okFlags.filter((y) => y.value === x.okflag)[0]?.color }}
                  >
                    {okFlags?.filter((y) => y.value === x.okflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'bilddate',
                text: '건축허가일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.bilddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'okbook',
                text: '승인도서',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: okBooks?.filter((y) => y.value === x.okbook)[0]?.color }}
                  >
                    {okBooks?.filter((y) => y.value === x.okbook)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'compbook',
                text: '준공도서',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: compBooks?.filter((y) => y.value === x.compbook)[0]?.color }}
                  >
                    {compBooks?.filter((y) => y.value === x.compbook)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'bildju',
                text: '모델인증서',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.bildjus?.filter((y) => y.code === x.bildju)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'surdate',
                text: '실측일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.surdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'surpernm',
                text: '실측자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.surpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'surflag',
                text: '여부',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.surflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {x.surflag === '1' ? 'O' : 'X'}
                  </FlexLayout>
                ),
              },
              {
                id: 'desdate',
                text: '설계일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.desdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'despernm',
                text: '설계자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.despernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'desflag',
                text: '여부',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.desflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {x.desflag === '1' ? 'O' : 'X'}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={scope.balPopupInfinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.balModalData}
          >
          </GridLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
            <Button onClick={() => scope.SS({ balModal: false })}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>

      <ModalView
        isVisible={scope.state.misModal}
        onClose={() => scope.SS({ misModal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>설치계약달성현황 {scope.state.modalNames}</LayoutTitle>

          <GridLayout
            ref={scope.misGrid}
            header={[
              {
                id: 'misdate',
                text: '매출일자',
                width: 1.5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
              },
              {
                id: 'misnum',
                text: '번호',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.misnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '매출구분',
                width: 1.2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.gubuns?.filter((y) => y.code === x.gubun)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt2',
                text: '매출금액',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt2)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt2_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt2',
                text: '입금액',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.rcvamt2)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#18FFFF' }}
                  >
                    {scope.state.rcvamt2_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt2',
                text: '미수금액',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.iamt2)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#FF00FF' }}
                  >
                    {scope.state.iamt2_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'contdate',
                text: '계약일자',
                width: 1.5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.contdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'projno',
                text: '프로젝트번호',
                width: 1.5,
                render: (x) => {
                  const projnoDash = `${x.projno.substr(0, 4)}-${x.projno.substr(4, 3)}`;
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {projnoDash}
                    </FlexLayout>
                  );
                },
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '진행현황',
                width: 4,
                render: (x) => (
                  <React.Fragment>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      <RowCover color="#FAFA80" />
                      <Button
                        isMini={true}
                        theme={BUTTON_THEMES.BLUE}
                        onClick={() => scope.remarkModal(true)}
                      >
                        조회
                      </Button>
                    </FlexLayout>
                    <FlexLayout weight={5}>
                      <RowCover color="#FAFA80" />
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ marginRight: 10 }}
                      >
                        {x.remark}
                      </FlexLayout>
                    </FlexLayout>
                  </React.Fragment>
                ),
              },
              {
                id: 'hdate',
                text: '답변작성일',
                width: 1.5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={scope.misPopupInfinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.misModalData}
            onRowClick={(item) => scope.onMisModalRowFocused(item)}
          >
          </GridLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
            <Button onClick={() => scope.SS({ misModal: false })}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>

      <ModalView
        isVisible={scope.state.remarkModal}
        onClose={() => scope.remarkModal(false)}
        width={1000}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.misModalFocusedData?.actnm}미수답변내역</LayoutTitle>

          <FlexLayout weight={14}>
            <TableLayout
              ref={scope.remarkTable}
              header={[
                {
                  id: 'seq',
                  text: '순서',
                  width: 10,
                },
                {
                  id: 'inperid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      textAlign="center"
                      value={x.perid}
                      onChange={(value) => rowUpdate({ perid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 10,
                },
                {
                  id: 'indate',
                  text: '기록일자',
                  width: 10,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.indate || Today.date()}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ indate: value })}
                    />
                  ),
                },
                {
                  id: 'saflag',
                  text: '미수사유',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.saflag}
                      data={[
                        { value: '001', remark: '계약조건' },
                        { value: '002', remark: '자금부족' },
                        { value: '003', remark: '법정관리(워크아웃)' },
                        { value: '004', remark: '부도' },
                        { value: '005', remark: '고객불만' },
                        { value: '006', remark: '고객행불' },
                        { value: '007', remark: '계산서오류' },
                        { value: '008', remark: '기타' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ saflag: option.value })}
                    />
                  ),
                },
                {
                  id: 'buildflag',
                  text: '건물상태',
                  width: 10,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.buildflag}
                      data={[
                        { value: '0', remark: '정상' },
                        { value: '1', remark: '폐쇄' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ buildflag: option.value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '진행현황',
                  width: 40,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.remarkModalData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowFocusEvent={(item) => scope.remarkModalFocusedData(item)}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
               <Button onClick={() => scope.dw_2_new()}>신규</Button>
               <Button onClick={() => scope.onRemarkModalSaveEvent()}>저장</Button>
               <Button onClick={() => scope.dw_2_delete()}>삭제</Button>
               <Button onClick={() => scope.remarkModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
