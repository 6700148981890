import { computed } from 'mobx';

/**
 * 은행내역 모델
 * @window w_tb_ca642w_10
 * @category 입출금현황
 */
export class BankModel {
  /**
   * 회사코드
   *
   * **data type** varchar(10)
   */
  readonly custcd: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(50)
   */
  accnum: string;

  /**
   * 발생일자
   *
   * **data type** varchar(4)
   */
  accdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 시간
   *
   * **data type** varchar(6)
   */
  acctime: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  sndamt: string;

  /**
   * 잔액
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 거래유형
   *
   * **data type** char(60)
   */
  content: string;

  /**
   * 비고
   *
   * **data type** char(60)
   */
  etc: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.accnum = data.accnum || '';
    this.accdate = data.accdate || '';
    this.seq = data.seq || '';
    this.acctime = data.acctime || '';
    this.remark = data.remark || '';
    this.rcvamt = data.rcvamt || '';
    this.sndamt = data.sndamt || '';
    this.resuamt = data.resuamt || '';
    this.content = data.content || '';
    this.etc = data.etc || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
