import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import EffortModel from './models/EffortModel';
import { EffortTemplate } from './Effort.template';
import { Date6 } from '../../../../utils/time';
import { PageComponent } from '../../../../utils';
import { ConfirmWarning } from '../../../../utils/confirm';

interface SampleState {
  searchQuery: string;
  year: string;
  spjangcd: string;
  mpclafi: string;
  spjangcds: Array<any>;
  focusIndex: number;
  content: EffortModel;
  graphUrl: string;

  dataKc: Array<EffortModel>;
  dataEnd: Array<EffortModel>;

  tabKcMon01_tot: string;
  tabKcMon02_tot: string;
  tabKcMon03_tot: string;
  tabKcMon04_tot: string;
  tabKcMon05_tot: string;
  tabKcMon06_tot: string;
  tabKcMon07_tot: string;
  tabKcMon08_tot: string;
  tabKcMon09_tot: string;
  tabKcMon10_tot: string;
  tabKcMon11_tot: string;
  tabKcMon12_tot: string;
  tabKcTmon_tot: string;

  tabEndMon01_tot: string;
  tabEndMon02_tot: string;
  tabEndMon03_tot: string;
  tabEndMon04_tot: string;
  tabEndMon05_tot: string;
  tabEndMon06_tot: string;
  tabEndMon07_tot: string;
  tabEndMon08_tot: string;
  tabEndMon09_tot: string;
  tabEndMon10_tot: string;
  tabEndMon11_tot: string;
  tabEndMon12_tot: string;
  tabEndTmon_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_06
 * @category 월공수현황
 */
export class Effort extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinityKc?: InfinityRetrieve;

  infinityEnd?: InfinityRetrieve;

  tabIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      spjangcd: 'ZZ',
      mpclafi: '%',
      year: Date6.make().substr(0, 4),
      content: new EffortModel(),
      graphUrl: '',

      dataKc: [],
      dataEnd: [],

      tabKcMon01_tot: '0',
      tabKcMon02_tot: '0',
      tabKcMon03_tot: '0',
      tabKcMon04_tot: '0',
      tabKcMon05_tot: '0',
      tabKcMon06_tot: '0',
      tabKcMon07_tot: '0',
      tabKcMon08_tot: '0',
      tabKcMon09_tot: '0',
      tabKcMon10_tot: '0',
      tabKcMon11_tot: '0',
      tabKcMon12_tot: '0',
      tabKcTmon_tot: '0',

      tabEndMon01_tot: '0',
      tabEndMon02_tot: '0',
      tabEndMon03_tot: '0',
      tabEndMon04_tot: '0',
      tabEndMon05_tot: '0',
      tabEndMon06_tot: '0',
      tabEndMon07_tot: '0',
      tabEndMon08_tot: '0',
      tabEndMon09_tot: '0',
      tabEndMon10_tot: '0',
      tabEndMon11_tot: '0',
      tabEndMon12_tot: '0',
      tabEndTmon_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const data = await api.dropdown('wf_dd_spjangcd_02');

    if (!data) return;
    this.setState({ spjangcds: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityKc = new InfinityRetrieve(
      {
        year: this.state.year || Date6.make().substr(0, 4),
        spjangcd: this.state.spjangcd || 'ZZ',
        mpclafi: this.state.mpclafi || '0',
      },
      (params) => api.fxExec('tab_1_retrieve', params),
      (items) => {
        this.SS({
          dataKc: [
            ...this.state.dataKc,
            ...items.map((item) => new EffortModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataKc: [],
        });
        this.infinityKc?.retrieveAll();
      },
    );

    this.infinityEnd = new InfinityRetrieve(
      {
        year: this.state.year || Date6.make().substr(0, 4),
        spjangcd: this.state.spjangcd || 'ZZ',
        mpclafi: this.state.mpclafi || '0',
      },
      (params) => api.fxExec('tab_2_retrieve', params),
      (items) => {
        this.SS({
          dataEnd: [
            ...this.state.dataEnd,
            ...items.map((item) => new EffortModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataEnd: [],
        });
        this.infinityEnd?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataKc: [],
      dataEnd: [],
    });

    const dataKc = await this.infinityKc?.retrieveAll();
    const dataEnd = await this.infinityEnd?.retrieveAll();

    this.SS({
      tabKcMon01_tot: dataKc?.mon01_tot || '0',
      tabKcMon02_tot: dataKc?.mon02_tot || '0',
      tabKcMon03_tot: dataKc?.mon03_tot || '0',
      tabKcMon04_tot: dataKc?.mon04_tot || '0',
      tabKcMon05_tot: dataKc?.mon05_tot || '0',
      tabKcMon06_tot: dataKc?.mon06_tot || '0',
      tabKcMon07_tot: dataKc?.mon07_tot || '0',
      tabKcMon08_tot: dataKc?.mon08_tot || '0',
      tabKcMon09_tot: dataKc?.mon09_tot || '0',
      tabKcMon10_tot: dataKc?.mon10_tot || '0',
      tabKcMon11_tot: dataKc?.mon11_tot || '0',
      tabKcMon12_tot: dataKc?.mon12_tot || '0',
      tabKcTmon_tot: dataKc?.tmon_tot || '0',

      tabEndMon01_tot: dataEnd?.mon01_tot || '0',
      tabEndMon02_tot: dataEnd?.mon02_tot || '0',
      tabEndMon03_tot: dataEnd?.mon03_tot || '0',
      tabEndMon04_tot: dataEnd?.mon04_tot || '0',
      tabEndMon05_tot: dataEnd?.mon05_tot || '0',
      tabEndMon06_tot: dataEnd?.mon06_tot || '0',
      tabEndMon07_tot: dataEnd?.mon07_tot || '0',
      tabEndMon08_tot: dataEnd?.mon08_tot || '0',
      tabEndMon09_tot: dataEnd?.mon09_tot || '0',
      tabEndMon10_tot: dataEnd?.mon10_tot || '0',
      tabEndMon11_tot: dataEnd?.mon11_tot || '0',
      tabEndMon12_tot: dataEnd?.mon12_tot || '0',
      tabEndTmon_tot: dataEnd?.tmon_tot || '0',
    });

    this.updateGraphUrl();
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxPrint(`tab_${this.tabIndex + 1}_print`, {
      year: this.state.year || Date6.make().substr(0, 4),
      spjangcd: this.state.spjangcd || 'ZZ',
      mpclafi: this.state.mpclafi || '0',
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxExcel(`tab_${this.tabIndex + 1}_excel`, {
      year: this.state.year || Date6.make().substr(0, 4),
      spjangcd: this.state.spjangcd || 'ZZ',
      mpclafi: this.state.mpclafi || '0',
    });
  }

  @action
  updateGraphUrl() {
    const { publicStore } = this.props;
    const { custcd } = publicStore.user;
    this.SS({
      graphUrl: `https://api.elmansoft.com/chart/stick7.php?window=w_tb_e601w_sulchi_06&type=1&as_custcd=${custcd}&as_spjangcd=${this.state.spjangcd}&as_year=${this.state.year}&as_mpclafi=${this.state.mpclafi}`,
    });
  }

  onTabChange(index: number) {
    this.tabIndex = index;
  }

  getPrintRowCount(): number {
    let rowCount = 0;
    switch (this.tabIndex) {
      case 0:
        rowCount = this.state.dataKc.length;
        break;

      case 1:
        rowCount = this.state.dataEnd.length;
        break;
    }

    return rowCount;
  }

  render() {
    return <EffortTemplate
      scope={this}
    />;
  }
}
