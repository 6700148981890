import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdReorder,
  MdPublish,
  MdSentimentVeryDissatisfied,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  UserTimeModel,
  UserERPModel,
  UserAppModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { UserTemplate } from './User.template';
import { InfinityRetrieve } from '../../../../models/common';
import { TabHeaderUserAPP, TabHeaderUserERP, TabHeaderUserTime } from './tabs';
import { PageComponent } from '../../../../utils';

export enum UserTabId {
  User,
  UserErp,
  UserApp,
}

export const UserTabTitles = [
  '사용자별 접속시간',
  '사용자별 ERP사용건수',
  '사용자별 어플사용건수',
];

export const UserTabModels = [
  UserTimeModel,
  UserERPModel,
  UserAppModel,
];

export const UserTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
];

export const UserTabDataStateNames = [
  'Users',
  'UserErps',
  'UserApps',
];

export const UserTabUpdatesStateNames = [
  'UserUpdates',
  'UserErpUpdates',
  'UserAppUpdates',
];

export const UserTabFocusedStateNames = [
  'UserUpdatesFocused',
  'UserErpUpdatesFocused',
  'UserAppUpdatesFocused',
];

export interface UserState {
  // 사용자별 접속시간
  Users?: Array<UserTimeModel>;
  UserUpdates?: Array<UserTimeModel>;
  UsersFocused?: UserTimeModel;

  // 사용자별 접속시간
  UserErps?: Array<UserERPModel>;
  UserErpUpdates?: Array<UserERPModel>;
  UserErpsFocused?: UserERPModel;

  // 사용자별 접속시간
  UserApps?: Array<UserAppModel>;
  UserAppUpdates?: Array<UserAppModel>;
  UserAppsFocused?: UserAppModel;

  focusedTab?: TabModel;
  stdate?: string;
  enddate?: string;
  searchQuery?: string;
  divicd?: string;
  divinm?: string;
  perid?: string;
  pernm?: string;
  spjangcd?: string;
  url?: string;
  url2?: string;
  url3?: string;

  // 하단 총계
  appendhour_tot: string;
  appendtime_tot: string;
  erpendhour_tot: string;
  erpendtime_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_xlogin_02
 * @category 사용자별접속현황
 */
export class User extends PageComponent<PageProps, UserState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = UserTabId;
    const titles = UserTabTitles;

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.tabs = [
      new TabModel(id.User.toString(), MdReorder, titles[id.User]),
      new TabModel(id.UserErp.toString(), MdPublish, titles[id.UserErp]),
      new TabModel(id.UserApp.toString(), MdSentimentVeryDissatisfied, titles[id.UserApp]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}0${1}`,
      enddate: `${year}${month}${date}`,
      divicd: '',
      perid: '',
      appendhour_tot: '시간',
      appendtime_tot: '분',
      erpendhour_tot: '시간',
      erpendtime_tot: '분',
      data: [],
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 사용자별 접속시간
      TabHeaderUserTime,

      // 사용자별 ERP사용건수
      TabHeaderUserERP,

      // 사용자별 어플사용건수
      TabHeaderUserAPP,
    ];
  }

  @action
  async onFirstOpenEvent() {
    await this.onTabChange(this.tabs[UserTabId.User]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: UserTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.fxExec(
        `${UserTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          //  @ts-ignore
          this.setState({
            [UserTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[UserTabDataStateNames[i]],
                ...items.map((x: any) => new UserTabModels[i](x))],
          }, () => this.table.current?.update(false));
          next && next();
        }
      },
      async () => {
        //  @ts-ignore
        await this.SS({
          [UserTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        this.table.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    //  @ts-ignore
    this.setState({
      [UserTabDataStateNames[i]]: [],
      [UserTabUpdatesStateNames[i]]: [],
      [UserTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.infinity?.retrieveAll();
      const data = await this.infinity?.box;

      data && await this.SS({
        appendhour_tot: data.appendhour_tot || '0',
        appendtime_tot: data.appendhour_tot || '0',
        erpendhour_tot: data.erpendhour_tot || '0',
        erpendtime_tot: data.erpendhour_tot || '0',
      });

      await this.table.current?.update(false);
      this.table.current?.setFocus(0);
      // @ts-ignore
      this.onRowFocusEvent(this.state[UserTabDataStateNames[0]]);
    });
  }

  @computed
  get tabIndex(): UserTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    //  @ts-ignore
    this.setState({
      [UserTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [UserTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  @action
  onRowFocusEvent(item: any) {
    const { user } = this.props.publicStore;
    const url = `https://api.elmansoft.com/chart/pie.php?window=w_tb_xlogin_02_01&type=1&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=%&as_perid=p${item.userid}`;
    const url2 = `https://api.elmansoft.com/chart/pie.php?window=w_tb_xlogin_02_01&type=2&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=%&as_perid=p${item.userid}`;
    const url3 = `https://api.elmansoft.com/chart/pie.php?window=w_tb_xlogin_02_01&type=3&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_divicd=%&as_perid=p${item.userid}`;

    //  @ts-ignore
    this.setState({
      [UserTabFocusedStateNames[this.tabIndex]]: item,
      url,
      url2,
      url3,
    });
  }

  render() {
    return (
      <UserTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
