import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { CashBookTemplate } from './CashBook.template';
import { CashBookModel } from './CashBook.model';
import { InfinityRetrieve, SpjangItem } from '../../../../models';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface CashBookState {
  // search
  stdate: string;
  enddate: string;
  spjangcd: string;
  spjangcds?: Array<SpjangItem>;
  cltcd: string;
  cltnm: string;

  // data
  data: Array<CashBookModel>;
  focused?: CashBookModel;

  // trail
  misamt_tot: string;
  mijamt_tot: string;
  resuamt_tot: string;
}

/**
 * 컨트롤러
 * @window  w_tb_ca642w_09
 * @category 현금출납장
 */
export class CashBook extends PageComponent<PageProps, CashBookState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      spjangcd: 'ZZ',
      cltcd: '',
      data: [],
      misamt_tot: '0',
      mijamt_tot: '0',
      resuamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트
    const data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        spjangcd: this.state.spjangcd,
        cltcd: this.state.cltcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new CashBookModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['misdate', 'resuamt'],
        [this.state.focused?.misdate, this.state.focused?.resuamt], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
      this.SS({
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent(item: CashBookModel) {
    this.setState({ focused: item });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      cltcd: this.state.cltcd,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      cltcd: this.state.cltcd,
    });
  }

  render() {
    return (
      <CashBookTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
