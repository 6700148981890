import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e037_type2
 * @category 개별업무일지
 */

export class RoutineModel {
  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 담당자
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 진행
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 결과
   *
   * **data type** varchar(3)
   */
  readonly result: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   * 구분
   *
   * **data type** varchar(3)
   */
  readonly gubun: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 검사자
   *
   * **data type** varchar(50)
   */
  readonly kcpernm: string;

  /**
   * 검사기관
   *
   * **data type** varchar(200)
   */
  readonly kcspnm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.state = data.state || '';
    this.plandate = data.plandate || '';
    this.result = data.result || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.gubun = data.gubun || '';
    this.kcdate = data.kcdate || '';
    this.kcpernm = data.kcpernm || '';
    this.kcspnm = data.kcspnm || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
