import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { TabModel } from '../../../../models';
import { MonthResult } from './MonthResult';
import { IndividualTemplate } from './tabs/Individual.tab';
import { DepartmentTemplate } from './tabs/Department.tab';
import { MonthTemplate } from './tabs/Month.tab';

/**
 * 화면 > 탭
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export const MonthResultTab: Array<TabLayoutDataItem<MonthResult>> = [
  {
    tab: new TabModel('DE', FaCodeBranch, '부서별'),
    template: (scope) => <DepartmentTemplate scope={scope} />,
  },
  {
    tab: new TabModel('IN', FaCodeBranch, '개인별'),
    template: (scope) => <IndividualTemplate scope={scope} />,
  },
  {
    tab: new TabModel('MO', FaCodeBranch, '월별합계'),
    template: (scope) => <MonthTemplate scope={scope} />,
  },
];
