import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ConfirmWarning } from '../../utils/confirm';
import { ModalStore } from '../../stores';
import {
  ModalView,
  FlexLayout,
  SearchLayout,
  TextBox,
  GridLayout,
  Button,
} from '../../components';
import { Global } from '../../constants';

interface ModalSearchAddressProps {
  modalStore: ModalStore;
}

@observer
export class ModalSearchAddress extends React.Component<ModalSearchAddressProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1100}
        isVisible={modalStore.isVisibleSearchAddress}
        onClose={() => modalStore.closeSearchAddress()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              align="center"
              justify="center"
              weight={3}
            >
              <span style={{ margin: 8, fontSize: 12 }}>검색어</span>
              <TextBox
                value={modalStore.searchAddressSearchQuery}
                onChange={(value: any) => modalStore.updateSearchAddressSearchQuery(value)}
                onEnter={() => modalStore.searchAddress()}
              />
            </FlexLayout>
          </SearchLayout>

          <GridLayout
            ref={modalStore.searchAddressGrid}
            header={[
              {
                id: 'name',
                text: '이름',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.name}
                  </FlexLayout>
                ),
              },
              {
                id: 'roadAddress',
                text: '도로명주소',
                width: 40,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.roadAddress}
                  </FlexLayout>
                ),
              },
              {
                id: 'address',
                text: '구주소',
                width: 40,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.address}
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore.searchAddressData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item: any) => modalStore.onSearchAddressRowFocusEvent(item)}
            onRowDoubleClick={(item: any) => modalStore.successSearchAddress(item)}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeSearchAddress()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (modalStore.selectedSearchAddressData) {
                  modalStore.successSearchAddress(modalStore.selectedSearchAddressData);
                } else {
                  ConfirmWarning.show('오류', '항목을 먼저 선택해주세요.');
                }
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
