import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { HolidayGiftTemplate } from './HolidayGift.template';
import { HolidayGiftModel } from './models/HolidayGift.model';
import { Today } from '../../../../utils/time';
import { TableLayout } from '../../../../components';
import {
  InfinityRetrieve,
  SpjangItem,
} from '../../../../models';
import { GiftModel } from './models/Gift.model';
import {
  FileReader,
  FileSelector,
} from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import {
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import FunctionRepository from '../../../../repositories/FunctionRepository';

interface HolidayGiftState {
  // 년도
  spjangcd: string;
  year: string;
  gubun: string;
  searchQuery: string;
  perid: string;
  src: string;

  spjangcds?: Array<SpjangItem>;

  focused: HolidayGiftModel;
  detailFocusedData: GiftModel;
  holidayGiftList: Array<HolidayGiftModel>;
  giftDetailtList: Array<GiftModel>;

  img_pic?: ArrayBuffer;

  focusIndex: number;
  focusDetailIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_gift_history
 * @category 명절선물등록
 */
export class HolidayGift extends PageComponent<PageProps, HolidayGiftState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  table2: React.RefObject<TableLayout> = React.createRef();

  holidayGiftUserUpdates: Array<HolidayGiftModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    this.holidayGiftUserUpdates = [];

    this.state = props.state || {
      spjangcd: 'ZZ',
      year: Today.year(),
      gubun: '2',
      searchQuery: '',
      perid: '',

      focused: [],
      detailFocusedData: [],
      holidayGiftList: [],
      giftDetailtList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 사업장 리스트 가져오기
    const data = await api.dropdown('wf_dd_spjangcd_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd,
        year: this.state.year,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
      (params) => (api.retrieve(params)),
      async (items, next) => {
        this.setState({
          holidayGiftList: [
            ...this.state.holidayGiftList,
            ...items.map((x: any) => new HolidayGiftModel(x)),
          ],
        });
        await this.table.current?.update(false);

        if (this.state.focusIndex !== undefined) {
          await this.table.current?.setFocus(this.state.focusIndex);
        } else {
          await this.table.current?.setFocus(0);
        }
        next && next();
      },
      async () => {
        await this.SS({
          holidayGiftList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.holidayGiftList && this.state.holidayGiftList?.length > 0) {
          await this.table.current?.setFocus(0);
        }
      },
    );

    this.setState({
      holidayGiftList: [],
      img_pic: new ArrayBuffer(0),
    }, async () => {
      await this.infinity?.retrieveAll();
      await this.table.current?.update();
    });

    this.infinity2 = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd,
        year: this.state.year,
        gubun: this.state.gubun,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.setState({
          giftDetailtList: [...this.state.giftDetailtList, ...items.map((x: any) => new GiftModel(x))],
        }, async () => {
          await this.table2.current?.update(false);
          await this.table2.current?.setFocus(0, 0);
        });
      },
      async () => {
        await this.SS({
          giftDetailtList: [],
        });
        await this.infinity2?.retrieveAll();
        if (this.state.giftDetailtList && this.state.giftDetailtList?.length > 0) {
          await this.table2.current?.setFocus(0);
        }
      },
    );
    this.setState({
      giftDetailtList: [],
    }, async () => {
      await this.infinity2?.retrieveAll();
      await this.table2.current?.update();
    });
  }

  @action
  async onRowFocusEvent(item: HolidayGiftModel) {
    await this.SS({
      focused: item,
    });
  }

  @action
  async onRowFocusEvent2(item: GiftModel) {
    const { actionStore: api } = this.props;

    await this.SS({
      detailFocusedData: item,
    });
    const image = await api.fxBinary(
      'pic_retrieve',
      {
        new: this.state.detailFocusedData.new,
        year: this.state.year,
        gubun: this.state.gubun,
        giftcd: this.state.detailFocusedData.giftcd,
      },
    );
    await this.SS({ img_pic: image });
  }

  @action
  async onUserTableChange(rows: Array<HolidayGiftModel>, updates: Array<HolidayGiftModel>) {
    this.holidayGiftUserUpdates = updates;
    await this.SS({
      holidayGiftList: rows,
    });
  }

  @action
  onGiftTableChange(rows: Array<GiftModel>) {
    this.SS({
      giftDetailtList: rows,
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.holidayGiftList[0]?.endflag === '1') {
      ConfirmWarning.show('경고', '이미 마감처리 되었습니다');
      return;
    }

    const data = await api.new(
      {
        uv_arg1: this.state.year,
        uv_arg2: this.state.gubun,
      },
    );

    this.setState({
      holidayGiftList: [...this.state.holidayGiftList, new HolidayGiftModel({
        ...data,
        year: this.state.year,
        gubun: this.state.gubun,
      }, true)],
    }, async () => {
      this.table.current?.update(true);
      this.table.current?.setFocus(this.state.holidayGiftList.length - 1, 0);
    });
  }

  @action
  async onDetailNewEvent() {
    const { actionStore: api, publicStore } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        year: this.state.year,
        gubun: this.state.gubun,
      },
    );

    this.setState({
      giftDetailtList: [...this.state.giftDetailtList, new GiftModel({
        ...data,
        year: this.state.year,
        gubun: this.state.gubun,
        spjangcd: publicStore.user.spjangcd,
      }, true)],
      img_pic: new ArrayBuffer(0),
    }, async () => {
      await this.table2.current?.update(true);
      this.table2.current?.setFocus(this.state.giftDetailtList.length - 1, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (this.state.holidayGiftList.length === 0) return;

    if (this.state.holidayGiftList[0]?.endflag === '1') {
      ConfirmWarning.show('경고', '이미 마감처리 되었습니다');
      return;
    }

    if (this.state.focused.pernm.trim() === '') {
      ConfirmWarning.show('확인', '사원명을 입력(선택)하십시오.');
      return;
    }

    if (this.state.detailFocusedData.giftnm.trim() === '') {
      ConfirmWarning.show('확인', '선물명 입력하십시오.');
      return;
    }

    await api.save({
      items: this.state.holidayGiftList,
      items2: this.state.giftDetailtList,
    }, false);
    await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.holidayGiftList.length === 0) return;

    if (this.state.holidayGiftList[0]?.endflag === '1') {
      ConfirmWarning.show('경고', '이미 마감처리 되었습니다');
      return;
    }

    const text = `번 호 : ${this.state.focused?.seq} 사 원 명 : ${this.state.focused?.pernm}`;
    await api.delete(
      text,
      {
        spjangcd: this.state.focused?.spjangcd,
        year: this.state.year,
        gubun: this.state.gubun,
        seq: this.state.focused?.seq,
      },
    );
    await this.onRetrieveEvent();
  }

  @action
  async onDetailDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.giftDetailtList.length === 0) return;

    const text = `코 드 : ${this.state.detailFocusedData?.giftcd} 선 물 명 : ${this.state.detailFocusedData?.giftnm}`;
    await api.fxDelete(
      'dw_2_delete',
      text,
      {
        spjangcd: this.state.spjangcd,
        year: this.state.year,
        gubun: this.state.gubun,
        giftcd: this.state.detailFocusedData.giftcd,
      },
    );
    await this.onRetrieveEvent();
  }

  @action
  async imageSelect() {
    if (this.state.giftDetailtList.filter((x) => x.isNew).length) {
      ConfirmWarning.show('경고', '우선 저장 후에 시도해주세요.');
      return;
    }

    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728);
      await this.imageUpload(files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async imageUpload(filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    const tempInfo = await api.tempUpload(file, filename, () => {});

    if (await api.exec(
      Category.MATERIAL,
      'pic_save',
      {
        new: this.state.detailFocusedData.new,
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        year: this.state.year,
        gubun: this.state.gubun,
        giftcd: this.state.detailFocusedData.giftcd,
        tempfile: tempInfo.data,
        dir: tempInfo.dir,
        filename: tempInfo.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    )) {
      ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      await this.onRetrieveEvent();
    }
  }

  @action
  async imageDelete() {
    const { actionStore: api } = this.props;

    if (await api.exec(
      Category.MAINTENANCE,
      'pic_delete',
      {
        new: this.state.detailFocusedData.new,
        year: this.state.year,
        gubun: this.state.gubun,
        giftcd: this.state.detailFocusedData.giftcd,
      },
    )) {
      const image = await api.fxBinary(
        'pic_retrieve',
        {
          new: this.state.detailFocusedData.new,
          year: this.state.year,
          gubun: this.state.gubun,
          giftcd: this.state.detailFocusedData.giftcd,
        },
      );
      await this.SS({ img_pic: image });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.holidayGiftList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.printWithElmanManager({
      src: this.state.src,
      spjangcd: this.state.spjangcd,
      year: this.state.year,
      gubun: this.state.gubun,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.holidayGiftList.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.excel({
      src: this.state.src,
      spjangcd: this.state.spjangcd,
      year: this.state.year,
      gubun: this.state.gubun,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async sendSMS(row: HolidayGiftModel) {
    const { user } = this.props.publicStore;
    // 문자버튼 클릭시 chk값 1로 바꾼 후 sms 전송
    this.state.focused.chk = '1';

    await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/event/libs/send.php', {
        custcd: user.custcd,
        spjangcd: user.spjangcd,
        year: this.state.year,
        gubun: this.state.gubun,
        seq: row.seq,
      },
    );

    ConfirmSuccess.show('전송', '성공적으로 문자를 전송하였습니다');
  }

  @action
  async smsCheckAll() {
    const { user } = this.props.publicStore;
    // 체크박스 체크후
    await this.toggleCheckbox();
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.state.holidayGiftList.filter((x) => x.chk === '1')) {
      // eslint-disable-next-line no-await-in-loop
      await FunctionRepository.request(
        false,
        'https://api.elmansoft.com/event/libs/send.php', {
          custcd: user.custcd,
          spjangcd: user.spjangcd,
          year: this.state.year,
          gubun: this.state.gubun,
          seq: item.seq,
        },
      );
    }
    ConfirmSuccess.show('전송', '성공적으로 문자를 전송하였습니다');
  }

  @action
  async toggleCheckbox() {
    if (!this.state.holidayGiftList.length) {
      return;
    }

    const newChk = this.state.holidayGiftList[0].chk === '0' ? '1' : '0';
    await this.SS({
      holidayGiftList: this.state.holidayGiftList.map((x) => new HolidayGiftModel({
        ...x,
        chk: newChk,
      }, x.isNew)),
    });
    this.table.current?.update(false);
  }

  @action
  async peridAllBtn() {
    const { actionStore: api, publicStore } = this.props;
    // 일괄적용버튼
    // 행이 이미 있을시에
    if (this.state.holidayGiftList.length > 0) {
      // 첫번째행 endflag 0이면
      if (this.state.holidayGiftList[0].endflag === '0') {
        const result = await Confirm.ask('선택', '재직자중 전화번호가 기입된 사원들은 불러오게 됩니다 불러오시겠습니까?', '예', '아니오');

        // 아니오 누르면 return
        if (result === 1) {
          return;
        }
        const result2 = await Confirm.show('선택', '이미 작성된 명단이 있습니다 해당내역 삭제되고 다시 불러오게됩니다 불러오시겠습니까?', ConfirmType.QUESTION);

        // 취소 누르면 return
        if (!result2) {
          return;
        }

        await api.fxExec(
          'wb_peridall',
          {
            year: this.state.year,
            gubun: this.state.gubun,
            spjangcd: publicStore.user.spjangcd,
          },
        );
        await ConfirmWarning.show('확인', '재직자들 전부 등록되었습니다!');
        await this.onRetrieveEvent();
      } else {
        // 첫번째행 endflag 1이면 마감x
        await ConfirmWarning.show('확인', '이미 마감처리되었습니다! \n 불러올 수 없습니다.');
      }
    } else {
      // 목록 없을 때 일괄적용
      const result = await Confirm.ask('선택', '재직자중 전화번호가 기입된 사원들은 불러오게 됩니다 불러오시겠습니까?', '예', '아니오');

      // 아니오 누르면 return
      if (result === 1) {
        return;
      }

      await api.fxExec(
        'wb_peridall',
        {
          year: this.state.year,
          gubun: this.state.gubun,
          spjnacd: publicStore.user.spjangcd,
        },
      );
      await ConfirmWarning.show('확인', '재직자들 전부 등록되었습니다!');
      await this.onRetrieveEvent();
    }
  }

  @action
  async endBtn() {
    const { actionStore: api } = this.props;

    if (this.state.holidayGiftList.length > 0) {
      // 첫번째 행 endflag 0이면 마감, 1이면 마감 취소
      if (this.state.holidayGiftList[0].endflag === '0') {
        await api.fxExec(
          'wb_end',
          {
            year: this.state.year,
            gubun: this.state.gubun,
            flag: 'ok',
          },
        );
        await ConfirmWarning.show('확인', '마감 처리되었습니다.');
      } else {
        await api.fxExec(
          'wb_end',
          {
            year: this.state.year,
            gubun: this.state.gubun,
            flag: 'cancel',
          },
        );
        await ConfirmWarning.show('확인', '마감 취소되었습니다.');
      }
      await this.onRetrieveEvent();
    }
  }

  render() {
    return (
      <HolidayGiftTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focused: {
              ...this.state.focused,
              ...change,
            },
            detailFocusedData: {
              ...this.state.detailFocusedData,
              ...change,
            },
          }, () => callback && callback());
        }}
      >
      </HolidayGiftTemplate>
    );
  }
}
