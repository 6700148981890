import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  FormatTextBox,
  DateTextBox,
  ComboBox,
  ComboBoxModel,
  LayoutTitle,
  Button,
  TableLayout,
  ModalView, IFrame, CheckBox, LabelBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { MonthContract } from './MonthContract';
import { SearchBinding } from '../../../../models';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e101w_02
 * @category 월 계약변동내역
 */

const flags = [
  { value: '%', remark: '전체' },
  { value: '1', remark: '이월' },
  { value: '2', remark: '당월' },
  { value: '3', remark: '신규' },
  { value: '5', remark: '해지' },
];


export const MonthContractTemplate: React.FC<TemplateProps<MonthContract>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout weight={4}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>계약분류</span>
          <ComboBox
            value={scope.state.contgubun}
            data={scope.state.contgubuns?.map((y) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => setData({ contgubun: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.contg}
            data={scope.state.contgs?.map((y) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => setData({ contg: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={208}>
          <span >처리</span>
          <ComboBox
            style={{ marginLeft: 24 }}
            value={scope.state.compflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '미처리' },
              { value: '1', remark: '처리' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ compflag: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span
            style={{
              color: 'var(--color-red)',
              marginLeft: 26,
            }}>
            회사구분
          </span>
          <TextBox
            weight={1.4}
            style={{
              color: 'var(--color-red)',
            }}
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.wkactnm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout weight={2.1}>
          <span>분류</span>
          <ComboBox
            value={scope.state.flag}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '이월' },
              { value: '2', remark: '당월' },
              { value: '3', remark: '신규' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ flag: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            weight={1.4}
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout>
          <span
            style={{
              color: 'var(--color-red)',
              marginLeft: 28,
            }}>
            사용자
          </span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span
            style={{
              color: 'var(--color-red)',
              marginLeft: 28,
            }}>
            계약자
          </span>
          <TextBox
            value={scope.state.contpernm}
            textAlign="center"
            onChange={(value) => setData({ contpernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ contperid: item.cd, contpernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.contperid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'divinm',
                text: '담당자별그룹',
                isHidden: true,
                group: true,
                value: (x) => x.divinm,
              },
              {
                id: 'flag',
                text: '분류',
                width: 50,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.flag === '5' ? { color: 'var(--color-red)' } : ''}
                  >
                    {flags?.filter((y) => y.value === x.flag)[0]?.remark || '-'}
                  </FlexLayout>
                ),
              },
              {
                id: 'compflag',
                text: '처리',
                width: 60,
                sum: (x, data) => {
                  const sumCompflag = Sum.groupValue(data, 'compflag', x, '5', ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                     해지: {sumCompflag} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.clscnt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.compflag === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                  >
                    {x.compflag === '0' ? '미처리' : '처리'}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 80,
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 80,
                sum: (x, data) => {
                  const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {sumPernm} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.total}건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 80,
                sum: (x, data) => {
                  const sumCompcnt = Sum.groupValue(data, 'compcnt', x, '1', ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-gray)' }}
                    >
                      완료: {sumCompcnt} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                  >
                    {scope.state.compcnt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'okflag',
                text: '전자',
                width: 70,
                sum: (x, data) => {
                  const sumContgubun01 = Sum.groupValue(data, 'contgubun01', x, '1', ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-gray)' }}
                    >
                      (FM: {sumContgubun01} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                  >
                    {scope.state.contgubun01_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.okflag === '1' ? { color: 'var(--color-blue)' } : ''}
                  >
                    {x.okflag === '1' ? '전자' : '수기'}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 70,
                sum: (x, data) => {
                  const sumContgubun02 = Sum.groupValue(data, 'contgubun02', x, '1', ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-gray)' }}
                    >
                      다년: {sumContgubun02} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                  >
                    {scope.state.contgubun02_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                    style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
                  >
                    {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                sum: (x, data) => {
                  const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
                  const sumClscnt = Sum.groupValue(data, 'clscnt', x, '1', ['divinm']);
                  const sumCompcnt = Sum.groupValue(data, 'compcnt', x, '1', ['divinm']);
                  const sumContgubun03 = Sum.groupValue(data, 'contgubun03', x, '1', ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: 'var(--color-gray)' }}
                      >
                        일반: {sumContgubun03} 건)
                      </FlexLayout>
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        미완료: {(Number(sumPernm) - Number(sumClscnt)) - Number(sumCompcnt)}건
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-green)' }}
                    >
                      {scope.state.contgubun03_tot}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {scope.state.notcnt_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'beqty',
                text: '전대수',
                width: 40,
                color: 'var(--color-gray)',
                sum: (x, data) => {
                  const sumQty = Sum.byGroup(data, 'beqty', x, ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-gray)' }}
                    >
                      {sumQty}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {scope.state.beqty_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {x.beqty}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: 'var(--color-gray)',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: 'var(--color-gray)',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: 'var(--color-gray)',
                sum: (x, data) => {
                  const sumBeamt = Sum.byGroup(data, 'beamt', x, ['divinm']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumBeamt)}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-pink)' }}
                  >
                    {scope.state.beamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt2',
                text: '전보수료(대당가)',
                width: 100,
                color: 'var(--color-gray)',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {Number(x.qty) > 0 ? Format.number(String(Math.round(parseInt(x.beamt, 10) / parseInt(x.qty, 10)))) : ''}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: 'var(--color-gray)',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-gray)' }}
                  >
                    {scope.state.contgubuns?.filter((y) => y.code === x.becontgubun)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: 'var(--color-gray)',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.becontg === '01' ? { color: 'var(--color-blue)' }
                      : x.becontg === '04' ? { color: 'var(--color-red)' } : { color: 'var(--color-gray)' }}
                  >
                    {scope.state.contgs?.filter((y) => y.code === x.becontg)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'varianceQty',
                text: '대수변동',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                    style={Number(x.qty) - Number(x.beqty) > 0 ? { color: 'var(--color-blue)' }
                      : Number(x.qty) - Number(x.beqty) < 0 ? { color: 'var(--color-red)' }
                        : { color: 'var(--color-black)' }}
                  >
                    <FlexLayout>
                      {Number(x.qty) - Number(x.beqty) > 0 ? '↑'
                        : Number(x.qty) - Number(x.beqty) < 0 ? '↓'
                          : ''}
                    </FlexLayout>
                    <FlexLayout>
                      {String(Number(x.qty) - Number(x.beqty)) === '0' ? '-' : String(Number(x.qty) - Number(x.beqty))}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'qty',
                text: '대수',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.qty}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumQty}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.qty_tot}
                  </FlexLayout>
                ),
              },
              {
                id: '',
                text: '금액변동',
                width: 100,
                sum: (x, data) => {
                  const sumBeamt = Sum.byGroup(data, 'beamt', x, ['divinm']);
                  const sumAmt = Sum.byGroup(data, 'amt', x, ['divinm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={Number(sumAmt) - Number(sumBeamt) > 0
                        ? { color: 'var(--color-blue)' } : Number(sumAmt) - Number(sumBeamt) < 0
                          ? { color: 'var(--color-red)' } : ''}
                    >
                      {Number(sumAmt) - Number(sumBeamt) > 0
                        ? '보수료 상승' : Number(sumAmt) - Number(sumBeamt) < 0
                          ? '보수료 하락' : '보수료 동일'}
                    </FlexLayout>
                  );
                },
                trail: () => {
                  function stringNumberToInt(stringNumber: string) {
                    return parseInt(stringNumber.replace(/,/g, ''), 10);
                  }
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={stringNumberToInt(scope.state.amt_tot) - stringNumberToInt(scope.state.beamt_tot) > 0
                        ? { color: 'var(--color-blue)' } : stringNumberToInt(scope.state.amt_tot)
                        - stringNumberToInt(scope.state.beamt_tot) < 0 ? { color: 'var(--color-red)' } : ''}
                    >
                      {stringNumberToInt(scope.state.amt_tot) - stringNumberToInt(scope.state.beamt_tot) > 0
                        ? '보수료 상승' : stringNumberToInt(scope.state.amt_tot)
                        - stringNumberToInt(scope.state.beamt_tot) < 0 ? '보수료 하락' : '보수료 동일'}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={(parseInt(x.amt, 10) - parseInt(x.beamt, 10)) > 0
                        ? { color: 'var(--color-blue)' }
                        : (parseInt(x.amt, 10) - parseInt(x.beamt, 10)) < 0 ? { color: 'var(--color-red)' } : '' }
                    >
                      {(parseInt(x.amt, 10) - parseInt(x.beamt, 10)) > 0
                        ? '↑' : (parseInt(x.amt, 10) - parseInt(x.beamt, 10)) < 0 ? '↓' : '-' }
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={(parseInt(x.amt, 10) - parseInt(x.beamt, 10)) > 0
                        ? { color: 'var(--color-blue)' }
                        : (parseInt(x.amt, 10) - parseInt(x.beamt, 10)) < 0 ? { color: 'var(--color-red)' } : '' }
                    >
                      {(parseInt(x.amt, 10) - parseInt(x.beamt, 10))}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                sum: (x, data) => {
                  const sumAmt = Sum.byGroup(data, 'amt', x, ['divinm']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumAmt)}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-pink)' }}
                  >
                    {scope.state.amt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.contgubuns.filter((y) => y.code === x.contgubun)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.contg === '01' ? { color: 'var(--color-blue)' }
                      : x.contg === '04' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                  >
                    {scope.state.contgs.filter((y) => y.code === x.contg)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변일',
                width: 150,
                render: (x) => (
                  <FlexLayout style={{ backgroundColor: '#FAFA80' }}>
                    <Button
                      style={{
                        height: '20px',
                        width: '40px',
                        marginTop: 10,
                        marginLeft: 5,
                      }}
                      theme={BUTTON_THEMES.BLUE}
                      onClick={() => scope.historyPopupModal(true)}
                    >
                     조회
                    </Button>
                    <FlexLayout style={{ backgroundColor: '#FAFA80' }} weight={5}>
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ marginRight: 10 }}
                      >
                        {Date8.withDash(x.hdate)}
                      </FlexLayout>
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 500,
                render: (x) => (
                  <FlexLayout style={{ backgroundColor: '#FAFA80' }} weight={5}>
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ marginRight: 10 }}
                    >
                      {x.title}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
            data={scope.state.data}
          />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout weight={0.5}>
        <IFrame src={scope.state.url} />
      </FlexLayout>


      <ModalView
        isVisible={scope.state.historyModal}
        onClose={() => scope.historyPopupModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약내용기록
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
                <FlexLayout>
                  {scope.state.focusedData?.actnm} 계약내역
                </FlexLayout>
                <FlexLayout justify='right'>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    onClick={() => scope.onNewHistoryEvent()}
                  >
                    신규
                  </Button>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    onClick={() => scope.onHistorySaveEvent()}
                  >
                    저장
                  </Button>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.historyDelete()}
                  >
                    삭제
                  </Button>
                  <Button
                    style={{ height: '25px', width: '80px' }}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.historyPopupModal(false)}
                  >
                    닫기
                  </Button>
                </FlexLayout>
              </FlexLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableHistory}
                  header={[
                    {
                      id: 'seq',
                      text: '순서',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.seq}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'perid',
                      text: '작성자',
                      width: 8,
                      color: 'var(--color-red)',
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <TextBox
                            value={x.perid}
                            textAlign="center"
                            onChange={(value) => rowUpdate({ perid: value })}
                            bindSearchModal={new SearchBinding(
                              scope.props.modalStore,
                              'TB_JA001',
                              {},
                              true,
                              (item) => {
                                rowUpdate({
                                  perid: item.cd,
                                  pernm: item.cdnm,
                                });
                              },
                            )}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'pernm',
                      text: '작성자명',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.pernm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'hdate',
                      text: '기록일자',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.hdate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'wakedate',
                      text: '알림만료일',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.wakedate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'title',
                      text: '제목',
                      width: 40,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          <TextBox
                            value={x.title}
                            textAlign="left"
                            onChange={(value) => rowUpdate({ title: value })}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'okflag',
                      text: '답변확인',
                      width: 6,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <CheckBox
                            noMargin
                            value={x.okflag === '1'}
                            onChange={(value) => {
                              rowUpdate({ okflag: value ? '1' : '0' });
                            }}
                          />
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.historyData || []}
                  infinityHandler={scope.infinityHistory}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onChange={(rows2, updatedRows2) => {
                    scope.onUpdatedRows2(rows2, updatedRows2);
                  }}
                  onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout>
          <LabelBox title="계약내용">
            <TextBox
              value={scope.state.remark}
              onChange={(value) => setData({ remark: value })}
              isMultiline={true}
            />
          </LabelBox>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
