import * as React from 'react';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Account } from './Account';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_s004
 * @category 계좌번호등록
 */
export const AccountTemplate: React.FC<TemplateProps<Account>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout>
            <span>검 색 어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="은행명, 계좌번호, 계좌명칭으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
        <FlexLayout isVertical={true}>
          <FlexLayout
            align="center"
            justify="center"
            isVertical={true}
          >
              계좌연동은 부가서비스입니다.
              1계좌는 무료입니다(1계좌 추가시 건당  5,000원)
          </FlexLayout>

          <FlexLayout>
              <FlexLayout
                style={{ color: 'var(--color-blue)' }}
                justify="right"
              >
                {scope.state.acccnt}
              </FlexLayout>

              <FlexLayout style={{ color: 'var(--color-pink)' }}>
                {scope.state.accamt}
              </FlexLayout>

              <FlexLayout
                weight={1.25}
                style={{ color: 'var(--color-red)' }}
              >
               *탈퇴요청시 다음달 1일부터 탈퇴처리가 가능합니다
              </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>


      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'bank',
            text: '금융기관',
            width: 100,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.bank}
                textAlign="center"
                data={scope.state.banks?.map((y) => new ComboBoxModel(y.bankcd, y.banknm))}
                onSelect={(option) => {
                  rowUpdate({ bank: option.value, banknm: option.remark });
                  scope.backItemChanged(rowUpdate, x, 'bank', option.value);
                }}
                readonly={!x.isNew}
              />
            ),
          },
          {
            id: 'banknm',
            text: '금융기관명',
            width: 150,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.banknm}
                textAlign="center"
                onChange={(value) => rowUpdate({ banknm: value })}
              />
            ),
          },
          {
            id: 'bankcd5',
            text: '관리코드',
            width: 70,
          },
          {
            id: 'accnum',
            text: '계좌번호',
            width: 250,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.accnum}
                textAlign="center"
                onChange={(value) => rowUpdate({ accnum: value })}
              />
            ),
          },
          {
            id: 'ACCNAME',
            text: '계좌명칭',
            width: 150,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.ACCNAME}
                textAlign="center"
                onChange={(value) => rowUpdate({ ACCNAME: value })}
              />
            ),
          },
          {
            id: 'acccd',
            text: '계정',
            width: 150,
            render: (x, rowUpdate) => (
              <ComboBox
                textAlign="center"
                value={x.acccd}
                data={scope.state.acccds?.map((y) => new ComboBoxModel(y.acccd, y.accnm))}
                onSelect={(option) => rowUpdate({ acccd: option.value })}
              />
            ),
          },
          {
            id: 'bankflag',
            text: '구분',
            width: 70,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.bankflag}
                data={[
                  { value: '0', remark: '법인' },
                  { value: '1', remark: '개인' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => rowUpdate({ bankflag: option.value })}
              />
            ),
          },
          {
            id: 'spjangcd',
            text: '사업장',
            width: 170,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.spjangcd}
                data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
                onSelect={(option) => rowUpdate({ spjangcd: option.value })}
              />
            ),
          },
          {
            id: 'basic',
            text: '기본',
            width: 70,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.basic === '1'}
                onChange={(v) => rowUpdate({ basic: v ? '1' : '0' })}
              />
            ),
          },
          {
            id: 'giro',
            text: '지로',
            width: 70,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.giro === '1'}
                onChange={(v) => rowUpdate({ giro: v ? '1' : '0' })}
              />
            ),
          },
          {
            id: 'cms',
            text: 'CMS',
            width: 70,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.cms === '1'}
                onChange={(v) => rowUpdate({ cms: v ? '1' : '0' })}
              />
            ),
          },
          {
            id: 'useyn',
            text: '사용유무',
            width: 70,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.useyn === '1'}
                onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })}
              />
            ),
          },
          {
            id: 'clsflag', // 기능 추가 필요
            text: '연동',
            width: 80,
            render: (x) => (
              <FlexLayout
                align="center"
                justify="center"
              >
                <Button
                  isMini={true}
                  theme={x.clsflag ? BUTTON_THEMES.BLUE : BUTTON_THEMES.RED}
                >
                  {x.clsflag ? '연동하기' : '탈퇴요청'}
                </Button>
              </FlexLayout>
            ),
          },
          {
            id: 'signflag',
            text: '계좌연동확인',
            width: 100,
            render: (x) => (
              <FlexLayout
                align="center" // 상하 가운데 정렬
                justify="center" // 좌우 가운데 정렬
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  color: x.signflag === '1' ? 'var(--color-blue)' : x.signflag === '2' ? 'var(--color-red)' : null,
                }}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {x.signflag === '1' ? '성공' : x.signflag === '2' ? '실패' : null}
              </FlexLayout>
            ),
          },
          {
            id: 'bankda_message',
            text: '연동사유(결과)',
            width: 200,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.bankda_message}
                textAlign="left"
                onChange={(value) => rowUpdate({ bankda_message: value })}
              />
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.accountList}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
