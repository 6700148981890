import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_05
 * @category 급여일괄전송[출력/메일]
 */
export class PrepaymentBundleModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 출력
   *
   * **data type** varchar(1)
   */
  prtflag: string;

  /**
   * 메일전송
   *
   * **data type** varchar(1)
   */
  emailflag: string;

  /**
   * 월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 부서
   *
   * **data type** varchar(10)
   */
  divicd: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  spjangnm: string;

  /**
   * 입력일
   *
   * **data type** varchar(4)
   */
  indate: string;

  /**
   * 월간급여총액
   *
   * **data type** number
   */
  tot_samt: string;

  /**
   * 공제내역총액
   *
   * **data type** number
   */
  tot_tamt: string;

  /**
   * 실수령액
   *
   * **data type** number
   */
  tot_mamt: string;

  /**
   * 실수령액
   *
   * **data type** number
   */
  searchQuery: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 재직자구분
   *
   * **data type** varchar(3)
   */
  rtclafi: string;

  /**
   * 입사일
   *
   * **data type** varchar(8)
   */
  entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  rtdate: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 직책코드
   *
   * **data type** varchar(3)
   */
  rspcd: string;

  /**
   * 직책
   *
   * **data type** varchar(40)
   */
  rspnm: string;

  /**
   * 대표자여부
   *
   * **data type** varchar(1)
   */
  preyn: string;

  /**
   * 내근자여부
   *
   * **data type** varchar(1)
   */
  offiyn: string;

  /**
   * 성별
   *
   * **data type** varchar(1)
   */
  sex: string;

  /**
   * 메일주소
   *
   * **data type** varchar(30)
   */
  email: string;

  /**
   * 지급은행
   *
   * **data type** varchar(2)
   */
  bankcd: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(50)
   */
  accnum: string;

  /**
   * 비고
   *
   * **data type** varchar(20)
   */
  title: string;

  /**
   * 지급구분
   *
   * **data type** varchar(1)
   */
  mijflag: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  no: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  as_nm: string;

  /**
   * 입금은행2
   *
   * **data type** varchar(20)
   */
  banknm: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(10)
   */
  saupnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.saupnum = data.saupnum || '';
    this.pernm = data.pernm || '';
    this.rspcd = data.rspcd || '';
    this.banknm = data.banknm || '';
    this.rspnm = data.rspnm || '';
    this.mon = data.mon || '';
    this.tot_samt = data.tot_samt || '';
    this.tot_tamt = data.tot_tamt || '';
    this.tot_mamt = data.tot_mamt || '';
    this.spjangnm = data.spjangnm || '';
    this.seq = data.seq || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.divinm = data.divinm || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.preyn = data.preyn || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.offiyn = data.offiyn || '';
    this.sex = data.sex || '';
    this.email = data.email || '';
    this.bankcd = data.bankcd || '';
    this.accnum = data.accnum || '';
    this.title = data.title || '';
    this.chk = data.chk || '';
    this.prtflag = data.prtflag || '';
    this.emailflag = data.emailflag || '';
    this.mijflag = data.mijflag || '';
    this.rtclafi = data.rtclafi || '';
    this.searchQuery = data.searchQuery || '';
    this.as_nm = data.as_nm || '';
    this.no = data.no || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
