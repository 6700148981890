import * as React from 'react';
import {
  ComboBox, ComboBoxModel, FlexLayout, GridLayout, SearchLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BalGubuns, Gubuns, Order } from './Order';
import { OrderGridHeader } from './headers/OrderGridHeader';

/**
 * 화면
 * @window w_tb_e601w_sulchi_05
 * @category 설치발주현황
 */
export const OrderTemplate: React.FC<TemplateProps<Order>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout weight={2}>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => scope.setState({ spjangcd: option.value }, () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>설치구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={Gubuns.map((y) => new ComboBoxModel(y.key, y.value))}
            onSelect={(option) => scope.setState({ gubun: option.value }, () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>발주구분</span>
          <ComboBox
            value={scope.state.contflag}
            data={BalGubuns.map((y) => new ComboBoxModel(y.key, y.value))}
            onSelect={(option) => scope.setState({ contflag: option.value }, () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout weight={4}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 프로젝트로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          header={OrderGridHeader(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          data={scope.state.data}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
