import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca643
 * @category 가지급등록
 */

export class PaymentRegisterDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  snddate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  sndnum: string;

  /**
   * 지급사유
   *
   * **data type** varchar(23764)
   */
  remark: string;

  /**
   * 지급금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 잔액(환불액)
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 정산금액
   *
   * **data type** number
   */
  mamt: string;

  /**
   * 지급유형
   *
   * **data type** char(1)
   */
  amtflag: string;

  /**
   * 지급은행
   *
   * **data type** char(8)
   */
  bankcd: string;

  /**
   * 참고(비고)
   *
   * **data type** varchar(23764)
   */
  bigo: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.snddate = data.snddate || '';
    this.sndnum = data.sndnum || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.amtflag = data.amtflag || '';
    this.bankcd = data.bankcd || '';
    this.bigo = data.bigo || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
