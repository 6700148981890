import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */
export class AchievementStatusModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  readonly mon: string;

  /**
   * 영업건
   *
   * **data type** varchar(8)
   */
  readonly busi: string;

  /**
   * 견적제출
   *
   * **data type** varchar(8)
   */
  readonly busi_state0: string;

  /**
   * 미제출
   *
   * **data type** varchar(8)
   */
  readonly busi_state1: string;

  /**
   * 관급견적
   *
   * **data type** varchar(8)
   */
  readonly cost_cnt0: string;

  /**
   * 관급견적금액
   *
   * **data type** varchar(8)
   */
  readonly cost_amt0: string;

  /**
   * 사급견적
   *
   * **data type** varchar(8)
   */
  readonly cost_cnt1: string;

  /**
   * 사급견적금액
   *
   * **data type** varchar(8)
   */
  readonly cost_amt1: string;

  /**
   * 총견적
   *
   * **data type** varchar(8)
   */
  readonly cost_cnt: string;

  /**
   * 총견적금액
   *
   * **data type** varchar(8)
   */
  readonly cost_amt: string;

  /**
   * 관급계약
   *
   * **data type** varchar(8)
   */
  readonly comp_cnt0: string;

  /**
   * 관급계약 합
   *
   * **data type** varchar(8)
   */
  readonly comp_qty_cnt0_2: string;

  /**
   * 관급계약금액
   *
   * **data type** varchar(8)
   */
  readonly comp_amt0: string;

  /**
   * 사급계약
   *
   * **data type** varchar(8)
   */
  readonly comp_cnt1: string;

  /**
   * 사급계약 합
   *
   * **data type** varchar(8)
   */
  readonly comp_qty_cnt1_2: string;

  /**
   * 사급계약금액
   *
   * **data type** varchar(8)
   */
  readonly comp_amt1: string;

  /**
   * 총계약
   *
   * **data type** varchar(8)
   */
  readonly comp_cnt: string;

  /**
   * 총계약 합
   *
   * **data type** varchar(8)
   */
  readonly comp_qty_cnt_2: string;

  /**
   * 총계약금액
   *
   * **data type** varchar(8)
   */
  readonly comp_amt: string;

  /**
   * 계약자
   *
   * **data type** varchar(8)
   */
  readonly comp_perids: string;

  /**
   * 관급
   *
   * **data type** varchar(8)
   */
  readonly comp_yul0: string;

  /**
   * 사급
   *
   * **data type** varchar(8)
   */
  readonly comp_yul1: string;

  /**
   * 총대수
   *
   * **data type** varchar(8)
   */
  readonly comp: string;

  /**
   * 설치
   *
   * **data type** varchar(8)
   */
  readonly comp_gubun14: string;

  /**
   * 리모델링
   *
   * **data type** varchar(8)
   */
  readonly comp_gubun15: string;

  /**
   * 검사대수
   *
   * **data type** varchar(8)
   */
  readonly kcqty: string;

  /**
   * 매출금액
   *
   * **data type** varchar(8)
   */
  readonly misamt2: string;

  /**
   * 수금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt2: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly iamt2: string;

  /**
   * 관급실측
   *
   * **data type** varchar(8)
   */
  readonly sur_cnt0: string;

  /**
   * 관급실측[대수]
   *
   * **data type** varchar(8)
   */
  readonly sur_qty_cnt0: string;

  /**
   * 관습미실측
   *
   * **data type** varchar(8)
   */
  readonly sur_notcnt0: string;

  /**
   * 관급미실측[대수]
   *
   * **data type** varchar(8)
   */
  readonly sur_qty_notcnt0: string;

  /**
   * 사급실측
   *
   * **data type** varchar(8)
   */
  readonly sur_cnt1: string;

  /**
   * 사급실측[대수]
   *
   * **data type** varchar(8)
   */
  readonly sur_qty_cnt1: string;

  /**
   * 사급미실측
   *
   * **data type** varchar(8)
   */
  readonly sur_notcnt1: string;

  /**
   * 사급미실측[대수]
   *
   * **data type** varchar(8)
   */
  readonly sur_qty_notcnt1: string;

  /**
   * 총실측
   *
   * **data type** varchar(8)
   */
  readonly sur_cnt: string;

  /**
   * 총실측[대수]
   *
   * **data type** varchar(8)
   */
  readonly sur_qty_cnt: string;

  /**
   * 총미실측
   *
   * **data type** varchar(8)
   */
  readonly sur_notcnt: string;

  /**
   * 총미실측[대수]
   *
   * **data type** varchar(8)
   */
  readonly sur_qty_notcnt: string;

  /**
   * 관급설계
   *
   * **data type** varchar(8)
   */
  readonly des_cnt0: string;

  /**
   * 관급설계[대수]
   *
   * **data type** varchar(8)
   */
  readonly des_qty_cnt0: string;

  /**
   * 관습미설계
   *
   * **data type** varchar(8)
   */
  readonly des_notcnt0: string;

  /**
   * 관급미설계[대수]
   *
   * **data type** varchar(8)
   */
  readonly des_qty_notcnt0: string;

  /**
   * 사급설계
   *
   * **data type** varchar(8)
   */
  readonly des_cnt1: string;

  /**
   * 사급설계[대수]
   *
   * **data type** varchar(8)
   */
  readonly des_qty_cnt1: string;

  /**
   * 사급미설계
   *
   * **data type** varchar(8)
   */
  readonly des_notcnt1: string;

  /**
   * 사급미설계[대수]
   *
   * **data type** varchar(8)
   */
  readonly des_qty_notcnt1: string;

  /**
   * 총설계
   *
   * **data type** varchar(8)
   */
  readonly des_cnt: string;

  /**
   * 총설계[대수]
   *
   * **data type** varchar(8)
   */
  readonly des_qty_cnt: string;

  /**
   * 총미설계
   *
   * **data type** varchar(8)
   */
  readonly des_notcnt: string;

  /**
   * 총미설계[대수]
   *
   * **data type** varchar(8)
   */
  readonly des_qty_notcnt: string;

  /**
   * 관급착공
   *
   * **data type** varchar(8)
   */
  readonly bal_cnt0: string;

  /**
   * 관급착공[대수]
   *
   * **data type** varchar(8)
   */
  readonly bal_qty_cnt0: string;

  /**
   * 사급착공
   *
   * **data type** varchar(8)
   */
  readonly bal_cnt1: string;

  /**
   * 사급착공[대수]
   *
   * **data type** varchar(8)
   */
  readonly bal_qty_cnt1: string;

  /**
   * 총착공
   *
   * **data type** varchar(8)
   */
  readonly bal_cnt: string;

  /**
   * 총착공[대수]
   *
   * **data type** varchar(8)
   */
  readonly bal_qty_cnt: string;

  /**
   * 착공진행중
   *
   * **data type** varchar(8)
   */
  readonly bal_ingcnt: string;

  /**
   * 착공진행중[대수]
   *
   * **data type** varchar(8)
   */
  readonly bal_qty_ingcnt: string;

  /**
   * 미착공
   *
   * **data type** varchar(8)
   */
  readonly bal_notcnt: string;

  /**
   * 미착공[대수]
   *
   * **data type** varchar(8)
   */
  readonly bal_qty_notcnt: string;

  /**
   * 목표금액
   *
   * **data type** varchar(8)
   */
  readonly planamt: string;

  /**
   * 목표대비달성율
   *
   * **data type** varchar(8)
   */
  readonly plan_yul: string;

  /**
   * 실측자
   *
   */
  readonly sur_perids: string;

  /**
   * 설계자
   *
   */
  readonly des_perids: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.mon = data.mon || '';
    this.busi = data.busi || '';
    this.busi_state0 = data.busi_state0 || '';
    this.busi_state1 = data.busi_state1 || '';
    this.cost_cnt0 = data.cost_cnt0 || '';
    this.cost_amt0 = data.cost_amt0 || '';
    this.cost_cnt1 = data.cost_cnt1 || '';
    this.comp_amt1 = data.comp_amt1 || '';
    this.cost_cnt = data.cost_cnt || '';
    this.cost_amt = data.cost_amt || '';
    this.comp_cnt0 = data.comp_cnt0 || '';
    this.comp_qty_cnt0_2 = data.comp_qty_cnt0_2;
    this.comp_amt0 = data.comp_amt0 || '';
    this.comp_cnt1 = data.comp_cnt1 || '';
    this.comp_qty_cnt1_2 = data.comp_qty_cnt1_2 || '';
    this.cost_amt1 = data.cost_amt1 || '';
    this.comp_cnt = data.comp_cnt || '';
    this.comp_qty_cnt_2 = data.comp_qty_cnt_2 || '';
    this.comp_amt = data.comp_amt || '';
    this.comp_perids = data.comp_perids || '';
    this.comp_yul0 = data.comp_yul0 || '';
    this.comp_yul1 = data.comp_yul1 || '';
    this.comp = data.comp || '';
    this.comp_gubun14 = data.comp_gubun14 || '';
    this.comp_gubun15 = data.comp_gubun15 || '';
    this.kcqty = data.kcqty || '';
    this.misamt2 = data.misamt2 || '';
    this.rcvamt2 = data.rcvamt2 || '';
    this.iamt2 = data.iamt2 || '';
    this.sur_cnt0 = data.sur_cnt0 || '';
    this.sur_qty_cnt0 = data.sur_qty_cnt0 || '';
    this.sur_notcnt0 = data.sur_notcnt0 || '';
    this.sur_qty_notcnt0 = data.sur_qty_notcnt0 || '';
    this.sur_cnt1 = data.sur_cnt1 || '';
    this.sur_qty_cnt1 = data.sur_qty_cnt1 || '';
    this.sur_notcnt1 = data.sur_notcnt1 || '';
    this.sur_qty_notcnt1 = data.sur_qty_notcnt1 || '';
    this.sur_cnt = data.sur_cnt || '';
    this.sur_qty_cnt = data.sur_qty_cnt || '';
    this.sur_notcnt = data.sur_notcnt || '';
    this.sur_qty_notcnt = data.sur_qty_notcnt || '';
    this.des_cnt0 = data.des_cnt0 || '';
    this.des_qty_cnt0 = data.des_qty_cnt0 || '';
    this.des_notcnt0 = data.des_notcnt0 || '';
    this.des_qty_notcnt0 = data.des_qty_notcnt0 || '';
    this.des_cnt1 = data.des_cnt1 || '';
    this.des_qty_cnt1 = data.des_qty_cnt1 || '';
    this.des_notcnt1 = data.des_notcnt1 || '';
    this.des_qty_notcnt1 = data.des_qty_notcnt1 || '';
    this.des_cnt = data.des_cnt || '';
    this.des_qty_cnt = data.des_qty_cnt || '';
    this.des_notcnt = data.des_notcnt || '';
    this.des_qty_notcnt = data.des_qty_notcnt || '';
    this.bal_cnt0 = data.bal_cnt0 || '';
    this.bal_qty_cnt0 = data.bal_qty_cnt0 || '';
    this.bal_cnt1 = data.bal_cnt1 || '';
    this.bal_qty_cnt1 = data.bal_qty_cnt1 || '';
    this.bal_cnt = data.bal_cnt || '';
    this.bal_qty_cnt = data.bal_qty_cnt || '';
    this.bal_ingcnt = data.bal_ingcnt || '';
    this.bal_qty_ingcnt = data.bal_qty_ingcnt || '';
    this.bal_notcnt = data.bal_notcnt || '';
    this.bal_qty_notcnt = data.bal_qty_notcnt || '';
    this.planamt = data.planamt || '';
    this.plan_yul = data.plan_yul || '';
    this.sur_perids = data.sur_perids || '';
    this.des_perids = data.des_perids || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}

