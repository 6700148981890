import * as React from 'react';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  GridLayout,
  LabelBox,
  LayoutTitle,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { CostClause } from './CostClause';

// @ts-ignore
/**
 * 화면
 * @window w_tb_ca647
 * @category 비용항목등록
 */
export const CostClauseTemplate: React.FC<TemplateProps<CostClause>> = ({
  scope,
  update,
  // @ts-ignore
  modelUpdate,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout weight={0.4}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="그룹으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => setData({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'gartcd',
            text: '코드',
            width: 30,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {Format.make(x.gartcd, '-', 4)}
              </FlexLayout>
            ),
          },
          {
            id: 'gartnm',
            text: '그룹명',
            width: 70,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {x.gartnm}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.costClauseList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
      />

      <FlexLayout isVertical={true} weight={3}>
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="그룹코드" isNeed={true} size={200}>
            <TextBox
              textAlign="center"
              value={model.gartcd}
              onChange={(value) => modelUpdate({ gartcd: value })}
              readonly={true}
            />
          </LabelBox>
          <LabelBox title="그룹명" size={200}>
            <TextBox
              value={model.gartnm}
              onChange={(value) => modelUpdate({ gartnm: value, isChanged: 'true' })}
            />
          </LabelBox>
          <LabelBox title="비고">
            <TextBox
              value={model.remark}
              onChange={(value) => modelUpdate({ remark: value, isChanged: 'true' })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
          <FlexLayout justify="left" weight={1}>
            <LayoutTitle size={270}>비용항목</LayoutTitle>
          </FlexLayout>
          <FlexLayout justify="right" weight={3}>
            <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.dw_2_new()}>추가</Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.dw_2_delete()}
            >
              삭제
            </Button>
          </FlexLayout>
        </FlexLayout>

        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'artcd',
              text: '코드',
              width: 8,
              render: (x) => (
                <FlexLayout justify="center" align="center">
                  {x.artcd}
                </FlexLayout>
              ),
            },
            {
              id: 'artnm',
              text: '매입항목',
              width: 14,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  ref={refs}
                  value={x.artnm}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ artnm: value })}
                />
              ),
            },
            {
              id: 'jflag',
              text: '고정비',
              width: 9,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.jflag}
                  style={{
                    padding: '0 0 0 6px',
                    fontSize: 12,
                  }}
                  data={scope.state.jflags?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                  onSelect={(option) => rowUpdate({ jflag: option.value })}
                />
              ),
            },
            {
              id: 'gflag',
              text: '비용분류',
              width: 11,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.gflag}
                  style={{
                    padding: '0 0 0 6px',
                    fontSize: 12,
                  }}
                  data={scope.state.gflags?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                  onSelect={(option) => rowUpdate({ gflag: option.value })}
                />
              ),
            },
            {
              id: 'acccd',
              text: '비용계정',
              color: 'var(--color-red)',
              width: 9,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  ref={refs}
                  value={x.acccd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ acccd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_AC001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        acccd: item.cd,
                        accnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'accnm',
              text: '비용계정명',
              width: 16,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.accnm}
                </FlexLayout>
              ),
            },
            {
              id: 'acccd2',
              text: '원가계정',
              color: 'var(--color-red)',
              width: 9,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  ref={refs}
                  value={x.acccd2}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ acccd2: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_AC001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        acccd2: item.cd,
                        accnm2: item.cdnm,
                      });
                    },
                  )}>
                </TextBox>
              ),
            },
            {
              id: 'accnm2',
              text: '원가계정명',
              width: 16,
              render: (x) => (
                <FlexLayout justify="left" align="center">
                  {x.accnm2}
                </FlexLayout>
              ),
            },
            {
              id: 'wkgubun',
              text: '매입구분',
              width: 12,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.wkgubun}
                  data={scope.state.wkgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                  onSelect={(option) => rowUpdate({ wkgubun: option.value })}
                >
                </ComboBox>
              ),
            },
            {
              id: 'useyn',
              text: '사용유무',
              width: 8,
              render: (x, rowUpdate) => (
                <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })}/>
              ),
            },
          ]}
          data={scope.state.costClauseDetailList}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
