import {
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import { WaitQueueCallback } from '../constants';
import {
  ModalStore,
  ToolStore,
} from './index';

interface WaitQueueItem {
  title: string;
  key: string; // MQTT message for check on finish
  intime: Date;
  runningTimes: string;
  isDone?: boolean;
  response?: WaitQueueResponse;
  callback: WaitQueueCallback;
  retry?: Function;
}

interface WaitQueueResponse {
  key: string;
  response: any;
}

interface IWaitQueueStore {
  data: WaitQueueItem[];
  toolStore?: ToolStore;
}

/**
 * MQTT를 통한 오래걸리는 작업 대기용 스토어
 * Topic: /elman/web/<custcd>/<perid>/wait
 */
export default class WaitQueueStore implements IWaitQueueStore {
  @observable data: WaitQueueItem[];

  toolStore?: ToolStore;

  modalStore?: ModalStore;

  constructor() {
    this.data = [];

    makeObservable(this);
  }

  setToolStore(toolStore: ToolStore) {
    this.toolStore = toolStore;
  }

  setModalStore(modalStore: ModalStore) {
    this.modalStore = modalStore;
  }

  @action
  public append(
    title: string,
    key: string,
    callback: WaitQueueCallback,
    retry?: Function,
  ) {
    this.data = [
      {
        title,
        key,
        callback,
        retry,
        intime: new Date(),
        runningTimes: '-',
      },
      ...this.data,
    ];
  }

  @action
  updateToolBadge() {
    this.toolStore?.setBadgeText('WAIT', (this.data.filter((x) => x.isDone).length || '').toString());
  }

  @action
  public removeDoneItems() {
    this.data = this.data.filter((x) => !x.isDone);
    this.updateToolBadge();
  }

  @action
  public removeAll() {
    this.data = [];
    this.updateToolBadge();
  }

  @action
  public onEvent(data: WaitQueueResponse) {
    const item = this.data.filter((x) => x.key === data.key);
    if (item.length) {
      const change = {
        ...item[0],
        response: data.response,
        isDone: true,
      };

      this.data = [
        ...this.data.filter((x) => !x.isDone && x.key !== data.key),
        change,
        ...this.data.filter((x) => x.isDone && x.key !== data.key),
      ];

      this.modalStore?.gridWaitQueue?.current?.forceRepaint(true);
      this.updateToolBadge();
    }
  }

  @action
  public doAction(item: WaitQueueItem) {
    if (!item.isDone) return;
    item.callback(item.response);
    this.data = this.data.filter((x) => x.key !== item.key);
    this.updateToolBadge();
  }

  @action
  public doRetry(item: WaitQueueItem) {
    if (!item.retry) return;
    this.data = this.data.filter((x) => x.key !== item.key);
    item.retry();
    this.updateToolBadge();
  }

  @action
  public tick() {
    if (this.data.length === 0) return;

    const items = this.data.filter((x) => !x.isDone);
    if (items.length === 0) return;

    const nexts: WaitQueueItem[] = [];
    const now = new Date();

    items.forEach((x) => nexts.push({
      ...x,
      runningTimes: `${Math.round((now.getTime() - x.intime.getTime()) / 1000.0)}초`,
    }));

    this.data = [
      ...nexts,
      ...this.data.filter((x) => x.isDone),
    ];
  }

  @computed
  public get queue(): WaitQueueItem[] {
    return this.data;
  }
}
