import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { MonthSale } from './MonthSale';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_da052w_02
 * @category 월매출실적현황
 */
export const MonthSaleTemplate: React.FC<TemplateProps<MonthSale>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => setData({ year: value })}
          onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                setData({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                setData({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={250}>
        <span>구분</span>
        <ComboBox
          value={scope.state.searchGubun}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.gubun?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
          onSelect={(option) => setData({ searchGubun: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={250}>
        <span>직영구분</span>
        <ComboBox
          value={scope.state.mpclafi}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '직영' },
            { value: '1', remark: '상주' },
          ].map((y) => new ComboBoxModel(y.value, y.remark))}
          onSelect={(option) => setData({ mpclafi: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          textAlign="center"
          value={scope.state.divicd}
          onChange={(value) => setData({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              setData({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={1.5}
          value={scope.state.divinm}
          readonly={true}
          onChange={(value) => setData({ divinm: value })}
        />
      </FlexLayout>

      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>사용자</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              setData({
                perid: item.cd,
                pernm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          value={scope.state.pernm}
          weight={1.5}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <GridLayoutAdditionalHeader
        ref={scope.additionalTitle}
        height={Global.LAYOUT_TITLE_HEIGHT_1}
      >
        <FlexLayout
          justify="center"
          align="center"
          size={470}
        >
          {scope.state.year}년 유지보수
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          size={498}
        >
          월목표
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
          size={800}
        >
          년목표
        </FlexLayout>
      </GridLayoutAdditionalHeader>

      <FlexLayout onDoubleClick={() => scope.onClickOpenBalEnrollment()}>
        <GridLayout
          ref={scope.grid}
          headerHeight={55}
          header={[
            {
              id: 'divinm',
              text: '부서별그룹',
              isHidden: true,
              group: true,
              value: (x) => x.divinm,
            },
            {
              id: 'divinm',
              text: '부서명',
              width: 6,
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '성명',
              width: 8,
            },
            {
              id: 'ordmontot',
              text: '합계',
              width: 7,
              sum: (x, data) => {
                const sumOrdmontot = Sum.byGroup(data, 'ordmontot', x, ['divinm']);
                const sumMontot = Sum.byGroup(data, 'montot', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmontot)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMontot)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmontot_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.montot_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmontot)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.montot)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmonyul',
              text: '달성율',
              width: 7,
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {x.ordmonyul}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {x.monyul}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon01',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    01월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon01 = Sum.byGroup(data, 'ordmon01', x, ['divinm']);
                const sumMon01 = Sum.byGroup(data, 'mon01', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon01)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon01)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon01_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon01_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon01)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon01)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon02',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    02월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon02 = Sum.byGroup(data, 'ordmon02', x, ['divinm']);
                const sumMon02 = Sum.byGroup(data, 'mon02', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon02)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon02)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon02_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon02_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon02)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon02)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon03',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    03월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon03 = Sum.byGroup(data, 'ordmon03', x, ['divinm']);
                const sumMon03 = Sum.byGroup(data, 'mon03', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon03)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon03)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon03_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon03_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon03)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon03)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon04',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    04월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon04 = Sum.byGroup(data, 'ordmon04', x, ['divinm']);
                const sumMon04 = Sum.byGroup(data, 'mon04', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon04)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon04)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon04_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon04_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon04)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon04)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon05',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    05월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon05 = Sum.byGroup(data, 'ordmon05', x, ['divinm']);
                const sumMon05 = Sum.byGroup(data, 'mon05', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon05)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon05)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon05_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon05_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon05)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon05)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon06',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    06월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon06 = Sum.byGroup(data, 'ordmon06', x, ['divinm']);
                const sumMon06 = Sum.byGroup(data, 'mon06', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon06)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon06)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon06_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon06_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon06)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon06)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon07',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    07월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon07 = Sum.byGroup(data, 'ordmon07', x, ['divinm']);
                const sumMon07 = Sum.byGroup(data, 'mon07', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon07)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon07)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon07_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon07_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon07)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon07)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon08',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    08월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon08 = Sum.byGroup(data, 'ordmon08', x, ['divinm']);
                const sumMon08 = Sum.byGroup(data, 'mon08', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon08)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon08)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon08_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon08_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon08)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon08)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon09',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    09월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon09 = Sum.byGroup(data, 'ordmon09', x, ['divinm']);
                const sumMon09 = Sum.byGroup(data, 'mon09', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon09)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon09)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon09_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon09_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon09)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon09)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon10',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    10월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon10 = Sum.byGroup(data, 'ordmon10', x, ['divinm']);
                const sumMon10 = Sum.byGroup(data, 'mon10', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon10)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon10)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon10_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon10_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon10)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon10)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon11',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    11월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon11 = Sum.byGroup(data, 'ordmon11', x, ['divinm']);
                const sumMon11 = Sum.byGroup(data, 'mon11', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon11)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon11)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon11_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon11_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon11)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon11)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ordmon12',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    12월
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    매출예상금액
                  </FlexLayout>

                  <FlexLayout align="end">
                    매출발행금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumOrdmon12 = Sum.byGroup(data, 'ordmon12', x, ['divinm']);
                const sumMon12 = Sum.byGroup(data, 'mon12', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumOrdmon12)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMon12)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.ordmon12_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon12_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordmon12)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon12)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          rowHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
