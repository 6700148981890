import * as React from 'react';
import { observer } from 'mobx-react';
import { FiUpload } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/all';
import style from '../AuthorizedScreen.module.scss';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { TextBox } from '../../components/forms/TextBox';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { LabelBox } from '../../components/forms/LabelBox';
import { GridLayout } from '../../components/layout/GridLayout';
import { ConfirmWarning } from '../../utils/confirm';
import { LoaderButton } from '../../components/forms/LoaderButton';
import { Format } from '../../utils/string';
import { OptionBox } from '../../components/forms/OptionBox';
import {
  ComboBox,
  ComboBoxModel,
} from '../../components';

interface ModalEmailProps {
  modalStore: ModalStore;
}

@observer
export class ModalEmail extends React.Component<ModalEmailProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1024}
        height={900}
        isVisible={modalStore.isVisibleEmail}
        onClose={() => modalStore.closeEmail()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>이메일 전송</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="보내는이 주소"
              isNeed={true}
            >
              <TextBox
                value={modalStore.emailFrom}
                onChange={(value) => { modalStore.emailFrom = value; }}
              />
            </LabelBox>

            <LabelBox
              title="보내는이 이름"
              isNeed={true}
            >
              <TextBox
                value={modalStore.emailFromName}
                onChange={(value) => { modalStore.emailFromName = value; }}
              />
            </LabelBox>

            <LabelBox title="구분">
              <OptionBox
                value={modalStore.emailFromAutomation}
                data={[
                  { value: '0', remark: '회사' },
                  { value: '1', remark: '개인' },
                ]}
                onChange={(item) => { modalStore.selectEmailAutomation(item.value === '0'); }}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="받는이 주소"
              weight={2}
              isNeed={true}
            >
              <ComboBox
                value={modalStore.emailTo}
                data={modalStore.emailSuggestion.map((y) => new ComboBoxModel(y.email, `${y.emailtitle}${y.toname}`))}
                onSelect={(option) => modalStore.emailComboSelected(option)}
                onChangeSearchQuery={(value) => modalStore.emailQuickSearch(value)}
              />
            </LabelBox>

            <LabelBox
              title="받는이 이름"
              isNeed={true}
            >
              <TextBox
                value={modalStore.emailToName}
                onChange={(value) => { modalStore.emailToName = value; }}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="참조자 주소" weight={2}>
              <TextBox
                value={modalStore.emailCC}
                onChange={(value) => { modalStore.emailCC = value; }}
              />
            </LabelBox>

            <LabelBox title="카톡전송전화번호">
              <TextBox
                value={modalStore.emailKakaoTelnum}
                onChange={(value) => { modalStore.emailKakaoTelnum = value; }}
              />
            </LabelBox>
          </FlexLayout>

          <LabelBox
            title="제목"
            size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}
            isNeed={true}
          >
            <TextBox
              value={modalStore.emailTitle}
              onChange={(value) => { modalStore.emailTitle = value; }}
            />
          </LabelBox>

          <LabelBox
            title="내용"
            size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
            isNeed={true}
          >
            <TextBox
              value={modalStore.emailRemark}
              onChange={(value) => { modalStore.emailRemark = value; }}
              isMultiline={true}
            />
          </LabelBox>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <LayoutTitle weight={1}>
              이메일 첨부
            </LayoutTitle>
            <LoaderButton
              onClick={() => modalStore.emailAddAttachment()}
              percent={modalStore.emailPercentAttachment}
            >
              <FiUpload />
              <span>내 PC</span>
            </LoaderButton>
          </FlexLayout>

          <GridLayout
            header={[
              {
                id: 'delete',
                text: '삭제',
                width: 10,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    <Button
                      isIcon={true}
                      onClick={() => modalStore.emailAttachmentRemove(x)}
                    >
                      <IoMdTrash size={19} />
                    </Button>
                  </FlexLayout>
                ),
              }, {
                id: 'filename',
                text: '파일명',
                width: 60,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.filename.startsWith('.') ? `이름없음${x.filename}` : x.filename}
                  </FlexLayout>
                ),
              }, {
                id: 'extension',
                text: '확장자',
                width: 10,
              }, {
                id: 'size',
                text: '파일크기',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="end" align="center">
                    {Format.number(x.size)}B&nbsp;
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore.emailAttachments || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeEmail()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (ConfirmWarning.assert(modalStore.emailFrom, '경고', '보내는이의 이메일 주소를 입력해주세요.')
                  && ConfirmWarning.assert(modalStore.emailFromName, '경고', '보내는이의 이름을 입력해주세요.')
                  && ConfirmWarning.assert(modalStore.emailTo, '경고', '받는이의 이메일 주소를 입력해주세요.')
                  && ConfirmWarning.assert(modalStore.emailToName, '경고', '받는이의 이름을 입력해주세요.')
                  && ConfirmWarning.assert(modalStore.emailTitle, '경고', '이메일 제목을 입력해주세요.')
                  && ConfirmWarning.assert(modalStore.emailRemark, '경고', '본문을 입력해주세요.')
                ) {
                  modalStore.sendEmail();
                }
              }}
            >
              전송
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
