import { action } from 'mobx';
import { ModalStore } from '../../stores';
import { ProjectSelectorItem } from '../../stores/ModalStore';
import { ISearchBinding } from './SearchBinding';

export default class SearchBindingProjects implements ISearchBinding {
  readonly modalStore: ModalStore;

  readonly params: any;

  readonly immediatlySearch: boolean;

  readonly onSearch: (item: ProjectSelectorItem) => void;

  readonly functionName: string;

  readonly isInstall: boolean;

  constructor(
    modalStore: ModalStore,
    params: any,
    immediatlySearch: boolean,
    onSearch: (item: ProjectSelectorItem) => void,
    isInstall?: boolean,
  ) {
    this.modalStore = modalStore;
    this.params = params;
    this.immediatlySearch = immediatlySearch;
    this.onSearch = onSearch;
    this.functionName = '';
    this.isInstall = isInstall || false;
  }

  @action
  open(params?: any) {
    const args = {
      ...this.params,
      ...params,
    };

    this.modalStore.openProjectSelector(args, this.immediatlySearch, this.isInstall)
      .then((item) => {
        this.onSearch(item);
      });
  }
}
