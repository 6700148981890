import { computed } from 'mobx';

/**
 * 무상관리현장모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class FreeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 현장코드
   *
   * **data type** char(15)
   */
  actcd: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 설치->보수일
   *
   * **data type** char(8)
   */
  senddate: string;

  /**
   * 시작일
   *
   * **data type** char(8)
   */
  stdate: string;

  /**
   * 종료일
   *
   * **data type** char(8)
   */
  enddate: string;

  /**
   * 보수료
   *
   * **data type** number
   */
  amt: string;

  /**
   * 계약분류
   *
   * **data type** char(2)
   */
  contg: string;

  /**
   * 계약구분
   *
   * **data type** char(1)
   */
  contgubun: string;

  /**
   * 유무상
   *
   * **data type** char(1)
   */
  mgubun: string;

  /**
   * 현장담당자
   *
   * **data type** char(20)
   */
  actpernm: string;

  /**
   * 담당자전화번호
   *
   * **data type** char(20)
   */
  hp: string;

  /**
   * 주소
   *
   * **data type** char(100)
   */
  address: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.actcd = data.actcd || '';
    this.qty = data.qty || '';
    this.mgubun = data.mgubun || '';
    this.senddate = data.senddate || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.amt = data.amt || '';
    this.contg = data.contg || '';
    this.contgubun = data.contgubun || '';
    this.mgubun = data.mgubun || '';
    this.actpernm = data.actpernm || '';
    this.hp = data.hp || '';
    this.address = data.address || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
