
import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { IssueTemplate } from './Issue.template';
import { IssueModel } from './Issue.model';
import { TableLayout } from '../../../../components';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models/common';
import { Confirm, ConfirmDelete, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface IssueState {
  searchQuery: string;
  flag: string;
  num: string;
  perid: string;
  seal: string;
  bu: string;
  data: Array<IssueModel>;
  focused?: IssueModel;
  apppercds: Array<any>;
  appgubuns: Array<any>;
  pernmData?:string;
  copyDetailModal: boolean; // 문서복사 모달
  isCopy: boolean; // 문서복사 여부
  isReported: boolean; // 결재상신 여부
}

/**
 * 컨트롤러
 * @window w_pa100
 * @category 제증명발행
 */
export class Issue extends PageComponent<PageProps, IssueState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: Today.year(),
      flag: '',
      num: '',
      perid: '',
      seal: '',
      data: [],
      bu: '',
    };
  }

  @action
  async onItemChange(name: any, value: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_itemchanged', {
      itemname: name,
      data: value === true ? '1' : '0',
      year: this.state.focused?.year,
      num: this.state.focused?.num,
      perid: this.state.focused?.perid,
    });

    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      if (x.perid === this.state.focused?.perid) {
        this.updatedRows?.push({
          ...x,
          tot_samt: data?.tot_samt,
        });
        checkData.push({
          ...x,
        });
      } else {
        checkData.push({
          ...x,
        });
      }
    });
    this.setState({
      data: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  async onMessageEvent(_: any, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json && json?.key) {
      switch (json.key) {
        case 'ALERT-ANSWER':
          if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
            break;
          }

          data = await api.fxExec(
            'delete_appok',
            {
              ...this.state.focused,
              appgubun: this.state.focused?.appgubun,
              flag: this.state.focused?.flag,
              pernm: this.state.focused?.pernm,
            }, false,
          );

          data && this.setState({
            data: new IssueModel({
              ...this.state.data,
              ...data,
            }, false),
            ...data,
          });
          await this.onRetrieveEvent();
          break;

        default:
        //
      }
    }
  }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `호수: ${this.state.focused?.num}, 성명: ${this.state.focused?.pernm}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.exec(Category.MATERIAL, 'wb_copy', {
      ...this.state.focused,
      bu: this.state.bu,
    });

    if (data) {
      const newModel = new IssueModel(data, true);
      const buObject = [...data.items.map((x: any) => (new IssueModel(x)))];

      this.setState({
        focused: newModel,
        data: [...buObject, ...this.state.data],
        isCopy: true,
      }, async () => {
        await this.table.current?.update(false);
      });
      this.table.current?.setFocus(0);
      await this.copyModal(false);
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    const data = await api.dropdown('wf_dd_ca510_621_01');

    if (!data) return;
    // 쓸모없어보임
    // this.setState({ appgubuns: data.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      { year: this.state.searchQuery },
      (params) => (
        api.retrieve(params)
      ),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new IssueModel(x))],
        }, next);
      },
      async () => {
        await this.SS({ data: [] });
        await this.table.current?.update(true);
        await this.infinity?.retrieveAll();
        this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.focused;
    await this.SS({
      data: [],
    });

    const index = await this.infinity?.retrieveTo(['custcd', 'spjangcd', 'flag', 'year', 'num', 'perid'],
      [lastSelected?.custcd, lastSelected?.spjangcd, lastSelected?.flag, lastSelected?.year, lastSelected?.num, lastSelected?.perid], type, true) || 0;

    this.table.current?.update(true);
    this.state.data && this.state.data.length > index && this.table.current?.setFocus(index, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({ year: this.state.searchQuery });

    data && this.setState({
      data: [new IssueModel({
        ...data,
        year: this.state.searchQuery,
      }, true), ...this.state.data],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, this.state.focused?.new === '1')) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `호수 :${this.state.focused?.num} 성명: ${this.state.focused?.pernm}`;
    if (await ConfirmDelete.show(text)) {
      await api.fxExec(
        'delete',
        {
          ...this.state.focused,
          appgubun: this.state.focused?.appgubun,
          flag: this.state.focused?.flag,
          pernm: this.state.focused?.pernm,
        }, true,
      );
      await this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    // const result: AskType = await Confirm.ask('직인출력확인', '직인을 찍어 출력하시겠습니까?', 'Yes', 'No');
    // switch (result) {
    //   case AskType.YES:
    //     this.setState({ seal: '1' });
    //     // 수락
    //     break;
    //   case AskType.NO:
    //     this.setState({ seal: '0' });
    //     // 거절
    //     break;
    //   default:
    //   // 취소됨
    // }
    await api.printWithElmanManager({
      year: this.state.searchQuery,
      flag: this.state.focused?.flag,
      appgubun: this.state.focused?.appgubun,
      appnum: this.state.focused?.appnum,
      num: this.state.focused?.num,
      perid: this.state.focused?.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      year: this.state.searchQuery,
    });
  }

  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 팝업
    if (appflag === 'ok') {
      await modalStore.openApprovalLine(PAPERCD.OWNAUTH);
    }

    // 결재상신
    await api.exec(Category.MATERIAL, 'wb_appreport', {
      papercd: PAPERCD.OWNAUTH.toString(),
      ...this.state.data,
      ...this.state.focused,
      year: this.state.searchQuery,
      num: this.state.focused?.num,
      appnum: this.state.focused?.appnum,
      appgubun: this.state.focused?.appgubun,
      reqdt: this.state.focused?.reqdt,
      flag: this.state.focused?.flag,
      perid: this.state.focused?.perid,
      pernm: this.state.focused?.pernm,
      appflag,
    });

    await this.onRetrieveEvent();
  }

  @action
  copyModal(isOpen: boolean) {
    this.setState({ copyDetailModal: isOpen });
  }

  @action
  onRowFocusEvent(item: IssueModel) {
    this.setState({ focused: new IssueModel(item, item?.isNew) }, () => {
      // 결재상신 여부확인
      if (this.state.focused?.appgubun === null
        || this.state.focused?.appgubun === undefined
        || this.state.focused?.appgubun === ''
        || this.state.focused?.appgubun === '131') {
        this.setState({ isReported: false });
      } else {
        this.setState({ isReported: true });
      }
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<IssueModel>, updatedRows: Array<IssueModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <IssueTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
