import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  CheckBox,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  MonthSales,
  MonthSalesTabDataStateNames,
} from './MonthSales';
import {
  Global,
  TemplateProps,
} from '../../../../constants';

/**
 * 화면
 * @window w_tb_e050w_05
 * @category 월별매출현황
 */
export const MonthSalesTemplate: React.FC<TemplateProps<MonthSales>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>년도</span>
              <DateTextBox
                value={scope.state.year}
                textAlign="center"
                format="####"
                onChange={(value) => setData({ year: value })}
                onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      setData({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      setData({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={400}>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder={scope.state.holderName}
                value={scope.state.searchQuery}
                onChange={(value) => scope.setState({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'cnam',
                text: '매출구분',
                width: 80,
              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            data={scope.state.openChk}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>

        <FlexLayout weight={6}>
          <FlexLayout isVertical={true}>
            <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
              <TabView
                tabs={scope.tabs}
                focused={scope.state.focusedTab}
                onChange={(tab) => scope.onTabChange(tab)}
              />
            </FlexLayout>
            <FlexLayout>
              <GridLayout
                header={scope.tabHeaders[scope.tabIndex]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowClick={(item) => scope.onRowFocusEvent(item)}
                infinityHandler={scope.infinity}
                scope={scope}
                ref={scope.grid}
                // @ts-ignore
                data={scope.state[MonthSalesTabDataStateNames[scope.tabIndex]] || [] }
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
