import { TableLayoutHeader } from '../../../../../constants';
import { CarModel } from '../Car.model';
import { Car } from '../Car';
import { ComboBox, ComboBoxModel } from '../../../../../components/forms/ComboBox';
import { DateTextBox } from '../../../../../components/forms/DateTextBox';
import { TextBox } from '../../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../../models/common';

// 회사소유
const Company: TableLayoutHeader<CarModel, Car>[] = [
  {
    id: 'seq',
    text: '순번',
    width: 7,
  },
  {
    id: 'perid',
    text: '코드',
    width: 10,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.perid}
        textAlign="center"
        onChange={(value) => rowUpdate({ perid: value })}
        bindSearchModal={new SearchBinding(
          // @ts-ignore
          scope.props.modalStore,
          'TB_JA001',
          {},
          true,
          (item) => {
            rowUpdate({
              perid: item.cd,
              pernm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'pernm',
    text: '이름(사용자)',
    width: 15,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pernm}
        onChange={(value) => rowUpdate({ pernm: value })}
      />
    ),
  },
  {
    id: 'carpernm',
    text: '차량소유주',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pernm}
        onChange={(value) => rowUpdate({ pernm: value })}
      />
    ),
  },
  {
    id: 'carnum',
    text: '차량번호',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.carnum}
        onChange={(value) => rowUpdate({ carnum: value })}
      />
    ),
  },
  {
    id: 'carname',
    text: '차명',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.carname}
        onChange={(value) => rowUpdate({ carname: value })}
      />
    ),
  },
  {
    id: 'kmflag',
    text: '유종',
    width: 10,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.kmflag || ''}
        style={{
          padding: '0 0 0 6px',
          fontSize: 12,
        }}
        data={[
          { value: '001', remark: '휘발유' },
          { value: '002', remark: '경유' },
          { value: '003', remark: 'LPG' },
          { value: '004', remark: '전기' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ kmflag: option.value })}
      />
    ),
  },
  {
    id: 'cardate',
    text: '차량등록일',
    width: 10,
    render: (x, rowUpdate, _, scope) => (
      <DateTextBox
        value={x.cardate || '19700101'}
        textAlign="center"
        format="####-##-##"
        onChange={(value) => rowUpdate({ cardate: value })}
        onEnter={() => scope?.onRetrieveEvent()}
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 45,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
        textAlign='left'
      />
    ),
  },
];
export default Company;
