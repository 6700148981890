import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { ExpenditureTemplate } from './Expenditure.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  ExpenditureDetailModel,
  ExpenditureModel,
  JournalDetailPopupModel,
  JournalPopupModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { PageComponent } from '../../../../utils/layout';

export enum ExpenditureItemChangeTypes {
  INPUTDATE,
  SAMT,
  TAMT,
  AMOUNT,
}

export enum ExpenditureItemChangeTypeNames {
  inputdate,
  samt,
  tamt,
  amount,
}

interface ExpenditureState {

  // 검색 조건
  searchQuery: string;
  stdate: string;
  enddate: string;
  perid: string;
  pernm: string;
  peridState: string;
  as_nm: string;
  kukcd: string;

  inputdate: string;
  wkcds: Array<any>; // 업무구분 리스트

  // 데이터 객체
  focusedExpenditure?: ExpenditureModel;
  focusedExpenditureDetail?: ExpenditureDetailModel;
  data: ExpenditureModel;
  lastNewData: ExpenditureModel;
  expenditureList: Array<ExpenditureModel>;
  expenditureDetailList: Array<ExpenditureDetailModel>;
  popupList: Array<JournalPopupModel>;
  popupDetailList: Array<JournalDetailPopupModel>;
  popupFocused?: JournalPopupModel;
  popupDetailFocused?: JournalDetailPopupModel;

  // 모달
  fileDetailModal: boolean; // 첨부파일
  journalModal: boolean; // 일지 KM 불러오기

  isReported: boolean; // 결재상신 여부
  isCopy: boolean;

  focusIndex: number;
  chk: string;
  new: string;

  samt_tot: string;
  tamt_tot: string;
  amount_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ae200
 * @category 지출결의서
 */
export class Expenditure extends PageComponent<PageProps, ExpenditureState>
  implements PageToolEvents {
  updatedRows?: Array<ExpenditureDetailModel>;

  updatedRows2?: Array<JournalPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  popupDetailGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinity4?: InfinityRetrieve;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}0101`,
      enddate: `${year}${month}${date}`,
      peridState: '0',
      perid: '',
      data: new ExpenditureModel({
        inputdate: `${year}${month}${date}`,
      }),
      expenditureList: [],
      expenditureDetailList: [],
      popupList: [],
      popupDetailList: [],
      wkcds: [],
      isCopy: false,
      isReported: false,
      focusIndex: 0,
      chk: '0',
      new: '0',

      inputdate: `${year}${month}${date}`,
      samt_tot: '',
      tamt_tot: '',
      amount_tot: '',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          ...this.state.data,
        }, false,
      );

      data && this.setState({
        data: new ExpenditureModel({
          ...this.state.data,
          ...data,
        }, data.new),
      });

      await this.onRetrieveEvent();
    }
  }


  @action
  async onFirstOpenEvent() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;

    // 조건에 따른 사원명
    user.divicd.slice(0, 2) === 'AA'
    && this.setState({
      perid: user.perid,
      pernm: user.pernm,
      peridState: '1',
    });

    // 팝업 구분
    const data = await api.dropdown('wf_dd_e021_kukcd02');
    if (!data) return;
    this.setState({
      wkcds: data.items,
      perid: user.perid,
      pernm: user.pernm,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          expenditureList: [...this.state.expenditureList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          expenditureList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.expenditureList && this.state.expenditureList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      expenditureList: [],
      expenditureDetailList: [],
      data: new ExpenditureModel(),
      isReported: false,
      new: '0',
    }, async () => {
      const index = await this.infinity?.retrieveTo(['inputdate', 'inputnum'],
        [lastSelected?.inputdate, lastSelected?.inputnum], type, true) || 0;
      this.state.expenditureList && this.state.expenditureList.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  onRowFocusEvent(item: ExpenditureModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        focusedExpenditureDetail: undefined,
        isCopy: false,
        isReported: false,
      });
      return;
    }

    // 신규 시
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        focusedExpenditureDetail: undefined,
        expenditureDetailList: [],
        isReported: false,
        data: lastNewData,
      });
      this.table.current?.update(false);
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState(
      {
        focusedExpenditure: item,
        focusIndex: index,
      },
      async () => {
        this.infinity2 = new InfinityRetrieve({
          inputdate: this.state.focusedExpenditure?.inputdate,
          inputnum: this.state.focusedExpenditure?.inputnum,
          inputsabun: this.state.focusedExpenditure?.inputsabun,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            expenditureDetailList: [
              ...this.state.expenditureDetailList,
              ...items.map((x: any) => new ExpenditureDetailModel(x, false)),
            ],
          });

          // 결재상신 여부확인
          if (this.state.focusedExpenditure?.appgubun === null
            || this.state.focusedExpenditure?.appgubun === ''
            || this.state.focusedExpenditure?.appgubun === '131') {
            this.setState({ isReported: false });
          } else {
            this.setState({ isReported: true });
          }
          this.table.current?.update(false);
        },
        async () => {
          await this.SS({
            expenditureDetailList: [],
          });

          await this.infinity2?.retrieve();
          if (this.state.expenditureDetailList && this.state.expenditureDetailList?.length > 0) {
            await this.table.current?.update();
            this.table.current?.setFocus(0, 1);
          }
        });

        // 상단 조회 버튼을 누를때는 기존 배열 초기화
        this.setState({
          expenditureDetailList: [],
        }, async () => {
          const dataDate = await this.infinity2?.retrieve();
          this.setState({
            data: new ExpenditureModel(dataDate),
            samt_tot: this.infinity2?.box?.samt_tot || '0',
            tamt_tot: this.infinity2?.box?.tamt_tot || '0',
            amount_tot: this.infinity2?.box?.amount_tot || '0',
          });

          if (!this.state.expenditureDetailList.length) {
            const dataDate = await api.fxExec(
              'dw_1_RowFocuschanged',
              {
                inputdate: this.state.focusedExpenditure?.inputdate,
                inputnum: this.state.focusedExpenditure?.inputnum,
                inputsabun: this.state.focusedExpenditure?.inputsabun,
              },
            );
            this.setState({
              data: new ExpenditureModel(dataDate),
              samt_tot: dataDate?.samt_tot || '0',
              tamt_tot: dataDate?.tamt_tot || '0',
              amount_tot: dataDate?.amount_tot || '0',
            });
          }

          await this.table.current?.update();
          this.table.current?.setFocus(0, 1);
        });
      },
    );
  }

  @action
  onRowFocusEvent2(item:ExpenditureDetailModel) {
    // if (item.new === '1') return;
    this.setState({ focusedExpenditureDetail: item });
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    let samt = Format.toNumber(item.samt);
    const amount = Format.toNumber(item.amount);
    const tamt = Format.toNumber(item.tamt);

    let value = 0;
    let sum = 0;
    let tax = 0;

    if (name === 'samt') {
      tax = Math.round(samt / 10);
      sum = Math.round(samt + tax);
    } else if (name === 'amount') {
      value = Math.round(amount / 1.1);
      tax = Math.round(amount - value);
      samt = value;
      sum = Math.round(value + tax);
    } else {
      tax = tamt;
      sum = Math.round(samt + tamt);
    }

    rowUpdate({
      ...item,
      samt: samt.toString(),
      tamt: tax.toString(),
      amount: sum.toString(),
    });
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    if (type === ExpenditureItemChangeTypeNames.inputdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: ExpenditureItemChangeTypeNames[type],
          data: this.state.data.inputdate,
          items3: this.state.expenditureDetailList.length.toString(), // detail list count
          new: this.state.data.new,
        },
      );
    }
    data && this.setState({
      data: new ExpenditureModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async detailItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.expenditureDetailList.findIndex((x) => x.outnum === item.outnum);
    let data = { new: undefined };
    if (type === ExpenditureItemChangeTypeNames.tamt
    ) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: ExpenditureItemChangeTypeNames[type],
          data: '',
          samt: item.samt,
          tamt: item.tamt,
          new: this.state.focusedExpenditureDetail?.new,
        },
      );
    }

    if (type === ExpenditureItemChangeTypeNames.samt
    ) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: ExpenditureItemChangeTypeNames[type],
          data: item.samt,
          new: this.state.focusedExpenditureDetail?.new,
        },
      );
    }

    if (type === ExpenditureItemChangeTypeNames.amount
    ) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: ExpenditureItemChangeTypeNames[type],
          data: '',
          amount: item.amount,
          new: this.state.focusedExpenditureDetail?.new,
        },
      );
    }
    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedExpenditureDetail: new ExpenditureDetailModel({
        ...this.state.focusedExpenditureDetail,
      }, this.state.focusedExpenditureDetail?.isNew),
      // eslint-disable-next-line max-len
      expenditureDetailList: this.state.expenditureDetailList.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new ExpenditureDetailModel({ ...x, ...data }, data.new === '1')
          : new ExpenditureDetailModel(x)
      )),
    }, () => this.table.current?.update(false));
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedExpenditure!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.COST, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedExpenditure!, this.state.focusIndex);
  }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `일자: ${this.state.focusedExpenditure?.inputdate}
                번호: ${this.state.focusedExpenditure?.inputnum}
                제목: ${this.state.focusedExpenditure?.subject}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    // 문서복사 시 appnum, inputnum 중복안되게 공백 ''값으로
    const data = await api.exec(Category.EMAIL, 'wb_copy', {
      inputdate: this.state.focusedExpenditure?.inputdate,
      inputnum: '',
      inputsabun: this.state.focusedExpenditure?.inputsabun,
      subject: this.state.focusedExpenditure?.subject,
      remark: this.state.data?.remark,
      appnum: '',
      appgubun: '',
      sndflag: this.state.data?.sndflag,
      amount: this.state.data?.amount,
    });

    if (data) {
      const newModel = new ExpenditureModel(data, true);
      this.state.expenditureDetailList[0].new = '1';
      let items: any = [];
      items = this.state.expenditureDetailList.map((x) => ({
        ...x,
        new: '1',
      }));

      this.setState({
        data: newModel,
        expenditureList: [newModel, ...this.state.expenditureList],
        focusedExpenditure: newModel,
        expenditureDetailList: items,
        isCopy: true,
      }, async () => {
        await this.table.current?.update(false);
      });
      this.grid.current?.setFocus(0);
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.COST);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.COST.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const remarkLine = this.state.data?.remark.split('\n');

    // 비고 10줄까지 입력 제한
    if (remarkLine.length > 10) {
      ConfirmWarning.show('경고', '최대 10줄까지 입력 가능합니다.');
      return;
    }

    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }
    if (this.state.expenditureDetailList.length < 1) {
      ConfirmWarning.show('경고', '상세 내용이 없습니다.');
      return;
    }

    // 상세내용 8건 이상 입력제한
    if (this.state.expenditureDetailList.length > 8) {
      ConfirmWarning.show('경고', '8건이상 결의를 한번에 등록 할 수 없습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
      inputdate: this.state.data?.inputdate || Today.date(),
      items: this.state.expenditureDetailList,
    }, this.state.data.new === '1')) {
      this.updatedRows = [];
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.inputdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new ExpenditureModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        expenditureList: [
          newModel,
          ...this.state.expenditureList,
        ],
        new: '1',
        expenditureDetailList: [],
        focusedExpenditure: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(false);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `일자: ${this.state.focusedExpenditure?.inputdate} 번호: ${this.state.focusedExpenditure?.inputnum}`;

    if (this.state.focusedExpenditure?.appgubun === '101') {
      let result: boolean;
      // eslint-disable-next-line prefer-const
      result = await Confirm.show('확인', '이미 결재가 상신되어있는 문서입니다. 다른이(상급자)가 이문서에 대해 승인이 있습니다.그래도 상신 취소 하시겠습니까?', ConfirmType.QUESTION);

      if (!result) return;

      await api.fxExec('delete_appok', {
        sndflag: this.state.data?.sndflag,
        inputdate: this.state.data?.inputdate,
        inputnum: this.state.data?.inputnum,
        appnum: this.state.data?.appnum,
        appgubun: this.state.data?.appgubun,
        remark: this.state.data?.remark,
      });

      await this.onRetrieveEvent(RetrieveFocusType.END);
    } else {
      await api.delete(text, this.state.focusedExpenditure) && this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        inputsabun: this.state.data?.inputsabun,
        inputdate: this.state.data?.inputdate || Today.date(),
        inputnum: this.state.data?.inputnum,
        outnum: this.state.focusedExpenditureDetail?.outnum,
        subject: this.state.data?.subject,
      },
    );

    if (this.state.expenditureDetailList.length === 8) {
      ConfirmWarning.show('경고', '8건이상 결의를 한번에 등록 할 수 없습니다.');
      return;
    }

    const seq = this.state.expenditureDetailList.length + 1;
    data && this.setState({
      expenditureDetailList: [...this.state.expenditureDetailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new ExpenditureDetailModel({ ...data, outnum: seq <= 10 ? `00${seq}` : seq <= 100 ? `0${seq}` : seq.toString(), spdate: Today.date() },
          true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.expenditureDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.expenditureDetailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 항목이 없습니다.');
      return;
    }
    const text = `${this.state.focusedExpenditureDetail?.outnum}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        inputdate: this.state.data?.inputdate || '',
        inputnum: this.state.data?.inputnum || '',
        outnum: this.state.focusedExpenditureDetail?.outnum || '',
        inputsabun: this.state.data?.inputsabun || '',
        appgubun: this.state.data?.appgubun || '',
        okgubun: this.state.focusedExpenditureDetail?.okgubun || '',
        oknumber: this.state.focusedExpenditureDetail?.oknumber || '',
        subject: this.state.data?.subject || '',
        new: this.state.focusedExpenditureDetail?.new,
      },
    );
    const checkData: any = [];
    this.state.expenditureDetailList?.forEach((x: any) => {
      if (this.state.focusedExpenditureDetail?.outnum !== x.outnum) {
        checkData.push({
          ...x,
        });
      }
    });
    await this.setState({
      expenditureDetailList: checkData,
    });
    await this.table.current?.update(true);
    await this.table.current?.setFocus(0);
  }


  @action
  async loadJournalModal(isOpen: boolean) {
    this.setState({ journalModal: isOpen });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_ae200_e038',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          popupList: [...this.state.popupList, ...items],
        }, () => { this.popupTable.current?.update(false); });
      },
      async () => {
        await this.SS({
          popupList: [],
        });

        await this.infinity3?.retrieve();
        if (this.state.popupList && this.state.popupList?.length > 0) {
          await this.popupTable.current?.update();
          this.popupTable.current?.setFocus(0, 0);
        }
      },
    );

    this.setState({
      popupList: [],
      popupDetailList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
      await this.popupTable.current?.update();
      this.popupTable.current?.setFocus(0, 0);
    });
  }

  @action
  async modalFocusChange(item: JournalPopupModel) {
    const { actionStore: api } = this.props;

    this.setState(
      {
        popupFocused: item,
        popupDetailList: [],
      }, async () => {
        this.infinity4 = new InfinityRetrieve(
          {
            sub: 'w_popup_ae200_e038',
            rptdate: item.rptdate,
          },
          (params) => api.fxExec('dw_list_RowFocuschanged', params),
          (items) => {
            this.setState({
              popupDetailList: [...this.state.popupDetailList, ...items],
            });
          },
          async () => {
            await this.SS({
              popupDetailList: [],
            });

            await this.infinity4?.retrieve();
            if (this.state.popupDetailList && this.state.popupDetailList?.length > 0) {
              await this.popupDetailGrid.current?.setFocus(0);
            }
          },
        );

        if (!this.infinity4.count) {
          this.setState({ expenditureDetailList: [] });
        }

        await this.infinity4?.retrieveAll();
        this.popupDetailGrid.current?.setFocus(0);
        // this.popupDetailGrid.current?.setFocus(0);
      },
    );
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_ae200_e038',
      items: this.updatedRows2,
    }) && await this.modalRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('save',
      {
        sub: 'w_popup_ae200_e038',
        inputdate: this.state.inputdate || '',
        items: this.updatedRows2,
      });

    if (data) {
      await this.loadJournalModal(false);
      await this.onRetrieveEvent();
      this.updatedRows2 = [];
    }
  }


  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.inputsabun, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      inputsabun: this.state.data.inputsabun,
      inputdate: this.state.data.inputdate,
      inputnum: this.state.data.inputnum,
      appgubun: this.state.data.appgubun,
      net: '1',
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  // eslint-disable-next-line max-len
  onUpdatedRows(rows2: Array<ExpenditureDetailModel>, updatedRows: Array<ExpenditureDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ expenditureDetailList: rows2 });
  }

  @action
  // eslint-disable-next-line max-len
  onUpdatedRows2(rows2: Array<JournalPopupModel>, updatedRows2: Array<JournalPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ popupList: rows2 });
  }

  render() {
    return (
      <ExpenditureTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new ExpenditureModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
