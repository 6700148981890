import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { CashBook } from './CashBook';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBindingClt } from '../../../../models/common';
import { Sum } from '../../../../utils/array';

/**
 * 화면
 * @window  w_tb_ca642w_09
 * @category 현금출납장
 */
export const CashBookTemplate: React.FC<TemplateProps<CashBook>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>사업장</span>
            <ComboBox
              value={scope.state.spjangcd}
              data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm || '전체'))}
              onSelect={(option) => scope.setState({ spjangcd: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={450}>
            <span style={{ color: 'var(--color-red)' }}>거래처</span>
            <TextBox
              size={100}
              textAlign="center"
              value={scope.state.cltcd}
              onChange={(value) => setData({ cltcd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBindingClt(
                scope.props.modalStore,
                {},
                true,
                (item) => {
                  setData({
                    cltcd: item.cltcd,
                    cltnm: item.cltnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.cltnm}
              onChange={(value) => setData({ cltnm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'misdate',
            text: '발생일자',
            width: 10,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.misdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'mismon',
            text: '발생월',
            isHidden: true,
            group: true,
            value: (x) => x.misdate?.substr(0, 6),
          },
          {
            id: 'misnum',
            text: '번호',
            width: 7,
            sum: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                월 계
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '적요',
            width: 28,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'cltnm',
            text: '지급처명',
            width: 15,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.cltnm}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '입금액',
            width: 11,
            sum: (x, data) => {
              const sumMisamt = Sum.all(
                data.filter((y) => y.misdate.substr(0, 6) === x.misdate.substr(0, 6)),
                'misamt',
              );

              return (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(sumMisamt)}
                </FlexLayout>
              );
            },
            trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.misamt_tot}
                </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.misamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'mijamt',
            text: '지급액',
            width: 11,
            sum: (x, data) => {
              const sumMijamt = Sum.all(
                data.filter((y) => y.misdate.substr(0, 6) === x.misdate.substr(0, 6)),
                'mijamt',
              );

              return (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(sumMijamt)}
                </FlexLayout>
              );
            },
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.mijamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.mijamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'resuamt',
            text: '잔액',
            width: 13,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.resuamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.resuamt)}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
