import { TextBox, DateTextBox, FormatTextBox } from '../../../../../components';
import { TableLayoutHeader } from '../../../../../constants';
import { EnrollmentAccountModel } from '../models';
import { Enrollment } from '../Enrollment';


// 거래처이력정보
const Account: TableLayoutHeader<EnrollmentAccountModel, Enrollment>[] = [
  {
    id: 'seq',
    text: '순서',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.seq}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'cltcd',
    text: '거래처코드',
    width: 70,
    render: (x, _, ref) => (
      <TextBox
        ref={ref}
        value={x.cltcd}
        textAlign="center"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 150,
    render: (x) => (
      <TextBox
        value={x.cltnm}
        transparent={true}
        textAlign="left"
      />
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 80,
    render: (x, _, ref) => (
      <DateTextBox
        ref={ref}
        value={x.stdate}
        textAlign="center"
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'enddate',
    text: '종료일자',
    width: 80,
    render: (x, _, ref) => (
      <DateTextBox
        ref={ref}
        value={x.stdate}
        textAlign="center"
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'prenm',
    text: '대표자명',
    width: 60,
    render: (x) => (
      <TextBox
        value={x.prenm}
        transparent={true}
        textAlign="left"
      />
    ),
  },
  {
    id: 'saupnum',
    text: '사업자번호',
    width: 70,
    render: (x) => (
      <FormatTextBox
        format='###-##-#####'
        value={x.saupnum}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'prenum',
    text: '법인번호',
    width: 80,
    render: (x) => (
      <TextBox
        value={x.prenum}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'agnernm',
    text: '담당자',
    width: 60,
    render: (x) => (
      <TextBox
        value={x.agnernm}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'taxmail',
    text: '계산서메일',
    width: 100,
    render: (x) => (
      <TextBox
        value={x.taxmail}
        transparent={true}
        textAlign="center"
      />
    ),
  },
];

export default Account;
