export default class Sum {
  public static all(data: Array<any>, column: string): number {
    if (!data || !column) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < data.length; i += 1) {
      sum += parseInt(`${data[i][column]}`.replace(/,/ig, ''), 10) || 0;
    }

    return sum;
  }

  // 실수 총합
  public static floatAll(data: Array<any>, column: string): number {
    if (!data || !column) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < data.length; i += 1) {
      sum += parseFloat(`${data[i][column]}`.replace(/,/ig, '')) || 0;
    }

    return sum;
  }

  public static byGroup(data: Array<any>, column: string, item: any, group: Array<string>): number {
    if (!data || !column) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (Sum.isGrouped(group, item, data[i])) {
        sum += parseInt(`${data[i][column]}`.replace(/,/ig, ''), 10) || 0;
      }
    }

    return sum;
  }

  public static byGroupFloat(data: Array<any>, column: string, item: any, group: Array<string>): number {
    if (!data || !column) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (Sum.isGrouped(group, item, data[i])) {
        sum += parseFloat(`${data[i][column]}`.replace(/,/ig, '')) || 0;
      }
    }

    return sum;
  }

  public static groupLength(
    data: Array<any>,
    column: string,
    item: any,
    group: Array<string>,
  ): number {
    if (!data || !column) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (Sum.isGrouped(group, item, data[i])) {
        sum += 1;
      }
    }

    return sum;
  }

  public static groupValue(data: Array<any>,
    column: string,
    item: any,
    value: string,
    group: Array<string>): number {
    if (!data || !column) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (Sum.isGrouped(group, item, data[i])) {
        if (data[i][column] === value) {
          sum += 1;
        }
      }
    }

    return sum;
  }

  public static timeByGroup(
    data: Array<any>,
    hourColumn: string,
    minuteColumn: string,
    item: any,
    group: Array<string>,
  ): string {
    if (!data || !hourColumn || !minuteColumn) {
      return '0000';
    }

    let hours = 0;
    let minutes = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (Sum.isGrouped(group, item, data[i])) {
        hours += parseInt(`${data[i][hourColumn]}`.replace(/,/ig, ''), 10) || 0;
        minutes += parseInt(`${data[i][minuteColumn]}`.replace(/,/ig, ''), 10) || 0;
      }
    }

    hours += Math.floor(minutes / 60);
    minutes %= 60;

    return `${hours < 10 ? `0${hours}` : hours}${minutes < 10 ? `0${minutes}` : minutes}`;
  }

  public static time(
    data: Array<any>,
    hourColumn: string,
    minuteColumn: string,
  ): string {
    if (!data || !hourColumn || !minuteColumn) {
      return '0000';
    }

    let hours = 0;
    let minutes = 0;
    for (let i = 0; i < data.length; i += 1) {
      hours += parseInt(`${data[i][hourColumn]}`.replace(/,/ig, ''), 10) || 0;
      minutes += parseInt(`${data[i][minuteColumn]}`.replace(/,/ig, ''), 10) || 0;
    }

    hours += Math.floor(minutes / 60);
    minutes %= 60;

    return `${hours < 10 ? `0${hours}` : hours}${minutes < 10 ? `0${minutes}` : minutes}`;
  }

  public static isGrouped(data: Array<any>, item: any, item2: any): boolean {
    let result = true;

    data.forEach((x) => {
      if (item[x] !== item2[x]) {
        result = false;
      }
    });

    return result;
  }
}
