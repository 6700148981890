import Swal from 'sweetalert2';
import { ConfirmWarning } from './index';
import { AskType, ConfirmType, ConfirmTypeName } from '../../constants';
import { Fix } from '../string';

export default class Confirm {
  static show(title: string, text: string, confirmType: ConfirmType): Promise<boolean> {
    return new Promise((resolve) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title,
        html: Fix.newline(text).split('\n').join('<BR/>'),
        icon: ConfirmTypeName[confirmType],
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        reverseButtons: true,
      // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          resolve(true);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          ConfirmWarning.show('취소', '취소하였습니다.');
          resolve(false);
        }
      });
    });
  }

  static ask(
    title: string,
    text: string,
    buttonYesText: string,
    buttonNoText: string,
  ): Promise<AskType> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: false,
    });

    return new Promise((resolve) => {
      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title,
        html: Fix.newline(text).split('\n').join('<BR/>'),
        icon: ConfirmTypeName[ConfirmType.QUESTION],
        showCancelButton: true,
        confirmButtonText: buttonYesText,
        cancelButtonText: buttonNoText,
        reverseButtons: true,
      // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          resolve(AskType.YES);
        } else if (
          result.dismiss === Swal.DismissReason.backdrop
        ) {
          ConfirmWarning.show('취소', '취소하였습니다.');
          resolve(AskType.CANCEL);
        } else {
          resolve(AskType.NO);
        }
      });
    });
  }
}
