import { computed } from 'mobx';

/**
 *
 * @window w_tb_pa105
 * @category 차량등록
 */
export class CarModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  gubun: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(4)
   */
  divinm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(4)
   */
  divicd: string;

  /**
   * 사원코드
   *
   * **data type** varchar(13)
   */
  perid: string;

  /**
   * 사용자명
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 차량소유주
   *
   * **data type** varchar(100)
   */
  carpernm: string;

  /**
   * 차량번호
   *
   * **data type** varchar(20)
   */
  carnum: string;

  /**
   * 차명
   *
   * **data type** varchar(255)
   */
  carname: string;

  /**
   * 유종
   *
   * **data type** varchar(3)
   */
  kmflag: string;

  /**
   * 차량등록일
   *
   * **data type** varchar(8)
   */
  cardate: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  /**
   * 유종 치환
   *
   * **data type** varchar(1)
   */
  com_cnam: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.gubun = data.gubun || '';
    this.seq = data.seq || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.com_cnam = data.com_cnam || '';
    this.carpernm = data.carpernm || '';
    this.carnum = data.carnum || '';
    this.carname = data.carname || '';
    this.kmflag = data.kmflag || '';
    this.cardate = data.cardate || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
