import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 메인모델
 * @window w_tb_ca611_01
 * @category 입고등록
 */

export class StockModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  ibgdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly ibgnum: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly ibgseq: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly ibgno: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly ibgtype: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly taxcls: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly setcls: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly store: string;

  /**
   * 창고명
   *
   * **data type** varchar(3)
   */
  readonly storenm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly domcls: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly moncls: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly monrate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly sunflag: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly taxflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly taxdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly taxnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly qcdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly qcnum: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly sunamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly jiamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly schdate: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(32765)
   */
  readonly bigo: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly projnm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly cdflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly tax_spdate: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly tax_spnum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly mijflag: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly r_color: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gs_color: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.datetime = data.datetime || '';
    this.ibgdate = data.ibgdate || '';
    this.ibgnum = data.ibgnum || '';
    this.ibgno = data.ibgno || '';
    this.ibgtype = data.ibgtype || '';
    this.ibgseq = data.ibgseq || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.taxcls = data.taxcls || '';
    this.setcls = data.setcls || '';
    this.store = data.store || '';
    this.storenm = data.storenm || '';
    this.domcls = data.domcls || '';
    this.moncls = data.moncls || '';
    this.monrate = data.monrate || '';
    this.sunflag = data.sunflag || '';
    this.taxflag = data.taxflag || '';
    this.taxdate = data.taxdate || '';
    this.taxnum = data.taxnum || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.qcdate = data.qcdate || '';
    this.qcnum = data.qcnum || '';
    this.sunamt = data.sunamt || '';
    this.jiamt = data.jiamt || '';
    this.schdate = data.schdate || '';
    this.remark = Fix.newline(data.remark) || '';
    this.bigo = Fix.newline(data.bigo) || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.cdflag = data.cdflag || '';
    this.tax_spdate = data.tax_spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.mijflag = data.mijflag || '';
    this.state = data.state || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.r_color = data.r_color || '';
    this.gs_color = data.gs_color || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
