/**
 * 모델
 * @window w_tb_e601w_sulchi_03
 * @category 설치진행현황
 */
export default class ProcessModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 설치 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate_sc: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly baldateok: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly baldateok_sc: string;

  /**
   * 발주상태
   *
   * **data type** varchar(8)
   */
  readonly balflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly balokflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly be_date: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly be_date_sc: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly be_state: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly compbook: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly compbookdate_sc: string;

  /**
   * 계약상태
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;

  /**
   * 계약상태명
   *
   * **data type** varchar(8)
   */
  readonly contflagnm: string;

  /**
   * 완료금
   *
   * **data type** varchar(8)
   */
  readonly endamt: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 설치 검사일자
   *
   * **data type** varchar(8)
   */
  readonly kcdate_sc: string;

  /**
   * 검사상태
   *
   * **data type** varchar(8)
   */
  readonly kcflag: string;

  /**
   * 중도금
   *
   * **data type** varchar(8)
   */
  readonly midamt: string;

  /**
   * 미수금일자
   *
   * **data type** varchar(8)
   */
  readonly misenddate: string;

  /**
   * 설치 미수금일자
   *
   * **data type** varchar(8)
   */
  readonly misenddate_sc: string;

  /**
   * 미수상태
   *
   * **data type** varchar(8)
   */
  readonly misendflag: string;

  /**
   * 종료금 매출일자
   *
   * **data type** varchar(8)
   */
  readonly misendnum: string;

  /**
   * 중도금 매출일자
   *
   * **data type** varchar(8)
   */
  readonly mismiddate: string;

  /**
   * 설치 중도금 매출일자
   *
   * **data type** varchar(8)
   */
  readonly mismiddate_sc: string;

  /**
   * 중도금 매출
   *
   * **data type** varchar(8)
   */
  readonly mismidflag: string;

  /**
   * 중도금 매출번호
   *
   * **data type** varchar(8)
   */
  readonly mismidnum: string;

  /**
   * 선급금 매출일
   *
   * **data type** varchar(8)
   */
  readonly misstdate: string;

  /**
   * 선급금 매출일
   *
   * **data type** varchar(8)
   */
  readonly misstdate_sc: string;

  /**
   * 선급금 매출상태
   *
   * **data type** varchar(8)
   */
  readonly misstflag: string;

  /**
   * 선급금 매출번호
   *
   * **data type** varchar(8)
   */
  readonly misstnum: string;

  /**
   * 수주통보
   *
   * **data type** varchar(8)
   */
  readonly notice: string;

  /**
   * 승인도서
   *
   * **data type** varchar(8)
   */
  readonly okbook: string;

  /**
   * 승인도서 일자
   *
   * **data type** varchar(8)
   */
  readonly okbookdate_sc: string;

  /**
   * 승인도서 일자
   *
   * **data type** varchar(8)
   */
  readonly okdate_sc: string;

  /**
   * 필증
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   * 잔금발행일
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 잔금발행상태
   *
   * **data type** varchar(8)
   */
  readonly ordflag: string;

  /**
   * 잔금발행월
   *
   * **data type** varchar(8)
   */
  readonly ordmon: string;

  /**
   * 착공일자
   *
   * **data type** varchar(8)
   */
  readonly processdate_sc: string;

  /**
   * 착공상태
   *
   * **data type** varchar(8)
   */
  readonly processflag: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly sckcdate: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  readonly sckcdate_sc: string;

  /**
   * 검사상태
   *
   * **data type** varchar(8)
   */
  readonly sckcflag: string;

  /**
   * 선급금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 상태명
   *
   * **data type** varchar(8)
   */
  readonly statenm: string;

  /**
   * 착공율
   *
   * **data type** varchar(8)
   */
  readonly process_per: string;

  /**
   * 오늘
   *
   * **data type** varchar(8)
   */
  readonly today: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.baldate = data.baldate || '';
    this.baldate_sc = data.baldate_sc || '';
    this.baldateok = data.baldateok || '';
    this.baldateok_sc = data.baldateok_sc || '';
    this.balflag = data.balflag || '';
    this.balokflag = data.balokflag || '';
    this.be_date = data.be_date || '';
    this.be_date_sc = data.be_date_sc || '';
    this.be_state = data.be_state || '';
    this.compbook = data.compbook || '';
    this.compbookdate_sc = data.compbookdate_sc || '';
    this.contflag = data.contflag || '';
    this.contflagnm = data.contflagnm || '';
    this.endamt = data.endamt || '';
    this.kcdate = data.kcdate || '';
    this.kcdate_sc = data.kcdate_sc || '';
    this.kcflag = data.kcflag || '';
    this.midamt = data.midamt || '';
    this.misenddate = data.misenddate || '';
    this.misenddate_sc = data.misenddate_sc || '';
    this.misendflag = data.misendflag || '';
    this.misendnum = data.misendnum || '';
    this.mismiddate = data.mismiddate || '';
    this.mismiddate_sc = data.mismiddate_sc || '';
    this.mismidflag = data.mismidflag || '';
    this.mismidnum = data.mismidnum || '';
    this.misstdate = data.misstdate || '';
    this.misstdate_sc = data.misstdate_sc || '';
    this.misstflag = data.misstflag || '';
    this.misstnum = data.misstnum || '';
    this.notice = data.notice || '';
    this.okbook = data.okbook || '';
    this.okbookdate_sc = data.okbookdate_sc || '';
    this.okdate_sc = data.okdate_sc || '';
    this.okflag = data.okflag || '';
    this.orddate = data.orddate || '';
    this.ordflag = data.ordflag || '';
    this.ordmon = data.ordmon || '';
    this.processdate_sc = data.processdate_sc || '';
    this.processflag = data.processflag || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.scactnm = data.scactnm || '';
    this.sckcdate = data.sckcdate || '';
    this.sckcdate_sc = data.sckcdate_sc || '';
    this.sckcflag = data.sckcflag || '';
    this.stamt = data.stamt || '';
    this.state = data.state || '';
    this.process_per = data.process_per || '';
    this.statenm = data.statenm || '';
    this.today = data.today || '';
  }
}
