import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { JournalStatementTemplate } from './JournalStatement.template';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { Today } from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { JournalStatementModel } from './JournalStatement.model';

interface JournalState {

  // 검색조건
  stmon: string;
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;
  topDivicd: string;

  pages: number;
  lastday: number;
  nlastday: number;

  journalStatementList: Array<JournalStatementModel>;
  data: JournalStatementModel;
  focusedWalkDown: JournalStatementModel;
  trailData: Array<any>;

  deday_tot: string;
  today_tot: string;
  totnotday_tot: string;
  totkm_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e037w
 * @category 업무일지작성현황
 */
export class JournalStatement extends PageComponent<PageProps, JournalState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {

      perid: '',
      pernm: '',
      divicd: '',
      divinm: '',
      topDivicd: '01',

      pages: 0,
      stmon: Today.yearMon(),

      data: new JournalStatementModel(),
      journalStatementList: [],
      trailData: [],

      lastday: 30,
      nlastday: 30,

      deday_tot: '0',
      today_tot: '0',
      totnotday_tot: '0',
      totkm_tot: '0',
    };
  }

  @action
  // eslint-disable-next-line class-methods-use-this,no-empty-function
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        perid: this.state.perid,
        divicd: this.state.divicd,
        stmon: this.state.stmon,
        kukcd: this.state.topDivicd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          pages: this.state.pages + items.length,
          journalStatementList: [...this.state.journalStatementList,
            // eslint-disable-next-line max-len
            ...items.map((x: any, index: number) => new JournalStatementModel({ ...x, seq: this.state.pages + index + 1 }))],
        }, next);
      },
      async () => {
        await this.SS({
          pages: 0,
          journalStatementList: [],
        });
        await this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      pages: 0,
      journalStatementList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['divinm', 'pernm'],
        [this.state.focusedWalkDown?.divinm, this.state.focusedWalkDown?.pernm], type, true) || 0;
      this.state.journalStatementList && this.state.journalStatementList.length > index && this.grid.current?.setFocus(index);

      const checkData: any = [];
      await checkData.push({
        km1_tot: this.infinity?.box?.km1_tot || '0',
        km2_tot: this.infinity?.box?.km2_tot || '0',
        km3_tot: this.infinity?.box?.km3_tot || '0',
        km4_tot: this.infinity?.box?.km4_tot || '0',
        km5_tot: this.infinity?.box?.km5_tot || '0',
        km6_tot: this.infinity?.box?.km6_tot || '0',
        km7_tot: this.infinity?.box?.km7_tot || '0',
        km8_tot: this.infinity?.box?.km8_tot || '0',
        km9_tot: this.infinity?.box?.km9_tot || '0',
        km10_tot: this.infinity?.box?.km10_tot || '0',
        km11_tot: this.infinity?.box?.km11_tot || '0',
        km12_tot: this.infinity?.box?.km12_tot || '0',
        km13_tot: this.infinity?.box?.km13_tot || '0',
        km14_tot: this.infinity?.box?.km14_tot || '0',
        km15_tot: this.infinity?.box?.km15_tot || '0',
        km16_tot: this.infinity?.box?.km16_tot || '0',
        km17_tot: this.infinity?.box?.km17_tot || '0',
        km18_tot: this.infinity?.box?.km18_tot || '0',
        km19_tot: this.infinity?.box?.km19_tot || '0',
        km20_tot: this.infinity?.box?.km20_tot || '0',
        km21_tot: this.infinity?.box?.km21_tot || '0',
        km22_tot: this.infinity?.box?.km22_tot || '0',
        km23_tot: this.infinity?.box?.km23_tot || '0',
        km24_tot: this.infinity?.box?.km24_tot || '0',
        km25_tot: this.infinity?.box?.km25_tot || '0',
        km26_tot: this.infinity?.box?.km26_tot || '0',
        km27_tot: this.infinity?.box?.km27_tot || '0',
        km28_tot: this.infinity?.box?.km28_tot || '0',
        km29_tot: this.infinity?.box?.km29_tot || '0',
        km30_tot: this.infinity?.box?.km30_tot || '0',
        km31_tot: this.infinity?.box?.km31_tot || '0',
      });

      this.setState({
        trailData: checkData,
        totkm_tot: this.infinity?.box?.totkm_tot || '0',
        deday_tot: this.infinity?.box?.deday_tot || '0',
        today_tot: this.infinity?.box?.today_tot || '0',
        totnotday_tot: this.infinity?.box?.totnotday_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.journalStatementList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      perid: this.state.perid,
      divicd: this.state.divicd,
      stmon: this.state.stmon,
      kukcd: this.state.topDivicd,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.journalStatementList.length, '오류', '엑셀로 변환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      perid: this.state.perid,
      divicd: this.state.divicd,
      stmon: this.state.stmon,
      kukcd: this.state.topDivicd,
    });
  }

  @action
  onRowFocusEvent(item:JournalStatementModel) {
    this.setState({ focusedWalkDown: item });
  }

  render() {
    return (
      <JournalStatementTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new JournalStatementModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
