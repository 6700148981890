import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox, ComboBoxModel, DateTextBox, FlexLayout, IFrame, SearchLayout, TabLayout, TabView,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { MonthOilTab } from './MonthOil.tab';
import { MonthOil } from './MonthOil';
import Date6Calculator from '../../../../utils/time/Date6Calculator';

/**
 * 화면
 * @window w_tb_ca640_pa105_01w
 * @category 월유류비내역
 */
export const MonthOilTemplate: React.FC<TemplateProps<MonthOil>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>월</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => {
              setData({ yearmon: value },
                () => scope.onRetrieveEvent());
            }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                // eslint-disable-next-line max-len
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>재직구분</span>
          <ComboBox
            value={scope.state.rtclafi}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '001', remark: '재직자' },
              { value: '002', remark: '퇴직자' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ rtclafi: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>
      </SearchLayout>

      {scope.tabIndex === 0
        ? <FlexLayout>
          <TabLayout
            scope={scope}
            tabs={MonthOilTab}
            onChange={(_, index) => scope.onTabChange(index)}
          />

          <FlexLayout style={{ marginTop: 50 }}>
            <IFrame src={scope.state.graph}/>
          </FlexLayout>
        </FlexLayout>
        : <FlexLayout isVertical={true}>
          <TabView
            tabs={scope.tabs}
            focused={scope.state.focusedTab}
            onChange={(_, index) => scope.onTabChange2(index)}
          />

          <FlexLayout weight={10}>
            <IFrame src={scope.state.graph2}/>
          </FlexLayout>
        </FlexLayout>
      }
    </FlexLayout>
  );
};
