/**
 * 모델
 * @window w_tb_e034
 * @category 일상점검등록
 */
import { computed } from 'mobx';

export class DailyCheckModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 전송결과
   *
   * **data type** varchar(13)
   */
  result_code: string;

  /**
   * 회사
   *
   * **data type** varchar(13)
   */
  wkactcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(2)
   */
  equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(2)
   */
  equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(10)
   */
  elno: string;

  /**
   * 점검자
   *
   * **data type** float
   */
  kcpernm: string;

  /**
   * 계획일자
   *
   * **data type** varchar(20)
   */
  plandate: string;

  /**
   * 점검일자
   *
   * **data type** varchar(20)
   */
  rptdate: string;

  /**
   * 전송일자
   *
   * **data type** char(1)
   */
  senddate: string;

  /**
   * 전송시간
   *
   * **data type** varchar(50)
   */
  sendtime: string;

  /**
   * 차이일
   *
   * **data type** varchar(6)
   */
  gigan: string;

  /**
   * 전송실패내용
   *
   * **data type** varchar(?)
   */
  error_message: string;

  /**
   * 하단 총계 데이터
   *
   * **data type** varchar(?)
   */
  equp_cnttot: string;

  /**
   * 일지
   *
   * **data type** varchar(?)
   */
  kclog: string;

  /**
   * 점검기종
   *
   * **data type** varchar(?)
   */
  evcd: string;

  /**
   * 시작시간
   *
   * **data type** varchar(?)
   */
  sttime: string;

  /**
   * 종료시간
   *
   * **data type** varchar(?)
   */
  endtime: string;

  /**
   * 처리자
   *
   * **data type** varchar(?)
   */
  perid: string;

  /**
   * 처리자명
   *
   * **data type** varchar(?)
   */
  pernm: string;

  /**
   * 비고
   *
   * **data type** varchar(?)
   */
  remark: string;

  /**
   * ?
   *
   * **data type** varchar(?)
   */
  planmon: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.result_code = data.result_code || '';
    this.wkactcd = data.wkactcd || '';
    this.actnm = data.actnm || '';
    this.elno = data.elno || '';
    this.kcpernm = data.kcpernm || '';
    this.plandate = data.plandate || '';
    this.planmon = data.planmon || '';
    this.rptdate = data.rptdate || '';
    this.senddate = data.senddate || '';
    this.sendtime = data.sendtime || '';
    this.gigan = data.gigan || '';
    this.error_message = data.error_message || '';
    this.equp_cnttot = data.equp_cnttot || '';
    this.kclog = data.kclog || '';
    this.evcd = data.evcd || '';
    this.sttime = data.sttime || '';
    this.endtime = data.endtime || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
