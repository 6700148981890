import * as React from 'react';
import { action } from 'mobx';
import { RefObject } from 'react';
import update from 'react-addons-update';
import {
  AskType,
  PageProps,
  PageToolEvents,
  RowUpdate,
} from '../../../../constants';
import { CalendarLayoutDataModel, InfinityRetrieve } from '../../../../models';
import PlanModel from './models/PlanModel';
import { PlanTemplate } from './Plan.template';
import { PageComponent } from '../../../../utils';
import { GridLayout, GridLayoutAdditionalHeader, TableLayout } from '../../../../components';
import { Date6, Date8, Today } from '../../../../utils/time';
import PlanYetModel from './models/PlanYetModel';
import PlanExpireModel from './models/PlanExpireModel';
import PlanActModel from './models/PlanActModel';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { ChargePopupModel } from '../../../electronic/preparation/Journal/models';
import {
  JournalButtonClickedTypeNames,
  JournalButtonClickedTypes,
} from '../../../electronic/preparation/Journal/Journal';
import { Fix } from '../../../../utils/string';

interface PlanState {
  planmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  actcd: string;
  actnm: string;
  equpcd: string;
  equpnm: string;

  data: CalendarLayoutDataModel<PlanModel>[];
  content: PlanModel;

  isVisibleModalDetail: boolean;
  dataAct: Array<PlanActModel>;
  dataDetail: Array<PlanModel>;

  isVisibleModalYet: boolean;
  dataYet: Array<PlanYetModel>;

  isVisibleModalExpire: boolean;
  dataExpire: Array<PlanExpireModel>;

  isVisibleModalEqup: boolean;
  dataEqup: Array<PlanModel>;

  isChargeModalVisible: boolean;
  isGraphVisible: boolean;
  popupStmon: string;
  graph?: string;
  focusedCharge?: ChargePopupModel;
  popupChargeList: Array<ChargePopupModel>;
  equpChkToggle: boolean;
  pushTotalCheck: boolean;

  endcnt: string;
  notplan: string;
  tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e035
 * @category 점검계획등록
 */
export class Plan extends PageComponent<PageProps, PlanState>
  implements PageToolEvents {
  tableAct: RefObject<TableLayout> = React.createRef();

  tableDetail: RefObject<TableLayout> = React.createRef();

  tableYet: RefObject<TableLayout> = React.createRef();

  tableEqup: RefObject<TableLayout> = React.createRef();

  gridExpire: React.RefObject<GridLayout> = React.createRef();

  infinityAct?: InfinityRetrieve;

  infinityDetail?: InfinityRetrieve;

  infinityYet?: InfinityRetrieve;

  infinityExpire?: InfinityRetrieve;

  infinityEqup?: InfinityRetrieve;

  tableActFocusIndex: number = 0;

  tableActFocusActcd: string = '';

  tableDetailFocusIndex: number = 0;

  detailDate: string = Today.date();

  equpActcd?: string;

  dayRemarks: Record<string, { done: number, plan: number }>;

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    // 알림에서 자체점검[다음달] 미계획으로 페이지 이동 시 planmon 지정
    if (pageParams.planmon) {
      pageParams.planmon = `${Today.year()}${pageParams.planmon}`;
    }

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    this.dayRemarks = {};

    this.state = props.state || {
      planmon: pageParams.planmon || Date6.make(),
      divicd: '',
      divinm: '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      actcd: '',
      actnm: '',
      equpcd: '',
      equpnm: '',

      data: [],
      content: new PlanModel(),

      isVisibleModalYet: false,
      dataYet: [],

      isVisibleModalExpire: false,
      dataExpire: [],

      dataEqup: [],

      isChargeModalVisible: false,
      isGraphVisible: false,
      popupStmon: Today.yearMon(),
      popupChargeList: [],
      equpChkToggle: true,

      endcnt: '0',
      notplan: '0',
      tot: '',
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        planmon: pageParams.planmon || this.state.planmon,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    this.setState({
      data: [],
    }, async () => {
      // 달력
      const { actionStore: api } = this.props;
      const data = await api.retrieve({
        stmon: this.state.planmon || Date6.make(),
        divicd: this.state.divicd || '%',
        perid: this.state.perid || '%',
        actcd: this.state.actcd || '%',
        equpcd: this.state.equpcd || '%',
        state: '%',
      });

      this.setState({
        endcnt: data?.endcnt || '0', notplan: data?.notplan || '0', tot: Fix.newline(data?.tot) || '',
      });

      this.dayRemarks = {};
      data?.items?.forEach((x: any) => {
        if (!this.dayRemarks[x.plandate]) {
          this.dayRemarks[x.plandate] = { done: 0, plan: 0 };
        }
        // actnm 괄호안의 값 가져오기
        const startIndex = x.actnm.indexOf('[');
        const lastIndex = x.actnm.indexOf(']');
        const result = Number(x.actnm.substr(startIndex + 1, lastIndex - startIndex - 1));

        // 계획 완료 각각 total
        if (x.state === '1') {
          this.dayRemarks[x.plandate].plan += result;
        } else {
          this.dayRemarks[x.plandate].done += result;
        }
      });

      data?.items && this.setState({
        data: data.items.map((x: any) => (new CalendarLayoutDataModel(x.plandate, new PlanModel(x)))),
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stmon: this.state.planmon || Date6.make(),
      divicd: this.state.divicd || '%',
      perid: this.state.perid || '%',
      actcd: this.state.actcd || '%',
      equpcd: this.state.equpcd || '%',
      state: '%',
      gubun: 'a4',
    });
  }


  getItemColor(item: PlanModel): string {
    if (item.state === '1') {
      return 'var(--color-red)';
    }

    return 'var(--color-blue)';
  }

  getItemPrefix(item: PlanModel): string {
    if (item.state === '1') {
      return '계획';
    }

    return '완료';
  }


  // Modal Detail
  @action
  async openModalDetail(date: string, reset = true) {
    const { actionStore: api } = this.props;

    this.detailDate = date;
    this.tableDetailFocusIndex = 0;
    this.tableActFocusIndex = 0;

    if (reset) {
      this.tableActFocusActcd = '';
    }

    await this.SS({
      dataAct: [],
      dataDetail: [],
      isVisibleModalDetail: true,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityAct = new InfinityRetrieve(
      {
        sub: 'w_popup_e035_callrender',
        plandate: this.detailDate,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          dataAct: [
            ...this.state.dataAct,
            ...items.map((item) => new PlanActModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataAct: [],
        });
        await this.infinityAct?.retrieveAll();
        await this.tableAct.current?.update(true);
        if (this.state.dataAct.length > 0) {
          this.tableAct.current?.setFocus(this.tableActFocusIndex);
        }
      },
    );

    await this.infinityAct?.retrieveAll();
    await this.tableAct.current?.update(true);

    if (this.tableActFocusActcd) {
      this.tableActFocusIndex = this.state.dataAct.findIndex((x) => this.tableActFocusActcd === x.actcd);
    }

    if (this.state.dataAct.length > this.tableActFocusIndex) {
      this.tableAct.current?.setFocus(this.tableActFocusIndex);
    }
  }

  @action
  async saveModalDetail() {
    const { actionStore: api } = this.props;
    await api.save({
      sub: 'w_popup_e035_callrender',
      items: this.state.dataDetail,
    }, false);

    if (this.state.dataDetail.length) {
      this.tableActFocusActcd = this.state.dataDetail[0].actcd;
    }

    await this.onRetrieveEvent();
    await this.openModalDetail(this.detailDate, false);
  }

  @action
  async onTableModalActFocused(index: number) {
    const { actionStore: api } = this.props;

    this.tableActFocusIndex = index;
    await this.SS({
      dataDetail: [],
    });

    const focused = this.tableActFocusIndex > -1 && this.tableActFocusIndex < this.state.dataAct.length
      ? this.state.dataAct[this.tableActFocusIndex]
      : undefined;

    if (focused?.isNew) return;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityDetail = new InfinityRetrieve(
      {
        sub: 'w_popup_e035_callrender',
        plandate: this.detailDate,
        actcd: this.state.dataAct[index].actcd,
      },
      (params) => api.fxExec('dw_list_RowFocuschanged', params),
      (items) => {
        this.SS({
          dataDetail: [
            ...this.state.dataDetail,
            ...items.map((item) => new PlanModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataDetail: [],
        });
        this.tableDetailFocusIndex = 0;
        await this.infinityDetail?.retrieveAll();
        await this.tableDetail.current?.update(true);
        if (this.state.dataDetail.length > 0) {
          this.tableDetail.current?.setFocus(0, 1);
        }
      },
    );

    this.tableDetailFocusIndex = 0;
    await this.infinityDetail?.retrieveAll();
    await this.tableDetail.current?.update(true);
    if (this.state.dataDetail.length > 0) {
      this.tableDetail.current?.setFocus(0, 1);
    }
  }

  onTableModalDetailFocused(index: number) {
    this.tableDetailFocusIndex = index;
  }

  @action
  async addModalAct() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      sub: 'w_popup_e035_callrender',
      plandate: this.detailDate,
    });

    if (data) {
      await this.SS({
        dataAct: [new PlanActModel(data, true), ...this.state.dataAct],
        dataDetail: [],
      });

      await this.tableAct.current?.update(true);
      await this.tableAct.current?.setFocus(0, 0);
      await this.tableDetail.current?.update(true);
      await this.tableDetail.current?.setFocus(0, 0);
    }
  }

  @action
  async removeModalAct() {
    const item = this.tableActFocusIndex > -1 && this.tableActFocusIndex < this.state.dataAct.length
      ? this.state.dataAct[this.tableActFocusIndex]
      : undefined;

    if (item?.isNew) {
      await this.nextModalAct();
      return;
    }

    if (!ConfirmWarning.assert(item, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${Date8.withDash(item?.plandate)} ${item?.actnm}`;

    if (await api.delete(text, {
      sub: 'w_popup_e035_callrender',
      plandate: this.detailDate,
      actcd: item?.actcd,
    })) {
      await this.nextModalAct();
    }
    await this.tableAct.current?.update(true);
  }

  @action
  async nextModalAct() {
    await this.SS({
      dataAct: update(this.state.dataAct, {
        $splice: [[this.tableActFocusIndex, 1]],
      }),
      dataDetail: [],
    });

    // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
    if (this.state.dataAct.length) {
      const next = this.tableActFocusIndex === 0 ? 0 : this.tableActFocusIndex - 1;
      this.tableAct.current?.setFocus(next);
    } else {
      this.tableActFocusIndex = 0;
    }

    await this.tableDetail.current?.update(true);
    await this.tableAct.current?.update(false);
  }

  @action
  async removeModalDetail() {
    const item = this.tableDetailFocusIndex > -1 && this.tableDetailFocusIndex < this.state.dataDetail.length
      ? this.state.dataDetail[this.tableDetailFocusIndex]
      : undefined;

    if (!ConfirmWarning.assert(item, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${item?.actnm} ${item?.equpnm}`;

    if (await api.fxDelete('delete_equpcd', text, {
      sub: 'w_popup_e035_callrender',
      plandate: this.detailDate,
      actcd: item?.actcd,
      equpcd: item?.equpcd,
    })) {
      await this.SS({
        dataDetail: update(this.state.dataDetail, {
          $splice: [[this.tableDetailFocusIndex, 1]],
        }),
      });

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.dataDetail.length) {
        const next = this.tableDetailFocusIndex === 0 ? 0 : this.tableDetailFocusIndex - 1;
        this.tableAct.current?.setFocus(next);
      } else {
        this.tableDetailFocusIndex = 0;
      }

      await this.tableDetail.current?.update(false);
      await this.onRetrieveEvent();
    }
  }

  @action
  async updateNewAct(rowUpdate: RowUpdate<PlanActModel>, _: string, actcd: string) {
    rowUpdate({ new: '0' });
    await this.openModalEqup(actcd);
  }

  @action
  async updateAct(key: string, value: string) {
    const { actionStore: api } = this.props;
    await api.fxExec('dw_list_itemchanged', {
      sub: 'w_popup_e035_callrender',
      [key]: value,
      itemname: key,
      data: value,
      items: this.state.dataDetail.map((x) => new PlanModel({
        ...x,
        plandate: key === 'plandate' ? value : this.detailDate,
      })),
    }, false);

    await this.openModalDetail(this.detailDate);
    await this.tableDetail.current?.update(true);
    await ConfirmSuccess.show('완료', '완료되었습니다');
    await this.onRetrieveEvent();
  }


  // Modal Equp
  @action
  async openModalEqup(actcd: string) {
    const { actionStore: api } = this.props;
    this.equpActcd = actcd;

    await this.SS({
      dataEqup: [],
    });
    await this.tableEqup.current?.update(true);

    // 무한 스크롤바 헬퍼 초기화
    this.infinityEqup = new InfinityRetrieve(
      {
        sub: 'w_popup_e073',
        plandate: this.detailDate,
        actcd: this.equpActcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          dataEqup: [
            ...this.state.dataEqup,
            ...items.map((item) => new PlanModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataEqup: [],
        });
        await this.infinityEqup?.retrieveAll();
        await this.tableEqup.current?.update(true);
      },
    );

    await this.infinityEqup?.retrieveAll();

    if (this.state.dataEqup.length) {
      await this.SS({ isVisibleModalEqup: true });
      await this.tableEqup.current?.update(true);
    }
  }

  @action
  async saveModalEqup() {
    const { actionStore: api } = this.props;

    const item = this.tableActFocusIndex > -1 && this.tableActFocusIndex < this.state.dataAct.length
      ? this.state.dataAct[this.tableActFocusIndex]
      : undefined;
    if (!ConfirmWarning.assert(item, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const completionDates: string[] = [];
    const movedEqups: string[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const x of this.state.dataEqup) {
      if (x.chk === '1') {
        const existDates = this.state.data.filter((y) => y.data.actcd === x.actcd);
        if (existDates.length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const z of existDates) {
            // eslint-disable-next-line no-await-in-loop
            const dayChks = await api.fxExec('dw_list_RowFocuschanged', {
              sub: 'w_popup_e035_callrender',
              plandate: z.date,
              actcd: z.data.actcd,
            });

            const exists = dayChks?.items?.find((y: any) => y.actcd === x.actcd && y.equpcd === x.equpcd);
            if (exists) {
              // eslint-disable-next-line no-await-in-loop
              const result = await Confirm.ask(
                '확인',
                // eslint-disable-next-line no-plusplus
                `선택된 ${x.equpnm}호기는 ${Date8.withDash(exists.plandate)} 에 이미 있습니다! 선택한 날짜로 옮기시겠습니까?`,
                '예',
                '아니오',
              );

              if (result === AskType.YES) {
                movedEqups.push(x.equpcd);
                completionDates.push(exists.plandate);
              } else if (result === AskType.NO) {
                x.chk = '0';
              }
            }
          }
        }
      }
    }

    let i = 0;
    if (await api.save({
      sub: 'w_popup_e073',
      plandate: this.detailDate,
      actcd: this.equpActcd,
      items: this.state.dataEqup.filter((x) => x.chk === '1').map((x) => new PlanModel({
        ...x,
        // eslint-disable-next-line no-plusplus
        rptdate: movedEqups.indexOf(x.equpcd) > -1 ? completionDates[i++] : x.rptdate,
      }, x.isNew)),
      gubun: 'e035',
    }, false, false)) {
      const duplicatedRowsRemoved = this.state.dataAct.filter((x, i) => {
        if (i < this.tableActFocusIndex) {
          this.tableActFocusIndex -= 1;
        }
        return x.actcd !== this.equpActcd || this.tableActFocusIndex === i;
      });

      i = 0;
      await this.SS({
        isVisibleModalEqup: false,
        dataDetail: this.state.dataEqup.filter((x) => x.chk === '1').map((x) => new PlanModel({
          ...x,
          plandate: this.detailDate,
          planmon: this.detailDate.substr(0, 6),
          // eslint-disable-next-line no-plusplus
          rptdate: movedEqups.indexOf(x.equpcd) > -1 ? completionDates[i++] : x.rptdate,
          actcd: item?.actcd,
          actnm: item?.actnm,
          sendflag: '0',
          state: '1',
        })),
        dataAct: duplicatedRowsRemoved,
        dataEqup: [],
      });
      this.tableDetailFocusIndex = 0;
      await this.tableAct.current?.update(false);
      await this.tableDetail.current?.update(true);
      await this.tableDetail.current?.setFocus(0);
    }
  }

  @action
  async toggleEqupChk(value: boolean) {
    await this.SS({
      equpChkToggle: value,
      dataEqup: this.state.dataEqup.map((x) => new PlanModel({
        ...x,
        chk: value ? '1' : '0',
      })),
    });

    await this.tableEqup.current?.update(false);
  }


  // Modal Yet
  @action
  async openModalYet() {
    const { actionStore: api } = this.props;

    await this.SS({
      dataYet: [],
      isVisibleModalYet: true,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityYet = new InfinityRetrieve(
      {
        sub: 'w_popup_e035_callrender_not',
        stmon: this.state.planmon,
        divicd: this.state.divicd || '%',
        perid: this.state.perid || '%',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          dataYet: [
            ...this.state.dataYet,
            ...items.map((item) => new PlanYetModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataYet: [],
        });
        await this.infinityYet?.retrieveAll();
        await this.tableYet.current?.update(true);
        await this.tableYet.current?.setFocus(0);
      },
    );

    await this.infinityYet?.retrieveAll();
    await this.tableYet.current?.update(true);
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.dataYet?.forEach((x: any) => {
      checkData.push(new PlanYetModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      dataYet: [
        ...checkData,
      ],
    }, async () => {
      await this.tableYet.current?.update(false);
    });
  }

  @action
  async saveModalYet() {
    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_e035_callrender_not',
      planmon: this.state.planmon,
      items: this.state.dataYet,
    }, false)) {
      await this.SS({
        dataYet: [],
        isVisibleModalYet: false,
      });

      await this.onRetrieveEvent();
    }
  }


  // Modal Expire
  @action
  async openModalExpire() {
    const { actionStore: api } = this.props;

    await this.SS({
      dataExpire: [],
      isVisibleModalExpire: true,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityExpire = new InfinityRetrieve(
      {
        sub: 'w_popup_e035_callrender_contg',
        stmon: this.state.planmon,
        divicd: this.state.divicd || '%',
        perid: this.state.perid || '%',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          dataExpire: [
            ...this.state.dataExpire,
            ...items.map((item) => new PlanExpireModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataExpire: [],
        });
        await this.infinityExpire?.retrieveAll();
        await this.gridExpire.current?.setFocus(0);
      },
    );

    await this.infinityExpire?.retrieveAll();
  }


  // Modal charge
  @action
  async openModalCharge() {
    this.setState({
      isChargeModalVisible: true,
    }, () => this.chargeRetrieve());
  }

  @action
  async closeModalCharge() {
    this.setState({
      popupChargeList: [],
      isGraphVisible: false,
      isChargeModalVisible: false,
    });
  }

  @action
  async chargeRetrieve() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    await this.SS({
      graph: `https://api.elmansoft.com/chart/stick2.php?database=weberp&window=w_popup_e033w&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_mon=${this.state.popupStmon}`,
    });

    const data = await api.retrieve(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );

    if (data?.items) {
      await this.SS({
        isGraphVisible: true,
        popupChargeList: data.items?.map((x: any) => new ChargePopupModel({ open: '0', ...x })),
      });
    } else {
      await this.SS({
        isGraphVisible: true,
        popupChargeList: [],
      });
    }
  }

  @action
  async onButtonClicked(type:any, item:any) {
    const { actionStore: api } = this.props;
    let data = {
      new: undefined,
      items: [],
    };
    const params = {
      sub: 'w_popup_e033w',
      itemname: JournalButtonClickedTypeNames[type],
      data: '',
    };

    if (type === JournalButtonClickedTypes.ADD) {
      data = await api.fxExec(
        'dw_list_buttonclikced',
        {
          ...params,
          divicd: item.divicd,
          stmon: this.state.popupStmon,
        },
      );

      if (data?.items) {
        const focusedIndex = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, true),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, true),
            ...data?.items.map((x: any) => new ChargePopupModel(x)),
            ...this.state.popupChargeList.slice(focusedIndex + 1),
          ],
        });
      }
    }

    if (type === JournalButtonClickedTypes.MINUS) {
      // 버튼을 누른 행의 인덱스 추출
      const focusedIndex:number = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

      // 버튼을 누른 행 다음에 있는 첫번째 부서장 모델 추출
      const model = this.state.popupChargeList.slice(focusedIndex + 1).find((x) => x.chk === '0');

      // 버튼을 누른 행의 다음 부서장 인덱스 추출
      const deleteIndex = this.state.popupChargeList.findIndex((x) => x.divicd === model?.divicd);
      if (deleteIndex > -1) {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
            ...this.state.popupChargeList.slice(deleteIndex),
          ],
        });
      } else {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
          ],
        });
      }
    }
  }

  @action
  onChargeRowFocusEvent(item: ChargePopupModel) {
    this.setState({ focusedCharge: item });
  }

  @action
  async printCharge() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );
  }


  render() {
    return <PlanTemplate
      scope={this}
    />;
  }
}
