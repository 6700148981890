import * as React from 'react';
import { WiSnowflakeCold } from 'react-icons/wi';
import { ReactNode } from 'react';
import style from './LabelBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import { LayoutProps } from '../../../constants';
import { FlexLayout, DefaultLayout } from '../../layout';

interface LabelBoxActions {
  onClick?: () => any;
  onDblClick?: () => any;
}

interface LabelBoxProps extends LabelBoxActions, LayoutProps {
  title?: string;
  isNeed?: boolean;
  footer?: ReactNode;
}

export class LabelBox extends React.Component<LabelBoxProps> {
  render() {
    return (
      <FlexLayout
        className={JoinClassName.make([style.container, this.props.className])}
        style={this.props.style}
        weight={this.props.weight}
        size={this.props.size}
        isVertical={true}
      >
        {this.props.title && <DefaultLayout className={style.title} size={22}>
          <span style={this.props.style}>{this.props.title}</span>
          {this.props.isNeed && (
            <span className={style.need}>
              <WiSnowflakeCold />
            </span>
          )}
          {this.props.footer && (
            <FlexLayout
              className={style.footer}
              size={42}
              justify="end"
            >
              {this.props.footer}
            </FlexLayout>
          )}
        </DefaultLayout>}
        <FlexLayout
          className={style.textField}
          style={{
            paddingTop: this.props.title ? 4 : 8,
          }}
        >
          {this.props.children}
        </FlexLayout>
      </FlexLayout>
    );
  }
}
