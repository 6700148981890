import * as React from 'react';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  FormatTextBox,
  ComboBox,
  ComboBoxModel,
  OptionBox,
  LabelBox,
  LayoutTitle,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { RetrieveElevator } from './RetrieveElevator';
/**
 * 화면
 * @window w_tb_e101w_04
 * @category 유지보수해지현황
 */
export const RetrieveElevatorTemplate: React.FC<TemplateProps<RetrieveElevator>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout>
          <span>선택</span>
          <div>
            <OptionBox
              value={scope.state.gubun}
              data={[
                { value: '0', remark: '건물명' },
                { value: '1', remark: '승강기번호' },
              ]}
              onChange={(item) => setData({ gubun: item.value })}
            />
          </div>
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>시도</span>
          <ComboBox
            value={scope.state?.sido}
            textAlign="left"
            data={scope.state?.sidos?.map((y) => new ComboBoxModel(y.sidocd, y.sidonm)) || ''}
            onSelect={
              (option) => setData({ sido: option.value }, () => {
                scope.onSigunguRetrieveEvent();
              })
            }
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>군구</span>
          <ComboBox
            textAlign="left"
            value={scope.state?.sigungu}
            data={scope.state?.sigungus?.map((y) => new ComboBoxModel(y.sigungucd, y.sigungunm))}
            onSelect={
              (option) => setData({ sigungu: option.value }, () => {

              })
            }
          />
        </FlexLayout>
        <FlexLayout>
          <span>건물명</span>
          <TextBox
            textAlign="left"
            value={scope.state.buldnm}
            onChange={(value) => setData({ buldnm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>승강기번호</span>
          <FormatTextBox
            format="####-###"
            textAlign="center"
            value={scope.state.elno}
            onChange={(value) => setData({ elno: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          header={[
            {
              id: 'buldnm',
              text: '건물명',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.buldnm}
                </FlexLayout>
              ),
            },
            {
              id: 'mntcpnynm',
              text: '유지보수업체명',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.mntcpnynm}
                </FlexLayout>
              ),
            },
            {
              id: 'elevatorno',
              text: '승강기번호',
              width: 5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.elevatorno?.substr(0, 4)}-{x.elevatorno?.substr(4, 3)}
                </FlexLayout>
              ),
            },
          ]}
          ref={scope.grid}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
        <FlexLayout
          weight={2}
          isVertical={true}
          >
          <LayoutTitle>
            <FlexLayout>
              승강기 기본정보
            </FlexLayout>
          </LayoutTitle>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout>
              <LabelBox
                title="건물명"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.buldnm}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout weight={0.25}>
              <LabelBox
                title="승강기번호"
              >
                <FormatTextBox
                  format="####-###"
                  textAlign="center"
                  value={scope.state.focusedData?.elevatorno}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout weight={0.75}>
              <LabelBox
                title="유지보수업체명"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.mntcpnynm}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout>
              <LabelBox
                title="소재지1"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.address1}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="소재지2"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.address2}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout>
              <LabelBox
                title="운행유효시작일"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.applcbedt}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="운행유효종료"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.applcendt}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="지역번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.areano}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="건물관리번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.buldmgtno1}
                />
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.buldmgtno2}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout weight={2}>
              <LabelBox
                title="건물용도명"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.buldprpos}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout>
              <LabelBox
                title="지상운행층수"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.divgroundfloorcnt}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="지하운행층수"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.divundgrndfloorcnt}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="운행층수"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.shuttlefloorcnt}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="승강기구분명"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrdivnm}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="승강기형식명"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrformnm}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="승강기종류명"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrkindnm}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout>
              <LabelBox
                title="승강기상태"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrstts}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="승강기관리번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrmgtno1}
                />
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrmgtno2}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="모델명"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrmodel}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="승강기재개일"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrresmptde}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="설치일자"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.installationde}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="설치장소"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.installationplace}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout weight={2}>
              <LabelBox
                title="검사기관"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.inspctlnstt}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="최종검사일"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.lastinspctde}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="최종검사종류"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.lastinspctkind}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="최종검사결과"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.lastresultnm}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="승강기호기"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.elvtrasignno}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout weight={2}>
              <LabelBox
                title="제조업체명"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.manufacturename}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="제조업체번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.manufacturetelno}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="제조업체코드"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.mnfcturcpnycd}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="보수업체번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.mntcpnytelno}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="보수업체코드"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.mntcpnycd}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout weight={2}>
              <LabelBox
                title="관리주체명"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.partcpntnm}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="관리주체번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.partcpnttelno}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="최대정원"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.ratedcap}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="정격속도"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.ratedspeed}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="적재하중"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.liveload}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <FlexLayout weight={2}>
              <LabelBox
                title="운행관리자SN"
              >
                <TextBox
                  textAlign="left"
                  value={scope.state.focusedData?.shuttlemngrnm}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="운행관리자번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.shuttlemngrtelno}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="운행구간"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.shuttlesection}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="시/군/구코드"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.sigungucd}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <LabelBox
                title="우편번호"
              >
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.zipcd1}
                />
                <TextBox
                  textAlign="center"
                  value={scope.state.focusedData?.zipcd2}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

    </FlexLayout>
  );
};
