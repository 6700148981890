import * as React from 'react';
import { ReactNode, RefObject } from 'react';
import { inject, observer } from 'mobx-react';
import { action, computed } from 'mobx';
import { List, ListRowProps } from 'react-virtualized';
import { MdChevronLeft, MdChevronRight } from 'react-icons/all';
import {
  Menu,
  Item,
  Separator,
  useContextMenu,
  ItemParams,
} from 'react-contexify';
import _ from 'lodash';
import { FlexLayout } from '../FlexLayout';
import {
  ConfirmType,
  Global,
  GridLayoutHeader,
  GridLayoutProps,
} from '../../../constants';
import style from './GridLayout.module.scss';
import { Format, JoinClassName, Sha256 } from '../../../utils/string';
import { Confirm } from '../../../utils/confirm';
import { AutoPadding } from '../../../utils/layout';
import 'react-contexify/dist/ReactContexify.css';
import { Sort } from '../../../utils/array';
import { PageComponent } from '../../../utils';

enum MENU_ACTION {
  SORT_ASC,
  SORT_DESC,
  LOCK,
  UNLOCK,
}

interface GridLayoutState {
  isLoaded: boolean;
  focusedIndex: number;
  data: Array<ReactNode>;
  fixedData: Array<ReactNode>;
  railThumbWidth: number;
  isHorizontalMouseActive: boolean;
  isVisibleHorizontalScroll: boolean;
  isListVerticalScroll: boolean;
  colLock: number;
}

@inject('publicStore')
@observer
export class GridLayout extends PageComponent<
  GridLayoutProps,
  GridLayoutState
> {
  mainList: RefObject<List>;

  refLockList: RefObject<List>;

  data: any[];

  triggeredOnBottom: boolean;

  group: Array<string>;

  isEnabledGroup: boolean;

  hasTrail: boolean;

  totalWidth: number;

  gridWidth: number;

  gridHeight: number;

  perPxWidth: number;

  scrollTop: number;

  virtualInnerHeight: number;

  littleWidth: number;

  prevFocusedIndex?: number;

  scrollHeader: RefObject<HTMLDivElement>;

  scrollTrail: RefObject<HTMLDivElement>;

  scrollBody: RefObject<HTMLDivElement>;

  scrollHorizontalRail: RefObject<HTMLDivElement>;

  scrollHorizontalRailThumb: RefObject<HTMLDivElement>;

  /**
   * 하단 스크롤바 위에서 움직이는 녀석의 현재 위치
   */
  scrollHorizontalRailThumbX: number;

  /**
   * 하단 스크롤바의 최대 이동 범위
   */
  maxScrollHorizontalRailThumbX: number;

  /**
   * 하단 스크롤바가 1px 움직일때 본문의 X축이 얼만큼 움직일지
   */
  perPxScrollHorizontalRailThumbX1px: number;

  constructor(props: GridLayoutProps, context?: any) {
    super(props, context);
    this.mainList = React.createRef();
    this.refLockList = React.createRef();
    this.scrollHeader = React.createRef();
    this.scrollTrail = React.createRef();
    this.scrollBody = React.createRef();
    this.scrollHorizontalRail = React.createRef();
    this.scrollHorizontalRailThumb = React.createRef();
    this.scrollTop = 0;
    this.littleWidth = 0;
    this.virtualInnerHeight = 0;
    this.totalWidth = 1;
    this.gridWidth = 1;
    this.gridHeight = 1;
    this.perPxWidth = 1; // 화면보다 내용물이 적을 경우 100% 맞추기 위해서
    this.triggeredOnBottom = false;
    this.group = props.header.filter((x) => x.group).map((x) => x.id);
    this.isEnabledGroup = this.group.length > 0;
    this.hasTrail = props.header.filter((x) => x.trail).length > 0;
    this.scrollHorizontalRailThumbX = 0;
    this.maxScrollHorizontalRailThumbX = 0;
    this.perPxScrollHorizontalRailThumbX1px = 0;
    this.data = props.data;

    this.state = {
      isLoaded: false,
      focusedIndex: 0,
      railThumbWidth: 100,
      data: [],
      fixedData: [],
      isHorizontalMouseActive: false,
      isVisibleHorizontalScroll: false,
      isListVerticalScroll: false,
      colLock: 0,
    };
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(
    prevProps: Readonly<GridLayoutProps>,
    _prevState: Readonly<GridLayoutState>,
    _snapshot?: any,
  ): void {
    if (Sha256.make(JSON.stringify(this.props.header.map((x) => x.id)))
      !== Sha256.make(JSON.stringify(prevProps.header.map((x) => x.id)))) this.init();
    else if (this.props.data?.length !== prevProps.data?.length) {
      this.forceRepaint(
        !this.props.data?.length
        || !prevProps.data?.length
        || Sha256.make(JSON.stringify(this.props.data[0])) !== Sha256.make(JSON.stringify(prevProps.data[0])),
      );
    }
  }

  async forceRepaint(reset: boolean = false) {
    requestAnimationFrame(async () => {
      reset && await this.SS({ data: [] });
      await this.preRender();
    });
  }

  async init() {
    this.setState({
      isLoaded: false,
    }, () => {
      setTimeout(() => {
        const { rowHeight } = this.props;
        const data = this.props.data || [];
        this.triggeredOnBottom = false;
        this.prevFocusedIndex = undefined;
        this.group = this.props.header.filter((x) => x.group).map((x) => x.id);
        this.isEnabledGroup = this.group.length > 0;
        this.hasTrail = this.props.header.filter((x) => x.trail).length > 0;

        // horizontal scrollbar
        this.scrollHorizontalRailThumbX = 0;
        this.maxScrollHorizontalRailThumbX = 0;
        this.perPxScrollHorizontalRailThumbX1px = 0;
        const isListVerticalScroll = data.length * rowHeight > (this.scrollBody.current?.clientHeight || 0);

        let totalWidth = this.visibleHeaders.reduce(
          (p, c) => p + (c?.width || 100), 0,
        );

        let clientWidth = this.scrollHeader.current?.clientWidth || 0;
        const railWidth = this.scrollHorizontalRail.current?.clientWidth
          || Math.round(clientWidth / 3) - 40;
        let railThumbWidth = Math.round((railWidth * clientWidth) / totalWidth);
        let isVisibleHorizontalScroll = false;

        totalWidth = this.props.contentWidth ?? totalWidth;

        if (clientWidth - totalWidth > 0) {
          railThumbWidth = railWidth;
          this.maxScrollHorizontalRailThumbX = 0;
          this.perPxScrollHorizontalRailThumbX1px = 0;

          const tWidth = clientWidth + (isListVerticalScroll ? -12 : 0);
          this.perPxWidth = tWidth / totalWidth;
          this.littleWidth = tWidth - this.visibleHeaders.reduce(
            (p, c) => p + Math.round((c?.width || 100) * this.perPxWidth), 0,
          );
        } else {
          isVisibleHorizontalScroll = true;
          this.perPxWidth = 1;
          this.maxScrollHorizontalRailThumbX = railWidth - railThumbWidth;
          this.perPxScrollHorizontalRailThumbX1px = Math.ceil(
            (totalWidth + (isListVerticalScroll ? 12 : 0) - clientWidth) / this.maxScrollHorizontalRailThumbX,
          );
          if (isListVerticalScroll) clientWidth += 12;
        }
        //

        this.gridWidth = clientWidth;
        this.gridHeight = this.scrollBody.current?.clientHeight || 0;
        this.totalWidth = totalWidth;

        this.setState({
          isLoaded: true,
          railThumbWidth,
          isListVerticalScroll,
          isVisibleHorizontalScroll,
        }, () => {
          requestAnimationFrame(() => {
            this.virtualInnerHeight = (this.scrollBody.current?.clientHeight || 0);
            this.preRender();
          });
        });
      }, 10);
    });
  }

  @action
  private async preRender(sorted?: Array<any>): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.state.isLoaded) {
        resolve();
        return;
      }

      const data: ReactNode[] = [];
      const fixedData: ReactNode[] = [];
      const {
        header,
        onRowClick,
        onRowDoubleClick,
        rowHeight,
      } = this.props;
      this.data = sorted || this.props.data || [];
      this.gridHeight = this.scrollBody.current?.clientHeight || 0;
      this.hasTrail = this.props.header.filter((x) => x.trail).length > 0;

      const isListVerticalScroll = this.data.length * rowHeight > (this.scrollBody.current?.clientHeight || 0);
      if (this.state.isVisibleHorizontalScroll) {
        this.gridWidth = (this.scrollHeader.current?.clientWidth || 0) + (isListVerticalScroll ? 12 : 0);
      } else {
        const tWidth = (this.scrollHeader.current?.clientWidth || 0) + (isListVerticalScroll ? -12 : 0);
        this.perPxWidth = tWidth / this.totalWidth;
        this.littleWidth = tWidth - this.visibleHeaders.reduce(
          (p, c) => p + Math.round((c?.width || 100) * this.perPxWidth), 0,
        );
      }

      header.forEach((x) => {
        if (x.value) {
          this.data.forEach((y) => {
            // eslint-disable-next-line no-param-reassign
            y[x.id] = x.value!(y, this.props.scope);
          });
        }
      });

      for (let i = 0; i < this.data.length; i += 1) {
        if (!this.isEnabledGroup && this.state.data[i] && i !== this.state.focusedIndex && i !== this.prevFocusedIndex) {
          data.push(this.state.data[i]);
          fixedData.push(this.state.fixedData[i]);
          // eslint-disable-next-line no-continue
          continue;
        }

        const item = this.data[i];
        const isGrouped = this.isGrouped(item, i);
        const calcBackgroundColor = this.props.rowColor ? this.props.rowColor(item) : '';
        const backgroundColor = calcBackgroundColor || (i % 2 === 0 ? '#fafafa' : '#f2f2f2');

        data.push(<FlexLayout
          onClick={async () => {
            if (this.state.focusedIndex === i) return;

            if (this.data[this.state.focusedIndex]?.new === '1') {
              const warning = await Confirm.show(
                '경고',
                '저장하지 않은 데이터는 사라집니다.\n다른 행으로 넘어가시겠습니까?',
                ConfirmType.QUESTION,
              );

              if (!warning) return;
            }

            this.prevFocusedIndex = this.state.focusedIndex;
            this.setState({ focusedIndex: i }, () => this.preRender());
            onRowClick && onRowClick(item, i);
          }}
          onDoubleClick={() => onRowDoubleClick && onRowDoubleClick(item, i)}
          className={this.state.focusedIndex === i ? style.highlight : undefined}
        >
          {this.visibleHeaders.map((x: GridLayoutHeader, y) => {
            const width = Math.round((x.width || 100) * this.perPxWidth)
              + (y === 0 ? this.littleWidth : -2);

            // eslint-disable-next-line no-nested-ternary
            const rowItem = x.render ? (
              // eslint-disable-next-line no-nested-ternary
              x.group ? (isGrouped ? <div/> : x.render(item, this.props.scope)) : x.render(item, this.props.scope)) : (
              <FlexLayout align="center" justify="center">
                {/* eslint-disable-next-line no-nested-ternary */}
                {x.group ? (isGrouped ? <div/> : item[x.id]) : item[x.id]}
              </FlexLayout>);

            return (<FlexLayout
              key={x.id}
              style={{
                width,
                maxWidth: width,
                minWidth: width,
                backgroundColor,
              }}
            >
              {AutoPadding.make(rowItem)}
            </FlexLayout>);
          })}
        </FlexLayout>);

        if (this.isEnabledGroup && this.isGroupTail(item, i)) {
          data.push(<FlexLayout
            key={`${i}_sum`}
            className={style.group}
          >
            {this.visibleHeaders.map((x: GridLayoutHeader, y) => {
              const width = Math.round((x.width || 100) * this.perPxWidth)
                + (y === 0 ? this.littleWidth : -2);

              return (<FlexLayout
                key={x.id}
                style={{
                  width,
                  maxWidth: width,
                  minWidth: width,
                }}
              >
                {AutoPadding.make(x.sum && x.sum(item, this.data, this.props.scope))}
              </FlexLayout>);
            })}
          </FlexLayout>);
        }

        // 틀 고정시 사용할 미리 만든 행
        fixedData.push(<FlexLayout
          onClick={async () => {
            if (this.state.focusedIndex === i) return;

            if (this.data[this.state.focusedIndex]?.new === '1') {
              const warning = await Confirm.show(
                '경고',
                '저장하지 않은 데이터는 사라집니다.\n다른 행으로 넘어가시겠습니까?',
                ConfirmType.QUESTION,
              );

              if (!warning) return;
            }

            this.prevFocusedIndex = this.state.focusedIndex;
            this.setState({ focusedIndex: i }, () => this.preRender());
            onRowClick && onRowClick(item, i);
          }}
          onDoubleClick={() => onRowDoubleClick && onRowDoubleClick(item, i)}
          className={this.state.focusedIndex === i ? style.highlight : undefined}
        >
          {this.visibleHeaders.slice(0, this.state.colLock).filter((x) => !x.isHidden).map((x: GridLayoutHeader, y) => {
            const width = Math.round((x.width || 100) * this.perPxWidth)
              + (y === 0 ? this.littleWidth : -2);

            // eslint-disable-next-line no-nested-ternary
            const rowItem = x.render ? (
              // eslint-disable-next-line no-nested-ternary
              x.group ? (isGrouped ? <div/> : x.render(item, this.props.scope)) : x.render(item, this.props.scope)) : (
              <FlexLayout align="center" justify="center">
                {/* eslint-disable-next-line no-nested-ternary */}
                {x.group ? (isGrouped ? <div/> : item[x.id]) : item[x.id]}
              </FlexLayout>);

            return (<FlexLayout
              key={x.id}
              style={{
                width,
                maxWidth: width,
                minWidth: width,
                backgroundColor,
              }}
            >
              {AutoPadding.make(rowItem)}
            </FlexLayout>);
          })}
        </FlexLayout>);

        if (this.isEnabledGroup && this.isGroupTail(item, i)) {
          fixedData.push(<FlexLayout
            key={`${i}_sum`}
            className={style.group}
          >
            {this.visibleHeaders.slice(0, this.state.colLock).filter((x) => !x.isHidden).map((x: GridLayoutHeader, y) => {
              const width = Math.round((x.width || 100) * this.perPxWidth)
                + (y === 0 ? this.littleWidth : -2);

              return (<FlexLayout
                key={x.id}
                style={{
                  width,
                  maxWidth: width,
                  minWidth: width,
                }}
              >
                {AutoPadding.make(x.sum && x.sum(item, this.data, this.props.scope))}
              </FlexLayout>);
            })}
          </FlexLayout>);
        }
      }

      this.setState({
        data,
        fixedData,
        isListVerticalScroll,
        colLock: this.state?.colLock || this.props?.columnLockIndex || 0,
      }, async () => {
        await this.mainList.current?.forceUpdateGrid();
        await this.refLockList.current?.forceUpdateGrid();
        resolve();
      });
    });
  }

  @computed
  get visibleHeaders(): Array<GridLayoutHeader> {
    return this.props.header.filter((k) => !k.isHidden);
  }

  public setFocus(index: number) {
    setTimeout(() => {
      this.prevFocusedIndex = this.state.focusedIndex;
      this.setState({ focusedIndex: index }, async () => {
        await this.preRender();
        this.props.onRowClick && this.props.onRowClick(this.data[index], index);
        requestAnimationFrame(() => {
          this.mainList.current?.scrollToRow(index);
          this.refLockList.current?.scrollToRow(index);
        });
      });
    }, 500);
  }

  private isGrouped(item: any, i: number): boolean {
    let result = true;

    if (i === 0) {
      return false;
    }

    this.group.forEach((x) => {
      if (this.data[i - 1][x] !== item[x]) {
        result = false;
      }
    });

    return result;
  }

  private isGroupTail(item: any, i: number): boolean {
    let result = false;

    if (i === this.data.length - 1) {
      return true;
    }

    this.group.forEach((x) => {
      if (this.data[i + 1][x] !== item[x]) {
        result = true;
      }
    });

    return result;
  }

  private onHorizontalDragging(e: React.MouseEvent<HTMLDivElement>): void {
    if (e.buttons === 1 && !this.state.isHorizontalMouseActive) {
      this.setState({ isHorizontalMouseActive: true });
      e.preventDefault();
      e.stopPropagation();
    }
  }

  private onDeltaXEvent(value: number): void {
    if (this.scrollHorizontalRailThumb.current) {
      let toThumbLeft = this.scrollHorizontalRailThumbX + value;
      if (toThumbLeft < 0) toThumbLeft = 0;
      if (toThumbLeft > this.maxScrollHorizontalRailThumbX) {
        toThumbLeft = this.maxScrollHorizontalRailThumbX;
      }

      this.scrollHorizontalRailThumbX = toThumbLeft;
      this.scrollHorizontalRailThumb.current.style.transform = `translateX(${toThumbLeft}px)`;

      const toBothScrollLeft = this.perPxScrollHorizontalRailThumbX1px * toThumbLeft;

      if (this.scrollHeader.current) this.scrollHeader.current.scrollLeft = toBothScrollLeft;
      if (this.scrollTrail.current) this.scrollTrail.current.scrollLeft = toBothScrollLeft;
      if (this.mainList.current) {
        this.mainList.current.Grid?.scrollToPosition({
          scrollLeft: toBothScrollLeft,
          scrollTop: this.scrollTop,
        });

        this.props.onHorizontalScroll && this.props.onHorizontalScroll(toBothScrollLeft);
      }
    }
  }

  private onMouseMove(e: React.MouseEvent<HTMLDivElement>): void {
    if (e.buttons === 1 && this.state.isHorizontalMouseActive) {
      this.onDeltaXEvent(e.movementX);
      e.preventDefault();
      e.stopPropagation();
    } else if (this.state.isHorizontalMouseActive) {
      requestAnimationFrame(() => {
        this.setState({ isHorizontalMouseActive: false });
      });
    }
  }

  private onMouseLeave(_: React.MouseEvent<HTMLDivElement>): void {
    if (!this.state.isHorizontalMouseActive) return;
    this.setState({ isHorizontalMouseActive: false });
  }

  private onKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
    if (this.props.publicStore?.isLoading || this.props.publicStore?.isPreLoading) {
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowDown') {
      const current = this.state.focusedIndex;
      const next = current + 1;
      if (next < this.data.length) {
        this.prevFocusedIndex = this.state.focusedIndex;
        this.setState({ focusedIndex: next }, () => this.preRender());
        this.props.onRowClick && this.props.onRowClick(this.data[next], next);
        if (this.isVisibleRow(next)) e.preventDefault();
      }
    } else if (e.key === 'ArrowUp') {
      const current = this.state.focusedIndex;
      const next = current - 1;
      if (next > -1 && this.data.length > 0) {
        this.prevFocusedIndex = this.state.focusedIndex;
        this.setState({ focusedIndex: next }, () => this.preRender());
        this.props.onRowClick && this.props.onRowClick(this.data[next], next);
        if (this.isVisibleRow(next)) e.preventDefault();
      }
    }
  }

  private isVisibleRow(i: number): boolean {
    const visibleStart = Math.ceil(this.scrollTop / this.props.rowHeight);
    const visibleEnd = visibleStart + Math.floor(this.gridHeight / this.props.rowHeight);
    return i >= visibleStart && i <= visibleEnd;
  }

  private onListScroll = (e: any, isMain: boolean): void => {
    const { clientHeight, scrollTop, scrollHeight } = e;
    if (this.scrollTop === scrollTop) return;

    const top = Math.round(((clientHeight + scrollTop) / scrollHeight) * 100);
    this.scrollTop = scrollTop;

    if (isMain) {
      requestAnimationFrame(() => {
        this.refLockList.current?.scrollToPosition(scrollTop || 0);
      });
    } else {
      requestAnimationFrame(() => {
        this.mainList?.current?.scrollToPosition(scrollTop || 0);
      });
    }

    if (this.props.hasNext
      && !this.triggeredOnBottom
      && this.props.onBottom
      && top > 85
    ) {
      this.triggeredOnBottom = true;
      this.props.onBottom();
    }

    if (!this.props.onBottom
      && this.props.infinityHandler?.hasNext
      && top > 85
    ) {
      this.props.infinityHandler.retrieve();
    }
  }

  doScrollY = _.debounce(this.onListScroll, 300);

  show = useContextMenu({
    id: 'GRID_CONTEXT',
  }).show;

  handleContextMenu = (event: React.MouseEvent, item: any) => {
    this.show(event, {
      props: {
        scope: this,
        item,
      },
    });
  }

  @action
  onContextMenuHandler = ({ props }: ItemParams, action: MENU_ACTION) => {
    const { item, scope } = props;
    if (action === MENU_ACTION.SORT_ASC) {
      scope.sortBy(item.sort || item.id);
    } else if (action === MENU_ACTION.SORT_DESC) {
      scope.sortBy(item.sort || item.id, true);
    } else if (action === MENU_ACTION.LOCK) {
      let index = 0;
      for (let i = 0; i < scope.props.header.length; i += 1) {
        if (scope.props.header[i].id === item.id) {
          index = i;
          break;
        }
      }

      scope.setState({ colLock: index + 1 });
      this.forceRepaint(true);
    } else {
      scope.setState({ colLock: 0 });
      this.forceRepaint(true);
    }
  }

  @action
  async sortBy(key: string, isDesc: boolean = false) {
    if (!this.data.length) {
      return;
    }

    if (this.props.infinityHandler) {
      this.props.infinityHandler.sortBy(key, isDesc);
      await this.init();
      return;
    }

    if (isDesc) {
      this.data = Sort.desc(key, this.data);
    } else {
      this.data = Sort.asc(key, this.data);
    }
    await this.preRender(this.data);
  }

  private rowRenderer = ({
    key,
    index: i,
    style: rowStyle,
  }: ListRowProps, isFixed: boolean = false) => {
    const item = isFixed ? this.state.fixedData[i] : this.state.data[i];
    return (
      <div
        key={key}
        style={{
          ...rowStyle,
          width: this.state.isVisibleHorizontalScroll ? this.totalWidth : '100%',
        }}
      >
        {item}
      </div>
    );
  }

  render() {
    const {
      header,
      rowHeight,
      className,
      weight,
      minHeight,
      onClick,
    } = this.props;
    const visibleHeaders = header.filter((h) => !h.isHidden);

    const innerLayout = (
      <React.Fragment>
        <FlexLayout
          weight={1}
          minHeight={minHeight}
          className={style.container}
        >
          <div
            ref={this.scrollBody}
            className={JoinClassName.make([style.scroller, style.body])}
          >
            <List
              ref={this.mainList}
              width={this.scrollHeader.current?.clientWidth || 0}
              onScroll={(e: any) => this.doScrollY(e, true)}
              overscanRowCount={10}
              className={style.list}
              height={this.virtualInnerHeight}
              rowCount={this.state.data.length}
              rowHeight={rowHeight}
              rowRenderer={this.rowRenderer}
            />
          </div>
        </FlexLayout>

        {this.hasTrail && <FlexLayout
          className={style.container}
          size={this.props.headerHeight || Global.LAYOUT_TITLE_HEIGHT_1}
          minHeight={this.props.headerHeight || Global.LAYOUT_TITLE_HEIGHT_1}
        >
          <div
            className={style.scroller}
          >
            <FlexLayout
              refs={this.scrollTrail}
              className={JoinClassName.make([
                style.container,
                style.footer,
              ])}
              weight={weight}
              size={this.props.rowHeight}
              minHeight={this.props.headerHeight || Global.LAYOUT_TITLE_HEIGHT_1}
              onClick={() => onClick && onClick()}
            >
              {visibleHeaders.map((item, y) => {
                const width = Math.round((item.width || 100) * this.perPxWidth)
                  + (y === 0 ? this.littleWidth : -2);

                return (
                  <FlexLayout
                    key={item.id}
                    justify="center"
                    align="center"
                    style={{
                      width,
                      maxWidth: width,
                      minWidth: width,
                    }}
                  >
                    {AutoPadding.make(item.trail && item.trail(this.data, this.props.scope))}
                  </FlexLayout>
                );
              })}

              {this.state.isListVerticalScroll && <div style={{
                width: 10,
                flex: '0 0 10px',
              }} />}
            </FlexLayout>
          </div>
        </FlexLayout>}
      </React.Fragment>
    );

    const innerFixedColumnLayout = (
      <React.Fragment>
        <FlexLayout
          weight={1}
          minHeight={minHeight}
          className={style.container}
        >
          <div className={JoinClassName.make([style.scroller, style.body])}>
            <List
              ref={this.refLockList}
              width={this.scrollHeader.current?.clientWidth || 0}
              onScroll={(e: any) => this.doScrollY(e, false)}
              overscanRowCount={10}
              className={style.list}
              height={this.virtualInnerHeight}
              rowCount={this.state.data.length}
              rowHeight={rowHeight}
              rowRenderer={(x) => this.rowRenderer(x, true)}
            />
          </div>
        </FlexLayout>
      </React.Fragment>
    );

    return (
      <FlexLayout
        className={className}
        isVertical={true}
        onMouseMove={(e) => this.onMouseMove(e)}
        onMouseLeave={(e) => this.onMouseLeave(e)}
        onKeyDown={(e) => this.onKeyDown(e)}
      >
        {/* Real header */}
        <FlexLayout
          className={style.container}
          weight={weight}
          size={this.props.headerHeight ? this.props.headerHeight : 32}
          minHeight={minHeight}
          onClick={() => onClick && onClick()}
        >
          <div
            ref={this.scrollHeader}
            className={style.scroller}
          >
            {this.state.isLoaded && <FlexLayout
              className={style.header}
              style={{
                width: this.perPxWidth === 1
                  ? this.totalWidth + (this.state.isListVerticalScroll ? 12 : 0)
                  : this.gridWidth,
                height: this.props.headerHeight ? this.props.headerHeight : 32,
              }}
            >
              {visibleHeaders.map((item, y) => {
                const width = Math.round((item.width || 100) * this.perPxWidth)
                  + (y === 0 ? this.littleWidth : -2);

                return (
                  <div
                    key={y}
                    onContextMenu={(e) => this.handleContextMenu(e, item)}
                  >
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        width,
                        maxWidth: width,
                        minWidth: width,
                        color: item.color || 'inherit',
                        height: this.props.headerHeight ? this.props.headerHeight : 32,
                      }}
                    >
                      {item.text}
                    </FlexLayout>
                  </div>
                );
              })}

              {this.state.isListVerticalScroll && <div style={{
                width: 10,
                flex: '0 0 10px',
              }} />}
            </FlexLayout>}
          </div>
        </FlexLayout>

        {/* Fixed column header */}
        <FlexLayout
          className={JoinClassName.make([
            style.container,
            style.fixedCol,
          ])}
          weight={weight}
          size={this.props.headerHeight ? this.props.headerHeight : 32}
          minHeight={minHeight}
          onClick={() => onClick && onClick()}
          style={{
            width: this.props.header.slice(0, this.state.colLock).filter((x) => !x.isHidden).reduce((a, x, y) => {
              // const width = this.state.isVisibleHorizontalScroll
              //   ? x.width
              //   : (x.width || 100) * this.perPxWidth;
              const width = Math.round((x.width || 100) * this.perPxWidth)
                + (y === 0 ? this.littleWidth : -2);

              return a + (width || 100) + 2;
            }, 0),
          }}
        >
          <div className={style.scroller}>
            {this.state.isLoaded && <FlexLayout
              className={style.header}
              style={{
                width: this.totalWidth < this.gridWidth
                  ? this.gridWidth
                  : this.totalWidth,
                height: this.props.headerHeight ? this.props.headerHeight : 32,
              }}
            >
              {visibleHeaders.map((item, y) => {
                const width = Math.round((item.width || 100) * this.perPxWidth)
                  + (y === 0 ? this.littleWidth : -2);

                return (
                  <div
                    key={y}
                    onContextMenu={(e) => this.handleContextMenu(e, item)}
                  >
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        width,
                        maxWidth: width,
                        minWidth: width,
                        color: item.color || 'inherit',
                        height: this.props.headerHeight ? this.props.headerHeight : 32,
                      }}
                    >
                      {item.text}
                    </FlexLayout>
                  </div>
                );
              })}

              {this.state.isListVerticalScroll && <div style={{
                width: 10,
                flex: '0 0 10px',
              }} />}
            </FlexLayout>}
          </div>
        </FlexLayout>

        {/* Main */}
        {this.state.isLoaded && <FlexLayout isVertical={true}>
          {innerLayout}
        </FlexLayout>}

        {/* Fixed column main */}
        {this.state.isLoaded && <FlexLayout
          className={style.fixedColMain}
          isVertical={true}
          style={{
            top: (this.props.headerHeight || 32) + 2,
            bottom: (this.props.headerHeight ? (this.props.headerHeight + 22) : 56) + 22 + 2 - (this.hasTrail ? 22 : 0),
            width: this.props.header.slice(0, this.state.colLock).filter((x) => !x.isHidden).reduce((a, x, y) => {
              // const width = this.state.isVisibleHorizontalScroll
              //   ? x.width
              //   : (x.width || 100) * this.perPxWidth;
              const width = Math.round((x.width || 100) * this.perPxWidth)
                + (y === 0 ? this.littleWidth : -2);

              return a + (width || 100) + 2;
            }, 0),
          }}
        >
          {innerFixedColumnLayout}
        </FlexLayout>}

        {this.state.isLoaded && <FlexLayout className={style.bottom} size={24}>
          <FlexLayout align="center" weight={2}>
            <FlexLayout style={{ color: '#818181' }}>
              <div>
                총 <span style={{ color: '#515151' }}>
                  {Format.number(this.props.infinityHandler?.total || this.data?.length || 0)}
                </span>행
              </div>
            </FlexLayout>
          </FlexLayout>

          {this.state.isVisibleHorizontalScroll && <FlexLayout
            align="center"
            justify="center"
          >
            <FlexLayout size={15}>
              <FlexLayout
                className={style.thumb}
                onClick={() => this.onDeltaXEvent(-50)}
              >
                <MdChevronLeft />
              </FlexLayout>
            </FlexLayout>
            <FlexLayout>
              <div
                ref={this.scrollHorizontalRail}
                className={style.rail}
              >
                <div
                  ref={this.scrollHorizontalRailThumb}
                  onMouseMove={(e) => this.onHorizontalDragging(e)}
                  style={{
                    width: this.state.railThumbWidth,
                  }}
                />
              </div>
            </FlexLayout>
            <FlexLayout size={15}>
              <FlexLayout
                className={style.thumb}
                onClick={() => this.onDeltaXEvent(50)}
              >
                <MdChevronRight />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>}
        </FlexLayout>}

        <Menu id="GRID_CONTEXT">
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.SORT_ASC)}>
            오름차순 정렬
          </Item>
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.SORT_DESC)}>
            내림차순 정렬
          </Item>
          <Separator />
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.LOCK)}>
            틀 고정
          </Item>
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.UNLOCK)}>
            틀 고정 해제
          </Item>
        </Menu>
      </FlexLayout>
    );
  }
}
