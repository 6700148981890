import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642w_16
 * @category 업종별 매출대비매입요율
 */

export class IndustryRateModel {
  /**
   * 월
   *
   * **data type** varchar(13)
   */
  readonly mon: string;

  /**
   * 매출금액 (전체)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamttot: string;

  /**
   * 매입금액 (전체)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamttot: string;

  /**
   * 율 (전체)
   *
   * **data type** varchar(13)
   */
  readonly monyultot: string;

  /**
   * 매출금액 (보수)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamt0: string;

  /**
   * 매입금액 (보수)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamt0: string;

  /**
   * 율 (보수)
   *
   * **data type** varchar(13)
   */
  readonly monyul0: string;

  /**
   * 매출금액 (설치)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamt1: string;

  /**
   * 매입금액 (설치)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamt1: string;

  /**
   * 율 (설치)
   *
   * **data type** varchar(13)
   */
  readonly monyul1: string;

  /**
   * 매출금액 (제조)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamt2: string;

  /**
   * 매입금액 (제조)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamt2: string;

  /**
   * 율 (제조)
   *
   * **data type** varchar(13)
   */
  readonly monyul2: string;


  /**
   * 매출금액 (전기)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamt3: string;

  /**
   * 매입금액 (전기)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamt3: string;

  /**
   * 율 (전기)
   *
   * **data type** varchar(13)
   */
  readonly monyul3: string;

  /**
   * 매출금액 (도매)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamt4: string;

  /**
   * 매입금액 (도매)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamt4: string;

  /**
   * 율 (도매)
   *
   * **data type** varchar(13)
   */
  readonly monyul4: string;

  /**
   * 매출금액 (기타)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamt5: string;

  /**
   * 매입금액 (기타)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamt5: string;

  /**
   * 율 (기타)
   *
   * **data type** varchar(13)
   */
  readonly monyul5: string;

  /**
   * 매출금액 (미입력)
   *
   * **data type** varchar(13)
   */
  readonly wkmisamtnot: string;

  /**
   * 매입금액 (미입력)
   *
   * **data type** varchar(13)
   */
  readonly wkmijamtnot: string;

  /**
   * 율 (미입력)
   *
   * **data type** varchar(13)
   */
  readonly monyulnot: string;

  /**
   * 분기
   *
   * **data type** varchar(13)
   */
  readonly bungi: string;

  /**
   * 반기
   *
   * **data type** varchar(13)
   */
  readonly bangi: string;

  /**
   * new
   *
   * **data type** varchar(13)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.mon = data.mon || '';
    this.wkmisamttot = data.wkmisamttot || '';
    this.wkmijamttot = data.wkmijamttot || '';
    this.monyultot = data.monyultot || '';
    this.wkmisamt0 = data.wkmisamt0 || '';
    this.wkmijamt0 = data.wkmijamt0 || '';
    this.monyul0 = data.monyul0 || '';
    this.wkmisamt1 = data.wkmisamt1 || '';
    this.wkmijamt1 = data.wkmijamt1 || '';
    this.monyul1 = data.monyul1 || '';
    this.wkmisamt2 = data.wkmisamt2 || '';
    this.wkmijamt2 = data.wkmijamt2 || '';
    this.monyul2 = data.monyul2 || '';
    this.wkmisamt3 = data.wkmisamt3 || '';
    this.wkmijamt3 = data.wkmijamt3 || '';
    this.monyul3 = data.monyul3 || '';
    this.wkmisamt4 = data.wkmisamt4 || '';
    this.wkmijamt4 = data.wkmijamt4 || '';
    this.monyul4 = data.monyul4 || '';
    this.wkmisamt5 = data.wkmisamt5 || '';
    this.wkmijamt5 = data.wkmijamt5 || '';
    this.monyul5 = data.monyul5 || '';
    this.wkmisamtnot = data.wkmisamtnot || '';
    this.wkmijamtnot = data.wkmijamtnot || '';
    this.monyulnot = data.monyulnot || '';
    this.bungi = data.bungi || '';
    this.bangi = data.bangi || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
