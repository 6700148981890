import * as React from 'react';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { CustomerPresent } from './CustomerPresent';

const telcds = [
  { value: '001', remark: '회사' },
  { value: '002', remark: '휴대전화' },
  { value: '003', remark: '자택' },
  { value: '004', remark: '팩스' },
  { value: '005', remark: '비상통화장치' },
];

/**
 * 화면
 * @window w_tb_e911w_01
 * @category 고객현황
 */
export const CustomerPresentTemplate: React.FC<TemplateProps<CustomerPresent>> = ({
  scope,
  update,
}) => {
  const model = scope.state.CustomerPresentList;
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={230}>
          <span>그룹분류</span>
          <ComboBox
            value={model.groupcd}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '현장' },
              { value: '1', remark: '거래처' },
              { value: '2', remark: '직원' },
              { value: '3', remark: '일반' },
              { value: '4', remark: '기타' },
              { value: '5', remark: '영업' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ searchGroupcd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={230}>
          <span>전화분류</span>
          <ComboBox
            value={model.telcd}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.telcds?.map((y) => new ComboBoxModel(y.telcd, y.telnm))}
            onSelect={(option) => setData({ searchTelcd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout weight={3}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="고객, 비고(담당자), 전화번호, 핸드폰번호, 팩스번호로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        header={[
          {
            id: 'actcd',
            text: '코드',
            width: 7,
          },
          {
            id: 'actmail',
            text: '고객명',
            width: 18,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {x.actmail}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '담당자명',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
               >
                {x.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'telcd',
            text: '분류',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {telcds.filter((y) => y.value === x.telcd)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'tel',
            text: '전화번호',
            width: 7,
          },
          {
            id: 'nicknm',
            text: '별명',
            width: 7,
          },
          {
            id: 'fax',
            text: '팩스번호',
            width: 7,
          },
          {
            id: 'hp',
            text: '핸드폰번호',
            width: 7,
          },
          {
            id: 'email',
            text: '메일주소',
            width: 10,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {x.email}
              </FlexLayout>
            ),
          },
          {
            id: 'custnm',
            text: '회사명',
            width: 10,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {x.custnm}
              </FlexLayout>
            ),
          },
          {
            id: 'homepage',
            text: '홈페이지주소',
            width: 10,
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
