import * as React from 'react';
import {
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import { GeneralJournal } from './GeneralJournal';
import {
  Button,
  BUTTON_THEMES,
} from '../../../../components/forms/Button/Button';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  LabelBox,
  AttachmentBox,
  TextBox,
  DateTextBox,
  GridLayout,
  FlexLayout,
  ModalView,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  LayoutTitle,
  SubHeader,
} from '../../../../components';
import {
  Date6Calculator, Date8,
  Today,
} from '../../../../utils/time';
import { ScheduleItemChangeTypes } from '../Schedule/Schedule';
import { SearchBinding } from '../../../../models/common';
import { JournalItemChangeTypes } from '../Journal/Journal';
import { Update } from '../../../../utils/array';

/*
* 화면
* @window w_tb_e037_type1
* @category 개별업무일지[총무팀]
*/

const states = [
  { value: '1', remark: '계획' },
  { value: '2', remark: '완료' },
];

const notices = [
  { value: '0', remark: '사용' },
  { value: '1', remark: '미사용' },
];

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const GeneralJournalTemplate: React.FC<TemplateProps<GeneralJournal>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.stmon}
              textAlign="center"
              format="####-##"
              onChange={(value) => { scope.setState({ stmon: value }, () => scope.onRetrieveEvent()); }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
            {user.kukcd === '01'
              ? <FlexLayout size={300}>
                <span style={{ color: 'var(--color-red)' }}>사원명</span>
                <TextBox
                  value={scope.state.perid}
                  textAlign="center"
                  onChange={(value) => scope.SS({ perid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    async (item) => {
                      await scope.SS({
                        perid: item.cd,
                        pernm: item.cdnm,
                      });
                      scope.onRetrieveEvent();
                    },
                  )}
                />
                <TextBox
                  value={scope.state.pernm}
                  textAlign="center"
                  onChange={(value) => setData({ pernm: value })}
                  readonly={true}
                />
              </FlexLayout>
              : null
            }

          <FlexLayout/>
          <Button onClick={() => scope.onReport()}>
            <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout size={180}>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'rptdate',
                text: '일자',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rptdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '사원명',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'rspnm',
                text: '직위',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rspnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 4,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 27 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={25}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'attcnt',
                text: '첨부',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {`${x.attcnt} 건`}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.generalJournalList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          weight={1.5}
          isVertical={true}
          isScroll={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.rptdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ rptdate: value })}
                // onBlur={() => scope.itemChanged(BillItemChangeTypes.PUMDATA)}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format={model?.appnum && '##-######-###-####'}
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="비고(현장요구)" >
              <TextBox
                isMultiline={true}
                value={model?.rptremark}
                onChange={(value) => setData({ rptremark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: (model?.attcnt) === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={(model?.attcnt) === '0' ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout weight={0.01}>
        <LayoutTitle weight={0.5}>
          <FlexLayout>
            익일계획
            <Button
              isIcon={true}
              onClick={() => scope.openScheduleModal()}
            >
              <FiArrowRightCircle size={17}/>
            </Button>
          </FlexLayout>
        </LayoutTitle>

        <LabelBox
          weight={9.5}
          style={{ height: 50 }}
        >
          <TextBox
            isMultiline={true}
            value={model?.overwk}
            onChange={(value) => setData({ overwk: value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout
        size={Global.LAYOUT_BUTTON_HEIGHT_1}
      >
        <LayoutTitle
          highlight={true}
          size={240}
        >
          상세내용
        </LayoutTitle>

        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <Button
          style={{ height: '25px', width: '80px' }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope.onLoadSchedule()}
        >
          스케쥴
        </Button>
        <Button
          style={{ height: '25px', width: '80px' }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope.onLoadToday()}
        >
          금일업무
        </Button>
      </FlexLayout>

      <FlexLayout weight={1.8}>
        <TableLayout
          ref={scope.detailTable}
          header={[
            {
              id: 'rptnum',
              text: '순번',
              width: 7,
            },
            {
              id: 'wkcd',
              text: '구분',
              width: 10,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.wkcd}
                  textAlign='center'
                  data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                  onSelect={(option) => { rowUpdate({ wkcd: option.value }); }}
                />
              ),
            },
            {
              id: 'remark',
              text: '세부내용',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ remark: value })}
                  onEnter={() => scope.onNewEvent2()}
                />
              ),
            },
          ]}
          data={scope.state.detailList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item, i) => scope.onDetailRowFocusEvent(item, i)}
        />
      </FlexLayout>

      <LayoutTitle highlight={true}>전날 업무내용</LayoutTitle>

      <GridLayout
        ref={scope.yesterdayGrid}
        header={[
          {
            id: 'rptnum',
            text: '순번',
            width: 7,
          },
          {
            id: 'wkcd',
            text: '구분',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.wkcds?.filter((y) => y.busicd === x.wkcd)[0]?.businm}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '세부내용',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="start"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.yesterdayList}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onYesterdayRowFocusEvent(item)}
      />

      <ModalView
        width={1300}
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
      >
        <FlexLayout
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            appnum={scope.state.data?.appnum}
            appnm={'업무일지'}
            isEnabledPreview={true}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });

              // grid
              const index = scope.state.generalJournalList.findIndex((x) => x.rptdate === model?.rptdate);
              await Update.byIndex(scope, 'generalJournalList', index, 'attcnt', count.toString());
              scope.grid.current?.forceRepaint(true);
            }}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isEducationModalVisible}
        onClose={() => scope.closeEducationModal()}
        width={900}
        height={600}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>교육보고서에 올라온 내역</LayoutTitle>

          <GridLayout
            ref={scope.popupGrid}
            header={[
              {
                id: 'edudate',
                text: '교육일자',
                width: 8,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.edudate}
                  />
                ),
              },
              {
                id: 'actnm',
                text: '교육제목',
                width: 85,
                render: (x) => (
                  <TextBox
                    value={x.actnm}
                    textAlign="left"
                  />
                ),
              },
              {
                id: 'edupernm',
                text: '교육자',
                width: 7,
                render: (x) => (
                  <TextBox
                    value={x.edupernm}
                    textAlign="left"
                  />
                ),
              },
            ]}
            data={scope.state.popupEducationList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onYesterdayRowFocusEvent(item)}
          />
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.modalEducationSave()}>저장</Button>
            <Button onClick={() => scope.closeEducationModal()}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>

      {/* 보수팀 */}
      <ModalView
        isVisible={scope.state.isScheduleMaintenanceModalVisible}
        onClose={() => scope.closeScheduleModal()}
        width={1200}
        height={900}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>스케쥴 등록</LayoutTitle>

          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            justify='left'
          >
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalSchduleNew()}
            >
              추 가
            </Button>
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalSchduleDelete()}
            >
              삭 제
            </Button>
            <FlexLayout
              style={{ color: 'var(--color-blue)' }}
              align='center'
              justify='left'
            >
            </FlexLayout>
            <FlexLayout size={250}>
              <LayoutTitle
                style={{ color: 'var(--color-red)' }}
                weight={1}
              >
                담당자
              </LayoutTitle>
              <LabelBox weight={4}>
                <TextBox
                  value={scope.state.popupPerid}
                  textAlign="center"
                  onChange={(value) => scope.setState({ popupPerid: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      scope.setState({
                        popupPerid: item.cd,
                        popupPernm: item.cdnm,
                      });
                    },
                  )}
                  isDisabledTrackingStateChange={true}
                />
                <TextBox
                  value={scope.state.popupPernm}
                  weight={1.5}
                  textAlign="center"
                  isDisabledTrackingStateChange={true}
                  readonly={true}
                />
              </LabelBox>
            </FlexLayout>
            <Button
              style={{ width: '80px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.roadSchedule()}
            >
              부서계획
            </Button>
          </FlexLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupScheduleTable}
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'upflagnm',
                  text: '구분',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: scope.getItemColor(x.wkcd) }}
                    >
                      {x.upflagnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'wkcd',
                  text: '업무구분',
                  width: 15,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.wkcd}
                      style={{ color: scope.getItemColor(x.wkcd) }}
                      data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                      onSelect={(option) => {
                        rowUpdate({ wkcd: option.value });
                        scope.itemChanged(JournalItemChangeTypes.WKCD,
                          {
                            ...x,
                            wkcd: option.value,
                          });
                      }}
                    />
                  ),
                },
                {
                  id: 'plandate',
                  text: '계획일자',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.plandate || `${Today.yearMon()}${Today.date()}`}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ plandate: value })}
                    />
                  ),
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 30,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'equpcd',
                  text: '호기코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E611',
                        { uv_arg1: x.actcd },
                        true,
                        (item) => {
                          rowUpdate({
                            equpcd: item.cd,
                            equpnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기명',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpnm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpnm: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '업무내용',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'perid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid || x.u}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 15,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                    />
                  ),
                },
              ]}
              data={scope.state.popupScheduleList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onScheduleUpdateRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onScheduleRowFocusEvent(item)}
            />
          </FlexLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.modalSchduleSave()}>저장</Button>
            <Button onClick={() => scope.closeScheduleModal()}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>

      {/* 총무팀 */}
      <ModalView
        isVisible={scope.state.isScheduleGreetingModalVisible}
        onClose={() => scope.closeScheduleModal()}
        width={1500}
        height={900}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              스케쥴 등록
            </LayoutTitle>
          </FlexLayout>
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            justify='left'
          >
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalSchduleNew()}
            >
              추 가
            </Button>
            <Button
              style={{ width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalSchduleDelete()}
            >
              삭 제
            </Button>
          </FlexLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupScheduleTable}
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'upflagnm',
                  text: '구분',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: scope.getItemColor(x.wkcd) }}>
                      {x.upflagnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'wkcd',
                  text: '업무구분',
                  width: 15,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.wkcd}
                      style={{ color: scope.getItemColor(x.wkcd) }}
                      data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                      onSelect={(option) => {
                        rowUpdate({ wkcd: option.value });
                        scope.itemChanged(ScheduleItemChangeTypes.WKCD,
                          {
                            ...x,
                            wkcd: option.value,
                          });
                      }}
                    />
                  ),
                },
                {
                  id: 'plandate',
                  text: '발송일',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.plandate || `${Today.yearMon()}${Today.date()}`}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ plandate: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '업무내용',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
                {
                  id: 'notice',
                  text: '알림구분',
                  width: 10,
                  render: (x, rowUpdate, _) => (
                    <ComboBox
                      value={x.gubun}
                      data={notices.map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => { rowUpdate({ notice: option.value }); }}
                    />
                  ),
                },
                {
                  id: 'noticedate',
                  text: '알림일자',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.noticedate || `${Today.yearMon()}${Today.date()}`}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ noticedate: value })}
                    />
                  ),
                },
                {
                  id: 'noticetime',
                  text: '접수시간',
                  width: 10,
                  render: (x, rowUpdate, ref) => (
                    <FormatTextBox
                      ref={ref}
                      textAlign="center"
                      format="##:##"
                      value={x.noticetime}
                      onChange={(value) => rowUpdate({ noticetime: value })}
                    />
                  ),
                },
                {
                  id: 'perid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid || x.u}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 15,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'equpcd',
                  text: '호기코드',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E611',
                        { uv_arg1: x.actcd },
                        true,
                        (item) => {
                          rowUpdate({
                            equpcd: item.cd,
                            equpnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기명',
                  width: 15,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpnm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpnm: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.popupScheduleList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onScheduleUpdateRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onScheduleRowFocusEvent(item)}
            />
          </FlexLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.modalSchduleSave()}>저장</Button>
            <Button onClick={() => scope.closeScheduleModal()}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  );
};
