import * as React from 'react';
import { FiArrowRightCircle, FiFolderPlus } from 'react-icons/fi';
import {
  AttachmentBox as GlobalAttachmentBox, Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  EditTitleLabelBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle, ModalView,
  SearchLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  ElevatorInfo,
  ElevatorInfoTabDataStateNames,
  ElevatorInfoTabFocusedStateNames,
  ElevatorInfoTabId,
} from './ElevatorInfo';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ConfirmWarning } from '../../../../utils/confirm';

/**
 * 화면
 * @window w_tb_e611_type
 * @category 승강기제원정보[이력카드]
 */

export const ElevatorInfoTemplate: React.FC<TemplateProps<ElevatorInfo>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model2 = scope.state.Infos;

  const setModel2State = (changes: any) => {
    scope.setState({
      Infos: {
        ...scope.state.Infos,
        ...changes,
      },
    });
  };
  return (<FlexLayout isVertical={true}>
    <FlexLayout isVertical={true}>
      {(scope.tabIndex === ElevatorInfoTabId.Info
        || scope.tabIndex === ElevatorInfoTabId.History
        || scope.tabIndex === ElevatorInfoTabId.Product
      ) && <FlexLayout isVertical={true}>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout>
              <span>검색어</span>
              <TextBox
                  placeholderColor="#CACACA"
                  placeholderAlign="left"
                  placeholder="현장, 거래처, 현장담당자, 주소, 승강기번호로 검색하세요"
                  value={scope.state.searchQuery}
                  onChange={(value) => setData({ searchQuery: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  isDisabledTrackingStateChange={true}
              />
          </FlexLayout>

          <FlexLayout>
              <span>계약구분</span>
              <ComboBox
                  value={scope.state.gubun}
                  data={[
                    { value: '%', remark: '전체' },
                    { value: '1', remark: '계약중' },
                    { value: '2', remark: '해지' },
                    { value: '3', remark: '미계약' },
                  ].map((x) => new ComboBoxModel(x.value, x.remark))}
                  onSelect={(option) => scope.setState({ gubun: option.value },
                    () => scope.onRetrieveEvent())}
                  isDisabledTrackingStateChange={true}
              />
          </FlexLayout>

          <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>담당자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  }, () => scope.onRetrieveEvent());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              weight={1.6}
              value={scope.state.pernm}
              onChange={(value) => setData({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>회사구분</span>
            <TextBox
              value={scope.state.wkactcd}
              textAlign="center"
              onChange={(value) => setData({ wkactcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_E018_1',
                {},
                true,
                (item) => {
                  setData({
                    wkactcd: item.cd,
                    wkactnm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              weight={1.6}
              value={scope.state.wkactnm}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
        <FlexLayout>
          <GridLayout
            header={[
              {
                id: 'actcd',
                text: '현장코드',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'elno',
                text: '승강기번호',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
                  </FlexLayout>
                ),
              },
            ]}
            ref={scope.grid}
            data={scope.state.infoList || []}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
          {(scope.tabIndex === ElevatorInfoTabId.Info) && <FlexLayout isVertical={true} weight={1.8}>
            <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} isVertical={true}>
              <TabView
                tabs={scope.tabs}
                focused={scope.state.focusedTab}
                onChange={(tab) => scope.onTabChange(tab)}
              />
           </FlexLayout>
            <FlexLayout>
              <FlexLayout
                isVertical={true}
                isScroll={true}
              >
                <LayoutTitle>
                  <FlexLayout>
                    승강기 기본정보
                    <div style={{ position: 'relative', left: '359px' }}>
                      고장인센적용제외
                      <CheckBox
                        value={model2?.incentive === '1'}
                        onChange={(v) => setModel2State({ incentive: v ? '1' : '0' })}
                        style={{ height: '3px', transform: 'translateX(4px) translateY(4px)' }}
                      />
                    </div>
                  </FlexLayout>
                </LayoutTitle>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <FlexLayout size={167}>
                    <LabelBox
                      title="호기"
                      isNeed={true}
                    >
                      <TextBox
                        textAlign="center"
                        value={model2?.equpcd}
                        onChange={(value) => setModel2State({ equpcd: value })}
                        readonly={true}
                        size={70}
                      />
                      <TextBox
                        textAlign="left"
                        value={model2?.equpnm}
                        onChange={(value) => setModel2State({ equpnm: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={167}>
                    <LabelBox
                      title="승강기번호"
                    >
                      <FormatTextBox
                        textAlign="center"
                        format="####-###"
                        value={model2?.elno}
                        onChange={(value) => setModel2State({ elno: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="구분"
                    >
                      <ComboBox
                        value={model2?.gubun}
                        data={scope.state.gubuns2?.map((y) => new ComboBoxModel(y.eligcd, y.elignm))}
                        onSelect={(option) => setModel2State({ gubun: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="종류"
                    >
                      <ComboBox
                        value={model2?.kind}
                        data={scope.state.kinds?.map((y) => new ComboBoxModel(y.elijcd, y.elijnm))}
                        onSelect={(option) => setModel2State({ kind: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                      title="형식"
                    >
                      <ComboBox
                        value={model2?.form}
                        data={scope.state.forms?.map((y) => new ComboBoxModel(y.elifcd, y.elifnm))}
                        onSelect={(option) => setModel2State({ form: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                    <LabelBox
                        title="도어방식"
                    >
                      <ComboBox
                        value={model2?.inver}
                        data={scope.state.invers?.map((y) => new ComboBoxModel(y.invercd, y.invernm))}
                        onSelect={(option) => setModel2State({ inver: option.value })}
                        isDisabledTrackingStateChange={true}
                      />
                    </LabelBox>
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <FlexLayout size={167}>
                    <LabelBox
                      title="점검기종"
                      isNeed={true}
                      footer={<FlexLayout>
                        <Button
                          onClick={() => scope.openTelnum()}
                          isIcon={true}
                        >
                          <FiArrowRightCircle size={25}/>
                        </Button>
                      </FlexLayout>}
                    >
                      <ComboBox
                        value={model2?.evcd}
                        data={scope.state.evcds?.map((y) => new ComboBoxModel(y.evlcd, y.evlnm))}
                        onSelect={(option) => setModel2State({ evcd: option.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={83}>
                      <LabelBox
                          title="제어반"
                      >
                          <ComboBox
                              value={model2?.jeoban}
                              data={[
                                { value: '01', remark: 'MR' },
                                { value: '02', remark: 'MRL' },
                              ].map((x) => new ComboBoxModel(x.value, x.remark))}
                              onSelect={(option) => setModel2State({ jeoban: option.value })}
                              isDisabledTrackingStateChange={true}
                          />
                      </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={84}>
                      <LabelBox
                          title="인화물구분"
                      >
                          <ComboBox
                              value={model2?.class2}
                              data={[
                                { value: 'D', remark: '인승용' },
                                { value: 'F', remark: '화물용' },
                              ].map((x) => new ComboBoxModel(x.value, x.remark))}
                              onSelect={(option) => setModel2State({ class2: option.value })}
                              isDisabledTrackingStateChange={true}
                          />
                      </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                      <LabelBox
                          title="모델명"
                          style={{ color: 'var(--color-red)' }}
                      >
                          <TextBox
                              textAlign="center"
                              value={model2?.model}
                              onChange={(value) => setModel2State({ model: value })}
                              size={50}
                          />
                          <TextBox
                              textAlign="left"
                              value={model2?.mdnm}
                              onChange={(value) => setModel2State({ mdnm: value })}
                              readonly={true}
                          />
                      </LabelBox>
                  </FlexLayout>

                  <FlexLayout >
                      <LabelBox
                          title="제조업체"
                          style={{ color: 'var(--color-red)' }}
                      >
                          <TextBox
                              textAlign="center"
                              value={model2?.manucd}
                              onChange={(value) => setModel2State({ manucd: value })}
                              size={50}
                          />
                          <TextBox
                              textAlign="left"
                              value={model2?.manunm}
                              onChange={(value) => setModel2State({ manunm: value })}
                              readonly={true}
                          />
                      </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={56}>
                      <LabelBox
                          title="층수"
                      >
                          <TextBox
                              textAlign="center"
                              value={model2?.number}
                              onChange={(value) => setModel2State({ number: value })}
                          />
                      </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={50}>
                      <LabelBox
                          title="지상"
                      >
                          <TextBox
                              textAlign="center"
                              value={model2?.number_gr}
                              onChange={(value) => setModel2State({ number_gr: value })}
                          />
                      </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={50}>
                      <LabelBox
                          title="지하"
                      >
                          <TextBox
                              textAlign="center"
                              value={model2?.number_ungr}
                              onChange={(value) => setModel2State({ number_ungr: value })}
                          />
                      </LabelBox>
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <FlexLayout size={83}>
                        <LabelBox
                            title="최대정원"
                        >
                            <FormatTextBox
                                textAlign="right"
                                value={model2?.nperson}
                                format="##인승"
                                onChange={(value) => setModel2State({ nperson: value })}
                                weight={2}
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={83}>
                        <LabelBox
                            title="적재하중"
                        >
                            <FormatTextBox
                                textAlign="right"
                                value={model2?.height}
                                format="####kg"
                                onChange={(value) => setModel2State({ height: value })}
                                weight={2}
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={167}>
                        <LabelBox
                            title="정격속도"
                        >
                            <ComboBox
                                value={model2?.spped}
                                data={scope.state.speeds?.map((y) => new ComboBoxModel(y.prospcd, y.prospnm))}
                                onSelect={(option) => setModel2State({ spped: option.value })}
                                isDisabledTrackingStateChange={true}
                            />

                            <TextBox
                                textAlign="center"
                                value={model2?.speednm}
                                onChange={(value) => setModel2State({ speednm: value })}
                                readonly={true}
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="사양"
                        >
                            <TextBox
                                textAlign="left"
                                value={model2?.sizecd}
                                onChange={(value) => setModel2State({ sizecd: value })}
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="점검대상/원격"
                        >
                            <CheckBox
                                value={model2?.kcflag === '1'}
                                onChange={(v) => setModel2State({ kcflag: v ? '1' : '0' })}
                            />
                            <CheckBox
                                value={model2?.remflag === 'Y'}
                                onChange={(v) => setModel2State({ remflag: v ? 'Y' : 'N' })}
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="도면"
                            footer={<FlexLayout>
                              <Button
                                onClick={() => scope.copyDP()}
                              >
                                전체복사
                              </Button>
                              <Button
                                onClick={() => {
                                  setData({
                                    isDPModalVisible: true,
                                  });
                                }}
                                isIcon={true}
                                theme={BUTTON_THEMES.BLUE}
                              >
                                <FiFolderPlus size={25}/>
                              </Button>
                            </FlexLayout>}
                        >
                            <TextBox
                                textAlign="center"
                                value={Number(model2?.dpattcnt || '') > 0 ? '도면 있음' : '도면 없음'}
                                onChange={(value) => setModel2State({ dpattcnt: value })}
                              color={model2?.dpattcnt === '0' ? 'var(--color-red)' : 'var(--color-blue)'}
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="PLC/TAC"
                            footer={<FlexLayout>
                              <Button
                                onClick={() => scope.openPLC()}
                                isIcon={true}
                                theme={BUTTON_THEMES.BLUE}
                              >
                                <FiArrowRightCircle size={25}/>
                              </Button>
                              <Button
                                onClick={() => {
                                  if (model2?.plc !== '1') {
                                    ConfirmWarning.show('오류', '먼저 사용여부를 사용으로 설정하세요');
                                    return;
                                  }

                                  setData({
                                    basicAddressToggle: !scope.state.basicAddressToggle,
                                  });
                                }}
                                isIcon={true}
                                theme={BUTTON_THEMES.BLUE}
                              >
                                <FiFolderPlus size={25}/>
                              </Button>
                            </FlexLayout>}
                        >
                            <ComboBox
                                value={model2?.plc}
                                data={[
                                  { value: '0', remark: '미사용' },
                                  { value: '1', remark: '사용' },
                                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                                onSelect={(option) => setModel2State({ plc: option.value })}
                                isDisabledTrackingStateChange={true}
                                style={{ color: model2?.plc === '0' ? '' : 'var(--color-blue)' }}
                            />
                            <TextBox
                                textAlign="center"
                                value={Number(model2?.attcnt || '') > 0 ? '파일 있음' : '파일 없음'}
                                onChange={(value) => setModel2State({ attcnt: value })}
                                color={model2?.attcnt === '0' ? 'var(--color-red)' : 'var(--color-blue)'}
                            />
                        </LabelBox>
                    </FlexLayout>


                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <FlexLayout size={168}>
                        <LabelBox
                            title="최초설치일자"
                        >
                            <DateTextBox
                                value={model2?.yyyymm || '000000'}
                                textAlign="center"
                                format="####-##-##"
                                onChange={
                                  (value) => setModel2State({ yyyymm: value })
                                }
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout size={167}>
                        <LabelBox
                            title="설치일자"
                        >
                            <DateTextBox
                                value={model2?.yyyymm2 || '20200101'}
                                textAlign="center"
                                format="####-##-##"
                                onChange={
                                  (value) => setModel2State({ yyyymm2: value })
                                }
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="운행유효기간-시작일"
                        >
                            <DateTextBox
                                value={model2?.kcstdate || '20200101'}
                                textAlign="center"
                                format="####-##-##"
                                onChange={
                                  (value) => setModel2State({ kcstdate: value })
                                }
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="종료일"
                        >
                            <DateTextBox
                                value={model2?.kcenddate || '20200101'}
                                textAlign="center"
                                format="####-##-##"
                                onChange={
                                  (value) => setModel2State({ kcenddate: value })
                                }
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                        <LabelBox
                            title="최종검사일"
                        >
                            <DateTextBox
                                value={model2?.totkcdate || '20200101'}
                                textAlign="center"
                                format="####-##-##"
                                onChange={
                                  (value) => setModel2State({ totkcdate: value })
                                }
                            />
                        </LabelBox>
                    </FlexLayout>

                    <FlexLayout >
                      <LabelBox title="최종검사종류 / 결과">
                        <ComboBox
                          value={model2?.kcgubun}
                          data={scope.state.kcgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={(option) => setModel2State({ kcgubun: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                        <ComboBox
                          value={model2?.kcresult}
                          data={scope.state.kcresults?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                          onSelect={(option) => setModel2State({ kcresult: option.value })}
                          isDisabledTrackingStateChange={true}
                        />
                      </LabelBox>
                    </FlexLayout>


                  </FlexLayout>

                  <LayoutTitle>
                      승강기 재원정보
                  </LayoutTitle>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename01}
                          onChange={(value) => setModel2State({ typename01: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type01}
                              onChange={(value) => setModel2State({ type01: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename02}
                          onChange={(value) => setModel2State({ typename02: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type02}
                              onChange={(value) => setModel2State({ type02: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename03}
                          onChange={(value) => setModel2State({ typename03: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type03}
                              onChange={(value) => setModel2State({ type03: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename04}
                          onChange={(value) => setModel2State({ typename04: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type04}
                              onChange={(value) => setModel2State({ type04: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename05}
                          onChange={(value) => setModel2State({ typename05: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type05}
                              onChange={(value) => setModel2State({ type05: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename06}
                          onChange={(value) => setModel2State({ typename06: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type06}
                              onChange={(value) => setModel2State({ type06: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename07}
                          onChange={(value) => setModel2State({ typename07: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type07}
                              onChange={(value) => setModel2State({ type07: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename08}
                          onChange={(value) => setModel2State({ typename08: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type08}
                              onChange={(value) => setModel2State({ type08: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename09}
                          onChange={(value) => setModel2State({ typename09: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type09}
                              onChange={(value) => setModel2State({ type09: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename10}
                          onChange={(value) => setModel2State({ typename10: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type10}
                              onChange={(value) => setModel2State({ type10: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename11}
                          onChange={(value) => setModel2State({ typename11: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type11}
                              onChange={(value) => setModel2State({ type11: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename12}
                          onChange={(value) => setModel2State({ typename12: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type12}
                              onChange={(value) => setModel2State({ type12: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename13}
                          onChange={(value) => setModel2State({ typename13: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type13}
                              onChange={(value) => setModel2State({ type13: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename14}
                          onChange={(value) => setModel2State({ typename14: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type14}
                              onChange={(value) => setModel2State({ type14: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename15}
                          onChange={(value) => setModel2State({ typename15: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type15}
                              onChange={(value) => setModel2State({ type15: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename16}
                          onChange={(value) => setModel2State({ typename16: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type16}
                              onChange={(value) => setModel2State({ type16: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename17}
                          onChange={(value) => setModel2State({ typename17: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type17}
                              onChange={(value) => setModel2State({ type17: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename18}
                          onChange={(value) => setModel2State({ typename18: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type18}
                              onChange={(value) => setModel2State({ type18: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename19}
                          onChange={(value) => setModel2State({ typename19: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type19}
                              onChange={(value) => setModel2State({ type19: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename20}
                          onChange={(value) => setModel2State({ typename20: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type20}
                              onChange={(value) => setModel2State({ type20: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename21}
                          onChange={(value) => setModel2State({ typename21: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type21}
                              onChange={(value) => setModel2State({ type21: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename22}
                          onChange={(value) => setModel2State({ typename22: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type22}
                              onChange={(value) => setModel2State({ type22: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename23}
                          onChange={(value) => setModel2State({ typename23: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type23}
                              onChange={(value) => setModel2State({ type23: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename24}
                          onChange={(value) => setModel2State({ typename24: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type24}
                              onChange={(value) => setModel2State({ type24: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename25}
                          onChange={(value) => setModel2State({ typename25: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type25}
                              onChange={(value) => setModel2State({ type25: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename26}
                          onChange={(value) => setModel2State({ typename26: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type26}
                              onChange={(value) => setModel2State({ type26: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename27}
                          onChange={(value) => setModel2State({ typename27: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type27}
                              onChange={(value) => setModel2State({ type27: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename28}
                          onChange={(value) => setModel2State({ typename28: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type28}
                              onChange={(value) => setModel2State({ type28: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename29}
                          onChange={(value) => setModel2State({ typename29: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type29}
                              onChange={(value) => setModel2State({ type29: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename30}
                          onChange={(value) => setModel2State({ typename30: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type30}
                              onChange={(value) => setModel2State({ type30: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename31}
                          onChange={(value) => setModel2State({ typename31: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type31}
                              onChange={(value) => setModel2State({ type31: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename32}
                          onChange={(value) => setModel2State({ typename32: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type32}
                              onChange={(value) => setModel2State({ type32: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename33}
                          onChange={(value) => setModel2State({ typename33: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type33}
                              onChange={(value) => setModel2State({ type33: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename34}
                          onChange={(value) => setModel2State({ typename34: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type34}
                              onChange={(value) => setModel2State({ type34: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename35}
                          onChange={(value) => setModel2State({ typename35: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type35}
                              onChange={(value) => setModel2State({ type35: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename36}
                          onChange={(value) => setModel2State({ typename36: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type36}
                              onChange={(value) => setModel2State({ type36: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename37}
                          onChange={(value) => setModel2State({ typename37: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type37}
                              onChange={(value) => setModel2State({ type37: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename38}
                          onChange={(value) => setModel2State({ typename38: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type38}
                              onChange={(value) => setModel2State({ type38: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename39}
                          onChange={(value) => setModel2State({ typename39: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type39}
                              onChange={(value) => setModel2State({ type39: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename40}
                          onChange={(value) => setModel2State({ typename40: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type40}
                              onChange={(value) => setModel2State({ type40: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename41}
                          onChange={(value) => setModel2State({ typename41: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type41}
                              onChange={(value) => setModel2State({ type41: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename42}
                          onChange={(value) => setModel2State({ typename42: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type42}
                              onChange={(value) => setModel2State({ type42: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename43}
                          onChange={(value) => setModel2State({ typename43: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type43}
                              onChange={(value) => setModel2State({ type43: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename44}
                          onChange={(value) => setModel2State({ typename44: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type44}
                              onChange={(value) => setModel2State({ type44: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename45}
                          onChange={(value) => setModel2State({ typename45: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type45}
                              onChange={(value) => setModel2State({ type45: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename46}
                          onChange={(value) => setModel2State({ typename46: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type46}
                              onChange={(value) => setModel2State({ type46: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename47}
                          onChange={(value) => setModel2State({ typename47: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type47}
                              onChange={(value) => setModel2State({ type47: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename48}
                          onChange={(value) => setModel2State({ typename48: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type48}
                              onChange={(value) => setModel2State({ type48: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename49}
                          onChange={(value) => setModel2State({ typename49: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type49}
                              onChange={(value) => setModel2State({ type49: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename50}
                          onChange={(value) => setModel2State({ typename50: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type50}
                              onChange={(value) => setModel2State({ type50: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename51}
                          onChange={(value) => setModel2State({ typename51: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type51}
                              onChange={(value) => setModel2State({ type51: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename52}
                          onChange={(value) => setModel2State({ typename52: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type52}
                              onChange={(value) => setModel2State({ type52: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename53}
                          onChange={(value) => setModel2State({ typename53: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type53}
                              onChange={(value) => setModel2State({ type53: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename54}
                          onChange={(value) => setModel2State({ typename54: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type54}
                              onChange={(value) => setModel2State({ type54: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename55}
                          onChange={(value) => setModel2State({ typename55: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type55}
                              onChange={(value) => setModel2State({ type55: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename56}
                          onChange={(value) => setModel2State({ typename56: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type56}
                              onChange={(value) => setModel2State({ type56: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename57}
                          onChange={(value) => setModel2State({ typename57: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type57}
                              onChange={(value) => setModel2State({ type57: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename58}
                          onChange={(value) => setModel2State({ typename58: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type58}
                              onChange={(value) => setModel2State({ type58: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename59}
                          onChange={(value) => setModel2State({ typename59: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type59}
                              onChange={(value) => setModel2State({ type59: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename60}
                          onChange={(value) => setModel2State({ typename60: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type60}
                              onChange={(value) => setModel2State({ type60: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename61}
                          onChange={(value) => setModel2State({ typename61: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type61}
                              onChange={(value) => setModel2State({ type61: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename62}
                          onChange={(value) => setModel2State({ typename62: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type62}
                              onChange={(value) => setModel2State({ type62: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename63}
                          onChange={(value) => setModel2State({ typename63: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type63}
                              onChange={(value) => setModel2State({ type63: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename64}
                          onChange={(value) => setModel2State({ typename64: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type64}
                              onChange={(value) => setModel2State({ type64: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename65}
                          onChange={(value) => setModel2State({ typename65: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type65}
                              onChange={(value) => setModel2State({ type65: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename66}
                          onChange={(value) => setModel2State({ typename66: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type66}
                              onChange={(value) => setModel2State({ type66: value })}
                          />
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename67}
                          onChange={(value) => setModel2State({ typename67: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type67}
                              onChange={(value) => setModel2State({ type67: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename68}
                          onChange={(value) => setModel2State({ typename68: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type68}
                              onChange={(value) => setModel2State({ type68: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename69}
                          onChange={(value) => setModel2State({ typename69: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type69}
                              onChange={(value) => setModel2State({ type69: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename70}
                          onChange={(value) => setModel2State({ typename70: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type70}
                              onChange={(value) => setModel2State({ type70: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename71}
                          onChange={(value) => setModel2State({ typename71: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type71}
                              onChange={(value) => setModel2State({ type71: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename72}
                          onChange={(value) => setModel2State({ typename72: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type72}
                              onChange={(value) => setModel2State({ type72: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename73}
                          onChange={(value) => setModel2State({ typename73: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type73}
                              onChange={(value) => setModel2State({ type73: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename74}
                          onChange={(value) => setModel2State({ typename74: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type74}
                              onChange={(value) => setModel2State({ type74: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename75}
                          onChange={(value) => setModel2State({ typename75: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type75}
                              onChange={(value) => setModel2State({ type75: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename76}
                          onChange={(value) => setModel2State({ typename76: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type76}
                              onChange={(value) => setModel2State({ type76: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename77}
                          onChange={(value) => setModel2State({ typename77: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type77}
                              onChange={(value) => setModel2State({ type77: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename78}
                          onChange={(value) => setModel2State({ typename78: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type78}
                              onChange={(value) => setModel2State({ type78: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename79}
                          onChange={(value) => setModel2State({ typename79: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type67}
                              onChange={(value) => setModel2State({ type67: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename80}
                          onChange={(value) => setModel2State({ typename80: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type80}
                              onChange={(value) => setModel2State({ type80: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename81}
                          onChange={(value) => setModel2State({ typename81: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type81}
                              onChange={(value) => setModel2State({ type81: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename82}
                          onChange={(value) => setModel2State({ typename82: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type82}
                              onChange={(value) => setModel2State({ type82: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename83}
                          onChange={(value) => setModel2State({ typename83: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type83}
                              onChange={(value) => setModel2State({ type83: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename84}
                          onChange={(value) => setModel2State({ typename84: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type84}
                              onChange={(value) => setModel2State({ type84: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename85}
                          onChange={(value) => setModel2State({ typename85: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type85}
                              onChange={(value) => setModel2State({ type85: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename86}
                          onChange={(value) => setModel2State({ typename86: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type86}
                              onChange={(value) => setModel2State({ type86: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename87}
                          onChange={(value) => setModel2State({ typename87: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type87}
                              onChange={(value) => setModel2State({ type87: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename88}
                          onChange={(value) => setModel2State({ typename88: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type88}
                              onChange={(value) => setModel2State({ type88: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename89}
                          onChange={(value) => setModel2State({ typename89: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type89}
                              onChange={(value) => setModel2State({ type89: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename90}
                          onChange={(value) => setModel2State({ typename90: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type90}
                              onChange={(value) => setModel2State({ type90: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename91}
                          onChange={(value) => setModel2State({ typename91: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type91}
                              onChange={(value) => setModel2State({ type91: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename92}
                          onChange={(value) => setModel2State({ typename92: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type92}
                              onChange={(value) => setModel2State({ type92: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename93}
                          onChange={(value) => setModel2State({ typename93: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type93}
                              onChange={(value) => setModel2State({ type93: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename94}
                          onChange={(value) => setModel2State({ typename94: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type94}
                              onChange={(value) => setModel2State({ type94: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename95}
                          onChange={(value) => setModel2State({ typename95: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type95}
                              onChange={(value) => setModel2State({ type95: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename96}
                          onChange={(value) => setModel2State({ typename96: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type96}
                              onChange={(value) => setModel2State({ type96: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename97}
                          onChange={(value) => setModel2State({ typename97: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type97}
                              onChange={(value) => setModel2State({ type97: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename98}
                          onChange={(value) => setModel2State({ typename98: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type98}
                              onChange={(value) => setModel2State({ type98: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename99}
                          onChange={(value) => setModel2State({ typename99: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type99}
                              onChange={(value) => setModel2State({ type99: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename100}
                          onChange={(value) => setModel2State({ typename100: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type100}
                              onChange={(value) => setModel2State({ type100: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename101}
                          onChange={(value) => setModel2State({ typename101: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type101}
                              onChange={(value) => setModel2State({ type101: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename102}
                          onChange={(value) => setModel2State({ typename102: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type102}
                              onChange={(value) => setModel2State({ type102: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename103}
                          onChange={(value) => setModel2State({ typename103: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type103}
                              onChange={(value) => setModel2State({ type103: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename104}
                          onChange={(value) => setModel2State({ typename104: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type104}
                              onChange={(value) => setModel2State({ type104: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename105}
                          onChange={(value) => setModel2State({ typename105: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type105}
                              onChange={(value) => setModel2State({ type105: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename106}
                          onChange={(value) => setModel2State({ typename106: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type106}
                              onChange={(value) => setModel2State({ type106: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename107}
                          onChange={(value) => setModel2State({ typename107: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type107}
                              onChange={(value) => setModel2State({ type107: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename108}
                          onChange={(value) => setModel2State({ typename108: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type108}
                              onChange={(value) => setModel2State({ type108: value })}
                          />
                      </EditTitleLabelBox>


                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <EditTitleLabelBox
                          value={model2?.typename109}
                          onChange={(value) => setModel2State({ typename109: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type109}
                              onChange={(value) => setModel2State({ type109: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox
                          value={model2?.typename110}
                          onChange={(value) => setModel2State({ typename110: value })}
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.type110}
                              onChange={(value) => setModel2State({ type110: value })}
                          />
                      </EditTitleLabelBox>

                      <EditTitleLabelBox>
                          <TextBox/>
                      </EditTitleLabelBox>

                      <EditTitleLabelBox>
                          <TextBox/>
                      </EditTitleLabelBox>

                      <EditTitleLabelBox>
                          <TextBox/>
                      </EditTitleLabelBox>

                      <EditTitleLabelBox>
                          <TextBox/>
                      </EditTitleLabelBox>

                  </FlexLayout>

                  <LayoutTitle>
                      승강기 보험
                  </LayoutTitle>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                      <LabelBox
                          title="보험사명"
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.company_nm}
                              onChange={(value) => setModel2State({ company_nm: value })}
                          />
                      </LabelBox>

                      <LabelBox
                          title="보험종류"
                      >
                          <TextBox
                              textAlign="left"
                              value={model2?.insurance_kind}
                              onChange={(value) => setModel2State({ insurance_kind: value })}
                          />
                      </LabelBox>

                      <LabelBox
                          title="계약기간-시작일"
                      >
                          <DateTextBox
                              value={model2?.cont_st_de || '20200101'}
                              textAlign="center"
                              format="####-##-##"
                              onChange={
                                (value) => setModel2State({ cont_st_de: value })
                              }
                          />
                      </LabelBox>

                      <LabelBox
                          title="종료일"
                      >
                          <DateTextBox
                              value={model2?.cont_en_de || '20200101'}
                              textAlign="center"
                              format="####-##-##"
                              onChange={
                                (value) => setModel2State({ cont_en_de: value })
                              }
                          />
                      </LabelBox>

                  </FlexLayout>
                </FlexLayout>
            </FlexLayout>
          </FlexLayout>}
          {(scope.tabIndex === ElevatorInfoTabId.History) && <FlexLayout isVertical={true} weight={1.8}>
            <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} isVertical={true}>
              <TabView
                tabs={scope.tabs}
                focused={scope.state.focusedTab}
                onChange={(tab) => scope.onTabChange(tab)}
              />
            </FlexLayout>
          <FlexLayout>
            <GridLayout
              // @ts-ignore
              ref={scope.grid}
              scope={scope}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity2}
             // @ts-ignore
              data={scope.state[ElevatorInfoTabDataStateNames[scope.tabIndex]] || []}
              onRowClick={(item) => setData({
                [ElevatorInfoTabFocusedStateNames[scope.tabIndex]]: item,
              })}
            />
          </FlexLayout>
          </FlexLayout>}
          {(scope.tabIndex === ElevatorInfoTabId.Product) && <FlexLayout isVertical={true} weight={1.8}>
            <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} isVertical={true}>
              <TabView
                tabs={scope.tabs}
                focused={scope.state.focusedTab}
                onChange={(tab) => scope.onTabChange(tab)}
              />
            </FlexLayout>
              <FlexLayout>
                <GridLayout
                  // @ts-ignore
                    ref={scope.grid}
                    scope={scope}
                    header={scope.tabHeaders[scope.tabIndex]}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    infinityHandler={scope.infinity}
                  // @ts-ignore
                    data={scope.state[ElevatorInfoTabDataStateNames[scope.tabIndex]] || []}
                    onRowClick={(item) => setData({
                      [ElevatorInfoTabFocusedStateNames[scope.tabIndex]]: item,
                    })}
                />
              </FlexLayout>
          </FlexLayout>}
        </FlexLayout>
      </FlexLayout>}
    </FlexLayout>

    {/* 도면 */}
    <ModalView
      isVisible={scope.state.isDPModalVisible}
      onClose={() => scope.closeDPModal()}
      width={1366}
    >
      <FlexLayout style={{ padding: 8 }}>
        <GlobalAttachmentBox
          appnum={`TB_E611_DP_${scope.state.focuseddata?.actcd}${scope.state.focuseddata?.equpcd}`}
          appnm={'도면'}
          isEnabledPreview={true}
        />
      </FlexLayout>
    </ModalView>

    {/* PLC */}
    <ModalView
      isVisible={scope.state.isPLCModalVisible}
      onClose={() => scope.closePLCModal()}
      width={1366}
    >
      <FlexLayout style={{ padding: 8 }}>
        <GlobalAttachmentBox
          appnum={`TB_E611_PLC_${scope.state.focuseddata?.actcd}${scope.state.focuseddata?.equpcd}`}
          appnm={'PLC'}
          isEnabledPreview={true}
        />
      </FlexLayout>
    </ModalView>
  </FlexLayout>);
};
