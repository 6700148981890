import * as React from 'react';
import { action } from 'mobx';
import printJS from 'print-js';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ElevatorErrorCodeTemplate } from './ElevatorErrorCode.template';
import { ElevatorErrorCodeModel } from './ElevatorErrorCode.model';
import { FileReader, FileSelector } from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { ConfirmFail, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';

export interface ElevatorErrorCodeListItem {
  manucd: string;
  mdcd: string;
  errcd: string;
  title: string;
  seq: string;
  new: string;
}

export interface ManucdItem {
  manucd: string;
  manunm: string;
}

export interface MdcdItem {
  mdcd: string;
  mdnm: string;
}


interface ElevatorErrorCodeState {
  ElevatorErrorCodeList: Array<ElevatorErrorCodeListItem>;
  focusedElevatorErrorCode?: ElevatorErrorCodeListItem;
  data: ElevatorErrorCodeModel;
  lastNewData: ElevatorErrorCodeModel;
  img?: ArrayBuffer;
  manucds?: Array<ManucdItem>;
  mdcds?: Array<MdcdItem>;
  searchQuery: string;
  searchManucd: string;
  searchMdcd: string;
  percentImage?: number;
}

/**
 * 컨트롤러
 * @window w_tb_e006_err
 * @category 승강기에러코드
 */
export class ElevatorErrorCode extends PageComponent<PageProps, ElevatorErrorCodeState>
  implements PageToolEvents {
  updatedRows?: Array<ElevatorErrorCodeModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  gridIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      ElevatorErrorCodeList: [],
      data: new ElevatorErrorCodeModel(),
      searchQuery: '',
      searchManucd: '',
      searchMdcd: '',
    };
    // 행 추가시 2행 이상 추가 경우 방지
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 첫번째 서버 요청
    // 제조사 리스트 가져오기
    // request 함수를 호출
    // 실패시 data에 undefined가 들어옴
    const data = await api.dropdown('wf_dd_e001');

    // 서버 요청 실패시 빠져나감
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ manucds: data.items });

    // 두번째 서버 요청
    // manucd에 맞는 mdcd 리스트를 가져옴
    await this.getMdcds();

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async getMdcds() {
    const { actionStore: api } = this.props;

    // mdcd 리스트 가져오기
    // request 함수를 호출
    // 실패시 data에 undefined가 들어옴
    const data = await api.dropdown('wf_dd_e006', {
      manucd: this.state.searchManucd || '%',
    });

    // 서버 요청 실패시 빠져나감
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ mdcds: data.items });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        mdcd: this.state.searchMdcd || '%',
        manucd: this.state.searchManucd || '%',
        as_nm: this.state.searchQuery,
        errcd: this.state.data.errcd,
        title: this.state.data.title,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          ElevatorErrorCodeList: [...this.state.ElevatorErrorCodeList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          ElevatorErrorCodeList: [],
        });

        const index = await this.infinity?.retrieveTo('mdcd', this.state.focusedElevatorErrorCode?.mdcd, type, true) || 0;
        this.state.ElevatorErrorCodeList.length > index && this.grid.current?.setFocus(index);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      ElevatorErrorCodeList: [],
    });
    if (!autoLoad) return;
    await this.infinity?.retrieveAll();
    if (this.state.ElevatorErrorCodeList.length > this.gridIndex) {
      this.grid.current?.setFocus(this.gridIndex);
    } else if (this.state.ElevatorErrorCodeList.length > 0) {
      this.grid.current?.setFocus(0);
    }
  }


  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new ElevatorErrorCodeModel(data, true);
      const newlistItem = {
        spjangcd: data.spjangcd,
        manucd: data.manucd,
        mdcd: data.mdcd,
        errcd: data.errcd,
        title: data.title,
        seq: data.seq,
        new: data.new,
      };

      this.setState({
        data: newModel,
        ElevatorErrorCodeList: [
          newlistItem,
          ...this.state.ElevatorErrorCodeList,
        ],
        focusedElevatorErrorCode: newlistItem,
        lastNewData: new ElevatorErrorCodeModel(data, true),
      }, () => this.grid.current?.setFocus(0));
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save(this.state.data, false)) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focusedElevatorErrorCode?.mdcd} - ${this.state.focusedElevatorErrorCode?.errcd}`;
    await api.delete(text, this.state.focusedElevatorErrorCode) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  async imageRequest() {
    const { actionStore: api, publicStore } = this.props;
    const blob = await api.fxBinary(
      'tb_pic_retrieve',
      {
        spjangcd: publicStore.user.spjangcd,
        manucd: this.state.data.manucd,
        mdcd: this.state.data.mdcd,
        errcd: this.state.data.errcd,
        seq: this.state.data.seq,
        title: this.state.data.title,
      },
    );

    this.setState({
      img: blob,
    });
  }

  @action
  async onPrintEvent() {
    if (!this.state.img) {
      ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
      return;
    }

    try {
      printJS({
        printable: URL.createObjectURL(new Blob([this.state.img])),
        type: 'image',
        base64: true,
        showModal: true,
      });
    } catch {
      ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
    }
  }

  async imageUpload(filename: string, file: Blob) {
    const { actionStore: api } = this.props;

    this.setState({
      percentImage: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        this.setState({
          percentImage: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        'tb_pic_save',
        {
          manucd: this.state.data.manucd,
          mdcd: this.state.data.mdcd,
          errcd: this.state.data.errcd,
          seq: this.state.data.seq,
          title: this.state.data.title,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        this.imageRequest();
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      setTimeout(() => this.setState({
        percentImage: 0,
      }), 1000);
    });
  }

  @action
  async imageDelete() {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      'tb_pic_delete',
      {
        manucd: this.state.data.manucd,
        mdcd: this.state.data.mdcd,
        errcd: this.state.data.errcd,
        seq: this.state.data.seq,
        title: this.state.data.title,
      },
    )) {
      this.imageRequest();
      ConfirmSuccess.show('삭제', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect() {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      this.imageUpload(files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  onRowFocusEvent(item: ElevatorErrorCodeListItem, index: number) {
    this.gridIndex = index;

    if (item?.new === '1') {
      this.setState({
        focusedElevatorErrorCode: item,
        img: undefined,
        data: this.state.lastNewData,
      });
      return;
    }

    this.setState({
      focusedElevatorErrorCode: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.exec(
        Category.GENERAL,
        'dw_1_RowFocuschanged',
        item,
      );

      if (data) {
        this.setState({ data: new ElevatorErrorCodeModel(data) });
        this.imageRequest();
      }
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<ElevatorErrorCodeModel>, updatedRows: Array<ElevatorErrorCodeModel>) {
    this.updatedRows = updatedRows;
    this.setState({ ElevatorErrorCodeList: rows });
  }

  render() {
    return (
      <ElevatorErrorCodeTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
        dataUpdate={(change) => {
          this.setState({
            data: new ElevatorErrorCodeModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          });
        }}
      />
    );
  }
}
