import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e037_type2
 * @category 개별업무일지
 */

export class DetailModel {
  /**
   * 키
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly rptnum: string;

  /**
   * 구분
   *
   * **data type** varchar(2)
   */
  readonly wkcd: string;

  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 코드
   *
   * **data type** varchar(100)
   */
  readonly equpcd: string;

  /**
   * 동호기
   *
   * **data type** varchar(3)
   */
  readonly equpnm: string;

  /**
   * 도착시간
   *
   * **data type** varchar(255)
   */
  readonly frtime: string;

  /**
   * 완료시간
   *
   * **data type** varchar(30)
   */
  readonly totime: string;

  /**
   * 세부내용
   *
   * **data type** varchar(30)
   */
  readonly remark: string;

  /**
   * 예상거리
   *
   * **data type** varchar(3)
   */
  readonly prekm: string;

  /**
   * 이동거리
   *
   * **data type** varchar(10)
   */
  readonly km: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly lat: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly lng: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly carcd: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly plandate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly edunum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly lkdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly lknum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.rptdate = data.rptdate || '';
    this.rptnum = data.rptnum || '';
    this.wkcd = data.wkcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.frtime = data.frtime || '';
    this.totime = data.totime || '';
    this.remark = data.remark || '';
    this.prekm = data.prekm || '';
    this.km = data.km || '';
    this.cltcd = data.cltcd || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.carcd = data.carcd || '';
    this.plandate = data.plandate || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.lkdate = data.lkdate || '';
    this.lknum = data.lknum || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
