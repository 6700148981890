import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e401w_01
 * @category 고장건수통계
 */

export class BreakNumberAllModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(5)
   */
  readonly receyearnm: string;

  /**
   * 1월
   *
   * **data type** varchar(8)
   */
  readonly mon01: string;

  /**
   * 2월
   *
   * **data type** varchar(8)
   */
  readonly mon02: string;

  /**
   * 3월
   *
   * **data type** varchar(8)
   */
  readonly mon03: string;

  /**
   * 4월
   *
   * **data type** varchar(8)
   */
  readonly mon04: string;

  /**
   * 5월
   *
   * **data type** varchar(8)
   */
  readonly mon05: string;

  /**
   * 6월
   *
   * **data type** varchar(8)
   */
  readonly mon06: string;

  /**
   * 7월
   *
   * **data type** varchar(8)
   */
  readonly mon07: string;

  /**
   * 8월
   *
   * **data type** varchar(8)
   */
  readonly mon08: string;

  /**
   * 9월
   *
   * **data type** varchar(8)
   */
  readonly mon09: string;

  /**
   * 10월
   *
   * **data type** varchar(8)
   */
  readonly mon10: string;

  /**
   * 11월
   *
   * **data type** varchar(8)
   */
  readonly mon11: string;

  /**
   * 12월
   *
   * **data type** varchar(8)
   */
  readonly mon12: string;

  /**
   * 합계
   *
   * **data type** varchar(8)
   */
  readonly ttot_samt: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.receyearnm = data.receyearnm || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.ttot_samt = data.ttot_samt || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
