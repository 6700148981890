import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e031
 * @category 일상점검항목등록 - 중분류
 */

export class SelfInspectionModel2 {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 점검종류
   *
   * **data type** varchar(5)
   */
  evlcd: string;

  /**
   * 구분코드
   *
   * **data type** varchar(5)
   */
  gevcd: string;

  /**
   * No
   *
   * **data type** varchar(5)
   */
  seq: string;

  /**
   * 검사항목.장치
   *
   * **data type** varchar(100)
   */
  evnm: string;

  /**
   * 세부사항
   *
   * **data type** varchar(255)
   */
  evdtlnm: string;

  /**
   * 점검주기(월)
   *
   * **data type** varchar(2)
   */
  evmon: string;

  /**
   * 점검주기(월)
   *
   * **data type** varchar(2)
   */
  evcd: string;

  /**
   * 점검주기(주)
   *
   * **data type** varchar(2)
   */
  evju: string;

  /**
   * 상태(기본값)
   *
   * **data type** char(3)
   */
  state: string;

  /**
   * 검사유무
   *
   * **data type** char(1)
   */
  kcyn: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(20)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 정보센터연동코드
   *
   * **data type** varchar(10)
   */
  SEL_CHK_ITEM_CD: string;

  /**
   * 무한반복 제거를 위한 변수
   *
   *
   */
  new: string;

  isnew: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.evlcd = data.evlcd || '';
    this.gevcd = data.gevcd || '';
    this.seq = data.seq || '';
    this.evdtlnm = data.evdtlnm || '';
    this.evnm = data.evnm || '';
    this.evcd = data.evcd || '';
    this.evmon = data.evmon || '';
    this.evju = data.evju || '';
    this.kcyn = data.kcyn || '';
    this.useyn = data.useyn || '';
    this.state = data.state || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.SEL_CHK_ITEM_CD = data.SEL_CHK_ITEM_CD || '';
    this.new = data.new || '';
    this.isnew = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
