import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps, PageToolEvents, RetrieveFocusType, RowUpdate,
} from '../../../../constants';
import { YearTemplate } from './Year.template';
import { YearModel } from './Year.model';
import { TableLayout } from '../../../../components';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { DetailModalModel } from './DetailModal.model';
import { DayNumModalModel } from './DayNumModal.model';

interface YearState {
  searchQuery: string;
  divicd: string;
  divinm: string;
  pernm: string;
  flag: string;
  appgubun: string;
  appnum: string;
  num: string;
  perid: string;
  seal: string;
  rtclafi: string;
  data: Array<YearModel>;
  focused?: YearModel;
  apppercds: Array<any>;
  appgubuns: Array<any>;
  pernmData?:string;
  holiflag?:string;

  // 상세 팝업
  detailModal: boolean;
  detailPopupData: Array<DetailModalModel>;
  detailPopupYear: string;
  detailPopupDivicd: string;
  detailPopupDivinm: string;
  detailPopupPerid: string;
  detailPopupPernm: string;
  detailPopupRtclafi: string;

  // 연차사용 팝업
  daynumModal: boolean;
  daynumPopupData: Array<DayNumModalModel>;
  daynumPopupYear: string;
  daynumPopupPerid: string;
  daynumPopupPernm: string;
  atdcds: Array<any>; // 신청구분 리스트
}

/**
 * 컨트롤러
 * @window w_pb209
 * @category 연차생성
 */
export class Year extends PageComponent<PageProps, YearState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: Today.year(),
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      rtclafi: '001',
      flag: '',
      appgubun: '',
      appnum: '',
      num: '',
      seal: '',
      holiflag: '0',
      data: [],

      // 팝업
      detailModal: false,
      detailPopupData: [],
      detailPopupYear: '',
      detailPopupDivicd: '',
      detailPopupDivinm: '',
      detailPopupPerid: '',
      detailPopupPernm: '',
      detailPopupRtclafi: '',

      // 연차사용 팝업
      daynumModal: '',
      daynumPopupData: '',
      daynumPopupYear: '',
      daynumPopupPerid: '',
      daynumPopupPernm: '',
      atdcds: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 팝업 구분
    const data = await api.dropdown('wf_dd_pb002_03');
    if (!data) return;
    this.setState({
      atdcds: data?.items,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.searchQuery,
        divicd: this.state.divicd,
        perid: this.state.perid,
        rtclafi: this.state.rtclafi,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new YearModel(x))],
        }, () => {
          this.table.current?.update(false);
          next && next();
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['nperid', 'pernm'],
        [this.state.focused?.nperid, this.state.focused?.pernm], type, true) || 0;
      this.state.data && this.state.data.length > index && this.table.current?.setFocus(index);
      await this.SS({
        holiflag: this.infinity?.box?.holiflag || '0',
      });
    });

    await this.table.current?.update(true);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      year: this.state.searchQuery,
      divicd: this.state.divicd,
      perid: this.state.perid,
      rtclafi: this.state.rtclafi,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      year: this.state.searchQuery,
      divicd: this.state.divicd,
      perid: this.state.perid,
      rtclafi: this.state.rtclafi,
    });
  }

  @action
  async itemchanged(row: YearModel, item: any, name: string, rowUpdate: RowUpdate, callback?: VoidFunction) {
    const { actionStore: api } = this.props;

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    row[name] = item;

    const data = await api.fxExec('dw_list_itemchanged', {
      itemname: 'iwolnum_comp',
      data: item,
      holinum_basic: row.holinum_basic,
      iwolnum_comp: row.iwolnum_comp,
      spjangcd: row.spjangcd,
      perid: row.perid,
      hyear: row.hyear,
    });

    rowUpdate({
      ...row, ...data,
    });
    callback && callback();

    // data && this.setState({ focused: new YearModel(data) });

    await this.onRetrieveEvent();
  }

  @action
  async onDetail(isOpen: boolean, data: YearModel = new YearModel()) {
    if (this.state.holiflag === '1') {
      return;
    }

    await this.SS({
      focused: data,
      detailModal: isOpen,
    });

    await this.onDetailModalRetrieve();
  }

  // 상세 버튼 눌렀을때 조회
  @action
  async onDetailModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      detailPopupData: [],
    });

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_pb209_01',
        year: this.state.searchQuery,
        divicd: '',
        divinm: '',
        // perid 앞에 p가붙어있는경우 제거
        perid: this.state.focused?.perid.charAt(0) === 'p' ? this.state.focused?.perid.replace('p', '') : this.state.focused?.perid,
        pernm: this.state.focused?.pernm,
        rtclafi: this.state.rtclafi,
      });

    await this.SS({
      detailPopupData: data?.items,
      detailPopupYear: data?.year,
      detailPopupDivicd: data?.divicd,
      detailPopupDivinm: data?.divinm,
      detailPopupPerid: data?.perid,
      detailPopupPernm: data?.pernm,
      detailPopupRtclafi: data?.rtclafi,
    });
  }

  // 상세 팝업창에서 조회버튼 눌렀을 때 조회
  @action
  async onDetailPopupRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      detailPopupData: [],
    });

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_pb209_01',
        year: this.state.searchQuery,
        divicd: this.state.detailPopupDivicd,
        divinm: this.state.detailPopupDivinm,
        perid: this.state.detailPopupPerid,
        pernm: this.state.detailPopupPernm,
        rtclafi: this.state.rtclafi,
      });

    await this.SS({
      detailPopupData: data?.items,
      detailPopupYear: data?.year,
      detailPopupDivicd: data?.divicd,
      detailPopupDivinm: data?.divinm,
      detailPopupPerid: data?.perid,
      detailPopupPernm: data?.pernm,
      detailPopupRtclafi: data?.rtclafi,
    });
  }

  @action
  async onDetailModalClose() {
    await this.SS({
      detailModal: false,
      detailPopupYear: '',
      detailPopupDivicd: '',
      detailPopupDivinm: '',
      detailPopupPerid: '',
      detailPopupPernm: '',
      detailPopupRtclafi: '',
    });
  }

  @action
  async onDayNumDoubleClick(isOpen: boolean, data: YearModel = new YearModel()) {
    await this.SS({
      focused: data,
      daynumModal: isOpen,
    });

    await this.onDaynumModalRetrieve();
  }

  @action
  async onDaynumModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      daynumPopupData: [],
    });

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_pb209_02',
      year: this.state.searchQuery,
      // perid 앞에 p가붙어있는경우 제거
      perid: this.state.focused?.perid.charAt(0) === 'p' ? this.state.focused?.perid.replace('p', '') : this.state.focused?.perid,
    });

    await this.SS({
      daynumPopupYear: data?.year,
      daynumPopupData: data?.items,
      daynumPopupPerid: data?.perid,
      daynumPopupPernm: this.state.focused?.pernm || '',
    });
  }

  // 팝업에서 조회버튼 클릭 시
  @action
  async onDaynumPopupRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      daynumPopupData: [],
    });

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_pb209_02',
      year: this.state.daynumPopupYear,
      // perid 앞에 p가붙어있는경우 제거
      perid: this.state.daynumPopupPerid,
    });

    await this.SS({
      daynumPopupYear: data?.year,
      daynumPopupData: data?.items,
      daynumPopupPerid: data?.perid,
      daynumPopupPernm: this.state.daynumPopupPernm,
    });
  }

  @action
  async onDaynumModalClose() {
    await this.SS({
      daynumModal: false,
      daynumPopupYear: '',
      daynumPopupPerid: '',
      daynumPopupPernm: '',
    });
  }

  @action
  async refresh() {
    const { publicStore } = this.props;

    // 프론트단에서 처리하기로하여 주석처리함.
    // const data = await api.fxExec('wb_refresh', {
    //   year: this.state.searchQuery,
    //   holiflag: this.state.holiflag,
    // });
    // data && this.setState({ focused: new YearModel(data) });

    // 휴가신청서, 근태맞춤
    publicStore.mqtt.publish('/taskcycle/task/run', JSON.stringify({ opcode: 3, custcd: publicStore.user.custcd, command: 'python/15' }));

    ConfirmWarning.show('확인', '성공적으로 연차가 갱신되었습니다\n갱신이 안되었을경우에 최대1분정도 소요될 수 있습니다\n최대 1분이내 조회를 하시면 갱신되어 있으실겁니다\'');

    await this.onRetrieveEvent();
  }

  @action
  onRowFocusEvent(item: YearModel) {
    this.setState({
      focused: item,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<YearModel>, updatedRows: Array<YearModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <YearTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
