import { computed } from 'mobx';

/**
 * 메인모델
 * @window w_tb_da051
 * @category 회사->개인목표계획등록
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 계획월
   *
   * **data type** char(6)
   */
  planmon: string;

  /**
   * 전년도실적
   *
   * **data type** number
   */
  besamt: string;

  /**
   * 당해년도계획
   *
   * **data type** number
   */
  samt: string;

  /**
   * 변동금액
   *
   * **data type** number
   */
  addsamt: string;

  /**
   * 변동율
   *
   * **data type** number
   */
  addyul: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.planmon = data.planmon || '';
    this.besamt = data.besamt || '';
    this.samt = data.samt || '';
    this.addsamt = data.addsamt || '';
    this.addyul = data.addyul || '';
    this.remark = data.remark || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
