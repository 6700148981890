import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { MonthResult } from '../MonthResult';
import { GridLayout } from '../../../../../components/layout/GridLayout';
import { MonthGrid } from '../headers/MonthGrid';

/**
 * 화면 > 탭 월별합계
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export const MonthTemplate: React.FC<TemplateProps<MonthResult>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <GridLayout
        ref={scope.gridMonth}
        scope={scope}
        header={MonthGrid(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.months}
        infinityHandler={scope.infinity}
      />
    </FlexLayout>
);
