import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  ComboBoxModel,
  ComboBox,
  FormatTextBox,
  TelnumTextBox,
  GridLayout,
  LayoutTitle,
  DateTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Monthly } from './Monthly';
import style from './Monthly.module.scss';

// const rtclafies = [
//   { value: '001', remark: '입사' },
//   { value: '002', remark: '퇴사' },
// ];

/**
 * 화면
 * @window w_tb_ja001_02
 * @category 월별입퇴사자현황
 */
export const MonthlyTemplate: React.FC<TemplateProps<Monthly>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.searchQuery}
            textAlign="center"
            format="####"
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={(value) => setData(({ searchQuery: value }))}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.searchQuery}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ searchQuery: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.searchQuery}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  setData({ searchQuery: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm || '전체'))}
            onSelect={(option) => scope.setState({ spjangcd: option.value })}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span>구분</span>
          <ComboBox
            value={scope.state.rtclafi}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={[
              { value: '%', remark: '전체' },
              { value: '001', remark: '입사' },
              { value: '002', remark: '퇴사' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ rtclafi: option.value })}
          />
        </FlexLayout>
      </SearchLayout>

      <LayoutTitle>월별입퇴사자현황</LayoutTitle>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <table className={style.blueTable}>
          <tbody>
          <tr>
            <td
              rowSpan={2}
              className={style.head}
            >
              기초인원({parseInt(scope.state.searchQuery, 10) - 1})년
            </td>
            <td
              rowSpan={2}
              className={style.head}
            >
              평균인원({scope.state.searchQuery})년
            </td>
            <td
              colSpan={2}
              className={style.head}
            >
              평균이직률
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              1월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              2월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              3월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              4월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              5월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              6월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              7월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              8월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              9월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              10월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              11월
            </td>
            <td
              colSpan={3}
              className={style.head}
            >
              12월
            </td>
          </tr>
          <tr>
            <td className={style.head}>
              전체
            </td>
            <td className={style.head2}>
              수습X
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
            <td className={style.head3}>
              입사
            </td>
            <td className={style.head4}>
              퇴사
            </td>
            <td className={style.head}>
              인원
            </td>
          </tr>
          <tr>
            <td>
              {scope.state.monthData?.mon}
            </td>
            <td className={style.highlight}>
              {/* 평균 인원 데이터 */}
              {Math.round(parseInt(scope.state.monthData?.reserv, 10))}
            </td>
            <td className={style.highlight}>
              {/* 평균이직률 데이터 */}
              {scope.state.monthData?.aver}
            </td>
            <td className={style.head2}>
              {/* 수습x 데이터 */}
              {scope.state.monthData?.aver3not}
            </td>
            <td className={style.head3}>
              {/* 1월  입사 데이터 */}
              {scope.state.monthData?.newmon01}
            </td>
            <td className={style.head4}>
              {/* 1월 퇴사 데이터 */}
              {scope.state.monthData?.outmon01}
            </td>
            <td className={style.highlight}>
              {/* 1월 인원 데이터 */}
              {scope.state.monthData?.totmon01}
            </td>
            <td className={style.head3}>
              {/* 2월  입사 데이터 */}
              {scope.state.monthData?.newmon02}
            </td>
            <td className={style.head4}>
              {/* 2월 퇴사 데이터 */}
              {scope.state.monthData?.outmon02}
            </td>
            <td className={style.highlight}>
              {/* 2월 인원 데이터 */}
              {scope.state.monthData?.totmon02}
            </td>
            <td className={style.head3}>
              {/* 3월  입사 데이터 */}
              {scope.state.monthData?.newmon03}
            </td>
            <td className={style.head4}>
              {/* 3월 퇴사 데이터 */}
              {scope.state.monthData?.outmon03}
            </td>
            <td className={style.highlight}>
              {/* 3월 인원 데이터 */}
              {scope.state.monthData?.totmon03}
            </td>
            <td className={style.head3}>
              {/* 4월  입사 데이터 */}
              {scope.state.monthData?.newmon04}
            </td>
            <td className={style.head4}>
              {/* 4월 퇴사 데이터 */}
              {scope.state.monthData?.outmon04}
            </td>
            <td className={style.highlight}>
              {/* 4월 인원 데이터 */}
              {scope.state.monthData?.totmon04}
            </td>
            <td className={style.head3}>
              {/* 5월  입사 데이터 */}
              {scope.state.monthData?.newmon05}
            </td>
            <td className={style.head4}>
              {/* 5월 퇴사 데이터 */}
              {scope.state.monthData?.outmon05}
            </td>
            <td className={style.highlight}>
              {/* 5월 인원 데이터 */}
              {scope.state.monthData?.totmon05}
            </td>
            <td className={style.head3}>
              {/* 6월  입사 데이터 */}
              {scope.state.monthData?.newmon06}
            </td>
            <td className={style.head4}>
              {/* 6월 퇴사 데이터 */}
              {scope.state.monthData?.outmon06}
            </td>
            <td className={style.highlight}>
              {/* 6월 인원 데이터 */}
              {scope.state.monthData?.totmon06}
            </td>
            <td className={style.head3}>
              {/* 7월  입사 데이터 */}
              {scope.state.monthData?.newmon07}
            </td>
            <td className={style.head4}>
              {/* 7월 퇴사 데이터 */}
              {scope.state.monthData?.outmon07}
            </td>
            <td className={style.highlight}>
              {/* 7월 인원 데이터 */}
              {scope.state.monthData?.totmon07}
            </td>
            <td className={style.head3}>
              {/* 8월  입사 데이터 */}
              {scope.state.monthData?.newmon08}
            </td>
            <td className={style.head4}>
              {/* 8월 퇴사 데이터 */}
              {scope.state.monthData?.outmon08}
            </td>
            <td className={style.highlight}>
              {/* 8월 인원 데이터 */}
              {scope.state.monthData?.totmon08}
            </td>
            <td className={style.head3}>
              {/* 9월  입사 데이터 */}
              {scope.state.monthData?.newmon09}
            </td>
            <td className={style.head4}>
              {/* 9월 퇴사 데이터 */}
              {scope.state.monthData?.outmon09}
            </td>
            <td className={style.highlight}>
              {/* 9월 인원 데이터 */}
              {scope.state.monthData?.totmon09}
            </td>
            <td className={style.head3}>
              {/* 10월  입사 데이터 */}
              {scope.state.monthData?.newmon10}
            </td>
            <td className={style.head4}>
              {/* 10월 퇴사 데이터 */}
              {scope.state.monthData?.outmon10}
            </td>
            <td className={style.highlight}>
              {/* 10월 인원 데이터 */}
              {scope.state.monthData?.totmon10}
            </td>
            <td className={style.head3}>
              {/* 11월  입사 데이터 */}
              {scope.state.monthData?.newmon11}
            </td>
            <td className={style.head4}>
              {/* 11월 퇴사 데이터 */}
              {scope.state.monthData?.outmon11}
            </td>
            <td className={style.highlight}>
              {/* 11월 인원 데이터 */}
              {scope.state.monthData?.totmon11}
            </td>
            <td className={style.head3}>
              {/* 12월  입사 데이터 */}
              {scope.state.monthData?.newmon12}
            </td>
            <td className={style.head4}>
              {/* 12월 퇴사 데이터 */}
              {scope.state.monthData?.outmon12}
            </td>
            <td className={style.highlight}>
              {/* 12월 인원 데이터 */}
              {scope.state.monthData?.totmon12}
            </td>
          </tr>
          </tbody>
        </table>
      </FlexLayout>

      <LayoutTitle style={{ textAlign: 'left' }}>
        * 월별 상세현황
      </LayoutTitle>
      <GridLayout
        header={[
          {
            id: 'spjangnm',
            text: '사업장명',
            width: 7,
            sum: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {x.rtmon.substr(0, 4)}년  {x.rtmon.substr(5, 2)}월
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.yeartot}
              </FlexLayout>
            ),
          },
          {
            id: 'rtmon',
            text: '발생월',
            isHidden: true,
            group: true,
            value: (x) => x.rtmon,
          },
          {
            id: 'rtclafi',
            text: '입퇴사',
            width: 8,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.rtclafi === '001' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.rtclafi === '001' ? '입사' : '퇴사'}
              </FlexLayout>
            ),
            sum: (x, data) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                변경인원 총 {data.filter((y) => y.rtmon.substr(0, 6) === x.rtmon).length}명
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                변경인원 총  {scope.state.monthData.total}명
              </FlexLayout>
            ),
          },
          {
            id: 'perid',
            text: '사원번호',
            width: 5,
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 5,
            sum: (x, data) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                입사:{data.filter((y) => y.rtmon.substr(0, 6) === x.rtmon && y.rtclafi === '001').length}명
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.rt001}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '사원명',
            width: 5,
            sum: (x, data) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                퇴사:{data.filter((y) => y.rtmon.substr(0, 6) === x.rtmon && y.rtclafi === '002').length}명
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.rt002}
              </FlexLayout>
            ),
          },
          {
            id: 'rspnm',
            text: '직급',
            width: 5,
          },
          {
            id: 'entdate',
            text: '입사일',
            width: 5,
            render: (x) => (
              <FormatTextBox
                textAlign='center'
                format="####-##-##"
                value={x.entdate}
              />
            ),
          },
          {
            id: 'rtdate',
            text: '퇴사일',
            width: 5,
            render: (x) => (
              <FormatTextBox
                textAlign='center'
                format="####-##-##"
                value={x.rtdate}
              />
            ),
          },
          {
            id: 'dayterm',
            text: '근속년수',
            width: 5,
          },
          {
            id: 'insudate',
            text: '4대보험취득일',
            width: 5,
            render: (x) => (
              <FormatTextBox
                textAlign='center'
                format="####-##-##"
                value={x.insudate}
              />
            ),
          },
          {
            id: 'inoutdate',
            text: '4대보험상실일',
            width: 5,
            render: (x) => (
              <FormatTextBox
                textAlign='center'
                format="####-##-##"
                value={x.inoutdate}
              />
            ),
          },
          {
            id: 'handphone',
            text: '핸드폰번호',
            width: 6,
            render: (x) => (
              <TelnumTextBox
                textAlign='center'
                value={x.handphone}
              />
            ),
          },
          {
            id: 'zipadres',
            text: '주소',
            width: 28,
            render: (x) => (
              <FlexLayout
                align="center"
                justify="start"
              >
                {x.zipadres}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.data}
      />
    </FlexLayout>
  );
};
