import * as React from 'react';
import { action } from 'mobx';
import { RefObject } from 'react';
import {
  ConfirmType, PageProps, PageToolEvents, PAPERCD, RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ResultModel from './models/ResultModel';
import ResultModalModel from './models/ResultModalModel';
import { ResultTemplate } from './Result.template';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { Date6 } from '../../../../utils/time';

export const Gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '19', remark: '기타수입' },
];

interface SampleState {
  searchQuery: string;
  stmon: string;
  endmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  gubun: string;

  content?: ResultModel;

  data: Array<ResultModel>;
  dataMis: Array<any>;
  dataMij: Array<any>;

  total?: string;
  iamt_tot?: string;
  mijamt_tot?: string;
  ordamt_tot?: string;
  rcvamt_tot?: string;
  useamt2_tot?: string;
  useamt3_tot?: string;
  useamt_tot?: string;

  isVisibleModal: boolean;
  modalMisSamt_tot?: string;
  modalMijSamt_tot?: string;

  gubuns: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export class Result extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityMis?: InfinityRetrieve;

  infinityMij?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  gridFocus?: ResultModel;

  gridFocusIndex: number = 0;

  tabIndex: number = 0;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    // const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    this.state = props.state || {
      searchQuery: '',
      stmon: Date6.make(),
      endmon: Date6.make(),
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      gubun: '%',

      data: [],
      content: new ResultModel(),

      isVisibleModal: false,

      gubuns: [],
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_da020');
    await this.SS({
      gubuns: data?.items,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon || Date6.make(),
        as_nm: this.state.searchQuery,
        divicd: this.state.divicd || '%',
        perid: this.state.perid || '%',
        gubun: this.state.gubun,
      },
      (params) => api.fxExec('retrieve', params),
      (items, next) => {
        this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new ResultModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length > 0) {
          this.onRowFocusEvent(this.state.data[0], 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });
    const index = await this.infinity?.retrieveTo('projno',
      this.state.content?.projno, type, true) || 0;
    const data = await this.infinity.box;
    await this.SS({
      total: data?.total,
      iamt_tot: data?.iamt_tot,
      mijamt_tot: data?.mijamt_tot,
      ordamt_tot: data?.ordamt_tot,
      rcvamt_tot: data?.rcvamt_tot,
      useamt2_tot: data?.useamt2_tot,
      useamt3_tot: data?.useamt3_tot,
      useamt_tot: data?.useamt_tot,
    });

    if (this.state.data.length > 0) {
      this.grid.current?.setFocus(index);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stmon: this.state.stmon,
      projno: this.gridFocus?.projno,
    });
  }


  @action
  async onReport(isForce: boolean = false) {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    const { user } = this.props.publicStore;

    let text = '';
    let appflag = '';

    if (user.perid !== this.gridFocus?.perid) {
      ConfirmWarning.show('경고', '본인이 주공사자가 아닌 경우 상신할 수 없습니다');
      return;
    }

    if (!isForce && this.gridFocus?.isReported && !this.gridFocus?.isReportedCancelable) {
      ConfirmWarning.show('오류', '현재 결재 상태에서는 취소할 수 없습니다');
      return;
    }

    if (this.gridFocus?.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    if (appflag === 'ok') {
      if (await modalStore.openApprovalLine(PAPERCD.ENDCONSTRUCT)) {
        await api.fxExec('wb_appreport', {
          papercd: PAPERCD.ENDCONSTRUCT.toString(),
          stmon: this.state.stmon,
          ...this.gridFocus,
          appflag,
          rcvflag: this.state.content?.rcvflag,
        }) && this.onRetrieveEvent();
      }
    } else {
      await api.fxExec('wb_appreport', {
        papercd: PAPERCD.ENDCONSTRUCT.toString(),
        stmon: this.state.stmon,
        ...this.gridFocus,
        appflag,
        rcvflag: this.state.content?.rcvflag,
      }) && this.onRetrieveEvent();
    }
  }


  @action
  async onRowFocusEvent(item: ResultModel | undefined, index: number) {
    this.SS({ content: item });
    this.gridFocus = item;
    this.gridFocusIndex = index;
  }

  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    this.onRowFocusEvent(this?.gridFocus, this.gridFocusIndex);
  }


  @action
  async openModal() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;

    this.infinityMis = new InfinityRetrieve(
      {
        sub: 'w_popup_e471w_02',
        projno: this.gridFocus?.projno,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.SS({
          dataMis: [
            ...this.state.dataMis,
            ...items?.map((x) => new ResultModalModel(x)) || [],
          ],
        });
      },
      async () => {
        await this.SS({
          dataMis: [],
        });
        await this.infinityMis?.retrieve();
      },
    );

    this.infinityMij = new InfinityRetrieve(
      {
        sub: 'w_popup_e471w_02',
        projno: this.gridFocus?.projno,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.SS({
          dataMij: [
            ...this.state.dataMij,
            ...items?.map((x) => new ResultModalModel(x)) || [],
          ],
        });
      },
      async () => {
        await this.SS({
          dataMij: [],
        });
        await this.infinityMij?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataMis: [],
      dataMij: [],
    });

    const dataMis = await this.infinityMis?.retrieve();
    const dataMij = await this.infinityMij?.retrieve();

    this.SS({
      modalMisSamt_tot: dataMis?.samt_tot,
      modalMijSamt_tot: dataMij?.samt_tot,
      isVisibleModal: true,
    });
  }


  render() {
    return <ResultTemplate
      scope={this}
    />;
  }
}
