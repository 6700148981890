import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642w_17
 * @category 거래처월별 매입/지급현황
 */
export class ClientMonthLeftModel {
  /**
   * 업종코드
   *
   * **data type** varchar(8)
   */
  readonly code: string;

  /**
   * 업종
   *
   * **data type** varchar(8)
   */
  readonly cnam: string;

  /**
   * 체크
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.code = data.code || '';
    this.cnam = data.cnam || '';
    this.chk = data.chk || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
