import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e471
 * @category 공사등록
 */
export default class EnrollmentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 결재구분
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   * 첨부파일
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 첨부파일 유무
   *
   * **data type** varchar(8)
   */
  readonly attflag: string;

  /**
   * 과세구분
   *
   * **data type** varchar(8)
   */
  readonly billgubun: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(8)
   */
  readonly billkind: string;

  /**
   * 카드수수료
   *
   * **data type** varchar(8)
   */
  readonly camt: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 공사일자
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly compflag: string;

  /**
   * 공사구분
   *
   * **data type** varchar(8)
   */
  readonly compgubun: string;

  /**
   * 공사번호
   *
   * **data type** varchar(8)
   */
  readonly compnum: string;

  /**
   * 처리자
   *
   * **data type** varchar(8)
   */
  readonly compperid: string;

  /**
   * 처리자2
   *
   * **data type** varchar(8)
   */
  readonly compperid2: string;

  /**
   * 처리자3
   *
   * **data type** varchar(8)
   */
  readonly compperid3: string;

  /**
   * 처리자명
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   * 처리자2 이름
   *
   * **data type** varchar(8)
   */
  readonly comppernm2: string;

  /**
   * 처리자3 이름
   *
   * **data type** varchar(8)
   */
  readonly comppernm3: string;

  /**
   * 처리상세
   *
   * **data type** varchar(8)
   */
  readonly compremark: string;

  /**
   * 매출여부
   *
   * **data type** varchar(8)
   */
  readonly compyn: string;

  /**
   * 견적서명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contgubun: string;

  /**
   * 계약번호
   *
   * **data type** varchar(8)
   */
  readonly contnum: string;

  /**
   * 계약자
   *
   * **data type** varchar(8)
   */
  readonly contperid: string;

  /**
   * 계약자2
   *
   * **data type** varchar(8)
   */
  readonly contperid2: string;

  /**
   * 계약자3
   *
   * **data type** varchar(8)
   */
  readonly contperid3: string;

  /**
   * 계약자명
   *
   * **data type** varchar(8)
   */
  readonly contpernm: string;

  /**
   * 계약자2 이름
   *
   * **data type** varchar(8)
   */
  readonly contpernm2: string;

  /**
   * 계약자3 이름
   *
   * **data type** varchar(8)
   */
  readonly contpernm3: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서2
   *
   * **data type** varchar(8)
   */
  readonly divicd2: string;

  /**
   * 부서3
   *
   * **data type** varchar(8)
   */
  readonly divicd3: string;

  /**
   * 견적특기사항
   *
   * **data type** varchar(8)
   */
  readonly e451remark: string;

  /**
   * 공사종료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly flag: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 입력자명
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 서명1
   *
   * **data type** varchar(8)
   */
  readonly ks1flag: string;

  /**
   * 서명2
   *
   * **data type** varchar(8)
   */
  readonly ks2flag: string;

  /**
   * 확인서
   *
   * **data type** varchar(8)
   */
  readonly ks3flag: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  readonly misdate: string;

  /**
   * 매출번호
   *
   * **data type** varchar(8)
   */
  readonly misnum: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly offinm: string;

  /**
   * 승인도서 일자
   *
   * **data type** varchar(8)
   */
  readonly okdate: string;

  /**
   * 승인도서
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly okperid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly okpernm: string;

  /**
   * 출금일
   *
   * **data type** varchar(8)
   */
  readonly outdate: string;

  /**
   * 출금
   *
   * **data type** varchar(8)
   */
  readonly outflag: string;

  /**
   * 휴대폰번호
   *
   * **data type** varchar(8)
   */
  readonly perhp: string;

  /**
   * 이메일
   *
   * **data type** varchar(8)
   */
  readonly permail: string;

  /**
   * 이름
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 전화번호
   *
   * **data type** varchar(8)
   */
  readonly pertel: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ramt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly receflag: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly remarkflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s1flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s2flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s3flag: string;

  /**
   * 할인금액
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   * 할인율
   *
   * **data type** varchar(8)
   */
  readonly saleyul: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 사업자코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly store: string;

  /**
   * 부가세액
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(8)
   */
  readonly taxcls: string;

  /**
   * 부가세구분
   *
   * **data type** varchar(8)
   */
  readonly taxgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total2: string;

  /**
   * 회사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 회사명
   *
   * **data type** varchar(8)
   */
  readonly wkactnm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcode: string;

  /**
   * saleflag
   *
   * **data type** varchar(8)
   */
  readonly saleflag: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  /**
   * ? 날짜
   *
   * **data type** varchar(1)
   */
  ks1indate: string;

  /**
   * ? 날짜
   *
   * **data type** varchar(1)
   */
  s1indate: string;

  /**
   * ? 날짜
   *
   * **data type** varchar(1)
   */
  ks2indate: string;

  /**
   * ? 날짜
   *
   * **data type** varchar(1)
   */
  s2indate: string;

  /**
   * ? 날짜
   *
   * **data type** varchar(1)
   */
  ks3indate: string;

  /**
   * ? 날짜
   *
   * **data type** varchar(1)
   */
  s3indate: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.appgubun = data.appgubun || '';
    this.attcnt = data.attcnt || '';
    this.attflag = data.attflag || '';
    this.billgubun = data.billgubun || '';
    this.billkind = data.billkind || '';
    this.camt = data.camt || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.compdate = data.compdate || '';
    this.compflag = data.compflag || '';
    this.compgubun = data.compgubun || '';
    this.compnum = data.compnum || '';
    this.compperid = data.compperid || '';
    this.compperid2 = data.compperid2 || '';
    this.compperid3 = data.compperid3 || '';
    this.comppernm = data.comppernm || '';
    this.comppernm2 = data.comppernm2 || '';
    this.comppernm3 = data.comppernm3 || '';
    this.compremark = data.compremark || '';
    this.compyn = data.compyn || '';
    this.constnm = data.constnm || '';
    this.contdate = data.contdate || '';
    this.contgubun = data.contgubun || '';
    this.contnum = data.contnum || '';
    this.contperid = data.contperid || '';
    this.contperid2 = data.contperid2 || '';
    this.contperid3 = data.contperid3 || '';
    this.contpernm = data.contpernm || '';
    this.contpernm2 = data.contpernm2 || '';
    this.contpernm3 = data.contpernm3 || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.divicd = data.divicd || '';
    this.divicd2 = data.divicd2 || '';
    this.divicd3 = data.divicd3 || '';
    this.e451remark = data.e451remark || '';
    this.enddate = data.enddate || '';
    this.flag = data.flag || '';
    this.gubun = data.gubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.ks1flag = data.ks1flag || '';
    this.ks2flag = data.ks2flag || '';
    this.ks3flag = data.ks3flag || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.offinm = data.offinm || '';
    this.okdate = data.okdate || '';
    this.okflag = data.okflag || '';
    this.okperid = data.okperid || '';
    this.okpernm = data.okpernm || '';
    this.outdate = data.outdate || '';
    this.outflag = data.outflag || '';
    this.perhp = data.perhp || '';
    this.permail = data.permail || '';
    this.pernm = data.pernm || '';
    this.pertel = data.pertel || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.ramt = data.ramt || '';
    this.receflag = data.receflag || '';
    this.remark = data.remark || '';
    this.remarkflag = data.remarkflag || '';
    this.s1flag = data.s1flag || '';
    this.s2flag = data.s2flag || '';
    this.s3flag = data.s3flag || '';
    this.saleamt = data.saleamt || '';
    this.saleyul = data.saleyul || '';
    this.samt = data.samt || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.stdate = data.stdate || '';
    this.store = data.store || '';
    this.tamt = data.tamt || '';
    this.total = data.total || '';
    this.total2 = data.total2 || '';
    this.taxcls = data.taxcls || '';
    this.taxgubun = data.taxgubun || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.zipcode = data.zipcode || '';
    this.saleflag = data.saleflag || '0';
    this.ks1indate = data.ks1indate || '';
    this.s1indate = data.s1indate || '';
    this.ks2indate = data.ks2indate || '';
    this.s2indate = data.s2indate || '';
    this.ks3indate = data.ks3indate || '';
    this.s3indate = data.s3indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
