import { computed } from 'mobx';

/**
 * 회사구분_디테일 모델
 * @window w_tb_e015
 * @category 유지보수관리코드
 */
export class CompanyModel2 {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(3)
   */
  readonly wkactcd: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 담당자
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 휴대폰번호
   *
   * **data type** varchar(20)
   */
  readonly tel: string;

  /**
   * 사람갇힘문자발송
   *
   * **data type** varchar(1)
   */
  readonly trouble_sms: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.wkactcd = data.wkactcd || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.tel = data.tel || '';
    this.trouble_sms = data.trouble_sms || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
