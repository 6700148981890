import * as React from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import {
  AskType,
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import RegistModel from './models/RegistModel';
import { RegistTemplate } from './Regist.template';
import { PageComponent } from '../../../../utils';
import {
  Date6,
  Date8,
  DateStabilizer,
} from '../../../../utils/time';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { ApiService } from '../../../../utils/string';
import {
  PersonPopupModel,
} from '../../../maintenance/management/Enrollment/models';

interface RegistState {
  searchQuery: string;
  gubun: string;
  contflag: string;
  okbook: string;
  compbook: string;
  stmon: string;
  endmon: string;
  data: Array<RegistModel>;
  focusIndex: number;
  processDrawingEqupcd: string;
  content: RegistModel;
  gubuns: Array<any>;
  detailConferenceFocused: RegistModel;
  detailConferenceRemark: string;

  // Tab data
  tabDataProcess: Array<any>;
  tabDataConference: Array<any>;
  tabDataTel: Array<any>;
  tabDataScAmt: Array<any>;
  tabDataSuju: Array<any>;
  tabDataBal: Array<any>;
  tabDataEstimate: Array<any>;
  tabDataSales: Array<any>;

  // Modal - drawing attachment
  isVisibleProcessDrawing: boolean;
  isVisibleBosu: boolean;

  // Grid total
  state1_tot: string;
  state2_tot: string;
  cnt_tot: string;
  mamt_tot: string;
  total7: string;
  samt_tot: string;
  tamt_tot: string;
  total8: string;
  taxDetailModal: boolean;

  PersonPopUpData: Array<PersonPopupModel>;
  PersonPopUpFocusedData: PersonPopupModel;

  // 설치작업자 modal
  scPerModal: boolean;
  scPerModalData: Array<RegistModel>;
  scPerModalFocused: RegistModel;
}

/**
 * 컨트롤러
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export class Regist extends PageComponent<PageProps, RegistState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityModal2?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  tableProcess: React.RefObject<TableLayout> = React.createRef();

  tableConference: React.RefObject<TableLayout> = React.createRef();

  tableTel: React.RefObject<TableLayout> = React.createRef();

  tableScAmt: React.RefObject<TableLayout> = React.createRef();

  tableCheckPerson: React.RefObject<TableLayout> = React.createRef();

  gridBal: React.RefObject<GridLayout> = React.createRef();

  gridEstimate: React.RefObject<GridLayout> = React.createRef();

  gridSales: React.RefObject<GridLayout> = React.createRef();

  tableScPerModal: React.RefObject<TableLayout> = React.createRef();


  // Tab data updates
  updatesDataProcess: Array<any>;

  updatesDataConference: Array<any>;

  updatesDataTel: Array<any>;

  updatesDataScAmt: Array<any>;

  dataBildju: Array<any> = [];


  // Tab table index
  tabTableProcessIndex: number;

  tabTableConferenceIndex: number;

  tabTableTelIndex: number;

  tabTableScAmtIndex: number;


  // Tab
  tabIndex: number = 0;


  // Grid
  gridBalIndex: number;

  gridEstimateIndex: number;

  gridSalesIndex: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    // Tab data updates
    this.updatesDataProcess = [];
    this.updatesDataConference = [];
    this.updatesDataTel = [];
    this.updatesDataScAmt = [];

    // Tab table index
    this.tabTableProcessIndex = -1;
    this.tabTableConferenceIndex = -1;
    this.tabTableTelIndex = -1;
    this.tabTableScAmtIndex = -1;

    // Tab bal index
    this.gridBalIndex = -1;
    this.gridEstimateIndex = -1;
    this.gridSalesIndex = -1;

    this.state = props.state || {
      searchQuery: pageParams.searchQuery || '',
      gubun: '%',
      contflag: '%',
      okbook: '%',
      compbook: '%',
      stmon: pageParams.mon || '000000',
      endmon: pageParams.mon || Date6.make(),
      data: [],
      focusIndex: 0,
      processDrawingEqupcd: '001',
      content: new RegistModel(),

      // Tab data
      tabDataProcess: [],
      tabDataConference: [],
      tabDataTel: [],
      tabDataScAmt: [],
      tabDataSuju: [],
      tabDataBal: [],
      tabDataEstimate: [],
      tabDataSales: [],

      // Modal
      isVisibleProcessDrawing: false,
      isVisibleBosu: false,

      // Grid total
      state1_tot: '0',
      state2_tot: '0',
      cnt_tot: '0',
      mamt_tot: '0',
      total7: '0',
      total8: '0',

      detailConferenceRemark: '',

      taxDetailModal: false,

      // 설치작업자 modal
      scPerModal: false,
      scPerModalData: [],
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams.searchQuery || this.state.searchQuery,
        stmon: pageParams.mon || this.state.stmon,
        endmon: pageParams.mon || this.state.endmon,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    this.dataBildju = (await api.dropdown('wf_dd_ca510_301_01'))?.items || [];

    // 매출구분
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ gubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        contflag: this.state.contflag,
        okbook: this.state.okbook,
        compbook: this.state.compbook,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new RegistModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          await this.onRowFocusEvent(this.state.data[0], 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.content;
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('actcd', lastSelected.actcd, type, true) || 0;

      await this.SS({
        state1_tot: this.infinity?.box?.state1_tot,
        state2_tot: this.infinity?.box?.state2_tot,
        cnt_tot: this.infinity?.box?.cnt_tot,
        mamt_tot: this.infinity?.box?.mamt_tot,
        total7: this.infinity?.box?.total7 || '0',
        tamt_tot: this.infinity?.box?.tamt_tot || '0',
        samt_tot: this.infinity?.box?.samt_tot || '0',
        total8: this.infinity?.box?.total8 || '0',
      });

      if (!autoLoad) return;
      this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.searchQuery !== '' || this.state.endmon < Date6.make()
      || this.state.stmon !== '197001' || this.state.gubun !== '%'
      || this.state.contflag !== '%' || this.state.okbook !== '%' || this.state.compbook !== '%') {
      await this.SS({
        searchQuery: '',
        stmon: '000000',
        endmon: Date6.make(),
        gubun: '%',
        contflag: '%',
        okbook: '%',
        compbook: '%',
      });
      // 필요없을거같아서 주석처리함
      // await this.onRetrieveEvent(RetrieveFocusType.FIRST, false);
    }
    const data = await api.new();
    if (data) {
      const item = new RegistModel({
        ...data,
        qty: '1',
      }, true);

      await this.SS({
        data: [item, ...this.state.data],
        content: item,
      });

      await this.initDocument(0);

      this.tableProcess.current?.update(true);
      this.tableConference.current?.update(true);
      this.tableTel.current?.update(true);
      this.tableScAmt.current?.update(true);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const result = await api.fxExec(
      `tab_${this.tabIndex + 1}_save`,
      {
        ...this.state.content,
        items: this.getCurrentTabData().map((x: any) => ApiService.notNull(x)),
      },
      this.state.content?.isNew,
    );

    if (result) {
      const futureSearchRange = DateStabilizer.get(this.state.stmon, this.state.endmon, this.state.content.orddate);
      this.setState({
        stmon: futureSearchRange.stdate,
        endmon: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.content?.actcd} - ${this.state.content?.scactnm}`;

    if (await api.delete(text, this.state.content)) {
      this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { modalStore } = this.props;
    const json = JSON.parse(JSON.parse(message));

    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      const cltResponse = await modalStore.openAddClt(this.state.content.cltcd, '2');
      this.setState({
        content: new RegistModel({
          ...this.state.content,
          cltcd: cltResponse.cltcd,
          cltnm: cltResponse.cltnm,
        }),
      });
    }
  }

  @action
  async initDocument(index: number) {
    this.updatesDataProcess = [];
    this.updatesDataConference = [];
    this.updatesDataTel = [];
    this.updatesDataScAmt = [];

    this.tabTableProcessIndex = -1;
    this.tabTableConferenceIndex = -1;
    this.tabTableTelIndex = -1;
    this.tabTableScAmtIndex = -1;
    this.gridBalIndex = -1;
    this.gridEstimateIndex = -1;
    this.gridSalesIndex = -1;

    await this.SS({
      focusIndex: index,
      tabDataProcess: [],
      tabDataConference: [],
      tabDataTel: [],
      tabDataScAmt: [],
      tabDataSuju: [],
      tabDataBal: [],
      tabDataEstimate: [],
      tabDataSales: [],
    });
  }

  @action
  async onRowFocusEvent(item: RegistModel, index: number) {
    await this.initDocument(index);

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_list_RowFocuschanged', {
      actcd: item?.actcd,
    });

    if (data) {
      await this.SS({
        content: new RegistModel(data, item?.isNew),
        tabDataProcess: data?.items1 || [],
        tabDataConference: data?.items2 || [],
        tabDataTel: data?.items3 || [],
        tabDataScAmt: data?.items4 || [],
        tabDataSuju: data?.items5 || [],
        tabDataBal: data?.items6 || [],
        tabDataEstimate: data?.items7 || [],
        tabDataSales: data?.items8 || [],
        total7: data?.total7 || '0',
        samt_tot: data?.samt_tot || '0',
        tamt_tot: data?.tamt_tot || '0',
        mamt_tot: data?.mamt_tot || '0',
        total8: data?.total8 || '0',
      });
    }
    await this.onTabSetFocus();
  }

  @action
  async onTabSetFocus() {
    if (this.tabIndex === 0) {
      this.tableProcess.current?.setFocus(0);
    } else if (this.tabIndex === 1) {
      this.tableConference.current?.setFocus(0);
    } else if (this.tabIndex === 2) {
      this.tableTel.current?.setFocus(0);
    } else if (this.tabIndex === 3) {
      this.tableScAmt.current?.setFocus(0);
    } else if (this.tabIndex === 4) {
      this.gridBal.current?.setFocus(0);
    } else if (this.tabIndex === 5) {
      this.gridEstimate.current?.setFocus(0);
    } else if (this.tabIndex === 6) {
      this.gridSales.current?.setFocus(0);
    }

    await this.tableProcess.current?.update(true);
    await this.tableConference.current?.update(true);
    await this.tableTel.current?.update(true);
    await this.tableScAmt.current?.update(true);
    await this.gridBal.current?.forceRepaint();
    await this.gridEstimate.current?.forceRepaint();
    await this.gridSales.current?.forceRepaint();
  }


  @action
  async onTabTableProcessChange(rows: Array<any>, updates: Array<any>) {
    await this.SS({ tabDataProcess: rows });
    this.updatesDataTel = updates;
  }

  @action
  async onTabTableConferenceChange(rows: Array<any>, updates: Array<any>) {
    await this.SS({ tabDataConference: rows });
    this.updatesDataTel = updates;
  }

  @action
  async onTabTableTelChange(rows: Array<any>, updates: Array<any>) {
    await this.SS({ tabDataTel: rows });
    this.updatesDataTel = updates;
  }

  @action
  async onTabTableScAmtChange(rows: Array<any>, updates: Array<any>) {
    await this.SS({ tabDataScAmt: rows });
    this.updatesDataTel = updates;
  }


  @action
  onTabTableProcessRowFocused(index: number) {
    this.tabTableProcessIndex = index;
    this.SS({ processDrawingEqupcd: this.state.tabDataProcess[this.tabTableProcessIndex].equpcd });
  }

  onTabTableConferenceRowFocused(item: RegistModel, index: number) {
    this.tabTableConferenceIndex = index;
    this.SS({
      detailConferenceFocused: item,
      detailConferenceRemark: item.remark,
    });
  }

  onTabTableTelRowFocused(index: number) {
    this.tabTableTelIndex = index;
  }

  onTabTableScAmtRowFocused(index: number) {
    this.tabTableScAmtIndex = index;
  }

  onGridEstimateFocused(index: number) {
    this.gridEstimateIndex = index;
  }

  onGridSalesFocused(index: number) {
    this.gridSalesIndex = index;
  }


  onTabChange(index: number) {
    this.tabIndex = index;
    this.onTabSetFocus();
  }

  getRowFocusItemOnActiveTab(): any {
    switch (this.tabIndex) {
      case 0:
        return this.state.tabDataProcess[this.tabTableProcessIndex];

      case 1:
        return this.state.tabDataConference[this.tabTableConferenceIndex];

      case 2:
        return this.state.tabDataTel[this.tabTableTelIndex];

      case 3:
        return this.state.tabDataScAmt[this.tabTableScAmtIndex];

      default:
        return {};
    }
  }

  // eslint-disable-next-line consistent-return
  getCurrentTabData(): any {
    switch (this.tabIndex) {
      case 0:
        return this.state.tabDataProcess || [];

      case 1:
        return this.state.tabDataConference || [];

      case 2:
        return this.state.tabDataTel || [];

      case 3:
        return this.state.tabDataScAmt || [];

      default:
        return [];
    }
  }

  @action
  async onHeaderItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...this.state.content,
      [key]: value,
      itemname: key,
      data: value,
    }, this.state.content.isNew);

    if (result) {
      this.SS({
        content: new RegistModel({
          ...result,
          [key]: value,
        }, this.state.content.isNew),
      });
    }
  }

  @action
  async onDetailItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_3_itemchanged', {
      ...this.getRowFocusItemOnActiveTab(),
      [key]: value,
      itemname: key,
      data: value,
    }, this.getRowFocusItemOnActiveTab().new === '1');

    if (result) {
      rowUpdate(result);
    }
  }


  // 설치 종료 - 버튼
  @action
  async onClickScEnd(key: string) {
    if (key === 'b_scclscancle') {
      if (this.state.content?.sccls !== '1') return;

      const message = `보수현장명: ${this.state.content.actnm}\n을(를) 설치현장으로 돌리시겠습니까?`;
      if (AskType.YES !== await Confirm.ask('경고', message, 'Yes', 'No')) return;
    }

    const { state } = this.state.content;
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_2_buttonclicked', {
      ...this.state.content,
      itemname: key,
      data: '',
    });

    if (result) {
      await this.SS({
        content: new RegistModel(result, this.state.content.isNew),
      });

      await this.onRowFocusEvent(this.state.data[this.state.focusIndex], this.state.focusIndex);
      await this.SS({
        data: update(this.state.data, {
          [this.state.focusIndex]: {
            state: { $set: state === '2' ? '1' : '2' },
          },
        }),
      });
    }
  }


  // 탭 > 공정현황 - 버튼
  @action
  async onClickProcessCreate() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const result = await api.fxExec('wb_e611_create_all', {
        actcd: this.state.content?.actcd,
        qty: this.state.content?.qty || 1,
      });

      if (result) {
        this.onRowFocusEvent(this.state.content, this.state.focusIndex);
      }
    }
  }

  @action
  async onClickProcessNew() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const seq = `${this.state.tabDataProcess.length + 1}`;

      const result = await api.fxExec('wb_e611_new', {
        actcd: this.state.content?.actcd,
        cltcd: this.state.content?.cltcd,
        seq,
      });

      if (result) {
        await this.SS({
          tabDataProcess: [
            ...this.state.tabDataProcess,
            {
              ...result,
              equpcd: `000${seq}`.substr(-3),
              new: '1',
            },
          ],
        });

        await this.tableProcess.current?.update(true);
        this.tableProcess.current?.setFocus(this.state.tabDataProcess.length - 1);
      }
    }
  }

  @action
  async onClickProcessRemove() {
    if (!ConfirmWarning.assert(this.tabTableProcessIndex > -1, '오류', '선택된 행이 없습니다')) {
      return;
    }

    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      if (!this.state.tabDataProcess[this.tabTableProcessIndex].new) {
        const { actionStore: api } = this.props;
        await api.fxExec('wb_e611_delete', {
          actcd: this.state.tabDataProcess[this.tabTableProcessIndex].actcd,
          equpcd: this.state.tabDataProcess[this.tabTableProcessIndex].equpcd,
        });
      }

      await this.SS({
        tabDataProcess: update(this.state.tabDataProcess, {
          $splice: [[this.tabTableProcessIndex, 1]],
        }),
      });

      // Focus next row
      if (this.state.tabDataProcess.length) {
        const next = this.tabTableProcessIndex === 0 ? 0 : this.tabTableProcessIndex - 1;
        this.tabTableProcessIndex = next;

        await this.tableProcess.current?.update(false);
        this.tableProcess.current?.setFocus(next);
      } else {
        this.tabTableProcessIndex = -1;
        await this.tableProcess.current?.update(false);
      }
    }
  }

  @action
  async onClickSendSMS() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    const { publicStore } = this.props;

    const data = await api.exec(Category.INSTALL, 'wb_sms_scremark', {
      actcd: this.state.detailConferenceFocused.actcd,
      remark: this.state.detailConferenceFocused.remark,
    });

    const hpData = data.items?.map((x: { hp: any; }) => ({ tel: x.hp }));
    let smsText = (publicStore.user.spjangnm + data?.text).replace(/\\n/ig, '\n');
    smsText = smsText.replace(/\\/ig, '');

    if (data.items.length > 1) {
      const result = await Confirm.show('확인', `담당연락처에 등록된[핸드폰번호]가 ${data.items.length}명입니다\n문자전송에서 받을사람을 잘 확인해주세요`, ConfirmType.QUESTION);
      if (!result) return;
    }
    // tel -> 받는사람, remark -> 본문내용
    modalStore.openSMS(hpData, smsText);
  }

  // 탭 > 현장협의사항 - 버튼
  @action
  async onClickConferenceNew() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const seq = `00${this.state.tabDataConference.length + 1}`.substr(-2);

      const result = await api.fxExec('wb_e601_sc_remark_new', {
        cltcd: this.state.content?.cltcd,
        actcd: this.state.content?.actcd,
        seq,
      });

      if (result) {
        await this.SS({
          tabDataConference: [
            ...this.state.tabDataConference,
            { ...result, scdate: Date8.make(), new: '1' },
          ],
        });

        await this.tableConference.current?.update(true);
        this.tableConference.current?.setFocus(this.state.tabDataConference.length - 1);
      }
    }
  }

  @action
  async onClickConferenceRemove() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const result = await api.fxExec('wb_e601_sc_remark_delete', {
        cltcd: this.state.tabDataConference[this.tabTableConferenceIndex].cltcd,
        actcd: this.state.tabDataConference[this.tabTableConferenceIndex].actcd,
        seq: this.state.tabDataConference[this.tabTableConferenceIndex].seq,
      });

      if (result) {
        await this.SS({
          tabDataConference: update(this.state.tabDataConference, {
            $splice: [[this.tabTableConferenceIndex, 1]],
          }),
        });

        // Focus next row
        if (this.state.tabDataConference.length) {
          const next = this.tabTableConferenceIndex === 0 ? 0 : this.tabTableConferenceIndex - 1;
          this.tabTableConferenceIndex = next;

          await this.tableConference.current?.update(false);
          this.tableConference.current?.setFocus(next);
        } else {
          this.tabTableConferenceIndex = -1;
          await this.tableConference.current?.update(false);
        }
      }
    }
  }


  // 탭 > 담당자연락처 - 버튼
  @action
  async onClickTelNew() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const seq = `000${this.state.tabDataTel.length + 1}`.substr(-3);

      const result = await api.fxExec('wb_e601_sc_perid_new', {
        cltcd: this.state.content?.cltcd,
        actcd: this.state.content?.actcd,
        seq,
      });

      if (result) {
        await this.SS({
          tabDataTel: [
            ...this.state.tabDataTel,
            { ...result, new: '1' },
          ],
        });

        await this.tableTel.current?.update(false);
        this.tableTel.current?.setFocus(this.state.tabDataTel.length - 1);
      }
    }
  }

  @action
  async onClickTelRemove() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const result = await api.fxExec('wb_e601_sc_perid_delete', {
        actcd: this.state.tabDataTel[this.tabTableTelIndex].actcd,
        seq: this.state.tabDataTel[this.tabTableTelIndex].seq,
      });

      if (result) {
        await this.SS({
          tabDataTel: update(this.state.tabDataTel, {
            $splice: [[this.tabTableTelIndex, 1]],
          }),
        });

        // Focus next row
        if (this.state.tabDataTel.length) {
          const next = this.tabTableTelIndex === 0 ? 0 : this.tabTableTelIndex - 1;
          this.tabTableTelIndex = next;

          await this.tableTel.current?.update(false);
          this.tableTel.current?.setFocus(next);
        } else {
          this.tabTableTelIndex = -1;
          await this.tableTel.current?.update(false);
        }
      }
    }
  }


  // 탭 > 설치비내역 - 버튼
  @action
  async onClickScAmtNew() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const seq = `00${this.state.tabDataScAmt.length + 1}`.substr(-2);

      const result = await api.fxExec('wb_e601_sc_amt_new', {
        cltcd: this.state.content?.cltcd,
        actcd: this.state.content?.actcd,
        seq,
      });

      if (result) {
        await this.SS({
          tabDataScAmt: [
            ...this.state.tabDataScAmt,
            { ...result, new: '1' },
          ],
        });

        await this.tableScAmt.current?.update(false);
        this.tableScAmt.current?.setFocus(this.state.tabDataScAmt.length - 1);
      }
    }
  }

  @action
  async onClickScAmtRemove() {
    if (ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      const { actionStore: api } = this.props;
      const result = await api.fxExec('wb_e601_sc_amt_delete', {
        actcd: this.state.tabDataScAmt[this.tabTableScAmtIndex].actcd,
        seq: this.state.tabDataScAmt[this.tabTableScAmtIndex].seq,
      });

      if (result) {
        await this.SS({
          tabDataScAmt: update(this.state.tabDataScAmt, {
            $splice: [[this.tabTableScAmtIndex, 1]],
          }),
        });

        // Focus next row
        if (this.state.tabDataScAmt.length) {
          const next = this.tabTableScAmtIndex === 0 ? 0 : this.tabTableScAmtIndex - 1;
          this.tabTableScAmtIndex = next;

          await this.tableScAmt.current?.update(false);
          this.tableScAmt.current?.setFocus(next);
        } else {
          this.tabTableScAmtIndex = -1;
          await this.tableScAmt.current?.update(false);
        }
      }
    }
  }

  @action
  async onClickOpenBosu() {
    if (!ConfirmWarning.assert(this.state.content.enddate, '오류', '납기일자를 입력해주세요')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content.actnm, '오류', '현장이름을 입력해주세요')) {
      return;
    }

    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      return;
    }

    const message = `보수현장명: ${this.state.content.actnm}\n을(를) 설치현장으로 돌리시겠습니까?`;
    if (this.state.content.state === '2'
      && AskType.YES === await Confirm.ask('경고', message, 'Yes', 'No')
    ) {
      this.onClickBosu();
      return;
    }

    if (!ConfirmWarning.assert(this.state.content.state !== '2', '오류', '이미 보수로 이관되었습니다')) {
      return;
    }

    this.SS({ isVisibleBosu: true });
  }

  @action
  async onClickBosu() {
    this.SS({ isVisibleBosu: false });

    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      return;
    }

    const gubun = this.state.content.state !== '2' ? '0' : '1';

    const { actionStore: api } = this.props;
    if (await api.fxExec('wb_bosu', {
      actcd: this.state.content.actcd,
      enddate: this.state.content.enddate,
      gubun,
    })) {
      ConfirmSuccess.show('완료', '이관이 완료되었습니다');
      await this.onRowFocusEvent(this.state.data[this.state.focusIndex], this.state.focusIndex);
      await this.SS({
        data: update(this.state.data, {
          [this.state.focusIndex]: {
            state: { $set: gubun === '1' ? '1' : '2' },
          },
        }),
      });
    }
  }


  onGridBalFocused(index: number) {
    this.gridBalIndex = index;
  }

  // 수주서 등록
  async openSujuPaper() {
    const { actionStore: api } = this.props;

    const item = this.state.content;
    let { orddate } = item;
    let message = '';

    if (item.orddate) {
      message = '이미 수주등록되어 있습니다!\n확인하시겠습니까?';
    } else if (!this.state.content?.contdate) {
      ConfirmWarning.show('오류', '계약일을 먼저 입력하세요');
      return;
    } else if (!item.orddate) {
      if (!item.contamt || parseInt(item.contamt, 10) < 1) {
        ConfirmWarning.show('오류', '계약금액을 먼저 입력하세요');
        return;
      }

      await api.fxExec('wb_suju', {
        actcd: this.state.content.actcd,
        contdate: this.state.content.contdate,
        taxcls: this.state.content.taxcls,
        contamt: this.state.content.contamt,
      });

      this.setState({
        content: new RegistModel({
          ...this.state.content,
          orddate: this.state.content.contdate,
        }),
      });

      await this.onRowFocusEvent(this.state.data[this.state.focusIndex], this.state.focusIndex);

      orddate = this.state.content.contdate;
      message = '수주서를 확인하시겠습니까?';
    }

    let result = AskType.YES;
    if (message) {
      result = await Confirm.ask('바로가기', message, '예', '취소');
    }

    if (AskType.YES === result) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_da006', {
        searchQuery: item.scactnm,
        stdate: orddate,
        enddate: orddate,
      });
    }
  }

  // 발주서 등록
  async openBal() {
    const item = this.state.content;

    if (!item.orddate) {
      ConfirmWarning.show('오류', '등록된 수주(프로젝트번호)가 없습니다');
      return;
    }

    if (this.gridBalIndex < 0) {
      ConfirmWarning.show('오류', '항목을 먼저 선택해주세요');
      return;
    }

    if (AskType.YES === await Confirm.ask('바로가기', '해당 발주서를 확인하시겠습니까?', '예', '취소')) {
      const { publicStore } = this.props;

      publicStore.go('/w_tb_ca608_01', {
        stdate: this.state.tabDataBal[this.gridBalIndex].baldate,
        enddate: this.state.tabDataBal[this.gridBalIndex].baldate,
        searchQuery: this.state.tabDataBal[this.gridBalIndex].remark,
      });
    }
  }

  @action
  async onScdividedChk(value: boolean) {
    if (value) {
      this.state.content.scdivided = '1';
    } else {
      this.state.content.scdivided = '0';
    }
    await this.SS({
      content: this.state.content,
    });
  }

  @action
  async onRowFocusEvent2(item: PersonPopupModel) {
    if (item?.isNew) {
      return;
    }

    await this.SS({
      PersonPopUpFocusedData: new PersonPopupModel(item, false),
    });
  }

  @action
  onUpdatedRows2(rows2: any) {
    this.setState({ PersonPopUpData: rows2 });
  }

  @action
  async scperModal(isOpen: boolean) {
    await this.SS({
      scPerModal: isOpen,
    });

    await this.scPerOnRetrieve();
  }

  @action
  async scPerOnRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      scPerModalData: [],
    });
    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e601_scpernm',
      actcd: this.state.content?.actcd,
    });

    await this.SS({
      scPerModalData: data?.items,
    });

    await this.tableScPerModal.current?.update(true);
    await this.tableScPerModal.current?.setFocus(0);
  }

  @action
  async scPerModalNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_popup_e601_scpernm',
      actcd: this.state.content?.actcd,
    });

    // seq 추가
    let newSeq = '';

    if (this.state.scPerModalData === undefined) {
      newSeq = '001';
    } else {
      newSeq = `00${String(this.state.scPerModalData?.length + 1)}`;
    }

    await this.SS({
      scPerModalData: [...this.state.scPerModalData || [], new RegistModel({
        ...data,
        seq: newSeq,
      }, true)],
    });

    await this.tableScPerModal.current?.update(true);
    await this.tableScPerModal.current?.setFocus(this.state.scPerModalData.length - 1);
  }

  scPerModalUpdateEvent(data: Array<RegistModel>) {
    this.SS({
      scPerModalData: data,
    });
  }

  @action
  async scPerModalSaveEvent() {
    const { actionStore: api } = this.props;

    // items 보낼값만
    const sendItems: any = [];
    this.state.scPerModalData.forEach((x) => {
      sendItems.push({
        new: x.new,
        actcd: x.actcd,
        seq: x.seq,
        perid: x.perid,
        pernm: x.pernm,
        mpclafi: x.mpclafi,
        remark: '',
      });
    });

    await api.save({
      sub: 'w_popup_e601_scpernm',
      items: sendItems,
    }, this.state.scPerModalFocused?.isNew) && await this.scPerOnRetrieve();

    await this.onRetrieveEvent();
  }

  @action
  async scPerModalDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `설 치 자: ${this.state.scPerModalFocused?.perid}\n설치자명: ${this.state.scPerModalFocused?.pernm}\n삭제하시겠습니까?`;

    await api.delete(text, {
      sub: 'w_popup_e601_scpernm',
      actcd: this.state.scPerModalFocused?.actcd,
      seq: this.state.scPerModalFocused?.seq,
    }) && await this.scPerOnRetrieve();

    await this.onRetrieveEvent();
  }

  @action
  async scPerModalRowfocusEvent(item: RegistModel) {
    await this.SS({
      scPerModalFocused: new RegistModel(item, item?.isNew),
    });
  }

  @action
  async scperModalClose() {
    await this.SS({
      scPerModal: false,
    });
  }

  @action
  async taxModal(isOpen: boolean) {
    await this.setState({ taxDetailModal: isOpen });
    await this.taxRetrieve();
  }

  @action
  async taxRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      PersonPopUpData: [],
    });

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_tb_e601_cltcd',
        actcd: this.state.content?.actcd,
      },
    );

    if (data?.items) {
      this.setState({
        PersonPopUpData: data?.items,
      });
    }

    await this.tableCheckPerson.current?.update(true);
    await this.tableCheckPerson.current?.setFocus(0);
  }

  @action
  async onNewTaxEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e601_cltcd',
      actcd: this.state.PersonPopUpFocusedData?.actcd,
      seq: this.state.PersonPopUpFocusedData?.seq,
    });

    await this.SS({
      PersonPopUpData: [...this.state.PersonPopUpData || [], new PersonPopupModel(data, true)],
      PersonPopUpFocusedData: new PersonPopupModel(data, true),
    });

    await this.tableCheckPerson.current?.update(true);
    await this.tableCheckPerson.current?.setFocus(this.state.PersonPopUpData.length - 1, 1);
  }

  @action
  async onTaxSaveEvent() {
    const { actionStore: api } = this.props;

    await api.save({
      sub: 'w_tb_e601_cltcd',
      contflag: this.state.content?.contflag,
      contdate: this.state.content?.contdate,
      items: this.state.PersonPopUpData,
    }, this.state.PersonPopUpFocusedData?.isNew);

    await this.tableCheckPerson.current?.update(true);
    await this.tableCheckPerson.current?.setFocus(0);

    await this.setState({
      content: new RegistModel({
        ...this.state.content,
        cltcd: this.state.PersonPopUpFocusedData?.cltcd,
        cltnm: this.state.PersonPopUpFocusedData?.cltnm,
      }, true),
    });
  }

  @action
  async onTaxDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e601_cltcd',
      actcd: this.state.PersonPopUpFocusedData?.actcd,
      contflag: this.state.PersonPopUpFocusedData?.contflag,
      contdate: this.state.PersonPopUpFocusedData?.contdate,
      seq: this.state.PersonPopUpFocusedData?.seq,
    });
    await this.tableCheckPerson.current?.setFocus(0);
    await this.tableCheckPerson.current?.update(true);
  }

  render() {
    return <RegistTemplate
      scope={this}
    />;
  }
}
