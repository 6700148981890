import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  GridLayout,
  LabelBox,
  FormatNumberTextBox,
  ComboBox,
  ComboBoxModel,
  OptionBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PaymentRegister } from './PaymentRegister';
import { Date8 } from '../../../../utils/time';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { Sum } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_ca643
 * @category 가지급등록
 */

export const PaymentRegisterTemplate: React.FC<TemplateProps<PaymentRegister>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={425}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '사용자, 지급사유로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '미정산' },
              { value: '1', remark: '정산' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'snddate',
              text: '지급일자',
              width: 17,
              trail: (_) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  가지급건수: {scope?.state.PaymentRegisterList?.length} 건
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.snddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'sndnum',
              text: '번호',
              width: 10,
            },
            {
              id: 'pernm',
              text: '사용자명',
              width: 17,
            },
            {
              id: 'samt',
              text: '지급금액',
              width: 17,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(sumSamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.samt)}
                </FlexLayout>
              ),
            },
            {
              id: 'tamt',
              text: '잔액(환불액)',
              width: 17,
              trail: (data) => {
                const sumTamt = Sum.all(data, 'tamt');
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(sumTamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.tamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'mamt',
              text: '정산금액',
              width: 17,
              trail: (data) => {
                const sumMamt = Sum.all(data, 'mamt');
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(sumMamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.mamt)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.PaymentRegisterList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 가지급등록을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="지급일자">
              <DateTextBox
                value={model?.snddate ? model?.snddate : scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ snddate: value })}
                readonly={model?.isNew === false}
              />
            </LabelBox>

            <LabelBox title="지급번호">
              <TextBox
                textAlign="center"
                value={model?.sndnum}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="사용자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={1.5}
                value={model?.perid}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                      divicd: item.uv_arg2,
                      divinm: item.uv_arg3,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.5}
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model?.divicd}
                onChange={(value) => setData({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="계정과목"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.acccd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AC001',
                  {},
                  true,
                  (item) => {
                    setData({
                      acccd: item.cd,
                      accnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.accnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="지급사유"
              isNeed={true}
            >
              <TextBox
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
                textAlign="left"
              />
            </LabelBox>

            <LabelBox
              title="프로젝트"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model?.projno}
                onChange={(value) => setData({ projno: value })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      projno: item.projno,
                      projnm: item.projnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.projnm}
                onChange={(value) => setData({ projnm: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="지급유형"
              size={200}
            >
              <OptionBox
                value={model?.amtflag}
                data={[
                  { value: 'H', remark: '현금' },
                  { value: 'B', remark: '이체' },
                ]}
                onChange={(v) => setData({ amtflag: v.value })}
              />
            </LabelBox>

            <LabelBox
              title="지급금액"
              size={200}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="center"
                value={model?.samt}
                onChange={(value) => setData({ samt: value })}
              />
            </LabelBox>

            <LabelBox
              title="지급은행"
              style={{ color: 'var(--color-red)' }}
              weight={2.5}
            >
              <TextBox
                textAlign="center"
                value={model?.bankcd}
                onChange={(value) => setData({ bankcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AA040',
                  {},
                  true,
                  (item) => {
                    setData({
                      bankcd: item.cd,
                      banknm: item.uv_arg3,
                      accnum: item.uv_arg1,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.5}
                value={model?.banknm}
                readonly={true}
              />
              <TextBox
                weight={2}
                value={model?.accnum}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="지급자"
              size={200}
            >
              <TextBox
                textAlign="center"
                value={model?.inperid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.inpernm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="참조(비고)">
              <TextBox
                isMultiline={true}
                value={model?.bigo}
                onChange={(value) => setData({ bigo: value })}
               />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 가지급등록을 선택해주세요"
      >
        <GridLayout
          ref={scope.grid2}
          header={[
            {
              id: 'snddate',
              text: '지급일자',
              width: 9,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.snddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'sndnum',
              text: '번호',
              width: 6,
            },
            {
              id: 'remark',
              text: '지급사유',
              width: 17,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'samt',
              text: '지급금액',
              width: 9,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(sumSamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.samt)}
                </FlexLayout>
              ),
            },
            {
              id: 'tamt',
              text: '전액(환불액)',
              width: 9,
              trail: (data) => {
                const sumTamt = Sum.all(data, 'tamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(sumTamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.tamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'mamt',
              text: '정산금액',
              width: 9,
              trail: (data) => {
                const sumMamt = Sum.all(data, 'mamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(sumMamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.mamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'amtflag',
              text: '지금유형',
              width: 9,
              render: (x) => (
                <OptionBox
                  value={x.amtflag}
                  data={[
                    { value: 'H', remark: '현금' },
                    { value: 'B', remark: '예금' },
                  ]}
                  onChange={() => {}}
                />
              ),
            },
            {
              id: 'banknm',
              text: '지급은행(계좌번호)',
              width: 16,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.banknm}&nbsp;{x.accnum}
                </FlexLayout>
              ),
            },
            {
              id: 'bigo',
              text: '참고(비고)',
              width: 16,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.bigo}
                </FlexLayout>
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.PaymentRegisterDetailList}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
