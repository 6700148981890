export enum HeaderMenuOption {
  MENU = 0,
  SPJANG,
}

export const HEADER_MENU_OPTIONS = [
  HeaderMenuOption.MENU,
  HeaderMenuOption.SPJANG,
];

export const HEADER_MENU_TITLES = {
  [HeaderMenuOption.MENU]: '메뉴명',
  [HeaderMenuOption.SPJANG]: '사업장변경',
};
