import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  ComboBoxModel,
  ComboBox,
  GridLayout,
  SearchLayout,
  CheckBox,
  DateTextBox,
  TabView, ModalView, LayoutTitle, Button, TableLayout, FormatTextBox, LabelBox, SubHeader,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { EndContract, EndContractTabDataStateNames, EndContractTabFocusedStateNames } from './EndContract';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_e101w_01
 * @category 계약만료현장조회
 */
export const EndContractTemplate: React.FC<TemplateProps<EndContract>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={175}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={120}>
          <span>3개월 체크</span>
          <CheckBox
            value={scope.state.month3 === '1'}
            onChange={(value) => {
              scope.setState({ month3: value ? '1' : '0' }, () => {
                scope.onDataChange();
              });
            }}
          />
        </FlexLayout>
        <FlexLayout>
           <span
             style={{
               color: 'var(--color-red)',
             }}>
             담당자
           </span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span
            style={{
              color: 'var(--color-red)',
            }}>
            부서
          </span>
          <TextBox
            weight={1.4}
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout weight={2}>
          <span>현장명</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 보수업체명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span>계약분류</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          // @ts-ignore
          ref={scope.grid}
          scope={scope}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[EndContractTabDataStateNames[scope.tabIndex]] || []}
          onRowClick={(item) => setData({
            [EndContractTabFocusedStateNames[scope.tabIndex]]: item,
          })}
        />
      </FlexLayout>
      <ModalView
        isVisible={scope.state.historyModal}
        onClose={() => scope.historyPopupModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약내용기록
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
                <FlexLayout>
                  {/* @ts-ignore */}
                  {scope.state[EndContractTabFocusedStateNames[scope.tabIndex]]?.actnm} 계약내역
                </FlexLayout>
              </FlexLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableHistory}
                  header={[
                    {
                      id: 'seq',
                      text: '순서',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.seq}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'perid',
                      text: '작성자',
                      width: 8,
                      color: 'var(--color-red)',
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <TextBox
                            value={x.perid}
                            textAlign="center"
                            onChange={(value) => rowUpdate({ perid: value })}
                            bindSearchModal={new SearchBinding(
                              scope.props.modalStore,
                              'TB_JA001',
                              {},
                              true,
                              (item) => {
                                rowUpdate({
                                  perid: item.cd,
                                  pernm: item.cdnm,
                                });
                              },
                            )}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'pernm',
                      text: '작성자명',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.pernm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'hdate',
                      text: '기록일자',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.hdate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'wakedate',
                      text: '알림만료일',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.wakedate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'title',
                      text: '제목',
                      width: 40,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          <TextBox
                            value={x.title}
                            textAlign="left"
                            onChange={(value) => rowUpdate({ title: value })}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'okflag',
                      text: '답변확인',
                      width: 6,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <CheckBox
                            noMargin
                            value={x.okflag === '1'}
                            onChange={(value) => {
                              rowUpdate({ okflag: value ? '1' : '0' });
                            }}
                          />
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.historyData || []}
                  infinityHandler={scope.infinityHistory}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onChange={(rows2, updatedRows2) => {
                    scope.onUpdatedRows2(rows2, updatedRows2);
                  }}
                  onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout>
          <LabelBox title="계약내용">
            <TextBox
              value={scope.state.remark}
              onChange={(value) => setData({ remark: value })}
              isMultiline={true}
            />
          </LabelBox>
        </FlexLayout>
        <SubHeader>
          <FlexLayout justify='end'>
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={() => scope.onNewHistoryEvent()}
            >
              신규
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={() => scope.onHistorySaveEvent()}
            >
              저장
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.historyDelete()}
            >
              삭제
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.historyPopupModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  );
};
