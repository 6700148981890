import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Enrollment } from './Enrollment';
import { TabModel } from '../../../../models';
import { EnrollmentTabTemplate } from './tabs/EnrollmentTab.template';
import { EnrollmentAttachmentTabTemplate } from './tabs/EnrollmentAttachmentTab.template';

/**
 * 화면 > 탭
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export const EnrollmentTab: Array<TabLayoutDataItem<Enrollment>> = [
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '부품내역등록'),
    template: (scope) => <EnrollmentTabTemplate scope={scope} />,
  },
  {
    tab: new TabModel('ATTACHMENT', FaCodeBranch, '첨부파일'),
    template: (scope) => <EnrollmentAttachmentTabTemplate scope={scope} />,
  },
];
