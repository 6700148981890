import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { AccountDueModel } from '../models';
import { MonthWork } from '../MonthWork';
import { Sum } from '../../../../../utils/array';

// 미수금현황
const AccountDue: GridLayoutHeader<AccountDueModel, MonthWork>[] = [
  {
    id: 'divinm',
    text: '부서별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.divinm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 7,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 7,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumPernm)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 7,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 15,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'beamt',
    text: '전월미수금',
    width: 10,
    sum: (x, data) => {
      const sumBeamt = Sum.byGroup(data, 'beamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumBeamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.beamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.beamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금액',
    width: 10,
    sum: (x, data) => {
      const sumRcvamt = Sum.byGroup(data, 'rcvamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumRcvamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '미수금',
    width: 10,
    sum: (x, data) => {
      const sumResuamt = Sum.byGroup(data, 'resuamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumResuamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.resuamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '진행',
    width: 6,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: x.state === '미수' ? 'var(--color-red)' : 'var(--color-blue)' }}
      >
        {x.state}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '진행사항',
    width: 15,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];

export default AccountDue;
