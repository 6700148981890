import { computed } from 'mobx';

/**
 * 모델
 * @window w_s010
 * @category 은행코드등록
 */
export class BankModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 은행코드
   *
   * **data type** varchar(2)
   */
  bankcd: string;

  /**
   *기본값
   *
   * **data type** varchar(4)
   */
  subcd: string;

  /**
   * 은행명
   *
   * **data type** varchar(30)
   */
  banknm: string;

  /**
   *추가은행명
   *
   * **data type** varchar(30)
   */
  subnm: string;

  /**
   *입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   *비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   *연동가능
   *
   * **data type** varchar(1)
   */
  bankda: string;

  /**
   *뱅크다연동코드
   *
   * **data type** varchar(5)
   */
  bankda_cd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.bankcd = data.bankcd || '';
    this.subcd = data.subcd || '';
    this.banknm = data.banknm || '';
    this.subnm = data.subnm || '';
    this.indate = data.indate || '';
    this.remark = data.remark || '';
    this.bankda = data.bankda || '';
    this.bankda_cd = data.bankda_cd || '';
    this.new = isNew ? '1' : '0';
  }

@computed
  get isNew() {
    return this.new === '1';
  }
}
