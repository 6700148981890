import { action } from 'mobx';
import { MdPublish, MdReorder } from 'react-icons/md';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve, TabModel } from '../../../../models';
import MonthOilModel from './models/MonthOilModel';
import { MonthOilTemplate } from './MonthOil.template';
import { PageComponent } from '../../../../utils';
import { ConfirmWarning } from '../../../../utils/confirm';
import Today from '../../../../utils/time/Today';
import { GridLayout } from '../../../../components/layout/GridLayout';

export enum MonthOilTabId {
  Recent,
  LastMonths,
}

export const MonthOilTabTitles = [
  '최근6개월교통비/통행료현황',
  '전월대비교통비/통행료현황',
];

interface MonthOilState {
  // search
  yearmon: string;
  rtclafi: string;

  // data
  recents: Array<MonthOilModel>;
  focused: MonthOilModel;
  focusedTab?: TabModel;
  focusIndex: number;
  graph: string;
  graph2: string;

  // trail
  besamt05_tot: string;
  besamt04_tot: string;
  besamt03_tot: string;
  besamt02_tot: string;
  besamt01_tot: string;
  aver_tot: string;
  samt_tot: string;

  // title
  bemon01: string;
  bemon02: string;
  bemon03: string;
  bemon04: string;
  bemon05: string;
  mon: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca640_pa105_01w
 * @category 월유류비내역
 */
export class MonthOil extends PageComponent<PageProps, MonthOilState>
  implements PageToolEvents {
  infinityRecents?: InfinityRetrieve;

  infinityLastMonths?: InfinityRetrieve;

  tabs: Array<TabModel>;

  tabIndex: number = 0;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = MonthOilTabId;
    const titles = MonthOilTabTitles;

    this.tabs = [
      new TabModel(id.Recent.toString(), MdReorder, titles[id.Recent]),
      new TabModel(id.LastMonths.toString(), MdPublish, titles[id.LastMonths]),
    ];

    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      rtclafi: '%',

      // data
      recents: [],
      graph: '',
      graph2: '',

      // trail
      besamt05_tot: '0',
      besamt04_tot: '0',
      besamt03_tot: '0',
      besamt02_tot: '0',
      besamt01_tot: '0',
      aver_tot: '0',
      samt_tot: '0',

      // title
      bemon01: '0',
      bemon02: '0',
      bemon03: '0',
      bemon04: '0',
      bemon05: '0',
      mon: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityRecents = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        rtclafi: this.state.rtclafi,
      },
      (params) => api.fxExec('tab_1_retrieve', params),
      (items, next) => {
        this.setState({
          recents: [
            ...this.state.recents,
            ...items.map((item) => new MonthOilModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          recents: [],
        });

        await this.infinityRecents?.retrieveAll();
        if (this.state.recents && this.state.recents?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      recents: [],
    }, async () => {
      const index = await this.infinityRecents?.retrieveTo(['divicd', 'perid'],
        [this.state.focused?.divicd, this.state.focused?.perid], type, true) || 0;
      this.state.recents && this.state.recents.length > index && this.grid.current?.setFocus(index);

      this.SS({
        bemon01: this.infinityRecents?.box?.bemon01 || '0',
        bemon02: this.infinityRecents?.box?.bemon02 || '0',
        bemon03: this.infinityRecents?.box?.bemon03 || '0',
        bemon04: this.infinityRecents?.box?.bemon04 || '0',
        bemon05: this.infinityRecents?.box?.bemon05 || '0',
        mon: this.infinityRecents?.box?.mon || '0',
        besamt05_tot: this.infinityRecents?.box?.besamt05_tot || '0',
        besamt04_tot: this.infinityRecents?.box?.besamt04_tot || '0',
        besamt03_tot: this.infinityRecents?.box?.besamt03_tot || '0',
        besamt02_tot: this.infinityRecents?.box?.besamt02_tot || '0',
        besamt01_tot: this.infinityRecents?.box?.besamt01_tot || '0',
        aver_tot: this.infinityRecents?.box?.aver_tot || '0',
        samt_tot: this.infinityRecents?.box?.samt_tot || '0',
      });
    });
    this.updateGraphUrl();
  }

  @action
  async onPrintEvent() {
    if (this.state.recents.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxPrint(`tab_${this.tabIndex + 1}_print`, {
      stmon: this.state.yearmon,
      rtclafi: this.state.rtclafi,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.recents.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxExcel(`tab_${this.tabIndex + 1}_excel`, {
      stmon: this.state.yearmon,
      rtclafi: this.state.rtclafi,
    });
  }

  @action
  updateGraphUrl() {
    const { user } = this.props.publicStore;
    this.setState({
      graph: ` https://api.elmansoft.com/chart/line.php?window=tb_ca640_pa105&type=0&as_mon=${this.state.yearmon}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_rtclafi=${this.state.rtclafi}&xcnt=6`,
      graph2: ` https://api.elmansoft.com/chart/stick4.php?window=tb_ca640_pa105&type=0&as_mon=${this.state.yearmon}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_rtclafi=${this.state.rtclafi}`,
    });
  }

  onTabChange(index: number) {
    this.tabIndex = index;
    this.updateGraphUrl();
  }

  onTabChange2(index: number) {
    this.tabIndex = index;
    this.onRetrieveEvent();
    this.updateGraphUrl();
  }

  render() {
    return <MonthOilTemplate
      scope={this}
    />;
  }
}
