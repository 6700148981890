import { computed } from 'mobx';

/**
 * 모델
 * @window w_input_da026w
 * @category 입금현황
 */
export class InputStateModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 입금일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 입금월
   *
   * **data type** varchar(8)
   */
  rcvmon: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  rcvnum: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 입금형태
   *
   * **data type** varchar(45)
   */
  chk: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 적요
   *
   * **data type** varchar(300)
   */
  remark: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 회계적용
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 계정명
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 은행계좌코드
   *
   * **data type** varchar(4)
   */
  bankacccd: string;

  /**
   * 계좌명
   *
   * **data type** varchar(30)
   */
  banknm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(20)
   */
  aaccnum: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 매출구분
   *
   * **data type** varchar(2)
   */
  code: string;

  /**
   * 해제
   *
   * **data type** varchar(50)
   */
  cnam: string;

  /**
   * 계정명
   *
   * **data type** varchar(50)
   */
  accnm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(50)
   */
  accnum: string;

  /**
   * 수수료
   *
   * **data type** number
   */
  rcvmar: string;

  /**
   * 입금주(예금관리적요)
   *
   * **data type** number
   */
  mismijremark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rcvmar = data.rcvmar || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvmon = data.rcvmon || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.accnm = data.accnm || '';
    this.rcvnum = data.rcvnum || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.misdate = data.misdate || '';
    this.chk = data.chk || '';
    this.misnum = data.misnum || '';
    this.rcvamt = data.rcvamt || '';
    this.remark = data.remark || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.accyn = data.accyn || '';
    this.acccd = data.acccd || '';
    this.bankacccd = data.bankacccd || '';
    this.aaccnum = data.aaccnum || '';
    this.projno = data.projno || '';
    this.code = data.code || '';
    this.cnam = data.cnam || '';
    this.mismijremark = data.mismijremark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
