import * as React from 'react';
import {
  LayoutTitle,
  FlexLayout,
  LabelBox,
  TextBox,
  OptionBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Jiro } from './Jiro';

/**
 * 화면
 * @window w_jirosetup
 * @category 지로번호등록
 */
export const JiroTemplate: React.FC<TemplateProps<Jiro>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <LayoutTitle>지로 정보</LayoutTitle>

    <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
      <LabelBox title="지로 번호" isNeed={true}>
        <TextBox
          value={scope.state.jironum}
          onChange={(value) => scope.setState({ jironum: value })}
        />
      </LabelBox>

      <LabelBox title="전자 납부 사용">
        <OptionBox
          value={scope.state.elecflag}
          data={[
            { value: '1', remark: '사용' },
            { value: '0', remark: '미사용' },
          ]}
          onChange={(item) => scope.setState({ elecflag: `${item.value}` })}
        />
      </LabelBox>
    </FlexLayout>

    <FlexLayout size={300}>
      <LabelBox title="공통사항" isNeed={true}>
        <TextBox
          value={scope.state.remark}
          onChange={(value) => scope.setState({ remark: value })}
          isMultiline={true}
        />
      </LabelBox>

      <LabelBox title="개별 알림사항(비고)">
        <TextBox
          value={scope.state.remark2}
          onChange={(value) => scope.setState({ remark2: value })}
          isMultiline={true}
        />
      </LabelBox>
    </FlexLayout>

    <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
      <LabelBox title="E-지로 아이디" isNeed={true}>
        <TextBox
          value={scope.state.egiro_id}
          onChange={(value) => scope.setState({ egiro_id: value })}
        />
      </LabelBox>

      <LabelBox title="E-지로 비밀번호">
        <TextBox
          type="password"
          value={scope.state.egiro_pw}
          onChange={(value) => scope.setState({ egiro_pw: value })}
        />
      </LabelBox>
    </FlexLayout>

    <LayoutTitle>CMS 정보</LayoutTitle>

    <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
      <LabelBox title="CMS 아이디" isNeed={true}>
        <TextBox
          value={scope.state.cms_id}
          onChange={(value) => scope.setState({ cms_id: value })}
        />
      </LabelBox>

      <LabelBox title="CMS 비밀번호">
        <TextBox
          type="password"
          value={scope.state.cms_pw}
          onChange={(value) => scope.setState({ cms_pw: value })}
        />
      </LabelBox>
    </FlexLayout>
  </FlexLayout>
);
