import * as React from 'react';
import {
  action,
} from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { AppointModel } from './Appoint.model';
import {
  ConfirmDelete,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { AppointTemplate } from './Appoint.template';
import {
  InfinityRetrieve,
} from '../../../../models/common';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils';

// 예전 초기 방식
// export interface AppointListItem {
//   new: string;
//   perid: string;
//   prodate: string;
//   ordercd: string;
//   ordertxt: string;
//   divicd: string;
//   divinm: string;
//   frombal: string;
//   tobal: string;
//   mpclafi: string;
//   rtclafi: string;
//   spjangcd: string;
//   modcode: string;
//   handphone: string;
//   payiden: string;
//   rnum: string;
//   rspcd: string;
//   pernm: string;
//
//   focusIndex: number;
// }

interface AppointState {
  searchQuery: string;
  spjangcd: string;
  rtclafi: string;
  appointList: Array<AppointModel>;
  appointDetailList: Array<AppointModel>;
  focusedAppoint?: AppointModel;
  focusedDetailAppoint?: AppointModel;
  focusedDetailIndex: number;
  focusedDetailBeforeIndex: number;
  spjangcds: Array<AppointModel>;
  ordercds: Array<any>;

  focusIndex: number;
}

/**
 * 컨트롤러
 * @window w_p2120
 * @category 인사발령등록
 */
export class Appoint extends PageComponent<PageProps, AppointState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  grid: React.RefObject<GridLayout> = React.createRef();

  gridDetail: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  newCheck?: boolean;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      focusedDetailIndex: 0,
      searchQuery: '',
      spjangcd: '%',
      rtclafi: '001',
      appointDetailList: [],
      appointList: [],
      checkSaveEvent: false,
      newCheck: false,
      focusIndex: 0,
      focusedAppoint: [],
      focusedDetailBeforeIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트 가져오기
    let data = await api.dropdown('wf_dd_spjangcd_02');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data?.items });

    // 발령굼분 리스트 가져오기
    data = await api.dropdown('wf_dd_pz006');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ ordercds: data?.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        spjangcd: this.state.spjangcd,
        rtclafi: this.state.rtclafi,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          appointList: [...this.state.appointList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          appointList: [],
        });
        await this.infinity?.retrieveAll();
        this.state.appointList.length > 0 && await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      appointList: [],
      appointDetailList: [],
      focusedAppoint: undefined,
    });
    if (!autoLoad) return;
    const index = await this.infinity?.retrieveTo('perid', this.state.focusedAppoint?.perid, type, true) || 0;
    this.state.appointList.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onRowFocusEvent(item: AppointModel) {
    const { actionStore: api } = this.props;

    await this.setState({
      focusedAppoint: new AppointModel(item, false),
    }, async () => {
      const focusedAppointDetailList = await api.fxExec(
        'dw_1_RowFocuschanged',
        item,
      );

      if (focusedAppointDetailList?.items?.length) {
        this.setState({
          appointDetailList: focusedAppointDetailList?.items?.map((x: any) => new AppointModel(x)),
        });

        // 우측 그리드 디테일 화면 포커스를 바로잡아 데이터를 받아오기 위한 행
        // await this.onDetailRowFocusEvent(this.state.appointDetailList[0], 0);
        await this.gridDetail.current?.setFocus(0);
        await this.gridDetail.current?.forceRepaint();
      } else {
        this.setState({
          appointDetailList: [],
        });
      }
    });
  }

  @action
  async onDetailRowFocusEvent(item: AppointModel, index: number) {
    if (item.isNew) {
      return;
    }

    await this.SS({
      focusedDetailAppoint: new AppointModel(item, item?.isNew),
      focusIndex: index,
    });
    this.setState({
      focusedDetailIndex: this.state.appointDetailList?.findIndex((x) => x.prodate === item.prodate),
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }

    // if (this.state.appointDetailList.filter((x) => x.isNew).length) {
    //   ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
    //   return;
    // }

    if (this.state.focusedDetailAppoint?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가할 수 있습니다.');
      return;
    }

    const data = await api.new({
      perid: this.state.focusedDetailAppoint?.perid,
    });

    if (data) {
      const newItem = new AppointModel(data, true);
      await this.setState({
        focusedDetailAppoint: new AppointModel(newItem, true),
        focusedDetailIndex: 0,
        appointDetailList: [newItem, ...this.state.appointDetailList],
      });
    }

    await this.gridDetail.current?.setFocus(0);
    await this.gridDetail.current?.forceRepaint();
    // this.newCheck = true;
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (!this.state.focusedDetailAppoint?.isNew) {
      ConfirmWarning.show('경고', '신규 추가후 저장해주세요');
      return;
    }

    if (this.state.appointDetailList[0].ordercd === '04'
      && this.state.appointDetailList[1].rspcd === this.state.appointDetailList[0].rspcd) {
      ConfirmWarning.show('오류', '이미 해당 직위입니다!');
      return;
    }

    if (await api.save({
      bespjangcd: this.state.appointDetailList[1].spjangcd,
      beordercd: this.state.appointDetailList[1].ordercd,
      beprodate: this.state.appointDetailList[1].prodate,
      rspcd: this.state.focusedDetailAppoint.rspcd,
      divicd: this.state.focusedDetailAppoint.divicd,
      spjangcd: this.state.focusedDetailAppoint.spjangcd || 'ZZ',
      prodate: this.state.focusedDetailAppoint.prodate,
      perid: this.state.focusedAppoint?.perid,
      rtclafi: this.state.focusedDetailAppoint.rtclafi,
      payiden: this.state.focusedAppoint?.payiden,
      mpclafi: this.state.focusedAppoint?.mpclafi,
      modcode: this.state.focusedAppoint?.modcode,
      handphone: this.state.focusedAppoint?.handphone,
      rnum: this.state.focusedAppoint?.rnum,
      clacd: this.state.appointDetailList[1].clacd,
      stpcd: this.state.appointDetailList[1].stpcd,
      ordercd: this.state.focusedDetailAppoint.ordercd,
      ordertxt: this.state.focusedDetailAppoint?.ordertxt,
    }, this.state.focusedDetailAppoint!.isNew)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      await this.SS({ searchQuery: this.state.focusedAppoint?.pernm || '' });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `성명: ${this.state.focusedAppoint?.pernm}, 발령일자: ${this.state.appointDetailList[0]?.prodate}`;
    const text2 = '최초발령내역은 삭제할 수  없습니다';

    if (this.state.appointDetailList.length === this.state.focusIndex + 1) {
      await ConfirmWarning.show('오류', text2);
      return;
    }

    if (await ConfirmDelete.show(text)) {
      await api.fxDelete(
        'delete',
        text,
        {
          // new: this.newCheck ? '1' : '0',
          new: this.state.focusedDetailAppoint?.new,
          bespjangcd: this.state.appointDetailList[0].spjangcd,
          ordercd: this.state.appointDetailList[0].ordercd,
          rspcd: this.state.appointDetailList[0].rspcd,
          divicd: this.state.appointDetailList[0].divicd,
          spjangcd: this.state.appointDetailList[0].spjangcd || 'ZZ',
          prodate: this.state.appointDetailList[0].prodate,
          perid: this.state.focusedAppoint?.perid,
          rtclafi: this.state.focusedAppoint?.rtclafi,
          payiden: this.state.focusedAppoint?.payiden,
          mpclafi: this.state.focusedAppoint?.mpclafi,
          modcode: this.state.focusedAppoint?.modcode,
          handphone: this.state.focusedAppoint?.handphone,
          rnum: this.state.focusedAppoint?.rnum,
          clacd: this.state.appointDetailList[0].clacd,
          stpcd: this.state.appointDetailList[0].stpcd,
        },
      );
      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  // @computed
  // get currentDetailData() {
  //   return this.state.appointDetailList[this.state.focusedDetailIndex];
  // }

  render() {
    return (
      <AppointTemplate
        scope={this}
        update={(changes, callback) => {
          this.setState({
            focusedDetailAppoint: new AppointModel({
              ...this.state.focusedDetailAppoint,
              ...changes,
            }, this.state.focusedDetailAppoint?.isNew),
          }, () => callback && callback());
          // const willUpdate = {};
          // Object.keys(changes).forEach((id) => {
          //   // @ts-ignore
          //   willUpdate[id] = {
          //     $set: changes[id],
          //   };
          // });
          //
          // this.setState({
          //   appointDetailList: update(this.state.appointDetailList, {
          //     [this.state.focusedDetailIndex]: willUpdate,
          //   }),
          // });
        }}
        dataUpdate={(change, callback) => {
          this.setState(change, () => callback && callback());
        }}
      />
    );
  }
}
