import { TextBox } from '../../../../../components/forms/TextBox';
import { TableLayoutHeader } from '../../../../../constants';
import { CareerModel } from '../models';
import {
  PersonnelResistration,
} from '../PersonnelResistration';

// 경력
const Career: TableLayoutHeader<CareerModel, PersonnelResistration>[] = [
  {
    id: 'carcd',
    text: '순번',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.carcd}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'comnm',
    text: '회사명',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.comnm}
        onChange={(value) => rowUpdate({ comnm: value })}
      />
    ),
  },
  {
    id: 'sdate',
    text: '입사일',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.sdate}
        onChange={(value) => rowUpdate({ sdate: value })}
      />
    ),
  },
  {
    id: 'edate',
    text: '퇴사일',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.edate}
        onChange={(value) => rowUpdate({ edate: value })}
      />
    ),
  },
  {
    id: 'dept',
    text: '근무부서',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.dept}
        onChange={(value) => rowUpdate({ dept: value })}
      />
    ),
  },
  {
    id: 'posi',
    text: '직위',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.posi}
        onChange={(value) => rowUpdate({ posi: value })}
      />
    ),
  },
  {
    id: 'serv',
    text: '업무',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.serv}
        onChange={(value) => rowUpdate({ serv: value })}
      />
    ),
  },
  {
    id: 'loc',
    text: '소재지',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.loc}
        onChange={(value) => rowUpdate({ loc: value })}
      />
    ),
  },
];

export default Career;
