import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ApplicationTemplate } from './Application.template';
import { InfinityRetrieve } from '../../../../models/common';
import { ApplicationModel } from './models/Application.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface InvoiceState {

  // 검색조건
  searchQuery: string; // 검색어
  stmon: string;
  endmon: string;
  contgubun: string;
  prtflag: string;
  wkactcd: string;
  wkactnm: string;
  src: string;
  contgubuns: Array<any>;
  deatilcontgubun: Array<any>;
  wkactcds: Array<any>;

  cnt_tot: string;
  prtflag0_tot: string;
  prtflag1_tot: string;
  qty_tot: string;
  total: string;

  applicationList: Array<ApplicationModel>;
  focusedApplication?: ApplicationModel;
}

/**
 * 컨트롤러
 * @window w_tb_e073w_01
 * @category 검사신청서
 */
export class Application extends PageComponent<PageProps, InvoiceState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  updatedRows?: Array<ApplicationModel>;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stmon: `${year}01`,
      endmon: `${year}12`,
      contgubun: '01', // 계약분류
      prtflag: '%', // 신청구분
      wkactcd: '',
      wkactnm: '',

      cnt_tot: '',
      prtflag0_tot: '',
      prtflag1_tot: '',
      qty_tot: '',
      total: '',

      applicationList: [],
    };
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // const show = await ConfirmWarning.show('확인', '검사는 정보센터 보안이슈로 협역이 될때까지 정보센터에서 확인부탁드립니다. 정보센터로 이동합니다.');
    //
    // const data = true;
    //
    // if (data) {
    //   await show;
    //   this.openWebSite2();
    // } else {
    //   await this.onRetrieveEvent();
    // }
    let data = await api.dropdown('wf_dd_ca510_008_02');
    if (!data) return;
    await this.setState({ contgubuns: data?.items });

    data = await api.dropdown('wf_dd_ca510_008_01');
    if (!data) return;
    await this.setState({ deatilcontgubun: data?.items });

    data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    await this.setState({ wkactcds: data.items });

    await this.onRetrieveEvent();
  }

  openWebSite2() {
    window.open('https://www.elevator.go.kr/main/Login.do?menuId=00000000');
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        contgubun: this.state.contgubun,
        prtflag: this.state.prtflag,
        wkactcd: this.state.wkactcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          applicationList: [...this.state.applicationList, ...items],
        }, () => {
          this.table.current?.update(false);
        });
      },
      async () => {
        await this.SS({
          applicationList: [],
        });
        this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      applicationList: [],
    }, async () => {
      const data = await this.infinity?.retrieve();
      data && this.setState({
        cnt_tot: data?.cnt_tot || '0',
        prtflag0_tot: data?.prtflag0_tot || '0',
        prtflag1_tot: data?.prtflag1_tot || '0',
        qty_tot: data?.qty_tot || '0',
        total: data?.total || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    // 선택 하나이상 했는지 확인
    let noChk = true;

    if (!ConfirmWarning.assert(this.state.applicationList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    // 선택이 하나라도 안되있으면 선택 하라고 경고팝업창
    this.updatedRows?.forEach((x) => {
      if (x.chk === '1') {
        noChk = false;
      }
    });

    if (noChk) {
      ConfirmWarning.show('경고', '선택을 하나이상 해주세요.');
      return;
    }

    await api.printWithElmanManager({
      src: this.state.src,
      stmon: this.state.stmon,
      items: this.updatedRows?.map((x) => ({
        chk: x.chk,
        actcd: x.actcd,
        kcenddate: x.kcenddate,
      })),
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.applicationList.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      src: this.state.src,
      as_nm: this.state.searchQuery,
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      contgubun: this.state.contgubun,
      prtflag: this.state.prtflag,
      wkactcd: this.state.wkactcd,
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save({
      stmon: this.state.stmon,
      items: this.updatedRows?.map((x) => ({
        prtflag: x.prtflag,
        actcd: x.actcd,
        kcenddate: x.kcenddate,
      })),
    }, false);
  }

  @action
  onUpdatedRows(rows: Array<ApplicationModel>, updatedRows: Array<ApplicationModel>) {
    this.updatedRows = updatedRows;
    this.setState({ applicationList: rows });
  }

  @action
  onRowFocusEvent(item:any) {
    this.setState({ focusedApplication: item });
  }

  render() {
    return (
      <ApplicationTemplate scope={this}/>
    );
  }
}
