import * as React from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import { RefObject } from 'react';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ResultModel from './models/ResultModel';
import { ResultTemplate } from './Result.template';
import { PageComponent } from '../../../../utils';
import { GridLayoutAdditionalHeader, TableLayout } from '../../../../components';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import ResultDetailModel from './models/ResultDetailModel';
import { Date6, Date8, Today } from '../../../../utils/time';
import { ChargePopupModel } from '../../../electronic/preparation/Journal/models';
import {
  JournalButtonClickedTypeNames,
  JournalButtonClickedTypes,
} from '../../../electronic/preparation/Journal/Journal';

interface SampleState {
  searchQuery: string;
  planmon: string;
  perid: string;
  pernm: string;
  state: string;

  data: Array<ResultModel>;
  dataDetail: Array<ResultDetailModel>;
  content: ResultDetailModel;
  tableFocus?: ResultModel;

  isVisibleDetail: boolean;
  detailSearchQuery: string;

  isVisibleAttachment: boolean;

  isChargeModalVisible: boolean;
  isGraphVisible: boolean;
  popupStmon: string;
  graph?: string;
  focusedCharge?: ChargePopupModel;
  popupChargeList: Array<ChargePopupModel>;
  equpChkToggle: boolean;
  evcds: Array<any>;
  wkactcds: Array<any>;

  gkccnt1_tot?: string;
  gkccnt2_tot?: string;
  gremaincnt1_tot?: string;
  gremaincnt2_tot?: string;
  kccnttot?: string;
  plan_cnt_tot?: string;
  plan_comp_tot?: string;
  plan_jcomp_tot?: string;
  plan_not_tot?: string;
  plan_rece_tot?: string;
  plan_success_tot?: string;
  remaintot?: string;
  send_cnt_tot?: string;
  send_not_tot?: string;
  sendcnt1_tot?: string;
  sendcnt2_tot?: string;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  kakaoyn: string;
}

/**
 * 컨트롤러
 * @window w_tb_e035_03
 * @category 점검결과등록
 */
export class Result extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityDetail?: InfinityRetrieve;

  table: RefObject<TableLayout> = React.createRef();

  tableDetail: RefObject<TableLayout> = React.createRef();

  tableFocus?: ResultModel;

  tableFocusIndex: number = 0;

  tableChanges: Array<ResultModel> = [];

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    this.state = props.state || {
      searchQuery: '',
      planmon: Date6.make(),
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      state: '%',

      data: [],
      dataDetail: [],

      isVisibleDetail: false,
      detailSearchQuery: '',

      isChargeModalVisible: false,
      isGraphVisible: false,
      popupStmon: Today.yearMon(),
      popupChargeList: [],
      equpChkToggle: true,

      kakaoyn: '0',
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const openData = await api.fxExec('open');
    await this.setState({
      kakaoyn: openData?.alim_kakao_useyn,
    });

    let data = [];

    // 점검기종 리스트
    data = await api.dropdown('wf_dd_e030');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ evcds: data.items });

    // 회사구분 리스트
    data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    this.setState({ wkactcds: data.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        result: this.state.state,
        stmon: this.state.planmon,
        perid: this.state.perid,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new ResultModel(item)),
          ],
        });
        await this.table.current?.update(false);
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length > 0) {
          this.table.current?.setFocus(this.tableFocusIndex);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });
    await this.table.current?.update(true);

    const data = await this.infinity?.box;
    const index = await this.infinity?.retrieveTo(['actcd', 'equpnm'],
      [this.state.tableFocus?.actcd, this.state.tableFocus?.equpnm], type) || 0;
    await this.SS({
      gkccnt1_tot: data?.gkccnt1_tot || '0',
      gkccnt2_tot: data?.gkccnt2_tot || '0',
      gremaincnt1_tot: data?.gremaincnt1_tot || '0',
      gremaincnt2_tot: data?.gremaincnt2_tot || '0',
      kccnttot: data?.kccnttot || '0',
      plan_cnt_tot: data?.plan_cnt_tot || '0',
      plan_comp_tot: data?.plan_comp_tot || '0',
      plan_jcomp_tot: data?.plan_jcomp_tot || '0',
      plan_not_tot: data?.plan_not_tot || '0',
      plan_rece_tot: data?.plan_rece_tot || '0',
      plan_success_tot: data?.plan_success_tot || '0',
      remaintot: data?.remaintot || '0',
      send_cnt_tot: data?.send_cnt_tot || '0',
      send_not_tot: data?.send_not_tot || '0',
      sendcnt1_tot: data?.sendcnt1_tot || '0',
      sendcnt2_tot: data?.sendcnt2_tot || '0',
    });

    if (this.state.data && this.state.data.length > index) {
      this.table.current?.setFocus(index);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    // if (this.state.perid === '') {
    //   ConfirmWarning.show('경고', '사용자 전체검색시에는 저장할 수 없습니다.\n먼저 사용자를 선택해주세요.');
    //   return;
    // }

    // 결과 처리이고 점검일자가 미래일자이면 팝업창
    let dateChk = false;
    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }
    const dates = `${year}${month}${date}`;

    this.tableChanges.forEach((x) => {
      if (Number(dates) < Number(x.rptdate) && x.state === '2') {
        dateChk = true;
      }
    });

    if (dateChk) {
      ConfirmWarning.show('경고', '점검일자가 미래날짜입니다. \n최대 금일날짜로 변경해주시길 바랍니다.');
      return;
    }

    let kcask1 = false;
    let kcask2 = AskType.YES;

    // eslint-disable-next-line no-restricted-syntax
    for (const x of this.tableChanges) {
      if (x.kcflag === '1' && x.kcpernm2 === '' && x.state === '2') {
        kcask1 = true;
      }

      if (x.kcflag === '0' && x.kcpernm2 === '' && x.state === '2') {
        // eslint-disable-next-line no-await-in-loop
        kcask2 = await Confirm.ask('확인', '보조자가 없는게 맞습니까?', '예', '아니오');
      }
    }
    if (kcask1) {
      ConfirmWarning.show('경고', '보조자를 입력해주세요');
      return;
    }

    if (kcask2 === 1) {
      return;
    }

    if (await api.save({
      items: this.tableChanges,
    }, false)) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.state.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${Date8.withDash(this.state.tableFocus?.plandate)} ${this.state.tableFocus?.actnm} ${this.state.tableFocus?.equpnm}`;

    if (await api.delete(text, this.state.tableFocus)) {
      await this.SS({
        data: update(this.state.data, {
          $splice: [[this.tableFocusIndex, 1]],
        }),
      });

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.data.length) {
        const next = this.tableFocusIndex === 0 ? 0 : this.tableFocusIndex - 1;
        this.table.current?.setFocus(next);
      } else {
        this.SS({
          tableFocus: new ResultModel(),
        });
        this.tableFocusIndex = 0;
      }
    }
  }

  @action
  async onPrintEvent() {
    if (!this.state.data.filter((x) => x.chk === '1').length) {
      ConfirmWarning.show('오류', '선택된 점검일지가 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stmon: this.state.planmon,
      items: this.state.data.filter((x) => x.chk === '1').map((x) => ({
        chk: '1',
        plandate: x.plandate,
        actcd: x.actcd,
        equpcd: x.equpcd,
        wkactcd: x.wkactcd,
      })),
    });
  }

  @action
  async onKakaoButtonClicked() {
    const { actionStore: api } = this.props;

    if (this.state.data.filter((x) => x.chk === '1').length < 1) {
      ConfirmWarning.show('경고', '전송할 내역을 하나이상 출력채크를 해주세요');
      return;
    }

    await api.fxExec('wb_kakaosend', {
      items: this.state.data,
    });
  }

  @action
  onSendEmail() {
    const { actionStore: api } = this.props;

    if (!this.state.data.filter((x) => x.chk === '1').length) {
      ConfirmWarning.show('오류', '선택된 점검일지가 없습니다');
      return;
    }

    api.fxEmail(
      '',
      '',
      '',
      '',
      '',
      '',
      {
        stmon: this.state.planmon,
        items: this.state.data.filter((x) => x.chk === '1'),
      },
    );
  }

  @action
  async onGosiEmail() {
    const { actionStore: api } = this.props;

    if (!this.state.data.filter((x) => x.gosi === '1').length) {
      ConfirmWarning.show('오류', '선택된 점검일지가 없습니다');
      return;
    }

    ConfirmWarning.showPbMessage(await api.fxExec(
      'wb_gosisend',
      {
        stmon: this.state.planmon,
        items: this.state.data.filter((x) => x.gosi === '1'),
      },
    ));
  }

  openGosi() {
    window.open('https://www.elevator.go.kr');
  }

  @action
  async tableItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      ...this.state.data[this.tableFocusIndex],
      [key]: value,
      itemname: key,
      data: value,
    }, this.state.data[this.tableFocusIndex].isNew);

    if (result.messagebox === '최근 검사결과가 불합격입니다. 점검처리를 할 수 없습니다') {
      ConfirmWarning.show('경고', '최근 검사결과가 불합격입니다. \n 점검처리를 할 수 없습니다');
      return;
    }

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  async rpdateItemChanged(rowUpdate: RowUpdate, value: string) {
    // 오늘날짜로부터 5일전 날짜
    const today = new Date();
    const before = new Date(today.setDate(today.getDate() - 10));
    const beforeYear = String(before.getFullYear());
    let beforeMonth = String(before.getMonth() + 1);
    let beforeDay = String(before.getDate());

    if (Number(beforeMonth) < 10) {
      beforeMonth = `0${beforeMonth}`;
    }
    if (Number(beforeDay) < 10) {
      beforeDay = `0${beforeDay}`;
    }

    const fiveDay = beforeYear + beforeMonth + beforeDay;
    if (Number(fiveDay) - Number(value) > 0) {
      if (!await Confirm.show('선택!',
        `금일로부터 10일전 일자입니다!\n\n정보센터에 점검일을${Date8.withDash(value)} 일자로 
        전송하게됩니다.\n최대 10일이전 일자는 ${Date8.withDash(fiveDay)} 입니다!\n ${Date8.withDash(fiveDay)} 일자로 셋팅하시겠습니까?`,
        ConfirmType.QUESTION)) {
        rowUpdate({
          rptdate: value,
          sendflag: '0',
        });
        return;
      }
      rowUpdate({
        rptdate: fiveDay,
        sendflag: '0',
      });
    } else {
      rowUpdate({
        rptdate: value,
        sendflag: '0',
      });
    }
  }


  onRowFocused(item: ResultModel, index: number) {
    this.tableFocusIndex = index;

    this.setState({
      tableFocus: new ResultModel(item, item?.isNew),
    });
  }

  onChanged(rows: Array<ResultModel>, updates: Array<ResultModel>) {
    this.SS({ data: rows });
    this.tableChanges = updates;
  }


  // Detail
  @action
  async openDetail() {
    await this.SS({
      isVisibleDetail: true,
      detailSearchQuery: '',
      dataDetail: [],
    });

    this.retrieveDetail();
  }

  @action
  async retrieveDetail() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityDetail = new InfinityRetrieve(
      {
        sub: 'w_popup_e035_03',
        actnm: this.state.tableFocus?.actnm,
        equpnm: this.state.tableFocus?.equpnm,
        plandate: this.state.tableFocus?.plandate,
        actcd: this.state.tableFocus?.actcd,
        equpcd: this.state.tableFocus?.equpcd,
        evcd: this.state.tableFocus?.evcd_new || '1',
      },
      (params) => api.retrieve(params),
      async (items) => {
        await this.SS({
          dataDetail: [
            ...this.state.dataDetail,
            ...items.map((item) => new ResultDetailModel(item)),
          ],
        });

        await this.tableDetail.current?.update(false);
      },
      async () => {
        await this.SS({
          dataDetail: [],
        });
        await this.infinityDetail?.retrieve();
        this.tableDetail.current?.update(true);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataDetail: [],
    });

    await this.infinityDetail?.retrieve();
    this.tableDetail.current?.update(true);
  }

  @action
  async onSaveDetail() {
    const { actionStore: api } = this.props;

    await api.save({
      sub: 'w_popup_e035_03',
      actnm: this.state.tableFocus?.actnm,
      equpnm: this.state.tableFocus?.equpnm,
      plandate: this.state.tableFocus?.plandate,
      actcd: this.state.tableFocus?.actcd,
      equpcd: this.state.tableFocus?.equpcd,
      evcd: this.state.tableFocus?.evcd_new || '1',
      items: this.state.dataDetail,
    }, false);
  }

  @action
  async onDetailReroll() {
    const { actionStore: api } = this.props;

    ConfirmSuccess.showPbMessage(await api.fxExec('wb_create', {
      sub: 'w_popup_e035_03',
      plandate: this.state.tableFocus?.plandate,
      planmon: this.state.tableFocus?.plandate.substr(0, 6),
      actcd: this.state.tableFocus?.actcd,
      equpcd: this.state.tableFocus?.equpcd,
      evcd: this.state.tableFocus?.evcd_new || '1',
    }, false));

    this.retrieveDetail();
  }

  getSerialText(item: ResultDetailModel) {
    switch (item.evmon) {
      case '3':
        return '3 6 9 12';

      case '6':
        return '6 12';

      case '2':
        return '2 4 6 8 10 12';

      default:
        return '매월';
    }
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen });
  }


  // Modal charge
  @action
  async openModalCharge() {
    this.setState({
      isChargeModalVisible: true,
    }, () => this.chargeRetrieve());
  }

  @action
  async closeModalCharge() {
    this.setState({
      popupChargeList: [],
      isGraphVisible: false,
      isChargeModalVisible: false,
    });
  }

  // @action
  // fileModal(isOpen: boolean) {
  //   this.setState({ fileDetailModal: isOpen },
  //     () => {
  //       if (!isOpen) { this.onRowFocusEvent(this.state.content!); }
  //     });
  // }

  @action
  async chargeRetrieve() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    this.SS({
      graph: `https://api.elmansoft.com/chart/stick2.php?database=weberp&window=w_popup_e033w&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_mon=${this.state.popupStmon}`,
    });

    const data = await api.retrieve(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );

    if (data?.items) {
      this.SS({
        isGraphVisible: true,
        popupChargeList: data.items?.map((x: any) => new ChargePopupModel({ open: '0', ...x })),
      });
    } else {
      this.SS({
        isGraphVisible: true,
        popupChargeList: [],
      });
    }
  }

  @action
  async onButtonClicked(type:any, item:any) {
    const { actionStore: api } = this.props;
    let data = {
      new: undefined,
      items: [],
    };
    const params = {
      sub: 'w_popup_e033w',
      itemname: JournalButtonClickedTypeNames[type],
      data: '',
    };

    if (type === JournalButtonClickedTypes.ADD) {
      data = await api.fxExec(
        'dw_list_buttonclikced',
        {
          ...params,
          divicd: item.divicd,
          stmon: this.state.popupStmon,
        },
      );

      if (data?.items) {
        const focusedIndex = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, true),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, true),
            ...data?.items.map((x: any) => new ChargePopupModel(x)),
            ...this.state.popupChargeList.slice(focusedIndex + 1),
          ],
        });
      }
    }

    if (type === JournalButtonClickedTypes.MINUS) {
      // 버튼을 누른 행의 인덱스 추출
      const focusedIndex:number = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

      // 버튼을 누른 행 다음에 있는 첫번째 부서장 모델 추출
      const model = this.state.popupChargeList.slice(focusedIndex + 1).find((x) => x.chk === '0');

      // 버튼을 누른 행의 다음 부서장 인덱스 추출
      const deleteIndex = this.state.popupChargeList.findIndex((x) => x.divicd === model?.divicd);
      if (deleteIndex > -1) {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
            ...this.state.popupChargeList.slice(deleteIndex),
          ],
        });
      } else {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
          ],
        });
      }
    }
  }

  @action
  onChargeRowFocusEvent(item: ChargePopupModel) {
    this.setState({ focusedCharge: item });
  }

  @action
  async printCharge() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );
  }


  render() {
    return <ResultTemplate
      scope={this}
    />;
  }
}
