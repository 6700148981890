

export class BreakDownModalModel {
  /**
   * 처리자명
   *
   */
  readonly pernm: string;

  /**
   * 담당자명
   *
   */
  readonly actpernm: string;

  /**
   * 접수일자
   *
   */
  readonly recedate: string;

  /**
   * 접수시간
   *
   */
  readonly recetime: string;

  /**
   * 완료일자
   *
   */
  readonly compdate: string;

  /**
   * 완료시간
   *
   */
  readonly comptime: string;

  /**
   * 도착일자
   *
   */
  readonly arrivdate: string;

  /**
   * 도착시간
   *
   */
  readonly arrivtime: string;

  /**
   * 현장명
   *
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   */
  readonly equpnm: string;

  /**
   * 고장내용
   *
   */
  readonly contnm: string;

  /**
   * 고장내용상세
   *
   */
  readonly contremark: string;

  /**
   * 처리내용
   *
   */
  readonly resunm: string;

  /**
   * 처리내용상세
   *
   */
  readonly resuremark: string;

  /**
   * 고장요인
   *
   */
  readonly remonm: string;

  /**
   * 고장요인상세
   *
   */
  readonly remoremark: string;

  /**
   * 고장원인
   *
   */
  readonly facnm: string;

  /**
   * 고장부위
   *
   */
  readonly greginm: string;

  /**
   * 고장부위상세
   *
   */
  readonly reginm: string;

  /**
   * 처리방법
   *
   */
  readonly resultnm: string;

  /**
   * 대응시간
   *
   */
  readonly resultime: string;

  /**
   * 처리시간
   *
   */
  readonly resulttime: string;

  /**
   * 진행내역
   *
   */
  readonly result: string;

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.actpernm = data.actpernm || '';
    this.recedate = data.recedate || '';
    this.recetime = data.recetime || '';
    this.compdate = data.compdate || '';
    this.comptime = data.comptime || '';
    this.arrivdate = data.arrivdate || '';
    this.arrivtime = data.arrivtime || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.contnm = data.contnm || '';
    this.contremark = data.contremark || '';
    this.resunm = data.resunm || '';
    this.resuremark = data.resuremark || '';
    this.remonm = data.remonm || '';
    this.remoremark = data.remoremark || '';
    this.facnm = data.facnm || '';
    this.greginm = data.greginm || '';
    this.reginm = data.reginm || '';
    this.resultnm = data.resultnm || '';
    this.resultime = data.resultime || '';
    this.resulttime = data.resulttime || '';
    this.result = data.result || '';
  }
}
