import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e450
 * @category 견적비용등록
 */

export class EstimatedCostModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(3)
   */
  costcd: string;

  /**
   * 견적비용명
   *
   * **data type** varchar(20)
   */
  costnm: string;

  /**
   * 단위
   *
   * **data type** varchar(20)
   */
  unit: string;

  /**
   * 수량
   *
   * **data type** float
   */
  qty: string;

  /**
   * 적용유무
   *
   * **data type** char(1)
   */
  yn: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 단가
   *
   * **data type** float
   */
  uamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.costcd = data.costcd || '';
    this.costnm = data.costnm || '';
    this.unit = data.unit || '';
    this.qty = data.qty || '';
    this.yn = data.yn || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.uamt = data.uamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
