import * as React from 'react';
import {
  Button, FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentTableHeader } from '../headers/EnrollmentTableHeader';

/**
 * 화면 > 탭 A
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export const EnrollmentTabTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <Button onClick={() => scope.onClickDetailNew()}>추가</Button>
      <Button onClick={() => scope.onClickDetailRemove()}>삭제</Button>
    </FlexLayout>

    <TableLayout
      ref={scope.table}
      infinityHandler={scope.infinity}
      header={EnrollmentTableHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataTable}
      onRowFocusEvent={(item, index) => scope.onTableRowFocusEvent(item, index)}
      onChange={(rows) => scope.onTableChange(rows)}
    />
  </FlexLayout>
);
