import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Global, TemplateProps } from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { TodayPlan } from './TodayPlan';
import style from './TodayPlan.module.scss';
import { Button } from '../../../../components/forms/Button';
import { DragAndDropLayout } from '../../../../components/layout/DragAndDropLayout';

/*
 * 화면
 * @window w_tb_e037w_03
 * @category 금일계획 조회
*/

export const TodayPlanTemplate: React.FC<TemplateProps<TodayPlan>> = ({
  scope,
}) => {
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout
        size={Global.LAYOUT_BUTTON_HEIGHT_1}
        style={{ color: 'var(--color-brown)' }}
        justify='right'
        align='center'
      >
        *스케쥴계획이 반영되지 않은 경우 새로고침을 눌러주세요~!
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={220}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={400}>
            <span>검색어</span>
            <TextBox
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.onRetrieveEvent()}>
            <div style={{ width: 80 }}>새로고침</div>
          </Button>
        </SearchLayout>

      </FlexLayout>
      <FlexLayout
        size={Global.LAYOUT_GRID_HEIGHT_1}
        className={style.pageLayout}
      >
        <table className={style.headerTable}>
          <tbody>
          <tr>
            <th>근태</th>
            <td>{model?.atdnm01}</td>
          </tr>
          <tr>
            <th>공사</th>
            <td>{model?.atdnm02}</td>
          </tr>
          <tr>
            <th>당직</th>
            <td>{model?.atdnm03}</td>
          </tr>
          </tbody>
        </table>
      </FlexLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout weight={3}>
          <DragAndDropLayout
            ref={scope.dragAndDrop}
            header={[
              {
                id: 'sort',
                text: '순번',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                      {x.sort}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '사원명',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    style={{ color: x.flag === '0' && 'var(--color-blue)' }}
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'overwk',
                text: '익일계획',
                width: 500,
                render: (x, rowUpdate, ref) => (
                 <TextBox
                   textAlign="left"
                   ref={ref}
                   value={(x.overwk).replace(/\\n|￦n|￦/g, '')}
                   // value={x.overwk}
                   onChange={(value) => rowUpdate({ overwk: value })}
                 />
                ),
              },
              {
                id: 'inpernm',
                text: '입력자',
                width: 90,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.inpernm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.todayPlanList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => { scope.onUpdatedRows(rows, updatedRows); }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
