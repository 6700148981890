import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import { InspectorStatusTemplate } from './InspectorStatus.template';
import { InspectorStatusModel } from './InspectorStatus.model';
import {
  GridLayout,
  GridLayoutAdditionalHeader,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PopupModel } from './Popup.model';

interface InspectorStatusState {
  stdate?: string;
  enddate?: string;
  divicd?: string;
  divinm?: string;
  perid?: string;
  pernm?: string;
  seq: Array<string>;
  trailTotals?: any;

  data: Array<InspectorStatusModel>;
  focusedData: InspectorStatusModel;

  popup: boolean;
  popupData: Array<PopupModel>;

  wkactcds: Array<any>;
  contgs: Array<any>;

  // popup trail
  pernm_tot: string;
  ju_tot: string;
  day_tot: string;
  bu_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e035w_04
 * @category 점검자별[주/부]점검현황
 */
export class InspectorStatus extends PageComponent<PageProps, InspectorStatusState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  popupInfinity?: InfinityRetrieve;

  gridAdditionalHeader?: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    this.state = props.state || {
      stdate: `${year}${month}`,
      enddate: '',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',

      data: [],
      seq: [],

      wkactcds: [],
      contgs: [],

      pernm_tot: '',
      ju_tot: '',
      day_tot: '',
      bu_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    await this.setState({ wkactcds: data?.items });

    // 리스트 (계약분류)
    const data2 = await api.dropdown('wf_dd_ca510_001_02');

    if (!data2) return;

    // 성공시 상태 반영
    this.setState({ contgs: data2?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    await this.SS({ data: [] });
    this.infinity = new InfinityRetrieve(
      {
        mon: this.state.stdate,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => (api.retrieve(params)),
      (items) => {
        this.setState({
          data: [...this.state.data || [], ...items.map((x: any) => new InspectorStatusModel(x))],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        await this.grid.current?.setFocus(0);
      },
    );
    await this.infinity?.retrieveAll();
    const seqData: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.state.data?.length; i++) {
      seqData.push(new InspectorStatusModel({
        ...this.state.data[i],
        seq: String(i + 1),
      }));
    }
    await this.SS({
      data: seqData,
      trailTotals: this.infinity?.box,
    });
    this.grid.current?.setFocus(0);
    this.grid.current?.forceRepaint();
  }

  @action
  async onRowFocusEvent(item: InspectorStatusModel) {
    await this.SS({
      focusedData: item,
    });
  }

  @action
  async onSetEndDate(endDates: string) {
    if (endDates === 'mal') {
      const date = String(new Date(Number(this.state.stdate?.substr(0, 4)), Number(this.state.stdate?.substr(3, 2)), 0).getDate());
      await this.SS({
        enddate: `${this.state.stdate}${date}`,
      });
    } else {
      await this.SS({
        enddate: `${this.state.stdate}${endDates}`,
      });
    }
  }


  @action
  async openPopup(isOpen: boolean) {
    await this.SS({
      popupData: [],
      popup: isOpen,
    });
    await this.onPopupRetrieve();
  }

  @action
  async onPopupRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      popupData: [],
      pernm_tot: '0',
      ju_tot: '0',
      bu_tot: '0',
      day_tot: '0',
    });

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_e035w_04',
        stdate: `${this.state.stdate}01`,
        enddate: this.state.enddate,
        perid: this.state.focusedData.perid,
      });

    await this.SS({
      popupData: data?.items,
      pernm_tot: data?.pernm_tot,
      ju_tot: data?.ju_tot || '0',
      bu_tot: data?.bu_tot || '0',
      day_tot: data?.day_tot || '0',
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      mon: this.state.stdate,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환 내역이 없습니다.');
      return;
    }

    await api.excel({
      mon: this.state.stdate,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }


  render() {
    return (
      <InspectorStatusTemplate scope={this}>
      </InspectorStatusTemplate>
    );
  }
}
