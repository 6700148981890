import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Effort } from '../Effort';
import EffortModel from '../models/EffortModel';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 - 검사월 > 그리드
 * @window w_tb_e601w_sulchi_06
 * @category 월공수현황
 */
export const EffortGridKc: GLHF<EffortModel, Effort> = (scope) => ([
  {
    id: 'pernm',
    text: '설치자',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'mpclafi',
    text: '구분',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.mpclafi === '0' ? 'var(--color-red)' : 'var(--color-blue)',
        }}
      >
        {x.mpclafi === '0' ? '직영' : '외주'}
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: '01월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon01_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon01 === '0' ? '' : x.mon01}
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: '02월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon02_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon02 === '0' ? '' : x.mon02}
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: '03월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon03_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon03 === '0' ? '' : x.mon03}
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: '04월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon04_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon04 === '0' ? '' : x.mon04}
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: '05월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon05_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon05 === '0' ? '' : x.mon05}
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: '06월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon06_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon06 === '0' ? '' : x.mon06}
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: '07월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon07_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon07 === '0' ? '' : x.mon07}
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: '08월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon08_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon08 === '0' ? '' : x.mon08}
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: '09월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon09_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon09 === '0' ? '' : x.mon09}
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: '10월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon10_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon10 === '0' ? '' : x.mon10}
      </FlexLayout>
    ),
  },
  {
    id: 'mon11',
    text: '11월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon11_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon11 === '0' ? '' : x.mon11}
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: '12월',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcMon12_tot, true)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {x.mon12 === '0' ? '' : x.mon12}
      </FlexLayout>
    ),
  },
  {
    id: 'sum',
    text: '합계',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tabKcTmon_tot, true)}
      </FlexLayout>
    ),
    render: (x) => {
      let sum = 0;

      sum += parseInt(x.mon01, 10) || 0;
      sum += parseInt(x.mon02, 10) || 0;
      sum += parseInt(x.mon03, 10) || 0;
      sum += parseInt(x.mon04, 10) || 0;
      sum += parseInt(x.mon05, 10) || 0;
      sum += parseInt(x.mon06, 10) || 0;
      sum += parseInt(x.mon07, 10) || 0;
      sum += parseInt(x.mon08, 10) || 0;
      sum += parseInt(x.mon09, 10) || 0;
      sum += parseInt(x.mon10, 10) || 0;
      sum += parseInt(x.mon11, 10) || 0;
      sum += parseInt(x.mon12, 10) || 0;

      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {sum}
        </FlexLayout>
      );
    },
  },
]);
