import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { GiveStateTemplate } from './GiveState.template';
import { GiveStateModel } from './GiveState.model';
import {
  InfinityRetrieve,
  SpjangItem,
} from '../../../../models';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components';

interface GiveStateState {
  // search
  stdate: string;
  enddate: string;
  cltcd: string;
  cltnm: string;
  actcd: string;
  actnm: string;
  spjangcd: string;
  spjangcds?: Array<SpjangItem>;
  searchQuery: string;
  projno: string;
  projnm: string;

  // 바뀐 조회조건 (사업장, 시작일, 종료일)
  changeSpjagncd: string;
  changeStdate: string;
  changeEnddate: string;

  // data
  data: Array<GiveStateModel>;
  leftData: Array<GiveStateModel>;
  focused?: GiveStateModel;

  // 전체 선택
  allChk: boolean;

  // trail
  total: string;
  sndamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_01
 * @category 지급현황
 */
export class GiveState extends PageComponent<PageProps, GiveStateState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  updatedRows?: Array<GiveStateModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      cltcd: '',
      actcd: '',
      spjangcd: 'ZZ',
      changeStdate: `${year}${month}01`,
      changeEnddate: `${year}${month}${date}`,
      changeSpjangcd: 'ZZ',
      searchQuery: '',
      projno: '',

      // data
      data: [],
      leftData: [],

      // 전체 선택
      allChk: true,

      // trail
      total: '0',
      sndamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트
    const data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 사업장코드, 시작일, 종료일이 바뀔때만 retrieve_cond 조회
    // eslint-disable-next-line max-len
    if ((this.state.spjangcd !== this.state.changeSpjagncd) || (this.state.stdate !== this.state.changeStdate) || this.state.enddate !== this.state.changeEnddate) {
      // 왼쪽 비용항목 List 조회
      const data = await api.fxExec('retrieve_cond', {
        spjangcd: this.state.spjangcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      });

      // items가 없으면 경고창
      if (data?.items === undefined) {
        await this.SS({
          data: [],
          leftData: [],
        });
        ConfirmWarning.show('경고', '먼저 비용항목을 등록하세요.');
        await this.table.current?.update(true);
        return;
      }

      // 왼쪽 List
      await this.SS({
        leftData: data?.items,
        changeSpjagncd: this.state.spjangcd,
        changeStdate: this.state.stdate,
        changeEnddate: this.state.enddate,
      });

      await this.table.current?.update(true);
      await this.table.current?.setFocus(0);
    }

    // 체크 하나이상 했는지 확인
    let noChk = true;

    this.state.leftData.forEach((x) => {
      if (x.chk === '1') {
        noChk = false;
      }
    });

    if (noChk) {
      ConfirmWarning.show('경고', '비용항목을 하나이상 체크해주세요');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
        actcd: this.state.actcd,
        spjangcd: this.state.spjangcd,
        as_nm: this.state.searchQuery,
        projno: this.state.projno,
        items: this.state.leftData.filter((x) => x.chk === '1').map((x) => ({
          code: x.code,
          chk: x.chk,
        })),
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.filter((x) => x.chk !== '1').map((x: any) => new GiveStateModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltcd', 'accnum'],
        [this.state.focused?.cltcd, this.state.focused?.accnum], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
      }
      this.SS({
        total: this.infinity?.box?.total || '0',
        sndamt_tot: this.infinity?.box?.sndamt_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent(item: GiveStateModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<GiveStateModel>, updatedRows: Array<GiveStateModel>) {
    this.updatedRows = updatedRows;
    this.setState({ leftData: rows });
  }

  @action
  onCehckAll(checked: boolean) {
    this.SS({
      allChk: checked,
      leftData: this.state.leftData.map((x) => new GiveStateModel({
        ...x,
        chk: checked ? '1' : '0',
      })),
    });
    this.table.current?.update(false);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      cltcd: this.state.cltcd,
      actcd: this.state.actcd,
      spjangcd: this.state.spjangcd,
      searchQuery: this.state.searchQuery,
      projno: this.state.projno,
      items: this.state.leftData.filter((x) => x.chk === '1').map((x) => ({
        code: x.code,
        chk: x.chk,
      })),
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      cltcd: this.state.cltcd,
      actcd: this.state.actcd,
      spjangcd: this.state.spjangcd,
      searchQuery: this.state.searchQuery,
      projno: this.state.projno,
      items: this.state.leftData.filter((x) => x.chk === '1').map((x) => ({
        code: x.code,
        chk: x.chk,
      })),
    });
  }

  render() {
    return (
      <GiveStateTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
