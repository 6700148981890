import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  ResponseModel,
  ProcessingModel,
  Tab3Model,
  Tab4Model,
} from './models';
import { TabModel } from '../../../../models/component';
import { ResponseTemplate } from './Response.template';
import { GridLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderResponseTab,
  TabHeaderProcessing,
  TabHeaderResponseTab2,
  TabHeaderProcessing2,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum ResponseTabId {
  Day,
  Act,
  Day2,
  Act2,
}

export const ResponseTabTitles = [
  '처리자별-대응시간',
  '처리자별-처리시간',
  '현장별-대응시간',
  '현장별-처리시간',
];

export const ResponseTabModels = [
  ResponseModel,
  ProcessingModel,
  Tab3Model,
  Tab4Model,
];

export const ResponseTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
];

export const ResponseTabDataStateNames = [
  'responseList',
  'processingList',
  'responseList2',
  'processingList2',
];

export const ResponseTabFocusedStateNames = [
  'ResponseUpdatesFocused',
  'ResponseErpUpdatesFocused',
  'ResponseUpdatesFocused2',
  'ResponseErpUpdatesFocused2',
];

export interface ResponseState {
  responseList?: Array<ResponseModel>; // 처리자별-대응시간 리스트
  processingList?: Array<ProcessingModel>; // 처리자별-처리시간 리스트
  responseList2?: Array<ResponseModel>; //  현장별-대응시간 리스트
  processingList2?: Array<ProcessingModel>; // 현장별-처리시간 리스트

  focusedTab?: TabModel;

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  perid: string; // 담당자
  pernm: string; // 담당자명
  actcd: string; // 현장
  actnm: string; // 현장명
  divicd: string; // 부서코드
  divinm: string;
  gubun: string;

  // url
  url?: string;
  url2?: string;
  url3?: string;
  url4?: string;

  total: string;
  cnt_tot: string; // 고장건수 통계
  time5_tot: string; // 5분이내 통계
  timeyul5_tot: string; // 5분이내 퍼센트
  time6_tot: string; // 5분이내 통계
  timeyul6_tot: string; // 5분이내 퍼센트
  time1_tot: string; // 5분이내 통계
  timeyul1_tot: string; // 5분이내 퍼센트
  time2_tot: string; // 5분이내 통계
  timeyul2_tot: string; // 5분이내 퍼센트
  time3_tot: string; // 5분이내 통계
  timeyul3_tot: string; // 5분이내 퍼센트
  time4_tot: string; // 5분이내 통계
  timeyul4_tot: string; // 5분이내 퍼센트
}
/**
 * 컨트롤러
 * @window w_tb_e411w_03
 * @category 대응/처리시간현황
 */
export class Response extends PageComponent<PageProps, ResponseState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = ResponseTabId;
    const titles = ResponseTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    this.tabs = [
      new TabModel(id.Day.toString(), MdReorder, titles[id.Day]),
      new TabModel(id.Act.toString(), MdReceipt, titles[id.Act]),
      new TabModel(id.Day2.toString(), MdReorder, titles[id.Day2]),
      new TabModel(id.Act2.toString(), MdReceipt, titles[id.Act2]),
    ];

    this.tabHeaders = [
      TabHeaderResponseTab,
      TabHeaderProcessing,
      TabHeaderResponseTab2,
      TabHeaderProcessing2,
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      url: '',
      url2: '',
      stdate: `${year - 1}${month}${date}`,
      enddate: `${year}${month}${date}`,
      responseList: [],
      processingList: [],
      searchQuery: '', // 검색어
      perid: bosuPerid || '%', // 담당자
      pernm: bosuPernm || '', // 담당자명
      actcd: '%', // 현장
      actnm: '', // 현장명
      divicd: bosuDivicd || '%',
      divinm: bosuDivinm || '',
      gubun: '%',

      total: '',
      qty_tot: '',
      banqty_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[ResponseTabId.Day]);
    // await this.doRetrieve(ResponseTabId.Day);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[ResponseTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxPrint(
      `${ResponseTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[ResponseTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxExcel(
      `${ResponseTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
    );
  }

  @computed
  get tabIndex(): ResponseTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: ResponseTabId) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
      (params) => api.fxExec(
        `${ResponseTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [ResponseTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[ResponseTabDataStateNames[i]],
              ...items.map((x: any) => new ResponseTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [ResponseTabDataStateNames[i]]: [],
          [ResponseTabFocusedStateNames[i]]: undefined,
        });
        await this.infinity?.retrieve();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [ResponseTabDataStateNames[i]]: [],
      [ResponseTabFocusedStateNames[i]]: undefined,
    }, async () => {
      const data = await this.infinity?.retrieve();
      this.setState({
        total: data?.total || '0',
        cnt_tot: data?.cnt_tot || '0',
        time5_tot: data?.time5_tot || '0',
        timeyul5_tot: data?.timeyul5_tot || '0',
        time6_tot: data?.time6_tot || '0',
        timeyul6_tot: data?.timeyul6_tot || '0',
        time1_tot: data?.time1_tot || '0',
        timeyul1_tot: data?.timeyul1_tot || '0',
        time2_tot: data?.time2_tot || '0',
        timeyul2_tot: data?.timeyul2_tot || '0',
        time3_tot: data?.time3_tot || '0',
        timeyul3_tot: data?.timeyul3_tot || '0',
        time4_tot: data?.time4_tot || '0',
        timeyul4_tot: data?.timeyul4_tot || '0',
      });
    });
    // @ts-ignore
    await this.onRowFocusEvent(this.state[ResponseTabDataStateNames[0]]);
  }

  @action
  onRowFocusEvent(item: any) {
    const { user } = this.props.publicStore;
    const url = `https://api.elmansoft.com/chart/stick.php?window=w_tb_e411w_04&type=0&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_cltcd=${this.state.gubun}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&xcnt=7&tnm0=총계&tnm5=5분이내&tnm6=6분-15분&tnm1=16분-30분&tnm2=30분-1시간&tnm3=1시간-2시간&tnm4=2시간이상`;
    const url2 = `https://api.elmansoft.com/chart/stick.php?window=w_tb_e411w_04&type=1&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_cltcd=${this.state.gubun}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&xcnt=7&tnm0=총계&tnm5=5분이내&tnm6=6분-15분&tnm1=16분-30분&tnm2=30분-1시간&tnm3=1시간-2시간&tnm4=2시간이상`;
    const url3 = `https://api.elmansoft.com/chart/stick.php?window=w_tb_e411w_04&type=3&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_cltcd=${this.state.gubun}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&xcnt=7&tnm0=총계&tnm5=5분이내&tnm6=6분-15분&tnm1=16분-30분&tnm2=30분-1시간&tnm3=1시간-2시간&tnm4=2시간이상`;
    const url4 = `https://api.elmansoft.com/chart/stick.php?window=w_tb_e411w_04&type=4&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_cltcd=${this.state.gubun}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}&xcnt=7&tnm0=총계&tnm5=5분이내&tnm6=6분-15분&tnm1=16분-30분&tnm2=30분-1시간&tnm3=1시간-2시간&tnm4=2시간이상`;


    // @ts-ignore
    this.setState({
      [ResponseTabFocusedStateNames[this.tabIndex]]: item,
      url,
      url2,
      url3,
      url4,
    });
  }

  render() {
    return (
      <ResponseTemplate
        scope={this}
      />
    );
  }
}
