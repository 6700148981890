import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e301
 * @category 민원접수등록
 */

export class ReportModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 접수일자
   *
   * **data type** varchar(3)
   */
  readonly recedate: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  readonly recenum: string;

  /**
   * 민원내용
   *
   * **data type** varchar(3)
   */
  readonly civilnm: string;

  /**
   * 비고
   *
   * **data type** varchar(3)
   */
  readonly remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.civilnm = data.civilnm || '';
    this.remark = data.remark || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
