
import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { TypeModel } from '../models';
import { BreakAnalysisAll } from '../BreakAnalysisAll';
import { TextBox } from '../../../../../components';

// 고장요인별
const Type: GridLayoutHeader<TypeModel, BreakAnalysisAll>[] = [
  {
    id: 'gubunnm',
    text: '기종별(호기구분별)',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.gubunnm}
      </FlexLayout>
    ),
  },
  {
    id: 'tot',
    text: '고장건수',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state?.tot_tot}건
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.tot}
      />
    ),
  },
];

export default Type;
