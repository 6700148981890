import * as React from 'react';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ProductGroup } from './ProductGroup';

/**
 * 화면
 * @window w_tb_ca503
 * @category 제품분류등록
 */
export const ProductGroupTemplate: React.FC<TemplateProps<ProductGroup>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
   <FlexLayout isVertical={true}>
     <SearchLayout
       size={Global.LAYOUT_SEARCH_HEIGHT_1}
     >
     <FlexLayout weight={0.2}>
       <span>품목분류</span>
       <ComboBox
         value={scope.state.searchSelect}
         style={{
           padding: '0 0 0 6px',
           fontSize: 12,
         }}
         data={scope.state.phm_modes?.map((x) => new ComboBoxModel(x.phm_mode, x.phm_modenm))}
         onSelect={(option) => setData({ searchSelect: option.value },
           () => scope.onRetrieveEvent())}
         isDisabledTrackingStateChange={true}
       />
     </FlexLayout>
     </SearchLayout>
     <FlexLayout>
       <TableLayout
         ref={scope.table}
         header={[
           {
             id: 'pgr_hcod',
             text: '대분류코드',
             width: 20,
           },
           {
             id: 'pgr_hnam',
             text: '대분류명',
             width: 40,
             render: (x, rowUpdate, ref) => (
               <TextBox
                 textAlign="left"
                 ref={ref}
                 value={x.pgr_hnam}
                 onChange={(value) => rowUpdate({ pgr_hnam: value })}
               />
             ),
           },
           {
             id: 'remark',
             text: '비고',
             width: 40,
             render: (x, rowUpdate, ref) => (
               <TextBox
                 textAlign="left"
                 ref={ref}
                 value={x.remark}
                 onChange={(value) => rowUpdate({ remark: value, new: '0' })}
               />
             ),
           },
         ]}
         data={scope.state.productGroupList}
         infinityHandler={scope.infinity}
         rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
         onChange={(rows, updatedRows) => {
           scope.onUpdatedRows(rows, updatedRows);
         }}
         onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
       />
     </FlexLayout>

     <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
       <FlexLayout justify="right" weight={3}>
         <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.dw_2_new()}>추가</Button>
         <Button
           style={{ height: '25px', width: '60px' }}
           theme={BUTTON_THEMES.RED}
           onClick={() => scope.dw_2_delete()}
         >
           삭제
         </Button>
       </FlexLayout>
       <FlexLayout justify="right" weight={3}>
         <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.dw_3_new()}>추가</Button>
         <Button
           style={{ height: '25px', width: '60px' }}
           theme={BUTTON_THEMES.RED}
           onClick={() => scope.dw_3_delete()}
         >
           삭제
         </Button>
       </FlexLayout>
     </FlexLayout>

     <FlexLayout>
       <TableLayout
         ref={scope.tableDetail}
         header={[
           {
             id: 'bgroup',
             text: '중분류코드',
             width: 20,
           },
           {
             id: 'bgrpnm',
             text: '중분류명',
             width: 80,
             render: (x, rowUpdate, ref) => (
               <TextBox
                 textAlign="left"
                 ref={ref}
                 value={x.bgrpnm}
                 onChange={(value) => rowUpdate({ bgrpnm: value })}
               />
             ),
           },
         ]}
         data={scope.state.productGroupDetailList}
         rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
         onChange={(rows2, updatedRows2) => {
           scope.onUpdatedRows2(rows2, updatedRows2);
         }}
         onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
       />

       <TableLayout
         ref={scope.tableDetail2}
         header={[
           {
             id: 'cgroup',
             text: '소분류코드',
             width: 20,
           },
           {
             id: 'cgrpnm',
             text: '소분류명',
             width: 50,
             render: (x, rowUpdate, ref) => (
               <TextBox
                 textAlign="left"
                 ref={ref}
                 value={x.cgrpnm}
                 onChange={(value) => rowUpdate({ cgrpnm: value })}
               />
             ),
           },
           {
             id: 'age_year',
             text: '부품수명주기(년)',
             width: 30,
             render: (x, rowUpdate, ref) => (
               <FormatTextBox
                 ref={ref}
                 format='##년'
                 value={x.age_year}
                 textAlign="center"
                 onChange={(value) => rowUpdate({ age_year: value })}
               />
             ),
           },
           {
             id: 'age_month',
             text: '부품수명주기(개월)',
             width: 30,
             render: (x, rowUpdate, ref) => (
               <FormatTextBox
                 ref={ref}
                 format="##개월"
                 value={x.age_month}
                 textAlign="center"
                 onChange={(value) => rowUpdate({ age_month: value })}
               />
             ),
           },
         ]}
         data={scope.state.productGroupDetailList2}
         rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
         onChange={(rows3, updatedRows3) => {
           scope.onUpdatedRows3(rows3, updatedRows3);
         }}
         onRowFocusEvent={(item) => scope.onRowFocusEvent3(item)}
       />
     </FlexLayout>

   </FlexLayout>
  );
};
