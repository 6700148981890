export interface IComboBoxModel {
  value: any;
  remark: any;
}

export class ComboBoxModel implements IComboBoxModel {
  readonly value: any;

  readonly remark: any;

  readonly item: any;

  constructor(value: any, remark: any, item: any = undefined) {
    this.value = value;
    this.remark = remark;
    this.item = item || {
      value,
      remark,
    };
  }
}
