import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Plan } from '../Plan';
import PlanExpireModel from '../models/PlanExpireModel';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 미계획 > 그리드 레이아웃
 * @window w_tb_e035
 * @category 점검계획등록
 */
export const PlanExpireGridHeader: GLHF<PlanExpireModel, Plan> = () => ([
  {
    id: 'actcd',
    text: '현장코드',
    width: 20,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '만료일자',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.enddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '점검계획일자',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.plandate)}
      </FlexLayout>
    ),
  },
]);
