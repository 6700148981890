import { computed } from 'mobx';

/**
 * 계획대비실적[공사]모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class ConstructionModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 구분
   *
   * **data type** char(5)
   */
  gubun: string;

  /**
   * 결과
   *
   * **data type** char(1)
   */
  compyn: string;

  /**
   * 계획금액
   *
   * **data type** number
   */
  planamt: string;

  /**
   * 금액
   *
   * **data type** number
   */
  costamt: string;

  /**
   * 차이금액
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 견적일자
   *
   * **data type** char(8)
   */
  costdate: string;

  /**
   * 계획내용
   *
   * **data type** char(255)
   */
  constnm: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  perid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.perid = data.perid || '';
    this.actnm = data.actnm || '';
    this.gubun = data.gubun || '';
    this.compyn = data.compyn || '';
    this.planamt = data.planamt || '';
    this.costamt = data.costamt || '';
    this.addamt = data.addamt || '';
    this.costdate = data.costdate || '';
    this.constnm = data.constnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
