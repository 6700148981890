import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
} from '../../../../constants';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { MeetingTemplate } from './Meeting.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { MeetingModel } from './models';
import { PageComponent } from '../../../../utils';
import { DateStabilizer } from '../../../../utils/time';

interface MeetingState {

  // 검색 조건
  stdate: string;
  enddate: string;
  projno: string;
  projnm: string;
  searchQuery: string;

  // 데이터 객체
  focusedMeeting: MeetingModel;
  data: MeetingModel;
  lastNewData: MeetingModel,
  meetingList: Array<MeetingModel>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isCopy: boolean; // 복사 여부
  isReported: boolean; // 결재상신 여부

  focusIndex: number;
  chk: string;
  custcd: string;
  spjangcd: string;
  spjangnm: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb407
 * @category 회의록
 */
export class Meeting extends PageComponent<PageProps, MeetingState>
  implements PageToolEvents {
  updatedRows?: Array<MeetingModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const storage = JSON.parse(window.localStorage['last-login'] || '{}');

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      projno: '',
      projnm: '',
      isCopy: false,
      isReported: false,
      fileDetailModal: false,
      meetingList: [],
      data: new MeetingModel(),
      focusIndex: 0,
      chk: '0',
      custcd: storage.custcd || 'dmyong_g',
      spjangcd: storage.spjangcd || '10',
      spjangnm: storage.spjangnm || '대명엘리베이터',
      total: '',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          paperdate: this.state.data.paperdate,
          papernum: this.state.data.papernum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false,
      );

      data && this.setState({
        data: new MeetingModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        projno: this.state.projno,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          meetingList: items.map((x: any, index: number) => (
            new MeetingModel({ ...x, page: (items.length - index).toString() }))),
          total: items.length.toString(),
        }, next);
      },
      async () => {
        await this.SS({ meetingList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      meetingList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.state.meetingList.length > 0
      && this.onRowFocusEvent(this.state.meetingList[this.state.focusIndex], this.state.focusIndex);
      if (this.state.chk === '1') {
        this.grid.current?.setFocus(this.state.focusIndex);
        this.setState({ chk: '0' });
      } else {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  onRowFocusEvent(item: MeetingModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        isCopy: false,
        isReported: false,
      });
      return;
    }
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedMeeting: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            paperdate: this.state.focusedMeeting?.paperdate,
            papernum: this.state.focusedMeeting?.papernum,
            page: this.state.focusedMeeting?.page,
          });

        await data && this.setState({ data: new MeetingModel(data) });

        // 결재상신 여부확인
        if (this.state.focusedMeeting?.appgubun === null
          || this.state.focusedMeeting?.appgubun === ''
          || this.state.focusedMeeting?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedMeeting!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.MEETING, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedMeeting!, this.state.focusIndex);
  }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `작성일자: ${this.state.focusedMeeting?.paperdate}, 문서번호: ${this.state.data.appnum}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.exec(Category.EMAIL, 'wb_copy', {
      projno: this.state.data.projno,
      projnm: this.state.data.projnm,
      compdate: this.state.data.compdate,
      attendee: this.state.data.attendee,
      place: this.state.data.place,
      title: this.state.data.title,
      perid: this.state.data.perid,
      remark: this.state.data.remark,
    });

    if (data) {
      const newModel = new MeetingModel(data, true);

      this.setState({
        data: newModel,
        meetingList: [newModel, ...this.state.meetingList],
        focusedMeeting: newModel,
        isCopy: true,
      });
      this.grid.current?.setFocus(0);
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.MEETING);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.MEETING.toString(),
      appnum: this.state.data.appnum,
      appgubun: this.state.data.appgubun,
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      title: this.state.data.title,
      perid: this.state.data.perid,
      refer: this.state.data.refer,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.data.new,
      custcd: this.state.data.custcd,
      spjangcd: this.state.data.spjangcd,
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      compdate: this.state.data.compdate,
      place: this.state.data.place,
      title: this.state.data.title,
      projno: this.state.data.projno,
      projnm: this.state.data.projnm,
      attendee: this.state.data.attendee,
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      remark: this.state.data.remark,
      apptime: this.state.data.apptime,
      appgubun: this.state.data.appgubun,
      appperid: this.state.data.appperid,
      appremark: this.state.data.appremark,
      appnum: this.state.data.appnum,
      rspnm1: this.state.data.rspnm1,
      rspnm2: this.state.data.rspnm2,
      rspnm3: this.state.data.rspnm3,
      rspnm4: this.state.data.rspnm4,
      appdate1: this.state.data.appdate1,
      appdate2: this.state.data.appdate2,
      appdate3: this.state.data.appdate3,
      appdate4: this.state.data.appdate4,
      apptime1: this.state.data.apptime1,
      apptime2: this.state.data.apptime2,
      apptime3: this.state.data.apptime3,
      apptime4: this.state.data.apptime4,
      appgubun1: this.state.data.appgubun1,
      appgubun2: this.state.data.appgubun2,
      appgubun3: this.state.data.appgubun3,
      appgubun4: this.state.data.appgubun4,
      apppernm1: this.state.data.apppernm1,
      apppernm2: this.state.data.apppernm2,
      apppernm3: this.state.data.apppernm3,
      apppernm4: this.state.data.apppernm4,
      inperid: this.state.data.inperid,
      indate: this.state.data.indate,
      picflag: this.state.data.picflag,
      upgrade: this.state.data.upgrade,
      rspnm: this.state.data.rspnm,
      divinm: this.state.data.divinm,
      refer: this.state.data.refer,
      attcnt: this.state.data.attcnt,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.paperdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new MeetingModel(data, true);

      this.setState({
        lastNewData: newModel,
        data: newModel,
        meetingList: [
          newModel,
          ...this.state.meetingList,
        ],
        total: this.state.meetingList.length.toString(),
        focusedMeeting: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          paperdate: this.state.data.paperdate,
          papernum: this.state.data.papernum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false);
    } else {
      const text = `작성일자: ${this.state.focusedMeeting?.paperdate} 작성번호: ${this.state.focusedMeeting?.papernum}`;
      await api.delete(text, {
        paperdate: this.state.data.paperdate,
        papernum: this.state.data.papernum,
        appnum: this.state.data.appnum,
        appgubun: this.state.data.appgubun,
        title: this.state.data.title,
      }) && this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.papernum, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      appgubun: this.state.data.appgubun,
      net: '',
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  render() {
    return (
      <MeetingTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new MeetingModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
