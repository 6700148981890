import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ja001_02
 * @category 월별입퇴사자현황
 */
export class MonthlyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 입퇴사
   *
   * **data type** varchar(2)
   */
  readonly rtclafi: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  rtmon: string;

  /**
   * 입사일
   *
   * **data type** varchar(8)
   */
  entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  rtdate: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 직급코드
   *
   * **data type** varchar(2)
   */
  rspcd: string;

  /**
   * 직급
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 4대보험취득일
   *
   * **data type** varchar(8)
   */
  insudate: string;

  /**
   * 핸드폰번호
   *
   * **data type** varchar(20)
   */
  handphone: string;

  /**
   * 우편번호
   *
   * **data type** varchar(6)
   */
  zipcd: string;

  /**
   * 주소
   *
   * **data type** char(90)
   */
  zipadres: string;

  /**
   * 주소
   *
   * **data type** char(90)
   */
  adres: string;

  /**
   * 기초인원
   *
   * **data type** 모름
   */
  mon: string;

  /**
   * 평균인원
   *
   * **data type** 모름
   */
  reserv: string;

  /**
   * 평균이직률
   *
   * **data type** 모름
   */
  aver: string;

  /**
   * 수습x
   *
   * **data type** 모름
   */
  aver3not: string;

  /**
   * 1월 입사
   *
   * **data type** 모름
   */
  newmon01: string;

  /**
   * 2월입사
   *
   * **data type** 모름
   */
  newmon02: string;

  /**
   * 3월입사
   *
   * **data type** 모름
   */
  newmon03: string;

  /**
   * 4월입사
   *
   * **data type** 모름
   */
  newmon04: string;

  /**
   * 5월입사
   *
   * **data type** 모름
   */
  newmon05: string;

  /**
   * 6월입사
   *
   * **data type** 모름
   */
  newmon06: string;

  /**
   * 7월입사
   *
   * **data type** 모름
   */
  newmon07: string;

  /**
   * 8월입사
   *
   * **data type** 모름
   */
  newmon08: string;

  /**
   * 9월입사
   *
   * **data type** 모름
   */
  newmon09: string;

  /**
   * 10월입사
   *
   * **data type** 모름
   */
  newmon10: string;

  /**
   * 11월입사
   *
   * **data type** 모름
   */
  newmon11: string;

  /**
   * 12월입사
   *
   * **data type** 모름
   */
  newmon12: string;

  /**
   * 1월 퇴사
   *
   * **data type** 모름
   */
  outmon01: string;

  /**
   * 2월 퇴사
   *
   * **data type** 모름
   */
  outmon02: string;

  /**
   * 3월 퇴사
   *
   * **data type** 모름
   */
  outmon03: string;

  /**
   * 4월 퇴사
   *
   * **data type** 모름
   */
  outmon04: string;

  /**
   * 5월 퇴사
   *
   * **data type** 모름
   */
  outmon05: string;

  /**
   * 6월 퇴사
   *
   * **data type** 모름
   */
  outmon06: string;

  /**
   * 7월퇴사
   *
   * **data type** 모름
   */
  outmon07: string;

  /**
   * 8월퇴사
   *
   * **data type** 모름
   */
  outmon08: string;

  /**
   * 9월퇴사
   *
   * **data type** 모름
   */
  outmon09: string;

  /**
   * 10월퇴사
   *
   * **data type** 모름
   */
  outmon10: string;

  /**
   * 11월퇴사
   *
   * **data type** 모름
   */
  outmon11: string;

  /**
   * 12월퇴사
   *
   * **data type** 모름
   */
  outmon12: string;

  /**
   * 1월 인원
   *
   * **data type** 모름
   */
  totmon01: string;

  /**
   * 2월 인원
   *
   * **data type** 모름
   */
  totmon02: string;

  /**
   * 3월 안원
   *
   * **data type** 모름
   */
  totmon03: string;

  /**
   * 4월 인원
   *
   * **data type** 모름
   */
  totmon04: string;

  /**
   * 5월 인원
   *
   * **data type** 모름
   */
  totmon05: string;

  /**
   * 6월 인원
   *
   * **data type** 모름
   */
  totmon06: string;

  /**
   * 7월 인원
   *
   * **data type** 모름
   */
  totmon07: string;

  /**
   * 8월 인원
   *
   * **data type** 모름
   */
  totmon08: string;

  /**
   * 9월 인원
   *
   * **data type** 모름
   */
  totmon09: string;

  /**
   * 10월 인원
   *
   * **data type** 모름
   */
  totmon10: string;

  /**
   * 11월 인원
   *
   * **data type** 모름
   */
  totmon11: string;

  /**
   * 12월 인원
   *
   * **data type** 모름
   */
  totmon12: string;

  /**
   * 근속년수
   *
   * **data type** 모름
   */
  dayterm: string;

  /**
   * 년
   *
   * **data type** 모름
   */
  year: string;

  /**
   * 총원
   *
   * **data type** 모름
   */
  total: string;

  /**
   * 4대보험상실일
   *
   * **data type** 모름
   */
  inoutdate: string;

  /**
   * data new 를 위함
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rtclafi = data.rtclafi || '';
    this.spjangnm = data.spjangnm || '';
    this.rtmon = data.rtmon || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.dayterm = data.dayterm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.insudate = data.insudate || '';
    this.handphone = data.handphone || '';
    this.zipcd = data.zipcd || '';
    this.zipadres = data.zipadres || '';
    this.adres = data.adres || '';
    this.mon = data.mon || '';
    this.reserv = data.reserv || '';
    this.aver = data.aver || '';
    this.aver3not = data.aver3not || '';
    this.newmon01 = data.newmon01 || '';
    this.newmon02 = data.newmon02 || '';
    this.newmon03 = data.newmon03 || '';
    this.newmon04 = data.newmon04 || '';
    this.newmon05 = data.newmon05 || '';
    this.newmon06 = data.newmon06 || '';
    this.newmon07 = data.newmon07 || '';
    this.newmon08 = data.newmon08 || '';
    this.newmon09 = data.newmon09 || '';
    this.newmon10 = data.newmon10 || '';
    this.newmon11 = data.newmon11 || '';
    this.newmon12 = data.newmon12 || '';
    this.outmon01 = data.outmon01 || '';
    this.outmon02 = data.outmon02 || '';
    this.outmon03 = data.outmon03 || '';
    this.outmon04 = data.outmon04 || '';
    this.outmon05 = data.outmon05 || '';
    this.outmon06 = data.outmon06 || '';
    this.outmon07 = data.outmon07 || '';
    this.outmon08 = data.outmon08 || '';
    this.outmon09 = data.outmon09 || '';
    this.outmon10 = data.outmon10 || '';
    this.outmon11 = data.outmon11 || '';
    this.outmon12 = data.outmon12 || '';
    this.totmon01 = data.totmon01 || '';
    this.totmon02 = data.totmon02 || '';
    this.totmon03 = data.totmon03 || '';
    this.totmon04 = data.totmon04 || '';
    this.totmon05 = data.totmon05 || '';
    this.totmon06 = data.totmon06 || '';
    this.totmon07 = data.totmon07 || '';
    this.totmon08 = data.totmon08 || '';
    this.totmon09 = data.totmon09 || '';
    this.totmon10 = data.totmon10 || '';
    this.totmon11 = data.totmon11 || '';
    this.totmon12 = data.totmon12 || '';
    this.year = data.year || '';
    this.total = data.total || '';
    this.inoutdate = data.inoutdate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
