import * as React from 'react';
import { action } from 'mobx';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { FieldPartsReplaceTemplate } from './FieldPartsReplace.template';
import { LeftDataModel } from './LeftData.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components';
import { RightDataModel } from './RightData.model';
import { DetailDataModel } from './DetailData.model';

interface FieldPartsReplaceState {
  searchQuery: string
  gijun: string
  divicd: string
  divinm: string
  perid: string
  pernm: string
  agecode: string
  agename: string

  wkactcds: Array<any>
  gubuns: Array<any>

  leftData: Array<LeftDataModel>
  leftFocusedData: LeftDataModel
  rightData: Array<RightDataModel>
  rightFocusedData: RightDataModel
  detailData: Array<DetailDataModel>
}

/**
 * 컨트롤러
 * @window w_tb_e471w_05
 * @category 현장별 부품교체[수명] 가능현황
 */
export class FieldPartsReplace extends PageComponent<PageProps, FieldPartsReplaceState>
  implements PageToolEvents {
  leftGrid: React.RefObject<GridLayout> = React.createRef();

  rightGrid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  leftInfinity?: InfinityRetrieve;

  rightInfinity?: InfinityRetrieve;

  detailInfinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      gijun: '0',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      agecode: '',
      agename: '',

      wkactcds: [],
      gubuns: [],

      leftData: [],
      leftFocusedData: [],
      rightData: [],
      rightFocusedData: [],
      detailData: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 회사구분 코드 가져오기
    let data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    await this.SS({ wkactcds: data?.items });

    // 구분 코드 가져오기
    data = await api.dropdown('wf_dd_da020');
    if (!data) return;
    await this.SS({ gubuns: data?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.leftInfinity = new InfinityRetrieve(
      {
        agecode: this.state.agecode,
        as_nm: this.state.searchQuery,
        divicd: this.state.divicd,
        perid: this.state.perid,
        gijun: this.state.gijun,
      },
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          leftData: [...this.state.leftData, ...items.map((x: any) => new LeftDataModel(x))],
        });
      },
      async () => {
        await this.setState({
          leftData: [],
        }, () => {
          this.leftInfinity?.retrieveAll();
        });
      },
    );

    await this.SS({
      leftData: [],
      rightData: [],
      detailData: [],
    });

    await this.leftInfinity?.retrieveAll();
    await this.leftGrid.current?.forceRepaint();

    if (this.state.leftData.length > 0) {
      await this.leftGrid.current?.setFocus(0);
    }
  }

  @action
  async onRowFocusEvent(item: LeftDataModel) {
    const { actionStore: api } = this.props;

    // 왼쪽 포커스 data, detailData 초기화
    await this.SS({
      leftFocusedData: item,
      detailData: [],
    });

    // 로우포커스 시 data 가져오기
    const data = await api.fxExec('dw_list_RowFocuschanged',
      {
        agecode: this.state.agecode,
        gijun: this.state.gijun,
        actcd: item?.actcd,
      });

    // 오른쪽 리스트 data
    await this.SS({
      rightData: data?.items,
    });
    await this.rightGrid.current?.forceRepaint();

    // data가 있으면 focus
    if (this.state.rightData.length > 0) {
      this.rightGrid.current?.setFocus(0);
    }
  }

  @action
  async rightRowFocusEvent(item: RightDataModel) {
    const { actionStore: api } = this.props;

    await this.SS({
      rightFocusedData: item,
    });

    const data = await api.fxExec(
      'dw_2_RowFocuschanged',
      {
        agecode: this.state.agecode,
        actcd: item?.actcd,
        equpcd: item?.equpcd,
      },
    );

    await this.SS({
      detailData: data?.items,
    });
  }

  render() {
    return <FieldPartsReplaceTemplate scope={this} />;
  }
}
