import { computed } from 'mobx';

/**
 * 메인 모델
 * @window w_tb_e471w_04
 * @category 공사수주매출통계
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 수주금액
   *
   * **data type** number
   */
  contamt: string;

  /**
   * 수주(부품교체)
   *
   * **data type** number
   */
  contamt13: string;

  /**
   * 수주(수리공사)
   *
   * **data type** number
   */
  contamt12: string;

  /**
   * 미매출
   *
   * **data type** number
   */
  notmisamt: string;

  /**
   * 설치매출
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 설치입금
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 설치미수금
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 잔액
   *
   * **data type** number
   */
  jamt: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 건수(공사)
   *
   * **data type** number
   */
  qty12: string;

  /**
   * 건수 (부품)
   *
   * **data type** number
   */
  qty13: string;

  /**
   * 건수 (무상)
   *
   * **data type** number
   */
  qtymu: string;

  /**
   * 매출
   *
   * **data type** number
   */
  misamt2: string;

  /**
   * 입금
   *
   * **data type** number
   */
  rcvamt2: string;

  /**
   * 미수금
   *
   * **data type** number
   */
  iamt2: string;

  /**
   * 수주(무상)
   *
   * **data type** number
   */
  contamtmu: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangnm = data.spjangnm || '';
    this.custcd = data.custcd || '';
    this.contamt = data.contamt || '';
    this.contamtmu = data.contamtmu || '';
    this.contamt13 = data.contamt13 || '';
    this.contamt12 = data.contamt12 || '';
    this.notmisamt = data.notmisamt || '';
    this.misamt = data.misamt || '';
    this.rcvamt = data.rcvamt || '';
    this.iamt = data.iamt || '';
    this.jamt = data.jamt || '';
    this.qty = data.qty || '';
    this.qty12 = data.qty12 || '';
    this.qty13 = data.qty13 || '';
    this.misamt2 = data.misamt2 || '';
    this.rcvamt2 = data.rcvamt2 || '';
    this.iamt2 = data.iamt2 || '';
    this.spjangcd = data.spjangcd || '';
    this.qtymu = data.qtymu || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
