import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { BreakUnit } from '../BreakUnit';
import { BreakUnitTabAllGridHeader } from '../headers/BreakUnitTabAllGridHeader';

/**
 * 화면 > 탭 > 전체
 * @window w_tb_e401w_01
 * @category 호기별고장통계
 */
export const BreakUnitTabAllTemplate: React.FC<TemplateProps<BreakUnit>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={BreakUnitTabAllGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.allList}
    />
  </FlexLayout>
);
