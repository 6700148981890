import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { GridLayout, TableLayout } from '../../../../components';
import { SalesPurchaseTemplate } from './SalesPurchase.template';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { SalesPurchaseModel } from './SalesPurchase.model';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

export interface SalesPurchaseState {
  year: string;
  billkind?: string;
  gubun?: Array<any>;
  searchGubun?: string;

  // data
  data: Array<SalesPurchaseModel>;
  dataDetail: Array<SalesPurchaseModel>;
  openChk?: Array<any>;
  openChk2?: Array<any>;
  focused?: SalesPurchaseModel;
  gubunGraph: string;
  pushTotalCheck: boolean;
  pushTotalCheck2: boolean;

  // trail
  misamt_tot: string;
  mijamt_tot: string;
  resuamt_tot: string;
  misamttot_tot: string;
  mijamttot_tot: string;
  resuamttot_tot: string;
  misamt01_tot: string;
  misamt02_tot: string;
  misamt03_tot: string;
  misamt04_tot: string;
  misamt05_tot: string;
  misamt06_tot: string;
  misamt07_tot: string;
  misamt08_tot: string;
  misamt09_tot: string;
  misamt10_tot: string;
  misamt11_tot: string;
  misamt12_tot: string;
  mijamt01_tot: string;
  mijamt02_tot: string;
  mijamt03_tot: string;
  mijamt04_tot: string;
  mijamt51_tot: string;
  mijamt06_tot: string;
  mijamt07_tot: string;
  mijamt08_tot: string;
  mijamt09_tot: string;
  mijamt10_tot: string;
  mijamt11_tot: string;
  mijamt12_tot: string;
  resuamt01_tot: string;
  resuamt02_tot: string;
  resuamt03_tot: string;
  resuamt04_tot: string;
  resuamt05_tot: string;
  resuamt06_tot: string;
  resuamt07_tot: string;
  resuamt08_tot: string;
  resuamt09_tot: string;
  resuamt10_tot: string;
  resuamt11_tot: string;
  resuamt12_tot: string;
  mijamt05_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_13
 * @category 월매출매입현황
 */
export class SalesPurchase extends PageComponent<PageProps, SalesPurchaseState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  table2: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinity4?: InfinityRetrieve;

  graph?: any;

  // 그래프 데이터 보내는 값
  misgubun?: Array<any>;

  mijgubun?: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      data: [],
      dataDetail: [],
      openChk: [],
      openChk2: [],
      searchGubun: '%',
      billkind: '%',
      pushTotalCheck: true,
      pushTotalCheck2: true,

      // trail
      misamt_tot: '',
      mijamt_tot: '',
      resuamt_tot: '',
      misamttot_tot: '',
      mijamttot_tot: '',
      resuamttot_tot: '',
      misamt01_tot: '',
      misamt02_tot: '',
      misamt03_tot: '',
      misamt04_tot: '',
      misamt05_tot: '',
      misamt06_tot: '',
      misamt07_tot: '',
      misamt08_tot: '',
      misamt09_tot: '',
      misamt10_tot: '',
      misamt11_tot: '',
      misamt12_tot: '',
      mijamt01_tot: '',
      mijamt02_tot: '',
      mijamt03_tot: '',
      mijamt04_tot: '',
      mijamt51_tot: '',
      mijamt06_tot: '',
      mijamt07_tot: '',
      mijamt08_tot: '',
      mijamt09_tot: '',
      mijamt10_tot: '',
      mijamt11_tot: '',
      mijamt12_tot: '',
      resuamt01_tot: '',
      resuamt02_tot: '',
      resuamt03_tot: '',
      resuamt04_tot: '',
      resuamt05_tot: '',
      resuamt06_tot: '',
      resuamt07_tot: '',
      resuamt08_tot: '',
      resuamt09_tot: '',
      resuamt10_tot: '',
      resuamt11_tot: '',
      resuamt12_tot: '',
      mijamt05_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 매입세금계산서
    const data = await api.dropdown('wf_dd_ca510_113_02');
    if (!data) return;
    this.setState({ gubun: data.items });

    // 왼쪽 리스트
    const data2 = await api.dropdown('wf_da023_code');
    if (!data2) return;
    this.setState({ openChk: data2.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });

    // 왼쪽 리스트2
    const data3 = await api.dropdown('wf_ca640_code',
      { year: this.state.year });

    if (!data3) return;
    this.setState({ openChk2: data3.items }, async () => {
      await this.table2.current?.update();
      this.table2.current?.setFocus(0, 1);
    });

    // 그래프 보내는 값
    this.misgubun = data2.items.map((x: any) => x.code).join('\',\'');
    this.mijgubun = data3.items.map((x: any) => x.code).join('\',\'');

    await this.onRetrieveEvent();
  }

  @action
  async onYearChange() {
    const { actionStore: api } = this.props;
    // 왼쪽 리스트2
    const data3 = await api.dropdown('wf_ca640_code',
      { year: this.state.year });

    if (!data3) return;
    this.setState({ openChk2: data3.items }, async () => {
      await this.table2.current?.update();
      this.table2.current?.setFocus(0, 1);
    });

    this.mijgubun = data3.items.map((x: any) => x.code).join('\',\'');

    await this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    const checkData2: any = [];
    this.state.openChk2?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData2.push({
          ...x,
        });
      }
    });
    if (!checkData2?.length) {
      ConfirmWarning.show('확인', '매입항목이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        year: this.state.year,
        billkind: this.state.billkind,
        gubun: this.state.searchGubun,
        items: this.state.openChk,
        items2: this.state.openChk2,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new SalesPurchaseModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity2?.retrieveTo(['resuamt', 'mon'],
        [this.state.focused?.resuamt, this.state.focused?.mon], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        this.grid.current?.setFocus(index);
        await this.onRetrieveEvent2();
      }
      this.graph = `https://api.elmansoft.com/chart/multi3.php?window=w_tb_ca642w_13&type=0&as_custcd=${user.custcd}&as_year=${this.state.year}&as_billkind=${this.state.billkind}&as_gubun=${this.state.searchGubun}&as_misgubun=${this.misgubun}&as_mijgubun=${this.mijgubun}`;
      await this.SS({
        misamt_tot: this.infinity2?.box?.misamt_tot || '0',
        mijamt_tot: this.infinity2?.box?.mijamt_tot || '0',
        resuamt_tot: this.infinity2?.box?.resuamt_tot || '0',
        misamttot_tot: this.infinity2?.box?.misamttot_tot || '0',
        mijamttot_tot: this.infinity2?.box?.mijamttot_tot || '0',
      });
    });
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity4 = new InfinityRetrieve(
      {
        year: this.state.year,
        billkind: this.state.billkind,
        gubun: this.state.searchGubun,
        items: this.state.openChk,
        items2: this.state.openChk2,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.SS({
          dataDetail: [
            ...this.state.dataDetail,
            ...items.map((x: any) => new SalesPurchaseModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataDetail: [],
        });

        await this.infinity4?.retrieveAll();
        if (this.state.dataDetail && this.state.dataDetail?.length > 0) {
          this.grid2.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataDetail: [],
    });
    await this.infinity4?.retrieveAll();
    const dataDate = this.infinity4?.box;

    await this.SS({
      resuamttot_tot: dataDate?.resuamttot_tot || '0',
      misamt01_tot: dataDate?.misamt01_tot || '0',
      misamt02_tot: dataDate?.misamt02_tot || '0',
      misamt03_tot: dataDate?.misamt03_tot || '0',
      misamt04_tot: dataDate?.misamt04_tot || '0',
      misamt05_tot: dataDate?.misamt05_tot || '0',
      misamt06_tot: dataDate?.misamt06_tot || '0',
      misamt07_tot: dataDate?.misamt07_tot || '0',
      misamt08_tot: dataDate?.misamt08_tot || '0',
      misamt09_tot: dataDate?.misamt09_tot || '0',
      misamt10_tot: dataDate?.misamt10_tot || '0',
      misamt11_tot: dataDate?.misamt11_tot || '0',
      misamt12_tot: dataDate?.misamt12_tot || '0',
      mijamt01_tot: dataDate?.mijamt01_tot || '0',
      mijamt02_tot: dataDate?.mijamt02_tot || '0',
      mijamt03_tot: dataDate?.mijamt03_tot || '0',
      mijamt04_tot: dataDate?.mijamt04_tot || '0',
      mijamt05_tot: dataDate?.mijamt05_tot || '0',
      mijamt06_tot: dataDate?.mijamt06_tot || '0',
      mijamt07_tot: dataDate?.mijamt07_tot || '0',
      mijamt08_tot: dataDate?.mijamt08_tot || '0',
      mijamt09_tot: dataDate?.mijamt09_tot || '0',
      mijamt10_tot: dataDate?.mijamt10_tot || '0',
      mijamt11_tot: dataDate?.mijamt11_tot || '0',
      mijamt12_tot: dataDate?.mijamt12_tot || '0',
      resuamt01_tot: dataDate?.resuamt01_tot || '0',
      resuamt02_tot: dataDate?.resuamt02_tot || '0',
      resuamt03_tot: dataDate?.resuamt03_tot || '0',
      resuamt04_tot: dataDate?.resuamt04_tot || '0',
      resuamt05_tot: dataDate?.resuamt05_tot || '0',
      resuamt06_tot: dataDate?.resuamt06_tot || '0',
      resuamt07_tot: dataDate?.resuamt07_tot || '0',
      resuamt08_tot: dataDate?.resuamt08_tot || '0',
      resuamt09_tot: dataDate?.resuamt09_tot || '0',
      resuamt10_tot: dataDate?.resuamt10_tot || '0',
      resuamt11_tot: dataDate?.resuamt11_tot || '0',
      resuamt12_tot: dataDate?.resuamt12_tot || '0',
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      billkind: this.state.billkind,
      gubun: this.state.searchGubun,
      items: this.state.openChk,
      items2: this.state.openChk2,
    });
  }

  @action
  onRowFocusEvent(item: SalesPurchaseModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ openChk: rows });
  }

  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({ openChk2: rows2 });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck: checked,
      openChk: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  async updateCheckAllToggle2(checked: boolean) {
    const checkData: any = [];
    this.state.openChk2?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck2: checked,
      openChk2: checkData,
    }, async () => {
      await this.table2.current?.update(false);
    });
  }

  render() {
    return (
      <SalesPurchaseTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
