/**
 * 모델
 * @window w_tb_e601w_02
 * @category 설치된지오래된현장
 */
import { computed } from 'mobx';

export class OldSpaceModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순번
   *
   * **data type** varchar(?)
   */
  seq: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 담당자
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;


  /**
   * 진행현황
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * 답변작성일
   *
   * **data type** varchar(8)
   */
  hdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  inperid: string;


  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.custcd = data.custcd || '';
    this.hdate = data.hdate || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
