import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601w_02
 * @category 설치된지오래된현장 [진행현황 팝업창]
 */
export class OldSpaceRemarkModalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 회사구분
   *
   * **data type** varchar(3)
   */
  wkactcd: string;

  /**
   * 고객조회번호
   *
   * **data type** char(30)
   */
  cltnum: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  /**
   * 장기미수3개월
   *
   * **data type** number
   */
  bemon03: string;

  /**
   * 장기미수2개월
   *
   * **data type** number
   */
  bemon02: string;

  /**
   * 장기미수1개월
   *
   * **data type** number
   */
  bemon01: string;

  /**
   * 장기미수3개월타이틀
   *
   * **data type** varchar(8)
   */
  mon03: string;

  /**
   * 장기미수2개월타이틀
   *
   * **data type** varchar(8)
   */
  mon02: string;

  /**
   * 장기미수1개월타이틀
   *
   * **data type** varchar(8)
   */
  mon01: string;

  /**
   * 진행현황
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 답변작성일
   *
   * **data type** varchar(8)
   */
  hdate: string;

  /**
   * 미수이유
   *
   * **data type** varchar(10)
   */
  saflag: string;

  /**
   * 건물상태
   *
   * **data type** varchar(10)
   */
  buildflag: string;

  /**
   * 기간
   *
   * **data type** string
   */
  styyyy: string;

  /**
   * 기간2
   *
   * **data type** string
   */
  stmm: string;

  /**
   * 검색어
   *
   * **data type** string
   */
  as_nm: string;

  /**
   * 순서
   *
   * **data type** string
   */
  seq: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totamt: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  recivstatus: string;

  /**
   * 청구금액
   *
   * **data type** varchar(8)
   */
  misamt: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  iamt: string;

  /**
   * 미수잔액
   *
   * **data type** varchar(8)
   */
  resuamt: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  misnum: string;

  /**
   * 장기미수
   *
   * **data type** varchar(8)
   */
  longmisnm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.inperid = data.inperid || '';
    this.wkactcd = data.wkactcd || '';
    this.cltnum = data.cltnum || '';
    this.recivstatus = (data.recivstatus || '').trim();
    this.totamt = data.totamt || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.bemon03 = data.bemon03 || '';
    this.bemon02 = data.bemon02 || '';
    this.bemon01 = data.bemon01 || '';
    this.mon03 = data.mon03 || '';
    this.mon02 = data.mon02 || '';
    this.mon01 = data.mon01 || '';
    this.remark = data.remark || '';
    this.hdate = data.hdate || '';
    this.saflag = data.saflag || '';
    this.buildflag = data.buildflag || '';
    this.styyyy = data.styyyy || '';
    this.stmm = data.stmm || '';
    this.as_nm = data.as_nm || '';
    this.seq = data.seq || '';
    this.misamt = data.misamt || '';
    this.iamt = data.iamt || '';
    this.resuamt = data.resuamt || '';
    this.misnum = data.misnum || '';
    this.longmisnm = data.longmisnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed get isNew() {
    return this.new === '1';
  }
}
