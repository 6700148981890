export default class AuthModel {
  readonly handphone: string;

  readonly userid: string;

  readonly password: string;

  constructor(handphone: string, userid: string, userpw: string) {
    this.handphone = handphone;
    this.userid = userid;
    this.password = userpw;
  }
}
