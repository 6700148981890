import { computed } from 'mobx';

/**
 *
 * @window w_tb_ca642w_10
 * @category 입출금현황
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 은행코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 은행명
   *
   * **data type** varchar(20)
   */
  banknm: string;

  /**
   * 계좌계정
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(20)
   */
  accnum: string;

  /**
   * 연동
   *
   * **data type** varchar(1)
   */
  signflag: string;

  /**
   * 마지막연동일자
   *
   * **data type** varchar(8)
   */
  lasttime: string;

  /**
   * 마지막연동시간
   *
   * **data type** varchar(8)
   */
  lastdate: string;

  /**
   * 전일잔액
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 입금금액
   *
   * **data type** decimal
   */
  misamt: string;

  /**
   * 잔액
   *
   * **data type** varchar(8)
   */
  resuamt: string;

  /**
   * 은행잔액
   *
   * **data type** varchar(8)
   */
  bank_resuamt: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 지급처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 발행년월
   *
   * **data type** varchar(8)
   */
  mismon: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 적요
   *
   * **data type** varchar(303)
   */
  remark: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 합계금액
   *
   * **data type** decimal
   */
  totamt: string;

  /**
   * 정렬
   *
   * **data type** char(1)
   */
  sort: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  rcv: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  rcvamt: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  resu: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  snd: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  sndamt: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  compare: string;

  accname: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.bankcd = data.bankcd || '';
    this.compare = data.compare || '';
    this.rcv = data.rcv || '';
    this.rcvamt = data.rcvamt || '';
    this.snd = data.snd || '';
    this.sndamt = data.sndamt || '';
    this.resuamt = data.resuamt || '';
    this.resu = data.resu || '';
    this.banknm = data.banknm || '';
    this.acccd = data.acccd || '';
    this.accnum = data.accnum || '';
    this.signflag = data.signflag || '';
    this.lastdate = data.lastdate || '';
    this.lasttime = data.lasttime || '';
    this.beamt = data.beamt || '';
    this.misamt = data.misamt || '';
    this.bank_resuamt = data.bank_resuamt || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.misdate = data.misdate || '';
    this.mismon = data.mismon || '';
    this.misnum = data.misnum || '';
    this.remark = data.remark || '';
    this.mijamt = data.mijamt || '';
    this.totamt = data.totamt || '';
    this.sort = data.sort || '';
    this.accname = data.accname || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
