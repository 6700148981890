import { action, makeObservable } from 'mobx';
import printJS from 'print-js';
import { AxiosResponse } from 'axios';
import { ModalStore, PublicStore, WaitQueueStore } from './index';
import { ActionRepository, FunctionRepository } from '../repositories';
import {
  ConfirmDelete,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../utils/confirm';
import { Category, Global, TempFileInfo } from '../constants';
import { MD5 } from '../utils/string';

const fileDownload = require('js-file-download');

interface IActionStore {
  print: () => void;
}

export default class ActionStore implements IActionStore {
  readonly publicStore: PublicStore;

  readonly waitQueueStore: WaitQueueStore;

  modalStore?: ModalStore;

  constructor(publicStore: PublicStore, waitQueueStore: WaitQueueStore) {
    this.publicStore = publicStore;
    this.waitQueueStore = waitQueueStore;

    makeObservable(this);
  }

  setModalStore(modalStore: ModalStore) {
    this.modalStore = modalStore;
  }

  async putState(name: string, params: any) {
    const { publicStore } = this;

    await FunctionRepository.putState(
      {
        custcd: publicStore.user.custcd,
        spjangcd: publicStore.user.spjangcd,
        perid: publicStore.user.perid,
        token: publicStore.user.token,
        category: Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
        window: publicStore.currentMenu.active.path?.substr(1) || '',
        state: JSON.stringify(publicStore.currentPage?.state || {}),
        sub: params?.sub || '',
        type: 'action',
        action_name: name,
        function_name: '',
      },
    );
  }

  async putLog(name: string, params: any) {
    const { publicStore } = this;

    FunctionRepository.putState(
      {
        custcd: publicStore.user.custcd,
        spjangcd: publicStore.user.spjangcd,
        perid: publicStore.user.perid,
        token: publicStore.user.token,
        category: Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
        window: publicStore.currentMenu.active.path?.substr(1) || '',
        state: JSON.stringify(publicStore.currentPage?.state || {}),
        sub: params?.sub || '',
        type: 'action',
        action_name: name,
        function_name: '',
      },
    );
  }

  @action
  async retrieve(params: any = {}, useCache?: boolean) {
    const { publicStore } = this;
    return publicStore.request(async () => ActionRepository.retrieve(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      await publicStore.makeParams(params, useCache),
    ));
  }

  @action
  async new(params: any = {}): Promise<any> {
    const { publicStore } = this;
    return publicStore.request(async () => ActionRepository.new(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      await publicStore.makeParams(params),
    ));
  }

  @action
  async save(params: any, isNew: boolean, visibleMessage: boolean = true): Promise<any> {
    const { publicStore } = this;
    // await this.putState('save', params);

    const data = await publicStore.request(async () => ActionRepository.save(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      await publicStore.makeParamsForSave(params, isNew),
    ));

    if (data) {
      visibleMessage && ConfirmSuccess.showPbMessage(data);
      return data;
    }
    return false;
  }

  @action
  async delete(confirmMessage: string, params: any): Promise<boolean> {
    if (params) {
      if (await ConfirmDelete.show(confirmMessage)) {
        const { publicStore } = this;
        // await this.putState('delete', params);

        if (await publicStore.request(async () => ActionRepository.delete(
          Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
          await publicStore.makeParams(params),
        ))) {
          ConfirmSuccess.show('삭제', '행을 삭제하였습니다.');
          return true;
        }
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
    return false;
  }

  @action
  async print(params: any = {}): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;
    const { user } = publicStore;
    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 인쇄`;
    const key = `PRINT${src}`;
    waitQueueStore.append(title, key, async (response) => {
      const file = await FunctionRepository.tempDownloadRaw(response);
      if (file.size > 0) {
        try {
          printJS({
            printable: URL.createObjectURL(new Blob([file.raw!], {
              type: file.extension === 'pdf' ? 'application/pdf' : 'image/png',
            })),
            type: file.extension === 'pdf' ? 'pdf' : 'image',
            showModal: true,
          });
        } catch {
          ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
        }
      }
    }, () => this.print(params));
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.print(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      await publicStore.makeParams({
        ...params,
        src,
      }),
    ));

    return true;
  }

  @action
  async printWithElmanManager(params: any = {}): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;

    publicStore.withManager(() => {
      const { user } = publicStore;
      const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
      const title = `${publicStore.currentMenu?.active?.text || ''} 인쇄`;
      const key = `PRINT${src}`;
      waitQueueStore.append(title, key, async (response) => {
        this.publicStore.publish({
          command: 'print',
          ...response.tempfile,
          filename: response.tempfile.filename.indexOf('.') === -1 ? `${response.tempfile.src}.${response.fileext}` : response.tempfile.filename,
        });
      }, () => this.printWithElmanManager(params));
      modalStore?.openWaitQueue();

      publicStore.request(async () => ActionRepository.print(
        Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
        await publicStore.makeParams({
          ...params,
          src,
        }),
      ));
    });

    return true;
  }

  @action
  async excel(params: any = {}): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;
    const { user } = publicStore;

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 엑셀 다운로드`;
    const key = `EXCEL${src}`;
    waitQueueStore.append(title, key, async (response) => {
      const file = await FunctionRepository.tempDownloadRaw(response);
      try {
        fileDownload(file.raw, `${file.filename}.${file.extension}`.trim());
      } catch {
        ConfirmFail.show('오류', '엑셀 다운로드에 실패하였습니다.');
      }
    }, () => this.excel(params));
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.excel(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      await publicStore.makeParams({
        ...params,
        src,
      }),
    ));

    return true;
  }

  @action
  async execGeneric(category: string, functionName: string, params: any = {}, isNew?: boolean, disableLoading?: boolean): Promise<any> {
    const { publicStore } = this;

    if (functionName.indexOf('save') > -1 || functionName.indexOf('delete') > -1) {
      FunctionRepository.putState(
        {
          custcd: publicStore.user.custcd,
          spjangcd: publicStore.user.spjangcd,
          perid: publicStore.user.perid,
          token: publicStore.user.token,
          category: Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
          window: publicStore.currentMenu.active.path?.substr(1) || '',
          state: JSON.stringify(publicStore.currentPage?.state || {}),
          sub: params?.sub || '',
          type: 'function',
          action_name: '',
          function_name: functionName,
        },
      );
    }

    return publicStore.request(async () => FunctionRepository.exec(
      params?.sub ? 'general' : category,
      functionName,
      // eslint-disable-next-line no-nested-ternary
      isNew === undefined ? await publicStore.makeParams(params)
        : isNew ? await publicStore.makeParamsForSave(params, true)
          : await publicStore.makeParamsForSave(params, false),
    ), disableLoading);
  }

  @action
  async execBinary(category: string, functionName: string, params: any = {}): Promise<any> {
    const { publicStore } = this;
    return publicStore.request(async () => FunctionRepository.binary(
      params?.sub ? 'general' : category,
      functionName,
      await publicStore.makeParams(params),
    ));
  }

  @action
  // eslint-disable-next-line max-len
  async exec(category: Category, functionName: string, params: any = {}, isNew?: boolean): Promise<any> {
    return this.execGeneric(
      Global.CATEGORY_NAME[category],
      functionName,
      params,
      isNew,
    );
  }

  @action
  async fxExec(functionName: string, params: any = {}, isNew?: boolean, disableLoading?: boolean): Promise<any> {
    const { publicStore } = this;
    return this.execGeneric(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      functionName,
      params,
      isNew,
      disableLoading,
    );
  }

  @action
  async fxBinary(functionName: string, params: any = {}): Promise<any> {
    const { publicStore } = this;
    return this.execBinary(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      functionName,
      params,
    );
  }

  @action
  async fxSave(functionName: string, params: any, isNew: boolean): Promise<boolean> {
    const data = await this.fxExec(
      functionName,
      params,
      isNew,
    );

    if (data) {
      ConfirmSuccess.showPbMessage(data);
      return true;
    }
    return false;
  }

  @action
  async fxDelete(functionName: string, confirmMessage: string, params: any): Promise<boolean> {
    if (params) {
      if (await ConfirmDelete.show(confirmMessage)) {
        if (await this.fxExec(
          functionName,
          params,
        )) {
          ConfirmSuccess.show('삭제', '행을 삭제하였습니다.');
          return true;
        }
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
    return false;
  }

  @action
  async fxPrint(functionName: string, params: any = {}): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;
    const { user } = publicStore;

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 인쇄`;
    const key = `PRINT${src}`;
    waitQueueStore.append(title, key, async (response) => {
      const file = await FunctionRepository.tempDownloadRaw(response);
      if (file.size > 0) {
        try {
          printJS({
            printable: URL.createObjectURL(new Blob([file.raw!], {
              type: file.extension === 'pdf' ? 'application/pdf' : 'image/png',
            })),
            type: file.extension === 'pdf' ? 'pdf' : 'image',
            showModal: true,
          });
        } catch {
          ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
        }
      }
    }, () => this.fxPrint(functionName, params));
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.process(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      functionName,
      await publicStore.makeParams({
        ...params,
        src,
      }),
    ));

    return false;
  }

  @action
  async fxExcel(functionName: string, params: any = {}): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;
    const { user } = publicStore;

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 엑셀 다운로드`;
    const key = `EXCEL${src}`;
    waitQueueStore.append(title, key, async (response) => {
      const file = await FunctionRepository.tempDownloadRaw(response);
      try {
        fileDownload(file.raw, `${file.filename}.${file.extension}`.trim());
      } catch {
        ConfirmFail.show('오류', '엑셀 다운로드에 실패하였습니다.');
      }
    }, () => this.fxExcel(functionName, params));
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.process(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      functionName,
      await publicStore.makeParams({
        ...params,
        src,
      }),
    ));

    return true;
  }

  @action
  async fxEmail(
    emailTo: string,
    emailToName: string,
    emailToCC: string,
    emailToKakaoTelnum: string,
    emailTitle: string,
    emailRemark: string,
    printParams: any = {},
    printFunctionName: string = 'print',
  ): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;
    const { user } = publicStore;

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 메일 전송`;
    const key = `PRINT${src}`;
    waitQueueStore.append(title, key, async (response) => {
      modalStore?.openEmail(
        '',
        '',
        emailTo,
        emailToName,
        emailToCC,
        emailToKakaoTelnum,
        emailTitle,
        emailRemark,
        [{
          ...response.tempfile,
          filename: `${response.tempfile.filename}.${response.fileext.toLowerCase()}`,
          extension: response.fileext.toLowerCase(),
        }],
      );
      modalStore?.closeWaitQueue();
    }, () => this.fxEmail(
      emailTo,
      emailToName,
      emailToCC,
      emailToKakaoTelnum,
      emailTitle,
      emailRemark,
      printParams,
      printFunctionName,
    ));
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.process(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      printFunctionName,
      await publicStore.makeParams({
        ...printParams,
        src,
      }),
    ));

    return false;
  }

  @action
  async fxFax(
    faxToTelnum: string,
    faxRemark: string,
    printParams: any = {},
    printFunctionName: string = 'print',
  ): Promise<boolean> {
    const { publicStore, waitQueueStore, modalStore } = this;
    const { user } = publicStore;

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 팩스 전송`;
    const key = `PRINT${src}`;
    waitQueueStore.append(title, key, async (response) => {
      modalStore?.openFax(
        faxToTelnum,
        faxRemark,
        [{
          ...response.tempfile,
          filename: `${response.tempfile.filename}.${response.fileext.toLowerCase()}`,
          extension: response.fileext.toLowerCase(),
        }],
      );
      modalStore?.closeWaitQueue();
    }, () => this.fxFax(
      faxToTelnum,
      faxRemark,
      printParams,
      printFunctionName,
    ));
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.process(
      Global.CATEGORY_NAME[publicStore.currentMenu.active.category ?? Category.COMMON],
      printFunctionName,
      await publicStore.makeParams({
        ...printParams,
        src,
      }),
    ));

    return false;
  }

  @action
  async fxFile(functionName: string, params: any = {}): Promise<TempFileInfo> {
    const data = await this.fxExec(
      functionName,
      params,
    );

    if (!data || !data.tempfile) {
      return {
        filename: '',
        extension: '',
        size: 0,
        data: '',
      };
    }

    return FunctionRepository.tempDownload(data);
  }

  @action
  async fxFileRaw(functionName: string, params: any = {}): Promise<TempFileInfo> {
    const data = await this.fxExec(
      functionName,
      params,
    );

    if (!data || !data.tempfile) {
      return {
        filename: '',
        extension: '',
        size: 0,
        data: '',
      };
    }

    return FunctionRepository.tempDownloadRaw(data);
  }

  @action
  async dropdown(functionName: string, params: any = {}): Promise<any> {
    const { publicStore } = this;
    return publicStore.request(async () => FunctionRepository.dropdown(
      functionName,
      await publicStore.makeParams(params),
    ));
  }

  @action
  async custBoard(functionName: string, params: any = {}): Promise<any> {
    const { publicStore } = this;
    return publicStore.request(async () => FunctionRepository.custBoard(
      functionName,
      await publicStore.makeParams(params),
    ));
  }

  @action
  async tempUpload(file: Blob, filename: string, progress?: (e: any) => void): Promise<any> {
    const { publicStore } = this;

    const data = new FormData();
    data.append('custcd', publicStore.user.custcd);
    data.append('spjangcd', publicStore.user.spjangcd);
    data.append('perid', publicStore.user.perid);
    data.append('filename', filename);
    data.append('attachment', file);

    return FunctionRepository.tempUpload(data, progress);
  }

  @action
  async sendFax(
    faxTo: string,
    faxRemark: string,
    faxAttachments: Array<TempFileInfo>,
  ): Promise<any> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.sendFax(
      await publicStore.makeParams({
        custcd: publicStore.user.custcd,
        spjangcd: publicStore.user.spjangcd,
        perid: publicStore.user.perid,
        faxnum: faxTo,
        subject: faxRemark,
        items: faxAttachments,
      }),
    ));
  }

  @action
  async sendEmail(
    emailFrom: string,
    emailFromName: string,
    emailFromAutomation: string,
    emailTo: string,
    emailToName: string,
    emailCC: string,
    emailKakaoTelnum: string,
    emailTitle: string,
    emailRemark: string,
    emailAttachments: Array<TempFileInfo>,
  ): Promise<any> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.sendEmail(
      await publicStore.makeParams({
        custcd: publicStore.user.custcd,
        spjangcd: publicStore.user.spjangcd,
        perid: publicStore.user.perid,
        fromemail: emailFrom,
        fromemailtitle: `<${emailFrom}>`,
        fromname: emailFromName,
        fromflag: emailFromAutomation,
        toemail: emailTo,
        toemailtitle: `<${emailTo}>`,
        toname: emailToName,
        cc: emailCC,
        tohp: emailKakaoTelnum,
        subject: emailTitle,
        contents: emailRemark,
        items: emailAttachments,
      }),
    ));
  }

  @action
  async attachment(params: any, actionName: string): Promise<any> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.attachment(
      await publicStore.makeParams(params),
      actionName,
    ));
  }

  @action
  async attachmentDownload(params: any = {}): Promise<TempFileInfo> {
    const { publicStore } = this;

    const data = await publicStore.request(async () => FunctionRepository.attachment(
      await publicStore.makeParams(params),
      'download',
    ));

    if (!data || !data.tempfile) {
      return {
        filename: '',
        extension: '',
        size: 0,
        data: '',
      };
    }

    return FunctionRepository.tempDownload(data);
  }

  @action
  async attachmentBinary(params: any = {}): Promise<any> {
    return this.fxBinary('download', params);
  }

  @action
  async kakao(functionName: string, params: any): Promise<any> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.kakao(
      functionName,
      await publicStore.makeParams({
        custcd: publicStore.user.custcd,
        spjangcd: publicStore.user.spjangcd,
        perid: publicStore.user.perid,
        ...params,
      }),
    ));
  }

  @action
  async smsSend(params: any): Promise<any> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.smsSend(
      await publicStore.makeParams({
        custcd: publicStore.user.custcd,
        spjangcd: publicStore.user.spjangcd,
        perid: publicStore.user.perid,
        ...params,
        items: params?.items?.map((x: string) => ({ tel: x })) || [],
      }),
    ));
  }

  @action
  async post(url: string, params: any = {}, headers: any = {}): Promise<AxiosResponse<any>> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.request(
      true,
      url,
      params,
      headers,
    ));
  }

  @action
  async get(url: string, params: any = {}, headers: any = {}): Promise<AxiosResponse<any>> {
    const { publicStore } = this;

    return publicStore.request(async () => FunctionRepository.request(
      false,
      url,
      params,
      headers,
    ));
  }
}
