import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601w_sulchi_10
 * @category 설치수주/수금현황
 */
export class OrderCollectionLeftModel {
  /**
   * 현장코드
   *
   */
  readonly actcd: string;

  /**
   * 월
   *
   */
  readonly actnm: string;

  /**
   * 수주금액
   *
   */
  readonly compamt: string;

  /**
   * 회사코드
   *
   */
  readonly custcd: string;

  /**
   * 미수금
   *
   */
  readonly notamt: string;

  /**
   * 수금액
   *
   */
  readonly rcvamt: string;

  /**
   * 사업장코드
   *
   */
  readonly spjangcd: string;

  /**
   * 신규 new
   *
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.compamt = data.compamt || '';
    this.custcd = data.custcd || '';
    this.notamt = data.notamt || '';
    this.rcvamt = data.rcvamt || '';
    this.spjangcd = data.spjangcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
