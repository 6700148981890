import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { TabModel } from '../../../../models';
import { PaymentTemplate } from './tabs/Payment.template';
import { PurchasingTemplate } from './tabs/Purchasing.template';
import { ClientMonth } from './ClientMonth';

export const ClientMonthTab: Array<TabLayoutDataItem<ClientMonth>> = [
  {
    tab: new TabModel('DEPOSITPAYMENT', FaCodeBranch, '매입일기준'),
    template: (scope) => <PaymentTemplate scope={scope} />,
  },
  {
    tab: new TabModel('SALESPUIRCHASE', FaCodeBranch, '지급일기준'),
    template: (scope) => <PurchasingTemplate scope={scope} />,
  },
];
