import * as React from 'react';
import {
  FlexLayout, TableLayout, TabView,
} from '../../../../components';
import {
  ElevatorManagementCode,
  ElevatorManagementCodeTabDataStateNames,
  ElevatorManagementCodeTabFocusedStateNames,
  ElevatorManagementCodeTabUpdatesStateNames,
} from './ElevatorManagementCode';
import { Global, TemplateProps } from '../../../../constants';


/**
 * 화면
 * @window w_tb_e001
 * @category 승강기관리코드
 */
export const ElevatorManagementCodeTemplate: React.FC<TemplateProps<ElevatorManagementCode>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[ElevatorManagementCodeTabDataStateNames[scope.tabIndex]]}
          onRowFocusEvent={(item) => setData({
            [ElevatorManagementCodeTabFocusedStateNames[scope.tabIndex]]: item,
          })}
          onChange={(rows, updatedRows) => setData({
            [ElevatorManagementCodeTabDataStateNames[scope.tabIndex]]: rows,
            [ElevatorManagementCodeTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
          })}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
