export interface DateRange {
  stdate: string;
  enddate: string;
}

export default class DateStabilizer {
  static get(stdate: string, enddate: string, target: string): DateRange {
    const result = {
      stdate,
      enddate,
    };

    if (target < stdate) {
      result.stdate = target;
    } else if (target > enddate) {
      result.enddate = target;
    }

    return result;
  }
}
