import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { HumanCageDayModel } from '../models';
import { HumanCage } from '../HumanCage';
import { CheckBox, FormatTextBox, TextBox } from '../../../../../components';
import { Date8 } from '../../../../../utils/time';

// 일별
const Day: GridLayoutHeader<HumanCageDayModel, HumanCage>[] = [
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 140,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.aver_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.equpnm}
      />
    ),
  },
  {
    id: 'elno',
    text: '승강기번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {`${x.elno.substr(0, 4)}-${x.elno.substr(4, 3)}`}
      </FlexLayout>
    ),
  },
  {
    id: 'recedate',
    text: '접수일자',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.trouble_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.recedate}
      />
    ),
  },
  {
    id: 'arrivdate',
    text: '도착일자',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.troubleyul_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.arrivdate}
      />
    ),
  },
  {
    id: 'arrivtime',
    text: '도착시간',
    width: 80,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.arrivtime}
      />
    ),
  },
  {
    id: 'compdate',
    text: '완료일자',
    width: 120,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.compdate}
      />
    ),
  },
  {
    id: 'comptime',
    text: '완료시간',
    width: 100,
    render: (x) => (
      <FormatTextBox
        format="##:##"
        textAlign="center"
        value={x.comptime}
      />
    ),
  },
  {
    id: 'resutime',
    text: '대응시간',
    width: 80,
    render: (x) => (
      <TextBox
        textAlign="right"
        value={x.resutime}
      />
    ),
  },
  {
    id: 'resulttime',
    text: '처리시간',
    width: 80,
    render: (x) => (
      <TextBox
        textAlign="right"
        value={x.resulttime}
      />
    ),
  },
  {
    id: 'trouble',
    text: '사람갇힘',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <CheckBox
          value={x.trouble === '1'}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'troubledate_be',
    text: '이전갇힘일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.troubledate_be)}
      </FlexLayout>
    ),
  },
  {
    id: 'dayterm',
    text: '이전으로 발생(월)',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.dayterm}
      </FlexLayout>
    ),
  },
  {
    id: 'contnm',
    text: '고장내용',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {x.contnm}
      </FlexLayout>
    ),
  },
  {
    id: 'contremark',
    text: '고장내용상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.contremark}
      </FlexLayout>
    ),
  },
  {
    id: 'resunm',
    text: '처리내용',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.resunm}
      </FlexLayout>
    ),
  },
  {
    id: 'resuremark',
    text: '처리내용상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.resuremark}
      </FlexLayout>
    ),
  },
  {
    id: 'remonm',
    text: '고장요인',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remonm}
      </FlexLayout>
    ),
  },
  {
    id: 'remoremark',
    text: '고장요인상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remoremark}
      </FlexLayout>
    ),
  },
  {
    id: 'facnm',
    text: '고장원인',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.facnm}
      </FlexLayout>
    ),
  },
  {
    id: 'greginm',
    text: '고장부위',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.greginm}
      </FlexLayout>
    ),
  },
  {
    id: 'reginm',
    text: '고장부위상세',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.reginm}
      </FlexLayout>
    ),
  },
  {
    id: 'resultnm',
    text: '처리방법',
    width: 100,
  },
  {
    id: 'actdivinm',
    text: '담당부서명',
    width: 90,
  },
  {
    id: 'actpernm',
    text: '담당자',
    width: 70,
  },
  {
    id: 'pernm',
    text: '처리자',
    width: 70,
  },
  {
    id: 'pernm2',
    text: '처리자2',
    width: 70,
  },
  {
    id: 'repernm',
    text: '접수자',
    width: 70,
  },
];

export default Day;
