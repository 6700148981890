/**
 * 모델
 * @window w_tb_e601w_sulchi_04
 * @category 설치공정현황
 */
export default class StatusModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ause: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly choice: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly class: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly class2: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 계약여부
   *
   * **data type** varchar(8)
   */
  readonly contyn: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly dong: string;

  /**
   * 호기번호
   *
   * **data type** varchar(8)
   */
  readonly elno: string;

  /**
   * 승강기롼리번호1
   *
   * **data type** varchar(8)
   */
  readonly elvtrmgtno1: string;

  /**
   * 승강기관리번호2
   *
   * **data type** varchar(8)
   */
  readonly elvtrmgtno2: string;

  /**
   * 납기일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly eqperid: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 점검기종
   *
   * **data type** varchar(8)
   */
  readonly evcd: string;

  /**
   * 점검주기
   *
   * **data type** varchar(8)
   */
  readonly evju: string;

  /**
   * 점검월
   *
   * **data type** varchar(8)
   */
  readonly evmon: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly form: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly gunsang: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly height: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ho: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly incentive: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자코드
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly inver: string;

  /**
   * 제작사
   *
   * **data type** varchar(8)
   */
  readonly jejak: string;

  /**
   * 제어반
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 검사유무
   *
   * **data type** varchar(8)
   */
  readonly kcchk: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 검사자 부서명
   *
   * **data type** varchar(8)
   */
  readonly kcdivinm: string;

  /**
   * 검사유효일
   *
   * **data type** varchar(8)
   */
  readonly kcenddate: string;

  /**
   * 검사월
   *
   * **data type** varchar(8)
   */
  readonly kcmon: string;

  /**
   * 검사원
   *
   * **data type** varchar(8)
   */
  readonly kcspnm: string;

  /**
   * 검사시작일
   *
   * **data type** varchar(8)
   */
  readonly kcstdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly kind: string;

  /**
   * 모델
   *
   * **data type** varchar(8)
   */
  readonly model: string;

  /**
   * 모터
   *
   * **data type** varchar(8)
   */
  readonly moter: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly nperson: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly number: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 계약월
   *
   * **data type** varchar(8)
   */
  readonly ordmon: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process001: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process002: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process003: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process004: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process005: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process006: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process007: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process008: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process009: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process010: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process011: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process012: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process013: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process014: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process015: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process016: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process017: string;

  /**
   * 작업
   *
   * **data type** varchar(8)
   */
  readonly process_per: string;

  /**
   * 레일
   *
   * **data type** varchar(8)
   */
  readonly rail: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly rof: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   * 사양
   *
   * **data type** varchar(8)
   */
  readonly sizecd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly spped: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 작업율
   *
   * **data type** varchar(8)
   */
  readonly tot_process: string;

  /**
   * 귀속연월
   *
   * **data type** varchar(8)
   */
  readonly yyyymm: string;


  constructor(data: any = {}) {
    this.actcd = data?.actcd || '';
    this.ause = data?.ause || '';
    this.choice = data?.choice || '';
    this.class = data?.class || '';
    this.class2 = data?.class2 || '';
    this.cltcd = data?.cltcd || '';
    this.contyn = data?.contyn || '';
    this.custcd = data?.custcd || '';
    this.dong = data?.dong || '';
    this.elno = data?.elno || '';
    this.elvtrmgtno1 = data?.elvtrmgtno1 || '';
    this.elvtrmgtno2 = data?.elvtrmgtno2 || '';
    this.enddate = data?.enddate || '';
    this.eqperid = data?.eqperid || '';
    this.equpcd = data?.equpcd || '';
    this.equpnm = data?.equpnm || '';
    this.evcd = data?.evcd || '';
    this.evju = data?.evju || '';
    this.evmon = data?.evmon || '';
    this.form = data?.form || '';
    this.gubun = data?.gubun || '';
    this.gunsang = data?.gunsang || '';
    this.height = data?.height || '';
    this.ho = data?.ho || '';
    this.incentive = data?.incentive || '';
    this.indate = data?.indate || '';
    this.inperid = data?.inperid || '';
    this.inver = data?.inver || '';
    this.jejak = data?.jejak || '';
    this.jeoban = data?.jeoban || '';
    this.kcchk = data?.kcchk || '';
    this.kcdate = data?.kcdate || '';
    this.kcdivinm = data?.kcdivinm || '';
    this.kcenddate = data?.kcenddate || '';
    this.kcmon = data?.kcmon || '';
    this.kcspnm = data?.kcspnm || '';
    this.kcstdate = data?.kcstdate || '';
    this.kind = data?.kind || '';
    this.model = data?.model || '';
    this.moter = data?.moter || '';
    this.nperson = data?.nperson || '';
    this.number = data?.number || '';
    this.orddate = data?.orddate || '';
    this.ordmon = data?.ordmon || '';
    this.process001 = data?.process001 || '';
    this.process002 = data?.process002 || '';
    this.process003 = data?.process003 || '';
    this.process004 = data?.process004 || '';
    this.process005 = data?.process005 || '';
    this.process006 = data?.process006 || '';
    this.process007 = data?.process007 || '';
    this.process008 = data?.process008 || '';
    this.process009 = data?.process009 || '';
    this.process010 = data?.process010 || '';
    this.process011 = data?.process011 || '';
    this.process012 = data?.process012 || '';
    this.process013 = data?.process013 || '';
    this.process014 = data?.process014 || '';
    this.process015 = data?.process015 || '';
    this.process016 = data?.process016 || '';
    this.process017 = data?.process017 || '';
    this.process_per = data?.process_per || '';
    this.rail = data?.rail || '';
    this.remark = data?.remark || '';
    this.rof = data?.rof || '';
    this.scactnm = data?.scactnm || '';
    this.sizecd = data?.sizecd || '';
    this.spjangcd = data?.spjangcd || '';
    this.spped = data?.spped || '';
    this.state = data?.state || '';
    this.tot_process = data?.tot_process || '';
    this.yyyymm = data?.yyyymm || '';
  }
}
