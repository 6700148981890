import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  BreakNumberAllModel,
  BreakNumberFieldModel,
  BreakNumberUnitModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { BreakNumberTemplate } from './BreakNumber.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderAll,
  TabHeaderField,
  TabHeaderUnit,
} from './tabs';
import { Date6, Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum BreakNumberTabId {
  All,
  Field,
  Unit
}

export const BreakNumberTabTitles = [
  '전체',
  '현장별',
  '호기별',
];

export const BreakNumberTabModels = [
  BreakNumberAllModel,
  BreakNumberFieldModel,
  BreakNumberUnitModel,
];

export const BreakNumberTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
];

export const BreakNumberTabDataStateNames = [
  'allList',
  'fieldList',
  'unitList',
];

export interface BreakNumberState {
  allList?: Array<BreakNumberAllModel>; // 전체 리스트
  fieldList?: Array<BreakNumberFieldModel>; // 현장별 리스트
  unitList?: Array<BreakNumberUnitModel>; // 호기별 리스트

  focusedTab?: TabModel;
  focuseddata?: any;

  // 검색조건
  searchQuery: string; // 검색어
  styear: string; // 기간(시작)
  endyear: string; // 기간(끝)
  pernm: string; // 담당자명
  actcd: string; // 현장
  actnm: string; // 현장명
  equpcd: string; // 호기코드
  equpnm: string; // 호기명
  nflag: string;

  total: string;
  mon01_tot: '',
  mon02_tot: '',
  mon03_tot: '',
  mon04_tot: '',
  mon05_tot: '',
  mon06_tot: '',
  mon07_tot: '',
  mon08_tot: '',
  mon09_tot: '',
  mon10_tot: '',
  mon11_tot: '',
  mon12_tot: '',

  url: string;
}
/**
 * 컨트롤러
 * @window w_tb_e401w_01
 * @category 고장건수통계
 */
export class BreakNumber extends PageComponent<PageProps, BreakNumberState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = BreakNumberTabId;
    const titles = BreakNumberTabTitles;

    this.tabs = [
      new TabModel(id.All.toString(), MdReorder, titles[id.All]),
      new TabModel(id.Field.toString(), MdReceipt, titles[id.Field]),
      new TabModel(id.Unit.toString(), MdReceipt, titles[id.Unit]),
    ];

    this.tabHeaders = [
      TabHeaderAll,
      TabHeaderField,
      TabHeaderUnit,
    ];

    const today = new Date();
    const twoYearAgo: Date = new Date(today.setFullYear(today.getFullYear() - 2));

    // state 기본값 정의
    this.state = props.state || {
      styear: `${Date6.make(twoYearAgo).substr(0, 4)}`,
      endyear: Today.year(),
      allList: [],
      fieldList: [],
      unitList: [],
      actcd: '%', // 현장
      actnm: '', // 현장명
      equpcd: '%', // 호기
      equpnm: '', // 호기명
      nflag: '%', // 주야간

      mon01_tot: '',
      mon02_tot: '',
      mon03_tot: '',
      mon04_tot: '',
      mon05_tot: '',
      mon06_tot: '',
      mon07_tot: '',
      mon08_tot: '',
      mon09_tot: '',
      mon10_tot: '',
      mon11_tot: '',
      mon12_tot: '',
      url: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[BreakNumberTabId.All]);
    // await this.doRetrieve(BreakNumberTabId.Day);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakNumberTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${BreakNumberTabFunctionNames[i]}_print`,
      {
        styear: this.state.styear,
        endyear: this.state.endyear,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        nflag: this.state.nflag,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakNumberTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${BreakNumberTabFunctionNames[i]}_excel`,
      {
        styear: this.state.styear,
        endyear: this.state.endyear,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        nflag: this.state.nflag,
      },
    );
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/stick6.php?window=w_tb_e401_01&type=1&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_styear=${this.state.styear}&as_endyear=${this.state.endyear}&as_actcd=${this.state.actcd}&as_equpcd=${this.state.equpcd}&as_nflag=${this.state.nflag}`,
    });
  }


  @computed
  get tabIndex(): BreakNumberTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: BreakNumberTabId, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        styear: this.state.styear,
        endyear: this.state.endyear,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        nflag: this.state.nflag,
      },
      (params) => api.fxExec(
        `${BreakNumberTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BreakNumberTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[BreakNumberTabDataStateNames[i]],
              ...items.map((x: any) => new BreakNumberTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [BreakNumberTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [BreakNumberTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['actnm', 'tot'],
        [this.state.focuseddata?.actnm, this.state.focuseddata?.tot], type, true) || 0;
      // @ts-ignore
      this.state[BreakNumberTabDataStateNames[i]]
      // @ts-ignore
      && this.state[BreakNumberTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        mon01_tot: data?.mon01_tot || '0',
        mon02_tot: data?.mon02_tot || '0',
        mon03_tot: data?.mon03_tot || '0',
        mon04_tot: data?.mon04_tot || '0',
        mon05_tot: data?.mon05_tot || '0',
        mon06_tot: data?.mon06_tot || '0',
        mon07_tot: data?.mon07_tot || '0',
        mon08_tot: data?.mon08_tot || '0',
        mon09_tot: data?.mon09_tot || '0',
        mon10_tot: data?.mon10_tot || '0',
        mon11_tot: data?.mon11_tot || '0',
        mon12_tot: data?.mon12_tot || '0',
      });
      this.grid.current?.setFocus(0);
      await this.onRowFocusEvent(this.state.focuseddata);
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onRowFocusEvent(item: any) {
    this.setState({
      focuseddata: item,
    }, async () => {
      await this.urlRetrieve();
    });
  }

  render() {
    return (
      <BreakNumberTemplate
        scope={this}
      />
    );
  }
}
