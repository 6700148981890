/**
 * 모델
 * @window w_tb_e411w_08
 * @category 월별다발고장현황
 */
export class MonthBreakOftenModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 담당부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 담당부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 처리자
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   * 접수자
   *
   * **data type** varchar(8)
   */
  readonly repernm: string;

  /**
   * 최대횟수
   *
   * **data type** varchar(8)
   */
  readonly wadmax: string;

  /**
   * 월발생
   *
   * **data type** varchar(8)
   */
  readonly combo: string;

  /**
   * 1월
   *
   * **data type** varchar(8)
   */
  readonly mon01: string;

  /**
   * 2월
   *
   * **data type** varchar(8)
   */
  readonly mon02: string;

  /**
   * 3월
   *
   * **data type** varchar(8)
   */
  readonly mon03: string;

  /**
   * 4월
   *
   * **data type** varchar(8)
   */
  readonly mon04: string;

  /**
   * 5월
   *
   * **data type** varchar(8)
   */
  readonly mon05: string;

  /**
   * 6월
   *
   * **data type** varchar(8)
   */
  readonly mon06: string;

  /**
   * 7월
   *
   * **data type** varchar(8)
   */
  readonly mon07: string;

  /**
   * 8월
   *
   * **data type** varchar(8)
   */
  readonly mon08: string;

  /**
   * 9월
   *
   * **data type** varchar(8)
   */
  readonly mon09: string;

  /**
   * 10월
   *
   * **data type** varchar(8)
   */
  readonly mon10: string;

  /**
   * 11월
   *
   * **data type** varchar(8)
   */
  readonly mon11: string;

  /**
   * 12월
   *
   * **data type** varchar(8)
   */
  readonly mon12: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.comppernm = data.comppernm || '';
    this.repernm = data.repernm || '';
    this.wadmax = data.wadmax || '';
    this.combo = data.combo || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
  }
}
