import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e037_type2
 * @category 개별업무일지
 */

export class JournalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  rptdate: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly kukcd: string;

  /**
   * 비고(현장요구)
   *
   * **data type** varchar(255)
   */
  readonly rptremark: string;

  /**
   * 익일계획
   *
   * **data type** varchar(255)
   */
  readonly overwk: string;

  /**
   * 업무비고
   *
   * **data type** varchar(100)
   */
  readonly upmuremark: string;

  /**
   * 공급가액
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 시작시간
   *
   * **data type** varchar(4)
   */
  readonly sttime: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 종료시간
   *
   * **data type** varchar(4)
   */
  readonly endtime: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  readonly appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  readonly appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 결재자1
   *
   * **data type** varchar(8)
   */
  readonly appdate1: string;

  /**
   * 결재자2
   *
   * **data type** varchar(8)
   */
  readonly appdate2: string;

  /**
   * 결재자3
   *
   * **data type** varchar(8)
   */
  readonly appdate3: string;

  /**
   * 결재자4
   *
   * **data type** varchar(8)
   */
  readonly appdate4: string;

  /**
   * 결재자5
   *
   * **data type** varchar(8)
   */
  readonly appdate5: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 출발현장코드
   *
   * **data type** varchar(13)
   */
  readonly st_actcd: string;

  /**
   * 출발지
   *
   * **data type** varchar(255)
   */
  readonly st_actnm: string;

  /**
   * 출발지위도
   *
   * **data type** varchar(30)
   */
  readonly st_lat: string;

  /**
   * 출발지경도
   *
   * **data type** varchar(30)
   */
  readonly st_lng: string;

  /**
   * 도착현장코드
   *
   * **data type** varchar(13)
   */
  readonly end_actcd: string;

  /**
   * 도착지
   *
   * **data type** varchar(255)
   */
  readonly end_actnm: string;

  /**
   * 도착지위도
   *
   * **data type** varchar(30)
   */
  readonly end_lat: string;

  /**
   * 도착지경도
   *
   * **data type** varchar(30)
   */
  readonly end_lng: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly rspcd: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;


  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly endperid: string;


  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly endpernm: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly endflag: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly endtext: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly chk: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly carcd1: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly carnum1: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly soyou1: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly model1: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly gubun1: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly carcd2: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly carnum2: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly soyou2: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly model2: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly gubun2: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly sjik: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly djik: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  readonly syn: string;

  /**
   * 작성일자
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly rptnum: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 코드
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 경도
   *
   * **data type** varchar(30)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(30)
   */
  readonly lng: string;

  /**
   * 코드
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   * 동호기
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   * 차량코드
   *
   * **data type** varchar(3)
   */
  readonly carcd: string;

  /**
   * 구분
   *
   * **data type** varchar(100)
   */
  readonly wkcd: string;

  /**
   * 도착시간
   *
   * **data type** varchar(5)
   */
  readonly frtime: string;

  /**
   * 완료시간
   *
   * **data type** varchar(0)
   */
  readonly totime: string;

  /**
   * 예상거리
   *
   * **data type** varchar(0)
   */
  readonly prekm: string;

  /**
   * 이동거리
   *
   * **data type** varchar(0)
   */
  readonly km: string;

  /**
   * 점검일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 교육일자
   *
   * **data type** varchar(8)
   */
  readonly edudate: string;

  /**
   * 교육번호
   *
   * **data type** varchar(4)
   */
  readonly edunum: string;

  /**
   * 연결일자
   *
   * **data type** varchar(8)
   */
  readonly lkdate: string;

  /**
   * 연결번호
   *
   * **data type** varchar(4)
   */
  readonly lknum: string;

  /**
   * 연결번호
   *
   * **data type** varchar(4)
   */
  readonly attcnt: number;

  /**
   * 연결번호
   *
   * **data type** varchar(4)
   */
  readonly sndflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rptdate = data.rptdate || '';
    this.perid = data.perid || '';
    this.kukcd = data.kukcd || '';
    this.rptremark = Fix.newline(data.rptremark) || '';
    this.overwk = data.overwk || '';
    this.upmuremark = Fix.newline(data.upmuremark) || '';
    this.samt = data.samt || '';
    this.gubun = data.gubun || '';
    this.stdate = data.stdate || '';
    this.sttime = data.sttime || '';
    this.enddate = data.enddate || '';
    this.endtime = data.endtime || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.appdate1 = data.appdate1 || '';
    this.appdate2 = data.appdate2 || '';
    this.appdate3 = data.appdate3 || '';
    this.appdate4 = data.appdate4 || '';
    this.appdate5 = data.appdate5 || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.st_actcd = data.st_actcd || '';
    this.st_actnm = data.st_actnm || '';
    this.st_lat = data.st_lat || '';
    this.st_lng = data.st_lng || '';
    this.end_actcd = data.end_actcd || '';
    this.end_actnm = data.end_actnm || '';
    this.end_lat = data.end_lat || '';
    this.end_lng = data.end_lng || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.apptime = data.apptime || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.datetime = data.datetime || '';
    this.rptnum = data.rptnum || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.carcd = data.carcd || '';
    this.wkcd = data.wkcd || '';
    this.frtime = data.frtime || '';
    this.totime = data.totime || '';
    this.prekm = data.prekm || '';
    this.km = data.km || '';
    this.plandate = data.plandate || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.lkdate = data.lkdate || '';
    this.lknum = data.lknum || '';
    this.attcnt = data.attcnt || 0;
    this.rspcd = data.rspcd || '';
    this.divicd = data.divicd || '';
    this.soyou2 = data.soyou2 || '';
    this.model2 = data.model2 || '';
    this.gubun2 = data.gubun2 || '';
    this.carcd2 = data.carcd2 || '';
    this.carnum2 = data.carnum2 || '';
    this.sjik = data.sjik || '';
    this.djik = data.djik || '';
    this.syn = data.syn || '';
    this.carcd1 = data.carcd1 || '';
    this.carnum1 = data.carnum1 || '';
    this.soyou1 = data.soyou1 || '';
    this.model1 = data.model1 || '';
    this.gubun1 = data.gubun1 || '';
    this.chk = data.chk || '';
    this.lknum = data.lknum || '';
    this.endperid = data.endperid || '';
    this.endpernm = data.endpernm || '';
    this.endtext = data.endtext || '';
    this.endflag = data.endflag || '';
    this.sndflag = data.sndflag || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
