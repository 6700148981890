import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_pb407
 * @category 회의록
 */

export class MeetingModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly paperdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  readonly papernum: string;

  /**
   * 완료일자
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 장소
   *
   * **data type** varchar(255)
   */
  readonly place: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(255)
   */
  readonly projnm: string;

  /**
   * 주제
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 참석자
   *
   * **data type** varchar(255)
   */
  readonly attendee: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 내용
   *
   * **data type** varchar(0)
   */
  readonly remark: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(3)
   */
  readonly appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(0)
   */
  readonly appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  readonly apptime: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly rspnm: string;

  /**
   * 부서
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 담당1
   *
   * **data type** varchar(20)
   */
  readonly rspnm1: string;

  /**
   * 담당2
   *
   * **data type** varchar(20)
   */
  readonly rspnm2: string;

  /**
   * 담당3
   *
   * **data type** varchar(20)
   */
  readonly rspnm3: string;

  /**
   * 담당4
   *
   * **data type** varchar(20)
   */
  readonly rspnm4: string;

  /**
   * 담당1
   *
   * **data type** varchar(20)
   */
  readonly apppernm1: string;

  /**
   * 담당2
   *
   * **data type** varchar(20)
   */
  readonly apppernm2: string;

  /**
   * 담당3
   *
   * **data type** varchar(20)
   */
  readonly apppernm3: string;

  /**
   * 담당4
   *
   * **data type** varchar(20)
   */
  readonly apppernm4: string;

  /**
   * 담당1
   *
   * **data type** varchar(20)
   */
  readonly appgubun1: string;

  /**
   * 담당2
   *
   * **data type** varchar(20)
   */
  readonly appgubun2: string;

  /**
   * 담당3
   *
   * **data type** varchar(20)
   */
  readonly appgubun3: string;

  /**
   * 담당4
   *
   * **data type** varchar(20)
   */
  readonly appgubun4: string;

  /**
   * 담당1
   *
   * **data type** varchar(20)
   */
  readonly apptime1: string;

  /**
   * 담당2
   *
   * **data type** varchar(20)
   */
  readonly apptime2: string;

  /**
   * 담당3
   *
   * **data type** varchar(20)
   */
  readonly apptime3: string;

  /**
   * 담당4
   *
   * **data type** varchar(20)
   */
  readonly apptime4: string;

  /**
   * 담당1
   *
   * **data type** varchar(20)
   */
  readonly appdate1: string;

  /**
   * 담당2
   *
   * **data type** varchar(20)
   */
  readonly appdate2: string;

  /**
   * 담당3
   *
   * **data type** varchar(20)
   */
  readonly appdate3: string;

  /**
   * 담당4
   *
   * **data type** varchar(20)
   */
  readonly appdate4: string;

  /**
   * 첨부
   *
   * **data type** varchar(20)
   */
  readonly attcnt: number;

  /**
   * 첨부
   *
   * **data type** varchar(20)
   */
  readonly preserve: string;

  /**
   * 첨부
   *
   * **data type** varchar(20)
   */
  readonly help: string;

  /**
   * 첨부
   *
   * **data type** varchar(20)
   */
  readonly upgrade: string;

  /**
   * 첨부
   *
   * **data type** varchar(20)
   */
  readonly page: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.paperdate = data.paperdate || '';
    this.papernum = data.papernum || '';
    this.compdate = data.compdate || '';
    this.place = data.place || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.title = data.title || '';
    this.attendee = data.attendee || '';
    this.perid = data.perid || '';
    this.remark = Fix.newline(data.remark) || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.appnum = data.appnum || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.apptime = data.apptime || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.divinm = data.divinm || '';
    this.rspnm1 = data.rspnm1 || '';
    this.rspnm2 = data.rspnm2 || '';
    this.rspnm3 = data.rspnm3 || '';
    this.rspnm4 = data.rspnm4 || '';
    this.apppernm1 = data.apppernm1 || '';
    this.apppernm2 = data.apppernm2 || '';
    this.apppernm3 = data.apppernm3 || '';
    this.apppernm4 = data.apppernm4 || '';
    this.appgubun1 = data.appgubun1 || '';
    this.appgubun2 = data.appgubun2 || '';
    this.appgubun3 = data.appgubun3 || '';
    this.appgubun4 = data.appgubun4 || '';
    this.apptime1 = data.apptime1 || '';
    this.apptime2 = data.apptime2 || '';
    this.apptime3 = data.apptime3 || '';
    this.apptime4 = data.apptime4 || '';
    this.appdate1 = data.appdate1 || '';
    this.appdate2 = data.appdate2 || '';
    this.appdate3 = data.appdate3 || '';
    this.appdate4 = data.appdate4 || '';
    this.attcnt = data.attcnt || 0;
    this.preserve = data.preserve || '';
    this.help = data.help || '';
    this.upgrade = data.upgrade || '0';
    this.page = data.page || '0';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
