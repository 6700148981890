import * as React from 'react';
import style from './EditTitleLabelBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import { LayoutProps } from '../../../constants';
import { FlexLayout, DefaultLayout } from '../../layout';

interface EditTitleLabelBoxActions {
  onClick?: () => any;
  onDblClick?: () => any;
  onChange?: (v: string) => any;
}

interface EditTitleLabelBoxProps extends EditTitleLabelBoxActions, LayoutProps {
  value?: string;
}

export class EditTitleLabelBox extends React.Component<EditTitleLabelBoxProps> {
  render() {
    return (
      <FlexLayout
        className={JoinClassName.make([style.container, this.props.className])}
        style={this.props.style}
        weight={this.props.weight}
        size={this.props.size}
        isVertical={true}
      >
        <DefaultLayout
          className={style.title}
          size={22}
          style={{
            marginBottom: -2,
          }}
        >
          <span style={this.props.style}>
            <input
              type="text"
              value={this.props.value || ''}
              onChange={(e) => this.props.onChange && this.props.onChange(e.target.value)}
            />
          </span>
        </DefaultLayout>
        <FlexLayout
          className={style.textField}
          style={{
            paddingTop: 4,
          }}
        >
          {this.props.children}
        </FlexLayout>
      </FlexLayout>
    );
  }
}
