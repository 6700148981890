import * as React from 'react';
import mStyle from './RowCover.module.scss';

export interface RowColorProps {
  color?: string;
}

export const RowCover: React.FC<RowColorProps> = ({
  children,
  color,
}) => (
  <div
    className={mStyle.container}
    style={{ backgroundColor: color }}
  >
    {children}
  </div>
);
