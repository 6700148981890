import Swal from 'sweetalert2';
import { Fix } from '../string';

export default class Success {
  static show(title: string, text: string): Promise<void> {
    return new Promise((resolve) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons.fire({
        title,
        html: Fix.newline(text).split('\n').join('<BR/>'),
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: '확인',
        reverseButtons: true,
      }).then(() => {
        resolve();
      });
    });
  }

  static showPbMessage(data: any) {
    this.show(data.titlebox, data.messagebox);
  }
}
