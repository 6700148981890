

export class ContractModalModel {
  /**
   * 담당자명
   *
   *
   */
  readonly pernm: string

  /**
   * 현장명
   *
   *
   */
  readonly actnm: string

  /**
   * 계역구분
   *
   *
   */
  readonly contgubun: string

  /**
   * 만료일
   *
   *
   */
  readonly enddate: string

  /**
   * 계약분류
   *
   *
   */
  readonly contg: string

  /**
   * 상태
   *
   *
   */
  readonly state: string

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.contgubun = data.contgubun || '';
    this.enddate = data.enddate || '';
    this.contg = data.contg || '';
    this.state = data.state || '';
  }
}
