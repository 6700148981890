import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { ActModel } from '../models';
import { RepairCurrent } from '../RepairCurrent';
import { FormatTextBox, TelnumTextBox, TextBox } from '../../../../../components';

// 거래처별
const Act: GridLayoutHeader<ActModel, RepairCurrent>[] = [
  {
    id: 'spjangnm',
    text: '사업장',
    width: 150,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.spjangnm}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.actcd}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '세금계산서발행처',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactnm',
    text: '회사구분',
    width: 60,
  },
  {
    id: 'qty',
    text: '관리대수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 150,
    render: (x) => (
        <FlexLayout
          justify="left"
          align="center"
        >
          {x.bildyd}
        </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 70,
  },
  {
    id: 'perstdate',
    text: '담당자변경일',
    width: 100,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.perstdate}
      />
    ),
  },
  {
    id: 'contpernm',
    text: '계약자명',
    width: 70,
  },
  {
    id: 'actmail',
    text: '담당자메일',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actmail}
      </FlexLayout>
    ),
  },
  {
    id: 'tel',
    text: '현장전화번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.tel}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'hp',
    text: '현장핸드폰번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.hp}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'zipcode',
    text: '우편번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.zipcode}
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'address2',
    text: '상세주소',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address2}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnum',
    text: '프로젝트번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.cltnum}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 400,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];

export default Act;
