import * as React from 'react';
import { inject } from 'mobx-react';
// @ts-ignore
import { ExternalPageProps } from '../../../../constants';
import { ConfirmWarning } from '../../../../utils/confirm';

/**
 * 컨트롤러
 * @window w_tb_email_send_01
 * @category 메일함
 */
@inject('toolStore')
export class MailBox extends React.Component<ExternalPageProps> {
  componentDidMount(): void {
    const { user } = this.props.publicStore;
    let url = '';
    if (user.emailuseyn === '0') {
      ConfirmWarning.show('오류', '사업자정보등록에서 이메일 사용설정을 해주세요.');
      return;
    }
    if (user.emailuseyn === '1') {
      url = 'https://logins.daum.net/accounts/signinform.do?url=https%3A%2F%2Fwww.daum.net%2F';
    }
    if (user.emailuseyn === '2') {
      url = 'https://accounts.kakao.com/login?continue=https%3A%2F%2Fmail.kakao.com%2F';
    }
    if (user.emailuseyn === '3') {
      url = 'https://nid.naver.com/nidlogin.login?url=http%3A%2F%2Fmail.naver.com%2F';
    }
    if (user.emailuseyn === '4') {
      url = 'https://accounts.google.com/AccountChooser/signinchooser?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&flowName=GlifWebSignIn&flowEntry=AccountChooser';
    }
    if (user.emailuseyn === '5') {
      url = 'http://home.mail.nate.com/login/login.html';
    }
    window.open(url);
    this.props.toolStore.onCloseHandler();
  }

  render() {
    return (
      <React.Fragment />
    );
  }
}
