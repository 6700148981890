import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { AiOutlineCloudUpload, IoIosMail } from 'react-icons/all';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  DateTextBox,
  LabelBox,
  FormatTextBox,
  Button,
  ModalView,
  LayoutTitle,
  ComboBox,
  ComboBoxModel,
  Badge,
  OptionBox,
  CheckBox,
  CircleBadge,
  TableLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { CageReport, CageReportItemChangeTypeNames, CageReportItemChangeTypes } from './CageReport';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Appgubuns } from '../../../install/sales/Report/headers/ReportGridHeader';
import { SearchDateRange } from '../../../../components/search';

/**
 * 화면
 * @window w_tb_e411_cri
 * @category 중대고장처리보고서
 */

const Results = [
  { value: '0', remark: '미완료' },
  { value: '1', remark: '완료' },
  { value: '2', remark: '접수' },
];

export const BreakReportTemplate: React.FC<TemplateProps<CageReport>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.focusedDetailBreakReport;
  const { user } = scope.props.publicStore;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>

        <FlexLayout size={280}>
          <span style={{ color: 'var(--color-red)' }}>담당</span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm === '%' ? '' : scope.state.pernm}
            textAlign="center"
            onChange={(value) => scope.setState({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid === '%' ? '' : scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span style={{ color: 'var(--color-black)' }}>등록(전송)</span>
          <ComboBox
            value={scope.state.sendflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '사고' },
              { value: '0', remark: '고장' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ sendflag: option.value })}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span style={{ color: 'var(--color-black)' }}>중대한구분</span>
          <ComboBox
            value={scope.state.crigubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '사고' },
              { value: '0', remark: '고장' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ crigubun: option.value })}
          />
        </FlexLayout>

        <FlexLayout />

        <Button
          onClick={() => scope.PopupModal(true)}
        >
          <FlexLayout>
            {(scope.state.equpcnt_tot) && <CircleBadge
              style={{
                left: 'initial',
                right: -11,
              }}
            >
                  <span
                    style={{
                      backgroundColor: 'var(--color-red)',
                      borderRadius: 24,
                      color: '#FFF',
                      fontWeight: 300,
                      padding: '0 3px',
                      fontSize: 11,
                      transform: 'translate(4px, 0px)',
                    }}
                  >
                    {scope.state.equpcnt_tot}
                  </span>
            </CircleBadge>}
            <div style={{ width: 100 }}>사람갇힘불러오기</div>
          </FlexLayout>
        </Button>
        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 104 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout weight={0.9}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'cridate',
                text: '중대보고일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={x.cridate}
                  />
                ),
              },
              {
                id: 'crinum',
                text: '번호',
                width: 50,
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 150,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.actnm}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.equpnm}
                  />
                ),
              },
              {
                id: 'appgubun',
                text: '결재상태',
                width: 70,
                render: (x) => {
                  const fp = Appgubuns.filter((y: any) => y.value === x.appgubun);
                  const nm = fp.length > 0 ? fp[0].remark : '';
                  const color = fp.length > 0 ? fp[0].color : '#000000';

                  return (
                    <FlexLayout
                      align="center"
                      justify="center"
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        color,
                      }}
                    >
                      {nm}
                      {x?.appremark && <Badge>
                        <IoIosMail color="var(--color-red)" size={25} />
                      </Badge>}
                    </FlexLayout>
                  );
                },
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
            data={scope.state.BreakReportList || []}
          />
        </FlexLayout>

        <FlexLayout
          weight={2}
          isVertical={true}
          isScroll={true}
        >
          <FlexLayout>
            <FlexLayout
              justify="center"
              align="center"
              weight={0.7}
            >
              <LabelBox>
                <span
                  style={{ fontWeight: 1000, color: '#008080' }}
                >일&nbsp;반<br/>현&nbsp;황
                </span>
              </LabelBox>
            </FlexLayout>

            <FlexLayout
              isVertical={true}
              weight={10}
            >
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
                <LabelBox
                  title="중대고장작성일자"
                  isNeed={true}
                  weight={3}
                >
                  <DateTextBox
                    value={model?.cridate}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => setData({ cridate: value })}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.crinum}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="문서번호"
                  weight={1.8}
                >
                  <FormatTextBox
                    format="##-######-###-####"
                    textAlign="center"
                    value={user.papernum + model?.appnum}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox title="중대한구분"
                  weight={1.2}
                  isNeed={true}
                >
                  <ComboBox
                    value={model?.crigubun}
                    data={[
                      { value: '1', remark: '사고' },
                      { value: '0', remark: '고장' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setData({ crigubun: option.value })}
                  />
                </LabelBox>
                <LabelBox
                  title="현장명"
                  style={{ color: 'var(--color-red)' }}
                  weight={4}
                  isNeed={true}
                >
                  <TextBox
                    style={{ marginLeft: 2 }}
                    weight={1}
                    value={model?.actcd}
                    textAlign="center"
                    onChange={(value) => setData({ actcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        setData({
                          actcd: item.cd,
                          actnm: item.cdnm,
                        }, () => scope.itemChanged(CageReportItemChangeTypeNames.actcd, CageReportItemChangeTypes.ACTCD));
                      },
                    )}
                  />
                  <TextBox
                    weight={4}
                    value={model?.actnm}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="호기명"
                  style={{ color: 'var(--color-red)' }}
                  weight={2}
                  isNeed={true}
                >
                  <TextBox
                    value={model?.equpcd}
                    textAlign="center"
                    onChange={(value) => setData({ equpcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E611',
                      { uv_arg1: scope.state.focusedDetailBreakReport?.equpcd },
                      true,
                      (item) => {
                        setData({
                          actcd: item.cd,
                          actnm: item.cdnm,
                        }, () => scope.itemChanged(CageReportItemChangeTypeNames.equpcd, CageReportItemChangeTypes.EQUPCD));
                      },
                    )}
                  />
                  <TextBox
                    value={model?.equpnm}
                    weight={1.5}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
                <LabelBox
                  title="접수(사고또는발생)일시"
                  isNeed={true}
                  weight={3}
                >
                  <DateTextBox
                    value={model?.recedate}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => setData({ recedate: value })}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.recenum}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="접수시간"
                  weight={1}
                >
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={model?.recetime}
                    onChange={(value) => setData({ recetime: value })}
                  />
                </LabelBox>
                <LabelBox
                  title="갇힘시간(분)"
                  style={{ color: 'var(--color-red)' }}
                  weight={1.1}
                >
                  <FormatTextBox
                    value={model?.troubletime}
                    textAlign="right"
                    format='##분'
                    onChange={(value) => setData({ troubletime: value })}
                  />
                </LabelBox>
                <LabelBox title="인원(명)"
                  style={{ color: 'var(--color-red)' }}
                  weight={0.9}
                  isNeed={true}
                >
                  <FormatTextBox
                    value={model?.troublesu}
                    textAlign="right"
                    format='##명'
                    onChange={(value) => setData({ troublesu: value })}
                  />
                </LabelBox>
                <LabelBox
                  title="처리자"
                  style={{ color: 'var(--color-red)' }}
                  weight={2}
                  isNeed={true}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.perid}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E010',
                      {},
                      true,
                      (item) => {
                        setData({
                          contcd: item.cd,
                          contnm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.pernm}
                    weight={1.5}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="(지원)처리자2"
                  style={{ color: 'var(--color-red)' }}
                  weight={2}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.perid2}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E010',
                      {},
                      true,
                      (item) => {
                        setData({
                          contcd: item.cd,
                          contnm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.pernm2}
                    weight={1.5}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="보고자명"
                  style={{ color: 'var(--color-red)' }}
                  weight={2}
                  isNeed={true}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.inperid}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E010',
                      {},
                      true,
                      (item) => {
                        setData({
                          contcd: item.cd,
                          contnm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    textAlign="center"
                    value={model?.inpernm}
                    weight={1.5}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
                <LabelBox
                  title="도착일자"
                  isNeed={true}
                  weight={1}
                >
                  <DateTextBox
                    value={model?.arrivdate}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => setData({ arrivdate: value },
                      () => scope.itemChanged(CageReportItemChangeTypeNames.arrivtime, CageReportItemChangeTypes.ARRIVTIME))}
                  />
                </LabelBox>
                <LabelBox
                  title="도착시간"
                  weight={1}
                >
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={model?.arrivtime}
                    onChange={(value) => setData({ arrivtime: value })}
                  />
                </LabelBox>
                <LabelBox
                  title="대응시간"
                  weight={1}
                >
                  <FormatTextBox
                    value={model?.resutime}
                    textAlign="right"
                    format='###분'
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="완료일자"
                  isNeed={true}
                >
                  <DateTextBox
                    value={model?.compdate}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => setData({ compdate: value },
                      () => scope.itemChanged(CageReportItemChangeTypeNames.compdate, CageReportItemChangeTypes.COMPDATE))}
                  />
                </LabelBox>
                <LabelBox
                  title="완료시간"
                  weight={1}
                >
                  <FormatTextBox
                    format="##:##"
                    textAlign="center"
                    value={model?.comptime}
                    onChange={(value) => setData({ comptime: value },
                      () => scope.itemChanged(CageReportItemChangeTypeNames.comptime, CageReportItemChangeTypes.COMPTIME))}
                  />
                </LabelBox>
                <LabelBox
                  title="처리시간"
                  weight={1}
                >
                  <FormatTextBox
                    value={model?.resulttime}
                    textAlign="right"
                    format='###분'
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
                <LabelBox title="구출기관"
                  weight={3}
                  isNeed={true}
                >
                  <OptionBox
                    value={model?.rescueflag || '001'}
                    data={[
                      { value: '1', remark: '119 구조대' },
                      { value: '2', remark: '유지관리업체' },
                      { value: '3', remark: '관리주체' },
                      { value: '0', remark: '기타' },
                    ]}
                    onChange={(item) => setData({ rescueflag: item.value })}
                  />
                </LabelBox>
                <LabelBox
                  title="(구출기관 기타일시 입력)"
                  weight={1.5}
                >
                  <TextBox
                    value={model?.rescueremark}
                    readonly={model?.rescueflag !== '0'}
                  />
                </LabelBox>
                <LabelBox
                  title="피해정도"
                  weight={1.5}
                  isNeed={true}
                >
                  <OptionBox
                    value={model?.damageflag || '001'}
                    data={[
                      { value: '0', remark: '없음' },
                      { value: '1', remark: '갇힘' },
                      { value: '2', remark: '부상' },
                      { value: '3', remark: '사망' },
                    ]}
                    onChange={(item) => setData({ damageflag: item.value })}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
            <FlexLayout
              justify="center"
              align="center"
              weight={0.7}
            >
              <LabelBox
              >
                <span
                  style={{ fontWeight: 1000, color: '#008080' }}
                >승강기<br/>&nbsp; 현&nbsp;황</span></LabelBox>
            </FlexLayout>

            <FlexLayout
              weight={10}
            >
              <LabelBox
                title="승강기번호"
                isNeed={true}
              >
                <TextBox
                  value={model?.elno}
                  textAlign="center"
                />
              </LabelBox>
              <LabelBox
                title="승강기종류(구조/용도)"
              >
                <TextBox
                  value={model?.kindnm}
                />
              </LabelBox>
              <LabelBox title="제조업체또는수입업체"
              >
                <TextBox
                  value={model?.manunm}
                />
              </LabelBox>
              <LabelBox title="유지관리업체"
              >
                <TextBox
                  value={model?.bosunum}
                />
              </LabelBox>
              <LabelBox title="모델명(개별인증은승강기명)"
              >
                <TextBox
                  value={model?.mdnm}
                />
              </LabelBox>
              <LabelBox title="안전인증번호"
              >
                <TextBox
                  value={model?.safenum}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
            <FlexLayout
              justify="center"
              align="center"
              weight={0.7}
            >
              <LabelBox>
                <span
                  style={{ fontWeight: 1000, color: '#008080' }}
                >검&nbsp;사<br/>현&nbsp;황</span>
              </LabelBox>
            </FlexLayout>

            <FlexLayout
              weight={10}
            >
              <LabelBox title="설치검사합격일"
                weight={1}
              >
                <DateTextBox
                  value={model?.yyyymm}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => setData({ yyyymm: value })}
                />
              </LabelBox>
              <LabelBox
                title="최종검사 대행기간"
                weight={1}
              >
                <TextBox
                  textAlign="center"
                  value="승강기안전공단"
                />
              </LabelBox>
              <LabelBox title="최종검사일"
                weight={1}
              >
                <DateTextBox
                  value={model?.totkcdate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => setData({ compdate: value })}
                />
              </LabelBox>
              <LabelBox
                title="최종검사결과"
                weight={1}
              >
                <TextBox
                  value={model?.kcresultnm}
                  textAlign="center"
                />
              </LabelBox>
              <LabelBox
                style={{ color: 'var(--color-blue)' }}
                title="정보센터전송 확인여부 및 비고사항(내부)"
                weight={2}
                footer={<FlexLayout>
                  <Button
                    isIcon={true}
                    onClick={() => scope.SS({ isVisibleAttachment: true })}
                  >
                    <AiOutlineCloudUpload size={24} />
                  </Button>
                </FlexLayout>}
              >
                <OptionBox
                  value={model?.sendflag || '001'}
                  data={[
                    { value: '0', remark: '확인(민원24등록)' },
                    { value: '1', remark: '미확인(미등록)' },
                  ]}
                  onChange={(item) => setData({ sendflag: item.value })}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
            <FlexLayout
              justify="center"
              align="center"
              weight={0.7}
            >
              <LabelBox>
                <span
                  style={{ fontWeight: 1000, color: '#008080' }}
                >점&nbsp;검<br/>현&nbsp;황</span>
              </LabelBox>
            </FlexLayout>

            <FlexLayout
              weight={10}
            >
              <LabelBox title="최종점검일"
                weight={1}
              >
                <DateTextBox
                  value={model?.rptdate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => setData({ compdate: value })}
                />
              </LabelBox>

              <LabelBox title="점검결과"
                weight={1}
              >
                <TextBox
                  value={model?.rptresult}
                  textAlign="center"
                />
              </LabelBox>

              <LabelBox title="자체점검자"
                weight={1}
              >
                <TextBox
                  value={model?.rptpernm}
                />
              </LabelBox>
              <LabelBox title="자격유무"
                weight={1}
              >
                <ComboBox
                  value={model?.rptlicense}
                  textAlign="center"
                  data={[
                    { value: '1', remark: '유' },
                    { value: '0', remark: '무' },
                  ].map((x) => new ComboBoxModel(x.value, x.remark))}
                  onSelect={(option) => setData({ rptlicense: option.value })}
                />
              </LabelBox>
              <LabelBox
                weight={2}
              >
                <TextBox
                  value={model?.sendflag_remark}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout
          >
            <FlexLayout
              justify="center"
              align="center"
              weight={0.7}
            >
              <LabelBox>
                <span
                  style={{ fontWeight: 1000, color: '#008080' }}
                >&nbsp;사고 고장 &nbsp;&nbsp;내용 및 &nbsp;조치 사항</span>
              </LabelBox>
            </FlexLayout>

            <FlexLayout
              isVertical={true}
              weight={10}
            >
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
                <LabelBox
                  title="[1] 피해자 인적사항(성명, 나이, 성별)"
                  weight={2.7}
                  isNeed={true}
                >
                  <TextBox
                    value={model?.cri_trouble}
                    onChange={(value) => setData({ cri_trouble: value })}
                  />
                </LabelBox>
                <LabelBox
                  title="중대한사고여부"
                  weight={2.3}
                  isNeed={true}
                >
                  <OptionBox
                    value={model?.cri_flag || '001'}
                    data={[
                      { value: '1', remark: '중대사고' },
                      { value: '2', remark: '중대사고아님' },
                      { value: '3', remark: '중대사고판단곤란' },
                    ]}
                    onChange={(item) => setData({ cri_flag: item.value })}
                  />
                </LabelBox>
                <LabelBox
                  title="현장보존조치 필요여부"
                  weight={1}
                >
                  <OptionBox
                    value={model?.cri_actflag || ''}
                    data={[
                      { value: '1', remark: '필요' },
                      { value: '0', remark: '불필요' },
                    ]}
                    onChange={(item) => setData({ cri_actflag: item.value })}
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout>
                <FlexLayout isVertical={true}>
                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_2}>
                    <LabelBox
                      title="[2] 사고 또는 고장내용 및 응급조치"
                      style={{ color: 'var(--color-red)' }}
                      isNeed={true}
                      footer={<FlexLayout>
                        <span
                        >1.고장신고형태</span>
                        </FlexLayout>}
                    >
                      <OptionBox
                        value={model?.cri_remark_1 || '001'}
                        data={[
                          { value: '1', remark: '비상통화장치' },
                          { value: '2', remark: '휴대폰' },
                          { value: '3', remark: '관리사무소' },
                          { value: '4', remark: '경비실' },
                          { value: '0', remark: '기타' },
                        ]}
                        onChange={(item) => setData({ cri_remark_1: item.value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout
                  >
                    <LabelBox
                      title="2.고장내용 상세 기재 요함(육하원칙의거) 도착시 현장상황"
                      weight={1.5}
                      isNeed={true}
                      style={{ paddingRight: 3 }}
                    >
                      <TextBox
                        value={model?.cri_remark_2}
                        isMultiline={true}
                        onChange={(value) => setData({ cri_remark_2: value })}
                      />
                    </LabelBox>
                    <LabelBox
                      title="3.응급조치(조치내용)"
                      weight={1.5}
                      isNeed={true}
                    >
                      <TextBox
                        value={model?.cri_remark_3}
                        isMultiline={true}
                        onChange={(value) => setData({ cri_remark_3: value })}
                      />
                    </LabelBox>
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout style={{ width: 498 }} isVertical={true}>
                  <FlexLayout
                    weight={2}
                  >
                    <LabelBox
                      title="[3] 사고 또는 고장의 원인 및 예방대책"
                      isNeed={true}
                      style={{ color: 'var(--color-red)' }}
                    >
                      <TextBox
                        value={model?.safe_remark}
                        isMultiline={true}
                        onChange={(value) => setData({ safe_remark: value })}
                      />
                    </LabelBox>
                  </FlexLayout>
                  <LabelBox
                  >
                    <FlexLayout
                      isVertical={true}
                      weight={3}
                    >
                      <FlexLayout
                        justify='center'
                        align='center'
                      >
                        <LabelBox
                          title="제조업체과실"
                        >
                          <CheckBox
                            value={model?.safe_nm1 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm1: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="작업자과실"
                        >
                          <CheckBox
                            value={model?.safe_nm2 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm2: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="유지관리업체과실"
                        >
                          <CheckBox
                            value={model?.safe_nm3 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm3: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="이용자과실"
                        >
                          <CheckBox
                            value={model?.safe_nm4 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm4: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                      </FlexLayout>
                      <FlexLayout
                        justify='center'
                        align='center'
                      >
                        <LabelBox
                          title="관리주체과실"
                        >
                          <CheckBox
                            value={model?.safe_nm5 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm5: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="이물질끼임"
                        >
                          <CheckBox
                            value={model?.safe_nm6 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm6: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="부품이상"
                        >
                          <CheckBox
                            value={model?.safe_nm7 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm7: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="조정불량"
                        >
                          <CheckBox
                            value={model?.safe_nm8 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm8: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                      </FlexLayout>
                      <FlexLayout
                        justify='center'
                        align='center'
                      >
                        <LabelBox
                          title="노후"
                        >
                          <CheckBox
                            value={model?.safe_nm9 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm9: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="천재지변/불가항력"
                        >
                          <CheckBox
                            value={model?.safe_nm10 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm10: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="비정상사용"
                        >
                          <CheckBox
                            value={model?.safe_nm11 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm11: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                        <LabelBox
                          title="기타(확인불가)"
                        >
                          <CheckBox
                            value={model?.safe_nm12 === '1'}
                            onChange={(value) => {
                              setData({ safe_nm12: value ? '1' : '0' });
                            }}
                          />
                        </LabelBox>
                      </FlexLayout>
                    </FlexLayout>
                    </LabelBox>
                  </FlexLayout>
                </FlexLayout>
            </FlexLayout>

          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <ModalView
        isVisible={scope.state.popupModal}
        onClose={() => scope.PopupModal(false)}
        width={1500}
        height={1000}
        zIndex={-2}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle weight={1}>
              사람갇힘 불러오기
            </LayoutTitle>
          </FlexLayout>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <FlexLayout weight={0.5}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdatePopup}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdatePopup: value })}
                onEnter={() => scope.popupRetrieve()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdatePopup));
                    date.setDate(date.getDate() - 1);
                    setData({ stdatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdatePopup));
                    date.setDate(date.getDate() + 1);
                    setData({ stdatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout weight={0.5}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddatePopup}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddatePopup: value })}
                onEnter={() => scope.popupRetrieve()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddatePopup));
                    date.setDate(date.getDate() - 1);
                    setData({ enddatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddatePopup));
                    date.setDate(date.getDate() + 1);
                    setData({ enddatePopup: Date8.make(date) },
                      () => scope.popupRetrieve());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout
              weight={1}>
              <span style={{ color: 'var(--color-red)' }}>고장내용</span>
              <TextBox
                value={scope.state.contcdPopup}
                textAlign="center"
                onChange={(value) => setData({ contcdPopup: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E010',
                  {},
                  true,
                  (item) => {
                    scope.setState({ contcdPopup: item.cd, contnmPopup: item.cdnm });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.contnmPopup}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout
              weight={1}>
              <span>처리</span>
              <ComboBox
                value={scope.state.gubunPopup}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '0', remark: '미처리' },
                  { value: '1', remark: '처리' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ gubunPopup: option.value },
                  () => scope.popupRetrieve())}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
              <Button
                style={{ width: 100 }}
                onClick={() => scope.popupRetrieve()}
              >
                조회
              </Button>
          </SearchLayout>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <FlexLayout
              weight={1}
            >
              <span>현장명</span>
              <TextBox
                value={scope.state.as_nmPopup}
                onChange={(value) => scope.setState({ as_nmPopup: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout
              weight={1}
            >
              <span style={{ color: 'var(--color-red)' }}>부서</span>
              <TextBox
                textAlign="center"
                value={scope.state.divicdPopup}
                onChange={(value) => scope.setState({ divicdPopup: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      divicdPopup: item.cd,
                      divinmPopup: item.cdnm,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.divinmPopup}
                readonly={true}
                onChange={(value) => scope.setState({ divinmPopup: value })}
              />
            </FlexLayout>
            <FlexLayout
              weight={1}
            >
              <span style={{ color: 'var(--color-red)' }}>담당</span>
              <TextBox
                value={scope.state.pernmPopup}
                textAlign="center"
                onChange={(value) => setData({ pernmPopup: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({ peridPopup: item.cd, pernmPopup: item.cdnm });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.peridPopup}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>

            <Button
              style={{ width: 100 }}
              onClick={() => scope.popupDelete()}
            >
              사람갇힘삭제
            </Button>
          </SearchLayout>

          <TableLayout
            rowHeight={70}
            headerHeight={55}
            ref={scope.popupHumanTable}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows2(rows, updatedRows);
            }}
            header={[
              {
                id: 'chk',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      삭제
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 50,
                render: (x, rowUpdate) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      <CheckBox
                        value={x.chk === '1'}
                        noMargin={true}
                        onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    총계
                  </FlexLayout>
                ),
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.actnm}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.equpcnt_tot}대
                  </FlexLayout>
                ),
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.equpnm}
                  />
                ),
              },
              {
                id: 'actpernm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="end"
                      justify="center"
                    >
                      점검자
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      접수자
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      처리자
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.cnt_tot}건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      {x.actpernm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      {x.repernm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'seq',
                text: '처리',
                width: 50,
                render: (x) => (
                  <FormatTextBox
                    format="#차"
                    textAlign="center"
                    value={x.seq}
                  />
                ),
              },
              {
                id: 'recedate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="end"
                      justify="center"
                    >
                      접수일자
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      도착일자
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      완료일자
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={x.recedate}
                    />

                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={x.arrivdate}
                    />

                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={x.compdate}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="end"
                      justify="center"
                    >
                      접수시간
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      도착시간
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      완료시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FormatTextBox
                      format="##:##"
                      textAlign="center"
                      value={x.recedate}
                    />

                    <FormatTextBox
                      format="##:##"
                      textAlign="center"
                      value={x.arrivtime}
                    />

                    <FormatTextBox
                      format="##:##"
                      textAlign="center"
                      value={x.comptime}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'repernm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="end"
                      justify="center"
                    >
                      대응시간
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      처리시간
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      총시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="end"
                      justify="end"
                      style={{ paddingRight: 12 }}
                    >
                      {x.resutime}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="end"
                      style={{ paddingRight: 12 }}
                    >
                      {x.resulttime}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="end"
                      style={{ paddingRight: 12 }}
                    >
                      {x.resutottime}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'contnm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      고장내용
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      고장내용상세
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 300,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.contnm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.contremark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      처리내용
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      처리내용상세
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 800,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.resunm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.resuremark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      고장요인
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      고장요인상세
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 300,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.remonm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="start"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.remoremark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      고장부위
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      고장부위상세
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 200,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="left"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.greginm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="left"
                      style={{ paddingLeft: 12 }}
                    >
                      {x.reginm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="end"
                      justify="center"
                    >
                      고장원인
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      진행내역
                    </FlexLayout>

                    <FlexLayout
                      align="start"
                      justify="center"
                    >
                      처리방법
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      {x.facnm}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      {Results?.filter((y) => y.value === x.result)[0]?.remark}
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      justify="center"
                    >
                      {x.resultnm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
            infinityHandler={scope.infinityPopup}
            onRowFocusEvent={(item) => scope.onPopupRowFocusEvent(item)}
          />
          <FlexLayout size={130}>
            <LabelBox
              title="고장내용상세"
            >
              <TextBox
                value={scope.state.focusedPopupData?.contremark}
                onChange={(value) => setData({ contremark: value })}
                isMultiline={true}
              />
            </LabelBox>
            <LabelBox
              title="고장요인상세"
            >
              <TextBox
                value={scope.state.focusedPopupData?.remoremark}
                isMultiline={true}
              />
            </LabelBox>
            <LabelBox
              title="처리내용상세"
            >
              <TextBox
                value={scope.state.focusedPopupData?.resuremark}
                onChange={(value) => setData({ resuremark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout>
              <FlexLayout weight={1}>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.BLUE}
                  onClick={() => scope.popupSave()}
                >
                  확인
                </Button>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.PopupModal(false)}
                >
                  닫기
                </Button>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
