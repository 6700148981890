import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca608_01
 * @category 발주등록
 */

export class OrderDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   * 발주구분
   *
   * **data type** varchar(5)
   */
  readonly baldv: string;

  /**
   * 담당자
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 입고구분
   *
   * **data type** varchar(1)
   */
  readonly ipgflag: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(0)
   */
  readonly cltnm: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 부가세구분
   *
   * **data type** varchar(2)
   */
  readonly taxcls: string;

  /**
   * 작성일자
   *
   * **data type** char(8)
   */
  readonly indate: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly inpernm: string;

  /**
   * 전달사항
   *
   * **data type** text
   */
  readonly bigo: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(10)
   */
  readonly projnm: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 하자기간
   *
   * **data type** float
   */
  readonly hagigan: string;

  /**
   * 소요기간
   *
   * **data type** float
   */
  readonly sogigan: string;

  /**
   * 발주부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 발주부서명
   *
   * **data type** varchar(100)
   */
  readonly divinm: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 중도금
   *
   * **data type** float
   */
  readonly midyul: string;

  /**
   * 잔금
   *
   * **data type** float
   */
  readonly endyul: string;

  /**
   * 할인율
   *
   * **data type** float
   */
  readonly saleyul: string;

  /**
   * 납품장소
   *
   * **data type** varchar(13)
   */
  readonly proactcd: string;

  /**
   * 중도금2
   *
   * **data type** float
   */
  readonly midamt: string;

  /**
   * 잔금2
   *
   * **data type** float
   */
  readonly endamt: string;

  /**
   * 할인금액
   *
   * **data type** float
   */
  readonly saleamt: string;

  /**
   * 계약금 여부
   *
   * **data type** float
   */
  readonly sunflag: string;

  /**
   * 계약금
   *
   * **data type** float
   */
  readonly styul: string;

  /**
   * 계약금2
   *
   * **data type** float
   */
  readonly stamt: string;

  /**
   * 발주처담당자
   *
   * **data type** varchar(100)
   */
  readonly balpernm: string;

  /**
   * 발주연락처
   *
   * **data type** varchar(20)
   */
  readonly telnum: string;

  /**
   * 발주처fax
   *
   * **data type** varchar(20)
   */
  readonly faxnum: string;

  /**
   * 발주email
   *
   * **data type** varchar(100)
   */
  readonly email: string;

  /**
   * 첨부파일
   *
   * **data type** varchar(1)
   */
  readonly attcnt: number;

  /**
   * 첨부파일2
   *
   * **data type** varchar(1)
   */
  readonly attcnt2: number;

  /**
   * 순번
   *
   * **data type**
   */
  readonly balseq: string;

  /**
   * 품목코드
   *
   * **data type**
   */
  readonly pcode: string;

  /**
   * 품목명
   *
   * **data type**
   */
  readonly pname: string;

  /**
   * 규격
   *
   * **data type**
   */
  readonly psize: string;

  /**
   * 단위
   *
   * **data type**
   */
  readonly punit: string;

  /**
   * 입고요청일
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   * 입고예정일
   *
   * **data type** varchar(8)
   */
  readonly re_ibgdate: string;

  /**
   * 예비자재
   *
   * **data type** varchar(1)
   */
  ibgflag: string;

  /**
   * 현재고
   *
   * **data type** float
   */
  readonly jqty: string;

  /**
   * 수량
   *
   * **data type** float
   */
  readonly qty: string;

  /**
   * 단가
   *
   * **data type** float
   */
  readonly uamt: string;

  /**
   * 공급금액
   *
   * **data type** float
   */
  readonly samt: string;

  /**
   * 부가세구분
   *
   * **data type** float
   */
  readonly tamt: string;

  /**
   * 합계
   *
   * **data type** float
   */
  readonly mamt: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(0)
   */
  readonly actnm: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.baldv = data.baldv || '';
    this.pernm = data.pernm || '';
    this.perid = data.perid || '';
    this.ipgflag = data.ipgflag || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.state = data.state || '';
    this.taxcls = data.taxcls || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.bigo = data.bigo || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.appnum = data.appnum || '';
    this.hagigan = data.hagigan || '';
    this.sogigan = data.sogigan || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.refer = data.refer || '';
    this.picflag = data.picflag || '';
    this.midyul = data.midyul || '';
    this.endyul = data.endyul || '';
    this.saleyul = data.saleyul || '';
    this.proactcd = data.proactcd || '';
    this.midamt = data.midamt || '';
    this.endamt = data.endamt || '';
    this.saleamt = data.saleamt || '';
    this.sunflag = data.sunflag || '';
    this.styul = data.styul || '';
    this.stamt = data.stamt || '';
    this.balpernm = data.balpernm || '';
    this.telnum = data.telnum || '';
    this.faxnum = data.faxnum || '';
    this.email = data.email || '';
    this.attcnt = data.attcnt || 0;
    this.attcnt2 = data.attcnt2 || 0;
    this.balseq = data.balseq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.deldate = data.deldate || '';
    this.re_ibgdate = data.re_ibgdate || '';
    this.ibgflag = data.ibgflag || '';
    this.jqty = data.jqty || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.remark = data.remark || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
