import * as React from 'react';
import {
  FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentTabItemsTableHeader } from '../headers/EnrollmentTabItemsTableHeader';

/**
 * 화면 > 탭 - 견적항목
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTabItemsTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.tableItems}
      header={EnrollmentTabItemsTableHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataItems}
      onChange={(rows, updatedRows) => scope.onChangeTableItems(rows, updatedRows)}
      onRowFocusEvent={(row, index) => scope.onRowFocusItems(row, index)}
    />
  </FlexLayout>
);
