import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdAvTimer,
  MdWork,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  InfoModel,
  InfoDetailModel,
  HistoryModel,
  ProductModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ElevatorInfoTemplate } from './ElevatorInfo.template';
import { InfinityRetrieve } from '../../../../models/common';
import { TabHeaderHistory, TabHeaderProduct, TabHeaderInfo } from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum ElevatorInfoTabId {
  Info,
  History,
  Product,
}

export const ElevatorInfoTabTitles = [
  '승강기제원정보',
  '승강기이력카드',
  '승강기이력카드[제품]',

];

export const ElevatorInfoTabModels = [
  InfoDetailModel,
  HistoryModel,
  ProductModel,
];

export const ElevatorInfoTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
];

export const ElevatorInfoTabDataStateNames = [
  'Infos',
  'Historys',
  'Products',
];

export const ElevatorInfoTabUpdatesStateNames = [
  'InfoUpdates',
  'HistoryUpdates',
  'ProductUpdates',
];

export const ElevatorInfoTabFocusedStateNames = [
  'InfoUpdatesFocused',
  'HistoryUpdatesFocused',
  'ProductUpdatesFocused',
];

export interface ElevatorInfoState {
  // 승강기제원정보
  Infos?: InfoDetailModel;
  InfoUpdates?: Array<InfoDetailModel>;
  InfosFocused?: InfoDetailModel;

  // 이력카드
  Historys?: Array<HistoryModel>;
  HistoryUpdates?: Array<HistoryModel>;
  HistorysFocused?: HistoryModel;

  // 이력카드제품
  Products?: Array<ProductModel>;
  ProductUpdates?: Array<ProductModel>;
  ProductsFocused?: ProductModel;

  focusedTab?: TabModel;
  focuseddata?: any;
  searchQuery?: string;
  gubun?: string;
  perid?: string;
  pernm?: string;
  wkactcd?: string;
  wkactnm?: string;
  infoList?: Array<InfoModel>;
  infoDetail?: InfoDetailModel;

  basicAddressToggle?: boolean;


  gubuns?: Array<any>;
  gubuns2?: Array<any>;
  kinds?: Array<any>
  forms?: Array<any>
  invers?: Array<any>;
  kcgubuns?: Array<any>;
  kcresults?: Array<any>;
  speeds?: Array<any>;
  // 종류
  evcds?: Array<any>;

  // 도면, PLC
  isDPModalVisible?: boolean;
  isPLCModalVisible?: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_e611_type
 * @category 승강기제원정보[이력카드]
 */
export class ElevatorInfo extends PageComponent<PageProps, ElevatorInfoState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = ElevatorInfoTabId;
    const titles = ElevatorInfoTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    this.tabs = [
      new TabModel(id.Info.toString(), MdAvTimer, titles[id.Info]),
      new TabModel(id.History.toString(), MdWork, titles[id.History]),
      new TabModel(id.Product.toString(), MdWork, titles[id.Product]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      wkactcd: '',
      wkactnm: '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      gubun: '1',
      month3: '0',
      searchQuery: '',
      data: [],
      basicAddressToggle: true,
    };

    this.grid = React.createRef();

    this.tabHeaders = [
      // 승강기제원정보
      TabHeaderInfo,

      // 승강기이력카드
      TabHeaderHistory,

      // 승강기이력카드[제품]
      TabHeaderProduct,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = await api.dropdown('wf_dd_e002_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ gubuns2: data.items });

    // 종류
    data = await api.dropdown('wf_dd_e004_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ kinds: data.items });

    // 매출구분

    data = await api.dropdown('wf_dd_da020');
    if (!data) return;
    this.setState({ gubuns: data?.items || [] });

    // 형식
    data = await api.dropdown('wf_dd_e005_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ forms: data.items });

    // 도어방식
    data = await api.dropdown('wf_dd_e007_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ invers: data.items });

    // 정격속도
    data = await api.dropdown('wf_dd_e008_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ speeds: data.items });

    // 검사구분
    data = await api.dropdown('wf_dd_ca510_012_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ kcgubuns: data.items });

    // 검사결과
    data = await api.dropdown('wf_dd_ca510_018_01');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ kcresults: data.items });

    data = await api.dropdown('wf_dd_e030_new');
    if (!data) return;
    this.setState({ evcds: data?.items });
    await this.onRetrieveEvent();
    await this.onTabChange(this.tabs[ElevatorInfoTabId.Info]);
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        perid: this.state.perid,
        wkactcd: this.state.wkactcd,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          infoList: [...this.state.infoList || [], ...items],
        }, next);
      },
      async () => {
        await this.SS({
          infoList: [],
          [ElevatorInfoTabUpdatesStateNames[this.tabIndex]]: [],
          [ElevatorInfoTabFocusedStateNames[this.tabIndex]]: undefined,
        });
        await this.infinity?.retrieveAll();
        if (this.state.infoList && this.state.infoList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      infoList: [],
      [ElevatorInfoTabUpdatesStateNames[this.tabIndex]]: [],
      [ElevatorInfoTabFocusedStateNames[this.tabIndex]]: undefined,
    }, async () => {
      await this.infinity?.retrieveAll();
      if (this.state.infoList && this.state.infoList?.length > 0) {
        await this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab) {
      this.setState({
        [ElevatorInfoTabUpdatesStateNames[i]]: [],
        [ElevatorInfoTabFocusedStateNames[i]]: undefined,
      });
    }

    this.setState({ focusedTab }, () => {
      this.onRowFocusEvent(this.state.focuseddata);
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[ElevatorInfoTabDataStateNames[this.tabIndex]].length < 1) {
      ConfirmWarning.show('경고', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${ElevatorInfoTabFunctionNames[i]}_print`,
      {
        actcd: this.state.focuseddata.actcd,
        equpcd: this.state.focuseddata.equpcd,
      },
    );
  }

  // Links
  // 전화번호등록
  async openTelnum() {
    const { publicStore } = this.props;
    publicStore.go('/w_tb_e611_035_03', {
      searchQuery: this.state.Infos?.actcd,
    });
  }

  @computed
  get tabIndex(): ElevatorInfoTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onRowFocusEvent(item: any) {
    const { actionStore: api } = this.props;
    this.setState({
      focuseddata: item,
    }, async () => {
      const tabData = await api.fxExec(`${
        ElevatorInfoTabFunctionNames[this.tabIndex]}_dw_1_RowFocuschanged`, item);
      if (this.tabIndex === ElevatorInfoTabId.Info) {
        this.setState({
          [ElevatorInfoTabDataStateNames[this.tabIndex]]:
            new ElevatorInfoTabModels[this.tabIndex](tabData),
        });
      } else if (tabData?.items) {
        this.setState({
          // @ts-ignore
          [ElevatorInfoTabDataStateNames[this.tabIndex]]:
            tabData.items.map(
              (x: any) => new ElevatorInfoTabModels[this.tabIndex](x),
            ),
        });
      } else if (tabData?.items2) {
        this.setState({
          // @ts-ignore
          [ElevatorInfoTabDataStateNames[this.tabIndex]]:
            tabData.items2.map(
              (x: any) => new ElevatorInfoTabModels[this.tabIndex](x),
            ),
        });
      } else if (tabData?.items3) {
        this.setState({
          // @ts-ignore
          [ElevatorInfoTabDataStateNames[this.tabIndex]]:
            tabData.items3.map(
              (x: any) => new ElevatorInfoTabModels[this.tabIndex](x),
            ),
        });
      } else {
        this.setState({
          // @ts-ignore
          [ElevatorInfoTabDataStateNames[this.tabIndex]]: [],
          [ElevatorInfoTabUpdatesStateNames[this.tabIndex]]: [],
          [ElevatorInfoTabFocusedStateNames[this.tabIndex]]: undefined,
        });
      }
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [ElevatorInfoTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [ElevatorInfoTabDataStateNames[this.tabIndex]]: rows,
    });
  }


  @action
  async copyDP() {
    if (!this.state.focuseddata?.dpattcnt || parseInt(this.state.focuseddata?.dpattcnt, 10) < 1) {
      ConfirmWarning.show('오류', '선택한 호기가 도면이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxExec('wb_dpcopy', {
      actcd: this.state.focuseddata?.actcd,
      equpcd: this.state.focuseddata?.equpcd,
    });

    this.onRowFocusEvent(this.state.focuseddata);
  }

  @action
  openPLC() {
    ConfirmWarning.show('...', '현재 이 기능은 개발중입니다');
  }

  @action
  async closeDPModal() {
    await this.SS({ isDPModalVisible: false });
    this.onRowFocusEvent(this.state.focuseddata);
  }

  @action
  async closePLCModal() {
    await this.SS({ isPLCModalVisible: false });
    this.onRowFocusEvent(this.state.focuseddata);
  }


  render() {
    return (
      <ElevatorInfoTemplate
        scope={this}
        update={(state, c) => this.setState(state, c)}
      />
    );
  }
}
