import * as React from 'react';
import {
  FlexLayout, TableLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Bank } from './Bank';

const BANK_CHK = [
  '불가능',
  '가능',
];

/**
 * 화면
 * @window w_s010
 * @category 은행코드등록
 */
export const BankTemplate: React.FC<TemplateProps<Bank>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'bankcd',
          text: '은행코드',
          width: 15,
        },
        {
          id: 'banknm',
          text: '은행명',
          width: 32,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.banknm}
              onChange={(value) => rowUpdate({ banknm: value })}
            />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 43,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
        {
          id: 'bankda',
          text: '연동가능',
          width: 10,
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
              style={{
                color: x.bankda === '1' ? 'var(--color-blue)' : x.bankda === '0' ? 'var(--color-red)' : '',
              }}
            >
              {BANK_CHK[x.bankda]}
            </FlexLayout>
          ),
        },
      ]}
      data={scope.state.bankList}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
    />
  </FlexLayout>
);
