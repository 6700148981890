import { action } from 'mobx';
import { AskType, PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ProcessModel from './models/ProcessModel';
import { ProcessTemplate } from './Process.template';
import {
  // Date6,
  Date8,
} from '../../../../utils/time';
import { Confirm } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


// 설치구분
export const ScGubuns = [
  { key: '%', value: '전체' },
  { key: '1', value: '설치' },
  { key: '2', value: '보수' },
];

// 계약구분
export const Gubuns = [
  { key: '%', value: '전체' },
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];


interface ProcessState {
  searchQuery: string;
  stmon: string;
  endmon: string;
  gubun: string;
  contflag: string;
  data: Array<ProcessModel>;
  focusIndex: number;
  content: ProcessModel;
  perid: string;
  pernm: string;
}


/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_03
 * @category 설치진행현황
 */
export class Process extends PageComponent<PageProps, ProcessState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      // stmon: '197001',
      // endmon: Date6.make(),
      stmon: '',
      endmon: '',
      gubun: '%',
      contflag: '%',
      data: [],
      focusIndex: 0,
      content: new ProcessModel(),
      perid: '',
      pernm: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        contflag: this.state.contflag,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new ProcessModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({ data: [] });
        this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, () => this.infinity?.retrieveAll());
  }

  @action
  onRowFocusEvent(item: ProcessModel, index: number) {
    this.setState({
      content: item,
      focusIndex: index,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  bookName(bookcd: string) {
    switch (parseInt(bookcd, 10)) {
      case 1: return '제작'; break;
      case 2: return '제출'; break;
      case 3: return '완료'; break;
      default: return '미제작';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isAlert(itemcd: string, targetDate: string, reverse: boolean = false) {
    if (!targetDate || targetDate === '') return false;
    return itemcd === (reverse ? '1' : '0') && Date8.make() > targetDate;
  }

  percent(item: ProcessModel): number {
    let sum = 0;

    if (item.ordflag === '1') {
      sum += 20;
    }

    if (item.balflag === '1') {
      sum += 20;
    }

    if (item.sckcflag === '1') {
      sum += 20;
    }

    if (item.kcflag === '1') {
      sum += 20;
    }

    if (item.okflag === '1') {
      sum += 20;
    }

    return sum;
  }


  // Links
  // 수주서 등록
  async openSujuPaper(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n수주를 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_da006', {
        searchQuery: item.scactnm,
        stdate: item.orddate,
        enddate: item.orddate,
      });
    }
  }

  // 계약금 발행
  async openEarn(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n계약금을 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_da023', {
        projno: `${item.misstdate}${item.misstnum}`,
        misdate: item.misstdate,
      });
    }
  }

  // 설치현장등록
  async openRegist(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_e601_sulchi', {
        searchQuery: item.scactnm,
      });
    }
  }

  // 설치공정현황
  async openProcess(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n공정(착공율)을 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_e601w_sulchi_04', {
        searchQuery: item.scactnm,
      });
    }
  }

  // 설치발주현황
  async openBal(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n발주내역을 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_e601w_sulchi_05', {
        searchQuery: item.scactnm,
      });
    }
  }

  // 중도금 발행
  async openEarnMid(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n중도금을 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_da023', {
        projno: `${item.mismiddate}${item.mismidnum}`,
        misdate: item.mismiddate,
      });
    }
  }

  // 잔금 발행
  async openEarnEnd(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n잔금을 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_da023', {
        projno: `${item.misenddate}${item.misendnum}`,
        misdate: item.misenddate,
      });
    }
  }

  // 사전검사
  async openPreChk(item: ProcessModel) {
    const message = `프로젝트: ${item.projnm}\n설치현장명: ${item.scactnm}\n사전검사를 확인하시겠습니까?`;
    if (AskType.YES === await Confirm.ask('바로가기', message, '예', '취소')) {
      const { publicStore } = this.props;
      publicStore.go('/w_tb_e045_03', {
        searchQuery: `${item.scactnm}`,
        mon: item.be_date.substr(0, 6),
      });
    }
  }


  render() {
    return <ProcessTemplate
      scope={this}
    />;
  }
}
