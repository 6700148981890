import { computed } from 'mobx';

/**
 * 메인모델
 * @window w_tb_ca640
 * @category 매입등록
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 0
   *
   * **data type** varchar(1)
   */
  mijgubun: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 매입번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 귀속부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 총매입금액
   *
   * **data type** float
   */
  mijamt: string;

  /**
   * 차이금액
   *
   * **data type** float
   */
  chaamt: string;

  /**
   * 지급예정일
   *
   * **data type** varchar(8)
   */
  schdate: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  taxcls: string;

  /**
   * 0
   *
   * **data type** float
   */
  monrate: string;

  /**
   * 현금지급
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** float
   */
  eamt: string;

  /**
   * 어음번호
   *
   * **data type** varchar(12)
   */
  enum: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  edate: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  eidate: string;

  /**
   * 어음거래처
   *
   * **data type** varchar(13)
   */
  ecltcd: string;

  /**
   * 공급가액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 예금금액
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 지금은행
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 은행번호
   *
   * **data type** varchar(20)
   */
  bankno: string;

  /**
   * 카드금액
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드금액
   *
   * **data type** varchar(10)
   */
  cardcd: string;

  /**
   * 카드요율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 카드번호
   *
   * **data type** varchar(16)
   */
  cardno: string;

  /**
   * 0
   *
   * **data type** float
   */
  camt: string;

  /**
   * 0
   *
   * **data type** float
   */
  ctaxamt: string;

  /**
   * 기타구분
   *
   * **data type** varchar(5)
   */
  ggubun: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 계산서일자
   *
   * **data type** varchar(8)
   */
  tax_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  tax_spnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 선급금구분
   *
   * **data type** varchar(1)
   */
  sunflag: string;

  /**
   * 선급금구분
   *
   * **data type** float
   */
  sunamt: string;

  /**
   * 증빙구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 발행구분
   *
   * **data type** varchar(3)
   */
  bhflag: string;

  /**
   * 영세율구분
   *
   * **data type** varchar(3)
   */
  osflag: string;

  /**
   * 내수구분
   *
   * **data type** varchar(3)
   */
  cdflag: string;

  /**
   * 자산구분
   *
   * **data type** varchar(3)
   */
  jsflag: string;

  /**
   * 귀속년월
   *
   * **data type** varchar(6)
   */
  yyyymm: string;

  /**
   * 1
   *
   * **data type** varchar(2)
   */
  setcls: string;

  /**
   * 계정과목
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 지로금액
   *
   * **data type** float
   */
  jamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 참고(비고)
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 회계적용
   *
   * **data type** varchar(3)
   */
  accyn: string;

  /**
   * 비용항목
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 미지급거래처
   *
   * **data type** varchar(13)
   */
  mijcltcd: string;

  /**
   * 미지급거래처
   *
   * **data type** varchar(100)
   */
  mijcltnm: string;

  /**
   * 카드거래처
   *
   * **data type** varchar(13)
   */
  cardcltcd: string;

  /**
   * 카드거래처명
   *
   * **data type** varchar(100)
   */
  cardcltnm: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 거래처구분
   *
   * **data type** varchar(2)
   */
  cltflag: string;

  /**
   * 카드
   *
   * **data type** varchar(10)
   */
  cardco: string;

  /**
   * 카드2
   *
   * **data type** varchar(40)
   */
  cardnm: string;

  /**
   * 카드3
   *
   * **data type** varchar(20)
   */
  cardnum: string;

  /**
   * 입고일자
   *
   * **data type** varchar(8)
   */
  ibgdate: string;

  /**
   * 입고번호
   *
   * **data type** varchar(4)
   */
  ibgnum: string;

  /**
   * 0
   *
   * **data type** varchar(1)
   */
  jflag: string;

  /**
   * 정상시지급처리
   *
   * **data type** varchar(1)
   */
  etflag: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 현장명
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 그룹매입일자
   *
   * **data type** varchar(8)
   */
  gmijdate: string;

  /**
   * 그룹매입번호
   *
   * **data type** varchar(4)
   */
  gmijnum: string;

  /**
   * 지급유형
   *
   * **data type** varchar(1)
   */
  amtflag: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 지급자
   *
   * **data type** varchar(50)
   */
  inpernm: string;

  /**
   * 성명
   *
   * **data type** varchar(50)
   */
  pernm: string;

  /**
   * 귀속부서
   *
   * **data type** varchar(100)
   */
  divinm: string;

  /**
   * 항목구분
   *
   * **data type** varchar(100)
   */
  artnm: string;

  /**
   * 예금연결코드
   *
   * **data type** varchar(50)
   */
  imseq: string;

  /**
   * 예전매입일자
   *
   * **data type** varchar(8)
   */
  bemijdate: string;

  /**
   * 예전매입번호
   *
   * **data type** varchar(4)
   */
  bemijnum: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  baldate: string;

  /**
   * 발주번호
   *
   * **data type** varchar(4)
   */
  balnum: string;

  /**
   * 발주구분
   *
   * **data type** varchar(1)
   */
  balflag: string;

  /**
   * 지급처리
   *
   * **data type** varchar(1)
   */
  cancleflag: string;

  /**
   * 출고코드
   *
   * **data type** varchar(10)
   */
  outcd: string;

  /**
   * 그룹지급일자
   *
   * **data type** varchar(8)
   */
  gsnddate: string;

  /**
   * 그룹지급번호
   *
   * **data type** varchar(4)
   */
  gsndnum: string;

  /**
   * 경비증빙여부
   *
   * **data type** varchar(1)
   */
  proof: string;

  /**
   * 프로젝트현장코드
   *
   * **data type** varchar(13)
   */
  proactcd: string;

  /**
   * 설치구분
   *
   * **data type** varchar(1)
   */
  scflag: string;

  /**
   * 설치순번
   *
   * **data type** varchar(5)
   */
  scseq: string;

  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  accdate: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  size: string;

  /**
   * 단위
   *
   * **data type** varchar(50)
   */
  unit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uamt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 카드입력일
   *
   * **data type** varchar(8)
   */
  cardindate: string;

  /**
   * 매입일자
   *
   * **data type** varchar(8)
   */
  datetime: string;

  /**
   * 세액공제
   *
   * **data type** varchar(8)
   */
  taxreclafi: string;

  /**
   * 세액공제명
   *
   * **data type** varchar(8)
   */
  taxrenm: string;

  /**
   * 지급액
   *
   * **data type** varchar(8)
   */
  sndamt: string;

  /**
   * 계정과목명
   *
   * **data type** varchar(8)
   */
  accnm: string;

  /**
   * 지급은행명
   *
   * **data type** varchar(8)
   */
  banknm: string;

  /**
   * 지급은행명2
   *
   * **data type** varchar(8)
   */
  accnum: string;

  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 직책
   *
   * **data type** varchar(10)
   */
  rspnm: string;

  /**
   * 월간연봉급여
   *
   * **data type** varchar(20)
   */
  tot_samt: string;

  /**
   * 공제금액
   *
   * **data type** varchar(20)
   */
  tot_tamt: string;

  /**
   * 실수령액
   *
   * **data type** varchar(20)
   */
  tot_mamt: string;

  /**
   * 기간
   *
   * **data type** varchar(8)
   */
  outdate: string;

  /**
   * 기간
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  outnum: string;

  /**
   * 사업자명
   *
   * **data type** varchar(20)
   */
  spjangnm: string;

  /**
   * 금액
   *
   * **data type** number
   */
  mamt: string;

  /**
   * 제목
   *
   * **data type** varchar(50)
   */
  title: string;

  /**
   * 품명
   *
   * **data type** varchar(50)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** number
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** number
   */
  punit: string;

  /**
   * 번호
   *
   * **data type** number
   */
  inputnum: string;

  /**
   * 제목
   *
   * **data type** varchar(100)
   */
  subject: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  inputdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  balseq: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  inputsabun: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  taxgubun: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  billgubun: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  cltcd_new: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  total3: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  total4: string;

  /**
   * 기성총금액
   *
   * **data type** number
   */
  amt: string;

  /**
   * 순서
   *
   * **data type** number
   */
  seqnm: string;

  /**
   * 매입구분
   *
   * **data type** varchar(10)
   */
  wkgubun: string;

  /**
   * 매입번호
   *
   * **data type** varchar(10)
   */
  misnum: string;

  /**
   * 색상
   *
   * **data type** varchar(1)
   */
  imseq_color: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.misnum = data.misnum || '';
    this.imseq_color = data.imseq_color || '';
    this.amt = data.amt || '';
    this.wkgubun = data.wkgubun || '';
    this.seqnm = data.seqnm || '';
    this.balseq = data.balseq || '';
    this.total3 = data.total3 || '';
    this.total4 = data.total4 || '';
    this.chk = data.chk || '';
    this.cltcd_new = data.cltcd_new || '';
    this.taxgubun = data.taxgubun || '';
    this.billgubun = data.billgubun || '';
    this.inputsabun = data.inputsabun || '';
    this.inputdate = data.inputdate || '';
    this.subject = data.subject || '';
    this.inputnum = data.inputnum || '';
    this.enddate = data.enddate || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.pname = data.pname || '';
    this.mamt = data.mamt || '';
    this.title = data.title || '';
    this.outnum = data.outnum || '';
    this.spjangnm = data.spjangnm || '';
    this.outdate = data.outdate || '';
    this.tot_tamt = data.tot_tamt || '';
    this.tot_mamt = data.tot_mamt || '';
    this.mon = data.mon || '';
    this.pernm = data.pernm || '';
    this.rspnm = data.rspnm || '';
    this.tot_samt = data.tot_samt || '';
    this.cltcd = data.cltcd || '';
    this.sndamt = data.sndamt || '';
    this.datetime = data.datetime || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.accnm = data.accnm || '';
    this.taxrenm = data.taxrenm || '';
    this.mijgubun = data.mijgubun || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.divicd = data.divicd || '';
    this.perid = data.perid || '';
    this.mijamt = data.mijamt || '';
    this.chaamt = data.chaamt || '';
    this.schdate = data.schdate || '';
    this.billkind = data.billkind || '';
    this.taxcls = data.taxcls || '';
    this.monrate = data.monrate || '';
    this.hamt = data.hamt || '';
    this.eamt = data.eamt || '';
    this.enum = data.enum || '';
    this.edate = data.edate || '';
    this.eidate = data.eidate || '';
    this.ecltcd = data.ecltcd || '';
    this.samt = data.samt || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.bankno = data.bankno || '';
    this.damt = data.damt || '';
    this.cardcd = data.cardcd || '';
    this.persent = data.persent || '';
    this.cardno = data.cardno || '';
    this.camt = data.camt || '';
    this.ctaxamt = data.ctaxamt || '';
    this.ggubun = data.ggubun || '';
    this.gamt = data.gamt || '';
    this.tax_spdate = data.tax_spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.remark = data.remark || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.sunflag = data.sunflag || '';
    this.gubun = data.gubun || '';
    this.bhflag = data.bhflag || '';
    this.osflag = data.osflag || '';
    this.cdflag = data.cdflag || '';
    this.jsflag = data.jsflag || '';
    this.yyyymm = data.yyyymm || '';
    this.setcls = data.setcls || '';
    this.acccd = data.acccd || '';
    this.jamt = data.jamt || '';
    this.jmar = data.jmar || '';
    this.bigo = data.bigo || '';
    this.accyn = data.accyn || '';
    this.artcd = data.artcd || '';
    this.taxreclafi = data.taxreclafi || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.cardcltcd = data.cardcltcd || '';
    this.cardcltnm = data.cardcltnm || '';
    this.cltnm = data.cltnm || '';
    this.cltflag = data.cltflag || '';
    this.cardco = data.cardco || '';
    this.cardnm = data.cardnm || '';
    this.cardnum = data.cardnum || '';
    this.ibgdate = data.ibgdate || '';
    this.ibgnum = data.ibgnum || '';
    this.jflag = data.jflag || '';
    this.etflag = data.etflag || '';
    this.projno = data.projno || '';
    this.actcd = data.actcd || '';
    this.gmijdate = data.gmijdate || '';
    this.gmijnum = data.gmijnum || '';
    this.amtflag = data.amtflag || '';
    this.projnm = data.projnm || '';
    this.actnm = data.actnm || '';
    this.inpernm = data.inpernm || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.artnm = data.artnm || '';
    this.imseq = data.imseq || '';
    this.bemijdate = data.bemijdate || '';
    this.bemijnum = data.bemijnum || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balflag = data.balflag || '';
    this.cancleflag = data.cancleflag || '';
    this.outcd = data.outcd || '';
    this.gsnddate = data.gsnddate || '';
    this.gsndnum = data.gsndnum || '';
    this.proof = data.proof || '';
    this.proactcd = data.proactcd || '';
    this.scflag = data.scflag || '';
    this.scseq = data.scseq || '';
    this.seq = data.seq || '';
    this.accdate = data.accdate || '';
    this.size = data.size || '';
    this.unit = data.unit || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.tamt = data.tamt || '';
    this.cardindate = data.cardindate || '';
    this.sunamt = data.sunamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
