import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { BenefitItemTemplate } from './BenefitItem.template';
import { BenefitItemModel } from './BenefitItem.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface BenefitItemState {
  data: Array<BenefitItemModel>;
  focused?: BenefitItemModel;
  focusIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_basic
 * @category 급여항목등록
 */
export class BenefitItem extends PageComponent<PageProps, BenefitItemState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  newChk: string | undefined;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      data: [],
      focusIndex: 0,
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
    this.newChk = '0';
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new BenefitItemModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(3, 2);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['pay_nm', 'pay_cd'],
        [this.state.focused?.pay_nm, this.state.focused?.pay_cd], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 2);
        this.onRowFocusEvent(this.state.data[0], 0);
      }
      this.newChk = '0';
    });
    await this.table.current?.update();
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        ...this.state.data,
        new BenefitItemModel(data, true),
      ],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.data.length - 1, 1);
      this.newChk = '1';
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    let taxflag0Count = 0;
    let taxflag1Count = 0;

    // 과세 비과세 개수 제한.
    this.state.data.forEach((x) => {
      if (x.flag === '0') {
        if (x.taxflag === '0') {
          taxflag0Count += 1;
        } else if (x.taxflag === '1') {
          taxflag1Count += 1;
        }
      }
    });

    if (taxflag0Count > 8) {
      ConfirmWarning.show('확인', '과세항목은 최대 8개까지 입력가능 합니다!');
      return;
    }

    if (taxflag1Count > 5) {
      ConfirmWarning.show('확인', '비과세항목은 최대 5개까지 입력가능 합니다!');
      return;
    }

    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    this.newChk === '1' && await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `급여항목코드: ${this.state.focused?.pay_cd} 급여항목명칭:${this.state.focused?.pay_nm}`;
    await api.delete(text, this.state.focused) && await this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel();
  }

  @action
  onRowFocusEvent(item: BenefitItemModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<BenefitItemModel>, updatedRows: Array<BenefitItemModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <BenefitItemTemplate
        scope={this}
      />
    );
  }
}
