/**
 * 모델
 * @window w_pa100
 * @category 제증명발행
 */
import { computed } from 'mobx';

export class IssueModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  flag: string;

  /**
   * 년도
   *
   * **data type** varchar(4)
   */
  year: string;

  /**
   * 호수
   *
   * **data type** varchar(10)
   */
  num: string;

  /**
   * 사원코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(10)
   */
  pernm: string;

  /**
   * 용도/제출처
   *
   * **data type** char(100)
   */
  suse: string;

  /**
   * 신청일
   *
   * **data type** varchar(8)
   */
  reqdt: string;

  /**
   * 발행일
   *
   * **data type** varchar(8)
   */
  makedt: string;

  /**
   * 발행부수
   *
   * **data type** numeric(2 0)
   */
  makenum: string;

  /**
   * 내용
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 결재
   *
   * **data type** varchar(3)
   */
  appgubun: string;

  /**
   * 상신자
   *
   * **data type** varchar(10)
   */
  appperid: string;

  /**
   * 상신자이름
   *
   * **data type** varchar(10)
   */
  apppernm: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  appremark: string;

  /**
   * 결재번호
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 참초자들
   *
   * **data type** varchar(255)
   */
  refer: string;

  /**
   * 출력 후 사업장 코드
   *
   * **data type** varchar(2)
   */
  pspjangcd: string;

  /**
   * 결재시간
   *
   * **data type** varchar(6)
   */
  apptime: string;

  /**
   * 결재코드
   *
   * **data type** 모름
   */
  com_code: string;

  /**
   * 결재코드이름
   *
   * **data type** 모름
   */
  com_cnam: string;

  /**
   * 부수
   *
   * **data type** 모름
   */
  bu: string;

  /**
   * 직인표시
   *
   * **data type** 모름
   */
  stamp: string;

  /**
   * 주민번호표시
   *
   * **data type** 모름
   */
  rnumflag: string;

  /**
   * 모름
   *
   * **data type** 모름
   */
  projno: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.flag = data.flag || 'A';
    this.year = data.year || '';
    this.num = data.num || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.suse = data.suse || '';
    this.reqdt = data.reqdt || '';
    this.makedt = data.makedt || '';
    this.makenum = data.makenum || '';
    this.remark = data.remark || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.apppernm = data.apppernm || '';
    this.appremark = data.appremark || '';
    this.appnum = data.appnum || '';
    this.refer = data.refer || '';
    this.pspjangcd = data.pspjangcd || '';
    this.apptime = data.apptime || '';
    this.com_code = data.com_code || '';
    this.com_cnam = data.com_cnam || '';
    this.bu = data.bu || '';
    this.stamp = data.stamp || '';
    this.rnumflag = data.rnumflag || '';
    this.projno = data.projno || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
