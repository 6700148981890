import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  IFrame,
  SearchLayout,
  Button,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { MonthAverage } from './MonthAverage';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_da052w_05
 * @category 월평균보수료
 */
export const MonthAverageTemplate: React.FC<TemplateProps<MonthAverage>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
          onSelect={(option) => scope.setState({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <Button
        width={100}
        onClick={() => scope.onRefresh()}
        style={{ fontWeight: 'bold' }}
      >
        새로고침
      </Button>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          headerHeight={60}
          header={[
            {
              id: 'spjangnm',
              text: '사업장명',
              width: 10,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'monamt01',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    01월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt01_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon01_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon01)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt02',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    02월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt02_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon02_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon02)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt03',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    03월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt03_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon03_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon03)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt04',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    04월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt04_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon04_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon04)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt05',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    05월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt05_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon05_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon05)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt06',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    06월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt06_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon06_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon06)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt07',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    07월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt07_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon07_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon07)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt08',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    08월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt08_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon08_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon08)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt09',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    09월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt09_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon09_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon09)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt10',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    10월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt10_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon10_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon10)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt11',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    11월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt11_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon11_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon11)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'monamt12',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout align="start">
                    12월
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    유지보수료
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: '#7F7A2C' }}
                  >
                    평균보수료
                  </FlexLayout>
                  <FlexLayout align="end">
                    관리대수
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 7,
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.averamt12_tot}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mon12_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.monamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.averamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mon12)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={55}
        />
      </FlexLayout>

      <FlexLayout weight={0.5}>
        <IFrame src={scope.graph} />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
