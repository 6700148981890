import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e511
 * @category 당직계획등록
 */
export class PlanEnrollModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly pernm: string;

  /**
   *
   * 당직 / 통신 / 상주
   * **data type** varchar(0)
   */
  readonly flag: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly plandate: string;

  /**
   *
   * 계획 / 완료
   * **data type** varchar(0)
   */
  readonly gubun: string;

  /**
   *
   * 계획 / 완료
   * **data type** varchar(0)
   */
  readonly seq: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.flag = data.flag || '';
    this.plandate = data.plandate || '';
    this.gubun = data.gubun || '1';
    this.seq = data.seq || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
