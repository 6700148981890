import { computed } from 'mobx';

/**
 * 첨부파일 모델
 * @window w_p2110
 * @category 인사등록
 */
export class FileModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순번
   *
   * **data type** float
   */
  readonly fileseq: string;

  /**
   * 파일명
   *
   * **data type** varchar(255)
   */
  readonly filename: string;

  /**
   * 확장자
   *
   * **data type** varchar(5)
   */
  readonly fileext: string;

  /**
   * 파일경로
   *
   * **data type** varchar(255)
   */
  readonly filepath: string;

  /**
   * 파일이미지
   *
   * **data type** image
   */
  readonly fileimg: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 패스워드
   *
   * **data type** varchar(100)
   */
  readonly passward: string;

  /**
   * 파일크기
   *
   * **data type** float
   */
  readonly filesize: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.fileseq = data.fileseq || '';
    this.filename = data.filename || '';
    this.fileext = data.fileext || '';
    this.filepath = data.filepath || '';
    this.fileimg = data.fileimg || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.title = data.title || '';
    this.passward = data.passward || '';
    this.filesize = data.filesize || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
