import * as React from 'react';
import {
  Button,
  ComboBox, ComboBoxModel, FlexLayout, FormatTextBox, OptionBox, TableLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Unit } from './Unit';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_e611_035_03
 * @category 호기별점검항목등록
 */
export const UnitTemplate: React.FC<TemplateProps<Unit>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span >검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처, 주소로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={205}>
          <span>계약구분</span>
          <ComboBox
            textAlign="center"
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            textAlign="center"
            style={{ marginLeft: 10 }}
            value={scope.state.perid}
            onChange={(value) => scope.setState({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            readonly={true}
            onChange={(value) => scope.setState({ pernm: value })}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => scope.setState({ wkactcd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                scope.setState({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={2}
            value={scope.state.wkactnm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'kcflag',
                text: '유무',
                width: 30,
                render: (x, _, ref) => (
                  <TextBox
                    ref={ref}
                    color={x.kcflag === '0' ? 'var(--color-red)' : 'var(--color-blue)'}
                    value={x.kcflag === '0' ? 'X' : 'O'}
                    textAlign="center"
                  />
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x, _, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.actnm}
                    textAlign="left"
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 50,
                render: (x, _, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.equpnm}
                    textAlign="center"
                  />
                ),
              },
              {
                id: 'evcd',
                text: '구분',
                width: 150,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.evcd}
                    data={scope.state.evcds.map((y) => new ComboBoxModel(y.evlcd, y.evlnm))}
                    onSelect={(option) => rowUpdate({ evcd: option.value })}
                  />
                ),
              },
              {
                id: 'elno',
                text: '승강기번호',
                width: 60,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-###"
                    value={x.elno}
                  />
                ),
              },
            ]}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.unitList}
            onChange={(rows, updatedRows) => { scope.onUpdatedRows(rows, updatedRows); }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchLayout
              isSub={true}
            >
              <FlexLayout>
                <span style={{ marginLeft: -1 }}>검색어</span>
                <TextBox
                  value={scope.state.tableSearchQuery}
                  onChange={(value) => scope.setState({ tableSearchQuery: value })}
                  onEnter={() => scope.searchTable()}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            </SearchLayout>
              <Button onClick={() => scope.createJournal()}>
                점검일지생성
              </Button>
          </FlexLayout>

          <TableLayout
            ref={scope.detailTable}
            header={[
              {
                id: 'resultcd',
                text: 'No',
                width: 50,
                render: (x, _) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{
                      backgroundColor: x.gubun === '1' ? '#5f6d7b' : '',
                      color: x.gubun === '1' ? '#FFFFFF' : '',
                    }}
                  >
                    {(x.seq === '0' || x.seq === '1') && x.resultcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'resultnm',
                text: '검사항목, 장치',
                width: 200,
                render: (x, _) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{
                      backgroundColor: x.gubun === '1' ? '#5f6d7b' : '',
                      color: x.gubun === '1' ? '#FFFFFF' : '',
                    }}
                  >
                    {x.resultnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'resultdtlnm',
                text: '세부사항',
                width: 200,
                render: (x, _) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{
                      backgroundColor: x.gubun === '1' ? '#5f6d7b' : '',
                      color: x.gubun === '1' ? '#FFFFFF' : '',
                    }}
                  >
                    {x.resultdtlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'evmon',
                text: '주기',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      backgroundColor: x.gubun === '1' ? '#5f6d7b' : '',
                      color: x.gubun === '1' ? '#FFFFFF' : '',
                    }}
                  >
                    {x.resultdtlnm && `${x.evmon}개월`}
                  </FlexLayout>
                ),
              },
              {
                id: 'state',
                text: '상태',
                width: 140,
                render: (x, rowUpdate) => (
                  x.gubun === '1'
                    ? <FlexLayout
                      style={{
                        backgroundColor: x.gubun === '1' ? '#5f6d7b' : '',
                        color: x.gubun === '1' ? '#FFFFFF' : '',
                      }}
                    />
                    : <OptionBox
                    value={x.state}
                    data={[
                      { value: 'A', remark: '있음' },
                      { value: 'E', remark: '없음' },
                    ]}
                    onChange={(item) => rowUpdate({ state: item.value })}
                  />
                ),
              },
            ]}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.unitDetailList}
            onChange={(rows2, updatedRows2) => { scope.onUpdatedDetailRows(rows2, updatedRows2); }}
            onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
);
