import * as React from 'react';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  SelfCertificate,
  SelfCertificateTabDataStateNames,
  SelfCertificateTabFocusedStateNames,
} from './SelfCertificate';
import { Global, TemplateProps } from '../../../../constants';


/**
 * 화면
 * @window w_pa108w
 * @category 자체점검자격현황
 */
export const SelfCertificateTemplate: React.FC<TemplateProps<SelfCertificate>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value })}
          />
        </FlexLayout>
        <FlexLayout weight={0.7}>
          <span>자격만료월</span>
          <DateTextBox
            textAlign="center"
            value={scope.state.stmon}
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            format="####-##"
          />
        </FlexLayout>
        <FlexLayout weight={0.7}>
          <span>자체점검</span>
          <ComboBox
            value={scope.state.checkflag}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '자체점검' },
              { value: '2', remark: '없음' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ checkflag: option.value })}
          />
        </FlexLayout>
        <FlexLayout weight={4}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="성명, 자격종류로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout>
        <GridLayout
          // @ts-ignore
          ref={scope.grid}
          scope={scope}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[SelfCertificateTabDataStateNames[scope.tabIndex]] || []}
          onRowClick={(item) => setData({
            [SelfCertificateTabFocusedStateNames[scope.tabIndex]]: item,
          })}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
