import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Collect } from '../Collect';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 > 발주내역 - 그리드 레이아웃
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTabBalGridHeader: GLHF<any, Collect> = (scope) => ([
  {
    id: 'baldate',
    text: '발주일자',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.baldate)}
      </FlexLayout>
    ),
  },
  {
    id: 'balnum',
    text: '번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.balnum}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '발주처',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '제목',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataBal, 'samt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataBal, 'tamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '발주금액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataBal, 'mamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'bigo',
    text: '납품장소',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.bigo}
      </FlexLayout>
    ),
  },
]);
