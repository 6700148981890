/**
 * 모델
 * @window w_td_da023_import
 * @category 매출등록[국세청]
 */
import { computed } from 'mobx';

export class SalesNationalTaxServiceModel {
  /**
   * 거래처코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  rmisdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  rmisnum: string;

  /**
   * 0
   *
   * **data type** varchar(1)
   */
  misgubun: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 회사구분코드
   *
   * **data type** varchar(3)
   */
  wkactcd: string;

  /**
   * 계약금액
   *
   * **data type** float
   */
  contamt: string;

  /**
   * 구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 년월
   *
   * **data type** varchar(6)
   */
  yyyymm: string;

  /**
   * 회계적용
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 부가세
   *
   * **data type** float
   */
  addamt: string;

  /**
   * 합계
   *
   * **data type** float
   */
  misamt: string;

  /**
   * 차이금액
   *
   * **data type** float
   */
  chaamt: string;

  /**
   * 지급예정일
   *
   * **data type** varchar(8)
   */
  schdate: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  taxcls: string;

  /**
   * 과세구분
   *
   * **data type** varchar(3)
   */
  taxgubun: string;

  /**
   * 지로구분
   *
   * **data type** char(1)
   */
  jirogubun: string;

  /**
   * 현금지급
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** float
   */
  eamt: string;

  /**
   * 어음번호
   *
   * **data type** varchar(12)
   */
  enum: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  edate: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  eidate: string;

  /**
   * 어음거래처
   *
   * **data type** varchar(13)
   */
  ecltcd: string;

  /**
   * 금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 예금금액
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 은행코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 카드금액
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드은행
   *
   * **data type** varchar(10)
   */
  cardcd: string;

  /**
   * 카드효율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 카드번호
   *
   * **data type** varchar(16)
   */
  cardno: string;

  /**
   * 기타구분
   *
   * **data type** varchar(5)
   */
  ggubun: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 지로금액
   *
   * **data type** float
   */
  jamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 지로번호
   *
   * **data type** varchar(13)
   */
  jnum: string;

  /**
   * 지로은행
   *
   * **data type** varchar(8)
   */
  jbankcd: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  dcamt: string;

  /**
   * 지로번호
   *
   * **data type** varchar(10)
   */
  pubnum: string;

  /**
   * 계산서일자
   *
   * **data type** varchar(8)
   */
  tax_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  tax_spnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 선납구분
   *
   * **data type** char(1)
   */
  sunflag: string;

  /**
   * 선납금액
   *
   * **data type** float
   */
  sunamt: string;

  /**
   * 선납채크
   *
   * **data type** char(1)
   */
  sunchk: string;

  /**
   * 발행구분
   *
   * **data type** char(1)
   */
  billgubun: string;

  /**
   * 공사구분
   *
   * **data type** char(1)
   */
  compflag: string;

  /**
   * 공사일자
   *
   * **data type** varchar(8)
   */
  compdate: string;

  /**
   * 공사번호
   *
   * **data type** varchar(3)
   */
  compnum: string;

  /**
   * 출고구분
   *
   * **data type** char(1)
   */
  delflag: string;

  /**
   * 출고일자
   *
   * **data type** varchar(8)
   */
  deldate: string;

  /**
   * 출고번호
   *
   * **data type** varchar(4)
   */
  delnum: string;

  /**
   * 계정과목
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 지로월
   *
   * **data type** varchar(1)
   */
  ungijun: string;

  /**
   * 지로구분
   *
   * **data type** varchar(1)
   */
  jirochk: string;

  /**
   * 지로납기일
   *
   * **data type** varchar(8)
   */
  jirodate: string;

  /**
   * 예전미수일자
   *
   * **data type** varchar(8)
   */
  bemisdate: string;

  /**
   * 예전미수번호
   *
   * **data type** varchar(4)
   */
  bemisnum: string;

  /**
   * 예금수수료
   *
   * **data type** float
   */
  bmar: string;

  /**
   * 입고일자
   *
   * **data type** varchar(8)
   */
  ibgdate: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(13)
   */
  emcltcd: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(13)
   */
  emactcd: string

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emactcd2: string

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emseq: string

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emtaxbillno: string

  /**
   * 프로젝트번호
   *
   * **data type** varchar(10)
   */
  projno: string

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string

  /**
   * 회사구분
   *
   * **data type** varchar(100)
   */
  wkactnm: string

  /**
   * 부서명
   *
   * **data type** varchar(100)
   */
  divinm: string

  /**
   * 성명
   *
   * **data type** varchar(100)
   */
  pernm: string

  /**
   * 프로젝트명
   *
   * **data type** varchar(255)
   */
  projnm: string

  /**
   * 지로출력채크
   *
   * **data type** varchar(1)
   */
  prtchk: string

  /**
   * 취소여부
   *
   * **data type** varchar(1)
   */
  cancleflag: string

  /**
   * 도장
   *
   * **data type** varchar(1)
   */
  stamp: string

  /**
   * 현대금액
   *
   * **data type** float
   */
  hdamt: string

  /**
   * 프로젝트현장코드
   *
   * **data type** varchar(13)
   */
  proactcd: string

  /**
   * 설치선급금구분
   *
   * **data type** varchar(1)
   */
  scstflag: string

  /**
   * 설치선급일자
   *
   * **data type** varchar(8)
   */
  scstdate: string

  /**
   * 설치중도구분
   *
   * **data type** varchar(1)
   */
  scmidflag: string

  /**
   * 설치중도일자
   *
   * **data type** varchar(8)
   */
  scmiddate: string

  /**
   * 설치완료구분
   *
   * **data type** varchar(1)
   */
  scendflag: string

  /**
   * 설치완료일자
   *
   * **data type** varchar(8)
   */
  scenddate: string

  /**
   * 설치계약일자
   *
   * **data type** varchar(8)
   */
  orddate: string

  /**
   * 설치계약번호
   *
   * **data type** varchar(4)
   */
  ordnum: string

  /**
   * 스위치
   *
   * **data type** varchar(1)
   */
  flag: string

  /**
   * 현대다른거비고
   *
   * **data type** varchar(255)
   */
  hd_diff_remark: string

  /**
   * 지로도장
   *
   * **data type** varchar(1)
   */
  jiro_stamp: string

  /**
   * 세금계산서_다이렉트
   *
   * **data type** varchar(3)
   */
  tax_direct: string

  /**
   * 비고2
   *
   * **data type** varchar(255)
   */
  bigo2: string

  /**
   * 예금연결
   *
   * **data type** varchar(50)
   */
  imseq: string

  /**
   * 지로출력순번
   *
   * **data type** float
   */
  giro_prtseq: string

  /**
   * 선택
   *
   * **data type** float
   */
  chk: string

  accdate: string

  /**
   * 비고
   *
   * **data type** float
   */
  remark: string

  /**
   * x
   *
   * **data type** float
   */
  bhflag: string

  /**
   * 사이즈
   *
   * **data type** float
   */
  size: string

  /**
   * x
   *
   * **data type** float
   */
  qty: string

  /**
   * x
   *
   * **data type** float
   */
  uamt: string

  /**
   * x
   *
   * **data type** float
   */
  tamt: string

  /**
   * x
   *
   * **data type** float
   */
  totsamt: string

  /**
   * x
   *
   * **data type** float
   */
  tottamt: string

  /**
   * 승인번호
   *
   * **data type** float
   */
  seq: string

  /**
   * x
   *
   * **data type** float
   */
  receiptyn: string

  /**
   * 삭제
   *
   * **data type** float
   */
  delchk: string

  /**
   * 매출여부
   *
   * **data type** varchar(10)
   */
  misflag: string

  /**
   * 총금액
   *
   * **data type** float
   */
  totmamt: string

  /**
   * 사업자번호
   *
   * **data type** varchar(10)
   */
  saupnum: string

  /**
   * 대표자명
   *
   * **data type** varchar(20)
   */
  prenm: string

  /**
   * 메일
   *
   * **data type** varchar(30)
   */
  taxmail: string

  /**
   * 종류
   *
   * **data type** varchar(10)
   */
  gubun2: string

  /**
   * -
   *
   * **data type** varchar(30)
   */
  rtax_spdate: string

  /**
   * -
   *
   * **data type** varchar(10)
   */
  rtax_spnum: string

  /**
   * -
   *
   * **data type** varchar(10)
   */
  recivstatus: string

  /**
   * -
   *
   * **data type** varchar(10)
   */
  misflag2: string

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = (data.spjangcd || '').trim();
    this.totmamt = data.totmamt || '';
    this.misflag2 = data.misflag2 || '';
    this.recivstatus = data.recivstatus || '';
    this.rtax_spdate = data.rtax_spdate || '';
    this.rtax_spnum = data.rtax_spnum || '';
    this.misflag = data.misflag || '';
    this.saupnum = data.saupnum || '';
    this.prenm = data.prenm || '';
    this.taxmail = data.taxmail || '';
    this.gubun2 = data.gubun2 || '';
    this.qty = data.qty || '';
    this.delchk = data.delchk || '';
    this.size = data.size || '';
    this.bhflag = data.bhflag || '';
    this.remark = data.remark || '';
    this.accdate = data.accdate || '';
    this.chk = data.chk || '';
    this.receiptyn = data.receiptyn || '';
    this.seq = data.seq || '';
    this.tottamt = data.tottamt || '';
    this.totsamt = data.totsamt || '';
    this.tamt = data.tamt || '';
    this.uamt = data.uamt || '';
    this.cltcd = data.cltcd || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.rmisdate = data.rmisdate || '';
    this.rmisnum = data.rmisnum || '';
    this.misgubun = data.misgubun || '';
    this.actcd = data.actcd || '';
    this.wkactcd = data.wkactcd || '';
    this.contamt = data.contamt || '';
    this.gubun = data.gubun || '';
    this.yyyymm = data.yyyymm || '';
    this.accyn = data.accyn || '';
    this.divicd = data.divicd || '';
    this.perid = data.perid || '';
    this.addamt = data.addamt || '';
    this.misamt = data.misamt || '';
    this.chaamt = data.chaamt || '';
    this.schdate = data.schdate || '';
    this.billkind = data.billkind || '';
    this.taxcls = data.taxcls || '';
    this.taxgubun = data.taxgubun || '';
    this.jirogubun = data.jirogubun || '';
    this.hamt = data.hamt || '';
    this.eamt = data.eamt || '';
    this.enum = data.enum || '';
    this.edate = data.edate || '';
    this.eidate = data.eidate || '';
    this.ecltcd = data.ecltcd || '';
    this.samt = data.samt || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.damt = data.damt || '';
    this.cardcd = data.cardcd || '';
    this.persent = data.persent || '';
    this.cardno = data.cardno || '';
    this.ggubun = data.ggubun || '';
    this.gamt = data.gamt || '';
    this.jamt = data.jamt || '';
    this.jmar = data.jmar || '';
    this.jnum = data.jnum || '';
    this.jbankcd = data.jbankcd || '';
    this.dcamt = data.dcamt || '';
    this.pubnum = data.pubnum || '';
    this.tax_spdate = data.tax_spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.bigo = data.bigo || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.sunflag = data.sunflag || '';
    this.sunamt = data.sunamt || '';
    this.sunchk = data.sunchk || '';
    this.billgubun = data.billgubun || '';
    this.compflag = data.compflag || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.delflag = data.delflag || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.acccd = data.acccd || '';
    this.ungijun = data.ungijun || '';
    this.jirochk = data.jirochk || '';
    this.jirodate = data.jirodate || '';
    this.bemisdate = data.bemisdate || '';
    this.bemisnum = data.bemisnum || '';
    this.bmar = data.bmar || '';
    this.ibgdate = data.ibgdate || '';
    this.emcltcd = data.emcltcd || '';
    this.emactcd = data.emactcd || '';
    this.emactcd2 = data.emactcd2 || '';
    this.emseq = data.emseq || '';
    this.emtaxbillno = data.emtaxbillno || '';
    this.projno = data.projno || '';
    this.actnm = data.actnm || '';
    this.cltnm = data.cltnm || '';
    this.wkactnm = data.wkactnm || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.projnm = data.projnm || '';
    this.prtchk = data.prtchk || '';
    this.cancleflag = data.cancleflag || '';
    this.stamp = data.stamp || '';
    this.hdamt = data.hdamt || '';
    this.proactcd = data.proactcd || '';
    this.scstflag = data.scstflag || '';
    this.scstdate = data.scstdate || '';
    this.scmidflag = data.scmidflag || '';
    this.scmiddate = data.scmiddate || '';
    this.scendflag = data.scendflag || '';
    this.scenddate = data.scenddate || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.flag = data.flag || '';
    this.hd_diff_remark = data.hd_diff_remark || '';
    this.jiro_stamp = data.jiro_stamp || '';
    this.tax_direct = data.tax_direct || '';
    this.bigo2 = data.bigo2 || '';
    this.imseq = data.imseq || '';
    this.giro_prtseq = data.giro_prtseq || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
