import * as React from 'react';
// @ts-ignore
import { TransitionMotion, spring } from '@serprex/react-motion';
import {
  AiOutlineClose,
  AiTwotoneStar,
} from 'react-icons/ai';
import style from './HeaderTabView.module.scss';
import { TabModel } from '../../../models';
import { JoinClassName } from '../../../utils/string';
import { DefaultLayout } from '../../layout';
import { FavoriteMenuStore } from '../../../stores';

interface HeaderTabViewActions {
  onChange: (tab: TabModel) => any;
  onRemove?: (tab: TabModel, index: number) => any;
  onStar?: (tab: TabModel) => any;
}

interface HeaderTabViewProps extends HeaderTabViewActions {
  tabs: TabModel[];
  isRemovable?: boolean;
  focused?: TabModel;
  favoriteMenuStore: FavoriteMenuStore;
}

const defaultSpring = spring(0, { stiffness: 80, damping: 15 });

const willLeave = () => ({
  paddingLeft: 0,
  paddingRight: 0,
  width: defaultSpring,
  minWidth: defaultSpring,
  translateX: spring(-218, { stiffness: 50, damping: 15 }),
  opacity: defaultSpring,
  opacity2: 0,
});

export class HeaderTabView extends React.Component<HeaderTabViewProps> {
  render() {
    const {
      favoriteMenuStore,
      tabs,
      isRemovable,
      focused,
      onChange,
      onRemove,
      onStar,
    } = this.props;

    const hasFocused = focused !== undefined;

    return (
      <TransitionMotion
        willLeave={willLeave}
        styles={tabs.map((item, i) => ({
          key: item.id,
          style: {
            paddingLeft: 12,
            paddingRight: 12,
            width: 218,
            minWidth: 218,
            translateX: 0,
            opacity: 1,
            opacity2: 1,
            zIndex: 1000 - i,
          },
          data: item,
        }))}
      >
        {(interpolatedStyles: any[]) => (
          <DefaultLayout className={style.container} isScroll={true}>
            {interpolatedStyles.map((item, i) => {
              const x = item.data;
              return (
                <div
                  key={x.id}
                  style={{
                    paddingLeft: item.style.paddingLeft,
                    paddingRight: item.style.paddingRight,
                    width: item.style.width,
                    minWidth: item.style.minWidth,
                    opacity: item.style.opacity,
                    transform: `translateX(${item.style.translateX}px)`,
                    zIndex: item.style.zIndex,
                  }}
                  className={JoinClassName.make([
                    style.tab,
                    // eslint-disable-next-line no-nested-ternary, max-len
                    hasFocused && x.id === focused?.id ? style.active : x.isEnabled ? style.active : false,
                  ])}
                  onClick={(e) => {
                    // @ts-ignore
                    if (e.target.className.indexOf('closer') > 0) {
                      // @ts-ignore
                    } else if (e.target.className.indexOf('star') > 0) {
                      //
                    } else {
                      onChange(x);
                    }
                  }}
                >
                  <div
                    className={style.tab__cover}
                    style={{
                      opacity: item.style.opacity2,
                    }}
                  >
                    <x.icon />

                    <p>{x.text}</p>

                    <div
                      className={JoinClassName.make([style.star, 'star'])}
                      onClick={() => onStar && onStar(x)}
                    >
                      <AiTwotoneStar
                        size={20}
                        color={favoriteMenuStore.isActive(x.id) ? '#F6B924' : '#ABABAB'}
                      />
                    </div>

                    {isRemovable && <div
                      className={JoinClassName.make([style.close, 'closer'])}
                      onClick={() => onRemove && onRemove(x, i)}
                    >
                      <AiOutlineClose />
                    </div>}
                  </div>
                </div>
              );
            })}
          </DefaultLayout>
        )}
      </TransitionMotion>
    );
  }
}
