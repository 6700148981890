import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { JiroModel } from './Jiro.model';
import { JiroTemplate } from './Jiro.template';
import { PageComponent } from '../../../../utils';

/**
 * 컨트롤러
 * @window w_jirosetup
 * @category 지로번호등록
 */
export class Jiro extends PageComponent<PageProps, JiroModel> implements PageToolEvents {
  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    this.state = props.state || new JiroModel();
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve();
    data && this.setState(new JiroModel(data));
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save(
      this.state,
      false,
    );
  }

  render() {
    return (
      <JiroTemplate
        scope={this}
      />
    );
  }
}
