/**
 * 모델
 * @window w_popup_e501w_01 점검시계약
 * @category 현장방문숙지리스트
 */

export class InspectionContractModalModel {
  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contgubun: string;

  /**
   * 만료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 점검계획일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 계약분류
   *
   * **data type** varchar(8)
   */
  readonly contg: string;

  /**
   *  상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.contgubun = data.contgubun || '';
    this.enddate = data.enddate || '';
    this.plandate = data.plandate || '';
    this.contg = data.contg || '';
    this.state = data.state || '';
  }
}
