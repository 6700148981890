import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdAvTimer,
  MdWork,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PeriodModel, DepartmentModel } from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { EndContractTemplate } from './EndContract.template';
import { InfinityRetrieve } from '../../../../models/common';
import { TabHeaderPeriod, TabHeaderDepartment } from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { HistoryPopupModel } from '../MonthContract/HistoryPopup.model';
import { TableLayout } from '../../../../components';
import { Date6Calculator } from '../../../../utils/time';
import { PageComponent } from '../../../../utils';


export enum EndContractTabId {
  Period,
  Department,
}

export const EndContractTabTitles = [
  '기간별',
  '부서별',
];

export const EndContractTabModels = [
  PeriodModel,
  DepartmentModel,
];

export const EndContractTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const EndContractTabDataStateNames = [
  'Periods',
  'Departments',
];

export const EndContractTabUpdatesStateNames = [
  'PeriodUpdates',
  'DepartmentUpdates',
];

export const EndContractTabFocusedStateNames = [
  'PeriodUpdatesFocused',
  'DepartmentUpdatesFocused',
];

export interface EndContractState {
  // 기간별
  Periods?: Array<PeriodModel>;
  PeriodUpdates?: Array<PeriodModel>;
  PeriodsFocused?: PeriodModel;

  // 부서별
  Departments?: Array<DepartmentModel>;
  DepartmentUpdates?: Array<DepartmentModel>;
  DepartmentsFocused?: DepartmentModel;

  focusedTab?: TabModel;
  stdate?: string;
  enddate?: string;
  searchQuery?: string;
  month3?: string;
  gubun?: string;
  perid?: string;
  pernm?: string;
  divicd?: string;
  divinm?: string;

  wkactcds?: Array<any>;
  deljugis?: Array<any>;
  contgs?: Array<any>;
  contgubuns?: Array<any>;
  gubuns?: Array<any>;
  taxgubuns?: Array<any>;
  bildyds?: Array<any>;

  // 계약기록 모달
  historyFocusedData?: HistoryPopupModel;
  historyData?: Array<HistoryPopupModel>;
  historyModal?: boolean;
  remark?: string;

  // trail
  amt_tot?: string;
  equpamt_tot?: string;
  monamt_tot?: string;
  nowamt_tot?: string;
  qty_tot?: string;
  total?: string;
}

/**
 * 컨트롤러
 * @window w_tb_e601w
 * @category 계약만료된현장
 */
export class EndContract extends PageComponent<PageProps, EndContractState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  tableHistory: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinityHistory?: InfinityRetrieve;

  updatedRows2?: Array<HistoryPopupModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = EndContractTabId;
    const titles = EndContractTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    this.tabs = [
      new TabModel(id.Period.toString(), MdAvTimer, titles[id.Period]),
      new TabModel(id.Department.toString(), MdWork, titles[id.Department]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    const lastDay: string | number = new Date(today.getFullYear(),
      today.getMonth() + 1, 0).getDate().toString();

    // state 기본값 정의
    this.state = props.state || {
      stdate: '',
      enddate: `${year}${month}${lastDay}`,
      divicd: bosuDivicd || '',
      duvinm: bosuDivinm || '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      gubun: '1',
      month3: '0',
      searchQuery: '',
      data: [],
      remark: '',

      // trail
      amt_tot: '0',
      equpamt_tot: '0',
      monamt_tot: '0',
      qty_tot: '0',
      total: '0',
    };

    this.grid = React.createRef();

    this.tabHeaders = [
      // 기간별
      TabHeaderPeriod,

      // 부서별
      TabHeaderDepartment,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 회사구분 리스트

    let data = [];

    data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    this.setState({ wkactcds: data.items });

    data = await api.dropdown('wf_dd_ca510_007_01');
    if (!data) return;
    this.setState({ deljugis: data.items });

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    // 매출 구분
    data = await api.dropdown('wf_dd_da020');
    if (!data) return;
    this.setState({ gubuns: data?.items || [] });

    // 건물용도 리스트 가져오기
    data = await api.dropdown('wf_dd_e029');
    this.setState({ bildyds: data?.items || [] });

    // 세금 구분
    data = await api.dropdown('wf_dd_ca510_006_01');
    this.setState({
      taxgubuns: data.items,
    });
    if (!data) return;

    await this.onTabChange(this.tabs[EndContractTabId.Period]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab) {
      this.setState({
        [EndContractTabUpdatesStateNames[i]]: [],
        [EndContractTabFocusedStateNames[i]]: undefined,
      });
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: EndContractTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
        month3: this.state.month3,
        as_nm: this.state.searchQuery,
      },
      (params) => api.fxExec(
        `${EndContractTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [EndContractTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[EndContractTabDataStateNames[i]],
                ...items.map((x: any) => new EndContractTabModels[i](x))],
          });
        }
      },
      async () => {
        await this.SS({
          [EndContractTabDataStateNames[i]]: [],
          [EndContractTabUpdatesStateNames[i]]: [],
          [EndContractTabFocusedStateNames[i]]: undefined,
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [EndContractTabDataStateNames[i]]: [],
      [EndContractTabUpdatesStateNames[i]]: [],
      [EndContractTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.infinity?.retrieveAll();
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        amt_tot: data?.amt_tot || '0',
        equpamt_tot: data?.equpamt_tot || '0',
        monamt_tot: data?.monamt_tot || '0',
        nowamt_tot: data?.nowamt_tot || '0',
        qty_tot: data?.qty_tot || '0',
      });
      this.grid.current?.setFocus(0);
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[EndContractTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${EndContractTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onDataChange() {
    const today = new Date();
    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    const Nstdate = `${year}${month}01`;

    const test = new Date6Calculator(this.state.enddate)
      .add(3).toString() + this.state.enddate?.slice(6, 8);

    this.setState({ stdate: Nstdate, enddate: test }, async () => {
      await this.onRetrieveEvent();
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[EndContractTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxExcel(
      `${EndContractTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async historyPopupModal(isOpen: boolean) {
    setTimeout(() => this.historyRetrieve(), 50);
    this.setState({ historyModal: isOpen });
  }

  @action
  async historyRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화

    this.infinityHistory = new InfinityRetrieve(
      {
        sub: 'w_tb_e101_history',
        // @ts-ignore
        actcd: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          historyData: [...items],
        });
      },
      async () => {
        await this.SS({
          historyData: [],
        });
        await this.infinityHistory?.retrieveAll();
        await this.tableHistory.current?.update(false);
        await this.tableHistory.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      historyData: [],
    }, async () => {
      await this.infinityHistory?.retrieveAll();
      await this.tableHistory.current?.update(false);
      await this.tableHistory.current?.setFocus(0, 1);
    });
  }

  @action
  async onNewHistoryEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e101_history',
      // @ts-ignore
      actcd: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
    });

    const newModel = new HistoryPopupModel(data, true);

    if (data) {
      this.setState({
        historyData: [...this.state.historyData || [], data],
        historyFocusedData: newModel,
      }, () => this.tableHistory.current?.update(false));
      // @ts-ignore
      this.tableHistory.current?.setFocus(this.state.historyData?.length - 1, 1);
    }
  }

  @action
  onClickOpenEnrollment() {
    this.props.publicStore.go(
      '/w_tb_e601_new',
      {
        // @ts-ignore
        searchQuery: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
      },
    );
  }

  @action
  async onHistorySaveEvent() {
    const { actionStore: api } = this.props;
    const params = [{
      new: this.state.historyFocusedData?.new,
      actcd: this.state.historyFocusedData?.actcd,
      actnm: this.state.historyFocusedData?.actnm,
      seq: this.state.historyFocusedData?.seq,
      perid: this.state.historyFocusedData?.perid,
      pernm: this.state.historyFocusedData?.pernm,
      hdate: this.state.historyFocusedData?.hdate,
      title: this.state.historyFocusedData?.title,
      remark: this.state.remark,
      wakeflag: '',
      wakedate: this.state.historyFocusedData?.wakedate,
      okflag: this.state.historyFocusedData?.okflag,
    }];

    const data = await api.save({
      sub: 'w_tb_e101_history',
      items: params,
    }, true);
    if (data) {
      this.setState({

      }, () => this.historyRetrieve());
    }
  }

  @action
  async historyDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e101_history',
      // @ts-ignore
      actcd: this.state[EndContractTabFocusedStateNames[this.tabIndex]]?.actcd,
      seq: this.state.historyFocusedData?.seq,
    }) && await this.historyRetrieve();

    this.updatedRows2 = [];
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onRowFocusEvent2(item:HistoryPopupModel) {
    await this.setState({
      historyFocusedData: item,
      remark: item.remark,
    });
  }

  @computed
  get tabIndex(): EndContractTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [EndContractTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [EndContractTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<HistoryPopupModel>, updatedRows2: Array<HistoryPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ historyData: rows2 });
  }

  render() {
    return (
      <EndContractTemplate
        scope={this}
        update={(state) => this.setState(state)}
      />
    );
  }
}
