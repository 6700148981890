import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  BreakReportDetailModel,
  BreakReportModel,
} from './models';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { BreakReportTemplate } from './BreakReport.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  FileReader,
  FileSelector,
} from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { PopupModel } from './models/PopupModel';
import { PageComponent } from '../../../../utils';
import {
  DateStabilizer,
} from '../../../../utils/time';

export enum BreakReportImages {
  SEARCH,
  ACT,
}

export enum BreakReportButtonClickedTypes {
  cancle,
  cancle1,
  cancle2,
  cancle3
}

export enum BreakReportButtonClickedTypeNames{
  b_cancle,
  b_cancle1,
  b_cancle2,
  b_cancle3
}

export enum BreakReportItemChangeTypes {
  COMPDATE,
  ARRIVTIME,
  COMPTIME,
  PERID,
  PERID2,
  ACTCD,
}

export enum BreakReportItemChangeTypeNames {
  compdate,
  arrivtime,
  comptime,
  perid,
  perid2,
  actcd,
}

export const imgFunctionNames = [
  'tb_pic',
  'tb_pic2',
];

export const imgStateNames = [
  'img_search',
  'img_act',
];

export const imgStatePercentNames = [
  'percentImageSearch',
  'percentImageAct',
];


interface BreakReportState {

  // 검색조건
  gubun: string; // 구분
  stdate: string; // 기간
  enddate: string; // 기간
  searchQuery: string; // 검색어
  perid: string; // 담당자 사번
  pernm: string; // 담당자 이름
  trouble: boolean; // 사람갇힘
  divicd: string; // 부서코드
  divinm: string; // 부서명
  cd: string;
  cdnm: string;

  // 사진
  img?: ArrayBuffer;
  percentImage?: number;
  img2?: ArrayBuffer;

  // 총계
  total: string;

  // 데이터 객체

  // 포커싱 데이터
  focusedBreakReport?: BreakReportModel;
  focusedDetailBreakReport: BreakReportDetailModel;
  lastSaveData: BreakReportDetailModel;

  // 테이블 데이터 리스트
  BreakReportList: Array<BreakReportModel>;

  // 구분종류
  wkcds: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  percentImageSearch?: number;
  percentImageAct?: number;

  //  다발고장 팝업 관련
  popupModal: boolean;
  focusedPopupData?:PopupModel;
  popupData?: Array<PopupModel>;

  // 검색조건
  gubunPopup: string; // 구분
  stdatePopup: string; // 기간
  enddatePopup: string; // 기간
  wadcntPopup: string;
  cltcdPopup: string; // 검색어
  divicdPopup: string; // 부서
  divinmPopup: string; // 부서명
  peridPopup: string; // 담당자 사번
  pernmPopup: string; // 담당자 이름

  // 팝업 trail
  cnt_tot: string;
  equpcnt_tot: string;
}

/*
* 컨트롤러
 * @window w_tb_e411_wad
 * @category 고장다발처리보고서
*/

export class BreakReport extends PageComponent<PageProps, BreakReportState>
  implements PageToolEvents {
  updatedRows?: Array<BreakReportModel>;

  updatedRows2?: Array<PopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinityPopup?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    let popupMonth:string | number = today.getMonth(); // 월

    let date:string | number = today.getDate(); // 날짜

    if (month < 10) {
      month = `0${month}`;
    }

    if (popupMonth < 10) {
      popupMonth = `0${popupMonth}`;
    }


    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}31`,
      gubun: '0',
      searchQuery: pageParams.searchQuery || '',
      perid: '%',
      trouble: '0',
      total: '',
      data: new BreakReportDetailModel(),
      breakResisterList: [],
      breakFieldList: [],
      stdatePopup: `${year}${popupMonth}${date}`,
      enddatePopup: `${year}${month}${date}`,
      gubunPopup: '%',
      wadcntPopup: '3',
      cltcdPopup: '',
      peridPopup: '',
      divicdPopup: '',

      divicd: '',
      divinm: '',
      cd: '',
      cdnm: '',

      // trail
      cnt_tot: '0',
      equpcnt_tot: '0',

      wkcds: [],
      isReported: false,
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          BreakReportList: [...this.state.BreakReportList, ...items],
          focusedBreakReport: new BreakReportModel(items),
        }, next);
      },
      async () => {
        await this.SS({
          BreakReportList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.focusedDetailBreakReport) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.focusedDetailBreakReport;
    this.setState({
      BreakReportList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['waddate', 'wadnum'],
        [lastSelected?.waddate, lastSelected?.wadnum], type, true) || 0;
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
      });
      if (this.state.BreakReportList && this.state.BreakReportList.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: BreakReportDetailModel) {
    if (item?.new === '1') {
      this.setState({
        isReported: false,
        focusedDetailBreakReport: this.state.lastSaveData,
        img: new ArrayBuffer(0),
        img2: new ArrayBuffer(0),
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      { focusedDetailBreakReport: item },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            waddate: this.state.focusedDetailBreakReport?.waddate,
            wadnum: this.state.focusedDetailBreakReport?.wadnum,
          });

        await data && this.setState({
          focusedDetailBreakReport: new BreakReportDetailModel(data),
        });

        if (data.items) {
          this.detailGrid.current?.setFocus(0);
        }
        // 결재상신 여부확인
        if (this.state.focusedDetailBreakReport?.appgubun === null
          || this.state.focusedDetailBreakReport?.appgubun === ''
          || this.state.focusedDetailBreakReport?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
    this.imageRequest(item);
    this.imageRequest2(item);
  }

  @action
  async onButtonClicked(type: any) {
    const { actionStore: api } = this.props;

    const params = {
      itemname: BreakReportButtonClickedTypeNames[type],
      data: '',
      recedate: this.state.focusedDetailBreakReport?.recedate,
      new: this.state.focusedDetailBreakReport?.new,
    };

    if (type === BreakReportButtonClickedTypes.cancle) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          perid: this.state.focusedDetailBreakReport?.perid,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }

    if (type === BreakReportButtonClickedTypes.cancle1) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }

    if (type === BreakReportButtonClickedTypes.cancle2) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }

    if (type === BreakReportButtonClickedTypes.cancle3) {
      await api.fxExec(
        'dw_2_buttonclicked',
        {
          ...params,
          actnm: this.state.focusedDetailBreakReport?.actnm,
          equpnm: this.state.focusedDetailBreakReport?.equpnm,
        },
      );
    }
  }

  async imageRequest(item: any) {
    const { actionStore: api } = this.props;

    const blob = await api.fxBinary(
      'tb_pic_retrieve',
      {
        waddate: item?.waddate,
        wadnum: item?.wadnum,
      },
    );

    this.setState({
      img: blob,
    });
  }

  async imageRequest2(item: any) {
    const { actionStore: api } = this.props;

    const blob = await api.fxBinary(
      'tb_pic2_retrieve',
      {
        waddate: item?.waddate,
        wadnum: item?.wadnum,
      },
    );

    this.setState({
      img2: blob,
    });
  }

  @action
  async imageDelete(item: any) {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      'tb_pic_delete',
      {
        new: this.state.focusedDetailBreakReport?.new,
        waddate: this.state.focusedDetailBreakReport?.waddate,
        wadnum: this.state.focusedDetailBreakReport?.wadnum,
      },
    )) {
      this.imageRequest(item);
      ConfirmSuccess.show('삭제', '삭제하였습니다.');
    }
  }

  @action
  async imageDelete2(item: any) {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.GENERAL,
      'tb_pic2_delete',
      {
        new: this.state.focusedDetailBreakReport?.new,
        waddate: this.state.focusedDetailBreakReport?.waddate,
        wadnum: this.state.focusedDetailBreakReport?.wadnum,
      },
    )) {
      this.imageRequest2(item);
      ConfirmSuccess.show('삭제', '삭제하였습니다.');
    }
  }

  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_save`,
        {
          custcd: publicStore.user.custcd,
          waddate: this.state.focusedDetailBreakReport?.waddate,
          wadnum: this.state.focusedDetailBreakReport?.wadnum,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        await this.imageRequest(i);
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  async imageSelect(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      await this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async imageSelect2(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      await this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async itemChanged(type: number) {
    const { actionStore: api } = this.props;

    let data:any = {
      new: undefined,
    };

    const params = {
      itemname: BreakReportItemChangeTypeNames[type],
      compdate: this.state.focusedDetailBreakReport?.compdate,
      compnum: this.state.focusedDetailBreakReport?.compnum,
      new: this.state.focusedDetailBreakReport?.new,
    };

    if (type === BreakReportItemChangeTypeNames.compdate) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.compdate,
          recedate: this.state.focusedDetailBreakReport?.recedate,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakReportItemChangeTypeNames.arrivtime) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.arrivtime,
          recedate: this.state.focusedDetailBreakReport?.recedate,
          recetime: this.state.focusedDetailBreakReport?.recetime,
          comptime: this.state.focusedDetailBreakReport?.comptime,
          arrivdate: this.state.focusedDetailBreakReport?.arrivdate,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakReportItemChangeTypeNames.comptime) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.comptime,
          recedate: this.state.focusedDetailBreakReport?.recedate,
          recetime: this.state.focusedDetailBreakReport?.recetime,
          arrivtime: this.state.focusedDetailBreakReport?.arrivtime,
          arrivdate: this.state.focusedDetailBreakReport?.arrivdate,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakReportItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.perid,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakReportItemChangeTypeNames.perid2) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.perid2,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === BreakReportItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          data: this.state.focusedDetailBreakReport?.actcd,
          actcd: this.state.focusedDetailBreakReport?.actcd,
        },
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }
  }

  @action
  async PopupModal(isOpen: boolean) {
    await this.popupRetrieve();
    this.setState({ popupModal: isOpen });
  }

  @action
  async popupRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityPopup = new InfinityRetrieve(
      {
        sub: 'w_popup_e411_wad',
        stdate: this.state.stdatePopup,
        enddate: this.state.enddatePopup,
        wadcnt: this.state.wadcntPopup,
        gubun: this.state.gubunPopup,
        cltcd: this.state.cltcdPopup,
        divicd: this.state.divicdPopup,
        perid: this.state.peridPopup,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          popupData: [...items],
        });
      },
      async () => {
        await this.SS({
          popupData: [],
        });
        await this.infinityPopup?.retrieve();
        await this.popupGrid.current?.setFocus(0);
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      popupData: [],
    }, async () => {
      const data = await this.infinityPopup?.retrieve();
      this.setState({
        cnt_tot: data?.cnt_tot || '0',
        equpcnt_tot: data?.equpcnt_tot || '0',
      });

      await this.infinityPopup?.retrieveAll();
      await this.popupGrid.current?.setFocus(0);
    });
  }

  @action
  async popupSave() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix

    if (this.state.popupData?.length! < 1) {
      await this.setState({ popupModal: false });
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_e411_wad',
        actcd: this.state.focusedPopupData?.actcd,
        actnm: this.state.focusedPopupData?.actnm,
        equpcd: this.state.focusedPopupData?.equpcd,
        equpnm: this.state.focusedPopupData?.equpnm,
        actpernm: this.state.focusedPopupData?.actpernm,
        perid: this.state.focusedPopupData?.perid,
        perid2: this.state.focusedPopupData?.perid2,
        recedate: this.state.focusedPopupData?.recedate,
        recenum: this.state.focusedPopupData?.recenum,
        recetime: this.state.focusedPopupData?.recetime,
        compdate: this.state.focusedPopupData?.compdate,
        compnum: this.state.focusedPopupData?.compnum,
        comptime: this.state.focusedPopupData?.comptime,
        arrivdate: this.state.focusedPopupData?.arrivdate,
        arrivtime: this.state.focusedPopupData?.arrivtime,
        resutime: this.state.focusedPopupData?.resutime,
        resulttime: this.state.focusedPopupData?.resulttime,
        contcd: this.state.focusedPopupData?.contcd,
        contents: this.state.focusedPopupData?.contents,
        gregicd: this.state.focusedPopupData?.gregicd,
        regicd: this.state.focusedPopupData?.regicd,
        remocd: this.state.focusedPopupData?.remocd,
        remoremark: this.state.focusedPopupData?.remoremark,
        resucd: this.state.focusedPopupData?.resucd,
        resuremark: this.state.focusedPopupData?.resuremark,
        resultcd: this.state.focusedPopupData?.resultcd,
        faccd: this.state.focusedPopupData?.faccd,
        customer: this.state.focusedPopupData?.customer,
        remark: this.state.focusedPopupData?.remark,
        receperid: this.state.focusedPopupData?.receperid,
        divicd: this.state.focusedPopupData?.divicd,
        divicd2: this.state.focusedPopupData?.divicd2,
        wad: this.state.focusedPopupData?.wad,
      });

    if (data) {
      await this.PopupModal(false);
      await this.onRetrieveEvent();
    }
  }

  @action
  onPopupRowFocusEvent(item: PopupModel) {
    this.setState({ focusedPopupData: item });
  }

  @action
  onPopupClose() {
    const today = new Date();
    const year = today.getFullYear(); // 년도

    let popupMonth:string | number = today.getMonth(); // 팝업창 월

    let date:string | number = today.getDate(); // 날짜

    let month:string | number = today.getMonth() + 1; // 월

    if (popupMonth < 10) {
      popupMonth = `0${popupMonth}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    if (date < 10) {
      date = `0${date}`;
    }

    this.setState({
      popupModal: false,
      popupData: [],
      stdatePopup: `${year}${popupMonth}${date}`,
      enddatePopup: `${year}${month}${date}`,
      cltcdPopup: '',
      divicdPopup: '',
      divinmPopup: '',
      peridPopup: '',
      pernmPopup: '',
    });
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      waddate: this.state.focusedDetailBreakReport?.waddate,
      wadnum: this.state.focusedDetailBreakReport?.wadnum,
      wadtime: this.state.focusedDetailBreakReport?.wadtime,
      inperid: this.state.focusedDetailBreakReport?.inperid,
      actcd: this.state.focusedDetailBreakReport?.actcd,
      actnm: this.state.focusedDetailBreakReport?.actnm,
      equpcd: this.state.focusedDetailBreakReport?.equpcd,
      equpnm: this.state.focusedDetailBreakReport?.equpnm,
      actpernm: this.state.focusedDetailBreakReport?.actpernm,
      perid: this.state.focusedDetailBreakReport?.perid,
      perid2: this.state.focusedDetailBreakReport?.perid2,
      recedate: this.state.focusedDetailBreakReport?.recedate,
      recenum: this.state.focusedDetailBreakReport?.recenum,
      recetime: this.state.focusedDetailBreakReport?.recetime,
      compdate: this.state.focusedDetailBreakReport?.compdate,
      compnum: this.state.focusedDetailBreakReport?.compnum,
      comptime: this.state.focusedDetailBreakReport?.comptime,
      arrivdate: this.state.focusedDetailBreakReport?.arrivdate,
      arrivtime: this.state.focusedDetailBreakReport?.arrivtime,
      resutime: this.state.focusedDetailBreakReport?.resutime,
      resulttime: this.state.focusedDetailBreakReport?.resulttime,
      contcd: this.state.focusedDetailBreakReport?.contcd,
      contents: this.state.focusedDetailBreakReport?.contents,
      gregicd: this.state.focusedDetailBreakReport?.gregicd,
      regicd: this.state.focusedDetailBreakReport?.regicd,
      remocd: this.state.focusedDetailBreakReport?.remocd,
      remoremark: this.state.focusedDetailBreakReport?.remoremark,
      customer: this.state.focusedDetailBreakReport?.customer,
      resucd: this.state.focusedDetailBreakReport?.resucd,
      resuremark: this.state.focusedDetailBreakReport?.resuremark,
      resultcd: this.state.focusedDetailBreakReport?.resultcd,
      faccd: this.state.focusedDetailBreakReport?.faccd,
      remark: this.state.focusedDetailBreakReport?.remark,
      receperid: this.state.focusedDetailBreakReport?.receperid,
      divicd: this.state.focusedDetailBreakReport?.divicd,
      divicd2: this.state.focusedDetailBreakReport?.divicd2,
      wad: this.state.focusedDetailBreakReport?.wad,

    }, this.state.focusedDetailBreakReport?.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.focusedDetailBreakReport?.recedate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        // focusedDetailBreakReport: new BreakReportDetailModel(),
      });
      await this.onRetrieveEvent();
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (user.pernm !== this.state.focusedDetailBreakReport?.inpernm) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다.');
      return;
    }

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    if (appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.FIXWAD)) {
      // 결재상신
      const data = await api.exec(Category.MAINTENANCE, 'wb_appreport', {
        papercd: PAPERCD.FIXWAD.toString(),
        appnum: this.state.focusedDetailBreakReport?.appnum,
        appgubun: this.state.focusedDetailBreakReport?.appgubun,
        waddate: this.state.focusedDetailBreakReport?.waddate,
        wadnum: this.state.focusedDetailBreakReport?.wadnum,
        inperid: this.state.focusedDetailBreakReport?.inperid,
        perid: this.state.focusedDetailBreakReport?.inperid,
        actnm: this.state.focusedDetailBreakReport?.actnm,
        title: '',
        refer: this.state.focusedDetailBreakReport?.refer,
        appflag,
      });
      data && await this.onRetrieveEvent();
    } if (appflag === 'cancel') {
      const data = await api.exec(Category.MAINTENANCE, 'wb_appreport', {
        papercd: PAPERCD.FIXWAD.toString(),
        appnum: this.state.focusedDetailBreakReport?.appnum,
        appgubun: this.state.focusedDetailBreakReport?.appgubun,
        waddate: this.state.focusedDetailBreakReport?.waddate,
        wadnum: this.state.focusedDetailBreakReport?.wadnum,
        inperid: this.state.focusedDetailBreakReport?.inperid,
        perid: this.state.focusedDetailBreakReport?.inperid,
        actnm: this.state.focusedDetailBreakReport?.actnm,
        title: '',
        refer: this.state.focusedDetailBreakReport?.refer,
        appflag,
      });
      data && await this.onRetrieveEvent();
    }
  }


  @action
  async onNewEvent() {
    if (this.state.focusedDetailBreakReport?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new BreakReportDetailModel({
        ...data, // new = 1
      }, true);

      // @ts-ignore
      this.setState({
        focusedDetailBreakReport: newModel,
        lastSaveData: newModel,
        BreakReportList: [
          // @ts-ignore
          newModel,
          ...this.state.BreakReportList,
        ],
        // @ts-ignore
        focusedBreakReport: newModel,
      }, async () => {
        await this.grid.current?.setFocus(0);
        await this.detailGrid.current?.setFocus(0);
      });
    }
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          waddate: this.state.focusedDetailBreakReport?.waddate,
          wadnum: this.state.focusedDetailBreakReport?.wadnum,
          appnum: this.state.focusedDetailBreakReport?.appnum,
          attcnt: this.state.focusedDetailBreakReport?.attcnt,
          appgubun: this.state.focusedDetailBreakReport?.appgubun,
          actnm: this.state.focusedDetailBreakReport?.actnm,
        }, false,
      );

      data && this.setState({
        focusedDetailBreakReport: new BreakReportDetailModel({
          ...this.state.focusedDetailBreakReport,
          ...data,
        }, data.new),
      });

      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const params = {
      waddate: this.state.focusedDetailBreakReport?.waddate,
      wadnum: this.state.focusedDetailBreakReport?.wadnum,
      appnum: this.state.focusedDetailBreakReport?.appnum,
      attcnt: this.state.focusedDetailBreakReport?.attcnt,
      appgubun: this.state.focusedDetailBreakReport?.appgubun,
      actnm: this.state.focusedDetailBreakReport?.actnm,
    };
    if (this.state.isReported && this.state.focusedBreakReport?.appgubun !== '001') {
      await api.fxExec('delete', params, false);
    } else {
      // eslint-disable-next-line max-len
      const text = `문서번호: ${this.state.focusedDetailBreakReport?.appnum} 현장: ${this.state.focusedDetailBreakReport?.actnm}`;
      await api.delete(text, params) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }


  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.BreakReportList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      perid: this.state.perid,
      waddate: this.state.focusedDetailBreakReport?.waddate,
      wadnum: this.state.focusedDetailBreakReport?.wadnum,
      wkactcd: this.state.focusedDetailBreakReport?.actcd,
    });
  }


  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<BreakReportModel>, updatedRows: Array<BreakReportModel>) {
    this.updatedRows = updatedRows;
    this.setState({ BreakReportList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<PopupModel>, updatedRows2: Array<PopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ popupData: rows2 });
  }

  render() {
    return (
      <BreakReportTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focusedDetailBreakReport: {
              ...this.state.focusedDetailBreakReport,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
