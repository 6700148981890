import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb300_01
 * @category 급여계산
 */
export class PayCalculateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  mon: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  seq: string;

  /**
   * 입력자
   *
   * **data type** varchar(6)
   */
  perid: string;

  /**
   * 부서
   *
   * **data type** varchar(4)
   */
  divicd: string;

  /**
   * 소득공제인원
   *
   * **data type** varchar(10)
   */
  deduction: string;

  /**
   * 갑근세
   *
   * **data type** varchar(10)
   */
  payamt_tax: string;

  /**
   * 월간급여금액
   *
   * **data type** float
   */
  pay_month: string;

  /**
   * 년간급여금액
   *
   * **data type** float
   */
  pay_year: string;

  /**
   * 일급금액
   *
   * **data type** float
   */
  pay_day: string;

  /**
   * 시가금액
   *
   * **data type** float
   */
  pay_hour: string;

  /**
   * 통상임금총액
   *
   * **data type** float
   */
  pay_tot: string;

  /**
   * 기본급(주휴포함)
   *
   * **data type** float
   */
  pay_basic: string;

  /**
   * 식대
   *
   * **data type** float
   */
  pay_food: string;

  /**
   * 직무수당
   *
   * **data type** float
   */
  pay_SerAllow: string;

  /**
   * 고정시간외근로수당
   *
   * **data type** float
   */
  pay_older: string;

  /**
   * 고정야간근로수당
   *
   * **data type** float
   */
  pay_night: string;

  /**
   * 고정휴일근로수당
   *
   * **data type** float
   */
  pay_holiday: string;

  /**
   * 산출총시간
   *
   * **data type** float
   */
  time_tot: string;

  /**
   * 월법정근로시간
   *
   * **data type** float
   */
  time_basic: string;

  /**
   * 고정시간외할증시간
   *
   * **data type** float
   */
  time_older: string;

  /**
   * 고정야간할증시간
   *
   * **data type** float
   */
  time_night: string;

  /**
   * 고정휴일할증시간
   *
   * **data type** float
   */
  time_holiday: string;

  /**
   * 매월기준일수
   *
   * **data type** float
   */
  month_basic: string;

  /**
   * 매월차감일수(결근)
   *
   * **data type** float
   */
  month_minus: string;

  /**
   * 기본금(주휴)차감금액(결근)
   *
   * **data type** float
   */
  minuspay_basic: string;

  /**
   * 고정시간외수당 차감금액(결근)
   *
   * **data type** float
   */
  minuspay_older: string;

  /**
   * 지각조퇴차감시간
   *
   * **data type** float
   */
  minuspay_late_time: string;

  /**
   * 지각조퇴차감금액
   *
   * **data type** float
   */
  minuspay_late_pay: string;

  /**
   * 결근지각조퇴 총 차감금액
   *
   * **data type** float
   */
  minuspay_late_tot: string;

  /**
   * 인센티브1(직책수당)
   *
   * **data type** float
   */
  pay_leader: string;

  /**
   * 상여금
   *
   * **data type** float
   */
  pay_bonus: string;

  /**
   * 인센티브2(월실적)
   *
   * **data type** float
   */
  pay_incentive: string;

  /**
   * 기타수당 통신
   *
   * **data type** float
   */
  pay_other: string;

  /**
   * 연장시간계산
   *
   * **data type** float
   */
  over_older_time_cal: string;

  /**
   * 연장시간(1.5배)
   *
   * **data type** float
   */
  over_older_time: string;

  /**
   * 연장시간(1.5배)
   *
   * **data type** float
   */
  over_older_pay: string;

  /**
   * 야간근무시간계산
   *
   * **data type** float
   */
  over_duty_time_cal: string;

  /**
   * 야근시간(1.5배)
   *
   * **data type** float
   */
  over_duty_time: string;

  /**
   * 야근정산금액
   *
   * **data type** float
   */
  over_duty_pay: string;

  /**
   * 휴일일수계산
   *
   * **data type** float
   */
  over_holi_time_cal: string;

  /**
   * 휴일시간(1.5배)
   *
   * **data type** float
   */
  over_holi_time: string;

  /**
   * 휴일정산금액
   *
   * **data type** float
   */
  over_holi_pay: string;

  /**
   * 남은연차
   *
   * **data type** float
   */
  rest_day: string;

  /**
   * 연차수당정산금액
   *
   * **data type** float
   */
  rest_pay: string;

  /**
   * 소득세환급금
   *
   * **data type** float
   */
  tax_income: string;

  /**
   * 건강보험료환급금
   *
   * **data type** float
   */
  tax_health: string;

  /**
   * 연말정산보험료
   *
   * **data type** float
   */
  tax_national: string;

  /**
   * 가불금
   *
   * **data type** float
   */
  tax_suspense: string;

  /**
   * 기타공제
   *
   * **data type** float
   */
  tax_other: string;

  /**
   * 비고
   *
   * **data type** float
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** float
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** text
   */
  indate: string;

  /**
   * 월간연봉급여총액
   *
   * **data type** varchar(10)
   */
  tot_samt: string;

  /**
   * 공제금액
   *
   * **data type** varchar(8)
   */
  tot_tamt: string;

  /**
   * 실수령금액
   *
   * **data type** float
   */
  tot_mamt: string;

  /**
   * 상조회비
   *
   * **data type** float
   */
  insu_maf: string;

  /**
   * 주민세
   *
   * **data type** float
   */
  payamt_tax2: string;

  /**
   * 고용보험보험료(0.8%)
   *
   * **data type** float
   */
  insu_unem_flag: string;

  /**
   * 고용보험보험료(3.335%)
   *
   * **data type** varchar(1)
   */
  insu_health_flag: string;

  /**
   * 장기요양보험료(10.25%)
   *
   * **data type** varchar(1)
   */
  insu_longterm_flag: string;

  /**
   * 국민연금보험료(4.5%)
   *
   * **data type** varchar(1)
   */
  insu_national_flag: string;

  /**
   * 성명
   *
   * **data type** varchar(1)
   */
  pernm: string;

  /**
   * 재직구분
   *
   * **data type** varchar(1)
   */
  rtclafi: string;

  /**
   * 입사일
   *
   * **data type** varchar(30)
   */
  entdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(3)
   */
  rtdate: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 직책코드
   *
   * **data type** varchar(8)
   */
  rspcd: string;

  /**
   * 직책
   *
   * **data type** varchar(40)
   */
  rspnm: string;

  /**
   * 대표여부
   *
   * **data type** varchar(3)
   */
  preyn: string;

  /**
   * 내근자여부
   *
   * **data type** varchar(40)
   */
  offiyn: string;

  /**
   * 성별
   *
   * **data type** varchar(1)
   */
  sex: string;

  /**
   * 지급
   *
   * **data type** varchar(1)
   */
  mijflag: string;

  /**
   * 작성일자
   *
   * **data type** varchar(1)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(1)
   */
  mijnum: string;

  /**
   * 합계금액
   *
   * **data type** varchar(8)
   */
  mijamt: string;

  /**
   * 기타금액
   *
   * **data type** varchar(4)
   */
  pay_other_random: string;

  /**
   * 매입순번
   *
   * **data type** float
   */
  mijseq: string;

  /**
   * 연차산정시간
   *
   * **data type** float
   */
  time_yearly: string;

  /**
   * 총연차일수
   *
   * **data type** varchar(4)
   */
  yearly_cnt: string;

  /**
   * 연차수당
   *
   * **data type** float
   */
  pay_yearly: string;

  /**
   * 자동증가연차
   *
   * **data type** float
   */
  yearly_add: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(10)
   */
  dayterm2: string;

  /**
   * 국민연금보험료
   *
   * **data type** number
   */
  insu_national: string;

  /**
   * 고용보험보험료
   *
   * **data type** number
   */
  insu_unem: string;

  /**
   * 고속도로통행료
   *
   * **data type** number
   */
  pay_tax1_01: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_01_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_02_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_03_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_04_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_05_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_06_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_07_t: string;

  /**
   * -
   *
   * **data type** varchar(30)
   */
  pay_tax0_08_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_01: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_02: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_03: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_04: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_05: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_06: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_07: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_08: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_09: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax0_10: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_02: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_03: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_04: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_05: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_06: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_07: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_08: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_09: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_10: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_01_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_02_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_03_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_04_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_05_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_06_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_07_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_tax1_08_t: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_other_random_t: string;

  /**
   * -
   *
   * **data type** number
   */
  yearly_default: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_serallow: string;

  /**
   * -
   *
   * **data type** number
   */
  insu_maf_flag: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_serallow_flag: string;

  /**
   * -
   *
   * **data type** number
   */
  pay_food_flag: string;

  /**
   * -
   *
   * **data type** number
   */
  insu_longterm: string;

  /**
   * -
   *
   * **data type** number
   */
  insu_health: string;

  /**
   * -
   *
   * **data type** number
   */
  yearly_flag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.yearly_flag = data.yearly_flag || '';
    this.mon = data.mon || '';
    this.insu_health = data.insu_health || '';
    this.insu_longterm = data.insu_longterm || '';
    this.pay_food_flag = data.pay_food_flag || '';
    this.pay_serallow_flag = data.pay_serallow_flag || '';
    this.insu_maf_flag = data.insu_maf_flag || '';
    this.pay_serallow = data.pay_serallow || '';
    this.yearly_default = data.yearly_default || '';
    this.pay_other_random_t = data.pay_other_random_t || '';
    this.pay_tax0_01_t = data.pay_tax0_01_t || '';
    this.pay_tax0_02_t = data.pay_tax0_02_t || '';
    this.pay_tax0_03_t = data.pay_tax0_03_t || '';
    this.pay_tax0_04_t = data.pay_tax0_04_t || '';
    this.pay_tax0_05_t = data.pay_tax0_05_t || '';
    this.pay_tax0_06_t = data.pay_tax0_06_t || '';
    this.pay_tax0_07_t = data.pay_tax0_07_t || '';
    this.pay_tax0_08_t = data.pay_tax0_08_t || '';
    this.pay_tax1_01_t = data.pay_tax1_01_t || '';
    this.pay_tax1_02_t = data.pay_tax1_02_t || '';
    this.pay_tax1_03_t = data.pay_tax1_03_t || '';
    this.pay_tax1_04_t = data.pay_tax1_04_t || '';
    this.pay_tax1_05_t = data.pay_tax1_05_t || '';
    this.pay_tax1_06_t = data.pay_tax1_06_t || '';
    this.pay_tax1_07_t = data.pay_tax1_07_t || '';
    this.pay_tax1_08_t = data.pay_tax1_08_t || '';
    this.pay_tax1_01 = data.pay_tax1_01 || '';
    this.pay_tax1_02 = data.pay_tax1_02 || '';
    this.pay_tax1_03 = data.pay_tax1_03 || '';
    this.pay_tax1_04 = data.pay_tax1_04 || '';
    this.pay_tax1_05 = data.pay_tax1_05 || '';
    this.pay_tax1_06 = data.pay_tax1_06 || '';
    this.pay_tax1_07 = data.pay_tax1_07 || '';
    this.pay_tax1_08 = data.pay_tax1_08 || '';
    this.pay_tax1_09 = data.pay_tax1_09 || '';
    this.pay_tax1_10 = data.pay_tax1_10 || '';
    this.pay_tax0_01 = data.pay_tax0_01 || '';
    this.pay_tax0_02 = data.pay_tax0_02 || '';
    this.pay_tax0_03 = data.pay_tax0_03 || '';
    this.pay_tax0_04 = data.pay_tax0_04 || '';
    this.pay_tax0_05 = data.pay_tax0_05 || '';
    this.pay_tax0_06 = data.pay_tax0_06 || '';
    this.pay_tax0_07 = data.pay_tax0_07 || '';
    this.pay_tax0_08 = data.pay_tax0_08 || '';
    this.pay_tax0_09 = data.pay_tax0_09 || '';
    this.pay_tax0_10 = data.pay_tax0_10 || '';
    this.insu_unem = data.insu_unem || '';
    this.insu_national = data.insu_national || '';
    this.dayterm2 = data.dayterm2 || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.divicd = data.divicd || '';
    this.deduction = data.deduction || '';
    this.payamt_tax = data.payamt_tax || '';
    this.pay_month = data.pay_month || '';
    this.pay_year = data.pay_year || '';
    this.pay_day = data.pay_day || '';
    this.pay_hour = data.pay_hour || '';
    this.pay_tot = data.pay_tot || '';
    this.pay_basic = data.pay_basic || '';
    this.pay_food = data.pay_food || '';
    this.pay_SerAllow = data.pay_SerAllow || '';
    this.pay_older = data.pay_older || '';
    this.pay_night = data.pay_night || '';
    this.pay_holiday = data.pay_holiday || '';
    this.time_tot = data.time_tot || '';
    this.time_basic = data.time_basic || '';
    this.time_older = data.time_older || '';
    this.time_night = data.time_night || '';
    this.time_holiday = data.time_holiday || '';
    this.month_basic = data.month_basic || '';
    this.month_minus = data.month_minus || '';
    this.minuspay_basic = data.minuspay_basic || '';
    this.minuspay_older = data.minuspay_older || '';
    this.minuspay_late_time = data.minuspay_late_time || '';
    this.minuspay_late_pay = data.minuspay_late_pay || '';
    this.minuspay_late_tot = data.minuspay_late_tot || '';
    this.pay_leader = data.pay_leader || '';
    this.pay_bonus = data.pay_bonus || '';
    this.pay_incentive = data.pay_incentive || '';
    this.pay_other = data.pay_other || '';
    this.over_older_time_cal = data.over_older_time_cal || '';
    this.over_older_time = data.over_older_time || '';
    this.over_older_pay = data.over_older_pay || '';
    this.over_duty_time_cal = data.over_duty_time_cal || '';
    this.over_duty_time = data.over_duty_time || '';
    this.over_duty_pay = data.over_duty_pay || '';
    this.over_holi_time_cal = data.over_holi_time_cal || '';
    this.over_holi_time = data.over_holi_time || '';
    this.over_holi_pay = data.over_holi_pay || '';
    this.rest_day = data.rest_day || '';
    this.rest_pay = data.rest_pay || '';
    this.tax_income = data.tax_income || '';
    this.tax_health = data.tax_health || '';
    this.tax_national = data.tax_national || '';
    this.tax_suspense = data.tax_suspense || '';
    this.tax_other = data.tax_other || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.tot_samt = data.tot_samt || '';
    this.tot_tamt = data.tot_tamt || '';
    this.tot_mamt = data.tot_mamt || '';
    this.insu_maf = Number(data.insu_maf || '0').toFixed(0).toString();
    this.payamt_tax2 = data.payamt_tax2 || '';
    this.insu_unem_flag = data.insu_unem_flag || '';
    this.insu_health_flag = data.insu_health_flag || '';
    this.insu_longterm_flag = data.insu_longterm_flag || '';
    this.insu_national_flag = data.insu_national_flag || '';
    this.pernm = data.pernm || '';
    this.rtclafi = data.rtclafi || '';
    this.entdate = data.entdate || '';
    this.rtdate = data.rtdate || '';
    this.divinm = data.divinm || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.preyn = data.preyn || '';
    this.offiyn = data.offiyn || '';
    this.sex = data.sex || '';
    this.mijflag = data.mijflag || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.mijamt = data.mijamt || '';
    this.pay_other_random = data.pay_other_random || '';
    this.mijseq = data.mijseq || '';
    this.time_yearly = data.time_yearly || '';
    this.yearly_cnt = data.yearly_cnt || '';
    this.pay_yearly = data.pay_yearly || '';
    this.yearly_add = data.yearly_add || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
