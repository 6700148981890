import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import { Date8 } from '../../utils/time';
import { Format } from '../../utils/string';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { SubHeader } from '../../components/layout/SubHeader';
import { CheckBox } from '../../components/forms/CheckBox';

interface ModalContractProps {
  modalStore: ModalStore;
}

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

@observer
export class ModalContract extends React.Component<ModalContractProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1200}
        height={600}
        isVisible={modalStore.isVisibleContractSelector}
        onClose={() => modalStore.closeContractSelector()}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>계약만료현장알림</LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actnm',
                text: '현장명',
                width: 16,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {modalStore.contractTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'tel',
                text: '전화번호',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.tel}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '만료일자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 8,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'averamt',
                text: '평균보수료',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.averamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'monamt',
                text: '월관리료',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(modalStore.monamt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.monamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '점검관리료',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(modalStore.amt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'addyn',
                text: '부가세',
                width: 6,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.addyn === '0' ? '별도' : '포함'}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
            ]}
            ref={modalStore?.contractSelectorGrid}
            data={modalStore?.contractData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => modalStore.onContractRowFocusEvent(item)}
            onRowDoubleClick={() => modalStore.openHistorySelector()}
          />

          <SubHeader>
            <FlexLayout
              align="center"
              size={1000}
            >
              <FlexLayout isVertical={true}>
                <FlexLayout style={{ color: 'var(--color-red)' }}>
                  *목록의 현장이 계약이 만료(예정)되었습니다. 연장인지 해지인지 확인하시기 바랍니다. -빠른답변 부탁드립니다.-
                </FlexLayout>

                <FlexLayout>
                  <div style={{ color: '#8106aa' }}>{modalStore.text1}.</div> &nbsp;
                  <div style={{ color: '#7F7A2C' }}>{modalStore.text2}.</div>
                </FlexLayout>
              </FlexLayout>

              <FlexLayout weight={0.2}>
                오늘 더이상 보지않음
                <CheckBox
                  value={modalStore.contractChk === '1'}
                  onChange={(value) => {
                    modalStore.contractChk = value ? '1' : '0';
                    modalStore.onContractRetrieveEvent();
                  }}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => modalStore.onContractPrint()}>출력</Button>
              <Button onClick={() => modalStore.closeContractSelector()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
