import { action } from 'mobx';
import { ModalStore } from '../../stores';
import { CltSelectorItem } from '../../stores/ModalStore';
import { ISearchBinding } from './SearchBinding';

export default class SearchBindingClt implements ISearchBinding {
  readonly modalStore: ModalStore;

  readonly params: any;

  readonly immediatlySearch: boolean;

  readonly onSearch: (item: CltSelectorItem) => void;

  readonly functionName: string;

  constructor(
    modalStore: ModalStore,
    params: any,
    immediatlySearch: boolean,
    onSearch: (item: CltSelectorItem) => void,
  ) {
    this.modalStore = modalStore;
    this.params = params;
    this.immediatlySearch = immediatlySearch;
    this.onSearch = onSearch;
    this.functionName = '';
  }

  @action
  open(params?: any) {
    const args = {
      ...this.params,
      ...params,
    };

    this.modalStore.openCltSelector(args, this.immediatlySearch)
      .then((item) => {
        this.onSearch(item);
      });
  }
}
