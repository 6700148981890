import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
  RouterStore,
  MenuStore,
  PublicStore,
  ToolStore,
} from '../stores';
import { Login } from '../pages';
import ModalStore from '../stores/ModalStore';

export interface UnAuthorizedAppProps {
  publicStore?: PublicStore;
  menuStore?: MenuStore;
  routerStore?: RouterStore;
  modalStore?: ModalStore;
  toolStore?: ToolStore;
}

@inject('publicStore', 'menuStore', 'routerStore', 'modalStore', 'toolStore')
@observer
export class UnAuthorizedScreen extends React.Component<UnAuthorizedAppProps> {
  render() {
    const {
      publicStore,
      menuStore,
      routerStore,
      modalStore,
      toolStore,
    } = this.props;

    return (
      <React.Fragment>
        <Login
          publicStore={publicStore!}
          menuStore={menuStore!}
          routerStore={routerStore!}
          modalStore={modalStore!}
          toolStore={toolStore!}
        />
      </React.Fragment>
    );
  }
}
