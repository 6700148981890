import * as React from 'react';
import { inject } from 'mobx-react';
import { action, computed, observable } from 'mobx';
// @ts-ignore
import { Global, LayoutProps } from '../../../constants';
import { PublicStore, ModalStore } from '../../../stores';
import { FormatTextBox } from '../FormatTextBox';
import { Calendar } from '../../../utils/calendar';
import { CalendarItem } from '../../../utils/calendar/Calendar';
import Date8Calculator from '../../../utils/time/Date8Calculator';
import { PageComponent } from '../../../utils';
import { Date6Calculator, Today } from '../../../utils/time';

interface DateTextBoxActions {
  onChange?: (value: any) => any;
  onBlur?: (value: any) => any;
  onEnter?: (value: any) => any;
}

interface DateTextBoxProps extends DateTextBoxActions, LayoutProps {
  publicStore?: PublicStore;
  modalStore?: ModalStore;
  refs?: React.RefObject<any>;
  value?: any;
  color?: string;
  bold?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  format?: string;
  placeholder?: string;
  className?: any;
  style?: any;
  readonly?: boolean;
  transparent?: boolean;
  isDisabledTrackingStateChange?: boolean;
  // @ts-ignore
  bindSearchModal?: Global.SearchBindingTypes;
  head?: React.ReactNode;
  trail?: React.ReactNode;
}

export enum DateTextBoxMode {
  YEAR,
  MONTH,
  DATE,
}

interface DateTextBoxState {
  data: Array<CalendarItem>;
  depth: DateTextBoxMode;
  mode: DateTextBoxMode;
  temp: string;
  popupLeft: number;
  isPopupForward: boolean;
}

@inject('publicStore', 'modalStore')
export class DateTextBox extends PageComponent<DateTextBoxProps, DateTextBoxState> {
  @observable numberFormatCount: number

  doNotClose?: boolean;

  ref: React.RefObject<FormatTextBox>;

  refInput: React.RefObject<HTMLInputElement>;

  isChanged: boolean;

  isMount: boolean;

  constructor(props: DateTextBoxProps, context: any) {
    super(props, context);
    this.numberFormatCount = this.props.format?.split('').filter((x) => x === '#').length || 0;
    this.ref = React.createRef();
    this.refInput = this.props.refs || React.createRef();
    this.isChanged = false;
    this.isMount = false;
    // eslint-disable-next-line no-nested-ternary, max-len
    const depth = this.numberFormatCount === 4 ? DateTextBoxMode.YEAR : (this.numberFormatCount === 6 ? DateTextBoxMode.MONTH : DateTextBoxMode.DATE);

    let temp = this.props.value;
    if (!temp || temp === '0000' || temp === '000000' || temp === '00000000') {
      switch (depth) {
        case DateTextBoxMode.YEAR:
          temp = '1970';
          break;

        case DateTextBoxMode.MONTH:
          temp = '197001';
          break;

        default:
          temp = '19700101';
      }
    }

    this.state = {
      data: [],
      depth,
      mode: depth,
      temp,
      popupLeft: 0,
      isPopupForward: true,
    };
  }

  async componentDidMount() {
    await this.updateDate();
    this.generateCalendar();
    this.isMount = true;
  }

  async componentDidUpdate(prevProps: Readonly<DateTextBoxProps>) {
    if (prevProps.value !== this.props.value) {
      await this.updateDate();
      this.generateCalendar();
    }

    const { modalStore } = this.props;
    modalStore?.refCalendarContainer.current?.update();

    this.ref.current?.forceUpdate();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  @action
  async updateDate() {
    const depth = this.numberFormatCount === 4 ? DateTextBoxMode.YEAR : (this.numberFormatCount === 6 ? DateTextBoxMode.MONTH : DateTextBoxMode.DATE);

    let temp = this.props.value;
    if (!temp || temp === '0000' || temp === '000000' || temp === '00000000') {
      switch (depth) {
        case DateTextBoxMode.YEAR:
          temp = Today.year();
          break;

        case DateTextBoxMode.MONTH:
          temp = Today.yearMon();
          break;

        default:
          temp = Today.date();
      }
    }

    await this.SS({
      depth,
      mode: depth,
      temp,
    });
  }

  @computed
  get rawData(): string {
    return this.props.value.replace(/[^0-9]/ig, '');
  }

  @action
  generateCalendar() {
    switch (this.state.mode) {
      case DateTextBoxMode.YEAR:
        this.SS({
          data: Calendar.years(this.state.temp.substring(0, 4)),
        });
        break;

      case DateTextBoxMode.MONTH:
        this.SS({
          data: Calendar.months(this.state.temp.substring(0, 4)),
        });
        break;

      default:
        this.SS({
          data: Calendar.dates(this.state.temp.substring(0, 6)),
        });
        break;
    }
  }

  @action
  async showCalendar() {
    const { modalStore } = this.props;

    if (!modalStore?.isVisibleCalendar || modalStore?.refCalendar !== this) {
      this.isChanged = false;
      const depth = this.numberFormatCount === 4
        ? DateTextBoxMode.YEAR
        : (this.numberFormatCount === 6 ? DateTextBoxMode.MONTH : DateTextBoxMode.DATE);

      await this.SS({
        depth,
        mode: depth,
        popupLeft: (this.refInput.current?.offsetLeft || 0),
        isPopupForward: (this.refInput.current?.parentElement?.getBoundingClientRect().y || 0)
          / window.innerHeight <= 0.6,
      });
      modalStore?.openCalendar(this);
    }
  }

  @action
  closeCalendar() {
    const { modalStore } = this.props;

    if (this.doNotClose) {
      this.doNotClose = false;
      return;
    }

    if (modalStore?.isVisibleCalendar && modalStore?.refCalendar === this) {
      modalStore.closeCalendar();
    }
  }

  @action
  onChange(data: string) {
    const { modalStore } = this.props;

    this.isChanged = true;
    if (data !== this.props.value) {
      if (this.props.onChange) {
        this.props.onChange(data.replace(/[^0-9]/ig, ''));
      }

      if (!this.props.isDisabledTrackingStateChange) {
        this.props.publicStore?.setStateChanged(true);
      }
    }

    modalStore?.closeCalendar();
  }

  @action
  onEnter(value: string) {
    this.closeCalendar();
    this.onChange(value);
    setTimeout(() => {
      this.props.onEnter && this.props.onEnter(this.rawData);
    });
  }

  @action
  selectCalendarItem(data: string) {
    switch (this.state.mode) {
      case DateTextBoxMode.YEAR:
        switch (this.state.depth) {
          case DateTextBoxMode.YEAR:
            this.onChange(data);
            break;

          default:
            this.doNotClose = true;
            this.setState({
              temp: data,
              mode: DateTextBoxMode.MONTH,
            }, () => this.generateCalendar());
            break;
        }
        break;

      case DateTextBoxMode.MONTH:
        switch (this.state.depth) {
          case DateTextBoxMode.MONTH:
            this.onChange(data);
            break;

          default:
            this.doNotClose = true;
            this.setState({
              temp: data,
              mode: DateTextBoxMode.DATE,
            }, () => this.generateCalendar());
            break;
        }
        break;

      default:
        this.onChange(data);
        break;
    }
  }

  setDoNotClose() {
    this.doNotClose = true;
  }

  @action
  prevDepth() {
    this.doNotClose = true;
    this.setState({
      mode: this.state.mode === DateTextBoxMode.DATE ? DateTextBoxMode.MONTH : DateTextBoxMode.YEAR,
    }, () => this.generateCalendar());
  }

  @action
  async prevPage() {
    this.doNotClose = true;
    const temp = new Date8Calculator(this.state.temp);
    if (this.state.mode === DateTextBoxMode.DATE) {
      temp.addMonth(-1);
      await this.SS({ temp: temp.toString() });
    } else {
      temp.addYear(-1);
      await this.SS({ temp: temp.toString() });
    }
    this.generateCalendar();
  }

  @action
  async nextPage() {
    this.doNotClose = true;
    if (this.state.mode === DateTextBoxMode.DATE) {
      const temp = new Date8Calculator(this.state.temp);
      temp.addMonth(1);
      await this.SS({ temp: temp.toString() });
    } else {
      const yearmon = this.state.temp.length === 6 ? this.state.temp : `${this.state.temp}01`;
      const temp = new Date6Calculator(yearmon);
      temp.addYear(1);
      await this.SS({ temp: temp.toString() });
    }
    this.generateCalendar();
  }

  @action
  public focus() {
    requestAnimationFrame(() => this.refInput.current?.focus());
  }

  @action
  onRawChange(value: string) {
    this.props.onChange && this.props.onChange(value);
  }

  render() {
    return (
      <FormatTextBox
        ref={this.ref}
        refs={this.refInput}
        value={this.props.value}
        textAlign={this.props.textAlign}
        format={this.props.format}
        placeholder={this.props.placeholder}
        className={this.props.className}
        style={{
          ...this.props.style,
          marginRight: 0,
        }}
        readonly={this.props.readonly}
        transparent={this.props.transparent}
        isDisabledTrackingStateChange={this.props.isDisabledTrackingStateChange}
        bindSearchModal={this.props.bindSearchModal}
        head={this.props.head}
        trail={this.props.trail}
        onBlur={() => {
          this.props.onBlur && this.props.onBlur(this.rawData);
          this.closeCalendar();
        }}
        onChange={(v) => this.onRawChange(v)}
        onEnter={(v) => this.onEnter(v)}
        onClick={() => this.showCalendar()}
      />
    );
  }
}
