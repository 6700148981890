/**
 * 모델
 * @window w_popup_e501w_01 점검
 * @category 현장방문숙지리스트
 */

export class InspectionLongModalModel {
  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 적요
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 미수일자
   *
   * **data type** varchar(8)
   */
  readonly misdate: string;

  /**
   * 장기미수금액
   *
   * **data type** varchar(8)
   */
  readonly misamt: string;

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.actnm = data.actnm || '';
    this.remark = data.remark || '';
    this.misdate = data.misdate || '';
    this.misamt = data.misamt || '';
  }
}
