import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LabelBox,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { SeverancePay } from './SeverancePay';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_pb301_03
 * @category 퇴직금적립내역
 */
export const SeverancePayTemplate: React.FC<TemplateProps<SeverancePay>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() });
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() });
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span style={{ color: 'var(--color-red)' }}>사원명</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              textAlign="center"
              onChange={(value) => setData({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'spjangnm',
            text: '사업장',
            width: 25,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.spjangnm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '성명',
            width: 10,
            trail: (_) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope?.state.total} 건
              </FlexLayout>
            ),
          },
          {
            id: 'mon',
            text: '적용월',
            width: 10,
          },
          {
            id: 'retiredate',
            text: '적용일자',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.retiredate)}
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '구분',
            width: 10,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                총적립금액
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.gubun === '0' ? 'var(--color-blue)' : '' }}
              >
                {x.gubun === '0' ? '적립' : '정산'}
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt',
            text: '적립금액',
            width: 10,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope?.state.retireamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.retireamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 25,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />

      <ModalView
        width={350}
        height={150}
        isVisible={scope.state.modalVisible}
        onClose={() => scope.onPageCloseEvent()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <label style={{ color: 'var(--color-red)' }}>[해제] 비밀번호를 입력하세요!</label>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="비밀번호">
              <TextBox
                type="password"
                value={scope.state.password}
                onChange={(value) => scope.setState({ password: value })}
                onEnter={(value) => scope.onKeyEvent(value)}
              />
            </LabelBox>
          </FlexLayout>

           <FlexLayout
             justify="center"
             align="center"
           >
             <Button
               style={{
                 height: 25,
                 width: 80,
               }}
               theme={BUTTON_THEMES.BLUE}
               onClick={() => scope.onKeyEvent(scope.state.password)}
             >
               확인
             </Button>

             <Button
               style={{
                 height: 25,
                 width: 80,
               }}
               theme={BUTTON_THEMES.RED}
               onClick={() => scope.onPageCloseEvent()}
             >
               취소
             </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
