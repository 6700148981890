import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Worker } from '../Worker';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 - 계약사항 > 그리드
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTabContractGridHeader: GLHF<any, Worker> = () => ([
  {
    id: 'seq',
    text: '순번',
    width: 70,
  },
  {
    id: 'contdate',
    text: '계약일',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.contdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'projnm',
    text: '프로젝트명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.projnm}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '계약금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'baldate',
    text: '착공일',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.baldate)}
      </FlexLayout>
    ),
  },
  {
    id: 'kcdate',
    text: '준공일',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.kcdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '공사장소',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.address}
      </FlexLayout>
    ),
  },
]);
