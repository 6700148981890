import * as React from 'react';
import { RefObject } from 'react';
import { action } from 'mobx';
import {
  AskType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ContractDefaultModel from './models/ContractDefaultModel';
import { ContractTemplate } from './Contract.template';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';

interface ContractState {
  searchQuery: string;
  data: Array<ContractDefaultModel>;
  content: ContractDefaultModel;

  // ID modal
  isVisibleIDModal: boolean;
  searchQueryID: string;
  dataID: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export class Contract extends PageComponent<PageProps, ContractState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityID?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  gridID: React.RefObject<GridLayout> = React.createRef();

  gridIndex: number = 0;

  gridFocused?: ContractDefaultModel;

  gridIDFocus?: any;

  tabIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      data: [],
      content: new ContractDefaultModel(),
    };
  }

  @action
  async onFirstOpenEvent() {
    this.gridIndex = 0;
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new ContractDefaultModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
          content: new ContractDefaultModel(),
        });
        this.infinity?.retrieveAll();

        if (this.state.data.length > 0) {
          await this.grid.current?.setFocus(0);
          await this.onGridRowFocused(this.state.data[0], 0);
        }
      },
    );

    this.gridFocused = undefined;

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    const index = await this.infinity?.retrieveTo('actcd', this.state.content?.actcd, type, true) || 0;
    if (!autoLoad) return;
    this.state.data.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST, false);
    }
    if (!ConfirmWarning.assert(this.tabIndex === 0, '오류', '신규 등록은 "하도급계약서[기본]" 탭에서만 가능합니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('tab_1_new', {
      id: this.gridFocused?.id || '',
    });

    if (data) {
      const one = new ContractDefaultModel(data, true);

      await this.SS({
        content: one,
        data: [one, ...this.state.data],
      });

      await this.grid.current?.setFocus(0);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.fxSave(
      `tab_${this.tabIndex + 1}_save`,
      this.state.content,
      this.state.content.isNew,
    )) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.gridFocused?.projnm, '오류', '우선 현장을 선택해주세요')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.gridFocused?.id} - ${this.gridFocused?.projnm}`;

    if (await api.delete(text, {
      id: this.gridFocused?.id || '',
    })) {
      this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.content?.custcd, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    let isWhole = true;
    const confirm = await Confirm.ask('출력', '선택해주세요', '전체', '개별');

    switch (confirm) {
      case AskType.NO:
        isWhole = false;
        break;

      case AskType.CANCEL:
        return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      id: this.gridFocused?.id || '',
      actcd: this.gridFocused?.actcd || '',
      gubun: isWhole ? '%' : `${this.tabIndex + 1}`,
    });
  }

  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    this.onGridRowFocused(this.gridFocused, this.gridIndex);
  }


  @action
  async onGridRowFocused(item: ContractDefaultModel | undefined, index: number) {
    this.gridFocused = item;
    this.gridIndex = index;

    if (item?.isNew) return;

    // Init
    this.SS({
      content: new ContractDefaultModel(),
    });

    if (!item) return;


    const { actionStore: api } = this.props;
    const data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_1_RowFocuschanged`, {
      id: this.gridFocused?.id || '',
      actcd: this.gridFocused?.actcd || '',
    });

    if (data) {
      this.SS({ content: new ContractDefaultModel(data) });
    }
  }

  @action
  async itemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...content,
      [key]: value,
      itemname: key,
      data: value,
      new: content.isNew ? '1' : '0',
    });

    const one = new ContractDefaultModel({ ...content, ...result }, content.isNew);
    this.setState({ content: one });
  }


  @action
  async onIDRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.infinityID = new InfinityRetrieve(
      {
        sub: 'w_popup_share_xusers',
        as_nm: this.state.searchQueryID,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.SS({
          dataID: [
            ...this.state.dataID,
            ...items,
          ],
        });
      },
      async () => {
        await this.SS({
          dataID: [],
        });

        await this.infinityID?.retrieve();
        if (this.state.dataID.length) {
          this.gridID.current?.setFocus(0);
        }
      },
    );

    await this.SS({
      dataID: [],
    });

    await this.infinityID?.retrieve();
    if (this.state.dataID.length) {
      this.gridID.current?.setFocus(0);
    }
  }

  onGridIDRowClicked(item: any) {
    this.gridIDFocus = item;
  }

  @action
  async openModalID(search?: string) {
    this.gridIDFocus = undefined;
    await this.SS({
      isVisibleIDModal: true,
      dataID: [],
      searchQueryID: search || '',
    });

    this.onIDRetrieveEvent();
  }

  @action
  onClickModalIDSuccess() {
    if (!ConfirmWarning.assert(this.gridIDFocus, '오류', '먼저 선택해주세요')) return;

    this.setState({
      content: new ContractDefaultModel({
        ...this.state.content,
        id: this.gridIDFocus.id,
        pernm: this.gridIDFocus.pernm,
        email: this.gridIDFocus.email,
        hpnum: this.gridIDFocus.hpnum,
      }, this.state.content.isNew),
      isVisibleIDModal: false,
    });
  }


  render() {
    return <ContractTemplate
      scope={this}
    />;
  }
}
