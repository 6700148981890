export default class Date6 {
  static make(date?: Date): string {
    const now = date || new Date();
    const month = now.getMonth() + 1;
    return `${now.getFullYear()}${month < 10 ? `0${month}` : month}`;
  }

  static withDash(date?: Date | string): string {
    const str = typeof date === 'string' ? date : Date6.make(date);
    return `${str.substr(0, 4)}-${str.substr(4, 2)}`;
  }

  static withKor(date?: Date | string): string {
    const str = typeof date === 'string' ? date : Date6.make(date);
    return `${str.substr(0, 4)}년 ${str.substr(4, 2)}월`;
  }
}
