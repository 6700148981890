import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
} from 'react-icons/md';
import {
  FiCalendar,
  FiClipboard,
} from 'react-icons/all';
import { RefObject } from 'react';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { TabModel } from '../../../../models/component';
import { MonthResultTemplate } from './MonthResult.template';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import Confirm from '../../../../utils/confirm/Confirm';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Today } from '../../../../utils/time';
import { DepartmentModel } from './models/Department.model';
import { MonthModel } from './models/Month.model';
import { IndividualModel } from './models/Individual.model';
import { PopupModel } from './models/Popup.model';

export enum MonthResultTabId {
  Department,
  Individual,
  Month,
}

export const MonthResultTabTitles = [
  '부서별',
  '개인별',
  '월별합계',
];

export const MonthResultTabModels = [
  DepartmentModel,
  IndividualModel,
  MonthModel,
];

export const MonthResultTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
];

export const MonthResultTabDataStateNames = [
  'departments',
  'individuals',
  'months',
];

export const MonthResultTabUpdatesStateNames = [
  'departmetsUpdates',
  'individualsUpdates',
  'monthspdates',
];

export const MonthResultFocusedStateNames = [
  'departmetsFocused',
  'individualsFocused',
  'monthsFocused',
];

export interface MonthResultState {
  // 조회조건
  kukcdGubun: string;

  // 부서별
  departments?: Array<DepartmentModel>;
  departmetsUpdates?: Array<DepartmentModel>;
  departmetsFocused: DepartmentModel;

  // 부서별 팝업
  kcspnms?: Array<any>;
  resultss?: Array<any>;
  states?: Array<any>;
  evcdList: Array<any>;
  e035_qty01_state: boolean;
  e035_totplancnt01_state: boolean;
  e035_jrececnt01_state: boolean;
  e035_jcompcnt01_state: boolean;
  e035_qty01_2_state: boolean;
  e035_totplancnt01_2_state: boolean;
  e035_jrececnt01_2_state: boolean;
  e035_jcompcnt01_2_state: boolean;
  e035_qty02_state: boolean;
  e035_totplancnt02_state: boolean;
  e035_jrececnt02_state: boolean;
  e035_jcompcnt02_state: boolean;
  e035_qty02_2_state: boolean;
  e035_totplancnt02_2_state: boolean;
  e035_jrececnt02_2_state: boolean;
  e035_jcompcnt02_2_state: boolean;
  e035_qty03_state: boolean;
  e035_totplancnt03_state: boolean;
  e035_jrececnt03_state: boolean;
  e035_jcompcnt03_state: boolean;
  e035_qty04_state: boolean;
  e035_totplancnt04_state: boolean;
  e035_jrececnt04_state: boolean;
  e035_jcompcnt04_state: boolean;
  e035_qty05_state: boolean;
  e035_totplancnt05_state: boolean;
  e035_jrececnt05_state: boolean;
  e035_jcompcnt05_state: boolean;
  e035_qty10_state: boolean;
  e035_totplancnt10_state: boolean;
  e035_jrececnt10_state: boolean;
  e035_jcompcnt10_state: boolean;
  e401_rececnt_state: boolean;
  e401_wadcnt_state: boolean;
  e401_rececnt_ex_state: boolean;
  e401_wadcnt_ex_state: boolean;
  e401_rececompcnt_state: boolean;
  e401_trouble_state: boolean;
  e401_trouble_c_state: boolean;
  e301_minwoncnt_state: boolean;
  e301_minwonnotcnt_state: boolean;
  e301_minwoncompcnt_state: boolean;
  e073_kc001cnt_state: boolean;
  e073_kc002cnt_state: boolean;
  e073_kc003cnt_state: boolean;
  e101_vlongcnt_state: boolean;
  e101_longcnt_state: boolean;
  e101_fmcnt_state: boolean;
  e101_pogcnt_state: boolean;
  e101_mucnt_state: boolean;
  e101_sccnt_state: boolean;
  e101_vlongcnt03_state: boolean;
  e101_longcnt03_state: boolean;
  e101_fmcnt03_state: boolean;
  e101_pogcnt03_state: boolean;
  e101_pluscnt_state: boolean;
  e101_yucnt03_state: boolean;
  e101_clscnt_state: boolean;
  e101_eleccnt_state: boolean;
  b501_businesscnt_state: boolean;
  e451_costcnt_state: boolean;
  e471_misuse_compamt_state: boolean;
  e471_contuse_compamt_state: boolean;
  pb401_out_state: boolean;
  pb403_propose_state: boolean;
  pb404_educnt_state: boolean;
  pb404_edurececnt_state: boolean;
  e037not_state: boolean;
  give_talent_state: boolean;
  rece_talent_state: boolean;
  minus_state: boolean;
  userid: string;

  // 개인별
  individuals?: Array<IndividualModel>;
  individualsUpdates?: Array<IndividualModel>;
  individualsFocused: IndividualModel;

  // 월별합계
  months?: Array<MonthModel>;
  monthsUpdates?: Array<MonthModel>;
  monthsFocused: MonthModel;

  yearmon: string;
  focusedTab?: TabModel;

  // 팝업
  modalState: boolean;
  popupState: boolean;
  calculateState: boolean;
  modalData: Array<DepartmentModel>;
  popupFocused: PopupModel;
  plusData: PopupModel;
  minusDate: PopupModel;

  // 달란트점수
  onModalPlusBtn: boolean;
  onModalMinusBtn: boolean;

  // 부서별 trail
  qty01_tot: string;
  planamt01_tot: string;
  totplancnt01_tot: string;
  jrececnt01_tot: string;
  jcompcnt01_tot: string;
  qty01_2_tot: string;
  totplancnt01_2_tot: string;
  jrececnt01_2_tot: string;
  jcompcnt01_2_tot: string;
  qty02_2_tot: string;
  planamt04_tot: string;
  qty02_tot: string;
  jrececnt02_2_tot: string;
  jcompcnt02_tot: string;
  totplancnt02_2_tot: string;
  totplancnt02_tot: string;
  jrececnt02_tot: string;
  planamt02_2_tot: string;
  jcompcnt02_2_tot: string;
  jcompcnt03_tot: string;
  jcompcnt04_tot: string;
  planamt01_2_tot: string;
  planamt02_tot: string;
  minustot_tot: string;
  minus_tot: string;
  talenttot_tot: string;
  rece_talent_tot: string;
  give_talent_tot: string;
  talent_tot: string;
  e037tot_tot: string;
  e037not_tot: string;
  eduamttot_tot: string;
  edureceamt_tot: string;
  edurececnt_tot: string;
  eduamt_tot: string;
  educnt_tot: string;
  proposetot_tot: string;
  propose_tot: string;
  outamttot_tot: string;
  outdatecnt_tot: string;
  outcnt_tot: string;
  outdatenotcnt_tot: string;
  outnotcnt_tot: string;
  comp_contamttot_tot: string;
  comp_contamt_tot: string;
  comp_iamttot_tot: string;
  comp_uamt_tot: string;
  comp_iamt_tot: string;
  compamt_tot: string;
  costamt_tot: string;
  costcnt_tot: string;
  businessamt_tot: string;
  businesscnt_tot: string;
  contamttot_tot: string;
  elecamt_tot: string;
  eleccnt_tot: string;
  clsamt_tot: string;
  clscnt_tot: string;
  yu03amt_tot: string;
  yucnt03_tot: string;
  plusamt_tot: string;
  pluscnt_tot: string;
  pog03amt_tot: string;
  pogcnt03_tot: string;
  fm03amt_tot: string;
  fmcnt03_tot: string;
  long03amt_tot: string;
  longcnt03_tot: string;
  vlong03amt_tot: string;
  vlongcnt03_tot: string;
  scamt_tot: string;
  sccnt_tot: string;
  muamt_tot: string;
  mucnt_tot: string;
  pogamt_tot: string;
  pogcnt_tot: string;
  fmamt_tot: string;
  fmcnt_tot: string;
  longamt_tot: string;
  longcnt_tot: string;
  vlongamt_tot: string;
  vlongcnt_tot: string;
  kcamttot_tot: string;
  kc003amt_tot: string;
  kc003cnt_tot: string;
  kc002amt_tot: string;
  kc002cnt_tot: string;
  kc001amt_tot: string;
  kc001cnt_tot: string;
  minwonamttot_tot: string;
  minwoncompcnt_tot: string;
  minwonnotcnt_tot: string;
  minwoncnt_tot: string;
  troubleamtexectot_tot: string;
  troubletot_tot: string;
  trouble_c_tot: string;
  trouble_tot: string;
  rececnt_tot: string;
  receamt_tot: string;
  wadcnt_tot: string;
  wadamt_tot: string;
  rececnt_ex_tot: string;
  wadcnt_ex_tot: string;
  rececompcnt_tot: string;
  rececompamt_tot: string;
  receamttot_tot: string;
  planamttot_tot: string;
  totplancnttot_tot: string;
  totqty_tot: string;
  jcompcnt10_tot: string;
  totplancnt10_tot: string;
  planamt10_tot: string;
  qty10_tot: string;
  jcompcnt05_tot: string;
  jrececnt05_tot: string;
  totplancnt05_tot: string;
  planamt05_tot: string;
  qty05_tot: string;
  jrececnt10_tot: string;
  jrececnt04_tot: string;
  totplancnt04_tot: string;
  qty04_tot: string;
  jrececnt03_tot: string;
  totplancnt03_tot: string;
  planamt03_tot: string;
  qty03_tot: string;
  useamt_tot: string;
  accamt_tot: string;

  // 부서별 title
  t_amt01: string;
  t_amt01_2: string;
  t_amt02: string;
  t_amt02_2: string;
  t_amt03: string;
  t_amt04: string;
  t_amt05: string;
  t_amt10: string;
  t_receamtexec: string;
  t_wadamtexec: string;
  t_rececompamtexec: string;
  t_troubleamtexec: string;
  t_kc001amtexec: string;
  t_kc002amtexec: string;
  t_kc003amtexec: string;
  t_vlongamtexec: string;
  t_longamtexec: string;
  t_fmamtexec: string;
  t_pogamtexec: string;
  t_muamtexec: string;
  t_scamtexec: string;
  t_vlong03amtexec: string;
  t_long03amtexec: string;
  t_fm03amtexec: string;
  t_pog03amtexec: string;
  t_yu03amtexec: string;
  t_plusamtexec: string;
  t_clsamtexec: string;
  t_elecamtexec: string;
  t_businessexec: string;
  t_costexec: string;
  t_compyul: string;
  t_comp_contyul: string;
  t_outamtexec: string;
  t_outnotamtexec: string;
  t_proposeexec: string;
  t_eduamtexec: string;
  t_edureceamtexec: string;
  t_e037exec: string;
  t_e037notexec: string;
  t_minwonamtexec: string;
  t_minwonnotamtexec: string;
  t_minwoncompamtexec: string;

  // trail tap_1
  total: string;
  cnt_tot: string;
  class2p_tot: string;
  class2f_tot: string;
  flag1_tot: string;
  flag2_tot: string;
  flag3_tot: string;

  // trail
  totamt_tot: string;
  totamt2_tot: string;
  mon01_tot: string;
  mon02_tot: string;
  mon03_tot: string;
  mon04_tot: string;
  mon05_tot: string;
  mon06_tot: string;
  mon07_tot: string;
  mon08_tot: string;
  mon09_tot: string;
  mon10_tot: string;
  mon11_tot: string;
  mon12_tot: string;

  // popup trail
  popupTotal: string;
  popupData: Array<any>;

  focusedData: DepartmentModel;
  focusedIndex: number;
}

/**
 * 컨트롤러
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export class MonthResult extends PageComponent<PageProps, MonthResultState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  tabs: Array<TabModel>;

  tabIndex: number = 0;

  infinity?: InfinityRetrieve;

  gridDepartment: React.RefObject<GridLayout> = React.createRef();

  gridIndividual: React.RefObject<GridLayout> = React.createRef();

  gridMonth: React.RefObject<GridLayout> = React.createRef();

  modalTable: React.RefObject<TableLayout> = React.createRef();

  modalTable2: React.RefObject<TableLayout> = React.createRef();

  additionalTitle: RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalTitle2: RefObject<GridLayoutAdditionalHeader> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = MonthResultTabId;
    const titles = MonthResultTabTitles;

    this.tabs = [
      new TabModel(id.Department.toString(), MdReorder, titles[id.Department]),
      new TabModel(id.Individual.toString(), FiClipboard, titles[id.Individual]),
      new TabModel(id.Month.toString(), FiCalendar, titles[id.Month]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      modalData: [],

      // popup trail
      popupTotal: '0',
      popupData: [],

      // trail tap_1
      total: '0',
      cnt_tot: '0',
      class2p_tot: '0',
      class2f_tot: '0',
      flag1_tot: '0',
      flag2_tot: '0',
      flag3_tot: '0',

      // trail
      totamt_tot: '0',
      average_tot: '0',
      mon01_tot: '0',
      mon02_tot: '0',
      mon03_tot: '0',
      mon04_tot: '0',
      mon05_tot: '0',
      mon06_tot: '0',
      mon07_tot: '0',
      mon08_tot: '0',
      mon09_tot: '0',
      mon10_tot: '0',
      mon11_tot: '0',
      mon12_tot: '0',

      plusData: '0',

      focusedData: '',
      focusedIndex: 0,
      kukcdGubun: '02',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    this.setState({ userid: user.custcd });

    // 전기식인승관리 대수 팝업
    const data = await api.dropdown('wf_dd_e030');
    if (!data) return;
    this.setState({ evcdList: data.items });

    // 팝업(결과)
    const data2 = await api.dropdown('wf_dd_ca510_018_01');
    if (!data) return;
    this.setState({ resultss: data2.items });

    // 팝업(검사기관)
    const data3 = await api.dropdown('wf_dd_ca510_011_01');
    if (!data) return;
    this.setState({ kcspnms: data3.items });

    // 팝업(진행)
    const data4 = await api.dropdown('wf_dd_ca510_012_01');
    if (!data) return;
    this.setState({ states: data4.items });
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        mon: this.state.yearmon,
        as_kukcd: this.state.kukcdGubun,
      },
      (params) => api.fxExec(
        `${MonthResultTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [MonthResultTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[MonthResultTabDataStateNames[i]],
              ...items.map((x: any) => new MonthResultTabModels[i](x)),
            ],
          });
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [MonthResultTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieveAll();
        // @ts-ignore
        if (this.state[MonthResultTabDataStateNames[i]] && this.state[MonthResultTabDataStateNames[i]]?.length > 0) {
          this.currentGrid.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [MonthResultTabDataStateNames[i]]: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.currentGrid.current?.setFocus(0);

      this.SS({
        // 월별합계 trail
        totamt_tot: this.infinity?.box?.totamt_tot || '0',
        totamt2_tot: this.infinity?.box?.totamt2_tot || '0',
        mon01_tot: this.infinity?.box?.mon01_tot || '0',
        mon02_tot: this.infinity?.box?.mon02_tot || '0',
        mon03_tot: this.infinity?.box?.mon03_tot || '0',
        mon04_tot: this.infinity?.box?.mon04_tot || '0',
        mon05_tot: this.infinity?.box?.mon05_tot || '0',
        mon06_tot: this.infinity?.box?.mon06_tot || '0',
        mon07_tot: this.infinity?.box?.mon07_tot || '0',
        mon08_tot: this.infinity?.box?.mon08_tot || '0',
        mon09_tot: this.infinity?.box?.mon09_tot || '0',
        mon10_tot: this.infinity?.box?.mon10_tot || '0',
        mon11_tot: this.infinity?.box?.mon11_tot || '0',
        mon12_tot: this.infinity?.box?.mon12_tot || '0',

        // 부서별 trail
        qty01_tot: this.infinity?.box?.qty01_tot || '0',
        planamt01_tot: this.infinity?.box?.planamt01_tot || '0',
        totplancnt01_tot: this.infinity?.box?.totplancnt01_tot || '0',
        jrececnt01_tot: this.infinity?.box?.jrececnt01_tot || '0',
        jcompcnt01_tot: this.infinity?.box?.jcompcnt01_tot || '0',
        qty01_2_tot: this.infinity?.box?.qty01_2_tot || '0',
        totplancnt01_2_tot: this.infinity?.box?.totplancnt01_2_tot || '0',
        jrececnt01_2_tot: this.infinity?.box?.jrececnt01_2_tot || '0',
        jcompcnt01_2_tot: this.infinity?.box?.jcompcnt01_2_tot || '0',
        qty02_2_tot: this.infinity?.box?.qty02_2_tot || '0',
        planamt04_tot: this.infinity?.box?.planamt04_tot || '0',
        qty02_tot: this.infinity?.box?.qty02_tot || '0',
        jrececnt02_2_tot: this.infinity?.box?.jrececnt02_2_tot || '0',
        jcompcnt02_tot: this.infinity?.box?.jcompcnt02_tot || '0',
        totplancnt02_2_tot: this.infinity?.box?.totplancnt02_2_tot || '0',
        totplancnt02_tot: this.infinity?.box?.totplancnt02_tot || '0',
        jrececnt02_tot: this.infinity?.box?.jrececnt02_tot || '0',
        planamt02_2_tot: this.infinity?.box?.planamt02_2_tot || '0',
        jcompcnt02_2_tot: this.infinity?.box?.jcompcnt02_2_tot || '0',
        jcompcnt03_tot: this.infinity?.box?.jcompcnt03_tot || '0',
        jcompcnt04_tot: this.infinity?.box?.jcompcnt04_tot || '0',
        planamt01_2_tot: this.infinity?.box?.planamt01_2_tot || '0',
        planamt02_tot: this.infinity?.box?.planamt02_tot || '0',
        minustot_tot: this.infinity?.box?.minustot_tot || '0',
        minus_tot: this.infinity?.box?.minus_tot || '0',
        talenttot_tot: this.infinity?.box?.talenttot_tot || '0',
        rece_talent_tot: this.infinity?.box?.rece_talent_tot || '0',
        give_talent_tot: this.infinity?.box?.give_talent_tot || '0',
        talent_tot: this.infinity?.box?.talent_tot || '0',
        e037tot_tot: this.infinity?.box?.e037tot_tot || '0',
        e037not_tot: this.infinity?.box?.e037not_tot || '0',
        eduamttot_tot: this.infinity?.box?.eduamttot_tot || '0',
        edureceamt_tot: this.infinity?.box?.edureceamt_tot || '0',
        edurececnt_tot: this.infinity?.box?.edurececnt_tot || '0',
        eduamt_tot: this.infinity?.box?.eduamt_tot || '0',
        educnt_tot: this.infinity?.box?.educnt_tot || '0',
        proposetot_tot: this.infinity?.box?.proposetot_tot || '0',
        propose_tot: this.infinity?.box?.propose_tot || '0',
        outamttot_tot: this.infinity?.box?.outamttot_tot || '0',
        outdatecnt_tot: this.infinity?.box?.outdatecnt_tot || '0',
        outcnt_tot: this.infinity?.box?.outcnt_tot || '0',
        outdatenotcnt_tot: this.infinity?.box?.outdatenotcnt_tot || '0',
        outnotcnt_tot: this.infinity?.box?.outnotcnt_tot || '0',
        comp_contamttot_tot: this.infinity?.box?.comp_contamttot_tot || '0',
        comp_contamt_tot: this.infinity?.box?.comp_contamt_tot || '0',
        comp_iamttot_tot: this.infinity?.box?.comp_iamttot_tot || '0',
        comp_uamt_tot: this.infinity?.box?.comp_uamt_tot || '0',
        comp_iamt_tot: this.infinity?.box?.comp_iamt_tot || '0',
        compamt_tot: this.infinity?.box?.compamt_tot || '0',
        costamt_tot: this.infinity?.box?.costamt_tot || '0',
        costcnt_tot: this.infinity?.box?.costcnt_tot || '0',
        businessamt_tot: this.infinity?.box?.businessamt_tot || '0',
        businesscnt_tot: this.infinity?.box?.businesscnt_tot || '0',
        contamttot_tot: this.infinity?.box?.contamttot_tot || '0',
        elecamt_tot: this.infinity?.box?.elecamt_tot || '0',
        eleccnt_tot: this.infinity?.box?.eleccnt_tot || '0',
        clsamt_tot: this.infinity?.box?.clsamt_tot || '0',
        clscnt_tot: this.infinity?.box?.clscnt_tot || '0',
        yu03amt_tot: this.infinity?.box?.yu03amt_tot || '0',
        yucnt03_tot: this.infinity?.box?.yucnt03_tot || '0',
        plusamt_tot: this.infinity?.box?.plusamt_tot || '0',
        pluscnt_tot: this.infinity?.box?.pluscnt_tot || '0',
        pog03amt_tot: this.infinity?.box?.pog03amt_tot || '0',
        pogcnt03_tot: this.infinity?.box?.pogcnt03_tot || '0',
        fm03amt_tot: this.infinity?.box?.fm03amt_tot || '0',
        fmcnt03_tot: this.infinity?.box?.fmcnt03_tot || '0',
        long03amt_tot: this.infinity?.box?.long03amt_tot || '0',
        longcnt03_tot: this.infinity?.box?.longcnt03_tot || '0',
        vlong03amt_tot: this.infinity?.box?.vlong03amt_tot || '0',
        vlongcnt03_tot: this.infinity?.box?.vlongcnt03_tot || '0',
        scamt_tot: this.infinity?.box?.scamt_tot || '0',
        sccnt_tot: this.infinity?.box?.sccnt_tot || '0',
        muamt_tot: this.infinity?.box?.muamt_tot || '0',
        mucnt_tot: this.infinity?.box?.mucnt_tot || '0',
        pogamt_tot: this.infinity?.box?.pogamt_tot || '0',
        pogcnt_tot: this.infinity?.box?.pogcnt_tot || '0',
        fmamt_tot: this.infinity?.box?.fmamt_tot || '0',
        fmcnt_tot: this.infinity?.box?.fmcnt_tot || '0',
        longamt_tot: this.infinity?.box?.longamt_tot || '0',
        longcnt_tot: this.infinity?.box?.longcnt_tot || '0',
        vlongamt_tot: this.infinity?.box?.vlongamt_tot || '0',
        vlongcnt_tot: this.infinity?.box?.vlongcnt_tot || '0',
        kcamttot_tot: this.infinity?.box?.kcamttot_tot || '0',
        kc003amt_tot: this.infinity?.box?.kc003amt_tot || '0',
        kc003cnt_tot: this.infinity?.box?.kc003cnt_tot || '0',
        kc002amt_tot: this.infinity?.box?.kc002amt_tot || '0',
        kc002cnt_tot: this.infinity?.box?.kc002cnt_tot || '0',
        kc001amt_tot: this.infinity?.box?.kc001amt_tot || '0',
        kc001cnt_tot: this.infinity?.box?.kc001cnt_tot || '0',
        minwonamttot_tot: this.infinity?.box?.minwonamttot_tot || '0',
        minwoncompcnt_tot: this.infinity?.box?.minwoncompcnt_tot || '0',
        minwonnotcnt_tot: this.infinity?.box?.minwonnotcnt_tot || '0',
        minwoncnt_tot: this.infinity?.box?.minwoncnt_tot || '0',
        troubleamtexectot_tot: this.infinity?.box?.troubleamtexectot_tot || '0',
        troubletot_tot: this.infinity?.box?.troubletot_tot || '0',
        trouble_c_tot: this.infinity?.box?.trouble_c_tot || '0',
        trouble_tot: this.infinity?.box?.trouble_tot || '0',
        rececnt_tot: this.infinity?.box?.rececnt_tot || '0',
        receamt_tot: this.infinity?.box?.receamt_tot || '0',
        wadcnt_tot: this.infinity?.box?.wadcnt_tot || '0',
        wadamt_tot: this.infinity?.box?.wadamt_tot || '0',
        rececnt_ex_tot: this.infinity?.box?.rececnt_ex_tot || '0',
        wadcnt_ex_tot: this.infinity?.box?.wadcnt_ex_tot || '0',
        rececompcnt_tot: this.infinity?.box?.rececompcnt_tot || '0',
        rececompamt_tot: this.infinity?.box?.rececompamt_tot || '0',
        receamttot_tot: this.infinity?.box?.receamttot_tot || '0',
        planamttot_tot: this.infinity?.box?.planamttot_tot || '0',
        totplancnttot_tot: this.infinity?.box?.totplancnttot_tot || '0',
        totqty_tot: this.infinity?.box?.totqty_tot || '0',
        jcompcnt10_tot: this.infinity?.box?.jcompcnt10_tot || '0',
        totplancnt10_tot: this.infinity?.box?.totplancnt10_tot || '0',
        planamt10_tot: this.infinity?.box?.planamt10_tot || '0',
        qty10_tot: this.infinity?.box?.qty10_tot || '0',
        jcompcnt05_tot: this.infinity?.box?.jcompcnt05_tot || '0',
        jrececnt05_tot: this.infinity?.box?.jrececnt05_tot || '0',
        totplancnt05_tot: this.infinity?.box?.totplancnt05_tot || '0',
        planamt05_tot: this.infinity?.box?.planamt05_tot || '0',
        qty05_tot: this.infinity?.box?.qty05_tot || '0',
        jrececnt10_tot: this.infinity?.box?.jrececnt10_tot || '0',
        jrececnt04_tot: this.infinity?.box?.jrececnt04_tot || '0',
        totplancnt04_tot: this.infinity?.box?.totplancnt04_tot || '0',
        qty04_tot: this.infinity?.box?.qty04_tot || '0',
        jrececnt03_tot: this.infinity?.box?.jrececnt03_tot || '0',
        totplancnt03_tot: this.infinity?.box?.totplancnt03_tot || '0',
        planamt03_tot: this.infinity?.box?.planamt03_tot || '0',
        qty03_tot: this.infinity?.box?.qty03_tot || '0',
        useamt_tot: this.infinity?.box?.useamt_tot || '0',
        accamt_tot: this.infinity?.box?.accamt_tot || '0',
        cnt_tot: this.infinity?.box?.cnt_tot || '0',

        // 부서별 title
        t_amt01: this.infinity?.box?.t_amt01 || '0',
        t_amt01_2: this.infinity?.box?.t_amt01_2 || '0',
        t_amt02: this.infinity?.box?.t_amt02 || '0',
        t_amt02_2: this.infinity?.box?.t_amt02_2 || '0',
        t_amt03: this.infinity?.box?.t_amt03 || '0',
        t_amt04: this.infinity?.box?.t_amt04 || '0',
        t_amt05: this.infinity?.box?.t_amt05 || '0',
        t_amt10: this.infinity?.box?.t_amt10 || '0',
        t_receamtexec: this.infinity?.box?.t_receamtexec || '0',
        t_wadamtexec: this.infinity?.box?.t_wadamtexec || '0',
        t_rececompamtexec: this.infinity?.box?.t_rececompamtexec || '0',
        t_troubleamtexec: this.infinity?.box?.t_troubleamtexec || '0',
        t_kc001amtexec: this.infinity?.box?.t_kc001amtexec || '0',
        t_kc002amtexec: this.infinity?.box?.t_kc002amtexec || '0',
        t_kc003amtexec: this.infinity?.box?.t_kc003amtexec || '0',
        t_vlongamtexec: this.infinity?.box?.t_vlongamtexec || '0',
        t_longamtexec: this.infinity?.box?.t_longamtexec || '0',
        t_fmamtexec: this.infinity?.box?.t_fmamtexec || '0',
        t_pogamtexec: this.infinity?.box?.t_pogamtexec || '0',
        t_muamtexec: this.infinity?.box?.t_muamtexec || '0',
        t_scamtexec: this.infinity?.box?.t_scamtexec || '0',
        t_vlong03amtexec: this.infinity?.box?.t_vlong03amtexec || '0',
        t_long03amtexec: this.infinity?.box?.t_long03amtexec || '0',
        t_fm03amtexec: this.infinity?.box?.t_fm03amtexec || '0',
        t_pog03amtexec: this.infinity?.box?.t_pog03amtexec || '0',
        t_yu03amtexec: this.infinity?.box?.t_yu03amtexec || '0',
        t_plusamtexec: this.infinity?.box?.t_plusamtexec || '0',
        t_clsamtexec: this.infinity?.box?.t_clsamtexec || '0',
        t_elecamtexec: this.infinity?.box?.t_elecamtexec || '0',
        t_businessexec: this.infinity?.box?.t_businessexec || '0',
        t_costexec: this.infinity?.box?.t_costexec || '0',
        t_compyul: this.infinity?.box?.t_compyul || '0',
        t_comp_contyul: this.infinity?.box?.t_comp_contyul || '0',
        t_outamtexec: this.infinity?.box?.t_outamtexec || '0',
        t_outnotamtexec: this.infinity?.box?.t_outnotamtexec || '0',
        t_proposeexec: this.infinity?.box?.t_proposeexec || '0',
        t_eduamtexec: this.infinity?.box?.t_eduamtexec || '0',
        t_edureceamtexec: this.infinity?.box?.t_edureceamtexec || '0',
        t_e037exec: this.infinity?.box?.t_e037exec || '0',
        t_e037notexec: this.infinity?.box?.t_e037notexec || '0',
        t_minwonamtexec: this.infinity?.box?.t_minwonamtexec || '0',
        t_minwonnotamtexec: this.infinity?.box?.t_minwonnotamtexec || '0',
        t_minwoncompamtexec: this.infinity?.box?.t_minwoncompamtexec || '0',
      });
    });
    await this.currentGrid.current?.forceRepaint();
    this.currentGrid.current?.setFocus(0);
  }

  @computed
  get currentGrid(): RefObject<GridLayout> {
    return [
      this.gridDepartment,
      this.gridIndividual,
      this.gridMonth,
    ][this.tabIndex];
  }

  @action
  onRowFocusEvent(item: any) {
    const i = this.tabIndex;
    // @ts-ignore
    this.setState({
      [MonthResultFocusedStateNames[i]]: item,
    });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    const i = this.tabIndex;
    this.updatedRows = updatedRows;
    // @ts-ignore
    this.setState({ [MonthResultTabUpdatesStateNames[i]]: rows });
  }

  @action
  async onRefreshRetrieve() {
    const { actionStore: api } = this.props;
    await api.fxExec(
      'wb_refresh',
      {
        mon: this.state.yearmon,
        kukcd: this.state.kukcdGubun,
      },
    );
    await this.onRetrieveEvent();
  }

  @action
  onDoubleClickEvent(isOpen: boolean, item: string, title: string) {
    switch (item) {
      case 'e035_qty01':
        this.setState({ e035_qty01_state: isOpen });
        break;
      case 'e035_totplancnt01':
        this.setState({ e035_totplancnt01_state: isOpen });
        break;
      case 'e035_jrececnt01':
        this.setState({ e035_jrececnt01_state: isOpen });
        break;
      case 'e035_jcompcnt01':
        this.setState({ e035_jcompcnt01_state: isOpen });
        break;
      case 'e035_qty01_2':
        this.setState({ e035_qty01_2_state: isOpen });
        break;
      case 'e035_totplancnt01_2':
        this.setState({ e035_totplancnt01_2_state: isOpen });
        break;
      case 'e035_jrececnt01_2':
        this.setState({ e035_jrececnt01_2_state: isOpen });
        break;
      case 'e035_jcompcnt01_2':
        this.setState({ e035_jcompcnt01_2_state: isOpen });
        break;
      case 'e035_qty02':
        this.setState({ e035_qty02_state: isOpen });
        break;
      case 'e035_totplancnt02':
        this.setState({ e035_totplancnt02_state: isOpen });
        break;
      case 'e035_jrececnt02':
        this.setState({ e035_jrececnt02_state: isOpen });
        break;
      case 'e035_jcompcnt02':
        this.setState({ e035_jcompcnt02_state: isOpen });
        break;
      case 'e035_qty02_2':
        this.setState({ e035_qty02_2_state: isOpen });
        break;
      case 'e035_totplancnt02_2':
        this.setState({ e035_totplancnt02_2_state: isOpen });
        break;
      case 'e035_jrececnt02_2':
        this.setState({ e035_jrececnt02_2_state: isOpen });
        break;
      case 'e035_jcompcnt02_2':
        this.setState({ e035_jcompcnt02_2_state: isOpen });
        break;
      case 'e035_qty03':
        this.setState({ e035_qty03_state: isOpen });
        break;
      case 'e035_totplancnt03':
        this.setState({ e035_totplancnt03_state: isOpen });
        break;
      case 'e035_jrececnt03':
        this.setState({ e035_jrececnt03_state: isOpen });
        break;
      case 'e035_jcompcnt03':
        this.setState({ e035_jcompcnt03_state: isOpen });
        break;
      case 'e035_qty04':
        this.setState({ e035_qty04_state: isOpen });
        break;
      case 'e035_totplancnt04':
        this.setState({ e035_totplancnt04_state: isOpen });
        break;
      case 'e035_jrececnt04':
        this.setState({ e035_jrececnt04_state: isOpen });
        break;
      case 'e035_jcompcnt04':
        this.setState({ e035_jcompcnt04_state: isOpen });
        break;
      case 'e035_qty05':
        this.setState({ e035_qty05_state: isOpen });
        break;
      case 'e035_totplancnt05':
        this.setState({ e035_totplancnt05_state: isOpen });
        break;
      case 'e035_jrececnt05':
        this.setState({ e035_jrececnt05_state: isOpen });
        break;
      case 'e035_jcompcnt05':
        this.setState({ e035_jcompcnt05_state: isOpen });
        break;
      case 'e035_qty10':
        this.setState({ e035_qty10_state: isOpen });
        break;
      case 'e035_totplancnt10':
        this.setState({ e035_totplancnt10_state: isOpen });
        break;
      case 'e035_jrececnt10':
        this.setState({ e035_jrececnt10_state: isOpen });
        break;
      case 'e035_jcompcnt10':
        this.setState({ e035_jcompcnt10_state: isOpen });
        break;
      case 'e401_rececnt':
        this.setState({ e401_rececnt_state: isOpen });
        break;
      case 'e401_wadcnt':
        this.setState({ e401_wadcnt_state: isOpen });
        break;
      case 'e401_rececnt_ex':
        this.setState({ e401_rececnt_ex_state: isOpen });
        break;
      case 'e401_wadcnt_ex':
        this.setState({ e401_wadcnt_ex_state: isOpen });
        break;
      case 'e401_rececompcnt':
        this.setState({ e401_rececompcnt_state: isOpen });
        break;
      case 'e401_trouble':
        this.setState({ e401_trouble_state: isOpen });
        break;
      case 'e401_trouble_c':
        this.setState({ e401_trouble_c_state: isOpen });
        break;
      case 'e301_minwoncnt':
        this.setState({ e301_minwoncnt_state: isOpen });
        break;
      case 'e301_minwonnotcnt':
        this.setState({ e301_minwonnotcnt_state: isOpen });
        break;
      case 'e301_minwoncompcnt':
        this.setState({ e301_minwoncompcnt_state: isOpen });
        break;
      case 'e073_kc001cnt':
        this.setState({ e073_kc001cnt_state: isOpen });
        break;
      case 'e073_kc002cnt':
        this.setState({ e073_kc002cnt_state: isOpen });
        break;
      case 'e073_kc003cnt':
        this.setState({ e073_kc003cnt_state: isOpen });
        break;
      case 'e101_vlongcnt':
        this.setState({ e101_vlongcnt_state: isOpen });
        break;
      case 'e101_longcnt':
        this.setState({ e101_longcnt_state: isOpen });
        break;
      case 'e101_fmcnt':
        this.setState({ e101_fmcnt_state: isOpen });
        break;
      case 'e101_pogcnt':
        this.setState({ e101_pogcnt_state: isOpen });
        break;
      case 'e101_mucnt':
        this.setState({ e101_mucnt_state: isOpen });
        break;
      case 'e101_sccnt':
        this.setState({ e101_sccnt_state: isOpen });
        break;
      case 'e101_vlongcnt03':
        this.setState({ e101_vlongcnt03_state: isOpen });
        break;
      case 'e101_longcnt03':
        this.setState({ e101_longcnt03_state: isOpen });
        break;
      case 'e101_fmcnt03':
        this.setState({ e101_fmcnt03_state: isOpen });
        break;
      case 'e101_pogcnt03':
        this.setState({ e101_pogcnt03_state: isOpen });
        break;
      case 'e101_pluscnt':
        this.setState({ e101_pluscnt_state: isOpen });
        break;
      case 'e101_yucnt03':
        this.setState({ e101_yucnt03_state: isOpen });
        break;
      case 'e101_clscnt':
        this.setState({ e101_clscnt_state: isOpen });
        break;
      case 'e101_eleccnt':
        this.setState({ e101_eleccnt_state: isOpen });
        break;
      case 'b501_businesscnt':
        this.setState({ b501_businesscnt_state: isOpen });
        break;
      case 'e451_costcnt':
        this.setState({ e451_costcnt_state: isOpen });
        break;
      case 'e471-misuse_compamt':
        this.setState({ e471_misuse_compamt_state: isOpen });
        break;
      case 'e471-contuse_compamt':
        this.setState({ e471_contuse_compamt_state: isOpen });
        break;
      case 'pb401_out':
        this.setState({ pb401_out_state: isOpen });
        break;
      case 'pb401_out2':
        this.setState({ pb401_out_state: isOpen });
        break;
      case 'pb403_propose':
        this.setState({ pb403_propose_state: isOpen });
        break;
      case 'pb404_educnt':
        this.setState({ pb404_educnt_state: isOpen });
        break;
      case 'pb404_edurececnt':
        this.setState({ pb404_edurececnt_state: isOpen });
        break;
      case 'e037not':
        this.setState({ e037not_state: isOpen });
        break;
      case 'give_talent':
        this.setState({ give_talent_state: isOpen });
        break;
      case 'rece_talent':
        this.setState({ rece_talent_state: isOpen });
        break;
      case 'minus':
        this.setState({ minus_state: isOpen });
        break;
    }
    this.onDepartmentPopup(item, title);
  }

  @action
  async onDepartmentPopup(item: string, title: string) {
    const { actionStore: api } = this.props;

    let subData;
    if (item === 'pb401_out') {
      subData = '0';
    } else if (item === 'pb401_out2') {
      subData = '1';
    }

    const data = await api.fxExec(
      title,
      {
        sub: 'w_popup_monend_incentive',
        gubun: item === 'pb401_out2' ? 'pb401_out' : item,
        mon: this.state.yearmon,
        perid: this.state.departmetsFocused?.perid,
        kmflag: subData,
      },
    );

    if (data.items) {
      this.setState({
        popupData: data.items,
        popupTotal: data?.total || '0',
      });
    } else {
      this.setState({
        popupData: [],
        popupTotal: '0',
      });
    }
    this.onPopupRowFocusEvent(this.state.popupData[0]);
  }

  @action
  onConfirmCheck() {
    const { publicStore } = this.props;

    if (publicStore.user.userid === '1') {
      ConfirmWarning.show('확인', '본인것만 확인할수 있습니다.');
    }
  }

  @action
  async onModalEvent(isOpen: boolean) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_calculator', { mon: this.state.yearmon });

    if (data?.titlebox === '성공') {
      this.setState({ modalState: isOpen });
      await this.onMondalRetrive();
    }
  }

  @action
  async onMondalRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_monend_incentive_exec',
        mon: this.state.yearmon,
      },
    );
    if (data?.items) {
      this.setState({
        modalData: data.items,
      }, async () => {
        await this.modalTable.current?.update();
        this.modalTable2.current?.update();
      });
    }
  }

  @action
  onPopupRowFocusEvent(item: PopupModel) {
    this.setState({ popupFocused: item });
  }

  @action
  onPopupUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows2 = updatedRows;

    this.setState({ modalData: rows });
  }

  @action
  // eslint-disable-next-line consistent-return
  async onMondalSave(item: any) {
    const { actionStore: api } = this.props;

    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인', '이 금액으로 적용하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return this.onModalEvent(false);

    if (await api.save({
      sub: 'w_popup_monend_incentive_exec',
      mon: this.state.yearmon,
      amt01: item[0].amt01,
      amt01_2: item[0].amt01_2,
      amt02: item[0].amt02,
      amt02_2: item[0].amt02_2,
      amt03: item[0].amt03,
      amt04: item[0].amt04,
      amt05: item[0].amt05,
      amt10: item[0].amt10,
      receamtexec: item[0].receamtexec,
      wadamtexec: item[0].wadamtexec,
      rececompamtexec: item[0].rececompamtexec,
      troubleamtexec: item[0].troubleamtexec,
      minwonamtexec: item[0].minwonamtexec,
      minwonnotamtexec: item[0].minwonnotamtexec,
      minwoncompamtexec: item[0].minwoncompamtexec,
      kc001amtexec: item[0].kc001amtexec,
      kc002amtexec: item[0].kc002amtexec,
      kc003amtexec: item[0].kc003amtexec,
      vlongamtexec: item[0].vlongamtexec,
      longamtexec: item[0].longamtexec,
      fmamtexec: item[0].fmamtexec,
      pogamtexec: item[0].pogamtexec,
      muamtexec: item[0].muamtexec,
      vlong03amtexec: item[0].vlong03amtexec,
      long03amtexec: item[0].long03amtexec,
      fm03amtexec: item[0].fm03amtexec,
      pog03amtexec: item[0].pog03amtexec,
      yu03amtexec: item[0].yu03amtexec,
      plusamtexec: item[0].plusamtexec,
      clsamtexec: item[0].clsamtexec,
      elecamtexec: item[0].elecamtexec,
      businessexec: item[0].businessexec,
      costexec: item[0].costexec,
      compyul: item[0].compyul,
      comp_contyul: item[0].comp_contyul,
      outamtexec: item[0].outamtexec,
      outnotamtexec: item[0].outnotamtexec,
      proposeexec: item[0].proposeexec,
      eduamtexec: item[0].eduamtexec,
      edureceamtexec: item[0].edureceamtexec,
      e037exec: item[0].e037exec,
      e037notexec: item[0].e037notexec,
      talentexec: item[0].talentexec,
    }, true)) {
      await this.onModalPlus(false);
      await this.onModalEvent(false);
      await this.onRefreshRetrieve();
    }
  }

  @action
  async onMinusBtn(isOpen: boolean) {
    const { user } = this.props.publicStore;
    if (user.custcd === 'youil_s') {
      if (user.perid !== '00001' && user.perid !== '00005') {
        ConfirmWarning.show('확인', '대표님만 확인하실수 있습니다.');
        return;
      }
    } else if (user.perid !== '00001') {
      ConfirmWarning.show('확인', '대표님만 확인하실수 있습니다.');
      return;
    }
    this.setState({ onModalMinusBtn: isOpen });
  }

  @action
  async onMinusOkBtn() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    await api.fxExec(
      'save',
      {
        sub: 'w_popup_monend_minus',
        mon: this.state.yearmon,
        // @ts-ignore
        perid: this.state[MonthResultFocusedStateNames[i]]?.perid,
        remark: this.state.minusDate?.remark,
        newrow: this.state.minusDate?.newrow,
      },
    );
  }

  @action
  async onConfirmBtn() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[MonthResultFocusedStateNames[i]]?.perid !== user.perid) {
      ConfirmWarning.show('확인', '본인것만 확인할수 있습니다.');
      return;
    }

    const data = await api.fxExec(
      'wb_ok',
      {
        mon: this.state.yearmon,
        // @ts-ignore
        perid: this.state[MonthResultFocusedStateNames[i]]?.perid,
        // @ts-ignore
        okflag: this.state[MonthResultFocusedStateNames[i]]?.okflag,
      },
    );
    if (data?.items) {
      this.setState({
        modalData: data.items,
      });
    }
  }

  @action
  async onModalPlus(isOpen: boolean, target?: DepartmentModel) {
    const { user } = this.props.publicStore;

    if (user.perid === target?.perid) {
      ConfirmWarning.show('경고', '본인에게 점수를 줄 수 없습니다!');
      return;
    }

    this.setState({ onModalPlusBtn: isOpen });

    await this.onPlusRetrieve();
  }

  @action
  async onPlusRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_monend_talent',
        mon: this.state.yearmon,
      },
    );
    if (data.remain === '0') {
      await this.SS({
        onModalPlusBtn: false,
      });
      ConfirmWarning.show('경고', '권한 점수를 모두 소진하였습니다.');
      return;
    }
    if (data) {
      this.setState({
        plusData: data,
      });
    }
  }

  @action
  async onPlusSave() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (await api.save({
      sub: 'w_popup_monend_talent',
      mon: this.state.yearmon,
      // @ts-ignore
      perid: this.state[MonthResultFocusedStateNames[i]]?.perid,
      remark: this.state.plusData.remark,
      newrow: this.state.plusData.newrow,
      remain: this.state.plusData.remain,
    }, true)) {
      await this.onRefreshRetrieve();
      // await this.onPlusRetrieve();
    }
  }

  @action
  async onMinusSave(item: string) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    ConfirmWarning.show('확인', '삭제하시겠습니까?');

    let itemData = {};

    switch (item) {
      case 'rece_talent':
        itemData = {
          give_perid: this.state.popupFocused?.give_perid,
          seq: this.state.popupFocused?.seq,
        };
        break;
      case 'give_talent':
        itemData = {
          give_perid: this.state.popupFocused?.give_perid,
          seq: this.state.popupFocused?.seq,
        };
        break;
      case 'minus':
        itemData = {
          minus: this.state.popupFocused?.minus,
          seq: this.state.popupFocused?.seq,
        };
        break;
    }

    if (await api.save({
      sub: 'w_popup_monend_incentive',
      mon: this.state.yearmon,
      // @ts-ignore
      gubun: this.state[MonthResultFocusedStateNames[i]]?.gubun,
      // @ts-ignore
      perid: this.state[MonthResultFocusedStateNames[i]]?.perid,
      ...itemData,
    }, true)) {
      this.setState({ onModalPlusBtn: false });
      await this.onPlusRetrieve();
    }
  }

  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    this.onRetrieveEvent();
  }

  render() {
    return (
      <MonthResultTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            plusData: new PopupModel({
              ...this.state.plusData,
              ...change,
            }, this.state.plusData?.isNew),
            minusDate: new PopupModel({
              ...this.state.minusDate,
              ...change,
            }, this.state.minusDate?.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
