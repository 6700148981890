import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Appointment } from './Appointment';

/**
 * 화면
 * @window w_pz006
 * @category 발령코드
 */
export const AppointmentTemplate: React.FC<TemplateProps<Appointment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'ordercd',
          text: '발령코드',
          width: 10,
        },
        {
          id: 'ordernm',
          text: '발령명',
          width: 30,
          render: (x, rowUpdate, ref) => (
            // eslint-disable-next-line max-len
            <TextBox ref={ref} value={x.ordernm} onChange={(value) => rowUpdate({ ordernm: value })} />
          ),
        },
        {
          id: 'useyn',
          text: '사용여부',
          width: 10,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remarks',
          text: '비고',
          width: 50,
        },
      ]}
      data={scope.state.data}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
