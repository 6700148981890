import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { TableLayoutHeader } from '../../../../../constants';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { SelfModel } from '../models';
import { Journal } from '../Journal';

// 사용내역
const Self: TableLayoutHeader<SelfModel, Journal>[] = [
  {
    id: 'state',
    text: '결과',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}
      >
        {
          [{ value: '1', remark: '미처리' },
            { value: '2', remark: '처리' }].filter((y) => y.value === x.state)[0]?.remark
        }
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '계획일자',
    width: 150,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        계획 {scope?.state.tabSelfPlancnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        format="####-##-##"
        value={x.plandate}
        textAlign="center"
      />
    ),
  },
  {
    id: 'rptdate',
    text: '점검일자',
    width: 150,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#00cec9' }}
      >
        점검 {scope?.state.tabSelfSuccess_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        format="####-##-##"
        value={x.plandate}
        textAlign="center"
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    trail: (_, scope) => (
      <FlexLayout>
        (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#00cec9' }}
        >
          담당점검 {scope?.state.tabSelfCompcnt_tot}
        </FlexLayout>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-green' }}
        >
          지원받음 {scope?.state.tabSelfJcomcnt_rece_tot}
        </FlexLayout>
        )
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 200,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        미처리 {scope?.state.tabSelfCompcnt_not_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'plantime',
    text: '시간',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-green)' }}
      >
        지원처리 {scope?.state.tabSelfJcomcnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.plantime}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#00cec9' }}
      >
        총점검 {scope?.state.tabSelfTotcompcnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm',
    text: '점검자',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.kcpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 300,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];

export default Self;
