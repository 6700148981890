import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve, TabModel } from '../../../../models';
import { InstallTemplate } from './Install.template';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { MainModel } from './models/Main.model';
// @ts-ignore
import { ModalModel } from './models/Modal.model';
import { FiveYearModel, MonthModel } from './models';
import { PageComponent } from '../../../../utils/layout';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface InstallState {
  // Search
  year: string;

  // Header
  data: Array<MainModel>;
  focusIndex: number;
  focused?: MainModel;

  // Tab - FiveYear
  dataFiveYear: Array<FiveYearModel>;
  fiveYearFocused?: FiveYearModel;

  // Tab - Month
  dataMonth: Array<MonthModel>;
  monthFocused?: MonthModel;

  // modal
  textDetailModal: boolean;
  modalData: Array<ModalModel>;

  // trail
  total: string;
  contamt14_tot: string;
  contamt15_tot: string;
  contamt_tot: string;
  iamt2_tot: string;
  iamt_tot: string;
  jamt_tot: string;
  misamt2_tot: string;
  misamt_tot: string;
  notmisamt_tot: string;
  qty14_tot: string;
  qty15_tot: string;
  qty_tot: string;
  rcvamt2_tot: string;
  rcvamt_tot: string;

  // tab_trail
  tab_contamt14_tot: string;
  tab_contamt15_tot: string;
  tab_contamt_tot: string;
  tab_iamt2_tot: string;
  tab_iamt_tot: string;
  tab_jamt_tot: string;
  tab_misamt2_tot: string;
  tab_misamt_tot: string;
  tab_notmisamt_tot: string;
  tab_qty14_tot: string;
  tab_qty15_tot: string;
  tab_qty_tot: string;
  tab_rcvamt2_tot: string;
  tab_rcvamt_tot: string;

  // modal trail
  modal_contamt14_tot: string;
  modal_contamt15_tot: string;
  modal_contamt_tot: string;
  modal_iamt2_tot: string;
  modal_iamt_tot: string;
  modal_jamt_tot: string;
  modal_misamt2_tot: string;
  modal_misamt_tot: string;
  modal_notmisamt_tot: string;
  modal_qty14_tot: string;
  modal_qty15_tot: string;
  modal_qty_tot: string;
  modal_rcvamt2_tot: string;
  modal_rcvamt_tot: string;
}


/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_08
 * @category 설치수주매출통계
 */
export class Install extends PageComponent<PageProps, InstallState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  tabIndex: number = 0;

  modalTab: string | undefined;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // Search
      year: Today.year(),

      // Header
      data: [],
      focusIndex: 0,

      // Tab - FiveYear
      dataFiveYear: [],

      // Tab - Month
      dataMonth: [],

      // modal
      modalData: [],

      // trail
      contamt14_tot: '',
      contamt15_tot: '',
      contamt_tot: '',
      iamt2_tot: '',
      iamt_tot: '',
      jamt_tot: '',
      misamt2_tot: '',
      misamt_tot: '',
      notmisamt_tot: '',
      qty14_tot: '',
      qty15_tot: '',
      qty_tot: '',
      rcvamt2_tot: '',
      rcvamt_tot: '',

      // tab1_trail
      tab_contamt14_tot: '',
      tab_contamt15_tot: '',
      tab_contamt_tot: '',
      tab_iamt2_tot: '',
      tab_iamt_tot: '',
      tab_jamt_tot: '',
      tab_misamt2_tot: '',
      tab_misamt_tot: '',
      tab_notmisamt_tot: '',
      tab_qty14_tot: '',
      tab_qty15_tot: '',
      tab_qty_tot: '',
      tab_rcvamt2_tot: '',
      tab_rcvamt_tot: '',

      // modal trail
      modal_contamt14_tot: '',
      modal_contamt15_tot: '',
      modal_contamt_tot: '',
      modal_iamt2_tot: '',
      modal_iamt_tot: '',
      modal_jamt_tot: '',
      modal_misamt2_tot: '',
      modal_misamt_tot: '',
      modal_notmisamt_tot: '',
      modal_qty14_tot: '',
      modal_qty15_tot: '',
      modal_qty_tot: '',
      modal_rcvamt2_tot: '',
      modal_rcvamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year || '',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new MainModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
          dataFiveYear: [],
          dataMonth: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      dataFiveYear: [],
      dataMonth: [],
    });

    await this.infinity?.retrieveAll();
    const dataDate = await this.infinity?.box;

    await this.setState({
      contamt14_tot: dataDate?.contamt14_tot || '0',
      contamt15_tot: dataDate?.contamt15_tot || '0',
      contamt_tot: dataDate?.contamt_tot || '0',
      iamt2_tot: dataDate?.iamt2_tot || '0',
      iamt_tot: dataDate?.iamt_tot || '0',
      jamt_tot: dataDate?.jamt_tot || '0',
      misamt2_tot: dataDate?.misamt2_tot || '0',
      misamt_tot: dataDate?.misamt_tot || '0',
      notmisamt_tot: dataDate?.notmisamt_tot || '0',
      qty14_tot: dataDate?.qty14_tot || '0',
      qty15_tot: dataDate?.qty15_tot || '0',
      qty_tot: dataDate?.qty_tot || '0',
      rcvamt2_tot: dataDate?.rcvamt2_tot || '0',
      rcvamt_tot: dataDate?.rcvamt_tot || '0',
    });

    if (this.state.data && this.state.data?.length > 0) {
      await this.grid.current?.setFocus(0);
      this.onRowFocusEvent(this.state.data[0], 0);
    }
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      year: this.state.year || '',
      spjangcd: this.state.focused?.spjangcd || '',
    });
  }

  @action
  onRowFocusEvent(item: MainModel, index: number) {
    this.setState({
      focusIndex: index,
      focused: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_list_RowFocuschanged`, {
        year: this.state.year || '',
        spjangcd: this.state.focused?.spjangcd || '',
      });

      switch (this.tabIndex) {
        case 0:
          this.setState({ dataFiveYear: data?.items || [] });
          break;

        case 1:
          this.setState({ dataMonth: data?.items || [] });
          break;
      }
      this.setState({
        tab_contamt14_tot: data?.contamt14_tot || '0',
        tab_contamt15_tot: data?.contamt15_tot || '0',
        tab_contamt_tot: data?.contamt_tot || '0',
        tab_iamt2_tot: data?.iamt2_tot || '0',
        tab_iamt_tot: data?.iamt_tot || '0',
        tab_jamt_tot: data?.jamt_tot || '0',
        tab_misamt2_tot: data?.misamt2_tot || '0',
        tab_misamt_tot: data?.misamt_tot || '0',
        tab_notmisamt_tot: data?.notmisamt_tot || '0',
        tab_qty14_tot: data?.qty14_tot || '0',
        tab_qty15_tot: data?.qty15_tot || '0',
        tab_qty_tot: data?.qty_tot || '0',
        tab_rcvamt2_tot: data?.rcvamt2_tot || '0',
        tab_rcvamt_tot: data?.rcvamt_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent2(item: any) {
    this.tabIndex === 0
      ? this.setState({ fiveYearFocused: item }) : this.setState({ monthFocused: item });
  }

  @action
  textModal(isOpen: boolean, item: string) {
    this.setState({ textDetailModal: isOpen });
    isOpen === true && this.modalRetrive(item);
  }

  @action
  async modalRetrive(item: string) {
    const { actionStore: api } = this.props;
    this.modalTab = item;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_e601w_sulchi_08',
        tab: item,
        mon: item === '1' ? this.state.year : item === '2'
          ? this.state.fiveYearFocused?.year : this.state.monthFocused?.mon,
        spjangcd: item === '1' ? this.state.focused?.spjangcd : item === '2'
          ? this.state.fiveYearFocused?.spjangcd : this.state.monthFocused?.spjangcd,
      });

    this.setState({
      modalData: data?.items || [],
      modal_contamt14_tot: data?.contamt14_tot || '0',
      modal_contamt15_tot: data?.contamt15_tot || '0',
      modal_contamt_tot: data?.contamt_tot || '0',
      modal_iamt2_tot: data?.iamt2_tot || '0',
      modal_iamt_tot: data?.iamt_tot || '0',
      modal_jamt_tot: data?.jamt_tot || '0',
      modal_misamt2_tot: data?.misamt2_tot || '0',
      modal_misamt_tot: data?.misamt_tot || '0',
      modal_notmisamt_tot: data?.notmisamt_tot || '0',
      modal_qty14_tot: data?.qty14_tot || '0',
      modal_qty15_tot: data?.qty15_tot || '0',
      modal_qty_tot: data?.qty_tot || '0',
      modal_rcvamt2_tot: data?.rcvamt2_tot || '0',
      modal_rcvamt_tot: data?.rcvamt_tot || '0',
    });
  }

  @action
  async onModalExcel() {
    const { actionStore: api } = this.props;

    if (this.state.modalData.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel('excel',
      {
        sub: 'w_popup_e601w_sulchi_08',
        tab: this.modalTab,
        mon: this.modalTab === '1' ? this.state.year : this.modalTab === '2'
          ? this.state.fiveYearFocused?.year : this.state.monthFocused?.mon,
        spjangcd: this.modalTab === '1' ? this.state.focused?.spjangcd : this.modalTab === '2'
          ? this.state.fiveYearFocused?.spjangcd : this.state.monthFocused?.spjangcd,
      });
  }

  @action
  async headerItemChanged(item: MainModel, key: string, value: string) {
    const { actionStore: api } = this.props;
    await api.fxExec('dw_1_itemchanged', {
      ...item,
      itemname: key,
      data: value,
      projno: item.spjangcd,
    });

    this.onRetrieveEvent();
  }

  @action
  onTabChange(_: TabModel, index: number) {
    this.tabIndex = index;
    if (this.state.focused?.spjangcd) {
      this.onRowFocusEvent(this.state.focused, this.state.focusIndex);
    }
  }

  render() {
    return <InstallTemplate
      scope={this}
    />;
  }
}
