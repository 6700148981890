import { computed } from 'mobx';

/**
 * 부품내역 모델
 * @window w_tb_ca608w_02
 * @category 발주현황
 */
export class PartModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly balseq: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly ibgflag: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly qcflag: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qcqty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly ibgqty: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly pumseq: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly pumdv: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly taxflag: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balseq = data.balseq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.remark = data.remark || '';
    this.ibgflag = data.ibgflag || '';
    this.qcflag = data.qcflag || '';
    this.qcqty = data.qcqty || '';
    this.ibgqty = data.ibgqty || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.pumseq = data.pumseq || '';
    this.pumdv = data.pumdv || '';
    this.datetime = data.datetime || '';
    this.deldate = data.deldate || '';
    this.taxflag = data.taxflag || '';
    this.projno = data.projno || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
