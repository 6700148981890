/**
 * 모델
 * @window w_jirosetup
 * @category 지로번호등록
 */
import { Fix } from '../../../../utils/string';

export class JiroModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 지로번호
   *
   * **data type** varchar(8)
   */
  jironum: string;

  /**
   * 알림1
   *
   * **data type** text
   */
  remark: string;

  /**
   * 알림2
   *
   * **data type** text
   */
  remark2: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 전자납부사용
   *
   * **data type** varchar(1)
   */
  elecflag: string;

  /**
   * 이지로아이디
   *
   * **data type** varchar(20)
   */
  egiro_id: string;

  /**
   * 이지로비번
   *
   * **data type** varchar(50)
   */
  egiro_pw: string;

  /**
   * CMS아이디
   *
   * **data type** varchar(50)
   */
  cms_id: string;

  /**
   * CMS비번
   *
   * **data type** varchar(50)
   */
  cms_pw: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.jironum = data.jironum || '';
    this.remark = Fix.newline(data.remark) || '';
    this.remark2 = Fix.newline(data.remark2) || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.elecflag = data.elecflag || '';
    this.egiro_id = data.egiro_id || '';
    this.egiro_pw = data.egiro_pw || '';
    this.cms_id = data.cms_id || '';
    this.cms_pw = data.cms_pw || '';
  }
}
