import { GLHF } from '../../../../../constants';
import { SelfCheck } from '../SelfCheck';
import { FlexLayout } from '../../../../../components';
import { Date8 } from '../../../../../utils/time';

const states = [
  { value: '1', remark: '미처리' },
  { value: '2', remark: '처리' },
  { value: '3', remark: '원격' },
];

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];


export const Tab1Header: GLHF<any, SelfCheck> = (scope) => ([
  {
    id: 'state',
    text: '결과',
    width: 7,
    render: (x) => (
      <FlexLayout
        weight={2}
        align='center'
        justify='center'
        style={{ color: x.state === '2' ? 'var(--color-blue)' : x.state === '3' ? 'var(--color-violet)' : 'var(--color-red)' }}>
        {states.filter((y) => y.value === x.state)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '계획일자',
    width: 12,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        계획 {scope.state.cnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.plandate)}
      </FlexLayout>
    ),
  },
  {
    id: 'rptdate',
    text: '점검일자',
    width: 12,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.rptdate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        점검 {scope.state.success_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        미처리: {scope.state.plan_not_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactcd',
    text: '회사구분',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.contg === '04' ? { color: 'var(--color-red)' } : {}}
      >
        {contgs.filter((y) => y.value === x.contg)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'plantime',
    text: '시간',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.plantime.substr(0, 2)}:{x.plantime.substr(2, 2)}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm2',
    text: '보조담당자',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm2}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-pink)' }}
      >
        원격: {scope.state.remote_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm',
    text: '점검자',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.kcpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm2',
    text: '보조점검자',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.kcpernm2}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
