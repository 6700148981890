import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Journal } from '../Journal';
import JournalGridModel from '../models/JournalGridModel';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export const JournalGridHeader: GLHF<JournalGridModel, Journal> = () => ([
  {
    id: 'gkccd',
    text: 'NO',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.gubun === '1' ? {
          color: '#FFFFFF',
        } : ''}
      >
        {x.gkccd}
      </FlexLayout>
    ),
  },
  {
    id: 'resultnm',
    text: '검사항목',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
        style={x.gubun === '1' ? {
          color: '#FFFFFF',
        } : ''}
      >
        {x.resultnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gkcnm',
    text: '검사항목.장치',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.gkcnm}
      </FlexLayout>
    ),
  },
  {
    id: 'aa_error',
    text: '판정기준',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.aa_error}
      </FlexLayout>
    ),
  },
  {
    id: 'statenm',
    text: '상태',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.gubun === '1' ? '' : x.statenm}
      </FlexLayout>
    ),
  },
  {
    id: 'state2',
    text: '부적합',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.state2}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '확인결과',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
