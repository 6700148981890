/**
 * 모델
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서 tab 2
 */
export class DepositPaymentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 01월
   *
   * **data type** varchar(8)
   */
  amt01: string;

  /**
   * 02월
   *
   * **data type** varchar(8)
   */
  amt02: string;

  /**
   * 03월
   *
   * **data type** varchar(8)
   */
  amt03: string;

  /**
   * 04월
   *
   * **data type** varchar(8)
   */
  amt04: string;

  /**
   * 05월
   *
   * **data type** varchar(8)
   */
  amt05: string;

  /**
   * 06월
   *
   * **data type** varchar(8)
   */
  amt06: string;

  /**
   * 07월
   *
   * **data type** varchar(8)
   */
  amt07: string;

  /**
   * 08월
   *
   * **data type** varchar(8)
   */
  amt08: string;

  /**
   * 09월
   *
   * **data type** varchar(8)
   */
  amt09: string;

  /**
   * 10월
   *
   * **data type** varchar(8)
   */
  amt10: string;

  /**
   * 11월
   *
   * **data type** varchar(8)
   */
  amt11: string;

  /**
   * 12월
   *
   * **data type** varchar(8)
   */
  amt12: string;

  /**
   * 년 합계
   *
   * **data type** varchar(8)
   */
  amttot: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  flag: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  gubun: string;

  /**
   * 구분명
   *
   * **data type** varchar(8)
   */
  gubunnm: string;

  constructor(data: any = {}) {
    this.spjangcd = data.spjangcd || '';
    this.custcd = data.custcd || '';
    this.amt01 = data.amt01 || '';
    this.amt02 = data.amt02 || '';
    this.amt03 = data.amt03 || '';
    this.amt04 = data.amt04 || '';
    this.amt05 = data.amt05 || '';
    this.amt06 = data.amt06 || '';
    this.amt07 = data.amt07 || '';
    this.amt08 = data.amt08 || '';
    this.amt09 = data.amt09 || '';
    this.amt10 = data.amt10 || '';
    this.amt11 = data.amt11 || '';
    this.amt12 = data.amt12 || '';
    this.amttot = data.amttot || '';
    this.flag = data.flag || '';
    this.gubun = data.gubun || '';
    this.gubunnm = data.gubunnm || '';
  }
}
