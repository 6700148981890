import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Management } from './Management';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';
import { Sum } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_da052w_03
 * @category 월관리대수현황-담당별
 */
export const ManagementTemplate: React.FC<TemplateProps<Management>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span>직영구분</span>
            <ComboBox
              value={scope.state.mpclafi}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '직영' },
                { value: '1', remark: '상주' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => setData({ mpclafi: option.value })}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              textAlign="center"
              value={scope.state.divicd}
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.divinm}
              readonly={true}
              onChange={(value) => setData({ divinm: value })}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <GridLayoutAdditionalHeader
          ref={scope.additionalTitle}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
        >
          <FlexLayout
            size={620}
            justify="center"
            align="center"
          >
            {scope.state.data?.year}년 관리대수
          </FlexLayout>

          <FlexLayout
            size={498}
            justify="center"
            align="center"
          >
            월목표: {Format.number(scope.state.data?.monamt || '0')}
          </FlexLayout>

          <FlexLayout
            size={550}
            justify="center"
            align="center"
          >
            년목표: {Format.number(scope.state.data?.yearamt || '0')}
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'divinm',
              text: '담당자별그룹',
              isHidden: true,
              group: true,
              value: (x) => x.divinm,
            },
            {
              id: 'divinm',
              text: '부서명',
              width: 7,
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '성명',
              width: 6,
            },
            {
              id: 'totamt',
              text: '합계',
              width: 6,
              sum: (x, data) => {
                const sumTotamt = Sum.byGroup(data, 'totamt', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumTotamt)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.totamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.totamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'average',
              text: '평균',
              width: 6,
              sum: (x, data) => {
                const sumAverage = Sum.byGroup(data, 'average', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumAverage)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.average_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.average)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon01',
              text: '01월',
              width: 6,
              sum: (x, data) => {
                const sumMon01 = Sum.byGroup(data, 'mon01', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon01)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon01_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon01)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon02',
              text: '02월',
              width: 6,
              sum: (x, data) => {
                const sumMon02 = Sum.byGroup(data, 'mon02', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon02)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon02_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon02)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon03',
              text: '03월',
              width: 6,
              sum: (x, data) => {
                const sumMon03 = Sum.byGroup(data, 'mon03', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon03)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon03_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon03)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon04',
              text: '04월',
              width: 6,
              sum: (x, data) => {
                const sumMon04 = Sum.byGroup(data, 'mon04', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon04)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon04_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon04)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon05',
              text: '05월',
              width: 6,
              sum: (x, data) => {
                const sumMon05 = Sum.byGroup(data, 'mon05', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon05)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon05_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon05)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon06',
              text: '06월',
              width: 6,
              sum: (x, data) => {
                const sumMon06 = Sum.byGroup(data, 'mon06', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon06)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon06_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon06)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon07',
              text: '07월',
              width: 6,
              sum: (x, data) => {
                const sumMon07 = Sum.byGroup(data, 'mon07', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon07)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon07_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon07)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon08',
              text: '08월',
              width: 6,
              sum: (x, data) => {
                const sumMon08 = Sum.byGroup(data, 'mon08', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon08)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon08_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon08)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon09',
              text: '09월',
              width: 6,
              sum: (x, data) => {
                const sumMon09 = Sum.byGroup(data, 'mon09', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon09)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon09_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon09)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon10',
              text: '10월',
              width: 6,
              sum: (x, data) => {
                const sumMon10 = Sum.byGroup(data, 'mon10', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon10)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon10_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon10)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon11',
              text: '11월',
              width: 6,
              sum: (x, data) => {
                const sumMon11 = Sum.byGroup(data, 'mon11', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon11)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon11_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon11)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon12',
              text: '12월',
              width: 9,
              sum: (x, data) => {
                const sumMon12 = Sum.byGroup(data, 'mon12', x, ['divinm']);
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMon12)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mon12_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mon12)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.ManagementList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
