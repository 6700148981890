import React from 'react';
import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { MonthWork } from '../MonthWork';
import { Date8 } from '../../../../../utils/time';
import { FmModel } from '../models';
import { Sum } from '../../../../../utils/array';
import { Button } from '../../../../../components/forms/Button';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

const planflags = [
  { value: '0', remark: '완료' },
  { value: '1', remark: '미달' },
];

const becontgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

// FM계획예정
const Fm: GridLayoutHeader<FmModel, MonthWork>[] = [
  {
    id: 'divinm',
    text: '부서별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.divinm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 4,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 5,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumPernm)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 21,
    sum: (x, data) => {
      const sumPlanflag = Sum.groupValue(data, 'planflag', x, '0', ['divinm']);
      const sumPlanflag2 = Sum.groupValue(data, 'planflag', x, '1', ['divinm']);
      const sumBecontgubun = Sum.groupValue(data, 'becontgubun', x, '01', ['divinm']);
      return (
        <FlexLayout
          justify="left"
          align="center"
        >
          <div>결과: (</div>
          <div style={{ color: 'var(--color-blue)' }}> 완료: {Format.number(sumPlanflag)} 건,</div>
          <div style={{ color: 'var(--color-red)' }}> 미달: {Format.number(sumPlanflag2)} 건,</div>
          <div style={{ color: 'var(--color-blue)' }}> FM계약: {Format.number(sumBecontgubun)} 건</div>
          <div>)</div>
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>결과: (</div>
        <div style={{ color: 'var(--color-blue)' }}>{scope?.state.compcnt_tot}</div>
        <div style={{ color: 'var(--color-red)' }}>{scope?.state.notcnt_tot}</div>
        <div style={{ color: 'var(--color-blue)' }}>{scope?.state.fmcnt_tot}</div>
        <div>)</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: x.becontgubun === '01' ? 'var(--color-blue)' : '' }}
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 3,
  },
  {
    id: 'planflag',
    text: '결과',
    width: 5,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
        style={{ color: x.planflag === '0' ? 'var(--color-blue)' : 'var(--color-red)' }}
      >
        {planflags.filter((y) => y.value === x.planflag)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 5,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.compflag === '1' ? '계약완료' : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'becontgubun',
    text: '계약',
    width: 5,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
        style={{ color: x.becontgubun === '01' ? 'var(--color-blue)' : '' }}
      >
        {becontgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'endmon',
    text: '만료일',
    width: 6,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.endmon)}
      </FlexLayout>
    ),
  },
  {
    id: 'qtyamt',
    text: '보수료',
    width: 6,
    sum: (x, data) => {
      const sumQtyamt = Sum.byGroup(data, 'qtyamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQtyamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qtyamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align='center'
        justify='right'
      >
        {Format.number(x.qtyamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt',
    text: '계획',
    width: 3,
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        style={{ backgroundColor: '#FAFA80' }}
      >
        <Button
          style={{
            height: '20px',
            width: '40px',
            marginTop: 10,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope?.textModal(true)}
        >
          조회
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계획계약',
    width: 5,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {becontgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt',
    text: '예상보수료',
    width: 6,
    sum: (x, data) => {
      const sumBesamt = Sum.byGroup(data, 'besamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(sumBesamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.qtysamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.besamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'addamt',
    text: '증가액',
    width: 6,
    sum: (x, data) => {
      const sumAddamt = Sum.byGroup(data, 'addamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#7F7A2C' }}
        >
          {Format.number(sumAddamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.chaamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.addamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '계획내용',
    width: 25,
    render: (x, scope) => (
      <React.Fragment>
        <FlexLayout style={{ backgroundColor: '#FAFA80' }}>
          <Button
            style={{
              height: '20px',
              width: '40px',
              marginTop: 10,
              marginLeft: 13,
            }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope?.historyPopupModal(true)}
          >
            조회
          </Button>
        </FlexLayout>
        <FlexLayout style={{ backgroundColor: '#FAFA80' }} weight={5}>
          <FlexLayout
            justify="left"
            align="center"
            style={{ marginRight: 10 }}
          >
            {x.remark}
          </FlexLayout>
        </FlexLayout>
      </React.Fragment>
    ),
  },
];

export default Fm;
