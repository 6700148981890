import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { CustomerGroupTemplate } from './CustomerGroup.template';
import { CustomerGroupModel } from './CustomerGroup.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface CustomerGroupState {
  searchQuery: string;
  data: Array<CustomerGroupModel>;
  focused?: CustomerGroupModel;
}

/**
 * 컨트롤러
 * @window w_tb_e901
 * @category 고객그룹등록
 */
export class CustomerGroup extends PageComponent<PageProps, CustomerGroupState>
  implements PageToolEvents {
  updatedRows?: Array<CustomerGroupModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new CustomerGroupModel(x))],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieve();
        this.table.current?.update(false);
        this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieve();
      this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onNewEvent() {
    ConfirmWarning.show('확인', '고객그룹등록은 새로이 추가 할 수 없습니다');
    return;

    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        new CustomerGroupModel({
          ...data,
          newCount: '1',
        }, true),
        ...this.state.data,
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `그룹코드: ${this.state.focused?.groupcd} 그룹명: ${this.state.focused?.groupnm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: CustomerGroupModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<CustomerGroupModel>, updatedRows: Array<CustomerGroupModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <CustomerGroupTemplate scope={this} />;
  }
}
