import { action } from 'mobx';
import { AskType, PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import StatusModel from './models/StatusModel';
import { StatusTemplate } from './Status.template';
import { Date6 } from '../../../../utils/time';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface SampleState {
  stmon: string;
  endmon: string;
  gubun: string;
  contflag: string;
  searchQuery: string;
  data: Array<StatusModel>;
}

/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_04
 * @category 설치공정현황
 */
export class Status extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  focusedRow?: StatusModel;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    this.state = props.state || {
      searchQuery: pageParams.searchQuery || '',
      stmon: '197001',
      endmon: Date6.make(),
      gubun: '%',
      contflag: '%',
      data: [],
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams?.searchQuery || this.state.searchQuery,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        gubun: this.state.gubun,
        contflag: this.state.contflag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new StatusModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.focusedRow = this.state.data[0];
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    await this.infinity?.retrieveAll();

    if (this.state.data.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.focusedRow = this.state.data[0];
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    let prtGubun = '1';

    const confirm = await Confirm.ask('출력', '선택해주세요', '전체', '개별');

    switch (confirm) {
      case AskType.NO:
        prtGubun = '0';
        break;

      case AskType.CANCEL:
        return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      gubun: this.state.gubun,
      contflag: this.state.contflag,
      actcd: this.focusedRow?.actcd || '',
      prt_gubun: prtGubun,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      as_nm: this.state.searchQuery,
      stmon: this.state.stmon,
      endmon: this.state.endmon,
      gubun: this.state.gubun,
      contflag: this.state.contflag,
    });
  }


  onGridRowClicked(item: StatusModel) {
    this.focusedRow = item;
  }


  render() {
    return <StatusTemplate
      scope={this}
    />;
  }
}
