import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { MonthSaleModel } from './MonthSale.model';
import { MonthSaleTemplate } from './MonthSale.template';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout, GridLayoutAdditionalHeader } from '../../../../components';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface MonthAverageState {
  year: string;
  gubun?: Array<any>;
  searchGubun?: string;
  mpclafi: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // data
  data: Array<MonthSaleModel>;
  focused?: MonthSaleModel;

  // trail
  ordmontot_tot: string;
  montot_tot: string;
  ordmon01_tot: string;
  ordmon02_tot: string;
  ordmon03_tot: string;
  ordmon04_tot: string;
  ordmon05_tot: string;
  ordmon06_tot: string;
  ordmon07_tot: string;
  ordmon08_tot: string;
  ordmon09_tot: string;
  ordmon10_tot: string;
  ordmon11_tot: string;
  ordmon12_tot: string;
  mon01_tot: string;
  mon02_tot: string;
  mon03_tot: string;
  mon04_tot: string;
  mon05_tot: string;
  mon06_tot: string;
  mon07_tot: string;
  mon08_tot: string;
  mon09_tot: string;
  mon10_tot: string;
  mon11_tot: string;
  mon12_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_02
 * @category 월매출실적현황
 */
export class MonthSale extends PageComponent<PageProps, MonthAverageState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      searchGubun: '11',
      mpclafi: '0',
      divicd: '',
      perid: '',
      data: [],

      // trail
      ordmontot_tot: '0',
      montot_tot: '0',
      ordmon01_tot: '0',
      ordmon02_tot: '0',
      ordmon03_tot: '0',
      ordmon04_tot: '0',
      ordmon05_tot: '0',
      ordmon06_tot: '0',
      ordmon07_tot: '0',
      ordmon08_tot: '0',
      ordmon09_tot: '0',
      ordmon10_tot: '0',
      ordmon11_tot: '0',
      ordmon12_tot: '0',
      mon01_tot: '0',
      mon02_tot: '0',
      mon03_tot: '0',
      mon04_tot: '0',
      mon05_tot: '0',
      mon06_tot: '0',
      mon07_tot: '0',
      mon08_tot: '0',
      mon09_tot: '0',
      mon10_tot: '0',
      mon11_tot: '0',
      mon12_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 구분
    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubun: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        gubun: this.state.searchGubun,
        mpclafi: this.state.mpclafi,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MonthSaleModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();

      this.SS({
        ordmontot_tot: this.infinity?.box?.ordmontot_tot || '0',
        montot_tot: this.infinity?.box?.montot_tot || '0',
        ordmon01_tot: this.infinity?.box?.ordmon01_tot || '0',
        ordmon02_tot: this.infinity?.box?.ordmon02_tot || '0',
        ordmon03_tot: this.infinity?.box?.ordmon03_tot || '0',
        ordmon04_tot: this.infinity?.box?.ordmon04_tot || '0',
        ordmon05_tot: this.infinity?.box?.ordmon05_tot || '0',
        ordmon06_tot: this.infinity?.box?.ordmon06_tot || '0',
        ordmon07_tot: this.infinity?.box?.ordmon07_tot || '0',
        ordmon08_tot: this.infinity?.box?.ordmon08_tot || '0',
        ordmon09_tot: this.infinity?.box?.ordmon09_tot || '0',
        ordmon10_tot: this.infinity?.box?.ordmon10_tot || '0',
        ordmon11_tot: this.infinity?.box?.ordmon11_tot || '0',
        ordmon12_tot: this.infinity?.box?.ordmon12_tot || '0',
        mon01_tot: this.infinity?.box?.mon01_tot || '0',
        mon02_tot: this.infinity?.box?.mon02_tot || '0',
        mon03_tot: this.infinity?.box?.mon03_tot || '0',
        mon04_tot: this.infinity?.box?.mon04_tot || '0',
        mon05_tot: this.infinity?.box?.mon05_tot || '0',
        mon06_tot: this.infinity?.box?.mon06_tot || '0',
        mon07_tot: this.infinity?.box?.mon07_tot || '0',
        mon08_tot: this.infinity?.box?.mon08_tot || '0',
        mon09_tot: this.infinity?.box?.mon09_tot || '0',
        mon10_tot: this.infinity?.box?.mon10_tot || '0',
        mon11_tot: this.infinity?.box?.mon11_tot || '0',
        mon12_tot: this.infinity?.box?.mon12_tot || '0',
      });

      if (this.state.data && this.state.data?.length > 0) {
        await this.grid.current?.setFocus(0);
        this.onRowFocusEvent(this.state.data[0]);
      }
    });
  }

  @action
  onRowFocusEvent(item: MonthSaleModel) {
    this.setState({ focused: item });
  }

  @action
  onClickOpenBalEnrollment() {
    this.props.publicStore.go(
      '/w_tb_e050w',
      {
        stdate: this.state.year,
      },
    );
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      gubun: this.state.searchGubun,
      mpclafi: this.state.mpclafi,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      gubun: this.state.searchGubun,
      mpclafi: this.state.mpclafi,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  render() {
    return (
      <MonthSaleTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
