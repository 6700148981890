import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { TextTransmitModel } from './TextTransmit.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models';
import { TextTransmitTemplate } from './TextTransmit.template';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface TextTransmitState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  data: Array<TextTransmitModel>;
  textTransmitList: TextTransmitModel;
  focused?: TextTransmitModel;
  telcds?: Array<any>;
  textDetailModal: boolean;
  popupData: string;
}


/**
 * 컨트롤러
 * @window w_tb_sms_report
 * @category 문자송신내역
 */
export class TextTransmit extends PageComponent<PageProps, TextTransmitState>
  implements PageToolEvents {
  updatedRows?: Array<TextTransmitModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      data: [],
      textTransmitList: new TextTransmitModel(),
    };
  }


  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          data: [],
        });

        this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, () => this.infinity?.retrieveAll());
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });
  }

  @action
  onRowFocusEvent(item: TextTransmitModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <TextTransmitTemplate
        // @ts-ignore
        scope={this}
      />
    );
  }
}
