import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { ConstructionSales } from '../ConstructionSales';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 > 5년간 수주매출내역 - 그리드 레이아웃
 * @window w_tb_e471w_04
 * @category 공사수주매출통계
 */
export const TabFiveYearGridHeader: GLHF<any, ConstructionSales> = (scope) => ([
  {
    id: 'year',
    text: '년도',
    width: 12,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.year}
      </FlexLayout>
    ),
  },
  {
    id: 'contamt',
    text: '수주금액',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'contamt12',
    text: '수주(수리공사)',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt12_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt12)}
      </FlexLayout>
    ),
  },
  {
    id: 'contamt13',
    text: '수주(부품교체)',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt13_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt13)}
      </FlexLayout>
    ),
  },
  {
    id: 'contamtmu',
    text: '수주(무상)',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#633A00' }}
      >
        {scope?.state.tab_contamtmu_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#633A00' }}
      >
        {Format.number(x.contamtmu)}
      </FlexLayout>
    ),
  },
  {
    id: 'notmisamt',
    text: '미매출',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_notmisamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.notmisamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '공사매출',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '공사입금',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '공사미수금',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_iamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.iamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'jamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          잔액
        </FlexLayout>

        <FlexLayout>
          (수주-입금)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_jamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.jamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '건수',
    width: 4,
    trail: (_) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.tab_qty_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty12',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          건수
        </FlexLayout>

        <FlexLayout>
          (공사)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 4,
    trail: (_) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.tab_qty12_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Format.number(x.qty12)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty13',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          건수
        </FlexLayout>

        <FlexLayout>
          (부품)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 4,
    trail: (_) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.tab_qty13_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Format.number(x.qty13)}
      </FlexLayout>
    ),
  },
  {
    id: 'qtymu',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          건수
        </FlexLayout>

        <FlexLayout>
          (무상)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 4,
    trail: (_) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_qtymu_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.qtymu)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt2',
    text: '메출',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_misamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt2',
    text: '입금',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_rcvamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.rcvamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt2',
    text: '미수금',
    width: 6,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_iamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.iamt2)}
      </FlexLayout>
    ),
  },
]);
