import {
  ImMinus,
  ImPlus,
} from 'react-icons/all';
import { GLHF } from '../../../../../constants';
import {
  Button,
  FlexLayout,
} from '../../../../../components';
import { Status } from '../Status';
import StatusModel from '../models/StatusModel';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e101w_05
 * @category 월별 관리대수변동내역
 */
export const StatusGridHeader: GLHF<StatusModel, Status> = (scope) => ([
  {
    id: 'gubun',
    text: 'NO',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.gubun}
      </FlexLayout>
    ),
  },
  {
    id: 'gubunnm',
    text: '구분',
    width: 80,
    render: (x) => (
      <FlexLayout>
        {x.gubunnm === '대수'
          ? <Button
            style={{
              marginTop: 7,
              marginLeft: 3,
              width: 25,
              height: 25,
            }}
            onClick={() => scope.detailBtnClick()}
          >
            {scope.state.detailBtnState === '0' ? <FlexLayout><ImPlus/></FlexLayout> : <FlexLayout><ImMinus/></FlexLayout>}
        </Button> : null}
        <FlexLayout
          align="center"
          justify="center"
          style={scope.getTitleColor(x)}
        >
          {x.gubunnm}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: '1월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon01)}
        onDoubleClick={() => scope.openDetail('01')}
      >
        {Format.number(x.mon01, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: '2월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon02)}
        onDoubleClick={() => scope.openDetail('02')}
      >
        {Format.number(x.mon02, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: '3월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon03)}
        onDoubleClick={() => scope.openDetail('03')}
      >
        {Format.number(x.mon03, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: '4월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon04)}
        onDoubleClick={() => scope.openDetail('04')}
      >
        {Format.number(x.mon04, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: '5월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon05)}
        onDoubleClick={() => scope.openDetail('05')}
      >
        {Format.number(x.mon05, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: '6월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon06)}
        onDoubleClick={() => scope.openDetail('06')}
      >
        {Format.number(x.mon06, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: '7월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon07)}
        onDoubleClick={() => scope.openDetail('07')}
      >
        {Format.number(x.mon07, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: '8월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon08)}
        onDoubleClick={() => scope.openDetail('08')}
      >
        {Format.number(x.mon08, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: '9월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon09)}
        onDoubleClick={() => scope.openDetail('09')}
      >
        {Format.number(x.mon09, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: '10월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon10)}
        onDoubleClick={() => scope.openDetail('10')}
      >
        {Format.number(x.mon10, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon11',
    text: '11월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon11)}
        onDoubleClick={() => scope.openDetail('11')}
      >
        {Format.number(x.mon11, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: '12월',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.mon12)}
        onDoubleClick={() => scope.openDetail('12')}
      >
        {Format.number(x.mon12, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'totmon',
    text: '합계',
    width: 50,
    render: (x) => (
        <FlexLayout
          align="center"
          justify="end"
          style={scope.getColor(x, x.totmon)}
        >
          {Format.number(x.totmon, true)}
        </FlexLayout>
    ),
  },
  {
    id: 'avg',
    text: '평균',
    width: 50,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={scope.getColor(x, x.aver)}
      >
        {Format.number(Math.round(Number(x.aver)), true)}
      </FlexLayout>
    ),
  },
]);
