export default class Finder {
  public static index(data: Array<any>, comp: any, column: string): number {
    if (!data || !comp || !column) {
      return 0;
    }

    for (let i = 0; i < data.length; i += 1) {
      if (data[i][column] === comp[column]) {
        return i;
      }
    }

    return 0;
  }

  public static hasNew(data: Array<any>): boolean {
    if (!data) {
      return false;
    }

    for (let i = 0; i < data.length; i += 1) {
      if (data[i]?.new === '1') {
        return true;
      }
    }

    return false;
  }
}
