import { computed } from 'mobx';

/**
 * 사용자별 접속시간 모델
 * @window w_tb_xlogin_02_02
 * @category 사용자별접속현황
 */
export class UserTimeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(4)
   */
  divinm: string;

  /**
   * ID
   *
   * **data type** varchar(1)
   */
  userid: string;

  /**
   * 사용자명
   *
   * **data type** varchar(3)
   */
  pernm: string;

  /**
   * 접속일자
   *
   * **data type** varchar(4)
   */
  londtdate: string;

  /**
   * ERP로그인시간
   *
   * **data type** varchar(3)
   */
  compute_4: string;

  /**
   * ERP로그인시간
   *
   * **data type** varchar(3)
   */
  erpendhour: string;

  /**
   * ERP로그인시간
   *
   * **data type** varchar(3)
   */
  erpendtime: string;

  /**
   * 어플로그인시간
   *
   * **data type** varchar(3)
   */
  compute_10: string;

  /**
   * 어플로그인시간
   *
   * **data type** varchar(3)
   */
  appendhour: string;

  /**
   * 어플로그인시간
   *
   * **data type** varchar(3)
   */
  appendtime: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.divinm = data.divinm || '';
    this.userid = data.userid || '';
    this.pernm = data.pernm || '';
    this.londtdate = data.londtdate || '';
    this.compute_4 = data.compute_4 || '';
    this.erpendhour = data.erpendhour || '';
    this.erpendtime = data.erpendtime || '';
    this.compute_10 = data.compute_10 || '';
    this.appendhour = data.appendhour || '';
    this.appendtime = data.appendtime || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
