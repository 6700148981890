import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Install } from '../Install';
import { InstallTabMonthGridHeader } from '../headers/InstallTabMonthGridHeader';

/**
 * 화면 > 탭 > 월별 수주매출내역
 * @window w_tb_e601w_sulchi_08
 * @category 설치수주매출통계
 */
export const InstallTabMonthTemplate: React.FC<TemplateProps<Install>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={InstallTabMonthGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataMonth}
      onRowDoubleClick={() => scope.textModal(true, '3')}
      onRowClick={(row) => scope.onRowFocusEvent2(row)}
    />
  </FlexLayout>
);
