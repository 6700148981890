import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca606_01_2
 * @category 자재청구서_청구내역
 */

export class BillDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  readonly pumseq: string;

  /**
   * 품목코드
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   * 품목명
   *
   * **data type** varchar(50)
   */
  readonly pname: string;

  /**
   * 규격
   *
   * **data type** varchar(200)
   */
  readonly psize: string;

  /**
   * 수량
   *
   * **data type** float
   */
  readonly qty: string;

  /**
   * 수량
   *
   * **data type** float
   */
  readonly jqty: string;

  /**
   * 단가
   *
   * **data type** float
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type**
   */
  readonly samt: string;

  /**
   *
   *
   * **data type**
   */
  readonly tamt: string;

  /**
   * 금액
   *
   * **data type** float
   */
  readonly mamt: string;

  /**
   * 발주여부
   *
   * **data type** char(1)
   */
  readonly balflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly demdate: string;

  /**
   *
   *
   * **data type**
   */
  readonly demnum: string;

  /**
   *
   *
   * **data type**
   */
  readonly demseq: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type**
   */
  readonly hopedate: string;

  /**
   *
   *
   * **data type**
   */
  readonly reserve: string;

  /**
   * 호기코드
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(3)
   */
  readonly equpnm: string;

  /**
   * 유무상
   *
   * **data type** varchar(3)
   */
  readonly myn: string;

  /**
   *
   *
   * **data type**
   */
  readonly outflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly napdate: string;

  /**
   * 현장명
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type**
   */
  readonly perid: string;

  /**
   *
   *
   * **data type**
   */
  readonly delqty: string;

  /**
   *
   *
   * **data type**
   */
  readonly jitime: string;

  /**
   *
   *
   * **data type**
   */
  readonly state: string;

  /**
   *
   *
   * **data type**
   */
  readonly mynremark: string;

  /**
   *
   *
   * **data type**
   */
  readonly chqty: string;

  /**
   *
   *
   * **data type**
   */
  readonly budflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly buddate: string;

  /**
   *
   *
   * **data type**
   */
  readonly budnum: string;

  /**
   *
   *
   * **data type**
   */
  readonly budseq: string;

  /**
   *
   *
   * **data type**
   */
  readonly punit: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.pumseq = data.pumseq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.qty = data.qty || '';
    this.jqty = data.jqty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.balflag = data.balflag || '';
    this.demdate = data.demdate || '';
    this.demnum = data.demnum || '';
    this.demseq = data.demseq || '';
    this.remark = data.remark || '';
    this.hopedate = data.hopedate || '';
    this.reserve = data.reserve || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.myn = data.myn || '';
    this.outflag = data.outflag || '';
    this.napdate = data.napdate || '';
    this.actcd = data.actcd || '';
    this.perid = data.perid || '';
    this.delqty = data.delqty || '';
    this.jitime = data.jitime || '';
    this.state = data.state || '';
    this.mynremark = data.mynremark || '';
    this.chqty = data.chqty || '';
    this.budflag = data.budflag || '';
    this.buddate = data.buddate || '';
    this.budnum = data.budnum || '';
    this.budseq = data.budseq || '';
    this.punit = data.punit || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
