import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
  Button,
  TabLayout,
  ModalView,
  LayoutTitle,
  CheckBox,
  SubHeader,
  CircleBadge,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Maintenance } from './Maintenance';
import { SearchBinding } from '../../../../models/common';
import {
  Date8,
  Date6,
  Date6Calculator,
  Time4,
} from '../../../../utils/time';
import { MaintenanceTab } from './Maintenance.tab';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e601w_03
 * @category 보수마감통계
 */
export const MaintenanceTemplate: React.FC<TemplateProps<Maintenance>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => scope.setState({ yearmon: value },
              () => scope.onRetrieveEvent())}
            onEnter={(value) => scope.setState({ yearmon: value },
              () => scope.onRetrieveEvent())}
            head={(<button
              onClick={() => {
                scope.setState({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                scope.setState({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => scope.setState({ divicd: value })}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                scope.setState({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.divinm}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <Button onClick={() => scope.onRefresh()}>
          <div style={{ width: 80 }}>새로고침</div>
        </Button>
      </SearchLayout>
    </FlexLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={MaintenanceTab}
          onChange={(item, index) => scope.onTabChange(item, index)}
        />
      </FlexLayout>
    </FlexLayout>


    {/* 전체, 부서, 담당자 선택 */}
    <ModalView
      isVisible={scope.state.choiceModal}
      onClose={() => scope.SS({ choiceModal: false })}
      width={520}
      height={150}
    >
      <FlexLayout
        style={{ padding: 8 }}
        isVertical={true}
      >
        <LayoutTitle>선택</LayoutTitle>
        <FlexLayout
          align="center"
          justify="center"
        >
          <FlexLayout>
            <CheckBox
              value={scope.state.choice === '1'}
              onChange={(v) => scope.setState({ choice: v ? '1' : '0' })}
            >
            </CheckBox>
            전체
          </FlexLayout>

          <FlexLayout>
            <CheckBox
              value={scope.state.choice === '2'}
              onChange={(v) => scope.setState({ choice: v ? '2' : '0' })}
            >
            </CheckBox>
            부서
          </FlexLayout>

          <FlexLayout>
            <CheckBox
              value={scope.state.choice === '3'}
              onChange={(v) => scope.setState({ choice: v ? '3' : '0' })}
            >
            </CheckBox>
            담당자
          </FlexLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.closeChoiceModal()}>확인</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>


    {/* 현장, 대수, 계약(건수) */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab1and12to15_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
      <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab1and12to15_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 10.2,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '대수',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="cetner"
                >
                  {scope.state.popup_qty_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'amt',
              text: '금액',
              width: 3,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(scope.state.popup_amt_tot)}
                </FlexLayout>
              ),
            },
            {
              id: 'contg',
              text: '계약분류',
              width: 2.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.contg === '01' ? '신규' : x.contg === '02' ? '연장' : '재계약'}
                </FlexLayout>
              ),
            },
            {
              id: 'contgubun',
              text: '계약구분',
              width: 2.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.contgubun === '01' ? 'FM계약' : x.contgubun === '02' ? '다년계약' : '일반계약'}
                </FlexLayout>
              ),
            },
            {
              id: 'stdate',
              text: '시작일',
              width: 3.2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.stdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'enddate',
              text: '종료일',
              width: 3.2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.enddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'contdivinm',
              text: '계약부서',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.contdivinm}
                </FlexLayout>
              ),
            },
            {
              id: 'contpernm',
              text: '계약자',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.contpernm}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>

    {/* 유지보스 */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab2_11_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계 [유지보수]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab2_11_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 1.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  총
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 1.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'flag',
              text: '구분',
              width: 1.2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.flag === '1' ? 'blue' : 'black' }}
                >
                  {x.flag === '0' ? '견적' : x.flag === '1' ? '실적' : ''}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  견적
                </FlexLayout>
              ),
            },
            {
              id: 'mon',
              text: '월',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date6.withDash(x.mon)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.popup_cost_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  실적
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 6,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {scope.state.popup_mis_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '실적(매출)금액',
              width: 3,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {Format.number(x.misamt === '0' ? '' : x.misamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {scope.state.popup_misamt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '실적내용',
              width: 5.4,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>

    {/* 부품교체, 수리공사 */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab2to3_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
      <LayoutTitle style={{ marginBottom: 4 }}>{scope.tab === '2' ? '월보수마감통계[부품교체]' : '월보수마감통계 [수리공사]'}</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab2to3_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 1.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  총
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 1.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'flag',
              text: '구분',
              width: 1.2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.flag === '1' ? 'blue' : 'black' }}
                >
                  {x.flag === '0' ? '견적' : x.flag === '1' ? '실적' : ''}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  견적
                </FlexLayout>
              ),
            },
            {
              id: 'mon',
              text: '월',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date6.withDash(x.mon)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.popup_cost_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  실적
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 6,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {scope.state.popup_mis_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'costamt',
              text: '견적금액',
              width: 3,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {Format.number(x.costamt === '0' ? '' : x.costamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {scope.state.popup_costamt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '실적(매출)금액',
              width: 3,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {Format.number(x.misamt === '0' ? '' : x.misamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {scope.state.popup_misamt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'cost_remark',
              text: '견적제목',
              width: 5,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'var(--color-green)' }}
                >
                  {x.cost_remark}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '실적내용',
              width: 5.4,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>


    {/* 점검 B,C건수 */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab4_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
      <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계 [점검 B,C건수]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab4_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 10.2,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpcd',
              text: '호기코드',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpcd}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'elno',
              text: '승강기번호',
              width: 2.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
                </FlexLayout>
              ),
            },
            {
              id: 'plandate',
              text: '점검계획일',
              width: 2.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.plandate)}
                </FlexLayout>
              ),
            },
            {
              id: 'rptdate',
              text: '점검일',
              width: 2.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.rptdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'bcnt',
              text: 'B건수',
              width: 2.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: '#808000' }}
                >
                  {x.bcnt === '0' ? '' : x.bcnt}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: '#808000' }}
                >
                  {scope.state.popup_bcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'ccnt',
              text: 'C건수',
              width: 2.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: '#3A8080' }}
                >
                  {x.ccnt === '0' ? '' : x.ccnt}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: '#3A8080' }}
                >
                  {scope.state.popup_ccnt_tot}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>


    {/* 고장(건수) */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab5to7_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계 [고장(건수)]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab5to7_modalGrid}
          header={[
            {
              id: 'actdivinm',
              text: '담당부서',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actdivinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  총 계
                </FlexLayout>
              ),
            },
            {
              id: 'actpernm',
              text: '담당자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actpernm}
                </FlexLayout>
              ),
            },
            {
              id: 'seq',
              text: '처리',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq}차
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  접수
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '처리자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.popup_rececnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm2',
              text: '처리자2',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm2}
                </FlexLayout>
              ),
            },
            {
              id: 'recepernm',
              text: '배정자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.recepernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  처리
                </FlexLayout>
              ),
            },
            {
              id: 'repernm',
              text: '접수자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.repernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {scope.state.popup_rececompcnt_tot === '' ? '0' : scope.state.popup_rececompcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'recedate',
              text: '접수일자',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.recedate)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'red' }}
                >
                  미처리
                </FlexLayout>
              ),
            },
            {
              id: 'recetime',
              text: '접수시간',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Time4.withDash(x.recetime)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'red' }}
                >
                  {scope.state.popup_recenotcnt_tot === '' ? '0' : scope.state.popup_recenotcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'compdate',
              text: '완료일자',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.compdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'comptime',
              text: '완료시간',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Time4.withDash(x.comptime)}
                </FlexLayout>
              ),
            },
            {
              id: 'arrivdate',
              text: '도착일자',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.arrivdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'arrivtime',
              text: '도착시간',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Time4.withDash(x.arrivtime)}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 140,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                  <CircleBadge>
                    {Number(x.wadcnt) > 1 && <span
                      style={{ backgroundColor: 'var(--color-red)' }}
                    >
                      {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
                    </span>}
                    {Number(x.lastcnt) > 1 && <span
                      style={{ backgroundColor: 'var(--color-blue)' }}
                    >
                      {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
                    </span>}
                  </CircleBadge>
                </FlexLayout>
              ),
            },
            {
              id: 'contnm',
              text: '고장내용',
              width: 140,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.contnm}
                </FlexLayout>
              ),
            },
            {
              id: 'contremark',
              text: '고장내용상세',
              width: 320,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.contremark}
                </FlexLayout>
              ),
            },
            {
              id: 'resunm',
              text: '처리내용',
              width: 140,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.resunm}
                </FlexLayout>
              ),
            },
            {
              id: 'resuremark',
              text: '처리내용상세',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.resuremark}
                </FlexLayout>
              ),
            },
            {
              id: 'remonm',
              text: '고장요인',
              width: 140,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remonm}
                </FlexLayout>
              ),
            },
            {
              id: 'remoremark',
              text: '고장요인상세',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remoremark}
                </FlexLayout>
              ),
            },
            {
              id: 'facnm',
              text: '고장원인',
              width: 140,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.facnm}
                </FlexLayout>
              ),
            },
            {
              id: 'qreqinm',
              text: '고장부위',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.greginm}
                </FlexLayout>
              ),
            },
            {
              id: 'reqinm',
              text: '고장부위상세',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.reginm}
                </FlexLayout>
              ),
            },
            {
              id: 'resultnm',
              text: '처리방법',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.resultnm}
                </FlexLayout>
              ),
            },
            {
              id: 'resutime',
              text: '대응(분)',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.resutime}
                </FlexLayout>
              ),
            },
            {
              id: 'resulttime',
              text: '처리(분)',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.resulttime}
                </FlexLayout>
              ),
            },
            {
              id: 'stoptime',
              text: '비운행(분)',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.stoptime}
                </FlexLayout>
              ),
            },
            {
              id: 'reyyyymm',
              text: '설치년도',
              width: 110,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.reyyyymm)}
                </FlexLayout>
              ),
            },
            {
              id: 'contgubun',
              text: '계약구분',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.contgubun === '01' ? 'FM계약' : x.contgubun === '02' ? '다년계약' : '일반계약'}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>


    {/* 검사(건수) */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab8to11_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
      <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계 [검사(건수)]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab8to11_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  총 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm2',
              text: '보조담당',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm2}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  처리
                </FlexLayout>
              ),
            },
            {
              id: 'statenm',
              text: '진행',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.statenm === '완료' ? 'blue' : 'black' }}
                >
                  {x.statenm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {scope.state.popup_compcnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'plandate',
              text: '검사일자',
              width: 2.4,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.plandate)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  합격 {scope.state.popup_result01_tot === '' ? '0' : scope.state.popup_result01_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'result',
              text: '결과',
              width: 2.4,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.result === '001' ? 'blue' : 'black' }}
                >
                  {scope.state.results.filter((y) => x.result === y.com_code)[0]?.com_cnam}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'green' }}
                >
                  조건부 {scope.state.popup_result03_tot === '' ? '0' : scope.state.popup_result03_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 10.6,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: 'red' }}
                >
                  불합격 {scope.state.popup_result02_tot === '' ? '0' : scope.state.popup_result02_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '구분',
              width: 1.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.gubun}
                </FlexLayout>
              ),
            },
            {
              id: 'kcdate',
              text: '검사일자',
              width: 2.4,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.kcdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'kcpernm',
              text: '검사자',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.kcpernm}
                </FlexLayout>
              ),
            },
            {
              id: 'kcspnm',
              text: '검사기관',
              width: 2.8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.kcspnm}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>


    {/* 장기미수금-미수금 */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab16_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
      <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계 [장기미수금(미수금)]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab16_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 1.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 1.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 1.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 6,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '미수금액',
              width: 1.6,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'red' }}
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'red' }}
                >
                  {Format.number(scope.state.popup_misamt_tot)}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '담당자답변내용',
              width: 8,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'hdate',
              text: '답변일',
              width: 1.9,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.hdate)}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>


    {/* 장기미수금-수금 */}
    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.tab17_modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
      <LayoutTitle style={{ marginBottom: 4 }}>월보수마감통계 [장기미수금(수금)]</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={482}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.popupDivicd}
              textAlign="center"
              onChange={(value) => scope.setState({ popupDivicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupDivicd: item.cd,
                    popupDivinm: item.cdnm,
                  });
                  scope.modalRetrieve();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.popupDivinm}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.popupPerid}
              textAlign="center"
              onChange={(value) => scope.setState({ popupPerid: value })}
              onEnter={() => scope.modalRetrieve()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    popupPerid: item.cd,
                    popupPernm: item.cdnm,
                  }, () => scope.modalRetrieve());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.popupPernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <GridLayout
          ref={scope.tab17_modalGrid}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 2.3,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                 합 계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 2.3,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.popup_cnt_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 2.3,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 7.2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'beamt',
              text: '전월미수금',
              width: 1.7,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'red' }}
                >
                  {Format.number(x.beamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'red' }}
                >
                  {Format.number(scope.state.popup_beamt_tot)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt5',
              text: '당월미수금',
              width: 3.7,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Format.number(x.iamt5)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt',
              text: '수금액',
              width: 2.5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {Format.number(x.rcvamt)}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'blue' }}
                >
                  {Format.number(scope.state.popup_rcvamt_tot)}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.modalData}
          infinityHandler={scope.modalInfinity}
        />
      </FlexLayout>
    </ModalView>

  </FlexLayout>
);
