import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { TextBox } from '../../../../../components/forms/TextBox';
import { TableLayoutHeader } from '../../../../../constants';
import { CarModel } from '../models';
import {
  PersonnelResistration,
} from '../PersonnelResistration';
import { ComboBox, ComboBoxModel, DateTextBox } from '../../../../../components';
import { Date8 } from '../../../../../utils/time';

// 경력
const Car: TableLayoutHeader<CarModel, PersonnelResistration>[] = [
  {
    id: 'seq',
    text: '순번',
    width: 8,
  },
  {
    id: 'pernm',
    text: '이름(사용자)',
    width: 15,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pernm}
        onChange={(value) => rowUpdate({ pernm: value })}
      />
    ),
  },
  {
    id: 'carpernm',
    text: '차량소유주',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.carpernm}
        onChange={(value) => rowUpdate({ carpernm: value })}
      />
    ),
  },
  {
    id: 'carnum',
    text: '차량번호',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.carnum}
        onChange={(value) => rowUpdate({ carnum: value })}
      />
    ),
  },
  {
    id: 'carname',
    text: '차명',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.carname}
        onChange={(value) => rowUpdate({ carname: value })}
      />
    ),
  },
  {
    id: 'kmflag',
    text: '유종',
    width: 10,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.kmflag || ''}
        style={{
          padding: '0 0 0 6px',
          fontSize: 12,
        }}
        data={[
          { value: '001', remark: '휘발유' },
          { value: '002', remark: '경유' },
          { value: '003', remark: 'LPG' },
          { value: '004', remark: '전기' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ kmflag: option.value })}
      />
    ),
  },
  {
    id: 'cardate',
    text: '차량등록일',
    width: 20,
    render: (x, rowUpdate, _, scope) => (
      <DateTextBox
        value={x.indate || '19700101'}
        textAlign="center"
        format="####-##-##"
        onChange={(value) => rowUpdate({ indate: value })}
        onEnter={() => scope?.onRetrieveEvent()}
        head={(<button
          onClick={() => {
            const date = new Date(Date8.withDash(x.indate));
            date.setDate(date.getDate() - 1);
            rowUpdate({ indate: Date8.make(date) });
          }}
        >
          <MdKeyboardArrowLeft size={24}/>
        </button>)}
        trail={(<button
          onClick={() => {
            const date = new Date(Date8.withDash(x.indate));
            date.setDate(date.getDate() + 1);
            rowUpdate({ indate: Date8.make(date) });
          }}
        >
          <MdKeyboardArrowRight size={24}/>
        </button>)}
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 20,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
];

export default Car;
