/**
 * 모델
 * @window w_tb_ca642w_02
 * @category 미지급현황
 */
import { computed } from 'mobx';

export class UnpaidModel {
  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  /**
   * 전일잔액
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 발생금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  sndamt: string;

  /**
   * 미지급금
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 비고
   *
   * **data type** varchar(12)
   */
  remark: string;

  /**
   * 기간
   *
   * **data type** datetime
   */
  stdatetime: string;

  /**
   * ~기간
   *
   * **data type** datetime
   */
  enddatetime: string;

  /**
   * 계약분류
   *
   * **data type** string
   */
  gubun: string;

  /**
   * 잔액체크
   *
   * **data type** string
   */
  resuck: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.beamt = data.beamt || '';
    this.mijamt = data.mijamt || '';
    this.sndamt = data.sndamt || '';
    this.resuamt = data.resuamt || '';
    this.remark = data.remark || '';
    this.stdatetime = data.stdatetime || '';
    this.enddatetime = data.enddatetime || '';
    this.gubun = data.gubun || '';
    this.resuck = data.resuck || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
