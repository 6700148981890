import * as React from 'react';
import {
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  ImageView,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  PAPERCD,
  TemplateProps,
} from '../../../../constants';
import {
  Integrate,
  IntegrateItemChangeTypes,
} from './Integrate';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import style from './Integrate.module.scss';

/**
 * 화면
 * @window w_tb_pb403
 * @category 통합문서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const IntegrateTemplate: React.FC<TemplateProps<Integrate>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={220}>
          <span>문서구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={scope.state.gubuns2?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => {
                scope.onRetrieveEvent();
              })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={400}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '제목으로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        {
          user.kukcd === '01'
            ? <FlexLayout size={300}>
            <span style={{ color: 'var(--color-red)' }}>담당</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.pernm}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
            : null
        }

        <FlexLayout/>
        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 80 }}>문서복사</div>
        </Button>

        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>
    </FlexLayout>


    <FlexLayout>
      <FlexLayout
        isVertical={true}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'appgubun',
              text: '상태',
              width: 10,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={
                      () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                    }
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'paperdate',
              text: '일자',
              width: 15,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {Date8.withDash(x.paperdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '문서구분',
              width: 15,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                >
                  {scope.state.gubuns1?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                </FlexLayout>
              ),
            },
            {
              id: 'attcnt',
              text: '첨부',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: (x.attcnt) > 0 && 'var(--color-blue)' }}
                >
                  {`${x.attcnt} 건`}
                </FlexLayout>
              ),
            },
            {
              id: 'title',
              text: '제목',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '작성자',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.integrateList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
      </FlexLayout>
      <FlexLayout
        isVertical={true}
        isScroll={true}
        disabled={!model?.custcd && !model?.isNew}
        disabledText="신규로 추가하거나 문서를 선택해주세요"
        className={style.pageLayout}
        style={{
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        <FlexLayout
          size={Global.LAYOUT_GRID_HEIGHT_1}
        >
          <table className={style.headerTable}>
            <tbody>
              <tr>
                <td rowSpan={4}>
                  <ImageView
                    src={`https://api.elmansoft.com/etc/getCustImage.php?database=${scope.state.custcd}&custcd=${scope.state.custcd}&spjangcd=${scope.state.spjangcd}`}
                  />
                </td>
                <td colSpan={2}><></></td>
                <th>문서분류</th>
                <td>{`${PAPERCD.TOTAL}/통합문서`}</td>
              </tr>
              <tr>
                <td colSpan={2} rowSpan={2}>
                  <ComboBox
                    value={model?.gubun}
                    style={{
                      padding: '0 0 0 6px',
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                    data={scope.state.gubuns2?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                    onSelect={(option) => setData({
                      gubun: option.value,
                    }, () => scope.itemChanged(IntegrateItemChangeTypes.GUBUN))}
                  />
                </td>
                <th>페이지번호</th>
                <td>
                  {`${scope.state.data.page}/${scope.state.total}페이지`}
                </td>
              </tr>
              <tr>
                <th>작성자</th>
                <td>{model?.pernm}</td>
              </tr>
              <tr>
                <td colSpan={2}><></></td>
                <th>작성일자</th>
                <td>
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={model?.paperdate}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </FlexLayout>
        <FlexLayout
          style={{ marginTop: 10 }}
          size={100}
        >
          <FlexLayout
            justify='center'
            align='center'
            isVertical={true}
            style={{ paddingTop: 10 }}
          >
            <FlexLayout
              style={{
                fontSize: 14,
                marginLeft: 100,
              }}
              justify='left'
            >
              <span>{`아래와 같이 ${scope.state.gubuns1?.filter((y) => y.com_code === model?.gubun)[0]?.com_cnam}를`}</span>
            </FlexLayout>
            <FlexLayout
              style={{
                fontSize: 14,
                marginLeft: 100,
              }}
              align='left'
              justify='left'
            >
              제출합니다.
            </FlexLayout>
          </FlexLayout>
          <FlexLayout>
            <table className={style.reportTable}>
              <tbody>
                <tr>
                  <th rowSpan={5}>결재</th>
                  <th>담당</th>
                  <th>{model?.rspnm1}</th>
                  <th>{model?.rspnm2}</th>
                  <th>{model?.rspnm3}</th>
                  <th>{model?.rspnm4}</th>
                </tr>
                <tr>
                  <td><div className={style.circle}>{model?.pernm}</div></td>
                  <td>
                    <div className={model?.appgubun1 === '101' ? style.circle : undefined}>{model?.apppernm1}</div>
                  </td>
                  <td>
                    <div className={model?.appgubun2 === '101' ? style.circle : undefined}>{model?.apppernm2}</div>
                  </td>
                  <td>
                    <div className={model?.appgubun3 === '101' ? style.circle : undefined}>{model?.apppernm3}</div>
                  </td>
                  <td>
                    <div className={model?.appgubun4 === '101' ? style.circle : undefined}>{model?.apppernm4}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    {model?.appdate
                      && <FormatTextBox
                          style={{ padding: 0 }}
                          textAlign="center"
                          format="##/## ##:##"
                          value={`${model?.appdate.substring(4, 8)}${model?.apptime.substring(0, 4)}`}
                      />
                    }
                  </td>
                  <td>
                    {model?.appdate1
                      && <FormatTextBox
                          textAlign="center"
                          format="##/## ##:##"
                          value={`${model?.appdate1.substring(4, 8)}${model?.apptime1.substring(0, 4)}`}
                      />
                    }
                  </td>
                  <td>
                    {
                      model?.appdate2
                      && <FormatTextBox
                          textAlign="center"
                          format="##/## ##:##"
                          value={`${model?.appdate2.substring(4, 8)}${model?.apptime2.substring(0, 4)}`}
                      />
                    }
                  </td>
                  <td>
                    {
                      model?.appdate3
                      && <FormatTextBox
                          textAlign="center"
                          format="##/## ##:##"
                          value={`${model?.appdate3.substring(4, 8)}${model?.apptime3.substring(0, 4)}`}
                      />
                    }
                  </td>
                  <td>
                    {
                      model?.appdate4
                      && <FormatTextBox
                          textAlign="center"
                          format="##/## ##:##"
                          value={`${model?.appdate4.substring(4, 8)}${model?.apptime4.substring(0, 4)}`}
                      />
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout
          style={{ marginTop: 10 }}
          size={750}
        >
          <table className={style.bodyTable}>
            <tbody>
              <tr>
                <th>문서번호</th>
                <td>
                  <FormatTextBox
                    format="##-######-###-####"
                    textAlign="center"
                    value={user.papernum + model?.appnum}
                  />
                </td>
                <th>직위</th>
                <td>
                  <TextBox
                    textAlign='center'
                    value={model?.rspnm}
                  />
                </td>
              </tr>
              <tr>
                <th>부서</th>
                <td>
                  <TextBox
                    textAlign='center'
                    value={model?.divinm}
                  />
                </td>
                <th>성명</th>
                <td>
                  <TextBox
                    textAlign='center'
                    value={model?.pernm}
                  />
                </td>
              </tr>
              <tr>
                <th>신청일자</th>
                <td>
                  <FlexLayout>
                    {
                      scope.state.data.isNew
                        ? <DateTextBox
                          value={model?.paperdate}
                          textAlign="center"
                          format="####-##-##"
                          onChange={(value) => setData({ paperdate: value })}
                        />
                        : <DateTextBox
                          value={model?.paperdate}
                          textAlign="center"
                          format="####-##-##"
                        />
                    }
                  </FlexLayout>
                </td>
                <th>시행일자</th>
                <td>
                  <FlexLayout>
                    <DateTextBox
                      value={model?.compdate}
                      textAlign="center"
                      format="####-##-##"
                      onChange={(value) => setData({ compdate: value })}
                    />
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th>수신참조</th>
                <td colSpan={3}>
                  <FlexLayout>
                    <TextBox
                      textAlign='left'
                      value={model?.refer}
                    />
                    <Button
                      isIcon={true}
                      onClick={() => scope.openReferSelector()}
                      style={{ marginRight: 5 }}
                    >
                      <FiArrowRightCircle size={17}/>
                    </Button>
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='left'
                    value={model?.title}
                    onChange={(value) => setData({ title: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>첨부파일</th>
                <td colSpan={3}>
                  <FlexLayout>
                    <Button
                      isIcon={true}
                      onClick={() => scope.fileModal(true)}
                      style={{ marginLeft: 5 }}
                    >
                      <RiFolder2Line size={17}/>
                    </Button>
                    <TextBox
                      style={{ color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                      value={model?.attcnt === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                    />
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th>특이사항</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='left'
                    value={model?.bigo}
                    onChange={(value) => setData({ bigo: value })}
                  >
                  </TextBox>
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  style={{ textAlign: 'center' }}
                >
                  내용 (최대 26줄까지 입력가능합니다)
                </th>
              </tr>
              <tr style={{ height: 400 }}>
                <td colSpan={4}>
                  <TextBox
                    isMultiline={true}
                    textAlign='left'
                    value={model?.remark}
                    onChange={(value) => setData({ remark: value })}
                  />
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  style={{ textAlign: 'center' }}
                >
                  결재자 지시사항
                </th>
              </tr>
              <tr style={{ height: 100 }}>
                <td colSpan={4}>
                  <TextBox
                    isMultiline={true}
                    textAlign='left'
                    value={model?.appremark}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_GRID_HEIGHT_1}
          style={{ fontSize: 14 }}
          justify='right'
          align='center'
        >
          {scope.state.spjangnm}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>

    <ModalView
      isVisible={scope.state.fileDetailModal}
      onClose={() => scope.fileModal(false)}
      width={1300}
    >
      <FlexLayout style={{ padding: 8 }}>
        <AttachmentBox
          isEnabledPreview={true}
          appnum={scope.state.data?.appnum}
          appnm={'통합문서'}
          onChangeCount={async (count) => {
            // state
            setData({ attcnt: count.toString() });
          }}
        />
      </FlexLayout>
    </ModalView>
  </FlexLayout>
  );
};
