import { TableLayoutHeader } from '../../../../../constants';
import { ExpenseModel } from '../models';
import {
  ItemChange,
  Sales,
} from '../Sales';
import { TextBox, FormatNumberTextBox, FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

// 매출상세
const Expense: TableLayoutHeader<ExpenseModel, Sales>[] = [
  {
    id: 'seq',
    text: '순서',
    width: 5,
  },
  {
    id: 'pname',
    text: '품명',
    width: 19,
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.pname}
        onChange={(value) => rowUpdate({ pname: value })}
        onEnter={(value) => scope?.onItemChange(value, ItemChange.pname, 'dw_3_itemchanged')}
      />
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.psize}
        onChange={(value) => rowUpdate({ psize: value })}
      />
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 6,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.punit}
        onChange={(value) => rowUpdate({ punit: value })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 5,
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.qty}
        onChange={(value) => scope?.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 9,
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 9,
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
      />
    ),
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 9,
    trail: (data) => {
      const sumAddamt = Sum.all(data, 'addamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAddamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={String(Number(x.addamt).toFixed(0))}
        onChange={(value) => scope?.tabAutoCalc({ ...x, addamt: value }, rowUpdate, 'addamt')}
      />
    ),
  },
  {
    id: 'misamt',
    text: '합계금액',
    width: 9,
    trail: (data) => {
      const sumMisamt = Sum.all(data, 'misamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMisamt, true)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(String(Number(x.misamt).toFixed(0)))}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 19,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
];

export default Expense;
