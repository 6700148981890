class JoinClassName {
  make(classes: any): string {
    if (typeof classes === 'string') return classes;
    return this.task(classes).join(' ');
  }

  private task(arr: any[]): string[] {
    let lstClass: string[] = [];

    arr.forEach((x: any) => {
      if (!x) return;
      if (typeof x === 'string') {
        lstClass.push(x);
      } else {
        lstClass = [...lstClass, ...this.task(x)];
      }
    });

    return lstClass;
  }
}

export default new JoinClassName();
