/**
 * 모델
 * @window w_tb_ca504
 * @category 창고코드등록
 */
import { computed } from 'mobx';

export class StorageCodeModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  chg_cust: string;

  /**
   * 창고코드
   *
   * **data type** varchar(3)
   */
  chg_gcod: string;

  /**
   * 창고명
   *
   * **data type** varchar(30)
   */
  chg_gnam: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 창고명(영문)
   *
   * **data type** varchar(15)
   */
  chg_nm: string;

  /**
   * 창고담당자연락처
   *
   * **data type** varchar(20)
   */
  chg_phone: string;

  /**
   * 비고
   *
   * **data type** varchar(60)
   */
  remark: string;

  /**
   * ems창고코드
   *
   * **data type** varchar(50)
   */
  emchg_gcod: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.chg_cust = data.chg_cust || '';
    this.chg_gcod = data.chg_gcod || '';
    this.chg_gnam = data.chg_gnam || '';
    this.cltcd = data.cltcd || '';
    this.divicd = data.divicd || '';
    this.remark = data.remark || '';
    this.chg_nm = data.chg_nm || '';
    this.chg_phone = data.chg_phone || '';
    this.remark = data.remark || '';
    this.spjangcd = data.spjangcd || '';
    this.emchg_gcod = data.emchg_gcod || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
