import { FaCodeBranch, FaListUl, FaWonSign } from 'react-icons/all';
import { Enrollment } from './Enrollment';
import { TabModel } from '../../../../models';
import { EnrollmentTabMaterialsTemplate } from './tabs/EnrollmentTabMaterials.template';
import { EnrollmentTabItemsTemplate } from './tabs/EnrollmentTabItems.template';
import { EnrollmentTabConditionsTemplate } from './tabs/EnrollmentTabConditions.template';
import { TabLayoutDataItem } from '../../../../constants';

/**
 * 화면 > 하단 탭
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTab: Array<TabLayoutDataItem<Enrollment>> = [
  {
    tab: new TabModel('MATS', FaCodeBranch, '부품내역등록'),
    template: (s: Enrollment) => <EnrollmentTabMaterialsTemplate scope={s} />,
  },
  {
    tab: new TabModel('ITEM', FaListUl, '견적항목'),
    template: (s: Enrollment) => <EnrollmentTabItemsTemplate scope={s} />,
  },
  {
    tab: new TabModel('COND', FaWonSign, '결제조건'),
    template: (s: Enrollment) => <EnrollmentTabConditionsTemplate scope={s} />,
  },
];
