import { action } from 'mobx';
import * as React from 'react';
import {
  MonthlyMaterialTemplate,
} from './MonthlyMaterial.template';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import {
  MonthlyMaterialLeftModel,
  MonthlyMaterialRightModel,
} from './models';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components';

interface MonthlyMaterialState {
  stdate: string;
  enddate: string;

  // 왼쪽List
  leftData: Array<MonthlyMaterialLeftModel>;
  rightData: Array<MonthlyMaterialRightModel>;
}

/**
 * 컨트롤러
 * @window w_tb_da034w_02
 * @category 월별자재마감
 */
export class MonthlyMaterial extends PageComponent<PageProps, MonthlyMaterialState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  graph?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,

      leftData: [],
      rightData: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          leftData: [...this.state.leftData, ...items],
        });
      },
      async () => {
        await this.SS({
          leftData: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.leftData.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    await this.SS({
      leftData: [],
    });

    this.graph = `https://api.elmansoft.com/chart/stick8.php?database=${user.custcd}&window=w_tb_da034w_02&type=0&as_custcd=${user.custcd}&as_mon=${this.state.stdate.substring(0, 6)}&xcnt=2&tnm0=%EC%A0%84%EC%9B%94&tnm1=%EB%8B%B9%EC%9B%94`;

    await this.infinity?.retrieveAll();

    if (this.state.leftData.length > 0) {
      this.grid.current?.setFocus(0);
    }
    await this.onRetrieveEvent2();
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;

    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.setState({
          rightData: [...this.state.rightData, ...items],
        });
      },
      async () => {
        await this.SS({
          rightData: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.rightData.length > 0) {
          this.grid2.current?.setFocus(0);
        }
      },
    );

    await this.SS({
      rightData: [],
    });

    await this.infinity2?.retrieveAll();

    if (this.state.rightData.length > 0) {
      this.grid2.current?.setFocus(0);
    }

    await this.plusButton();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    // if (this.state.leftData.length < 1) {
    //   ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
    //   return;
    // }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  async plusButton() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_buttonclicked',
      {
        itemname: 'p_add',
        data: '',
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        spjangcd: 'ZZ',
      });

    console.log(data);
    // params
    /*
    * ls_itemname = json.getattribute('itemname')
    ls_data       = json.getattribute('data')
    ls_mon       = json.getattribute('mon')
    ls_spjangcd   = json.getattribute('spjangcd')
    *
    * */
  }

  render() {
    return (
      <MonthlyMaterialTemplate
        scope={this}
      />
    );
  }
}
