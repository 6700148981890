import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export default class JournalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 결재일
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 결재상태
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   * 문서코드
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 결재문서명
   *
   * **data type** varchar(8)
   */
  readonly appnum_title: string;

  /**
   * 결재자
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   * 결재권자 지시사항
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   * 결재시간
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   * 첨부파일수
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 자동유무
   *
   * **data type** varchar(8)
   */
  readonly autoflag: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 검사로그
   *
   * **data type** varchar(8)
   */
  readonly kclog: string;

  /**
   * 검사자
   *
   * **data type** varchar(8)
   */
  readonly kcperid: string;

  /**
   * 검사자2
   *
   * **data type** varchar(8)
   */
  readonly kcperid2: string;

  /**
   * 검사자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 검사자명2
   *
   * **data type** varchar(8)
   */
  readonly kcpernm2: string;

  /**
   * 부서구분
   *
   * **data type** varchar(8)
   */
  readonly kukcd: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 수신참조
   *
   * **data type** varchar(8)
   */
  readonly refer: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 설치자
   *
   * **data type** varchar(8)
   */
  readonly scpernm: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appnum_title = data.appnum_title || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.apptime = data.apptime || '';
    this.attcnt = data.attcnt || '';
    this.autoflag = data.autoflag || '';
    this.custcd = data.custcd || '';
    this.divicd = data.divicd || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.kcdate = data.kcdate || '';
    this.kclog = data.kclog || '';
    this.kcperid = data.kcperid || '';
    this.kcperid2 = data.kcperid2 || '';
    this.kcpernm = data.kcpernm || '';
    this.kcpernm2 = data.kcpernm2 || '';
    this.kukcd = data.kukcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.plandate = data.plandate || '';
    this.refer = data.refer || '';
    this.remark = data.remark || '';
    this.scpernm = data.scpernm || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get isReported() {
    return this.appgubun && (this.appgubun !== '' && this.appgubun !== '131');
  }

  @computed
  get isReportedCancelable() {
    return this.isReported && this.appgubun === '001';
  }
}
