import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da026_import
 * @category 지로자동이체
 */
export class JiroAutoDetailModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;


  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  vseq: string;

  /**
   * 고객조회번호
   *
   * **data type** varchar(30)
   */
  cltnum: string;

  /**
   * 거래저명
   *
   * **data type** char(200)
   */
  cltnm: string;

  /**
   * 발행년월
   *
   * **data type** char(6)
   */
  mismon: string;

  /**
   * 청구일
   *
   * **data type** char(8)
   */
  misdate: string;

  /**
   * 납입일
   *
   * **data type** varchar(8)
   */
  sunapdate: string;

  /**
   * 납입금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 이체일
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 이체금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 수수료
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 수납구분
   *
   * **data type** varchar(2)
   */
  misflag: string;

  /**
   * 고객조회번호
   *
   * **data type** number
   */
  seqnum: string;

  /**
   * 고객명
   *
   * **data type** char(15)
   */
  cltcd: string;

  /**
   * 고객명
   *
   * **data type** char(15)
   */
  seq: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.chk = data.chk || '';
    this.seqnum = data.seqnum || '';
    this.seq = data.seq || '';
    this.cltcd = data.cltcd || '';
    this.vseq = data.vseq || '';
    this.cltnum = data.cltnum || '';
    this.cltnm = data.cltnm || '';
    this.mismon = data.mismon || '';
    this.misdate = data.misdate || '';
    this.sunapdate = data.sunapdate || '';
    this.misamt = data.misamt || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvamt = data.rcvamt || '';
    this.tamt = data.tamt || '';
    this.misflag = data.misflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
