import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ExpenseModel } from './Expense.model';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { ExpenseTemplate } from './Expense.template';
import {
  ConfirmDelete,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';


// export interface ExpenseListItem {
//   custcd: string;
//   spjangcd: string;
//   artcd: string;
//   outcd: string;
//   new: string;
//   artnm: string;
// }
//
// export interface ExpenseListDetailItem {
//   outcd: string;
//   new: string;
//   remark: string;
// }

interface ExpenseState {
  // 조회 조건
  searchQuery: string;
  focusedTime?: any;

  expenseList: Array<ExpenseModel>;
  expenseDetailList: Array<ExpenseModel>;
  focusedExpense: ExpenseModel;
  focusedDetailExpense: ExpenseModel;

  // expenseList: Array<ExpenseListItem>;
  // expenseDetailList: Array<ExpenseListDetailItem>;
  // focusedExpense?: ExpenseListItem;
  // focusedDetailExpense?: ExpenseListDetailItem;

  // dw_2_rowfocus Data
  data: ExpenseModel;
  focused: ExpenseModel;
}

/**
 * 컨트롤러
 * @window w_tb_ae001
 * @category 정기지출항목등록
 */
export class Expense extends PageComponent<PageProps, ExpenseState>
  implements PageToolEvents {
  updatedRows?: Array<ExpenseModel>;

  // rowItem?: ExpenseModel;

  table: React.RefObject<TableLayout> = React.createRef();

  gridDetail: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  // isChanged: string;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 상단 리스트
      expenseList: [],

      // 하단 리스트
      expenseDetailList: [],

      // 상단 리스트 포커스
      focusedExpense: [],

      // 하단 리스트 포커스
      focusedDetailExpense: [],

      data: [],
    };
    // this.isChanged = 'false';
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          expenseList: [...this.state.expenseList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          expenseList: [],
        });

        await this.infinity?.retrieveAll();
        await this.table.current?.update(true);
        await this.table.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      expenseList: [],
    });
    if (!autoLoad) return;
    const index = await this.infinity?.retrieveTo('spjangcd', this.state.data.spjangcd, type, true) || 0;
    await this.table.current?.update(true);
    this.state.expenseList.length > index && await this.table.current?.setFocus(index, 0);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    // 검색어 초기화
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }

    // 상단 Table 신규 생성 시 한 행 만 추가 되도록.
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    // 신규 시 data 가져오기
    const data = await api.new();

    if (data) {
      // data.new = '1';
      this.setState({
        expenseList: [
          ...this.state.expenseList,
          new ExpenseModel(data, true),
        ],
        focusedExpense: new ExpenseModel(data, true),
      }, async () => {
        await this.table.current?.update(true);
        await this.table.current?.setFocus(this.state.expenseList.length - 1, 0);
      });
    }
    // this.isChanged = 'true';
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    // let newItem: boolean;
    //
    // if (this.isChanged === 'true') {
    //   newItem = true;
    // } else {
    //   newItem = false;
    // }

    if (await api.save(this.state.data, this.state.data?.isNew)) {
      this.updatedRows = [];
      await this.table.current?.resetUpdates();
      await this.onRetrieveEvent();
    }
    // this.isChanged = 'false';
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `매입코드: ${this.state.focusedExpense?.artcd}, 매입항목: ${this.state.focusedExpense?.artnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedExpense) && this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }

  @action
  async onRowFocusEvent(item: ExpenseModel) {
    const { actionStore: api } = this.props;

    if (item?.isNew) {
      await this.SS({
        expenseDetailList: [],
        focusedDetailExpense: new ExpenseModel(),
        data: new ExpenseModel(),
      });
      return;
    }

    // this.rowItem = item;
    this.setState({
      focusedExpense: item,
    }, async () => {
      const data = await api.fxExec(
        'dw_1_RowFocuschanged',
        item,
      );

      data && this.setState({ expenseDetailList: data?.items },
        () => {
        // const index = Finder.index(
        //   data.items,
        //   this.state.focusedDetailExpense,
        //   'outcd',
        // );

          // if (data.items.length > index) {
          //   this.gridDetail.current?.setFocus(index);
          // }
          this.gridDetail.current?.setFocus(0);
          this.gridDetail.current?.forceRepaint();
        });
    });
  }

  @action
  async onDetailRowFocusEvent(item: ExpenseModel) {
    if (item.new === '1') return;

    this.setState({
      focusedDetailExpense: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.exec(
        Category.GENERAL,
        'dw_2_RowFocuschanged',
        {
          outcd: item?.outcd,
          artcd: this.state.focusedExpense?.artcd,
        },
      );
      data && this.setState({ data });
    });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    if (this.state.focusedDetailExpense.isNew) {
      ConfirmWarning.show('경고', '한 번에 한 행만 추가 할 수 있습니다.');
      return;
    }

    // new Data가져오기
    const data = await api.fxExec(
      'dw_2_new',
      {
        artcd: this.state.focusedExpense?.artcd,
        artnm: this.state.focusedExpense?.artnm,
      },
    );
    // dw_2_new 시에 artnm 안가져와서 focuse 데이터로 추가
    // data.artnm = this.state.focusedExpense?.artnm;

    if (data) {
      // const newData: ExpenseListDetailItem = {
      //   outcd: data.outcd,
      //   new: '1',
      //   remark: data.remark,
      // };

      this.setState({
        data: new ExpenseModel(data),
        focusedDetailExpense: new ExpenseModel(data, true),
        expenseDetailList: [
          new ExpenseModel(data, true),
          ...this.state.expenseDetailList,
        ],
      }, () => this.gridDetail.current?.setFocus(0));
      // this.state.data.new = '1';
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focusedDetailExpense;

    if (focusedItem) {
      // @ts-ignore
      const text = `번호: ${focusedItem.outcd}, 제목: ${focusedItem.remark} 을(를) 삭제하시겠습니까?`;

      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;

        await api.fxExec(
          'dw_2_delete',
          {
            outcd: this.state.data.outcd,
            artcd: this.state.data.artcd,
          },
        );
        this.onRetrieveEvent();
        ConfirmSuccess.show('삭제', `${focusedItem.remark} 를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.expenseList.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.expenseList.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  render() {
    return (
      <ExpenseTemplate
        scope={this}
        update={(change) => this.setState(change)}
        // @ts-ignore
        modelUpdate={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
