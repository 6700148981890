import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w_04
 * @category 대응/처리시간현황
 */

export class ResponseModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *  처리자
   *
   * **data type** varchar(?)
   */
  readonly pernm: string;

  /**
   *  고장건수
   *
   * **data type** varchar(?)
   */
  readonly tottime: string;

  /**
   *  5분이내
   *
   * **data type** varchar(?)
   */
  readonly time5: string;

  /**
   *  6분~15분
   *
   * **data type** varchar(?)
   */
  readonly time6: string;

  /**
   *  16분~30분
   *
   * **data type** varchar(?)
   */
  readonly time1: string;

  /**
   *  30분~1시간
   *
   * **data type** varchar(?)
   */
  readonly time2: string;

  /**
   *  1시간~2시간
   *
   * **data type** varchar(?)
   */
  readonly time3: string;

  /**
   *  2시간이상
   *
   * **data type** varchar(?)
   */
  readonly time4: string;

  /**
   *  5분이내
   *
   * **data type** varchar(?)
   */
  readonly timeyul5: string;

  /**
   *  6분~15분
   *
   * **data type** varchar(?)
   */
  readonly timeyul6: string;

  /**
   *  16분~30분
   *
   * **data type** varchar(?)
   */
  readonly timeyul1: string;

  /**
   *  30분~1시간
   *
   * **data type** varchar(?)
   */
  readonly timeyul2: string;

  /**
   *  1시간~2시간
   *
   * **data type** varchar(?)
   */
  readonly timeyul3: string;

  /**
   *  2시간이상
   *
   * **data type** varchar(?)
   */
  readonly timeyul4: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.pernm = data.pernm || '';
    this.tottime = data.tottime || '';
    this.time1 = data.time1 || '';
    this.time2 = data.time2 || '';
    this.time3 = data.time3 || '';
    this.time4 = data.time4 || '';
    this.time5 = data.time5 || '';
    this.time6 = data.time6 || '';
    this.timeyul1 = data.timeyul1 || '';
    this.timeyul2 = data.timeyul2 || '';
    this.timeyul3 = data.timeyul3 || '';
    this.timeyul4 = data.timeyul4 || '';
    this.timeyul5 = data.timeyul5 || '';
    this.timeyul6 = data.timeyul6 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
