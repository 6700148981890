import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { RoutineTemplate } from './Routine.template';
import { Today } from '../../../../utils/time';
import { RoutineModel } from './Routine.model';
import { CalendarLayoutDataModel } from '../../../../models/component';
import { ConfirmWarning } from '../../../../utils/confirm';

export interface RoutineState {
  // 검색 조건
  stmon: string;
  actcd: string;
  divicd: string;
  perid: string;
  actnm: string;
  divinm: string;
  pernm: string;
  state: string;

  // 검사 리스트
  data: Array<CalendarLayoutDataModel<RoutineModel>>;

  // 만료
  isExpiredModalVisible?: boolean;
  expiredData?: Array<RoutineModel>;

  // 검사계획 모달
  isInspectModalVisible?: boolean;
  inspectData?: Array<RoutineModel>;
  inspectDate?: string;

  // 검사계획 상세 모달
  isInspectDetailModalVisible?: boolean;
  inspectDetailData?: Array<any>;
  inspectDetailItem?: RoutineModel;
}

/**
 * 컨트롤러
 * @window w_tb_e073
 * @category 검사스케쥴
 */
export class Routine extends React.Component<PageProps, RoutineState> implements PageToolEvents {
  dayRemarks: Record<string, { done: number, plan: number, yet: number }>;

  inspectResults: Array<any>;

  inspectAgency: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.dayRemarks = {};

    this.inspectResults = [];

    this.inspectAgency = [];

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      actcd: '',
      divicd: '',
      perid: '',
      actnm: '',
      divinm: '',
      pernm: '',
      state: '%',
    };
  }

  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = await api.dropdown('wf_dd_ca510_018_01');
    this.inspectResults = data?.items || [];

    data = await api.dropdown('wf_dd_ca510_011_01');
    this.inspectAgency = data?.items || [];

    // const show = await ConfirmWarning.show('확인', '검사는 정보센터 보안이슈로 협역이 될때까지 정보센터에서 확인부탁드립니다. 정보센터로 이동합니다.');
    //
    // if (data) {
    //   await show;
    //   this.openWebSite2();
    // } else {
    //   this.onRetrieveEvent();
    // }

    this.onRetrieveEvent();
  }

  getInspectResultText(comCode: string) {
    return this.inspectResults?.filter((x) => x.com_code === comCode)[0]?.com_cnam || '';
  }

  getInspectAgencyText(comCode: string) {
    return this.inspectAgency?.filter((x) => x.com_code === comCode)[0]?.com_cnam || '';
  }

  openWebSite() {
    window.open('https://elevator.go.kr');
  }

  openWebSite2() {
    window.open('https://www.elevator.go.kr/main/Login.do?menuId=00000000');
  }

  getItemColor(item: RoutineModel): string {
    if (item.state === '0') {
      return 'var(--color-red)';
    }

    if (item.state === '1') {
      return item.planflag === '1' ? 'var(--color-blue)' : 'var(--color-green)';
    }

    return 'var(--color-blue)';
  }

  getItemPrefix(item: RoutineModel): string {
    if (item.state === '0') {
      return '만료';
    }

    if (item.state === '1') {
      return item.planflag === '1' ? '확정' : '계획';
    }

    return '완료';
  }

  @action
  async onRetrieveEvent() {
    this.setState({
      data: [],
      expiredData: [],
    }, async () => {
      // 달력
      const { actionStore: api } = this.props;
      const data = await api.retrieve({
        stmon: this.state.stmon,
        actcd: this.state.actcd,
        divicd: this.state.divicd,
        perid: this.state.perid,
        state: this.state.state,
      });

      this.dayRemarks = {};
      data?.items?.forEach((x: any) => {
        if (!this.dayRemarks[x.plandate]) {
          this.dayRemarks[x.plandate] = { done: 0, plan: 0, yet: 0 };
        }

        if (x.state === '0') {
          this.dayRemarks[x.plandate].yet += 1;
        } else if (x.planflag === '1') {
          this.dayRemarks[x.plandate].done += 1;
        } else {
          this.dayRemarks[x.plandate].plan += 1;
        }
      });

      data?.items && this.setState({
        data: data.items.map((x: any) => new CalendarLayoutDataModel(x.plandate, new RoutineModel(x))),
      });

      // 만료
      const expired = await api.retrieve(
        {
          sub: 'w_popup_e073_callrender_not',
          stmon: this.state.stmon,
          divicd: this.state.divicd,
          perid: this.state.perid,
          contgubun: '%',
        },
      );

      if (expired?.items) {
        this.setState({
          expiredData: expired.items.map((x: any) => new RoutineModel(x)),
        });
      }
    });
  }

  @action
  onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    api.printWithElmanManager({
      stmon: this.state.stmon,
      actcd: this.state.actcd,
      divicd: this.state.divicd,
      perid: this.state.perid,
      state: this.state.state,
      gubun: 'a4',
      contgubun: '%',
    });
  }

  @action
  async openExpiredModal() {
    if (!this.state.expiredData?.length) {
      ConfirmWarning.show('만료 없음', '검사가 만료된 현장이 없습니다.');
      return;
    }

    this.setState({
      isExpiredModalVisible: true,
    });
  }

  @action
  async openInspectModal(date: string) {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e073_callrender',
        date,
        perid: this.state.perid,
      },
    );

    if (data?.items) {
      this.setState({
        isInspectModalVisible: true,
        inspectData: data.items.map((x: any) => new RoutineModel(x)),
        inspectDate: date,
      });
    }
  }

  @action
  async openInspectDetailModal(item: RoutineModel) {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e073_fail',
        ...item,
      },
    );

    if (data?.items) {
      this.setState({
        isInspectDetailModalVisible: true,
        inspectDetailData: data?.items,
        inspectDetailItem: item,
      });
    }
  }

  render() {
    return (
      <RoutineTemplate
        scope={this}
      />
    );
  }
}
