import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { Global } from '../../constants';
import { SubHeader } from '../../components/layout/SubHeader';
import { GridLayout } from '../../components';
import { Date8 } from '../../utils/time';

interface ModalExpirationProps {
  modalStore: ModalStore;
}

@observer
export class ModalExpiration extends React.Component<ModalExpirationProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1200}
        height={700}
        isVisible={modalStore.isVisibleExpirationSelector}
        onClose={() => modalStore.closeExpirationSelector()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>만료에 대한 답변내용</LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '내용',
                width: 50,
                trail: () => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    합계: {modalStore.expirationTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'wakedate',
                text: '답변만료일',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.wakedate)}
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore?.expirationData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowDoubleClick={() => modalStore.onPageEvent()}
            onRowClick={(item) => modalStore.onExpirationRowFocusEvent(item)}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => modalStore.closeExpirationSelector()}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
