import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { BillStatementTemplate } from './BillStatement.template';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { BillStatementModel } from './BillStatement.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface BillStatementState {

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  gubun: string; // 공사구분
  receflag: string; // 확인서구분
  perid: string; // 담당자
  pernm: string; // 담당자명
  myn: string; // 유무상구분
  wkactcd: string; //  회사구분
  wkactnm: string; //  회사구분명

  billStatementList: Array<BillStatementModel>;
  data: BillStatementModel;
  focusedBillStatement?: BillStatementModel;
}

/**
 * 컨트롤러
 * @window w_tb_ca606w_01
 * @category 자재청구현황
 */
export class BillStatement extends PageComponent<PageProps, BillStatementState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // eslint-disable-next-line max-len
    const lastDay: string | number = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate().toString();

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${lastDay}`,
      gubun: '%',
      receflag: '%',
      perid: '',
      pernm: '',
      myn: '%',
      wkactnm: '',
      wkactcd: '',
      data: new BillStatementModel(),
      billStatementList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        gubun: this.state.gubun,
        receflag: this.state.receflag,
        perid: this.state.perid,
        pernm: this.state.pernm,
        myn: this.state.myn,
        wkactnm: this.state.wkactnm,
        wkactcd: this.state.wkactcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          billStatementList: [...this.state.billStatementList, ...items],
        });
      },
      async () => {
        await this.SS({ billStatementList: [] });
        this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      billStatementList: [],
    }, () => this.infinity?.retrieveAll());
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.billStatementList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      gubun: this.state.gubun,
      receflag: this.state.receflag,
      perid: this.state.perid,
      pernm: this.state.pernm,
      myn: this.state.myn,
      wkactnm: this.state.wkactnm,
      wkactcd: this.state.wkactcd,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.billStatementList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      gubun: this.state.gubun,
      receflag: this.state.receflag,
      perid: this.state.perid,
      pernm: this.state.pernm,
      myn: this.state.myn,
      wkactnm: this.state.wkactnm,
      wkactcd: this.state.wkactcd,
    });
  }

  render() {
    return (
      <BillStatementTemplate
        scope={this}
      />
    );
  }
}
