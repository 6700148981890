import { TLHF } from '../../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../../components';
import { Regist } from '../Regist';


const Gubuns = [
  { value: '0', remark: '담당감독' },
  { value: '1', remark: '현장소장' },
  { value: '2', remark: '업체소장' },
  { value: '3', remark: '기타' },
];


/**
 * 화면 > 상세 > 탭 - 담당연락처 > 테이블
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabTableTelHeader: TLHF<any, Regist> = () => ([
  {
    id: 'seq',
    text: '순서',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'custcd',
    text: '구분',
    width: 100,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.gubun}
        data={Gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ gubun: option.value })}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자명(거래처명)',
    width: 250,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.pernm}
        onChange={(v) => rowUpdate({ pernm: v })}
      />
    ),
  },
  {
    id: 'telnum',
    text: '전화번호',
    width: 150,
    render: (x, rowUpdate, refs) => (
      <TelnumTextBox
        refs={refs}
        value={x.telnum}
        onChange={(v) => rowUpdate({ telnum: v })}
      />
    ),
  },
  {
    id: 'faxnum',
    text: '팩스번호',
    width: 150,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.faxnum}
        onChange={(v) => rowUpdate({ faxnum: v })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 300,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
