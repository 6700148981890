import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  CheckBox,
  CircleBadge,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Defect } from './Defect';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_e035w_02
 * @category 자체점검중대결함현황
 */

export const DefectTemplate: React.FC<TemplateProps<Defect>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={180}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout weight={3}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>

        <Button onClick={() => scope.onEmailButtonClicked()}>
          EMAIL
        </Button>
      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'plandate',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>계획일자</FlexLayout>
                  <FlexLayout>점검일자</FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout>
                    <DateTextBox
                      value={x.plandate}
                      textAlign="center"
                      format="####-##-##"
                    />
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    <DateTextBox
                      value={x.rptdate}
                      textAlign="center"
                      format="####-##-##"
                    />
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'wkactcd',
              text: '회사구분',
              width: 50,
              trail: (_, scope) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope?.state.cnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                  style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
                >
                  {scope.state.wkactcds?.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-yellow)' }}
                >
                  B건수 :
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="start"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 60,
              trail: (_, scope) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-yellow)' }}
                >
                  {scope?.state.cntb_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>담당자</FlexLayout>
                  <FlexLayout>보조담당</FlexLayout>
                </FlexLayout>
              ),
              width: 60,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  C건수 :
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.pernm}
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.pernm2}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'plantime',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>시작시간</FlexLayout>
                  <FlexLayout>완료시간</FlexLayout>
                </FlexLayout>
              ),
              width: 80,
              color: 'var(--color-red)',
              trail: (_, scope) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope?.state.cntc_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FormatTextBox
                    format="##:##"
                    value={x.plantime}
                    textAlign="center"
                  />

                  <FormatTextBox
                    format="##:##"
                    value={x.plantime2}
                    textAlign="center"
                  />
                </FlexLayout>
              ),
            },
            {
              id: 'kcpernm',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>처리자명</FlexLayout>
                  <FlexLayout>보조자명</FlexLayout>
                </FlexLayout>
              ),
              width: 60,
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.kcpernm}
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'evcd',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>점검기종</FlexLayout>
                  <FlexLayout>승강기번호</FlexLayout>
                </FlexLayout>
              ),
              width: 120,
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {scope.state.evcds.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>특이사항</FlexLayout>
                  <FlexLayout style={{ color: 'var(--color-blue)' }}>더블클릭시 특이사항 수정가능</FlexLayout>
                </FlexLayout>
              ),
              width: 230,
              render: (x) => (
                <FlexLayout
                  style={{ padding: 8 }}
                  onDoubleClick={() => scope.remarkModal(true)}
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'state',
              text: '결과',
              width: 50,
              color: 'var(--color-red)',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <FlexLayout style={{ marginRight: 36, marginTop: 18 }}>
                    <CircleBadge>
                      {<span
                        style={x.state === 'B' ? { backgroundColor: '#F2CB61' }
                          : { backgroundColor: 'var(--color-red)' }}
                      >
          {x.state}
        </span>}
                    </CircleBadge>
                  </FlexLayout>
                  <FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'resultcd',
              text: '번호',
              width: 50,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.resultcd}
                </FlexLayout>
              ),
            },
            {
              id: 'resultnm',
              text: '점검항목장치',
              width: 80,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.resultnm}
                </FlexLayout>
              ),
            },
            {
              id: 'resultdtlnm',
              text: '부적합내용',
              width: 250,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.resultdtlnm}
                </FlexLayout>
              ),
            },
            {
              id: 'feedback',
              text: '처리여부',
              width: 80,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.feedback}
                  style={x.feedback === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                  data={[
                    { value: '0', remark: '미처리' },
                    { value: '1', remark: '처리' },
                    { value: '2', remark: '견적처리' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => {
                    rowUpdate({ feedback: option.value });
                    if (option.value !== '0') {
                      scope.listItemChanged(x, rowUpdate, 'feedback', option.value);
                    }
                  }}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'feedback_remark',
              text: '처리여부상세',
              width: 250,
              render: (x, rowUpdate) => (
                <TextBox
                  value={x.feedback_remark}
                  textAlign="left"
                  onBlur={(value) => value && scope.listItemChanged(x, rowUpdate, 'feedback_remark', value)}
                  onChange={(value) => {
                    rowUpdate({ feedback_remark: value });
                  }}
                  readonly={x.feedback === '0'}
                >
                </TextBox>
              ),
            },
            {
              id: 'feedback_indate',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout>작성일</FlexLayout>
                  <FlexLayout>작성자</FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {Date8.withDash(x.feedback_indate)}
                  </FlexLayout>

                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.feedback_inpernm}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          rowHeight={68}
          headerHeight={62}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
        />
      </FlexLayout>


      {/* 첨부파일 */}
      <ModalView
        width={800}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 사전점검 */}
      <ModalView
        width={800}
        isVisible={scope.state.remarkDetailModal}
        onClose={() => scope.remarkModal(false)}
      >
        <FlexLayout
          isVertical={true}
          weight={3}
          style={{ padding: 8 }}>
          <SubHeader>
            <div>
              <span>특이사항</span>
            </div>

            <FlexLayout justify="end">
              <Button
                onClick={() => scope.remarkModalsave()}>
                확인
              </Button>
              <Button
                onClick={() => scope.remarkModal(false)}>
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>

          <TextBox
            value={scope.state.remark || ''}
            onChange={(value) => setData({
              remark: value,
            })}
            isMultiline={true}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.printTypeModal}
        width={560}
        height={160}
        onClose={() => scope.SS({ printTypeModal: false })}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>

          <FlexLayout
            align='center'
            justify='center'
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.printType === '0'}
                onChange={(v) => scope.SS({ printType: v ? '0' : '0' })}
              />
                점검처리결과보고서
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.printType === '1'}
                onChange={(v) => scope.SS({ printType: v ? '1' : '0' })}
              />
                리스트
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.printTypeSelect()}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>


      {/* 사전점검 상세 */}
    </FlexLayout>
  );
};
