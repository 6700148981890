import * as React from 'react';
import mStyle from './FlexLayout.module.scss';
import { JoinClassName } from '../../../utils/string';
import { FlexLayoutProps } from '../../../constants';
import { DefaultLayout } from '../DefaultLayout';

const getGravityString = (value?: string) => {
  if (!value) return 'inherit';

  switch (value.toUpperCase()) {
    case 'LEFT':
    case 'START':
      return 'flex-start';
    case 'RIGHT':
    case 'END':
      return 'flex-end';
    case 'INIT':
    case 'INITIAL':
      return 'initial';
    default:
      return 'center';
  }
};

/**
 * 동적 레이아웃
 *
 * @remarks
 * 페이지의 모든 뷰는 이 레이아웃을 기본으로 사용해야 합니다.
 * 1920x1080 해상도를 기본으로 하되 더 작거나 큰 해상도를 고려하도록 상하 여백은 paading보다 height를 권장합니다.
 */
export class FlexLayout extends React.PureComponent<FlexLayoutProps> {
  public static readonly DOM_NAME = 'FlexLayout';

  private lastTapTime = 0;

  render() {
    const {
      refs,
      role,
      isVertical,
      children,
      className,
      isScroll,
      weight,
      size,
      minHeight,
      justify,
      align,
      style,
      tabindex,
      disabled,
      disabledText,
      onClick,
      onDoubleClick,
      onMouseMove,
      onMouseLeave,
      onFocus,
      onUnFocus,
      onKeyDown,
    } = this.props;
    const styleJustify = getGravityString(justify);
    const styleAlign = getGravityString(align);

    return (
      <DefaultLayout
        refs={refs}
        role={role}
        className={JoinClassName.make([
          mStyle.container,
          className,
          isVertical ? mStyle.direction__vertical : null,
          disabled ? mStyle.disabled : null,
        ])}
        tabindex={tabindex}
        isScroll={isScroll}
        weight={weight}
        size={size}
        minHeight={minHeight}
        style={{
          justifyContent: styleJustify,
          alignItems: styleAlign,
          ...style,
        }}
        onClick={() => onClick && onClick()}
        onTouchStart={() => {
          const now = new Date().getTime();
          const elapsedTime = now - this.lastTapTime;
          if (elapsedTime < 300) {
            onDoubleClick && onDoubleClick();
          }
          this.lastTapTime = now;
        }}
        onDoubleClick={() => onDoubleClick && onDoubleClick()}
        onMouseMove={(e) => onMouseMove && onMouseMove(e)}
        onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
        onFocus={(e) => onFocus && onFocus(e)}
        onUnFocus={(e) => onUnFocus && onUnFocus(e)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      >
        {disabled && disabledText && <div className={mStyle.disabled__text}>{disabledText}</div>}
        {children}
      </DefaultLayout>
    );
  }
}
