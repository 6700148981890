import * as React from 'react';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { EarlyInspection } from './EarlyInspection';


/**
 * 화면
 * @window w_tb_e042
 * @category 사전점검항목등록
 */
export const EarlyInspectionTemplate: React.FC<TemplateProps<EarlyInspection>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout weight={0.2}>
          <span>종류</span>
          <ComboBox
            value={scope.state.searchEvcd}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.evcds?.map((x) => new ComboBoxModel(x.evlcd, x.evlnm))}
            onSelect={(option) => setData({ searchEvcd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'gresultcd',
              text: '구분코드',
              width: 10,
            },
            {
              id: 'gresultnm',
              text: '구분명',
              width: 45,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.gresultnm}
                  onChange={(value) => rowUpdate({ gresultnm: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 45,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <FlexLayout justify="right" weight={3}>
          <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.onNewEvent2()}>추가</Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.dw_2_delete()}
          >삭제</Button>
        </FlexLayout>
        <FlexLayout justify="right" weight={3}>
          <Button style={{ height: '25px', width: '60px' }} onClick={() => scope.onNewEvent3()}>추가</Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.dw_3_delete()}
          >삭제</Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.tableDetail}
          header={[
            {
              id: 'resultcd',
              text: 'No',
              width: 20,
            },
            {
              id: 'resultnm',
              text: '검사항목',
              width: 80,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.resultnm}
                  onChange={(value) => rowUpdate({ resultnm: value })}
                />
              ),
            },
          ]}
          data={scope.state.data2}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows2, updatedRows2) => {
            scope.onUpdatedRows2(rows2, updatedRows2);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />

        <TableLayout
          ref={scope.tableDetail2}
          header={[
            {
              id: 'seq',
              text: 'No',
              width: 20,
            },
            {
              id: 'seqnm',
              text: '검사항목.장치',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.seqnm}
                  onChange={(value) => rowUpdate({ seqnm: value })}
                />
              ),
            },
            {
              id: 'aa_error',
              text: '허용오차',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.aa_error}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ aa_error: value })}
                />
              ),
            },
          ]}
          data={scope.state.data3}
          infinityHandler={scope.infinity3}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows3, updatedRows3) => {
            scope.onUpdatedRows3(rows3, updatedRows3);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent3(item)}
        />
      </FlexLayout>

    </FlexLayout>
  );
};
