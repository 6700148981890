import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export class PopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 전기식인승점수
   *
   * **data type** number
   */
  amt01: string;

  /**
   * 전기식화물점수
   *
   * **data type** number
   */
  amt01_2: string;

  /**
   * 유압식인승점수
   *
   * **data type** number
   */
  amt02: string;

  /**
   * 유압식화물점수
   *
   * **data type** number
   */
  amt02_2: string;

  /**
   * 에스컬레이터점수
   *
   * **data type** number
   */
  amt03: string;

  /**
   * 덤웨이터(주차기)점수
   *
   * **data type** number
   */
  amt04: string;

  /**
   * 휠체어리프트점수
   *
   * **data type** number
   */
  amt05: string;

  /**
   * 주차설비작업용점수
   *
   * **data type** number
   */
  amt10: string;

  /**
   * 담당고장차감점수
   *
   * **data type** number
   */
  receamtexec: string;

  /**
   * 다발고장차감점수
   *
   * **data type** number
   */
  mowadamtexecn: string;

  /**
   * 고장처리점수
   *
   * **data type** number
   */
  rececompamtexec: string;

  /**
   * 긴급출동(사람갇힘)점수
   *
   * **data type** number
   */
  troubleamtexec: string;

  /**
   * 접수점수
   *
   * **data type** number
   */
  minwonamtexec: string;

  /**
   * 미처리차감점수
   *
   * **data type** number
   */
  minwonnotamtexec: string;

  /**
   * 처리점수
   *
   * **data type** number
   */
  minwoncompamtexec: string;

  /**
   * 합격점수
   *
   * **data type** number
   */
  kc001amtexec: string;

  /**
   * 조건부점수
   *
   * **data type** number
   */
  kc002amtexec: string;

  /**
   * 불합격차감
   *
   * **data type** number
   */
  kc003amtexec: string;

  /**
   * 견적건당점수
   *
   * **data type** number
   */
  costexec: string;

  /**
   * 처리인센률(%)
   *
   * **data type** number
   */
  compyul: string;

  /**
   * 계약인센율(%)
   *
   * **data type** number
   */
  comp_contyul: string;

  /**
   * 당일처리
   *
   * **data type** number
   */
  e037exec: string;

  /**
   * 당일미처리
   *
   * **data type** number
   */
  e037notexec: string;

  /**
   * 장기(10년)신규
   *
   * **data type** number
   */
  vlongamtexec: string;

  /**
   * 다년(5년)신규
   *
   * **data type** number
   */
  longamtexec: string;

  /**
   * FM신규
   *
   * **data type** number
   */
  fmamtexec: string;

  /**
   * 일반신규
   *
   * **data type** number
   */
  pogamtexec: string;

  /**
   * 무상신규
   *
   * **data type** number
   */
  muamtexec: string;

  /**
   * 설치신규
   *
   * **data type** number
   */
  scamtexec: string;

  /**
   * 장기(10년)재계약
   *
   * **data type** number
   */
  vlong03amtexec: string;

  /**
   * 다년(5)년재계약
   *
   * **data type** number
   */
  long03amtexec: string;

  /**
   * FM재계약
   *
   * **data type** number
   */
  fm03amtexec: string;

  /**
   * 익월재계약
   *
   * **data type** number
   */
  pog03amtexec: string;

  /**
   * 인상재계약
   *
   * **data type** number
   */
  plusamtexec: string;

  /**
   * 유상전환재계약
   *
   * **data type** number
   */
  yu03amtexec: string;

  /**
   * 해약(중지)
   *
   * **data type** number
   */
  clsamtexec: string;

  /**
   * 전자계약서
   *
   * **data type** number
   */
  elecamtexec: string;

  /**
   * 개선제안점수
   *
   * **data type** number
   */
  proposeexec: string;

  /**
   * 교육한건수
   *
   * **data type** number
   */
  eduamtexec: string;

  /**
   * 교육받은건수
   *
   * **data type** number
   */
  edureceamtexec: string;

  /**
   * 달란트점수
   *
   * **data type** number
   */
  talentexec: string;

  /**
   * 영업방문점수
   *
   * **data type** number
   */
  businessexec: string;

  /**
   * 시내출장일당점수
   *
   * **data type** number
   */
  outnotamtexec: string;

  /**
   * 시외출장일당점수
   *
   * **data type** number
   */
  outamtexec: string;

  /**
   * -
   *
   * **data type** number
   */
  wadamtexec: string;

  /**
   * 평가내용
   *
   * **data type** char(255)
   */
  newrow: string;

  /**
   * 남은점수
   *
   * **data type** number
   */
  remain: string;

  /**
   * 계획내용
   *
   * **data type** char(255)
   */
  remark: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  result: string;

  /**
   * 출장사유
   *
   * **data type** char(255)
   */
  adviceremark: string;

  /**
   * 결과보완사항
   *
   * **data type** char(255)
   */
  resultremark: string;

  /**
   * 기타내용 및 승강기상태
   *
   * **data type** char(255)
   */
  bigo: string;

  /**
   * 민원처리결과내용
   *
   * **data type** char(255)
   */
  compremark: string;

  /**
   * -
   *
   * **data type** nunmber
   */
  seq: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  give_perid: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  minus: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  bestdate: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  beenddate: string;

  /**
   * 인사코드
   *
   * **data type** string
   */
  perid: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  gubun: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  state_remark: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  costnum: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  costdate: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  usemon: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  equpnm: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  equpcd: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  flag: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  contgubun: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  compflag: string;

  /**
   * -
   *
   * **data type** char(10)
   */
  mon: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.flag = data.flag || '';
    this.contgubun = data.contgubun || '';
    this.mon = data.mon || '';
    this.compflag = data.compflag || '';
    this.usemon = data.usemon || '';
    this.equpnm = data.equpnm || '';
    this.equpcd = data.equpcd || '';
    this.state_remark = data.state_remark || '';
    this.costnum = data.costnum || '';
    this.costdate = data.costdate || '';
    this.adviceremark = data.adviceremark || '';
    this.resultremark = data.resultremark || '';
    this.bigo = data.bigo || '';
    this.gubun = data.gubun || '';
    this.perid = data.perid || '';
    this.minus = data.minus || '';
    this.bestdate = data.bestdate || '';
    this.beenddate = data.beenddate || '';
    this.give_perid = data.give_perid || '';
    this.seq = data.seq || '';
    this.compremark = data.compremark || '';
    this.result = data.result || '';
    this.custcd = data.custcd || '';
    this.remain = data.remain || '';
    this.remark = data.remark || '';
    this.newrow = data.newrow || '';
    this.amt01 = data.amt01 || '';
    this.amt01_2 = data.amt01_2 || '';
    this.amt02 = data.amt02 || '';
    this.amt02_2 = data.amt02_2 || '';
    this.amt03 = data.amt03 || '';
    this.amt04 = data.amt04 || '';
    this.amt05 = data.amt05 || '';
    this.amt10 = data.amt10 || '';
    this.amt02 = data.amt02 || '';
    this.receamtexec = data.receamtexec || '';
    this.wadamtexec = data.wadamtexec || '';
    this.mowadamtexecn = data.mowadamtexecn || '';
    this.rececompamtexec = data.rececompamtexec || '';
    this.troubleamtexec = data.troubleamtexec || '';
    this.minwonamtexec = data.minwonamtexec || '';
    this.minwonnotamtexec = data.minwonnotamtexec || '';
    this.minwoncompamtexec = data.minwoncompamtexec || '';
    this.kc001amtexec = data.kc001amtexec || '';
    this.kc002amtexec = data.kc002amtexec || '';
    this.kc003amtexec = data.kc003amtexec || '';
    this.costexec = data.costexec || '';
    this.compyul = data.compyul || '';
    this.comp_contyul = data.comp_contyul || '';
    this.e037exec = data.e037exec || '';
    this.e037notexec = data.e037notexec || '';
    this.vlongamtexec = data.vlongamtexec || '';
    this.longamtexec = data.longamtexec || '';
    this.fmamtexec = data.fmamtexec || '';
    this.pogamtexec = data.pogamtexec || '';
    this.muamtexec = data.muamtexec || '';
    this.scamtexec = data.scamtexec || '';
    this.vlong03amtexec = data.vlong03amtexec || '';
    this.long03amtexec = data.long03amtexec || '';
    this.fm03amtexec = data.fm03amtexec || '';
    this.pog03amtexec = data.pog03amtexec || '';
    this.plusamtexec = data.plusamtexec || '';
    this.yu03amtexec = data.yu03amtexec || '';
    this.clsamtexec = data.clsamtexec || '';
    this.elecamtexec = data.elecamtexec || '';
    this.proposeexec = data.proposeexec || '';
    this.eduamtexec = data.eduamtexec || '';
    this.edureceamtexec = data.edureceamtexec || '';
    this.talentexec = data.talentexec || '';
    this.businessexec = data.businessexec || '';
    this.outnotamtexec = data.outnotamtexec || '';
    this.outamtexec = data.outamtexec || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
