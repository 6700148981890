import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdAvTimer,
  MdWork,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ActModel, UnitModel, DepartmentModel } from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { RepairCurrentTemplate } from './RepairCurrent.template';
import { InfinityRetrieve, SpjangItem } from '../../../../models/common';
import { TabHeaderAct, TabHeaderDepartment, TabHeaderUnit } from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


export enum RepairCurrentTabId {
  Act,
  Unit,
  Department,
}

export const RepairCurrentTabTitles = [
  '현장현황',
  '호기현황',
  '부서별현장현황',
];

export const RepairCurrentTabModels = [
  ActModel,
  UnitModel,
  DepartmentModel,
];

export const RepairCurrentTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
];

export const RepairCurrentTabDataStateNames = [
  'Acts',
  'Units',
  'Departments',
];

export const RepairCurrentTabUpdatesStateNames = [
  'ActUpdates',
  'UnitUpdates',
  'DepartmentUpdates',
];

export const RepairCurrentTabFocusedStateNames = [
  'ActUpdatesFocused',
  'UnitUpdatesFocused',
  'DepartmentUpdatesFocused',
];

export interface RepairCurrentState {
  // 현장현황
  Acts?: Array<ActModel>;
  ActUpdates?: Array<ActModel>;
  ActsFocused?: ActModel;

  // 호기형환
  Units?: Array<UnitModel>;
  UnitUpdates?: Array<UnitModel>;
  UnitsFocused?: UnitModel;

  // 현장현황
  Departments?: Array<DepartmentModel>;
  DepartmentUpdates?: Array<DepartmentModel>;
  DepartmentsFocused?: DepartmentModel;

  focusedTab?: TabModel;
  spjangcd?: string;
  spjangcds?: Array<SpjangItem>;
  contgubun?: string;
  contgubuns?: Array<any>;
  wkactcd?: string;
  wkactnm?: string;
  searchQuery?: string;
  gubun?: string;
  perid?: string;
  pernm?: string;
  contperid?: string;
  contpernm?: string;
  bildyd?: string;
  buildnm?: string;
  areacd?: string;
  areanm?: string;
  divicd?: string;
  divinm?: string;
  elno?: string;
  as_nm2?: string;
  eligcd?: string;
  eliycd?: string;
  elijcd?: string;
  elifcd?: string;
  nperson?: string;
  height?: string;
  kcflag?: string;
  mdcd?: string;
  jejak?: string;
  number?: string;
  yyyymm?: string;
  speed?: string;
  plc?: string;
  eligcds?: Array<any>;
  eliycds?: Array<any>;
  elijcds?: Array<any>;
  elifcds?: Array<any>;
  bildyds?: Array<any>;
  contgs?: Array<any>;
  mdcds?: Array<any>;
  speeds?: Array<any>;

  total?: string;
  qty_tot?: string;
  equpamt_tot?: string;
  averamt_tot?: string;
  focuseddata?: any;

  // PLC
  isPLCModalVisible?: boolean;

}

/**
 * 화면
 * @window w_tb_e601w
 * @category 보수현장현황
 */
export class RepairCurrent extends PageComponent<PageProps, RepairCurrentState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = RepairCurrentTabId;
    const titles = RepairCurrentTabTitles;

    const { user } = this.props.publicStore;
    // let bosuPerid = '';
    // let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    this.tabs = [
      new TabModel(id.Act.toString(), MdAvTimer, titles[id.Act]),
      new TabModel(id.Unit.toString(), MdWork, titles[id.Unit]),
      new TabModel(id.Department.toString(), MdWork, titles[id.Department]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    if (user.kukcd !== '01') {
      // bosuPerid = user.perid;
      // bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      spjangcd: 'ZZ',
      wkactcd: '',
      wkactnm: '',
      contgubun: '%',
      stdate: '',
      enddate: `${year}${month}01`,
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      perid: '',
      pernm: '',
      contperid: '',
      contpernm: '',
      bildyd: '%',
      buildnm: '',
      areacd: '',
      areanm: '',
      gubun: '1',
      month3: '0',
      searchQuery: '',
      data: [],
      total: '',
      qty_tot: '',
      equpamt_tot: '',
      averamt_tot: '',
      elno: '',
      eligcd: '%',
      eliycd: '%',
      elijcd: '%',
      kcflag: '%',
      elifcd: '%',
      nperson: '0',
      height: '0',
      yyyymm: '',
      mdcd: '%',
      number: '',
      speed: '%',
      plc: '%',
      jejak: '',
      as_nm: '',
      as_nm2: '',
    };

    this.grid = React.createRef();

    this.tabHeaders = [
      // 기간별
      TabHeaderAct,

      // 호기현황
      TabHeaderUnit,

      // 부서별
      TabHeaderDepartment,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트
    let data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 구분 리스트
    data = await api.dropdown('wf_dd_e002_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ eligcds: data.items });

    // 구분 리스트
    data = await api.dropdown('wf_dd_e003_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ eliycds: data.items });

    // 구분 리스트
    data = await api.dropdown('wf_dd_e004_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ elijcds: data.items });

    // 구분 리스트
    data = await api.dropdown('wf_dd_e005_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ elifcds: data.items });

    // 건물용도 리스트
    data = await api.dropdown('wf_dd_e029_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ bildyds: data.items });

    // 건물용도 리스트
    data = await api.dropdown('wf_dd_e006_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ mdcds: data.items });

    // 건물용도 리스트
    data = await api.dropdown('wf_dd_e008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ speeds: data.items });

    await this.onTabChange(this.tabs[RepairCurrentTabId.Act]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab) {
      this.setState({
        [RepairCurrentTabUpdatesStateNames[i]]: [],
        [RepairCurrentTabFocusedStateNames[i]]: undefined,
      });
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: RepairCurrentTabId) {
    const { actionStore: api } = this.props;

    if (this.tabIndex === RepairCurrentTabId.Act) {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity = new InfinityRetrieve(
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          divicd: this.state.divicd,
          perid: this.state.perid,
          contperid: this.state.contperid,
          bildyd: this.state.bildyd,
          areacd: this.state.areacd,
        },
        (params) => api.fxExec(
          `${RepairCurrentTabFunctionNames[i]}_retrieve`,
          params,
        ),
        (items, next) => {
          if (items) {
            this.setState({
              [RepairCurrentTabDataStateNames[i]]:
              // @ts-ignore
                [...this.state[RepairCurrentTabDataStateNames[i]],
                  ...items.map((x: any) => new RepairCurrentTabModels[i](x))],
            }, next);
          }
        },
        async () => {
          await this.SS({ [RepairCurrentTabDataStateNames[i]]: [] });
          await this.infinity?.retrieveAll();
          this.grid.current?.setFocus(0);
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        [RepairCurrentTabDataStateNames[i]]: [],
        [RepairCurrentTabUpdatesStateNames[i]]: [],
        [RepairCurrentTabFocusedStateNames[i]]: undefined,
      }, async () => {
        await this.infinity?.retrieveAll();
        const data = this.infinity?.box;
        this.setState({
          total: data?.total || '0',
          qty_tot: data?.qty_tot || '0',
          equpamt_tot: data?.equpamt_tot || '0',
          averamt_tot: data?.averamt_tot || '0',
        });
        this.grid.current?.setFocus(0);
      });
    }

    if (this.tabIndex === RepairCurrentTabId.Unit) {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity = new InfinityRetrieve(
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          elno: this.state.elno,
          eligcd: this.state.eligcd,
          eliycd: this.state.eliycd,
          elijcd: this.state.elijcd,
          elifcd: this.state.elifcd,
          nperson: this.state.nperson,
          height: this.state.height,
          kcflag: this.state.kcflag,
          mdcd: this.state.mdcd,
          jejak: this.state.jejak,
          as_nm2: this.state.as_nm2,
          number: this.state.number,
          yyyymm: this.state.yyyymm,
          bildyd: this.state.bildyd,
          spped: this.state.speed,
          plc: this.state.plc,
          perid: this.state.perid,
          divicd: this.state.divicd,
        },
        (params) => api.fxExec(
          `${RepairCurrentTabFunctionNames[i]}_retrieve`,
          params,
        ),
        (items2, next) => {
          if (items2) {
            this.setState({
              [RepairCurrentTabDataStateNames[i]]:
              // @ts-ignore
                [...this.state[RepairCurrentTabDataStateNames[i]],
                  ...items2.map((x: any) => new RepairCurrentTabModels[i](x))],
            }, next);
          }
        },
        async () => {
          await this.SS({ [RepairCurrentTabDataStateNames[i]]: [] });
          await this.infinity?.retrieveAll();
          this.grid.current?.setFocus(0);
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        [RepairCurrentTabDataStateNames[i]]: [],
        [RepairCurrentTabUpdatesStateNames[i]]: [],
        [RepairCurrentTabFocusedStateNames[i]]: undefined,
      }, async () => {
        await this.infinity?.retrieveAll();
        const data = this.infinity?.box;
        this.setState({
          total: data?.total || '0',
          qty_tot: data?.qty_tot || '0',
          equpamt_tot: data?.equpamt_tot || '0',
          averamt_tot: data?.averamt_tot || '0',
        });
        this.grid.current?.setFocus(0);
      });
    }

    if (this.tabIndex === RepairCurrentTabId.Department) {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity = new InfinityRetrieve(
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          perid: this.state.perid,
          divicd: this.state.divicd,
        },
        (params) => api.fxExec(
          `${RepairCurrentTabFunctionNames[i]}_retrieve`,
          params,
        ),
        (items3, next) => {
          if (items3) {
            this.setState({
              [RepairCurrentTabDataStateNames[i]]:
              // @ts-ignore
                [...this.state[RepairCurrentTabDataStateNames[i]],
                  ...items3.map((x: any) => new RepairCurrentTabModels[i](x))],
            }, next);
          }
        },
        async () => {
          await this.SS({ [RepairCurrentTabDataStateNames[i]]: [] });
          await this.infinity?.retrieveAll();
          this.grid.current?.setFocus(0);
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        [RepairCurrentTabDataStateNames[i]]: [],
        [RepairCurrentTabUpdatesStateNames[i]]: [],
        [RepairCurrentTabFocusedStateNames[i]]: undefined,
      }, async () => {
        await this.infinity?.retrieveAll();
        const data = this.infinity?.box;
        this.setState({
          total: data?.total || '0',
          qty_tot: data?.qty_tot || '0',
          equpamt_tot: data?.equpamt_tot || '0',
          averamt_tot: data?.averamt_tot || '0',
        });
        this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[RepairCurrentTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    if (i === RepairCurrentTabId.Act) {
      await api.fxPrint(
        `${RepairCurrentTabFunctionNames[i]}_print`,
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          divicd: this.state.divicd,
          perid: this.state.perid,
          contperid: this.state.contperid,
          bildyd: this.state.bildyd,
          areacd: this.state.areacd,
        },
      );
    }
    if (i === RepairCurrentTabId.Unit) {
      await api.fxPrint(
        `${RepairCurrentTabFunctionNames[i]}_print`,
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          elno: this.state.elno,
          as_nm2: this.state.as_nm2,
          eligcd: this.state.eligcd,
          eliycd: this.state.eliycd,
          elijcd: this.state.elijcd,
          elifcd: this.state.elifcd,
          nperson: this.state.nperson,
          height: this.state.height,
          kcflag: this.state.kcflag,
          mdcd: this.state.mdcd,
          jejak: this.state.jejak,
          number: this.state.number,
          yyyymm: this.state.yyyymm,
          bildyd: this.state.bildyd,
          spped: this.state.speed,
          plc: this.state.plc,
          perid: this.state.perid,
          divicd: this.state.divicd,
        },
      );
    }
    if (i === RepairCurrentTabId.Department) {
      await api.fxPrint(
        `${RepairCurrentTabFunctionNames[i]}_print`,
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          perid: this.state.perid,
          divicd: this.state.divicd,
        },
      );
    }
  }

  @action
  openPLC() {
    ConfirmWarning.show('...', '현재 이 기능은 개발중입니다');
  }

  @action
  async closePLCModal() {
    await this.SS({ isPLCModalVisible: false });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[RepairCurrentTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    if (i === RepairCurrentTabId.Act) {
      await api.fxExcel(
        `${RepairCurrentTabFunctionNames[i]}_excel`,
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          divicd: this.state.divicd,
          perid: this.state.perid,
          contperid: this.state.contperid,
          bildyd: this.state.bildyd,
          areacd: this.state.areacd,
        },
      );
    }
    if (i === RepairCurrentTabId.Unit) {
      await api.fxExcel(
        `${RepairCurrentTabFunctionNames[i]}_excel`,
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          elno: this.state.elno,
          as_nm2: this.state.as_nm2,
          eligcd: this.state.eligcd,
          eliycd: this.state.eliycd,
          elijcd: this.state.elijcd,
          elifcd: this.state.elifcd,
          nperson: this.state.nperson,
          height: this.state.height,
          kcflag: this.state.kcflag,
          mdcd: this.state.mdcd,
          jejak: this.state.jejak,
          number: this.state.number,
          yyyymm: this.state.yyyymm,
          bildyd: this.state.bildyd,
          spped: this.state.speed,
          plc: this.state.plc,
          perid: this.state.perid,
          divicd: this.state.divicd,
        },
      );
    }
    if (i === RepairCurrentTabId.Department) {
      await api.fxExcel(
        `${RepairCurrentTabFunctionNames[i]}_excel`,
        {
          spjangcd: this.state.spjangcd,
          as_nm: this.state.searchQuery,
          wkactcd: this.state.wkactcd,
          contgubun: this.state.contgubun,
          gubun: this.state.gubun,
          perid: this.state.perid,
          divicd: this.state.divicd,
        },
      );
    }
  }

  @action
  onRowfocusEvent(item: any) {
    this.setState({ focuseddata: item });
  }

  @computed
  get tabIndex(): RepairCurrentTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onClickOpenEnrollment() {
    this.props.publicStore.go(
      '/w_tb_e601_new',
      {
        // @ts-ignore
        searchQuery: this.state.focuseddata?.actcd,
        menu: 'w_tb_e601w',
      },
    );
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [RepairCurrentTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [RepairCurrentTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <RepairCurrentTemplate
        scope={this}
        update={(state) => this.setState(state)}
      />
    );
  }
}
