import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LabelBox,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  HolidayWork,
  HolidayWorkItemChangeTypes,
} from './HolidayWork';
import {
  Date8,
  Today,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_pb214
 * @category 휴무근무신청서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

const gubuns = [
  { value: '1', remark: '특근' },
  { value: '2', remark: '당직' },
];

export const HolidayWorkTemplate: React.FC<TemplateProps<HolidayWork>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={210}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          {
            scope.state.kukcd === '01'
              ? <FlexLayout size={300}>
                <span style={{ color: 'var(--color-red)' }}>사원명</span>
                <TextBox
                  value={scope.state.perid}
                  textAlign="center"
                  onChange={(value) => scope.setState({ perid: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      scope.setState({
                        perid: item.cd,
                        pernm: item.cdnm,
                      });
                    },
                  )}
                  isDisabledTrackingStateChange={true}
                />
                <TextBox
                  value={scope.state.pernm}
                  weight={1.5}
                  textAlign="center"
                  isDisabledTrackingStateChange={true}
                  readonly={true}
                />
              </FlexLayout>
              : ''
          }

          <FlexLayout/>
          <Button onClick={() => scope.onReport()}>
            <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <span style={{
            textAlign: 'center',
            marginBottom: 2,
            fontSize: 12,
          }}>
            휴가근무신청서와 업무일지 결재가 전부승인되면 대체휴무가 증가합니다.
          </span>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'weekdate',
                text: '작성일자',
                width: 7,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    근무횟수: {scope.state.worknum_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.weekdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '제목',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'worknum',
                text: '특근일수',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    /* eslint-disable-next-line max-len */
                    style={{ color: x.appgubun === '101' && x.rpt_appgubun === '101' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {x.worknum}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 7,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 27 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={25}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'rpt_appgubun',
                text: '업무일지상태',
                width: 7,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      style={{ color: x.rpt_appgubun === '101' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                      {appgubuns.filter((y) => y.value === x.rpt_appgubun)[0]?.remark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'attcnt',
                text: '첨부',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {`${x.attcnt} 건`}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.holidayList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.weekdate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ weekdate: value },
                  () => scope.itemChanged(HolidayWorkItemChangeTypes.WEEKDATE))}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format={model?.appnum && '##-######-###-####'}
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="근무구분"
            >
              <ComboBox
                value={model?.gubun}
                textAlign="center"
                data={gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={
                  (option) => setData({ gubun: option.value })
                }
              />
            </LabelBox>

            <LabelBox
              title="휴무근무일"
              weight={2}
            >
              <DateTextBox
                value={model?.stdate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ stdate: value })
                }
              />
            </LabelBox>
            <LabelBox title="휴무근무시간">
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.sttime}
                onChange={(value) => setData({ sttime: value })}
                onBlur={() => scope.itemChanged(HolidayWorkItemChangeTypes.STTIME)}

              />
              <span style={{
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}> ~
              </span>
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.endtime}
                onChange={(value) => setData({ endtime: value })}
                onBlur={() => scope.itemChanged(HolidayWorkItemChangeTypes.STTIME)}
              />
            </LabelBox>
            <LabelBox title="특근일수">
              <TextBox
                color='var(--color-blue)'
                textAlign='center'
                value={`${model?.worknum}`}
                onChange={(value) => setData({ worknum: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="제목" >
              <TextBox
                value={model?.title}
                onChange={(value) => setData({ title: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={250}>
            <LabelBox title="사유" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: (model?.attcnt) === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={(model?.attcnt) === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data?.appnum}
            appnm={'휴무근무신청서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });
            }}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
