import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { IntegrateTemplate } from './Integrate.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { IntegrateModel } from './models';
import { PageComponent } from '../../../../utils';
import { DateStabilizer } from '../../../../utils/time';

export enum IntegrateItemChangeTypes {
  GUBUN,
}

export enum IntegrateItemChangeTypeNames {
  gubun,
}

interface IntegrateState {

  // 검색 조건
  stdate: string;
  enddate: string;
  gubun: string;
  perid: string;
  pernm: string;
  kukcd: string;
  searchQuery: string;

  // 데이터 객체
  focusedIntegrate?: IntegrateModel;
  data: IntegrateModel;
  lastNewData: IntegrateModel;
  integrateList: Array<IntegrateModel>;
  gubuns1: Array<any>;
  gubuns2: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isCopy: boolean; // 복사 여부
  isReported: boolean; // 결재상신 여부

  focusIndex: number;
  chk: string;
  custcd: string;
  spjangcd: string;
  spjangnm: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb403
 * @category 통합문서
 */
export class Integrate extends PageComponent<PageProps, IntegrateState>
  implements PageToolEvents {
  updatedRows?: Array<IntegrateModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const storage = JSON.parse(window.localStorage['last-login'] || '{}');

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      gubun: '%',
      searchQuery: '',
      perid: '',
      pernm: '',
      kukcd: '',
      isCopy: false,
      isReported: false,
      fileDetailModal: false,
      integrateList: [],
      data: new IntegrateModel(),
      focusIndex: 0,
      chk: '0',
      custcd: storage.custcd || 'dmyong_g',
      spjangcd: storage.spjangcd || '10',
      spjangnm: storage.spjangnm || '대명엘리베이터',
      total: '',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          paperdate: this.state.data.paperdate,
          papernum: this.state.data.papernum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false,
      );

      data && this.setState({
        data: new IntegrateModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }

  @action
  async onFirstOpenEvent() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;

    // 팝업 구분
    const data = await api.dropdown('wf_dd_pb002_03');
    if (!data) return;
    this.setState({
      perid: user.perid,
      pernm: user.pernm,
    });

    const data2 = await api.dropdown('wf_dd_ca510_622_01');
    this.SS({ gubuns1: data2.items });
    const data3 = await api.dropdown('wf_dd_ca510_622_02');
    this.SS({ gubuns2: data3.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    this.setState({ kukcd: user.kukcd });

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          integrateList: items.map((x: any, index: number) => (
            new IntegrateModel({ ...x, page: (items.length - index).toString() }))),
          total: items.length.toString(),
        }, next);
      },
      async () => {
        await this.SS({ integrateList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      integrateList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(
        ['paperdate', 'papernum'],
        [lastSelected?.paperdate, lastSelected?.papernum], type, true,
      ) || 0;
      if (this.state.integrateList && this.state.integrateList.length > index) {
        await this.grid.current?.setFocus(index);
      }
      if (this.state.chk === '1') {
        this.setState({ chk: '0' });
      }
    });
  }

  @action
  onRowFocusEvent(item: IntegrateModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        isCopy: false,
        isReported: false,
      });
      return;
    }

    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedIntegrate: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            paperdate: this.state.focusedIntegrate?.paperdate,
            papernum: this.state.focusedIntegrate?.papernum,
            page: this.state.focusedIntegrate?.page,
          });

        await data && this.setState({ data: new IntegrateModel(data) });

        // 결재상신 여부확인
        if (this.state.focusedIntegrate?.appgubun === null
          || this.state.focusedIntegrate?.appgubun === ''
          || this.state.focusedIntegrate?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedIntegrate!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.TOTAL, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedIntegrate!, this.state.focusIndex);
  }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `작성일자: ${this.state.focusedIntegrate?.paperdate}, 문서번호: ${this.state.focusedIntegrate?.appnum}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.exec(Category.EMAIL, 'wb_copy', {
      gubun: this.state.data.gubun,
      compdate: this.state.data.compdate,
      preserve: this.state.data.preserve,
      help: this.state.data.help,
      title: this.state.data.title,
      perid: this.state.data.perid,
      remark: this.state.data.remark,
      upgrade: this.state.data.upgrade,
    });

    if (data) {
      const newModel = new IntegrateModel(data, true);

      this.setState({
        data: newModel,
        integrateList: [newModel, ...this.state.integrateList],
        focusedIntegrate: newModel,
        isCopy: true,
      });
      this.grid.current?.setFocus(0);
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (!await this.onSaveEvent()) {
      return;
    }

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }
    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.TOTAL);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.TOTAL.toString(),
      appnum: this.state.data.appnum,
      appgubun: this.state.data.appgubun,
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      title: this.state.data.title,
      perid: this.state.data.perid,
      refer: this.state.data.refer,
      gubun: this.state.data.gubun,
      appflag,
    });
    data && await this.onRetrieveEvent();
  }

  @action
  async onSaveEvent(): Promise<boolean> {
    const remarkLine = this.state.data?.remark.split('\n');

    // 비고 26줄 입력제한
    if (remarkLine.length > 26) {
      ConfirmWarning.show('경고', '최대 26줄까지 입력 가능합니다.');
      return true;
    }
    // 결재 상신된 청구서
    if (this.state.isReported) {
      if (this.state.focusedIntegrate?.appgubun === '001' || this.state.focusedIntegrate?.appgubun === '131') {
        ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
        return true;
      }
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return false;
    }

    const { actionStore: api } = this.props;

    if (await api.save({
      new: this.state.data.new,
      custcd: this.state.data.custcd,
      spjangcd: this.state.data.spjangcd,
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      gubun: this.state.data.gubun,
      compdate: this.state.data.compdate,
      preserve: this.state.data.preserve,
      help: this.state.data.help,
      title: this.state.data.title,
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      remark: this.state.data.remark,
      appdate: this.state.data.appdate,
      apptime: this.state.data.apptime,
      appgubun: this.state.data.appgubun,
      appperid: this.state.data.appperid,
      appnum: this.state.data.appnum,
      appremark: this.state.data.appremark,
      rspnm1: this.state.data.rspnm1,
      rspnm2: this.state.data.rspnm2,
      rspnm3: this.state.data.rspnm3,
      rspnm4: this.state.data.rspnm4,
      appdate1: this.state.data.appdate1,
      appdate2: this.state.data.appdate2,
      appdate3: this.state.data.appdate3,
      appdate4: this.state.data.appdate4,
      apptime1: this.state.data.apptime1,
      apptime2: this.state.data.apptime2,
      apptime3: this.state.data.apptime3,
      apptime4: this.state.data.apptime4,
      appgubun1: this.state.data.appgubun1,
      appgubun2: this.state.data.appgubun2,
      appgubun3: this.state.data.appgubun3,
      appgubun4: this.state.data.appgubun4,
      apppernm1: this.state.data.apppernm1,
      apppernm2: this.state.data.apppernm2,
      apppernm3: this.state.data.apppernm3,
      apppernm4: this.state.data.apppernm4,
      inperid: this.state.data.inperid,
      indate: this.state.data.indate,
      picflag: this.state.data.picflag,
      upgrade: this.state.data.upgrade,
      rspnm: this.state.data.rspnm,
      divinm: this.state.data.divinm,
      refer: this.state.data.refer,
      attcnt: this.state.data.attcnt,
      bigo: this.state.data.bigo,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.paperdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
      return true;
    }
    return false;
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new IntegrateModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        integrateList: [
          newModel,
          ...this.state.integrateList,
        ],
        total: this.state.integrateList.length.toString(),
        focusedIntegrate: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }


  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          paperdate: this.state.data.paperdate,
          papernum: this.state.data.papernum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false);
    } else {
      const text = `작성일자: ${this.state.focusedIntegrate?.paperdate} 작성번호: ${this.state.focusedIntegrate?.papernum}`;
      await api.delete(text, {
        paperdate: this.state.data.paperdate,
        papernum: this.state.data.papernum,
        appnum: this.state.data.appnum,
        appgubun: this.state.data.appgubun,
        title: this.state.data.title,
      }) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.papernum, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      appgubun: this.state.data.appgubun,
      net: '',
    });
  }


  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    const params = {
      itemname: IntegrateItemChangeTypeNames[type],
      data: '',
      new: this.state.data.new,
    };
    if (type === IntegrateItemChangeTypeNames.gubun) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.gubun,
        },
      );
    }

    data && this.setState({
      data: new IntegrateModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    await modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    await modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  render() {
    return (
      <IntegrateTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new IntegrateModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
