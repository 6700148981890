/**
 * 모델
 * @window w_popup_e501w_01 점검
 * @category 현장방문숙지리스트
 */

export class InspectionModalModel {
  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 처리자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 계획일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 처리일자
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  constructor(data: any = {}) {
    this.pernm = data.pernm || '';
    this.kcpernm = data.kcpernm || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.plandate = data.plandate || '';
    this.rptdate = data.rptdate || '';
    this.state = data.state || '';
  }
}
