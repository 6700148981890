import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  DateTextBox, FlexLayout, IFrame, SearchLayout, TabLayout, TabView,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { TransportationFeeTab } from './TransportationFee.tab';
import { TransportationFee } from './TransportationFee';
import Date6Calculator from '../../../../utils/time/Date6Calculator';

/**
 * 화면
 * @window w_tb_ca640_pa105_02w
 * @category 월교통비/통행료내역
 */
export const TransportationFeeTemplate: React.FC<TemplateProps<TransportationFee>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>월</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => {
              setData({ yearmon: value },
                () => scope.onRetrieveEvent());
            }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                // eslint-disable-next-line max-len
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      {scope.tabIndex === 0
        ? <FlexLayout>
          <TabLayout
            scope={scope}
            tabs={TransportationFeeTab}
            onChange={(_, index) => scope.onTabChange(index)}
          />

          <FlexLayout>
            <IFrame src={scope.state.graph}/>
          </FlexLayout>
        </FlexLayout>
        : <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.tabs[1]}
              onChange={(_, index) => scope.onTabChange2(index)}
            />
          </FlexLayout>

          <FlexLayout>
            <IFrame src={scope.state.graph2}/>
          </FlexLayout>
        </FlexLayout>
      }
    </FlexLayout>
  );
};
