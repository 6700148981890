import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_xappnum
 * @category 어플사용자등록
 */
export class MobileModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(8)
   */
  perid: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * KT유무(사용안함)
   *
   * **data type** varchar(1)
   */
  sid: string;

  /**
   * 휴대폰번호
   *
   * **data type** varchar(50)
   */
  handphone: string;

  /**
   * 사용자명
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 어플알림고유값
   *
   * **data type** varchar(200)
   */
  regid: string;

  /**
   * 어플음성안내유무
   *
   * **data type** varchar(1)
   */
  call_guide_useyn: string;

  /**
   * 어플수신자모드활성화
   *
   * **data type** varchar(20)
   */
  beginner_mode: string;

  /**
   * 어플애니매이션사용유무
   *
   * **data type** varchar(1)
   */
  app_animation: string;

  /**
   * 위치기반알림서비스유무
   *
   * **data type** varchar(1)
   */
  act_guide_useyn: string;

  /**
   * 어플소리키고끔
   *
   * **data type** varchar(1)
   */
  sound_useyn: string;

  /**
   * 알림1-고장접수
   *
   * **data type** varchar(50)
   */
  alert01: string;

  /**
   * 알림2-일반알림
   *
   * **data type** varchar(50)
   */
  alert02: string;

  /**
   * 알림3-고장배정알림
   *
   * **data type** varchar(50)
   */
  alert03: string;

  /**
   * 앱 백그라운드 절전 모드 온오프 상태
   *
   * **data type** varchar(1)
   */
  battery_app: string;

  /**
   * 배터리 절전 모드 온오프 상태
   *
   * **data type** varchar(1)
   */
  battery_device: string;

  /**
   * 전에 번호
   *
   * **data type** varchar(1)
   */
  behandphone: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.useyn = data.useyn || '';
    this.sid = data.sid || '';
    this.handphone = data.handphone || '';
    this.pernm = data.pernm || '';
    this.regid = data.regid || '';
    this.call_guide_useyn = data.call_guide_useyn || '';
    this.beginner_mode = data.beginner_mode || '';
    this.app_animation = data.app_animation || '';
    this.act_guide_useyn = data.act_guide_useyn || '';
    this.sound_useyn = data.sound_useyn || '';
    this.alert01 = data.alert01 || '';
    this.alert02 = data.alert02 || '';
    this.alert03 = data.alert03 || '';
    this.battery_app = data.battery_app || '';
    this.battery_device = data.battery_device || '';
    this.behandphone = data.behandphone || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
