import { computed } from 'mobx';

/**
 * 회사구분 모델
 * @window w_tb_e015
 * @category 유지보수관리코드
 */

export class CompanyModel {
/**
 * 회사코드
 *
 * **data type** varchar(8)
 */
readonly custcd: string;

/**
 * 사업장코드
 *
 * **data type** varchar(2)
 */
readonly spjangcd: string;

/**
 * 코드
 *
 * **data type** varchar(3)
 */
readonly wkactcd: string;

/**
 * 회사구분명
 *
 * **data type** varchar(60)
 */
readonly wkactnm: string;

/**
 * 사용유무
 *
 * **data type** char(1)
 */
readonly useyn: string;

/**
 * 비고
 *
 * **data type** varchar(100)
 */
readonly remark: string;

/**
 * 입력자
 *
 * **data type** varchar(10)
 */
readonly inperid: string;

/**
 * 입력일
 *
 * **data type** varchar(8)
 */
readonly indate: string;

/**
 * 전송유무
 *
 * **data type** varchar(1)
 */
readonly wkactcd_send: string;


/**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.wkactcd_send = data.wkactcd_send || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
