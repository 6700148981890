import * as React from 'react';
import { action } from 'mobx';
import {
  AskType,
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models/common';
import { OrderTemplate } from './Order.template';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  BillPopupModel,
  OrderDetailModel,
  OrderModel,
} from './models';
import {
  Confirm, ConfirmDelete,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { Format } from '../../../../utils/string';
import { PageComponent } from '../../../../utils';
import {
  Date8,
  DateStabilizer,
  Today,
} from '../../../../utils/time';

export enum Gubun {
  INTERNAL,
  EXTERNAL,
}

export enum OrderItemChangeTypes {
  CDFLAG,
  BALDATE,
  SUNFLAG,
  SALEYUL,
  SALEAMT,
  STYUL,
  STAMT,
  MIDYUL,
  MIDAMT,
  ENDYUL,
  ENDAMT,
  PERID,
  CLTCD,
  ACTCD,
  PROJNO,
  UAMT,
  QTY,
  SAMT,
  TAMT,
  PCODE
}

export enum OrderItemChangeTypeNames {
  cdflag,
  baldate,
  sunflag,
  saleyul,
  saleamt,
  styul,
  stamt,
  midyul,
  midamt,
  endyul,
  endamt,
  perid,
  cltcd,
  actcd,
  projno,
  uamt,
  qty,
  samt,
  tamt,
  pcode
}

export enum OrderButtonClickedTypes {
  BALPERNM,
  TEL,
  FAX,
  EMAIL,
  PIC,
  REFER,
  PROJNO,
}

export enum OrderButtonClickedTypeNames{
  b_balpernm,
  b_tel,
  b_fax,
  b_email,
  b_pic,
  b_refer,
  b_projno
}

interface OrderState {

  // 검색 조건(메인)
  searchQuery: string;
  stdate: string;
  curdate: string;
  enddate: string;
  perid: string;
  pernm: string;

  // 검색 조건(팝업)
  popupSearchQuery: string;
  baldate: string;
  cltcd: string;
  cltnm: string;

  // 출력 구분
  gubun: string | number;
  ipgubuns: Array<any>;

  // 데이터 객체
  focusedOrder?: OrderModel;
  focusedOrderDetail?: OrderDetailModel;
  data: OrderModel;
  lastNewData: OrderModel;
  orderList: Array<OrderModel>;
  orderDetailList: Array<OrderDetailModel>;
  popupList: Array<BillPopupModel>;
  popupFocused?: BillPopupModel;
  actcd: string;
  actnm: string;
  cdflag: string;


  // 팝업
  referDetailModal: boolean;// 수신자참조 팝업
  photoDetailModal: boolean;// 첨부사진 팝업
  fileDetailModal: boolean; // 첨부파일 팝업
  loadBillModal: boolean; // 자재청구서 불러오기
  selectGubunModal: boolean; // 프린트 구분

  isReported: boolean; // 결재상신 여부
  isCopy: boolean; // 발주서복사 여부

  uamt_tot: string;
  samt_tot: string;
  tamt_tot: string;
  mamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca608_01
 * @category 발주등록
 */
export class Order extends PageComponent<PageProps, OrderState>
  implements PageToolEvents {
  updatedRows?: Array<OrderDetailModel>;

  updatedRows2?: Array<BillPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const params = this.props.publicStore?.getPageParams();

    // state 기본값 정의
    this.state = props.state || {
      // 검색란
      curdate: `${year}${month}${date}`,
      stdate: params?.stdate || `${year}${month}01`,
      enddate: params?.enddate || `${year}${month}${date}`,
      searchQuery: params?.searchQuery || '',
      perid: '',
      pernm: '',

      popupSearchQuery: '',
      baldate: `${year}${month}${date}`,
      cltcd: '',
      cltnm: '',
      actcd: '',
      actnm: '',

      gubun: Gubun.INTERNAL, // 초기값 내부용 출력구분
      ipgubuns: [], // 입고구분

      data: new OrderModel(),
      orderList: [],
      orderDetailList: [],
      isCopy: false,
      isReported: false,

      uamt_tot: '',
      samt_tot: '',
      tamt_tot: '',
      mamt_tot: '',
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        stdate: pageParams?.stdate || this.state.stdate,
        enddate: pageParams?.enddate || this.state.enddate,
        searchQuery: pageParams?.searchQuery || this.state.searchQuery,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          ibgflag: this.state.data.ibgflag,
          baldate: this.state.data.baldate,
          balnum: this.state.data.balnum,
          appnum: this.state.data.appnum,
          remark: this.state.data.remark,
          cltnm: this.state.data.cltnm,
          appgubun: this.state.data.appgubun,
        }, false,
      );

      data && this.setState({
        data: new OrderModel({
          ...this.state.data,
          ...data,
        }, data.new),
      });

      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const data = await api.dropdown('wf_dd_ca510_029_01');
    this.setState({ ipgubuns: data.items });

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        pernm: this.state.pernm,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          orderList: [...this.state.orderList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          orderList: [],
          orderDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.orderList && this.state.orderList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      data: new OrderModel(),
      isReported: false,
      focusedOrder: new OrderModel(),
      orderList: [],
      orderDetailList: [],
      isCopy: false,
    }, async () => {
      await this.table.current?.update(true);
      const index = await this.infinity?.retrieveTo(['baldate', 'balnum'], [lastSelected?.baldate, lastSelected?.balnum], type, true) || 0;
      if (this.state.orderList && this.state.orderList.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: OrderModel) {
    if (this.state.isCopy) {
      this.setState({
        focusedOrderDetail: undefined,
      });
      return;
    }
    if (item?.new === '1') {
      this.setState({
        focusedOrderDetail: undefined,
        orderDetailList: [],
        data: this.state.lastNewData,
      });
      this.table.current?.update();
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState(
      { focusedOrder: new OrderModel(item, item?.isNew) },
      async () => {
        const data = await api.exec(Category.MATERIAL, 'dw_1_RowFocuschanged', this.state.focusedOrder);

        this.infinity2 = new InfinityRetrieve(
          item,
          (params) => api.fxExec('dw_1_RowFocuschanged', params),
          (items) => {
            this.setState({
              orderDetailList: items.map((x: any) => new OrderDetailModel({
                ...x,
                // eslint-disable-next-line no-nested-ternary
                // balseq: index < 9 ? `00${index + 1}` : index < 99 ? `0${index + 1}` : (index + 1).toString(),
              })),
            });
          },
          async () => {
            await this.SS({ orderDetailList: [] });
            await this.infinity2?.retrieve();
            await this.table.current?.update();
            this.table.current?.setFocus(0, 0);
          },
        );
        // 결재상신 여부확인
        if (this.state.focusedOrder?.appgubun === null
          || this.state.focusedOrder?.appgubun === undefined
          || this.state.focusedOrder?.appgubun === ''
          || this.state.focusedOrder?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }

        // 상단 조회 버튼을 누를때는 기존 배열 초기화
        this.setState({
          orderDetailList: [],
        }, async () => {
          await this.infinity2?.retrieve();
          this.SS({
            data: new OrderModel(data),
            uamt_tot: data?.uamt_tot || '0',
            samt_tot: data?.samt_tot || '0',
            tamt_tot: data?.tamt_tot || '0',
            mamt_tot: data?.mamt_tot || '0',
          });

          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        });
      },
    );
  }

  @action
  onRowFocusEvent2(item:OrderDetailModel) {
    this.setState({ focusedOrderDetail: item });
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    // if (this.state.isReported && !this.state.isCopy) {
    //   ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
    //   return;
    // }
    if (!this.state.data.remark) {
      ConfirmWarning.show('경고', '특이사항(공사명)을 입력하세요.');
      return;
    }
    if (!this.state.data.baldate) {
      ConfirmWarning.show('경고', '발주일자를 확인해주세요.');
      return;
    }
    if (!this.state.data.cltnm) {
      ConfirmWarning.show('경고', '발주처를 확인해주세요.');
      return;
    }
    if (!this.state.data.perid) {
      ConfirmWarning.show('경고', '담당자를 확인해주세요.');
      return;
    }
    if (this.state.orderDetailList.length < 1) {
      ConfirmWarning.show('경고', '발주품목을 입력해주세요.');
      return;
    }

    if ((this.state.data.sunflag === '1')
      && (this.state.data.styul + this.state.data.midyul + this.state.data.endyul !== 100)) {
      ConfirmWarning.show('경고', '계약금 + 중도금 + 잔금과 합계금액이 상이합니다!');
      return;
    }

    const { actionStore: api } = this.props;

    if (await api.save({
      ...this.state.data,
      items: this.state.orderDetailList,
    },
    this.state.data.isNew)) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.baldate);
      await this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '' || this.state.enddate < Today.date() || this.state.perid !== '') {
      await this.SS({
        searchQuery: '',
        stdate: `${Date8.make().substr(0, 6)}01`,
        enddate: Date8.make(),
        perid: '',
        pernm: '',
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }

    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();
    data.cdflag = '0';
    if (data) {
      const newModel = new OrderModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        isReported: false,
        orderList: [
          newModel,
          ...this.state.orderList,
        ],
        orderDetailList: [],
        focusedOrder: newModel,
      }, async () => {
        await this.table.current?.update(false);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    // 삭제 팝업창 안띄어서 주석처리함
    // if (this.state.isReported) {
    //   await api.fxExec('delete',
    //     {
    //       ...this.state.data,
    //       mijflag: this.state.data?.mijflag,
    //     }, false);
    // } else {
    //   const text = `발주일자: ${this.state.focusedOrder?.baldate}, 발주번호: ${this.state.focusedOrder?.balnum}`;
    //   await api.delete(text, this.state.data) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    // }

    let result;

    // 삭제시 상신돼있는 문서면 팝업창 띄움(대기상태 제외)
    if (this.state.isReported && this.state.focusedOrder?.appgubun !== '001') {
      result = await Confirm.ask('확인', '이미 결재가 상신되어있는 문서입니다.다른이(상급자)가 이문서에 대해 승인이 있습니다.그래도 상신취소 하시겠습니까?', '확인', '취소');
    }

    if (result === 1) {
      return;
    }

    // 결재된 문서 && 결재 대기상태는 바로 삭제되게끔 함
    if (this.state.isReported && this.state.focusedOrder?.appgubun !== '001') {
      await api.fxExec(
        'delete_appok',
        {
          ibgflag: this.state.data.ibgflag,
          baldate: this.state.data.baldate,
          balnum: this.state.data.balnum,
          appnum: this.state.data.appnum,
          remark: this.state.data.remark,
          cltnm: this.state.data.cltnm,
          appgubun: this.state.data.appgubun,
        }, false,
      );
      await this.onRetrieveEvent();
      return;
    }

    const text = `발주일자: ${this.state.focusedOrder?.baldate}, 발주번호: ${this.state.focusedOrder?.balnum}`;
    await api.delete(text, this.state.data) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        baldate: this.state.data?.baldate,
        balnum: this.state.data?.balnum,
        balseq: this.state.focusedOrderDetail?.balseq,
        sogigan: this.state.data?.sogigan,
      },
    );

    const seq = this.state.orderDetailList.length;
    data && this.setState({
      orderDetailList: [...this.state.orderDetailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new OrderDetailModel({ ...data, balseq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString() }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.orderDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.orderDetailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 항목이 없습니다.');
      return;
    }

    const text = `순번: ${this.state.focusedOrderDetail?.balseq}`;

    if (this.state.data.isNew) {
      if (await ConfirmDelete.show(text)) {
        // 발주등록이 신규일 경우 해당 행을 삭제하고 balseq 다시 매기기
        await this.SS({
          orderDetailList: this.state.orderDetailList
            .filter((x) => x.balseq !== this.state.focusedOrderDetail?.balseq)
            .map((x, i) => new OrderDetailModel({
              ...x,
              balseq: `000${i + 1}`.substr(-3),
            }, x.isNew)),
        });
        this.table.current?.update(true);
      }
      return;
    }

    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        baldate: this.state.data?.baldate,
        balnum: this.state.data?.balnum,
        balseq: this.state.focusedOrderDetail?.balseq,
        ibgflag: this.state.focusedOrderDetail?.ibgflag,
      },
    ) && this.onRowFocusEvent(this.state.focusedOrder!);
  }

  @action
  async onButtonClicked(type:any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };

    if (type === OrderButtonClickedTypes.BALPERNM
      || type === OrderButtonClickedTypes.TEL
      || type === OrderButtonClickedTypes.FAX
      || type === OrderButtonClickedTypes.EMAIL
      || type === OrderButtonClickedTypes.PIC
      || type === OrderButtonClickedTypes.PROJNO) {
      data = await api.fxExec(
        'dw_2_buttonclicked',
        {
          itemname: OrderButtonClickedTypeNames[type],
          data: '',
          ...this.state.data,
        },
      );
    }

    data && this.setState({
      data: new OrderModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  // 발주서 복사
  @action
  async onCopy() {
    const text = `청구일자: ${this.state.focusedOrder?.baldate}, 청구번호: ${this.state.focusedOrder?.balnum}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    // ibgflag값 0을 기본값으로 보내야해서 0으로 setting
    for (let i = 0; i < this.state.orderDetailList.length; i += 1) {
      this.state.orderDetailList[i].ibgflag = '0';
    }

    const { actionStore: api } = this.props;
    const data = await api.exec(Category.MATERIAL, 'wb_copy', {
      ...this.state.data,
      ibgflag: '0', // 기본값
      delflag: '0', // 기본값
      balnum: '',
      appnum: '',
      appgubun: '',
      items: this.state.orderDetailList,
    });

    if (data) {
      const newModel = new OrderModel(data, true);

      this.setState({
        data: newModel,
        orderList: [newModel, ...this.state.orderList],
        focusedOrder: newModel,
        orderDetailList: data.items,
        isCopy: true,
      }, async () => {
        await this.table.current?.update(false);
      });
      this.grid.current?.setFocus(0);
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    let text = '';
    let appflag = '';

    if (user.pernm !== this.state.data.pernm) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다.');
      return;
    }

    if (this.state.data?.isNew) {
      ConfirmWarning.show('경고', '저장 후 상신 부탁드립니다.');
      return;
    }

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    // await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 팝업
    if (appflag === 'ok') {
      await modalStore.openApprovalLine(PAPERCD.REQUEST);
    }

    // 결재상신
    const data = await api.exec(Category.MATERIAL, 'wb_appreport', {
      papercd: PAPERCD.REQUEST.toString(),
      ...this.state.data,
      ...this.state.focusedOrder,
      appnum: this.state.data.appnum,
      appgubun: this.state.focusedOrder?.appgubun,
      baldate: this.state.focusedOrder?.baldate,
      balnum: this.state.focusedOrder?.balnum,
      remark: this.state.data.remark,
      perid: this.state.data.inperid,
      cltnm: this.state.data.cltnm,
      refer: this.state.data.refer,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  selectGubunModal(isOpen: boolean) {
    this.setState({ selectGubunModal: isOpen });
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1' || this.state.isCopy) {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }

    this.setState({ fileDetailModal: isOpen });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.REQUEST, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedOrder!);
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async photoModal(isOpen: boolean) {
    isOpen && ConfirmWarning.show('경고', '아직준비중입니다.');
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const uamt = Format.toNumber(item.uamt);
    const tamt = Format.toNumber(item.tamt);
    const samt = Format.toNumber(item.samt);

    let sum = 0;
    let tax = 0;
    let amount = Math.round(qty * uamt);
    if (name === 'qty' || name === 'uamt') {
      tax = Math.round((qty * uamt) / 10);
      sum = Math.round(amount + tax);
    } else if (name === 'samt') {
      amount = samt;
      tax = Math.round(samt / 10);
      sum = Math.round(amount + tax);
    } else {
      amount = samt;
      tax = tamt;
      sum = Math.round(samt + tamt);
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: amount.toString(),
      tamt: tax.toString(),
      mamt: sum.toString(),
    });
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;
    let data: any;

    if (type === OrderItemChangeTypeNames.baldate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: this.state.data.baldate,
          // items3: this.state.orderDetailList.length.toString(), // detail list count
          new: this.state.data.new,
        },
      );
    }

    if (type === OrderItemChangeTypeNames.cdflag) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: this.state.data.cdflag,
          new: this.state.data.new,
        },
      );
    }

    if (type === OrderItemChangeTypeNames.sunflag) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: this.state.data.sunflag,
          mamt: this.state.data.mamt,
          new: this.state.data.new,
        },
      );
    }

    if (type === OrderItemChangeTypeNames.saleamt
    || type === OrderItemChangeTypeNames.saleyul
    || type === OrderItemChangeTypeNames.stamt
    || type === OrderItemChangeTypeNames.styul
    || type === OrderItemChangeTypeNames.midamt
    || type === OrderItemChangeTypeNames.midyul
    || type === OrderItemChangeTypeNames.endamt
    || type === OrderItemChangeTypeNames.endyul) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: '',
          ...this.state.data,
          new: this.state.data.new,
        },
      );
    }

    if (type === OrderItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: item,
          new: this.state.data.new,
        },
      );

      if (data) {
        let warning: boolean;
        // eslint-disable-next-line prefer-const
        warning = await Confirm.show('확인', `${this.state.actnm}현장으로 전부 설정하시겠습니까?`, ConfirmType.QUESTION);
        if (warning) {
          const chkData: any[] = [];
          this.state.orderDetailList.forEach((x: any) => {
            chkData.push({
              ...x,
              actcd: this.state.actcd,
              actnm: this.state.actnm,
            });
          });
          this.setState({
            orderDetailList: chkData,
          }, async () => {
            await this.table.current?.update();
          });

          // 전부 같은 현장으로 설정시 입고일도 전부 같은 일자로 수정할지 물어봄.
          let delDateAll: boolean;
          // eslint-disable-next-line prefer-const
          delDateAll = await Confirm.show('확인', '입고일을 같은날로 전부 설정하시겠습니까?', ConfirmType.QUESTION);

          // erp에서는 맨위에 현장만 변경하는경우 전체현장 변경할지 물어보는 팝업창떠서 첫번째 row의 입고일자만 가져와서 전체 셋팅해줌.
          const firstRowDelDate = this.state.orderDetailList[0].deldate;
          if (delDateAll) {
            const deldateChangeData: any[] = [];
            this.state.orderDetailList.forEach((x: any) => {
              deldateChangeData.push({
                ...x,
                deldate: firstRowDelDate,
              });
            });

            this.setState({ orderDetailList: deldateChangeData });
            await this.table.current?.update();
          }
        }
        await this.actcdItemChanged(type, item);
      }
    }

    if (type === OrderItemChangeTypeNames.cltcd
    || type === OrderItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: item,
          new: this.state.data.new,
        },
      );
    }

    data && this.setState({
      data: new OrderModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
        ...(type === OrderItemChangeTypeNames.actcd && this.state.data.bigo ? { bigo: this.state.data.bigo } : { bigo: data.bigo }),
      }, data.new === '1'),
    });
  }

  @action
  async detailItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    const focusedDetailIndex = this.state.orderDetailList.findIndex((x) => x.balseq === item.balseq);
    let data = { new: undefined };
    if (type === OrderItemChangeTypeNames.uamt
      || type === OrderItemChangeTypeNames.qty
      || type === OrderItemChangeTypeNames.samt
      || type === OrderItemChangeTypeNames.tamt
    ) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          data: '',
          ...item,
          new: this.state.focusedOrderDetail?.new,
        },
      );
    }

    if (type === OrderItemChangeTypeNames.pcode) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: OrderItemChangeTypeNames[type],
          cltcd: this.state.data.cltcd,
          data: this.state.focusedOrderDetail?.pcode,
          new: this.state.focusedOrderDetail?.new,
        },
      );
    }

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedOrderDetail: new OrderDetailModel({
        ...this.state.focusedOrderDetail,
      }, this.state.focusedOrderDetail?.isNew),
      // eslint-disable-next-line max-len
      orderDetailList: this.state.orderDetailList.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new OrderDetailModel({ ...x, ...data }, data.new === '1') : new OrderDetailModel(x)
      )),
    }, () => this.table.current?.update(false));
  }

  @action
  async actcdItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'dw_3_itemchanged',
      {
        itemname: OrderItemChangeTypeNames[type],
        actcd: item,
        data: item,
        new: this.state.focusedOrderDetail?.new,
      },
    );

    if (this.state.data.cdflag === '0' && !this.state.data.bigo) {
      // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
      data && this.setState({
        data: new OrderModel({
          ...this.state.data,
          ...data,
        }),
      });
    }
  }

  @action
  async loadBillModal(isOpen: boolean) {
    this.setState({ loadBillModal: isOpen });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca606_01',
        as_nm: this.state.popupSearchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          popupList: [...this.state.popupList, ...items],
        }, () => this.popupTable.current?.update(false));
      },
      async () => {
        await this.SS({ popupList: [] });
        await this.infinity3?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      popupList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
    });
  }


  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_ca606_01',
      items: this.updatedRows2,
    }) && await this.modalRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('save',
      {
        sub: 'w_popup_ca606_01',
        as_nm: this.state.popupSearchQuery,
        baldate: this.state.baldate || '',
        cltcd: this.state.cltcd || '',
        cltnm: this.state.cltnm || '',
        items: this.updatedRows2,
      });

    if (data) {
      await this.loadBillModal(false);
      await this.onRetrieveEvent();
      this.updatedRows2 = [];
    }
  }

  @action
  async onPrintEvent() {
    if (this.state.isReported) {
      this.setState({ selectGubunModal: true });
    } else {
      await this.onPrint();
    }
  }

  @action
  async onPrint() {
    const { actionStore: api } = this.props;
    if (this.state.orderList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다');
      return;
    }

    let result;
    // 금액 표기
    if (this.state.gubun === '0') {
      result = await Confirm.ask('선택!', '금액표기를 적용하여 출력하시겠습니까?', '예', '아니오');
      // 예이면 1 아니요면 net 값 0으로 보내야함.
      // 예 누르면 result값 0으로 나와서 변환.
      if (result === 1) {
        result = '0';
      } else {
        result = '1';
      }
    }
    await api.printWithElmanManager({
      baldate: this.state.data.baldate,
      balnum: this.state.data.balnum,
      appgubun: this.state.data.appgubun,
      gubun: String(this.state.gubun),
      net: result,
      attcnt: this.state.data.attcnt,
    });

    this.setState({ gubun: Gubun.INTERNAL });
  }

  @action
  async onSendFax() {
    if (!this.state.isReported) {
      ConfirmWarning.show('경고', '결재가 나지 않은 건은 Fax 전송할 수 없습니다');
      return;
    }

    if (!this.state.data.faxnum) {
      ConfirmWarning.show('경고', '먼저 발주처 Fax 항목을 입력해주세요');
      return;
    }

    const result = await Confirm.ask('금액표기', '금액표기를 적용하여 팩스를 전송 하시겠습니까?', '예', '아니오');

    const { actionStore: api } = this.props;
    api.fxFax(
      this.state.data.faxnum,
      this.state.data.remark,
      {
        baldate: this.state.data.baldate,
        balnum: this.state.data.balnum,
        appgubun: this.state.data.appgubun,
        gubun: Gubun.EXTERNAL.toString(),
        net: result === AskType.YES ? '1' : '0',
        attcnt: this.state.data.attcnt,
      },
    );
  }

  @action
  async onSendEmail() {
    if (!this.state.isReported) {
      ConfirmWarning.show('경고', '결재가 나지 않은 건은 Email 전송할 수 없습니다.');
      return;
    }

    if (!this.state.data.email) {
      ConfirmWarning.show('경고', '먼저 발주처 Email 항목을 입력해주세요');
      return;
    }

    const result = await Confirm.ask('금액표기', '금액표기를 적용하여 메일을 전송 하시겠습니까?', '예', '아니오');

    const { actionStore: api, publicStore } = this.props;
    const { spjangnm } = publicStore.user;
    api.fxEmail(
      this.state.data.email,
      this.state.data.balpernm,
      '',
      this.state.data.telnum,
      `발주서 : ${spjangnm}`,
      `${this.state.data.cltnm}의 무궁한 발전을 기원합니다.`,
      {
        baldate: this.state.data.baldate,
        balnum: this.state.data.balnum,
        appgubun: this.state.data.appgubun,
        gubun: Gubun.EXTERNAL.toString(),
        net: result === AskType.YES ? '1' : '0',
        attcnt: this.state.data.attcnt,
      },
    );
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */
  @action
  onUpdatedRows(rows: Array<OrderDetailModel>, updatedRows: Array<OrderDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ orderDetailList: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows2      전체 행 (변경 행 반영된 상태)
   * @param updatedRows2 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<BillPopupModel>, updatedRows2: Array<BillPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ popupList: rows2 });
  }

  render() {
    return (
      <OrderTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new OrderModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
