/**
 * 모델
 * @window w_tb_e911w_01
 * @category 고객현황
 */
import { computed } from 'mobx';

export class CustomerPresentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 고객코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * No
   *
   * **data type** char(2)
   */
  seq: string;

  /**
   * 연결코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 전화번호
   *
   * **data type** varchar(20)
   */
  tel: string;

  /**
   * 휴대폰
   *
   * **data type** varchar(20)
   */
  hp: string;

  /**
   * 팩스번호
   *
   * **data type** varchar(20)
   */
  fax: string;

  /**
   * 고객명
   *
   * **data type** varchar(255)
   */
  actmail: string;

  /**
   * 비고(담당자)
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 등록일
   *
   * **data type** char(8)
   */
  regdate: string;

  /**
   * 등록시간
   *
   * **data type** char(4)
   */
  regtime: string;

  /**
   * 고객구분
   *
   * **data type** char(1)
   */
  regflag: string;

  /**
   * X
   *
   * **data type** varchar(15)
   */
  orgactcd: string;

  /**
   * 담당자
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 회사메일
   *
   * **data type** varchar(50)
   */
  email: string;

  /**
   * 홈페이지
   *
   * **data type** varchar(255)
   */
  homepage: string;

  /**
   * 주소
   *
   * **data type** varchar(6)
   */
  zipcd: string;

  /**
   * 주소1
   *
   * **data type** varchar(255)
   */
  address: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remarks: string;

  /**
   * 별명
   *
   * **data type** varchar(50)
   */
  nicknm: string;

  /**
   * 회사명
   *
   * **data type** varchar(50)
   */
  custnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(20)
   */
  divinm: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 개인메일
   *
   * **data type** varchar(50)
   */
  memail: string;

  /**
   * 생일
   *
   * **data type** varchar(8)
   */
  birthday: string;

  /**
   * X
   *
   * **data type** varchar(6)
   */
  mezipcd: string;

  /**
   * X
   *
   * **data type** varchar(255)
   */
  meaddress: string;

  /**
   * 주소2
   *
   * **data type** varchar(255)
   */
  address2: string;

  /**
   * 구우편번호
   *
   * **data type** varchar(6)
   */
  oldzipcd: string;

  /**
   * 구주소
   *
   * **data type** varchar(255)
   */
  oldaddress: string;

  /**
   * 구상세주소
   *
   * **data type** varchar(255)
   */
  oldaddress2: string;

  /**
   * 위도
   *
   * **data type** varchar(16)
   */
  lat: string;

  /**
   * 경도
   *
   * **data type** varchar(16)
   */
  lng: string;

  /**
   * 그룹분류
   *
   * **data type** varchar(10)
   */
  groupcd: string;

  /**
   * 전화분류
   *
   * **data type** varchar(10)
   */
  telcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.seq = data.seq || '';
    this.cltcd = data.cltcd || '';
    this.tel = data.tel || '';
    this.hp = data.hp || '';
    this.fax = data.fax || '';
    this.actmail = data.actmail || '';
    this.remark = data.remark || '';
    this.regdate = data.regdate || '';
    this.regtime = data.regtime || '';
    this.regflag = data.regflag || '';
    this.orgactcd = data.orgactcd || '';
    this.perid = data.perid || '';
    this.email = data.email || '';
    this.homepage = data.homepage || '';
    this.zipcd = data.zipcd || '';
    this.address = data.address || '';
    this.remarks = data.remarks || '';
    this.nicknm = data.nicknm || '';
    this.custnm = data.custnm || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.memail = data.memail || '';
    this.birthday = data.birthday || '';
    this.mezipcd = data.mezipcd || '';
    this.meaddress = data.meaddress || '';
    this.address2 = data.address2 || '';
    this.oldzipcd = data.oldzipcd || '';
    this.oldaddress = data.oldaddress || '';
    this.oldaddress2 = data.oldaddress2 || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.groupcd = data.groupcd || '';
    this.telcd = data.telcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
