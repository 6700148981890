import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  MainModel,
  PartModel,
  UseModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { OrderStatementTemplate } from './OrderStatement.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderPart,
  TabHeaderUse,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum OrderStatementTabId {
  Part,
  Use,
}

export const OrderStatementTabTitles = [
  '부품내역',
  '사용내역',
];

export const OrderStatementTabModels = [
  PartModel,
  UseModel,
];

export const OrderStatementTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const OrderStatementTabDataStateNames = [
  'partList',
  'useList',
];

export interface OrderStatementState {
  partList?: Array<PartModel>; // 부품내역 리스트
  useList?: Array<UseModel>; // 사용내역 리스트

  orderStatementList: Array<MainModel>;
  data: MainModel;
  focusedTab?: TabModel;
  focused?: TabModel;

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  divicd: string; // 부서코드
  divinm: string; // 부서명
  cltcd: string; // 거래처코드
  cltnm: string; // 거래처명
  perid: string; // 담당자
  pernm: string; // 담당자명
  actcd: string; // 현장코드
  actnm: string; // 현장명
  projcd: string; // 프로젝트코드
  projnm: string; // 프로젝트명

  tabPartSamt_tot: string;
  tabPartTamt_tot: string;
  tabPartMamt_tot: string;
  total: string;
  mamt_tot: string;
}
/**
 * 컨트롤러
 * @window w_tb_ca608w_02
 * @category 발주현황
 */
export class OrderStatement extends PageComponent<PageProps, OrderStatementState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  tapChk?: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = OrderStatementTabId;
    const titles = OrderStatementTabTitles;

    this.tabs = [
      new TabModel(id.Part.toString(), MdReorder, titles[id.Part]),
      new TabModel(id.Use.toString(), MdReceipt, titles[id.Use]),
    ];

    this.tabHeaders = [
      TabHeaderPart, // 부품내역
      TabHeaderUse, // 사용내역
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      orderStatementList: [],
      date: new MainModel(),
      partList: [],
      useList: [],
      searchQuery: '', // 검색어
      divicd: '', // 부서코드
      divinm: '', // 부서명
      cltcd: '', // 거래처코드
      cltnm: '', // 거래처명
      perid: '', // 담당자
      pernm: '', // 담당자명
      actcd: '', // 현장코드
      actnm: '', // 현장명
      projcd: '', // 프로젝트코드
      projnm: '', // 프로젝트명

      total: '',
      mamt_tot: '',
      tabPartSamt_tot: '',
      tabPartTamt_tot: '',
      tabPartMamt_tot: '',
    };
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[OrderStatementTabId.Part]);
    // await this.doRetrieve(OrderStatementTabId.Part);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    if (i === OrderStatementTabId.Part && (this.state.partList?.length || 0) === 0) {
      await this.doRetrieve(OrderStatementTabId.Part);
    } else {
      await this.doRetrieve(i);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (this.state.orderStatementList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${OrderStatementTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        cltcd: this.state.cltcd,
        perid: this.state.perid,
        actcd: this.state.actcd,
        projcd: this.state.projcd,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[OrderStatementTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${OrderStatementTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        cltcd: this.state.cltcd,
        perid: this.state.perid,
        actcd: this.state.actcd,
        projcd: this.state.projcd,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @computed
  get tabIndex(): OrderStatementTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: OrderStatementTabId) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        cltcd: this.state.cltcd,
        perid: this.state.perid,
        actcd: this.state.actcd,
        projcd: this.state.projcd,
        as_nm: this.state.searchQuery,
      },
      (params) => api.fxExec(
        `${OrderStatementTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        this.setState({
          orderStatementList: [
            ...this.state.orderStatementList,
            ...items.map((x: any) => new MainModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          orderStatementList: [],
          partList: [],
          useList: [],
        });
        await this.infinity?.retrieveAll();

        if (this.state.orderStatementList.length) {
          await this.onRowFocusEvent(this.state.orderStatementList[0]);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      orderStatementList: [],
      partList: [],
      useList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.setState({
        total: this.infinity?.box?.total || '0',
        mamt_tot: this.infinity?.box?.mamt_tot || '0',
      });
      if (this.state.orderStatementList.length) {
        await this.onRowFocusEvent(this.state.orderStatementList[0]);
      } else {
        this.setState({
          partList: [],
          useList: [],
        });
      }
    });
  }

  @action
  onRowFocusEvent(item: any) {
    const { actionStore: api } = this.props;

    this.setState({ data: item }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        {
          ...item,
        },
        (params) => api.fxExec(`tab_${this.tapChk}_dw_1_RowFocuschanged`, params),
        (items) => {
          if (items) {
            this.setState({
              partList: items,
              useList: items,
            });
          }
        },
        async () => {
          await this.SS({
            partList: [],
            useList: [],
          });
          await this.infinity2?.retrieveAll();
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        partList: [],
        useList: [],
      }, async () => {
        await this.infinity2?.retrieveAll();
        this.setState({
          tabPartSamt_tot: this.infinity2?.box?.samt_tot || '0',
          tabPartTamt_tot: this.infinity2?.box?.tamt_tot || '0',
          tabPartMamt_tot: this.infinity2?.box?.mamt_tot || '0',
        });
      });
    });
  }

  render() {
    return (
      <OrderStatementTemplate
        scope={this}
      />
    );
  }
}
