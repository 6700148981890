import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { GridLayout, TableLayout } from '../../../../components';
import { CMSAutoTemplate } from './CMSAuto.template';
import { InfinityRetrieve } from '../../../../models/common';
import { CMSAutoModel } from './CMSAuto.model';
import { FileSelector } from '../../../../utils/file';
import {
  ConfirmFail,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import Confirm from '../../../../utils/confirm/Confirm';

interface CMSAutoState {
  // 검색조건
  stdate: string;
  enddate: string;
  cmsdate: string;
  isReported: boolean;

  // data
  cmsAutoList: Array<CMSAutoModel>;
  cmsAutoListDetailList: Array<CMSAutoModel>;
  focused?: CMSAutoModel;
  focusIndex: number;
  focusedDetail?: CMSAutoModel;

  pushTotalCheck: boolean;
  pushData: Array<CMSAutoModel>;
  excelUploadFocus: boolean;

  // trail
  jamt_tot: string;
  misamt_tot: string;
  total: string;
  iamt_tot: string;
  notamt_tot: string;
  rcvamt_tot: string;
  bmar_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da026_cms
 * @category CMS자동이체
 */
export class CMSAuto extends PageComponent<PageProps, CMSAutoState>
  implements PageToolEvents {
  updatedRows?: Array<CMSAutoModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  allData?: Array<CMSAutoModel>;

  files?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // search
      stdate: `${year}${month}01`,
      enddate: date === '30' ? `${year}${month}30` : `${year}${month}31`,
      cmsdate: `${year}${month}${date}`,
      isReported: false,

      // data
      focusIndex: 0,
      cmsAutoList: [],
      cmsAutoListDetailList: [],
      excelUploadFocus: false,

      // trail
      jamt_tot: '0',
      misamt_tot: '0',
      total: '0',
      iamt_tot: '0',
      notamt_tot: '0',
      rcvamt_tot: '0',
      bmar_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          cmsAutoList: [
            ...this.state.cmsAutoList,
            ...items.map((x: any) => new CMSAutoModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          cmsAutoList: [],
          cmsAutoListDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.cmsAutoList && this.state.cmsAutoList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      cmsAutoList: [],
      cmsAutoListDetailList: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['savedate', 'cnt'],
        [this.state.focused?.savedate, this.state.focused?.cnt], type, true) || 0;
      if (this.state.excelUploadFocus) {
        await this.grid.current?.setFocus(this.state.cmsAutoList.length - 1);
        this.setState({ excelUploadFocus: false });
      } else if (this.state.cmsAutoList && this.state.cmsAutoList.length > index) {
        await this.grid.current?.setFocus(index);
      }
    });
    await this.table.current?.update(true);
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    const { actionStore: api } = this.props;

    this.setState({
      focused: item,
      cmsdate: item?.savedate,
      focusIndex: index,
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        {
          savedate: item?.savedate,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            cmsAutoListDetailList: [
              ...this.state.cmsAutoListDetailList,
              ...items.map((x: any) => new CMSAutoModel(x, false)),
            ],
          });
          // 결재상신 여부확인
          if (this.state.focused?.appgubun === null
            || this.state.focused?.appgubun === ''
            || this.state.focused?.appgubun === '131') {
            this.setState({ isReported: false });
          } else {
            this.setState({ isReported: true });
          }
        },
        async () => {
          await this.SS({
            cmsAutoListDetailList: [],
          });
          await this.infinity2?.retrieveAll();
          if (this.state.cmsAutoListDetailList && this.state.cmsAutoListDetailList?.length > 0) {
            await this.table.current?.update(true);
            this.table.current?.setFocus(0, 3);
          }
        },
      );
      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        cmsAutoListDetailList: [],
      }, async () => {
        // 전체 체크
        this.allData = await this.infinity2?.retrieveAll();

        if (this.state.cmsAutoListDetailList.length) {
          const data = await api.fxExec(
            'dw_1_RowFocuschanged',
            { savedate: item?.savedate },
          );
          this.SS({
            jamt_tot: data?.jamt_tot || '0',
            misamt_tot: data?.misamt_tot || '0',
            total: data?.total || '0',
            iamt_tot: data?.iamt_tot || '0',
            notamt_tot: data?.notamt_tot || '0',
            rcvamt_tot: data?.rcvamt_tot || '0',
            bmar_tot: data?.bmar_tot || '0',
          });
        }

        // 입금버튼
        if (this.state.focused?.rcvflag === '2') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }

        if (this.state.cmsAutoListDetailList && this.state.cmsAutoListDetailList?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 3);
        }
        this.state.cmsAutoListDetailList.length > 0 && this.onRowFocusEvent2(this.state.cmsAutoListDetailList[0], 0);
      });
    });
  }

  @action
  onRowFocusEvent2(item: any, index: number) {
    this.setState({
      focusedDetail: item,
      focusIndex: index,
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.allData?.forEach((x: any) => {
      if (x.flag === '1' && x.rcvflag === '1' && x.cltflag === '1' && checked) {
        checkData.push({
          ...x,
          chk: checked ? '1' : '0',
        });
      } else if (!checked) {
        checkData.push({
          ...x,
          chk: '0',
        });
      } else {
        checkData.push({
          ...x,
          chk: '0',
        });
      }
    });

    this.setState({
      pushTotalCheck: checked,
      cmsAutoListDetailList: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onDeleteEvent() {
    if (!this.state.isReported) {
      ConfirmWarning.show('확인', '입금상태에서는 삭제할 수 없습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    const text = `저장일자: ${this.state.focused?.savedate}`;
    await api.delete(text, this.state.focusedDetail) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ cmsAutoListDetailList: rows });
  }

  @action
  async onSubExcelEvent() {
    try {
      const files = await FileSelector.single(false);
      this.excelUpload(files[0].name, files[0]);
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async excelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('CMS자동이체', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);

      // 저장된 날짜 없을 때
      if (response.stdate) {
        await this.setState({ stdate: response.stdate });
      }

      // 이미 저장된 날짜 있을 때
      if (response.rcvdate) {
        await this.setState({ stdate: response.rcvdate });
      }

      await this.setState({
        excelUploadFocus: true,
      });
      await this.onRetrieveEvent();
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    await api.exec(
      Category.GENERAL,
      'wb_excel_lazytask',
      {
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    );
  }

  @action
  async onBtnEvent() {
    const { actionStore: api } = this.props;

    const item: any[] = [];
    this.state.cmsAutoListDetailList.forEach((x: any, key) => {
      item[key] = {
        chk: x.chk,
        seq: x.seq,
        cltflag: x.cltflag,
      };
    });

    let warning: boolean;
    if (this.state.isReported === false) {
      warning = await Confirm.show('확인', '입금된 내역을 취소하시겠습니까?', ConfirmType.QUESTION);
      if (warning === false) return;
    } else {
      warning = await Confirm.show('확인', 'CMS 내역일 입금 처리하시겠습니까?', ConfirmType.QUESTION);
      if (warning === false) return;
    }

    if (this.state.isReported === false) {
      await api.fxExec('wb_rcvdelete',
        {
          savedate: this.state.focused?.savedate,
          rcvdate: this.state.cmsdate,
          items: item,
        });
    } else {
      await api.fxExec('wb_rcvsave',
        {
          savedate: this.state.focused?.savedate,
          rcvdate: this.state.cmsdate,
          items: item,
        });
    }
    await this.onRetrieveEvent();
  }

  render() {
    return (
      <CMSAutoTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
