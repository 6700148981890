import * as React from 'react';
import { observer } from 'mobx-react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { Global } from '../../constants';
import { GridLayout } from '../../components/layout/GridLayout';
import { SubHeader } from '../../components/layout/SubHeader';
import {
  ComboBox, ComboBoxModel, DateTextBox, FormatTextBox, LayoutTitle, SearchLayout, TextBox,
} from '../../components';
import { Date8 } from '../../utils/time';

interface ModalQnAProps {
  modalStore: ModalStore;
}

@observer
export class ModalQnA extends React.Component<ModalQnAProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1500}
        height={1000}
        isVisible={modalStore.isVisibleQnASelector}
        onClose={() => modalStore.closeQnASelector()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchLayout>
              <FlexLayout size={200}>
                <span>기간</span>
                <DateTextBox
                    value={modalStore.stdateaQnA}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => {
                      modalStore.stdateaQnA = value;
                    }}
                    onEnter={() => modalStore.dataQnASelectorRetrieve()}
                    head={(<button
                        onClick={() => {
                          const date = new Date(Date8.withDash(modalStore.stdateaQnA));
                          date.setDate(date.getDate() - 1);
                          modalStore.stdateaQnA = Date8.make(date);
                          modalStore.dataQnASelectorRetrieve();
                        }}
                    >
                      <MdKeyboardArrowLeft size={24}/>
                    </button>)}
                    trail={(<button
                        onClick={() => {
                          const date = new Date(Date8.withDash(modalStore.stdateaQnA));
                          date.setDate(date.getDate() + 1);
                          modalStore.stdateaQnA = Date8.make(date);
                          modalStore.dataQnASelectorRetrieve();
                        }}
                    >
                      <MdKeyboardArrowRight size={24}/>
                    </button>)}
                    isDisabledTrackingStateChange={true}
                />
              </FlexLayout>

              <FlexLayout size={180}>
                <span style={{ marginLeft: 8 }}>~</span>
                <DateTextBox
                    value={modalStore.endateaQnA}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => {
                      modalStore.endateaQnA = value;
                    }}
                    onEnter={() => modalStore.dataQnASelectorRetrieve()}
                    head={(<button
                        onClick={() => {
                          const date = new Date(Date8.withDash(modalStore.endateaQnA));
                          date.setDate(date.getDate() - 1);
                          modalStore.endateaQnA = Date8.make(date);
                          modalStore.dataQnASelectorRetrieve();
                        }}
                    >
                      <MdKeyboardArrowLeft size={24}/>
                    </button>)}
                    trail={(<button
                        onClick={() => {
                          const date = new Date(Date8.withDash(modalStore.endateaQnA));
                          date.setDate(date.getDate() + 1);
                          modalStore.endateaQnA = Date8.make(date);
                          modalStore.dataQnASelectorRetrieve();
                        }}
                    >
                      <MdKeyboardArrowRight size={24}/>
                    </button>)}
                    isDisabledTrackingStateChange={true}
                />
              </FlexLayout>

              <FlexLayout size={400}>
                <span>검색어</span>
                <TextBox
                    value={modalStore.searchQueryQnA}
                    onChange={(value) => {
                      modalStore.searchQueryQnA = value;
                    }}
                    onEnter={() => modalStore.dataQnASelectorRetrieve()}
                    isDisabledTrackingStateChange={true}
                />
              </FlexLayout>

              <FlexLayout
                size={176}
                style={{ marginLeft: 12 }}
              >
                <span>확인</span>
                <ComboBox
                    value={modalStore.receflag}
                    textAlign="center"
                    data={[
                      { value: '%', remark: '전체' },
                      { value: '1', remark: '확인' },
                      { value: '0', remark: '미확인' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(item) => {
                      modalStore.receflag = item.value;
                      modalStore.dataQnASelectorRetrieve();
                    }}
                    isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            </SearchLayout>
          </FlexLayout>

          <GridLayout
            header={[
              {
                id: 'recedate',
                text: '접수일자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '시간',
                width: 10,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '요청자명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                      {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '요청내용',
                width: 50,
                render: (x) => (
                  <FlexLayout
                      justify="left"
                      align="center"
                  >
                      {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'receflag',
                text: '확인',
                width: 10,
                render: (x) => (
                  <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.receflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                  >
                      {x.receflag === '0' ? '미확인' : '확인'}
                  </FlexLayout>
                ),
              },
              {
                id: 'compflag',
                text: '답변',
                width: 10,
                render: (x) => (
                  <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.compflag === '0' ? 'var(--color-red)' : x.compflag === '1' ? 'var(--color-blue)' : '' }}
                  >
                      {x.compflag === '0' ? '미처리' : x.compflag === '1' ? '처리' : '보류'}
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore?.dataQnA || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => modalStore.onQnARowFocusEvent(item)}
          />

            <FlexLayout weight={0.1}>
              <LayoutTitle weight={0.1}>요청일시</LayoutTitle>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={modalStore.recedate}
              />

              <LayoutTitle weight={0.1}>처리일시</LayoutTitle>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={modalStore.compdate}
              />
            </FlexLayout>

            <FlexLayout>
              <LayoutTitle weight={0.1}>요청내용</LayoutTitle>
              <TextBox
                value={modalStore.remarkQnA}
                isMultiline={true}
                minHeight={120}
              />

              <LayoutTitle weight={0.1}>처리내용</LayoutTitle>
              <TextBox
                value={modalStore.compremark}
                isMultiline={true}
                minHeight={120}
              />
            </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => modalStore.closeQnASelector()}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
