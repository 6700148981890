import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e035_03
 * @category 점검결과등록
 */
export default class ResultDetailModel {
  /**
   * 현장
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 거래처
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 회사
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 호기
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 기종
   *
   * **data type** varchar(8)
   */
  readonly evcd: string;

  /**
   * 기종2
   *
   * **data type** varchar(8)
   */
  readonly evcd2: string;

  /**
   * 기종3
   *
   * **data type** varchar(8)
   */
  readonly evlcd: string;

  /**
   * 주기
   *
   * **data type** varchar(8)
   */
  readonly evmon: string;

  /**
   * 대분류
   *
   * **data type** varchar(8)
   */
  readonly gevcd: string;

  /**
   * 중분류
   *
   * **data type** varchar(8)
   */
  readonly gresultcd: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 교체부품
   *
   * **data type** varchar(8)
   */
  readonly part_nm: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 계획월
   *
   * **data type** varchar(8)
   */
  readonly planmon: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 비고2
   *
   * **data type** varchar(8)
   */
  readonly remark2: string;

  /**
   * 비고상태
   *
   * **data type** varchar(8)
   */
  readonly remark_flag: string;

  /**
   * No
   *
   * **data type** varchar(8)
   */
  readonly resultcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly resultdtlnm: string;

  /**
   * No 명칭
   *
   * **data type** varchar(8)
   */
  readonly resultnm: string;

  /**
   *
   * 모름
   * **data type** varchar(8)
   */
  readonly sel_chk_item_cd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly sel_chk_result: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 순번2
   *
   * **data type** varchar(8)
   */
  readonly seq2: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly Gkccnt1: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly Gkccnt2: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly Gremaincnt1: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly Gremaincnt2: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 현장담장자 전화번호
   *
   * **data type** varchar(8)
   */
  readonly actperhp: string;

  /**
   * 현장담당자명
   *
   * **data type** varchar(8)
   */
  readonly actpernm: string;

  /**
   * 첨부파일
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 체크
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(8)
   */
  readonly elno: string;

  /**
   * 이메일주소
   *
   * **data type** varchar(8)
   */
  readonly email: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly evcd_new: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly gosi: string;

  /**
   * 점검자 정보센터ID
   *
   * **data type** varchar(8)
   */
  readonly groupid: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly kccnt0: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly kccnt1: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly kccnt2: string;

  /**
   * 서명
   *
   * **data type** varchar(8)
   */
  readonly kcflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly kclog: string;

  /**
   * 점검자
   *
   * **data type** varchar(8)
   */
  readonly kcperid: string;

  /**
   * 부점검자
   *
   * **data type** varchar(8)
   */
  readonly kcperid2: string;

  /**
   * 점검자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 부점검자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm2: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly kcspnm: string;

  /**
   * 서명
   *
   * **data type** varchar(8)
   */
  readonly ks1flag: string;

  /**
   * 서명
   *
   * **data type** varchar(8)
   */
  readonly ks2flag: string;

  /**
   * legacy
   *
   * **data type** varchar(8)
   */
  readonly nfc: string;

  /**
   * 조속기
   *
   * **data type** varchar(8)
   */
  readonly part_nm1: string;

  /**
   * 로프브레이크
   *
   * **data type** varchar(8)
   */
  readonly part_nm2: string;

  /**
   * 도어잠금장치
   *
   * **data type** varchar(8)
   */
  readonly part_nm3: string;

  /**
   * 비상정지장치
   *
   * **data type** varchar(8)
   */
  readonly part_nm4: string;

  /**
   * 완충기
   *
   * **data type** varchar(8)
   */
  readonly part_nm5: string;

  /**
   * 역구동방지장치
   *
   * **data type** varchar(8)
   */
  readonly part_nm6: string;

  /**
   * 기판(제어판
   *
   * **data type** varchar(8)
   */
  readonly part_nm7: string;

  /**
   * EL 제동기
   *
   * **data type** varchar(8)
   */
  readonly part_nm8: string;

  /**
   * 와이어로프
   *
   * **data type** varchar(8)
   */
  readonly part_nm9: string;

  /**
   * 리미트스위치
   *
   * **data type** varchar(8)
   */
  readonly part_nm10: string;

  /**
   * 럽쳐밸브
   *
   * **data type** varchar(8)
   */
  readonly part_nm11: string;

  /**
   * 스텝
   *
   * **data type** varchar(8)
   */
  readonly part_nm12: string;

  /**
   * 스텝체인
   *
   * **data type** varchar(8)
   */
  readonly part_nm13: string;

  /**
   * ES 제동기
   *
   * **data type** varchar(8)
   */
  readonly part_nm14: string;

  /**
   * 점검자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 부점검자
   *
   * **data type** varchar(8)
   */
  readonly perid2: string;

  /**
   * 점검자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 부점검자명
   *
   * **data type** varchar(8)
   */
  readonly pernm2: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly plangubun: string;

  /**
   * 점검시작시간
   *
   * **data type** varchar(8)
   */
  readonly plantime: string;

  /**
   * 점검완료시간
   *
   * **data type** varchar(8)
   */
  readonly plantime2: string;

  /**
   * 결과
   *
   * **data type** varchar(8)
   */
  readonly result: string;

  /**
   * 점검일
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 서명
   *
   * **data type** varchar(8)
   */
  readonly s1flag: string;

  /**
   * 정보센터 전송여부
   *
   * **data type** varchar(8)
   */
  readonly sendflag: string;

  /**
   * 회사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.cltcd = data.cltcd || '';
    this.custcd = data.custcd || '';
    this.equpcd = data.equpcd || '';
    this.evcd = data.evcd || '';
    this.evcd2 = data.evcd2 || '';
    this.evlcd = data.evlcd || '';
    this.evmon = data.evmon || '';
    this.gevcd = data.gevcd || '';
    this.gresultcd = data.gresultcd || '';
    this.gubun = data.gubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.part_nm = data.part_nm || '';
    this.plandate = data.plandate || '';
    this.planmon = data.planmon || '';
    this.remark = data.remark || '';
    this.remark2 = data.remark2 || '';
    this.remark_flag = data.remark_flag || '';
    this.resultcd = data.resultcd || '';
    this.resultdtlnm = data.resultdtlnm || '';
    this.resultnm = data.resultnm || '';
    this.sel_chk_item_cd = data.sel_chk_item_cd || '';
    this.sel_chk_result = data.sel_chk_result || '';
    this.seq = data.seq || '';
    this.seq2 = data.seq2 || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || 'A';
    this.Gkccnt1 = data.Gkccnt1 || '';
    this.Gkccnt2 = data.Gkccnt2 || '';
    this.Gremaincnt1 = data.Gremaincnt1 || '';
    this.Gremaincnt2 = data.Gremaincnt2 || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.actperhp = data.actperhp || '';
    this.actpernm = data.actpernm || '';
    this.attcnt = data.attcnt || '';
    this.chk = data.chk || '0';
    this.cltcd = data.cltcd || '';
    this.custcd = data.custcd || '';
    this.elno = data.elno || '';
    this.email = data.email || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.evcd = data.evcd || '';
    this.evcd_new = data.evcd_new || '';
    this.gosi = data.gosi || '0';
    this.groupid = data.groupid || '';
    this.gubun = data.gubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.kccnt0 = data.kccnt0 || '';
    this.kccnt1 = data.kccnt1 || '';
    this.kccnt2 = data.kccnt2 || '';
    this.kcflag = data.kcflag || '0';
    this.kclog = data.kclog || '';
    this.kcperid = data.kcperid || '';
    this.kcperid2 = data.kcperid2 || '';
    this.kcpernm = data.kcpernm || '';
    this.kcpernm2 = data.kcpernm2 || '';
    this.kcspnm = data.kcspnm || '';
    this.ks1flag = data.ks1flag || '';
    this.ks2flag = data.ks2flag || '';
    this.nfc = data.nfc || '';
    this.part_nm1 = data.part_nm1 || '';
    this.part_nm2 = data.part_nm2 || '';
    this.part_nm3 = data.part_nm3 || '';
    this.part_nm4 = data.part_nm4 || '';
    this.part_nm5 = data.part_nm5 || '';
    this.part_nm6 = data.part_nm6 || '';
    this.part_nm7 = data.part_nm7 || '';
    this.part_nm8 = data.part_nm8 || '';
    this.part_nm9 = data.part_nm9 || '';
    this.part_nm10 = data.part_nm10 || '';
    this.part_nm11 = data.part_nm11 || '';
    this.part_nm12 = data.part_nm12 || '';
    this.part_nm13 = data.part_nm13 || '';
    this.part_nm14 = data.part_nm14 || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
    this.pernm = data.pernm || '';
    this.pernm2 = data.pernm2 || '';
    this.plandate = data.plandate || '';
    this.plangubun = data.plangubun || '';
    this.planmon = data.planmon || '';
    this.plantime = data.plantime || '';
    this.plantime2 = data.plantime2 || '';
    this.remark = data.remark || '';
    this.result = data.result || '';
    this.rptdate = data.rptdate || '';
    this.s1flag = data.s1flag || '';
    this.sendflag = data.sendflag || '0';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '1';
    this.wkactcd = data.wkactcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
