import { GridLayoutHeader } from '../../../../../constants';
import { FalseModel } from '../models';
import { SelfCertificate } from '../SelfCertificate';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';


// 자격증 소지자
const False: GridLayoutHeader<FalseModel, SelfCertificate>[] = [
  {
    id: 'spjangcd',
    text: '사업장',
    width: 10,
    render: (x, scope) => {
      const find = scope?.state.spjangcds?.filter((y) => y.spjangcd === x.spjangcd);
      const spjangnm = find?.length ? find[0].spjangnm : x.spjangcd;
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {spjangnm}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
       총계
      </FlexLayout>
    ),
  },
  {
    id: 'perid',
    text: '코드',
    width: 5,
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 5,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.Falses?.length}명
      </FlexLayout>
    ),
  },
  {
    id: 'licence',
    text: '자격종류',
    width: 20,
  },
  {
    id: 'licencewhere',
    text: '발생기관',
    width: 15,
  },
  {
    id: 'licencenum',
    text: '자격증번호',
    width: 15,
  },
  {
    id: 'entdate',
    text: '입사일',
    width: 8,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.entdate}
      />
    ),
  },
  {
    id: 'gooddate',
    text: '자격취득예정일',
    width: 10,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.gooddate}
      />
    ),
  },
];


export default False;
