import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import WorkerModel from './models/WorkerModel';
import { WorkerTemplate } from './Worker.template';
import { PageComponent } from '../../../../utils';
import {
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  FileReader,
  FileSelector,
} from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { GridLayout } from '../../../../components';

interface SampleState {
  searchQuery: string;
  data: Array<WorkerModel>;
  content: WorkerModel;

  // Image
  img?: ArrayBuffer;
  percentImage?: number;

  // Tab - contract
  dataContract: Array<any>;

  // Tab - payments
  dataPayments: Array<any>;

  // ID modal
  isVisibleIDModal: boolean;
  searchQueryID: string;
  dataID: Array<any>;

  // Total
  mijamt_tot: string;
  sndamt_tot: string;
  iamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export class Worker extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityID?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  gridID: React.RefObject<GridLayout> = React.createRef();

  gridFocus?: WorkerModel;

  gridFocusIndex: number = 0;

  tabIndex: number = 0;

  gridIDFocus?: any;

  acccds: Array<any> = [];

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      data: [],
      content: new WorkerModel(),

      dataContract: [],
      dataPayments: [],

      isVisibleIDModal: false,
      searchQueryID: '',
      dataID: [],

      mijamt_tot: '0',
      sndamt_tot: '0',
      iamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ac001');

    if (!data) return;
    this.acccds = data?.items || [];

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new WorkerModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });
        this.infinity?.retrieveAll();

        if (this.state.data.length > 0) {
          await this.grid.current?.setFocus(0);
          await this.onGridRowClicked(this.state.data[0], 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    const index = await this.infinity?.retrieveTo('id', this.state.content?.id, type, true) || 0;
    const data = await this.infinity?.box;
    this.SS({
      mijamt_tot: data?.mijamt_tot || '0',
      sndamt_tot: data?.sndamt_tot || '0',
      iamt_tot: data?.iamt_tot || '0',
    });
    if (!autoLoad) return;
    this.state.data.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST, false);
    }
    const data = await api.new();

    if (data) {
      const one = new WorkerModel(data, true);

      await this.SS({
        content: one,
        data: [one, ...this.state.data],
      });

      await this.grid.current?.setFocus(0);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save(
      this.state.content,
      this.state.content.isNew,
    )) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.gridFocus?.pernm}`;

    if (await api.delete(text, this.state.content)) {
      this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }


  @action
  async headerItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...content,
      [key]: value,
      itemname: key,
      data: value,
      new: content.isNew ? '1' : '0',
    });

    const one = new WorkerModel({ ...content, ...result }, content.isNew);
    await this.SS({ content: one });
  }


  @action
  async onGridRowClicked(item: WorkerModel | undefined, index: number) {
    this.gridFocus = item;
    this.gridFocusIndex = index;

    if (item?.isNew) return;

    // Init
    this.SS({
      content: new WorkerModel(),
      dataContract: [],
      dataPayments: [],
    });

    if (!item) {
      return;
    }

    this.imageRequest();

    const { actionStore: api } = this.props;
    const data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_1_RowFocuschanged`, {
      id: item?.id,
    });

    switch (this.tabIndex) {
      case 0:
        this.SS({ content: new WorkerModel(data) });
        break;

      case 1:
        this.SS({ dataContract: data?.items || [] });
        break;

      case 2:
        this.SS({
          dataPayments: data?.items || [],
          mijamt_tot: data?.mijamt_tot || '0',
          sndamt_tot: data?.sndamt_tot || '0',
          iamt_tot: data?.iamt_tot || '0',
        });
        break;
    }
  }

  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    this.onGridRowClicked(this.gridFocus, this.gridFocusIndex);
  }


  // Image
  @action
  async imageRequest() {
    const { actionStore: api, publicStore } = this.props;
    const blob = await api.fxBinary(
      'pic_retrieve',
      {
        spjangcd: publicStore.user.spjangcd,
        id: this.gridFocus?.id,
      },
    );

    this.setState({
      img: blob,
    });
  }


  @action
  async imageDelete() {
    const { actionStore: api, publicStore } = this.props;
    if (await api.fxExec(
      'pic_delete',
      {
        spjangcd: publicStore.user.spjangcd,
        id: this.gridFocus?.id,
      },
    )) {
      this.imageRequest();
      ConfirmSuccess.show('저장', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect() {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      this.imageUpload(files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async imageUpload(filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      percentImage: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          percentImage: percent,
        });
      });

      if (await api.fxExec(
        'pic_save',
        {
          spjangcd: publicStore.user.spjangcd,
          id: this.gridFocus?.id,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        this.imageRequest();
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        percentImage: 0,
      }), 1000);
    });
  }


  @action
  async onIDRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.infinityID = new InfinityRetrieve(
      {
        sub: 'w_popup_share_xusers',
        as_nm: this.state.searchQueryID,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.SS({
          dataID: [
            ...this.state.dataID,
            ...items,
          ],
        });
      },
      async () => {
        await this.SS({
          dataID: [],
        });

        await this.infinityID?.retrieve();
        if (this.state.dataID.length) {
          this.gridID.current?.setFocus(0);
        }
      },
    );

    await this.SS({
      dataID: [],
    });

    await this.infinityID?.retrieve();
    if (this.state.dataID.length) {
      this.gridID.current?.setFocus(0);
    }
  }

  onGridIDRowClicked(item: any) {
    this.gridIDFocus = item;
  }

  @action
  async openModalID(search?: string) {
    this.gridIDFocus = undefined;
    await this.SS({
      isVisibleIDModal: true,
      dataID: [],
      searchQueryID: search || '',
    });

    this.onIDRetrieveEvent();
  }

  @action
  onClickModalIDSuccess() {
    if (!ConfirmWarning.assert(this.gridIDFocus, '오류', '먼저 선택해주세요')) return;

    this.setState({
      content: new WorkerModel({
        ...this.state.content,
        id: this.gridIDFocus.id,
        pernm: this.gridIDFocus.pernm,
        email: this.gridIDFocus.email,
        hpnum: this.gridIDFocus.hpnum,
      }, this.state.content.isNew),
      isVisibleIDModal: false,
    });
  }


  render() {
    return <WorkerTemplate
      scope={this}
    />;
  }
}
