import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { BreakPartModel } from '../models';
import { BreakAnalysisOne } from '../BreakAnalysisOne';
import { TextBox } from '../../../../../components';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';

// 고장부위별
const Part: GridLayoutHeader<BreakPartModel, BreakAnalysisOne>[] = [
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    group: true,
    sum: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 100,
    sum: (x, data) => {
      const sumEqupnm = Sum.groupLength(data, 'equpnm', x, ['actnm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumEqupnm)} 대
        </FlexLayout>
      );
    },
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.equpnm}
      />
    ),
  },
  {
    id: 'gregnm',
    text: '고장부위',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.greginm}
      </FlexLayout>
    ),
  },
  {
    id: 'reginm',
    text: '고장부위상세',
    width: 150,
    render: (x) => (
      <TextBox
        textAlign="left"
        value={x.reginm}
      />
    ),
  },
  {
    id: 'tot',
    text: '고장건수',
    width: 50,
    sum: (x, data) => {
      const sumTot = Sum.groupLength(data, 'tot', x, ['actnm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumTot)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.tot_tot}건
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.tot}
      />
    ),
  },
];

export default Part;
