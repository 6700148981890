import {
  FlexLayout,
  CircleBadge,
} from '../../../../../components';
import { GridLayoutHeader } from '../../../../../constants';
import { EnrollmentCheckModel } from '../models';
import { Enrollment } from '../Enrollment';
import { Date8 } from '../../../../../utils/time';

export const results = [
  { value: '002', remark: '불합격' },
  { value: '003', remark: '조건부' },
  { value: '010', remark: '0' },
  { value: '011', remark: '조건부합격(1년)' },
  { value: '012', remark: '2개월조건부조건후불합격' },
  { value: '001', remark: '합격' },
  { value: '007', remark: '보완후합격' },
  { value: '008', remark: '현장시정조치합격' },
  { value: '009', remark: '보완' },
  { value: '013', remark: '보완후불합격' },
  { value: '014', remark: '승인' },
  { value: '004', remark: '조건후합격' },
  { value: '005', remark: '조건후불합격' },
  { value: '006', remark: '조건부합격' },
];

export const gubuns3 = [
  { value: '001', remark: '정기' },
  { value: '002', remark: '정밀' },
  { value: '003', remark: '자체' },
  { value: '004', remark: '완성' },
  { value: '005', remark: '수시' },
  { value: '006', remark: '검사' },
  { value: '007', remark: '설치' },
  { value: '008', remark: '0' },
];

const states = [
  { value: '1', remark: '계획' },
  { value: '2', remark: '검사' },
  { value: '3', remark: '조치' },
  { value: '0', remark: '만료' },
];

// 검사이력정보
const CheckDetail: GridLayoutHeader<EnrollmentCheckModel, Enrollment>[] = [
  {
    id: 'seq',
    text: '순서',
    width: 13,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '상태',
    width: 15,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
        style={{ color: x.state === '2' ? 'var(--color-blue)' : 'var(--color-gray)' }}
      >
        {states?.filter((y) => y.value === x.state)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '계획일자',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.plandate)}
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm',
    text: '검사기관',
    width: 45,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="cneter"
      >
        {x.kcpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '검사구분',
    width: 20,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {gubuns3?.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'kcdate',
    text: '검사일자',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.kcdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '운행시작일',
    width: 25,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.stdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '운행종료일',
    width: 25,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.enddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'result',
    text: '합격유무(결과)',
    width: 35,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        <FlexLayout
          align='center'
          justify='center'
          style={{
            color: x.result === '002' || x.result === '005' ? 'var(--color-red)' : 'var(--color-blue)',
          }}
        >
          {results?.filter((y) => y.value === x.result)[0]?.remark}
          <CircleBadge>
            {Number(x.failcnt) > 0 && <span
              style={{ backgroundColor: 'var(--color-red)' }}
            >
                        {Number(x.failcnt) > 0 ? x.failcnt : ''}
                      </span>}
          </CircleBadge>
        </FlexLayout>
      </FlexLayout>
    ),
  },
];

export default CheckDetail;
