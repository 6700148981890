import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { SiteModel } from '../models';
import { RepairSite } from '../RepairSite';
import { Button, FormatTextBox, TelnumTextBox } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];
// 거래처별
const Site: GridLayoutHeader<SiteModel, RepairSite>[] = [
  {
    id: 'spjangnm',
    text: '사업장명',
    width: 90,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.spjangnm}
      </FlexLayout>
    ),
  },
  {
    id: 'bosunm',
    text: '보수업체명',
    width: 130,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.bosunm}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactnm',
    text: '회사구분',
    width: 60,
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 80,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.total} 건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '관리대수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot} 대
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '담당부서',
    width: 85,
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 85,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
       평균보수료:
      </FlexLayout>
    ),
  },
  {
    id: 'contpernm',
    text: '계약자명',
    width: 85,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.averamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.contpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm3',
    text: '기록',
    width: 50,
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <Button
          style={{
            height: '20px',
            width: '40px',
            marginTop: 10,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope?.historyPopupModal(true)}
        >
          조회
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'deljugi',
    text: '청구주기',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope?.state.deljugis?.filter((y) => y.com_code === x.deljugi)[0]?.com_cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'daeamt',
    text: '대당금액',
    width: 110,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.equpamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(Math.round(Number(x.monamt) / Number(x.qty)))}
      </FlexLayout>
    ),
  },
  {
    id: 'monamt',
    text: '월청구금액(평균)',
    width: 110,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.monamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(Math.round(Number(x.monamt)))}
      </FlexLayout>
    ),
  },
  {
    id: 'nowamt',
    text: '당월청구금액',
    width: 110,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.nowamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.nowamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '계약금액',
    width: 110,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'picflag',
    text: '계약서',
    width: 60,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.picflag === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
      >
        {x.picflag === '0' ? '없음' : '있음'}
      </FlexLayout>
    ),
  },
  {
    id: 'cltcd',
    text: '코드',
    width: 60,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.cltcd}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 130,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.bildyds?.filter((y) => y.buildcd === x.bildyd)[0]?.buildnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {contgs.filter((y) => y.value === x.contg)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약분류',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'mgubun',
    text: '유무상',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.mgubun === '1' ? '유' : '무'}
      </FlexLayout>
    ),
  },
  {
    id: 'recedate',
    text: '접수일자',
    width: 110,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FormatTextBox
          format="####-##-##"
          textAlign="center"
          value={x.recedate}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'contdate',
    text: '계약일자',
    width: 110,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FormatTextBox
          format="####-##-##"
          textAlign="center"
          value={x.contdate}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'yy',
    text: '년',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.yy}
      </FlexLayout>
    ),
  },
  {
    id: 'month',
    text: '개월',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.month}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.stdate}
      />
    ),
  },
  {
    id: 'enddate',
    text: '종료일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.enddate}
      />
    ),
  },
  {
    id: 'yy_total',
    text: '총년',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.yy_total}
      </FlexLayout>
    ),
  },
  {
    id: 'month_total',
    text: '총개월',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.month_total}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate_total',
    text: '최초시작일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.stdate_total}
      />
    ),
  },
  {
    id: 'taxgubun',
    text: '세금구분',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.taxgubuns?.filter((y) => y.com_code === x.taxgubun)[0]?.com_cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'taxflag',
    text: '증빙구분',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.taxflag === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
  {
    id: 'addyn',
    text: '부가세포함',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.addyn === '1' ? '포함' : '별도'}
      </FlexLayout>
    ),
  },
  {
    id: 'dedate',
    text: '청구서발행일',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.dedate}
      </FlexLayout>
    ),
  },
  {
    id: 'jirogubun',
    text: '지로발행',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.jirogubun === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
  {
    id: 'tel',
    text: '전화번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.tel}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'hp',
    text: '휴대폰번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.hp}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'zipcode',
    text: '우편번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.zipcode}
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'address2',
    text: '상세주소',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address2}
      </FlexLayout>
    ),
  },
];

export default Site;
