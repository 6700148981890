/**
 * 모델
 * @window w_tb_e101w_07
 * @category 전월대비 계약변동내역
 */

export class PreviousMonthModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 보수료
   *
   * **data type** varchar(8)
   */
  readonly amt: string;

  /**
   * 전보수료
   *
   * **data type** varchar(8)
   */
  readonly beamt: string;

  /**
   * 전분류
   *
   * **data type** varchar(8)
   */
  readonly becontg: string;

  /**
   * 전분류 이름
   *
   * **data type** varchar(8)
   */
  readonly becontgnm: string;

  /**
   * 전계약구분
   *
   * **data type** varchar(8)
   */
  readonly becontgubun: string;

  /**
   * 전계약구분 이름
   *
   * **data type** varchar(8)
   */
  readonly becontgubunnm: string;

  /**
   * 전담당자 코드
   *
   * **data type** varchar(8)
   */
  readonly beperid: string;

  /**
   * 전담당자 이름
   *
   * **data type** varchar(8)
   */
  readonly bepernm: string;

  /**
   * 전 수량
   *
   * **data type** varchar(8)
   */
  readonly beqty: string;

  /**
   * 금액변동
   *
   * **data type** varchar(8)
   */
  readonly chamt: string;

  /**
   * 금액변동 화살표
   *
   * **data type** varchar(8)
   */
  readonly chamt_arrow: string;

  /**
   * 변동유무
   *
   * **data type** varchar(8)
   */
  readonly chflag: string;

  /**
   * 대수 변동
   *
   * **data type** varchar(8)
   */
  readonly chqty: string;

  /**
   * 대수 변동 화살표
   *
   * **data type** varchar(8)
   */
  readonly chqty_arrow: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;

  /**
   * 분류
   *
   * **data type** varchar(8)
   */
  readonly contg: string;

  /**
   * 분류명
   *
   * **data type** varchar(8)
   */
  readonly contgnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contgubun: string;

  /**
   * 계약구분명
   *
   * **data type** varchar(8)
   */
  readonly contgubunnm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly kukcd: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 대수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.amt = data.amt || '';
    this.beamt = data.beamt || '';
    this.becontg = data.becontg || '';
    this.becontgnm = data.becontgnm || '';
    this.becontgubun = data.becontgubun || '';
    this.becontgubunnm = data.becontgubunnm || '';
    this.beperid = data.beperid || '';
    this.bepernm = data.bepernm || '';
    this.beqty = data.beqty || '';
    this.chamt = data.chamt || '';
    this.chamt_arrow = data.chamt_arrow || '';
    this.chflag = data.chflag || '';
    this.chqty = data.chqty || '';
    this.chqty_arrow = data.chqty_arrow || '';
    this.contflag = data.contflag || '';
    this.contg = data.contg || '';
    this.contgnm = data.contgnm || '';
    this.contgubun = data.contgubun || '';
    this.contgubunnm = data.contgubunnm || '';
    this.custcd = data.custcd || '';
    this.kukcd = data.kukcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.qty = data.qty || '';
    this.spjangcd = data.spjangcd || '';
  }
}
