import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { CancelModel } from '../models';
import { MonthWork } from '../MonthWork';
import { CheckBox } from '../../../../../components/forms/CheckBox';

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const clss = [
  { value: '001', remark: '계약만기' },
  { value: '002', remark: '사업종료' },
  { value: '003', remark: '서비스불만' },
  { value: '004', remark: '이관' },
  { value: '005', remark: '고객의 일방적 해지' },
  { value: '006', remark: '사용저조' },
  { value: '007', remark: '기타' },
];

// 보수해지현장
const Cancel: GridLayoutHeader<CancelModel, MonthWork>[] = [
  {
    id: 'divinm',
    text: '부서명',
    width: 6,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 6,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total} 건
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 15,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 4,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}대
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '구분',
    width: 8,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'cls',
    text: '해지사유',
    width: 8,
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {clss.filter((y) => y.value === x.cls)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'clsremark',
    text: '해지사유상세',
    width: 21,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.clsremark}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '보수료',
    width: 7,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'appflag',
    text: '보고서',
    width: 5,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <CheckBox
          value={x.appflag === '1'}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'tasacltnm',
    text: '타사업체',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.tasacltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'tasauamt',
    text: '타사보수료',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.tasauamt)}
      </FlexLayout>
    ),
  },
];

export default Cancel;
