export default class Date8 {
  static make(date?: Date): string {
    const now = date || new Date();
    const month = now.getMonth() + 1;
    const dt = now.getDate();
    return `${now.getFullYear()}${month < 10 ? `0${month}` : month}${
      dt < 10 ? `0${dt}` : dt
    }`;
  }

  static withDash(date?: Date | string): string {
    const str = typeof date === 'string' ? date : Date8.make(date);
    return `${str.substr(0, 4)}-${str.substr(4, 2)}-${str.substr(6, 2)}`;
  }

  static withDashMon(date?: Date | string): string {
    const str = typeof date === 'string' ? date : Date8.make(date);
    return `${str.substr(0, 4)}-${str.substr(4, 2)}`;
  }

  static withKor(date?: Date | string): string {
    const str = typeof date === 'string' ? date : Date8.make(date);
    return `${str.substr(0, 4)}년 ${str.substr(4, 2)}월 ${str.substr(6, 2)}일`;
  }
}
