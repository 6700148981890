import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  ComboBoxModel,
  ComboBox,
  GridLayout,
  SearchLayout,
  TabView, DateTextBox, LayoutTitle, TableLayout, FormatTextBox, CheckBox, LabelBox, SubHeader, Button, ModalView,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { RepairSite, RepairSiteTabDataStateNames, RepairSiteTabFocusedStateNames } from './RepairSite';
import { SearchBinding } from '../../../../models';
import { Date6 } from '../../../../utils/time';
import { EndContractTabFocusedStateNames } from '../EndContract/EndContract';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_e101w
 * @category 유지보수계약현황
 */
export const RepairSiteTemplate: React.FC<TemplateProps<RepairSite>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={270} style={{ marginLeft: 14 }}>
          <span style={{ marginRight: 9 }}>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout >
        <FlexLayout size={266} style={{ marginLeft: 13 }}>
          <span style={{ marginRight: 4 }}>현장명</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 보수업체명으로 검색하세요"
            value={scope.state.cltcd}
            onChange={(value) => scope.setState({ cltcd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={370} style={{ marginLeft: 30 }}>
          <span style={{ color: 'var(--color-red)', marginRight: 30 }}>회사구분</span>
          <TextBox
            weight={1.4}
            value={scope.state.wkactcd === '%' ? '' : scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.wkactnm}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={250} style={{ marginLeft: 7 }}>
          <span>증빙구분</span>
          <ComboBox
            value={scope.state.taxflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '발행' },
              { value: '0', remark: '미발행' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ taxflag: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout style={{ marginLeft: 22 }} size={354}>
          <span style={{ marginRight: 32 }}>계약분류</span>
          <ComboBox
            value={scope.state.contg}
            data={scope.state.contgs?.map((y) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => setData({ contg: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={284}>
          <span>건물용도</span>
          <ComboBox
            value={scope.state.bildyd}
            data={scope.state.bildyds?.map((y) => new ComboBoxModel(y.buildcd, y.buildnm))}
            onSelect={(option) => setData({ bildyd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={280}>
          <span style={{ marginRight: 4 }}>
            계약분류
          </span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ gubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={256}>
          <span style={{
            marginLeft: 20,
          }}>
            계약기간(월)</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.stmon}
            onChange={(v) => scope.setState({ stmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon?.substr(0, 4)}-${scope.state.stmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ stmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  const date = new Date(`${scope.state.stmon?.substr(0, 4)}-${scope.state.stmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ stmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={168}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.endmon}
            onChange={(v) => scope.setState({ endmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon?.substr(0, 4)}-${scope.state.endmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ endmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon?.substr(0, 4)}-${scope.state.endmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ endmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={232}>
          <span style={{
            marginLeft: 0,
          }}>
            계약구분
          </span>
          <ComboBox
            value={scope.state.contgubun}
            data={scope.state.contgubuns?.map((y) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => setData({ bildyd: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={194} style={{ marginLeft: 22 }}>
          <span style={{ marginRight: 34 }}>청구주기</span>
          <ComboBox
            value={scope.state.deljugi}
            data={[
              { value: '%', remark: '전체' },
              { value: '01', remark: '월' },
              { value: '02', remark: '분기' },
              { value: '03', remark: '반기' },
              { value: '04', remark: '년' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ deljugi: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={160}>
          <span style={{ marginRight: 14 }}>유무상</span>
          <ComboBox
            value={scope.state.mgubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '유상' },
              { value: '0', remark: '무상' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ mgubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={256} style={{ marginLeft: 28 }}>
          <span
            style={{
              color: 'var(--color-red)',
              marginRight: 8,
            }}>
            부서
          </span>
          <TextBox
            weight={1.4}
            textAlign="center"
            value={scope.state.divicd === '%' ? '' : scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout size={268} style={{ marginLeft: 12 }}>
          <span style={{ color: 'var(--color-red)', marginRight: 4 }}>담당자</span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm },
                  () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid === '%' ? '' : scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={354} style={{ marginLeft: 46 }}>
          <span style={{ color: 'var(--color-red)', marginRight: 28 }}>계약자</span>
          <TextBox
            weight={1.4}
            value={scope.state.contpernm}
            textAlign="center"
            onChange={(value) => setData({ contpernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ contperid: item.cd, contpernm: item.cdnm }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.contperid === '%' ? '' : scope.state.contperid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={220} style={{ marginLeft: 36 }}>
          <span>보류</span>
          <ComboBox
            value={scope.state.boryu}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '있음' },
              { value: '0', remark: '없음' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ boryu: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={240}>
          <span>보류기간(월)</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.boryustmon}
            onChange={(v) => scope.setState({ boryustmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.boryustmon?.substr(0, 4)}
                  -${scope.state.boryustmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ boryustmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.boryustmon?.substr(0, 4)}
                  -${scope.state.boryustmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ boryustmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={160}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.boryuendmon}
            onChange={(v) => scope.setState({ boryuendmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.boryuendmon?.substr(0, 4)}-
                  ${scope.state.boryuendmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ boryuendmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.boryuendmon?.substr(0, 4)}
                  -${scope.state.boryuendmon?.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ boryuendmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          // @ts-ignore
          ref={scope.grid}
          scope={scope}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[RepairSiteTabDataStateNames[scope.tabIndex]] || []}
          onRowClick={(item) => setData({
            [RepairSiteTabFocusedStateNames[scope.tabIndex]]: item,
          })}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.historyModal}
        onClose={() => scope.historyPopupModal(false)}
        width={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle weight={1}>
            계약내용기록
          </LayoutTitle>
          <FlexLayout weight={14}>
            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
                <FlexLayout>
                  {/* @ts-ignore */}
                  {scope.state[EndContractTabFocusedStateNames[scope.tabIndex]]?.actnm} 계약내역
                </FlexLayout>
              </FlexLayout>
              <FlexLayout>
                <TableLayout
                  ref={scope.tableHistory}
                  header={[
                    {
                      id: 'seq',
                      text: '순서',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.seq}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'perid',
                      text: '작성자',
                      width: 8,
                      color: 'var(--color-red)',
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <TextBox
                            value={x.perid}
                            textAlign="center"
                            onChange={(value) => rowUpdate({ perid: value })}
                            bindSearchModal={new SearchBinding(
                              scope.props.modalStore,
                              'TB_JA001',
                              {},
                              true,
                              (item) => {
                                rowUpdate({
                                  perid: item.cd,
                                  pernm: item.cdnm,
                                });
                              },
                            )}
                            isDisabledTrackingStateChange={true}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'pernm',
                      text: '작성자명',
                      width: 8,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.pernm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'hdate',
                      text: '기록일자',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.hdate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'wakedate',
                      text: '알림만료일',
                      width: 10,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <FormatTextBox
                            textAlign="center"
                            format="####-##-##"
                            value={x.wakedate}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'title',
                      text: '제목',
                      width: 40,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          <TextBox
                            value={x.title}
                            textAlign="left"
                            onChange={(value) => rowUpdate({ title: value })}
                          />
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'okflag',
                      text: '답변확인',
                      width: 6,
                      render: (x, rowUpdate) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          <CheckBox
                            noMargin
                            value={x.okflag === '1'}
                            onChange={(value) => {
                              rowUpdate({ okflag: value ? '1' : '0' });
                            }}
                          />
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.historyData || []}
                  infinityHandler={scope.infinityHistory}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onChange={(rows2, updatedRows2) => {
                    scope.onUpdatedRows2(rows2, updatedRows2);
                  }}
                  onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout>
          <LabelBox title="계약내용">
            <TextBox
              value={scope.state.remark}
              onChange={(value) => setData({ remark: value })}
              isMultiline={true}
            />
          </LabelBox>
        </FlexLayout>
        <SubHeader>
          <FlexLayout justify='end'>
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={() => scope.onNewHistoryEvent()}
            >
              신규
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              onClick={() => scope.onHistorySaveEvent()}
            >
              저장
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.historyDelete()}
            >
              삭제
            </Button>
            <Button
              style={{ height: '25px', width: '80px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.historyPopupModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  );
};
