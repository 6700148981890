import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640_pa105
 * @category 정기매입자료생성-유류비
 */
export class DollarAverageModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 삭제
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 사용일자
   *
   * **data type** datetime
   */
  usedatetime: string;

  /**
   * 사용일자
   *
   * **data type** varchar(8)
   */
  usedate: string;

  /**
   * 사용자
   *
   * **data type** varchar(13)
   */
  perid: string;

  /**
   * 사용자명
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 차량번호
   *
   * **data type** varchar(20)
   */
  carnum: string;

  /**
   * 차명
   *
   * **data type** varchar(255)
   */
  carname: string;

  /**
   * 유종
   *
   * **data type** varchar(3)
   */
  kmflag: string;

  /**
   * 유종명
   *
   * **data type** varchar(100)
   */
  kmflagnm: string;

  /**
   * 이동거리(km)
   *
   * **data type** number
   */
  qty: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uamt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 지급
   *
   * **data type** varchar(1)
   */
  mijflag: string;

  /**
   * 매입구분
   *
   * **data type** varchar(1)
   */
  mijflag2: string;

  /**
   * 매입일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 매입번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  sndamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.chk = data.chk || '';
    this.spjangcd = data.spjangcd || '';
    this.sndamt = data.sndamt || '';
    this.custcd = data.custcd || '';
    this.mon = data.mon || '';
    this.seq = data.seq || '';
    this.usedatetime = data.usedatetime || '';
    this.usedate = data.usedate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.carnum = data.carnum || '';
    this.carname = data.carname || '';
    this.kmflag = data.kmflag || '';
    this.kmflagnm = data.kmflagnm || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.tamt = data.tamt || '';
    this.samt = data.samt || '';
    this.remark = data.remark || '';
    this.mijflag = data.mijflag || '';
    this.mijflag2 = data.mijflag2 || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
