import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
// @ts-ignore
import { CustomerGroup } from './CustomerGroup';

/**
 * 화면
 * @window w_tb_e901
 * @category 고객그룹등록
 */
export const CustomerGroupTemplate: React.FC<TemplateProps<CustomerGroup>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'groupcd',
          text: '코드',
          width: 10,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.groupcd}
              textAlign="center"
              readonly={x.newCount !== '1'}
              transparent={true}
              onChange={(value) => rowUpdate({ groupcd: value })} />
          ),
        },
        {
          id: 'groupnm',
          text: '그룹분류명',
          width: 35,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.groupnm}
              onChange={(value) => rowUpdate({ groupnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 10,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 45,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
