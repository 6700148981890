import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import printJS from 'print-js';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
  TableLayoutHeader,
} from '../../../../constants';
import {
  BuyModel,
  DetailModel,
  MainModel,
} from './models';
import { TabModel } from '../../../../models/component';
import {
  TableLayout,
  GridLayout,
} from '../../../../components';
import { BuyRegisterTemplate } from './BuyRegister.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderBuy,
  TabHeaderDetail,
} from './tabs';
import {
  Confirm,
  ConfirmDelete,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { FileSelector } from '../../../../utils/file';
import { PageComponent } from '../../../../utils/layout';
import { ProjectModel } from './models/Project.model';
import { Format } from '../../../../utils/string';

const projectGubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

export enum BtnGubun {
  b_projnoadd,
  b_artcd,
  b_acccd,
  b_bigo,
  b_proof,
  b_projno,
  b_mijdate,
  b_wkgubun,
}

export enum ItemChange {
  b_projnoadd,
  cancleflag,
  amtflag,
  etflag,
  osflag,
  gubun,
  billkind,
  mijdate,
  pname,
  qty,
  uamt,
  samt,
  cltcd,
  cltcd_new,
  artcd,
}

export enum BuyRegisterTabId {
  Buy,
  Detail
}

export const BuyRegisterTabTitles = [
  '매입상세',
  '거래명세표',
];

export const BuyRegisterTabModels = [
  BuyModel,
  DetailModel,
];

export const BuyRegisterTabDataStateNames = [
  'buys',
  'details',
];

export const BuyRegisterTabUpdatesStateNames = [
  'buysUpdates',
  'detailsUpdates',
];

export const BuyRegisterTabFocusedStateNames = [
  'buysFocused',
  'detailsFocused',
];

export interface BuyRegisterState {
  // 검색조건
  stdate: string;
  enddate: string;
  searchQuery: string;
  searchGubun?: string;
  gubun?: Array<any>;
  resuchk: string;
  taxgubuns?: Array<any>;
  wkgubuns?: Array<any>;

  // data
  focusedTab?: TabModel;
  buyList: Array<MainModel>;
  data: MainModel;
  lastNewData: MainModel;
  focused?: MainModel;
  focusIndex: number;
  sndamt?: number;
  isNew: string;
  newChk?: string;
  nowData: string;
  misdateChk: string;
  mijdate?: string;
  focusState?: string;

  // 프로젝트팝업
  projectFocused?: ProjectModel;
  projectData: Array<ProjectModel>;
  ProjectModal?: boolean;

  // 매입상세
  buys: Array<BuyModel>;
  buysUpdates?: Array<BuyModel>;
  buysFocused: BuyModel;
  projectActcd: string;
  projectActnm: string;

  // 거래명세표
  details: Array<DetailModel>;
  detailsUpdates?: Array<DetailModel>;
  detailsFocused: DetailModel
  dealImg?: string;

  // 외주인건비 팝업
  outsourceModal: boolean;
  outsourceCheck: boolean;
  outsourceDate: string;
  popupSearchQuery: string;
  outsourceFocused?: MainModel;
  outsourceData: Array<MainModel>;

  // 발주서 팝업
  printorderModal: boolean;
  printorderSdate: string;
  printorderEdate: string;
  printorderSearch: string;
  printorderCheck: boolean;
  printorderDate: string;
  printorderFocused?: MainModel;
  printorderData: Array<MainModel>;
  checkData: Array<any>;
  printorderFocusIndex: number;

  // 매입세금계산서 팝업
  choice: string | number;
  buyModal: boolean;
  buyModal2: boolean;
  buyModal3: boolean;
  buyImg?: string;

  // 지출결의서 팝업
  disbursementModal: boolean;
  disbursementModal2: boolean;
  disbursementModal3: boolean;
  disbursementSearch: string;
  disbursementDate: string;
  disbursementArtcd: string;
  disbursementartnm: string;
  disbursementAcccd: string;
  disbursementCheck: boolean;
  disbursementFocused?: MainModel;
  disbursementData: Array<MainModel>;
  actnm: string | number;

  // 출장 팝업
  businessTripSearch: string;
  businessTripDate: string;
  businessTripArtcd: string;
  businessTriPartnm: string;
  businessTripAcccd: string;
  businessTripModal: boolean;
  businessTripFocused?: MainModel;
  businessTripData: Array<MainModel>;
  businessTripCheck: boolean;

  // 급여 팝업
  payYearmon: string;
  paySearch: string;
  offiyn: string;
  cltcd: string;
  cltnm: string;
  artcd: string;
  artnm: string;
  acccd: string;
  accnm: string;
  payDate: string;
  payModal: boolean;
  payData: Array<MainModel>;
  pushTotalCheck: boolean;

  // trail
  total: string;
  jcnt_tot: string;
  notcnt_tot: string;
  qty_tot3: string;
  samt_tot3: string;
  tamt_tot3: string;
  mijamt_tot3: string;
  qty_tot4: string;
  samt_tot4: string;
  tamt_tot4: string;
  mijamt_tot4: string;

  // popup trail
  tot_samt_tot: string;
  tot_tamt_tot: string;
  tot_mamt_tot: string;

  // 카드엑셀 이미지 팝업
  cardView: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_ca640
 * @category 매입등록
 */
export class BuyRegister extends PageComponent<PageProps, BuyRegisterState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  updatedRows3?: Array<any>;

  updatedRows4?: Array<any>;

  updatedRows5?: Array<any>;

  updatedRows6?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  table2: React.RefObject<TableLayout> = React.createRef();

  table3: React.RefObject<TableLayout> = React.createRef();

  table4: React.RefObject<TableLayout> = React.createRef();

  table5: React.RefObject<TableLayout> = React.createRef();

  table6: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinity4?: InfinityRetrieve;

  infinity5?: InfinityRetrieve;

  infinity6?: InfinityRetrieve;

  tabRows?: Array<any>;

  tabRows2?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  buy?: number;

  deal?: number;

  files?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const id = BuyRegisterTabId;
    const titles = BuyRegisterTabTitles;

    this.tabs = [
      new TabModel(id.Buy.toString(), MdReorder, titles[id.Buy]),
      new TabModel(id.Detail.toString(), MdPublish, titles[id.Detail]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // 전년도 월 계산
    const frontToday = new Date();
    let frontYear: string | number;

    today.getMonth() === 0 ? frontYear = frontToday.getFullYear() - 1
      : frontYear = frontToday.getFullYear(); // 년도

    let frontMonth: string | number;
    today.getMonth() === 0 ? frontMonth = 12
      : frontMonth = frontToday.getMonth(); // 월

    if (frontMonth < 10) {
      frontMonth = `0${frontMonth}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // data
      stdate: pageParams?.date || `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      searchGubun: '%',
      searchQuery: pageParams?.remark || '',
      resuchk: '1',
      focusIndex: 0,
      data: [],
      buyList: [],
      choice: '1',
      newChk: '0',
      isNew: '0',
      nowData: `${year}${month}${date}`,
      misdateChk: '0',
      focusState: '0',

      // 프로젝트팝업
      projectActcd: '',
      projectActnm: '',
      projectData: [],

      // 외주인건비 팝업
      outsourceDate: `${year}${month}${date}`,
      popupSearchQuery: '',

      // 발주서 팝업
      printorderSearch: '',
      printorderDate: `${year}${month}${date}`,
      printorderSdate: '19700101',
      printorderEdate: `${year}${month}${date}`,
      printorderFocusIndex: 0,

      // 지출결의서 팝업
      disbursementSearch: '',
      disbursementDate: `${year}${month}${date}`,
      disbursementArtcd: '',
      disbursementartnm: '',
      disbursementAcccd: '7111',
      actnm: '1',

      // 출장 팝업
      businessTripSearch: '',
      businessTripDate: `${year}${month}${date}`,
      businessTripArtcd: '',
      businessTripAcccd: '7111',

      // 급여팝업
      payYearmon: `${frontYear}${frontMonth}`,
      offiyn: '0',
      paySearch: '',
      payDate: `${year}${month}${date}`,
      artcd: '0101',
      artnm: '직원급여',
      acccd: '7111',
      accnm: '직원급여와임금',

      // trail
      total: '0',
      jcnt_tot: '0',
      notcnt_tot: '0',
      qty_tot3: '0',
      samt_tot3: '0',
      tamt_tot3: '0',
      mijamt_tot3: '0',
      qty_tot4: '0',
      samt_tot4: '0',
      tamt_tot4: '0',
      mijamt_tot4: '0',

      // popup trail
      tot_samt_tot: '0',
      tot_tamt_tot: '0',
      tot_mamt_tot: '0',

      // 신용카드엑셀 이미지 도우미
      cardView: false,
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 매입상세
      TabHeaderBuy,
      // 거래명세표
      TabHeaderDetail,
    ];
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams.remark || this.state.searchQuery,
        stdate: pageParams.date || this.state.stdate,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    await api.fxExec('open', {});
    // 매입구분
    const data2 = await api.dropdown('wf_dd_ca510_114_01');
    this.SS({ wkgubuns: data2?.items || [] });

    if (!data2) return;

    // ??
    const data4 = await api.dropdown('wf_da020_code');
    if (!data4) return;
    this.setState({ gubun: data4.items });

    this.onTabChange(this.tabs[BuyRegisterTabId.Buy]);
    this.onRetrieveEvent();
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, async () => {
      await this.table.current?.update(false);
      this.table.current?.update(true);
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        resuchk: this.state.resuchk,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        if (items) {
          this.setState({
            buyList: [
              ...this.state.buyList,
              ...items.map((x: any) => new MainModel(x)),
            ],
          }, next);
        }
      },
      async () => {
        await this.SS({
          buyList: [],
          data: new MainModel(),
        });

        await this.infinity?.retrieveAll();
        await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      buyList: [],
      data: new MainModel(),
      isNew: '0',
      outsourceCheck: false,
      printorderCheck: false,
      disbursementCheck: false,
      businessTripCheck: false,
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['mijdate', 'mijnum'],
        [this.state.focused?.mijdate, this.state.focused?.mijnum], type, true) || 0;
      if (this.state.buyList && this.state.buyList.length > index) {
        this.grid.current?.setFocus(index);
      }
      this.SS({
        total: this.infinity?.box?.total || '0',
        jcnt_tot: this.infinity?.box?.jcnt_tot || '0',
        notcnt_tot: this.infinity?.box?.notcnt_tot || '0',
        misdateChk: '0',
        newChk: '0',
      });
    });
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();
    if (!data) {
      ConfirmWarning.show('오류', '서버 오류입니다. 엘맨소프트에 문의 바랍니다.');
      return;
    }
    // const date = data;
    // date.mijdate = this.state.nowData;
    const one = new MainModel({
      ...data,
      mijdate: this.state.nowData,
    }, true);

    this.setState({
      isNew: '1',
      buyList: [
        one,
        ...this.state.buyList,
      ],
      data: one,
      lastNewData: new MainModel(data, true),
      buys: [],
      details: [],
      misdateChk: '1',
    }, async () => {
      await this.table.current?.update();
      await this.grid.current?.setFocus(0);
      this.onItemChange(this.state.enddate, ItemChange.mijdate, 'dw_2_itemchanged');
    });
  }

  // seq컬럼 순서정렬을 위한 함수
  @action
  async seq() {
    this.tabRows = [];
    this.buy = 0;
    this.state.buys.forEach((x: any, num) => {
      // @ts-ignore
      this.buy += parseInt(x.samt, 10);
      this.tabRows?.push({
        ...x,
        seq: `00${num + 1}`,
        new: '1',
      });
    });

    this.tabRows2 = [];
    this.deal = 0;
    this.state.details.forEach((x: any, num) => {
      // @ts-ignore
      this.deal += parseInt(x.samt, 10);
      this.tabRows2?.push({
        ...x,
        seq: `00${num + 1}`,
        new: '1',
      });
    });
    // totlal 개수 변경
    const total = this.state.data;
    total.mijdate = this.state.misdateChk === '1' ? String(this.state.mijdate) : this.state.data.mijdate;
    total.total3 = String(this.tabRows.length);
    total.total4 = String(this.tabRows2.length);

    this.setState({
      buys: this.tabRows,
      details: this.tabRows2,
      data: total,
    });
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const samt = Format.toNumber(item.samt);
    const tamt = Format.toNumber(item.tamt);
    const uamt = Format.toNumber(item.uamt);

    let amount = 0;
    let sum = 0;
    let tax = 0;
    if (name === 'qty' || name === 'uamt') {
      if (this.state.data.gubun === '02' || this.state.data.gubun === '04' || this.state.data.taxreclafi === '99') {
        amount = Math.round(qty * uamt);
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.taxcls === '1') {
        amount = Math.round((qty * uamt) / 1.1);
        tax = Math.round((qty * uamt) - ((qty * uamt) / 1.1));
        sum = amount + tax;
      } else {
        amount = Math.round(qty * uamt);
        tax = Math.round((qty * uamt) / 10);
        sum = amount + tax;
      }
    } else if (name === 'samt') {
      if (this.state.data.gubun === '02') {
        amount = samt;
        tax = 0;
        sum = samt + tamt;
      } else if (this.state.data.gubun === '03') {
        if (this.state.data.taxreclafi === '99') {
          amount = samt;
          tax = 0;
          sum = samt + tamt;
        } else if (this.state.data.taxcls === '1') {
          if (qty === 0 && uamt === 0) {
            tax = Math.round(samt - (samt / 1.1));
            amount = Math.round(samt - tax);
            sum = amount + tax;
          } else {
            amount = samt;
            tax = Math.round(amount - (amount / 1.1));
            sum = samt + tax;
          }
        } else {
          amount = samt;
          tax = Math.round(samt / 10);
          sum = samt + tax;
        }
      } else if (this.state.data.gubun !== '02' && this.state.data.gubun !== '03') {
        if (this.state.data.taxcls === '1') {
          tax = Math.round((samt) - (samt / 1.1));
          amount = samt - tax;
          sum = amount + tax;
        } else {
          amount = samt;
          tax = Math.round(samt / 10);
          sum = samt + tax;
        }
      }
    } else {
      tax = tamt;
      amount = samt;
      sum = samt + tamt;
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: amount.toString(),
      tamt: tax.toString(),
      mijamt: sum.toString(),
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (this.buy !== this.deal && this.state.buys.length > 2 && this.state.details.length > 2) {
      ConfirmWarning.show('확인', '매입상세랑 거래명세표 금액이 맞지않습니다.');
      return;
    }

    if (!this.state.data?.projno && this.state.data?.proof !== '1') {
      const data = await Confirm.show('확인', '해당내역이 경비증빙여부가 없고 프로젝트번호가 입력이 안되어 있는것이 맞습니까?', ConfirmType.QUESTION);
      if (data !== true) {
        return;
      }
    } else if (this.state.data?.proof !== '1') {
      const data = await Confirm.show('확인', '해당내역이 경비증빙여부가 없는것이 맞습니까?', ConfirmType.QUESTION);
      if (data !== true) {
        return;
      }
    } else if (!this.state.data?.projno) {
      const data = await Confirm.show('확인', '프로젝트번호가 입력이 안되어있습니다. 프로젝트 번호가 없는게 맞습니까?', ConfirmType.QUESTION);
      if (data !== true) {
        return;
      }
    } else if (this.state.data?.mijdate > this.state.nowData) {
      const data = await Confirm.show('삭제', '매입일자가 금일이후 입니다. 맞습니까?', ConfirmType.QUESTION);
      if (data !== true) {
        return;
      }
    }

    // 증빙구분
    const items = this.state.data;
    if (!items.gubun) {
      items.gubun = '01';
    }

    const data = await api.save({
      ...items,
      new: this.state.isNew,
      items: this.tabRows,
      items2: this.tabRows2,
    }, true);
    this.updatedRows = [];
    await this.table.current?.update(true);

    this.setState({
      misdateChk: '0',
      focusState: '1',
    });

    if (data !== false) this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `매입일자: ${this.state.focused?.mijdate}, 매입번호: ${this.state.focused?.mijnum}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focused) && this.onRetrieveEvent(RetrieveFocusType.END);
      this.setState({ focused: undefined, buys: [] });
    }
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    const { actionStore: api } = this.props;

    if (!item) {
      await this.SS({
        details: [],
        data: new MainModel(),
      });
      await this.table.current?.update();
      return;
    }

    if (item.isNew) {
      return;
    }

    this.setState({
      focused: item,
      focusIndex: index,
      newChk: '0',
    }, async () => {
      const data = await api.fxExec('dw_1_RowFocuschanged',
        {
          cltcd: item?.cltcd,
          mijdate: item?.mijdate,
          mijnum: item?.mijnum,
        });
      if (data?.items) {
        this.setState({
          buys: data.items,
          data: new MainModel(data, false),
          qty_tot3: data?.qty_tot3 || '0',
          samt_tot3: data?.samt_tot3 || '0',
          tamt_tot3: data?.tamt_tot3 || '0',
          mijamt_tot3: data?.mijamt_tot3 || '0',
        });
      } else {
        this.setState({
          buys: [],
          data: new MainModel(data, false),
        });
      }
      if (data?.items2) {
        this.setState({
          details: data.items2,
          data: new MainModel(data, false),
          qty_tot4: data?.qty_tot4 || '0',
          samt_tot4: data?.samt_tot4 || '0',
          tamt_tot4: data?.tamt_tot4 || '0',
          mijamt_tot4: data?.mijamt_tot4 || '0',
        });
      } else {
        this.setState({
          details: [],
          data: new MainModel(),
        });
      }
      this.setState({ sndamt: parseInt(data?.mijamt, 10) - parseInt(data?.sndamt, 10) },
        async () => {
          await this.seq();
          await this.table.current?.update();

          // @ts-ignore
          if (this.state[BuyRegisterTabDataStateNames[this.tabIndex]].length) {
            // @ts-ignore
            this.onRowFocusEvent2(this.state[BuyRegisterTabDataStateNames[this.tabIndex]][0]);
          }
        });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.state.focused?.gubun === '04') {
      ConfirmWarning.show('오류', '증빙구분이 매입세금계산서만 출력가능 합니다!');
      return;
    }

    if (this.state.buyList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    const file = await api.fxFileRaw(
      'wb_taxprt',
      {
        gubun: this.state.data.gubun,
        tax_spdate: this.state.data.tax_spdate,
        tax_spnum: this.state.data.tax_spnum,
      },
    );

    printJS({
      printable: URL.createObjectURL(new Blob([file.raw!], {
        type: file.extension === 'pdf' ? 'application/pdf' : 'image/png',
      })),
      type: file.extension === 'pdf' ? 'pdf' : 'image',
      showModal: true,
    });
  }

  @action
  async tapUpdatedRows(rows: any, updatedRows: any) {
    const i = this.tabIndex;
    // @ts-ignore
    this.setState({
      // @ts-ignore
      [BuyRegisterTabDataStateNames[i]]: rows,
      // @ts-ignore
      [BuyRegisterTabUpdatesStateNames[i]]: updatedRows,
    }, async () => {
      await this.seq();
    });
  }

  @action
  async subNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `tab_${i + 1}_dw_3_new`,
      {
        cltcd: this.state.data?.cltcd,
        mijdate: this.state.data?.mijdate,
        mijnum: this.state.data?.mijnum,
        // @ts-ignore
        row: this.state[BuyRegisterTabDataStateNames[i]]?.length + 1,
      },
    );

    if (!this.state.buys.length && !this.state.details.length) {
      this.setState({
        buys: [...this.state.buys, new BuyModel(data, true)],
        details: [...this.state.details, new DetailModel(data, true)],
      });
    } else if (this.tabIndex === 0) {
      this.setState({ buys: [...this.state.buys, new BuyModel(data, true)] });
    } else {
      this.setState({ details: [...this.state.details, new DetailModel(data, true)] });
    }
    await this.table.current?.update();
    // @ts-ignore
    this.table.current?.setFocus(this.state[BuyRegisterTabDataStateNames[i]].length - 1, 0);
    // @ts-ignore
    this.table.current?.forceModifiedRow(this.state[BuyRegisterTabDataStateNames[i]].length - 1);
  }

  @action
  async subDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    // eslint-disable-next-line max-len
    const text = `순번: ${this.state[BuyRegisterTabFocusedStateNames[i]]?.seq}, 품명: ${this.state[BuyRegisterTabFocusedStateNames[i]]?.remark}`;
    await api.fxDelete(
      `tab_${i + 1}_dw_3_delete`,
      text,
      {
        // @ts-ignore
        ...this.state[BuyRegisterTabFocusedStateNames[i]],
      },
    );

    const checkData: any = [];
    // @ts-ignore
    this.state[BuyRegisterTabDataStateNames[i]]?.forEach((x: any) => {
      // @ts-ignore
      if (x.seq !== this.state[BuyRegisterTabFocusedStateNames[i]]?.seq) {
        checkData.push({
          ...x,
        });
      }
    });

    // @ts-ignore
    this.setState({ [BuyRegisterTabDataStateNames[i]]: checkData });
    await this.table.current?.update(true);
    this.table.current?.setFocus(0, 1);
  }

  @action
  onRowFocusEvent2(item: any) {
    const i = this.tabIndex;
    // @ts-ignore
    this.setState({ [BuyRegisterTabFocusedStateNames[i]]: item });
  }

  @action
  async onSubExcelEvent() {
    try {
      const files = await FileSelector.single(false);
      await this.excelUpload(files[0].name, files[0]);
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async excelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('매입등록', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      this.onRetrieveEvent();
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    if (await api.exec(
      Category.GENERAL,
      'wb_excel_lazytask',
      {
        mijdate: this.state.focused?.mijdate,
        mijnum: this.state.focused?.mijnum,
        cltcd: this.state.focused?.cltcd,
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    )) {
      await ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      await this.onRetrieveEvent();
    }
  }

  @action
  async openCardExcelFile() {
    await this.SS({ cardView: false });
    try {
      const files = await FileSelector.single(false);
      await this.cardExcelUpload(files[0].name, files[0]);
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async onSubCardExcelEvent() {
    if (this.state.buyList.length < 1) {
      await ConfirmWarning.show('확인', '선택된 항목이 없습니다.');
      return;
    }

    if (this.state.data?.mijcltcd === '') {
      await ConfirmWarning.show('확인', '먼저 미지급거래처(카드사)를 선택해주세요.');
      return;
    }

    // 매입상세 있으면 다 덮어씌우는 경고 메세지
    let result;
    if (this.state.buys?.length > 0) {
      result = await Confirm.ask('확인', '입력한내역이 있습니다 전부 덮어씌어집니다.\n덮어쓰기 하시겠습니까?', '확인', '취소');
    }

    if (result) return;

    await this.SS({
      cardView: true,
    });
  }

  async cardExcelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    // 거래명세표로 탭 이동
    await this.onTabChange(this.tabs[1]);

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('신용카드', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);

      if (response.items) {
        await this.SS({
          details: response.items.filter((x: any) => x.seq !== '001').map((x: any) => new DetailModel(x, true)),
          buys: [new BuyModel(response, true)],
        });
        this.table.current?.update(true);
      }
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    await api.exec(Category.GENERAL, 'wb_excel_card_lazytask',
      {
        mijdate: this.state.focused?.mijdate,
        mijnum: this.state.focused?.mijnum,
        cltcd: this.state.focused?.cltcd,
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      }, false);
  }

  @action
  async onButtonclick(datas: any, name: any) {
    const { actionStore: api } = this.props;

    this.setState({ focusState: '1' });
    let btnData = {};

    switch (BtnGubun[name]) {
      case 'b_projnoadd':
        btnData = {
          actcd: this.state.data?.actcd,
          actnm: this.state.data?.actnm,
        };
        break;

      case 'b_artcd':
        btnData = {
          artcd: this.state.data?.artcd,
          artnm: this.state.data?.artnm,
          acccd: this.state.data?.acccd,
        };
        break;

      case 'b_acccd':
        btnData = {
          acccd: this.state.data?.acccd,
          accnm: this.state.data?.accnm,
        };
        break;

      case 'b_bigo':
        btnData = {
          bigo: this.state.data?.bigo,
        };
        break;

      case 'b_proof':
        btnData = {
          proof: this.state.data?.proof,
        };
        break;

      case 'b_projno':
        btnData = {
          projno: this.state.data?.projno,
          projnm: this.state.data?.projnm,
          actcd: this.state.data?.actcd,
          actnm: this.state.data?.actnm,
        };
        break;

      case 'b_mijdate':
        btnData = {
          cltcd: this.state.data?.cltcd,
          newmijdate: datas,
          tax_spdate: this.state.data?.tax_spdate,
          tax_spnum: this.state.data?.tax_spnum,
          divicd: this.state.data?.divicd,
          gubun: this.state.data?.gubun,
        };
        break;

      case 'b_wkgubun':
        btnData = {
          wkgubun: this.state.data?.wkgubun,
        };
        break;

      default:
        break;
    }

    const data = await api.fxExec('dw_2_buttonclicked',
      {
        new: this.state.newChk === '1' ? '0' : '1',
        itemname: BtnGubun[name],
        data: datas,
        mijdate: BtnGubun[name] === 'b_mijdate' ? this.state.focused?.mijdate : this.state.data?.mijdate,
        mijnum: BtnGubun[name] === 'b_mijdate' ? this.state.focused?.mijnum : this.state.data?.mijnum,
        ...btnData,
      });

    data && this.setState({
      newChk: '0',
      data: new MainModel({
        ...this.state.data,
        ...data,
      }, true),
    });

    if (ItemChange[name] !== 'b_projnoadd') {
      this.onRetrieveEvent();
    } else if (data?.titlebox === '성공' && ItemChange[name] === 'b_projnoadd') {
      this.onProjectEvent(true);
    }
  }

  @action
  async projectChange(data: any) {
    const checkData: any = [];
    this.state.details?.forEach((x: any) => {
      checkData.push({
        ...x,
        projno: data.projno,
        projnm: data.projnm,
        actcd: data.actcd,
        actnm: data.actnm,
      });
    });

    this.setState({ details: checkData }, async () => {
      await this.table.current?.update();
    });
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { modalStore } = this.props;
    const json = JSON.parse(JSON.parse(message));

    if (json?.key === 'LAZYTASK_save') {
      await this.onRetrieveEvent();
    }

    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      if (json?.message.indexOf('공사') !== -1) {
        // this.disbursementModal(true);
      } else {
        const cltResponse = await modalStore.openAddClt(this.state.data.cltcd, '2');
        this.setState({
          // @ts-ignore
          data: new BuyModel({
            ...this.state.data,
            cltcd: cltResponse.cltcd,
            cltnm: cltResponse.cltnm,
          }),
        });
      }
    }
  }

  @action
  async onItemChange(datas: any, name: any, fntName: any) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // eslint-disable-next-line no-param-reassign
    datas = datas.replace(',', '');
    let itemData = {};
    switch (ItemChange[name]) {
      case 'cancleflag':
        itemData = {
          etflag: this.state.data?.etflag,
        };
        break;

      case 'amtflag':
        itemData = {
          amtflag: datas,
        };
        break;

      case 'etflag':
        itemData = {
          etflag: datas,
        };
        break;

      case 'osflag':
        itemData = {
          osflag: datas,
        };
        break;

      case 'gubun':
        itemData = {
          gubun: datas,
        };
        break;

      case 'billkind':
        itemData = {
          billkind: datas,
          gubun: this.state.data?.gubun,
        };
        break;

      case 'mijdate':
        itemData = {
          jirogubun: this.state.data.samt,
        };
        break;

      case 'cltcd':
        itemData = {
          cardco: this.state.data.cardco,
          cltflag: this.state.data.cltflag,
          row3: String(this.state.buys?.length),
          row4: String(this.state.details?.length),
        };
        break;

      case 'cltcd_new':
        itemData = {
          cltcd_new: this.state.data.cltcd_new,
          cltflag: this.state.data.cltflag,
          row3: String(this.state.buys?.length),
          row4: String(this.state.details?.length),
        };
        break;

      case 'artcd':
        itemData = {
          artcd: this.state.data.artcd,
          divicd: this.state.data.divicd,
        };
        break;

      case 'projno':
        itemData = {
          projno: this.state.data.projno,
          row3: String(this.state.buys?.length),
          row4: String(this.state.details?.length),
        };
        break;

      case 'divicd':
        itemData = {
          divicd: this.state.data.divicd,
          artcd: this.state.data.artcd,
        };
        break;

      case 'pname':
        itemData = {
          pname: datas,
        };
        break;

      case 'qty':
        itemData = {
          gubun: this.state.data.gubun,
          taxreclafi: this.state.data.taxreclafi,
          taxcls: this.state.data.taxcls,
          // @ts-ignore
          samt: this.state[BuyRegisterTabFocusedStateNames[i]]?.samt,
          // @ts-ignore
          uamt: this.state[BuyRegisterTabFocusedStateNames[i]]?.uamt,
          // @ts-ignore
          qty: datas,
        };
        break;

      case 'uamt':
        itemData = {
          gubun: this.state.data.gubun,
          taxreclafi: this.state.data.taxreclafi,
          taxcls: this.state.data.taxcls,
          // @ts-ignore
          samt: this.state[BuyRegisterTabFocusedStateNames[i]]?.samt,
          uamt: datas,
          // @ts-ignore
          qty: this.state[BuyRegisterTabFocusedStateNames[i]]?.qty,
        };
        break;

      case 'samt':
        itemData = {
          gubun: this.state.data.gubun,
          taxreclafi: this.state.data.taxreclafi,
          taxcls: this.state.data.taxcls,
          samt: datas,
          // @ts-ignore
          uamt: this.state[BuyRegisterTabFocusedStateNames[i]]?.uamt,
          // @ts-ignore
          qty: this.state[BuyRegisterTabFocusedStateNames[i]]?.qty,
        };
        break;

      case 'tamt':
        itemData = {
          tamt: datas,
          // @ts-ignore
          samt: this.state[BuyRegisterTabFocusedStateNames[i]]?.samt,
        };
        break;

      default:
        itemData = {
          jirogubun: datas,
        };
        break;
    }
    const data = await api.fxExec(
      fntName,
      {
        // @ts-ignore
        new: this.state[BuyRegisterTabFocusedStateNames[i]]?.isNew === true ? '1' : '0',
        itemname: ItemChange[name],
        data: datas || '',
        cltcd: this.state.data?.cltcd,
        cltnm: this.state.data?.cltnm,
        mijdate: this.state.data?.mijdate,
        mijnum: this.state.data?.mijnum,
        ...itemData,
      },
    );
    this.dataChange(name, datas, data);
  }

  @action
  async dataChange(name: any, datas: any, data: any) {
    const i = this.tabIndex;
    switch (ItemChange[name]) {
      case 'gubun':
        this.state.buyList.forEach((x: any, j: number) => {
          if (x?.misnum === this.state.focused?.mijnum) {
            this.state.buyList[j].mijnum = datas;
          }
        });
        break;

      case 'pname':
        // @ts-ignore
        this.state[BuyRegisterTabDataStateNames[i]].forEach((x: any, j: number) => {
          // @ts-ignore
          this.state[BuyRegisterTabDataStateNames[i]][j].remark = data.remark;
        });
        break;

      case 'qty':
      case 'uamt':
      case 'samt':
        // @ts-ignore
        // eslint-disable-next-line no-case-declarations
        const newTabData: BuyRegisterTabModels[i] = [];
        // @ts-ignore
        this.state[BuyRegisterTabDataStateNames[i]].forEach((x: any) => {
          // @ts-ignore
          // eslint-disable-next-line radix
          if (x?.seq === this.state[BuyRegisterTabFocusedStateNames[i]]?.seq) {
            // @ts-ignore
            newTabData.push(new BuyRegisterTabModels[i]({
              ...x,
              ...data,
            }));
            // @ts-ignore
          } else if (this.state[BuyRegisterTabFocusedStateNames[i]].isNew === true && !x.seq) {
            // @ts-ignore
            newTabData.push(new BuyRegisterTabModels[i]({
              ...x,
              ...data,
            }));
          } else {
            // @ts-ignore
            newTabData.push(new BuyRegisterTabModels[i]({
              ...x,
            }));
          }
        });
        // @ts-ignore
        this.setState({
          [BuyRegisterTabDataStateNames[i]]: newTabData,
        }, async () => {
          await this.table.current?.update(true);
        });
        break;

      case 'mijdate':
        data && this.setState({
          data: new MainModel({
            ...this.state.data,
            ...data,
          }, true),
          mijdate: datas,
        });
        break;

      default:
        // @ts-ignore
        data && this.setState({
          data: new MainModel({
            ...this.state.data,
            ...data,
          }, true),
        });
        break;
    }
    await this.table.current?.update(true);
    // @ts-ignore
    await this.tapUpdatedRows(this.state[BuyRegisterTabDataStateNames[i]], data);
  }

  @action
  onOutsourceModal(isOpen: boolean) {
    this.setState({ outsourceModal: isOpen });

    if (isOpen === false) {
      this.setState({ focusState: '1' });
    } else {
      this.onOutsourceRetrive();
    }
  }

  @action
  async onOutsourceRetrive() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        sub: 'w_popup_e601_scamt',
        as_nm: this.state.popupSearchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          outsourceData: [
            ...this.state.outsourceData,
            ...items.map((x: any) => new MainModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          outsourceData: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.outsourceData && this.state.outsourceData?.length > 0) {
          await this.table2.current?.update(true);
          this.table2.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      outsourceData: [],
    }, async () => {
      await this.infinity2?.retrieveAll();
      await this.table2.current?.update(true);
      this.table2.current?.setFocus(0, 0);
    });
  }

  @action
  onOutsourceURowFocus(item: MainModel) {
    this.setState({ outsourceFocused: item });
  }

  @action
  onOutsourceUpdated(rows: any, updatedRows2: any) {
    this.updatedRows = updatedRows2;
    this.setState({ outsourceData: rows });
  }

  @action
  async updateOutsourceChk(checked: boolean) {
    this.setState({
      outsourceCheck: checked,
      outsourceData: this.state.outsourceData?.map((x) => new MainModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table2.current?.update(false);
  }

  @action
  async modalOutsourceSave() {
    const { actionStore: api } = this.props;

    let chkData: MainModel[] = [];
    this.state.outsourceData.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new MainModel({
          chk: x.chk,
          actcd: x.actcd,
          seq: x.seq,
          schdate: x.schdate,
        }));
      }
    });


    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.save({
        sub: 'w_popup_e601_scamt',
        new: '0',
        as_nm: this.state.popupSearchQuery,
        mijdate: this.state.outsourceDate,
        items: chkData,
      }, true);
      this.updatedRows2 = [];
      chkData = [];
      await this.table2.current?.resetUpdates();
      this.onOutsourceModal(false);

      await this.SS({
        stdate: this.state.outsourceDate,
        enddate: this.state.outsourceDate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  onPrintorderModal(isOpen: boolean) {
    this.setState({ printorderModal: isOpen });

    if (isOpen === false) {
      this.setState({ focusState: '1' });
    } else {
      this.onPrintorderModalRetrive();
    }
  }

  @action
  async onPrintorderModalRetrive() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca608_03',
        stdate: this.state.printorderSdate,
        enddate: this.state.enddate,
        as_nm: this.state.printorderSearch,
      },
      (params) => api.retrieve(params),
      async (items) => {
        this.setState({
          printorderData: [
            ...this.state.printorderData,
            ...items.map((x: any) => new MainModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          printorderData: [],
        });

        await this.infinity3?.retrieveAll();
        if (this.state.printorderData && this.state.printorderData?.length > 0) {
          await this.table3.current?.update();
          await this.table3.current?.setFocus(0, 0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      printorderData: [],
    }, async () => {
      await this.infinity3?.retrieveAll();

      if (this.state.printorderData && this.state.printorderData?.length > 0) {
        await this.table3.current?.update();
        await this.table3.current?.setFocus(0, 0);
        this.onPrintorderRowFocus(this.state.printorderData[this.state.printorderFocusIndex], 0);
      }
    });
  }

  @action
  onPrintorderRowFocus(item: MainModel, index: number) {
    this.setState({
      printorderFocused: item,
      printorderFocusIndex: index,
    });
  }

  @action
  onPrintorderRowUpdated(rows3: any, updatedRows3: any) {
    this.updatedRows3 = updatedRows3;
    this.setState({ printorderData: rows3 });

    this.updatePrintorderChk();
  }

  @action
  async updatePrintorderChk() {
    const checkData: any = [];
    this.state.printorderData.forEach((x: any) => {
      if (this.state.printorderFocused?.baldate === x.baldate
        && this.state.printorderFocused?.balnum === x.balnum
        && this.state.printorderFocused?.actnm === x.actnm) {
        checkData.push({
          ...x,
          chk: x.chk === '1' ? '0' : '1',
        });
      } else {
        checkData.push({
          ...x,
        });
      }
    });

    this.setState({
      printorderData: checkData,
    }, async () => {
      await this.table3.current?.update();
      this.table3.current?.setFocus(this.state.printorderFocusIndex, 0);
    });
  }

    @action
  async updatePrintorderAllChk(checked: boolean) {
    this.setState({
      printorderCheck: checked,
      printorderData: this.state.printorderData?.map((x) => new MainModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table3.current?.update(false);
  }

  @action
    async onPrintorderSave() {
      const { actionStore: api } = this.props;

      let chkData: MainModel[] = [];
      this.state.printorderData.forEach((x: any) => {
        if (x?.chk === '1') {
          chkData.push(new MainModel({
            ...x,
          }));
        }
      });

      if (!chkData.length) {
        ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
        return;
      }
      await api.save({
        sub: 'w_popup_ca608_03',
        stdate: this.state.printorderSdate,
        enddate: this.state.printorderEdate,
        as_nm: this.state.printorderSearch,
        mijdate: this.state.printorderDate,
        items: chkData,
      }, false);
      this.updatedRows3 = [];
      chkData = [];
      await this.table3.current?.resetUpdates();
      this.onPrintorderModal(false);

      await this.SS({
        stdate: this.state.printorderDate,
        enddate: this.state.printorderDate,
      });
      await this.onRetrieveEvent();
    }

  @action
  async onPrintorderBtn() {
    ConfirmWarning.show('확인', '이기능은 준비중입니다.');
  }

  @action
  async onPrintorderDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하겠습니까?';

    let chkData: MainModel[] = [];
    this.state.printorderData.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new MainModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.delete(text,
        {
          sub: 'w_popup_ca608_03',
          items: chkData,
        }) && this.onPrintorderModalRetrive();
      chkData = [];
    }
  }

  @action
  onBuyModal(isOpen: boolean) {
    this.setState({ buyModal: isOpen });
  }

  @action
  onBuyChoice(item: any) {
    if (item === '1') {
      this.onBuyModal2(true);
    } else {
      this.onBuyModal3(true);
    }
  }

  @action
  onBuyModal2(isOpen: boolean) {
    this.setState({ buyModal: false });
    this.setState({ buyModal2: isOpen });

    // 페이지 넘어가기
    this.props.publicStore.go(
      '/w_tb_ca640_import',
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
    );
  }

  @action
  async onBuyModal3(isOpen: boolean) {
    this.setState({ buyModal: false });
    this.setState({ buyModal3: isOpen });

    this.onPrintEvent();
  }

  @action
  onDisbursementModal(isOpen: boolean) {
    this.setState({ disbursementModal: isOpen });
  }

  @action
  constructionChoice(item: any) {
    if (item === '1') {
      this.onDisbursementModal2(true);
    } else {
      this.onDisbursementModal3(true);
    }
  }

  @action
  onDisbursementModal2(isOpen: boolean) {
    this.setState({ disbursementModal: false });
    this.setState({ disbursementModal2: isOpen });

    if (isOpen === false) {
      this.setState({ focusState: '1' });
    } else {
      this.onDisbursementRetrive('w_popup_ca642_ae200');
    }
  }

  @action
  onDisbursementModal3(isOpen: boolean) {
    this.setState({ disbursementModal: false });
    this.setState({ disbursementModal3: isOpen });

    if (isOpen === false) {
      this.setState({ focusState: '1' });
    } else {
      this.onDisbursementRetrive('w_popup_ca642_pb401');
    }
  }

  @action
  async onDisbursementRetrive(item: any) {
    const { actionStore: api } = this.props;

    if (item === 'w_popup_ca642_ae200') {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity5 = new InfinityRetrieve(
        {
          sub: item,
          as_nm: this.state.disbursementSearch,
        },
        (params) => api.retrieve(params),
        (items) => {
          this.setState({
            disbursementData: [
              ...this.state.disbursementData,
              ...items.map((x: any) => new MainModel(x)),
            ],
          });
        },
        async () => {
          await this.SS({
            disbursementData: [],
          });

          await this.infinity5?.retrieveAll();
          if (this.state.disbursementData && this.state.disbursementData?.length > 0) {
            await this.table4.current?.update();
            this.table4.current?.setFocus(0, 0);
          }
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        disbursementData: [],
      }, async () => {
        await this.infinity5?.retrieveAll();
        if (this.state.disbursementData && this.state.disbursementData?.length > 0) {
          await this.table4.current?.update();
          this.table4.current?.setFocus(0, 0);
          this.onDisbursementRowFocus(this.state.disbursementData[0]);
        }
      });
    } else {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity6 = new InfinityRetrieve(
        {
          sub: item,
          as_nm: this.state.businessTripSearch,
        },
        (params) => api.retrieve(params),
        (items) => {
          this.setState({
            businessTripData: [
              ...this.state.businessTripData,
              ...items.map((x: any) => new MainModel(x)),
            ],
          });
        },
        async () => {
          await this.SS({
            businessTripData: [],
          });

          await this.infinity6?.retrieveAll();
          if (this.state.businessTripData && this.state.businessTripData?.length > 0) {
            await this.table5.current?.update();
            this.table5.current?.setFocus(0, 0);
          }
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        businessTripData: [],
      }, async () => {
        await this.infinity6?.retrieveAll();
        if (this.state.businessTripData && this.state.businessTripData?.length > 0) {
          await this.table5.current?.update();
          this.table5.current?.setFocus(0, 0);
          this.onBusinessTripRowFocus(this.state.businessTripData[0]);
        }
      });
    }
  }

  @action
  onDisbursementRowFocus(item: MainModel) {
    this.setState({ disbursementFocused: item });
  }

  @action
  onDisbursementRowUpdated(rows: any, updatedRows4: any) {
    this.updatedRows4 = updatedRows4;
    this.setState({ disbursementData: rows });
  }

  @action
  async updateDisbursementChk(checked: boolean) {
    this.setState({
      disbursementCheck: checked,
      disbursementData: this.state.disbursementData?.map((x) => new MainModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table4.current?.update(false);
  }

  @action
  async onDisbursementSave() {
    const { actionStore: api } = this.props;

    let chkData: MainModel[] = [];
    this.state.disbursementData.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new MainModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    if (!this.state.disbursementArtcd) {
      ConfirmWarning.show('확인', '비용항목을 선택해주세요.');
      return;
    }
    await api.save({
      sub: 'w_popup_ca642_ae200',
      artcd: this.state.disbursementArtcd,
      artnm: this.state.disbursementartnm,
      acccd: this.state.disbursementAcccd,
      mijdate: this.state.disbursementDate,
      inputdate: this.state.disbursementFocused?.inputdate,
      inputnum: this.state.disbursementFocused?.inputnum,
      inputsabun: this.state.disbursementFocused?.inputsabun,
      pernm: this.state.disbursementFocused?.pernm,
      samt: this.state.disbursementFocused?.samt,
      tamt: this.state.disbursementFocused?.tamt,
      mamt: this.state.disbursementFocused?.mamt,
      subject: this.state.disbursementFocused?.subject,
      items: chkData,
    }, true);
    await this.table4.current?.resetUpdates();
    this.onDisbursementModal2(false);

    this.updatedRows4 = [];
    chkData = [];

    await this.SS({
      stdate: this.state.disbursementDate,
      enddate: this.state.disbursementDate,
    });
    await this.onRetrieveEvent();
  }

  @action
  async onDisbursementDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하겠습니까?';

    let chkData: MainModel[] = [];
    this.state.disbursementData.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new MainModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.delete(text,
        {
          sub: 'w_popup_ca642_ae200',
          items: chkData,
        }) && this.onDisbursementRetrive('w_popup_ca642_ae200');
      chkData = [];
    }
  }

  @action
  onBusinessTripRowFocus(item: MainModel) {
    this.setState({ businessTripFocused: item });
  }

  @action
  onBusinessTripRowUpdated(rows: any, updatedRows5: any) {
    this.updatedRows5 = updatedRows5;
    this.setState({ businessTripData: rows });
  }

  @action
  async updateBusinessTripChk(checked: boolean) {
    this.setState({
      businessTripCheck: checked,
      businessTripData: this.state.businessTripData?.map((x) => new MainModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table5.current?.update(false);
  }

  @action
  async onBusinessTripSave() {
    const { actionStore: api } = this.props;

    if (!this.state.businessTripArtcd) {
      ConfirmWarning.show('확인', '비용항목을 선택해주세요.');
      return;
    }

    await api.save({
      new: '0',
      sub: 'w_popup_ca642_pb401',
      artcd: this.state.businessTripArtcd,
      artnm: this.state.businessTriPartnm,
      acccd: this.state.businessTripAcccd,
      mijdate: this.state.businessTripDate,
      outdate: this.state.businessTripFocused?.outdate,
      outnum: this.state.businessTripFocused?.outnum,
      perid: this.state.businessTripFocused?.perid,
      pernm: this.state.businessTripFocused?.pernm,
      mamt: this.state.businessTripFocused?.mamt,
      title: this.state.businessTripFocused?.title,
      actnm: this.state.businessTripFocused?.actnm,
      actcd: this.state.businessTripFocused?.actcd,
    }, true);
    this.updatedRows5 = [];
    this.table5.current?.resetUpdates();
    this.onDisbursementModal3(false);
    this.onRetrieveEvent();
  }

  @action
  async onBusinessTripDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하겠습니까?';

    let chkData: MainModel[] = [];
    this.state.businessTripData.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new MainModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.delete(text,
        {
          sub: 'w_popup_ca642_pb401',
          items: chkData,
        }) && this.onDisbursementRetrive('w_popup_ca642_pb401');
      chkData = [];

      await this.SS({
        stdate: this.state.businessTripDate,
        enddate: this.state.businessTripDate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  onPayModal(isOpen: boolean) {
    this.setState({
      payModal: isOpen,
    });

    if (isOpen === false) {
      this.setState({
        focusState: '1',
        cltcd: '',
        cltnm: '',
        artcd: '',
        artnm: '',
        acccd: '',
        accnm: '',
      });
    } else {
      this.onPayModalRetrive();
    }
  }

  @action
  async onPayModalRetrive() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity4 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca642_pb300',
        mon: this.state.payYearmon,
        offiyn: this.state.offiyn,
        as_nm: this.state.paySearch,
      },
      (params) => api.retrieve(params),
      (items) => {
        if (items) {
          this.setState({
            payData: [...this.state.payData, ...items],
          });
        }
      },
      async () => {
        await this.SS({
          payData: [],
        });

        await this.infinity4?.retrieveAll();
        if (this.state.payData && this.state.payData?.length > 0) {
          await this.table6.current?.update();
          this.table6.current?.setFocus(0, 0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      payData: [],
    }, async () => {
      // 전체 체크
      await this.infinity4?.retrieveAll();
      if (this.state.payData.length) {
        const dataDate = await api.fxExec(
          'retrieve',
          {
            sub: 'w_popup_ca642_pb300',
            mon: this.state.payYearmon,
            offiyn: this.state.offiyn,
            as_nm: this.state.paySearch,
          },
        );
        this.SS({
          tot_samt_tot: dataDate?.tot_samt_tot || '0',
          tot_tamt_tot: dataDate?.tot_tamt_tot || '0',
          tot_mamt_tot: dataDate?.tot_mamt_tot || '0',
        });
      }
      await this.table6.current?.update();
      this.table6.current?.setFocus(0, 0);
    });
  }

  @action
  modalPayUpdated(rows: any, updatedRows6: any) {
    this.updatedRows6 = updatedRows6;

    this.setState({
      payData: rows,
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      payData: this.state.payData?.map((x) => new MainModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table6.current?.update(false);
  }

  @action
  async modalPaySave() {
    const { actionStore: api } = this.props;

    let chkData: any[] = [];
    this.state.payData.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push({
          chk: x.chk,
          perid: x.perid,
          remark: x.remark,
        });
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    if (!this.state.cltcd) {
      ConfirmWarning.show('확인', '거래처를 선택하십시오!');
      return;
    }

    await api.fxExec('save_lazytask',
      {
        sub: 'w_popup_ca642_pb300',
        new: '0',
        as_nm: this.state.paySearch,
        mon: this.state.payYearmon,
        offiyn: this.state.offiyn,
        artcd: this.state.artcd,
        artnm: this.state.artnm,
        cltnm: this.state.cltnm,
        acccd: this.state.acccd,
        accnm: this.state.accnm,
        mijdate: this.state.payDate,
        cltcd: this.state.cltcd,
        items: chkData,
      });
    this.updatedRows6 = [];

    chkData = [];
    this.table6.current?.resetUpdates();

    await this.SS({
      stdate: this.state.payDate,
      enddate: this.state.payDate,
    });

    await this.onPayModal(false);
  }

  onProjectEvent(isOpen: boolean) {
    this.setState({ ProjectModal: isOpen });

    this.onProjectBtn();
  }

  @action
  async onProjectBtn() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_projnoadd',
        actcd: this.state.data?.actcd,
      });
    this.setState({
      projectData: data?.items,
      projectActcd: this.state.data?.actcd,
      projectActnm: this.state.data?.actnm,
    });
    data && this.onProjectRowFocus(this.state?.projectData[0]);
  }

  @action
  onProjectRowFocus(item: any) {
    this.setState({ projectFocused: item });
  }

  @action
  async onProjectSubBtn(name: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_buttonclikced',
      {
        sub: 'w_popup_projnoadd',
        itemname: name === '0' ? 'b_add' : 'b_del',
        costdate: this.state.projectFocused?.costdate,
        costnum: this.state.projectFocused?.costnum,
      });

    // 현장명 + 버튼에대해 들어오는값 배열 중간에 추가
    const chkData: ProjectModel[] = [];
    let index: number;
    this.state.projectData.forEach((x: any, n) => {
      if (x.costnum === this.state.projectFocused?.costnum && x.costdate === this.state.projectFocused?.costdate) {
        index = n + 1;
        chkData.push(new ProjectModel({
          ...x,
          stateBtn: name === '0' ? '1' : '0',
        }));
      } else {
        chkData.push(new ProjectModel({
          ...x,
        }));
      }
    });

    if (data.items) {
      data.items.forEach((x: any, n: string | number) => {
        // @ts-ignore
        chkData.splice(index + n, 0, x);
      });
    } else {
      // @ts-ignore
      await chkData.splice(index, data.row);
    }

    await this.setState({ projectData: chkData });
  }

  @action
  // eslint-disable-next-line consistent-return
  async onProjectSave() {
    const { actionStore: api } = this.props;

    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인',
      `견적일자: ${this.state.projectFocused?.costdate},
       공사구분: ${projectGubuns.filter((y) => y.value === this.state.projectFocused?.gubun)[0]?.remark},
       현장명: ${this.state.projectFocused?.actnm} 선택한 내용으로 프로젝트를 생성하시겠습니까?`, ConfirmType.QUESTION);
    if (warning === false) return this.onProjectBtn();

    await api.fxExec('save',
      {
        sub: 'w_popup_projnoadd',
        costdate: this.state.projectFocused?.costdate,
        costnum: this.state.projectFocused?.costnum,
        gubun: this.state.projectFocused?.gubun,
        cltcd: this.state.projectFocused?.cltcd,
        actcd: this.state.projectFocused?.actcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      });
    this.onProjectBtn();
  }

  @computed
  get tabIndex(): BuyRegisterTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <BuyRegisterTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new MainModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
        // @ts-ignore
        rowUpdate={(state) => this.setState(state)}
      />
    );
  }
}
