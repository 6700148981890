import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps, PageToolEvents, RetrieveFocusType,
} from '../../../../constants';
import { EarlyInspectionModel, EarlyInspectionModel2, EarlyInspectionModel3 } from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { EarlyInspectionTemplate } from './EarlyInspection.template';
import { ConfirmDelete, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

export interface evcdItem {
  custcd: string;
  evlcd: string;
  evlnm: string;
  gresultcd: string;
  gresultnm: string;
  useyn: string;
  remark: string;
  inperid: string;
  indate: string;
}

// export interface EarlyInspectionListItem {
//   new: string;
//   custcd: string;
//   gresultcd: string;
//   gresultnm: string;
// }
//
// export interface EarlyInspectionListDetailItem {
//   evcd: string;
//   gresultcd: string;
//   resultcd: string;
//   resultnm: string;
//   new: string;
// }
//
// export interface EarlyInspectionListDetailItem2 {
//   evcd: string;
//   gresultcd: string;
//   resultcd: string;
//   resultnm: string;
//   seq: string;
//   seqnm: string;
//   new: string;
// }

interface EarlyInspectionState {
  // 조회 조건
  searchEvcd: string;
  searchQuery: string;

  evcds?: Array<evcdItem>;

  // data
  data: Array<EarlyInspectionModel>;
  data2: Array<EarlyInspectionModel2>;
  data3: Array<EarlyInspectionModel3>;

  focused?: EarlyInspectionModel;
  lastNewData: EarlyInspectionModel;

  EarlyInspectionList: Array<EarlyInspectionModel>;
  EarlyInspectionDetailList: Array<EarlyInspectionModel2>;
  EarlyInspectionDetailList2: Array<EarlyInspectionModel3>;
  focusedEarlyInspection?: EarlyInspectionModel;
  focusedDetailEarlyInspection?: EarlyInspectionModel2;
  focusedDetailEarlyInspection2?: EarlyInspectionModel3;
}

/**
 * 컨트롤러
 * @window w_tb_e042
 * @category 사전점검항목등록
 */
export class EarlyInspection extends PageComponent<PageProps, EarlyInspectionState>
  implements PageToolEvents {
  updatedRows?: Array<EarlyInspectionModel>;

  updatedRows2?: Array<EarlyInspectionModel2>;

  updatedRows3?: Array<EarlyInspectionModel3>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  tableDetail2:React.RefObject<TableLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      EarlyInspectionList: [],
      EarlyInspectionDetailList: [],
      EarlyInspectionDetailList2: [],
      data: [],
      data2: [],
      data3: [],
      searchEvcd: '01',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 첫번째 서버 요청
    // 정기유무 리스트 가져오기
    const data = await api.dropdown('wf_dd_e030');
    if (!data) return;
    this.setState({ evcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        evcd: this.state.searchEvcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new EarlyInspectionModel(x))],
        }, async () => {
          await this.table.current?.update(true);
          next && next();
        });
      },
      async () => {
        await this.SS({
          data: [],
        });
        const index = await this.infinity?.retrieveTo('gresultcd', this.state.focusedEarlyInspection?.gresultcd, type) || 0;
        this.state.data.length > index && this.table.current?.setFocus(index);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      data2: [],
      data3: [],
    });

    await this.infinity?.retrieveAll();
    await this.table.current?.update(true);
    await this.table.current?.setFocus(0);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    // 신규 시 하나 행만 추가
    if (this.state.focusedEarlyInspection?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    // 신규 시 기본 data 가져오기
    const data = await api.new({
      evcd: this.state.searchEvcd,
    });

    // 신규 시 gresultcd 값 추가
    if (data) {
      const one = new EarlyInspectionModel({
        ...data,
        gresultcd: this.state.data.length ? `00${Number(this.state.data[this.state.data.length - 1].gresultcd) + 1}`.substr(-2) : '00',
      }, true);

      await this.setState({
        data: [
          ...this.state.data,
          one,
        ],
        focusedEarlyInspection: one,
        data2: [],
        data3: [],
      }, async () => {
        // 신규시 rerender
        await this.table.current?.update(true);
        await this.table.current?.setFocus(this.state.data.length - 1);
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
      items2: this.updatedRows2,
      items3: this.updatedRows3,
    }, this.state.focusedEarlyInspection?.isNew === true
      || this.state.focusedDetailEarlyInspection?.isNew === true
      || this.state.focusedDetailEarlyInspection2?.isNew === true)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      this.updatedRows3 = [];
      this.table.current?.resetUpdates();
    }
    // this.newRowCount = 0;
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `구분코드: ${this.state.focusedEarlyInspection?.gresultcd
    }  구분명: ${this.state.focusedEarlyInspection?.gresultnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focusedEarlyInspection) && this.onRetrieveEvent();
    }
  }

  @action
  onRowFocusEvent(item: EarlyInspectionModel) {
    const { actionStore: api } = this.props;

    this.setState({
      focusedEarlyInspection: item,
      data2: [],
      data3: [],
    }, async () => {
      // this.updatedRows2 = [];
      // const data = await api.exec(
      //   Category.GENERAL,
      //   'dw_1_RowFocuschanged',
      //   item,
      // );
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        this.state.focusedEarlyInspection,
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            data2: [...this.state.data2, ...items.map((x: any) => new EarlyInspectionModel2(x, false))],
          }, async () => {
            this.tableDetail.current?.update(true);
            this.tableDetail.current?.setFocus(0);
          });
        },
        async () => {
          await this.SS({
            data2: [],
          });
          await this.infinity2?.retrieveAll();
        },
      );
      await this.infinity2.retrieveAll();
      await this.tableDetail.current?.update(true);
      await this.tableDetail.current?.setFocus(0);
      await this.tableDetail2.current?.update(true);
      await this.tableDetail2.current?.setFocus(0);
    });
  }

  @action
  onRowFocusEvent2(item: EarlyInspectionModel2) {
    const { actionStore: api } = this.props;
    this.updatedRows3 = [];

    this.setState({ focusedDetailEarlyInspection: item }, async () => {
      const data = await api.fxExec(
        'dw_2_RowFocuschanged',
        this.state.focusedDetailEarlyInspection,
      );
      if (data?.items) {
        this.setState({
          data3: data?.items?.map((x: any) => new EarlyInspectionModel3(x, false)),
        }, () => this.tableDetail2.current?.update(true));
        this.tableDetail2.current?.setFocus(0);
      } else {
        this.setState({
          data3: [],
        }, () => this.tableDetail2.current?.update(true));
      }
    });
  }

  @action
  onRowFocusEvent3(item:EarlyInspectionModel3) {
    if (item.isNew) return;
    this.setState({ focusedDetailEarlyInspection2: item });
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;

    const data2 = await api.fxExec(
      'dw_2_new',
      {
        evcd: this.state.searchEvcd,
        gresultcd: this.state.focusedEarlyInspection?.gresultcd,
      },
    );

    const one = new EarlyInspectionModel2({
      ...data2,
      resultcd: this.state.data2.length ? `00${Number(this.state.data2[this.state.data2.length - 1].resultcd) + 1}`.substr(-2) : '01',
    }, true);

    data2 && this.setState({
      data2: [
        ...this.state.data2,
        one,
      ],
      focusedDetailEarlyInspection: new EarlyInspectionModel2(data2, true),
    }, async () => {
      await this.tableDetail.current?.update(true);
      this.tableDetail.current?.setFocus(this.state.data2.length - 1);
    });
  }

  @action
  async onNewEvent3() {
    const { actionStore: api } = this.props;
    const data3 = await api.fxExec(
      'dw_3_new',
      {
        evcd: this.state.searchEvcd,
        gresultcd: this.state.focusedDetailEarlyInspection?.gresultcd,
        resultcd: this.state.focusedDetailEarlyInspection2?.resultcd,
      },
    );

    const one = new EarlyInspectionModel3({
      ...data3,
      seq: this.state.data3.length ? `00${Number(this.state.data3[this.state.data3.length - 1].seq) + 1}`.substr(-2) : '01',
    }, true);

    data3 && this.setState({
      data3: [
        ...this.state.data3,
        one,
      ],
      focusedDetailEarlyInspection2: new EarlyInspectionModel3(data3, true),
    }, async () => {
      await this.tableDetail2.current?.update(true);
      this.tableDetail2.current?.setFocus(this.state.data3.length - 1);
    });
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focusedDetailEarlyInspection;

    if (focusedItem) {
      const text = `순서: ${this.state.focusedDetailEarlyInspection?.resultcd
      }, 검사항목: ${this.state.focusedDetailEarlyInspection?.resultnm}`;
      const text2 = '우측 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;
        if (await ConfirmDelete.show(text2)) {
          await api.fxExec(
            'dw_2_delete',
            {
              evcd: this.state.searchEvcd,
              gresultcd: this.state.focusedDetailEarlyInspection?.gresultcd,
              resultcd: this.state.focusedDetailEarlyInspection2?.resultcd,
            },
          );
          this.onRetrieveEvent();
          ConfirmSuccess.show('삭제', `${focusedItem.resultnm}를 삭제하였습니다.`);
        }
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  async dw_3_delete() {
    const focusedItem = this.state.focusedDetailEarlyInspection2;

    if (focusedItem) {
      const text = `순서: ${this.state.focusedDetailEarlyInspection2?.seq
      }, 검사항목.장치: ${this.state.focusedDetailEarlyInspection2?.seqnm}`;
      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;
        await api.fxExec(
          'dw_3_delete',
          {
            evcd: this.state.searchEvcd,
            gresultcd: this.state.focusedDetailEarlyInspection?.gresultcd,
            resultcd: this.state.focusedDetailEarlyInspection2?.resultcd,
            seq: this.state.focusedDetailEarlyInspection2?.seq,
          },
        );
        this.onRetrieveEvent();
        ConfirmSuccess.show('삭제', `${focusedItem.seqnm}를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<EarlyInspectionModel>, updatedRows: Array<EarlyInspectionModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows2      전체 행 (변경 행 반영된 상태)
   * @param updatedRows2 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<EarlyInspectionModel2>, updatedRows2: Array<EarlyInspectionModel2>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ data2: rows2 });
  }

  /**
   * 행 변경 이벤트
   * @param rows3        전체 행 (변경 행 반영된 상태)
   * @param updatedRows3 변경 행들만
   */
  @action
  onUpdatedRows3(rows3: Array<EarlyInspectionModel3>, updatedRows3: Array<EarlyInspectionModel3>) {
    this.updatedRows3 = updatedRows3;
    this.setState({ data3: rows3 });
  }

  render() {
    return (
      <EarlyInspectionTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...this.state.data,
            ...this.state.data2,
            ...this.state.data3,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
