import * as React from 'react';
import { observer } from 'mobx-react';
import { FlexLayout, ModalView } from '../../components';
import { ModalStore } from '../../stores';

interface ModalAdviceProps {
  modalStore: ModalStore;
}

@observer
export class ModalAdvice extends React.Component<ModalAdviceProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={677}
        height={540}
        style={{
          minHeight: 540,
        }}
        isVisible={modalStore.isVisibleModalAdvice}
        onClose={() => { modalStore.isVisibleModalAdvice = false; }}
      >
        <FlexLayout
          isVertical={true}
          style={{
            background: 'url(https://api.elmansoft.com/img/elmanpro-force-update.png) no-repeat',
          }}
        />
      </ModalView>
    );
  }
}
