import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_popup_wakeup
 * @category 개별알림
 */
export class IndividualModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 구분
   *
   * **data type** char(50)
   */
  gubunnm: string;

  /**
   * 구분
   *
   * **data type** char(50)
   */
  gubun: string;

  /**
   * 건수
   *
   * **data type** number
   */
  cnt: string;

  /**
   * 분류
   *
   * **data type** char(1)
   */
  flag: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(?)
   */
  equpnm: string;

  /**
   * -
   *
   * **data type** varchar(?)
   */
  scokflag: string;

  /**
   * 확인자
   *
   * **data type** varchar(?)
   */
  scokpernm: string;

  /**
   * 담당자
   *
   * **data type** varchar(?)
   */
  pernm: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(?)
   */
  scactnm: string;

  /**
   * 납기일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 무상시작일
   *
   * **data type** varchar(8)
   */
  contstdate: string;

  /**
   * 무상만료일
   *
   * **data type** varchar(8)
   */
  contenddate: string;

  /**
   * 답변만료일
   *
   * **data type** varchar(8)
   */
  wakedate: string;

  /**
   * 내용
   *
   * **data type** varchar(?)
   */
  remark: string;

  /**
   * 내용
   *
   * **data type** varchar(?)
   */
  actcd: string;

  /**
   * 종료일자
   *
   * **data type** varchar(?)
   */
  haja_enddate: string;

  /**
   * 시작일자
   *
   * **data type** varchar(?)
   */
  haja_stdate: string;

  /**
   * 자동이체번호
   *
   * **data type** varchar(?)
   */
  autonum: string;

  /**
   * 전송오류메시지
   *
   * **data type** varchar(?)
   */
  error_message: string;

  /**
   * 점검계획일
   *
   * **data type** varchar(?)
   */
  plandate: string;

  /**
   * 점검계획일
   *
   * **data type** varchar(?)
   */
  kcdate: string;

  /**
   * -
   *
   * **data type** varchar(?)
   */
  cltnm: string;

  /**
   * -
   *
   * **data type** varchar(?)
   */
  cltcd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.error_message = data.error_message || '';
    this.plandate = data.plandate || '';
    this.cltnm = data.cltnm || '';
    this.cltcd = data.cltcd || '';
    this.kcdate = data.kcdate || '';
    this.haja_enddate = data.haja_enddate || '';
    this.autonum = data.autonum || '';
    this.haja_stdate = data.haja_stdate || '';
    this.spjangcd = data.spjangcd || '';
    this.remark = Fix.newline(data.remark) || '';
    this.wakedate = data.wakedate || '';
    this.scokflag = data.scokflag || '';
    this.contenddate = data.contenddate || '';
    this.contstdate = data.contstdate || '';
    this.enddate = data.enddate || '';
    this.scactnm = data.scactnm || '';
    this.pernm = data.pernm || '';
    this.scokpernm = data.scokpernm || '';
    this.spjangnm = data.spjangnm || '';
    this.gubunnm = data.gubunnm || '';
    this.gubun = data.gubun || '';
    this.cnt = data.cnt || '';
    this.flag = data.flag || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
