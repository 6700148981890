import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox, ComboBoxModel, DateTextBox, FlexLayout, GridLayout, SearchLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Process } from './Process';
import { Date6 } from '../../../../utils/time';
import { Gubuns, ScGubuns } from '../Collect/Collect';
import { ProcessGrid } from './headers/ProcessGrid';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_e601w_sulchi_03
 * @category 설치진행현황
 */
export const ProcessTemplate: React.FC<TemplateProps<Process>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>검색년도</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.stmon}
            onChange={(v) => scope.setState({ stmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ stmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ stmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={170}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.endmon}
            onChange={(v) => scope.setState({ endmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ endmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ endmon: Date6.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span>설치구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={ScGubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.contflag}
            textAlign="center"
            data={Gubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ contflag: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 프로젝트로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={190}>
          <span>미처리내역</span>
          <ComboBox
            value={scope.state.contflag}
            textAlign="center"
            data={Gubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ contflag: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>계약자</span>
          <TextBox
            value={scope.state?.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state?.pernm}
            textAlign="center"
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          header={ProcessGrid(scope)}
          rowHeight={250}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          headerHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
