import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { SalesStateModel } from './SalesState.model';
import { GridLayout, TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { SalesStateTemplate } from './SalesState.template';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import Confirm from '../../../../utils/confirm/Confirm';

interface SalesStateState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;
  resuck: string;
  billgubun: string;
  cancleflag: string;
  rcvflag: string;
  wkactcd: string;
  wkactnm: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  billkind: string;
  recivstatus: string;

  // data
  openChk: Array<SalesStateModel>;
  data: Array<SalesStateModel>;
  focused?: SalesStateModel;
  pushTotalCheck: boolean;

  // trail
  amt_tot: string;
  addamt_tot: string;
  total: string;
  misamt_tot: string;
  rcvamt_tot: string;
  iamt_tot: string;
  qty_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e050w
 * @category 매출현황
 */
export class SalesState extends PageComponent<PageProps, SalesStateState>
  implements PageToolEvents {
  updatedRows?: Array<SalesStateModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}01`,
      enddate: date === '30' ? `${year}${month}30` : `${year}${month}31`,
      searchQuery: '',
      resuck: '1',
      billgubun: '%',
      cancleflag: '0',
      rcvflag: '%',
      wkactcd: '',
      divicd: '',
      perid: '',
      billkind: '%',
      recivstatus: '%',

      // data
      pushTotalCheck: true,
      data: [],

      // trail
      amt_tot: '0',
      addamt_tot: '0',
      total: '0',
      misamt_tot: '0',
      rcvamt_tot: '0',
      iamt_tot: '0',
      qty_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 왼쪽 리스트
    const data = await api.dropdown('wf_da023_code');
    if (!data) return;
    this.setState({ openChk: data.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        billkind: this.state.billkind,
        billgubun: this.state.billgubun,
        perid: this.state.perid,
        wkactcd: this.state.wkactcd,
        divicd: this.state.divicd,
        recivstatus: this.state.recivstatus,
        cancleflag: this.state.cancleflag,
        resuck: this.state.resuck === '1' ? '1' : '%',
        rcvflag: this.state.rcvflag,
        items: this.state.openChk,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new SalesStateModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity2?.retrieveTo(['actcd', 'cltcd'],
        [this.state.focused?.actcd, this.state.focused?.cltcd], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
      }
      this.SS({
        amt_tot: this.infinity2?.box?.amt_tot || '0',
        addamt_tot: this.infinity2?.box?.addamt_tot || '0',
        total: this.infinity2?.box?.total || '0',
        misamt_tot: this.infinity2?.box?.misamt_tot || '0',
        rcvamt_tot: this.infinity2?.box?.rcvamt_tot || '0',
        iamt_tot: this.infinity2?.box?.iamt_tot || '0',
        qty_tot: this.infinity2?.box?.qty_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      billkind: this.state.billkind,
      billgubun: this.state.billgubun,
      perid: this.state.perid,
      wkactcd: this.state.wkactcd,
      divicd: this.state.divicd,
      recivstatus: this.state.recivstatus,
      cancleflag: this.state.cancleflag,
      resuck: this.state.resuck === '1' ? '1' : '%',
      items: this.state.openChk,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      billkind: this.state.billkind,
      billgubun: this.state.billgubun,
      perid: this.state.perid,
      wkactcd: this.state.wkactcd,
      divicd: this.state.divicd,
      recivstatus: this.state.recivstatus,
      cancleflag: this.state.cancleflag,
      resuck: this.state.resuck === '1' ? '1' : '%',
      items: this.state.openChk,
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck: checked,
      openChk: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  async onClickOpenPage() {
    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인', '해당매출내역으로 넘어가시겠습니까? ', ConfirmType.QUESTION);
    if (!warning) return;

    // @ts-ignore
    const data = this.state.focused?.misdate + this.state.focused?.misnum;

    this.props.publicStore.go(
      '/w_tb_da023',
      {
        projno: data,
        misdate: this.state.focused?.misdate,
      },
    );
  }

  @action
  onRowFocusEvent(item: SalesStateModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<SalesStateModel>, updatedRows: Array<SalesStateModel>) {
    this.updatedRows = updatedRows;
    this.setState({ openChk: rows });
  }

  render() {
    return (
      <SalesStateTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
