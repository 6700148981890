import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PurchasingStatistics } from './PurchasingStatistics';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TabLayout,
  TextBox,
} from '../../../../components';
import { PurchasingStatisticsGridHeader } from './headers/PurchasingStatisticsGridHeader';
import { PurchasingStatisticsTab } from './PurchasingStatistics.tab';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { Sum } from '../../../../utils/array';


/**
 * 화면
 * @window w_tb_ca642w_14
 * @category 매입통계내역
 */

export const PurchasingStatisticsTemplate: React.FC<TemplateProps<PurchasingStatistics>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          isDisabledTrackingStateChange={true}
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={600}>
        <span>검색어</span>
        <TextBox
          style={{ marginLeft: 11 }}
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        >
        </TextBox>
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout
        onClick={() => scope.initTopGridMons()}
      >
        <GridLayout
          ref={scope.grid}
          header={PurchasingStatisticsGridHeader(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          onRowClick={(item) => scope.onRowFocusEvenet(item)}
        />
      </FlexLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={PurchasingStatisticsTab}
          onChange={(item, index) => scope.onTabChange(item, index)}
        />
      </FlexLayout>
    </FlexLayout>

    <ModalView
      width={1400}
      height={600}
      isVisible={scope.state.modal}
      onClose={() => scope.closeModal(false)}
      zIndex={-2}
    >
      <FlexLayout isVertical={true} style={{ padding: 10 }}>
        <LayoutTitle>년 매입통계내역 [{scope.state.modalTopTitles}]</LayoutTitle>

        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={642}>
            <span>검색어</span>
            <TextBox
              value={scope.state.popupSearchQuery}
              onChange={(value) => scope.setState({ popupSearchQuery: value })}
              onEnter={() => scope.modalRetrieve()}
            >
            </TextBox>
          </FlexLayout>
          <FlexLayout size={200}>
            <span>지급여부</span>
            <ComboBox
              value={scope.state.mijchk}
              textAlign="center"
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '미지급' },
                { value: '1', remark: '지급' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => scope.setState({ mijchk: option.value }, () => scope.modalRetrieve())}
            />
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.modalGrid}
            header={[
              {
                id: 'mijdate',
                text: '발생일자',
                width: 3,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDashMon(x.mijdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
              },
              scope.state.tabNames !== 'cltcd'
                ? {
                  id: 'year',
                  text: '월별그룹',
                  isHidden: true,
                  group: true,
                  value: (x) => Date8.withDashMon(x.mijdate),
                } : {
                  id: 'year',
                  text: '월별그룹',
                  isHidden: true,
                  group: false,
                  value: (x) => Date8.withDashMon(x.mijdate),
                },
              {
                id: 'mijnum',
                text: '번호',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mijnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'artnm',
                text: '항목명',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.artnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumRemark = Sum.groupLength(data, 'year', x, ['year']);
                  return (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      건수 {sumRemark}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    건수 {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '발생금액',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumMijamt = Sum.byGroup(data, 'mijamt', x, ['year']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMijamt)}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.mijamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'sndamt',
                text: '지급금액',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.sndamt)}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumSndamt = Sum.byGroup(data, 'sndamt', x, ['year']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#60C5F1' }}
                    >
                      {Format.number(sumSndamt)}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#60C5F1' }}
                  >
                    {Format.number(scope.state.sndamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '미지급금액',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumIamt = Sum.byGroup(data, 'iamt', x, ['year']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#FF00FF' }}
                    >
                      {Format.number(sumIamt)}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#FF00FF' }}
                  >
                    {Format.number(scope.state.iamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 8,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '증빙구분',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.gubuns?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.modalData}
            infinityHandler={scope.modalInfinity}
          />
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onPopupExcelEvent()}>
              EXCEL DOWNLOAD
            </Button>
            <Button>
              닫기
            </Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>

    <ModalView
      width={580}
      height={145}
      isVisible={scope.state.excelModalVisible}
      onClose={() => scope.setState({ excelModalVisible: false, excelPopupGubun: '1' })}
      zIndex={-2}
    >
      <FlexLayout isVertical={true} style={{ padding: 10 }}>
        <LayoutTitle>선택</LayoutTitle>
          <FlexLayout>
            <OptionBox
              value={scope.state.excelPopupGubun}
              data={[
                { value: '1', remark: '사업장별' },
                { value: '2', remark: '10년간' },
                { value: '3', remark: '월별' },
                { value: '4', remark: '거래처별' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onChange={(option) => scope.SS({ excelPopupGubun: option.value.toString() })}
            >
            </OptionBox>
          </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.tryExcelEvent()}>확인</Button>
            <Button onClick={() => scope.SS({ excelModalVisible: false, excelPopupGubun: '1' })}>취소</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);


