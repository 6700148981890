import { FlexLayout } from '../../../../../components';
import { GridLayoutHeader } from '../../../../../constants';
import { ProductModel } from '../models';
import { ElevatorInfo } from '../ElevatorInfo';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';

const compyns = [
  { value: '0', remark: '견적' },
  { value: '1', remark: '공사' },
  { value: '2', remark: '매출' },
  { value: '3', remark: '폐기' },
  { value: '5', remark: '수금' },
  { value: ' ', remark: '견적전' },
];

// 담당자이력정보
const Product: GridLayoutHeader<ProductModel, ElevatorInfo>[] = [
  {
    id: 'compdate',
    text: '발생일자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.compdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'compnum',
    text: '번호',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.compnum}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
      </FlexLayout>
    ),
  },
  {
    id: 'offinm',
    text: '공사명',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.offinm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="left"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'pserial',
    text: '품목시리얼번호',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pserial}
      </FlexLayout>
    ),
  },
  {
    id: 'ibgdate',
    text: '입고일자',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.ibgdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'ibgnum',
    text: '번호',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.ibgnum}
      </FlexLayout>
    ),
  },
  {
    id: 'deldate',
    text: '출고일자',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.deldate)}
      </FlexLayout>
    ),
  },
  {
    id: 'delnum',
    text: '번호',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.delnum}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'compamt',
    text: '공사금액',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.compamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'compyn',
    text: '상태',
    width: 130,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.compyn === '2'
            ? 'var(--color-blue)' : x.compyn === '5'
              ? '#7F7A2C' : x.compyn === '9'
                ? 'var(--color-red)' : x.compyn === '1'
                  ? '#7F7A2C' : '',
        }}
      >
        {compyns.filter((y) => y.value === x.compyn)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'comppernm',
    text: '처리자',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.comppernm}
      </FlexLayout>
    ),
  },
];

export default Product;
