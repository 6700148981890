import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Journal } from './Journal';
import { JournalTableHeader } from './headers/JournalTableHeader';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { JournalGridHeader } from './headers/JournalGridHeader';
import { JournalDetailTableHeader } from './headers/JournalDetailTableHeader';
import { AttachmentBox } from './components/AttachmentBox';

/**
 * 화면
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export const JournalTemplate: React.FC<TemplateProps<Journal>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={180}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value })}
          />
        </FlexLayout>

        <FlexLayout>
          <span>구분</span>
          <ComboBox
            value={scope.state.state}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '미처리' },
              { value: '2', remark: '처리' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ state: option.value })}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>담당자</span>
          <TextBox
            textAlign="center"
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>현장</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <Button
          onClick={() => scope.onSubmitButtonClicked()}
          style={scope.state.tableFocusItem?.appgubun === '101' ? {
            opacity: 0.5,
          } : {}}
        >
          {scope.state.tableFocusItem?.isReported ? '상신취소' : '결재상신'}
        </Button>

        <Button onClick={() => scope.onEmailButtonClicked()}>
          EMAIL
        </Button>
      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          scope={scope}
          header={JournalTableHeader(scope)}
          data={scope.state.data}
          rowHeight={68}
          headerHeight={62}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
          onChange={(rows, updateRows) => scope.onTableChange(rows, updateRows)}
        />
      </FlexLayout>


      {/* 첨부파일 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            custcd={scope.tableFocus?.custcd || ''}
            spjangcd={scope.tableFocus?.spjangcd || ''}
            actcd={scope.tableFocus?.actcd || ''}
            equpcd={scope.tableFocus?.equpcd || ''}
            seq={scope.tableFocus?.seq || ''}
            isEnabledTitle={true}
            isEnabledPreview={true}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 사전점검 */}
      <ModalView
        width={1000}
        isVisible={scope.state.isVisibleGrid}
        onClose={() => setData({ isVisibleGrid: false })}
        zIndex={-3}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>{scope.tableFocus?.actnm} {scope.tableFocus?.equpnm} 승강기의 사전점검일지</LayoutTitle>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            align="center"
            justify="end"
            style={{
              color: 'var(--color-violet)',
            }}
          >
            * 결과(상태변경)를 입력하시려면 더블클릭하세요!
          </FlexLayout>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.searchQueryGrid}
                onChange={(v) => scope.SS({ searchQueryGrid: v })}
                onEnter={() => scope.retrieveGrid()}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              data={scope.state.dataGrid}
              header={JournalGridHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowClick={(_, index) => scope.onGridRowFocusEvent(index)}
              onRowDoubleClick={(_, index) => scope.onGridRowFocusEvent(index, true)}
              rowColor={(item) => scope.getGridRowBackgroundColor(item)}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleGrid: false })}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>


      {/* 사전점검 상세 */}
      <ModalView
        width={800}
        isVisible={scope.state.isVisibleDetail}
        onClose={() => setData({ isVisibleDetail: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>★ {scope.gridFocus?.gkccd} {scope.gridFocus?.resultnm}</LayoutTitle>

          <FlexLayout>
            <TableLayout
              ref={scope.tableDetail}
              data={scope.state.dataDetail}
              header={JournalDetailTableHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowFocusEvent={(item, index) => scope.onDetailRowFocusEvent(item, index)}
              onChange={(rows) => scope.onTableDetailChange(rows)}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onDetailSaveEvent()}
            >
              저장
            </Button>

            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleDetail: false })}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
