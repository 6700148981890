export default class Time4 {
  static make(date?: Date): string {
    const now = date || new Date();
    const hours = now.getHours();
    const mins = now.getMinutes();
    return `${hours < 10 ? `0${hours}` : hours}${
      mins < 10 ? `0${mins}` : mins
    }`;
  }

  static withDash(time?: Date | string): string {
    const str = typeof time === 'string' ? time : Time4.make(time);
    return `${str.substr(0, 2)}:${str.substr(2, 2)}`;
  }

  static withDash2(time?: Date | string): string {
    const str = typeof time === 'string' ? time : Time4.make(time);
    const hour = parseInt(str.substr(0, 2), 10);
    return `${hour > 11 ? hour - 12 : hour}:${str.substr(2, 2)}`;
  }

  static aMpM(time?: Date): string {
    const now = time || new Date();
    return now.getHours() > 11 ? 'P.M.' : 'A.M.';
  }

  static withKor(time?: Date | string): string {
    const str = typeof time === 'string' ? time : Time4.make(time);
    return `${str.substr(0, 2)}시 ${str.substr(2, 2)}분`;
  }
}
