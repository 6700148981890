import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { DollarAverageTemplate } from './DollarAverage.template';
import { DollarAverageModel } from './DollarAverage.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import {
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { FileSelector } from '../../../../utils/file';
import Confirm from '../../../../utils/confirm/Confirm';

interface DollarAverageState {
  // search
  yearmon: string;
  datetime: string;
  cltcd: string;
  cltnm: string;

  // data
  data: Array<DollarAverageModel>;
  focused?: DollarAverageModel;
  focusIndex: number;
  pushTotalCheck: boolean;
  isReported: boolean; // 매입버튼

  // trail
  qty_tot: string;
  uamt_tot: string;
  samt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca640_pa105
 * @category 정기매입자료생성-유류비
 */
export class DollarAverage extends PageComponent<PageProps, DollarAverageState>
  implements PageToolEvents {
  updatedRows?: Array<DollarAverageModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  files?: any;

  newChk: string | undefined;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      datetime: `${year}${month}${date}`,
      cltcd: '',

      // data
      focusIndex: 0,
      data: [],

      // trail
      qty_tot: '0',
      uamt_tot: '0',
      samt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new DollarAverageModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['seq', 'perid'],
        [this.state.focused?.seq, this.state.focused?.perid], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 0);
        this.onRowFocusEvent(this.state.data[0], 0);
      }

      // 전체 체크
      if (this.state.data.length) {
        const dataDate = await this.infinity?.box;
        if (dataDate?.items) {
          // 버튼 여부확인
          if (dataDate.items[0]?.mijflag === '0') {
            this.setState({ isReported: true });
          } else {
            this.setState({ isReported: false });
          }
        }
      }
      this.newChk = '0';
      this.SS({
        qty_tot: this.infinity?.box?.qty_tot || '0',
        uamt_tot: this.infinity?.box?.uamt_tot || '0',
        samt_tot: this.infinity?.box?.samt_tot || '0',
      });
      await this.table.current?.update();
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      stmon: this.state.yearmon,
    });
    if (data) {
      this.setState({
        data: [
          new DollarAverageModel(data, true),
          ...this.state.data,
        ],
      }, async () => {
        await this.table.current?.update(false);
        this.table.current?.setFocus(0, 0);
        this.newChk = '1';
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    this.newChk === '1' && this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const chkData: DollarAverageModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new DollarAverageModel({
          ...x,
        }));
      }
    });

    if (chkData?.length === 0) {
      ConfirmWarning.show('경고', '삭제 선택된것이 없습니다.');
    } else {
      const text = `${this.state.focused?.usedate}선택한 내역을 삭제하시겠습니까?`;
      await api.delete(text, {
        items: chkData,
        mon: this.state.yearmon,
      }) && this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
    });
  }

  @action
  async onSubExcelEvent() {
    if (this.state.data.length > 0) {
      // eslint-disable-next-line max-len
      const warning = await Confirm.show('확인', `${this.state.yearmon}년월에 유류내역이 있습니다 삭제하고 다시 불러오시겠습니까?`, ConfirmType.QUESTION);
      if (warning === false) return;
    }

    try {
      const files = await FileSelector.single(false);
      this.excelUpload(files[0].name, files[0]);
      this.files = files;
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async excelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('정기매입자료생성-유류비', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      this.onRetrieveEvent();
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    if (await api.exec(
      Category.GENERAL,
      'wb_excel_lazytask',
      {
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        stmon: this.state.yearmon,
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    )) {
      ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      await this.onRetrieveEvent();
    }
  }

  @action
  onRowFocusEvent(item: DollarAverageModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<DollarAverageModel>, updatedRows: Array<DollarAverageModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new DollarAverageModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onBtnEvent() {
    const { actionStore: api } = this.props;

    if (!this.state.cltcd) {
      ConfirmWarning.show('확인', '거래처명을 입력해주세요');
      return;
    }

    let warning: boolean;
    if (this.state.yearmon.slice(4, 6) !== this.state.datetime.slice(4, 6)) {
      warning = await Confirm.show('확인', '처리하시려는 유류비월과 매입처리월이 다른것 맞습니까?', ConfirmType.QUESTION);
      if (warning === false) return;
    }

    if (this.state.isReported === false) {
      await api.fxExec('wb_mijdelete',
        {
          mijflag: this.state.focused?.mijflag2,
          sndamt: this.state.focused?.sndamt,
          seq: this.state.focused?.seq,
          mijdate: this.state.datetime,
          stmon: this.state.yearmon,
          mijnum: this.state.focused?.mijnum,
        });
    } else {
      await api.fxExec('wb_mijsave',
        {
          mijdate: this.state.datetime,
          mijflag: this.state.focused?.mijflag2,
          stmon: this.state.yearmon,
          cltcd: this.state.cltcd,
          cltnm: this.state.cltnm,
        });
    }
    this.onRetrieveEvent();
  }

  render() {
    return (
      <DollarAverageTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
