import { action } from 'mobx';
import React, { RefObject } from 'react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import DrawingModel from './models/DrawingModel';
import { DrawingTemplate } from './Drawing.template';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';

interface DrawingState {
  searchQuery: string;
  searchFilenm: string;
  data: Array<DrawingModel>;
  focusIndex: number;
  content: DrawingModel;
  iframe?: string;
  preview: number;
}

/**
 * 컨트롤러
 * @window w_tb_e601_dp_01
 * @category 설치현장도면
 */
export class Drawing extends PageComponent<PageProps, DrawingState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      data: [],
      focusIndex: 0,
      preview: 0,
      content: new DrawingModel(),
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery || '',
        filenm: this.state.searchFilenm || '',
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new DrawingModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.state.data.length > 0 && this.onRowFocusEvent(this.state.data[0], 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('actcd', this.state.content?.actcd, type, true) || 0;
      this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  async onRowFocusEvent(item: DrawingModel, index: number) {
    this.setState({
      content: item,
      focusIndex: index,
    });
  }

  render() {
    return <DrawingTemplate
      scope={this}
    />;
  }
}
