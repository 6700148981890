import { computed } from 'mobx';

/**
* 모델
* @window w_tb_da038_01
* @category 반납등록
*/
export class ReturnRegistrationModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly taxcls: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly setcls: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly domcls: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly taxflag: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly taxdate: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly taxnum: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly selflag: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly serinum: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly sgmdate: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly delnum: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly compperid: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly compdivicd: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * ( 모름 )
   *
   * **data type** varchar(8)
   */
  readonly compflag: string;

  /**
   * 반납일자
   *
   * **data type** varchar(8)
   */
  readonly bandate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly bannum: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly bantype: string;

  /**
   * 반납자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 제목(비고)
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 반납일자 (오른쪽 리스트)
   *
   * **data type** varchar(8)
   */
  readonly datetime: string;

  /**
   * 창고코드
   *
   * **data type** varchar(8)
   */
  readonly store: string;

  /**
   * 창고명
   *
   * **data type** varchar(8)
   */
  readonly storenm: string;

  /**
   * 작성자 id
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 작성자 이름
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 반납자 id
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 수령자 id
   *
   * **data type** varchar(8)
   */
  readonly receperid: string;

  /**
   * 수령자이름
   *
   * **data type** varchar(8)
   */
  readonly recepernm: string;

  /**
   * 특이사항[반납사유]
   *
   * **data type** varchar(8)
   */
  readonly bansayoo: string;

  /**
   * (모름)
   *
   * **data type** varchar(8)
   */
  readonly pumnum: string;

  /**
   * (모름)
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.taxcls = data.taxcls || '';
    this.setcls = data.setcls || '';
    this.domcls = data.domcls || '';
    this.taxflag = data.taxflag || '';
    this.taxdate = data.taxdate || '';
    this.taxnum = data.taxnum || '';
    this.selflag = data.selflag || '';
    this.serinum = data.serinum || '';
    this.sgmdate = data.sgmdate || '';
    this.state = data.state || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.compperid = data.compperid || '';
    this.compdivicd = data.compdivicd || '';
    this.compdate = data.compdate || '';
    this.compflag = data.compflag || '';
    this.bandate = data.bandate || '';
    this.bannum = data.bannum || '';
    this.bantype = data.bantype || '';
    this.pernm = data.pernm || '';
    this.remark = data.remark || '';
    this.datetime = data.datetime || '';
    this.store = data.store || '';
    this.storenm = data.storenm || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.indate = data.indate || '';
    this.perid = data.perid || '';
    this.receperid = data.receperid || '';
    this.recepernm = data.recepernm || '';
    this.bansayoo = data.bansayoo || '';
    this.pumnum = data.pumnum || '';
    this.pumdate = data.pumdate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}

