import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_pb405
 * @category 공문서
 */

export class DiplomaModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  readonly paperdate: string

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  readonly papernum: string

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string

  /**
   * 경유
   *
   * **data type** varchar(255)
   */
  readonly gyungyu: string

  /**
   * 수신
   *
   * **data type** varchar(255)
   */
  readonly susin: string

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly subject: string

  /**
   * 내용
   *
   * **data type** varchar(0)
   */
  readonly remark: string

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  readonly recedate: string

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  readonly recenum: string

  /**
   * 부서
   *
   * **data type** varchar(13)
   */
  readonly divicd: string

  /**
   * 부서명
   *
   * **data type** varchar(100)
   */
  readonly divinm: string

  /**
   * 인사코드
   *
   * **data type** varchar(13)
   */
  perid: string

  /**
   * 작성자
   *
   * **data type** varchar(100)
   */
  pernm: string

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string

  /**
   * 처리과
   *
   * **data type** varchar(100)
   */
  readonly actdivinm: string

  /**
   * 담당자
   *
   * **data type** varchar(100)
   */
  readonly actpernm: string

  /**
   * 파일이름
   *
   * **data type** varchar(255)
   */
  readonly filename: string

  /**
   * 파일이름2
   *
   * **data type** varchar(0)
   */
  readonly filename2: string

  /**
   * 첨부파일
   *
   * **data type** number(0)
   */
  readonly attcnt: number;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly spjangnm: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly zipcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly adres: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly adresa: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly adresb: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tel1: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly fax: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly title: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.paperdate = data.paperdate || '';
    this.papernum = data.papernum || '';
    this.appnum = data.appnum || '';
    this.gyungyu = data.gyungyu || '';
    this.susin = data.susin || '';
    this.refer = Fix.newline(data.refer) || '';
    this.subject = data.subject || '';
    this.remark = Fix.newline(data.remark);
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.actdivinm = data.actdivinm || '';
    this.actpernm = data.actpernm || '';
    this.filename = data.filename || '';
    this.filename2 = data.filename2 || '';
    this.attcnt = data.attcnt || 0;
    this.spjangnm = data.spjangnm || '';
    this.zipcd = data.zipcd || '';
    this.adresa = data.adresa || '';
    this.adresb = data.adresb || '';
    this.adres = data.adres || '';
    this.tel1 = data.tel1 || '';
    this.fax = data.fax || '';
    this.appgubun = data.appgubun || '';
    this.title = data.title || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
