import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { CallDivisionTemplate } from './CallDivision.template';
import { CallDivisionModel } from './CallDivision.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface CallDivisionState {
  searchQuery: string;
  data: Array<CallDivisionModel>;
  focused?: CallDivisionModel;
}

/**
 * 컨트롤러
 * @window w_tb_e902
 * @category 전화구분등록
 */
export class CallDivision extends PageComponent<PageProps, CallDivisionState>
  implements PageToolEvents {
  updatedRows?: Array<CallDivisionModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  // save, delete 시 index 값 주기위한 변수
  lastIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new CallDivisionModel(x))],
        }, async () => {
          this.table.current?.update();
          this.table.current?.setFocus(this.lastIndex, 0);
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, () => this.infinity?.retrieve());
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [...this.state.data, new CallDivisionModel(data, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.data.length - 1, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `전화구분코드: ${this.state.focused?.telcd} 전화구분명: ${this.state.focused?.telnm}`;

    const result = await api.delete(text, this.state.focused);

    // 삭제 시 위에 행으로 focus
    if (result && this.state.data.length > 0) {
      this.lastIndex -= 1;
    }
    await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: CallDivisionModel, index: number) {
    this.lastIndex = index;
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<CallDivisionModel>, updatedRows: Array<CallDivisionModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <CallDivisionTemplate scope={this} />;
  }
}
