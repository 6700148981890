/**
 * 컨트롤러
 * @window w_tb_ca642w_16
 * @category 업종별 매출대비매입요율
 */
import { action } from 'mobx';
import * as React from 'react';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import { IndustryRateTemplate } from './IndustryRate.template';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components';
import { IndustryRateModel } from './IndustryRate.model';
import { IndustryRateModalModel } from './IndustryRateModal.model';

export interface IndustryRateState {
  // 조회 조건
  year: string;
  gubun: string;

  // List
  industryData: Array<IndustryRateModel>;

  // List focus Data
  focusedData: IndustryRateModel;

  // List trail
  wkmisamttot_tot: string;
  wkmijamttot_tot: string;
  monyultot_tot: string;
  wkmisamt0_tot: string;
  wkmijamt0_tot: string;
  monyul0_tot: string;
  wkmisamt1_tot: string;
  wkmijamt1_tot: string;
  monyul1_tot: string;
  wkmisamt2_tot: string;
  wkmijamt2_tot: string;
  monyul2_tot: string;
  wkmisamt3_tot: string;
  wkmijamt3_tot: string;
  monyul3_tot: string;
  wkmisamt4_tot: string;
  wkmijamt4_tot: string;
  monyul4_tot: string;
  wkmisamt5_tot: string;
  wkmijamt5_tot: string;
  monyul5_tot: string;
  wkmisamtnot_tot: string;
  wkmijamtnot_tot: string;
  monyulnot_tot: string;

  // Modal
  industryModal: boolean;
  industryModalData: Array<IndustryRateModalModel>;
  wkgubun: string;
  wkgubuns: Array<any>;
  modalStdate: string;
  modalEnddate: string;
  modalSearchQuery: string;

  // Modal trail
  cnt_tot: string;
  mijamt_tot: string;
  misamt_tot: string;
}

export class IndustryRate extends PageComponent<PageProps, IndustryRateState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  modalGrid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // 조회 조건
      year: Today.year(),
      gubun: '1', // default값 1

      // List
      industryData: [],

      // List focus Data
      focusedData: [],

      // Modal
      industryModal: false,
      industryModalData: [],
      wkgubun: '',
      wkgubuns: [],
      modalStdate: '',
      modalEnddate: '',
      modalSearchQuery: '',

      // Modal trail
      cnt_tot: '',
      mijamt_tot: '',
      misamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 업종구분
    const data = await api.dropdown('wf_dd_ca510_114_01');

    // 전체 없어서 추가함
    const dataAll = [
      { com_code: '%', com_cnam: '전체' },
      ...data?.items,
    ];
    await this.SS({
      wkgubuns: dataAll || [],
    });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        gubun: this.state.gubun,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          industryData: [...this.state.industryData, ...items],
        });
      },
      async () => {
        await this.SS({
          industryData: [],
        });

        await this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼
    await this.SS({
      industryData: [],
      focusedData: new IndustryRateModel(),
    });

    await this.infinity?.retrieveAll();

    await this.SS({
      wkmisamttot_tot: this.infinity?.box?.wkmisamttot_tot || '0',
      wkmijamttot_tot: this.infinity?.box?.wkmijamttot_tot || '0',
      monyultot_tot: this.infinity?.box?.monyultot_tot || '0',
      wkmisamt0_tot: this.infinity?.box?.wkmisamt0_tot || '0',
      wkmijamt0_tot: this.infinity?.box?.wkmijamt0_tot || '0',
      monyul0_tot: this.infinity?.box?.monyul0_tot || '0',
      wkmisamt1_tot: this.infinity?.box?.wkmisamt1_tot || '0',
      wkmijamt1_tot: this.infinity?.box?.wkmijamt1_tot || '0',
      monyul1_tot: this.infinity?.box?.monyul1_tot || '0',
      wkmisamt2_tot: this.infinity?.box?.wkmisamt2_tot || '0',
      wkmijamt2_tot: this.infinity?.box?.wkmijamt2_tot || '0',
      monyul2_tot: this.infinity?.box?.monyul2_tot || '0',
      wkmisamt3_tot: this.infinity?.box?.wkmisamt3_tot || '0',
      wkmijamt3_tot: this.infinity?.box?.wkmijamt3_tot || '0',
      monyul3_tot: this.infinity?.box?.monyul3_tot || '0',
      wkmisamt4_tot: this.infinity?.box?.wkmisamt4_tot || '0',
      wkmijamt4_tot: this.infinity?.box?.wkmijamt4_tot || '0',
      monyul4_tot: this.infinity?.box?.monyul4_tot || '0',
      wkmisamt5_tot: this.infinity?.box?.wkmisamt5_tot || '0',
      wkmijamt5_tot: this.infinity?.box?.wkmijamt5_tot || '0',
      monyul5_tot: this.infinity?.box?.monyul5_tot || '0',
      wkmisamtnot_tot: this.infinity?.box?.wkmisamtnot_tot || '0',
      wkmijamtnot_tot: this.infinity?.box?.wkmijamtnot_tot || '0',
      monyulnot_tot: this.infinity?.box?.monyulnot_tot || '0',
    });

    await this.grid.current?.setFocus(0);
  }

  @action
  async onRowFocusEvent(item: IndustryRateModel) {
    await this.SS({
      focusedData: new IndustryRateModel(item, item?.isNew),
    });
  }

  @action
  async onRowDoubleCliked(isOpen: boolean, wkgubuns: string) {
    await this.SS({
      industryModal: isOpen,
      wkgubun: wkgubuns === 'tot' ? '%' : wkgubuns,
    });

    await this.onModalRetrieveEvent();
  }

  @action
  async onModalRetrieveEvent() {
    const { actionStore: api } = this.props;

    let lastdate = '';
    let stmon = '';
    let endmon = '';

    if (this.state.gubun === '1') {
      // 해당 월에 대한 말일
      lastdate = String(new Date(Number(this.state.year), Number((this.state.focusedData?.mon)?.substr(4, 2)), 0).getDate());
      stmon = `${this.state.focusedData?.mon}01`;
      endmon = `${this.state.focusedData?.mon}${lastdate}`;
    } else if (this.state.gubun === '2') {
      // 해당 분기에 대한 말일
      // ex) 20230101 ~ 20230331 (3달 단위로)
      let smon = '';
      let emon = '';
      if (this.state.focusedData?.bungi === '1분기') {
        smon = '01';
        emon = '03';
      } else if (this.state.focusedData?.bungi === '2분기') {
        smon = '04';
        emon = '06';
      } else if (this.state.focusedData?.bungi === '3분기') {
        smon = '07';
        emon = '09';
      } else if (this.state.focusedData?.bungi === '4분기') {
        smon = '10';
        emon = '12';
      }
      // 시작월 + 2달의 말일
      lastdate = String(new Date(Number(this.state.year), Number(emon), 0).getDate());
      stmon = `${this.state.year}${smon}01`;
      endmon = `${this.state.year}${emon}${lastdate}`;
    } else if (this.state.gubun === '3') {
      // 해당 반기에 대한 말일
      // ex) 20230101 ~ 20230631 (6달 단위로)
      let smon = '';
      let emon = '';
      if (this.state.focusedData?.bangi === '전반기') {
        smon = '01';
        emon = '06';
      } else if (this.state.focusedData?.bangi === '후반기') {
        smon = '07';
        emon = '12';
      }

      // 시작월 + 5달의 말일
      lastdate = String(new Date(Number(this.state.year), Number(emon), 0).getDate());
      stmon = `${this.state.year}${smon}01`;
      endmon = `${this.state.year}${emon}${lastdate}`;
    }

    const data = await api.retrieve({
      sub: 'w_popup_ca642w_16',
      stdate: stmon,
      enddate: endmon,
      wkgubun: this.state.wkgubun,
      as_nm: this.state.modalSearchQuery,
    });

    await this.SS({
      industryModalData: data?.items,
      modalStdate: stmon,
      modalEnddate: endmon,
    });

    // trail
    await this.SS({
      cnt_tot: data?.cnt_tot,
      misamt_tot: data?.misamt_tot,
      mijamt_tot: data?.mijamt_tot,
    });

    // modal focus
    await this.modalGrid?.current?.setFocus(0);
  }

  // onModalRetrieve 시에는 월, 분기, 반기 계산
  // noDoubleClickModalRetrieve 시에는 월, 분기 ,반기 계산 할필요없음.
  @action
  async noDoubleClickModalRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve({
      sub: 'w_popup_ca642w_16',
      stdate: this.state.modalStdate,
      enddate: this.state.modalEnddate,
      wkgubun: this.state.wkgubun,
      as_nm: this.state.modalSearchQuery,
    });

    await this.SS({
      industryModalData: data?.items,
    });

    // trail
    await this.SS({
      cnt_tot: data?.cnt_tot,
      misamt_tot: data?.misamt_tot,
      mijamt_tot: data?.mijamt_tot,
    });

    // modal focus
    await this.modalGrid?.current?.setFocus(0);
  }

  @action
  async onModalClose() {
    await this.SS({
      industryModal: false,
      industryModalData: [],
      wkgubun: '',
      modalStdate: '',
      modalEnddate: '',
      modalSearchQuery: '',
    });
  }

  render() {
    return (
      <IndustryRateTemplate
        scope={this}
      >
      </IndustryRateTemplate>
    );
  }
}
