import { computed } from 'mobx';

/**
 * 발주내역 모델
 * @window w_tb_da003_01w_view
 * @category 프로젝트현황
 */
export class OderModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 발주금액
   *
   * **data type** number
   */
  balamt: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.balamt = data.balamt || '';
    this.mijamt = data.mijamt || '';
    this.projno = data.projno || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
