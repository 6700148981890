import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { MonthTemplate } from './Month.template';
import { MonthModel } from './Month.model';
import { GridLayout } from '../../../../components';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve, SpjangItem } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface MonthState {
  mon: string;
  spjangcd: string;
  mpclafi: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  seal: string;
  data: Array<MonthModel>;
  focused?: MonthModel;
  spjangcds: Array<SpjangItem>;
  dateDatas: Array<any>;
  pernmData?:string;
  weekday: Array<any>;
  color: Array<any>;

  // trail
  rt001_tot: string;
  rt002_tot: string;
  atdcd01_tot: string;
  atdcd02_tot: string;
  atdcd03_tot: string;
  atdcd04_tot: string;
  atdcd05_tot: string;
  atdcd06_tot: string;
  atdcd07_tot: string;
  atdcd08_tot: string;
  atdcd09_tot: string;
  atdcd10_tot: string;
  atdcd11_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb201w_01
 * @category 월근태현황
 */
export class Month extends PageComponent<PageProps, MonthState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      spjangcd: 'ZZ',
      mon: Today.yearMon(),
      divicd: '',
      perid: '',
      divinm: '',
      pernm: '',
      data: [],
      color: [],

      rt001_tot: '0',
      rt002_tot: '0',
      atdcd01_tot: '0',
      atdcd02_tot: '0',
      atdcd03_tot: '0',
      atdcd04_tot: '0',
      atdcd05_tot: '0',
      atdcd06_tot: '0',
      atdcd07_tot: '0',
      atdcd08_tot: '0',
      atdcd09_tot: '0',
      atdcd10_tot: '0',
      atdcd11_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트 가져오기
    let data = await api.dropdown('wf_dd_spjangcd_02');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data.items });

    // 근태 상태 리스트 가져오기
    data = await api.dropdown('wf_dd_pb002');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ dateDatas: data.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.mon,
        spjangcd: this.state.spjangcd,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => (
        api.retrieve(params)
      ),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new MonthModel(x))],
        }, next);
        this.getWeekDay();
        this.getColor();
      },
      async () => {
        await this.SS({
          data: [],
          color: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      color: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['seq', 'divinm'],
        [this.state.focused?.seq, this.state.focused?.divinm], type, true) || 0;
      const data = this.infinity?.box;
      await this.SS({
        rt001_tot: data?.rt001_tot || '',
        rt002_tot: data?.rt002_tot || '',
        atdcd01_tot: data?.atdcd01_tot || '',
        atdcd02_tot: data?.atdcd02_tot || '',
        atdcd03_tot: data?.atdcd03_tot || '',
        atdcd04_tot: data?.atdcd04_tot || '',
        atdcd05_tot: data?.atdcd05_tot || '',
        atdcd06_tot: data?.atdcd06_tot || '',
        atdcd07_tot: data?.atdcd07_tot || '',
        atdcd08_tot: data?.atdcd08_tot || '',
        atdcd09_tot: data?.atdcd09_tot || '',
        atdcd10_tot: data?.atdcd10_tot || '',
        atdcd11_tot: data?.atdcd11_tot || '',
      });
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stmon: this.state.mon,
      spjangcd: this.state.spjangcd,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stmon: this.state.mon,
      spjangcd: this.state.spjangcd,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async getWeekDay() {
    for (let i = 0; i < this.state.data.length; i += 1) {
      if (this.state.perid !== '') {
        return;
      }
      if (this.state.divicd !== '') {
        return;
      }
      const list: any = Object.keys(this.state.data[i]).filter((k) =>
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        k.startsWith('weekday'))?.map((k, text) => ({ id: k, text: this.state.data[i][k] }));
      this.setState({ weekday: list });
    }
  }

  @action
  async getColor() {
    return;
    for (let i = 0; i < 31; i += 1) {
      if (this.state.weekday[i].text === '토') {
        this.setState({ color: [...this.state.color, 'var(--color-blue)'] });
      } else if (this.state.weekday[i].text === '일') {
        this.setState({ color: [...this.state.color, 'var(--color-red)'] });
      } else {
        this.setState({ color: [...this.state.color, ''] });
      }
    }
  }


  @action
  onRowFocusEvent(item: MonthModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<MonthModel>, updatedRows: Array<MonthModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <MonthTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
