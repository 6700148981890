import * as React from 'react';
import { action } from 'mobx';
import { MdReceipt, MdReorder } from 'react-icons/all';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { BreakUnitAllModel, BreakUnitMonthModel } from './models';
import { TabModel } from '../../../../models/component';
import { BreakUnitTemplate } from './BreakUnit.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { Date6 } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum BreakUnitTabId {
  All,
  Month,
}

export const BreakUnitTabTitles = [
  '전체',
  '월별',
];

export const BreakUnitTabModels = [
  BreakUnitAllModel,
  BreakUnitMonthModel,
];

export const BreakUnitTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const BreakUnitTabDataStateNames = [
  'allList',
  'monthList',
];

export const BreakUnitTabFocusedStateNames = [
  'allFocused',
  'monthFocused',
];

export interface BreakUnitState {
  allList: Array<BreakUnitAllModel>; // 전체 리스트
  monthList: Array<BreakUnitMonthModel>; // 현장별 리스트

  allFocused?: BreakUnitAllModel; // 전체
  monthFocused?: BreakUnitMonthModel;

  focusedTab?: TabModel;
  focuseddata?: any;

  // URL
  url?: string;

  // 검색조건
  searchQuery: string; // 검색어
  stmon: string; // 기간(시작)
  endmon: string; // 기간(끝)
  pernm: string; // 담당자명
  actcd: string; // 현장
  actnm: string; // 현장명
  equpcd: string; // 호기코드
  equpnm: string; // 호기명
  nflag: string;

  total: string;
  qty_tot: string;
  equptot_tot: string;
}
/**
 * 컨트롤러
 * @window w_tb_e401w_01
 * @category 호기별고장통계
 */
export class BreakUnit extends PageComponent<PageProps, BreakUnitState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  // tabHeaders: Array<Array<GridLayoutHeader>>;

  tabIndex: number = 0;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = BreakUnitTabId;
    const titles = BreakUnitTabTitles;

    this.tabs = [
      new TabModel(id.All.toString(), MdReorder, titles[id.All]),
      new TabModel(id.Month.toString(), MdReceipt, titles[id.Month]),
    ];

    const today = new Date();
    const twoYearAgo: Date = new Date(today.setFullYear(today.getFullYear() - 2));


    // state 기본값 정의
    this.state = props.state || {
      url: '',
      stmon: `${Date6.make(twoYearAgo).substr(0, 4)}01`,
      endmon: Date6.make(),
      allList: [],
      monthList: [],
      actcd: '', // 현장
      actnm: '', // 현장명
      equpcd: '', // 호기
      equpnm: '', // 호기명
      nflag: '%', // 주야간

      total: '',
      qty_tot: '',
      equptot_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[BreakUnitTabId.All], 0);
    // await this.doRetrieve(BreakUnitTabId.Day);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i, RetrieveFocusType.DEFAULT);
    await this.doRetrieve2(i, RetrieveFocusType.DEFAULT);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakUnitTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxPrint(
      `${BreakUnitTabFunctionNames[i]}_print`,
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        actcd: this.state.actcd,
      },
    );
  }

  @action
  onTabChange(focusedTab: TabModel, index: number) {
    this.tabIndex = index;
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: BreakUnitTabId, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        actcd: this.state.actcd,
      },
      (params) => api.fxExec(
        `tab_${this.tabIndex + 1}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BreakUnitTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[BreakUnitTabDataStateNames[i]],
              ...items.map((x: any) => new BreakUnitTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [BreakUnitTabDataStateNames[i]]: [],
          [BreakUnitTabFocusedStateNames[i]]: undefined,
        });
        this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [BreakUnitTabDataStateNames[i]]: [],
      [BreakUnitTabFocusedStateNames[i]]: undefined,
    }, async () => {
      const index = await this.infinity?.retrieveTo('ttot_samt',
        this.state.focuseddata?.ttot_samt, type, true) || 0;
      // @ts-ignore
      this.state[BreakUnitTabDataStateNames[i]]
      // @ts-ignore
      && this.state[BreakUnitTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        qty_tot: data?.qty_tot || '0',
        equptot_tot: data?.equptot_tot || '0',
      });
      this.grid.current?.setFocus(0);
      await this.onRowFocusEvent(this.state.focuseddata);
    });
  }

  @action
  async doRetrieve2(i: BreakUnitTabId, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        actcd: this.state.actcd,
      },
      (params) => api.fxExec(
        `${BreakUnitTabFunctionNames[i]}_retrieve_tot`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BreakUnitTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[BreakUnitTabDataStateNames[i]],
              ...items.map((x: any) => new BreakUnitTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [BreakUnitTabDataStateNames[i]]: [],
          [BreakUnitTabFocusedStateNames[i]]: undefined,
        });
        this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [BreakUnitTabDataStateNames[i]]: [],
      [BreakUnitTabFocusedStateNames[i]]: undefined,
    }, async () => {
      const index = await this.infinity?.retrieveTo('ttot_samt',
        this.state.focuseddata?.ttot_samt, type, true) || 0;
      // @ts-ignore
      this.state[BreakUnitTabDataStateNames[i]]
      // @ts-ignore
      && this.state[BreakUnitTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        qty_tot: data?.qty_tot || '0',
        equptot_tot: data?.equptot_tot || '0',
      });
      this.grid.current?.setFocus(0);
      await this.onRowFocusEvent(this.state.focuseddata);
    });
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/stick.php?database=${user.custcd}&window=w_tb_e401_02&type=2&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_actcd=${this.state.actcd}&as_stmon=${this.state.stmon}&as_endmon=${this.state.endmon}&xcnt=1&tnm0=%EA%B3%A0%EC%9E%A5`,
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onRowFocusEvent(item: any) {
    this.setState({
      focuseddata: item,
    }, async () => {
      await this.urlRetrieve();
    });
  }

  render() {
    return (
      <BreakUnitTemplate
        scope={this}
      />
    );
  }
}
