import { computed } from 'mobx';


export class ModalDataModel {
  /**
   * 발생일자
   *
   * **data type** varchar(40)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(40)
   */
  mijnum: string;

  /**
   * 항목명
   *
   * **data type** varchar(40)
   */
  artnm: string;

  /**
   * 적요
   *
   * **data type** varchar(40)
   */
  remark: string;

  /**
   * 발생금액
   *
   * **data type** varchar(40)
   */
  mijamt: string;

  /**
   * 지급금액
   *
   * **data type** varchar(40)
   */
  sndamt: string;

  /**
   * 미지급금액
   *
   * **data type** varchar(40)
   */
  iamt: string;

  /**
   * 거래처명
   *
   * **data type** varchar(40)
   */
  cltnm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(40)
   */
  projnm: string;

  /**
   * 증빙구분
   *
   * **data type** varchar(40)
   */
  gubun: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.artnm = data.artnm || '';
    this.remark = data.remark || '';
    this.mijamt = data.mijamt || '';
    this.sndamt = data.sndamt || '';
    this.iamt = data.iamt || '';
    this.cltnm = data.cltnm || '';
    this.projnm = data.projnm || '';
    this.gubun = data.gubun || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
