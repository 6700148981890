import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/all';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  BreakSituationChargeModel,
  BreakSituationProcessorModel,
  BreakSituationPeriodModel,
  BreakSituationManyModel,
  BreakSituationSameModel,
  BreakSituationActModel,
  PopupModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { BreakSituationTemplate } from './BreakSituation.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderCharge,
  TabHeaderProcessor,
  TabHeaderPeriod,
  TabHeaderMany,
  TabHeaderSame,
  TabHeaderAct,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import Date8Calculator from '../../../../utils/time/Date8Calculator';
import { Date8 } from '../../../../utils/time';
import { PageComponent } from '../../../../utils';


export enum BreakSituationTabId {
  Charge,
  Processor,
  Period,
  Many,
  Same,
  Act,
}

export const BreakSituationTabTitles = [
  '담당자부서별',
  '처리자별',
  '기간별',
  '다발고장별',
  '동일고장별',
  '현장호기별',
];

export const BreakSituationTabModels = [
  BreakSituationChargeModel,
  BreakSituationProcessorModel,
  BreakSituationPeriodModel,
  BreakSituationManyModel,
  BreakSituationSameModel,
  BreakSituationActModel,
];

export const BreakSituationTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
];

export const BreakSituationTabDataStateNames = [
  'chargeList',
  'processorlist',
  'periodList',
  'manylist',
  'sameList',
  'actlist',
];

export interface BreakSituationState {
  chargeList?: Array<BreakSituationChargeModel>; // 담당자부서 리스트
  processorlist?: Array<BreakSituationProcessorModel>; // 처리자 리스트
  periodList?: Array<BreakSituationPeriodModel>; // 기간별 리스트
  manylist?: Array<BreakSituationManyModel>; // 다발고장별 리스트
  sameList?: Array<BreakSituationSameModel>; // 동일고장별 리스트
  actlist?: Array<BreakSituationActModel>; // 현장호기별 리스트

  focusedTab?: TabModel;

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  divicd: string; // 부서
  divinm: string; // 부서명
  perid: string; // 담당자
  pernm: string; // 담당자명
  gubun: string; // 처리
  wkactcd: string; // 회사구분
  wkactnm: string; // 회사구분명
  sttime: string; // 시간(시작)
  endtime: string; // 시간(끝)
  actcd: string; // 현장
  actnm: string; // 현장명
  equpcd: string; // 호기
  equpnm: string; // 호기명
  searchQuery: string; // 검색어

  total: string;
  compcnt_tot: string;
  notcnt_tot: string;
  rececnt_tot: string;

  // URL
  url: string;
  url2: string;
  url3: string;
  url4: string;
  url5: string;
  url6: string;

  focuseddata?: any;
  popupData: PopupModel;
  popupDetailModal: boolean;

  // 모달 총계
  compcnt_tot2: string;
  notcnt_tot2: string;
  rececnt_tot2: string;
}
/**
 * 컨트롤러
 * @window w_tb_e411w
 * @category 고장처리현황
 */
export class BreakSituation extends PageComponent<PageProps, BreakSituationState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  tapChk?: number;

  mamtSum?: number; // 총 발주금액

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = BreakSituationTabId;
    const titles = BreakSituationTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    this.tabs = [
      new TabModel(id.Charge.toString(), MdReorder, titles[id.Charge]),
      new TabModel(id.Processor.toString(), MdReceipt, titles[id.Processor]),
      new TabModel(id.Period.toString(), MdReorder, titles[id.Period]),
      new TabModel(id.Many.toString(), MdReceipt, titles[id.Many]),
      new TabModel(id.Same.toString(), MdReorder, titles[id.Same]),
      new TabModel(id.Act.toString(), MdReceipt, titles[id.Act]),
    ];

    this.tabHeaders = [
      TabHeaderCharge,
      TabHeaderProcessor,
      TabHeaderPeriod,
      TabHeaderMany,
      TabHeaderSame,
      TabHeaderAct,
    ];

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      stdate: new Date8Calculator().addMonth(-1).toString(),
      enddate: Date8.make(),
      chargeList: [],
      processorlist: [],
      periodList: [],
      manylist: [],
      sameList: [],
      actlist: [],
      searchQuery: '', // 검색어
      perid: bosuPerid || '', // 담당자
      pernm: bosuPernm || '', // 담당자명
      gubun: '%', // 처리
      wkactcd: '', // 회사구분
      wkactnm: '', // 회사구분명
      sttime: '0000', // 시간(시작)
      endtime: '2359', // 시간(끝)
      actcd: '', // 현장
      actnm: '', // 현장명
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',

      total: '',
      compcnt_tot: '0',
      notcnt_tot: '0',
      rececnt_tot: '0',
      compcnt_tot2: '0',
      notcnt_tot2: '0',
      rececnt_tot2: '0',
      url: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[BreakSituationTabId.Charge]);
    // await this.doRetrieve(BreakSituationTabId.Day);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakSituationTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }


    await api.fxPrint(
      `${BreakSituationTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        perid: this.state.perid,
        gubun: this.state.gubun,
        wkactcd: this.state.wkactcd,
        sttime: this.state.sttime,
        endtime: this.state.endtime,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakSituationTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }


    await api.fxExcel(
      `${BreakSituationTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        perid: this.state.perid,
        gubun: this.state.gubun,
        wkactcd: this.state.wkactcd,
        sttime: this.state.sttime,
        endtime: this.state.endtime,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onRowFocusEvent(item: any) {
    this.setState({
      focuseddata: item,
    }, async () => {
      await this.urlRetrieve();
    });
  }

  @computed
  get tabIndex(): BreakSituationTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: BreakSituationTabId) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd,
        perid: this.state.perid,
        gubun: this.state.gubun,
        wkactcd: this.state.wkactcd,
        sttime: this.state.sttime,
        endtime: this.state.endtime,
        actcd: this.state.actcd,
        equpcd: this.state.equpcd,
        as_nm: this.state.searchQuery,
      },
      (params) => api.fxExec(
        `${BreakSituationTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BreakSituationTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[BreakSituationTabDataStateNames[i]],
              ...items.map((x: any) => new BreakSituationTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [BreakSituationTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [BreakSituationTabDataStateNames[i]]: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        compcnt_tot: data?.compcnt_tot || '0',
        notcnt_tot: data?.notcnt_tot || '0',
        rececnt_tot: data?.rececnt_tot || '0',
      });
      this.grid.current?.setFocus(0);
      // @ts-ignore
      await this.onRowFocusEvent(this.state[BreakSituationTabDataStateNames[0]]);
    });
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/stick.php?database=${user.custcd}&window=w_tb_e411w&type=1&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_sttime=${this.state.sttime}&as_endtime=${this.state.endtime}&xcnt=2&tnm0=고장&tnm1=처리`,
      url2: `https://api.elmansoft.com/chart/stick.php?database=${user.custcd}&window=w_tb_e411w&type=2&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_sttime=${this.state.sttime}&as_endtime=${this.state.endtime}&xcnt=1&tnm0=처리`,
      url3: `https://api.elmansoft.com/chart/stock.php?database=${user.custcd}&window=w_tb_e411w&type=3&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_sttime=${this.state.sttime}&as_endtime=${this.state.endtime}&title=%EA%B3%A0%EC%9E%A5`,
      url4: `https://api.elmansoft.com/chart/stick.php?database=${user.custcd}&window=w_tb_e411w&type=4&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_sttime=${this.state.sttime}&as_endtime=${this.state.endtime}&xcnt=1&tnm0=%EA%B3%A0%EC%9E%A5`,
      url5: `https://api.elmansoft.com/chart/stick.php?database=${user.custcd}&window=w_tb_e411w&type=5&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_sttime=${this.state.sttime}&as_endtime=${this.state.endtime}&xcnt=1&tnm0=%EA%B3%A0%EC%9E%A5`,
      url6: `https://api.elmansoft.com/chart/stock2.php?database=${user.custcd}&window=w_tb_e411w&type=6&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_actcd=00002&as_equpcd=001`,
    });
  }

  @action
  popupModal(isOpen: boolean) {
    this.setState({ popupDetailModal: isOpen });

    setTimeout(() => this.modalRetrive(), 70);
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_e411w',
        actcd: this.state.focuseddata?.actcd,
        equpcd: this.state.focuseddata?.equpcd,
        enddate: this.state.focuseddata?.recedate,
      });
    if (data) {
      this.setState({
        popupData: data?.items,

        // 하단 총계
        rececnt_tot2: data?.rececnt_tot || '0',
        compcnt_tot2: data?.compcnt_tot || '0',
        notcnt_tot2: data?.notcnt_tot || '0',
      }, () => this.popupGrid.current?.setFocus(0));
    }
  }


  render() {
    return (
      <BreakSituationTemplate
        scope={this}
      />
    );
  }
}
