import Swal from 'sweetalert2';
import { ConfirmWarning } from './index';
import { Fix } from '../string';

export default class Delete {
  static show(text: string): Promise<boolean> {
    return new Promise((resolve) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons.fire({
        title: '삭제하시겠습니까?',
        html: Fix.newline(text).split('\n').join('<BR/>'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          ConfirmWarning.show('취소', '삭제하지 않았습니다.');
        }
      });
    });
  }
}
