import { computed } from 'mobx';

/**
 * 차량등록 모델
 * @window w_p2110
 * @category 인사등록
 */
export class CarModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   * 순번
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   * 직원번호
   *
   * **data type** varchar(13)
   */
  readonly perid: string;

  /**
   * 이름(사용자)
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 차량소유주
   *
   * **data type** varchar(100)
   */
  readonly carpernm: string;

  /**
   * 차량번호
   *
   * **data type** char(20)
   */
  readonly carnum: string;

  /**
   * 차명
   *
   * **data type** varchar(255)
   */
  readonly carname: string;

  /**
   * 유종
   *
   * **data type** varchar(3)
   */
  readonly kmflag: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.gubun = data.gubun || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.carpernm = data.carpernm || '';
    this.carnum = data.carnum || '';
    this.carname = data.carname || '';
    this.kmflag = data.kmflag || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
