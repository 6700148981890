import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { GiSave } from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  GridLayout,
  LabelBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  BtnGubun,
  DepositInput,
  ItemChange,
} from './DepositInput';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';
import { Sum } from '../../../../utils/array';

const billkinds = [
  { value: '1', remark: '발행' },
  { value: '0', remark: '미발행' },
];

const recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'B ', remark: '발송전' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'R ', remark: '열람' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
];

/**
 * 화면
 * @window w_input_da026_all
 * @category 입금입력
 */
export const DepositInputTemplate: React.FC<TemplateProps<DepositInput>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={350}>
          <span style={{ color: 'var(--color-red)' }}>거래처</span>
          <TextBox
            textAlign="center"
            value={scope.state.cltcd}
            onChange={(value) => scope.setState({ cltcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_XCLIENT',
              { uv_arg1: '2' },
              true,
              (item) => {
                scope.setState({
                  cltcd: item.cd,
                  cltnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.cltnm}
            onChange={(value) => scope.setState({ cltnm: value })}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.smsEvent()}>
          <div style={{ width: 80 }}>문자전송</div>
        </Button>
      </SearchLayout>

      <FlexLayout
        isVertical={true}
        size={220}
      >
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox
            title="고객명"
            size={379}
          >
            <TextBox
              textAlign="left"
              value={model.cltnm}
              readonly={true}
            />
          </LabelBox>

          <LabelBox title="입금일자">
            <DateTextBox
              format="####-##-##"
              value={!model.rcvdate ? '19700101' : model.rcvdate}
              textAlign="center"
              onChange={(item) => setData({ rcvdate: item },
                () => scope.onItemChanged(item.value, ItemChange.rcvdate))}
            />
          </LabelBox>

          <LabelBox title="입금번호">
            <TextBox
              textAlign="center"
              value={model.rcvnum}
              readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="입금담당"
            style={{ color: 'var(--color-red)' }}
          >
            <TextBox
              textAlign="center"
              value={model.perid}
              onChange={(value) => setData({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                },
              )}
            />
            <TextBox
              textAlign="center"
              value={model.pernm}
              readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="입금형태"
            size={379}
          >
            <FlexLayout size={71}>
              <CheckBox
                value={model.hchk === '1'}
                onChange={(value) => {
                  setData({
                    hchk: value ? '1' : '0',
                    total_amt: !value && '',
                    hamt: !value && '',
                  });
                }}
                isDisabledTrackingStateChange={true}
              />
              <label style={{ marginTop: 9 }}>현금</label>
            </FlexLayout>

            <FlexLayout size={71}>
              <CheckBox
                value={model.bchk === '1'}
                onChange={(value) => {
                  setData({
                    bchk: value ? '1' : '0',
                    total_amt: !value && '',
                    bamt: !value && '',
                  });
                }}
                isDisabledTrackingStateChange={true}
              />
              <label style={{ marginTop: 9 }}>예금</label>
            </FlexLayout>

            <FlexLayout size={71}>
              <CheckBox
                value={model.jchk === '1'}
                onChange={(value) => {
                  setData({
                    jchk: value ? '1' : '0',
                    total_amt: !value && '',
                    jamt: !value && '',
                  });
                  scope.onItemChanged(value ? '1' : '0', ItemChange.jchk);
                }}
                isDisabledTrackingStateChange={true}
              />
              <label style={{ marginTop: 9 }}>지로</label>
            </FlexLayout>

            <FlexLayout size={71}>
              <CheckBox
                value={model.dchk === '1'}
                onChange={(value) => {
                  setData({
                    dchk: value ? '1' : '0',
                    total_amt: !value && '',
                    damt: !value && '',
                    dcamt: !value && '',
                  });
                  scope.onItemChanged(value ? '1' : '0', ItemChange.dchk);
                }}
              />
              <label style={{ marginTop: 9 }}>카드</label>
            </FlexLayout>

            <FlexLayout size={71}>
              <CheckBox
                value={model.gchk === '1'}
                onChange={(value) => {
                  setData({
                    gchk: value ? '1' : '0',
                    total_amt: !value && '',
                    gamt: !value && '',
                  });
                }}
              />
              <label style={{ marginTop: 9 }}>기타</label>
            </FlexLayout>
          </LabelBox>

          <LabelBox
            title="기타구분"
            size={189}
          >
            <ComboBox
              value={model.gubun || '1'}
              textAlign="center"
              data={scope.state.gubun?.map((x: any) => new ComboBoxModel(x.code, x.cnam))}
              onSelect={(option) => setData({ gubun: option.value })}
            />
          </LabelBox>

          <LabelBox
            title="기타입금"
            size={189}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.gamt}
              onChange={(value) => setData({ gamt: value })}
              onEnter={() => scope.updateTotal()}
              onBlur={() => scope.updateTotal()}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox
            title="현금입금"
            size={189}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.hchk === '1' ? model.hamt : ' '}
              readonly={model.hchk !== '1'}
              onChange={(value) => setData({ hamt: value })}
              onEnter={() => scope.updateTotal()}
              onBlur={() => scope.updateTotal()}
            />
          </LabelBox>

          <LabelBox
            title="예금입금"
            size={189}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.bchk === '1' ? model.bamt : ' '}
              readonly={model.bchk !== '1'}
              onChange={(v) => {
                setData({ bamt: v }, () => scope.onItemChanged(v, ItemChange.bamt));
              }}
              onEnter={(item) => scope.onItemChanged(item, ItemChange.bamt)}
            />
          </LabelBox>

          <LabelBox
            title="입금은행"
            size={267}
            style={{ color: 'var(--color-red)' }}
            footer={
              <Button
                style={{ fontSize: 22 }}
                isIcon={true}
                onClick={() => scope.onButtonclick(model.bankcd, BtnGubun.b_bankcd)}
              >
                <GiSave/>
              </Button>}
          >
            <TextBox
              weight={1}
              value={model.bchk === '1' ? model.bankcd : ' '}
              readonly={model.bchk !== '1'}
              textAlign="center"
              onChange={(value) => setData({ bankcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AA040',
                {},
                true,
                (item) => {
                  setData({
                    bankcd: item.cd,
                    banknm: item.uv_arg3,
                    accnum: item.uv_arg1,
                  });
                },
              )}
            />
            <TextBox
              value={model.bchk === '1' ? model.banknm : ' '}
              onChange={(value) => setData({ banknm: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="계좌번호"
            size={267}
          >
            <TextBox
              textAlign="center"
              value={model.bchk === '1' ? model.accnum : ' '}
              onChange={(value) => setData({ accnum: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox title="지로입금">
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.jchk === '1' ? model.jamt : ' '}
              readonly={model.jchk !== '1'}
              onChange={(v) => {
                setData({ jamt: v, tjamt: v }, () => scope.onItemChanged(v, ItemChange.jamt));
              }}
              onEnter={(item) => scope.onItemChanged(item, ItemChange.jamt)}
            />
          </LabelBox>

          <LabelBox title="지로수수료">
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.jchk === '1' ? model.jmar : ' '}
              readonly={model.jchk !== '1'}
              onChange={(v) => {
                setData({ jmar: v }, () => scope.onItemChanged(v, ItemChange.jmar));
              }}
              onEnter={(item) => scope.onItemChanged(item, ItemChange.jmar)}
            />
          </LabelBox>

          <LabelBox
            title="지로은행"
            style={{ color: 'var(--color-red)' }}
            size={180}
            footer={
              <Button
                style={{ fontSize: 22 }}
                isIcon={true}
                onClick={() => scope.onButtonclick(model.jbankcd, BtnGubun.b_jbankcd)}
              >
                <GiSave/>
             </Button>}
          >
            <TextBox
              textAlign="center"
              value={model.jchk === '1' ? model.jbankcd : ' '}
              readonly={model.jchk !== '1'}
              onChange={(value) => setData({ jbankcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AA040',
                {},
                true,
                (item) => {
                  setData({
                    jbankcd: item.cd,
                    jbanknm: item.cdnm,
                  });
                },
              )}
            />
            <TextBox
              value={model.jbanknm}
              onChange={(value) => setData({ jbanknm: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="지로계좌번호"
            size={290}
          >
            <TextBox
              value={model.jaccnum}
              onChange={(value) => setData({ jaccnum: value })}
              readonly={true}
              textAlign="center"
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox
            title="적요"
            size={730}
          >
            <TextBox
              value={model.remark}
              onChange={(value) => setData({ remark: value })}
            />
          </LabelBox>

          <LabelBox
            title="카드입금"
            size={186}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.dchk === '1' ? model.damt : ' '}
              readonly={model.dchk !== '1'}
              onChange={(v) => {
                setData({ damt: v });
              }}
              onEnter={() => scope.updateTotal()}
              onBlur={() => scope.updateTotal()}
            />
          </LabelBox>

          <LabelBox
            title="카드수수료"
            size={143}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.dchk === '1' ? model.dcamt : ' '}
              readonly={model.dchk !== '1'}
              onChange={(v) => {
                setData({ dcamt: v });
              }}
              onEnter={() => scope.updateTotal()}
              onBlur={() => scope.updateTotal()}
            />
          </LabelBox>

          <LabelBox
            title="카드번호"
            size={143}
          >
            <TextBox
              value={model.dchk === '1' ? model.cardno : ' '}
              readonly={model.dchk !== '1'}
              onChange={(value) => setData({ cardno: value })}
            />
          </LabelBox>

          <LabelBox
            title="카드은행"
            size={180}
            style={{ color: 'var(--color-red)' }}
          >
            <TextBox
              textAlign="center"
              value={model.dchk === '1' ? model.cardcd : ' '}
              readonly={model.dchk !== '1'}
              onChange={(value) => setData({ cardcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_IZ010',
                {},
                true,
                (item) => {
                  setData({
                    cardcd: item.cd,
                    cardcd_1: item.cdnm,
                  });
                },
              )}
            />
            <TextBox
              weight={1.3}
              value={model.cardcd_1}
              onChange={(value) => setData({ cardcd_1: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="수수료율(%)"
            size={90}
          >
            <TextBox
              textAlign="center"
              value={model.dchk === '1' ? model.persent : ' '}
              readonly={model.dchk !== '1'}
              onChange={(value) => setData({ persent: value })}
            />
          </LabelBox>

          <LabelBox
            title="회계적용"
            size={80}
          >
            <ComboBox
              value={model.accyn}
              data={[
                { value: '1', remark: '적용' },
                { value: '0', remark: '미적용' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ accyn: option.value })}
              isDisabledTrackingStateChange={true}
            />
          </LabelBox>

          <LabelBox
            title="합계금액"
            size={125}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              color={'#ED709B'}
              value={model.total_amt}
              onChange={(value) => setData({ total_amt: value })}
              readonly={true}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>


      <FlexLayout isVertical={true}>
        <SubHeader>
          <div>
            <span>미수내역</span>
          </div>

          <FlexLayout justify="end">
          </FlexLayout>
        </SubHeader>

        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'chk',
              text: '선택',
              width: 50,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.chk === '1'}
                  onChange={(v) => {
                    scope.checkAlert(rowUpdate, v);
                  }}
                />
              ),
            },
            {
              id: 'misdate',
              text: '미수일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'misnum',
              text: '번호',
              width: 70,
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'recivstatus',
              text: '상태',
              width: 150,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='left'
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    color: x.recivstatus === 'F '
                      // eslint-disable-next-line no-nested-ternary
                      ? 'var(--color-blue)' : x.recivstatus === 'FF '
                        ? '#7A297B' : x.recivstatus === 'B '
                          ? 'var(--color-red)' : '',
                  }}>
                  {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '매출액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.misamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'janamt',
              text: '미수잔액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.janamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.janamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'inamt',
              text: '기입금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'inamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.inamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'winamt',
              text: '예정입금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'winamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.winamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wbamt',
              text: '예금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wbamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wbamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wjamt',
              text: '지로',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wjamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wjamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'whamt',
              text: '현금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'whamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.whamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wjmar',
              text: '수수료',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wjmar'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wjmar)}
                </FlexLayout>
              ),
            },
            {
              id: 'weamt',
              text: '어음',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'weamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.weamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wsamt',
              text: '수표',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wsamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wsamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wdamt',
              text: '카드',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wdamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wdamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wgamt',
              text: '기타',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wgamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wgamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wdcamt',
              text: 'DC금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.depositList, 'wdcamt'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wdcamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'billkind',
              text: '증빙구분',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                >
                  {billkinds.filter((y) => y.value === x.billkind)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '매출구분',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                >
                  {scope.state.gubuns?.filter((y) => y.code === x.gubun)[0]?.cnam}
                </FlexLayout>
              ),
            },
            {
              id: 'tax_spdate',
              text: '계산서일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'tax_spnum',
              text: '번호',
              width: 70,
            },
            {
              id: 'acc_spdate',
              text: '전표일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'acc_spnum',
              text: '번호',
              width: 70,
            },
          ]}
          data={scope.state.depositList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowFocusEvent={(item, index) => scope.onMainRowFocusEvent(item, index)}
          onChange={(rows) => {
            scope.onUpdatedRows(rows);
          }}
        />
      </FlexLayout>

      <SubHeader>
        <div>
          <span>입금내역</span>
        </div>

        <FlexLayout justify="end">
        </FlexLayout>
      </SubHeader>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'rcvdate',
                text: '입금일자',
                width: 12,
                trail: (_) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvnum',
                text: '번호',
                width: 8,
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '입금액',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.iamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'billgubun',
                text: '발행구분',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {billkinds.filter((y) => y.value === x.billgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.depositDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout weight={2.5}>
          <GridLayout
            ref={scope.grid2}
            header={[
              {
                id: 'rcvseq',
                text: '순번',
                width: 50,
              },
              {
                id: 'misdate',
                text: '발생일자',
                width: 100,
                trail: (_) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misnum',
                text: '번호',
                width: 50,
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '발생금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.iamt_tot2}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '예금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.bamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'jamt',
                text: '지로',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.jamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.jamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'jmar',
                text: '지로수수료',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.jmar_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.jmar)}
                  </FlexLayout>
                ),
              },
              {
                id: 'hamt',
                text: '현금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.hamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.hamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'damt',
                text: '카드',
                width: 100,
                trail: (data) => {
                  const sumDamt = Sum.all(data, 'damt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumDamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.damt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'dcamt',
                text: '카드수수료',
                width: 100,
                trail: (data) => {
                  const sumDcamt = Sum.all(data, 'dcamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumDcamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.dcamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'eamt',
                text: '어음',
                width: 100,
                trail: (data) => {
                  const sumEamt = Sum.all(data, 'eamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumEamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.eamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'samt',
                text: '수표',
                width: 100,
                trail: (data) => {
                  const sumSamt = Sum.all(data, 'samt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumSamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'gamt',
                text: '기타',
                width: 100,
                trail: (data) => {
                  const sumGamt = Sum.all(data, 'gamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumGamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.gamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'acc_spdate',
                text: '전표일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.acc_spdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'acc_spnum',
                text: '번호',
                width: 70,
              },
            ]}
            data={scope.state.depositDetailList2}
            infinityHandler={scope.infinity3}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
