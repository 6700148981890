import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { DollarAverage } from './DollarAverage';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';

const mijflag2s = [
  { value: '0', remark: '미처리' },
  { value: '1', remark: '매입처리' },
  { value: '2', remark: '지급처리' },
];

/**
 * 화면
 * @window w_tb_ca640_pa105
 * @category 정기매입자료생성-유류비
 */
export const DollarAverageTemplate: React.FC<TemplateProps<DollarAverage>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ yearmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button
          onClick={() => scope.onSubExcelEvent()}>
          <div style={{ width: 80 }}>EXCEL 자료</div>
        </Button>

        <Button onClick={() => scope.onRetrieveEvent()}>
          <div style={{ width: 80 }}>새로고침</div>
        </Button>

        <FlexLayout
          align="center"
          justify="center"
          size={340}
          style={{ marginLeft: 70 }}
        >
          금액계산공식: 단가(오피넷 당일편균가) x 1.2 ÷ x 운행거리(km)
        </FlexLayout>

        <FlexLayout size={200}>
          <span>매입일자</span>
          <DateTextBox
            value={scope.state?.datetime}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ datetime: value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={400}>
          <span style={{ color: 'var(--color-red)' }}>거래처</span>
          <TextBox
            value={scope.state.cltcd}
            textAlign="center"
            onChange={(value) => setData({ cltcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_XCLIENT',
              { uv_arg1: '1' },
              true,
              (item) => {
                setData({
                  cltcd: item.cd,
                  cltnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.cltnm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onBtnEvent()}>
          <div style={{ width: 80 }}>{scope.state.isReported ? '매입처리' : '매입취소'}</div>
        </Button>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={scope.state.pushTotalCheck}
                  onChange={async (value) => scope
                    .updateCheckAllToggle(value)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
            width: 3,
            render: (x, rowUpdate) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={x.chk === '1'}
                  onChange={(value) => {
                    rowUpdate({ chk: value ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'mijflag2',
            text: '매입구분',
            width: 4,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.mijflag2 === '1' ? 'var(--color-blue)' : '' }}
              >
                {mijflag2s.filter((y) => y.value === x.mijflag2)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'seq',
            text: '순번',
            width: 4,
          },
          {
            id: 'usedate',
            text: '일자',
            width: 6,
            render: (x, rowUpdate, ref) => (
              <DateTextBox
                ref={ref}
                format="####-##-##"
                value={x?.usedate}
                textAlign="center"
                onChange={(value) => rowUpdate({ usedate: value })}
              />
            ),
          },
          {
            id: 'perid',
            text: '사용자',
            width: 7,
            color: 'var(--color-red)',
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.perid}
                textAlign="center"
                onChange={(value) => rowUpdate({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    rowUpdate({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
            ),
          },
          {
            id: 'pernm',
            text: '사용자명',
            width: 8,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign='left'
                value={x.pernm}
                onChange={(value) => { rowUpdate({ pernm: value }); }}
              />
            ),
          },
          {
            id: 'carnum',
            text: '차량번호',
            width: 8,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign='left'
                value={x.carnum}
                onChange={(value) => { rowUpdate({ carnum: value }); }}
              />
            ),
          },
          {
            id: 'carname',
            text: '차명',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign='left'
                value={x.carname}
                onChange={(value) => { rowUpdate({ carname: value }); }}
              />
            ),
          },
          {
            id: 'kmflag',
            text: '유종',
            width: 6,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.kmflag}
                data={[
                  { value: '001', remark: '휘발유' },
                  { value: '002', remark: '경유' },
                  { value: '003', remark: 'LPG' },
                  { value: '004', remark: '전기' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => rowUpdate({ kmflag: option.value })}
                textAlign='center'
              />
            ),
          },
          {
            id: 'qty',
            text: '이동거리(km)',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.qty_tot}
              </FlexLayout>
            ),
            render: (x, rowUpdate, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="right"
                value={x.qty}
                onChange={(value) => rowUpdate({ qty: value })}
              />
            ),
          },
          {
            id: 'uamt',
            text: '단가',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.uamt_tot}
              </FlexLayout>
            ),
            render: (x, rowUpdate, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="right"
                value={x.uamt}
                onChange={(value) => rowUpdate({ uamt: value })}
              />
            ),
          },
          {
            id: 'samt',
            text: '금액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.samt_tot}
              </FlexLayout>
            ),
            render: (x, rowUpdate, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="right"
                value={x.samt}
                onChange={(value) => rowUpdate({ samt: value })}
              />
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 20,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign='left'
                value={x.remark}
                onChange={(value) => { rowUpdate({ remark: value }); }}
              />
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      />
    </FlexLayout>
  );
};
