import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { DepartmentModel } from '../models';
import { RepairSite } from '../RepairSite';
import { Button, FormatTextBox, TelnumTextBox } from '../../../../../components';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';


const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];
// 부서별현장현황
const Department: GridLayoutHeader<DepartmentModel, RepairSite>[] = [
  {
    id: 'no',
    text: 'No',
    width: 60,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.no}
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '담당부서',
    width: 85,
    group: true,
    sum: (x) => (
        <FlexLayout
          justify="center"
          align="center"
        >
          {x.divinm}
        </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 85,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumPernm)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.total} 건
      </FlexLayout>
    ),
  },
  {
    id: 'contpernm',
    text: '계약자명',
    width: 85,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.contpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm3',
    text: '기록',
    width: 50,
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <Button
          style={{
            height: '20px',
            width: '40px',
            marginTop: 10,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope?.historyPopupModal(true)}
        >
          조회
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    sum: (x, data) => {
      const sumMonamt = Sum.byGroup(data, 'monamt', x, ['divinm']);
      const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
         평균보수료: {Format.number(Math.ceil(Number(sumMonamt) / Number(sumQty)))}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.averamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactnm',
    text: '회사구분',
    width: 60,
  },
  {
    id: 'qty',
    text: '관리대수',
    width: 70,
    sum: (x, data) => {
      const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumQty}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot} 대
      </FlexLayout>
    ),
  },
  {
    id: 'equpamt',
    text: '대당금액',
    width: 110,
    sum: (x, data) => {
      const sumEqupamt = Sum.byGroup(data, 'equpamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEqupamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.equpamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.equpamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '계약금액',
    width: 110,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.amt_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumAmt = Sum.byGroup(data, 'amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAmt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mgubun',
    text: '유무상',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.mgubun === '1' ? '유' : '무'}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.stdate}
      />
    ),
  },
  {
    id: 'enddate',
    text: '종료일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.enddate}
      />
    ),
  },
  {
    id: 'tel',
    text: '전화번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.tel}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'deljugi',
    text: '청구주기',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope?.state.deljugis?.filter((y) => y.com_code === x.deljugi)[0]?.com_cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 130,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.bildyds?.filter((y) => y.buildcd === x.bildyd)[0]?.buildnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {contgs.filter((y) => y.value === x.contg)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'contdate',
    text: '계약일자',
    width: 110,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FormatTextBox
          format="####-##-##"
          textAlign="center"
          value={x.contdate}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'yy',
    text: '년차',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.yy}
      </FlexLayout>
    ),
  },
  {
    id: 'taxgubun',
    text: '세금구분',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.taxgubuns?.filter((y) => y.com_code === x.taxgubun)[0]?.com_cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'taxflag',
    text: '증빙구분',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.taxflag === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
  {
    id: 'addyn',
    text: '부가세포함',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.addyn === '1' ? '포함' : '별도'}
      </FlexLayout>
    ),
  },
  {
    id: 'dedate',
    text: '청구서발행일',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.dedate}
      </FlexLayout>
    ),
  },
  {
    id: 'jirogubun',
    text: '지로발행',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.jirogubun === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
];

export default Department;
