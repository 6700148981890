/**
 * 모델
 * @window w_tb_e101w_05
 * @category 월별 관리대수변동내역
 */
export default class StatusModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 정렬
   * **data type** varchar(8)
   */
  readonly sort: string;

  /**
   * 구분
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 구분명
   * **data type** varchar(8)
   */
  readonly gubunnm: string;

  /**
   * 01월
   * **data type** varchar(8)
   */
  readonly mon01: string;

  /**
   * 02월
   * **data type** varchar(8)
   */
  readonly mon02: string;

  /**
   * 03월
   * **data type** varchar(8)
   */
  readonly mon03: string;

  /**
   * 04월
   * **data type** varchar(8)
   */
  readonly mon04: string;

  /**
   * 05월
   * **data type** varchar(8)
   */
  readonly mon05: string;

  /**
   * 06월
   * **data type** varchar(8)
   */
  readonly mon06: string;

  /**
   * 07월
   * **data type** varchar(8)
   */
  readonly mon07: string;

  /**
   * 08월
   * **data type** varchar(8)
   */
  readonly mon08: string;

  /**
   * 09월
   * **data type** varchar(8)
   */
  readonly mon09: string;

  /**
   * 10월
   * **data type** varchar(8)
   */
  readonly mon10: string;

  /**
   * 11월
   * **data type** varchar(8)
   */
  readonly mon11: string;

  /**
   * 12월
   * **data type** varchar(8)
   */
  readonly mon12: string;

  /**
   * 합계
   * **data type** varchar(8)
   */
  readonly totmon: string;

  /**
   * 평균
   * **data type** varchar(8)
   */
  readonly aver: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.sort = data.sort || '';
    this.gubun = data.gubun || '';
    this.gubunnm = data.gubunnm || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.totmon = data.totmon || '';
    this.aver = data.aver || '';
  }
}
