import * as React from 'react';
import {
  Button, FlexLayout, GridLayout, ModalView, SearchLayout, TabLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Contract } from './Contract';
import { ContractTab } from './Contract.tab';
import { ContractGridHeader } from './headers/ContractGridHeader';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ContractIDModalGridHeader } from './headers/ContractIDModalGridHeader';

/**
 * 화면
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export const ContractTemplate: React.FC<TemplateProps<Contract>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 프로젝트로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout size={420}>
          <GridLayout
            ref={scope.grid}
            header={ContractGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            onRowClick={(item, index) => scope.onGridRowFocused(item, index)}
          />
        </FlexLayout>

        <FlexLayout>
          <TabLayout
            scope={scope}
            tabs={ContractTab}
            onChange={(_, index) => scope.onTabChange(index)}
          />
        </FlexLayout>
      </FlexLayout>


      <ModalView
        isVisible={scope.state.isVisibleIDModal}
        width={800}
        onClose={() => scope.SS({ isVisibleIDModal: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.searchQueryID}
                onChange={(value) => scope.SS({ searchQueryID: value })}
                onEnter={() => scope.onIDRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              ref={scope.gridID}
              header={ContractIDModalGridHeader(scope)}
              data={scope.state.dataID}
              infinityHandler={scope.infinityID}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowClick={(item) => scope.onGridIDRowClicked(item)}
              onRowDoubleClick={() => scope.onClickModalIDSuccess()}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.SS({ isVisibleIDModal: false })}
            >
              닫기
            </Button>
            <Button
              isFull={true}
              onClick={() => scope.onClickModalIDSuccess()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
