import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { CardTemplate } from './Card.template';
import { CardModel } from './Card.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models';
import { PageComponent } from '../../../../utils';

interface CardState {
  searchQuery: string;
  cardList: Array<CardModel>;
  focused?: CardModel;
  cards?: Array<any>;
  acccds?: Array<CardModel>;
  spjangcds?: Array<CardModel>;
}

/**
 * 컨트롤러
 * @window w_card_insert
 * @category 카드사등록
 */
export class Card extends PageComponent<PageProps, CardState> implements PageToolEvents {
  updatedRows?: Array<CardModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      data: [],
      cardList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          cardList: [...this.state.cardList, ...items.map((x: any) => new CardModel(x))],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          cardList: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        this.state.cardList.length > 0 && await this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      cardList: [],
    });

    await this.infinity?.retrieveAll();

    let index = 0;
    for (; index < this.state.cardList.length; index += 1) {
      if (this.state.focused?.nm === this.state.cardList[index].nm) break;
    }

    await this.table.current?.update(true);
    if (this.state.cardList && this.state.cardList.length > index) {
      await this.table.current?.setFocus(index, 1);
    } else {
      await this.table.current?.setFocus(0, 1);
    }
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      cardList: [...this.state.cardList, new CardModel(data, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.cardList.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.state.cardList,
    }, true)) {
      this.updatedRows = [];
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.cd} - ${this.state.focused?.nm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: CardModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<CardModel>, updatedRows: Array<CardModel>) {
    this.updatedRows = updatedRows;
    this.setState({ cardList: rows });
  }

  render() {
    return (
      <CardTemplate
       scope={this}
      />
    );
  }
}
