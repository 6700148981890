import { computed } from 'mobx';

/**
 * 상세모델
 * @window w_tb_pb401
 * @category 출장보고서
 */

export class TripDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly outdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly outnum: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly accdate: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly cltnm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly productnm: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly unit: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: number;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly amount: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly outcnt: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.outdate = data.outdate || '';
    this.outnum = data.outnum || '';
    this.seq = data.seq || '';
    this.datetime = data.datetime || '';
    this.accdate = data.accdate || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.productnm = data.productnm || '';
    this.unit = data.unit || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || 0;
    this.tamt = data.tamt || '';
    this.amount = data.amount || '';
    this.perid = data.perid || '';
    this.remark = data.remark || '';
    this.outcnt = data.outcnt || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
