import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  ModalView,
  SearchLayout,
  TableLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Invoice } from './Invoice';
import { SearchBinding } from '../../../../models/common';
import { Today } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_ca900
 * @category 송장등록
 */

export const InvoiceTemplate: React.FC<TemplateProps<Invoice>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.setState({ year: value })}
            onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={500}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="발송처, 발송자, 내용, 연락처, 받은사람, 택배회사로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.smsEvent()}>
          <div style={{ width: 80 }}>문자전송</div>
        </Button>
      </SearchLayout>
      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'seq',
            text: 'No',
            width: 7,
            render: (x, _, ref) => (
              <TextBox
                ref={ref}
                value={x.seq}
                textAlign="center"
              />
            ),
          },
          {
            id: 'attcnt',
            text: '첨부여부',
            width: 8,
            render: (x, _, ref) => (
              <FlexLayout>
                <Button
                  isIcon={true}
                  style={{ marginLeft: 5 }}
                  onClick={() => scope.fileModal(true)}
                >
                  <RiFolder2Line size={17}/>
                </Button>
                <TextBox
                  ref={ref}
                  color={x.attcnt > 0 ? 'var(--color-blue)' : 'var(--color-red)'}
                  value={x.attcnt > 0 ? 'O' : 'X'}
                  textAlign="right"
                />
              </FlexLayout>
            ),
          },
          {
            id: 'invocltnm',
            text: '택배회사명',
            width: 15,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.invocltnm}
                textAlign="left"
                onChange={(value) => rowUpdate({ invocltnm: value })}
              />
            ),
          },
          {
            id: 'num',
            text: '송장번호',
            width: 15,
            render: (x, rowUpdate, ref) => (
              <FormatTextBox
                ref={ref}
                textAlign="center"
                format="###-####-####"
                value={x.num}
                onChange={(value) => rowUpdate({ num: value })}
              />
            ),
          },
          {
            id: 'invodate',
            text: '발송일',
            width: 15,
            render: (x, rowUpdate, ref) => (
              <DateTextBox
                ref={ref}
                format="####-##-##"
                value={(!x.invodate) ? `${Today.date()}` : x.invodate }
                textAlign="center"
                onChange={(value) => rowUpdate({ invodate: value })}
              />
            ),
          },
          {
            id: 'cltcd',
            text: '발송처',
            width: 8,
            color: 'var(--color-red)',
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.cltcd}
                textAlign="center"
                onChange={(value) => rowUpdate({ cltcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  {},
                  true,
                  (item) => {
                    rowUpdate({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    });
                  },
                )}
              />
            ),
          },
          {
            id: 'cltnm',
            text: '발송처명',
            width: 20,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.cltnm}
                textAlign="left"
                onChange={(value) => rowUpdate({ cltnm: value })}
              />
            ),
          },
          {
            id: 'gubun',
            text: '구분',
            width: 10,
            render: (x, rowUpdate, _) => (
              <ComboBox
                value={x.gubun}
                data={[
                  { value: '01', remark: '자재A/S' },
                  { value: '02', remark: '자재반납' },
                  { value: '03', remark: '자재교환' },
                  { value: '04', remark: '문서' },
                  { value: '05', remark: '안전용품' },
                  { value: '06', remark: '기타' },
                  { value: 'null', remark: null },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => rowUpdate({ gubun: option.value })}
              />
            ),
          },
          {
            id: 'remark',
            text: '내용',
            width: 30,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.remark}
                textAlign="left"
                onChange={(value) => rowUpdate({ remark: value })}
              />
            ),
          },
          {
            id: 'toname',
            text: '받는이',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.toname}
                textAlign="left"
                onChange={(value) => rowUpdate({ toname: value })}
              />
            ),
          },
          {
            id: 'repernm',
            text: '요청자',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.repernm}
                textAlign="left"
                onChange={(value) => rowUpdate({ repernm: value })}
              />
            ),
          },
          {
            id: 'tel',
            text: '연락처',
            width: 15,
            render: (x, rowUpdate, ref) => (
              <TelnumTextBox
                ref={ref}
                value={x.tel}
                textAlign="center"
                onChange={(value) => rowUpdate({ tel: value })}
              />
            ),
          },
          {
            id: 'perid',
            text: '발송자',
            width: 8,
            color: 'var(--color-red)',
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.perid}
                textAlign="center"
                onChange={(value) => rowUpdate({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    rowUpdate({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
            ),
          },
          {
            id: 'pernm',
            text: '발송자명',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.pernm}
                textAlign="center"
                onChange={(value) => rowUpdate({ pernm: value })}
              />
            ),
          },
        ]}
        data={scope.state.invoiceList}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={`${scope.state.year}CA900${scope.state.data?.seq}`}
            appnm={'송장관리'}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
