import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import {
  OrderStatement,
  OrderStatementTabDataStateNames,
} from './OrderStatement';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8 } from '../../../../utils/time';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { FormatNumberTextBox } from '../../../../components/forms/FormatNumberTextBox';
import { FormatTextBox } from '../../../../components/forms/FormatTextBox';
import { TabView } from '../../../../components/layout/TabView';

/**
 * 화면
 * @window w_tb_ca608w_02
 * @category 발주현황
 */
export const OrderStatementTemplate: React.FC<TemplateProps<OrderStatement>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={250}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            부서
          </span>
        <TextBox
          weight={1}

          size={100}
          value={scope.state.divicd}
          textAlign="center"
          onChange={(value) => scope.setState({ divicd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.5}
          value={scope.state.divinm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}
          >
            거래처
          </span>
        <TextBox
          weight={1}
          size={100}
          value={scope.state.cltcd}
          textAlign="center"
          onChange={(value) => scope.setState({ cltcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_XCLIENT',
            {},
            true,
            (item) => {
              scope.setState({
                cltcd: item.cd,
                cltnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.5}
          value={scope.state.cltnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>
            프로젝트
          </span>
        <TextBox
          weight={1}
          size={100}
          value={scope.state.projcd}
          textAlign="center"
          onChange={(value) => scope.setState({ projcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                projcd: item.cd,
                projnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.5}
          value={scope.state.projnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      isSub={true}
    >
      <FlexLayout size={475}>
        <span style={{ marginRight: 20 }}>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="특이사항, 품목별비고로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={275}>
          <span style={{
            color: 'var(--color-red)',
            marginLeft: 40,
          }}
          >
            담당자
          </span>
        <TextBox
          weight={1}
          size={100}
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.5}
          value={scope.state.pernm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={500}>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            현장
          </span>
        <TextBox
          weight={1}
          size={100}
          value={scope.state.actcd}
          textAlign="center"
          onChange={(value) => scope.setState({ actcd: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              scope.setState({
                actcd: item.cd,
                actnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={5}
          value={scope.state.actnm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout weight={3}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'baldate',
              text: '발주일자',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.baldate}
                />
              ),
            },
            {
              id: 'balnum',
              text: '번호',
              width: 75,
              trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope?.state.total}건
                  </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.balnum}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 75,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '발주처',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'mamt',
              text: '발주금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope?.state.mamt_tot}
                </FlexLayout>
              ),
              render: (x, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.mamt}
                />
              ),
            },
            {
              id: 'email',
              text: '발주처메일',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.taxmail}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '특이사항(공사명)',
              width: 250,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'projnm',
              text: '프로젝트명',
              width: 250,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.projnm}
                </FlexLayout>
              ),
            },
            {
              id: 'bigo',
              text: '납품장소',
              width: 250,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.bigo}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.orderStatementList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>
    </FlexLayout>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <TabView
        tabs={scope.tabs}
        focused={scope.state.focusedTab}
        onChange={(tab) => scope.onTabChange(tab)}
      />
    </FlexLayout>
    <FlexLayout weight={1}>
      <GridLayout
        header={scope.tabHeaders[scope.tabIndex]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity2}
        scope={scope}
        // @ts-ignore
        data={scope.state[OrderStatementTabDataStateNames[scope.tabIndex]] || [] }
      />
    </FlexLayout>
  </FlexLayout>
);
