import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { GridLayout } from '../../components/layout/GridLayout';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';

interface ModalWriterProps {
  modalStore: ModalStore;
}

@observer
export class ModalWriter extends React.Component<ModalWriterProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={600}
        height={600}
        isVisible={modalStore.isVisibleWriterSelector}
        onClose={() => modalStore.openWriterSelector()}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout weight={2}>
              <span>검색어</span>
              <TextBox
                value={modalStore.writerSearch}
                onChange={(value) => {
                  modalStore.writerSearch = value;
                }}
                onEnter={() => modalStore.onWriterRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <GridLayout
            header={[
              {
                id: 'perid',
                text: '코드',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.perid}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '코드명',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore?.writerData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => modalStore.onWriterRowFocusEvent(item)}
            onRowDoubleClick={() => modalStore.onWriterDoubleClick()}
          />
        </FlexLayout>
      </ModalView>
    );
  }
}
