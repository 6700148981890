/**
 * 모델
 * 현장정보
 * @window w_tb_e073
 * @category 검사스케쥴
 */
export class RoutineModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 계획확정
   *
   * **data type** varchar(1)
   */
  readonly planflag: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(50)
   */
  readonly elno: string;

  /**
   * 담당자
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 검사계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 유효기간만료일
   *
   * **data type** varchar(8)
   */
  readonly kcenddate: string;

  /**
   * 검사시간
   *
   * **data type** varchar(4)
   */
  readonly plantime: string;

  /**
   * 검사자
   *
   * **data type** varchar(255)
   */
  readonly kcpernm: string;

  /**
   * 검사기관
   *
   * **data type** varchar(200)
   */
  readonly kcspnm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 검사결과
   *
   * **data type** varchar(3)
   */
  readonly result: string;

  /**
   * 순서
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   * 검사지적갯수
   *
   * **data type** long
   */
  readonly failcnt: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.planflag = data.planflag || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.pernm = data.pernm || '';
    this.plandate = data.plandate || '';
    this.kcenddate = data.kcenddate || '';
    this.plantime = data.plantime || '';
    this.kcpernm = data.kcpernm || '';
    this.kcspnm = data.kcspnm || '';
    this.perid = data.perid || '';
    this.result = data.result || '';
    this.seq = data.seq || '';
    this.failcnt = data.failcnt || '';
  }
}
