import { computed } from 'mobx';

/**
* 모델
* @window TB_E021
* @category 업무코드등록
*/
export class WorkCodeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 업무코드
   *
   * **data type** varchar(3)
   */
  busicd: string;

  /**
   * 상위부서명
   *
   * **data type** varchar(100)
   */
  businm: string;

  /**
   * 사용유무
   *
   * **data type** 사용유무
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 업무구분
   *
   * **data type** varchar(3)
   */
  upflag: string;

  /**
   * 상위부서코드
   *
   * **data type** varchar(3)
   */
  kukcd: string;

  /**
   * 교육
   *
   * **data type** varchar(1)
   */
  eduflag: string;

  /**
   * 상위부서명
   *
   * **data type** varchar(100)
   */
  kuknm: string;

  /**
   * 값전달
   *
   * **data type** varchar(10)
   */
  searchQuery: string;

  /**
   * popup값 전달
   *
   * **data type** varchar(10)
   */
  modalCheck: string

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.busicd = data.busicd || '';
    this.businm = data.businm || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.upflag = data.upflag || '';
    this.kukcd = data.kukcd || '';
    this.eduflag = data.eduflag || '';
    this.kuknm = data.kuknm || '';
    this.searchQuery = data.searchQuery || '';
    this.modalCheck = data.modalCheck || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
