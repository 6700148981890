export default class Selector {
  static single(onlyImage: boolean = false): Promise<FileList> {
    return Selector.open(false, onlyImage);
  }

  static multi(onlyImage: boolean = false): Promise<FileList> {
    return Selector.open(true, onlyImage);
  }

  static open(isMulti: boolean, onlyImage: boolean): Promise<FileList> {
    return new Promise((resolve, reject) => {
      const fileSelector = document.createElement('input');
      fileSelector.setAttribute('type', 'file');

      if (isMulti) fileSelector.setAttribute('multiple', 'multiple');
      if (onlyImage) fileSelector.setAttribute('accept', 'image/*');

      fileSelector.onchange = () => {
        // @ts-ignore
        resolve(fileSelector.files);
      };
      fileSelector.oncancel = reject;
      fileSelector.click();
    });
  }
}
