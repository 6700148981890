import * as React from 'react';
import Lottie from 'react-lottie';
import { RefObject } from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import style from './CheckBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import check from '../../../../assets/icons/svg/check.json';
import { PublicStore } from '../../../stores';

interface CheckBoxActions {
  onChange?: (value: boolean) => any;
}

interface CheckBoxProps extends CheckBoxActions {
  publicStore?: PublicStore;
  value: boolean;
  noMargin?: boolean;
  className?: any;
  style?: any;
  isDisabledTrackingStateChange?: boolean;
  readonly?: boolean;
}

@inject('publicStore')
export class CheckBox extends React.Component<CheckBoxProps> {
  lottie: RefObject<Lottie>;

  constructor(props: CheckBoxProps) {
    super(props);
    this.lottie = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    const segments = value ? [29, 30] : [0, 1];

    // @ts-ignore
    this.lottie.current?.anim.playSegments(segments, true);

    // @ts-ignore
    this.lottie.current?.anim.pause();
  }

  shouldComponentUpdate(nextProps: Readonly<CheckBoxProps>): boolean {
    return nextProps.value !== this.props.value
      || nextProps.readonly !== this.props.readonly;
  }

  componentDidUpdate() {
    const { value } = this.props;
    const segments = value ? [0, 30] : [30, 0];

    // @ts-ignore
    this.lottie.current?.anim.playSegments(segments, true);
  }

  @action
  callOnChange(value: boolean) {
    const { publicStore } = this.props;
    this.props.onChange && this.props.onChange(value);

    if (!this.props.isDisabledTrackingStateChange) {
      publicStore?.setStateChanged(true);
    }
  }

  render() {
    const { value } = this.props;

    return (
      <div
        className={JoinClassName.make([
          style.container,
          this.props.className,
          value ? style.active : false,
          this.props.readonly ? style.readonly : false,
          this.props.noMargin ? style.noMargin : false,
        ])}
        style={this.props.style}
        onClick={() => this.callOnChange(!value)}
        role="check-box"
      >
        <Lottie
          ref={this.lottie}
          options={{
            loop: false,
            autoplay: false,
            animationData: check,
          }}
          speed={2.5}
          isClickToPauseDisabled={true}
          height={18}
          width={18}
        />
      </div>
    );
  }
}
