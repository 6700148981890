import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  ConstructionModel,
  SalesModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout } from '../../../../components';
import { MonthConstructionTemplate } from './MonthConstruction.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderConstruction,
  TabHeaderSales,
} from './tabs';
import Today from '../../../../utils/time/Today';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

export enum ConstructionTabId {
  Construction,
  Sales,
}

export const ConstructionTabTitles = [
  '공사(수주)별',
  '매출별',
];

export const ConstructionTabModels = [
  ConstructionModel,
  SalesModel,
];

export const ConstructionTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const ConstructionTabDataStateNames = [
  'constructions',
  'saless',
];

export interface ReceivableState {
  // 공사별
  constructions: Array<ConstructionModel>;

  // 매출별
  saless: Array<SalesModel>;

  // search
  year: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  focusedTab?: TabModel;
  focused?: TabModel;

  // data
  graph: string;
  graph2: string;

  // tab2 trail
  mon01_tot: string;
  mon02_tot: string;
  mon03_tot: string;
  mon04_tot: string;
  mon05_tot: string;
  mon06_tot: string;
  mon07_tot: string;
  mon08_tot: string;
  mon09_tot: string;
  mon10_tot: string;
  mon11_tot: string;
  mon12_tot: string;
  totmon_tot: string;
  aver_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e471w_03
 * @category 월별 공사실적내역
 */
export class MonthConstruction extends PageComponent<PageProps, ReceivableState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  focused?: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = ConstructionTabId;
    const titles = ConstructionTabTitles;

    this.tabs = [
      new TabModel(id.Construction.toString(), MdReorder, titles[id.Construction]),
      new TabModel(id.Sales.toString(), MdPublish, titles[id.Sales]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      graph: '',
      graph2: '',

      // tab2 trail
      mon01_tot: '',
      mon02_tot: '',
      mon03_tot: '',
      mon04_tot: '',
      mon05_tot: '',
      mon06_tot: '',
      mon07_tot: '',
      mon08_tot: '',
      mon09_tot: '',
      mon10_tot: '',
      mon11_tot: '',
      mon12_tot: '',
      totmon_tot: '',
      aver_tot: '',
    };

    this.tabHeaders = [
      // 공사별
      TabHeaderSales,
      // 매출별
      TabHeaderConstruction,
    ];
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[ConstructionTabId.Construction]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    if (i === ConstructionTabId.Construction && (this.state.constructions?.length || 0) === 0) {
      await this.doRetrieve(ConstructionTabId.Construction);
    } else {
      await this.doRetrieve(i);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxPrint(`tab_${this.tabIndex + 1}_print`,
      {
        year: this.state.year,
        divicd: this.state.divicd,
        perid: this.state.perid,
      });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxExcel(`tab_${this.tabIndex + 1}_excel`, {
      year: this.state.year,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
  }

  @action
  async doRetrieve(i: ConstructionTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.fxExec(
        `${ConstructionTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [ConstructionTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[ConstructionTabDataStateNames[i]],
              ...items.map((x: any) => new ConstructionTabModels[i](x)),
            ],
          });
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [ConstructionTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieve();
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [ConstructionTabDataStateNames[i]]: [],
    }, async () => {
      await this.infinity?.retrieveAll();

      // tab2 retrive 시 trail값들 setState
      this.SS({
        mon01_tot: this.infinity?.box?.mon01_tot || '0',
        mon02_tot: this.infinity?.box?.mon02_tot || '0',
        mon03_tot: this.infinity?.box?.mon03_tot || '0',
        mon04_tot: this.infinity?.box?.mon04_tot || '0',
        mon05_tot: this.infinity?.box?.mon05_tot || '0',
        mon06_tot: this.infinity?.box?.mon06_tot || '0',
        mon07_tot: this.infinity?.box?.mon07_tot || '0',
        mon08_tot: this.infinity?.box?.mon08_tot || '0',
        mon09_tot: this.infinity?.box?.mon09_tot || '0',
        mon10_tot: this.infinity?.box?.mon10_tot || '0',
        mon11_tot: this.infinity?.box?.mon11_tot || '0',
        mon12_tot: this.infinity?.box?.mon12_tot || '0',
        totmon_tot: this.infinity?.box?.totmon_tot || '0',
        aver_tot: this.infinity?.box?.aver_tot || '0',
      });
      this.updateGraphUrl();
    });
    await this.grid.current?.forceRepaint(true);
  }

  @action
  updateGraphUrl() {
    const { user } = this.props.publicStore;
    this.setState({
      graph: `https://api.elmansoft.com/chart/multi5.php?database=${user?.custcd}&window=w_tb_e471w_03&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_year=${this.state.year}&as_divicd=${this.state.divicd || '%'}&as_perid=${this.state.perid || '%'}`,
      graph2: `https://api.elmansoft.com/chart/multi5.php?database=${user?.custcd}&window=w_tb_e471w_03&type=1&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_year=${this.state.year}&as_divicd=${this.state.divicd || '%'}&as_perid=${this.state.perid || '%'}`,
    });
  }

  getPrintRowCount(): number {
    let rowCount = 0;
    switch (this.tabIndex) {
      case 0:
        rowCount = this.state.constructions.length;
        break;

      case 1:
        rowCount = this.state.saless.length;
        break;
    }
    return rowCount;
  }

  // 상단 grid 색상
  getColotEvent(name: any) {
    switch (name) {
      case '수리공사':
        return { color: 'var(--color-blue)' };

      case '부품교체':
        return { color: 'var(--color-red)' };

      case '설치공사':
        return { color: '' };

      case '리모델링':
        return { color: '#7F7A2C' };

      default:
        return {};
    }
  }

  @computed
  get tabIndex(): ConstructionTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <MonthConstructionTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
