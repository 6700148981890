import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { BreakUnit } from '../BreakUnit';
import { FormatTextBox, TextBox } from '../../../../../components';

// 월별
export const BreakUnitTabAllGridHeader: GLHF<any, BreakUnit> = (scope) => ([
  {
    id: 'receyear',
    text: '년도',
    width: 150,
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####"
        value={x.receyear}
      />
    ),
  },
  {
    id: 'ttot_samt',
    text: '합계',
    color: 'var(--color-blue)',
    width: 150,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        합계: {scope?.state.equptot_tot} 건
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.ttot_samt}
        style={{ color: 'var(--color-blue)' }}
      />
    ),
  },
  {
    id: 'equpnm001',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
        {scope?.state.allList[0]?.equpnm001}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp001,
  },
  {
    id: 'equpnm002',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm002}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp002,
  },
  {
    id: 'equpnm003',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm003}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp003,
  },
  {
    id: 'equpnm004',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm004}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp004,
  },
  {
    id: 'equpnm005',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm005}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp005,
  },
  {
    id: 'equpnm006',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm006}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp006,
  },
  {
    id: 'equpnm007',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm007}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp007,
  },
  {
    id: 'equpnm008',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm008}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp008,
  },
  {
    id: 'equpnm009',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm009}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp009,
  },
  {
    id: 'equpnm010',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm010}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp010,
  },
  {
    id: 'equpnm011',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm011}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp011,
  },
  {
    id: 'equpnm012',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm012}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp012,
  },
  {
    id: 'equpnm013',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm013}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp013,
  },
  {
    id: 'equpnm014',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm014}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp014,
  },
  {
    id: 'equpnm015',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm015}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp015,
  },
  {
    id: 'equpnm016',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm016}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp016,
  },
  {
    id: 'equpnm017',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm017}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp017,
  },
  {
    id: 'equpnm018',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm018}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp018,
  },
  {
    id: 'equpnm019',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm019}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp019,
  },
  {
    id: 'equpnm020',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm020}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp020,
  },
  {
    id: 'equpnm021',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm021}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp021,
  },
  {
    id: 'equpnm022',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm022}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp022,
  },
  {
    id: 'equpnm023',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm023}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp023,
  },
  {
    id: 'equpnm024',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm002}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp024,
  },
  {
    id: 'equpnm025',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm025}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp025,
  },
  {
    id: 'equpnm026',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm026}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp006,
  },
  {
    id: 'equpnm027',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm027}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp027,
  },
  {
    id: 'equpnm028',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm028}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp028,
  },
  {
    id: 'equpnm029',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm029}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp029,
  },
  {
    id: 'equpnm030',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm030}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp030,
  },
  {
    id: 'equpnm031',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm031}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp031,
  },
  {
    id: 'equpnm032',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm032}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp032,
  },
  {
    id: 'equpnm033',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm033}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp033,
  },
  {
    id: 'equpnm034',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm034}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp034,
  },
  {
    id: 'equpnm035',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm035}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp035,
  },
  {
    id: 'equpnm036',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm036}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp036,
  },
  {
    id: 'equpnm037',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm037}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp037,
  },
  {
    id: 'equpnm038',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm038}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp038,
  },
  {
    id: 'equpnm039',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm039}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp039,
  },
  {
    id: 'equpnm040',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm040}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp040,
  },
  {
    id: 'equpnm041',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm041}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp041,
  },
  {
    id: 'equpnm042',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm042}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp042,
  },
  {
    id: 'equpnm043',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm043}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp043,
  },
  {
    id: 'equpnm044',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm044}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp044,
  },
  {
    id: 'equpnm045',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm045}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp045,
  },
  {
    id: 'equpnm046',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm046}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp046,
  },
  {
    id: 'equpnm047',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm047}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp047,
  },
  {
    id: 'equpnm048',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm048}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp048,
  },
  {
    id: 'equpnm049',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm049}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp049,
  },
  {
    id: 'equpnm050',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm050}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp050,
  },
  {
    id: 'equpnm051',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm051}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp051,
  },
  {
    id: 'equpnm052',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm052}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp052,
  },
  {
    id: 'equpnm053',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm053}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp053,
  },
  {
    id: 'equpnm054',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm054}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp054,
  },
  {
    id: 'equpnm055',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm055}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp055,
  },
  {
    id: 'equpnm056',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm056}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp056,
  },
  {
    id: 'equpnm057',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm057}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp057,
  },
  {
    id: 'equpnm058',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm058}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp058,
  },
  {
    id: 'equpnm059',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm059}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp059,
  },
  {
    id: 'equpnm060',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm060}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp060,
  },
  {
    id: 'equpnm061',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm061}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp061,
  },
  {
    id: 'equpnm062',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm062}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp062,
  },
  {
    id: 'equpnm063',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm063}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp063,
  },
  {
    id: 'equpnm064',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm064}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp064,
  },
  {
    id: 'equpnm065',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm065}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp065,
  },
  {
    id: 'equpnm066',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm066}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp066,
  },
  {
    id: 'equpnm067',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm067}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp067,
  },
  {
    id: 'equpnm068',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm068}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp068,
  },
  {
    id: 'equpnm069',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm069}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp069,
  },
  {
    id: 'equpnm070',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm070}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp070,
  },
  {
    id: 'equpnm071',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm071}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp071,
  },
  {
    id: 'equpnm072',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm072}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp072,
  },
  {
    id: 'equpnm073',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm073}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp073,
  },
  {
    id: 'equpnm074',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm074}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp074,
  },
  {
    id: 'equpnm075',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm075}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp075,
  },
  {
    id: 'equpnm076',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm076}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp076,
  },
  {
    id: 'equpnm077',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm077}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp077,
  },
  {
    id: 'equpnm078',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm078}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp078,
  },
  {
    id: 'equpnm079',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm079}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp079,
  },
  {
    id: 'equpnm080',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm080}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp080,
  },
  {
    id: 'equpnm081',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm081}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp081,
  },
  {
    id: 'equpnm082',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm082}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp082,
  },
  {
    id: 'equpnm083',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm083}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp083,
  },
  {
    id: 'equpnm084',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm084}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp084,
  },
  {
    id: 'equpnm085',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm085}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp085,
  },
  {
    id: 'equpnm086',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm086}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp086,
  },
  {
    id: 'equpnm087',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm087}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp087,
  },
  {
    id: 'equpnm088',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm088}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp088,
  },
  {
    id: 'equpnm089',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm089}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp089,
  },
  {
    id: 'equpnm090',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm090}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp090,
  },
  {
    id: 'equpnm091',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm091}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp091,
  },
  {
    id: 'equpnm092',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm092}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp092,
  },
  {
    id: 'equpnm093',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm093}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp093,
  },
  {
    id: 'equpnm094',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm094}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp094,
  },
  {
    id: 'equpnm095',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm095}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp095,
  },
  {
    id: 'equpnm096',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm096}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp096,
  },
  {
    id: 'equpnm097',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm097}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp097,
  },
  {
    id: 'equpnm098',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm098}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp098,
  },
  {
    id: 'equpnm099',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm099}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp099,
  },
  {
    id: 'equpnm100',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm100}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp100,
  },
  {
    id: 'equpnm101',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm101}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp101,
  },
  {
    id: 'equpnm102',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm102}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp102,
  },
  {
    id: 'equpnm103',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm103}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp103,
  },
  {
    id: 'equpnm104',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm104}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp104,
  },
  {
    id: 'equpnm105',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm105}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp105,
  },
  {
    id: 'equpnm106',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm106}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp106,
  },
  {
    id: 'equpnm107',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm107}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp107,
  },
  {
    id: 'equpnm108',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm108}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp108,
  },
  {
    id: 'equpnm109',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm109}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp109,
  },
  {
    id: 'equpnm110',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm110}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp110,
  },
  {
    id: 'equpnm111',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm111}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp111,
  },
  {
    id: 'equpnm112',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm112}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp112,
  },
  {
    id: 'equpnm113',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm113}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp113,
  },
  {
    id: 'equpnm114',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm114}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp114,
  },
  {
    id: 'equpnm115',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm115}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp115,
  },
  {
    id: 'equpnm116',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm116}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp116,
  },
  {
    id: 'equpnm117',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm117}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp117,
  },
  {
    id: 'equpnm118',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm118}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp118,
  },
  {
    id: 'equpnm119',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm119}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp119,
  },
  {
    id: 'equpnm120',
    text: (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope?.state.allList[0]?.equpnm120}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    value: (x) => x.equp120,
  },
]);
