import { computed } from 'mobx';

/**
 * 모델
 * @window w_input_da026_all
 * @category 입금현황
 */
export class DepositInputDetailModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;


  /**
   * 입금일자
   *
   * **data type** varchar(50)
   */
  rcvdate: string;

  /**
   * 입금번호
   *
   * **data type** float
   */
  rcvnum: string;

  /**
   * 거래처명
   *
   * **data type** varchar(10)
   */
  cltnm: string;

  /**
   * 입금액
   *
   * **data type** char(8)
   */
  iamt: string;

  /**
   * 발행구분
   *
   * **data type** float
   */
  billgubun: string;

  /**
   * 발행구분
   *
   * **data type** float
   */
  cltcd: string;

  /**
   * 발행구분
   *
   * **data type** varchar(10)
   */
  accyn: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.billgubun = data.billgubun || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvnum = data.rcvnum || '';
    this.iamt = data.iamt || '';
    this.accyn = data.accyn || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
