import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { TopDepartmentTemplate } from './TopDepartment.template';
import { TopDepartmentModel } from './TopDepartment.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


interface TopDepartmentState {
  searchQuery: string;
  data: Array<TopDepartmentModel>;
  focused?: TopDepartmentModel;
}

/**
 * 컨트롤러
 * @window w_pz008
 * @category 상위부서
 */
export class TopDepartment extends PageComponent<PageProps, TopDepartmentState>
  implements PageToolEvents {
  updatedRows?: Array<TopDepartmentModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new TopDepartmentModel(x))],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        this.state.data.length > 0 && this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    await this.infinity?.retrieveAll();
    await this.table.current?.update(true);
    this.state.data.length > 0 && this.table.current?.setFocus(0, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new();

    data && this.setState({
      data: [...this.state.data, new TopDepartmentModel(data, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.data.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      await this.table.current?.resetUpdates();
    }
    await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.kukcd} - ${this.state.focused?.kuknm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: TopDepartmentModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<TopDepartmentModel>, updatedRows: Array<TopDepartmentModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <TopDepartmentTemplate scope={this} />;
  }
}
