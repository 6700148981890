import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ServerancePayRegister } from './ServerancePayRegister';
import { SearchBinding } from '../../../../models/common';
import { Date6Calculator } from '../../../../utils/time';
import { Format } from '../../../../utils/string';


/**
 * 화면
 * @window w_tb_pb301_01
 * @category 퇴직금등록
 */
export const ServerancePayRegisterTemplate: React.FC<TemplateProps<ServerancePayRegister>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ yearmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>사원명</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onBtnRetriveEvent()}>
          <div style={{ width: 110 }}>새로고침</div>
        </Button>

        <Button onClick={() => scope.onButtonExcel()}>
          <div style={{ width: 110 }}>대량이체파일생성</div>
        </Button>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'seq',
            text: '순서',
            width: 8,
          },
          {
            id: 'perid',
            text: '사번',
            width: 8,
            color: 'var(--color-red)',
            render: (x, rowUpdate, refs) => (
              <TextBox
                ref={refs}
                value={x.perid}
                textAlign="center"
                onChange={(value) => rowUpdate({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    rowUpdate({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
            ),
          },
          {
            id: 'pernm',
            text: '사원명',
            width: 8,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Format.number(x.pernm)}
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '구분',
            width: 8,
            render: (x, rowUpdate) => (
              <ComboBox
                style={{ color: x.gubun === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={x.gubun}
                textAlign="center"
                data={[
                  { value: '0', remark: '적립' },
                  { value: '1', remark: '정산' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => {
                  rowUpdate({ gubun: option.value });
                  scope.onIntemChange('gubun', option.value);
                }}
              />
            ),
          },
          {
            id: 'retiredate',
            text: '적용일자',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <DateTextBox
                ref={ref}
                format="####-##-##"
                value={x.retiredate}
                textAlign="center"
                onChange={(value) => rowUpdate({ retiredate: value })}
              />
            ),
          },
          {
            id: 'tot_samt',
            text: '급여(세전)',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.tot_samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'retireamt',
            text: '퇴직적립금액',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="right"
                value={x.retireamt}
                onChange={
                  (value) => { rowUpdate({ retireamt: value }); }
                }
              />
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 28,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.remark}
                onChange={(value) => rowUpdate({ remark: value })}
              />
            ),
          },
          {
            id: 'be_retireamt',
            text: '누적퇴직적립금액',
            width: 10,
            render: (x, rowUpdate, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="center"
                value={x.be_retireamt}
                onChange={
                  (value) => { rowUpdate({ be_retireamt: value }); }
                }
              />
            ),
          },
        ]}
        data={scope.state.data}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      />
    </FlexLayout>
  );
};
