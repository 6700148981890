import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640w_02
 * @category 매입세금계산서[미지급현황]
 */
export class PurchaseTaxModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 매입일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 만료일자
   *
   * **data type** varchar(8)
   */
  icdate: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  mijcltnm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 항목명
   *
   * **data type** varchar(100)
   */
  artnm: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 매입금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 경비증명
   *
   * **data type** varchar(1)
   */
  proof: string;

  /**
   * 미지급액
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  sndamt: string;

  /**
   * 수주금액
   *
   * **data type** number
   */
  ordamt: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 수주집행률
   *
   * **data type** number
   */
  ording: string;

  /**
   * 매출집행률
   *
   * **data type** number
   */
  misfer: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.icdate = data.icdate || '';
    this.ording = data.ording || '';
    this.misfer = data.misfer || '';
    this.mijcltnm = data.mijcltnm || '';
    this.projnm = data.projnm || '';
    this.artnm = data.artnm || '';
    this.remark = data.remark || '';
    this.mijamt = data.mijamt || '';
    this.proof = data.proof || '';
    this.iamt = data.iamt || '';
    this.sndamt = data.sndamt || '';
    this.ordamt = data.ordamt || '';
    this.misamt = data.misamt || '';
    this.rcvamt = data.rcvamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
