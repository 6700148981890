import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  CheckBox,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  LabelBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { SafetyEducation } from './SafetyEducation';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e521
 * @category 안전교육일지
 */

export const SafetyEducationTemplate: React.FC<TemplateProps<SafetyEducation>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'chk',
              text: (
                <FlexLayout
                  isVertical={true}
                >
                  <FlexLayout>출력</FlexLayout>
                  <CheckBox
                    value={scope.state.allChk}
                    onChange={(value) => scope.onCehckAll(value)}
                    isDisabledTrackingStateChange={true}
                  >
                  </CheckBox>
                </FlexLayout>
              ),
              width: 50,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.prtchk === '1'}
                  onChange={(value) => rowUpdate({ prtchk: value ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'safedate',
              text: '일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.safedate)}
                </FlexLayout>
              ),
            },
            {
              id: 'title',
              text: '제목',
              width: 250,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'safepernm',
              text: '교육자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.safepernm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.safetyEducationList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          headerHeight={38}
          onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.safedate}
                textAlign="center"
                format="####-##-##"
                onChange={
                  (value) => setData({ safedate: value })
                }
                // onBlur={() => scope.itemChanged(ExpenditureItemChangeTypes.INPUTDATE)}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format='##-######-###-####'
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.inperid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.inpernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.inrspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.indivinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="교육자"
              weight={1}
            >
              <TextBox
                textAlign="center"
                value={model?.safepernm}
                onChange={(value) => setData({ safepernm: value })}
              />
            </LabelBox>
            <LabelBox title="교육시간">
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.sttime}
                onChange={(value) => setData({ sttime: value })}
              />
              <span style={{
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}> ~
              </span>
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.endtime}
                onChange={(value) => setData({ endtime: value })}
              />
            </LabelBox>
            <LabelBox
              title="제목"
              weight={3}
            >
              <TextBox
                value={model.title}
                onChange={(value) => setData({ title: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={250}>
            <LabelBox title="안전지시사항" >
              <TextBox
                isMultiline={true}
                value={model?.saferemark}
                onChange={(value) => setData({ saferemark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="기타사항" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
