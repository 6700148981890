import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Worker } from '../Worker';
import { WorkerTabContractGridHeader } from '../headers/WorkerTabContractGridHeader';

/**
 * 화면 > 탭 - 계약사항
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTabContractTemplate: React.FC<TemplateProps<Worker>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <GridLayout
        header={WorkerTabContractGridHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataContract}
      />
    </FlexLayout>
  );
};
