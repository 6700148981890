import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da003_03w
 * @category 월별프로젝트현황
 */
export class MonthProjectsModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 년도
   *
   * **data type** varchar(8)
   */
  // ordyear: string;

  /**
   * 검사년도
   *
   * **data type** varchar(8)
   */
  kcyear: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  gubunnm: string;

  /**
   * 01월
   *
   * **data type** number
   */
  mon01: string;

  /**
   * 02월
   *
   * **data type** number
   */
  mon02: string;

  /**
   * 03월
   *
   * **data type** number
   */
  mon03: string;

  /**
   * 04월
   *
   * **data type** number
   */
  mon04: string;

  /**
   * 05월
   *
   * **data type** number
   */
  mon05: string;

  /**
   * 06월
   *
   * **data type** number
   */
  mon06: string;

  /**
   * 07월
   *
   * **data type** number
   */
  mon07: string;

  /**
   * 08월
   *
   * **data type** number
   */
  mon08: string;

  /**
   * 09월
   *
   * **data type** number
   */
  mon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  mon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  mon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  mon12: string;


  /**
   * 상태
   *
   * **data type** varchar(1)
   */
  state: string;

  /**
   * 구분
   *
   * **data type** varchar(5)
   */
  gubun: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 프로젝트일자
   *
   * **data type** varchar(8)
   */
  prodate: string;

  /**
   * 수주금액
   *
   * **data type** number
   */
  camt: string;

  /**
   * 발주금액
   *
   * **data type** number
   */
  balamt: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 소요경비
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 수금여부
   *
   * **data type** varchar(1)
   */
  rcvflag: string;

  // /**
  //  * 계약월
  //  *
  //  * **data type** varchar(8)
  //  */
  // ordmon: string;
  //
  // /**
  //  * 계약일자
  //  *
  //  * **data type** varchar(8)
  //  */
  // orddate: string;

  /**
   * 검사월
   *
   * **data type** varchar(8)
   */
  kcmon: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  kcdate: string;

  /**
   * 계약번호
   *
   * **data type** varchar(4)
   */
  ordnum: string;

  /**
   * 현장건수
   *
   * **data type** long
   */
  actcnt: string;

  /**
   * 손익률
   *
   * **data type** number
   */
  resuyul: string;

  /**
   * 미매출잔액
   *
   * **data type** number
   */
  resuamt2: string;

  /**
   * 손익액
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 손익률
   *
   * **data type** number
   */
  resupercnt: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totmon: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.resuamt = data.resuamt || '';
    this.resupercnt = data.resupercnt || '';
    this.spjangcd = data.spjangcd || '';
    this.totmon = data.totmon || '';
    this.projno = data.projno || '';
    this.resuamt2 = data.resuamt2 || '';
    this.resuyul = data.resuyul || '';
    this.prodate = data.prodate || '';
    this.projnm = data.projnm || '';
    // this.ordyear = data.ordyear || '';
    this.kcyear = data.kcyear || '';
    this.gubunnm = data.gubunnm || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    // this.orddate = data.orddate || '';
    this.kcdate = data.kcdate || '';
    this.ordnum = data.ordnum || '';
    this.state = data.state || '';
    this.gubun = data.gubun || '';
    this.camt = data.camt || '';
    this.balamt = data.balamt || '';
    this.misamt = data.misamt || '';
    this.mijamt = data.mijamt || '';
    this.rcvflag = data.rcvflag || '';
    // this.ordmon = data.ordmon || '';
    // this.orddate = data.orddate || '';
    this.kcmon = data.kcmon || '';
    this.kcdate = data.kcdate || '';
    this.ordnum = data.ordnum || '';
    this.actcnt = data.actcnt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
