import { computed } from 'mobx';

/**
 * 상세모델
 * @window w_tb_ca611_01
 * @category 입고등록
 */

export class StockDetailModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly datetime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ibgdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly ibgnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ibgseq: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly pserial: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly balqty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly bigo: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly mijflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly balseq: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly qcdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly qcnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly qcseq: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly idate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly inum: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly iseq: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly store: string;

  readonly myn: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.datetime = data.datetime || '';
    this.ibgdate = data.ibgdate || '';
    this.ibgnum = data.ibgnum || '';
    this.ibgseq = data.ibgseq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.pserial = data.pserial || '';
    this.qty = data.qty || '';
    this.balqty = data.balqty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.bigo = data.bigo || '';
    this.mijflag = data.mijflag || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balseq = data.balseq || '';
    this.qcdate = data.qcdate || '';
    this.qcnum = data.qcnum || '';
    this.qcseq = data.qcseq || '';
    this.projno = data.projno || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.idate = data.idate || '';
    this.inum = data.inum || '';
    this.iseq = data.iseq || '';
    this.store = data.store || '';
    this.myn = data.myn || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
