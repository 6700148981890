import * as React from 'react';
import {
  Button, FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabGridSujuHeader } from '../headers';

/**
 * 화면 > 상세 > 탭 - 수주내역
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabSuju: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <Button width={85} onClick={() => scope.openSujuPaper()}>수주서등록</Button>
    </FlexLayout>

    <GridLayout
      header={RegistTabGridSujuHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.tabDataSuju}
    />
  </FlexLayout>
);
