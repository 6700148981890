import * as React from 'react';
import { IFrame } from '../../../../components/forms/IFrame';
import { PageProps } from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';

export class Pairing extends React.Component<PageProps> {
  componentDidMount(): void {
    this.props.publicStore.setCurrentPage(this);
  }

  render() {
    const { user } = this.props.publicStore;
    const url = `https://api.elmansoft.com/chart/teams.php?database=${user.custcd}`;

    return (
      <FlexLayout
        align="left"
        justify="left">
      <IFrame src={url} />
      </FlexLayout>
    );
  }
}
