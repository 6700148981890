import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { Global } from '../../constants';
import { SubHeader } from '../../components/layout/SubHeader';
import {
  CheckBox,
  FormatTextBox,
  TableLayout,
} from '../../components';

interface ModalInstallProps {
  modalStore: ModalStore;
}

@observer
export class ModalInstall extends React.Component<ModalInstallProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1200}
        height={700}
        isVisible={modalStore.isVisibleInstallationSelector}
        onClose={() => modalStore.closeInstallationSelector()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>설치현장 -{'>'} 보수현장</LayoutTitle>

          <TableLayout
            ref={modalStore.InstallationTable}
            header={[
              {
                id: 'scokflag',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={modalStore.installTotalCheck}
                      onChange={async (value) => modalStore
                        .installationUpdateCheckAllToggle(value)}
                    />
                  </FlexLayout>
                ),
                width: 5,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.scokflag === '1'}
                      onChange={(value) => {
                        rowUpdate({
                          scokflag: value ? '1' : '0',
                          scokpernm: value ? modalStore.expirationUser : '',
                        });
                      }}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'scokpernm',
                text: '확인자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.scokpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'scactnm',
                text: '설치현장명',
                width: 45,
                trail: () => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    합계: {modalStore.installationTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.scactnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '납기일',
                width: 10,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    ref={ref}
                    format="####-##-##"
                    textAlign="center"
                    value={x.enddate}
                    onChange={(value) => rowUpdate({ enddate: value })}/>
                ),
              },
              {
                id: 'contstdate',
                text: '무상시작일',
                width: 10,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    ref={ref}
                    format="####-##-##"
                    textAlign="center"
                    value={x.contstdate}
                    onChange={(value) => rowUpdate({ contstdate: value })}/>
                ),
              },
              {
                id: 'contenddate',
                text: '무상만료일',
                width: 10,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    ref={ref}
                    format="####-##-##"
                    textAlign="center"
                    value={x.contenddate}
                    onChange={(value) => rowUpdate({ contenddate: value })}/>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={modalStore.installationData}
            onRowFocusEvent={(item) => {
              modalStore.installationSelected = item;
            }}
            onChange={(rows) => {
              modalStore.installationData = rows;
            }}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => modalStore.installSave()}>확인</Button>
              <Button onClick={() => modalStore.closeInstallationSelector()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
