import { computed } from 'mobx';

/**
 * 학력 모델
 * @window w_p2110
 * @category 인사등록
 */
export class AwardModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  readonly flag: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  readonly panaltycd: string;

  /**
   * 세부사항
   *
   * **data type** varchar(100)
   */
  readonly panaltycnm: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  readonly panaltyday: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  /**
   * 옵션박스 ischanged
   *
   * **data type** boolean
   */
  readonly isChanged: boolean;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.flag = data.flag || '';
    this.panaltycd = data.panaltycd || '';
    this.panaltycnm = data.panaltycnm || '';
    this.panaltyday = data.panaltyday || '';
    this.new = isNew ? '1' : '0';
    this.isChanged = !!data.isChanged;
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
