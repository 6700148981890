import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da026_cms
 * @category CMS자동이체
 */
export class CMSAutoModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 순번
   *
   * **data type** varchar(5)
   */
  seq: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 출력거래처명
   *
   * **data type** varchar(255)
   */
  prtcltnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(20)
   */
  saupnum: string;

  /**
   * 생일
   *
   * **data type** varchar(20)
   */
  birthday: string;

  /**
   * 은행
   *
   * **data type** varchar(50)
   */
  banknm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(50)
   */
  accnum: string;

  /**
   * 예금주명
   *
   * **data type** varchar(100)
   */
  accnm: string;

  /**
   * 납부자번호
   *
   * **data type** varchar(13)
   */
  autonum: string;

  /**
   * 에러사항
   *
   * **data type** char(255)
   */
  remark: string;

  /**
   * 입력일
   *
   * **data type** varchar(2)
   */
  idate: string;

  /**
   * 정기금액
   *
   * **data type** float
   */
  jamt: string;

  /**
   * 미납금액
   *
   * **data type** float
   */
  misamt: string;

  /**
   * 출금신청금액
   *
   * **data type** float
   */
  iamt: string;

  /**
   * 불능금액
   *
   * **data type** float
   */
  notamt: string;

  /**
   * 출금성공금액
   *
   * **data type** float
   */
  rcvamt: string;

  /**
   * 수수료
   *
   * **data type** float
   */
  bmar: string;

  /**
   * 지부1
   *
   * **data type** varchar(20)
   */
  jibu1: string;

  /**
   * 지부2
   *
   * **data type** varchar(20)
   */
  jibu2: string;

  /**
   * 지부3
   *
   * **data type** varchar(20)
   */
  jibu3: string;

  /**
   * 납입회차
   *
   * **data type** varchar(5)
   */
  rcvseq: string;

  /**
   * 입금일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 연락처
   *
   * **data type** varchar(20)
   */
  tel: string;

  /**
   * 휴대폰
   *
   * **data type** varchar(20)
   */
  hpnum: string;

  /**
   * 주소
   *
   * **data type** varchar(255)
   */
  address: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * CMS연결 매출/입금
   *
   * **data type** varchar(1)
   */
  rcvflag: string;

  /**
   * 출금여부
   *
   * **data type** varchar(1)
   */
  flag: string;

  /**
   * 거래처매칭
   *
   * **data type** varchar(1)
   */
  cltflag: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 고객명
   *
   * **data type** varchar(50)
   */
  cltcd: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  rcvnum: string;

  /**
   * 출금일
   *
   * **data type** varchar(8)
   */
  outdate: string;

  /**
   * cms일자
   *
   * **data type** varchar(8)
   */
  cmsdate: string;

  /**
   * 저장시간
   *
   * **data type** datetime
   */
  savedatetime: string;

  /**
   * 저장일자
   *
   * **data type** varchar(8)
   */
  savedate: string;

  /**
   * 건수
   *
   * **data type** long
   */
  cnt: string;

  /**
   * ERP 매출/입금
   *
   * **data type** varchar(1)
   */
  erp_rcvflag: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  appgubun: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.appgubun = data.appgubun || '';
    this.spjangcd = data.spjangcd || '';
    this.savedate = data.savedate || '';
    this.seq = data.seq || '';
    this.cltnm = data.cltnm || '';
    this.prtcltnm = data.prtcltnm || '';
    this.pernm = data.pernm || '';
    this.saupnum = data.saupnum || '';
    this.birthday = data.birthday || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.accnm = data.accnm || '';
    this.autonum = data.autonum || '';
    this.remark = data.remark || '';
    this.idate = data.idate || '';
    this.jamt = data.jamt || '';
    this.misamt = data.misamt || '';
    this.iamt = data.iamt || '';
    this.notamt = data.notamt || '';
    this.rcvamt = data.rcvamt || '';
    this.bmar = data.bmar || '';
    this.jibu1 = data.jibu1 || '';
    this.jibu2 = data.jibu2 || '';
    this.jibu3 = data.jibu3 || '';
    this.rcvseq = data.rcvseq || '';
    this.rcvdate = data.rcvdate || '';
    this.tel = data.tel || '';
    this.hpnum = data.hpnum || '';
    this.address = data.address || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.chk = data.chk || '';
    this.rcvflag = data.rcvflag || '';
    this.flag = data.flag || '';
    this.cltflag = data.cltflag || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.cltcd = data.cltcd || '';
    this.rcvnum = data.rcvnum || '';
    this.outdate = data.outdate || '';
    this.cmsdate = data.cmsdate || '';
    this.savedatetime = data.savedatetime || '';
    this.savedate = data.savedate || '';
    this.cnt = data.cnt || '';
    this.erp_rcvflag = data.erp_rcvflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
