import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  SearchLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { MonthProjects } from './MonthProjects';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { Sum } from '../../../../utils/array';

const states = [
  { value: '0', remark: '진행중' },
  { value: '1', remark: '완료' },
  { value: '2', remark: '취소' },
];

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면
 * @window w_tb_da003_03w
 * @category 월별프로젝트현황
 */
export const MonthProjectsTemplate: React.FC<TemplateProps<MonthProjects>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  let sumkcdate: number;

  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => setData({ year: value })}
          onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                setData({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                setData({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span>구분</span>
        <ComboBox
          value={scope.state.searchGubun}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
          onSelect={(option) => scope.setState({ searchGubun: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span>상태</span>
        <ComboBox
          value={scope.state.state}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '진행중' },
            { value: '1', remark: '완료' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ state: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'gubunnm',
              text: '구분',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <FlexLayout style={scope.getColotEvent(x.gubunnm)}>
                    {x.gubunnm}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'mon01',
              text: '01월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}01`)}
                >
                  {Format.number(x.mon01)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon02',
              text: '02월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}02`)}
                >
                  {Format.number(x.mon02)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon03',
              text: '03월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}03`)}
                >
                  {Format.number(x.mon03)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon04',
              text: '04월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}04`)}
                >
                  {Format.number(x.mon04)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon05',
              text: '05월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}05`)}
                >
                  {Format.number(x.mon05)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon06',
              text: '06월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}06`)}
                >
                  {Format.number(x.mon06)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon07',
              text: '07월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}07`)}
                >
                  {Format.number(x.mon07)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon08',
              text: '08월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}08`)}
                >
                  {Format.number(x.mon08)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon09',
              text: '09월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}09`)}
                >
                  {Format.number(x.mon09)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon10',
              text: '10월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}10`)}
                >
                  {Format.number(x.mon10)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon11',
              text: '11월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}11`)}
                >
                  {Format.number(x.mon11)}
                </FlexLayout>
              ),
            },
            {
              id: 'mon12',
              text: '12월',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                  onClick={() => scope.onRowColorFocusEvent(`${scope.state.year}12`)}
                >
                  {Format.number(x.mon12)}
                </FlexLayout>
              ),
            },
            {
              id: 'totmon',
              text: '합계',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={scope.getColotEvent(x.gubunnm)}
                >
                  {Format.number(x.totmon)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid2}
          header={[
            {
              id: 'state',
              text: '상태',
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    color: x.state === '1'
                      ? 'var(--color-blue)'
                      : x.state === '2'
                        ? 'var(--color-red)' : '',
                  }}
                >
                  {states.filter((y) => y.value === x.state)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '구분',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'projno',
              text: '번호',
              width: 100,
            },
            {
              id: 'projnm',
              text: '프로젝트명',
              width: 220,
              sum: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####년##월"
                  value= {x.kcdate.substr(0, 6)}
                />
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.projnm}
                </FlexLayout>
              ),
            },
            {
              id: 'mismon',
              text: '발생월',
              isHidden: true,
              group: true,
              value: (x) => x.kcdate?.substr(0, 6),
            },
            {
              id: 'kcdate',
              text: '검사일자',
              width: 100,
              sum: (x, data) => {
                const sumKcdate = Sum.groupLength(data, 'kcdate', x, ['mismon', x.kcdate?.substr(0, 6)]);
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {sumKcdate} 건
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.kcdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'camt',
              text: '수주금액',
              width: 100,
              sum: (x, data) => {
                const sumCamt = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'camt',
                );

                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumCamt)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.camt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.camt)}
                </FlexLayout>
              ),
            },
            {
              id: 'balamt',
              text: '발주금액',
              width: 100,
              sum: (x, data) => {
                const sumBalamt = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'balamt',
                );

                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumBalamt)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.balamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.balamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '매출금액',
              width: 100,
              sum: (x, data) => {
                const sumMisamt = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'misamt',
                );

                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMisamt)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.misamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'mijamt',
              text: '소요경비',
              width: 100,
              sum: (x, data) => {
                const sumMijamt = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'mijamt',
                );

                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMijamt)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mijamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mijamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvflag',
              text: '수금여부',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.rcvflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                >
                  {x.rcvflag === '1' ? '수금' : '미수금'}
                </FlexLayout>
              ),
            },
            {
              id: 'resuamt',
              text: '손익액',
              width: 100,
              sum: (x, data) => {
                const sumMisamt = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'resuamt',
                );

                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMisamt)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.resuamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.resuamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'resuyul',
              text: '손익률',
              width: 80,
              sum: (x, data) => {
                const sumResuyul = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'resuyul',
                );

                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#5B1A86' }}
                  >
                    {(parseInt(String(sumResuyul), 10) / sumkcdate).toFixed(2)}%
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {scope.state.resuyul_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#5B1A86' }}
                >
                  {x.resuyul === '.00%' ? '%' : x.resuyul}
                </FlexLayout>
              ),
            },
            {
              id: 'resuamt2',
              text: '미매출잔액',
              width: 100,
              sum: (x, data) => {
                const sumResuamt2 = Sum.all(
                  data.filter((y) => y.kcdate.substr(0, 6) === x.kcdate.substr(0, 6)),
                  'resuamt2',
                );
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumResuamt2)}
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.resuamt2_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.resuamt2)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.monthProjectList}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
