import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca647
 * @category 비용항목등록
 */
export class CostClauseModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(2)
   */
  gartcd: string;

  /**
   * 그룹명
   *
   * **data type** varchar(50)
   */
  gartnm: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 매입코드
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 매입항목
   *
   * **data type** varchar(50)
   */
  artnm: string;

  /**
   * 고정비
   *
   * **data type** varchar(1)
   */
  jflag: string;

  /**
   * 비용분류
   *
   * **data type** varchar(1)
   */
  gflag: string;

  /**
   * 비용계정
   *
   * **data type** number
   */
  acccd: string;

  /**
   * 비용계정명
   *
   * **data type** varchar(4)
   */
  accnm: string;

  /**
   * 원가계정
   *
   * **data type** varchar(4)
   */
  acccd2: string;

  /**
   * 원가계정명
   *
   * **data type** varchar(4)
   */
  accnm2: string;

  /**
   * 매입구분
   *
   * **data type** varchar(4)
   */
  wkgubun: string

  /**
   * new 상태변화값
   *
   * **data type** varchar(4)
   */
  isChanged: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.gartcd = data.gartcd || '';
    this.gartnm = data.gartnm || '';
    this.useyn = data.useyn || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.remark = data.remark || '';
    this.artcd = data.artcd || '';
    this.artnm = data.artnm || '';
    this.jflag = data.jflag || '';
    this.gflag = data.gflag || '';
    this.acccd = data.acccd || '';
    this.accnm = data.accnm || '';
    this.acccd2 = data.acccd2 || '';
    this.accnm2 = data.accnm2 || '';
    this.isChanged = data.isChanged || '';
    this.wkgubun = data.wkgubun || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
