import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { WalkDownTemplate } from './WalkDown.template';
import { InfinityRetrieve } from '../../../../models/common';
import { WalkDownModel } from './models/WalkDown.model';
import { Today } from '../../../../utils/time';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils';

interface WalkDownState {

  // 검색조건
  searchQuery: string; // 검색어
  year: string; // 년도
  store: string; // 창고
  storenm: string; // 창고명
  qtyflag: string; // 재고유무
  jqtyflag: string; // 적정재고
  idate: string; // 실사일자
  useyn: string; // 사용유무

  // 통계
  total: string;
  qty_tot: string;
  xqty_tot: string;
  iqty_tot: string;
  cqty_tot: string;
  bqty_tot: string;

  walkDownList: Array<WalkDownModel>;
  data: WalkDownModel;
  focusedWalkDown?: WalkDownModel;

  // 팝업
  loadDetailModal: boolean; // 상세내역 불러오기
  popupList: Array<WalkDownModel>;
}

/**
 * 컨트롤러
 * @window w_tb_ca602
 * @category 재고실사등록
 */
export class WalkDown extends PageComponent<PageProps, WalkDownState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  updatedRows?: Array<WalkDownModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      year: Today.year(),
      store: '',
      storenm: '',
      qtyflag: '%',
      jqtyflag: '%',
      idate: `${year}${month}${date}`,
      data: new WalkDownModel(),
      walkDownList: [],
      popupList: [],
      loadDetailModal: false,
      useyn: '%',

      total: '',
      qty_tot: '',
      xqty_tot: '',
      iqty_tot: '',
      cqty_tot: '',
      bqty_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    ConfirmWarning.show('확인', '먼저 창고를 선택해주세요.');
  }

  @action
  async onRetrieveEvent() {
    if (!this.state.store) {
      ConfirmWarning.show('확인', '먼저 창고를 선택해주세요.');
      return;
    }

    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        year: this.state.year,
        store: this.state.store,
        qtyflag: this.state.qtyflag,
        jqtyflag: this.state.jqtyflag,
        useyn: this.state.useyn,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          walkDownList: [...this.state.walkDownList, ...items],
        }, () => this.table.current?.update(false));
      },
      async () => {
        await this.SS({ walkDownList: [] });
        await this.infinity?.retrieveAll();
        this.table.current?.update();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      walkDownList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.setState({
        total: this.infinity?.box?.total,
        qty_tot: this.infinity?.box?.qty_tot || '0',
        xqty_tot: this.infinity?.box?.xqty_tot || '0',
        iqty_tot: this.infinity?.box?.iqty_tot || '0',
        cqty_tot: this.infinity?.box?.cqty_tot || '0',
        bqty_tot: this.infinity?.box?.bqty_tot || '0',
      }, () => this.table.current?.update());
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const checkData: any = [];
    this.updatedRows?.forEach((x: any) => {
      checkData.push({
        pcode: x.pcode,
        remark: x.remark,
        qty: x.qty,
        new: '1',
      });
    });

    if (await api.save({
      year: this.state.year,
      store: this.state.store,
      idate: this.state.idate,
      items: checkData,
    }, this.state.data?.new === '1')) {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.walkDownList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      year: this.state.year,
      store: this.state.store,
      qtyflag: this.state.qtyflag,
      jqtyflag: this.state.jqtyflag,
      useyn: this.state.useyn,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.walkDownList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      year: this.state.year,
      store: this.state.store,
      qtyflag: this.state.qtyflag,
      jqtyflag: this.state.jqtyflag,
      useyn: this.state.useyn,
    });
  }

  @action
  onUpdatedRows(rows: Array<WalkDownModel>, updatedRows: Array<WalkDownModel>) {
    this.updatedRows = updatedRows;
    this.setState({ walkDownList: rows });
  }

  @action
  onRowFocusEvent(item:WalkDownModel) {
    this.setState({ focusedWalkDown: item });
  }

  @action
  async loadDetailModal(isOpen: boolean) {
    this.setState({ loadDetailModal: isOpen });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity2 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca602',
        year: this.state.year,
        store: this.state.store,
        pcode: this.state.focusedWalkDown?.pcode,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          popupList: [...this.state.popupList, ...items],
        });
      },
      async () => {
        await this.SS({ popupList: [] });
        await this.infinity2?.retrieveAll();
        this.popupGrid.current?.setFocus(0);
      },
    );

    this.setState({
      popupList: [],
    }, async () => {
      await this.infinity2?.retrieveAll();
      this.popupGrid.current?.setFocus(0);
    });
  }

  render() {
    return (
      <WalkDownTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new WalkDownModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
