import { computed } from 'mobx';

/**
 * 매출별 모델
 * @window w_tb_e471w_03
 * @category 월별 공사실적내역
 */
export class SalesModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  gubun: string;

  /**
   * 구분
   *
   * **data type** varchar(40)
   */
  gubunnm: string;

  /**
   * 1월
   *
   * **data type** number
   */
  mon01: string;

  /**
   * 2월
   *
   * **data type** number
   */
  mon02: string;

  /**
   * 3월
   *
   * **data type** number
   */
  mon03: string;

  /**
   * 4월
   *
   * **data type** number
   */
  mon04: string;

  /**
   * 5월
   *
   * **data type** number
   */
  mon05: string;

  /**
   * 6월
   *
   * **data type** number
   */
  mon06: string;

  /**
   * 7월
   *
   * **data type** number
   */
  mon07: string;

  /**
   * 8월
   *
   * **data type** number
   */
  mon08: string;

  /**
   * 9월
   *
   * **data type** number
   */
  mon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  mon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  mon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  mon12: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totmon: string;

  /**
   * 평균
   *
   * **data type** number
   */
  aver: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.gubun = data.gubun || '';
    this.custcd = data.custcd || '';
    this.gubunnm = data.gubunnm || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.totmon = data.totmon || '';
    this.aver = data.aver || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
