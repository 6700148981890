import { computed } from 'mobx';

/**
 * 모델
 * @window w_xa012
 * @category 사업자정보등록
 */

export class SpjangModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** char(2)
   */
  readonly spjangcd: string;

  /**
   * 사업자등록번호
   *
   * **data type** varchar(13)
   */
  readonly saupnum: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  readonly spjangnm: string;

  /**
   * 단축사업자명
   *
   * **data type** varchar(40)
   */
  readonly compnm: string;

  /**
   * 대표자명
   *
   * **data type** varchar(30)
   */
  readonly prenm: string;

  /**
   * 개업일자
   *
   * **data type** varchar(8)
   */
  readonly openymd: string;

  /**
   * X
   *
   * **data type** varchar(1)
   */
  readonly custperclsf: string;

  /**
   * 법인(주민)번호
   *
   * **data type** varchar(13)
   */
  readonly corpnum: string;

  /**
   * X
   *
   * **data type** varchar(60)
   */
  readonly jointsaup: string;

  /**
   * 세금계산서소재지
   *
   * **data type** varchar(6)
   */
  readonly zipcd: string;

  /**
   * 세금계산서소재지-위도
   *
   * **data type** varchar(60)
   */
  readonly adresa: string;

  /**
   * 세금계산서소재지-경도
   *
   * **data type** varchar(60)
   */
  readonly adresb: string;

  /**
   * 사업장소재지
   *
   * **data type** varchar(6)
   */
  readonly zipcd2: string;

  /**
   * 사업장소재지-위도
   *
   * **data type** varchar(60)
   */
  readonly adres2a: string;

  /**
   * 사업장소재지-경도
   *
   * **data type** varchar(60)
   */
  readonly adres2b: string;

  /**
   * 업태
   *
   * **data type** varchar(40)
   */
  readonly biztype: string;

  /**
   * 종목
   *
   * **data type** varchar(40)
   */
  readonly item: string;

  /**
   * 전화번호
   *
   * **data type** varchar(30)
   */
  readonly tel1: string;

  /**
   * 발신번호(가상)
   *
   * **data type** varchar(30)
   */
  readonly tel2: string;

  /**
   * 팩스
   *
   * **data type** varchar(30)
   */
  readonly fax: string;

  /**
   * X
   *
   * **data type** varchar(2)
   */
  readonly buzclaif: string;

  /**
   * X
   *
   * **data type** varchar(3)
   */
  readonly comtaxoff: string;

  /**
   * 메일주소
   *
   * **data type** varchar(30)
   */
  readonly emailadres: string;

  /**
   * 비밀번호
   *
   * **data type** varchar(4)
   */
  readonly passwd: string;

  /**
   * X
   *
   * **data type** varchar(2)
   */
  readonly astvaluemet: string;

  /**
   * 전화번호1
   *
   * **data type** varchar(30)
   */
  readonly agnertel1: string;

  /**
   * 전화번호2
   *
   * **data type** varchar(30)
   */
  readonly agnertel2: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly eddate: string;

  /**
   * 부서구분
   *
   * **data type** varchar(1)
   */
  readonly operdivsn: string;

  /**
   * 실번호
   *
   * **data type** varchar(30)
   */
  readonly tel3: string;

  /**
   * 일괄결재
   *
   * **data type** varchar(1)
   */
  readonly allpay: string;

  /**
   * 반결재
   *
   * **data type** varchar(1)
   */
  readonly halfpay: string;

  /**
   * X
   *
   * **data type** varchar(6)
   */
  readonly taxagentcd: string;

  /**
   * X
   *
   * **data type** varchar(30)
   */
  readonly taxagentnm: string;

  /**
   * X
   *
   * **data type** varchar(10)
   */
  readonly ctano: string;

  /**
   * X
   *
   * **data type** varchar(10)
   */
  readonly taxagentsp: string;

  /**
   * X
   *
   * **data type** varchar(18)
   */
  readonly taxagenttel: string;

  /**
   * 이지로아이디
   *
   * **data type** varchar(40)
   */
  readonly guchung: string;

  /**
   * 위도
   *
   * **data type** varchar(16)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(16)
   */
  readonly lng: string;

  /**
   * 인쇄할 사업장명
   *
   * **data type** varchar(255)
   */
  readonly Pspjangnm: string;

  /**
   * 우수업체인증
   *
   * **data type** varchar(1)
   */
  readonly quality: string;

  /**
   * 승강기정보센터회사관리코드
   *
   * **data type** varchar(10)
   */
  readonly busicd: string;

  /**
   * X
   *
   * **data type** image
   */
  readonly noticebg: string;

  /**
   * 견적서 타이틀명
   *
   * **data type** text
   */
  readonly e451text: string;

  /**
   * 이메일연동-아이디
   *
   * **data type** varchar(50)
   */
  readonly emailid: string;

  /**
   * 이메일연동-비밀번호
   *
   * **data type** varchar(50)
   */
  readonly emailpw: string;

  /**
   * 이메일연동-smtp서버명
   *
   * **data type** varchar(20)
   */
  readonly emailsmtp: string;

  /**
   * port
   *
   * **data type** varchar(10)
   */
  readonly emailsmtppt: string;

  /**
   * IMAP서버명
   *
   * **data type** varchar(20)
   */
  readonly emailimap: string;

  /**
   * port
   *
   * **data type** varchar(10)
   */
  readonly emailimappt: string;

  /**
   * 이메일 연동주소
   *
   * **data type** varchar(255)
   */
  readonly emailaddress: string;

  /**
   * 이메일 연동체크
   *
   * **data type** varchar(1)
   */
  readonly emailuseyn: string;

  /**
   * 출근사용[기준시간]체크
   *
   * **data type** varchar(1)
   */
  readonly Commute: string;

  /**
   * 출근사용[기준시간]
   *
   * **data type** varchar(4)
   */
  readonly st_worktime: string;

  /**
   * X
   *
   * **data type** varchar(50)
   */
  readonly pemailid: string;

  /**
   * X
   *
   * **data type** varchar(50)
   */
  readonly pemailpw: string;

  /**
   * 카카오톡연동연락처
   *
   * **data type** varchar(20)
   */
  readonly tel_kakao: string;

  /**
   * 견적서 하단 특이사항명
   *
   * **data type** text
   */
  readonly e451remark: string;

  /**
   * 점검일지 꼬릿말
   *
   * **data type** text
   */
  readonly e035bottom: string;

  /**
   * 위도2
   *
   * **data type** varchar(16)
   */
  readonly lat2: string;

  /**
   * 경도2
   *
   * **data type** varchar(16)
   */
  readonly lng2: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  readonly useyn: string;

  /**
   * 출근사용시[급여기준일]
   *
   * **data type** varchar(2)
   */
  readonly st_paydate: string;

  /**
   * 정보센터 회사아이디
   *
   * **data type** varchar(50)
   */
  readonly gosiid: string;

  /**
   * 정보센터 회사비번
   *
   * **data type** varchar(50)
   */
  readonly gosipasswd: string;

  /**
   * 이메일 하단 소갯말
   *
   * **data type** varchar(255)
   */
  readonly email_bottom: string;

  /**
   * 정보센터 api 인증키
   *
   * **data type** varchar(255)
   */
  readonly gosi_apikey: string;

  /**
   * 정보센터 api 암호화키
   *
   * **data type** varchar(255)
   */
  readonly gosi_apikey_secret: string;

  /**
   * 더존연동 회사구분
   *
   * **data type** varchar(?)
   */
  readonly dz_custflag: string;

  /**
   * 더존연동 회사구분
   *
   * **data type** varchar(?)
   */
  readonly dz_flag: string;

  /**
   * 더존회사코드
   *
   * **data type** varchar(?)
   */
  readonly dz_co_cd: string;

  /**
   * 더존사업장코드
   *
   * **data type** varchar(?)
   */
  readonly dz_div_cd: string;

  /**
   * 더존홈페이지
   *
   * **data type** varchar(?)
   */
  readonly dz_homepage: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.saupnum = data.saupnum || '';
    this.spjangnm = data.spjangnm || '';
    this.compnm = data.compnm || '';
    this.prenm = data.prenm || '';
    this.openymd = data.openymd || '';
    this.custperclsf = data.custperclsf || '';
    this.corpnum = data.corpnum || '';
    this.jointsaup = data.jointsaup || '';
    this.zipcd = data.zipcd || '';
    this.adresa = data.adresa || '';
    this.adresb = data.adresb || '';
    this.zipcd2 = data.zipcd2 || '';
    this.adres2a = data.adres2a || '';
    this.adres2b = data.adres2b || '';
    this.biztype = data.biztype || '';
    this.item = data.item || '';
    this.tel1 = data.tel1 || '';
    this.tel2 = data.tel2 || '';
    this.fax = data.fax || '';
    this.buzclaif = data.buzclaif || '';
    this.comtaxoff = data.comtaxoff || '';
    this.emailadres = data.emailadres || '';
    this.passwd = data.passwd || '';
    this.astvaluemet = data.astvaluemet || '';
    this.agnertel1 = data.agnertel1 || '';
    this.agnertel2 = data.agnertel2 || '';
    this.stdate = data.stdate || '';
    this.eddate = data.eddate || '';
    this.operdivsn = data.operdivsn || '';
    this.tel3 = data.tel3 || '';
    this.allpay = data.allpay || '';
    this.halfpay = data.halfpay || '';
    this.taxagentcd = data.taxagentcd || '';
    this.taxagentnm = data.taxagentnm || '';
    this.ctano = data.ctano || '';
    this.taxagentsp = data.taxagentsp || '';
    this.taxagenttel = data.taxagenttel || '';
    this.guchung = data.guchung || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.Pspjangnm = data.Pspjangnm || '';
    this.quality = data.quality || '';
    this.busicd = data.busicd || '';
    this.noticebg = data.noticebg || '';
    this.e451text = data.e451text || '';
    this.emailid = data.emailid || '';
    this.emailpw = data.emailpw || '';
    this.emailsmtp = data.emailsmtp || '';
    this.emailsmtppt = data.emailsmtppt || '';
    this.emailimap = data.emailimap || '';
    this.emailimappt = data.emailimappt || '';
    this.emailaddress = data.emailaddress || '';
    this.emailuseyn = data.emailuseyn || '';
    this.Commute = data.Commute || '';
    this.st_worktime = data.st_worktime || '';
    this.pemailid = data.pemailid || '';
    this.pemailpw = data.pemailpw || '';
    this.tel_kakao = data.tel_kakao || '';
    this.e451remark = data.e451remark || '';
    this.e035bottom = data.e035bottom || '';
    this.lat2 = data.lat2 || '';
    this.lng2 = data.lng2 || '';
    this.useyn = data.useyn || '';
    this.st_paydate = data.st_paydate || '';
    this.gosiid = data.gosiid || '';
    this.gosipasswd = data.gosipasswd || '';
    this.email_bottom = data.email_bottom || '';
    this.gosi_apikey = data.gosi_apikey || '';
    this.gosi_apikey_secret = data.gosi_apikey_secret || '';
    this.dz_co_cd = data.dz_co_cd || '';
    this.dz_custflag = data.dz_custflag || '';
    this.dz_flag = data.dz_flag || '';
    this.dz_div_cd = data.dz_div_cd || '';
    this.dz_homepage = data.dz_homepage || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
