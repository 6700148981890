import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { HolidayTemplate } from './Holiday.template';
import { GridLayout, TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import {
  HolidayModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { Date8 } from '../../../../utils/time';

export enum HolidayItemChangeTypes {
  VAFRDATE,
  VATODATE,
  ATDCD,
  VAFRTIME,
  VATOTIME,
}

export enum HolidayItemChangeTypeNames {
  vafrdate,
  vatodate,
  atdcd,
  vafrtime,
  vatotime,
}

interface HolidayState {

  // 검색 조건
  year: string;
  perid: string;
  pernm: string;

  curdate: string; // 오늘날짜
  atdcds: Array<any>; // 신청구분 리스트
  kukcd: string; // 사원명구분

  // 데이터 객체
  focusedHoliday?: HolidayModel;
  lastNewData: HolidayModel;
  data: HolidayModel;
  holidayList: Array<HolidayModel>;

  daynum_tot: string;
  daynum_use_tot: string;
  daynum_remain_tot: string;
  workdaynum_tot: string;
  workdaynum_use_tot: string;
  workdaynum_remain_tot: string;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  focusIndex: number;
  chk: string;
  autoflag: string;
  yearly_flag: string;
}

/**
 * 컨트롤러
 * @window w_pb204
 * @category 휴가신청서
 */
export class Holiday extends PageComponent<PageProps, HolidayState>
  implements PageToolEvents {
  updatedRows?: Array<HolidayModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      year: `${year}`,
      curdate: `${year}${month}${date}`,
      data: new HolidayModel({
        reqdate: `${year}${month}${date}`,
        vafrdate: `${year}${month}${date}`,
        vatodate: `${year}${month}${date}`,
      }),
      kukcd: '',
      holidayList: [],
      atdcds: [],
      isReported: false,

      daynum_tot: '',
      daynum_use_tot: '',
      daynum_remain_tot: '',
      workdaynum_tot: '',
      workdaynum_use_tot: '',
      workdaynum_remain_tot: '',

      focusIndex: 0,
      chk: '0',
      autoflag: '0',
      yearly_flag: '0',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    // const { actionStore: api } = this.props;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      // await api.fxExec(
      //   'delete_appok',
      //   {
      //     vayear: this.state.data?.vayear,
      //     vanum: this.state.data?.vanum,
      //     perid: this.state.data?.perid,
      //     vafrdate: this.state.data?.vafrdate,
      //     vatodate: this.state.data?.vatodate,
      //     atdcd: this.state.data?.atdcd,
      //     autoflag: this.state.data?.autoflag,
      //     appnum: this.state.data?.appnum,
      //     appgubun: this.state.data?.appgubun,
      //   }, false,
      // );

      await this.onRetrieveEvent();
    }
  }


  @action
  async onFirstOpenEvent() {
    await this.open();
  }

  @action
  async open() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;

    const data = await api.fxExec('open');

    if (data.yearly_flag === '1') {
      // 페이지 닫기
      this.props.publicStore.toolStore?.onCloseHandler();
      ConfirmWarning.show('경고', '연차산정대상은 연차신청서를 작성 할 수 없습니다\n귀사의 인사담당자에게 문의하세요!');
    } else {
      // 팝업 구분
      const data = await api.dropdown('wf_dd_pb002_03');
      if (!data) return;
      this.setState({
        atdcds: data.items,
        perid: user.perid,
        pernm: user.pernm,
      });

      await this.onRetrieveEvent();
    }
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    this.setState({ kukcd: user.kukcd });

    if (this.state.perid === undefined || this.state.pernm === undefined || this.state.perid === '' || this.state.pernm === '') {
      this.setState({
        holidayList: [],
      });
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          holidayList: [...this.state.holidayList, ...items.map((x: any) => new HolidayModel(x))],
        }, next);
      },
      async () => {
        await this.SS({ holidayList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      holidayList: [],
      data: new HolidayModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(
        ['vayear', 'vanum'],
        [this.state.focusedHoliday?.vayear, this.state.focusedHoliday?.vanum],
        type, true,
      ) || 0;
      this.state.holidayList.length > index && this.grid.current?.setFocus(index);

      this.setState({
        workdaynum_tot: this.infinity?.box?.workdaynum_tot || '0',
        workdaynum_use_tot: this.infinity?.box?.workdaynum_use_tot || '0',
        workdaynum_remain_tot: this.infinity?.box?.workdaynum_remain_tot || '0',
        daynum_tot: this.infinity?.box?.daynum_tot || '0',
        daynum_use_tot: this.infinity?.box?.daynum_use_tot || '0',
        daynum_remain_tot: this.infinity?.box?.daynum_remain_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent(item: HolidayModel, index: number) {
    const { actionStore: api } = this.props;

    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      this.table.current?.update(false);
      return;
    }

    this.setState(
      {
        focusedHoliday: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            vayear: item.vayear || '',
            vanum: item?.vanum?.trim() || '',
            perid: this.state.perid || '',
            spjangcd: item.spjangcd || '',
          });

        await data && this.setState({ data: new HolidayModel(data) });

        // 결재상신 여부확인
        if (this.state.focusedHoliday?.appgubun === null
          || this.state.focusedHoliday?.appgubun === ''
          || this.state.focusedHoliday?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  async itemChanged(type: number) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    const params = {
      itemname: HolidayItemChangeTypeNames[type],
      year: this.state.year,
      vafrdate: this.state.data.vafrdate,
      vatodate: this.state.data.vatodate,
      vafrtime: this.state.data.vafrtime,
      vatotime: this.state.data.vatotime,
      atdcd: this.state.data.atdcd,
      new: this.state.data.new,
    };

    if (type === HolidayItemChangeTypes.VAFRDATE
    || type === HolidayItemChangeTypes.VATODATE) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.vafrdate,
        },
      );
    }

    if (type === HolidayItemChangeTypes.ATDCD) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.atdcd,
        },
      );
    }

    if (type === HolidayItemChangeTypes.VAFRTIME
    || type === HolidayItemChangeTypes.VATOTIME) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.vafrtime,
        },
      );
    }

    data && this.setState({
      data: new HolidayModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  fileModal(isOpen: boolean) {
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedHoliday!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.VACATION, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedHoliday!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    if (this.state.data?.appgubun === '101') {
      ConfirmWarning.show('경고', '이미 결재된 휴가신청서는 상신취소 할 수 없습니다.');
      return;
    }

    if (this.state.data?.isNew) {
      ConfirmWarning.show('경고', '저장 후 상신 부탁드립니다.');
      return;
    }

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.VACATION);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.VACATION.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    if (await api.save({
      ...this.state.data,
      year: this.state.year,
      vayear: this.state.year,
      vanum: this.state.focusedHoliday?.vanum,
      autoflag: this.state.autoflag,
      yearly_flag: this.state.yearly_flag,
    }, true)) {
      this.updatedRows = [];
      await this.setState({ chk: '1' });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new HolidayModel(data, true);

      this.setState({
        autoflag: data.autoflag,
        yearly_flag: data.yearflag,
        data: newModel,
        lastNewData: newModel,
        holidayList: [
          newModel,
          ...this.state.holidayList,
        ],
        focusedHoliday: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(false);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api, publicStore } = this.props;

    if (publicStore.user.custcd === 'shwa_gp' && this.state.isReported && publicStore.user.kukcd === '01') {
      if (this.state.data.appgubun === '001') {
        const text = `년도: ${this.state.year} 신청일: ${Date8.withDash(this.state.data.reqdate)}`;
        await api.delete(text, this.state.data) && this.onRetrieveEvent(RetrieveFocusType.END);
      } else {
        let result: boolean;
        // eslint-disable-next-line prefer-const
        result = await Confirm.show('확인', '이미 결재가 상신되어있는 문서입니다. 다른이(상급자)가 이문서에 대해 승인이 있습니다.그래도 상신 취소 하시겠습니까?', ConfirmType.QUESTION);

        if (!result) return;

        await api.fxExec('delete_appok', {
          vayear: this.state.data?.vayear,
          vanum: this.state.data?.vanum,
          perid: this.state.data?.perid,
          vafrdate: this.state.data?.vafrdate,
          vatodate: this.state.data?.vatodate,
          atdcd: this.state.data?.atdcd,
          autoflag: this.state.data?.autoflag,
          appnum: this.state.data?.appnum,
          appgubun: this.state.data?.appgubun,
        }, false);
      }
    } else {
      const text = `년도: ${this.state.year} 신청일: ${Date8.withDash(this.state.data.reqdate)}`;
      await api.delete(text, this.state.data) && this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.focusedHoliday?.custcd, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      vayear: this.state.year,
      vanum: this.state.focusedHoliday?.vanum,
      perid: this.state.perid,
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  render() {
    return (
      <HolidayTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new HolidayModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, callback);
        }}
      />
    );
  }
}
