import { action } from 'mobx';
import React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import { PreviousMonthModel } from './models/PreviousMonth.model';
import { PreviousMonthTemplate } from './PreviousMonth.template';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';

interface PreviousMonthState {
  // 조회조건
  mon: string;
  bemon: string;
  chflag: string;
  searchQuery: string;
  perid: string;
  pernm: string;

  // data
  data: Array<PreviousMonthModel>
}

/**
 * 컨트롤러
 * @window w_tb_e101w_07
 * @category 전월대비 계약변동내역
 */
export class PreviousMonth extends PageComponent<PageProps, PreviousMonthState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 조회조건
      mon: Today.yearMon(),
      bemon: String(Number(Today.yearMon()) - 1),
      chflag: '1',
      searchQuery: '',
      perid: '',
      pernm: '',

      // Data
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        mon: this.state.mon,
        bemon: this.state.bemon,
        chflag: this.state.chflag,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new PreviousMonthModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
      },
    );

    await this.SS({
      data: [],
    });

    await this.infinity?.retrieveAll();
    await this.grid.current?.setFocus(0);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('경고', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      mon: this.state.mon,
      bemon: this.state.bemon,
      chflag: this.state.chflag,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('경고', '엑셀로 변환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      mon: this.state.mon,
      bemon: this.state.bemon,
      chflag: this.state.chflag,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
    });
  }

  render() {
    return <PreviousMonthTemplate
      scope={this}
    >
    </PreviousMonthTemplate>;
  }
}
