import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import {
  Gubuns,
  States,
  Status,
} from '../Status';
import StatusDateModel from '../models/StatusDateModel';
import { Format } from '../../../../../utils/string';
import { Date6, Date8 } from '../../../../../utils/time';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export const StatusDateGridHeader: GLHF<StatusDateModel, Status> = (scope) => ([
  {
    id: 'mon',
    text: '월',
    isHidden: true,
    group: true,
  },
  {
    id: 'costdate',
    text: '견적일자',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout justify="center" align="center">
        {Date6.withDash(x.mon)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="center" align="center">
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 60,
    sum: (x, data) => (
      <FlexLayout justify="center" align="center">
        {Format.number(data.filter((y) => y.mon === x.mon).length)}건
      </FlexLayout>
    ),
  },
  {
    id: 'costpernm',
    text: '담당자',
    width: 90,
    trail: () => (
      <FlexLayout justify="center" align="center">
       {Format.number(scope.state.total)}건
      </FlexLayout>
    ),
  },
  {
    id: 'contflag',
    text: '계약구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.key === x.contflag)[0]?.value}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout justify="center" align="center">
        관급 : {Format.number(data.filter((y) => y.mon === x.mon && y.contflag === '0').length)}건
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="center" align="center">
        관급 : {Format.number(scope.state.tabDateContflag0_tot)}건
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout justify="center" align="center">
        사급 : {Format.number(data.filter((y) => y.mon === x.mon && y.contflag === '1').length)}건
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="center" align="center">
        사급 : {Format.number(scope.state.tabDateContflag1_tot)}건
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '공사명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout justify="start" align="center">
        LH : {Format.number(data.filter((y) => y.mon === x.mon && y.contflag === '2').length)}건
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="start" align="center">
        LH : {Format.number(scope.state.tabDateContflag2_tot)}건
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '거래처명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>
          견적: {Format.number(data.filter((y) => y.mon === x.mon && y.state === '0').length)} 건
        </div>
        <div style={{ color: 'var(--color-blue)', marginLeft: 52 }}>
          계약: {Format.number(data.filter((y) => y.mon === x.mon && y.state === '1').length)} 건
        </div>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="start"
      >
        <div>견적 : {Format.number(scope.state.state0_tot)} 건</div>
        <div
          style={{ color: 'var(--color-blue)', marginLeft: 52 }}
        >
          계약 : {Format.number(scope.state.state1_tot)} 건
        </div>
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '상태',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{ color: x.state === '1' ? 'var(--color-blue)' : '' }}
      >
        {States.filter((y) => y.key === x.state)[0]?.value}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');

      return (
        <FlexLayout>
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout justify="end" align="center">
        {Format.number(Sum.byGroup(data, 'samt', x, ['mon']))}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDateSamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout justify="end" align="center">
        {Format.number(Sum.byGroup(data, 'tamt', x, ['mon']))}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDateTamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '공사금액',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout justify="end" align="center">
        {Format.number(Sum.byGroup(data, 'mamt', x, ['mon']))}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="end" align="center">
        {Format.number(scope.state.tabDateMamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'jeoban',
    text: '제어반',
    width: 80,
  },
  {
    id: 'nperson',
    text: '인승',
    width: 80,
  },
  {
    id: 'number',
    text: '층수',
    width: 80,
  },
  {
    id: 'remark',
    text: '비고',
    width: 1000,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
