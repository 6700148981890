import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { MonthMoneyModel } from './MonthMoney.model';
import { MonthMoneyTemplate } from './MonthMoney.template';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface MonthMoneyState {
  year: string;
  data: Array<MonthMoneyModel>;
  data2: Array<MonthMoneyModel>;
  focused?: MonthMoneyModel;

  // trail
  rcvamt_tot: string;
  sndamt_tot: string;
  addamt_tot: string;
  resuamt_tot: string;
  rcvamt01_tot: string;
  rcvamt02_tot: string;
  rcvamt03_tot: string;
  rcvamt04_tot: string;
  rcvamt05_tot: string;
  rcvamt06_tot: string;
  rcvamt07_tot: string;
  rcvamt08_tot: string;
  rcvamt09_tot: string;
  rcvamt10_tot: string;
  rcvamt11_tot: string;
  rcvamt12_tot: string;
  addamt01_tot: string;
  addamt02_tot: string;
  addamt03_tot: string;
  addamt04_tot: string;
  addamt05_tot: string;
  addamt06_tot: string;
  addamt07_tot: string;
  addamt08_tot: string;
  addamt09_tot: string;
  addamt10_tot: string;
  addamt11_tot: string;
  addamt12_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_12
 * @category 월시재현황
 */
export class MonthMoney extends PageComponent<PageProps, MonthMoneyState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  graph?: any;

  beamts?: string;

  resuamts?: string;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      data: [],
      data2: [],

      // trail
      rcvamt_tot: '0',
      sndamt_tot: '0',
      addamt_tot: '0',
      resuamt_tot: '0',
      rcvamt01_tot: '0',
      rcvamt02_tot: '0',
      rcvamt03_tot: '0',
      rcvamt04_tot: '0',
      rcvamt05_tot: '0',
      rcvamt06_tot: '0',
      rcvamt07_tot: '0',
      rcvamt08_tot: '0',
      rcvamt09_tot: '0',
      rcvamt10_tot: '0',
      rcvamt11_tot: '0',
      rcvamt12_tot: '0',
      addamt01_tot: '0',
      addamt02_tot: '0',
      addamt03_tot: '0',
      addamt04_tot: '0',
      addamt05_tot: '0',
      addamt06_tot: '0',
      addamt07_tot: '0',
      addamt08_tot: '0',
      addamt09_tot: '0',
      addamt10_tot: '0',
      addamt11_tot: '0',
      addamt12_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.beamts = items[items.length - 1]?.beamt;
        this.resuamts = items[items.length - 1]?.resuamt;

        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MonthMoneyModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['addamt', 'beamt'],
        [this.state.focused?.addamt, this.state.focused?.beamt], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.data[0]);
        this.onRetrieveEvent2();
      }
      this.graph = `https://api.elmansoft.com/chart/multi2.php?window=w_tb_ca642w_12&type=0&as_custcd=${user.custcd}&as_year=${this.state.year}`;
      this.SS({
        rcvamt_tot: this.infinity?.box?.rcvamt_tot || '0',
        sndamt_tot: this.infinity?.box?.sndamt_tot || '0',
        addamt_tot: this.infinity?.box?.addamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        year: this.state.year,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.setState({
          data2: [
            ...this.state.data2,
            ...items.map((x: any) => new MonthMoneyModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          data2: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.data2 && this.state.data2?.length > 0) {
          await this.grid2.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data2: [],
    }, async () => {
      await this.infinity2?.retrieveAll();
      if (this.state.data2 && this.state.data2?.length > 0) {
        await this.grid2.current?.setFocus(0);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
    });
  }

  @action
  onRowFocusEvent(item: MonthMoneyModel) {
    this.setState({ focused: item });
  }

  render() {
    return <MonthMoneyTemplate scope={this} />;
  }
}
