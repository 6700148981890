import { sort } from 'fast-sort';

export default class Sort {
  public static asc<T = {}>(column: string, arr: Array<T>): Array<T> {
    // @ts-ignore
    return sort(arr).asc((x) => (Sort.isNumeric(x[column]) ? parseFloat(x[column]) : x[column]));
  }

  public static desc<T = {}>(column: string, arr: Array<T>): Array<T> {
    // @ts-ignore
    return sort(arr).desc((x) => (Sort.isNumeric(x[column]) ? parseFloat(x[column]) : x[column]));
  }

  private static isNumeric(n: string) {
    if (!/^(-?)([0-9|.])+$/.test(n)) return false;
    const f = parseFloat(n);
    return !Number.isNaN(f) && Number.isFinite(f);
  }
}
