import { computed } from 'mobx';

/**
 * 매입상세 모델
 * @window w_tb_ca640
 * @category 매입등록
 */
export class BuyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  size: string;

  /**
   * 단위
   *
   * **data type** varchar(50)
   */
  unit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 단가
   *
   * **data type** number
   */
  uamt: string;

  /**
   * 공급가액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 총매입금액
   *
   * **data type** float
   */
  mijamt: string;

  /**
   * 참고(비고)
   *
   * **data type** varchar(255)
   */
  bigo: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(20)
   */
  spjangcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 매입번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  accdate: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 현금지급
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** float
   */
  eamt: string;

  /**
   * 예금금액
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 카드금액
   *
   * **data type** float
   */
  damt: string;

  /**
   * 지로금액
   *
   * **data type** float
   */
  jamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 카드요율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 0
   *
   * **data type** float
   */
  camt: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 카드입력일
   *
   * **data type** varchar(8)
   */
  cardindate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.bigo = data.bigo || '';
    this.gamt = data.gamt || '';
    this.mijnum = data.mijnum || '';
    this.indate = data.indate || '';
    this.cardindate = data.cardindate || '';
    this.inperid = data.inperid || '';
    this.damt = data.damt || '';
    this.persent = data.persent || '';
    this.camt = data.camt || '';
    this.bamt = data.bamt || '';
    this.jmar = data.jmar || '';
    this.jamt = data.jamt || '';
    this.eamt = data.eamt || '';
    this.remark = data.remark || '';
    this.hamt = data.hamt || '';
    this.acc_spnum = data.acc_spnum || '';
    this.accdate = data.accdate || '';
    this.acc_spdate = data.acc_spdate || '';
    this.mijdate = data.mijdate || '';
    this.cltcd = data.cltcd || '';
    this.spjangcd = data.spjangcd || '';
    this.tamt = data.tamt || '';
    this.samt = data.samt || '';
    this.uamt = data.uamt || '';
    this.qty = data.qty || '';
    this.unit = data.unit || '';
    this.size = data.size || '';
    this.seq = data.seq || '';
    this.mijamt = data.mijamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
