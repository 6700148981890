import {
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import MenuModel from './MenuModel';

const nullMenu: MenuModel = new MenuModel('NULL', 'AiOutlineQuestion');

export default class CurrentMenuModel {
  @observable private primaryMenu?: MenuModel;

  @observable private activeMenu?: MenuModel;

  constructor() {
    makeObservable(this);
  }

  @computed
  get primary(): MenuModel {
    return this.primaryMenu ? this.primaryMenu : nullMenu;
  }

  @computed
  get active(): MenuModel {
    return this.activeMenu ? this.activeMenu : nullMenu;
  }

  @action
  setPrimary(menu: MenuModel) {
    this.primaryMenu = menu;
  }

  @action
  setActive(menu: MenuModel) {
    this.activeMenu = menu;
  }

  @action
  setEmpty() {
    this.activeMenu = nullMenu;
  }
}
