import { computed } from 'mobx';
import { Today } from '../../../../../utils/time';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_b035
 * @category 샘플
 */
export default class ReportModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 첨부파일갯수
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly ctdate: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 작업자수
   *
   * **data type** varchar(8)
   */
  readonly dpercnt: string;

  /**
   * 작업자아이디
   *
   * **data type** varchar(8)
   */
  readonly dperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력사원
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 직영구분
   *
   * **data type** varchar(8)
   */
  readonly mpclafi: string;

  /**
   * 책임자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 책임자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 안전지시사항
   *
   * **data type** varchar(8)
   */
  readonly saferemark: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly title: string;

  /**
   * 진행율
   *
   * **data type** varchar(8)
   */
  readonly tot_dpercnt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly total2: string;

  /**
   * 날씨
   *
   * **data type** varchar(8)
   */
  readonly weather: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.attcnt = data.attcnt || '0';
    this.ctdate = data.ctdate || Today.date();
    this.custcd = data.custcd || '';
    this.dpercnt = data.dpercnt || '';
    this.dperid = data.dperid || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.mpclafi = data.mpclafi || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.remark = Fix.newline(data.remark) || '';
    this.saferemark = Fix.newline(data.saferemark) || '';
    this.spjangcd = data.spjangcd || '';
    this.title = data.title || '';
    this.tot_dpercnt = data.tot_dpercnt || '';
    this.total2 = data.total2 || '';
    this.weather = data.weather || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
