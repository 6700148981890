import * as React from 'react';
import {
  FlexLayout,
  TextBox,
  ComboBoxModel,
  ComboBox,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { StaffScore } from './StaffScore';


/**
 * 화면
 * @window w_tb_ja001_talent
 * @category 직원점수권한
 */
export const StaffScoreTemplate: React.FC<TemplateProps<StaffScore>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={400}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="성명, 부서, 입사일로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={400}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm || '전체'))}
          onSelect={(option) => scope.setState({ spjangcd: option.value })}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout weight={2}>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'seq',
              text: '순번',
              width: 50,
              render: (x, _, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  value={x.seq}
                />
              ),
            },
            {
              id: 'spjangcd',
              text: '사업장',
              width: 100,
              render: (x) => {
                const find = scope?.state.spjangcds?.filter((y) => y.spjangcd === x.spjangcd);
                const spjangnm = find?.length ? find[0].spjangnm : x.spjangcd;
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {spjangnm}
                  </FlexLayout>
                );
              },
            },
            {
              id: 'perid',
              text: '사원번호',
              width: 100,
            },
            {
              id: 'pernm',
              text: '사원명',
              width: 100,
            },
            {
              id: 'divinm',
              text: '부서명',
              width: 150,
              group: true,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
            },
            {
              id: 'rspnm',
              text: '직급',
              width: 100,
            },
            {
              id: 'talent',
              text: '권한점수',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="right"
                  ref={ref}
                  value={x.talent}
                  onChange={(value) => rowUpdate({ talent: value })}
                />
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          data={scope.state.data}
        />
      </FlexLayout>
    </FlexLayout>


  </FlexLayout>
);
