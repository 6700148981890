import {
  ImArrowDown,
  ImArrowDownLeft,
  ImArrowDownRight,
  ImArrowLeft,
  ImArrowRight,
  ImArrowUp,
  ImArrowUpRight,
} from 'react-icons/all';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Process } from '../Process';
import ProcessModel from '../models/ProcessModel';
import { Date8 } from '../../../../../utils/time';
import style from '../Process.module.scss';
import { JoinClassName } from '../../../../../utils/string';

const stateColors = [
  { key: '설치', color: 'var(--color-green)' },
  { key: '설치종료', color: 'var(--color-green)' },
  { key: '보수', color: 'var(--color-blue)' },
  { key: '사급', color: 'var(--color-blue)' },
];

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e601w_sulchi_03
 * @category 설치진행현황
 */
export const ProcessGrid: GLHF<ProcessModel, Process> = (scope) => ([
  {
    id: 'state',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">설치구분</FlexLayout>
        <FlexLayout align="start">계약구분</FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => {
      const fpSt = stateColors.filter((y) => y.key === x.statenm);
      const fpCt = stateColors.filter((y) => y.key === x.contflagnm);
      const colorState = fpSt.length > 0 ? fpSt[0].color : 'inherit';
      const colorCont = fpCt.length > 0 ? fpCt[0].color : 'inherit';

      return (
        <FlexLayout
          isVertical={true}
          justify="center"
        >
          <FlexLayout
            align="end"
            style={{
              color: colorState,
            }}
          >
            {x.statenm}
          </FlexLayout>

          <FlexLayout
            align="start"
            style={{
              color: colorCont,
            }}
          >
            {x.contflagnm}
          </FlexLayout>
        </FlexLayout>
      );
    },
  },
  {
    id: 'projno',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">프로젝트코드</FlexLayout>
        <FlexLayout align="start">설치현장명</FlexLayout>
      </FlexLayout>
    ),
    width: 350,
    render: (x) => (
      <FlexLayout
        isVertical={true}
        style={{
          paddingLeft: 8,
        }}
      >
        <FlexLayout align="end">{x.projno}</FlexLayout>
        <FlexLayout align="start">{x.scactnm}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'orddate',
    text: '계약일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.orddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: (
      <FlexLayout>
        <FlexLayout size={300}>1단계</FlexLayout>
        <FlexLayout size={180}>2단계</FlexLayout>
        <FlexLayout>3단계</FlexLayout>
        <FlexLayout>4단계</FlexLayout>
        <FlexLayout>5단계</FlexLayout>
      </FlexLayout>
    ),
    width: 1000,
    render: (x) => (
      <FlexLayout>
        <div className={style.container}>
          {/* 1단계 */}
          <div className={JoinClassName.make([
            x.ordflag === '0' ? style.yet : false,
          ])}>
            <div onDoubleClick={() => x.ordflag !== '0' && scope.openSujuPaper(x)}>
              수주(계약)등록
              <span>{x.ordflag === '0' ? '미완료' : '완료'}</span>
            </div>
            <div>{Date8.withDash(x.orddate)}</div>
          </div>

          <div className={JoinClassName.make([
            x.notice === '0' ? style.yet : false,
          ])}>
            <div>
              <span>수주 {x.notice === '0' ? '미통보' : '통보'}</span>
            </div>
            <div><ImArrowDown /></div>
          </div>

          <div className={JoinClassName.make([
            x.okbook === '0' ? style.yet : false,
            scope.isAlert(x.okbook, x.okbookdate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.okbook !== '0' && scope.openRegist(x)}>
              승인도서
              <span>{scope.bookName(x.okbook)}</span>
            </div>
            <div>{Date8.withDash(x.okbookdate_sc)}</div>
            <div><ImArrowDownRight /></div>
          </div>

          <div className={JoinClassName.make([
            x.misstflag === '0' ? style.yet : false,
            scope.isAlert(x.misstflag, x.misstdate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.misstflag !== '0' && scope.openEarn(x)}>
              계약금발행
              <span>{x.misstflag === '0' ? '미발행' : '발행'}</span>
            </div>
            <div>{Date8.withDash(x.misstflag === '0' ? x.misstdate_sc : x.misstdate)}</div>
            <div><ImArrowUpRight /></div>
          </div>


          {/* 2단계 */}
          <div className={JoinClassName.make([
            x.processflag === '0' ? style.yet : false,
            scope.isAlert(x.processflag, x.processdate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.processflag !== '0' && scope.openProcess(x)}>
              착공율
              <span>{x.process_per}%</span>
            </div>
            <div>{Date8.withDash(x.processdate_sc)}</div>
            <div><ImArrowLeft /></div>
          </div>

          <div className={JoinClassName.make([
            x.balflag === '0' ? style.yet : false,
            scope.isAlert(x.balflag, x.baldate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.balflag !== '0' && scope.openBal(x)}>
              부품업체발주
              {/* eslint-disable-next-line no-nested-ternary */}
              <span>{x.balflag === '0' ? '미발주' : (x.balflag === '1' ? '발주중' : '발주완료')}</span>
            </div>
            <div>{Date8.withDash(x.balflag === '0' ? x.baldate_sc : x.baldate)}</div>
            <div><ImArrowRight /></div>
          </div>

          <div className={JoinClassName.make([
            x.balokflag === '0' ? style.yet : false,
            scope.isAlert(x.balokflag, x.baldateok_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.balokflag !== '0' && scope.openBal(x)}>
              자재반입일정확인
              <span>{x.balokflag === '0' ? '미확인' : '확인'}</span>
            </div>
            <div>{Date8.withDash(x.balokflag === '0' ? x.baldateok_sc : x.baldateok)}</div>
            <div><ImArrowUpRight /></div>
          </div>


          {/* 3단계 */}
          <div className={JoinClassName.make([
            x.sckcflag === '0' ? style.yet : false,
            scope.isAlert(x.sckcflag, x.sckcdate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.sckcflag !== '0' && scope.openRegist(x)}>
              검사신청
              <span>{x.sckcflag === '0' ? '미신청' : '신청'}</span>
            </div>
            <div>{Date8.withDash(x.sckcflag === '0' ? x.sckcdate_sc : x.sckcdate)}</div>
            <div><ImArrowRight /></div>
          </div>


          {/* 4단계 */}
          <div className={JoinClassName.make([
            x.compbook === '0' ? style.yet : false,
            scope.isAlert(x.compbook, x.compbookdate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.compbook !== '0' && scope.openRegist(x)}>
              준공도서
              <span>{scope.bookName(x.compbook)}</span>
            </div>
            <div>{Date8.withDash(x.compbookdate_sc)}</div>
            <div><ImArrowDownLeft /></div>
          </div>

          <div className={JoinClassName.make([
            x.kcflag === '0' ? style.yet : false,
            scope.isAlert(x.kcflag, x.kcdate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.kcflag !== '0' && scope.openRegist(x)}>
              검사확정일
              <span>{x.kcflag === '0' ? '미확정' : '확정'}</span>
            </div>
            <div>{Date8.withDash(x.kcflag === '0' ? x.kcdate_sc : x.kcdate)}</div>
            <div><ImArrowRight /></div>
          </div>

          <div className={JoinClassName.make([
            x.be_state === '1' ? style.yet : false,
            scope.isAlert(x.be_state, x.be_date_sc, true) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.be_state !== '1' && scope.openPreChk(x)}>
              사전검사
              <span>{x.be_state === '1' ? '미처리' : '처리'}</span>
            </div>
            <div>{Date8.withDash(x.be_state === '1' ? x.be_date_sc : x.be_date)}</div>
            <div><ImArrowDownRight /></div>
          </div>

          <div className={JoinClassName.make([
            x.mismidflag === '0' ? style.yet : false,
            scope.isAlert(x.mismidflag, x.mismiddate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.mismidflag !== '0' && scope.openEarnMid(x)}>
              중도금발행
              <span>{x.mismidflag === '0' ? '미발행' : '발행'}</span>
            </div>
            <div>{Date8.withDash(x.mismidflag === '0' ? x.mismiddate_sc : x.mismiddate)}</div>
            <div><ImArrowUp /></div>
          </div>

          <div className={JoinClassName.make([
            x.misendflag === '0' ? style.yet : false,
            scope.isAlert(x.misendflag, x.misenddate_sc) ? style.alert : false,
          ])}>
            <div onDoubleClick={() => x.misendflag !== '0' && scope.openEarnEnd(x)}>
              잔금발행
              <span>{x.misendflag === '0' ? '미발행' : '발행'}</span>
            </div>
            <div>{Date8.withDash(x.misendflag === '0' ? x.misenddate_sc : x.misenddate)}</div>
            <div><ImArrowUpRight /></div>
          </div>


          {/* 5단계 */}
          <div className={JoinClassName.make([
            x.okflag === '0' ? style.yet : false,
          ])}>
            <div onDoubleClick={() => x.okflag !== '0' && scope.openRegist(x)}>
              검사결과(필증)
              <span>{x.okflag === '0' ? '무' : '유'}</span>
            </div>
            <div>{Date8.withDash(x.okdate_sc)}</div>
            <div><ImArrowRight /></div>
          </div>
        </div>
      </FlexLayout>
    ),
  },
  {
    id: 'percent',
    text: '진행율',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <FlexLayout weight={0.9}>
          <CircularProgressbar
            value={scope.percent(x)}
            text={`${scope.percent(x)}%`}
            styles={buildStyles({
              rotation: 0,
              textSize: '16px',
              pathTransitionDuration: 0.7,
              pathColor: 'var(--color-secondary)',
              textColor: 'var(--color-secondary)',
              trailColor: 'var(--color-primary)',
            })}
          />
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
