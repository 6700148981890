import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642w_01
 * @category 지급현황
 */
export class GiveStateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  snddate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  sndnum: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  sndamt: string;

  /**
   * 적요
   *
   * **data type** varchar(258)
   */
  remark: string;

  /**
   * 지급구분
   *
   * **data type** varchar(34)
   */
  gubun: string;

  /**
   * 은행명
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 계정명
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 은행계좌코드
   *
   * **data type** varchar(4)
   */
  bankacccd: string;

  /**
   * 은행명
   *
   * **data type** varchar(20)
   */
  banknm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(50)
   */
  accnum: string;

  /**
   * 계정명
   *
   * **data type** varchar(4)
   */
  accnm: string;

  /**
   * 비용항목그룹
   *
   * **data type** number
   */
  code: string;

  /**
   * 비용항목그룹명
   *
   * **data type** number
   */
  cnam: string;

  /**
   * 선택
   *
   * **data type** number
   */
  chk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.sndnum = data.sndnum || '';
    this.snddate = data.snddate || '';
    this.banknm = data.banknm || '';
    this.accnm = data.accnm || '';
    this.accnum = data.accnum || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.sndamt = data.sndamt || '';
    this.remark = data.remark || '';
    this.gubun = data.gubun || '';
    this.bankcd = data.bankcd || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.acccd = data.acccd || '';
    this.bankacccd = data.bankacccd || '';
    this.custcd = data.custcd || '';
    this.code = data.code || '';
    this.cnam = data.cnam || '';
    this.chk = data.chk || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
