import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ProductGroupModel, ProductGroupModel2, ProductGroupModel3 } from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ProductGroupTemplate } from './ProductGroup.template';
import { ConfirmDelete, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

export interface ProductGroupListItem {
  custcd: string;
  pgr_hcod: string;
  phm_mode: string;
  pgr_hnam: string;
  remark: string;
  new: string;
}

export interface ProductGroupListDetailItem {
  pgr_hcod: string;
  bgroup: string;
  bgrpnm: string;
  custcd: string;
  indate: string;
  remark: string;
  new: string;
}

export interface ProductGroupListDetailItem2 {
  pgr_hcod: string;
  cgroup: string;
  cgrpnm: string;
  new: string;
}

interface ProductGroupState {
  searchQuery: string;
  searchSelect: string;
  phm_modes?: Array<any>;
  data: ProductGroupModel;
  data2: ProductGroupModel2;
  data3: ProductGroupModel3;
  focused?: ProductGroupModel;
  productGroupList: Array<ProductGroupModel>;
  productGroupDetailList: Array<ProductGroupModel2>;
  productGroupDetailList2: Array<ProductGroupModel3>;
  focusedProductGroup?: ProductGroupListItem;
  focusedDetailProductGroup?: ProductGroupListDetailItem;
  focusedDetailProductGroup2?: ProductGroupListDetailItem2;
}

/**
 * 컨트롤러
 * @window w_tb_ca503
 * @category 제품분류등록
 */
export class ProductGroup extends PageComponent<PageProps, ProductGroupState>
  implements PageToolEvents {
  updatedRows?: Array<ProductGroupModel>;

  updatedRows2?: Array<ProductGroupModel2>;

  updatedRows3?: Array<ProductGroupModel3>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  tableDetail2: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  formatCheck: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      productGroupList: [],
      productGroupDetailList: [],
      productGroupDetailList2: [],
      searchQuery: '',
      data: [],
      data2: [],
      data3: [],
      searchSelect: '001',
    };
    this.formatCheck = 0;
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 정기유무 리스트 가져오기
    const data = await api.dropdown('wf_dd_ca541');

    if (!data) return;
    this.setState({ phm_modes: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        phm_mode: this.state.searchSelect,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          productGroupList: [...this.state.productGroupList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          productGroupList: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(false);
        await this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      productGroupList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('pgr_hcod', this.state.focusedProductGroup?.pgr_hcod, type) || 0;
      await this.table.current?.update();
      this.tableDetail.current?.update(true);
      this.tableDetail2.current?.update(true);
      this.state.productGroupList.length > index && await this.table.current?.setFocus(index, 1);
    });
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new({
      phm_mode: this.state.searchSelect,
    });

    data.new = '1';
    data && this.setState({
      productGroupList: [data, ...this.state.productGroupList],
      productGroupDetailList: [],
      productGroupDetailList2: [],
    }, async () => {
      await this.table.current?.update(false);
      await this.tableDetail.current?.update(true);
      await this.tableDetail2.current?.update(true);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const items2 = this.updatedRows2?.filter((x) => x).map((x) => ({
      new: x.new,
      pgr_hcod: x.pgr_hcod,
      bgroup: x.bgroup,
      bgroupnm: x.bgrpnm,
      remark: x.remark,
      embgroup: x.embgroup,
    }));
    if (await api.save({
      items: this.updatedRows,
      items2,
      items3: this.updatedRows3,
    }, true)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      this.updatedRows3 = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `대분류코드: ${this.state.focusedProductGroup?.pgr_hcod
    }  대분류명: ${this.state.focusedProductGroup?.pgr_hnam}`;
    await api.delete(text, this.state.focusedProductGroup) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onRowFocusEvent(item: ProductGroupModel) {
    this.setState({
      focusedProductGroup: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.exec(
        Category.GENERAL,
        'dw_1_RowFocuschanged',
        item,
      );
      data && this.setState({
        productGroupDetailList: data.items,
      }, async () => {
        if (data.items) {
          await this.tableDetail.current?.update(true);
          this.tableDetail.current?.setFocus(0, 1);
          await this.tableDetail2.current?.update(true);
        } else {
          this.setState({
            productGroupDetailList: [],
            productGroupDetailList2: [],
          }, async () => {
            await this.tableDetail.current?.update(false);
            await this.tableDetail.current?.setFocus(0, 0);
            await this.tableDetail2.current?.update(false);
          });
        }
      });
    });
  }

  @action
  onRowFocusEvent2(item:ProductGroupModel2) {
    this.setState({
      focusedDetailProductGroup: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.exec(
        Category.GENERAL,
        'dw_2_RowFocuschanged',
        {
          pgr_hcod: item.pgr_hcod,
          bgroup: item.bgroup,
        },
      );
      data && this.setState({
        productGroupDetailList2: data.items,
      }, async () => {
        if (data.items) {
          await this.tableDetail2.current?.update(false);
          await this.tableDetail2.current?.setFocus(0, 1);
        }
      });
    });
  }

  @action
  onRowFocusEvent3(item:ProductGroupModel3) {
    if (item.new === '1') return;
    this.setState({ focusedDetailProductGroup2: item });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data2 = await api.fxExec(
      'dw_2_new',
      {
        phm_mode: this.state.searchSelect,
        pgr_hcod: this.state.focusedProductGroup?.pgr_hcod,
      },
    );

    if (data2) {
      const newData = {
        pgr_hcod: data2.pgr_hcod,
        bgroup: data2.bgroup,
        bgrpnm: data2.bgrpnm,
        bgroupnm: data2.bgroupnm,
        custcd: data2.custcd,
        embgroup: data2.embgroup,
        indate: data2.indate,
        remark: data2.remark,
        isNew: true,
        new: '1',
      };

      if (!this.state.productGroupDetailList) {
        this.setState({
          focusedDetailProductGroup: newData,
          productGroupDetailList: [
            newData,
          ],
          productGroupDetailList2: [],
        }, async () => {
          await this.tableDetail.current?.update(false);
          await this.tableDetail2.current?.update(true);
          this.tableDetail.current?.setFocus(0, 1);
        });
      } else {
        this.setState({
          focusedDetailProductGroup: newData,
          productGroupDetailList: [
            newData,
            ...this.state.productGroupDetailList,
          ],
          productGroupDetailList2: [],
        }, async () => {
          await this.tableDetail.current?.update(false);
          await this.tableDetail2.current?.update(true);
          this.tableDetail.current?.setFocus(0, 1);
        });
      }
    }
  }

  @action
  async dw_3_new() {
    const { actionStore: api } = this.props;
    const data3 = await api.fxExec(
      'dw_3_new',
      {
        phm_mode: this.state.searchSelect,
        pgr_hcod: this.state.focusedProductGroup?.pgr_hcod,
        bgroup: this.state.focusedDetailProductGroup?.bgroup,
      },
    );

    this.formatCheck = 1;

    if (data3) {
      const newData = {
        pgr_hcod: data3.pgr_hcod,
        bgroup: data3.bgroup,
        bgrpnm: data3.bgrpnm,
        cgroup: data3.cgroup,
        cgrpnm: data3.cgrpnm,
        age_year: data3.age_year || '',
        age_month: data3.age_month || '',
        custcd: data3.custcd,
        indate: data3.custcd,
        emcgroup: data3.custcd,
        remark: data3.custcd,
        isNew: true,
        new: '1',
      };

      if (!this.state.productGroupDetailList2) {
        this.setState({
          focusedDetailProductGroup2: newData,
          productGroupDetailList2: [
            newData,
          ],
        }, async () => {
          await this.tableDetail2.current?.update(false);
          this.tableDetail2.current?.setFocus(0, 1);
        });
      } else {
        this.setState({
          focusedDetailProductGroup2: newData,
          productGroupDetailList2: [
            newData,
            ...this.state.productGroupDetailList2,
          ],
        }, async () => {
          await this.tableDetail2.current?.update(false);
          this.tableDetail2.current?.setFocus(0, 1);
        });
      }
    }
  }

  @action
  async dw_2_delete() {
    const focusedItem = this.state.focusedDetailProductGroup;

    if (focusedItem) {
      // @ts-ignore
      const text = `중분류코드: ${this.state.focusedDetailProductGroup?.bgroup
      }, 중분류명칭: ${this.state.focusedDetailProductGroup?.bgrpnm}`;
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;
        if (await ConfirmDelete.show(text2)) {
          await api.fxExec(
            'dw_2_delete',
            {
              pgr_hcod: this.state.focusedDetailProductGroup?.pgr_hcod,
              bgroup: this.state.focusedDetailProductGroup?.bgroup,
            },
          );
          this.onRetrieveEvent();
          // eslint-disable-next-line no-template-curly-in-string
          ConfirmSuccess.show('삭제', `${focusedItem.bgrpnm}를 삭제하였습니다.`);
        }
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  async dw_3_delete() {
    const focusedItem = this.state.focusedDetailProductGroup2;

    if (focusedItem) {
      // @ts-ignore
      const text = `소분류코드: ${this.state.focusedDetailProductGroup2?.cgroup
      }, 소분류명칭: ${this.state.focusedDetailProductGroup2?.cgrpnm}`;
      if (await ConfirmDelete.show(text)) {
        const { actionStore: api } = this.props;
        await api.fxExec(
          'dw_3_delete',
          {
            pgr_hcod: this.state.focusedDetailProductGroup?.pgr_hcod,
            bgroup: this.state.focusedDetailProductGroup?.bgroup,
            cgroup: this.state.focusedDetailProductGroup2?.cgroup,
          },
        );
        this.onRetrieveEvent();
        // eslint-disable-next-line no-template-curly-in-string
        ConfirmSuccess.show('삭제', `${focusedItem.cgrpnm}를 삭제하였습니다.`);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({ data: rows2 });
  }

  @action
  onUpdatedRows3(rows3: any, updatedRows3: any) {
    this.updatedRows3 = updatedRows3;
    this.setState({ data: rows3 });
  }

  render() {
    return (
      <ProductGroupTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...this.state.productGroupList,
            ...this.state.productGroupDetailList,
            ...this.state.productGroupDetailList2,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
