import * as React from 'react';
import {
  ComboBox, ComboBoxModel, FlexLayout, SearchLayout, TabLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Compyns, Gubuns, Status } from './Status';
import { StatusTab } from './Status.tab';
import { SearchDateRange } from '../../../../components/search';
import { SearchBinding, SearchBindingProjects } from '../../../../models';

/**
 * 화면
 * @window w_tb_e471w
 * @category 공사현황
 */
export const StatusTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={306}>
          <span
            style={{
              ...Global.STYLE_COLOR_RED,
              paddingLeft: 2,
            }}
          >
            부서
          </span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            value={scope.state.divinm}
            readonly={true}
            weight={2}
          />
        </FlexLayout>

        <FlexLayout>
          <span
            style={{
              ...Global.STYLE_COLOR_RED,
              paddingLeft: 13,
            }}
          >
            사용자
          </span>
          <TextBox
            textAlign="center"
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            value={scope.state.pernm}
            readonly={true}
            weight={2}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span>상태</span>
          <ComboBox
            value={scope.state.compyn}
            textAlign="center"
            data={Compyns.map((x: any) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(v) => setData({ compyn: v.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={351}>
          <span style={{ paddingRight: 11 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 공사명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={330}>
          <span style={Global.STYLE_COLOR_RED}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({ wkactcd: item.cd, wkactnm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={2}
            value={scope.state.wkactnm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>프로젝트</span>
          <TextBox
            textAlign="center"
            value={scope.state.projno}
            onChange={(value) => setData({ projno: value })}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBindingProjects(
              scope.props.modalStore,
              {},
              true,
              (item) => {
                setData({
                  projno: item.projno,
                  projnm: item.projnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            weight={2}
            value={scope.state.projnm}
            onChange={(value) => setData({ projnm: value })}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={Gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ gubun: option.value })}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={StatusTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
