import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import EnrollmentPullModel from '../models/EnrollmentPullModel';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentPullGridHeader: GLHF<EnrollmentPullModel, Enrollment> = (scope) => ([
  {
    id: 'costdate',
    text: '견적일자',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costnum}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '공사구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.Gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '견적서명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '합계금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'saleamt',
    text: '할인금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.saleamt)}
      </FlexLayout>
    ),
  },
]);
