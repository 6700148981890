import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  IFrame,
  SearchLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  User,
  UserTabDataStateNames,
  UserTabId,
} from './User';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_xlogin_02
 * @category 사용자별접속현황
 */

export const UserTemplate: React.FC<TemplateProps<User>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (<FlexLayout isVertical={true}>
    <FlexLayout isVertical={true}>
      {(scope.tabIndex === UserTabId.User
        || scope.tabIndex === UserTabId.UserErp
        || scope.tabIndex === UserTabId.UserApp
      ) && <FlexLayout isVertical={true}>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 1000 }}>
          <FlexLayout>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          {(scope.tabIndex === UserTabId.User
            || scope.tabIndex === UserTabId.UserErp
            || scope.tabIndex === UserTabId.UserApp)
          && <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.divinm}
              onChange={(value) => setData({ divinm: value })}
              readonly={true}
            />
          </FlexLayout>}
          <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              onChange={(value) => setData({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
        <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <TabView
            tabs={scope.tabs}
            focused={scope.state.focusedTab}
            onChange={(tab) => scope.onTabChange(tab)}
          />
        </FlexLayout>
        <FlexLayout>
          <GridLayout
            // @ts-ignore
            header={scope.tabHeaders[scope.tabIndex]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            // @ts-ignore
            data={scope.state[UserTabDataStateNames[scope.tabIndex]] || [] }
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
          {(scope.tabIndex === UserTabId.User) && <FlexLayout>
                <IFrame src={scope.state.url} />
            </FlexLayout>}
          {(scope.tabIndex === UserTabId.UserErp) && <FlexLayout>
              <IFrame src={scope.state.url2} />
            </FlexLayout>}
          {(scope.tabIndex === UserTabId.UserApp) && <FlexLayout>
              <IFrame src={scope.state.url3} />
            </FlexLayout>}
        </FlexLayout>
      </FlexLayout>}
    </FlexLayout>
  </FlexLayout>);
};
