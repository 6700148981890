import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { FcCalculator } from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  GridLayoutAdditionalHeader,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { MonthResult } from './MonthResult';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { MonthResultTab } from './MonthResult.tab';

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const wkactcds = [
  { value: '001', remark: '자사' },
  { value: '002', remark: '현대' },
  { value: '003', remark: '오티스' },
  { value: '004', remark: '티센' },
  { value: '005', remark: '미쓰비시' },
];

const states = [
  { value: '1', remark: '계획' },
  { value: '2', remark: '검사' },
  { value: '3', remark: '조치' },
  { value: '0', remark: '만료' },
];

export const gubuns = [
  { value: '%', remark: '전체' },
  { value: '1', remark: '설치' },
  { value: '2', remark: '보수' },
];

export const results = [
  { value: '002', remark: '불합격' },
  { value: '003', remark: '조건부' },
  { value: '010', remark: '0' },
  { value: '011', remark: '조건부합격(1년)' },
  { value: '012', remark: '2개월조건부조건후불합격' },
  { value: '001', remark: '합격' },
  { value: '007', remark: '보완후합격' },
  { value: '008', remark: '현장시정조치합격' },
  { value: '009', remark: '보완' },
  { value: '013', remark: '보완후불합격' },
  { value: '014', remark: '승인' },
  { value: '004', remark: '조건후합격' },
  { value: '005', remark: '조건후불합격' },
  { value: '006', remark: '조건부합격' },
];

const gubuns2 = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

const gubuns3 = [
  { value: '001', remark: '정기' },
  { value: '002', remark: '정밀' },
  { value: '003', remark: '자체' },
  { value: '004', remark: '완성' },
  { value: '005', remark: '수시' },
  { value: '006', remark: '검사' },
  { value: '007', remark: '설치' },
  { value: '008', remark: '0' },
];

const states2 = [
  { value: '0', remark: '접수' },
  { value: '1', remark: '배정' },
  { value: '2', remark: '완료' },
];

/**
 * 화면
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export const MonthResultTemplate: React.FC<TemplateProps<MonthResult>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={220}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => {
                scope.setState({ yearmon: value },
                  () => scope.onRetrieveEvent());
              }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  scope.setState({
                    yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                  },
                  () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  scope.setState({
                    yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                  },
                  () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <Button onClick={() => scope.onModalEvent(true)}>
            <FcCalculator
              size={36}
              style={{
                margin: 0,
              }}
            />
          </Button>

          <FlexLayout
            justify="left"
            align="center"
            style={{
              marginLeft: 16,
              color: 'var(--color-blue)',
            }}
          >
            * 월 말 산정기준입니다.
          </FlexLayout>

          {/* <FlexLayout */}
          {/*  isVertical={true} */}
          {/*  justify="center" */}
          {/*  size={400} */}
          {/* > */}
          {/*  <figure */}
          {/*    style={{ */}
          {/*      margin: 0, */}
          {/*      color: 'var(--color-blue)', */}
          {/*    }} */}
          {/*  > */}
          {/*    적용점수는 최종점수 * 입사일기준(입사일)이 적용됩니다. */}
          {/*  </figure> */}
          {/*  <figure */}
          {/*    style={{ */}
          {/*      margin: 0, */}
          {/*      color: 'var(--color-violet)', */}
          {/*    }} */}
          {/*  > */}
          {/*    1.입사2년이상 100%  &nbsp;&nbsp;&nbsp; 2.입사1년차 40% &nbsp;&nbsp;&nbsp;3.입사9개월미만 20% */}
          {/*  </figure> */}
          {/*  <figure */}
          {/*    style={{ */}
          {/*      margin: 0, */}
          {/*      color: 'var(--color-violet)', */}
          {/*    }} */}
          {/*  > */}
          {/*    4.입사6개월미만 10% &nbsp;&nbsp;&nbsp; 5.입사3개월미만 0% */}
          {/*  </figure> */}
          {/* </FlexLayout> */}

          <FlexLayout>
            <FlexLayout>
              <OptionBox
                value={scope.state.kukcdGubun}
                data={[
                  { value: '%', remark: '전직원' },
                  { value: '02', remark: '보수' },
                ]}
                onChange={(item) => scope.setState({ kukcdGubun: String(item.value) })}
                >
              </OptionBox>
            </FlexLayout>
          </FlexLayout>

          <Button onClick={() => scope.onRefreshRetrieve()}>
            <div style={{ width: 80 }}>새로고침</div>
          </Button>

          <FlexLayout
            justify="left"
            align="center"
            style={{ color: '#8106aa' }}
          >
            * 점수갱신은 매일새벽에 갱신됩니다. 바로 적용하시려면 새로고침을 눌러주세요.
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isScroll={true}>
        <TabLayout
          tabs={MonthResultTab}
          scope={scope}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.minus_state}
        onClose={() => scope.onDoubleClickEvent(false, 'minus', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 차감평가 받은 내역`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 10,
              },
              {
                id: 'give_pernm',
                text: '평가기준직원',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 't_4',
                text: '▶',
                width: 10,
                render: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    ▶
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '평가받은직원',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'minus',
                text: '차감된점수',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.minus}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '평가내용(차감)',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '삭제',
                width: 10,
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'minus', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.rece_talent_state}
        onClose={() => scope.onDoubleClickEvent(false, 'rece_talent', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 내가 직원평가 받은 내역`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 10,
              },
              {
                id: 'pernm',
                text: '평가준직원',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 't_4',
                text: '▶',
                width: 10,
                render: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    ▶
                  </FlexLayout>
                ),
              },
              {
                id: 'give_pernm',
                text: '평가받은직원',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.give_pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'give_talent',
                text: '점수',
                width: 10,
              },
              {
                id: 'give_remark',
                text: '평가내용',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.give_remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '삭제',
                width: 10,
                render: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.userid === '00001'
                      ? <Button
                        style={{ height: '25px', width: '60px' }}
                        onClick={() => scope.onMinusSave('rece_talent')}
                      >
                        삭제
                      </Button>
                      : ''}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'rece_talent', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.give_talent_state}
        onClose={() => scope.onDoubleClickEvent(false, 'give_talent', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 내가 직원평가준 내역`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 10,
              },
              {
                id: 'pernm',
                text: '평가기준직원',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 't_4',
                text: '▶',
                width: 10,
                render: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    ▶
                  </FlexLayout>
                ),
              },
              {
                id: 'give_pernm',
                text: '평가받은직원',
                width: 10,
              },
              {
                id: 'give_talent',
                text: '점수',
                width: 10,
              },
              {
                id: 'give_remark',
                text: '평가내용',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.give_remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '삭제',
                width: 10,
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'give_talent', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e037not_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e037not', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 업무일지당일미작성 내역`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '작성자',
                width: 10,
              },
              {
                id: 'rptdate',
                text: '업무일지일자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rptdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'indate',
                text: '작성일자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.indate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'gigan',
                text: '경과일',
                width: 10,
              },
              {
                id: 'overwk',
                text: '익일계획내용',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.overwk}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e037not', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.pb404_edurececnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'pb404_edurececnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 교육받은건수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '교육받은자',
                width: 10,
              },
              {
                id: 'edudate',
                text: '교육일자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.edudate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'edunum',
                text: '번호',
                width: 10,
              },
              {
                id: 'title',
                text: '제목',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'pb404_edurececnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.pb404_educnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'pb404_educnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 교육한건수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '교육자',
                width: 10,
              },
              {
                id: 'edudate',
                text: '교육일자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.edudate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'edunum',
                text: '번호',
                width: 10,
              },
              {
                id: 'title',
                text: '제목',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'pb404_educnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.pb403_propose_state}
        onClose={() => scope.onDoubleClickEvent(false, 'pb403_propose', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 개선제안`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '작성자',
                width: 10,
              },
              {
                id: 'paperdate',
                text: '작성일자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.paperdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'papernum',
                text: '번호',
                width: 10,
              },
              {
                id: 'title',
                text: '제목',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'pb403_propose', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.pb401_out_state}
        onClose={() => scope.onDoubleClickEvent(false, 'pb401_out', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 출장내역`}
          </LayoutTitle>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'pernm',
                  text: '출장자',
                  width: 10,
                },
                {
                  id: 'outdate',
                  text: '출장일자',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계: {scope.state.popupTotal} 건
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.outdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'outnum',
                  text: '번호',
                  width: 10,
                },
                {
                  id: 'title',
                  text: '제목',
                  width: 70,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.title}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
              onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="출장사유">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.remark}
                onChange={(value) => setData({ remark: value })}
                textAlign="left"
              />
            </LabelBox>

            <LabelBox title="결과보완사항">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.result}
                onChange={(value) => setData({ result: value })}
                textAlign="left"
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'pb401_out', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e471_contuse_compamt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e471-contuse_compamt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 공사, 부품교체 매출공사이익`}
          </LayoutTitle>

          <FlexLayout isVertical={true} weight={0.1}>
            <div style={{ color: 'var(--color-blue)' }}>적용일자: 2017년 09월 01일</div>
            <div style={{ color: 'var(--color-red)' }}>
              *최초견적이 없는내용은 적용금액이 발생괴지 않습니다. 견적금액이 0일 경우 견적을 필히 작성하세요
            </div>
            <div style={{ color: '#7F7A2C' }}>
              *2017년 09월 이전 수주(계약)로 이미 인센티브금액이 지급된 내역은 적용금액에 발생하지 않습니다.
            </div>
          </FlexLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'projno',
                  text: '프로젝트코드',
                  width: 100,
                },
                {
                  id: 'projnm',
                  text: '프로젝트명',
                  width: 200,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계: {scope.state.popupTotal} 건
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.projnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'entdate',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        align="start"
                      >
                        주계약자
                      </FlexLayout>
                      <FlexLayout
                        align="end"
                      >
                        적용율
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      isVertical={true}
                      style={{
                        color: scope.state.departmetsFocused?.perid === x.perid
                        || scope.state.individualsFocused?.perid === x.perid
                          ? 'var(--color-blue)' : '',
                      }}
                    >
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.pernm}
                      </FlexLayout>

                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.comperyul}%
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'entdate',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        align="start"
                      >
                        계약자2
                      </FlexLayout>
                      <FlexLayout
                        align="end"
                      >
                        적용율
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      isVertical={true}
                      style={{
                        color: scope.state.departmetsFocused?.perid === x.perid2
                        || scope.state.individualsFocused?.perid === x.perid2
                          ? 'var(--color-blue)' : '',
                      }}
                    >
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.pernm2}
                      </FlexLayout>

                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.comperyul2}%
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'entdate',
                  text: (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        align="start"
                      >
                        계약자3
                      </FlexLayout>
                      <FlexLayout
                        align="end"
                      >
                        적용율
                      </FlexLayout>
                    </FlexLayout>
                  ),
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      isVertical={true}
                      style={{
                        color: scope.state.departmetsFocused?.perid === x.perid3
                        || scope.state.individualsFocused?.perid === x.perid3
                          ? 'var(--color-blue)' : '',
                      }}
                    >
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.pernm3}
                      </FlexLayout>

                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.comperyul3}%
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ordamt',
                  text: '수주(견적)금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(x.ordamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'rcvamt',
                  text: '수금금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rcvamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mijamt',
                  text: '매입(지출)금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'iamt',
                  text: '이익금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'useamt',
                  text: '적용금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.useamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'sndflag',
                  text: '지급유무',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.sndflag === '0' ? 'var(--color-blue)' : '' }}
                    >
                      {x.sndflag === '1' ? '지급' : '미지급'}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
               onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e471-contuse_compamt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e471_misuse_compamt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e471-misuse_compamt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 공사, 부품교체 매출공사이익`}
          </LayoutTitle>

          <FlexLayout
            isVertical={true}
            weight={0.1}
          >
            <div style={{ color: 'var(--color-blue)' }}>적용일자: 2017년 09월 01일</div>
            <div style={{ color: 'var(--color-red)' }}>
              *최초견적이 없는내용은 적용금액이 발생괴지 않습니다. 견적금액이 0일 경우 견적을 필히 작성하세요
            </div>
            <div style={{ color: '#7F7A2C' }}>
              *2017년 09월 이전 수주(계약)로 이미 인센티브금액이 지급된 내역은 적용금액에 발생하지 않습니다.
            </div>
          </FlexLayout>
          <FlexLayout>
          <GridLayout
            header={[
              {
                id: 'projno',
                text: '프로젝트코드',
                width: 100,
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 200,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                    >
                      주처리자
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                    >
                      적용일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    style={{
                      color: scope.state.departmetsFocused?.perid === x.perid
                      || scope.state.individualsFocused?.perid === x.perid
                        ? 'var(--color-blue)' : '',
                    }}
                    >
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.comperyul}%
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                    >
                      처리자2
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                    >
                      적용율
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    style={{
                      color: scope.state.departmetsFocused?.perid === x.perid2
                      || scope.state.individualsFocused?.perid === x.perid2
                        ? 'var(--color-blue)' : '',
                    }}
                  >
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm2}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.comperyul2}%
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                    >
                      처리자3
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                    >
                      적용율
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    style={{
                      color: scope.state.departmetsFocused?.perid === x.perid3
                      || scope.state.individualsFocused?.perid === x.perid3
                        ? 'var(--color-blue)' : '',
                    }}
                  >
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm3}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.comperyul3}%
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'ordamt',
                text: '수주(견적)금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.ordamt)}
                    </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '수금금액',
                width: 100,
                render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rcvamt)}
                    </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '매입(지출)금액',
                width: 100,
                render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt)}
                    </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '이익금액',
                width: 100,
                render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                ),
              },
              {
                id: 'useamt',
                text: '적용금액',
                width: 100,
                render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.useamt)}
                    </FlexLayout>
                ),
              },
              {
                id: 'sndflag',
                text: '지급유무',
                width: 100,
                render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.sndflag === '0' ? 'var(--color-blue)' : '' }}
                    >
                      {x.sndflag === '1' ? '지급' : '미지급'}
                    </FlexLayout>
                ),
              },
            ]}
              data={scope.state.popupData}
               onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e471-misuse_compamt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e451_costcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e451_costcnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 견적현황`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'costdate',
                text: '견적일자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.costdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'costnum',
                text: '번호',
                width: 80,
              },
              {
                id: 'costpernm',
                text: '담당자',
                width: 80,
              },
              {
                id: 'gubun',
                text: '매출구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {gubuns2.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'constnm',
                text: '공사명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.constnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'samt',
                text: '공급가액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tamt',
                text: '부가세',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mamt',
                text: '공사금액',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 300,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e451_costcnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.b501_businesscnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'b501_businesscnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 영업내역`}
          </LayoutTitle>

          <FlexLayout weight={2}>
            <GridLayout
              header={[
                {
                  id: 'pernm',
                  text: '처리자',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계: {scope.state.popupTotal} 건
                    </FlexLayout>
                  ),
                },
                {
                  id: 'plandate',
                  text: '계획일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.plandate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compdate',
                  text: '방문일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.compdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'address',
                  text: '주소',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.address}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mdnm',
                  text: '기종(모델)',
                  width: 100,
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 100,
                },
                {
                  id: 'amt',
                  text: '보수료',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.amt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bosunm',
                  text: '보수업체',
                  width: 100,
                },
                {
                  id: 'bosupernm',
                  text: '관리책임자',
                  width: 100,
                },
                {
                  id: 'bosutelnum',
                  text: '관리책임자연락처',
                  width: 100,
                },
              ]}
              data={scope.state.popupData}
               onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="출장사유">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.adviceremark}
                onChange={(value) => setData({ adviceremark: value })}
                textAlign="left"
              />
            </LabelBox>

            <LabelBox title="결과보완사항">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.resultremark}
                onChange={(value) => setData({ resultremark: value })}
                textAlign="left"
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="기타내용 및 승강기상태">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.bigo}
                onChange={(value) => setData({ bigo: value })}
                textAlign="left"
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'b501_businesscnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_eleccnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_eleccnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전자계약서`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '작성자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_eleccnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_clscnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_clscnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 계약해지`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'clsdate',
                text: '해지일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Date8.withDash(x.clsdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'clsrecedate',
                text: '해지접수일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Date8.withDash(x.clsrecedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_clscnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_yucnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_yucnt03', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 무 -> 유상 재계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '작성자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_yucnt03', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_pluscnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_pluscnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 계약인상`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '해지일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Date8.withDash(x.clsdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'clsrecedate',
                text: '해지접수일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Date8.withDash(x.clsrecedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='left'
                    justify='center'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_pluscnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_pogcnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_pogcnt03', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 익월 재계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : x.beamt === x.amt ? '' : 'var(--color-red)' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_pogcnt03', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_fmcnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_fmcnt03', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} FM 재계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : x.beamt === x.amt ? '' : 'var(--color-red)' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_fmcnt03', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_longcnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_longcnt03', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 다년 재계약(5년이상)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : x.beamt === x.amt ? '' : 'var(--color-red)' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_longcnt03', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_vlongcnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_vlongcnt03', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 장기 재계약(10년이상)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : x.beamt === x.amt ? '' : 'var(--color-red)' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_vlongcnt03', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_sccnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_sccnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 설치 신규계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '계약자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 46,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'qty',
                text: '대수',
                width: 10,
              },
              {
                id: 'contdate',
                text: '계약일',
                width: 17,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.contdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contamt',
                text: '계약금액',
                width: 17,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.contamt)}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_sccnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_mucnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_mucnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 무상 신규계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '작성자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_mucnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_pogcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_pogcnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 일반 신규계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_pogcnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_fmcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_fmcnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} FM 신규계약`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : '' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_fmcnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_longcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_longcnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 다년 신규계약(5년이상)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : x.beamt === x.amt ? '' : 'var(--color-red)' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_longcnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e101_vlongcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e101_vlongcnt', 'retrieve3')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 장기 신규계약(10년이상)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'contpernm',
                text: '계약자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.contpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='left'
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'hdate',
                text: '최근답변기록일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {Date8.withDash(x.hdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '최근답변내용',
                width: 300,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ backgroundColor: '#FAFA80' }}
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'bestdate',
                text: '전시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.bestdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beenddate',
                text: '전종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Date8.withDash(x.beenddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontgubun',
                text: '전계약구분',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '전분류',
                width: 100,
                color: '#7F7A2C',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {contgs.filter((y) => y.value === x.becontg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'becontg',
                text: '->',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.beamt < x.amt ? '#7F7A2C' : x.beamt === x.amt ? '' : 'var(--color-red)' }}
                  >
                    {x.beamt < x.amt ? '↑'
                      : x.beamt === x.amt ? '-' : '↓'}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'amt',
                text: '보수료',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '분류',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e101_vlongcnt', 'retrieve3')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e073_kc003cnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e073_kc003cnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 검사불합격`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
              },
              {
                id: 'state',
                text: '진행',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{ color: x.state === '2' ? 'var(--color-blue)' : '' }}
                  >
                    {states?.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '검사일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'result',
                text: '결과',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{ color: x.state === '2' || x.state === '5' ? 'var(--color-red)' : '' }}
                  >
                    {results?.filter((y) => y.value === x.result)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'gubun',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.gubun === '002'
                        ? 'var(--color-red)' : x.gubun === '003'
                          ? 'var(--color-red)' : '',
                    }}
                  >
                    {gubuns3.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '검사자',
                width: 200,
              },
              {
                id: 'kcspnm',
                text: '검사기관',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {x.kcspnm === '3' ? '승강기안전공단' : x.kcspnm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e073_kc003cnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e073_kc002cnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e073_kc002cnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 검사조건부합격`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
              },
              {
                id: 'state',
                text: '진행',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{ color: x.state === '2' ? 'var(--color-blue)' : '' }}
                  >
                    {states?.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '검사일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'result',
                text: '결과',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{
                      color: x.result === '002' || x.result === '005' ? 'var(--color-red)' : 'var(--color-blue)',
                    }}
                  >
                    {results?.filter((y) => y.value === x.result)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'gubun',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.gubun === '002'
                        ? 'var(--color-red)' : x.gubun === '003'
                          ? 'var(--color-red)' : '',
                    }}
                  >
                    {gubuns3.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '검사자',
                width: 200,
              },
              {
                id: 'kcspnm',
                text: '검사기관',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {x.kcspnm === '3' ? '승강기안전공단' : x.kcspnm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e073_kc002cnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e073_kc001cnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e073_kc001cnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 검사합격`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 100,
              },
              {
                id: 'state',
                text: '진행',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{ color: x.state === '2' ? 'var(--color-blue)' : '' }}
                  >
                    {states?.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '검사일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'result',
                text: '결과',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{
                      color: x.result === '002' || x.result === '005' ? 'var(--color-red)' : 'var(--color-blue)',
                    }}
                  >
                    {results?.filter((y) => y.value === x.result)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'gubun',
                text: '구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.gubun === '002'
                        ? 'var(--color-red)' : x.gubun === '003'
                          ? 'var(--color-red)' : '',
                    }}
                  >
                    {gubuns3.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcdate',
                text: '검사일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.kcdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '검사자',
                width: 200,
              },
              {
                id: 'kcspnm',
                text: '검사기관',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {x.kcspnm === '3' ? '승강기안전공단' : x.kcspnm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e073_kc001cnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e301_minwoncompcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e301_minwoncompcnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 민원처리내역`}
          </LayoutTitle>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {states2.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'repernm',
                  text: '접수자',
                  width: 100,
                },
                {
                  id: 'pernm',
                  text: '처리자(배정)',
                  width: 100,
                  render: (x: any) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okflag',
                  text: '처리구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.okflag === '1' ? '' : 'var(--color-red)' }}
                    >
                      {x.okflag}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recedate',
                  text: '민원일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.recedate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recenum',
                  text: '번호',
                  width: 100,
                },
                {
                  id: 'actnm',
                  text: '고객/민원신고자명',
                  width: 200,
                  render: (x: any) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'civilnm',
                  text: '민원구분',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: x.civilnm === '민원예방' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.civilnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compdate',
                  text: '완료일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.compdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resultnm',
                  text: '처리결과',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.resultcd === '001' ? 'var(--color-blue)' : '' }}
                    >
                      {x.resultnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
               onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="고객민원내용">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.remark}
                onChange={(value) => setData({ remark: value })}
                textAlign="left"
              />
            </LabelBox>

            <LabelBox title="민원처리결과내용">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.compremark}
                onChange={(value) => setData({ compremark: value })}
                textAlign="left"
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e301_minwoncompcnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e301_minwonnotcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e301_minwonnotcnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 민원미처리내역`}
          </LayoutTitle>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'repernm',
                  text: '접수자',
                  width: 100,
                },
                {
                  id: 'pernm',
                  text: '처리자(배정)',
                  width: 100,
                  render: (x: any) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okflag',
                  text: '처리구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.okflag === '1' ? '' : 'var(--color-red)' }}
                    >
                      {x.okflag}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recedate',
                  text: '민원일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.recedate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recenum',
                  text: '번호',
                  width: 100,
                },
                {
                  id: 'actnm',
                  text: '고객/민원신고자명',
                  width: 200,
                  render: (x: any) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'civilnm',
                  text: '민원구분',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: x.civilnm === '민원예방' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.civilnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compdate',
                  text: '완료일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.compdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resultnm',
                  text: '처리결과',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.resultcd === '001' ? 'var(--color-blue)' : '' }}
                    >
                      {x.resultnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
              onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="고객민원내용">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.remark}
                onChange={(value) => setData({ remark: value })}
                textAlign="left"
              />
            </LabelBox>

            <LabelBox title="민원처리결과내용">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.compremark}
                onChange={(value) => setData({ compremark: value })}
                textAlign="left"
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e301_minwonnotcnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e301_minwoncnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e301_minwoncnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 민원접수내역`}
          </LayoutTitle>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계: {scope.state.popupTotal} 건
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'repernm',
                  text: '접수자',
                  width: 100,
                },
                {
                  id: 'pernm',
                  text: '처리자(배정)',
                  width: 100,
                  render: (x: any) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okflag',
                  text: '처리구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.okflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.okflag === '1' ? '처리' : x.okflag === '2' ? '미처리' : x.okflag}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recedate',
                  text: '민원일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.recedate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recenum',
                  text: '번호',
                  width: 100,
                },
                {
                  id: 'actnm',
                  text: '고객/민원신고자명',
                  width: 200,
                  render: (x: any) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'civilnm',
                  text: '민원구분',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: x.civilnm === '민원예방' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.civilnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compdate',
                  text: '완료일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.compdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resultnm',
                  text: '처리결과',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.resultcd === '001' ? 'var(--color-blue)' : '' }}
                    >
                      {x.resultnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
              onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="고객민원내용">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.remark}
                onChange={(value) => setData({ remark: value })}
                textAlign="left"
              />
            </LabelBox>

            <LabelBox title="민원처리결과내용">
              <TextBox
                isMultiline={true}
                value={scope.state.popupFocused?.compremark}
                onChange={(value) => setData({ compremark: value })}
                textAlign="left"
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e301_minwoncnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_trouble_c_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_trouble_c', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 고장배정거절(사람갖힘)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recenum',
                text: '번호',
                width: 10,
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 25,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '배정거절사유',
                width: 25,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'repernm',
                text: '배정자',
                width: 10,
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_trouble_c', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_trouble_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_trouble', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 고장처리(사람갖힘)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actpernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.actperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.trouble === '1' ? 'var(--color-red)' : '' }}
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnm',
                text: '처리',
                width: 100,
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 100,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resutime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resulttime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.stoptime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_trouble', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_rececompcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_rececompcnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 고장처리`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actpernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.actperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.trouble === '1' ? 'var(--color-red)' : '' }}
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnm',
                text: '처리',
                width: 100,
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 100,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resutime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resulttime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.stoptime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_rececompcnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_wadcnt_ex_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_wadcnt_ex', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 담당 다발고장(제외)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actpernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.actperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.trouble === '1' ? 'var(--color-red)' : '' }}
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnm',
                text: '처리',
                width: 100,
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 100,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resutime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resulttime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.stoptime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_wadcnt_ex', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_wadcnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_wadcnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 담당고장(제외)`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actpernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.actperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.trouble === '1' ? 'var(--color-red)' : '' }}
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnm',
                text: '처리',
                width: 100,
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 100,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resutime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resulttime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.stoptime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_wadcnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_rececnt_ex_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_rececnt_ex', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 담당 다발고장`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actpernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.actperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.trouble === '1' ? 'var(--color-red)' : '' }}
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnm',
                text: '처리',
                width: 100,
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 100,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resutime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resulttime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.stoptime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_rececnt_ex', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e401_rececnt_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e401_rececnt', 'retrieve2')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 담당고장`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'actpernm',
                text: '담당자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.actperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 100,
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.trouble === '1' ? 'var(--color-red)' : '' }}
                  >
                    {x.contnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contremark',
                text: '고장내용상세',
                width: 200,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.contremark}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnm',
                text: '처리',
                width: 100,
              },
              {
                id: 'pernm',
                text: '처리자',
                width: 100,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.actpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '처리자2',
                width: 100,
              },
              {
                id: 'resutime',
                text: '대응시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resutime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'resulttime',
                text: '처리시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.resulttime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'stoptime',
                text: '비운행시간',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.stoptime}분
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recetime',
                text: '접수시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.recetime}
                  />
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'comptime',
                text: '완료시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.comptime}
                  />
                ),
              },
              {
                id: 'arrivdate',
                text: '도착일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.arrivdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'arrivtime',
                text: '도착시간',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="##:##"
                    value={x.arrivtime}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e401_rececnt', 'retrieve2')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt10_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt10', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 주차설비작업용 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt10', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt10_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt10', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 주차설비작업용 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt10', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt10_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt10', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 주차설비작업용 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt10', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty10_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty10', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 주차설비작업용 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 55,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty10', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt05_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt05', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 휠체어리프트 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt05', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt05_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt05', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 휠체어리프트 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt05', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt05_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt05', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 휠체어리프트 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt05', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty05_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty05', 'retrieve')}
      >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>
          {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 휠체어리프트 관리대수`}
        </LayoutTitle>

        <GridLayout
          header={[
            {
              id: 'pernm',
              text: '담당자',
              width: 10,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계: {scope.state.popupTotal} 건
                </FlexLayout>
              ),
              render: (x: any) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 55,
              render: (x: any) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 10,
            },
            {
              id: 'evcd',
              text: '점검기종',
              width: 15,
              render: (x: any) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                </FlexLayout>
              ),
            },
            {
              id: 'class2',
              text: '구분',
              width: 10,
              render: (x: any) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.class2 === 'P' ? '인승용' : '화물용'}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.popupData}
           onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty05', 'retrieve')}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt04_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt04', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 덤웨이터 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt04', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt04_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt04', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 덤웨이터 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt04', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt04_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt04', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 덤웨이터 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt04', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty04_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty04', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 덤웨이터 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 55,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty04', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt03', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 에스컬레이터 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt03', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt03', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 에스컬레이터 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt03', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt03', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 에스컬레이터 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt03', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty03_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty03', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 에스컬레이터 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 55,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty03', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt02_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt02_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 화물용 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt02_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt02_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt02_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 화물용 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt02_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt02_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt02_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 화물용 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt02_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty02_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty02_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 화물용 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 55,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty02_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt02_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt02', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 인승용 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcpernm2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt02', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt02_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt02', 'retrieve')}
      >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>
          {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 인승용 지원받은대수`}
        </LayoutTitle>

        <GridLayout
          header={[
            {
              id: 'pernm',
              text: '담당자',
              width: 10,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계: {scope.state.popupTotal} 건
                </FlexLayout>
              ),
              render: (x: any) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'kcpernm',
              text: '처리자',
              width: 10,
              render: (x: any) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                >
                  {x.kcpernm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 45,
              render: (x: any) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 10,
            },
            {
              id: 'evcd',
              text: '점검기종',
              width: 15,
              render: (x: any) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                </FlexLayout>
              ),
            },
            {
              id: 'class2',
              text: '구분',
              width: 10,
              render: (x: any) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.class2 === 'P' ? '인승용' : '화물용'}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.popupData}
          onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt02', 'retrieve')}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt02_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt02', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 인승용 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt02', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty02_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty02', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 유압식엘리베이터 인승용 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty02', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt01_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt01_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 화물용 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt01_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt01_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt01_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 화물용 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt01_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt01_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt01_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 화물용 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt01_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty01_2_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty01_2', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 화물용 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 55,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty01_2', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jcompcnt01_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt01', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 인승용 지원점검한대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid2 === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jcompcnt01', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_jrececnt01_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_jrececnt01', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 인승용 지원받은대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_jrececnt01', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_totplancnt01_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_totplancnt01', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 인승용 점검대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.perid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '처리자',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.kcperid === scope.state.departmetsFocused?.perid ? 'var(--color-blue)' : '' }}
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 45,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 10,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
             onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_totplancnt01', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        height={600}
        isVisible={scope.state.e035_qty01_state}
        onClose={() => scope.onDoubleClickEvent(false, 'e035_qty01', 'retrieve')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>
            {`${scope.state.yearmon.slice(0, 4)} - ${scope.state.yearmon.slice(4, 6)} 전기식엘리베이터 인승용 관리대수`}
          </LayoutTitle>

          <GridLayout
            header={[
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계: {scope.state.popupTotal} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 53,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
              },
              {
                id: 'evcd',
                text: '점검기종',
                width: 15,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.state.evcdList.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'class2',
                text: '구분',
                width: 12,
                render: (x: any) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.class2 === 'P' ? '인승용' : '화물용'}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData}
            onRowClick={(item) => scope.onPopupRowFocusEvent(item)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDoubleClickEvent(false, 'e035_qty01', 'retrieve')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={1600}
        height={400}
        isVisible={scope.state.modalState}
        onClose={() => scope.onModalEvent(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout
            isVertical={true}
            weight={0.01}
          >
            <GridLayoutAdditionalHeader
              ref={scope.additionalTitle}
              height={Global.LAYOUT_TITLE_HEIGHT_1}
            >
              <FlexLayout
                size={607}
                justify="center"
                align="center"
              >
               점검부문
              </FlexLayout>

              <FlexLayout
                size={250}
                justify="center"
                align="center"
              >
                고장부문
              </FlexLayout>

              <FlexLayout
                size={186}
                justify="center"
                align="center"
              >
                민원부문
              </FlexLayout>

              <FlexLayout
                size={188}
                justify="center"
                align="center"
              >
                검사부문
              </FlexLayout>

              <FlexLayout
                size={60}
                justify="center"
                align="center"
              >
                견적부문
              </FlexLayout>

              <FlexLayout
                size={125}
                justify="center"
                align="center"
              >
                공사부문
              </FlexLayout>

              <FlexLayout
                size={152}
                justify="center"
                align="center"
              >
                업무일지부문
              </FlexLayout>
            </GridLayoutAdditionalHeader>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.modalTable}
              header={[
                {
                  id: 'amt01',
                  text: '전기식인승점수',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt01}
                      onChange={(value) => rowUpdate({ amt01: value })}
                    />
                  ),
                },
                {
                  id: 'amt01_2',
                  text: '전기식화물점수',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt01_2}
                      onChange={(value) => rowUpdate({ amt01_2: value })}
                    />
                  ),
                },
                {
                  id: 'amt02',
                  text: '유압식인승점수',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt02}
                      onChange={(value) => rowUpdate({ amt02: value })}
                    />
                  ),
                },
                {
                  id: 'amt02_2',
                  text: '유압식화물점수',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt02_2}
                      onChange={(value) => rowUpdate({ amt02_2: value })}
                    />
                  ),
                },
                {
                  id: 'amt03',
                  text: '에스컬레이터점수',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt03}
                      onChange={(value) => rowUpdate({ amt03: value })}
                    />
                  ),
                },
                {
                  id: 'amt04',
                  text: '덤웨이터(주차기)점수',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt04}
                      onChange={(value) => rowUpdate({ amt04: value })}
                    />
                  ),
                },
                {
                  id: 'amt05',
                  text: '휠체어리프트점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt05}
                      onChange={(value) => rowUpdate({ amt05: value })}
                    />
                  ),
                },
                {
                  id: 'amt10',
                  text: '주차설비작업용점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.amt10}
                      onChange={(value) => rowUpdate({ amt10: value })}
                    />
                  ),
                },
                {
                  id: 'receamtexec',
                  text: '담당고장차감점수',
                  width: 4,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.receamtexec}
                      onChange={(value) => rowUpdate({ receamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'wadamtexec',
                  text: '다발고장차감점수',
                  width: 4,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.wadamtexec}
                      onChange={(value) => rowUpdate({ wadamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'rececompamtexec',
                  text: '고장처리점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.rececompamtexec}
                      onChange={(value) => rowUpdate({ rececompamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'troubleamtexec',
                  text: '긴급출동(사람갇힘)점수',
                  width: 4,
                  color: '#8106aa',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.troubleamtexec}
                      onChange={(value) => rowUpdate({ troubleamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'minwonamtexec',
                  text: '접수점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.minwonamtexec}
                      onChange={(value) => rowUpdate({ minwonamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'minwonnotamtexec',
                  text: '미처리차감점수',
                  width: 4,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.minwonnotamtexec}
                      onChange={(value) => rowUpdate({ minwonnotamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'minwoncompamtexec',
                  text: '처리점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.minwoncompamtexec}
                      onChange={(value) => rowUpdate({ minwoncompamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'kc001amtexec',
                  text: '합격점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.kc001amtexec}
                      onChange={(value) => rowUpdate({ kc001amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'kc002amtexec',
                  text: '조건부점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.kc002amtexec}
                      onChange={(value) => rowUpdate({ kc002amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'kc003amtexec',
                  text: '불합격차감',
                  width: 4,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.kc003amtexec}
                      onChange={(value) => rowUpdate({ kc003amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'costexec',
                  text: '견적건당점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.costexec}
                      onChange={(value) => rowUpdate({ costexec: value })}
                    />
                  ),
                },
                {
                  id: 'compyul',
                  text: '처리인센률(%)',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.compyul}
                      onChange={(value) => rowUpdate({ compyul: value })}
                    />
                  ),
                },
                {
                  id: 'comp_contyul',
                  text: '계약인센율(%)',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.comp_contyul}
                      onChange={(value) => rowUpdate({ comp_contyul: value })}
                    />
                  ),
                },
                {
                  id: 'e037exec',
                  text: '당일처리',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.e037exec}
                      onChange={(value) => rowUpdate({ e037exec: value })}
                    />
                  ),
                },
                {
                  id: 'e037notexec',
                  text: '당일미처리',
                  width: 6,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.e037notexec}
                      onChange={(value) => rowUpdate({ e037notexec: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.modalData}
              onChange={(rows, updatedRows) => {
                scope.onPopupUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout
            isVertical={true}
            weight={0.01}
          >
            <GridLayoutAdditionalHeader
              ref={scope.additionalTitle}
              height={Global.LAYOUT_TITLE_HEIGHT_1}
            >
              <FlexLayout
                size={479}
                justify="center"
                align="center"
              >
                계약부문(신규)
              </FlexLayout>

              <FlexLayout
                size={472}
                justify="center"
                align="center"
              >
                계약부문(재계약)
              </FlexLayout>

              <FlexLayout
                size={156}
                justify="center"
                align="center"
              >
                계약부문
              </FlexLayout>

              <FlexLayout
                size={61}
                justify="center"
                align="center"
              >
                개선제안
              </FlexLayout>

              <FlexLayout
                size={61}
                justify="center"
                align="center"
              >
                교육부문
              </FlexLayout>

              <FlexLayout
                size={60}
                justify="center"
                align="center"
              >
                달란트
              </FlexLayout>

              <FlexLayout
                size={125}
                justify="center"
                align="center"
              >
                영업부문
              </FlexLayout>

              <FlexLayout
                size={150}
                justify="center"
                align="center"
              >
                출장부문
              </FlexLayout>
            </GridLayoutAdditionalHeader>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.modalTable2}
              header={[
                {
                  id: 'vlongamtexec',
                  text: '장기(10년)신규',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.vlongamtexec}
                      onChange={(value) => rowUpdate({ vlongamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'longamtexec',
                  text: '다년(5년)신규',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.longamtexec}
                      onChange={(value) => rowUpdate({ longamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'fmamtexec',
                  text: 'FM신규',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.fmamtexec}
                      onChange={(value) => rowUpdate({ fmamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'pogamtexec',
                  text: '일반신규',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.pogamtexec}
                      onChange={(value) => rowUpdate({ pogamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'muamtexec',
                  text: '무상신규',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.muamtexec}
                      onChange={(value) => rowUpdate({ muamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'scamtexec',
                  text: '설치신규',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.scamtexec}
                      onChange={(value) => rowUpdate({ scamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'vlong03amtexec',
                  text: '장기(10년)재계약',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.vlong03amtexec}
                      onChange={(value) => rowUpdate({ vlong03amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'long03amtexec',
                  text: '다년(5)년재계약',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.long03amtexec}
                      onChange={(value) => rowUpdate({ long03amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'fm03amtexec',
                  text: 'FM재계약',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.fm03amtexec}
                      onChange={(value) => rowUpdate({ fm03amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'pog03amtexec',
                  text: '익월재계약',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.pog03amtexec}
                      onChange={(value) => rowUpdate({ pog03amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'plusamtexec',
                  text: '인상재계약',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.plusamtexec}
                      onChange={(value) => rowUpdate({ plusamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'yu03amtexec',
                  text: '유상전환재계약',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.yu03amtexec}
                      onChange={(value) => rowUpdate({ yu03amtexec: value })}
                    />
                  ),
                },
                {
                  id: 'clsamtexec',
                  text: '해약(중지)',
                  width: 5,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.clsamtexec}
                      onChange={(value) => rowUpdate({ clsamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'elecamtexec',
                  text: '전자계약서',
                  width: 5,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.elecamtexec}
                      onChange={(value) => rowUpdate({ elecamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'proposeexec',
                  text: '개선제안점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.proposeexec}
                      onChange={(value) => rowUpdate({ proposeexec: value })}
                    />
                  ),
                },
                {
                  id: 'eduamtexec',
                  text: '교육한건수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.eduamtexec}
                      onChange={(value) => rowUpdate({ eduamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'edureceamtexec',
                  text: '교육받은건수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.edureceamtexec}
                      onChange={(value) => rowUpdate({ edureceamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'talentexec',
                  text: '달란트점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.talentexec}
                      onChange={(value) => rowUpdate({ talentexec: value })}
                    />
                  ),
                },
                {
                  id: 'businessexec',
                  text: '영업방문점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.businessexec}
                      onChange={(value) => rowUpdate({ businessexec: value })}
                    />
                  ),
                },
                {
                  id: 'outnotamtexec',
                  text: '시내출장일당점수',
                  width: 4,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.outnotamtexec}
                      onChange={(value) => rowUpdate({ outnotamtexec: value })}
                    />
                  ),
                },
                {
                  id: 'outamtexec',
                  text: '시외출장일당점수',
                  width: 6,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.outamtexec}
                      onChange={(value) => rowUpdate({ outamtexec: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.modalData}
              onChange={(rows, updatedRows) => {
                scope.onPopupUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onPopupRowFocusEvent(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onMondalSave(scope.state.modalData)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.onModalPlusBtn}
        onClose={() => scope.onModalPlus(false)}
        height={400}
        width={800}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout
            weight={0.2}
            style={{ color: 'var(--color-blue)', fontSize: 20 }}
          >
            {scope?.tabIndex === 0 ? scope.state.departmetsFocused?.pernm : scope.state.individualsFocused?.pernm}
            에게 점수를 주시겠습니까?
          </FlexLayout>
          <FlexLayout>
            <LabelBox title="평가내용">
              <TextBox
                isMultiline={true}
                value={scope.state.plusData?.remark}
                onChange={(v) => setData({ remark: v })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="평가점수">
              <TextBox
                isMultiline={true}
                value={scope.state.plusData?.newrow}
                onChange={(v) => setData({ newrow: v })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>

            <LabelBox title="남은점수">
              <TextBox
                isMultiline={true}
                color={'var(--color-red)'}
                value={scope.state.plusData?.remain}
                onChange={(v) => setData({ remain: v })}
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onPlusSave()}>예</Button>
              <Button onClick={() => scope.onModalPlus(false)}>아니오</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.onModalMinusBtn}
        onClose={() => scope.onMinusBtn(false)}
        height={400}
        width={800}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout
            weight={0.2}
            style={{ color: 'var(--color-blue)', fontSize: 20 }}
          >
            {/* eslint-disable-next-line max-len */}
            {scope?.tabIndex === 0 ? scope.state.departmetsFocused?.pernm : scope.state.individualsFocused?.pernm}에게 차감점수를 주시겠습니까?
          </FlexLayout>
          <FlexLayout>
            <LabelBox title="평가내용(차감)">
              <TextBox
                isMultiline={true}
                value={scope.state.minusDate?.remark}
                onChange={(v) => setData({ remark: v })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="차감점수">
              <TextBox
                isMultiline={true}
                value={scope.state.minusDate?.newrow}
                onChange={(v) => setData({ newrow: v })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onMinusOkBtn()}>예</Button>
              <Button onClick={() => scope.onMinusBtn(false)}>아니오</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
