import { GLHF } from '../../../../../constants';
import {
  FlexLayout,
} from '../../../../../components';
import { Regist } from '../Regist';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 상세 > 탭 - 영업내역 > 그리드
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabGridSalesHeader: GLHF<any, Regist> = (scope) => ([
  {
    id: 'plandate',
    text: '계획(작성)일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.plandate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'plannum',
    text: '번호',
    width: 50,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "center"
      >
        {x.plannum}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.total8}
      </FlexLayout>
    ),
  },
  {
    id: 'compdate',
    text: '계획(작성)일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "center"
      >
        {Date8.withDash(x.compdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '상태',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.state === '1' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
      >
        {x.state === '1' ? '계약' : '견적'}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 60,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'advicehpnum',
    text: '상담자연락처',
    width: 90,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "center"
      >
        {x.advicehpnum}
      </FlexLayout>
    ),
  },
  {
    id: 'adviceremark',
    text: '상담내용',
    width: 600,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "left"
      >
        {x.adviceremark}
      </FlexLayout>
    ),
  },
  {
    id: 'resultremark',
    text: '협의내용 및 조사내용',
    width: 600,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "left"
      >
        {x.resultremark}
      </FlexLayout>
    ),
  },
  {
    id: 'bigo',
    text: '기타내용 및 승강기상태',
    width: 600,
    render: (x) => (
      <FlexLayout
        align = "center"
        justify = "left"
      >
        {x.bigo}
      </FlexLayout>
    ),
  },
]);
