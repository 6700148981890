import * as React from 'react';
import { LayoutProps } from '../../../constants';
import mStyle from './CircleBadge.module.scss';
import { JoinClassName } from '../../../utils/string';

export const CircleBadge: React.FC<LayoutProps> = ({
  refs,
  className,
  style,
  children,
  onClick,
  onDoubleClick,
  onMouseMove,
  onMouseLeave,
  onFocus,
  onUnFocus,
  onKeyDown,
}) => (
  <div
    ref={refs}
    className={JoinClassName.make([className, mStyle.container])}
    style={style}
    onClick={() => onClick && onClick()}
    onDoubleClick={() => onDoubleClick && onDoubleClick()}
    onMouseMove={(e) => onMouseMove && onMouseMove(e)}
    onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
    onFocus={(e) => onFocus && onFocus(e)}
    onBlur={(e) => onUnFocus && onUnFocus(e)}
    onKeyDown={(e) => onKeyDown && onKeyDown(e)}
  >
    {children}
  </div>
);
