import * as React from 'react';
import { RefObject } from 'react';
import style from './HwpView.module.scss';
import { ToBase64 } from '../../../utils/binary';
import { ConfirmWarning } from '../../../utils/confirm';

const { Viewer } = require('hwp.js');

export interface HwpViewProps {
  binary: ArrayBuffer;
}

export class HwpView extends React.Component<HwpViewProps> {
  ref: RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      // eslint-disable-next-line no-new
      new Viewer(this.ref.current!, await ToBase64.fromArrayBuffer(this.props.binary), { raw: true });
    } catch (e) {
      ConfirmWarning.show('오류', '한글 문서 로드에 실패하였습니다');
    }
  }

  render() {
    return (
      <div className={style.container}>
        <div
          className={style.view}
          ref={this.ref}
        />
      </div>
    );
  }
}
