import * as React from 'react';
import style from './IFrame.module.scss';

interface ImageViewProps {
  src?: string;
}

export class IFrame extends React.Component<ImageViewProps> {
  constructor(props: ImageViewProps, context: any) {
    super(props, context);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <iframe title="#" className={style.container} src={this.props.src} />
    );
  }
}
