import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ae200
 * @category 지출결의서
 */

export class ExpenditureDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 품명
   *
   * **data type** varchar(255)
   */
  readonly productnm: string;

  /**
   * 사용일자
   *
   * **data type** varchar(8)
   */
  readonly spdate: string;

  /**
   * NO
   *
   * **data type** varchar(3)
   */
  readonly outnum: string;

  /**
   * 상세내역
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 공급가액
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   * 부가세액
   *
   * **data type** varchar(0)
   */
  readonly tamt: string;

  /**
   * 금액
   *
   * **data type** varchar(0)
   */
  readonly amount: string;

  /**
   * 지급처명
   *
   * **data type** varchar(255)
   */
  readonly cltnm: string;

  /**
   * 지급처명
   *
   * **data type** varchar(255)
   */
  readonly allok: string;

  /**
   * -
   *
   * **data type** varchar(10)
   */
  sndflag: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  outdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  summy: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  unit: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  okgubun: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  oknumber: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  qty: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.sndflag = data.sndflag || '';
    this.okgubun = data.okgubun || '';
    this.qty = data.qty || '';
    this.oknumber = data.oknumber || '';
    this.summy = data.summy || '';
    this.unit = data.unit || '';
    this.outdate = data.outdate || '';
    this.spjangcd = data.spjangcd || '';
    this.productnm = data.productnm || '';
    this.spdate = data.spdate || '';
    this.outnum = data.outnum || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.amount = data.amount || '';
    this.cltnm = data.cltnm || '';
    this.allok = data.allok || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
