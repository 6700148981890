/**
 * 모델
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export default class ManageStatusModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 예상지출금액
   *
   * **data type** varchar(8)
   */
  readonly ex_mijamt: string;

  /**
   * 예상이윤금액
   *
   * **data type** varchar(8)
   */
  readonly ex_iamt: string;

  /**
   * 발주여부
   *
   * **data type** varchar(8)
   */
  readonly balflag: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate_max: string;

  /**
   * 발주금액
   *
   * **data type** varchar(8)
   */
  readonly balamt: string;

  /**
   * 매입금액
   *
   * **data type** varchar(8)
   */
  readonly mijamt: string;

  /**
   * 발주거래처
   *
   * **data type** varchar(8)
   */
  readonly balcltcd: string;

  /**
   * 발주거래처명
   *
   * **data type** varchar(8)
   */
  readonly balcltnm: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 발주월
   *
   * **data type** varchar(8)
   */
  readonly balmon: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly bilddate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly bildmon: string;

  /**
   * 거래처
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 준공도서
   *
   * **data type** varchar(8)
   */
  readonly compbook: string;

  /**
   * 계약금
   *
   * **data type** varchar(8)
   */
  readonly contamt: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 계약월
   *
   * **data type** varchar(8)
   */
  readonly contmon: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly elecamt: string;

  /**
   * 완료금
   *
   * **data type** varchar(8)
   */
  readonly endamt: string;

  /**
   * 완료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 완료월
   *
   * **data type** varchar(8)
   */
  readonly endmon: string;

  /**
   * 입력일자
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자사번
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ipsundate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ipsunmon: string;

  /**
   * 제어반
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 계약자
   *
   * **data type** varchar(8)
   */
  readonly ppernm: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 검사월
   *
   * **data type** varchar(8)
   */
  readonly kcmon: string;

  /**
   * 중도금
   *
   * **data type** varchar(8)
   */
  readonly midamt: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly misamt: string;

  /**
   * 승인도서
   *
   * **data type** varchar(8)
   */
  readonly okbook: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ordnum: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 댓수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 받은돈
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly scactnmflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly scamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly scoption: string;

  /**
   * 설치자
   *
   * **data type** varchar(8)
   */
  readonly scpernm: string;

  /**
   * 비고2
   *
   * **data type** varchar(8)
   */
  readonly scremark: string;

  /**
   * 사양
   *
   * **data type** varchar(8)
   */
  readonly sizecd: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 선급금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 시작월
   *
   * **data type** varchar(8)
   */
  readonly stmon: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly today: string;

  /**
   * 유효월
   *
   * **data type** varchar(8)
   */
  readonly tomon: string;

  /**
   * 모델인증서
   *
   * **data type** varchar(8)
   */
  readonly bildju: string;

  /**
   * 실측일
   *
   * **data type** varchar(8)
   */
  readonly surdate: string;

  /**
   * 실측자
   *
   * **data type** varchar(8)
   */
  readonly surpernm: string;

  /**
   * 여부
   *
   * **data type** varchar(8)
   */
  readonly surflag: string;

  /**
   * 설계일
   *
   * **data type** varchar(8)
   */
  readonly desdate: string;

  /**
   * 설계자
   *
   * **data type** varchar(8)
   */
  readonly despernm: string;

  /**
   * 여부
   *
   * **data type** varchar(8)
   */
  readonly desflag: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 층수
   *
   * **data type** varchar(8)
   */
  readonly number: string;

  /**
   * 인승
   *
   * **data type** varchar(8)
   */
  readonly nperson: string;

  /**
   * 계획(작성)일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly plannum: string;

  /**
   * 계획(작성)일자 2
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 상담자연락처
   *
   * **data type** varchar(8)
   */
  readonly advicehpnum: string;

  /**
   * 상담내용
   *
   * **data type** varchar(8)
   */
  readonly adviceremark: string;

  /**
   * 협의내용 및 조치내용
   *
   * **data type** varchar(8)
   */
  readonly resultmark: string;

  /**
   * 기타내용
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;


  constructor(data: any = {}) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.ex_mijamt = data.ex_mijamt || '';
    this.ex_iamt = data.ex_iamt || '';
    this.balflag = data.balflag || '';
    this.baldate_max = data.baldate_max || '';
    this.balamt = data.balamt || '';
    this.mijamt = data.mijamt || '';
    this.balcltcd = data.balcltcd || '';
    this.balcltnm = data.balcltnm || '';
    this.baldate = data.baldate || '';
    this.balmon = data.balmon || '';
    this.bilddate = data.bilddate || '';
    this.bildmon = data.bildmon || '';
    this.cltcd = data.cltcd || '';
    this.compbook = data.compbook || '';
    this.contamt = data.contamt || '';
    this.contdate = data.contdate || '';
    this.contmon = data.contmon || '';
    this.custcd = data.custcd || '';
    this.elecamt = data.elecamt || '';
    this.endamt = data.endamt || '';
    this.enddate = data.enddate || '';
    this.endmon = data.endmon || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.ipsundate = data.ipsundate || '';
    this.ipsunmon = data.ipsunmon || '';
    this.jeoban = data.jeoban || '';
    this.ppernm = data.ppernm || '';
    this.kcdate = data.kcdate || '';
    this.kcmon = data.kcmon || '';
    this.midamt = data.midamt || '';
    this.misamt = data.misamt || '';
    this.okbook = data.okbook || '';
    this.okflag = data.okflag || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.qty = data.qty || '';
    this.rcvamt = data.rcvamt || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.scactnm = data.scactnm || '';
    this.scactnmflag = data.scactnmflag || '';
    this.scamt = data.scamt || '';
    this.scoption = data.scoption || '';
    this.scpernm = data.scpernm || '';
    this.scremark = data.scremark || '';
    this.sizecd = data.sizecd || '';
    this.spjangcd = data.spjangcd || '';
    this.stamt = data.stamt || '';
    this.state = data.state || '';
    this.stdate = data.stdate || '';
    this.stmon = data.stmon || '';
    this.tamt = data.tamt || '';
    this.today = data.today || '';
    this.tomon = data.tomon || '';
    this.bildju = data.bildju || '';
    this.surdate = data.surdate || '';
    this.surpernm = data.surpernm || '';
    this.surflag = data.surflag || '';
    this.desdate = data.desdate || '';
    this.despernm = data.despernm || '';
    this.desflag = data.desflag || '';
    this.costdate = data?.costdate || '';
    this.costnum = data?.costnum || '';
    this.costpernm = data?.costpernm || '';
    this.constnm = data?.constnm || '';
    this.mamt = data?.mamt || '';
    this.nperson = data?.nperson || '';
    this.number = data?.number || '';
    this.plandate = data?.plandate || '';
    this.plannum = data?.plannum || '';
    this.compdate = data?.compdate || '';
    this.advicehpnum = data?.advicehpnum || '';
    this.adviceremark = data?.adviceremark || '';
    this.resultmark = data?.resultmark || '';
    this.bigo = data?.bigo || '';
  }
}
