import React from 'react';
import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { AimModel } from '../models';
import { MonthWork } from '../MonthWork';
import { Sum } from '../../../../../utils/array';
import { Button } from '../../../../../components/forms/Button';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

// 계획대비실적[계약]
const Aim: GridLayoutHeader<AimModel, MonthWork>[] = [
  {
    id: 'pernm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.pernm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 6,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 6,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.pernm}
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 17,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['pernm']);
      const sumPlanflag = Sum.groupValue(data, 'planflag', x, '0', ['pernm']);
      const sumPlanflag2 = Sum.groupValue(data, 'planflag', x, '1', ['pernm']);
      return (
        <FlexLayout
          justify="left"
          align="center"
        >
          <div style={{ color: '#8106aa' }}> {Format.number(sumPernm)} 건  &nbsp;</div>
          <div>결과: &nbsp;</div>
          <div style={{ color: 'var(--color-blue)' }}> 완료: {Format.number(sumPlanflag)} 건, &nbsp;</div>
          <div style={{ color: 'var(--color-red)' }}> 미달: {Format.number(sumPlanflag2)} 건</div>
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>결과: </div>
        <div style={{ color: 'var(--color-blue)' }}>{scope?.state.compcnt_tot}</div>
        <div style={{ color: 'var(--color-red)' }}>{scope?.state.notcnt_tot}</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: x.compflag === '1' ? 'var(--color-blue)' : '' }}
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 7,
    sum: (x) => (
        <FlexLayout
          justify="left"
          align="center"
        >
          <div>(</div>
          <div style={{ color: 'var(--color-blue)' }}> FM계약: {x.contgubun01} 건,</div>
        </FlexLayout>
    ),
  },
  {
    id: 'planflag',
    text: '결과',
    width: 7,
    sum: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>(</div>
        <div style={{ color: '#7F7A2C' }}> 다년계약: {x.contgubun02} 건,</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: x.planflag !== '1' ? 'var(--color-red)' : 'var(--color-blue)' }}
      >
        {x.planflag === '1' ? '계획' : '미계획'}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '구분',
    width: 7,
    sum: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>(</div>
        <div style={{ color: 'var(--color-red)' }}> 일반계약: {x.contgubun03} 건,</div>
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div> ( </div>
        <div style={{ color: 'var(--color-blue)' }}>{scope?.state.fmcnt_tot}</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{
          color: x.contgubun === '03' ? 'var(--color-red)'
            : x.contgubun === '01' ? 'var(--color-blue)'
              : x.contgubun === '02' ? '#7F7A2C' : '',
        }}
      >
        {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'qtysamt',
    text: '완료보수료',
    width: 7,
    sum: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        <div>계획: (</div>
      </FlexLayout>
    ),
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <div style={{ color: '#7F7A2C' }}>{scope?.state.longcnt_tot}</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          // eslint-disable-next-line no-nested-ternary
          color: x.qtysamt > x.qtyamt ? 'var(--color-blue)'
            : x.qtysamt === x.qtyamt ? ''
              : 'var(--color-red)',
        }}
      >
        {Format.number(x.qtysamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'becontgubun',
    text: '이전계약',
    width: 8,
    sum: (x, data) => {
      const sumPlancontgubun = Sum.groupValue(data, 'plancontgubun', x, '01', ['pernm']);
      return (
        <FlexLayout
          justify="left"
          align="center"
        >
          <div style={{ color: 'var(--color-blue)' }}> FM계약: {Format.number(sumPlancontgubun)} 건,</div>
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div style={{ color: 'var(--color-red)' }}>{scope?.state.pogcnt_tot}</div>
        <div> ) </div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {contgubuns.filter((y) => y.value === x.becontgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'qtyamt',
    text: '전보수료',
    width: 8,
    sum: (x, data) => {
      const sumPlancontgubun = Sum.groupValue(data, 'plancontgubun', x, '02', ['pernm']);
      return (
        <FlexLayout
          justify="left"
          align="center"
        >
          <div style={{ color: '#7F7A2C' }}> 다년계약: {Format.number(sumPlancontgubun)} 건,</div>
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.qtyamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'b_plan',
    text: '계획',
    width: 8,
    sum: (x, data) => {
      const sumPlancontgubun = Sum.groupValue(data, 'plancontgubun', x, '03', ['pernm']);
      return (
        <FlexLayout
          justify="left"
          align="center"
        >
          <div style={{ color: 'var(--color-red)' }}> 일반계약: {Format.number(sumPlancontgubun)} 건</div>
          <div>)</div>
        </FlexLayout>
      );
    },
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        <div>계획: (</div>
      </FlexLayout>
    ),
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        style={{ backgroundColor: '#FAFA80' }}
      >
        <Button
          style={{
            height: '20px',
            width: '40px',
            marginTop: 10,
            marginLeft: 5,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope?.textModal(true)}
        >
          조회
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'plancontgubun',
    text: '계획계약',
    width: 7,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div style={{ color: 'var(--color-blue)' }}>{scope?.state.fmplancnt_tot}</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
        style={{
          color: x.plancontgubun === '03' ? 'var(--color-red)'
            : x.plancontgubun === '01' ? 'var(--color-blue)'
              : x.plancontgubun === '02' ? '#7F7A2C' : '',
        }}
      >
        {contgubuns.filter((y) => y.value === x.plancontgubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'planqtysamt',
    text: '예상보수료',
    width: 7,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div></div>
        <div style={{ color: '#7F7A2C' }}>{scope?.state.longplancnt_tot}</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.planqtysamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'planqtysamttot',
    text: '예상증감액',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div style={{ color: 'var(--color-red)' }}>{scope?.state.pogplancnt_tot}</div>
        <div>)</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.planqtysamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '계획내용',
    width: 25,
    render: (x, scope) => (
      <React.Fragment>
        <FlexLayout style={{ backgroundColor: '#FAFA80' }}>
          <Button
            style={{
              height: '20px',
              width: '40px',
              marginTop: 10,
              marginLeft: 8,
            }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope?.historyPopupModal(true)}
          >
            조회
          </Button>
        </FlexLayout>
        <FlexLayout style={{ backgroundColor: '#FAFA80' }} weight={5}>
          <FlexLayout
            justify="left"
            align="center"
            style={{ marginRight: 10 }}
          >
            {x.remark}
          </FlexLayout>
        </FlexLayout>
      </React.Fragment>
    ),
  },
];

export default Aim;
