import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { HolidayStatementTemplate } from './HolidayStatement.template';
import { InfinityRetrieve } from '../../../../models/common';
import { HolidayStatementModel } from './HolidayStatement.model';
import { GridLayout } from '../../../../components/layout/GridLayout';
import {
  Date6Calculator,
  Today,
} from '../../../../utils/time';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface HolidayState {

  // 검색조건
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;
  mpclafi: string; // 직영구분
  styyyy: string;
  stmon: string;
  endyyyy: string;
  endmon: string;

  yearmon: string;
  nyearmon: string;

  pages: number;
  lastday: number;
  nlastday: number;

  holidayStatementList: Array<HolidayStatementModel>;
  data: HolidayStatementModel;
  focusedWalkDown?: HolidayStatementModel;
}

/**
 * 컨트롤러
 * @window w_pb204w_01
 * @category 휴가현황
 */
export class HolidayStatement extends PageComponent<PageProps, HolidayState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {

      perid: '',
      pernm: '',
      divicd: '',
      divinm: '',
      mpclafi: '%',

      pages: 0,
      yearmon: Today.yearMon(),
      nyearmon: new Date6Calculator(Today.yearMon()).add().toString(),

      styyyy: '',
      stmon: '',
      endyyyy: '',
      endmon: '',

      data: new HolidayStatementModel(),
      holidayStatementList: [],

      lastday: 30,
      nlastday: 30,
    };
  }

  @action
  // eslint-disable-next-line class-methods-use-this,no-empty-function
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        perid: this.state.perid,
        divicd: this.state.divicd,
        mpclafi: this.state.mpclafi,
        stmon: this.state.yearmon,
        endmon: this.state.nyearmon,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          pages: this.state.pages + items.length,
          holidayStatementList: [...this.state.holidayStatementList,
            // eslint-disable-next-line max-len
            ...items.map((x: any, index: number) => new HolidayStatementModel({ ...x, seq: this.state.pages + index + 1 }))],
        }, next);
      },
      async () => {
        await this.SS({ holidayStatementList: [] });
        this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      pages: 0,
      holidayStatementList: [],
    }, () => {
      this.infinity?.retrieveAll();
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.holidayStatementList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      perid: this.state.perid,
      divicd: this.state.divicd,
      mpclafi: this.state.mpclafi,
      stmon: this.state.yearmon,
      endmon: this.state.nyearmon,
    });
  }

  @action
  onRowFocusEvent(item:HolidayStatementModel) {
    this.setState({ focusedWalkDown: item });
  }

  render() {
    return (
      <HolidayStatementTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new HolidayStatementModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
