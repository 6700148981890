import { TableLayoutHeader } from '../../../../../constants';
import { ActionModel } from '../models';
import { Management } from '../Management';
import { TextBox } from '../../../../../components/forms/TextBox';
import { CheckBox } from '../../../../../components/forms/CheckBox';

// 조치결과
const Action: TableLayoutHeader<ActionModel, Management>[] = [
  {
    id: 'maresult',
    text: '코드',
    width: 50,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.maresult}
        onChange={(value) => rowUpdate({ maresult: value })}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'maresultnm',
    text: '조치결과명',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.maresultnm}
        onChange={(value) => rowUpdate({ maresultnm: value })}
      />
    ),
  },
  {
    id: 'useyn',
    text: '사용유무',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.useyn === '1'}
        onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
];

export default Action;
