import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ProjectCodeTemplate } from './ProjectCode.template';
import { ProjectCodeModel } from './ProjectCode.model';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components';
import { PageComponent } from '../../../../utils';
import { ConfirmWarning } from '../../../../utils/confirm';

interface ProjectCodeState {
  // search
  year?: string;
  gubun?: Array<any>;
  searchGubun?: string;
  searchQuery: string;

  // data
  focusIndex: number;
  textOftenList: Array<ProjectCodeModel>;
  focused?: ProjectCodeModel;
  data: ProjectCodeModel;
  lastNewData: ProjectCodeModel;
  newChk: string;
}

/**
 * 컨트롤러
 * @window w_tb_da003_01
 * @category 프로젝트코드등록
 */
export class ProjectCode extends PageComponent<PageProps, ProjectCodeState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const params = this.props.publicStore?.getPageParams();

    this.state = props.state || {
      // search
      year: Today.year(),
      searchGubun: '%',
      searchQuery: params?.searchQuery || '',

      // data
      focusIndex: 0,
      data: new ProjectCodeModel(),
      textOftenList: [],
    };
  }

  @action
  async componentDidRecover() {
    const params = this.props.publicStore?.getPageParams();
    if (params) {
      await this.SS({
        searchQuery: params?.searchQuery || this.state.searchQuery,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 구분
    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubun: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        gubun: this.state.searchGubun,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          textOftenList: [
            ...this.state.textOftenList,
            ...items.map((x: any) => new ProjectCodeModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          textOftenList: [],
          data: new ProjectCodeModel(),
        });

        await this.infinity?.retrieveAll();
        if (this.state.textOftenList && this.state.textOftenList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      textOftenList: [],
      data: new ProjectCodeModel(),
      newChk: '0',
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltcd', 'perid'],
        [lastSelected?.cltcd, lastSelected?.perid], type, true) || 0;
      if (this.state.textOftenList && this.state.textOftenList.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.textOftenList[this.state.focusIndex], this.state.focusIndex);
      }
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      year: this.state.year,
    });
    data && this.setState({
      data: new ProjectCodeModel(data, true),
      lastNewData: new ProjectCodeModel(data, true),
      textOftenList: [
        new ProjectCodeModel(data, true),
        ...this.state.textOftenList,
      ],
      newChk: '1',
    });
    this.grid.current?.setFocus(0);
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const item = this.state.data;
    if (!this.state.data.gubun) {
      item.gubun = '11';
    }

    if (!this.state.data?.actcd) {
      ConfirmWarning.show('확인', '현장명을 선택해 주세요.');
      return;
    }

    await api.save(item, false) && this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `프로젝트번호: ${this.state.focused?.projno} 프로젝트명: ${this.state.focused?.projnm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onRowFocusEvent(item: ProjectCodeModel, index: number) {
    const { actionStore: api } = this.props;
    if (item?.new === '1') {
      this.setState({
        data: this.state.lastNewData,
      });
      return;
    }

    this.setState({
      focused: item,
    }, async () => {
      const data = await api.fxExec(
        'dw_1_RowFocuschanged',
        {
          projno: this.state.focused?.projno,
        },
      );
      data && this.setState({
        data: new ProjectCodeModel(data, false),
        focusIndex: index,
      });
    });
  }

  render() {
    return (
      <ProjectCodeTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new ProjectCodeModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
