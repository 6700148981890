import { computed } from 'mobx';

/**
 * 신체병역 모델
 * @window w_p2110
 * @category 인사등록
 */
export class BodyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 신장
   *
   * **data type** float
   */
  readonly stan: string;

  /**
   * 체중
   *
   * **data type** float
   */
  readonly weig: string;

  /**
   * 시력(좌)
   *
   * **data type** varchar(3)
   */
  readonly lsigh: string;

  /**
   * 시력(우)
   *
   * **data type** varchar(3)
   */
  readonly rsigh: string;

  /**
   * 혈액형
   *
   * **data type** varchar(2)
   */
  readonly blood: string;

  /**
   * rh
   *
   * **data type** varchar(1)
   */
  readonly rh: string;

  /**
   * 색맹여부
   *
   * **data type** varchar(1)
   */
  readonly colo: string;

  /**
   * 병력
   *
   * **data type** varchar(30)
   */
  readonly sick: string;

  /**
   * 병역
   *
   * **data type** varchar(1)
   */
  readonly army: string;

  /**
   * 미필사유
   *
   * **data type** varchar(30)
   */
  readonly nmili: string;

  /**
   * 복무시작
   *
   * **data type** varchar(8)
   */
  readonly cterms: string;

  /**
   * 복무만료
   *
   * **data type** varchar(8)
   */
  readonly cterme: string;

  /**
   * 군별
   *
   * **data type** varchar(1)
   */
  readonly carm: string;

  /**
   * 병과
   *
   * **data type** varchar(30)
   */
  readonly arm: string;

  /**
   * 계급
   *
   * **data type** varchar(2)
   */
  readonly grad: string;

  /**
   * 제대구분
   *
   * **data type** varchar(1)
   */
  readonly dcha: string;

  /**
   * 취미
   *
   * **data type** varchar(30)
   */
  readonly hobby: string;

  /**
   * 특기
   *
   * **data type** varchar(30)
   */
  readonly ability: string;

  /**
   * 종교
   *
   * **data type** varchar(30)
   */
  readonly religion: string;

  /**
   * 주량
   *
   * **data type** varchar(10)
   */
  readonly drink: string;

  /**
   * 담배(일)
   *
   * **data type** varchar(10)
   */
  readonly tabacco: string;

  /**
   * 주거형태
   *
   * **data type** char(1)
   */
  readonly houseflag: string;

  /**
   * 주거명의
   *
   * **data type** varchar(30)
   */
  readonly housename: string;

  /**
   * 주거구분
   *
   * **data type** char(1)
   */
  readonly houseshape: string;

  /**
   * 주거명수
   *
   * **data type** varchar(30)
   */
  readonly housesize: string;

  /**
   * 호주성명
   *
   * **data type** varchar(30)
   */
  readonly hojuname: string;

  /**
   * 호주관계
   *
   * **data type** varchar(30)
   */
  readonly hojurelation: string;

  /**
   * 호주직업
   *
   * **data type** varchar(30)
   */
  readonly hojujob: string;

  /**
   * 국가유공자
   *
   * **data type** char(10)
   */
  readonly nationflag: string;

  /**
   * 장애부위
   *
   * **data type** varchar(30)
   */
  readonly injury: string;

  /**
   * 춘추복사이즈
   *
   * **data type** decimal(18 0)
   */
  readonly springsize: string;

  /**
   * 동복사이즈
   *
   * **data type** decimal(18 0)
   */
  readonly wintersize: string;

  /**
   * 신발사이즈
   *
   * **data type** decimal(18 0)
   */
  readonly footsize: string;

  /**
   * 하복사이즈
   *
   * **data type** decimal(18 0)
   */
  readonly summersize: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.stan = data.stan || '';
    this.weig = data.weig || '';
    this.lsigh = data.lsigh || '';
    this.rsigh = data.rsigh || '';
    this.blood = data.blood || '';
    this.rh = data.rh || '';
    this.colo = data.colo || '';
    this.sick = data.sick || '';
    this.army = data.army || '';
    this.nmili = data.nmili || '';
    this.cterms = data.cterms || '';
    this.cterme = data.cterme || '';
    this.carm = data.carm || '';
    this.arm = data.arm || '';
    this.grad = data.grad || '';
    this.dcha = data.dcha || '';
    this.hobby = data.hobby || '';
    this.ability = data.ability || '';
    this.religion = data.religion || '';
    this.drink = data.drink || '';
    this.tabacco = data.tabacco || '';
    this.houseflag = data.houseflag || '';
    this.housename = data.housename || '';
    this.houseshape = data.houseshape || '';
    this.housesize = data.housesize || '';
    this.hojuname = data.hojuname || '';
    this.hojurelation = data.hojurelation || '';
    this.hojujob = data.hojujob || '';
    this.nationflag = data.nationflag || '';
    this.injury = data.injury || '';
    this.springsize = data.springsize || '';
    this.wintersize = data.wintersize || '';
    this.footsize = data.footsize || '';
    this.summersize = data.summersize || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
