import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { LayoutProps } from '../../../constants';

export const DefaultLayout: React.FC<LayoutProps> = ({
  refs,
  role,
  className,
  style,
  children,
  isScroll,
  weight,
  size,
  minHeight,
  tabindex,
  onClick,
  onTouchStart,
  onDoubleClick,
  onMouseMove,
  onMouseLeave,
  onFocus,
  onUnFocus,
  onKeyDown,
}) => {
  const cWeight = !weight && !size ? 1 : weight;
  const oMinHeight = minHeight ? { minHeight } : {};

  if (isScroll) {
    return (
      <Scrollbars
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className="thumb-horizontal" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={
          // eslint-disable-next-line no-nested-ternary
          cWeight
            ? { flex: cWeight, ...oMinHeight }
            : size
              ? { flex: `${size}px 0 0`, ...oMinHeight }
              : {}
        }
      >
        <div
          ref={refs}
          role={role}
          className={className}
          onClick={() => onClick && onClick()}
          onFocus={(e) => onFocus && onFocus(e)}
          onBlur={(e) => onUnFocus && onUnFocus(e)}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          style={style}
          tabIndex={tabindex}
        >
          {children}
        </div>
      </Scrollbars>
    );
  }
  return (
    <div
      ref={refs}
      role={role}
      className={className}
      tabIndex={tabindex}
      onClick={() => onClick && onClick()}
      onTouchStart={() => onTouchStart && onTouchStart()}
      onDoubleClick={() => onDoubleClick && onDoubleClick()}
      onMouseMove={(e) => onMouseMove && onMouseMove(e)}
      onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
      onFocus={(e) => onFocus && onFocus(e)}
      onBlur={(e) => onUnFocus && onUnFocus(e)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      style={
        // eslint-disable-next-line no-nested-ternary
        cWeight
          ? { flex: cWeight, ...oMinHeight, ...style }
          : size
            ? { flex: `${size}px 0 0`, ...oMinHeight, ...style }
            : style
      }
    >
      {children}
    </div>
  );
};
