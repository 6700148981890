import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb301_03
 * @category 퇴직금적립내역
 */
export class SeverancePayModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 적용월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 적용일자
   *
   * **data type** varchar(13)
   */
  retiredate: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  gubun: string;

  /**
   * 적립금액
   *
   * **data type** number
   */
  retireamt: string;

  /**
   * 비고
   *
   * **data type** varchar(32764)
   */
  remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.mon = data.mon || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.retiredate = data.retiredate || '';
    this.spjangnm = data.spjangnm || '';
    this.gubun = data.gubun || '';
    this.retireamt = data.retireamt || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
