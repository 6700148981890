import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { OptionBox, SubHeader } from '../../components';

interface ModalGosiAskProps {
  modalStore: ModalStore;
}

@observer
export class ModalGosiAsk extends React.Component<ModalGosiAskProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={580}
        height={145}
        isVisible={modalStore.isVisibleGosiAsk}
        onClose={() => modalStore.closeGosiAsk()}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <LayoutTitle>작업선택</LayoutTitle>
          <FlexLayout>
            <OptionBox
              value={modalStore.modalGosiAskAnswer}
              data={[
                { value: 0, remark: '정보센터 사이트' },
                { value: 1, remark: '점검연동(동기화)' },
                { value: 2, remark: '검사연동(동기화)' },
              ]}
              onChange={(item) => {
                modalStore.modalGosiAskAnswer = Number(item.value);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => modalStore.closeGosiAsk(true)}>확인</Button>
              <Button onClick={() => modalStore.closeGosiAsk()}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
