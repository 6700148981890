import * as React from 'react';
import { action } from 'mobx';
import { HolidayWorkTemplate } from './HolidayWork.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { HolidayWorkModel } from './HolidayWork.model';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import { DateStabilizer } from '../../../../utils/time';

export enum HolidayWorkItemChangeTypes {
  WORKNUM,
  WEEKDATE,
  STDATE,
  ENDDATE,
  STTIME,
  ENDTIME
}

export enum HolidayWorkItemChangeTypeNames {
  worknum,
  weekdate,
  stdate,
  enddate,
  sttime,
  endtime,
}

interface HolidayWorkState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  perid: string;
  pernm: string;
  kukcd: string;

  // 데이터 객체
  focusedHoliday?: HolidayWorkModel;
  data: HolidayWorkModel;
  lastNewData: HolidayWorkModel;
  holidayList: Array<HolidayWorkModel>;

  focusIndex: number;
  chk: string;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  worknum_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb214
 * @category 휴무근무신청서
 */
export class HolidayWork extends PageComponent<PageProps, HolidayWorkState>
  implements PageToolEvents {
  updatedRows?: Array<HolidayWorkModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();
    const year = today.getFullYear(); // 년도

    const { user } = this.props.publicStore;

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}0101`, // 기간(시작)
      enddate: `${year}1231`, // 기간(끝)
      perid: user.perid,
      pernm: user.pernm,
      data: new HolidayWorkModel(),
      holidayList: [],
      isReported: false,
      focusIndex: 0,
      chk: '0',
      kukcd: '0',

      worknum_tot: '',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          weekdate: this.state.data.weekdate,
          weeknum: this.state.data.weeknum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
          perid: this.state.perid,
        }, false,
      );

      data && this.setState({
        data: new HolidayWorkModel({
          ...this.state.data,
          ...data,
        }, data.new),
      });
    }
  }


  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    this.setState({ kukcd: user.kukcd });

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        pernm: this.state.pernm,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          holidayList: [...this.state.holidayList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ holidayList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      holidayList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['weekdate', 'stdate'],
        [lastSelected?.weekdate, lastSelected?.stdate], type, true) || 0;
      const data = this.infinity?.box;
      // this.state.holidayList.length > 0
      // && this.onRowFocusEvent(this.state.holidayList[this.state.focusIndex], this.state.focusIndex);
      // if (this.state.chk === '1') {
      //   this.grid.current?.setFocus(this.state.focusIndex);
      //   this.setState({ chk: '0' });
      // } else {
      //   this.grid.current?.setFocus(0);
      // }
      this.grid.current?.setFocus(index);
      this.setState({
        worknum_tot: data?.total,
      });
    });
  }

  @action
  onRowFocusEvent(item: HolidayWorkModel, index: number) {
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedHoliday: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            weekdate: this.state.focusedHoliday?.weekdate,
            weeknum: this.state.focusedHoliday?.weeknum,
          });

        await data && this.setState({ data: new HolidayWorkModel(data) });

        // 결재상신 여부확인
        if (this.state.focusedHoliday?.appgubun === null
          || this.state.focusedHoliday?.appgubun === ''
          || this.state.focusedHoliday?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    const params = {
      itemname: HolidayWorkItemChangeTypeNames[type],
      date: '',
      new: this.state.data.new,
    };

    if (type === HolidayWorkItemChangeTypeNames.worknum) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.worknum,
        },
      );
    }

    if (type === HolidayWorkItemChangeTypeNames.weekdate
    || type === HolidayWorkItemChangeTypeNames.stdate
    || type === HolidayWorkItemChangeTypeNames.enddate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          stdate: this.state.data.stdate,
          enddate: this.state.data.enddate,
        },
      );
    }

    if (type === HolidayWorkItemChangeTypeNames.sttime
      || type === HolidayWorkItemChangeTypeNames.endtime) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          stdate: this.state.data.stdate,
          sttime: this.state.data.sttime,
          endtime: this.state.data.endtime,
        },
      );
    }

    data && this.setState({
      data: new HolidayWorkModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedHoliday!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.HOLIWORK, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedHoliday!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.HOLIWORK);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.HOLIWORK.toString(),
      ...this.state.data,
      appflag,
    });
    data && await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.weekdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new HolidayWorkModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        holidayList: [
          newModel,
          ...this.state.holidayList,
        ],
        focusedHoliday: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          weekdate: this.state.data.weekdate,
          weeknum: this.state.data.weeknum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
          perid: this.state.perid,
        }, false);
    } else {
      const text = `작성일자: ${this.state.focusedHoliday?.weekdate} 작성번호: ${this.state.focusedHoliday?.weeknum}`;
      await api.delete(text, {
        weekdate: this.state.data.weekdate,
        weeknum: this.state.data.weeknum,
        appnum: this.state.data.appnum,
        appgubun: this.state.data.appgubun,
        title: this.state.data.title,
        perid: this.state.perid,
      }) && await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.custcd, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      weekdate: this.state.data.weekdate,
      weeknum: this.state.data.weeknum,
      appgubun: this.state.data.appgubun,
      net: '',
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  render() {
    return (
      <HolidayWorkTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new HolidayWorkModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
