import { TLHF } from '../../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
} from '../../../../../components';
import { ManageStatus } from '../ManageStatus';
import ManageStatusModel from '../models/ManageStatusModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { RowBooks, RowResults } from './ManageStatusTableHeaderContract';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 상단 탭 > 테이블 - 검사예정일자별
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusTableHeaderPreinspect: TLHF<ManageStatusModel, ManageStatus> = (scope) => ([
  {
    id: 'actcd',
    text: '코드',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.actcd}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout>
        전체
      </FlexLayout>
    ),
  },
  {
    id: 'balcltnm',
    text: '발주예정처',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.balcltnm}
      </FlexLayout>
    ),
    trail: () => {
      let contAll = 0;
      scope.state.dataHeadTabTablePreInspect?.forEach((x) => {
        if (x.contdate !== '00000000') {
          contAll += 1;
        }
      });
      return (
        <FlexLayout>
          계약: {contAll}
        </FlexLayout>
      );
    },
  },
  {
    id: 'scactnm',
    text: '설치현장명',
    width: 450,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scactnm}
      </FlexLayout>
    ),
    trail: () => {
      let endAll = 0;
      scope.state.dataHeadTabTablePreInspect?.forEach((x) => {
        if (x.enddate !== '00000000' && x.enddate !== '') {
          endAll += 1;
        }
      });

      let kcAll = 0;
      scope.state.dataHeadTabTablePreInspect?.forEach((x) => {
        if (x.kcdate !== '00000000' && x.kcdate !== '') {
          kcAll += 1;
        }
      });

      let balAll = 0;
      scope.state.dataHeadTabTablePreInspect?.forEach((x) => {
        if (x.baldate !== '00000000' && x.baldate !== '') {
          balAll += 1;
        }
      });

      let balMaxAll = 0;
      scope.state.dataHeadTabTablePreInspect?.forEach((x) => {
        if (x.baldate_max !== '00000000' && x.baldate_max !== '') {
          balMaxAll += 1;
        }
      });
      return (
        <FlexLayout>
          <FlexLayout>
            납기: {endAll}
          </FlexLayout>

          <FlexLayout>
            검사: {kcAll}
          </FlexLayout>

          <FlexLayout>
            자재출하: {balAll}
          </FlexLayout>

          <FlexLayout>
            발주: {balMaxAll}
          </FlexLayout>
        </FlexLayout>
      );
    },
  },
  {
    id: 'qty',
    text: '대수',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.qty}
      </FlexLayout>
    ),
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'stdate',
    text: '검사예정일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.stdate || Date8.make()}
        onChange={(v) => rowUpdate({ stdate: v })}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
        style={{ color: 'var(--color-green)' }}
      >
        <span>설치 {Format.number(scope.state?.preInspectState1_tot || 0)}건</span>
      </FlexLayout>
    ),
  },
  {
    id: 'kcdate',
    text: '검사일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.kcdate || Date8.make()}
        onChange={(v) => rowUpdate({ kcdate: v })}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
        style={{ color: 'var(--color-blue)' }}
      >
        <span>보수 {Format.number(scope.state?.preInspectState2_tot || 0)}건</span>
      </FlexLayout>
    ),
  },
  {
    id: 'okflag',
    text: '합격필증',
    width: 100,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.okflag}
        data={RowResults.map((y) => new ComboBoxModel(y.key, y.value))}
        onSelect={(option) => rowUpdate({ okflag: option.value })}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <span>합격여부 :</span>
      </FlexLayout>
    ),
  },
  {
    id: 'contdate',
    text: '계약일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.contdate || Date8.make()}
        onChange={(v) => rowUpdate({ contdate: v })}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        <span>합격 {Format.number(scope.state?.preInspectOkflag2_tot || 0)}건</span>
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '납기일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.enddate || Date8.make()}
        onChange={(v) => rowUpdate({ enddate: v })}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <span>보완 {Format.number(scope.state?.preInspectOkflag1_tot || 0)}건</span>
      </FlexLayout>
    ),
  },
  {
    id: 'jeoban',
    text: '기종',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.jeoban === '01' ? 'MR' : 'MRL'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <span>불합격 {Format.number(scope.state?.preInspectOkflag0_tot || 0)}건</span>
      </FlexLayout>
    ),
  },
  {
    id: 'contamt',
    text: '계약금',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.contamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectContamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'stamt',
    text: '( 선급금',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.stamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectStamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'midamt',
    text: '중도금',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.midamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectMidamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'endamt',
    text: '잔금 )',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.endamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectEndamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'ex_mijamt',
    text: '예상지출금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.ex_mijamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectEx_mijamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'ex_iamt',
    text: '예상이윤금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={Number(x.ex_iamt) > 0 ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {Format.number(x.ex_iamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectEx_iamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'balflag',
    text: '발주여부',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={
          x.balflag === '0' ? { color: 'var(--color-red)' }
            : x.balflag === '1' ? { color: 'var(--color-green)' } : { color: 'var(--color-blue)' }
        }
      >
        {
          x.balflag === '0' ? '미발주'
            : x.balflag === '1' ? '발주중' : '발주완료'
        }
      </FlexLayout>
    ),
  },
  {
    id: 'baldate_max',
    text: '발주일자',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.baldate_max)}
      </FlexLayout>
    ),
  },
  {
    id: 'balamt',
    text: '발주금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.balamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.contractBalamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '매입금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectMijamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectRcvamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '잔액',
    width: 150,
    render: (x) => {
      const iamt = parseInt(x.contamt || '0', 10) - parseInt(x.rcvamt || '0', 10);
      return (
        <FlexLayout
          align="center"
          justify="end"
        >
          {Format.number(iamt)}
        </FlexLayout>
      );
    },
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state?.preInspectResuamt_tot || 0)}
      </FlexLayout>
    ),
  },
  {
    id: 'sizecd',
    text: '사양',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.sizecd}
      </FlexLayout>
    ),
  },
  {
    id: 'scoption',
    text: '옵션',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.scoption}
      </FlexLayout>
    ),
  },
  {
    id: 'scpernm',
    text: '설치작업자',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.scpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'baldate',
    text: '자재현장도착일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.baldate || Date8.make()}
        onChange={(v) => rowUpdate({ baldate: v })}
      />
    ),
  },
  {
    id: 'ipsundate',
    text: '본전원입선일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.ipsundate || Date8.make()}
        onChange={(v) => rowUpdate({ ipsundate: v })}
      />
    ),
  },
  {
    id: 'bilddate',
    text: '건축허가일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.bilddate || Date8.make()}
        onChange={(v) => rowUpdate({ bilddate: v })}
      />
    ),
  },
  {
    id: 'surdate',
    text: '실측일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.surdate || Date8.make()}
        onChange={(v) => rowUpdate({ surdate: v })}
      />
    ),
  },
  {
    id: 'surpernm',
    text: '실측자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.surpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'surflag',
    text: '여부',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.surflag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'desdate',
    text: '설계일',
    width: 150,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.desdate || Date8.make()}
        onChange={(v) => rowUpdate({ desdate: v })}
      />
    ),
  },
  {
    id: 'despernm',
    text: '설계자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.despernm}
      </FlexLayout>
    ),
  },
  {
    id: 'desflag',
    text: '여부',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.desflag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'okbook',
    text: '승인도서',
    width: 100,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.okbook}
        data={RowBooks.map((y) => new ComboBoxModel(y.key, y.value))}
        onSelect={(option) => rowUpdate({ okbook: option.value })}
      />
    ),
  },
  {
    id: 'compbook',
    text: '준공도서',
    width: 100,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.compbook}
        data={RowBooks.map((y) => new ComboBoxModel(y.key, y.value))}
        onSelect={(option) => rowUpdate({ compbook: option.value })}
      />
    ),
  },
  {
    id: 'bildju',
    text: '모델인증서',
    width: 120,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value={x.bildju}
        data={scope.dataBildju.map((y) => new ComboBoxModel(y.code, y.cnam))}
        onSelect={(option) => rowUpdate({ bildju: option.value })}
      />
    ),
  },
  {
    id: 'scremark',
    text: '현재진행사항',
    width: 550,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scremark}
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '설치',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.state === '2' ? '보수' : '설치'}
      </FlexLayout>
    ),
  },
]);
