import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da450_default
 * @category 설치품목등록
 */
export class GovermentCommonModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 년도
   *
   * **data type** varchar(4)
   */
  year: string;

  /**
   * 관급,사급구분
   *
   * **data type** varchar(1)
   */
  contflag: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 인승
   *
   * **data type** varchar(4)
   */
  nperson: string;

  /**
   * 제어반
   *
   * **data type** varchar(3)
   */
  jeoban: string;

  /**
   * 두께
   *
   * **data type** varchar(3)
   */
  thickness: string;

  /**
   * 품명코드
   *
   * **data type** varchar(50)
   */
  pcode: string;

  /**
   * 품명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(255)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** varchar(50)
   */
  punit: string;

  /**
   * 단가
   *
   * **data type** float
   */
  uamt: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.year = data.year || '';
    this.contflag = data.contflag || '';
    this.seq = data.seq || '';
    this.nperson = data.nperson || '';
    this.jeoban = data.jeoban || '';
    this.thickness = data.thickness || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.uamt = data.uamt || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
