import * as React from 'react';
import { action } from 'mobx';
import { PageProps } from '../../../../constants';
import { CheckDepartmentTemplate } from './CheckDepartment.template';
import { CheckDepartmentModel } from './CheckDepartment.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface CheckDepartmentState {
  searchQuery: string;
  data: Array<CheckDepartmentModel>;
  focused?: CheckDepartmentModel;
}

/**
 * 컨트롤러
 * @window w_tb_e027
 * @category 검사담당부서등록
 */
export class CheckDepartment extends PageComponent<PageProps, CheckDepartmentState> {
  updatedRows?: Array<CheckDepartmentModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery || '',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new CheckDepartmentModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieve();
        await this.table.current?.setFocus(0);
        await this.table.current?.update(true);
        this.state.data.length > 0 && this.table.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    });
    if (!autoLoad) return;
    await this.infinity?.retrieve();
    await this.table.current?.setFocus(0);
    await this.table.current?.update(true);
    this.state.data.length > 0 && this.table.current?.setFocus(0);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        ...this.state.data,
        new CheckDepartmentModel(data, true),
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.data.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.gareacd} - ${this.state.focused?.gareanm}`;
    await api.delete(text, this.state.focused) && await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel();
  }

  @action
  onRowFocusEvent(item: CheckDepartmentModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<CheckDepartmentModel>, updatedRows: Array<CheckDepartmentModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <CheckDepartmentTemplate
        scope={this}
        update={(change) => {
          this.setState({
            ...this.state,
            ...change,
          });
        }}
      />
    );
  }
}
