import * as React from 'react';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TabView,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import {
  RepairCurrent,
  RepairCurrentTabDataStateNames,
  RepairCurrentTabId,
} from './RepairCurrent';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_tb_e601w
 * @category 보수현장현황
 */
export const RepairCurrentTemplate: React.FC<TemplateProps<RepairCurrent>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout weight={3}>
          <span>현장명</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            weight={1.4}
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.6}
            value={scope.state.wkactnm}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>계약분류</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ gubun: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.contgubun}
            data={scope.state.contgubuns?.map((y) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => setData({ contgubun: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      {(scope.tabIndex === RepairCurrentTabId.Act)
        && <SearchLayout
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
        >
          <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              weight={1.4}
              textAlign="center"
              value={scope.state.divicd}
              onChange={(value) => setData({ divicd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.6}
              value={scope.state.divinm}
              readonly={true}
              onChange={(value) => setData({ divinm: value })}
            />
          </FlexLayout>
          <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              weight={1.4}
              value={scope.state.pernm}
              textAlign="center"
              onChange={(value) => setData({ pernm: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({ perid: item.cd, pernm: item.cdnm });
                  scope.onRetrieveEvent();
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              weight={1.6}
              value={scope.state.perid}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>계약자</span>
            <TextBox
              weight={1.4}
              value={scope.state.contpernm}
              textAlign="center"
              onChange={(value) => setData({ contpernm: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({ contperid: item.cd, contpernm: item.cdnm });
                  scope.onRetrieveEvent();
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              weight={1.6}
              value={scope.state.contperid}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout>
            <span style={{ color: 'var(--color-red)' }}>건물용도</span>
            <TextBox
              weight={1.4}
              value={scope.state.bildyd === '%' ? '' : scope.state.bildyd}
              textAlign="center"
              onChange={(value) => setData({ buildnm: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_E029',
                {},
                true,
                (item) => {
                  setData({ bildyd: item.cd, buildnm: item.cdnm });
                  scope.onRetrieveEvent();
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              weight={1.6}
              value={scope.state.buildnm}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>지역</span>
              <TextBox
                  weight={1.4}
                  value={scope.state.areacd}
                  textAlign="center"
                  onChange={(value) => setData({ areanm: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E028',
                    {},
                    true,
                    (item) => {
                      setData({ areacd: item.cd, areanm: item.cdnm });
                      scope.onRetrieveEvent();
                    },
                  )}
                  isDisabledTrackingStateChange={true}
              />
              <TextBox
                  weight={1.6}
                  value={scope.state.areacd}
                  textAlign="center"
                  isDisabledTrackingStateChange={true}
                  readonly={true}
              />
          </FlexLayout>
        </SearchLayout>}
      {(scope.tabIndex === RepairCurrentTabId.Unit)
          && <FlexLayout
          isVertical={true}
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
          <SearchLayout
              size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
              <FlexLayout size={324}>
                  <span style={{ marginLeft: 12 }}>승강기번호</span>
                  <FormatTextBox
                      format="####-###"
                      value={scope.state.elno}
                      onChange={(value) => setData({ elno: value }, () => scope.onRetrieveEvent())}
                      textAlign="center"
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 34 }}>구분</span>
                  <ComboBox
                      value={scope.state.eligcd}
                      data={scope.state.eligcds?.map((y) => new ComboBoxModel(y.eligcd, y.elignm))}
                      onSelect={(option) => setData({ eligcd: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 21 }}>용도</span>
                  <ComboBox
                      value={scope.state.eliycd}
                      data={scope.state.eliycds?.map((y) => new ComboBoxModel(y.eliycd, y.eliynm))}
                      onSelect={(option) => setData({ eliycd: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 39 }}>E/V종류</span>
                  <ComboBox
                      value={scope.state.elijcd}
                      data={scope.state.elijcds?.map((y) => new ComboBoxModel(y.elijcd, y.elijnm))}
                      onSelect={(option) => setData({ elijcd: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span>점검대상</span>
                  <ComboBox
                      value={scope.state.kcflag}
                      data={[
                        { value: '%', remark: '전체' },
                        { value: '1', remark: '점검대상' },
                        { value: '0', remark: '미대상' },
                      ].map((x) => new ComboBoxModel(x.value, x.remark))}
                      onSelect={(option) => setData({ kcflag: option.value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
          </SearchLayout>
          <SearchLayout
              size={Global.LAYOUT_SEARCH_HEIGHT_1}
              isSub={true}
          >
              <FlexLayout>
                  <span style={{ marginRight: 47 }}>형식</span>
                  <ComboBox
                      value={scope.state.elifcd}
                      data={scope.state.elifcds?.map((y) => new ComboBoxModel(y.elifcd, y.elifnm))}
                      onSelect={(option) => setData({ elifcd: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 34 }}>정원</span>
                  <FormatTextBox
                      format="## 이상"
                      value={scope.state.nperson}
                      textAlign="center"
                      onChange={(value) => setData({ nperson: value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 22 }}>무게</span>
                  <FormatTextBox
                      format="#### 이상"
                      value={scope.state.height}
                      textAlign="center"
                      onChange={(value) => setData({ height: value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 35 }}>건물용도</span>
                  <ComboBox
                      value={scope.state.bildyd}
                      data={scope.state.bildyds?.map((y) => new ComboBoxModel(y.buildcd, y.buildnm))}
                      onSelect={(option) => setData({ bildyd: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span>제작년월</span>
                  <FormatTextBox
                      format="#### 년 이전 설치"
                      value={scope.state.yyyymm}
                      textAlign="right"
                      onChange={(value) => setData({ yyyymm: value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
          </SearchLayout>
          <SearchLayout
              size={Global.LAYOUT_SEARCH_HEIGHT_1}
              isSub={true}
          >
              <FlexLayout>
                  <span style={{ marginRight: 47 }}>모델</span>
                  <ComboBox
                      value={scope.state.mdcd}
                      data={scope.state.mdcds?.map((y) => new ComboBoxModel(y.mdcd, y.mdnm))}
                      onSelect={(option) => setData({ mdcd: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 7 }}>운행층수</span>
                  <FormatTextBox
                      format="## 이상"
                      value={scope.state.number}
                      textAlign="center"
                      onChange={(value) => setData({ number: value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 22 }}>속도</span>
                  <ComboBox
                      value={scope.state.speed}
                      data={scope.state.speeds?.map((y) => new ComboBoxModel(y.prospcd, y.prospnm))}
                      onSelect={(option) => setData({ speeds: option.value }, () => scope.onRetrieveEvent())}
                      isDisabledTrackingStateChange={true}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{ marginRight: 10 }}>PLC사용여부</span>
                  <ComboBox
                      value={scope.state.plc}
                      data={[
                        { value: '%', remark: '전체' },
                        { value: '1', remark: '사용' },
                        { value: '0', remark: '미사용' },
                      ].map((x) => new ComboBoxModel(x.value, x.remark))}
                      onSelect={(option) => setData({ plc: option.value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{
                    color: 'var(--color-red)',
                    marginRight: 36,
                  }}>부서</span>
                  <TextBox
                      weight={1.4}
                      textAlign="center"
                      value={scope.state.divicd}
                      onChange={(value) => setData({ divicd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JC002',
                        {},
                        true,
                        (item) => {
                          setData({
                            divicd: item.cd,
                            divinm: item.cdnm,
                          });
                          scope.onRetrieveEvent();
                        },
                      )}
                  />
                  <TextBox
                      weight={1.6}
                      value={scope.state.divinm}
                      readonly={true}
                      onChange={(value) => setData({ divinm: value })}
                  />
              </FlexLayout>
          </SearchLayout>
          <SearchLayout
              size={Global.LAYOUT_SEARCH_HEIGHT_1}
              isSub={true}
          >
              <FlexLayout weight={2}>
                  <span style={{ marginRight: 33 }}>제작사</span>
                  <TextBox
                      value={scope.state.jejak}
                      onChange={(value) => setData({ jejak: value }, () => scope.onRetrieveEvent())}
                      textAlign="left"
                  />
              </FlexLayout>
              <FlexLayout weight={2}>
                  <span>검색어</span>
                  <TextBox
                      value={scope.state.as_nm2}
                      onChange={(value) => setData({ as_nm2: value }, () => scope.onRetrieveEvent())}
                  />
              </FlexLayout>
              <FlexLayout>
                  <span style={{
                    color: 'var(--color-red)',
                    marginRight: 22,
                  }}>담당자</span>
                  <TextBox
                      weight={1.4}
                      value={scope.state.pernm}
                      textAlign="center"
                      onChange={(value) => setData({ pernm: value })}
                      onEnter={() => scope.onRetrieveEvent()}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          setData({ perid: item.cd, pernm: item.cdnm });
                          scope.onRetrieveEvent();
                        },
                      )}
                      isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                      weight={1.6}
                      value={scope.state.perid}
                      textAlign="center"
                      isDisabledTrackingStateChange={true}
                      readonly={true}
                  />
              </FlexLayout>
          </SearchLayout>
      </FlexLayout>
      }
      {(scope.tabIndex === RepairCurrentTabId.Department)
       && <SearchLayout
              size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
          <FlexLayout size={200}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              weight={1.4}
              textAlign="center"
              value={scope.state.divicd}
              onChange={(value) => setData({ divicd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.6}
              value={scope.state.divinm}
              onChange={(value) => setData({ divinm: value })}
            />
          </FlexLayout>
          <FlexLayout size={230}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              weight={1.4}
              value={scope.state.pernm}
              textAlign="center"
              onChange={(value) => setData({ pernm: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({ perid: item.cd, pernm: item.cdnm });
                  scope.onRetrieveEvent();
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              weight={1.6}
              value={scope.state.perid}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
          </SearchLayout>}

      <FlexLayout>
        <GridLayout
          // @ts-ignore
          ref={scope.grid}
          scope={scope}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[RepairCurrentTabDataStateNames[scope.tabIndex]] || []}
          onRowClick={(item) => scope.onRowfocusEvent(item)}
          onRowDoubleClick={() => scope.onClickOpenEnrollment()}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
