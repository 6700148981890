import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox, ComboBoxModel, DateTextBox, FlexLayout, SearchLayout, TabLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { ManageStatus } from './ManageStatus';
import { Date6 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { ManageStatusHeadTabTemplate } from './ManageStatusHead.tab';
import { ManageStatusDetailTabTemplate } from './ManageStatusDetail.tab';

// 승인/준공도셔
const Books = [
  { key: '%', value: '전체' },
  { key: '0', value: '미제작' },
  { key: '1', value: '제작완료' },
  { key: '2', value: '제출완료' },
  { key: '3', value: '완료' },
];

// 설치구분
const ScGubuns = [
  { key: '%', value: '전체' },
  { key: '1', value: '설치' },
  { key: '2', value: '보수' },
];

// 계약구분
const Gubuns = [
  { key: '%', value: '전체' },
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];

// 발주발생여부
const Balflags = [
  { key: '%', value: '전체' },
  { key: '0', value: '미발주' },
  { key: '1', value: '발주중' },
  { key: '2', value: '발주완료' },
];

/**
 * 화면
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusTemplate: React.FC<TemplateProps<ManageStatus>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>검색년도</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.stmon}
            onChange={(v) => scope.setState({ stmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ stmon: Date6.make(date) });
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ stmon: Date6.make(date) });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={170}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            format="####-##"
            textAlign="center"
            value={scope.state.endmon}
            onChange={(v) => scope.setState({ endmon: v })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() - 1);
                  scope.setState({ endmon: Date6.make(date) });
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                  date.setMonth(date.getMonth() + 1);
                  scope.setState({ endmon: Date6.make(date) });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처, 주소로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>승인도서</span>
          <ComboBox
            value={scope.state.okbook}
            textAlign="center"
            style={{ marginLeft: 12 }}
            data={Books.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ okbook: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span>준공도서</span>
          <ComboBox
            value={scope.state.compbook}
            textAlign="center"
            style={{ marginLeft: 12 }}
            data={Books.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ compbook: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>
      </SearchLayout>

      <SearchLayout
        isSub={true}
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={366}>
          <span style={{ paddingRight: 12 }}>설치구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            style={{ marginLeft: 12 }}
            data={ScGubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout size={353}>
          <span style={{ paddingLeft: 11 }}>계약구분</span>
          <ComboBox
            value={scope.state.contflag}
            textAlign="center"
            data={Gubuns.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ contflag: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout size={353}>
          <span style={{ paddingLeft: 11 }}>발주발생여부</span>
          <ComboBox
            value={scope.state.balflag}
            textAlign="center"
            data={Balflags.map((x) => new ComboBoxModel(x.key, x.value))}
            onSelect={(option) => scope.setState({ balflag: option.value },
              () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span
            style={{
              color: 'var(--color-red)',
              paddingLeft: 11,
            }}
          >
            발주거래처
          </span>

          <TextBox
            value={scope.state.balcltcd}
            textAlign="center"
            size={184}
            onChange={(v) => scope.setState({ balcltcd: v })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_XCLIENT',
              { uv_arg1: '2' },
              true,
              (item) => {
                setData({
                  balcltcd: item.cd,
                  balcltnm: item.cdnm,
                });
              },
            )}
          />

          <TextBox
            value={scope.state.balcltnm}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <TabLayout
          scope={scope}
          tabs={ManageStatusHeadTabTemplate}
          onChange={(_, index) => scope.onTabChange(index)}
        />

        <TabLayout
          scope={scope}
          tabs={ManageStatusDetailTabTemplate}
          onChange={(_, index) => scope.onTabDetailChange(index)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
