import { computed } from 'mobx';

/**
 * 모델
 * @window w_s007
 * @category 신용카드등록
 */
export class CreditModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 카드번호
   *
   * **data type** varchar(20)
   */
  cardnum: string;

  /**
   * 카드명
   *
   * **data type** varchar(30)
   */
  cardnm: string;

  /**
   * 카드사
   *
   * **data type** varchar(10)
   */
  cardco: string;

  /**
   * 카드구분
   *
   * **data type** varchar(10)
   */
  cardclafi: string;

  /**
   * 발급일자
   *
   * **data type** varchar(8)
   */
  isudate: string;

  /**
   * 만기일자
   *
   * **data type** varchar(8)
   */
  expedate: string;

  /**
   * 결제은행
   *
   * **data type** varchar(6)
   */
  stlbank: string;

  /**
   * 결제계좌
   *
   * **data type** varchar(20)
   */
  stlacc: string;

  /**
   * 결제일자
   *
   * **data type** varchar(8)
   */
  stldate: string;

  /**
   * 사용여부
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 정지일자
   *
   * **data type** varchar(8)
   */
  usestdate: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  cardperson: string;

  /**
   * 제출자와의 관계
   *
   * **data type** varchar(30)
   */
  connection: string;

  /**
   * 결제은행
   *
   * **data type** varchar(40)
   */
  stlbanknm: string;

  /**
   * 카드순번
   *
   * **data type** varchar(2)
   */
  cdseq: string;

  /**
   * 성명
   *
   * **data type** varchar(10)
   */
  cardperid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cardnum = data.cardnum || '';
    this.cardnm = data.cardnm || '';
    this.cardco = data.cardco || '';
    this.cardclafi = data.cardclafi || '';
    this.isudate = data.isudate || '';
    this.expedate = data.expedate || '';
    this.stlbank = data.stlbank || '';
    this.stlacc = data.stlacc || '';
    this.stldate = data.stldate || '';
    this.useyn = data.useyn || '';
    this.usestdate = data.usestdate || '';
    this.cardperson = data.cardperson || '';
    this.connection = data.connection || '';
    this.stlbanknm = data.stlbanknm || '';
    this.cdseq = data.cdseq || '';
    this.cardperid = data.cardperid || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
