import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CalendarLayout,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  FormatTextBox,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import { CalendarLayoutDataModel } from '../../../../models/component';
import { ScheduleModel } from './models/Schedule.model';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchBinding } from '../../../../models/common';
import {
  Date6Calculator,
  Today,
} from '../../../../utils/time';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Schedule,
  ScheduleItemChangeTypes,
} from './Schedule';

const states = [
  { value: '1', remark: '계획' },
  { value: '2', remark: '완료' },
];

const notices = [
  { value: '0', remark: '사용' },
  { value: '1', remark: '미사용' },
];

/**
 * 화면
 * @window w_tb_e501
 * @category 스케쥴관리
 */
export const ScheduleTemplate: React.FC<TemplateProps<Schedule>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>월</span>
        <DateTextBox
          value={scope.state.stmon}
          textAlign="center"
          format="####-##"
          onChange={(value) => { scope.setState({ stmon: value }, () => scope.onRetrieveEvent()); }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      {scope.state.kukcd === '01'
        ? <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        : ''
      }
    </SearchLayout>

    <FlexLayout>
      <CalendarLayout
        month={scope.state.stmon}
        data={scope.state.data}
        render={(_, items: CalendarLayoutDataModel<ScheduleModel>[]) => (
          <DefaultLayout style={{ cursor: 'pointer' }}>
            {items.slice(0, 6).map((x, i) => (
              <div key={i}>
                <span style={{ color: scope.getItemColor(x.data?.upflag) }}>
                  [{x.data.upflagnm}]
                </span>
                {x.data.remark}
              </div>
            ))}

          </DefaultLayout>
        )}
        onDateClick={(date) => { scope.openScheduleModal(date.data); }}
      />
    </FlexLayout>

    {/* 보수팀 */}
    <ModalView
      isVisible={scope.state.isScheduleMaintenanceModalVisible}
      onClose={() => scope.closeScheduleModal()}
      width={1300}
      height={900}
      zIndex={-1}
    >
      <FlexLayout isVertical={true} style={{ padding: 8 }}>
        <FlexLayout
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
          style={{ paddingBottom: 2 }}
        >
          <LayoutTitle weight={1}>스케쥴 등록</LayoutTitle>
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_GRID_HEIGHT_1}
          justify='right'
        >
          <Button
            style={{ width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.modalNew()}
          >
            추 가
          </Button>
          <Button
            style={{ width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.modalDelete()}
          >
            삭 제
          </Button>
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
            align='center'
            justify='left'
          >
          </FlexLayout>
          <FlexLayout size={250}>
            <LayoutTitle
              style={{ color: 'var(--color-red)' }}
              weight={1}
            >
              담당자
            </LayoutTitle>
            <LabelBox weight={4}>
              <TextBox
                value={scope.state.popupPerid}
                textAlign="center"
                onChange={(value) => scope.setState({ popupPerid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      popupPerid: item.cd,
                      popupPernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.popupPernm}
                weight={1.5}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <Button
            style={{ width: '80px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.roadSchedule()}
          >
            부서계획
          </Button>
        </FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.popupTable}
            header={[
              {
                id: 'state',
                text: '상태',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                    {states.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'upflagnm',
                text: '구분',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    style={{ color: scope.getItemColor(x.wkcd) }}
                  >
                    {x.upflagnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkcd',
                text: '업무구분',
                width: 17,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.wkcd}
                    textAlign="center"
                    style={{ color: scope.getItemColor(x.wkcd) }}
                    data={scope.state?.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                    onSelect={(option) => {
                      rowUpdate({ wkcd: option.value });
                      scope.itemChanged(ScheduleItemChangeTypes.WKCD,
                        {
                          ...x,
                          wkcd: option.value,
                        });
                    }}
                  />
                ),
              },
              {
                id: 'plandate',
                text: '계획일자',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <DateTextBox
                    ref={ref}
                    format="####-##-##"
                    value={x.plandate || `${Today.yearMon()}${Today.date()}`}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ plandate: value })}
                  />
                ),
              },
              {
                id: 'actcd',
                text: '현장코드',
                width: 10,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.actcd}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ actcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        rowUpdate({
                          actcd: item.cd,
                          actnm: item.cdnm,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 30,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.actnm}
                    textAlign="left"
                    onChange={(value) => rowUpdate({ actnm: value })}
                  />
                ),
              },
              {
                id: 'equpcd',
                text: '호기코드',
                width: 10,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.equpcd}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ equpcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E611',
                      { uv_arg1: x.actcd },
                      true,
                      (item) => {
                        rowUpdate({
                          equpcd: item.cd,
                          equpnm: item.cdnm,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.equpnm}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ equpnm: value })}
                  />
                ),
              },
              {
                id: 'remark',
                text: '업무내용',
                width: 48,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.remark}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ remark: value })}
                  />
                ),
              },
              {
                id: 'perid',
                text: '담당자',
                width: 10,
                color: 'var(--color-red)',
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 15,
              },
            ]}
            data={scope.state.schedulePopupList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.modalSave()}>저장</Button>
          <Button onClick={() => scope.closeScheduleModal()}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>

    {/* 총무팀 */}
    <ModalView
      isVisible={scope.state.isScheduleGreetingModalVisible}
      onClose={() => scope.closeScheduleModal()}
      width={1500}
      height={900}
      zIndex={-1}
    >
      <FlexLayout isVertical={true} style={{ padding: 8 }}>
        <FlexLayout
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
          style={{ paddingBottom: 2 }}
        >
          <LayoutTitle weight={1}>스케쥴 등록</LayoutTitle>
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_GRID_HEIGHT_1}
          justify='right'
        >
          <Button
            style={{ width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.modalNew()}
          >
            추 가
          </Button>
          <Button
            style={{ width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.modalDelete()}
          >
            삭 제
          </Button>
        </FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.popupTable}
            header={[
              {
                id: 'state',
                text: '상태',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    style={{ color: x.state === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                    {states.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'upflagnm',
                text: '구분',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    style={{ color: scope.getItemColor(x.wkcd) }}>
                    {x.upflagnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkcd',
                text: '업무구분',
                width: 15,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.wkcd}
                    style={{ color: scope.getItemColor(x.wkcd) }}
                    data={scope.state.wkcds?.map((y) => new ComboBoxModel(y.busicd, y.businm))}
                    onSelect={(option) => {
                      rowUpdate({ wkcd: option.value });
                      scope.itemChanged(ScheduleItemChangeTypes.WKCD,
                        {
                          ...x,
                          wkcd: option.value,
                        });
                    }}
                  />
                ),
              },
              {
                id: 'plandate',
                text: '발송일',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <DateTextBox
                    ref={ref}
                    format="####-##-##"
                    value={x.plandate || `${Today.yearMon()}${Today.date()}`}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ plandate: value })}
                  />
                ),
              },
              {
                id: 'remark',
                text: '업무내용',
                width: 50,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.remark}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ remark: value })}
                  />
                ),
              },
              {
                id: 'notice',
                text: '알림구분',
                width: 10,
                render: (x, rowUpdate, _) => (
                  <ComboBox
                    value={x.gubun}
                    data={notices.map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => { rowUpdate({ notice: option.value }); }}
                  />
                ),
              },
              {
                id: 'noticedate',
                text: '알림일자',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <DateTextBox
                    ref={ref}
                    format="####-##-##"
                    value={x.noticedate || `${Today.yearMon()}${Today.date()}`}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ noticedate: value })}
                  />
                ),
              },
              {
                id: 'noticetime',
                text: '접수시간',
                width: 10,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    ref={ref}
                    textAlign="center"
                    format="##:##"
                    value={x.noticetime}
                    onChange={(value) => rowUpdate({ noticetime: value })}
                  />
                ),
              },
              {
                id: 'perid',
                text: '담당자',
                width: 10,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.perid}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ perid: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        rowUpdate({
                          perid: item.cd,
                          pernm: item.cdnm,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.pernm}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ pernm: value })}
                  />
                ),
              },
              {
                id: 'actcd',
                text: '현장코드',
                width: 10,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.actcd}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ actcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        rowUpdate({
                          actcd: item.cd,
                          actnm: item.cdnm,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.actnm}
                    textAlign="left"
                    onChange={(value) => rowUpdate({ actnm: value })}
                  />
                ),
              },
              {
                id: 'equpcd',
                text: '호기코드',
                width: 10,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.equpcd}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ equpcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E611',
                      { uv_arg1: x.actcd },
                      true,
                      (item) => {
                        rowUpdate({
                          equpcd: item.cd,
                          equpnm: item.cdnm,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 15,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.equpnm}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ equpnm: value })}
                  />
                ),
              },
            ]}
            data={scope.state.schedulePopupList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.modalSave()}>저장</Button>
          <Button onClick={() => scope.closeScheduleModal()}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>
  </FlexLayout>
);
