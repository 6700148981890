import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642_01_all
 * @category 지급등록
 */
export class GiveModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string | undefined;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 발생일자
   *
   * **data type** char(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(13)
   */
  mijnum: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  snddate: string;

  /**
   * 지급번호
   *
   * **data type** varchar(4)
   */
  sndnum: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  sndseq: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 회계적용
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 현금지급
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** float
   */
  eamt: string;

  /**
   * 어음번호
   *
   * **data type** varchar(12)
   */
  enum: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  edate: string;

  /**
   * 어음일자
   *
   * **data type** varchar(8)
   */
  eidate: string;

  /**
   * 어음거래처
   *
   * **data type** varchar(13)
   */
  ecltcd: string;

  /**
   * 금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 예금지급
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 은행코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 은행번호
   *
   * **data type** varchar(20)
   */
  bankno: string;

  /**
   * 카드금액
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드은행
   *
   * **data type** varchar(10)
   */
  cardcd: string;

  /**
   * 카드요율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 카드번호
   *
   * **data type** varchar(16)
   */
  cardno: string;

  /**
   * 기타구분
   *
   * **data type** varchar(5)
   */
  ggubun: string;

  /**
   * 기타지급
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 지로번호
   *
   * **data type** varchar(10)
   */
  pubnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 선급금구분
   *
   * **data type** varchar(1)
   */
  sunflag: string;

  /**
   * 선급금구분
   *
   * **data type** float
   */
  sunamt: string;

  /**
   * 이체수수료
   *
   * **data type** float
   */
  bmar: string;

  /**
   * 이체수수료구분
   *
   * **data type** varchar(1)
   */
  bmarflag: string;

  /**
   * 설치군번
   *
   * **data type** varchar(5)
   */
  scseq: string;

  /**
   * 예전매입일자
   *
   * **data type** varchar(8)
   */
  mismijdate: string;

  /**
   * 예전매입번호
   *
   * **data type** varchar(4)
   */
  mismijnum: string;

  /**
   * 정렬
   *
   * **data type** long
   */
  sort: string;

  /**
   * 선택
   *
   * **data type** char(1)
   */
  chk: string;

  /**
   * 고객명
   *
   * **data type** char(255)
   */
  cltnm: string;

  /**
   * 지급처명
   *
   * **data type** varchar(13)
   */
  mijcltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(100)
   */
  mijcltnm: string;

  /**
   * 0
   *
   * **data type** varchar(1)
   */
  mijgubun: string;

  /**
   * 발생금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 차이금액
   *
   * **data type** number
   */
  chaamt: string;

  /**
   * 미지급잔액
   *
   * **data type** number
   */
  janamt: string;

  /**
   * 기지급액
   *
   * **data type** number
   */
  inamt: string;

  /**
   * 지금예정일
   *
   * **data type** varchar(8)
   */
  schdate: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(2)
   */
  taxcls: string;

  /**
   * 0
   *
   * **data type** number
   */
  camt: string;

  /**
   * 예정지급액
   *
   * **data type** long
   */
  winamt: string;

  /**
   * 현금
   *
   * **data type** long
   */
  whamt: string;

  /**
   * 예금
   *
   * **data type** long
   */
  wbamt: string;

  /**
   * 어음
   *
   * **data type** long
   */
  weamt: string;

  /**
   * 수표
   *
   * **data type** long
   */
  wsamt: string;

  /**
   * 카드
   *
   * **data type** long
   */
  wdamt: string;

  /**
   * 기타
   *
   * **data type** long
   */
  wgamt: string;

  /**
   * 계산서일자
   *
   * **data type** varchar(8)
   */
  tax_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  tax_spnum: string;

  /**
   * 예금연결
   *
   * **data type** varchar(50)
   */
  imseq: string;

  /**
   * 현금
   *
   * **data type** varchar(1)
   */
  hchk: string;

  /**
   * 예금
   *
   * **data type** varchar(1)
   */
  bchk: string;

  /**
   * 어음
   *
   * **data type** varchar(1)
   */
  echk: string;

  /**
   * 카드
   *
   * **data type** varchar(1)
   */
  dchk: string;

  /**
   * 기타
   *
   * **data type** varchar(1)
   */
  gchk: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  amt: string;

  /**
   * 발생금액
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 지급일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  snum: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  total_amt: string;

  /**
   * 지급은행
   *
   * **data type** varchar(8)
   */
  banknm: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(8)
   */
  accnum: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  scflag: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  jcltno: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  schk: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  payment: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.scflag = data.scflag || '';
    this.payment = data.payment || '';
    this.schk = data.schk || '';
    this.jcltno = data.jcltno || '';
    this.snum = data.snum || '';
    this.total_amt = data.total_amt || '';
    this.cltcd = data.cltcd || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.rcvdate = data.rcvdate || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.snddate = data.snddate || '';
    this.sndnum = data.sndnum || '';
    this.sndseq = data.sndseq || '';
    this.actcd = data.actcd || '';
    this.accyn = data.accyn || '';
    this.perid = data.perid || '';
    this.hamt = data.hamt || '';
    this.eamt = data.eamt || '';
    this.enum = data.enum || '';
    this.edate = data.edate || '';
    this.eidate = data.eidate || '';
    this.ecltcd = data.ecltcd || '';
    this.samt = data.samt || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.bankno = data.bankno || '';
    this.damt = data.damt || '';
    this.cardcd = data.cardcd || '';
    this.persent = data.persent || '';
    this.cardno = data.cardno || '';
    this.ggubun = data.ggubun || '';
    this.gamt = data.gamt || '';
    this.pubnum = data.pubnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.remark = data.remark || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.billkind = data.billkind || '';
    this.divicd = data.divicd || '';
    this.sunflag = data.sunflag || '';
    this.sunamt = data.sunamt || '';
    this.bmar = data.bmar || '';
    this.bmarflag = data.bmarflag || '0';
    this.scseq = data.scseq || '';
    this.mismijdate = data.mismijdate || '';
    this.mismijnum = data.mismijnum || '';
    this.sort = data.sort || '';
    this.chk = data.chk || '';
    this.custcd = data.custcd || '';
    this.cltnm = data.cltnm || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.mijgubun = data.mijgubun || '';
    this.mijamt = data.mijamt || '';
    this.chaamt = data.chaamt || '';
    this.janamt = data.janamt || '';
    this.inamt = data.inamt || '';
    this.schdate = data.schdate || '';
    this.taxcls = data.taxcls || '';
    this.camt = data.camt || '';
    this.winamt = data.winamt || '';
    this.whamt = data.whamt || '';
    this.wbamt = data.wbamt || '';
    this.weamt = data.weamt || '';
    this.wsamt = data.wsamt || '';
    this.wdamt = data.wdamt || '';
    this.wgamt = data.wgamt || '';
    this.tax_spdate = data.tax_spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.acc_spnum = data.acc_spnum || '';
    this.imseq = data.imseq || '';
    this.hchk = data.hchk || '';
    this.bchk = data.bchk || '';
    this.echk = data.echk || '';
    this.dchk = data.dchk || '';
    this.gchk = data.gchk || '';
    this.amt = data.amt || '';
    this.iamt = data.iamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
