import { computed } from 'mobx';

/**
 * 교육 모델
 * @window w_p2110
 * @category 인사등록
 */
export class EducationModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  readonly educd: string;

  /**
   * 교육명
   *
   * **data type** varchar(50)
   */
  readonly edunm: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly sdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly edate: string;

  /**
   * 비고
   *
   * **data type** varchar(40)
   */
  readonly ref: string;

  /**
   * 교육기관
   *
   * **data type** varchar(50)
   */
  readonly orgnm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.educd = data.educd || '';
    this.edunm = data.edunm || '';
    this.sdate = data.sdate || '';
    this.edate = data.edate || '';
    this.ref = data.ref || '';
    this.orgnm = data.orgnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
