import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  TextBox,
  DateTextBox,
  GridLayout,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  CheckBox,
  TableLayout,
  LayoutTitle,
  ModalView,
  Button,
  LabelBox,
  IFrame,
  Badge,
  SubHeader,
  AttachmentBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import {
  ComboBox,
  ComboBoxModel,
} from '../../../../components/forms/ComboBox';
import { ReferenceBox } from './ReferenceBox';
import subHeaderStyle from '../../../../components/layout/SubHeader/SubHeader.module.scss';

/*
 * 화면
 * @window w_tb_e080w_03
 * @category 수신참조함
*/

const referflags = [
  { value: '1', remark: '확인' },
  { value: '0', remark: '미확인' },
];

const gubuns = [
  { value: '%', remark: '전체' },
  { value: '1', remark: '확인' },
  { value: '0', remark: '미확인' },
];

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const ReferenceBoxTemplate: React.FC<TemplateProps<ReferenceBox>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={210}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24} />
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24} />
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={200}>
            <span>확인구분</span>
            <ComboBox
              value={scope.state.gubun}
              data={gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => scope.setState({ gubun: option.value },
                () => {
                  scope.onRetrieveEvent();
                })}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span>문서구분</span>
            <ComboBox
              value={scope.state.papercd}
              data={scope.state.papercds?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
              onSelect={(option) => scope.setState({ papercd: option.value },
                () => {
                  scope.onRetrieveEvent();
                })}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={350}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="제목, 작성자, 부서로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout weight={3}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'prtchk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout onClick={() => (scope.state.pushTotalCheck === true ? scope.updateCheckAllToggle(false)
                      : scope.updateCheckAllToggle(true))}>
                      {scope.state.pushTotalCheck === true ? '해제' : '출력'}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 5,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.prtchk === '1'}
                      onChange={(value) => {
                        rowUpdate({ prtchk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'referflag',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout onClick={() => (scope.state.pushTotalCheck2 === true ? scope.updateCheckAllToggle2(false)
                      : scope.updateCheckAllToggle2(true))}>
                      {scope.state.pushTotalCheck2 === true ? '해제' : '확인'}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 5,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.referflag === '1'}
                      onChange={(value) => {
                        rowUpdate({ referflag: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'papercd',
                text: '문서',
                width: 10,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      align='center'
                      justify='center'
                    >
                      {scope.state.papercds?.filter((y) => y.com_code === x.papercd)[0]?.com_cnam}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'repodate',
                text: '작성일자',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.repodate}
                  />
                ),
              },
              {
                id: 'repopernm',
                text: '작성자명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.repopernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '제목',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.title}
                  </FlexLayout>
                ),
              },
              {
                id: 'appnum',
                text: '문서번호',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="######-###-####"
                    value={x.appnum}
                  />
                ),
              },
              {
                id: 'appgubun',
                text: '결재상태',
                width: 10,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='right'
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 10 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>

                    <FlexLayout
                      weight={1.2}
                      align='center'
                      justify='left'
                      style={{
                        marginRight: x.appremark?.length > 0 ? 10 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {x.appremark
                      && <IoIosMail size={22}/>
                      }
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.referenceBoxList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => { scope.onUpdatedRows(rows, updatedRows); }}
            onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
            onRowDoubleClick={() => scope.openReferenceModal()}
          />
        </FlexLayout>
        <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
          <LayoutTitle size={240}>상세내용</LayoutTitle>
        </FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.detailGrid}
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'apppernm',
                text: '결재자명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.apppernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'rspnm',
                text: '직위',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rspnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '결재구분',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    {x?.appremark && <Badge>
                      <IoIosMail color="var(--color-red)" size={25} />
                    </Badge>}
                  </FlexLayout>
                ),
              },
              {
                id: 'referflag',
                text: '수신참조확인',
                width: 10,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      align='center'
                      justify='center'
                      style={{ color: x.referflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                    >
                      {x.appgubun === '121' && referflags.filter((y) => y.value === x.referflag)[0]?.remark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'appdate',
                text: '작성일자',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.appdate}
                  />
                ),
              },
              {
                id: 'remark',
                text: '사유',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.detailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.isReferenceModalVisible}
        onClose={() => scope.closeReferenceModal()}
        width={864}
        height={1000}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>수신참조</LayoutTitle>

          <FlexLayout>
            <IFrame src={scope.state.url} />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LayoutTitle weight={1}>
              지시사항
            </LayoutTitle>
            <LabelBox weight={3}>
              <TextBox
                value={scope.state.remark}
                textAlign="left"
                isMultiline={true}
                onChange={(value) => scope.setState({ remark: value })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>
          <SubHeader>
            <FlexLayout
              justify="start"
              className={subHeaderStyle.buttons}
            >
              <Button onClick={() => scope.modalConfirm()}>확인</Button>
            </FlexLayout>

            <FlexLayout justify="end">
              {scope.state.attcnt > '0' && <Button onClick={() => scope.fileModal(true)}>첨부파일</Button>}
              <Button onClick={() => scope.modalPrint()}>출력</Button>
              <Button onClick={() => scope.closeReferenceModal()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.focusedReferenceBox?.appnum}
            appnm={'수신참조함'}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
