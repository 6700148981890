import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import {
  ExternalPageProps,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { YearTemplate } from './Year.template';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';

interface YearState {
  year: string;
  keyData: string;
  textDetailModal: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_yearend
 * @category 년마감현황
 */
@inject('toolStore')
export class Year extends React.Component<PageProps, YearState, ExternalPageProps>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      keyData: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.textModal(true);
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });
  }

  async onPageMove() {
    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        year: this.state.year,
      },
    );

    if (data) {
      this.setState({
        keyData: data?.key,
      }, async () => {
        window.open(`http://pro.elmansoft.com:8993/?custcd=${user.custcd}&spjangcd=${user.spjangcd}&year=${this.state.year}&h=${this.state.keyData}`);
        // @ts-ignore
        this.props.toolStore.onCloseHandler();
        this.textModal(false);
      });
    }
  }

  render() {
    return (
      <YearTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
