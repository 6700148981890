import { computed } from 'mobx';

/**
 * 경력 모델
 * @window w_p2110
 * @category 인사등록
 */
export class CareerModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  readonly carcd: string;

  /**
   * 회사명
   *
   * **data type** varchar(255)
   */
  readonly comnm: string;

  /**
   * 소재지
   *
   * **data type** varchar(60)
   */
  readonly loc: string;

  /**
   * 직위
   *
   * **data type** varchar(20)
   */
  readonly posi: string;

  /**
   * 근무부서
   *
   * **data type** varchar(30)
   */
  readonly dept: string;

  /**
   * 업무
   *
   * **data type** varchar(30)
   */
  readonly serv: string;

  /**
   * 입사일
   *
   * **data type** varchar(8)
   */
  readonly sdate: string;

  /**
   * 퇴사일
   *
   * **data type** varchar(8)
   */
  readonly edate: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.carcd = data.carcd || '';
    this.comnm = data.comnm || '';
    this.loc = data.loc || '';
    this.posi = data.posi || '';
    this.dept = data.dept || '';
    this.serv = data.serv || '';
    this.sdate = data.sdate || '';
    this.edate = data.edate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
