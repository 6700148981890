/**
 * 모델
 * @window w_preview_da006
 * @category 수주현황
 */
export default class StatusModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 공사일자
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 공사자 부서 코드
   *
   * **data type** varchar(8)
   */
  readonly compdivicd: string;

  /**
   * 공사자 부서명
   *
   * **data type** varchar(8)
   */
  readonly compdivinm: string;

  /**
   * 공사 구분
   *
   * **data type** varchar(8)
   */
  readonly compflag: string;

  /**
   * 공사자 사원코드
   *
   * **data type** varchar(8)
   */
  readonly compperid: string;

  /**
   * 공사자 사원명
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   * 견적 일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적 번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 삭제 일자
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   * 부서 코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 완료일자
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 작성일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 작성자 코드
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 작성자 명
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 총액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 수주일자
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 수주번호
   *
   * **data type** varchar(8)
   */
  readonly ordnum: string;

  /**
   * 사원코드
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 갯수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 할인액수
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   * 할인율
   *
   * **data type** varchar(8)
   */
  readonly saleyul: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly title: string;

  /**
   * 특기사항
   *
   * **data type** varchar(8)
   */
  readonly worknm: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.compdate = data.compdate || '';
    this.compdivicd = data.compdivicd || '';
    this.compdivinm = data.compdivinm || '';
    this.compflag = data.compflag || '';
    this.compperid = data.compperid || '';
    this.comppernm = data.comppernm || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.deldate = data.deldate || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.enddate = data.enddate || '';
    this.gubun = data.gubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.mamt = data.mamt || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.saleamt = data.saleamt || '';
    this.saleyul = data.saleyul || '';
    this.samt = data.samt || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.stdate = data.stdate || '';
    this.tamt = data.tamt || '';
    this.title = data.title || '';
    this.worknm = data.worknm || '';
  }
}
