import * as React from 'react';
import { inject } from 'mobx-react';
import { ExternalPageProps } from '../../../../constants';

/**
 * 컨트롤러
 * @window w_gps_lbs
 * @category 화상원격지원
 */
@inject('toolStore')
export class Video extends React.Component<ExternalPageProps> {
  componentDidMount(): void {
    window.open('https://glasses.isbg.co.kr/');
    this.props.toolStore.onCloseHandler();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <React.Fragment />
    );
  }
}
