import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { StaffListTemplate } from './StaffList.template';
import { StaffListModel } from './StaffList.model';
import { AcccdItem, InfinityRetrieve, SpjangItem } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface StaffListState {
  searchQuery: string;
  spjangcd: string;
  rtclafi: string;
  data: Array<StaffListModel>;
  banks?: Array<any>;
  acccds?: Array<AcccdItem>;
  spjangcds?: Array<SpjangItem>;
  focusedData?: StaffListModel;
}

/**
 * 컨트롤러
 * @window w_p3181
 * @category 사원명부조회
 */
export class StaffList extends PageComponent<PageProps, StaffListState>
  implements PageToolEvents {
  updatedRows?: Array<StaffListModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      spjangcd: '%',
      rtclafi: '001',
      data: [],
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 세번째 서버 요청
    // 사업장 리스트 가져오기
    const data = await api.dropdown('wf_dd_spjangcd_02');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data.items });

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        spjangcd: this.state.spjangcd,
        rtclafi: this.state.rtclafi,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new StaffListModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      if (this.state.data.length) {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      spjangcd: this.state.spjangcd,
      rtclafi: this.state.rtclafi,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      spjangcd: this.state.spjangcd,
      rtclafi: this.state.rtclafi,
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: StaffListModel) {
    this.setState({ focusedData: item });
  }

  render() {
    return <StaffListTemplate scope={this} />;
  }
}
