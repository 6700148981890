import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da052w_03
 * @category 월관리대수현황-담당별
 */
export class ManagementModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totamt: string;

  /**
   * 평균
   *
   * **data type** number
   */
  average: string;

  /**
   * 01월
   *
   * **data type** number
   */
  mon01: string;

  /**
   * 02월
   *
   * **data type** number
   */
  mon02: string;

  /**
   * 03월
   *
   * **data type** number
   */
  mon03: string;

  /**
   * 04월
   *
   * **data type** number
   */
  mon04: string;

  /**
   * 05월
   *
   * **data type** number
   */
  mon05: string;

  /**
   * 06월
   *
   * **data type** number
   */
  mon06: string;

  /**
   * 07월
   *
   * **data type** number
   */
  mon07: string;

  /**
   * 08월
   *
   * **data type** number
   */
  mon08: string;

  /**
   * 09월
   *
   * **data type** number
   */
  mon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  mon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  mon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  mon12: string;

  /**
   * 년
   *
   * **data type** char(4)
   */
  year: string;

  /**
   * 월목표
   *
   * **data type** number
   */
  monamt: string;

  /**
   * 년목표
   *
   * **data type** number
   */
  yearamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.totamt = data.totamt || '';
    this.year = data.year || '';
    this.monamt = data.monamt || '';
    this.yearamt = data.yearamt || '';
    this.average = data.average || '';
    this.mon01 = data.mon01 || '';
    this.mon02 = data.mon02 || '';
    this.mon03 = data.mon03 || '';
    this.mon04 = data.mon04 || '';
    this.mon05 = data.mon05 || '';
    this.mon06 = data.mon06 || '';
    this.mon07 = data.mon07 || '';
    this.mon08 = data.mon08 || '';
    this.mon09 = data.mon09 || '';
    this.mon10 = data.mon10 || '';
    this.mon11 = data.mon11 || '';
    this.mon12 = data.mon12 || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
