import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { SalesModel } from '../models';
import { Project } from '../Project';

const recivstatuss = [
  { value: 'A', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'R', remark: '열람' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
];

// 매출내역
const Sales: GridLayoutHeader<SalesModel, Project>[] = [
  {
    id: 'misdate',
    text: '매출일자',
    width: 8,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 5,
  },
  {
    id: 'seq',
    text: '순번',
    width: 5,
  },
  {
    id: 'acccd',
    text: '계정과목',
    width: 8,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.acccds?.filter((y) => y.acccd === x.acccd)[0]?.accnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '적요',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'recivstatus',
    text: '전자세금계산서',
    width: 11,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{
          // eslint-disable-next-line no-nested-ternary
          color: x.recivstatus === 'F'
            // eslint-disable-next-line no-nested-ternary
            ? 'var(--color-blue)' : x.recivstatus === 'FF'
              ? '#7A297B' : x.recivstatus === 'B'
                ? 'var(--color-red)' : '',
        }}>
        {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 6,
  },
  {
    id: 'punit',
    text: '단위',
    width: 5,
  },
  {
    id: 'qty',
    text: '수량',
    width: 5,
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_samt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_addamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.addamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출금액',
    width: 11,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
];

export default Sales;
