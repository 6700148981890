import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  MonthWork,
  MonthWorkTabDataStateNames,
} from './MonthWork';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];

/**
 * 화면
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export const MonthWorkTemplate: React.FC<TemplateProps<MonthWork>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={220}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => setData({ yearmon: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({
                    yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                  },
                  () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({
                    yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                  },
                  () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.divinm}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout
          isVertical={true}
          weight={5}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>
          <FlexLayout weight={1.5}>
            <GridLayout
              scope={scope}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity}
              onRowClick={(item) => scope.onRowFocusEvent(item)}
              // @ts-ignore
              data={scope.state[MonthWorkTabDataStateNames[scope.tabIndex]] || [] }
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        width={1200}
        height={600}
        isVisible={scope.state.historyModal}
        onClose={() => scope.historyPopupModal(false)}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.focused?.actnm}계약내역</LayoutTitle>

          <FlexLayout>
            <TableLayout
              ref={scope.planTable}
              header={[
                {
                  id: 'seq',
                  text: '순서',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'perid',
                  text: '작성자',
                  width: 8,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <TextBox
                        value={x.perid}
                        textAlign="center"
                        onChange={(value) => rowUpdate({ perid: value })}
                        bindSearchModal={new SearchBinding(
                          scope.props.modalStore,
                          'TB_JA001',
                          {},
                          true,
                          (item) => {
                            rowUpdate({
                              perid: item.cd,
                              pernm: item.cdnm,
                            });
                          },
                        )}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '작성자명',
                  width: 8,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'hdate',
                  text: '기록일자',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <FormatTextBox
                        textAlign="center"
                        format="####-##-##"
                        value={x.hdate}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'wakedate',
                  text: '알림만료일',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <FormatTextBox
                        textAlign="center"
                        format="####-##-##"
                        value={x.wakedate}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'title',
                  text: '제목',
                  width: 40,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      <TextBox
                        value={x.title}
                        textAlign="left"
                        onChange={(value) => rowUpdate({ title: value })}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'okflag',
                  text: '답변확인',
                  width: 6,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={x.okflag === '1'}
                        onChange={(value) => {
                          rowUpdate({ okflag: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.modalData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, modalUpdated) => {
                scope.onModalUpdatedRows(rows, modalUpdated);
              }}
              onRowFocusEvent={(item) => scope.historyRowFocusEvent(item)}
            />
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="계약내용">
              <TextBox
                value={scope.state.remark}
                onChange={(value) => setData({ remark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onNewHistoryEvent()}>신규</Button>
              <Button onClick={() => scope.historyDelete()}>삭제</Button>
              <Button onClick={() => scope.onHistorySaveEvent()}>저장</Button>
              <Button onClick={() => scope.historyPopupModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.modalState}
        onClose={() => scope.textModal(false)}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >

          <LayoutTitle>
            <div>{scope.state.popupDate?.title}</div>
            <div>{scope.state.popupDate?.mon.slice(4, 6)} 월</div>
          </LayoutTitle>

          <FlexLayout weight={8}>
            <TableLayout
              ref={scope.table}
              header={[
                {
                  id: 'gubun',
                  text: '매출구분',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계 {scope.state.popupDate?.total} 건
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seq',
                  text: 'No',
                  width: 80,
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 80,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      textAlign="center"
                      value={x.qty}
                      onChange={(value) => rowUpdate({ qty: value })}
                    />
                  ),
                },
                {
                  id: 'cnam',
                  text: '청구주기',
                  width: 80,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.cnam}
                      data={[
                        { value: '01', remark: '월' },
                        { value: '02', remark: '분기' },
                        { value: '03', remark: '반기' },
                        { value: '04', remark: '년' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ cnam: option.value })}
                    />
                  ),
                },
                {
                  id: 'flag',
                  text: '구분',
                  width: 100,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.flag}
                      style={{ color: x.flag === '2' ? 'var(--color-red)' : '' }}
                      data={[
                        { value: '0', remark: '신규' },
                        { value: '1', remark: '만료' },
                        { value: '2', remark: '저가' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ flag: option.value })}
                    />
                  ),
                },
                {
                  id: 'contgubun',
                  text: '이전계약',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {contgs.filter((y) => y.value === x.contgubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'besamt',
                  text: '이전보수료',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.besamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.besamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qtyamt',
                  text: '이전보수(대당가)',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.qtyamt_tot}
                    </FlexLayout>
                  ),
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      textAlign="right"
                      value={x.qtyamt}
                      onChange={(value) => rowUpdate({ qtyamt: value })}
                    />
                  ),
                },
                {
                  id: 'contgubun',
                  text: '계획계약',
                  width: 100,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.contgubun}
                      style={{
                        color: x.contgubun === '03' ? 'var(--color-red)'
                          : x.contgubun === '01' ? 'var(--color-blue)'
                            : x.contgubun === '02' ? '#7F7A2C' : '',
                      }}
                      data={[
                        { value: '01', remark: 'FM계약' },
                        { value: '02', remark: '다년계약' },
                        { value: '03', remark: '일반계약' },
                        { value: '04', remark: '상주' },
                        { value: '05', remark: '외주' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ contgubun: option.value })}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '인상보수료',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.samt_tot}
                    </FlexLayout>
                  ),
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      textAlign="right"
                      value={x.samt}
                      color={'var(--color-blue)'}
                      onChange={(value) => rowUpdate({ samt: value })}
                    />
                  ),
                },
                {
                  id: 'chasamt',
                  text: '증가액',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.chasamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(x.chasamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '계획내용',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.popupUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.popupRowFocusEvent(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalContractEvent()}>현재계약갱신</Button>
              <Button onClick={() => scope.texDowntModal(true)}>내려받기</Button>
              <Button onClick={() => scope.modalNewEvent()}>신규</Button>
              <Button onClick={() => scope.modalDelete()}>삭제</Button>
              <Button onClick={() => scope.modalSave()}>저장</Button>
              <Button onClick={() => scope.modalRetrieve()}>조회</Button>
              <Button onClick={() => scope.textModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={370}
        height={70}
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.texDowntModal(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SearchLayout>
            <FlexLayout size={200}>
              <span>년도</span>
              <DateTextBox
                value={scope.state.yearmon}
                textAlign="center"
                format="####년 ##월"
                onChange={(value) => setData({ year: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    setData({ year: new Date6Calculator(scope.state.year).add(-1).toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    setData({ year: new Date6Calculator(scope.state.year).add().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button onClick={() => scope.modalDownEvent()}>
              확인
            </Button>
          </SearchLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
