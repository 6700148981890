import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  FormatTextBox,
  GridLayout,
  Button,
  LabelBox,
  FormatNumberTextBox,
  ComboBox,
  ComboBoxModel,
  DefaultLayout,
  TableLayout,
  LayoutTitle,
  ModalView,
  AttachmentBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Bill,
  BillItemChangeTypes,
} from './Bill';
import { Date8, Today } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ca606_01_2
 * @category 자재청구서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const BillTemplate: React.FC<TemplateProps<Bill>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={400}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 80 }}>청구서복사</div>
        </Button>

        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout size={368}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'pumdate',
              text: '청구일자',
              width: 15,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.pumdate}
                />
              ),
            },
            {
              id: 'pumnum',
              text: '번호',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pumnum}
                </FlexLayout>
              ),
            },
            {
              id: 'appgubun',
              text: '상태',
              width: 10,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='right'
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 10 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                    onDoubleClick={() => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>

                  <FlexLayout
                    weight={1}
                    align='center'
                    justify='left'
                    style={{
                      marginRight: x.appremark?.length > 0 ? 10 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {x.appremark
                    && <IoIosMail size={22}/>
                    }
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '청구자명',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.billList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 자재청구서를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.pumdate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ pumdate: value }, () => scope.itemChanged(BillItemChangeTypes.PUMDATA))}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.pumnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="######-###-####"
                textAlign="center"
                value={model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.inperid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.inpernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성일자">
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={model?.indate}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="제목">
              <TextBox
                value={model.title}
                onChange={(value) => setData({ title: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              weight={2}
              style={{ color: 'var(--color-red)' }}
              footer={
                <DefaultLayout size={175}>
                  <ComboBox
                    textAlign="center"
                    value={model?.contgubun}
                    data={[
                      { value: '01', remark: 'FM계약' },
                      { value: '02', remark: '다년계약' },
                      { value: '03', remark: '일반계약' },
                      { value: '04', remark: '상주' },
                      { value: '05', remark: '외주' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setData({ contgubun: option.value })}
                    isDisabledTrackingStateChange={true}
                  />
                </DefaultLayout>
              }
            >
              <TextBox
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.actnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="청구자명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    }, () => scope.itemChanged(BillItemChangeTypes.PERID));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="청구자부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.divinm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="비고(요청사항)"
              weight={3}
            >
              <TextBox
                onChange={(value) => setData({ remark: value })}
                value={model?.remark}
              />
            </LabelBox>
            <LabelBox
              title="청구금액"
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.samt}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <FlexLayout
                style={{
                  color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)',
                  backgroundColor: '#F0F0F0',
                }}
                align="center"
                justify="center"
              >
                {model?.attcnt === 0 ? '0건 없음' : `${model?.attcnt}건 있음`}
              </FlexLayout>
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

        <LayoutTitle size={230}>청구 내역</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 자재청구서를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'pumseq',
              text: '순번',
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pumseq}
                  onChange={(value) => rowUpdate({ pumseq: value })}
                  readonly={true}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'equpcd',
              text: '호기코드',
              width: 75,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.equpcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ equpcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E611',
                    { uv_arg1: model?.actcd },
                    true,
                    (item) => {
                      rowUpdate({
                        equpcd: item.cd,
                        equpnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.equpnm}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ equpnm: value })}
                />
              ),
            },
            {
              id: 'myn',
              text: '유무상',
              width: 70,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.myn}
                  textAlign="center"
                  data={[
                    { value: '0', remark: '무상' },
                    { value: '1', remark: '유상' },
                    { value: '2', remark: '클레임' },
                  ].map((x) => new ComboBoxModel(x.value, x.remark))}
                  onSelect={(option) => rowUpdate({ myn: option.value })}
                />
              ),
            },
            {
              id: 'pcode',
              text: '품목코드',
              width: 150,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pcode}
                  textAlign="center"
                  onDoubleClick={async () => {
                    const result = await scope.props.modalStore.openProductSelector(x.pcode);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_mamt,
                      jqty: result.jqty,
                    });
                  }}
                  onChange={(value) => rowUpdate({ pcode: value })}
                  onEnter={async (value) => {
                    const result = await scope.props.modalStore.openProductSelector(value);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_mamt,
                      jqty: result.jqty,
                    });
                  }}
                />
              ),
            },
            {
              id: 'pname',
              text: '품목명',
              width: 300,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pname}
                  onChange={(value) => rowUpdate({ pname: value })}
                />
              ),
            },
            {
              id: 'psize',
              text: '규격',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.psize}
                  onChange={(value) => rowUpdate({ psize: value })}
                />
              ),
            },
            {
              id: 'punit',
              text: '단위',
              width: 75,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.punit}
                </FlexLayout>
              ),
            },
            {
              id: 'jqty',
              text: '현재고',
              width: 75,
              trail: (data) => {
                const sumJqty = Sum.all(data, 'jqty');
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(sumJqty)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.jqty}
                  textAlign="center"
                  onChange={(value) => scope.tabAutoCalc({ ...x, jqty: value }, rowUpdate, 'jqty')}
                />
              ),
            },
            {
              id: 'qty',
              text: '수량',
              width: 75,
              trail: (data) => {
                const sumQty = Sum.all(data, 'qty');
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(sumQty)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.qty}
                  textAlign="center"
                  onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
                />
              ),
            },
            {
              id: 'uamt',
              text: '단가',
              width: 150,
              trail: (data) => {
                const sumUamt = Sum.all(data, 'uamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumUamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.uamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                />
              ),
            },
            {
              id: 'samt',
              text: '금액',
              width: 150,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (<FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumSamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 800,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.billDetailList}
          onChange={(rows2, updatedRows2) => {
            scope.onUpdatedRows2(rows2, updatedRows2);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data.appnum}
            appnm={'자재청구서'}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
