import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  EducationModel,
  EducationPopupModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import { EducationTemplate } from './Education.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { PageComponent } from '../../../../utils/layout';
import { DateStabilizer } from '../../../../utils/time';

export enum EducationItemChangeTypes {
  STDATE,
  ENDDATE,
}

export enum EducationItemChangeTypeNames {
  stdate,
  enddate,
}

interface EducationState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  perid: string;
  pernm: string;

  focusIndex: number;
  chk: string;

  // 데이터 객체
  focusedEducation?: EducationModel;
  data: EducationModel;
  lastNewData: EducationModel;
  educationList: Array<EducationModel>;
  popupList: Array<EducationPopupModel>;
  focusedPopup?: EducationPopupModel;

  // 모달
  fileDetailModal: boolean; // 첨부파일
  isEduperModalVisible: boolean; // 교육받는자

  isReported: boolean; // 결재상신 여부
}

/*
 모델
* @window w_tb_pb404
* @category 교육보고서
*/

export class Education extends PageComponent<PageProps, EducationState>
  implements PageToolEvents {
  updatedRows?: Array<EducationPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}0101`, // 기간(시작)
      enddate: `${year}${month}${date}`, // 기간(끝)
      perid: '',
      pernm: '',
      searchQuery: '',
      focusIndex: 0,
      chk: '0',
      data: new EducationModel(),
      educationList: [],
      isReported: false,
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          edudate: this.state.data.edudate,
          edunum: this.state.data.edunum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false,
      );

      data && this.setState({
        data: new EducationModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }


  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          educationList: [...this.state.educationList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          educationList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.educationList && this.state.educationList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      educationList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['edudate', 'edunum'],
        [lastSelected?.edudate, lastSelected?.edunum], type, true) || 0;
      if (this.state.educationList && this.state.educationList.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.educationList[this.state.focusIndex], this.state.focusIndex);
      }
      // this.state.educationList.length > 0
      // && this.onRowFocusEvent(this.state.educationList[this.state.focusIndex], this.state.focusIndex);
      if (this.state.chk === '1') {
        this.grid.current?.setFocus(this.state.focusIndex);
        this.setState({ chk: '0' });
      } else {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  onRowFocusEvent(item: EducationModel, index: number) {
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedEducation: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            edudate: this.state.focusedEducation?.edudate,
            edunum: this.state.focusedEducation?.edunum,
          });

        await data && this.setState({ data: new EducationModel(data) });

        // 결재상신 여부확인
        if (this.state.focusedEducation?.appgubun === null
          || this.state.focusedEducation?.appgubun === ''
          || this.state.focusedEducation?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    const params = {
      itemname: EducationItemChangeTypeNames[type],
      date: '',
      new: this.state.data.new,
    };

    if (type === EducationItemChangeTypeNames.stdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          // date: this.state.data.enddate,
        },
      );
    }

    if (type === EducationItemChangeTypeNames.enddate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          date: this.state.data.enddate,
          stdate: this.state.data.stdate,
          enddate: this.state.data.enddate,
        },
      );
    }

    data && this.setState({
      data: new EducationModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedEducation!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.STUDY, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedEducation!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.STUDY);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.STUDY.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.edudate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new EducationModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        educationList: [
          newModel,
          ...this.state.educationList,
        ],
        focusedEducation: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported) {
      await api.fxExec('delete',
        {
          edudate: this.state.data.edudate,
          edunum: this.state.data.edunum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false);
    } else {
      const text = `작성일자: ${this.state.data?.edudate} 작성번호: ${this.state.data?.edunum}`;
      await api.delete(text, {
        edudate: this.state.data.edudate,
        edunum: this.state.data.edunum,
        appnum: this.state.data.appnum,
        appgubun: this.state.data.appgubun,
        title: this.state.data.title,
      }) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.appnum, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      edudate: this.state.data.edudate,
      edunum: this.state.data.edunum,
      appnum: this.state.data.appnum,
      net: '',
    });
  }

  @action
  onPopupRowFocusEvent(item:EducationPopupModel) {
    this.setState({ focusedPopup: item });
  }

  @action
  async closeEduperModal() {
    this.setState({
      popupList: [],
      isEduperModalVisible: false,
    });
    this.updatedRows = [];
    await this.onRetrieveEvent();
  }


  @action
  async openWorkerModal() {
    // eslint-disable-next-line max-len
    this.setState({
      isEduperModalVisible: true,
    }, () => this.modalRetrieve());
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const data = await api.retrieve(
      {
        sub: 'w_popup_pb404_perid',
        edudate: this.state.data.edudate,
        edunum: this.state.data.edunum,
      },
    );

    if (data.items) {
      this.setState({
        popupList: data.items?.map((x: any) => new EducationPopupModel(x)),
      }, async () => {
        await this.popupTable.current?.update();
        this.popupTable.current?.setFocus(0, 0);
      });
    } else {
      this.setState({
        popupList: [],
      }, () => this.popupTable.current?.update());
    }
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_pb404_perid',
      edudate: this.state.focusedPopup?.edudate,
      edunum: this.state.focusedPopup?.edunum,
      seq: this.state.focusedPopup?.seq,

    }) && await this.modalRetrieve();

    this.updatedRows = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix

    if (this.state.popupList?.length! < 1) {
      await this.setState({ isEduperModalVisible: false });
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_pb404_perid',
        items: this.state.popupList,
      });

    if (data) {
      this.closeEduperModal();
    }
  }


  @action
  async modalNew() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const seq:number | undefined = this.state.popupList?.length;

    const data = await api.fxExec('new',
      {
        sub: 'w_popup_pb404_perid',
        edudate: this.state.data.edudate,
        edunum: this.state.data.edunum,
      });

    if (data) {
      // eslint-disable-next-line max-len
      const newModel = new EducationPopupModel({
        ...data,

        // eslint-disable-next-line no-nested-ternary
        seq: seq! < 10 ? `00${seq! + 1}` : seq! < 100 ? `0${seq! + 1}` : (seq! + 1).toString(),
      }, true);

      this.setState({
        popupList: [
          newModel,
          ...this.state.popupList!,
        ],
        focusedPopup: newModel,
      }, async () => {
        await this.popupTable.current?.update(false);
        this.popupTable.current?.setFocus(0, 0);
      });
    }
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<EducationPopupModel>, updatedRows: Array<EducationPopupModel>) {
    this.updatedRows = updatedRows;
    this.setState({ popupList: rows });
  }

  render() {
    return (
      <EducationTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new EducationModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
