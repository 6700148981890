import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import { LabelBox } from '../../components/forms/LabelBox';
import { TextBox } from '../../components/forms/TextBox';
import { Date8 } from '../../utils/time';


interface ModalApprovalRemarkProps {
  modalStore: ModalStore;
}

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];


@observer
export class ModalApprovalRemark extends React.Component<ModalApprovalRemarkProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        isVisible={modalStore.isVisibleModalApprovalRemark}
        onClose={() => modalStore.closeApprovalRemark()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LabelBox title="결재권자 지시사항">
            <TextBox
              value={modalStore.approvalRemarkFocused?.remark}
              // value={x.appremark}
              isMultiline={true}
            />
          </LabelBox>

          <GridLayout
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 15,
              },
              {
                id: 'apppernm',
                text: '결재자명',
                width: 17,
              },
              {
                id: 'rspnm',
                text: '직위',
                width: 17,
              },
              {
                id: 'appgubun',
                text: '결재구분',
                width: 17,
                // render: (x: any) => (
                //   <FlexLayout justify="center" align="center">
                //     {modalStore.getGubunnm(x.appgubun)}
                //   </FlexLayout>
                // ),
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={
                      x.appgubun === '101' ? { color: 'var(--color-blue)' }
                        : x.appgubun === '131' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }
                    }
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'referflag',
                text: '수신참조확인',
                width: 17,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.referflag === '1' ? 'var(--color-blue)' : 'var(--color-red)',
                    }}
                  >
                    {x.appgubun === '121' && (x.referflag === '1' ? '확인' : '미확인')}
                  </FlexLayout>
                ),
              },
              {
                id: 'appdate',
                text: '결재일자',
                width: 17,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {Date8.withDash(x.appdate)}
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore.approvalRemarkData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item: any) => modalStore.onApprovalRemarkRowFocusEvent(item)}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => modalStore.closeApprovalRemark()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
