import {
  FaQuoteLeft,
  FaQuoteRight,
  FaRoad,
  FaRegCreditCard,
  FaWonSign,
}
  from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Collect } from './Collect';
import { TabModel } from '../../../../models';
import { CollectTabDaTemplate } from './tabs/CollectTabDa.template';
import { CollectTabCollectTemplate } from './tabs/CollectTabCollect.template';
import { CollectTabBalTemplate } from './tabs/CollectTabBal.template';
import { CollectTabPurchaseTemplate } from './tabs/CollectTabPurchase.template';
import { CollectTabProvisionTemplate } from './tabs/CollectTabProvision.template';

/**
 * 화면 > 탭
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTab: Array<TabLayoutDataItem<Collect>> = [
  {
    tab: new TabModel('DA', FaQuoteLeft, '매출내역'),
    template: (scope) => <CollectTabDaTemplate scope={scope} />,
  },
  {
    tab: new TabModel('COLLECT', FaQuoteRight, '수금내역'),
    template: (scope) => <CollectTabCollectTemplate scope={scope} />,
  },
  {
    tab: new TabModel('BAL', FaRoad, '발주내역'),
    template: (scope) => <CollectTabBalTemplate scope={scope} />,
  },

  {
    tab: new TabModel('Purchase', FaRegCreditCard, '매입내역'),
    template: (scope) => <CollectTabPurchaseTemplate scope={scope} />,
  },

  {
    tab: new TabModel('Provision', FaWonSign, '지급내역'),
    template: (scope) => <CollectTabProvisionTemplate scope={scope} />,
  },
];
