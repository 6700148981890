import { GLHF } from '../../../../../constants';
import { MonthlyIncomeStatement } from '../MonthlyIncomeStatement';
import { DepositPaymentModel } from '../models/DepositPayment.model';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';

export const DepositPaymentGridHeader: GLHF<DepositPaymentModel, MonthlyIncomeStatement> = (scope) => ([
  {
    id: 'sum',
    text: '그룹',
    isHidden: true,
    group: true,
    value: (x) => x.flag,
  },
  {
    id: 'flag',
    text: '분류',
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB', color: 'var(--color-blue)' }
          : { backgroundColor: '#FFEAEA', color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? '입금' : '지급'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '코드',
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
      >
        {x.gubun}
      </FlexLayout>
    ),
  },
  {
    id: 'gubunnm',
    text: '구분명',
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
      >
        {x.gubunnm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? '입금 합계' : '지급 합계'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout style={{ color: 'var(--color-green)' }}>입금</FlexLayout>
        <FlexLayout style={{ color: 'var(--color-violet)' }}>지급</FlexLayout>
        <FlexLayout>차액</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt01',
    text: '01월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '01', '01') : scope.onPaymentModalOpen(true, '01', '01'))}
      >
        {Format.number(x.amt01)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt01_mis_tot : scope.state.amt01_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt01_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt01_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt01_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt02',
    text: '02월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '02', '02') : scope.onPaymentModalOpen(true, '02', '02'))}
      >
        {Format.number(x.amt02)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt02_mis_tot : scope.state.amt02_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt02_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt02_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt02_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt03',
    text: '03월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '03', '03') : scope.onPaymentModalOpen(true, '03', '03'))}
      >
        {Format.number(x.amt03)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt03_mis_tot : scope.state.amt03_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt03_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt03_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt03_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt04',
    text: '04월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '04', '04') : scope.onPaymentModalOpen(true, '04', '04'))}
      >
        {Format.number(x.amt04)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt04_mis_tot : scope.state.amt04_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt04_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt04_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt04_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt05',
    text: '05월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '05', '05') : scope.onPaymentModalOpen(true, '05', '05'))}
      >
        {Format.number(x.amt05)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt05_mis_tot : scope.state.amt05_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt05_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt05_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt05_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt06',
    text: '06월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '06', '06') : scope.onPaymentModalOpen(true, '06', '06'))}
      >
        {Format.number(x.amt06)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt06_mis_tot : scope.state.amt06_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt06_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt06_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt06_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt07',
    text: '07월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '07', '07') : scope.onPaymentModalOpen(true, '07', '07'))}
      >
        {Format.number(x.amt07)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt07_mis_tot : scope.state.amt07_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt07_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt07_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt07_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt08',
    text: '08월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '08', '08') : scope.onPaymentModalOpen(true, '08', '08'))}
      >
        {Format.number(x.amt08)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt08_mis_tot : scope.state.amt08_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt08_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt08_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt08_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt09',
    text: '09월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '09', '09') : scope.onPaymentModalOpen(true, '09', '09'))}
      >
        {Format.number(x.amt09)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt09_mis_tot : scope.state.amt09_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt09_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt09_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt09_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt10',
    text: '10월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '10', '10') : scope.onPaymentModalOpen(true, '10', '10'))}
      >
        {Format.number(x.amt10)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt10_mis_tot : scope.state.amt10_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt10_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt10_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt10_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt11',
    text: '11월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '11', '11') : scope.onPaymentModalOpen(true, '11', '11'))}
      >
        {Format.number(x.amt11)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt11_mis_tot : scope.state.amt11_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt11_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt11_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt11_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amt12',
    text: '12월',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '12', '12') : scope.onPaymentModalOpen(true, '12', '12'))}
      >
        {Format.number(x.amt12)}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
      >
        {x.flag === '1' ? scope.state.amt12_mis_tot : scope.state.amt12_mij_tot}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{scope.state.amt12_mis_tot}</FlexLayout>
        <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{scope.state.amt12_mij_tot}</FlexLayout>
        <FlexLayout justify="right">{scope.state.amt12_cha_tot}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'amttot',
    text: '년합계',
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={x.flag === '1' ? { backgroundColor: '#E6F0FB' } : { backgroundColor: '#FFEAEA' }}
        onDoubleClick={() => (x.flag === '1' ? scope.onDepositModalOpen(true, '01', '12') : scope.onPaymentModalOpen(true, '01', '12'))}
      >
        {Format.number(x.amttot.replace(/,/g, ''))}
      </FlexLayout>
    ),
    sum: (x) => {
      // 입금 총 합계
      const totMis = String(Number(scope.state.amt01_mis_tot.replace(/,/g, '')) + Number(scope.state.amt02_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt03_mis_tot.replace(/,/g, '')) + Number(scope.state.amt04_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt05_mis_tot.replace(/,/g, '')) + Number(scope.state.amt06_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt07_mis_tot.replace(/,/g, '')) + Number(scope.state.amt08_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt09_mis_tot.replace(/,/g, '')) + Number(scope.state.amt10_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt11_mis_tot.replace(/,/g, '')) + Number(scope.state.amt12_mis_tot.replace(/,/g, '')));

      // 출금 총 합계
      const totMij = String(Number(scope.state.amt01_mij_tot.replace(/,/g, '')) + Number(scope.state.amt02_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt03_mij_tot.replace(/,/g, '')) + Number(scope.state.amt04_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt05_mij_tot.replace(/,/g, '')) + Number(scope.state.amt06_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt07_mij_tot.replace(/,/g, '')) + Number(scope.state.amt08_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt09_mij_tot.replace(/,/g, '')) + Number(scope.state.amt10_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt11_mij_tot.replace(/,/g, '')) + Number(scope.state.amt12_mij_tot.replace(/,/g, '')));

      return (
        <FlexLayout
          justify="right"
          align="center"
          style={x.flag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
        >
          {x.flag === '1' ? Format.number(totMis) : Format.number(totMij)}
        </FlexLayout>
      );
    },
    trail: () => {
      const totMis = String(Number(scope.state.amt01_mis_tot.replace(/,/g, '')) + Number(scope.state.amt02_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt03_mis_tot.replace(/,/g, '')) + Number(scope.state.amt04_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt05_mis_tot.replace(/,/g, '')) + Number(scope.state.amt06_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt07_mis_tot.replace(/,/g, '')) + Number(scope.state.amt08_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt09_mis_tot.replace(/,/g, '')) + Number(scope.state.amt10_mis_tot.replace(/,/g, ''))
        + Number(scope.state.amt11_mis_tot.replace(/,/g, '')) + Number(scope.state.amt12_mis_tot.replace(/,/g, '')));

      const totMij = String(Number(scope.state.amt01_mij_tot.replace(/,/g, '')) + Number(scope.state.amt02_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt03_mij_tot.replace(/,/g, '')) + Number(scope.state.amt04_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt05_mij_tot.replace(/,/g, '')) + Number(scope.state.amt06_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt07_mij_tot.replace(/,/g, '')) + Number(scope.state.amt08_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt09_mij_tot.replace(/,/g, '')) + Number(scope.state.amt10_mij_tot.replace(/,/g, ''))
        + Number(scope.state.amt11_mij_tot.replace(/,/g, '')) + Number(scope.state.amt12_mij_tot.replace(/,/g, '')));

      const totCha = String(Number(scope.state.amt01_cha_tot.replace(/,/g, '')) + Number(scope.state.amt02_cha_tot.replace(/,/g, ''))
        + Number(scope.state.amt03_cha_tot.replace(/,/g, '')) + Number(scope.state.amt04_cha_tot.replace(/,/g, ''))
        + Number(scope.state.amt05_cha_tot.replace(/,/g, '')) + Number(scope.state.amt06_cha_tot.replace(/,/g, ''))
        + Number(scope.state.amt07_cha_tot.replace(/,/g, '')) + Number(scope.state.amt08_cha_tot.replace(/,/g, ''))
        + Number(scope.state.amt09_cha_tot.replace(/,/g, '')) + Number(scope.state.amt10_cha_tot.replace(/,/g, ''))
        + Number(scope.state.amt11_cha_tot.replace(/,/g, '')) + Number(scope.state.amt12_cha_tot.replace(/,/g, '')));

      return (
        <FlexLayout isVertical={true}>
          <FlexLayout justify="right" style={{ color: 'var(--color-green)' }}>{Format.number(totMis)}</FlexLayout>
          <FlexLayout justify="right" style={{ color: 'var(--color-violet)' }}>{Format.number(totMij)}</FlexLayout>
          <FlexLayout justify="right">{Format.number(totCha)}</FlexLayout>
        </FlexLayout>
      );
    },
  },
]);
