import * as React from 'react';
import { action, computed } from 'mobx';
import update from 'react-addons-update';
import { RefObject } from 'react';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import JournalModel from './models/JournalModel';
import { JournalTemplate } from './Journal.template';
import { PageComponent } from '../../../../utils';
import { TableLayout } from '../../../../components';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import JournalGridModel from './models/JournalGridModel';
import JournalDetailModel from './models/JournalDetailModel';
import { Date6 } from '../../../../utils/time';


export const Appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];


interface JournalState {
  stmon: string;
  perid: string;
  pernm: string;
  spjangcd: string;
  state: string;
  searchQuery: string;
  searchQueryGrid: string;

  data: Array<JournalModel>;
  dataGrid: Array<JournalGridModel>;
  dataDetail: Array<JournalDetailModel>;

  isVisibleAttachment: boolean;
  isVisibleGrid: boolean;
  isVisibleDetail: boolean;

  tableFocusItem?: JournalModel;
}

/**
 * 컨트롤러
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export class Journal extends PageComponent<PageProps, JournalState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  table: RefObject<TableLayout> = React.createRef();

  tableDetail: RefObject<TableLayout> = React.createRef();

  tableUpdates: Array<JournalModel> = [];

  gridFocusIndex: number = -1;

  tableFocusIndex: number = -1;

  tableDetailFocusIndex: number = -1;

  spjangcds: Array<any> = [];


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const { pageParams } = this.props.publicStore;

    this.state = props.state || {
      stmon: pageParams.mon || Date6.make(),
      perid: '',
      pernm: '',
      spjangcd: '%',
      state: '%',
      searchQuery: pageParams.searchQuery,
      searchQueryGrid: '',

      data: [],
      dataGrid: [],
      dataDetail: [],

      isVisibleAttachment: false,
      isVisibleGrid: false,
      isVisibleDetail: false,

      tableFocusItem: undefined,
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;

    this.spjangcds = data.items;

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon || Date6.make(),
        perid: this.state.perid || '%',
        spjangcd: this.state.spjangcd || '%',
        result: this.state.state || '%',
        as_nm: this.state.searchQuery || '',
      },
      (params) => api.retrieve(params),
      async (items) => {
        await this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new JournalModel(item)),
          ],
        });
        await this.table.current?.update(false);
      },
      async () => {
        await this.SS({
          data: [],
        });

        this.tableFocusIndex = -1;
        this.gridFocusIndex = -1;
        this.tableDetailFocusIndex = -1;

        this.tableUpdates = [];
        await this.table.current?.update(true);

        await this.infinity?.retrieveAll();
        await this.table.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
    });

    this.tableFocusIndex = -1;
    this.gridFocusIndex = -1;
    this.tableDetailFocusIndex = -1;

    this.tableUpdates = [];
    await this.table.current?.update(true);

    await this.infinity?.retrieveAll();
    await this.table.current?.setFocus(0);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (this.state.searchQuery !== '' || this.state.stmon !== Date6.make() || this.state.state !== '%'
      || this.state.perid !== '' || this.state.pernm !== '') {
      await this.SS({
        searchQuery: '',
        stmon: Date6.make(),
        state: '%',
        perid: '',
        pernm: '',
      });
      await this.onRetrieveEvent();
    }

    if (data) {
      const one = new JournalModel(data, true);

      await this.SS({
        data: [...this.state.data, one],
      });

      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.data.length - 1);
    }
  }

  @action
  async onSaveEvent() {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.tableFocus?.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.tableUpdates,
    }, false)) {
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.tableFocus?.actnm} ${this.tableFocus?.equpnm}`;

    if (this.tableFocus?.isReported) {
      this.onSubmitButtonClicked(true);
      return;
    }

    if (await api.delete(text, this.tableFocus)) {
      await this.SS({
        data: update(this.state.data, {
          $splice: [[this.tableFocusIndex, 1]],
        }),
      });

      await this.table.current?.update(false);

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.data.length) {
        const next = this.tableFocusIndex === 0 ? 0 : this.tableFocusIndex - 1;
        await this.table.current?.setFocus(next);
      } else {
        this.tableFocusIndex = 0;
      }

      await api.fxExec('delete_appok', this.tableFocus);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      spjangcd: this.tableFocus?.spjangcd,
      actcd: this.tableFocus?.actcd,
      equpcd: this.tableFocus?.equpcd,
      seq: this.tableFocus?.seq,
      attcnt: this.tableFocus?.attcnt,
    });
  }


  // Button
  @action
  async onSubmitButtonClicked(isForce: boolean = false) {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.tableFocus?.appgubun === '101') {
      return;
    }

    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (user.perid !== this.tableFocus?.kcperid) {
      ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다');
      return;
    }

    if (!isForce && this.tableFocus?.isReported && !this.tableFocus?.isReportedCancelable) {
      ConfirmWarning.show('오류', '현재 결재 상태에서는 취소할 수 없습니다');
      return;
    }

    await this.onSaveEvent();
    if (this.tableFocus?.isReportedCancelable) {
      appflag = 'cancel';
      text = `${this.tableFocus?.actnm} ${this.tableFocus?.equpnm} 결재상신을 취소하시겠습니까?`;
    } else {
      appflag = 'ok';
      text = `${this.tableFocus?.actnm} ${this.tableFocus?.equpnm} 결재를 상신하시겠습니까?`;
    }

    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    if (appflag === 'ok') {
      if (await modalStore.openApprovalLine(PAPERCD.PRECHK)) {
        await api.fxExec('wb_appreport', {
          papercd: PAPERCD.PRECHK.toString(),
          ...this.tableFocus,
          appflag,
        }) && this.onRetrieveEvent();
      }
    } else {
      await api.fxExec('wb_appreport', {
        papercd: PAPERCD.PRECHK.toString(),
        ...this.tableFocus,
        appflag,
      }) && this.onRetrieveEvent();
    }
  }

  @action
  async onEmailButtonClicked() {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    api.fxEmail(
      '',
      '',
      '',
      '',
      '',
      '',
      {
        spjangcd: this.tableFocus?.spjangcd,
        actcd: this.tableFocus?.actcd,
        equpcd: this.tableFocus?.equpcd,
        seq: this.tableFocus?.seq,
        attcnt: this.tableFocus?.attcnt,
      },
    );
  }


  // Main
  getGubunnm(gubuncd: string) {
    const f = Appgubuns.filter((x) => x.value === gubuncd);
    return f.length ? f[0].remark : '';
  }

  @action
  async onRowFocusEvent(item: JournalModel | undefined, index: number) {
    if (!item) {
      this.tableFocusIndex = 0;
      this.SS({ tableFocusItem: undefined });
      return;
    }

    this.tableFocusIndex = index;
    this.SS({ tableFocusItem: this.tableFocus });
  }

  @action
  onTableChange(rows: Array<JournalModel>, updates: Array<JournalModel>) {
    this.tableUpdates = updates;
    this.SS({ data: rows });
  }

  @action
  async onGridRowFocusEvent(index: number, isDoubleClick: boolean = false) {
    this.gridFocusIndex = index;

    if (this.gridFocus?.gubun === '1') {
      return;
    }

    if (isDoubleClick) {
      await this.SS({ isVisibleDetail: true });
      this.retrieveDetail();
    }
  }

  @action
  async retrieveGrid() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e045_03',
      spjangcd: this.tableFocus?.spjangcd,
      actcd: this.tableFocus?.actcd,
      equpcd: this.tableFocus?.equpcd,
      seq: this.tableFocus?.seq,
      state: this.tableFocus?.state,
    });

    if (data?.items) {
      this.SS({
        dataGrid: data?.items.map((x: any) => new JournalGridModel(x)),
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getGridRowBackgroundColor(item: any): string {
    if (item.gubun === '1') {
      return 'var(--color-secondary)';
    }

    return '';
  }

  @action
  async onTableItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      ...this.tableFocus,
      [key]: value,
      itemname: key,
      data: value,
    }, this.tableFocus?.isNew);

    if (result) {
      rowUpdate(result);
    }
  }

  // Detail
  @action
  async onDetailRowFocusEvent(item: JournalModel | undefined, index: number) {
    if (!item) {
      this.tableFocusIndex = 0;
      return;
    }

    this.tableFocusIndex = index;
  }

  @action
  onTableDetailChange(rows: Array<JournalDetailModel>) {
    this.SS({ dataDetail: rows });
  }

  @action
  async retrieveDetail() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e045_04',
      spjangcd: this.gridFocus?.spjangcd,
      actcd: this.gridFocus?.actcd,
      equpcd: this.gridFocus?.equpcd,
      seq: this.gridFocus?.seq,
      gkccd: this.gridFocus?.gkccd,
    });

    if (data?.items) {
      await this.SS({
        dataDetail: data?.items.map((x: any) => new JournalDetailModel(x)),
      });

      this.tableDetail.current?.update(true);
    }
  }

  @action
  async onDetailSaveEvent() {
    if (this.tableFocus?.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_e045_04',
      items: this.state.dataDetail,
    }, false)) {
      this.tableDetail.current?.update(true);
    }
  }


  @computed
  get gridFocus(): JournalGridModel | undefined {
    if (this.state.dataGrid.length < this.gridFocusIndex) return undefined;
    return this.state.dataGrid[this.gridFocusIndex];
  }

  @computed
  get tableFocus(): JournalModel | undefined {
    if (this.state.data.length < this.tableFocusIndex) return undefined;
    return this.state.data[this.tableFocusIndex];
  }

  @computed
  get tableDetailFocus(): JournalDetailModel | undefined {
    if (this.state.dataDetail.length < this.tableDetailFocusIndex) return undefined;
    return this.state.dataDetail[this.tableDetailFocusIndex];
  }


  render() {
    return <JournalTemplate
      scope={this}
    />;
  }
}
