import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { Button } from '../../components/forms/Button';
import { TableLayout } from '../../components/layout/TableLayout';
import { CheckBox } from '../../components/forms/CheckBox';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';
import { SearchBinding } from '../../models/common';
import { ComboBox, ComboBoxModel } from '../../components/forms/ComboBox';
import { SubHeader } from '../../components';

interface ModalPushProps {
  modalStore: ModalStore;
}

@observer
export class ModalPush extends React.Component<ModalPushProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={800}
        height={600}
        isVisible={modalStore.isVisiblePush}
        onClose={() => modalStore.closePush()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SubHeader>
            <div>
              <span>모바일 알림전송</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => modalStore.onPushSave()}>
                확인
              </Button>
              <Button
                onClick={
                  async () => {
                    modalStore.closePush();
                  }}
              >
                닫기
              </Button>
            </FlexLayout>

          </SubHeader>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>상위부서</span>
              <ComboBox
                value={modalStore.pushSearchQueryUpdivicd}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '02', remark: '보수' },
                  { value: '01', remark: '총무' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => {
                  modalStore.pushSearchQueryUpdivicd = option.value;
                  modalStore.pushRetrieve();
                }}
              />
            </FlexLayout>

            <FlexLayout>
              <span>사업장</span>
              <ComboBox
                value={modalStore.pushSearchQuerySpjangcd}
                data={modalStore.pushSearchQuerySpjangcdData
                  .map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
                onSelect={(option) => {
                  modalStore.pushSearchQuerySpjangcd = option.value;
                  modalStore.pushRetrieve();
                }}
              />
            </FlexLayout>
          </SearchLayout>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>하위부서</span>
              <TextBox
                value={modalStore.pushSearchQueryDivicd}
                textAlign="center"
                onChange={(value) => { modalStore.pushSearchQueryDivicd = value; }}
                onEnter={() => modalStore.pushRetrieve()}
                bindSearchModal={new SearchBinding(
                  modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    modalStore.pushSearchQueryDivicd = item.cd;
                    modalStore.pushSearchQueryDivinm = item.cdnm;
                    modalStore.pushRetrieve();
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={modalStore.pushSearchQueryDivinm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>사용자</span>
              <TextBox
                value={modalStore.pushSearchQueryPerid}
                color='var(--color-red)'
                textAlign="center"
                onChange={(value) => { modalStore.pushSearchQueryPerid = value; }}
                onEnter={() => modalStore.pushRetrieve()}
                bindSearchModal={new SearchBinding(
                  modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    modalStore.pushSearchQueryPerid = item.cd;
                    modalStore.pushSearchQueryPernm = item.cdnm;
                    modalStore.pushRetrieve();
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={modalStore.pushSearchQueryPernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>
          </SearchLayout>

          <TableLayout
            ref={modalStore.pushTable}
            header={[
              {
                id: 'check',
                text: (
                  <FlexLayout justify="center" align="center">
                    <CheckBox
                      noMargin
                      value={modalStore.pushTotalCheck}
                      onChange={async (value) => modalStore
                        .pushUpdateCheckAllToggle(value)}
                    />
                  </FlexLayout>
                ),
                width: 18,
                render: (x, rowUpdate) => (
                  <FlexLayout justify="center" align="center">
                    <CheckBox
                      noMargin
                      value={x.isChecked}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '사번',
                width: 80,
              },
              {
                id: 'pernm',
                text: '사원명',
                width: 80,
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 80,
              },
              {
                id: 'handphone',
                text: '연락처',
                width: 120,
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={modalStore.pushData}
            onRowFocusEvent={(item) => {
              modalStore.pushSelected = item;
            }}
            onChange={(rows) => {
              modalStore.pushData = rows;
            }}
          />
        </FlexLayout>
      </ModalView>
    );
  }
}
