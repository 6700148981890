import {
  CheckBox,
  CircleBadge,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatNumberTextBox,
  TextBox,
} from '../../../../../components';
import { Global, TLHF } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { Date6 } from '../../../../../utils/time';
import EnrollmentSelfInspectModel from '../models/EnrollmentSelfInspectModel';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 점검부적합
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentModalSelfInspectTableHeader: TLHF<EnrollmentSelfInspectModel, Enrollment> = (scope) => ([
  {
    id: 'seq',
    text: '순서',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactcd',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">회사구분</FlexLayout>
        <FlexLayout align="start">점검월</FlexLayout>
      </FlexLayout>
    ),
    width: 90,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          {Date6.withDash(x.planmon)}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">처리자명</FlexLayout>
        <FlexLayout align="start">보조자명</FlexLayout>
      </FlexLayout>
    ),
    width: 90,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          {x.kcpernm}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          {x.kcpernm2}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '결과',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FlexLayout style={{ marginRight: 28, marginTop: 18 }}>
          <CircleBadge>
            {<span
              style={x.state === 'B' ? { backgroundColor: '#F2CB61' }
                : { backgroundColor: 'var(--color-red)' }}
            >
          {x.state}
        </span>}
          </CircleBadge>
        </FlexLayout>
        <FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'resultcd',
    text: '번호',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.resultcd}
      </FlexLayout>
    ),
  },
  {
    id: 'resultnm',
    text: '점검항목장치',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.resultnm}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '부적합내용',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'chk',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="center">선택</FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x, rowUpdate) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          <CheckBox
            value={x.chk === '1'}
            onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
          />
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'chk',
    text: (
      <FlexLayout isVertical={true}>

        <FlexLayout
          align="center"
          style={Global.STYLE_COLOR_RED}
        >
          품번
        </FlexLayout>
      </FlexLayout>
    ),
    width: 90,
    render: (x, rowUpdate, ref) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="start"
          justify="center"
        >
          <TextBox
            ref={ref}
            value={x.pcode}
            textAlign="center"
            onDoubleClick={async () => {
              const result = await scope.props.modalStore.openProductSelector(x.pcode);
              rowUpdate({
                pcode: result.phm_pcod,
                pname: result.phm_pnam,
                psize: result.phm_size,
                punit: result.phm_unit,
                uamt: result.phm_uamt || result.phm_samt,
              });
            }}
            onChange={(value) => rowUpdate({ pcode: value })}
            onEnter={async (value) => {
              const result = await scope.props.modalStore.openProductSelector(value);
              rowUpdate({
                pcode: result.phm_pcod,
                pname: result.phm_pnam,
                psize: result.phm_size,
                punit: result.phm_unit,
                uamt: result.phm_uamt || result.phm_samt,
              });
            }}
          />
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">품명</FlexLayout>
        <FlexLayout align="start">규격</FlexLayout>
      </FlexLayout>
    ),
    width: 170,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">{x.pname}</FlexLayout>
        <FlexLayout align="start">{x.psize}</FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'myn',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">유무상</FlexLayout>
        <FlexLayout align="start">수량</FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">
          <ComboBox
            textAlign="center"
            value={x.myn}
            data={[
              { value: '1', remark: '유상' },
              { value: '0', remark: '무상' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => rowUpdate({ myn: option.value })}
          />
        </FlexLayout>

        <FlexLayout align="start">
          <FormatNumberTextBox
            ref={ref}
            value={x.qty}
            textAlign="center"
            onChange={(v) => rowUpdate({ qty: v })}
          />
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">단가</FlexLayout>
        <FlexLayout align="start">견적금액</FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">
          <FormatNumberTextBox
            ref={ref}
            value={x.uamt}
            textAlign="right"
            onChange={(v) => rowUpdate({ uamt: v })}
          />
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="end"
        >
          {Format.number(x.costamt)}
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
