import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { LastMonthTemplate } from './LastMonth.template';
import { LastMonthModel } from './LastMonth.model';
import { GridLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface LastMonthState {
  // search
  yearmon: string;
  lastYearmon: string;
  searchGubun?: string;
  state?: string;
  wkactcd: string;
  wkactnm: string;

  // data
  data: Array<LastMonthModel>;
  focused?: LastMonthModel;

  // trail
  beamt_tot: string;
  misamt_tot: string;
  resuamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_input_da023w_02
 * @category 전월대비유지보수체크
 */
export class LastMonth extends PageComponent<PageProps, LastMonthState>
  implements PageToolEvents {
  updatedRows?: Array<LastMonthModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    let year: string | number;

    today.getMonth() === 0 ? year = today.getFullYear() - 1
      : year = today.getFullYear(); // 년도

    let month: string | number;
    today.getMonth() === 0 ? month = 12
      : month = today.getMonth(); // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      lastYearmon: `${year}${month}`,
      searchGubun: '11',
      state: '%',
      wkactcd: '',

      // data
      data: [],

      // trail
      beamt_tot: '',
      misamt_tot: '',
      resuamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        endmon: this.state.lastYearmon,
        state: this.state.state,
        wkactcd: this.state.wkactcd,
        gubun: this.state.searchGubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new LastMonthModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['actcd1', 'mismon'],
        [this.state.focused?.actcd1, this.state.focused?.mismon], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        this.grid.current?.setFocus(index);
      }

      await this.SS({
        beamt_tot: this.infinity?.box?.beamt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.yearmon,
      endmon: this.state.lastYearmon,
      state: this.state.state,
      wkactcd: this.state.wkactcd,
      gubun: this.state.searchGubun,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      endmon: this.state.lastYearmon,
      state: this.state.state,
      wkactcd: this.state.wkactcd,
      gubun: this.state.searchGubun,
    });
  }

  @action
  onRowFocusEvent(item: LastMonthModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<LastMonthModel>, updatedRows: Array<LastMonthModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <LastMonthTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
