import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { MonthOil } from '../MonthOil';
import { RecentGrid } from '../headers/RecentGrid';

/**
 * 화면 > 탭 A
 * @window w_tb_ca640_pa105_01w
 * @category 월유류비내역
 */
export const RecentTemplate: React.FC<TemplateProps<MonthOil>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <GridLayout
        ref={scope.grid}
        header={RecentGrid(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.recents}
        infinityHandler={scope.infinityRecents}
      />
    </FlexLayout>
  );
};
