/**
 * 모델
 * @window w_tb_e611_gosi
 * @category 승강기조회
 */
import { computed } from 'mobx';

export class RetrieveElevatorModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 건물명
   *
   * **data type** varchar(1)
   */
  buldnm: string;

  /**
   * 유지보수업체명
   *
   * **data type** varchar(1)
   */
  mntcpnynm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(1)
   */
  elevatorno: string;

  /**
   * 소재지1
   *
   * **data type** varchar(1)
   */
  address1: string;

  /**
   * 소재지2
   *
   * **data type** varchar(1)
   */
  address2: string;

  /**
   * 운행유효시작일
   *
   * **data type** varchar(1)
   */
  applcbedt: string;

  /**
   * 운행유효종료
   *
   * **data type** varchar(1)
   */
  applcendt: string;

  /**
   * 지역번호
   *
   * **data type** varchar(1)
   */
  areano: string;

  /**
   * 건물관리번호
   *
   * **data type** varchar(1)
   */
  buldmgtno1: string;

  /**
   * 건물관리번호2
   *
   * **data type** varchar(1)
   */
  buldmgtno2: string;

  /**
   * 건물용도명
   *
   * **data type** varchar(1)
   */
  buldprpos: string;

  /**
   * 지상운행층수
   *
   * **data type** varchar(1)
   */
  divgroundfloorcnt: string;

  /**
   * 지하운행층수
   *
   * **data type** varchar(1)
   */
  divundgrndfloorcnt: string;

  /**
   * 운행층수
   *
   * **data type** varchar(1)
   */
  shuttlefloorcnt: string;

  /**
   * 승강기구분명
   *
   * **data type** varchar(1)
   */
  elvtrdivnm: string;

  /**
   * 승강기형식명
   *
   * **data type** varchar(1)
   */
  elvtrformnm: string;

  /**
   * 승강기종류명
   *
   * **data type** varchar(1)
   */
  elvtrkindnm: string;

  /**
   * 승강기상태
   *
   * **data type** varchar(1)
   */
  elvtrstts: string;

  /**
   * 승강기관리번호
   *
   * **data type** varchar(1)
   */
  elvtrmgtno1: string;

  /**
   * 승강기관리번호
   *
   * **data type** varchar(1)
   */
  elvtrmgtno2: string;

  /**
   * 모델명
   *
   * **data type** varchar(1)
   */
  elvtrmodel: string;

  /**
   * 승강기재개일
   *
   * **data type** varchar(1)
   */
  elvtrresmptde: string;

  /**
   * 설치일자
   *
   * **data type** varchar(1)
   */
  installationde: string;

  /**
   * 설치장소
   *
   * **data type** varchar(1)
   */
  installationplace: string;

  /**
   * 검사기관
   *
   * **data type** varchar(1)
   */
  inspctlnstt: string;

  /**
   * 최종검사일
   *
   * **data type** varchar(1)
   */
  lastinspctde: string;

  /**
   * 최종검사종류
   *
   * **data type** varchar(1)
   */
  lastinspctkind: string;

  /**
   * 최종검사결과
   * **data type** varchar(1)
   */
  lastresultnm: string;

  /**
   * 승강기호기
   *
   * **data type** varchar(1)
   */
  elvtrasignno: string;

  /**
   * 제조업체명
   *
   * **data type** varchar(1)
   */
  manufacturename: string;

  /**
   * 제조업체번호
   *
   * **data type** varchar(1)
   */
  manufacturetelno: string;

  /**
   * 제조업체코드
   *
   * **data type** varchar(1)
   */
  mnfcturcpnycd: string;

  /**
   * 보수업체번호
   *
   * **data type** varchar(1)
   */
  mntcpnytelno: string;

  /**
   * 보수업체코드
   *
   * **data type** varchar(1)
   */
  mntcpnycd: string;

  /**
   * 관리주체명
   *
   * **data type** varchar(1)
   */
  partcpntnm: string;

  /**
   * 관리주체번호
   *
   * **data type** varchar(1)
   */
  partcpnttelno: string;

  /**
   * 최대정원
   *
   * **data type** varchar(1)
   */
  ratedcap: string;

  /**
   * 정격속도
   *
   * **data type** varchar(1)
   */
  ratedspeed: string;

  /**
   * 적재하중
   *
   * **data type** varchar(1)
   */
  liveload: string;

  /**
   * 운행관리자SN
   *
   * **data type** varchar(1)
   */
  shuttlemngrnm: string;

  /**
   * 운행관리자번호
   *
   * **data type** varchar(1)
   */
  shuttlemngrtelno: string;

  /**
   * 운행구간
   *
   * **data type** varchar(1)
   */
  shuttlesection: string;

  /**
   * 시/군/구코드
   *
   * **data type** varchar(1)
   */
  sigungucd: string;

  /**
   * 우편번호
   *
   * **data type** varchar(1)
   */
  zipcd1: string;

  /**
   * 우편번호
   *
   * **data type** varchar(1)
   */
  zipcd2: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.buldnm = data.buldnm || '';
    this.mntcpnynm = data.mntcpnynm || '';
    this.elevatorno = data.elevatorno || '';
    this.address1 = data.address1 || '';
    this.address2 = data.address2 || '';
    this.applcbedt = data.applcbedt || '';
    this.applcendt = data.applcendt || '';
    this.areano = data.areano || '';
    this.buldmgtno1 = data.buldmgtno1 || '';
    this.buldmgtno2 = data.buldmgtno2 || '';
    this.buldprpos = data.buldprpos || '';
    this.divgroundfloorcnt = data.divgroundfloorcnt || '';
    this.divundgrndfloorcnt = data.divundgrndfloorcnt || '';
    this.shuttlefloorcnt = data.shuttlefloorcnt || '';
    this.elvtrdivnm = data.elvtrdivnm || '';
    this.elvtrformnm = data.elvtrformnm || '';
    this.elvtrkindnm = data.elvtrkindnm || '';
    this.elvtrstts = data.elvtrstts || '';
    this.elvtrmgtno1 = data.elvtrmgtno1 || '';
    this.elvtrmgtno2 = data.elvtrmgtno2 || '';
    this.elvtrmodel = data.elvtrmodel || '';
    this.elvtrresmptde = data.elvtrresmptde || '';
    this.installationde = data.installationde || '';
    this.installationplace = data.installationplace || '';
    this.inspctlnstt = data.inspctlnstt || '';
    this.lastinspctde = data.lastinspctde || '';
    this.lastinspctkind = data.lastinspctkind || '';
    this.lastresultnm = data.lastresultnm || '';
    this.elvtrasignno = data.elvtrasignno || '';
    this.manufacturename = data.manufacturename || '';
    this.manufacturetelno = data.manufacturetelno || '';
    this.mnfcturcpnycd = data.mnfcturcpnycd || '';
    this.mntcpnytelno = data.mntcpnytelno || '';
    this.mntcpnycd = data.mntcpnycd || '';
    this.partcpntnm = data.partcpntnm || '';
    this.partcpnttelno = data.partcpnttelno || '';
    this.ratedcap = data.ratedcap || '';
    this.ratedspeed = data.ratedspeed || '';
    this.liveload = data.liveload || '';
    this.shuttlemngrnm = data.shuttlemngrnm || '';
    this.shuttlemngrtelno = data.shuttlemngrtelno || '';
    this.shuttlesection = data.shuttlesection || '';
    this.sigungucd = data.sigungucd || '';
    this.zipcd1 = data.zipcd1 || '';
    this.zipcd2 = data.zipcd2 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
