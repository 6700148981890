import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { SeverancePayTemplate } from './SeverancePay.template';
import { SeverancePayModel } from './SeverancePay.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { AuthRepository } from '../../../../repositories';
import { AuthModel } from '../../../../models/component';
import { Sha256 } from '../../../../utils/string';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils/layout';

interface SeverancePayState {
  // search
  year: string;
  perid: string;
  pernm: string;

  // data
  data: Array<SeverancePayModel>;
  focused?: SeverancePayModel;
  password: string;

  // modal
  modalVisible?: boolean;

  // trail
  total: string;
  retireamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb301_03
 * @category 퇴직금적립내역
 */
@inject('toolStore')
export class SeverancePay extends PageComponent<PageProps, SeverancePayState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      year: Today.year(),
      perid: '',
      pernm: '',

      // data
      data: [],
      password: '',

      // trail
      retireamt_tot: '',
      total: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.setState({ modalVisible: true });

    const { user } = this.props.publicStore;
    this.setState({
      perid: user.perid,
      pernm: user.pernm,
    });
  }

  @action
  async onKeyEvent(value: any) {
    const { publicStore } = this.props;

    const params = new AuthModel(
      `${publicStore.user.handphone}`.replace(/-/gi, ''),
      publicStore.user.userid,
      Sha256.make(value),
    );

    try {
      const response = await AuthRepository.login(params);

      if (response.status === 200) {
        // 성공
        this.setState({ modalVisible: false });
        this.onRetrieveEvent();
      } else {
        await ConfirmWarning.show('확인', '비밀번호가 틀렸습니다.');
      }
    } catch (_) {
      await ConfirmWarning.show('확인', '비밀번호가 틀렸습니다.');
    }
  }

  async onPageCloseEvent() {
    // @ts-ignore
    this.props.toolStore.onCloseHandler();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new SeverancePayModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['mon', 'perid'],
        [this.state.focused?.mon, this.state.focused?.perid], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
        this.onTrailRetrieve();
      }
    });
  }

  @action
  onRowFocusEvent(item: SeverancePayModel) {
    this.setState({ focused: item });
  }

  @action
  async onTrailRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        year: this.state.year,
        perid: this.state.perid,
      },
    );

    this.setState({
      retireamt_tot: data?.retireamt_tot || '0',
      total: data?.total || '0',
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      perid: this.state.perid,
    });
  }

  render() {
    return (
      <SeverancePayTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
