import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { MonthOil } from './MonthOil';
import { TabModel } from '../../../../models';
import { LastMonthTemplate } from './tabs/LastMonth.template';
import { RecentTemplate } from './tabs/Recent.template';

/**
 * 화면 > 탭
 * @window w_tb_ca640_pa105_01w
 * @category 월유류비내역
 */
export const MonthOilTab: Array<TabLayoutDataItem<MonthOil>> = [
  {
    tab: new TabModel('RE', FaCodeBranch, '최근6개월교통비/통행료현황'),
    template: (scope) => <RecentTemplate scope={scope} />,
  },
  {
    tab: new TabModel('LA', FaCodeBranch, '전월대비교통비/통행료현황'),
    template: (scope) => <LastMonthTemplate scope={scope} />,
  },
];
