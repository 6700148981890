import * as React from 'react';
import { action } from 'mobx';
import Swal from 'sweetalert2';
import {
  ConfirmType,
  ConfirmTypeName,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { UnitTemplate } from './Unit.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { UnitDetailModel, UnitModel } from './models';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface UnitState {
  // 검색 조건
  gubun: string;
  searchQuery: string;
  tableSearchQuery: string;
  perid: string;
  pernm: string;
  wkactcd: string;
  wkactnm: string;

  // 종류
  evcds: Array<any>;

  // 데이터
  focusedUnit?: UnitModel;
  unitList: Array<UnitModel>;
  focusedUnitDetail?: UnitDetailModel;
  unitDetailList: Array<UnitDetailModel>;
}

/**
 * 컨트롤러
 * @window w_tb_e611_035_03
 * @category 호기별점검항목등록
 */

export class Unit extends PageComponent<PageProps, UnitState>
  implements PageToolEvents {
  updatedRows?: Array<UnitModel>;

  updatedRows2?: Array<UnitDetailModel>;

  tableSearchPosition: number;

  table: React.RefObject<TableLayout> = React.createRef();

  detailTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    this.tableSearchPosition = -1;

    // state 기본값 정의
    this.state = props.state || {
      // 검색 조건
      gubun: '1',
      searchQuery: pageParams.searchQuery || '',
      tableSearchQuery: '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      wkactcd: '',
      wkactnm: '',

      // 종류
      evcds: [],

      // 데이터
      unitList: [],
      unitDetailList: [],
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams.searchQuery || this.state.searchQuery,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 점검기종
    const data = await api.dropdown('wf_dd_e030_new');
    if (!data) return;
    this.setState({ evcds: data?.items });

    await this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        wkactcd: this.state.wkactcd,
        gubun: this.state.gubun,
        ls_perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      async (items, next) => {
        await this.SS({
          unitList: [...this.state.unitList, ...items],
        });
        await this.table.current?.update(false);
        next && next();
      },
      async () => {
        await this.SS({
          unitList: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.unitList.length > 0) {
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      unitList: [],
      unitDetailList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['actcd', 'equpnm'],
        [this.state.focusedUnit?.actcd, this.state.focusedUnit?.equpnm], type) || 0;
      await this.table.current?.update();
      if (this.state.unitList && this.state.unitList.length > index) {
        this.table.current?.setFocus(index);
      }
    });
  }

  @action
  async searchTable() {
    let searched:boolean = false;

    for (let i = this.tableSearchPosition + 1; i < this.state.unitDetailList.length; i += 1) {
      const y:UnitDetailModel = this.state.unitDetailList[i];
      if ((y.resultnm + y.resultdtlnm).indexOf(this.state.tableSearchQuery) > -1) {
        this.tableSearchPosition = i;
        searched = true;
        break;
      }
    }

    if (searched) {
      this.detailTable.current?.setFocus(this.tableSearchPosition);
      this.detailTable.current?.update();
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title: '검색',
        html: '조회된 결과가 없습니다.\n처음부터 다시 검색할까요?',
        icon: ConfirmTypeName[ConfirmType.WARNING],
        showCancelButton: true,
        confirmButtonText: '처음부터 검색',
        cancelButtonText: '취소',
        reverseButtons: true,
      // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          this.tableSearchPosition = -1;
          this.searchTable();
        }
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows2,
    }, this.state.focusedUnit?.new === '1')) {
      this.updatedRows2 = [];
      this.onRowFocusEvent(this.state.focusedUnit!);
    }
  }

  @action
  onRowFocusEvent(item: UnitModel, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState(
      { focusedUnit: item, unitDetailList: [] },
      async () => {
        this.infinity2 = new InfinityRetrieve({
          actcd: this.state.focusedUnit?.actcd,
          equpcd: this.state.focusedUnit?.equpcd,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items, next) => {
          this.SS({
            unitDetailList: [
              ...this.state.unitDetailList,
              ...items?.map((x: any) => new UnitDetailModel(x)),
            ],
          });
          next && next();
          this.detailTable.current?.update(false);
          this.detailTable.current?.setFocus(0, 0);
        }, async () => {
          await this.SS({
            unitDetailList: [],
          });
          await this.infinity2?.retrieveAll();
          if (this.state.unitDetailList.length) {
            await this.detailTable.current?.update(true);
            this.detailTable.current?.setFocus(0, 0);
          }
        });

        await this.detailTable.current?.update(true);
        const index = await this.infinity2?.retrieveTo(['resultcd', 'resultnm'],
          [this.state.focusedUnitDetail?.resultcd, this.state.focusedUnitDetail?.resultnm], type, true) || 0;

        if (this.state.unitDetailList.length) {
          this.detailTable.current?.setFocus(index);
        }
      },
    );
  }

  @action
  onDetailRowFocusEvent(item:UnitDetailModel) {
    // if (item.new === '1') return;
    this.setState({ focusedUnitDetail: item });
  }


  // 결재상신
  @action
  async createJournal() {
    const { actionStore: api } = this.props;

    if (this.state.unitList.length > 0) {
      ConfirmWarning.show('확인', '생성할 점검내역이 없습니다!');
    }

    if (this.state.focusedUnit?.evcd === '') {
      ConfirmWarning.show('확인', '생성할 점검기종이 없습니다!, 먼저 현장등록에서 점검기종을 등록하세요!');
    }

    if (this.state.unitDetailList.length > 0) {
      if (!await Confirm.show('확인', '이미 생성한 점검일지가 있습니다!\n 삭제하고 다시 생성 하시겠습니까?', ConfirmType.QUESTION)) {
        return;
      }
    }

    const data = await api.fxExec('wb_create_gosi', {
      actcd: this.state.focusedUnit?.actcd,
      equpcd: this.state.focusedUnit?.equpcd,
      evcd: this.state.focusedUnit?.evcd,
      elno: this.state.focusedUnit?.elno,
    });
    data && this.onRowFocusEvent(this.state.focusedUnit!);
  }

  @action
  onUpdatedRows(rows: Array<UnitModel>, updatedRows: Array<UnitModel>) {
    this.updatedRows = updatedRows;
    this.setState({ unitList: rows });
  }

  @action
  onUpdatedDetailRows(rows2: Array<UnitDetailModel>, updatedRows2: Array<UnitDetailModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ unitDetailList: rows2 });
  }

  render() {
    return (
      <UnitTemplate scope={this}/>
    );
  }
}
