import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { PaymentCalculateTemplate } from './PaymentCalculate.template';
import { PaymentCalculateModel } from './PaymentCalculate.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import Confirm from '../../../../utils/confirm/Confirm';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Format } from '../../../../utils/string';

interface PaymentCalculateState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;
  gubun: string;

  // data
  data: Array<PaymentCalculateModel>;
  focused?: PaymentCalculateModel;
  focusIndex: number;

  focusedUpdate?: PaymentCalculateModel;
  nameState: string;

  // trail
  samt_tot: string;
  tamt_tot: string;
  mamt_tot: string;
  iamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca644
 * @category 가지급정산등록
 */
export class PaymentCalculate extends PageComponent<PageProps, PaymentCalculateState>
  implements PageToolEvents {
  updatedRows?: Array<PaymentCalculateModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // search
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      gubun: '%',

      // data
      data: [],
      focusIndex: 0,

      // trail
      samt_tot: '0',
      tamt_tot: '0',
      mamt_tot: '0',
      iamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new PaymentCalculateModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 10);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['inperid', 'acccd'],
        [this.state.focused?.inperid, this.state.focused?.acccd], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 10);
      }
      this.SS({
        samt_tot: this.infinity?.box?.samt_tot || '0',
        tamt_tot: this.infinity?.box?.tamt_tot || '0',
        mamt_tot: this.infinity?.box?.mamt_tot || '0',
        iamt_tot: this.infinity?.box?.iamt_tot || '0',
      });
      await this.table.current?.update(true);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      new: '0',
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
    });
  }

  @action
  async itemchanged(item: any, value: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_list_itemchanged', {
      itemname: item,
      data: value,
      samt: this.state.focused?.samt,
      tamt: item === 'tamt' ? value : this.state.focused?.tamt,
      mamt: item === 'mamt' ? value : this.state.focused?.mamt,
    });
    const items = this.state.focused;
    // @ts-ignore
    items.iamt = data?.iamt;

    const chkData: PaymentCalculateModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.yyyymm === this.state.focused?.yyyymm && x?.samt === this.state.focused?.samt) {
        chkData.push(new PaymentCalculateModel({
          ...x,
          iamt: data?.iamt,
        }));
      } else {
        chkData.push(new PaymentCalculateModel({
          ...x,
        }));
      }
    });
    data && this.setState({
      data: chkData,
      focused: items,
    });
    await this.table.current?.update();
  }

  @action
  async onBtnSaveEvent() {
    const { actionStore: api } = this.props;
    // @ts-ignore
    const tamt = this.updatedRows[0]?.tamt;
    // @ts-ignore
    const mamt = this.updatedRows[0]?.mamt;
    // @ts-ignore
    const iamt = this.updatedRows[0]?.iamt;

    const sum = parseInt(tamt, 10) + parseInt(mamt, 10) + parseInt(iamt, 10);
    if (this.state.focused?.samt !== String(sum)) {
      ConfirmWarning.show('확인', '지급금액과 정산+잔액금액이 다릅니다.');
      return;
    }

    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인', `가지급정산 취소시 비용/지급도 같이 처리됩니다.
     지급일자: ${this.state.focused?.snddate}
     지급번호: ${this.state.focused?.sndnum} 
     사용자: ${this.state.focused?.pernm}
     정산금액: ${mamt}
     정산내역을 취소하시겠습니까?`, ConfirmType.QUESTION);
    if (warning === false) return;

    await api.fxExec(
      // @ts-ignore
      'wb_mijsave', this.updatedRows[0],
    );
    this.onRetrieveEvent();
  }

  @action
  async onBtnDelEvent() {
    const { actionStore: api } = this.props;

    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인', `가지급정산 취소시 비용/지급도 같이 취소됩니다.
     지급일자: ${this.state.focused?.snddate}
     지급번호: ${this.state.focused?.sndnum} 
     사용자: ${this.state.focused?.pernm}
     정산금액: ${this.state.focused?.mamt}
     정산내역을 취소하시겠습니까?`, ConfirmType.QUESTION);
    if (warning === false) return;

    await api.fxExec(
      'wb_mijdelete',
      {
        snddate: this.state.focused?.snddate,
        sndnum: this.state.focused?.sndnum,
        perid: this.state.focused?.perid,
        pernm: this.state.focused?.pernm,
        divicd: this.state.focused?.divicd,
        divinm: this.state.focused?.divinm,
        samt: this.state.focused?.samt,
        tamt: this.state.focused?.tamt,
        mamt: this.state.focused?.mamt,
        iamt: this.state.focused?.iamt,
        remark: this.state.focused?.remark,
        amtflag: this.state.focused?.amtflag,
        bankcd: this.state.focused?.bankcd,
        bigo: this.state.focused?.bigo,
        projno: this.state.focused?.projno,
        projnm: this.state.focused?.projnm,
        actcd: this.state.focused?.actcd,
        actnm: this.state.focused?.actnm,
      },
    );
    await this.onRetrieveEvent();
  }

  @action
  onRowFocusEvent(item: PaymentCalculateModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate) {
    const samt = Format.toNumber(item.samt);
    const tamt = Format.toNumber(item.tamt);
    const mamt = Format.toNumber(item.mamt);
    let iamt = Format.toNumber(item.iamt);

    iamt = Math.round(samt - (mamt + tamt));

    rowUpdate({
      ...item,
      tamt: tamt.toString(),
      mamt: mamt.toString(),
      iamt: iamt.toString(),
    });
  }

  @action
  async onUpdatedRows(rows: Array<PaymentCalculateModel>, updatedRows: Array<PaymentCalculateModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <PaymentCalculateTemplate
        scope={this}
      />
    );
  }
}
