import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca642w_14
 * @category 매입통계내역
 */
export class PurchasingStatisticsModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangnm: string;

  /**
   * 보수매입
   *
   * **data type** varchar(8)
   */
  readonly mijamt_0: string;

  /**
   * 보수지급
   *
   * **data type** varchar(8)
   */
  readonly sndamt_0: string;

  /**
   * 보수미지급
   *
   * **data type** varchar(8)
   */
  readonly iamt_0: string;

  /**
   * 설치매입
   *
   * **data type** varchar(8)
   */
  readonly mijamt_1: string;

  /**
   * 설치지급
   *
   * **data type** varchar(8)
   */
  readonly sndamt_1: string;

  /**
   * 설치미지급
   *
   * **data type** varchar(8)
   */
  readonly iamt_1: string;

  /**
   * 제조매입
   *
   * **data type** varchar(8)
   */
  readonly mijamt_2: string;

  /**
   * 제조지급
   *
   * **data type** varchar(8)
   */
  readonly sndamt_2: string;

  /**
   * 제조미지급
   *
   * **data type** varchar(8)
   */
  readonly iamt_2: string;

  /**
   * 기타매입
   *
   * **data type** varchar(8)
   */
  readonly mijamt_5: string;

  /**
   * 기타지급
   *
   * **data type** varchar(8)
   */
  readonly sndamt_5: string;

  /**
   * 기타미지급
   *
   * **data type** varchar(8)
   */
  readonly iamt_5: string;

  /**
   * 총매입
   *
   * **data type** varchar(8)
   */
  readonly mijamt_tot: string;

  /**
   * 총지급
   *
   * **data type** varchar(8)
   */
  readonly sndamt_tot: string;

  /**
   * 총미지급
   *
   * **data type** varchar(8)
   */
  readonly iamt_tot: string;

  readonly beamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.mijamt_0 = data.mijamt_0 || '';
    this.sndamt_0 = data.sndamt_0 || '';
    this.iamt_0 = data.iamt_0 || '';
    this.mijamt_1 = data.mijamt_1 || '';
    this.sndamt_1 = data.sndamt_1 || '';
    this.iamt_1 = data.iamt_1 || '';
    this.mijamt_2 = data.mijamt_2 || '';
    this.sndamt_2 = data.sndamt_2 || '';
    this.iamt_2 = data.iamt_2 || '';
    this.mijamt_5 = data.mijamt_5 || '';
    this.sndamt_5 = data.sndamt_5 || '';
    this.iamt_5 = data.iamt_5 || '';
    this.mijamt_tot = data.mijamt_tot || '';
    this.sndamt_tot = data.sndamt_tot || '';
    this.iamt_tot = data.iamt_tot || '';
    this.beamt = data.beamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}

