import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  TelnumTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { CheckDepartment } from './CheckDepartment';


/**
 * 화면
 * @window w_tb_e027
 * @category 검사담당부서 등록
 */
export const CheckDepartmentTemplate: React.FC<TemplateProps<CheckDepartment>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="검사담당부서, 비고, 전화번호, 팩스번호로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'gareacd',
            text: '코드',
            width: 40,
          },
          {
            id: 'gareanm',
            text: '검사담당부서명',
            width: 100,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.gareanm}
                onChange={(value) => rowUpdate({ gareanm: value })}
              />
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 600,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.remark}
                onChange={(value) => rowUpdate({ remark: value })}
              />
            ),
          },
          {
            id: 'tellnum',
            text: '전화번호',
            width: 100,
            render: (x, rowUpdate, ref) => (
              <TelnumTextBox
                ref={ref}
                value={x.telnum}
                onChange={(value) => rowUpdate({ telnum: value })}
              />
            ),
          },
          {
            id: 'faxnum',
            text: '팩스',
            width: 100,
            render: (x, rowUpdate, ref) => (
              <TelnumTextBox
                ref={ref}
                value={x.faxnum}
                onChange={(value) => rowUpdate({ faxnum: value })}
              />
            ),
          },
          {
            id: 'useyn',
            text: '사용유무',
            width: 75,
            render: (x, rowUpdate) => (
              <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })}/>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.data}
      />
    </FlexLayout>
  );
};
