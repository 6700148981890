import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Duty } from './Duty';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_e512w_01
 * @category 당직현황
 */
export const DutyTemplate: React.FC<TemplateProps<Duty>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => {
                setData({ yearmon: value },
                  () => scope.onRetrieveEvent());
              }}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.divinm}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              onEnter={() => scope.onRetrieveEvent()}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  scope.setState({
                    perid: item.cd,
                    pernm: item.cdnm,
                  }, () => scope.onRetrieveEvent());
                },
              )}
              isDisabledTrackingStateChange={true}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              textAlign="center"
              isDisabledTrackingStateChange={true}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'no',
            text: 'No',
            width: 60,
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                합계
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '사원명',
            width: 100,
          },
          {
            id: 'rspnm',
            text: '직위',
            width: 100,
          },
          {
            id: 'plantot',
            text: '당직일',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-blue)' }}>
                  {scope.state.plantot_tot}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-blue)' }}>
                  {scope.state.tot_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-blue)' }}>
                  {x.plantot}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-blue)' }}>
                  {x.tot}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'planteltot',
            text: '통신일',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: '#7F7A2C' }}>
                  {scope.state.planteltot_tot}
                </div>

                <div>/</div>

                <div style={{ color: '#7F7A2C' }}>
                  {scope.state.teldaytot_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: '#7F7A2C' }}>
                  {x.planteltot}
                </div>

                <div>/</div>

                <div style={{ color: '#7F7A2C' }}>
                  {x.teldaytot}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'plantotweeksat',
            text: '당직(토요일)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-blue)' }}>
                  {scope.state.plantotweeksat_tot}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-blue)' }}>
                  {scope.state.totweeksat_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-blue)' }}>
                  {x.plantotweeksat}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-blue)' }}>
                  {x.totweeksat}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'plantotweeksun',
            text: '당직(일요일)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-red)' }}>
                  {scope.state.plantotweeksun_tot}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-red)' }}>
                  {scope.state.totweeksun_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-red)' }}>
                  {x.plantotweeksun}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-red)' }}>
                  {x.totweeksun}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'plantotweek',
            text: '당직(평일)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div>
                  {scope.state.plantotweek_tot}
                </div>

                <div>/</div>

                <div>
                  {scope.state.totweek_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div>
                  {x.plantotweek}
                </div>

                <div>/</div>

                <div>
                  {x.totweek}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'plantot21',
            text: '21시간(토요일)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-blue)' }}>
                  {scope.state.plantot21_tot}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-blue)' }}>
                  {scope.state.tot21_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-blue)' }}>
                  {x.plantot21}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-blue)' }}>
                  {x.tot21}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'plantot25',
            text: '25시간(일요일)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-red)' }}>
                  {scope.state.plantot25_tot}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-red)' }}>
                  {scope.state.tot25_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: 'var(--color-red)' }}>
                  {x.plantot25}
                </div>

                <div>/</div>

                <div style={{ color: 'var(--color-red)' }}>
                  {x.tot25}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'plantot6',
            text: '6시간(평일)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div>
                  {scope.state.plantot6_tot}
                </div>

                <div>/</div>

                <div>
                  {scope.state.tot6_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div>
                  {x.plantot6}
                </div>

                <div>/</div>

                <div>
                  {x.tot6}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'totplantot',
            text: '합계(일수)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: '#5D0000' }}>
                  {scope.state.totplantot_tot}
                </div>

                <div>/</div>

                <div style={{ color: '#5D0000' }}>
                  {scope.state.totcomptot_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: '#5D0000' }}>
                  {x.totplantot}
                </div>

                <div>/</div>

                <div style={{ color: '#5D0000' }}>
                  {x.totcomptot}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'totplantottime',
            text: '합계(시간)',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: '#00006F' }}>
                  {scope.state.totplantottime_tot}
                </div>

                <div>/</div>

                <div style={{ color: '#00006F' }}>
                  {scope.state.totcomptottime_tot}
                </div>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <div style={{ color: '#00006F' }}>
                  {x.totplantottime}
                </div>

                <div>/</div>

                <div style={{ color: '#00006F' }}>
                  {x.totcomptottime}
                </div>
              </FlexLayout>
            ),
          },
          {
            id: 'date1',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday1 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday1 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  1일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday1}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
               >
                {scope.state.date1_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date1 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date1}
              </FlexLayout>
            ),
          },
          {
            id: 'date2',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday2 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday2 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  2일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday2}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date2_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date2 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date2}
              </FlexLayout>
            ),
          },
          {
            id: 'date3',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday3 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday3 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  3일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday3}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date3_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date3 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date3}
              </FlexLayout>
            ),
          },
          {
            id: 'date4',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday4 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday4 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  4일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday4}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date4_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date4 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date4}
              </FlexLayout>
            ),
          },
          {
            id: 'date5',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday5 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday5 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  5일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday5}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date5_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date5 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date5}
              </FlexLayout>
            ),
          },
          {
            id: 'date6',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday6 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday6 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  6일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday6}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date6_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date6 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date6}
              </FlexLayout>
            ),
          },
          {
            id: 'date7',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday7 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday7 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  7일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday7}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date7_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date7 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date7}
              </FlexLayout>
            ),
          },
          {
            id: 'date8',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday8 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday8 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  8일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday8}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date8_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date8 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date8}
              </FlexLayout>
            ),
          },
          {
            id: 'date9',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday9 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday9 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  9일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday9}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date9_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date9 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date9}
              </FlexLayout>
            ),
          },
          {
            id: 'date10',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday10 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday10 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  10일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday10}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date10_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date10 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date10}
              </FlexLayout>
            ),
          },
          {
            id: 'date11',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday11 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday11 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  11일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday11}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date11_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date11 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date11}
              </FlexLayout>
            ),
          },
          {
            id: 'date12',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday12 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday12 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  12일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday12}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date12_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date12 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date12}
              </FlexLayout>
            ),
          },
          {
            id: 'date13',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday13 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday13 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  13일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday13}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date13_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date13 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date13}
              </FlexLayout>
            ),
          },
          {
            id: 'date14',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday14 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday14 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  14일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday14}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date14_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date14 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date14}
              </FlexLayout>
            ),
          },
          {
            id: 'date15',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday15 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday15 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  15일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday15}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date15_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date15 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date15}
              </FlexLayout>
            ),
          },
          {
            id: 'date16',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday16 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday16 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  16일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday16}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date16_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date16 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date16}
              </FlexLayout>
            ),
          },
          {
            id: 'date17',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday17 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday17 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  17일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday17}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date17_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date17 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date17}
              </FlexLayout>
            ),
          },
          {
            id: 'date18',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday18 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday18 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  18일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday18}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date18_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date18 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date18}
              </FlexLayout>
            ),
          },
          {
            id: 'date19',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday19 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday19 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  19일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday19}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date19_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date19 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date19}
              </FlexLayout>
            ),
          },
          {
            id: 'date20',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday20 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday20 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  20일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday20}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date10_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date20 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date20}
              </FlexLayout>
            ),
          },
          {
            id: 'date21',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday21 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday21 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  21일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday21}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date21_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date21 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date21}
              </FlexLayout>
            ),
          },
          {
            id: 'date22',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday22 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday22 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  22일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday22}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date22_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date22 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date22}
              </FlexLayout>
            ),
          },
          {
            id: 'date23',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday23 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday23 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  23일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday23}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date23_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date23 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date23}
              </FlexLayout>
            ),
          },
          {
            id: 'date24',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday24 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday24 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  24일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday24}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date24_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date24 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date24}
              </FlexLayout>
            ),
          },
          {
            id: 'date25',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday25 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday25 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  25일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday25}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date25_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date25 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date25}
              </FlexLayout>
            ),
          },
          {
            id: 'date26',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday26 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday26 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  26일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday26}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date26_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date26 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date26}
              </FlexLayout>
            ),
          },
          {
            id: 'date27',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday27 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday27 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  27일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday27}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date27_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date27 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date27}
              </FlexLayout>
            ),
          },
          {
            id: 'date28',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday28 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday28 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  28일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday28}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date28_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date28 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date28}
              </FlexLayout>
            ),
          },
          {
            id: 'date29',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday29 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday29 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  29일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday29}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date29_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date29 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date29}
              </FlexLayout>
            ),
          },
          {
            id: 'date30',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday30 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday30 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  30일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday30}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date30_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date30 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date30}
              </FlexLayout>
            ),
          },
          {
            id: 'date31',
            text: (
              <FlexLayout
                isVertical={true}
                style={{
                  color: scope.state.data[0]?.weekday31 === '일' ? 'var(--color-red)'
                    : scope.state.data[0]?.weekday31 === '토' ? 'var(--color-blue)'
                      : '',
                }}
              >
                <FlexLayout align="start">
                  31일
                </FlexLayout>
                <FlexLayout align="end">
                  {scope.state.data[0]?.weekday31}
                </FlexLayout>
              </FlexLayout>
            ),
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#8106aa' }}
              >
                {scope.state.date31_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.date31 === 'X' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.date31}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      />
    </FlexLayout>
  );
};
