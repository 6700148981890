import Swal from 'sweetalert2';
import { Fix } from '../string';

export default class Fail {
  static show(title: string, text: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons.fire({
      title,
      html: Fix.newline(text).split('\n').join('<BR/>'),
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: '확인',
      reverseButtons: true,
    });
  }
}
