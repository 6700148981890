import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ResultTemplate } from './Result.template';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ResultModel } from './models/Result.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface ResultState {

  // 검색조건
  searchQuery: string; // 검색어
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  gubun: string;
  result: string;
  state: string;

  resultList: Array<ResultModel>;
  data: ResultModel;

  fileDetailModal: boolean; // 첨부파일

  // trail
  cnt_tot: string;
  compcnt_tot: string;
  miscnt_tot: string;
  notcnt_tot: string;
  result01_tot: string;
  result02_tot: string;
  result03_tot: string;

  // modal
  isModal: boolean;
  popupData: Array<ResultModel>;
}

/**
 * 컨트롤러
 * @window w_tb_e075w
 * @category 검사결과현황
 */
export class Result extends PageComponent<PageProps, ResultState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  updatedRows?: Array<ResultModel>;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // 말일
    const lasyDay = String(new Date(Number(year), Number(month), 0).getDate());

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${lasyDay}`,
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      gubun: '%',
      result: '%',
      state: '%',
      resultList: [],
      data: new ResultModel(),
      fileDetailModal: false,

      cnt_tot: '',
      compcnt_tot: '',
      miscnt_tot: '',
      notcnt_tot: '',
      result01_tot: '',
      result02_tot: '',
      result03_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    // const show = await ConfirmWarning.show('확인', '검사는 정보센터 보안이슈로 협역이 될때까지 정보센터에서 확인부탁드립니다. 정보센터로 이동합니다.');
    // const data = true;
    //
    // if (data) {
    //   await show;
    //   this.openWebSite2();
    // } else {
    //   await this.onRetrieveEvent();
    // }

    await this.onRetrieveEvent();
  }

  openWebSite2() {
    window.open('https://www.elevator.go.kr/main/Login.do?menuId=00000000');
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        state: this.state.state,
        result: this.state.result,
        divicd: this.state.divicd,
        perid: this.state.perid,
        gubun: this.state.gubun,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          resultList: [...this.state.resultList, ...items],
        }, () => {
          this.grid.current?.setFocus(0);
        });
      },
      async () => {
        await this.SS({
          data: new ResultModel(),
          resultList: [],
        });
        this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      data: new ResultModel(),
      resultList: [],
    });

    await this.infinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot || '0',
      compcnt_tot: this.infinity?.box?.compcnt_tot || '0',
      miscnt_tot: this.infinity?.box?.miscnt_tot || '0',
      notcnt_tot: this.infinity?.box?.notcnt_tot || '0',
      result01_tot: this.infinity?.box?.result01_tot || '0',
      result02_tot: this.infinity?.box?.result02_tot || '0',
      result03_tot: this.infinity?.box?.result03_tot || '0',
    });
  }

  @action
  async onModalOpenEvenet() {
    await this.onModalRetrieveEvent();
  }

  @action
  async onModalRetrieveEvent() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve(
      {
        sub: 'w_popup_e073_fail',
        ...this.state.data,
      },
    );

    if (data?.items) {
      this.setState({
        isModal: true,
        popupData: data?.items,
      });
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.resultList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      state: this.state.state,
      result: this.state.result,
      divicd: this.state.divicd,
      perid: this.state.perid,
      gubun: this.state.gubun,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.resultList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      state: this.state.state,
      result: this.state.result,
      divicd: this.state.divicd,
      perid: this.state.perid,
      gubun: this.state.gubun,
    });
  }

  @action
  onRowFocusEvent(item:any) {
    this.setState({ data: item });
  }

  render() {
    return (
      <ResultTemplate scope={this}/>
    );
  }
}
