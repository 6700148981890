import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
} from '../../../../constants';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { ContractTemplate } from './Contract.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ContractModel } from './models';
import { PageComponent } from '../../../../utils/layout';
import { DateStabilizer } from '../../../../utils/time';

export enum ContractItemChangeTypes {
  BILDYD,
  EVERCD,
  EVERNIGHT,
  CONTGUBUN,
  AMT,
  ADDYN,
  YEARFLAG,
  STDATE,
  ENDDATE,
  ACTCD,
  CLTCD,
}

export enum ContractItemChangeTypeNames {
  bildyd,
  evercd,
  evernight,
  contgubun,
  amt,
  addyn,
  yearflag,
  stdate,
  enddate,
  actcd,
  cltcd,
}

interface ContractState {

  // 검색 조건
  stdate: string;
  enddate: string;
  gubun: string;
  perid: string;
  pernm: string;
  searchQuery: string;

  // 데이터 객체
  focusedContract?: ContractModel;
  data: ContractModel;
  lastNewData: ContractModel;
  contractList: Array<ContractModel>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  focusIndex: number;
  chk: string;
  custcd: string;
  spjangcd: string;
  spjangnm: string;
  total: string;

  evercds: Array<any>;
  bildyds: Array<any>;
  contbilds: Array<any>;

  kakaoyn: string;
}

/**
 * 컨트롤러
 * @window w_tb_e101_elec
 * @category 전자계약서
 */
export class Contract extends PageComponent<PageProps, ContractState>
  implements PageToolEvents {
  updatedRows?: Array<ContractModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      isReported: false,
      fileDetailModal: false,
      contractList: [],
      data: new ContractModel(),
      focusIndex: 0,
      chk: '0',
      evercds: [],
      bildyds: [],
      contbilds: [],

      kakaoyn: '0',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          contmon: this.state.data.contmon,
          seq: this.state.data.seq,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          actnm: this.state.data.actnm,
        }, false,
      );

      data && this.setState({
        data: new ContractModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    const openData = await api.fxExec('open');
    await this.setState({
      kakaoyn: openData?.alim_kakao_useyn,
    });

    if (user.custcd !== 'dmyong_g') {
      // 해당기능은 커스텀상품(유료서비스)입니다
      // 엘맨소프트에 서비스 이용 문의 바랍니다.
      // 문구 사용 할 수 있어서 주석처리 해놨음
      ConfirmWarning.show('확인', '해당기능은 커스텀상품(유료서비스)입니다 \n엘맨소프트에 서비스 이용 문의 바랍니다');
      this.props.publicStore?.toolStore?.onCloseHandler();
      return;
    }

    // 건물용도
    const data = await api.dropdown('wf_dd_e029');
    if (!data) return;
    this.setState({ bildyds: data.items });

    // 유지관리비별구분
    const data2 = await api.dropdown('wf_dd_ca510_028_01');
    if (!data2) return;
    this.setState({ contbilds: data2.items });

    const data3 = await api.dropdown('wf_dd_e101_aver', { contbild: this.state.data.contbild });
    if (!data3) return;
    this.setState({ evercds: data3.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    if (user.custcd !== 'dmyong_g') {
      ConfirmWarning.show('확인', '해당기능은 커스텀상품(유료서비스)입니다 \n엘맨소프트에 서비스 이용 문의 바랍니다');
      this.props.publicStore?.toolStore?.onCloseHandler();
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          contractList: [...this.state.contractList, ...items.map((x: any, index: number) => (
            new ContractModel({ ...x, page: (items.length - index).toString() })))],
          total: items.length.toString(),
        }, next);
      },
      async () => {
        await this.SS({
          contractList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.contractList && this.state.contractList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      contractList: [],
    }, async () => {
      const data = await this.infinity?.retrieveAll();
      this.state.contractList.length > 0
      && this.onRowFocusEvent(this.state.contractList[this.state.focusIndex], this.state.focusIndex);
      if (this.state.chk === '1') {
        this.grid.current?.setFocus(this.state.focusIndex);
        this.setState({ chk: '0' });
      } else {
        this.grid.current?.setFocus(0);
      }
      data && this.setState({ total: data?.total });
    });
  }

  @action
  onRowFocusEvent(item: ContractModel, index: number) {
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedContract: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            contmon: this.state.focusedContract?.contmon,
            seq: this.state.focusedContract?.seq,
          });

        if (data) {
          this.setState({
            data: new ContractModel({
              ...data,
            }),
          });
        }

        // 결재상신 여부확인
        if (this.state.focusedContract?.appgubun === null
          || this.state.focusedContract?.appgubun === ''
          || this.state.focusedContract?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedContract!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.ELECCONTRACT, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedContract!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.ELECCONTRACT);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.ELECCONTRACT.toString(),
      ...this.state.data,
      appnum: this.state.data.appnum,
      appgubun: this.state.data.appgubun,
      appdate: this.state.data.appdate,
      contmon: this.state.data.contmon,
      seq: this.state.data.seq,
      actnm: this.state.data.actnm,
      perid: this.state.data.inperid,
      refer: this.state.data.refer,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { user } = this.props.publicStore;

    if (user.custcd !== 'dmyong_g') {
      ConfirmWarning.show('확인', '해당기능은 커스텀상품(유료서비스)입니다 \n엘맨소프트에 서비스 이용 문의 바랍니다');
      this.props.publicStore?.toolStore?.onCloseHandler();
      return;
    }

    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.data.new,
      custcd: this.state.data.custcd,
      spjangcd: this.state.data.spjangcd,
      contmon: this.state.data.contmon,
      seq: this.state.data.seq,
      contdeliv: this.state.data.contdeliv,
      contgubun: this.state.data.contgubun,
      contgubunnm: this.state.data.contgubunnm,
      becontgubun: this.state.data.becontgubun,
      actcd: this.state.data.actcd,
      actnm: this.state.data.actnm,
      actnm_mini: this.state.data.actnm_mini,
      zipcode: this.state.data.zipcode,
      address: this.state.data.address,
      address2: this.state.data.address2,
      bildyd: this.state.data.bildyd,
      contbild: this.state.data.contbild,
      evercd: this.state.data.evercd,
      evernight: this.state.data.evernight,
      contdate: this.state.data.contdate,
      stdate: this.state.data.stdate,
      enddate: this.state.data.enddate,
      addyn: this.state.data.addyn,
      mdnm1: this.state.data.mdnm1,
      sizecd1: this.state.data.sizecd1,
      bigo1: this.state.data.bigo1,
      mdnm2: this.state.data.mdnm2,
      sizecd2: this.state.data.sizecd2,
      bigo2: this.state.data.bigo2,
      mdnm3: this.state.data.mdnm3,
      sizecd3: this.state.data.sizecd3,
      bigo3: this.state.data.bigo3,
      mdnm4: this.state.data.mdnm4,
      sizecd4: this.state.data.sizecd4,
      bigo4: this.state.data.bigo4,
      mdnm5: this.state.data.mdnm5,
      sizecd5: this.state.data.sizecd5,
      bigo5: this.state.data.bigo5,
      mdnm6: this.state.data.mdnm6,
      sizecd6: this.state.data.sizecd6,
      bigo6: this.state.data.bigo6,
      mdnm7: this.state.data.mdnm7,
      sizecd7: this.state.data.sizecd7,
      bigo7: this.state.data.bigo7,
      mdnm8: this.state.data.mdnm8,
      sizecd8: this.state.data.sizecd8,
      bigo8: this.state.data.bigo8,
      cltadres: this.state.data.cltadres,
      cltadres2: this.state.data.cltadres2,
      clttelnum: this.state.data.clttelnum,
      clttaxmail: this.state.data.clttaxmail,
      dedate: this.state.data.dedate,
      clthpnum: this.state.data.clthpnum,
      zipcd: this.state.data.zipcd,
      cltcd: this.state.data.cltcd,
      cltnm: this.state.data.cltnm,
      cltsaupnum: this.state.data.cltsaupnum,
      cltprenm: this.state.data.cltprenm,
      actpernm: this.state.data.actpernm,
      indiv_agree: this.state.data.indiv_agree,
      number_agree: this.state.data.number_agree,
      cms_banknm: this.state.data.cms_banknm,
      cms_accnum: this.state.data.cms_accnum,
      cms_accname: this.state.data.cms_accname,
      cms_saupnum: this.state.data.cms_saupnum,
      cms_dedate: this.state.data.cms_dedate,
      cms_name: this.state.data.cms_name,
      cms_indiv_agree: this.state.data.cms_indiv_agree,
      cms_number_agree: this.state.data.cms_number_agree,
      cms_number_flag: this.state.data.cms_number_flag,
      remark: this.state.data.remark,
      okflag: this.state.data.okflag,
      appnum: this.state.data.appnum,
      appdate: this.state.data.appdate,
      appgubun: this.state.data.appgubun,
      appperid: this.state.data.appperid,
      inperid: this.state.data.inperid,
      pernm: this.state.data.pernm,
      appremark: this.state.data.appremark,
      divinm: this.state.data.divinm,
      indate: this.state.data.indate,
      gigan: this.state.data.gigan,
      yearflag: this.state.data.yearflag,
      cmsflag: this.state.data.cmsflag,
      refer: this.state.data.refer,
      salenm: this.state.data.salenm,
      apptime: this.state.data.apptime,
      attcnt: this.state.data.attcnt,
      qty: this.state.data.qty,
      beqty: this.state.data.beqty,
      number: this.state.data.number,
      amt: this.state.data.amt,
      beamt: this.state.data.beamt,
      qty1: this.state.data.qty1,
      qty2: this.state.data.qty2,
      qty3: this.state.data.qty3,
      qty4: this.state.data.qty4,
      qty5: this.state.data.qty5,
      qty6: this.state.data.qty6,
      qty7: this.state.data.qty7,
      qty8: this.state.data.qty8,
      saleamt: this.state.data.saleamt,
      equpamt1: this.state.data.equpamt1,
      equpamt2: this.state.data.equpamt2,
      equpamt3: this.state.data.equpamt3,
      equpamt4: this.state.data.equpamt4,
      equpamt5: this.state.data.equpamt5,
      equpamt6: this.state.data.equpamt6,
      equpamt7: this.state.data.equpamt7,
      equpamt8: this.state.data.equpamt8,
      uamt: this.state.data.uamt,
      evernumber: this.state.data.evernumber,
      everamt: this.state.data.everamt,
      everaddamt: this.state.data.everaddamt,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.contdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    const { user } = this.props.publicStore;

    if (user.custcd !== 'dmyong_g') {
      ConfirmWarning.show('확인', '해당기능은 커스텀상품(유료서비스)입니다 \n엘맨소프트에 서비스 이용 문의 바랍니다');
      this.props.publicStore?.toolStore?.onCloseHandler();
      return;
    }

    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new ContractModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        contractList: [
          newModel,
          ...this.state.contractList,
        ],
        total: this.state.contractList.length.toString(),
        focusedContract: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }


  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    if (user.custcd !== 'dmyong_g') {
      ConfirmWarning.show('확인', '해당기능은 커스텀상품(유료서비스)입니다 \n엘맨소프트에 서비스 이용 문의 바랍니다');
      this.props.publicStore?.toolStore?.onCloseHandler();
      return;
    }
    const params = {
      contmon: this.state.data.contmon,
      seq: this.state.data.seq,
      appnum: this.state.data.appnum,
      appgubun: this.state.data.appgubun,
      actnm: this.state.data.actnm,
    };
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete', params, false);
    } else {
      const text = `문서번호: ${this.state.data.appnum} 현장: ${this.state.data.actnm}`;
      await api.delete(text, params) && this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const { user } = this.props.publicStore;

    if (user.custcd !== 'dmyong_g') {
      ConfirmWarning.show('확인', '해당기능은 커스텀상품(유료서비스)입니다 \n엘맨소프트에 서비스 이용 문의 바랍니다');
      this.props.publicStore?.toolStore?.onCloseHandler();
      return;
    }
    if (!ConfirmWarning.assert(this.state.contractList.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      contmon: this.state.focusedContract?.contmon,
      seq: this.state.data.seq,
    });
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;
    let data:ContractModel = new ContractModel();
    const params = {
      itemname: ContractItemChangeTypeNames[type],
      data: item,
      new: this.state.data.new,
    };
    if (type === ContractItemChangeTypeNames.bildyd) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          addyn: this.state.data.addyn,
          evernight: this.state.data.evernight || '0',
          evercd: this.state.data.evercd,
          number: this.state.data.number,
          saleamt: this.state.data.saleamt,
        },
      );
      const evercds = await api.dropdown('wf_dd_e101_aver', { contbild: this.state.data.contbild });
      evercds && this.setState({ evercds: evercds.items }); // 승강기 구분 정보
    }

    if (type === ContractItemChangeTypeNames.evercd
    || type === ContractItemChangeTypeNames.evernight
    || type === ContractItemChangeTypeNames.addyn
    || type === ContractItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          addyn: this.state.data.addyn,
          evernight: this.state.data.evernight || '0',
          evercd: this.state.data.evercd,
          contbild: this.state.data.contbild,
          number: this.state.data.number,
          saleamt: this.state.data.saleamt,
        },
      );
    }

    if (type === ContractItemChangeTypeNames.contgubun
      || type === ContractItemChangeTypeNames.cltcd) {
      data = await api.fxExec('dw_2_itemchanged', {
        params,
        number: this.state.data.number,
        saleamt: this.state.data.saleamt,
      });
    }

    if (type === ContractItemChangeTypeNames.yearflag) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          yearflag: this.state.data.yearflag,
          stdate: this.state.data.stdate,
          number: this.state.data.number,
          saleamt: this.state.data.saleamt,
        },
      );
    }

    if (type === ContractItemChangeTypeNames.stdate
    || type === ContractItemChangeTypeNames.enddate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          stdate: this.state.data.stdate,
          enddate: this.state.data.enddate,
          number: this.state.data.number,
          saleamt: this.state.data.saleamt,
        },
      );
    }

    data && this.setState({
      data: new ContractModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async onKakaoButtonClicked() {
    const { actionStore: api } = this.props;

    await api.fxExec('wb_kakaosend', {
      appgubun: this.state.data.appgubun,
      contdate: this.state.data.contdate,
      contmon: this.state.data.contmon,
      seq: this.state.data.seq,
      appnum: this.state.data.appnum,
      actcd: this.state.data.actcd,
      clthpnum: this.state.data.clthpnum,
      actnm: this.state.data.actnm,
      contgun: this.state.data.contgubun,
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  render() {
    return (
      <ContractTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new ContractModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
