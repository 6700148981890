import React from 'react';
import {
  TemplateProps,
} from '../../../../../constants';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { PurchsingGridHeader } from '../headers/PurchsingGridHeader';
import { ClientMonth } from '../ClientMonth';

export const PaymentTemplate: React.FC<TemplateProps<ClientMonth>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.grid}
      infinityHandler={scope.infinity}
      header={PurchsingGridHeader(scope)}
      rowHeight={48}
      data={scope.state.cltList}
      headerHeight={68}
    />
  </FlexLayout>
);
