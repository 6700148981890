import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LayoutTitle,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { JiroAuto } from './JiroAuto';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_da026_import
 * @category 지로자동이체
 */
export const JiroAutoTemplate: React.FC<TemplateProps<JiroAuto>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={750}>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    setData({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    setData({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    setData({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    setData({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onSubExcelEvent()}>
              <div style={{ width: 72 }}>지로파일</div>
            </Button>

            <Button onClick={() => scope.onReport()}>
              <div style={{ width: 72 }}>{scope.state.focused?.rcvflag === '0' ? '입금처리' : '입금취소'}</div>
            </Button>
          </SearchLayout>
        </FlexLayout>

        <FlexLayout />

        <FlexLayout size={370}>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.ejiroStdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ ejiroStdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.ejiroStdate));
                    date.setDate(date.getDate() - 1);
                    setData({ ejiroStdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.ejiroStdate));
                    date.setDate(date.getDate() + 1);
                    setData({ ejiroStdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>

            <Button
              onClick={() => scope.onJiroEvent()}
              style={{
                borderLeft: 'solid 2px #FFFFFF',
              }}
            >
              <div style={{ width: 72 }}>지로동기화</div>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout weight={0.3}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'filename',
                text: '파일명',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.filename}
                  </FlexLayout>
                ),
              },
              {
                id: 'savedate',
                text: '저장일자',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.savedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'count',
                text: '건수',
                width: 20,
              },
            ]}
            data={scope.state.jiroAutoList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 5,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'vseq',
                text: '순번',
                width: 6,
              },
              {
                id: 'spjangcd',
                text: '사업장',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {scope.props.publicStore.spjangs.filter((y) => y.spjangcd === x.spjangcd)[0]?.spjangnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'seqnum',
                text: '고객조회번호',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seqnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 13,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'mismon',
                text: '발행년월',
                width: 7,
                render: (x) => (
                  <FormatTextBox
                    format="####-##"
                    textAlign="center"
                    value={x.mismon}
                  />
                ),
              },
              {
                id: 'misdate',
                text: '청구일',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'sunapdate',
                text: '납입일',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.sunapdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '납입금액',
                width: 7,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvdate',
                text: '이체일',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '이체금액',
                width: 7,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.rcvamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tamt',
                text: '수수료',
                width: 7,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.tamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misflag',
                text: '수납구분',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.misflag === '0' ? '수납' : ''}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.jiroAutoDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <LayoutTitle
          size={20}
          style={{ width: 154 }}
        >
          거래처정보
        </LayoutTitle>
        <GridLayout
          ref={scope.grid2}
          header={[
            {
              id: 'misdate',
              text: '청구일자',
              width: 8,
              trail: (_) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '청구금액',
              width: 8,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.misuamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvdate',
              text: '입금일자',
              width: 8,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.rcvdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'chk',
              text: '입금구분',
              width: 25,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.chk}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt',
              text: '입금액',
              width: 8,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.rcvamt_tot2}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.rcvamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'misuamt',
              text: '미수잔액',
              width: 8,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.misuamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misuamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'bigo',
              text: '비고',
              width: 35,
            },
          ]}
          data={scope.state.jiroAutoDetailList2}
          infinityHandler={scope.infinity3}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent3(item)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
