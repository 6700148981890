import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { BreakResister, BreakResisterItemChangeTypes } from './BreakResister';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Date8,
} from '../../../../utils/time';
import {
  Button,
  CheckBox, CircleBadge,
  ComboBox,
  ComboBoxModel,
  FormatNumberTextBox,
  FormatTextBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SubHeader,
  TableLayout,
  TelnumTextBox,
} from '../../../../components';
import { SearchBinding } from '../../../../models';

/*
* 화면
* @window w_tb_e401
* @category 고장접수등록
*/


export const BreakResisterTemplate: React.FC<TemplateProps<BreakResister>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  // eslint-disable-next-line no-return-assign
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={146}>
              <span>배정</span>
              <ComboBox
                value={scope.state.okflag}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '0', remark: '미배정' },
                  { value: '1', remark: '배정' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ okflag: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout size={176}>
              <span>처리결과</span>
              <ComboBox
                value={scope.state.resultck}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '처리' },
                  { value: '0', remark: '미처리' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ resultck: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout weight={3}>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder="현장으로 검색하세요"
                value={scope.state.searchQuery}
                onChange={(value) => scope.setState({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button
              onClick={() => scope.onAlarmClicked()}
            >
              알림전송
            </Button>
            <Button
              onClick={() => scope.smsSendModal(true)}
            >
              문자전송
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'state',
                text: '상태',
                width: 12,
                render: (x) => (
                  <FlexLayout
                  >
                    <TextBox
                      textAlign="center"
                      color={x.state === '1' ? 'var(--color-orange)' : x.state === '2'
                        ? 'var(--color-blue)' : 'var(--color-red)'}
                      value={x.state === '1' ? '처리중' : x.state === '2'
                        ? '완료' : '접수'}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 16,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recenum',
                text: '번호',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.recenum}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                    {x.contcd === '008' && <FlexLayout
                      style={{
                        position: 'absolute',
                        width: 50,
                        right: 0,
                        top: 0,
                        height: 20,
                        color: 'white',
                        background: '#FF3752',
                        lineHeight: 24,
                        fontSize: 11,
                        paddingLeft: 3,
                        borderRadius: 4,
                      }}>
                    사람갇힘
                  </FlexLayout>}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 18,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.equpnm}
                    <CircleBadge>
                      {Number(x.wadcnt) > 1 && <span
                        style={{ backgroundColor: 'var(--color-red)' }}
                      >
                        {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
                      </span>}
                      {Number(x.lastcnt) > 1 && <span
                        style={{ backgroundColor: 'var(--color-blue)' }}
                      >
                        {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
                      </span>}
                    </CircleBadge>
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 16,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Date8.withDash(x.compdate || '')}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.breakResisterList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          weight={1.3}
          isVertical={true}
          disabled={!scope.state.breakResisterList.length}
          disabledText="신규로 추가하거나 현장을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="접수일자"
              isNeed={true}
            >
              <DateTextBox
                size={150}
                value={model?.recedate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ recedate: value },
                  () => scope.itemChanged(BreakResisterItemChangeTypes.RECEDATE, value))}
                readonly={model?.new === '0'}
              />
              <TextBox
                textAlign="center"
                value={model?.recenum}
                readonly={true}
                weight={0.807}
              />
            </LabelBox>
            <LabelBox title="접수시간">
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.recetime}
                onChange={(value) => setData({ recetime: value })}
                onBlur={() => scope.itemChanged(BreakResisterItemChangeTypes.RECETIME)}
              />
            </LabelBox>
            <LabelBox title="고장일자">
              <DateTextBox
                format="####-##-##"
                textAlign="center"
                value={model?.hitchdate}
                onChange={(value) => setData({ hitchdate: value })}
              />
            </LabelBox>
            <LabelBox
              title="고장시간"
              size={200}
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.hitchhour}
                onChange={(value) => setData({ hitchhour: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                size={130}
                value={model.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    },
                    () => scope.itemChanged(BreakResisterItemChangeTypes.ACTCD));
                  },
                )}
              />
              <TextBox
                value={model.actnm}
                weight={2}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="담당자"
              size={100}
            >
              <TextBox
                textAlign="center"
                value={model?.actpername}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="장기미수금"
              style={{ color: 'var(--color-red)' }}
              size={200}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.misamt03}
                color={'var(--color-red)'}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장주소"
            >
              <TextBox
                size={130}
                value={model.zipcode}
                textAlign="center"
                readonly={true}
              />
              <TextBox
                value={model.address}
                weight={3}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model.address2}
                weight={1.62}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="현장담당자"
              size={200}
            >
              <TextBox
                textAlign="center"
                value={model?.actpernm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="호기명"
              style={{ color: 'var(--color-red)' }}
              footer={
                <FlexLayout>
                  <FlexLayout size={150}>

                  </FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                    justify="right"
                  >
                    {Number(model?.wadcnt) > 1 ? `다발고장${model?.wadcnt}회` : '*'}
                  </FlexLayout>
                  <FlexLayout
                    style={{ color: '#0100FF' }}
                    justify="right"
                  >
                    {Number(model?.lastcnt) > 1 ? `동일고장${model?.lastcnt}회` : '*'}
                  </FlexLayout>
                </FlexLayout>
              }
            >
              <TextBox
                size={130}
                value={model.equpcd}
                textAlign="center"
                onChange={(value) => setData({ equpcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E611',
                  { uv_arg1: scope.state.focusedDetailBreakResister?.actcd },
                  true,
                  (item) => {
                    setData({
                      equpcd: item.cd,
                      equpnm: item.cdnm,
                    }, () => scope.itemChanged(BreakResisterItemChangeTypes.EQUPCD));
                  },
                )}
              />
                <TextBox
                  value={model.equpnm}
                  readonly={true}
                  weight={1}
                />
                <FormatTextBox
                  textAlign="center"
                  format="####-###"
                  value={model.elno}
                  readonly={true}
                />
            </LabelBox>
            <LabelBox
              title="고장내용"
              style={{ color: 'var(--color-red)' }}
              size={220}
            >
              <TextBox
                textAlign="center"
                value={model?.contcd}
                onChange={(value) => setData({ contcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E010',
                  {},
                  true,
                  (item) => {
                    setData({
                      contcd: item.cd,
                      contnm: item.cdnm,
                    },
                    () => scope.itemChanged(BreakResisterItemChangeTypes.CONTCD));
                  },
                )}
                  />
              <TextBox
                textAlign="left"
                value={model?.contnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장아님(다발아님)"
              style={{ color: 'var(--color-blue)' }}
              size={200}
              footer={<FlexLayout>
                <CheckBox
                  value={model.wadnot === '1'}
                  onChange={(value) => {
                    setData({ wadnot: value ? '1' : '0' }, () => scope.wadnotItemcanged(value));
                  }}
                />
              </FlexLayout>}
            >
              <TextBox
                textAlign="left"
                value={model?.contnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title={`${model?.recedate.substr(4, 2)}월점검처리`}
              size={200}
            >
              <TextBox
                textAlign="center"
                value={model?.planstate === '1' ? '계획' : '완료'}
                color={model?.planstate === '1' ? 'var(--color-red)' : 'var(--color-blue)'}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox
              title="고장상세내용"
              footer={<FlexLayout>
                <CheckBox
                  value={model.contchk === '1'}
                  onChange={(value) => {
                    setData({ contchk: value ? '1' : '0' });
                  }}
                />
                연관검색
              </FlexLayout>}
            >
              <TextBox
                textAlign="left"
                isMultiline={true}
                value={model?.contents}
                onChange={(value) => setData({ contents: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="사람갇힘일자"
              footer={<FlexLayout>
                <CheckBox
                  value={model.trouble === '1'}
                  onChange={(value) => {
                    setData({
                      trouble: value ? '1' : '0',
                      troubledate: value ? model?.recedate : '',
                      troubletime: value ? model?.recetime : '',
                      troublesu: value ? '1' : '0',
                    },
                    () => scope.itemChanged(BreakResisterItemChangeTypes.TROUBLE));
                  }}
                />
              </FlexLayout>}
            >
              <DateTextBox
                value={model?.troubledate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ troubledate: value })}
                readonly={model?.trouble === '0'}
              />
            </LabelBox>
            <LabelBox title="갇힘시간">
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.troubletime}
                onChange={(value) => setData({ troubletime: value })}
                readonly={model?.trouble === '0'}
              />
            </LabelBox>
            <LabelBox title="갇힘사람수">
              <FormatTextBox
                format="##명"
                textAlign="center"
                value={model?.troublesu}
                readonly={model?.trouble === '0'}
              />
            </LabelBox>
            <LabelBox title="접수자"
              style={{ color: 'var(--color-red)' }}
              >
              <TextBox
                value={model.reperid}
                textAlign="center"
                onChange={(value) => setData({ reperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      reperid: item.cd,
                      repernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.repernm}
                readonly={true}
                textAlign='center'
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title={model?.okflag === '1' ? '배정자' : '배정예정자'}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.pernm}
                readonly={true}
                textAlign='center'
              />
            </LabelBox>
            <LabelBox title="배정자부서/근태">
              <TextBox
                value={model.divinm}
                textAlign="center"
                readonly={true}
              />
              <TextBox
                color={model.timeyn === '4' ? 'var(--color-red)' : ''}
                value={model.atdnm}
                weight={1}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="배정자구분"
              footer={<FlexLayout>
                <Button
                  style={{ color: 'var(--color-red)' }}
                  onClick={() => scope.PopupModal(true)}
                >
                  배정거절내역
                </Button>
              </FlexLayout>}
            >
              <OptionBox
                value={model?.okflag}
                data={[
                  { value: '0', remark: '미배정' },
                  { value: '1', remark: '배정완료' },
                ]}
                onChange={(item) => setData({ okflag: item.value },
                  () => scope.itemChanged(BreakResisterItemChangeTypes.OKFLAG))}
              />
            </LabelBox>
            <LabelBox
              title="고객안심문자"
              footer={<FlexLayout>
                <Button
                  style={{ color: 'var(--color-pink)' }}
                  onClick={() => scope.onCustomerSMS()}
                >
                  보내기
                </Button>
              </FlexLayout>}
            >
              <TelnumTextBox
                value={model.recetelnum}
                textAlign="center"
                onChange={(value) => setData({ recetelnum: value })}
              />
            </LabelBox>
          </FlexLayout>

        </FlexLayout>
      </FlexLayout>
      <FlexLayout
        weight={5}
        disabled={!scope.state.breakResisterList.length}
        disabledText="신규로 추가하거나 현장을 선택해주세요"
      >
        <GridLayout
          ref={scope.detailGrid}
          header={[
            {
              id: 'recedate',
              text: '접수일자',
              width: 3,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.recedate)}
                </FlexLayout>
              ),
            },
            {
              id: 'recenum',
              text: '번호',
              width: 2,
              render: (x) => (
                <TextBox
                  value={x.recenum}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 3,
              render: (x) => (
                <TextBox
                  value={x.equpnm}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'wadnot',
              text: '고장여부',
              width: 2,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={x.wadnot === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }}
                >
                  {x.wadnot === '1' ? '고장아님' : '고장'}
                </FlexLayout>
              ),
            },
            {
              id: 'contnm',
              text: '고장내용',
              width: 3,
              render: (x) => (
                <TextBox
                  value={x.contnm}
                  textAlign="left"
                />
              ),
            },
            {
              id: 'contents',
              text: '고장상세내용',
              width: 10,
              render: (x) => (
                <TextBox
                  value={x.contents}
                  textAlign="left"
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 16,
              render: (x) => (
                <TextBox
                  value={x.contents}
                  textAlign="left"
                />
              ),
            },
          ]}
          data={scope.state.breakFieldList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity2}
          // onRowClick={(item) => scope.onYesterdayRowFocusEvent(item)}
        />
      </FlexLayout>
      <ModalView
        width={500}
        height={120}
        isVisible={scope.state.smsModal}
        onClose={() => scope.smsSendModal(false)}
      >
        <FlexLayout isVertical={true}>
          <LayoutTitle size={25}>문자유형</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >

            <OptionBox
              value={scope.state?.gubun}
              data={[
                { value: '1', remark: '음성문자' },
                { value: '0', remark: '문자' },
              ]}
              onChange={(v) => scope.setState({ gubun: `${v.value}` })}
            />
          </FlexLayout>
          <SubHeader>
            <FlexLayout justify="end">
              <Button
                onClick={() => scope.onClickSMS()}>
                확인
              </Button>
              <Button
                onClick={() => scope.smsSendModal(false)}>
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
      <ModalView
        isVisible={scope.state.popupDetailModal}
        onClose={() => scope.PopupModal(false)}
        width={900}
        height={700}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >

          <SubHeader>
            <div>
              {model.actnm} {model.equpnm} 고장배정거절내역
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.popupNew()}>신규</Button>
              <Button onClick={() => scope.popupSave()}>저장</Button>
              <Button onClick={() => scope.popupDelete()}>삭제</Button>
              <Button onClick={() => scope.PopupModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>

          <TableLayout
            ref={scope.popupTable}
            header={[
              {
                id: 'seq',
                text: '순서',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '배정거절자',
                width: 1.5,
                color: 'var(--color-red)',
                render: (x, rowUpdate, refs) => (
                  <TextBox
                    refs={refs}
                    value={x.perid}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ perid: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        rowUpdate({ perid: item.cd, pernm: item.cdnm });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '배정거절자명',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '배정거절사유',
                width: 5,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.remark}
                    onChange={(value) => rowUpdate({ remark: value })}
                  />
                ),
              },
              {
                id: 'repernm',
                text: '접수자(배정자)',
                width: 2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.repernm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.popupData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowFocusEvent={(item) => scope.onPopupRowFocusEvent(item)}
            onChange={(rows, updatedRows) => { scope.onPopupUpdateRows(rows, updatedRows); }}
          />
        </FlexLayout>
      </ModalView>

    </FlexLayout>
  );
};
