import { computed } from 'mobx';

/**
 * 사용내역 모델
 * @window w_tb_ca608w_02
 * @category 발주현황
 */
export class UseModel {
  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly balseq: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qcqty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly ibgqty: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly ibgperid: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly ibgpernm: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly delqty: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly delperid: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly delpernm: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly banqty: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.balseq = data.balseq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.qcqty = data.qcqty || '';
    this.ibgqty = data.ibgqty || '';
    this.ibgperid = data.ibgperid || '';
    this.ibgpernm = data.ibgpernm || '';
    this.delqty = data.delqty || '';
    this.delperid = data.delperid || '';
    this.delpernm = data.delpernm || '';
    this.banqty = data.banqty || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
