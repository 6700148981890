import { computed } from 'mobx';
import { Today } from '../../../../../utils/time';

/**
 * 모델
 * @window w_input_da026_all
 * @category 입금현황
 */
export class DepositInputModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(8)
   */
  cltcd: string;

  /**
   * 미수일자
   *
   * **data type** varchar(2)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(13)
   */
  misnum: string;

  /**
   * 0
   *
   * **data type** varchar(8)
   */
  misgubun: string;

  /**
   * 현장코드
   *
   * **data type** char(4)
   */
  actcd: string;

  /**
   * 회사구분코드
   *
   * **data type** varchar(1)
   */
  wkactcd: string;

  /**
   * 계약금액
   *
   * **data type** varchar(15)
   */
  contamt: string;

  /**
   * 매출구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 년월
   *
   * **data type** float
   */
  yyyymm: string;

  /**
   * 회계적용
   *
   * **data type** varchar(6)
   */
  accyn: string;

  /**
   * 부서
   *
   * **data type** varchar(3)
   */
  divicd: string;

  /**
   * 입금담당
   *
   * **data type** char(1)
   */
  perid: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  amt: string;

  /**
   * 부가세
   *
   * **data type** varchar(10)
   */
  addamt: string;

  /**
   * 매출액
   *
   * **data type** float
   */
  misamt: string;

  /**
   * 차이금액
   *
   * **data type** float
   */
  chaamt: string;

  /**
   * 지급예정일
   *
   * **data type** float
   */
  schdate: string;

  /**
   * 계산서발행
   *
   * **data type** float
   */
  billkind: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(8)
   */
  taxcls: string;

  /**
   * 과세구분
   *
   * **data type** varchar(2)
   */
  taxgubun: string;

  /**
   * 지로구분
   *
   * **data type** varchar(2)
   */
  jirogubun: string;

  /**
   * 현금
   *
   * **data type** varchar(3)
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** varchar(1)
   */
  eamt: string;

  /**
   * 어음번호
   *
   * **data type** float
   */
  enum: string;

  /**
   * 어음일자
   *
   * **data type** float
   */
  edate: string;

  /**
   * 어음일자
   *
   * **data type** varchar(12)
   */
  eidate: string;

  /**
   * 어음거래처
   *
   * **data type** varchar(8)
   */
  ecltcd: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  samt: string;

  /**
   * 예금
   *
   * **data type** varchar(8)
   */
  bamt: string;

  /**
   * 입금은행
   *
   * **data type** varchar(13)
   */
  bankcd: string;

  /**
   * 카드입금
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드은행
   *
   * **data type** varchar(8)
   */
  cardcd: string;

  /**
   * 카드효율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 카드번호
   *
   * **data type** varchar(10)
   */
  cardno: string;

  /**
   * 기타구분
   *
   * **data type** float
   */
  ggubun: string;

  /**
   * 기타입금
   *
   * **data type** varchar(16)
   */
  gamt: string;

  /**
   * 지로입금
   *
   * **data type** varchar(5)
   */
  jamt: string;

  /**
   * 지로입금 임시
   *
   * **data type** varchar(5)
   */
  tjamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 지로번호
   *
   * **data type** float
   */
  jnum: string;

  /**
   * 지로은행
   *
   * **data type** float
   */
  jbankcd: string;

  /**
   * 기타금액
   *
   * **data type** varchar(13)
   */
  dcamt: string;

  /**
   * 지로번호
   *
   * **data type** varchar(8)
   */
  pubnum: string;

  /**
   * 비고
   *
   * **data type** float
   */
  bigo: string;

  /**
   * 입력일
   *
   * **data type** float
   */
  indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(255)
   */
  inperid: string;

  /**
   * 선급금구분
   *
   * **data type** varchar(6)
   */
  sunflag: string;

  /**
   * 선급금
   *
   * **data type** varchar(10)
   */
  sunamt: string;

  /**
   * 선급금채크
   *
   * **data type** varchar(1)
   */
  sunchk: string;

  /**
   * 발행구분
   *
   * **data type** float
   */
  billgubun: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  compflag: string;

  /**
   * 공사일자
   *
   * **data type** char(1)
   */
  compdate: string;

  /**
   * 공사번호
   *
   * **data type** char(1)
   */
  compnum: string;

  /**
   * 출고구분
   *
   * **data type** char(8)
   */
  delflag: string;

  /**
   * 출고일자
   *
   * **data type** char(3)
   */
  deldate: string;

  /**
   * 출고번호
   *
   * **data type** char(1)
   */
  delnum: string;

  /**
   * 계정과목
   *
   * **data type** varchar(8)
   */
  acccd: string;

  /**
   * 월기준
   *
   * **data type** varchar(4)
   */
  ungijun: string;

  /**
   * 지로채크
   *
   * **data type** varchar(4)
   */
  jirochk: string;

  /**
   * 지로납기일
   *
   * **data type** varchar(1)
   */
  jirodate: string;

  /**
   * 예전미수일자
   *
   * **data type** varchar(1)
   */
  bemisdate: string;

  /**
   * 예전미수번호
   *
   * **data type** varchar(8)
   */
  bemisnum: string;

  /**
   * 예금수수료
   *
   * **data type** varchar(4)
   */
  bmar: string;

  /**
   * 입고일자
   *
   * **data type** float
   */
  ibgdate: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(1)
   */
  emcltcd: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(8)
   */
  emactcd: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(13)
   */
  emactcd2: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(13)
   */
  emseq: string;

  /**
   * ems연결코드
   *
   * **data type** varchar(50)
   */
  emtaxbillno: string;

  /**
   * 번호
   *
   * **data type** varchar(50)
   */
  projno: string;

  /**
   * 현장명
   *
   * **data type** varchar(50)
   */
  actnm: string;

  /**
   * 거래처명
   *
   * **data type** varchar(10)
   */
  cltnm: string;

  /**
   * 회사구분
   *
   * **data type** varchar(255)
   */
  wkactnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(255)
   */
  divinm: string;

  /**
   * 입금담당2
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(100)
   */
  projnm: string;

  /**
   * 지로출력
   *
   * **data type** varchar(100)
   */
  prtchk: string;

  /**
   * 취소여부
   *
   * **data type** varchar(255)
   */
  cancleflag: string;

  /**
   * 도장
   *
   * **data type** varchar(1)
   */
  stamp: string;

  /**
   * 현대금액
   *
   * **data type** varchar(1)
   */
  hdamt: string;

  /**
   * 프로젝트현장코드
   *
   * **data type** varchar(1)
   */
  proactcd: string;

  /**
   * 설치선급금구분
   *
   * **data type** float
   */
  scstflag: string;

  /**
   * 설치선급일자
   *
   * **data type** varchar(13)
   */
  scstdate: string;

  /**
   * 설치중도구분
   *
   * **data type** varchar(1)
   */
  scmidflag: string;

  /**
   * 설치중도일자
   *
   * **data type** varchar(8)
   */
  scmiddate: string;

  /**
   * 설치완료구분
   *
   * **data type** varchar(1)
   */
  scendflag: string;

  /**
   * 설치완료일자
   *
   * **data type** varchar(8)
   */
  scenddate: string;

  /**
   * 설치계약일자
   *
   * **data type** varchar(1)
   */
  orddate: string;

  /**
   * 설치계약번호
   *
   * **data type** varchar(8)
   */
  ordnum: string;

  /**
   * 스위치
   *
   * **data type** varchar(8)
   */
  flag: string;

  /**
   * 현대다른거비교
   *
   * **data type** varchar(4)
   */
  hd_diff_remark: string;

  /**
   * 지로도장
   *
   * **data type** varchar(1)
   */
  jiro_stamp: string;

  /**
   * 계산서다이렉트
   *
   * **data type** varchar(255)
   */
  tax_direct: string;

  /**
   * 비고2
   *
   * **data type** varchar(1)
   */
  bigo2: string;

  /**
   * 예금연결코드
   *
   * **data type** varchar(3)
   */
  imseq: string;

  /**
   * 지로출력순번
   *
   * **data type** varchar(255)
   */
  giro_prtseq: string;

  /**
   * 입금일자
   *
   * **data type** varchar(50)
   */
  rcvdate: string;

  /**
   * 입금번호
   *
   * **data type** float
   */
  rcvnum: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  rcvseq: string;

  /**
   * 전표일자
   *
   * **data type** float
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(10)
   */
  acc_spnum: string;

  /**
   * 적요
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * cms구분
   *
   * **data type** varchar(50)
   */
  cms: string;

  /**
   * 예금연결미수일자
   *
   * **data type** varchar(50)
   */
  mismijdate: string;

  /**
   * 예금연결미수일자
   *
   * **data type** varchar(1)
   */
  mismijnum: string;

  /**
   * 입금일자
   *
   * **data type** varchar(8)
   */
  datetime: string;

  /**
   * 현금
   *
   * **data type** varchar(4)
   */
  hchk: string;

  /**
   * 예금
   *
   * **data type** datetime
   */
  bchk: string;

  /**
   * 지로
   *
   * **data type** char(1)
   */
  jchk: string;

  /**
   * 카드
   *
   * **data type** char(1)
   */
  dchk: string;

  /**
   * 기타
   *
   * **data type** char(1)
   */
  gchk: string;

  /**
   * 입금은행
   *
   * **data type** number
   */
  banknm: string;

  /**
   * 계좌번호
   *
   * **data type** number
   */
  accnum: string;

  /**
   * 지로은행
   *
   * **data type** varchar(50)
   */
  jbankcd_1: string;

  /**
   * 카드은행
   *
   * **data type** varchar(50)
   */
  cardcd_1: string;

  /**
   * 지로계좌번호2
   *
   * **data type** varchar(50)
   */
  jbankcd_2: string;

  /**
   * 선택
   *
   * **data type** number
   */
  chk: string;

  /**
   * 상태
   *
   * **data type** varchar(4)
   */
  recivstatus: string;

  /**
   * 미수잔액
   *
   * **data type** varchar(2)
   */
  janamt: string;

  /**
   * 기입금액
   *
   * **data type** number
   */
  inamt: string;

  /**
   * 예정입금액
   *
   * **data type** number
   */
  winamt: string;

  /**
   * 예금
   *
   * **data type** number
   */
  wbamt: string;

  /**
   * 지로
   *
   * **data type** long
   */
  wjamt: string;

  /**
   * 현금
   *
   * **data type** long
   */
  whamt: string;

  /**
   * 수수료
   *
   * **data type** long
   */
  wjmar: string;

  /**
   * 어음
   *
   * **data type** long
   */
  weamt: string;

  /**
   * 수표
   *
   * **data type** long
   */
  wsamt: string;

  /**
   * 카드
   *
   * **data type** long
   */
  wdamt: string;

  /**
   * 기타
   *
   * **data type** long
   */
  wgamt: string;

  /**
   * DC금액
   *
   * **data type** long
   */
  wdcamt: string;

  /**
   * 계산서일자
   *
   * **data type** char(2)
   */
  tax_spdate: string;

  /**
   * 번호
   *
   * **data type** char(3)
   */
  tax_spnum: string;

  /**
   * 입금액
   *
   * **data type** char(8)
   */
  iamt: string;

  /**
   * 입금형태
   *
   * **data type** char(8)
   */
  proof: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  sort: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  total_amt: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  jcltno: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  jp_chk: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  in_acc_spdate: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  query: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  echk: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  schk: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  dcchk: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  pubgubun: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  ctaxamt: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  jbanknm: string;

  /**
   * -
   *
   * **data type** char(8)
   */
  jaccnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.jaccnum = data.jaccnum || '';
    this.jbanknm = data.jbanknm || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.echk = data.echk || '';
    this.jcltno = data.jcltno || '';
    this.pubgubun = data.pubgubun || '';
    this.ctaxamt = data.ctaxamt || '';
    this.dcchk = data.dcchk || '';
    this.cltcd = data.cltcd || '';
    this.schk = data.schk || '';
    this.jcltno = data.jcltno || '';
    this.query = data.query || '';
    this.in_acc_spdate = data.in_acc_spdate || '';
    this.jp_chk = data.jp_chk || '';
    this.total_amt = data.total_amt || '';
    this.chk = data.chk || '';
    this.sort = data.sort || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.misgubun = data.misgubun || '';
    this.actcd = data.actcd || '';
    this.wkactcd = data.wkactcd || '';
    this.contamt = data.contamt || '';
    this.gubun = data.gubun || '';
    this.yyyymm = data.yyyymm || '';
    this.accyn = data.accyn || '1';
    this.divicd = data.divicd || '';
    this.perid = data.perid || '';
    this.amt = data.amt || '';
    this.addamt = data.addamt || '';
    this.misamt = data.misamt || '';
    this.chaamt = data.chaamt || '';
    this.schdate = data.schdate || '';
    this.billkind = data.billkind || '';
    this.taxcls = data.taxcls || '';
    this.taxgubun = data.taxgubun || '';
    this.jirogubun = data.jirogubun || '';
    this.hamt = data.hamt || '';
    this.eamt = data.eamt || '';
    this.enum = data.enum || '';
    this.edate = data.edate || '';
    this.eidate = data.eidate || '';
    this.ecltcd = data.ecltcd || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.damt = data.damt || '';
    this.cardcd = data.cardcd || '';
    this.persent = data.persent || '';
    this.cardno = data.cardno || '';
    this.ggubun = data.ggubun || '';
    this.gamt = data.gamt || '';
    this.jamt = data.jamt || '';
    this.tjamt = data.tjamt || '';
    this.jmar = data.jmar || '';
    this.jnum = data.jnum || '';
    this.jbankcd = data.jbankcd || '';
    this.dcamt = data.dcamt || '';
    this.bigo = data.bigo || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.sunflag = data.sunflag || '';
    this.sunamt = data.sunamt || '';
    this.sunchk = data.sunchk || '';
    this.billgubun = data.billgubun || '';
    this.compflag = data.compflag || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.delflag = data.delflag || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.acccd = data.acccd || '';
    this.ungijun = data.ungijun || '';
    this.jirochk = data.jirochk || '';
    this.jirodate = data.jirodate || '';
    this.bemisdate = data.bemisdate || '';
    this.bemisnum = data.bemisnum || '';
    this.bmar = data.bmar || '';
    this.ibgdate = data.ibgdate || '';
    this.emcltcd = data.emcltcd || '';
    this.emactcd = data.emactcd || '';
    this.emactcd2 = data.emactcd2 || '';
    this.emseq = data.emseq || '';
    this.emtaxbillno = data.emtaxbillno || '';
    this.projno = data.projno || '';
    this.actnm = data.actnm || '';
    this.cltnm = data.cltnm || '';
    this.wkactnm = data.wkactnm || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.projnm = data.projnm || '';
    this.prtchk = data.prtchk || '';
    this.cancleflag = data.cancleflag || '';
    this.stamp = data.stamp || '';
    this.hdamt = data.hdamt || '';
    this.proactcd = data.proactcd || '';
    this.scstflag = data.scstflag || '';
    this.scstdate = data.scstdate || '';
    this.scmidflag = data.scmidflag || '';
    this.scmiddate = data.scmiddate || '';
    this.scendflag = data.scendflag || '';
    this.scenddate = data.scenddate || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.flag = data.flag || '';
    this.hd_diff_remark = data.hd_diff_remark || '';
    this.jiro_stamp = data.jiro_stamp || '';
    this.tax_direct = data.tax_direct || '';
    this.bigo2 = data.bigo2 || '';
    this.imseq = data.imseq || '';
    this.giro_prtseq = data.giro_prtseq || '';
    this.rcvdate = data.rcvdate || Today.date();
    this.rcvnum = data.rcvnum || '';
    this.rcvseq = data.rcvseq || '';
    this.jnum = data.jnum || '';
    this.pubnum = data.pubnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.remark = data.remark || '';
    this.billgubun = data.billgubun || '';
    this.emtaxbillno = data.emtaxbillno || '';
    this.cms = data.cms || '';
    this.mismijdate = data.mismijdate || '';
    this.mismijnum = data.mismijnum || '';
    this.datetime = data.datetime || '';
    this.hchk = data.hchk || '';
    this.bchk = data.bchk || '1';
    this.jchk = data.jchk || '';
    this.dchk = data.dchk || '';
    this.gchk = data.gchk || '';
    this.banknm = data.banknm || '';
    this.accnum = data.accnum || '';
    this.jbankcd_1 = data.jbankcd_1 || '';
    this.cardcd_1 = data.cardcd_1 || '';
    this.jbankcd_2 = data.jbankcd_2 || '';
    this.recivstatus = data.recivstatus || '';
    this.janamt = data.janamt || '';
    this.inamt = data.inamt || '';
    this.winamt = data.winamt || '';
    this.wbamt = data.wbamt || '';
    this.wjamt = data.wjamt || '';
    this.whamt = data.whamt || '';
    this.wjmar = data.wjmar || '';
    this.weamt = data.weamt || '';
    this.wsamt = data.wsamt || '';
    this.wdamt = data.wdamt || '';
    this.wgamt = data.wgamt || '';
    this.wdcamt = data.wdcamt || '';
    this.tax_spdate = data.tax_spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.iamt = data.iamt || '';
    this.samt = data.samt || '';
    this.proof = data.proof || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
