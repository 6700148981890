import {
  FaQuoteLeft,
  FaQuoteRight,
}
  from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Install } from './Install';
import { TabModel } from '../../../../models';
import { InstallTabMonthTemplate } from './tabs/InstallTabMonth.template';
import { InstallTabFiveYearTemplate } from './tabs/InstallTabFiveYear.template';

/**
 * 화면 > 탭
 * @window w_tb_e601w_sulchi_08
 * @category 설치수주매출통계
 */
export const InstallTab: Array<TabLayoutDataItem<Install>> = [
  {
    tab: new TabModel('Five', FaQuoteLeft, '5년간 수주매출내역'),
    template: (scope) => <InstallTabFiveYearTemplate scope={scope} />,
  },
  {
    tab: new TabModel('Month', FaQuoteRight, '월별 수주매출내역'),
    template: (scope) => <InstallTabMonthTemplate scope={scope} />,
  },
];
