import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { InputState } from './InputState';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_input_da026w
 * @category 입금현황
 */
export const InputStateTemplate: React.FC<TemplateProps<InputState>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => setData({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() - 1);
              setData({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() + 1);
              setData({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={180}>
        <span style={{ marginLeft: 13 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => setData({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() - 1);
              setData({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() + 1);
              setData({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={268} style={{ marginLeft: 32 }}>
        <span style={{ color: 'var(--color-red)', marginRight: 20 }}>부서</span>
        <TextBox
          textAlign="center"
          value={scope.state.divicd}
          onChange={(value) => setData({ divicd: value })}
          noPadding={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              setData({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.2}
          value={scope.state.divinm}
          readonly={true}
          onChange={(value) => setData({ divinm: value })}
        />
      </FlexLayout>

      <FlexLayout
        size={400}
        style={{ marginLeft: 14 }}
      >
        <span style={{ color: 'var(--color-red)' }}>거래처</span>
        <TextBox
          textAlign="center"
          value={scope.state.cltcd}
          onChange={(value) => setData({ cltcd: value })}
          noPadding={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_XCLIENT',
            { uv_arg1: '2' },
            true,
            (item) => {
              setData({
                cltcd: item.cd,
                cltnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={2}
          value={scope.state.cltnm}
          readonly={true}
          onChange={(value) => setData({ cltnm: value })}
        />
      </FlexLayout>

      <FlexLayout size={440}>
        <span style={{ color: 'var(--color-red)' }}>계좌번호</span>
        <TextBox
          weight={0.8}
          textAlign="center"
          value={scope.state.bankcd}
          onChange={(value) => setData({ bankcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_AA040',
            {},
            true,
            (item) => {
              setData({
                bankcd: item.cd,
                banknm: item.uv_arg3,
                accnum: item.uv_arg1,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />

        <TextBox
          value={scope.state.accnum}
          readonly={true}
          onChange={(value) => setData({ accnum: value })}
        />

        <TextBox
          value={scope.state.banknm}
          readonly={true}
          onChange={(value) => setData({ cltnm: value })}
        />
      </FlexLayout>
    </SearchLayout>

    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      isSub={true}
    >
      <FlexLayout size={200}>
        <span>매출분류</span>
        <ComboBox
          value={scope.state.billgubun}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '매출' },
            { value: '0', remark: '비매출' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ billgubun: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span>입금형태</span>
        <ComboBox
          value={scope.state.chk}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '현금', remark: '현금' },
            { value: '예금', remark: '예금' },
            { value: '지로', remark: '지로' },
            { value: 'CMS', remark: 'CMS' },
            { value: '수표', remark: '수표' },
            { value: '카드', remark: '카드' },
            { value: 'D/C', remark: 'D/C' },
            { value: '기타', remark: '기타' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ chk: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout
        size={280}
      >
        <span style={{ color: 'var(--color-red)', marginRight: 18 }}>담당자</span>
        <TextBox
          textAlign="center"
          value={scope.state.perid}
          onChange={(value) => setData({ perid: value })}
          noPadding={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              setData({
                perid: item.cd,
                pernm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={1.2}
          value={scope.state.pernm}
          readonly={true}
          onChange={(value) => setData({ pernm: value })}
        />
      </FlexLayout>

      <FlexLayout
        style={{ marginLeft: 28 }}
        size={386}
      >
        <span style={{ color: 'var(--color-red)' }}>현장</span>
        <TextBox
          textAlign="center"
          value={scope.state.actcd}
          onChange={(value) => setData({ actcd: value })}
          noPadding={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E601_1',
            {},
            true,
            (item) => {
              setData({
                actcd: item.cd,
                actnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={2}
          value={scope.state.actnm}
          readonly={true}
          onChange={(value) => setData({ actnm: value })}
        />
      </FlexLayout>

      <FlexLayout size={440}>
        <span style={{ color: 'var(--color-red)' }}>프로젝트</span>
        <TextBox
          textAlign="center"
          value={scope.state.projno}
          onChange={(value) => setData({ projno: value })}
          noPadding={true}
          bindSearchModal={new SearchBindingProjects(
            scope.props.modalStore,
            {},
            true,
            (item) => {
              setData({
                projno: item.projno,
                projnm: item.projnm,
              });
              scope.onRetrieveEvent();
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          weight={2.5}
          value={scope.state.projnm}
          onChange={(value) => setData({ projnm: value })}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} isSub={true}>
      <FlexLayout size={200}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => setData({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={480}>
        <span style={{ marginRight: 20 }}>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="거래처, 현장, 적요로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => setData({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={414}>
        <span style={{ color: 'var(--color-red)' }}>회사구분</span>
        <TextBox
          value={scope.state.wkactcd}
          textAlign="center"
          onChange={(value) => setData({ wkactcd: value })}
          isDisabledTrackingStateChange={true}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_E018_1',
            {},
            true,
            (item) => {
              setData({
                wkactcd: item.cd,
                wkactnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={2}
          value={scope.state.wkactnm}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'cnam',
              text: '매출구분',
              width: 60,
            },
            {
              id: 'chk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={scope.state.pushTotalCheck}
                    onChange={async (value) => scope
                      .updateCheckAllToggle(value)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
              width: 40,
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.chk === '1'}
                    onChange={(value) => {
                      rowUpdate({ chk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
        />
      </FlexLayout>

      <FlexLayout weight={7}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'rcvdate',
              text: '입금일자',
              width: 6,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {Date8.withDash(x.rcvdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvnum',
              text: '번호',
              width: 3,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.rcvnum}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 15,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  건수: {Format.number(scope.state.total)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt',
              text: '입금액',
              width: 7,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state?.rcvamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {Format.number(x.rcvamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvmar',
              text: '수수료',
              width: 7,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.rcvmar_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : '' }}
                >
                  {Format.number(x.rcvmar)}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'chk',
              text: '입금형태',
              width: 5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.chk}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'accnm',
              text: '계정명',
              width: 7,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.accnm}
                </FlexLayout>
              ),
            },
            {
              id: 'banknm',
              text: '계좌명',
              width: 7,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.banknm}
                </FlexLayout>
              ),
            },
            {
              id: 'accnum',
              text: '계좌번호',
              width: 13,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: x.actnm === '예금관리' ? '#7F7A2C' : {} }}
                >
                  {x.accnum}
                </FlexLayout>
              ),
            },
            {
              id: 'mismijremark',
              text: '입금주(예금관리적요)',
              width: 13,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.mismijremark}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.inputStateList}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onRowDoubleClick={() => scope.onClickOpenBalEnrollment()}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
