import { computed } from 'mobx';
// import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e037w_03
 * @category 금일계획 조회
 */
export class TodayPlanModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 근태
   *
   * **data type** varchar(255)
   */
  readonly atdnm01: string;

  /**
   * 공사
   *
   * **data type** varchar(255)
   */
  readonly atdnm02: string;

  /**
   * 당직
   *
   * **data type** varchar(255)
   */
  readonly atdnm03: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   * 정렬
   *
   * **data type** varchar(3)
   */
  readonly sort: string;

  /**
   * 점검일자
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 담당자
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 부서
   *
   * **data type** varchar(10)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(100)
   */
  readonly divinm: string;

  /**
   * 직급코드
   *
   * **data type** varchar(10)
   */
  readonly rspcd: string;

  /**
   * 직급명
   *
   * **data type** varchar(100)
   */
  readonly rspnm: string;

  /**
   * 익일계획
   *
   * **data type** varchar(255)
   */
  readonly overwk: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력자
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  readonly flag: string;

  /**
   * 연결순번
   *
   * **data type** varchar(3)
   */
  readonly overseq: string;

  /**
   *  ?
   *
   * **data type** varchar(3)
   */
  readonly input: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.atdnm01 = data.atdnm01 || '';
    this.atdnm02 = data.atdnm02 || '';
    this.atdnm03 = data.atdnm03 || '';
    this.seq = data.seq || '';
    this.sort = data.sort || '';
    this.rptdate = data.rptdate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.overwk = data.overwk || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.flag = data.flag || '';
    this.overseq = data.overseq || '';
    this.input = data.input || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
