import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_gift_history
 * @category 명절선물등록
 */
export class GiftModel {
  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  readonly giftcd: string;

  /**
   * 선물명
   *
   * **data type** varchar(8)
   */
  readonly giftnm: string;

  /**
   * 사진
   *
   * **data type** varchar(8)
   */
  readonly imgflag: string;

  /**
   * 연도
   *
   * **data type** varchar(8)
   */
  readonly year: string;

  /**
   * 구분(설/추석)
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  new : string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.giftcd = data.giftcd || '';
    this.giftnm = data.giftnm || '';
    this.imgflag = data.imgflag || '0';
    this.new = isNew ? '1' : '0';
    this.year = data.year || '';
    this.gubun = data.gubun || '';
    this.spjangcd = data.spjangcd || '';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
