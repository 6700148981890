import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { GoalResult } from './GoalResult';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_da052w_01
 * @category 목표대비실적[개인별]
 */
export const GoalResultTemplate: React.FC<TemplateProps<GoalResult>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span style={{ color: 'var(--color-red)' }}>사원명</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              textAlign="center"
              onChange={(value) => setData({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <GridLayoutAdditionalHeader
          ref={scope.additionalTitle}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
        >
          <FlexLayout
            justify="center"
            align="center"
            size={682}
          >
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
            size={191}
          >
            {Format.number(scope.state.data?.planamt11 || '0')}
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
            size={191}
          >
            {Format.number(scope.state.data?.planamt12 || '0')}
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
            size={191}
          >
            {Format.number(scope.state.data?.planamt13 || '0')}
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
            size={191}
          >
            {Format.number(scope.state.data?.planamt14 || '0')}
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
            size={250}
          >
            {Format.number(scope.state.data?.planamtqty || '0')}
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'planmon2',
              text: '월',
              width: 6,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'planamt',
              text: '매출목표',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.planamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.planamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'totamt',
              text: '매출실적',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.totamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.totamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'amt11',
              text: '유지보수',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amt11_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt11)}
                </FlexLayout>
              ),
            },
            {
              id: 'amt12',
              text: '수리공사',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amt12_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt12)}
                </FlexLayout>
              ),
            },
            {
              id: 'amt13',
              text: '부품교체',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amt13_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt13)}
                </FlexLayout>
              ),
            },
            {
              id: 'amt14',
              text: '리모델링',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amt14_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt14)}
                </FlexLayout>
              ),
            },
            {
              id: 'amtelse',
              text: '가공매출(기타)',
              width: 11,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amtelse_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amtelse)}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '대수',
              width: 6,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.qty_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'yul',
              text: '월실적',
              width: 7,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.yul_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {x.yul}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.GoalResultList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
