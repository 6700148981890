import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640w_03
 * @category 년항목별 매입/지급현황
 */
export class YearArticleModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 비용항목
   *
   * **data type** varchar(4)
   */
  artcd: string;

  /**
   * 항목명
   *
   * **data type** varchar(50)
   */
  artnm: string;

  /**
   * 계정과목
   *
   * **data type** varchar(4)
   */
  acccd: string;

  /**
   * 계정과목
   *
   * **data type** varchar(40)
   */
  accnm: string;

  /**
   * 합계
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 1월
   *
   * **data type** number
   */
  mijmon01: string;

  /**
   * 2월
   *
   * **data type** number
   */
  mijmon02: string;

  /**
   * 3월
   *
   * **data type** number
   */
  mijmon03: string;

  /**
   * 4월
   *
   * **data type** number
   */
  mijmon04: string;

  /**
   * 5월
   *
   * **data type** number
   */
  mijmon05: string;

  /**
   * 6월
   *
   * **data type** number
   */
  mijmon06: string;

  /**
   * 7월
   *
   * **data type** number
   */
  mijmon07: string;

  /**
   * 8월
   *
   * **data type** number
   */
  mijmon08: string;

  /**
   * 9월
   *
   * **data type** number
   */
  mijmon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  mijmon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  mijmon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  mijmon12: string;

  /**
   * 1월
   *
   * **data type** number
   */
  bemon01: string;

  /**
   * 2월
   *
   * **data type** number
   */
  bemon02: string;

  /**
   * 3월
   *
   * **data type** number
   */
  bemon03: string;

  /**
   * 4월
   *
   * **data type** number
   */
  bemon04: string;

  /**
   * 5월
   *
   * **data type** number
   */
  bemon05: string;

  /**
   * 6월
   *
   * **data type** number
   */
  bemon06: string;

  /**
   * 7월
   *
   * **data type** number
   */
  bemon07: string;

  /**
   * 8월
   *
   * **data type** number
   */
  bemon08: string;

  /**
   * 9월
   *
   * **data type** number
   */
  bemon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  bemon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  bemon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  bemon12: string;

  /**
   * 1월
   *
   * **data type** number
   */
  sndmon01: string;

  /**
   * 2월
   *
   * **data type** number
   */
  sndmon02: string;

  /**
   * 3월
   *
   * **data type** number
   */
  sndmon03: string;

  /**
   * 4월
   *
   * **data type** number
   */
  sndmon04: string;

  /**
   * 5월
   *
   * **data type** number
   */
  sndmon05: string;

  /**
   * 6월
   *
   * **data type** number
   */
  sndmon06: string;

  /**
   * 7월
   *
   * **data type** number
   */
  sndmon07: string;

  /**
   * 8월
   *
   * **data type** number
   */
  sndmon08: string;

  /**
   * 9월
   *
   * **data type** number
   */
  sndmon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  sndmon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  sndmon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  sndmon12: string;

  /**
   * 1월
   *
   * **data type** number
   */
  tmijmon01: string;

  /**
   * 2월
   *
   * **data type** number
   */
  tmijmon02: string;

  /**
   * 3월
   *
   * **data type** number
   */
  tmijmon03: string;

  /**
   * 4월
   *
   * **data type** number
   */
  tmijmon04: string;

  /**
   * 5월
   *
   * **data type** number
   */
  tmijmon05: string;

  /**
   * 6월
   *
   * **data type** number
   */
  tmijmon06: string;

  /**
   * 7월
   *
   * **data type** number
   */
  tmijmon07: string;

  /**
   * 8월
   *
   * **data type** number
   */
  tmijmon08: string;

  /**
   * 9월
   *
   * **data type** number
   */
  tmijmon09: string;

  /**
   * 10월
   *
   * **data type** number
   */
  tmijmon10: string;

  /**
   * 11월
   *
   * **data type** number
   */
  tmijmon11: string;

  /**
   * 12월
   *
   * **data type** number
   */
  tmijmon12: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totmijamt: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totsndamt: string;

  /**
   * 합계
   *
   * **data type** number
   */
  tottmijamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.artcd = data.artcd || '';
    this.artnm = data.artnm || '';
    this.acccd = data.acccd || '';
    this.accnm = data.accnm || '';
    this.beamt = data.beamt || '';
    this.totmijamt = data.totmijamt || '';
    this.totsndamt = data.totsndamt || '';
    this.tottmijamt = data.tottmijamt || '';
    this.tmijmon01 = data.tmijmon01 || '';
    this.tmijmon02 = data.tmijmon02 || '';
    this.tmijmon03 = data.tmijmon03 || '';
    this.tmijmon04 = data.tmijmon04 || '';
    this.tmijmon05 = data.tmijmon05 || '';
    this.tmijmon06 = data.tmijmon06 || '';
    this.tmijmon07 = data.tmijmon07 || '';
    this.tmijmon08 = data.tmijmon08 || '';
    this.tmijmon09 = data.tmijmon09 || '';
    this.tmijmon10 = data.tmijmon10 || '';
    this.tmijmon11 = data.tmijmon11 || '';
    this.tmijmon12 = data.tmijmon12 || '';
    this.sndmon01 = data.sndmon01 || '';
    this.sndmon02 = data.sndmon02 || '';
    this.sndmon03 = data.sndmon03 || '';
    this.sndmon04 = data.sndmon04 || '';
    this.sndmon05 = data.sndmon05 || '';
    this.sndmon06 = data.sndmon06 || '';
    this.sndmon07 = data.sndmon07 || '';
    this.sndmon08 = data.sndmon08 || '';
    this.sndmon09 = data.sndmon09 || '';
    this.sndmon10 = data.sndmon10 || '';
    this.sndmon11 = data.sndmon11 || '';
    this.sndmon12 = data.sndmon12 || '';
    this.bemon01 = data.bemon01 || '';
    this.bemon02 = data.bemon02 || '';
    this.bemon03 = data.bemon03 || '';
    this.bemon04 = data.bemon04 || '';
    this.bemon05 = data.bemon05 || '';
    this.bemon06 = data.bemon06 || '';
    this.bemon07 = data.bemon07 || '';
    this.bemon08 = data.bemon08 || '';
    this.bemon09 = data.bemon09 || '';
    this.bemon10 = data.bemon10 || '';
    this.bemon11 = data.bemon11 || '';
    this.bemon12 = data.bemon12 || '';
    this.mijmon01 = data.mijmon01 || '';
    this.mijmon02 = data.mijmon02 || '';
    this.mijmon03 = data.mijmon03 || '';
    this.mijmon04 = data.mijmon04 || '';
    this.mijmon05 = data.mijmon05 || '';
    this.mijmon06 = data.mijmon06 || '';
    this.mijmon07 = data.mijmon07 || '';
    this.mijmon08 = data.mijmon08 || '';
    this.mijmon09 = data.mijmon09 || '';
    this.mijmon10 = data.mijmon10 || '';
    this.mijmon11 = data.mijmon11 || '';
    this.mijmon12 = data.mijmon12 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
