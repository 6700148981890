import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { StorageCodeModel } from './StorageCode.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { StorageCodeTemplate } from './StorageCode.template';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface StorageCodeState {
  searchQuery: string;
  data: Array<StorageCodeModel>;
  focused?: StorageCodeModel;
}

/**
 * 컨트롤러
 * @window w_tb_ca504
 * @category 창고코드등록
 */
@inject('routerStore', 'publicStore', 'modalStore', 'actionStore')
@observer
export class StorageCode extends PageComponent<PageProps, StorageCodeState>
  implements PageToolEvents {
  updatedRows?: Array<StorageCodeModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new StorageCodeModel(x))],
        }, async () => {
          this.table.current?.update();
          await this.table.current?.setFocus(0, 1);
        });
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        this.state.data.length > 0 && await this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.SS({
      data: [],
    });

    await this.infinity?.retrieve();
    await this.table.current?.update(true);
    this.state.data.length > 0 && await this.table.current?.setFocus(0, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        new StorageCodeModel(data, true),
        ...this.state.data,
      ],
    }, async () => {
      await this.table.current?.update(false);
      await this.table.current?.setFocus(0, 0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `창고코드: ${this.state.focused?.chg_gcod} 창고명: ${this.state.focused?.chg_gnam}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.data.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: StorageCodeModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<StorageCodeModel>, updatedRows: Array<StorageCodeModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return <StorageCodeTemplate scope={this} />;
  }
}
