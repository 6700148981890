import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  GridLayout, LayoutTitle,
  ModalView,
  SearchLayout, SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { IndividualGoal } from './IndividualGoal';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];

const flags = [
  { value: '4', remark: '공사없음' },
  { value: '5', remark: '다발고장' },
  { value: '7', remark: '교체없음' },
];

/**
 * 화면
 * @window w_tb_da052
 * @category 개인목표계획등록
 */
export const IndividualGoalTemplate: React.FC<TemplateProps<IndividualGoal>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout
      isVertical={true}
      isScroll={true}
    >
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>구분</span>
            <ComboBox
              value={scope.state.gubun}
              data={[
                { value: '11', remark: '계약관리' },
                { value: '12', remark: '수리공사' },
                { value: '13', remark: '부품교체' },
                { value: '15', remark: '리모델링' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => setData({ gubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              weight={1.5}
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout
            justify="left"
            align="center"
            style={{ color: 'var(--color-blue)' }}
          >
            {scope.state.data?.title4}
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout weight={0.2}>
        <div style={{ fontSize: 18, color: 'var(--color-blue)' }}>{scope.state.year}년 계약만료 및 저가현장</div>
        <div style={{ fontSize: 18, color: '#8106aa' }}>(저가현장은 만료일에 관계없이 해당월에 표기됩니다. 저가기준 70,000미만)</div>
      </FlexLayout>

      {scope.state.gubun !== '11'
        ? <FlexLayout>
        <FlexLayout
          size={300}
          isVertical={true}
          style={{ height: 220 }}
        >
          <FlexLayout
            justify="center"
            align="center"
            style={{ fontSize: 20 }}
          >
            <div style={{ color: 'var(--color-blue)' }}>1 &nbsp;</div>
            <div>January</div>
          </FlexLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'flag',
                  text: '구분',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                    >
                      {flags.filter((y) => y.value === x.flag)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.rsData1}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout>
          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
            >
              <div style={{ color: 'var(--color-blue)' }}>2 &nbsp;</div>
              <div>February</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData2}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
            >
              <div style={{ color: 'var(--color-blue)' }}>3 &nbsp;</div>
              <div>March</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData3}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
            >
              <div style={{ color: 'var(--color-blue)' }}>4 &nbsp;</div>
              <div>April</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData4}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
            >
              <div style={{ color: 'var(--color-blue)' }}>5 &nbsp;</div>
              <div>May</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData5}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
            >
              <div style={{ color: 'var(--color-blue)' }}>6 &nbsp;</div>
              <div>June</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData6}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
        : <FlexLayout>
          <FlexLayout
            size={300}
            isVertical={true}
            style={{ height: 220 }}
          >
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>1 &nbsp;</div>
              <div>January</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'contnm',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.contnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData1}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>2 &nbsp;</div>
                <div>February</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData2}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>3 &nbsp;</div>
                <div>March</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData3}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>4 &nbsp;</div>
                <div>April</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData4}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>5 &nbsp;</div>
                <div>May</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData5}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>6 &nbsp;</div>
                <div>June</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData6}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      }

      {scope.state.gubun !== '11'
        ? <FlexLayout>
        <FlexLayout
          isVertical={true}
          size={300}
          style={{ height: 220 }}
        >
          <FlexLayout
            justify="center"
            align="center"
            style={{ fontSize: 20 }}
            weight={0.1}
          >
            <div style={{ color: 'var(--color-blue)' }}>7 &nbsp;</div>
            <div>July</div>
          </FlexLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'flag',
                  text: '구분',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                    >
                      {flags.filter((y) => y.value === x.flag)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.rsData7}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout>
          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>8 &nbsp;</div>
              <div>August</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData8}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>9 &nbsp;</div>
              <div>September</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData9}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>10 &nbsp;</div>
              <div>October</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData10}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>11 &nbsp;</div>
              <div>November</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData11}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>12 &nbsp;</div>
              <div>December</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'flag',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {flags.filter((y) => y.value === x.flag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData12}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
        : <FlexLayout>
          <FlexLayout
            isVertical={true}
            size={300}
            style={{ height: 220 }}
          >
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-blue)' }}>7 &nbsp;</div>
              <div>July</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'contnm',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                      >
                        {x.contnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.rsData7}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>8 &nbsp;</div>
                <div>August</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData8}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>9 &nbsp;</div>
                <div>September</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData9}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>10 &nbsp;</div>
                <div>October</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData10}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>11 &nbsp;</div>
                <div>November</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData11}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-blue)' }}>12 &nbsp;</div>
                <div>December</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={{ color: x.actcd === x.planflag ? '#8C8C8C' : '' }}
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.rsData12}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      }

      <FlexLayout weight={0.2}>
        <FlexLayout style={{ fontSize: 18, color: 'var(--color-red)' }}>{scope.state.year}년 유지보수 목표등록</FlexLayout>
        <FlexLayout style={{ fontSize: 18 }}>{scope.state.data?.title5}</FlexLayout>
        <FlexLayout style={{ fontSize: 18, color: 'var(--color-blue)' }}>{scope.state.data?.title6}</FlexLayout>
        <FlexLayout style={{ fontSize: 18, color: '#7F7A2C' }}>{scope.state.data?.title7}</FlexLayout>
        <FlexLayout size={200} style={{ fontSize: 18, color: '#7F7A2C' }}></FlexLayout>
      </FlexLayout>

      {scope.state.gubun !== '11'
        ? <FlexLayout>
        <FlexLayout
          isVertical={true}
          size={300}
          style={{ height: 250 }}
        >
          <FlexLayout
            justify="center"
            align="center"
            style={{ fontSize: 20 }}
            weight={0.1}
          >
            <div style={{ color: 'var(--color-red)' }}>1 &nbsp;</div>
            <div>January</div>
          </FlexLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 60,
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      isVertical={true}
                      size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                    >
                      <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                        scope.state.trailData1?.cnt_tot} = FM: {scope.state.trailData1?.fm_tot},
                        다년: {scope.state.trailData1?.long_tot}, 일반: {scope.state.trailData1?.long_tot}</FlexLayout>
                      <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {scope.state.trailData1?.bemisamt_tot}</FlexLayout>
                      <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                        scope.state.trailData1?.totchasamt_tot}</FlexLayout>
                      <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData1?.pro_tot}</FlexLayout>
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'amt',
                  text: '금액',
                  width: 20,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {scope.state.trailData1?.amt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.amt)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.data1}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowDoubleClick={() => scope.textModal(true, '01')}
            />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout>
          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>2 &nbsp;</div>
              <div>February</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData2?.cnt_tot} = FM: {scope.state.trailData2?.fm_tot},
                          다년: {scope.state.trailData2?.long_tot}, 일반: {scope.state.trailData2?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData2?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData2?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData2?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData2?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data2}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowDoubleClick={() => scope.textModal(true, '02')}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>3 &nbsp;</div>
              <div>March</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData3?.cnt_tot} = FM: {scope.state.trailData3?.fm_tot},
                          다년: {scope.state.trailData3?.long_tot}, 일반: {scope.state.trailData3?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData3?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData3?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData3?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData3?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data3}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowDoubleClick={() => scope.textModal(true, '03')}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>4 &nbsp;</div>
              <div>April</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData4?.cnt_tot} = FM: {scope.state.trailData4?.fm_tot},
                          다년: {scope.state.trailData4?.long_tot}, 일반: {scope.state.trailData4?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData4?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData4?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData4?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData4?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data4}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowDoubleClick={() => scope.textModal(true, '04')}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>5 &nbsp;</div>
              <div>May</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData5?.cnt_tot} = FM: {scope.state.trailData5?.fm_tot},
                          다년: {scope.state.trailData5?.long_tot}, 일반: {scope.state.trailData5?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData5?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData5?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData5?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData5?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data5}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowDoubleClick={() => scope.textModal(true, '05')}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>6 &nbsp;</div>
              <div>June</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData6?.cnt_tot} = FM: {scope.state.trailData6?.fm_tot},
                          다년: {scope.state.trailData6?.long_tot}, 일반: {scope.state.trailData6?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData6?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData6?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData6?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData6?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data6}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowDoubleClick={() => scope.textModal(true, '06')}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
        : <FlexLayout>
          <FlexLayout
            isVertical={true}
            size={300}
            style={{ height: 250 }}
          >
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>1 &nbsp;</div>
              <div>January</div>
            </FlexLayout>

            <FlexLayout onClick={() => scope.textModal(true, '01')}>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData1?.cnt_tot} = FM: {scope.state.trailData1?.fm_tot},
                          다년: {scope.state.trailData1?.long_tot}, 일반: {scope.state.trailData1?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData1?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData1?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {scope.state.trailData1?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'contnm',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.contnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData1?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data1}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>2 &nbsp;</div>
                <div>February</div>
              </FlexLayout>

              <FlexLayout onClick={() => scope.textModal(true, '02')}>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData2?.cnt_tot} = FM: {scope.state.trailData2?.fm_tot},
                            다년: {scope.state.trailData2?.long_tot}, 일반: {scope.state.trailData2?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData2?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData2?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData2?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData2?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data2}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>3 &nbsp;</div>
                <div>March</div>
              </FlexLayout>

              <FlexLayout onClick={() => scope.textModal(true, '03')}>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData3?.cnt_tot} = FM: {scope.state.trailData3?.fm_tot},
                            다년: {scope.state.trailData3?.long_tot}, 일반: {scope.state.trailData3?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData3?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData3?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData3?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData3?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data3}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>4 &nbsp;</div>
                <div>April</div>
              </FlexLayout>

              <FlexLayout onClick={() => scope.textModal(true, '04')}>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData4?.cnt_tot} = FM: {scope.state.trailData4?.fm_tot},
                            다년: {scope.state.trailData4?.long_tot}, 일반: {scope.state.trailData4?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData4?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData4?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData4?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData3?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data4}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>5 &nbsp;</div>
                <div>May</div>
              </FlexLayout>

              <FlexLayout onClick={() => scope.textModal(true, '05')}>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData5?.cnt_tot} = FM: {scope.state.trailData5?.fm_tot},
                            다년: {scope.state.trailData5?.long_tot}, 일반: {scope.state.trailData5?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData5?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData5?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData5?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData5?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data5}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>6 &nbsp;</div>
                <div>June</div>
              </FlexLayout>

              <FlexLayout onClick={() => scope.textModal(true, '06')}>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData6?.cnt_tot} = FM: {scope.state.trailData6?.fm_tot},
                            다년: {scope.state.trailData6?.long_tot}, 일반: {scope.state.trailData6?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData6?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData6?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData6?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData6?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data6}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      }

      { scope.state.gubun !== '11'
        ? <FlexLayout>
        <FlexLayout
          isVertical={true}
          size={300}
          style={{ height: 250 }}
        >
          <FlexLayout
            justify="center"
            align="center"
            style={{ fontSize: 20 }}
            weight={0.1}
          >
            <div style={{ color: 'var(--color-red)' }}>7 &nbsp;</div>
            <div>July</div>
          </FlexLayout>

          <FlexLayout onClick={() => scope.textModal(true, '07')}>
            <GridLayout
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 60,
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      isVertical={true}
                      size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                    >
                      <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                        scope.state.trailData7?.cnt_tot} = FM: {scope.state.trailData7?.fm_tot},
                        다년: {scope.state.trailData7?.long_tot}, 일반: {scope.state.trailData7?.long_tot}</FlexLayout>
                      <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                        scope.state.trailData7?.bemisamt_tot}</FlexLayout>
                      <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                        scope.state.trailData7?.totchasamt_tot}</FlexLayout>
                      <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                        scope.state.trailData7?.pro_tot}</FlexLayout>
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'amt',
                  text: '금액',
                  width: 20,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {scope.state.trailData7?.amt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.amt)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.data7}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout>
          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>8 &nbsp;</div>
              <div>August</div>
            </FlexLayout>

            <FlexLayout onClick={() => scope.textModal(true, '08')}>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData8?.cnt_tot} = FM: {scope.state.trailData8?.fm_tot},
                          다년: {scope.state.trailData8?.long_tot}, 일반: {scope.state.trailData8?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData8?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData8?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                          scope.state.trailData8?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData8?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data8}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>9 &nbsp;</div>
              <div>September</div>
            </FlexLayout>

            <FlexLayout onClick={() => scope.textModal(true, '09')}>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData9?.cnt_tot} = FM: {scope.state.trailData9?.fm_tot},
                          다년: {scope.state.trailData9?.long_tot}, 일반: {scope.state.trailData9?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData9?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData9?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                          scope.state.trailData9?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData9?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data9}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>10 &nbsp;</div>
              <div>October</div>
            </FlexLayout>

            <FlexLayout onClick={() => scope.textModal(true, '10')}>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData10?.cnt_tot} = FM: {scope.state.trailData10?.fm_tot},
                          다년: {scope.state.trailData10?.long_tot}, 일반: {scope.state.trailData10?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData10?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData10?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                          scope.state.trailData10?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData10?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data10}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>11 &nbsp;</div>
              <div>November</div>
            </FlexLayout>

            <FlexLayout onClick={() => scope.textModal(true, '11')}>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData11?.cnt_tot} = FM: {scope.state.trailData11?.fm_tot},
                          다년: {scope.state.trailData11?.long_tot}, 일반: {scope.state.trailData11?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData11?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData11?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                          scope.state.trailData11?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData11?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data11}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>12 &nbsp;</div>
              <div>December</div>
            </FlexLayout>

            <FlexLayout onClick={() => scope.textModal(true, '12')}>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData12?.cnt_tot} = FM: {scope.state.trailData12?.fm_tot},
                          다년: {scope.state.trailData12?.long_tot}, 일반: {scope.state.trailData12?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData12?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData12?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                          scope.state.trailData12?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '호기',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.equpnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data12}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
        : <FlexLayout>
          <FlexLayout
            isVertical={true}
            size={300}
            style={{ height: 250 }}
          >
            <FlexLayout
              justify="center"
              align="center"
              style={{ fontSize: 20 }}
              weight={0.1}
            >
              <div style={{ color: 'var(--color-red)' }}>7 &nbsp;</div>
              <div>July</div>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 60,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        isVertical={true}
                        size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                      >
                        <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                          scope.state.trailData7?.cnt_tot} = FM: {scope.state.trailData7?.fm_tot},
                          다년: {scope.state.trailData7?.long_tot}, 일반: {scope.state.trailData7?.long_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                          scope.state.trailData7?.bemisamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                          scope.state.trailData7?.totchasamt_tot}</FlexLayout>
                        <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                          scope.state.trailData7?.pro_tot}</FlexLayout>
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'contnm',
                    text: '구분',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.contnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'amt',
                    text: '금액',
                    width: 20,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.trailData7?.amt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.amt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.data7}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowDoubleClick={() => scope.textModal(true, '07')}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>8 &nbsp;</div>
                <div>August</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData8?.cnt_tot} = FM: {scope.state.trailData8?.fm_tot},
                            다년: {scope.state.trailData8?.long_tot}, 일반: {scope.state.trailData8?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData8?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData8?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData8?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData8?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data8}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onRowDoubleClick={() => scope.textModal(true, '08')}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>9 &nbsp;</div>
                <div>September</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData9?.cnt_tot} = FM: {scope.state.trailData9?.fm_tot},
                            다년: {scope.state.trailData9?.long_tot}, 일반: {scope.state.trailData9?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData9?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData9?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData9?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData9?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data9}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onRowDoubleClick={() => scope.textModal(true, '09')}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>10 &nbsp;</div>
                <div>October</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData10?.cnt_tot} = FM: {scope.state.trailData10?.fm_tot},
                            다년: {scope.state.trailData10?.long_tot}, 일반: {
                            scope.state.trailData10?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData10?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData10?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData10?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData10?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data10}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onRowDoubleClick={() => scope.textModal(true, '10')}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>11 &nbsp;</div>
                <div>November</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData11?.cnt_tot} = FM: {scope.state.trailData11?.fm_tot},
                            다년: {scope.state.trailData11?.long_tot
                          }, 일반: {scope.state.trailData11?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData11?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData11?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData11?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData11?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data11}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onRowDoubleClick={() => scope.textModal(true, '11')}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                justify="center"
                align="center"
                style={{ fontSize: 20 }}
                weight={0.1}
              >
                <div style={{ color: 'var(--color-red)' }}>12 &nbsp;</div>
                <div>December</div>
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={[
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 60,
                      trail: () => (
                        <FlexLayout
                          justify="left"
                          align="center"
                          isVertical={true}
                          size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}
                        >
                          <FlexLayout style={{ color: 'var(--color-blue)' }}> 합계: {
                            scope.state.trailData12?.cnt_tot} = FM: {scope.state.trailData12?.fm_tot},
                            다년: {scope.state.trailData12?.long_tot}, 일반: {
                            scope.state.trailData12?.long_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 실적: {
                            scope.state.trailData12?.bemisamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#7F7A2C' }}> 누적증가액: {
                            scope.state.trailData12?.totchasamt_tot}</FlexLayout>
                          <FlexLayout style={{ color: '#8106aa' }}> 예상보수료: {
                            scope.state.trailData12?.pro_tot}</FlexLayout>
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.actnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'contnm',
                      text: '구분',
                      width: 20,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {x.contnm}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'amt',
                      text: '금액',
                      width: 20,
                      trail: () => (
                        <FlexLayout
                          justify="right"
                          align="center"
                          style={{ color: 'var(--color-blue)' }}
                        >
                          {scope.state.trailData12?.amt_tot}
                        </FlexLayout>
                      ),
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.amt)}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.data12}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onRowDoubleClick={() => scope.textModal(true, '12')}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      }

      <ModalView
        isVisible={scope.state.modalState}
        onClose={() => scope.textModal(false, '0')}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.popupDate?.title} &nbsp; {scope.state.popupDate?.mon.slice(4, 6)} 월</LayoutTitle>

          <FlexLayout weight={8}>
            <TableLayout
              ref={scope.table}
              header={[
                {
                  id: 'gubun',
                  text: '매출구분',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.popupDate?.total}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계: {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seq',
                  text: 'No',
                  width: 80,
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 80,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      textAlign="center"
                      value={x.qty}
                      onChange={(value) => rowUpdate({ qty: value })}
                    />
                  ),
                },
                {
                  id: 'cnam',
                  text: '청구주기',
                  width: 80,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.cnam}
                      data={[
                        { value: '01', remark: '월' },
                        { value: '02', remark: '분기' },
                        { value: '03', remark: '반기' },
                        { value: '04', remark: '년' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ cnam: option.value })}
                    />
                  ),
                },
                {
                  id: 'flag',
                  text: '구분',
                  width: 100,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.flag}
                      style={{ color: x.flag === '2' ? 'var(--color-red)' : '' }}
                      data={[
                        { value: '0', remark: '신규' },
                        { value: '1', remark: '만료' },
                        { value: '2', remark: '저가' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ flag: option.value })}
                    />
                  ),
                },
                {
                  id: 'contgubun',
                  text: '이전계약',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {contgs.filter((y) => y.value === x.contgubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'besamt',
                  text: '이전보수료',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.besamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.besamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qtyamt',
                  text: '이전보수(대당가)',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.qtyamt_tot}
                    </FlexLayout>
                  ),
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      textAlign="right"
                      value={x.qtyamt}
                      onChange={(value) => rowUpdate({ qtyamt: value })}
                    />
                  ),
                },
                {
                  id: 'contgubun',
                  text: '계획계약',
                  width: 100,
                  render: (x, rowUpdate) => (
                    <ComboBox
                      value={x.contgubun}
                      style={{
                        color: x.contgubun === '03' ? 'var(--color-red)'
                          : x.contgubun === '01' ? 'var(--color-blue)'
                            : x.contgubun === '02' ? '#7F7A2C' : '',
                      }}
                      data={[
                        { value: '01', remark: 'FM계약' },
                        { value: '02', remark: '다년계약' },
                        { value: '03', remark: '일반계약' },
                        { value: '04', remark: '상주' },
                        { value: '05', remark: '외주' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(option) => rowUpdate({ contgubun: option.value })}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '인상보수료',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.samt_tot}
                    </FlexLayout>
                  ),
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      textAlign="right"
                      value={x.samt}
                      color={'var(--color-blue)'}
                      onChange={(value) => rowUpdate({ samt: value })}
                    />
                  ),
                },
                {
                  id: 'chasamt',
                  text: '증가액',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.popupDate?.chasamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(x.chasamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '계획내용',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalNewEvent()}>신규</Button>
              <Button onClick={() => scope.modalDelete()}>삭제</Button>
              <Button onClick={() => scope.modalSave()}>저장</Button>
              <Button onClick={() => scope.textModal(false, '0')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.modalState2}
        onClose={() => scope.textModal(false, '0')}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.popupDate?.title} &nbsp; {scope.state.popupDate?.mon.slice(4, 6)} 월</LayoutTitle>

          <FlexLayout weight={8}>
            <TableLayout
              ref={scope.table2}
              header={[
                {
                  id: 'gubun',
                  text: '매출구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seq',
                  text: 'No',
                  width: 80,
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'equpcd',
                  text: '호기코드',
                  width: 75,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E611',
                        { uv_arg1: x.actcd },
                        true,
                        (item) => {
                          rowUpdate({
                            equpcd: item.cd,
                            equpnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기명',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpnm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpnm: value })}
                    />
                  ),
                },
                {
                  id: 'flag',
                  text: '구분',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={ x.flag === '5' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                    >
                      {x.flag === '3' ? '기능개선'
                        : x.flag === '4' ? '공사없음' : '다발고장'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '계획금액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '계획내용',
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalNewEvent()}>신규</Button>
              <Button onClick={() => scope.modalDelete()}>삭제</Button>
              <Button onClick={() => scope.modalSave()}>저장</Button>
              <Button onClick={() => scope.textModal(false, '0')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.modalState3}
        onClose={() => scope.textModal(false, '0')}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.popupDate?.title} &nbsp; {scope.state.popupDate?.mon.slice(4, 6)} 월</LayoutTitle>

          <FlexLayout weight={8}>
            <TableLayout
              ref={scope.table3}
              header={[
                {
                  id: 'gubun',
                  text: '매출구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seq',
                  text: 'No',
                  width: 80,
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ actcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            actcd: item.cd,
                            actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.actnm}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ actnm: value })}
                    />
                  ),
                },
                {
                  id: 'equpcd',
                  text: '호기코드',
                  width: 75,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpcd}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpcd: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E611',
                        { uv_arg1: x.actcd },
                        true,
                        (item) => {
                          rowUpdate({
                            equpcd: item.cd,
                            equpnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'equpnm',
                  text: '호기명',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.equpnm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ equpnm: value })}
                    />
                  ),
                },
                {
                  id: 'flag',
                  text: '구분',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={ x.flag === '5' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                    >
                      {x.flag === '3' ? '기능개선'
                        : x.flag === '4' ? '공사없음' : '다발고장'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '계획금액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '계획내용',
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
            />
          </FlexLayout>

          <ModalView
            isVisible={scope.state.modalState4}
            onClose={() => scope.textModal(false, '0')}
            width={1400}
            zIndex={-2}
          >
            <FlexLayout
              isVertical={true}
              style={{ padding: 8 }}
            >
              <LayoutTitle>{scope.state.popupDate?.title} &nbsp; {scope.state.popupDate?.mon.slice(4, 6)} 월</LayoutTitle>

              <FlexLayout weight={8}>
                <TableLayout
                  ref={scope.table2}
                  header={[
                    {
                      id: 'gubun',
                      text: '매출구분',
                      width: 100,
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'seq',
                      text: 'No',
                      width: 80,
                    },
                    {
                      id: 'actcd',
                      text: '현장코드',
                      width: 100,
                      color: 'var(--color-red)',
                      render: (x, rowUpdate, ref) => (
                        <TextBox
                          ref={ref}
                          value={x.actcd}
                          textAlign="center"
                          onChange={(value) => rowUpdate({ actcd: value })}
                          bindSearchModal={new SearchBinding(
                            scope.props.modalStore,
                            'TB_E601_1',
                            {},
                            true,
                            (item) => {
                              rowUpdate({
                                actcd: item.cd,
                                actnm: item.cdnm,
                              });
                            },
                          )}
                        />
                      ),
                    },
                    {
                      id: 'actnm',
                      text: '현장명',
                      width: 200,
                      render: (x, rowUpdate, ref) => (
                        <TextBox
                          ref={ref}
                          value={x.actnm}
                          textAlign="left"
                          onChange={(value) => rowUpdate({ actnm: value })}
                        />
                      ),
                    },
                    {
                      id: 'equpcd',
                      text: '호기코드',
                      width: 75,
                      color: 'var(--color-red)',
                      render: (x, rowUpdate, ref) => (
                        <TextBox
                          ref={ref}
                          value={x.equpcd}
                          textAlign="center"
                          onChange={(value) => rowUpdate({ equpcd: value })}
                          bindSearchModal={new SearchBinding(
                            scope.props.modalStore,
                            'TB_E611',
                            { uv_arg1: x.actcd },
                            true,
                            (item) => {
                              rowUpdate({
                                equpcd: item.cd,
                                equpnm: item.cdnm,
                              });
                            },
                          )}
                        />
                      ),
                    },
                    {
                      id: 'equpnm',
                      text: '호기명',
                      render: (x, rowUpdate, ref) => (
                        <TextBox
                          ref={ref}
                          value={x.equpnm}
                          textAlign="center"
                          onChange={(value) => rowUpdate({ equpnm: value })}
                        />
                      ),
                    },
                    {
                      id: 'flag',
                      text: '구분',
                      render: (x) => (
                        <FlexLayout
                          justify="center"
                          align="center"
                          style={ x.flag === '5' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                        >
                          {x.flag === '3' ? '기능개선'
                            : x.flag === '4' ? '공사없음' : '다발고장'}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'samt',
                      text: '계획금액',
                      render: (x) => (
                        <FlexLayout
                          justify="right"
                          align="center"
                        >
                          {Format.number(x.samt)}
                        </FlexLayout>
                      ),
                    },
                    {
                      id: 'remark',
                      text: '계획내용',
                      render: (x) => (
                        <FlexLayout
                          justify="left"
                          align="center"
                        >
                          {x.remark}
                        </FlexLayout>
                      ),
                    },
                  ]}
                  data={scope.state.popupList}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  onChange={(rows, updatedRows) => {
                    scope.onUpdatedRows(rows, updatedRows);
                  }}
                  onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
                />
              </FlexLayout>

              <SubHeader>
                <FlexLayout justify="end">
                  <Button onClick={() => scope.modalNewEvent()}>신규</Button>
                  <Button onClick={() => scope.modalDelete()}>삭제</Button>
                  <Button onClick={() => scope.modalSave()}>저장</Button>
                  <Button onClick={() => scope.textModal(false, '0')}>닫기</Button>
                </FlexLayout>
              </SubHeader>
            </FlexLayout>
          </ModalView>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalNewEvent()}>신규</Button>
              <Button onClick={() => scope.modalDelete()}>삭제</Button>
              <Button onClick={() => scope.modalSave()}>저장</Button>
              <Button onClick={() => scope.textModal(false, '0')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
