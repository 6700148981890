import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e401
 * @category 고장접수등록
 */

export class BreakFieldModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly rptnum: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly carcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly wkcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly wktext: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly frtime: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly totime: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly km: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   *+
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly dedunum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly lkdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly lknum: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  readonly recenum: string;

  /**
   *  고장일자
   *
   * **data type** varchar(?)
   */
  readonly datetime2: string;

  /**
   *  고장시간
   *
   * **data type** varchar(?)
   */
  readonly hitchhour: string;

  /**
   *  담당자
   *
   * **data type** varchar(?)
   */
  readonly actperid: string;

  /**
   *  장기미수금
   *
   * **data type** varchar(?)
   */
  readonly misamt03: string;

  /**
   *  현장주소
   *
   * **data type** varchar(?)
   */
  readonly zipcode: string;

  /**
   *  현장주소
   *
   * **data type** varchar(?)
   */
  readonly address: string;

  /**
   *  현장주소
   *
   * **data type** varchar(?)
   */
  readonly address2: string;

  /**
   *  현장담당자
   *
   * **data type** varchar(?)
   */
  readonly actpernm: string;

  /**
   *  호기명
   *
   * **data type** varchar(?)
   */
  readonly elno: string;

  /**
   *  고장내용
   *
   * **data type** varchar(?)
   */
  readonly contcd: string;

  /**
   *  고장내용
   *
   * **data type** varchar(?)
   */
  readonly contnm: string;

  /**
   *  점검처리
   *
   * **data type** varchar(?)
   */
  readonly planstate: string;

  /**
   *  고장상세내용
   *
   * **data type** varchar(?)
   */
  readonly contents: string;

  /**
   *  사람갇힘일자
   *
   * **data type** varchar(?)
   */
  readonly datetime3: string;

  /**
   *  갇힘시간
   *
   * **data type** varchar(?)
   */
  readonly troubletime: string;

  /**
   *  갇힘사람수
   *
   * **data type** varchar(?)
   */
  readonly troublesu: string;

  /**
   *  접수자
   *
   * **data type** varchar(?)
   */
  readonly reperid: string;

  /**
   *  접수자명
   *
   * **data type** varchar(?)
   */
  readonly repernm: string;

  /**
   *  배정예정자
   *
   * **data type** varchar(?)
   */
  readonly perid: string;

  /**
   *  배정예정자
   *
   * **data type** varchar(?)
   */
  readonly pernm: string;

  /**
   *  배정자부서
   *
   * **data type** varchar(?)
   */
  readonly divicd: string;

  /**
   *  배정자부서
   *
   * **data type** varchar(?)
   */
  readonly divinm: string;

  /**
   *  배정자근태
   *
   * **data type** varchar(?)
   */
  readonly atdnm: string;

  /**
   *  배정자구분
   *
   * **data type** varchar(?)
   */
  readonly okflag: string;

  /**
   *  근거리직원1
   *
   * **data type** varchar(?)
   */
  readonly nearpernm1: string;

  /**
   *  근거리직원1
   *
   * **data type** varchar(?)
   */
  readonly rdistance1: string;

  /**
   *  근거리직원2
   *
   * **data type** varchar(?)
   */
  readonly nearpernm2: string;

  /**
   *  근거리직원2
   *
   * **data type** varchar(?)
   */
  readonly rdistance2: string;

  /**
   *  근거리직원3
   *
   * **data type** varchar(?)
   */
  readonly nearpernm3: string;

  /**
   *  근거리직원3
   *
   * **data type** varchar(?)
   */
  readonly rdistance3: string;

  /**
   *  고객안심문자
   *
   * **data type** varchar(?)
   */
  readonly recetelnum: string;

  /**
   *  접수시간
   *
   * **data type** varchar(?)
   */
  readonly recetime: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.perid = data.perid || '';
    this.rptnum = data.rptnum || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.carcd = data.carcd || '';
    this.wkcd = data.wkcd || '';
    this.wktext = data.wktext || '';
    this.frtime = data.frtime || '';
    this.totime = data.totime || '';
    this.km = data.km || '';
    this.remark = data.remark || '';
    this.plandate = data.plandate || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.edudate = data.edudate || '';
    this.dedunum = data.dedunum || '';
    this.lkdate = data.lkdate || '';
    this.lknum = data.lknum || '';
    this.rptdate = data.rptdate || '';
    this.datetime2 = data.datetime2 || '';
    this.recenum = data.recenum || '';
    this.recetime = data.recetime || '';
    this.hitchhour = data.hitchhour || '';
    this.actperid = data.actperid || '';
    this.misamt03 = data.misamt03 || '';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.actpernm = data.actpernm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.contcd = data.contcd || '';
    this.contnm = data.contnm || '';
    this.planstate = data.planstate || '';
    this.contents = Fix.newline(data.contents) || '';
    this.datetime3 = data.datetime3 || '';
    this.troubletime = data.troubletime || '';
    this.troublesu = data.troublesu || '';
    this.reperid = data.reperid || '';
    this.repernm = data.repernm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.atdnm = data.atdnm || '';
    this.okflag = data.okflag || '';
    this.nearpernm1 = data.nearpernm1 || '';
    this.nearpernm2 = data.nearpernm2 || '';
    this.nearpernm3 = data.nearpernm3 || '';
    this.rdistance1 = data.rdistance1 || '';
    this.rdistance2 = data.rdistance2 || '';
    this.rdistance3 = data.rdistance3 || '';
    this.recetelnum = data.recetelnum || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
