import * as React from 'react';
import {
  AttachmentBox, FlexLayout, GridLayout, IFrame, ImageView, SearchLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { FloorPlan } from './FloorPlan';
import { FloorPlanGridHeader } from './headers/FloorPlanGridHeader';
import { PREVIEW_OPTION } from '../../../../components/forms/AttachmentBox/AttachmentBox';

/**
 * 화면
 * @window w_tb_e601_dp_01
 * @category 보수현장도면
 */
export const FloorPlanTemplate: React.FC<TemplateProps<FloorPlan>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>현장명</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>파일명</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="파일명으로 검색하세요"
            value={scope.state.searchFilenm}
            onChange={(value) => setData({ searchFilenm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            header={FloorPlanGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={2}
        >
          <FlexLayout>
            {scope.state.content?.appnum && <AttachmentBox
                appnum={scope.state.content.appnum}
                appnm={'설치현장도면'}
                onPreview={(type, uri) => scope.setState({ preview: type, iframe: uri })}
            />}
          </FlexLayout>

          <FlexLayout weight={2}>
            {scope.state.preview === PREVIEW_OPTION.IMAGE
            && <ImageView src={scope.state.iframe} style={{ marginBottom: 0 }} />}
            {scope.state.preview === PREVIEW_OPTION.PDF
            && <IFrame src={scope.state.iframe} />}
            {scope.state.preview === PREVIEW_OPTION.NONE
            && <FlexLayout
              align="center"
              justify="center"
            >
              dwg 확장자는 서비스가 종료되었습니다. <br />해당내역을 다운받아 도면프로그램에서 확인가능합니다.
            </FlexLayout>}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
