import * as React from 'react';
import { FiTrash, FiUpload } from 'react-icons/fi';
import {
  Button, FlexLayout, GridLayout, ImageView, LoaderButton, SearchLayout, TabLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Worker } from './Worker';
import { WorkerTab } from './Worker.tab';
import { WorkerGridHeader } from './headers/WorkerGridHeader';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTemplate: React.FC<TemplateProps<Worker>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="성명으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout
          size={250}
          isVertical={true}
        >
          <FlexLayout size={350}>
            <ImageView
              src={scope.state.img}
              footer={
                <React.Fragment>
                  <LoaderButton
                    isFull={true}
                    onClick={() => scope.imageSelect()}
                    percent={scope.state.percentImage}
                  >
                    <FiUpload />
                    <span>등록</span>
                  </LoaderButton>

                  <Button
                    isFull={true}
                    theme={BUTTON_THEMES.RED}
                    onClick={() => scope.imageDelete()}
                  >
                    <FiTrash />
                    <span>삭제</span>
                  </Button>
                </React.Fragment>
              }
            />
          </FlexLayout>

          <FlexLayout>
            <GridLayout
              ref={scope.grid}
              header={WorkerGridHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.data}
              infinityHandler={scope.infinity}
              onRowClick={(item, index) => scope.onGridRowClicked(item, index)}
            />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout>
          <TabLayout
            scope={scope}
            tabs={WorkerTab}
            onChange={(_, index) => scope.onTabChange(index)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
