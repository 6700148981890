import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  FormatTextBox,
  GridLayout,
  Button,
  LabelBox,
  ModalView,
  AttachmentBox,
  ComboBox,
  ComboBoxModel,
  TelnumTextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Holiday, HolidayItemChangeTypes,
} from './Holiday';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import style from './Holiday.module.scss';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_pb204
 * @category 휴가신청서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

const yearflags = [
  { value: '0', remark: '미사용' },
  { value: '1', remark: '연차' },
  { value: '2', remark: '대체휴무' },
  { value: '3', remark: '연차, 대체휴무' },
];

export const HolidayTemplate: React.FC<TemplateProps<Holiday>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout weight={5}>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>년도</span>
              <DateTextBox
                value={scope.state.year}
                textAlign="center"
                format="####"
                onChange={(value) => scope.setState({ year: value })}
                onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      scope.setState({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      scope.setState({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            {
              scope.state.kukcd === '01'
                ? <FlexLayout size={250}>
                  <span style={{ color: 'var(--color-red)' }}>사원명</span>
                  <TextBox
                    value={scope.state.perid}
                    textAlign="center"
                    onChange={(value) => scope.setState({ perid: value })}
                    onEnter={() => scope.onRetrieveEvent()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          perid: item.cd,
                          pernm: item.cdnm,
                        });
                        scope.onRetrieveEvent();
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state.pernm}
                    weight={1.5}
                    textAlign="center"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </FlexLayout>
                : ''
            }

            <FlexLayout/>
            <Button onClick={() => scope.onReport()}>
              <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>


      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'reqdate',
                text: '작성일자',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.reqdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'vafrdate',
                text: '시작일',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.vafrdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'vatodate',
                text: '종료일',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.vatodate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'usenum',
                text: '휴가일',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.usenum}
                  </FlexLayout>
                ),
              },
              {
                id: 'daynum2',
                text: (
                  <FlexLayout
                    align="start"
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      연차
                    </FlexLayout>

                    <FlexLayout>
                      사용일수
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 4,
                render: (x, ref) => (
                  <FlexLayout
                    ref={ref}
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-green)' }}
                  >
                    {x.daynum === '0' ? '' : x.daynum}
                  </FlexLayout>
                ),
              },
              {
                id: 'workdaynum2',
                text: (
                  <FlexLayout
                    align="start"
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      대체
                    </FlexLayout>

                    <FlexLayout>
                      사용일수
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 4,
                render: (x, ref) => (
                  <FlexLayout
                    ref={ref}
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {x.workdaynum2 === '0' ? '' : parseInt(x.workdaynum2, 10).toFixed(1)}
                  </FlexLayout>
                ),
              },
              {
                id: 'atdcd',
                text: '신청구분',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {scope.state.atdcds.filter((y) => y.atdcd === x.atdcd)[0]?.atdnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gowhere',
                text: '제목',
                width: 20,
                render: (x, ref) => (
                  <FlexLayout
                    ref={ref}
                    justify="left"
                    align="center"
                  >
                    {x.gowhere}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 7,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 27 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={25}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.holidayList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <div className={style.container}>
              <div className={style.header}>
                {scope.state.year}년
              </div>
              <div className={style.contents}>
                <div style={{ color: 'cyan' }}>
                  <span className={style.contents_label}>연차일수 :</span>
                  <span className={style.contents_data}>
                    {/* eslint-disable-next-line radix */}
                    {(scope.state.daynum_tot)}
                  </span>
                </div>
                <div style={{ color: 'greenyellow' }}>
                  <span className={style.contents_label}>연차사용일수 :</span>
                  <span className={style.contents_data}>
                    {/* eslint-disable-next-line radix */}
                    {(scope.state.daynum_use_tot)}
                  </span>
                </div>
                <div style={{ color: 'gold' }}>
                  <span className={style.contents_label}>연차남은일수 :</span>
                  <span className={style.contents_data}>
                    {scope.state.daynum_remain_tot}
                  </span>
                </div>
                <div style={{ color: 'cyan' }}>
                  <span className={style.contents_label}>대체근무일수 :</span>
                  <span className={style.contents_data}>
                    {scope.state.workdaynum_tot}
                  </span>
                </div>
                <div style={{ color: 'greenyellow' }}>
                  <span className={style.contents_label}>대체근무사용일수 :</span>
                  <span className={style.contents_data}>
                    {scope.state.workdaynum_use_tot}
                  </span>
                </div>
                <div style={{ color: 'gold' }}>
                  <span className={style.contents_label}>대체근무남은일수 :</span>
                  <span className={style.contents_data}>
                    {scope.state.workdaynum_remain_tot}
                  </span>
                </div>
              </div>
            </div>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.reqdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ reqdate: value })}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format={model?.appnum && 'DM-######-###-####'}
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="신청구분"
              isNeed={true}
            >
              <ComboBox
                value={model?.atdcd}
                textAlign="center"
                data={scope.state.atdcds.map((y) => new ComboBoxModel(y.atdcd, y.atdnm))}
                onSelect={
                  (option) => setData({ atdcd: option.value },
                    () => scope.itemChanged(HolidayItemChangeTypes.ATDCD))
                }
              />
            </LabelBox>

            <LabelBox
              title="신청기간"
              weight={2}
            >
              <DateTextBox
                value={model?.vafrdate}
                textAlign="center"
                format="####-##-##"
                onChange={
                  (value) => setData({ vafrdate: value, vatodate: value },
                    () => scope.itemChanged(HolidayItemChangeTypes.VAFRDATE))
                }
              />
              <span style={{
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}> ~
              </span>
              <DateTextBox
                value={model?.vatodate}
                textAlign="center"
                format="####-##-##"
                onChange={
                  (value) => setData({ vatodate: value },
                    () => scope.itemChanged(HolidayItemChangeTypes.VATODATE))
                }
              />
            </LabelBox>
            <LabelBox title="시간">
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.vafrtime}
                readonly={model?.appgubun !== ''}
                onChange={(value) => setData({ vafrtime: value })}
                onBlur={() => scope.itemChanged(HolidayItemChangeTypes.VAFRTIME)}
              />
              <span style={{
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}> ~
              </span>
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.vatotime}
                readonly={model?.appgubun !== ''}
                onChange={(value) => setData({ vatotime: value })}
                onBlur={() => scope.itemChanged(HolidayItemChangeTypes.VATOTIME)}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="사용구분"
              weight={3}
            >
              <TextBox
                textAlign='center'
                value={yearflags.filter((y) => y.value === model?.yearflag)[0]?.remark}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="휴가일수"
              weight={2}
            >
              <TextBox
                color='var(--color-red)'
                textAlign='right'
                value={`${model?.gigan} 일`}
                onChange={(value) => setData({ gigan: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="연차사용일수"
              weight={2}
            >
              <TextBox
                color='var(--color-green)'
                textAlign='right'
                value={`${model?.daynum} 일`}
                onChange={(value) => setData({ daynum: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="대체사용일수"
              weight={2}
            >
              <TextBox
                color='var(--color-blue)'
                textAlign='right'
                value={`${model?.workdaynum} 일`}
                onChange={(value) => setData({ workdaynum: value })}
              />
            </LabelBox>
            <LabelBox
              title="연락처"
              weight={3}
            >
              <TelnumTextBox
                value={model?.telnum}
                textAlign="center"
                onChange={(value) => setData({ telnum: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="제목" >
              <TextBox
                value={model?.gowhere}
                onChange={(value) => setData({ gowhere: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox title="사유" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={model?.attcnt === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data?.appnum}
            appnm={'휴가신청서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });
            }}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
