import { IconType } from 'react-icons';
import {
  AiOutlineQuestion,
  MdAssessment,
  MdAssignment,
  MdBuild,
  MdExtension,
  MdInfo,
  MdMonetizationOn,
  MdPeople,
  MdSettingsInputComponent,
} from 'react-icons/all';
import { action, makeObservable, observable } from 'mobx';
import { Category } from '../../constants';

export default class MenuModel {
  readonly id: string;

  readonly iconType?: string;

  readonly text?: string;

  readonly path?: string;

  readonly level?: string;

  readonly category?: number;

  @observable isEnabled?: boolean; // Open/Close

  parent: MenuModel | undefined;

  children?: MenuModel[];

  view?: React.Component;

  constructor(
    id: string,
    icon: string,
    text?: string,
    path?: string,
    level?: string,
    category?: number,
    isEnabled?: boolean,
    parent?: MenuModel,
    children?: MenuModel[],
    view?: React.Component,
  ) {
    this.id = id;
    this.iconType = icon;
    this.text = text || '##';
    this.path = path || '/';
    this.level = level || '1';
    this.category = category ?? Category.COMMON;
    this.isEnabled = isEnabled || false;
    this.parent = parent;
    this.children = children || [];
    this.view = view || undefined;

    makeObservable(this);
  }

  @action
  enable(value: boolean) {
    this.isEnabled = value;
  }

  get icon(): IconType {
    switch (this.iconType) {
      case 'AiOutlineQuestion':
        return AiOutlineQuestion;
      case 'MdAssessment':
        return MdAssessment;
      case 'MdAssignment':
        return MdAssignment;
      case 'MdBuild':
        return MdBuild;
      case 'MdExtension':
        return MdExtension;
      case 'MdInfo':
        return MdInfo;
      case 'MdMonetizationOn':
        return MdMonetizationOn;
      case 'MdPeople':
        return MdPeople;
      case 'MdSettingsInputComponent':
        return MdSettingsInputComponent;
    }
    return AiOutlineQuestion;
  }
}
