import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { TemplateProps } from '../../../../../constants';
import { Result } from '../Result';
import { ResultGridHeader } from '../headers/ResultGridHeader';

/**
 * 화면 > 탭 A
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export const ResultTabTemplate: React.FC<TemplateProps<Result>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.grid}
      infinityHandler={scope.infinity}
      header={ResultGridHeader(scope)}
      headerHeight={46}
      rowHeight={75}
      data={scope.state.data}
      onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
      onRowDoubleClick={() => scope.openModal()}
    />
  </FlexLayout>
);
