import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
} from 'react-icons/all';
import {
  GridLayoutHeader, PageProps, PageToolEvents, RetrieveFocusType,
} from '../../../../constants';
import {
  BreakPartModel,
  BreakContentModel,
  BreakFactorsModel,
  BreakCauseModel,
  ProcessContentModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { BreakAnalysisOneTemplate } from './BreakAnalysisOne.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderPart,
  TabHeaderContent,
  TabHeaderFactors,
  TabHeaderCause,
  TabHeaderProcess,
} from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import Date8Calculator from '../../../../utils/time/Date8Calculator';
import { PageComponent } from '../../../../utils';
import { BreakAnalysisAllTabDataStateNames } from '../BreakAnalysisAll/BreakAnalysisAll';

export enum BreakAnalysisOneTabId {
  Part,
  Content,
  Factors,
  Cause,
  Process,
}

export const BreakAnalysisOneTabTitles = [
  '고장부위별',
  '고장내용별',
  '고장요인별',
  '고장원인별',
  '처리내용별',
];

export const BreakAnalysisOneTabModels = [
  BreakPartModel,
  BreakContentModel,
  BreakFactorsModel,
  BreakCauseModel,
  ProcessContentModel,
];

export const BreakAnalysisOneTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
];

export const BreakAnalysisOneTabDataStateNames = [
  'partList',
  'contentlist',
  'factorslist',
  'causelist',
  'processlist',
];

export const BreakAnalysisOneTabUpdatesStateNames = [
  'PartsUpdates',
  'ContentsUpdates',
  'FactorsUpdates',
  'CausesUpdates',
  'ProcessUpdates',
];

export const BreakAnalysisOneTabFocusedStateNames = [
  'PartsFocused',
  'ContentsFocused',
  'FactorsFocused',
  'CausesFocused',
  'ProcessFocused',
];

export interface BreakAnalysisOneState {
  partList?: Array<BreakPartModel>; // 고장부위별 리스트
  PartUpdates?: Array<BreakPartModel>;
  PartsFocused?: BreakPartModel;
  contentlist?: Array<BreakContentModel>; // 고장내용별 리스트
  ContentUpdates?: Array<BreakContentModel>;
  ContentsFocused?: BreakContentModel;
  factorslist?: Array<BreakFactorsModel>; // 고장요인별 리스트
  FactorsUpdates?: Array<BreakFactorsModel>;
  FactorsFocused?: BreakFactorsModel;
  causelist?: Array<BreakCauseModel>; // 고장원인별 리스트
  CauseUpdates?: Array<BreakCauseModel>;
  CausesFocused?: BreakCauseModel;
  processlist?: Array<ProcessContentModel>; // 처리내용별 리스트
  ProcessUpdates?: Array<ProcessContentModel>;
  ProcessFocused?: ProcessContentModel;

  focusedTab?: TabModel;

  focuseddata?: any; // 포커스 이벤트 용

  // 검색조건
  searchQuery?: string; // 검색어
  stdate?: string; // 기간(시작)
  enddate?: string; // 기간(끝)
  perid?: string; // 담당자
  pernm?: string; // 담당자명
  divicd?: string; // 부서코드
  divinm?: string; // 부서명
  gubun?: string;

  holderName?: string; // placeholder 내용

  // URL
  url?: string;
  url2?: string;
  url3?: string;
  url4?: string;
  url5?: string;

  tot_tot?: string;
  qty_tot?: string;
  banqty_tot?: string;
}
/**
 * 컨트롤러
 * @window w_tb_e411w_05
 * @category 고장분석현황[현장별]
 */
export class BreakAnalysisOne extends PageComponent<PageProps, BreakAnalysisOneState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  tapChk?: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = BreakAnalysisOneTabId;
    const titles = BreakAnalysisOneTabTitles;

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    this.tabs = [
      new TabModel(id.Part.toString(), MdReorder, titles[id.Part]),
      new TabModel(id.Content.toString(), MdReceipt, titles[id.Content]),
      new TabModel(id.Factors.toString(), MdReceipt, titles[id.Factors]),
      new TabModel(id.Cause.toString(), MdReceipt, titles[id.Cause]),
      new TabModel(id.Process.toString(), MdReceipt, titles[id.Process]),
    ];

    this.tabHeaders = [
      TabHeaderPart,
      TabHeaderContent,
      TabHeaderFactors,
      TabHeaderCause,
      TabHeaderProcess,
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      // stdate: `${year}${month}${date}`,
      stdate: new Date8Calculator().addMonth(-1).toString(),
      enddate: `${year}${month}${date}`,
      partList: [],
      contentlist: [],
      factorslist: [],
      causelist: [],
      processlist: [],
      typelist: [],
      floorlist: [],
      arealist: [],
      searchQuery: '', // 검색어
      holderName: '고장부위를 선택하세요',
      perid: bosuPerid || '%', // 담당자
      pernm: bosuPernm || '', // 담당자명
      divicd: bosuDivicd || '%',
      divinm: bosuDivinm || '',
      gubun: '%',

      tot_tot: '',
      qty_tot: '',
      banqty_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onTabChange(this.tabs[BreakAnalysisOneTabId.Part]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
    await this.onHolderNameMake();
  }

  @action
  async onHolderNameMake() {
    let text = '';
    switch ((this.tabIndex)) {
      case 0:
        text = '고장부위를 선택하세요';
        break;

      case 1:
        text = '고장내용을 선택하세요';
        break;

      case 2:
        text = '고장요인을 선택하세요';
        break;

      case 3:
        text = '고장원인을 선택하세요';
        break;

      case 4:
        text = '처리내용을 선택하세요';
        break;

      default:
        text = '';
    }
    this.setState({ holderName: text });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakAnalysisOneTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${BreakAnalysisOneTabFunctionNames[i]}_print`,
      {
        actcd: this.state.focuseddata.actcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[BreakAnalysisOneTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${BreakAnalysisOneTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
    );
  }

  @computed
  get tabIndex(): BreakAnalysisOneTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onRetrieveEvent());
    // eslint-disable-next-line radix
    this.tapChk = parseInt(focusedTab.id) + 1;
  }

  @action
  async doRetrieve(i: BreakAnalysisOneTabId, type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        divicd: this.state.divicd,
        gubun: this.state.gubun,
      },
      (params) => api.fxExec(
        `${BreakAnalysisOneTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [BreakAnalysisOneTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[BreakAnalysisOneTabDataStateNames[i]],
              ...items.map((x: any) => new BreakAnalysisOneTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [BreakAnalysisOneTabDataStateNames[i]]: [],
          [BreakAnalysisOneTabUpdatesStateNames[i]]: [],
          [BreakAnalysisOneTabFocusedStateNames[i]]: undefined,
        });
        await this.infinity?.retrieveAll();
        await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [BreakAnalysisOneTabDataStateNames[i]]: [],
      [BreakAnalysisOneTabUpdatesStateNames[i]]: [],
      [BreakAnalysisOneTabFocusedStateNames[i]]: undefined,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['actnm', 'tot'],
        [this.state.focuseddata?.actnm, this.state.focuseddata?.tot], type, true) || 0;
      // @ts-ignore
      this.state[BreakAnalysisAllTabDataStateNames[i]]
      // @ts-ignore
      && this.state[BreakAnalysisAllTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);
      const data = this.infinity?.box;
      await this.grid.current?.setFocus(index);
      this.setState({
        tot_tot: data?.tot_tot || '0',
        qty_tot: data?.qty_tot || '0',
        banqty_tot: data?.banqty_tot || '0',
      });
    });
  }

  @action
  async onRowFocusEvent(item: any) {
    if (item) {
      this.setState({
        focuseddata: item,
        [BreakAnalysisOneTabFocusedStateNames[this.tabIndex]]: item,
      }, async () => {
        await this.urlRetrieve();
      });
    }
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_05&type=0&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_actcd=${this.state.focuseddata.actcd}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}`,
      url2: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_05&type=1&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_actcd=${this.state.focuseddata.actcd}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}`,
      url3: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_05&type=2&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_actcd=${this.state.focuseddata.actcd}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}`,
      url4: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_05&type=3&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_actcd=${this.state.focuseddata.actcd}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}`,
      url5: `https://api.elmansoft.com/chart/pie.php?window=w_tb_e411w_05&type=4&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_stdate=${this.state.stdate}&as_enddate=${this.state.enddate}&as_actcd=${this.state.focuseddata.actcd}&as_divicd=${this.state.divicd}&as_perid=${this.state.perid}`,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [BreakAnalysisOneTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [BreakAnalysisOneTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <BreakAnalysisOneTemplate
        scope={this}
      />
    );
  }
}
