/**
 * 모델
 * @window w_tb_e601w_sulchi_05
 * @category 설치발주현황
 */
export default class OrderModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 발주상태
   *
   * **data type** varchar(8)
   */
  readonly balflag: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly cdflag: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 삭제구분
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   * 수주일자
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 프로젝트 코드
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly re_ibgdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly re_ibgflag: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly tot_re_ibgflag: string;


  constructor(data: any = {}) {
    this.cltcd = data?.cltcd || '';
    this.cltnm = data?.cltnm || '';
    this.actcd = data?.actcd || '';
    this.actnm = data?.actnm || '';
    this.baldate = data?.baldate || '';
    this.balflag = data?.balflag || '';
    this.bigo = data?.bigo || '';
    this.cdflag = data?.cdflag || '';
    this.custcd = data?.custcd || '';
    this.deldate = data?.deldate || '';
    this.orddate = data?.orddate || '';
    this.projno = data?.projno || '';
    this.re_ibgdate = data?.re_ibgdate || '';
    this.re_ibgflag = data?.re_ibgflag || '';
    this.spjangcd = data?.spjangcd || '';
    this.state = data?.state || '';
    this.tot_re_ibgflag = data?.tot_re_ibgflag || '';
  }
}
