import { Global, TLHF } from '../../../../../constants';
import { FlexLayout, TextBox } from '../../../../../components';
import { Schedule } from '../Schedule';
import ScheduleModel from '../models/ScheduleModel';
import { SearchBinding } from '../../../../../models';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e045
 * @category 사전점검스케쥴
 */
export const ScheduleTableHeader: TLHF<ScheduleModel, Schedule> = (scope) => ([
  {
    id: 'state',
    text: '결과',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.state === '1' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
      >
        {x.state === '1' ? '계획' : '처리'}
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '계획일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.plandate)}
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    color: 'var(--color-red)',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ actcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });

            scope.modalTableItemChanged(rowUpdate, 'actcd', item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '호기코드',
    color: 'var(--color-red)',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.equpcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ equpcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: x.actcd },
          true,
          (item) => {
            rowUpdate({
              equpcd: item.cd,
              equpnm: item.cdnm,
            });

            scope.modalTableItemChanged(rowUpdate, 'equpcd', item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'scpernm',
    text: '설치자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.scpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'perid',
    text: '담당자',
    color: 'var(--color-red)',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.perid}
        textAlign="center"
        onChange={(value) => rowUpdate({ perid: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_JA001',
          {},
          true,
          (item) => {
            rowUpdate({
              perid: item.cd,
              pernm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'kcperid',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout style={Global.STYLE_COLOR_RED}>처리자</FlexLayout>
        <FlexLayout style={Global.STYLE_COLOR_RED}>보조자</FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FlexLayout isVertical={true}>
        <TextBox
          ref={ref}
          value={x.kcperid}
          textAlign="center"
          onChange={(value) => rowUpdate({ kcperid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              rowUpdate({
                kcperid: item.cd,
                kcpernm: item.cdnm,
              });
            },
          )}
        />

        <TextBox
          ref={ref}
          value={x.kcperid2}
          textAlign="center"
          onChange={(value) => rowUpdate({ kcperid2: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              rowUpdate({
                kcperid2: item.cd,
                kcpernm2: item.cdnm,
              });
            },
          )}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>처리자명</FlexLayout>
        <FlexLayout>보조자명</FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {x.kcpernm}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          {x.kcpernm2}
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
