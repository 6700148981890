import { computed } from 'mobx';

/**
 * 모델
 * @window wb_education
 * @category 교육팝업
 */

export class EducationPopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly edunum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly title: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly page: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rptdate = data.rptdate || '';
    this.perid = data.perid || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.title = data.title || '';
    this.page = data.page || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
