import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { SalesState } from './SalesState';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

const recivstatuss = [
  { value: 'A', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'R', remark: '열람' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
];

const taxclss = [
  { value: '1', remark: '포함' },
  { value: '0', remark: '별도' },
];

const billkinds = [
  { value: '1', remark: '발행' },
  { value: '0', remark: '미발행' },
];

const taxgubuns = [
  { value: '01', remark: '과세' },
  { value: '02', remark: '영세' },
  { value: '03', remark: '면세' },
];

/**
 * 화면
 * @window w_tb_e050w
 * @category 매출현황
 */
export const SalesStateTemplate: React.FC<TemplateProps<SalesState>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={232}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span style={{ marginLeft: 15 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={493}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />

          <FlexLayout
            size={100}
            style={{
              backgroundColor: '#FFFFFF',
              height: 30,
              marginTop: 8,
            }}
          >
            <CheckBox
              value={scope.state.resuck === '1'}
              onChange={(v) => setData({ resuck: v ? '1' : '0' },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />

            <FlexLayout
              weight={2.5}
              style={{ marginLeft: 3 }}
            >
              매출만
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout
          size={250}
          style={{ marginLeft: 53 }}
        >
          <span>매출분류</span>
            <ComboBox
              value={scope.state.billgubun}
              textAlign="center"
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '매출' },
                { value: '0', remark: '비매출' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ billgubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
        </FlexLayout>

        <FlexLayout
          size={350}
          style={{ marginRight: 293 }}
        >
          <span>수정건수(전자세금계산서)</span>
          <ComboBox
            value={scope.state.cancleflag}
            textAlign="center"
            data={[
              { value: '0', remark: '제외' },
              { value: '%', remark: '포함' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ cancleflag: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
    </FlexLayout>

    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={209}>
          <span>입금기준</span>
          <ComboBox
            value={scope.state.rcvflag}
            textAlign="center"
            data={[
              { value: '%', remark: '매출일자기준' },
              { value: '1', remark: '기간내기준' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ rcvflag: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value })}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.wkactnm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout
          style={{ marginLeft: 37 }}
          size={240}
        >
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => setData({ divicd: value })}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.divinm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={240}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => setData({ perid: value })}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout
          size={263}
          style={{ marginLeft: 39 }}
        >
          <span>계산서발행</span>
          <ComboBox
            value={scope.state.billkind}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '발행' },
              { value: '0', remark: '미발행' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ billkind: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout
          size={280}
          style={{ marginLeft: 69 }}
        >
          <span>전자세금계산서</span>
          <ComboBox
            value={scope.state.recivstatus}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: 'F', remark: '국세청최종접수' },
              { value: 'B', remark: '발송전' },
              { value: 'A', remark: 'KTNET접수' },
              { value: 'E', remark: 'KTNET오류' },
              { value: 'L', remark: '승인(화주)' },
              { value: 'D', remark: '거부(화주)' },
              { value: 'N', remark: '이메일전송실패' },
              { value: 'T', remark: '국세청1차접수' },
              { value: 'X', remark: '국세청오류' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ recivstatus: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
    </FlexLayout>

    <FlexLayout>
      <FlexLayout size={200}>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'cnam',
              text: '매출구분',
              width: 80,

            },
            {
              id: 'chk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={scope.state.pushTotalCheck}
                    onChange={async (value) => scope
                      .updateCheckAllToggle(value)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
              width: 20,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.chk === '1'}
                    onChange={(value) => {
                      rowUpdate({ chk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          data={scope.state.openChk}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
        />
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'misdate',
              text: '매출일자',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.misdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'misnum',
              text: '번호',
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  건 수
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '매출구분',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.total}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'billgubun2',
              text: '매출분류',
              width: 70,
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '담당자',
              width: 70,
            },
            {
              id: 'qty',
              text: '관리대수',
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.qty_tot}
                </FlexLayout>
              ),
            },
            {
              id: 'taxcls',
              text: '부가세여부',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {taxclss.filter((y) => y.value === x.taxcls)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'amt',
              text: '공급가액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.amt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.amt)}
                </FlexLayout>
              ),
            },
            {
              id: 'addamt',
              text: '부가세',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.addamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.addamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '매출액(합계)',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.misamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt',
              text: '입금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.rcvamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.rcvamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt',
              text: '미수금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.iamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.iamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'yyyymm',
              text: '귀속년월',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  format="####-##"
                  textAlign="center"
                  value={x.yyyymm}
                />
              ),
            },
            {
              id: 'billkind',
              text: '계산서발행',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {billkinds.filter((y) => y.value === x.billkind)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'recivstatus',
              text: '전자세금계산서상태',
              width: 120,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    color: x.recivstatus === 'F'
                      ? 'var(--color-blue)'
                      // eslint-disable-next-line no-nested-ternary
                      : x.recivstatus === 'FF'
                        ? '#7A297B'
                        : x.recivstatus === 'B'
                          ? 'var(--color-red)' : '',
                  }}>
                  {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'taxgubun',
              text: '과세구분',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {taxgubuns.filter((y) => y.value === x.taxgubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'jirogubun',
              text: '지로구분',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {billkinds.filter((y) => y.value === x.jirogubun)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'pubnum',
              text: '지로번호',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="######-####"
                  value={x.pubnum}
                />
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'projnm',
              text: '프로젝트명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.projnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onRowDoubleClick={() => scope.onClickOpenPage()}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
