import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Drawing } from '../Drawing';
import DrawingModel from '../models/DrawingModel';

/**
 * 화면 > 그리드 레이아웃
 * @window w_tb_e601_dp_01
 * @category 설치현장도면
 */
export const DrawingGridHeader: GLHF<DrawingModel, Drawing> = () => ([
  {
    id: 'seq',
    text: '구분',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.state}
      </FlexLayout>
    ),
  },
  {
    id: 'scactnm',
    text: '설치현장명',
    width: 55,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scactnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cnt',
    text: '파일수',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.cnt}
      </FlexLayout>
    ),
  },
]);
