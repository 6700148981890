import { computed } from 'mobx';

/**
 * FM계획예정모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class FmModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 결과
   *
   * **data type** char(1)
   */
  planflag: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  gubun: string;

  /**
   * 계약
   *
   * **data type** char(10)
   */
  becontgubun: string;

  /**
   * 만료일
   *
   * **data type** char(8)
   */
  endmon: string;

  /**
   * 보수료
   *
   * **data type** number
   */
  qtyamt: string;

  /**
   * 계획
   *
   * **data type** number
   */
  b_plan: string;

  /**
   * 계획계약
   *
   * **data type** char(5)
   */
  contgubun: string;

  /**
   * 예상보수료
   *
   * **data type** number
   */
  qtysamt: string;

  /**
   * 증가액
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 목표
   *
   * **data type** number
   */
  totplansamt: string;

  /**
   * 계획금액
   *
   * **data type** number
   */
  planamt: string;

  /**
   * 금액
   *
   * **data type** number
   */
  costamt: string;

  /**
   * 계획내용
   *
   * **data type** char(8000)
   */
  remark: string | undefined;

  /**
   * 계획내용/견적서명
   *
   * **data type** char(8000)
   */
  constnm: string;

  /**
   * 담당자
   *
   * **data type** char(50)
   */
  pernm: string;

  /**
   * 금액
   *
   * **data type** char(50)
   */
  besamt: string;

  /**
   * 조건
   *
   * **data type** char(5)
   */
  compflag: string;

  /**
   * -
   *
   * **data type** char(5)
   */
  title: string;

  /**
   * -
   *
   * **data type** char(5)
   */
  mon: string;

  /**
   * -
   *
   * **data type** char(5)
   */
  perid: string;

  /**
   * -
   *
   * **data type** char(5)
   */
  seq: string;

  /**
   * -
   *
   * **data type** char(5)
   */
  actcd: string;

  /**
   * 합계
   *
   * **data type** number
   */
  total: string;

  /**
   * 이전보수료
   *
   * **data type** number
   */
  besamt_tot: string;

  /**
   * 이전보수료(대당가)
   *
   * **data type** number
   */
  qtyamt_tot: string;

  /**
   * 인상보수료
   *
   * **data type** number
   */
  samt_tot: string;

  /**
   * 증가액
   *
   * **data type** number
   */
  chasamt_tot: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  hdate: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  wakedate: string;

  /**
   * -
   *
   * **data type** number
   */
  okflag: string;

  /**
   * -
   *
   * **data type** varchar(8)
   */
  wakeflag: string;

  /**
   * -
   *
   * **data type** number
   */
  wakedat: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.wakeflag = data.wakeflag || '';
    this.wakedat = data.wakedat || '';
    this.hdate = data.hdate || '';
    this.wakedate = data.wakedate || '';
    this.okflag = data.okflag || '';
    this.custcd = data.custcd || '';
    this.samt_tot = data.samt_tot || '';
    this.chasamt_tot = data.chasamt_tot || '';
    this.divinm = data.divinm || '';
    this.total = data.total || '';
    this.qtyamt_tot = data.qtyamt_tot || '';
    this.besamt_tot = data.besamt_tot || '';
    this.title = data.title || '';
    this.perid = data.perid || '';
    this.seq = data.seq || '';
    this.actcd = data.actcd || '';
    this.mon = data.mon || '';
    this.actnm = data.actnm || '';
    this.compflag = data.compflag || '';
    this.besamt = data.besamt || '';
    this.pernm = data.pernm || '';
    this.endmon = data.endmon || '';
    this.constnm = data.constnm || '';
    this.costamt = data.costamt || '';
    this.planamt = data.planamt || '';
    this.divinm = data.divinm || '';
    this.gubun = data.gubun || '';
    this.qty = data.qty || '';
    this.planflag = data.planflag || '';
    this.becontgubun = data.becontgubun || '';
    this.endmon = data.endmon || '';
    this.qtyamt = data.qtyamt || '';
    this.b_plan = data.b_plan || '';
    this.contgubun = data.contgubun || '';
    this.qtysamt = data.qtysamt || '';
    this.addamt = data.addamt || '';
    this.totplansamt = data.totplansamt || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
