import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  IFrame,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { YearPay } from './YearPay';
import { Format } from '../../../../utils/string';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_pb300_03
 * @category 년급여현황
 */
export const YearPayTemplate: React.FC<TemplateProps<YearPay>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>월</span>
        <DateTextBox
          value={scope.state.yearmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => {
            setData({ yearmon: value },
              () => scope.onRetrieveEvent());
          }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={220}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => setData({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={220}>
        <span>임원구분</span>
        <ComboBox
          value={scope.state.offiyn}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '임원' },
            { value: '0', remark: '직원' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ offiyn: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span>재직구분</span>
        <ComboBox
          value={scope.state.rtclafi}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '001', remark: '재직자' },
            { value: '002', remark: '퇴직자' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ rtclafi: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span>사원명</span>
        <TextBox
          value={scope.state.name}
          onChange={(value) => setData({ name: value })}
          onEnter={() => scope.onFocusEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          headerHeight={70}
          header={[
            {
              id: 'no',
              text: '순번',
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
            },
            {
              id: 'divinm',
              text: '부서명',
              width: 70,
            },
            {
              id: 'entdate',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    입사일
                  </FlexLayout>
                  <FlexLayout
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    퇴사일
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.entdate)}
                  </FlexLayout>
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Date8.withDash(x.rtdate)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'sex',
              text: '성별',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.sex === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                >
                  {x.sex === '1' ? '남' : '여'}
                </FlexLayout>
              ),
            },
            {
              id: 'rspnm',
              text: '직책',
              width: 50,
            },
            {
              id: 'pernm',
              text: '성명',
              width: 70,
            },
            {
              id: 'tot_samt01',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    01월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt01_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt01_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt01_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  style={scope.getColotEvent(x.rtdate, x.year, '01')}
                  onDoubleClick={() => scope.imageRequest('01')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt01)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt01)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt02',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    02월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt02_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt02_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt02_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('02')}
                  style={scope.getColotEvent(x.rtdate, x.year, '00')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt02)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt02)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt03',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    03월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt03_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt03_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt03_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('03')}
                  style={scope.getColotEvent(x.rtdate, x.year, '03')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt03)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt03)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt04',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    04월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt04_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt04_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt04_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('04')}
                  style={scope.getColotEvent(x.rtdate, x.year, '04')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt04)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt04)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt05',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    05월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt05_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt05_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt05_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('05')}
                  style={scope.getColotEvent(x.rtdate, x.year, '05')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt05)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt05)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt06',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    06월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt06_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt06_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt06_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('06')}
                  style={scope.getColotEvent(x.rtdate, x.year, '06')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt06)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt06)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt07',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    07월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt07_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt07_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt07_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('07')}
                  style={scope.getColotEvent(x.rtdate, x.year, '07')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt07)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt07)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt08',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    08월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt08_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt08_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt08_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('08')}
                  style={scope.getColotEvent(x.rtdate, x.year, '08')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt08)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt08)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt09',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    09월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt09_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt09_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt09_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('09')}
                  style={scope.getColotEvent(x.rtdate, x.year, '09')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt09)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt09)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt10',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    10월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt10_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt10_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt10_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('10')}
                  style={scope.getColotEvent(x.rtdate, x.year, '10')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt10)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt10)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt11',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    11월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt11_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt11_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt11_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('11')}
                  style={scope.getColotEvent(x.rtdate, x.year, '11')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt11)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt11)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_samt12',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: '#7F7A2C' }}
                  >
                    12월
                  </FlexLayout>
                  <FlexLayout align="center">
                    월간연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt12_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt12_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt12_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.imageRequest('12')}
                  style={scope.getColotEvent(x.rtdate, x.year, '12')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.tot_tamt12)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_mamt12)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'ttot_samt',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    합계
                  </FlexLayout>
                  <FlexLayout align="center">
                    년연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  isVertical={true}
                  justify="right"
                  align="center"
                >
                  <FlexLayout>
                    {scope.state.tot_samt_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-red)' }}>
                    {scope.state.tot_tamt_tot}
                  </FlexLayout>

                  <FlexLayout style={{ color: 'var(--color-blue)' }}>
                    {scope.state.tot_mamt_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  style={{ backgroundColor: '#B2EBF4' }}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.ttot_samt)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.ttot_tamt)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.ttot_mamt)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'atot_samt',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="start"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    평균
                  </FlexLayout>
                  <FlexLayout align="center">
                    년연봉급여액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-red)' }}
                  >
                    공제금액
                  </FlexLayout>
                  <FlexLayout
                    align="end"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실수령액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  style={{ backgroundColor: '#B2EBF4' }}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.atot_samt)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.atot_tamt)}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.atot_mamt)}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={50}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <FlexLayout weight={0.5}>
        <IFrame src={scope.graph} />
      </FlexLayout>
    </FlexLayout>

    <ModalView
      width={900}
      height={1400}
      isVisible={scope.state.textDetailModal}
      onClose={() => scope.textModal(false)}
    >
      <IFrame src={scope.state.img} />
    </ModalView>
  </FlexLayout>
  );
};
