import { action } from 'mobx';
import { ModalStore } from '../../stores';
import { CommonSelectorItem } from '../../stores/ModalStore';
import { ComboBoxModel } from '../../components';

export interface ISearchBinding {
  modalStore: ModalStore;
  functionName: string;
  params: any;
  immediatlySearch: boolean;
  onSearch: (item: any) => void;
}

export default class SearchBinding implements ISearchBinding {
  readonly modalStore: ModalStore;

  readonly functionName: string;

  readonly params: any;

  readonly immediatlySearch: boolean;

  readonly onSearch: (item: CommonSelectorItem) => void;

  constructor(
    modalStore: ModalStore,
    functionName: string,
    params: any,
    immediatlySearch: boolean,
    onSearch: (item: CommonSelectorItem) => void,
  ) {
    this.modalStore = modalStore;
    this.functionName = functionName;
    this.params = params;
    this.immediatlySearch = immediatlySearch;
    this.onSearch = onSearch;
  }

  @action
  open(params?: any) {
    const args = {
      ...this.params,
      ...params,
    };

    switch (this.functionName) {
      case 'TB_JA001':
      case 'TB_JA001_1':
      case 'TB_JA001_3':
        this.modalStore.openCommonSelector(
          this.functionName,
          args,
          this.immediatlySearch,
          [
            new ComboBoxModel('%', '전체'),
            new ComboBoxModel('001', '재직자'),
            new ComboBoxModel('002', '퇴직자'),
          ],
          'rtclafi',
          '재직구분',
          '001',
        ).then((item) => {
          this.onSearch(item);
        });
        break;

      case 'TB_E611':
        this.modalStore.openCommonSelector(
          this.functionName,
          args,
          this.immediatlySearch,
          [
            new ComboBoxModel('0', '계약/입사중'),
            new ComboBoxModel('1', '해제/퇴사포함'),
          ],
          'gubun',
          '',
          '0',
        ).then((item) => {
          this.onSearch(item);
        });
        break;
      case 'TB_E601_1':
      case 'TB_E601_2':
      case 'TB_E601_3':
        this.modalStore.openCommonSelector(
          this.functionName,
          args,
          this.immediatlySearch,
          [
            new ComboBoxModel('%', '전체'),
            new ComboBoxModel('1', '계약중'),
            new ComboBoxModel('2', '해지'),
            new ComboBoxModel('3', '미계약'),
          ],
          'gubun',
          '계약구분',
          '1',
        ).then((item) => {
          this.onSearch(item);
        });
        break;

      default:
        this.modalStore.openCommonSelector(this.functionName, args, this.immediatlySearch)
          .then((item) => {
            this.onSearch(item);
          });
    }
  }
}
