
/**
 * 모델
 * @window w_tb_ca602w_02
 * @category 재고이동내역
 */
export class BarcodeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 제품코드
   *
   * **data type** varchar(8)
   */
  pcode: string;

  /**
   * 현재고
   *
   * **data type** varchar(8)
   */
  qty: string;

  /**
   * 년도
   *
   * **data type** varchar(8)
   */
  year: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  num: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.pcode = data.pcode || '';
    this.qty = data.qty || '';
    this.spjangcd = data.spjangcd || '';
    this.year = data.year || '';
    this.num = data.num || '';
  }
}
