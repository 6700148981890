import * as React from 'react';
import { action } from 'mobx';
import Swal from 'sweetalert2';
import { Today } from '../../../../utils/time';
import { CalendarLayoutDataModel } from '../../../../models/component';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { PlanEnrollModel } from './PlanEnroll.model';
import { PlanEnrollTemplate } from './PlanEnroll.template';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  ConfirmType,
  ConfirmTypeName,
  PageProps,
  PageToolEvents,
} from '../../../../constants';

export enum PlanEnrollChangeTypes {
  PERID,
  PERID_OK,
}

export enum PlanEnrollChangeTypeNames {
  perid,
  perid_ok,
}

export interface PlanEnrollState {
  // 검색 조건
  stmon: string;

  // 당직 구분
  gubuns: Array<any>;

  // 당직 리스트
  data: Array<CalendarLayoutDataModel<PlanEnrollModel>>;

  // 당직 모달
  isWorkerModalVisible?: boolean;
  workerPopupList?: Array<PlanEnrollModel>;
  workerPopupDate: string;
  focusedWorkPopup?: PlanEnrollModel,
}

/**
 * 컨트롤러
 * @window w_tb_e511
 * @category 당직계획등록
 */
// eslint-disable-next-line max-len
export class PlanEnroll extends React.Component<PageProps, PlanEnrollState> implements PageToolEvents {
  updatedRows?: Array<PlanEnrollModel>;

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  dayRemarks: Record<string, { done: number, plan: number, yet: number }>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.dayRemarks = {};

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      focusedWorkPopup: '',
      workerPopupDate: '',
      workerPopupList: [],
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }
      this.itemChanged(PlanEnrollChangeTypes.PERID_OK);
    }
  }

  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 당직구분
    const data = await api.dropdown('wf_dd_ca510_025_01');
    if (!data) return;
    this.setState({ gubuns: data.items.filter((x: any) => x.com_code) });

    await this.onRetrieveEvent();
  }

  getItemColor(item: PlanEnrollModel): string {
    if (item.gubun === '1') {
      return 'var(--color-black)';
    }

    return 'var(--color-blue)';
  }

  getItemPrefix(item: PlanEnrollModel): string {
    if (item.gubun === '1') {
      return '계획';
    }

    return '완료';
  }

  @action
  async onRetrieveEvent() {
    this.setState({
      data: [],
    }, async () => {
      // 달력
      const { actionStore: api } = this.props;
      const data = await api.retrieve({
        stmon: this.state.stmon,
      });

      this.dayRemarks = {};
      data?.items?.forEach((x: any) => {
        if (!this.dayRemarks[x.rptdate]) {
          this.dayRemarks[x.rptdate] = { done: 0, plan: 0, yet: 0 };
        }

        if (x.gubun === '1') {
          this.dayRemarks[x.rptdate].plan += 1;
        } else {
          this.dayRemarks[x.rptdate].done += 1;
        }
      });

      data?.items && this.setState({
        data: data.items.map((x: any) => (
          new CalendarLayoutDataModel(x.rptdate, new PlanEnrollModel(x)))),
      });
    });
  }

  @action
  onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: false,
    });

    // @ts-ignore
    swalWithBootstrapButtons.fire({
      title: '선택',
      html: '인쇄할 사이즈를 선택해주세요',
      icon: ConfirmTypeName[ConfirmType.QUESTION],
      showCancelButton: true,
      confirmButtonText: 'A4',
      cancelButtonText: 'A3',
      reverseButtons: true,
    // @ts-ignore
    }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
      if (result.value) {
        this.doPrint('a4');
      } else if (
        result.dismiss === Swal.DismissReason.backdrop
      ) {
        ConfirmWarning.show('취소', '취소하였습니다.');
      } else {
        this.doPrint('a3');
      }
    });
  }

  doPrint(gubun: string) {
    const { actionStore: api } = this.props;
    api.printWithElmanManager({
      stmon: this.state.stmon,
      perid: '',
      actcd: '',
      divicd: '',
      state: '',
      gubun,
      contgubun: '%',
    });
  }

  @action
  onRowFocusEvent(item:PlanEnrollModel) {
    this.setState({ focusedWorkPopup: item });
  }

  @action
  async closeWorkerModal() {
    this.setState({
      workerPopupDate: '',
      workerPopupList: [],
      isWorkerModalVisible: false,
    });
    this.updatedRows = [];
    this.onRetrieveEvent();
  }

  @action
  async openWorkerModal(date: string) {
    // eslint-disable-next-line max-len
    const gubun: string|undefined = this.state.data?.filter((item) => item.date === date)[0]?.data.gubun;
    if (!gubun) {
      // 미정
      this.setState({
        isWorkerModalVisible: true,
        workerPopupDate: date,
      }, () => this.modalRetrieve());
    } else if (gubun === '1') {
      // 계획
      this.setState({
        isWorkerModalVisible: true,
        workerPopupDate: date,
      }, () => this.modalRetrieve());
    } else if (gubun === '2') {
      // 완료
      ConfirmWarning.show('확인', '이미 당직근무 확정(완료)된 내용입니다.');
    }
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const day:number = parseInt(this.state.workerPopupDate.substring(6, 8));
    const data = await api.retrieve(
      {
        sub: 'w_popup_e511',
        planmon: this.state.stmon,
        date: day.toString(),
      },
    );

    if (data.items) {
      this.setState({
        workerPopupList: data.items?.map((x: any) => new PlanEnrollModel(x)),
      }, async () => {
        await this.popupTable.current?.update();
        this.popupTable.current?.setFocus(0, 1);
      });
    } else {
      this.setState({
        workerPopupList: [],
      }, () => this.popupTable.current?.update());
    }
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const day:number = parseInt(this.state.workerPopupDate.substring(6, 8));
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_e511',
      stmon: this.state.stmon,
      date: day.toString(),
      perid: this.state.focusedWorkPopup?.perid,
    }) && await this.modalRetrieve();

    this.updatedRows = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const day:number = parseInt(this.state.workerPopupDate.substring(6, 8));

    if (this.state.workerPopupList?.length! < 1) {
      await this.setState({ isWorkerModalVisible: false });
      return;
    }

    if (this.state.workerPopupList?.filter((x) => !x.gubun).length) {
      ConfirmWarning.show('경고', '당직구분을 선택하지 않은 인원이 있습니다');
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_e511',
        stmon: this.state.stmon,
        date: day.toString(),
        pernm: '',
        items: this.state.workerPopupList,
      });

    if (data) {
      await this.setState({ isWorkerModalVisible: false });
      ConfirmSuccess.showPbMessage(data);
      this.updatedRows = [];
      this.onRetrieveEvent();
    }
  }

  @action
  async modalNew() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const day:number = parseInt(this.state.workerPopupDate.substring(6, 8));
    const seq:number | undefined = this.state.workerPopupList?.length;

    const data = await api.fxExec('new',
      {
        sub: 'w_popup_e511',
        stmon: this.state.stmon,
        date: day.toString(),
        perid: this.state.focusedWorkPopup?.perid,
      });

    if (data) {
      // eslint-disable-next-line max-len
      const newModel = new PlanEnrollModel({
        ...data,

        // eslint-disable-next-line no-nested-ternary
        seq: seq! < 10 ? `00${seq! + 1}` : seq! < 100 ? `0${seq! + 1}` : (seq! + 1).toString(),
      }, true);

      this.setState({
        workerPopupList: [
          newModel,
          ...this.state.workerPopupList!,
        ],
        focusedWorkPopup: newModel,
      }, async () => {
        await this.popupTable.current?.update(false);
        this.popupTable.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;
    if (item) this.setState({ focusedWorkPopup: item });
    // eslint-disable-next-line radix
    const day:number = parseInt(this.state.workerPopupDate.substring(6, 8));
    let data = { items: [] };
    const params = {
      itemname: PlanEnrollChangeTypeNames[type],
      new: this.state.focusedWorkPopup?.new,
    };

    if (type === PlanEnrollChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          sub: 'w_popup_e511',
          ...params,
          data: item.perid,
        },
      );
    }

    if (type === PlanEnrollChangeTypeNames.perid_ok) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          sub: 'w_popup_e511',
          ...params,
          perid: this.state.focusedWorkPopup?.perid,
          stmon: this.state.stmon,
          date: day.toString(),
          data: '',
        },
      );

      // 팀원 가져오기
      data?.items && this.setState({
        workerPopupList: data?.items,
      }, () => this.popupTable.current?.update(false));
    }
  }

  @action
  onUpdatedRows(rows: Array<PlanEnrollModel>, updatedRows: Array<PlanEnrollModel>) {
    this.updatedRows = updatedRows;
    this.setState({ workerPopupList: rows });
  }

  render() {
    return (
      <PlanEnrollTemplate
        scope={this}
      />
    );
  }
}
