import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  GridLayoutAdditionalHeader,
  SearchLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { LastMonthPay } from './LastMonthPay';
import { Format } from '../../../../utils/string';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';

const mijflags = [
  { value: '0', remark: '미지급' },
  { value: '1', remark: '매입' },
  { value: '2', remark: '지급' },
];

/**
 * 화면
 * @window w_tb_pb300_06
 * @category 전월대비급여현황
 */
export const LastMonthPayTemplate: React.FC<TemplateProps<LastMonthPay>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>월</span>
        <DateTextBox
          value={scope.state.yearmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => {
            setData({ yearmon: value },
              () => scope.onRetrieveEvent());
          }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={240}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => setData({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={176}>
        <span>임원구분</span>
        <ComboBox
          value={scope.state.offiyn}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '임원' },
            { value: '0', remark: '직원' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ offiyn: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <GridLayoutAdditionalHeader
        ref={scope.additionalTitle}
        height={Global.LAYOUT_TITLE_HEIGHT_1}
      >
        <FlexLayout
          size={317}
          justify="center"
          align="center"
        >
          항목
        </FlexLayout>

        <FlexLayout
          size={504}
          justify="center"
          align="center"
        >
          {new Date6Calculator(scope.state.yearmon).add(-3).toString().slice(0, 4)}년
          {new Date6Calculator(scope.state.yearmon).add(-1).toString().slice(4, 6)}월 급여
        </FlexLayout>

        <FlexLayout
          size={436}
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {new Date6Calculator(scope.state.yearmon).add(-3).toString().slice(0, 4)}년
          {new Date6Calculator(scope.state.yearmon).toString().slice(4, 6)}월 급여
        </FlexLayout>

        <FlexLayout
          size={420}
          justify="center"
          align="center"
        >
          차이금액
        </FlexLayout>
      </GridLayoutAdditionalHeader>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'no',
            text: '순번',
            width: 3,
          },
          {
            id: 'entdate',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="start"
                  style={{ color: 'var(--color-blue)' }}
                >
                  입사일
                </FlexLayout>

                <FlexLayout
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  퇴사일
                </FlexLayout>
              </FlexLayout>
            ),
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                월간 연봉총액
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.entdate)}
                </FlexLayout>

                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.rtdate ? 'var(--color-red)' : '' }}
                >
                  {!x.dayterm2 ? Date8.withDash(x.rtdate) : Date8.withDash(x.dayterm2)}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'rspnm',
            text: '직책',
            width: 4,
            trail: () => (
              <FlexLayout
                justify="left"
                align="center"
              >
                정산내역
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '성명',
            width: 4,
          },
          {
            id: 'betot_samt',
            text: '월간급여총액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.betot_samt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.betot_samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'betot_tamt',
            text: '공제내역총액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.betot_tamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {Format.number(x.betot_tamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'betot_mamt',
            text: '실수령액',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.betot_mamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {Format.number(x.betot_mamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'bemijflag',
            text: '지급구분',
            width: 6,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  color: x.bemijflag === '0'
                    ? 'var(--color-red)'
                    : x.bemijflag === '2'
                      ? 'var(--color-blue)'
                      : '#7F7A2C',
                }}
              >
                {mijflags.filter((y) => y.value === x.bemijflag)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'tot_samt',
            text: '월간급여총액',
            width: 7,
            color: 'var(--color-blue)',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.tot_samt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.tot_samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'tot_tamt',
            text: '공제내역총액',
            color: 'var(--color-blue)',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state.tot_tamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {Format.number(x.tot_tamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'tot_mamt',
            text: '실수령액',
            color: 'var(--color-blue)',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state.tot_mamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {Format.number(x.tot_mamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'mijflag',
            text: '지급구분',
            color: 'var(--color-blue)',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  color: x.mijflag === '0'
                    ? 'var(--color-red)'
                    : x.mijflag === '2'
                      ? 'var(--color-blue)'
                      : '#7F7A2C',
                }}
              >
                {mijflags.filter((y) => y.value === x.mijflag)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'ttot_samt',
            text: '월간급여총액',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: 'var(--color-blue)' }}
              >
                {scope.state.ttot_samt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: 'var(--color-blue)' }}
              >
                {Format.number(x.ttot_samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'ttot_tamt',
            text: '공제내역총액',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: 'var(--color-red)' }}
              >
                {scope.state.ttot_tamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: 'var(--color-red)' }}
              >
                {Format.number(x.ttot_tamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'ttot_mamt',
            text: '실수령액',
            width: 11,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: 'var(--color-blue)' }}
              >
                {scope.state.ttot_mamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ backgroundColor: '#B2EBF4', color: 'var(--color-blue)' }}
              >
                {Format.number(x.ttot_mamt)}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  </FlexLayout>
  );
};
