import * as React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { MonthResult } from '../MonthResult';
import { GridLayout } from '../../../../../components/layout/GridLayout';
import { IndividualGrid } from '../headers/IndividualGrid';
import { GridLayoutAdditionalHeader } from '../../../../../components/layout/GridLayoutAdditionalHeader';

/**
 * 화면 > 탭 개인별
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export const IndividualTemplate: React.FC<TemplateProps<MonthResult>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      {scope.tabIndex === 0
        ? <FlexLayout
          isVertical={true}
          weight={0.01}
        >
          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle2}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
          >
            <FlexLayout
              size={360}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={3018}
              justify="center"
              align="center"
            >
              점검부문
            </FlexLayout>

            <FlexLayout
              size={628}
              justify="center"
              align="center"
            >
              고장부문
            </FlexLayout>

            <FlexLayout
              size={278}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              긴급출동(사람갇힘)
            </FlexLayout>

            <FlexLayout
              size={278}
              justify="center"
              align="center"
            >
              민원부문
            </FlexLayout>

            <FlexLayout
              size={488}
              justify="center"
              align="center"
            >
              검사부문
            </FlexLayout>

            <FlexLayout
              size={838}
              justify="center"
              align="center"
            >
              계약부문(신규)
            </FlexLayout>

            <FlexLayout
              size={868}
              justify="center"
              align="center"
            >
              계약부문(재계약)
            </FlexLayout>

            <FlexLayout
              size={349}
              justify="center"
              align="center"
            >
              계약부문
            </FlexLayout>

            <FlexLayout
              size={137}
              justify="center"
              align="center"
            >
              영업방문부문
            </FlexLayout>

            <FlexLayout
              size={138}
              justify="center"
              align="center"
            >
              견적부문
            </FlexLayout>

            <FlexLayout
              size={798}
              justify="center"
              align="center"
            >
              공사부품부문
            </FlexLayout>

            <FlexLayout
              size={368}
              justify="center"
              align="center"
            >
              출장부문
            </FlexLayout>

            <FlexLayout
              size={138}
              justify="center"
              align="center"
            >
              개선제안부문
            </FlexLayout>

            <FlexLayout
              size={348}
              justify="center"
              align="center"
            >
              교육부문
            </FlexLayout>

            <FlexLayout
              size={138}
              justify="center"
              align="center"
            >
              업무일지부문
            </FlexLayout>

            <FlexLayout
              size={348}
              justify="center"
              align="center"
            >
              점수
            </FlexLayout>

            <FlexLayout
              size={208}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              차감점수
            </FlexLayout>

            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={98}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={297}
              justify="center"
              align="center"
              style={{ color: 'var(--color-blue)' }}
            >
              4분기
            </FlexLayout>

            <FlexLayout
              size={100}
              justify="center"
              align="center"
            >
            </FlexLayout>
          </GridLayoutAdditionalHeader>
        </FlexLayout>

        : scope.tabIndex === 1

          ? <FlexLayout
            isVertical={true}
            weight={0.01}
          >
            <GridLayoutAdditionalHeader
              ref={scope.additionalTitle2}
              height={Global.LAYOUT_TITLE_HEIGHT_1}
            >
              <FlexLayout
                size={260}
                justify="center"
                align="center"
              >
              </FlexLayout>

              <FlexLayout
                size={3018}
                justify="center"
                align="center"
              >
                점검부문
              </FlexLayout>

              <FlexLayout
                size={628}
                justify="center"
                align="center"
              >
                고장부문
              </FlexLayout>

              <FlexLayout
                size={278}
                justify="center"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                긴급출동(사람갇힘)
              </FlexLayout>

              <FlexLayout
                size={278}
                justify="center"
                align="center"
              >
                민원부문
              </FlexLayout>

              <FlexLayout
                size={488}
                justify="center"
                align="center"
              >
                검사부문
              </FlexLayout>

              <FlexLayout
                size={838}
                justify="center"
                align="center"
              >
                계약부문(신규)
              </FlexLayout>

              <FlexLayout
                size={868}
                justify="center"
                align="center"
              >
                계약부문(재계약)
              </FlexLayout>

              <FlexLayout
                size={349}
                justify="center"
                align="center"
              >
                계약부문
              </FlexLayout>

              <FlexLayout
                size={137}
                justify="center"
                align="center"
              >
                영업방문부문
              </FlexLayout>

              <FlexLayout
                size={138}
                justify="center"
                align="center"
              >
                견적부문
              </FlexLayout>

              <FlexLayout
                size={798}
                justify="center"
                align="center"
              >
                공사부품부문
              </FlexLayout>

              <FlexLayout
                size={368}
                justify="center"
                align="center"
              >
                출장부문
              </FlexLayout>

              <FlexLayout
                size={138}
                justify="center"
                align="center"
              >
                개선제안부문
              </FlexLayout>

              <FlexLayout
                size={357}
                justify="center"
                align="center"
              >
                교육부문
              </FlexLayout>

              <FlexLayout
                size={142}
                justify="center"
                align="center"
              >
                업무일지부문
              </FlexLayout>

              <FlexLayout
                size={358}
                justify="center"
                align="center"
              >
                점수
              </FlexLayout>

              <FlexLayout
                size={214}
                justify="center"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                차감점수
              </FlexLayout>

              <FlexLayout
                size={100}
                justify="center"
                align="center"
              >
              </FlexLayout>

              <FlexLayout
                size={201}
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                2분기
              </FlexLayout>

              <FlexLayout
                size={100}
                justify="center"
                align="center"
              >
              </FlexLayout>
            </GridLayoutAdditionalHeader>
          </FlexLayout>
          : ''
      }
      <GridLayout
        ref={scope.gridIndividual}
        scope={scope}
        headerHeight={53}
        header={IndividualGrid(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onHorizontalScroll={(x) => { scope.additionalTitle2.current?.scrollTo(x); }}
        data={scope.state.individuals}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
);
