import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Date8 } from '../../../../utils/time';
import { SendFax } from './SendFax';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { Button } from '../../../../components/forms/Button';
import { GridLayout } from '../../../../components/layout/GridLayout';

/*
 * 화면
 * @window w_tb_fax_send_01
 * @category 보낸팩스함
*/

export const SendFaxTemplate: React.FC<TemplateProps<SendFax>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={230}>
          <span style={{ marginRight: 30 }}>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={500}>
          <span style={{ marginRight: 40 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="제목으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout weight={2}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'status',
                text: '상태',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.status === '400' ? 'var(--color-red)' : x.status === '300' ? 'var(--color-blue)' : 'var(--color-black)' }}
                  >
                    {[
                      { value: '200', remark: '진행중' },
                      { value: '300', remark: '성공' },
                      { value: '400', remark: '실패' },
                    ].filter((y) => y.value === x.status)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'senddate',
                text: '전송일자',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.senddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '보낸이',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'faxnum',
                text: '받는이 팩스번호',
                width: 25,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.faxnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'subject',
                text: '제목/내용',
                width: 55,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.subject}
                  </FlexLayout>
                ),
              },
              {
                id: 'cnt',
                text: '첨부건수',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cnt}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.sendFaxList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
        <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
          <LayoutTitle size={180}>상세내용/보낸파일</LayoutTitle>
        </FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.detailTable}
            header={[
              {
                id: 'filename',
                text: '파일명',
                width: 50,
                render: (x) => (
                  <FlexLayout align="center">
                    <FlexLayout justify="left">
                      {x.filename}
                    </FlexLayout>
                    <FlexLayout justify="right">
                      <Button
                        isIcon={true}
                        onClick={() => scope.onDownload()}
                      >
                        <RiFolder2Line size={17}/>
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'fileext',
                text: '확장자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.fileext}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.sendFaxDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
);
