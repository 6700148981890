import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da034_01
 * @category 불출등록
 */

export class SupplyDetailModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly delseq: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly myn: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(60)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly pserial: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly banqty: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly chqty: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly reserve: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly taxdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly taxnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly proseq: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly prodv: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly pumseq: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly banflag: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly bandate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly bannum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly banseq: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly balseq: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly idate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly inum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly iseq: string;

  /**
   * 창고
   *
   * **data type** varchar()
   */
  readonly store: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.delseq = data.delseq || '';
    this.myn = data.myn || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.pserial = data.pserial || '';
    this.qty = data.qty || '';
    this.banqty = data.banqty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.remark = data.remark || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.chqty = data.chqty || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.reserve = data.reserve || '';
    this.perid = data.perid || '';
    this.taxdate = data.taxdate || '';
    this.taxnum = data.taxnum || '';
    this.projno = data.projno || '';
    this.proseq = data.proseq || '';
    this.prodv = data.prodv || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.pumseq = data.pumseq || '';
    this.banflag = data.banflag || '';
    this.bandate = data.bandate || '';
    this.bannum = data.bannum || '';
    this.banseq = data.banseq || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balseq = data.balseq || '';
    this.idate = data.idate || '';
    this.inum = data.inum || '';
    this.iseq = data.iseq || '';
    this.store = data.store || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
