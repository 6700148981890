import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { CustomerPresentTemplate } from './CustomerPresent.template';
import { CustomerPresentModel } from './CustomerPresent.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface CustomerPresentState {
  searchQuery: string;
  searchGroupcd: string;
  searchTelcd: string;
  data: Array<CustomerPresentModel>;
  CustomerPresentList: CustomerPresentModel;
  focused?: CustomerPresentModel;
  telcds?: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e911w_01
 * @category 고객현황
 */
export class CustomerPresent extends PageComponent<PageProps, CustomerPresentState>
  implements PageToolEvents {
  updatedRows?: Array<CustomerPresentModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
      CustomerPresentList: new CustomerPresentModel(),
    };
    this.state.CustomerPresentList.groupcd = '%';
    this.state.CustomerPresentList.telcd = '%';
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 전화분류
    const data = await api.dropdown('wf_dd_e902_02');

    if (!data) return;
    this.setState({ telcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    if (this.state.searchGroupcd) {
      this.state.CustomerPresentList.groupcd = this.state.searchGroupcd;
    }
    if (this.state.searchTelcd) {
      this.state.CustomerPresentList.telcd = this.state.searchTelcd;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        groupcd: this.state.CustomerPresentList.groupcd,
        telcd: this.state.CustomerPresentList.telcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items],
        }, () => {
          this.table.current?.update();
          next && next();
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        const index = await this.infinity?.retrieveTo('actcd', this.state.focused?.actcd, type, true) || 0;
        this.state.data.length > index && this.table.current?.setFocus(index);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, () => this.infinity?.retrieveAll());
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      groupcd: this.state.CustomerPresentList.groupcd,
      telcd: this.state.CustomerPresentList.telcd,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      groupcd: this.state.CustomerPresentList.groupcd,
      telcd: this.state.CustomerPresentList.telcd,
    });
  }

  @action
  onRowFocusEvent(item: CustomerPresentModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <CustomerPresentTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
