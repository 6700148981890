/**
 * 모델
 * @window w_tb_e501w_01
 * @category 현장방문숙지리스트
 */

export class SiteVisitModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly reader: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly atdcd: string;

  /**
   *
   *
   * **data type** varchar(12)
   */
  readonly todayplan: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly todaycompplan: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly plancomp: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly notplancomp: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly planyul: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly plancontcnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly plancont001cnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly plancont002cnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly rececompcnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly rececnt: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly wadcnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly misplan: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly planmiscnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly planmisamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly miscnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly misamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly notkccnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly kccnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly kc001cnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly kc002cnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly contcnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly cont001cnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly cont002cnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly compcnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly costcnt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly costcompcnt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly stdate2: string;

  /**
   * 노후된호기
   *
   * **data type** varchar(8)
   */
  readonly oldequpcnt: string;

  /**
   * 미매출
   *
   * **data type** varchar(8)
   */
  readonly compnotmiscnt: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 처리자명
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.kcpernm = data.kcpernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.reader = data.reader || '';
    this.atdcd = data.atdcd || '';
    this.todayplan = data.todayplan || '';
    this.todaycompplan = data.todaycompplan || '';
    this.plancomp = data.plancomp || '';
    this.notplancomp = data.notplancomp || '';
    this.planyul = data.planyul || '';
    this.plancontcnt = data.plancontcnt || '';
    this.plancont001cnt = data.plancont001cnt || '';
    this.plancont002cnt = data.plancont002cnt || '';
    this.rececompcnt = data.rececompcnt || '';
    this.rececnt = data.rececnt || '';
    this.wadcnt = data.wadcnt || '';
    this.misplan = data.misplan || '';
    this.planmiscnt = data.planmiscnt || '';
    this.planmisamt = data.planmisamt || '';
    this.miscnt = data.miscnt || '';
    this.misamt = data.misamt || '';
    this.notkccnt = data.notkccnt || '';
    this.kccnt = data.kccnt || '';
    this.kc001cnt = data.kc001cnt || '';
    this.kc002cnt = data.kc002cnt || '';
    this.contcnt = data.contcnt || '';
    this.cont001cnt = data.cont001cnt || '';
    this.cont002cnt = data.cont002cnt || '';
    this.compcnt = data.compcnt || '';
    this.costcnt = data.costcnt || '';
    this.costcompcnt = data.costcompcnt || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.stdate2 = data.stdate2 || '';
    this.oldequpcnt = data.oldequpcnt || '';
    this.compnotmiscnt = data.compnotmiscnt || '';
    this.equpnm = data.equpnm || '';
  }
}
