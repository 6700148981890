import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { DepositConfirmationTemplate } from './DepositConfirmation.template';
import { InfinityRetrieve } from '../../../../models';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { DepositConfirmationModel } from './DepositConfirmation.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Update } from '../../../../utils/array';

interface DepositConfirmationState {
  // 조회 조건
  stdate: string;
  enddate: string;
  searchQuery: string;
  perid: string;
  pernm: string;
  sendflag: string;

  // kakao 유무 체크
  kakaoyn: string;

  // data
  depositConfirmationList: Array<DepositConfirmationModel>;
  focusedData: DepositConfirmationModel;
}

/**
 * 컨트롤러
 * @window w_tb_da026_sms
 * @category 입금확인[카톡]내역
 */
export class DepositConfirmation extends PageComponent<PageProps, DepositConfirmationState>
  implements PageToolEvents {
  updatedRows?: Array<DepositConfirmationModel>;

  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate();

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // 조회 조건
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      perid: '',
      pernm: '',
      sendflag: '1',

      // kakao 유무 체크
      kakaoyn: '',

      // data
      depositConfirmationList: [],
      focusedData: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const openData = await api.fxExec('open');
    await this.SS({
      kakaoyn: openData?.alim_kakao_useyn,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        sendflag: this.state.sendflag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          depositConfirmationList: [...this.state.depositConfirmationList, ...items.map((x: any) => new DepositConfirmationModel(x))],
        });
      },
      async () => {
        await this.SS({
          depositConfirmationList: [],
        });
        await this.infinity?.retrieveAll();
      },
    );

    await this.SS({
      depositConfirmationList: [],
    });

    await this.infinity?.retrieveAll();

    if (this.state.sendflag === '1') {
      await this.grid.current?.setFocus(0);
    } else {
      await this.table.current?.setFocus(0);
      await this.table.current?.update(true);
    }
  }

  @action
  onRowFocusEvent(item:DepositConfirmationModel) {
    this.setState({ focusedData: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ depositConfirmationList: rows });
  }

  @action
  async onCheckList(type: string) {
    let noChk = true;

    // 전송할 내역 있는지 찾기
    this.state.depositConfirmationList.forEach((x) => {
      if (x.chk === '1') {
        noChk = false;
      }
    });

    // chk 하나도 안되있을 때 경고창
    if (noChk) {
      ConfirmWarning.show('경고', '전송할 내역을 하나이상 선택해주세요.');
      return;
    }

    ConfirmWarning.show('경고', '연락처가 없거나 핸드폰번호가 아닌 번호는 \n 자동 전송하지 않습니다\n 선택이 해제됩니다');

    // 체크한것중에서 신고자 번호가없거나 번호가 010이 아닌 리스트는 체크풀어줌
    for (let i = 0; i < this.state.depositConfirmationList.length; i += 1) {
      const x = this.state.depositConfirmationList[i];

      if (x.chk === '1' && !x.hp?.startsWith('010')) {
        // eslint-disable-next-line no-await-in-loop
        await Update.byIndex(this, 'depositConfirmationList', i, 'chk', '0');
      }
    }

    await this.table.current?.update(false);

    // kakao버튼이면 kakao로
    // sms버튼이면 sms로
    if (type === 'kakao') {
      await this.onSendKakao();
    } else if (type === 'sms') {
      await this.onSendSms();
    }
  }

  @action
  async onSendSms() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_sms', {
      items: this.state.depositConfirmationList?.filter((x) => x.chk === '1').map((x) => ({
        chk: x.chk,
        cltcd: x.cltcd,
        cltnm: x.cltnm,
        remark: x.remark,
        rcvamt: x.rcvamt,
        rcvdate: x.rcvdate,
        rcvnum: x.rcvnum,
        hp: x.hp,
      })),
    });

    if (data?.titlebox === '성공') {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onSendKakao() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_kakaosend', {
      items: this.state.depositConfirmationList?.filter((x) => x.chk === '1').map((x) => ({
        chk: x.chk,
        cltcd: x.cltcd,
        cltnm: x.cltnm,
        remark: x.remark,
        rcvamt: x.rcvamt,
        rcvdate: x.rcvdate,
        rcvnum: x.rcvnum,
        hp: x.hp,
      })),
    });

    if (data?.titlebox === '성공') {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.state.depositConfirmationList.length < 1) {
      ConfirmWarning.show('경고', '출력할 내역이 없습니다');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      sendflag: this.state.sendflag,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (this.state.depositConfirmationList.length < 1) {
      ConfirmWarning.show('경고', '엑셀 전환할 내역이 없습니다');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      sendflag: this.state.sendflag,
    });
  }

  render() {
    return (
      <DepositConfirmationTemplate
        scope={this}
      >
      </DepositConfirmationTemplate>
    );
  }
}
