import { action } from 'mobx';
import { PageComponent } from '../../../../utils';
import { YearInsuranceRatesRegisterTemplate } from './YearInsuranceRatesRegister.template';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { Today } from '../../../../utils/time';
import { YearInsuranceRatesRegisterModel } from './YearInsuranceRatesRegister.model';
import { InfinityRetrieve } from '../../../../models';

interface YearInsuranceRatesRegisterState {
  year: string;
  data: YearInsuranceRatesRegisterModel;
}

/**
 *
 * 화면
 * @window w_tb_pb300_insu
 * @category 년공제보험요율등록.
 */
export class YearInsuranceRatesRegister extends PageComponent<PageProps, YearInsuranceRatesRegisterState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      year: Today.year(),
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        year: this.state.year,
      },
    );

    // 성공시 상태를 반영하고, 다른 작업을 계속한다
    if (data) {
      this.setState({
        data: new YearInsuranceRatesRegisterModel({
          ...data,
        }),
      });
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save(this.state.data, false);
    await this.onRetrieveEvent();
  }

  render() {
    return (
      <YearInsuranceRatesRegisterTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}

