import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  GridLayoutAdditionalHeader,
  LabelBox,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PayCalculate } from './PayCalculate';
import { Format } from '../../../../utils/string';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

const mijflags = [
  { value: '0', remark: '미지급' },
  { value: '1', remark: '매입' },
  { value: '2', remark: '지급' },
];

/**
 * 화면
 * @window w_tb_pb300_01
 * @category 급여계산
 */
export const PayCalculateTemplate: React.FC<TemplateProps<PayCalculate>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>월</span>
        <DateTextBox
          value={scope.state.yearmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => {
            setData({ yearmon: value },
              () => scope.onRetrieveEvent());
          }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={240}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => setData({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={176}>
        <span>임원구분</span>
        <ComboBox
          value={scope.state.offiyn}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '임원' },
            { value: '0', remark: '직원' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ offiyn: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={230}>
        <span>사원명</span>
        <TextBox
          value={scope.state.searchName}
          onChange={(value) => setData({ searchName: value })}
          onEnter={() => scope.onNameFocusBtn(scope.state.searchName)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span>순서1</span>
        <ComboBox
          value={scope.state.sequence}
          textAlign="center"
          data={[
            { value: '0', remark: '순번' },
            { value: '1', remark: '사원번호' },
            { value: '2', remark: '성명' },
            { value: '3', remark: '연봉급여총액' },
            { value: '4', remark: '실수령금액' },
            { value: '5', remark: '직책' },
            { value: '6', remark: '입사일' },
            { value: '7', remark: '퇴사일' },
            { value: '8', remark: '성명' },
            { value: '9', remark: '부서' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ sequence: option.value },
            () => scope.onSequenceBtn(scope.state.index))}
        />

        <ComboBox
          value={scope.state.index}
          textAlign="center"
          data={[
            { value: '0', remark: '오름차순' },
            { value: '1', remark: '내림차순' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ index: option.value },
            () => scope.onSequenceBtn(option.value))}
        />
      </FlexLayout>

      <FlexLayout
        align="center"
        style={{
          color: 'var(--color-red)',
          marginLeft: 16,
        }}
      >
        * 이미 정산(매입, 지급)된 내역은 수정할 수 없습니다!
      </FlexLayout>

      <Button onClick={() => scope.onRefresh()}><div style={{ width: 80 }}>새로고침</div></Button>
      {scope.state.custcd === 'dmyong_g' ? <Button onClick={() => scope.imageRequest()}><div style={{ width: 80 }}>연봉계약서</div></Button> : ''}
    </SearchLayout>

    {scope.state.data[0]?.pay_tax0_01_t.length === 0
      ? <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
          >
            <FlexLayout
              size={540}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={68}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={398}
              justify="center"
              align="center"
            >
              기본급여계산
            </FlexLayout>

            <FlexLayout
              size={298}
              justify="center"
              align="center"
            >
              통상임금
            </FlexLayout>

            <FlexLayout
              size={498}
              justify="center"
              align="center"
            >
              기본급여반영금액
            </FlexLayout>

            <FlexLayout
              size={478}
              justify="center"
              align="center"
            >
              통상시급시간계산(기본+고정연장)
            </FlexLayout>

            <FlexLayout
              size={238}
              justify="center"
              align="center"
            >
              연차
            </FlexLayout>

            <FlexLayout
              size={718}
              justify="center"
              align="center"
            >
              추가연장근로수당
            </FlexLayout>

            <FlexLayout
              size={508}
              justify="center"
              align="center"
            >
              기타수당
            </FlexLayout>

            <FlexLayout
              size={638}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              차감수당
            </FlexLayout>

            <FlexLayout
              size={948}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              공제내역
            </FlexLayout>

            <FlexLayout
              size={280}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              연말환급
            </FlexLayout>
          </GridLayoutAdditionalHeader>

          <TableLayout
            ref={scope.table}
            headerHeight={50}
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 50,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.mijflag === '0'
                          ? 'var(--color-red)'
                          : x.mijflag === '2'
                            ? 'var(--color-blue)'
                            : '#7F7A2C',
                      }}
                    >
                      {mijflags.filter((y) => y.value === x.mijflag)[0]?.remark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 100,
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      입사일
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      퇴사일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.entdate)}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.dayterm2}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'sex',
                text: '성별',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.sex === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {x.sex === '1' ? '남' : '여'}
                  </FlexLayout>
                ),
              },
              {
                id: 'rspnm',
                text: '직책',
                width: 70,
              },
              {
                id: 'pernm',
                text: '성명',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
              },
              {
                id: 'tot_samt',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      월간연봉급여총액
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      공제금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      실수령액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tot_samt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.tot_tamt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.tot_mamt)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'p_refresh',
                text: '재계산',
                width: 70,
                render: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <Button
                      isMini={true}
                      onClick={() => scope.onReloadBtn('p_refresh')}
                    >
                      RELOAD
                    </Button>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_month',
                text: '월간급여금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_month_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.pay_month}
                        style={{ backgroundColor: '#FFFFD2' }}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'pay_month', rowUpdate);
                        }}
                      />
                    : <FlexLayout
                      justify="right"
                      align="center"
                      >
                       {Format.number(x.pay_month)}
                      </FlexLayout>
                ),
              },
              {
                id: 'pay_year',
                text: '년간급여금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_year_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_year)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_day',
                text: '일급금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_day_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_day)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_hour',
                text: '시급금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_hour_tot)}
                  </FlexLayout>
                ),
                color: '#8106aa',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#E6FFFF' }}
                  >
                    {Format.number(x.pay_hour)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tot',
                text: '통상임금총액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tot_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_basic',
                text: '기본급(주휴포함)',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_basic_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_basic)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_food',
                text: '식대',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_food_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.pay_food_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'pay_food_flag', rowUpdate);
                          }
                        }
                      />
                      <FormatNumberTextBox
                        textAlign="right"
                        ref={ref}
                        value={x.pay_food}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'pay_food', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.pay_food_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.pay_food)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_serallow',
                text: '직무수당',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_serallow_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.pay_serallow_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'pay_serallow_flag', rowUpdate);
                          }
                        }
                      />
                      <FormatNumberTextBox
                        textAlign="right"
                        ref={ref}
                        value={x.pay_serallow}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'pay_serallow', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.pay_serallow_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.pay_serallow)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_older',
                text: '고정외 근로수당',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_older_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_older)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_night',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      고정야간
                    </FlexLayout>

                    <FlexLayout>
                      근로수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_night_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_night)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_holiday',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      고정휴일
                    </FlexLayout>

                    <FlexLayout>
                      근로수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_holiday_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_holiday)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_yearly',
                text: '연차수당',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_yearly_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_yearly)}
                  </FlexLayout>
                ),
              },
              {
                id: 'time_basic',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      월
                    </FlexLayout>

                    <FlexLayout>
                      법정근로시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_basic}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_basic', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_basic)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_older',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      고정시간외
                    </FlexLayout>

                    <FlexLayout>
                      할증시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_older}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_older', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_older)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_night',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      고정야간
                    </FlexLayout>

                    <FlexLayout>
                      할증시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_night}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_night', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_night)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_holiday',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      고정휴일
                    </FlexLayout>

                    <FlexLayout>
                      할증시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_holiday}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_holiday', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_holiday)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_yearly',
                text: '연차산정시간',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_yearly}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_yearly', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_yearly)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_tot',
                text: '산출 총시간',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#E6FFFF' }}
                  >
                    {Format.number(x.time_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yearly_default',
                text: '대상기본연차',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.yearly_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'yearly_flag', rowUpdate);
                            rowUpdate({ yearly_flag: value ? '1' : '0' });
                          }
                        }
                      />

                      <FormatNumberTextBox
                        textAlign="right"
                        value={x.yearly_default}
                        ref={ref}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'yearly_default', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.yearly_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.yearly_default)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'yearly_add',
                text: '자동증가연차',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.yearly_add)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yearly_cnt',
                text: '총연차일수',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#E6FFFF' }}
                  >
                    {Format.number(x.yearly_cnt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_older_time_cal',
                text: '연장시간계산',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_older_time_cal_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_older_time_cal}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_older_time_cal', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_older_time_cal)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_older_time',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      연장확정시간
                    </FlexLayout>

                    <FlexLayout>
                      (1.5배)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_older_time_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_older_time)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_older_pay',
                text: '연장정산금액',
                width: 80,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_older_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_older_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_duty_time_cal',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      야근근무
                    </FlexLayout>

                    <FlexLayout>
                      시간계산
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_duty_time_cal_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_duty_time_cal}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_duty_time_cal', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_duty_time_cal)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_duty_time',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      야근확정시간
                    </FlexLayout>

                    <FlexLayout>
                      (1.5배)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_duty_time_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_duty_time)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_duty_pay',
                text: '야근정산금액',
                width: 80,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_duty_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_duty_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_holi_time_cal',
                text: '휴일일수계산',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_holi_time_cal_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_holi_time_cal}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_holi_time_cal', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_holi_time_cal)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_holi_time',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      휴일확정시간
                    </FlexLayout>

                    <FlexLayout>
                      (1.5배)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_holi_time_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_holi_time)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_holi_pay',
                text: '휴일정산금액',
                width: 80,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_holi_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_holi_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_leader',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="start"
                  >
                    <FlexLayout>
                      인센티브1
                    </FlexLayout>

                    <FlexLayout>
                      (직책수당)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_leader_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_leader}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_leader', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_leader)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_bonus',
                text: '상여금',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_bonus_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_bonus}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_bonus', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_bonus)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_incentive',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      인센티브2(월실적)
                    </FlexLayout>

                    <FlexLayout>
                      <Button onClick={() => scope.onReloadBtn('p_incentive')}>
                        RELOAD
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 110,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_incentive_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_incentive}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_incentive', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_incentive)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_other',
                text: '기타수당통신',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_other_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_other}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_other', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_other)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_other_random',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      {scope.state.data[0]?.pay_other_random_t}
                    </FlexLayout>

                    <FlexLayout>
                      <Button
                        style={{ height: 27, marginTop: 10 }}
                        onClick={() => scope.textModal(true)}
                      >
                        RELOAD
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_other_random_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_other_random}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_other_random', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_other_random)}
                    </FlexLayout>
                ),
              },
              {
                id: 'month_basic',
                text: '매월기준일수',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.month_basic}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'month_basic', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.month_basic)}
                    </FlexLayout>
                ),
              },
              {
                id: 'month_minus',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      매월차감일수
                    </FlexLayout>

                    <FlexLayout>
                      (결근)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.month_minus_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      color={'var(--color-red)'}
                      value={x.month_minus}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'month_minus_tot', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.month_minus)}
                    </FlexLayout>
                ),
              },
              {
                id: 'minuspay_basic',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      기본급(주휴)
                    </FlexLayout>

                    <FlexLayout>
                      차감금액(결근)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_basic_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_basic)}
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_older',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      고정시간외
                    </FlexLayout>

                    <FlexLayout>
                      수당차감금액
                    </FlexLayout>

                    <FlexLayout>
                      (결근)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_older_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_older)}
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_time',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      차감시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_late_time_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.minuspay_late_time}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'minuspay_late_time', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.minuspay_late_time)}
                    </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_pay',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      차감금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_late_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_late_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_tot',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      결근지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      총 차감금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_late_tot_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_late_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'deduction',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      소득공제
                    </FlexLayout>

                    <FlexLayout>
                      인원
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 50,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.deduction_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.deduction}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'deduction', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.deduction)}
                    </FlexLayout>
                ),
              },
              {
                id: 'payamt_tax',
                text: '소득세',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.payamt_tax_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.payamt_tax}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'payamt_tax', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.payamt_tax)}
                    </FlexLayout>
                ),
              },
              {
                id: 'payamt_tax2',
                text: '지방소득세',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.payamt_tax2_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.payamt_tax2}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'payamt_tax2', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.payamt_tax2)}
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_maf',
                text: '상조회비',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_maf_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_maf_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_maf_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_maf}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_maf', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_maf_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_maf)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_unem',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      고용보험
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_unem_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_unem_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_unem_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_unem_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_unem}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_unem', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_unem_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_unem)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_health',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      건강보험
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_health_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_health_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_health_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_health_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_health}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_health', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_health_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_health)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_longterm',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      장기요양
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_longterm_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_longterm_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_longterm_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_longterm_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_longterm}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_longterm', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_longterm_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_longterm)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_national',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      국민연금
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_national_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_national_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_national_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_national_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_national}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_national', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_national_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_national)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_national',
                text: '연말정산보험료',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_national_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_national}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_national', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_national)}
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_suspense',
                text: '가불금',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_suspense_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_suspense}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_suspense', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_suspense)}
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_other',
                text: '기타공제',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_other_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_other}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_other', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_other)}
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_income',
                text: '소득세 환급금',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_income_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tax_income)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_health',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      건강보험료
                    </FlexLayout>

                    <FlexLayout>
                      환급금
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_health_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tax_health)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rest_day',
                text: '남은연차',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.rest_day_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      textAlign="right"
                      style={{ backgroundColor: '#FFFFD2' }}
                      value={x.rest_day}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'rest_day', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rest_day)}
                    </FlexLayout>
                ),
              },
              {
                id: 'rest_pay',
                text: (
                  <FlexLayout
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      연차수당
                    </FlexLayout>

                    <FlexLayout>
                      정산금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.rest_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rest_pay)}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
            onHorizontalScroll={(x) => { scope.additionalTitle.current?.scrollTo(x); }}
          />
        </FlexLayout>
      </FlexLayout>
      : <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayoutAdditionalHeader
            ref={scope.additionalTitle}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
          >
            <FlexLayout
              size={540}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={68}
              justify="center"
              align="center"
            >
            </FlexLayout>

            <FlexLayout
              size={198}
              justify="center"
              align="center"
            >
              기본급여계산
            </FlexLayout>

            <FlexLayout
              size={298}
              justify="center"
              align="center"
            >
              통상임금
            </FlexLayout>

            <FlexLayout
              size={498}
              justify="center"
              align="center"
            >
              기본급여반영금액
            </FlexLayout>

            <FlexLayout
              size={478}
              justify="center"
              align="center"
            >
              통상시급시간계산(기본+고정연장)
            </FlexLayout>

            <FlexLayout
              size={238}
              justify="center"
              align="center"
            >
              연차
            </FlexLayout>

            <FlexLayout
              size={718}
              justify="center"
              align="center"
            >
              추가연장근로수당
            </FlexLayout>

            <FlexLayout
              size={798}
              justify="center"
              align="center"
            >
              과세항목
            </FlexLayout>

            <FlexLayout
              size={698}
              justify="center"
              align="center"
              style={{ color: 'var(--color-violet)' }}
            >
              비과세항목
            </FlexLayout>

            <FlexLayout
              size={638}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              차감수당
            </FlexLayout>

            <FlexLayout
              size={178}
              justify="center"
              align="center"
            >
              연차
            </FlexLayout>

            <FlexLayout
              size={198}
              justify="center"
              align="center"
            >
              연말환급
            </FlexLayout>

            <FlexLayout
              size={1038}
              justify="center"
              align="center"
              style={{ color: 'var(--color-red)' }}
            >
              공제내역
            </FlexLayout>
          </GridLayoutAdditionalHeader>

          <TableLayout
            ref={scope.table}
            headerHeight={50}
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 50,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.mijflag === '0'
                          ? 'var(--color-red)'
                          : x.mijflag === '2'
                            ? 'var(--color-blue)'
                            : '#7F7A2C',
                      }}
                    >
                      {mijflags.filter((y) => y.value === x.mijflag)[0]?.remark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 100,
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      입사일
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      퇴사일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.entdate)}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.dayterm2}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'sex',
                text: '성별',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.sex === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {x.sex === '1' ? '남' : '여'}
                  </FlexLayout>
                ),
              },
              {
                id: 'rspnm',
                text: '직책',
                width: 70,
              },
              {
                id: 'pernm',
                text: '성명',
                width: 70,
              },
              {
                id: 'tot_samt',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: '#7F7A2C' }}
                    >
                      월간연봉급여총액
                    </FlexLayout>
                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      공제금액
                    </FlexLayout>
                    <FlexLayout
                      align="end"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      실수령액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tot_samt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.tot_tamt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.tot_mamt)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'p_refresh',
                text: '재계산',
                width: 70,
                render: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <Button
                      style={{
                        height: 27,
                        width: 60,
                        marginTop: 10,
                      }}
                      onClick={() => scope.onReloadBtn('p_refresh')}
                    >
                      RELOAD
                    </Button>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_month',
                text: '월간급여금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_month_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      textAlign="right"
                      value={x.pay_month}
                      style={{ backgroundColor: '#FFFFD2' }}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_month', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_month)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_year',
                text: '년간급여금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_year_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_year)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_day',
                text: '일급금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_day_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_day)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_hour',
                text: '시급금액',
                width: 100,
                color: '#8106aa',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_hour_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#E6FFFF' }}
                  >
                    {Format.number(x.pay_hour)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tot',
                text: '통상임금총액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tot_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_basic',
                text: '기본급(주휴포함)',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_basic_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_basic)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_older',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      고정시간외
                    </FlexLayout>

                    <FlexLayout>
                      근로수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_older_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_older)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_night',
                text: '고정야간근로수당',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_night_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_night)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_holiday',
                text: '고정휴일근로수당',
                color: 'var(--color-blue)',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_holiday_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_holiday)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_yearly',
                text: '연차수당',
                color: 'var(--color-blue)',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_yearly_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_yearly)}
                  </FlexLayout>
                ),
              },
              {
                id: 'time_basic',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      월
                    </FlexLayout>

                    <FlexLayout>
                      법정근로시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_basic}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_basic', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_basic)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_older',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      고정시간외
                    </FlexLayout>

                    <FlexLayout>
                      할증시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_older}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_older', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_older)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_night',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      고정야간
                    </FlexLayout>

                    <FlexLayout>
                      할증시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_night}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_night', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_night)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_holiday',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      고정휴일
                    </FlexLayout>

                    <FlexLayout>
                      할증시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_holiday}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_holiday', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_holiday)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_yearly',
                text: '연차산정시간',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.time_yearly}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'time_yearly', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.time_yearly)}
                    </FlexLayout>
                ),
              },
              {
                id: 'time_tot',
                text: '산출 총시간',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#E6FFFF' }}
                  >
                    {Format.number(x.time_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yearly_default',
                text: '대상기본연차',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.yearly_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'yearly_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        textAlign="right"
                        ref={ref}
                        value={x.yearly_default}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'yearly_default', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.yearly_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.yearly_default)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'yearly_add',
                text: '자동증가연차',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.yearly_add)}
                  </FlexLayout>
                ),
              },
              {
                id: 'yearly_cnt',
                text: '총연차일수',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#E6FFFF' }}
                  >
                    {Format.number(x.yearly_cnt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_older_time_cal',
                text: '연장시간계산',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_yearly_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_older_time_cal}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_older_time_cal', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_older_time_cal)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_older_time',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      연장확정시간
                    </FlexLayout>

                    <FlexLayout>
                      (1.5배)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_older_time_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_older_time}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_older_time', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_older_time)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_older_pay',
                text: '연장정산금액',
                width: 80,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_older_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_older_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_duty_time_cal',
                text: '당직일수계산',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_duty_time_cal_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_duty_time_cal}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_duty_time_cal', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_duty_time_cal)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_duty_time',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      평일당직비
                    </FlexLayout>

                    <FlexLayout>
                      <Button onClick={() => scope.payModal(true)}>
                        RELOAD
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_duty_time_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_duty_time}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_duty_time', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_duty_time)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_duty_pay',
                text: '당직정산금액',
                width: 80,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_duty_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_duty_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'over_holi_time_cal',
                text: '휴일일수계산',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_holi_time_cal_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_holi_time_cal}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_holi_time_cal', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_holi_time_cal)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_holi_time',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      휴일당직비
                    </FlexLayout>

                    <FlexLayout>
                      <Button onClick={() => scope.onReloadBtn('p_over_holi_time')}>
                        RELOAD
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_holi_time_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.over_holi_time}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'over_holi_time', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.over_holi_time)}
                    </FlexLayout>
                ),
              },
              {
                id: 'over_holi_pay',
                text: '휴일정산금액',
                width: 80,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.over_holi_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.over_holi_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_01',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_01_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_01_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_01_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_01}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_01', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_01)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_02',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_02_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_02_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_02_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_02}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_02', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_02)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_03',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_03_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_03_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_03_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_03}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_03', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_03)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_04',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_04_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_04_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_04_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_04}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_04', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_04)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_05',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_05_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_05_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_05_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_05}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_05', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_05)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_06',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_06_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_06_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_06_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_06}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_06', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_06)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_07',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_07_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_07_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_07_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_07}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_07', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_07)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_08',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax0_08_t === '' ? null : <FlexLayout>[변동]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax0_08_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax0_08_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax0_08}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax0_08', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax0_08)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_01',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_01_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_01_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_01_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_01}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_01', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_01)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_02',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_02_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_02_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_02_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_02}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_02', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_02)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_03',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_03_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_03_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_03_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_03}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_03', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_03)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_04',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_04_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_04_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_04_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_04}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_04', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_04)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_05',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_05_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_05_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_05_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_05}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_05', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_05)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_06',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_06_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_06_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_06_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_06}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_06', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_06)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_07',
                text: (
                  <FlexLayout isVertical={true}>
                    {scope.state.data[0]?.pay_tax1_07_t === '' ? null : <FlexLayout>[고정]</FlexLayout>}
                    <FlexLayout>{scope.state.data[0]?.pay_tax1_07_t}</FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.pay_tax1_07_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.pay_tax1_07}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'pay_tax1_07', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_tax1_07)}
                    </FlexLayout>
                ),
              },
              {
                id: 'month_basic',
                text: '매월기준일수',
                width: 80,
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.month_basic}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'month_basic', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.month_basic)}
                    </FlexLayout>
                ),
              },
              {
                id: 'month_minus',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      매월차감일수
                    </FlexLayout>

                    <FlexLayout>
                      (결근)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.month_minus_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{
                        backgroundColor: '#FFFFD2',
                        color: 'var(--color-red)',
                      }}
                      textAlign="right"
                      value={x.month_minus}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'month_minus', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.month_minus)}
                    </FlexLayout>
                ),
              },
              {
                id: 'minuspay_basic',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      기본급(주휴)
                    </FlexLayout>

                    <FlexLayout>
                      차감금액(결근)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_basic_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{
                        backgroundColor: '#FFFFD2',
                        color: 'var(--color-red)',
                      }}
                      textAlign="right"
                      value={x.minuspay_basic}
                      onChange={(value) => rowUpdate({ minuspay_basic: value })}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.minuspay_basic)}
                    </FlexLayout>
                ),
              },
              {
                id: 'minuspay_older',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      고정시간외
                    </FlexLayout>

                    <FlexLayout>
                      수당차감금액
                    </FlexLayout>

                    <FlexLayout>
                      (결근)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_older_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_older)}
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_time',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      차감시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_late_time_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.minuspay_late_time}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'minuspay_late_time', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.minuspay_late_time)}
                    </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_pay',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      차감금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_late_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_late_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_tot',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      결근지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      총 차감금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.minuspay_late_tot_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      backgroundColor: '#E6FFFF',
                      color: 'var(--color-red)',
                    }}
                  >
                    {Format.number(x.minuspay_late_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rest_day',
                text: '남은연차',
                width: 80,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.rest_day_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.rest_day}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'rest_day', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.rest_day)}
                    </FlexLayout>
                ),
              },
              {
                id: 'rest_pay',
                text: '연차수당정산금액',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.rest_pay_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rest_pay)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_income',
                text: '소득세환급금',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_income_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_income}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_income', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_income)}
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_health',
                text: '건강보험료환급금',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_health_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_health}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_health', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_health)}
                    </FlexLayout>
                ),
              },
              {
                id: 'deduction',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      소득공제
                    </FlexLayout>

                    <FlexLayout>
                      인원
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 50,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.deduction_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.deduction}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'deduction', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.deduction)}
                    </FlexLayout>
                ),
              },
              {
                id: 'payamt_tax',
                text: '소득세',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.payamt_tax_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.payamt_tax}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'payamt_tax', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.payamt_tax)}
                    </FlexLayout>
                ),
              },
              {
                id: 'payamt_tax2',
                text: '지방소득세',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.payamt_tax2_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.payamt_tax2}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'payamt_tax2', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.payamt_tax2)}
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_maf',
                text: '상조회비',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_maf_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_maf_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_maf_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_maf}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_maf', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_maf_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_maf)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_unem',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      고용보험
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_unem_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_unem_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_unem_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_unem_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_unem}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_unem', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_unem_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_unem)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_health',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      건강보험
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_health_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_health_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_health_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_health_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_health}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_health', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_health_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_health)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_longterm',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      장기요양
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_longterm_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_longterm_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_longterm_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_longterm_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_longterm}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_longterm', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_longterm_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_longterm)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'insu_national',
                text: (
                  <FlexLayout
                    isVertical={true}
                    align="center"
                  >
                    <FlexLayout>
                      국민연금
                    </FlexLayout>

                    <FlexLayout>
                      보험료{scope.state.insu_national_def}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.insu_national_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox
                        value={x.insu_national_flag === '1'}
                        onChange={
                          (value) => {
                            scope?.onItemChange(x, value ? '1' : '0', 'insu_national_flag', rowUpdate);
                          }
                        }
                      />

                      <FormatNumberTextBox
                        ref={ref}
                        textAlign="right"
                        value={x.insu_national}
                        onChange={(value) => {
                          scope.onItemChange(x, value, 'insu_national', rowUpdate);
                        }}
                      />
                    </FlexLayout>

                    : <FlexLayout
                      isVertical={true}
                      style={{ backgroundColor: '#FFFFD2' }}
                    >
                      <CheckBox value={x.insu_national_flag === '1'}/>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.insu_national)}
                      </FlexLayout>
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_national',
                text: '연말정산보험료',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_national_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_national}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_national', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_national)}
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_suspense',
                text: '가불금',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_suspense_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_suspense}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_suspense', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_suspense)}
                    </FlexLayout>
                ),
              },
              {
                id: 'tax_other',
                text: '기타공제',
                width: 100,
                color: 'var(--color-blue)',
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.tax_other_tot)}
                  </FlexLayout>
                ),
                render: (x, rowUpdate, ref) => (
                  x.mijflag === '0'
                    ? <FormatNumberTextBox
                      ref={ref}
                      style={{ backgroundColor: '#FFFFD2' }}
                      textAlign="right"
                      value={x.tax_other}
                      onChange={(value) => {
                        scope.onItemChange(x, value, 'tax_other', rowUpdate);
                      }}
                    />
                    : <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tax_other)}
                    </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
            onHorizontalScroll={(x) => { scope.additionalTitle.current?.scrollTo(x); }}
          />
        </FlexLayout>
      </FlexLayout>
    }

    <ModalView
      width={350}
      height={150}
      isVisible={scope.state.modalVisible}
      onClose={() => scope.textModal(false)}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="변경할기타수당명">
            <TextBox
              value={scope.state.titleName}
              onChange={(value) => scope.setState({ titleName: value })}
              onEnter={() => scope.onReloadBtn('p_change')}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          <Button
            style={{
              height: 25,
              width: 80,
            }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.onReloadBtn('p_change')}
          >
            예
          </Button>

          <Button
            style={{
              height: 25,
              width: 80,
            }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.textModal(false)}
          >
            아니오
          </Button>
        </FlexLayout>
      </FlexLayout>
    </ModalView>

    <ModalView
      width={350}
      height={150}
      isVisible={scope.state.modalPayVisible}
      onClose={() => scope.payModal(false)}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="금액">
            <FormatNumberTextBox
              value={scope.state.payTitleName}
              onChange={(value) => scope.setState({ payTitleName: value })}
              onEnter={() => scope.onReloadBtn('p_over_duty_time')}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout
          justify="center"
          align="center"
        >
          <Button
            style={{
              height: 25,
              width: 80,
            }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.onReloadBtn('p_over_duty_time')}
          >
            예
          </Button>

          <Button
            style={{
              height: 25,
              width: 80,
            }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.payModal(false)}
          >
            아니오
          </Button>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
  );
};
