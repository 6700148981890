import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { Unpaid } from './Unpaid';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ca642w_02
 * @category 미지급현황
 */
export const UnpaidTemplate: React.FC<TemplateProps<Unpaid>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={250}>
          <span>매입구분</span>
          <ComboBox
            value={scope.state.searchGubun}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.gubun?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
            onSelect={(option) => setData({ searchGubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout align="center">
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />

          <FlexLayout
            size={100}
            style={{
              height: '67%',
              backgroundColor: '#FFFFFF',
              border: 'solid 1px var(--color-tools-overlay)',
            }}
          >
            <CheckBox
              style={{
                width: 0,
                border: 0,
              }}
              value={scope.state.resuck === '1'}
              onChange={(v) => setData({ resuck: v ? '1' : '0' },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />

            <FlexLayout weight={2.5}>
              잔액체크
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </SearchLayout>
    </FlexLayout>

    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'cltcd',
          text: '거래처코드',
          width: 10,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              합 계
            </FlexLayout>
          ),
        },
        {
          id: 'cltnm',
          text: '거래처명',
          width: 40,
          trail: () => (
            <FlexLayout
              justify="left"
              align="center"
            >
              건수: {scope.state.total}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="left"
              align="center"
            >
              {x.cltnm}
            </FlexLayout>
          ),
        },
        {
          id: 'beamt',
          text: '전일잔액',
          width: 10,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.beamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.beamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'mijamt',
          text: '발생금액',
          width: 10,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.mijamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.mijamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'sndamt',
          text: '지급액',
          width: 10,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.sndamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.sndamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'resuamt',
          text: '미지급금',
          width: 10,
          trail: () => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {scope.state.resuamt_tot}
            </FlexLayout>
          ),
          render: (x) => (
            <FlexLayout
              justify="right"
              align="center"
            >
              {Format.number(x.resuamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 10,
        },
      ]}
      infinityHandler={scope.infinity}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      onRowDoubleClick={() => scope.textModal(true)}
      data={scope.state.data}
    />

    <ModalView
      isVisible={scope.state.textDetailModal}
      onClose={() => scope.textModal(false)}
      width={1000}
      height={800}
    >
      <FlexLayout
        style={{ padding: 8 }}
        isVertical={true}
      >
        <LayoutTitle>*미지급내역</LayoutTitle>
        <FlexLayout>
          <GridLayout
            header={[
              {
                id: 'cltnm',
                text: '거래처명',
                width: 25,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijdate',
                text: '발생일자',
                width: 10,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.mijdate}
                  />
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 8,
              },
              {
                id: 'remark',
                text: '적요',
                width: 27,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '발생금액',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.pmijamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.mijamt}
                  />
                ),
              },
              {
                id: 'iamt',
                text: '지급액',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.piamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.iamt}
                  />
                ),
              },
              {
                id: 'presuamt',
                text: '미지급금',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.presuamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.mijamt}
                  />
                ),
              },
            ]}
            data={scope.state.popupData}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />
        </FlexLayout>

        <FlexLayout isVertical={true}>
          <LayoutTitle>*상세내역</LayoutTitle>
          <GridLayout
            header={[
              {
                id: 'cltnm',
                text: '거래처명',
                width: 21,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijdate',
                text: '발생일자',
                width: 9,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.mijdate}
                  />
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 6,
              },
              {
                id: 'snddate',
                text: '지급일자',
                width: 10,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.snddate}
                  />
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 22,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '발생금액',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.pmijamt_tot2}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.mijamt}
                  />
                ),
              },
              {
                id: 'rcvamt',
                text: '지급액',
                width: 10,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.prcvamt_tot2}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.rcvamt}
                  />
                ),
              },
              {
                id: 'resuamt',
                text: '미지급금',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.presuamt_tot2}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.resuamt}
                  />
                ),
              },
            ]}
            data={scope.state.popupData2}
            infinityHandler={scope.infinity3}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button
              onClick={() => scope.modalExcelEvent()}
              style={{ width: 100 }}
            >
              EXCEL 자료
            </Button>
            <Button
              onClick={() => scope.textModal(false)}
              style={{ width: 100 }}
            >
              확인
            </Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
  );
};
