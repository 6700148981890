import { TableLayoutHeader } from '../../../../../constants';
import { BuyModel } from '../models';
import {
  ItemChange,
  BuyRegister,
} from '../BuyRegister';
import { TextBox } from '../../../../../components/forms/TextBox';
import { FormatNumberTextBox } from '../../../../../components/forms/FormatNumberTextBox';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

// 매입상세
const Buy: TableLayoutHeader<BuyModel, BuyRegister>[] = [
  {
    id: 'seq',
    text: '순서',
    width: 5,
  },
  {
    id: 'remark',
    text: '품명',
    width: 19,
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
        onEnter={(value) => scope?.onItemChange(value, ItemChange.pname, 'dw_3_itemchanged')}
      />
    ),
  },
  {
    id: 'size',
    text: '규격',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.size}
        onChange={(value) => rowUpdate({ size: value })}
      />
    ),
  },
  {
    id: 'unit',
    text: '단위',
    width: 6,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.unit}
        textAlign="center"
        onChange={(value) => rowUpdate({ unit: value })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 5,
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.qty}
        onChange={(value) => scope?.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 9,
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 9,
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
      />
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 9,
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.tamt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, tamt: value }, rowUpdate, 'tamt')}
      />
    ),
  },
  {
    id: 'mijamt',
    text: '합계금액',
    width: 9,
    trail: (data) => {
      const sumMijamt = Sum.all(data, 'mijamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMijamt, true)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'bigo',
    text: '비고',
    width: 19,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.bigo}
        onChange={(value) => rowUpdate({ bigo: value })}
      />
    ),
  },
];

export default Buy;
