import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import printJS from 'print-js';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
  TableLayoutHeader,
} from '../../../../constants';
import {
  ExpenseModel,
  DealModel,
  TexPopupModel,
} from './models';
import { TabModel } from '../../../../models/component';
import {
  TableLayout,
  GridLayout,
} from '../../../../components';
import { SalesTemplate } from './Sales.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderDeal,
  TabHeaderExpense,
} from './tabs';
import {
  Confirm, ConfirmDelete, ConfirmFail, ConfirmSuccess, ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { Format } from '../../../../utils/string';
import MD5 from '../../../../utils/string/MD5';
import { DateStabilizer } from '../../../../utils/time';

export enum BtnGubun {
  b_gubun,
  b_perid,
  b_yyyymm,
  b_projno,
  b_jirogubun,
  b_bigo2,
  b_misdate,
  b_recivstatus,
}

export enum ItemChange {
  misflag,
  cancleflag,
  misdate,
  taxgubun,
  taxcls,
  gubun,
  jirodate,
  pname,
  qty,
  uamt,
  samt,
  actcd,
  cltcd,
}

export enum SalesTabId {
  Expense,
  Deal
}

export const SalesTabTitles = [
  '매출상세',
  '거래명세표',
];

export const SalesTabModels = [
  ExpenseModel,
  DealModel,
];

export const SalesTabDataStateNames = [
  'expenses',
  'deals',
];

export const SalesTabUpdatesStateNames = [
  'expensesUpdates',
  'dealsUpdates',
];

export const SalesTabFocusedStateNames = [
  'expensesFocused',
  'dealsFocused',
];

export interface SalesState {
  // 매출상세
  expenses: Array<ExpenseModel>;
  expensesUpdates?: Array<ExpenseModel>;
  expensesFocused: ExpenseModel;
  changeValue?: string;

  // 거래명세표
  deals: Array<DealModel>;
  dealsUpdates?: Array<DealModel>;
  dealsFocused: DealModel
  dealImg?: string;
  dealsModal: boolean;

  // 세금계산서발행 팝업
  texBillModal: boolean;
  texBillImg?: string;
  texModalCheck?: boolean;

  // 수정세금계산서 팝업
  texModal: boolean;
  texPopup: TexPopupModel;
  texPopup2: Array<TexPopupModel>;
  taxPopupDetailData: Array<TexPopupModel>;
  textPopupFocused?: TexPopupModel;
  n_datetime: string;

  // 청구서발행 팝업
  billModal: boolean;
  printTypeModal: boolean;
  printTypeModal2: boolean;
  printTypeDealModal: boolean;
  printTypeDealModal2: boolean;
  billImg?: string;

  // 공사불러오기 팝업
  constructionModal: boolean;
  constructionModal2: boolean;
  constructionModal3: boolean;
  constructionSearch: string;
  constructionSearch2: string;
  constructionPopup: Array<TexPopupModel>;
  constructionPopup2: Array<TexPopupModel>;
  constructionFocused?: TexPopupModel;
  constructionFocused2?: TexPopupModel;
  constructionDate: string;
  constructionDate2: string;
  popupStdate: string;
  pushTotalCheck: boolean;
  pushTotalCheck2: boolean;

  // data
  focusedTab?: TabModel;
  salesList: Array<ExpenseModel>;
  data: ExpenseModel;
  focused?: ExpenseModel;
  focusIndex: number;
  actChk?: string;
  cltChk?: string;
  subNew: boolean;
  newChk?: string;
  date?: string;
  misdateChk?: string;
  misdate?: string;
  construction: string;
  stateNew?: string;
  popupData?: string;
  popupStamp?: string;
  popupPrintType?: string;
  popupPrintType2?: string;

  // 검색조건
  stdate: string;
  enddate: string;
  searchQuery: string;
  billgubun?: string;
  taxgubuns?: Array<any>;
  gubuns?: Array<any>;
  searchGubun?: string;
  gubun?: Array<any>;

  textDetailModal: boolean;
  perid: string;
  userId: string;
  custcd: string;

  // trail
  totbillkind_tot: string;
  fcnt_tot: string;
  bcnt_tot: string;
  total: string;

  da006_cnt: number; // 수리공사 갯수
  e471_cnt: number; // 설치공사 갯수

  beforeCltcd: string;
}

const recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'B ', remark: '발송전' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'FF ', remark: '국세청직접발행' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'R ', remark: '열람' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
];

/**
 * 컨트롤러
 * @window w_tb_da023
 * @category 매출등록
 */
export class Sales extends PageComponent<PageProps, SalesState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  updatedRows3?: Array<any>;

  textPopupUpdatedRows?: Array<any>;

  tabRows?: Array<any>;

  tabRows2?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  table2: React.RefObject<TableLayout> = React.createRef();

  table3: React.RefObject<TableLayout> = React.createRef();

  table4: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinity4?: InfinityRetrieve;

  amt?: Array<any>;

  samt?: number;

  addamt?: number;

  misamt?: number;

  modalChk?: string;

  expense?: number;

  deal?: number;

  subData?: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const id = SalesTabId;
    const titles = SalesTabTitles;

    this.tabs = [
      new TabModel(id.Expense.toString(), MdReorder, titles[id.Expense]),
      new TabModel(id.Deal.toString(), MdPublish, titles[id.Deal]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: pageParams.misdate || `${year}${month}${date}`,
      enddate: pageParams.misdate || `${year}${month}${date}`,
      date: `${year}${month}${date}`,
      popupStdate: '19700101',
      constructionDate: `${year}${month}${date}`,
      constructionDate2: `${year}${month}${date}`,
      searchGubun: '%',
      searchQuery: pageParams.projno || '',
      billgubun: '%',
      data: [],
      focusIndex: 0,
      salesList: [],
      texPopup2: [],
      taxPopupDetailData: [],
      n_datetime: '00000000',
      actChk: '0',
      cltChk: '0',
      newChk: '0',
      misdateChk: '0',
      construction: '1',
      stateNew: '0',
      perid: '',
      userId: '',
      custcd: '',
      popupData: '1',
      popupStamp: '1',
      popupPrintType: '1',
      popupPrintType2: '1',
      texModalCheck: 'false',

      // trail
      totbillkind_tot: '0',
      fcnt_tot: '0',
      bcnt_tot: '0',
      total: '0',

      da006_cnt: 0,
      e471_cnt: 0,

      beforeCltcd: '',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 매출상세
      TabHeaderExpense,
      // 거래명세표
      TabHeaderDeal,
    ];
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams.projno || this.state.searchQuery,
        stdate: pageParams.misdate || this.state.stdate,
        enddate: pageParams.misdate || this.state.enddate,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.open();
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    this.setState({
      userId: user.userid,
      custcd: user.custcd,
    });
    // 상단의 구분
    const data2 = await api.dropdown('wf_dd_da020_all');
    if (!data2) return;
    this.setState({ gubuns: data2.items });

    // 과세구분
    const data3 = await api.dropdown('wf_dd_ca510_006_01');
    if (!data3) return;
    this.setState({ taxgubuns: data3.items });

    // 매출구분
    const data4 = await api.dropdown('wf_da020_code');
    if (!data4) return;
    this.setState({ gubun: data4.items });
    this.onTabChange(this.tabs[SalesTabId.Expense]);
    this.onRetrieveEvent();
  }

  @action
  async open() {
    const { actionStore: api } = this.props;
    await api.fxExec('open',
      {
      });
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, async () => {
      await this.table.current?.update(true);
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    this.setState({ perid: user.userid });
    const i = this.tabIndex;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        billgubun: this.state.billgubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        if (items) {
          this.setState({
            salesList: [
              ...this.state.salesList,
              ...items.map((x: any) => new ExpenseModel(x), false),
            ],
            stateNew: '0',
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          stateNew: '0',
          salesList: [],
          data: new ExpenseModel([], false),
          [SalesTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.salesList && this.state.salesList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    // @ts-ignore
    this.setState({
      salesList: [],
      stateNew: '0',
      data: new ExpenseModel([], false),
      [SalesTabDataStateNames[i]]: [],
      pushTotalCheck: false,
      pushTotalCheck2: false,
      popupData: '1',
      popupStamp: '1',
      da006_cnt: 0,
      e471_cnt: 0,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['misdate', 'misnum'],
        [lastSelected.misdate, lastSelected.misnum], type, true) || 0;
      this.state.salesList && this.state.salesList.length > index && this.grid.current?.setFocus(index);
      await this.SS({
        totbillkind_tot: this.infinity?.box?.totbillkind_tot || '0',
        fcnt_tot: this.infinity?.box?.fcnt_tot || '0',
        bcnt_tot: this.infinity?.box?.bcnt_tot || '0',
        total: this.infinity?.box?.total || '0',
        da006_cnt: parseInt(this.infinity?.box?.da006_cnt || '0', 10) || 0,
        e471_cnt: parseInt(this.infinity?.box?.e471_cnt || '0', 10) || 0,
        misdateChk: '0',
        newChk: '0',
      });
    });
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      salesList: [
        new ExpenseModel(data),
        ...this.state.salesList,
      ],
      data: new ExpenseModel(data, true),
      misdateChk: '1',
      stateNew: '1',
      expenses: [],
      deals: [],
    }, async () => {
      await this.table.current?.update(true);
      this.grid.current?.setFocus(0);
      await this.onItemChange(this.state.enddate, ItemChange.misdate, 'dw_2_itemchanged');
    });
  }

  // seq컬럼 순서정렬과 하단탭2개 동일값 지정을 위한 함수
  @action
  async seq() {
    this.tabRows = [];
    this.samt = 0;
    this.addamt = 0;
    this.misamt = 0;
    this.expense = 0;
    this.state.expenses?.forEach((x: any, num) => {
      // @ts-ignore
      this.expense += parseInt(x.samt, 10);
      this.tabRows?.push({
        ...x,
        seq: `00${num + 1}`,
        amt: x.misamt,
        tamt: x.addamt,
        new: '1',
      });

      // @ts-ignore
      this.samt += parseInt(x.samt, 10);
      // @ts-ignore
      this.addamt += parseInt(x.addamt, 10);
      // @ts-ignore
      this.misamt += parseInt(x.misamt, 10);
    });

    this.tabRows2 = [];
    this.deal = 0;
    this.state.deals?.forEach((x: any, num) => {
      // @ts-ignore
      this.deal += parseInt(x.samt, 10);
      if (this.state.deals.length === 1) {
        this.tabRows2?.push({
          ...x,
          seq: `00${num + 1}`,
          amt: x.misamt,
          new: '1',
          qty: '1',
          uamt: this.state.expenses[0]?.uamt,
          tamt: this.state.expenses[0]?.addamt,
          samt: this.state.expenses[0]?.samt,
          misamt: this.state.expenses[0]?.misamt,
          pname: this.state.expenses[0]?.pname,
        });
      } else {
        this.tabRows2?.push({
          ...x,
          seq: `00${num + 1}`,
          amt: x.misamt,
          new: '1',
        });
      }
    });
    // totlal 개수 변경
    const total = this.state.data;
    total.misdate = this.state.misdateChk === '1' ? String(this.state.misdate) : this.state.data.misdate;
    total.total3 = String(this.tabRows.length);
    total.total4 = String(this.tabRows2.length);

    this.setState({
      expenses: this.tabRows,
      deals: this.tabRows2,
      data: total,
    });
  }

  // 부가세 여부에따른 계산
  @action
  async taxAutoCalc() {
    const i = this.tabIndex;
    const checkData: any = [];
    // @ts-ignore
    this.state[SalesTabDataStateNames[i]]?.forEach((x: any) => {
      if (parseInt(x.uamt, 10) > 0) {
        // 부가세 포함
        if (this.state.data.taxcls === '1') {
          checkData.push({
            ...x,
            samt: Math.round((x.qty * x.uamt) / 1.1).toString(),
            addamt: Math.round((x.qty * x.uamt) - ((x.qty * x.uamt) / 1.1)).toString(),
            misamt: Math.round(((x.qty * x.uamt) / 1.1) + ((x.qty * x.uamt) - ((x.qty * x.uamt) / 1.1))).toString(),
          });
        } else {
          checkData.push({
            ...x,
            samt: Math.round(x.qty * x.uamt).toString(),
            addamt: Math.round((x.qty * x.uamt) / 10).toString(),
            misamt: Math.round((x.qty * x.uamt) + ((x.qty * x.uamt) / 10)).toString(),
          });
        }
      } else if (parseInt(x.uamt, 10) === 0) {
        if (this.state.data.taxcls === '1') {
          if (x.qty === '0' && x.uamt === '0') {
            checkData.push({
              ...x,
              addamt: Math.round(parseInt(x.samt, 10) - (parseInt(x.samt, 10) / 1.1)).toString(),
              samt: Math.round(parseInt(x.samt, 10) - (parseInt(x.samt, 10) - (parseInt(x.samt, 10) / 1.1))).toString(),
              misamt: Math.round((parseInt(x.samt, 10) - (parseInt(x.samt, 10) / 1.1))
                + (parseInt(x.samt, 10) - (parseInt(x.samt, 10) - (parseInt(x.samt, 10) / 1.1)))).toString(),
            });
          } else {
            checkData.push({
              ...x,
              addamt: Math.round((x.samt) - (x.samt / 1.1)).toString(),
              misamt: Math.round(parseInt(x.samt, 10) + parseInt(x.addamt, 10)).toString(),
            });
          }
        } else {
          checkData.push({
            ...x,
            samt: Math.round(parseInt(x.samt, 10) + parseInt(x.addamt, 10)).toString(),
            addamt: Math.round((parseInt(x.samt, 10) + parseInt(x.addamt, 10)) / 10).toString(),
            misamt: Math.round((parseInt(x.samt, 10) + parseInt(x.addamt, 10)) + ((parseInt(x.samt, 10) + parseInt(x.addamt, 10)) / 10)).toString(),
          });
        }
      }
    });

    // @ts-ignore
    this.setState({
      [SalesTabDataStateNames[i]]: checkData,
    }, async () => {
      await this.table.current?.update();
    });
  }

  // 하단 탭 계산
  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const samt = Format.toNumber(item.samt);
    const addamt = Format.toNumber(item.addamt);
    const uamt = Format.toNumber(item.uamt);

    let amount = 0;
    let sum = 0;
    let tax = 0;
    if (name === 'qty' || name === 'uamt') {
      if (this.state.data.taxgubun === '03') {
        amount = Math.round(qty * uamt);
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.billgubun === '0') {
        amount = Math.round(qty * uamt);
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.taxcls === '1') {
        amount = Math.round((qty * uamt) / 1.1);
        tax = Math.round((qty * uamt) - ((qty * uamt) / 1.1));
        sum = amount + tax;
      } else {
        amount = Math.round(qty * uamt);
        tax = Math.round((qty * uamt) / 10);
        sum = amount + tax;
      }
    } else if (name === 'samt') {
      if (this.state.data.taxgubun === '03') {
        amount = samt;
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.billgubun === '0') {
        amount = samt;
        tax = addamt;
        sum = amount + tax;
      } else if (this.state.data.taxcls === '1') {
        if (qty === 0 && uamt === 0) {
          tax = Math.round(samt - (samt / 1.1));
          amount = Math.round(samt - tax);
          sum = amount + tax;
        } else {
          tax = Math.round((samt) - (samt / 1.1));
          amount = samt - tax;
          sum = amount + tax;
        }
      } else {
        amount = samt;
        tax = Math.round(amount / 10);
        sum = amount + tax;
      }
    } else {
      amount = samt;
      tax = addamt;
      sum = amount + tax;
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: amount.toString(),
      addamt: tax.toString(),
      tamt: tax.toString(),
      misamt: sum.toString(),
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (this.state.focused?.recivstatus.trim() !== '' && this.state.focused?.recivstatus.trim() !== 'B' && !this.state.data.isNew) {
      // eslint-disable-next-line max-len
      ConfirmWarning.show('확인', `${recivstatuss.filter((y) => y.value === this.state.focused?.recivstatus)[0]?.remark}상태입니다. 변경할수 없습니다.`);
      return;
    }

    if (this.expense !== this.deal && this.state.expenses.length > 0 && this.state.deals.length > 1) {
      ConfirmWarning.show('확인', '매출상세랑 거래명세표 금액이 맞지않습니다.');
      return;
    }

    this.state.deals.forEach((x) => {
      // eslint-disable-next-line no-param-reassign
      x.amt = String(Number(x.amt).toFixed());
      // eslint-disable-next-line no-param-reassign
      x.misamt = String(Number(x.misamt).toFixed());
      // eslint-disable-next-line no-param-reassign
      x.tamt = String(Number(x.tamt).toFixed());
    });

    const data = await api.save({
      ...this.state.data,
      new: this.state.stateNew,
      amt: String(this.samt),
      addamt: String(this.addamt),
      misamt: String(this.misamt),
      items: !this.tabRows ? this.state.expenses : this.tabRows,
      items2: !this.tabRows2 ? this.state.deals : this.tabRows2,
    }, this.state.data.isNew);
    this.updatedRows = [];
    await this.table.current?.update(true);
    this.setState({
      misdateChk: '0',
      stateNew: '0',
    });

    if (data !== false) this.onRetrieveEvent();
  }

  @action
  async onDealDetailSave() {
    const { actionStore: api } = this.props;

    const data = await api.save({
      ...this.state.data,
      new: this.state.stateNew,
      amt: String(this.samt),
      addamt: String(this.addamt),
      misamt: String(this.misamt),
      items: !this.tabRows ? this.state.expenses : this.tabRows,
      items2: !this.tabRows2 ? this.state.deals : this.tabRows2,
    }, this.state.data.isNew);
    this.updatedRows = [];
    await this.table.current?.update(true);
    this.setState({
      misdateChk: '0',
      stateNew: '0',
    });

    if (data !== false) await this.onRetrieveEvent();
  }

  @action
  async onDetailSave2() {
    const { actionStore: api } = this.props;

    const data = await api.fxSave(
      'wb_da024_pcode_save',
      {
        cltcd: this.state.data.cltcd,
        misdate: this.state.data.misdate,
        misnum: this.state.data.misnum,
        items: !this.tabRows2 ? this.state.deals : this.tabRows2,
      }, this.state.data.isNew,
    );
    this.updatedRows = [];
    await this.table.current?.update(true);
    this.setState({
      misdateChk: '0',
      stateNew: '0',
    });

    if (data !== false) await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data?.rcvflag === '1') {
      ConfirmWarning.show('경고', '해당 매출건은 이미 입금되었습니다.!');
      return;
    }

    if (this.state.data?.recivstatus.trim() !== '' && this.state.data?.recivstatus.trim() !== 'B') {
      // eslint-disable-next-line max-len
      ConfirmWarning.show('확인', `${recivstatuss.filter((y) => y.value === this.state.data?.recivstatus)[0]?.remark}상태입니다. 삭제할수 없습니다.`);
      return;
    }

    const text = `매출일자: ${this.state.data?.misdate}, 매출번호: ${this.state.data?.misnum}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '하단 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.data) && this.onRetrieveEvent(RetrieveFocusType.END);
      this.setState({ focused: undefined });
    }
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    const { actionStore: api } = this.props;
    if (this.state.data.new === '1') {
      return;
    }

    this.setState({
      focused: item,
      focusIndex: index,
      newChk: '0',
    }, async () => {
      const data = await api.fxExec(
        'dw_1_RowFocuschanged',
        {
          cltcd: item?.cltcd,
          misdate: item?.misdate,
          misnum: item?.misnum,
        },
      );

      if (data?.items) {
        this.setState({
          expenses: data.items.map((x: any) => new ExpenseModel(x)),
          data: new ExpenseModel(data, false),
        });
      } else {
        this.setState({
          expenses: [],
          data: new ExpenseModel(data, false),
        });
      }

      if (data?.items2) {
        this.setState({
          deals: data.items2.map((x: any) => new DealModel(x)),
          data: new ExpenseModel(data, false),
        });
      } else {
        this.setState({
          deals: [],
          data: new ExpenseModel(data, false),
        });
      }

      await this.seq();
      await this.table.current?.update(true);

      // @ts-ignore
      if (this.state[SalesTabDataStateNames[this.tabIndex]].length) {
        // @ts-ignore
        await this.onRowFocusEvent2(this.state[SalesTabDataStateNames[this.tabIndex]][0]);
      }
    });
  }

  @action
  async tapUpdatedRows(rows: any, updatedRows: any) {
    const i = this.tabIndex;
    // @ts-ignore
    this.setState({
      // @ts-ignore
      [SalesTabDataStateNames[i]]: rows,
      // @ts-ignore
      [SalesTabUpdatesStateNames[i]]: updatedRows,
    }, async () => {
      await this.seq();
    });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ constructionPopup: rows });
  }

  @action
  onTexPopupUpdatedRows(rows: any, updatedRows: any) {
    this.textPopupUpdatedRows = updatedRows;
    this.setState({ taxPopupDetailData: rows });
  }

  @action
  async subNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (this.state.data.actcd.trim() === '' && this.state.data.cltcd.trim() === '') {
      ConfirmWarning.show('경고', '현장(거래처)명을 확인해주세요');
      return;
    }

    const data = await api.fxExec(
      `tab_${i + 1}_dw_3_new`,
      {
        cltcd: this.state.data?.cltcd,
        misdate: this.state.data?.misdate || this.state.date,
        misnum: this.state.data?.misnum,
        gubun: this.state.data?.gubun,
        actcd: this.state.data?.actcd,
        taxcls: this.state.data?.taxcls,
        yyyymm: this.state.data?.yyyymm,
        // @ts-ignore
        row: this.state[SalesTabDataStateNames[i]]?.length + 1,
      },
    );

    const taxcls = this.state.data;
    taxcls.taxcls = data?.taxcls;

    // dw_3_new 시 부가세 포함이면
    if (data?.taxcls === '1') {
      // 부가세 = 합계금액 % 11
      data.addamt = String((Number(data?.samt) / 11).toFixed());
      // 금액 = 합계금액 % 1.1
      data.samt = String((Number(data?.samt) / 1.1).toFixed());
      // 합계금액 = 부가세 + 금액
      data.misamt = String(Number(data.samt) + Number(data.addamt));
    }

    if (!this.state.expenses.length && !this.state.deals.length) {
      this.setState({
        expenses: [...this.state.expenses, new ExpenseModel(data, true)],
        deals: [...this.state.deals, new DealModel(data, true)],
        data: taxcls,
      });
    } else if (this.tabIndex === 0) {
      this.setState({
        expenses: [...this.state.expenses, new ExpenseModel(data, true)],
        data: taxcls,
      });
    } else {
      this.setState({
        deals: [...this.state.deals, new DealModel(data, true)],
        data: taxcls,
      });
    }
    await this.table.current?.update();
    // @ts-ignore
    this.table.current?.setFocus(this.state[SalesTabDataStateNames[i]].length - 1, 0);
    // @ts-ignore
    this.table.current?.forceModifiedRow(this.state[SalesTabDataStateNames[i]].length - 1);
  }

  @action
  async subDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    // eslint-disable-next-line max-len
    const text = `순번: ${this.state[SalesTabFocusedStateNames[i]]?.seq}, 품명: ${this.state[SalesTabFocusedStateNames[i]]?.pname}`;
    await api.fxDelete(
      `tab_${i + 1}_dw_3_delete`,
      text,
      {
        cltnm: this.state.data?.cltnm,
        billkind: this.state.data?.billkind,
        recivstatus: this.state.data?.recivstatus,
        // @ts-ignore
        ...this.state[SalesTabFocusedStateNames[i]],
      },
    );

    const checkData: any = [];
    // @ts-ignore
    this.state[SalesTabDataStateNames[i]]?.forEach((x: any) => {
      // @ts-ignore
      if (x.seq !== this.state[SalesTabFocusedStateNames[i]]?.seq) {
        checkData.push({
          ...x,
        });
      }
    });

    // @ts-ignore
    this.setState({ [SalesTabDataStateNames[i]]: checkData });
    await this.table.current?.update(true);
    this.table.current?.setFocus(0, 1);
  }

  @action
  async onRowFocusEvent2(item: any) {
    const i = this.tabIndex;
    // @ts-ignore
    this.setState({ [SalesTabFocusedStateNames[i]]: item });
  }

  @action
  async onButtonclick(datas: any, name: any) {
    const { actionStore: api } = this.props;

    let warning: boolean;
    if (name === 7 && (datas === 'FF' || 'FF ')) {
      warning = await Confirm.show('확인', '국세청 직접발행처리 하시겠습니까?', ConfirmType.QUESTION);
      if (warning === false) return;
    } else if (name === 7 && datas !== 'FF' && datas !== 'FF ') {
      warning = await Confirm.show('확인', '직접발행처리내역을 취소하시겠습니까?', ConfirmType.QUESTION);
      if (warning === false) return;
    }

    if (this.state.focused?.recivstatus !== '' && this.state.focused?.recivstatus !== 'B' && name === 6) {
      this.setState({ newChk: '0' });
      // eslint-disable-next-line max-len
      ConfirmWarning.show('확인', `${recivstatuss.filter((y) => y.value === this.state.focused?.recivstatus)[0]?.remark}상태입니다. 변경할수 없습니다.`);
      return;
    }

    let btnData = {};

    switch (BtnGubun[name]) {
      case 'b_gubun':
        btnData = { gubun: datas };
        break;

      case 'b_perid':
        btnData = {
          perid: datas,
          pernm: this.state.data?.pernm,
          divicd: this.state.data?.divicd,
          divinm: this.state.data?.divinm,
        };
        break;

      case 'b_yyyymm':
        btnData = {
          yyyymm: datas,
        };
        break;

      case 'b_projno':
        btnData = {
          projno: datas,
          projnm: this.state.data?.projnm,
          proactcd: this.state.data?.proactcd,
        };
        break;

      case 'b_jirogubun':
        btnData = {
          jirogubun: datas,
        };
        break;

      case 'b_misdate':
        btnData = {
          spnum: this.state.data?.tax_spnum,
          spdate: this.state.data?.tax_spdate,
          billkind: this.state.data?.billkind,
          newmisdate: this.state.data?.misdate,
          actcd: this.state.data?.actcd,
          scstdate: this.state.data?.scstdate,
          scmiddate: this.state.data?.scmiddate,
          scenddate: this.state.data?.scenddate,
        };
        break;

      case 'b_recivstatus':
        btnData = {
          recivgubun: datas !== 'FF' && datas !== 'FF ' ? '1' : '2',
          billkind: this.state.data?.billkind,
        };
        break;

      default:
        btnData = {
          bigo2: datas,
        };
        break;
    }
    this.setState({ newChk: '0' });
    const data = await api.fxExec('dw_2_buttonclicked',
      {
        new: '0',
        itemname: BtnGubun[name],
        data: datas,
        cltcd: this.state.data?.cltcd,
        misdate: BtnGubun[name] === 'b_misdate' ? this.state.focused?.misdate : this.state.data?.misdate,
        misnum: BtnGubun[name] === 'b_misdate' ? this.state.focused?.misnum : this.state.data?.misnum,
        ...btnData,
      });

    const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.misdate);
    data && this.setState({
      data: new ExpenseModel({
        ...this.state.data,
        ...data,
      }, true),
      stdate: futureSearchRange.stdate,
      enddate: futureSearchRange.enddate,
    }, async () => {
      ConfirmWarning.show('확인', '수정되었습니다.');
      await this.onRetrieveEvent();
    });
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { modalStore } = this.props;
    const json = JSON.parse(JSON.parse(message));

    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      if (json?.message.indexOf('공사') !== -1) {
        this.constructionModal(true);
        this.modalChk = '1';
      } else {
        const cltResponse = await modalStore.openAddClt(this.state.data.cltcd, '2');
        this.setState({
          data: new ExpenseModel({
            ...this.state.data,
            cltcd: cltResponse.cltcd,
            cltnm: cltResponse.cltnm,
          }),
        });
      }
    }
  }

  @action
  async onItemChange(datas: any, name: any, fntName: any) {
    const { actionStore: api, modalStore } = this.props;
    const i = this.tabIndex;

    // eslint-disable-next-line no-param-reassign
    datas = datas.replace(',', '');

    let itemData = {};

    switch (ItemChange[name]) {
      case 'misflag':
        break;

      case 'cancleflag':
        break;

      case 'misdate':
        itemData = {
          row3: String(this.state.expenses?.length),
          row4: String(this.state.deals?.length),
        };
        break;

      case 'taxgubun':
        itemData = {
          taxcls: datas,
          billgubun: this.state.data?.billgubun,
        };
        break;

      case 'taxcls':
        itemData = {
          taxcls: datas,
          billgubun: this.state.data?.billgubun,
          taxgubun: this.state.data?.taxgubun,
        };
        break;

      case 'gubun':
        itemData = {
          taxcls: datas,
          billgubun: this.state.data?.billgubun,
          taxgubun: this.state.data?.taxgubun,
          ungijun: this.state.data?.ungijun,
          jirochk: this.state.data?.jirochk,
          jirodate: this.state.data?.jirodate,
          jirogubun: this.state.data?.jirogubun,
        };
        break;

      case 'jirodate':
        itemData = {
          jirogubun: this.state.data.jirogubun,
        };
        break;

      case 'pname':
        itemData = {
          itemcnt: datas,
          remark: this.state.data.bigo2,
        };
        break;

      case 'qty':
        itemData = {
          taxgubun: this.state.data.taxgubun,
          billgubun: this.state.data.billgubun,
          taxcls: this.state.data.taxcls,
          // @ts-ignore
          samt: this.state[SalesTabFocusedStateNames[i]]?.samt,
          // @ts-ignore
          uamt: this.state[SalesTabFocusedStateNames[i]]?.uamt,
          qty: datas,
        };
        break;

      case 'uamt':
        itemData = {
          taxgubun: this.state.data.taxgubun,
          billgubun: this.state.data.billgubun,
          taxcls: this.state.data.taxcls,
          // @ts-ignore
          samt: this.state[SalesTabFocusedStateNames[i]]?.samt,
          uamt: datas,
          // @ts-ignore
          qty: this.state[SalesTabFocusedStateNames[i]]?.qty,
        };
        break;

      case 'samt':
        itemData = {
          taxgubun: this.state.data.taxgubun,
          billgubun: this.state.data.billgubun,
          taxcls: this.state.data.taxcls,
        };
        break;

      case 'actcd':
        itemData = {
          gubun: this.state.data?.gubun,
          row3: String(this.state.expenses?.length),
          row4: String(this.state.deals?.length),
        };
        break;

      case 'cltcd':
        itemData = {
          row3: String(this.state.expenses?.length),
          row4: String(this.state.deals?.length),
        };
        break;
      case 'cltcd_new':
        itemData = {
          row3: String(this.state.expenses?.length),
          row4: String(this.state.deals?.length),
        };
        break;

      default:
        itemData = {
          jirogubun: datas,
        };
        break;
    }

    let data = await api.fxExec(
      fntName,
      {
        // @ts-ignore
        new: this.state[SalesTabFocusedStateNames[i]]?.isNew === true ? '1' : '0',
        itemname: ItemChange[name],
        data: datas || '',
        cltcd: this.state.data?.cltcd,
        misdate: this.state.data?.misdate,
        misnum: this.state.data?.misnum,
        ...itemData,
      },
    );
    await this.dataChange(name, datas, data);

    /**
     * 기존 배열에 변경된 컬럼만 수정하기
     * tabData(기존 state의 배열 - 하단 매출상세, 거래명세표)의 값 중 data.items에 들어있는 samt와 addamt만 변경되어야 함
     * data.items는 해당 매출 상세의 순서대로 들어오기 때문에 for문 사용이 가능합니다.
     */

    if (ItemChange[name] !== 'cltcd' && data?.items) {
      // @ts-ignore
      const tabData = this.state[SalesTabDataStateNames[i]]; // 하단 매출상세, 거래명세표 data 배열
      // @ts-ignore
      const newTabData: SalesTabModels[i] = []; // 교체할 새로운 Object (불변성 때문에)

      tabData.forEach((x: any, j: number) => newTabData.push(new SalesTabModels[i]({
        ...x,
        ...data.items[j],
      })));

      // @ts-ignore
      await this.SS({
        // state에 새로운 배열인 newTabData로 교체 해주면 불변성을 지키면서 화면 다시 렌더링이 가능합니다
        [SalesTabDataStateNames[i]]: newTabData,
      });
    }

    // 거래처 빠른 조회시 거래처가 있는경우
    // 없는 경우에는 onMessageEvent로 MQTT 이벤트를 받아 빠른 거래처 추가 작업을 하게 됨
    if (ItemChange[name] === 'cltcd' && data.titlebox === '' && this.state.cltChk === '1') {
      data = await modalStore.openCltSelector({
        as_nm: this.state.data?.cltcd,
      }, true);
      await this.SS({ cltChk: '0' });
    }

    if (ItemChange[name] === 'actcd' && this.state.cltChk === '1') {
      data = await modalStore.openCommonSelector('TB_E601_1', {
        as_nm: this.state.data?.actcd,
      }, true);
      await this.SS({ actChk: '0' });
    }
  }

  @action
  async dataChange(name: any, datas: any, data: any) {
    const i = this.tabIndex;

    switch (ItemChange[name]) {
      case 'gubun':
        this.state.salesList.forEach((x: any, j: number) => {
          if (x?.misnum === this.state.focused?.misnum) {
            this.state.salesList[j].misnum = datas;
          }
        });
        break;

      case 'pname':
        // @ts-ignore
        this.state[SalesTabDataStateNames[i]].forEach((x: any, j: number) => {
          // @ts-ignore
          this.state[SalesTabDataStateNames[i]][j].remark = data.remark;
        });
        break;

      case 'qty':
      case 'uamt':
      case 'samt':
        // @ts-ignore
        // eslint-disable-next-line no-case-declarations
        const newTabData: SalesTabModels[i] = [];
        // @ts-ignore
        this.state[SalesTabDataStateNames[i]].forEach((x: any) => {
          // @ts-ignore
          // eslint-disable-next-line radix
          if (x?.seq === this.state[SalesTabFocusedStateNames[i]]?.seq) {
            // @ts-ignore
            newTabData.push(new SalesTabModels[i]({
              ...x,
              ...data,
            }));
            // @ts-ignore
          } else if (this.state[SalesTabFocusedStateNames[i]].isNew === true && !x.seq) {
            // @ts-ignore
            newTabData.push(new SalesTabModels[i]({
              ...x,
              ...data,
            }));
          } else {
            // @ts-ignore
            newTabData.push(new SalesTabModels[i]({
              ...x,
            }));
          }
        });
        this.subData = newTabData;
        // @ts-ignore
        this.setState({
          [SalesTabDataStateNames[i]]: newTabData,
        }, async () => {
          await this.table.current?.update(false);
        });
        break;

      case 'misdate':
        data && this.setState({
          data: new ExpenseModel({
            ...this.state.data,
            ...data,
          }, true),
          misdate: datas,
        });
        break;

      case 'taxcls':
        // eslint-disable-next-line no-case-declarations
        const updateData: any[] = [];
        // @ts-ignore
        this.state[SalesTabDataStateNames[i]]?.forEach((x: any, i) => {
          updateData.push({
            ...x,
            addamt: data.items[i]?.addamt,
            samt: data.items[i]?.samt,
            misamt: data.items[i]?.misamt,
          });
        });
        // @ts-ignore
        data && this.setState({
          [SalesTabDataStateNames[i]]: updateData,
          data: new ExpenseModel({
            ...this.state.data,
            ...data,
          }, true),
          misdate: datas,
        });
        break;

      default:
        data && this.setState({
          data: new ExpenseModel({
            ...this.state.data,
            ...data,
          }, true),
        });
        break;
    }
    await this.table.current?.update(true);
    // @ts-ignore
    await this.tapUpdatedRows(this.state[SalesTabDataStateNames[i]], data);
  }

  @action
  printTypeDealModal(isOpen: boolean) {
    this.setState({ printTypeDealModal: isOpen });
  }

  @action
  dealPrintModal(isOpen: boolean) {
    this.setState({
      dealsModal: isOpen,
      printTypeDealModal: false,
    });
  }

  @action
  async dealPrintRetrieve() {
    const { actionStore: api } = this.props;
    this.dealPrintModal(false);

    if (this.state.popupPrintType2 === '1') {
      const file = await api.fxFileRaw(
        'wb_accountprt',
        {
          stamp: this.state.popupStamp,
          dateflag: this.state.popupData,
          cltcd: this.state.data?.cltcd,
          misdate: this.state.data?.misdate,
          misnum: this.state.data?.misnum,
          src: MD5.make(`${this.state.focused?.misdate}${this.state.data?.misnum}${this.state.focused?.perid}${new Date().getMilliseconds()}`),
        },
      );

      if (parseInt(file?.size?.toString() || '0', 10) > 0) {
        try {
          printJS({
            printable: URL.createObjectURL(new Blob([file.raw!], {
              type: file.extension === 'pdf' ? 'application/pdf' : 'image/png',
            })),
            type: file.extension === 'pdf' ? 'pdf' : 'image',
            showModal: true,
          });
        } catch {
          ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
        }
      }
    }
    if (this.state.popupPrintType2 === '2') {
      api.fxEmail(
        this.state.data.email,
        this.state.data.toname,
        '',
        '',
        `거래명세표 - ${this.state.data.subject}`,
        `${this.state.data.actnm}의 무궁한 발전을 기원합니다.`,
        {
          stamp: this.state.popupStamp,
          dateflag: this.state.popupData,
          misdate: this.state.data?.misdate,
          misnum: this.state.data?.misnum,
        },
        'wb_accountprt',
      );
    }
    if (this.state.popupPrintType2 === '3') {
      api.fxFax(
        '',
        `거래명세표 - ${this.state.data.subject}`,
        {
          stamp: this.state.popupStamp,
          dateflag: this.state.popupData,
          misdate: this.state.data?.misdate,
          misnum: this.state.data?.misnum,
        },
        'wb_accountprt',
      );
    }
  }

  @action
  async texModal(isOpen: boolean) {
    if (!isOpen) {
      this.setState({
        texModal: false,
        n_datetime: '00000000',
        taxPopupDetailData: [],
      });
      await this.onRetrieveEvent();
      return;
    }

    if (this.state.data?.recivstatus !== 'F ' && this.state.data?.recivstatus !== 'FF') {
      ConfirmWarning.show('확인', '상태가 국세청최종승인난 건이 아닙니다. 확인하세요!');
      return;
    }

    if (this.state.data?.misdate === '' || this.state.data?.misdate === null) {
      ConfirmWarning.show('확인', '매출한 내역이 없습니다!');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'wb_modify',
      {
        misdate: this.state.data?.misdate,
        misnum: this.state.data?.misnum,
        cancleflag: this.state.data?.cancleflag,
        recivstatus: this.state.data?.recivstatus,
      },
    );

    if (data) {
      this.setState({ texModal: isOpen });
      await this.modalTexRetrive();
    }
  }

  @action
  onModalRowFocusEvent(item: TexPopupModel) {
    this.setState({ textPopupFocused: item });
  }

  @action
  printTypeModal(isOpen: boolean) {
    this.setState({ printTypeModal: isOpen });
  }

  @action
  billModal(isOpen: boolean) {
    this.setState({
      printTypeModal: false,
      billModal: isOpen,
    });
  }

  @action
  printTypeModal2(isOpen: boolean) {
    this.setState({ printTypeModal2: isOpen, texBillModal: true });
  }

  @action
  async billModalDetail() {
    const { actionStore: api } = this.props;
    this.billModal(false);
    if (this.state.popupPrintType === '1') {
      api.fxPrint('wb_billprt_print', {
        stamp: this.state.popupStamp,
        dateflag: this.state.popupData,
        misdate: this.state.data?.misdate,
        misnum: this.state.data?.misnum,
      });
    }
    if (this.state.popupPrintType === '2') {
      api.fxEmail(
        this.state.data.email,
        this.state.data.toname,
        '',
        '',
        `청구서 - ${this.state.data.subject}`,
        `${this.state.data.actnm}의 무궁한 발전을 기원합니다.`,
        {
          stamp: this.state.popupStamp,
          dateflag: this.state.popupData,
          misdate: this.state.data?.misdate,
          misnum: this.state.data?.misnum,
        },
        'wb_billprt_print',
      );
    }
    if (this.state.popupPrintType === '3') {
      api.fxFax(
        '',
        `청구서 - ${this.state.data.subject}`,
        {
          stamp: this.state.popupStamp,
          dateflag: this.state.popupData,
          misdate: this.state.data?.misdate,
          misnum: this.state.data?.misnum,
        },
        'wb_billprt_print',
      );
    }
  }

  @action
  constructionModal(isOpen: boolean) {
    this.setState({ constructionModal: isOpen });
  }

  @action
  constructionChoice(item: any) {
    if (item === '1') {
      this.constructionModal2(true);
    } else {
      this.constructionModal3(true);
    }
  }

  @action
  constructionModal2(isOpen: boolean) {
    this.setState({ constructionModal: false });
    this.setState({ constructionModal2: isOpen });

    if (isOpen === false) {
      this.onRetrieveEvent();
    } else {
      this.modalConstructionRetrive('w_popup_e471');
    }
  }

  @action
  constructionModal3(isOpen: boolean) {
    this.setState({ constructionModal: false });
    this.setState({ constructionModal3: isOpen });

    if (isOpen === false) {
      this.onRetrieveEvent();
    } else {
      this.modalConstructionRetrive('w_popup_load_da006');
    }
  }

  @action
  async texBillModal(isOpen: boolean) {
    if (this.state.data?.recivstatus !== 'F ' && this.state.data?.recivstatus !== 'FF') {
      ConfirmWarning.show('확인', '국세청최종접수 된 것만 출력/메일전송이 가능합니다.');
      return;
    }
    this.setState({ texBillModal: isOpen, printTypeModal2: true });
  }

  @action
  async modalTexBillRetrive() {
    const { actionStore: api } = this.props;

    this.setState({ printTypeModal2: false });

    if (this.state.data?.recivstatus !== 'F ' && this.state.data?.recivstatus !== 'FF') {
      ConfirmWarning.show('확인', '국세청최종접수 된 것만 출력/메일전송이 가능합니다.');
      return;
    }
    if (this.state.popupPrintType2 === '1') {
      api.fxPrint('wb_taxprt_print', {
        recivstatus: this.state.focused?.recivstatus,
        gubun: this.state.data.gubun,
        tax_spdate: this.state.data.tax_spdate,
        tax_spnum: this.state.data.tax_spnum,
      });
    }
    if (this.state.popupPrintType2 === '2') {
      api.fxEmail(
        this.state.data.email,
        this.state.data.toname,
        '',
        '',
        `전자세금계산서 - ${this.state.data.subject}`,
        `${this.state.data.actnm}의 무궁한 발전을 기원합니다.`,
        {
          recivstatus: this.state.focused?.recivstatus,
          gubun: this.state.data.gubun,
          tax_spdate: this.state.data.tax_spdate,
          tax_spnum: this.state.data.tax_spnum,
        },
        'wb_taxprt_print',
      );
    }
    if (this.state.popupPrintType2 === '3') {
      api.fxFax(
        '',
        `전자세금계산서 - ${this.state.data.subject}`,
        {
          recivstatus: this.state.focused?.recivstatus,
          gubun: this.state.data.gubun,
          tax_spdate: this.state.data.tax_spdate,
          tax_spnum: this.state.data.tax_spnum,
        },
        'wb_taxprt_print',
      );
    }
  }

  @action
  async modalRetrive(item: any, fntName: any) {
    const { actionStore: api } = this.props;

    await this.SS({
      constructionPopup: [],
    });

    const data = await api.fxExec('retrieve',
      {
        sub: fntName,
        spjangcd: item.spjangcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: item.cltcd,
        actcd: item.actcd,
      });
    if (data?.items) {
      this.setState({
        constructionPopup: data?.items,
      });
    }
    if (data?.items2) {
      this.setState({
        constructionPopup: data?.items2,
      });
    }
  }

  @action
  async modalTexRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_da026',
        misdate: this.state.focused?.misdate,
        misnum: this.state.focused?.misnum,
      });
    if (data) {
      this.setState({
        texPopup: data,
        beforeCltcd: data?.n_cltcd,
      });
    }
    this.setState({
      texPopup2: data?.items ? [data.items, { amt: this.state.data?.amt }] : [],
      texModalCheck: false,
    }, async () => {
      await this.table4.current?.update(true);
      this.table4.current?.setFocus(0, 2);
    });
  }

  @action
  async modalNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.n_datetime === '00000000') {
      ConfirmWarning.show('확인', '먼저 매출일자를 입력하세요!');
      return;
    }

    if (this.state.texPopup?.n_sayoo !== '01' && this.state.texPopup?.n_sayoo !== '02') {
      ConfirmWarning.show('확인', '사유가 [2]일경우에만 추가가 가능합니다!');
      return;
    }

    const data = await api.fxExec(
      'dw_3_new',
      {
        sub: 'w_popup_da026',
        misdate: this.state.data?.misdate,
        misnum: this.state.data?.misnum,
        cltcd: this.state.beforeCltcd,
        sayoo: this.state.texPopup?.n_sayoo,
        cnt: String(this.state.texPopup2?.length),
      },
    );

    // const taxcls = data;

    // if (this.state.texPopup?.n_taxcls === '0') {
    //   // 부가세 = 합계금액 % 10
    //   taxcls.addamt = String((Number(data?.misamt) / 10).toFixed());
    //   // 금액 = 합계금액
    //   taxcls.samt = data.misamt;
    //   // 합계금액 = 부가세 + 금액
    //   taxcls.misamt = String(Number(data.samt) + Number(data.addamt));
    // }

    this.setState({
      taxPopupDetailData: [...this.state.taxPopupDetailData, new TexPopupModel({
        ...data?.items[0],
        seq: data?.seq,
      }, true)],
    }, async () => {
      await this.table4.current?.update(true);
      this.table4.current?.setFocus(this.state.taxPopupDetailData?.length - 1);
    });

    this.state.taxPopupDetailData[0].amt = this.state.data?.amt;

    // textPopup 왜쓰는지 모르겠어서 위에 taxPopupDetail로 다시만듬
    // this.setState({
    //   texPopup2: [...this.state.texPopup2, new TexPopupModel(taxcls, true)],
    // }, async () => {
    //   await this.table4.current?.update(true);
    //   this.table4.current?.setFocus(this.state.texPopup2.length - 1);
    // });
    // this.state.texPopup2[0].amt = this.state.data?.amt;
  }

  @action
  async modalDelete() {
    let warning: boolean;
    // eslint-disable-next-line prefer-const
    warning = await Confirm.show('확인', '선택한 내역을 목록에서 삭제하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;

    const checkData: any = [];
    this.state.taxPopupDetailData?.forEach((x: any) => {
      if (this.state.textPopupFocused?.seq !== x.seq) {
        checkData.push(new TexPopupModel({
          ...x,
        }));
      }
    });

    this.setState({
      taxPopupDetailData: checkData,
    }, async () => {
      this.table4.current?.update(false);
    });
  }

  @action
  async modalAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const samt = Format.toNumber(item.samt);
    const addamt = Format.toNumber(item.addamt);
    const uamt = Format.toNumber(item.uamt);

    let amount = 0;
    let sum = 0;
    let tax = 0;
    if (name === 'qty' || name === 'uamt') {
      if (this.state.data.taxgubun === '03') {
        amount = Math.round(qty * uamt);
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.billgubun === '0') {
        amount = Math.round(qty * uamt);
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.taxcls === '1') {
        amount = Math.round((qty * uamt) / 1.1);
        tax = Math.round((qty * uamt) - ((qty * uamt) / 1.1));
        sum = amount + tax;
      } else {
        amount = Math.round(qty * uamt);
        tax = Math.round((qty * uamt) / 10);
        sum = amount + tax;
      }
    } else if (name === 'samt') {
      if (this.state.data.taxgubun === '03') {
        amount = samt;
        tax = 0;
        sum = amount + tax;
      } else if (this.state.data.billgubun === '0') {
        amount = samt;
        tax = addamt;
        sum = amount + tax;
      } else if (this.state.data.taxcls === '1') {
        if (qty === 0 && uamt === 0) {
          tax = Math.round(samt - (samt / 1.1));
          amount = Math.round(samt - tax);
          sum = amount + tax;
        } else {
          tax = Math.round((samt) - (samt / 1.1));
          amount = samt - tax;
          sum = amount + tax;
        }
      } else {
        amount = samt;
        tax = Math.round(amount / 10);
        sum = amount + tax;
      }
    } else {
      amount = samt;
      tax = addamt;
      sum = amount + tax;
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: amount.toString(),
      addamt: tax.toString(),
      misamt: sum.toString(),
    });
  }

  @action
  async modalSaveEvent() {
    const { actionStore: api } = this.props;
    const cDate = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.n_datetime);

    if (this.state.n_datetime === '00000000') {
      ConfirmWarning.show('확인', '먼저 매출일자를 입력하세요!');
      return;
    }

    const data = await api.fxExec('save', {
      sub: 'w_popup_da026',
      new: '1',
      becltcd: this.state.data?.cltcd,
      bemisdate: this.state.data?.misdate,
      bemisnum: this.state.data?.misnum,
      misdate: this.state.n_datetime,
      remark: this.state.texPopup?.remarks,
      bigo: this.state.texPopup?.n_bigo,
      billgubun: this.state.data?.billgubun,
      taxcls: this.state.texPopup?.n_taxcls,
      sayoo: this.state.texPopup?.n_sayoo,
      receiptyn: this.state.texPopup?.n_receiptyn,
      cltcd: this.state.texPopup?.n_cltcd,
      actcd: this.state.texPopup?.n_actcd,
      cltnm: this.state.texPopup?.n_cltnm,
      actnm: this.state.texPopup?.n_actnm,
      billkind: this.state.texPopup?.n_billkind,
      divicd: this.state.texPopup?.divicd,
      divinm: this.state.texPopup?.n_bigo,
      accyn: this.state.data?.accyn,
      acccd: this.state.data?.acccd,
      gubun: this.state.data?.gubun,
      // items: this.textPopupUpdatedRows || this.state.texPopup2,
      items: this.textPopupUpdatedRows || this.state.taxPopupDetailData,
    }, this.state.texPopup.isNew);

    if (data) {
      this.updatedRows = [];
      this.table4.current?.resetUpdates();

      await this.SS({
        stdate: cDate.stdate,
        enddate: cDate.enddate,
        data: new ExpenseModel({
          ...this.state.data,
          misdate: data.misdate,
          misnum: data.misnum,
        }),
      });

      ConfirmSuccess.showPbMessage(data);
    }
    await this.texModal(false);
  }

  @action
  async modalConstructionRetrive(item: any) {
    const { actionStore: api } = this.props;

    if (item === 'w_popup_e471') {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity3 = new InfinityRetrieve(
        {
          sub: item,
          stdate: this.state.popupStdate,
          enddate: this.state.enddate,
          as_nm: this.state.constructionSearch,
        },
        (params) => api.retrieve(params),
        (items) => {
          this.setState({
            constructionPopup: [
              ...this.state.constructionPopup,
              ...items.map((x: any) => new TexPopupModel(x)),
            ],
            construction: '1',
          });
        },
        async () => {
          await this.SS({
            constructionPopup: [],
          });
          await this.infinity3?.retrieveAll();
          if (this.state.constructionPopup && this.state.constructionPopup?.length > 0) {
            await this.table2.current?.update();
            this.table2.current?.setFocus(0, 0);
          }
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        constructionPopup: [],
      }, async () => {
        await this.infinity3?.retrieveAll();
        if (this.state.constructionPopup && this.state.constructionPopup?.length > 0) {
          await this.table2.current?.update();
          this.table2.current?.setFocus(0, 0);
        }
      });
    } else {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity4 = new InfinityRetrieve(
        {
          sub: item,
          stdate: this.state.popupStdate,
          enddate: this.state.enddate,
          as_nm: this.state.constructionSearch2,
        },
        (params) => api.retrieve(params),
        (items) => {
          this.setState({
            constructionPopup2: [
              ...this.state.constructionPopup2,
              ...items.map((x: any) => new TexPopupModel(x)),
            ],
            construction: '1',
          });
        },
        async () => {
          await this.SS({
            constructionPopup2: [],
          });
          await this.infinity4?.retrieveAll();
          if (this.state.constructionPopup2 && this.state.constructionPopup2?.length > 0) {
            await this.table3.current?.update();
            this.table3.current?.setFocus(0, 0);
          }
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        constructionPopup2: [],
      }, async () => {
        await this.infinity4?.retrieveAll();
        if (this.state.constructionPopup2 && this.state.constructionPopup2?.length > 0) {
          await this.table3.current?.update();
          this.table3.current?.setFocus(0, 0);
        }
      });
    }
    await this.table2.current?.update(true);
  }

  @action
  modalConstructionRowFocus(item: any) {
    this.setState({ constructionFocused: item });
  }

  @action
  modalConstructionUpdated(rows: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({ constructionPopup: rows });
  }

  @action
  modalConstructionUpdated2(rows: any, updatedRows3: any) {
    this.updatedRows3 = updatedRows3;
    this.setState({ constructionPopup2: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      constructionPopup: this.state.constructionPopup?.map((x) => new TexPopupModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table2.current?.update(false);
  }

  @action
  async updateCheckAllToggle2(checked: boolean) {
    this.setState({
      pushTotalCheck2: checked,
      constructionPopup2: this.state.constructionPopup2?.map((x) => new TexPopupModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table3.current?.update(false);
  }

  @action
  async modalConstructionSave() {
    const { actionStore: api } = this.props;

    if (this.state.constructionPopup.length < 1) {
      ConfirmWarning.show('확인', '공사등록할 목록이 없습니다.');
      return;
    }

    const alert: any[] = [];
    this.state.constructionPopup?.forEach((x: any) => {
      if (x?.misperid) {
        alert.push(({
          ...x,
        }));
      }
    });

    if (!alert.length) {
      ConfirmWarning.show('확인', '매출실적 담당자를 입력해주세요');
      return;
    }

    const chkData: any[] = [];
    this.state.constructionPopup?.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(({
          new: '0',
          chk: x.chk,
          actcd: x.actcd,
          actnm: x.actnm,
          cltcd: x.cltcd,
          cltnm: x.cltnm,
          divicd: x.divicd,
          divinm: x.divinm,
          perid: x.perid,
          misperid: x.misperid,
          pernm: x.pernm,
          remark: x.remark,
          gubun: x.gubun,
          billkind: x.billkind,
          samt: x.samt,
          tamt: x.tamt,
          camt: x.camt,
          compdate: x.compdate,
          compnum: x.compnum,
          projno: x.projno,
          projnm: x.projnm,
          wkactcd: x.wkactcd,
          saleamt: x.saleamt,
          saleyul: x.saleyul,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    if (await api.save({
      sub: 'w_popup_e471',
      new: '0',
      misdate: this.state.constructionDate,
      items: chkData,
    }, true)) {
      this.updatedRows2 = [];
      this.table2.current?.resetUpdates();
      await this.modalConstructionRetrive('w_popup_e471');
      this.setState({
        stdate: this.state.constructionDate,
        enddate: this.state.constructionDate,
      });
    }
  }

  @action
  async modalConstructionSave2() {
    const { actionStore: api } = this.props;

    const alert: any[] = [];
    this.state.constructionPopup2?.forEach((x: any) => {
      if (x?.misperid) {
        alert.push(({
          ...x,
        }));
      }
    });

    if (!alert.length) {
      ConfirmWarning.show('확인', '매출실적 담당자를 입력해주세요');
      return;
    }

    const chkData: any[] = [];
    this.state.constructionPopup2?.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(({
          new: '0',
          chk: x.chk,
          actcd: x.actcd,
          actnm: x.actnm,
          cltcd: x.cltcd,
          cltnm: x.cltnm,
          perid: x.misperid,
          gubunnm: x.gubunnm,
          setcls: x.setcls,
          samt: x.samt,
          tamt: x.tamt,
          mamt: x.mamt,
          scdate: x.scdate,
          orddate: x.orddate,
          ordnum: x.ordnum,
          projno: x.projno,
          projnm: x.projnm,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    if (await api.save({
      sub: 'w_popup_load_da006',
      new: '0',
      items: chkData,
      misdate: this.state.constructionDate2,
    }, true)) {
      this.updatedRows3 = [];
      this.table3.current?.resetUpdates();
      await this.modalConstructionRetrive('w_popup_load_da006');
      this.setState({
        stdate: this.state.constructionDate2,
        enddate: this.state.constructionDate2,
      });
    }
  }

  @action
  async modalConstructionDelete() {
    const { actionStore: api } = this.props;

    const chkData: any[] = [];
    this.state.constructionPopup.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    const text = '선택한 내용을 목록에서 삭제하시겠습니까?';
    await api.fxDelete(
      'delete',
      text,
      {
        sub: 'w_popup_e471',
        chk: this.state.constructionFocused?.chk,
        compdate: this.state.constructionFocused?.compdate,
        compnum: this.state.constructionFocused?.compnum,
        items: chkData,
      },
    );

    this.modalConstructionRetrive('w_popup_e471');
  }

  @action
  async modalConstructionDelete2() {
    const { actionStore: api } = this.props;

    const chkData: any[] = [];
    this.state.constructionPopup2.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    const text = '선택한 내용을 목록에서 삭제하시겠습니까?';
    await api.fxDelete(
      'delete',
      text,
      {
        sub: 'w_popup_load_da006',
        chk: this.state.constructionFocused?.chk,
        compdate: this.state.constructionFocused?.compdate,
        compnum: this.state.constructionFocused?.compnum,
        items: chkData,
      },
    );

    this.modalConstructionRetrive('w_popup_load_da006');
  }

  @computed
  get tabIndex(): SalesTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <SalesTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new ExpenseModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
            texPopup: new TexPopupModel({
              ...this.state.texPopup,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
        // @ts-ignore
        rowUpdate={(state) => this.setState(state)}
      />
    );
  }
}
