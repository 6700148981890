import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PayCurrentState } from './PayCurrentState';
import { Format } from '../../../../utils/string';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';

const mijflags = [
  { value: '0', remark: '미지급' },
  { value: '1', remark: '매입' },
  { value: '2', remark: '지급' },
];

/**
 * 화면
 * @window w_tb_pb300_02
 * @category 급여현황
 */
export const PayCurrentStateTemplate: React.FC<TemplateProps<PayCurrentState>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>월</span>
        <DateTextBox
          value={scope.state.yearmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => {
            setData({ yearmon: value },
              () => scope.onRetrieveEvent());
          }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={240}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => setData({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={240}>
        <span>부서구분</span>
        <ComboBox
          textAlign="center"
          value={scope.state.searchKukcds}
          data={scope.state.kukcd?.map((y) => new ComboBoxModel(y.kukcd, y.kuknm))}
          onSelect={(option) => setData({ searchKukcds: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={176}>
        <span>임원구분</span>
        <ComboBox
          value={scope.state.offiyn}
          textAlign="center"
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '임원' },
            { value: '0', remark: '직원' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ offiyn: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={176}>
        <span>연차적용</span>
        <ComboBox
          value={scope.state.restflag}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={[
            { value: '1', remark: '적용' },
            { value: '0', remark: '미적용' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ restflag: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span>사원명</span>
        <TextBox
          value={scope.state.name}
          onChange={(value) => setData({ name: value })}
          onEnter={() => scope.onFocusEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    {
      scope.state.paytype !== '2'
        ? <FlexLayout>
          <GridLayout
            ref={scope.grid}
            headerHeight={60}
            header={[
              {
                id: 'no',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      순번
                    </FlexLayout>

                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      사원번호
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 50,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.no}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.perid}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      입사일
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      퇴사일
                    </FlexLayout>

                    <FlexLayout align="end">
                      생년월일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.entdate)}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.rtdate ? 'var(--color-red)' : '' }}
                    >
                      {!x.dayterm2 ? x.rtdate : x.dayterm2}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.birthday}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      성명
                    </FlexLayout>

                    <FlexLayout>
                      직책
                    </FlexLayout>

                    <FlexLayout>
                      부서
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    정산내역
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pernm}
                    </FlexLayout>

                    <FlexLayout>
                      {x.rspnm}
                    </FlexLayout>

                    <FlexLayout>
                      {x.divinm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'workday',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      근로일수
                    </FlexLayout>

                    <FlexLayout>
                      근로시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.workday}
                    </FlexLayout>

                    <FlexLayout>
                      {x.worktime}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'month_minus_tot',
                text: '매월차감일수',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.month_minus_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'time_ol',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      고정연장
                    </FlexLayout>

                    <FlexLayout>
                      고정야간
                    </FlexLayout>

                    <FlexLayout>
                      고정휴일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.time_older}
                    </FlexLayout>

                    <FlexLayout>
                      {x.time_night}
                    </FlexLayout>

                    <FlexLayout>
                      {x.time_holiday}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'over_older_pay',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      추가연장
                    </FlexLayout>

                    <FlexLayout>
                      추가연장
                    </FlexLayout>

                    <FlexLayout>
                      추가휴일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.over_older_time_cal}
                    </FlexLayout>

                    <FlexLayout>
                      {x.over_duty_time_cal}
                    </FlexLayout>

                    <FlexLayout>
                      {x.over_holi_time_cal}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_basic',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      기본급
                    </FlexLayout>

                    <FlexLayout>
                      (주휴포함)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.pay_basic_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.pay_basic)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_older',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      고정연장
                    </FlexLayout>

                    <FlexLayout>
                      고정야간
                    </FlexLayout>

                    <FlexLayout>
                      고정휴일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                      justify="right"
                      align="center"
                      isVertical={true}
                  >
                    <FlexLayout>
                      {scope.state.pay_older_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_night_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_holiday_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {Format.number(x.pay_older)}
                    </FlexLayout>

                    <FlexLayout>{
                      Format.number(x.pay_night)}
                    </FlexLayout>

                    <FlexLayout>{
                      Format.number(x.pay_night)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'over_older_pay2',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      추가연장
                    </FlexLayout>

                    <FlexLayout>
                      당직평일
                    </FlexLayout>

                    <FlexLayout>
                      당직휴일
                    </FlexLayout>
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {scope.state.over_older_pay_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.over_holi_pay_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.over_duty_pay_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                 <FlexLayout
                   isVertical={true}
                   justify="center"
                   align="center"
                 >
                   <FlexLayout>
                     {Format.number(x.over_older_pay)}
                   </FlexLayout>

                   <FlexLayout>
                     {Format.number(x.over_duty_pay)}
                   </FlexLayout>

                   <FlexLayout>
                     {Format.number(x.over_holi_pay)}
                   </FlexLayout>
                 </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_01',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {scope.state.pay_tax0_01_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax0_02_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax0_03_t}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pay_tax0_01}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax0_02}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax0_03}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_04',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {scope.state.pay_tax0_04_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax0_05_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax0_06_t}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pay_tax0_04}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax0_05}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax0_06}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax0_07',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {scope.state.pay_tax0_07_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax0_08_t}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pay_tax0_07}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax0_08}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_01',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {scope.state.pay_tax1_01_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax1_02_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax1_03_t}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pay_tax1_01}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax1_02}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax1_03}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_04',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {scope.state.pay_tax1_04_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax1_05_t}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_tax1_06_t}
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pay_tax1_04}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax1_05}
                    </FlexLayout>

                    <FlexLayout>
                      {x.pay_tax1_06}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_tot',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      지각조퇴
                    </FlexLayout>

                    <FlexLayout>
                      차감금액
                    </FlexLayout>

                    <FlexLayout>
                      /
                    </FlexLayout>

                    <FlexLayout>
                      연차수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {scope.state.minuspay_late_tot_tot}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.pay_yearly_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.minuspay_late_tot)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_yearly)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'tot_samt',
                text: '월간급여총액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#B2EBF4' }}
                  >
                    {scope.state.tot_samt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    style={{ backgroundColor: '#B2EBF4' }}
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rest_pay',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      연차환급금
                    </FlexLayout>

                    <FlexLayout>
                      소득세환급금
                    </FlexLayout>

                    <FlexLayout>
                      건강보험환급금
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.rest_pay_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_income_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_health_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                    style={{ color: x.rest_pay > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.rest_pay)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_income)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_health)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'insu_unem',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      고용보험
                    </FlexLayout>

                    <FlexLayout>
                      건강보험
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.insu_unem_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.insu_health_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.insu_unem)}
                    </FlexLayout>
                    <FlexLayout>
                      {Format.number(x.insu_health)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'insu_longterm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      장기요양
                    </FlexLayout>

                    <FlexLayout>
                      국민연금
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.insu_longterm_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.insu_national_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.insu_longterm)}
                    </FlexLayout>
                    <FlexLayout>
                      {Format.number(x.insu_national)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'payamt_tax',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      갑근세
                    </FlexLayout>

                    <FlexLayout>
                      주민세
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.payamt_tax_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.payamt_tax2_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.payamt_tax)}
                    </FlexLayout>
                    <FlexLayout>
                      {Format.number(x.payamt_tax2)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'insu_maf',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      상조회비
                    </FlexLayout>

                    <FlexLayout>
                      가불금
                    </FlexLayout>

                    <FlexLayout>
                      기타공제
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.insu_maf_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_suspense_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_other_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.insu_maf)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_suspense)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_other)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_national',
                text: '연말정산보험료',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.tax_national_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tax_national)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tot_tamt',
                text: '공제내역총액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {scope.state.tot_tamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {Format.number(x.tot_tamt)}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={60}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
        : <FlexLayout>
          <GridLayout
            ref={scope.grid}
            headerHeight={60}
            header={[
              {
                id: 'no',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      순번
                    </FlexLayout>

                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      사원번호
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 50,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.no}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.perid}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'entdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      align="start"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      입사일
                    </FlexLayout>

                    <FlexLayout
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      퇴사일
                    </FlexLayout>

                    <FlexLayout align="end">
                      생년월일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.entdate)}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.rtdate ? 'var(--color-red)' : '' }}
                    >
                      {!x.dayterm2 ? x.rtdate : x.dayterm2}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.birthday}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      성명
                    </FlexLayout>

                    <FlexLayout>
                      직책
                    </FlexLayout>

                    <FlexLayout>
                      부서
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    정산내역
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.pernm}
                    </FlexLayout>

                    <FlexLayout>
                      {x.rspnm}
                    </FlexLayout>

                    <FlexLayout>
                      {x.divinm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'workday',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      근로일수
                    </FlexLayout>

                    <FlexLayout>
                      근로시간
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.workday}
                    </FlexLayout>

                    <FlexLayout>
                      {x.worktime}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'month_minus_tot',
                text: '매월차감일수',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.month_minus_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'time_ol',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      고정연장
                    </FlexLayout>

                    <FlexLayout>
                      고정야간
                    </FlexLayout>

                    <FlexLayout>
                      고정휴일
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.time_older}
                    </FlexLayout>

                    <FlexLayout>
                      {x.time_night}
                    </FlexLayout>

                    <FlexLayout>
                      {x.time_holiday}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_01',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      추가연장
                    </FlexLayout>

                    <FlexLayout>
                      추가휴일
                    </FlexLayout>

                    <FlexLayout>
                      당직연장
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      {x.over_older_time_cal}
                    </FlexLayout>

                    <FlexLayout>
                      {x.over_duty_time_cal}
                    </FlexLayout>

                    <FlexLayout>
                      {x.over_holi_time_cal}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_basic',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      기본급
                    </FlexLayout>

                    <FlexLayout>
                      (주휴포함)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.pay_basic_tot}
                    </FlexLayout>
                ),
                render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_basic)}
                    </FlexLayout>
                ),
              },
              {
                id: 'pay_older',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      고정연장수당
                    </FlexLayout>

                    <FlexLayout>
                      고정야간수당
                    </FlexLayout>

                    <FlexLayout>
                      고정휴일수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {scope.state.pay_older_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_night_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_holiday_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {Format.number(x.pay_older)}
                    </FlexLayout>

                    <FlexLayout>{
                      Format.number(x.pay_night)}
                    </FlexLayout>

                    <FlexLayout>{
                      Format.number(x.pay_night)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_tax1_04',
                text: (
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="center"
                  >
                    <FlexLayout>
                      추가연장수당
                    </FlexLayout>

                    <FlexLayout>
                      추가휴일수당
                    </FlexLayout>

                    <FlexLayout>
                      당직외추가수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {scope.state.over_older_pay_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.over_holi_pay_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.over_duty_pay_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                  >
                    <FlexLayout>
                      {Format.number(x.over_older_pay)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.over_holi_pay)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.over_duty_pay)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_tot2',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      식대수당금액
                    </FlexLayout>

                    <FlexLayout>
                      직무수당금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {scope.state.pay_food_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_serallow_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.pay_food)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_serallow)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'pay_leader',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      인센티브1
                    </FlexLayout>

                    <FlexLayout>
                      /
                    </FlexLayout>

                    <FlexLayout>
                      인센티브2
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {scope.state.pay_leader_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.pay_incentive_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      {Format.number(x.pay_leader)}
                    </FlexLayout>

                    <FlexLayout>{
                      Format.number(x.pay_incentive)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'minuspay_late_tot',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      지각조퇴차감
                    </FlexLayout>

                    <FlexLayout>
                      /
                    </FlexLayout>

                    <FlexLayout>
                      연차수당
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {scope.state.minuspay_late_tot_tot}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.pay_yearly_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.minuspay_late_tot)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.pay_yearly)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'tot_samt',
                text: '월간급여총액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#B2EBF4' }}
                  >
                    {scope.state.tot_samt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    style={{ backgroundColor: '#B2EBF4' }}
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tot_samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rest_pay',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      연차환급금
                    </FlexLayout>

                    <FlexLayout>
                      소득세환급금
                    </FlexLayout>

                    <FlexLayout>
                      건강보험환급금
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.rest_pay_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_income_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_health_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                    style={{ color: x.rest_pay > 0 ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.rest_pay)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_income)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_health)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'insu_longterm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      고용보험
                    </FlexLayout>

                    <FlexLayout>
                      건강보험
                    </FlexLayout>

                    <FlexLayout>
                      장기요양보험
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.insu_unem_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.insu_health_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.insu_longterm_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.insu_unem)}
                    </FlexLayout>
                    <FlexLayout>
                      {Format.number(x.insu_health)}
                    </FlexLayout>
                    <FlexLayout>
                      {Format.number(x.insu_longterm)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'payamt_tax',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      국민연금
                    </FlexLayout>

                    <FlexLayout>
                      근로소득세
                    </FlexLayout>

                    <FlexLayout>
                      근로주민세
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.insu_national_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.payamt_tax_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.payamt_tax2_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.insu_national)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.payamt_tax)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.payamt_tax2)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'insu_maf',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>
                      가불금
                    </FlexLayout>

                    <FlexLayout>
                      상조회비
                    </FlexLayout>

                    <FlexLayout>
                      기타공제
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {scope.state.insu_maf_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_suspense_tot}
                    </FlexLayout>

                    <FlexLayout>
                      {scope.state.tax_other_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    isVertical={true}
                    style={{ color: 'var(--color-red)' }}
                  >
                    <FlexLayout>
                      {Format.number(x.insu_maf)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_suspense)}
                    </FlexLayout>

                    <FlexLayout>
                      {Format.number(x.tax_other)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_national',
                text: '연말정산보험료',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.tax_national_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tax_national)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tot_tamt',
                text: '공제내역총액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {scope.state.tot_tamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-red)',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {Format.number(x.tot_tamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tot_mamt',
                text: '실수령액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {scope.state.tot_mamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{
                      color: 'var(--color-blue)',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {Format.number(x.tot_mamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijflag',
                text: '지급구분',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ backgroundColor: '#B2EBF4' }}
                  >
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: x.mijflag === '0'
                        ? 'var(--color-red)'
                        : x.mijflag === '2'
                          ? 'var(--color-blue)'
                          : '#7F7A2C',
                      backgroundColor: '#B2EBF4',
                    }}
                  >
                    {mijflags.filter((y) => y.value === x.mijflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={60}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
    }
  </FlexLayout>
  );
};
