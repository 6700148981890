import * as React from 'react';
import {
  Button, FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabTableProcessHeader } from '../headers';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 상세 > 탭 - 공정현황
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabProcess: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <Button width={85} onClick={() => scope.onClickProcessCreate()}>호기생성</Button>
      <Button width={85} onClick={() => scope.onClickProcessNew()}>추가</Button>
      <Button width={85} theme={BUTTON_THEMES.RED} onClick={() => scope.onClickProcessRemove()}>삭제</Button>
    </FlexLayout>

    <TableLayout
      ref={scope.tableProcess}
      header={RegistTabTableProcessHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.tabDataProcess}
      onChange={(rows, updates) => scope.onTabTableProcessChange(rows, updates)}
      onRowFocusEvent={(_, index) => scope.onTabTableProcessRowFocused(index)}
    />
  </FlexLayout>
);
