import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w_02
 * @category 사람갇힘현황
 */

export class HumanCageDayModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(3)
   */
  readonly equpnm: string;

  /**
   * 접수일자
   *
   * **data type** varchar(15)
   */
  readonly recedate: string;

  /**
   * 접수시간
   *
   * **data type** varchar(100)
   */
  readonly recetime: string;

  /**
   * 도착일자
   *
   * **data type** varchar(10)
   */
  readonly arrivdate: string;

  /**
   * 도착시간
   *
   * **data type** varchar(100)
   */
  readonly arrivtime: string;

  /**
   * 완료일자
   *
   * **data type** varchar(3)
   */
  readonly compdate: string;

  /**
   * 완료시간
   *
   * **data type** varchar(3)
   */
  readonly comptime: string;

  /**
   * 대응시간
   *
   * **data type** varchar(100)
   */
  readonly resutime: string;

  /**
   * 처리시간
   *
   * **data type** varchar(0)
   */
  readonly resulttime: string;

  /**
   * 사람갇힘
   *
   * **data type** varchar(1)
   */
  readonly trouble: string;

  /**
   * 고장내용
   *
   * **data type** varchar(?)
   */
  readonly contnm: string;

  /**
   * 고장내용상세
   *
   * **data type** varchar(?)
   */
  readonly contremark: string;

  /**
   * 처리내용
   *
   * **data type** varchar(8)
   */
  readonly resunm: string;

  /**
   * 처리내용상세
   *
   * **data type** varchar(8)
   */
  readonly resuremark: string;

  /**
   * 고장요인
   *
   * **data type** varchar(10)
   */
  readonly remonm: string;

  /**
   * 고장요인상세
   *
   * **data type** varchar(8)
   */
  readonly remoremark: string;

  /**
   * 고장원인
   *
   * **data type** varchar(4)
   */
  readonly facnm: string;

  /**
   * 고장부위
   *
   * **data type** varchar(8)
   */
  readonly greginm: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar(4)
   */
  readonly reginm: string;

  /**
   * 처리방법
   *
   * **data type** varchar(4)
   */
  readonly resultnm: string;

  /**
   * 담당부서명
   *
   * **data type** varchar(?)
   */
  readonly actdivinm: string;

  /**
   *  담당자
   *
   * **data type** varchar(?)
   */
  readonly actpernm: string;

  /**
   *  처리자
   *
   * **data type** varchar(?)
   */
  readonly pernm: string;

  /**
   *  처리자2
   *
   * **data type** varchar(?)
   */
  readonly pernm2: string;

  /**
   *  접수자
   *
   * **data type** varchar(?)
   */
  readonly repernm: string;

  /**
   *  승강기번호
   *
   * **data type** varchar(?)
   */
  readonly elno: string;

  /**
   *  이전갇힘일자
   *
   * **data type** varchar(?)
   */
  readonly troubledate_be: string;

  /**
   *  이전으로 발생(월)
   *
   * **data type** varchar(?)
   */
  readonly dayterm: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.arrivdate = data.arrivdate || '';
    this.arrivtime = data.arrivtime || '';
    this.recedate = data.recedate || '';
    this.compdate = data.compdate || '';
    this.comptime = data.comptime || '';
    this.resutime = data.resutime || '';
    this.resulttime = data.resulttime || '';
    this.trouble = data.trouble || '';
    this.contremark = data.contremark || '';
    this.resunm = data.resunm || '';
    this.resuremark = data.resuremark || '';
    this.remonm = data.remonm || '';
    this.remoremark = data.remoremark || '';
    this.facnm = data.facnm || '';
    this.greginm = data.greginm || '';
    this.reginm = data.reginm || '';
    this.resultnm = data.resultnm || '';
    this.actdivinm = data.actdivinm || '';
    this.equpnm = data.equpnm || '';
    this.recetime = data.recetime || '';
    this.actpernm = data.actpernm || '';
    this.equpnm = data.equpnm || '';
    this.contnm = data.contnm || '';
    this.repernm = data.repernm || '';
    this.pernm = data.pernm || '';
    this.pernm2 = data.pernm2 || '';
    this.elno = data.elno || '';
    this.troubledate_be = data.troubledate_be || '';
    this.dayterm = data.dayterm || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
