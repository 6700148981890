import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca608_01
 * @category 발주등록
 */

export class OrderModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   * 발주구분
   *
   * **data type** varchar(5)
   */
  readonly baldv: string;

  /**
   * 담당자
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 입고구분
   *
   * **data type** varchar(1)
   */
  readonly ipgflag: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(0)
   */
  readonly cltnm: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 부가세구분
   *
   * **data type** varchar(2)
   */
  readonly taxcls: string;

  /**
   * 창고코드
   *
   * **data type** varchar(3)
   */
  readonly store: string;

  /**
   * 창고명
   *
   * **data type** varchar(3)
   */
  readonly storenm: string;

  /**
   * 작성일자
   *
   * **data type** char(8)
   */
  readonly indate: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 특이사항
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 전달사항
   *
   * **data type** text
   */
  bigo: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 하자기간
   *
   * **data type** float
   */
  readonly hagigan: number;

  /**
   * 소요기간
   *
   * **data type** float
   */
  readonly sogigan: number;

  /**
   * 발주부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 발주부서명
   *
   * **data type** varchar(100)
   */
  readonly divinm: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  readonly refer: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  readonly picflag: string;

  /**
   * 납품장소
   *
   * **data type** varchar(13)
   */
  readonly proactcd: string;

  /**
   * 계약금
   *
   * **data type** float
   */
  readonly styul: number;

  /**
   * 중도금
   *
   * **data type** float
   */
  readonly midyul: number;

  /**
   * 잔금
   *
   * **data type** float
   */
  readonly endyul: number;

  /**
   * 할인율
   *
   * **data type** float
   */
  readonly saleyul: number;

  /**
   * 계약금2
   *
   * **data type** float
   */
  readonly stamt: string;

  /**
   * 중도금2
   *
   * **data type** float
   */
  readonly midamt: string;

  /**
   * 잔금2
   *
   * **data type** float
   */
  readonly endamt: string;

  /**
   * 할인금액
   *
   * **data type** float
   */
  readonly saleamt: string;

  /**
   * 계약금 여부
   *
   * **data type** varchar(1)
   */
  readonly sunflag: string;

  /**
   * 발주처담당자
   *
   * **data type** varchar(100)
   */
  readonly balpernm: string;

  /**
   * 발주연락처
   *
   * **data type** varchar(20)
   */
  readonly telnum: string;

  /**
   * 발주처fax
   *
   * **data type** varchar(20)
   */
  readonly faxnum: string;

  /**
   * 발주email
   *
   * **data type** varchar(100)
   */
  readonly email: string;

  /**
   * 전달사항(비고)
   *
   * **data type**
   */
  readonly reporemark: string;

  /**
   * 첨부파일
   *
   * **data type**
   */
  readonly attcnt: number;

  /**
   * 첨부파일2
   *
   * **data type**
   */
  readonly attcnt2: number;

  /**
   * 프로젝트명
   *
   * **data type**
   */
  readonly projnm: string;

  /**
   *
   *
   * **data type**
   */
  readonly balno: string;

  /**
   *
   *
   * **data type**
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type**
   */
  readonly pumdivicd: string;

  /**
   *
   *
   * **data type**
   */
  readonly taxgubun: string;

  /**
   *
   *
   * **data type**
   */
  readonly setcls: string;

  /**
   *
   *
   * **data type**
   */
  readonly domcls: string;

  /**
   *
   *
   * **data type**
   */
  readonly moncls: string;

  /**
   *
   *
   * **data type**
   */
  readonly saleflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly ischdate: string;

  /**
   *
   *
   * **data type**
   */
  ibgflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly qcflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type**
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type**
   */
  readonly pumdv: string;

  /**
   *
   *
   * **data type**
   */
  readonly maildv: string;

  /**
   *
   *
   * **data type**
   */
  readonly cdflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly compflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly compdate: string;

  /**
   *
   *
   * **data type**
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type**
   */
  readonly apptime: string;

  /**
   *
   *
   * **data type**
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type**
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type**
   */
  readonly mijflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type**
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type**
   */
  readonly chk: string;

  /**
   *
   *
   * **data type**
   */
  readonly monrate: string;

  /**
   *
   *
   * **data type**
   */
  readonly samt: string;

  /**
   *
   *
   * **data type**
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type**
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type**
   */
  readonly sunamt: string;

  /**
   *
   *
   * **data type**
   */
  readonly ibgdate: string;

  /**
   *
   *
   * **data type**
   */
  readonly ibgnum: string;

  /**
   *
   *
   * **data type**
   */
  readonly compperid: string;

  /**
   *
   *
   * **data type**
   */
  readonly compdivicd: string;

  /**
   *
   *
   * **data type**
   */
  readonly mijdate: string;

  /**
   *
   *
   * **data type**
   */
  readonly mijnum: string;

  /**
   *
   *
   * **data type**
   */
  readonly delflag: string;

  /**
   *
   *
   * **data type**
   */
  readonly amtflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.baldv = data.baldv || '';
    this.pernm = data.pernm || '';
    this.perid = data.perid || '';
    this.ipgflag = data.ipgflag || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.state = data.state || '';
    this.taxcls = data.taxcls || '';
    this.store = data.store || '';
    this.storenm = data.storenm || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.remark = data.remark || '';
    this.bigo = data.bigo || '';
    this.projno = data.projno || '';
    this.appnum = data.appnum || '';
    this.hagigan = data.hagigan || 0;
    this.sogigan = data.sogigan || 0;
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.refer = data.refer || '';
    this.picflag = data.picflag || '';
    this.proactcd = data.proactcd || '';
    this.styul = data.styul || 0;
    this.midyul = data.midyul || 0;
    this.endyul = data.endyul || 0;
    this.saleyul = data.saleyul || 0;
    this.stamt = data.stamt || '';
    this.midamt = data.midamt || '';
    this.endamt = data.endamt || '';
    this.saleamt = data.saleamt || '';
    this.sunflag = data.sunflag || '';
    this.balpernm = data.balpernm || '';
    this.telnum = data.telnum || '';
    this.faxnum = data.faxnum || '';
    this.email = data.email || '';
    this.reporemark = data.reporemark || '';
    this.attcnt = data.attcnt || 0;
    this.attcnt2 = data.attcnt2 || 0;
    this.projnm = data.projnm || '';
    this.balno = data.balno || '';
    this.actcd = data.actcd || '';
    this.pumdivicd = data.pumdivicd || '';
    this.taxgubun = data.taxgubun || '';
    this.setcls = data.setcls || '';
    this.domcls = data.domcls || '';
    this.moncls = data.moncls || '';
    this.saleflag = data.saleflag || '';
    this.ischdate = data.ischdate || '';
    this.ibgflag = data.ibgflag || '';
    this.qcflag = data.qcflag || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.pumdv = data.pumdv || '';
    this.maildv = data.maildv || '';
    this.cdflag = data.cdflag || '0';
    this.compflag = data.compflag || '';
    this.compdate = data.compdate || '';
    this.appdate = data.appdate || '';
    this.apptime = data.apptime || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.mijflag = data.mijflag || '';
    this.inpernm = data.inpernm || '';
    this.appremark = data.appremark || '';
    this.chk = data.chk || '';
    this.monrate = data.monrate || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.sunamt = data.sunamt || '';
    this.ibgdate = data.ibgdate || '';
    this.ibgnum = data.ibgnum || '';
    this.compperid = data.compperid || '';
    this.compdivicd = data.compdivicd || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.delflag = data.delflag || '';
    this.amtflag = data.amtflag || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
