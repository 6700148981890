/**
 * 모델
 * @window w_tb_e451w
 * @category 견적현황
 */
export default class StatusDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 발주일
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 발주상태
   *
   * **data type** varchar(8)
   */
  readonly balflag: string;

  /**
   * 발주번호
   *
   * **data type** varchar(8)
   */
  readonly balnum: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  /**
   * 발주구분
   *
   * **data type** varchar(8)
   */
  readonly billkind: string;

  /**
   * 완료여부
   *
   * **data type** varchar(8)
   */
  readonly compyn: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly costamt: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 견적담당자
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly janamt: string;

  /**
   * 지로구분
   *
   * **data type** varchar(8)
   */
  readonly jirogubun: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly misamt: string;

  /**
   * 유무상
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly offidate: string;

  /**
   * 품목코드
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 품명
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   * 자재청구일
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   * 자재청구구분
   *
   * **data type** varchar(8)
   */
  readonly pumflag: string;

  /**
   * 자재청구번호
   *
   * **data type** varchar(8)
   */
  readonly pumnum: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 팀장
   *
   * **data type** varchar(8)
   */
  readonly reader: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly recivstatus: string;

  /**
   * 특이사항
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 수주일
   *
   * **data type** varchar(8)
   */
  readonly sudate: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 회사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actnm = data.actnm || '';
    this.baldate = data.baldate || '';
    this.balflag = data.balflag || '';
    this.balnum = data.balnum || '';
    this.bigo = data.bigo || '';
    this.billkind = data.billkind || '';
    this.compyn = data.compyn || '';
    this.constnm = data.constnm || '';
    this.costamt = data.costamt || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.costpernm = data.costpernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.gubun = data.gubun || '';
    this.janamt = data.janamt || '';
    this.jirogubun = data.jirogubun || '';
    this.misamt = data.misamt || '';
    this.myn = data.myn || '';
    this.offidate = data.offidate || '';
    this.pcode = data.pcode || '';
    this.perid = data.perid || '';
    this.pname = data.pname || '';
    this.pumdate = data.pumdate || '';
    this.pumflag = data.pumflag || '';
    this.pumnum = data.pumnum || '';
    this.qty = data.qty || '';
    this.rcvamt = data.rcvamt || '';
    this.reader = data.reader || '';
    this.recivstatus = data.recivstatus || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.sudate = data.sudate || '';
    this.tamt = data.tamt || '';
    this.wkactcd = data.wkactcd || '';
  }
}
