import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { PurchasingStatistics } from '../PurchasingStatistics';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { PurchasingStatisticsTabMonthGridHeader } from '../headers/PurchasingStatisticsTabMonthGridHeader';

export const PurchasingStatisticsMonthTemplate: React.FC<TemplateProps<PurchasingStatistics>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridMonth}
      header={PurchasingStatisticsTabMonthGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataMonth}
    />
  </FlexLayout>
);
