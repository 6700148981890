import * as React from 'react';
import {
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import { RiKakaoTalkFill } from 'react-icons/ri';
import {
  AddressButton,
  AttachmentBox,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  ModalView,
  OptionBox,
  SearchLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Contract,
  ContractItemChangeTypes,
} from './Contract';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import style from './Contract.module.scss';


/**
 * 화면
 * @window w_tb_e101_elec
 * @category 전자계약서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

const contgubuns = [
  { value: '01', remark: 'FM(승강기 유지관리 FM계약)' },
  { value: '02', remark: 'P.O.G(승강기 유지관리 일반계약)' },
];

const addyns = [
  { value: '1', remark: '포함' },
  { value: '0', remark: '별도' },
];

const indiv_agrees = [
  { value: '1', remark: '동의함' },
  { value: '0', remark: '동의안함' },
];

const number_agrees = [
  { value: '1', remark: '동의함' },
  { value: '0', remark: '동의안함' },
];

const cms_indiv_agrees = [
  { value: '1', remark: '신청' },
  { value: '0', remark: '미신청' },
];

const contdelivs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '사업자변경재계약' },
  { value: '03', remark: '기간만료재계약' },
  { value: '04', remark: '회수' },
];

export const ContractTemplate: React.FC<TemplateProps<Contract>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={210}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={400}>
            <span style={{ marginRight: 20 }}>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder={scope.state.data?.new === '0' ? '현장으로 검색하세요' : ''}
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout/>

          {
            scope.state.kakaoyn === '1'
              ? <Button onClick={() => scope.onKakaoButtonClicked()}>
                <FlexLayout style={{ width: 80 }}>
                  <RiKakaoTalkFill size={30}></RiKakaoTalkFill>
                  <span style={{ paddingLeft: 8 }}>전송</span>
                </FlexLayout>
              </Button>
              : null
          }

          <Button onClick={() => scope.onReport()}>
            <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'contdate',
                text: '계약일자',
                width: 15,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.contdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 30,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.total} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '상품명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 10,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      weight={2}
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 27 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={25}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'attcnt',
                text: '첨부',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: (x.attcnt) > 0 && 'var(--color-blue)' }}
                  >
                    {`${x.attcnt} 건`}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.contractList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          isVertical={true}
          isScroll={true}
          className={style.pageLayout}
          style={{
            paddingLeft: 100,
            paddingRight: 100,
          }}
        >
          <FlexLayout size={20}>
            <div className={style.titleBox}>
              승강기 유지관리 계약서
            </div>
          </FlexLayout>

          <FlexLayout size={600}>
            <table className={style.bodyTable}>
              <tbody>
              <tr>
                <th rowSpan={22}>계약내용</th>
                <th>계약일자</th>
                <td colSpan={3}>
                  <FlexLayout>
                    <DateTextBox
                      value={model?.contdate}
                      textAlign="center"
                      format="####-##-##"
                      onChange={(value) => setData({ contdate: value })}
                    />
                  </FlexLayout>
                </td>
                <th colSpan={2}>문서번호</th>
                <td colSpan={2}>
                  <FormatTextBox
                    format="##-######-###-####"
                    textAlign="center"
                    value={user.papernum + model?.appnum}
                    readonly={true}
                  />
                </td>
              </tr>
              <tr>
                <th>계약자명</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='center'
                    value={model?.pernm}
                    readonly={true}
                  />
                </td>
                <th colSpan={2}>부서명</th>
                <td colSpan={2}>
                  <TextBox
                    textAlign='center'
                    value={model?.divinm}
                    readonly={true}
                  />
                </td>
              </tr>
              <tr>
                <th>상품명</th>
                <td colSpan={7}>
                  <ComboBox
                    value={model?.contgubun}
                    data={contgubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => setData({ contgubun: option.value },
                      () => scope.itemChanged(ContractItemChangeTypes.CONTGUBUN, option.value))
                    }
                  />
                </td>
              </tr>
              <tr>
                <th style={{ color: 'var(--color-red)' }}>현장</th>
                <td>
                  <TextBox
                    value={model?.actcd}
                    textAlign="center"
                    onChange={(value) => setData({ actcd: value })}
                    onEnter={() => scope.onRetrieveEvent()}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_E601_1',
                      {},
                      true,
                      (item) => {
                        setData({
                          actcd: item.cd,
                          actnm: item.cdnm,
                        }, () => scope.itemChanged(ContractItemChangeTypes.ACTCD, item.cd));
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                </td>
                <td colSpan={6}>
                  <TextBox
                    value={model?.actnm}
                    weight={3}
                    textAlign="left"
                  />
                </td>
              </tr>
              <tr>
                <th>소재지</th>
                <td>
                  <FlexLayout>
                    <TextBox
                      textAlign="center"
                      value={model?.zipcode}
                      onChange={(value) => setData({ zipcode: value })}
                      readonly={true}
                    />
                    <AddressButton
                      publicStore={scope.props.publicStore}
                      modalStore={scope.props.modalStore}
                      isIcon={true}
                      onChange={(address) => setData({
                        zipcode: address.zonecode,
                        address: address.address,
                      })}
                    >
                      <FiArrowRightCircle size={17}/>
                    </AddressButton>
                  </FlexLayout>
                </td>
                <td colSpan={6}>
                  <TextBox
                    textAlign="left"
                    value={model?.address}
                    onChange={(value) => setData({ address: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>건물용도</th>
                <td colSpan={3}>
                  <ComboBox
                    value={model?.bildyd}
                    data={scope.state.bildyds.map((y) => new ComboBoxModel(y.buildcd, y.buildnm))}
                    onSelect={
                      (option) => setData({ bildyd: option.value },
                        () => scope.itemChanged(ContractItemChangeTypes.BILDYD, option.value))
                    }
                  />
                </td>
                <th colSpan={2}>유지관리비별구분</th>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={scope.state.contbilds.filter((y) => y.com_code === model?.contbild)[0]?.com_cnam}
                    readonly={true}
                  />
                </td>
              </tr>
              <tr>
                <th>승강기구분</th>
                <td colSpan={5}>
                  <ComboBox
                    value={model?.evercd}
                    data={scope.state.evercds.map((y) => new ComboBoxModel(y.evercd, y.evernm))}
                    onSelect={
                      (option) => setData({ evercd: option.value },
                        () => scope.itemChanged(ContractItemChangeTypes.EVERCD, option.value))
                    }
                  />
                </td>
                <td>휴일야간적용</td>
                <td>
                  <FlexLayout justify='center'>
                  <CheckBox
                    value={model?.evernight === '1'}
                    onChange={
                      (value) => setData({ evernight: value ? '1' : '0' },
                        () => scope.itemChanged(ContractItemChangeTypes.EVERNIGHT, value ? '1' : '0'))
                    }
                    isDisabledTrackingStateChange={true}
                  />
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th>표준/층수/초과</th>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.evernumber}
                    readonly={true}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.number}
                    onChange={(value) => setData({ number: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.addnumber}
                    readonly={true}
                    style={{ width: 60 }}
                  />
                </td>
                <th colSpan={2}>체증초과(표준/실)</th>
                <td>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    readonly={true}
                    value={model?.everaddamt}
                  />
                </td>
                <td>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    readonly={true}
                    color='var(--color-blue)'
                    value={model?.toteveraddamt}
                  />
                </td>
              </tr>
              <tr>
                <th>표준유지관리비</th>
                <td colSpan={3}>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    color='var(--color-blue)'
                    textAlign="right"
                    value={model?.everamt || '0'}
                    readonly={true}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.salenm}
                    readonly={true}
                  />
                </td>
                <td colSpan={2}>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    color='var(--color-red)'
                    textAlign="right"
                    value={model?.saleamt || '0'}
                    readonly={true}
                  />
                </td>
              </tr>
              <tr>
                <th>대당가</th>
                <td colSpan={2}>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    readonly={true}
                    color= {
                      // eslint-disable-next-line radix
                      ((parseInt(model?.uamt) * 100) / parseInt(model?.tot_averamt)) > 100
                        ? 'var(--color-blue)'
                        : 'var(--color-red)'
                    }
                    /* eslint-disable-next-line radix */
                    value={parseInt(model?.uamt).toFixed().toString()}
                  />
                </td>
                <td>
                  <FlexLayout
                    justify='center'
                    style={{
                      backgroundColor: '#dddddd',
                    }}>
                    <span style={{
                      // eslint-disable-next-line radix
                      color: ((parseInt(model?.uamt) * 100) / parseInt(model?.tot_averamt)) > 100
                        ? 'var(--color-blue)'
                        : 'var(--color-red)',
                    }}>
                      {
                          `${((parseInt(model?.uamt, 10) * 100) / parseInt(model?.tot_averamt, 10)).toFixed().toString()}` === 'NaN'
                            ? '%' : `${((parseInt(model?.uamt, 10) * 100) / parseInt(model?.tot_averamt, 10)).toFixed().toString()}%`
                      }
                    </span>
                  </FlexLayout>
                </td>
                <th colSpan={2}>총표준유리관리비</th>
                <td colSpan={2}>
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      color='var(--color-blue)'
                      readonly={true}
                      value={model?.tot_averamt}
                      />
                </td>
              </tr>
              <tr>
                <th>계약기간</th>
                <td colSpan={2}>
                  <FlexLayout>
                    <DateTextBox
                      value={model?.stdate}
                      textAlign="center"
                      format="####-##-##"
                      onChange={(value) => setData({ stdate: value })}
                    />
                  </FlexLayout>
                </td>
                <td style={{ textAlign: 'center' }}> ~</td>
                <td colSpan={2}>
                  <FlexLayout>
                    <DateTextBox
                      value={model?.enddate}
                      textAlign="center"
                      format="####-##-##"
                      onChange={(value) => setData({ enddate: value })}
                    />
                  </FlexLayout>
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.gigan}
                    style={{ width: 73 }}
                    readonly={true}
                  />
                </td>
                <td>
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    <DateTextBox
                      value={model?.yearflag}
                      textAlign="center"
                      format="#년"
                      onChange={(value) => setData({ yearflag: value })}
                      onBlur={(value) => scope.itemChanged(ContractItemChangeTypes.YEARFLAG, value)}
                    />
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th>부가세여부</th>
                <td colSpan={3}>
                  <OptionBox
                    value={model?.addyn}
                    data={addyns}
                    onChange={
                      (option) => setData({ addyn: option.value },
                        () => scope.itemChanged(ContractItemChangeTypes.ADDYN, option.value))
                    }
                  />
                </td>
                <th colSpan={2}>합계금액</th>
                <td colSpan={2}>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={model?.amt || '0'}
                    onChange={
                      (value) => setData({ amt: value },
                        () => scope.itemChanged(ContractItemChangeTypes.AMT, value))
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>공급가액</th>
                <td colSpan={3}>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    readonly={true}
                    textAlign="right"
                    /* eslint-disable-next-line radix */
                    value={(parseInt(model?.amt) / 1.1).toFixed().toString() || '0'}
                  />
                </td>
                <th colSpan={2}>부가세액</th>
                <td colSpan={2}>
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    readonly={true}
                    textAlign="right"
                    /* eslint-disable-next-line radix */
                    value={(parseInt(model.amt) - (parseInt(model.amt) / 1.1)).toFixed().toString() || '0'}
                  />
                </td>
              </tr>
              <tr>
                <th rowSpan={9}>계약대상<br/><br/>및<br/><br/>내역</th>
                <th colSpan={2}>기종</th>
                <th style={{ borderRight: 'none' }}/>
                <th style={{ borderLeft: 'none' }}>사양</th>
                <th>대수</th>
                <th>월정보수료</th>
                <th>비고</th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm1}
                    onChange={(value) => setData({ mdnm1: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd1}
                    onChange={(value) => setData({ sizecd1: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    style={{ width: 40 }}
                    value={model?.qty1 || ''}
                    onChange={(value) => setData({ qty1: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt1}
                    onChange={(value) => setData({ equpamt1: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm1 ? '' : model?.bigo1}
                    onChange={(value) => setData({ bigo1: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm2}
                    onChange={(value) => setData({ mdnm2: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd2}
                    onChange={(value) => setData({ sizecd2: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty2}
                    onChange={(value) => setData({ qty2: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt2}
                    onChange={(value) => setData({ equpamt2: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm2 ? '' : model?.bigo2}
                    onChange={(value) => setData({ bigo2: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm3}
                    onChange={(value) => setData({ mdnm3: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd3}
                    onChange={(value) => setData({ sizecd3: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty3}
                    onChange={(value) => setData({ qty3: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt3}
                    onChange={(value) => setData({ equpamt3: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm3 ? '' : model?.bigo3}
                    onChange={(value) => setData({ bigo3: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm4}
                    onChange={(value) => setData({ mdnm4: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd4}
                    onChange={(value) => setData({ sizecd4: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty4}
                    onChange={(value) => setData({ qty4: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt4}
                    onChange={(value) => setData({ equpamt4: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm4 ? '' : model?.bigo4}
                    onChange={(value) => setData({ bigo4: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm5}
                    onChange={(value) => setData({ mdnm5: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd5}
                    onChange={(value) => setData({ sizecd5: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty5}
                    onChange={(value) => setData({ qty5: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt5}
                    onChange={(value) => setData({ equpamt5: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm5 ? '' : model?.bigo5}
                    onChange={(value) => setData({ bigo5: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm6}
                    onChange={(value) => setData({ mdnm6: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd6}
                    onChange={(value) => setData({ sizecd6: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty6}
                    onChange={(value) => setData({ qty6: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt6}
                    onChange={(value) => setData({ equpamt6: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm6 ? '' : model?.bigo6}
                    onChange={(value) => setData({ bigo6: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm7}
                    onChange={(value) => setData({ mdnm7: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd7}
                    onChange={(value) => setData({ sizecd7: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty7}
                    onChange={(value) => setData({ qty7: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt7}
                    onChange={(value) => setData({ equpamt7: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm7 ? '' : model?.bigo7}
                    onChange={(value) => setData({ bigo7: value })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.mdnm8}
                    onChange={(value) => setData({ mdnm8: value })}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    textAlign="left"
                    value={model?.sizecd8}
                    onChange={(value) => setData({ sizecd8: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.qty8}
                    onChange={(value) => setData({ qty8: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="right"
                    value={model?.equpamt8}
                    onChange={(value) => setData({ equpamt8: value })}
                  />
                </td>
                <td>
                  <TextBox
                    textAlign="left"
                    value={!model?.mdnm8 ? '' : model?.bigo8}
                    onChange={(value) => setData({ bigo8: value })}
                  />
                </td>
              </tr>
              <tr>
                <th rowSpan={5}>지불조건</th>
                <th style={{ color: 'var(--color-red)' }}>거래처</th>
                <td>
                  <TextBox
                    textAlign="center"
                    value={model?.cltcd}
                    onChange={(value) => setData({ cltcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_XCLIENT',
                      {},
                      true,
                      (item) => {
                        setData({
                          cltcd: item.cd,
                          cltnm: item.cdnm,
                        }, () => scope.itemChanged(ContractItemChangeTypes.CLTCD, item.cd));
                      },
                    )}
                  />
                </td>
                <td colSpan={6}>
                  <TextBox
                    value={model?.cltnm}
                    onChange={(value) => setData({ cltnm: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>청구지주소</th>
                <td>
                  <FlexLayout>
                    <TextBox
                      textAlign="center"
                      value={model?.zipcd}
                      onChange={(value) => setData({ zipcd: value })}
                      readonly={true}
                    />
                    <AddressButton
                      publicStore={scope.props.publicStore}
                      modalStore={scope.props.modalStore}
                      isIcon={true}
                      onChange={(address) => setData({
                        zipcd: address.zonecode,
                        cltadres: address.address,
                      })}
                    >
                      <FiArrowRightCircle size={17}/>
                    </AddressButton>
                  </FlexLayout>
                </td>
                <td colSpan={6}>
                  <TextBox
                    textAlign="left"
                    value={model?.cltadres}
                    onChange={(value) => setData({ cltadres: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>대표자명</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='left'
                    value={model?.cltprenm}
                    onChange={(value) => setData({ cltprenm: value })}
                  />
                </td>
                <th colSpan={2}>사업자번호</th>
                <td colSpan={2}>
                  <FormatTextBox
                    textAlign="left"
                    format="###-##-#####"
                    value={model?.cltsaupnum}
                    onChange={(value) => setData({ cltsaupnum: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td colSpan={3}>
                  <TelnumTextBox
                    textAlign="left"
                    value={model?.clttelnum}
                    onChange={(value) => setData({ clttelnum: value })}
                  />
                </td>
                <th colSpan={2}>핸드폰</th>
                <td colSpan={2}>
                  <TelnumTextBox
                    textAlign="left"
                    value={model?.clthpnum}
                    onChange={(value) => setData({ clthpnum: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>세금계산서메일</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='left'
                    value={model?.clttaxmail}
                    onChange={(value) => setData({ clttaxmail: value })}
                  />
                </td>
                <th colSpan={2}>지불일</th>
                <td colSpan={2}>
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    <FormatTextBox
                      textAlign="center"
                      format="##일"
                      value={model?.dedate}
                      onChange={(value) => setData({ dedate: value })}
                    />
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th rowSpan={5}>정보수집 / 동의서</th>
                <th>개인정보</th>
                <td colSpan={3}>
                  <OptionBox
                    value={model?.indiv_agree}
                    data={indiv_agrees}
                    onChange={(item) => { setData({ indiv_agree: item.value }); }}
                    isDisabledTrackingStateChange={true}
                  />
                </td>
                <th colSpan={2}>고객식별번호</th>
                <td colSpan={2}>
                  <OptionBox
                    value={model?.number_agree}
                    data={number_agrees}
                    onChange={(item) => { setData({ number_agree: item.value }); }}
                    isDisabledTrackingStateChange={true}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ color: 'var(--color-blue)' }}>계약변경사유</th>
                <td colSpan={7}>
                  <ComboBox
                    style={{ color: 'var(--color-blue)' }}
                    value={model?.contdeliv}
                    data={contdelivs.map((y) => new ComboBoxModel(y.value, y.remark || '전체'))}
                    onSelect={(option) => setData({ contdeliv: option.value })}
                  />
                </td>
              </tr>
              <tr>
                <th>고객요구안<br/>(메모)</th>
                <td colSpan={7}>
                  <TextBox
                    textAlign='left'
                    isMultiline={true}
                    value={model?.remark}
                    onChange={(value) => setData({ remark: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>현장책임자</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='left'
                    value={model?.actpernm}
                    onChange={(value) => setData({ actpernm: value })}
                  />
                </td>
                <th colSpan={2}>CMS 신청여부</th>
                <td colSpan={2}>
                  <OptionBox
                    value={model?.cms_indiv_agree}
                    data={cms_indiv_agrees}
                    onChange={() => {}}
                    isDisabledTrackingStateChange={true}
                  />
                </td>
              </tr>
              <tr>
                <th>첨부파일</th>
                <td colSpan={3}>
                  <FlexLayout>
                    <Button
                      isIcon={true}
                      onClick={() => scope.fileModal(true)}
                      style={{ marginLeft: 5 }}
                    >
                      <RiFolder2Line size={17}/>
                    </Button>
                    <TextBox
                      color={model?.attcnt > 0 ? 'var(--color-blue)' : 'var(--color-black)'}
                      value={model?.attcnt > 0 ? `첨부파일 ${model?.attcnt}건 있음` : '첨부파일 0건 없음'}
                    />
                  </FlexLayout>
                </td>
                <th colSpan={2}>수신참조</th>
                <td colSpan={2}>
                  <FlexLayout>
                    <TextBox
                      textAlign='left'
                      value={model?.refer}
                    />
                    <Button
                      isIcon={true}
                      onClick={() => scope.openReferSelector()}
                      style={{ marginRight: 5 }}
                    >
                      <FiArrowRightCircle size={17}/>
                    </Button>
                  </FlexLayout>
                </td>
              </tr>
              </tbody>
            </table>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data?.appnum}
            appnm={'전자계약서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });
            }}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
