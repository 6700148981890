import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import style from './Login.module.scss';
import {
  RouterStore,
  PublicStore,
  MenuStore,
  ModalStore,
  ToolStore,
} from '../../../stores';
import { ImageView, TextBox } from '../../../components';
import { AuthModel } from '../../../models';
import { Sha256 } from '../../../utils/string';
import { MenuRepository } from '../../../repositories';
import { MenuParser } from '../../../utils/parser';
import { ConfirmFail } from '../../../utils/confirm';

export interface LoginProps {
  routerStore: RouterStore;
  publicStore: PublicStore;
  menuStore: MenuStore;
  modalStore: ModalStore;
  toolStore: ToolStore;
}

export interface LoginState {
  handphone: string;
  userid: string;
  userpw: string;
}

@inject('routerStore', 'publicStore', 'menuStore', 'modalStore', 'toolStore') @observer
export class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps, context: any) {
    super(props, context);

    const storage = JSON.parse(window.localStorage['last-login'] || '{}');
    this.state = {
      handphone: storage.handphone || '',
      userid: storage.userid || '',
      userpw: '',
    };
  }

  componentDidMount(): void {
    const { routerStore } = this.props;
    if (routerStore.location.pathname !== '/') {
      routerStore.replace('/');
    }

    window.addEventListener('message', this.onFrameMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onFrameMessage, false);
  }

  onFrameMessage = (e: MessageEvent<any>) => {
    if (e.origin === 'http://bug.elel.kr') {
      const { data } = e;
      this.setState({
        handphone: data.tel,
        userid: data.id,
        userpw: data.pw,
      }, () => this.tryLogin());
    }
  }

  @action
  async tryLogin() {
    const { publicStore, routerStore, modalStore } = this.props;
    try {
      if (
        await publicStore.tryLogin(
          new AuthModel(
            `${this.state.handphone}`.replace(/-/gi, ''),
            this.state.userid,
            Sha256.make(this.state.userpw),
          ),
        )
      ) {
        window.localStorage['last-login'] = JSON.stringify({
          custcd: publicStore.user.custcd,
          spjangcd: publicStore.user.spjangcd,
          spjangnm: publicStore.user.spjangnm,
          handphone: this.state.handphone,
          userid: this.state.userid,
        });

        routerStore.replace('/');

        if (!(await this.tryRetrieveMenus())) {
          publicStore.logout();
        } else {
          // 로그인 성공
          modalStore.openSelectorRetrieve();
        }
      }
    } catch (e) {
      if (e.response && e.response.data) {
        ConfirmFail.show('로그인 실패', e.response.data.message);
      } else {
        ConfirmFail.show(
          '서버 오류',
          '연결에 실패하였습니다. 엘맨소프트에 문의 바랍니다.',
        );
      }
    }
  }

  @action
  async tryRetrieveMenus(): Promise<boolean> {
    const { publicStore, menuStore, toolStore } = this.props;
    const data = await publicStore.request(async () => MenuRepository.fetch(
      await publicStore.makeParamsWithWindowName('w_xusers'),
    ));

    if (data) {
      if (!data.filter((x: any) => x.mtext === '입출금현황').length) {
        toolStore.tools = toolStore.tools.filter((x) => x.id !== 'BANK');
      }

      menuStore.menus = MenuParser.parse(data);
      publicStore.currentMenu.setPrimary(menuStore.menus[0]);
      return true;
    }

    ConfirmFail.show('서버 오류', '엘맨소프트에 문의 바랍니다.');
    return false;
  }

  render() {
    const storage = JSON.parse(window.localStorage['last-login'] || '{}');
    const custcd = storage.custcd || 'dmyong_g';
    const spjangcd = storage.spjangcd || '10';
    const spjangnm = storage.spjangnm || '고객';

    return (
      <div className={style.container}>
        <div className={style.login__box}>
          <div className={style.logo}>
            <div className={style.logo__container}>
              <ImageView
                src={`https://api.elmansoft.com/etc/getCustImage.php?database=${custcd}&custcd=${custcd}&spjangcd=${spjangcd}`}
              />
            </div>
          </div>
          <div className={style.logo2}>
            <div className={style.logo2__container2}>
              <ImageView
                src={`https://api.elmansoft.com/etc/getCustImage.php?database=${custcd}&custcd=${custcd}&spjangcd=${spjangcd}`}
              />
            </div>
          </div>
          <div className={style.title}>Sign in</div>
          {spjangnm && <div className={style.remark}>{spjangnm}</div>}
          <div className={style.form}>
            <div className={style.form__input}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="#74899F"
                >
                  {/* eslint-disable-next-line max-len */}
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.445 17.827c-3.684 1.684-9.401-9.43-5.8-11.308l1.053-.519 1.746 3.409-1.042.513c-1.095.587 1.185 5.04 2.305 4.497l1.032-.505 1.76 3.397-1.054.516z" />
                </svg>
                <TextBox
                  placeholder="휴대전화"
                  value={this.state.handphone}
                  onChange={(handphone) => this.setState({ handphone })}
                />
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="#74899F"
                >
                  {/* eslint-disable-next-line max-len */}
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" />
                </svg>
                <TextBox
                  placeholder="사원코드"
                  value={this.state.userid}
                  onChange={(userid) => this.setState({ userid })}
                />
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="#74899F"
                >
                  {/* eslint-disable-next-line max-len */}
                  <path d="M16.625 8.292c0 .506-.41.917-.917.917s-.916-.411-.916-.917.409-.917.916-.917.917.411.917.917zm7.375 3.708c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-11.293 1.946c-1.142-.436-2.065-1.312-2.561-2.423l-3.146 3.185v2.292h3v-1h1v-1h.672l1.035-1.054zm5.293-4.279c0-2.025-1.642-3.667-3.667-3.667-2.024 0-3.666 1.642-3.666 3.667s1.642 3.667 3.666 3.667c2.025-.001 3.667-1.643 3.667-3.667z" />
                </svg>
                <TextBox
                  placeholder="비밀번호"
                  type="password"
                  value={this.state.userpw}
                  onChange={(userpw) => this.setState({ userpw })}
                  onEnter={() => this.tryLogin()}
                />
              </div>
            </div>
            <div
              className={style.form__btn_login}
              onClick={() => this.tryLogin()}
            >
              로그인
            </div>
          </div>
        </div>
      </div>
    );
  }
}
