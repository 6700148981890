import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  DateTextBox,
  FlexLayout,
  SearchLayout,
  TableLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { RegularBuy } from './RegularBuy';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';

const gubuns = [
  { value: '01', remark: '매입세금계산서' },
  { value: '02', remark: '매입계산서' },
  { value: '03', remark: '매입카드' },
  { value: '04', remark: '기타' },
  { value: '05', remark: '수정세금계산서' },
];

/**
 * 화면
 * @window w_tb_ca640_all
 * @category 정기매입자료생성
 */
export const RegularBuyTemplate: React.FC<TemplateProps<RegularBuy>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>월</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => {
              setData({ yearmon: value },
                () => scope.onRetrieveEvent());
            }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({
                  yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({
                  yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onBtnEvent()}>
          정기지출자료생성
        </Button>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={scope.state.pushTotalCheck}
                  onChange={async (value) => scope
                    .updateCheckAllToggle(value)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
            width: 50,
            render: (x, rowUpdate) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={x.chk === '1'}
                  onChange={(value) => {
                    x.flag === '지급' ? '' : rowUpdate({ chk: value ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'flag',
            text: '구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.flag === '매입' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.flag}
              </FlexLayout>
            ),
          },
          {
            id: 'mijdate',
            text: '매입일자',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.mijdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'mijnum',
            text: '번호',
            width: 80,
          },
          {
            id: 'remark',
            text: '제목',
            width: 200,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                건수 {scope.state.total}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'cltnm',
            text: '거래처명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.cltnm}
              </FlexLayout>
            ),
          },
          {
            id: 'billkind',
            text: '부가세여부',
            width: 80,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.billkind === '1' ? '포함' : '별도'}
              </FlexLayout>
            ),
          },
          {
            id: 'samt',
            text: '금액',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.samt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.samt)}
              </FlexLayout>
            ),
          },
          {
            id: 'tamt',
            text: '부가세액',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.tamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.tamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'mijamt',
            text: '매입금액',
            width: 100,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state.mijamt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.mijamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '증빙구분',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'artnm',
            text: '항목구분',
            width: 100,
          },
          {
            id: 'acccd',
            text: '계정과목',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope?.state.acccds?.filter((y) => y.acccd === x.acccd)[0]?.accnm}
              </FlexLayout>
            ),
          },
          {
            id: 'divinm',
            text: '귀속부서',
            width: 100,
          },
          {
            id: 'jsflag',
            text: '자산구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.jsflag === 'IG' ? '일반' : '고정자산'}
              </FlexLayout>
            ),
          },
          {
            id: 'bhflag',
            text: '발행구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.bhflag === 'OD' ? '전자' : '수기'}
              </FlexLayout>
            ),
          },
          {
            id: 'osflag',
            text: '영세율',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.osflag === '213' ? '해당' : '비해당'}
              </FlexLayout>
            ),
          },
          {
            id: 'cdflag',
            text: '내수구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.cdflag === '1' ? '수입' : '내수'}
              </FlexLayout>
            ),
          },
          {
            id: 'taxrenm',
            text: '세액공제',
            width: 100,
          },
          {
            id: 'bigo',
            text: '비고',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.bigo}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      />
    </FlexLayout>
  );
};
