import * as React from 'react';
import {
  Button,
  CheckBox,
  DragAndDropLayout,
  FlexLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentMaterialsTableHeader } from '../headers/EnrollmentMaterialslTableHeader';

/**
 * 화면 > 탭 - 부품내역등록
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentTabMaterialsTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <FlexLayout />
      <span style={{
        textAlign: 'center',
        marginTop: 6,
        fontSize: 12,
      }}>
           금액수동계산
        </span>
      <CheckBox
        value={scope.state.useCalc === '1'}
        onChange={(value) => scope.setState({ useCalc: value ? '1' : '0' })}
      />

      <Button onClick={() => scope.onClickMaterialsNew()}>추가</Button>
      <Button onClick={() => scope.onClickMaterialsDelete()}>삭제</Button>
    </FlexLayout>

    <DragAndDropLayout
      ref={scope.tableMaterials}
      infinityHandler={scope.infinityMaterials}
      header={EnrollmentMaterialsTableHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.materials}
      onRowFocusEvent={(item) => scope.onTableMaterialsRowFocusEvent(item)}
      onChange={(rows) => scope.onChangeTableMaterials(rows)}
    />
  </FlexLayout>
);
