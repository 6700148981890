import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Issue } from './Issue';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_pa100
 * @category 제증명발행
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const IssueTemplate: React.FC<TemplateProps<Issue>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              size={200}
              value={scope.state.searchQuery}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={(value) => setData({ searchQuery: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.searchQuery}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ searchQuery: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.searchQuery}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ searchQuery: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout>
          </FlexLayout>
          <Button
            onClick={() => scope.copyModal(true)}>문서복사</Button>
          <Button
            disabled={scope.state.isReported && scope.state.focused?.appgubun !== '001'}
            onClick={() => scope.onReport()}>{scope.state.isReported ? '상신취소' : '결재상신'}</Button>
        </SearchLayout>
      </FlexLayout>

        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'com_code',
              text: '결재',
              width: 10,
              render: (x) => (
              <FlexLayout
                align="center"
                justify="center"
                style={
                x.appgubun === '101' ? { color: 'var(--color-blue)' }
                  : x.appgubun === '131' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }
              }
              >
                {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
              </FlexLayout>
              ),
            },
            {
              id: 'flag',
              text: '구분',
              width: 20,
              render: (x, rowUpdate) => (
                <ComboBox
                  textAlign="center"
                  value={x.flag || 'A'}
                  data={[
                    { value: 'A', remark: '재직증명서' },
                    { value: 'B', remark: '경력증명서' },
                    { value: 'C', remark: '퇴직증명서' },
                    { value: 'D', remark: '위임장' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ flag: option.value })}
                />
              ),
            },
            {
              id: 'num',
              text: '호수',
              width: 15,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.num}
                  readonly={true}
                />
              ),
            },
            {
              id: 'perid',
              text: '사원코드',
              width: 20,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  readonly={x.appgubun === '101' || x.appgubun === '111'}
                  value={x.perid}
                  onChange={(value) => rowUpdate({ perid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        perid: item.cd,
                        pernm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'pernm',
              text: '사원명',
              width: 20,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'reqdt',
              text: '신청일',
              width: 20,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  textAlign="center"
                  value={x.reqdt}
                  onChange={(value) => rowUpdate({ reqdt: value })}
                />
              ),
            },
            {
              id: 'makedt',
              text: '발행일',
              width: 20,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  textAlign="center"
                  value={x.makedt}
                  onChange={(value) => rowUpdate({ makedt: value })}
                />
              ),
            },
            {
              id: 'makenum',
              text: '발행부수',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  value={x.makenum}
                  onChange={(value) => rowUpdate({ makenum: value })}
                />
              ),
            },
            {
              id: 'suse',
              text: '용도/제출처',
              width: 40,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.suse}
                  onChange={(value) => rowUpdate({ suse: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '내용',
              width: 40,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
            {
              id: 'stamp',
              text: '직인표시',
              width: 10,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.stamp === '1'}
                  onChange={(value) => {
                    rowUpdate({ stamp: value ? '1' : '0' });
                    scope.onItemChange('stamp', value);
                  }}
                />
              ),
            },
            {
              id: 'rnumflag',
              text: '번호표시',
              width: 10,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.rnumflag === '1'}
                  onChange={(value) => {
                    rowUpdate({ rnumflag: value ? '1' : '0' });
                    scope.onItemChange('rnumflag', value);
                  }}
                />
              ),
            },
            {
              id: 'appnum',
              text: '문서번호',
              width: 30,

              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  value={user.papernum + x.appnum}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ appnum: value })}
                  format="###############"
                />
              ),
            },
            {
              id: 'apppernm',
              text: '작성자',
              width: 20,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  value={x.apppernm}
                  onChange={(value) => rowUpdate({ apppernm: value })}
                />
              ),
            },
          ]}
          data={scope.state.data}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />
      <ModalView
        width={300}
        height={100}
        isVisible={scope.state.copyDetailModal}
        onClose={() => scope.copyModal(true)}
      >
        <FlexLayout style={{ padding: 8 }}>
          <FlexLayout isVertical={true}>
            <LayoutTitle weight={1}>
              몇 부 복사하시겠습니까?
            </LayoutTitle>
            <FlexLayout
            >
              <TextBox
                value={scope.state.bu}
                textAlign="right"
                onChange={(value) => scope.setState({ bu: value })}
              />
              <LabelBox style={{ textAlign: 'left' }}>
                부
              </LabelBox>
            </FlexLayout>
            <FlexLayout>
              <FlexLayout>
                <Button
                  style={{
                    height: '25px',
                    width: '80px',
                    marginTop: 10,
                  }}
                  onClick={() => scope.onCopy()}
                >
                  복사
                </Button>
              </FlexLayout>
             <FlexLayout justify="right">
               <Button
                 style={{
                   height: '25px',
                   width: '80px',
                   marginTop: 10,
                 }}
                 theme={BUTTON_THEMES.RED}
                 onClick={() => scope.copyModal(false)}
               >
                 취소
               </Button>
             </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
