import * as React from 'react';
import {
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  ImageView,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  PAPERCD,
  TemplateProps,
} from '../../../../constants';
import { Meeting } from './Meeting';
import { SearchBindingProjects } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import style from './Meeting.module.scss';

/**
 * 화면
 * @window w_tb_pb407
 * @category 회의록
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const MeetingTemplate: React.FC<TemplateProps<Meeting>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={350}>
          <span style={{ color: 'var(--color-red)' }}>프로젝트</span>
          <TextBox
            textAlign="center"
            value={scope.state.projno}
            onChange={(value) => scope.setState({ projno: value })}
            bindSearchModal={new SearchBindingProjects(
              scope.props.modalStore,
              {},
              true,
              (item) => {
                scope.setState({
                  projno: item.projno,
                  projnm: item.projnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
          />
          <TextBox
            weight={2}
            value={scope.state.projnm}
            onChange={(value) => scope.setState({ projnm: value })}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={350}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '제목으로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 80 }}>문서복사</div>
        </Button>

        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 80 }}> {scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>
    </FlexLayout>


    <FlexLayout>
      <FlexLayout
        isVertical={true}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'appgubun',
              text: '상태',
              width: 10,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={
                      () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                    }
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'paperdate',
              text: '일자',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.paperdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'title',
              text: '제목',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.title}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '작성자',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'attcnt',
              text: '첨부',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: (x.attcnt) > 0 && 'var(--color-blue)' }}
                >
                  {`${x.attcnt} 건`}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.meetingList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
      </FlexLayout>
      <FlexLayout
        isVertical={true}
        isScroll={true}
        className={style.pageLayout}
        style={{
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
          <table className={style.headerTable}>
            <tbody>
              <tr>
                <td rowSpan={4}>
                  <ImageView
                    src={`https://api.elmansoft.com/etc/getCustImage.php?database=${scope.state.custcd}&custcd=${scope.state.custcd}&spjangcd=${scope.state.spjangcd}`}
                  />
                </td>
                <td
                  colSpan={2}
                  rowSpan={4}
                >
                  <div style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}>
                    회의록
                  </div>
                </td>
                <th>문서분류</th>
                <td>{`${PAPERCD.MEETING}/회의록`}</td>
              </tr>
              <tr>
                <th>페이지번호</th>
                <td>
                  {`${scope.state.data.page}/${scope.state.total}페이지`}
                </td>
              </tr>
              <tr>
                <th>작성자</th>
                <td>{model?.pernm}</td>
              </tr>
              <tr>
                <th>작성일자</th>
                <td>
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={model?.paperdate}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </FlexLayout>
        <FlexLayout
          style={{ marginTop: 10 }}
          size={850}
        >
          <table className={style.bodyTable}>
            <tbody>
              <tr>
                <th>문서번호</th>
                <td>
                  <FormatTextBox
                    format="##-######-###-####"
                    textAlign="center"
                    value={user.papernum + model?.appnum}
                  />
                </td>
                <th>직위</th>
                <td>
                  <TextBox
                    textAlign='center'
                    value={model?.rspnm}
                  />
                </td>
              </tr>
              <tr>
                <th>부서</th>
                <td>
                  <TextBox
                    textAlign='center'
                    value={model?.divinm}
                  />
                </td>
                <th>성명</th>
                <td>
                  <TextBox
                    textAlign='center'
                    value={model?.pernm}
                  />
                </td>
              </tr>
              <tr>
                <th>일자</th>
                <td>
                  <FlexLayout>
                    {
                      scope.state.data.isNew
                        ? <DateTextBox
                          value={model?.paperdate}
                          textAlign="center"
                          format="####-##-##"
                          onChange={(value) => setData({ paperdate: value })}
                        />
                        : <DateTextBox
                          value={model?.paperdate}
                          textAlign="center"
                          format="####-##-##"
                        />
                    }
                  </FlexLayout>
                </td>
                <th>장소</th>
                <td>
                  <TextBox
                    textAlign='left'
                    value={model?.place}
                    onChange={(value) => setData({ place: value })}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ color: 'var(--color-blue)' }}>프로젝트</th>
                <td colSpan={1}>
                  <TextBox
                    textAlign="center"
                    value={model?.projno}
                    onChange={(value) => setData({ projno: value })}
                    bindSearchModal={new SearchBindingProjects(
                      scope.props.modalStore,
                      {},
                      true,
                      (item) => {
                        setData({
                          projno: item.projno,
                          projnm: item.projnm,
                        });
                      },
                    )}
                  />
                </td>
                <td colSpan={2}>
                  <TextBox
                    weight={3}
                    value={model?.projnm}
                  />
                </td>
              </tr>
              <tr>
                <th>주제(제목)</th>
                <td colSpan={3}>
                  <TextBox
                    textAlign='left'
                    value={model?.title}
                    onChange={(value) => setData({ title: value })}
                  />
                </td>
              </tr>
              <tr>
                <th>수신참조</th>
                <td colSpan={3}>
                  <FlexLayout>
                    <TextBox
                      textAlign='left'
                      value={model?.refer}
                    />
                    <Button
                      isIcon={true}
                      onClick={() => scope.openReferSelector()}
                      style={{ marginRight: 5 }}
                    >
                      <FiArrowRightCircle size={17}/>
                    </Button>
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th>첨부파일</th>
                <td colSpan={3}>
                  <FlexLayout>
                    <Button
                      isIcon={true}
                      onClick={() => scope.fileModal(true)}
                      style={{ marginLeft: 5 }}
                    >
                      <RiFolder2Line size={17}/>
                    </Button>
                    <TextBox
                      style={{ color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                      value={model?.attcnt === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                    />
                  </FlexLayout>
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  style={{ textAlign: 'center' }}
                >
                  내용
                </th>
              </tr>
              <tr style={{ height: 500 }}>
                <td colSpan={4}>
                  <TextBox
                    isMultiline={true}
                    textAlign='left'
                    value={model?.remark}
                    onChange={(value) => setData({ remark: value })}
                  />
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  style={{ textAlign: 'center' }}
                >
                  결재자 지시사항
                </th>
              </tr>
              <tr style={{ height: 100 }}>
                <td
                  colSpan={4}
                >
                  <TextBox
                    isMultiline={true}
                    textAlign='left'
                    value={model?.appremark}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_GRID_HEIGHT_1}
          style={{ fontSize: 14 }}
          justify='right'
          align='center'
        >
          {scope.state.spjangnm}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>

    <ModalView
      isVisible={scope.state.fileDetailModal}
      onClose={() => scope.fileModal(false)}
      width={1300}
    >
      <FlexLayout style={{ padding: 8 }}>
        <AttachmentBox
          isEnabledPreview={true}
          appnum={scope.state.data?.appnum}
          appnm={'회의록'}
          onChangeCount={async (count) => {
            // state
            setData({ attcnt: count.toString() });
          }}
        />
      </FlexLayout>
    </ModalView>
  </FlexLayout>
  );
};
