import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e611_035_03
 * @category 호기별점검항목등록
 */
export class UnitDetailModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly evcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly gresultcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly resultcd: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly resultnm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly resultdtlnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly evmon: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly sel_chk_item_cd: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.equpcd = data.equpcd || '';
    this.evcd = data.evcd || '';
    this.gresultcd = data.gresultcd || '';
    this.resultcd = data.resultcd || '';
    this.seq = data.seq || '';
    this.resultnm = data.resultnm || '';
    this.resultdtlnm = data.resultdtlnm || '';
    this.evmon = data.evmon || '';
    this.state = data.state || '';
    this.remark = data.remark || '';
    this.gubun = data.gubun || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.sel_chk_item_cd = data.sel_chk_item_cd || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
