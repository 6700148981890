import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Status } from '../Status';
import { StatusGridDivicdHeader } from '../headers/StatusGridDivicdHeader';

/**
 * 화면 > 탭 A
 * @window w_tb_e471w
 * @category 공사현황
 */
export const StatusTabDivicdTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridDivicd}
      infinityHandler={scope.infinityDivicd}
      header={StatusGridDivicdHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataDivicd}
      onRowClick={(item, index) => scope.onDivicdRowFocusEvent(item, index)}
    />
  </FlexLayout>
);
