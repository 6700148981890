import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601w_sulchi_11
 * @category 설치 월별 수입/지출 현황
 */
export class OrderMonthlyModalModel {
  /**
   * 현장코드
   *
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   */
  readonly actnm: string;

  /**
   * 월
   *
   */
  readonly compamt: string;

  /**
   * 월
   *
   */
  readonly compdate: string;

  /**
   * 월
   *
   */
  readonly compmon: string;

  /**
   * 월
   *
   */
  readonly contdate: string;

  /**
   * 월
   *
   */
  readonly custcd: string;

  /**
   * 월
   *
   */
  readonly enddate: string;

  /**
   * 월
   *
   */
  readonly gubun: string;

  /**
   * 월
   *
   */
  readonly misdate: string;

  /**
   * 월
   *
   */
  readonly misflag: string;

  /**
   * 월
   *
   */
  readonly notamt: string;

  /**
   * 계약자 코드
   *
   */
  readonly pperid: string;

  /**
   * 계약자명
   *
   */
  readonly ppernm: string;

  /**
   * 월
   *
   */
  readonly qty: string;

  /**
   * 월
   *
   */
  readonly rcvamt: string;

  /**
   * 월
   *
   */
  readonly rcvdate: string;

  /**
   * 월
   *
   */
  readonly rcvexdate: string;

  /**
   * 월
   *
   */
  readonly rcvexflag: string;

  /**
   * 월
   *
   */
  readonly rcvmon: string;

  /**
   * 월
   *
   */
  readonly spjangcd: string;

  /**
   * 발생일자
   *
   */
  readonly mijdate: string;

  /**
   * 번호
   *
   */
  readonly mijnum: string;

  /**
   * 항목명
   *
   */
  readonly artnm: string;

  /**
   * 적요
   *
   */
  readonly remark: string;

  /**
   * 발생금액
   *
   */
  readonly mijamt: string;

  /**
   * 지급금액
   *
   */
  readonly sndamt: string;

  /**
   * 미지급금액
   *
   */
  readonly iamt: string;

  /**
   * 거래처명
   *
   */
  readonly cltnm: string;

  /**
   * 프로젝트명
   *
   */
  readonly projnm: string;

  /**
   * 신규 new
   *
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.compamt = data.compamt || '';
    this.compdate = data.compdate || '';
    this.compmon = data.compmon || '';
    this.contdate = data.contdate || '';
    this.custcd = data.custcd || '';
    this.enddate = data.enddate || '';
    this.gubun = data.gubun || '';
    this.misdate = data.misdate || '';
    this.misflag = data.misflag || '';
    this.notamt = data.notamt || '';
    this.pperid = data.pperid || '';
    this.ppernm = data.ppernm || '';
    this.qty = data.qty || '';
    this.rcvamt = data.rcvamt || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvexdate = data.rcvexdate || '';
    this.rcvexflag = data.rcvexflag || '';
    this.rcvmon = data.rcvmon || '';
    this.spjangcd = data.spjangcd || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.artnm = data.artnm || '';
    this.remark = data.remark || '';
    this.mijamt = data.mijamt || '';
    this.sndamt = data.sndamt || '';
    this.iamt = data.iamt || '';
    this.cltnm = data.cltnm || '';
    this.projnm = data.projnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
