import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_pb401
 * @category 출장보고서
 */

export class TripPopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly outdate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly outnum: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly seq: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.outdate = data.outdate || '';
    this.outnum = data.outnum || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
