import * as React from 'react';
import {
  FlexLayout, GridLayout, SearchLayout, TableLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Status } from './Status';
import { StausGridHeader } from './headers/StausGridHeader';
import { SearchDateRange } from '../../../../components/search';
import { SearchBinding, SearchBindingProjects } from '../../../../models';
import { StatusContentTableHeader } from './headers/StatusContentTableHeader';

/**
 * 화면
 * @window w_preview_da006
 * @category 수주현황
 */
export const StatusTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            value={scope.state.divinm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span
            style={{
              color: 'var(--color-red)',
              paddingRight: 3,
            }}
          >
            담당자
          </span>

          <TextBox
            size={82}
            textAlign="center"
            style={{ marginLeft: 10 }}
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>거래처</span>
          <TextBox
            textAlign="center"
            value={scope.state.cltcd}
            onChange={(value) => setData({ cltcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_XCLIENT',
              {},
              true,
              (item) => {
                setData({
                  cltcd: item.cd,
                  cltnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            weight={2}
            value={scope.state.cltnm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>현장</span>
          <TextBox
            textAlign="center"
            style={{ marginLeft: 10 }}
            value={scope.state.actcd}
            onChange={(value) => setData({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setData({
                  actcd: item.cd,
                  actnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            weight={2}
            value={scope.state.actnm}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={685}>
          <span style={{ paddingRight: 10 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="제목, 특기사항으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>프로젝트</span>

          <TextBox
            size={82}
            textAlign="center"
            value={scope.state.projno}
            onChange={(value) => setData({ projno: value })}
            bindSearchModal={new SearchBindingProjects(
              scope.props.modalStore,
              {},
              true,
              (item) => {
                setData({
                  projno: item.projno,
                  projnm: item.projnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />

          <TextBox
            value={scope.state.projnm}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout size={200}>
          <TableLayout
            ref={scope.table}
            header={StatusContentTableHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.option}
            onChange={(rows) => scope.SS({ option: rows })}
          />
        </FlexLayout>

        <FlexLayout>
          <GridLayout
            header={StausGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            data={scope.state.data}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
