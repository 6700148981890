import { action } from 'mobx';
import React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { InventoryMovementTemplate } from './InventoryMovement.template';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models';
import { InventoryMovementModel } from './models/InventoryMovement.model';
import { GridLayout } from '../../../../components';
import { BarcodeDetailModel } from './models/BarcodeDetail.model';
import { BarcodeModel } from './models/Barcode.model';
import { ConfirmWarning } from '../../../../utils/confirm';

interface InventoryMovementState {
  // 조회 조건
  year: string;
  searchQuery: string;
  store: string;
  storenm: string;

  // 맨 왼쪽 List
  productList: Array<InventoryMovementModel>;

  // 왼쪽 trail
  cnt_tot: string;

  // 가운데 List
  barcodeData: Array<BarcodeModel>;

  // 가운데 trail
  barcode_cnt_tot: string;
  qty_tot: string;

  // 오른쪽 List
  barcodeDataDetail: Array<BarcodeDetailModel>
}

/**
 * 컨트롤러
 * @window w_tb_ca602w_02
 * @category 재고이동내역
 */
export class InventoryMovement extends PageComponent<PageProps, InventoryMovementState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  grid3: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 조회조건
      searchQuery: '',
      year: Today.year(),
      store: '',
      storenm: '',

      // 맨 왼쪽 List
      productList: [],

      // 왼쪽 trail
      cnt_tot: '',

      // 중간 List
      barcodeData: [],

      // 가운데 trail
      barcode_cnt_tot: [],
      qty_tot: '',

      // 오른쪽 List
      barcodeDataDetail: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        store: this.state.store,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          productList: [...this.state.productList, ...items.map((item) => new InventoryMovementModel(item))],
        });
      },
      async () => {
        await this.SS({
          productList: [],
        });
        this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 조회 버튼 클릭 시 기존 객체 초기화
    await this.setState({
      productList: [],
      barcodeData: [],
      barcodeDataDetail: [],
    });

    // retrieve 시도
    await this.infinity?.retrieveAll();

    // trail setState
    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot || '0',
    });

    // 조회 시 rowfocus 상단
    await this.grid.current?.setFocus(0);
    await this.grid.current?.forceRepaint(true);
  }

  @action
  async onRowFocusEvent(item: InventoryMovementModel) {
    const { actionStore: api } = this.props;

    // 왼쪽 List로 가져온 item으로 Rowfocus
    // Rowfocus한 data setState
    const data = await api.fxExec(
      'dw_1_RowFocuschanged',
      {
        year: this.state.year,
        store: this.state.store,
        pcode: item?.pcode,
      },
    );

    // barcodeData -> 중간 화면 전체 List
    // barcode_cnt_tot -> trail 합계
    await this.SS({
      barcodeData: data?.items?.map((x: any) => new BarcodeModel(x)) || [],
      barcode_cnt_tot: data?.cnt_tot || '0',
      qty_tot: data?.qty_tot || '0',
    });

    // 다른 List Rowfocus 시 가운데 List는 Focus 맨위로
    await this.grid2.current?.setFocus(0);
    await this.grid2.current?.forceRepaint(true);
  }

  @action
  async onRowFocusEvent2(item: BarcodeModel) {
    const { actionStore: api } = this.props;

    // 가운데 List로 가져온 item으로 Rowfocus
    // Rowfocus한 data setState
    const data = await api.fxExec(
      'dw_2_RowFocuschanged',
      {
        year: this.state.year,
        store: this.state.store,
        pcode: item?.pcode,
        num: item?.num,
      },
    );

    // barcodeDataDetail -> 맨 오른쪽 화면 전체 List
    await this.SS({
      barcodeDataDetail: data?.items?.map((x: any) => new BarcodeDetailModel(x)) || [],
    });

    await this.grid3.current?.setFocus(0);
    await this.grid3.current?.forceRepaint(true);
  }

  @action
  async onPrintEvent() {
    ConfirmWarning.show('경고', '아직 준비중입니다...');
  }

  @action
  async onExcelEvent() {
    ConfirmWarning.show('경고', '아직 준비중입니다...');
  }

  render() {
    return (
      <InventoryMovementTemplate
        scope={this}
      >
      </InventoryMovementTemplate>
    );
  }
}
