import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { BreakPartModel } from '../models';
import { BreakAnalysisAll } from '../BreakAnalysisAll';
import { TextBox } from '../../../../../components';
import { Sum } from '../../../../../utils/array';
import Format from '../../../../../utils/string/Format';

// 고장부위별
const Part: GridLayoutHeader<BreakPartModel, BreakAnalysisAll>[] = [
  {
    id: 'greginm',
    text: '고장부위',
    width: 100,
    group: true,
    value: (x) => x.greginm,
    sum: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.greginm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.greginm}
      </FlexLayout>
    ),
  },
  {
    id: 'reginm',
    text: '고장부위상세',
    width: 150,
    render: (x) => (
      <TextBox
        textAlign="left"
        value={x.reginm}
      />
    ),
  },
  {
    id: 'cnt',
    text: '고장건수',
    width: 40,
    sum: (x, data) => {
      const sumTot = Sum.groupLength(data, 'tot', x, ['greginm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumTot)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state?.tot_tot}건
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.tot}
      />
    ),
  },
];

export default Part;
