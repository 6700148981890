import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { PurchaseState } from './PurchaseState';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';

const gubuns = [
  { value: '01', remark: '매입세금계산서' },
  { value: '02', remark: '매입계산서' },
  { value: '03', remark: '매입카드' },
  { value: '04', remark: '기타' },
  { value: '05', remark: '수정세금계산서' },
];

/**
 * 화면
 * @window w_tb_ca640w
 * @category 매입현황
 */
export const PurchaseStateTemplate: React.FC<TemplateProps<PurchaseState>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout isVertical={true}>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout
            size={200}
            style={{ marginLeft: 2 }}
          >
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 11 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>사업장</span>
            <ComboBox
              value={scope.state.spjangcd}
              data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
              onSelect={(option) => scope.setState({ spjangcd: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout
            size={300}
            style={{ marginLeft: 51 }}
          >
            <span style={{ color: 'var(--color-red)' }}>항목</span>
            <TextBox
              textAlign="center"
              value={scope.state.artcd}
              onChange={(value) => setData({ artcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_CA648_ARG',
                {},
                true,
                (item) => {
                  setData({
                    artcd: item.cd,
                    artnm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.artnm}
              readonly={true}
              onChange={(value) => setData({ artnm: value })}
            />
          </FlexLayout>

          <FlexLayout size={230}>
            <span>경비증빙구분</span>
            <ComboBox
              value={scope.state.proof}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '미증빙' },
                { value: '1', remark: '증빙' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ proof: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>

        <SearchLayout
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
          isSub={true}
        >
          <FlexLayout size={178}>
            <span>증빙구분</span>
            <ComboBox
              value={scope.state.gubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '01', remark: '매입세금계산서' },
                { value: '02', remark: '매입계산서' },
                { value: '03', remark: '매입카드' },
                { value: '04', remark: '기타' },
                { value: '05', remark: '수정세금계산서' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ gubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span>발행구분</span>
            <ComboBox
              value={scope.state.bhflag}
              data={[
                { value: '%', remark: '전체' },
                { value: 'OD', remark: '전자' },
                { value: 'AS', remark: '수기' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ bhflag: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout
            style={{ marginLeft: 13 }}
            size={311}
          >
            <span>매입구분</span>
            <ComboBox
              value={scope.state.wkgubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '보수' },
                { value: '1', remark: '설치' },
                { value: '2', remark: '제조' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ wkgubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout
            size={325}
            style={{ marginLeft: 26 }}
          >
            <span style={{ color: 'var(--color-red)' }}>세액공제</span>
            <TextBox
              textAlign="center"
              value={scope.state.taxreclafi}
              onChange={(value) => setData({ taxreclafi: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    taxreclafi: item.cd,
                    taxrenm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.taxrenm}
              readonly={true}
              onChange={(value) => setData({ taxrenm: value })}
            />
          </FlexLayout>

          <FlexLayout size={230}>
            <span>이월금액포함</span>
            <ComboBox
              value={scope.state.beamtflag}
              data={[
                { value: '1', remark: '포함' },
                { value: '0', remark: '미포함' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ beamtflag: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>

        <SearchLayout
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
          isSub={true}
        >
          <FlexLayout
            style={{ marginLeft: 14 }}
            size={344}
          >
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="거래처, 적요로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout
            style={{ marginLeft: 38 }}
            size={286}
          >
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              textAlign="center"
              value={scope.state.divicd}
              onChange={(value) => setData({ divicd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.divinm}
              readonly={true}
              onChange={(value) => setData({ divinm: value })}
            />
          </FlexLayout>

          <FlexLayout
            size={556}
            style={{ marginLeft: 25 }}
          >
            <span style={{ color: 'var(--color-red)' }}>프로젝트</span>
            <TextBox
              textAlign="center"
              value={scope.state.projno}
              onChange={(value) => setData({ projno: value })}
              bindSearchModal={new SearchBindingProjects(
                scope.props.modalStore,
                {},
                true,
                (item) => {
                  setData({
                    projno: item.projno,
                    projnm: item.projnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.projnm}
              onChange={(value) => setData({ projnm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <FlexLayout size={200}>
            <TableLayout
              ref={scope.table}
              data={scope.state.leftData}
              header={[
                {
                  id: 'cnam',
                  text: '비용항목그룹',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'chk',
                  text: (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={scope.state.allChk}
                        onChange={(value) => scope.onCehckAll(value)}
                        isDisabledTrackingStateChange={true}
                      >
                      </CheckBox>
                    </FlexLayout>
                  ),
                  width: 60,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.chk === '1'}
                        onChange={(value) => {
                          rowUpdate({ chk: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      >
                      </CheckBox>
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
            >
            </TableLayout>
          </FlexLayout>

          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'mijdate',
                text: '발생일자',
                width: 100,
                trail: (_) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'artnm',
                text: '항목명',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.artnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                trail: (_) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    건수: {scope.state.total}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '이월미지급',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {scope.state.beamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'samt',
                text: '공급가액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.samt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.samt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tamt',
                text: '부가세',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.tamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.tamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '발생금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mijamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '지급금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.iamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'resuamt_tot',
                text: '미지급금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {scope.state.resuamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.resuamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '증빙구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'proof',
                text: '경비증빙',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.proof === '1' ? '증빙' : '미증빙'}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '귀속부서',
                width: 100,
              },
              {
                id: 'taxrenm',
                text: '세액공제',
                width: 100,
              },
              {
                id: 'bhflag',
                text: '발행구분',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.bhflag === 'OD' ? '전자' : '수기'}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
