import { computed } from 'mobx';

/**
 * 메인 모델
 * @window w_tb_e101w_06
 * @category 보수수주매출통계
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 수주금액
   *
   * **data type** number
   */
  contamt: string;

  /**
   * 평균보수료
   *
   * **data type** number
   */
  averamt: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 신규
   *
   * **data type** number
   */
  qty_new: string;

  /**
   * 해지
   *
   * **data type** number
   */
  qty_cls: string;

  /**
   * FM대수
   *
   * **data type** number
   */
  qty_fm: string;

  /**
   * 장기대수
   *
   * **data type** number
   */
  qty_long: string;

  /**
   * 일반대수
   *
   * **data type** number
   */
  qty_pog: string;

  /**
   * 잔액(수주-입금)
   *
   * **data type** number
   */
  jamt: string;

  /**
   * 매출
   *
   * **data type** number
   */
  misamt2: string;

  /**
   * 입금
   *
   * **data type** number
   */
  rcvamt2: string;

  /**
   * 미수금
   *
   * **data type** number
   */
  iamt2: string;

  /**
   * 대수 평균
   *
   * **data type** number
   */
  qty_aver: string;

  /**
   * 신규 평균
   *
   * **data type** number
   */
  qty_new_aver: string;

  /**
   * 해지 평균
   *
   * **data type** number
   */
  qty_cls_aver: string;

  /**
   * FM대수 평균
   *
   * **data type** number
   */
  qty_fm_aver: string;

  /**
   * 장기대수 평균
   *
   * **data type** number
   */
  qty_long_aver: string;

  /**
   * 일반대수 평균
   *
   * **data type** number
   */
  qty_pog_aver: string;

  /**
   * 평균보수료 평균
   *
   * **data type** number
   */
  averamt_aver: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangnm = data.spjangnm || '';
    this.averamt_aver = data.averamt_aver || '';
    this.custcd = data.custcd || '';
    this.contamt = data.contamt || '';
    this.averamt = data.averamt || '';
    this.qty_aver = data.qty_aver || '';
    this.qty_new_aver = data.qty_new_aver || '';
    this.qty_cls_aver = data.qty_cls_aver || '';
    this.qty_fm_aver = data.qty_fm_aver || '';
    this.qty_long_aver = data.qty_long_aver || '';
    this.qty_pog_aver = data.qty_pog_aver || '';
    this.qty = data.qty || '';
    this.qty_new = data.qty_new || '';
    this.qty_cls = data.qty_cls || '';
    this.qty_fm = data.qty_fm || '';
    this.qty_long = data.qty_long || '';
    this.qty_pog = data.qty_pog || '';
    this.jamt = data.jamt || '';
    this.misamt2 = data.misamt2 || '';
    this.rcvamt2 = data.rcvamt2 || '';
    this.iamt2 = data.iamt2 || '';
    this.spjangcd = data.spjangcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
