import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox, FormatNumberTextBox,
} from '../../../../components';

import { Global, TemplateProps } from '../../../../constants';
import { EstimatedCost } from './EstimatedCost';


/**
 * 화면
 * @window w_tb_e450
 * @category 견적비용 등록
 */
export const EstimatedCostTemplate: React.FC<TemplateProps<EstimatedCost>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        { id: 'costcd', text: '코드', width: 20 },
        {
          id: 'costnm',
          text: '견적비용명',
          width: 80,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.costnm}
              textAlign="left"
              onChange={(value) => rowUpdate({ costnm: value })}
            />
          ),
        },
        {
          id: 'unit',
          text: '단위',
          width: 20,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.unit}
              onChange={(value) => rowUpdate({ unit: value })}
            />
          ),
        },
        {
          id: 'qty',
          text: '수량',
          width: 20,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.qty}
              onChange={(value) => rowUpdate({ qty: value })}
            />
          ),
        },
        {
          id: 'uamt',
          text: '단가',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <FormatNumberTextBox
              ref={ref}
              value={x.uamt}
              textAlign="right"
              onChange={(value) => rowUpdate({ uamt: value })}
            />
          ),
        },
        {
          id: 'yn',
          text: '적용유무',
          width: 15,
          render: (x, rowUpdate) => (
            <CheckBox value={x.yn === '1'} onChange={(v) => rowUpdate({ yn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 15,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
