import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { DepositConfirmation } from './DepositConfirmation';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_da026_sms
 * @category 입금확인[카톡]내역
 */
export const DepositConfirmationTemplate: React.FC<TemplateProps<DepositConfirmation>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.SS({ stdate: value })}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={400}>
        <span>검색어</span>
        <TextBox
          value={scope.state.searchQuery}
          textAlign="left"
          onChange={(value) => scope.SS({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={270}>
        <span style={{ color: 'var(--color-red)' }}>담당</span>
        <TextBox
          value={scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.SS({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        >
        </TextBox>
        <TextBox
          value={scope.state.pernm}
          weight={1.2}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        >
        </TextBox>
      </FlexLayout>

      <FlexLayout size={210}>
        <span>전송여부</span>

        <ComboBox
          value={scope.state.sendflag}
          onSelect={(option) => scope.setState({ sendflag: option.value }, () => scope.onRetrieveEvent())}
          textAlign="left"
          data={[
            { value: '1', remark: '전송' },
            { value: '0', remark: '미전송' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          isDisabledTrackingStateChange={true}
        >
        </ComboBox>
      </FlexLayout>

      {
        scope.state.kakaoyn === '1' && scope.state.sendflag === '0'
          ? <Button
            style={{ backgroundColor: '#f9e000' }}
            onClick={() => scope.onCheckList('kakao')}
          >
            <RiKakaoTalkFill size={26}/>

            <span>전송</span>
          </Button> : null
      }

      {
        scope.state.sendflag === '0'
          ? <Button onClick={() => scope.onCheckList('sms')}>
            문자전송
          </Button> : null
      }
    </SearchLayout>

    <FlexLayout>
      {/* 조회조건 전송여부 전송이면 첫번째 FlexLayout */}
      {
        scope.state.sendflag === '1'
          ? <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'indate',
                text: '입금처리일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.indate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvdate',
                text: '입금일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvnum',
                text: '번호',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rcvnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltcd',
                text: '거래처코드',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cltcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '내용',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubunnm',
                text: '매출구분',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gubunnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '입금액',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvtelnum',
                text: '고객연락처',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rcvtelnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvflag',
                text: '전송여부',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.rcvflag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
                  >
                    {x.rcvflag === '1' ? '전송' : '미전송'}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
            data={scope.state.depositConfirmationList}
          />
          : <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'chk',
                text: '선택',
                width: 50,
                render: (x, rowUpdate) => (
                  <CheckBox
                    value={x.chk === '1'}
                    onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                  >
                  </CheckBox>
                ),
              },
              {
                id: 'hp',
                text: '신고자번호',
                width: 100,
                render: (x, rowUpdate) => (
                  <TelnumTextBox
                    textAlign="center"
                    value={x.hp}
                    onChange={(value) => rowUpdate({ hp: value })}
                  />
                ),
              },
              {
                id: 'indate',
                text: '입금처리일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.indate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvdate',
                text: '입금일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvnum',
                text: '번호',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rcvnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltcd',
                text: '거래처코드',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cltcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '내용',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubunnm',
                text: '매출구분',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gubunnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '입금액',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvflag',
                text: '전송여부',
                width: 40,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.rcvflag === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
                  >
                    {x.rcvflag === '1' ? '전송' : '미전송'}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
            onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
            data={scope.state.depositConfirmationList}
          />
      }
    </FlexLayout>
  </FlexLayout>
);
