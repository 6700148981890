import * as React from 'react';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { ManageStatus } from '../ManageStatus';
import { ManageStatusGridHeaderSales } from '../headers';

/**
 * 화면 > 상세 > 탭 - 영업내역
 * @window w_tb_e601_sulchi
 * @category 설치관리현황
 */
export const ManageStatusDetailTabSalesTemplate: React.FC<TemplateProps<ManageStatus>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={ManageStatusGridHeaderSales(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataHeadTabGridSales}
    />
  </FlexLayout>
);
