import { computed } from 'mobx';

/**
 * 처리결과 모델
 * @window w_tb_e010
 * @category 고장관리코드
 */
export class ProcessingResultModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(3)
   */
  readonly resultcd: string;

  /**
   * 처리결과명
   *
   * **data type** varchar(20)
   */
  readonly resultnm: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  readonly seq: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  readonly useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  readonly remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.resultcd = data.resultcd || '';
    this.resultnm = data.resultnm || '';
    this.seq = data.seq || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
