import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InputStateTemplate } from './InputState.template';
import { InputStateModel } from './InputState.model';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface InputStateState {
  // search
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  cltcd: string;
  cltnm: string;
  bankcd: string;
  banknm: string;
  billgubun: string;
  chk: string;
  perid: string;
  pernm: string;
  actcd: string;
  actnm: string;
  accnum: string;
  searchQuery: string;
  projno: string;
  projnm: string;
  spjangcd: string;
  wkactcd: string;
  wkactnm: string;

  // data
  inputStateList: Array<InputStateModel>;
  focused?: InputStateModel;
  focusedDetail?: InputStateModel;
  data: Array<InputStateModel>;
  pushTotalCheck: boolean;

  // trail
  rcvamt_tot: string;
  rcvmar_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_input_da026w
 * @category 입금현황
 */
export class InputState extends PageComponent<PageProps, InputStateState>
  implements PageToolEvents {
  updatedRows?: Array<InputStateModel>;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      divicd: '',
      bankcd: '',
      cltcd: '',
      billgubun: '%',
      chk: '%',
      perid: '',
      actcd: '',
      searchQuery: '',
      projno: '',
      spjangcd: 'ZZ',
      wkactcd: '',
      wkactnm: '',

      // data
      data: [],
      inputStateList: [],
      pushTotalCheck: true,

      // trail
      rcvamt_tot: '0',
      bmar_tot: '0',
      total: '0',
    };
    this.grid = React.createRef();
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 왼쪽 리스트
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ data: data.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
        actcd: this.state.actcd,
        projno: this.state.projno,
        as_nm: this.state.searchQuery,
        billgubun: this.state.billgubun,
        chk: this.state.chk,
        divicd: this.state.divicd,
        perid: this.state.perid,
        bankcd: this.state.bankcd,
        items: this.state.data,
        wkactcd: this.state.wkactcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          inputStateList: [
            ...this.state.inputStateList,
            ...items.map((x: any) => new InputStateModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          inputStateList: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.inputStateList && this.state.inputStateList?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      inputStateList: [],
    }, async () => {
      const index = await this.infinity2?.retrieveTo(['actcd', 'cltcd'],
        [this.state.focused?.actcd, this.state.focused?.cltcd], type, true) || 0;
      if (this.state.inputStateList && this.state.inputStateList.length > index) {
        this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.data[0]);
      }
      await this.SS({
        rcvamt_tot: this.infinity2?.box?.rcvamt_tot || '0',
        rcvmar_tot: this.infinity2?.box?.rcvmar_tot || '0',
        total: this.infinity2?.box?.total || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.inputStateList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      cltcd: this.state.cltcd,
      actcd: this.state.actcd,
      projno: this.state.projno,
      as_nm: this.state.searchQuery,
      billgubun: this.state.billgubun,
      chk: this.state.chk,
      divicd: this.state.divicd,
      perid: this.state.perid,
      bankcd: this.state.bankcd,
      wkactcd: this.state.wkactcd,
      items: this.state.data,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.inputStateList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      cltcd: this.state.cltcd,
      actcd: this.state.actcd,
      projno: this.state.projno,
      as_nm: this.state.searchQuery,
      billgubun: this.state.billgubun,
      chk: this.state.chk,
      divicd: this.state.divicd,
      perid: this.state.perid,
      bankcd: this.state.bankcd,
      wkactcd: this.state.wkactcd,
      items: this.state.data,
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      data: this.state.data?.map((x) => new InputStateModel({
        ...x,
        chk: checked === true ? '1' : '0',
      })),
    });
    this.table.current?.update(false);
  }

  @action
  onRowFocusEvent(item:InputStateModel) {
    this.setState({ focused: item });
  }

  @action
  onRowFocusEvent2(item:InputStateModel) {
    this.setState({ focusedDetail: item });
  }

  @action
  onUpdatedRows(rows: Array<InputStateModel>, updatedRows: Array<InputStateModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  onClickOpenBalEnrollment() {
    this.props.publicStore.go(
      '/w_tb_da003_01w_view',
      {
        projno: this.state.focusedDetail?.projno,
      },
    );
  }

  render() {
    return (
      <InputStateTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
