import { computed } from 'mobx';

/**
 * 부서별 업무보고모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class DepartmetModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 구분
   *
   * **data type** char(3)
   */
  gubun: string;

  /**
   * 연간목표
   *
   * **data type** number
   */
  totamt: string;

  /**
   * 연간목표
   *
   * **data type** number
   */
  yearplansamt: string;

  /**
   * 목표
   *
   * **data type** number
   */
  beplansamt: string;

  /**
   * 실적
   *
   * **data type** number
   */
  besamt: string;

  /**
   * 차이
   *
   * **data type** number
   */
  compute_8: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  compute_27: string;

  /**
   * 목표
   *
   * **data type** number
   */
  plansamt: string;

  /**
   * 현재실적
   *
   * **data type** number
   */
  samt: string;

  /**
   * 차이
   *
   * **data type** number
   */
  compute_30: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  compute_31: string;

  /**
   * 목표
   *
   * **data type** number
   */
  totplansamt: string;

  /**
   * 실적
   *
   * **data type** number
   */
  totsamt: string;

  /**
   * 차이
   *
   * **data type** number
   */
  compute_32: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  compute_33: string;

  /**
   * 차이1
   *
   * **data type** number
   */
  bechaamt: string;

  /**
   * 차이2
   *
   * **data type** number
   */
  chaamt: string;

  /**
   * 차이3
   *
   * **data type** number
   */
  totchaamt: string;

  /**
   * -
   *
   * **data type** number
   */
  actnm: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  bechayul: string;

  /**
   * 달성율2
   *
   * **data type** number
   */
  chayul: string;

  /**
   * 달성율3
   *
   * **data type** number
   */
  totchayul: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.bechayul = data.bechayul || '';
    this.chayul = data.chayul || '';
    this.totchayul = data.totchayul || '';
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.totamt = data.totamt || '';
    this.chaamt = data.chaamt || '';
    this.actnm = data.actnm || '';
    this.divinm = data.divinm || '';
    this.totchaamt = data.totchaamt || '';
    this.bechaamt = data.bechaamt || '';
    this.gubun = data.gubun || '';
    this.yearplansamt = data.yearplansamt || '';
    this.beplansamt = data.beplansamt || '';
    this.besamt = data.besamt || '';
    this.compute_8 = data.compute_8 || '';
    this.compute_27 = data.compute_27 || '';
    this.plansamt = data.plansamt || '';
    this.samt = data.samt || '';
    this.compute_30 = data.compute_30 || '';
    this.compute_31 = data.compute_31 || '';
    this.totplansamt = data.totplansamt || '';
    this.totsamt = data.totsamt || '';
    this.compute_32 = data.compute_32 || '';
    this.compute_33 = data.compute_33 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
