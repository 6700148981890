import { computed } from 'mobx';

/**
 * 모델
 * @window w_pb204w_01
 * @category 휴가현황
 */

export class HolidayStatementModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 년도
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 번호
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 작성자
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   * 시작일
   *
   * **data type** varchar(1)
   */
  readonly reader: string;

  /**
   * 종료일
   *
   * **data type** varchar(2)
   */
  readonly rspcd: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly daynum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly vafrdate: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date3: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date4: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date5: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date6: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date7: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date8: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date9: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date10: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date11: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date12: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date13: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date14: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date15: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date16: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date17: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date18: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date19: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date20: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date21: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date22: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date23: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date24: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date25: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date26: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date27: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date28: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date29: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date30: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly date31: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday1: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday2: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday3: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday4: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday5: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday6: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday7: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday8: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday9: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday10: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday11: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday12: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday13: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday14: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday15: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday16: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday17: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday18: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday19: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday20: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday21: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday22: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday23: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday24: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday25: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday26: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday27: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday28: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday29: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday30: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly weekday31: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate1: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate2: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate3: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate4: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate5: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate6: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate7: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate8: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate9: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate10: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate11: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate12: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate13: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate14: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate15: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate16: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate17: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate18: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate19: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate20: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate21: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate22: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate23: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate24: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate25: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate26: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate27: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate28: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate29: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate30: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly ndate31: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday1: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday2: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday3: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday4: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday5: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday6: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday7: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday8: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday9: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday10: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday11: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday12: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday13: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday14: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday15: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday16: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday17: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday18: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday19: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday20: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday21: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday22: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday23: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday24: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday25: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday26: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday27: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday28: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday29: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday30: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly nweekday31: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly lastday: number;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly nlastday: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly seq: number;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.reader = data.reader || '';
    this.rspcd = data.rspcd || '';
    this.daynum = data.daynum || '';
    this.vafrdate = data.vafrdate || '';
    this.date1 = data.date1 || '';
    this.date2 = data.date2 || '';
    this.date3 = data.date3 || '';
    this.date4 = data.date4 || '';
    this.date5 = data.date5 || '';
    this.date6 = data.date6 || '';
    this.date7 = data.date7 || '';
    this.date8 = data.date8 || '';
    this.date9 = data.date9 || '';
    this.date10 = data.date10 || '';
    this.date11 = data.date11 || '';
    this.date12 = data.date12 || '';
    this.date13 = data.date13 || '';
    this.date14 = data.date14 || '';
    this.date15 = data.date15 || '';
    this.date16 = data.date16 || '';
    this.date17 = data.date17 || '';
    this.date18 = data.date18 || '';
    this.date19 = data.date19 || '';
    this.date20 = data.date20 || '';
    this.date21 = data.date21 || '';
    this.date22 = data.date22 || '';
    this.date23 = data.date23 || '';
    this.date24 = data.date24 || '';
    this.date25 = data.date25 || '';
    this.date26 = data.date26 || '';
    this.date27 = data.date27 || '';
    this.date28 = data.date28 || '';
    this.date29 = data.date29 || '';
    this.date30 = data.date30 || '';
    this.date31 = data.date31 || '';
    this.weekday1 = data.weekday1 || '';
    this.weekday2 = data.weekday2 || '';
    this.weekday3 = data.weekday3 || '';
    this.weekday4 = data.weekday4 || '';
    this.weekday5 = data.weekday5 || '';
    this.weekday6 = data.weekday6 || '';
    this.weekday7 = data.weekday7 || '';
    this.weekday8 = data.weekday8 || '';
    this.weekday9 = data.weekday9 || '';
    this.weekday10 = data.weekday10 || '';
    this.weekday11 = data.weekday11 || '';
    this.weekday12 = data.weekday12 || '';
    this.weekday13 = data.weekday13 || '';
    this.weekday14 = data.weekday14 || '';
    this.weekday15 = data.weekday15 || '';
    this.weekday16 = data.weekday16 || '';
    this.weekday17 = data.weekday17 || '';
    this.weekday18 = data.weekday18 || '';
    this.weekday19 = data.weekday19 || '';
    this.weekday20 = data.weekday20 || '';
    this.weekday21 = data.weekday21 || '';
    this.weekday22 = data.weekday22 || '';
    this.weekday23 = data.weekday23 || '';
    this.weekday24 = data.weekday24 || '';
    this.weekday25 = data.weekday25 || '';
    this.weekday26 = data.weekday26 || '';
    this.weekday27 = data.weekday27 || '';
    this.weekday28 = data.weekday28 || '';
    this.weekday29 = data.weekday29 || '';
    this.weekday30 = data.weekday30 || '';
    this.weekday31 = data.weekday31 || '';
    this.ndate1 = data.ndate1 || '';
    this.ndate2 = data.ndate2 || '';
    this.ndate3 = data.ndate3 || '';
    this.ndate4 = data.ndate4 || '';
    this.ndate5 = data.ndate5 || '';
    this.ndate6 = data.ndate6 || '';
    this.ndate7 = data.ndate7 || '';
    this.ndate8 = data.ndate8 || '';
    this.ndate9 = data.ndate9 || '';
    this.ndate10 = data.ndate10 || '';
    this.ndate11 = data.ndate11 || '';
    this.ndate12 = data.ndate12 || '';
    this.ndate13 = data.ndate13 || '';
    this.ndate14 = data.ndate14 || '';
    this.ndate15 = data.ndate15 || '';
    this.ndate16 = data.ndate16 || '';
    this.ndate17 = data.ndate17 || '';
    this.ndate18 = data.ndate18 || '';
    this.ndate19 = data.ndate19 || '';
    this.ndate20 = data.ndate20 || '';
    this.ndate21 = data.ndate21 || '';
    this.ndate22 = data.ndate22 || '';
    this.ndate23 = data.ndate23 || '';
    this.ndate24 = data.ndate24 || '';
    this.ndate25 = data.ndate25 || '';
    this.ndate26 = data.ndate26 || '';
    this.ndate27 = data.ndate27 || '';
    this.ndate28 = data.ndate28 || '';
    this.ndate29 = data.ndate29 || '';
    this.ndate30 = data.ndate30 || '';
    this.ndate31 = data.ndate31 || '';
    this.nweekday1 = data.nweekday1 || '';
    this.nweekday2 = data.nweekday2 || '';
    this.nweekday3 = data.nweekday3 || '';
    this.nweekday4 = data.nweekday4 || '';
    this.nweekday5 = data.nweekday5 || '';
    this.nweekday6 = data.nweekday6 || '';
    this.nweekday7 = data.nweekday7 || '';
    this.nweekday8 = data.nweekday8 || '';
    this.nweekday9 = data.nweekday9 || '';
    this.nweekday10 = data.nweekday10 || '';
    this.nweekday11 = data.nweekday11 || '';
    this.nweekday12 = data.nweekday12 || '';
    this.nweekday13 = data.nweekday13 || '';
    this.nweekday14 = data.nweekday14 || '';
    this.nweekday15 = data.nweekday15 || '';
    this.nweekday16 = data.nweekday16 || '';
    this.nweekday17 = data.nweekday17 || '';
    this.nweekday18 = data.nweekday18 || '';
    this.nweekday19 = data.nweekday19 || '';
    this.nweekday20 = data.nweekday20 || '';
    this.nweekday21 = data.nweekday21 || '';
    this.nweekday22 = data.nweekday22 || '';
    this.nweekday23 = data.nweekday23 || '';
    this.nweekday24 = data.nweekday24 || '';
    this.nweekday25 = data.nweekday25 || '';
    this.nweekday26 = data.nweekday26 || '';
    this.nweekday27 = data.nweekday27 || '';
    this.nweekday28 = data.nweekday28 || '';
    this.nweekday29 = data.nweekday29 || '';
    this.nweekday30 = data.nweekday30 || '';
    this.nweekday31 = data.nweekday31 || '';
    this.lastday = data.lastday || '';
    this.nlastday = data.nlastday || '';
    this.seq = data.seq || 0;
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
