import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox, ComboBoxModel, DateTextBox, FlexLayout, GridLayout, SearchLayout, TabLayout, TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import {
  Bals, Collect, Conts, Das, Gubuns, Kcs, ScGubuns,
} from './Collect';
import { CollectTab } from './Collect.tab';
import { Date6 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { CollectGridHeader } from './headers/CollectGridHeader';

/**
 * 화면
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTemplate: React.FC<TemplateProps<Collect>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={220}>
        <span>검색년도</span>
        <DateTextBox
          format="####-##"
          textAlign="center"
          value={scope.state.stmon}
          onChange={(v) => scope.setState({ stmon: v })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                date.setMonth(date.getMonth() - 1);
                scope.setState({ stmon: Date6.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.stmon.substr(0, 4)}-${scope.state.stmon.substr(4, 2)}-01`);
                date.setMonth(date.getMonth() + 1);
                scope.setState({ stmon: Date6.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={170}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          format="####-##"
          textAlign="center"
          value={scope.state.endmon}
          onChange={(v) => scope.setState({ endmon: v })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                date.setMonth(date.getMonth() - 1);
                scope.setState({ endmon: Date6.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.endmon.substr(0, 4)}-${scope.state.endmon.substr(4, 2)}-01`);
                date.setMonth(date.getMonth() + 1);
                scope.setState({ endmon: Date6.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span>설치구분</span>
        <ComboBox
          value={scope.state.gubun}
          textAlign="center"
          style={{ marginLeft: 4 }}
          data={ScGubuns.map((x) => new ComboBoxModel(x.key, x.value))}
          onSelect={(option) => scope.setState({ gubun: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={210}>
        <span>계약상태</span>
        <ComboBox
          value={scope.state.contstate}
          textAlign="center"
          style={{ marginLeft: 4 }}
          data={Conts.map((x) => new ComboBoxModel(x.key, x.value))}
          onSelect={(option) => scope.setState({ contstate: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={214}>
        <span style={{ paddingLeft: 20 }}>계약구분</span>
        <ComboBox
          value={scope.state.contflag}
          textAlign="center"
          style={{ marginLeft: 4 }}
          data={Gubuns.map((x) => new ComboBoxModel(x.key, x.value))}
          onSelect={(option) => scope.setState({ contflag: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span>매출발생여부</span>
        <ComboBox
          value={scope.state.okbook}
          textAlign="center"
          data={Das.map((x) => new ComboBoxModel(x.key, x.value))}
          onSelect={(option) => scope.setState({ okbook: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span>발주발생여부</span>
        <ComboBox
          value={scope.state.compbook}
          textAlign="center"
          data={Bals.map((x) => new ComboBoxModel(x.key, x.value))}
          onSelect={(option) => scope.setState({ compbook: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span>분할납부</span>
        <ComboBox
          value={scope.state.scdivided}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '진행중' },
            { value: '1', remark: '아님' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ scdivided: option.value }, () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        >
        </ComboBox>
      </FlexLayout>
    </SearchLayout>

    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      isSub={true}
    >
      <FlexLayout size={366}>
        <span style={{ paddingRight: 37 }}>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="현장, 거래처, 주소로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={444}>
        <span
          style={{
            color: 'var(--color-red)',
            paddingLeft: 38,
            paddingRight: 4,
          }}
        >
          계약자
        </span>

        <TextBox
          value={scope.state.perid}
          textAlign="center"
          size={118}
          onChange={(value) => scope.setState({ perid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              });
            },
          )}
        />

        <TextBox
          size={208}
          textAlign="center"
          value={scope.state.pernm}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={406}>
        <span style={{ color: 'var(--color-red)' }}>
          발주거래처
        </span>

        <TextBox
          value={scope.state.balcltcd}
          textAlign="center"
          size={112}
          onChange={(value) => scope.setState({ balcltcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_XCLIENT',
            {},
            true,
            (item) => {
              scope.setState({
                balcltcd: item.cd,
                balcltcm: item.cdnm,
              });
            },
          )}
        />

        <TextBox
          textAlign="center"
          value={scope.state.balcltcm}
          readonly={true}
        />
      </FlexLayout>

      <FlexLayout size={200}>
        <span style={{ paddingLeft: 13 }}>검사일여부</span>
        <ComboBox
          value={scope.state.kcdateflag}
          textAlign="center"
          data={Kcs.map((x) => new ComboBoxModel(x.key, x.value))}
          onSelect={(option) => scope.setState({ kcdateflag: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout>
        <span>수금여부</span>
        <ComboBox
          value={scope.state.rcvflag}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '미수금' },
            { value: '1', remark: '수금' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ rcvflag: option.value }, () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        >
        </ComboBox>
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={CollectGridHeader(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          onRowClick={(row, index) => scope.onRowFocusEvent(row, index)}
        />
      </FlexLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={CollectTab}
          onChange={(item, index) => scope.onTabChange(item, index)}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
