import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  ModalView,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { InspectionNotice } from './InspectionNotice';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_e035w_03
 * @category 점검시행안내문
 */
export const InspectionNoticeTemplate: React.FC<TemplateProps<InspectionNotice>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>월</span>
            <DateTextBox
              value={scope.state.yearmon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => setData({ yearmon: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout weight={2}>
            <span>검색어</span>
            <TextBox
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>출력</span>
            <ComboBox
              value={scope.state.prtchk}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '미처리' },
                { value: '1', remark: '처리' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => setData({ prtchk: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>메일</span>
            <ComboBox
              value={scope.state.emailchk}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '발행' },
                { value: '0', remark: '미발행' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ emailchk: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout>
          </FlexLayout>

          <Button onClick={() => scope.onEmailBtn()}>EMAIL</Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout
          weight={0.01}
          style={{
            color: 'var(--color-blue)',
            // marginLeft: 300,
          }}
        >
          *더블클릭시 점검일자와 시간을 수정할 수 있습니다.
        </FlexLayout>

        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'prtchk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                >
                  <FlexLayout>
                    출력선택
                  </FlexLayout>

                  <CheckBox
                    noMargin
                    value={scope.state.pushTotalCheck}
                    onChange={async (value) => scope
                      .updateCheckAllToggle(value)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
              width: 50,
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.prtchk === '1'}
                    onChange={(value) => {
                      rowUpdate({ prtchk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
            {
              id: 'emailchk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                >
                  <FlexLayout>
                    메일선택
                  </FlexLayout>

                  <CheckBox
                    noMargin
                    value={scope.state.pushTotalCheck2}
                    onChange={async (value) => scope
                      .updateCheckAllToggle2(value)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  건수: {scope.state.total}
                </FlexLayout>
              ),
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.emailchk === '1'}
                    onChange={(value) => {
                      rowUpdate({ emailchk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
            {
              id: 'prtflag',
              text: '출력',
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  출력건수: {scope?.state.prtcnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.prtflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                >
                  {x.prtflag === '1' ? '출력' : '미출력'}
                </FlexLayout>
              ),
            },
            {
              id: 'emailflag',
              text: '메일',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  미출력건수: {scope?.state.notprtcnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.emailflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                >
                  {x.emailflag === '0' ? '미전송' : '전송'}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '코드',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  메일전송건수: {scope?.state.emailcnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  메일미전송건수: {scope?.state.notemailcnt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '대수',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty}
                </FlexLayout>
              ),
            },
            {
              id: 'pernms',
              text: '점검자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernms}
                </FlexLayout>
              ),
            },
            {
              id: 'plandate_min',
              text: '점검시작일',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.plandate_min)}
                </FlexLayout>
              ),
            },
            {
              id: 'plandate_max',
              text: '점검종료일',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.plandate_max)}
                </FlexLayout>
              ),
            },
            {
              id: 'plantime_min',
              text: '시작시간',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="##:##"
                  value={x.plantime_min}
                />
              ),
            },
            {
              id: 'plantime_max',
              text: '종료시간',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="##:##"
                  value={x.plantime_max}
                />
              ),
            },
            {
              id: 'email',
              text: '메일주소',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.email}
                </FlexLayout>
              ),
            },
            {
              id: 'address',
              text: '주소',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.address}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          onRowDoubleClick={() => scope.modalOpen(true)}
        />
      </FlexLayout>

      <ModalView
        width={800}
        isVisible={scope.state.modalState}
        onClose={() => scope.modalOpen(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <TableLayout
            ref={scope.modalTable}
            header={[
              {
                id: 'actcd',
                text: '현장코드',
                width: 10,
                trail: () => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    합 계 {scope.state.modalTotal}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 44,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '점검일자',
                width: 14,
                render: (x, rowUpdate, ref) => (
                  <DateTextBox
                    format="####-##-##"
                    textAlign="center"
                    transparent={true}
                    ref={ref}
                    value={x.plandate}
                    onChange={(value) => rowUpdate({ plandate: value })}/>
                ),
              },
              {
                id: 'plantime',
                text: '시작시간',
                width: 10,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    ref={ref}
                    format="##:##"
                    textAlign="center"
                    value={x.plantime}
                    onChange={(v) => rowUpdate({ plantime: v })}
                  />
                ),
              },
              {
                id: 'plantime2',
                text: '종료시간',
                width: 12,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    ref={ref}
                    format="##:##"
                    textAlign="center"
                    value={x.plantime2}
                    onChange={(v) => rowUpdate({ plantime2: v })}
                  />
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.modalData}
            onRowFocusEvent={(item) => scope.onModalRowFocusEvent(item)}
            onChange={(rows, updatedRows) => {
              scope.onModalUpdatedRows(rows, updatedRows);
            }}
          />

          <FlexLayout
            justify="right"
            weight={0.01}
          >
            <Button
              style={{
                height: '25px',
                width: '80px',
                marginTop: 10,
                marginRight: 5,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalSave()}
            >
              저장
            </Button>

            <Button
              style={{
                height: '25px',
                width: '80px',
                marginTop: 10,
                marginRight: 5,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalOpen(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
