import Date8 from './Date8';

export default class Date8Calculator {
  date: Date;

  constructor(date?: Date | string) {
    this.date = typeof date === 'string' ? new Date(Date8.withDash(date)) : date || new Date();
  }

  public add(date?: number): Date8Calculator {
    this.date.setDate(this.date.getDate() + (date || 1));
    return this;
  }

  public addMonth(month?: number): Date8Calculator {
    this.date.setMonth(this.date.getMonth() + (month || 1));
    return this;
  }

  public addYear(year?: number): Date8Calculator {
    this.date.setFullYear(this.date.getFullYear() + (year || 1));
    return this;
  }

  public toString(): string {
    return Date8.make(this.date);
  }
}
