import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { TerminationModel } from './Termination.model';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
} from '../../../../constants';
import { TerminationTemplate } from './Termination.template';
import { PageComponent } from '../../../../utils';
import { DateStabilizer } from '../../../../utils/time';

export enum TerminationItemChangeTypes {
  ACTCD,
}

export enum TerminationItemChangeTypeNames {
  actcd,
}

interface TerminationState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어

  // 데이터 객체
  focusedTermination?: TerminationModel;
  data: TerminationModel;
  lastNewData: TerminationModel;
  terminationList: Array<TerminationModel>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  focusIndex: number;
  chk: string;

  total: string;
  amt_tot: string;
}

// **
// * 모델
// * @window w_tb_e101_cls
// * @category 해지보고서
// *

export class Termination extends PageComponent<PageProps, TerminationState>
  implements PageToolEvents {
  updatedRows?: Array<TerminationModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`, // 기간(시작)
      enddate: `${year}${month}${date}`, // 기간(끝)
      searchQuery: '',
      data: new TerminationModel(),
      terminationList: [],
      isReported: false,
      focusIndex: 0,
      chk: '0',

      total: '',
      amt_tot: '',
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          actcd: this.state.data.actcd,
          actnm: this.state.data.actnm,
          seq: this.state.data.seq,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
        }, false,
      );

      data && this.setState({
        data: new TerminationModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }


  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          terminationList: [...this.state.terminationList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ terminationList: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      terminationList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.state.terminationList.length > 0
        && this.onRowFocusEvent(this.state.terminationList[this.state.focusIndex], this.state.focusIndex);
      if (this.state.chk === '1') {
        this.grid.current?.setFocus(this.state.focusIndex);
        this.setState({ chk: '0' });
      } else {
        this.grid.current?.setFocus(0);
      }

      this.setState({
        amt_tot: this.infinity?.box?.amt_tot,
        total: this.infinity?.box?.total,
      });
    });
  }

  @action
  onRowFocusEvent(item: TerminationModel, index: number) {
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        isReported: false,
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedTermination: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            ...this.state.focusedTermination,
            actcd: this.state.focusedTermination?.actcd,
            seq: this.state.focusedTermination?.seq,
          });

        await data && this.setState({ data: new TerminationModel(data) });

        // 결재상신 여부확인
        if (this.state.focusedTermination?.appgubun === null
          || this.state.focusedTermination?.appgubun === ''
          || this.state.focusedTermination?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
      },
    );
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    const params = {
      itemname: TerminationItemChangeTypeNames[type],
      date: '',
      new: this.state.data.new,
    };

    if (type === TerminationItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.actcd,
        },
      );
    }

    data && this.setState({
      data: new TerminationModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedTermination!, this.state.focusIndex); }
      });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.TERMINATE, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedTermination!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.TERMINATE);
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.TERMINATE.toString(),
      appflag,
      ...this.state.focusedTermination,
      ...this.state.data,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.appdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new TerminationModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        terminationList: [
          newModel,
          ...this.state.terminationList,
        ],
        focusedTermination: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported) {
      await api.fxExec('delete',
        {
          actcd: this.state.data.actcd,
          actnm: this.state.data.actnm,
          seq: this.state.data.seq,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
        }, false);
    } else {
      const text = `작성일자: ${this.state.focusedTermination?.appdate} 작성번호: ${this.state.focusedTermination?.appnum}`;
      await api.delete(text, {
        actcd: this.state.data.actcd,
        actnm: this.state.data.actnm,
        seq: this.state.data.seq,
        appnum: this.state.data.appnum,
        appgubun: this.state.data.appgubun,
      }) && this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.focusedTermination?.actcd, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      actcd: this.state.focusedTermination?.actcd,
      seq: this.state.focusedTermination?.seq,
    });
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  render() {
    return (
      <TerminationTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new TerminationModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
