import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RefObject } from 'react';
import { Root } from './Root';
import { PublicStore } from '../stores';
import { AuthorizedScreen } from './AuthorizedScreen';
import { UnAuthorizedScreen } from './UnAuthorizedScreen';
import { Loading, Minimap } from '../components';
import { FunctionRepository } from '../repositories';

export interface AuthManagerProps {
  publicStore?: PublicStore;
}

export interface AuthManagerStates {
  publicStore?: PublicStore;
  isZoom: boolean;
}

const DEFAULT_WIDTH = 1920.0;

@inject('publicStore') @observer
export class AuthManager extends React.Component<AuthManagerProps, AuthManagerStates> {
  ref: RefObject<HTMLDivElement> = React.createRef();

  minimap: RefObject<Minimap> = React.createRef();

  isScaled: boolean = false;

  epZoom: number = 0;

  constructor(props: AuthManagerProps, context?: any) {
    super(props, context);
    this.state = {
      isZoom: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener('mousewheel', this.onWindowScroll, false);
    this.onWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize, false);
    window.removeEventListener('mousewheel', this.onWindowScroll, false);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    let formatted = '';
    formatted += `Exception: "${error.message}"\n`;
    formatted += `Caused on: ${errorInfo.componentStack}\n`;
    formatted += `Stack: ${error.stack}\n`;

    const { publicStore } = this.props;
    const { custcd, spjangcd, perid } = publicStore!.user;
    if (perid) {
      FunctionRepository.request(
        true,
        'https://api.elmansoft.com/etc/errorCapture.php', {
          custcd,
          spjangcd,
          perid,
          tag: 'ERROR_WEBERP',
          error: formatted,
          url: '',
          line: '',
        },
      );
    }
  }

  onWindowResize = () => {
    const w = window.innerWidth;
    // if (w >= DEFAULT_WIDTH) {
    //   if (this.isScaled && this.ref.current) {
    //     this.ref.current.style.transform = '';
    //     this.ref.current.style.width = '';
    //     this.ref.current.style.height = '';
    //   }
    //   this.isScaled = false;
    //   this.props.publicStore?.setZoomF(1.0);
    //   return;
    // }

    this.isScaled = true;

    const f = (w * (1.001 + this.epZoom)) / DEFAULT_WIDTH;
    if (this.ref.current) {
      this.ref.current.style.transform = `scale(${f})`;
      this.ref.current.style.width = f > 1 ? `${DEFAULT_WIDTH}px` : `${w / f}px`;
      this.ref.current.style.height = f > 1 ? `${(window.innerHeight / window.innerWidth) * DEFAULT_WIDTH}px` : `${window.innerHeight / f}px`;
      this.props.publicStore?.setZoomF(f);
      this.minimap.current?.update();

      if (f <= 1) {
        this.ref.current?.parentElement?.scrollTo(0, 0);
      }
    }
  }

  onWindowScroll = (s: Event) => {
    const e = s as WheelEvent;
    if (e.altKey) {
      this.epZoom -= e.deltaY / 1000.0;
      if (this.epZoom <= 0) {
        // this.epZoom = 0;
        this.setState({ isZoom: false });
      } else if (!this.state.isZoom) {
        this.setState({ isZoom: true });
      }

      this.onWindowResize();

      if (this.epZoom > 0) {
        const p = this.ref.current?.parentElement;
        p?.scrollTo(
          p?.scrollLeft + (e?.x - p?.clientWidth / 2),
          p?.scrollTop + (e?.y - p?.clientHeight / 2),
        );
      }
    }
  }


  render() {
    const { publicStore } = this.props;

    return (
      <Minimap
        ref={this.minimap}
        selector=".minimap"
        isZoom={this.state.isZoom}
      >
        <Root
          refs={this.ref}
          theme={publicStore!.theme}
          contrast={publicStore!.contrast === '1'}
        >
          <React.Fragment>
            <Loading remarks="잠시만 기다려 주세요..." />
            {publicStore?.isLoggedIn ? (
              <AuthorizedScreen />
            ) : (
              <UnAuthorizedScreen />
            )}
          </React.Fragment>
        </Root>
      </Minimap>
    );
  }
}
