import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { ManageStatus } from '../ManageStatus';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';

const Gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면 > 하단 탭 > 그리드 - 수주/발주내역 > 수주내역
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusGridHeaderSuju: GLHF<any, ManageStatus> = () => ([
  {
    id: 'orddate',
    text: '수주일자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.orddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'ordnum',
    text: '번호',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.ordnum}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '수주종류',
    width: 100,
    render: (x) => {
      const fp = Gubuns.filter((y) => y.value === x.gubun);
      const nm = fp.length > 0 ? fp[0].remark : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'projnm',
    text: '프로젝트명',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.projnm}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '수주금액',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
  },
]);
