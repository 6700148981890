import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca606w_01
 * @category 자재청구현황
 */

export class BillStatementModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 작성일자
   *
   * **data type** datetime
   */
  readonly datetime: string;

  /**
   * 청구일자
   *
   * **data type** char(8)
   */
  readonly pumdate: string;

  /**
   * 청구번호
   *
   * **data type** varchar(4)
   */
  readonly pumnum: string;

  /**
   * 담당자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(30)
   */
  readonly inpernm: string;

  /**
   * 검색어
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   * 현장명2
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(2)
   */
  readonly contg: string;

  /**
   * 발주여부
   *
   * **data type** char(1)
   */
  readonly balflag: string;

  /**
   * 품목코드
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   * 품목명
   *
   * **data type** varchar(50)
   */
  readonly pname: string;

  /**
   * 규격
   *
   * **data type** varchar(200)
   */
  readonly psize: string;

  /**
   * 단가
   *
   * **data type** float
   */
  readonly uamt: string;

  /**
   * 금액
   *
   * **data type** float
   */
  readonly samt: string;

  /**
   * 수량
   *
   * **data type** float
   */
  readonly qty: string;

  /**
   * 유무상
   *
   * **data type** varchar(3)
   */
  readonly myn: string;

  /**
   * 유무상구분
   *
   * **data type** varchar(1)
   */
  readonly mgubun: string;

  /**
   * 기간
   *
   * **data type** varchar(12)
   */
  readonly gigan: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  readonly pumseq: string;

  /**
   * 호기코드
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(
   */
  readonly equpnm: string;

  /**
   * 입고여부
   *
   * **data type** varchar(1)
   */
  readonly ibgflag: string;

  /**
   * 확인서수령여부
   *
   * **data type** char(1)
   */
  readonly receyn: string;

  /**
   * 공사여부
   *
   * **data type** char(1)
   */
  readonly compflag: string;

  /**
   * 공사구분
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   * 확인서구분
   *
   * **data type** varchar(1)
   */
  readonly receflag: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   * 발주비고
   *
   * **data type** varchar(60)
   */
  readonly bal_remark: string;

  /**
   * 견적여부
   *
   * **data type** char(1)
   */
  readonly costflag: string;

  /**
   * 전자세금계산서
   *
   * **data type** varchar(2)
   */
  readonly recivstatus: string;

  /**
   * 청구비고
   *
   * **data type** varchar(60)
   */
  readonly pum_remark: string;

  /**
   * 불출여부
   *
   * **data type** varchar(1)
   */
  readonly demflag: string;

  /**
   * 발주일자
   *
   * **data type** datetime
   */
  readonly baldate: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.datetime = data.datetime || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.contg = data.contg || '';
    this.balflag = data.balflag || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.qty = data.qty || '';
    this.myn = data.myn || '';
    this.mgubun = data.mgubun || '';
    this.gigan = data.gigan || '';
    this.pumseq = data.pumseq || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.ibgflag = data.ibgflag || '';
    this.receyn = data.receyn || '';
    this.compflag = data.compflag || '';
    this.gubun = data.gubun || '';
    this.receflag = data.receflag || '';
    this.remark = data.remark || '';
    this.bal_remark = data.bal_remark || '';
    this.costflag = data.costflag || '';
    this.recivstatus = data.recivstatus || '';
    this.pum_remark = data.pum_remark || '';
    this.demflag = data.demflag || '';
    this.baldate = data.baldate || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
