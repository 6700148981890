import { TableLayoutHeader } from '../../../../../constants';
import { CompanyModel } from '../models';
import { Management } from '../Management';
import { TextBox } from '../../../../../components/forms/TextBox';
import { CheckBox } from '../../../../../components/forms/CheckBox';

// 회사구분
const Company: TableLayoutHeader<CompanyModel, Management>[] = [
  {
    id: 'wkactcd',
    text: '코드',
    width: 50,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.wkactcd}
        onChange={(value) => rowUpdate({ wkactcd: value })}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'wkactnm',
    text: '회사구분명',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.wkactnm}
        onChange={(value) => rowUpdate({ wkactnm: value })}
      />
    ),
  },
  {
    id: 'useyn',
    text: '사용유무',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.useyn === '1'}
        onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'wkactcd_send',
    text: '전송유무',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.wkactcd_send === '1'}
        onChange={(value) => rowUpdate({ wkactcd_send: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
];
export default Company;
