import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { ManageStatus } from '../ManageStatus';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 하단 탭 > 그리드 - 현장협의사항
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusGridHeaderMemo: GLHF<any, ManageStatus> = () => ([
  {
    id: 'seq',
    text: '순서',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'scdate',
    text: '협의일자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.scdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '현장협의사항',
    width: 550,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
