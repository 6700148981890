import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ConfirmWarning } from '../../utils/confirm';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { LabelBox } from '../../components/forms/LabelBox';
import { TextBox } from '../../components/forms/TextBox';
import { CheckBox } from '../../components/forms/CheckBox';
import { AddressButton } from '../../components/forms/AddressButton';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { Global } from '../../constants';
import { ComboBox, ComboBoxModel } from '../../components/forms/ComboBox';
import { OptionBox } from '../../components/forms/OptionBox';
import { FormatTextBox } from '../../components/forms/FormatTextBox';
import { ResisterModel } from '../../pages/human/account/Resister/Resister.model';

interface ModalAddCltProps {
  modalStore: ModalStore;
}

@observer
export class ModalAddClt extends React.Component<ModalAddCltProps> {
  render() {
    const { modalStore } = this.props;
    const { addCltData: data } = modalStore;

    const setData = (s: any) => {
      modalStore.addCltData = new ResisterModel({
        ...modalStore.addCltData,
        ...s,
      });
    };

    return (
      <ModalView
        width={1100}
        isVisible={modalStore.isVisibleAddClt}
        onClose={() => modalStore.closeAddClt(false)}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout isVertical={true}>
            <LayoutTitle>빠른 거래처 등록</LayoutTitle>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="거래처코드"
                isNeed={true}
              >
                <TextBox
                  value={data.cltcd}
                  onChange={(v) => setData({ cltcd: v })}
                />
              </LabelBox>

              <LabelBox
                title="거래처명칭"
                weight={3}
              >
                <TextBox
                  value={data.cltnm}
                  onChange={(v) => setData({ cltnm: v })}
                />
              </LabelBox>

              <LabelBox
                title="거래처타입"
              >
                <ComboBox
                  value={data.grade}
                  data={modalStore.addCltGrades?.map(
                    (x: any) => new ComboBoxModel(x.com_code, x.com_cnam),
                  )}
                  onSelect={(v) => setData({ grade: v.value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="대표자명"
              >
                <TextBox
                  value={data.prenm}
                  onChange={(v) => setData({ prenm: v })}
                />
              </LabelBox>

              <LabelBox
                title="사업자등록번호"
              >
                <FormatTextBox
                  format="###-##-#####"
                  textAlign="center"
                  value={data.saupnum}
                  onChange={(v) => setData({ saupnum: v })}
                />
              </LabelBox>

              <LabelBox
                title="주민(법인)번호"
              >
                <FormatTextBox
                  format="######-#######"
                  textAlign="center"
                  value={data.prenum}
                  onChange={(v) => setData({ prenum: v })}
                />
              </LabelBox>

              <LabelBox
                title="업태"
              >
                <TextBox
                  value={data.biztypenm}
                  onChange={(v) => setData({ biztypenm: v })}
                />
              </LabelBox>

              <LabelBox
                title="종목"
              >
                <TextBox
                  value={data.bizitemnm}
                  onChange={(v) => setData({ bizitemnm: v })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="담당자명"
              >
                <TextBox
                  value={data.agnernm}
                  onChange={(v) => setData({ agnernm: v })}
                />
              </LabelBox>

              <LabelBox
                title="계산서메일"
                weight={3}
                footer={
                  <FlexLayout>
                    사용
                    <CheckBox
                      value={data.taxflag === '1'}
                      onChange={(v) => setData({ taxflag: v ? '1' : '0' })}
                    />
                  </FlexLayout>
                }
              >
                <TextBox
                  value={data.taxmail}
                  onChange={(v) => setData({ taxmail: v })}
                />
              </LabelBox>

              <LabelBox
                title="구분"
              >
                <OptionBox
                  value={data.clttype}
                  data={[
                    { value: '1', remark: '매입' },
                    { value: '2', remark: '매출' },
                    { value: '3', remark: '공통' },
                  ]}
                  onChange={(v) => setData({ clttype: v.value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="주소"
                weight={4}
                style={{ color: 'var(--color-red)' }}
                footer={<FlexLayout>
                  <AddressButton
                    publicStore={modalStore.publicStore}
                    modalStore={modalStore}
                    onChange={(address) => setData({
                      zipcd: address.zonecode,
                      oldcltadres: address.address,
                      cltadres2: address.jibunAddress,
                      lat: address.y,
                      lng: address.x,
                    })}
                  >
                    주소검색
                  </AddressButton>

                  <DefaultLayout
                    size={150}
                    style={{ marginLeft: 16 }}
                  >
                    {`위도: ${data.lat}`}
                  </DefaultLayout>

                  <DefaultLayout size={150}>
                    {`경도: ${data.lng}`}
                  </DefaultLayout>
                </FlexLayout>}
              >
                <TextBox
                  textAlign="center"
                  value={data.zipcd}
                  onChange={(value) => setData({ zipcd: value })}
                  readonly={true}
                />

                <TextBox
                  weight={3}
                  value={data.oldcltadres}
                  readonly={true}
                  onChange={(value) => setData({ oldcltadres: value })}
                />

                <TextBox
                  weight={3}
                  value={data.cltadres2}
                  onChange={(value) => setData({ cltadres2: value })}
                />
              </LabelBox>

              <LabelBox>
                <FlexLayout>
                  <OptionBox
                    value={data?.corpperclafi || '0'}
                    isVertical={true}
                    data={[
                      { value: '0', remark: '법인' },
                      { value: '1', remark: '개인' },
                    ]}
                    onChange={(item) => setData({ corpperclafi: item.value })}
                  />
                  <OptionBox
                    value={data?.upriceclafi || '1'}
                    isVertical={true}
                    data={[
                      { value: '1', remark: '도매' },
                      { value: '2', remark: '소매' },
                    ]}
                    onChange={(item) => setData({ upriceclafi: item.value })}
                  />
                  <OptionBox
                    value={data?.foreyn || '0'}
                    isVertical={true}
                    data={[
                      { value: '0', remark: '국내' },
                      { value: '1', remark: '국외' },
                    ]}
                    onChange={(item) => setData({ foreyn: item.value })}
                  />
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout>
              <LabelBox title="비고">
                <TextBox
                  value={data.remark}
                  isMultiline={true}
                  onChange={(v) => setData({ remark: v })}
                />
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeAddClt(false)}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (ConfirmWarning.assert(
                  data.taxflag === '0'
                    || (data.taxflag === '1' && data.taxmail),
                  '경고',
                  '계산서 메일주소를 적거나 사용 체크를 풀어주세요!',
                )) {
                  modalStore.closeAddClt(true);
                }
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
