import { GLHF } from '../../../../../constants';
import { FlexLayout, FormatTextBox } from '../../../../../components';
import { Install } from '../Install';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 > 월별 수주매출내역 - 그리드 레이아웃
 * @window w_tb_e601w_sulchi_08
 * @category 설치수주매출통계
 */
export const InstallTabMonthGridHeader: GLHF<any, Install> = (scope) => ([
  {
    id: 'mon',
    text: '월',
    width: 8,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##"
        value={x.mon}
      />
    ),
  },
  {
    id: 'contamt',
    text: '수주금액',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'contamt14',
    text: '수주(설치)',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt14_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt14)}
      </FlexLayout>
    ),
  },
  {
    id: 'contamt15',
    text: '수주(리모델링)',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.tab_contamt15_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.contamt14)}
      </FlexLayout>
    ),
  },
  {
    id: 'notmisamt',
    text: '미매출',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_notmisamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.notmisamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '설치매출',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '설치입금',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt',
    text: '설치미수금',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_iamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.iamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'jamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          잔액
        </FlexLayout>

        <FlexLayout>
          (수주-입금)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_jamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.jamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 5,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_qty_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty14',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          대수
        </FlexLayout>

        <FlexLayout>
          (설치)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 5,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_qty14_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.qty14)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty15',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          대수
        </FlexLayout>

        <FlexLayout>
          (리모델링)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 5,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_qty15_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.qty15)}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt2',
    text: '메출',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_misamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt2',
    text: '입금',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.tab_rcvamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.rcvamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt2',
    text: '미수금',
    width: 7,
    trail: (_) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {scope?.state.tab_iamt2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.iamt2)}
      </FlexLayout>
    ),
  },
]);
