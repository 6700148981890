import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { IndustryRate } from './IndustryRate';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_ca642w_16
 * @category 업종별 매출대비매입요율
 */
export const IndustryRateTemplate: React.FC<TemplateProps<IndustryRate>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.SS({ year: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={400}>
          <span>구분</span>
          <div>
            <OptionBox
              value={scope.state.gubun}
              data={[
                { value: '1', remark: '월' },
                { value: '2', remark: '분기' },
                { value: '3', remark: '반기' },
              ]}
              onChange={(item) => scope.setState({ gubun: String(item.value) }, () => scope.onRetrieveEvent())}
            >
            </OptionBox>
          </div>
        </FlexLayout>
      </SearchLayout>
    </FlexLayout>

    <FlexLayout>
      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'mon',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {
                    scope.state.gubun === '1' ? '월'
                      : scope.state.gubun === '2' ? '분기' : '반기'
                  }
                </FlexLayout>

                <FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            width: 30,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {
                  scope.state.gubun === '1' ? Date8.withDashMon(x.mon)
                    : scope.state.gubun === '2' ? x.bungi
                      : scope.state.gubun === '3' ? x.bangi : ''
                }
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'tot',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  전체(합)
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, 'tot')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamttot !== '0' ? Format.number(x.wkmisamttot) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamttot !== '0' ? Format.number(x.wkmijamttot) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyultot !== '0' ? Format.number(String(Number(x.monyultot).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamttot_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamttot_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyultot_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '0',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  보수
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, '0')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamt0 !== '0' ? Format.number(x.wkmisamt0) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamt0 !== '0' ? Format.number(x.wkmijamt0) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyul0 !== '0' ? Format.number(String(Number(x.monyul0).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamt0_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamt0_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyul0_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '1',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  설치
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, '1')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamt1 !== '0' ? Format.number(x.wkmisamt1) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamt1 !== '0' ? Format.number(x.wkmijamt1) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyul1 !== '0' ? Format.number(String(Number(x.monyul1).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamt1_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamt1_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyul1_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '2',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  공사
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, '2')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamt2 !== '0' ? Format.number(x.wkmisamt2) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamt2 !== '0' ? Format.number(x.wkmijamt2) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyul2 !== '0' ? Format.number(String(Number(x.monyul2).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamt2_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamt2_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyul2_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '3',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  전기
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, '3')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamt3 !== '0' ? Format.number(x.wkmisamt3) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamt3 !== '0' ? Format.number(x.wkmijamt3) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyul3 !== '0' ? Format.number(String(Number(x.monyul3).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamt3_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamt3_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyul3_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '4',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  도매
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, '4')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamt4 !== '0' ? Format.number(x.wkmisamt4) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamt4 !== '0' ? Format.number(x.wkmijamt4) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyul4 !== '0' ? Format.number(String(Number(x.monyul4).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamt4_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamt4_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyul4_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: '5',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  기타
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, '5')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamt5 !== '0' ? Format.number(x.wkmisamt5) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamt5 !== '0' ? Format.number(x.wkmijamt5) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyul5 !== '0' ? Format.number(String(Number(x.monyul5).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamt5_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamt5_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyul5_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
          {
            id: 'not',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  미입력
                </FlexLayout>

                <FlexLayout>
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                  >
                    매출금액
                  </FlexLayout>

                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                  >
                    매입금액
                  </FlexLayout>

                  <FlexLayout>
                    율(%)
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout onDoubleClick={() => scope.onRowDoubleCliked(true, 'not')}>
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.wkmisamtnot !== '0' ? Format.number(x.wkmisamtnot) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.wkmijamtnot !== '0' ? Format.number(x.wkmijamtnot) : ''}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {x.monyulnot !== '0' ? Format.number(String(Number(x.monyulnot).toFixed(0))) : ''}
                </FlexLayout>
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout>
                <FlexLayout
                  justify="right"
                  style={{ color: '#18B89E' }}
                >
                  {scope.state.wkmisamtnot_tot}
                </FlexLayout>

                <FlexLayout
                  justify="right"
                  style={{ color: '#B818E7' }}
                >
                  {scope.state.wkmijamtnot_tot}
                </FlexLayout>

                <FlexLayout justify="right">
                  {scope.state.monyulnot_tot}
                </FlexLayout>
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.industryData}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      >
      </GridLayout>
    </FlexLayout>

    <ModalView
      isVisible={scope.state.industryModal}
      width={1500}
      zIndex={-2}
      onClose={() => scope.onModalClose()}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle>업종별 매출대비매입요율</LayoutTitle>

        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={200}>
            <span>기간</span>

            <DateTextBox
              value={scope.state.modalStdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ modalStdate: value })}
              onEnter={() => scope.noDoubleClickModalRetrieve()}
              head={(
                <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.modalStdate));
                  date.setDate(date.getDate() - 1);
                  scope.SS({ modalStdate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
              )}
              trail={(
                <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.modalStdate));
                  date.setDate(date.getDate() + 1);
                  scope.SS({ modalStdate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.modalEnddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ modalEnddate: value })}
              onEnter={() => scope.noDoubleClickModalRetrieve()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.modalEnddate));
                  date.setDate(date.getDate() - 1);
                  scope.SS({ modalEnddate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.modalEnddate));
                  date.setDate(date.getDate() + 1);
                  scope.SS({ modalEnddate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>업종구분</span>

            <ComboBox
              value={scope.state.wkgubun}
              data={scope.state.wkgubuns?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
              onSelect={(option) => scope.setState({ wkgubun: option.value })}
            >
            </ComboBox>
          </FlexLayout>

          <FlexLayout size={400}>
            <span>검색어</span>

            <TextBox
              value={scope.state.modalSearchQuery}
              onChange={(value) => scope.SS({ modalSearchQuery: value })}
            >
            </TextBox>
          </FlexLayout>

          <FlexLayout
            size={100}
          >
            <Button
              style={{
                width: 40,
                height: 30,
              }}
              onClick={() => scope.noDoubleClickModalRetrieve()}
            >
              <div>조회</div>
            </Button>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.modalGrid}
            header={[
              {
                id: 'wkgubunnm',
                text: '업종구분',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.wkgubunnm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'projno',
                text: '프로젝트번호',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.projno.substr(0, 4)}-{x.projno.substr(4, 3)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'mismijamt',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매출금액
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매입금액
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 40,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {Format.number(x.mijamt)}
                    </FlexLayout>
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      style={{ color: '#18B89E' }}
                    >
                      {scope.state.misamt_tot}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      style={{ color: '#B818E7' }}
                    >
                      {scope.state.mijamt_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'mismijdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매출일자
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매입일자
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 30,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.mijdate)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'mismijnum',
                width: 20,
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매출번호
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매입번호
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.misnum}
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.mijnum}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'mismijremark',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매출비고
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매입비고
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.misremark}
                    </FlexLayout>

                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.mijremark}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'mismijcltnm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매출거래처
                    </FlexLayout>

                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      매입거래처
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.miscltnm}
                    </FlexLayout>

                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.mijcltnm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.industryModalData}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.onModalClose()}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>
  </FlexLayout>
);
