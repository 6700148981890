import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Status } from './Status';
import { TabModel } from '../../../../models';
import { StatusTabDivicdTemplate } from './tabs/StatusTabDivicd.template';
import { StatusTabDetailTemplate } from './tabs/StatusTabDetail.template';

/**
 * 화면 > 탭
 * @window w_tb_e471w
 * @category 공사현황
 */
export const StatusTab: Array<TabLayoutDataItem<Status>> = [
  {
    tab: new TabModel('DIVICD', FaCodeBranch, '부서별'),
    template: (scope) => <StatusTabDivicdTemplate scope={scope} />,
  },
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '상세현황(공사별)'),
    template: (scope) => <StatusTabDetailTemplate scope={scope} />,
  },
];
