import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models/common';
import { SupplyTemplate } from './Supply.template';
import {
  GridLayout,
  TableLayout,
} from '../../../../components/layout';
import {
  SupplyDetailModel,
  SupplyModel,
  BillPopupModel,
  OrderPopupModel,
} from './models';
import {
  ConfirmWarning,
} from '../../../../utils/confirm';
import { Format } from '../../../../utils/string';
import { PageComponent } from '../../../../utils';
import {
  Date8,
  DateStabilizer,
  Today,
} from '../../../../utils/time';

export enum SupplyItemChangeTypes {
  DELDATE,
  PERID,
  ACTCD,
  UAMT,
  SAMT,
  QTY,
  PCODE,
  PSERIAL
}

export enum SupplyItemChangeTypeNames {
  deldate,
  perid,
  actcd,
  uamt,
  samt,
  qty,
  pcode,
  pserial
}

interface SupplyState {

  // 검색 조건(메인)
  searchQuery: string;
  stdate: string;
  curdate: string;
  enddate: string;
  perid: string;
  pernm: string;

  // 검색 조건(팝업)
  popupSearchQuery: string;
  baldate: string;
  deldate: string;
  cltcd: string;
  cltnm: string;

  // 데이터 객체
  focusedSupply?: SupplyModel;
  focusedSupplyDetail?: SupplyDetailModel;
  data: SupplyModel;
  lastNewData: SupplyModel;
  supplyList: Array<SupplyModel>;
  supplyDetailList: Array<SupplyDetailModel>;
  billPopupList: Array<BillPopupModel>;
  billPopupFocused?: BillPopupModel;
  orderPopupList: Array<OrderPopupModel>;
  orderPopupFocused?: OrderPopupModel;

  // 팝업
  loadBillModal: boolean; // 자재청구서 불러오기
  loadOrderModal: boolean; // 발주서 불러오기

  samt_tot: string;
  taxcls: string;
}

/**
 * 컨트롤러
 * @window w_tb_da034_01
 * @category 불출등록
 */
export class Supply extends PageComponent<PageProps, SupplyState>
  implements PageToolEvents {
  updatedRows?: Array<SupplyDetailModel>;

  updatedRows2?: Array<BillPopupModel>;

  updatedRows3?: Array<OrderPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // 검색란
      curdate: `${year}${month}${date}`,
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',

      popupSearchQuery: '',

      data: new SupplyModel(),
      supplyList: [],
      supplyDetailList: [],

      samt_tot: '',
      deldate: Today.date(),
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          supplyList: [...this.state.supplyList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          supplyList: [],
          supplyDetailList: [],
          data: new SupplyModel(),
        });

        await this.infinity?.retrieveAll();

        this.state.supplyList.length > 0
          && await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      supplyList: [],
      supplyDetailList: [],
      data: new SupplyModel(),
      focusedSupply: new SupplyModel(),
    }, async () => {
      this.table?.current?.update(true);
      const index = await this.infinity?.retrieveTo(['deldate', 'delnum'], [lastSelected?.deldate, lastSelected?.delnum], type, true) || 0;
      if (this.state.supplyList && this.state.supplyList.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: SupplyModel) {
    if (item?.new === '1') {
      this.setState({
        focusedSupplyDetail: undefined,
        supplyDetailList: [],
        taxcls: item?.taxcls,
        data: this.state.lastNewData,
      });
      this.table.current?.update();
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState({ focusedSupply: item });
    this.infinity2 = new InfinityRetrieve(
      item,
      (params) => api.fxExec('dw_1_RowFocuschanged', params),
      (items) => {
        this.setState({
          supplyDetailList: [
            ...this.state.supplyDetailList,
            ...items.map((item) => new SupplyDetailModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({ supplyDetailList: [] });
        await this.infinity2?.retrieve();
        await this.table.current?.update();
        this.table.current?.setFocus(0, 2);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      supplyDetailList: [],
    }, async () => {
      const data = await this.infinity2?.retrieve();
      this.setState({
        data: new SupplyModel(data),
        samt_tot: this.infinity2?.box?.samt_tot,
      });
      if (!this.state.supplyDetailList.length) {
        const data = await api.fxExec('dw_1_RowFocuschanged',
          { ...item });
        this.setState({
          data: new SupplyModel(data),
          samt_tot: data?.samt_tot,
        });
      }
      await this.table.current?.update();
      this.table.current?.setFocus(0, 2);
    });
  }

  @action
  onRowFocusEvent2(item:SupplyDetailModel) {
    this.setState({ focusedSupplyDetail: item });
  }

  @action
  async onSaveEvent() {
    let zeroQty = false;

    // 결재 상신된 청구서
    if (this.state.supplyDetailList.length < 1) {
      ConfirmWarning.show('경고', '불출할 품목이 없습니다.');
      return;
    }

    this.state.supplyDetailList.forEach((x) => {
      if (x.qty === '0') {
        zeroQty = true;
      }
    });

    // 수량 0이면 저장못하게 막음
    if (zeroQty) {
      ConfirmWarning.show('경고', '수량이 0인품목이 있습니다 \n 수량을 입력해 주세요');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
      store: this.state.data.store,
      deltype: this.state.data.deltype,
      items: this.state.supplyDetailList,
    }, this.state.data.isNew)) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.deldate);
      await this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '' || this.state.enddate < Today.date()) {
      await this.SS({
        searchQuery: '',
        stdate: `${Date8.make().substr(0, 6)}01`,
        enddate: Date8.make(),
      });
    }

    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new SupplyModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        supplyList: [
          newModel,
          ...this.state.supplyList,
        ],
        supplyDetailList: [],
        focusedSupply: newModel,
      }, async () => {
        await this.table.current?.update(false);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `발주일자: ${this.state.focusedSupply?.deldate}, 발주번호: ${this.state.focusedSupply?.delnum}`;
    await api.delete(text, this.state.data) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        deldate: this.state.data?.deldate,
        delnum: this.state.data?.delnum,
        delseq: this.state.focusedSupplyDetail?.delseq,
      },
    );

    const seq = this.state.supplyDetailList.length;
    data && this.setState({
      supplyDetailList: [...this.state.supplyDetailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new SupplyDetailModel({ ...data, delseq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString() }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.supplyDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.supplyDetailList.length === 0) {
      ConfirmWarning.show('경고', '입고할 항목이 없습니다.');
      return;
    }
    const text = `${this.state.focusedSupplyDetail?.delseq}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        deldate: this.state.data?.deldate,
        delnum: this.state.data?.delnum,
        delseq: this.state.focusedSupplyDetail?.delseq,
        baldate: this.state.focusedSupplyDetail?.baldate,
        balnum: this.state.focusedSupplyDetail?.balnum,
        balseq: this.state.focusedSupplyDetail?.balseq,
        qty: this.state.focusedSupplyDetail?.qty,
        pcode: this.state.focusedSupplyDetail?.pcode,
        store: this.state.data?.store,
      },
    ) && this.onRowFocusEvent(this.state.focusedSupply!);
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };

    // if (type === SupplyItemChangeTypeNames.pserial) {
    //   await api.fxExec(
    //     'dw_3_itemchanged',
    //     {
    //       itemname: SupplyItemChangeTypeNames[type],
    //       deldate: this.state.data.deldate,
    //       delnum: this.state.data.delnum,
    //       delseq: this.state.focusedSupplyDetail?.delseq,
    //     },
    //   );
    // }

    if (type === SupplyItemChangeTypeNames.deldate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: SupplyItemChangeTypeNames[type],
          data: this.state.data.deldate,
          items3: this.state.supplyDetailList.length.toString(), // detail list count
          new: this.state.data.new,
        },
      );
    }

    if (type === SupplyItemChangeTypeNames.actcd
    || type === SupplyItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: SupplyItemChangeTypeNames[type],
          data: item,
          new: this.state.data.new,
        },
      );
    }

    data && this.setState({
      data: new SupplyModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const qty = Format.toNumber(item.qty);
    const uamt = Format.toNumber(item.uamt);
    let sum = 0;
    if (qty === 0 && name !== 'qty') {
      sum = Math.round(uamt);
    } else {
      sum = Math.round(qty * uamt);
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: sum.toString(),
    });
  }

  @action
  async loadBillModal(isOpen: boolean) {
    this.setState({ loadBillModal: isOpen });
    isOpen && await this.modalBillRetrieve();
  }

  @action
  async loadOrderModal(isOpen: boolean) {
    this.setState({ loadOrderModal: isOpen });
    isOpen && await this.modalOrderRetrieve();
  }

  @action
  async modalBillRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca606_02',
        as_nm: this.state.popupSearchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          billPopupList: [...this.state.billPopupList, ...items],
        }, () => this.popupTable.current?.update(false));
      },
      async () => {
        await this.SS({ billPopupList: [] });
        await this.infinity3?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      billPopupList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
    });
  }

  @action
  async modalBillDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_ca606_02',
      items: this.updatedRows2,
    }) && await this.modalBillRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalBillSave() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('save',
      {
        sub: 'w_popup_ca606_02',
        as_nm: this.state.popupSearchQuery,
        deldate: this.state.deldate || '',
        items: this.updatedRows2,
      });

    if (data) {
      await this.loadBillModal(false);
      await this.onRetrieveEvent();
      this.updatedRows2 = [];
    }
  }

  @action
  async modalOrderRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca608_02',
        as_nm: this.state.popupSearchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          orderPopupList: [...this.state.orderPopupList, ...items],
        }, () => this.popupTable.current?.update(false));
      },
      async () => {
        await this.SS({ orderPopupList: [] });
        await this.infinity3?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      orderPopupList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
    });
  }

  @action
  async modalOrderDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_ca608_02',
      items: this.updatedRows2,
    }) && await this.modalOrderRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalOrderSave() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('save',
      {
        sub: 'w_popup_ca608_02',
        as_nm: this.state.popupSearchQuery,
        deldate: this.state.deldate || '',
        items: this.updatedRows2,
      });

    if (data) {
      await this.loadOrderModal(false);
      await this.onRetrieveEvent();
      this.updatedRows2 = [];
    }
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */
  @action
  onUpdatedRows(rows: Array<SupplyDetailModel>, updatedRows: Array<SupplyDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ supplyDetailList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<BillPopupModel>, updatedRows2: Array<BillPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ billPopupList: rows2 });
  }

  @action
  onUpdatedRows3(rows3: Array<OrderPopupModel>, updatedRows3: Array<OrderPopupModel>) {
    this.updatedRows3 = updatedRows3;
    this.setState({ orderPopupList: rows3 });
  }

  render() {
    return (
      <SupplyTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new SupplyModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
