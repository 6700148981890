import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { StandardTemplate } from './Standard.template';
import { StandardModel, DiscountModel } from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface StandardState {
  searchQuery: string;
  searchContbild: string;
  contbilds?: Array<any>;
  data: Array<StandardModel>;
  discountData: DiscountModel;
  focused?: StandardModel;
  focusedDiscount?: DiscountModel;
}

/**
 * 컨트롤러
 * @window w_tb_e101_aver
 * @category 표준유지관리비
 */
export class Standard extends PageComponent<PageProps, StandardState>
  implements PageToolEvents {
  updatedRows?: Array<StandardModel>;

  updatedRows2?: Array<DiscountModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: Today.year(),
      searchContbild: '01',
      data: [],
      discountData: new DiscountModel(),
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 첫번째 서버 요청
    // 구분코드 리스트 가져오기
    // request 함수를 호출
    // 실패시 data에 undefined가 들어옴
    const data = await api.dropdown('wf_dd_ca510_028_01');

    // 서버 요청 실패시 빠져나감
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contbilds: data.items });

    // 선행되어야 하는 서버 호출이 모두 성공한 경우 [조회]
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.searchQuery,
        contbild: this.state.searchContbild,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: items.map((x: any) => new StandardModel(x)),
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update();
        this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [], // 조회 후 신규 시 행 예외에 걸려서 추가
      discountData: new DiscountModel(),
    }, async () => {
      const data = await this.infinity?.retrieve();
      await this.table.current?.update(true);
      this.table.current?.setFocus(0, 1);

      this.SS({ discountData: new DiscountModel(data) });
    });
  }


  // @action
  // async onSaveEvent() {
  //   const { actionStore: api } = this.props;
  //   if (await api.save({
  //     items: this.updatedRows,
  //   }, false)) {
  //     this.updatedRows = [];
  //     this.table.current?.resetUpdates();
  //   }
  // }


  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      year: this.state.searchQuery,
      contbild: this.state.searchContbild,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      year: this.state.searchQuery,
      contbild: this.state.searchContbild,
    });
  }

  @action
  onRowFocusEvent(item:StandardModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<StandardModel>, updatedRows: Array<StandardModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <StandardTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
