import * as React from 'react';
import {
  RiBillLine,
  RiBuildingLine,
  RiFileList2Line,
  RiFileTextLine,
} from 'react-icons/ri';
import {
  action,
  computed,
} from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  TableLayoutHeader,
} from '../../../../constants';
import { ManagementTemplate } from './Management.template';
import { TabModel } from '../../../../models/component';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  TabHeaderAction,
  TabHeaderCancel,
  TabHeaderCompany,
  TabHeaderComplaint,
} from './tabs';
import {
  ActionModel,
  CancelModel,
  CompanyModel,
  CompanyModel2,
  ComplaintModel,
} from './models';
import { InfinityRetrieve } from '../../../../models/common';
import {
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { Finder } from '../../../../utils/array';
import {
  FileReader,
  FileSelector,
} from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';
import { PageComponent } from '../../../../utils';

export enum ManagementImages {
  E018_1_PIC
}

export const ManagementTabModels = [
  ComplaintModel,
  ActionModel,
  CancelModel,
  CompanyModel,
];

export const ManagementTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
];

export const ManagementTabUpdatesStateNames = [
  'complainUpdates',
  'actionUpdates',
  'cancelUpdates',
  'companyUpdates',
];

export const ManagementTabFocusedStateNames = [
  'complainFocused',
  'actionFocused',
  'cancelFocused',
  'companyFocused',
];

export const imgFunctionNames = [
  'tb_e018_1_pic',
];

export const imgStateNames = [
  'img_e018_1_pic',
];

export const imgStatePercentNames = [
  'percentImageE018',
];

export interface ManagementState {
  // 분류코드
  complain?: Array<ComplaintModel>;
  complainUpdates?: Array<ComplaintModel>;
  complainFocused?: ComplaintModel;

  action?: Array<ActionModel>;
  actionUpdates?: Array<ActionModel>;
  actionFocused?: ActionModel;

  cancel?: Array<CancelModel>;
  cancelUpdates?: Array<CancelModel>;
  cancelFocused?: CancelModel;

  company?: Array<CompanyModel>;
  companyUpdates?: Array<CompanyModel>;
  companyFocused?: CompanyModel;
  companyDetail?: Array<CompanyModel2>;
  companyDetailUpdates?: Array<CompanyModel2>;
  companyFocusedDetail?: CompanyModel2;

  focusedTab?: TabModel;

  img_e018_1_pic?: ArrayBuffer;
  percentImageE018?: number;
}

export const ManagementTabTitles = [
  '불만내용',
  '조치결과',
  '해지사유',
  '회사구분',
];

export enum ManagementTabId {
  COMPLAINT,
  ACTION,
  CANCEL,
  COMPANY,
}

export const ManagementTabDataStateNames = [
  'company',
  'action',
  'cancel',
  'company',
];

/**
 * 컨트롤러
 * @window w_tb_e010
 * @category 유지보수관리코드
 */
export class Management extends PageComponent<PageProps, ManagementState>
  implements PageToolEvents {
  updatedRows2?: Array<CompanyModel2>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = ManagementTabId;
    const titles = ManagementTabTitles;

    this.tabs = [
      new TabModel(id.COMPLAINT.toString(), RiFileList2Line, titles[id.COMPLAINT]),
      new TabModel(id.ACTION.toString(), RiBillLine, titles[id.ACTION]),
      new TabModel(id.CANCEL.toString(), RiFileTextLine, titles[id.CANCEL]),
      new TabModel(id.COMPANY.toString(), RiBuildingLine, titles[id.COMPANY]),
    ];

    this.state = props.state || {
      focusedTab: this.tabs[ManagementTabId.COMPLAINT],
      data: [],
    };

    this.tabHeaders = [
      // 불만내용
      TabHeaderComplaint,

      // 조치결과
      TabHeaderAction,

      // 해지사유
      TabHeaderCancel,

      // 회사구분
      TabHeaderCompany,
    ];
  }


  @action
  async imageRequest(i: number) {
    const { actionStore: api } = this.props;
    const index = this.tabIndex;
    const blob = await api.fxBinary(
      `${ManagementTabFunctionNames[index]}_${imgFunctionNames[i]}_retrieve`,
      {
        spjangcd: this.state.companyFocused?.spjangcd,
        wkactcd: this.state.companyFocused?.wkactcd,
      },
    );


    // @ts-ignore
    this.setState({
      [imgStateNames[i]]: blob,
    });
  }

  @action
  async imageDelete(i: number) {
    const { actionStore: api } = this.props;
    const index = this.tabIndex;

    if (await api.exec(
      Category.GENERAL,
      `${ManagementTabFunctionNames[index]}_${imgFunctionNames[i]}_delete`,
      {
        custcd: this.state.companyFocused?.custcd,
        spjangcd: this.state.companyFocused?.spjangcd,
        wkactcd: this.state.companyFocused?.wkactcd,
      },
    )) {
      await this.imageRequest(i);
      ConfirmSuccess.show('저장', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api } = this.props;
    const index = this.tabIndex;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${ManagementTabFunctionNames[index]}_${imgFunctionNames[i]}_save`,
        {
          custcd: this.state.companyFocused?.custcd,
          spjangcd: this.state.companyFocused?.spjangcd,
          wkactcd: this.state.companyFocused?.wkactcd,
          fileext: 'png',
          tempfile: tempInfo.data,
        },
        false,
      )) {
        this.imageRequest(i);
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  async onFirstOpenEvent() {
    await this.onTabChange(this.tabs[ManagementTabId.COMPLAINT]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    this.updatedRows2 = [];
    await this.doRetrieve(i);
  }


  @action
  async doRetrieve(i: ManagementTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => api.fxExec(
        `${ManagementTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [ManagementTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[ManagementTabDataStateNames[i]],
                ...items.map((x: any) => new ManagementTabModels[i](x))],
          }, () => this.table.current?.update(false));
        }
      },
      async () => {
        await this.SS({
          [ManagementTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [ManagementTabDataStateNames[i]]: [],
      [ManagementTabUpdatesStateNames[i]]: [],
      [ManagementTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.infinity?.retrieve();
      await this.table.current?.update(true);
      this.table.current?.setFocus(0, 0);
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const data = await api.fxExec(
      `${ManagementTabFunctionNames[i]}_new`,
    );

    // @ts-ignore
    data && this.setState({
      [ManagementTabDataStateNames[i]]: [
        new ManagementTabModels[i](data, true),
        // @ts-ignore
        ...this.state[ManagementTabDataStateNames[i]],
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 0);
    });
  }


  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;

    const companyDetail = await api.fxExec(
      'tab_4_dw_2_new',
      {
        wkactcd: this.state.companyFocused?.wkactcd,
        wkactnm: this.state.companyFocused?.wkactnm,
      },
    );

    companyDetail && this.setState({
      companyDetail: [
        new CompanyModel2(companyDetail, true),
        ...(this.state.companyDetail || []),
      ],
    }, async () => {
      await this.tableDetail.current?.update(false);
      if (this.state.companyDetail?.length) {
        this.tableDetail.current?.setFocus(0, 0);
      }
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    await api.fxSave(
      `${ManagementTabFunctionNames[i]}_save`,
      {
        // @ts-ignore
        items: this.state[ManagementTabUpdatesStateNames[i]],
        items2: this.updatedRows2,
      },
      true,
    );
    this.updatedRows2 = [];
    this.setState({
      [ManagementTabUpdatesStateNames[i]]: [],
    });

    this.doRetrieve(i);
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    const item = this.state[ManagementTabFocusedStateNames[i]];

    if (!item) {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
      return;
    }

    let text = '';
    switch (i) {
      case ManagementTabId.COMPLAINT:
        text = `${item.disconcd} - ${item.disconnm}`;
        break;

      case ManagementTabId.ACTION:
        text = `${item.maresult} - ${item.maresultnm}`;
        break;

      case ManagementTabId.CANCEL:
        text = `${item.outcd} - ${item.outnm}`;
        break;

      case ManagementTabId.COMPANY:
        text = `${item.wkactcd} - ${item.wkactnm}`;
        break;

      default:
        text = '선택된 행을 삭제하시겠습니까?';
    }

    await api.fxDelete(
      `${ManagementTabFunctionNames[i]}_delete`,
      text,
      // @ts-ignore
      this.state[ManagementTabFocusedStateNames[i]],
    );

    this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    const text = `${this.state.companyFocusedDetail?.seq} - ${this.state.companyFocusedDetail?.pernm}`;
    await api.fxDelete(
      'tab_4_dw_2_delete',
      text,
      {
        wkactcd: this.state.companyFocusedDetail?.wkactcd,
        seq: this.state.companyFocusedDetail?.seq,
      },
    ) && this.onRowFocusEvent2(this.state.companyFocusedDetail!);

    this.onRetrieveEvent();
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[ManagementTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        this.setState({
          [ManagementTabUpdatesStateNames[i]]: [],
          [ManagementTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  onRowFocusEvent(item: CompanyModel) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    this.updatedRows2 = [];

    this.setState({ companyFocused: item }, async () => {
      await this.imageRequest(ManagementImages.E018_1_PIC);
      const companyDetail = await api.fxExec(
        `${ManagementTabFunctionNames[i]}_dw_1_RowFocuschanged`,
        this.state.companyFocused,
      );

      if (companyDetail?.items) {
        this.setState({
          companyDetail: companyDetail.items.map((x: any) => new CompanyModel2(x)),
        }, () => this.tableDetail.current?.update());
      } else {
        this.setState({
          companyDetail: [],
        }, () => this.tableDetail.current?.update());
      }
    });
  }

  @action
  onRowFocusEvent2(item: CompanyModel2) {
    if (item.new === '1') return;
    this.setState({ companyFocusedDetail: item });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[ManagementTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${ManagementTabFunctionNames[i]}_print`,
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[ManagementTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${ManagementTabFunctionNames[i]}_excel`,
    );
  }

  @computed
  get tabIndex(): ManagementTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [ManagementTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [ManagementTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows2
   * @param updatedRows2
   */
  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({
      companyDetail: rows2,
    });
  }

  render() {
    return (
      <ManagementTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
        requestRowFocusEvent={() => {
          this.state.company && this.onRowFocusEvent(this.state.company[Finder.index(
            this.state.company,
            this.state.companyFocused,
            'wkactcd',
          )]);
        }}
      />
    );
  }
}
