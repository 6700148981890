import { AiOutlineCloudUpload, GoChecklist } from 'react-icons/all';
import { Global, TLHF } from '../../../../../constants';
import {
  Button, ComboBox, ComboBoxModel, DateTextBox, FlexLayout, FormatTextBox, TextBox,
} from '../../../../../components';
import { Journal } from '../Journal';
import JournalModel from '../models/JournalModel';
import { SearchBinding } from '../../../../../models';

/**
 * 화면 > 팝업 > 그리드 > 팝업 > 테이블 레이아웃
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export const JournalTableHeader: TLHF<JournalModel, Journal> = (scope) => ([
  {
    id: 'state',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>결과</FlexLayout>
        <FlexLayout>결재</FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x, rowUpdate) => (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          <ComboBox
            value={x.state}
            textAlign="center"
            style={x.state === '1' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
            data={[
              { key: '1', value: '미처리' },
              { key: '2', value: '처리' },
            ].map((y) => new ComboBoxModel(y.key, y.value))}
            onSelect={(v) => rowUpdate({ state: v.value })}
          />
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          {scope.getGubunnm(x.appgubun)}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>계획일자</FlexLayout>
        <FlexLayout>점검일자</FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x, rowUpdate) => (
      <FlexLayout isVertical={true}>
        <FlexLayout>
          <DateTextBox
            value={x.plandate}
            textAlign="center"
            format="####-##-##"
            onChange={(v) => rowUpdate({ plandate: v })}
          />
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          <DateTextBox
            value={x.kcdate}
            textAlign="center"
            format="####-##-##"
            onChange={(v) => rowUpdate({ kcdate: v })}
          />
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'kclog',
    text: (
      <FlexLayout>
        점검<br />일지
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
          style={{
            fontSize: 16,
            ...(x.kclog === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED),
          }}
        >
          {x.kclog === '1' ? 'O' : 'X'}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          <Button
            isIcon={true}
            onClick={() => {
              scope.retrieveGrid();
              scope.SS({ isVisibleGrid: true });
            }}
          >
            <GoChecklist size={24} />
          </Button>
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 80,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ actcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });

            scope.onTableItemChanged(rowUpdate, 'actcd', item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '호기코드',
    width: 80,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.equpcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ equpcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: x.actcd },
          true,
          (item) => {
            rowUpdate({
              equpcd: item.cd,
              equpnm: item.cdnm,
            });

            scope.onTableItemChanged(rowUpdate, 'equpcd', item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'scpernm',
    text: '설치자',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'perid',
    text: '담당자',
    width: 80,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.perid}
        onChange={(value) => rowUpdate({ perid: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_JA001',
          {},
          true,
          (item) => {
            rowUpdate({
              perid: item.cd,
              pernm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'kcperid',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout style={Global.STYLE_COLOR_RED}>처리자</FlexLayout>
        <FlexLayout style={Global.STYLE_COLOR_RED}>보조자</FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FlexLayout isVertical={true}>
        <TextBox
          ref={ref}
          value={x.kcperid}
          textAlign="center"
          onChange={(value) => rowUpdate({ kcperid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              rowUpdate({
                kcperid: item.cd,
                kcpernm: item.cdnm,
              });
            },
          )}
        />

        <TextBox
          ref={ref}
          value={x.kcperid2}
          textAlign="center"
          onChange={(value) => rowUpdate({ kcperid2: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              rowUpdate({
                kcperid2: item.cd,
                kcpernm2: item.cdnm,
              });
            },
          )}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>처리자명</FlexLayout>
        <FlexLayout>보조자명</FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {x.kcpernm}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          {x.kcpernm2}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '특이사항',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
  {
    id: 'appnum',
    text: '문서번호',
    width: 150,
    render: (x) => {
      const { user } = scope.props.publicStore;
      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          <FormatTextBox
            format={x.appnum && '##-######-###-####'}
            textAlign="center"
            value={user.papernum + x.appnum}
            transparent={true}
            readonly={true}
          />
        </FlexLayout>
      );
    },
  },
  {
    id: 'attach',
    text: (
      <FlexLayout
        align="center"
        justify="center"
      >
        첨부<br />(사진)
      </FlexLayout>
    ),
    width: 80,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
          style={{
            fontSize: 16,
            ...(x.attcnt > '0' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED),
          }}
        >
          {x.attcnt > '0' ? 'O' : 'X'}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          <Button
            isIcon={true}
            onClick={() => scope.SS({ isVisibleAttachment: true })}
          >
            <AiOutlineCloudUpload size={24} />
          </Button>
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
