import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { DayDetailModel } from './models/DayDetail.model';
import { DayTemplate } from './Day.template';
import {
  InfinityRetrieve,
  SpjangItem,
} from '../../../../models/common';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { Today } from '../../../../utils/time';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export interface DayListItem {
  workday: string;
  weekday: string;
}

interface DayState {
  mon: string;
  spjangcd: string;
  mpclafi: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  atdcd: string;
  workday: string;
  workday2: string;
  dayList: Array<DayListItem>;
  dayDetailList: Array<DayDetailModel>;
  focusedDayDetailList: Array<DayDetailModel>;
  focusedDay?: DayListItem;
  focusedDayDetail? : DayDetailModel;
  dayData: DayDetailModel;
  buttonData: DayDetailModel;
  focusedDetailIndex: number;
  spjangcds: Array<SpjangItem>;
  atdcds: Array<any>;
  ordercds: Array<any>
  newDetailModal?: boolean;
  sign?: boolean;
  printChkAll: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb201
 * @category 일근태등록
 */
export class Day extends PageComponent<PageProps, DayState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<DayDetailModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  newCheck?: boolean;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      focusedDetailIndex: 0,
      mon: Today.yearMon(),
      spjangcd: 'ZZ',
      mpclafi: '0',
      divicd: '',
      perid: '',
      divinm: '',
      pernm: '',
      st_lat: '',
      st_lng: '',
      workday: Today.date(),
      workday2: Today.date(),
      dayData: '',
      appointDetailList: [],
      appointList: [],
      newCheck: false,
      printChkAll: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 세번째 서버 요청
    // 사업장 리스트 가져오기
    const data = await api.dropdown('wf_dd_pb002');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ atdcds: data.items });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.END) {
    const { actionStore: api } = this.props;

    const apiParams = {
      mon: this.state.mon,
      spjangcd: this.state.spjangcd,
      mpclafi: this.state.mpclafi,
    };

    const data = await api.retrieve(apiParams);
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        mon: this.state.mon,
        spjangcd: this.state.spjangcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          dayList: [...this.state.dayList, ...items],
          focusedDay: data,
        }, next);
      },
      async () => {
        await this.SS({
          dayList: [],
          dayDetailList: [],
          dayData: new DayDetailModel(data, true),
        });

        await this.infinity?.retrieveAll();
        if (this.state.dayList.length > 0) {
          await this.table.current?.setFocus(this.state.dayList.length - 1);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      dayList: [],
      dayDetailList: [],
      dayData: new DayDetailModel(data, true),
    }, async () => {
      const index = await this.infinity?.retrieveTo('workday', this.state.focusedDay?.workday, type, true) || 0;
      await this.table.current?.update();
      this.state.dayList.length > index && this.table.current?.setFocus(index);

      const addChk: any[] = [];
      this.state.dayList.forEach((x: any) => {
        addChk.push(new DayDetailModel({
          ...x,
          chk: '0',
        }));
      });
      await this.setState({
        dayList: addChk,
      });
    });
  }

  @action
  async onRowFocusEvent(item: DayListItem) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    await this.SS({ dayDetailList: [], focusedDayDetailList: [] });

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd || [],
        workday: item?.workday,
        mpclafi: this.state.mpclafi,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.fxExec('dw_1_RowFocuschanged', params),
      (items) => {
        this.setState({
          dayDetailList: [...this.state.dayDetailList,
            ...items.map((x: any) => new DayDetailModel(x, false))],
          focusedDay: item,
        }, () => this.tableDetail.current?.update(false));
      },
      async () => {
        await this.SS({ dayDetailList: [] });
        await this.infinity2?.retrieveAll();
        await this.tableDetail.current?.update();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.setState({
      dayDetailList: [],
    }, async () => {
      await this.infinity2?.retrieveAll();
      await this.tableDetail.current?.update();
      await this.tableDetail.current?.setFocus(0);
    });
  }

  @action
  async onDetailRowFocusEvent(item: DayDetailModel) {
    this.setState({ focusedDayDetail: item });
  }

  @action
  async buttonClicked(item: any) {
    this.setState({
      focusedDay: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const buttonData = await api.fxExec(
        'dw_2_buttonclicked',
        {
          itemname: 'b_gps',
          perid: item.perid,
          pernm: item.pernm,
          st_lat: item.st_lat,
          st_lng: item.st_lng,
          workday: item.workday,
        },
      );
      if (buttonData?.url) {
        window.open(buttonData?.url);
        // window.open(`https://api.elmansoft.com/etc/mapDaumMakers.php?st=${buttonData?.st_lat},${buttonData?.st_lng}&end=${buttonData?.office_lat},${buttonData?.office_lng}`);
      }
    });
  }

  @action
  async buttonClicked2(item: any) {
    this.setState({
      focusedDay: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const buttonData = await api.fxExec(
        'dw_2_buttonclicked',
        {
          itemname: 'b_gps2',
          perid: item.perid,
          pernm: item.pernm,
          end_lat: item.end_lat,
          end_lng: item.end_lng,
          workday: item.workday,
        },
      );

      if (buttonData?.url) {
        window.open(buttonData?.url);
        // window.open(`https://api.elmansoft.com/etc/mapDaumMakers.php?st=${buttonData?.end_lat},${buttonData?.end_lng}&
        // end=${buttonData?.office_lat},${buttonData?.office_lng}`);
      }
    });
  }

  @action
  async buttonClicked3(item: any) {
    this.setState({
      focusedDay: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const buttonData = await api.fxExec(
        'dw_2_buttonclicked',
        {
          itemname: 'b_gps3',
          perid: item.perid,
          pernm: item.pernm,
          atdcd: item.atdcd,
          stdate: item.workday,
          end_lat: item.end_lat,
        },
      );
      if (buttonData) {
        // window.open(buttonData?.url);
        window.open(`https://api.elmansoft.com/monitor/?database=${buttonData?.custcd}&perid=${buttonData?.perid}&stdate=${buttonData?.stdate}&enddate=${buttonData?.stdate}`);
      }
    });
  }

  @action
  async onNewEvent() {
    this.newModal(true, true);
  }

  @action
  async newModal(isOpen: boolean, okSign: boolean) {
    if (!isOpen && this.state.newDetailModal && !okSign) {
      const { actionStore: api } = this.props;
      const dayData = await api.new({
        spjangcd: this.state.spjangcd,
        mpclafi: this.state.mpclafi,
        workday: this.state.workday2,
      });
      if (dayData) {
        this.setState({
          dayList: [...this.state.dayList, new DayDetailModel(dayData, true)],
        }, async () => {
          this.table.current?.setFocus(this.state.dayList.length - 1);
        });
      }
      this.newCheck = true;
    }

    this.setState({ newDetailModal: isOpen, sign: okSign });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    if (await api.save(
      {
        items: this.updatedRows2,
        spjangcd: user.spjangcd,
        mpclafi: this.state.mpclafi,
        workday: this.state.focusedDay?.workday,
        divicd: this.state.divicd,
        perid: this.state.perid,
      }, true,
    )) {
      this.updatedRows2 = [];
      this.tableDetail.current?.resetUpdates();

      await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `일자:${this.state.focusedDay?.workday}를 모두 삭제하시겠습니까?`;
    await api.fxDelete(
      'delete',
      text,
      {
        new: this.newCheck ? '1' : '0',
        spjangcd: this.state.spjangcd,
        workday: this.state.focusedDay?.workday,
      },
    );
    await this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    if (!ConfirmWarning.assert(this.state.dayDetailList.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.printWithElmanManager({
      spjangcd: user.spjangcd,
      divicd: this.state.divicd,
      perid: this.state.perid,
      mon: this.state.mon,
      items: this.state.dayList,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    if (!ConfirmWarning.assert(this.state.dayDetailList.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      spjangcd: user.spjangcd,
      workday: this.state.focusedDay?.workday,
      mpclafi: this.state.mpclafi,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  onUpdatedRows(rows: Array<DayListItem>) {
    this.setState({ dayList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<DayDetailModel>, updatedRows2: Array<DayDetailModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ dayDetailList: rows2 });
  }

  @action
  async printCheckAll(value: boolean) {
    let check = '0';

    if (value) {
      check = '1';

      await this.SS({
        dayList: this.state.dayList.map((x) => new DayDetailModel({
          ...x,
          chk: '1',
        })),
      });
    } else {
      await this.SS({
        dayList: this.state.dayList.map((x) => new DayDetailModel({
          ...x,
          chk: '0',
        })),
      });
    }
    await this.SS({
      printChkAll: check,
    });
    this.table.current?.update(false);
  }

  render() {
    return (
      <DayTemplate
        scope={this}
        update={(change, callback) => {
          this.setState(change, () => callback && callback());
        }}
      />
    );
  }
}
