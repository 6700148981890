import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e601w_sulchi_11
 * @category 설치 월별 수입/지출 현황
 */
export class OrderMonthlyModel {
  /**
   * 회사코드
   *
   */
  readonly custcd: string;

  /**
   * 거래처코드
   *
   */
  readonly actcd: string;


  /**
   * 거래처명
   *
   */
  readonly actnm: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon01: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon02: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon03: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon04: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon05: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon06: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon07: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon08: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon09: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon10: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon11: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmon12: string;

  /**
   * 수금예정
   *
   */
  readonly rcvexmontot: string;

  /**
   * 수금
   *
   */
  readonly rcvmon01: string;

  /**
   * 수금
   *
   */
  readonly rcvmon02: string;

  /**
   * 수금
   *
   */
  readonly rcvmon03: string;

  /**
   * 수금
   *
   */
  readonly rcvmon04: string;

  /**
   * 수금
   *
   */
  readonly rcvmon05: string;

  /**
   * 수금
   *
   */
  readonly rcvmon06: string;

  /**
   * 수금
   *
   */
  readonly rcvmon07: string;

  /**
   * 수금
   *
   */
  readonly rcvmon08: string;

  /**
   * 수금
   *
   */
  readonly rcvmon09: string;

  /**
   * 수금
   *
   */
  readonly rcvmon10: string;

  /**
   * 수금
   *
   */
  readonly rcvmon11: string;

  /**
   * 수금
   *
   */
  readonly rcvmon12: string;

  /**
   * 수금
   *
   */
  readonly rcvmontot: string;

  /**
   * 예정 + 수금
   *
   */
  readonly rcvtotmon01: string;

  readonly rcvtotmon02: string;

  readonly rcvtotmon03: string;

  readonly rcvtotmon04: string;

  readonly rcvtotmon05: string;

  readonly rcvtotmon06: string;

  readonly rcvtotmon07: string;

  readonly rcvtotmon08: string;

  readonly rcvtotmon09: string;

  readonly rcvtotmon10: string;

  readonly rcvtotmon11: string;

  readonly rcvtotmon12: string;

  readonly rcvtotmontot: string;

  /**
   * 매입 ( 지출 )
   *
   */
  readonly mijamt01: string;

  readonly mijamt02: string;

  readonly mijamt03: string;

  readonly mijamt04: string;

  readonly mijamt05: string;

  readonly mijamt06: string;

  readonly mijamt07: string;

  readonly mijamt08: string;

  readonly mijamt09: string;

  readonly mijamt10: string;

  readonly mijamt11: string;

  readonly mijamt12: string;

  readonly mijamttot: string;

  /**
   * 지급 ( 지출 )
   *
   */
  readonly sndamt01: string;

  readonly sndamt02: string;

  readonly sndamt03: string;

  readonly sndamt04: string;

  readonly sndamt05: string;

  readonly sndamt06: string;

  readonly sndamt07: string;

  readonly sndamt08: string;

  readonly sndamt09: string;

  readonly sndamt10: string;


  readonly sndamt11: string;

  readonly sndamt12: string;

  readonly sndamttot: string;

  /**
   * 미지급 ( 지출 )
   *
   */
  readonly iamt01: string;

  readonly iamt02: string;

  readonly iamt03: string;

  readonly iamt04: string;

  readonly iamt05: string;

  readonly iamt06: string;

  readonly iamt07: string;

  readonly iamt08: string;

  readonly iamt09: string;

  readonly iamt10: string;

  readonly iamt11: string;

  readonly iamt12: string;

  readonly iamttot: string;

  /**
   * 예정+수금 ( 예산 )
   *
   */
  readonly rcvtotmon01_2: string;

  readonly rcvtotmon02_2: string;

  readonly rcvtotmon03_2: string;

  readonly rcvtotmon04_2: string;

  readonly rcvtotmon05_2: string;

  readonly rcvtotmon06_2: string;

  readonly rcvtotmon07_2: string;

  readonly rcvtotmon08_2: string;

  readonly rcvtotmon09_2: string;

  readonly rcvtotmon10_2: string;

  readonly rcvtotmon11_2: string;

  readonly rcvtotmon12_2: string;

  readonly rcvtotmontot_2: string;

  /**
   * 매입 ( 예산 )
   *
   */
  readonly mijamt01_2: string;

  readonly mijamt02_2: string;

  readonly mijamt03_2: string;

  readonly mijamt04_2: string;

  readonly mijamt05_2: string;

  readonly mijamt06_2: string;

  readonly mijamt07_2: string;

  readonly mijamt08_2: string;

  readonly mijamt09_2: string;

  readonly mijamt10_2: string;

  readonly mijamt11_2: string;

  readonly mijamt12_2: string;

  readonly mijamttot_2: string;

  /**
   * 차액 ( 예산 )
   *
   */
  readonly totex01: string;

  readonly totex02: string;

  readonly totex03: string;

  readonly totex04: string;

  readonly totex05: string;

  readonly totex06: string;

  readonly totex07: string;

  readonly totex08: string;

  readonly totex09: string;

  readonly totex10: string;

  readonly totex11: string;

  readonly totextot: string;

  readonly totex12: string;

  /**
   * 신규 new
   *
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.rcvexmon01 = data.rcvexmon01 || '';
    this.rcvexmon02 = data.rcvexmon02 || '';
    this.rcvexmon03 = data.rcvexmon03 || '';
    this.rcvexmon04 = data.rcvexmon04 || '';
    this.rcvexmon05 = data.rcvexmon05 || '';
    this.rcvexmon06 = data.rcvexmon06 || '';
    this.rcvexmon07 = data.rcvexmon07 || '';
    this.rcvexmon08 = data.rcvexmon08 || '';
    this.rcvexmon09 = data.rcvexmon09 || '';
    this.rcvexmon10 = data.rcvexmon10 || '';
    this.rcvexmon11 = data.rcvexmon11 || '';
    this.rcvexmon12 = data.rcvexmon12 || '';
    this.rcvexmontot = data.rcvexmontot || '';
    this.rcvmon01 = data.rcvmon01 || '';
    this.rcvmon02 = data.rcvmon02 || '';
    this.rcvmon03 = data.rcvmon03 || '';
    this.rcvmon04 = data.rcvmon04 || '';
    this.rcvmon05 = data.rcvmon05 || '';
    this.rcvmon06 = data.rcvmon06 || '';
    this.rcvmon07 = data.rcvmon07 || '';
    this.rcvmon08 = data.rcvmon08 || '';
    this.rcvmon09 = data.rcvmon09 || '';
    this.rcvmon10 = data.rcvmon10 || '';
    this.rcvmon11 = data.rcvmon11 || '';
    this.rcvmon12 = data.rcvmon12 || '';
    this.rcvmontot = data.rcvmontot || '';
    this.rcvtotmon01 = data.rcvtotmon01 || '';
    this.rcvtotmon02 = data.rcvtotmon02 || '';
    this.rcvtotmon03 = data.rcvtotmon03 || '';
    this.rcvtotmon04 = data.rcvtotmon04 || '';
    this.rcvtotmon05 = data.rcvtotmon05 || '';
    this.rcvtotmon06 = data.rcvtotmon06 || '';
    this.rcvtotmon07 = data.rcvtotmon07 || '';
    this.rcvtotmon08 = data.rcvtotmon08 || '';
    this.rcvtotmon09 = data.rcvtotmon09 || '';
    this.rcvtotmon10 = data.rcvtotmon10 || '';
    this.rcvtotmon11 = data.rcvtotmon11 || '';
    this.rcvtotmon12 = data.rcvtotmon12 || '';
    this.rcvtotmontot = data.rcvtotmontot || '';
    this.mijamt01 = data.rcvtotmon01 || '';
    this.mijamt02 = data.rcvtotmon02 || '';
    this.mijamt03 = data.rcvtotmon03 || '';
    this.mijamt04 = data.rcvtotmon04 || '';
    this.mijamt05 = data.rcvtotmon05 || '';
    this.mijamt06 = data.rcvtotmon06 || '';
    this.mijamt07 = data.rcvtotmon07 || '';
    this.mijamt08 = data.rcvtotmon08 || '';
    this.mijamt09 = data.rcvtotmon09 || '';
    this.mijamt10 = data.rcvtotmon10 || '';
    this.mijamt11 = data.rcvtotmon11 || '';
    this.mijamt12 = data.rcvtotmon12 || '';
    this.mijamttot = data.rcvtotmontot || '';
    this.sndamt01 = data.sndamt01 || '';
    this.sndamt02 = data.sndamt02 || '';
    this.sndamt03 = data.sndamt03 || '';
    this.sndamt04 = data.sndamt04 || '';
    this.sndamt05 = data.sndamt05 || '';
    this.sndamt06 = data.sndamt06 || '';
    this.sndamt07 = data.sndamt07 || '';
    this.sndamt08 = data.sndamt08 || '';
    this.sndamt09 = data.sndamt09 || '';
    this.sndamt10 = data.sndamt10 || '';
    this.sndamt11 = data.sndamt11 || '';
    this.sndamt12 = data.sndamt12 || '';
    this.sndamttot = data.sndamttot || '';
    this.iamt01 = data.iamt01 || '';
    this.iamt02 = data.iamt02 || '';
    this.iamt03 = data.iamt03 || '';
    this.iamt04 = data.iamt04 || '';
    this.iamt05 = data.iamt05 || '';
    this.iamt06 = data.iamt06 || '';
    this.iamt07 = data.iamt07 || '';
    this.iamt08 = data.iamt08 || '';
    this.iamt09 = data.iamt09 || '';
    this.iamt10 = data.iamt10 || '';
    this.iamt11 = data.iamt11 || '';
    this.iamt12 = data.iamt12 || '';
    this.iamttot = data.iamttot || '';
    this.rcvtotmon01_2 = data.rcvtotmon01_2 || '';
    this.rcvtotmon02_2 = data.rcvtotmon02_2 || '';
    this.rcvtotmon03_2 = data.rcvtotmon03_2 || '';
    this.rcvtotmon04_2 = data.rcvtotmon04_2 || '';
    this.rcvtotmon05_2 = data.rcvtotmon05_2 || '';
    this.rcvtotmon06_2 = data.rcvtotmon06_2 || '';
    this.rcvtotmon07_2 = data.rcvtotmon07_2 || '';
    this.rcvtotmon08_2 = data.rcvtotmon08_2 || '';
    this.rcvtotmon09_2 = data.rcvtotmon09_2 || '';
    this.rcvtotmon10_2 = data.rcvtotmon10_2 || '';
    this.rcvtotmon11_2 = data.rcvtotmon11_2 || '';
    this.rcvtotmon12_2 = data.rcvtotmon12_2 || '';
    this.rcvtotmontot_2 = data.rcvtotmontot_2 || '';
    this.mijamt01_2 = data.mijamt01_2 || '';
    this.mijamt02_2 = data.mijamt02_2 || '';
    this.mijamt03_2 = data.mijamt03_2 || '';
    this.mijamt04_2 = data.mijamt04_2 || '';
    this.mijamt05_2 = data.mijamt05_2 || '';
    this.mijamt06_2 = data.mijamt06_2 || '';
    this.mijamt07_2 = data.mijamt07_2 || '';
    this.mijamt08_2 = data.mijamt08_2 || '';
    this.mijamt09_2 = data.mijamt09_2 || '';
    this.mijamt10_2 = data.mijamt10_2 || '';
    this.mijamt11_2 = data.mijamt11_2 || '';
    this.mijamt12_2 = data.mijamt12_2 || '';
    this.mijamttot_2 = data.mijamttot_2 || '';
    this.totex01 = data.totex01 || '';
    this.totex02 = data.totex02 || '';
    this.totex03 = data.totex03 || '';
    this.totex04 = data.totex04 || '';
    this.totex05 = data.totex05 || '';
    this.totex06 = data.totex06 || '';
    this.totex07 = data.totex07 || '';
    this.totex08 = data.totex08 || '';
    this.totex09 = data.totex09 || '';
    this.totex10 = data.totex10 || '';
    this.totex11 = data.totex11 || '';
    this.totex12 = data.totex12 || '';
    this.totextot = data.totextot || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
