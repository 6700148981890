import * as React from 'react';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabGridEstimateHeader } from '../headers';

/**
 * 화면 > 상세 > 탭 - 견적내역
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabEstimate: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
        <GridLayout
          ref={scope.gridEstimate}
          header={RegistTabGridEstimateHeader(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.tabDataEstimate}
          onRowClick={(_, index) => scope.onGridEstimateFocused(index)}
        />
    </FlexLayout>
);
