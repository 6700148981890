import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  CalendarLayout,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Schedule } from './Schedule';
import { Date6, Date6Calculator } from '../../../../utils/time';
import { CalendarLayoutDataModel, SearchBinding } from '../../../../models';
import ScheduleModel from './models/ScheduleModel';
import { ScheduleTableHeader } from './headers/ScheduleTableHeader';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_b501
 * @category 영업스케쥴
 */
export const ScheduleTemplate: React.FC<TemplateProps<Schedule>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        maxWidth={1136}
      >
        <FlexLayout size={180}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>사용자</span>
          <TextBox
            textAlign="center"
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout weight={2}>
          <span style={Global.STYLE_COLOR_RED}>현장코드</span>
          <TextBox
            textAlign="center"
            value={scope.state.actcd}
            onChange={(value) => setData({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setData({
                  actcd: item.cd,
                  actnm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={2}
            value={scope.state.actnm}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <LayoutTitle>{Date6.withKor(scope.state.stmon)} 사전점검계획</LayoutTitle>
        <FlexLayout
          size={ 20 }
          justify="end"
          align="start"
          style={{ color: '#008080', paddingRight: 36 }}
        >
          총:{scope.total} 건, 계획:{scope.done_cnt} 건, 방문:{scope.plan_cnt} 건
        </FlexLayout>
        <CalendarLayout
          month={scope.state.stmon}
          data={scope.state.schedules}
          onDateClick={(c, items) => scope.onCalendarItemClicked(c, items)}
          render={(c, items: CalendarLayoutDataModel<ScheduleModel>[]) => (
            <DefaultLayout style={{ cursor: 'pointer' }}>
              <div>
                <span style={{ color: 'var(--color-red)' }}>계획 {scope.dayRemarks[c.data]?.plan || 0} </span>
                <span style={{ color: 'var(--color-blue)' }}>방문 {scope.dayRemarks[c.data]?.done || 0} </span>
              </div>
              {items.slice(0, 6).map((x, i) => (
                <div key={i}>
                <span style={{ color: scope.getItemColor(x.data) }}>
                  [{scope.getItemPrefix(x.data)}]
                </span>
                  {x.data.actnm}
                </div>
              ))}
            </DefaultLayout>
          )}
        />
      </FlexLayout>


      {/* 영업스케쥴 추가 팝업 */}
      <ModalView
        width={1000}
        isVisible={scope.state.isVisibleModal}
        onClose={() => scope.closeModal()}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            isVertical={true}
          >
            <SubHeader>
            <div>
              <span>영업계획</span>
            </div>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onModalPrint()}>출력</Button>
              <Button onClick={() => scope.onModalAdd()}>추가</Button>
              <Button onClick={() => scope.onModalRemove()}>삭제</Button>
            </FlexLayout>
          </SubHeader>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.table}
              header={ScheduleTableHeader(scope)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.data}
              onChange={(updates, rows) => scope.onChangeTable(updates, rows)}
              onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
            />
          </FlexLayout>

          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            justify="end"
          >
            <Button
              isFull={true}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.closeModal()}
            >
              닫기
            </Button>

            <Button
              isFull={true}
              onClick={() => scope.onModalSave()}
            >
              저장
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
