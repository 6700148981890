import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 팝업모델
 * @window w_tb_e080_list
 * @category 미결함
 */
export class IncompleteBoxPopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly attcnt: number;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly papercd: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly remark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  readonly perid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.appnum = data.appnum || '';
    this.papercd = data.papercd || '';
    this.remark = Fix.newline(data.remark) || '';
    this.attcnt = data.attcnt || 0;
    this.perid = data.perid || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
