import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { GiSave } from 'react-icons/all';
import { WiSnowflakeCold } from 'react-icons/wi';
import {
  Button,
  CheckBox,
  CircleBadge,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import {
  BtnGubun,
  ItemChange,
  Sales,
  SalesTabDataStateNames,
  SalesTabId,
} from './Sales';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';
import {
  Format,
  JoinClassName,
} from '../../../../utils/string';
import style from './Sales.module.scss';
import { Sum } from '../../../../utils/array';
import { ConfirmWarning } from '../../../../utils/confirm';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

const recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'B', remark: '발송전' },
  { value: 'B ', remark: '발송전' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'FF ', remark: '국세청직접발행' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'R ', remark: '열람' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
];

const taxflags = [
  { value: '01', remark: '일반' },
  { value: '02', remark: '수정' },
];

/**
 * 화면
 * @window w_tb_da023
 * @category 매출등록
 */
export const SalesTemplate: React.FC<TemplateProps<Sales>> = ({
  scope,
  update,
  // @ts-ignore
  rowUpdates,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const texModel = scope.state.texPopup;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout style={{ width: 1200 }}>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder={scope.state.data?.new === '0' ? '거래처, 현장, 적요로 검색하세요' : ''}
                value={scope.state.searchQuery}
                onChange={(value) => scope.setState({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={220}>
              <span>구분</span>
              <ComboBox
                value={scope.state.searchGubun}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
                onSelect={(option) => scope.setState({ searchGubun: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout size={250}>
              <span>계산서발행</span>
              <ComboBox
                value={scope.state.billgubun}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '발행' },
                  { value: '0', remark: '미발행' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ billgubun: option.value },
                  () => scope.onRetrieveEvent())}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button
              onClick={() => scope.texBillModal(true)}
              disabled={!model?.custcd}
            >
              <div style={{ width: 85 }}>세금계산서발행</div>
            </Button>

            <Button
              onClick={() => scope.texModal(true)}
              disabled={!model?.custcd}
            >
              <div style={{ width: 85 }}>수정세금계산서</div>
            </Button>

            <Button
              onClick={() => scope.printTypeModal(true)}
              disabled={!model?.custcd}
            >
              <div style={{ width: 85 }}>청구서발행</div>
            </Button>

            <Button
              onClick={() => scope.constructionModal(true)}
            >
              <FlexLayout>
                {(scope.state.da006_cnt + scope.state.e471_cnt) > 0 && <CircleBadge
                  style={{
                    left: 'initial',
                    right: -11,
                  }}
                >
                  <span
                    style={{
                      backgroundColor: 'var(--color-red)',
                      borderRadius: 24,
                      color: '#FFF',
                      fontWeight: 300,
                      padding: '0 3px',
                      fontSize: 11,
                      transform: 'translate(4px, 0px)',
                    }}
                  >
                    {scope.state.da006_cnt + scope.state.e471_cnt}
                  </span>
                </CircleBadge>}
                <div style={{ width: 85 }}>공사불러오기</div>
              </FlexLayout>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'misdate',
                text: '매출일자',
                width: 15,
                trail: () => (
                  <FlexLayout
                    justify="end"
                    align="center"
                  >
                    매출건수
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misnum',
                text: '번호',
                width: 9,
                trail: () => (
                  <FlexLayout
                    justify="start"
                    align="center"
                  >
                    {Format.number(scope.state.total)}건
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 30,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.totbillkind_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '매출구분',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.fcnt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                  >
                    {scope.state.gubun?.filter((y) => y.code === x.gubun)[0]?.cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'recivstatus',
                text: '상태',
                width: 17,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {scope.state.bcnt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: x.recivstatus === 'F' || x.recivstatus === 'F '
                        ? 'var(--color-blue)'
                        // eslint-disable-next-line no-nested-ternary
                        : x.recivstatus === 'FF' || x.recivstatus === 'FF '
                          ? '#7A297B'
                          : x.recivstatus === 'B' || x.recivstatus === 'B '
                            ? 'var(--color-red)' : '',
                    }}>
                    {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'taxflag',
                text: '발행구분',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{
                      color: x.taxflag === '01'
                        ? 'var(--color-blue)' : 'var(--color-red)',
                    }}
                  >
                    {taxflags.filter((y) => y.value === x.taxflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.salesList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={1.5}
          disabled={!model?.custcd && !model?.isNew}
          disabledText="신규로 추가하거나 매출을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="매출일자"
              isNeed={true}
              footer={
                <Button
                  isMini={true}
                  theme={BUTTON_THEMES.BLUE}
                  onClick={() => {
                    if (model?.rcvflag === '1') {
                      ConfirmWarning.show('경고', '해당 매출건은 이미 입금되었습니다.');
                      return;
                    }
                    setData({ isNew: true },
                      () => scope.setState({ newChk: '1' }));
                  }}
                >
                  변경
                </Button>
              }>

              <DateTextBox
                weight={1.5}
                textAlign="center"
                readonly={scope.state.misdateChk === '0' && scope.state.newChk !== '1' }
                format="####-##-##"
                value={!model?.misdate ? scope.state.enddate : model?.misdate}
                onChange={(item) => setData({ misdate: item },
                  scope.state.newChk === '0'
                    ? () => scope.onItemChange(item, ItemChange.misdate, 'dw_2_itemchanged')
                    : () => scope.onButtonclick(item, BtnGubun.b_misdate))}
              />
            </LabelBox>

            <LabelBox title="매출번호">
              <TextBox
                textAlign="center"
                readonly={true}
                value={model?.misnum}
                onChange={(value) => setData({ misnum: value })}
              />
            </LabelBox>

            <LabelBox
              title="매출구분"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.gubun, BtnGubun.b_gubun)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <ComboBox
                value={model.gubun}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.state.gubun?.map((y) => new ComboBoxModel(y.code, y.cnam))}
                onSelect={(option) => setData({ gubun: option.value },
                  () => scope.onItemChange(option.value, ItemChange.gubun, 'dw_2_itemchanged'))}
              />
            </LabelBox>

            <LabelBox title="매출분류">
              <OptionBox
                value={model.billgubun}
                data={[
                  { value: '1', remark: '매출' },
                  { value: '0', remark: '비매출' },
                ]}
                onChange={(item) => setData({ billgubun: item.value })}
              />
            </LabelBox>

            <LabelBox
              title="회사구분"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model.wkactcd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E018_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      wkactcd: item.cd,
                      wkactnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.wkactnm}
                weight={1.5}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="과세구분">
              <ComboBox
                textAlign="center"
                value={model.taxgubun}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.state.taxgubuns?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
                onSelect={(option) => setData({ taxgubun: option.value },
                  () => scope.onItemChange(option.value, ItemChange.taxgubun, 'dw_2_itemchanged'))}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model.actcd}
                textAlign="center"
                size={151}
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    }, () => scope.onItemChange(item.cd, ItemChange.actcd, 'dw_2_itemchanged'));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                value={model.actnm}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="거래처명"
              style={{ color: 'var(--color-red)' }}
              isNeed={true}
              footer={
                <FlexLayout
                  align="left"
                  style={{ paddingRight: 10 }}
                >
                  (거래처명은 거래처등록에서 인쇄할거래처명을 가져옵니다)
                </FlexLayout>
              }
            >
              <TextBox
                value={model.cltcd}
                textAlign="center"
                size={151}
                onChange={(value) => setData({ cltcd: value })}
                onEnter={(value) => scope.setState({ cltChk: '1' },
                  () => scope.onItemChange(value, ItemChange.cltcd, 'dw_2_itemchanged'))}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  { uv_arg1: '2' },
                  true,
                  (item) => {
                    setData({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    });
                    scope.onItemChange(item.cd, ItemChange.cltcd, 'dw_2_itemchanged');
                  },
                )}
              />
              <TextBox
                value={model.cltnm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="계산서발행"
              footer={
                <FlexLayout
                  align="center"
                  size={70}
                >
                  <FlexLayout style={{ color: model?.taxflag === '01' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                    {model.taxflag === '01' ? '일반' : !model.taxflag ? '' : '수정'}
                  </FlexLayout>
                </FlexLayout>}>
              <OptionBox
                value={scope.state.data.billkind}
                data={[
                  { value: '1', remark: '발행' },
                  { value: '0', remark: '미발행' },
                ]}
                onChange={(item) => setData({ billkind: item.value })}
              />
            </LabelBox>

            <LabelBox title="부가세여부">
              <OptionBox
                value={scope.state.data.taxcls}
                data={[
                  { value: '1', remark: '포함' },
                  { value: '0', remark: '별도' },
                ]}
                onChange={(item) => setData({ taxcls: item.value },
                  () => (scope.state.stateNew !== '1' ? scope.onItemChange(item.value, ItemChange.taxcls, 'dw_2_itemchanged')
                    : scope.taxAutoCalc()))}
              />
            </LabelBox>

            <LabelBox
              title="전자상태"
              footer={
                scope.state.userId === 'ELMAN' && scope.state.custcd === 'dmyong_g'
                  ? <FlexLayout
                    align="right"
                    size={70}
                  >
                    <FlexLayout style={{ width: 98 }}>
                      <Button onClick={() => scope.onButtonclick(model.recivstatus, BtnGubun.b_recivstatus)}>
                        {model.recivstatus === 'FF' || model.recivstatus === 'FF ' ? '국세청발행취소' : '국세청직접발행'}
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                  : ''}
            >
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  backgroundColor: '#D8D8D8',
                  // eslint-disable-next-line no-nested-ternary
                  color: model.recivstatus === 'F '
                    ? 'var(--color-blue)'
                    // eslint-disable-next-line no-nested-ternary
                    : model.recivstatus === 'FF'
                      ? '#7A297B'
                      : model.recivstatus === 'B'
                        ? 'var(--color-red)' : '',
                }}
              >
                {recivstatuss.filter((y) => y.value === model.recivstatus)[0]?.remark}
              </FlexLayout>
            </LabelBox>

            <LabelBox
              title="담당자명"
              style={{ color: 'var(--color-red)' }}
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.perid, BtnGubun.b_perid)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <TextBox
                value={model.perid}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                      divicd: item.uv_arg2,
                      divinm: item.uv_arg3,
                    });
                  },
                )}
              />
              <TextBox
                value={model.pernm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="귀속부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model.divicd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.divinm}
                weight={1.3}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="귀속년월"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.yyyymm, BtnGubun.b_yyyymm)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <FormatTextBox
                textAlign="center"
                format="####-##"
                value={model.yyyymm}
                onChange={(value) => setData({ yyyymm: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="계정코드"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model.acccd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AC001',
                  {},
                  true,
                  (item) => {
                    setData({
                      acccd: item.cd,
                      accnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.accnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="입금처리">
              <OptionBox
                value={scope.state.data.misflag}
                data={[
                  { value: '0', remark: '필요' },
                  { value: '1', remark: '불필요' },
                ]}
                onChange={(item) => setData({ misflag: item.value },
                  () => scope.onItemChange(item.value, ItemChange.misflag, 'dw_2_itemchanged'))}
              />
            </LabelBox>

            <LabelBox title="매출취소처리">
              <OptionBox
                value={scope.state.data.cancleflag}
                data={[
                  { value: '1', remark: '취소' },
                  { value: '0', remark: '정상' },
                ]}
                onChange={(item) => setData({ cancleflag: item.value },
                  () => scope.onItemChange(item.value, ItemChange.cancleflag, 'dw_2_itemchanged'))}
              />
            </LabelBox>

            <LabelBox
              title="입금은행"
              style={{ color: 'var(--color-red)' }}
              weight={2}
            >
              <TextBox
                weight={1}
                value={model.bankcd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AA040',
                  {},
                  true,
                  (item) => {
                    setData({
                      bankcd: item.cd,
                      banknm: item.cdnm,
                      accnum: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.banknm}
                weight={1.5}
                readonly={true}
              />
              <TextBox
                value={model.accnum}
                weight={2}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="발행구분"
            >
              <OptionBox
                value={model.receiptyn}
                data={[
                  { value: '1', remark: '영수' },
                  { value: '2', remark: '청구' },
                ]}
                onChange={(item) => setData({ receiptyn: item.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="프로젝트"
              style={{ color: 'var(--color-red)' }}
              size={380}
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.projno, BtnGubun.b_projno)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <TextBox
                size={90}
                textAlign="center"
                value={model.projno}
                onChange={(value) => setData({ projno: value, isChanged: 'true' })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      projno: item.projno,
                      projnm: item.projnm,
                      actcd: item.actcd,
                      actnm: item.actnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.projnm}
                onChange={(value) => setData({ projnm: value, isChanged: 'true' })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="지로구분"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.jirogubun, BtnGubun.b_jirogubun)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <OptionBox
                value={model.jirogubun}
                data={[
                  { value: '1', remark: '발행' },
                  { value: '0', remark: '미발행' },
                ]}
                onChange={(item) => setData({ jirogubun: item.value })}
              />
            </LabelBox>

            <LabelBox title="지로납기일">
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={!model.jirodate ? '19700101' : model.jirodate}
                onChange={(value) => setData({ jirodate: value },
                  () => scope.onItemChange(value, ItemChange.jirodate, 'dw_2_itemchanged'))}
              />
            </LabelBox>

            <LabelBox
              title="수금예정일"
              footer={
                <FlexLayout
                  justify="center"
                  align="center"
                  size={70}
                  style={{ color: model?.rcvflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                >
                  {model.rcvflag === '0' ? '미수금' : '수금'}
                </FlexLayout>
              }
            >
              <DateTextBox
                format="####-##-##"
                value={!model.ibgdate ? '00000000' : model.ibgdate}
                textAlign="center"
                onChange={(value) => setData({ ibgdate: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="세금계산서비고(적요)">
              <TextBox
                textAlign="left"
                value={model.bigo}
                onChange={(value) => setData({ bigo: value })}
              />
            </LabelBox>

            <LabelBox
              title="비고"
              size={420}
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.bigo2, BtnGubun.b_bigo2)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <TextBox
                textAlign="left"
                value={model.bigo2}
                onChange={(value) => setData({ bigo2: value })}
              />
            </LabelBox>

            <LabelBox
              title="매출금액"
              weight={0.54}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                readonly={true}
                textAlign="center"
                value={model.misamt}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        weight={5}
        disabled={!model?.custcd && !model?.isNew}
        disabledText="신규로 추가하거나 매출을 선택해주세요"
      >
        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />

            {(scope.tabIndex === SalesTabId.Deal)
            && <Button
              isIcon={true}
              style={{ fontSize: 22 }}
              onClick={() => scope.onDetailSave2()}
            >
              <GiSave/>
            </Button>}

            <Button
              style={{
                height: 25,
                width: 80,
                marginTop: 12,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.subNewEvent()}>
              추가
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
                marginTop: 12,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.subDeleteEvent()}>
              삭제
            </Button>

            {(scope.tabIndex === SalesTabId.Deal)
            && <Button
              style={{
                height: 25,
                width: 80,
                marginTop: 12,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.printTypeDealModal(true)}>
              거래명세표
            </Button>}
          </FlexLayout>

          <FlexLayout weight={1.5}>
            <TableLayout
              ref={scope.table}
              scope={scope}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity2}
              onChange={(rows, updatedRows) => {
                scope.tapUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              // @ts-ignore
              data={scope.state[SalesTabDataStateNames[scope.tabIndex]] || [] }
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.printTypeDealModal}
        onClose={() => scope.printTypeDealModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType2 === '1'}
                onChange={(v) => scope.setState({ popupPrintType2: v ? '1' : '0' })}
              />
              출력
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType2 === '2'}
                onChange={(v) => scope.setState({ popupPrintType2: v ? '2' : '0' })}
              />
              이메일
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType2 === '3'}
                onChange={(v) => scope.setState({ popupPrintType2: v ? '3' : '0' })}
              />
              팩스
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.dealPrintModal(true)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.dealsModal}
        onClose={() => scope.dealPrintModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>출력구분</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.popupData === '1'}
                onChange={(v) => scope.setState({ popupData: v ? '1' : '0' })}
              />
              날짜표기
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupStamp === '1'}
                onChange={(v) => scope.setState({ popupStamp: v ? '1' : '0' })}
              />
              직인표기
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.dealPrintRetrieve()}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.printTypeModal}
        onClose={() => scope.printTypeModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType === '1'}
                onChange={(v) => scope.setState({ popupPrintType: v ? '1' : '0' })}
              />
              출력
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType === '2'}
                onChange={(v) => scope.setState({ popupPrintType: v ? '2' : '0' })}
              />
              이메일
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType === '3'}
                onChange={(v) => scope.setState({ popupPrintType: v ? '3' : '0' })}
              />
              팩스
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={scope.state.texModalCheck ? () => scope.modalTexRetrive() : () => scope.billModal(true)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.billModal}
        onClose={() => scope.billModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>출력구분</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.popupData === '1'}
                onChange={(v) => scope.setState({ popupData: v ? '1' : '0' })}
              />
              날짜표기
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupStamp === '1'}
                onChange={(v) => scope.setState({ popupStamp: v ? '1' : '0' })}
              />
              직인표기
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.billModalDetail()}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.printTypeModal2}
        onClose={() => scope.printTypeModal2(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType2 === '1'}
                onChange={(v) => scope.setState({ popupPrintType2: v ? '1' : '0' })}
              />
              출력
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType2 === '2'}
                onChange={(v) => scope.setState({ popupPrintType2: v ? '2' : '0' })}
              />
              이메일
            </FlexLayout>

            <FlexLayout>
              <CheckBox
                value={scope.state.popupPrintType2 === '3'}
                onChange={(v) => scope.setState({ popupPrintType2: v ? '3' : '0' })}
              />
              팩스
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalTexBillRetrive()}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.constructionModal}
        onClose={() => scope.constructionModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>출력구분</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <OptionBox
              size={270}
              value={scope.state.construction}
              data={[
                { value: '1', remark: '수리공사/부품교체' },
                { value: '0', remark: '설치공사' },
              ]}
              onChange={(item) => scope.setState({ construction: `${item.value}` })}
            />
            {/* 수리공사/부품교체 */}
            {scope.state.e471_cnt > 0 && <CircleBadge style={{ left: 'initial' }}>
              <span
                style={{ backgroundColor: 'var(--color-red)' }}
              >
                {scope.state.e471_cnt}
              </span>
            </CircleBadge>}
            {/* 설치공사 */}
            {scope.state.da006_cnt > 0 && <CircleBadge
              style={{
                left: 'initial',
                transform: 'translate(108px, -6px)',
              }}
            >
              <span style={{ backgroundColor: 'var(--color-red)' }}>
                {scope.state.da006_cnt}
              </span>
            </CircleBadge>}
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.constructionChoice(scope.state.construction)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.constructionModal2}
        onClose={() => scope.constructionModal2(false)}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>공사등록 불러오기</LayoutTitle>

          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            noMarginTop={true}
          >
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.popupStdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ popupStdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.popupStdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ popupStdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={400}>
              <span>검색어</span>
              <TextBox
                value={scope.state.constructionSearch}
                onChange={(value) => scope.setState({ constructionSearch: value })}
                onEnter={() => scope.modalConstructionRetrive('w_popup_e471')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.modalConstructionRetrive('w_popup_e471')}>
              <div style={{ width: 80 }}>조회</div>
            </Button>

            <Button onClick={() => scope.modalConstructionDelete()}>
              <div style={{ width: 80 }}>공사내역삭제</div>
            </Button>
          </SearchLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.table2}
              header={[
                {
                  id: 'chk',
                  text: (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={scope.state.pushTotalCheck}
                        onChange={async (value) => scope
                          .updateCheckAllToggle(value)}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={x.chk === '1'}
                        onChange={(value) => {
                          rowUpdate({ chk: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compdate',
                  text: '공사등록일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.compdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'compnum',
                  text: '번호',
                  width: 80,
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'offinm',
                  text: '공사명',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.offinm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '공사담당자',
                  width: 100,
                },
                {
                  id: 'contpernm',
                  text: '계약담당자',
                  width: 100,
                },
                {
                  id: 'misperid',
                  text: '매출실적담당자',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.misperid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ misperid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            misperid: item.cd,
                            mispernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'mispernm',
                  text: '매출실적담당자명',
                  width: 100,
                  render: (x) => (
                    <TextBox
                      textAlign="center"
                      value={x.mispernm}
                      weight={1.5}
                    />
                  ),
                },
                {
                  id: 'taxcls',
                  text: '계산서발행',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center
                      "
                      align="center"
                    >
                      {x.taxcls === '1' ? '포함' : '별도'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'billkind',
                  text: '부가세',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.billkind === '1' ? '발행' : '미발행'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'camt',
                  text: '합계금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.camt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'saleamt',
                  text: '할인금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.saleamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.cltnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '공사시작일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.stdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'enddate',
                  text: '공사완료일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.enddate)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.constructionPopup}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows2) => {
                scope.modalConstructionUpdated(rows, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.modalConstructionRowFocus(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="right"
              align="center"
              size={200}
              style={{ marginLeft: 1000 }}
            >
              <span>일자</span>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={scope.state.constructionDate}
                onChange={(item) => scope.setState({ constructionDate: item })}
              />
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalConstructionSave()}>확인</Button>
              <Button onClick={() => scope.constructionModal2(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.constructionModal3}
        onClose={() => scope.constructionModal3(false)}
        width={1400}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>설치공사 수주 불러오기</LayoutTitle>

          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            noMarginTop={true}
          >
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.popupStdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ popupStdate: value })}
                onEnter={() => scope.modalConstructionRetrive('w_popup_load_da006')}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.popupStdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ popupStdate: Date8.make(date) },
                      () => scope.modalConstructionRetrive('w_popup_load_da006'));
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.modalConstructionRetrive('w_popup_load_da006'));
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.modalConstructionRetrive('w_popup_load_da006')}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.modalConstructionRetrive('w_popup_load_da006'));
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.modalConstructionRetrive('w_popup_load_da006'));
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={400}>
              <span>검색어</span>
              <TextBox
                value={scope.state.constructionSearch2}
                onChange={(value) => scope.setState({ constructionSearch2: value })}
                onEnter={() => scope.modalConstructionRetrive('w_popup_load_da006')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.modalConstructionRetrive('w_popup_load_da006')}>
              <div style={{ width: 80 }}>조회</div>
            </Button>

            <Button onClick={() => scope.modalConstructionDelete2()}>
              <div style={{ width: 80 }}>공사내역삭제</div>
            </Button>
          </SearchLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.table3}
              header={[
                {
                  id: 'chk',
                  text: (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={scope.state.pushTotalCheck2}
                        onChange={async (value) => scope
                          .updateCheckAllToggle2(value)}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={x.chk === '1'}
                        onChange={(value) => {
                          rowUpdate({ chk: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'orddate',
                  text: '수주일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.orddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ordnum',
                  text: '번호',
                  width: 70,
                },
                {
                  id: 'gubunnm',
                  text: '구분',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.gubunnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'scdate',
                  text: '일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.scdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'projnm',
                  text: '프로젝트명',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.projnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '계약자명',
                  width: 100,
                },
                {
                  id: 'misperid',
                  text: '매출실적담당자',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.misperid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ misperid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            misperid: item.cd,
                            mispernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'mispernm',
                  text: '매출실적담당자명',
                  width: 100,
                  render: (x) => (
                    <TextBox
                      value={x.mispernm}
                      textAlign="center"
                      weight={1.5}
                    />
                  ),
                },
                {
                  id: 'setcls',
                  text: '계산서발행',
                  width: 70,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.setcls === '0' ? '미발행' : '발행'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'taxcls',
                  text: '부가세',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.taxcls === '0' ? '별도' : '포함'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mamt',
                  text: '합계금액',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.mamt)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.constructionPopup2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity4}
              onChange={(rows, updatedRows3) => {
                scope.modalConstructionUpdated2(rows, updatedRows3);
              }}
              onRowFocusEvent={(item) => scope.modalConstructionRowFocus(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="right"
              align="center"
              size={200}
              style={{ marginLeft: 1000 }}
            >
              <span>일자</span>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={scope.state.constructionDate2}
                onChange={(item) => scope.setState({ constructionDate2: item })}
              />
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalConstructionSave2()}>확인</Button>
              <Button onClick={() => scope.constructionModal3(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.texModal}
        onClose={() => scope.texModal(false)}
        width={1200}
        height={1800}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>수정세금계산서</LayoutTitle>

          <FlexLayout isVertical={true}>
            <FlexLayout style={{ color: '#8106aa' }}>
              * 사유가 [1]일 경우(예: 환입) 는 기존세금계산서로 -매출이 1건 생성됩니다.
              <label style={{ color: 'var(--color-blue)' }}>(입력한 매출일자로 생성됨)</label>
            </FlexLayout>

            <FlexLayout style={{ color: '#8106AA' }}>
              * 사유가 [2]일 경우(예: 기재사항착오) 는 기존세금계산서로 -매출, 수정세금계산서 +매출 로 2건 생성됩니다.
            </FlexLayout>

            <FlexLayout style={{ color: 'var(--color-blue)' }}>
              (-건은 기존세금계산서 매출일자로 생성, 새로이 입력한 내역만 입력한 매출일자로 생성)
            </FlexLayout>

            <LayoutTitle size={10} style={{ width: 150 }}>기존세금계산서</LayoutTitle>

            <FlexLayout>
              <table className={style.table}>
                <tbody>
                <tr>
                  <td className={style.largeWidth}>{texModel?.occuclafi}</td>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>영세율</th>
                  <td className={style.square2Width}>
                    <CheckBox
                      value={texModel?.eclafi === '1'}
                      onChange={(value) => setData({ eclafi: value ? '1' : '0' })}
                      isDisabledTrackingStateChange={true}
                    />
                  </td>
                  <th className={style.largeWidth}></th>
                  <th className={style.middleWidth}></th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>책번호</th>
                  <td className={style.middleWidth}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {texModel?.booknum}
                      <label style={{ color: 'var(--color-red)' }}>
                        권
                      </label>
                    </FlexLayout>
                  </td>
                  <td className={style.middleWidth}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {texModel?.bookho}
                      <label style={{ color: 'var(--color-red)' }}>
                        호
                      </label>
                    </FlexLayout>
                  </td>
                </tr>

                <tr>
                  <td className={style.largeWidth}>
                    <OptionBox
                      value={texModel?.saleflag}
                      data={[
                        { value: 'OD', remark: '전자발급' },
                        { value: 'AS', remark: '수기발급' },
                      ]}
                      onChange={(item) => setData({ saleflag: item.value })}
                    />
                  </td>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>귀속부서</th>
                  <td className={style.square2Width}>{texModel?.divicd}</td>
                  <th colSpan={2} align='left'>{texModel?.divinm}</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>일련번호</th>
                  <td colSpan={2}>
                    <FormatTextBox
                      format="##-####"
                      textAlign="center"
                      value={texModel?.serinum}
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </FlexLayout>

            <FlexLayout>
              <table className={style.table}>
                <tbody>
                <tr>
                  <th className={JoinClassName.make([style.slimWidth, style.thFontColor])} rowSpan={4}>공급자</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>등록번호</th>
                  <th colSpan={3}>
                    <FormatTextBox
                      textAlign="center"
                      format="###-##-#####"
                      value={texModel?.tb_xa012_saupnum}
                    />
                  </th>
                  <th className={JoinClassName.make([style.slimWidth, style.thFontColor])}>공급받는자</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>등록번호</th>
                  <th colSpan={9}>
                    <FormatTextBox
                      textAlign="center"
                      format="###-##-#####"
                      value={texModel?.tb_ia011_saupnum}
                    />
                  </th>
                </tr>

                <tr>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>상호(법인명)</th>
                  <th align='left'>{texModel?.tb_xa012_spjangnm}</th>
                  <th className={JoinClassName.make([style.slimWidth, style.thFontColor])}>성명</th>
                  <th className={style.squareWidth}>{texModel?.tb_xa012_prenm}</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>상호(법인명)</th>
                  <th align='left' className={style.squareWidth}>{texModel?.n_cltcd}</th>
                  <th align='left' className={style.largeWidth} colSpan={6}>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 200,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.tb_ia011_cltnm}
                    </span>
                  </th>
                  <th className={JoinClassName.make([style.slimWidth, style.thFontColor])}>성명</th>
                  <th className={style.square2Width}>{texModel?.tb_ia011_prenm}</th>
                </tr>

                <tr>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>사업장주소</th>
                  <th align='left' colSpan={3}>{texModel?.tb_xa012_address}</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>사업장주소</th>
                  <th align='left' colSpan={9}>{texModel?.tb_ia011_cltadres}</th>
                </tr>

                <tr>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>업태</th>
                  <th>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 100,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.tb_xa012_biztype}
                    </span>
                  </th>
                  <th className={JoinClassName.make([style.slimWidth, style.thFontColor])}>종목</th>
                  <th className={style.largeWidth}>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 250,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.tb_xa012_item}
                    </span>
                  </th>
                  <th className={style.thFontColor}>업태</th>
                  <th align='left'>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 100,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.tb_ia011_biztypenm}
                    </span>
                  </th>
                  <th className={JoinClassName.make([style.slimWidth, style.thFontColor])} colSpan={2}>종목</th>
                  <th align='left' colSpan={6} className={style.largeWidth}>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 270,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.tb_ia011_bizitemnm}
                    </span>
                  </th>
                </tr>
                </tbody>
              </table>
            </FlexLayout>

            <FlexLayout>
              <table className={style.table}>
                <tbody>
                <tr>
                  <th colSpan={3} className={style.thFontColor}>작성일자</th>
                  <th colSpan={12} className={style.thFontColor}>공급가액</th>
                  <th colSpan={10} className={style.thFontColor}>세액</th>
                  <th rowSpan={2} className={JoinClassName.make([style.largeWidth, style.thFontColor])}>비고</th>
                </tr>

                <tr>
                  <th className={style.square2Width}>년</th>
                  <th className={style.squareWidth}>월</th>
                  <th className={style.squareWidth}>일</th>
                  <th className={style.square2Width}>공란수</th>
                  <th className={style.slimWidth}>백</th>
                  <th className={style.slimWidth}>십</th>
                  <th className={style.slimWidth}>억</th>
                  <th className={style.slimWidth}>천</th>
                  <th className={style.slimWidth}>백</th>
                  <th className={style.slimWidth}>십</th>
                  <th className={style.slimWidth}>만</th>
                  <th className={style.slimWidth}>천</th>
                  <th className={style.slimWidth}>백</th>
                  <th className={style.slimWidth}>십</th>
                  <th className={style.slimWidth}>일</th>
                  <th className={style.slimWidth}>십</th>
                  <th className={style.slimWidth}>억</th>
                  <th className={style.slimWidth}>천</th>
                  <th className={style.slimWidth}>백</th>
                  <th className={style.slimWidth}>십</th>
                  <th className={style.slimWidth}>만</th>
                  <th className={style.slimWidth}>천</th>
                  <th className={style.slimWidth}>백</th>
                  <th className={style.slimWidth}>십</th>
                  <th className={style.slimWidth}>일</th>
                </tr>

                <tr>
                  <td>{texModel?.pubdatey}</td>
                  <td>{texModel?.pubdatem}</td>
                  <td>{texModel?.pubdated}</td>
                  <th>{texModel?.samt0}</th>
                  <th>{texModel?.samt1}</th>
                  <th>{texModel?.samt2}</th>
                  <th>{texModel?.samt3}</th>
                  <th>{texModel?.samt4}</th>
                  <th>{texModel?.samt5}</th>
                  <th>{texModel?.samt6}</th>
                  <th>{texModel?.samt7}</th>
                  <th>{texModel?.samt8}</th>
                  <th>{texModel?.samt9}</th>
                  <th>{texModel?.samt10}</th>
                  <th>{texModel?.samt11}</th>
                  <th>{texModel?.tamt1}</th>
                  <th>{texModel?.tamt1}</th>
                  <th>{texModel?.tamt2}</th>
                  <th>{texModel?.tamt3}</th>
                  <th>{texModel?.tamt4}</th>
                  <th>{texModel?.tamt5}</th>
                  <th>{texModel?.tamt6}</th>
                  <th>{texModel?.tamt7}</th>
                  <th>{texModel?.tamt8}</th>
                  <th>{texModel?.tamt9}</th>
                  <td align='left' className={style.tdColor} style={{ backgroundColor: 'white' }}>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 200,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.remarks}
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </FlexLayout>

            <FlexLayout>
              <table className={style.table}>
                <thead>
                <tr>
                  <th className={style.slimWidth}>월</th>
                  <th className={style.slimWidth}>일</th>
                  <th className={style.largesWidth}>품목</th>
                  <th className={style.square2Width}>규격</th>
                  <th className={style.square2Width}>수량</th>
                  <th className={style.middleWidth}>단가</th>
                  <th className={style.mlargeWidth}>공급가액</th>
                  <th className={style.mlargeWidth}>세액</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <th>{texModel?.pubdatem}</th>
                  <th>{texModel?.pubdated}</th>
                  <td align='left'>{texModel?.artclnm1}</td>
                  <td>{texModel?.standard1}</td>
                  <td>{texModel?.qty1}</td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.uprice1)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.suprice1)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.taxamt1)}
                    </FlexLayout>
                  </td>
                </tr>

                <tr>
                  <th>{texModel?.pubdatem}</th>
                  <th>{texModel?.pubdated}</th>
                  <td>{texModel?.artclnm2}</td>
                  <td>{texModel?.standard2}</td>
                  <td>{texModel?.qty2}</td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.uprice2)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.suprice2)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.taxamt2)}
                    </FlexLayout>
                  </td>
                </tr>

                <tr>
                  <th>{texModel?.pubdatem}</th>
                  <th>{texModel?.pubdated}</th>
                  <td>{texModel?.artclnm3}</td>
                  <td>{texModel?.standard3}</td>
                  <td>{texModel?.qty3}</td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.uprice3)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.suprice3)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.taxamt3)}
                    </FlexLayout>
                  </td>
                </tr>

                <tr>
                  <th>{texModel?.pubdatem}</th>
                  <th>{texModel?.pubdated}</th>
                  <td>{texModel?.artclnm4}</td>
                  <td>{texModel?.standard4}</td>
                  <td>{texModel?.qty4}</td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.uprice4)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.suprice4)}
                    </FlexLayout>
                  </td>
                  <td>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(texModel?.taxamt4)}
                    </FlexLayout>
                  </td>
                </tr>
                </tbody>
              </table>
            </FlexLayout>

            <FlexLayout>
              <table className={style.table}>
                <tbody>
                <tr>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>합계</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>현금</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>수표</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>어음</th>
                  <th className={JoinClassName.make([style.middleWidth, style.thFontColor])}>외상미수금</th>
                  <td className={JoinClassName.make([style.largeWidth, style.thFontColor])} rowSpan={2} align='center'>
                    <FlexLayout
                      style={{
                        width: 200,
                        color: 'var(--color-red)',
                      }}
                    >
                      이금액을
                      <OptionBox
                        value={texModel?.receiptyn}
                        data={[
                          { value: '1', remark: '영수' },
                          { value: '2', remark: '청구' },
                        ]}
                        onChange={(item) => setData({ receiptyn: item.value })}
                      />
                      함
                    </FlexLayout>
                  </td>
                </tr>

                <tr>
                  <th>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(texModel?.totamt)}
                    </FlexLayout>
                  </th>
                  <td>{texModel?.cash}</td>
                  <td>{texModel?.chk}</td>
                  <td>{texModel?.draft}</td>
                  <th>
                    {/* 임시로 주석처리 */}
                    { /* {texModel?.trureciamt} */}
                  </th>
                </tr>
                </tbody>
              </table>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <LayoutTitle size={10} style={{ width: 150 }}>수정세금계산서</LayoutTitle>

            <FlexLayout>
              <table className={style.blueTable}>
                <tbody>
                <tr>
                  <th className={style.largeWidth}>
                    <span className={style.need}>
                       <WiSnowflakeCold />
                    </span>
                    매출일자
                  </th>
                  <td className={style.mlargeWidth}>
                    <DateTextBox
                      weight={1.5}
                      textAlign="center"
                      format="####-##-##"
                      value={scope.state.n_datetime}
                      onChange={(item) => scope.setState({ n_datetime: item })}
                    />
                  </td>
                  <td className={style.tdColor}>{texModel?.n_misnum}</td>
                  <th className={style.middleWidth}>부가세여부</th>
                  <td className={style.mlargeWidth}>
                    <OptionBox
                      value={texModel?.n_taxcls}
                      data={[
                        { value: '1', remark: '포함' },
                        { value: '0', remark: '별도' },
                      ]}
                      onChange={(item) => setData({ n_taxcls: item.value })}
                    />
                  </td>
                  <th className={style.middleWidth}>계산서발행</th>
                  <td className={style.largeWidth}>
                    <OptionBox
                      value={texModel?.n_billkind}
                      data={[
                        { value: '1', remark: '발행' },
                        { value: '0', remark: '미발행' },
                      ]}
                      onChange={(item) => setData({ n_billkind: item.value })}
                    />
                  </td>
                </tr>

                <tr>
                  <th style={{ color: 'var(--color-red)' }}>현장명</th>
                  <td>
                    <TextBox
                      value={texModel?.n_actcd}
                      textAlign="center"
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_E601_1',
                        {},
                        true,
                        (item) => {
                          setData({
                            n_actcd: item.cd,
                            n_actnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  </td>
                  <td align='left' colSpan={3} className={style.tdColor}>{texModel?.n_actnm}</td>
                  <th>
                    <span className={style.need}>
                       <WiSnowflakeCold />
                    </span>
                    사유
                  </th>
                  <td align='left'>
                    <ComboBox
                      value={texModel?.n_sayoo}
                      textAlign="center"
                      data={[
                        { value: '01', remark: '기재사항착오.정정      [2]' },
                        { value: '02', remark: '공급가액변동             [2]' },
                        { value: '03', remark: '환입                         [1]' },
                        { value: '04', remark: '계약의해제                [1]' },
                        { value: '05', remark: '내국신용장사후개설    [1]' },
                        { value: '06', remark: '착오에의한이중발급    [1]' },
                      ].map((x) => new ComboBoxModel(x.value, x.remark))}
                      onSelect={(option) => setData({ n_sayoo: option.value })}
                    />
                  </td>
                </tr>

                <tr>
                  <th style={{ color: 'var(--color-red)' }}>거래처명</th>
                  <td>
                    <TextBox
                      value={texModel?.n_cltcd}
                      textAlign="center"
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_XCLIENT',
                        { uv_arg1: '2' },
                        true,
                        (item) => {
                          setData({
                            n_cltcd: item.cd,
                            n_cltnm: item.cdnm,
                          });
                        },
                      )}
                    />
                  </td>
                  <td align='left' colSpan={3} className={style.tdColor}>{texModel?.n_cltnm}</td>
                  <th>대표자명</th>
                  <td className={style.tdColor}>{texModel?.n_prenm}</td>
                </tr>

                <tr>
                  <th>사업자번호</th>
                  <td colSpan={2} className={style.tdColor}>
                    <FormatTextBox
                      textAlign="left"
                      format="####-##-#####"
                      value={texModel?.n_saupnum}
                    />
                  </td>
                  <th className={style.middleWidth}>업태</th>
                  <td align='left' className={style.tdColor}>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 150,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.n_biztypenm}
                    </span>
                  </td>
                  <th className={style.middleWidth}>종목</th>
                  <td align='left' className={style.tdColor}>
                    <span style={{
                      overflow: 'hidden',
                      display: 'block',
                      maxWidth: 250,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {texModel?.n_bizitemnm}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>비고</th>
                  <td align='left' colSpan={4}>{texModel?.n_bigo}</td>
                  <th className={style.middleWidth}>발행구분</th>
                  <td>
                    <OptionBox
                      value={texModel?.n_receiptyn}
                      data={[
                        { value: '1', remark: '영수' },
                        { value: '2', remark: '청구' },
                      ]}
                      onChange={(item) => setData({ n_receiptyn: item.value })}
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout
            justify="right"
            weight={0.01}
          >
            <label style={{ color: 'var(--color-red)', marginTop: 5 }}>*사유가 [2]일 경우에만 추가</label>
            <Button
              style={{ height: 20, width: 60 }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalNewEvent()}>
              추가
            </Button>
            <Button
              style={{ height: 20, width: 60 }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalDelete()}>
              삭제
            </Button>
          </FlexLayout>

          <FlexLayout weight={10}>
            <TableLayout
              ref={scope.table4}
              header={[
                {
                  id: 'seq',
                  text: '순서',
                  width: 6,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합계
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품명',
                  width: 32,
                  render: (x, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.pname}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ pname: value })}
                    />
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 9,
                  render: (x, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.psize}
                      textAlign="center"
                      onChange={(v) => rowUpdate({ psize: v })}
                    />
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 6,
                  render: (x, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.punit}
                      onChange={(v) => rowUpdate({ punit: v })}
                    />
                  ),
                },
                {
                  id: 'qty',
                  text: '수량',
                  width: 6,
                  trail: (data) => {
                    const sumQty = Sum.all(data, 'qty');
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Format.number(sumQty)}
                      </FlexLayout>
                    );
                  },
                  render: (x, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.qty}
                      textAlign="center"
                      onChange={(value) => scope?.modalAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
                    />
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 10,
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={String(x.uamt)}
                      onChange={(value) => scope?.modalAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '금액',
                  width: 10,
                  trail: (data) => {
                    const sumSamt = Sum.all(data, 'samt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumSamt)}
                      </FlexLayout>
                    );
                  },
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={String(x.samt)}
                      onChange={(value) => scope?.modalAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                    />
                  ),
                },
                {
                  id: 'addamt',
                  text: '부가세',
                  width: 10,
                  trail: (data) => {
                    const sumAddamt = Sum.all(data, 'addamt');
                    return (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumAddamt)}
                      </FlexLayout>
                    );
                  },
                  render: (x, rowUpdate, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={String(x.addamt)}
                      onChange={(value) => scope?.modalAutoCalc({ ...x, addamt: value }, rowUpdate, 'addamt')}
                    />
                  ),
                },
                {
                  id: 'misamt',
                  text: '합계금액',
                  width: 11,
                  trail: (data) => {
                    const sumMisamt = Sum.all(data, 'misamt');
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Format.number(sumMisamt)}
                      </FlexLayout>
                    );
                  },
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.taxPopupDetailData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onTexPopupUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onModalRowFocusEvent(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalSaveEvent()}>확인</Button>
              <Button onClick={() => scope.texModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
