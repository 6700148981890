import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e101_aver
 * @category 표준유지관리비
 */
export class StandardModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 년도
   *
   * **data type** varchar(4)
   */
  year: string;

  /**
   * 건물용도
   *
   * **data type** varchar(10)
   */
  contbild: string;

  /**
   * 구분코드
   *
   * **data type** varchar(3)
   */
  evercd: string;

  /**
   * 구분명
   *
   * **data type** varchar(100)
   */
  evernm: string;

  /**
   * 기준층
   *
   * **data type** float
   */
  number: string;

  /**
   * 일반유지관리비
   *
   * **data type** float
   */
  everamt: string;

  /**
   * 휴일야간 유지관리비
   *
   * **data type** float
   */
  everamt_tot: string;

  /**
   * 일반체증요금
   *
   * **data type** float
   */
  addamt: string;

  /**
   * 휴일야간체증요금
   *
   * **data type** float
   */
  addamt_tot: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 기준층구분(층,등등)
   *
   * **data type** varchar(8)
   */
  numflag: string;

  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.year = data.year || '';
    this.contbild = data.contbild || '';
    this.evercd = data.evercd || '';
    this.evernm = data.evernm || '';
    this.number = data.number || '';
    this.everamt = data.everamt || '';
    this.everamt_tot = data.everamt_tot || '';
    this.addamt = data.addamt || '';
    this.addamt_tot = data.addamt_tot || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.numflag = data.numflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
