import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Router, Route } from 'react-router';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { action } from 'mobx';
import style from './AuthorizedScreen.module.scss';
import {
  FavoriteMenuStore,
  HeaderTabStore,
  MenuStore,
  ModalStore,
  PublicStore,
  RouterStore,
  ToolStore,
  WaitQueueStore,
} from '../stores';
import {
  HeaderTools,
  HeaderTab,
  HeaderMenu,
  SideMenu,
  withScreen,
} from '../components';
import {
  Dash,
  GPCust,
  GPBank,
  GPAccount,
  GPCard,
  GPCredit,
  GPJiro,
  GPPublic,
  GPMobile,
  GPLbs,
  GPSettings,
  MMEnrollment,
  MMElevatorInfo,
  MMRetrieveElevator,
  MMFloorPlan,
  MMEndContract,
  MMOldSpace,
  MMRepairCurrent,
  MMRepairSite,
  MMRevocation,
  MMFree,
  MBBreakResister,
  MBBreakComplete,
  MBBreakOften,
  MBBreakReport,
  MBBreakSituation,
  MBBreakRefusal,
  MBBreakCustomer,
  MBHumanCage,
  MBNonDriving,
  MBResponse,
  MBBreakAnalysisAll,
  MBBreakAnalysisOne,
  MBBreakPercent,
  MBBreakNumber,
  MBBreakUnit,
  MBCageReport,
  MIEnrollment,
  MIApplication,
  MIResult,
  MIUnitinspect,
  MEEnrollment,
  MEStatus,
  MEProposal,
  GHTopDepartment,
  GHDepartmentCode,
  GHPositionCode,
  GHAppointment,
  GHHolidayCode,
  GPSpjang,
  GSSortationCode,
  GCExpense,
  GCCostClause,
  GMElevatorErrorCode,
  GMComplaint,
  GMProductGroup,
  GMStorageCode,
  GMElevatorManagementCode,
  GMFailure,
  GMCheckDepartment,
  GMProductCode,
  GMRegion,
  GMBuildingUse,
  GMEstimatedCost,
  GMDailyInspection,
  GMInspectionType,
  GMSelfInspection,
  GESafetyManage,
  GETopWork,
  GEWorkCode,
  GMRemodel,
  GMManagement,
  GMStandard,
  GCCustomerGroup,
  GCCallDivision,
  GCCustomerRegister,
  GCCustomerPresent,
  GCTellReceive,
  GCTextOften,
  GCTextTransmit,
  GCKakaoTalk,
  GIWork,
  GMProductAccount,
  GIItem,
  GIEarlyInspection,
  GSSystem,
  GSUser,
  HSAppoint,
  HSIssue,
  HSPairing,
  HSPersonnelResistration,
  HSSelfCertificate,
  HSStaffList,
  HSMonthly,
  HSStaffScore,
  HAResister,
  HAStatus,
  HCDay,
  HCCar,
  HCMonth,
  HCYear,
  HNNotice,
  AMOneDayDeadline,
  AMBankStatement,
  AMMonthMoney,
  AMSalesPurchase,
  AMChargeReceivable,
  AMReceivable,
  AMLongReceivable,
  AMUnpaid,
  AMProject,
  AMSales,
  AMSalesNationalTaxService,
  AMSalesState,
  AMMonthSales,
  AMMaintenance,
  AMJiroBundle,
  AMBillBundle,
  AMElectronicTax,
  AMTaxInvoice,
  AMLastMonth,
  AMDepositInput,
  AMJiroAuto,
  AMCMSAuto,
  AMInputState,
  AMMonthInput,
  AMElmanData,
  AMLongReceivable2,
  ATTaxInvoice,
  ACManageDeposit,
  AWBuyNationalTaxService,
  AWDollarAverage,
  AWPurchaseTax,
  AWPurchaseState,
  AWGiveState,
  AWGiveRegistration,
  AWMonthOil,
  AWTransportationFee,
  AWYearArticle,
  AWDisbursement,
  AWRegularBuy,
  AWBuyRegister,
  APPaymentCalculate,
  APPaymentRegister,
  AMPayCurrentState,
  AMPayCalculate,
  AMBenefitItem,
  AMLastMonthPay,
  AMYearPay,
  AMServerancePayRegister,
  AMPayslip,
  AMPrepaymentBundle,
  AMYearSeverancePay,
  AMSeverancePay,
  APProjectCode,
  APProject,
  APMonthProjects,
  ACCashBook,
  AMMonthProject,
  EAEnroll,
  EPPatrol,
  EPExpenditure,
  EPBill,
  EPHoliday,
  EPHolidayStatement,
  EPTrip,
  EPOvertime,
  EPWorkReport,
  EPTeamEnroll,
  EPPlanEnroll,
  EPSafetyEducation,
  EPHolidayWork,
  EPTermination,
  EPJournalStatement,
  EPEducation,
  EPSchedule,
  EPJournal,
  EPGeneralJournal,
  EPIntegrate,
  EPMeeting,
  EPContract,
  EPDiploma,
  EPSiteVisit,
  EPTodayPlan,
  EDReferenceBox,
  EDSubmitBox,
  EDCompleteBox,
  EDIncompleteBox,
  EDFileStore,
  EMMailBox,
  EFSendFax,
  PMBill,
  PMBillStatement,
  PMOrder,
  PMOrderStatement,
  PMStock,
  PMStockStatement,
  PMSupply,
  PMSupplyStatement,
  PIWalkDown,
  PIBalance,
  PIInvoice,
  IMEnrollment,
  ISDrawing,
  IMStatus,
  ISOrder,
  ISManageStatus,
  ISCollect,
  ISRegist,
  ISProcess,
  IPStatus,
  IPEffort,
  IPReport,
  IOWorker,
  IOContract,
  IISchedule,
  IIJournal,
  IEStatus,
  IEEnrollment,
  ISSchedule,
  ISReport,
  DAContract,
  DAContractStatistics,
  DAMaintenance,
  DAMonthResult,
  DADuty,
  DAMonth,
  DAYear,
  DMCompany,
  DMIndividual,
  DMIndividualGoal,
  DMMonthWork,
  DMGoalPlan,
  DMGoalResult,
  DMGoal,
  DMPlan,
  DMMonthSale,
  DMManagement,
  DMMonthAverage,
  MMMonthContract,
  MMStatus,
  MCEnrollment,
  MCResult,
  MCResister,
  MCReport,
  MCStatus,
  MCPlan,
  MCMonthConstruction,
  MMCUnit,
  MMCPlan,
  MMCSelfCheck,
  MMCResult,
  MMCDefect,
  MMCNation,
  MMCElectronCheck,
  MMCDailyChart,
  MMInspectionNotice,
  MMCDailyCheck,
  DMInstall,
  DMConstructionSales,
  DMPaySales,
  AMYearInsuranceRatesRegister,
  ISAchievementStatus,
  MAAR,
  MAVideo,
  PIHolidayGift,
  DMPurchasingStatistics,
  MMCInspectorStatus,
  MEPartsCheckList,
  MEFieldPartsReplace,
  PMReturnRegistration,
  PIInventoryMovement,
  MMPreviousMonth,
  AMMonthlyIncomeStatement,
  AMDepositConfirmation,
  MBMonthBreakOften,
  AMIndustry,
  AWClientMonth,
  ISOrderCollection,
  ISOrderMonthly,
  PMMonthlyMaterial,
} from '../pages';
import {
  ModalCommon,
  ModalProjects,
  ModalClt,
  ModalFax,
  ModalEmail,
  ModalKakao,
  ModalSMS,
  ModalBoilerplate,
  ModalApproval,
  ModalWaitQueue,
  ModalAddClt,
  ModalApprovalReference,
  ModalPush,
  ModalApprovalRemark,
  ModalApprovalReferenceRemark,
  ModalProduct,
  ModalRollback,
  ModalReproduce,
  ModalElmNotice,
  ModalNotify,
  ModalContract,
  ModalInstall,
  ModalHistory,
  ModalWriter,
  ModalPerid,
  ModalCalendar,
  ModalImageEditor,
  ModalSearchAddress,
  ModalComboBox,
  ModalQnA,
  ModalExpiration,
  ModalAddress,
  ModalAdvice,
  ModalGosiAsk,
  ModalKS,
} from './templates';

export interface AuthorizedAppProps {
  menuStore?: MenuStore;
  modalStore?: ModalStore;
  waitQueueStore?: WaitQueueStore;
  publicStore?: PublicStore;
  routerStore?: RouterStore;
  headerTabStore?: HeaderTabStore;
  toolStore?: ToolStore;
  favoriteMenuStore?: FavoriteMenuStore;
}

@inject(
  'menuStore',
  'modalStore',
  'publicStore',
  'routerStore',
  'headerTabStore',
  'toolStore',
  'waitQueueStore',
  'favoriteMenuStore',
)
@observer
export class AuthorizedScreen extends React.Component<AuthorizedAppProps> {
  @action keyboardHook(e: React.KeyboardEvent<HTMLDivElement>) {
    const { toolStore } = this.props;
    if (e.ctrlKey) {
      switch (e.key) {
        case 's':
          e.preventDefault();
          e.stopPropagation();
          toolStore?.onSaveHandler();
          return false;

        case 'q':
          e.preventDefault();
          e.stopPropagation();
          toolStore?.onNewHandler();
          return false;

        case 'r':
          e.preventDefault();
          e.stopPropagation();
          toolStore?.onRetrieveHandler();
          return false;

        case 'p':
          e.preventDefault();
          e.stopPropagation();
          toolStore?.onPrintHandler();
          return false;

        case 'e':
          e.preventDefault();
          e.stopPropagation();
          toolStore?.onExcelHandler();
          return false;

        case 'd':
          e.preventDefault();
          e.stopPropagation();
          toolStore?.onDeleteHandler();
          return false;
      }
    }
    return true;
  }

  render() {
    const {
      menuStore,
      waitQueueStore,
      modalStore,
      publicStore,
      routerStore,
      headerTabStore,
      toolStore,
      favoriteMenuStore,
    } = this.props;

    return (
      <React.Fragment>
        <HeaderTools user={publicStore!.user} toolStore={toolStore!} />
        <HeaderMenu
          publicStore={publicStore!}
          menuStore={menuStore!}
          onSelect={(item) => {
            publicStore!.openDefaultMenu();
            publicStore!.currentMenu.setPrimary(item);
          }}
        />
        <div className={style.contents}>
          <SideMenu
            publicStore={publicStore!}
            routerStore={routerStore!}
            headerTabStore={headerTabStore!}
            favoriteMenuStore={favoriteMenuStore!}
          />

          <div className={style.contents__body}>
            <HeaderTab
              publicStore={publicStore!}
              tabStore={headerTabStore!}
              routerStore={routerStore!}
              favoriteMenuStore={favoriteMenuStore!}
            />

            <div
              className={style.contents__view}
              onKeyDown={(e) => this.keyboardHook(e)}
            >
              <Router history={routerStore!.history}>
                <CacheSwitch>
                  {/* <공용> */}
                  <Route exact path="/" component={withScreen(Dash)} />
                  {/* </공용> */}


                  {/* <기준정보> */}
                  {/* <기초코드> */}
                  <CacheRoute exact path="/w_xa011_input" component={withScreen(GPCust)} cacheKey="/w_xa011_input" />
                  <CacheRoute exact path="/w_xa012" component={withScreen(GPSpjang)} cacheKey="/w_xa012" />
                  <CacheRoute exact path="/w_s010" component={withScreen(GPBank)} cacheKey="/w_s010" />
                  <CacheRoute exact path="/w_s004" component={withScreen(GPAccount)} cacheKey="/w_s004" />
                  <CacheRoute exact path="/w_card_insert" component={withScreen(GPCard)} cacheKey="/w_card_insert" />
                  <CacheRoute exact path="/w_s007" component={withScreen(GPCredit)} cacheKey="/w_s007" />
                  <CacheRoute exact path="/w_jirosetup" component={withScreen(GPJiro)} cacheKey="/w_jirosetup" />
                  <CacheRoute exact path="/w_tb_ca510" component={withScreen(GPPublic)} cacheKey="/w_tb_ca510" />
                  <CacheRoute exact path="/w_tb_xappnum" component={withScreen(GPMobile)} cacheKey="/w_tb_xappnum" />
                  <CacheRoute exact path="/w_gps_lbs" component={withScreen(GPLbs)} cacheKey="/w_gps_lbs" />
                  <CacheRoute exact path="/w_xusers" component={withScreen(GPSettings)} cacheKey="/w_xusers" />
                  {/* </기초코드> */}

                  {/* <인사코드관리> */}
                  <CacheRoute exact path="/w_pz008" component={withScreen(GHTopDepartment)} cacheKey="/w_pz008" />
                  <CacheRoute exact path="/w_jc002" component={withScreen(GHDepartmentCode)} cacheKey="/w_jc002" />
                  <CacheRoute exact path="/w_p1110" component={withScreen(GHPositionCode)} cacheKey="/w_p1110" />
                  <CacheRoute exact path="/w_pz006" component={withScreen(GHAppointment)} cacheKey="/w_pz006" />
                  <CacheRoute exact path="/w_pz010" component={withScreen(GHHolidayCode)} cacheKey="/w_pz010" />
                  {/* </인사코드관리> */}

                  {/* <비용코드관리> */}
                  <CacheRoute exact path="/w_tb_ae001" component={withScreen(GCExpense)} cacheKey="/w_tb_ae001" />
                  <CacheRoute exact path="/w_tb_ca647" component={withScreen(GCCostClause)} cacheKey="/w_tb_ca647" />
                  {/* </비용코드관리> */}

                  {/* <매출코드관리> */}
                  <CacheRoute exact path="/w_tb_da020" component={withScreen(GSSortationCode)} cacheKey="/w_tb_da020" />
                  {/* </매출코드관리> */}

                  {/* <자재코드관리> */}
                  <CacheRoute exact path="/w_tb_ca504" component={withScreen(GMStorageCode)} cacheKey="/w_tb_ca504" />
                  <CacheRoute exact path="/w_tb_ca503" component={withScreen(GMProductGroup)} cacheKey="/w_tb_ca503" />
                  <CacheRoute exact path="/w_tb_ca501_01" component={withScreen(GMProductCode)} cacheKey="/w_tb_ca501_01" />
                  <CacheRoute exact path="/w_tb_ca501_cltcd" component={withScreen(GMProductAccount)} cacheKey="/w_tb_ca501_cltcd" />
                  {/* </설치코드관리> */}
                  <CacheRoute exact path="/w_tb_e601_sulchi_step" component={withScreen(GIWork)} cacheKey="/w_tb_e601_sulchi_step" />
                  <CacheRoute exact path="/w_tb_da450" component={withScreen(GIItem)} cacheKey="/w_tb_da450" />
                  <CacheRoute exact path="/w_tb_e042" component={withScreen(GIEarlyInspection)} cacheKey="/w_tb_e042" />
                  {/* <유지코드관리> */}
                  <CacheRoute exact path="/w_tb_e001" component={withScreen(GMElevatorManagementCode)} cacheKey="/w_tb_e001" />
                  <CacheRoute exact path="/w_tb_e006_err" component={withScreen(GMElevatorErrorCode)} cacheKey="/w_tb_e006_err" />
                  <CacheRoute exact path="/w_tb_e300" component={withScreen(GMComplaint)} cacheKey="/w_tb_e300" />
                  <CacheRoute exact path="/w_tb_e027" component={withScreen(GMCheckDepartment)} cacheKey="/w_tb_e027" />
                  <CacheRoute exact path="/w_tb_e028" component={withScreen(GMRegion)} cacheKey="/w_tb_e028" />
                  <CacheRoute exact path="/w_tb_e029" component={withScreen(GMBuildingUse)} cacheKey="/w_tb_e029" />
                  <CacheRoute exact path="/w_tb_e450" component={withScreen(GMEstimatedCost)} cacheKey="/w_tb_e450" />
                  <CacheRoute exact path="/w_tb_e031" component={withScreen(GMDailyInspection)} cacheKey="/w_tb_e031" />
                  <CacheRoute exact path="/w_tb_e030" component={withScreen(GMInspectionType)} cacheKey="/w_tb_e030" />
                  <CacheRoute exact path="/w_tb_e032" component={withScreen(GMSelfInspection)} cacheKey="/w_tb_e032" />
                  <CacheRoute exact path="/w_tb_e010" component={withScreen(GMFailure)} cacheKey="/w_tb_e010" />
                  <CacheRoute exact path="/w_tb_e450_bom_15" component={withScreen(GMRemodel)} cacheKey="/w_tb_e450_bom_15" />
                  <CacheRoute exact path="/w_tb_e015" component={withScreen(GMManagement)} cacheKey="/w_tb_e015" />
                  <CacheRoute exact path="/w_tb_e101_aver" component={withScreen(GMStandard)} cacheKey="/w_tb_e101_aver" />
                  {/* </유지코드관리> */}

                  {/* <전자결재코드관리> */}
                  <CacheRoute exact path="/w_tb_e502" component={withScreen(GESafetyManage)} cacheKey="/w_tb_e502" />
                  <CacheRoute exact path="/w_tb_e022" component={withScreen(GETopWork)} cacheKey="/w_tb_e022" />
                  <CacheRoute exact path="/w_tb_e021" component={withScreen(GEWorkCode)} cacheKey="/w_tb_e021" />
                  {/* </전자결재코드관리> */}

                  {/* <고객관리> */}
                  <CacheRoute exact path="/w_tb_e901" component={withScreen(GCCustomerGroup)} cacheKey="/w_tb_e901" />
                  <CacheRoute exact path="/w_tb_e902" component={withScreen(GCCallDivision)} cacheKey="/w_tb_e902" />
                  <CacheRoute exact path="/w_tb_e911" component={withScreen(GCCustomerRegister)} cacheKey="/w_tb_e911" />
                  <CacheRoute exact path="/w_tb_e911w_01" component={withScreen(GCCustomerPresent)} cacheKey="/w_tb_e911w_01" />
                  <CacheRoute exact path="/w_tb_callmainw" component={withScreen(GCTellReceive)} cacheKey="/w_tb_callmainw" />
                  <CacheRoute exact path="/w_tb_sms_repeat" component={withScreen(GCTextOften)} cacheKey="/w_tb_sms_repeat" />
                  <CacheRoute exact path="/w_tb_sms_report" component={withScreen(GCTextTransmit)} cacheKey="/w_tb_sms_report" />
                  <CacheRoute exact path="/w_tb_KaKao_report" component={withScreen(GCKakaoTalk)} cacheKey="/w_tb_KaKao_report" />
                  {/* </고객관리> */}

                  {/* <시스템접속정보> */}
                  <CacheRoute exact path="/w_tb_xlogin_01" component={withScreen(GSSystem)} cacheKey="/w_tb_xlogin_01" />
                  <CacheRoute exact path="/w_tb_xlogin_02" component={withScreen(GSUser)} cacheKey="/w_tb_xlogin_02" />
                  {/* </시스템접속정보> */}
                  {/* </기준정보> */}


                  {/* <총 무/인사관리> */}
                  {/* <사원관리> */}
                  <CacheRoute exact path="/w_p2110" component={withScreen(HSPersonnelResistration)} cacheKey="/w_p2110" />
                  <CacheRoute exact path="/w_p2120" component={withScreen(HSAppoint)} cacheKey="/w_p2120" />
                  <CacheRoute exact path="/w_pa100" component={withScreen(HSIssue)} cacheKey="/w_pa100" />
                  <CacheRoute exact path="/w_p3181" component={withScreen(HSStaffList)} cacheKey="/w_p3181" />
                  <CacheRoute exact path="/w_tb_ja001_01w" component={withScreen(HSPairing)} cacheKey="/w_tb_ja001_01w" />
                  <CacheRoute exact path="/w_tb_ja001_02" component={withScreen(HSMonthly)} cacheKey="/w_tb_ja001_02" />
                  <CacheRoute exact path="/w_pa108w" component={withScreen(HSSelfCertificate)} cacheKey="/w_pa108w" />
                  <CacheRoute exact path="/w_tb_ja001_talent" component={withScreen(HSStaffScore)} cacheKey="/w_tb_ja001_talent" />
                  {/* <사원관리> */}

                  {/* <거래처관리> */}
                  <CacheRoute exact path="/w_tb_xclient" component={withScreen(HAResister)} cacheKey="/w_tb_xclient" />
                  <CacheRoute exact path="/w_tb_xclient_01" component={withScreen(HAStatus)} cacheKey="/w_tb_xclient_01" />
                  {/* <거래처관리> */}

                  {/* <근태관리> */}
                  <CacheRoute exact path="/w_tb_pb201" component={withScreen(HCDay)} cacheKey="/w_tb_pb201" />
                  <CacheRoute exact path="/w_tb_pb201w_01" component={withScreen(HCMonth)} cacheKey="/w_tb_pb201w_01" />
                  <CacheRoute exact path="/w_pb209" component={withScreen(HCYear)} cacheKey="/w_pb209" />
                  {/* <근태관리> */}

                  {/* <차량등록> */}
                  <CacheRoute exact path="/w_tb_pa105" component={withScreen(HCCar)} cacheKey="/w_tb_pa105" />
                  {/* <차량등록> */}

                  {/* <공지사항> */}
                  <CacheRoute exact path="/w_tb_xgw010_01" component={withScreen(HNNotice)} cacheKey="/w_tb_xgw010_01" />
                  {/* <공지사항> */}
                  {/* <총 무/인사관리> */}


                  {/* <회계관리> */}
                  {/* <주현황> */}
                  <CacheRoute exact path="/w_tb_ca642w_11" component={withScreen(AMOneDayDeadline)} cacheKey="/w_tb_ca642w_11" />
                  <CacheRoute exact path="/w_tb_ca642w_10" component={withScreen(AMBankStatement)} cacheKey="/w_tb_ca642w_10" />
                  <CacheRoute exact path="/w_tb_ca642w_12" component={withScreen(AMMonthMoney)} cacheKey="/w_tb_ca642w_12" />
                  <CacheRoute exact path="/w_tb_ca642w_13" component={withScreen(AMSalesPurchase)} cacheKey="/w_tb_ca642w_13" />
                  <CacheRoute exact path="/w_input_da023w_04" component={withScreen(AMChargeReceivable)} cacheKey="/w_input_da023w_04" />
                  <CacheRoute exact path="/w_input_da023w" component={withScreen(AMReceivable)} cacheKey="/w_input_da023w" />
                  <CacheRoute exact path="/w_input_da023w_03_view" component={withScreen(AMLongReceivable)} cacheKey="/w_input_da023w_03_view" />
                  <CacheRoute exact path="/w_tb_ca642w_15" component={withScreen(AMMonthlyIncomeStatement)} cacheKey="/w_tb_ca642w_15" />
                  <CacheRoute exact path="/w_tb_ca642w_02" component={withScreen(AMUnpaid)} cacheKey="/w_tb_ca642w_02" />
                  <CacheRoute exact path="/w_tb_da003_01w_view" component={withScreen(AMProject)} cacheKey="/w_tb_da003_01w_view" />
                  <CacheRoute exact path="/w_tb_ca642w_16" component={withScreen(AMIndustry)} cacheKey="/w_tb_ca642w_16" />
                  {/* </주현황> */}

                  {/* <수금관리> */}
                  <CacheRoute exact path="/w_tb_da023" component={withScreen(AMSales)} cacheKey="/w_tb_da023" />
                  <CacheRoute exact path="/w_tb_da023_import" component={withScreen(AMSalesNationalTaxService)} cacheKey="/w_tb_da023_import" />
                  <CacheRoute exact path="/w_tb_e050w" component={withScreen(AMSalesState)} cacheKey="/w_tb_e050w" />
                  <CacheRoute exact path="/w_tb_e050w_05" component={withScreen(AMMonthSales)} cacheKey="/w_tb_e050w_05" />
                  <CacheRoute exact path="/w_tb_e050" component={withScreen(AMMaintenance)} cacheKey="/w_tb_e050" />
                  <CacheRoute exact path="/w_tb_e050w_02" component={withScreen(AMJiroBundle)} cacheKey="/w_tb_e050w_02" />
                  <CacheRoute exact path="/w_tb_e050w_04" component={withScreen(AMBillBundle)} cacheKey="/w_tb_e050w_04" />
                  <CacheRoute exact path="/w_tb_ia090" component={withScreen(AMElectronicTax)} cacheKey="/w_tb_ia090" />
                  <CacheRoute exact path="/w_tb_e050w_03" component={withScreen(AMTaxInvoice)} cacheKey="/w_tb_e050w_03" />
                  <CacheRoute exact path="/w_input_da023w_02" component={withScreen(AMLastMonth)} cacheKey="/w_input_da023w_02" />
                  <CacheRoute exact path="/w_input_da026_all" component={withScreen(AMDepositInput)} cacheKey="/w_input_da026_all" />
                  <CacheRoute exact path="/w_tb_da026_import" component={withScreen(AMJiroAuto)} cacheKey="/w_tb_da026_import" />
                  <CacheRoute exact path="/w_tb_da026_cms" component={withScreen(AMCMSAuto)} cacheKey="/w_tb_da026_cms" />
                  <CacheRoute exact path="/w_input_da026w" component={withScreen(AMInputState)} cacheKey="/w_input_da026w" />
                  <CacheRoute exact path="/w_input_da026w_02" component={withScreen(AMMonthInput)} cacheKey="/w_input_da026w_02" />
                  <CacheRoute exact path="/w_tb_da026_sms" component={withScreen(AMDepositConfirmation)} cacheKey="/w_tb_da026_sms" />
                  <CacheRoute exact path="/w_tb_e050w_elman" component={withScreen(AMElmanData)} cacheKey="/w_tb_e050w_elman" />
                  <CacheRoute exact path="/w_input_da023w_03" component={withScreen(AMLongReceivable2)} cacheKey="/w_input_da023w_03" />
                  {/* </수금관리> */}

                  {/* <출금관리> */}
                  <CacheRoute exact path="/w_tb_ca640_import" component={withScreen(AWBuyNationalTaxService)} cacheKey="/w_tb_ca640_import" />
                  <CacheRoute exact path="/w_tb_ca640_pa105" component={withScreen(AWDollarAverage)} cacheKey="/w_tb_ca640_pa105" />
                  <CacheRoute exact path="/w_tb_ca640w_02" component={withScreen(AWPurchaseTax)} cacheKey="/w_tb_ca640w_02" />
                  <CacheRoute exact path="/w_tb_ca640w" component={withScreen(AWPurchaseState)} cacheKey="/w_tb_ca640w" />
                  <CacheRoute exact path="/w_tb_ca642w_01" component={withScreen(AWGiveState)} cacheKey="/w_tb_ca642w_01" />
                  <CacheRoute exact path="/w_tb_ca642_01_all" component={withScreen(AWGiveRegistration)} cacheKey="/w_tb_ca642_01_all" />
                  <CacheRoute exact path="/w_tb_ca640_pa105_01w" component={withScreen(AWMonthOil)} cacheKey="/w_tb_ca640_pa105_01w" />
                  <CacheRoute exact path="/w_tb_ca640_pa105_02w" component={withScreen(AWTransportationFee)} cacheKey="/w_tb_ca640_pa105_02w" />
                  <CacheRoute exact path="/w_tb_ca640w_03" component={withScreen(AWYearArticle)} cacheKey="/w_tb_ca640w_03" />
                  <CacheRoute exact path="/w_tb_ca642w_17" component={withScreen(AWClientMonth)} cacheKey="/w_tb_ca642w_17" />
                  <CacheRoute exact path="/w_tb_ae200w_01" component={withScreen(AWDisbursement)} cacheKey="/w_tb_ae200w_01" />
                  <CacheRoute exact path="/w_tb_ca640_all" component={withScreen(AWRegularBuy)} cacheKey="/w_tb_ca640_all" />
                  <CacheRoute exact path="/w_tb_ca640" component={withScreen(AWBuyRegister)} cacheKey="/w_tb_ca640" />
                  {/* </출금관리> */}

                  {/* <가지급관리> */}
                  <CacheRoute exact path="/w_tb_ca643" component={withScreen(APPaymentRegister)} cacheKey="/w_tb_ca643" />
                  <CacheRoute exact path="/w_tb_ca644" component={withScreen(APPaymentCalculate)} cacheKey="/w_tb_ca644" />
                  {/* </가지급관리> */}

                  {/* <급여관리> */}
                  <CacheRoute exact path="/w_tb_pb300_02" component={withScreen(AMPayCurrentState)} cacheKey="/w_tb_pb300_02" />
                  <CacheRoute exact path="/w_tb_pb300_01" component={withScreen(AMPayCalculate)} cacheKey="/w_tb_pb300_01" />
                  <CacheRoute exact path="/w_tb_pb300_basic" component={withScreen(AMBenefitItem)} cacheKey="/w_tb_pb300_basic" />
                  <CacheRoute exact path="/w_tb_pb300_06" component={withScreen(AMLastMonthPay)} cacheKey="/w_tb_pb300_06" />
                  <CacheRoute exact path="/w_tb_pb300_03" component={withScreen(AMYearPay)} cacheKey="/w_tb_pb300_03" />
                  <CacheRoute exact path="/w_tb_pb301_01" component={withScreen(AMServerancePayRegister)} cacheKey="/w_tb_pb301_01" />
                  <CacheRoute exact path="/w_tb_pb300_04" component={withScreen(AMPayslip)} cacheKey="/w_tb_pb300_04" />
                  <CacheRoute exact path="/w_tb_pb300_05" component={withScreen(AMPrepaymentBundle)} cacheKey="/w_tb_pb300_05" />
                  <CacheRoute exact path="/w_tb_pb301_02" component={withScreen(AMYearSeverancePay)} cacheKey="/w_tb_pb301_02" />
                  <CacheRoute exact path="/w_tb_pb301_03" component={withScreen(AMSeverancePay)} cacheKey="/w_tb_pb301_03" />
                  <CacheRoute exact path="/w_tb_pb300_insu" component={withScreen(AMYearInsuranceRatesRegister)} cacheKey="/w_tb_pb300_insu" />
                  {/* </가지급관리> */}

                  {/* <프로젝트관리> */}
                  <CacheRoute exact path="/w_tb_da003_01" component={withScreen(APProjectCode)} cacheKey="/w_tb_da003_01" />
                  <CacheRoute exact path="/w_tb_da003_01w" component={withScreen(APProject)} cacheKey="/w_tb_da003_01w" />
                  <CacheRoute exact path="/w_tb_da003_03w" component={withScreen(APMonthProjects)} cacheKey="/w_tb_da003_03w" />
                  {/* </프로젝트관리> */}

                  {/* <세금계산서> */}
                  <CacheRoute exact path="/w_tb_ia090_list" component={withScreen(ATTaxInvoice)} cacheKey="/w_tb_ia090_list" />
                  {/* </세금계산서> */}

                  {/* <예금관리> */}
                  <CacheRoute exact path="/w_tb_ca642w_09" component={withScreen(ACCashBook)} cacheKey="/w_tb_ca642w_09" />
                  <CacheRoute exact path="/w_tb_ca642_ats" component={withScreen(ACManageDeposit)} cacheKey="/w_tb_ca642_ats" />
                  {/* </예금관리> */}

                  {/* <월마감> */}
                  <CacheRoute
                    exact
                    path="/w_tb_monend_incentive_notuseamt"
                    component={withScreen(AMMonthProject)}
                    cacheKey="/w_tb_monend_incentive_notuseamt"
                  />
                  {/* </월마감> */}
                  {/* </회계관리> */}


                  {/* <설치/영업관리> */}
                  {/* <수주관리> */}
                  <CacheRoute exact path="/w_tb_da006" component={withScreen(IMEnrollment)} cacheKey="/w_tb_da006" />
                  <CacheRoute exact path="/w_preview_da006" component={withScreen(IMStatus)} cacheKey="/w_preview_da006" />
                  {/* </수주관리> */}

                  {/* <설치현장관리> */}
                  <CacheRoute exact path="/w_tb_e601_sulchi" component={withScreen(ISRegist)} cacheKey="/w_tb_e601_sulchi" />
                  <CacheRoute exact path="/w_tb_e601_dp_01" component={withScreen(ISDrawing)} cacheKey="/w_tb_e601_dp_01" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_05" component={withScreen(ISOrder)} cacheKey="/w_tb_e601w_sulchi_05" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_02" component={withScreen(ISCollect)} cacheKey="/w_tb_e601w_sulchi_02" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_01" component={withScreen(ISManageStatus)} cacheKey="/w_tb_e601w_sulchi_01" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_03" component={withScreen(ISProcess)} cacheKey="/w_tb_e601w_sulchi_03" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_09" component={withScreen(ISAchievementStatus)} cacheKey="/w_tb_e601w_sulchi_09" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_10" component={withScreen(ISOrderCollection)} cacheKey="/w_tb_e601w_sulchi_10" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_11" component={withScreen(ISOrderMonthly)} cacheKey="/w_tb_e601w_sulchi_11" />
                  {/* </설치현장관리> */}

                  {/* <설치공정관리> */}
                  <CacheRoute exact path="/w_tb_b035" component={withScreen(IPReport)} cacheKey="/w_tb_b035" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_06" component={withScreen(IPEffort)} cacheKey="/w_tb_e601w_sulchi_06" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_04" component={withScreen(IPStatus)} cacheKey="/w_tb_e601w_sulchi_04" />
                  {/* </설치공정관리> */}

                  {/* <설치외주관리> */}
                  <CacheRoute exact path="/w_tb_xusers_share" component={withScreen(IOWorker)} cacheKey="/w_tb_xusers_share" />
                  <CacheRoute exact path="/w_tb_e101_share" component={withScreen(IOContract)} cacheKey="/w_tb_e101_share" />
                  {/* </설치외주관리> */}

                  {/* <사전점검관리> */}
                  <CacheRoute exact path="/w_tb_e045" component={withScreen(IISchedule)} cacheKey="/w_tb_e045" />
                  <CacheRoute exact path="/w_tb_e045_03" component={withScreen(IIJournal)} cacheKey="/w_tb_e045_03" />
                  <CacheRoute exact path="/w_tb_da451w_01" component={withScreen(IEStatus)} cacheKey="/w_tb_da451w_01" />
                  {/* </사전점검관리> */}

                  {/* <영업관리> */}
                  <CacheRoute exact path="/w_tb_b501" component={withScreen(ISSchedule)} cacheKey="/w_tb_b501" />
                  <CacheRoute exact path="/w_tb_b502" component={withScreen(ISReport)} cacheKey="/w_tb_b502" />
                  {/* </영업관리> */}

                  {/* <견적관리> */}
                  <CacheRoute exact path="/w_tb_da451" component={withScreen(IEEnrollment)} cacheKey="/w_tb_da451" />
                  {/* </견적관리> */}
                  {/* </설치/영업관리> */}


                  {/* <유지보수> */}
                  {/* <보수현장관리> */}
                  <CacheRoute exact path="/w_tb_e601_new" component={withScreen(MMEnrollment)} cacheKey="/w_tb_e601_new" />
                  <CacheRoute exact path="/w_tb_e601w" component={withScreen(MMRepairCurrent)} cacheKey="/w_tb_e601w" />
                  <CacheRoute exact path="/w_tb_e611_type" component={withScreen(MMElevatorInfo)} cacheKey="/w_tb_e611_type" />
                  <CacheRoute exact path="/w_tb_e611_gosi" component={withScreen(MMRetrieveElevator)} cacheKey="/w_tb_e611_gosi" />
                  <CacheRoute exact path="/w_tb_e601_dp_02" component={withScreen(MMFloorPlan)} cacheKey="/w_tb_e601_dp_02" />
                  <CacheRoute exact path="/w_tb_e101w" component={withScreen(MMRepairSite)} cacheKey="/w_tb_e101w" />
                  <CacheRoute exact path="/w_tb_e101w_01" component={withScreen(MMEndContract)} cacheKey="/w_tb_e101w_01" />
                  <CacheRoute exact path="/w_tb_e101w_02" component={withScreen(MMMonthContract)} cacheKey="/w_tb_e101w_02" />
                  <CacheRoute exact path="/w_tb_e101w_03" component={withScreen(MMFree)} cacheKey="/w_tb_e101w_03" />
                  <CacheRoute exact path="/w_tb_e101w_04" component={withScreen(MMRevocation)} cacheKey="/w_tb_e101w_04" />
                  <CacheRoute exact path="/w_tb_e601w_02" component={withScreen(MMOldSpace)} cacheKey="/w_tb_e601w_02" />
                  <CacheRoute exact path="/w_tb_e101w_05" component={withScreen(MMStatus)} cacheKey="/w_tb_e101w_05" />
                  <CacheRoute exact path="/w_tb_e101w_07" component={withScreen(MMPreviousMonth)} cacheKey="/w_tb_e101w_07" />
                  {/* </보수현장관리> */}

                  {/* <점검관리> */}
                  <CacheRoute exact path="/w_tb_e611_035_03" component={withScreen(MMCUnit)} cacheKey="/w_tb_e611_035_03" />
                  <CacheRoute exact path="/w_tb_e035" component={withScreen(MMCPlan)} cacheKey="/w_tb_e035" />
                  <CacheRoute exact path="/w_tb_e035_03" component={withScreen(MMCResult)} cacheKey="/w_tb_e035_03" />
                  <CacheRoute exact path="/w_tb_e033w" component={withScreen(MMCSelfCheck)} cacheKey="/w_tb_e033w" />
                  <CacheRoute exact path="/w_tb_e035w_02" component={withScreen(MMCDefect)} cacheKey="/w_tb_e035w_02" />
                  <CacheRoute exact path="/w_tb_e035_gosi_02" component={withScreen(MMCNation)} cacheKey="/w_tb_e035_gosi_02" />
                  <CacheRoute exact path="/w_tb_e035_gosi" component={withScreen(MMCElectronCheck)} cacheKey="/w_tb_e035_gosi" />
                  <CacheRoute exact path="/w_tb_e035w_04" component={withScreen(MMCInspectorStatus)} cacheKey="/w_tb_e035w_04" />
                  <CacheRoute exact path="/w_tb_e034" component={withScreen(MMCDailyCheck)} cacheKey="/w_tb_e034" />
                  <CacheRoute exact path="/w_tb_e034w_01" component={withScreen(MMCDailyChart)} cacheKey="/w_tb_e034w_01" />
                  <CacheRoute exact path="/w_tb_e035w_03" component={withScreen(MMInspectionNotice)} cacheKey="/w_tb_e035w_03" />
                  {/* </점검관리> */}

                  {/* <고장관리> */}
                  <CacheRoute exact path="/w_tb_e401" component={withScreen(MBBreakResister)} cacheKey="/w_tb_e401" />
                  <CacheRoute exact path="/w_tb_e411_01" component={withScreen(MBBreakComplete)} cacheKey="/w_tb_e411_01" />
                  <CacheRoute exact path="/w_tb_e411_wad" component={withScreen(MBBreakReport)} cacheKey="/w_tb_e411_wad" />
                  <CacheRoute exact path="/w_tb_e411w" component={withScreen(MBBreakSituation)} cacheKey="/w_tb_e411w" />
                  <CacheRoute exact path="/w_tb_e411w_01" component={withScreen(MBBreakOften)} cacheKey="/w_tb_e411w_01" />
                  <CacheRoute exact path="/w_tb_e411w_08" component={withScreen(MBMonthBreakOften)} cacheKey="/w_tb_e411w_08" />
                  <CacheRoute exact path="/w_tb_e401w_cancle" component={withScreen(MBBreakRefusal)} cacheKey="/w_tb_e401w_cancle" />
                  <CacheRoute exact path="/w_tb_e401w_recvscore" component={withScreen(MBBreakCustomer)} cacheKey="/w_tb_e401w_recvscore" />
                  <CacheRoute exact path="/w_tb_e411w_02" component={withScreen(MBHumanCage)} cacheKey="/w_tb_e411w_02" />
                  <CacheRoute exact path="/w_tb_e411w_03" component={withScreen(MBNonDriving)} cacheKey="/w_tb_e411w_03" />
                  <CacheRoute exact path="/w_tb_e411w_04" component={withScreen(MBResponse)} cacheKey="/w_tb_e411w_04" />
                  <CacheRoute exact path="/w_tb_e411w_07" component={withScreen(MBBreakAnalysisAll)} cacheKey="/w_tb_e411w_07" />
                  <CacheRoute exact path="/w_tb_e411w_05" component={withScreen(MBBreakAnalysisOne)} cacheKey="/w_tb_e411w_05" />
                  <CacheRoute exact path="/w_tb_e411w_06" component={withScreen(MBBreakPercent)} cacheKey="/w_tb_e411w_06" />
                  <CacheRoute exact path="/w_tb_e401w_01" component={withScreen(MBBreakNumber)} cacheKey="/w_tb_e401w_01" />
                  <CacheRoute exact path="/w_tb_e401w_02" component={withScreen(MBBreakUnit)} cacheKey="/w_tb_e401w_02" />
                  <CacheRoute exact path="/w_tb_e411_cri" component={withScreen(MBCageReport)} cacheKey="/w_tb_e411_cri" />
                  {/* </고장관리> */}

                  {/* <민원관리> */}
                  <CacheRoute exact path="/w_tb_e301" component={withScreen(MCResister)} cacheKey="/w_tb_e301" />
                  <CacheRoute exact path="/w_tb_e311" component={withScreen(MCReport)} cacheKey="/w_tb_e311" />
                  {/* </민원관리> */}

                  {/* <검사관리> */}
                  <CacheRoute exact path="/w_tb_e073" component={withScreen(MIEnrollment)} cacheKey="/w_tb_e073" />
                  <CacheRoute exact path="/w_tb_e073w_01" component={withScreen(MIApplication)} cacheKey="/w_tb_e073w_01" />
                  <CacheRoute exact path="/w_tb_e075w" component={withScreen(MIResult)} cacheKey="/w_tb_e075w" />
                  <CacheRoute exact path="/w_tb_e075w_01" component={withScreen(MIUnitinspect)} cacheKey="/w_tb_e075w_01" />
                  {/* </검사관리> */}

                  {/* <견적관리> */}
                  <CacheRoute exact path="/w_tb_e451" component={withScreen(MEEnrollment)} cacheKey="/w_tb_e451" />
                  <CacheRoute exact path="/w_tb_e451w" component={withScreen(MEStatus)} cacheKey="/w_tb_e451w" />
                  <CacheRoute exact path="/w_tb_e448" component={withScreen(MEProposal)} cacheKey="/w_tb_e448" />
                  <CacheRoute exact path="/w_tb_ca501_age" component={withScreen(MEPartsCheckList)} cacheKey="/w_tb_ca501_age" />
                  <CacheRoute exact path="/w_tb_e471w_05" component={withScreen(MEFieldPartsReplace)} cacheKey="/w_tb_e471w_05" />
                  {/* </견적관리> */}

                  {/* <공사관리> */}
                  <CacheRoute exact path="/w_tb_e471" component={withScreen(MCEnrollment)} cacheKey="/w_tb_e471" />
                  <CacheRoute exact path="/w_tb_e471w" component={withScreen(MCStatus)} cacheKey="/w_tb_e471w" />
                  <CacheRoute exact path="/w_tb_e471w_02" component={withScreen(MCResult)} cacheKey="/w_tb_e471w_02" />
                  <CacheRoute exact path="/w_tb_e470" component={withScreen(MCPlan)} cacheKey="/w_tb_e470" />
                  <CacheRoute exact path="/w_tb_e471w_03" component={withScreen(MCMonthConstruction)} cacheKey="/w_tb_e471w_03" />
                  {/* </공사관리> */}

                  {/* <스마트원격지원> */}
                  <CacheRoute exact path="/w_tb_ar" component={withScreen(MAAR)} cacheKey="/w_tb_ar" />
                  <CacheRoute exact path="/w_tb_video" component={withScreen(MAVideo)} cacheKey="/w_tb_video" />
                  {/* </스마트원격지원> */}
                  {/* </유지보수> */}


                  {/* <전자결재> */}
                  {/* <결재라인> */}
                  <CacheRoute exact path="/w_tb_e063" component={withScreen(EAEnroll)} cacheKey="/w_tb_e063" />
                  {/* </결재라인> */}

                  {/* <문서작성> */}
                  <CacheRoute exact path="/w_tb_e039_01" component={withScreen(EPPatrol)} cacheKey="/w_tb_e039_01" />
                  <CacheRoute exact path="/w_tb_ae200" component={withScreen(EPExpenditure)} cacheKey="/w_tb_ae200" />
                  <CacheRoute exact path="/w_tb_ca606_01" component={withScreen(EPBill)} cacheKey="/w_tb_ca606_01" />
                  <CacheRoute exact path="/w_pb204" component={withScreen(EPHoliday)} cacheKey="/w_pb204" />
                  <CacheRoute exact path="/w_pb204w_01" component={withScreen(EPHolidayStatement)} cacheKey="/w_pb204w_01" />
                  <CacheRoute exact path="/w_tb_pb401" component={withScreen(EPTrip)} cacheKey="/w_tb_pb401" />
                  <CacheRoute exact path="/w_tb_pb420" component={withScreen(EPOvertime)} cacheKey="/w_tb_pb420" />
                  <CacheRoute exact path="/w_tb_e509" component={withScreen(EPTeamEnroll)} cacheKey="/w_tb_e509" />
                  <CacheRoute exact path="/w_tb_e511" component={withScreen(EPPlanEnroll)} cacheKey="/w_tb_e511" />
                  <CacheRoute exact path="/w_tb_e512" component={withScreen(EPWorkReport)} cacheKey="/w_tb_e512" />
                  <CacheRoute exact path="/w_tb_e521" component={withScreen(EPSafetyEducation)} cacheKey="/w_tb_e521" />
                  <CacheRoute exact path="/w_tb_pb214" component={withScreen(EPHolidayWork)} cacheKey="/w_tb_pb214" />
                  <CacheRoute exact path="/w_tb_e101_cls" component={withScreen(EPTermination)} cacheKey="/w_tb_e101_cls" />
                  <CacheRoute exact path="/w_tb_e037w" component={withScreen(EPJournalStatement)} cacheKey="/w_tb_e037w" />
                  <CacheRoute exact path="/w_tb_pb404" component={withScreen(EPEducation)} cacheKey="/w_tb_pb404" />
                  <CacheRoute exact path="/w_tb_e501" component={withScreen(EPSchedule)} cacheKey="/w_tb_e501" />
                  <CacheRoute exact path="/w_tb_e037_type2" component={withScreen(EPJournal)} cacheKey="/w_tb_e037_type2" />
                  <CacheRoute exact path="/w_tb_e037_type1" component={withScreen(EPGeneralJournal)} cacheKey="/w_tb_e037_type1" />
                  <CacheRoute exact path="/w_tb_pb403" component={withScreen(EPIntegrate)} cacheKey="/w_tb_pb403" />
                  <CacheRoute exact path="/w_tb_pb407" component={withScreen(EPMeeting)} cacheKey="/w_tb_pb407" />
                  <CacheRoute exact path="/w_tb_e101_elec" component={withScreen(EPContract)} cacheKey="/w_tb_e101_elec" />
                  <CacheRoute exact path="/w_tb_pb405" component={withScreen(EPDiploma)} cacheKey="/w_tb_pb405" />
                  <CacheRoute exact path="/w_tb_e501w_01" component={withScreen(EPSiteVisit)} cacheKey="/w_tb_e501w_01" />
                  <CacheRoute exact path="/w_tb_e037w_03" component={withScreen(EPTodayPlan)} cacheKey="/w_tb_e037w_03" />
                  <CacheRoute exact path="/w_tb_e080w_03" component={withScreen(EDReferenceBox)} cacheKey="/w_tb_e080w_03" />
                  <CacheRoute exact path="/w_tb_e080w_01" component={withScreen(EDSubmitBox)} cacheKey="/w_tb_e080w_01" />
                  <CacheRoute exact path="/w_tb_e080_list" component={withScreen(EDIncompleteBox)} cacheKey="/w_tb_e080_list" />
                  <CacheRoute exact path="/w_tb_e080_list_02" component={withScreen(EDCompleteBox)} cacheKey="/w_tb_e080_list_02" />
                  <CacheRoute exact path="/w_tb_e080_attach_01" component={withScreen(EDFileStore)} cacheKey="/w_tb_e080_attach_01" />
                  {/* </문서작성> */}

                  {/* <메일함> */}
                  <CacheRoute exact path="/w_tb_email_send_01" component={withScreen(EMMailBox)} cacheKey="/w_tb_email_send_01" />
                  {/* </메일함> */}
                  {/* </문서함> */}

                  {/* <팩스함> */}
                  <CacheRoute exact path="/w_tb_fax_send_01" component={withScreen(EFSendFax)} cacheKey="/w_tb_fax_send_01" />
                  {/* </팩스함> */}
                  {/* </전자결재> */}


                  {/* <구매자재관리> */}
                  {/* <자재관리> */}
                  <CacheRoute exact path="/w_tb_ca606_01_2" component={withScreen(PMBill)} cacheKey="/w_tb_ca606_01_2" />
                  <CacheRoute exact path="/w_tb_ca606w_01" component={withScreen(PMBillStatement)} cacheKey="/w_tb_ca606w_01" />
                  <CacheRoute exact path="/w_tb_ca608_01" component={withScreen(PMOrder)} cacheKey="/w_tb_ca608_01" />
                  <CacheRoute exact path="/w_tb_ca608w_02" component={withScreen(PMOrderStatement)} cacheKey="/w_tb_ca608w_02" />
                  <CacheRoute exact path="/w_tb_ca611_01" component={withScreen(PMStock)} cacheKey="/w_tb_ca611_01" />
                  <CacheRoute exact path="/w_tb_ca611w_01" component={withScreen(PMStockStatement)} cacheKey="/w_tb_ca611w_01" />
                  <CacheRoute exact path="/w_tb_da034_01" component={withScreen(PMSupply)} cacheKey="/w_tb_da034_01" />
                  <CacheRoute exact path="/w_tb_da034w_01" component={withScreen(PMSupplyStatement)} cacheKey="/w_tb_da034w_01" />
                  {<CacheRoute exact path="/w_tb_da038_01" component={withScreen(PMReturnRegistration)} cacheKey="/w_tb_da038_01" />}
                  {<CacheRoute exact path="/w_tb_da034w_02" component={withScreen(PMMonthlyMaterial)} cacheKey="/w_tb_da034w_02" />}
                  {/* </자재관리> */}

                  {/* 재고관리 */}
                  <CacheRoute exact path="/w_tb_ca602" component={withScreen(PIWalkDown)} cacheKey="/w_tb_ca602" />
                  <CacheRoute exact path="/w_tb_ca602w_01" component={withScreen(PIBalance)} cacheKey="/w_tb_ca602w_01" />
                  <CacheRoute exact path="/w_tb_ca602w_02" component={withScreen(PIInventoryMovement)} cacheKey="/w_tb_ca602w_02" />
                  {/* </재고관리> */}

                  {/* <송장관리> */}
                  <CacheRoute exact path="/w_tb_ca900" component={withScreen(PIInvoice)} cacheKey="/w_tb_ca900" />
                  <CacheRoute exact path="/w_tb_gift_history" component={withScreen(PIHolidayGift)} cacheKey="/w_tb_gift_history" />
                  {/* </송장관리> */}
                  {/* </구매자재관리> */}


                  {/* <마감및계획> */}
                  {/* <마감> */}
                  <CacheRoute exact path="/w_tb_e101_monend" component={withScreen(DAContract)} cacheKey="/w_tb_e101_monend" />
                  <CacheRoute exact path="/w_tb_e101w_08" component={withScreen(DAContractStatistics)} cacheKey="/w_tb_e101w_08" />
                  <CacheRoute exact path="/w_tb_e601w_03" component={withScreen(DAMaintenance)} cacheKey="/w_tb_e601w_03" />
                  <CacheRoute exact path="/w_tb_monend_incentive" component={withScreen(DAMonthResult)} cacheKey="/w_tb_monend_incentive" />
                  <CacheRoute exact path="/w_tb_e512w_01" component={withScreen(DADuty)} cacheKey="/w_tb_e512w_01" />
                  <CacheRoute exact path="/w_tb_monend" component={withScreen(DAMonth)} cacheKey="/w_tb_monend" />
                  <CacheRoute exact path="/w_tb_yearend" component={withScreen(DAYear)} cacheKey="/w_tb_yearend" />
                  {/* </마감> */}

                  {/* <계획관리> */}
                  <CacheRoute exact path="/w_tb_da050" component={withScreen(DMCompany)} cacheKey="/w_tb_da050" />
                  <CacheRoute exact path="/w_tb_da051" component={withScreen(DMIndividual)} cacheKey="/w_tb_da051" />
                  <CacheRoute exact path="/w_tb_da052" component={withScreen(DMIndividualGoal)} cacheKey="/w_tb_da052" />
                  <CacheRoute exact path="/w_tb_da052w_07" component={withScreen(DMMonthWork)} cacheKey="/w_tb_da052w_07" />
                  <CacheRoute exact path="/w_tb_da052w_04" component={withScreen(DMGoalPlan)} cacheKey="/w_tb_da052w_04" />
                  <CacheRoute exact path="/w_tb_da052w_01" component={withScreen(DMGoalResult)} cacheKey="/w_tb_da052w_01" />
                  <CacheRoute exact path="/w_tb_da052w_08" component={withScreen(DMGoal)} cacheKey="/w_tb_da052w_08" />
                  <CacheRoute exact path="/w_tb_da052w_09" component={withScreen(DMPlan)} cacheKey="/w_tb_da052w_09" />
                  <CacheRoute exact path="/w_tb_da052w_02" component={withScreen(DMMonthSale)} cacheKey="/w_tb_da052w_02" />
                  <CacheRoute exact path="/w_tb_da052w_03" component={withScreen(DMManagement)} cacheKey="/w_tb_da052w_03" />
                  <CacheRoute exact path="/w_tb_da052w_05" component={withScreen(DMMonthAverage)} cacheKey="/w_tb_da052w_05" />
                  <CacheRoute exact path="/w_tb_e601w_sulchi_08" component={withScreen(DMInstall)} cacheKey="/w_tb_e601w_sulchi_08" />
                  <CacheRoute exact path="/w_tb_e471w_04" component={withScreen(DMConstructionSales)} cacheKey="/w_tb_e471w_04" />
                  <CacheRoute exact path="/w_tb_e101w_06" component={withScreen(DMPaySales)} cacheKey="/w_tb_e101w_06" />
                  <CacheRoute exact path="/w_tb_ca642w_14" component={withScreen(DMPurchasingStatistics)} cacheKey="/w_tb_ca642w_14" />
                  {/* </계획관리> */}
                  {/* </마감및계획> */}
                </CacheSwitch>
              </Router>
            </div>
          </div>
        </div>

        <div className={style.modals}>
          <ModalCommon modalStore={modalStore!} />
          <ModalProjects modalStore={modalStore!} />
          <ModalClt modalStore={modalStore!} />
          <ModalFax modalStore={modalStore!} />
          <ModalEmail modalStore={modalStore!} />
          <ModalKakao modalStore={modalStore!} />
          <ModalSMS modalStore={modalStore!} />
          <ModalBoilerplate modalStore={modalStore!} />
          <ModalApproval modalStore={modalStore!} />
          <ModalWaitQueue waitQueueStore={waitQueueStore!} modalStore={modalStore!} />
          <ModalAddClt modalStore={modalStore!} />
          <ModalApprovalReference modalStore={modalStore!} />
          <ModalPush modalStore={modalStore!} />
          <ModalApprovalRemark modalStore={modalStore!} />
          <ModalApprovalReferenceRemark modalStore={modalStore!} />
          <ModalProduct modalStore={modalStore!} />
          <ModalRollback modalStore={modalStore!} />
          <ModalReproduce modalStore={modalStore!} />
          <ModalElmNotice modalStore={modalStore!} />
          <ModalNotify modalStore={modalStore!} />
          <ModalContract modalStore={modalStore!} />
          <ModalInstall modalStore={modalStore!} />
          <ModalHistory modalStore={modalStore!} />
          <ModalWriter modalStore={modalStore!} />
          <ModalPerid modalStore={modalStore!} />
          <ModalImageEditor modalStore={modalStore!} />
          <ModalSearchAddress modalStore={modalStore!} />
          <ModalQnA modalStore={modalStore!} />
          <ModalExpiration modalStore={modalStore!} />
          <ModalAddress modalStore={modalStore!} />
          <ModalAdvice modalStore={modalStore!} />
          <ModalGosiAsk modalStore={modalStore!} />
          <ModalKS modalStore={modalStore!} />
          <ModalCalendar
            ref={modalStore!.refCalendarContainer}
            modalStore={modalStore!}
          />
          <ModalComboBox
            ref={modalStore!.refComboBoxContainer}
            modalStore={modalStore!}
          />
        </div>
      </React.Fragment>
    );
  }
}
