import { computed } from 'mobx';

/**
 * 매출내역 모델
 * @window w_tb_da003_01w
 * @category 프로젝트현황
 */
export class SalesModel {
  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** char(4)
   */
  misnum: string;

  /**
   * 순번
   *
   * **data type** char(3)
   */
  seq: string;

  /**
   * 계정과목
   *
   * **data type** varchar(20)
   */
  acccd: string;

  /**
   * 품목명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** char(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 매입가액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 매출금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 전자세금계산서
   *
   * **data type** char(50)
   */
  recivstatus: string;

  /**
   * 수주금액
   *
   * **data type** number
   */
  camt: string;

  /**
   * 예상지출금액
   *
   * **data type** number
   */
  ex_mijamt: string;

  /**
   * 발주금액
   *
   * **data type** number
   */
  balamt: string;

  /**
   * 수금여부
   *
   * **data type** number
   */
  rcvflag: string;

  /**
   * 손익액
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 손익률
   *
   * **data type** number
   */
  resupercnt: string;

  /**
   * 미매출잔액
   *
   * **data type** number
   */
  resuamt2: string;

  /**
   * 상태
   *
   * **data type** varchar(20)
   */
  state: string;

  /**
   * 구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  orddate: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  actgubun: string;

  /**
   * -
   *
   * **data type** varchar(1)
   */
  projno2: string;

  /**
   * 검사(완료)일자
   *
   * **data type** varchar(8)
   */
  kcdate: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  cnam: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  chk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.kcdate = data.kcdate || '';
    this.chk = data.chk || '';
    this.cnam = data.cnam || '';
    this.actgubun = data.actgubun || '';
    this.projno2 = data.projno2 || '';
    this.orddate = data.orddate || '';
    this.gubun = data.gubun || '';
    this.state = data.state || '';
    this.resuamt2 = data.resuamt2 || '';
    this.resupercnt = data.resupercnt || '';
    this.resuamt = data.resuamt || '';
    this.rcvflag = data.rcvflag || '';
    this.balamt = data.balamt || '';
    this.ex_mijamt = data.ex_mijamt || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.camt = data.camt || '';
    this.seq = data.seq || '';
    this.acccd = data.acccd || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.addamt = data.addamt || '';
    this.misamt = data.misamt || '';
    this.recivstatus = data.recivstatus || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
