import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import { Date8 } from '../../utils/time';

interface ModalApprovalReferenceRemarkProps {
  modalStore: ModalStore;
}

@observer
export class ModalApprovalReferenceRemark extends React.Component<ModalApprovalReferenceRemarkProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        isVisible={modalStore.isVisibleModalApprovalReferenceRemark}
        onClose={() => modalStore.closeApprovalReferenceRemark()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <GridLayout
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 15,
              },
              {
                id: 'apppernm',
                text: '결재자명',
                width: 17,
              },
              {
                id: 'rspnm',
                text: '직위',
                width: 17,
              },
              {
                id: 'appgubun',
                text: '결재구분',
                width: 17,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {modalStore.getGubunnm(x.appgubun)}
                  </FlexLayout>
                ),
              },
              {
                id: 'referflag',
                text: '수신참조확인',
                width: 17,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.referflag === '1' ? 'var(--color-blue)' : 'var(--color-red)',
                    }}
                  >
                    {x.referflag === '1' ? '확인' : '미확인'}
                  </FlexLayout>
                ),
              },
              {
                id: 'appdate',
                text: '결재일자',
                width: 17,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    {Date8.withDash(x.appdate)}
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore.approvalReferenceRemarkData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => modalStore.closeApprovalReferenceRemark()}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
