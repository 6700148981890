import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da052w_09
 * @category 계획대비추진리스트
 */
export class PlanModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(255)
   */
  equpnm: string;

  /**
   * 계획내용
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * Month1
   *
   * **data type** number
   */
  samt01: string;

  /**
   * Month2
   *
   * **data type** number
   */
  samt02: string;

  /**
   * Month3
   *
   * **data type** number
   */
  samt03: string;

  /**
   * 진행상태
   *
   * **data type** char(1)
   */
  compyn: string;

  /**
   * 진행내용
   *
   * **data type** varchar(255)
   */
  state_remark: string;

  /**
   * 월1
   *
   * **data type** number
   */
  costamt01: string;

  /**
   * 월2
   *
   * **data type** number
   */
  costamt02: string;

  /**
   * 월3
   *
   * **data type** number
   */
  costamt03: string;

  /**
   * 검색조건월
   *
   * **data type** varchar(6)
   */
  nmon: string;

  /**
   * 순서
   *
   * **data type** number
   */
  seq: string;

  /**
   * 월
   *
   * **data type** number
   */
  mon: string;

  /**
   * 코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.costamt01 = data.costamt01 || '';
    this.costamt02 = data.costamt02 || '';
    this.costamt03 = data.costamt03 || '';
    this.perid = data.perid || '';
    this.nmon = data.nmon || '';
    this.mon = data.mon || '';
    this.seq = data.seq || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.remark = data.remark || '';
    this.samt01 = data.samt01 || '';
    this.samt02 = data.samt02 || '';
    this.samt03 = data.samt03 || '';
    this.compyn = data.compyn || '';
    this.state_remark = data.state_remark || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
