import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Collect } from '../Collect';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 > 매입내역 - 그리드 레이아웃
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export const CollectTabPurchaseGridHeader: GLHF<any, Collect> = (scope) => ([
  {
    id: 'mijdate',
    text: '매입일자',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.mijdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijnum',
    text: '번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.mijnum}
      </FlexLayout>
    ),
  },
  {
    id: 'seq',
    text: '순번',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'acccd',
    text: '계정과목',
    width: 100,
    render: (x) => {
      const fp = scope.acccds.filter((y) => y.acccd === x.acccd);
      const nm = fp.length > 0 ? fp[0].accnm : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'mijcltnm',
    text: '지급처명',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.mijcltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 350,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '매입가액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataCollect, 'samt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataDa, 'tamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt) || ''}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '합계금액',
    width: 120,
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(Sum.all(scope.state.dataDa, 'mijamt'))}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mijamt) || ''}
      </FlexLayout>
    ),
  },
]);
