import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e050w_elman
 * @category 엘맨자료
 */
export class ElmanDataModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** char(4)
   */
  custcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  date: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  num: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 계정과목
   *
   * **data type** varchar(255)
   */
  accnm: string;

  /**
   * 적요
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입금
   *
   * **data type** float
   */
  iamt: string;

  /**
   * 출금
   *
   * **data type** float
   */
  camt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.date = data.date || '';
    this.num = data.num || '';
    this.seq = data.seq || '';
    this.accnm = data.accnm || '';
    this.remark = data.remark || '';
    this.iamt = data.iamt || '';
    this.camt = data.camt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
