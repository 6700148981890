import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { SelfCheck } from '../SelfCheck';
import { Tab1Header } from '../headers/tab1Header';


export const Tab1Template: React.FC<TemplateProps<SelfCheck>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.grid}
      header={Tab1Header(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      headerHeight={48}
      data={scope.state.SelfCheckList}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
