import { computed } from 'mobx';

/**
 * 모델
 * 검사이력정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentCheckModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  actcd: string;

  /**
   * 호기
   *
   * **data type** varchar(3)
   */
  equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  equpnm: string;

  /**
   * 계약
   *
   * **data type** varchar(1)
   */
  contyn: string;

  /**
   * 순번
   *
   * **data type** varchar(100)
   */
  seq: string

  /**
   * 상태
   *
   * **data type** varchar(1)
   */
  state: string;

  /**
   * 계획일자
   *
   * **data type** varchar(8)
   */
  plandate: string;

  /**
   * 검사기관
   *
   * **data type** varchar(100)
   */
  kcpernm: string;

  /**
   * 검사구분
   *
   * **data type** varchar(1)
   */
  gubun: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  kcdate: string;

  /**
   * 운행시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 운행종료일
   *
   * **data type** varchar(1)
   */
  enddate: string;

  /**
   * 합격유무(결과)
   *
   * **data type** varchar(1)
   */
  result: string;

  /**
   * 모름
   *
   * **data type** varchar(?)
   */
  elno: string;

  /**
   * 모름
   *
   * **data type** varchar(?)
   */
  elvtrmgtno1: string;

  /**
   * 모름
   *
   * **data type** varchar(?)
   */
  elvtrmgtno2: string;

  /**
   * 모름
   *
   * **data type** varchar(?)
   */
  failcnt: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.contyn = data.contyn || '';
    this.actcd = data.actcd || '';
    this.seq = data.seq || '';
    this.state = data.state || '';
    this.plandate = data.plandate || '';
    this.kcpernm = data.kcpernm || '';
    this.gubun = data.gubun || '';
    this.kcdate = data.kcdate || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.result = data.result || '';
    this.elno = data.elno || '';
    this.elvtrmgtno1 = data.elvtrmgtno1 || '';
    this.elvtrmgtno2 = data.elvtrmgtno2 || '';
    this.failcnt = data.failcnt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
