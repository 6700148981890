import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { BreakUnit } from '../BreakUnit';
import { BreakUnitTabMonthGridHeader } from '../headers/BreakUnitTabMonthGridHeader';

/**
 * 화면 > 탭 > 월별
 * @window w_tb_e401w
 * @category 설치수금현황
 */
export const BreakUnitTabMonthTemplate: React.FC<TemplateProps<BreakUnit>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={BreakUnitTabMonthGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.monthList}
    />
  </FlexLayout>
);
