import { IconType } from 'react-icons';
import { AiOutlineQuestion } from 'react-icons/ai';
import { ReactNode } from 'react';
import { ToolExecuteHandler } from '../../constants';

export default class TabModel {
  readonly id: string;

  readonly iconType?: IconType;

  readonly text?: ReactNode | string;

  onExecute?: ToolExecuteHandler;

  isEnabled?: boolean;

  data: any;

  constructor(
    id: string,
    icon: IconType,
    text: ReactNode | string,
    onExecute?: ToolExecuteHandler,
    isEnabled?: boolean,
    data?: any,
  ) {
    this.id = id;
    this.iconType = icon;
    this.text = text || '##';
    this.onExecute = onExecute || (() => false);
    this.isEnabled = isEnabled || false;
    this.data = data || {};
  }

  get icon(): IconType {
    return this.iconType || AiOutlineQuestion;
  }
}
