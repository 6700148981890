import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w_02
 * @category 사람갇힘현황
 */

export class HumanCageMonthModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(3)
   */
  readonly equpnm: string;

  /**
   * 접수일자
   *
   * **data type** varchar(15)
   */
  readonly recedate: string;

  /**
   * 접수시간
   *
   * **data type** varchar(100)
   */
  readonly recetime: string;

  /**
   * 도착일자
   *
   * **data type** varchar(10)
   */
  readonly arrivdate: string;

  /**
   * 도착시간
   *
   * **data type** varchar(100)
   */
  readonly arrivtime: string;

  /**
   * 완료일자
   *
   * **data type** varchar(3)
   */
  readonly compdate: string;

  /**
   * 완료시간
   *
   * **data type** varchar(3)
   */
  readonly comptime: string;

  /**
   * 대응시간
   *
   * **data type** varchar(100)
   */
  readonly resutime: string;

  /**
   * 처리시간
   *
   * **data type** varchar(0)
   */
  readonly resulttime: string;

  /**
   * 사람갇힘
   *
   * **data type** varchar(1)
   */
  readonly trouble: string;

  /**
   * 고장내용
   *
   * **data type** varchar(?)
   */
  readonly contnm: string;

  /**
   * 고장내용상세
   *
   * **data type** varchar(?)
   */
  readonly contremark: string;

  /**
   * 처리내용
   *
   * **data type** varchar(8)
   */
  readonly resunm: string;

  /**
   * 처리내용상세
   *
   * **data type** varchar(8)
   */
  readonly resuremark: string;

  /**
   * 고장요인
   *
   * **data type** varchar(10)
   */
  readonly remonm: string;

  /**
   * 고장요인상세
   *
   * **data type** varchar(8)
   */
  readonly remoremark: string;

  /**
   * 고장원인
   *
   * **data type** varchar(4)
   */
  readonly facnm: string;

  /**
   * 고장부위
   *
   * **data type** varchar(8)
   */
  readonly greginm: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar(4)
   */
  readonly reginm: string;

  /**
   * 처리방법
   *
   * **data type** varchar(4)
   */
  readonly resultnm: string;

  /**
   * 담당부서명
   *
   * **data type** varchar(?)
   */
  readonly actdivinm: string;

  /**
   * 담당부서명
   *
   * **data type** varchar(?)
   */
  readonly divinm: string;

  /**
   *  담당자
   *
   * **data type** varchar(?)
   */
  readonly actpernm: string;

  /**
   *  처리자
   *
   * **data type** varchar(?)
   */
  readonly pernm: string;

  /**
   *  접수자
   *
   * **data type** varchar(?)
   */
  readonly repernm: string;

  /**
   *  전체비운행
   *
   * **data type** varchar(?)
   */
  readonly totcompmon: string;

  /**
   *  전체관리대수
   *
   * **data type** varchar(?)
   */
  readonly totmon: string;

  /**
   *  전체평균
   *
   * **data type** varchar(?)
   */
  readonly compute_4: string;

  /**
   *  1월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon01: string;

  /**
   *  1월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon01: string;

  /**
   *  1월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_01: string;

  /**
   *  1월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon02: string;

  /**
   *  1월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon02: string;

  /**
   *  1월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_02: string;

  /**
   *  3월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon03: string;

  /**
   *  3월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon03: string;

  /**
   *  3월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_03: string;

  /**
   *  4월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon04: string;

  /**
   *  4월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon04: string;

  /**
   *  4월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_04: string;

  /**
   *  1월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon05: string;

  /**
   *  1월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon05: string;

  /**
   *  1월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_05: string;

  /**
   *  6월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon06: string;

  /**
   *  6월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon06: string;

  /**
   *  6월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_06: string;

  /**
   *  7월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon07: string;

  /**
   *  1월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon07: string;

  /**
   *  7월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_07: string;

  /**
   *  8월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon08: string;

  /**
   *  8월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon08: string;

  /**
   *  8월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_08: string;

  /**
   *  9월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon09: string;

  /**
   *  9월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon09: string;

  /**
   *  9월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_09: string;

  /**
   *  10월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon10: string;

  /**
   *  10월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon10: string;

  /**
   *  10월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_10: string;

  /**
   *  11월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon11: string;

  /**
   *  11월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon11: string;

  /**
   *  11월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_11: string;

  /**
   *  12월관리대수
   *
   * **data type** varchar(?)
   */
  readonly mon12: string;

  /**
   *  12월 비운행
   *
   * **data type** varchar(?)
   */
  readonly compmon12: string;

  /**
   *  12월 평균
   *
   * **data type** varchar(?)
   */
  readonly compute_12: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.arrivdate = data.arrivdate || '';
    this.arrivtime = data.arrivtime || '';
    this.recedate = data.recedate || '';
    this.compdate = data.compdate || '';
    this.comptime = data.comptime || '';
    this.resutime = data.resutime || '';
    this.resulttime = data.resulttime || '';
    this.trouble = data.trouble || '';
    this.contremark = data.contremark || '';
    this.resunm = data.resunm || '';
    this.resuremark = data.resuremark || '';
    this.remonm = data.remonm || '';
    this.remoremark = data.remoremark || '';
    this.facnm = data.facnm || '';
    this.greginm = data.greginm || '';
    this.reginm = data.reginm || '';
    this.resultnm = data.resultnm || '';
    this.actdivinm = data.actdivinm || '';
    this.divinm = data.divinm || '';
    this.equpnm = data.equpnm || '';
    this.recetime = data.recetime || '';
    this.actpernm = data.actpernm || '';
    this.equpnm = data.equpnm || '';
    this.contnm = data.contnm || '';
    this.repernm = data.repernm || '';
    this.pernm = data.pernm || '';
    this.totcompmon = data.totcompmon || '';
    this.totmon = data.totmon || '';
    this.compute_4 = data.compute_4 || '';
    this.mon01 = data.mon01 || '';
    this.compmon01 = data.compmon01 || '';
    this.compute_01 = data.compute_01 || '';
    this.mon02 = data.mon02 || '';
    this.compmon02 = data.compmon02 || '';
    this.compute_02 = data.compute_02 || '';
    this.mon03 = data.mon03 || '';
    this.compmon03 = data.compmon03 || '';
    this.compute_03 = data.compute_03 || '';
    this.mon04 = data.mon04 || '';
    this.compmon04 = data.compmon04 || '';
    this.compute_04 = data.compute_04 || '';
    this.mon05 = data.mon05 || '';
    this.compmon05 = data.compmon05 || '';
    this.compute_05 = data.compute_05 || '';
    this.mon06 = data.mon06 || '';
    this.compmon06 = data.compmon06 || '';
    this.compute_06 = data.compute_06 || '';
    this.mon07 = data.mon07 || '';
    this.compmon07 = data.compmon07 || '';
    this.compute_07 = data.compute_07 || '';
    this.mon08 = data.mon08 || '';
    this.compmon08 = data.compmon08 || '';
    this.compute_08 = data.compute_08 || '';
    this.mon09 = data.mon09 || '';
    this.compmon09 = data.compmon09 || '';
    this.compute_09 = data.compute_09 || '';
    this.mon10 = data.mon10 || '';
    this.compmon10 = data.compmon10 || '';
    this.compute_10 = data.compute_10 || '';
    this.mon11 = data.mon11 || '';
    this.compmon11 = data.compmon11 || '';
    this.compute_11 = data.compute_11 || '';
    this.mon12 = data.mon12 || '';
    this.compmon12 = data.compmon12 || '';
    this.compute_12 = data.compute_12 || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
