import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Worker } from '../Worker';
import { WorkerTabPaymentsGridHeader } from '../headers/WorkerTabPaymentsGridHeader';

/**
 * 화면 > 탭 - 지급현황
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTabPaymentsTemplate: React.FC<TemplateProps<Worker>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <GridLayout
        header={WorkerTabPaymentsGridHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataPayments}
      />
    </FlexLayout>
  );
};
