import {
  AiOutlineDislike,
  AiOutlineLike,
} from 'react-icons/all';
import { GLHF } from '../../../../../constants';
import {
  Button,
  FlexLayout,
} from '../../../../../components';
import { Format } from '../../../../../utils/string';
import Sum from '../../../../../utils/array/Sum';
import { MonthResult } from '../MonthResult';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';
import { IndividualModel } from '../models/Individual.model';

/**
 * 화면 > 탭  > 그리드
 * @window w_tb_monend_incentive
 * @category 월실적
 */
export const IndividualGrid: GLHF<IndividualModel, MonthResult> = (scope) => ([
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 80,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.cnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: x.rtclafi !== '001' ? '#997000' : '' }}
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'dayterm2',
    text: '근속',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.dayterm2)}
      </FlexLayout>
    ),
  },
  {
    id: 'totyul',
    text: '적용',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.totyul)}
      </FlexLayout>
    ),
  },
  {
    id: 'totamt2',
    text: '총실적점수',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.useamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#A0BAED',
          color: x.rtclafi !== '001' ? '#997000' : 'var(--color-blue)',
        }}
      >
        {Format.number(x.totamt2)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty01',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          전기식
        </FlexLayout>

        <FlexLayout>
          인승
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty01 = Sum.byGroup(data, 'qty01', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty01)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty01_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty01', 'retrieve')}
      >
        {Format.number(x.qty01)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt01',
    text: (<FlexLayout>{scope?.state.t_amt01}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt01 = Sum.byGroup(data, 'planamt01', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt01)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt01_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt01}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt01',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt01 = Sum.byGroup(data, 'totplancnt01', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt01)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt01_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt01', 'retrieve')}
      >
        {Format.number(x.totplancnt01)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt01',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt01 = Sum.byGroup(data, 'jrececnt01', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt01)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt01_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt01', 'retrieve')}
      >
        {Format.number(x.jrececnt01)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt01',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt01 = Sum.byGroup(data, 'jcompcnt01', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt01)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt01_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt01', 'retrieve')}
      >
        {Format.number(x.jcompcnt01)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty01_2',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          전기식화물
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty01_2 = Sum.byGroup(data, 'qty01_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty01_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty01_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty01_2', 'retrieve')}
      >
        {Format.number(x.qty01_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt01_2',
    text: (<FlexLayout>{scope?.state.t_amt01_2}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt01_2 = Sum.byGroup(data, 'planamt01_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt01_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt01_2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt01_2}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt01_2',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt01_2 = Sum.byGroup(data, 'totplancnt01_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt01_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt01_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt01_2', 'retrieve')}
      >
        {Format.number(x.totplancnt01_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt01_2',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt01_2 = Sum.byGroup(data, 'jrececnt01_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt01_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt01_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt01_2', 'retrieve')}
      >
        {Format.number(x.jrececnt01_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt01_2',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt01_2 = Sum.byGroup(data, 'jcompcnt01_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt01_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt01_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt01_2', 'retrieve')}
      >
        {Format.number(x.jcompcnt01_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty02',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          유압식인승
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty02 = Sum.byGroup(data, 'qty02', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty02)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty02_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty02', 'retrieve')}
      >
        {Format.number(x.qty02)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt02',
    text: (<FlexLayout>{scope?.state.t_amt02}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt02 = Sum.byGroup(data, 'planamt02', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt02)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt02_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt02}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt02',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt02 = Sum.byGroup(data, 'totplancnt02', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt02)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt02_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt02', 'retrieve')}
      >
        {Format.number(x.totplancnt02)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt02',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt02 = Sum.byGroup(data, 'jrececnt02', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt02)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt02_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt02', 'retrieve')}
      >
        {Format.number(x.jrececnt02)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt02',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt02 = Sum.byGroup(data, 'jcompcnt02', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt02)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt02_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt02', 'retrieve')}
      >
        {Format.number(x.jcompcnt02)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty02_2',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          유압식화물
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty02_2 = Sum.byGroup(data, 'qty02_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty02_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty02_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty02_2', 'retrieve')}
      >
        {Format.number(x.qty02_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt02_2',
    text: (<FlexLayout>{scope?.state.t_amt02_2}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt02_2 = Sum.byGroup(data, 'planamt02_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt02_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt02_2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt02_2}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt02_2',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt02_2 = Sum.byGroup(data, 'totplancnt02_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt02_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt02_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt02_2', 'retrieve')}
      >
        {Format.number(x.totplancnt02_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt02_2',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt02_2 = Sum.byGroup(data, 'jrececnt02_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt02_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt02_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt02_2', 'retrieve')}
      >
        {Format.number(x.jrececnt02_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt02_2',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt02_2 = Sum.byGroup(data, 'jcompcnt02_2', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt02_2)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt02_2_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt02_2', 'retrieve')}
      >
        {Format.number(x.jcompcnt02_2)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty03',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          에스컬레이터
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    sum: (x, data) => {
      const sumQty03 = Sum.byGroup(data, 'qty03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty03', 'retrieve')}
      >
        {Format.number(x.qty03)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt03',
    text: (<FlexLayout>{scope?.state.t_amt03}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt03 = Sum.byGroup(data, 'planamt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt03_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt03}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt03',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt03 = Sum.byGroup(data, 'totplancnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt03', 'retrieve')}
      >
        {Format.number(x.totplancnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt03',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt03 = Sum.byGroup(data, 'jrececnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt03', 'retrieve')}
      >
        {Format.number(x.jrececnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt03',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt03 = Sum.byGroup(data, 'jcompcnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt03', 'retrieve')}
      >
        {Format.number(x.jcompcnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty04',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          덤웨이터
        </FlexLayout>

        <FlexLayout>
          (주차기)
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty04 = Sum.byGroup(data, 'qty04', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty04)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty04_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty04', 'retrieve')}
      >
        {Format.number(x.qty04)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt04',
    text: (<FlexLayout>{scope?.state.t_amt04}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt04 = Sum.byGroup(data, 'planamt04', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt04)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt04_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt04}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt04',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt04 = Sum.byGroup(data, 'totplancnt04', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt04)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt04_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt04', 'retrieve')}
      >
        {Format.number(x.totplancnt04)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt04',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt04 = Sum.byGroup(data, 'jrececnt04', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt04)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt04_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt04', 'retrieve')}
      >
        {Format.number(x.jrececnt04)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt04',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt04 = Sum.byGroup(data, 'jcompcnt04', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt04)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt04_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt04', 'retrieve')}
      >
        {Format.number(x.jcompcnt04)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty05',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          휠체어
        </FlexLayout>

        <FlexLayout>
          리프트
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty05 = Sum.byGroup(data, 'qty05', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty05)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty05_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty05', 'retrieve')}
      >
        {Format.number(x.qty05)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt05',
    text: (<FlexLayout>{scope?.state.t_amt05}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt05 = Sum.byGroup(data, 'planamt05', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt05)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt05_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt05}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt05',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt05 = Sum.byGroup(data, 'totplancnt05', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt05)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt05_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt05', 'retrieve')}
      >
        {Format.number(x.totplancnt05)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt05',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt05 = Sum.byGroup(data, 'jrececnt05', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt05)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt05_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt05', 'retrieve')}
      >
        {Format.number(x.jrececnt05)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt05',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt05 = Sum.byGroup(data, 'jcompcnt05', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt05)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt05_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt05', 'retrieve')}
      >
        {Format.number(x.jcompcnt05)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty10',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          주차설비
        </FlexLayout>

        <FlexLayout>
          작업용
        </FlexLayout>

        <FlexLayout>
          관리대수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumQty10 = Sum.byGroup(data, 'qty10', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty10)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.qty10_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_qty10', 'retrieve')}
      >
        {Format.number(x.qty10)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt10',
    text: (<FlexLayout>{scope?.state.t_amt10}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlanamt10 = Sum.byGroup(data, 'planamt10', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamt10)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamt10_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.planamt10}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnt10',
    text: '점검한대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnt10 = Sum.byGroup(data, 'totplancnt10', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnt10)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnt10_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_totplancnt10', 'retrieve')}
      >
        {Format.number(x.totplancnt10)}
      </FlexLayout>
    ),
  },
  {
    id: 'jrececnt10',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          지원받은
        </FlexLayout>

        <FlexLayout>
          점검
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumJrececnt10 = Sum.byGroup(data, 'jrececnt10', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJrececnt10)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jrececnt10_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jrececnt10', 'retrieve')}
      >
        {Format.number(x.jrececnt10)}
      </FlexLayout>
    ),
  },
  {
    id: 'jcompcnt10',
    text: '지원한점검',
    width: 70,
    sum: (x, data) => {
      const sumJcompcnt10 = Sum.byGroup(data, 'jcompcnt10', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumJcompcnt10)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.jcompcnt10_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e035_jcompcnt10', 'retrieve')}
      >
        {Format.number(x.jcompcnt10)}
      </FlexLayout>
    ),
  },
  {
    id: 'totqty',
    text: '총관리대수',
    width: 70,
    sum: (x, data) => {
      const sumTotqty = Sum.byGroup(data, 'totqty', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotqty)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totqty_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.totqty)}
      </FlexLayout>
    ),
  },
  {
    id: 'totplancnttot',
    text: '총점검대수',
    width: 70,
    sum: (x, data) => {
      const sumTotplancnttot = Sum.byGroup(data, 'totplancnttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplancnttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.totplancnttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.totplancnttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'planamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          점검실적
        </FlexLayout>

        <FlexLayout>
          점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumPlanamttot = Sum.byGroup(data, 'planamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlanamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.planamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ backgroundColor: '#FAFA80' }}
      >
        {Format.number(x.planamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'rececnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          담당고장
        </FlexLayout>

        <FlexLayout>
          건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumRececnt = Sum.byGroup(data, 'rececnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumRececnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rececnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_rececnt', 'retrieve2')}
      >
        {Format.number(x.rececnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'receamt',
    text: (<FlexLayout>{scope?.state.t_receamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumReceamt = Sum.byGroup(data, 'receamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumReceamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.receamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.receamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'wadcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          다발고장
        </FlexLayout>

        <FlexLayout>
          건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumWadcnt = Sum.byGroup(data, 'wadcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumWadcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.wadcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_wadcnt', 'retrieve2')}
      >
        {Format.number(x.wadcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'wadamt',
    text: (<FlexLayout>{scope?.state.t_wadamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumWadamt = Sum.byGroup(data, 'wadamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumWadamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.wadamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.wadamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rececnt_ex',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          담당
        </FlexLayout>

        <FlexLayout>
          고장건수
        </FlexLayout>

        <FlexLayout>
          (제외)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumRececnt_ex = Sum.byGroup(data, 'rececnt_ex', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumRececnt_ex)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rececnt_ex_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_rececnt_ex', 'retrieve2')}
      >
        {Format.number(x.rececnt_ex)}
      </FlexLayout>
    ),
  },
  {
    id: 'wadcnt_ex',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          다발고장
        </FlexLayout>

        <FlexLayout>
          (제외)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumWadcnt_ex = Sum.byGroup(data, 'wadcnt_ex', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumWadcnt_ex)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.wadcnt_ex_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_wadcnt_ex', 'retrieve2')}
      >
        {Format.number(x.wadcnt_ex)}
      </FlexLayout>
    ),
  },
  {
    id: 'rececompcnt',
    text: '고장처리',
    width: 70,
    sum: (x, data) => {
      const sumRececompcnt = Sum.byGroup(data, 'rececompcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumRececompcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rececompcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_rececompcnt', 'retrieve2')}
      >
        {Format.number(x.rececompcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rececompamt',
    text: (<FlexLayout>{scope?.state.t_rececompamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumRececompamt = Sum.byGroup(data, 'rececompamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumRececompamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rececompamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.rececompamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'receamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          고장실적
        </FlexLayout>

        <FlexLayout>
          점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumReceamttot = Sum.byGroup(data, 'receamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumReceamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.receamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          color: x.receamttot > '0' ? 'var(--color-blue)' : 'var(--color-red)',
          backgroundColor: '#FAFA80',
        }}
      >
        {Format.number(x.receamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'trouble',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          고장처리
        </FlexLayout>

        <FlexLayout>
          (사람갇힘)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumTrouble = Sum.byGroup(data, 'trouble', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTrouble)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.trouble_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_trouble', 'retrieve2')}
      >
        {Format.number(x.trouble)}
      </FlexLayout>
    ),
  },
  {
    id: 'trouble_c',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          배정거절
        </FlexLayout>

        <FlexLayout>
          (사람갇힘)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumTrouble_c = Sum.byGroup(data, 'trouble_c', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTrouble_c)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.trouble_c_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e401_trouble_c', 'retrieve2')}
      >
        {Format.number(x.trouble_c)}
      </FlexLayout>
    ),
  },
  {
    id: 'troubletot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          사람갇힘
        </FlexLayout>

        <FlexLayout>
          합계
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumTroubletot = Sum.byGroup(data, 'troubletot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTroubletot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.troubletot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.troubletot)}
      </FlexLayout>
    ),
  },
  {
    id: 'troubleamtexectot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          긴급출동
        </FlexLayout>

        <FlexLayout>
          실적점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumTroubleamtexectot = Sum.byGroup(data, 'troubleamtexectot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTroubleamtexectot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.troubleamtexectot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: x.troubleamtexectot > '0' ? 'var(--color-blue)' : 'var(--color-red)',
        }}
      >
        {Format.number(x.troubleamtexectot)}
      </FlexLayout>
    ),
  },
  {
    id: 'minwoncnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          접수건수
        </FlexLayout>

        <FlexLayout>
          1.0점
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumMinwoncnt = Sum.byGroup(data, 'minwoncnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMinwoncnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.minwoncnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e301_minwoncnt', 'retrieve2')}
      >
        {Format.number(x.minwoncnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'minwonnotcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          미처리건수
        </FlexLayout>

        <FlexLayout>
          2.0점
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumMinwonnotcnt = Sum.byGroup(data, 'minwonnotcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMinwonnotcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.minwonnotcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e301_minwonnotcnt', 'retrieve2')}
      >
        {Format.number(x.minwonnotcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'minwoncompcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          처리건수
        </FlexLayout>

        <FlexLayout>
          5.0점
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumMinwoncompcnt = Sum.byGroup(data, 'minwoncompcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMinwoncompcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.minwoncompcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e301_minwoncompcnt', 'retrieve2')}
      >
        {Format.number(x.minwoncompcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'minwonamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          민원처리
        </FlexLayout>

        <FlexLayout>
          실적점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumMinwonamttot = Sum.byGroup(data, 'minwonamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMinwonamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.minwonamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: x.minwonamttot > '0' ? 'var(--color-blue)' : 'var(--color-red)',
        }}
      >
        {Format.number(x.minwonamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'kc001cnt',
    text: '합격',
    width: 70,
    sum: (x, data) => {
      const sumKc001cnt = Sum.byGroup(data, 'kc001cnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKc001cnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kc001cnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e073_kc001cnt', 'retrieve2')}
      >
        {Format.number(x.kc001cnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'kc001amt',
    text: (<FlexLayout>{scope?.state.t_kc001amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumKc001amt = Sum.byGroup(data, 'kc001amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKc001amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kc001amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.kc001amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'kc002cnt',
    text: '조건부',
    width: 70,
    sum: (x, data) => {
      const sumKc002cnt = Sum.byGroup(data, 'kc002cnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKc002cnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kc002cnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e073_kc002cnt', 'retrieve2')}
      >
        {Format.number(x.kc002cnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'kc002amt',
    text: (<FlexLayout>{scope?.state.t_kc002amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumKc002amt = Sum.byGroup(data, 'kc002amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKc002amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kc002amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.kc002amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'kc003cnt',
    text: '불합격',
    width: 70,
    sum: (x, data) => {
      const sumKc003cnt = Sum.byGroup(data, 'kc003cnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKc003cnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kc003cnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e073_kc003cnt', 'retrieve2')}
      >
        {Format.number(x.kc003cnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'kc003amt',
    text: (<FlexLayout>{scope?.state.t_kc003amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumKc003amt = Sum.byGroup(data, 'kc003amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKc003amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kc003amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.kc003amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'kcamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          검사실적
        </FlexLayout>

        <FlexLayout>
          점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumKcamttot = Sum.byGroup(data, 'kcamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumKcamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.kcamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: x.kcamttot > '0' ? 'var(--color-blue)' : 'var(--color-red)',
        }}
      >
        {Format.number(x.kcamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'vlongcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          장기(10년)
        </FlexLayout>

        <FlexLayout>
          신규계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumVlongcnt = Sum.byGroup(data, 'vlongcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumVlongcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.vlongcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_vlongcnt', 'retrieve3')}
      >
        {Format.number(x.vlongcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'vlongamt',
    text: (<FlexLayout>{scope?.state.t_vlongamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumVlongamt = Sum.byGroup(data, 'vlongamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumVlongamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.vlongamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.vlongamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'longcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          다년(5년)
        </FlexLayout>

        <FlexLayout>
          신규계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumLongcnt = Sum.byGroup(data, 'longcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumLongcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.longcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_longcnt', 'retrieve3')}
      >
        {Format.number(x.longcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'longamt',
    text: (<FlexLayout>{scope?.state.t_longamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumLongamt = Sum.byGroup(data, 'longamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumLongamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.longamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.longamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'fmcnt',
    text: 'FM신규계약',
    width: 70,
    sum: (x, data) => {
      const sumFmcnt = Sum.byGroup(data, 'fmcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumFmcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.fmcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_fmcnt', 'retrieve3')}
      >
        {Format.number(x.fmcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'fmamt',
    text: (<FlexLayout>{scope?.state.t_fmamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumFmamt = Sum.byGroup(data, 'fmamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumFmamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.fmamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.fmamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'pogcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          일반신규
        </FlexLayout>

        <FlexLayout>
          계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumPogcnt = Sum.byGroup(data, 'pogcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPogcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.pogcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_pogcnt', 'retrieve3')}
      >
        {Format.number(x.pogcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'pogamt',
    text: (<FlexLayout>{scope?.state.t_pogamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPogamt = Sum.byGroup(data, 'pogamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPogamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.pogamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.pogamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mucnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          무상신규
        </FlexLayout>

        <FlexLayout>
          계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumMucnt = Sum.byGroup(data, 'mucnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMucnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mucnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_mucnt', 'retrieve3')}
      >
        {Format.number(x.mucnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'muamt',
    text: (<FlexLayout>{scope?.state.t_muamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumMuamt = Sum.byGroup(data, 'muamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMuamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.muamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.muamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'sccnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          설치신규
        </FlexLayout>

        <FlexLayout>
          계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumSccnt = Sum.byGroup(data, 'sccnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSccnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.sccnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_sccnt', 'retrieve3')}
      >
        {Format.number(x.sccnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'scamt',
    text: (<FlexLayout>{scope?.state.t_scamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumScamt = Sum.byGroup(data, 'scamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumScamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.scamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.scamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'vlongcnt03',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          장기(10년)
        </FlexLayout>

        <FlexLayout>
          재계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumVlongcnt03 = Sum.byGroup(data, 'vlongcnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumVlongcnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.vlongcnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_vlongcnt03', 'retrieve3')}
      >
        {Format.number(x.vlongcnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'vlong03amt',
    text: (<FlexLayout>{scope?.state.t_vlong03amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumVlong03amt = Sum.byGroup(data, 'vlong03amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumVlong03amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.vlong03amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.vlong03amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'longcnt03',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          다년(5년)
        </FlexLayout>

        <FlexLayout>
          재계약
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumLongcnt03 = Sum.byGroup(data, 'longcnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumLongcnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.longcnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_longcnt03', 'retrieve3')}
      >
        {Format.number(x.longcnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'long03amt',
    text: (<FlexLayout>{scope?.state.t_long03amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumLong03amt = Sum.byGroup(data, 'long03amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumLong03amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.long03amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.long03amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'fmcnt03',
    text: 'FM재계약',
    width: 70,
    sum: (x, data) => {
      const sumFmcnt03 = Sum.byGroup(data, 'fmcnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumFmcnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.fmcnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_fmcnt03', 'retrieve3')}
      >
        {Format.number(x.fmcnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'fm03amt',
    text: (<FlexLayout>{scope?.state.t_fm03amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumFm03amt = Sum.byGroup(data, 'fm03amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumFm03amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.fm03amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.fm03amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'pogcnt03',
    text: '익월재계약',
    width: 70,
    sum: (x, data) => {
      const sumPogcnt03 = Sum.byGroup(data, 'pogcnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPogcnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.pogcnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_pogcnt03', 'retrieve3')}
      >
        {Format.number(x.pogcnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'pog03amt',
    text: (<FlexLayout>{scope?.state.t_pog03amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPog03amt = Sum.byGroup(data, 'pog03amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPog03amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.pog03amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.pog03amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'pluscnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          계약금액
        </FlexLayout>

        <FlexLayout>
          인상건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumPluscnt = Sum.byGroup(data, 'pluscnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPluscnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.pluscnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_pluscnt', 'retrieve3')}
      >
        {Format.number(x.pluscnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'plusamt',
    text: (<FlexLayout>{scope?.state.t_plusamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumPlusamt = Sum.byGroup(data, 'plusamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlusamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.plusamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.plusamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'yucnt03',
    text: '무->유상 재계약',
    width: 100,
    sum: (x, data) => {
      const sumYucnt03 = Sum.byGroup(data, 'yucnt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumYucnt03)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.yucnt03_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_yucnt03', 'retrieve3')}
      >
        {Format.number(x.yucnt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'yu03amt',
    text: (<FlexLayout>{scope?.state.t_yu03amtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumYu03amt = Sum.byGroup(data, 'yu03amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumYu03amt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.yu03amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.yu03amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'clscnt',
    text: '해약/중지',
    width: 70,
    sum: (x, data) => {
      const sumClscnt = Sum.byGroup(data, 'clscnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumClscnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.clscnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_clscnt', 'retrieve3')}
      >
        {Format.number(x.clscnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'clsamt',
    text: (<FlexLayout>{scope?.state.t_clsamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumClsamt = Sum.byGroup(data, 'clsamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumClsamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.clsamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.clsamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'eleccnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          전자계약서
        </FlexLayout>

        <FlexLayout>
          건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumEleccnt = Sum.byGroup(data, 'eleccnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEleccnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.eleccnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e101_eleccnt', 'retrieve3')}
      >
        {Format.number(x.eleccnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'elecamt',
    text: (<FlexLayout>{scope?.state.t_elecamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumElecamt = Sum.byGroup(data, 'elecamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumElecamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.elecamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.elecamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'contamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          계약실적
        </FlexLayout>

        <FlexLayout>
          점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumContamttot = Sum.byGroup(data, 'contamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumContamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.contamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: x.contamttot > '0' ? 'var(--color-blue)' : 'var(--color-red)',
        }}
      >
        {Format.number(x.contamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'businesscnt',
    text: '영업건수',
    width: 70,
    sum: (x, data) => {
      const sumBusinesscnt = Sum.byGroup(data, 'businesscnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBusinesscnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.businesscnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'b501_businesscnt', 'retrieve3')}
      >
        {Format.number(x.businesscnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'businessamt',
    text: (<FlexLayout>{scope?.state.t_businessexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumBusinessamt = Sum.byGroup(data, 'businessamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBusinessamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.businessamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.businessamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'costcnt',
    text: '견적건수',
    width: 70,
    sum: (x, data) => {
      const sumCostcnt = Sum.byGroup(data, 'costcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCostcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.costcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e451_costcnt', 'retrieve3')}
      >
        {Format.number(x.costcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt',
    text: (<FlexLayout>{scope?.state.t_costexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumCostamt = Sum.byGroup(data, 'costamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCostamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.costamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.costamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'compamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          공사, 부품금액
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          (공사[수주]기준)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 120,
    sum: (x, data) => {
      const sumCompamt = Sum.byGroup(data, 'compamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCompamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.compamt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e471-misuse_compamt', 'retrieve3')}
      >
        {Format.number(x.compamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'comp_iamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          공사, 부품이익금액
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          (공사[수주]기준)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 120,
    sum: (x, data) => {
      const sumComp_iamt = Sum.byGroup(data, 'comp_iamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumComp_iamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.comp_iamt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e471-misuse_compamt', 'retrieve3')}
      >
        {Format.number(x.comp_iamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'comp_uamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          공사, 부품이익금액-처리
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          (확정/적용된금액)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 140,
    sum: (x, data) => {
      const sumComp_uamt = Sum.byGroup(data, 'comp_uamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumComp_uamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.comp_uamt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e471-misuse_compamt', 'retrieve3')}
      >
        {Format.number(x.comp_uamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'comp_iamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          공사, 부품
        </FlexLayout>

        <FlexLayout>
          처리실적점수 {scope.state.t_compyul}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 140,
    sum: (x, data) => {
      const sumComp_iamttot = Sum.byGroup(data, 'comp_iamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumComp_iamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.comp_iamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.comp_iamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'comp_contamt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          공사, 부품 이익금액-계약
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          (확정/적용된 금액)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 140,
    sum: (x, data) => {
      const sumComp_contamt = Sum.byGroup(data, 'comp_contamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumComp_contamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.comp_contamt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e471-contuse_compamt', 'retrieve3')}
      >
        {Format.number(x.comp_contamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'comp_contamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          공사, 부품
        </FlexLayout>

        <FlexLayout>
          계약실적점수 {scope.state.t_comp_contyul}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 140,
    sum: (x, data) => {
      const sumComp_contamttot = Sum.byGroup(data, 'comp_contamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumComp_contamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.comp_contamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.comp_contamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'outnotcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          시내출장
        </FlexLayout>

        <FlexLayout>
          건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumOutnotcnt = Sum.byGroup(data, 'outnotcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumOutnotcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.outnotcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb401_out', 'retrieve3')}
      >
        {Format.number(x.outnotcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'outdatenotcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          시내출장일수
        </FlexLayout>

        <FlexLayout>
          {scope.state.t_outnotamtexec}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    sum: (x, data) => {
      const sumOutdatenotcnt = Sum.byGroup(data, 'outdatenotcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumOutdatenotcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.outdatenotcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb401_out', 'retrieve3')}
      >
        {Format.number(x.outdatenotcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'outcnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          시외출장
        </FlexLayout>

        <FlexLayout>
          건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumOutcnt = Sum.byGroup(data, 'outcnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumOutcnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.outcnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb401_out2', 'retrieve3')}
      >
        {Format.number(x.outcnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'outdatecnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          시외출장일수
        </FlexLayout>

        <FlexLayout>
          {scope.state.t_outamtexec}
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    sum: (x, data) => {
      const sumOutdatecnt = Sum.byGroup(data, 'outdatecnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumOutdatecnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.outdatecnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb401_out2', 'retrieve3')}
      >
        {Format.number(x.outdatecnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'outamttot',
    text: '출장점수',
    width: 70,
    sum: (x, data) => {
      const sumOutamttot = Sum.byGroup(data, 'outamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumOutamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.outamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.outamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'propose',
    text: '개선제안',
    width: 70,
    sum: (x, data) => {
      const sumPropose = Sum.byGroup(data, 'propose', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPropose)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.propose_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb403_propose', 'retrieve3')}
      >
        {Format.number(x.propose)}
      </FlexLayout>
    ),
  },
  {
    id: 'proposetot',
    text: (<FlexLayout>{scope?.state.t_proposeexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumProposetot = Sum.byGroup(data, 'proposetot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumProposetot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.proposetot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.proposetot)}
      </FlexLayout>
    ),
  },
  {
    id: 'educnt',
    text: '교육한건수',
    width: 70,
    sum: (x, data) => {
      const sumEducnt = Sum.byGroup(data, 'educnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEducnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.educnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb404_educnt', 'retrieve3')}
      >
        {Format.number(x.educnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'eduamt',
    text: (<FlexLayout>{scope?.state.t_eduamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumEduamt = Sum.byGroup(data, 'eduamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEduamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.eduamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.eduamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'edurececnt',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          교육받은
        </FlexLayout>

        <FlexLayout>
          건수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumEdurececnt = Sum.byGroup(data, 'edurececnt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEdurececnt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.edurececnt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'pb404_edurececnt', 'retrieve3')}
      >
        {Format.number(x.edurececnt)}
      </FlexLayout>
    ),
  },
  {
    id: 'edureceamt',
    text: (<FlexLayout>{scope?.state.t_edureceamtexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumEdureceamt = Sum.byGroup(data, 'edureceamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEdureceamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.edureceamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.edureceamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'eduamttot',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          교육실적
        </FlexLayout>

        <FlexLayout>
          점수
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumEduamttot = Sum.byGroup(data, 'eduamttot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumEduamttot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.eduamttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.eduamttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'e037not',
    text: '미작성',
    width: 70,
    color: 'var(--color-red)',
    sum: (x, data) => {
      const sumE037not = Sum.byGroup(data, 'e037not', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumE037not)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.e037not_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'e037not', 'retrieve3')}
      >
        {Format.number(x.e037not)}
      </FlexLayout>
    ),
  },
  {
    id: 'e037tot',
    text: (<FlexLayout>{scope?.state.t_e037notexec}</FlexLayout>),
    width: 70,
    sum: (x, data) => {
      const sumE037tot = Sum.byGroup(data, 'e037tot', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumE037tot)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.e037tot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-red)',
        }}
      >
        {Format.number(x.e037tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'talent',
    text: (
      <FlexLayout
        isVertical={true}
        align="center"
      >
        <FlexLayout>
          소유(권한)
        </FlexLayout>

        <FlexLayout>
          달란트
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    sum: (x, data) => {
      const sumTalent = Sum.byGroup(data, 'talent', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTalent)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.talent_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        {Format.number(x.talent)}
      </FlexLayout>
    ),
  },
  {
    id: 'p_like',
    text: '달란트주기',
    width: 70,
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <Button
          style={{ height: 28, marginTop: 5 }}
          onClick={() => scope?.onModalPlus(true)}
        >
          <FlexLayout>
            <AiOutlineLike/>
          </FlexLayout>
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'give_talent',
    text: '사용건수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.give_talent_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'give_talent', 'retrieve3')}
      >
        {Format.number(x.give_talent)}
      </FlexLayout>
    ),
  },
  {
    id: 'rece_talent',
    text: '받은건수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rece_talent_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'rece_talent', 'retrieve3')}
      >
        {Format.number(x.rece_talent)}
      </FlexLayout>
    ),
  },
  {
    id: 'talenttot',
    text: '받은점수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.talenttot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-blue)',
        }}
      >
        {Format.number(x.talenttot)}
      </FlexLayout>
    ),
  },
  {
    id: 'p_minus',
    text: '차감하기',
    width: 70,
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <Button
          theme={BUTTON_THEMES.RED}
          style={{ height: 28, marginTop: 5 }}
          onClick={() => scope?.onMinusBtn(true)}
        >
          <FlexLayout>
            <AiOutlineDislike/>
          </FlexLayout>
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'minus',
    text: '받은건수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.minus_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope?.onDoubleClickEvent(true, 'minus', 'retrieve3')}
      >
        {x.minus}
      </FlexLayout>
    ),
  },
  {
    id: 'minustot',
    text: '받은점수',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.minustot_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          backgroundColor: '#FAFA80',
          color: 'var(--color-red)',
        }}
      >
        {x.minustot}
      </FlexLayout>
    ),
  },
  {
    id: 'totamt',
    text: '총실적점수',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.useamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: x.rtclafi !== '001' ? '#997000' : 'var(--color-blue)' }}
      >
        {x.totamt}
      </FlexLayout>
    ),
  },
  {
    id: 'accamt',
    text: '누적점수',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.accamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ backgroundColor: '#A0BAED' }}
      >
        {x.accamt}
      </FlexLayout>
    ),
  },
  {
    id: 'rtclafiuseamt',
    text: '적용점수',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.useamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ backgroundColor: '#A0BAED' }}
      >
        {x.rtclafiuseamt}
      </FlexLayout>
    ),
  },
  {
    id: 'okflag',
    text: '본인확인함',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FlexLayout style={{ marginTop: 12 }}>
          <Button
            style={{ height: '25px', width: '40px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope?.onConfirmBtn()}
          >
            확인
          </Button>
        </FlexLayout>

        <FlexLayout style={{ color: x.okflag !== '1' ? 'var(--color-red)' : 'var(--color-blue)' }}>
          {x.okflag === '0' ? '미확인' : '확인'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
]);
