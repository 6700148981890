import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_ca606_01_2
 * @category 당직근무보고서
 */

export class WorkReportPopupModel {
  /**
   * 일자
   *
   * **data type** varchar(0)
   */
  readonly rptdate: string;

  /**
   * 일자
   *
   * **data type** varchar(0)
   */
  readonly outdate: string;


  /**
   * 일자
   *
   * **data type** varchar(0)
   */
  readonly indate: string;


  /**
   * 사원코드
   *
   * **data type** varchar(0)
   */
  readonly perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(0)
   */
  readonly pernm: string;

  /**
   * 구분
   *
   * **data type** varchar(0)
   */
  readonly flag: string;

  /**
   * 순번
   *
   * **data type** varchar(0)
   */
  readonly seq: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.rptdate = data.rptdate || '';
    this.outdate = data.outdate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.indate = data.indate || '';
    this.flag = data.flag || '1';
    this.seq = data.seq || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
