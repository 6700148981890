import { action } from 'mobx';
import React from 'react';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import { ClientMonthTemplate } from './ClientMonth.template';
import { Today } from '../../../../utils/time';
import { ClientMonthModel } from './ClientMonth.model';
import { InfinityRetrieve } from '../../../../models';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { ClientMonthLeftModel } from './ClientMonthLeft.model';
import {
  ConfirmWarning,
} from '../../../../utils/confirm';
import { GiveStateModel } from '../GiveState/GiveState.model';

interface ClientMonthState {
  // 조회조건
  year: string;
  searchQuery: string;

  // 왼쪽 FlexLayout Data
  leftData: Array<ClientMonthLeftModel>;
  allChk: boolean;

  // 리스트
  cltList: Array<ClientMonthModel>;

  // trail
  mijamt01_tot: string;
  sndamt01_tot: string;
  iamt01_tot: string;
  mijamt02_tot: string;
  sndamt02_tot: string;
  iamt02_tot: string;
  mijamt03_tot: string;
  sndamt03_tot: string;
  iamt03_tot: string;
  mijamt04_tot: string;
  sndamt04_tot: string;
  iamt04_tot: string;
  mijamt05_tot: string;
  sndamt05_tot: string;
  iamt05_tot: string;
  mijamt06_tot: string;
  sndamt06_tot: string;
  iamt06_tot: string;
  mijamt07_tot: string;
  sndamt07_tot: string;
  iamt07_tot: string;
  mijamt08_tot: string;
  sndamt08_tot: string;
  iamt08_tot: string;
  mijamt09_tot: string;
  sndamt09_tot: string;
  iamt09_tot: string;
  mijamt10_tot: string;
  sndamt10_tot: string;
  iamt10_tot: string;
  mijamt11_tot: string;
  sndamt11_tot: string;
  iamt11_tot: string;
  mijamt12_tot: string;
  sndamt12_tot: string;
  iamt12_tot: string;
  mijamt_tot_tot: string;
  sndamt_tot_tot: string;
  iamt_tot_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_17
 * @category 거래처월별 매입/지급현황
 */
export class ClientMonth extends PageComponent<PageProps, ClientMonthState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityTab1?: InfinityRetrieve;

  infinityTab2?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  gridTab1: React.RefObject<GridLayout> = React.createRef();

  gridTab2: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  tabIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = {
      // 조회 조건
      year: Today.year(),
      searchQuery: '',

      // 왼쪽 리스트
      leftData: [],
      allChk: false,

      // 리스트
      cltList: [],

      // trail
      iamt01_tot: '0',
      iamt02_tot: '0',
      iamt03_tot: '0',
      iamt04_tot: '0',
      iamt05_tot: '0',
      iamt06_tot: '0',
      iamt07_tot: '0',
      iamt08_tot: '0',
      iamt09_tot: '0',
      iamt10_tot: '0',
      iamt11_tot: '0',
      iamt12_tot: '0',
      iamt_tot_tot: '0',

      mijamt01_tot: '0',
      mijamt02_tot: '0',
      mijamt03_tot: '0',
      mijamt04_tot: '0',
      mijamt05_tot: '0',
      mijamt06_tot: '0',
      mijamt07_tot: '0',
      mijamt08_tot: '0',
      mijamt09_tot: '0',
      mijamt10_tot: '0',
      mijamt11_tot: '0',
      mijamt12_tot: '0',
      mijamt_tot_tot: '0',

      sndamt01_tot: '0',
      sndamt02_tot: '0',
      sndamt03_tot: '0',
      sndamt04_tot: '0',
      sndamt05_tot: '0',
      sndamt06_tot: '0',
      sndamt07_tot: '0',
      sndamt08_tot: '0',
      sndamt09_tot: '0',
      sndamt10_tot: '0',
      sndamt11_tot: '0',
      sndamt12_tot: '0',
      sndamt_tot_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 데이터가 없을때만 retrieve_cond 실행
    if (this.state.leftData.length === 0) {
      const data = await api.fxExec('retrieve_cond', {
        year: this.state.year,
      });

      // items가 없으면 경고 팝업창
      if (data?.items === undefined) {
        await this.SS({
          cltList: [],
          leftData: [],
        });
        ConfirmWarning.show('경고', '먼저 업종구분을 등록하세요!');
        await this.table.current?.update(true);
        return;
      }

      await this.SS({
        leftData: data?.items,
      });

      await this.table.current?.update(true);
      this.table.current?.setFocus(0);
    }

    // 체크 하나이상 했는지 확인
    let noChk = true;

    this.state.leftData.forEach((x) => {
      if (x.chk === '1') {
        noChk = false;
      }
    });

    if (noChk) {
      ConfirmWarning.show('경고', '업종을 하나이상 체크해주세요');
      return;
    }

    this.infinity = new InfinityRetrieve(
      {
        tab: `tab_${this.tabIndex + 1}`,
        year: this.state.year,
        as_nm: this.state.searchQuery,
        items: this.state.leftData?.filter((x) => x.chk === '1').map((x) => ({
          code: x.code,
          chk: x.chk,
        })),
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          cltList: [
            ...this.state.cltList,
            ...items.filter((x) => x.chk !== '1').map((x: any) => new ClientMonthModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          cltList: [],
        });
      },
    );
    await this.SS({
      cltList: [],
    });

    await this.infinity?.retrieveAll();

    await this.SS({
      iamt01_tot: this.infinity?.box?.iamt01_tot || '0',
      iamt02_tot: this.infinity?.box?.iamt02_tot || '0',
      iamt03_tot: this.infinity?.box?.iamt03_tot || '0',
      iamt04_tot: this.infinity?.box?.iamt04_tot || '0',
      iamt05_tot: this.infinity?.box?.iamt05_tot || '0',
      iamt06_tot: this.infinity?.box?.iamt06_tot || '0',
      iamt07_tot: this.infinity?.box?.iamt07_tot || '0',
      iamt08_tot: this.infinity?.box?.iamt08_tot || '0',
      iamt09_tot: this.infinity?.box?.iamt09_tot || '0',
      iamt10_tot: this.infinity?.box?.iamt10_tot || '0',
      iamt11_tot: this.infinity?.box?.iamt11_tot || '0',
      iamt12_tot: this.infinity?.box?.iamt12_tot || '0',
      iamt_tot_tot: this.infinity?.box?.iamt_tot_tot || '0',

      mijamt01_tot: this.infinity?.box?.mijamt01_tot || '0',
      mijamt02_tot: this.infinity?.box?.mijamt02_tot || '0',
      mijamt03_tot: this.infinity?.box?.mijamt03_tot || '0',
      mijamt04_tot: this.infinity?.box?.mijamt04_tot || '0',
      mijamt05_tot: this.infinity?.box?.mijamt05_tot || '0',
      mijamt06_tot: this.infinity?.box?.mijamt06_tot || '0',
      mijamt07_tot: this.infinity?.box?.mijamt07_tot || '0',
      mijamt08_tot: this.infinity?.box?.mijamt08_tot || '0',
      mijamt09_tot: this.infinity?.box?.mijamt09_tot || '0',
      mijamt10_tot: this.infinity?.box?.mijamt10_tot || '0',
      mijamt11_tot: this.infinity?.box?.mijamt11_tot || '0',
      mijamt12_tot: this.infinity?.box?.mijamt12_tot || '0',
      mijamt_tot_tot: this.infinity?.box?.mijamt_tot_tot || '0',

      sndamt01_tot: this.infinity?.box?.sndamt01_tot || '0',
      sndamt02_tot: this.infinity?.box?.sndamt02_tot || '0',
      sndamt03_tot: this.infinity?.box?.sndamt03_tot || '0',
      sndamt04_tot: this.infinity?.box?.sndamt04_tot || '0',
      sndamt05_tot: this.infinity?.box?.sndamt05_tot || '0',
      sndamt06_tot: this.infinity?.box?.sndamt06_tot || '0',
      sndamt07_tot: this.infinity?.box?.sndamt07_tot || '0',
      sndamt08_tot: this.infinity?.box?.sndamt08_tot || '0',
      sndamt09_tot: this.infinity?.box?.sndamt09_tot || '0',
      sndamt10_tot: this.infinity?.box?.sndamt10_tot || '0',
      sndamt11_tot: this.infinity?.box?.sndamt11_tot || '0',
      sndamt12_tot: this.infinity?.box?.sndamt12_tot || '0',
      sndamt_tot_tot: this.infinity?.box?.sndamt_tot_tot || '0',
    });

    if (this.state.cltList && this.state.cltList?.length > 0) {
      this.grid.current?.setFocus(0);
    }
  }

  @action
  onCheckAll(checked: boolean) {
    this.SS({
      allChk: checked,
      leftData: this.state.leftData?.map((x) => new ClientMonthLeftModel({
        ...x,
        chk: checked ? '1' : '0',
      })),
    });
    this.table.current?.update(false);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.cltList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      as_nm: this.state.searchQuery,
      items: this.state.leftData?.filter((x) => x.chk === '1').map((x) => ({
        code: x.code,
        chk: x.chk,
      })),
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.cltList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    ConfirmWarning.show('경고', '거래처월별 매입지금현황 엑셀전환은 미지급액만 전환됩니다');

    await api.excel({
      year: this.state.year,
      as_nm: this.state.searchQuery,
      items: this.state.leftData?.filter((x) => x.chk === '1').map((x) => ({
        code: x.code,
        chk: x.chk,
      })),
    });
  }

  @action
  onUpdatedRows(rows: Array<GiveStateModel>) {
    this.setState({ leftData: rows });
  }

  @action
  async onTabChange(index: number) {
    this.tabIndex = index;
    await this.onRetrieveEvent();
  }

  render() {
    return (
      <ClientMonthTemplate
        scope={this}
      >
      </ClientMonthTemplate>
    );
  }
}
