import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import { Global, TemplateProps } from '../../../../constants';
import { YearInsuranceRatesRegister } from './YearInsuranceRatesRegister';
import {
  DateTextBox,
  FlexLayout,
  LabelBox,
  SearchLayout,
  TextBox,
} from '../../../../components';

/**
 *
 * 화면
 * @window w_tb_pb300_insu
 * @category 년공제보험요율등록.
 */
export const YearInsuranceRatesRegisterTemplate: React.FC<TemplateProps<YearInsuranceRatesRegister>> = ({
  scope,
  update,
}) => {
  const model = scope.state.data;
  const setData = update!;
  const setData2 = (data: any, callback?: () => void) => scope?.setState(data, callback);
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => setData2({ year: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  setData2({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
          )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  setData2({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
          )}
            isDisabledTrackingStateChange={true}
          >
          </DateTextBox>
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="고용보험">
            <TextBox
              value={model?.insu_unem_def}
              textAlign="center"
              trail=" %"
              onChange={(value) => setData({ insu_unem_def: value })}
            />
          </LabelBox>
          <LabelBox title="건강보험">
            <TextBox
              value={model?.insu_health_def}
              textAlign="center"
              trail=" %"
              onChange={(value) => setData({ insu_health_def: value })}
            />
          </LabelBox>
          <LabelBox title="장기요양보험료">
            <TextBox
              value={model?.insu_longterm_def}
              textAlign="center"
              trail=" %"
              onChange={(value) => setData({ insu_longterm_def: value })}
            />
          </LabelBox>
          <LabelBox title="국민연금보험료">
            <TextBox
              value={model?.insu_national_def}
              textAlign="center"
              trail=" %"
              onChange={(value) => setData({ insu_national_def: value })}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
