import * as React from 'react';
import { RefObject } from 'react';
import { inject } from 'mobx-react';
import { GrDrag, MdChevronLeft, MdChevronRight } from 'react-icons/all';
import update from 'react-addons-update';
import { Mutex } from 'async-mutex';
import {
  Menu,
  Item,
  useContextMenu,
  ItemParams,
} from 'react-contexify';
import { action, computed } from 'mobx';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { FlexLayout } from '../FlexLayout';
import { DragAndDropLayoutProps, TableLayoutHeader } from '../../../constants';
import style from './DragAndDropLayout.module.scss';
import { Format, JoinClassName, Sha256 } from '../../../utils/string';
import { ConfirmWarning } from '../../../utils/confirm';
import 'react-contexify/dist/ReactContexify.css';

enum MENU_ACTION {
  SORT_ASC,
  SORT_DESC,
  LOCK,
  UNLOCK,
}

interface DragAndDropLayoutState {
  data: Array<any>;
  totalWidth?: number;
  focusedRow?: number;
  railThumbWidth: number;
  isHorizontalMouseActive: boolean;
  isListHorizontalScroll: boolean;
}

@inject('publicStore')
export class DragAndDropLayout extends React.Component<
  DragAndDropLayoutProps,
  DragAndDropLayoutState
> {
  gridClientHeight: number;

  pageHeight: number;

  totalHeight: number;

  totalWidth: number;

  perPxWidth: number;

  listScrollMutex: Mutex;

  virtualInnerHeight: number;

  littleWidth: number;

  scrollHeader: RefObject<HTMLDivElement>;

  scrollBody: RefObject<HTMLDivElement>;

  scrollHorizontalRail: RefObject<HTMLDivElement>;

  scrollHorizontalRailThumb: RefObject<HTMLDivElement>;

  /**
   * 하단 스크롤바 위에서 움직이는 녀석의 현재 위치
   */
  scrollHorizontalRailThumbX: number;

  /**
   * 하단 스크롤바의 최대 이동 범위
   */
  maxScrollHorizontalRailThumbX: number;

  /**
   * 하단 스크롤바가 1px 움직일때 본문의 X축이 얼만큼 움직일지
   */
  perPxScrollHorizontalRailThumbX1px: number;

  /**
   * props.data 중 변경된 행의 인덱스 모음
   */
  changedIndexes: Array<number>;

  dataRefs: Array<Array<RefObject<any>>>;

  lastFocusedTime: number;

  newRowCount: number;

  triggeredOnBottom: boolean;

  urlid?: string;

  constructor(props: DragAndDropLayoutProps, context?: any) {
    super(props, context);
    this.gridClientHeight = 1;
    this.pageHeight = 1;
    this.totalHeight = 1;
    this.totalWidth = 1;
    this.littleWidth = 0;
    this.perPxWidth = 1; // 화면보다 내용물이 적을 경우 100% 맞추기 위해서
    this.scrollHeader = React.createRef();
    this.scrollBody = React.createRef();
    this.scrollHorizontalRail = React.createRef();
    this.scrollHorizontalRailThumb = React.createRef();
    this.virtualInnerHeight = 0;
    this.scrollHorizontalRailThumbX = 0;
    this.maxScrollHorizontalRailThumbX = 0;
    this.perPxScrollHorizontalRailThumbX1px = 0;
    this.changedIndexes = [];
    this.dataRefs = [];
    this.listScrollMutex = new Mutex();
    this.lastFocusedTime = 0;
    this.newRowCount = 0;
    this.triggeredOnBottom = false;
    this.state = {
      data: props.data,
      railThumbWidth: 100,
      isHorizontalMouseActive: false,
      isListHorizontalScroll: false,
    };
  }

  componentDidMount(): void {
    const { publicStore: p, header } = this.props;
    this.urlid = p?.currentMenu.active.id;

    const key = Sha256.make(`${this.urlid}${JSON.stringify(header.map((x) => x.id))}`);
    const temp = p!.tempStorage[key];

    if (temp) {
      this.init(false);
      this.newRowCount = temp?.newRowCount || 0;
      this.changedIndexes = temp?.changedIndexes || [];
      if (temp?.focusedRow) {
        this.setState({ focusedRow: temp?.focusedRow });
      }
    } else {
      this.init();
    }
  }

  componentWillUnmount() {
    const { publicStore: p, header } = this.props;
    const key = Sha256.make(`${this.urlid}${JSON.stringify(header.map((x) => x.id))}`);
    p!.tempStorage[key] = {
      newRowCount: this.newRowCount,
      changedIndexes: this.changedIndexes,
      focusedRow: this.state.focusedRow,
    };

    if (p!.headerTabStore.tabs.filter((x) => x.id === this.urlid).length < 1) {
      delete p!.tempStorage[key];
    }
  }

  public update(isReset: boolean = true): Promise<void> {
    this.triggeredOnBottom = false;
    return this.init(isReset);
  }

  public resetUpdates(): void {
    this.changedIndexes = [];
  }

  public setFocus(y: number, x: number = 0): void {
    if (y < this.state.data.length) {
      this.scrollBody.current?.scrollTo(0, y * this.props.rowHeight);
      this.rowSelect(this.state.data[y], y);
    }

    try {
      if (this.dataRefs[y][x].current) {
        setTimeout(() => {
          requestAnimationFrame(() => this.dataRefs[y][x].current?.focus());
        }, 100);
      }
    } catch {
      //
    }
  }

  private init(isReset: boolean = true): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        const { rowHeight } = this.props;
        const data = this.props.data || [];
        const isListVerticalScroll = data.length * rowHeight > (this.scrollBody.current?.clientHeight || 0);

        let { totalWidth } = this.state;
        let { railThumbWidth } = this.state;

        if (isReset) {
          this.changedIndexes = [];
          this.newRowCount = 0;
          this.setState({ focusedRow: undefined });
          this.onDeltaXEvent(this.scrollHorizontalRailThumbX * -1);

          this.virtualInnerHeight = (this.scrollBody.current?.clientHeight || 0);

          // horizontal scrollbar
          this.scrollHorizontalRailThumbX = 0;
          this.maxScrollHorizontalRailThumbX = 0;
          this.perPxScrollHorizontalRailThumbX1px = 0;

          totalWidth = this.visibleHeaders.reduce(
            (p, c) => p + (c?.width || 100), 0,
          );

          const clientWidth = (this.scrollHeader.current?.clientWidth || 0) - 22;
          const railWidth = this.scrollHorizontalRail.current?.clientWidth || 0;
          railThumbWidth = Math.round((railWidth * clientWidth) / totalWidth);

          if (clientWidth - totalWidth > 0) {
            railThumbWidth = railWidth;
            this.maxScrollHorizontalRailThumbX = 0;
            this.perPxScrollHorizontalRailThumbX1px = 0;

            const tWidth = clientWidth + (isListVerticalScroll ? -12 : 0);
            this.perPxWidth = tWidth / totalWidth;
            this.littleWidth = tWidth - this.visibleHeaders.reduce(
              (p, c) => p + Math.round((c?.width || 100) * this.perPxWidth), 0,
            );
          } else {
            this.perPxWidth = 1;
            this.littleWidth = 0;
            this.maxScrollHorizontalRailThumbX = railWidth - railThumbWidth;
            this.perPxScrollHorizontalRailThumbX1px = Math.ceil(
              (totalWidth + (isListVerticalScroll ? 12 : 0) - clientWidth) / this.maxScrollHorizontalRailThumbX,
            );
          }
          //

          this.totalWidth = totalWidth;
        } else if (this.props.newOnlyOne && this.newRowCount > 1) {
          this.onChange();
          ConfirmWarning.show('경고', '한번에 한개의 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
          return;
        } else {
          this.newRowCount = (data?.length || 0) - (this.state.data?.length || 0);
        }

        this.setState({
          data: this.props?.data || [],
          totalWidth,
          railThumbWidth,
          isListHorizontalScroll: this.perPxScrollHorizontalRailThumbX1px > 0,
        }, async () => {
          if (isReset) await this.applyScrollX(0);
          resolve();
        });
      }, 10);
    });
  }

  @computed
  get visibleHeaders(): Array<TableLayoutHeader> {
    return this.props.header.filter((k) => !k.isHidden);
  }

  private applyScrollX(left: number) {
    if (this.scrollBody.current) this.scrollBody.current.scrollLeft = left;
  }

  private onHorizontalDragging(e: React.MouseEvent<HTMLDivElement>): void {
    if (e.buttons === 1 && !this.state.isHorizontalMouseActive) {
      this.setState({ isHorizontalMouseActive: true });
      e.preventDefault();
      e.stopPropagation();
    }
  }

  private onMouseMove(e: React.MouseEvent<HTMLDivElement>): void {
    if (e.buttons === 1 && this.state.isHorizontalMouseActive) {
      this.onDeltaXEvent(e.movementX);
      e.preventDefault();
      e.stopPropagation();
    } else if (this.state.isHorizontalMouseActive) {
      requestAnimationFrame(() => {
        this.setState({ isHorizontalMouseActive: false });
      });
    }
  }

  private onMouseLeave(_: React.MouseEvent<HTMLDivElement>): void {
    this.setState({ isHorizontalMouseActive: false });
  }

  private onDeltaXEvent(value: number): void {
    if (this.scrollHorizontalRailThumb.current) {
      let toThumbLeft = this.scrollHorizontalRailThumbX + value;
      if (toThumbLeft < 0) toThumbLeft = 0;
      if (toThumbLeft > this.maxScrollHorizontalRailThumbX) {
        toThumbLeft = this.maxScrollHorizontalRailThumbX;
      }

      this.scrollHorizontalRailThumbX = toThumbLeft;
      this.scrollHorizontalRailThumb.current.style.transform = `translateX(${toThumbLeft}px)`;

      const toBothScrollLeft = this.perPxScrollHorizontalRailThumbX1px * toThumbLeft;

      if (this.scrollHeader.current) this.scrollHeader.current.scrollLeft = toBothScrollLeft;
      if (this.scrollBody.current) this.scrollBody.current.scrollLeft = toBothScrollLeft;
    }
  }

  private rowUpdate(index: number, changes: any) {
    const willUpdate = {};
    Object.keys(changes).forEach((id) => {
      // @ts-ignore
      willUpdate[id] = {
        $set: changes[id],
      };
    });

    this.setState({
      data: update(this.state.data, {
        [index]: willUpdate,
      }),
    }, () => {
      if (this.changedIndexes.indexOf(index) === -1) this.changedIndexes.push(index);
      this.onChange();
    });
  }

  @action
  private rowSelect(item: any, index: number) {
    this.props.onRowFocusEvent && this.props.onRowFocusEvent(item, index);
    this.setState({ focusedRow: index });
  }

  private onChange = () => {
    this.props.onChange && this.props.onChange(this.state.data,
      this.state.data.filter((_x: any, i: number) => this.changedIndexes.indexOf(i) > -1));
  }

  private rowRenderer = (index: number) => {
    if (!this.state.data[index]) {
      return (
        <FlexLayout
          key={`ITEM_${index}`}
          size={this.props.rowHeight}
          align="center"
          justify="start"
        >
          Loading ..
        </FlexLayout>
      );
    }

    const y = this.state.data[index];
    if (!this.dataRefs[index]) {
      this.dataRefs[index] = [];
    }
    return (
      <FlexLayout
        key={`ITEM_${index}`}
        size={this.props.rowHeight}
        className={this.state.focusedRow === index ? style.highlight : undefined}
        onClick={() => {
          if (new Date().getTime() - this.lastFocusedTime < 300) return;
          if (this.state.focusedRow === index) return;
          this.lastFocusedTime = new Date().getTime();
          this.rowSelect(y, index);
        }}
        onFocus={() => {
          if (new Date().getTime() - this.lastFocusedTime < 300) return;
          if (this.state.focusedRow === index) return;
          this.lastFocusedTime = new Date().getTime();
          this.rowSelect(y, index);
        }}
      >
        <FlexLayout
          className={style.overflowHidden}
          align="center"
          justify="center"
          style={{
            fontSize: 12,
            width: 20,
            maxWidth: 20,
            minWidth: 20,
          }}
        >
          <GrDrag size={14} />
        </FlexLayout>

        {this.visibleHeaders.map((x, j: number) => {
          const width = Math.round((x.width || 100) * this.perPxWidth)
            + (j === 0 ? this.littleWidth : -2);

          let rowItem = x.render ? x.render(
            y,
            (changes) => this.rowUpdate(index, changes),
            this.dataRefs[index][j],
            this.props.scope,
            index,
          ) : undefined;

          if (!this.dataRefs[index][j]) {
            this.dataRefs[index][j] = React.createRef();
          }

          if (rowItem && (rowItem.type as any).prototype instanceof FlexLayout) {
            const p = rowItem.props;
            if (!p.isVertical && p.align?.toUpperCase() === 'CENTER') {
              if ((p.justify?.toUpperCase() === 'LEFT' || p.justify?.toUpperCase() === 'START')
                && (!p.style?.padding && !p.style?.paddingLeft)
              ) {
                if (p.style) {
                  p.style.paddingLeft = 8;
                } else {
                  rowItem = <FlexLayout style={{ paddingLeft: 8 }}>{rowItem}</FlexLayout>;
                }
              } else if ((p.justify?.toUpperCase() === 'RIGHT' || p.justify?.toUpperCase() === 'END')
                && (!p.style?.padding && !p.style?.paddingRight)
              ) {
                if (p.style) {
                  p.style.paddingRight = 8;
                } else {
                  rowItem = <FlexLayout style={{ paddingRight: 8 }}>{rowItem}</FlexLayout>;
                }
              }
            }
          }

          return (
            <React.Fragment key={x.id}>
              {x.render ? (
                <FlexLayout
                  className={style.overflowHidden}
                  style={{
                    width,
                    maxWidth: width,
                    minWidth: width || 100,
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 40) {
                      e.preventDefault();
                      if (this.state.data.length > index + 1) {
                        this.dataRefs[index + 1][j].current?.focus();
                      }
                    } else if (e.keyCode === 38) {
                      e.preventDefault();
                      if (index > 0) {
                        this.dataRefs[index - 1][j].current?.focus();
                      }
                    }
                  }}
                >
                  {rowItem}
                </FlexLayout>
              ) : (
                <FlexLayout
                  className={style.overflowHidden}
                  align="center"
                  justify="center"
                  style={{
                    fontSize: 12,
                    width,
                    maxWidth: width,
                    minWidth: width || 100,
                  }}
                >
                  {y[x.id]}
                </FlexLayout>
              )}
            </React.Fragment>
          );
        })}
      </FlexLayout>
    );
  }

  show = useContextMenu({
    id: 'DND_CONTEXT',
  }).show;

  handleContextMenu = (event: React.MouseEvent, item: any) => {
    event.preventDefault();
    this.show(event, {
      props: {
        item,
      },
    });
  }

  @action
  onContextMenuHandler = ({ props }: ItemParams, action: MENU_ACTION) => {
    const { item } = props;
    if (action === MENU_ACTION.SORT_ASC) {
      this.sortBy(item.sort || item.id);
    } else if (action === MENU_ACTION.SORT_DESC) {
      this.sortBy(item.sort || item.id, true);
    }
  }

  @action
  sortBy(key: string, isDesc: boolean = false) {
    if (isDesc) {
      this.state.data.sort((a, b) => (
        // eslint-disable-next-line no-nested-ternary
        `${b[key]}`.localeCompare(`${a[key]}`)
      ));
    } else {
      this.state.data.sort((a, b) => (
        // eslint-disable-next-line no-nested-ternary
        `${a[key]}`.localeCompare(`${b[key]}`)
      ));
    }

    this.setState({
      data: this.state.data,
    });
  }

  reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
    maxWidth: '100%',
    height: this.props.rowHeight,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 8px rgba(0, 0, 0, .03)',
    userSelect: 'none',
    ...draggableStyle,
  });

  getListStyle = (_isDraggingOver: boolean) => ({
    //
  });

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.data,
      result.source.index,
      result.destination.index,
    );

    let from = result.source.index;
    let to = (result.destination?.index ?? result.source.index);

    if (from > to) {
      from = to;
      to = result.source.index;
    }

    for (
      let i = from;
      i <= to;
      i += 1
    ) {
      if (this.changedIndexes.indexOf(i) === -1) this.changedIndexes.push(i);
    }

    this.setState({
      data: items,
    }, () => {
      this.onChange();
    });
  }

  render() {
    const {
      className,
      header,
    } = this.props;

    const {
      isHorizontalMouseActive,
    } = this.state;

    const data = this.state.data || [];

    return (
      <FlexLayout
        className={isHorizontalMouseActive ? style.disableDrag : undefined}
        onMouseMove={(e) => this.onMouseMove(e)}
        onMouseLeave={(e) => this.onMouseLeave(e)}
      >
        <FlexLayout
          className={JoinClassName.make([style.container, className])}
          isVertical={true}
        >
          <div
            ref={this.scrollHeader}
            className={style.scrollContainer}
            style={{
              flex: `${this.props.headerHeight ? this.props.headerHeight : 32}px 0 0`,
            }}
          >
            <div className={style.scroller}>
              <div className={style.headerContainer}>
                <FlexLayout
                  className={style.header}
                  style={{
                    fontSize: 12,
                    width: 20,
                    height: this.props.headerHeight ? this.props.headerHeight : 32,
                    maxWidth: 20,
                    minWidth: 20,
                    backgroundColor: 'var(--color-primary)',
                  }}
                />

                {header.filter((k) => !k.isHidden).map((x, j) => {
                  const width = Math.round((x.width || 100) * this.perPxWidth)
                    + (j === 0 ? this.littleWidth : -2);

                  return (
                    <div
                      key={j}
                      onContextMenu={(e) => this.handleContextMenu(e, x)}
                    >
                      <FlexLayout
                        className={style.header}
                        style={{
                          width,
                          maxWidth: width,
                          minWidth: width || 100,
                          color: x.color || 'inherit',
                          height: this.props.headerHeight ? this.props.headerHeight : 32,
                        }}
                        align="center"
                        justify="center"
                      >
                        {x.text}
                      </FlexLayout>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            className={style.scrollContainer}
          >
            <div
              ref={this.scrollBody}
              className={JoinClassName.make([style.scroller, style.body])}
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.data.map((_, index) => (
                        <Draggable key={`DRAG_${index}`} draggableId={`DRAG_${index}`} index={index}>
                          {(providedItem, snapshotItem) => (
                            <div
                              ref={providedItem.innerRef}
                              {...providedItem.draggableProps}
                              {...providedItem.dragHandleProps}
                              style={this.getItemStyle(
                                snapshotItem.isDragging,
                                providedItem.draggableProps.style,
                              )}
                            >
                              {this.rowRenderer(index)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>

          <FlexLayout className={style.footer} size={24}>
            <FlexLayout align="center" weight={2} className={style.noevent}>
              <FlexLayout style={{ color: '#818181' }}>
                <div>
                  총 <span style={{ color: '#515151' }}>
                    {Format.number(this.props.infinityHandler?.total || data.length || 0)}
                  </span>행,
                  &nbsp;변경 <span style={{ color: '#515151' }}>
                    {Format.number(this.changedIndexes.length || 0)}
                  </span>행
                </div>
              </FlexLayout>
            </FlexLayout>

            {this.state.isListHorizontalScroll && <FlexLayout align="center" justify="center">
              <FlexLayout size={15}>
                <FlexLayout
                  className={style.thumb}
                  onClick={() => this.onDeltaXEvent(-50)}
                >
                  <MdChevronLeft />
                </FlexLayout>
              </FlexLayout>
              <FlexLayout>
                <div ref={this.scrollHorizontalRail} className={style.rail}>
                  <div
                    ref={this.scrollHorizontalRailThumb}
                    onMouseMove={(e) => this.onHorizontalDragging(e)}
                    style={{
                      width: this.state.railThumbWidth,
                    }}
                  />
                </div>
              </FlexLayout>
              <FlexLayout size={15}>
                <FlexLayout
                  className={style.thumb}
                  onClick={() => this.onDeltaXEvent(50)}
                >
                  <MdChevronRight />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>}
          </FlexLayout>
        </FlexLayout>

        <Menu id="DND_CONTEXT">
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.SORT_ASC)}>
            오름차순 정렬
          </Item>
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.SORT_DESC)}>
            내림차순 정렬
          </Item>
        </Menu>
      </FlexLayout>
    );
  }
}
