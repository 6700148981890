import * as React from 'react';
import update from 'react-addons-update';
import {
  Button,
  FlexLayout,
  LabelBox,
  TableLayout,
  TextBox,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabTableConferenceHeader } from '../headers';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 상세 > 탭 - 현장협의사항
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabConference: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <Button width={85} onClick={() => scope.onClickSendSMS()}>문자전송</Button>
      <Button width={85} onClick={() => scope.onClickConferenceNew()}>추가</Button>
      <Button width={85} theme={BUTTON_THEMES.RED} onClick={() => scope.onClickConferenceRemove()}>삭제</Button>
    </FlexLayout>

    <FlexLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.tableConference}
          header={RegistTabTableConferenceHeader(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.tabDataConference}
          onChange={(rows, updates) => scope.onTabTableConferenceChange(rows, updates)}
          onRowFocusEvent={(item, index) => scope.onTabTableConferenceRowFocused(item, index)}
        />

        <FlexLayout>
          <LabelBox title="현장 협의사항">
            <TextBox
              value={scope.state.detailConferenceRemark}
              onChange={async (value) => {
                await scope.SS({
                  detailConferenceRemark: value,
                  tabDataConference: update(scope.state.tabDataConference, {
                    [scope.tabTableConferenceIndex]: {
                      remark: { $set: value },
                    },
                  }),
                });
                scope.tableConference.current?.update(false);
              }}
              isMultiline={true}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
