/**
 * 모델
 * @window w_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */

export class KcqtyPopupModel {
  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   * 대수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 검사일자
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 검사예정일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 합격필증
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 납기일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 기종
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 계약금
   *
   * **data type** varchar(8)
   */
  readonly contamt: string;

  /**
   * 선급금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   * 중도금
   *
   * **data type** varchar(8)
   */
  readonly midamt: string;

  /**
   * 잔금
   *
   * **data type** varchar(8)
   */
  readonly endamt: string;

  /**
   * 입금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 잔액
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   * 사양
   *
   * **data type** varchar(8)
   */
  readonly sizecd: string;

  /**
   * 옵션
   *
   * **data type** varchar(8)
   */
  readonly scoption: string;

  constructor(data: any = {}) {
    this.scactnm = data.scactnm || '';
    this.qty = data.qty || '';
    this.kcdate = data.kcdate || '';
    this.stdate = data.stdate || '';
    this.okflag = data.okflag || '';
    this.contdate = data.contdate || '';
    this.enddate = data.enddate || '';
    this.jeoban = data.jeoban || '';
    this.contamt = data.contamt || '';
    this.stamt = data.stamt || '';
    this.midamt = data.midamt || '';
    this.endamt = data.endamt || '';
    this.rcvamt = data.rcvamt || '';
    this.iamt = data.iamt || '';
    this.sizecd = data.sizecd || '';
    this.scoption = data.scoption || '';
  }
}
