import { computed } from 'mobx';

/**
 * 모델
 * @window w_popup_e033w
 * @category 담당별합계
 */

export class ChargePopupModel {
  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly kukcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(40)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly divicnt: number;

  /**
   *
   *
   * **data type** varchar(6)
   */
  readonly bemon: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly endqty: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly endqty2: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly totqty: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly notkccnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly chaqty: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly cnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly compcnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcnt1: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcnt2: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcnt3: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcnt4: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcnt5: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcompcnt1: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcompcnt2: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcompcnt3: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcompcnt4: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly weekcompcnt5: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly miscnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly jrececnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly jcompcnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly jmiscnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly send5cnt: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly totcnt: number;

  readonly totyul: number;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly open: string;

  constructor(data: any = {}, isOpen: boolean = false) {
    this.chk = data.chk || '';
    this.gubun = data.gubun || '';
    this.kukcd = data.kukcd || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicnt = data.divicnt || 0;
    this.bemon = data.bemon || '';
    this.endqty = data.endqty || 0;
    this.endqty2 = data.endqty2 || '';
    this.totqty = data.totqty || 0;
    this.totyul = data.totyul || 0;
    this.notkccnt = data.notkccnt || 0;
    this.cnt = data.cnt || 0;
    this.compcnt = data.compcnt || 0;
    this.weekcnt1 = data.weekcnt1 || 0;
    this.weekcnt2 = data.weekcnt2 || 0;
    this.weekcnt3 = data.weekcnt3 || 0;
    this.weekcnt4 = data.weekcnt4 || 0;
    this.weekcnt5 = data.weekcnt5 || 0;
    this.weekcompcnt1 = data.weekcompcnt1 || 0;
    this.weekcompcnt2 = data.weekcompcnt2 || 0;
    this.weekcompcnt3 = data.weekcompcnt3 || 0;
    this.weekcompcnt4 = data.weekcompcnt4 || 0;
    this.weekcompcnt5 = data.weekcompcnt5 || 0;
    this.miscnt = data.miscnt || 0;
    this.jrececnt = data.jrececnt || 0;
    this.jcompcnt = data.jcompcnt || 0;
    this.jmiscnt = data.jmiscnt || 0;
    this.send5cnt = data.send5cnt || 0;
    this.totcnt = data.totcnt || 0;
    this.chaqty = data.chaqty || 0;

    this.open = isOpen ? '1' : '0';
  }

  @computed
  get isOpen() {
    return this.open === '1';
  }
}
