import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { DepartmentModel } from '../models';
import { RepairCurrent } from '../RepairCurrent';
import { TelnumTextBox } from '../../../../../components';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';

// 부서별현장현황
const Department: GridLayoutHeader<DepartmentModel, RepairCurrent>[] = [
  {
    id: 'spjangnm',
    text: '사업장',
    width: 130,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.spjangnm}
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 100,
    group: true,
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        부서계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 80,
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 100,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumPernm)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '세금계산서발행처',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactnm',
    text: '회사구분',
    width: 70,
  },
  {
    id: 'qty',
    text: '관리대수',
    width: 70,
    sum: (x, data) => {
      const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumQty}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 130,
  },
  {
    id: 'actmail',
    text: '고객메일',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actmail}
      </FlexLayout>
    ),
  },
  {
    id: 'tel',
    text: '현장전화번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.tel}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'hp',
    text: '현장핸드폰번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.hp}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'zipcode',
    text: '우편번호',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.zipcode}
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnum',
    text: '프로젝트번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.cltnum}
      </FlexLayout>
    ),
  },
];

export default Department;
