import * as React from 'react';

export default class PageComponent<P, S> extends React.Component<P, S> {
  public SS<K extends keyof S>(
    state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null),
  ): Promise<void> {
    return new Promise((resolve) => {
      this.setState(state, () => resolve());
    });
  }
}
