import * as React from 'react';
import { observer } from 'mobx-react';
import { FiUpload } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/all';
import style from '../AuthorizedScreen.module.scss';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { TextBox } from '../../components/forms/TextBox';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { GridLayout } from '../../components/layout/GridLayout';
import { LoaderButton } from '../../components/forms/LoaderButton';
import { Format } from '../../utils/string';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { ComboBox, ComboBoxModel } from '../../components/forms/ComboBox';
import { CheckBox } from '../../components/forms/CheckBox';
import { TableLayout } from '../../components/layout/TableLayout';
import { TelnumTextBox } from '../../components/forms/TelnumTextBox';

interface ModalSMSProps {
  modalStore: ModalStore;
}

const searchGubun = [
  { groupcd: '%', groupnm: '전체' },
  { groupcd: '0', groupnm: '현장' },
  { groupcd: '1', groupnm: '거래처' },
  { groupcd: '2', groupnm: '직원' },
  { groupcd: '3', groupnm: '일반' },
  { groupcd: '4', groupnm: '기타' },
  { groupcd: '5', groupnm: '영업' },
];

@observer
export class ModalSMS extends React.Component<ModalSMSProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={900}
        height={800}
        isVisible={modalStore.isVisibleSMS}
        onClose={() => modalStore.closeSMS()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>문자메시지 전송</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>그룹분류</span>
              <ComboBox
                value={modalStore.smsSearchGubun}
                data={searchGubun.map((x) => new ComboBoxModel(x.groupcd, x.groupnm))}
                onSelect={(item) => {
                  modalStore.smsSearchGubun = item.value;
                  modalStore.retrieveSMS();
                }}
              />
            </FlexLayout>

            {(modalStore.smsSearchGubun === '0' || modalStore.smsSearchGubun === '2') && <FlexLayout size={106}>
              <span>{modalStore.smsSearchGubun === '0' ? '계약중' : '재직자'}</span>
              <div>
                <CheckBox
                  noMargin
                  value={modalStore.smsSearchGubunCheck}
                  onChange={(value) => {
                    modalStore.smsSearchGubunCheck = value;
                    modalStore.retrieveSMS();
                  }}
                />
              </div>
            </FlexLayout>}

            <FlexLayout weight={2}>
              <span>검색어</span>
              <TextBox
                value={modalStore.smsSearchQuery}
                onChange={(value) => {
                  modalStore.smsSearchQuery = value;
                  modalStore.smsSearchPosition = -1;
                }}
                onEnter={() => modalStore.searchSMS()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={136}>
              <span>선택만 보기</span>
              <div>
                <CheckBox
                  noMargin
                  value={modalStore.smsSearchCheck}
                  onChange={async (value) => {
                    modalStore.smsSearchCheck = value;
                    await modalStore.smsInfinity?.retrieveAll();

                    if (value) {
                      modalStore.smsTempData = [...modalStore.smsData];
                      modalStore.smsData = modalStore.smsData.filter((x) => x.check);
                    } else {
                      modalStore.smsData = modalStore.smsTempData;
                      modalStore.smsTempData = [];
                    }
                    modalStore.smsTableUsers.current?.update(true);
                  }}
                />
              </div>
            </FlexLayout>

            <Button onClick={() => modalStore.searchSMS()}>조회</Button>
          </SearchLayout>

          <FlexLayout isVertical={false} weight={2}>
            <FlexLayout weight={2}>
              <TableLayout
                ref={modalStore.smsTableUsers}
                header={[
                  {
                    id: 'check',
                    text: (
                      <FlexLayout justify="center" align="center">
                        <CheckBox
                          noMargin
                          value={modalStore.smsTotalCheck}
                          onChange={async (value) => modalStore.smsUpdateCheckAllToggle(value)}
                        />
                      </FlexLayout>
                    ),
                    width: 10,
                    render: (x, rowUpdate) => (
                      <FlexLayout justify="center" align="center">
                        <CheckBox
                          noMargin
                          value={x.check}
                          onChange={(value) => {
                            rowUpdate({ check: value });
                            modalStore.smsUpdateCheck(x, value);
                          }}
                        />
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'regflag',
                    text: '고객구분',
                    width: 20,
                    render: (x) => {
                      const item = searchGubun.filter((y) => y.groupcd === x.regflag);
                      const text = item.length ? item[0].groupnm : '';
                      return (
                        <FlexLayout justify="center" align="center">
                          {text}
                        </FlexLayout>
                      );
                    },
                  },
                  {
                    id: 'actmail',
                    text: '고객명',
                    width: 30,
                    render: (x) => (
                      <FlexLayout justify="start" align="center">
                        {x.actmail}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tel',
                    text: '전화번호',
                    width: 40,
                    render: (x) => (
                      <TelnumTextBox
                        value={x.tel || ''}
                        readonly={true}
                        transparent={true}
                      />
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={modalStore.smsInfinity}
                data={modalStore.smsData}
                onChange={(rows) => { modalStore.smsData = rows; }}
              />
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
                <LayoutTitle weight={1}>
                  본문
                </LayoutTitle>
                <Button onClick={async () => {
                  const boilerplate = await modalStore.openBoilerplate();
                  if (boilerplate) {
                    modalStore.smsRemark = `${modalStore.smsRemark}\n\n${boilerplate.remark}`;
                  }
                }}>
                  <span>자주쓰는 문구</span>
                </Button>
              </FlexLayout>

              <TextBox
                value={modalStore.smsRemark}
                placeholder="여기에 내용을 입력하세요."
                isMultiline={true}
                minHeight={120}
                onChange={(value) => { modalStore.smsRemark = value; }}
              />

              <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
                <LayoutTitle weight={1}>
                  받는 사람
                </LayoutTitle>
                <Button onClick={async () => {
                  modalStore.smsTo = [...modalStore.smsTo, { tel: '' }];
                  await modalStore.smsTableTo.current?.update(false);
                  modalStore.smsTableTo.current?.setFocus(modalStore.smsTo.length - 1, 1);
                }}>
                  <span>추가</span>
                </Button>
              </FlexLayout>

              <TableLayout
                ref={modalStore.smsTableTo}
                header={[
                  {
                    id: 'delete',
                    text: '삭제',
                    width: 30,
                    render: (x: any) => (
                      <FlexLayout justify="center" align="center">
                        <Button
                          isIcon={true}
                          onClick={() => {
                            modalStore.smsTo = modalStore
                              .smsTo.filter((y) => y.tel !== x.tel);
                            modalStore.smsTableTo.current?.update(false);
                          }}
                        >
                          <IoMdTrash size={19} />
                        </Button>
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tel',
                    text: '휴대폰 번호',
                    render: (x, rowUpdate, refs) => (
                      <TelnumTextBox
                        ref={refs}
                        value={x.tel || ''}
                        onChange={(value) => rowUpdate({ tel: value })}
                      />
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={modalStore.smsTo}
                onChange={(rows) => { modalStore.smsTo = rows; }}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
              <LayoutTitle weight={1}>
                사진 첨부 <span style={{ color: '#FF0000' }}>파일(이미지)은 70kbyte까지 가능합니다.</span>
              </LayoutTitle>
              <LoaderButton
                onClick={() => modalStore.smsAddAttachment()}
                percent={modalStore.smsPercentAttachment}
              >
                <FiUpload />
                <span>내 PC</span>
              </LoaderButton>
            </FlexLayout>

            <GridLayout
              header={[
                {
                  id: 'delete',
                  text: '삭제',
                  width: 10,
                  render: (x: any) => (
                    <FlexLayout justify="center" align="center">
                      <Button
                        isIcon={true}
                        onClick={() => modalStore.smsAttachmentRemove(x)}
                      >
                        <IoMdTrash size={19} />
                      </Button>
                    </FlexLayout>
                  ),
                }, {
                  id: 'filename',
                  text: '파일명',
                  width: 60,
                  render: (x: any) => (
                    <FlexLayout justify="start" align="center">
                      {x.filename.startsWith('.') ? `이름없음${x.filename}` : x.filename}
                    </FlexLayout>
                  ),
                }, {
                  id: 'extension',
                  text: '확장자',
                  width: 10,
                }, {
                  id: 'size',
                  text: '파일크기',
                  width: 20,
                  render: (x: any) => (
                    <FlexLayout justify="end" align="center">
                      {Format.number(x.size)}B&nbsp;
                    </FlexLayout>
                  ),
                },
              ]}
              data={modalStore.smsAttachments || []}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />

            <FlexLayout className={style.buttons} size={38}>
              <Button
                className={style.btnDanger}
                isFull={true}
                onClick={() => modalStore.closeSMS()}
              >
                취소
              </Button>
              <Button
                className={style.btnSuccess}
                isFull={true}
                onClick={() => modalStore.sendSMS()}
              >
                전송
              </Button>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
