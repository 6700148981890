import { computed } from 'mobx';

export class ApprovalItem {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 사업장 코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 사원코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 문서 분류 코드
   *
   * **data type** varchar(10)
   */
  papercd: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  no: string;

  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 결재자 사원 코드
   *
   * **data type** varchar(10)
   */
  kcperid: string;

  /**
   * 결재자 이름
   *
   * **data type** varchar(10)
   */
  kcpernm: string;

  /**
   * 직위
   *
   * **data type** varchar(10)
   */
  rspnm: string;

  /**
   * 결재 구분 (결재/참조)
   *
   * **data type** varchar(1)
   */
  gubun: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 결재를 태워야 하는지
   *
   * **data type** varchar(1)
   */
  kcchk: string;

  /**
   * 상신자 사원 코드
   *
   * **data type** varchar(8)
   */
  inperid: string;

  /**
   * 날짜
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 신규 유무
   *
   * **data type** char(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data?.custcd || '';
    this.spjangcd = data?.spjangcd || '';
    this.perid = data?.perid || '';
    this.papercd = data?.papercd || '';
    this.no = data?.no || '';
    this.seq = data?.seq || '';
    this.kcperid = data?.kcperid || '';
    this.kcpernm = data?.kcpernm || '';
    this.rspnm = data?.rspnm || '';
    this.gubun = data?.gubun || '';
    this.remark = data?.remark || '';
    this.kcchk = data?.kcchk || '';
    this.inperid = data?.inperid || '';
    this.indate = data?.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
