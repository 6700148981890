import { computed } from 'mobx';

/**
 * 지급내역 모델
 * @window w_tb_da003_01w
 * @category 프로젝트현황
 */
export class GiveModel {
  /**
   * 품목코드
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   * 매입일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** char(4)
   */
  mijnum: string;

  /**
   * 순번
   *
   * **data type** char(3)
   */
  seq: string;

  /**
   * 계정과목
   *
   * **data type** char(13)
   */
  actcd: string;

  /**
   * 지급처명
   *
   * **data type** char(13)
   */
  mijcltcd: string;

  /**
   * 지급처명
   *
   * **data type** char(100)
   */
  mijcltnm: string;

  /**
   * 계정과목
   *
   * **data type** varchar(20)
   */
  acccd: string;

  /**
   * 품목명
   *
   * **data type** varchar(255)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  psize: string;

  /**
   * 단위
   *
   * **data type** char(50)
   */
  punit: string;

  /**
   * 수량
   *
   * **data type** number
   */
  qty: string;

  /**
   * 매입가액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** number
   */
  tamt: string;

  /**
   * 합계금액
   *
   * **data type** number
   */
  mijamt: string;

  /**
   * 지급일자
   *
   * **data type** char(8)
   */
  snddate: string;

  /**
   * 번호
   *
   * **data type** char(4)
   */
  sndnum: string;

  /**
   * 지급금액
   *
   * **data type** number
   */
  sndamt: string;

  /**
   * 잔액(미지급액)
   *
   * **data type** number
   */
  iamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.seq = data.seq || '';
    this.actcd = data.actcd || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.acccd = data.acccd || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mijamt = data.mijamt || '';
    this.snddate = data.snddate || '';
    this.sndnum = data.sndnum || '';
    this.sndamt = data.sndamt || '';
    this.iamt = data.iamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
