import { GLHF } from '../../../../constants';
import { ContractStatisticsModel } from './models/ContractStatistics.model';
import {
  ContractStatistics,
} from './ContractStatistics';
import { FlexLayout } from '../../../../components';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';

export const ContractStatisticsGridHeader: GLHF<ContractStatisticsModel, ContractStatistics> = (scope) => ([
  {
    id: 'mons',
    text: '월',
    width: 30,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDashMon(x.mon)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'qty01',
    text: '신규대수',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#E6F0FB' }}
        onDoubleClick={() => scope.openModal(true, 'new')}
      >
        {x.qty01}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.qty01_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'amt01',
    text: '신규금액',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#E6F0FB' }}
        onDoubleClick={() => scope.openModal(true, 'new')}
      >
        {Format.number(x.amt01)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.amt01_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty03_plus',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>재계약</FlexLayout>
        <FlexLayout>인상대수</FlexLayout>
      </FlexLayout>
    ),
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#E6F0FB' }}
        onDoubleClick={() => scope.openModal(true, 'plus')}
      >
        {x.qty03_plus}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.qty03_plus_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'amt03_plus',
    text: '재계약인상금액',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#E6F0FB' }}
        onDoubleClick={() => scope.openModal(true, 'plus')}
      >
        {Format.number(x.amt03_plus)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.amt03_plus_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'tot_plus',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>증가합계</FlexLayout>
        <FlexLayout>(신규+재계약)</FlexLayout>
      </FlexLayout>
    ),
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)', background: '#E6F0FB' }}
      >
        {Format.number(x.tot_plus)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.tot_plus_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty03_m',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>재계약</FlexLayout>
        <FlexLayout>감소대수</FlexLayout>
      </FlexLayout>
    ),
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#FFEAEA' }}
        onDoubleClick={() => scope.openModal(true, 'minus')}
      >
        {x.qty03_minus}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.qty03_minus_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'amt03_minus',
    text: '재계약감소금액',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#FFEAEA' }}
        onDoubleClick={() => scope.openModal(true, 'minus')}
      >
        {Format.number(x.amt03_minus)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.amt03_minus_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'qty_cls',
    text: '해지대수',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#FFEAEA' }}
        onDoubleClick={() => scope.openModal(true, 'cls')}
      >
        {x.qty_cls}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.qty_cls_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'amt_cls',
    text: '해지금액',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ background: '#FFEAEA' }}
        onDoubleClick={() => scope.openModal(true, 'cls')}
      >
        {Format.number(x.amt_cls)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.amt_cls_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'tot_minus',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout>감소합계</FlexLayout>
        <FlexLayout>(재게약+해지)</FlexLayout>
      </FlexLayout>
    ),
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)', background: '#FFEAEA' }}
      >
        {Format.number(x.tot_minus)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#FF00FF' }}
      >
        {Format.number(scope.state.tot_minus_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'tot_amt',
    text: '총증감액',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {Format.number(x.tot_amt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.tot_amt_tot)}
      </FlexLayout>
    ),
  },
]);
