import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { ChargeReceivable } from '../ChargeReceivable';
import {
  FlexLayout,
  GridLayout,
  LayoutTitle,
} from '../../../../../components';
import { WeekendReceivablesTabGridHeader } from '../heades/WeekendReceivablesTabGridHeader';
import { Date6Calculator } from '../../../../../utils/time';


export const WeekendReceivablesTemplate: React.FC<TemplateProps<ChargeReceivable>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <LayoutTitle justify="left">
      <span style={{ color: 'var(--color-red)' }}>* 1달이상된 미수금액의 수금내역입니다. (미수기준월 :
        {new Date6Calculator(scope.state.yearmon).add(-1).toString().slice(0, 4)}년
        {new Date6Calculator(scope.state.yearmon).add(-1).toString().slice(4, 6)}월 )</span>
    </LayoutTitle>
    <GridLayout
      ref={scope.gridWeekendReceivable}
      header={WeekendReceivablesTabGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataWeekendReceivable}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
