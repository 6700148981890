import { computed } from 'mobx';

/**
 * 모델
 * @window w_xusers
 * @category 개인환경설정
 */
export class SettingsModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사용자ID
   *
   * **data type** varchar(10)
   */
  userid: string;

  /**
   * 주민번호
   *
   * **data type** varchar(13)
   */
  rnum: string;

  /**
   * 비밀번호
   *
   * **data type** varchar(255)
   */
  password1: string;

  /**
   * 비밀번호2
   *
   * **data type** varchar(255)
   */
  password2: string;

  /**
   * 회사명
   *
   * **data type** varchar(50)
   */
  custnm: string;

  /**
   * 사용자성명
   *
   * **data type** varchar(50)
   */
  pernm: string;

  /**
   * 사용여부
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 위치정보열람이용신청
   *
   * **data type** varchar(1)
   */
  state: string;

  /**
   * 직원코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  sysmain: string;

  /**
   * 그룹아이디
   *
   * **data type** varchar(10)
   */
  grpid: string;

  /**
   * 메뉴
   *
   * **data type** varchar(1)
   */
  menu: string;

  /**
   * 다른메뉴
   *
   * **data type** varchar(2)
   */
  ordermenu: string;

  /**
   * 다른메뉴스위치
   *
   * **data type** varchar(2)
   */
  ordermenuon: string;

  /**
   * 전자세금계산서 예약전송권한
   *
   * **data type** varchar(1)
   */
  taxreserv: string;

  /**
   * 보수관리권한
   *
   * **data type** varchar(1)
   */
  contyn: string;

  /**
   * 설치관리권한
   *
   * **data type** varchar(1)
   */
  sulchi: string;

  /**
   * 연관검색설정
   *
   * **data type** varchar(1)
   */
  search: string;

  /**
   * 관리자아이디
   *
   * **data type** varchar(1)
   */
  addmin: string;

  /**
   * 테마설정
   *
   * **data type** varchar(20)
   */
  color: string;

  /**
   * 공지사항관리자권한
   *
   * **data type** varchar(1)
   */
  noticeflag: string;

  /**
   * 자체점검계획생성
   *
   * **data type** varchar(1)
   */
  e035_autoflag: string;

  /**
   * 전송선택여부
   *
   * **data type** varchar(5)
   */
  tax_funccode: string;

  /**
   * 로그인실패횟수
   *
   * **data type** float
   */
  warning: string;

  /**
   * 비밀번호
   *
   * **data type** varchar(10)
   */
  passwd1: string;

  /**
   * 비밀번호 확인
   *
   * **data type** varchar(10)
   */
  passwd2: string;

  /**
   * 모름
   *
   * **data type** varchar(255)
   */
  auth: string;

  /**
   * 부서
   *
   * **data type**
   */
  divinm: string;

  /**
   * 직위
   *
   * **data type**
   */
  rspnm: string;

  /**
   * 시작일자
   *
   * **data type**
   */
  stdate: string;

  /**
   * 종료일자
   *
   * **data type**
   */
  enddate: string;

  /**
   * 특이사항
   *
   * **data type**
   */
  remark: string;

  /**
   * 컴퓨터이름
   *
   * **data type**
   */
  pcname: string;

  /**
   * MAC주소
   *
   * **data type**
   */
  mac: string;

  /**
   * 용도
   *
   * **data type**
   */
  state2: string;

  /**
   * 고대비
   *
   * **data type**
   */
  contrast: string;

  spjangnm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.userid = data.userid || '';
    this.rnum = data.rnum || '';
    this.password1 = data.password1 || '';
    this.password2 = data.password2 || '';
    this.custnm = data.custnm || '';
    this.pernm = data.pernm || '';
    this.useyn = data.useyn || '';
    this.perid = data.perid || '';
    this.sysmain = data.sysmain || '';
    this.grpid = data.grpid || '';
    this.menu = data.menu || '';
    this.ordermenu = data.ordermenu || '';
    this.ordermenuon = data.ordermenuon || '';
    this.taxreserv = data.taxreserv || '';
    this.state = data.state || '0';
    this.contyn = data.contyn || '';
    this.sulchi = data.sulchi || '';
    this.search = data.search || '';
    this.addmin = data.addmin || '';
    this.color = data.color || '';
    this.noticeflag = data.noticeflag || '';
    this.e035_autoflag = data.e035_autoflag || '';
    this.tax_funccode = data.tax_funccode || '';
    this.warning = data.warning || '';
    this.passwd1 = data.passwd1 || '';
    this.passwd2 = data.passwd2 || '';
    this.auth = data.auth || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.remark = data.remark || '';
    this.pcname = data.pcname || '';
    this.mac = data.mac || '';
    this.state2 = data.state2 || '';
    this.contrast = data.contrast || '';
    this.spjangnm = data.spjangnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
