import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  ModalView,
  SearchLayout,
} from '../../../../components';
import { TemplateProps } from '../../../../constants';
import { Year } from './Year';

/**
 * 화면
 * @window w_tb_yearend
 * @category 년마감현황
 */
export const YearTemplate: React.FC<TemplateProps<Year>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <ModalView
        width={370}
        height={70}
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.textModal(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SearchLayout>
            <FlexLayout size={200}>
              <span>년도</span>
              <DateTextBox
                value={scope.state.year}
                textAlign="center"
                format="####"
                onChange={(value) => scope.setState({ year: value })}
                onEnter={(value) => scope.setState({ year: value })}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      scope.setState({ year: date.getFullYear().toString() });
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      scope.setState({ year: date.getFullYear().toString() });
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button onClick={() => scope.onPageMove()}>
              확인
            </Button>
          </SearchLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
