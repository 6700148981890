import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w_01
 * @category 호기별고장통계
 */

export class BreakUnitMonthModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 접수일자
   *
   * **data type** varchar(15)
   */
  readonly recedate: string;

  /**
   * 접수일자
   *
   * **data type** varchar(15)
   */
  readonly receyear: string;

  /**
   * 접수일자
   *
   * **data type** varchar(15)
   */
  readonly recemon: string;

  /**
   * 합계
   *
   * **data type** varchar(15)
   */
  readonly ttot_samt: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm001: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm002: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm003: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm004: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm005: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm006: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm007: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm008: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm009: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm010: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm011: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm012: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm013: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm014: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm015: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm016: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm017: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm018: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm019: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm020: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm021: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm022: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm023: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm024: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm025: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm026: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm027: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm028: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm029: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm030: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm031: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm032: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm033: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm034: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm035: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm036: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm037: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm038: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm039: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm040: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm041: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm042: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm043: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm044: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm045: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm046: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm047: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm048: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm049: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm050: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm051: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm052: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm053: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm054: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm055: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm056: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm057: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm058: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm059: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm060: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm061: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm062: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm063: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm064: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm065: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm066: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm067: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm068: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm069: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm070: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm071: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm072: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm073: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm074: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm075: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm076: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm077: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm078: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm079: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm080: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm081: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm082: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm083: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm084: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm085: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm086: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm087: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm088: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm089: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm090: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm091: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm092: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm093: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm094: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm095: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm096: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm097: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm098: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm099: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm100: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm101: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm102: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm103: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm104: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm105: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm106: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm107: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm108: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm109: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm110: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm111: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm112: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm113: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm114: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm115: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm116: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm117: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm118: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm119: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equpnm120: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp001: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp002: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp003: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp004: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp005: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp006: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp007: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp008: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp009: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp010: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp011: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp012: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp013: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp014: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp015: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp016: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp017: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp018: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp019: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp020: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp021: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp022: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp023: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp024: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp025: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp026: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp027: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp028: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp029: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp030: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp031: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp032: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp033: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp034: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp035: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp036: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp037: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp038: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp039: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp040: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp041: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp042: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp043: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp044: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp045: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp046: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp047: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp048: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp049: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp050: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp051: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp052: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp053: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp054: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp055: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp056: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp057: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp058: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp059: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp060: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp061: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp062: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp063: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp064: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp065: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp066: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp067: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp068: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp069: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp070: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp071: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp072: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp073: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp074: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp075: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp076: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp077: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp078: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp079: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp080: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp081: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp082: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp083: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp084: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp085: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp086: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp087: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp088: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp089: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp090: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp091: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp092: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp093: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp094: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp095: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp096: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp097: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp098: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp099: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp100: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp101: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp102: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp103: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp104: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp105: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp106: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp107: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp108: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp109: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp110: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp111: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp112: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp113: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp114: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp115: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp116: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp117: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp118: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp119: string;

  /**
   * 호기명
   *
   * **data type** varchar(15)
   */
  readonly equp120: string;



  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.recedate = data.recedate || '';
    this.receyear = data.receyear || '';
    this.equpnm001 = data.equpnm001 || '';
    this.equpnm002 = data.equpnm002 || '';
    this.equpnm003 = data.equpnm003 || '';
    this.equpnm004 = data.equpnm004 || '';
    this.equpnm005 = data.equpnm005 || '';
    this.equpnm006 = data.equpnm006 || '';
    this.equpnm007 = data.equpnm007 || '';
    this.equpnm008 = data.equpnm008 || '';
    this.equpnm009 = data.equpnm009 || '';
    this.equpnm010 = data.equpnm010 || '';
    this.equpnm011 = data.equpnm011 || '';
    this.equpnm012 = data.equpnm012 || '';
    this.equpnm013 = data.equpnm013 || '';
    this.equpnm014 = data.equpnm014 || '';
    this.equpnm015 = data.equpnm015 || '';
    this.equpnm016 = data.equpnm016 || '';
    this.equpnm017 = data.equpnm017 || '';
    this.equpnm018 = data.equpnm018 || '';
    this.equpnm019 = data.equpnm019 || '';
    this.equpnm020 = data.equpnm020 || '';
    this.equpnm021 = data.equpnm021 || '';
    this.equpnm022 = data.equpnm022 || '';
    this.equpnm023 = data.equpnm023 || '';
    this.equpnm024 = data.equpnm024 || '';
    this.equpnm025 = data.equpnm025 || '';
    this.equpnm026 = data.equpnm026 || '';
    this.equpnm027 = data.equpnm027 || '';
    this.equpnm028 = data.equpnm028 || '';
    this.equpnm029 = data.equpnm029 || '';
    this.equpnm030 = data.equpnm030 || '';
    this.equpnm031 = data.equpnm031 || '';
    this.equpnm032 = data.equpnm032 || '';
    this.equpnm033 = data.equpnm033 || '';
    this.equpnm034 = data.equpnm034 || '';
    this.equpnm035 = data.equpnm035 || '';
    this.equpnm036 = data.equpnm036 || '';
    this.equpnm037 = data.equpnm037 || '';
    this.equpnm038 = data.equpnm038 || '';
    this.equpnm039 = data.equpnm039 || '';
    this.equpnm040 = data.equpnm040 || '';
    this.equpnm041 = data.equpnm041 || '';
    this.equpnm042 = data.equpnm042 || '';
    this.equpnm043 = data.equpnm043 || '';
    this.equpnm044 = data.equpnm044 || '';
    this.equpnm045 = data.equpnm045 || '';
    this.equpnm046 = data.equpnm046 || '';
    this.equpnm047 = data.equpnm047 || '';
    this.equpnm048 = data.equpnm048 || '';
    this.equpnm049 = data.equpnm049 || '';
    this.equpnm050 = data.equpnm050 || '';
    this.equpnm051 = data.equpnm051 || '';
    this.equpnm052 = data.equpnm052 || '';
    this.equpnm053 = data.equpnm053 || '';
    this.equpnm054 = data.equpnm054 || '';
    this.equpnm055 = data.equpnm055 || '';
    this.equpnm056 = data.equpnm056 || '';
    this.equpnm057 = data.equpnm057 || '';
    this.equpnm058 = data.equpnm058 || '';
    this.equpnm059 = data.equpnm059 || '';
    this.equpnm060 = data.equpnm060 || '';
    this.equpnm061 = data.equpnm061 || '';
    this.equpnm062 = data.equpnm062 || '';
    this.equpnm063 = data.equpnm063 || '';
    this.equpnm064 = data.equpnm064 || '';
    this.equpnm065 = data.equpnm065 || '';
    this.equpnm066 = data.equpnm066 || '';
    this.equpnm067 = data.equpnm067 || '';
    this.equpnm068 = data.equpnm068 || '';
    this.equpnm069 = data.equpnm069 || '';
    this.equpnm070 = data.equpnm070 || '';
    this.equpnm071 = data.equpnm071 || '';
    this.equpnm072 = data.equpnm072 || '';
    this.equpnm073 = data.equpnm073 || '';
    this.equpnm074 = data.equpnm074 || '';
    this.equpnm075 = data.equpnm075 || '';
    this.equpnm076 = data.equpnm076 || '';
    this.equpnm077 = data.equpnm077 || '';
    this.equpnm078 = data.equpnm078 || '';
    this.equpnm079 = data.equpnm079 || '';
    this.equpnm080 = data.equpnm080 || '';
    this.equpnm081 = data.equpnm081 || '';
    this.equpnm082 = data.equpnm082 || '';
    this.equpnm083 = data.equpnm083 || '';
    this.equpnm084 = data.equpnm084 || '';
    this.equpnm085 = data.equpnm085 || '';
    this.equpnm086 = data.equpnm086 || '';
    this.equpnm087 = data.equpnm087 || '';
    this.equpnm088 = data.equpnm088 || '';
    this.equpnm089 = data.equpnm089 || '';
    this.equpnm090 = data.equpnm090 || '';
    this.equpnm091 = data.equpnm091 || '';
    this.equpnm092 = data.equpnm092 || '';
    this.equpnm093 = data.equpnm093 || '';
    this.equpnm094 = data.equpnm094 || '';
    this.equpnm095 = data.equpnm095 || '';
    this.equpnm096 = data.equpnm096 || '';
    this.equpnm097 = data.equpnm097 || '';
    this.equpnm098 = data.equpnm098 || '';
    this.equpnm099 = data.equpnm099 || '';
    this.equpnm100 = data.equpnm100 || '';
    this.equpnm101 = data.equpnm101 || '';
    this.equpnm102 = data.equpnm102 || '';
    this.equpnm103 = data.equpnm103 || '';
    this.equpnm104 = data.equpnm104 || '';
    this.equpnm105 = data.equpnm105 || '';
    this.equpnm106 = data.equpnm106 || '';
    this.equpnm107 = data.equpnm107 || '';
    this.equpnm108 = data.equpnm108 || '';
    this.equpnm109 = data.equpnm109 || '';
    this.equpnm110 = data.equpnm110 || '';
    this.equpnm111 = data.equpnm111 || '';
    this.equpnm112 = data.equpnm112 || '';
    this.equpnm113 = data.equpnm113 || '';
    this.equpnm114 = data.equpnm114 || '';
    this.equpnm115 = data.equpnm115 || '';
    this.equpnm116 = data.equpnm116 || '';
    this.equpnm117 = data.equpnm117 || '';
    this.equpnm118 = data.equpnm118 || '';
    this.equpnm119 = data.equpnm119 || '';
    this.equpnm120 = data.equpnm120 || '';
    this.equp001 = data.equp001 || '';
    this.equp002 = data.equp002 || '';
    this.equp003 = data.equp003 || '';
    this.equp004 = data.equp004 || '';
    this.equp005 = data.equp005 || '';
    this.equp006 = data.equp006 || '';
    this.equp007 = data.equp007 || '';
    this.equp008 = data.equp008 || '';
    this.equp009 = data.equp009 || '';
    this.equp010 = data.equp010 || '';
    this.equp011 = data.equp011 || '';
    this.equp012 = data.equp012 || '';
    this.equp013 = data.equp013 || '';
    this.equp014 = data.equp014 || '';
    this.equp015 = data.equp015 || '';
    this.equp016 = data.equp016 || '';
    this.equp017 = data.equp017 || '';
    this.equp018 = data.equp018 || '';
    this.equp019 = data.equp019 || '';
    this.equp020 = data.equp020 || '';
    this.equp021 = data.equp021 || '';
    this.equp022 = data.equp022 || '';
    this.equp023 = data.equp023 || '';
    this.equp024 = data.equp024 || '';
    this.equp025 = data.equp025 || '';
    this.equp026 = data.equp026 || '';
    this.equp027 = data.equp027 || '';
    this.equp028 = data.equp028 || '';
    this.equp029 = data.equp029 || '';
    this.equp030 = data.equp030 || '';
    this.equp031 = data.equp031 || '';
    this.equp032 = data.equp032 || '';
    this.equp033 = data.equp033 || '';
    this.equp034 = data.equp034 || '';
    this.equp035 = data.equp035 || '';
    this.equp036 = data.equp036 || '';
    this.equp037 = data.equp037 || '';
    this.equp038 = data.equp038 || '';
    this.equp039 = data.equp039 || '';
    this.equp040 = data.equp040 || '';
    this.equp041 = data.equp041 || '';
    this.equp042 = data.equp042 || '';
    this.equp043 = data.equp043 || '';
    this.equp044 = data.equp044 || '';
    this.equp045 = data.equp045 || '';
    this.equp046 = data.equp046 || '';
    this.equp047 = data.equp047 || '';
    this.equp048 = data.equp048 || '';
    this.equp049 = data.equp049 || '';
    this.equp050 = data.equp050 || '';
    this.equp051 = data.equp051 || '';
    this.equp052 = data.equp052 || '';
    this.equp053 = data.equp053 || '';
    this.equp054 = data.equp054 || '';
    this.equp055 = data.equp055 || '';
    this.equp056 = data.equp056 || '';
    this.equp057 = data.equp057 || '';
    this.equp058 = data.equp058 || '';
    this.equp059 = data.equp059 || '';
    this.equp060 = data.equp060 || '';
    this.equp061 = data.equp061 || '';
    this.equp062 = data.equp062 || '';
    this.equp063 = data.equp063 || '';
    this.equp064 = data.equp064 || '';
    this.equp065 = data.equp065 || '';
    this.equp066 = data.equp066 || '';
    this.equp067 = data.equp067 || '';
    this.equp068 = data.equp068 || '';
    this.equp069 = data.equp069 || '';
    this.equp070 = data.equp070 || '';
    this.equp071 = data.equp071 || '';
    this.equp072 = data.equp072 || '';
    this.equp073 = data.equp073 || '';
    this.equp074 = data.equp074 || '';
    this.equp075 = data.equp075 || '';
    this.equp076 = data.equp076 || '';
    this.equp077 = data.equp077 || '';
    this.equp078 = data.equp078 || '';
    this.equp079 = data.equp079 || '';
    this.equp080 = data.equp080 || '';
    this.equp081 = data.equp081 || '';
    this.equp082 = data.equp082 || '';
    this.equp083 = data.equp083 || '';
    this.equp084 = data.equp084 || '';
    this.equp085 = data.equp085 || '';
    this.equp086 = data.equp086 || '';
    this.equp087 = data.equp087 || '';
    this.equp088 = data.equp088 || '';
    this.equp089 = data.equp089 || '';
    this.equp090 = data.equp090 || '';
    this.equp091 = data.equp091 || '';
    this.equp092 = data.equp092 || '';
    this.equp093 = data.equp093 || '';
    this.equp094 = data.equp094 || '';
    this.equp095 = data.equp095 || '';
    this.equp096 = data.equp096 || '';
    this.equp097 = data.equp097 || '';
    this.equp098 = data.equp098 || '';
    this.equp099 = data.equp099 || '';
    this.equp100 = data.equp100 || '';
    this.equp101 = data.equp101 || '';
    this.equp102 = data.equp102 || '';
    this.equp103 = data.equp103 || '';
    this.equp104 = data.equp104 || '';
    this.equp105 = data.equp105 || '';
    this.equp106 = data.equp106 || '';
    this.equp107 = data.equp107 || '';
    this.equp108 = data.equp108 || '';
    this.equp109 = data.equp109 || '';
    this.equp110 = data.equp110 || '';
    this.equp111 = data.equp111 || '';
    this.equp112 = data.equp112 || '';
    this.equp113 = data.equp113 || '';
    this.equp114 = data.equp114 || '';
    this.equp115 = data.equp115 || '';
    this.equp116 = data.equp116 || '';
    this.equp117 = data.equp117 || '';
    this.equp118 = data.equp118 || '';
    this.equp119 = data.equp119 || '';
    this.equp120 = data.equp120 || '';
    this.recemon = data.recemon || '';

    this.ttot_samt = data.ttot_samt || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
