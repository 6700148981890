import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { CompanyTemplate } from './Company.template';
import {
  DetailModel,
  MainModel,
} from './models';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import Confirm from '../../../../utils/confirm/Confirm';
import { PageComponent } from '../../../../utils/layout';

interface WorkState {
  year: string;

  // data
  data: Array<MainModel>;
  dataDetail: Array<DetailModel>;
  focused?: MainModel;
  focusedDetail?: DetailModel;

  // trail
  notamt_tot: string;
  samt_tot: string;
  misamt_tot: string;
  yul_tot: string;

  // Detail trail
  detail_yul_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_da050
 * @category 회사목표계획등록
 */
export class Company extends PageComponent<PageProps, WorkState>
  implements PageToolEvents {
  updatedRows?: Array<DetailModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  endBtn: boolean | undefined;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      data: [],

      // trail
      notamt_tot: '0',
      samt_tot: '0',
      misamt_tot: '0',
      yul_tot: '0',

      // Detail trail
      detail_yul_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.endBtn = false;
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MainModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo('planmon', this.state.focused?.planmon, type, true) || 0;
      await this.infinity?.retrieveAll();

      this.SS({
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        samt_tot: this.infinity?.box?.samt_tot || '0',
        notamt_tot: this.infinity?.box?.notamt_tot || '0',
        yul_tot: this.infinity?.box?.yul_tot || '0',
      });

      this.state.data.length > index && this.grid.current?.setFocus(index);
    });
  }

  @action
  onRowFocusEvent(item: MainModel) {
    const { actionStore: api } = this.props;

    this.setState({
      focused: item,
      detail_yul_tot: item.yul,
    }, async () => {
      const data = await api.fxExec('dw_1_RowFocuschanged',
        { planmon: this.state.focused?.planmon });
      if (data?.items) {
        this.setState({
          dataDetail: data.items,
        });
      } else {
        this.setState({
          dataDetail: [],
        });
      }
      await this.table.current?.update();
      this.table.current?.setFocus(0, 2);
    });
  }

  @action
  async onRowFocusEvent2(item: DetailModel) {
    await this.SS({
      focusedDetail: item,
    });
  }

  @action
  onUpdatedRows(rows: Array<DetailModel>, updatedRows: Array<DetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ dataDetail: rows });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    const warning = await Confirm.show('확인', '이미 계획한 내역이 있습니다. 삭제하고 다시 생성하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;

    const pastYear = parseInt(this.state.year, 10) - 1;

    await api.new({
      year: this.state.year,
      beyear: String(pastYear),
    });
    this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    if (this.endBtn === true) {
      this.onEndBtn();
    } else {
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `계획년도: ${this.state.year}`;
    await api.delete(text, { year: this.state.year }) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
    });
  }

  @action
  async onCopyBtn() {
    const { actionStore: api } = this.props;

    if (!this.state.data.length) {
      ConfirmWarning.show('확인', '먼저 신규버튼을 클릭하여 계획하세요');
      return;
    }
    const warning = await Confirm.show('확인', '현재 선택된 월로 계획금액을 전부 복사하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;


    const data = await api.fxExec('wb_copy',
      {
        planmon: this.state.focused?.planmon,
      });
    if (data.messagebox === '성공적으로 일괄생성되었습니다') this.onRetrieveEvent();
  }

  @action
  async onEndBtn() {
    const { actionStore: api } = this.props;
    this.endBtn = true;

    if (this.updatedRows?.length) {
      this.onSaveEvent();
    }
    if (!this.state.data.length) {
      ConfirmWarning.show('확인', '먼저 계획을 세우세요!');
      return;
    }
    const warning = await Confirm.show('확인', '전 직원에게 목표를 설정 하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;

    await api.fxExec('wb_end',
      {
        year: this.state.year,
      });
    this.endBtn = false;
  }

  render() {
    return (
      <CompanyTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
