import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  OptionBox,
  LabelBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { TopWork } from './TopWork';

/**
 * 화면
 * @window TB_E022
 * @category 상위업무코드등록
 */
export const TopWorkTemplate: React.FC<TemplateProps<TopWork>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
    <FlexLayout isVertical={true}>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <FlexLayout justify="left" weight={1}>
          <LabelBox title="상위부서" size={250} style={{ color: 'var(--color-red)' }}>
            <TextBox
              value={model.kukcd}
              textAlign="center"
              onChange={(value) => setData({ kukcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_PZ008',
                {},
                true,
                (item) => {
                  setData({
                    kukcd: item.cd,
                    kuknm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={model.kuknm}
              textAlign="center"
              onChange={(value) => setData({ kuknm: value })}
              readonly={true}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'kukcd',
            color: 'var(--color-red)',
            text: '상위부서코드',
            width: 7,
            render: (x, rowUpdate, ref) => (
              x.modalCheck === '1'
                ? <TextBox
                  ref={ref}
                  value={x.kukcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ kukcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_PZ008',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        kukcd: item.cd,
                        kuknm: item.cdnm,
                        searchQuery: item.cd,
                      });
                    },
                  )}
                />
                : <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.kukcd}
                </FlexLayout>
            ),
          },
          {
            id: 'kuknm',
            text: '상위부서명',
            width: 7,
          },
          {
            id: 'upflag',
            text: '상위업무코드',
            width: 12,
            render: (x, rowUpdate, ref) => (
              x.modalCheck === '1'
                ? <TextBox
                  ref={ref}
                  value={x.upflag}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ upflag: value })}
                />
                : <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.upflag}
                </FlexLayout>
            ),
          },
          {
            id: 'upflagnm',
            text: '상위업무명',
            width: 19,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                textAlign="left"
                value={x.upflagnm}
                onChange={(value) => rowUpdate({ upflagnm: value })}
              />
            ),
          },
          {
            id: 'useyn',
            text: '사용유무',
            width: 10,
            render: (x, rowUpdate) => (
              <OptionBox
                value={x.useyn}
                data={[
                  { value: '1', remark: '유' },
                  { value: '0', remark: '무' },
                ]}
                onChange={(v) => rowUpdate({ useyn: v.value })}
              />
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 25,
            render: (x, rowUpdate, ref) => (
              <TextBox ref={ref} textAlign="left" value={x.remark} onChange={(value) => rowUpdate({ remark: value })}/>
            ),
          },
        ]}
        data={scope.state.topWorkList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
