import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e075w
 * @category 검사결과현황
 */

export class ResultModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 유무
   *
   * **data type** varchar(1)
   */
  readonly kcflag: string;

  /**
   * 현장명
   *
   * **data type** varchar(13)
   */
  readonly actnm: string;

  /**
   * 점검기종
   *
   * **data type** varchar(5)
   */
  readonly evcd: string;

  /**
   * 호기코드
   *
   * **data type** varchar(5)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(50)
   */
  readonly elno: string;

  /**
   * 계획월
   *
   * **data type** varchar()
   */
  readonly planyear: string;

  /**
   * 순서
   *
   * **data type** varchar()
   */
  readonly seq: string;

  /**
   * 거래처코드
   *
   * **data type** varchar()
   */
  readonly cltcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar()
   */
  readonly actcd: string;

  /**
   * 사양
   *
   * **data type** varchar()
   */
  readonly sizecd: string;

  /**
   * 구분
   *
   * **data type** varchar()
   */
  readonly gubun: string;

  /**
   * 계획일자
   *
   * **data type** varchar()
   */
  readonly plandate: string;

  /**
   * 검사일자
   *
   * **data type** varchar()
   */
  readonly kcdate: string;

  /**
   * 검사자
   *
   * **data type** varchar()
   */
  readonly kcpernm: string;

  /**
   * 검사기관
   *
   * **data type** varchar()
   */
  readonly kcspnm: string;

  /**
   * 부서
   *
   * **data type** varchar()
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar()
   */
  readonly divinm: string;

  /**
   * 담당자
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar()
   */
  readonly pernm: string;

  /**
   * 보조담당
   *
   * **data type** varchar()
   */
  readonly perid2: string;

  /**
   * 보조담당명
   *
   * **data type** varchar()
   */
  readonly pernm2: string;

  /**
   * 결과
   *
   * **data type** varchar()
   */
  readonly result: string;

  /**
   * 모름
   *
   * **data type** varchar()
   */
  readonly result_cd: string;

  /**
   * 검사완료일
   *
   * **data type** varchar()
   */
  readonly kcenddate: string;

  /**
   * 상태
   *
   * **data type** varchar()
   */
  readonly state: string;

  /**
   * 계획구분
   *
   * **data type** varchar()
   */
  readonly planflag: string;

  /**
   * 검사지적갯수
   *
   * **data type** varchar()
   */
  readonly failcnt: string;

  /**
   * 진행
   *
   * **data type** varchar()
   */
  readonly statenm: string;

  /**
   * 비고
   *
   * **data type** varchar()
   */
  readonly remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.kcflag = data.kcflag || '';
    this.actnm = data.actnm || '';
    this.evcd = data.evcd || '';
    this.planyear = data.planyear || '';
    this.seq = data.seq || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.sizecd = data.sizecd || '';
    this.gubun = data.gubun || '';
    this.plandate = data.plandate || '';
    this.kcdate = data.kcdate || '';
    this.kcpernm = data.kcpernm || '';
    this.kcspnm = data.kcspnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.perid2 = data.perid2 || '';
    this.pernm2 = data.pernm2 || '';
    this.result = data.result || '';
    this.result_cd = data.result_cd || '';
    this.elno = data.elno || '';
    this.kcenddate = data.kcenddate || '';
    this.state = data.state || '';
    this.planflag = data.planflag || '';
    this.failcnt = data.failcnt || '';
    this.statenm = data.statenm || '';
    this.remark = data.remark || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
