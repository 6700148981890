import * as React from 'react';
import {
  ComboBox, ComboBoxModel,
  FlexLayout,
  SearchLayout,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  Car,
  CarTabDataStateNames,
  CarTabFocusedStateNames,
  CarTabUpdatesStateNames,
} from './Car';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';


/**
 * 화면
 * @window w_tb_pa105
 * @category 차량관리등록
 */
export const CarTemplate: React.FC<TemplateProps<Car>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout
        isVertical={true}
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <SearchLayout
          size={Global.LAYOUT_SEARCH_HEIGHT_1}
        >
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.divinm}
              onChange={(value) => setData({ divinm: value })}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              onChange={(value) => setData({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout size={350}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="차량번호, 차량소유주, 차명으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span>유종</span>
            <ComboBox
              value={scope.state.kmflag}
              data={scope.state.kmflags?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam || '전체'))}
              onSelect={(option) => scope.setState({ kmflag: option.value })}
            />
          </FlexLayout>
        </SearchLayout>
        <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <TabView
            tabs={scope.tabs}
            focused={scope.state.focusedTab}
            onChange={(tab) => scope.onTabChange(tab)}
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout>
        <FlexLayout weight={3.5}>
          <TableLayout
            ref={scope.table}
            scope={scope}
            header={scope.tabHeaders[scope.tabIndex]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            // @ts-ignore
            data={scope.state[CarTabDataStateNames[scope.tabIndex]]}
            onRowFocusEvent={(item) => setData({
              [CarTabFocusedStateNames[scope.tabIndex]]: item,
            })}
            onChange={(rows, updatedRows) => setData({
              [CarTabDataStateNames[scope.tabIndex]]: rows,
              [CarTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
            })}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
