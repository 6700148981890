import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { MonthContractTemplate } from './MonthContract.template';
import { MonthContractModel } from './MonthContract.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils';
import { Today } from '../../../../utils/time';
import { TableLayout } from '../../../../components';
import { HistoryPopupModel } from './HistoryPopup.model';
import { ConfirmWarning } from '../../../../utils/confirm';

interface MonthContractState {
  actcd: string;
  src: string;
  stmon: string;
  compflag: string;
  gubun: string;
  divicd: string;
  contg: string;
  divinm: string;
  perid: string;
  pernm: string;
  contgubun: string;
  contperid: string;
  contpernm: string;
  wkactcd: string;
  wkactnm: string;
  flag: string;
  searchQuery: string;
  clttype: string;
  data: Array<MonthContractModel>;
  focusedData?: MonthContractModel;
  contgs: Array<any>;
  contgubuns: Array<any>;
  historyFocusedData?: HistoryPopupModel;
  historyData?: Array<HistoryPopupModel>;
  historyModal?: boolean;
  url?: string;
  remark?: string;
  wkactcds: Array<any>;

  // trail
  amt_tot: string;
  beamt_tot: string;
  clscnt_tot: string;
  compcnt_tot: string;
  contgubun01_tot: string;
  contgubun02_tot: string;
  contgubun03_tot: string;
  notcnt_tot: string;
  qty_tot: string;
  beqty_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_e101w_02
 * @category 월 계약변동내역
 */
export class MonthContract extends PageComponent<PageProps, MonthContractState>
  implements PageToolEvents {
  updatedRows?: Array<MonthContractModel>;

  updatedRows2?: Array<HistoryPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  tableHistory: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinityHistory?: InfinityRetrieve;

  url?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01') {
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {
      url: '',
      stmon: Today.yearMon(),
      searchQuery: '',
      compflag: '',
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      pernm: user.pernm,
      perid: user.perid,
      contperid: '',
      contg: '%',
      contgubun: '%',
      wkactcd: '',
      wkactnm: '',
      flag: '',
      data: [],
      historyData: [],
      remark: '',

      // trail
      amt_tot: '0',
      beamt_tot: '0',
      clscnt_tot: '0',
      compcnt_tot: '0',
      contgubun01_tot: '0',
      contgubun02_tot: '0',
      contgubun03_tot: '0',
      notcnt_tot: '0',
      qty_tot: '0',
      beqty_tot: '0',
      total: '0',
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    //
    const { actionStore: api } = this.props;

    let data = [];

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data?.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data?.items });

    data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    this.setState({ wkactcds: data?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
        as_nm: this.state.searchQuery,
        compflag: this.state.compflag,
        divicd: this.state.divicd,
        perid: this.state.perid,
        contperid: this.state.contperid,
        contg: this.state.contg,
        contgubun: this.state.contgubun,
        wkactcd: this.state.wkactcd,
        flag: this.state.flag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new MonthContractModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
        amt_tot: data?.amt_tot || '0',
        beamt_tot: data?.beamt_tot || '0',
        clscnt_tot: data?.clscnt_tot || '0',
        compcnt_tot: data?.compcnt_tot || '0',
        contgubun01_tot: data?.contgubun01_tot || '0',
        contgubun02_tot: data?.contgubun02_tot || '0',
        contgubun03_tot: data?.contgubun03_tot || '0',
        notcnt_tot: data?.notcnt_tot || '0',
        qty_tot: data?.qty_tot || '0',
        beqty_tot: data?.beqty_tot || '0',
      });

      if (this.state.data.length) {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      stmon: this.state.stmon,
      as_nm: this.state.searchQuery,
      compflag: this.state.compflag,
      divicd: this.state.divicd,
      perid: this.state.perid,
      contperid: this.state.contperid,
      contg: this.state.contg,
      contgubun: this.state.contgubun,
      wkactcd: this.state.wkactcd,
      flag: this.state.flag,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      stmon: this.state.stmon,
      as_nm: this.state.searchQuery,
      compflag: this.state.compflag,
      divicd: this.state.divicd,
      perid: this.state.perid,
      contperid: this.state.contperid,
      contg: this.state.contg,
      contgubun: this.state.contgubun,
      wkactcd: this.state.wkactcd,
      flag: this.state.flag,
    });
  }

  @action
  async historyPopupModal(isOpen: boolean) {
    setTimeout(() => this.historyRetrieve(), 50);
    this.setState({ historyModal: isOpen });
  }

  @action
  async historyRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityHistory = new InfinityRetrieve(
      {
        sub: 'w_tb_e101_history',
        actcd: this.state.focusedData?.actcd,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          historyData: [...items],
        });
      },
      async () => {
        await this.SS({ historyData: [] });
        await this.infinityHistory?.retrieveAll();
        await this.tableHistory.current?.update(false);
        await this.tableHistory.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      historyData: [],
    }, async () => {
      await this.infinityHistory?.retrieveAll();
      await this.tableHistory.current?.update(false);
      await this.tableHistory.current?.setFocus(0, 1);
    });
  }

  @action
  async onNewHistoryEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e101_history',
      actcd: this.state.focusedData?.actcd,
    });

    const newModel = new HistoryPopupModel(data, true);

    if (data) {
      this.setState({
        historyData: [...this.state.historyData || [], data],
        historyFocusedData: newModel,
      }, () => this.tableHistory.current?.update(false));
      // @ts-ignore
      this.tableHistory.current?.setFocus(this.state.historyData?.length - 1, 1);
    }
  }

  @action
  async onHistorySaveEvent() {
    const { actionStore: api } = this.props;
    // const params = [{
    //   new: this.state.historyFocusedData?.new,
    //   actcd: this.state.historyFocusedData?.actcd,
    //   actnm: this.state.historyFocusedData?.actnm,
    //   seq: this.state.historyFocusedData?.seq,
    //   perid: this.state.historyFocusedData?.perid,
    //   pernm: this.state.historyFocusedData?.pernm,
    //   hdate: this.state.historyFocusedData?.hdate,
    //   title: this.state.historyFocusedData?.title,
    //   remark: this.state.historyFocusedData?.remark,
    //   wakeflag: '',
    //   wakedate: this.state.historyFocusedData?.wakedate,
    //   okflag: this.state.historyFocusedData?.okflag,
    // }];

    const data = await api.save({
      sub: 'w_tb_e101_history',
      items: this.updatedRows2,
    }, true);
    if (data) {
      this.setState({

      }, () => this.historyRetrieve());
    }
  }

  @action
  async historyDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e101_history',
      actcd: this.state.focusedData?.actcd,
      seq: this.state.historyFocusedData?.seq,
    }) && await this.historyRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async urlRetrieve() {
    const { user } = this.props.publicStore;

    this.setState({
      url: `https://api.elmansoft.com/chart/stick3.php?database=${user.custcd}&window=w_tb_e101w_02&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_actcd=${this.state.focusedData?.actcd}&xcnt=1&tnm0=계약월`,
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onRowFocusEvent(item:MonthContractModel) {
    this.setState({
      focusedData: item,
    }, async () => {
      await this.urlRetrieve();
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onRowFocusEvent2(item:HistoryPopupModel) {
    await this.setState({ historyFocusedData: item, remark: item.remark });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({ historyData: rows2 });
  }

  render() {
    return <MonthContractTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
