import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Status } from '../Status';
import { StatusDateGridHeader } from '../headers/StatusDateGridHeader';

/**
 * 화면 > 탭 A
 * @window w_tb_da451w_01
 * @category 설치견적현황
 */
export const StatusDateTabTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      infinityHandler={scope.infinityDate}
      header={StatusDateGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataDate}
      onRowClick={(row) => scope.onRowClick(row)}
      onRowDoubleClick={() => scope.onClickOpenResist()}
    />
  </FlexLayout>
);
