import { computed } from 'mobx';

/**
 * 상세모델
 * @window w_tb_pb420
 * @category 시간외근무보고서
 */

export class OvertimeDetailModel {
  /**
   * no
   *
   * **data type** varchar()
   */
  readonly seq: string;

  /**
   * 부서
   *
   * **data type** varchar()
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar()
   */
  readonly divinm: string;

  /**
   * 근무자
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   * 근무자
   *
   * **data type** varchar()
   */
  readonly overperid: string;

  /**
   * 근무자명
   *
   * **data type** varchar()
   */
  readonly overpernm: string

  /**
   * 근무자
   *
   * **data type** varchar()
   */
  readonly overrspnm: string;

  /**
   * 근무시작일
   *
   * **data type** varchar()
   */
  readonly stdate: string;

  /**
   * 근무종료일
   *
   * **data type** varchar()
   */
  readonly enddate: string;

  /**
   * 시작시간
   *
   * **data type** varchar()
   */
  readonly sttime: string;

  /**
   * 종료시간
   *
   * **data type** varchar()
   */
  readonly endtime: string;

  /**
   * 근무시간
   *
   * **data type** varchar()
   */
  readonly overtime: string;

  /**
   * 근무사유
   *
   * **data type** varchar()
   */
  readonly remark: string;

  /**
   * 근무시작월
   *
   * **data type** varchar()
   */
  readonly stweekname: string;

  /**
   * 근무종료월
   *
   * **data type** varchar()
   */
  readonly endweekname: string;

  /**
   * 근무시간
   *
   * **data type** varchar()
   */
  readonly overtime_hour: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.seq = data.seq || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.overperid = data.overperid || '';
    this.overpernm = data.overpernm || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.sttime = data.sttime || '';
    this.endtime = data.endtime || '';
    this.overtime = data.overtime || '';
    this.remark = data.remark || '';
    this.perid = data.perid || '';
    this.overrspnm = data.overrspnm || '';
    this.stweekname = data.stweekname || '';
    this.endweekname = data.endweekname || '';
    this.overtime_hour = data.overtime_hour || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
