import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_da052w_08
 * @category 목표대비계획및실적
 */
export class GoalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 성명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 유지보수
   *
   * **data type** number
   */
  planamt11: string;

  /**
   * 유지보수-실적
   *
   * **data type** number
   */
  misamt11: string;

  /**
   * 계획율
   *
   * **data type** number
   */
  planyul11: string;

  /**
   * 실적율
   *
   * **data type** number
   */
  misyul11: string;

  /**
   * 수리공사
   *
   * **data type** number
   */
  planamt12: string;

  /**
   * 수리공사-실적
   *
   * **data type** number
   */
  misamt12: string;

  /**
   * 계획율(부품교체)
   *
   * **data type** number
   */
  planyul12: string;

  /**
   * 실적율(부품교체)
   *
   * **data type** number
   */
  misyul12: string;

  /**
   * 부품교체
   *
   * **data type** number
   */
  planamt13: string;

  /**
   * 부품교체-실적
   *
   * **data type** number
   */
  misamt13: string;

  /**
   * 계획율(리모델링)
   *
   * **data type** number
   */
  planyul13: string;

  /**
   * 실적율(리모델링)
   *
   * **data type** number
   */
  misyul13: string;

  /**
   * 리모델링
   *
   * **data type** number
   */
  planamt15: string;

  /**
   * 리모델링-실적
   *
   * **data type** number
   */
  misamt15: string;

  /**
   * 계획율(총계획금액)
   *
   * **data type** number
   */
  planyul15: string;

  /**
   * 실적율(총실적금액)
   *
   * **data type** number
   */
  misyul15: string;

  /**
   * 총계획금액
   *
   * **data type** number
   */
  tot_planamt: string;

  /**
   * 총실적금액
   *
   * **data type** number
   */
  tot_misamt: string;

  /**
   * 계획율(팀평균)
   *
   * **data type** number
   */
  compute_14: string;

  /**
   * 실적율(팀평균)
   *
   * **data type** number
   */
  compute_6: string;

  /**
   * 실적율
   *
   * **data type** number
   */
  tot_misyul: string;

  /**
   * 계획율
   *
   * **data type** number
   */
  tot_planyul: string;

  /**
   * 합계
   *
   * **data type** number
   */
  totamttot: string;

  /**
   * 합계1
   *
   * **data type** number
   */
  totamt11: string;

  /**
   * 합계2
   *
   * **data type** number
   */
  totamt12: string;

  /**
   * 합계3
   *
   * **data type** number
   */
  totamt13: string;

  /**
   * 합계5
   *
   * **data type** number
   */
  totamt15: string;

  /**
   * 목표금액총합(합계)
   *
   * **data type** number
   */
  ttotamttot: string;

  /**
   *  목표금액총합 (합계1))
   *
   * **data type** number
   */
  ttotamt11: string;

  /**
   *  목표금액총합 (합계2)
   *
   * **data type** number
   */
  ttotamt12: string;

  /**
   * 목표금액총합 (합계3)
   *
   * **data type** number
   */
  ttotamt13: string;

  /**
   * 목표금액총합 (합계5)
   *
   * **data type** number
   */
  ttotamt15: string;

  /**
   * 사용자아이디
   *
   * **data type** char(10)
   */
  perid: string;

  /**
   * 유지보수 계획율
   *
   * **data type** char()
   */
  planyul11_tot: string;

  /**
   * 유지보수 실적율
   *
   * **data type** char()
   */
  misyul11_tot: string;

  /**
   * 수리공사 계획율
   *
   * **data type** char()
   */
  planyul12_tot: string;

  /**
   * 수리공사 실적율
   *
   * **data type** char()
   */
  misyul12_tot: string;

  /**
   * 부품교체 계획율
   *
   * **data type** char()
   */
  planyul13_tot: string;

  /**
   * 부품교체 실적율
   *
   * **data type** char()
   */
  misyul13_tot: string;

  /**
   * 리모델링 계획율
   *
   * **data type** char()
   */
  planyul15_tot: string;

  /**
   * 리모델링 실적율
   *
   * **data type** char()
   */
  misyul15_tot: string;

  /**
   * 총계획금액 계획율
   *
   * **data type** char()
   */
  tot_planyul_tot: string;

  /**
   * 총실적금액 실적율
   *
   * **data type** char()
   */
  tot_misyul_tot: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.totamttot = data.totamttot || '';
    this.totamt11 = data.totamt11 || '';
    this.totamt12 = data.totamt12 || '';
    this.totamt13 = data.totamt13 || '';
    this.totamt15 = data.totamt15 || '';
    this.ttotamttot = data.ttotamttot || '';
    this.ttotamt11 = data.ttotamt11 || '';
    this.ttotamt12 = data.ttotamt12 || '';
    this.ttotamt13 = data.ttotamt13 || '';
    this.ttotamt15 = data.ttotamt15 || '';
    this.pernm = data.pernm || '';
    this.perid = data.perid || '';
    this.divinm = data.divinm || '';
    this.tot_planyul = data.tot_planyul || '';
    this.tot_misyul = data.tot_misyul || '';
    this.planamt11 = data.planamt11 || '';
    this.misamt11 = data.misamt11 || '';
    this.planyul11 = data.planyul11 || '';
    this.misyul11 = data.misyul11 || '';
    this.planamt12 = data.planamt12 || '';
    this.misamt12 = data.misamt12 || '';
    this.planyul12 = data.planyul12 || '';
    this.misyul12 = data.misyul12 || '';
    this.planamt13 = data.planamt13 || '';
    this.misamt13 = data.misamt13 || '';
    this.planyul13 = data.planyul13 || '';
    this.misyul13 = data.misyul13 || '';
    this.planamt15 = data.planamt15 || '';
    this.misamt15 = data.misamt15 || '';
    this.planyul15 = data.planyul15 || '';
    this.misyul15 = data.misyul15 || '';
    this.tot_planamt = data.tot_planamt || '';
    this.tot_misamt = data.tot_misamt || '';
    this.compute_14 = data.compute_14 || '';
    this.compute_6 = data.compute_6 || '';
    this.spjangnm = data.spjangnm || '';
    this.planyul11_tot = data.planyul11_tot || '';
    this.misyul11_tot = data.misyul11_tot || '';
    this.planyul12_tot = data.planyul12_tot || '';
    this.misyul12_tot = data.misyul12_tot || '';
    this.planyul13_tot = data.planyul13_tot || '';
    this.misyul13_tot = data.misyul13_tot || '';
    this.planyul15_tot = data.planyul15_tot || '';
    this.misyul15_tot = data.misyul15_tot || '';
    this.tot_planyul_tot = data.tot_planyul_tot || '';
    this.tot_misyul_tot = data.tot_misyul_tot || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
