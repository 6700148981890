import * as React from 'react';
import { action } from 'mobx';
import { RefObject } from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { FunctionRepository } from '../../../repositories';
import { ModalStore, PublicStore } from '../../../stores';

export interface AddressItem {
  address_name: string;
  old_address_name: string;
  building_name: string;
  main_building_no: string;
  region_1depth_name: string;
  region_2depth_name: string;
  region_3depth_name: string;
  road_name: string;
  sub_building_no: string;
  undergroun_yn: string;
  underground_yn: string;
  x: string;
  y: string;
  zone_no: string;
}

interface AddressButtonState {
  query: string;
  page?: number;
  maxPage?: number;
  data: Array<AddressItem>;
}

interface AddressButtonProps extends ButtonProps {
  publicStore: PublicStore;
  modalStore: ModalStore;
  onChange?: (address: any) => any;
}

export class AddressButton extends React.Component<
  AddressButtonProps,
  AddressButtonState
> {
  postcode: RefObject<HTMLDivElement>;

  constructor(props: AddressButtonProps, context: any) {
    super(props, context);
    this.postcode = React.createRef();
    this.state = {
      query: '',
      data: [],
    };
  }

  @action
  doPostcode() {
    const { onChange } = this.props;

    // @ts-ignore
    new daum.Postcode({
      oncomplete: async (data: any) => {
        try {
          // @ts-ignore
          const { documents } = await this.doSearchDetail(data.address);

          if (documents.length > 0) {
            // eslint-disable-next-line no-param-reassign
            data.x = documents[0].road_address?.x || documents[0].address?.x;
            // eslint-disable-next-line no-param-reassign
            data.y = documents[0].road_address?.y || documents[0].address?.y;
          }
        } finally {
          onChange && onChange(data);
          this.props.modalStore.closeAddress();
        }
      },
      width: '100%',
      height: '100%',
      maxSuggestItems: 5,
    }).embed(this.postcode.current);
  }

  @action
  async doSearchDetail(address: string): Promise<any> {
    const { publicStore } = this.props;
    const { status, data } = await FunctionRepository.address(
      await publicStore.makeParams({
        query: address,
        size: 1,
        page: this.state.page || 1,
      }),
    );

    if (status === 200) {
      return data;
    }
    return {
      documents: [],
    };
  }

  @action
  openModal() {
    this.props.modalStore.openAddressModal(this);
  }

  @action
  onSelect(item: any) {
    this.props.onChange && this.props.onChange(item);
    this.props.modalStore.closeAddress();
  }

  render() {
    return (
      <React.Fragment>
        <Button
          {...this.props}
          onClick={() => this.openModal()}
        />
      </React.Fragment>
    );
  }
}
