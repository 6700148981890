import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout, FormatNumberTextBox,
  FormatTextBox,
  LabelBox,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Standard } from './Standard';


/**
 * 화면
 * @window w_tb_e101_aver
 * @category 표준유지관리비
 */
export const StandardTemplate: React.FC<TemplateProps<Standard>> = ({
  scope,
  update,
}) => {
  const model = scope.state.discountData;
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <FormatTextBox
            value={scope.state.searchQuery}
            textAlign="center"
            format="####"
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.searchQuery}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  setData({ searchQuery: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.searchQuery}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  setData({ searchQuery: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout weight={2}>
          <span>건물구분</span>
          <ComboBox
            value={scope.state.searchContbild}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.contbilds?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
            onSelect={(option) => setData({ searchContbild: option.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="2~3대 할인금액">
            <FormatNumberTextBox
              readonly={true}
              value={model.saleamt23}
              textAlign="right"
            />
          </LabelBox>
          <LabelBox title="4~5대 할인금액">
            <FormatNumberTextBox
              readonly={true}
              value={model.saleamt45}
              textAlign="right"
            />
          </LabelBox>
          <LabelBox title="6~7대 할인금액">
            <FormatNumberTextBox
              readonly={true}
              value={model.saleamt67}
              textAlign="right"
            />
          </LabelBox>
          <LabelBox title="8대이상 할인금액">
            <FormatNumberTextBox
              readonly={true}
              value={model.saleamt8}
              textAlign="right"
            />
          </LabelBox>
        </FlexLayout>

        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'evercd',
              text: '구분코드',
              width: 5,
            },
            {
              id: 'evernm',
              text: '구분명',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.evernm}
                  onChange={(value) => rowUpdate({ evernm: value })}
                />
              ),
            },
            {
              id: 'number',
              text: '기준층',
              width: 5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.number}{x.numflag === '1' ? '층' : 'm'}
                </FlexLayout>
              ),
            },
            {
              id: 'everamt',
              text: '일반유지관리비',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.everamt}
                  onChange={(value) => rowUpdate({ everamt: value })}
                />
              ),
            },
            {
              id: 'everamt_tot',
              text: '일반유지관리비',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.everamt_tot}
                  onChange={(value) => rowUpdate({ everamt_tot: value })}
                />
              ),
            },
            {
              id: 'addamt',
              text: '일반체증요금',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.addamt}
                  onChange={(value) => rowUpdate({ addamt: value })}
                />
              ),
            },
            {
              id: 'addamt_tot',
              text: '휴일야간체증요금',
              width: 10,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.addamt_tot}
                  onChange={(value) => rowUpdate({ addamt_tot: value })}
                />
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
