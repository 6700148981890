import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e042
 * @category 사전점검항목등록
 */
export class EarlyInspectionModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 종류
   *
   * **data type** varchar(2)
   */
  evcd: string;

  /**
   * 구분코드
   *
   * **data type** varchar(2)
   */
  gresultcd: string;

  /**
   * 구분명
   *
   * **data type** varchar(100)
   */
  gresultnm: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.evcd = data.evcd || '';
    this.gresultcd = data.gresultcd || '';
    this.gresultnm = data.gresultnm || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
