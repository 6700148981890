import { TLHF } from '../../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatTextBox,
  TextBox,
} from '../../../../../components';
import { Report } from '../Report';
import ReportDetailModel from '../models/ReportDetailModel';
import { SearchBinding } from '../../../../../models';


const ComboProcess = [
  { key: '0', value: '대기', color: 'var(--color-red)' },
  { key: '1', value: '진행중', color: 'inherit' },
  { key: '2', value: '완료', color: 'var(--color-blue)' },
];


/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_b035
 * @category 샘플페이지
 */
export const ReportDetailTableHeader: TLHF<ReportDetailModel, Report> = (scope) => ([
  {
    id: 'seq',
    text: '순서',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '호기코드',
    color: 'var(--color-red)',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.equpcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ equpcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: scope.state.content?.actcd },
          true,
          (item) => {
            rowUpdate({
              equpcd: item.cd,
              equpnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 100,
  },
  {
    id: 'sizecd',
    text: '사양',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.sizecd}
        onChange={(v) => rowUpdate({ sizecd: v })}
      />
    ),
  },
  {
    id: 'wkcd',
    text: '작업구분',
    width: 200,
    render: (x, rowUpdate) => (
      <ComboBox
        textAlign="left"
        value={x.wkcd}
        data={scope.wkcds.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
        onSelect={(option) => rowUpdate({ wkcd: option.value })}
      />
    ),
  },
  {
    id: 'state',
    text: '상태',
    width: 100,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.state);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.state}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ state: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'sttime',
    text: '시작시간',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="##:##"
        value={x.sttime}
        onChange={(v) => rowUpdate({ sttime: v })}
      />
    ),
  },
  {
    id: 'endtime',
    text: '끝시간',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FormatTextBox
        ref={ref}
        textAlign="center"
        format="##:##"
        value={x.endtime}
        onChange={(v) => rowUpdate({ endtime: v })}
      />
    ),
  },
  {
    id: 'remark',
    text: '특이사항',
    width: 350,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
