import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { ExpenseModel } from '../models';
import { Project } from '../Project';

// 경비사용내역
const Expense: GridLayoutHeader<ExpenseModel, Project>[] = [
  {
    id: 'mijdate',
    text: '매입일자',
    width: 8,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.mijdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijnum',
    text: '번호',
    width: 5,
  },
  {
    id: 'seq',
    text: '순번',
    width: 5,
  },
  {
    id: 'acccd',
    text: '계정과목',
    width: 8,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.acccds?.filter((y) => y.acccd === x.acccd)[0]?.accnm}
      </FlexLayout>
    ),
  },
  {
    id: 'mijcltnm',
    text: '지급처명',
    width: 16,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.mijcltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 16,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 6,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 5,
  },
  {
    id: 'qty',
    text: '수량',
    width: 5,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_qty_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '매입가액',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_samt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 8,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_tamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '합계금액',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tab_mijamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
  },
];

export default Expense;
