import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { Sum } from '../../../../../utils/array';
import { SupplyStatement } from '../SupplyStatement';
import { ActModel } from '../models';
import { Format } from '../../../../../utils/string';

const Act: GridLayoutHeader<ActModel, SupplyStatement>[] = [
  {
    id: 'actdivinm',
    text: '부서명',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.actdivinm}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.actdivinm}
      </FlexLayout>
    ),
  },
  {
    id: 'actpernm',
    text: '현장담당자명',
    width: 100,
  },
  {
    id: 'deldate',
    text: '불출일자',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total} 건
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumDelcnt = Sum.groupLength(data, 'deldate', x, ['actdivinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumDelcnt} 건
        </FlexLayout>
      );
    },
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.deldate}
      />
    ),
  },
  {
    id: 'delnum',
    text: '번호',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.delnum}
      </FlexLayout>
    ),
  },
  {
    id: 'delseq',
    text: '순번',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.delseq}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '수령자',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.myn === '1' ? '무상' : '유상'}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 150,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'pserial',
    text: '품목시리얼번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pserial}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumQty = Sum.byGroup(data, 'qty', x, ['actdivinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumQty}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'banqty',
    text: '반납수량',
    width: 70,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.banqty_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBanqty = Sum.byGroup(data, 'banqty', x, ['actdivinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumBanqty}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.balqty}
      </FlexLayout>
    ),
  },
  {
    id: 'projnm',
    text: '프로젝트',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.projnm}
      </FlexLayout>
    ),
  },
  {
    id: 'actdivinm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.actdivinm,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '동호기명',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.uamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];
export default Act;
