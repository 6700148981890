import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import {
  BreakAnalysisAll,
  BreakAnalysisAllTabDataStateNames,
  BreakAnalysisAllTabId,
} from './BreakAnalysisAll';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8 } from '../../../../utils/time';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TabView } from '../../../../components/layout/TabView';
import { ComboBox, ComboBoxModel, IFrame } from '../../../../components';

/**
 * 메인화면
 * @window w_tb_e411w_07
 * @category 고장분석현황[전체]
 */
export const BreakAnalysisAllTemplate: React.FC<TemplateProps<BreakAnalysisAll>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={500}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder={scope.state.holderName}
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      isSub={true}
    >
      <FlexLayout size={350}>
        <span style={{ marginRight: 32 }}>처리</span>
        <ComboBox
          value={scope.state.gubun}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '처리' },
            { value: '2', remark: '미처리' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ gubun: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={300}>
        <span style={{
          marginLeft: 55,
          color: 'var(--color-red)',
        }}>부서</span>
        <TextBox
          textAlign="center"
          value={scope.state.divicd === '%' ? '' : scope.state.divicd}
          onChange={(value) => scope.setState({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />

        <TextBox
          value={scope.state.divinm}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={225}>
        <span
          style={{
            color: 'var(--color-red)',
          }}>
          담당
        </span>
        <TextBox
          value={scope.state.perid === '%' ? '' : scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.pernm}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <TabView
        tabs={scope.tabs}
        focused={scope.state.focusedTab}
        onChange={(tab) => scope.onTabChange(tab)}
      />
    </FlexLayout>
    <FlexLayout>
      <GridLayout
        scope={scope}
        header={scope.tabHeaders[scope.tabIndex]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        // @ts-ignore
        data={scope.state[BreakAnalysisAllTabDataStateNames[scope.tabIndex]] || [] }
      />
      {(scope.tabIndex === BreakAnalysisAllTabId.Part) && <FlexLayout>
          <IFrame src={scope.state.url} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Content) && <FlexLayout>
          <IFrame src={scope.state.url2} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Factors) && <FlexLayout>
          <IFrame src={scope.state.url3} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Cause) && <FlexLayout>
          <IFrame src={scope.state.url4} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Process) && <FlexLayout>
          <IFrame src={scope.state.url5} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Type) && <FlexLayout>
          <IFrame src={scope.state.url6} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Floor) && <FlexLayout>
          <IFrame src={scope.state.url7} />
      </FlexLayout>}
      {(scope.tabIndex === BreakAnalysisAllTabId.Area) && <FlexLayout>
          <IFrame src={scope.state.url8} />
      </FlexLayout>}
    </FlexLayout>
  </FlexLayout>

);
