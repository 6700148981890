/**
 * 모델
 * @window w_tb_e601w_sulchi_02
 * @category 설치수금현황
 */
export default class CollectModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 발주금액
   *
   * **data type** varchar(8)
   */
  readonly balamt: string;

  /**
   * 발주거래처 코드
   *
   * **data type** varchar(8)
   */
  readonly balcltcd: string;

  /**
   * 발주거래처명
   *
   * **data type** varchar(8)
   */
  readonly balcltnm: string;

  /**
   * 발주일자
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 결제일자
   *
   * **data type** varchar(8)
   */
  readonly bilddate: string;

  /**
   * 결제월
   *
   * **data type** varchar(8)
   */
  readonly bildmon: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 발주발생여부
   *
   * **data type** varchar(8)
   */
  readonly compbook: string;

  /**
   * 계약금액
   *
   * **data type** varchar(8)
   */
  readonly contamt: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 계약월
   *
   * **data type** varchar(8)
   */
  readonly contmon: string;

  /**
   * 계약상태
   *
   * **data type** varchar(8)
   */
  readonly contstate: string;

  /**
   * 종료금
   *
   * **data type** varchar(8)
   */
  readonly endamt: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 종료월
   *
   * **data type** varchar(8)
   */
  readonly endmon: string;

  /**
   * 미수금액
   *
   * **data type** varchar(8)
   */
  readonly ex_mijamt: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   * 작성일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 작성자코드
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 출하예정일
   *
   * **data type** varchar(8)
   */
  readonly ipsundate: string;

  /**
   * 잔금
   *
   * **data type** varchar(8)
   */
  readonly jamt: string;

  /**
   * 제어반
   *
   * **data type** varchar(8)
   */
  readonly jeoban: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 중도금
   *
   * **data type** varchar(8)
   */
  readonly midamt: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly mijamt: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly misamt: string;

  /**
   * 검사일여부
   *
   * **data type** varchar(8)
   */
  readonly okbook: string;

  /**
   * 완료여부
   *
   * **data type** varchar(8)
   */
  readonly okflag: string;

  /**
   * 사원명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 작업자코드
   *
   * **data type** varchar(8)
   */
  readonly pperid: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 댓수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 받은금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly scoption: string;

  /**
   * 설치작업자명
   *
   * **data type** varchar(8)
   */
  readonly scpernm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly sizecd: string;

  /**
   * 사용금액
   *
   * **data type** varchar(8)
   */
  readonly sndamt: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly spjangcd2: string;

  /**
   * 선수금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.balamt = data.balamt || '';
    this.balcltcd = data.balcltcd || '';
    this.balcltnm = data.balcltnm || '';
    this.baldate = data.baldate || '';
    this.bilddate = data.bilddate || '';
    this.bildmon = data.bildmon || '';
    this.cltcd = data.cltcd || '';
    this.compbook = data.compbook || '';
    this.contamt = data.contamt || '';
    this.contdate = data.contdate || '';
    this.contmon = data.contmon || '';
    this.contstate = data.contstate || '';
    this.endamt = data.endamt || '';
    this.enddate = data.enddate || '';
    this.endmon = data.endmon || '';
    this.ex_mijamt = data.ex_mijamt || '';
    this.iamt = data.iamt || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.ipsundate = data.ipsundate || '';
    this.jamt = data.jamt || '';
    this.jeoban = data.jeoban || '';
    this.kcdate = data.kcdate || '';
    this.midamt = data.midamt || '';
    this.mijamt = data.mijamt || '';
    this.misamt = data.misamt || '';
    this.okbook = data.okbook || '';
    this.okflag = data.okflag || '';
    this.pernm = data.pernm || '';
    this.pperid = data.pperid || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.qty = data.qty || '';
    this.rcvamt = data.rcvamt || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.scactnm = data.scactnm || '';
    this.scoption = data.scoption || '';
    this.scpernm = data.scpernm || '';
    this.sizecd = data.sizecd || '';
    this.sndamt = data.sndamt || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangcd2 = data.spjangcd2 || '';
    this.stamt = data.stamt || '';
    this.state = data.state || '';
    this.stdate = data.stdate || '';
    this.tamt = data.tamt || '';
  }
}
