/**
 * 모델
 * @window w_input_da023w_02
 * @category 전월대비유지보수체크
 */
import { computed } from 'mobx';

export class LastMonthModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발행구분
   *
   * **data type** varchar(1)
   */
  billgubun: string;

  /**
   * 년월
   *
   * **data type** char(8)
   */
  mismon: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd1: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 전월금액
   *
   * **data type** number
   */
  beamt: string;

  /**
   * 당월금액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 계산서구분
   *
   * **data type** varchar(2)
   */
  billkind: string;

  /**
   * 예전계산서구분
   *
   * **data type** varchar(2)
   */
  bebillkind: string;

  /**
   * 사유
   *
   * **data type** varchar(205)
   */
  sayoo: string;

  /**
   * 계약구분
   *
   * **data type** varchar(205)
   */
  contgnm: string;

  /**
   * 청구주기
   *
   * **data type** varchar(205)
   */
  deljuginm: string;

  /**
   * 차액
   *
   * **data type** number
   */
  resuamt: string;

  /**
   * 상태
   *
   * **data type** varchar(5)
   */
  state: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.billgubun = data.billgubun || '';
    this.resuamt = data.resuamt || '';
    this.contgnm = data.contgnm || '';
    this.deljuginm = data.deljuginm || '';
    this.state = data.state || '';
    this.mismon = data.mismon || '';
    this.actcd1 = data.actcd1 || '';
    this.actnm = data.actnm || '';
    this.beamt = data.beamt || '';
    this.misamt = data.misamt || '';
    this.billkind = data.billkind || '';
    this.bebillkind = data.bebillkind || '';
    this.sayoo = data.sayoo || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
