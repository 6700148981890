import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  ComboBox,
  ComboBoxModel,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchBinding } from '../../../../models/common';
import { DepartmentCode } from './DepartmentCode';

const divicds = [
  { value: 'AA', remark: '일반' },
  { value: 'BB', remark: '도소매' },
  { value: 'CC', remark: '제조' },
  { value: 'DA', remark: '건설-보수' },
  { value: 'DD', remark: '건설-분양' },
];

/**
 * 화면
 * @window w_jc002
 * @category 부서코드
 */
export const DepartmentCodeTemplate: React.FC<TemplateProps<DepartmentCode>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'depart',
          text: '업종코드',
          width: 10,
          render: (x, rowUpdate) => (
            x.newCount !== '1'
              ? <FlexLayout
                justify="center"
                align="center">
                {divicds.filter((y) => y.value === x.divicd.substr(0, 2))[0]?.remark}
              </FlexLayout>
              : <ComboBox
                value={x.divicd.substr(0, 2)}
                data={[
                  { value: 'AA', remark: '일반' },
                  { value: 'BB', remark: '도소매' },
                  { value: 'CC', remark: '제조' },
                  { value: 'DA', remark: '건설-보수' },
                  { value: 'DB', remark: '건설-분양' },
                  // eslint-disable-next-line no-shadow
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => rowUpdate({ divicd: option.value })}
              />
          ),
        },
        {
          id: 'divicd',
          text: '부서코드',
          width: 10,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              readonly={x.newCount !== '1'}
              transparent={true}
              ref={ref}
              value={x.divicd}
              onChange={(value) => rowUpdate({ divicd: value })} />
          ),
        },
        {
          id: 'divinm',
          text: '부서명',
          width: 17,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.divinm}
              onChange={(value) => rowUpdate({ divinm: value })} />
          ),
        },
        {
          id: 'kukcd',
          text: '상위부서코드',
          color: 'var(--color-red)',
          width: 8,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="center"
              value={x.kukcd}
              onChange={(value) => rowUpdate({ kukcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_PZ008',
                {},
                true,
                (item) => {
                  rowUpdate({ kukcd: item.cd, kuknm: item.cdnm });
                },
              )}
            />
          ),
        },
        {
          id: 'kuknm',
          text: '상위부서명',
          width: 17,
        },
        {
          id: 'prtorder',
          text: '출력순서',
          width: 5,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.prtorder}
              onChange={(value) => rowUpdate({ prtorder: value })} />
          ),
        },
        {
          id: 'divilv',
          text: 'LEVEL',
          width: 5,
          render: (x, rowUpdate, ref) => (
            <TextBox
              textAlign="center"
              ref={ref}
              value={x.divilv}
              onChange={(value) => rowUpdate({ divilv: value })} />
          ),
        },
        {
          id: 'useyn',
          text: '사용여부',
          width: 5,
          render: (x, rowUpdate) => (
            <CheckBox
              value={x.useyn === '1'}
              onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'dz_dept_cd',
          text: '더존그룹연동코드',
          isHidden: scope.state.dz_dept_cd === '1',
          width: 10,
        },
      ]}
      data={scope.state.data}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
