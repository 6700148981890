import * as React from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import { observer } from 'mobx-react';
import { AiFillFile, FaFolder, FaFolderOpen } from 'react-icons/all';
import { MenuModel } from '../../../models';
import style from './SideMenu.module.scss';
import { JoinClassName } from '../../../utils/string';

interface SideMenuItemActions {
  onSelect: (menu: MenuModel) => void;
}

interface SideMenuItemProps extends SideMenuItemActions {
  menu: MenuModel;
  className?: string;
  isFavorite?: boolean;
}

const getChildClassName = (level: string) => {
  switch (level) {
    case '2':
      return style.first;
    case '3':
      return style.secondary;
    case '4':
      return style.tertiary;
    case '5':
      return style.quaternary;
    default:
      return style.quaternary;
  }
};

@observer
export class SideMenuItem extends React.Component<SideMenuItemProps> {
  render() {
    const {
      menu,
      onSelect,
      className,
      isFavorite,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className={JoinClassName.make([
            style.menu,
            className,
            menu.isEnabled ? style.open : false,
            getChildClassName(isFavorite ? '3' : menu!.level!),
          ])}
          onClick={() => onSelect(menu)}
        >
          {menu.children && menu.children.length > 0 ? (
            parseInt(menu.level || '0', 10) > 2 ? (
              menu.isEnabled ? <FaFolderOpen className={style.colorClose} /> : <FaFolder className={style.colorOpen} />
            ) : (
              <IoMdArrowDropright
                className={menu.isEnabled ? style.active : undefined}
              />
            )
          ) : (
            <AiFillFile />
          )}
          <p>{menu.text}</p>
        </div>
        {menu.isEnabled
          && menu.children?.map((x) => (
            <SideMenuItem
              key={x.id}
              className={style.open}
              menu={x}
              onSelect={(selected) => onSelect(selected)}
            />
          ))}
      </React.Fragment>
    );
  }
}
