import { TextBox, CheckBox, FlexLayout } from '../../../../../components';
import { TableLayoutHeader } from '../../../../../constants';
import { EnrollmentUnitModel } from '../models';
import { Enrollment } from '../Enrollment';


// 호기등록
const Unit: TableLayoutHeader<EnrollmentUnitModel, Enrollment>[] = [
  {
    id: 'equpcd',
    text: '코드',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        대수 {scope?.state.units?.length}대
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.equpcd}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 30,
    trail: (_, scope) => {
      const contynCheck = scope?.state.units?.filter((y) => y.contyn === '1');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          계약 {contynCheck?.length} 대
        </FlexLayout>
      );
    },
    render: (x, _, ref) => (
      <TextBox
        ref={ref}
        value={x.equpnm}
        readonly={true}
        transparent={true}
        textAlign="left"
      />
    ),
  },
  {
    id: 'contyn',
    text: '계약',
    width: 10,
    render: (x, rowUpdate, ref, scope) => (
      <CheckBox
        ref={ref}
        value={x.contyn === '1'}
        onChange={(value) => scope?.dw_3_itemChanged(value, x, rowUpdate, 'contyn')}
      />
    ),
  },
];

export default Unit;
