import axios from 'axios/index';
import { AuthModel } from '../models';

class AuthRepository {
  URL: string;

  constructor() {
    this.URL = `${process.env.REACT_APP_API_AUTH_HOST}`;
  }

  login(params: AuthModel) {
    return axios.post(`${this.URL}/login`, params, {
      timeout: 3000,
    });
  }

  check(params: any) {
    return axios.post(`${this.URL}/permission/access`, params, {
      timeout: 3000,
    });
  }
}

export default new AuthRepository();
