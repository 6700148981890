import * as React from 'react';
import { FiUpload, FiTrash } from 'react-icons/fi';
import {
  LayoutTitle,
  FlexLayout,
  LabelBox,
  TextBox,
  OptionBox,
  ComboBox,
  ImageView,
  Button,
  LoaderButton,
  TableLayout,
  ModalView,
  FormatTextBox, ComboBoxModel,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { ConfirmFail, ConfirmSuccess } from '../../../../utils/confirm';
import {
  BoardTimeTypes,
  BoardWindowTypeNames,
  Cust,
  CustImages,
} from './Cust';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_xa011
 * @category 회사정보등록
 */
export const CustTemplate: React.FC<TemplateProps<Cust>> = ({
  scope,
  update,
}) => (
  <FlexLayout>
    <FlexLayout isVertical={true} weight={3}>
      <LayoutTitle>기본 정보</LayoutTitle>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="회사코드" isNeed={true}>
          <TextBox
            textAlign="center"
            value={scope.state.data?.custcd}
            onChange={(value) => update!({ custcd: value })}
            readonly={true}
          />
        </LabelBox>

        <LabelBox title="회사명" weight={2}>
          <TextBox
            value={scope.state.data?.custnm}
            onChange={(value) => update!({ custnm: value })}
          />
        </LabelBox>

        <LabelBox title="대표자명">
          <TextBox
            textAlign="center"
            value={scope.state.data?.agnernm}
            onChange={(value) => update!({ agnernm: value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="회사구분">
          <OptionBox
            value={scope.state.data?.custperclsf}
            data={[
              { value: '1', remark: '법인' },
              { value: '8', remark: '개인' },
            ]}
            onChange={(item) => update!({ custperclsf: item.value })}
          />
          <OptionBox
            value={scope.state.data?.nationyn}
            data={[
              { value: '1', remark: '내국' },
              { value: '2', remark: '외국' },
            ]}
            onChange={(item) => update!({ nationyn: item.value })}
          />
          <OptionBox
            value={scope.state.data?.busineyn}
            data={[
              { value: '1', remark: '중소' },
              { value: '2', remark: '일반' },
            ]}
            onChange={(item) => update!({ busineyn: item.value })}
          />
          <OptionBox
            value={scope.state.data?.commeryn}
            data={[
              { value: '1', remark: '영리' },
              { value: '0', remark: '비영리' },
            ]}
            onChange={(item) => update!({ commeryn: item.value })}
          />
        </LabelBox>

        <LabelBox title="회사문서코드" size={250}>
          <TextBox
            textAlign="center"
            value={scope.state.data?.papernum}
            onChange={(value) => update!({ papernum: value })}

          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="국가승강기 정보센터 회사아이디 / 비번">
          <TextBox
            value={scope.state.data?.gosiid}
            onChange={(value) => update!({ gosiid: value })}
          />
          <TextBox
            textAlign="center"
            value={scope.state.data?.gosipasswd}
            type="password"
            onChange={(value) => update!({ gosipasswd: value })}
          />
        </LabelBox>

        <LabelBox title="점검수신담당자" style={{ color: 'var(--color-red)' }}>
          <TextBox
            value={scope.state.data?.perid}
            textAlign="center"
            onChange={(value) => update!({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                update!({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
              },
            )}>
          </TextBox>
          <TextBox
            value={scope.state.data?.pernm}
            onChange={(value) => update!({ pernm: value })}
            readonly={true}
          />
        </LabelBox>

        <LabelBox title="장기미수매출생성제외">
          <OptionBox
            value={scope.state.data?.longmisskip}
            data={[
              { value: '1', remark: '제외' },
              { value: '0', remark: '생성' },
            ]}
            onChange={(item) => update!({ longmisskip: item.value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="금액표기(계약,매입)-보수팀">
          <OptionBox
            value={scope.state.data?.contflag}
            data={[
              { value: '1', remark: '표기' },
              { value: '0', remark: '미표기' },
            ]}
            onChange={(item) => update!({ contflag: item.value })}
          />
        </LabelBox>

        <LabelBox title="고장알림타입(보수팀)">
          <OptionBox
            value={scope.state.data?.pushtype}
            data={[
              { value: '0', remark: '전직원' },
              { value: '1', remark: '부서' },
            ]}
            onChange={(item) => update!({ pushtype: item.value })}
          />
        </LabelBox>

        <LabelBox title="어플GPS사용">
          <ComboBox
            value={scope.state.data?.notgps}
            data={[
              { value: '1', remark: '미사용' },
              { value: '2', remark: '사용(상시)' },
              { value: '3', remark: '사용(사용시)' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(item) => update!({ notgps: item.value })}
          />
        </LabelBox>

        <LabelBox title="GPS위치표시">
          <OptionBox
            value={scope.state.data?.allgps}
            data={[
              { value: '1', remark: '전직원' },
              { value: '0', remark: '보수팀' },
            ]}
            onChange={(item) => update!({ allgps: item.value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="급여생성타입">
          <ComboBox
            value={scope.state.data?.paytype}
            data={[
              { value: '0', remark: '항목별생성(기본급포함)' },
              { value: '1', remark: '항목별생성' },
              { value: '2', remark: '당직시간계산' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(item) => update!({ paytype: item.value })}
          />
        </LabelBox>

        <LabelBox title="점검시간">
          <FormatTextBox
            textAlign="center"
            format="##:##"
            value={scope.state.data?.e035_time}
            onChange={(value) => update!({ e035_time: value })}
          />
        </LabelBox>

        <LabelBox title="점검텀시간">
          <FormatTextBox
            textAlign="center"
            format="##:##"
            value={scope.state.data?.e035_traveltime}
            onChange={(value) => update!({ e035_traveltime: value })}
          />
        </LabelBox>

        <LabelBox title="제품최근단가적용">
          <OptionBox
            value={scope.state.data?.pcode_uamt}
            data={[
              { value: '1', remark: '적용' },
              { value: '0', remark: '미적용' },
            ]}
            onChange={(item) => update!({ pcode_uamt: item.value })}
          />
        </LabelBox>

        <LabelBox title="출퇴근시 위치확인">
          <OptionBox
            value={scope.state.data?.notgps_commute}
            data={[
              { value: '1', remark: '사용' },
              { value: '0', remark: '미사용' },
            ]}
            onChange={(item) => update!({ notgps_commute: item.value })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="회사홈페이지"
          footer={<Button
            isMini={true}
            onClick={() => scope.toggleSettingsModal(true)}
          >
            전광판 시간 설정
          </Button>}
        >
          <TextBox
            value={scope.state.data?.homepage}
            onChange={(value) => update!({ homepage: value })}
          />
        </LabelBox>

        <LabelBox title="다른연동사용(위하고)" weight={0.4}>
          <OptionBox
            value={scope.state.data?.dz_flag}
            data={[
              { value: '1', remark: '사용' },
              { value: '0', remark: '미사용' },
            ]}
            onChange={(item) => update!({ dz_flag: item.value })}
          />
        </LabelBox>

        <LabelBox title="은행연동" weight={0.4}>
          <Button onClick={() => {
            if (scope.state.data?.bankda_flag === '1') {
              ConfirmFail.show('오류', '이미 연동되어 있습니다.');
            } else {
              scope.b_bankda && scope.b_bankda();
            }
          }}>연동사용</Button>
          <div
            style={{
              color:
                scope.state.data?.bankda_flag === '1'
                  ? 'var(--color-blue)'
                  : 'var(--color-red)',
            }}
          >
            {scope.state.data?.bankda_flag === '1' ? '사용' : '미사용'}
          </div>
        </LabelBox>
      </FlexLayout>

      <FlexLayout>
        <LabelBox title="견적서 타이틀명">
          <TextBox
            value={scope.state.data?.e451text}
            onChange={(value) => update!({ e451text: value })}
            isMultiline={true}
          />
        </LabelBox>

        <LabelBox title="견적서 하단 특이사항명">
          <TextBox
            value={scope.state.data?.e451remark}
            onChange={(value) => update!({ e451remark: value })}
            isMultiline={true}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout>
        <LabelBox title="이메일 하단 소갯말 (강조를 원하는 부분은 각 시작, 끝에'를 넣어주세요)">
          <TextBox
            value={scope.state.data?.email_bottom}
            onChange={(value) => update!({ email_bottom: value })}
            isMultiline={true}
          />
        </LabelBox>
      </FlexLayout>
    </FlexLayout>

    <FlexLayout isVertical={true} weight={2}>
      <FlexLayout>
        <FlexLayout isVertical={true}>
          <LayoutTitle>회사마크등록</LayoutTitle>

          <ImageView
            src={scope.state.img_cust}
            footer={
              <React.Fragment>
                <LoaderButton
                  isFull={true}
                  onClick={() => scope.imageSelect(CustImages.CUST)}
                  percent={scope.state.percentImageCust}
                >
                  <FiUpload />
                  <span>등록</span>
                </LoaderButton>

                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.imageDelete(CustImages.CUST)}
                >
                  <FiTrash />
                  <span>삭제</span>
                </Button>
              </React.Fragment>
            }
          />
        </FlexLayout>
        <FlexLayout isVertical={true}>
          <LayoutTitle>회사직인등록</LayoutTitle>

          <ImageView
            src={scope.state.img_seal}
            footer={
              <React.Fragment>
                <LoaderButton
                  isFull={true}
                  onClick={() => scope.imageSelect(CustImages.SEAL)}
                  percent={scope.state.percentImageSeal}
                >
                  <FiUpload />
                  <span>등록</span>
                </LoaderButton>

                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.imageDelete(CustImages.SEAL)}
                >
                  <FiTrash />
                  <span>삭제</span>
                </Button>
              </React.Fragment>
            }
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout isVertical={true}>
        <LayoutTitle>회사마크등록(상호명포함마크)</LayoutTitle>

        <ImageView
          src={scope.state.img_e018_1_pic}
          footer={
            <React.Fragment>
              <LoaderButton
                isFull={true}
                onClick={() => scope.imageSelect(CustImages.E018_1_PIC)}
                percent={scope.state.percentImageE018}
              >
                <FiUpload />
                <span>등록</span>
              </LoaderButton>

              <Button
                isFull={true}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.imageDelete(CustImages.E018_1_PIC)}
              >
                <FiTrash />
                <span>삭제</span>
              </Button>
            </React.Fragment>
          }
        />
      </FlexLayout>
    </FlexLayout>

    {/* [전광판 시간 설정 모달] */}
    <ModalView
      isVisible={scope.state.modal}
      onClose={() => scope.toggleSettingsModal(false)}
    >
      <FlexLayout style={{ padding: 8 }} isVertical={true}>
        <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1} style={{ color: 'var(--color-blue)', padding: 10 }}>
          <div>* 전광판 시간별 셋팅 </div>
          <FlexLayout justify={'right'}>
            <Button
              style={{ height: '25px', width: '60px' }}
              onClick={() => scope.new_time(BoardTimeTypes.TIME)}
            >
              신규
            </Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              onClick={async () => {
                if (await scope.save_time(BoardTimeTypes.TIME)) {
                  ConfirmSuccess.show('성공', '저장되었습니다.');
                } else {
                  ConfirmFail.show('실패', '저장에 실패하였습니다.');
                }
              }}
            >
              저장
            </Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.delete_time(BoardTimeTypes.TIME)}
            >
              삭제
            </Button>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout size={200}>
          <TableLayout
            ref={scope.tableTime}
            header={[
              {
                id: 'seq',
                text: '순서',
                width: 10,
              },
              {
                id: 'hh',
                text: '시 : 분 : 초',
                width: 50,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    textAlign="center"
                    style={{ fontSize: 12 }}
                    format="##:##:##"
                    ref={ref}
                    value={`${x.hh.padStart(2, '0')}${x.mm}${x.ss}`}
                    onChange={(value) => rowUpdate({
                      hh: value.substr(0, 2),
                      mm: value.substr(2, 2),
                      ss: value.substr(4, 2),
                    })}
                  />
                ),
              },
              {
                id: 'window',
                text: '화면명',
                width: 30,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.window}
                    style={{ fontSize: 12 }}
                    data={
                      Object
                        .keys(BoardWindowTypeNames)
                        // @ts-ignore
                        .map((key) => new ComboBoxModel(key, BoardWindowTypeNames[key]))
                    }
                    onSelect={(option) => rowUpdate({ window: option.value })}
                  />
                ),
              },
              {
                id: 'ignore',
                text: '반복무시',
                width: 10,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.ignore}
                    style={{ fontSize: 12 }}
                    data={[
                      { value: '0', remark: '복구' },
                      { value: '1', remark: '무시' },
                      // eslint-disable-next-line no-shadow
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => rowUpdate({ ignore: option.value })}
                  />
                ),
              },
            ]}
            data={scope.state.timeList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(BoardTimeTypes.TIME, rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(BoardTimeTypes.TIME, item)}
          />
        </FlexLayout>

        <FlexLayout isVertical={true} size={Global.LAYOUT_BUTTON_HEIGHT_1}>  </FlexLayout>
        <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1} style={{ color: 'var(--color-blue)', padding: 10 }}>
          <div>* 전광판 반복 셋팅</div>
          <FlexLayout justify="right">
            <Button
              style={{ height: '25px', width: '60px' }}
              onClick={() => scope.new_time(BoardTimeTypes.LOOP)}
            >
              신규
            </Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              onClick={async () => {
                if (await scope.save_time(BoardTimeTypes.LOOP)) {
                  ConfirmSuccess.show('성공', '저장되었습니다.');
                } else {
                  ConfirmFail.show('실패', '저장에 실패하였습니다.');
                }
              }}
            >
              저장
            </Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.delete_time(BoardTimeTypes.LOOP)}
            >
              삭제
            </Button>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout isVertical={true} size={200}>
          <TableLayout
            ref={scope.tableLoop}
            header={[
              {
                id: 'seq',
                text: '순서',
                width: 10,
              },
              {
                id: 'hh',
                text: '시 : 분 : 초',
                width: 40,
                render: (x, rowUpdate, ref) => (
                  <FormatTextBox
                    style={{ fontSize: 12 }}
                    textAlign="center"
                    format="##:##:##"
                    ref={ref}
                    value={`${x.hh.padStart(2, '0')}${x.mm}${x.ss}`}
                    onChange={(value) => rowUpdate({
                      hh: value.substr(0, 2),
                      mm: value.substr(2, 2),
                      ss: value.substr(4, 2),
                    })}
                  />
                ),
              },
              {
                id: 'window',
                text: '화면명',
                width: 50,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.window}
                    style={{ fontSize: 12 }}
                    data={[
                      { value: 'dash', remark: '고장현황' },
                      { value: 'event', remark: '아침조회' },
                      { value: 'chk', remark: '점검현황' },
                      { value: 'plantoday', remark: '금일계획' },
                      // eslint-disable-next-line no-shadow
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => rowUpdate({ window: option.value })}
                  />
                ),
              },
            ]}
            data={scope.state.loopList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(BoardTimeTypes.LOOP, rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(BoardTimeTypes.LOOP, item)}
          />
        </FlexLayout>

        <FlexLayout isVertical={true}>
          <FlexLayout />

          <FlexLayout className="modal-buttons" size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              className="btn-danger"
              isFull={true}
              onClick={() => scope.toggleSettingsModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </ModalView>
    {/* [/전광판 시간 설정 모달] */}
  </FlexLayout>
);
