import { TLHF } from '../../../../../constants';
import {
  ComboBox, ComboBoxModel, FlexLayout, FormatNumberTextBox, TextBox,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { SearchBinding } from '../../../../../models';
import EnrollmentMaterialsModel from '../models/EnrollmentMaterialsModel';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 테이블 레이아웃 A
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentMaterialsTableHeader: TLHF<EnrollmentMaterialsModel, Enrollment> = (scope) => ([
  {
    id: 'pcode',
    text: '품번',
    color: 'var(--color-red)',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pcode}
        textAlign="center"
        onDoubleClick={async () => {
          const result = await scope.props.modalStore.openProductSelector(x.pcode);
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
          });
        }}
        onChange={(value) => rowUpdate({ pcode: value })}
        onEnter={async (value) => {
          const result = await scope.props.modalStore.openProductSelector(value);
          await scope.tableMaterialsItemChanged(rowUpdate, 'pcode', result.phm_pcod, result, x);
        }}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품명',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pname}
        onChange={(v) => rowUpdate({ pname: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.psize}
        onChange={(v) => rowUpdate({ psize: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.punit}
        onChange={(v) => rowUpdate({ punit: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'pserial',
    text: '품목시리얼번호',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.pserial}
        onChange={(v) => rowUpdate({ pserial: v })}
      />
    ),
  },
  {
    id: 'equpcd',
    text: '동호기',
    color: 'var(--color-red)',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.equpcd}
        textAlign="center"
        onChange={(v) => rowUpdate({ equpcd: v })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E611',
          { uv_arg1: scope.state.content?.actcd },
          true,
          (item) => {
            rowUpdate({
              equpcd: item.cd,
              equpnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'equpnm',
    text: '동호기명',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <TextBox
        textAlign="center"
        ref={ref}
        value={x.equpnm}
        onChange={(v) => rowUpdate({ equpnm: v })}
        readonly={true}
      />
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 60,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.myn}
        data={[
          { value: '1', remark: '무상' },
          { value: '2', remark: '유상' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => scope.tabAutoCalc({ ...x, myn: option.value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 60,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
        onEnter={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
        onBlur={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
      />
    ),
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumQty, true)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
        onEnter={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
        onBlur={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, true)}
        onEnter={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate)}
        onBlur={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate)}
      />
    ),
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.addamt}
        onChange={(v) => rowUpdate({ addamt: v })}
      />
    ),
    trail: (data) => {
      const sumAddamt = Sum.all(data, 'addamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAddamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'compamt',
    text: '공사금액',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.compamt}
        onChange={(v) => rowUpdate({ compamt: v })}
      />
    ),
    trail: (data) => {
      const sumCompamt = Sum.all(data, 'compamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCompamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
