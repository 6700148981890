import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Regist } from '../Regist';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 상세 > 탭 - 발주내역 > 그리드
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabGridBalHeader: GLHF<any, Regist> = () => ([
  {
    id: 'baldate',
    text: '발주일자',
    width: 100,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.baldate)}
      </FlexLayout>
    ),
  },
  {
    id: 'balnum',
    text: '번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.balnum}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '발주처',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '제목',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '발주금액',
    width: 120,
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumMamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'deldate',
    text: '납기일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.deldate)}
      </FlexLayout>
    ),
  },
  {
    id: 're_ibgdate',
    text: '입고요청일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.re_ibgdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'balpernm',
    text: '발주처담당자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.balpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'telnum',
    text: '발주연락처',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.telnum}
      </FlexLayout>
    ),
  },
  {
    id: 'faxnum',
    text: '발주FAX',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.faxnum}
      </FlexLayout>
    ),
  },
  {
    id: 'email',
    text: '발주Email',
    width: 120,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.email}
      </FlexLayout>
    ),
  },
]);
