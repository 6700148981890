import { computed } from 'mobx';

/**
 * 모델
 * @window w_pz008
 * @category 상위부서
 */
export class TopDepartmentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 상위부서코드
   *
   * **data type** varchar(2)
   */
  kukcd: string;

  /**
   * 상위부서명
   *
   * **data type** varchar(20)
   */
  kuknm: string;

  /**
   * 사용여부
   *
   * **data type** varchar(1)
   */
  useyn: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.kukcd = data.kukcd || '';
    this.kuknm = data.kuknm || '';
    this.useyn = data.useyn || '';
    this.new = isNew ? '1' : '0';
  }


  @computed
  get isNew() {
    return this.new === '1';
  }
}
