import { IoIosMail } from 'react-icons/all';
import { GLHF, Global } from '../../../../../constants';
import { Badge, FlexLayout } from '../../../../../components';
import {
  Appgubuns,
  Comps,
  Compyns,
  Enrollment,
  Receflags,
} from '../Enrollment';
import EnrollmentModel from '../models/EnrollmentModel';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentGridHeader: GLHF<EnrollmentModel, Enrollment> = (scope) => ([
  {
    id: 'appgubun',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">결재상태</FlexLayout>
        <FlexLayout align="start">청구상태</FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
          style={{
            color: Appgubuns.filter((y) => y.value === x.appgubun)[0]?.color,
          }}
          onDoubleClick={
            () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
          }
        >
          {Appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
          {x?.appremark && <Badge>
            <IoIosMail color="var(--color-red)" size={25} />
          </Badge>}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
          style={x.pumflag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
        >
          {x.pumflag === '1' ? '있음' : '없음'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'status',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">견적상태</FlexLayout>
        <FlexLayout align="start">확인수령</FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
          style={{
            color: Compyns.filter((y) => y.value === x.compyn)[0]?.color,
          }}
        >
          {Comps.filter((y) => x.compyn === y.value)[0]?.remark}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
          style={x.receflag === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
        >
          {Receflags.filter((y) => x.receflag === y.value)[0]?.remark}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'costdate',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout align="end">견적일자</FlexLayout>
        <FlexLayout align="start">견적번호</FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          {Date8.withDash(x.costdate)}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          {x.costnum}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
]);
