import { computed } from 'mobx';

/**
 * 개인별 업무보고모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class IndividualModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 구분
   *
   * **data type** char(3)
   */
  gubun: string;

  /**
   * 연간목표
   *
   * **data type** number
   */
  totamt: string;

  /**
   * 연간목표
   *
   * **data type** number
   */
  yearplansamt: string;

  /**
   * 목표
   *
   * **data type** number
   */
  beplansamt: string;

  /**
   * 실적
   *
   * **data type** number
   */
  besamt: string;

  /**
   * 차이
   *
   * **data type** number
   */
  bechaamt: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  chaamt: string;

  /**
   * 목표
   *
   * **data type** number
   */
  plansamt: string;

  /**
   * 현재실적
   *
   * **data type** number
   */
  samt: string;

  /**
   * 차이
   *
   * **data type** number
   */
  totchaamt: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  beyul: string;

  /**
   * 목표
   *
   * **data type** number
   */
  totplansamt: string;

  /**
   * 실적
   *
   * **data type** number
   */
  totsamt: string;

  /**
   * 차이
   *
   * **data type** number
   */
  yul: string;

  /**
   * 달성율
   *
   * **data type** number
   */
  totyul: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 계획금액
   *
   * **data type** number
   */
  planamt: string;

  /**
   * 금액
   *
   * **data type** number
   */
  costamt: string;

  /**
   * 차이금액
   *
   * **data type** number
   */
  addamt: string;

  /**
   * 계획내용/견적서명
   *
   * **data type** varchar(100)
   */
  constnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(20)
   */
  pernm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.totamt = data.totamt || '';
    this.addamt = data.addamt || '';
    this.constnm = data.constnm || '';
    this.divinm = data.divinm || '';
    this.costamt = data.costamt || '';
    this.planamt = data.planamt || '';
    this.actnm = data.actnm || '';
    this.gubun = data.gubun || '';
    this.yearplansamt = data.yearplansamt || '';
    this.beplansamt = data.beplansamt || '';
    this.besamt = data.besamt || '';
    this.bechaamt = data.bechaamt || '';
    this.chaamt = data.chaamt || '';
    this.plansamt = data.plansamt || '';
    this.samt = data.samt || '';
    this.totchaamt = data.totchaamt || '';
    this.beyul = data.beyul || '';
    this.totplansamt = data.totplansamt || '';
    this.totsamt = data.totsamt || '';
    this.yul = data.yul || '';
    this.totyul = data.totyul || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
