import * as React from 'react';
import { observer } from 'mobx-react';
import {
  AiOutlineClose,
  AiOutlineSave,
} from 'react-icons/ai';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { Global } from '../../constants';
import { Button } from '../../components/forms/Button';
import { TableLayout } from '../../components/layout/TableLayout';
import { CheckBox } from '../../components/forms/CheckBox';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';
import { ConfirmWarning } from '../../utils/confirm';

interface ModalApprovalReferenceProps {
  modalStore: ModalStore;
}

@observer
export class ModalApprovalReference extends React.Component<ModalApprovalReferenceProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={600}
        height={500}
        isVisible={modalStore.isVisibleApprovalReference}
        onClose={() => modalStore.closeApprovalReferenceLine(false)}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
            style={{
              marginBottom: 4,
            }}
          >
            <LayoutTitle weight={1}>
              결재 라인 설정
            </LayoutTitle>

            <Button onClick={ async () => {
              if (await modalStore.approvalReferenceLineSave()) {
                modalStore.closeApprovalReferenceLine(true);
              }
            }}>
              <AiOutlineSave style={{ marginLeft: 2 }} />
              <span>확인</span>
            </Button>

            <Button
              onClick={() => modalStore.closeApprovalReferenceLine(true)}
            >
              <AiOutlineClose style={{ marginLeft: 2 }} />
              <span>닫기</span>
            </Button>
          </FlexLayout>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={modalStore.approvalReferenceSearchQuery}
                onChange={(value) => {
                  modalStore.approvalReferenceSearchQuery = value;
                  modalStore.approvalReferenceSearchPosition = -1;
                }}
                onEnter={() => modalStore.searchApprovalReference()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button onClick={() => modalStore.searchApprovalReference()}>조회</Button>
          </SearchLayout>

          <TableLayout
            ref={modalStore.approvalReferenceTable}
            header={[
              {
                id: 'check',
                text: (
                  <FlexLayout justify="center" align="center">
                    <CheckBox
                      noMargin
                      value={modalStore.approvalReferenceTotalCheck}
                      onChange={async (value) => modalStore
                        .approvalReferenceUpdateCheckAllToggle(value)}
                    />
                  </FlexLayout>
                ),
                width: 10,
                render: (x, rowUpdate) => (
                  <FlexLayout justify="center" align="center">
                    <CheckBox
                      noMargin
                      value={x.isChecked}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '코드',
                width: 80,
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 120,
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={modalStore.approvalReferenceData}
            onRowFocusEvent={(item) => {
              modalStore.approvalReferenceSelected = item;
            }}
            onChange={(rows) => {
              if (rows.filter((x) => x.chk === '1').length > 40) {
                ConfirmWarning.show('제한', '최대 40명까지만 선택할 수 있습니다');
                modalStore.approvalReferenceTable.current?.update(false);
                return;
              }
              modalStore.approvalReferenceData = rows;
            }}
          />
        </FlexLayout>
      </ModalView>
    );
  }
}
