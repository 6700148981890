import * as React from 'react';
import {
  FlexLayout, GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { PaySales } from '../PaySales';
import { TabFiveYearGridHeader } from '../headers/TabFiveYearGridHeader';

/**
 * 화면 > 탭 > 5년간 수주매출내역
 * @window w_tb_e101w_06
 * @category 보수수주매출통계
 */
export const TabFiveYearTemplate: React.FC<TemplateProps<PaySales>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      header={TabFiveYearGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataFiveYear}
      onRowDoubleClick={() => scope.textModal(true, '2')}
      onRowClick={(row) => scope.onRowFocusEvent2(row)}
    />
  </FlexLayout>
);
