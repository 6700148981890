import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { BreakPercentTemplate } from './BreakPercent.template';
import { InfinityRetrieve } from '../../../../models/common';
import { BreakPercentModel } from './BreakPercent.model';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface HolidayState {

  // 검색조건
  searchQuery: string;
  gubun: string;
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;

  stdate: string;
  enddate: string;

  atdcds: Array<any>;

  BreakPercentList: Array<BreakPercentModel>;
  focusedBreakPercent: BreakPercentModel;

  // 통계
  todayplan_tot: string;
  plancomp_tot: string;
  notplancomp_tot: string;
  todaycompplan_tot: string;
  misplan_tot: string;
  planyul_tot: string;
  plancontcnt_tot: string;
  plancont001cnt_tot: string;
  plancont002cnt_tot: string;
  planmiscnt_tot: string;
  planmisamt_tot: string;
  rececompcnt_tot: string;
  rececnt_tot: string;
  wadcnt_tot: string;
  notkccnt_tot: string;
  kccnt_tot: string;
  kc001cnt_tot: string;
  kc002cnt_tot: string;
  contcnt_tot: string;
  cont001cnt_tot: string;
  cont002cnt_tot: string;
  compcnt_tot: string;
  costcnt_tot: string;
  costcompcnt_tot: string;
  miscnt_tot: string;
  misamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_e411w_06
 * @category 담당별고장처리율
 */
export class BreakPercent extends PageComponent<PageProps, HolidayState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  additionalSubTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    // state 기본값 정의
    this.state = props.state || {

      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      searchQuery: '',
      gubun: '%',

      stdate: `${year}0101`,
      enddate: `${year}${month}${date}`,

      atdcds: [],

      data: new BreakPercentModel(),
      BreakPercentList: [],

      todayplan_tot: '',
      plancomp_tot: '',
      notplancomp_tot: '',
      todaycompplan_tot: '',
      misplan_tot: '',
      planyul_tot: '',
      plancontcnt_tot: '',
      plancont001cnt_tot: '',
      plancont002cnt_tot: '',
      planmiscnt_tot: '',
      planmisamt_tot: '',
      rececompcnt_tot: '',
      rececnt_tot: '',
      wadcnt_tot: '',
      notkccnt_tot: '',
      kccnt_tot: '',
      kc001cnt_tot: '',
      kc002cnt_tot: '',
      contcnt_tot: '',
      cont001cnt_tot: '',
      cont002cnt_tot: '',
      compcnt_tot: '',
      costcnt_tot: '',
      costcompcnt_tot: '',
      miscnt_tot: '',
      misamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_pb002_03');
    if (!data) return;
    this.setState({
      atdcds: data.items,
    });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid,
        divicd: this.state.divicd,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          BreakPercentList: items,
        }, next);
      },
      async () => {
        await this.SS({
          BreakPercentList: [],
        });
        await this.infinity?.retrieve();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      BreakPercentList: [],
    }, async () => {
      const data = await this.infinity?.retrieve();
      this.setState({
        todayplan_tot: data?.todayplan_tot || '0',
        plancomp_tot: data?.plancomp_tot || '0',
        notplancomp_tot: data?.notplancomp_tot || '0',
        todaycompplan_tot: data?.todaycompplan_tot || '0',
        misplan_tot: data?.misplan_tot || '0',
        planyul_tot: data?.planyul_tot || '0',
        plancontcnt_tot: data?.plancontcnt_tot || '0',
        plancont001cnt_tot: data?.plancont001cnt_tot || '0',
        plancont002cnt_tot: data?.plancont002cnt_tot || '0',
        planmiscnt_tot: data?.planmiscnt_tot || '0',
        planmisamt_tot: data?.planmisamt_tot || '0',
        rececompcnt_tot: data?.rececompcnt_tot || '0',
        rececnt_tot: data?.rececnt_tot || '0',
        wadcnt_tot: data?.wadcnt_tot || '0',
        notkccnt_tot: data?.notkccnt_tot || '0',
        kccnt_tot: data?.kccnt_tot || '0',
        kc001cnt_tot: data?.kc001cnt_tot || '0',
        kc002cnt_tot: data?.kc002cnt_tot || '0',
        contcnt_tot: data?.contcnt_tot || '0',
        cont001cnt_tot: data?.cont001cnt_tot || '0',
        cont002cnt_tot: data?.cont002cnt_tot || '0',
        compcnt_tot: data?.compcnt_tot || '0',
        costcnt_tot: data?.costcnt_tot || '0',
        costcompcnt_tot: data?.costcompcnt_tot || '0',
        miscnt_tot: data?.miscnt_tot || '0',
        misamt_tot: data?.misamt_tot || '0',
      });
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.BreakPercentList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      perid: this.state.perid,
      divicd: this.state.divicd,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.BreakPercentList?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      perid: this.state.perid,
      divicd: this.state.divicd,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
    });
  }

  @action
  onRowFocusEvent(item:BreakPercentModel) {
    this.setState({ focusedBreakPercent: item });
  }

  render() {
    return (
      <BreakPercentTemplate scope={this} />
    );
  }
}
