/**
 * 메인화면
 * @window w_tb_da038_01
 * @category 반납등록
 */
import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { MdKeyboardArrowRight } from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ReturnRegistration } from './ReturnRegistration';
import {
  Button,
  CheckBox,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Date8,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Format } from '../../../../utils/string';

export const ReturnRegistrationTemplate: React.FC<TemplateProps<ReturnRegistration>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
          >
          </DateTextBox>
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) }, () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={600}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout size={300}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'bandate',
              text: '반납일자',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.bandate)}
                </FlexLayout>
              ),
            },
            {
              id: 'bannum',
              text: '번호',
              width: 60,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.bannum}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '반납자명',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '제목(비고)',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          data={scope.state.returnList}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />

        <FlexLayout
          weight={1.4}
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="반납일자"
              isNeed={true}
            >
              <DateTextBox
                value={scope.state.rowFocusedData?.bandate}
                format="####-##-##"
                textAlign="center"
                onChange={(value) => setData({ bandate: value })}
                readonly={!scope.state.focusedList?.isNew}
              >
              </DateTextBox>

              <TextBox
                weight={0.8}
                value={scope.state.rowFocusedData?.bannum}
                textAlign="center"
                readonly={true}
              >
              </TextBox>
            </LabelBox>

            <LabelBox
              title="창고"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.9}
                value={scope.state.rowFocusedData?.store}
                textAlign="center"
                onChange={(value) => setData({ store: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_CA504',
                  {},
                  true,
                  (item) => {
                    setData({
                      store: item.cd,
                      storenm: item.cdnm,
                    });
                  },
                )}
              >
              </TextBox>
              <TextBox
                value={scope.state.rowFocusedData?.storenm}
                textAlign="center"
                readonly={true}
              >
              </TextBox>
            </LabelBox>

            <LabelBox
              title="작성자"
            >
              <TextBox
                weight={0.9}
                value={scope.state.rowFocusedData?.inperid}
                textAlign="center"
                readonly={true}
              >
              </TextBox>

              <TextBox
                value={scope.state.rowFocusedData?.inpernm}
                textAlign="center"
                readonly={true}
              >
              </TextBox>
            </LabelBox>

            <LabelBox
              title="작성일자"
            >
              <DateTextBox
                value={scope.state.rowFocusedData?.indate}
                format="####-##-##"
                textAlign="center"
                readonly={true}
              >
              </DateTextBox>
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              size={490}
              title="제목(비고)"
            >
              <TextBox
                value={scope.state.rowFocusedData?.remark}
                onChange={(value) => setData({ remark: value })}
              >
              </TextBox>
            </LabelBox>

            <LabelBox
              title="반납자명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.9}
                value={scope.state.rowFocusedData?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              >
              </TextBox>
              <TextBox
                value={scope.state.rowFocusedData?.pernm}
                textAlign="center"
                onChange={(value) => setData({ pernm: value })}
                isDisabledTrackingStateChange={true}
              >
              </TextBox>
            </LabelBox>

            <LabelBox
              title="수령자명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.9}
                value={scope.state.rowFocusedData?.receperid}
                textAlign="center"
                onChange={(value) => setData({ receperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      receperid: item.cd,
                      recepernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              >
              </TextBox>
              <TextBox
                value={scope.state.rowFocusedData?.recepernm}
                textAlign="center"
                onChange={(value) => setData({ recepernm: value })}
                isDisabledTrackingStateChange={true}
              >
              </TextBox>
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox
              title="특이사항[반납사유]"
            >
              <TextBox
                isMultiline={true}
                value={scope.state.rowFocusedData?.bansayoo}
                onChange={(value) => setData({ bansayoo: value })}
                isDisabledTrackingStateChange={true}
              >
              </TextBox>
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        size={Global.LAYOUT_BUTTON_HEIGHT_1}
      >
        <LayoutTitle size={230}>반납내역</LayoutTitle>

        <FlexLayout
          justify="right"
          disabled={scope.state.returnList?.length < 1}
        >
          <FlexLayout
            justify="right"
            align="center"
            style={{ color: 'var(--color-red)', marginRight: 32 }}
          >
            *반납등록은 추가버튼을 먼저 클릭하세요
          </FlexLayout>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.onDetailNewEvent()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDetailDeleteEvent()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={scope.state.returnList?.length < 1}
        disabledText="신규로 추가하거나 목록을 선택해주세요."
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'banseq',
              text: '순번',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.banseq}
                </FlexLayout>
              ),
            },
            {
              id: 'myn',
              text: '유무상',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.myn === '0' ? '무' : '유'}
                </FlexLayout>
              ),
            },
            {
              id: 'pcode',
              text: '코드',
              color: 'var(--color-red)',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pcode}
                </FlexLayout>
              ),
            },
            {
              id: 'pname',
              text: '품목명',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pname}
                </FlexLayout>
              ),
            },
            {
              id: 'psize',
              text: '규격',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.psize}
                </FlexLayout>
              ),
            },
            {
              id: 'punit',
              text: '단위',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.punit}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '수량',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty}
                </FlexLayout>
              ),
            },
            {
              id: 'uamt',
              text: '단가',
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.uamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'samt',
              text: '공급가액',
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.samt)}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              color: 'var(--color-red)',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpcd',
              text: '호기코드',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpcd}
                </FlexLayout>
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'deldate',
              text: '불출일자',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.deldate)}
                </FlexLayout>
              ),
            },
            {
              id: 'delnum',
              text: '번호',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.delnum}
                </FlexLayout>
              ),
            },
            {
              id: 'delseq',
              text: '순번',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.delseq}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.detailData}
          onChange={(rows, updatedRows) => {
            scope.onDetailUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item, index) => scope.onDetailRowFocusEvent(item, index)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.addModal}
        onClose={() => scope.SS({ addModal: false })}
        width={1400}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle
            style={{ marginBottom: 4 }}
            justify="left"
          >
            불출내역
          </LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={200}>
              <span style={{ color: 'var(--color-red)' }}>품목코드</span>
              <TextBox
                value={scope.state.popupPcode}
                textAlign="left"
                onChange={(value) => scope.SS({ popupPcode: value })}
                onDoubleClick={async () => {
                  const result = await scope.props.modalStore.openProductSelector();
                  scope.setState({
                    popupPcode: result.phm_pcod,
                    popupPname: result.phm_pnam,
                    popupPsize: result.phm_size,
                  }, () => scope.returnPopupRetrieve());
                }}
                onEnter={(value) => scope.onSearchPcode(value)}
              >
              </TextBox>
            </FlexLayout>

            <FlexLayout size={400}>
              <span>품명</span>
              <TextBox
                value={scope.state.popupPname}
                readonly={true}
              >
              </TextBox>
            </FlexLayout>

            <FlexLayout size={300}>
              <span>규격</span>
              <TextBox
                value={scope.state.popupPsize}
                readonly={true}
              >
              </TextBox>
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'chk',
                  text: '선택',
                  width: 40,
                  render: (x, rowUpdate) => (
                    <CheckBox
                      value={x.chk === '1'}
                      onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                    >
                    </CheckBox>
                  ),
                },
                {
                  id: 'deldate',
                  text: '출고일자',
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.deldate}
                    >
                    </FormatTextBox>
                  ),
                },
                {
                  id: 'delnum',
                  text: '출고번호',
                  width: 60,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.delnum}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ delnum: value })}
                    >
                    </TextBox>
                  ),
                },
                {
                  id: 'myn',
                  text: '유무상',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.myn === '0' ? '무상' : '유상'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품목명',
                  width: 150,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '출고된수량',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'banqty',
                  text: '반납수량',
                  width: 60,
                  render: (x, rowUpdate) => (
                    <TextBox
                      value={x.banqty}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ banqty: value })}
                    >
                    </TextBox>
                  ),
                },
                {
                  id: 'uamt2',
                  text: '단가',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.uamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '공급금액',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 150,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '동호기명',
                  width: 150,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.popupData}
              onChange={(rows) => scope.popupUpdateRows(rows)}
            />
          </FlexLayout>
        </FlexLayout>

        <SubHeader>
          <div>
            <span>일자</span>
            <DateTextBox
              textAlign="center"
              format="####-##-##"
              value={scope.state.popupDelDate}
              onChange={(value) => scope.setState({ popupDelDate: value })}
            />
          </div>
          <FlexLayout justify="end">
            <Button onClick={() => scope.popupSave()}>확인</Button>
            <Button onClick={() => scope.SS({ addModal: false, popupDelDate: '00000000' })}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  );
};
