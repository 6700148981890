import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411w_07
 * @category 고장분석현황[전체] - 기종별
 */

export class TypeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *  기종별
   *
   * **data type** varchar(?)
   */
  readonly gubunnm : string;

  /**
   *  고장건수
   *
   * **data type** varchar(?)
   */
  readonly tot: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.gubunnm = data.gubunnm || '';
    this.tot = data.tot || '';


    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
