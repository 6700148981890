import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e451
 * @category 견적서등록
 */
export default class EnrollmentDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly addamt: string;

  /**
   * 수동계산
   *
   * **data type** varchar(8)
   */
  readonly autoexec: string;

  /**
   * 수동계산
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly costamt: string;

  /**
   * 코드
   *
   * **data type** varchar(8)
   */
  readonly costcd: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly costnm: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 입고일자
   *
   * **data type** varchar(8)
   */
  readonly ibgdate: string;

  /**
   * 입고번호
   *
   * **data type** varchar(8)
   */
  readonly ibgnum: string;

  /**
   * 입고순서
   *
   * **data type** varchar(8)
   */
  readonly ibgseq: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 인쇄순서
   *
   * **data type** varchar(8)
   */
  readonly prtseq: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 규격
   *
   * **data type** varchar(8)
   */
  readonly size: string;

  /**
   * 사업자코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;

  /**
   * 단위
   *
   * **data type** varchar(8)
   */
  readonly unit: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.addamt = data.addamt || '';
    this.autoexec = data.autoexec || '';
    this.chk = data.chk || '';
    this.costamt = data.costamt || '';
    this.costcd = data.costcd || '';
    this.costdate = data.costdate || '';
    this.costnm = data.costnm || '';
    this.costnum = data.costnum || '';
    this.ibgdate = data.ibgdate || '19700101';
    this.ibgnum = data.ibgnum || '';
    this.ibgseq = data.ibgseq || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.prtseq = data.prtseq || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.seq = data.seq || '';
    this.size = data.size || '';
    this.spjangcd = data.spjangcd || '';
    this.uamt = data.uamt || '';
    this.unit = data.unit || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
