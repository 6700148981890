import { computed } from 'mobx';

/**
 * 학력 모델
 * @window w_p2110
 * @category 인사등록
 */
export class AcademicBackgroundModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 학교코드
   *
   * **data type** varchar(2)
   */
  readonly schcd: string;

  /**
   * 학교명
   *
   * **data type** varchar(30)
   */
  readonly schnm: string;

  /**
   * 전공
   *
   * **data type** varchar(30)
   */
  readonly majo: string;

  /**
   * 졸업구분
   *
   * **data type** varchar(1)
   */
  readonly igrad: string;

  /**
   * 입학일
   *
   * **data type** varchar(8)
   */
  readonly edate: string;

  /**
   * 졸업일
   *
   * **data type** varchar(8)
   */
  readonly gdate: string;

  /**
   * 자체점검자격학력
   *
   * **data type** varchar(10)
   */
  readonly checkflag: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  /**
   * 옵션박스 ischanged
   *
   * **data type** boolean
   */
  readonly isChanged: boolean;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.schcd = data.schcd || '';
    this.schnm = data.schnm || '';
    this.majo = data.majo || '';
    this.igrad = data.igrad || '';
    this.edate = data.edate || '';
    this.gdate = data.gdate || '';
    this.checkflag = data.checkflag || '';
    this.new = isNew ? '1' : '0';
    this.isChanged = !!data.isChanged;
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
