import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import { Global, TemplateProps } from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { HumanCage, HumanCageTabDataStateNames, HumanCageTabId } from './HumanCage';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8 } from '../../../../utils/time';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TabView } from '../../../../components/layout/TabView';
import {
  ComboBox,
  ComboBoxModel,
  GridLayoutAdditionalHeader,
  IFrame,
} from '../../../../components';

/**
 * 메인화면
 * @window w_tb_e411w_02
 * @category 사람갇힘현황
 */
export const HumanCageTemplate: React.FC<TemplateProps<HumanCage>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={175}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="현장으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={150}>
        <span>처리</span>
        <ComboBox
          value={scope.state.gubun}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '처리' },
            { value: '2', remark: '미처리' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ gubun: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>부서</span>
        <TextBox
          weight={1.4}
          textAlign="center"
          value={scope.state.divicd === '%' ? '' : scope.state.divicd}
          onChange={(value) => scope.setState({ divicd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JC002',
            {},
            true,
            (item) => {
              scope.setState({
                divicd: item.cd,
                divinm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />

        <TextBox
          weight={2}
          value={scope.state.divinm}
          readonly={true}
        />
      </FlexLayout>
      <FlexLayout size={250}>
        <span style={{ color: 'var(--color-red)' }}>담당</span>
        <TextBox
          value={scope.state.perid === '%' ? '' : scope.state.perid}
          textAlign="center"
          onChange={(value) => scope.setState({ perid: value })}
          onEnter={() => scope.onRetrieveEvent()}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              scope.setState({
                perid: item.cd,
                pernm: item.cdnm,
              }, () => scope.onRetrieveEvent());
            },
          )}
          isDisabledTrackingStateChange={true}
        />
        <TextBox
          value={scope.state.pernm}
          weight={1.5}
          textAlign="center"
          isDisabledTrackingStateChange={true}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <TabView
        tabs={scope.tabs}
        focused={scope.state.focusedTab}
        onChange={(tab) => scope.onTabChange(tab)}
      />
    </FlexLayout>
    {(scope.tabIndex === HumanCageTabId.Day) && <FlexLayout isVertical={true}>
    <GridLayout
      scope={scope}
      header={scope.tabHeaders[scope.tabIndex]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      // @ts-ignore
      data={scope.state[HumanCageTabDataStateNames[scope.tabIndex]] || [] }
      onRowClick={(item) => scope.onRowFocusEvent(item)}
    />
    </FlexLayout>}
    {(scope.tabIndex === HumanCageTabId.Act) && <FlexLayout isVertical={true}>
        <GridLayoutAdditionalHeader
            ref={scope.additionalTitle}
            height={Global.LAYOUT_TITLE_HEIGHT_1}
            contentWidth={2624}
        >
            <FlexLayout size={70}/>
            <FlexLayout size={68}/>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
              {`${scope.state.stdate.substr(0, 4)}년 평균`}
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                1월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                2월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                3월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                4월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                5월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                6월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                7월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                8월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                9월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                10월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                11월
            </FlexLayout>
            <FlexLayout
                size={184}
                justify="center"
                align="center"
            >
                12월
            </FlexLayout>
        </GridLayoutAdditionalHeader>
        <GridLayout
            header={[
              {
                id: 'divinm',
                text: '부서명',
                width: 70,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자명',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'totmon',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.montot_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Math.floor(x.totmon)}
                  </FlexLayout>
                ),
              },
              {
                id: 'totcompmon',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {scope.state.compmontot_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    style={{ color: 'var(--color-red)' }}
                    justify="center"
                    align="center"
                  >
                    {x.totcompmon}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_4',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.yultot_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    style={{ color: 'var(--color-blue)' }}
                    justify="center"
                    align="center"
                  >
                    {x.compute_4}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon01',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon01_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon01}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon01',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {scope.state.compmon01_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon01}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_01',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.yul01_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {x.compute_01}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon02',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon02_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon02}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon02',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {scope.state.compmon02_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon02}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_02',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.yul02_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {x.compute_02}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon03',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon03_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon03}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon03',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon03_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon03}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_03',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon03_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_03}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon04',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon04_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon04}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon04',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon04_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon04}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_04',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon04_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_04}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon05',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon05_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon05}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon05',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon05_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon05}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_05',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon05_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_05}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon06',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon06_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon06}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon06',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon06_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon06}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_06',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon06_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_06}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon07',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon07_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon07}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon07',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon07_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon07}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_07',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon07_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_07}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon08',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon08_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon08}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon08',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon08_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon08}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_08',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon08_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_08}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon09',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon09_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon09}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon09',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon09_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon09}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_09',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon09_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_09}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon10',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon10_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon10}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon10',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon10_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon10}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_10',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon10_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_10}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon11',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon11_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon11}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon11',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon11_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon11}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_11',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon11_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_11}
                  </FlexLayout>
                ),
              },
              {
                id: 'mon12',
                text: '관리대수',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.mon12_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mon12}
                  </FlexLayout>
                ),
              },
              {
                id: 'compmon12',
                text: '갇힘',
                width: 60,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.compmon12_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {x.compmon12}
                  </FlexLayout>
                ),
              },
              {
                id: 'compute_12',
                text: '발생율',
                width: 66,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-violet)' }}
                  >
                    {scope.state.compmon12_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.compute_12}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
            data={scope.state.monthlist}
            onHorizontalScroll={(x) => {
              scope.additionalTitle.current?.scrollTo(x);
            }}
        />
    </FlexLayout>}
    {(scope.tabIndex === HumanCageTabId.Day) && <FlexLayout weight={0.5}>
        <IFrame src={scope.state.url} />
    </FlexLayout>}
  </FlexLayout>

);
