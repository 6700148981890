import { TableLayoutHeader } from '../../../../../constants';
import { CertificateModel } from '../models';
import {
  PersonnelResistration,
  // PersonnelResistrationItemChangeTypes,
} from '../PersonnelResistration';
import {
  CheckBox,
  TextBox,
  DateTextBox,
} from '../../../../../components';

// 경력
const Certificate: TableLayoutHeader<CertificateModel, PersonnelResistration>[] = [
  {
    id: 'seq',
    text: '순번',
    width: 30,
    render: (x) => (
      <TextBox
        value={x.seq}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'checkflag',
    text: '자체점검관련',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.checkflag === '1'}
        onChange={(value) => rowUpdate({ checkflag: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'kcflag',
    text: '점검일지반영',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.kcflag === '1'}
        onChange={(value) => rowUpdate({ kcflag: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'checkflag2',
    text: '점검(교육)예정',
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.checkflag2 === '1'}
        onChange={(value) => rowUpdate({ checkflag2: value ? '1' : '0' })}
      />
    ),
  },
  {
    id: 'licence',
    text: '자격종류',
    width: 150,
    render: (x, rowUpdate, ref, _) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.licence}
        onChange={(value) => rowUpdate({ licence: value })}
        // onBlur={async (value) => {
        //   const licenceData = await scope?.getItem(
        //     value,
        //     PersonnelResistrationItemChangeTypes.LICENCE,
        //   );
        //
        //   licenceData && rowUpdate({ licence: licenceData.licence });
        // }}
      />
    ),
  },
  {
    id: 'licencenum',
    text: '자격증번호',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.licencenum}
        onChange={(value) => rowUpdate({ licencenum: value })}
      />
    ),
  },
  {
    id: 'licenceday1',
    text: '취득일',
    width: 80,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.licenceday1}
        onChange={(value) => rowUpdate({ licenceday1: value })}
      />
    ),
  },
  {
    id: 'licenceday2',
    text: '만료일',
    width: 80,
    render: (x, rowUpdate) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.licenceday2}
        onChange={(value) => rowUpdate({ licenceday2: value })}
      />
    ),
  },
  {
    id: 'licencewhere',
    text: '발생기관',
    width: 300,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.licencewhere}
        onChange={(value) => rowUpdate({ licencewhere: value })}
      />
    ),
  },
];

export default Certificate;
