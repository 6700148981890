import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import OrderModel from './models/OrderModel';
import { OrderTemplate } from './Order.template';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface OrderState {
  searchQuery?: string;
  spjangcd: string;
  gubun: string;
  contflag: string;
  spjangcds: Array<any>;
  stdate: string;
  data: Array<OrderModel>;
}

export const Gubuns = [
  { key: '%', value: '전체' },
  { key: '1', value: '설치' },
  { key: '2', value: '보수' },
];

export const BalGubuns = [
  { key: '%', value: '전체' },
  { key: '1', value: '발주중' },
  { key: '2', value: '발주완료' },
];

/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_05
 * @category 설치발주현황
 */
export class Order extends PageComponent<PageProps, OrderState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    this.state = props.state || {
      searchQuery: pageParams.searchQuery || '',
      spjangcd: 'ZZ',
      gubun: '%',
      contflag: '%',
      spjangcds: [],
      data: [],
      content: new OrderModel(),
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams.searchQuery || this.state.searchQuery,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 사업장 리스트
    const data = await api.dropdown('wf_dd_spjangcd_02');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery || '',
        spjangcd: this.state.spjangcd || '',
        gubun: this.state.gubun || '%',
        contflag: this.state.contflag || '%',
        stdate: this.state.stdate,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new OrderModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({ data: [] });
        this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, () => this.infinity?.retrieveAll());
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery || '',
      spjangcd: this.state.spjangcd || '',
      gubun: this.state.gubun || '%',
      contflag: this.state.contflag || '%',
      stdate: this.state.stdate,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      as_nm: this.state.searchQuery || '',
      spjangcd: this.state.spjangcd || '',
      gubun: this.state.gubun || '%',
      contflag: this.state.contflag || '%',
      stdate: this.state.stdate,
    });
  }

  render() {
    return <OrderTemplate
      scope={this}
    />;
  }
}
