import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { MonthlyIncomeStatement } from './MonthlyIncomeStatement';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TabLayout,
  TextBox,
} from '../../../../components';
import { MonthlyIncomeStatementTab } from './MonthlyIncomeStatement.tab';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서
 */
export const MonthlyIncomeStatementTemplate: React.FC<TemplateProps<MonthlyIncomeStatement>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={160}>
        <span>년도</span>

        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####"
          onChange={(value) => scope.SS({ stdate: value })}
          onEnter={(value) => scope.setState({ stdate: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.stdate}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ stdate: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.stdate}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ stdate: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
        >
        </DateTextBox>
      </FlexLayout>

      <FlexLayout size={240}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          textAlign="center"
          style={{ marginLeft: 12 }}
          data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
          onSelect={(option) => scope.setState({ spjangcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <TabLayout
        scope={scope}
        tabs={MonthlyIncomeStatementTab}
        onChange={(_, index) => scope.onTabChange(index)}
      />
    </FlexLayout>

    <ModalView
      isVisible={scope.state.misModal}
      onClose={() => scope.SS({
        misModal: false,
        misModalData: [],
        modalTitle: '',
        searchQuery: '',
      })}
      width={1400}
      height={600}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle style={{ marginBottom: 8 }}>월별 손익계산서{scope.state.modalTitle}</LayoutTitle>

        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={600}>
            <span>검색어</span>

            <TextBox
              value={scope.state.searchQuery}
              textAlign="left"
              onChange={(value) => scope.SS({ searchQuery: value })}
              onEnter={(value) => scope.setState({ searchQuery: value }, () => scope.onMisModalRetrieve())}
            >
            </TextBox>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.gridMisModal}
            header={[
              {
                id: 'misdate',
                text: '매출일자',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'misnum',
                text: '번호',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.misnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubunnm',
                text: '매출구분',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gubunnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자명',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    건 수
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="left"
                    style={{ paddingLeft: 12 }}
                  >
                    {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '매출금액',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(scope.state.misamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'billkind',
                text: '계산서발행',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.billkind === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }}
                  >
                    {x.billkind === '1' ? '발행' : '미발행'}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={scope.infinityMisModal}
            data={scope.state.misModalData}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.SS({ misModal: false, misModalData: [], searchQuery: '' })}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>

    <ModalView
      isVisible={scope.state.mijModal}
      onClose={() => scope.SS({
        mijModal: false,
        mijModalData: [],
        modalTitle: '',
        searchQuery: '',
      })}
      width={1400}
      height={600}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle style={{ marginBottom: 8 }}>월별 손익계산서{scope.state.modalTitle}</LayoutTitle>

        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={600}>
            <span>검색어</span>

            <TextBox
              value={scope.state.searchQuery}
              textAlign="left"
              onChange={(value) => scope.SS({ searchQuery: value })}
              onEnter={(value) => scope.setState({ searchQuery: value }, () => scope.onMijModalRetrieve())}
            >
            </TextBox>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.gridMijModal}
            header={[
              {
                id: 'mijdate',
                text: '매입일자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mijnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'gartnm',
                text: '그룹계정명',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gartnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'artnm',
                text: '계정명',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.artnm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    건 수
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="left"
                    style={{ paddingLeft: 12 }}
                  >
                    {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '매입',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(scope.state.mijamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkgubun',
                text: '증빙구분',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.gubuns?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={scope.infinityMijModal}
            data={scope.state.mijModalData}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.SS({ mijModal: false, mijModalData: [], searchQuery: '' })}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>

    <ModalView
      isVisible={scope.state.depositModal}
      onClose={() => scope.SS({
        depositModal: false,
        depositModalData: [],
        modalTitle: '',
        searchQuery: '',
      })}
      width={1400}
      height={600}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle style={{ marginBottom: 8 }}>월별 손익계산서{scope.state.modalTitle}</LayoutTitle>

        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={600}>
            <span>검색어</span>

            <TextBox
              value={scope.state.searchQuery}
              textAlign="left"
              onChange={(value) => scope.SS({ searchQuery: value })}
              onEnter={(value) => scope.setState({ searchQuery: value }, () => scope.onDepositModalRetrieve())}
            >
            </TextBox>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.gridDepositModal}
            header={[
              {
                id: 'rcvdate',
                text: '입금일자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rcvdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvnum',
                text: '번호',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.rcvnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubunnm',
                text: '매출구분',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gubunnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'perid',
                text: '담당자명',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.perid}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    건 수
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="left"
                    style={{ paddingLeft: 12 }}
                  >
                    {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt',
                text: '입금금액',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.rcvamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(scope.state.rcvamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'billkind',
                text: '계산서발행',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.billkind === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-black)' }}
                  >
                    {x.billkind === '1' ? '발행' : '미발행'}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={scope.infinityDepositModal}
            data={scope.state.depositModalData}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.SS({ depositModal: false, depositModalData: [], searchQuery: '' })}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>

    <ModalView
      isVisible={scope.state.payModal}
      onClose={() => scope.SS({
        payModal: false,
        payModalData: [],
        modalTitle: '',
        searchQuery: '',
      })}
      width={1400}
      height={600}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 8 }}
      >
        <LayoutTitle style={{ marginBottom: 8 }}>월별 손익계산서{scope.state.modalTitle}</LayoutTitle>

        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={600}>
            <span>검색어</span>

            <TextBox
              value={scope.state.searchQuery}
              textAlign="left"
              onChange={(value) => scope.SS({ searchQuery: value })}
              onEnter={(value) => scope.setState({ searchQuery: value }, () => scope.onPaymentModalRetrieve())}
            >
            </TextBox>
          </FlexLayout>
        </SearchLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.gridPayModal}
            header={[
              {
                id: 'snddate',
                text: '지급일자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.snddate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'sndnum',
                text: '번호',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.sndnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'gartnm',
                text: '그룹계정명',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.gartnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'artnm',
                text: '계정명',
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.artnm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout>
                    건 수
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="left"
                    style={{ paddingLeft: 12 }}
                  >
                    {scope.state.cnt_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'sndamt',
                text: '지급',
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.sndamt)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="right"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(scope.state.sndamt_tot)}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'projnm',
                text: '프로젝트명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.projnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkgubun',
                text: '증빙구분',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.gubuns?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
            ]}
            infinityHandler={scope.infinityPayModal}
            data={scope.state.payModalData}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          >
          </GridLayout>
        </FlexLayout>
      </FlexLayout>

      <SubHeader>
        <FlexLayout justify="end">
          <Button onClick={() => scope.SS({ payModal: false, payModalData: [], searchQuery: '' })}>닫기</Button>
        </FlexLayout>
      </SubHeader>
    </ModalView>
  </FlexLayout>
);
