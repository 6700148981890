import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { LastMonthPayModel } from './LastMonthPay.model';
import { LastMonthPayTemplate } from './LastMonthPay.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  GridLayout,
  GridLayoutAdditionalHeader,
} from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';


interface LastMonthPayState {
  // 검색조건
  yearmon: string;
  spjangcd: string;
  offiyn: string;

  // data
  data: Array<LastMonthPayModel>;
  focused?: LastMonthPayModel;

  // trail
  betot_samt_tot: string;
  betot_tamt_tot: string;
  betot_mamt_tot: string;
  tot_samt_tot: string;
  tot_tamt_tot: string;
  tot_mamt_tot: string;
  ttot_samt_tot: string;
  ttot_tamt_tot: string;
  ttot_mamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_06
 * @category 전월대비급여현황
 */
export class LastMonthPay extends PageComponent<PageProps, LastMonthPayState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  updatedRows?: Array<LastMonthPayModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    let year: string | number;

    today.getMonth() === 0 ? year = today.getFullYear() - 1
      : year = today.getFullYear(); // 년도

    let month: string | number;
    today.getMonth() === 0 ? month = 12
      : month = today.getMonth(); // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: `${year}${month}`,
      spjangcd: 'ZZ',
      offiyn: '%',

      // data
      data: [],

      // trail
      betot_samt_tot: '',
      betot_tamt_tot: '',
      betot_mamt_tot: '',
      tot_samt_tot: '',
      tot_tamt_tot: '',
      tot_mamt_tot: '',
      ttot_samt_tot: '',
      ttot_tamt_tot: '',
      ttot_mamt_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.yearmon,
        offiyn: this.state.offiyn,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new LastMonthPayModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['no', 'perid'],
        [this.state.focused?.no, this.state.focused?.perid], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.data[0]);
      }
      this.setState({
        betot_samt_tot: this.infinity?.box?.betot_samt_tot || '0',
        betot_tamt_tot: this.infinity?.box?.betot_tamt_tot || '0',
        betot_mamt_tot: this.infinity?.box?.betot_mamt_tot || '0',
        tot_samt_tot: this.infinity?.box?.tot_samt_tot || '0',
        tot_tamt_tot: this.infinity?.box?.tot_tamt_tot || '0',
        tot_mamt_tot: this.infinity?.box?.tot_mamt_tot || '0',
        ttot_samt_tot: this.infinity?.box?.ttot_samt_tot || '0',
        ttot_tamt_tot: this.infinity?.box?.ttot_tamt_tot || '0',
        ttot_mamt_tot: this.infinity?.box?.ttot_mamt_tot || '0',
      });
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      spjangcd: this.state.spjangcd,
      stmon: this.state.yearmon,
      offiyn: this.state.offiyn,
    });
  }

  @action
  onRowFocusEvent(item: LastMonthPayModel) {
    this.setState({ focused: item });
  }

  render() {
    return (
      <LastMonthPayTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
