import * as React from 'react';
import { inject } from 'mobx-react';
import { ExternalPageProps } from '../../../../constants';

/**
 * 컨트롤러
 * @window w_gps_lbs
 * @category 개인위치정보민원관리
 */
@inject('toolStore', 'publicStore')
export class AR extends React.Component<ExternalPageProps> {
  componentDidMount(): void {
    this.props.publicStore.withManager(() => {
      this.props.publicStore.publish({
        command: 'arview',
      });
    });
    this.props.toolStore.onCloseHandler();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <React.Fragment />
    );
  }
}
