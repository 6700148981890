import * as React from 'react';
import {
  IoIosMail, MdKeyboardArrowLeft, MdKeyboardArrowRight, RiFolder2Line,
} from 'react-icons/all';
import {
  LabelBox,
  AttachmentBox,
  TextBox,
  DateTextBox,
  GridLayout,
  FlexLayout,
  ModalView,
  FormatTextBox,
  SearchLayout,
  TelnumTextBox,
  FormatNumberTextBox,
  ComboBox,
  ComboBoxModel,
} from '../../../../components';
import { Button, BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Global, TemplateProps } from '../../../../constants';
import { Termination } from './Termination';
import { Date8, Today } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';

/*
* 모델
* @window w_tb_e101_cls
* @category 해지보고서
*/

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const TerminationTemplate: React.FC<TemplateProps<Termination>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={400}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '현장으로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 80 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'appdate',
                text: '신청일자',
                width: 15,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {Date8.withDash(x.appdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 35,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.total} 건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'stdate',
                text: '시작일',
                width: 15,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    월정보수료
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {Date8.withDash(x.stdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'enddate',
                text: '종료일',
                width: 15,
                trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.amt_tot}
                    </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {Date8.withDash(x.enddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'appgubun',
                text: '상태',
                width: 12,
                render: (x) => (
                  <FlexLayout>
                    <FlexLayout
                      align='center'
                      justify='center'
                      onDoubleClick={
                        () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                      }
                      style={{
                        marginLeft: x.appremark?.length > 0 ? 24 : 0,
                        color: x.appgubun === '101'
                          ? 'var(--color-blue)'
                          : x.appgubun === '131'
                            ? 'var(--color-red)'
                            : '',
                      }}
                    >
                      {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                    </FlexLayout>
                    { x.appremark.length > 0
                      ? <FlexLayout
                        weight={0.01}
                        align='center'
                        justify='right'
                        style={{ color: 'var(--color-red)' }}
                      >
                        <IoIosMail size={20}/>
                      </FlexLayout>
                      : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'attcnt',
                text: '첨부',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {`${x.attcnt} 건`}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.terminationList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>
        <FlexLayout
          weight={1.5}
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="신청일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.appdate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ appdate: value })}
                // onBlur={() => scope.itemChanged(TerminationItemChangeTypes.WEEKDATE)}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format={model?.appnum && '##-######-###-####'}
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="전화번호">
              <TelnumTextBox
                textAlign="center"
                value={model?.tel || ''}
                onChange={(value) => setData({ tel: value })}
              />
            </LabelBox>
            <LabelBox title="현장책임자">
              <TextBox
                textAlign="center"
                value={model?.actpernm}
                onChange={(value) => setData({ actpernm: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="해지사유">
              <ComboBox
                value={model?.sayoo}
                data={[
                  { value: '001', remark: '계약만기' },
                  { value: '002', remark: '사업종료' },
                  { value: '003', remark: '서비스불만' },
                  { value: '004', remark: '이관' },
                  { value: '005', remark: '고객의 일방적 해지' },
                  { value: '006', remark: '사용저조' },
                  { value: '007', remark: '기타' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setData({ sayoo: option.value })}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
            <LabelBox
              title="현장명"
              weight={2}
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.actnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="대수"
              weight={0.5}
            >
              <TextBox
                textAlign="center"
                value={model?.qty}
                readonly={true}
                onChange={(value) => setData({ sayoo: value })}
              />
            </LabelBox>
            <LabelBox
              title="계약기간"
              weight={1.5}
            >
              <DateTextBox
                value={model?.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={
                  (value) => setData({ stdate: value })
                    // () => scope.itemChanged(HolidayItemChangeTypes.VAFRDATE))
                }
              />
              <span style={{
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}> ~
              </span>
              <DateTextBox
                value={model?.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={
                  (value) => setData({ enddate: value })
                    // () => scope.itemChanged(HolidayItemChangeTypes.VATODATE))
                }
              />
            </LabelBox>
            <LabelBox
              title="월정보수료"
              weight={0.5}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.amt}
                onChange={(value) => setData({ amt: value })}
              />
            </LabelBox>
            <LabelBox
              title="미수금"
              weight={0.5}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.amt}
                onChange={(value) => setData({ amt: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={250}>
            <LabelBox title="고객요구안">
              <TextBox
                isMultiline={true}
                value={model?.bigo}
                onChange={(value) => setData({ bigo: value })}
              />
            </LabelBox>
            <LabelBox title="협의결과">
              <TextBox
                isMultiline={true}
                value={model?.result}
                onChange={(value) => setData({ result: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox title="특이사항" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: (model?.attcnt) === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={(model?.attcnt) === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="타사업체명(담당자)"
              weight={3}
            >
              <TextBox
                value={model?.tasacltnm}
                onChange={(value) => setData({ tasacltnm: value })}
              />
            </LabelBox>
            <LabelBox title="계약월">
              <DateTextBox
                value={model?.tasacontdate}
                textAlign="center"
                format="####-##"
                onChange={(value) => { setData({ tasacontdate: value }); }}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
            <LabelBox title="월정보수료">
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.tasaamt}
                onChange={(value) => setData({ tasaamt: value })}
              />
            </LabelBox>
            <LabelBox title="단가">
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.tasauamt}
                onChange={(value) => setData({ tasauamt: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data?.appnum}
            appnm={'해지보고서'}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });
            }}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
