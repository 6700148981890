/**
 * 모델
 * @window w_tb_e027
 * @category 검사담당부서등록
 */
import { computed } from 'mobx';

export class CheckDepartmentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 코드
   *
   * **data type** varchar(2)
   */
  gareacd: string;

  /**
   * 검사담당부서명
   *
   * **data type** varchar(20)
   */
  gareanm: string;

  /**
   * 그룹지역명
   *
   * **data type** varchar(20)
   */
  abbnm: string;

  /**
   * 담당자
   *
   * **data type** char(10)
   */
  perid: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  useyn: string;

  /**
   * 순번
   *
   * **data type** float
   */
  seq: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 출력순번
   *
   * **data type** int
   */
  printseq: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   *전화번호
   *
   * **data type** varchar(20)
   */
  telnum: string;

  /**
   *퍅수
   *
   * **data type** varchar(20)
   */
  faxnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.gareacd = data.gareacd || '';
    this.gareanm = data.gareanm || '';
    this.abbnm = data.abbnm || '';
    this.perid = data.perid || '';
    this.useyn = data.useyn || '';
    this.seq = data.seq || '';
    this.remark = data.remark || '';
    this.printseq = data.printseq || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.telnum = data.telnum || '';
    this.faxnum = data.faxnum || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
