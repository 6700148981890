import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
  ComboBoxModel,
  ComboBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Region } from './Region';

/**
 * 화면
 * @window w_tb_e028
 * @category 지역등록
 */
export const RegionTemplate: React.FC<TemplateProps<Region>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'areacd',
          text: '코드',
          width: 40,
        },
        {
          id: 'areanm',
          text: '지역명',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.areanm}
              onChange={(value) => rowUpdate({ areanm: value })}
            />
          ),
        },
        {
          id: 'gareacd',
          text: '검사담당부서',
          width: 100,
          render: (x, rowUpdate) => (
            <ComboBox
              value={x.gareacd}
              textAlign="center"
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.gareacds?.map((y) => new ComboBoxModel(y.gareacd, y.gareanm))}
              onSelect={(option) => rowUpdate({ gareacd: option.value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 30,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 100,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
