import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { BillStatement } from './BillStatement';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';

const receflags = [
  { value: '%', remark: '전체' },
  { value: '0', remark: '미수령' },
  { value: '1', remark: '어플수령' },
  { value: '2', remark: '파일수령' },
];

const gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '기타수입' },
  { value: '18', remark: '기타환불' },
];

const recivstatuss = [
  { value: '%', remark: '전체' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'B', remark: '발송전' },
  { value: 'A', remark: 'KTNET접수' },
];

/**
 * 화면
 * @window w_tb_ca606w_01
 * @category 자재청구현황
 */

export const BillStatementTemplate: React.FC<TemplateProps<BillStatement>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={250}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
          <span>공사구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '11', remark: '유지보수' },
              { value: '12', remark: '수리공사' },
              { value: '13', remark: '부품교체' },
              { value: '14', remark: '설치공사' },
              { value: '15', remark: '리모델링' },
              { value: '16', remark: '현대엘리베이터(공)' },
              { value: '17', remark: '기타수입' },
              { value: '18', remark: '기타환불' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => { scope.onRetrieveEvent(); })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={200}>
          <span>확인서구분</span>
          <ComboBox
            value={scope.state.receflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '미수령' },
              { value: '1', remark: '어플수령' },
              { value: '2', remark: '파일수령' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ receflag: option.value },
              () => { scope.onRetrieveEvent(); })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            담당자
          </span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={700}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 청구비고, 발주비고, 품목, 규격으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>유무상구분</span>
          <ComboBox
            value={scope.state.myn}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '유상' },
              { value: '0', remark: '무상' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ myn: option.value },
              () => { scope.onRetrieveEvent(); })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => scope.setState({ wkactcd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                scope.setState({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            value={scope.state.wkactnm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        header={[
          {
            id: 'myn',
            text: '유무상',
            width: 75,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.myn === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.myn === '1' ? '유상' : '무상'}
              </FlexLayout>
            ),
          },
          {
            id: 'pumdate',
            text: '청구일자',
            width: 100,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##-##"
                value={x.pumdate}
              />
            ),
          },
          {
            id: 'pumnum',
            text: '청구번호',
            width: 75,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pumnum}
              </FlexLayout>
            ),
          },
          {
            id: 'costflag',
            text: '견적여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.costflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.costflag === '1' ? 'O' : 'X'}
              </FlexLayout>
            ),
          },
          {
            id: 'compflag',
            text: '공사여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.compflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.compflag === '1' ? 'O' : 'X'}
              </FlexLayout>
            ),
          },
          {
            id: 'balflag',
            text: '발주여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.balflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.balflag === '1' ? 'O' : 'X'}
              </FlexLayout>
            ),
          },
          {
            id: 'ibgflag',
            text: '입고여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.ibgflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.ibgflag === '1' ? 'O' : 'X'}
              </FlexLayout>
            ),
          },
          {
            id: 'demflag',
            text: '불출여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.demflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.demflag === '1' ? 'O' : 'X'}
              </FlexLayout>
            ),
          },
          {
            id: 'receflag',
            text: '확인서수령여부',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.receflag === '1' || x.receflag === '2' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {receflags.filter((y) => y.value === x.receflag)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'misflag',
            text: '매출여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.misflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.misflag === '1' ? 'O' : 'X'}
              </FlexLayout>
            ),
          },
          {
            id: 'wkactnm',
            text: '회사',
            width: 75,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.wkactnm}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'equpnm',
            text: '호기명',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.equpnm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'gubun',
            text: '공사구분',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'pcode',
            text: '품목코드',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pcode}
              </FlexLayout>
            ),
          },
          {
            id: 'pname',
            text: '품목명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.pname}
              </FlexLayout>
            ),
          },
          {
            id: 'psize',
            text: '규격',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.psize}
              </FlexLayout>
            ),
          },
          {
            id: 'qty',
            text: '수량',
            width: 30,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {x.qty}
              </FlexLayout>
            ),
          },
          {
            id: 'uamt',
            text: '단가',
            width: 100,
            render: (x, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="right"
                value={x.uamt}
              />
            ),
          },
          {
            id: 'samt',
            text: '금액',
            width: 100,
            render: (x, ref) => (
              <FormatNumberTextBox
                ref={ref}
                char={','}
                charAt={3}
                textAlign="right"
                value={x.samt}
              />
            ),
          },
          {
            id: 'remark',
            text: '청구비고',
            width: 250,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'bal_remark',
            text: '발주비고',
            width: 250,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.bal_remark}
              </FlexLayout>
            ),
          },
          {
            id: 'baldate',
            text: '발주일자',
            width: 100,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##-##"
                value={x.baldate}
              />
            ),
          },
          {
            id: 'recivstatus',
            text: '전자세금계산서',
            width: 150,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.recivstatus === 'B' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.billStatementList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        // onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
);
