import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export default class ResultModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 결재일
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   * 결재일
   *
   * **data type** varchar(8)
   */
  readonly appdate_max: string;


  /**
   * 결재구분
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   * 문서번호
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 결재자
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   * 결재권자 지시사항
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   * 결재시간
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   * 완료율
   *
   * **data type** varchar(8)
   */
  readonly comperyul: string;

  /**
   * 완료율2
   *
   * **data type** varchar(8)
   */
  readonly comperyul2: string;

  /**
   * 완료율3
   *
   * **data type** varchar(8)
   */
  readonly comperyul3: string;

  /**
   * 완료갯수
   *
   * **data type** varchar(8)
   */
  readonly comppercnt: string;

  /**
   * 지급액
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   * 매입금액
   *
   * **data type** varchar(8)
   */
  readonly mijamt: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  readonly misdate: string;

  /**
   * 수주금액
   *
   * **data type** varchar(8)
   */
  readonly ordamt: string;

  /**
   * 수주일자
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 담당자2
   *
   * **data type** varchar(8)
   */
  readonly perid2: string;

  /**
   * 담당자3
   *
   * **data type** varchar(8)
   */
  readonly perid3: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 담당자명2
   *
   * **data type** varchar(8)
   */
  readonly pernm2: string;

  /**
   * 담당자명3
   *
   * **data type** varchar(8)
   */
  readonly pernm3: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 수금
   *
   * **data type** varchar(8)
   */
  readonly rcvflag: string;

  /**
   * 수금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 수금일
   *
   * **data type** varchar(8)
   */
  readonly rcvdate: string;

  /**
   * 참조
   *
   * **data type** varchar(8)
   */
  readonly refer: string;

  /**
   * 지급유무
   *
   * **data type** varchar(8)
   */
  readonly sndflag: string;

  /**
   * 사업장번호
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 적용금액
   *
   * **data type** varchar(8)
   */
  readonly useamt: string;

  /**
   * 적용금액2
   *
   * **data type** varchar(8)
   */
  readonly useamt2: string;

  /**
   * 적용금액3
   *
   * **data type** varchar(8)
   */
  readonly useamt3: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.appdate = data.appdate || '';
    this.appdate_max = data.appdate_max || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.apptime = data.apptime || '';
    this.comperyul = data.comperyul || '';
    this.comperyul2 = data.comperyul2 || '';
    this.comperyul3 = data.comperyul3 || '';
    this.comppercnt = data.comppercnt || '';
    this.iamt = data.iamt || '';
    this.mijamt = data.mijamt || '';
    this.misdate = data.misdate || '';
    this.ordamt = data.ordamt || '';
    this.orddate = data.orddate || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
    this.perid3 = data.perid3 || '';
    this.pernm = data.pernm || '';
    this.pernm2 = data.pernm2 || '';
    this.pernm3 = data.pernm3 || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.rcvflag = data.rcvflag || '';
    this.rcvamt = data.rcvamt || '';
    this.rcvdate = data.rcvdate || '';
    this.refer = data.refer || '';
    this.sndflag = data.sndflag || '';
    this.spjangcd = data.spjangcd || '';
    this.useamt = data.useamt || '';
    this.useamt2 = data.useamt2 || '';
    this.useamt3 = data.useamt3 || '';
    this.gubun = data.gubun || '';
  }

  @computed
  get isReported() {
    return this.appgubun && (this.appgubun !== '' && this.appgubun !== '131');
  }

  @computed
  get isReportedCancelable() {
    return this.isReported && this.appgubun === '001';
  }
}
