import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export default class ContractDefaultModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 아이디
   *
   * **data type** varchar(8)
   */
  readonly id: string;

  /**
   * 계약자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 첨부갯수
   *
   * **data type** varchar(8)
   */
  readonly attcnt: string;

  /**
   * 발주일
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   * 계약일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 회사주소
   *
   * **data type** varchar(8)
   */
  readonly custadres: string;

  /**
   * 회사주소2
   *
   * **data type** varchar(8)
   */
  readonly custadres2: string;

  /**
   * 회사명
   *
   * **data type** varchar(8)
   */
  readonly custnm: string;

  /**
   * 회사우편번호
   *
   * **data type** varchar(8)
   */
  readonly custzipcd: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 검사일
   *
   * **data type** varchar(8)
   */
  readonly kcdate: string;

  /**
   * 합계
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 문서코드
   *
   * **data type** varchar(8)
   */
  readonly papercd: string;

  /**
   * 대표자명
   *
   * **data type** varchar(8)
   */
  readonly prenm: string;

  /**
   * 곰사범위1
   *
   * **data type** varchar(8)
   */
  readonly range1: string;

  /**
   * 곰사범위2
   *
   * **data type** varchar(8)
   */
  readonly range2: string;

  /**
   * 곰사범위3
   *
   * **data type** varchar(8)
   */
  readonly range3: string;

  /**
   * 곰사범위4
   *
   * **data type** varchar(8)
   */
  readonly range4: string;

  /**
   * 곰사범위5
   *
   * **data type** varchar(8)
   */
  readonly range5: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 사업자 구분
   *
   * **data type** varchar(8)
   */
  readonly rflag: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(8)
   */
  readonly saupnum: string;

  /**
   * 사업장명
   *
   * **data type** varchar(8)
   */
  readonly spjangnm: string;

  /**
   * 착수금
   *
   * **data type** varchar(8)
   */
  readonly stamt: string;

  /**
   * 모룸
   *
   * **data type** varchar(8)
   */
  readonly stmamt: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly title: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly xaddress: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly xaddress2: string;

  /**
   * 사업장명
   *
   * **data type** varchar(8)
   */
  readonly xspjangnm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly xzipcd: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcd: string;

  /**
   * 핸드폰번호
   *
   * **data type** varchar(8)
   */
  readonly hpnum: string;

  /**
   * 이메일주소
   * **data type** varchar(8)
   */
  readonly email: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.attcnt = data.attcnt || '0';
    this.baldate = data.baldate || '';
    this.contdate = data.contdate || '';
    this.custadres = data.custadres || '';
    this.custadres2 = data.custadres2 || '';
    this.custcd = data.custcd || '';
    this.custnm = data.custnm || '';
    this.custzipcd = data.custzipcd || '';
    this.id = data.id || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.kcdate = data.kcdate || '';
    this.mamt = (`${data.mamt || '0'}`).replace(/,/ig, '');
    this.papercd = data.papercd || '';
    this.pernm = data.pernm || '';
    this.prenm = data.prenm || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.range1 = data.range1 || '';
    this.range2 = data.range2 || '';
    this.range3 = data.range3 || '';
    this.range4 = data.range4 || '';
    this.range5 = data.range5 || '';
    this.remark = data.remark || '';
    this.rflag = data.rflag || '';
    this.samt = (`${data.samt || '0'}`).replace(/,/ig, '');
    this.saupnum = data.saupnum || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.stamt = (`${data.stamt || '0'}`).replace(/,/ig, '');
    this.stmamt = (`${data.stmamt || '0'}`).replace(/,/ig, '');
    this.tamt = (`${data.tamt || '0'}`).replace(/,/ig, '');
    this.title = data.title || '';
    this.xaddress = data.xaddress || '';
    this.xaddress2 = data.xaddress2 || '';
    this.xspjangnm = data.xspjangnm || '';
    this.xzipcd = data.xzipcd || '';
    this.zipcd = data.zipcd || '';
    this.hpnum = data.hpnum || '';
    this.email = data.email || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
