import { computed } from 'mobx';

/**
 * 자격증 모델
 * @window w_p2110
 * @category 인사등록
 */
export class CertificateModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 직원번호
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   * 자격증번호
   *
   * **data type** varchar(30)
   */
  readonly licencenum: string;

  /**
   * 자격종류
   *
   * **data type** varchar(255)
   */
  readonly licence: string;

  /**
   * 취득일
   *
   * **data type** varchar(8)
   */
  readonly licenceday1: string;

  /**
   * 만료일
   *
   * **data type** varchar(8)
   */
  readonly licenceday2: string;

  /**
   * 발생기관
   *
   * **data type** varchar(50)
   */
  readonly licencewhere: string;

  /**
   * 자체점검관련
   *
   * **data type** varchar(1)
   */
  readonly checkflag: string;

  /**
   * 점검(교육)예정
   *
   * **data type** varchar(1)
   */
  readonly checkflag2: string;

  /**
   * 점검일지반영
   *
   * **data type** varchar(1)
   */
  readonly kcflag: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.seq = data.seq || '';
    this.licencenum = data.licencenum || '';
    this.licence = data.licence || '';
    this.licenceday1 = data.licenceday1 || '';
    this.licenceday2 = data.licenceday2 || '';
    this.licencewhere = data.licencewhere || '';
    this.checkflag = data.checkflag || '';
    this.checkflag2 = data.checkflag2 || '';
    this.kcflag = data.kcflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
