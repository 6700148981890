import * as React from 'react';
import {
  FiSave,
  FiUpload,
} from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import { BsDownload } from 'react-icons/bs';
import Dropzone from 'react-dropzone';
import { Button, ButtonProps } from '../Button/Button';
import { FlexLayout, DragAndDropLayout } from '../../layout';
import { Global } from '../../../constants';
import { LayoutTitle } from '../LayoutTitle';
import { LoaderButton } from '../LoaderButton';
import { Format } from '../../../utils/string';
import { ConfirmFail, ConfirmSuccess, ConfirmWarning } from '../../../utils/confirm';
import { FileSelector } from '../../../utils/file';
import { ActionStore, PublicStore } from '../../../stores';
import { AttachmentInfo } from '../../../models';
import { IFrame } from '../IFrame';
import { CheckBox } from '../CheckBox';
import { TextBox } from '../TextBox';
import { ImageView } from '../ImageView';
import { ToBase64 } from '../../../utils/binary';
import { HwpView } from '../HwpView';
import style from './AttachmentBox.module.scss';
import { PageComponent } from '../../../utils';

const fileDownload = require('js-file-download');

interface AttachmentBoxState {
  attachments: Array<AttachmentInfo>;
  percent: number;
  iframe?: string;
  preview: number;
  binary?: ArrayBuffer;
}

interface AttachmentBoxProps extends ButtonProps {
  publicStore?: PublicStore;
  actionStore?: ActionStore;
  appnum: string; // 문서 번호
  appnm: string; // 문서 이름(ex: 휴가신청서, 견적서)
  isEnabledTitle?: boolean; // 제목 기입 가능 여부
  isEnabledPreview?: boolean; // 미리보기 여부
  onChangeCount?: (count: number) => any;
  onPreview?: (type: PREVIEW_OPTION, uri: string) => any;
}

export enum PREVIEW_OPTION {
  IMAGE,
  PDF,
  HWP,
  NONE,
}

@inject('publicStore', 'actionStore')
export class AttachmentBox extends PageComponent<
  AttachmentBoxProps,
  AttachmentBoxState
> {
  table: React.RefObject<DragAndDropLayout>;

  focused?: AttachmentInfo;

  updates: Array<AttachmentInfo> = [];

  constructor(props: AttachmentBoxProps, context: any) {
    super(props, context);
    this.table = React.createRef();
    this.state = {
      attachments: [],
      percent: 0,
      preview: PREVIEW_OPTION.NONE,
    };
  }

  componentDidMount() {
    this.retrieve();
  }

  componentDidUpdate(prevProps: Readonly<AttachmentBoxProps>) {
    if (prevProps.appnum !== this.props.appnum) {
      this.retrieve();
    }
  }

  componentWillUnmount() {
    if (this.updates.length > 0) {
      this.save();
    }
  }

  @action
  async retrieve() {
    const { actionStore: api, publicStore } = this.props;

    this.updates = [];
    await this.SS({
      attachments: [],
      binary: new ArrayBuffer(0),
    });

    const data = await api!.attachment(
      {
        custcd: publicStore?.user.custcd,
        spjangcd: publicStore?.user.spjangcd,
        perid: publicStore?.user.perid,
        appnum: this.props.appnum,
      },
      'retrieve',
    );

    if (data) {
      await this.SS({
        attachments: data.items?.map((x: any) => new AttachmentInfo(x)) || [],
      });
      await this.table.current?.update();
      if (data.items?.length) {
        await this.table.current?.setFocus(0, 3);
      }
    } else {
      await this.table.current?.update();
    }
  }

  @action
  async addAttachment() {
    try {
      const files = await FileSelector.multi(false);
      await this.selectedAttachments(files);
    } catch {
      ConfirmFail.show('오류', '파일 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async selectedAttachments(files: FileList) {
    try {
      for (let i = 0; i < files.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.uploadAttachment(files[i].name, files[i], i);
      }
    } catch {
      ConfirmFail.show('오류', '파일 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async uploadAttachment(filename: string, file: File, index: number, fixedFileSeq: number = -1) {
    const { actionStore: api, publicStore } = this.props;

    await this.SS({
      percent: 1,
    });

    const { data } = await api?.tempUpload(file, filename, (e: any) => {
      const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
      this.updatePercent(percent);
    });

    if (data) {
      const attachment = await api?.attachment(
        {
          custcd: publicStore?.user.custcd,
          spjangcd: publicStore?.user.spjangcd,
          perid: publicStore?.user.perid,
          appnum: this.props.appnum,
          appnm: this.props.appnm,
          title: '',
          fileseq: fixedFileSeq === -1 ? this.getLastFileSeq() + 1 + index : fixedFileSeq,
          tempfile: data,
        },
        'upload',
      );

      if (attachment) {
        this.updatePercent(0);
        fixedFileSeq === -1 && await this.retrieve();
      } else {
        this.updatePercent(0);
      }
    } else {
      this.updatePercent(0);
    }
  }

  @action
  async save() {
    const { actionStore: api } = this.props;
    const data = await api?.attachment(
      {
        items: this.state.attachments.map((x, i) => ({
          new: '0',
          spjangcd: x.spjangcd,
          appnum: x.appnum,
          fileseq: x.fileseq,
          sort: `000${i}`.substr(-3),
          title: x.title,
        })),
      },
      'save',
    );
    ConfirmSuccess.showPbMessage(data);
    this.props.onChangeCount && this.props.onChangeCount(this.state.attachments.length);
  }

  getLastFileSeq() {
    if (!this.state.attachments.length) return 0;
    const seq = this.state.attachments[this.state.attachments.length - 1].fileseq;
    return parseInt(seq, 10);
  }

  @action
  updatePercent(percent: number) {
    this.setState({ percent });
  }

  @action
  async attachmentBinary(info: AttachmentInfo): Promise<ArrayBuffer> {
    const { actionStore: api } = this.props;
    return api!.attachmentBinary({
      ...info,
      sub: 'w_popup_attach',
    });
  }

  @action
  async attachmentDownload(info: AttachmentInfo) {
    const binary = await this.attachmentBinary(info);

    try {
      fileDownload(binary, info.filename.trim());
    } catch {
      ConfirmFail.show('오류', '다운로드에 실패하였습니다.');
    }
  }

  @action
  async attachmentRemove(): Promise<boolean> {
    const { actionStore: api } = this.props;
    const items = this.state.attachments.filter((x) => x.delete);

    if (!items.length) {
      ConfirmWarning.show('오류', '삭제할 파일이 없습니다!');
      return false;
    }

    try {
      const data = await api?.attachment(
        {
          items: this.state.attachments.filter((x) => x.delete),
        },
        'wb_delete',
      );

      if (data) {
        ConfirmSuccess.showPbMessage(data);
        this.retrieve();
      } else {
        ConfirmFail.show('오류', '서버 오류로 첨부파일이 삭제되지 않았습니다!');
      }
      this.props.onChangeCount && this.props.onChangeCount(this.state.attachments.length);
      return true;
    } catch {
      return false;
    }
  }

  @action
  async loadBinary(item: AttachmentInfo) {
    this.focused = item;

    const binary = await this.attachmentBinary(item);
    let option = PREVIEW_OPTION.NONE;
    let mimeType = '';

    switch (item.extension.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        option = PREVIEW_OPTION.IMAGE;
        mimeType = `image/${item.extension.toLowerCase()}`;
        break;

      case 'pdf':
        option = PREVIEW_OPTION.PDF;
        mimeType = 'application/pdf';
        break;

      case 'hwp':
        option = PREVIEW_OPTION.HWP;
        mimeType = 'application/pdf';
        this.setState({
          binary,
        });
        break;

      default:
      //
    }

    if (option === PREVIEW_OPTION.PDF) {
      const url = URL.createObjectURL(new Blob([binary], {
        type: 'application/pdf',
      }));

      this.setState({
        iframe: url,
        preview: option,
      }, () => this.table.current?.update(false));

      this.props.onPreview && this.props.onPreview(
        option,
        url,
      );
    } else {
      const b64 = await ToBase64.fromArrayBuffer(binary);

      this.setState({
        iframe: `data:${mimeType};base64,${b64}`,
        preview: option,
      }, () => this.table.current?.update(false));

      this.props.onPreview && this.props.onPreview(
        option,
        `data:${mimeType};base64,${b64}`,
      );
    }
  }

  @action
  async updateImage(b64: string) {
    const f = ToBase64.dataURLtoFile(b64, this.focused?.filename || 'image.png');
    await this.uploadAttachment(this.focused?.filename || 'image.png', f, 0, parseInt(this.focused?.fileseq || '1', 10));
    this.focused && this.loadBinary(this.focused);
  }

  render() {
    const {
      percent,
    } = this.state;

    return (
      <FlexLayout isVertical={true}>
        <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
          <LayoutTitle weight={1}>
            첨부 파일
          </LayoutTitle>
          <Button
            onClick={() => this.attachmentRemove()}
          >
            <IoMdTrash />
            <span>삭제</span>
          </Button>
          {this.props.isEnabledTitle && <Button
            onClick={() => this.save()}
          >
            <FiSave />
            <span>저장</span>
          </Button>}
          <LoaderButton
            onClick={() => this.addAttachment()}
            percent={percent}
          >
            <FiUpload />
            <span>내 PC</span>
          </LoaderButton>
        </FlexLayout>

        <FlexLayout>
          <FlexLayout weight={2}>
            <Dropzone onDrop={(acceptedFiles: any) => this.selectedAttachments(acceptedFiles)}>
              {({ getRootProps, isDragActive }) => (
                <div {...getRootProps()} style={{ display: 'contents' }}>
                  {isDragActive && <div className={style.dropzone}>여기에 파일을 놓으면 업로드 됩니다</div>}
                  <DragAndDropLayout
                    ref={this.table}
                    header={[
                      {
                        id: 'fileseq',
                        text: '순번',
                        width: 10,
                      }, {
                        id: 'delete',
                        text: '삭제',
                        width: 10,
                        render: (x: any, rowUpdate) => (
                          <FlexLayout justify="center" align="center">
                            <CheckBox
                              noMargin
                              value={x.delete}
                              onChange={(v) => rowUpdate({ delete: v })}
                            />
                          </FlexLayout>
                        ),
                      }, {
                        id: 'download',
                        text: '다운로드',
                        width: 10,
                        render: (x: any) => (
                          <FlexLayout justify="center" align="center">
                            <Button
                              isIcon={true}
                              onClick={() => this.attachmentDownload(x)}
                            >
                              <BsDownload size={19} />
                            </Button>
                          </FlexLayout>
                        ),
                      }, {
                        id: 'filename',
                        text: '파일명',
                        width: this.props.isEnabledTitle ? 20 : 40,
                        render: (x: any) => (
                          <FlexLayout justify="start" align="center">
                            {x.filename.startsWith('.') ? `이름없음${x.filename}` : x.filename}
                          </FlexLayout>
                        ),
                      }, {
                        id: 'title',
                        text: '제목',
                        width: 20,
                        isHidden: !this.props.isEnabledTitle,
                        render: (x: any, rowUpdate, refs) => (
                          <TextBox
                            ref={refs}
                            value={x.title}
                            onChange={(v) => rowUpdate({ title: v })}
                          />
                        ),
                      }, {
                        id: 'extension',
                        text: '확장자',
                        width: 10,
                      }, {
                        id: 'size',
                        text: '파일크기',
                        width: 20,
                        render: (x: any) => (
                          <FlexLayout justify={x.size ? 'end' : 'center'} align="center">
                            {x.size ? `${Format.number(x.size)}B` : '모름'}
                          </FlexLayout>
                        ),
                      },
                    ]}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    data={this.state.attachments || []}
                    onChange={(rows: Array<AttachmentInfo>, updates: Array<AttachmentInfo>) => {
                      this.updates = updates;
                      this.setState({ attachments: rows });
                    }}
                    onRowFocusEvent={(item) => this.loadBinary(item)}
                  />
                </div>
              )}
            </Dropzone>
          </FlexLayout>
          {this.props.isEnabledPreview && <FlexLayout weight={1} isVertical={true}>
            <LayoutTitle>
              미리보기
            </LayoutTitle>
            {this.state.preview === PREVIEW_OPTION.IMAGE
              && <ImageView
              src={this.state.iframe}
              style={{ marginBottom: 0 }}
              onImageUpdate={(b64) => this.updateImage(b64)}
            />}
            {this.state.preview === PREVIEW_OPTION.PDF
              && <IFrame src={this.state.iframe} />}
            {this.state.preview === PREVIEW_OPTION.HWP
              && <HwpView binary={this.state.binary!} />}
            {this.state.preview === PREVIEW_OPTION.NONE
              && <IFrame src={this.state.iframe} />}
          </FlexLayout>}
        </FlexLayout>
      </FlexLayout>
    );
  }
}
