import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Worker } from '../Worker';
import WorkerModel from '../models/WorkerModel';

/**
 * 화면 > 좌측 목록
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerGridHeader: GLHF<WorkerModel, Worker> = () => ([
  {
    id: 'pernm',
    text: '성명',
    width: 40,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'telnum',
    text: '핸드폰번호',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.hpnum}
      </FlexLayout>
    ),
  },
]);
