import * as React from 'react';
import {
  AttachmentBox, FlexLayout,
} from '../../../../../components';
import { TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';

/**
 * 화면 > 상세 > 탭 - 첨부내역
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabAttachment: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <AttachmentBox
        appnum={`e601sc-${scope.state.content?.actcd}`}
        appnm="설치계약서"
        isEnabledTitle={true}
      />
    </FlexLayout>
  );
};
