import React from 'react';
import {
  Global,
  TemplateProps,
} from '../../../../../constants';
import { ChargeReceivable } from '../ChargeReceivable';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { IntegrationTabGridHeader } from '../heades/IntegrationTabGridHeader';

export const IntegrationTemplate: React.FC<TemplateProps<ChargeReceivable>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayout
      ref={scope.gridIntegration}
      header={IntegrationTabGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataIntegration}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      onRowDoubleClick={() => scope.textModal(true)}
    />
  </FlexLayout>
);
