import * as React from 'react';
import { action } from 'mobx';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
// @ts-ignore
import { PaymentRegisterTemplate } from './PaymentRegister.template';
import { GridLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import {
  PaymentRegisterModel,
  PaymentRegisterDetailModel,
} from './models';
import { ConfirmWarning } from '../../../../utils/confirm';
import Confirm from '../../../../utils/confirm/Confirm';
import { PageComponent } from '../../../../utils/layout';

interface PaymentRegisterState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;
  gubun: string;
  dataChk: string
  dataChk2: string

  // data
  data: PaymentRegisterModel;
  lastNewData: PaymentRegisterModel;
  PaymentRegisterList: Array<PaymentRegisterModel>;
  PaymentRegisterDetailList: Array<PaymentRegisterDetailModel>;
  focused?: PaymentRegisterModel;
  focusIndex: number;
  chk: string;
  focusedDetail?: PaymentRegisterDetailModel;
}

/**
 * 컨트롤러
 * @window w_tb_ca643
 * @category 가지급등록
 */
export class PaymentRegister extends PageComponent<PageProps, PaymentRegisterState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      dataChk: `${year}${month}${date}`,
      searchQuery: '',
      gubun: '%',

      // data
      focusIndex: 0,
      chk: '0',
      dataChk2: '0',
      PaymentRegisterList: [],
      PaymentRegisterDetailList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          PaymentRegisterList: [
            ...this.state.PaymentRegisterList,
            ...items.map((x: any) => new PaymentRegisterModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          PaymentRegisterList: [],
          PaymentRegisterDetailList: [],
          data: new PaymentRegisterModel(),
        });

        await this.infinity?.retrieveAll();
        if (this.state.PaymentRegisterList && this.state.PaymentRegisterList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      PaymentRegisterList: [],
      PaymentRegisterDetailList: [],
      data: new PaymentRegisterModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['snddate', 'inperid'],
        [lastSelected?.snddate, lastSelected?.inperid], type, true) || 0;
      if (this.state.PaymentRegisterList && this.state.PaymentRegisterList.length > index) {
        await this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  async onRowFocusEvent(item: PaymentRegisterModel, index: number) {
    const { actionStore: api } = this.props;
    if (item?.new === '1') {
      this.setState({
        data: this.state.lastNewData,
      });
      return;
    }

    this.setState({ focused: item, focusIndex: index }, async () => {
      const datas = await api.fxExec('dw_1_RowFocuschanged',
        {
          snddate: item?.snddate,
          sndnum: item?.sndnum,
        });
      if (datas?.items) {
        this.setState({
          data: new PaymentRegisterModel(datas, false),
          PaymentRegisterDetailList: datas.items,
        });
      } else {
        this.setState({
          PaymentRegisterDetailList: [],
        });
      }
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data?.isNew === true) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const data = await api.new({ snddate: this.state.focused?.snddate });
    data && this.setState({
      data: new PaymentRegisterModel(data, true),
      lastNewData: new PaymentRegisterModel(data, true),
      PaymentRegisterList: [
        data,
        ...this.state.PaymentRegisterList,
      ],
      PaymentRegisterDetailList: [],
      chk: '1',
    }, async () => {
      await this.grid.current?.setFocus(0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data.amtflag === 'H' && this.state.data.bankcd) {
      ConfirmWarning.show('확인', '지급은행이 들어가있는데 지급유형이 현금으로 되어있습니다. 확인해주세요');
      return;
    }

    let warning: boolean;
    if (this.state.data.indate > this.state.dataChk && this.state.dataChk2 === '0') {
      // @ts-ignore
      warning = await Confirm.show('확인', '지급일자가 금일이후 입니다. 맞습니까?', ConfirmType.QUESTION);
      if (warning === true) this.setState({ dataChk2: '1' });
      if (warning === false) return;
      return;
    }

    if (await api.save({
      ...this.state.data,
      new: this.state.data?.isNew ? '1' : '0',
    }, true)) {
      await this.grid.current?.setFocus(this.state.PaymentRegisterList.length - 1);
      this.setState({ dataChk2: '0' });
    }
    await this.onRetrieveEvent();
    this.setState({ chk: '1' });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `지급일자: ${this.state.focused?.snddate} 지급번호: ${this.state.focused?.sndnum}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent(RetrieveFocusType.END);
  }


  render() {
    return (
      <PaymentRegisterTemplate
        scope={this}
        // @ts-ignore
        update={(change, callback) => {
          this.setState({
            data: new PaymentRegisterModel({
              ...this.state.data,
              ...change,
            }, this.state.data?.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
