import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import FloorPlanModel from './models/FloorPlanModel';
import { FloorPlanTemplate } from './FloorPlan.template';
import { PageComponent } from '../../../../utils';

interface FloorPlanState {
  searchQuery: string;
  searchFilenm: string;
  data: Array<FloorPlanModel>;
  focusIndex: number;
  content: FloorPlanModel;
  iframe?: string;
  preview: number;
}

/**
 * 컨트롤러
 * @window w_tb_e601_dp_01
 * @category 보수현장도면
 */
export class FloorPlan extends PageComponent<PageProps, FloorPlanState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      data: [],
      focusIndex: 0,
      preview: 0,
      content: new FloorPlanModel(),
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery || '',
        filenm: this.state.searchFilenm || '',
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new FloorPlanModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.onRowFocusEvent(this.state.data[0], 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.state.data.length > 0 && this.onRowFocusEvent(this.state.data[0], 0);
    });
  }

  @action
  async onRowFocusEvent(item: FloorPlanModel, index: number) {
    this.setState({
      content: item,
      focusIndex: index,
    });
  }

  render() {
    return <FloorPlanTemplate
      scope={this}
    />;
  }
}
