import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { MaintenanceTemplate } from './Maintenance.template';
import { MaintenanceModel } from './Maintenance.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { Confirm, ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface MaintenanceState {
  // search
  searchQuery: string;
  yearmon: string;
  gubun?: Array<any>;
  searchGubun?: string;
  billgubun?: string;
  wkactcd: string;
  wkactnm: string;

  // data
  data: Array<MaintenanceModel>;
  focused?: MaintenanceModel;
  focusIndex: number;
  pushTotalCheck: boolean;
  pushData: Array<MaintenanceModel>;

  // trail
  contamt_tot: string;
  amt_tot: string;
  addamt_tot: string;
  misamt_tot: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_e050
 * @category 유지관리매출생성
 */
export class Maintenance extends PageComponent<PageProps, MaintenanceState>
  implements PageToolEvents {
  updatedRows?: Array<MaintenanceModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  miscreateFlag: boolean = false;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: Today.yearMon(),
      searchQuery: '',
      billgubun: '%',
      searchGubun: '11',
      wkactcd: '001',
      wkactnm: '자사',

      // data
      focusIndex: 0,
      data: [],

      // trail
      contamt_tot: '0',
      amt_tot: '0',
      addamt_tot: '0',
      misamt_tot: '0',
      total: '0',
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const json = JSON.parse(JSON.parse(message));

    if (json?.key === 'wb_miscreate_lazytask') {
      ConfirmWarning.show('확인', json?.response.messagebox);

      // 매출생성 중복방지를 위한 변수 초기화
      this.miscreateFlag = false;
      await this.onRetrieveEvent();
    }

    if (json?.key === 'delete_lazytask') {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 구분
    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;
    this.setState({ gubun: data.items });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        wkactcd: this.state.wkactcd,
        as_nm: this.state.searchQuery,
        gubun: this.state.searchGubun,
        billgubun: this.state.billgubun,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new MaintenanceModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['actcd', 'saupnum'],
        [this.state.focused?.actcd, this.state.focused?.saupnum], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 0);
        this.onRowFocusEvent(this.state.data[0], 0);
      }
      // 전체 체크
      this.SS({
        contamt_tot: this.infinity?.box?.contamt_tot || '0',
        amt_tot: this.infinity?.box?.amt_tot || '0',
        addamt_tot: this.infinity?.box?.addamt_tot || '0',
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        total: this.infinity?.box?.total || '0',
      });
      await this.table.current?.update();
    });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const chkData: MaintenanceModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new MaintenanceModel({
          ...x,
        }));
      }
    });

    const warning = await Confirm.show('확인', '매출, 세금계산서, 전표가 모두 삭제됩니다. 그래도 삭제하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;

    await api.fxExec(
      'delete_lazytask',
      { items: chkData },
    );

    await this.onRetrieveEvent();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      wkactcd: this.state.wkactcd,
      as_nm: this.state.searchQuery,
      gubun: this.state.searchGubun,
      billgubun: this.state.billgubun,
    });
  }

  @action
  onRowFocusEvent(item: MaintenanceModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<MaintenanceModel>, updatedRows: Array<MaintenanceModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    this.setState({
      pushTotalCheck: checked,
      data: this.state.data.map((x) => x.updateChk(checked ? '1' : '0')),
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onSalesEvent() {
    const { actionStore: api } = this.props;
    ConfirmWarning.show('확인', '처리중입니다. 완료메시지가 뜰때까지 기다려주세요');

    if (this.miscreateFlag) {
      ConfirmWarning.show('경고', '처리중입니다. \n중복 생성 방지를위해 완료메시지가 뜰때까지 기다려주세요');
      return;
    }

    // 매출생성 중복 방지
    this.miscreateFlag = true;

    await api.exec(
      Category.GENERAL,
      'wb_miscreate_lazytask',
      {
        stmon: this.state.yearmon,
        wkactcd: this.state.wkactcd,
        billgubun: this.state.billgubun,
      },
    );
  }

  render() {
    return (
      <MaintenanceTemplate
        scope={this}
        // @ts-ignore
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
