import { TextBox } from '../../../../../components/forms/TextBox';
import { TableLayoutHeader } from '../../../../../constants';
import { AwardModel } from '../models';
import {
  PersonnelResistration,
} from '../PersonnelResistration';
import { OptionBox } from '../../../../../components/forms/OptionBox';
import { DateTextBox } from '../../../../../components/forms/DateTextBox';

// 경력
const Award: TableLayoutHeader<AwardModel, PersonnelResistration>[] = [
  {
    id: 'panaltycd',
    text: '순번',
    width: 5,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.panaltycd}
        onChange={(value) => rowUpdate({ panaltycd: value })}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'flag',
    text: '구분',
    width: 10,
    render: (x, rowUpdate) => (
      <OptionBox
        value={x.flag}
        data={[
          { value: '1', remark: '상' },
          { value: '2', remark: '벌' },
        ]}
        onChange={(item) => rowUpdate({ flag: item.value.toString(), isChanged: true })}
      />
    ),
  },
  {
    id: 'panaltycnm',
    text: '세부사항',
    width: 75,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.panaltycnm}
        onChange={(value) => rowUpdate({ panaltycnm: value })}
      />
    ),
  },
  {
    id: 'panaltyday',
    text: '일자',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        ref={ref}
        value={x.panaltyday}
        onChange={(value) => rowUpdate({ panaltyday: value })}
      />
    ),
  },
];

export default Award;
