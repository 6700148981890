import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Gubun, Status } from '../Status';
import StatusModel from '../models/StatusModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_preview_da006
 * @category 수주현황
 */
export const StausGridHeader: GLHF<StatusModel, Status> = () => ([
  {
    id: 'orddate',
    text: '수주일자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.orddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'ordnum',
    text: '번호',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.ordnum}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '수주종류',
    width: 100,
    render: (x) => {
      const fd = Gubun.filter((z) => z.code === x.gubun);
      const gubunnm = fd.length > 0 ? fd[0].remark : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
        >
          {gubunnm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'title',
    text: '제목',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.title}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '댓수',
    width: 80,
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 100,
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세액',
    width: 100,
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumTamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '합계금액',
    width: 100,
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumMamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '공사시작일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.stdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: '공사완료일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.enddate)}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'worknm',
    text: '특기사항',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.worknm}
      </FlexLayout>
    ),
  },
]);
