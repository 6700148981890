import * as React from 'react';
import {
  Button, FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import { RegistTabTableScAmtHeader } from '../headers';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 상세 > 탭 - 설치비내역
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabScAmt: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout
      size={Global.LAYOUT_BUTTON_HEIGHT_1}
      justify="end"
    >
      <FlexLayout />

      <Button width={85} onClick={() => scope.onClickScAmtNew()}>추가</Button>
      <Button width={85} theme={BUTTON_THEMES.RED} onClick={() => scope.onClickScAmtRemove()}>삭제</Button>
    </FlexLayout>

    <TableLayout
      ref={scope.tableScAmt}
      header={RegistTabTableScAmtHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.tabDataScAmt}
      onChange={(rows, updates) => scope.onTabTableScAmtChange(rows, updates)}
      onRowFocusEvent={(_, index) => scope.onTabTableScAmtRowFocused(index)}
    />
  </FlexLayout>
);
