import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb201
 * @category 일근태등록
 */
export class MonthModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 직급
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 1일
   * **data type** varchar(12)
   */
  date1: string;

  /**
   * 2일
   *
   * **data type** varchar(12)
   */
  date2: string;

  /**
   * 3일
   *
   * **data type** varchar(12)
   */
  date3: string;

  /**
   * 4일
   *
   * **data type** varchar(12)
   */
  date4: string;

  /**
   * 5일
   *
   * **data type** varchar(12)
   */
  date5: string;

  /**
   * 6일
   *
   * **data type** varchar(12)
   */
  date6: string;

  /**
   * 7일
   *
   * **data type** varchar(12)
   */
  date7: string;

  /**
   * 8일
   *
   * **data type** varchar(12)
   */
  date8: string;

  /**
   * 9일
   *
   * **data type** varchar(12)
   */
  date9: string;

  /**
   * 10일
   *
   * **data type** varchar(12)
   */
  date10: string;

  /**
   * 11일
   *
   * **data type** varchar(12)
   */
  date11: string;

  /**
   * 12일
   *
   * **data type** varchar(12)
   */
  date12: string;

  /**
   * 13일
   *
   * **data type** varchar(12)
   */
  date13: string;

  /**
   * 14일
   *
   * **data type** varchar(12)
   */
  date14: string;

  /**
   * 15일
   *
   * **data type** varchar(12)
   */
  date15: string;

  /**
   * 16일
   *
   * **data type** varchar(12)
   */
  date16: string;

  /**
   * 17일
   *
   * **data type** varchar(12)
   */
  date17: string;

  /**
   * 주소
   *
   * **data type** char(12)
   */
  date18: string;

  /**
   * 주소
   *
   * **data type** char(12)
   */
  date19: string;

  /**
   * 20일
   *
   * **data type** char(12)
   */
  date20: string;

  /**
   * 21일
   *
   * **data type** char(12)
   */
  date21: string;

  /**
   * 22일
   *
   * **data type** char(12)
   */
  date22: string;

  /**
   * 23일
   *
   * **data type** char(12)
   */
  date23: string;

  /**
   * 24일
   *
   * **data type** char(12)
   */
  date24: string;

  /**
   * 25일
   *
   * **data type** char(12)
   */
  date25: string;

  /**
   * 26일
   *
   * **data type** char(12)
   */
  date26: string;

  /**
   * 27일
   *
   * **data type** char(12)
   */
  date27: string;

  /**
   * 28일
   *
   * **data type** char(12)
   */
  date28: string;

  /**
   * 29일
   *
   * **data type** char(12)
   */
  date29: string;

  /**
   * 30일
   *
   * **data type** char(12)
   */
  date30: string;

  /**
   * 31일
   *
   * **data type** char(12)
   */
  date31: string;

  /**
   * 정상
   *
   * **data type** char(12)
   */
  atdcd01: string;

  /**
   * 지각
   *
   * **data type** char(12)
   */
  atdcd02: string;

  /**
   * 연차
   *
   * **data type** char(12)
   */
  atdcd03: string;

  /**
   * 반차
   *
   * **data type** char(12)
   */
  atdcd04: string;

  /**
   * 조퇴
   *
   * **data type** char(12)
   */
  atdcd05: string;

  /**
   * 결근
   *
   * **data type** char(12)
   */
  atdcd06: string;

  /**
   * 출장
   *
   * **data type** char(12)
   */
  atdcd07: string;

  /**
   * 훈련
   *
   * **data type** char(12)
   */
  atdcd08: string;

  /**
   * 교육
   *
   * **data type** char(12)
   */
  atdcd09: string;

  /**
   * 출산휴가
   *
   * **data type** char(12)
   */
  atdcd10: string;

  /**
   * 특별휴가
   *
   * **data type** char(12)
   */
  atdcd11: string;

  /**
   * 1일 요일
   *
   * **data type** char(12)
   */
  weekday1: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday2: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday3: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday4: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday5: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday6: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday7: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday8: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday9: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday10: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday11: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday12: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday13: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday14: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday15: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday16: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday17: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday18: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday19: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday20: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday21: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday22: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday23: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday24: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday25: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday26: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday27: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday28: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday29: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday30: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  weekday31: string;

  /**
   * 1일 요일
   *
   * **data type** char(12)
   */
  sttime1: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime2: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime3: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime4: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime5: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime6: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime7: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime8: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime9: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime10: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime11: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime12: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime13: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime14: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime15: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime16: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime17: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime18: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime19: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime20: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime21: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime22: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime23: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime24: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime25: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime26: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime27: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime28: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime29: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime30: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  sttime31: string;

  /**
   * 1일 요일
   *
   * **data type** char(12)
   */
  endtime1: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime2: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime3: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime4: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime5: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime6: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime7: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime8: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime9: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime10: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime11: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime12: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime13: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime14: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime15: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime16: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime17: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime18: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime19: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime20: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime21: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime22: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime23: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime24: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime25: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime26: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime27: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime28: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime29: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime30: string;

  /**
   * 2일 요일
   *
   * **data type** char(12)
   */
  endtime31: string;

  /**
   * 총원
   *
   * **data type** 모름
   */
  seq: string;

  /**
   * 총원
   *
   * **data type** 모름
   */
  total: string;

  /**
   * data new 를 위함
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.date1 = data.date1 || '';
    this.date2 = data.date2 || '';
    this.date3 = data.date3 || '';
    this.date4 = data.date4 || '';
    this.date5 = data.date5 || '';
    this.date6 = data.date6 || '';
    this.date7 = data.date7 || '';
    this.date8 = data.date8 || '';
    this.date9 = data.date9 || '';
    this.date10 = data.date10 || '';
    this.date11 = data.date11 || '';
    this.date12 = data.date12 || '';
    this.date13 = data.date13 || '';
    this.date14 = data.date14 || '';
    this.date15 = data.date15 || '';
    this.date16 = data.date16 || '';
    this.date17 = data.date17 || '';
    this.date18 = data.date18 || '';
    this.date19 = data.date19 || '';
    this.date20 = data.date20 || '';
    this.date21 = data.date21 || '';
    this.date22 = data.date22 || '';
    this.date23 = data.date23 || '';
    this.date24 = data.date24 || '';
    this.date25 = data.date25 || '';
    this.date26 = data.date26 || '';
    this.date27 = data.date27 || '';
    this.date28 = data.date28 || '';
    this.date29 = data.date29 || '';
    this.date30 = data.date30 || '';
    this.date31 = data.date31 || '';
    this.atdcd01 = data.atdcd01 || '';
    this.atdcd02 = data.atdcd02 || '';
    this.atdcd03 = data.atdcd03 || '';
    this.atdcd04 = data.atdcd04 || '';
    this.atdcd05 = data.atdcd05 || '';
    this.atdcd06 = data.atdcd06 || '';
    this.atdcd07 = data.atdcd07 || '';
    this.atdcd08 = data.atdcd08 || '';
    this.atdcd09 = data.atdcd09 || '';
    this.atdcd10 = data.atdcd10 || '';
    this.atdcd11 = data.atdcd11 || '';
    this.weekday1 = data.weekday1 || '';
    this.weekday2 = data.weekday2 || '';
    this.weekday3 = data.weekday3 || '';
    this.weekday4 = data.weekday4 || '';
    this.weekday5 = data.weekday5 || '';
    this.weekday6 = data.weekday6 || '';
    this.weekday7 = data.weekday7 || '';
    this.weekday8 = data.weekday8 || '';
    this.weekday9 = data.weekday9 || '';
    this.weekday10 = data.weekday10 || '';
    this.weekday11 = data.weekday11 || '';
    this.weekday12 = data.weekday12 || '';
    this.weekday13 = data.weekday13 || '';
    this.weekday14 = data.weekday14 || '';
    this.weekday15 = data.weekday15 || '';
    this.weekday16 = data.weekday16 || '';
    this.weekday17 = data.weekday17 || '';
    this.weekday18 = data.weekday18 || '';
    this.weekday19 = data.weekday19 || '';
    this.weekday20 = data.weekday20 || '';
    this.weekday21 = data.weekday21 || '';
    this.weekday22 = data.weekday22 || '';
    this.weekday23 = data.weekday23 || '';
    this.weekday24 = data.weekday24 || '';
    this.weekday25 = data.weekday25 || '';
    this.weekday26 = data.weekday26 || '';
    this.weekday27 = data.weekday27 || '';
    this.weekday28 = data.weekday28 || '';
    this.weekday29 = data.weekday29 || '';
    this.weekday30 = data.weekday30 || '';
    this.weekday31 = data.weekday31 || '';
    this.sttime1 = data.sttime1 || '';
    this.sttime2 = data.sttime2 || '';
    this.sttime3 = data.sttime3 || '';
    this.sttime4 = data.sttime4 || '';
    this.sttime5 = data.sttime5 || '';
    this.sttime6 = data.sttime6 || '';
    this.sttime7 = data.sttime7 || '';
    this.sttime8 = data.sttime8 || '';
    this.sttime9 = data.sttime9 || '';
    this.sttime10 = data.sttime10 || '';
    this.sttime11 = data.sttime11 || '';
    this.sttime12 = data.sttime12 || '';
    this.sttime13 = data.sttime13 || '';
    this.sttime14 = data.sttime14 || '';
    this.sttime15 = data.sttime15 || '';
    this.sttime16 = data.sttime16 || '';
    this.sttime17 = data.sttime17 || '';
    this.sttime18 = data.sttime18 || '';
    this.sttime19 = data.sttime19 || '';
    this.sttime20 = data.sttime20 || '';
    this.sttime21 = data.sttime21 || '';
    this.sttime22 = data.sttime22 || '';
    this.sttime23 = data.sttime23 || '';
    this.sttime24 = data.sttime24 || '';
    this.sttime25 = data.sttime25 || '';
    this.sttime26 = data.sttime26 || '';
    this.sttime27 = data.sttime27 || '';
    this.sttime28 = data.sttime28 || '';
    this.sttime29 = data.sttime29 || '';
    this.sttime30 = data.sttime30 || '';
    this.sttime31 = data.sttime31 || '';
    this.endtime1 = data.endtime1 || '';
    this.endtime2 = data.endtime2 || '';
    this.endtime3 = data.endtime3 || '';
    this.endtime4 = data.endtime4 || '';
    this.endtime5 = data.endtime5 || '';
    this.endtime6 = data.endtime6 || '';
    this.endtime7 = data.endtime7 || '';
    this.endtime8 = data.endtime8 || '';
    this.endtime9 = data.endtime9 || '';
    this.endtime10 = data.endtime10 || '';
    this.endtime11 = data.endtime11 || '';
    this.endtime12 = data.endtime12 || '';
    this.endtime13 = data.endtime13 || '';
    this.endtime14 = data.endtime14 || '';
    this.endtime15 = data.endtime15 || '';
    this.endtime16 = data.endtime16 || '';
    this.endtime17 = data.endtime17 || '';
    this.endtime18 = data.endtime18 || '';
    this.endtime19 = data.endtime19 || '';
    this.endtime20 = data.endtime20 || '';
    this.endtime21 = data.endtime21 || '';
    this.endtime22 = data.endtime22 || '';
    this.endtime23 = data.endtime23 || '';
    this.endtime24 = data.endtime24 || '';
    this.endtime25 = data.endtime25 || '';
    this.endtime26 = data.endtime26 || '';
    this.endtime27 = data.endtime27 || '';
    this.endtime28 = data.endtime28 || '';
    this.endtime29 = data.endtime29 || '';
    this.endtime30 = data.endtime30 || '';
    this.endtime31 = data.endtime31 || '';
    this.seq = data.seq || '';
    this.total = data.total || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
