import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import { FlexLayout } from '../../layout';
import { DateTextBox } from '../../forms';
import { Date8 } from '../../../utils/time';

export interface SearchDateRangeResult {
  stdate: string;
  enddate: string;
}

export interface SearchDateRangeActions {
  onChange?: (date: SearchDateRangeResult) => any;
}

export interface SearchDateRangeProps extends SearchDateRangeActions {
  format?: string;
  stdate: string;
  enddate: string;
}

export class SearchDateRange extends React.Component<SearchDateRangeProps> {
  render() {
    const {
      format,
      stdate,
      enddate,
      onChange,
    } = this.props;

    const defaultResponse: SearchDateRangeResult = {
      stdate,
      enddate,
    };

    return (
      <React.Fragment>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={stdate}
            textAlign="center"
            format={format || '####-##-##'}
            onChange={(value) => onChange && onChange({ stdate: value, enddate })}
            onEnter={() => onChange && onChange(defaultResponse)}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(stdate));
                date.setDate(date.getDate() - 1);
                onChange && onChange({ stdate: Date8.make(date), enddate });
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(stdate));
                date.setDate(date.getDate() + 1);
                onChange && onChange({ stdate: Date8.make(date), enddate });
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={175}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={enddate}
            textAlign="center"
            format={format || '####-##-##'}
            onChange={(value) => onChange && onChange({ stdate, enddate: value })}
            onEnter={() => onChange && onChange(defaultResponse)}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(enddate));
                date.setDate(date.getDate() - 1);
                onChange && onChange({ stdate, enddate: Date8.make(date) });
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(enddate));
                date.setDate(date.getDate() + 1);
                onChange && onChange({ stdate, enddate: Date8.make(date) });
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </React.Fragment>
    );
  }
}
