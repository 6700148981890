import * as React from 'react';
import style from './Button.module.scss';
import { JoinClassName } from '../../../utils/string';

export enum BUTTON_THEMES {
  BLUE,
  RED,
}

export interface ButtonActions {
  onClick?: () => any;
}

export interface ButtonProps extends ButtonActions {
  className?: any;
  style?: any;
  isMini?: boolean;
  isFull?: boolean;
  isIcon?: boolean;
  isMaterialTheme?: boolean;
  disabled?: boolean;
  theme?: BUTTON_THEMES,
  badge?: string;
  width?: number;
  height?: number;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

export class Button extends React.Component<ButtonProps> {
  render() {
    const widthApply = !this.props.width ? {} : {
      width: this.props.width,
    };

    const heightApply = !this.props.width ? {} : {
      height: this.props.height,
    };

    return (
      <div
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.isMini ? style.mini : false,
          this.props.isFull ? style.full : false,
          this.props.isIcon ? style.onlyIcon : false,
          this.props.theme === BUTTON_THEMES.RED ? style.red : false,
          this.props.isMaterialTheme ? style.material : false,
          this.props.disabled ? style.disabled : false,
        ])}
      >
        <button
          onClick={() => this.props.onClick && this.props.onClick()}
          onMouseOver={() => this.props.onMouseOver && this.props.onMouseOver()}
          onMouseOut={() => this.props.onMouseOut && this.props.onMouseOut()}
          style={{
            fontSize: 12,
            ...widthApply,
            ...heightApply,
            ...this.props.style,
          }}
        >
          {this.props.children}
          {this.props.badge && this.props.badge !== '0' && <div className={style.badge}>{this.props.badge}</div>}
        </button>
      </div>
    );
  }
}
