import {
  action,
  makeObservable,
  observable,
} from 'mobx';
import { MenuModel } from '../models';
import {
  ActionStore,
  PublicStore,
} from './index';
import { FunctionRepository } from '../repositories';

interface IFavoriteMenuStore {
  menus: MenuModel[];
}

export default class FavoriteMenuStore implements IFavoriteMenuStore {
  readonly publicStore: PublicStore;

  readonly actionStore: ActionStore;

  @observable menus: MenuModel[];

  constructor(publicStore: PublicStore, actionStore: ActionStore) {
    this.publicStore = publicStore;
    this.actionStore = actionStore;

    this.menus = [];

    makeObservable(this);
  }

  @action
  async retrieve() {
    const { user } = this.publicStore;
    const { data } = await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/etc/favoriteMenus.php', {
        custcd: user.custcd,
        userid: user.userid,
      },
    );

    const favorites: MenuModel[] = [];
    data.response.forEach((x: any) => {
      const m = this.getMenuWithWindowName(x.windowname);
      if (m) {
        favorites.push(m);
      }
    });
    this.menus = favorites;
  }

  async toggle(menu: MenuModel) {
    const { user } = this.publicStore;
    await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/etc/toggleFavoriteMenu.php', {
        custcd: user.custcd,
        spjangcd: user.spjangcd,
        userid: user.userid,
        windowname: menu.path?.substr(1),
        mtext: menu.text,
      },
    );

    await this.retrieve();
  }

  getMenuWithWindowName(windowname: string): MenuModel | false {
    let res: MenuModel | boolean = false;
    const { menuStore } = this.publicStore;
    menuStore.menus
      .forEach((x) => x.children!
        .forEach((y) => y.children!
          .forEach((z) => {
            if (!z.children?.length && z.path === `/${windowname}`) {
              res = z;
            } else if (z.children?.length) {
              z.children.forEach((n) => {
                if (!n.children?.length && n.path === `/${windowname}`) {
                  res = n;
                }
              });
            }
          })));
    return res;
  }

  isActive(id: string) {
    return this.menus.filter((x) => x.id === id).length;
  }
}
