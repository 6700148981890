import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_tb_sms_repeat
 * @category 문자자주쓰는문구
 */
export class TextOftenModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 문구제목
   *
   * **data type** varchar(255)
   */
  title: string;

  /**
   * 문구내용
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  modalCheck: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.seq = data.seq || '';
    this.title = data.title || '';
    this.remark = Fix.newline(data.remark) || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.modalCheck = data.modalCheck || '0';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
