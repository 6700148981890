import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { TeamEnrollTemplate } from './TeamEnroll.template';
import { TeamEnrollModel, TeamEnrollDetailModel } from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { ConfirmDelete } from '../../../../utils/confirm';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';

interface TeamEnrollState {

  // 검색조건
  searchQuery?: string;

  // 포커싱 객체
  focused?: TeamEnrollModel;
  focusedDetail?: TeamEnrollDetailModel;

  // 데이터 리스트
  teamEnrollList: Array<TeamEnrollModel>;
  teamEnrollDetailList: Array<TeamEnrollDetailModel>;

  focusIndex: number;
  chk: string;
}

/**
 * 컨트롤러
 * @window w_tb_e509
 * @category 당직팀등록
 */
export class TeamEnroll extends PageComponent<PageProps, TeamEnrollState>
  implements PageToolEvents {
  updatedRows?: Array<TeamEnrollModel>;

  updatedRows2?: Array<TeamEnrollDetailModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      teamEnrollList: [],
      teamEnrollDetailList: [],
      focusIndex: 0,
      chk: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];
    this.updatedRows2 = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        step: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          teamEnrollList:
            [...this.state.teamEnrollList, ...items.map((x: any) => new TeamEnrollModel(x))],
        }, next);
      },
      async () => {
        await this.SS({
          teamEnrollList: [],
          teamEnrollDetailList: [],
        });
        this.table.current?.update(true);
        this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      teamEnrollList: [],
      teamEnrollDetailList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['team', 'teamnm'],
        [this.state.focused?.team, this.state.focused?.teamnm], type, true) || 0;
      // if (this.state.chk === '1') {
      //   this.table.current?.setFocus(this.state.focusIndex, 1);
      //   this.setState({ chk: '0' });
      // } else {
      //   this.table.current?.setFocus(0, 1);
      // }
      this.table.current?.update(true);
      this.table.current?.setFocus(index);
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      // step: this.state.searchStep,
    });
    if (data) {
      this.setState({
        teamEnrollList: [
          new TeamEnrollModel(data, true),
          ...this.state.teamEnrollList,
        ],
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(false);
        this.table.current?.setFocus(0, 0);
      });
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_new',
      {
        team: this.state.focused?.team,
      },
    );

    data && this.setState({
      teamEnrollDetailList: [
        new TeamEnrollDetailModel(data, true),
        ...this.state.teamEnrollDetailList,
      ],
    }, async () => {
      await this.tableDetail.current?.update(false);
      this.tableDetail.current?.setFocus(0, 0);
    });
  }

  @action
  async onSaveEvent() {
    // @ts-ignore
    const datas = this.updatedRows[0] || this.state.focused;
    const { actionStore: api } = this.props;
    if (await api.save({
      new: '0',
      team: datas?.team,
      teamnm: datas?.teamnm,
      useyn: datas?.useyn,
      remark: datas?.remark,
      sort: datas?.sort,
      items: this?.updatedRows2,
    }, true)) {
      this.updatedRows = [];
      this.updatedRows2 = [];
      this.table.current?.resetUpdates();
      await this.setState({ chk: '1' });
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.team} - ${this.state.focused?.teamnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '우측 상세내용도 삭제됩니다. 정말 삭제하시겠습니까?';
      await api.delete(text2, this.state.focused) && await this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }

  // YSH _ 20201230 _ 확인필요 _ 성공메시지나오지만, DB반영안됨
  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focusedDetail?.perid} - ${this.state.focusedDetail?.pernm}`;
    await api.fxDelete(
      'dw_2_delete',
      text,
      {
        seq: this.state.focusedDetail?.seq,
        team: this.state.focused?.team,
      },
    ) && this.onRowFocusEvent(this.state.focused!, this.state.focusIndex);
  }

  @action
  async onRowFocusEvent(item: TeamEnrollModel, index: number) {
    const { actionStore: api } = this.props;
    this.updatedRows2 = [];

    this.setState({
      focused: item,
      focusIndex: index,
    }, async () => {
      const data = await api.fxExec('dw_1_RowFocuschanged', this.state.focused);
      if (data?.items) {
        this.setState({
          teamEnrollDetailList: data?.items.map((x: any) => new TeamEnrollDetailModel(x)),
        }, async () => {
          await this.state.teamEnrollDetailList.length > 0 && this.onRowFocusEvent2(this.state.teamEnrollDetailList[0]);
        });
      } else {
        this.setState({
          teamEnrollDetailList: [],
        });
      }
      await this.tableDetail.current?.update();
      await this.tableDetail.current?.setFocus(0, 1);
    });
  }

  @action
  onRowFocusEvent2(item:TeamEnrollDetailModel) {
    if (item.new === '1') return;
    this.setState({ focusedDetail: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<TeamEnrollModel>, updatedRows: Array<TeamEnrollModel>) {
    this.updatedRows = updatedRows;
    this.setState({ teamEnrollList: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<TeamEnrollDetailModel>, updatedRows2: Array<TeamEnrollDetailModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ teamEnrollDetailList: rows2 });
  }

  render() {
    return (
      <TeamEnrollTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state.teamEnrollList,
            ...this.state.teamEnrollDetailList,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
