import { computed } from 'mobx';

/**
 * 수입내역 모델
 * @window w_tb_ca642w_11
 * @category 일일마감
 */
export class MainModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
   today: string;

   /**
   * 전일잔액
   *
   * **data type** decimal(0)
   */
   beamt: string;

   /**
   * 수입
   *
   * **data type** number
   */
   rcvamt: string;

   /**
   * 지출
   *
   * **data type** number
   */
   sndamt: string;

   /**
   * 은행금액
   *
   * **data type** number
   */
   bamt: string;

   /**
   * 현금금액
   *
   * **data type** number
   */
   hamt: string;

   /**
   * 기타금액
   *
   * **data type** number
   */
   gamt: string;

   /**
   * 총은행금액
   *
   * **data type** decimal(0)
   */
   totbamt: string;

   /**
   * 총현금금액
   *
   * **data type** decimal(0)
   */
   tothamt: string;

   /**
   * 총기타금액
   *
   * **data type** decimal(0)
   */
   totgamt: string;

   /**
   * 상신일자
   *
   * **data type** varchar(8)
   */
   appdate: string;

  /**
   * 결재상태
   *
   * **data type** varchar(3)
   */
  appgubun: string;

  /**
   * 정렬
   *
   * **data type** varchar(1)
   */
  sort: string;

  /**
   * 해제
   *
   * **data type** varchar(1)
   */
  chk: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 지급처명
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 발생일자2
   *
   * **data type** varchar(8)
   */
  misdate2: string;

  /**
   * 미수잔액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 수금액
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 적요
   *
   * **data type** varchar(300)
   */
  remark: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  idate: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  appremark: string;

  /**
   * 결재번호
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 사진첨부
   *
   * **data type** varchar(1)
   */
  picflag: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  refer: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 사업장코드들
   *
   * **data type** varchar(255)
   */
  spjangs: string;

  /**
   * 결재시간
   *
   * **data type** varchar(6)
   */
  apptime: string;

  /**
   * 금일잔액
   *
   * **data type** number
   */
  to_amt: string;

  /**
   * 예금잔액
   *
   * **data type** number
   */
  to_bamt: string;

  /**
   * 현금잔액
   *
   * **data type** number
   */
  to_hamt: string;

  /**
   * 기타잔액
   *
   * **data type** number
   */
  to_gamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.today = data.today || '';
    this.to_bamt = data.to_bamt || '';
    this.to_gamt = data.to_gamt || '';
    this.to_hamt = data.to_hamt || '';
    this.to_amt = data.to_amt || '';
    this.beamt = data.beamt || '';
    this.rcvamt = data.rcvamt || '';
    this.sndamt = data.sndamt || '';
    this.bamt = data.bamt || '';
    this.hamt = data.hamt || '';
    this.gamt = data.gamt || '';
    this.totbamt = data.totbamt || '';
    this.tothamt = data.tothamt || '';
    this.totgamt = data.totgamt || '';
    this.appdate = data.appdate || '';
    this.apptime = data.apptime || '';
    this.appnum = data.appnum || '';
    this.appgubun = data.appgubun || '';
    this.sort = data.sort || '';
    this.chk = data.chk || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.misdate2 = data.misdate2 || '';
    this.misamt = data.misamt || '';
    this.iamt = data.iamt || '';
    this.remark = data.remark || '';
    this.idate = data.idate || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.picflag = data.picflag || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.spjangs = data.spjangs || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
