/**
 * 모델
 * @window w_tb_e601_dp_01
 * @category 설치현장도면
 */
export default class DrawingModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장 코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 문서명
   *
   * **data type** varchar(8)
   */
  readonly appnm: string;

  /**
   * 문서 코드
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   * 갯수
   *
   * **data type** varchar(8)
   */
  readonly cnt: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 설치현장명
   *
   * **data type** varchar(8)
   */
  readonly scactnm: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly title: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.appnm = data.appnm || '';
    this.appnum = data.appnum || '';
    this.cnt = data.cnt || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.scactnm = data.scactnm || '';
    this.state = data.state || '';
    this.title = data.title || '';
  }
}
