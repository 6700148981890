import { GLHF } from '../../../../../constants';
import {
  FlexLayout,
} from '../../../../../components';
import { Regist } from '../Regist';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';

// 계약구분
export const contFlags = [
  { key: '%', value: '전체' },
  { key: '0', value: '관급계약' },
  { key: '1', value: '사급계약' },
  { key: '2', value: 'LH계약' },
];

/**
 * 화면 > 상세 > 탭 - 견적내역 > 그리드
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabGridEstimateHeader: GLHF<any, Regist> = (scope) => ([
  {
    id: 'costdate',
    text: '견적일자',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="start"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costnum}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout>
        {scope.state.total7} 건
      </FlexLayout>
    ),
  },
  {
    id: 'costpernm',
    text: '담당자',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'contflag',
    text: '계약구분',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {contFlags.filter((y) => y.key === x.contflag)[0]?.value}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope.state.gubuns?.filter((y) => y.code === x.gubun)[0]?.cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '공사명',
    width: 320,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '거래처명',
    width: 240,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(scope.state.samt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(scope.state.tamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mamt',
    text: '공사금액',
    width: 90,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.mamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(scope.state.mamt_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'jeoban',
    text: '제어반',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.jeoban}
      </FlexLayout>
    ),
  },
  {
    id: 'nperson',
    text: '인승',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.nperson}
      </FlexLayout>
    ),
  },
  {
    id: 'number',
    text: '층수',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.number}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 600,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
