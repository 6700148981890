import React, { ReactElement } from 'react';
import _ from 'lodash';
import Child from './Child';
import './react-minimap.css';

interface MinimapProps {
  selector: string;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  node?: any;
  className?: any;
  children?: ReactElement;
  childComponent?: any;
  keepAspectRatio?: boolean;
  onMountCenterOnX?: boolean;
  onMountCenterOnY?: boolean;
  isZoom?: boolean;
}

interface MinimapStates {
  children: any;
  viewport: any;
  width: number;
  height: number;
}

export class Minimap extends React.Component<MinimapProps, MinimapStates> {
  static defaultProps = {
    className: '',
    width: 200,
    height: 200,
    keepAspectRatio: false,
    childComponent: Child,
    onMountCenterOnX: false,
    onMountCenterOnY: false,
  }

  downState: boolean;

  resize: (e: any) => void;

  x: number = 0;

  y: number = 0;

  l: number = 0;

  t: number = 0;

  h: number = 0;

  w: number = 0;

  scrollWidth: number = 0;

  scrollHeight: number = 0;

  source?: HTMLDivElement;

  minimap?: HTMLDivElement;

  constructor(props: MinimapProps, context?: any) {
    super(props, context);
    this.down = this.down.bind(this);
    this.move = this.move.bind(this);
    this.synchronize = this.synchronize.bind(this);
    this.init = this.init.bind(this);
    this.up = this.up.bind(this);

    this.resize = _.throttle(this.synchronize, 1000);

    this.state = {
      children: null,
      viewport: null,
      width: props.width || 200,
      height: props.height || 200,
    };

    this.downState = false;
  }

  componentDidMount() {
    const { onMountCenterOnX, onMountCenterOnY } = this.props;
    setTimeout(() => this.resize({
      centerOnX: onMountCenterOnX,
      centerOnY: onMountCenterOnY,
    }));
    this.init();
    this.update();
  }

  componentDidUpdate(prevProps: Readonly<MinimapProps>, prevState: Readonly<MinimapStates>) {
    if (prevProps.keepAspectRatio !== this.props.keepAspectRatio) {
      setTimeout(this.resize);
    } else if (prevState.children !== this.props.children) {
      setTimeout(this.resize);
    }
  }

  init() {
    const { childComponent, keepAspectRatio } = this.props;
    const ChildComponent = childComponent;
    const {
      scrollWidth, scrollHeight, scrollTop, scrollLeft,
    } = this.source!;
    const sourceRect = this.source!.getBoundingClientRect();

    let width = this.props.width || 200;
    let height = this.props.height || 200;

    let ratioX = (width) / scrollWidth;
    let ratioY = (height) / scrollHeight;

    if (keepAspectRatio) {
      if (ratioX < ratioY) {
        ratioY = ratioX;
        height = Math.round(scrollHeight / (scrollWidth / width));
      } else {
        ratioX = ratioY;
        width = Math.round(scrollWidth / (scrollHeight / height));
      }
    }

    const nodes = this.source!.querySelectorAll(this.props.selector);
    this.setState({
      ...this.state,
      height,
      width,
      children: _.map(nodes, (node, key) => {
        const {
          width, height, left, top,
        } = node.getBoundingClientRect();

        const wM = width * ratioX;
        const hM = height * ratioY;
        const xM = (left + scrollLeft - sourceRect.left) * ratioX;
        const yM = (top + scrollTop - sourceRect.top) * ratioY;

        return (
          <ChildComponent
            key={key}
            width={Math.round(wM)}
            height={Math.round(hM)}
            left={Math.round(xM)}
            top={Math.round(yM)}
            node={node}
          />
        );
      }),
    });
  }

  update() {
    this.scrollWidth = this.source!.scrollWidth;
    this.scrollHeight = this.source!.scrollHeight;
    setTimeout(this.resize);
  }

  down(e: any) {
    const pos = this.minimap!.getBoundingClientRect();

    this.x = Math.round(pos.left + this.l + this.w / 2);
    this.y = Math.round(pos.top + this.t + this.h / 2);

    this.downState = true;
    this.move(e);
  }

  up() {
    this.downState = false;
  }

  move(e: any) {
    if (!this.downState) return;

    const { width, height } = this.state;
    const event = e;

    e.preventDefault();

    let dx = event.clientX - this.x;
    let dy = event.clientY - this.y;
    if (this.l + dx < 0) {
      dx = -this.l;
    }
    if (this.t + dy < 0) {
      dy = -this.t;
    }
    if (this.l + this.w + dx > width) {
      dx = width - this.l - this.w;
    }
    if (this.t + this.h + dy > height) {
      dy = height - this.t - this.h;
    }

    this.x += dx;
    this.y += dy;

    this.l += dx;
    this.t += dy;

    this.l = this.l < 0 ? 0 : this.l;
    this.t = this.t < 0 ? 0 : this.t;

    const coefX = width / this.scrollWidth;
    const coefY = height / this.scrollHeight;
    let left = this.l / coefX;
    let top = this.t / coefY;

    if (this.scrollHeight < top + this.source!.clientHeight) {
      top = this.scrollHeight - this.source!.clientHeight;
    }

    if (this.scrollWidth < left + this.source!.clientWidth) {
      left = this.scrollWidth - this.source!.clientWidth;
    }

    this.source!.scrollLeft = Math.round(left);
    this.source!.scrollTop = Math.round(top);
    this.redraw();
  }

  synchronize(options: any) {
    const { width, height } = this.state;

    const rect = this.source?.getBoundingClientRect();

    if (!rect) return;

    const dims = [rect.width, rect.height];
    const scroll = [this.source!.scrollLeft, this.source!.scrollTop];
    const scaleX = width / this.scrollWidth;
    const scaleY = height / this.scrollHeight;

    const lW = dims[0] * scaleX;
    const lH = dims[1] * scaleY;
    const lX = scroll[0] * scaleX;
    const lY = scroll[1] * scaleY;

    // Ternary operation includes sanity check
    this.w = Math.round(lW) > this.state.width
      ? this.state.width
      : Math.round(lW);
    this.h = Math.round(lH) > this.state.height
      ? this.state.height
      : Math.round(lH);
    this.l = Math.round(lX);
    this.t = Math.round(lY);

    if (options !== undefined) {
      if (options.centerOnX === true) {
        this.source!.scrollLeft = this.scrollWidth / 2 - dims[0] / 2;
      }

      if (options.centerOnY === true) {
        this.source!.scrollTop = this.scrollHeight / 2 - dims[1] / 2;
      }
    }

    this.redraw();
  }

  redraw() {
    this.setState({
      ...this.state,
      viewport: (
        <div
          className="minimap-viewport"
          style={{
            width: this.w,
            height: this.h,
            left: this.l,
            top: this.t,
          }}
        />
      ),
    });
  }

  render() {
    const { width, height } = this.state;

    return (
      <div className={`minimap-container ${this.props.className}`}>
        <div
          className="minimap"
          style={{
            width: `${width}px`,
            height: `${height}px`,
            transform: `translateX(${this.props.isZoom ? 0 : 150}%)`,
          }}
          ref={(minimap) => {
            this.minimap = minimap!;
          }}
          onMouseDown={this.down}
          onTouchStart={this.down}
          onTouchMove={this.move}
          onMouseMove={this.move}
          onTouchEnd={this.up}
          onMouseUp={this.up}
          onMouseLeave={() => { this.downState = false; }}
        >
          {this.state.viewport}
          {this.state.children}
        </div>

        <div
          className={'minimap-container-scroll'}
          ref={(container) => {
            this.source = container!;
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Minimap;
