import * as React from 'react';
import { observer } from 'mobx-react';
import style from './HeaderTab.module.scss';
import {
  PublicStore,
  RouterStore,
  HeaderTabStore,
  FavoriteMenuStore,
} from '../../../stores';
import { HeaderTabView } from '../HeaderTabView';
import { TabModel } from '../../../models';

interface HeaderTabProps {
  publicStore: PublicStore;
  routerStore: RouterStore;
  favoriteMenuStore: FavoriteMenuStore;
  tabStore: HeaderTabStore;
}

@observer
export class HeaderTab extends React.Component<HeaderTabProps> {
  async toggleStar(tab: TabModel) {
    await this.props.favoriteMenuStore.toggle(tab.data.menu);
    this.setState({});
  }

  render() {
    const { publicStore, tabStore, favoriteMenuStore } = this.props;
    const activePage = publicStore.currentMenu.active;
    // eslint-disable-next-line no-param-reassign,no-return-assign
    tabStore.tabs.forEach((tab) => (tab.isEnabled = activePage.id === tab.id));

    return (
      <div className={style.container}>
        <HeaderTabView
          tabs={tabStore.tabs}
          isRemovable={true}
          favoriteMenuStore={favoriteMenuStore}
          onChange={(tab) => tabStore.showTab(tab)}
          onRemove={(tab, i) => tabStore.removeTab(tab, i)}
          onStar={(tab) => this.toggleStar(tab)}
        />
      </div>
    );
  }
}
