import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import {
  ExternalPageProps,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { GoalPlanTemplate } from './GoalPlan.template';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';

interface GoalPlanState {
  year: string;
  key: string;
  textDetailModal: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_da052w_04
 * @category 목표대비계획[개인별]
 */
@inject('toolStore')
export class GoalPlan extends React.Component<PageProps, GoalPlanState, ExternalPageProps>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      key: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('retrieve', {
      year: this.state.year,
    });

    if (data) {
      this.setState({
        key: data?.h,
      });
    }
    await this.textModal(true);
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });
  }

  async onPageMove(): Promise<void> {
    // window.open(`https://api.elmansoft.com/targetEarn/?key=00005717&year=${this.state.year}&h=00014770`);
    window.open(`http://gw.dmel.co.kr/api/targetEarn/?key=${this.state.key}&year=${this.state.year}`);

    // @ts-ignore
    this.props.toolStore.onCloseHandler();
    this.textModal(false);
  }

  render() {
    return (
      <GoalPlanTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
