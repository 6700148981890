import * as React from 'react';
import {
  AddressButton, DateTextBox, FlexLayout, LabelBox, LayoutTitle, TextBox,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Contract } from '../Contract';
import ContractDefaultModel from '../models/ContractDefaultModel';

/**
 * 화면 > 탭 - 특약조건
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export const ContractTabAgreementTemplate: React.FC<TemplateProps<Contract>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setContent = (data: any) => scope?.setState({
    content: new ContractDefaultModel({
      ...content,
      ...data,
    }, content?.isNew),
  });

  return (
    <FlexLayout isVertical={true}>
      <LayoutTitle>기본계약서 특약조건</LayoutTitle>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="[수급사업자] 주소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                custzipcd: address.zonecode,
                custadres: address.address,
                custadres2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.custzipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.custadres}
            onChange={(value) => setContent({ custadres: value })}
          />

          <TextBox
            weight={2}
            value={content?.custadres2}
            onChange={(value) => setContent({ custadres2: value })}
          />
        </LabelBox>

        <LabelBox
          title="(갑) 상호"
          weight={1.2}
        >
          <TextBox
            textAlign="left"
            value={content?.spjangnm}
            onChange={(v) => setContent({ spjangnm: v })}
          />
        </LabelBox>

        <LabelBox
          title="성명(대표이사)"
          weight={0.8}
        >
          <TextBox
            textAlign="center"
            value={content?.prenm}
            onChange={(v) => setContent({ prenm: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="[하수급사업자] 주소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                xzipcd: address.zonecode,
                xaddress: address.address,
                xaddress2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.xzipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.xaddress}
            onChange={(value) => setContent({ xaddress: value })}
          />

          <TextBox
            weight={2}
            value={content?.xaddress2}
            onChange={(value) => setContent({ xaddress2: value })}
          />
        </LabelBox>

        <LabelBox title="(을) 상호">
          <TextBox
            textAlign="left"
            value={content?.xspjangnm}
            onChange={(v) => setContent({ xspjangnm: v })}
          />
        </LabelBox>

        <LabelBox
          title="계약일"
          footer={
            <FlexLayout style={content?.attcnt === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}>
              {content?.attcnt === '0' ? '서명없음' : '서명있음'}
            </FlexLayout>
          }
        >
          <DateTextBox
            textAlign="center"
            format="####-##-##"
            value={content?.contdate}
            onChange={(v) => setContent({ contdate: v })}
          />
        </LabelBox>
      </FlexLayout>
    </FlexLayout>
  );
};
