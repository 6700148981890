import { computed } from 'mobx';

/**
 * 모델
 * @window w_jc002
 * @category 부서코드
 */
export class DepartmentCodeModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  divigb: string;

  /**
   * 시간
   *
   * **data type** float
   */
  times: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 사용여부
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 전화번호
   *
   * **data type** varchar(30)
   */
  telnum: string;

  /**
   * 출력순서
   *
   * **data type** numeric(3, 0)
   */
  prtorder: string;

  /**
   * 상위부서코드
   *
   * **data type** char(2)
   */
  kukcd: string;

  /**
   * 상위부서명
   *
   * **data type** char(2)
   */
  kuknm: string;

  /**
   * 업무코드
   *
   * **data type** char(3)
   */
  busicd: string;

  /**
   * level
   *
   * **data type** numeric(18, 0)
   */
  divilv: string;

  /**
   * 그룹코드
   *
   * **data type** varchar(50)
   */
  groupcd: string;

  /**
   * 더존연동코드
   *
   * **data type** varchar(50)
   */
  dz_dept_cd: string;

  /**
   * new layout block
   *
   * **data type** varchar(1)
   */
  newCount: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.divicd = data.divicd || '';
    this.divigb = data.divigb || '';
    this.times = data.times || '';
    this.divinm = data.divinm || '';
    this.useyn = data.useyn || '';
    this.telnum = data.telnum || '';
    this.prtorder = data.prtorder || '';
    this.kukcd = data.kukcd || '';
    this.kuknm = data.kuknm || '';
    this.busicd = data.busicd || '';
    this.divilv = data.divilv || '';
    this.groupcd = data.groupcd || '';
    this.newCount = data.newCount || '';
    this.dz_dept_cd = data.dz_dept_cd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
