import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  SearchLayout,
  GridLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Month } from './Month';
import { SearchBinding } from '../../../../models/common';
import { Date6Calculator } from '../../../../utils/time';


/**
 * 화면
 * @window w_tb_pb201w_01
 * @category 월근태현황
 */
export const MonthTemplate: React.FC<TemplateProps<Month>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <SearchLayout>
          <FlexLayout size={250}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.mon}
              textAlign="center"
              format="####년 ##월"
              onChange={(value) => setData({ mon: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  setData({ mon: new Date6Calculator(scope.state.mon).add(-1).toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  setData({ mon: new Date6Calculator(scope.state.mon).add().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout size={250}>
            <span>사업장</span>
            <ComboBox
              value={scope.state.spjangcd}
              data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
              onSelect={(option) => setData({ spjangcd: option.value })}
            />
          </FlexLayout>
          <FlexLayout
            justify="left"
            size={250}
          >
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.divinm}
              textAlign="center"
              onChange={(value) => setData({ divinm: value })}
              readonly={true}
            />
          </FlexLayout>
          <FlexLayout
            justify="left"
            size={250}
          >
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                    searchQuery: item.cd,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              textAlign="center"
              onChange={(value) => setData({ pernm: value })}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'seq',
            text: 'No',
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 80,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-red)' }}
              >
                {scope.state?.rt001_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '사원명',
            width: 75,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state?.rt002_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'rspnm',
            text: '직위',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.rspnm}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd01',
            text: '정상',
            color: '#000000',
            width: 50,
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {scope.state?.atdcd01_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#000000' }}
              >
                {x.atdcd01}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd02',
            text: '지각',
            width: 50,
            color: '#5B1A86',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#5B1A86' }}
              >
                {scope.state?.atdcd02_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#5B1A86' }}
              >
                {x.atdcd02}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd03',
            text: '연차',
            width: 50,
            color: '#FF7F27',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FF7F27' }}
              >
                {scope.state?.atdcd03_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#FF7F27' }}
              >
                {x.atdcd03}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd09',
            text: '반차',
            width: 50,
            color: 'var(--color-yellow)',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-yellow)' }}
              >
                {scope.state?.atdcd09_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-yellow)' }}
              >
                {x.atdcd09}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd04',
            text: '조퇴',
            width: 50,
            color: '#ED709B',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#ED709B' }}
              >
                {scope.state?.atdcd04_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#ED709B' }}
              >
                {x.atdcd04}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd05',
            text: '결근',
            width: 50,
            color: 'var(--color-red)',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#ED709B' }}
              >
                {scope.state?.atdcd05_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#ED709B' }}
              >
                {x.atdcd05}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd06',
            text: '출장',
            width: 50,
            color: 'var(--color-blue)',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {scope.state?.atdcd06_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: 'var(--color-blue)' }}
              >
                {x.atdcd06}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd07',
            text: '훈련',
            width: 50,
            color: '#000000',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#000000' }}
              >
                {scope.state?.atdcd07_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#000000' }}
              >
                {x.atdcd07}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd08',
            text: '교육',
            width: 50,
            color: '#FAF4C0',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#FAF4C0' }}
              >
                {scope.state?.atdcd08_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#FAF4C0' }}
              >
                {x.atdcd08}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd10',
            text: '출산휴가',
            width: 50,
            color: '#7F7A2C',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#7F7A2C' }}
              >
                {scope.state?.atdcd10_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#7F7A2C' }}
              >
                {x.atdcd10}
              </FlexLayout>
            ),
          },
          {
            id: 'atdcd11',
            text: '특별휴가',
            width: 50,
            color: '#7F7A2C',
            trail: () => (
              <FlexLayout
                justify="right"
                align="center"
                style={{ color: '#7F7A2C' }}
              >
                {scope.state?.atdcd11_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: '#7F7A2C' }}
              >
                {x.atdcd11}
              </FlexLayout>
            ),
          },
          {
            id: 'weekday1',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[0] }}
                >
                  1일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[0] }}
                  >
                    {scope.state?.weekday[0]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date1) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date1)[0]?.atdnm || []}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime1 === '00:00' ? '' : x.sttime1 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime1 === '00:00' ? '' : x.endtime1 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday2',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[1] }}
                >
                  2일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[1] }}
                  >
                    {scope.state?.weekday[1]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date2) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date2)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime2 === '00:00' ? '' : x.sttime2 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime2 === '00:00' ? '' : x.endtime2 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday3',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[2] }}
                >
                  3일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[2] }}
                  >
                    {scope.state?.weekday[2]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date3) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date3)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime3 === '00:00' ? '' : x.sttime3 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime3 === '00:00' ? '' : x.endtime3 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday4',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[3] }}
                >
                  4일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[3] }}
                  >
                    {scope.state?.weekday[3]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date4) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date4)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime4 === '00:00' ? '' : x.sttime4 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime4 === '00:00' ? '' : x.endtime4 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday5',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[4] }}
                >
                  5일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[4] }}
                  >
                    {scope.state?.weekday[4]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date5) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date5)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime5 === '00:00' ? '' : x.sttime5 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime5 === '00:00' ? '' : x.endtime5 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday6',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[5] }}
                >
                  6일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[5] }}
                  >
                    {scope.state?.weekday[5]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date6) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date6)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime6 === '00:00' ? '' : x.sttime6 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime6 === '00:00' ? '' : x.endtime6 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday7',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[6] }}
                >
                  7일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[6] }}
                  >
                    {scope.state?.weekday[6]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date7) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date7)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime7 === '00:00' ? '' : x.sttime7 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime7 === '00:00' ? '' : x.endtime7 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday8',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[7] }}
                >
                  8일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[7] }}
                  >
                    {scope.state?.weekday[7]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date8) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date8)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime8 === '00:00' ? '' : x.sttime8 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime8 === '00:00' ? '' : x.endtime8 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday9',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[8] }}
                >
                  9일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[8] }}
                  >
                    {scope.state?.weekday[8]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date9) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date9)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime9 === '00:00' ? '' : x.sttime9 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime9 === '00:00' ? '' : x.endtime9 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday10',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[9] }}
                >
                  10일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[9] }}
                  >
                    {scope.state?.weekday[9]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date10) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date10)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime10 === '00:00' ? '' : x.sttime10 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime10 === '00:00' ? '' : x.endtime10 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday11',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[10] }}
                >
                  11일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[10] }}
                  >
                    {scope.state?.weekday[10]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date11) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date11)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime11 === '00:00' ? '' : x.sttime11 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime11 === '00:00' ? '' : x.endtime11 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday12',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[11] }}
                >
                  12일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[11] }}
                  >
                    {scope.state?.weekday[11]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date12) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date12)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime12 === '00:00' ? '' : x.sttime12 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime12 === '00:00' ? '' : x.endtime12 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday13',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[12] }}
                >
                  13일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[12] }}
                  >
                    {scope.state?.weekday[12]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date13) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date13)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime13 === '00:00' ? '' : x.sttime13 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime13 === '00:00' ? '' : x.endtime13 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday14',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[13] }}
                >
                  14일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[13] }}
                  >
                    {scope.state?.weekday[13]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date14) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date14)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime14 === '00:00' ? '' : x.sttime14 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime14 === '00:00' ? '' : x.endtime14 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday15',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[14] }}
                >
                  15일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[14] }}
                  >
                    {scope.state?.weekday[14]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date15) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date15)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime15 === '00:00' ? '' : x.sttime15 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime15 === '00:00' ? '' : x.endtime15 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday16',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[15] }}
                >
                  16일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[15] }}
                  >
                    {scope.state?.weekday[15]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date16) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date16)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime16 === '00:00' ? '' : x.sttime16 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime16 === '00:00' ? '' : x.endtime16 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday17',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[16] }}
                >
                  17일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[16] }}
                  >
                    {scope.state?.weekday[16]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date17) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date17)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime17 === '00:00' ? '' : x.sttime17 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime17 === '00:00' ? '' : x.endtime17 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday18',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[17] }}
                >
                  18일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[17] }}
                  >
                    {scope.state?.weekday[17]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date18) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date18)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime18 === '00:00' ? '' : x.sttime18 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime18 === '00:00' ? '' : x.endtime18 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday19',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[18] }}
                >
                  19일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[18] }}
                  >
                    {scope.state?.weekday[18]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date19) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date19)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime19 === '00:00' ? '' : x.sttime19 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime19 === '00:00' ? '' : x.endtime19 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday20',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[19] }}
                >
                  20일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[19] }}
                  >
                    {scope.state?.weekday[19]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date20) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date20)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime20 === '00:00' ? '' : x.sttime20 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime20 === '00:00' ? '' : x.endtime20 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday21',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[20] }}
                >
                  21일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[20] }}
                  >
                    {scope.state?.weekday[20]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date21) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date21)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime21 === '00:00' ? '' : x.sttime21 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime21 === '00:00' ? '' : x.endtime21 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday22',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[21] }}
                >
                  22일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[21] }}
                  >
                    {scope.state?.weekday[21]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date22) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date22)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime22 === '00:00' ? '' : x.sttime22 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime22 === '00:00' ? '' : x.endtime22 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday23',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[22] }}
                >
                  23일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[22] }}
                  >
                    {scope.state?.weekday[22]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date23) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date23)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime23 === '00:00' ? '' : x.sttime23 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime23 === '00:00' ? '' : x.endtime23 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday24',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[23] }}
                >
                  24일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[23] }}
                  >
                    {scope.state?.weekday[23]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date24) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date24)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime24 === '00:00' ? '' : x.sttime24 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime24 === '00:00' ? '' : x.endtime24 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday25',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[24] }}
                >
                  25일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[24] }}
                  >
                    {scope.state?.weekday[24]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date25) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date25)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime25 === '00:00' ? '' : x.sttime25 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime25 === '00:00' ? '' : x.endtime25 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday26',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[25] }}
                >
                  26일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[25] }}
                  >
                    {scope.state?.weekday[25]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date26) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date26)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime26 === '00:00' ? '' : x.sttime26 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime26 === '00:00' ? '' : x.endtime26 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday27',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[26] }}
                >
                  27일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[26] }}
                  >
                    {scope.state?.weekday[26]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date27) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date27)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime27 === '00:00' ? '' : x.sttime27 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime27 === '00:00' ? '' : x.endtime27 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday28',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[27] }}
                >
                  28일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[27] }}
                  >
                    {scope.state?.weekday[27]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date28) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date28)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime28 === '00:00' ? '' : x.sttime28 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime28 === '00:00' ? '' : x.endtime28 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday29',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[28] }}
                >
                  29일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[28] }}
                  >
                    {scope.state?.weekday[28]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date29) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date29)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime29 === '00:00' ? '' : x.sttime29 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime29 === '00:00' ? '' : x.endtime29 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday30',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[29] }}
                >
                  30일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[29] }}
                  >
                    {scope.state?.weekday[29]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date30) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date30)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime30 === '00:00' ? '' : x.sttime30 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime30 === '00:00' ? '' : x.endtime30 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
          {
            id: 'weekday31',
            text: (
              <FlexLayout isVertical={true}>
                <FlexLayout
                  align="end"
                  style={{ color: scope.state.color[30] }}
                >
                  31일
                </FlexLayout>
                {scope.state.weekday && scope.state.weekday.length > 0 ? (
                  <FlexLayout
                    align="start"
                    style={{ color: scope.state.color[30] }}
                  >
                    {scope.state?.weekday[30]?.text}
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            width: 50,
            render: (x) => {
              let color = '';

              switch (x.date31) {
                case '00':
                  color = '#FF0000';
                  break;

                case '01':
                  color = '#000000';
                  break;

                case '02':
                  color = '#5B1A86';
                  break;

                case '03':
                  color = '#FF7F27';
                  break;

                case '04':
                  color = 'var(--color-yellow)';
                  break;

                case '05':
                  color = 'var(--color-red)';
                  break;

                case '06':
                  color = 'var(--color-blue)';
                  break;

                case '07':
                  color = 'var(--color-blue)';
                  break;

                case '08':
                  color = '#000000';
                  break;

                case '09':
                  color = '#FAF4C0';
                  break;

                case '10':
                  color = '#7F7A2C';
                  break;

                case '11':
                  color = '#7F7A2C';
                  break;

                case '12':
                  color = '#FF7F27';
                  break;

                default:
                  break;
              }

              return (
                <FlexLayout
                  justify="center"
                  align="center"
                  isVertical={true}
                  style={{ color }}
                >
                  <FlexLayout>
                    {scope.state.dateDatas.filter((y) => y.atdcd === x.date31)[0]?.atdnm}
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.sttime31 === '00:00' ? '' : x.sttime31 }
                  </FlexLayout>
                  <FlexLayout style={{ fontSize: 8 }}>
                    {x.endtime31 === '00:00' ? '' : x.endtime31 }
                  </FlexLayout>
                </FlexLayout>
              );
            },
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={60}
        headerHeight={42}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
