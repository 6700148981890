import { computed } from 'mobx';

/**
 * 모델3
 * @window w_tb_ca642_01_all
 * @category 지급등록
 */
export class GiveDetail2Model {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순번
   *
   * **data type** char(3)
   */
  sndseq: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** number
   */
  mijnum: string;

  /**
   * 적요
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 발생금액
   *
   * **data type** number
   */
  iamt: string;

  /**
   * 예금
   *
   * **data type** number
   */
  bamt: string;

  /**
   * 은행
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(20)
   */
  bankcd_1: string;

  /**
   * 현금
   *
   * **data type** number
   */
  hamt: string;

  /**
   * 기타
   *
   * **data type** number
   */
  gamt: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.sndseq = data.sndseq || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.remark = data.remark || '';
    this.iamt = data.iamt || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.bankcd_1 = data.bankcd_1 || '';
    this.hamt = data.hamt || '';
    this.gamt = data.gamt || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
