import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  FormatTextBox,
  DateTextBox,
  ComboBox,
  ComboBoxModel,
  IFrame, CircleBadge,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BreakOften } from './BreakOften';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e411w_01
 * @category 고장다발현장
 */
export const BreakOftenTemplate: React.FC<TemplateProps<BreakOften>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={250}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                setData({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                setData({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>고장다발기준</span>
          <TextBox
            color= {'var(--color-red)'}
            value={`월 ${scope.state.wadcnt} 회 이상 호기`}
            textAlign="center"
            onChange={(value) => scope.setState({ wadcnt: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>처리</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '미처리' },
              { value: '1', remark: '처리' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ gubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={446}>
          <span>현장명</span>
          <TextBox
            style={{ marginLeft: 10 }}
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.cltcd}
            onChange={(value) => scope.setState({ cltcd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={596}>
          <span style={{ color: 'var(--color-red)', marginLeft: 96 }}>부서</span>
          <TextBox
            weight={1.4}
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)', marginLeft: 17 }}>담당</span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'actcd',
              text: '현장코드',
              isHidden: true,
              group: true,
            },
            {
              id: 'equpcd',
              text: '호기코드',
              isHidden: true,
              group: true,
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.actnm}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 120,
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.equpcnt_tot}대
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.equpnm}
                  <CircleBadge>
                    {Number(x.wadcnt) > 1 && <span
                      style={{ backgroundColor: 'var(--color-red)' }}
                    >
                      {Number(x.wadcnt) > 1 ? x.wadcnt : ''}
                    </span>}
                    {Number(x.lastcnt) > 1 && <span
                      style={{ backgroundColor: 'var(--color-blue)' }}
                    >
                      {Number(x.lastcnt) > 1 ? x.lastcnt : ''}
                    </span>}
                  </CircleBadge>
                </FlexLayout>
              ),
            },
            {
              id: 'actdivinm',
              text: '담당부서명',
              width: 80,
              sum: (x, data) => {
                const sumActdivinm = Sum.groupLength(data, 'actdivinm', x, ['actnm']);
                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: '#8106aa' }}
                  >
                    {Format.number(sumActdivinm)} 건
                  </FlexLayout>
                );
              },
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.cnt_tot}건
                </FlexLayout>
              ),
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.actdivinm}
                />
              ),
            },
            {
              id: 'actpernm',
              text: '담당자',
              width: 80,
            },
            {
              id: 'seq',
              text: '처리',
              width: 50,
              render: (x) => (
                <FormatTextBox
                  format="#차"
                  textAlign="center"
                  value={x.seq}
                />
              ),
            },
            {
              id: 'pernm',
              text: '처리자',
              width: 80,
            },
            {
              id: 'repernm',
              text: '접수자',
              width: 80,
            },
            {
              id: 'recedate',
              text: '접수일자',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  format="####-##-##"
                    textAlign="center"
                    value={x.recedate}
                />
              ),
            },
            {
              id: 'recetime',
              text: '접수시간',
              width: 80,
              render: (x) => (
                <FormatTextBox
                  format="##:##"
                  textAlign="center"
                  value={x.recetime}
                />
              ),
            },
            {
              id: 'compdate',
              text: '완료일자',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={x.compdate}
                />
              ),
            },
            {
              id: 'comptime',
              text: '완료시간',
              width: 80,
              render: (x) => (
                <FormatTextBox
                  format="##:##"
                  textAlign="center"
                  value={x.comptime}
                />
              ),
            },
            {
              id: 'arrivdate',
              text: '도착일자',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={x.arrivdate}
                />
              ),
            },
            {
              id: 'arrivtime',
              text: '도착시간',
              width: 80,
              render: (x) => (
                <FormatTextBox
                  format="##:##"
                  textAlign="center"
                  value={x.arrivtime}
                />
              ),
            },
            {
              id: 'cnt',
              text: '대수',
              width: 60,
            },
            {
              id: 'ascnt',
              text: '건수',
              width: 60,
            },
            {
              id: 'contnm',
              text: '고장내용',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.contnm}
                />
              ),
            },
            {
              id: 'contremark',
              text: '고장내용상세',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.contremark}
                />
              ),
            },
            {
              id: 'resunm',
              text: '처리내용',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.resunm}
                />
              ),
            },
            {
              id: 'resuremark',
              text: '처리내용상세',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.resuremark}
                />
              ),
            },
            {
              id: 'remonm',
              text: '고장요인',
              width: 150,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.remonm}
                />
              ),
            },
            {
              id: 'remoremark',
              text: '고장요인상세',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.remoremark}
                />
              ),
            },
            {
              id: 'facnm',
              text: '고장원인',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.facnm}
                />
              ),
            },
            {
              id: 'greginm',
              text: '고장부위',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.greginm}
                />
              ),
            },
            {
              id: 'reginm',
              text: '고장부위상세',
              width: 200,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.reginm}
                />
              ),
            },
            {
              id: 'resultnm',
              text: '처리방법',
              width: 100,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.resultnm}
                />
              ),
            },
            {
              id: 'resutime',
              text: '대응시간',
              width: 80,
              render: (x) => (
                <TextBox
                  style={{ paddingRight: 8 }}
                  textAlign="right"
                  value={x.resutime}
                />
              ),
            },
            {
              id: 'resulttime',
              text: '처리시간',
              width: 80,
              render: (x) => (
                <TextBox
                  style={{ paddingRight: 8 }}
                  textAlign="right"
                  value={x.resulttime}
                />
              ),
            },
            {
              id: 'result',
              text: '진행내역',
              width: 100,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.result === '0' ? '미완료' : '완료'}
                />
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          data={scope.state.data}
        />
        <FlexLayout weight={0.8}>
          <IFrame src={scope.state.url} />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
