import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ae200w_01
 * @category 지출결의서일괄출력
 */
export class DisbursementModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  inputdate: string;

  /**
   * 번호
   *
   * **data type** varchar(3)
   */
  inputnum: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  inputsabun: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  subject: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 결재일자
   *
   * **data type** varchar(8)
   */
  appdate: string;

  /**
   * 결재
   *
   * **data type** varchar(3)
   */
  appgubun: string;

  /**
   * 결재자
   *
   * **data type** varchar(10)
   */
  appperid: string;

  /**
   * 결재비고
   *
   * **data type** varchar(255)
   */
  appremark: string;

  /**
   * 문서번호
   *
   * **data type** varchar(20)
   */
  appnum: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  gubun: string;

  /**
   * 첨부여부
   *
   * **data type** varchar(1)
   */
  picflag: string;

  /**
   * 참조
   *
   * **data type** varchar(255)
   */
  refer: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  mijnum: string;

  /**
   * 지급
   *
   * **data type** varchar(1)
   */
  mijflag: string;

  /**
   * 출력구분
   *
   * **data type** varchar(1)
   */
  prtchk: string;

  /**
   * 상신시간
   *
   * **data type** varchar(6)
   */
  apptime: string;

  /**
   * 지급여부
   *
   * **data type** varchar(2)
   */
  sndflag: string;

  /**
   * 첨부파일
   *
   * **data type** varchar(30)
   */
  attcnt: string;

  /**
   * 작성자
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 금액
   *
   * **data type** number
   */
  mamt: string;

  /**
   * 선택
   *
   * **data type** varchar(1)
   */
  chk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.inputdate = data.inputdate || '';
    this.chk = data.chk || '';
    this.mamt = data.mamt || '';
    this.sndflag = data.sndflag || '';
    this.attcnt = data.attcnt || '';
    this.pernm = data.pernm || '';
    this.inputnum = data.inputnum || '';
    this.inputsabun = data.inputsabun || '';
    this.subject = data.subject || '';
    this.remark = data.remark || '';
    this.divicd = data.divicd || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appperid = data.appperid || '';
    this.appremark = data.appremark || '';
    this.appnum = data.appnum || '';
    this.gubun = data.gubun || '';
    this.picflag = data.picflag || '';
    this.refer = data.refer || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.mijflag = data.mijflag || '';
    this.prtchk = data.prtchk || '';
    this.apptime = data.apptime || '';
    this.custcd = data.custcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
