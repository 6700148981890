import axios, { AxiosResponse } from 'axios/index';
import { ApiService } from '../utils/string';

class ActionRepository {
  URL: string;

  TIMEOUT: number;

  constructor() {
    this.URL = `${process.env.REACT_APP_API_SERVICE_HOST}`;
    this.TIMEOUT = parseInt(process.env.REACT_APP_API_TIMEOUT || '10000', 10);
  }

  process(category: string, action: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        category,
        params.window,
        'action',
        action,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  retrieve(category: string, params: any): Promise<AxiosResponse<any>> {
    return this.process(category, 'retrieve', params);
  }

  new(category: string, params: any): Promise<AxiosResponse<any>> {
    return this.process(category, 'new', params);
  }

  save(category: string, params: any): Promise<AxiosResponse<any>> {
    return this.process(category, 'save', params);
  }

  delete(category: string, params: any): Promise<AxiosResponse<any>> {
    return this.process(category, 'delete', params);
  }

  print(category: string, params: any): Promise<AxiosResponse<any>> {
    return this.process(category, 'print', params);
  }

  excel(category: string, params: any): Promise<AxiosResponse<any>> {
    return this.process(category, 'excel', params);
  }
}

export default new ActionRepository();
