import { GridLayoutHeader } from '../../../../../constants';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { FormatNumberTextBox } from '../../../../../components/forms/FormatNumberTextBox';
import { PartModel } from '../models';
import { OrderStatement } from '../OrderStatement';

// 부품내역
const Part: GridLayoutHeader<PartModel, OrderStatement>[] = [
  {
    id: 'balseq',
    text: '순번',
    width: 50,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.balseq}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.psize}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'deldate',
    text: '납기일',
    width: 150,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.deldate}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.uamt}
      />
    ),
  },
  {
    id: 'samt',
    text: '공급금액',
    width: 100,
    trail: (_, scope) => (
        <FlexLayout
          justify="right"
          align="center"
        >
          {scope?.state.tabPartSamt_tot || '0'}
        </FlexLayout>
    ),
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.samt}
      />
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tabPartTamt_tot || '0'}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.tamt}
      />
    ),
  },
  {
    id: 'mamt',
    text: '합계',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.tabPartMamt_tot || '0'}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        value={x.mamt}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 250,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
];

export default Part;
