import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e411_wad
 * @category 고장다발처리보고서
 */

export class BreakReportModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 다발보고일자
   *
   * **data type** varchar(8)
   */
  readonly waddate: string;

  /**
   * 번호
   *
   * **data type** varchar(?)
   */
  readonly wadnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly recedate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   * 다발횟수
   *
   * **data type** varchar(10)
   */
  readonly wad: number;

  /**
   * 고장시간
   *
   * **data type** varchar(10)
   */
  readonly appgubun: string;

  /**
   * 부서코드
   *
   * **data type** varchar()
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar()
   */
  readonly divinm: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.waddate = data.waddate || '';
    this.wadnum = data.wadnum || '';
    this.recedate = data.recedate || '';
    this.perid = data.perid || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.wad = data.wad || 0;
    this.equpnm = data.equpnm || '';
    this.compdate = data.compdate || '';
    this.enddate = data.enddate || '';
    this.appgubun = data.appgubun || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
