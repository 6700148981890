import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import { ManageStatusTemplate } from './ManageStatus.template';
import {
  Date6,
  Date8,
} from '../../../../utils/time';
import { TableLayout } from '../../../../components';
import ManageStatusModel from './models/ManageStatusModel';
import { ConfirmWarning } from '../../../../utils/confirm';


interface ManageStatusState {
  // Search
  searchQuery: string;
  stmon: string;
  endmon: string;
  gubun: string;
  contflag: string;
  okbook: string;
  compbook: string;
  balcltcd: string;
  balcltnm: string;
  balflag: string;

  // Head
  data: Array<any>;
  focusIndex: number;
  content: any;
  gubuns: Array<any>;

  // Head Tab - Contract > Table (tab1)
  dataHeadTabTableContract: Array<ManageStatusModel>;
  contractState1_tot?: string;
  contractState2_tot?: string;
  contractContamt_tot?: string;
  contractStamt_tot?: string;
  contractMidamt_tot?: string;
  contractEndamt_tot?: string;
  contractBalamt_tot?: string;
  contractElecamt_tot?: string;
  contractScamt_tot?: string;
  contractRcvamt_tot?: string;
  contractResuamt_tot?: string;
  contractEx_mijamt_tot?: string;
  contractEx_iamt_tot?: string;
  contractMijamt_tot?: string;

  // Head Tab - RealCheckDate > Table (tab2)
  dataHeadTabTableRealCheckDate: Array<ManageStatusModel>;
  realCheckDateState1_tot?: string;
  realCheckDateState2_tot?: string;
  realCheckDateContamt_tot?: string;
  realCheckDateStamt_tot?: string;
  realCheckDateMidamt_tot?: string;
  realCheckDateEndamt_tot?: string;
  realCheckDateBalamt_tot?: string;
  realCheckDateRcvamt_tot?: string;
  realCheckDateResuamt_tot?: string;
  realCheckDateEx_mijamt_tot?: string;
  realCheckDateEx_iamt_tot?: string;
  realCheckDateMijamt_tot?: string;

  // Head Tab - DesignDate > Table (tab3)
  dataHeadTabTableDesignDate: Array<ManageStatusModel>;
  designDateState1_tot?: string;
  designDateState2_tot?: string;
  designDateContamt_tot?: string;
  designDateStamt_tot?: string;
  designDateMidamt_tot?: string;
  designDateEndamt_tot?: string;
  designDateBalamt_tot?: string;
  designDateRcvamt_tot?: string;
  designDateResuamt_tot?: string;
  designDateEx_mijamt_tot?: string;
  designDateEx_iamt_tot?: string;
  designDateMijamt_tot?: string;

  // Head Tab - Arrival > Table (tab4)
  dataHeadTabTableArrival: Array<ManageStatusModel>;
  arrivalState1_tot?: string;
  arrivalState2_tot?: string;
  arrivalContamt_tot?: string;
  arrivalStamt_tot?: string;
  arrivalMidamt_tot?: string;
  arrivalEndamt_tot?: string;
  arrivalRcvamt_tot?: string;
  arrivalResuamt_tot?: string;
  arrivalEx_mijamt_tot?: string;
  arrivalEx_iamt_tot?: string;
  arrivalMijamt_tot?: string;

  // Head Tab - Pre inspect > Table (tab5)
  dataHeadTabTablePreInspect: Array<ManageStatusModel>;
  preInspectState1_tot?: string;
  preInspectState2_tot?: string;
  preInspectOkflag2_tot?: string;
  preInspectOkflag1_tot?: string;
  preInspectOkflag0_tot?: string;
  preInspectContamt_tot?: string;
  preInspectStamt_tot?: string;
  preInspectMidamt_tot?: string;
  preInspectEndamt_tot?: string;
  preInspectBalamt_tot?: string;
  preInspectRcvamt_tot?: string;
  preInspectResuamt_tot?: string;
  preInspectEx_mijamt_tot?: string;
  preInspectEx_iamt_tot?: string;
  preInspectMijamt_tot?: string;

  // Head Tab - Inspect > Table (tab6)
  dataHeadTabTableInspect: Array<ManageStatusModel>;
  inspectState1_tot?: string;
  inspectState2_tot?: string;
  inspectOkflag2_tot?: string;
  inspectOkflag1_tot?: string;
  inspectOkflag0_tot?: string;
  inspectContamt_tot?: string;
  inspectStamt_tot?: string;
  inspectMidamt_tot?: string;
  inspectEndamt_tot?: string;
  inspectBalamt_tot?: string;
  inspectRcvamt_tot?: string;
  inspectResuamt_tot?: string;
  inspectEx_mijamt_tot?: string;
  inspectEx_iamt_tot?: string;
  inspectMijamt_tot?: string;

  // Head Tab - Delivery > Table (tab7)
  dataHeadTabTableDelivery: Array<ManageStatusModel>;
  deliveryState1_tot?: string;
  deliveryState2_tot?: string;
  deliveryContamt_tot?: string;
  deliveryStamt_tot?: string;
  deliveryMidamt_tot?: string;
  deliveryEndamt_tot?: string;
  deliveryBalamt_tot?: string;
  deliveryRcvamt_tot?: string;
  deliveryResuamt_tot?: string;
  deliveryEx_mijamt_tot?: string;
  deliveryEx_iamt_tot?: string;
  deliveryMijamt_tot?: string;

  // 하단 탭 합계
  total: string;
  total7: string;
  samt_tot: string;
  tamt_tot: string;

  // Detail Tab - Detail > Table - Telnum
  dataHeadTabTableTelnum: Array<any>;

  // Detail Tab - Detail > Table - ScAmt
  dataHeadTabTableScAmt: Array<any>;

  // Detail Tab - Bal > Grid - Suju
  dataHeadTabGridSuju: Array<any>;
  focusedSuju?: any;

  // Detail Tab - Bal > Grid - Bal
  dataHeadTabGridBal: Array<any>;
  focusedBal: any;
  mamt_tot: string;

  // Detail Tab - Memo > Grid - Memo
  dataHeadTabGridMemo: Array<any>;

  // 견적내역
  dataHeadTabGridEstimate: Array<any>;

  // 영업내역
  dataHeadTabGridSales: Array<any>;

  focusedTop: any;
}

/**
 * 컨트롤러
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export class ManageStatus extends React.Component<PageProps, ManageStatusState>
  implements PageToolEvents {
  // Infinity handlers
  contractInfinity?: InfinityRetrieve;

  arrivalInfinity?: InfinityRetrieve;

  preInspectInfinity?: InfinityRetrieve;

  inspectInfinity?: InfinityRetrieve;

  deliveryInfinity?: InfinityRetrieve;

  realCheckDateInfinity?: InfinityRetrieve;

  designDateInfinity?: InfinityRetrieve;


  // Refs
  refTableContract: React.RefObject<TableLayout> = React.createRef();

  refTableArrival: React.RefObject<TableLayout> = React.createRef();

  refTablePreInspect: React.RefObject<TableLayout> = React.createRef();

  refTableInspect: React.RefObject<TableLayout> = React.createRef();

  refTableDelivery: React.RefObject<TableLayout> = React.createRef();

  refTableTelnum: React.RefObject<TableLayout> = React.createRef();

  refTableScAmt: React.RefObject<TableLayout> = React.createRef();

  refTableRealCheckDate: React.RefObject<TableLayout> = React.createRef();

  refTableDesignDate: React.RefObject<TableLayout> = React.createRef();


  // Updates
  updatesDataHeadTabTableTelnum: Array<any> = [];

  updatesDataHeadTabTableScAmt: Array<any> = [];


  // Head tab
  tabIndex: number = 0;

  content: ManageStatusModel = new ManageStatusModel();

  rowIndex: number = 0;


  // Detail tab
  tabDetailIndex: number = 0;

  contentTelnum: any = {};

  contentScAmt: any = {};


  dataBildju: Array<any> = [];


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      stmon: `${Date6.make().substr(0, 4)}01`,
      endmon: `${Date6.make().substr(0, 4)}12`,
      gubun: '%',
      contflag: '%',
      okbook: '%',
      compbook: '%',
      balcltcd: '',
      balcltnm: '',
      balflag: '%',

      dataHeadTabTableContract: [],
      dataHeadTabTableArrival: [],
      dataHeadTabTablePreInspect: [],
      dataHeadTabTableInspect: [],
      dataHeadTabTableDelivery: [],
      dataHeadTabTableTelnum: [],
      dataHeadTabTableScAmt: [],
      dataHeadTabTableRealCheckDate: [],
      dataHeadTabTableDesignDate: [],
      dataHeadTabGridSuju: [],
      dataHeadTabGridBal: [],
      dataHeadTabGridMemo: [],
      dataHeadTabGridEstimate: [],
      dataHeadTabGridSales: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    this.dataBildju = (await api.dropdown('wf_dd_ca510_301_01'))?.items || [];

    // 매출구분 코드 가져오기
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ gubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 모두 초기화
    this.rowIndex = -1;
    this.content = new ManageStatusModel();
    this.contentTelnum = {};
    this.contentScAmt = {};
    this.updatesDataHeadTabTableTelnum = [];
    this.updatesDataHeadTabTableScAmt = [];
    this.setState({
      dataHeadTabTableContract: [],
      dataHeadTabTableArrival: [],
      dataHeadTabTablePreInspect: [],
      dataHeadTabTableInspect: [],
      dataHeadTabTableDelivery: [],
      dataHeadTabTableTelnum: [],
      dataHeadTabTableScAmt: [],
      dataHeadTabTableRealCheckDate: [],
      dataHeadTabTableDesignDate: [],
    }, () => {
      // 무한 스크롤바 헬퍼 초기화
      const searchParams = {
        as_nm: this.state.searchQuery,
        stmon: this.state.stmon,
        endmon: this.state.endmon,
        gubun: this.state.gubun,
        contflag: this.state.contflag,
        okbook: this.state.okbook,
        compbook: this.state.compbook,
        balcltcd: this.state.balcltcd,
        balcltnm: this.state.balcltnm,
        balflag: this.state.balflag,
      };

      this.contractInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_1_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTableContract: [
              ...this.state.dataHeadTabTableContract,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTableContract.current?.update(true);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTableContract: [] }, () => this.updateTab(type));
          await this.contractInfinity?.retrieveAll();
        },
      );

      this.realCheckDateInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_2_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTableRealCheckDate: [
              ...this.state.dataHeadTabTableRealCheckDate,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTableRealCheckDate.current?.update(false);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTableRealCheckDate: [] }, () => this.updateTab(type));
          await this.realCheckDateInfinity?.retrieveAll();
        },
      );

      this.designDateInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_3_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTableDesignDate: [
              ...this.state.dataHeadTabTableDesignDate,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTableDesignDate.current?.update(false);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTableDesignDate: [] }, () => this.updateTab(type));
          await this.designDateInfinity?.retrieveAll();
        },
      );

      this.arrivalInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_4_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTableArrival: [
              ...this.state.dataHeadTabTableArrival,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTableArrival.current?.update(false);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTableArrival: [] }, () => this.updateTab(type));
          await this.arrivalInfinity?.retrieveAll();
        },
      );

      this.preInspectInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_5_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTablePreInspect: [
              ...this.state.dataHeadTabTablePreInspect,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTablePreInspect.current?.update(false);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTablePreInspect: [] }, () => this.updateTab(type));
          await this.preInspectInfinity?.retrieveAll();
        },
      );

      this.inspectInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_6_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTableInspect: [
              ...this.state.dataHeadTabTableInspect,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTableInspect.current?.update(false);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTableInspect: [] }, () => this.updateTab(type));
          await this.inspectInfinity?.retrieveAll();
        },
      );

      this.deliveryInfinity = new InfinityRetrieve(
        searchParams,
        (params) => api.fxExec('tab_7_retrieve', params),
        (items, next) => {
          this.setState({
            dataHeadTabTableDelivery: [
              ...this.state.dataHeadTabTableDelivery,
              ...items.map((x) => new ManageStatusModel(x)),
            ],
          }, async () => {
            await this.refTableDelivery.current?.update(false);
            next && next();
          });
        },
        async () => {
          await this.setState({ dataHeadTabTableDelivery: [] }, () => this.updateTab(type));
          await this.deliveryInfinity?.retrieveAll();
        },
      );

      this.updateTab(type);
    });
  }

  @action
  async updateTab(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    switch (this.tabIndex) {
      case 0:
        if (this.state.dataHeadTabTableContract.length < 1) {
          const index = await this.contractInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.contractInfinity?.box;

          await this.setState({
            contractState1_tot: data?.state1_tot || '0',
            contractState2_tot: data?.state2_tot || '0',
            contractContamt_tot: data?.contamt_tot || '0',
            contractStamt_tot: data?.stamt_tot || '0',
            contractMidamt_tot: data?.midamt_tot || '0',
            contractEndamt_tot: data?.endamt_tot || '0',
            contractBalamt_tot: data?.balamt_tot || '0',
            contractElecamt_tot: data?.elecamt_tot || '0',
            contractScamt_tot: data?.scamt_tot || '0',
            contractRcvamt_tot: data?.rcvamt_tot || '0',
            contractResuamt_tot: data?.resuamt_tot || '0',
            contractEx_iamt_tot: data?.ex_iamt_tot || '0',
            contractEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            contractMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTableContract.current?.update(true);
          this.state.dataHeadTabTableContract.length > index && this.refTableContract.current?.setFocus(index);
        }
        break;

      case 1:
        if (this.state.dataHeadTabTableRealCheckDate.length < 1) {
          const index = await this.realCheckDateInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.realCheckDateInfinity?.box;

          this.setState({
            realCheckDateState1_tot: data?.state1_tot || '0',
            realCheckDateState2_tot: data?.state2_tot || '0',
            realCheckDateContamt_tot: data?.contamt_tot || '0',
            realCheckDateStamt_tot: data?.stamt_tot || '0',
            realCheckDateMidamt_tot: data?.midamt_tot || '0',
            realCheckDateEndamt_tot: data?.endamt_tot || '0',
            realCheckDateBalamt_tot: data?.balamt_tot || '0',
            realCheckDateRcvamt_tot: data?.rcvamt_tot || '0',
            realCheckDateResuamt_tot: data?.resuamt_tot || '0',
            realCheckDateEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            realCheckDateEx_iamt_tot: data?.ex_iamt_tot || '0',
            realCheckDateMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTableRealCheckDate.current?.update(true);
          this.state.dataHeadTabTableRealCheckDate.length > index && this.refTableRealCheckDate.current?.setFocus(index);
        }
        break;

      case 2:
        if (this.state.dataHeadTabTableDesignDate.length < 1) {
          const index = await this.designDateInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.designDateInfinity?.box;

          this.setState({
            designDateState1_tot: data?.state1_tot || '0',
            designDateState2_tot: data?.state2_tot || '0',
            designDateContamt_tot: data?.contamt_tot || '0',
            designDateStamt_tot: data?.stamt_tot || '0',
            designDateMidamt_tot: data?.midamt_tot || '0',
            designDateEndamt_tot: data?.endamt_tot || '0',
            designDateBalamt_tot: data?.balamt_tot || '0',
            designDateRcvamt_tot: data?.rcvamt_tot || '0',
            designDateResuamt_tot: data?.resuamt_tot || '0',
            designDateEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            designDateEx_iamt_tot: data?.ex_iamt_tot || '0',
            designDateMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTableDesignDate.current?.update(true);
          this.state.dataHeadTabTableDesignDate.length > index && this.refTableDesignDate.current?.setFocus(index);
        }
        break;

      case 3:
        if (this.state.dataHeadTabTableArrival.length < 1) {
          const index = await this.arrivalInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.arrivalInfinity?.box;

          this.setState({
            arrivalState1_tot: data?.state1_tot || '0',
            arrivalState2_tot: data?.state2_tot || '0',
            arrivalContamt_tot: data?.contamt_tot || '0',
            arrivalStamt_tot: data?.stamt_tot || '0',
            arrivalMidamt_tot: data?.midamt_tot || '0',
            arrivalEndamt_tot: data?.endamt_tot || '0',
            arrivalRcvamt_tot: data?.rcvamt_tot || '0',
            arrivalResuamt_tot: data?.resuamt_tot || '0',
            arrivalEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            arrivalEx_iamt_tot: data?.ex_iamt_tot || '0',
            arrivalMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTableArrival.current?.update(true);
          this.state.dataHeadTabTableArrival.length > index && this.refTableArrival.current?.setFocus(index);
        }
        break;

      case 4:
        if (this.state.dataHeadTabTablePreInspect.length < 1) {
          const index = await this.preInspectInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.preInspectInfinity?.box;

          this.setState({
            preInspectState1_tot: data?.state1_tot || '0',
            preInspectState2_tot: data?.state2_tot || '0',
            preInspectOkflag2_tot: data?.okflag2_tot || '0',
            preInspectOkflag1_tot: data?.okflag1_tot || '0',
            preInspectOkflag0_tot: data?.okflag0_tot || '0',
            preInspectContamt_tot: data?.contamt_tot || '0',
            preInspectStamt_tot: data?.stamt_tot || '0',
            preInspectMidamt_tot: data?.midamt_tot || '0',
            preInspectEndamt_tot: data?.endamt_tot || '0',
            preInspectBalamt_tot: data?.balamt_tot || '0',
            preInspectRcvamt_tot: data?.rcvamt_tot || '0',
            preInspectResuamt_tot: data?.resuamt_tot || '0',
            preInspectEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            preInspectEx_iamt_tot: data?.ex_iamt_tot || '0',
            preInspectMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTablePreInspect.current?.update(true);
          this.state.dataHeadTabTablePreInspect.length > index && this.refTablePreInspect.current?.setFocus(index);
        }
        break;

      case 5:
        if (this.state.dataHeadTabTableInspect.length < 1) {
          const index = await this.inspectInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.inspectInfinity?.box;

          this.setState({
            inspectState1_tot: data?.state1_tot || '0',
            inspectState2_tot: data?.state2_tot || '0',
            inspectOkflag2_tot: data?.okflag2_tot || '0',
            inspectOkflag1_tot: data?.okflag1_tot || '0',
            inspectOkflag0_tot: data?.okflag0_tot || '0',
            inspectContamt_tot: data?.contamt_tot || '0',
            inspectStamt_tot: data?.stamt_tot || '0',
            inspectMidamt_tot: data?.midamt_tot || '0',
            inspectEndamt_tot: data?.endamt_tot || '0',
            inspectBalamt_tot: data?.balamt_tot || '0',
            inspectRcvamt_tot: data?.rcvamt_tot || '0',
            inspectResuamt_tot: data?.resuamt_tot || '0',
            inspectEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            inspectEx_iamt_tot: data?.ex_iamt_tot || '0',
            inspectMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTableInspect.current?.update(true);
          this.state.dataHeadTabTableInspect.length > index && this.refTableInspect.current?.setFocus(index);
        }
        break;

      case 6:
        if (this.state.dataHeadTabTableDelivery.length < 1) {
          const index = await this.deliveryInfinity?.retrieveTo('actcd', this.content?.actcd, type, true) || 0;
          const data = await this.deliveryInfinity?.box;

          this.setState({
            deliveryState1_tot: data?.state1_tot || '0',
            deliveryState2_tot: data?.state2_tot || '0',
            deliveryContamt_tot: data?.contamt_tot || '0',
            deliveryStamt_tot: data?.stamt_tot || '0',
            deliveryMidamt_tot: data?.midamt_tot || '0',
            deliveryEndamt_tot: data?.endamt_tot || '0',
            deliveryBalamt_tot: data?.balamt_tot || '0',
            deliveryRcvamt_tot: data?.rcvamt_tot || '0',
            deliveryResuamt_tot: data?.resuamt_tot || '0',
            deliveryEx_mijamt_tot: data?.ex_mijamt_tot || '0',
            deliveryEx_iamt_tot: data?.ex_iamt_tot || '0',
            deliveryMijamt_tot: data?.mijamt_tot || '0',
          });

          await this.refTableDelivery.current?.update(true);
          this.state.dataHeadTabTableDelivery.length > index && this.refTableDelivery.current?.setFocus(index);
        }
        break;
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save(
      {
        custcd: this.content?.custcd,
        spjangcd: this.content?.spjangcd,
        cltcd: this.content?.cltcd,
        actcd: this.content?.actcd,
        state: this.content?.state,
        balcltcd: this.content?.balcltcd,
        actnm: this.content?.actnm,
        scactnm: this.content?.scactnm,
        qty: this.content?.qty,
        okbook: this.content?.okbook,
        compbook: this.content?.compbook,
        baldate: this.content?.baldate,
        ipsundate: this.content?.ipsundate,
        stdate: this.content?.stdate,
        enddate: this.content?.enddate,
        kcdate: this.content?.kcdate,
        contdate: this.content?.contdate,
        bilddate: this.content?.bilddate,
        okflag: this.content?.okflag,
        remark: this.content?.remark,
        scremark: this.content?.scremark,
        bildju: this.content?.bildju,
        items: this.updatesDataHeadTabTableTelnum.map((x) => ({
          new: x?.new || '',
          actcd: x?.actcd || '',
          seq: x?.seq || '',
          gubun: x?.gubun || '',
          perid: x?.perid || '',
          pernm: x?.pernm || '',
          telnum: x?.telnum || '',
          faxnum: x?.faxnum || '',
          remark: x?.remark || '',
        })),
        items2: this.updatesDataHeadTabTableScAmt.map((x) => ({
          new: x?.new || '',
          actcd: x?.actcd || '',
          seq: x?.seq || '',
          gubun: x?.gubun || '',
          cltcd: x?.cltcd || '',
          cltnm: x?.cltnm || '',
          cltflag: x?.cltflag || '',
          perid: x?.perid || '',
          pernm: x?.pernm || '',
          schdate: x?.schdate || '',
          mijdate: x?.mijdate || '',
          mijnum: x?.mijnum || '',
          mijflag: x?.mijflag || '',
          amt: x?.amt || '',
          remark: x?.remark || '',
        })),
      },
      false,
    )) {
      this.onRowFocusEvent(this.content, this.rowIndex);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;

    switch (this.tabIndex) {
      case 0:
        await api.fxPrint('tab_1_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 1:
        await api.fxPrint('tab_2_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 2:
        await api.fxPrint('tab_3_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 3:
        await api.fxPrint('tab_4_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 4:
        await api.fxPrint('tab_5_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 5:
        await api.fxPrint('tab_6_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 6:
        await api.fxPrint('tab_7_print', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;
    }
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.getPrintRowCount() > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;

    switch (this.tabIndex) {
      case 0:
        await api.fxExcel('tab_1_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 1:
        await api.fxExcel('tab_2_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 2:
        await api.fxExcel('tab_3_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 3:
        await api.fxExcel('tab_4_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 4:
        await api.fxExcel('tab_5_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 5:
        await api.fxExcel('tab_6_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;

      case 6:
        await api.fxExcel('tab_7_excel', {
          as_nm: this.state.searchQuery,
          stmon: this.state.stmon,
          endmon: this.state.endmon,
          gubun: this.state.gubun,
          contflag: this.state.contflag,
          okbook: this.state.okbook,
          compbook: this.state.compbook,
          balflag: this.state.balflag,
        });
        break;
    }
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    this.content = item;
    this.rowIndex = index;

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_list_RowFocuschanged', {
      actcd: this.content?.actcd,
      orddate: this.content?.orddate,
      ordnum: this.content?.ordnum,
      projno: this.content?.projno,
    });

    this.setState({
      dataHeadTabTableTelnum: data?.items1 || [],
      dataHeadTabTableScAmt: data?.items2 || [],
      dataHeadTabGridSuju: data?.items3 || [],
      dataHeadTabGridBal: data?.items4 || [],
      dataHeadTabGridMemo: data?.items5 || [],
      dataHeadTabGridEstimate: data?.items6 || [],
      dataHeadTabGridSales: data?.items7 || [],
      focusedSuju: data?.items3?.length > 0 ? data?.items3[0] : undefined,
      focusedBal: data?.items4?.length > 0 ? data?.items4[0] : undefined,
      samt_tot: data?.samt_tot || '0',
      tamt_tot: data?.tamt_tot || '0',
      mamt_tot: data?.mamt_tot || '0',
      total: data?.total || '0',
    }, () => {
      switch (this.tabDetailIndex) {
        case 0:
          this.refTableTelnum.current?.update(true);
          this.refTableScAmt.current?.update(true);
          break;
      }
    });
  }

  @action
  onRowFocusEventTelnum(item: any) {
    this.contentTelnum = item;
  }

  @action
  onRowFocusEventScAmt(item: any) {
    this.contentScAmt = item;
  }


  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    this.updateTab();
  }

  getPrintRowCount(): number {
    let rowCount = 0;
    switch (this.tabIndex) {
      case 0:
        rowCount = this.state.dataHeadTabTableContract.length;
        break;

      case 1:
        rowCount = this.state.dataHeadTabTableRealCheckDate.length;
        break;

      case 2:
        rowCount = this.state.dataHeadTabTableDesignDate.length;
        break;

      case 3:
        rowCount = this.state.dataHeadTabTableArrival.length;
        break;

      case 4:
        rowCount = this.state.dataHeadTabTablePreInspect.length;
        break;

      case 5:
        rowCount = this.state.dataHeadTabTableInspect.length;
        break;

      case 6:
        rowCount = this.state.dataHeadTabTableDelivery.length;
        break;
    }

    return rowCount;
  }

  @action
  onTabDetailChange(index: number) {
    this.tabDetailIndex = index;
  }


  @action
  async onClickTelnumNew() {
    if (!this.content.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_2_new', {
      actcd: this.content?.actcd,
      seq: this.state.dataHeadTabTableTelnum.length < 1
        ? '01'
        : `00${this.state.dataHeadTabTableTelnum.length + 1}`.substr(-2),
    });

    if (data) {
      this.setState({
        dataHeadTabTableTelnum: [
          ...this.state.dataHeadTabTableTelnum,
          {
            ...data,
            new: '1',
          },
        ],
      }, async () => {
        await this.refTableTelnum.current?.update(false);
        this.refTableTelnum.current?.setFocus(this.state.dataHeadTabTableTelnum.length - 1, 1);
      });
    }
  }

  @action
  async onClickTelnumDelete() {
    if (!this.contentTelnum.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.fxDelete('dw_2_delete', `담당자 연락처 ${this.contentTelnum?.seq}번`, {
      actcd: this.contentTelnum?.actcd,
      seq: this.contentTelnum?.seq,
    })) {
      this.onRowFocusEvent(this.content, this.rowIndex);
    }
  }


  @action
  async onClickScAmtNew() {
    if (!this.content.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_3_new', {
      actcd: this.content?.actcd,
      seq: this.state.dataHeadTabTableScAmt.length < 1
        ? '01'
        : `00${this.state.dataHeadTabTableScAmt.length + 1}`.substr(-2),
    });

    if (data) {
      this.setState({
        dataHeadTabTableScAmt: [
          ...this.state.dataHeadTabTableScAmt,
          {
            ...data,
            new: '1',
          },
        ],
      }, async () => {
        await this.refTableScAmt.current?.update(false);
        this.refTableScAmt.current?.setFocus(this.state.dataHeadTabTableScAmt.length - 1, 1);
      });
    }
  }

  @action
  async onClickScAmtDelete() {
    if (!this.contentScAmt.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.fxDelete('dw_3_delete', `설치비 지급내역 ${this.contentScAmt?.seq}번`, {
      actcd: this.contentScAmt?.actcd,
      seq: this.contentScAmt?.seq,
    })) {
      this.onRowFocusEvent(this.content, this.rowIndex);
    }
  }


  @action
  onHeadChange(updatedRows: ManageStatusModel[]) {
    // eslint-disable-next-line prefer-destructuring
    this.content = updatedRows[0];
  }

  @action
  onDetailTelnumChange(updatedRows: any[]) {
    this.updatesDataHeadTabTableTelnum = updatedRows;
  }

  @action
  onDetailScAmtChange(updatedRows: any[]) {
    this.updatesDataHeadTabTableScAmt = updatedRows;
  }


  @action
  onClickOpenEnrollment() {
    if (!this.state.focusedSuju?.orddate) {
      return;
    }

    this.props.publicStore.go(
      '/w_tb_da006',
      {
        searchQuery: this.state.focusedSuju?.projnm,
        stdate: this.state.focusedSuju?.orddate,
        enddate: this.state.focusedSuju?.orddate,
      },
    );
  }

  @action
  onClickOpenResist(item: any) {
    if (!item.actcd) {
      return;
    }

    this.props.publicStore.go(
      '/w_tb_e601_sulchi',
      {
        searchQuery: item.actcd,
      },
    );
  }

  @action
  onClickOpenBalEnrollment() {
    if (!this.state.focusedBal?.baldate) {
      return;
    }

    this.props.publicStore.go(
      '/w_tb_ca608_01',
      {
        searchQuery: this.state.focusedBal?.cltnm,
        stdate: this.state.focusedBal?.baldate,
        enddate: this.state.focusedBal?.baldate,
      },
    );
  }


  // eslint-disable-next-line class-methods-use-this
  headerRowColor(item: any): string {
    if (item.state === '2') {
      return '#FFF017';
    }

    if (!item.baldate) {
      return '#FFFFFF';
    }

    if (item.baldate < Date8.make()) {
      return '#A6D7F4';
    }

    return '#FFFFFF';
  }


  render() {
    return <ManageStatusTemplate
      scope={this}
    />;
  }
}
