import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e033w
 * @category 자체점검현황
 */
export default class SelfCheckModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 결과
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 계획일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 점검일자
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 회사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 계약
   *
   * **data type** varchar(8)
   */
  readonly contg: string;

  /**
   * 시간
   *
   * **data type** varchar(8)
   */
  readonly plantime: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 보조담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm2: string;

  /**
   * 점검자
   *
   * **data type** varchar(8)
   */
  readonly kcpernm: string;

  /**
   * 보조점검자
   *
   * **data type** varchar(8)
   */
  readonly kcpernm2: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.state = data.state || '';
    this.plandate = data.plandate || '';
    this.rptdate = data.rptdate || '';
    this.equpnm = data.equpnm || '';
    this.wkactcd = data.wkactcd || '';
    this.contg = data.contg || '';
    this.plantime = data.plantime || '';
    this.pernm = data.pernm || '';
    this.pernm2 = data.pernm2 || '';
    this.kcpernm = data.kcpernm || '';
    this.kcpernm2 = data.kcpernm2 || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
