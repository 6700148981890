import { FlexLayout, FormatNumberTextBox, TextBox } from '../../../../../components';
import { TLHF } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 탭 > 테이블 - 결제조건
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentTabConditionsTableHeader: TLHF<any, Enrollment> = () => ([
  {
    id: 'seq',
    text: '차수',
    width: 10,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}
      </FlexLayout>
    ),
  },
  {
    id: 'costnm',
    text: '지급구분',
    width: 10,
    render: (x, rowUpdate, ref) => (

      <TextBox
        ref={ref}
        value={x.costnm}
        onChange={(value) => rowUpdate({ costnm: value })}
      />
    ),
  },
  {
    id: 'qty',
    text: '지급율',
    width: 10,
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(value) => rowUpdate({ qty: value })}
      />
    ),
  },
  {
    id: 'mamt',
    text: '금액',
    width: 10,
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumMamt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.mamt}
        onChange={(value) => rowUpdate({ mamt: value })}
      />
    ),
  },
  {
    id: 'remark',
    text: '지급시기',
    width: 60,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(value) => rowUpdate({ remark: value })}
      />
    ),
  },
]);
