import * as React from 'react';
import { inject } from 'mobx-react';
// @ts-ignore
import { ExternalPageProps } from '../../../../constants';

/**
 * 컨트롤러
 * @window w_tb_ia090_list
 * @category 전자세금계산서현황
 */
@inject('toolStore')
export class TaxInvoice extends React.Component<ExternalPageProps> {
  componentDidMount(): void {
    window.open('https://www.etradebill.co.kr/index.jsp?sso=ok');
    this.props.toolStore.onCloseHandler();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <React.Fragment />
    );
  }
}
