import * as React from 'react';
import { FiTrash, FiUpload } from 'react-icons/fi';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  GridLayout, ImageView,
  LabelBox,
  LoaderButton,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Proposal, ProposalImages } from './Proposal';
import { ProposalGridHeader } from './headers/ProposalGridHeader';
import { SearchDateRange } from '../../../../components/search';
import ProposalModel from './models/ProposalModel';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_sample
 * @category 샘플페이지
 */
export const ProposalTemplate: React.FC<TemplateProps<Proposal>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  const content = scope.state?.content || new ProposalModel();

  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new ProposalModel({
      ...content,
      ...data,
    }, content.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={200}>
          <span>결과</span>
          <ComboBox
            value={scope.state.state}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '2', remark: '처리' },
              { value: '1', remark: '미처리' },
            ].map((x: any) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(v) => setData({ state: v.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <Button>EMAIL</Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={ProposalGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          disabled={!content?.custcd}
          disabledText="신규로 추가하거나 현장을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="제안일자">
              <DateTextBox
                readonly={content?.new === '0'}
                format="####-##-##"
                value={content?.propodate}
                textAlign="center"
                onChange={(v) => setContent({ propodate: v })}
              />

              <TextBox
                weight={0.5}
                value={content?.proponum}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="구분">
              <ComboBox
                value={content.gubun || '12'}
                textAlign="center"
                data={[
                  { value: '12', remark: '수리공사' },
                  { value: '13', remark: '부품교체' },
                  { value: '15', remark: '리모델링' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setContent({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox title="부품파트">
              <ComboBox
                value={content.part}
                textAlign="center"
                data={scope.state.parts?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
                onSelect={(option) => setContent({ part: option.value })}
              />
            </LabelBox>

            <LabelBox
              title="담당자부서"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                textAlign="center"
                value={content?.divicd}
                onChange={(value) => setContent({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setContent({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                textAlign="center"
                value={content?.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={3}
              title="현장명"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content?.actcd}
                textAlign="center"
                onChange={(value) => setContent({ actcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setContent({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                value={content?.actnm}
                weight={2}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="담당자"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content?.perid}
                textAlign="center"
                onChange={(value) => setContent({ pernm: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({ perid: item.cd, pernm: item.cdnm });
                  },
                )}
              />

              <TextBox
                value={content?.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox title="담당진단결과">
              <TextBox
                value={content?.remark}
                onChange={(v) => setContent({ remark: v })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout >
            <LabelBox
              title="조사내용사진"
              footer={
                <DefaultLayout size={120}>
                  <FlexLayout>
                    <React.Fragment>
                      <LoaderButton
                        onClick={() => scope.imageSelect(ProposalImages.IMG)}
                        percent={scope.state.percentImage}
                      >
                        <FiUpload />
                        <span>등록</span>
                      </LoaderButton>

                      <Button
                        isFull={true}
                        theme={BUTTON_THEMES.RED}
                        onClick={() => scope.imageDelete(ProposalImages.IMG)}
                      >
                        <FiTrash />
                        <span>삭제</span>
                      </Button>
                    </React.Fragment>
                  </FlexLayout>
                </DefaultLayout>
              }>
              <ImageView
                src={scope.state.img}
              />
            </LabelBox>

            <LabelBox
              title="처리내용사진"
              footer={
                <DefaultLayout size={120}>
                  <FlexLayout>
                    <React.Fragment>
                      <LoaderButton
                        onClick={() => scope.imageSelect(ProposalImages.IMG2)}
                         percent={scope.state.percentImage2}
                      >
                        <FiUpload />
                        <span>등록</span>
                      </LoaderButton>

                      <Button
                        isFull={true}
                        theme={BUTTON_THEMES.RED}
                        onClick={() => scope.imageDelete(ProposalImages.IMG2)}
                      >
                        <FiTrash />
                        <span>삭제</span>
                      </Button>
                    </React.Fragment>
                  </FlexLayout>
                </DefaultLayout>
              }>
              <ImageView
                src={scope.state.img2}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
