import { RiMapPin2Fill } from 'react-icons/all';
import { Global, TLHF } from '../../../../../constants';
import {
  AddressButton,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  TextBox,
} from '../../../../../components';
import { Schedule } from '../Schedule';
import ScheduleModel from '../models/ScheduleModel';
import { SearchBinding } from '../../../../../models';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_b501
 * @category 영업스케쥴
 */
export const ScheduleTableHeader: TLHF<ScheduleModel, Schedule> = (scope) => ([
  {
    id: 'state',
    text: '상태',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.state === '1' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
      >
        {x.state === '1' ? '계획' : '방문'}
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '계획일자',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        format="####-##-##"
        textAlign="center"
        value={x.plandate}
        onChange={(v) => rowUpdate({ plandate: v })}
      />
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x, rowUpdatee) => (
      <ComboBox
        value={x.gubun}
        data={[
          { value: '11', remark: '보수영업' },
          { value: '14', remark: '설치영업' },
          { value: '15', remark: '리모델링' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdatee({ gubun: option.value })}
      />
    ),
  },
  {
    id: 'actcd',
    text: '코드',
    width: 70,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ actcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });
            scope.modalTableItemChanged(rowUpdate, 'actcd', item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'perid',
    text: '계획자',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.perid}
        textAlign="center"
        onChange={(value) => rowUpdate({ perid: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_JA001',
          {},
          true,
          (item) => {
            rowUpdate({
              perid: item.cd,
              pernm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'pernm',
    text: '계획자명',
    width: 100,
    color: 'var(--color-red)',
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'zipcd',
    text: '우편번호',
    width: 80,
    render: (x, rowUpdate) => (
      <FlexLayout>
        <FlexLayout
          align="center"
          justify="center"
        >
          {x.zipcd}
        </FlexLayout>

        <AddressButton
          publicStore={scope.props.publicStore}
          modalStore={scope.props.modalStore}
          onChange={(address) => rowUpdate({
            zipcd: address.zonecode,
            address: address.address,
            address2: address.jibunAddress,
            lat: address.y,
            lng: address.x,
          })}
          isIcon={true}
        >
          <RiMapPin2Fill size={18} />
        </AddressButton>
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'address2',
    text: '상세주소',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.address2}
        onChange={(v) => rowUpdate({ address2: v })}
      />
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
