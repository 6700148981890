import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export default class WorkerModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 이메일
   *
   * **data type** varchar(8)
   */
  readonly email: string;

  /**
   * 휴대폰번호
   *
   * **data type** varchar(8)
   */
  readonly hpnum: string;

  /**
   * 아이디
   *
   * **data type** varchar(8)
   */
  readonly id: string;

  /**
   * 가입일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 작성자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 위도
   *
   * **data type** varchar(8)
   */
  readonly lat: string;

  /**
   * 경도
   *
   * **data type** varchar(8)
   */
  readonly lng: string;

  /**
   * 비밀번호
   *
   * **data type** varchar(8)
   */
  readonly password1: string;

  /**
   * 비밀번호 재입력
   *
   * **data type** varchar(8)
   */
  readonly password2: string;

  /**
   * 작성자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 주민번호
   *
   * **data type** varchar(8)
   */
  readonly rnum: string;

  /**
   * 사업자구분
   *
   * **data type** varchar(8)
   */
  readonly rflag: string;

  /**
   * 토큰
   *
   * **data type** varchar(8)
   */
  readonly tokken: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcd: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.email = data.email || '';
    this.hpnum = data.hpnum || '';
    this.id = data.id || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.password1 = data.password1 || '';
    this.password2 = data.password2 || '';
    this.pernm = data.pernm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.rnum = data.rnum || '';
    this.rflag = data.rflag || '';
    this.tokken = data.tokken || '';
    this.zipcd = data.zipcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
