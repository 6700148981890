import * as React from 'react';
import {
  FlexLayout,
  TextBox,
  SearchLayout,
  ComboBox,
  ComboBoxModel,
  GridLayout,
  LayoutTitle,
  LabelBox,
  DateTextBox,
  CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Appoint } from './Appoint';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';

/**
 * 화면
 * @window w_p2120
 * @category 인사발령등록
 */
export const AppointTemplate: React.FC<TemplateProps<Appoint>> = ({
  scope,
  update,
  dataUpdate,
}) => {
  const setData = update!;
  const setData2 = dataUpdate!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout weight={4}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="성명, 부서로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData2({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
          <FlexLayout>
            <span>사업장</span>
            <ComboBox
              value={scope.state.spjangcd}
              data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
              onSelect={(option) => setData2({ spjangcd: option.value }, () => {
                scope.onRetrieveEvent();
              })}
            />
          </FlexLayout>
          <FlexLayout>
            <span>구분</span>
            <ComboBox
              value={scope.state.rtclafi}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={[
                { value: '%', remark: '전체' },
                { value: '001', remark: '재직자' },
                { value: '002', remark: '퇴직자' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => setData2({ rtclafi: option.value }, () => {
                scope.onRetrieveEvent();
              })}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>
      <FlexLayout>
        <FlexLayout weight={2}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'perid',
                text: '사번',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                    style={x.rtclafi === '001' ? {} : { color: 'var(--color-red)' }}
                  >
                    {x.perid}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '성명',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                    style={x.rtclafi === '001' ? {} : { color: 'var(--color-red)' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'divinm',
                text: '부서',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                    style={x.rtclafi === '001' ? {} : { color: 'var(--color-red)' }}
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'rspnm',
                text: '직위',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                    style={x.rtclafi === '001' ? {} : { color: 'var(--color-red)' }}
                  >
                    {x.rspnm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.appointList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout weight={5}>
          <FlexLayout isVertical={true} size={1000}>
            <LayoutTitle
              size={30}
              justify='left'
              style={{ color: 'var(--color-blue)' }}
            >
              * 가장최근 내역입니다
            </LayoutTitle>
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1} style={{ zIndex: 1 }}>
               <LabelBox title="발령일자" weight={1} isNeed={true}>
                <DateTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={scope.state.focusedDetailAppoint?.prodate}
                  readonly={!scope.state.focusedDetailAppoint?.isNew}
                  onChange={(value) => setData({ prodate: value })}
                />
               </LabelBox>

               <LabelBox title="발령구분" weight={1} isNeed={true}>
                <ComboBox
                  value={scope.state.focusedDetailAppoint?.ordercd}
                  data=
                    {
                      scope.state.ordercds?.map((y) => new ComboBoxModel(y.ordercd, y.ordernm))
                    }
                  onSelect={(option) => setData({
                    ordercd: option.value,
                    rtclafi: option.value === '02' ? '002' : '001',
                  })}
                />
               </LabelBox>

                 <LabelBox title="발령사유" weight={2}>
                 <TextBox
                  value={scope.state.focusedDetailAppoint?.ordertxt}
                  onChange={(value) => setData({ ordertxt: value })}
                 />
                 </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="사업장"
                isNeed={true}
                style={{ color: 'var(--color-red)' }}
                size={249}
              >
                <ComboBox
                  value={scope.state.focusedDetailAppoint?.spjangcd}
                  data={scope.props.publicStore.spjangs.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
                  onSelect={(option) => setData({ spjangcd: option.value })}
                />
              </LabelBox>

               <LabelBox title="부서" style={{ color: 'var(--color-red)' }} isNeed={true}>
                <TextBox
                  value={scope.state.focusedDetailAppoint?.divicd}
                  textAlign={'center'}
                  onChange={(value) => setData({ divicd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JC002',
                    {},
                    true,
                    (item) => {
                      setData({
                        divicd: item.cd,
                        divinm: item.cdnm,
                      });
                    },
                  )}
                />
                <TextBox
                  value={scope.state.focusedDetailAppoint?.divinm}
                  onChange={(value) => setData({ divinm: value })}
                  readonly={true}
                />
               </LabelBox>

               <LabelBox title="직위" style={{ color: 'var(--color-red)' }}>
                <TextBox
                  value={scope.state.focusedDetailAppoint?.rspcd}
                  textAlign={'center'}
                  onChange={(value) => setData({ rspcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_PZ001',
                    {},
                    true,
                    (item) => {
                      setData({
                        rspcd: item.cd,
                        rspnm: item.cdnm,
                      });
                    },
                  )}
                />
                <TextBox
                  value={scope.state.focusedDetailAppoint?.rspnm}
                  onChange={(value) => setData({ rspnm: value })}
                  readonly={true}
                />
               </LabelBox>

               <LabelBox title="생산직" size={100}>
                <CheckBox
                  value={scope.state.focusedDetailAppoint?.mpclafi === '1'}
                  onChange={(value) => setData({ mpclafi: value ? '1' : '0' })}
                />
               </LabelBox>

               <LabelBox title="퇴사" size={100}>
                <CheckBox
                  value={scope.state.focusedDetailAppoint?.rtclafi === '002'}
                  onChange={(value) => setData({ rtclafi: value ? '002' : '001' })}
                />
               </LabelBox>
            </FlexLayout>
            <GridLayout
              ref={scope.gridDetail}
              header={[
                {
                  id: 'prodate',
                  text: '일자',
                  width: 20,
                  render: (x) => (
                    <FlexLayout justify="center" align="center">
                      {Date8.withDash(x.prodate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ordercd',
                  text: '발령구분',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.ordercds?.filter((y) => y.ordercd === x.ordercd)[0]?.ordernm || '-'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'frombal',
                  text: '발령전',
                  width: 50,
                },
                {
                  id: 'tobal',
                  text: '발령후',
                  width: 50,
                },
                {
                  id: 'ordertxt',
                  text: '사유',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.ordertxt}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.appointDetailList}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowClick={(item, index) => scope.onDetailRowFocusEvent(item, index)}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
