/**
 * 모델
 * @window w_tb_e035
 * @category 점검계획등록
 */
export default class PlanExpireModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly contg: string;

  /**
   * 만료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 계획일
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 계약구분
   *
   * **data type** varchar(8)
   */
  readonly state_contg: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.contg = data.contg || '';
    this.enddate = data.enddate || '';
    this.perid = data.perid || '';
    this.plandate = data.plandate || '';
    this.state = data.state || '';
    this.state_contg = data.state_contg || '';
  }
}
