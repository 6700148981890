import * as React from 'react';
import {
  Button, CheckBox,
  ComboBox,
  ComboBoxModel, DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  OptionBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Expense } from './Expense';
import {
  SearchBinding,
  SearchBindingClt,
  SearchBindingProjects,
} from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ExpenseModel } from './Expense.model';

const EXPENSE_FLAG = [
  '',
  '변동비',
  '고정비',
];

const EXPENSE2_FLAG = [
  '',
  '원재료비',
  '인건비',
  '활동비',
  '유지비',
  '기타경비',
];

/**
 * 화면
 * @window w_tb_ae001
 * @category 정기지출항목등록
 */
export const ExpenseTemplate: React.FC<TemplateProps<Expense>> = ({
  scope,
  update,
  // @ts-ignore
  modelUpdate,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} style={{ width: 700 }}>
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="비용항목으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'artcd',
              text: '비용코드',
              color: 'var(--color-red)',
              width: 6,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  textAlign="center"
                  refs={refs}
                  value={x.artcd}
                  onChange={(value) => rowUpdate({ artcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_CA648_ARG',
                    {},
                    true,
                    (item) => {
                      // dw_2_new바로 실행되로록 하는 코드
                      // if (!x.artcd) {
                      //   scope.dw_2_new(item.cd, item.cdnm);
                      // }

                      rowUpdate({
                        artcd: item.cd,
                        artnm: item.cdnm,
                        acccd: item.uv_arg1,
                        accnm: item.uv_arg2,
                        acccd2: item.uv_arg3,
                        accnm2: item.uv_arg4,
                        gflag: item.uv_arg5.substr(0, 1),
                        jflag: item.uv_arg5.substr(1, 1),
                      });
                      scope.setState({
                        focusedExpense: new ExpenseModel({
                          ...scope.state.focusedExpense,
                          artcd: item.cd,
                          artnm: item.cdnm,
                          acccd: item.uv_arg1,
                          accnm: item.uv_arg2,
                          acccd2: item.uv_arg3,
                          accnm2: item.uv_arg4,
                          gflag: item.uv_arg5.substr(0, 1),
                          jflag: item.uv_arg5.substr(1, 1),
                        }),
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'artnm',
              text: '비용항목명',
              width: 15,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.artnm}
                </FlexLayout>
              ),
            },
            {
              id: 'jflag',
              text: '정기유무',
              width: 7,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {EXPENSE_FLAG[x.jflag]}
                </FlexLayout>
              ),
            },
            {
              id: 'gflag',
              text: '비용분류',
              width: 7,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {EXPENSE2_FLAG[x.gflag]}
                </FlexLayout>
              ),
            },
            {
              id: 'acccd',
              text: '비용계정',
              width: 6,
            },
            {
              id: 'accnm',
              text: '비용계정명',
              width: 15,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.accnm}
                </FlexLayout>
              ),
            },
            {
              id: 'acccd2',
              text: '원가계정',
              width: 6,
            },
            {
              id: 'accnm2',
              text: '원가계정명',
              width: 15,
              render: (x) => (
                <FlexLayout justify="start" align="center">
                  {x.accnm2}
                </FlexLayout>
              ),
            },
            {
              id: 'samt',
              text: '비용합계금액',
              width: 10,
              render: (x) => (
                <FlexLayout justify="end" align="center">
                  {Format.number(x.samt)}
                </FlexLayout>
              ),
            },
            {
              id: 'bigo',
              text: '비고',
              width: 14,
            },
          ]}
          data={scope.state.expenseList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />

      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <FlexLayout justify="left" weight={1}>
          <LayoutTitle size={200}>정기지출상세항목</LayoutTitle>
        </FlexLayout>
        <FlexLayout justify="right" weight={3}>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.dw_2_new()}
          >
            추가
          </Button>

          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.dw_2_delete()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.gridDetail}
          header={[
            {
              id: 'outcd',
              text: '번호',
              width: 15,
              render: (x) => (
                <FlexLayout justify="center" align="center">
                  {x.outcd}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '제목',
              width: 50,
              render: (x) => (
                <FlexLayout align="center">
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 35,
              render: (x) => (
                <FlexLayout align="center">
                  {x.cltnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.expenseDetailList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onDetailRowFocusEvent(item)}
        />

        <FlexLayout
          isVertical={true}
          weight={2.5}
        >
          <LayoutTitle>정기지출 상세항목</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="번호" isNeed={true}>
              <TextBox
                textAlign="center"
                value={model.outcd}
                onChange={(value) => modelUpdate({ outcd: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="고정금액" isNeed={true}>
              <FormatNumberTextBox
                textAlign="right"
                charAt={3}
                value={model.samt}
                onChange={(value) => modelUpdate({ samt: value })}
              />
            </LabelBox>

            <LabelBox title="증빙구분" isNeed={true}>
              <ComboBox
                value={model.gubun}
                data={[
                  { value: '01', remark: '매입세금계산서' },
                  { value: '02', remark: '매입계산서' },
                  { value: '03', remark: '매입카드' },
                  { value: '04', remark: '기타' },
                  { value: '05', remark: '수정세금계산서' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => modelUpdate({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox title="세액공제" style={{ color: 'var(--color-red)' }}>
              <TextBox
                textAlign="center"
                value={model.taxreclafi}
                onChange={(value) => modelUpdate({ taxreclafi: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_IZ903',
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      taxreclafi: item.cd,
                      taxrenm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model.taxrenm}
                onChange={(value) => modelUpdate({ taxrenm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="비용항목">
              <TextBox
                textAlign="center"
                value={model.artcd}
                onChange={(value) => modelUpdate({ artcd: value })}
                readonly={true}
              />
              <TextBox
                value={model.artnm}
                onChange={(value) => modelUpdate({ artnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="경비증빙여부">
              <OptionBox
                value={model.proof}
                data={[
                  { value: '1', remark: '증빙' },
                  { value: '0', remark: '미증빙' },
                ]}
                onChange={(item) => modelUpdate({ proof: item.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="제목" isNeed={true}>
              <TextBox
                value={model.remark}
                onChange={(value) => modelUpdate({ remark: value })}
              />
            </LabelBox>

            <LabelBox title="거래처" isNeed={true} style={{ color: 'var(--color-red)' }}>
              <TextBox
                size={100}
                textAlign="center"
                value={model.cltcd}
                onChange={(value) => modelUpdate({ cltcd: value })}
                bindSearchModal={new SearchBindingClt(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      cltcd: item.cltcd,
                      cltnm: item.cltnm,
                      mijcltcd: item.cltcd,
                      mijcltnm: item.cltnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.cltnm}
                onChange={(value) => modelUpdate({ cltnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="미지급거래처" isNeed={true} style={{ color: 'var(--color-red)' }}>
              <TextBox
                size={100}
                textAlign="center"
                value={model.mijcltcd}
                onChange={(value) => modelUpdate({ mijcltcd: value })}
                bindSearchModal={new SearchBindingClt(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      mijcltcd: item.cltcd,
                      mijcltnm: item.cltnm,
                      cltcd: item.cltcd,
                      cltnm: item.cltnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.mijcltnm}
                onChange={(value) => modelUpdate({ mijcltnm: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="자산구분">
              <OptionBox
                value={model.jsflag}
                data={[
                  { value: 'IG', remark: '일반' },
                  { value: 'IB', remark: '고정자산' },
                ]}
                onChange={(item) => modelUpdate({ jsflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="부가세여부">
              <OptionBox
                value={model.billkind}
                data={[
                  { value: '1', remark: '포함' },
                  { value: '0', remark: '별도' },
                ]}
                onChange={(item) => modelUpdate({ billkind: item.value })}
              />
            </LabelBox>

            <LabelBox title="영세율구분">
              <OptionBox
                value={model.osflag}
                data={[
                  { value: '213', remark: '해당' },
                  { value: '211', remark: '비해당' },
                ]}
                onChange={(item) => modelUpdate({ osflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="내수구분">
              <OptionBox
                value={model.cdflag}
                data={[
                  { value: '0', remark: '내수' },
                  { value: '1', remark: '수입' },
                ]}
                onChange={(item) => modelUpdate({ cdflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="발행구분">
              <OptionBox
                value={model.bhflag}
                data={[
                  { value: 'OD', remark: '전자' },
                  { value: 'AS', remark: '수기' },
                ]}
                onChange={(item) => modelUpdate({ bhflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="지급유형">
              <OptionBox
                value={model.mijchk}
                data={[
                  { value: 'H', remark: '현금' },
                  { value: 'B', remark: '예금' },
                ]}
                onChange={(item) => modelUpdate({ mijchk: item.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="귀속부서" style={{ color: 'var(--color-red)' }} size={199}>
              <TextBox
                textAlign="center"
                value={model.divicd}
                onChange={(value) => modelUpdate({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.divinm}
                onChange={(value) => modelUpdate({ divinm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="지급은행" style={{ color: 'var(--color-red)' }} size={290}>
              <TextBox
                textAlign="center"
                value={model.bankcd}
                onChange={(value) => modelUpdate({ bankcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AA040',
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      bankcd: item.cd,
                      banknm: item.cdnm,
                      accnum: item.uv_arg1,
                    });
                  },
                )}
              />
              <TextBox
                value={model.banknm}
                onChange={(value) => modelUpdate({ banknm: value })}
                readonly={true}
              />
              <TextBox
                size={120}
                value={model.accnum}
                onChange={(value) => modelUpdate({ accnum: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="카드" style={{ color: 'var(--color-red)' }} size={306}>
              <TextBox
                textAlign="center"
                value={model.cardco}
                onChange={(value) => modelUpdate({ cardco: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_IZ010',
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      cardco: item.uv_arg1,
                      cardnm: item.cdnm,
                      cardnum: item.cd,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model.cardnm}
                onChange={(value) => modelUpdate({ cardnm: value })}
                readonly={true}
              />
              <TextBox
                size={120}
                textAlign="center"
                value={model.cardnum}
                onChange={(value) => modelUpdate({ cardnum: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="지급처리">
              <OptionBox
                value={model.cancleflag}
                data={[
                  { value: '0', remark: '필요' },
                  { value: '1', remark: '불필요' },
                ]}
                onChange={(item) => modelUpdate({ cancleflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="저장시지급처리">
              <OptionBox
                value={model.etflag}
                data={[
                  { value: '1', remark: '예' },
                  { value: '0', remark: '아니오' },
                ]}
                onChange={(item) => modelUpdate({ etflag: item.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="프로젝트" style={{ color: 'var(--color-red)' }} size={380}>
              <TextBox
                size={90}
                textAlign="center"
                value={model.projno}
                onChange={(value) => modelUpdate({ projno: value })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      projno: item.projno,
                      projnm: item.projnm,
                      actcd: item.actcd,
                      actnm: item.actnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.projnm}
                onChange={(value) => modelUpdate({ projnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="지급예정일">
              <FormatTextBox
                size={150}
                textAlign="center"
                value={model.schdate ?? ''}
                format="##일, 말일체크"
                onChange={(value) => modelUpdate({ schdate: value })}
              />
              <CheckBox
                value={model.schchk === '1'}
                onChange={(v) => modelUpdate({ schchk: v ? '1' : '0' })}
              />
            </LabelBox>

            <LabelBox title="지급주기">
              <ComboBox
                value={model.mijjugi}
                textAlign="center"
                data={[
                  { value: '1', remark: '월' },
                  { value: '2', remark: '분기' },
                  { value: '3', remark: '반기' },
                  { value: '4', remark: '년' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => modelUpdate({ mijjugi: option.value })}
              />
            </LabelBox>

            <LabelBox title="지급시작일" size={198}>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={model.datetime || ''}
                onChange={(value) => modelUpdate({ datetime: value })}
              />
            </LabelBox>

            <LabelBox title="지급종료일" size={198}>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={model.datetime2 || ''}
                onChange={(value) => modelUpdate({ datetime2: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="현장" style={{ color: 'var(--color-red)' }} size={380}>
              <TextBox
                size={90}
                textAlign="center"
                value={model.actcd}
                onChange={(value) => modelUpdate({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    modelUpdate({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model.actnm}
                onChange={(value) => modelUpdate({ actnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="참고(비고)">
              <TextBox
                value={model.bigo}
                onChange={(value) => modelUpdate({ bigo: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
