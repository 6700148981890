import {
  SearchBinding,
  SearchBindingClt,
  SearchBindingProjects,
} from '../models/common';

export class Global {
  public static readonly LAYOUT_LABEL_BOX_HEIGHT_1 = 72;

  public static readonly LAYOUT_LABEL_BOX_HEIGHT_2 = 70;

  public static readonly LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1 = 150;

  public static readonly LAYOUT_TITLE_HEIGHT_1 = 32;

  public static readonly LAYOUT_GRID_HEIGHT_1 = 38;

  public static readonly LAYOUT_BUTTON_HEIGHT_1 = 26;

  public static readonly LAYOUT_SEARCH_HEIGHT_1 = 46;

  public static readonly STYLE_COLOR_RED = { color: 'var(--color-red)' };

  public static readonly STYLE_COLOR_BLUE = { color: 'var(--color-blue)' };

  public static readonly CATEGORY_NAME = [
    'common',
    'general',
    'hr',
    'accounting',
    'install',
    'maintenance',
    'email',
    'material',
    'plan',
  ];
}

export enum Category {
  COMMON,
  GENERAL,
  HR,
  ACCOUNTING,
  INSTALL,
  MAINTENANCE,
  EMAIL,
  MATERIAL,
  PLAN,
}

export enum ConfirmType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
  QUESTION,
}

export const ConfirmTypeName = [
  'success',
  'error',
  'warning',
  'info',
  'question',
];

export enum AskType {
  YES,
  NO,
  CANCEL,
}

export enum RetrieveFocusType {
  DEFAULT,
  FIRST,
  END,
}

export enum PAPERCD {
  JOURNAL = 101, // 업무일지
  VACATION = 201, // 휴가신청서
  OWNAUTH = 202, // 제증명발행
  HOLIWORK = 203, // 휴무근무신청서
  ESTIMATE = 301, // 견적서
  INSTALL = 302, // 설치견적서
  PRECHK = 304, // 사전검사일지
  ENDCONSTRUCT = 311, // 공사결과보고서
  COST = 401, // 지출결의서
  DAYMONEY = 402, // 일일회계마감
  REQUEST = 501, // 발주서
  WORKNIGHT = 512, // 당직근무보고서
  SAFE = 521, // 안전교육일지
  TERMINATE = 601, // 해지보고서
  ELECCONTRACT = 602, // 전자계약서
  COMPLAIN = 603, // 민원처리보고서
  SALES = 604, // 영업보고서
  FIXWAD = 605, // 다발고장처리보고서
  CRI = 606, // 중대고장처리보고서
  BUSINESSTRIP = 701, // 출장보고서
  STUDY = 702, // 교육보고서
  TOTAL = 801, // 통합문서
  MEETING = 802, // 회의록
  REQUIRE = 901, // 자재청구서
  OVERTIME = 704, // 시간외근무보고서
}

export type SearchBindingTypes = SearchBinding | SearchBindingClt | SearchBindingProjects;

export interface TempFileInfo {
  filename: string;
  extension: string;
  size: number;
  data: string;
  raw?: ArrayBuffer;
  url?: string;
}

export const KTNET_STATES = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: 'F', remark: '국세청최종접수', color: 'var(--color-blue)' },
  { value: 'B', remark: '발송전', color: 'inherit' },
  { value: 'A', remark: 'KTNET접수', color: 'var(--color-blue)' },
  { value: 'E', remark: 'KTNET오류', color: 'var(--color-red)' },
  { value: 'L', remark: '승인(화주)', color: 'var(--color-blue)' },
  { value: 'D', remark: '거부(화주)', color: 'var(--color-red)' },
  { value: 'N', remark: '이메일전송실패', color: 'var(--color-red)' },
  { value: 'T', remark: '국세청1차접수', color: 'var(--color-blue)' },
  { value: 'X', remark: '국세청오류', color: 'var(--color-red)' },
];

export interface TimeTask {
  fn: Function,
  duration: number;
  remain: number;
}
