import * as React from 'react';
import { observer } from 'mobx-react';
import { action, computed } from 'mobx';
import { ImSad2, ImSmile, IoMdHelpCircle } from 'react-icons/all';
import style from './HeaderMenu.module.scss';
import { MenuStore, PublicStore } from '../../../stores';
import { HeaderMenuItem } from './HeaderMenu.item';
import { MenuModel, UserModel } from '../../../models';
import { Time4 } from '../../../utils/time';
import {
  Button,
  TextBox,
} from '../../forms';
import { HEADER_MENU_OPTIONS, HEADER_MENU_TITLES, HeaderMenuOption } from './HeaderMenu.constants';
import { FlexLayout } from '../../layout';
import { Global } from '../../../constants';
import { JoinClassName } from '../../../utils/string';

interface HeaderMenuActions {
  onSelect: (item: MenuModel) => any;
}

interface HeaderMenuProps extends HeaderMenuActions {
  publicStore: PublicStore;
  menuStore: MenuStore;
}

interface HeaderMenuState {
  option: number;
  searchQuery: string;

  searchedMenus: Array<MenuModel>;
}

@observer
export class HeaderMenu extends React.Component<HeaderMenuProps, HeaderMenuState> {
  constructor(props: HeaderMenuProps, context?: any) {
    super(props, context);
    this.state = {
      option: HeaderMenuOption.MENU,
      searchQuery: '',

      searchedMenus: [],
    };
  }

  @computed
  get viewOptionExtra() {
    switch (this.state.option) {
      case HeaderMenuOption.MENU:
        return (
          <TextBox
            className={style.search__input}
            value={this.state.searchQuery}
            placeholder="여기서 검색하세요"
            onChange={(searchQuery) => this.setState({ searchQuery })}
            onEnter={() => this.onMenuSearch()}
          />
        );

      case HeaderMenuOption.SPJANG:
        return (
          <select
            value={this.props.publicStore.user.spjangcd}
            onChange={(e) => this.onChangeSpjangcd(e.target.value)}
          >
            {this.props.publicStore.spjangs.map((x) => <option key={x.spjangcd} value={x.spjangcd}>{x.spjangnm}</option>)}
          </select>
        );

      default:
        return <div />;
    }
  }

  @action
  onOptionSelect(option: any) {
    let op: number;
    if (typeof option === 'string') {
      op = Number(option);
    } else {
      op = option;
    }

    this.setState({ option: op }, () => {
      const { currentPage, setStateChanged } = this.props.publicStore;
      if (currentPage?.onRetrieveEvent) {
        currentPage.onRetrieveEvent();
        setStateChanged(false);
      }
    });
  }

  @action
  onMenuSearch() {
    this.setState({ searchedMenus: [] });

    const res: Array<MenuModel> = [];
    this.props.menuStore.menus
      .forEach((x) => x.children!
        .forEach((y) => y.children!
          .forEach((z) => {
            if (!z.children?.length && z.text!.indexOf(this.state.searchQuery) > -1) {
              res.push(z);
            } else if (z.children?.length) {
              z.children.forEach((n) => {
                if (!n.children?.length && n.text!.indexOf(this.state.searchQuery) > -1) {
                  res.push(n);
                }
              });
            }
          })));

    this.setState({ searchedMenus: res });
  }

  @action
  onMenuGo(item: MenuModel) {
    this.props.publicStore.headerTabStore.toggle(item);
    this.setState({ searchedMenus: [], searchQuery: '' });
  }

  @action
  onChangeSpjangcd(spjangcd: string) {
    this.props.publicStore.setUser(new UserModel({
      ...this.props.publicStore.user,
      spjangcd,
    }));
  }


  render() {
    const { publicStore, menuStore } = this.props;

    const menuComponents = menuStore.menus.map((x) => (
      <HeaderMenuItem
        key={x.id}
        menu={x}
        active={publicStore.currentMenu?.primary.id === x.id}
        onClick={() => this.props.onSelect(x)}
      />
    ));

    return (
      <div className={style.container}>
        <div
          id="header-tool-loader"
          className={style.preLoader}
        />
        {menuComponents}

        {/* 상단 검색 */}
        <div className={style.right}>
          <div className={style.infomation}>
            <div className={style.time}>
              {Time4.withDash2(publicStore?.now)}<span>{Time4.aMpM(publicStore?.now)}</span>
            </div>

            <Button
              className={JoinClassName.make([style.manager, publicStore?.isManagerOnline ? style.active : false])}
              onClick={() => publicStore.openElmanManager()}
              isIcon={true}
            >
              ELMAN MANAGER&nbsp;
              {publicStore?.isManagerOnline ? <ImSmile /> : <ImSad2 />}
            </Button>

            <Button
              onClick={() => publicStore?.modalStore?.openAdviceModal()}
              style={{
                marginLeft: 8,
                color: '#f81b1b',
                transform: 'translateY(1px)',
              }}
              isIcon={true}
            >
              <IoMdHelpCircle
                size={18.5}
              />
            </Button>
          </div>

          <div className={style.search}>
            <div className={style.search__container}>
              <div className={style.search__option}>
                <select
                  value={this.state.option}
                  style={{ textAlign: 'center' }}
                  onChange={(e) => this.onOptionSelect(e.target.value)}
                >
                  {HEADER_MENU_OPTIONS.map((x) => <option key={x} value={x}>{HEADER_MENU_TITLES[x]}</option>)}
                </select>
              </div>
              <div className={style.search__extra}>{this.viewOptionExtra}</div>
            </div>
          </div>

          {this.state.searchedMenus.length > 0 && <div className={style.search__filter}>
            <FlexLayout isVertical={true}>
              {this.state.searchedMenus.map((x) => <FlexLayout
                key={x.id}
                size={Global.LAYOUT_TITLE_HEIGHT_1}
                align="center"
                style={{ paddingLeft: 13 }}
                onClick={() => this.onMenuGo(x)}
              >
                {x.text}
              </FlexLayout>)}
            </FlexLayout>
          </div>}
        </div>
      </div>
    );
  }
}
