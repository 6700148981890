import * as React from 'react';
import {
  FiSave,
  FiUpload,
} from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import { BsDownload } from 'react-icons/bs';
import { Button, ButtonProps } from '../../../../../../components/forms/Button/Button';
import {
  FlexLayout,
  DragAndDropLayout,
  LayoutTitle,
  LoaderButton,
  IFrame,
  CheckBox,
  TextBox,
  ImageView,
} from '../../../../../../components';
import { Global, TempFileInfo } from '../../../../../../constants';
import { Format } from '../../../../../../utils/string';
import { ConfirmFail, ConfirmSuccess, ConfirmWarning } from '../../../../../../utils/confirm';
import { FileSelector } from '../../../../../../utils/file';
import { ActionStore, PublicStore } from '../../../../../../stores';
import { AttachmentInfo } from '../../../../../../models';
import { ToBase64 } from '../../../../../../utils/binary';

const fileDownload = require('js-file-download');

interface AttachmentBoxState {
  attachments: Array<AttachmentInfo>;
  percent: number;
  iframe?: string;
  preview: number;
}

interface AttachmentBoxProps extends ButtonProps {
  publicStore?: PublicStore;
  actionStore?: ActionStore;
  custcd: string;
  spjangcd: string;
  actcd: string;
  equpcd: string;
  seq: string;
  isEnabledTitle?: boolean; // 제목 기입 가능 여부
  isEnabledPreview?: boolean; // 미리보기 여부
  onChange?: (attachments: Array<TempFileInfo>) => any;
  onPreview?: (type: PREVIEW_OPTION, uri: string) => any;
}

export enum PREVIEW_OPTION {
  IMAGE,
  PDF,
  NONE,
}

@inject('publicStore', 'actionStore')
export class AttachmentBox extends React.Component<
  AttachmentBoxProps,
  AttachmentBoxState
> {
  table: React.RefObject<DragAndDropLayout>;

  constructor(props: AttachmentBoxProps, context: any) {
    super(props, context);
    this.table = React.createRef();
    this.state = {
      attachments: [],
      percent: 0,
      preview: PREVIEW_OPTION.NONE,
    };
  }

  componentDidMount() {
    this.retrieve();
  }

  componentDidUpdate(prevProps: Readonly<AttachmentBoxProps>) {
    if (prevProps.spjangcd !== this.props.spjangcd
      || prevProps.actcd !== this.props.actcd
      || prevProps.equpcd !== this.props.equpcd
      || prevProps.seq !== this.props.seq) {
      this.retrieve();
    }
  }

  componentWillUnmount() {
    if (this.state.attachments.length > 0) {
      this.save();
    }
  }

  @action
  async retrieve() {
    const { actionStore: api } = this.props;

    const data = await api!.attachment(
      {
        sub: 'w_popup_e045_img',
        custcd: this.props.custcd,
        spjangcd: this.props.spjangcd,
        actcd: this.props.actcd,
        equpcd: this.props.equpcd,
        seq: this.props.seq,
      },
      'retrieve',
    );

    if (data) {
      this.setState({
        attachments: data.items?.map((x: any) => new AttachmentInfo({ ...x, sub: 'w_popup_e045_img' })) || [],
      }, async () => {
        await this.table.current?.update();
        if (data.items?.length) this.table.current?.setFocus(0, 3);
      });
    }
  }

  @action
  async addAttachment() {
    try {
      const files = await FileSelector.multi(false);
      for (let i = 0; i < files.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.uploadAttachment(files[i].name, files[i], i);
      }
    } catch {
      ConfirmFail.show('오류', '파일 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async uploadAttachment(filename: string, file: File, index: number) {
    const { actionStore: api } = this.props;

    this.setState({
      percent: 1,
    }, async () => {
      const { data } = await api?.tempUpload(file, filename, (e: any) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        this.updatePercent(percent);
      });

      if (data) {
        const attachment = await api?.attachment(
          {
            sub: 'w_popup_e045_img',
            custcd: this.props.custcd,
            spjangcd: this.props.spjangcd,
            actcd: this.props.actcd,
            equpcd: this.props.equpcd,
            seq: this.props.seq,
            fileseq: `000${this.state.attachments.length + 1 + index}`.substr(-3),
            title: '',
            tempfile: data,
          },
          'upload',
        );

        if (attachment) {
          this.setState({
            attachments: [
              ...this.state.attachments,
              new AttachmentInfo(attachment),
            ],
          }, () => {
            this.updatePercent(0);
            this.table.current?.update(false);
          });
        } else {
          this.updatePercent(0);
        }
      } else {
        this.updatePercent(0);
      }
    });
  }

  @action
  async save() {
    const { actionStore: api } = this.props;
    await api?.attachment(
      {
        sub: 'w_popup_e045_img',
        items: this.state.attachments.map((x, i) => ({
          new: '0',
          spjangcd: x.spjangcd,
          actcd: this.props.actcd,
          equpcd: this.props.equpcd,
          seq: x.seq,
          fileseq: x.fileseq,
          sort: `000${i}`.substr(-3),
          title: x.title,
        })),
      },
      'save',
    );
  }

  @action
  updatePercent(percent: number) {
    this.setState({ percent });
  }

  @action
  async attachmentBinary(info: AttachmentInfo): Promise<ArrayBuffer> {
    const { actionStore: api } = this.props;
    return api!.attachmentBinary({
      ...info,
      sub: 'w_popup_e045_img',
      custcd: this.props.custcd,
      spjangcd: this.props.spjangcd,
      actcd: this.props.actcd,
      equpcd: this.props.equpcd,
      seq: this.props.seq,
      fileseq: info.seq,
    });
  }

  @action
  async attachmentDownload(info: AttachmentInfo) {
    const binary = await this.attachmentBinary(info);

    try {
      fileDownload(binary, `${info.filename}.${info.extension}`.trim());
    } catch {
      ConfirmFail.show('오류', '다운로드에 실패하였습니다.');
    }
  }

  @action
  async attachmentRemove(): Promise<boolean> {
    const { actionStore: api } = this.props;
    const items = this.state.attachments.filter((x) => x.delete);

    if (!items.length) {
      ConfirmWarning.show('오류', '삭제할 파일이 없습니다!');
      return false;
    }

    try {
      const data = await api?.attachment(
        {
          sub: 'w_popup_e045_img',
          items: this.state.attachments.filter((x) => x.delete).map((x) => ({
            custcd: this.props.custcd,
            spjangcd: this.props.spjangcd,
            actcd: this.props.actcd,
            equpcd: this.props.equpcd,
            seq: this.props.seq,
            fileseq: x.seq,
          })),
        },
        'wb_delete',
      );

      if (data) {
        ConfirmSuccess.showPbMessage(data);

        this.setState({
          attachments: this.state.attachments
            .filter((x) => !x.delete),
        }, () => this.table.current?.update(false));
      } else {
        ConfirmFail.show('오류', '서버 오류로 첨부파일이 삭제되지 않았습니다!');
      }

      return true;
    } catch {
      return false;
    }
  }

  render() {
    const {
      percent,
    } = this.state;

    return (
      <FlexLayout isVertical={true}>
        <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
          <LayoutTitle weight={1}>
            첨부 파일
          </LayoutTitle>
          <Button
            onClick={() => this.attachmentRemove()}
          >
            <IoMdTrash />
            <span>삭제</span>
          </Button>
          {this.props.isEnabledTitle && <Button
            onClick={() => this.save()}
          >
            <FiSave />
            <span>저장</span>
          </Button>}
          <LoaderButton
            onClick={() => this.addAttachment()}
            percent={percent}
          >
            <FiUpload />
            <span>내 PC</span>
          </LoaderButton>
        </FlexLayout>

        <FlexLayout>
          <FlexLayout weight={2}>
            <DragAndDropLayout
              ref={this.table}
              header={[
                {
                  id: 'seq',
                  text: '순번',
                  width: 10,
                }, {
                  id: 'delete',
                  text: '삭제',
                  width: 10,
                  render: (x: any, rowUpdate) => (
                    <FlexLayout justify="center" align="center">
                      <CheckBox
                        noMargin
                        value={x.delete}
                        onChange={(v) => rowUpdate({ delete: v })}
                      />
                    </FlexLayout>
                  ),
                }, {
                  id: 'download',
                  text: '다운로드',
                  width: 10,
                  render: (x: any) => (
                    <FlexLayout justify="center" align="center">
                      <Button
                        isIcon={true}
                        onClick={() => this.attachmentDownload(x)}
                      >
                        <BsDownload size={19} />
                      </Button>
                    </FlexLayout>
                  ),
                }, {
                  id: 'filename',
                  text: '파일명',
                  width: this.props.isEnabledTitle ? 20 : 40,
                  render: (x: any) => (
                    <FlexLayout justify="start" align="center">
                      {x.filename}
                    </FlexLayout>
                  ),
                }, {
                  id: 'title',
                  text: '제목',
                  width: 20,
                  isHidden: !this.props.isEnabledTitle,
                  render: (x: any, rowUpdate, refs) => (
                    <TextBox
                      refs={refs}
                      value={x.title}
                      onChange={(v) => rowUpdate({ title: v })}
                    />
                  ),
                }, {
                  id: 'extension',
                  text: '확장자',
                  width: 10,
                }, {
                  id: 'size',
                  text: '파일크기',
                  width: 20,
                  render: (x: any) => (
                    <FlexLayout justify={x.size ? 'end' : 'center'} align="center">
                      {x.size ? `${Format.number(x.size)}B` : '모름'}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={this.state.attachments || []}
              onChange={(rows: Array<AttachmentInfo>) => {
                this.setState({ attachments: rows });
              }}
              onRowFocusEvent={async (item) => {
                const binary = await this.attachmentBinary(item);
                let option = PREVIEW_OPTION.NONE;
                let mimeType = '';

                switch (item.extension.toLowerCase()) {
                  case 'jpg':
                  case 'jpeg':
                  case 'gif':
                  case 'bmp':
                  case 'png':
                    option = PREVIEW_OPTION.IMAGE;
                    mimeType = `image/${item.extension.toLowerCase()}`;
                    break;

                  case 'pdf':
                    option = PREVIEW_OPTION.PDF;
                    mimeType = 'application/pdf';
                    break;

                  default:
                  //
                }

                const b64 = await ToBase64.fromArrayBuffer(binary);

                this.setState({
                  iframe: `data:${mimeType};base64,${b64}`,
                  preview: option,
                });

                this.props.onPreview && this.props.onPreview(
                  option,
                  `data:${mimeType};base64,${b64}`,
                );
              }}
            />
          </FlexLayout>
          {this.props.isEnabledPreview && <FlexLayout weight={1} isVertical={true}>
            <LayoutTitle>
              미리보기
            </LayoutTitle>
            {this.state.preview === PREVIEW_OPTION.IMAGE
              && <ImageView src={this.state.iframe} style={{ marginBottom: 0 }} />}
            {this.state.preview === PREVIEW_OPTION.PDF
              && <IFrame src={this.state.iframe} />}
            {this.state.preview === PREVIEW_OPTION.NONE
              && <IFrame src={this.state.iframe} />}
          </FlexLayout>}
        </FlexLayout>
      </FlexLayout>
    );
  }
}
