/**
 * 모델
 * @window w_tb_e611_gosi
 * @category 승강기조회
 */
import { computed } from 'mobx';

export class RetrieveElevatorDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 전분류
   *
   * **data type** varchar(2)
   */
  contg: string;

  /**
   * 인사코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 대수
   *
   * **data type** float
   */
  qty: string;

  /**
   * 전화번호
   *
   * **data type** varchar(20)
   */
  tel: string;

  /**
   * 팩스
   *
   * **data type** varchar(20)
   */
  fax: string;

  /**
   * 분류
   *
   * **data type** char(1)
   */
  flag: string;

  /**
   * 홈페이지
   *
   * **data type** varchar(50)
   */
  homepage: string;

  /**
   * 주소
   *
   * **data type** varchar(6)
   */
  zipcode: string;

  /**
   * 주소2
   *
   * **data type** varchar(100)
   */
  address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(100)
   */
  address2: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 보수료
   *
   * **data type** float
   */
  amt: string;

  /**
   * 대당금액
   *
   * **data type** varchar(?)
   */
  monamt: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 해지사유
   *
   * **data type** varchar(100)
   */
  clsnm: string;

  /**
   * 해지상세사유
   *
   * **data type** varchar(100)
   */
  clsremark: string;

  /**
   * 비고
   *
   * **data type** text
   */
  bigo: string;

  /**
   * 구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 계약구분
   *
   * **data type** varchar(10)
   */
  contgubun: string;

  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 전분류
   *
   * **data type** varchar(5)
   */
  becontg: string;

  /**
   * 해지일
   *
   * **data type** varchar(8)
   */
  clsrecedate: string;

  /**
   * 처리
   *
   * **data type** varchar(1)
   */
  compflag: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 담당자
   *
   * **data type** varchar(30)
   */
  pernm: number;

  /**
   * 현장담당자
   *
   * **data type** varchar(30)
   */
  actpernm: number;

  /**
   * 계약자
   *
   * **data type** varchar(30)
   */
  contpernm: number;

  /**
   * 담당전화번호
   *
   * **data type** varchar(30)
   */
  hp: number;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.contg = data.contg || '';
    this.perid = data.perid || '';
    this.qty = data.qty || '';
    this.tel = data.tel || '';
    this.fax = data.fax || '';
    this.flag = data.flag || '';
    this.homepage = data.homepage || '';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.samt = data.samt || '';
    this.amt = data.amt || '';
    this.monamt = data.monamt || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.clsnm = data.clsnm || '';
    this.clsremark = data.clsremark || '';
    this.bigo = data.bigo || '';
    this.gubun = data.gubun || '';
    this.contgubun = data.contgubun || '';
    this.seq = data.seq || '';
    this.becontg = data.becontg || '';
    this.clsrecedate = data.clsrecedate || '';
    this.compflag = data.compflag || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.actpernm = data.actpernm || '';
    this.contpernm = data.contpernm || '';
    this.hp = data.hp || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
