export default class Range {
  public static make(fromOrTo: number, to: number = 0): Array<number> {
    const lst = [];

    for (let i = to ? fromOrTo : 0; i <= (to || fromOrTo - 1); i += 1) {
      lst.push(i);
    }

    return lst;
  }
}
