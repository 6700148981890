import * as React from 'react';
import {
  FaFax,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  AttachmentBox,
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  IFrame,
  ImageView,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Diploma } from './Diploma';
import { Date8 } from '../../../../utils/time';
import style from './Diploma.module.scss';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_pb405
 * @category 공문서
 */

export const DiplomaTemplate: React.FC<TemplateProps<Diploma>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={350}>
          <span style={{ marginRight: 20 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.data?.new === '0' ? '제목, 경유, 수신, 내용으로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onEstimateModal(true)}>
          <div style={{ width: 80 }}>견적서</div>
        </Button>

        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 80 }}>문서복사</div>
        </Button>

        <Button onClick={() => scope.onSendFax()}>
          <div style={{ width: 80 }}>FAX</div>
        </Button>

        <Button onClick={() => scope.onSendEmail()}>
          <div style={{ width: 80 }}>EMAIL</div>
        </Button>
      </SearchLayout>
    </FlexLayout>

    <FlexLayout>
      <FlexLayout
        isVertical={true}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'paperdate',
              text: '일자',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.paperdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'attcnt',
              text: '첨부',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: (x.attcnt) > 0 && 'var(--color-blue)' }}
                >
                  {`${x.attcnt} 건`}
                </FlexLayout>
              ),
            },
            {
              id: 'subject',
              text: '제목',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.subject}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '작성자',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.diplomaList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
      </FlexLayout>
      <FlexLayout
        isVertical={true}
        isScroll={true}
        className={style.pageLayout}
        style={{
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >

        <FlexLayout size={100}>
          <ImageView
            src={`https://api.elmansoft.com/etc/getCustImageLong.php?database=${scope.state.custcd}&custcd=${scope.state.custcd}&spjangcd=${scope.state.spjangcd}`}
          />
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_TITLE_HEIGHT_1}
          className={style.header}
          align='center'
        >
          <span>우</span>
          <span>{model?.zipcd}</span>
          <span>{model?.adresa}</span>
          <span>{model?.adresb}</span>
          <FaFax size={15}/>
          <span>{model?.tel1}</span>
          <span>전송{model?.fax}</span>
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_TITLE_HEIGHT_1}
          style={{ backgroundColor: 'gray' }}>
        </FlexLayout>
        <FlexLayout>
          <FlexLayout>
            <table className={style.headerTable1}>
              <tbody>
                <tr>
                  <th>문서번호</th>
                  <td>
                    <FormatTextBox
                      format={`${scope.props.publicStore.user.papernum}######-####호`}
                      textAlign="left"
                      value={`${model.paperdate.substr(0, 6)}${model?.papernum}`}
                    />
                  </td>
                </tr>
                <tr>
                  <th>시행일자</th>
                  <td>
                    {
                      scope.state.data.isNew
                        ? <DateTextBox
                          value={model?.paperdate}
                          textAlign="left"
                          format="####-##-##"
                          onChange={(value) => setData({ paperdate: value })}
                        />
                        : <DateTextBox
                          value={model?.paperdate}
                          textAlign="left"
                          format="####-##-##"
                        />
                    }
                  </td>
                </tr>
                <tr>
                  <th>경유</th>
                  <td>
                    <TextBox
                      textAlign='left'
                      value={model?.gyungyu}
                      onChange={(value) => setData({ gyungyu: value })}
                    />
                  </td>
                </tr>
                <tr>
                  <th>수신</th>
                  <td>
                    <TextBox
                      textAlign='left'
                      value={model?.susin}
                      onChange={(value) => setData({ susin: value })}
                    />
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    <TextBox
                      textAlign='left'
                      value={model?.adres}
                      onChange={(value) => setData({ adres: value })}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingBottom: 25 }}>
                    참조
                  </th>
                  <td>
                    <TextBox
                      textAlign='left'
                      isMultiline={true}
                      value={model?.refer}
                      onChange={(value) => setData({ refer: value })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </FlexLayout>
          <FlexLayout>
            <table className={style.headerTable2}>
              <tbody>
              <tr>
                <th>선결</th>
                <td colSpan={2}></td>
                <th>지시</th>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <th rowSpan={2}>접수</th>
                <th>일자</th>
                <td></td>
                <th rowSpan={4}>결재 · 공람</th>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>번호</th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th colSpan={2}>처리과</th>
                <td>
                  <TextBox
                    textAlign='left'
                    value={model?.actdivinm}
                    onChange={(value) => setData({ actdivinm: value })}
                  />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th colSpan={2}>담당자</th>
                <td>
                  <TextBox
                    textAlign='left'
                    value={model?.actpernm}
                    onChange={(value) => setData({ actpernm: value })}
                  />
                </td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout
          className={style.title}
          size={Global.LAYOUT_BUTTON_HEIGHT_1}
          align='center'
        >
          <span>제목</span>
          <TextBox
            textAlign='left'
            value={model?.subject}
            onChange={(value) => setData({ subject: value })}
          />
        </FlexLayout>
        <FlexLayout
          isVertical={true}
          className={style.contents}
          size={600}
        >
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isIcon={true}
              onClick={() => scope.fileModal(true)}
              style={{ marginLeft: 5 }}
            >
              <RiFolder2Line size={17}/>
            </Button>
            <TextBox
              style={{ color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
              value={model?.attcnt === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
            />
          </FlexLayout>
          <FlexLayout>
            <TextBox
              textAlign='left'
              isMultiline={true}
              value={model?.remark}
              onChange={(value) => setData({ remark: value })}
            />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout
          size={Global.LAYOUT_GRID_HEIGHT_1}
          style={{ fontSize: 32 }}
          justify='center'
          align='center'
        >
          {scope.state.spjangnm}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>

    <ModalView
      isVisible={scope.state.fileDetailModal}
      onClose={() => scope.fileModal(false)}
      width={1300}
    >
      <FlexLayout style={{ padding: 8 }}>
        <AttachmentBox
          isEnabledPreview={true}
          appnum={scope.state.data?.appnum}
          appnm={'공문서'}
          onChangeCount={async (count) => {
            // state
            setData({ attcnt: count.toString() });
          }}
        />
      </FlexLayout>
    </ModalView>

    <ModalView
      isVisible={scope.state.estimateModal}
      onClose={() => scope.onEstimateModal(false)}
      width={1800}
    >
      <FlexLayout isVertical={true}>
        <LayoutTitle>공문서-견적서첨부내역불러오기</LayoutTitle>
        <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
          <FlexLayout size={244}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.estimateModalStdate}
              format="####-##-##"
              textAlign="center"
              onChange={(value) => scope.setState({ estimateModalStdate: value })}
              onEnter={() => scope.onEstimateModalRetrieve()}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.estimateModalStdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ estimateModalStdate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.estimateModalStdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ estimateModalStdate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
            >
            </DateTextBox>
          </FlexLayout>

          <FlexLayout size={216}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.estimateModalEnddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ estimateModalEnddate: value })}
              onEnter={() => scope.onEstimateModalRetrieve()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.estimateModalEnddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ estimateModalEnddate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.estimateModalEnddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ estimateModalEnddate: Date8.make(date) });
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout>
            <span>검색어</span>
            <TextBox
              value={scope.state.estimateModalSearchQuery}
              onChange={(value) => scope.SS({ estimateModalSearchQuery: value })}
              onEnter={() => scope.onEstimateModalRetrieve()}
            >
            </TextBox>
          </FlexLayout>

          <FlexLayout size={188}>
            <span>구분</span>
            <ComboBox
              value={scope.state.gubun}
              textAlign="center"
              data={scope.state.gubuns?.map((x: any) => new ComboBoxModel(x.artcd, x.artnm))}
              onSelect={(v) => scope.setState({ gubun: v.value }, () => scope.onEstimateModalRetrieve())}
            >
            </ComboBox>
          </FlexLayout>

          <FlexLayout size={188}>
            <span>사용유무</span>
            <ComboBox
              value={scope.state.paperflag}
              textAlign="center"
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '미사용' },
                { value: '1', remark: '사용' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(v) => scope.setState({ paperflag: v.value }, () => scope.onEstimateModalRetrieve())}
            >
            </ComboBox>
          </FlexLayout>

          <Button style={{ width: 144 }} onClick={() => scope.onEstimateModalRetrieve()}>조회</Button>
        </SearchLayout>
        <FlexLayout>
          <GridLayout
            size={500}
            ref={scope.estimateModalGrid}
            header={[
              {
                id: 'paperflag',
                text: '사용유무',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.paperflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                  >
                    {x.paperflag === '0' ? '미사용' : '사용' }
                  </FlexLayout>
                ),
              },
              {
                id: 'costdate',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>견적날짜</FlexLayout>
                    <FlexLayout>견적번호</FlexLayout>
                  </FlexLayout>
                ),
                width: 1,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.costdate)}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.costnum}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>공사구분</FlexLayout>
                    <FlexLayout>계약구분</FlexLayout>
                  </FlexLayout>
                ),
                width: 1,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.modalGubuns?.filter((y) => y.com_code === x.gubun)[0]?.com_cnam}
                    </FlexLayout>
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.modalConstGubuns?.filter((y) => y.artcd === x.contgubun)[0]?.artnm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>현장명</FlexLayout>
                    <FlexLayout>견적서명</FlexLayout>
                  </FlexLayout>
                ),
                width: 2,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.constnm}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'samt',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>공급가액</FlexLayout>
                    <FlexLayout>부가세</FlexLayout>
                  </FlexLayout>
                ),
                width: 1,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'mamt',
                text: (
                  <FlexLayout isVertical={true}>
                    <FlexLayout>합계금액</FlexLayout>
                    <FlexLayout>할인금액</FlexLayout>
                  </FlexLayout>
                ),
                width: 1,
                render: (x) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.mamt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.saleamt)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.estimateModalData}
            onRowClick={(item) => scope.estimateModalRowFocus(item)}
          />
          <FlexLayout>
            <IFrame src={scope.state.img_pic} />
          </FlexLayout>
        </FlexLayout>
        <SubHeader>
          <div>
            <span>일자</span>
            <span>
                <DateTextBox
                  format="####-##-##"
                  textAlign="center"
                  value={scope.state.modalDate}
                  onChange={(v) => scope.SS({ modalDate: v })}
                />
              </span>
          </div>
          <FlexLayout justify="end">
            <Button onClick={() => scope.estimateModalSave()}>확인</Button>
            <Button onClick={() => scope.SS({ estimateModal: false })}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
  );
};
