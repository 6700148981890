import { TableLayoutHeader } from '../../../../../constants';
import { DetailModel } from '../models';
import {
  ItemChange,
  BuyRegister,
} from '../BuyRegister';
import { TextBox } from '../../../../../components/forms/TextBox';
import { FormatNumberTextBox } from '../../../../../components/forms/FormatNumberTextBox';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../../models/common';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';

// 거래명세표
const Detail: TableLayoutHeader<DetailModel, BuyRegister>[] = [
  {
    id: 'seq',
    text: '순서',
    width: 70,
  },
  {
    id: 'pcode',
    text: '품목코드',
    width: 200,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.pcode}
        textAlign="center"
        onDoubleClick={async () => {
          // @ts-ignore
          const result = await scope.props.modalStore.openProductSelector(x.pcode);
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
          });
        }}
        onChange={(value) => rowUpdate({ pcode: value })}
        onEnter={async (value) => {
          // @ts-ignore
          const result = await scope.props.modalStore.openProductSelector(value);
          rowUpdate({
            pcode: result.phm_pcod,
            pname: result.phm_pnam,
            psize: result.phm_size,
            punit: result.phm_unit,
            uamt: result.phm_uamt || result.phm_samt,
          });
        }}
      />
    ),
  },
  {
    id: 'pname',
    text: '거래명세표-품명',
    width: 300,
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.pname}
        onChange={(value) => rowUpdate({ pname: value })}
        onEnter={(value) => scope?.onItemChange(value, ItemChange.pname, 'dw_3_itemchanged')}
      />
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.psize}
        onChange={(value) => rowUpdate({ psize: value })}
      />
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 70,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.punit}
        textAlign="center"
        onChange={(value) => rowUpdate({ punit: value })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 70,
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.qty}
        onChange={(value) => scope?.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 100,
    trail: (data) => {
      const sumUamt = Sum.all(data, 'uamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumUamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
      />
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref, scope) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.tamt}
        onChange={(value) => scope?.tabAutoCalc({ ...x, tamt: value }, rowUpdate, 'tamt')}
      />
    ),
  },
  {
    id: 'mijamt',
    text: '합계금액',
    width: 100,
    trail: (data) => {
      const sumMijamt = Sum.all(data, 'mijamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMijamt, true)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        char={','}
        charAt={3}
        textAlign="right"
        value={x.mijamt}
        onChange={(value) => rowUpdate({ mijamt: value })}
      />
    ),
  },
  {
    id: 'projno',
    text: '프로젝트',
    width: 150,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.projno}
        textAlign="center"
        onChange={(value) => rowUpdate({ projno: value })}
        bindSearchModal={new SearchBindingProjects(
          // @ts-ignore
          scope.props.modalStore,
          {},
          true,
          (item) => {
            rowUpdate({
              projno: item.projno,
              projnm: item.projnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'projnm',
    text: '프로젝트명',
    width: 300,
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.projnm}
        onChange={(value) => rowUpdate({ projnm: value })}
        onEnter={(value) => scope?.onItemChange(value, ItemChange.qty, 'dw_3_itemchanged')}
      />
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 100,
    render: (x, rowUpdate, ref, scope) => (
      <TextBox
        ref={ref}
        value={x.actcd}
        textAlign="center"
        onChange={(value) => rowUpdate({ actcd: value })}
        bindSearchModal={new SearchBinding(
          // @ts-ignore
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 300,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.actnm}
        onChange={(value) => rowUpdate({ actnm: value })}
      />
    ),
  },
  {
    id: 'bigo',
    text: '비고',
    width: 300,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.bigo}
        onChange={(value) => rowUpdate({ bigo: value })}
      />
    ),
  },
];

export default Detail;
