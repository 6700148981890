import { GridLayoutHeader } from '../../../../../constants';
import { UserTimeModel } from '../models';
import { User } from '../User';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Sum } from '../../../../../utils/array';
import { Date8, Time4 } from '../../../../../utils/time';


// 줄 정렬을 위한 함수
function pad(n: string | any[], width: number) {
  // eslint-disable-next-line no-param-reassign
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}
// 가족
const UserERP: GridLayoutHeader<UserTimeModel, User>[] = [
  {
    id: 'divinm',
    text: '부서명',
    width: 15,
    group: true,
    trail: () => (
  <FlexLayout
    justify="center"
    align="center"
  >
    총 계
  </FlexLayout>
    ),
  },
  {
    id: 'userid',
    text: 'ID',
    width: 10,
    group: true,
  },
  {
    id: 'pernm',
    text: '사용자명',
    width: 15,
    group: true,
    sum: (x) => (
  <FlexLayout
    justify="center"
    align="center"
  >
    {x.pernm}
  </FlexLayout>
    ),
  },
  {
    id: 'londtdate',
    text: '접속일자',
    width: 20,
    render: (x) => (
  <FlexLayout
    justify="center"
    align="center"
  >
    {Date8.withDash(x.londtdate)}
  </FlexLayout>
    ),
  },
  {
    id: 'erpendhour',
    text: 'ERP로그인시간',
    width: 20,
    sum: (x, data) => {
      const sumTime = Sum.timeByGroup(data, 'erpendhour', 'erpendtime', x, ['divinm', 'pernm']);
      return (
    <FlexLayout
      justify="center"
      align="center"
    >
      {Time4.withKor(sumTime)}
    </FlexLayout>
      );
    },
    trail: (_, scope) => (
    <FlexLayout
      justify="center"
      align="center"
    >
      {scope?.state?.erpendhour_tot} {scope?.state?.erpendtime_tot}
    </FlexLayout>
    ),
    render: (x) => (
  <FlexLayout
    justify="center"
    align="center"
  >
  {pad(x.erpendhour, 2)}시간
     { (Number.isNaN(parseInt(x.erpendtime, 10) % 60)
       ? pad(String(0), 2) : pad(String(parseInt(x.erpendtime, 10) % 60), 2))}분
  </FlexLayout>
    ),
  },
  {
    id: 'appendhour',
    text: '어플로그인시간',
    width: 20,
    sum: (x, data) => {
      const sumTime = Sum.timeByGroup(data, 'appendhour', 'appendtime', x, ['custcd', 'userid']);
      return (
    <FlexLayout
      justify="center"
      align="center"
    >
      {Time4.withKor(sumTime)}
    </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state?.appendhour_tot} {scope?.state?.appendtime_tot}
      </FlexLayout>
    ),
    render: (x) => (
  <FlexLayout
    justify="center"
    align="center"
  >
    {pad(x.appendhour, 2)}시간
    { (Number.isNaN(parseInt(x.appendtime, 10) % 60)
      ? pad(String(0), 2) : pad(String(parseInt(x.appendtime, 10) % 60), 2))}분
  </FlexLayout>
    ),
  },
];
export default UserERP;
