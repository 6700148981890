import * as React from 'react';
import {
  Button,
  CheckBox,
  DragAndDropLayout,
  FlexLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { EnrollmentMaterialsTableHeader } from '../headers/EnrollmentMaterialsTableHeader';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

/**
 * 화면 > 탭 - 부품내역등록
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentTabMaterialsTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
      <FlexLayout />

      <FlexLayout size={400}>
         <span style={{
           textAlign: 'center',
           marginTop: 6,
           fontSize: 12,
         }}>
           금액수동계산
        </span>
        <CheckBox
          value={scope.state.useCalc === '1'}
          onChange={(value) => scope.setState({ useCalc: value ? '1' : '0' })}
        />
        <Button
          isFull={true}
          onClick={() => scope.onGenerateButtonClick()}
        >
          호기생성
        </Button>

        <Button
          isFull={true}
          onClick={() => scope.onClickMaterialsNew()}
        >
          추가
        </Button>

        <Button
          isFull={true}
          theme={BUTTON_THEMES.RED}
          onClick={() => scope.onClickMaterialsRemove()}
        >
          삭제
        </Button>
      </FlexLayout>
    </FlexLayout>

    <FlexLayout>
      <DragAndDropLayout
        ref={scope.tableMaterials}
        header={EnrollmentMaterialsTableHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataMaterials}
        onRowFocusEvent={(item, index) => scope.onMaterialsRowFocusEvent(item, index)}
        onChange={(rows) => scope.onChangeTableMaterials(rows)}
      />
    </FlexLayout>
  </FlexLayout>
);
