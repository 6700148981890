import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button, DateTextBox, FlexLayout, GridLayout, LayoutTitle, ModalView, SearchLayout, SubHeader, TabLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { InstallTab } from './Install.tab';
import { InstallGridHeader } from './headers/InstallGridHeader';
import { Install } from './Install';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e601w_sulchi_08
 * @category 설치수주매출통계
 */
export const InstallTemplate: React.FC<TemplateProps<Install>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout>
        <GridLayout
          header={InstallGridHeader(scope)}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          onRowClick={(row, index) => scope.onRowFocusEvent(row, index)}
          onRowDoubleClick={() => scope.textModal(true, '1')}
        />
      </FlexLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={InstallTab}
          onChange={(item, index) => scope.onTabChange(item, index)}
        />
      </FlexLayout>
    </FlexLayout>

    <ModalView
      width={1200}
      height={600}
      isVisible={scope.state.textDetailModal}
      onClose={() => scope.textModal(false, '')}
      zIndex={-2}
    >
      <FlexLayout
        isVertical={true}
        style={{ padding: 10 }}
      >
        <LayoutTitle>설치수주매출통계</LayoutTitle>

        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'contdate',
              text: '계약일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Date8.withDash(x.contdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '코드',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.actcd}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 200,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'projno',
              text: '프로젝트번호',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.projno}
                </FlexLayout>
              ),
            },
            {
              id: 'contamt',
              text: '수주금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(scope.state.modal_contamt_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.contamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'contamt14',
              text: '수주(설치)',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(scope.state.modal_contamt14_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.contamt14)}
                </FlexLayout>
              ),
            },
            {
              id: 'contamt15',
              text: '수주(리모델링)',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(scope.state.modal_contamt15_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: '#7F7A2C' }}
                >
                  {Format.number(x.contamt15)}
                </FlexLayout>
              ),
            },
            {
              id: 'notmisamt',
              text: '미매출',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(scope.state.modal_notmisamt_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.notmisamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt',
              text: '설치매출',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(scope.state.modal_misamt_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt',
              text: '설치입금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(scope.state.modal_rcvamt_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.rcvamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt',
              text: '설치미수금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(scope.state.modal_iamt_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.iamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'jamt',
              text: (
                <FlexLayout
                  align="start"
                  justify="center"
                  isVertical={true}
                >
                  <FlexLayout>
                    잔액
                  </FlexLayout>

                  <FlexLayout>
                    (수주-입금)
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(scope.state.modal_jamt_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.jamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'qty',
              text: '대수',
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Format.number(scope.state.modal_qty_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty}
                </FlexLayout>
              ),
            },
            {
              id: 'qty14',
              text: (
                <FlexLayout
                  align="center"
                  justify="center"
                  isVertical={true}
                >
                  <FlexLayout>
                    대수
                  </FlexLayout>

                  <FlexLayout>
                    (설치)
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(scope.state.modal_qty14_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty14}
                </FlexLayout>
              ),
            },
            {
              id: 'qty15',
              text: (
                <FlexLayout
                  align="center"
                  justify="center"
                  isVertical={true}
                >
                  <FlexLayout>
                    대수
                  </FlexLayout>

                  <FlexLayout>
                    (리모델링)
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 70,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(scope.state.modal_qty15_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.qty15}
                </FlexLayout>
              ),
            },
            {
              id: 'misamt2',
              text: '매출',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(scope.state.modal_misamt2_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.misamt2)}
                </FlexLayout>
              ),
            },
            {
              id: 'rcvamt2',
              text: '입금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(scope.state.modal_rcvamt2_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.rcvamt2)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt2',
              text: '미수금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(scope.state.modal_iamt2_tot)}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.iamt2)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope?.state.modalData || []}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onModalExcel()}>EXCEL 자료</Button>
            <Button onClick={() => scope.textModal(false, '')}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
