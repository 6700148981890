import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Regist } from '../Regist';
import { Format } from '../../../../../utils/string';


// 설치구분
export const ScGubuns = [
  { key: '0', value: '설치종료', color: 'var(--color-red)' },
  { key: '3', value: '설치종료', color: 'var(--color-red)' },
  { key: '1', value: '설치', color: 'var(--color-green)' },
  { key: '2', value: '보수', color: 'var(--color-blue)' },
];


/**
 * 화면 > 좌측 그리드
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistGridMainHeader: GLHF<any, Regist> = (scope) => ([
  {
    id: 'state',
    text: '설치',
    width: 20,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        설치:{Format.number(scope.state.state1_tot)}건
      </FlexLayout>
    ),
    render: (x) => {
      const fp = ScGubuns.filter((y) => y.key === x.state);
      const nm = fp.length > 0 ? fp[0].value : '';
      const color = fp.length > 0 ? fp[0].color : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'actcd',
    text: '코드',
    width: 20,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        보수:{Format.number(scope.state.state2_tot)}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.actcd}
      </FlexLayout>
    ),
  },
  {
    id: 'scactnm',
    text: '설치현장명',
    width: 60,
    trail: () => (
      <FlexLayout
        justify="start"
        align="center"
      >
        합계:{Format.number(scope.state.cnt_tot)}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.scactnm || x.actnm}
      </FlexLayout>
    ),
  },
]);
