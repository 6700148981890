import update from 'react-addons-update';
import * as React from 'react';

export default class Update {
  public static byIndex(scope: React.Component, arrValName: String, index: number, updateColumn: String, updateValue: String): Promise<void> {
    return new Promise((resolve) => {
      scope.setState({
        // @ts-ignore
        [arrValName]: update(scope.state[arrValName], {
          [index]: {
            // @ts-ignore
            [updateColumn]: { $set: updateValue },
          },
        }),
      }, () => resolve());
    });
  }
}
