import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca501_age
 * @category 부품채크리스트등록
 */

export class PartsCheckListModel {
  /**
   * 채크코드
   *
   * **data type** varchar(8)
   */
  agecode: string;

  /**
   * 채크분류명
   *
   * **data type** varchar(8)
   */
  agename: string;

  /**
   * 물품코드
   *
   * **data type** varchar(8)
   */
  readonly mode: string;

  /**
   * 물품분류
   *
   * **data type** varchar(8)
   */
  readonly modenm: string;

  /**
   * 대분류 코드
   *
   * **data type** varchar(8)
   */
  readonly agroup: string;

  /**
   * 대분류
   *
   * **data type** varchar(8)
   */
  readonly agroupnm: string;

  /**
   * 중분류 코드
   *
   * **data type** varchar(8)
   */
  readonly bgroup: string;

  /**
   * 중분류
   *
   * **data type** varchar(8)
   */
  readonly bgroupnm: string;

  /**
   * 소분류 코드
   *
   * **data type** varchar(8)
   */
  readonly cgroup: string;

  /**
   * 소분류
   *
   * **data type** varchar(8)
   */
  readonly cgroupnm: string;

  /**
   * 년
   *
   * **data type** varchar(8)
   */
  age_year: string;

  /**
   * 월
   *
   * **data type** varchar(8)
   */
  age_month: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * mamt
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * samt
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * age
   *
   * **data type** varchar(8)
   */
  age: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.agecode = data.agecode || '';
    this.agename = data.agename || '';
    this.mode = data.mode || '';
    this.modenm = data.modenm || '';
    this.agroup = data.agroup || '';
    this.agroupnm = data.agroupnm || '';
    this.bgroup = data.bgroup || '';
    this.bgroupnm = data.bgroupnm || '';
    this.cgroup = data.cgroup || '';
    this.cgroupnm = data.cgroupnm || '';
    this.age_year = data.age_year || '';
    this.age_month = data.age_month || '';
    this.remark = data.remark || '';
    this.mamt = data.mamt || '';
    this.samt = data.samt || '';
    this.age = data.age || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
