import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox, ComboBoxModel, FlexLayout, FormatTextBox, SearchLayout, TableLayout, TabView, TextBox,
} from '../../../../components';
import {
  Item, ItemTabDataStateNames, ItemTabFocusedStateNames, ItemTabId, ItemTabUpdatesStateNames,
} from './Item';
import { Global, TemplateProps } from '../../../../constants';

/**
 * 화면
 * @window w_tb_da450
 * @category 설치품목등록
 */
export const ItemTemplate: React.FC<TemplateProps<Item>> = ({
  scope, update,
}) => {
  const setData = update!;
  return (<FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout isVertical={true}>
        {(scope.tabIndex === ItemTabId.G_Common
          || scope.tabIndex === ItemTabId.C_Common
          || scope.tabIndex === ItemTabId.G_Option
          || scope.tabIndex === ItemTabId.C_Option) && <FlexLayout isVertical={true}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>년도</span>
              <FormatTextBox
                value={scope.state.year}
                textAlign="center"
                format="####"
                onChange={(value) => setData({ year: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      setData({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                trail={(<button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      setData({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            {(scope.tabIndex === ItemTabId.G_Common
              || scope.tabIndex === ItemTabId.C_Common)
            && <FlexLayout>
              <span>제어반</span>
              <ComboBox
                value={scope.state.jeoban}
                style={{
                  padding: '0 0 0 6px', fontSize: 12,
                }}
                data={[{ value: '%', remark: '전체' }, { value: '01', remark: 'MR' }, {
                  value: '02',
                  remark: 'MRL',
                }].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ jeoban: option.value })}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>}

            <FlexLayout>
              <span>인승</span>
              <ComboBox
                value={scope.state.nperson}
                style={{
                  padding: '0 0 0 6px', fontSize: 12,
                }}
                data={[{ value: '%', remark: '전체' }, { value: 'P06', remark: '6인승' }, {
                  value: 'P08',
                  remark: '8인승',
                }, { value: 'P09', remark: '9인승' }, { value: 'P10', remark: '10인승' }, {
                  value: 'P11',
                  remark: '11인승',
                }, { value: 'P13', remark: '13인승' }, { value: 'P15', remark: '15인승' }, {
                  value: 'P17',
                  remark: '17인승',
                }, { value: 'P20', remark: '20인승' }, {
                  value: 'P24',
                  remark: '24인승',
                }].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ nperson: option.value })}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            {(scope.tabIndex === ItemTabId.G_Common
              || scope.tabIndex === ItemTabId.C_Common)
            && <FlexLayout>
              <span>두께</span>
              <ComboBox
                value={scope.state.thickness}
                style={{
                  padding: '0 0 0 6px', fontSize: 12,
                }}
                data={[{ value: '%', remark: '전체' }, { value: '01', remark: '304-1.5T' }, {
                  value: '02',
                  remark: '334-1.2T',
                }].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ thickness: option.value })}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>}

            <FlexLayout weight={3}>
              <span>검색어</span>
              <TextBox
                value={scope.state.searchQuery}
                onChange={(value) => setData({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout weight={5}>
            <FlexLayout>
              <TableLayout
                ref={scope.table}
                header={scope.tabHeaders[scope.tabIndex]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={scope.infinity}
                // @ts-ignore
                data={scope.state[ItemTabDataStateNames[scope.tabIndex]]}
                onRowFocusEvent={(item) => {
                  setData({
                    [ItemTabFocusedStateNames[scope.tabIndex]]: item,
                  }, () => {
                  });
                }}
                onChange={(rows, updatedRows) => setData({
                  [ItemTabDataStateNames[scope.tabIndex]]: rows,
                  [ItemTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
                })}
              />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>}
        {(scope.tabIndex === ItemTabId.G_Item || scope.tabIndex === ItemTabId.C_Item) && <FlexLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.table}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity}
              // @ts-ignore
              data={scope.state[ItemTabDataStateNames[scope.tabIndex]]}
              onRowFocusEvent={(item) => {
                setData({
                  [ItemTabFocusedStateNames[scope.tabIndex]]: item,
                }, () => {
                });
              }}
              onChange={(rows, updatedRows) => setData({
                [ItemTabDataStateNames[scope.tabIndex]]: rows, [ItemTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
              })}
            />
          </FlexLayout>
        </FlexLayout>}
      </FlexLayout>
    </FlexLayout>);
};
