import { FaCodeBranch } from 'react-icons/all';
import { TabLayoutDataItem } from '../../../../constants';
import { Result } from './Result';
import { TabModel } from '../../../../models';
import { ResultTabTemplate } from './tabs/ResultTab.template';

/**
 * 화면 > 탭
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export const ResultTab: Array<TabLayoutDataItem<Result>> = [
  {
    tab: new TabModel('COMPER', FaCodeBranch, '주공사자별'),
    template: (scope) => <ResultTabTemplate scope={scope} />,
  },
];
