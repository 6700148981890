import { computed } from 'mobx';

/**
 * 모델
 * 승강기이력카드[제품]
 * @window w_tb_e611_type
 * @category 승강기제원정보[이력카드]
 */
export class ProductModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발생일자
   *
   * **data type** varchar(8)
   */
  compdate: string;

  /**
   * 번호
   *
   * **data type** varchar(?)
   */
  compnum: string;

  /**
   * 구분
   *
   * **data type** varchar(2)
   */
  gubun: string;

  /**
   * 공사명
   *
   * **data type** varchar(100)
   */
  offinm: string;

  /**
   * 품목명
   *
   * **data type** varchar(100)
   */
  pname: string;

  /**
   * 규격
   *
   * **data type** varchar(100)
   */
  psize: string;

  /**
   * 품목시리얼번호
   *
   * **data type** varchar(100)
   */
  pserial: string;

  /**
   * 입고일자
   *
   * **data type** varchar(8)
   */
  ibgdate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  ibgnum: string;

  /**
   * 출고일자
   *
   * **data type** varchar(8)
   */
  deldate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  delnum: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  samt: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  tamt: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  compamt: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  compyn: string;

  /**
   * 처리자
   *
   * **data type** varchar(8)
   */
  comppernm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.gubun = data.gubun || '';
    this.offinm = data.offinm || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.pserial = data.pserial || '';
    this.ibgdate = data.ibgdate || '';
    this.ibgnum = data.ibgnum || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.compamt = data.compamt || '';
    this.compyn = data.compyn || '';
    this.comppernm = data.comppernm || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
