/**
 * 모델
 * @window w_tb_e451
 * @category 견적서등록
 */
export default class EnrollmentHistoryModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(8)
   */
  readonly addyn: string;

  /**
   * 견적명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.addyn = data.addyn || '';
    this.constnm = data.constnm || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.gubun = data.gubun || '';
    this.iamt = data.iamt || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
  }
}
