import { computed } from 'mobx';

/**
 * 모델2
 * @window w_tb_ca642_01_all
 * @category 지급등록
 */
export class GiveDetailModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 지급일자
   *
   * **data type** char(8)
   */
  snddate: string;


  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  sndnum: string;

  /**
   * 지급액
   *
   * **data type** number
   */
  amt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.snddate = data.snddate || '';
    this.sndnum = data.sndnum || '';
    this.amt = data.amt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
