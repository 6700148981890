import { ObservableHistory } from 'mobx-observable-history';
import ActionStore from './ActionStore';
import HeaderTabStore from './HeaderTabStore';
import MenuStore from './MenuStore';
import ModalStore from './ModalStore';
import ToolStore from './ToolStore';
import PublicStore from './PublicStore';
import RouterStore from './RouterStore';
import WaitQueueStore from './WaitQueueStore';
import WaitAsyncQueueStore from './WaitAsyncQueueStore';
import FavoriteMenuStore from './FavoriteMenuStore';

// eslint-disable-next-line import/prefer-default-export
export default function createStores(history: ObservableHistory) {
  const routerStore = new RouterStore(history);
  const menuStore = new MenuStore();
  const headerTabStore = new HeaderTabStore(routerStore);
  const waitQueueStore = new WaitQueueStore();
  const waitAsyncQueueStore = new WaitAsyncQueueStore();
  const publicStore = new PublicStore(headerTabStore, routerStore, waitQueueStore, waitAsyncQueueStore, menuStore);
  const actionStore = new ActionStore(publicStore, waitQueueStore);
  const modalStore = new ModalStore(publicStore, actionStore);
  const toolStore = new ToolStore(publicStore, routerStore, modalStore);
  const favoriteMenuStore = new FavoriteMenuStore(publicStore, actionStore);

  /**
   * 의존성 주입
   */
  headerTabStore.setPublicStore(publicStore);
  actionStore.setModalStore(modalStore);
  waitQueueStore.setToolStore(toolStore);
  waitQueueStore.setModalStore(modalStore);
  publicStore.setModalStore(modalStore);
  publicStore.setActionStore(actionStore);
  publicStore.setToolStore(toolStore);

  return {
    actionStore,
    headerTabStore,
    menuStore,
    modalStore,
    publicStore,
    routerStore,
    toolStore,
    waitQueueStore,
    favoriteMenuStore,
  };
}
