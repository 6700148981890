import { action } from 'mobx';
import React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PurchasingStatisticsTemplate } from './PurchasingStatistics.template';
import { PurchasingStatisticsModel } from './models/PurchasingStatistics.model';
import {
  Today,
} from '../../../../utils/time';
import {
  InfinityRetrieve,
  TabModel,
} from '../../../../models';
import { GridLayout } from '../../../../components';
import { TenYearModel } from './models/TenYear.model';
import { ActcdModel } from './models/Actcd.model';
import { MonthModel } from './models/Month.model';
import { ModalDataModel } from './models/ModalData.model';
import { ConfirmWarning } from '../../../../utils/confirm';

export enum titleNames {
  bosu = '0',
  sulchi = '1',
  jejo = '2',
  etc = '5',
  tot = '%',
}

interface PurchasingStatisticsState {
  // 처음화면 열 때 rowfocus2번 방지
  firstFlag: boolean;

  // Search
  year: string;
  searchQuery: string;
  popupSearchQuery: string;
  mijchk: string;

  // GridHeader
  data: Array<PurchasingStatisticsModel>;
  dataTenYear: Array<TenYearModel>;
  dataMonth: Array<MonthModel>;
  dataActcd: Array<ActcdModel>;

  // ModalData
  modalData: Array<ModalDataModel>;

  // 상단 Grid focus
  focused?: PurchasingStatisticsModel;

  // Grid Trail
  mijamt_0_tot: string;
  sndamt_0_tot: string;
  iamt_0_tot: string;
  mijamt_1_tot: string;
  sndamt_1_tot: string;
  iamt_1_tot: string;
  mijamt_2_tot: string;
  sndamt_2_tot: string;
  iamt_2_tot: string;
  mijamt_5_tot: string;
  iamt_5_tot: string;
  sndamt_5_tot: string;
  mijamt_tot_tot: string;
  sndamt_tot_tot: string;
  iamt_tot_tot: string;

  // Tab  Trail
  tab_mijamt_0_tot: string;
  tab_sndamt_0_tot: string;
  tab_iamt_0_tot: string;
  tab_mijamt_1_tot: string;
  tab_sndamt_1_tot: string;
  tab_iamt_1_tot: string;
  tab_mijamt_2_tot: string;
  tab_sndamt_2_tot: string;
  tab_iamt_2_tot: string;
  tab_mijamt_5_tot: string;
  tab_iamt_5_tot: string;
  tab_sndamt_5_tot: string;
  tab_mijamt_tot_tot: string;
  tab_sndamt_tot_tot: string;
  tab_iamt_tot_tot: string;

  // Tab3 Trail
  tab_mijamt01_tot: string;
  tab_sndamt01_tot: string;
  tab_iamt01_tot: string;
  tab_mijamt02_tot: string;
  tab_sndamt02_tot: string;
  tab_iamt02_tot: string;
  tab_mijamt03_tot: string;
  tab_sndamt03_tot: string;
  tab_iamt03_tot: string;
  tab_mijamt04_tot: string;
  tab_sndamt04_tot: string;
  tab_iamt04_tot: string;
  tab_mijamt05_tot: string;
  tab_sndamt05_tot: string;
  tab_iamt05_tot: string;
  tab_mijamt06_tot: string;
  tab_sndamt06_tot: string;
  tab_iamt06_tot: string;
  tab_mijamt07_tot: string;
  tab_sndamt07_tot: string;
  tab_iamt07_tot: string;
  tab_mijamt08_tot: string;
  tab_sndamt08_tot: string;
  tab_iamt08_tot: string;
  tab_mijamt09_tot: string;
  tab_sndamt09_tot: string;
  tab_iamt09_tot: string;
  tab_mijamt10_tot: string;
  tab_sndamt10_tot: string;
  tab_iamt10_tot: string;
  tab_mijamt11_tot: string;
  tab_sndamt11_tot: string;
  tab_iamt11_tot: string;
  tab_mijamt12_tot: string;
  tab_sndamt12_tot: string;
  tab_iamt12_tot: string;

  // Modal
  modal: boolean,
  tabNames: string;
  modalTitles: string;
  modalTopTitles: string; // 모달 상단 이름
  mons: string;
  wkgubuns: string;

  // Modal trail
  cnt_tot: string;
  iamt_tot: string;
  mijamt_tot: string;
  sndamt_tot: string;

  // 증빙구분
  gubuns: Array<any>;

  // 거래처별탭 검색
  tab3Search: string;

  // 엑셀 팝업 구분
  excelPopupGubun: string;
  excelModalVisible: boolean;

  dateflag: string | number,
  beamt_tot: string,
}

/**
 * 컨트롤러
 * @window w_tb_ca642w_14
 * @category 매입통계내역
 */
export class PurchasingStatistics extends PageComponent<PageProps, PurchasingStatisticsState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  modalInfinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  gridActcd: React.RefObject<GridLayout> = React.createRef();

  gridMonth: React.RefObject<GridLayout> = React.createRef();

  gridTenYear: React.RefObject<GridLayout> = React.createRef();

  modalGrid: React.RefObject<GridLayout> = React.createRef();

  tabIndex: number = 0;

  tab3FocusIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      firstFlag: true,
      year: Today.year(),
      searchQuery: '',
      popupSearchQuery: '',

      // data
      data: [],
      dataTenYear: [],
      dataMonth: [],
      dataActcd: [],

      // Modal Data
      modalData: [],

      focused: [],

      // Trail
      mijamt_0_tot: '0',
      sndamt_0_tot: '0',
      iamt_0_tot: '0',
      mijamt_1_tot: '0',
      sndamt_1_tot: '0',
      iamt_1_tot: '0',
      mijamt_2_tot: '0',
      sndamt_2_tot: '0',
      iamt_2_tot: '0',
      mijamt_5_tot: '0',
      iamt_5_tot: '0',
      sndamt_5_tot: '0',
      mijamt_tot_tot: '0',
      sndamt_tot_tot: '0',
      iamt_tot_tot: '0',

      // Tab Trail
      tab_mijamt_0_tot: '0',
      tab_sndamt_0_tot: '0',
      tab_iamt_0_tot: '0',
      tab_mijamt_1_tot: '0',
      tab_sndamt_1_tot: '0',
      tab_iamt_1_tot: '0',
      tab_mijamt_2_tot: '0',
      tab_sndamt_2_tot: '0',
      tab_iamt_2_tot: '0',
      tab_mijamt_5_tot: '0',
      tab_iamt_5_tot: '0',
      tab_sndamt_5_tot: '0',
      tab_mijamt_tot_tot: '0',
      tab_sndamt_tot_tot: '0',
      tab_iamt_tot_tot: '0',

      // Tab3 Trail
      tab_mijamt01_tot: '0',
      tab_sndamt01_tot: '0',
      tab_iamt01_tot: '0',
      tab_mijamt02_tot: '0',
      tab_sndamt02_tot: '0',
      tab_iamt02_tot: '0',
      tab_mijamt03_tot: '0',
      tab_sndamt03_tot: '0',
      tab_iamt03_tot: '0',
      tab_mijamt04_tot: '0',
      tab_sndamt04_tot: '0',
      tab_iamt04_tot: '0',
      tab_mijamt05_tot: '0',
      tab_sndamt05_tot: '0',
      tab_iamt05_tot: '0',
      tab_mijamt06_tot: '0',
      tab_sndamt06_tot: '0',
      tab_iamt06_tot: '0',
      tab_mijamt07_tot: '0',
      tab_sndamt07_tot: '0',
      tab_iamt07_tot: '0',
      tab_mijamt08_tot: '0',
      tab_sndamt08_tot: '0',
      tab_iamt08_tot: '0',
      tab_mijamt09_tot: '0',
      tab_sndamt09_tot: '0',
      tab_iamt09_tot: '0',
      tab_mijamt10_tot: '0',
      tab_sndamt10_tot: '0',
      tab_iamt10_tot: '0',
      tab_mijamt11_tot: '0',
      tab_sndamt11_tot: '0',
      tab_iamt11_tot: '0',
      tab_mijamt12_tot: '0',
      tab_sndamt12_tot: '0',
      tab_iamt12_tot: '0',
      beamt_tot: '0',

      // Modal
      modal: false,
      modalTitles: '',
      modalTopTitles: '',
      wkgubuns: '',
      mijchk: '%',
      mons: '',

      // Modal Trail
      cnt_tot: '0',
      iamt_tot: '0',
      mijamt_tot: '0',
      sndamt_tot: '0',

      gubuns: [],

      // tab3 search
      tab3Search: '',

      // excel 팝업 구분
      excelPopupGubun: '1',
      excelModalVisible: false,

      dateflag: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 증빙 구분
    const data = await api.dropdown('wf_dd_ca510_113_01');
    await this.SS({ gubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year || '',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new PurchasingStatisticsModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();

        if (this.state.data && this.state.data?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    this.setState({
      data: [],
    });

    await this.infinity?.retrieveAll();
    await this.SS({
      mijamt_0_tot: this.infinity?.box?.mijamt_0_tot || '0',
      sndamt_0_tot: this.infinity?.box?.sndamt_0_tot || '0',
      iamt_0_tot: this.infinity?.box?.iamt_0_tot || '0',
      mijamt_1_tot: this.infinity?.box?.mijamt_1_tot || '0',
      sndamt_1_tot: this.infinity?.box?.sndamt_1_tot || '0',
      iamt_1_tot: this.infinity?.box?.iamt_1_tot || '0',
      mijamt_2_tot: this.infinity?.box?.mijamt_2_tot || '0',
      sndamt_2_tot: this.infinity?.box?.sndamt_2_tot || '0',
      iamt_2_tot: this.infinity?.box?.iamt_2_tot || '0',
      mijamt_5_tot: this.infinity?.box?.mijamt_5_tot || '0',
      sndamt_5_tot: this.infinity?.box?.sndamt_5_tot || '0',
      iamt_5_tot: this.infinity?.box?.iamt_5_tot || '0',
      mijamt_tot_tot: this.infinity?.box?.mijamt_tot_tot || '0',
      sndamt_tot_tot: this.infinity?.box?.sndamt_tot_tot || '0',
      iamt_tot_tot: this.infinity?.box?.iamt_tot_tot || '0',
    });

    if (this.state.data && this.state.data?.length > 0) {
      this.grid.current?.setFocus(0);
    }

    this.grid.current?.forceRepaint();
  }

  @action
  repaintGrid() {
    switch (this.tabIndex) {
      case 1:
        this.gridMonth.current?.forceRepaint(true);
        break;

      case 2:
        this.gridActcd.current?.forceRepaint(true);
        break;

      default:
        this.gridTenYear.current?.forceRepaint(true);
        break;
    }
  }

  @action
  async onRowFocusEvenet(item: PurchasingStatisticsModel | undefined) {
    const { actionStore: api } = this.props;

    this.setState({
      focused: item,
    });

    let data: any = [];

    if (this.tabIndex === 2) {
      data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_list_RowFocuschanged`, {
        year: this.state.year || '',
        spjangcd: item?.spjangcd || '',
        as_nm: this.state.searchQuery || '',
        dateflag: this.state.dateflag || '',
      });
    } else {
      data = await api.fxExec(`tab_${this.tabIndex + 1}_dw_list_RowFocuschanged`, {
        year: this.state.year || '',
        spjangcd: item?.spjangcd || '',
        as_nm: this.state.searchQuery || '',
      });
    }

    if (this.tabIndex === 0) {
      await this.SS({ dataTenYear: data?.items || [] });
      this.gridTenYear.current?.setFocus(0);
    } else if (this.tabIndex === 1) {
      await this.SS({ dataMonth: data?.items || [] });
      this.gridMonth.current?.setFocus(0);
    } else if (this.tabIndex === 2) {
      await this.SS({ dataActcd: data?.items || [] });
      this.gridActcd.current?.setFocus(0);
    }

    await this.SS({
      tab_mijamt_0_tot: data?.mijamt_0_tot || '0',
      tab_sndamt_0_tot: data?.sndamt_0_tot || '0',
      tab_iamt_0_tot: data?.iamt_0_tot || '0',
      tab_mijamt_1_tot: data?.mijamt_1_tot || '0',
      tab_sndamt_1_tot: data?.sndamt_1_tot || '0',
      tab_iamt_1_tot: data?.iamt_1_tot || '0',
      tab_mijamt_2_tot: data?.mijamt_2_tot || '0',
      tab_sndamt_2_tot: data?.sndamt_2_tot || '0',
      tab_iamt_2_tot: data?.iamt_2_tot || '0',
      tab_mijamt_5_tot: data?.mijamt_5_tot || '0',
      tab_sndamt_5_tot: data?.sndamt_5_tot || '0',
      tab_iamt_5_tot: data?.iamt_5_tot || '0',
      tab_mijamt_tot_tot: data?.mijamt_tot_tot || '0',
      tab_sndamt_tot_tot: data?.sndamt_tot_tot || '0',
      tab_iamt_tot_tot: data?.iamt_tot_tot || '0',
      tab_mijamt01_tot: data?.mijamt01_tot || '0',
      tab_sndamt01_tot: data?.sndamt01_tot || '0',
      tab_iamt01_tot: data?.iamt01_tot || '0',
      tab_mijamt02_tot: data?.mijamt02_tot || '0',
      tab_sndamt02_tot: data?.sndamt02_tot || '0',
      tab_iamt02_tot: data?.iamt02_tot || '0',
      tab_mijamt03_tot: data?.mijamt03_tot || '0',
      tab_sndamt03_tot: data?.sndamt03_tot || '0',
      tab_iamt03_tot: data?.iamt03_tot || '0',
      tab_mijamt04_tot: data?.mijamt04_tot || '0',
      tab_sndamt04_tot: data?.sndamt04_tot || '0',
      tab_iamt04_tot: data?.iamt04_tot || '0',
      tab_mijamt05_tot: data?.mijamt05_tot || '0',
      tab_sndamt05_tot: data?.sndamt05_tot || '0',
      tab_iamt05_tot: data?.iamt05_tot || '0',
      tab_mijamt06_tot: data?.mijamt06_tot || '0',
      tab_sndamt06_tot: data?.sndamt06_tot || '0',
      tab_iamt06_tot: data?.iamt06_tot || '0',
      tab_mijamt07_tot: data?.mijamt07_tot || '0',
      tab_sndamt07_tot: data?.sndamt07_tot || '0',
      tab_iamt07_tot: data?.iamt07_tot || '0',
      tab_mijamt08_tot: data?.mijamt08_tot || '0',
      tab_sndamt08_tot: data?.sndamt08_tot || '0',
      tab_iamt08_tot: data?.iamt08_tot || '0',
      tab_mijamt09_tot: data?.mijamt09_tot || '0',
      tab_sndamt09_tot: data?.sndamt09_tot || '0',
      tab_iamt09_tot: data?.iamt09_tot || '0',
      tab_mijamt10_tot: data?.mijamt10_tot || '0',
      tab_sndamt10_tot: data?.sndamt10_tot || '0',
      tab_iamt10_tot: data?.iamt10_tot || '0',
      tab_mijamt11_tot: data?.mijamt11_tot || '0',
      tab_sndamt11_tot: data?.sndamt11_tot || '0',
      tab_iamt11_tot: data?.iamt11_tot || '0',
      tab_mijamt12_tot: data?.mijamt12_tot || '0',
      tab_sndamt12_tot: data?.sndamt12_tot || '0',
      tab_iamt12_tot: data?.iamt12_tot || '0',
      beamt_tot: data?.beamt_tot || '0',
    });
    this.repaintGrid();
  }

  @action
  async initTopGridMons() {
    await this.SS({
      mons: this.state.year,
      tabNames: 'year',
    });
  }

  @action
  async onTabChange(_: TabModel, index: number) {
    this.tabIndex = index;

    if (this.state.firstFlag) {
      await this.SS({ firstFlag: false });
    } else {
      await this.onRowFocusEvenet(this.state.focused);
    }
  }

  @action
  async openModal(isOpen: boolean) {
    await this.SS({ modal: isOpen });
    await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ modalData: [] });

    this.modalInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_ca642w_14',
        tab: this.state.tabNames,
        spjangcd: this.state.focused?.spjangcd,
        mon: this.state.mons,
        title: this.state.modalTitles,
        as_nm: this.state.popupSearchQuery,
        mijchk: this.state.mijchk,
        wkgubun: this.state.wkgubuns,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          modalData: [
            ...this.state.modalData,
            ...items.map((item) => new ModalDataModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          modalData: [],
        });
        await this.modalInfinity?.retrieveAll();
      },
    );

    await this.modalInfinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.modalInfinity?.box?.cnt_tot || '0',
      iamt_tot: this.modalInfinity?.box?.iamt_tot || '0',
      mijamt_tot: this.modalInfinity?.box?.mijamt_tot || '0',
      sndamt_tot: this.modalInfinity?.box?.sndamt_tot || '0',
    });

    this.modalGrid.current?.setFocus(0);
    await this.modalGrid.current?.forceRepaint();
  }

  @action
  async closeModal(isOpen: boolean) {
    await this.SS({
      modal: isOpen,
      popupSearchQuery: '',
      modalTitles: '',
      modalTopTitles: '',
      mijchk: '%',
    });
  }

  @action
  async tab3SearchFocus() {
    // 검색어 찾아서 focus
    // 이전 focus한 다음의 검색어 focus
    // 검색어 다찾으면 다시 처음으로 focus
    if (this.state.tab3Search.trim() === '') return;

    for (let i = this.tab3FocusIndex; i < this.state.dataActcd.length; i += 1) {
      if (this.state.dataActcd[i].cltnm.includes(this.state.tab3Search)) {
        this.tab3FocusIndex = i + 1;
        this.gridActcd.current?.setFocus(this.tab3FocusIndex - 1);
        break;
      }

      if (i === this.state.dataActcd.length - 1) {
        this.tab3FocusIndex = 0;
        this.gridActcd.current?.setFocus(0);
        this.SS({
          tab3Search: '',
        });
        ConfirmWarning.show('확인', '더이상 찾는 검색어가 없습니다!');
      }
    }
  }

  @action
  async onExcelEvent() {
    // 엑셀 프린트 타입 선택 모달창 open
    await this.SS({
      excelModalVisible: true,
    });
  }

  @action
  async tryExcelEvent() {
    // 월별 매입지금내역 탭 excel
    // 엑셀 프린트 타입 선택 시 excel 호출
    const { actionStore: api } = this.props;
    await api.excel({
      spjangcd: this.state.focused?.spjangcd,
      year: this.state.year,
      gubun: this.state.excelPopupGubun,
      as_nm: this.state.searchQuery,
      dateflag: this.state.dateflag,
    });
  }

  @action
  async onPopupExcelEvent() {
    const { actionStore: api } = this.props;

    await api.excel({
      sub: 'w_popup_ca642w_14',
      tab: this.state.tabNames,
      spjangcd: this.state.focused?.spjangcd,
      mon: this.state.mons,
      title: this.state.modalTitles,
      as_nm: this.state.popupSearchQuery,
      mijchk: this.state.mijchk,
      wkgubun: this.state.wkgubuns,
    });
  }

  render() {
    return (
      <PurchasingStatisticsTemplate
        scope={this}
      >
      </PurchasingStatisticsTemplate>
    );
  }
}
