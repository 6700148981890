import { action } from 'mobx';
import React, { RefObject } from 'react';
import { FunctionRepository } from '../../../../repositories';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { TextOftenTemplate } from './TextOften.template';
import { TextOftenModel } from './TextOften.model';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import { Finder } from '../../../../utils/array';

interface TextOftenState {
  searchQuery: string;
  textOftenList: Array<TextOftenModel>;
  focusedTextOften?: TextOftenModel;
  data: TextOftenModel;
  textOftenModal: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_sms_repeat
 * @category 문자자주쓰는문구
 */
export class TextOften extends PageComponent<PageProps, TextOftenState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  gridIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      data: new TextOftenModel(),
      textOftenList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    this.gridIndex = 0;

    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          textOftenList: [...this.state.textOftenList, ...items.map((x) => new TextOftenModel(x))],
        }, next);
      },
      async () => {
        await this.SS({
          textOftenList: [],
        });

        await this.infinity?.retrieveAll();

        if (this.state.data.seq) {
          this.gridIndex = Finder.index(this.state.textOftenList, this.state.data, 'seq');
        }

        if (this.state.textOftenList.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      textOftenList: [],
    });
    if (!autoLoad) return;
    const index = await this.infinity?.retrieveTo('seq', this.state.focusedTextOften?.seq, type, true) || 0;
    this.state.textOftenList.length > index && this.grid.current?.setFocus(index);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;

    const data = new TextOftenModel({
      ...await api.new({
        seq: this.state.data.seq,
      }),
      modalCheck: '1',
    }, true);
    data && this.setState({
      data,
      textOftenList: [
        data,
        ...this.state.textOftenList,
      ],
    }, () => {
      this.grid.current?.setFocus(0);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    await api.save(this.state.data, false);

    this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `순 번: ${this.state.focusedTextOften?.seq} 문구제목: ${this.state.focusedTextOften?.title}`;
    await api.delete(text, this.state.focusedTextOften) && this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onRowFocusEvent(item: TextOftenModel, index: number) {
    this.gridIndex = index;

    if (item.isNew) {
      return;
    }

    this.setState({
      focusedTextOften: item,
    }, async () => {
      const { publicStore } = this.props;
      const data = await publicStore.request(async () => FunctionRepository.exec(
        'general',
        'dw_1_RowFocuschanged',
        await publicStore.makeParams(item),
      ));
      data && this.setState({ data: new TextOftenModel(data) });
    });
  }

  render() {
    return (
      <TextOftenTemplate
        scope={this}
        update={(change) => this.setState({
          data: new TextOftenModel({
            ...this.state.data,
            ...change,
          }, this.state.data.isNew),
        })}
      />
    );
  }
}
