import * as React from 'react';
import { action, computed } from 'mobx';
import {
  MdThumbUp,
  MdThumbDown,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { TrueModel, FalseModel } from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { SelfCertificateTemplate } from './SelfCertificate.template';
import { InfinityRetrieve, SpjangItem } from '../../../../models/common';
import { TabHeaderTrue, TabHeaderFalse } from './tabs';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';


export enum SelfCertificateTabId {
  True,
  False,
}

export const SelfCertificateTabTitles = [
  '자격증소지자',
  '자격증미소지자',
];

export const SelfCertificateTabModels = [
  TrueModel,
  FalseModel,
];

export const SelfCertificateTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const SelfCertificateTabDataStateNames = [
  'Trues',
  'Falses',
];

export const SelfCertificateTabUpdatesStateNames = [
  'TrueUpdates',
  'FalseUpdates',
];

export const SelfCertificateTabFocusedStateNames = [
  'TrueUpdatesFocused',
  'FalseUpdatesFocused',
];

export interface SelfCertificateState {
  // 자격증소지자
  Trues?: Array<TrueModel>;
  TrueUpdates?: Array<TrueModel>;
  TruesFocused?: TrueModel;

  // 자격증미소지자
  Falses?: Array<FalseModel>;
  FalseUpdates?: Array<FalseModel>;
  FalsesFocused?: FalseModel;

  focusedTab?: TabModel;
  stmon?: string;
  searchQuery?: string;
  spjangcd?: string;
  checkflag?: string;
  spjangcds?: Array<SpjangItem>;
}

/**
 * 컨트롤러
 * @window w_pa108w
 * @category 자체점검자격현황
 */
export class SelfCertificate extends PageComponent<PageProps, SelfCertificateState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const id = SelfCertificateTabId;
    const titles = SelfCertificateTabTitles;

    this.tabs = [
      new TabModel(id.True.toString(), MdThumbUp, titles[id.True]),
      new TabModel(id.False.toString(), MdThumbDown, titles[id.False]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      spjangcd: '%',
      stmon: '',
      checkflag: '1',
      searchQuery: '',
      data: [],
    };

    this.grid = React.createRef();

    this.tabHeaders = [
      // 자격증소지자
      TabHeaderTrue,

      // 자격증미소지자
      TabHeaderFalse,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 사업장 리스트 가져오기
    const data = await api.dropdown('wf_dd_spjangcd_02');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ spjangcds: data.items });

    await this.onTabChange(this.tabs[SelfCertificateTabId.True]);
  }

  @action
  async onRetrieveEvent() {
    const i = this.tabIndex;
    await this.doRetrieve(i);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    if (this.state.focusedTab) {
      this.setState({
        [SelfCertificateTabUpdatesStateNames[i]]: [],
        [SelfCertificateTabFocusedStateNames[i]]: undefined,
      });
    }

    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async doRetrieve(i: SelfCertificateTabId) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        checkflag: this.state.checkflag,
        as_nm: this.state.searchQuery,
      },
      (params) => api.fxExec(
        `${SelfCertificateTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items) => {
        if (items) {
          this.setState({
            [SelfCertificateTabDataStateNames[i]]:
            // @ts-ignore
              [...this.state[SelfCertificateTabDataStateNames[i]],
                ...items.map((x: any) => new SelfCertificateTabModels[i](x))],
          });
        }
      },
      async () => {
        await this.SS({
          [SelfCertificateTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      [SelfCertificateTabDataStateNames[i]]: [],
      [SelfCertificateTabUpdatesStateNames[i]]: [],
      [SelfCertificateTabFocusedStateNames[i]]: undefined,
    }, async () => {
      await this.infinity?.retrieveAll();
      this.grid.current?.setFocus(0);
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[SelfCertificateTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxPrint(
      `${SelfCertificateTabFunctionNames[i]}_print`,
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        checkflag: this.state.checkflag,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    // @ts-ignore
    if (!ConfirmWarning.assert(this.state[SelfCertificateTabDataStateNames[this.tabIndex]].length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    await api.fxExcel(
      `${SelfCertificateTabFunctionNames[i]}_excel`,
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.stmon,
        checkflag: this.state.checkflag,
        as_nm: this.state.searchQuery,
      },
    );
  }

  @computed
  get tabIndex(): SelfCertificateTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.setState({
      [SelfCertificateTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [SelfCertificateTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  render() {
    return (
      <SelfCertificateTemplate
        scope={this}
        update={(state) => this.setState(state)}
      />
    );
  }
}
