import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  OptionBox,
  FormatTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { PositionCode } from './PositionCode';


/**
 * 화면
 * @window w_p1110
 * @category 직위코드
 */
export const PositionCodeTemplate: React.FC<TemplateProps<PositionCode>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'rspcd',
          text: '직위코드',
          width: 5,
        },
        {
          id: 'rspnm',
          text: '직위명',
          width: 14,
          render: (x, rowUpdate, ref) => (
            <TextBox ref={ref} textAlign="center" value={x.rspnm} onChange={(value) => rowUpdate({ rspnm: value })} />
          ),
        },
        {
          id: 'applyn',
          text: '적용여부',
          width: 10,
          render: (x, rowUpdate) => (
            <OptionBox
              value={x.applyn}
              style={{
                // eslint-disable-next-line no-nested-ternary
                color: x.applyn === '1' ? 'var(--color-blue)' : x.applyn === '0' ? 'var(--color-red)' : 'inherit',
              }}
              data={[
                { value: '1', remark: '여' },
                { value: '0', remark: '부' },
              ]}
              onChange={(v) => rowUpdate({ applyn: v.value })}
            />
          ),
        },
        {
          id: 'sdate',
          text: '적용일',
          width: 8,
          render: (x, rowUpdate, ref) => (
            <FormatTextBox
              ref={ref}
              textAlign="center"
              format="####-##-##"
              value={x.sdate}
              onChange={(value) => rowUpdate({ sdate: value })}
            />
          ),
        },
        {
          id: 'endate',
          text: '만료일',
          width: 8,
          render: (x, rowUpdate, ref) => (
            <FormatTextBox
              ref={ref}
              textAlign="center"
              format="####-##-##"
              value={x.endate}
              onChange={(value) => rowUpdate({ endate: value })}
            />
          ),
        },
        {
          id: 'preyn',
          text: '대표이사',
          width: 10,
          render: (x, rowUpdate) => (
            <OptionBox
              value={x.preyn}
              data={[
                { value: '1', remark: '여' },
                { value: '0', remark: '부' },
              ]}
              onChange={(v) => rowUpdate({ preyn: v.value })}
            />
          ),
        },
        {
          id: 'offiyn',
          text: '임원',
          width: 10,
          render: (x, rowUpdate) => (
            <OptionBox
              value={x.offiyn}
              data={[
                { value: '1', remark: '여' },
                { value: '0', remark: '부' },
              ]}
              onChange={(v) => rowUpdate({ offiyn: v.value })}
            />
          ),
        },
        {
          id: 'prtseq',
          text: '출력순번',
          width: 5,
          render: (x, rowUpdate, ref) => (
            // eslint-disable-next-line max-len
            <TextBox ref={ref} textAlign="center" value={x.prtseq} onChange={(value) => rowUpdate({ prtseq: value })} />
          ),
        },
        {
          id: 'remarks',
          text: '비고',
          width: 20,
        },
      ]}
      data={scope.state.data}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
