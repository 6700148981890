import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { DepartmentModel } from '../models';
import { EndContract } from '../EndContract';
import {
  Button,
  CheckBox,
  FormatTextBox,
  TelnumTextBox,
  TextBox,
} from '../../../../../components';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';

// 거래처별
const Department: GridLayoutHeader<DepartmentModel, EndContract>[] = [
  {
    id: 'divinm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.divinm,
  },
  {
    id: 'no',
    text: 'No',
    width: 50,
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.no}
      </FlexLayout>
    ),
  },
  {
    id: 'smsflag',
    text: '문자',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <CheckBox
          noMargin
          value={x.smsflag === '1'}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'smscount',
    text: '횟수',
    width: 50,
  },
  {
    id: 'wakeflag',
    text: '답변',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <CheckBox
          noMargin
          value={x.wakeflag === '1'}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    sum: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        소 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 80,
  },
  {
    id: 'pernm3',
    text: '기록',
    width: 50,
    sum: (x, data) => {
      const sumPernm = Sum.groupLength(data, 'pernm', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumPernm} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total} 건
      </FlexLayout>
    ),
    render: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <Button
          style={{
            height: '20px',
            width: '40px',
            marginTop: 10,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope?.onClickOpenEnrollment()}
        >
          조회
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '관리대수',
    width: 80,
    sum: (x, data) => {
      const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {sumQty}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.qty_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.qty}
      />
    ),
  },
  {
    id: 'wkactcd',
    text: '회사구분',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
      >
        {scope?.state.wkactcds?.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'monamt',
    text: '대당금액(월 청구금액)',
    width: 130,
    sum: (x, data) => {
      const sumMonamt = Sum.byGroup(data, 'monamt', x, ['divinm']);
      const sumQty = Sum.byGroup(data, 'qty', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(Math.round(Number(sumMonamt) / Number(sumQty)))}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.equpamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.equpamt}
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '계약금액',
    width: 130,
    sum: (x, data) => {
      const sumAmt = Sum.byGroup(data, 'amt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAmt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.amt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.amt)}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약분류',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgubuns?.filter((y) => y.code === x.contgubun)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'picflag',
    text: '계약서',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.picflag === '0' ? '무' : '유'}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.stdate}
      />
    ),
  },
  {
    id: 'enddate',
    text: '종료일자',
    width: 110,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.enddate}
      />
    ),
  },
  {
    id: 'tel',
    text: '전화번호',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <TelnumTextBox
          textAlign="center"
          value={x.tel}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소',
    width: 300,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'deljugi',
    text: '청구주기',
    width: 80,
    render: (x, scope) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {scope?.state.deljugis?.filter((y) => y.com_code === x.deljugi)[0]?.com_cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'moamt',
    text: '청구금액',
    width: 130,
    sum: (x, data) => {
      const sumMonamt = Sum.byGroup(data, 'monamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMonamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.monamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '매출구분',
    width: 100,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 130,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.bildyds?.filter((y) => y.buildcd === x.bildyd)[0]?.buildnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'mgubun',
    text: '유무상',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.mgubun === '1' ? '유' : '무'}
      </FlexLayout>
    ),
  },
  {
    id: 'contdate',
    text: '계약일자',
    width: 110,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FormatTextBox
          format="####-##-##"
          textAlign="center"
          value={x.contdate}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'yy',
    text: '년차',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.yy}
      </FlexLayout>
    ),
  },
  {
    id: 'taxgubun',
    text: '세금구분',
    width: 90,
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.taxgubuns?.filter((y) => y.com_code === x.taxgubun)[0]?.com_cnam}
      </FlexLayout>
    ),
  },
  {
    id: 'taxflag',
    text: '증빙구분',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.taxflag === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
  {
    id: 'addyn',
    text: '부가세포함',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.addyn === '1' ? '포함' : '별도'}
      </FlexLayout>
    ),
  },
  {
    id: 'dedate',
    text: '청구서발행일',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.dedate}
      </FlexLayout>
    ),
  },
  {
    id: 'jirogubun',
    text: '지로발행',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.jirogubun === '1' ? '발행' : '미발행'}
      </FlexLayout>
    ),
  },
];

export default Department;
