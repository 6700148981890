import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { TableLayoutHeader } from '../../../../../constants';
import { FormatTextBox } from '../../../../../components/forms/FormatTextBox';
import { RoutineModel } from '../models';
import { Journal } from '../Journal';

// 사용내역
const Routine: TableLayoutHeader<RoutineModel, Journal>[] = [
  {
    id: 'divinm',
    text: '부서명',
    width: 75,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 75,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'state',
    text: '진행',
    width: 50,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        진행 {scope?.state.tabRoutineCnt_tot}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{
          // eslint-disable-next-line no-nested-ternary
          color: x.state === '0' ? 'var(--color-red)'
            : x.state === '1' ? 'var(--color-greed)' : 'var(--color-blue)',
        }}
      >
        {
          [{ value: '0', remark: '만료' },
            { value: '1', remark: '계획' },
            { value: '2', remark: '검사' }].filter((y) => y.value === x.state)[0]?.remark
        }
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '검사일자',
    width: 75,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        처리 {scope?.state.tabRoutineCompcnt_tot}건
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        format="####-##-##"
        textAlign="center"
        value={x.plandate}
      />
    ),
  },
  {
    id: 'result',
    text: '결과',
    width: 50,
    trail: (_, scope) => (
      <FlexLayout>
        (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          합격 {scope?.state.tabRoutineResult01_tot}건
        </FlexLayout>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: x.result === '002' ? 'var(--color-red)' : 'var(--color-blue)' }}
      >
        {
          [
            { value: '', remark: '' },
            { value: '001', remark: '합격' },
            { value: '002', remark: '불합격' },
            { value: '006', remark: '조건부합격' },
          ].filter((y) => y.value === x.result)[0]?.remark
        }
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    trail: (_, scope) => (
      <FlexLayout>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-green)' }}
        >
          조건부 {scope?.state.tabRoutineResult03_tot}건
        </FlexLayout>
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          불합격 {scope?.state.tabRoutineResult02_tot}건
        </FlexLayout>
        )
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: 'var(--color-red)' }}
        >
          미처리 {scope?.state.tabRoutineMiscnt_tot}건
        </FlexLayout>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 200,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'var(--color-red)' }}
      >
        미계획 {scope?.state.tabRoutineNotcnt_tot}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {
          [
            { value: '000', remark: ' ' },
            { value: '001', remark: '정기' },
            { value: '002', remark: '정밀' },
            { value: '003', remark: '자체' },
            { value: '004', remark: '설치' },
            { value: '005', remark: '완성' },
            { value: '006', remark: '수시' },
            { value: '007', remark: '검사' },
            { value: '008', remark: '0' },
          ].filter((y) => y.value === x.gubun)[0]?.remark
        }
      </FlexLayout>
    ),
  },
  {
    id: 'kcdate',
    text: '검사일자',
    width: 75,
    render: (x) => (
      <FormatTextBox
        format="####-##-##"
        textAlign="center"
        value={x.kcdate}
      />
    ),
  },
  {
    id: 'kcpernm',
    text: '검사자',
    width: 150,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.kcpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'kcspnm',
    text: '검사기관',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {
          [
            { value: '3', remark: '승강기안전공단' },
          ].filter((y) => y.value === x.kcspnm)[0]?.remark
        }
      </FlexLayout>
    ),
  },
];

export default Routine;
