import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_b035
 * @category 샘플
 */
export default class ReportDetailModel {
  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly ctdate: string;

  /**
   * 회사
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 종료시간
   *
   * **data type** varchar(8)
   */
  readonly endtime: string;

  /**
   * 호기
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 작성일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 작성자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 직영코드
   *
   * **data type** varchar(8)
   */
  readonly mpclafi: string;

  /**
   * 책임자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(8)
   */
  readonly projnm: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(8)
   */
  readonly projno: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 순서
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사양
   *
   * **data type** varchar(8)
   */
  readonly sizecd: string;

  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 시작시간
   *
   * **data type** varchar(8)
   */
  readonly sttime: string;

  /**
   * 작업구분
   *
   * **data type** varchar(8)
   */
  readonly wkcd: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.ctdate = data.ctdate || '';
    this.custcd = data.custcd || '';
    this.endtime = data.endtime || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.mpclafi = data.mpclafi || '';
    this.perid = data.perid || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';
    this.sizecd = data.sizecd || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.sttime = data.sttime || '';
    this.wkcd = data.wkcd || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
