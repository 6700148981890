import { TLHF } from '../../../../../constants';
import {
  ComboBox, ComboBoxModel, FlexLayout, FormatNumberTextBox, TextBox,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import EnrollmentTableModel from '../models/EnrollmentTableModel';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export const EnrollmentTableHeader: TLHF<EnrollmentTableModel, Enrollment> = (scope) => ([
  {
    id: 'costseq',
    text: '순번',
    width: 60,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'pgubun',
    text: '제품',
    width: 60,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.pgubun}
        data={[
          { value: '1', remark: '제품' },
          { value: '0', remark: '옵션' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ pgubun: option.value })}
      />
    ),
  },
  {
    id: 'pcode',
    text: '품번',
    color: 'var(--color-red)',
    width: 120,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pcode}
        textAlign="center"
        onDoubleClick={async () => {
          const result = await scope.openDetailitemSelector(x.pcode);
          await scope.tableProductItemChanged(rowUpdate, {
            pcode: result.pcode, pname: result.pname, psize: result.psize, punit: result.punit, uamt: result.uamt, remark: result.remark,
          });
        }}
        onChange={(value) => rowUpdate({ pcode: value })}
        onEnter={async (value) => {
          const result = await scope.openDetailitemSelector(value);
          await scope.tableProductItemChanged(rowUpdate, {
            pcode: result.pcode, pname: result.pname, psize: result.psize, punit: result.punit, uamt: result.uamt, remark: result.remark,
          });
        }}
      />
    ),
  },
  {
    id: 'pname',
    text: '품명',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.pname}
        onChange={(v) => rowUpdate({ pname: v })}
      />
    ),
  },
  {
    id: 'psize',
    text: '규격',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.psize}
        onChange={(v) => rowUpdate({ psize: v })}
      />
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.punit}
        onChange={(v) => rowUpdate({ punit: v })}
      />
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 60,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.myn}
        data={[
          { value: '1', remark: '유상' },
          { value: '0', remark: '무상' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ myn: option.value })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(v) => scope.tableItemChanged(rowUpdate, 'qty', v)}
      />
    ),
    trail: (data) => {
      const sumQty = Sum.all(data, 'qty');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumQty)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'uamt',
    text: '단가',
    width: 120,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.uamt}
        onChange={(v) => scope.tableItemChanged(rowUpdate, 'uamt', v)}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 120,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(v) => scope.tableItemChanged(rowUpdate, 'samt', v)}
      />
    ),
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 120,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.tamt}
        onChange={(v) => rowUpdate({ tamt: v })}
      />
    ),
    trail: (data) => {
      const sumTamt = Sum.all(data, 'tamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumTamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'mamt',
    text: '견적금액',
    width: 120,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.mamt}
        onChange={(v) => rowUpdate({ mamt: v })}
      />
    ),
    trail: (data) => {
      const sumMamt = Sum.all(data, 'mamt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumMamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 350,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
