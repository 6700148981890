/**
 * 모델
 * @window w_tb_e075w_01
 * @category 호기별검사현황[최종검사]
 */

export class UnitinspectModel {
  /**
   * 회사코드
   *
   * **data type** varchar()
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar()
   */
  readonly spjangcd: string;

  /**
   * 부서
   *
   * **data type** varchar()
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar()
   */
  readonly divinm: string;

  /**
   * 담당자
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar()
   */
  readonly pernm: string;

  /**
   * 현장코드
   *
   * **data type** varchar()
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar()
   */
  readonly actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar()
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar()
   */
  readonly equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar()
   */
  readonly elno: string;

  /**
   * 모델명
   *
   * **data type** varchar()
   */
  readonly mdnm: string;

  /**
   * 운행구간
   *
   * **data type** varchar()
   */
  readonly numbergugan: string;

  /**
   * 진행
   *
   * **data type** varchar()
   */
  readonly state: string;

  /**
   * 결과
   *
   * **data type** varchar()
   */
  readonly resultnm: string;

  /**
   * 검사일자
   *
   * **data type** varchar()
   */
  readonly plandate: string;

  /**
   * 운행시작일
   *
   * **data type** varchar()
   */
  readonly stdate: string;

  /**
   * 운행종료일
   *
   * **data type** varchar()
   */
  readonly enddate: string;

  /**
   * 검사기관
   *
   * **data type** varchar()
   */
  readonly kcpernm: string;

  /**
   * 결과
   *
   * **data type** varchar()
   */
  readonly result: string;

  /**
   * 팝업창 배열개수
   *
   * **data type** varchar()
   */
  readonly failcnt: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.mdnm = data.mdnm || '';
    this.numbergugan = data.numbergugan || '';
    this.state = data.state || '';
    this.resultnm = data.resultnm || '';
    this.plandate = data.plandate || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.kcpernm = data.kcpernm || '';
    this.result = data.result || '';
    this.failcnt = data.failcnt || '';
  }
}
