import * as React from 'react';
import {
  AddressButton, DateTextBox, FlexLayout, FormatNumberTextBox, LabelBox, LayoutTitle, TextBox,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Contract } from '../Contract';
import { SearchBinding, SearchBindingProjects } from '../../../../../models';
import ContractDefaultModel from '../models/ContractDefaultModel';

/**
 * 화면 > 탭 - 노무안전관리서약서
 * @window w_tb_e101_share
 * @category 외주계약등록
 */
export const ContractTabSafeTemplate: React.FC<TemplateProps<Contract>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new ContractDefaultModel({
      ...content,
      ...data,
    }, content?.isNew),
  }, callback);

  return (
    <FlexLayout isVertical={true}>
      <LayoutTitle>노무안전관리서약서</LayoutTitle>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="프로젝트"
          isNeed={true}
          style={Global.STYLE_COLOR_RED}
        >
          <TextBox
            size={90}
            textAlign="center"
            value={content?.projno}
            onChange={(value) => setContent({ projno: value })}
            bindSearchModal={new SearchBindingProjects(
              scope.props.modalStore,
              {},
              true,
              (item) => {
                setContent({
                  projno: item.projno,
                  projnm: item.projnm,
                  actcd: item.actcd,
                  actnm: item.actnm,
                }, () => scope.itemChanged('projno', item.projno));
              },
              true,
            )}
          />

          <TextBox
            value={content?.projnm}
            readonly={true}
          />
        </LabelBox>

        <LabelBox
          title="현장"
          style={Global.STYLE_COLOR_RED}
        >
          <TextBox
            value={content?.actcd}
            textAlign="center"
            onChange={(value) => setContent({ actcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                setContent({
                  actcd: item.cd,
                  actnm: item.cdnm,
                }, () => scope.itemChanged('actcd', item.cd));
              },
            )}
          />

          <TextBox
            weight={2}
            value={content.actnm}
            readonly={true}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox title="설치기성금액">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.mamt}
            onChange={(v) => scope.itemChanged('mamt', v)}
          />
        </LabelBox>

        <LabelBox title="공급가액">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.samt}
            onChange={(v) => setContent({ samt: v })}
          />
        </LabelBox>

        <LabelBox title="부가세액">
          <FormatNumberTextBox
            textAlign="right"
            value={content?.tamt}
            onChange={(v) => setContent({ tamt: v })}
          />
        </LabelBox>

        <LabelBox
          title="공사기간"
          footer={
            <FlexLayout>① 착공일</FlexLayout>
          }
        >
          <DateTextBox
            format="####-##-##"
            textAlign="center"
            value={content?.baldate}
            onChange={(v) => setContent({ baldate: v })}
          />
        </LabelBox>

        <LabelBox title="② 준공일">
          <DateTextBox
            format="####-##-##"
            textAlign="center"
            value={content?.kcdate}
            onChange={(v) => setContent({ kcdate: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="[수급사업자] 주소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                custzipcd: address.zonecode,
                custadres: address.address,
                custadres2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.custzipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.custadres}
            onChange={(value) => setContent({ custadres: value })}
          />

          <TextBox
            weight={2}
            value={content?.custadres2}
            onChange={(value) => setContent({ custadres2: value })}
          />
        </LabelBox>

        <LabelBox
          title="(갑) 상호"
          weight={1.2}
        >
          <TextBox
            textAlign="left"
            value={content?.spjangnm}
            onChange={(v) => setContent({ spjangnm: v })}
          />
        </LabelBox>

        <LabelBox
          title="성명(대표이사)"
          weight={0.8}
        >
          <TextBox
            textAlign="center"
            value={content?.prenm}
            onChange={(v) => setContent({ prenm: v })}
          />
        </LabelBox>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
        <LabelBox
          title="[하수급사업자] 주소"
          weight={4}
          style={Global.STYLE_COLOR_RED}
          footer={<FlexLayout>
            <AddressButton
              publicStore={scope.props.publicStore}
              modalStore={scope.props.modalStore}
              onChange={(address) => setContent({
                xzipcd: address.zonecode,
                xaddress: address.address,
                xaddress2: address.jibunAddress,
              })}
            >
              주소검색
            </AddressButton>
          </FlexLayout>}
        >
          <TextBox
            textAlign="center"
            value={content?.xzipcd}
            readonly={true}
          />

          <TextBox
            weight={2}
            value={content?.xaddress}
            onChange={(value) => setContent({ xaddress: value })}
          />

          <TextBox
            weight={2}
            value={content?.xaddress2}
            onChange={(value) => setContent({ xaddress2: value })}
          />
        </LabelBox>

        <LabelBox title="(을) 상호">
          <TextBox
            textAlign="left"
            value={content?.xspjangnm}
            onChange={(v) => setContent({ xspjangnm: v })}
          />
        </LabelBox>

        <LabelBox
          title="계약일"
          footer={
            <FlexLayout style={content?.attcnt === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}>
              {content?.attcnt === '0' ? '서명없음' : '서명있음'}
            </FlexLayout>
          }
        >
          <DateTextBox
            textAlign="center"
            format="####-##-##"
            value={content?.contdate}
            onChange={(v) => setContent({ contdate: v })}
          />
        </LabelBox>
      </FlexLayout>
    </FlexLayout>
  );
};
