import { computed } from 'mobx';

/**
 * 모델
 * @window  w_tb_monend_incentive_notuseamt
 * @category 월프로젝트이익금체크
 */
export class MonthProjectModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 수금일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 프로젝트NO
   *
   * **data type** varchar(10)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(200)
   */
  projnm: string;

  /**
   * 주담당자
   *
   * **data type** varchar(10)
   */
  compperid: string;

  /**
   * 주담당자명
   *
   * **data type** varchar(30)
   */
  comppernm: string;

  /**
   * 순번
   *
   * **data type** varchar(5)
   */
  seq: string;

  /**
   * 품목코드
   *
   * **data type** varchar(20)
   */
  pcode: string;

  /**
   * 품목명
   *
   * **data type** varchar(100)
   */
  pname: string;

  /**
   * 공사금액
   *
   * **data type** number
   */
  compamt: string;

  /**
   * 매입단가
   *
   * **data type** number
   */
  phm_mamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rcvdate = data.rcvdate || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.compperid = data.compperid || '';
    this.comppernm = data.comppernm || '';
    this.seq = data.seq || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.compamt = data.compamt || '';
    this.phm_mamt = data.phm_mamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
