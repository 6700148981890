import { computed } from 'mobx';

/**
 * 상세모델
 * @window w_tb_e080_attach_01
 * @category 파일보관함[첨부파일]
 */
export class FileStoreDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly chk: number;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly appnm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly fileseq: number;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly filename: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly fileext: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly filepath: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly lockflag: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly passward: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly gs_perid: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.chk = data.chk || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.appnum = data.appnum || '';
    this.appnm = data.appnm || '';
    this.title = data.title || '';
    this.fileseq = data.fileseq || 0;
    this.filename = data.filename || '';
    this.fileext = data.fileext || '';
    this.filepath = data.filepath || '';
    this.inperid = data.inperid || '';
    this.pernm = data.pernm || '';
    this.lockflag = data.lockflag || '';
    this.passward = data.passward || '';
    this.indate = data.indate || '';
    this.gs_perid = data.gs_perid || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
