/**
 * 모델
 * @window w_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */

export class CompPopupModel {
  /**
   * 수주일자
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly ordnum: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 수주종류
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 제목
   *
   * **data type** varchar(8)
   */
  readonly title: string;

  /**
   * 대수
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 부가세액
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 합계금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 공사완료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 특기사항
   *
   * **data type** varchar(8)
   */
  readonly worknm: string;

  constructor(data: any = {}) {
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.pernm = data.pernm || '';
    this.gubun = data.gubun || '';
    this.title = data.title || '';
    this.qty = data.qty || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.actnm = data.actnm || '';
    this.worknm = data.worknm || '';
  }
}
