import { GridLayoutHeader } from '../../../../../constants';
import { UserAppModel } from '../models';
import { User } from '../User';
import { FlexLayout } from '../../../../../components';


// 사용자별 어플사용건수
const UserAPP: GridLayoutHeader<UserAppModel, User>[] = [
  {
    id: 'divinm',
    text: '부서명',
    width: 14,
  },
  {
    id: 'perid',
    text: 'ID',
    width: 14,
  },
  {
    id: 'pernm',
    text: '사용자명',
    width: 14,
  },
  {
    id: 'winnm',
    text: '사용내역',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.winnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cnt',
    text: '건수',
    width: 20,
  },
];


export default UserAPP;
