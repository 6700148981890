import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_da034_01
 * @category 불출등록
 */

export class SupplyModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly delnum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly deltype: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly store: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly storenm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly cltnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly divinm: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly projnm: string;

  /**
   * 계약구분
   *
   * **data type** varchar(4)
   */
  readonly contgubun: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly misflag: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly ibgdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly ibgnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly pumflag: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly ibgflag: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly bestate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly taxcls: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly setcls: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly givedd: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly domcls: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly taxflag: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly taxdate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly taxnum: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly delgubun: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar()
   */
  readonly inperid: string;

  new: string;

  delseq: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.deldate = data.deldate || '';
    this.delnum = data.delnum || '';
    this.delseq = data.delseq || '';
    this.deltype = data.deltype || '';
    this.store = data.store || '';
    this.storenm = data.storenm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.contgubun = data.contgubun || '';
    this.remark = Fix.newline(data.remark) || '';
    this.misflag = data.misflag || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.ibgdate = data.ibgdate || '';
    this.ibgnum = data.ibgnum || '';
    this.pumflag = data.pumflag || '';
    this.ibgflag = data.ibgflag || '';
    this.bestate = data.bestate || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.taxcls = data.taxcls || '';
    this.setcls = data.setcls || '';
    this.givedd = data.givedd || '';
    this.domcls = data.domcls || '';
    this.taxflag = data.taxflag || '';
    this.taxdate = data.taxdate || '';
    this.taxnum = data.taxnum || '';
    this.delgubun = data.delgubun || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
