/**
 * 팝업모델
 * @window w_tb_ca642_ats
 * @category 예금관리
 */
export class CltcdPopupModel {
  /**
   * 구분
   *
   * **data type** varchar(13)
   */
  gubun: string;

  /**
   * 코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltnm: string;

  /**
   * 사업자번호
   *
   * **data type** varchar(13)
   */
  saupnum: string;

  /**
   * 대표자
   *
   * **data type** varchar(13)
   */
  prenm: string;

  constructor(data: any = {}) {
    this.gubun = data.gubun || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.saupnum = data.saupnum || '';
    this.prenm = data.prenm || '';
  }
}
