import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { createObservableHistory } from 'mobx-observable-history';
import { configure } from 'mobx';
import { createStores } from './app/stores';
import App from './app';

configure({
  enforceActions: 'never',
});

// prepare MobX stores
const navigation = createObservableHistory<History>();
const rootStore = createStores(navigation);

// render react DOM
ReactDOM.render(
  <Provider {...rootStore}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
