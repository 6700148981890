import { computed } from 'mobx';

/**
 * 계획대비실적[계약]모델
 * @window w_tb_da052w_07
 * @category 월업무보고
 */
export class AimModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 현장명
   *
   * **data type** char(255)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** number
   */
  qty: string;

  /**
   * 구분
   *
   * **data type** char(3)
   */
  flag: string;

  /**
   * 완료보수료
   *
   * **data type** number
   */
  qtysamt: string;

  /**
   * 이전계약
   *
   * **data type** char(10)
   */
  becontgubun: string;

  /**
   * 전보수료
   *
   * **data type** number
   */
  qtyamt: string;

  /**
   * 계획계약
   *
   * **data type** char(10)
   */
  plancontgubun: string;

  /**
   * 계약
   *
   * **data type** char(10)
   */
  contgubun: string;

  /**
   * 예상보수료
   *
   * **data type** number
   */
  planqtysamt: string;

  /**
   * 예상증감액
   *
   * **data type** number
   */
  compute_6: string;

  /**
   * 계획내용
   *
   * **data type** char(8000)
   */
  remark: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  /**
   * 결과
   *
   * **data type** char(5)
   */
  planflag: string;

  /**
   * 예상증감액
   *
   * **data type** number
   */
  planqtysamttot: string;

  /**
   * 예상증감액
   *
   * **data type** number
   */
  compflag: string;

  /**
   * -
   *
   * **data type** number
   */
  actcd: string;

  /**
   * -
   *
   * **data type** number
   */
  contgubun01: string;

  /**
   * -
   *
   * **data type** number
   */
  contgubun02: string;

  /**
   * -
   *
   * **data type** number
   */
  contgubun03: string;

  /**
   * 사원코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 지급구분
   *
   * **data type** varchar(34)
   */
  gubun: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.perid = data.perid || '';
    this.gubun = data.gubun || '';
    this.custcd = data.custcd || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.actcd = data.actcd || '';
    this.divinm = data.divinm || '';
    this.qty = data.qty || '';
    this.contgubun01 = data.contgubun01 || '';
    this.contgubun02 = data.contgubun02 || '';
    this.contgubun03 = data.contgubun03 || '';
    this.compflag = data.compflag || '';
    this.planqtysamttot = data.planqtysamttot || '';
    this.planflag = data.planflag || '';
    this.actnm = data.actnm || '';
    this.becontgubun = data.becontgubun || '';
    this.flag = data.flag || '';
    this.contgubun = data.contgubun || '';
    this.qtyamt = data.qtyamt || '';
    this.qtysamt = data.qtysamt || '';
    this.plancontgubun = data.plancontgubun || '';
    this.planqtysamt = data.planqtysamt || '';
    this.compute_6 = data.compute_6 || '';
    this.remark = data.remark || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
