import * as React from 'react';
import { ReactNode, RefObject } from 'react';
import { inject } from 'mobx-react';
import { BsDot, MdChevronLeft, MdChevronRight } from 'react-icons/all';
import update from 'react-addons-update';
import {
  Index,
  InfiniteLoader,
  List,
  ListRowProps,
} from 'react-virtualized';
import { Mutex } from 'async-mutex';
import { action, computed } from 'mobx';
import {
  Item,
  ItemParams,
  Menu,
  Separator,
  useContextMenu,
} from 'react-contexify';
import _ from 'lodash';
import { FlexLayout } from '../FlexLayout';
import { TableLayoutHeader, TableLayoutProps } from '../../../constants';
import style from './TableLayout.module.scss';
import { Format, JoinClassName, Sha256 } from '../../../utils/string';
import { ConfirmWarning } from '../../../utils/confirm';
import { AutoPadding } from '../../../utils/layout';
import { Sort } from '../../../utils/array';

enum MENU_ACTION {
  SORT_ASC,
  SORT_DESC,
  LOCK,
  UNLOCK,
}

interface TableLayoutState {
  data: Array<any>;
  totalWidth?: number;
  focusedRow?: number;
  railThumbWidth: number;
  isHorizontalMouseActive: boolean;
  isListHorizontalScroll: boolean;
  isListVerticalScroll: boolean;
  colLock: number;
  page: number;
}

@inject('publicStore')
export class TableLayout extends React.Component<
  TableLayoutProps,
  TableLayoutState
> {
  mainList?: List;

  refLockList: RefObject<List>;

  gridClientHeight: number;

  hasTrail: boolean;

  pageHeight: number;

  totalHeight: number;

  totalWidth: number;

  perPxWidth: number;

  listScrollMutex: Mutex;

  virtualInnerHeight: number;

  container: RefObject<HTMLDivElement>;

  scrollHeader: RefObject<HTMLDivElement>;

  horizontalScrollHandler: RefObject<HTMLDivElement>;

  horizontalTrailHandler: RefObject<HTMLDivElement>;

  scrollBody: RefObject<HTMLDivElement>;

  scrollHorizontalRail: RefObject<HTMLDivElement>;

  scrollHorizontalRailThumb: RefObject<HTMLDivElement>;

  /**
   * 하단 스크롤바 위에서 움직이는 녀석의 현재 위치
   */
  scrollHorizontalRailThumbX: number;

  /**
   * 하단 스크롤바의 최대 이동 범위
   */
  maxScrollHorizontalRailThumbX: number;

  /**
   * 하단 스크롤바가 1px 움직일때 본문의 X축이 얼만큼 움직일지
   */
  perPxScrollHorizontalRailThumbX1px: number;

  /**
   * props.data 중 변경된 행의 인덱스 모음
   */
  changedIndexes: Array<number>;

  dataRefs: Array<Array<RefObject<any>>>;

  lastFocusedTime: number;

  newRowCount: number;

  triggeredOnBottom: boolean;

  urlId?: string;

  littleWidth: number;

  scrollTop: number;

  preRenderData: Array<ReactNode> = [];

  preRenderHeader: ReactNode = undefined;

  isDisposed: boolean = false;

  focusedRow?: number;

  /**
   * 페이지 사용시
   */
  totalPage?: number;

  pageSize?: number;

  calcedRowHeightCauseOfPaging?: number;

  group: Array<string>;

  isEnabledGroup: boolean;

  constructor(props: TableLayoutProps, context?: any) {
    super(props, context);
    this.container = React.createRef();
    this.refLockList = React.createRef();
    this.gridClientHeight = 1;
    this.littleWidth = 0;
    this.pageHeight = 1;
    this.totalHeight = 1;
    this.totalWidth = 1;
    this.perPxWidth = 1; // 화면보다 내용물이 적을 경우 100% 맞추기 위해서
    this.scrollHeader = React.createRef();
    this.scrollBody = React.createRef();
    this.scrollHorizontalRail = React.createRef();
    this.scrollHorizontalRailThumb = React.createRef();
    this.horizontalScrollHandler = React.createRef();
    this.horizontalTrailHandler = React.createRef();
    this.virtualInnerHeight = 0;
    this.scrollHorizontalRailThumbX = 0;
    this.maxScrollHorizontalRailThumbX = 0;
    this.perPxScrollHorizontalRailThumbX1px = 0;
    this.changedIndexes = [];
    this.dataRefs = [];
    this.listScrollMutex = new Mutex();
    this.lastFocusedTime = 0;
    this.newRowCount = 0;
    this.scrollTop = 0;
    this.triggeredOnBottom = false;
    this.group = props.header.filter((x) => x.group).map((x) => x.id);
    this.isEnabledGroup = this.group.length > 0;
    this.hasTrail = props.header.filter((x) => x.trail).length > 0;
    this.state = {
      data: props.data,
      railThumbWidth: 100,
      isHorizontalMouseActive: false,
      isListVerticalScroll: false,
      isListHorizontalScroll: true,
      colLock: 0,
      page: 1,
    };
  }

  dispose() {
    this.isDisposed = true;
    this.mainList = undefined;
    this.preRenderData = [];
    this.preRenderHeader = null;
    this.dataRefs = [];
  }

  componentDidMount(): void {
    const { publicStore: p, header } = this.props;
    this.urlId = p?.currentMenu.active.id;

    const key = Sha256.make(`${this.urlId}${JSON.stringify(header.map((x) => x.id))}`);
    const temp = p!.tempStorage[key];

    this.preRenderData = [];

    if (temp) {
      this.newRowCount = temp?.newRowCount || 0;
      this.changedIndexes = temp?.changedIndexes || [];
      if (temp?.focusedRow) {
        this.setState({ focusedRow: temp?.focusedRow });
        this.focusedRow = temp?.focusedRow;
      }
      this.preRenderData = temp?.preRenderData || [];
      this.preRenderHeader = temp?.preRenderHeader || undefined;
    }
    this.init(true);
  }

  componentWillUnmount() {
    const { publicStore: p, header } = this.props;
    const key = Sha256.make(`${this.urlId}${JSON.stringify(header.map((x) => x.id))}`);
    p!.tempStorage[key] = {
      newRowCount: this.newRowCount,
      changedIndexes: this.changedIndexes,
      focusedRow: this.state.focusedRow,
      preRenderData: this.preRenderData,
      preRenderHeader: this.preRenderHeader,
    };

    if (p!.headerTabStore.tabs.filter((x) => x.id === this.urlId).length < 1) {
      delete p!.tempStorage[key];
      this.preRenderData = [];
      this.preRenderHeader = [];
    }

    this.dispose();
  }

  componentDidUpdate(prevProps: Readonly<TableLayoutProps>) {
    if (this.isDisposed) return;
    if (prevProps.data !== this.props.data) {
      this.preRenderData = [];
    }
  }

  public update(isReset: boolean = true): Promise<void> {
    this.triggeredOnBottom = false;
    if (isReset) this.preRenderData = [];
    if (isReset) this.preRenderHeader = undefined;
    return this.init(isReset);
  }

  public forceModifiedRow(y: number) {
    if (y < this.state.data.length && this.changedIndexes.indexOf(y) === -1) {
      this.changedIndexes.push(y);
      this.onChange();
    }
  }

  public resetUpdates(): void {
    this.changedIndexes = [];
  }

  public setFocus(y: number, x: number = 0): void {
    setTimeout(() => {
      if (y < this.state.data.length) {
        this.rowSelect(this.state.data[y], y);
      }

      if (this.mainList) {
        if (this.props.isPaging) {
          if (this.state.focusedRow !== undefined) {
            this.preRenderData[this.state.focusedRow] = undefined;
          }
          this.setState({ page: this.findPageWithRowIndex(y), focusedRow: y }, () => {
            this.mainList?.forceUpdateGrid();
            this.refLockList.current?.forceUpdateGrid();

            requestAnimationFrame(() => this.mainList!.scrollToRow(y % this.pageSize!));
            requestAnimationFrame(() => this.refLockList.current?.scrollToRow(y % this.pageSize!));
            try {
              if (this.dataRefs[y][x].current) {
                setTimeout(() => {
                  requestAnimationFrame(() => this.dataRefs[y][x].current?.focus());
                }, 100);
              }
            } catch {
              //
            }
          });
        } else {
          requestAnimationFrame(() => this.mainList!.scrollToRow(y));
          requestAnimationFrame(() => this.refLockList.current?.scrollToRow(y));
          try {
            if (this.dataRefs[y][x].current) {
              setTimeout(() => {
                requestAnimationFrame(() => this.dataRefs[y][x].current?.focus());
              }, 100);
            }
          } catch {
            //
          } finally {
            if (this.state.focusedRow !== undefined) {
              this.preRenderData[this.state.focusedRow] = undefined;
            }
            this.setState({ focusedRow: y }, () => {
              this.mainList?.forceUpdateGrid();
              this.refLockList.current?.forceUpdateGrid();
            });
          }
        }
      }
    }, 100);
  }

  private onListScroll = (e: any, isMain: boolean): void => {
    const { scrollTop } = e;
    if (this.props.infinityHandler?.isLoading || this.scrollTop === scrollTop) {
      return;
    }

    this.scrollTop = scrollTop;
    this.props?.onVerticalScroll && this.props.onVerticalScroll(scrollTop);

    if (isMain) {
      requestAnimationFrame(() => {
        this.refLockList.current?.scrollToPosition(scrollTop || 0);
      });
    } else {
      requestAnimationFrame(() => {
        this.mainList?.scrollToPosition(scrollTop || 0);
      });
    }
  }

  doScrollY = _.debounce(this.onListScroll, 300);

  private init(isReset: boolean = true): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.isDisposed) return;

      setTimeout(() => {
        const { rowHeight } = this.props;
        const data = this.props.data || [];
        // const isListVerticalScroll = this.props.isPaging ? false : data.length * rowHeight > (this.scrollBody.current?.clientHeight || 0);
        const isListVerticalScroll = data.length * rowHeight > (this.scrollBody.current?.clientHeight || 0);

        let { totalWidth } = this.state;
        let { railThumbWidth } = this.state;

        if (isReset) {
          this.preRenderData = [];
          this.changedIndexes = [];
          this.newRowCount = 0;
          this.setState({ focusedRow: undefined });
          this.focusedRow = undefined;
          this.scrollTop = 0;
          this.mainList?.scrollToPosition(0);
          this.refLockList.current?.scrollToPosition(0);
          this.onDeltaXEvent(this.scrollHorizontalRailThumbX * -1);

          this.virtualInnerHeight = (this.scrollBody.current?.clientHeight || 0);
          this.hasTrail = this.props.header.filter((x) => x.trail).length > 0;

          // horizontal scrollbar
          this.scrollHorizontalRailThumbX = 0;
          this.maxScrollHorizontalRailThumbX = 0;
          this.perPxScrollHorizontalRailThumbX1px = 0;

          totalWidth = this.visibleHeaders.reduce(
            (p, c) => p + (c?.width || 100), 0,
          );

          const clientWidth = this.container.current?.clientWidth || 0;
          const railWidth = this.scrollHorizontalRail.current?.clientWidth || 0;
          railThumbWidth = Math.round((railWidth * clientWidth) / totalWidth);

          if (clientWidth - totalWidth > 0) {
            railThumbWidth = railWidth;
            this.maxScrollHorizontalRailThumbX = 0;
            this.perPxScrollHorizontalRailThumbX1px = 0;

            const tWidth = clientWidth + (isListVerticalScroll ? -12 : 0);
            this.perPxWidth = tWidth / totalWidth;
            this.littleWidth = tWidth - this.visibleHeaders.reduce(
              (p, c) => p + Math.round((c?.width || 100) * this.perPxWidth), 0,
            );
          } else {
            this.perPxWidth = 1;
            this.littleWidth = 0;
            this.maxScrollHorizontalRailThumbX = railWidth - railThumbWidth;
            this.perPxScrollHorizontalRailThumbX1px = Math.ceil(
              (totalWidth + (isListVerticalScroll ? 12 : 0) - clientWidth) / this.maxScrollHorizontalRailThumbX,
            );
          }
          //

          this.totalWidth = totalWidth;

          // this.pageSize = Math.floor((this.scrollBody.current?.clientHeight || 1) / rowHeight);
          this.pageSize = 100;
          this.totalPage = Math.ceil(data.length / this.pageSize);
          this.calcedRowHeightCauseOfPaging = (this.scrollBody.current?.clientHeight || 1) / this.pageSize;
          this.setState({ page: 1 });
        } else if (this.props.newOnlyOne && this.newRowCount > 1) {
          this.onChange();
          ConfirmWarning.show('경고', '한번에 한개의 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
          return;
        } else {
          this.newRowCount = (data?.length || 0) - (this.state.data?.length || 0);
        }

        this.setState({
          data: this.props?.data || [],
          totalWidth,
          railThumbWidth,
          isListVerticalScroll,
          isListHorizontalScroll: this.perPxScrollHorizontalRailThumbX1px > 0,
        }, async () => {
          await this.mainList?.forceUpdateGrid();
          await this.refLockList.current?.forceUpdateGrid();
          if (isReset) await this.applyScrollX(0);
          resolve();
        });
      }, 10);
    });
  }

  private rowIndex(index: number): number {
    if (this.props.isPaging && this.state.page !== undefined && this.pageSize !== undefined) {
      return (this.state.page - 1) * this.pageSize + index;
    }
    return index;
  }

  private pageUp(): void {
    if (this.state.page === undefined || this.pageSize === undefined) return;
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.mainList?.forceUpdateGrid();
        this.refLockList.current?.forceUpdateGrid();

        this.mainList?.scrollToPosition(0);
        this.refLockList?.current?.scrollToPosition(0);
      });
    }
  }

  private pageDown(): void {
    if (this.state.page === undefined || this.pageSize === undefined) return;
    if (this.pageSize && this.state.page <= (this.totalPage || 1)) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.mainList?.forceUpdateGrid();
        this.refLockList.current?.forceUpdateGrid();

        this.mainList?.scrollToPosition(0);
        this.refLockList?.current?.scrollToPosition(0);
      });
    }
  }

  private onPageClick(page: number): void {
    if (this.state.page === undefined) return;
    if (page > 0 && page <= (this.totalPage || 1)) {
      this.setState({ page }, () => {
        this.mainList?.forceUpdateGrid();
        this.refLockList.current?.forceUpdateGrid();

        this.mainList?.scrollToPosition(0);
        this.refLockList?.current?.scrollToPosition(0);
      });
    }
  }

  private get pageBarContainerWidth(): number {
    if (!this.totalPage) return 1;
    if (this.totalPage <= 10) return 24 * this.totalPage;

    return 24 * 10;
  }

  // @ts-ignore
  private isGroupTail(item: any, i: number): boolean {
    let result = false;

    if (i === this.state.data.length - 1) {
      return true;
    }

    this.group.forEach((x) => {
      if (this.state.data[i + 1][x] !== item[x]) {
        result = true;
      }
    });

    return result;
  }

  private makePageArray(): number[] {
    const result: number[] = [];
    if (!this.totalPage || this.totalPage < 2) return result;
    if (this.totalPage <= 10) {
      for (let i = 1; i <= this.totalPage; i += 1) {
        result.push(i);
      }
      return result;
    }

    const start = Math.max(2, this.state.page - 3);
    const end = Math.min(this.totalPage, start + 7);
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    if (result.length < 7) {
      const diff = 7 - result.length;
      for (let i = 0; i < diff; i += 1) {
        if (result[0] > 2) {
          result.unshift(result[0] - 1);
        } else if (result[result.length - 1] < this.totalPage - 1) {
          result.push(result[result.length - 1] + 1);
        }
      }
    }
    return result;
  }

  private findPageWithRowIndex(rowIndex: number): number {
    if (this.pageSize === undefined) return 1;
    return Math.ceil((rowIndex + 1) / this.pageSize) || 1;
  }

  private onHorizontalDragging(e: React.MouseEvent<HTMLDivElement>): void {
    if (e.buttons === 1 && !this.state.isHorizontalMouseActive) {
      this.setState({ isHorizontalMouseActive: true });
      e.preventDefault();
      e.stopPropagation();
    }
  }

  private onMouseMove(e: React.MouseEvent<HTMLDivElement>): void {
    if (e.buttons === 1 && this.state.isHorizontalMouseActive) {
      this.onDeltaXEvent(e.movementX);
      e.preventDefault();
      e.stopPropagation();
    } else if (this.state.isHorizontalMouseActive) {
      requestAnimationFrame(() => {
        this.setState({ isHorizontalMouseActive: false });
      });
    }
  }

  private onMouseLeave(_: React.MouseEvent<HTMLDivElement>): void {
    this.setState({ isHorizontalMouseActive: false });
  }

  private onDeltaXEvent(value: number): void {
    if (this.scrollHorizontalRailThumb.current) {
      let toThumbLeft = this.scrollHorizontalRailThumbX + value;
      if (toThumbLeft < 0) toThumbLeft = 0;
      if (toThumbLeft > this.maxScrollHorizontalRailThumbX) {
        toThumbLeft = this.maxScrollHorizontalRailThumbX;
      }

      this.scrollHorizontalRailThumbX = toThumbLeft;
      this.scrollHorizontalRailThumb.current.style.transform = `translateX(${toThumbLeft}px)`;

      const toBothScrollLeft = this.perPxScrollHorizontalRailThumbX1px * toThumbLeft;

      this.applyScrollX(toBothScrollLeft);
      if (this.mainList) {
        this.mainList.Grid?.scrollToPosition({
          scrollLeft: toBothScrollLeft,
          scrollTop: this.scrollTop,
        });

        this.props.onHorizontalScroll && this.props.onHorizontalScroll(toBothScrollLeft);
      }
    }
  }

  private applyScrollX(left: number) {
    if (this.horizontalScrollHandler.current) this.horizontalScrollHandler.current.scrollLeft = left;
    if (this.horizontalTrailHandler.current) this.horizontalTrailHandler.current.scrollLeft = left;
    if (this.scrollBody.current) this.scrollBody.current.scrollLeft = left;
  }

  private rowUpdate(index: number, changes: any, _y: number) {
    const willUpdate = {};
    Object.keys(changes).forEach((id) => {
      // @ts-ignore
      willUpdate[id] = {
        $set: changes[id],
      };
    });

    this.preRenderData[index] = undefined;
    this.setState({
      data: update(this.state.data, {
        [index]: willUpdate,
      }),
    }, () => {
      this.mainList?.forceUpdateGrid();
      this.refLockList.current?.forceUpdateGrid();
      // this.dataRefs[y][index].current?.forceUpdate();

      if (this.changedIndexes.indexOf(index) === -1) this.changedIndexes.push(index);
      this.onChange();
    });
  }

  @computed
  get visibleHeaders(): Array<TableLayoutHeader> {
    return this.props.header.filter((k) => !k.isHidden);
  }

  @action
  private rowSelect(item: any, index: number) {
    if (this.focusedRow === index) return;
    this.preRenderData[index] = undefined;
    this.focusedRow = index;
    setTimeout(() => {
      this.props.onRowFocusEvent && this.props.onRowFocusEvent(item, index);
    }, 100);
  }

  private onChange = () => {
    this.props.onChange && this.props.onChange(this.state.data,
      this.state.data.filter((_x: any, i: number) => this.changedIndexes.indexOf(i) > -1));
  }

  private isRowLoaded(p: Index): boolean {
    return p.index < this.state.data.length && !!this.state.data[p.index];
  }

  @action
  private async loadMoreRows(): Promise<void> {
    return this.props.infinityHandler?.loadMoreRows();
  }

  private get pageData(): Array<any> {
    if (!this.state.data?.length) return [];
    if (!this.props.isPaging) return this.state.data;
    if (this.pageSize === undefined) return this.state.data;
    const start = (this.state.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.state.data.slice(start, end);
  }

  private get rowHeightWithPaging(): number {
    // if (!this.props.isPaging) return this.props.rowHeight;
    // return this.calcedRowHeightCauseOfPaging || this.props.rowHeight;
    return this.props.rowHeight;
  }

  // Contents
  private rowRenderer = ({
    key,
    index: i,
    style: rowStyle,
  }: ListRowProps) => {
    const index = this.rowIndex(i);

    if (!this.state.data[index]) {
      return (
        <FlexLayout
          key={key}
          size={this.rowHeightWithPaging}
          style={rowStyle}
        />
      );
    }

    if (this.preRenderData[index]) {
      return this.preRenderData[index];
    }

    const y = this.state.data[index];
    const calcBackgroundColor = this.props.rowColor ? this.props.rowColor(y) : '';
    const backgroundColor = index % 2 === 0 ? '#FAFAFA' : '#F2F2F2';

    if (!this.dataRefs[index]) {
      this.dataRefs[index] = [];
    }

    const result = (
      <FlexLayout
        key={key}
        className={this.state.focusedRow === index ? style.highlight : false}
        size={this.props.rowHeight}
        style={rowStyle}
        tabindex={1}
        onDoubleClick={() => {
          this.props.onRowDoubleClick && this.props.onRowDoubleClick(y, index);
        }}
        onClick={() => {
          if (new Date().getTime() - this.lastFocusedTime < 300) return;
          if (this.focusedRow === index) return;
          this.lastFocusedTime = new Date().getTime();
          this.rowSelect(y, index);
        }}
        onFocus={() => {
          if (new Date().getTime() - this.lastFocusedTime < 300) return;
          if (this.focusedRow === index) return;
          this.lastFocusedTime = new Date().getTime();
          this.rowSelect(y, index);
        }}
      >
        {this.visibleHeaders.map((x, j: number) => {
          const width = Math.round((x.width || 100) * this.perPxWidth)
            + (j === 0 ? this.littleWidth : -2);

          if (!this.dataRefs[index][j]) {
            this.dataRefs[index][j] = React.createRef();
          }

          const rowItem = x.render ? AutoPadding.make(x.render(
            y,
            (changes) => this.rowUpdate(index, changes, j),
            this.dataRefs[index][j],
            this.props.scope,
            index,
          )) : undefined;

          return (
            <React.Fragment key={x.id}>
              {x.render ? (
                <FlexLayout
                  className={style.overflowHidden}
                  style={{
                    width,
                    maxWidth: width,
                    minWidth: width || 100,
                    backgroundColor,
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 40) {
                      e.preventDefault();
                      !this.isVisibleRow(index + 1) && this.mainList?.scrollToRow(index + 1);
                      if (this.state.data.length > index + 1) {
                        this.dataRefs[index + 1][j]?.current?.focus();
                      }
                    } else if (e.keyCode === 38) {
                      e.preventDefault();
                      !this.isVisibleRow(index + 1) && this.mainList?.scrollToRow(index - 1);
                      if (index > 0) {
                        this.dataRefs[index - 1][j]?.current?.focus();
                      }
                    } else if (e.keyCode === 9 && e.shiftKey) {
                      e.preventDefault();
                      if (this.visibleHeaders.length > j + 1) {
                        let last = j;
                        this.dataRefs[index].forEach((c, l) => {
                          if (c.current && l < j) {
                            last = l;
                          }
                        });
                        this.dataRefs[index][last]?.current?.focus();
                      }
                    } else if (e.keyCode === 9) {
                      e.preventDefault();
                      if (j > 0) {
                        this.dataRefs[index][this.dataRefs[index]
                          .findIndex((c, l) => c.current && l > j)]?.current?.focus();
                      }
                    }
                  }}
                >
                  {calcBackgroundColor && <div
                    className={style.cover}
                    style={{ backgroundColor: calcBackgroundColor }}
                  />}
                  {rowItem}
                </FlexLayout>
              ) : (
                <FlexLayout
                  className={style.overflowHidden}
                  align="center"
                  justify="center"
                  style={{
                    fontSize: 12,
                    width,
                    maxWidth: width,
                    minWidth: width || 100,
                    backgroundColor,
                  }}
                >
                  {calcBackgroundColor && <div
                    className={style.cover}
                    style={{ backgroundColor: calcBackgroundColor }}
                  />}
                  {y[x.id]}
                </FlexLayout>
              )}
            </React.Fragment>
          );
        })}

        {this.state.isListVerticalScroll && <div style={{ width: 12 }} />}
      </FlexLayout>
    );

    this.preRenderData[index] = result;
    return result;
  }

  private rowRendererForFixed = ({
    key,
    index: i,
    style: rowStyle,
  }: ListRowProps) => {
    const index = this.rowIndex(i);

    if (!this.state.data[index]) {
      return (
        <FlexLayout
          key={key}
          size={this.rowHeightWithPaging}
          style={rowStyle}
          align="center"
          justify="start"
        />
      );
    }

    const y = this.state.data[index];
    const calcBackgroundColor = this.props.rowColor ? this.props.rowColor(y) : '';
    const backgroundColor = index % 2 === 0 ? '#FAFAFA' : '#F2F2F2';

    return (
      <FlexLayout
        key={key}
        className={this.focusedRow === index ? style.highlight : false}
        size={this.rowHeightWithPaging}
        style={rowStyle}
        tabindex={1}
        onDoubleClick={() => {
          this.props.onRowDoubleClick && this.props.onRowDoubleClick(y, index);
        }}
        onClick={() => {
          if (new Date().getTime() - this.lastFocusedTime < 300) return;
          if (this.focusedRow === index) return;
          this.lastFocusedTime = new Date().getTime();
          this.rowSelect(y, index);
          this.setState({ focusedRow: index }, () => this.mainList?.forceUpdateGrid());
        }}
        onFocus={() => {
          if (new Date().getTime() - this.lastFocusedTime < 300) return;
          if (this.focusedRow === index) return;
          this.lastFocusedTime = new Date().getTime();
          this.rowSelect(y, index);
          this.setState({ focusedRow: index }, () => this.mainList?.forceUpdateGrid());
        }}
      >
        {this.visibleHeaders.map((x, j: number) => {
          const width = Math.round((x.width || 100) * this.perPxWidth) + (j === 0 ? this.littleWidth : -2);

          // eslint-disable-next-line max-len
          const rowItem = x.render ? AutoPadding.make(x.render(y, (changes) => this.rowUpdate(index, changes, j), undefined, this.props.scope, index)) : undefined;

          return (<React.Fragment key={x.id}>
              {x.render ? (<FlexLayout
                  className={style.overflowHidden}
                  style={{
                    width, maxWidth: width, minWidth: width || 100, backgroundColor,
                  }}
                >
                  {calcBackgroundColor && <div
                    className={style.cover}
                    style={{ backgroundColor: calcBackgroundColor }}
                  />}
                  {rowItem}
                </FlexLayout>) : (<FlexLayout
                  className={style.overflowHidden}
                  align="center"
                  justify="center"
                  style={{
                    fontSize: 12, width, maxWidth: width, minWidth: width || 100, backgroundColor,
                  }}
                >
                  {calcBackgroundColor && <div
                    className={style.cover}
                    style={{ backgroundColor: calcBackgroundColor }}
                  />}
                  {y[x.id]}
                </FlexLayout>)}
            </React.Fragment>);
        })}

        {this.state.isListVerticalScroll && <div style={{ width: 12 }}/>}
      </FlexLayout>
    );
  }

  private isVisibleRow(i: number): boolean {
    const visibleStart = Math.ceil(this.scrollTop / this.props.rowHeight);
    const visibleEnd = visibleStart + Math.floor((this.scrollBody.current?.clientHeight || 0) / this.props.rowHeight);
    return i >= visibleStart && i <= visibleEnd;
  }

  show = useContextMenu({
    id: 'TABLE_CONTEXT',
  }).show;

  handleContextMenu = (event: React.MouseEvent, item: any) => {
    event.preventDefault();
    this.show(event, {
      props: {
        scope: this,
        item,
      },
    });
  }

  @action
  onContextMenuHandler = ({ props }: ItemParams, action: MENU_ACTION) => {
    const { item, scope } = props;
    if (action === MENU_ACTION.SORT_ASC) {
      scope.sortBy(item.sort || item.id);
    } else if (action === MENU_ACTION.SORT_DESC) {
      scope.sortBy(item.sort || item.id, true);
    } else if (action === MENU_ACTION.LOCK) {
      let index = 0;
      for (let i = 0; i < scope.visibleHeaders.length; i += 1) {
        if (scope.visibleHeaders[i].id === item.id) {
          index = i;
          break;
        }
      }

      scope.setState({ colLock: index + 1 });
      scope.init(false);
    } else {
      scope.setState({ colLock: 0 });
      scope.init(false);
    }
  }

  @action
  async sortBy(key: string, isDesc: boolean = false) {
    if (!this.state.data.length) {
      return;
    }

    if (this.props.infinityHandler) {
      this.props.infinityHandler.sortBy(key, isDesc);
      await this.init();
      return;
    }

    let sorted = [];
    if (isDesc) {
      sorted = Sort.desc(key, this.state.data);
    } else {
      sorted = Sort.asc(key, this.state.data);
    }

    this.preRenderData = [];
    this.setState({
      data: sorted,
    }, () => {
      this.mainList?.forceUpdateGrid();
      this.refLockList.current?.forceUpdateGrid();
    });
  }

  @computed
  getHeader(): ReactNode {
    if (this.preRenderHeader) return this.preRenderHeader;
    this.preRenderHeader = <div className={style.headerContainer}>
      {this.visibleHeaders.map((x, j) => {
        const width = Math.round((x.width || 100) * this.perPxWidth)
          + (j === 0 ? this.littleWidth : -2);

        return (
          <div
            key={j}
            onContextMenu={(e) => this.handleContextMenu(e, x)}
          >
            <FlexLayout
              className={style.header}
              style={{
                width,
                maxWidth: width,
                minWidth: width || 100,
                height: this.props.headerHeight ? this.props.headerHeight : 32,
                color: x.color || 'inherit',
              }}
              align="center"
              justify="center"
            >
              {x.text}
            </FlexLayout>
          </div>
        );
      })}

      {this.state.isListVerticalScroll && <div
        className={style.menuPadding}
        style={{
          width: 10,
          flex: '0 0 10px',
          height: this.props.headerHeight ? this.props.headerHeight : 32,
        }}
      />}
    </div>;
    return this.preRenderHeader;
  }

  render() {
    const {
      className,
    } = this.props;

    const {
      isHorizontalMouseActive,
    } = this.state;

    const data = this.pageData || [];

    return (
      <FlexLayout
        refs={this.container}
        className={isHorizontalMouseActive ? style.disableDrag : undefined}
        onMouseMove={(e) => this.onMouseMove(e)}
        onMouseLeave={(e) => this.onMouseLeave(e)}
        onKeyDown={(e) => {
          if (e.keyCode === 33 && this.props.isPaging) {
            e.preventDefault();
            this.pageUp();
          } else if (e.keyCode === 34 && this.props.isPaging) {
            e.preventDefault();
            this.pageDown();
          }
        }}
      >
        <FlexLayout
          className={JoinClassName.make([style.container, className])}
          isVertical={true}
          onUnFocus={() => {
            if (this.focusedRow !== undefined) {
              this.preRenderData[this.focusedRow] = undefined;
              this.setState({ focusedRow: this.focusedRow }, () => {
                this.mainList?.forceUpdateGrid();
                this.refLockList.current?.forceUpdateGrid();
              });
            }
          }}
          onFocus={() => {
            if (this.state.focusedRow !== undefined) {
              this.preRenderData[this.state.focusedRow] = undefined;
              this.setState({ focusedRow: undefined }, () => {
                this.mainList?.forceUpdateGrid();
                this.refLockList.current?.forceUpdateGrid();
              });
            }
          }}
        >
          <div
            ref={this.scrollHeader}
            className={style.scrollContainer}
            style={{
              flex: `${this.props.headerHeight ? this.props.headerHeight : 32}px 0 0`,
            }}
          >
            {/* Main header */}
            <div
              ref={this.horizontalScrollHandler}
              className={style.scroller}
            >
              {/* Header */}
              <div className={style.headerContainer}>
                {this.visibleHeaders.map((x, j) => {
                  const width = Math.round((x.width || 100) * this.perPxWidth)
                    + (j === 0 ? this.littleWidth : -2);

                  return (
                    <div
                      key={j}
                      onContextMenu={(e) => this.handleContextMenu(e, x)}
                    >
                      <FlexLayout
                        className={style.header}
                        style={{
                          width,
                          maxWidth: width,
                          minWidth: width || 100,
                          height: this.props.headerHeight ? this.props.headerHeight : 32,
                          color: x.color || 'inherit',
                        }}
                        align="center"
                        justify="center"
                      >
                        {x.text}
                      </FlexLayout>
                    </div>
                  );
                })}

                {this.state.isListVerticalScroll && <div
                  className={style.menuPadding}
                  style={{
                    width: 10,
                    flex: '0 0 10px',
                    height: this.props.headerHeight ? this.props.headerHeight : 32,
                  }}
                />}
              </div>
            </div>
          </div>

          {/* Fixed column header */}
          <div
            className={JoinClassName.make([
              style.scrollContainer,
              style.fixedCol,
            ])}
            style={{
              flex: `${this.props.headerHeight ? this.props.headerHeight : 32}px 0 0`,
              height: `${this.props.headerHeight ? this.props.headerHeight : 32}px`,
              width: this.visibleHeaders.slice(0, this.state.colLock).reduce((a, x) => {
                const width = this.state.isListHorizontalScroll
                  ? x.width
                  : Math.round((x.width || 100) * this.perPxWidth);

                return a + (width || 100);
              }, 0),
            }}
          >
            <div className={style.scroller}>
              {/* Header */}
              {this.getHeader()}
            </div>
          </div>

          {/* Main list */}
          <div
            className={style.scrollContainer}
          >
            <div
              ref={this.scrollBody}
              className={JoinClassName.make([style.scroller, style.body])}
            >
              <InfiniteLoader
                isRowLoaded={(p) => this.isRowLoaded(p)}
                loadMoreRows={() => this.loadMoreRows()}
                rowCount={data.length + (this.props.infinityHandler?.isEnabled ? 1 : 0)}
              >
                {({ onRowsRendered, registerChild }) => (
                  <List
                    ref={(ref) => {
                      registerChild(ref);
                      this.mainList = ref || undefined;
                    }}
                    width={this.scrollHeader.current?.clientWidth || 0}
                    className={style.list}
                    // style={this.props.isPaging ? { overflowY: 'hidden' } : undefined}
                    onRowsRendered={onRowsRendered}
                    onScroll={(e: any) => this.doScrollY(e, true)}
                    height={this.virtualInnerHeight}
                    rowCount={data.length}
                    rowHeight={this.rowHeightWithPaging}
                    rowRenderer={this.rowRenderer}
                    overscanColumnCount={1}
                    overscanRowCount={1}
                  />
                )}
              </InfiniteLoader>
            </div>
          </div>

          {/* Fixed column list */}
          <div
            className={JoinClassName.make([style.scrollContainer, style.fixedColMain])}
            style={{
              top: (this.props.headerHeight ? this.props.headerHeight : 32) + 2,
              bottom: (this.hasTrail ? (this.props.headerHeight ? this.props.headerHeight : 56) : 22) + 2,
              width: this.visibleHeaders.slice(0, this.state.colLock).reduce((a, x) => {
                const width = this.state.isListHorizontalScroll
                  ? x.width
                  : Math.round((x.width || 100) * this.perPxWidth);

                return a + (width || 100);
              }, 0),
            }}
          >
            <div className={JoinClassName.make([style.scroller, style.body])}>
              <List
                ref={this.refLockList}
                width={
                  this.perPxWidth === 1
                    ? this.totalWidth + (this.state.isListVerticalScroll ? 12 : 0)
                    : this.scrollHeader.current?.clientWidth || 0
                }
                className={style.list}
                // style={this.props.isPaging ? { overflowY: 'hidden' } : undefined}
                onScroll={(e: any) => this.doScrollY(e, false)}
                height={this.virtualInnerHeight}
                rowCount={data.length}
                rowHeight={this.rowHeightWithPaging}
                rowRenderer={this.rowRendererForFixed}
                overscanColumnCount={1}
                overscanRowCount={1}
              />
            </div>
          </div>

          {this.hasTrail && <div
            className={style.scrollContainer}
            style={{
              flex: `${this.props.headerHeight ? this.props.headerHeight : 32}px 0 0`,
            }}
          >
            <div
              ref={this.horizontalTrailHandler}
              className={style.scroller}
            >
              {/* Trail */}
              <div className={style.trailContainer}>
                {this.visibleHeaders.map((x, j) => {
                  const width = Math.round((x.width || 100) * this.perPxWidth)
                    + (j === 0 ? this.littleWidth : 0);

                  return (
                    <FlexLayout
                      key={x.id}
                      justify="center"
                      align="center"
                      style={{
                        width,
                        maxWidth: width,
                        minWidth: width,
                        height: this.props.headerHeight ? this.props.headerHeight : 32,
                      }}
                    >
                      {AutoPadding.make(x.trail && x.trail(this.state.data, this.props.scope))}
                    </FlexLayout>
                  );
                })}

                {this.state.isListVerticalScroll && <div
                  className={style.menuPadding}
                  style={{
                    width: 10,
                    flex: '0 0 10px',
                  }}
                />}
              </div>
            </div>
          </div>}

          <FlexLayout className={style.footer} size={24}>
            <FlexLayout align="center" weight={2} className={style.noEvent}>
              <FlexLayout style={{ color: '#818181' }}>
                <div>
                  총 <span style={{ color: '#515151' }}>
                    {Format.number(this.props.infinityHandler?.total || data.length || 0)}
                  </span>행,
                  &nbsp;변경 <span style={{ color: '#515151' }}>{Format.number(this.changedIndexes.length || 0)}</span>행
                </div>
              </FlexLayout>
            </FlexLayout>

            {this.props.isPaging && this.totalPage !== undefined && this.totalPage > 1
              && <FlexLayout align="center" size={this.pageBarContainerWidth} className={style.pageContainer}
            >
              {this.totalPage && this.totalPage > 10 && <FlexLayout
                className={JoinClassName.make([style.page, this.state.page === 1 ? style.active : undefined])}
                onClick={() => this.onPageClick(1)}
              >
                1
              </FlexLayout>}

              {this.totalPage && this.totalPage > 10 && <FlexLayout size={6} className={style.blank}><BsDot /></FlexLayout>}

              {this.makePageArray().map((x) => (
                <FlexLayout
                  key={x}
                  className={JoinClassName.make([style.page, x === this.state.page ? style.active : undefined])}
                  onClick={() => this.onPageClick(x)}
                >
                  {x}
                </FlexLayout>
              ))}

              {this.totalPage && this.totalPage > 10 && <FlexLayout size={6} className={style.blank}><BsDot /></FlexLayout>}

              {this.totalPage && this.totalPage > 10 && <FlexLayout
                className={JoinClassName.make([style.page, (this.totalPage || 1) === this.state.page ? style.active : undefined])}
                onClick={() => this.onPageClick(this.totalPage || 1)}
              >
                {this.totalPage || 1}
              </FlexLayout>}
            </FlexLayout>}

            <FlexLayout size={24} />

            {this.state.isListHorizontalScroll && <FlexLayout align="center" justify="center">
              <FlexLayout size={15}>
                <FlexLayout
                  className={style.thumb}
                  onClick={() => this.onDeltaXEvent(-50)}
                >
                  <MdChevronLeft />
                </FlexLayout>
              </FlexLayout>
              <FlexLayout>
                <div ref={this.scrollHorizontalRail} className={style.rail}>
                  <div
                    ref={this.scrollHorizontalRailThumb}
                    onMouseMove={(e) => this.onHorizontalDragging(e)}
                    style={{
                      width: this.state.railThumbWidth,
                    }}
                  />
                </div>
              </FlexLayout>
              <FlexLayout size={15}>
                <FlexLayout
                  className={style.thumb}
                  onClick={() => this.onDeltaXEvent(50)}
                >
                  <MdChevronRight />
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>}
          </FlexLayout>
        </FlexLayout>

        <Menu id="TABLE_CONTEXT">
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.SORT_ASC)}>
            오름차순 정렬
          </Item>
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.SORT_DESC)}>
            내림차순 정렬
          </Item>
          <Separator />
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.LOCK)}>
            틀 고정
          </Item>
          <Item onClick={(p) => this.onContextMenuHandler(p, MENU_ACTION.UNLOCK)}>
            틀 고정 해제
          </Item>
        </Menu>
      </FlexLayout>
    );
  }
}
