import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { PrepaymentBundleModel } from './PrepaymentBundle.model';
import { PrepaymentBundleTemplate } from './PrepaymentBundle.template';
import { InfinityRetrieve } from '../../../../models/common';
import { TableLayout } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';

interface PrepaymentBundleState {
  yearmon: string;
  spjangcd: string;
  offiyn: string;
  userData: string;
  as_nm: string;

  // data
  data: Array<PrepaymentBundleModel>;
  focused?: PrepaymentBundleModel;
  pushTotalCheck: boolean;
  pushData: Array<PrepaymentBundleModel>;

  // trail
  tot_samt_tot: string;
  tot_tamt_tot: string;
  tot_mamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_05
 * @category 급여일괄전송[출력/메일]
 */
export class PrepaymentBundle extends PageComponent<PageProps, PrepaymentBundleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  updatedRows?: Array<PrepaymentBundleModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    let year: string | number;

    today.getMonth() === 0 ? year = today.getFullYear() - 1
      : year = today.getFullYear(); // 년도

    let month: string | number;
    today.getMonth() === 0 ? month = 12
      : month = today.getMonth(); // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: `${year}${month}`,
      spjangcd: 'ZZ',
      offiyn: '%',

      // data

      // trail
      tot_samt_tot: '0',
      tot_tamt_tot: '0',
      tot_mamt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { user } = this.props.publicStore;
    this.setState({ userData: user.custcd });

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.yearmon,
        spjangcd: this.state.spjangcd,
        offiyn: this.state.offiyn,
        as_nm: this.state.as_nm,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new PrepaymentBundleModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['accnum', 'email'],
        [this.state.focused?.accnum, this.state.focused?.email], type, true) || 0;
      if (this.state.data && this.state.data.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 0);
        this.onRowFocusEvent(this.state.data[0]);
      }
      // 전체 체크
      this.SS({
        tot_samt_tot: this.infinity?.box?.tot_samt_tot || '0',
        tot_tamt_tot: this.infinity?.box?.tot_tamt_tot || '0',
        tot_mamt_tot: this.infinity?.box?.tot_mamt_tot || '0',
      });
      await this.table.current?.update();
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    let chkData: PrepaymentBundleModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new PrepaymentBundleModel({
          ...x,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.printWithElmanManager({
        stmon: this.state.yearmon,
        spjangcd: this.state.spjangcd,
        offiyn: this.state.offiyn,
        items: chkData,
      });
      chkData = [];
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stmon: this.state.yearmon,
      spjangcd: this.state.spjangcd,
      offiyn: this.state.offiyn,
      as_nm: this.state.as_nm,
    });
  }

  @action
  onUpdatedRows(rows: Array<PrepaymentBundleModel>, updatedRows: Array<PrepaymentBundleModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  onRowFocusEvent(item: PrepaymentBundleModel) {
    this.setState({ focused: item });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new PrepaymentBundleModel({
        ...x,
        chk: checked === true ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onButtonEmail() {
    const { actionStore: api } = this.props;

    const chkData: PrepaymentBundleModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push(new PrepaymentBundleModel({
          chk: x.chk,
          perid: x.perid,
          seq: x.seq,
        }));
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
      return;
    }

    await api.fxEmail(
      '',
      '',
      '',
      '',
      'wb_email',
      '',
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.yearmon,
        offiyn: this.state.offiyn,
        items: chkData,
      },
    );
  }

  @action
  async onButtonExcel() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel('wb_excel', {
      spjangcd: this.state.spjangcd,
      stmon: this.state.yearmon,
      offiyn: this.state.offiyn,
      as_nm: this.state.as_nm,
    });
  }

  render() {
    return (
      <PrepaymentBundleTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
