import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  GiSave, ImMinus,
  ImPlus,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import {
  BtnGubun,
  ItemChange,
  BuyRegister,
  BuyRegisterTabDataStateNames,
} from './BuyRegister';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import {
  SearchBinding,
  SearchBindingClt,
  SearchBindingProjects,
} from '../../../../models/common';
import { Format } from '../../../../utils/string';
import style from './BuyRegister.module.scss';

const gubuns = [
  { value: '01', remark: '매입세금계산서' },
  { value: '02', remark: '매입계산서' },
  { value: '03', remark: '매입카드' },
  { value: '04', remark: '기타' },
  { value: '05', remark: '수정세금계산서' },
];

const projectGubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면
 * @window w_tb_ca640
 * @category 매입등록
 */
export const BuyRegisterTemplate: React.FC<TemplateProps<BuyRegister>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={320}>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder={scope.state.data?.new === '0' ? '거래처, 프로젝트, 참고(비고)로 검색하세요' : ''}
                value={scope.state.searchQuery}
                onChange={(value) => scope.setState({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={200}>
              <span>구분</span>
              <ComboBox
                value={scope.state.searchGubun}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '매입세금계산서' },
                  { value: '2', remark: '매입계산서' },
                  { value: '3', remark: '매입카드' },
                  { value: '4', remark: '기타' },
                  { value: '5', remark: '수정세금계산서' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ searchGubun: option.value },
                  () => scope.onRetrieveEvent())}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout
              size={100}
              style={{
                backgroundColor: '#FFFFFF',
                height: 28,
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <CheckBox
                value={scope.state.resuchk === '1'}
                onChange={(v) => scope.setState({ resuchk: v ? '1' : '0' },
                  () => scope.onRetrieveEvent())}
                isDisabledTrackingStateChange={true}
              />

              <FlexLayout
                weight={2.5}
                style={{ marginLeft: 3 }}
              >
                지급포함
              </FlexLayout>
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onOutsourceModal(true)}>
              <div style={{ width: 90 }}>외주인건비</div>
            </Button>

            <Button onClick={() => scope.onPrintorderModal(true)}>
              <div style={{ width: 90 }}>발주서</div>
            </Button>

            <Button onClick={() => scope.onBuyModal(true)}>
              <div style={{ width: 90 }}>매입세금계산서</div>
            </Button>

            <Button onClick={() => scope.onDisbursementModal(true)}>
              <div style={{ width: 90 }}>지출결의/출장</div>
            </Button>

            <Button onClick={() => scope.onPayModal(true)}>
              <div style={{ width: 90 }}>급여</div>
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'mijdate',
                text: '매입일자',
                width: 13,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.imseq_color === '1' ? 'var(--color-blue)' : '' }}
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 9,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.imseq_color === '1' ? 'var(--color-blue)' : '' }}
                  >
                    {x.mijnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '제목',
                width: 38,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    매입건수: {Format.number(scope.state.total)}건
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    style={{ color: x.imseq_color === '1' ? 'var(--color-blue)' : '' }}
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 20,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(scope.state.jcnt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{ color: x.imseq_color === '1' ? 'var(--color-blue)' : '' }}
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '증빙구분',
                width: 20,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(scope.state.notcnt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.imseq_color === '1' ? 'var(--color-blue)' : '' }}
                    >
                    {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.buyList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={1.5}
          disabled={!model?.custcd && model?.isNew}
          disabledText="신규로 추가하거나 매입을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="매입일자"
              isNeed={true}
              footer={<Button
              isMini={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => {
                setData({ isNew: true },
                  () => scope.setState({ newChk: '1' }));
              }}>
                변경
              </Button>}>

              <DateTextBox
                weight={1.5}
                textAlign="center"
                readonly={scope.state.misdateChk === '0' && scope.state.newChk !== '1' }
                format="####-##-##"
                value={!model?.mijdate ? scope.state.enddate : model?.mijdate}
                onChange={(item) => setData({ mijdate: item },
                  scope.state.newChk === '0'
                    ? () => scope.onItemChange(item, ItemChange.mijdate, 'dw_2_itemchanged')
                    : () => scope.onButtonclick(item, BtnGubun.b_mijdate))}
              />
            </LabelBox>

            <LabelBox title="매입번호">
              <TextBox
                textAlign="center"
                readonly={true}
                value={model?.mijnum}
                onChange={(value) => setData({ mijnum: value })}
              />
            </LabelBox>

            <LabelBox title="증빙구분">
              <ComboBox
                value={model?.gubun}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={gubuns?.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ gubun: option.value },
                  () => scope.onItemChange(option.value, ItemChange.gubun, 'dw_2_itemchanged'))}
              />
            </LabelBox>

            <LabelBox
              title="세액공제"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model?.taxreclafi}
                onChange={(value) => setData({ TB_IZ903: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_IZ903',
                  {},
                  true,
                  (item) => {
                    setData({
                      taxreclafi: item.cd,
                      taxrenm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={2}
                value={model?.taxrenm}
                readonly={true}
                onChange={(value) => setData({ taxrenm: value })}
              />
            </LabelBox>

            <LabelBox
              title="비용항목"
              style={{ color: 'var(--color-red)' }}
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.artcd, BtnGubun.b_artcd)}
                >
                  <GiSave />
                </Button>
              }>
              <TextBox
                textAlign="center"
                value={model?.artcd}
                onChange={(value) => setData({ artcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_CA648_ARG',
                  {},
                  true,
                  (item) => {
                    setData({
                      artcd: item.cd,
                      artnm: item.cdnm,
                      acccd: item.uv_arg1,
                      accnm: item.uv_arg2,
                    });
                    scope.onItemChange(item.cd, ItemChange.artcd, 'dw_2_itemchanged');
                  },
                )}
              />
              <TextBox
                weight={1.5}
                value={model?.artnm}
                readonly={true}
                onChange={(value) => setData({ artnm: value })}
              />
            </LabelBox>

            <LabelBox
              title="계정과목"
              style={{ color: 'var(--color-red)' }}
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.acccd, BtnGubun.b_acccd)}
                >
                  <GiSave/>
                </Button>
              }>
              <TextBox
                value={model?.acccd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AC001',
                  {},
                  true,
                  (item) => {
                    setData({
                      acccd: item.cd,
                      accnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.accnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="제목"
              isNeed={true}
            >
              <TextBox
                value={model?.remark}
                weight={1.5}
                textAlign="left"
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>

            <LabelBox
              title="거래처명"
              style={{ color: 'var(--color-red)' }}
              isNeed={true}
            >
              <TextBox
                value={model?.cltcd}
                textAlign="center"
                onChange={(value) => setData({ cltcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  { uv_arg1: '1' },
                  true,
                  (item) => {
                    setData({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    });
                    scope.onItemChange(item.cd, ItemChange.cltcd, 'dw_2_itemchanged');
                  },
                )}
              />
              <TextBox
                value={model?.cltnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="경비증빙여부"
              size={166}
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.proof, BtnGubun.b_proof)}
                >
                  <GiSave/>
                </Button>
              }>
              <OptionBox
                value={model?.proof}
                data={[
                  { value: '1', remark: '증빙' },
                  { value: '0', remark: '미증빙' },
                ]}
                onChange={(item) => setData({ proof: item.value })}
              />
            </LabelBox>

            <LabelBox
              title="부가세여부"
              size={166}
            >
              <OptionBox
                value={model?.taxcls}
                data={[
                  { value: '1', remark: '포함' },
                  { value: '0', remark: '별도' },
                ]}
                onChange={(item) => setData({ taxcls: item.value },
                  () => scope.onItemChange(item.value, ItemChange.cancleflag, 'dw_2_itemchanged'))}
              />
            </LabelBox>

            <LabelBox
              size={180}
              title="자산구분"
            >
              <OptionBox
                value={model?.jsflag}
                data={[
                  { value: 'IG', remark: '일반' },
                  { value: 'IB', remark: '고정자산' },
                ]}
                onChange={(item) => setData({ jsflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="영세율구분">
              <OptionBox
                value={model?.osflag}
                data={[
                  { value: '213', remark: '해당' },
                  { value: '211', remark: '비해당' },
                ]}
                onChange={(item) => setData({ osflag: item.value })}
              />
            </LabelBox>

            <LabelBox
              title="귀속부서"
              style={{ color: 'var(--color-red)' }}
             >
              <TextBox
                value={model?.divicd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.divinm}
                weight={1.3}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="귀속년월">
              <FormatTextBox
                textAlign="center"
                format="####-##"
                value={scope.state.data?.yyyymm}
                onChange={(value) => setData({ yyyymm: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="지급예정일">
              <DateTextBox
                value={model?.schdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ schdate: value })}
              />
            </LabelBox>

            <LabelBox title="지급처리">
              <OptionBox
                value={model?.cancleflag}
                data={[
                  { value: '0', remark: '필요' },
                  { value: '1', remark: '불필요' },
                ]}
                onChange={(item) => setData({ cancleflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="저장시지급처리">
              <OptionBox
                value={model?.etflag}
                data={[
                  { value: '1', remark: '예' },
                  { value: '0', remark: '아니오' },
                ]}
                onChange={(item) => setData({ etflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="지급유형">
              <OptionBox
                value={model?.amtflag}
                data={[
                  { value: 'H', remark: '현금' },
                  { value: 'B', remark: '예금' },
                ]}
                onChange={(item) => setData({ amtflag: item.value })}
              />
            </LabelBox>

            <LabelBox title="발행구분">
              <OptionBox
                value={model?.bhflag}
                data={[
                  { value: 'OD', remark: '전자' },
                  { value: 'AS', remark: '수기' },
                ]}
                onChange={(item) => setData({ bhflag: item.value })}
              />
            </LabelBox>

            <LabelBox
              title="매입구분"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.wkgubun, BtnGubun.b_wkgubun)}
                >
                  <GiSave/>
                </Button>
              }>
              <ComboBox
                value={model?.wkgubun}
                data={scope.state.wkgubuns?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
                onSelect={(option) => setData({ wkgubun: option.value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="지급은행"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={1}
                value={model?.bankcd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_AA040',
                  {},
                  true,
                  (item) => {
                    setData({
                      bankcd: item.cd,
                      banknm: item.cdnm,
                      accnum: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.banknm}
                weight={1.5}
                readonly={true}
              />
              <TextBox
                value={model?.accnum}
                weight={2}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="카드"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model?.cardco}
                onChange={(value) => setData({ cardco: value, isChanged: 'true' })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_IZ010',
                  {},
                  true,
                  (item) => {
                    setData({
                      cardco: item.uv_arg1,
                      cardnm: item.cdnm,
                      cardnum: item.cd,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="center"
                value={model?.cardnm}
                onChange={(value) => setData({ cardnm: value, isChanged: 'true' })}
                readonly={true}
              />
              <TextBox
                weight={1.5}
                textAlign="center"
                value={model?.cardnum}
                onChange={(value) => setData({ cardnum: value, isChanged: 'true' })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="미지급거래처"
              isNeed={true}
              style={{ color: 'var(--color-red)' }}
              size={336}
            >
              <TextBox
                size={90}
                textAlign="center"
                value={model?.mijcltcd}
                onChange={(value) => setData({ mijcltcd: value, isChanged: 'true' })}
                bindSearchModal={new SearchBindingClt(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      mijcltcd: item.cltcd,
                      mijcltnm: item.cltnm,
                      cltcd: item.cltcd,
                      cltnm: item.cltnm,
                      cltflag: item.gubun,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.mijcltnm}
                onChange={(value) => setData({ mijcltnm: value, isChanged: 'true' })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="프로젝트"
              size={500}
              style={{ color: 'var(--color-red)' }}
              footer={
                <FlexLayout>
                  <FlexLayout style={{ marginRight: 310 }}>
                    <Button
                      isIcon={true}
                      style={{
                        fontSize: 16,
                        marginTop: -1,
                      }}
                      onClick={() => scope.onProjectEvent(true)}
                    >
                      <ImPlus/>
                    </Button>
                  </FlexLayout>

                  <FlexLayout>
                    <Button
                      isIcon={true}
                      style={{ fontSize: 22 }}
                      onClick={() => scope.onButtonclick(model.projno, BtnGubun.b_projno)}
                    >
                      <GiSave/>
                    </Button>
                  </FlexLayout>
                </FlexLayout>
              }
            >
              <TextBox
                size={120}
                textAlign="center"
                value={model?.projno}
                onChange={(value) => setData({ projno: value, isChanged: 'true' })}
                onEnter={() => scope.onButtonclick(model.projnm, BtnGubun.b_projnoadd)}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      projno: item.projno,
                      projnm: item.projnm,
                      actcd: item.actcd,
                      actnm: item.actnm,
                    });
                    scope.projectChange(item);
                  },
                )}
              />
              <TextBox
                value={model?.projnm}
                onChange={(value) => setData({ projnm: value })}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="총매입금액">
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.mijamt}
                onChange={(value) => setData({ mijamt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              size={336}
              title="참고(비고)"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(model.bigo, BtnGubun.b_bigo)}
                >
                  <GiSave/>
                </Button>
              }>
              <TextBox
                value={model?.bigo}
                onChange={(value) => setData({ bigo: value })}
              />
            </LabelBox>

            <LabelBox
              title="현장명"
              style={{ color: 'var(--color-red)' }}
              size={500}
            >
              <TextBox
                size={120}
                value={scope.state.data?.actcd}
                textAlign="center"
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={scope.state.data?.actnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="지급액"
              footer={
                <FlexLayout style={{ color: scope.state.sndamt === 0 ? 'var(--color-blue)' : 'var(--color-red)' }}>
                  {scope.state.sndamt === 0 ? '지급됨' : '미지급'}
                </FlexLayout>
              }>
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.sndamt}
                onChange={(value) => setData({ sndamt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout weight={5}>
        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />

            <Button
              style={{
                height: 25,
                width: 90,
                marginTop: 12,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onSubCardExcelEvent()}>
              신용카드 엑셀
            </Button>

            <Button
              style={{
                height: 25,
                width: 90,
                marginTop: 12,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onSubExcelEvent()}>
              EXCEL 자료
            </Button>

            <Button
              style={{
                height: 25,
                width: 90,
                marginTop: 12,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.subNewEvent()}>
              추가
            </Button>

            <Button
              style={{
                height: 25,
                width: 90,
                marginTop: 12,
                marginRight: 10,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.subDeleteEvent()}>
              삭제
            </Button>
          </FlexLayout>

          <FlexLayout
            disabled={!model?.custcd && model?.isNew}
            disabledText="신규로 추가하거나 매입을 선택해주세요"
          >
            <TableLayout
              ref={scope.table}
              scope={scope}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.tapUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              // @ts-ignore
              data={scope.state[BuyRegisterTabDataStateNames[scope.tabIndex]] || []}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.outsourceModal}
        onClose={() => scope.onOutsourceModal(false)}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <SearchLayout>
              <FlexLayout size={300}>
                <span>검색어</span>
                <TextBox
                  value={scope.state.popupSearchQuery}
                  onChange={(value) => scope.setState({ popupSearchQuery: value })}
                  onEnter={() => scope.onOutsourceRetrive()}
                />
              </FlexLayout>

              <LayoutTitle size={500}>설치인건비 불러오기</LayoutTitle>
            </SearchLayout>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.table2}
              header={[
                {
                  id: 'chk',
                  text: (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={scope.state.outsourceCheck}
                        onChange={async (value) => scope
                          .updateOutsourceChk(value)}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                  width: 5,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={x.chk === '1'}
                        onChange={(value) => {
                          rowUpdate({ chk: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'schdate',
                  text: '지급예정일',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.schdate)}
                    </FlexLayout>
                  ),

                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 22,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명(지급자)',
                  width: 11,
                },
                {
                  id: 'amt',
                  text: '기성총금액',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.amt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seqnm',
                  text: '순서',
                  width: 7,
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'projnm',
                  text: '프로젝트명',
                  width: 25,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.projnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.outsourceData}
              infinityHandler={scope.infinity2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows2) => {
                scope.onOutsourceUpdated(rows, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onOutsourceURowFocus(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="end"
              align="center"
              size={200}
              style={{ marginLeft: 800 }}
            >
              <span>일자</span>
              <DateTextBox
                textAlign="center"
                format="####-##-##"
                value={scope.state.outsourceDate}
                onChange={(item) => scope.setState({ outsourceDate: item })}
              />
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalOutsourceSave()}>확인</Button>
              <Button onClick={() => scope.onOutsourceModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.printorderModal}
        onClose={() => scope.onPrintorderModal(false)}
        width={1100}
        height={800}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>발주서 불러오기</LayoutTitle>

          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            noMarginTop={true}
          >
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.printorderSdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ printorderSdate: value })}
                onEnter={() => scope.onPrintorderModalRetrive()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.printorderSdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ printorderSdate: Date8.make(date) },
                      () => scope.onPrintorderModalRetrive());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.printorderSdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ printorderSdate: Date8.make(date) },
                      () => scope.onPrintorderModalRetrive());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.printorderEdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ printorderEdate: value })}
                onEnter={() => scope.onPrintorderModalRetrive()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.printorderEdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ printorderEdate: Date8.make(date) },
                      () => scope.onPrintorderModalRetrive());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.printorderEdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ printorderEdate: Date8.make(date) },
                      () => scope.onPrintorderModalRetrive());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span>검색어</span>
              <TextBox
                value={scope.state.printorderSearch}
                onChange={(value) => scope.setState({ printorderSearch: value })}
                onEnter={() => scope.onPrintorderModalRetrive()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onPrintorderModalRetrive()}>
              <div style={{ width: 80 }}>조회</div>
            </Button>

            <Button onClick={() => scope.onPrintorderDelete()}>
              <div style={{ width: 80 }}>발주내역삭제</div>
            </Button>
          </SearchLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.table3}
              header={[
                {
                  id: 'chk',
                  text: (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={scope.state.printorderCheck}
                        onChange={async (value) => scope
                          .updatePrintorderAllChk(value)}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={x.chk === '1'}
                        onChange={(value) => {
                          rowUpdate({ chk: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'baldate',
                  text: '발주일자',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.baldate}
                    />
                  ),
                },
                {
                  id: 'balnum',
                  text: '번호',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.balnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.cltnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'projno',
                  text: (
                    <FlexLayout>
                      <FlexLayout>
                        프로젝트
                      </FlexLayout>

                      <Button onClick={() => scope.onPrintorderBtn()}>
                        <FlexLayout>
                          <ImPlus/>
                        </FlexLayout>
                      </Button>
                    </FlexLayout>
                  ),
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.projno}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ projno: value })}
                      bindSearchModal={new SearchBindingProjects(
                        scope.props.modalStore,
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            projno: item.projno,
                            projnm: item.projnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'projnm',
                  text: '프로젝트명',
                  width: 200,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      textAlign="center"
                      value={x.projnm}
                      onChange={(value) => rowUpdate({ projnm: value })}
                      onEnter={(value) => scope?.onItemChange(value, ItemChange.qty, 'dw_3_itemchanged')}
                    />
                  ),
                },
                {
                  id: 'pname',
                  text: '품명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '수량',
                  width: 75,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.qty}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 150,
                  render: (x, _, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.uamt}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.samt}
                    />
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.tamt}
                    />
                  ),
                },
                {
                  id: 'deldate',
                  text: '납기일',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.deldate}
                    />
                  ),
                },
                {
                  id: 'bigo',
                  text: '납품장소',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.bigo}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.printorderData}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows3, updatedRows3) => {
                scope.onPrintorderRowUpdated(rows3, updatedRows3);
              }}
              onRowFocusEvent={(item, index) => scope.onPrintorderRowFocus(item, index)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="end"
              align="center"
              size={200}
              style={{ marginLeft: 700 }}
            >
              <span>일자</span>
              <DateTextBox
                value={scope.state?.printorderDate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ printorderDate: value })}
              />
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onPrintorderSave()}>확인</Button>
              <Button onClick={() => scope.onPrintorderModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.buyModal}
        onClose={() => scope.onBuyModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <OptionBox
              size={380}
              value={scope.state?.choice}
              data={[
                { value: '1', remark: '매입세금계산서로 넘어가기' },
                { value: '0', remark: '출력' },
              ]}
              onChange={(item) => scope.setState({ choice: item.value })}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onBuyChoice(scope.state.choice)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.disbursementModal}
        onClose={() => scope.onDisbursementModal(false)}
        width={520}
        height={150}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>선택</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <OptionBox
              size={270}
              value={scope.state?.actnm}
              data={[
                { value: '1', remark: '지출결의서' },
                { value: '0', remark: '출장보고서' },
              ]}
              onChange={(item) => scope.setState({ actnm: item.value })}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.constructionChoice(scope.state?.actnm)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.disbursementModal2}
        onClose={() => scope.onDisbursementModal2(false)}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>지출결의서 불러오기</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={571}>
              <span>검색어</span>
              <TextBox
                value={scope.state.disbursementSearch}
                onChange={(value) => scope.setState({ disbursementSearch: value })}
                onEnter={() => scope.onDisbursementRetrive('w_popup_ca642_ae200')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onDisbursementDelete()}>
              <div style={{ width: 80 }}>삭제</div>
            </Button>
          </SearchLayout>

          <FlexLayout>
            <FlexLayout>
              <TableLayout
                ref={scope.table4}
                header={[
                  {
                    id: 'inputdate',
                    text: '지출작성일자',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Date8.withDash(x.inputdate)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'inputnum',
                    text: '번호',
                    width: 7,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.inputnum}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'spjangnm',
                    text: '사업자명',
                    width: 10,
                  },
                  {
                    id: 'pernm',
                    text: '출장자명',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.pernm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'mamt',
                    text: '금액',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.mamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'subject',
                    text: '제목',
                    width: 45,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.subject}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'chk',
                    text: (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          noMargin
                          value={scope.state.disbursementCheck}
                          onChange={async (value) => scope
                            .updateDisbursementChk(value)}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                    width: 8,
                    render: (x, rowUpdate) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          noMargin
                          value={x.chk === '1'}
                          onChange={(value) => {
                            rowUpdate({ chk: value ? '1' : '0' });
                          }}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.disbursementData}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={scope.infinity5}
                onChange={(rows, updatedRows4) => {
                  scope.onDisbursementRowUpdated(rows, updatedRows4);
                }}
                onRowFocusEvent={(item) => scope.onDisbursementRowFocus(item)}
              />
            </FlexLayout>

            <FlexLayout
              weight={0.5}
              isVertical={true}
            >
              <FlexLayout weight={0.01}>
                <LayoutTitle size={400}>내용</LayoutTitle>
              </FlexLayout>

              <FlexLayout>
                <TextBox
                  isMultiline={true}
                  value={scope.state.disbursementFocused?.remark}
                  onChange={(value) => setData({ remark: value })}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="start"
              align="center"
              size={500}
              style={{ marginLeft: 500 }}
            >
              <FlexLayout size={200}>
                <span>일자</span>
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={scope.state.disbursementDate}
                  onChange={(item) => scope.setState({ disbursementDate: item })}
                />
              </FlexLayout>

              <FlexLayout size={250}>
                <span style={{ color: 'var(--color-red)' }}>비용항목</span>
                <TextBox
                  textAlign="center"
                  style={{
                    backgroundColor: '#FFFFFF',
                    marginLeft: 10,
                  }}
                  value={scope.state.disbursementArtcd}
                  onChange={(value) => scope.setState({ disbursementArtcd: value })}
                  bindSearchModal={
                    new SearchBinding(
                      scope.props.modalStore,
                      'TB_CA648_ARG',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          disbursementArtcd: item.cd,
                          disbursementartnm: item.cdnm,
                        });
                      },
                    )}
                />
                <TextBox
                  weight={1.5}
                  value={scope.state.disbursementartnm}
                  readonly={true}
                  onChange={(value) => scope.setState({ disbursementartnm: value })}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onDisbursementSave()}>확인</Button>
              <Button onClick={() => scope.onDisbursementModal2(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.disbursementModal3}
        onClose={() => scope.onDisbursementModal2(false)}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>출장 불러오기</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={571}>
              <span>검색어</span>
              <TextBox
                value={scope.state.businessTripSearch}
                onChange={(value) => scope.setState({ businessTripSearch: value })}
                onEnter={() => scope.onDisbursementRetrive('w_popup_ca642_pb401')}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout/>
            <Button onClick={() => scope.onBusinessTripDelete()}>
              <div style={{ width: 80 }}>삭제</div>
            </Button>
          </SearchLayout>

          <FlexLayout>
            <FlexLayout>
              <TableLayout
                ref={scope.table5}
                header={[
                  {
                    id: 'chk',
                    text: (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          noMargin
                          value={scope.state.businessTripCheck}
                          onChange={async (value) => scope
                            .updateBusinessTripChk(value)}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                    width: 5,
                    render: (x, rowUpdate) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          noMargin
                          value={x.chk === '1'}
                          onChange={(value) => {
                            rowUpdate({ chk: value ? '1' : '0' });
                          }}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'enddate',
                    text: '출장작성일자',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Date8.withDash(x.enddate)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'outnum',
                    text: '번호',
                    width: 7,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.outnum}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'spjangnm',
                    text: '사업자명',
                    width: 10,
                  },
                  {
                    id: 'pernm',
                    text: '출장자명',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.pernm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'mamt',
                    text: '금액',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.mamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'title',
                    text: '제목',
                    width: 24,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.title}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장',
                    width: 24,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.businessTripData}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={scope.infinity6}
                onChange={(rows, updatedRows5) => {
                  scope.onBusinessTripRowUpdated(rows, updatedRows5);
                }}
                onRowFocusEvent={(item) => scope.onBusinessTripRowFocus(item)}
              />
            </FlexLayout>

            <FlexLayout
              weight={0.5}
              isVertical={true}
            >
              <FlexLayout weight={0.01}>
                <LayoutTitle size={100}>기간</LayoutTitle>
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {/* eslint-disable-next-line max-len */}
                  {Date8.withDash(scope.state.businessTripFocused?.outdate)} ~ {Date8.withDash(scope.state.businessTripFocused?.enddate)}
                </FlexLayout>
              </FlexLayout>

              <FlexLayout weight={0.01}>
                <LayoutTitle size={400}>내용</LayoutTitle>
              </FlexLayout>

              <FlexLayout>
                <TextBox
                  isMultiline={true}
                  value={scope.state.businessTripFocused?.remark}
                  onChange={(value) => setData({ remark: value })}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="start"
              align="center"
              size={500}
              style={{ marginLeft: 500 }}
            >
              <FlexLayout size={200}>
                <span>일자</span>
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={scope.state.businessTripDate}
                  onChange={(item) => scope.setState({ businessTripDate: item })}
                />
              </FlexLayout>

              <FlexLayout size={250}>
                <span style={{ color: 'var(--color-red)' }}>비용항목</span>
                <TextBox
                  textAlign="center"
                  style={{
                    backgroundColor: '#FFFFFF',
                    marginLeft: 10,
                  }}
                  value={scope.state.businessTripArtcd}
                  onChange={(value) => scope.setState({ businessTripArtcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_CA648_ARG',
                    {},
                    true,
                    (item) => {
                      scope.setState({
                        businessTripArtcd: item.cd,
                        businessTriPartnm: item.cdnm,
                      });
                    },
                  )}
                />
                <TextBox
                  weight={2}
                  value={scope.state.businessTriPartnm}
                  readonly={true}
                  onChange={(value) => scope.setState({ businessTriPartnm: value })}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onBusinessTripSave()}>확인</Button>
              <Button onClick={() => scope.onDisbursementModal3(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.payModal}
        onClose={() => scope.onPayModal(false)}
        width={900}
        height={800}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>급여불러오기</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={200}>
              <span>월</span>
              <DateTextBox
                value={scope.state.payYearmon}
                textAlign="center"
                format="####년 ##월"
                onChange={(value) => {
                  scope.setState({ payYearmon: value },
                    () => scope.onPayModalRetrive());
                }}
                onEnter={() => scope.onPayModalRetrive()}
                head={(<button
                  onClick={() => {
                    scope.setState({
                      payYearmon: new Date6Calculator(scope.state.payYearmon).add(-1).toString(),
                    },
                    () => scope.onPayModalRetrive());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    scope.setState({
                      payYearmon: new Date6Calculator(scope.state.payYearmon).add().toString(),
                    },
                    () => scope.onPayModalRetrive());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={200}>
              <span>직원구분</span>
              <div>
              <OptionBox
                value={scope.state.offiyn}
                data={[
                  { value: '1', remark: '임원' },
                  { value: '0', remark: '직원' },
                ]}
                onChange={(item) => scope.setState({
                  offiyn: String(item.value),
                  artcd: item.value === '1' ? '0102' : '0101',
                  artnm: item.value === '1' ? '임원급여' : '직원급여',
                  acccd: item.value === '1' ? '7112' : '7111',
                  accnm: item.value === '1' ? '임원급여' : '직원급여와임금',
                },
                () => scope.onPayModalRetrive())}
              />
              </div>
            </FlexLayout>

            <FlexLayout size={300}>
              <span>검색어(성명)</span>
              <TextBox
                value={scope.state.paySearch}
                onChange={(value) => scope.setState({ paySearch: value })}
                onEnter={() => scope.onPayModalRetrive()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <FlexLayout>
              <TableLayout
                ref={scope.table6}
                header={[
                  {
                    id: 'chk',
                    text: (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          noMargin
                          value={scope.state.pushTotalCheck}
                          onChange={async (value) => scope
                            .updateCheckAllToggle(value)}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                    width: 6,
                    render: (x, rowUpdate) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          noMargin
                          value={x.chk === '1'}
                          onChange={(value) => {
                            rowUpdate({ chk: value ? '1' : '0' });
                          }}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'mon',
                    text: '월',
                    width: 8,
                    render: (x) => (
                      <FormatTextBox
                        textAlign="center"
                        format="####-##"
                        value={x.mon}
                      />
                    ),
                  },
                  {
                    id: 'rspnm',
                    text: '직책',
                    width: 8,
                  },
                  {
                    id: 'pernm',
                    text: '성명',
                    width: 8,
                    trail: () => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        합계
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tot_samt',
                    text: '월간연봉급여',
                    width: 10,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.tot_samt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.tot_samt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tot_tamt',
                    text: '공제금액',
                    width: 10,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {scope.state.tot_tamt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-red)' }}
                      >
                        {Format.number(x.tot_tamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tot_mamt',
                    text: '실수령액',
                    width: 10,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {scope.state.tot_mamt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(x.tot_mamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'remark',
                    text: '제목',
                    width: 40,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.remark}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.payData}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={scope.infinity4}
                onChange={(rows, updatedRows6) => {
                  scope.modalPayUpdated(rows, updatedRows6);
                }}
              />
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout
              justify="start"
              align="center"
              size={700}
            >
              <FlexLayout size={160}>
                <span>일자</span>
                <DateTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={scope.state.payDate}
                  onChange={(item) => scope.setState({ payDate: item })}
                />
              </FlexLayout>

              <FlexLayout size={250}>
                <span style={{ color: 'var(--color-red)' }}>비용항목</span>
                <TextBox
                  textAlign="center"
                  value={scope.state.artcd}
                  style={{
                    backgroundColor: '#FFFFFF',
                    marginLeft: 10,
                  }}
                  onChange={(value) => scope.setState({ artcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_CA648_ARG',
                    {},
                    true,
                    (item) => {
                      scope.setState({
                        artcd: item.cd,
                        artnm: item.cdnm,
                      });
                    },
                  )}
                />
                <TextBox
                  weight={1.5}
                  value={scope.state.artnm}
                  readonly={true}
                  onChange={(value) => scope.setState({ artnm: value })}
                />
              </FlexLayout>

              <FlexLayout size={250}>
                <span
                  style={{
                    color: 'var(--color-red)',
                    marginLeft: 10,
                  }}
                >
                  거래처
                </span>

                <TextBox
                  textAlign="center"
                  value={scope.state.cltcd}
                  style={{
                    backgroundColor: '#FFFFFF',
                    marginLeft: 10,
                  }}
                  onChange={(value) => scope.setState({ cltcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_XCLIENT',
                    { uv_arg1: '1' },
                    true,
                    (item) => {
                      scope.setState({
                        cltcd: item.cd,
                        cltnm: item.cdnm,
                      });
                    },
                  )}
                />
                <TextBox
                  weight={1.5}
                  value={scope.state.cltnm}
                  onChange={(value) => scope.setState({ cltnm: value })}
                  readonly={true}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalPaySave()}>확인</Button>
              <Button onClick={() => scope.onPayModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={1200}
        height={500}
        isVisible={scope.state.ProjectModal}
        onClose={() => scope.onProjectEvent(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={430}>
              <span style={Global.STYLE_COLOR_RED}>현장코드</span>
              <TextBox
                weight={0.5}
                textAlign="center"
                value={scope.state.projectActcd}
                onChange={(value) => setData({ projectActcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      projectActcd: item.cd,
                      projectActnm: item.cdnm,
                    }, () => scope.onRetrieveEvent());
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                value={scope.state.projectActnm}
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout
              align="center"
              justify="center"
            >
              프로젝트생성[견적]
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'costdate',
                  text: '견적일자',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.costdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'costnum',
                  text: '번호',
                  width: 6,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.costnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'gubun',
                  text: '공사구분',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                    >
                      {projectGubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 19,
                  render: (x) => (
                    <FlexLayout>
                      {
                        !x.costnum ? ''
                          : <Button
                            style={{
                              marginTop: 5,
                              marginRight: 5,
                              width: 25,
                              height: 25,
                            }}
                            onClick={() => scope.onProjectSubBtn(!x.stateBtn || x.stateBtn === '0' ? '0' : '1')}>
                            {x.stateBtn === '0'
                              ? <FlexLayout>
                                <ImMinus/>
                              </FlexLayout>

                              : x.stateBtn === '1'
                                ? <FlexLayout>
                                   <ImPlus/>
                                  </FlexLayout>

                                : x.stateBtn === '0'
                                  ? <FlexLayout>
                                     <ImMinus/>
                                    </FlexLayout>

                                  : <FlexLayout>
                                     <ImMinus/>
                                    </FlexLayout>
                            }
                          </Button>
                      }

                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                      >
                        {x.actnm}
                      </FlexLayout>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'constnm',
                  text: '견적서명',
                  width: 22,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                    >
                      {x.constnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                    >
                      {Format.number(x.samt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                    >
                      {Format.number(x.tamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mamt',
                  text: '합계금액',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                    >
                      {Format.number(x.mamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'saleamt',
                  text: '할인금액',
                  width: 13,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: !x.costnum ? '#7F7A2C' : '' }}
                    >
                      {Format.number(x.saleamt)}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.projectData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowClick={(item) => scope.onProjectRowFocus(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onProjectSave()}>확인</Button>
              <Button onClick={() => scope.onProjectEvent(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={570}
        height={500}
        isVisible={scope.state.cardView}
        onClose={() => scope.SS({ cardView: false })}
      >
        <FlexLayout
          className={style.modalCardImage}
          onClick={() => scope.SS({ cardView: false })}
        />
        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope?.openCardExcelFile()}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  );
};
