import { computed } from 'mobx';
import { Today } from '../../../../../utils/time';

/**
 * 모델
 * 검사이력정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class PersonPopupModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  actcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** varchar(3)
   */
  qty: string;

  /**
   * 지역명
   *
   * **data type** varchar(100)
   */
  areanm: string;

  /**
   * 주소
   *
   * **data type** varchar(1)
   */
  address: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(100)
   */
  perid: string

  /**
   * 시작일자
   *
   * **data type** varchar(1)
   */
  stdate: string;

  /**
   * 종료일자
   *
   * **data type** varchar(1)
   */
  enddate: string;

  /**
   * 담당자명
   *
   * **data type** varchar(1)
   */
  pernm: string;

  /**
   * 변경자명
   * **data type** varchar(1)
   */
  inpernm: string;

  /**
   * 순서
   * **data type** varchar(1)
   */
  seq: string;


  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  cltnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  cltcd: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  contdate: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  contflag: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  actmail: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  actdate: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  taxmail: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  taxdate: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * ?
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.seq = data.seq || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.areanm = data.areanm || '';
    this.address = data.address || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.inpernm = data.inpernm || '';
    this.remark = data.remark || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.contdate = data.contdate || '';
    this.contflag = data.contflag || '';
    this.actmail = data.actmail || '';
    this.actdate = data.actdate || '';
    this.taxmail = data.taxmail || '';
    this.taxdate = data.taxdate || Today.yearMon();
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
