import { computed } from 'mobx';
import { Fix } from '../../../../utils/string';

/**
 * 모델
 * @window w_tb_callmainw
 * @category 전화수신목록
 */
export class TellReceiveModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순번
   *
   * **data type** varchar(15)
   */
  seq: string;

  /**
   * 발신번호
   *
   * **data type** varchar(20)
   */
  callnum: string;

  /**
   * 수신포트번호
   *
   * **data type** varchar(50)
   */
  port: string;

  /**
   * 수신일자
   *
   * **data type** varchar(15)
   */
  calldate: string;

  /**
   * 시간
   *
   * **data type** varchar(50)
   */
  calltime: string;

  /**
   * 팩스
   *
   * **data type** varchar(15)
   */
  callfax: string;

  /**
   * 고객명
   *
   * **data type** varchar(100)
   */
  artnm: string;

  /**
   * 담당자
   *
   * **data type** char(10)
   */
  perid: string;

  /**
   * 수신자명
   *
   * **data type** varchar(20)
   */
  pernm: string;

  /**
   * 고객번호
   *
   * **data type** char(20)
   */
  actcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(60)
   */
  actnm: string;

  /**
   * 내용
   *
   * **data type** 모름
   */
  answerflag: string;

  /**
   * 연결코드
   *
   * **data type** varchar(50)
   */
  cltcd: string;

  /**
   * 연결명
   *
   * **data type** varchar(100)
   */
  cltnm: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  flag: string;

  /**
   * 현장,거래처구분
   *
   * **data type** char(1)
   */
  custflag: string;

  /**
   * 등록일
   *
   * **data type** char(10)
   */
  regdate: string;

  /**
   * 고객명
   *
   * **data type** 모름
   */
  callnm: string;

  /**
   * 수신자주소
   *
   * **data type** varchar(50)
   */
  calladdr: string;

  /**
   * 수신자메일
   *
   * **data type** varchar(50)
   */
  callemail: string;

  /**
   * 수신메모
   *
   * **data type** text
   */
  callmemo: string;

  /**
   * 비고(담당자)
   *
   * **data type** text
   */
  remark: string;

  /**
   * 처리내용(비고)
   *
   * **data type** text
   */
  callendmemo: string;

  /**
   * X
   *
   * **data type** char(1)
   */
  rcvflag: string;

  /**
   * 접수일자
   *
   * **data type** char(8)
   */
  recedate: string;

  /**
   * 접수번호
   *
   * **data type** char(3)
   */
  recenum: string;

  /**
   * 수신자우편번호
   *
   * **data type** varchar(6)
   */
  callzipcd: string;

  /**
   * 접수여부
   *
   * **data type** varchar(1)
   */
  okflag: string;

  /**
   * 영업담당자
   *
   * **data type** varchar(10)
   */
  busiperid: string;

  /**
   * 영업담당자명
   *
   * **data type** varchar(100)
   */
  busipernm: string;

  /**
   * 영업구분
   *
   * **data type** varchar(1)
   */
  busiflag: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(20)
   */
  projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(255)
   */
  projnm: string;

  /**
   * 민원일자
   *
   * **data type** varchar(8)
   */
  recedate_civil: string;

  /**
   * 민원번호
   *
   * **data type** varchar(4)
   */
  recenum_civil: string;

  /**
   * 영업일자
   *
   * **data type** varchar(8)
   */
  recedate_busi: string;

  /**
   * 영업번호
   *
   * **data type** varchar(4)
   */
  recenum_busi: string;

  /**
   * 수신번호
   *
   * **data type** varchar(20)
   */
  callee: string;

  /**
   * 기간
   * **data type** datetime
   */
  stdatetime: string;

  /**
   * 기간 2
   * **data type** datetime
   */
  enddatetime: string;

  /**
   * 시간구분
   * **data type** varchar(10)
   */
  timeflag: string;

  /**
   * 그룹분류
   * **data type** varchar(10)
   */
  groupcd: string;

  /**
   * 전화구분
   * **data type** varchar(10)
   */
  telcd: string;

  gartcd: string;

  artcd: string;

  useyn: string;

  /**
   * 수신결과
   * **data type** varchar(10)
   */
  kind: string;

  /**
   * ai여부
   * **data type** varchar(10)
   */
  aiflag: string;

  // 수신메모
  telstatenm: string;

  telstate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.seq = data.seq || '';
    this.callnum = data.callnum || '';
    this.port = data.port || '';
    this.calldate = data.calldate || '';
    this.calltime = data.calltime || '';
    this.callfax = data.callfax || '';
    this.artnm = data.artnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.answerflag = data.answerflag || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.flag = data.flag || '';
    this.custflag = data.custflag || '';
    this.regdate = data.regdate || '';
    this.callnm = data.callnm || '';
    this.calladdr = data.calladdr || '';
    this.callemail = data.callemail || '';
    this.callmemo = Fix.newline(data.callmemo) || '';
    this.remark = Fix.newline(data.remark) || '';
    this.callendmemo = Fix.newline(data.callendmemo) || '';
    this.rcvflag = data.rcvflag || '';
    this.recedate = data.recedate || '';
    this.recenum = data.recenum || '';
    this.callzipcd = data.callzipcd || '';
    this.okflag = data.okflag || '';
    this.busiperid = data.busiperid || '';
    this.busipernm = data.busipernm || '';
    this.busiflag = data.busiflag || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.recedate_civil = data.recedate_civil || '';
    this.recenum_civil = data.recenum_civil || '';
    this.recedate_busi = data.recedate_busi || '';
    this.recenum_busi = data.recenum_busi || '';
    this.callee = data.callee || '';
    this.stdatetime = data.stdatetime || '';
    this.enddatetime = data.enddatetime || '';
    this.timeflag = data.timeflag || '';
    this.groupcd = data.groupcd || '';
    this.telcd = data.telcd || '';
    this.new = isNew ? '1' : '0';
    this.gartcd = data.gartcd || '';
    this.artcd = data.artcd || '';
    this.useyn = data.useyn || '';
    this.kind = data.kind || '';
    this.aiflag = data.aiflag || '';
    this.telstatenm = data.telstatenm || '';
    this.telstate = data.telstate || '';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
