/**
 * 모델
 * @window w_tb_e300
 * @category 민원관리코드
 */
import { computed } from 'mobx';

export class ComplaintModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 민원코드
   *
   * **data type** varchar(4)
   */
  civilcd: string;

  /**
   * 민원명
   *
   * **data type** varchar(100)
   */
  civilnm: string;

  /**
   * 순번
   *
   * **data type** varchar(2)
   */
  seq: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 민원구분
   *
   * **data type** varchar(1)
   */
  flag: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.civilcd = data.civilcd || '';
    this.civilnm = data.civilnm || '';
    this.seq = data.seq || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.flag = data.flag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
