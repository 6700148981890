import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * 현장정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentActModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발령코드
   *
   * **data type** varchar(2)
   */
  ordercd: string;

  /**
   * 발령명
   *
   * **data type** varchar(20)
   */
  ordernm: string;

  /**
   * 발령명
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 발령명
   *
   * **data type** varchar(100)
   */
  seq: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  enddate: string;

  /**
   * 현장이름
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 현장담당자
   *
   * **data type** varchar(10)
   */
  actperid: string;

  /**
   * 현장담당자명
   *
   * **data type** varchar(50)
   */
  actpernm: string;

  /**
   * 설치현장
   *
   * **data type** varchar(255)
   */
  scactnm: string;

  /**
   * 계약상태
   *
   * **data type** varchar(2)
   */
  contg: string;

  /**
   * 시작구분
   *
   * **data type** varchar(20)
   */
  state: string;

  /**
   * 관리수
   *
   * **data type** float
   */
  qty: string;

  /**
   * 회사구분
   *
   * **data type** varchar(3)
   */
  wkactcd: string;

  /**
   * 회사구분명
   *
   * **data type** varchar(3)
   */
  wkactnm: string;

  /**
   * 거래처이름
   *
   * **data type** varchar(50)
   */
  cltnm: string;

  /**
   * 협력사코드
   *
   * **data type** varchar(50)
   */
  cltnum: string;

  /**
   * 건물용도
   *
   * **data type** varchar(20)
   */
  bildyd: string;

  /**
   * 유지관리비별 건물구분
   *
   * **data type** varchar(10)
   */
  contbild: string;

  /**
   * 지역(검사담당관할)
   *
   * **data type** varchar(4)
   */
  areacd: string;

  /**
   * 지역(검사담당관할)2
   *
   * **data type** varchar(4)
   */
  areanm: string;

  /**
   * 지역(검사담당관할)3
   *
   * **data type** varchar(10)
   */
  gareanm: string;

  /**
   * 담당(점검자)
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 보조자
   *
   * **data type** varchar(40)
   */
  perid: string;

  /**
   * 담당(점검자)2
   *
   * **data type** varchar(10)
   */
  pernm: string;

  /**
   * 보조자
   *
   * **data type** varchar(40)
   */
  perid2: string;

  /**
   * 보조자2
   *
   * **data type** varchar(10)
   */
  pernm2: string;

  /**
   * 문서번호
   *
   * **data type** varchar(5)
   */
  gpaper: string;

  /**
   * 문서번호2
   *
   * **data type** varchar(5)
   */
  paper: string;

  /**
   * 전화번호
   *
   * **data type** varchar(20)
   */
  tel: string;

  /**
   * 팩스번호
   *
   * **data type** varchar(20)
   */
  fax: string;

  /**
   * 담당자메일
   *
   * **data type** varchar(100)
   */
  actmail: string;

  /**
   * 담당자휴대폰번호
   *
   * **data type** varchar(20)
   */
  hp: string;

  /**
   * (세금계산서발행처)거래처
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * (세금계산서발행처)거래처
   *
   * **data type** varchar(?)
   */
  cltcd11: string;

  /**
   * (세금계산서발행처)거래처2
   *
   * **data type** varchar(200)
   */
  pubcltnm: string;

  /**
   * 거래처담당자
   *
   * **data type** varchar(50)
   */
  pubpernm: string;

  /**
   * 거래처우편번호
   *
   * **data type** varchar(6)
   */
  pubzipcd: string;

  /**
   * 거래처주소
   *
   * **data type** varchar(6)
   */
  pubaddress: string;

  /**
   * 구주소
   *
   * **data type** varchar(모름)
   */
  oldaddress: string;

  /**
   * 구주소
   *
   * **data type** varchar(모름)
   */
  oldpubaddress: string;

  /**
   * 우편번호
   *
   * **data type** varchar(6)
   */
  zipcode: string;

  /**
   * 주소
   *
   * **data type** varchar(100)
   */
  address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(100)
   */
  address2: string;

  /**
   * 지로우편번호
   *
   * **data type** varchar(6)
   */
  anzipcd: string;

  /**
   * 지로주소
   *
   * **data type** varchar(100)
   */
  anaddress: string;

  /**
   * 지로구주소
   *
   * **data type** varchar(100)
   */
  oldanaddress: string;

  /**
   * 지로상세주소
   *
   * **data type** varchar(100)
   */
  anaddress2: string;

  /**
   * 지로발송처명
   *
   * **data type** varchar(100)
   */
  ancltnm: string;

  /**
   * 담당
   *
   * **data type** varchar(50)
   */
  anpernm: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 보수업체명
   *
   * **data type** varchar(200)
   */
  bosunm: string;

  /**
   * 보수업체연락처
   *
   * **data type** varchar(20)
   */
  bosutelnum: string;

  /**
   * 현장아이디
   *
   * **data type** varchar(20)
   */
  actid: string;

  /**
   * 현장비밀번호
   *
   * **data type** varchar(20)
   */
  actpw: string;

  lat: string;

  lng: string;

  anlat: string;

  anlng: string;

  publat: string;

  publng: string;

  com_code: string;

  e035_official: string;

  remflag: string;

  remflag_wkactcd: string;

  exclflag: string;

  mgubun: string;

  contflag: string;

  contdate: string;

  autoflag: string;

  autonum: string;

  taxflag: string;

  taxmail: string;


  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.ordercd = data.ordercd || '';
    this.ordernm = data.ordernm || '';
    this.useyn = data.useyn || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.actperid = data.actperid || '';
    this.actpernm = data.actpernm || '';
    this.scactnm = data.scactnm || '';
    this.contg = data.contg || '';
    this.state = data.state || '0';
    this.qty = data.qty || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.cltnum = data.cltnum || '';
    this.bildyd = data.bildyd || '';
    this.contbild = data.contbild || '';
    this.areacd = data.areacd || '';
    this.areanm = data.areanm || '';
    this.gareanm = data.gareanm || '';
    this.cltnum = data.cltnum || '';
    this.divinm = data.divinm || '';
    this.pernm = data.pernm || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
    this.pernm2 = data.pernm2 || '';
    this.gpaper = data.gpaper || '';
    this.paper = data.paper || '';
    this.tel = data.tel || '';
    this.fax = data.fax || '';
    this.actpernm = data.actpernm || '';
    this.actmail = data.actmail || '';
    this.hp = data.hp || '';
    this.cltcd = data.cltcd || '';
    this.cltcd11 = data.cltcd11 || '';
    this.cltnm = data.cltnm || '';
    this.pubcltnm = data.pubcltnm || '';
    this.pubpernm = data.pubpernm || '';
    this.pubzipcd = data.pubzipcd || '';
    this.pubaddress = data.pubaddress || '';
    this.oldpubaddress = data.oldpubaddress || '';
    this.publat = data.publat || '';
    this.publng = data.publng || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.anlat = data.anlat || '';
    this.anlng = data.anlng || '';
    this.zipcode = data.zipcode || '';
    this.oldaddress = data.oldaddress || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.anzipcd = data.anzipcd || '';
    this.anaddress = data.anaddress || '';
    this.oldanaddress = data.oldanaddress || '';
    this.anaddress2 = data.anaddress2 || '';
    this.ancltnm = data.ancltnm || '';
    this.anpernm = data.anpernm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.bosunm = data.bosunm || '';
    this.bosutelnum = data.bosutelnum || '';
    this.actid = data.actid || '';
    this.actpw = data.actpw || '';
    this.com_code = data.com_code || '';
    this.e035_official = data.e035_official || '';
    this.seq = data.seq || '';
    this.enddate = data.enddate || '';
    this.remflag = data.remflag || '';
    this.remflag_wkactcd = data.remflag_wkactcd || '';
    this.exclflag = data.exclflag || '';
    this.mgubun = data.mgubun || '';
    this.contdate = data.contdate || '';
    this.contflag = data.contflag || '';
    this.autoflag = data.autoflag || '';
    this.autonum = data.autonum || '';
    this.taxflag = data.taxflag || '';
    this.taxmail = data.taxmail || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get isRemFlag() {
    return this.remflag === 'Y' || this.remflag_wkactcd === 'Y';
  }
}
