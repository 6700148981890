import * as React from 'react';
import { Button, FlexLayout, TableLayout } from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { BUTTON_THEMES } from '../../../../../components/forms/Button/Button';
import { EnrollmentConferencelTableHeader } from '../headers/EnrollmentConferencelTableHeader';

/**
 * 화면 > 탭 - 협의사항
 * @window w_tb_e451
 * @category 견적서등록
 */
export const EnrollmentTabConferenceTemplate: React.FC<TemplateProps<Enrollment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
      <FlexLayout />

      <FlexLayout size={150}>
        <Button
          isFull={true}
          onClick={() => scope.onClickConferenceNew()}
        >
          추가
        </Button>

        <Button
          isFull={true}
          theme={BUTTON_THEMES.RED}
          onClick={() => scope.onClickConferenceRemove()}
        >
          삭제
        </Button>
      </FlexLayout>
    </FlexLayout>

    <FlexLayout>
      <TableLayout
        ref={scope.tableConference}
        header={EnrollmentConferencelTableHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataConference}
        onRowFocusEvent={(item, index) => scope.onConferenceRowFocusEvent(item, index)}
        onChange={(rows) => scope.onChangeTableConference(rows)}
      />
    </FlexLayout>
  </FlexLayout>
);
