import * as React from 'react';
import { observer } from 'mobx-react';
import { FiUpload } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/all';
import style from '../AuthorizedScreen.module.scss';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { TextBox } from '../../components/forms/TextBox';
import { Button } from '../../components/forms/Button';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { LabelBox } from '../../components/forms/LabelBox';
import { TelnumTextBox } from '../../components/forms/TelnumTextBox';
import { GridLayout } from '../../components/layout/GridLayout';
import { ConfirmWarning } from '../../utils/confirm';
import { LoaderButton } from '../../components/forms/LoaderButton';
import { Format } from '../../utils/string';

interface ModalFaxProps {
  modalStore: ModalStore;
}

@observer
export class ModalFax extends React.Component<ModalFaxProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        isVisible={modalStore.isVisibleFax}
        onClose={() => modalStore.closeFax()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>팩스 전송</LayoutTitle>

          <LabelBox title="받는이 팩스 번호" size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <TelnumTextBox
              value={modalStore.faxTo}
              onChange={(value) => { modalStore.faxTo = value; }}
            />
          </LabelBox>

          <LabelBox title="제목(내용)" size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <TextBox
              value={modalStore.faxRemark}
              onChange={(value) => { modalStore.faxRemark = value; }}
              isMultiline={true}
            />
          </LabelBox>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <LayoutTitle weight={1}>
              팩스 첨부
            </LayoutTitle>
            <LoaderButton
              onClick={() => modalStore.faxAddAttachment()}
              percent={modalStore.faxPercentAttachment}
            >
              <FiUpload />
              <span>내 PC</span>
            </LoaderButton>
          </FlexLayout>

          <GridLayout
            header={[
              {
                id: 'delete',
                text: '삭제',
                width: 10,
                render: (x: any) => (
                  <FlexLayout justify="center" align="center">
                    <Button
                      isIcon={true}
                      onClick={() => modalStore.faxAttachmentRemove(x)}
                    >
                      <IoMdTrash size={19} />
                    </Button>
                  </FlexLayout>
                ),
              }, {
                id: 'filename',
                text: '파일명',
                width: 60,
                render: (x: any) => (
                  <FlexLayout justify="start" align="center">
                    {x.filename.startsWith('.') ? `이름없음${x.filename}` : x.filename}
                  </FlexLayout>
                ),
              }, {
                id: 'extension',
                text: '확장자',
                width: 10,
              }, {
                id: 'size',
                text: '파일크기',
                width: 20,
                render: (x: any) => (
                  <FlexLayout justify="end" align="center">
                    {Format.number(x.size)}B&nbsp;
                  </FlexLayout>
                ),
              },
            ]}
            data={modalStore.faxAttachments || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeFax()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (ConfirmWarning.assert(modalStore.faxTo, '경고', '수신지 번호를 입력해주세요.')
                  && ConfirmWarning.assert(modalStore.faxAttachments.length, '경고', '보낼 첨부 문서를 1개 이상 등록해주세요.')
                ) {
                  modalStore.sendFax();
                }
              }}
            >
              전송
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
