import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { StorageCode } from './StorageCode';

/**
 * 화면
 * @window w_tb_ca504
 * @category 창고코드등록
 */
export const StorageCodeTemplate: React.FC<TemplateProps<StorageCode>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'chg_gcod',
          text: '창고코드',
          width: 20,
        },
        {
          id: 'chg_gnam',
          text: '창고명',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.chg_gnam}
              onChange={(value) => rowUpdate({ chg_gnam: value })} />
          ),
        },
        {
          id: 'remark',
          text: '비 고',
          width: 60,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              textAlign="left"
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })} />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
        />
  </FlexLayout>
);
