import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e039_01
 * @category 순찰일지
 */

export class PatrolModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly patroldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly sttime: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly endtime: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly wkcd: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.patroldate = data.patroldate || '';
    this.seq = data.seq || '';
    this.sttime = data.sttime || '';
    this.endtime = data.endtime || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.wkcd = data.wkcd || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
