import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { TellReceive } from './TellReceive';
import { Date8 } from '../../../../utils/time';
import { Fix } from '../../../../utils/string';

const telcds = [
  { value: '001', remark: '회사' },
  { value: '002', remark: '휴대전화' },
  { value: '003', remark: '자택' },
  { value: '004', remark: '팩스' },
  { value: '005', remark: '비상통화장치' },
];

const custflags = [
  { value: '0', remark: '현장' },
  { value: '1', remark: '거래처' },
  { value: '2', remark: '직원' },
  { value: '3', remark: '일반' },
  { value: '4', remark: '기타' },
  { value: '5', remark: '영업' },
];

const timeflags = [
  { value: '%', remark: '전체' },
  { value: '1', remark: '주간' },
  { value: '2', remark: '야간' },
];

const kinds = [
  { value: '2', remark: '발신', color: 'var(--color-black)' },
  { value: '3', remark: '수신', color: 'var(--color-blue)' },
  { value: '4', remark: '부재중', color: 'var(--color-red)' },
];

/**
 * 화면
 * @window w_tb_callmainw
 * @category 전화수신목록
 */
export const TellReceiveTemplate: React.FC<TemplateProps<TellReceive>> = ({
  scope,
  update,
  // @ts-ignore
  modelUpdate,
}) => {
  const setData = update!;
  const model = scope.state.focused;

  return (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => setData({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() - 1);
              setData({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() + 1);
              setData({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={174}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => setData({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() - 1);
              setData({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() + 1);
              setData({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={170}>
        <span>시간구분</span>
        <ComboBox
          value={scope.state.timeflag}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '주간' },
            { value: '2', remark: '야간' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ timeflag: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={170}>
        <span>그룹분류</span>
        <ComboBox
          value={scope.state.regflag}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '현장' },
            { value: '1', remark: '거래처' },
            { value: '2', remark: '직원' },
            { value: '3', remark: '일반' },
            { value: '4', remark: '기타' },
            { value: '5', remark: '영업' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ regflag: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={170}>
        <span>전화구분</span>
        <ComboBox
          value={scope.state.telcd}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.telcds?.map((y) => new ComboBoxModel(y.telcd, y.telnm))}
          onSelect={(option) => setData({ telcd: option.value },
            () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout size={160}>
        <span>AI여부</span>
        <ComboBox
          value={scope.state.aiflag}
          data={[
            { value: '%', remark: '전체' },
            { value: '0', remark: '일반' },
            { value: '1', remark: 'AI' },
          ].map((y) => new ComboBoxModel(y.value, y.remark))}
          onSelect={(option) => setData({ aiflag: option.value }, () => scope.onRetrieveEvent())}
        />
      </FlexLayout>

      <FlexLayout>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="고객, 수신자, 수신번호, 수신메모로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => setData({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        />
      </FlexLayout>

      <FlexLayout
        justify="left"
        align="center"
        size={200}
        style={{ color: 'var(--color-violet)' }}
      >
        * 수신상태가 '착신전환'일 경우에는 통화녹음 및 AI 요약이 되지 않습니다
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <GridLayout
        header={[
          {
            id: 'calldate',
            text: '수신일자',
            width: 7,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##-##"
                value={x.calldate}
              />
            ),
          },
          {
            id: 'calltime',
            text: '시간',
            width: 7,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="##:##"
                value={x.calltime}
              />
            ),
          },
          {
            id: 'timeflag',
            text: '시간구분',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.timeflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                {timeflags.filter((y) => y.value === x.timeflag)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'kind',
            text: '수신결과',
            width: 7,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: kinds.filter((y) => y.value === x.kind)[0]?.color }}
              >
                {kinds.filter((y) => y.value === x.kind)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'callee',
            text: '수신번호',
            width: 11,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.callee}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '수신자명',
            width: 8,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'telcd',
            text: '전화분류',
            width: 11,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {telcds.filter((y) => y.value === x.telcd)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'callnum',
            text: '발신번호',
            width: 11,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.callnum}
              </FlexLayout>
            ),
          },
          {
            id: 'callnm',
            text: '발신고객명',
            width: 20,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {x.callnm}
              </FlexLayout>
            ),
          },
          {
            id: 'callmemo',
            text: '수신메모',
            width: 20,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {Fix.newline(x.callmemo).replace(/\n/ig, ' ').substr(0, 70)}
                {x.callmemo.length > 70 && '...'}
              </FlexLayout>
            ),
          },
          {
            id: 'telstatenm',
            text: '수신상태',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={x.telstate === '0' ? { color: 'var(--color-black)' } : { color: 'var(--color-red)' }}
              >
                {x.telstatenm}
              </FlexLayout>
            ),
          },
          {
            id: 'aiflag',
            text: 'AI여부',
            width: 6,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={x.aiflag === '0' ? { color: 'var(--color-black)' } : { color: 'var(--color-blue)' }}
              >
                {x.aiflag === '0' ? '일반' : 'AI'}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.costClauseList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>

    <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <LayoutTitle size={170}>상세내용</LayoutTitle>
    </FlexLayout>

    <FlexLayout>
      <GridLayout
        header={[
          {
            id: 'answerflag',
            text: '내용',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.answerflag === 'O' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.answerflag}
              </FlexLayout>
            ),
          },
          {
            id: 'calldate',
            text: '일자',
            width: 14,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##-##"
                value={x.calldate}
              />
            ),
          },
          {
            id: 'calltime',
            text: '시간',
            width: 10,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="##:##"
                value={x.calltime}
              />
            ),
          },
          {
            id: 'callnum',
            text: '전화번호',
            width: 14,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.callnum}
              </FlexLayout>
            ),
          },
          {
            id: 'custflag',
            text: '고객구분',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {custflags.filter((y) => y.value === x.custflag)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'callnm',
            text: '고객명',
            width: 30,
            render: (x) => (
              <FlexLayout justify="left" align="center">
                {x.callnm}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '접수자',
            width: 12,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.pernm}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.costClauseDetailList}
        infinityHandler={scope.infinity2}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onDetailRowFocusEvent(item)}
      />

      <FlexLayout isVertical={true} weight={0.7}>
        <FlexLayout size={220}>
          <LabelBox title="통화내용(이력)">
            <TextBox
              value={model?.callmemo}
              onChange={(value) => modelUpdate({ callmemo: value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={180}>
          <LabelBox title="처리내용(비고)">
            <TextBox
              value={model?.callendmemo}
              onChange={(value) => modelUpdate({ callendmemo: value })}
            />
          </LabelBox>
        </FlexLayout>

      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
