import { action } from 'mobx';
import * as React from 'react';
import { RefObject } from 'react';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import StatusModel from './models/StatusModel';
import { StatusTemplate } from './Status.template';
import { PageComponent } from '../../../../utils';
import { Date6 } from '../../../../utils/time';
import StatusDetailModel from './models/StatusDetailModel';
import { ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';

interface SampleState {
  year: string;
  data: Array<StatusModel>;

  graph: string;

  isVisibleModal: boolean;
  dataModalExpire: Array<StatusDetailModel>;
  dataModalFree: Array<StatusDetailModel>;
  dataModalNew: Array<StatusDetailModel>;
  dataModalCancel: Array<StatusDetailModel>;
  amt_tot: string;
  amt2_tot: string;
  amt3_tot: string;
  amt4_tot: string;
  cnt_tot: string;
  cnt2_tot: string;
  cnt3_tot: string;
  cnt4_tot: string;
  qty_tot: string;
  qty2_tot: string;
  qty3_new_tot: string;
  qty3_ch_tot: string;
  qty4_tot: string;
  bemon12: string;

  beforeData: Array<StatusModel>;
  detailBtnState: string;
}

/**
 * 컨트롤러
 * @window w_tb_e101w_05
 * @category 월별 관리대수변동내역
 */
export class Status extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      year: Date6.make().substr(0, 4),
      data: [],

      graph: '',

      isVisibleModal: false,
      dataModalExpire: [],
      dataModalFree: [],
      dataModalNew: [],
      dataModalCancel: [],
      amt_tot: '',
      amt2_tot: '',
      amt3_tot: '',
      amt4_tot: '',
      cnt_tot: '',
      cnt2_tot: '',
      cnt3_tot: '',
      cnt4_tot: '',
      qty_tot: '',
      qty2_tot: '',
      qty3_new_tot: '',
      qty3_ch_tot: '',
      qty4_tot: '',
      bemon12: '',

      beforData: [],
      // 대수쪽 버튼 상세보기
      detailBtnState: '0',
    };
  }


  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api, publicStore } = this.props;
    const { user } = publicStore;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new StatusModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
      graph: `https://api.elmansoft.com/chart/multi.php?database=${user.custcd}&window=w_tb_01&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_year=${this.state.year}&asc=1`,
    });

    await this.infinity?.retrieveAll();
    this.SS({
      bemon12: this.infinity?.box?.bemon12 || '',
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      year: this.state.year,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      year: this.state.year,
    });
  }


  getTitleColor(item: StatusModel) {
    switch (item.gubun) {
      case '1': return { paddingRight: 30 };
      case '1.1': return { color: 'var(--color-blue)' };
      case '1.2': return { color: '#8C04F7' };
      case '1.3': return { color: 'var(--color-black)' };
      case '2': return { color: 'var(--color-green)' };
      case '3': return { color: 'var(--color-violet)' };
      case '4': return { color: '#088BC0' };
      case '5.1': return { color: '#088BC0' };
      case '5.2': return { color: '#088BC0' };
      case '5': return { color: 'var(--color-blue)' };
      case '6': return { color: 'var(--color-red)' };
      default: return { color: 'var(--color-violet)' };
    }
  }

  getColor(item: StatusModel, n: string) {
    switch (item.gubun) {
      case '1': return {};
      case '2': return { color: 'var(--color-green)' };
      case '3': return { color: 'var(--color-violet)' };
      case '4': return { color: '#088BC0' };
      case '5.1': return { color: '#088BC0' };
      case '5.2': return { color: '#088BC0' };
      case '5': return { color: 'var(--color-blue)' };
      case '6': return { color: 'var(--color-red)' };
      default: return { color: parseInt(n, 10) < 0 ? 'var(--color-red)' : 'var(--color-blue)' };
    }
  }


  @action
  async openDetail(mon: string) {
    const { actionStore: api } = this.props;

    const data = await api.retrieve({
      sub: 'w_popup_e101w_05',
      mon: `${this.state.year}${mon}`,
    });

    await this.SS({
      isVisibleModal: true,
      dataModalExpire: data?.items?.map((x: any) => new StatusDetailModel(x)) || [],
      dataModalFree: data?.items2?.map((x: any) => new StatusDetailModel(x)) || [],
      dataModalNew: data?.items3?.map((x: any) => new StatusDetailModel(x)) || [],
      dataModalCancel: data?.items4?.map((x: any) => new StatusDetailModel(x)) || [],
      amt_tot: data?.amt_tot || '0',
      amt2_tot: data?.amt2_tot || '0',
      amt3_tot: data?.amt3_tot || '0',
      amt4_tot: data?.amt4_tot || '0',
      cnt_tot: data?.cnt_tot || '0',
      cnt2_tot: data?.cnt2_tot || '0',
      cnt3_tot: data?.cnt3_tot || '0',
      cnt4_tot: data?.cnt4_tot || '0',
      qty_tot: data?.qty_tot || '0',
      qty2_tot: data?.qty2_tot || '0',
      qty3_new_tot: data?.qty3_new_tot || '0',
      qty3_ch_tot: data?.qty3_ch_tot || '0',
      qty4_tot: data?.qty4_tot || '0',
    });
  }

  @action
  async detailBtnClick() {
    const { actionStore: api } = this.props;

    // 플러스버튼 마이너스버튼 표시하기위한 state값
    await this.SS({
      detailBtnState: this.state.detailBtnState === '0' ? '1' : '0',
    });

    // 플러스 눌렀을 때
    if (this.state.detailBtnState === '0') {
      // data에 기존값만 setState
      await this.SS({
        data: this.state.beforeData,
      });
    } else {
      const data = await api.fxExec('dw_list_buttonclicked',
        {
          itemname: 'p_add',
          data: '',
          year: this.state.year,
        });

      // 상세 리스트 추가하기전에 기존값 beforeData에 임시로 넣어줌
      const beforeData: Array<StatusModel> = JSON.parse(JSON.stringify(this.state.data));

      // items 정렬
      data.items.sort((a: any, b: any) => (a.sort < b.sort ? -1 : 1));

      // 기존 data에서 대수상세보기이므로 0번째이후로 dw_list_button의 items추가해줌
      const addData: Array<StatusModel> = [
        this.state.data[0],
        ...data.items.map((item: any) => new StatusModel(item)),
        ...this.state.data.splice(1),
      ];

      await this.SS({
        beforeData,
        data: addData,
      });
    }

    this.grid.current?.forceRepaint(true);
  }


  render() {
    return <StatusTemplate
      scope={this}
    />;
  }
}
