/**
 * 모델
 * @window w_tb_da026_sms
 * @category 입금확인[카톡]내역
 */
export class DepositConfirmationModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 입금처리일자
   *
   * **data type** varchar(2)
   */
  readonly indate: string;

  /**
   * 입금일자
   *
   * **data type** varchar(2)
   */
  readonly rcvdate: string;

  /**
   * 번호
   *
   * **data type** varchar(2)
   */
  readonly rcvnum: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(2)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(2)
   */
  readonly cltnm: string;

  /**
   * 내용
   *
   * **data type** varchar(2)
   */
  readonly remark: string;

  /**
   * 매출구분
   *
   * **data type** varchar(2)
   */
  readonly gubunnm: string;

  /**
   * 입금액
   *
   * **data type** varchar(2)
   */
  readonly rcvamt: string;

  /**
   * 고객연락처
   *
   * **data type** varchar(2)
   */
  readonly rcvtelnum: string;

  /**
   * 전송여부
   *
   * **data type** varchar(2)
   */
  readonly rcvflag: string;

  /**
   * 선택
   *
   * **data type** varchar(2)
   */
  readonly chk: string;

  /**
   * 고객연락처(2)
   *
   * **data type** varchar(2)
   */
  readonly hp: string;

  constructor(data: any = {}) {
    this.spjangcd = data.spjangcd || '';
    this.indate = data.indate || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvnum = data.rcvnum || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.remark = data.remark || '';
    this.gubunnm = data.gubunnm || '';
    this.rcvamt = data.rcvamt || '';
    this.rcvtelnum = data.rcvtelnum || '';
    this.rcvflag = data.rcvflag || '';
    this.chk = data.chk || '';
    this.hp = data.hp || '';
  }
}
