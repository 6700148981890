import { computed } from 'mobx';

/**
 * 현장별 모델
 * @window w_input_da023w
 * @category 미수금현황
 */
export class SiteModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 사업자명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  actcd: string;

   /**
   * 현장명
   *
   * **data type** varchar(200)
   */
   actnm: string;

   /**
   * 전일미수
   *
   * **data type** number
   */
   beamt: string;

   /**
   * 매출액
   *
   * **data type** number
   */
   misamt: string;

  /**
   * 매입가액
   *
   * **data type** float
   */
  samt: string;

   /**
   * 입금액
   *
   * **data type** number
   */
   rcvamt: string;

   /**
   * 미수잔액
   *
   * **data type** number
   */
   resuamt: string;

   /**
   * 비고
   *
   * **data type** varchar(12)
   */
   remark: string;

   /**
   * 코드
   *
   * **data type** char(2)
   */
   code: string;

   /**
   * 매출구분
   *
   * **data type** char(50)
   */
   cnam: string;

   /**
   * 해제
   *
   * **data type** char(1)
   */
   chk: string;

   /**
   * 기간
   *
   * **data type** datetime
   */
   stdatetime: string;

  /**
   * ~기간
   *
   * **data type** datetime
   */
  enddatetime: string;

  /**
   * 검색어
   *
   * **data type** string
   */
  cltcd: string;

  /**
   * 매출분류
   *
   * **data type** string
   */
  billgubun: string;

  /**
   * 부서
   *
   * **data type** string
   */
  divicd: string;

  /**
   * 부서
   *
   * **data type** string
   */
  divinm: string;

  /**
   * 담당자
   *
   * **data type** string
   */
  perid: string;

  /**
   * 담당자
   *
   * **data type** string
   */
  pernm: string;

  /**
   * 잔액체크
   *
   * **data type** string
   */
  resuck: string;

  /**
   * 미수일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  misnum: string;

  /**
   * 입금일자
   *
   * **data type** varchar(8)
   */
  rcvdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  rcvnum: string;

  /**
   * 순번
   *
   * **data type** varchar(3)
   */
  rcvseq: string;

  /**
   * 회계적용
   *
   * **data type** varchar(1)
   */
  accyn: string;

  /**
   * 현금지급
   *
   * **data type** float
   */
  hamt: string;

  /**
   * 어음
   *
   * **data type** float
   */
  eamt: string

  /**
   * 어음번호
   *
   * **data type** varchar(12)
   */
  enum: string;

  /**
   * 어음일자
   *
   * **data type** char(8)
   */
  edate: string;

  /**
   * 어음마지막일자
   *
   * **data type** char(8)
   */
  eidate: string;

  /**
   * 어음거래처
   *
   * **data type** char(13)
   */
  ecltcd: string;

  /**
   * 매입가액
   *
   * **data type** float
   */
  bamt: string;

  /**
   * 은행코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 카드금액
   *
   * **data type** float
   */
  damt: string;

  /**
   * 카드은행
   *
   * **data type** varchar(10)
   */
  cardcd: string;

  /**
   * 카드효율
   *
   * **data type** float
   */
  persent: string;

  /**
   * 카드번호
   *
   * **data type** varchar(16)
   */
  cardno: string;

  /**
   * 기타구분
   *
   * **data type** varchar(15)
   */
  ggubun: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 지로금액
   *
   * **data type** float
   */
  jamt: string;

  /**
   * 지로수수료
   *
   * **data type** float
   */
  jmar: string;

  /**
   * 지로번호
   *
   * **data type** varchar(13)
   */
  jnum: string;

  /**
   * 지로은행
   *
   * **data type** varchar(8)
   */
  jbankcd: string;

  /**
   * 기타금액
   *
   * **data type** float
   */
  dcamt: string;

  /**
   * 지로번호
   *
   * **data type** varchar(10)
   */
  pubnum: string;

  /**
   * 전표일자
   *
   * **data type** varchar(8)
   */
  acc_spdate: string;

  /**
   * 번호
   *
   * **data type** varchar(4)
   */
  acc_spnum: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 입력일
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 계산서발행
   *
   * **data type** varchar(8)
   */
  billkind: string;

  /**
   * 선납구분
   *
   * **data type** char(1)
   */
  sunflag: string;

  /**
   * 선급금액
   *
   * **data type** float
   */
  sunamt: string;

  /**
   * 예금수수료
   *
   * **data type** float
   */
  bmar: string;

  /**
   * ems연결번호
   *
   * **data type** varchar(50)
   */
  emtaxbillno: string;

  /**
   * cms구분
   *
   * **data type** varchar(1)
   */
  cms: string;

  /**
   * 예금관리연결미수일자
   *
   * **data type** varchar(8)
   */
  mismijdate: string;

  /**
   * 예금관리연결미수번호
   *
   * **data type** varchar(4)
   */
  mismijnum: string;

  /**
   * 미수기간(월)
   *
   * **data type** string
   */
  month3: string;

  /**
   * 계약분류
   *
   * **data type** string
   */
  gubun: string;

  /**
   * ems연결번호
   *
   * **data type** varchar(50)
   */
  emseq: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.beamt = data.beamt || '';
    this.misamt = data.misamt || '';
    this.rcvamt = data.rcvamt || '';
    this.resuamt = data.resuamt || '';
    this.remark = data.remark || '';
    this.code = data.code || '';
    this.cnam = data.cnam || '';
    this.chk = data.chk || '';
    this.stdatetime = data.stdatetime || '';
    this.enddatetime = data.enddatetime || '';
    this.cltcd = data.cltcd || '';
    this.billgubun = data.billgubun || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.resuck = data.resuck || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.rcvdate = data.rcvdate || '';
    this.rcvnum = data.rcvnum || '';
    this.rcvseq = data.rcvseq || '';
    this.accyn = data.accyn || '';
    this.hamt = data.hamt || '';
    this.eamt = data.eamt || '';
    this.enum = data.enum || '';
    this.edate = data.edate || '';
    this.eidate = data.eidate || '';
    this.ecltcd = data.ecltcd || '';
    this.samt = data.samt || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.damt = data.damt || '';
    this.cardcd = data.cardcd || '';
    this.persent = data.persent || '';
    this.cardno = data.cardno || '';
    this.ggubun = data.ggubun || '';
    this.gamt = data.gamt || '';
    this.jamt = data.jamt || '';
    this.jmar = data.jmar || '';
    this.jnum = data.jnum || '';
    this.jbankcd = data.jbankcd || '';
    this.dcamt = data.dcamt || '';
    this.pubnum = data.pubnum || '';
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.billkind = data.billkind || '';
    this.sunflag = data.sunflag || '';
    this.sunamt = data.sunamt || '';
    this.bmar = data.bmar || '';
    this.emseq = data.emseq || '';
    this.emtaxbillno = data.emtaxbillno || '';
    this.cms = data.cms || '';
    this.mismijdate = data.mismijdate || '';
    this.mismijnum = data.mismijnum || '';
    this.month3 = data.month3 || '';
    this.gubun = data.gubun || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
