import * as React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { findDOMNode } from 'react-dom';
import { ModalStore } from '../../stores';
import { JoinClassName } from '../../utils/string';
import style from '../../components/forms/ComboBox/ComboBox.module.scss';
import {
  getGravityPadding,
  getGravityString,
} from '../../components/forms/ComboBox/ComboBox';

interface ModalComboBoxProps {
  modalStore: ModalStore;
}

interface ModalComboBoxStates {
  searchQuery: string;
  x: number;
  y: number;
}

@observer
export class ModalComboBox extends React.Component<ModalComboBoxProps, ModalComboBoxStates> {
  constructor(props: ModalComboBoxProps, context: any) {
    super(props, context);
    this.state = {
      searchQuery: '',
      x: 0,
      y: 0,
    };
  }

  @action
  update() {
    const { modalStore } = this.props;
    const ref = modalStore.refComboBox;
    const state = ref?.state;

    let x = 0;
    let y = 0;
    const f = this.props.modalStore.publicStore.zoomF;

    if (ref) {
      // @ts-ignore
      const rect = findDOMNode(ref).getBoundingClientRect();
      const isSearchLayout = (ref.ref.current?.parentElement?.parentElement?.parentElement?.className.indexOf('SearchLayout') || -1) > -1;

      x = rect.left + state?.popupLeft + 1;
      y = rect.top + 1 + (isSearchLayout ? 9 : 0);

      if (x + ((modalStore?.comboBoxWidth || 0) * f) > window.innerWidth) {
        x = window.innerWidth - ((modalStore?.comboBoxWidth || 0) * f);
      }
    }

    this.setState({
      x: x / f,
      y: y / f,
    });
  }

  @action
  reset() {
    this.setState({
      searchQuery: '',
    });
  }

  render() {
    const { modalStore } = this.props;
    const ref = modalStore.refComboBox;
    const props = ref?.props;

    const rawData = (ref?.props.data || []);
    const data = rawData.filter((x, i) => i === rawData.findIndex((y) => y?.value === x?.value));
    const gravity = getGravityString(props?.textAlign);
    const textPadding = getGravityPadding(props?.textAlign);

    return (
      <div
        id="combobox"
        className={JoinClassName.make([
          style.combobox,
        ])}
        style={{
          width: (modalStore?.comboBoxWidth || 0) - 1,
          opacity: modalStore.isVisibleComboBox ? 1 : 0,
          transform: `translateX(${this.state.x}px) translateY(${this.state.y}px)`,
        }}
      >
        <div className={style.selector}>
          <div
            className={style.search}
            style={{
              height: (modalStore.comboBoxHeight || 0) - 2,
            }}
          >
            <input
              ref={modalStore.refComboBoxInput}
              value={this.state.searchQuery}
              onBlur={(e) => {
                if (e.target.className === 'search') return;
                ref?.toggle();
              }}
              style={{
                ...{
                  padding: props?.textAlign ? '0 4px 0 0' : '0 0 0 12px',
                  fontSize: 12,
                  textAlign: props?.textAlign || '',
                },
                ...props?.style,
              }}
              onChange={(e) => this.setState({ searchQuery: e.target.value }, () => {
                ref?.props.onChangeSearchQuery && ref?.props.onChangeSearchQuery(this.state.searchQuery);
              })}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  const fp = data.filter((x) => x?.remark?.indexOf(this.state.searchQuery) > -1);
                  if (fp.length === 1) {
                    ref?.onSelect(fp[0]?.value);
                  } else if (fp.length === 0) {
                    ref?.toggle();
                  }
                } else if (e.keyCode === 27) {
                  if (this.state.searchQuery !== '') {
                    this.setState({
                      searchQuery: '',
                    });
                  } else {
                    ref?.toggle();
                  }
                }
              }}
            />
          </div>
          <div
            className={style.dropdown}
            style={!ref?.state.isPopupForward ? {
              bottom: '-100%',
              boxShadow: '0px -12px 8px 0px rgba(0, 0, 0, .15)',
            } : {}}
          >
            {data.filter((x) => x?.remark?.indexOf(this.state.searchQuery) > -1).map(
              (item) => item && (
                <div
                  key={item.value}
                  onMouseDown={() => {
                    ref?.onSelect(item.value);
                  }}
                  style={{
                    ...{
                      padding: textPadding,
                      fontSize: 12,
                      justifyContent: gravity,
                    },
                    ...props?.style,
                  }}
                >
                  {item.remark}
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    );
  }
}
