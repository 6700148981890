import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { BreakNumberAllModel } from '../models';
import { BreakNumber } from '../BreakNumber';

// 거래처별
const All: GridLayoutHeader<BreakNumberAllModel, BreakNumber>[] = [
  {
    id: 'receyearnm',
    text: '년도',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.receyearnm}
      </FlexLayout>
    ),
  },
  {
    id: 'mon01',
    text: '01월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon01_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon01}
      </FlexLayout>
    ),
  },
  {
    id: 'mon02',
    text: '02월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon02_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon02}
      </FlexLayout>
    ),
  },
  {
    id: 'mon03',
    text: '03월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon03_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon03}
      </FlexLayout>
    ),
  },
  {
    id: 'mon04',
    text: '04월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon04_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon04}
      </FlexLayout>
    ),
  },
  {
    id: 'mon05',
    text: '05월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon05_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon05}
      </FlexLayout>
    ),
  },
  {
    id: 'mon06',
    text: '06월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon06_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon06}
      </FlexLayout>
    ),
  },
  {
    id: 'mon07',
    text: '07월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon07_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon07}
      </FlexLayout>
    ),
  },
  {
    id: 'mon08',
    text: '08월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon08_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon08}
      </FlexLayout>
    ),
  },
  {
    id: 'mon09',
    text: '09월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon09_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon09}
      </FlexLayout>
    ),
  },
  {
    id: 'mon10',
    text: '10월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon10_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon10}
      </FlexLayout>
    ),
  },
  {
    id: 'mon11',
    text: '11월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon11_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon11}
      </FlexLayout>
    ),
  },
  {
    id: 'mon12',
    text: '12월',
    width: 100,
    trail: (_, scope) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {scope?.state.mon12_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        style={{ paddingRight: 36 }}
        justify="right"
        align="center"
      >
        {x.mon12}
      </FlexLayout>
    ),
  },
  {
    id: 'ttot_samt',
    text: '합계',
    width: 100,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)', paddingRight: 36 }}
      >
        { Number(scope?.state.mon01_tot) + Number(scope?.state.mon02_tot)
        + Number(scope?.state.mon03_tot) + Number(scope?.state.mon04_tot)
        + Number(scope?.state.mon05_tot) + Number(scope?.state.mon06_tot)
        + Number(scope?.state.mon07_tot) + Number(scope?.state.mon08_tot)
        + Number(scope?.state.mon09_tot) + Number(scope?.state.mon10_tot)
        + Number(scope?.state.mon11_tot) + Number(scope?.state.mon12_tot)}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)', paddingRight: 36 }}
      >
        {x.ttot_samt}
      </FlexLayout>
    ),
  },
];

export default All;
