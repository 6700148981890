import { computed } from 'mobx';

/**
 * 모델
 * @window w_card_insert
 * @category 카드사등록
 */
export class CardModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 카드사코드
   *
   * **data type** varchar(10)
   */
  cd: string;

  /**
   * 카드사명
   *
   * **data type** varchar(100)
   */
  nm: string;

  /**
   * 요율
   *
   * **data type** float
   */
  persent: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cd = data.cd || '';
    this.nm = data.nm || '';
    this.persent = data.persent || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
