import { TLHF } from '../../../../../constants';
import {
  FlexLayout, FormatNumberTextBox, TextBox,
} from '../../../../../components';
import { Enrollment } from '../Enrollment';
import { SearchBinding } from '../../../../../models';
import EnrollmentDetailModel from '../models/EnrollmentDetailModel';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';

/**
 * 화면 > 테이블 레이아웃 A
 * @window w_tb_e471
 * @category 공사등록
 */
export const EnrollmentDetailTableHeader: TLHF<EnrollmentDetailModel, Enrollment> = (scope) => ([
  {
    id: 'costcd',
    text: '코드',
    width: 7,
    color: 'var(--color-red)',
    render: (x, rowUpdate, ref, _, index) => (
      index === 0 ? <TextBox
          ref={ref}
          textAlign="center"
          value={x.costcd}
          readonly={true}
        /> : <TextBox
        ref={ref}
        textAlign="center"
        value={x.costcd}
        onChange={(v) => rowUpdate({ costcd: v })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E450',
          {},
          true,
          (item) => {
            rowUpdate({
              costcd: item.cd,
              costnm: item.cdnm,
              unit: item.uv_arg1,
              qty: item.uv_arg2,
              remark: item.uv_arg4,
            });
            scope.tableDetailItemChanged(rowUpdate, 'costcd', item.cd);
          },
        )}
      />
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'costnm',
    text: '구분',
    width: 15,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.costnm}
        onChange={(v) => rowUpdate({ costnm: v })}
      />
    ),
  },
  {
    id: 'unit',
    text: '단위',
    width: 6,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="center"
        value={x.unit}
        onChange={(v) => rowUpdate({ unit: v })}
      />
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 6,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="center"
        value={x.qty}
        onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.uamt}
        onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate)}
      />
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.samt}
        onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, true)}
      />
    ),
    trail: (data) => {
      const sumSamt = Sum.all(data, 'samt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'addamt',
    text: '부가세',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.addamt}
        onChange={(v) => rowUpdate({ addamt: v })}
      />
    ),
    trail: (data) => {
      const sumAddamt = Sum.all(data, 'addamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumAddamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'compamt',
    text: '공사금액',
    width: 10,
    render: (x, rowUpdate, ref) => (
      <FormatNumberTextBox
        ref={ref}
        textAlign="right"
        value={x.compamt}
        onChange={(value) => scope.tabCompAmtCalc({ ...x, compamt: value }, rowUpdate)}
      />
    ),
    trail: (data) => {
      const sumCompamt = Sum.all(data, 'compamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCompamt)}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 30,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        textAlign="left"
        value={x.remark || ''}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
