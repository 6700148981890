import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_e512
 * @category 당직근무보고서
 */

export class WorkReportDetailModel {
  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  readonly recenum: string;

  /**
   * 구분
   *
   * **data type** varchar(1)
   */
  readonly receflag: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   * 동호기
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   * 고장내용
   *
   * **data type** varchar(5)
   */
  readonly contcd: string;

  /**
   * 고장상세내용
   *
   * **data type** varchar(255)
   */
  readonly contents: string;

  /**
   * 접수시간
   *
   * **data type** varchar(4)
   */
  readonly recetime: string;

  /**
   * 접수일자
   *
   * **data type** varchar(8)
   */
  readonly recedate: string;

  /**
   * 처리시간
   *
   * **data type** varchar(4)
   */
  readonly comptime: string;

  /**
   * 처리일자
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 처리자코드
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 처리자코드
   *
   * **data type** varchar(10)
   */
  readonly perid2: string;

  /**
   * 처리자명
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   * 처리내용
   *
   * **data type** varchar(3)
   */
  resucd: string;

  /**
   * 처리상세내용
   *
   * **data type** varchar(0)
   */
  readonly resuremark: string;

  /**
   * 고장부위
   *
   * **data type** varchar(3)
   */
  readonly gregicd: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar(3)
   */
  readonly regicd: string;

  /**
   * 고장요인
   *
   * **data type** varchar(3)
   */
  readonly remocd: string;

  /**
   * 고장원인
   *
   * **data type** varchar(3)
   */
  readonly faccd: string;

  /**
   * 처리결과
   *
   * **data type** varchar(3)
   */
  readonly resultcd: string;

  /**
   * 처리결과
   *
   * **data type** varchar(3)
   */
  readonly title: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.seq = data.seq || '';
    this.recenum = data.recenum || '';
    this.receflag = data.receflag || '0';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.contcd = data.contcd || '001';
    this.contents = Fix.newline(data.contents) || '';
    this.recetime = data.recetime || '';
    this.recedate = data.recedate || '';
    this.comptime = data.comptime || '';
    this.compdate = data.compdate || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid || '';
    this.pernm = data.pernm || '';
    this.resucd = data.resucd || '001';
    this.resuremark = data.resuremark || '';
    this.gregicd = data.gregicd || '01';
    this.regicd = data.regicd || '01';
    this.remocd = data.remocd || '001';
    this.faccd = data.faccd || '001';
    this.resultcd = data.resultcd || '001';
    this.title = data.title || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
