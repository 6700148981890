import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { KakaoTalkTemplate } from './KakaoTalk.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { KakaoTalkModel } from './KakaoTalk.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface KakaoTalkState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  kakaoTalkList: Array<KakaoTalkModel>;
  data: KakaoTalkModel;
  focusedKakaoTalk?: KakaoTalkModel;
  kakaoDetailModal: boolean;
  popupData: string;
}

/**
 * 컨트롤러
 * @window w_tb_KaKao_report
 * @category 카카오톡송신내역
 */
export class KakaoTalk extends PageComponent<PageProps, KakaoTalkState>
  implements PageToolEvents {
  // updatedRows?: Array<KakaoTalkModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      kakaoTalkList: [],
      data: new KakaoTalkModel(),
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    // this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          kakaoTalkList: [...this.state.kakaoTalkList, ...items],
        });
      },
      async () => {
        await this.SS({
          kakaoTalkList: [],
        });

        await this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      kakaoTalkList: [],
    }, () => this.infinity?.retrieveAll());
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.kakaoTalkList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.kakaoTalkList?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ kakaoDetailModal: isOpen });
  }

  @action
  onRowFocusEvent(item: KakaoTalkModel) {
    this.setState({ focusedKakaoTalk: item });
  }

  render() {
    return (
      <KakaoTalkTemplate
        scope={this}
      />
    );
  }
}
