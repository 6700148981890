import { GLHF, Global } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Comps, Gubuns, Status } from '../Status';
import StatusDetailModel from '../models/StatusDetailModel';
import { Date8 } from '../../../../../utils/time';
import { Format } from '../../../../../utils/string';
import { Sum } from '../../../../../utils/array';


const Wkactcds = [
  { value: '%', remark: '전체' },
  { value: '001', remark: '자사' },
  { value: '002', remark: '현대' },
  { value: '003', remark: '오티스' },
  { value: '004', remark: '티센' },
  { value: '005', remark: '미쓰비시' },
];


/**
 * 화면 > 탭 상세현황 > 그리드 레이아웃
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTabDetailGridHeader: GLHF<StatusDetailModel, Status> = (scope) => ([
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    group: true,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'costpernm',
    text: '처리자',
    width: 80,
    group: true,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costpernm}
      </FlexLayout>
    ),
  },
  {
    id: 'costdate',
    text: '견적일자',
    width: 100,
    group: true,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'costnum',
    text: '번호',
    width: 80,
    group: true,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costnum}
      </FlexLayout>
    ),
  },
  {
    id: 'wkactcd',
    text: '회사구분',
    width: 80,
    group: true,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 350,
    group: true,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        align="center"
        justify="start"
        style={{ color: 'var(--color-green)' }}
      >
        견적명 : {x.constnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'myn',
    text: '유무상',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={x.myn === '2' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE}
      >
        {x.myn === '2' ? '유상' : '무상'}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'pumflag',
    text: '자재청구',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.pumflag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.pumflag === '1' ? '있음' : '없음'}
      </FlexLayout>
    ),
  },
  {
    id: 'balflag',
    text: '발주내역',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.balflag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.balflag === '1' ? '있음' : '없음'}
      </FlexLayout>
    ),
  },
  {
    id: 'pname',
    text: '품목명',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '공급가액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{ color: 'var(--color-green)' }}
      >
        {Format.number(Sum.byGroup(data, 'samt', x, ['costdate', 'costnum']))}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tab_2_samt_tot || '')}
      </FlexLayout>
    ),
  },
  {
    id: 'tamt',
    text: '부가세',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.tamt)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{ color: 'var(--color-green)' }}
      >
        {Format.number(Sum.byGroup(data, 'tamt', x, ['costdate', 'costnum']))}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tab_2_tamt_tot || '')}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt',
    text: '공사금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.costamt)}
      </FlexLayout>
    ),
    sum: (x, data) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{ color: 'var(--color-green)' }}
      >
        {Format.number(Sum.byGroup(data, 'costamt', x, ['costdate', 'costnum']))}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(scope.state.tab_2_costamt_tot || '')}
      </FlexLayout>
    ),
  },
  {
    id: 'compyn',
    text: '상태',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Comps.filter((y) => y.value === x.compyn)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '견적명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
]);
