import { computed } from 'mobx';
import { Fix } from '../../../../../utils/string';

/**
 * 모델
 * @window w_tb_da451
 * @category 설치견적서등록
 */
export default class EnrollmentModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly addyn: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appgubun: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly appremark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly apptime: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compdivicd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compdivinm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly compyn: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly contflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costhp: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly costpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly customer: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly evidyn: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly exgigan: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly gita: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly iamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly iremark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly kcremark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ordflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly perfax: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly perhp: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly permail: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pertel: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly place: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly ramt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly recostperid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly recostpernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly refer: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly refmail: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly refnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly saleyul: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly statecd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly taxflag: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly taxyn: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly textnum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly total2: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly zipcode: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty_tot: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt_tot: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt_tot: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly mamt_tot: string;


  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.addyn = data.addyn || '';
    this.appdate = data.appdate || '';
    this.appgubun = data.appgubun || '';
    this.appnum = data.appnum || '';
    this.appperid = data.appperid || '';
    this.appremark = Fix.newline(data.appremark) || '';
    this.apptime = data.apptime || '';
    this.chk = data.chk || '';
    this.cltcd = data.cltcd || '';
    this.compdate = data.compdate || '';
    this.compdivicd = data.compdivicd || '';
    this.compdivinm = data.compdivinm || '';
    this.compflag = data.compflag || '';
    this.compperid = data.compperid || '';
    this.comppernm = data.comppernm || '';
    this.compyn = data.compyn || '';
    this.constnm = data.constnm || '';
    this.contflag = data.contflag || '';
    this.costdate = data.costdate || '';
    this.costhp = data.costhp || '';
    this.costnum = data.costnum || '';
    this.costperid = data.costperid || '';
    this.costpernm = data.costpernm || '';
    this.custcd = data.custcd || '';
    this.customer = data.customer || '';
    this.evidyn = data.evidyn || '';
    this.exgigan = Fix.newline(data.exgigan) || '';
    this.gita = Fix.newline(data.gita) || '';
    this.gubun = data.gubun || '';
    this.iamt = data.iamt || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inpernm = data.inpernm || '';
    this.iremark = Fix.newline(data.iremark) || '';
    this.kcremark = Fix.newline(data.kcremark) || '';
    this.mamt = data.mamt || '';
    this.ordflag = data.ordflag || '';
    this.perfax = data.perfax || '';
    this.perhp = data.perhp || '';
    this.permail = data.permail || '';
    this.pernm = data.pernm || '';
    this.pertel = data.pertel || '';
    this.place = data.place || '';
    this.ramt = data.ramt || '';
    this.recostperid = data.recostperid || '';
    this.recostpernm = data.recostpernm || '';
    this.refer = data.refer || '';
    this.refmail = data.refmail || '';
    this.refnm = data.refnm || '';
    this.remark = Fix.newline(data.remark) || '';
    this.saleamt = data.saleamt || '';
    this.saleyul = data.saleyul || '';
    this.samt = data.samt || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
    this.statecd = data.statecd || '';
    this.tamt = data.tamt || '';
    this.taxflag = data.taxflag || '0';
    this.taxyn = data.taxyn || '';
    this.textnum = data.textnum || '';
    this.total2 = data.total2 || '';
    this.wkactcd = data.wkactcd || '';
    this.zipcode = data.zipcode || '';
    this.qty_tot = data.qty_tot || '0';
    this.samt_tot = data.samt_tot || '0';
    this.tamt_tot = data.tamt_tot || '0';
    this.mamt_tot = data.mamt_tot || '0';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get isReported() {
    return this.appgubun && (this.appgubun !== '' && this.appgubun !== '131');
  }

  @computed
  get isReportedCancelable() {
    return this.isReported && this.appgubun === '001';
  }
}
