import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { CallDivision } from './CallDivision';

/**
 * 화면
 * @window w_tb_e902
 * @category 전화구분등록
 */
export const CallDivisionTemplate: React.FC<TemplateProps<CallDivision>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'telcd',
          text: '전화구분코드',
          width: 10,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.telcd}
              textAlign="center"
              readonly={x.newCount !== '1'}
              transparent={true}
              onChange={(value) => rowUpdate({ telcd: value })} />
          ),
        },
        {
          id: 'telnm',
          text: '전화구분명',
          width: 35,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.telnm}
              onChange={(value) => rowUpdate({ telnm: value })}
            />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 10,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remark',
          text: '비고',
          width: 45,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => scope.onUpdatedRows(rows, updatedRows)}
      onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
      data={scope.state.data}
    />
  </FlexLayout>
);
