import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_ca642_ats
 * @category 예금관리
 */
export class ManagePopupModel {
  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  acc_spdate: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  acc_spnum: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly accyn: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly actcd: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly actnm: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly addamt: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly amt: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly bamt: string;

  /**
   * 은행코드
   *
   * **data type** varchar(8)
   */
  bankcd: string;

  /**
   * 은행번호
   *
   * **data type** varchar(8)
   */
  bankno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  bchk: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  billkind: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  billgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  bmarflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  camt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  cardcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  cardno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  chaamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  contamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  ctaxamt: string;

  /**
   * 선택
   *
   * **data type** varchar(4)
   */
  chk: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltnm: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  damt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  dchk: string;

  /**
   * 부서코드
   *
   * **data type** varchar(13)
   */
  divicd: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  eamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  echk: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  ecltcd: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  eidate: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  enum: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  gamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  gubun: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  ggubun: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  hamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  hchk: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  imseq: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  inamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  indate: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  inperid: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  jamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  janamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  jbankcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  jchk: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  jirogubun: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  jcltno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  jmar: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  jnum: string;

  /**
   * 매출금액
   *
   * **data type** varchar(8)
   */
  misamt: string;

  /**
   * 매출일자
   *
   * **data type** varchar(8)
   */
  misdate: string;

  /**
   * 매출번호
   *
   * **data type** varchar(8)
   */
  misnum: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  mijamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  mijcltcd: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  mijcltnm: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  mijdate: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  mijgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  mijnum: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  projno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  pubgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  pubnum: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  recivstatus: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  payment: string;

  /**
   * 사원코드
   *
   * **data type** varchar(13)
   */
  perid: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  persent: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  remark: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  samt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  scflag: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  schdate: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  schk: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  snum: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  sort: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(13)
   */
  spjangcd: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  sunamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  sunflag: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  tax_spdate: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  tax_spnum: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  taxcls: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  wbamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  wdamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  weamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  wgamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  whamt: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  winamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  wjamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  wjmar: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  wkactcd: string;

  /**
   * 모름
   *
   * **data type** varchar(13)
   */
  wsamt: string;


  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.acc_spdate = data.acc_spdate || '';
    this.acc_spnum = data.acc_spnum || '';
    this.accyn = data.accyn || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.addamt = data.addamt || '';
    this.amt = data.amt || '';
    this.bamt = data.bamt || '';
    this.bankcd = data.bankcd || '';
    this.bankno = data.bankno || '';
    this.bchk = data.bchk || '';
    this.billkind = data.billkind || '';
    this.billgubun = data.billgubun || '';
    this.bmarflag = data.bmarflag || '';
    this.camt = data.camt || '';
    this.cardcd = data.cardcd || '';
    this.cardno = data.cardno || '';
    this.chaamt = data.chaamt || '';
    this.contamt = data.contamt || '';
    this.ctaxamt = data.ctaxamt || '';
    this.chk = data.chk || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.custcd = data.custcd || '';
    this.damt = data.damt || '';
    this.dchk = data.dchk || '';
    this.divicd = data.divicd || '';
    this.eamt = data.eamt || '';
    this.echk = data.echk || '';
    this.ecltcd = data.ecltcd || '';
    this.eidate = data.eidate || '';
    this.enum = data.enum || '';
    this.gamt = data.gamt || '';
    this.ggubun = data.ggubun || '';
    this.gubun = data.gubun || '';
    this.hamt = data.hamt || '';
    this.hchk = data.hchk || '';
    this.imseq = data.imseq || '';
    this.inamt = data.inamt || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.jamt = data.jamt || '';
    this.janamt = data.janamt || '';
    this.jbankcd = data.jbankcd || '';
    this.jchk = data.jchk || '';
    this.jcltno = data.jcltno || '';
    this.jirogubun = data.jirogubun || '';
    this.jmar = data.jmar || '';
    this.jnum = data.jnum || '';
    this.misamt = data.misamt || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.mijamt = data.mijamt || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.mijdate = data.mijdate || '';
    this.mijgubun = data.mijgubun || '';
    this.mijnum = data.mijnum || '';
    this.projno = data.projno || '';
    this.pubgubun = data.pubgubun || '';
    this.pubnum = data.pubnum || '';
    this.recivstatus = data.recivstatus || '';
    this.payment = data.payment || '';
    this.perid = data.perid || '';
    this.persent = data.persent || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.scflag = data.scflag || '';
    this.schdate = data.schdate || '';
    this.schk = data.schk || '';
    this.snum = data.snum || '';
    this.sort = data.sort || '';
    this.spjangcd = data.spjangcd || '';
    this.sunamt = data.sunamt || '';
    this.sunflag = data.sunflag || '';
    this.tax_spdate = data.spdate || '';
    this.tax_spnum = data.tax_spnum || '';
    this.taxcls = data.taxcls || '';
    this.wbamt = data.wbamt || '';
    this.wdamt = data.wdamt || '';
    this.weamt = data.weamt || '';
    this.wgamt = data.wgamt || '';
    this.whamt = data.whamt || '';
    this.winamt = data.winamt || '';
    this.wjamt = data.wjamt || '';
    this.wjmar = data.wjmar || '';
    this.wkactcd = data.wkactcd || '';
    this.wsamt = data.wsamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
