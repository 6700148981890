import { TLHF } from '../../../../../constants';
import {
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  OptionBox,
  TextBox,
} from '../../../../../components';
import { Regist } from '../Regist';
import { SearchBinding } from '../../../../../models';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { Today } from '../../../../../utils/time';

const Results = [
  { value: '0', remark: '미처리', color: 'var(--color-red)' },
  { value: '1', remark: '매입처리', color: 'inherit' },
  { value: '2', remark: '지급처리', color: 'var(--color-blue)' },
];

/**
 * 화면 > 상세 > 탭 - 설치비내역 > 테이블
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabTableScAmtHeader: TLHF<any, Regist> = (scope) => ([
  {
    id: 'seq',
    text: '순차',
    width: 70,
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.seq}차
      </FlexLayout>
    ),
  },
  {
    id: 'cltcd',
    text: '코드',
    width: 100,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.cltcd}
        textAlign="center"
        color={x.compflag !== '1' ? '#7F7A2C' : '' }
        onChange={(value) => rowUpdate({ cltcd: value })}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_XCLIENT',
          {},
          true,
          (item) => {
            rowUpdate({
              cltcd: item.cd,
              cltnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'cltnm',
    text: '담당자명(거래처명)',
    width: 250,
    render: (x, rowUpdate) => (
      <TextBox
        value={x.cltnm}
        onChange={(v) => rowUpdate({ cltnm: v })}
      />
    ),
  },
  {
    id: 'amt',
    text: '금액',
    width: 150,
    trail: (data) => {
      const sumAmt = Sum.all(data, 'amt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {Format.number(sumAmt)}
        </FlexLayout>
      );
    },
    render: (x, rowUpdate, refs) => (
      <FormatNumberTextBox
        refs={refs}
        textAlign="right"
        value={x.amt}
        onChange={(v) => rowUpdate({ amt: v })}
      />
    ),
  },
  {
    id: 'schdate',
    text: '지급예정일자',
    width: 100,
    render: (x, rowUpdate, refs) => (
      <DateTextBox
        refs={refs}
        textAlign="center"
        value={x.schdate || Today.date()}
        format="####-##-##"
        onChange={(v) => rowUpdate({ schdate: v })}
      />
    ),
  },
  {
    id: 'mijflag',
    text: '지급요청',
    width: 150,
    render: (x, rowUpdate) => (
      <OptionBox
        value={x.mijflag}
        data={[
          { value: '0', remark: '대기' },
          { value: '1', remark: '요청' },
        ]}
        onChange={(item) => rowUpdate({ mijflag: item.value })}
      />
    ),
  },
  {
    id: 'resultflag',
    text: '구분',
    width: 100,
    render: (x) => {
      const fp = Results.filter((y) => y.value === x.resultflag);
      const nm = fp.length > 0 ? fp[0].remark : '';
      const color = fp.length > 0 ? fp[0].color : '';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{ color }}
        >
          {nm}
        </FlexLayout>
      );
    },
  },
  {
    id: 'remark',
    text: '비고',
    width: 150,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
]);
