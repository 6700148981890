import { computed } from 'mobx';

/**
 * 모델
 * 검사이력정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentChangePersonModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  actcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  actnm: string;

  /**
   * 대수
   *
   * **data type** varchar(3)
   */
  qty: string;

  /**
   * 지역명
   *
   * **data type** varchar(100)
   */
  areanm: string;

  /**
   * 주소
   *
   * **data type** varchar(1)
   */
  address: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(100)
   */
  perid: string

  /**
   * 담당자명
   *
   * **data type** varchar(1)
   */
  pernm: string;

  /**
   * 확인
   *
   * **data type** varchar(8)
   */
  chk: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.qty = data.qty || '';
    this.areanm = data.areanm || '';
    this.address = data.address || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.chk = data.chk || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
