import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight, RiFolder2Line,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  ComboBox,
  ComboBoxModel,
} from '../../../../components/forms/ComboBox';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Date8 } from '../../../../utils/time';
import { FileStore } from './FileStore';
import { SearchBinding } from '../../../../models/common';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { FormatTextBox } from '../../../../components/forms/FormatTextBox';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { Button } from '../../../../components/forms/Button';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ModalView } from '../../../../components/layout/ModalView';
import { AttachmentBox } from '../../../../components/forms/AttachmentBox';

/*
 * 화면
 * @window w_tb_e080_attach_01
 * @category 파일보관함[첨부파일]
*/

export const FileStoreTemplate: React.FC<TemplateProps<FileStore>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={278}>
          <span style={{ marginRight: 30 }}>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout
          style={{ marginLeft: 4 }}
          size={237}
        >
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={500}>
          <span>제목/폴더명</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state.new === '0' ? '제목, 폴더명으로 검색하세요' : ''}
            value={scope.state.filenm}
            onChange={(value) => scope.setState({ filenm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={255}>
          <span style={{ marginRight: 30 }}>문서구분</span>
          <ComboBox
            value={scope.state.appnm}
            data={[
              { value: '%', remark: '전체' },
              { value: '파일보관', remark: '파일보관' },
              { value: '업무일지', remark: '업무일지' },
              { value: '휴가신청서', remark: '휴가신청서' },
              { value: '재증명발행', remark: '재증명발행' },
              { value: '휴일근무보고서', remark: '휴일근무보고서' },
              { value: '견적서', remark: '견적서' },
              { value: '설치견적서', remark: '설치견적서' },
              { value: '지출결의서', remark: '지출결의서' },
              { value: '발주서', remark: '발주서' },
              { value: '해지보고서', remark: '해지보고서' },
              { value: '전자계약서', remark: '전자계약서' },
              { value: '출장보고서', remark: '출장보고서' },
              { value: '교육보고서', remark: '교육보고서' },
              { value: '통합문서', remark: '통합문서' },
              { value: '자재청구서', remark: '자재청구서' },
              { value: '프로젝트', remark: '프로젝트' },
              { value: '공사등록', remark: '공사등록' },
              { value: '설치계약서', remark: '설치계약서' },
              { value: 'PLC', remark: 'PLC' },
              { value: '공문서', remark: '공문서' },
              { value: '도면', remark: '도면' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => scope.setState({ appnm: option.value },
              () => { scope.onRetrieveEvent(); })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={265}>
          <span style={{
            color: 'var(--color-red)',
            marginRight: 20,
          }}
          >
            등록자
          </span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            style={{ paddingRight: 25 }}
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={500}>
          <span style={{ marginRight: 40 }}>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder={scope.state?.new === '0' ? '파일명으로 검색하세요' : ''}
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout weight={2}>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'indate',
                text: '등록일자',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.indate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'appnum',
                text: '문서번호',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.appnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'appnm',
                text: '문서구분',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.appnm === '파일보관' ? 'var(--color-blue)' : '' }}
                  >
                    {x.appnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'title',
                text: '제목/폴더명',
                width: 50,
                render: (x, rowUpdate, refs) => (
                  <TextBox
                    refs={refs}
                    value={x.title}
                    onChange={(v) => rowUpdate({ title: v })}
                  />
                ),
              },
              {
                id: 'cnt',
                text: '첨부파일',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.cnt > '0' ? 'var(--color-blue)' : '' }}
                  >
                    {x.cnt}건
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '등록자',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'password',
                text: '비밀번호',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.password}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.fileStoreList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
        <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
          <LayoutTitle size={180}>파일</LayoutTitle>
          <FlexLayout justify='right'>
            <Button
              style={{ height: '25px', width: '60px' }}
              onClick={() => scope.openFileModal()}
            >
              내 PC
            </Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.onDeleteEvent2()}
            >
              삭제
            </Button>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.detailTable}
            header={[
              {
                id: 'chk',
                text: '삭제',
                width: 5,
                render: (x, rowUpdate) => (
                  <CheckBox
                    value={x.prtchk === '1'}
                    onChange={(v) => rowUpdate({ prtchk: v ? '1' : '0' })}
                    isDisabledTrackingStateChange={true}
                  />
                ),
              },
              {
                id: 'fileseq',
                text: '순번',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.fileseq}
                  </FlexLayout>
                ),
              },
              {
                id: 'filename',
                text: '파일명',
                width: 50,
                render: (x) => (
                  <FlexLayout align="center">
                    <FlexLayout justify="left">
                      {x.filename}
                    </FlexLayout>
                    <FlexLayout justify="right">
                      <Button
                        isIcon={true}
                        onClick={() => scope.openFileModal()}
                      >
                        <RiFolder2Line size={17}/>
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'fileext',
                text: '확장자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.fileext}
                  </FlexLayout>
                ),
              },
              {
                id: 'indate',
                text: '최종수정일',
                width: 15,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.indate}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '등록자',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.fileStoreDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => { scope.onUpdatedRows2(rows, updatedRows); }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
          />
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.isFileModalVisible}
        onClose={() => scope.closeFileModal()}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            appnum={scope.state.focusedFileStore?.appnum}
            appnm={scope.state.focusedFileStore?.appnm}
            isEnabledPreview={true}
          />
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
