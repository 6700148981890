import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  OptionBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ElectronicTax } from './ElectronicTax';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { ConfirmWarning } from '../../../../utils/confirm';

const recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'R ', remark: '열람' },
  { value: 'N ', remark: 'EMAIL송신실패' },
  { value: 'B ', remark: '발송전' },
];

const recivstatus2 = [
  { value: 'A', remark: 'KTNET접수' },
  { value: 'E', remark: 'KTNET오류' },
  { value: 'L', remark: '승인(화주)' },
  { value: 'D', remark: '거부(화주)' },
  { value: 'N', remark: '이메일전송실패' },
  { value: 'T', remark: '국세청1차접수' },
  { value: 'X', remark: '국세청오류' },
  { value: 'F', remark: '국세청최종접수' },
  { value: 'R', remark: '열람' },
  { value: 'N', remark: 'EMAIL송신실패' },
];

const regflags = [
  { value: '01', remark: '일반' },
  { value: '01', remark: '일반' },
  { value: '02', remark: '영세율' },
  { value: '03', remark: '위수탁' },
  { value: '04', remark: '수입' },
  { value: '05', remark: '영세율위수탁' },
];

const classcodes = [
  { value: '05', remark: '신규' },
  { value: '06', remark: '정정' },
  { value: '07', remark: '삭제' },
  { value: '9', remark: '오류' },
];

const documentfunccodes = [
  { value: 'CD', remark: '승인후신고' },
  { value: 'DM', remark: '메일재전송' },
  { value: 'DD', remark: '즉시신고' },
  { value: 'DE', remark: '익일신고' },
];

const taxbilltypecodes = [
  { value: '01', remark: '세금계산서' },
  { value: '02', remark: '수정세금계산서' },
  { value: '03', remark: '계산서' },
  { value: '04', remark: '수정계산서' },
];

/**
 * 화면
 * @window w_tb_ia090
 * @category 전자세금계산서발행
 */
export const ElectronicTaxTemplate: React.FC<TemplateProps<ElectronicTax>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout
        size={200}
        style={{ marginLeft: 24 }}
      >
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => setData({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() - 1);
              setData({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() + 1);
              setData({ stdate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={180}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => setData({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() - 1);
              setData({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() + 1);
              setData({ enddate: Date8.make(date) },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span style={{ color: 'var(--color-red)' }}>거래처</span>
        <TextBox
          textAlign="center"
          value={scope.state.cltcd}
          onChange={(value) => setData({ cltcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_XCLIENT',
            { uv_arg1: '2' },
            true,
            (item) => {
              setData({
                cltcd: item.cd,
                cltnm: item.cdnm,
              });
              scope.onRetrieveEvent();
            },
          )}
        />
        <TextBox
          weight={2}
          value={scope.state.cltnm}
          readonly={true}
          onChange={(value) => setData({ cltnm: value })}
        />
      </FlexLayout>

      <FlexLayout size={300}>
        <span>응답상태</span>
        <div>
          <OptionBox
            value={scope.state.recivstatus}
            data={[
              { value: '1', remark: '진행중' },
              { value: '%', remark: '전체' },
            ]}
            onChange={(item) => setData({ recivstatus: item.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </div>
      </FlexLayout>
    </SearchLayout>

    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      isSub={true}
    >
      <FlexLayout size={381}>
        <span>신고구분</span>
        <ComboBox
          value={scope.state.funccode}
          textAlign="center"
          style={{ marginLeft: 24 }}
          data={[
            { value: 'CD', remark: '승인후신고' },
            { value: 'DM', remark: '메일재전송' },
            { value: 'DD', remark: '즉시신고' },
            { value: 'DE', remark: '익일신고' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => setData({ funccode: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={325}>
        <span>발급일자</span>
        <FormatTextBox
          style={{ marginLeft: 11 }}
          format="####-##-##"
          textAlign="center"
          value={scope.state.regdate}
          onChange={(value) => setData({ regdate: value })}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      {
        scope.state.recivstatus === '%'
          ? ''
          : <Button
            onClick={() => scope.onSubmitEvent()}
            style={{
              borderRight: 'solid 1px #FFFFFF',
            }}
          >
            <div style={{ width: 75 }}>보내기</div>
          </Button>
      }

      <FlexLayout
        weight={5}
        align="center"
        style={{
          color: 'var(--color-red)',
          marginLeft: 15,
        }}
      >
        응답상태가 전체일경우는 전송할수없습니다.
      </FlexLayout>
    </SearchLayout>

    <FlexLayout isVertical={true}>
      <FlexLayout
        weight={1.5}
        onDoubleClick={() => scope.modalRetrive()}
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'no',
              text: 'No',
              width: 40,
            },
            {
              id: 'selchk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={scope.state.pushTotalCheck}
                    onChange={async (value) => scope.updateCheckAllToggle(value)}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
              width: 40,
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.selchk === '1'}
                    onChange={(value) => {
                      if (x.state === '02') {
                        ConfirmWarning.show('경고', '거래처가 휴업인 상태입니다.\n 국세청 발행 할 수 없습니다.');
                        return;
                      }

                      if (x.state === '03') {
                        ConfirmWarning.show('경고', '거래처가 폐업인 상태입니다.\n 국세청 발행 할 수 없습니다.');
                        return;
                      }
                      rowUpdate({ selchk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
            {
              id: 'spdate',
              text: '작성일자',
              width: 90,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.spdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '상호',
              width: 180,
              trail: () => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {Format.number(scope.state.total)}건
                </FlexLayout>
              ),
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.cltnm}
                  onChange={(value) => rowUpdate({ cltnm: value })}
                />
              ),
            },
            {
              id: 'state',
              text: '상태',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={
                    x.state === '01' ? { color: 'var(--color-blue)' }
                      : x.state === '02' ? { color: 'var(--color-red)' }
                        : x.state === '03' ? { color: 'var(--color-red)' } : ''
                  }
                >
                  {
                    x.state === '01' ? '정상'
                      : x.state === '02' ? '휴업'
                        : x.state === '03' ? '폐업' : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'suprice',
              text: '공급가액',
              width: 90,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.suprice_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.suprice)}
                </FlexLayout>
              ),
            },
            {
              id: 'taxamt',
              text: '부가세',
              width: 90,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.taxamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.taxamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'mamt',
              text: '합계금액',
              width: 90,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'pname',
              text: '품명',
              width: 180,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.pname}
                </FlexLayout>
              ),
            },
            {
              id: 'bigo',
              text: '비고',
              width: 240,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.bigo}
                </FlexLayout>
              ),
            },
            {
              id: 'agneremail',
              text: '담당이메일',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.agneremail}
                  onChange={(value) => rowUpdate({ agneremail: value })}
                />
              ),
            },
            {
              id: 'regdate',
              text: '발급일자',
              width: 90,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.regdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'regflag',
              text: '분류',
              width: 90,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.regflag}
                  style={{
                    padding: '0 0 0 6px',
                    fontSize: 12,
                  }}
                  data={regflags?.map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ regflag: option.value })}
                />
              ),
            },
            {
              id: 'pernm',
              text: '발급자',
              width: 70,
            },
            {
              id: 'mainkey',
              text: '발급코드',
              width: 150,
            },
            {
              id: 'recivstatus',
              text: '응답상태',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    color: x.recivstatus === 'F '
                      // eslint-disable-next-line no-nested-ternary
                      ? 'var(--color-blue)' : x.recivstatus === 'B ' || x.recivstatus === 'N ' || x.recivstatus === 'X ' || x.recivstatus === 'D '
                        ? 'var(--color-red)' : '',
                  }}
                >
                  {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'msgnumber',
              text: '승인번호',
              width: 170,
            },
            {
              id: 'taxsms',
              text: '담당핸드폰번호',
              width: 120,
              render: (x) => (
                <TextBox
                  textAlign="center"
                  value={x.taxsms}
                />
              ),
            },
            {
              id: 'biztypenm',
              text: '업태',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.biztypenm}
                  onChange={(value) => rowUpdate({ biztypenm: value })}
                />
              ),
            },
            {
              id: 'bizitemnm',
              text: '종목',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.bizitemnm}
                  onChange={(value) => rowUpdate({ bizitemnm: value })}
                />
              ),
            },
            {
              id: 'saupnum',
              text: '사업자번호',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="###-##-#####"
                  value={x.saupnum}
                />
              ),
            },
            {
              id: 'prenum',
              text: '주민번호',
              width: 120,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="######-#######"
                  value={x.prenum}
                />
              ),
            },
            {
              id: 'prenm',
              text: '대표자명',
              width: 70,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  value={x.prenm}
                  onChange={(value) => rowUpdate({ prenm: value })}
                />
              ),
            },
            {
              id: 'cltadres',
              text: '주소',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.cltadres}
                  onChange={(value) => rowUpdate({ cltadres: value })}
                />
              ),
            },
          ]}
          data={scope.state.electronicTaxList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
        />
      </FlexLayout>

      <FlexLayout weight={0.6}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'documentidentifier',
              text: '제출번호',
              width: 150,
            },
            {
              id: 'classcode',
              text: '발급종류',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {classcodes.filter((y) => y.value === x.classcode)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'responsetype',
              text: '응답유형',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    color: x.responsetype === 'A' || x.responsetype === 'L' || x.responsetype === 'T' || x.responsetype === 'F'
                      // eslint-disable-next-line no-nested-ternary
                      ? 'var(--color-blue)' : 'var(--color-red)',
                  }}
                >
                  {recivstatus2.filter((y) => y.value === x.responsetype)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'documentfunccode',
              text: '신고구분',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {documentfunccodes.filter((y) => y.value === x.documentfunccode)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'taxbilltypecode',
              text: '계산서종류',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {taxbilltypecodes.filter((y) => y.value === x.taxbilltypecode)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'amendmentstatuscode',
              text: '수정코드',
              width: 100,
            },
            {
              id: 'reverseissue',
              text: '역발행',
              width: 100,
            },
            {
              id: 'messageinformation1',
              text: '오류(거부)메시지1',
              width: 200,
            },
            {
              id: 'messageinformation2',
              text: '오류메시지2',
              width: 80,
            },
            {
              id: 'senddate',
              text: '발신일',
              width: 100,
            },
            {
              id: 'registdate',
              text: '응답수신시간',
              width: 100,
            },
            {
              id: 'recipientidentifier',
              text: '수신자번호',
              width: 100,
            },
            {
              id: 'senderidentifier',
              text: '발신자번호',
              width: 100,
            },
          ]}
          data={scope.state.electronicTaxDetailList}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        />
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
