import * as React from 'react';
import {
  FlexLayout, GridLayout, GridLayoutAdditionalHeader,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Status } from '../Status';
import { StatusTabMonthGridHeader } from '../headers/StatusTabMonthGridHeader';

/**
 * 화면 > 탭 월별건수
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTabMonthTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <GridLayoutAdditionalHeader
      ref={scope.additionalMonthHeader}
      height={32}
      contentWidth={2434}
    >
      <FlexLayout size={210} />

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        총
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        1월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        2월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        3월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        4월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        5월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        6월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        7월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        8월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        9월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        10월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        11월
      </FlexLayout>

      <FlexLayout
        justify="center"
        align="center"
        size={268}
      >
        12월
      </FlexLayout>
    </GridLayoutAdditionalHeader>

    <GridLayout
      ref={scope.gridMonth}
      infinityHandler={scope.infinityMonth}
      header={StatusTabMonthGridHeader(scope)}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      data={scope.state.dataMonth}
      onHorizontalScroll={(x) => { scope.additionalMonthHeader.current?.scrollTo(x); }}
      onRowClick={(item) => scope.onMonthRowFocusEvent(item)}
    />
  </FlexLayout>
);
