import * as React from 'react';
import {
  FlexLayout,
  TableLayout,
  TextBox,
  CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { TopDepartment } from './TopDepartment';

/**
 * 화면
 * @window w_pz008
 * @category 상위부서
 */
export const TopDepartmentTemplate: React.FC<TemplateProps<TopDepartment>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'kukcd',
          text: '상위부서코드',
          width: 10,
        },
        {
          id: 'kuknm',
          text: '상위부서명',
          width: 35,
          render: (x, rowUpdate, ref) => (
            <TextBox ref={ref} value={x.kuknm} onChange={(value) => rowUpdate({ kuknm: value })} />
          ),
        },
        {
          id: 'useyn',
          text: '사용유무',
          width: 10,
          render: (x, rowUpdate) => (
            <CheckBox value={x.useyn === '1'} onChange={(v) => rowUpdate({ useyn: v ? '1' : '0' })} />
          ),
        },
        {
          id: 'remarks',
          text: '비고',
          width: 45,
        },
      ]}
      data={scope.state.data}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
    />
  </FlexLayout>
);
