import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TabLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  KTNET_STATES,
  TemplateProps,
} from '../../../../constants';
import { Status } from './Status';
import { StatusTab } from './Status.tab';
import { SearchDateRange } from '../../../../components/search';
import { SearchBinding } from '../../../../models';
import { Date8 } from '../../../../utils/time';
import {
  Fix,
  Format,
} from '../../../../utils/string';
import { Sum } from '../../../../utils/array';


const Compyns = [
  { value: '%', remark: '전체' },
  { value: '0', remark: '견적' },
  { value: '1', remark: '공사' },
  { value: '2', remark: '매출' },
  { value: '3', remark: '폐기' },
  { value: '4', remark: '수주' },
  { value: '5', remark: '수금' },
  { value: '9', remark: '계획' },
  { value: ' ', remark: '견적전' },
];

/**
 * 화면
 * @window w_tb_e451w
 * @category 견적현황
 */
export const StatusTemplate: React.FC<TemplateProps<Status>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => scope.setState({ divicd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                scope.setState({ divicd: item.cd, divinm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.2}
            value={scope.state.divinm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.2}
            value={scope.state.pernm}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>상태</span>
          <ComboBox
            value={scope.state.compyn}
            textAlign="center"
            data={Compyns.map((x: any) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(v) => setData({ compyn: v.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        isSub={true}
      >
        <FlexLayout size={1202}>
          <span>검색어</span>
          <TextBox
            style={{ marginLeft: 10 }}
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 거래처로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={414}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={scope.state.gubuns2.map((x: any) => new ComboBoxModel(x.artcd, x.artnm))}
            onSelect={(v) => setData({ gubun: v.value })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={StatusTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.tab_3_modalChk}
        onClose={() => scope.SS({ tab_3_modalChk: false, popupDataDivinm: '0', popupDataPernm: '0' })}
        width={500}
        height={150}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>출력구분</LayoutTitle>

          <FlexLayout
            align="center"
            justify="center"
          >
            <FlexLayout>
              <CheckBox
                value={scope.state.popupDataAll === '1'}
                onChange={(v) => scope.setState({ popupDataAll: v ? '1' : '0', popupDataDivinm: '0', popupDataPernm: '0' })}
              />
              전체
            </FlexLayout>
            <FlexLayout>
              <CheckBox
                value={scope.state.popupDataDivinm === '2'}
                onChange={(v) => scope.setState({ popupDataDivinm: v ? '2' : '0', popupDataAll: '0', popupDataPernm: '0' })}
              />
              선택한부서
            </FlexLayout>
            <FlexLayout>
              <CheckBox
                value={scope.state.popupDataPernm === '3'}
                onChange={(v) => scope.setState({ popupDataPernm: v ? '3' : '0', popupDataAll: '0', popupDataDivinm: '0' })}
              />
              선택한담당자
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onMonthModal(true)}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.tab_3_modal}
        onClose={() => scope.SS({ tab_3_modal: false })}
        width={1800}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>견적현황[월별건수] 상세내역</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.popupStdate}
                format="####-##-##"
                onChange={(value) => scope.setState({ popupStdate: value })}
                onEnter={() => scope.onMonthModalRetrieve()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.popupStdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ popupStdate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.popupStdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ popupStdate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
              >
              </DateTextBox>
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.popupEnddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ popupEnddate: value })}
                onEnter={() => scope.onMonthModalRetrieve()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.popupEnddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ popupEnddate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.popupEnddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ popupEnddate: Date8.make(date) });
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.SS({ popupSearchQuery: value })}
                onEnter={() => scope.onMonthModalRetrieve()}
              >
              </TextBox>
            </FlexLayout>

            <FlexLayout size={250}>
              <span style={{ color: 'var(--color-red)' }}>부서</span>
              <TextBox
                textAlign="center"
                value={scope.state.popupDivicd}
                onChange={(value) => setData({ popupDivicd: value })}
                readonly={true}
              >
              </TextBox>
              <TextBox
                weight={1.2}
                value={scope.state.popupDivinm}
                readonly={true}
                onChange={(value) => setData({ popupDivinm: value })}
                textAlign="center"
              />
            </FlexLayout>

            <FlexLayout size={250}>
              <span style={{ color: 'var(--color-red)' }}>사용자</span>
              <TextBox
                textAlign="center"
                value={scope.state.popupPerid}
                onChange={(value) => setData({ popupPerid: value })}
                readonly={true}
              >
              </TextBox>
              <TextBox
                weight={1.2}
                value={scope.state.popupPernm}
                readonly={true}
                onChange={(value) => setData({ popupPernm: value })}
                textAlign="center"
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <FlexLayout>
                <span>상태</span>
              </FlexLayout>

              <FlexLayout>
                <CheckBox
                  value={scope.state.state0 === '1'}
                  onChange={(v) => scope.setState({ state0: v ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
                견적
              </FlexLayout>
              <FlexLayout>
                <CheckBox
                  value={scope.state.state1 === '1'}
                  onChange={(v) => scope.setState({ state1: v ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
                공사
              </FlexLayout>
              <FlexLayout>
                <CheckBox
                  value={scope.state.state2 === '1'}
                  onChange={(v) => scope.setState({ state2: v ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
                매출
              </FlexLayout>
              <FlexLayout>
                <CheckBox
                  value={scope.state.state5 === '1'}
                  onChange={(v) => scope.setState({ state5: v ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
                수금
              </FlexLayout>
            </FlexLayout>
            <Button onClick={() => scope.onMonthModalRetrieve()}>조회</Button>
          </SearchLayout>

          <GridLayout
            ref={scope.tab_3_modalGrid}
            data={scope.state.tab_3_modalData}
            header={[
              {
                id: 'divinmgroup',
                text: '부서그룹',
                group: true,
                isHidden: true,
                value: (x) => x.divinm,
              },
              {
                id: 'divinm',
                text: '부서명',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'costdate',
                text: '견적일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.costdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'costnum',
                text: '견적번호',
                width: 0.7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.costnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 3,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '구분',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.gubuns?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'constnm',
                text: '공사명',
                width: 3,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.constnm}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumConst = Sum.groupLength(data, 'constnm', x, ['divinmgroup']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {sumConst}건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.tab_3_modalTotal}건
                  </FlexLayout>
                ),
              },
              {
                id: 'costamt',
                text: '견적금액',
                width: 1.2,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.costamt)}
                  </FlexLayout>
                ),
                sum: (x, data) => {
                  const sumCostamt = Sum.byGroup(data, 'costamt', x, ['divinmgroup']);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumCostamt)}
                    </FlexLayout>
                  );
                },
                trail: (data) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(Sum.all(data, 'costamt'))}
                  </FlexLayout>
                ),
              },
              {
                id: 'compyn',
                text: '상태',
                width: 0.8,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.compyn === '0' ? 'var(--color-black)'
                        : x.compyn === '1' ? 'var(--color-green)'
                          : x.compyn === '2' ? 'var(--color-blue)'
                            : x.compyn === '3' ? 'var(--color-red)'
                              : x.compyn === '4' ? 'var(--color-red)' : 'var(--color-violet)',
                    }}
                  >
                    {
                      x.compyn === '0' ? '견적'
                        : x.compyn === '1' ? '공사'
                          : x.compyn === '2' ? '매출'
                            : x.compyn === '3' ? '폐기'
                              : x.compyn === '4' ? '수주' : '수금'
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'myn',
                text: '유무상',
                width: 0.8,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.myn === '1' ? 'var(--color-green)' : 'var(--color-blue)' }}
                  >
                    {x.myn === '1' ? '무상' : '유상'}
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '공사일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misdate',
                text: '매출일자',
                width: 1,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'receflag',
                text: '확인서수령여부',
                width: 1.2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.receflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                  >
                    {
                      x.receflag === '0' ? '미수령'
                        : x.receflag === '1' ? '어플수령'
                          : x.receflag === '2' ? '파일수령' : '문서수령'
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'recivstatus',
                text: '전자세금계산서',
                width: 1.2,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: KTNET_STATES.filter((y) => y.value === x.recivstatus)[0]?.color }}
                  >
                    {scope.state.recivstatus.filter((y) => y.com_code.trim() === x.recivstatus.trim())[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '특기사항',
                width: 4,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {Fix.newline(x.remark).replace(/\n/ig, ' ').substr(0, 60)}
                    {x.remark.length > 60 && '...'}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          >
          </GridLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.SS({ tab_3_modal: false })}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>

  );
};
