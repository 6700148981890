import { ReactNode } from 'react';
import { MenuModel } from '../../../models';

export type headerTabDataTableLayoutPreRendered = { [hash: string]: Array<ReactNode> };

export class HeaderTabDataModel {
  readonly id: string;

  readonly menu: MenuModel;

  state?: string;

  constructor(id: string, menu: MenuModel, state?: string) {
    this.id = id;
    this.menu = menu;
    this.state = state;
  }
}
