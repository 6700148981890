import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e509
 * @category 당직팀등록
 */
export class TeamEnrollModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly team: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly teamnm: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly useyn: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly sort: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly seq: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly gubun: string;

  /**
   * -
   *
   * **data type** varchar(50)
   */
  readonly remark: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.remark = data.remark || '';
    this.team = data.team || '';
    this.teamnm = data.teamnm || '';
    this.useyn = data.useyn || '';
    this.sort = data.sort || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.gubun = data.gubun || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
