import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { PayslipTemplate } from './Payslip.template';
import { PayslipModel } from './Payslip.model';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { AuthRepository } from '../../../../repositories';
import { AuthModel } from '../../../../models/component';
import { Sha256 } from '../../../../utils/string';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils/layout';

interface PayslipState {
  // search
  year: string;

  // data
  data: Array<PayslipModel>;
  focused?: PayslipModel;
  percentImage?: number;
  password: string;
  img?: string;

  // modal
  modalVisible?: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_04
 * @category 급여명세서
 */
@inject('toolStore')
export class Payslip extends PageComponent<PageProps, PayslipState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  graph?: any;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // search
      year: Today.year(),

      // data
      password: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.setState({ modalVisible: true });
  }

  @action
  async onKeyEvent(value: any) {
    const { publicStore } = this.props;

    const params = new AuthModel(
      `${publicStore.user.handphone}`.replace(/-/gi, ''),
      publicStore.user.userid,
      Sha256.make(value),
    );

    try {
      const response = await AuthRepository.login(params);

      if (response.status === 200) {
        // 성공
        this.setState({ modalVisible: false });
        this.onRetrieveEvent();
      } else {
        await ConfirmWarning.show('확인', '비밀번호가 틀렸습니다.');
      }
    } catch (_) {
      await ConfirmWarning.show('확인', '비밀번호가 틀렸습니다.');
    }
  }

  async onPageCloseEvent() {
    // @ts-ignore
    this.props.toolStore.onCloseHandler();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        perid: user.perid,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new PayslipModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['mon', 'tot_tamt'],
        [this.state.focused?.mon, this.state.focused?.tot_tamt], type, true) || 0;
      this.state.data && this.state.data.length > index && this.grid.current?.setFocus(index);
    });
    this.graph = `https://api.elmansoft.com/chart/money.php?window=money&type=0&database=${user.custcd}&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_perid=${user.perid}`;
  }

  @action
  onRowFocusEvent(item: PayslipModel) {
    this.setState({ focused: item });

    this.imageRequest(item);
  }

  async imageRequest(item: any) {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    const file = await api.fxFile(
      'tb_pic_retrieve',
      {
        stmon: item?.mon,
        perid: user.perid,
      },
    );

    if (file.size < 1) {
      return;
    }

    this.setState({
      img: `data:application/pdf;base64,${file.data}`,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stmon: this.state.focused?.mon,
      perid: user.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      perid: user.perid,
    });
  }

  render() {
    return (
      <PayslipTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
