import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Patrol } from './Patrol';
import {
  Date8,
  Today,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';

/**
 * 화면
 * @window w_tb_e039_01
 * @category 순찰일지
 */

export const PatrolTemplate: React.FC<TemplateProps<Patrol>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={210}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={180}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
          )}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <TableLayout
      ref={scope.table}
      header={[
        {
          id: 'patroldate',
          text: '순찰일자',
          width: 15,
          render: (x, rowUpdate, ref) => (
            <DateTextBox
              ref={ref}
              format="####-##-##"
              value={(!x.patroldate) ? `${Today.date()}` : x.patroldate }
              textAlign="center"
              readonly={x.new !== '1'}
              onChange={(value) => rowUpdate({ patroldate: value })}
            />
          ),
        },
        {
          id: 'seq',
          text: '번호',
          width: 7,
        },
        {
          id: 'sttime',
          text: '시작시간',
          width: 8,
          render: (x, rowUpdate, ref) => (
            <FormatTextBox
              ref={ref}
              textAlign="center"
              format="##:##"
              value={x.sttime}
              onChange={(value) => rowUpdate({ sttime: value })}
            />
          ),
        },
        {
          id: 'endtime',
          text: '종료시간',
          width: 8,
          render: (x, rowUpdate, ref) => (
            <FormatTextBox
              ref={ref}
              textAlign="center"
              format="##:##"
              value={x.endtime}
              onChange={(value) => rowUpdate({ endtime: value })}
            />
          ),
        },
        {
          id: 'actcd',
          text: '현장코드',
          width: 8,
          color: 'var(--color-red)',
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.actcd}
              textAlign="center"
              onChange={(value) => rowUpdate({ actcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_E601_1',
                {},
                true,
                (item) => {
                  rowUpdate({
                    actcd: item.cd,
                    actnm: item.cdnm,
                  });
                },
              )}
            />
          ),
        },
        {
          id: 'actnm',
          text: '현장명',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.actnm}
              textAlign="left"
              onChange={(value) => rowUpdate({ actnm: value })}
            />
          ),
        },
        {
          id: 'equpcd',
          text: '호기코드',
          width: 8,
          color: 'var(--color-red)',
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.equpcd}
              textAlign="center"
              onChange={(value) => rowUpdate({ equpcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_E611',
                { uv_arg1: x.actcd },
                true,
                (item) => {
                  rowUpdate({
                    equpcd: item.cd,
                    equpnm: item.cdnm,
                  });
                },
              )}
            />
          ),
        },
        {
          id: 'equpnm',
          text: '호기명',
          width: 15,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.equpnm}
              textAlign="left"
              onChange={(value) => rowUpdate({ equpnm: value })}
            />
          ),
        },
        {
          id: 'wkcd',
          text: '업무구분',
          width: 10,
          render: (x, rowUpdate, _) => (
            <ComboBox
              textAlign="center"
              value={x.wkcd}
              data={scope.state.wkcds.map((y) => new ComboBoxModel(y.busicd, y.businm))}
              onSelect={(option) => rowUpdate({ wkcd: option.value })}
            />
          ),
        },
        {
          id: 'perid',
          text: '처리자',
          width: 8,
          color: 'var(--color-red)',
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.perid}
              textAlign="center"
              onChange={(value) => rowUpdate({ perid: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  rowUpdate({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                },
              )}
            />
          ),
        },
        {
          id: 'pernm',
          text: '처리자명',
          width: 10,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.pernm}
              textAlign="center"
              onChange={(value) => rowUpdate({ pernm: value })}
            />
          ),
        },
        {
          id: 'remark',
          text: '특이사항',
          width: 30,
          render: (x, rowUpdate, ref) => (
            <TextBox
              ref={ref}
              value={x.remark}
              textAlign="left"
              onChange={(value) => rowUpdate({ remark: value })}
            />
          ),
        },
      ]}
      data={scope.state.patrolList}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onChange={(rows, updatedRows) => {
        scope.onUpdatedRows(rows, updatedRows);
      }}
      onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
    />

    <ModalView
      isVisible={scope.state.selectGubunModal}
      onClose={() => scope.selectGubunModal(false)}
      width={520}
      height={150}
    >
      <FlexLayout
        style={{ padding: 8 }}
        isVertical={true}
      >
        <LayoutTitle>출력구분</LayoutTitle>
        <FlexLayout
          align='center'
          justify='center'
        >
          <OptionBox
            value={scope.state?.gubun}
            data={[
              { value: '1', remark: '현장호기별' },
              { value: '2', remark: '일자별' },
            ]}
            onChange={(item) => scope.setState({ gubun: item.value })}
          />
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button
              onClick={() => {
                scope.selectGubunModal(false);
                scope.onPrint();
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
