import * as React from 'react';
import {
  FlexLayout, TableLayout, TabView,
} from '../../../../components';
import {
  Remodel,
  RemodelTabDataStateNames,
  RemodelTabFocusedStateNames,
  RemodelTabUpdatesStateNames,
} from './Remodel';
import { Global, TemplateProps } from '../../../../constants';


/**
 * 화면
 * @window w_tb_e450_bom_15
 * @category 견적리모델링제품등록
 */
export const RemodelTemplate: React.FC<TemplateProps<Remodel>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          // @ts-ignore
          data={scope.state[RemodelTabDataStateNames[scope.tabIndex]]}
          onRowFocusEvent={(item) => setData({
            [RemodelTabFocusedStateNames[scope.tabIndex]]: item,
          })}
          onChange={(rows, updatedRows) => setData({
            [RemodelTabDataStateNames[scope.tabIndex]]: rows,
            [RemodelTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
          })}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
