import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InspectionTypeModel } from './InspectionType.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { InspectionTypeTemplate } from './InspectionType.template';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface InspectionTypeState {
  searchQuery: string;
  data: Array<InspectionTypeModel>;
  focused?: InspectionTypeModel;
}

/**
 * 컨트롤러
 * @window w_tb_e030_new
 * @category 점검기종등록
 */
export class InspectionType extends PageComponent<PageProps, InspectionTypeState>
  implements PageToolEvents {
  updatedRows?: Array<InspectionTypeModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new InspectionTypeModel(x))],
        }, () => {
          this.table.current?.update();
          next && next();
        });
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        this.table.current?.setFocus(0, 1);
      },
    );

    await this.SS({ data: [] });
    await this.infinity?.retrieveAll();
    this.table.current?.setFocus(0, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      data: [
        new InspectionTypeModel(data, true),
        ...this.state.data,
      ],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.evcd} - ${this.state.focused?.evnm}`;
    await api.delete(text, this.state.focused) && await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel();
  }

  @action
  onRowFocusEvent(item: InspectionTypeModel) {
    this.setState({ focused: item });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: Array<InspectionTypeModel>, updatedRows: Array<InspectionTypeModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  render() {
    return (
      <InspectionTypeTemplate
        scope={this}
        update={(change: InspectionTypeModel[]) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
