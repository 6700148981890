import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  CheckBox,
  DateTextBox,
  FlexLayout,
  SearchLayout,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ClientMonth } from './ClientMonth';
import { ClientMonthTab } from './ClientMonth.tab';

/**
 * 화면
 * @window w_tb_ca642w_17
 * @category 거래처월별 매입/지급현황
 */
export const ClientMonthTemplate: React.FC<TemplateProps<ClientMonth>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.SS({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
        />
      </FlexLayout>

      <FlexLayout size={500}>
        <span>검색어</span>
        <TextBox
          value={scope.state.searchQuery}
          onChange={(value) => scope.SS({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
        >
        </TextBox>
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout size={160}>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'canm',
              text: '업종',
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.cnam}
                </FlexLayout>
              ),
            },
            {
              id: 'chk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    value={scope.state.allChk}
                    onChange={(value) => scope.onCheckAll(value)}
                  >
                  </CheckBox>
                </FlexLayout>
              ),
              width: 60,
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    value={x.chk === '1'}
                    onChange={(value) => {
                      rowUpdate({ chk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  >
                  </CheckBox>
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows) => {
            scope.onUpdatedRows(rows);
          }}
          data={scope.state.leftData}
        >
        </TableLayout>
      </FlexLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={ClientMonthTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />

        {/* <GridLayout */}
        {/*  ref={scope.grid} */}
        {/*  header={[ */}
        {/*    { */}
        {/*      id: 'cltnm', */}
        {/*      text: '거래처명', */}
        {/*      width: 14, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          justify="left" */}
        {/*          align="center" */}
        {/*        > */}
        {/*          {x.cltnm} */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout */}
        {/*          justify="center" */}
        {/*          align="center" */}
        {/*        > */}
        {/*          합계 */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon01', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            01월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt01)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt01)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt01)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt01_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt01_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt01_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon02', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            02월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt02)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt02)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt02)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt02_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt02_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt02_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon03', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            03월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt03)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt03)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt03)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt03_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt03_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt03_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon04', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            04월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt04)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt04)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt04)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt04_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt04_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt04_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon05', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            05월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt05)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt05)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt05)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt05_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt05_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt05_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon06', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            06월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt06)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt06)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt06)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt06_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt06_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt06_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon07', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            07월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt07)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt07)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt07)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt07_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt07_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt07_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon08', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            08월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt08)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt08)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt08)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt08_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt08_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt08_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon09', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            09월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt09)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt09)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt09)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt09_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt09_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt09_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon10', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            10월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt10)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt10)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt10)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt10_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt10_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt10_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon1', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            11월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt11)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt11)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt11)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt11_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt11_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt11_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'mon12', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: '#008080' }} */}
        {/*          > */}
        {/*            12월 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt12)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt12)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt12)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt12_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt12_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt12_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*    { */}
        {/*      id: 'monTot', */}
        {/*      text: ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            합계 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            매입금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            지급금액 */}
        {/*          </FlexLayout> */}

        {/*          <FlexLayout */}
        {/*            justify="center" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            미지금액 */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      width: 10, */}
        {/*      render: (x) => ( */}
        {/*        <FlexLayout */}
        {/*          isVertical={true} */}
        {/*          style={{ backgroundColor: '#A6CAF0' }} */}
        {/*        > */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="start" */}
        {/*          > */}
        {/*            {Format.number(x.mijamt_tot)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-blue)' }} */}
        {/*          > */}
        {/*            {Format.number(x.sndamt_tot)} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: 'var(--color-red)' }} */}
        {/*          > */}
        {/*            {Format.number(x.iamt_tot)} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*      trail: () => ( */}
        {/*        <FlexLayout isVertical={true}> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*          > */}
        {/*            {scope?.state.mijamt_tot_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#60C5F1' }} */}
        {/*          > */}
        {/*            {scope?.state.sndamt_tot_tot} */}
        {/*          </FlexLayout> */}
        {/*          <FlexLayout */}
        {/*            justify="right" */}
        {/*            align="center" */}
        {/*            style={{ color: '#FF00FF' }} */}
        {/*          > */}
        {/*            {scope?.state.iamt_tot_tot} */}
        {/*          </FlexLayout> */}
        {/*        </FlexLayout> */}
        {/*      ), */}
        {/*    }, */}
        {/*  ]} */}
        {/*  rowHeight={48} */}
        {/*  headerHeight={60} */}
        {/*  data={scope.state.cltList} */}
        {/* > */}
        {/* </GridLayout> */}
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
