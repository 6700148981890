import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  AttachmentBox,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  Project,
  ProjectTabDataStateNames,
  ProjectTabId,
} from './Project';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Date6Calculator,
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면
 * @window w_tb_da003_01w
 * @category 프로젝트현황
 */
export const ProjectTemplate: React.FC<TemplateProps<Project>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={220}>
              <span>년월</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##"
                onChange={(value) => setData({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    setData({ stdate: new Date6Calculator(scope.state.stdate).add(-1).toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    setData({ stdate: new Date6Calculator(scope.state.stdate).add().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout
              size={5}
              justify="center"
              align="center"
            >
              ~
            </FlexLayout>

            <FlexLayout size={170}>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##"
                onChange={(value) => setData({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    setData({ enddate: new Date6Calculator(scope.state.enddate).add(-1).toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    setData({ enddate: new Date6Calculator(scope.state.enddate).add().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder="프로젝트로 검색하세요"
                value={scope.state.searchQuery}
                onChange={(value) => setData({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={200}>
              <span>기준</span>
              <ComboBox
                value={scope.state.searchDateflag}
                data={[
                  { value: '1', remark: '계약일자' },
                  { value: '2', remark: '검사일자' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ searchDateflag: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout size={200}>
              <span>상태</span>
              <ComboBox
                value={scope.state.state}
                data={[
                  { value: '0', remark: '진행중' },
                  { value: '1', remark: '완료' },
                  { value: '%', remark: '전체' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setData({ state: option.value },
                  () => scope.onRetrieveEvent())}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout>
          <FlexLayout weight={0.15}>
            <TableLayout
              ref={scope.table}
              header={[
                {
                  id: 'cnam',
                  text: '구분',
                  width: 70,
                  render: (x) => (
                    <FlexLayout
                      align='center'
                      justify='center'
                    >
                      {x.cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'chk',
                  text: (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={scope.state.pushTotalCheck}
                        onChange={async (value) => scope
                          .updateCheckAllToggle(value)}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                  width: 30,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        noMargin
                        value={x.chk === '1'}
                        onChange={(value) => {
                          rowUpdate({ chk: value ? '1' : '0' });
                        }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows2(rows, updatedRows);
              }}
              data={scope.state.gubuns}
            />
          </FlexLayout>

          <FlexLayout>
            <GridLayout
              ref={scope.grid}
              header={[
                {
                  id: 'state',
                  text: '상태',
                  width: 5,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <ComboBox
                      value={x.state}
                      data={[
                        { value: '0', remark: '진행중' },
                        { value: '1', remark: '완료' },
                        { value: '2', remark: '취소' },
                      ].map((y) => new ComboBoxModel(y.value, y.remark))}
                      onSelect={(_option) => { scope.itemchanged(_option.value); }}
                    />
                  ),
                },
                {
                  id: 'gubun',
                  text: '구분',
                  width: 6,
                  render: (x) => (
                    <FlexLayout
                      align='center'
                      justify='center'
                    >
                      {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'projno',
                  text: '번호',
                  width: 5,
                },
                {
                  id: 'projnm',
                  text: '프로젝트명',
                  width: 15,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.projnm}
                    </FlexLayout>
                  ),
                },
                scope.state.searchDateflag === '1' ? {
                  id: 'orddate',
                  text: '계약일자',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.orddate)}
                    </FlexLayout>
                  ),
                } : {
                  id: 'kcdate',
                  text: '검사(완료)일자',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.kcdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'camt',
                  text: '수주금액',
                  width: 8,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.camt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.camt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'ex_mijamt',
                  text: '예상지출금액',
                  width: 8,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.ex_mijamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.ex_mijamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'balamt',
                  text: '발주금액',
                  width: 8,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.balamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.balamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '매출금액',
                  width: 8,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.misamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mijamt',
                  text: '소요경비',
                  width: 8,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.mijamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.mijamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'rcvflag',
                  text: '수금여부',
                  width: 4,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.rcvflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                      {x.rcvflag === '1' ? '수금' : '미수금'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resuamt',
                  text: '손익액',
                  width: 8,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.resuamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resupercnt',
                  text: '손익률',
                  width: 5,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.resupercnt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    x.resupercnt !== '0.00%' && x.resupercnt !== '%'
                      ? <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: '#8106AA' }}>
                        {x.resupercnt}
                      </FlexLayout>
                      : <FlexLayout>
                      </FlexLayout>
                  ),
                },
                {
                  id: 'resuamt2',
                  text: '미매출잔액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.resuamt2_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt2)}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity}
              onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
              onRowDoubleClick={() => scope.onClickOpenBalEnrollment()}
              data={scope.state.data}
            />
          </FlexLayout>
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={1.5}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
            <Button
              style={{
                height: '25px',
                width: '140px',
                marginTop: 12,
                marginRight: 850,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onButtonExcel()}>
              EXCEL 자료
            </Button>
          </FlexLayout>

          <FlexLayout>
            {(scope.tabIndex === ProjectTabId.Attach)
              ? <AttachmentBox
                appnum={`${scope.state.focused?.projno}`}
                isEnabledPreview={true}
                appnm={'프로젝트현황'}
              />
              : <GridLayout
                ref={scope.grid2}
                header={scope.tabHeaders[scope.tabIndex]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={scope.infinity2}
                scope={scope}
                // @ts-ignore
                data={scope.state[ProjectTabDataStateNames[scope.tabIndex]] || [] }
                onRowClick={
                  (scope.tabIndex === ProjectTabId.Order)
                    ? (item) => scope.onRowFocusEvent2(item)
                    : () => {}
                }
              />}

            {(scope.tabIndex === ProjectTabId.Order)
              && <FlexLayout weight={2}> <GridLayout
                ref={scope.grid3}
                header={[
                  {
                    id: 'title',
                    text: '제목',
                    width: 200,
                    trail: () => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        합 계
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.title}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'baldate',
                    text: '발주일자',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Date8.withDash(x.baldate)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'balnum',
                    text: '번호',
                    width: 100,
                  },
                  {
                    id: 'balseq',
                    text: '순번',
                    width: 80,
                  },
                  {
                    id: 'pname',
                    text: '품목명',
                    width: 150,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.pname}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'psize',
                    text: '규격',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.psize}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'punit',
                    text: '단위',
                    width: 80,
                  },
                  {
                    id: 'qty',
                    text: '수량',
                    width: 80,
                  },
                  {
                    id: 'uamt',
                    text: '단가',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.uamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'samt',
                    text: '공급금액',
                    width: 100,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.tab_samt_tot2}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.samt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tamt',
                    text: '부가세',
                    width: 100,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.tab_tamt_tot2}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.tamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'mamt',
                    text: '합계',
                    width: 100,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.tab_mamt_tot2}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(x.mamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 200,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                      >
                        {x.actnm}
                      </FlexLayout>
                    ),
                  },
                ]}
                scope={scope}
                data={scope.state.ordersDetail}
                infinityHandler={scope.infinity3}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
              </FlexLayout>}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
