import * as React from 'react';
import {
  GiSave,
  RiFolder2Line,
} from 'react-icons/all';
import {
  AddressButton, AttachmentBox,
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  ModalView,
  OptionBox,
  SearchLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import {
  BtnGubun,
  Report,
} from './Report';
import { ReportGridHeader } from './headers/ReportGridHeader';
import { SearchDateRange } from '../../../../components/search';
import { SearchBinding } from '../../../../models';
import ReportModel from './models/ReportModel';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Date8 } from '../../../../utils/time';
import { CallHistoryModel } from './models/CallHistoryModel';

/**
 * 화면
 * @window w_tb_b502
 * @category 영업보고서
 */
export const ReportTemplate: React.FC<TemplateProps<Report>> = ({
  scope,
}) => {
  const content = scope.state?.content;
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);
  const setContent = (data: any, callback?: () => void) => scope?.setState({
    content: new ReportModel({
      ...content,
      ...data,
    }, content?.isNew),
  }, callback);
  const { user } = scope.props.publicStore;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => setData({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={200}>
          <span>결과</span>
          <ComboBox
            value={scope.state.state}
            textAlign="center"
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계획' },
              { value: '2', remark: '방문' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ state: option.value }, () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout weight={2}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 상담내용, 협의내용으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>담당</span>
          <TextBox
            textAlign="center"
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={150}>
          <span>구분</span>
          <ComboBox
            value={scope.state.searchGubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '11', remark: '보수영업' },
              { value: '14', remark: '설치공사' },
              { value: '15', remark: '리모델링' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ searchGubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <Button onClick={() => scope.onClickSMS()}>문자전송</Button>

        <Button
          disabled={scope.state.reportVisible}
          onClick={() => scope.onSubmitButtonClicked()}
          style={scope.state?.gridFocus?.appgubun === '101' ? {
            opacity: 0.5,
          } : {}}
        >
          {scope.state.gridFocus?.isReported ? '상신취소' : '결재상신'}
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout weight={0.6}>
          <GridLayout
            ref={scope.grid}
            infinityHandler={scope.infinity}
            header={ReportGridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
          />
        </FlexLayout>

        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={1.2}
              title="영업계획일자"
              isNeed={true}
              footer={
                <Button
                  isMini={true}
                  theme={BUTTON_THEMES.BLUE}
                  onClick={() => scope.setState({ planDateBtnFlag: true }, () => scope.SS({ beforeplandate: content!.plandate }))}
                >
                  변경
                </Button>
              }
            >
              <DateTextBox
                value={content?.plandate}
                textAlign="center"
                format="####-##-##"
                readonly={!scope.state.planDateBtnFlag && !content?.isNew}
                onChange={(v) => (
                  content?.isNew ? setContent({ plandate: v })
                    : setContent({ plandate: v }, () => scope.plandateButtonClick()))}
              />

              <TextBox
                weight={0.5}
                textAlign="center"
                value={content?.plannum}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="상태" weight={0.8}>
              <OptionBox
                value={content?.state}
                data={[
                  { value: '1', remark: '계획' },
                  { value: '2', remark: '처리' },
                ]}
                onChange={(item) => setContent({ state: item.value })}
              />
            </LabelBox>

            <LabelBox
              weight={1}
              title="최초접수일자"
            >
              <DateTextBox
                value={content?.recedate}
                textAlign="center"
                format="####-##-##"
                readonly={!content?.isNew}
                onChange={(v) => setContent({ recedate: v })}
              />
            </LabelBox>

            <LabelBox
              title="방문자(처리자)"
              weight={1}
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content?.perid}
                textAlign="center"
                onChange={(value) => setContent({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setContent({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />

              <TextBox
                textAlign="center"
                value={content?.pernm}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              title="방문처리일자"
              weight={1}
              isNeed={true}
            >
              <DateTextBox
                value={content?.compdate}
                textAlign="center"
                format="####-##-##"
                readonly={content?.isNew}
                onChange={(v) => setContent({ compdate: v })}
              />
            </LabelBox>

            {/* <LabelBox */}
            {/*  weight={1} */}
            {/*  title="방문처리일시" */}
            {/* > */}
            {/*  <FormatTextBox */}
            {/*    value={content?.sttime} */}
            {/*    format="##:##" */}
            {/*    textAlign="center" */}
            {/*    onChange={(v) => setContent({ sttime: v })} */}
            {/*  /> */}

            {/*  <span */}
            {/*    style={{ */}
            {/*      display: 'inline-flex', */}
            {/*      justifyContent: 'center', */}
            {/*      alignItems: 'center', */}
            {/*      padding: '0 3px', */}
            {/*    }} */}
            {/*  > */}
            {/*    ~ */}
            {/*  </span> */}

            {/*  <FormatTextBox */}
            {/*    value={content?.endtime} */}
            {/*    format="##:##" */}
            {/*    textAlign="center" */}
            {/*    onChange={(v) => setContent({ endtime: v })} */}
            {/*  /> */}
            {/* </LabelBox> */}

            <LabelBox title="문서번호" weight={1}>
              <FormatTextBox
                format={content?.appnum && '##-######-###-####'}
                textAlign="center"
                value={user.papernum + content?.appnum}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="고객명(현장명)"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                value={content?.actcd}
                textAlign="center"
                onChange={(value) => setContent({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_ALL',
                  {},
                  true,
                  (item) => {
                    setContent({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });

                    scope.itemChanged('actcd', item.cd);
                  },
                )}
              />

              <TextBox
                weight={2}
                value={content?.actnm}
                onChange={(value) => setContent({ actnm: value })}
              />
            </LabelBox>

            <LabelBox
              title="주소"
              style={Global.STYLE_COLOR_RED}
              footer={<FlexLayout>
                <AddressButton
                  publicStore={scope.props.publicStore}
                  modalStore={scope.props.modalStore}
                  onChange={(address) => setContent({
                    zipcd: address.zonecode,
                    address: address.address,
                    address2: address.jibunAddress,
                    lat: address.y,
                    lng: address.x,
                  })}
                >
                  주소검색
                </AddressButton>

                <DefaultLayout
                  size={150}
                  style={{ marginLeft: 16 }}
                >
                  {`위도: ${content?.lat}`}
                </DefaultLayout>

                <DefaultLayout size={150}>
                  {`경도: ${content?.lng}`}
                </DefaultLayout>
              </FlexLayout>}
            >
              <TextBox
                textAlign="center"
                value={content?.zipcd}
                onChange={(value) => setContent({ zipcd: value })}
                readonly={true}
              />

              <TextBox
                weight={3}
                value={content?.address}
                readonly={true}
                onChange={(value) => setContent({ address: value })}
              />

              <TextBox
                weight={3}
                value={content?.address2}
                onChange={(value) => setContent({ address2: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={1.3}
              title="계약기간"
            >
              <DateTextBox
                value={content?.stdate}
                format="####-##-##"
                textAlign="center"
                onChange={(v) => setContent({ stdate: v })}
              />

              <span
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 3px',
                }}
              >
                ~
              </span>

              <DateTextBox
                value={content?.enddate}
                format="####-##-##"
                textAlign="center"
                onChange={(v) => setContent({ enddate: v })}
              />
            </LabelBox>

            <LabelBox title="기종(모델)" weight={0.7}>
              <TextBox
                value={content?.mdnm}
                onChange={(v) => setContent({ mdnm: v })}
              />
            </LabelBox>

            <LabelBox
              weight={0.5}
              title="대수"
            >
              <TextBox
                textAlign="center"
                value={content?.qty}
                onChange={(v) => setContent({ qty: v })}
              />
            </LabelBox>

            <LabelBox
              weight={0.5}
              title="보수료"
            >
              <FormatNumberTextBox
                textAlign="right"
                value={content?.amt}
                onChange={(v) => setContent({ amt: v })}
              />
            </LabelBox>

            <LabelBox title="보수업체" weight={1}>
              <TextBox
                value={content?.bosunm}
                onChange={(v) => setContent({ bosunm: v })}
              />
            </LabelBox>

            <LabelBox title="관리책임자" weight={1}>
              <TextBox
                textAlign="left"
                value={content?.bosupernm}
                onChange={(v) => setContent({ bosupernm: v })}
              />
            </LabelBox>

            <LabelBox title="관리책임자연락처" weight={1}>
              <TelnumTextBox
                textAlign="left"
                value={content?.bosuhpnum}
                onChange={(v) => setContent({ bosuhpnum: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="상담자" weight={0.9}>
              <TextBox
                weight={1}
                textAlign="left"
                value={content?.advicepernm}
                onChange={(v) => setContent({ advicepernm: v })}
              />
            </LabelBox>

            <LabelBox title="상담자연락처">
              <TelnumTextBox
                weight={1}
                textAlign="left"
                value={content?.advicehpnum}
                onChange={(v) => setContent({ advicehpnum: v })}
              />
            </LabelBox>

            <LabelBox
              weight={1.1}
              title="상담자메일"
            >
              <TextBox
                value={content?.advicemail}
                onChange={(v) => setContent({ advicemail: v })}
              />
            </LabelBox>

            <LabelBox title="구분" weight={1}>
              <ComboBox
                value={content?.gubun}
                data={[
                  { value: '11', remark: '보수영업' },
                  { value: '14', remark: '설치영업' },
                  { value: '15', remark: '리모델링' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setContent({ gubun: option.value })}
              />
            </LabelBox>

            <LabelBox title="구분" weight={1}>
              <ComboBox
                value={content?.contflag}
                data={[
                  { value: '0', remark: '관급' },
                  { value: '1', remark: '사급' },
                  { value: '2', remark: '건축사' },
                  { value: '3', remark: '설계사' },
                  { value: '4', remark: '기타' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setContent({ contflag: option.value })}
              />
            </LabelBox>

            <LabelBox title="현장보수담당자">
              <TextBox
                weight={1}
                textAlign="center"
                // readonly={true}
                value={content?.actpernm}
                onChange={(v) => setContent({ actpernm: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox
              title="상담내용"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(BtnGubun.b_adviceremark)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <TextBox
                textAlign="left"
                isMultiline={true}
                value={content?.adviceremark}
                onChange={(v) => setContent({ adviceremark: v })}
              />
            </LabelBox>

            <LabelBox
              title="협의내용 및 조치내용"
              footer={
                <Button
                  isIcon={true}
                  style={{ fontSize: 22 }}
                  onClick={() => scope.onButtonclick(BtnGubun.b_resultremark)}
                >
                  <GiSave/>
                </Button>
              }
            >
              <TextBox
                textAlign="left"
                isMultiline={true}
                value={content?.resultremark}
                onChange={(v) => setContent({ resultremark: v })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={2}
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={content?.refer}
                readonly={true}
              />
            </LabelBox>

            <LabelBox
              weight={1.5}
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => setData({ isVisibleAttachment: true })}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: content?.attcnt === '0' ? Global.STYLE_COLOR_RED : Global.STYLE_COLOR_BLUE }}
                value={content?.attcnt === '0' ? '첨부파일 0건 없음' : `첨부파일 ${content?.attcnt}건 있음`}
                readonly={true}
              />
            </LabelBox>

            <LabelBox title="콜센터(접수자)">
              <TextBox
                value={content?.reperid}
                readonly={true}
              />

              <TextBox
                value={content?.repernm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="통화이력">
              <GridLayout
                ref={scope.callHistoryGrid}
                header={[
                  {
                    id: 'callmemo',
                    text: '내용',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={x.callmemo === '' ? { color: 'var(--color-red)' } : { color: 'var(--color-blue)' }}
                      >
                        {x.callmemo === '' ? 'X' : 'O'}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'calldate',
                    text: '일자',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {Date8.withDash(x.calldate)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'calltime',
                    text: '시간',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.calltime.substr(0, 2)}:{x.calltime.substr(2, 2)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'callnum',
                    text: '전화번호',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.callnum}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'pernm',
                    text: '접수자',
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {x.pernm}
                      </FlexLayout>
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={scope.state.callHistoryData}
                onRowClick={(item) => scope.callHistoryOnRowFocusEvent(item)}
              >
              </GridLayout>
            </LabelBox>

            <LabelBox title="통화내용(이력)">
              <TextBox
                value={scope.state.callHistoryRowFocusedData?.callmemo}
                onChange={(value) => scope.setState({
                  callHistoryRowFocusedData: new CallHistoryModel({
                    ...scope.state.callHistoryRowFocusedData,
                    callmemo: value,
                  }),
                })}
                isMultiline={true}
                readonly={scope.state.callHistoryData?.length === undefined}
              >
              </TextBox>
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>


      {/* 첨부파일 */}
      <ModalView
        width={1300}
        isVisible={scope.state.isVisibleAttachment}
        onClose={() => setData({ isVisibleAttachment: false })}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{
            padding: 8,
          }}
        >
          <AttachmentBox
            appnum={content?.appnum}
            appnm="영업보고서"
            isEnabledTitle={true}
            isEnabledPreview={true}
            onChangeCount={async (count) => {
              setContent({ attcnt: count.toString() });
            }}
          />

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <Button
              isFull={true}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => setData({ isVisibleAttachment: false })}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
