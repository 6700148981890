export default class Time6 {
  static make(date?: Date): string {
    const now = date || new Date();
    const hours = now.getHours();
    const mins = now.getMinutes();
    const secs = now.getSeconds();
    return `${hours < 10 ? `0${hours}` : hours}${
      mins < 10 ? `0${mins}` : mins
    }${secs < 10 ? `0${secs}` : secs}`;
  }

  static withDash(date?: Date): string {
    const str = Time6.make(date);
    return `${str.substr(0, 2)}:${str.substr(2, 2)}:${str.substr(4, 2)}`;
  }

  static withKor(date?: Date): string {
    const str = Time6.make(date);
    return `${str.substr(0, 2)}시 ${str.substr(2, 2)}분 ${str.substr(4, 2)}초`;
  }
}
