/**
 * 견적 불러오기 모델
 * @window w_tb_e471
 * @category 공사등록
 */
export default class EnrollmentPullModel {
  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly flag: string;

  /**
   * 견적일
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 견적서명
   *
   * **data type** varchar(8)
   */
  readonly constnm: string;

  /**
   * 공사일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 공사명
   *
   * **data type** varchar(8)
   */
  readonly offinm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  readonly cltnm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  readonly zipcode: string;

  /**
   * 주소
   *
   * **data type** varchar(8)
   */
  readonly address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(8)
   */
  readonly address2: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly taxflag: string;

  /**
   * 담당자명
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 담당 전화번호
   *
   * **data type** varchar(8)
   */
  readonly pertel: string;

  /**
   * 담당 휴대전화번호
   *
   * **data type** varchar(8)
   */
  readonly perhp: string;

  /**
   * 담당 이메일
   *
   * **data type** varchar(8)
   */
  readonly permail: string;

  /**
   * 공사자
   *
   * **data type** varchar(8)
   */
  readonly compperid: string;

  /**
   * 공사자2
   *
   * **data type** varchar(8)
   */
  readonly compperid2: string;

  /**
   * 공사자3
   *
   * **data type** varchar(8)
   */
  readonly compperid3: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 자사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 공사완료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly taxyn: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 부가세액
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 합계금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 할인율
   *
   * **data type** varchar(8)
   */
  readonly saleyul: string;

  /**
   * 할인금액
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   * 할인구분
   *
   * **data type** varchar(8)
   */
  readonly saleflag: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remarks: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s2flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly s3flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ks2flag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly ks3flag: string;

  /**
   * 공사구분
   *
   * **data type** varchar(8)
   */
  readonly compgubun: string;


  constructor(data: any = {}) {
    this.flag = data.flag || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.constnm = data.constnm || '';
    this.compdate = data.compdate || '';
    this.offinm = data.offinm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.gubun = data.gubun || '';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.taxflag = data.taxflag || '';
    this.pernm = data.pernm || '';
    this.pertel = data.pertel || '';
    this.perhp = data.perhp || '';
    this.permail = data.permail || '';
    this.compperid = data.compperid || '';
    this.compperid2 = data.compperid2 || '';
    this.compperid3 = data.compperid3 || '';
    this.divicd = data.divicd || '';
    this.wkactcd = data.wkactcd || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.taxyn = data.taxyn || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.saleyul = data.saleyul || '';
    this.saleamt = data.saleamt || '';
    this.saleflag = data.saleflag || '';
    this.remarks = data.remarks || '';
    this.s2flag = data.s2flag || '';
    this.s3flag = data.s3flag || '';
    this.ks2flag = data.ks2flag || '';
    this.ks3flag = data.ks3flag || '';
    this.compgubun = data.compgubun || '';
  }
}
