import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Format } from '../../../../../utils/string';
import { ConstructionModel } from '../models';
import { MonthWork } from '../MonthWork';
import { Date8 } from '../../../../../utils/time';
import { Sum } from '../../../../../utils/array';

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

const compyns = [
  { value: '0', remark: '견적' },
  { value: '1', remark: '공사' },
  { value: '2', remark: '매출' },
  { value: '3', remark: '폐기' },
  { value: '5', remark: '수금' },
  { value: '9', remark: '계획' },
  { value: ' ', remark: '견적전' },
];

// 계획대비실적[공사]
const Construction: GridLayoutHeader<ConstructionModel, MonthWork>[] = [
  {
    id: 'pernm',
    text: '담당자별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.pernm,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 6,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 5,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'gubun',
    text: '구분',
    width: 6,
    sum: (x, data) => {
      const sumGubun = Sum.groupLength(data, 'gubun', x, ['pernm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumGubun)} 건
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 27,
    sum: (x, data) => {
      const sumCompyn = Sum.groupValue(data, 'compyn', x, '9', ['pernm']);
      const sumCompyn2 = Sum.groupValue(data, 'compyn', x, '0', ['pernm']);
      const sumCompyn3 = Sum.groupValue(data, 'compyn', x, '2', ['pernm']);
      return (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>결과: (</div>
        <div style={{ color: 'var(--color-red)' }}>계획: {Format.number(sumCompyn)} 건,</div>
        <div> 견적: {Format.number(sumCompyn2)} 건,</div>
        <div style={{ color: 'var(--color-blue)' }}> 완료: {Format.number(sumCompyn3)} 건</div>
        <div>)</div>
      </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        <div>결과: (</div>
        <div style={{ color: 'var(--color-red)' }}>{scope?.state.plancnt_tot}</div>
        <div>{scope?.state.costcnt_tot}</div>
        <div style={{ color: 'var(--color-blue)' }}>{scope?.state.compcnt_tot}</div>
        <div>)</div>
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ color: x.compyn < '2' ? 'var(--color-blue)' : '' }}
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'compyn',
    text: '결과',
    width: 5,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{
          color: x.compyn === '2'
            ? 'var(--color-blue)' : x.compyn === '5'
              ? '#7F7A2C' : x.compyn === '9'
                ? 'var(--color-red)' : x.compyn === '1'
                  ? '#7F7A2C' : '',
        }}
      >
        {compyns.filter((y) => y.value === x.compyn)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt',
    text: '계획금액',
    width: 8,
    sum: (x, data) => {
      const sumPlanamt = Sum.byGroup(data, 'planamt', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#7F7A2C' }}
        >
          {Format.number(sumPlanamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {scope?.state.planamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#7F7A2C' }}
      >
        {Format.number(x.planamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt',
    text: '금액',
    width: 8,
    sum: (x, data) => {
      const sumCostamt = Sum.byGroup(data, 'costamt', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumCostamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.costamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.costamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'addamt',
    text: '차이금액',
    width: 8,
    sum: (x, data) => {
      const sumAddamt = Sum.byGroup(data, 'addamt', x, ['pernm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: x.addamt > '0' ? 'var(--color-blue)' : 'var(--color-red)' }}
        >
          {Format.number(sumAddamt)}
        </FlexLayout>
      );
    },
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.chaamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: x.addamt > '0' ? 'var(--color-blue)' : 'var(--color-red)' }}
      >
        {Format.number(x.addamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'costdate',
    text: '견적일자',
    width: 6,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.costdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'constnm',
    text: '계획내용/견적서명',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
        style={{ backgroundColor: '#FAFA80' }}
      >
        {x.constnm}
      </FlexLayout>
    ),
  },
];

export default Construction;
