import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Report } from '../Report';
import ReportModel from '../models/ReportModel';
import { Date8 } from '../../../../../utils/time';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_b035
 * @category 샘플페이지
 */
export const ReportGridHeader: GLHF<ReportModel, Report> = () => ([
  {
    id: 'ctdate',
    text: '일자',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.ctdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'projnm',
    text: '프로젝트명',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.projnm}
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '작업자',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
]);
