import * as React from 'react';
import style from './HeaderMenu.module.scss';
import { MenuModel } from '../../../models';
import { JoinClassName } from '../../../utils/string';

interface HeaderMenuItemActions {
  onClick: () => any;
}

interface HeaderMenuItemProps extends HeaderMenuItemActions {
  menu: MenuModel;
  active?: boolean;
}

export const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({
  onClick,
  menu,
  active,
}) => (
  <div
    className={JoinClassName.make([style.menu, active ? style.active : false])}
    onClick={() => onClick()}
  >
    <menu.icon />
    <p>{menu.text}</p>
  </div>
);
