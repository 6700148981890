import { computed } from 'mobx';

/**
 * 모델
 * @window TB_E022
 * @category 상위업무코드등록
 */
export class TopWorkModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 상위부서코드
   *
   * **data type** varchar(3)
   */
  kukcd: string;

  /**
   * 상위업무코드
   *
   * **data type** varchar(3)
   */
  upflag: string;

  /**
   * 상위업무명
   *
   * **data type** varchar(100)
   */
  upflagnm: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 비고
   *
   * **data type** varchar(100)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 상위부서명
   *
   * **data type** varchar(100)
   */
  kuknm: string;

  /**
   * 상위부서값 전달
   *
   * **data type** varchar(10)
   */
  searchQuery: string;

  /**
   * popup값 전달
   *
   * **data type** varchar(10)
   */
  modalCheck: string

  /**
   * 상위업무코드 return
   *
   * **data type** varchar(10)
   */
  busicd: string

  /**
   * 상위업무명 return
   *
   * **data type** varchar(15)
   */
  businm: string

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.kukcd = data.kukcd || '';
    this.upflag = data.upflag || '';
    this.upflagnm = data.upflagnm || '';
    this.useyn = data.useyn || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.kuknm = data.kuknm || '';
    this.searchQuery = data.searchQuery || '';
    this.modalCheck = data.modalCheck || '';
    this.busicd = data.busicd || '';
    this.businm = data.businm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
