import axios, { AxiosResponse } from 'axios/index';
import { ApiService } from '../utils/string';
import { Category, Global, TempFileInfo } from '../constants';
import { ToBase64 } from '../utils/binary';

class FunctionRepository {
  URL: string;

  URL_FILE: string;

  TIMEOUT: number;

  constructor() {
    this.URL = `${process.env.REACT_APP_API_SERVICE_HOST}`;
    this.URL_FILE = `${process.env.REACT_APP_API_FILE_HOST}`;
    this.TIMEOUT = parseInt(process.env.REACT_APP_API_TIMEOUT || '10000', 10);
  }

  exec(category: string, functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        category,
        params.window,
        'function',
        functionName,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  binary(category: string, functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        category,
        params.window,
        'function',
        functionName,
      )}`,
      params,
      {
        responseType: 'arraybuffer',
        timeout: this.TIMEOUT,
      },
    );
  }

  dropdown(functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_dropdown',
        'function',
        functionName,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  searchCommon(functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_com',
        'function',
        functionName,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  searchProject(functionName: string, params: any, isInstall?: boolean): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        isInstall ? 'w_popup_projno_sulchi' : 'w_popup_projno',
        'function',
        functionName,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  searchClt(functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_ca640',
        'function',
        functionName,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  custBoard(functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_xa011_board',
        'function',
        functionName,
      )}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  address(params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make('common', 'address', 'action', 'search')}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  putState(params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}/state/put`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  getState(id: string, params: any = {}): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}/state/get/${id}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  states(windowName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}/states/${windowName}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  actions(custcd: string, perid: string, windowName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}/action/${custcd}/${perid}/${windowName}`,
      params,
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  tempUpload(
    formData: FormData,
    handleProgress?: (e: any) => void,
  ): Promise<AxiosResponse<any>> {
    return axios.post(`${this.URL_FILE}/temp/file`, formData, {
      onUploadProgress: (e) => handleProgress && handleProgress(e),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async tempDownload(data: any): Promise<TempFileInfo> {
    return {
      filename: data?.filename || data?.tempfile?.filename,
      extension: data?.fileext?.toLowerCase() || data?.tempfile?.fileext?.toLowerCase(),
      size: data?.size || data.tempfile?.size,
      data: await ToBase64.make(`${this.URL_FILE}/temp/file/download`, data?.tempfile),
    };
  }

  async tempDownloadRaw(data: any): Promise<TempFileInfo> {
    return {
      filename: data?.filename || data?.tempfile?.filename,
      extension: data?.fileext?.toLowerCase() || data?.tempfile?.fileext?.toLowerCase(),
      size: data?.size || data?.tempfile?.size,
      data: '',
      raw: await ToBase64.raw(`${this.URL_FILE}/temp/file/download`, data?.tempfile),
    };
  }

  async sendFax(params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_fax',
        'function',
        'send',
      )}`,
      {
        ...params,
        window: 'w_popup_fax',
      },
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  async sendEmail(params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_email',
        'function',
        'send',
      )}`,
      {
        ...params,
        window: 'w_popup_email',
      },
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  async attachment(params: any, actionName: string): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_attach',
        'function',
        actionName,
      )}`,
      {
        ...params,
        window: 'w_popup_attach',
      },
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  async kakao(functionName: string, params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_kakao',
        'function',
        functionName,
      )}`,
      {
        ...params,
        window: 'w_popup_kakao',
      },
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  async kakaoSend(params: {
    custcd: string,
    perid: string,
    hp: string,
    msg: string,
  }): Promise<AxiosResponse<any>> {
    // @ts-ignore
    const p = Object.keys(params).map((x) => `${x}=${encodeURIComponent(params[x])}`).join('&');
    return axios.post(
      `https://api.elmansoft.com/push/kakao.php?${p}`,
      {
        timeout: this.TIMEOUT,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      },
    );
  }

  async smsSend(params: any): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.URL}${ApiService.make(
        Global.CATEGORY_NAME[Category.GENERAL],
        'w_popup_sms',
        'function',
        'wb_send',
      )}`,
      {
        ...params,
        window: 'w_popup_sms',
      },
      {
        timeout: this.TIMEOUT,
      },
    );
  }

  async request(
    isPost: boolean,
    url: string,
    params: any = {},
    headers: any = {},
  ): Promise<AxiosResponse<any>> {
    if (isPost) {
      return axios.post(
        url,
        params,
        {
          timeout: this.TIMEOUT,
          headers,
        },
      );
    }

    const p = Object.keys(params).map((x) => `${x}=${encodeURIComponent(params[x])}`).join('&');
    return axios.get(
      `${url}?${p}`,
      {
        timeout: this.TIMEOUT,
        headers,
      },
    );
  }
}

export default new FunctionRepository();
