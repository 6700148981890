import { computed } from 'mobx';

export class LeftDataModel {
  /**
   * 사업장
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 사원코드
   *
   * **data type** varchar(8)
   */
  perid: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  pernm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  actnm: string;

  /**
   * 호기건수
   *
   * **data type** varchar(8)
   */
  equpcnt: string;

  /**
   * 부품교체이력
   *
   * **data type** varchar(8)
   */
  stflag: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.spjangcd = data.spjangcd || '';
    this.custcd = data.custcd || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcnt = data.equpcnt || '';
    this.stflag = data.stflag || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
