import * as React from 'react';
import { IoMdTrash, RiFolder2Line } from 'react-icons/all';
import { RiKakaoTalkFill } from 'react-icons/ri';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  DateTextBox,
  ComboBox,
  ComboBoxModel,
  CheckBox,
  LabelBox,
  FormatTextBox,
  Button,
  ModalView,
  CustomAttachmentBox,
  LayoutTitle,
  IFrame,
  TelnumTextBox,
  TableLayout,
  CircleBadge,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BreakComplete, BreakCompleteItemChangeTypes } from './BreakComplete';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchDateRange } from '../../../../components/search';
import style from '../../../../containers/AuthorizedScreen.module.scss';

/**
 * 화면
 * @window w_tb_e411_01
 * @category 고장처리결과등록
 */
export const BreakCompleteTemplate: React.FC<TemplateProps<BreakComplete>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.focusedDetailBreakComplete;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={230}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '0', remark: '미처리' },
              { value: '1', remark: '처리' },
              { value: '2', remark: '처리중' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ gubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <SearchDateRange
          stdate={scope.state.stdate}
          enddate={scope.state.enddate}
          onChange={(date) => scope.setState({ stdate: date.stdate, enddate: date.enddate })}
        />

        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={280}>
          <span style={{ color: 'var(--color-blue)' }}>담당자</span>
          <TextBox
            value={scope.state.pernm === '%' ? '' : scope.state.pernm}
            textAlign="center"
            onChange={(value) => scope.setState({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({ perid: item.cd, pernm: item.cdnm },
                  () => scope.itemChanged(BreakCompleteItemChangeTypes.PERID));
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.perid === '%' ? '' : scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={110}>
          <span>사람갇힘</span>
          <CheckBox
            value={scope.state.trouble === '1'}
            onChange={(value) => {
              scope.setState({ trouble: value ? '1' : '0' });
            }}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout />

        {
          scope.state.kakaoyn === '1'
            ? <Button onClick={() => scope.onKakaoButtonClicked()}>
              <FlexLayout style={{ width: 50 }}>
                <RiKakaoTalkFill size={20}></RiKakaoTalkFill>
                <span style={{ paddingLeft: 8 }}>전송</span>
              </FlexLayout>
            </Button>
            : null
        }

        <Button
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope.onSMSEvent()}
        >
          <div style={{ width: 50 }}>문자</div>
        </Button>

        <Button
          onClick={() => scope.onFaxEvent()}
        >
          <div style={{ width: 50 }}>FAX</div>
        </Button>

        <Button
          onClick={() => scope.onEmailEvent()}
        >
          <div style={{ width: 50 }}>EMAIL</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout weight={0.5}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'recedate',
                text: '접수일자',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FormatTextBox
                    format="####-##-##"
                    textAlign="center"
                    value={x.recedate}
                  />
                ),
              },
              {
                id: 'recenum',
                text: '번호',
                width: 50,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.total}건
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.actnm}
                  />
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 80,
                render: (x) => (
                  <TextBox
                    textAlign="center"
                    value={x.equpnm}
                  />
                ),
              },
              {
                id: 'contnm',
                text: '고장내용',
                width: 150,
                render: (x) => (
                  <TextBox
                    textAlign="left"
                    value={x.contnm}
                  />
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
            data={scope.state.breakCompleteList || []}
          />
        </FlexLayout>
        <FlexLayout
          weight={2}
          isVertical={true}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              style={{ color: 'var(--color-red)' }}
              weight={1.5}
            >
              <TextBox
                readonly={true}
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.actnm}
                weight={4}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="담당자"
              weight={0.44}
            >
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="호기명"
              style={{ color: 'var(--color-red)' }}
              footer={
                <CircleBadge>
                  {Number(scope.state.focusedDetailBreakComplete?.wadcnt) > 1 && <span
                    style={{ backgroundColor: 'var(--color-red)' }}
                  >
                        {Number(scope.state.focusedDetailBreakComplete?.wadcnt) > 1 ? scope.state.focusedDetailBreakComplete?.wadcnt : ''}
                      </span>}
                  {Number(scope.state.focusedDetailBreakComplete?.lastcnt) > 1 && <span
                    style={{ backgroundColor: 'var(--color-blue)' }}
                  >
                        {Number(scope.state.focusedDetailBreakComplete?.lastcnt) > 1 ? scope.state.focusedDetailBreakComplete?.lastcnt : ''}
                      </span>}
                </CircleBadge>
              }
              weight={1.3}
            >
              <TextBox
                value={model?.equpcd}
                textAlign="center"
                onChange={(value) => setData({ equpcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E611',
                  {
                    uv_arg1: scope.state.focusedDetailBreakComplete?.actcd,
                  },
                  true,
                  (item) => {
                    setData({
                      equpcd: item.cd,
                      equpnm: item.cdnm,
                      elno: item.uv_arg1,
                    }, () => scope.itemChanged(BreakCompleteItemChangeTypes.EQUPCD));
                  },
                )}
              />
              <TextBox
                value={model?.equpnm}
                readonly={true}
                weight={2}
              />
              <TextBox
                value={model?.elnoRegularized}
                readonly={true}
                textAlign="center"
              >
              </TextBox>
            </LabelBox>
            <LabelBox
              title="고객명"
              weight={0.64}
            >
              <TextBox
                value={model?.actppernm}
                textAlign="left"
                onChange={(value) => setData({ actppernm: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="처리자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="처리자부서"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                textAlign="center"
                value={model?.divicd}
                onChange={(value) => setData({ divicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="(지원)처리자2"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid2}
                textAlign="center"
                onChange={(value) => setData({ perid2: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid2: item.cd,
                      pernm2: item.cdnm,
                    }, () => scope.itemChanged(BreakCompleteItemChangeTypes.PERID2));
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.pernm2}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="접수(고장)일시"
              isNeed={true}
            >
              <DateTextBox
                value={model?.recedate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ recedate: value })}
                readonly={true}
              />
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.recetime}
                onChange={(value) => setData({ recetime: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="이전처리일시"
            >
              <DateTextBox
                value={model?.berecedate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ berecedate: value })}
                readonly={true}
              />
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.berecetime}
                onChange={(value) => setData({ berecetime: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고객메일"
            >
              <TextBox
                value={model?.email}
                textAlign="left"
                onChange={(value) => setData({ email: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="도착일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.arrivdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ arrivdate: value })}
              />
            </LabelBox>
            <LabelBox
              title="도착시간"
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.arrivtime}
                onChange={(value) => setData({ arrivtime: value })}
                onBlur={() => scope.itemChanged(BreakCompleteItemChangeTypes.ARRIVTIME)}
              />
            </LabelBox>
            <LabelBox
              title="대응시간"
            >
              <TextBox
                textAlign="center"
                value={model?.resutime}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="완료일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.compdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ compdate: value })}
                readonly={scope.readOnly()}
              />
              <TextBox
                textAlign="center"
                value={model?.compnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="완료시간"
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.comptime}
                onChange={(value) => setData({ comptime: value })}
                onBlur={() => scope.itemChanged(BreakCompleteItemChangeTypes.COMPTIME)}
              />
            </LabelBox>
            <LabelBox
              title="처리시간"
            >
              <TextBox
                textAlign="center"
                value={model?.resulttime}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="고장내용"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="left"
                value={model?.contnm}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장상세내용"
            >
              <TextBox
                value={model?.contents}
                textAlign="left"
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="에러코드">
              <TextBox
                textAlign="center"
                value={model?.errcd}
                onChange={(value) => setData({ errcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E006_ERR',
                  {
                    uv_arg1: model?.manucd,
                    uv_arg2: model?.mdnm,
                  },
                  true,
                  (item) => {
                    setData({
                      errcd: item.cd,
                      errnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.errseq}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="에러명" weight={1}>
              <TextBox
                value={model?.errnm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="제작사" weight={1}>
              <TextBox
                value={model?.manucd}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="모델" weight={1}>
              <TextBox
                value={model?.mdnm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="고장 아닐시(체크시)자동기입"
              style={{ color: 'var(--color-blue)' }}
              size={200}
              footer={<FlexLayout>
                <CheckBox
                  value={model?.wadnot === '1'}
                  onChange={(value) => {
                    setData({ wadnot: value ? '1' : '0' }, () => scope.wadnotCheck(value));
                  }}
                />
              </FlexLayout>}
            >
              <TextBox
                textAlign="center"
                value={model?.wadnot === '1' ? '고장아님' : ''}
                color={model?.wadnot === '1' ? 'var(--color-blue)' : 'inherit'}
                weight={1.5}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="처리결과"
              style={{ color: 'var(--color-red)' }}
              weight={0.5}
              footer={<FlexLayout>
                <Button onClick={() => scope.allgitaButton()}>
                  모두 기타
                </Button>
              </FlexLayout>}
            >
              <TextBox
                value={model?.resultcd}
                textAlign="center"
                onChange={(value) => setData({ resultcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E015',
                  {},
                  true,
                  (item) => {
                    setData({
                      resultcd: item.cd,
                      resultnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.resultnm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장부위"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                weight={0.3}
                textAlign="center"
                value={model?.gregicd}
                onChange={(value) => setData({ gregicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E013',
                  {},
                  true,
                  (item) => {
                    setData({
                      gregicd: item.cd,
                      greginm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.greginm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="고장부위상세"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.3}
                value={model?.regicd}
                textAlign="center"
                onChange={(value) => setData({ regicd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E014',
                  { uv_arg1: model?.gregicd },
                  true,
                  (item) => {
                    setData({
                      regicd: item.cd,
                      reginm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.reginm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="고장요인"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.3}
                value={model?.remocd}
                textAlign="center"
                onChange={(value) => setData({ remocd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E011',
                  {},
                  true,
                  (item) => {
                    setData({
                      remocd: item.cd,
                      remonm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.remonm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고장원인"
              style={Global.STYLE_COLOR_RED}
            >
              <TextBox
                weight={0.3}
                textAlign="center"
                value={model?.faccd}
                onChange={(value) => setData({ faccd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E019',
                  {},
                  true,
                  (item) => {
                    setData({
                      faccd: item.cd,
                      facnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                textAlign="left"
                value={model?.facnm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="처리내용"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                weight={0.3}
                value={model?.resucd}
                textAlign="center"
                onChange={(value) => setData({ resucd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E012',
                  {},
                  true,
                  (item) => {
                    setData({
                      resucd: item.cd,
                      resunm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.resunm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={130}>
            <LabelBox
              title="고장원인상세"
              footer={
                <FlexLayout>
                  <CheckBox
                    value={model?.remochk === '1'}
                    onChange={(value) => setData({ remochk: value ? '1' : '0' })}
                  />
                  연관검색
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.remoremark}
                onChange={(value) => setData({ remoremark: value })}
                isMultiline={true}
              />
            </LabelBox>

            <LabelBox
              title="처리내용상세"
              footer={
                <FlexLayout>
                  <CheckBox
                    value={model?.resuchk === '1'}
                    onChange={(value) => setData({ resuchk: value ? '1' : '0' })}
                  />
                  연관검색
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.resuremark}
                onChange={(value) => setData({ resuremark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={130}>
            <LabelBox
              title="사후관리사항(원인및조치내용)"
              footer={
                <FlexLayout>
                  <CheckBox
                    value={model?.custchk === '1'}
                    onChange={(value) => setData({ custchk: value ? '1' : '0' })}
                  />
                  연관검색
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.customer}
                onChange={(value) => setData({ customer: value })}
                isMultiline={true}
              />
            </LabelBox>
            <LabelBox
              title="고객요구사항"
              footer={
                <FlexLayout>
                  <CheckBox
                    value={model?.chk === '1'}
                    onChange={(value) => setData({ chk: value ? '1' : '0' })}
                  />
                  연관검색
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
                isMultiline={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="사고일시"
              isNeed={true}
            >
              <DateTextBox
                value={model?.troubledate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ troubledate: value })}
                readonly={scope.readOnly()}
              />
            </LabelBox>
            <LabelBox
              title="시간/사람수"
              weight={2}
            >
              <FormatTextBox
                format="##:##"
                textAlign="center"
                value={model?.troubletime}
                onChange={(value) => setData({ troubletime: value })}
                readonly={scope.readOnly()}
              />
              <TextBox
                textAlign="center"
                value={model?.troublesu}
                readonly={true}
                weight={2}
              />
            </LabelBox>
            <LabelBox
              title="구출일자"
            >
              <DateTextBox
                value={model?.troubledate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ troubledate: value })}
              />
            </LabelBox>
            <LabelBox
              title="시간/구출자"
              isNeed={true}
            >
              <FormatTextBox
                value={model?.rescuetime}
                textAlign="center"
                format="##:##"
                onChange={(value) => setData({ rescuetime: value })}
              />
              <TextBox
                textAlign="center"
                value={model?.rescuenm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="담당싸인"
            >
              <TextBox
                textAlign="center"
                value={model?.ks1flag}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고객싸인"
            >
              <TextBox
                textAlign="center"
                value={model?.s1flag}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부(사진)"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                textAlign="center"
                value={model?.attcnt !== '0' ? 'O' : 'X'}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

        </FlexLayout>
      </FlexLayout>

      <ModalView
        width={800}
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
      >
        <CustomAttachmentBox
          isEnabledPreview={true}
          window="w_popup_e411_img"
          params={{
            recedate: model?.recedate,
            recenum: model?.recenum,
          }}
        />
      </ModalView>

      <ModalView
        width={700}
        height={800}
        isVisible={scope.state.isVisibleSMS}
        onClose={() => scope.closeSMS()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>문자메시지 전송</LayoutTitle>

          <FlexLayout isVertical={false} weight={2}>
            <FlexLayout weight={1.5}>
              <IFrame src={scope.state.url} />
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="고장신고자전화번호"
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.breakTelnum()}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      번호추가
                    </Button>
                  </FlexLayout>}
                >
                  <TelnumTextBox
                    value={scope.state.recetelnum}
                    onChange={(value) => scope.setState({ recetelnum: value })}
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="현장관리자핸드폰번호"
                  footer={<FlexLayout>
                    <Button
                      onClick={() => scope.actTelnum()}
                      isIcon={true}
                      theme={BUTTON_THEMES.BLUE}
                    >
                      번호추가
                    </Button>
                  </FlexLayout>}
                >
                  <TelnumTextBox
                    value={scope.state.acttelnum}
                    onChange={(value) => scope.setState({ acttelnum: value })}
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
                <LayoutTitle weight={1}>
                  본문
                </LayoutTitle>
              </FlexLayout>

              <TextBox
                value={scope.state.text}
                placeholder="여기에 내용을 입력하세요."
                isMultiline={true}
                onChange={(value) => scope.setState({ text: value })}
              />

              <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
                <LayoutTitle weight={1}>
                  받는 사람
                </LayoutTitle>
                 <Button onClick={ () => scope.addReceive()}>
                  <span>추가</span>
                 </Button>
              </FlexLayout>
              <TableLayout
                weight={1}
                ref={scope.smsTableTo}
                header={[
                  {
                    id: 'delete',
                    text: '삭제',
                    width: 30,
                    render: (x: any) => (
                      <FlexLayout justify="center" align="center">
                        <Button
                          isIcon={true}
                          onClick={() => {
                            scope.setState({
                              smsTo: scope.state.smsTo.filter((y) => y.tel !== x.tel),
                            });
                            scope.smsTableTo.current?.update(false);
                          }}
                        >
                          <IoMdTrash size={19} />
                        </Button>
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'tel',
                    text: '휴대폰 번호',
                    render: (x, rowUpdate, refs) => (
                      <TelnumTextBox
                        ref={refs}
                        value={x.tel || ''}
                        onChange={(value) => rowUpdate({ tel: value })}
                      />
                    ),
                  },
                ]}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                data={scope.state.smsTo}
                onChange={(rows) => { scope.setState({ smsTo: rows }); }}
              />

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="보낸사람"
                >
                  <TelnumTextBox
                    readonly={true}
                    value={scope.state.tel}
                    onChange={(value) => scope.setState({ tel: value })}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>


            <FlexLayout className={style.buttons} size={38}>
              <Button
                className={style.btnDanger}
                isFull={true}
                onClick={() => scope.closeSMS()}
                theme={BUTTON_THEMES.RED}
              >
                취소
              </Button>
              <Button
                className={style.btnSuccess}
                isFull={true}
                onClick={() => scope.sendSMS()}
              >
                보내기
              </Button>
            </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
