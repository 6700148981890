import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Worker } from '../Worker';
import WorkerModel from '../models/WorkerModel';

/**
 * 화면 > 탭 - 기본정보 > 아이디 > 팝업
 * @window w_tb_xusers_share
 * @category 외주설치자등록
 */
export const WorkerTabDefaultIDModalGridHeader: GLHF<WorkerModel, Worker> = () => ([
  {
    id: 'pernm',
    text: '성명',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'hpnum',
    text: '핸드폰번호',
    width: 40,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.hpnum}
      </FlexLayout>
    ),
  },
  {
    id: 'email',
    text: '이메일주소',
    width: 40,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.email}
      </FlexLayout>
    ),
  },
]);
