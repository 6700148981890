import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/all';
import {
  Button,
  ComboBox, ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TabLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Result } from './Result';
import { ResultTab } from './Result.tab';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models';
import { ResultModalMisGridHeader } from './headers/ResultModalMisGridHeader';
import { ResultModalMijGridHeader } from './headers/ResultModalMijGridHeader';
import { Gubuns } from '../Status/Status';

/**
 * 화면
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export const ResultTemplate: React.FC<TemplateProps<Result>> = ({
  scope,
}) => {
  const setData = (data: any, callback?: () => void) => scope?.setState(data, callback);

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={async (value) => {
              await scope.SS({ stmon: value });
              scope.onRetrieveEvent();
            }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />

          <DateTextBox
            value={scope.state.endmon}
            textAlign="center"
            format="####-##"
            onChange={async (value) => {
              await scope.SS({ endmon: value });
              scope.onRetrieveEvent();
            }}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ endmon: new Date6Calculator(scope.state.endmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ endmon: new Date6Calculator(scope.state.endmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="프로젝트로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>구분</span>
          <ComboBox
            value={scope.state.gubun}
            textAlign="center"
            data={Gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ gubun: option.value }, () => scope.onRetrieveEvent())}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>부서</span>
          <TextBox
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.4}
            value={scope.state.divinm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={Global.STYLE_COLOR_RED}>사용자</span>
          <TextBox
            textAlign="center"
            value={scope.state.perid}
            onChange={(value) => setData({ perid: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />

          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            readonly={true}
          />
        </FlexLayout>

        <Button
          onClick={() => scope.onReport()}
          style={scope.state.content?.isReported && !scope.state.content?.isReportedCancelable ? {
            opacity: 0.5,
          } : {}}
        >
          {scope.state.content?.isReported ? '상신취소' : '결재상신'}
        </Button>
      </SearchLayout>

      <FlexLayout
        size={10}
        style={{ color: 'var(--color-red)', marginTop: 8 }}
      >
        *매출이 발행한 월을 기준으로 공사실적이 조회됩니다.&nbsp;수금이 된 내역만 결재상신이 가능합니다.
      </FlexLayout>

      <FlexLayout
        size={10}
        style={{ color: 'var(--color-red)', marginTop: 8 }}
      >
        &nbsp;&nbsp;월실적반영은 최종승인이 된 월에 반영됩니다.&nbsp;최종승인 이후에는 공사자를 수정할 수 없으니 결재 전 미리 확인바랍니다.
      </FlexLayout>

      <FlexLayout>
        <TabLayout
          scope={scope}
          tabs={ResultTab}
          onChange={(_, index) => scope.onTabChange(index)}
        />
      </FlexLayout>


      {/* 팝업 */}
      <ModalView
        width={1000}
        isVisible={scope.state.isVisibleModal}
        onClose={() => scope.SS({ isVisibleModal: false })}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout
            size={Global.LAYOUT_TITLE_HEIGHT_1}
            isVertical={true}
            style={{ paddingBottom: 2 }}
          >
            <LayoutTitle>
              프로젝트 명 : [{scope.gridFocus?.projno}] {scope.gridFocus?.projnm}
            </LayoutTitle>
          </FlexLayout>

          <FlexLayout>
            <FlexLayout isVertical={true}>
              <FlexLayout
                size={Global.LAYOUT_BUTTON_HEIGHT_1}
                style={Global.STYLE_COLOR_BLUE}
                align="center"
                justify="start"
              >
                * 매출내역
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={ResultModalMisGridHeader(scope)}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  data={scope.state.dataMis}
                  infinityHandler={scope.infinityMis}
                />
              </FlexLayout>
            </FlexLayout>

            <FlexLayout isVertical={true}>
              <FlexLayout
                size={Global.LAYOUT_BUTTON_HEIGHT_1}
                style={Global.STYLE_COLOR_RED}
                align="center"
                justify="start"
              >
                * 매입내역
              </FlexLayout>

              <FlexLayout>
                <GridLayout
                  header={ResultModalMijGridHeader(scope)}
                  rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                  data={scope.state.dataMij}
                  infinityHandler={scope.infinityMij}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.SS({ isVisibleModal: false })}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
