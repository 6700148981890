import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e451
 * @category 견적서등록
 */
export default class EnrollmentMaterialsModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly addamt: string;

  /**
   * 견적금액
   *
   * **data type** varchar(8)
   */
  readonly costamt: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(8)
   */
  readonly costnum: string;

  /**
   * 동호기
   *
   * **data type** varchar(8)
   */
  readonly dong: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 호
   *
   * **data type** varchar(8)
   */
  readonly ho: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   * 품번
   *
   * **data type** varchar(8)
   */
  readonly pcode: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly pgubun: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly phm_sgrbnm: string;

  /**
   * 계획
   *
   * **data type** varchar(8)
   */
  readonly planflag: string;

  /**
   * 품명
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   * 인쇄순서
   *
   * **data type** varchar(8)
   */
  readonly prtseq: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly pserial: string;

  /**
   * 규격
   *
   * **data type** varchar(8)
   */
  readonly psize: string;

  /**
   * 단위
   *
   * **data type** varchar(8)
   */
  readonly punit: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 단가
   *
   * **data type** varchar(8)
   */
  readonly uamt: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.addamt = data.addamt || '';
    this.costamt = data.costamt || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.dong = data.dong || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.gubun = data.gubun || '';
    this.ho = data.ho || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.myn = data.myn || '';
    this.pcode = data.pcode || '';
    this.pgubun = data.pgubun || '';
    this.phm_sgrbnm = data.phm_sgrbnm || '';
    this.planflag = data.planflag || '';
    this.pname = data.pname || '';
    this.prtseq = data.prtseq || '';
    this.pserial = data.pserial || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.samt = data.samt || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.uamt = data.uamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
