import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  IFrame,
  LabelBox,
  ModalView,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Payslip } from './Payslip';
import { Format } from '../../../../utils/string';
import { Sum } from '../../../../utils/array';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';

/**
 * 화면
 * @window w_tb_pb300_04
 * @category 급여명세서
 */
export const PayslipTemplate: React.FC<TemplateProps<Payslip>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'rspnm',
              text: '직책',
              width: 10,
            },
            {
              id: 'pernm',
              text: '성명',
              width: 10,
            },
            {
              id: 'mon',
              text: '급여월',
              width: 10,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x) => (
                <FormatTextBox
                  format="####-##"
                  textAlign="center"
                  value={x.mon}
                />
              ),
            },
            {
              id: 'tot_samt',
              text: '급여총액',
              width: 15,
              trail: (data) => {
                const sumTot_samt = Sum.all(data, 'tot_samt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumTot_samt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.tot_samt)}
                </FlexLayout>
              ),
            },
            {
              id: 'tot_tamt',
              text: '공제금액',
              width: 15,
              trail: (data) => {
                const sumTot_tamt = Sum.all(data, 'tot_tamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(sumTot_tamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.tot_tamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'tot_mamt',
              text: '실수령금액',
              width: 15,
              trail: (data) => {
                const sumTot_mamt = Sum.all(data, 'tot_mamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(sumTot_mamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.tot_mamt)}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />

        <FlexLayout>
          <IFrame src={scope.state.img} />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout weight={0.3}>
        <IFrame src={scope.graph} />
      </FlexLayout>

      <ModalView
        width={350}
        height={150}
        isVisible={scope.state.modalVisible}
        onClose={() => scope.onPageCloseEvent()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <label style={{ color: 'var(--color-red)' }}>[해제] 비밀번호를 입력하세요!</label>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="비밀번호">
              <TextBox
                type="password"
                value={scope.state.password}
                onChange={(value) => scope.setState({ password: value })}
                onEnter={(value) => scope.onKeyEvent(value)}
                isDisabledTrackingStateChange={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
          >
            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.onKeyEvent(scope.state.password)}
            >
              확인
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.onPageCloseEvent()}
            >
              취소
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
