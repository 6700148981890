/**
 * 모델
 * @window w_tb_ja001_talent
 * @category 직원점수권한
 */
import { computed } from 'mobx';

export class StaffScoreModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 순번
   *
   * **data type** 모름
   */
  seq: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 담당자명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 부서
   *
   * **data type** char(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** char(40)
   */
  divinm: string;


  /**
   * 직급
   *
   * **data type** char(20)
   */
  rspnm: string;


  /**
   * 권한점수
   *
   * **data type** number
   */
  talent: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.seq = data.seq || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.rspnm = data.rspnm || '';
    this.talent = data.talent || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
