import * as React from 'react';
import { action } from 'mobx';
import { InfinityRetrieve } from '../../../../models/common';
import { SendFaxTemplate } from './SendFax.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  SendFaxDetailModel,
  SendFaxModel,
} from './models';
import { FunctionRepository } from '../../../../repositories';
import { ConfirmFail, ConfirmWarning } from '../../../../utils/confirm';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { PageComponent } from '../../../../utils/layout';

const fileDownload = require('js-file-download');

interface ReferenceBoxState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  appnm: string;
  perid: string;
  pernm: string;
  filenm: string;

  focusedSendFax: SendFaxModel;
  sendFaxList: Array<SendFaxModel>;

  focusedSendFaxDetail: SendFaxDetailModel;
  sendFaxDetailList: Array<SendFaxDetailModel>;

  isFileModalVisible: boolean;
}

/*
 * 컨트롤러
 * @window w_tb_fax_send_01
 * @category 보낸팩스함
*/

export class SendFax extends PageComponent<PageProps, ReferenceBoxState>
  implements PageToolEvents {
  updatedRows?: Array<SendFaxModel>;

  updatedRows2?: Array<SendFaxDetailModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`, // 기간(시작)
      enddate: `${year}${month}${date}`, // 기간(끝)
      searchQuery: '',
      appnm: '%',
      perid: '',
      pernm: '',
      filenm: '',

      sendFaxList: [],
      sendFaxDetailList: [],

      isFileModalVisible: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }


  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          sendFaxList: [...this.state.sendFaxList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          sendFaxList: [],
          sendFaxDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.sendFaxList && this.state.sendFaxList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      sendFaxList: [],
      sendFaxDetailList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.state.sendFaxList.length > 0 && this.grid.current?.setFocus(0);
    });
  }

  @action
  onRowFocusEvent(item: SendFaxModel) {
    const { actionStore: api } = this.props;

    this.setState({ focusedSendFax: item },
      async () => {
        this.infinity2 = new InfinityRetrieve({
          reqdate: this.state.focusedSendFax?.senddate,
          reqnum: this.state.focusedSendFax?.seq,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            sendFaxDetailList: items,
          });
        },
        async () => {
          await this.SS({
            sendFaxDetailList: [],
          });

          await this.infinity2?.retrieve();
          if (this.state.sendFaxDetailList && this.state.sendFaxDetailList?.length > 0) {
            await this.detailTable.current?.update();
            this.detailTable.current?.setFocus(0, 0);
          }
        });
        await this.infinity2?.retrieve();
        await this.detailTable.current?.update();
        this.detailTable.current?.setFocus(0, 0);
      });
  }

  @action
  onRowFocusEvent2(item:SendFaxDetailModel) {
    this.setState({ focusedSendFaxDetail: item });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.sendFaxList.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async openFileModal() {
    // eslint-disable-next-line max-len
    this.setState({
      isFileModalVisible: true,
    });
  }

  @action
  async closeFileModal() {
    this.setState({
      isFileModalVisible: false,
    }, () => this.onRowFocusEvent(this.state.focusedSendFax));
  }

  @action
  async onDownload() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    const data = await api.fxExec('wb_download',
      {
        spjangcd: user.spjangcd,
        reqdate: this.state.focusedSendFax?.senddate,
        reqnum: this.state.focusedSendFax?.seq,
        seq: this.state.focusedSendFaxDetail?.fileseq,
      });

    const file = await FunctionRepository.tempDownloadRaw(data);

    if (file.size > 0) {
      try {
        fileDownload(file.raw, `${file.filename}.${file.extension}`.trim());
      } catch {
        ConfirmFail.show('오류', '다운로드에 실패하였습니다.');
      }
    }
  }

  render() {
    return (
      <SendFaxTemplate scope={this}/>
    );
  }
}
