import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { JiroAutoTemplate } from './JiroAuto.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  JiroAutoModel,
  JiroAutoDetail2Model,
  JiroAutoDetailModel,
} from './models';
import {
  ConfirmDelete,
  ConfirmFail,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { FileSelector } from '../../../../utils/file';
import { Date8 } from '../../../../utils/time';
import { PageComponent } from '../../../../utils/layout';

interface JiroAutoState {
  // 검색조건
  stdate: string;
  enddate: string;
  ejiroStdate: string;
  isReported: boolean;

  // data
  jiroAutoList: Array<JiroAutoModel>;
  jiroAutoDetailList: Array<JiroAutoDetailModel>;
  jiroAutoDetailList2: Array<JiroAutoDetail2Model>;
  focused?: JiroAutoModel;
  focusIndex: number;
  focusedJiroAuto?: JiroAutoModel;
  focusedJiroAuto2?: JiroAutoModel;
  data: JiroAutoModel;
  excelUploadFocus: boolean;
  fileName: string;
  pushTotalCheck: boolean;

  // trail
  misamt_tot: string;
  rcvamt_tot: string;
  tamt_tot: string;
  misuamt_tot: string;
  rcvamt_tot2: string;
}

/**
 * 컨트롤러
 * @window w_tb_da026_import
 * @category 지로자동이체
 */
export class JiroAuto extends PageComponent<PageProps, JiroAutoState>
  implements PageToolEvents {
  updatedRows?: Array<JiroAutoModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  files?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // search
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      ejiroStdate: `${year}${month}${date}`,
      cltcd: '00004',
      isReported: false,

      // data
      focusIndex: 0,
      jiroAutoList: [],
      jiroAutoDetailList: [],
      jiroAutoDetailList2: [],
      data: new JiroAutoModel(),
      excelUploadFocus: false,
      pushTotalCheck: false,
      fileName: '',

      // trail
      misamt_tot: '0',
      rcvamt_tot: '0',
      tamt_tot: '0',
      misuamt_tot: '0',
      rcvamt_tot2: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          jiroAutoList: [
            ...this.state.jiroAutoList,
            ...items.map((x: any) => new JiroAutoModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          jiroAutoList: [],
          jiroAutoDetailList: [],
          jiroAutoDetailList2: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.jiroAutoList && this.state.jiroAutoList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      jiroAutoList: [],
      jiroAutoDetailList: [],
      jiroAutoDetailList2: [],
      pushTotalCheck: false,
    }, async () => {
      this.grid?.current?.forceRepaint();
      const index = await this.infinity?.retrieveTo(['filename', 'indate'],
        [this.state.focused?.filename, this.state.focused?.indate], type, true) || 0;
      if (this.state.excelUploadFocus) {
        let indexData: any;
        this.state.jiroAutoList?.forEach((x: any, n) => {
          if (this.state.fileName.slice(4, 10) === x.savedate.slice(2, 8)) {
            indexData = n;
          }
        });
        this.grid.current?.setFocus(indexData);
        this.setState({ excelUploadFocus: false });
      } else if (this.state.jiroAutoList && this.state.jiroAutoList.length > index) {
        await this.grid.current?.setFocus(index);
      }
      await this.table.current?.update(true);
    });
    await this.table.current?.update(true);
  }

  @action
  async onRowFocusEvent(item: any, index: number) {
    const { actionStore: api } = this.props;

    // 입금버튼
    if (item?.rcvflag === '1') {
      this.setState({ isReported: false });
    } else {
      this.setState({ isReported: true });
    }

    await this.setState({
      focused: item,
      focusIndex: index,
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity2 = new InfinityRetrieve(
        { filename: this.state.focused?.filename },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            jiroAutoDetailList: [
              ...this.state.jiroAutoDetailList,
              ...items.map((x: any) => new JiroAutoDetailModel(x)),
            ],
          });
          // 결재상신 여부확인
          if (this.state.focused?.appgubun === null
            || this.state.focused?.appgubun === ''
            || this.state.focused?.appgubun === '131') {
            this.setState({ isReported: false });
          } else {
            this.setState({ isReported: true });
          }
        },
        async () => {
          await this.SS({
            jiroAutoDetailList: [],
            jiroAutoDetailList2: [],
          });

          await this.infinity2?.retrieve();
          if (this.state.jiroAutoDetailList && this.state.jiroAutoDetailList?.length > 0) {
            await this.table.current?.update(true);
            this.table.current?.setFocus(0, 0);
          }
        },
      );

      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        jiroAutoDetailList: [],
        jiroAutoDetailList2: [],
      }, async () => {
        await this.infinity2?.retrieve();
        this.SS({
          misamt_tot: this.infinity2?.box?.misamt_tot || '0',
          rcvamt_tot: this.infinity2?.box?.rcvamt_tot || '0',
          tamt_tot: this.infinity2?.box?.tamt_tot || '0',
        });

        if (this.state.jiroAutoDetailList && this.state.jiroAutoDetailList?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 0);
          this.onRowFocusEvent2(this.state.jiroAutoDetailList[0]);
        }
      });
    });
  }

  @action
  async onRowFocusEvent2(item: any) {
    const { actionStore: api } = this.props;

    await this.setState({
      focusedJiroAuto: new JiroAutoModel(item),
    }, async () => {
      // 무한 스크롤바 헬퍼 초기화
      this.infinity3 = new InfinityRetrieve(
        {
          cltcd: item?.cltcd,
        },
        (params) => api.fxExec('dw_2_RowFocuschanged', params),
        (items) => {
          if (items) {
            this.setState({
              jiroAutoDetailList2: [
                ...this.state.jiroAutoDetailList2,
                ...items.map((x: any) => new JiroAutoDetail2Model(x)),
              ],
            });
          } else {
            this.SS({
              jiroAutoDetailList2: [],
            });
          }
        },
        async () => {
          await this.SS({
            jiroAutoDetailList2: [],
          });

          await this.infinity3?.retrieveAll();
          if (this.state.jiroAutoDetailList2 && this.state.jiroAutoDetailList2?.length > 0) {
            await this.grid2.current?.setFocus(0);
          }
        },
      );
      // 상단 조회 버튼을 누를때는 기존 배열 초기화
      this.setState({
        jiroAutoDetailList2: [],
      }, async () => {
        this.grid2?.current?.forceUpdate();
        await this.infinity3?.retrieveAll();
        if (this.state.jiroAutoDetailList2 && this.state.jiroAutoDetailList2?.length > 0) {
          await this.grid2.current?.setFocus(0);
        }

        this.SS({
          misuamt_tot: this.infinity3?.box?.misuamt_tot || '0',
          rcvamt_tot2: this.infinity3?.box?.rcvamt_tot || '0',
        });
      });
    });
  }

  @action
  onRowFocusEvent3(item: any) {
    this.setState({ focusedJiroAuto2: item }, () => {
      this.grid2?.current?.forceUpdate();
    });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.focused?.rcvflag !== '0') {
      ConfirmWarning.show('확인', '입금상태에서는 삭제할 수 없습니다.');
      return;
    }

    const text = `파일명: ${this.state.focused?.filename} 저장일자: ${this.state.focused?.savedate}`;
    if (await ConfirmDelete.show(text)) {
      await api.fxExec(
        'delete',
        {
          rcvflag: this.state.focused?.rcvflag,
          filename: this.state.focused?.filename,
        }, true,
      );
      await this.onRetrieveEvent();
    }
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.jiroAutoList.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      filename: this.state.focused?.filename || '',
    });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ jiroAutoDetailList: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.jiroAutoDetailList?.forEach((x: any) => {
      checkData.push(new JiroAutoDetailModel({
        ...x,
        chk: checked ? '1' : '0',
      }));
    });

    this.setState({
      pushTotalCheck: checked,
      jiroAutoDetailList: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onSubExcelEvent() {
    try {
      const files = await FileSelector.single(false);
      this.excelUpload(files[0].name, files[0]);
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async excelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('지로자동이체', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      this.setState({
        excelUploadFocus: true,
        fileName: filename,
      });
      await this.onRetrieveEvent();
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    await api.exec(
      Category.GENERAL,
      'wb_excel_lazytask',
      {
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    );
  }

  @action
  async onReport() {
    const { actionStore: api } = this.props;
    let appflag;

    if (this.state.focused?.rcvflag === '0') {
      appflag = 'wb_rcvsave';
    } else {
      appflag = 'wb_rcvdelete';
    }

    const item: any = [];
    this.state.jiroAutoDetailList.forEach((x: any) => {
      item.push({
        chk: x.chk,
        seq: x.seq,
      });
    });

    await api.fxExec(
      appflag,
      {
        savedate: this.state.focused?.savedate,
        rcvdate: this.state.focusedJiroAuto?.rcvdate,
        filename: this.state.focused?.filename,
        items: item,
      },
    );
    this.onRetrieveEvent();
  }

  @action
  onJiroEvent() {
    const { actionStore: api, waitQueueStore, modalStore } = this.props;

    // 대기열에 추가
    const title = `이지로 연동 [${Date8.withDash(this.state.ejiroStdate)}]`;
    const key = `EGIRO-${this.state.ejiroStdate}`;

    if (waitQueueStore.queue.filter((x) => x.key === key).length) {
      ConfirmFail.show('오류', '이미 연동중입니다');
      return;
    }

    waitQueueStore.append(title, key, async (response) => {
      ConfirmWarning.show(response.title, response.message);
      this?.onRetrieveEvent && this.onRetrieveEvent();
    }, () => this.onJiroEvent());
    modalStore?.openWaitQueue();

    api.fxExec(
      'wb_egiro',
      {
        key,
        stdate: this.state.ejiroStdate,
      },
    );
  }

  render() {
    return (
      <JiroAutoTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
