import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_pb201
 * @category 일근태등록
 */
export class DayDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 순번
   * **data type** varchar(4)
   */
  seq: string;

  /**
   * 월
   *
   * **data type** varchar(2)
   */
  stmm: string;

  /**
   * 직영구분
   *
   * **data type** varchar(1)
   */
  mpclafi: string;

  /**
   * 남은대휴
   *
   * **data type** varchar(6)
   */
  workym: string;

  /**
   * 순번
   *
   * **data type** varchar(6)
   */
  worknum: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  workday: string;

  /**
   * 요일
   *
   * **data type** varchar(1)
   */
  weekday: string;

  /**
   * 출근위도
   *
   * **data type** 모름
   */
  st_lng: string;

  /**
   * 출근경도
   *
   * **data type** 모름
   */
  st_lat: string;

  /**
   * 출근위도
   *
   * **data type** 모름
   */
  end_lng: string;

  /**
   * 출근경도
   *
   * **data type** 모름
   */
  end_lat: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 출근시간
   *
   * **data type** datetime
   */
  starttime: string;

  /**
   * 퇴근시간
   *
   * **data type** datetime
   */
  endtime: string;

  /**
   * 출근시간
   *
   * **data type** datetime
   */
  sttime: string;

  /**
   * 퇴근시간
   *
   * **data type** datetime
   */
  edtime: string;

  /**
   * 사유
   *
   * **data type** varchar(255)
   */
  memotxt: string;

  /**
   * 근태
   *
   * **data type** varchar(12)
   */
  atdcd: string;

  /**
   * 공사
   *
   * **data type** varchar(1)
   */
  workflag: string;

  /**
   * 직급코드
   *
   * **data type** varchar(2)
   */
  rspcd: string;

  /**
   * 직급
   *
   * **data type** varchar(20)
   */
  rspnm: string;

  /**
   * 사원명
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 남은연차
   *
   * **data type** varchar(30)
   */
  restnum: string;

  /**
   * 남은대휴
   *
   * **data type** varchar(30)
   */
  workrestnum: string;

  /**
   * 4대보험취득일
   *
   * **data type** varchar(8)
   */
  insudate: string;

  /**
   * 핸드폰번호
   *
   * **data type** varchar(20)
   */
  handphone: string;

  /**
   * 우편번호
   *
   * **data type** varchar(6)
   */
  zipcd: string;

  /**
   * 주소
   *
   * **data type** char(90)
   */
  zipadres: string;

  /**
   * 주소
   *
   * **data type** char(90)
   */
  adres: string;

  /**
   * 년
   *
   * **data type** 모름
   */
  mon: string;

  /**
   * 평균인원
   *
   * **data type** 모름
   */
  reserv: string;

  /**
   * 평균이직률
   *
   * **data type** 모름
   */
  aver: string;

  /**
   * 정상
   *
   * **data type** 모름
   */
  atdcd01: string;

  /**
   * 지각
   *
   * **data type** 모름
   */
  atdcd02: string;

  /**
   * 연차
   *
   * **data type** 모름
   */
  atdcd03: string;

  /**
   * 조퇴
   *
   * **data type** 모름
   */
  atdcd04: string;

  /**
   * 결근
   *
   * **data type** 모름
   */
  atdcd05: string;

  /**
   * 출장
   *
   * **data type** 모름
   */
  atdcd06: string;

  /**
   * 훈련
   *
   * **data type** 모름
   */
  atdcd07: string;

  /**
   * 교육
   *
   * **data type** 모름
   */
  atdcd08: string;

  /**
   * 출근시 거리차이
   *
   * **data type** 모름
   */
  distance: string;

  /**
   * 총원
   *
   * **data type** 모름
   */
  total: string;

  /**
   * 비교값
   *
   * **data type** 모름
   */
  st_worktime: string;

  chk: string;

  /**
   * data new 를 위함
   */
  readonly new: string;


  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.seq = data.seq || '';
    this.stmm = data.stmm || '';
    this.mpclafi = data.mpclafi || '';
    this.workym = data.workym || '';
    this.worknum = data.worknum || '';
    this.restnum = data.restnum || '';
    this.workrestnum = data.workrestnum || '';
    this.divinm = data.divinm || '';
    this.workday = data.workday || '';
    this.weekday = data.weekday || '';
    this.starttime = data.starttime || '';
    this.endtime = data.endtime || '';
    this.memotxt = data.memotxt || '';
    this.atdcd = data.atdcd || '';
    this.workflag = data.workflag || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.insudate = data.insudate || '';
    this.handphone = data.handphone || '';
    this.zipcd = data.zipcd || '';
    this.zipadres = data.zipadres || '';
    this.adres = data.adres || '';
    this.mon = data.mon || '';
    this.reserv = data.reserv || '';
    this.aver = data.aver || '';
    this.atdcd01 = data.atdcd01 || '';
    this.atdcd02 = data.atdcd02 || '';
    this.atdcd03 = data.atdcd03 || '';
    this.atdcd04 = data.atdcd04 || '';
    this.atdcd05 = data.atdcd05 || '';
    this.atdcd06 = data.atdcd06 || '';
    this.atdcd07 = data.atdcd07 || '';
    this.atdcd08 = data.atdcd08 || '';
    this.distance = data.distance || '';
    this.st_lng = data.st_lng || '';
    this.st_lat = data.st_lat || '';
    this.end_lng = data.end_lng || '';
    this.end_lat = data.end_lat || '';
    this.st_worktime = data.st_worktime || '';
    this.total = data.total || '';
    this.sttime = data.sttime || '';
    this.edtime = data.edtime || '';
    this.chk = data.chk || '0';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
