import { GLHF, Global } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Result } from '../Result';
import { Format } from '../../../../../utils/string';
import ResultModalModel from '../models/ResultModalModel';

/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_e471w_02
 * @category 공사결과보고서
 */
export const ResultModalMijGridHeader: GLHF<ResultModalModel, Result> = (scope) => ([
  {
    id: 'pname',
    text: '사용내역',
    width: 35,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.pname}
      </FlexLayout>
    ),
  },
  {
    id: 'punit',
    text: '단위',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.punit}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Format.number(x.qty)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '사용금액',
    width: 20,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          ...Global.STYLE_COLOR_RED,
          paddingRight: 8,
        }}
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'flagnm',
    text: '사용구분',
    width: 25,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.flagnm === '0' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.flagnm === '0' ? '재고사용' : '매입/구입'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          ...Global.STYLE_COLOR_RED,
          paddingRight: 8,
        }}
      >
        {Format.number(scope.state.modalMijSamt_tot || '', true)}
      </FlexLayout>
    ),
  },
]);
