import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { IndividualGoalTemplate } from './IndividualGoal.template';
import { IndividualGoalModel } from './IndividualGoal.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { IndividualPopupModel } from './IndividualPopup.model';
import { Today } from '../../../../utils/time';

interface IndividualGoalState {
  year: string;
  gubun: string;
  perid: string;
  pernm: string;

  // data
  data1: Array<any>;
  data2: Array<any>;
  data3: Array<any>;
  data4: Array<any>;
  data5: Array<any>;
  data6: Array<any>;
  data7: Array<any>;
  data8: Array<any>;
  data9: Array<any>;
  data10: Array<any>;
  data11: Array<any>;
  data12: Array<any>;
  rsData1: Array<any>;
  rsData2: Array<any>;
  rsData3: Array<any>;
  rsData4: Array<any>;
  rsData5: Array<any>;
  rsData6: Array<any>;
  rsData7: Array<any>;
  rsData8: Array<any>;
  rsData9: Array<any>;
  rsData10: Array<any>;
  rsData11: Array<any>;
  rsData12: Array<any>;
  trailData1: IndividualGoalModel;
  trailData2: IndividualGoalModel;
  trailData3: IndividualGoalModel;
  trailData4: IndividualGoalModel;
  trailData5: IndividualGoalModel;
  trailData6: IndividualGoalModel;
  trailData7: IndividualGoalModel;
  trailData8: IndividualGoalModel;
  trailData9: IndividualGoalModel;
  trailData10: IndividualGoalModel;
  trailData11: IndividualGoalModel;
  trailData12: IndividualGoalModel;
  data: IndividualGoalModel;
  focused?: IndividualGoalModel;

  // popup
  modalState: boolean;
  modalState2: boolean;
  modalState3: boolean;
  modalState4: boolean;

  popupList: Array<IndividualPopupModel>;
  popupDate: IndividualGoalModel,
  flags: Array<any>;
  popupFocused?: IndividualPopupModel,
}

/**
 * 컨트롤러
 * @window w_tb_da052
 * @category 개인목표계획등록
 */
export class IndividualGoal extends React.Component<PageProps, IndividualGoalState>
  implements PageToolEvents {
  updatedRows?: Array<IndividualPopupModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  table2: React.RefObject<TableLayout> = React.createRef();

  table3: React.RefObject<TableLayout> = React.createRef();

  table4: React.RefObject<TableLayout> = React.createRef();

  popupDate: string | undefined;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      gubun: '11',
      perid: '',
      pernm: '',
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      data8: [],
      data9: [],
      data10: [],
      data11: [],
      data12: [],
      rsData1: [],
      rsData2: [],
      rsData3: [],
      rsData4: [],
      rsData5: [],
      rsData6: [],
      rsData7: [],
      rsData8: [],
      rsData9: [],
      rsData10: [],
      rsData11: [],
      rsData12: [],
      popupList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 팝업 청구주기
    const data = await api.dropdown('wf_dd_ca510_007_01');
    if (!data) return;

    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    if (!this.state.perid) {
      ConfirmWarning.show('확인', '사용자를 선택해 주세요!');
      return;
    }

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_da052',
        year: this.state.year,
        gubun: this.state.gubun,
        perid: this.state.perid,
      });
    this.setState({
      data,
    });

    for (let i = 1; i < 13; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const data = await api.fxExec(`retrieve${i}`,
        {
          sub: 'w_popup_da052',
          year: this.state.year,
          gubun: this.state.gubun,
          perid: this.state.perid,
        });
      if (data) {
        switch (i) {
          case 1:
            this.setState({
              data1: data.items || [],
              trailData1: data,
            });
            break;
          case 2:
            this.setState({
              data2: data.items || [],
              trailData2: data,
            });
            break;
          case 3:
            this.setState({
              data3: data.items || [],
              trailData3: data,
            });
            break;
          case 4:
            this.setState({
              data4: data.items || [],
              trailData4: data,
            });
            break;
          case 5:
            this.setState({
              data5: data.items || [],
              trailData5: data,
            });
            break;
          case 6:
            this.setState({
              data6: data.items || [],
              trailData6: data,
            });
            break;
          case 7:
            this.setState({
              data7: data.items || [],
              trailData7: data,
            });
            break;
          case 8:
            this.setState({
              data8: data.items || [],
              trailData8: data,
            });
            break;
          case 9:
            this.setState({
              data9: data.items || [],
              trailData9: data,
            });
            break;
          case 10:
            this.setState({
              data10: data.items || [],
              trailData10: data,
            });
            break;
          case 11:
            this.setState({
              data11: data.items || [],
              trailData11: data,
            });
            break;
          case 12:
            this.setState({
              data12: data.items || [],
              trailData12: data,
            });
            break;
        }
      }
    }

    for (let i = 1; i < 13; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const data = await api.fxExec(`retrieve_rs${i}`,
        {
          sub: 'w_popup_da052',
          year: this.state.year,
          gubun: this.state.gubun,
          perid: this.state.perid,
        });
      if (data) {
        switch (i) {
          case 1:
            this.setState({
              rsData1: data.items || [],
            });
            break;
          case 2:
            this.setState({
              rsData2: data.items || [],
            });
            break;
          case 3:
            this.setState({
              rsData3: data.items || [],
            });
            break;
          case 4:
            this.setState({
              rsData4: data.items || [],
            });
            break;
          case 5:
            this.setState({
              rsData5: data.items || [],
            });
            break;
          case 6:
            this.setState({
              rsData6: data.items || [],
            });
            break;
          case 7:
            this.setState({
              rsData7: data.items || [],
            });
            break;
          case 8:
            this.setState({
              rsData8: data.items || [],
            });
            break;
          case 9:
            this.setState({
              rsData9: data.items || [],
            });
            break;
          case 10:
            this.setState({
              rsData10: data.items || [],
            });
            break;
          case 11:
            this.setState({
              rsData11: data.items || [],
            });
            break;
          case 12:
            this.setState({
              rsData12: data.items || [],
            });
            break;
        }
      }
    }
  }

  @action
  textModal(isOpen: boolean, item: string) {
    if (this.state.gubun === '11') {
      this.setState({ modalState: isOpen });
    } else if (this.state.gubun === '12') {
      this.setState({ modalState2: isOpen });
    } else if (this.state.gubun === '13') {
      this.setState({ modalState3: isOpen });
    } else if (this.state.gubun === '14') {
      this.setState({ modalState4: isOpen });
    }

    isOpen === false && this.onRetrieveEvent();

    this.modalRetrieve(item);
  }

  @action
  async modalRetrieve(item: string) {
    const { actionStore: api } = this.props;

    this.popupDate = this.state.year + item;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_da052_detail',
        perid: this.state.perid,
        pernm: this.state.pernm,
        mon: this.popupDate,
        gubun: this.state.gubun,
      });
    if (data) {
      this.setState({
        popupList: data?.items || [],
        popupDate: data,
      }, async () => {
        if (this.state.gubun === '11') {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 2);
        } else if (this.state.gubun === '12') {
          await this.table2.current?.update(true);
          this.table2.current?.setFocus(0, 2);
        }
      });
    }
  }

  @action
  onRowFocusEvent(item: IndividualPopupModel) {
    this.setState({ popupFocused: item });
  }

  @action
  onUpdatedRows(rows: Array<IndividualPopupModel>, updatedRows: Array<IndividualPopupModel>) {
    this.updatedRows = updatedRows;
    this.setState({ popupList: rows });
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;

    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_da052_detail',
      mon: this.popupDate,
      perid: this.state.perid,
      gubun: this.state.gubun,
      seq: this.state.popupFocused?.seq,
    }) && await this.modalRetrieve(String(this.popupDate?.slice(4, 6)));
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_da052_detail',
        mon: this.popupDate,
        perid: this.state.perid,
        gubun: this.state.gubun,
        seq: this.state.popupFocused?.seq,
        items: this.updatedRows,
      });
    if (data) {
      this.setState({
        popupList: data?.items || [],
      });
    }
  }

  @action
  async modalNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'new ',
      {
        sub: 'w_popup_da052_detail',
        mon: this.popupDate,
        perid: this.state.perid,
        gubun: this.state.gubun,
      },
    );

    this.setState({
      popupList: [...this.state.popupList, new IndividualPopupModel(data, true)],
    }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(this.state.popupList.length - 1);
    });
  }

  render() {
    return (
      <IndividualGoalTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
