import { GLHF } from '../../../../../constants';
import { PurchasingStatisticsModel } from '../models/PurchasingStatistics.model';
import {
  PurchasingStatistics,
  titleNames,
} from '../PurchasingStatistics';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';


export const PurchasingStatisticsGridHeader: GLHF<PurchasingStatisticsModel, PurchasingStatistics> = (scope) => ([
  {
    id: 'spjangnm',
    text: '사업장명',
    width: 14,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.spjangnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt_0',
    text: '보수매입',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.bosu,
          modalTopTitles: '보수',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.mijamt_0)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.mijamt_0_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt_0',
    text: '보수지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.bosu,
          modalTopTitles: '보수',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.sndamt_0)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.sndamt_0_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt_0',
    text: '보수미지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.bosu,
          modalTopTitles: '보수',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.iamt_0)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#FF00FF' }}
      >
        {Format.number(scope.state.iamt_0_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt_1',
    text: '설치매입',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.sulchi,
          modalTopTitles: '설치',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.mijamt_1)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.mijamt_1_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt_1',
    text: '설치지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.sulchi,
          modalTopTitles: '설치',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.sndamt_1)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.sndamt_1_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt_1',
    text: '설치미지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.sulchi,
          modalTopTitles: '설치',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.iamt_1)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#FF00FF' }}
      >
        {Format.number(scope.state.iamt_1_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt_2',
    text: '공사매입',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.jejo,
          modalTopTitles: '공사',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.mijamt_2)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.mijamt_2_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt_2',
    text: '공사지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.jejo,
          modalTopTitles: '공사',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.sndamt_2)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.sndamt_2_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt_2',
    text: '공사미지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.jejo,
          modalTopTitles: '공사',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.iamt_2)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#FF00FF' }}
      >
        {Format.number(scope.state.iamt_2_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt_5',
    text: '기타매입',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.etc,
          modalTopTitles: '기타',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.mijamt_5)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.mijamt_5_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt_5',
    text: '기타지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.etc,
          modalTopTitles: '기타',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.sndamt_5)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.sndamt_5_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt_5',
    text: '기타미지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.etc,
          modalTopTitles: '기타',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.iamt_5)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#FF00FF' }}
      >
        {Format.number(scope.state.iamt_5_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt_tot',
    text: '총매입',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.tot,
          modalTopTitles: '총',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.mijamt_tot)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(scope.state.mijamt_tot_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'sndamt_tot',
    text: '총지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.tot,
          modalTopTitles: '총',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.sndamt_tot)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#60C5F1' }}
      >
        {Format.number(scope.state.sndamt_tot_tot)}
      </FlexLayout>
    ),
  },
  {
    id: 'iamt_tot_1',
    text: '총미지급',
    width: 10,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-red)' }}
        onDoubleClick={() => scope.setState({
          wkgubuns: titleNames.tot,
          modalTopTitles: '총',
        }, () => scope.openModal(true))}
      >
        {Format.number(x.iamt_tot)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#FF00FF' }}
      >
        {Format.number(scope.state.iamt_tot_tot)}
      </FlexLayout>
    ),
  },
]);
