import {
  observable,
  computed,
  action,
  makeObservable,
} from 'mobx';
import { AxiosResponse } from 'axios';
import {
  UserModel,
  CurrentMenuModel,
  AuthModel,
  MenuModel,
} from '../models';

import {
  ActionRepository,
  AuthRepository,
  FunctionRepository,
} from '../repositories';
import {
  AskType,
  PageToolEvents,
  TimeTask,
} from '../constants';
import {
  ActionStore,
  HeaderTabStore,
  MenuStore, ModalStore,
  RouterStore, ToolStore,
  WaitQueueStore,
} from '.';
import {
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../utils/confirm';
import { Fix, MD5 } from '../utils/string';
import { UseWasm } from '../utils/wasm';
import WaitAsyncQueueStore from './WaitAsyncQueueStore';

const Paho = require('paho-mqtt');

interface IPublicStore {
  headerTabStore: HeaderTabStore;
  routerStore: RouterStore;
  waitQueueStore: WaitQueueStore;
  modalStore?: ModalStore;
  actionStore?: ActionStore;
  toolStore?: ToolStore;
  currentUser?: UserModel;
  authData?: AuthModel;
  currentMenu: CurrentMenuModel;
  isLoading: boolean;
  themeName?: string;
  tempStorage: any;
  now: Date;
  globalTimer: NodeJS.Timeout;
  isStateChanged: boolean;
  managerKey: string;
  managerTopic: string;
  // @ts-ignore
  mqtt: Paho.Client;
}

interface IPublicSpjang {
  spjangcd: string;
  spjangnm: string;
}

export type RequestType = () => Promise<AxiosResponse<any>>;

export default class PublicStore implements IPublicStore {
  readonly isDev = `${process.env?.FAST_REFRESH}` === 'true';

  readonly headerTabStore: HeaderTabStore;

  readonly routerStore: RouterStore;

  readonly waitQueueStore: WaitQueueStore;

  readonly waitAsyncQueueStore: WaitAsyncQueueStore;

  readonly menuStore: MenuStore;

  modalStore?: ModalStore;

  actionStore?: ActionStore;

  toolStore?: ToolStore;

  @observable zoomF: number;

  @observable currentUser?: UserModel;

  @observable currentMenu: CurrentMenuModel;

  @observable currentPage?: PageToolEvents;

  @observable themeName?: string;

  @observable isPreLoading: boolean;

  @observable isLoading: boolean;

  @observable now: Date;

  @observable isFavoriteMenuVisible: boolean = false;

  authData?: AuthModel;

  @observable spjangs: Array<IPublicSpjang>;

  tempStorage: any;

  pageParams?: any;

  globalTimer: NodeJS.Timeout;

  isStateChanged: boolean;

  // @ts-ignore
  mqtt: PahoMQTT.Client;

  loadingCount: number;

  errorCount: number = 0;

  managerKey: string;

  managerTopic: string;

  @observable isManagerOnline: boolean = false;

  lastPingTimeManager: number = 0;

  managerConnectEventQueue: Array<Function | undefined> = [];

  globalTimerTasks: Array<TimeTask> = [];

  asm?: any;

  constructor(
    headerTabStore: HeaderTabStore,
    routerStore: RouterStore,
    waitQueueStore: WaitQueueStore,
    waitAsyncQueueStore: WaitAsyncQueueStore,
    menuStore: MenuStore,
  ) {
    this.headerTabStore = headerTabStore;
    this.routerStore = routerStore;
    this.waitQueueStore = waitQueueStore;
    this.waitAsyncQueueStore = waitAsyncQueueStore;
    this.menuStore = menuStore;
    this.isPreLoading = false;
    this.isLoading = false;
    this.currentMenu = new CurrentMenuModel();
    this.tempStorage = {};
    this.now = new Date();
    this.globalTimer = setInterval(this.tickGlobalTimer, 1000);
    this.isStateChanged = false;
    this.loadingCount = 0;
    this.currentUser = new UserModel();
    this.managerKey = MD5.make(`${window.navigator.userAgent}${new Date().getTime()}${Math.random()}`);
    this.managerTopic = `/weberp/manager/${this.managerKey}`;
    this.spjangs = [{ spjangcd: 'ZZ', spjangnm: '기본' }];
    this.zoomF = 1.0;

    this.mqtt = new Paho.Client('sub.elmansoft.com', 8084, `EMW${Math.random() * 1000}`);
    this.mqtt.onConnectionLost = this.onMqttConnectLost;
    this.mqtt.onMessageArrived = this.onMqttMessageArrived;

    makeObservable(this);
  }

  tickGlobalTimer = () => {
    this.now = new Date();

    if (this.waitQueueStore.data.length) {
      this.waitQueueStore.tick();
      this.modalStore?.gridWaitQueue?.current?.forceRepaint();
    }

    if (!this.isLoggedIn) return;

    // Ping elman manager
    this.publish({
      command: 'ping',
    });

    this.updateManagerOnline();

    this.globalTimerTasks.forEach((x) => {
      if (x.remain < 1) {
        // eslint-disable-next-line no-param-reassign
        x.remain = x.duration;
        x.fn();
      } else {
        // eslint-disable-next-line no-param-reassign,no-plusplus
        x.remain--;
      }
    });
  }

  publish(json: {}) {
    if (this.mqtt && this.mqtt.isConnected()) {
      this.mqtt.publish(this.managerTopic, JSON.stringify(json));
    }
  }

  addIntervalTask(fn: Function, durationSec: number) {
    this.globalTimerTasks.push({
      fn,
      duration: durationSec,
      remain: durationSec,
    });
  }

  setModalStore(m: ModalStore) {
    this.modalStore = m;
  }

  setActionStore(a: ActionStore) {
    this.actionStore = a;
  }

  setToolStore(a: ToolStore) {
    this.toolStore = a;
  }

  getPageParams() {
    const params = {
      ...this.pageParams,
    };
    this.pageParams = {};
    return Object.keys(params).length === 0 ? false : params;
  }

  @action
  setZoomF(f: number) {
    this.zoomF = f;
  }

  @computed
  get isLoggedIn() {
    return this.currentUser && this.currentUser.perid !== '';
  }

  @computed
  get user() {
    return this.currentUser || new UserModel();
  }

  @action
  updateManagerOnline() {
    const t = new Date().getTime() - this.lastPingTimeManager;
    this.isManagerOnline = t < 5000;
  }

  @action
  async tryLogin(data: AuthModel): Promise<boolean> {
    this.authData = data;
    return this.login();
  }

  @action
  async tryReLogin(): Promise<boolean> {
    return this.login();
  }

  @action
  async tryLoginForNext(next: Function): Promise<any> {
    if (await this.login()) {
      return next();
    }
    this.logout();
    ConfirmFail.show(
      '로그인 만료',
      '로그인 계정의 인증키가 만료되었습니다. 다시 로그인 바랍니다.',
    );
    return false;
  }

  @action
  async login(): Promise<boolean> {
    return this.withLoading(async () => {
      if (!this.authData) {
        return false;
      }

      const response = await AuthRepository.login(this.authData);
      if (response.status === 200) {
        await this.setUser(response.data, true);
        this.mqttConnect();
        this.retrieveSpjangs();

        this.retrieveEmailStatus(); // 이메일 오류 상태 갱신
        this.updateAlarm(); // 알림 갱신
        this.updateCS(); // 문의 갱신

        this.addIntervalTask(this.updateAlarm, 300);
        this.addIntervalTask(this.updateCS, 300);
        this.addIntervalTask(this.waitAsyncQueueStore.timeoutCheck, 1);

        this.asm = await UseWasm();
      } else {
        return false;
      }

      return true;
    });
  }

  @action
  async setUser(data: any, preserveSpjangcd: boolean = false) {
    if (data) {
      if (preserveSpjangcd && this.currentUser?.perid) {
        this.currentUser = new UserModel({
          ...data,
          spjangcd: this.currentUser.spjangcd,
        });
      } else {
        this.currentUser = new UserModel(data);
      }

      this.themeName = data.theme;
      return true;
    }
    return false;
  }

  @computed
  get isContPermitted(): boolean {
    return this.currentUser?.kukcd === '01' || this.currentUser?.contflag === '1';
  }

  logout() {
    this.setUser({});
  }

  @action
  preLoading() {
    this.isPreLoading = true;

    try {
      // @ts-ignore
      document.getElementById('loading-screen').style.pointerEvents = 'all';
      // @ts-ignore
      document.getElementById('header-tool-loader').style.display = 'block';
    } catch {
      //
    }
  }

  @action
  doLoading() {
    this.isLoading = true;
  }

  @action
  stopLoading() {
    this.isLoading = false;
    this.isPreLoading = false;

    try {
      // @ts-ignore
      document.getElementById('loading-screen').style.pointerEvents = 'none';
      // @ts-ignore
      document.getElementById('header-tool-loader').style.display = 'none';
    } catch {
      //
    }
  }

  async withLoading(fn: Function, next?: Function, disabled?: boolean): Promise<any> {
    if (disabled) {
      try {
        return await fn();
      } finally {
        next && next();
      }
    }

    this.preLoading();
    const sec1 = setTimeout(() => this.doLoading(), 800);
    this.loadingCount += 1;
    try {
      return await fn();
    } finally {
      clearTimeout(sec1);
      this.loadingCount -= 1;
      if (this.loadingCount === 0) this.stopLoading();
      next && next();
    }
  }

  async request(fn: RequestType, disableLoading?: boolean): Promise<any> {
    return this.withLoading(async () => {
      try {
        const { data, status } = await fn();
        if (status === 206 && data && data.messagebox) {
          ConfirmWarning.show(
            data.titlebox,
            Fix.newline(data.messagebox),
          );
          return data;
        }

        if (status === 204) {
          ConfirmWarning.show(
            '오류',
            '자료가 없습니다.',
          );
        } else if (!data) {
          if (this.errorCount > 1) {
            this.errorCount = 0;
            ConfirmWarning.show(
              '오류',
              '서버에서 응답이 없습니다.',
            );
            return undefined;
          }

          this.errorCount += 1;
          return this.request(fn);
        } else if (status === 200) {
          return data;
        }
        return undefined;
      } catch (e) {
        if (e.response && e.response.data && e.response.data.messagebox) {
          ConfirmFail.show(e.response.data.titlebox, e.response.data.messagebox.replace(/\\\\n/ig, '\n'));
        } else if (e.response.status === 401) {
          return this.tryLoginForNext(() => this.request(fn));
        } else {
          ConfirmFail.show(`${e.response?.status || 500}`, '서버 오류입니다.');
        }
        return undefined;
      }
    }, undefined, disableLoading);
  }

  @computed
  get theme() {
    return this.themeName || 'brown';
  }

  @computed
  get contrast() {
    return this.user.contrast;
  }

  async clearCache() {
    const windowName = this.currentMenu.active.path?.substr(1);
    if (!windowName) {
      ConfirmWarning.show('기능 없음', '이 화면에서은 캐시 기능이 없습니다.');
      return;
    }

    if (await this.withLoading(async () => FunctionRepository.request(
      true,
      `${process.env.REACT_APP_API_CACHE_HOST}/cache/reset`, {
        custcd: this.user.custcd,
        spjangcd: this.user.spjangcd,
        window: windowName,
        params: '',
      },
    ))) {
      ConfirmSuccess.show('완료', '현재 화면의 캐시를 비웠습니다');
    }
  }

  setCurrentPage(page: PageToolEvents) {
    this.currentPage = page;
    this.setStateChanged(false);
  }

  public setStateChanged = (changed: boolean) => {
    this.isStateChanged = changed;
  }

  @computed
  get headerTabStoredState(): any {
    const storedTab = this.headerTabStore.active;
    return JSON.parse(storedTab?.data?.state || 'false');
  }

  @computed
  get headerTabStoredTableLayoutPreRender(): any {
    const storedTab = this.headerTabStore.active;
    return storedTab?.data?.tableLayoutPreRendered;
  }

  @action
  go(menu: MenuModel | string, params: any = {}) {
    if (typeof menu === 'string') {
      // eslint-disable-next-line no-restricted-syntax
      for (const x of this.menuStore.menus!) {
        // eslint-disable-next-line no-restricted-syntax
        for (const y of x.children!) {
          // eslint-disable-next-line no-restricted-syntax
          for (const z of y.children!) {
            if (z.path === menu) {
              this.headerTabStore.toggle(z, params);
              return;
            }

            if (z.children) {
              // eslint-disable-next-line no-restricted-syntax
              for (const u of z.children) {
                if (u.path === menu) {
                  this.headerTabStore.toggle(u, params);
                  return;
                }
              }
            }
          }
        }
      }

      ConfirmWarning.show('오류', `없는 페이지입니다 : ${menu}`);
    } else {
      this.headerTabStore.toggle(menu, params);
    }
  }

  /**
   * Make params for requesting api server
   * @param data
   * @param enableCache
   */
  async makeParams(data: any = {}, useCache?: boolean): Promise<any> {
    const tempData = {
      sub: '',
      ...data,
    };

    const windowName = this.currentMenu.active.path?.substr(1) || '';
    return this.makeParamsWithWindowName(
      windowName || tempData?.sub,
      tempData,
      useCache,
    );
  }

  async makeParamsForSave(data: any, isInsert: boolean): Promise<any> {
    const windowName = this.currentMenu.active.path?.substr(1) || '';
    return this.makeParamsWithWindowName(
      windowName || data?.sub,
      {
        new: isInsert ? '1' : '0',
        sub: '',
        ...data,
      },
    );
  }

  async makeParamsWithWindowName(windowName: string, data?: any, useCache?: boolean): Promise<any> {
    return {
      custcd: this.user.custcd,
      spjangcd: this.user.spjangcd,
      perid: this.user.perid,
      token: this.user.token,
      window: windowName,
      data: JSON.stringify(data || {}),
      cache: useCache,
    };
  }

  private mqttConnect = () => {
    if (this.mqtt.isConnected()) {
      return;
    }
    this.mqtt.connect({ onSuccess: this.onMqttConnect, useSSL: true });
  }

  private onMqttConnect = () => {
    this.mqtt.subscribe('/elman/web', { qos: 1 });
    this.mqtt.subscribe(`/elman/web/${this.user.custcd}`, { qos: 1 });
    this.mqtt.subscribe(`/elman/web/${this.user.custcd}/${this.user.spjangcd}`, { qos: 1 });
    this.mqtt.subscribe(`/elman/web/${this.user.custcd}/${this.user.perid}`, { qos: 1 });
    this.mqtt.subscribe(`/elman/web/${this.user.custcd}/${this.user.perid}/wait`, { qos: 1 });
    this.mqtt.subscribe(`${this.managerTopic}/web`, { qos: 1 });
    this.mqtt.subscribe(`/vms/${this.user.custcd}/${this.user.perid}/done`, { qos: 1 });
    this.mqtt.subscribe(`/vms/${this.user.custcd}/${this.user.perid}/failed`, { qos: 1 });
  }

  private onMqttConnectLost = () => {
    this.mqttConnect();
  }

  private onMqttMessageArrived = (message: any) => {
    const topic = message.destinationName;
    const data = message.payloadString;

    if (topic === `/elman/web/${this.user.custcd}/${this.user.perid}/wait`) {
      // Long time task
      this.waitQueueStore.onEvent(JSON.parse(JSON.parse(data)));
      if (this.currentPage?.onMessageEvent) {
        this.currentPage?.onMessageEvent(topic, data);
      }
    } else if (topic === `/elman/web/${this.user.custcd}/${this.user.perid}`) {
      // PB
      const json = JSON.parse(JSON.parse(data));
      if (json && json?.key) {
        switch (json.key) {
          case 'ALERT':
            if (json?.title && json?.message) {
              ConfirmWarning.show(json.title, json.message);
            }
            break;

          default:
            if (this.currentPage?.onMessageEvent) {
              this.currentPage?.onMessageEvent(topic, data);
            }
        }
      }
    } else if (topic === `${this.managerTopic}/web`) {
      // Elman manager
      const json = JSON.parse(data);
      switch (json.command) {
        case 'ping':
          this.lastPingTimeManager = new Date().getTime();
          break;

        case 'ready':
          this.publish({
            command: 'connect',
            custcd: this.user.custcd,
            spjangcd: this.user.spjangcd,
            spjangnm: this.user.spjangnm,
            perid: this.user.perid,
            pernm: this.user.pernm,
            tel: this.user.tel,
          });
          break;

        case 'connect':
          this.modalStore?.closeElmanManagerNotice();

          while (this.managerConnectEventQueue.length > 0) {
            const f = this.managerConnectEventQueue.pop();
            try {
              f && f();
            } catch (_) {
              //
            }
          }
          break;

        case 'script':
          this.waitAsyncQueueStore.onEvent(json);
          break;

        default:
          if (this.currentPage?.onMessageEvent) {
            this.currentPage?.onMessageEvent(topic, data);
          }
      }
    } else if (this.currentPage?.onMessageEvent) {
      this.currentPage?.onMessageEvent(topic, data);
    }
  }

  @action
  public openElmanManager() {
    this.modalStore?.openElmanManagerNotice();
    window.location.href = `emr://${this.managerKey}`;
  }

  @action
  public async withManager(fn: Function | undefined) {
    if (this.isManagerOnline) {
      fn && fn();
    } else if (AskType.YES === await Confirm.ask(
      '엘맨 매니저 필요',
      '엘맨 매니저가 실행중이 아닙니다.\n지금 실행하시겠습니까?\n\n(해당 작업은 실행 완료 후 이어서 진행됩니다)',
      '예',
      '취소',
    )) {
      fn && this.managerConnectEventQueue.push(fn);
      this.openElmanManager();
    }
  }

  @action
  public async runManagerScript(code: string, args: string, isXml = false): Promise<any> {
    return new Promise<any>((resolve) => {
      const { user } = this;
      const key = MD5.make(`MANAGER_SCRIPT_${code}_${args}_${(new Date().getTime())}`);
      this.waitAsyncQueueStore.append(key, isXml, resolve, () => {
        this.mqtt.publish(this.managerTopic, JSON.stringify({
          command: 'script',
          custcd: user.custcd,
          spjangcd: user.spjangcd,
          perid: user.perid,
          seg: code,
          output: isXml ? 'xml' : 'json',
          args,
          key,
        }));
      });
    });
  }

  @action
  public updateAlarm = async () => {
    const { toolStore } = this;
    const response = await FunctionRepository.dropdown(
      'wf_dd_wakeup',
      await this.makeParams({ sub: 'w_xusers' }),
    );
    toolStore?.setBadgeText('ALERT', response?.data?.cnt || 0);
  }

  @action
  public updateCS = async () => {
    const { toolStore } = this;
    const response = await FunctionRepository.dropdown(
      'wf_dd_answer',
      await this.makeParams({ sub: 'w_xusers' }),
    );
    toolStore?.setBadgeText('QA', response?.data?.cnt || 0);
  }

  @action
  public retrieveSpjangs = async () => {
    const { data } = await FunctionRepository.dropdown(
      'wf_dd_spjangcd_02',
      await this.makeParams({ sub: 'w_xusers' }),
    );
    this.spjangs = data?.items || this.spjangs;
  }

  @action
  public retrieveEmailStatus = async () => {
    const { data } = await ActionRepository.retrieve(
      'general',
      await this.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_email_fail',
      }),
    );

    if (data?.title) {
      ConfirmWarning.show(data.title, Fix.newline(data.Message));
    }
  }

  @action
  openFavoriteMenu() {
    this.isFavoriteMenuVisible = true;
  }

  @action
  openDefaultMenu() {
    this.isFavoriteMenuVisible = false;
  }

  public getPublicIpAddress = async () : Promise<string> => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      return 'Unknown';
    }
  }

  @action
  public xloginAdd = async (buton: string) => {
    const windowName = this.currentMenu.active.path?.substr(1) || '';
    if (!windowName) {
      return;
    }

    if (!this.user.ipaddr) {
      this.user.ipaddr = await this.getPublicIpAddress();
    }

    await FunctionRepository.exec(
      'general',
      'xlogin',
      await this.makeParams({
        sub: 'w_xusers',
        custcd: this.user.custcd,
        spjangcd: this.user.spjangcd,
        perid: this.user.perid,
        winid: windowName,
        winnm: this.currentMenu.active.text,
        buton,
        logip: this.user.ipaddr,
      }),
    );
  }
}
