import * as React from 'react';
import {
  FlexLayout, TableLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { ManageStatus } from '../ManageStatus';
import { ManageStatusTableHeaderInspect } from '../headers';

/**
 * 화면 > 탭 - 상단 > 검사일자별
 * @window w_tb_e601w_sulchi_01
 * @category 설치관리현황
 */
export const ManageStatusHeadTabInspectTemplate: React.FC<TemplateProps<ManageStatus>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <TableLayout
        ref={scope.refTableInspect}
        header={ManageStatusTableHeaderInspect(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.dataHeadTabTableInspect}
        infinityHandler={scope.inspectInfinity}
        onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
        onRowDoubleClick={(item) => scope.onClickOpenResist(item)}
        rowColor={(x) => scope.headerRowColor(x)}
        onChange={(_, updatedRows) => scope.onHeadChange(updatedRows)}
      />
    </FlexLayout>
  );
};
