/**
 * 컨트롤러
 * @window w_tb_e411w_08
 * @category 월별다발고장현황
 */
import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { MonthBreakOftenTemplate } from './MonthBreakOften.template';
import { Today } from '../../../../utils/time';
import { InfinityRetrieve } from '../../../../models';
import { MonthBreakOftenModel } from './MonthBreakOften.model';
import { GridLayout } from '../../../../components';
import { MonthBreakOftenModalModel } from './MonthBreakOftenModal.model';
import { ConfirmWarning } from '../../../../utils/confirm';

interface MonthBreakOftenState {
  // 조회 조건
  year: string;
  searchQuery: string;
  wadcnt: string;
  combo: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // List
  monthBreakOftenList: Array<MonthBreakOftenModel>;
  focusedData: MonthBreakOftenModel;

  // trail
  cnt_tot: string;

  // Modal
  isvisibleModal: boolean;
  modalData: Array<MonthBreakOftenModalModel>;

  // modal trail
  modal_cnt_tot: string;
}

export class MonthBreakOften extends PageComponent<PageProps, MonthBreakOftenState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  modalGrid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      // 조회조건
      year: Today.year(),
      searchQuery: '',
      wadcnt: '3',
      combo: '0',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',

      // List
      monthBreakOftenList: [],
      focusedData: [],

      // trail
      cnt_tot: '0',

      // Modal
      modalData: [],
      isvisibleModal: false,

      // modal trail
      modal_cnt_tot: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        as_nm: this.state.searchQuery,
        wadcnt: this.state.wadcnt,
        combo: this.state.combo,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          monthBreakOftenList: [...this.state.monthBreakOftenList, ...items.map((x: any) => new MonthBreakOftenModel(x))],
        });
      },
      async () => {
        await this.SS({
          monthBreakOftenList: [],
        });
      },
    );
    await this.SS({
      monthBreakOftenList: [],
    });

    await this.infinity?.retrieveAll();

    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot,
    });

    if (this.state.monthBreakOftenList && this.state.monthBreakOftenList?.length > 0) {
      await this.grid.current?.setFocus(0);
    }
  }

  @action
  async onRowFocusEvent(item: MonthBreakOftenModel) {
    await this.SS({
      focusedData: new MonthBreakOftenModel(item),
    });
  }

  @action
  async onOpenModal(isOpen: boolean) {
    await this.SS({
      isvisibleModal: isOpen,
    });

    await this.onModalRetrieveEvent();
  }

  @action
  async onModalRetrieveEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_e411w_08',
        year: this.state.year,
        actcd: this.state.focusedData?.actcd,
        equpcd: this.state.focusedData?.equpcd,
        wadcnt: this.state.wadcnt,
      });

    await this.SS({
      modalData: data?.items,
      modal_cnt_tot: data?.cnt_tot || '0',
    });
  }

  @action
  async onCloseModal() {
    await this.SS({
      isvisibleModal: false,
      modalData: [],
      modal_cnt_tot: '0',
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.state.monthBreakOftenList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      as_nm: this.state.searchQuery,
      wadcnt: this.state.wadcnt,
      combo: this.state.combo,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (this.state.monthBreakOftenList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      as_nm: this.state.searchQuery,
      wadcnt: this.state.wadcnt,
      combo: this.state.combo,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  render() {
    return <MonthBreakOftenTemplate
      scope={this}
    >
    </MonthBreakOftenTemplate>;
  }
}
