import * as React from 'react';
import {
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  FormatTextBox,
  GridLayout,
  Button,
  LabelBox,
  FormatNumberTextBox,
  TableLayout,
  LayoutTitle,
  ModalView,
  AttachmentBox,
  ComboBox,
  ComboBoxModel,
  CheckBox,
  DefaultLayout,
  SubHeader,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Expenditure, ExpenditureItemChangeTypes,
} from './Expenditure';
import {
  Date8,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Sum, Update } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_ae200
 * @category 지출결의서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

const sndflags = [
  { value: '0', remark: '미지급' },
  { value: '1', remark: '지급' },
];

export const ExpenditureTemplate: React.FC<TemplateProps<Expenditure>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
            >
              <MdKeyboardArrowRight size={24} />
            </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span>검색어</span>
          <TextBox
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            placeholder="제목으로 검색하세요"
            placeholderColor="#CACACA"
            placeholderAlign="left"
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        {scope.state.peridState === '1'
          ? <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>사원명</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
          : ''}

        <FlexLayout/>
        <Button onClick={() => scope.loadJournalModal(true)}>
          <div style={{ width: 100 }}>일지KM 불러오기</div>
        </Button>

        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 100 }}>문서복사</div>
        </Button>

        <Button
          disabled={scope.state.data.appgubun === '101'}
          onClick={() => scope.onReport()}
        >
          <div style={{ width: 100 }}>{scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout size={372}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'inputdate',
              text: '일자',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.inputdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'inputnum',
              text: '번호',
              width: 10,
            },
            {
              id: 'subject',
              text: '제목',
              width: 35,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.subject}
                </FlexLayout>
              ),
            },
            {
              id: 'appgubun',
              text: '상태',
              width: 10,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={() => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())}
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns?.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark?.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'attcnt',
              text: '첨부',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {`${x.attcnt} 건`}
                </FlexLayout>
              ),
            },
            {
              id: 'sndflag',
              text: '지급',
              width: 15,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                  style={{ color: x.sndflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                >
                  {sndflags.filter((y) => y.value === x.sndflag)[0]?.remark}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.expenditureList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 지출결의서를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.inputdate}
                textAlign="center"
                format="####-##-##"
                onChange={
                  (value) => setData({ inputdate: value },
                    () => scope.itemChanged(ExpenditureItemChangeTypes.INPUTDATE))
                }
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.inputnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="##-######-###-####"
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.inputsabun}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="제목"
              weight={3}
            >
              <TextBox
                value={model.subject}
                onChange={(value) => setData({ subject: value })}
              />
            </LabelBox>
            <LabelBox
              title="지급여부"
              footer={
                <FlexLayout>
                  <DefaultLayout
                    size={150}
                    style={{ marginRight: 16 }}
                  >
                  </DefaultLayout>
                  <DateTextBox
                    value={model?.snddate}
                    textAlign="center"
                    format="####-##-##"
                    readonly={true}
                  />
                </FlexLayout>
              }
            >
              {
                model?.new === '1'
                  ? <ComboBox
                value={model?.sndflag}
                readonly={model?.new !== '1'}
                style={{
                  color: scope.state.focusedExpenditureDetail?.sndflag === '1'
                    ? 'var(--color-blue)'
                    : 'var(--color-red)',
                }}
                data={sndflags.map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => setData({ sndflag: option.value })}
                />
                  : <FlexLayout
                    justify='center'
                    align='center'
                    style={{
                      color: scope.state.focusedExpenditureDetail?.sndflag === '1'
                        ? 'var(--color-blue)'
                        : 'var(--color-red)',
                    }}
                  >
                    {model?.sndflag === '1' ? '지급' : '미지급'}
                  </FlexLayout>
              }
              <FormatNumberTextBox
                char={','}
                charAt={3}
                value={model.amount}
                textAlign='right'
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox title="비고 (최대 10줄까지 입력가능합니다)" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={model?.attcnt === 0 ? '첨부파일 0건 없음' : `첨부파일 ${model?.attcnt}건 있음`}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

        <LayoutTitle size={230}>상세내용</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 지출결의서를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'outnum',
              text: '순번',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.outnum}
                </FlexLayout>
              ),
            },
            {
              id: 'outdate',
              text: '사용일자',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  value={x.outdate}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ outdate: value })}
                />
              ),
            },
            {
              id: 'productnm',
              text: '품목명',
              width: 300,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="left"
                  value={x.productnm}
                  onChange={(value) => rowUpdate({ productnm: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '상세내역',
              width: 400,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="left"
                  value={x.remark}
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
            {
              id: 'samt',
              text: '공급가액',
              width: 150,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumSamt, true)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                />
              ),
            },
            {
              id: 'tamt',
              text: '부가세액',
              width: 150,
              trail: (data) => {
                const sumTamt = Sum.all(data, 'tamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumTamt, true)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.tamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, tamt: value }, rowUpdate, 'tamt')}
                />
              ),
            },
            {
              id: 'amount',
              text: '금액',
              width: 150,
              trail: (data) => {
                const sumAmount = Sum.all(data, 'amount');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumAmount, true)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.amount}
                  onChange={(value) => scope.tabAutoCalc({ ...x, amount: value }, rowUpdate, 'amount')}
                />
              ),
            },
            {
              id: 'cltnm',
              text: '지급처명',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.cltnm}
                  onChange={(value) => rowUpdate({ cltnm: value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.expenditureDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => scope.fileModal(false)}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            appnum={scope.state.data.appnum}
            appnm={'지출결의서'}
            isEnabledPreview={true}
            onChangeCount={async (count) => {
              // state
              setData({ attcnt: count.toString() });

              // grid
              const index = scope.state.expenditureList.findIndex((x) => x.inputdate === model?.inputdate);
              await Update.byIndex(scope, 'expenditureList', index, 'attcnt', count.toString());
              scope.grid.current?.forceRepaint(true);
            }}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.journalModal}
        onClose={() => scope.loadJournalModal(false)}
        width={1400}
        height={600}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>업무일지KM 불러오기</LayoutTitle>

          <FlexLayout size={Global.LAYOUT_TITLE_HEIGHT_1}>
            <span style = {{ marginTop: 8 }}>
              금액계산공식 : 단가(오피넷 당일평균가) * 1.2 / 10 * 운행거리(km)</span>
          </FlexLayout>

          <FlexLayout>
            <FlexLayout
              weight={1}
              isVertical={true}
            >
              <TableLayout
                ref={scope.popupTable}
                header={[
                  {
                    id: 'chk',
                    text: '선택',
                    width: 50,
                    render: (x, rowUpdate) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <CheckBox
                          value={x.chk === '1'}
                          onChange={
                            (value) => {
                              rowUpdate({ chk: value ? '1' : '0' });
                            }}
                          isDisabledTrackingStateChange={true}
                        />
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'rptdate',
                    text: '일자',
                    width: 100,
                    render: (x) => (
                      <FormatTextBox
                        textAlign="center"
                        format="####-##-##"
                        value={x.rptdate}
                      />
                    ),
                  },
                  {
                    id: 'uamt',
                    text: '평균단가',
                    width: 100,
                    render: (x) => (
                      <FormatNumberTextBox
                        weight={2}
                        char={','}
                        charAt={3}
                        textAlign="right"
                        value={x.uamt}
                      />
                    ),
                  },
                  {
                    id: 'km',
                    text: '운행거리',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {x.km} km
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'samt',
                    text: '금액',
                    width: 100,
                    render: (x) => (
                      <FormatNumberTextBox
                        weight={2}
                        char={','}
                        charAt={3}
                        textAlign="right"
                        value={x.samt}
                      />
                    ),
                  },
                ]}
                data={scope.state.popupList}
                infinityHandler={scope.infinity3}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowFocusEvent={(item) => scope.modalFocusChange(item)}
                onChange={(rows2, updatedRows2) => {
                  scope.onUpdatedRows2(rows2, updatedRows2);
                }}
              />

              <SubHeader>
                <FlexLayout justify="end">
                  <Button onClick={() => scope.modalDelete()}>삭제</Button>
                </FlexLayout>
              </SubHeader>
            </FlexLayout>

            <FlexLayout
              isVertical={true}
              weight={2}
            >
              <GridLayout
                ref={scope.popupDetailGrid}
                header={[
                  {
                    id: 'rptnum',
                    text: '순번',
                    width: 50,
                    render: (x) => (
                      <TextBox
                        value={x.rptnum}
                        textAlign="center"
                      />
                    ),
                  },
                  {
                    id: 'wkcd',
                    text: '구분',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        align='center'
                        justify='center'
                      >
                        {scope.state.wkcds.filter((y) => y.busicd === x.wkcd)[0]?.businm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'actnm',
                    text: '현장명',
                    width: 250,
                    render: (x) => (
                      <TextBox
                        value={x.actnm}
                        textAlign="left"
                      />
                    ),
                  },
                  {
                    id: 'equpnm',
                    text: '동호기',
                    width: 100,
                    render: (x) => (
                      <TextBox
                        value={x.equpnm}
                        textAlign="left"
                      />
                    ),
                  },
                  {
                    id: 'frtime',
                    text: '도착시간',
                    width: 100,
                    render: (x) => (
                      <FormatTextBox
                        textAlign="center"
                        format="##:##"
                        value={x.frtime}
                      />
                    ),
                  },
                  {
                    id: 'totime',
                    text: '완료시간',
                    width: 100,
                    render: (x) => (
                      <FormatTextBox
                        textAlign="center"
                        format="##:##"
                        value={x.totime}
                      />
                    ),
                  },
                  {
                    id: 'remark',
                    text: '세부내용(방문일지)',
                    width: 250,
                    render: (x) => (
                      <TextBox
                        value={x.remark}
                        textAlign="left"
                      />
                    ),
                  },
                  {
                    id: 'km',
                    text: '이동거리\n(KM)',
                    width: 100,
                    render: (x) => (
                      <TextBox
                        value={`${x.km} km`}
                        textAlign="center"
                      />
                    ),
                  },
                ]}
                data={scope.state.popupDetailList}
                infinityHandler={scope.infinity4}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />

              <SubHeader>
                <FlexLayout style={{ height: 30 }}>
                  <LayoutTitle weight={1}>일자</LayoutTitle>
                  <LabelBox weight={2.5}>
                    <DateTextBox
                      value={scope.state?.inputdate}
                      textAlign="center"
                      format="####-##-##"
                      onChange={(value) => scope.setState({ inputdate: value })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout justify="end">
                  <Button onClick={() => scope.modalSave()}>확인</Button>
                  <Button onClick={() => scope.loadJournalModal(false)}>닫기</Button>
                </FlexLayout>
              </SubHeader>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
