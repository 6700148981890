import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { DepartmentModel } from '../models';
import { LongReceivable } from '../LongReceivable';
import Format from '../../../../../utils/string/Format';

// 부서별
const Department: GridLayoutHeader<DepartmentModel, LongReceivable>[] = [
  {
    id: 'divinm',
    text: '부서명',
    width: 9,
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 7,
    trail: (_, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        건수: {scope?.state.total}
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 8,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 21,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '매출액',
    width: 11,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 11,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.rcvamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '미수잔액',
    width: 13,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.resuamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
];

export default Department;
