import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ConfirmWarning } from '../../utils/confirm';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import {
  ComboBox,
} from '../../components';

interface ModalCommonProps {
  modalStore: ModalStore;
}

@observer
export class ModalCommon extends React.Component<ModalCommonProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        isVisible={modalStore.isVisibleCommonSelector}
        zIndex={2}
        onClose={() => modalStore.closeCommonSelector()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout
              align="center"
              justify="center"
            >
              <span style={{ margin: 8, fontSize: 12 }}>검색어</span>
              <TextBox
                ref={modalStore.commonSelectorRefSearchQuery}
                value={modalStore.commonSelectorSearchQuery}
                onChange={(value: any) => modalStore.updateCommonSelectorSearchQuery(value)}
                onEnter={() => modalStore.searchCommonSelector()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            {modalStore.isVisibleCommonSelectorGubun && <FlexLayout size={180}>
              <span>{modalStore.commonSelectorGubunTitle}</span>
              <ComboBox
                value={modalStore.commonSelectorGubun}
                textAlign="left"
                data={modalStore.commonSelectorGubuns}
                onSelect={(option) => modalStore.updateCommonSelectorGubun(option.value)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>}
          </SearchLayout>

          <GridLayout
            ref={modalStore.searchCommonGrid}
            header={[{
              id: 'cd',
              text: '코드',
              width: 30,
              render: (x: any) => (<FlexLayout justify="center" align="center">
                {x.cd}
              </FlexLayout>),
            }, {
              id: 'cdnm',
              text: '코드명',
              width: 70,
              render: (x: any) => (<FlexLayout justify="start" align="center">
                {x.cdnm}
              </FlexLayout>),
            }]}
            infinityHandler={modalStore.commonSelectorInfinity}
            data={modalStore.commonSelectorData || []}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item: any) => modalStore.onCommonSelectorRowFocusEvent(item)}
            onRowDoubleClick={(item: any) => modalStore.successCommonSelector(item)}
          />

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeCommonSelector(true)}
            >
              닫기
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (modalStore.selectedCommonData) {
                  modalStore.successCommonSelector(modalStore.selectedCommonData);
                } else {
                  ConfirmWarning.show('오류', '항목을 먼저 선택해주세요.');
                }
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
