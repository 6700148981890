import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import style from './TextBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import { FlexLayout } from '../../layout';
// @ts-ignore
import { Global, LayoutProps } from '../../../constants';
import { PublicStore } from '../../../stores';

interface TextBoxActions {
  onChange?: (value: any) => any;
  onBlur?: (value: any) => any;
  onEnter?: (value: any) => any; // Single line only
}

interface TextBoxProps extends TextBoxActions, LayoutProps {
  publicStore?: PublicStore;
  value?: any;
  color?: string;
  bold?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  placeholder?: string;
  placeholderLeft?: boolean;
  placeholderColor?: string;
  placeholderAlign?: 'left' | 'center' | 'right';
  type?: any;
  className?: any;
  style?: any;
  isMultiline?: boolean;
  readonly?: boolean;
  transparent?: boolean;
  noPadding?: boolean;
  isDisabledTrackingStateChange?: boolean;
  head?: React.ReactNode;
  trail?: React.ReactNode;
  // @ts-ignore
  bindSearchModal?: Global.SearchBindingTypes;
}

interface TextBoxState {
  editable?: boolean;
}

const getAlignStyle = (value?: 'left' | 'center' | 'right'): {} => {
  if (!value) return {};

  switch (value.toUpperCase()) {
    case 'LEFT':
      return { left: 12 };
    case 'RIGHT':
      return { right: 12 };
    default:
      return 'center';
  }
};

@inject('publicStore')
export class TextBox extends React.Component<TextBoxProps, TextBoxState> {
  ref: React.RefObject<any> = React.createRef();

  isRefView: boolean;

  willFocus: boolean;

  constructor(props: TextBoxProps, context: any) {
    super(props, context);
    // eslint-disable-next-line no-prototype-builtins
    this.isRefView = false; // this.props.hasOwnProperty('ref');
    this.willFocus = false;
    this.state = {
      editable: !this.isRefView,
    };

    const checkString = this.replaceIrregularCharacter(props?.value);
    if (checkString !== props?.value) {
      this.callOnChange(checkString, true);
    }
  }

  shouldComponentUpdate(nextProps: Readonly<TextBoxProps>, nextStates: Readonly<TextBoxState>): boolean {
    return nextProps.value !== this.props.value
      || nextProps.color !== this.props.color
      || nextProps.readonly !== this.props.readonly
      || nextProps.placeholder !== this.props.placeholder
      || nextStates.editable !== this.state.editable;
  }

  componentDidUpdate(_: Readonly<TextBoxProps>, _2: Readonly<TextBoxState>, _3?: any): void {
    const checkString = this.replaceIrregularCharacter(this.props?.value);
    if (checkString !== this.props?.value) {
      this.callOnChange(checkString, true);
    }
  }

  replaceIrregularCharacter(value?: string): string {
    let results = `${value || ''}`;

    if (results.indexOf('\\') > -1) {
      results = results.replace(/\\+/g, '￦');
    }

    if (results.indexOf('|') > -1) {
      results = results.replace(/\|/g, '│');
    }

    return results;
  }

  @action
  callOnChange(value: string, trackingIgnore: boolean = false) {
    const { publicStore } = this.props;
    this.props.onChange && this.props.onChange(value);

    if (!trackingIgnore && !this.props.isDisabledTrackingStateChange) {
      publicStore?.setStateChanged(true);
    }
  }

  @action
  public focus() {
    this.willFocus = true;
    if (!this.state.editable) {
      this.setState({
        editable: true,
      });
    }
    this.ref.current?.focus();
  }

  render() {
    return (
      <FlexLayout
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.noPadding ? style.noPadding : false,
          this.props.transparent ? style.transparent : false,
          this.props.readonly ? style.readonly : false,
        ])}
        style={this.props.style}
        weight={this.props.weight}
        size={this.props.size}
        justify="center"
        align="center"
        onClick={() => this.focus()}
        onUnFocus={() => this.isRefView && this.setState({ editable: false })}
      >
        {!this.props.value && this.props.placeholder && (
          <label
            style={{
              color: this.props.placeholderColor || 'initial',
              ...getAlignStyle(this.props.placeholderAlign),
            }}
          >
            {this.props.placeholder}
          </label>
        )}

        {(!this.isRefView || this.state.editable) ? (
          this.props.isMultiline ? (
            <textarea
              ref={this.ref}
              onChange={(e) => this.callOnChange(e.target.value)}
              onBlur={(e) => this.props.onBlur && this.props.onBlur(e.target.value)}
              value={this.props.value || ''}
              readOnly={this.props.readonly}
              // @ts-ignore
              style={{
                textAlign: this.props?.textAlign,
                color: this.props?.color,
                fontWeight: this.props?.bold ? 600 : 'inherit',
              }}
            />
          ) : (
            <React.Fragment>
              {this.props.head}
              <input
                ref={this.ref}
                size={1}
                type={this.props.type || 'text'}
                value={this.props.value || ''}
                readOnly={this.props.readonly}
                style={{
                  textAlign: this.props?.textAlign,
                  color: this.props?.color,
                  fontWeight: this.props?.bold ? 600 : 'inherit',
                }}
                onChange={(e) => {
                  this.callOnChange(e.target.value);
                }}
                onDoubleClick={(e) => {
                  if (this.props.bindSearchModal && !this.props.readonly) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.bindSearchModal.open();
                  } else {
                    this.props.onDoubleClick && this.props.onDoubleClick();
                  }
                }}
                onFocus={() => this.ref.current?.setSelectionRange(0, this.props.value?.length || 0)}
                onBlur={(e) => {
                  this.props.onBlur && this.props.onBlur(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (this.props.bindSearchModal && !this.props.readonly && !this.props.readonly) {
                      if (this.props.value === '') {
                        this.props.bindSearchModal.onSearch({});
                        return;
                      }

                      this.props.bindSearchModal.open({
                        as_nm: this.props.value,
                      });
                    } else {
                      this.props.onEnter && this.props.onEnter(this.props.value);
                    }
                  } else {
                    this.props.onKeyDown && this.props.onKeyDown(e);
                  }
                }}
              />
              {this.props.trail}
            </React.Fragment>
          )
        ) : (
          <FlexLayout
            justify={this.props?.textAlign || 'left'}
            className={this.props.readonly ? style.readonly : undefined}
            style={{
              color: this.props?.color || '#000000',
              fontWeight: this.props?.bold ? 600 : 'inherit',
              padding: '0 8px',
            }}
          >
            {this.props.type?.toUpperCase() === 'PASSWORD' && this.props.value ? '********' : this.props.value}
          </FlexLayout>
        )}
      </FlexLayout>
    );
  }
}
