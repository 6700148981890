import { action } from 'mobx';
import * as React from 'react';
import { PageComponent } from '../../../../utils';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ContractStatisticsTemplate } from './ContractStatistics.template';
import { ContractStatisticsModel } from './models/ContractStatistics.model';
import { ModalDataModel } from './models/ModalData.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout, TableLayout } from '../../../../components';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';

interface ContractStatisticsState {
  data: Array<ContractStatisticsModel>;
  year: string;
  firstRetrieve: boolean;
  spjangData: Array<ContractStatisticsModel>;
  spjangcd: string;

  // 전체 선택
  allChk: boolean

  // ModalData
  modalData: Array<ModalDataModel>;

  focused?: ContractStatisticsModel;

  // Grid Trail
  qty01_tot: string;
  amt01_tot: string;
  qty03_plus_tot: string;
  amt03_plus_tot: string;
  tot_plus_tot: string;
  qty03_minus_tot: string;
  amt03_minus_tot: string;
  qty_cls_tot: string;
  amt_cls_tot: string;
  tot_minus_tot: string;
  tot_amt_tot: string;

  // Modal
  modal: boolean;
  mons: string;
  tabNames: string;

  // Modal Grid Trail
  total: string;
  qty_tot: string;
  amt_tot: string;

  // 계약
  contgsData: Array<any>;
  // 사업장리스트
  spjangList: Array<any>;
}

/**
 * 컨트롤러
 * @window www
 * @category 계약마감통계
 */
export class ContractStatistics extends PageComponent<PageProps, ContractStatisticsState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  modalInfinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  modalGrid: React.RefObject<GridLayout> = React.createRef();

  updatedRows?: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      data: [],
      year: Today.year(),
      firstRetrieve: false,
      spjangcd: '',

      // 전체 선택
      allChk: true,

      // Modal Data
      modalData: [],

      focused: [],

      // Grid Trail
      qty01_tot: '0',
      amt01_tot: '0',
      qty03_plus_tot: '0',
      amt03_plus_tot: '0',
      tot_plus_tot: '0',
      qty03_minus_tot: '0',
      amt03_minus_tot: '0',
      qty_cls_tot: '0',
      amt_cls_tot: '0',
      tot_minus_tot: '0',
      tot_amt_tot: '0',

      // Modal
      modal: false,
      mons: '',

      // Modal Grid Trail
      total: '0',
      qty_tot: '0',
      amt_tot: '0',

      // 계약
      contgsData: [],
      // 사업장리스트
      spjangList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 조회 시 체크를 풀어도 retrieve_cond 시에는 전체 목록을 가져오는 현상으로 인해
    // 첫번째 조회시에만 retrieve_cond 타도록 함
    if (this.state.firstRetrieve === false) {
      await this.SS({
        spjangData: [],
      });

      const data = await api.fxExec('retrieve_cond',
        {});

      await this.SS({
        spjangData: data?.items?.map((x: any) => new ContractStatisticsModel(x)),
        firstRetrieve: true,
      });

      if (!this.state.spjangData?.length) {
        ConfirmWarning.show('확인', '먼저 사업장을 등록해주세요.');
        return;
      }
    }

    if (!this.state.spjangData?.filter((x) => x.chk === '1').length) {
      ConfirmWarning.show('경고', '체크를 하나이상 해주세요');
      return;
    }

    if (this.state.spjangData && this.state.spjangData?.length > 0) {
      await this.table.current?.update(true);
      await this.table.current?.setFocus(0);
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year || '',
        items: this.state.spjangData?.filter((x) => x.chk === '1').map((x: any) => ({
          spjangcd: x.spjangcd,
          chk: x.chk,
        })),
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.filter((x) => x.spjangcd === undefined).map((x: any) => new ContractStatisticsModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();

        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    await this.setState({
      data: [],
    });

    await this.infinity?.retrieveAll();
    await this.SS({
      qty01_tot: this.infinity?.box?.qty01_tot || '0',
      amt01_tot: this.infinity?.box?.amt01_tot || '0',
      qty03_plus_tot: this.infinity?.box?.qty03_plus_tot || '0',
      amt03_plus_tot: this.infinity?.box?.amt03_plus_tot || '0',
      tot_plus_tot: this.infinity?.box?.tot_plus_tot || '0',
      qty03_minus_tot: this.infinity?.box?.qty03_minus_tot || '0',
      amt03_minus_tot: this.infinity?.box?.amt03_minus_tot || '0',
      qty_cls_tot: this.infinity?.box?.qty_cls_tot || '0',
      amt_cls_tot: this.infinity?.box?.amt_cls_tot || '0',
      tot_minus_tot: this.infinity?.box?.tot_minus_tot || '0',
      tot_amt_tot: this.infinity?.box?.tot_amt_tot || '0',
    });

    if (this.state.data && this.state.data?.length > 0) {
      await this.grid.current?.setFocus(0);
    }

    this.grid.current?.forceRepaint();
  }

  @action
  async onRowFocusEvent(item: ContractStatisticsModel | undefined) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    await this.setState({
      focused: new ContractStatisticsModel(item),
    }, async () => {
      this.infinity = new InfinityRetrieve(
        {
          year: this.state.year,
          items: this.state.spjangData,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items, next) => {
          this.setState({
            data: [
              ...this.state.data,
              ...items.map((x: any) => new ContractStatisticsModel(x)),
            ],
          }, next);
        },
        async () => {
          await this.SS({
            data: [],
          });

          await this.infinity?.retrieveAll();
          if (this.state.data && this.state.data?.length > 0) {
            await this.grid.current?.setFocus(0);
          }
        },
      );
    });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ spjangData: rows });
  }

  @action
  async onCheckAll(checked: boolean) {
    await this.SS({
      allChk: checked,
      spjangData: this.state.spjangData?.map((x) => new ContractStatisticsModel({
        ...x,
        chk: checked ? '1' : '0',
      })),
    });
    await this.table.current?.update(false);
  }

  @action
  async openModal(isOpen: boolean, tabName: string) {
    const { actionStore: api } = this.props;

    // 팝업창 계약
    const contgs = await api.dropdown('wf_dd_ca510_001_01');

    this.setState({ contgsData: contgs?.items });

    await this.SS({
      modal: isOpen,
      tabNames: tabName,
    });
    await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ modalData: [] });

    this.modalInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e101w_08',
        tab: this.state.tabNames,
        items: this.state.spjangData?.filter((x) => x.chk === '1').map((x: any) => ({
          spjangcd: x.spjangcd,
          chk: x.chk,
        })),
        mon: this.state.focused?.mon,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          modalData: [
            ...this.state.modalData,
            // ...items.map((item) => new ModalDataModel(item)),
            ...items.filter((x) => x.custcd !== undefined).map((x: any) => new ModalDataModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          modalData: [],
        });
        await this.modalInfinity?.retrieveAll();
      },
    );

    await this.modalInfinity?.retrieveAll();
    await this.SS({
      total: this.modalInfinity?.box?.total || '0',
      qty_tot: this.modalInfinity?.box?.qty_tot || '0',
      amt_tot: this.modalInfinity?.box?.amt_tot || '0',
    });

    if (this.state.modalData && this.state.modalData?.length > 0) {
      await this.modalGrid.current?.setFocus(0);
    }
  }

  @action
  async closeModal(isOpen: boolean) {
    await this.SS({
      modal: isOpen,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      year: this.state.year,
      items: this.state.spjangData?.filter((x) => x.chk === '1').map((x) => ({
        spjangcd: x.spjangcd,
        chk: x.chk,
      })),
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      year: this.state.year,
      items: this.state.spjangData?.filter((x) => x.chk === '1').map((x) => ({
        spjangcd: x.spjangcd,
        chk: x.chk,
      })),
    });
  }

  render() {
    return (
      <ContractStatisticsTemplate
        scope={this}
      >
      </ContractStatisticsTemplate>
    );
  }
}
