import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RowUpdate,
} from '../../../../constants';
import { AccountTemplate } from './Account.template';
import { AccountModel } from './Account.model';
import { TableLayout } from '../../../../components';
import { AcccdItem, InfinityRetrieve, SpjangItem } from '../../../../models/common';
import { BankItem } from '../../../human/staff/PersonnelResistration/PersonnelResistration';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface AccountState {
  searchQuery: string;
  accountList: Array<AccountModel>;
  data: AccountModel;
  focusedAccount?: AccountModel;
  banks?: Array<BankItem>;
  acccds?: Array<AcccdItem>;
  spjangcds?: Array<SpjangItem>;
  accamt: string; // 월 추가비용
  acccnt: string; // 연동계좌수
}

/**
 * 컨트롤러
 * @window w_s004
 * @category 계좌번호등록
 */
export class Account extends PageComponent<PageProps, AccountState>
  implements PageToolEvents {
  updatedRows?: Array<AccountModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      accamt: '',
      acccnt: '',
      accountList: [],
      banks: [],
      acccds: [],
      spjangcds: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 금융기관
    let data = await api.dropdown('wf_dd_s004');
    if (!data) return;
    this.setState({ banks: data.items });

    // 계정
    data = await api.dropdown('wf_dd_s004_acccd');
    if (!data) return;
    this.setState({ acccds: data.items });

    // 사업장
    data = await api.dropdown('wf_dd_spjangcd');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          accountList: [...this.state.accountList, ...items],
        }, () => this.table.current?.update());
      },
      async () => {
        await this.SS({
          accountList: [],
        });
        await this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      accountList: [],
    });
    if (!autoLoad) return;
    const data = await this.infinity?.retrieve();
    await this.SS({
      accamt: data?.accamt || '',
      acccnt: data?.acccnt || '',
    });

    await this.table.current?.update(true);
    this.state.accountList.length > 0 && this.table.current?.setFocus(0, 3);
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    data && this.setState({
      accountList: [...this.state.accountList, new AccountModel(data, true)],
    }, async () => {
      await this.table.current?.update(false);
      this.table.current?.setFocus(this.state.accountList.length - 1, 3);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
    this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focusedAccount?.banknm} ${this.state.focusedAccount?.accnum}`;
    await api.delete(text, this.state.focusedAccount) && this.onRetrieveEvent();
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.accountList.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.accountList.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: AccountModel) {
    this.setState({ focusedAccount: item });
  }

  @action
  async backItemChanged(rowUpdate: RowUpdate, x: AccountModel, name: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec(
      'dw_list_itemchanged',
      {
        itemname: name,
        data: value,
        new: x.isNew ? '1' : '0',
      },
    );

    rowUpdate(new AccountModel({
      ...x,
      bank: value,
      banknm: result.banknm,
      bankcd5: result.bankcd5,
    }, x.isNew));
  }

  render() {
    return <AccountTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
