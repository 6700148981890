import * as React from 'react';
import { action } from 'mobx';
import copy from 'fast-copy';
import { PageProps, PageToolEvents } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import StatusModel from './models/StatusModel';
import { StatusTemplate } from './Status.template';
import { Date8 } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { TableLayout } from '../../../../components';

interface IGubun {
  code: string;
  remark: string;
  chk: string;
}

export const Gubun: Array<IGubun> = [
  { code: '11', remark: '유지보수', chk: '0' },
  { code: '12', remark: '수리공사', chk: '0' },
  { code: '13', remark: '부품교체', chk: '0' },
  { code: '14', remark: '설치공사', chk: '1' },
  { code: '15', remark: '리모델링', chk: '1' },
  { code: '16', remark: '현대엘리베이터(공)', chk: '0' },
  { code: '17', remark: '전기공사', chk: '0' },
  { code: '18', remark: '통장거래', chk: '0' },
  { code: '19', remark: '기타수입', chk: '0' },
  { code: '20', remark: '기타환불', chk: '0' },
  { code: '21', remark: '가지급정산', chk: '0' },
  { code: '22', remark: '개발매출', chk: '0' },
];

interface StatusState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  perid: string;
  pernm: string;
  divicd: string;
  divinm: string;
  cltcd: string;
  cltnm: string;
  actcd: string;
  actnm: string;
  projno: string;
  projnm: string;
  as_nm: string;

  option: Array<IGubun>;

  data: Array<StatusModel>;
  focusIndex: number;

  mamt_tot: string;
  qty_tot: string;
  samt_tot: string;
  tamt_tot: string;

  allChecked: boolean;
}

/**
 * 컨트롤러
 * @window w_preview_da006
 * @category 수주현황
 */
export class Status extends PageComponent<PageProps, StatusState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      stdate: `${Date8.make().substr(0, 6)}01`,
      enddate: Date8.make(),
      searchQuery: '',
      data: [],
      option: copy(Gubun),
      focusIndex: 0,
      content: new StatusModel(),
      mamt_tot: '0',
      qty_tot: '0',
      samt_tot: '0',
      tamt_tot: '0',
      allChecked: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        perid: this.state.perid || '',
        divicd: this.state.divicd || '',
        as_nm: this.state.searchQuery || '',
        cltcd: this.state.cltcd || '',
        actcd: this.state.actcd || '',
        projno: this.state.projno || '',
        items: this.state.option,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((item) => new StatusModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({ data: [] });
        this.infinity?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      const data = await this.infinity?.retrieveAll();
      this.setState({
        mamt_tot: data?.mamt_tot,
        qty_tot: data?.qty_tot,
        samt_tot: data?.samt_tot,
        tamt_tot: data?.tamt_tot,
      });
    });
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      perid: this.state.perid || '',
      divicd: this.state.divicd || '',
      as_nm: this.state.as_nm || '',
      cltcd: this.state.cltcd || '',
      actcd: this.state.actcd || '',
      projno: this.state.projno || '',
      items: this.state.option,
    });
  }

  @action
  async onExcelEvent() {
    if (!ConfirmWarning.assert(this.state.data.length > 0, '오류', '엑셀전환할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      perid: this.state.perid || '',
      divicd: this.state.divicd || '',
      as_nm: this.state.as_nm || '',
      cltcd: this.state.cltcd || '',
      actcd: this.state.actcd || '',
      projno: this.state.projno || '',
      items: this.state.option,
    });
  }

  @action
  async toggleAllChecked() {
    await this.SS({
      allChecked: !this.state.allChecked,
      option: this.state.option.map((x) => ({
        ...x,
        chk: !this.state.allChecked ? '1' : '0',
      })),
    });
    this.table.current?.update(true);
  }

  render() {
    return <StatusTemplate
      scope={this}
    />;
  }
}
