import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { LastMonth } from './LastMonth';
import { Date6Calculator } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_input_da023w_02
 * @category 전월대비유지보수체크
 */
export const LastMonthTemplate: React.FC<TemplateProps<LastMonth>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={220}>
          <span>당월</span>
          <DateTextBox
            value={scope.state.yearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ yearmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({
                  yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString(),
                  lastYearmon: new Date6Calculator(scope.state.lastYearmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({
                  yearmon: new Date6Calculator(scope.state.yearmon).add().toString(),
                  lastYearmon: new Date6Calculator(scope.state.lastYearmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={220}>
          <span>전월</span>
          <DateTextBox
            value={scope.state.lastYearmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ lastYearmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ lastYearmon: new Date6Calculator(scope.state.lastYearmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ lastYearmon: new Date6Calculator(scope.state.lastYearmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={190}>
          <span>현장구분</span>
          <ComboBox
            value={scope.state.state}
            data={[
              { value: '%', remark: '전체' },
              { value: '0', remark: '보수' },
              { value: '1', remark: '설치' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ state: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={300}>
          <span style={{ color: 'var(--color-red)' }}>회사구분</span>
          <TextBox
            value={scope.state.wkactcd}
            textAlign="center"
            onChange={(value) => setData({ wkactcd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E018_1',
              {},
              true,
              (item) => {
                setData({
                  wkactcd: item.cd,
                  wkactnm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.5}
            value={scope.state.wkactnm}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>매출구분</span>
          <ComboBox
            value={scope.state.searchGubun}
            textAlign="center"
            style={{ marginLeft: 12 }}
            data={[
              { value: '11', remark: '유지보수' },
              { value: '12', remark: '수리공사' },
              { value: '13', remark: '부품교체' },
              { value: '14', remark: '설치공사' },
              { value: '15', remark: '리모델링' },
              { value: '16', remark: '현대엘리베이터(공)' },
              { value: '19', remark: '기타수입' },
              { value: '20', remark: '기타환불' },
              { value: '21', remark: '가지급정산' },
              { value: '17', remark: '전기공사' },
              { value: '22', remark: '개발매출' },
              { value: '18', remark: '통장거래' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ searchGubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'mismon',
            text: '년월',
            width: 10,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##"
                value={x.mismon}
              />
            ),
            trail: () => (
              <FlexLayout>
                합 계
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 35,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'beamt',
            text: '전월금액',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.beamt)}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout justify="right">
                {scope.state.beamt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '당월금액',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.misamt)}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout justify="right">
                {scope.state.misamt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'resuamt',
            text: '차액',
            width: 10,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
                style={{
                  color: x.resuamt > '0'
                    ? 'var(--color-blue)'
                    : 'var(--color-red)',
                }}>
                {Format.number(x.resuamt)}
              </FlexLayout>
            ),
            trail: () => (
              <FlexLayout
                justify="right"
              >
                {scope.state.resuamt_tot}
              </FlexLayout>
            ),
          },
          {
            id: 'state',
            text: '상태',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  color: x.resuamt > '0'
                    ? 'var(--color-blue)'
                    : 'var(--color-red)',
                }}>
                {x.state}
              </FlexLayout>
            ),
          },
          {
            id: 'contgnm',
            text: '계약구분',
            width: 6,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.contgnm}
              </FlexLayout>
            ),
          },
          {
            id: 'deljuginm',
            text: '청구주기',
            width: 4,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.deljuginm}
              </FlexLayout>
            ),
          },
          {
            id: 'sayoo',
            text: '차이예상',
            width: 20,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.sayoo}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
