import * as React from 'react';
import {
  IoIosMail, MdKeyboardArrowLeft, MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Badge, CheckBox, ComboBox, ComboBoxModel, DateTextBox, FlexLayout, SearchLayout, TableLayout, TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Format } from '../../../../utils/string';
import Date8 from '../../../../utils/time/Date8';
import { Disbursement } from './Disbursement';

/**
 * 화면
 * @window w_tb_ae200w_01
 * @category 지출결의서일괄출력
 */
export const DisbursementTemplate: React.FC<TemplateProps<Disbursement>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  setData({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => scope.setState({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  setData({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={400}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="제목, 비고, 작성자로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span>항목전체증빙여부</span>
            <ComboBox
              value={scope.state.useyn}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '증빙' },
                { value: '0', remark: '미증빙' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ useyn: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={200}>
            <span>결재여부</span>
            <ComboBox
              value={scope.state.appgubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '101', remark: '결재' },
                { value: '111', remark: '결재중' },
                { value: '131', remark: '반려' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ appgubun: option.value },
                () => scope.onRetrieveEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={scope.state.pushTotalCheck}
                  onChange={async (value) => scope
                    .updateCheckAllToggle(value)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
            width: 3,
            render: (x, rowUpdate) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={x.chk === '1'}
                  onChange={(value) => {
                    rowUpdate({ chk: value ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'prtchk',
            text: '출력구분',
            width: 3,
            render: (x, rowUpdate) => (
              <CheckBox
                value={x.prtchk === '1'}
                onChange={(v) => rowUpdate({ prtchk: v ? '1' : '0' })}
                isDisabledTrackingStateChange={true}
              />
            ),
          },
          {
            id: 'inputdate',
            text: '작성일자',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                건수: {scope.state.total}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.inputdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'inputnum',
            text: '번호',
            width: 4,
          },
          {
            id: 'sndflag',
            text: '지급여부',
            width: 7,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                출력건수: {scope.state.prtcnt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.sndflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
              >
                {x.sndflag === '1' ? '지급' : '미지급'}
              </FlexLayout>
            ),
          },
          {
            id: 'attcnt',
            text: '첨부파일',
            width: 8,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                미출력건수: {scope.state.notcnt_tot}
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.attcnt > '0' ? 'var(--color-blue)' : '' }}
              >
                {x.attcnt > '0' ? '있음' : '없음'} {x.attcnt} 건
              </FlexLayout>
            ),
          },
          {
            id: 'appgubun',
            text: '결재',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.appgubun === '101' ? 'var(--color-blue)' : 'var(--color-red)' }}
              >
                {x.appgubun === '101' ? '결재' : '미결재'}
                {x?.appremark && <Badge>
                  <IoIosMail color="var(--color-red)" size={25} />
                </Badge>}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '작성자',
            width: 7,
          },
          {
            id: 'mamt',
            text: '금액',
            width: 9,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.mamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'subject',
            text: '제목',
            width: 18,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.subject}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '비고',
            width: 30,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
