import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { DiplomaTemplate } from './Diploma.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { DiplomaModel } from './models';
import { PageComponent } from '../../../../utils/layout';
import { DateStabilizer } from '../../../../utils/time';
import { EstimateModalModel } from './models/EstimateModal.model';

interface DiplomaState {

  // 검색 조건
  stdate: string;
  enddate: string;
  searchQuery: string;
  src: string;

  // 데이터 객체
  focusedDiploma?: DiplomaModel;
  data: DiplomaModel;
  lastNewData: DiplomaModel;
  diplomaList: Array<DiplomaModel>;

  // 모달
  fileDetailModal: boolean; // 첨부파일
  estimateModal: boolean;
  estimateModalData: Array<EstimateModalModel>;
  estimateModalSearchQuery: string;
  estimateModalStdate: string;
  estimateModalEnddate: string;
  modalDate: string;
  estimateModalFocused: EstimateModalModel;
  estimateFlags: boolean;
  gubuns: Array<any>;
  modalGubuns: Array<any>;
  modalConstGubuns: Array<any>;
  gubun: string;
  paperflag: string;

  img_pic?: string;

  isCopy: boolean; // 복사 여부

  focusIndex: number;
  chk: string;
  custcd: string;
  spjangcd: string;
  spjangnm: string;
  total: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb405
 * @category 공문서
 */
export class Diploma extends PageComponent<PageProps, DiplomaState>
  implements PageToolEvents {
  updatedRows?: Array<DiplomaModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  estimateModalGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const storage = JSON.parse(window.localStorage['last-login'] || '{}');

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      isCopy: false,

      fileDetailModal: false,
      estimateModal: false,
      estimateModalData: [],
      estimateModalFocused: [],
      estimateModalSearchQuery: '',
      estimateModalStdate: `${year}0101`,
      estimateModalEnddate: `${year}${month}${date}`,

      modalDate: '00000000',
      gubuns: [],
      gubun: '%',
      paperflag: '0',

      diplomaList: [],
      data: new DiplomaModel(),
      focusIndex: 0,
      chk: '0',

      custcd: storage.custcd || 'dmyong_g',
      spjangcd: storage.spjangcd || '10',
      spjangnm: storage.spjangnm || '대명엘리베이터',
      total: '',
    };
  }


  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }

      data = await api.fxExec(
        'delete_appok',
        {
          paperdate: this.state.data.paperdate,
          papernum: this.state.data.papernum,
          appnum: this.state.data.appnum,
          appgubun: this.state.data.appgubun,
          title: this.state.data.title,
        }, false,
      );

      data && this.setState({
        data: new DiplomaModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_da020_all');
    if (!data) return;

    await this.SS({
      gubuns: data?.items || [],
    });

    const data2 = await api.dropdown('wf_dd_ca510_008_01');
    if (!data2) return;

    await this.SS({
      modalGubuns: data2?.items || [],
    });

    const data3 = await api.dropdown('wf_dd_da020');
    if (!data3) return;

    await this.SS({
      modalConstGubuns: data3?.items || [],
    });
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          diplomaList: items.map((x: any, index: number) => (
            new DiplomaModel({ ...x, page: (items.length - index).toString() }))),
          total: items.length.toString(),
        }, next);
      },
      async () => {
        await this.SS({
          diplomaList: [],
          data: new DiplomaModel(),
        });

        await this.infinity?.retrieveAll();
        if (this.state.diplomaList && this.state.diplomaList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      data: new DiplomaModel(),
      diplomaList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['paperdate', 'papernum'],
        [lastSelected?.paperdate, lastSelected?.papernum], type, true) || 0;
      if (this.state.diplomaList && this.state.diplomaList.length > index) {
        await this.grid.current?.setFocus(index);
        this.onRowFocusEvent(this.state.diplomaList[this.state.focusIndex], this.state.focusIndex);
      }
    });
  }

  @action
  onRowFocusEvent(item: DiplomaModel, index: number) {
    if (this.state.isCopy) {
      this.setState({
        isCopy: false,
      });
      return;
    }
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        data: lastNewData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      {
        focusedDiploma: item,
        focusIndex: index,
      },
      async () => {
        const datas = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            paperdate: this.state.focusedDiploma?.paperdate,
            papernum: this.state.focusedDiploma?.papernum,
          });
        await datas && this.setState({ data: new DiplomaModel(datas) });
      },
    );
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen },
      () => {
        if (!isOpen) { this.onRowFocusEvent(this.state.focusedDiploma!, this.state.focusIndex); }
      });
  }


  @action
  onSendFax() {
    const { actionStore: api } = this.props;

    api.fxFax(
      '',
      this.state.data.remark,
      {
        paperdate: this.state.data.paperdate,
        papernum: this.state.data.papernum,
        appgubun: this.state.data.appgubun,
        net: '',
        attcnt: this.state.data.attcnt,
      },
    );
  }

  @action
  onSendEmail() {
    const { actionStore: api } = this.props;

    api.fxEmail(
      '',
      '',
      '',
      '',
      this.state.data.subject,
      this.state.data.remark,
      {
        paperdate: this.state.data.paperdate,
        papernum: this.state.data.papernum,
        appgubun: this.state.data.appgubun,
        net: '',
        attcnt: this.state.data.attcnt,
      },
    );
  }

  // @action
  // onSendEmail() {
  //   const { actionStore: api } = this.props;
  //   const { user } = this.props.publicStore;
  //
  //   const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
  //
  //   api.exec(Category.EMAIL, 'wb_email', {
  //     src,
  //     paperdate: this.state.data.paperdate,
  //     papernum: this.state.data.papernum,
  //     appgubun: this.state.data.appgubun,
  //     net: '',
  //   });
  // }

  // 청구서 복사
  @action
  async onCopy() {
    const text = `작성일자: ${this.state.focusedDiploma?.paperdate}, 문서번호: ${this.state.data.appnum}`;
    if (!await Confirm.show('복사하시겠습니까?', text, ConfirmType.QUESTION)) {
      return;
    }

    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    const data = await api.exec(Category.EMAIL, 'wb_copy', {
      paperdate: this.state.data.paperdate,
      gyungyu: this.state.data.gyungyu,
      susin: this.state.data.susin,
      refer: this.state.data.refer,
      subject: this.state.data.subject,
      remark: this.state.data.remark,
      actdivinm: this.state.data.actdivinm,
      actpernm: this.state.data.actpernm,
      attcnt: this.state.data.attcnt,

    });

    if (data) {
      const newModel = new DiplomaModel(data, true);
      newModel.perid = user.perid;
      newModel.pernm = user.pernm;

      this.setState({
        data: newModel,
        diplomaList: [newModel, ...this.state.diplomaList],
        focusedDiploma: newModel,
        isCopy: true,
      });
      this.grid.current?.setFocus(0);
    }
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.data.new,
      custcd: this.state.data.custcd,
      spjangcd: this.state.data.spjangcd,
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
      appnum: this.state.data.appnum,
      gyungyu: this.state.data.gyungyu,
      susin: this.state.data.susin,
      refer: this.state.data.refer,
      subject: this.state.data.subject,
      remark: this.state.data.remark,
      recedate: this.state.data.recedate,
      recenum: this.state.data.recenum,
      actdivinm: this.state.data.actdivinm,
      actpernm: this.state.data.actpernm,
      divicd: this.state.data.divicd,
      divinm: this.state.data.divinm,
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      inperid: this.state.data.inperid,
      indate: this.state.data.indate,
      spjangnm: this.state.data.spjangnm,
      zipcd: this.state.data.zipcd,
      adres: this.state.data.adres,
      adresa: this.state.data.adresa,
      adresb: this.state.data.adresb,
      tel1: this.state.data.tel1,
      fax: this.state.data.fax,
      filename: this.state.data.filename,
      filename2: this.state.data.filename2,
      attcnt: this.state.data.attcnt,

    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.paperdate);
      await this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new DiplomaModel({
        ...data,
        perid: data?.inperid,
        pernm: data?.inpernm,
        remark: data?.remark,
      }, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        diplomaList: [
          newModel,
          ...this.state.diplomaList,
        ],
        total: this.state.diplomaList.length.toString(),
        focusedDiploma: newModel,
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.appgubun === null
      || this.state.data.appgubun === ''
      || this.state.data.appgubun === '131'
      || this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          paperdate: this.state.data.paperdate,
          papernum: this.state.data.papernum,
          perid: this.state.data.perid,
          appgubun: this.state.data.appgubun,
          appnum: this.state.data.appnum,
          title: this.state.data.title,
        }, false);
    } else {
      const text = `작성일자: ${this.state.focusedDiploma?.paperdate} 작성번호: ${this.state.focusedDiploma?.papernum}`;
      await api.delete(text, {
        paperdate: this.state.data.paperdate,
        papernum: this.state.data.papernum,
        perid: this.state.data.perid,
        appgubun: this.state.data.appgubun,
        appnum: this.state.data.appnum,
        title: this.state.data.title,
      });
    }
    await this.onRetrieveEvent();
  }

  @action
  async onEstimateModal(isOpen: boolean) {
    await this.SS({
      estimateModal: isOpen,
    });
    await this.onEstimateModalRetrieve();
  }

  @action
  async onEstimateModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      estimateModalData: [],
      estimateModalSearchQuery: '',
      modalDate: '00000000',
      img_pic: '',
    });

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_pb405_e451',
        stdate: this.state.estimateModalStdate,
        enddate: this.state.estimateModalEnddate,
        as_nm: this.state.estimateModalSearchQuery,
        gubun: this.state.gubun,
        paperflag: this.state.paperflag,
      },
    );

    await this.SS({
      estimateModalData: data?.items,
    });

    if (this.state.estimateModalData?.length) {
      this.estimateModalGrid.current?.setFocus(0);
    }
  }

  @action
  async estimateModalRowFocus(item: EstimateModalModel) {
    await this.SS({
      estimateModalFocused: item,
      img_pic: `https://api2.elmansoft.com/paper/CD001/view?custcd=${item.custcd}&spjangcd=${item.spjangcd}&costdate=${item.costdate}&costnum=${item.costnum}`,
    });
  }

  @action
  async estimateModalSave() {
    const { actionStore: api } = this.props;
    let data;
    if (this.state.modalDate === '00000000') {
      await ConfirmWarning.show('오류', '공문서에 등록할 일자를 입력하세요');
      return;
    }

    const result = await Confirm.show('선택!', '선택한 내용으로 견적서를 첨부하여 공문서를 등록하시겠습니까?', ConfirmType.QUESTION);

    if (result) {
      data = await api.save({
        sub: 'w_popup_pb405_e451',
        paperdate: this.state.modalDate,
        costdate: this.state.estimateModalFocused?.costdate,
        costnum: this.state.estimateModalFocused?.costnum,
        gubun: this.state.estimateModalFocused?.gubun,
        evidyn: this.state.estimateModalFocused?.evidyn,
        actnm: this.state.estimateModalFocused?.actnm,
        actcd: this.state.estimateModalFocused?.actcd,
        appnum: this.state.estimateModalFocused?.appnum,
        actdivinm: this.state.estimateModalFocused?.actdivinm,
        actpernm: this.state.estimateModalFocused?.actpernm,
        subject: this.state.estimateModalFocused?.constnm,
        divicd: this.state.estimateModalFocused?.divicd,
        divinm: this.state.estimateModalFocused?.divinm,
        perid: this.state.estimateModalFocused?.costperid,
        pernm: this.state.estimateModalFocused?.costpernm,
      }, false);
    }

    if (data.messagebox === '정상적으로 공문서가 등록되었습니다') {
      await this.SS({
        estimateModal: false,
      });
      await this.onRetrieveEvent();
      await this.grid.current?.forceRepaint();
    }
  }

  // @action
  // async onDeleteEvent() {
  //   const { actionStore: api } = this.props;
  //   const text = `작성일자: ${this.state.focusedDiploma?.paperdate} 작성번호: ${this.state.focusedDiploma?.papernum}`;
  //   await api.delete(text, {
  //     paperdate: this.state.data.paperdate,
  //     papernum: this.state.data.papernum,
  //     perid: this.state.data.perid,
  //     appgubun: this.state.data.appgubun,
  //     appnum: this.state.data.appnum,
  //     title: this.state.data.title,
  //   }) && this.onRetrieveEvent();
  // }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.papernum, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      paperdate: this.state.data.paperdate,
      papernum: this.state.data.papernum,
    });
  }

  render() {
    return (
      <DiplomaTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new DiplomaModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
