import { computed } from 'mobx';

/**
 * 모델
 * 분할납부정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentDivisionModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 접수일자
   *
   * **data type** varchar(8)
   */
  recedate: string;

  /**
   * 매출구분
   *
   * **data type** varchar(?)
   */
  gubun: string;

  /**
   * 매출분류
   *
   * **data type** varchar(?)
   */
  billgubun: string;

  /**
   * 회사구분
   *
   * **data type** varchar(?)
   */
  wkactcd: string;

  /**
   * 회사구분이름
   *
   * **data type** varchar(?)
   */
  wkactnm: string;

  /**
   * 분할납부거래처
   *
   * **data type** varchar(?)
   */
  cltcd: string;

  /**
   * 분할납부거래처이름
   *
   * **data type** varchar(?)
   */
  cltnm: string;

  /**
   * 세금구분
   *
   * **data type** varchar(?)
   */
  taxgubun: string;

  /**
   * 분할시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 분할만료일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 분할금액
   *
   * **data type** varchar(?)
   */
  amt: string;

  /**
   * 총회차
   *
   * **data type** varchar(?)
   */
  totqty: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(?)
   */
  addyn: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(?)
   */
  projno: string;

  /**
   * 프로젝트
   *
   * **data type** varchar(?)
   */
  projnm: string;

  /**
   * 전자계산서발행
   *
   * **data type** varchar(?)
   */
  taxflag: string;

  /**
   * 전자계산서발행체크
   *
   * **data type** varchar(?)
   */
  taxchk: string;

  /**
   * 전자계산서발행일자
   *
   * **data type** varchar(?)
   */
  dedate: string;

  /**
   * 지로고지서발행
   *
   * **data type** varchar(?)
   */
  jirogubun: string;

  /**
   * 지로고지서발행일자
   *
   * **data type** varchar(?)
   */
  ungijirodate: string;

  /**
   * 지로고지서발행체크
   *
   * **data type** varchar(?)
   */
  jirochk: string;

  /**
   * 보류시작일
   *
   * **data type** varchar(?)
   */
  boryudate: string;

  /**
   * 보류시작일 체크
   *
   * **data type** varchar(?)
   */
  boryu: string;

  /**
   * 보류만료일
   *
   * **data type** varchar(?)
   */
  boryudate2: string;

  /**
   * 보류사유
   *
   * **data type** varchar(?)
   */
  boryusayoo: string;

  /**
   * 청구주기
   *
   * **data type** varchar(?)
   */
  deljugi: string;

  /**
   * 말일체크
   *
   * **data type** varchar(?)
   */
  evernight: string;

  /**
   * 비고(세금계산서)
   *
   * **data type** varchar(?)
   */
  bigo: string;

  /**
   * 적요(매출품목적요)
   *
   * **data type** varchar(?)
   */
  remark: string;

  /**
   * 데이터가 없는 경우 (계약등록 안됨)
   *
   * **data type** varchar(1)
   */
  readonly isEmpty: boolean;

  /**
   * seq
   *
   * **data type** varchar(1)
   */
  readonly seq: string;

  /**
   * 현장코드
   *
   * **data type** varchar(1)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(1)
   */
  readonly actnm: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly mgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly mperiod: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly mdate: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contg: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contname: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly perid: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly pernm: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly sitename: string;

  /**
   * 대수
   *
   * **data type** varchar(1)
   */
  readonly qty: string;

  /**
   * 번호
   *
   * **data type** varchar(1)
   */
  readonly tel: string;

  /**
   * 팩스
   *
   * **data type** varchar(1)
   */
  readonly fax: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly homepage: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly misname: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly zipcode: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly address: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly address2: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contperid: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contpertel: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contperhp: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contpermail: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delchk: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delflag: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly ungijun: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly uneja: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly samt: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contd: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon1: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon2: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon3: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon4: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon5: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon6: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon7: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon8: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon9: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon10: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon11: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly delmon12: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly flag: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly cls: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly clsdate: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly clsremark: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly evmon: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly evju: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly pubgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly pubdate: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly inperid: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly indate: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly accyn: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly pubchk: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly contgubun: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly actcd1: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly cltadres: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  readonly clsnm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.isEmpty = !data.custcd;
    this.custcd = data.custcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.mgubun = data.mgubun || '';
    this.mperiod = data.mperiod || '';
    this.mdate = data.mdate || '';
    this.contg = data.contg || '';
    this.contname = data.contname || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.sitename = data.sitename || '';
    this.qty = data.qty || '';
    this.tel = data.tel || '';
    this.fax = data.fax || '';
    this.homepage = data.homepage || '';
    this.misname = data.misname || '';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.contperid = data.contperid || '';
    this.contperhp = data.contperhp || '';
    this.contpertel = data.contpertel || '';
    this.contpermail = data.contpermail || '';
    this.spjangcd = data.spjangcd || '';
    this.recedate = data.recedate || '';
    this.gubun = data.gubun || '11';
    this.billgubun = data.billgubun || '1';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.taxgubun = data.taxgubun || '01';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.amt = data.amt || '';
    this.totqty = data.totqty || '';
    this.addyn = data.addyn || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.taxflag = data.taxflag || '1';
    this.taxchk = data.taxchk || '1';
    this.dedate = data.dedate || '';
    this.jirogubun = data.jirogubun || '1';
    this.jirochk = data.jirochk || '1';
    this.ungijirodate = data.ungijirodate || '';
    this.boryudate = data.boryudate || '';
    this.boryu = data.boryu || '';
    this.boryudate2 = data.boryudate2 || '';
    this.boryusayoo = data.boryusayoo || '';
    this.uneja = data.uneja || '';
    this.ungijun = data.ungijun || '';
    this.samt = data.samt || '';
    this.contd = data.contd || '';
    this.deljugi = data.deljugi || '01';
    this.delchk = data.delchk || '';
    this.delflag = data.delflag || '';
    this.delmon1 = data.delmon1 || '01';
    this.delmon2 = data.delmon2 || '02';
    this.delmon3 = data.delmon3 || '03';
    this.delmon4 = data.delmon4 || '04';
    this.delmon5 = data.delmon5 || '05';
    this.delmon6 = data.delmon6 || '06';
    this.delmon7 = data.delmon7 || '07';
    this.delmon8 = data.delmon8 || '08';
    this.delmon9 = data.delmon9 || '09';
    this.delmon10 = data.delmon10 || '10';
    this.delmon11 = data.delmon11 || '11';
    this.delmon12 = data.delmon12 || '12';
    this.evernight = data.evernight || '1';
    this.bigo = data.bigo || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';
    this.flag = data.flag || '';
    this.cls = data.cls || '';
    this.clsdate = data.clsdate || '';
    this.clsremark = data.clsremark || '';
    this.evmon = data.evmon || '';
    this.evju = data.evju || '';
    this.pubgubun = data.pubgubun || '';
    this.pubdate = data.pubdate || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.accyn = data.accyn || '';
    this.pubchk = data.pubchk || '';
    this.contgubun = data.contgubun || '';
    this.actcd1 = data.actcd1 || '';
    this.cltadres = data.cltadres || '';
    this.clsnm = data.clsnm || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
