import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca640_pa105_01w
 * @category 월유류비내역
 */
export default class MonthOilModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 차량번호
   *
   * **data type** varchar(20)
   */
  carnum: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 인사코드
   *
   * **data type** varchar(13)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(100)
   */
  pernm: string;

  /**
   * 근태구분
   *
   * **data type** varchar(3)
   */
  rtclafi: string;

  /**
   * 5개월전
   *
   * **data type** varchar(6)
   */
  bemon05: string;

  /**
   * 4개월전
   *
   * **data type** varchar(6)
   */
  bemon04: string;

  /**
   * 3개월전
   *
   * **data type** varchar(6)
   */
  bemon03: string;

  /**
   * 2개월전
   *
   * **data type** varchar(6)
   */
  bemon02: string;

  /**
   * 1개월전
   *
   * **data type** varchar(6)
   */
  bemon01: string;

  /**
   * 해당월
   *
   * **data type** varchar(6)
   */
  mon: string;

  /**
   * 5개월전금액
   *
   * **data type** number
   */
  besamt05: string;

  /**
   * 4개월전금액
   *
   * **data type** number
   */
  besamt04: string;

  /**
   * 3개월전금액
   *
   * **data type** number
   */
  besamt03: string;

  /**
   * 2개월전금액
   *
   * **data type** number
   */
  besamt02: string;

  /**
   * 1개월전금액
   *
   * **data type** number
   */
  besamt01: string;

  /**
   * 해당월금액
   *
   * **data type** number
   */
  samt: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  gubun: string;

  /**
   * 부가세
   *
   * **data type** float
   */
  addamt: string;

  /**
   * 입력자
   *
   * **data type** varchar(100)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 순번
   *
   * **data type** varchar(5)
   */
  seq: string;

  /**
   * 평균
   *
   * **data type** number
   */
  aver: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.carnum = data.carnum || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.aver = data.aver || '';
    this.pernm = data.pernm || '';
    this.rtclafi = data.rtclafi || '';
    this.bemon05 = data.bemon05 || '';
    this.bemon04 = data.bemon04 || '';
    this.bemon03 = data.bemon03 || '';
    this.bemon02 = data.bemon02 || '';
    this.bemon01 = data.bemon01 || '';
    this.mon = data.mon || '';
    this.besamt05 = data.besamt05 || '';
    this.besamt04 = data.besamt04 || '';
    this.besamt03 = data.besamt03 || '';
    this.besamt02 = data.besamt02 || '';
    this.besamt01 = data.besamt01 || '';
    this.samt = data.samt || '';
    this.custcd = data.custcd || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.remark = data.remark || '';
    this.gubun = data.gubun || '';
    this.addamt = data.addamt || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.seq = data.seq || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
