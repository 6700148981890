import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TabLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { PaySalesTab } from './PaySales.tab';
import { GridHeader } from './headers/GridHeader';
import { PaySales } from './PaySales';
import { Format } from '../../../../utils/string';

const contgubuns = [
  { value: '01', remark: 'FM계약' },
  { value: '02', remark: '다년계약' },
  { value: '03', remark: '일반계약' },
  { value: '04', remark: '상주' },
  { value: '05', remark: '외주' },
];

const contg_ings = [
  { value: '0', remark: '미계약' },
  { value: '1', remark: '신규' },
  { value: '2', remark: '해지' },
  { value: '3', remark: '계약중' },
];

/**
 * 화면
 * @window w_tb_e101w_06
 * @category 보수수주매출통계
 */
export const PaySalesTemplate: React.FC<TemplateProps<PaySales>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>년도</span>
          <DateTextBox
            value={scope.state.year}
            textAlign="center"
            format="####"
            onChange={(value) => scope.setState({ year: value })}
            onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
            head={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() - 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(`${scope.state.year}-01-01`);
                  date.setFullYear(date.getFullYear() + 1);
                  scope.setState({ year: date.getFullYear().toString() },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onRefreshEvent()}>
          <div style={{ width: 90 }}>새로고침</div>
        </Button>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout>
          <GridLayout
            header={GridHeader(scope)}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            onRowClick={(row, index) => scope.onRowFocusEvent(row, index)}
            onRowDoubleClick={() => scope.textModal(true, '1')}
          />
        </FlexLayout>

        <FlexLayout>
          <TabLayout
            scope={scope}
            tabs={PaySalesTab}
            onChange={(item, index) => scope.onTabChange(item, index)}
          />
        </FlexLayout>
      </FlexLayout>

      <ModalView
        width={1200}
        height={600}
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.textModal(false, '')}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>보수수주매출통계</LayoutTitle>

          <TableLayout
            ref={scope.modalTable}
            header={[
              {
                id: 'actcd',
                text: '코드',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.actcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_combine',
                text: '통합발행',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.tax_combine === '1' ? { color: 'var(--color-red' } : { color: 'var(--color-black)' }}
                  >
                    {x.tax_combine === '1' ? '통합' : '일반'}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg_ing',
                text: '계약상태',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.contg_ing === '0' ? 'var(--color-green)'
                        : x.contg_ing === '1' ? 'var(--color-blue)'
                          : x.contg_ing === '2' ? 'var(--color-red)'
                            : '',
                    }}
                  >
                    {contg_ings.filter((y) => y.value === x.contg_ing)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'qty',
                text: '대수',
                width: 70,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Format.number(scope.state.modal_qty_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.qty}
                  </FlexLayout>
                ),
              },
              {
                id: 'contgubun',
                text: '계약구분',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.contgubun === '01' ? 'var(--color-blue)'
                        : x.contgubun === '02' ? '#7F7A2C'
                          : '',
                    }}
                  >
                    {contgubuns.filter((y) => y.value === x.contgubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'delgugi',
                text: '청구주기',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.deljugis?.filter((y) => y.com_code === x.deljugi)[0]?.com_cnam}
                  </FlexLayout>
                ),
              },
              {
                id: 'mgubun',
                text: '유무상',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.mgubun === '0' ? { color: 'var(--color-red)' } : { color: 'var(--color-black)' }}
                  >
                    {x.mgubun === '0' ? '무상' : '유상'}
                  </FlexLayout>
                ),
              },
              {
                id: 'contamt',
                text: '수주금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(scope.state.modal_contamt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.contamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'jamt',
                text: (
                  <FlexLayout
                    align="start"
                    justify="center"
                    isVertical={true}
                  >
                    <FlexLayout>
                      잔액
                    </FlexLayout>

                    <FlexLayout>
                      (수주-입금)
                    </FlexLayout>
                  </FlexLayout>
                ),
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.modal_jamt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.jamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'notmisamt',
                text: '미매출',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(scope.state.modal_notmisamt_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.notmisamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt2',
                text: '매출',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(scope.state.modal_misamt2_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rcvamt2',
                text: '입금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(scope.state.modal_rcvamt2_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.rcvamt2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt2',
                text: '미수금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(scope.state.modal_iamt2_tot)}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                    {Format.number(x.iamt2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'notmis_okflag',
                text: '미매출확인',
                width: 100,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.notmis_okflag}
                    data={[
                      { value: '1', remark: '확인' },
                      { value: '0', remark: '미확인' },
                    ].map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => rowUpdate({ notmis_okflag: option.value })}
                    isDisabledTrackingStateChange={true}
                    style={{ color: x.notmis_okflag === '1' ? 'blue' : x.notmis_okflag === '0' ? 'red' : 'black' }}
                    textAlign="center"
                  />
                ),
              },
              {
                id: 'notmis_remark',
                text: '미매출사유',
                width: 400,
                render: (x, rowUpdate) => (
                  <TextBox
                    value={x.notmis_remark}
                    onChange={(value) => rowUpdate({ notmis_remark: value })}
                    onBlur={(value) => {
                      setTimeout(() => scope.modalItemChanged(rowUpdate, x, value, 'notmis_remark'), 3000);
                    }}
                  />
                ),
              },
              {
                id: 'notmis_pernm',
                text: '작성자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    align="center"
                    justify="center"
                  >
                    {x.notmis_pernm}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.modalData}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.popupUpdateRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.popupRowFocusEvent(item)}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onModalExcel()}>EXCEL 자료</Button>
              <Button onClick={() => scope.textModal(false, '')}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
