/**
 * 모델
 * @window w_popup_tb_e601w_sulchi_09
 * @category 설치계약달성현황
 */
export class BusiPopupModel {
  /**
   * 작성일자
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   * 번호
   *
   * **data type** varchar(8)
   */
  readonly plannum: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly state: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly pernm: string;

  /**
   * 방문처리일자
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 최초접수일자
   *
   * **data type** varchar(8)
   */
  readonly recedate: string;

  /**
   * 작성자
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 상담자
   *
   * **data type** varchar(8)
   */
  readonly advicepernm: string;

  /**
   * 상담내용
   *
   * **data type** varchar(8)
   */
  readonly adviceremark: string;

  /**
   * 협의내용 및 조치내용
   *
   * **data type** varchar(8)
   */
  readonly resultremark: string;

  /**
   * 기타내용 및 승강기상태
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  constructor(data: any = {}) {
    this.plandate = data.plandate || '';
    this.plannum = data.plannum || '';
    this.state = data.state || '';
    this.actnm = data.actnm || '';
    this.pernm = data.pernm || '';
    this.compdate = data.compdate || '';
    this.recedate = data.recedate || '';
    this.recedate = data.recedate || '';
    this.inpernm = data.inpernm || '';
    this.advicepernm = data.advicepernm || '';
    this.adviceremark = data.adviceremark || '';
    this.resultremark = data.resultremark || '';
    this.bigo = data.bigo || '';
  }
}

