import * as React from 'react';
import { observer } from 'mobx-react';
import style from './HeaderTools.module.scss';
import { UserModel } from '../../../models';
import { ToolStore } from '../../../stores';
import { JoinClassName } from '../../../utils/string';

interface HeaderTopActions {
  // editTodo: (id: number, data: Partial<HeaderTopModel>) => any;
  // deleteTodo: (id: number) => any;
}

interface HeaderToolsProps extends HeaderTopActions {
  user: UserModel;
  toolStore: ToolStore;
}

@observer
export class HeaderTools extends React.Component<HeaderToolsProps> {
  render() {
    const { user, toolStore } = this.props;

    const contrast = user.contrast === '1';
    let rgb = '6F6F6F';
    let rev = 'reverse_off';

    switch (user.theme) {
      case 'blue':
        rev = 'reverse_on';
        rgb = contrast ? '3C6A9B' : '4D5C6C';
        break;
      case 'brown':
        rev = 'reverse_off';
        rgb = contrast ? '36342D' : '49433D';
        break;
      case 'pink':
        rev = 'reverse_off';
        rgb = contrast ? 'D266B3' : '6C4D5B';
        break;
      case 'gray':
        rev = 'reverse_off';
        rgb = contrast ? '111111' : '6F6F6F';
        break;
    }

    const imgLogo = (
      <img
        src={`https://api.elmansoft.com/etc/getCustImageLongInv.php?custcd=${user?.custcd}&spjangcd=${user?.spjangcd}&rgb=${rgb}&rev=${rev}`}
        alt="Logo"
        height="100%"
        style={{
          marginRight: 8,
        }}
      />
    );

    const userImage: object = (
      <img
        alt={user.pernm}
        src={`https://api.elmansoft.com/bbs/getProfileImage_v2.php?database=${user.custcd}&spjangcd=${user.spjangcd}&perid=${user.perid}`}
        height="100%"
      />
    );

    const buttons: object[] = [];

    toolStore.headerTools.forEach((x) => buttons.push(
        <button
          key={x.id}
          className={JoinClassName.make([
            x.id === 'CLOSE' ? style.red : '',
            x.id === 'DELETE' ? style.bgRed : '',
          ])}
          onClick={() => x.onExecute && x.onExecute()}
        >
          <x.icon />
          <p>{x.text}</p>
          {x.badge && <div className={style.badge}>{x.badge}</div>}
        </button>,
    ));

    return (
      <div className={style.container}>
        <div
          id="header-tool-loader"
          className={style.preLoader}
        />
        <div className={style.user}>
          <div className={style.logo}>
            {imgLogo}
            {userImage}
          </div>
          <p>
            <b>{user.pernm}</b>
          </p>
        </div>
        <div className={style.tools}>{buttons}</div>
      </div>
    );
  }
}
