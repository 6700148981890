import { FiArrowRightCircle } from 'react-icons/all';
import { TLHF } from '../../../../../constants';
import {
  Button, ComboBox, ComboBoxModel, FlexLayout, TextBox,
} from '../../../../../components';
import { Regist } from '../Regist';
import { Format } from '../../../../../utils/string';


const ComboProcess = [
  { key: '0', value: '대기', color: 'var(--color-red)' },
  { key: '1', value: '진행중', color: 'inherit' },
  { key: '2', value: '완료', color: 'var(--color-blue)' },
];


/**
 * 화면 > 상세 > 탭 - 공정현황 > 테이블
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabTableProcessHeader: TLHF<any, Regist> = (scope) => ([
  {
    id: 'equpcd',
    text: '코드',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpcd}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 150,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.equpnm}
        onChange={(v) => rowUpdate({ equpnm: v })}
      />
    ),
  },
  {
    id: 'jeoban',
    text: '제어반',
    width: 70,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.jeoban}
        data={[
          { value: '01', remark: 'MR' },
          { value: '02', remark: 'MRL' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ jeoban: option.value })}
      />
    ),
  },
  {
    id: 'sizecd',
    text: '사양',
    width: 250,
    render: (x, rowUpdate, refs) => (
      <TextBox
        refs={refs}
        value={x.sizecd}
        onChange={(v) => rowUpdate({ sizecd: v })}
      />
    ),
  },
  {
    id: 'file',
    text: '도면',
    width: 50,
    render: () => (
      <Button
        isFull={true}
        isIcon={true}
        onClick={() => scope.setState({ isVisibleProcessDrawing: true })}
      >
        <FiArrowRightCircle size={17} />
      </Button>
    ),
  },
  {
    id: 'tot',
    text: '전체',
    width: 80,
    render: (_, rowUpdate) => (
      <ComboBox
        textAlign="center"
        value=""
        data={[{ key: '', value: '' }, ...ComboProcess].map((y) => new ComboBoxModel(y.key, y.value))}
        onSelect={(option) => {
          rowUpdate({
            process001: option.value,
            process002: option.value,
            process003: option.value,
            process004: option.value,
            process005: option.value,
            process006: option.value,
            process007: option.value,
            process008: option.value,
            process009: option.value,
            process010: option.value,
            process011: option.value,
            process012: option.value,
            process013: option.value,
            process014: option.value,
            process015: option.value,
            process016: option.value,
            process017: option.value,
          });
        }}
      />
    ),
  },
  {
    id: 'process001',
    text: '형판작업',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process001);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process001}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process001: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process002',
    text: '레일양중',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process002);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process002}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process002: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process003',
    text: '기계',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process003);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process003}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process003: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process004',
    text: '저속',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process004);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process004}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process004: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process005',
    text: '1단',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process005);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process005}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process005: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process006',
    text: '체대',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process006);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process006}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process006: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process007',
    text: '로핑',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process007);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process007}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process007: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process008',
    text: '레일작업',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process008);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process008}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process008: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process009',
    text: '실',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process009);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process009}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process009: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process010',
    text: '삼방틀',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process010);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process010}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process010: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process011',
    text: '헷다',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process011);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process011}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process011: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process012',
    text: '도어',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process012);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process012}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process012: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process013',
    text: '배선',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process013);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process013}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process013: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process014',
    text: '판넬',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process014);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process014}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process014: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process015',
    text: '준비',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process015);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process015}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process015: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process016',
    text: '고속',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process016);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process016}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process016: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process017',
    text: '검사',
    width: 80,
    render: (x, rowUpdate) => {
      const fp = ComboProcess.filter((y) => y.key === x.process017);
      const color = fp.length > 0 ? fp[0].color : 'inherit';

      return (
        <ComboBox
          textAlign="center"
          value={x.process017}
          data={ComboProcess.map((y) => new ComboBoxModel(y.key, y.value))}
          onSelect={(option) => rowUpdate({ process017: option.value })}
          style={{ color }}
        />
      );
    },
  },
  {
    id: 'process_per',
    text: '진행률',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
        style={{
          color: 'var(--color-violet)',
        }}
      >
        <b>{x.process_per}%</b>
      </FlexLayout>
    ),
  },
  {
    id: 'ex_mijamt',
    text: '예상지출금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {Format.number(x.ex_mijamt)}
      </FlexLayout>
    ),
  },
]);
