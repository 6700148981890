/**
 * 모델
 * @window w_p2120
 * @category 인사발령등록
 */
import { computed } from 'mobx';

export class AppointModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사업장이름
   *
   * **data type** 모름
   */
  spjangnm: string;

  /**
   * 퇴사
   *
   * **data type** varchar(3)
   */
  rtclafi: string;

  /**
   * 일자
   *
   * **data type** varchar(8)
   */
  prodate: string;

  /**
   * 사원번호
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 사원명
   *
   * **data type** varchar(10)
   */
  pernm: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  rnum: string;

  /**
   * 직위(직급)
   *
   * **data type** char(2)
   */
  rspcd: string;

  /**
   * 직위(직급)명
   *
   * **data type** char(2)
   */
  rspnm: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  payiden: string;

  /**
   * 모름
   *
   * **data type** varchar(1)
   */
  mpclafi: string;

  /**
   * 모름
   *
   * **data type** 모름
   */
  clacd: string;

  /**
   * 모름
   *
   * **data type** 모름
   */
  stpcd: string;

  /**
   * 모름
   *
   * **data type** 모름
   */
  modcode: string;

  /**
   * 모름
   *
   * **data type** 모름
   */
  handphone: string;

  /**
   * 발령구분
   *
   * **data type** varchar(2)
   */
  ordercd: string;

  /**
   * 발령구분(이름)
   *
   * **data type** ?
   */
  ordernm: string;

  /**
   * 사유
   *
   * **data type** varchar(50)
   */
  ordertxt: string;

  /**
   * 발령전
   *
   * **data type** ???
   */
  frombal: string;

  /**
   * 발령후
   *
   * **data type** ???
   */
  tobal: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.prodate = data.prodate || '';
    this.rtclafi = data.rtclafi || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.rnum = data.rnum || '';
    this.rspcd = data.rspcd || '';
    this.rspnm = data.rspnm || '';
    this.payiden = data.payiden || '';
    this.mpclafi = data.mpclafi || '';
    this.clacd = data.clacd || '';
    this.stpcd = data.stpcd || '';
    this.modcode = data.modcode || '';
    this.handphone = data.handphone || '';
    this.ordercd = data.ordercd || '';
    this.ordernm = data.ordernm || '';
    this.ordertxt = data.ordertxt || '';
    this.frombal = data.frombal || '';
    this.tobal = data.tobal || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
