import * as React from 'react';
import { action } from 'mobx';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import {
  ReportModel,
  ResisterDetailModel,
  ResisterModel,
} from './models';
import {
  AskType,
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ResisterTemplate } from './Resister.template';
import {
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

// import { ConfirmWarning } from '../../../../utils/confirm';

export enum ResisterItemChangeTypes {
  RECEDATE,
  OKFLAG,
  OKFLAG_COMP,
  ACTCD,
  CIVILCD,
  PERID,
}

export enum ResisterItemChangeTypeNames {
  recedate,
  okflag,
  okflag_comp,
  actcd,
  civilcd,
  perid,
}

interface ResisterState {

  // 검색조건
  stdate: string; // 기간
  enddate: string; // 기간
  searchQuery: string; // 검색어
  perid: string; // 사용자 아이디
  resultck: string; // 결과

  // 데이터 객체

  // 포커싱 데이터
  focusedResister?: ResisterModel;
  focusedDetailResister: ResisterDetailModel;
  lastSaveData: ResisterDetailModel;

  // 하단 테이블 데이터
  data: ResisterDetailModel;

  // 테이블 데이터 리스트
  ResisterList: Array<ResisterModel>;
  ReportList: Array<ReportModel>;

  // 구분종류
  wkcds: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부
}

/*
* 컨트롤러
* @window w_tb_e301
* @category   민원접수등록
*/

export class Resister extends PageComponent<PageProps, ResisterState>
  implements PageToolEvents {
  updatedRows?: Array<ResisterModel>;

  updatedRows2?: Array<ReportModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: pageParams.stdate || `${year}${month}01`,
      enddate: pageParams.enddate || Today.date(),
      searchQuery: '',
      perid: '%',
      resultck: pageParams.resultck || '%',
      data: new ResisterDetailModel(),
      ResisterList: [],
      ReportList: [],

      wkcds: [],
      isReported: false,
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        stdate: pageParams.stdate || this.state.stdate,
        enddate: pageParams.enddate || this.state.enddate,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_e021');
    data && this.setState({ wkcds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: '%',
        resultck: this.state.resultck,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          ResisterList: [...this.state.ResisterList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          ResisterList: [],
          ReportList: [],
        });
        await this.infinity?.retrieveAll();
        this.state.ResisterList.length > 0
        && await this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.focusedDetailResister;
    this.setState({
      ResisterList: [],
      ReportList: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['recedate', 'recenum'],
        [lastSelected?.recedate, lastSelected?.recenum], type, true) || 0;
      if (!autoLoad) return;
      if (this.state.ResisterList && this.state.ResisterList.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  onRowFocusEvent(item: ResisterDetailModel) {
    if (item?.new === '1') {
      this.setState({
        isReported: false,
        focusedDetailResister: this.state.lastSaveData,
      });
      return;
    }

    const { actionStore: api } = this.props;
    this.setState(
      { focusedDetailResister: item },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged',
          {
            recedate: this.state.focusedDetailResister?.recedate,
            recenum: this.state.focusedDetailResister?.recenum,
            enddate: this.state.enddate,
          });

        await data && this.setState({
          data: new ResisterDetailModel(data),
          focusedDetailResister: new ResisterDetailModel(data),
          ReportList: data.items,
        });

        if (data.items) {
          this.detailGrid.current?.setFocus(0);
        }
      },
    );
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    let data:any = {
      new: undefined,
      items: [],
    };

    const params = {
      itemname: ResisterItemChangeTypeNames[type],
      data: item,
      recedate: this.state.focusedDetailResister?.recedate,
      recenum: this.state.focusedDetailResister?.recenum,
      new: this.state.data.new,
    };

    if (type === ResisterItemChangeTypeNames.recedate) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailResister: new ResisterDetailModel({
          ...this.state.focusedDetailResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === ResisterItemChangeTypeNames.okflag) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          perid: this.state.focusedDetailResister?.perid,
          actcd: this.state.focusedDetailResister?.actcd,
          actnm: this.state.focusedDetailResister?.actnm,
          acttelnum: this.state.focusedDetailResister?.acttelnum,
          recetime: this.state.focusedDetailResister?.recetime,
          civilcd: this.state.focusedDetailResister?.civilcd,
          civilnm: this.state.focusedDetailResister?.civilnm,
          remark: this.state.focusedDetailResister?.remark,
          okflag: this.state.focusedDetailResister?.okflag,
        },
      );

      data && this.setState({
        focusedDetailResister: new ResisterDetailModel({
          ...this.state.focusedDetailResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (this.state.focusedDetailResister?.okflag === '1' && type === ResisterItemChangeTypeNames.okflag) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
          perid: this.state.focusedDetailResister?.perid,
          actcd: this.state.focusedDetailResister?.actcd,
          actnm: this.state.focusedDetailResister?.actnm,
          acttelnum: this.state.focusedDetailResister?.acttelnum,
          recetime: this.state.focusedDetailResister?.recetime,
          civilcd: this.state.focusedDetailResister?.civilcd,
          civilnm: this.state.focusedDetailResister?.civilnm,
          remark: this.state.focusedDetailResister?.remark,
          okflag: '1',
        },
      );

      data && this.setState({
        focusedDetailResister: new ResisterDetailModel({
          ...this.state.focusedDetailResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === ResisterItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailResister: new ResisterDetailModel({
          ...this.state.focusedDetailResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === ResisterItemChangeTypeNames.civilcd) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailResister: new ResisterDetailModel({
          ...this.state.focusedDetailResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }

    if (type === ResisterItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged', {
          ...params,
        },
      );

      data && this.setState({
        focusedDetailResister: new ResisterDetailModel({
          ...this.state.focusedDetailResister, // new = 0
          ...data, // new = 1
        }, data.new === '1'),
      });
    }
  }

  @action
  async onSaveEvent() {
    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.focusedDetailResister?.new,
      custcd: this.state.focusedDetailResister?.custcd,
      spjangcd: this.state.focusedDetailResister?.spjangcd,
      recedate: this.state.focusedDetailResister?.recedate,
      recenum: this.state.focusedDetailResister?.recenum,
      recetime: this.state.focusedDetailResister?.recetime,
      actcd: this.state.focusedDetailResister?.actcd,
      actnm: this.state.focusedDetailResister?.actnm,
      acttelnum: this.state.focusedDetailResister?.acttelnum,
      perid: this.state.focusedDetailResister?.perid,
      pernm: this.state.focusedDetailResister?.pernm,
      divicd: this.state.focusedDetailResister?.divicd,
      divinm: this.state.focusedDetailResister?.divinm,
      reperid: this.state.focusedDetailResister?.reperid,
      repernm: this.state.focusedDetailResister?.repernm,
      actperid: this.state.focusedDetailResister?.actperid,
      actpernm: this.state.focusedDetailResister?.actpernm,
      civilcd: this.state.focusedDetailResister?.civilcd,
      civilnm: this.state.focusedDetailResister?.civilnm,
      civilflag: this.state.focusedDetailResister?.civilflag,
      remark: this.state.focusedDetailResister?.remark,
      okflag: this.state.focusedDetailResister?.okflag,
    }, this.state.data.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.focusedDetailResister?.recedate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        focusedDetailResister: new ResisterDetailModel(),
      });
      await this.onRetrieveEvent();
    }
  }


  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new ResisterModel(data, true);

      this.setState({
        data: newModel,
        lastSaveData: newModel,
        ResisterList: [
          newModel,
          ...this.state.ResisterList,
        ],
        focusedResister: newModel,
        focusedDetailResister: newModel,
        ReportList: [],
      }, async () => {
        await this.grid.current?.setFocus(0);
        await this.detailGrid.current?.setFocus(0);
      });
    }
  }

  @action
  async onClickSMS() {
    if (!ConfirmWarning.assert(this.state?.focusedDetailResister, '확인', '문자전송할 고장내용이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.focusedDetailResister?.acttelnum, '확인', '고객에게 보낼 핸드폰번호를 입력하세요')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state?.focusedDetailResister?.civilcd, '확인', '민원내용을 입력하세요!')) {
      return;
    }

    const result = await Confirm.ask(
      '문자 전송',
      `${this.state.focusedDetailResister?.acttelnum} 번호로 조치내용을 전송하시겠습니까?`,
      '보내기',
      '취소',
    );

    if (result === AskType.YES) {
      const { actionStore: api } = this.props;
      ConfirmSuccess.showPbMessage(await api.fxExec('wb_sms', {
        recedate: this.state.focusedDetailResister?.recedate,
        recenum: this.state.focusedDetailResister?.recenum,
        perid: this.state.focusedDetailResister?.perid,
        actcd: this.state.focusedDetailResister?.actcd,
        actnm: this.state.focusedDetailResister?.actnm,
        civilcd: this.state.focusedDetailResister?.civilcd,
        civilnm: this.state.focusedDetailResister?.civilnm,
        recetime: this.state.focusedDetailResister?.recetime,
      }));
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line max-len
    const text = `접수일자: ${this.state.data?.recedate} 번호: ${this.state.data?.recenum}를 삭제하시겠습니까?`;
    await api.delete(text, {
      recedate: this.state.data?.recedate,
      recenum: this.state.data?.recenum,
    }) && await this.onRetrieveEvent();
  }

  @action
  // eslint-disable-next-line no-empty-function
  async onOkFlagEvent() {
    const result = await Confirm.ask(
      '문자 전송',
      `${this.state.focusedDetailResister?.acttelnum} 번호로 조치내용을 전송하시겠습니까?`,
      '보내기',
      '취소',
    );
    if (result === AskType.YES) {
      const { actionStore: api } = this.props;
      ConfirmSuccess.showPbMessage(await api.fxExec('wb_sms', {
        recedate: this.state.focusedDetailResister?.recedate,
        recenum: this.state.focusedDetailResister?.recenum,
        perid: this.state.focusedDetailResister?.perid,
        actcd: this.state.focusedDetailResister?.actcd,
        actnm: this.state.focusedDetailResister?.actnm,
        civilcd: this.state.focusedDetailResister?.civilcd,
        civilnm: this.state.focusedDetailResister?.civilnm,
        recetime: this.state.focusedDetailResister?.recetime,
      }));
    }
  }


  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  onUpdatedRows(rows: Array<ResisterModel>, updatedRows: Array<ResisterModel>) {
    this.updatedRows = updatedRows;
    this.setState({ ResisterList: rows });
  }

  @action
  onUpdatedRows2(rows: Array<ReportModel>, updatedRows: Array<ReportModel>) {
    this.updatedRows2 = updatedRows;
    this.setState({ ReportList: rows });
  }

  render() {
    return (
      <ResisterTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            focusedDetailResister: {
              ...this.state.focusedDetailResister,
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
