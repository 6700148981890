import * as React from 'react';
import { observer } from 'mobx-react';
import {
  AiOutlineClose,
  AiOutlineSave,
  AiOutlineUserAdd,
  AiOutlineUserDelete,
} from 'react-icons/ai';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { LayoutTitle } from '../../components/forms/LayoutTitle';
import { Global } from '../../constants';
import { Button } from '../../components/forms/Button';
import { DragAndDropLayout } from '../../components/layout/DragAndDropLayout';
import { SearchBinding } from '../../models/common';
import { TextBox } from '../../components/forms/TextBox';
import { ComboBox, ComboBoxModel } from '../../components/forms/ComboBox';

interface ModalApprovalProps {
  modalStore: ModalStore;
}

@observer
export class ModalApproval extends React.Component<ModalApprovalProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={600}
        height={500}
        isVisible={modalStore.isVisibleApproval}
        onClose={async () => (await modalStore.approvalLineSave()) && modalStore.closeApprovalLine(false)}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <LayoutTitle weight={1}>
              결재 라인 설정
            </LayoutTitle>

            <Button onClick={() => modalStore.approvalLineNew()}>
              <AiOutlineUserAdd style={{ marginLeft: 2 }} />
              <span>신규</span>
            </Button>

            <Button onClick={() => modalStore.approvalLineSave()}>
              <AiOutlineSave style={{ marginLeft: 2 }} />
              <span>저장</span>
            </Button>

            <Button onClick={() => modalStore.approvalLineDelete()}>
              <AiOutlineUserDelete style={{ marginLeft: 2 }} />
              <span>삭제</span>
            </Button>

            <Button
              onClick={ async () => {
                if (await modalStore.approvalLineSave()) {
                  modalStore.closeApprovalLine(true);
                }
              }}
            >
              <AiOutlineClose style={{ marginLeft: 2 }} />
              <span>닫기</span>
            </Button>
          </FlexLayout>

          <DragAndDropLayout
            ref={modalStore.approvalGrid}
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 50,
              },
              {
                id: 'kcperid',
                text: '결재자코드',
                width: 80,
                color: 'var(--color-red)',
                render: (x, rowUpdate, refs) => (
                  <TextBox
                    refs={refs}
                    value={x.kcperid}
                    textAlign="center"
                    onChange={(v) => rowUpdate({ kcperid: v })}
                    bindSearchModal={new SearchBinding(
                      modalStore,
                      'TB_JA001',
                      {
                        sub: 'w_popup_e064',
                      },
                      true,
                      (item) => {
                        rowUpdate({ kcperid: item.cd, kcpernm: item.cdnm });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'kcpernm',
                text: '결재자명',
                width: 120,
              },
              {
                id: 'rspnm',
                text: '직급',
                width: 100,
              },
              {
                id: 'gubun',
                text: '구분',
                width: 100,
                render: (x, rowUpdate) => (
                  <ComboBox
                    value={x.gubun}
                    data={modalStore.approvalGubunData.map((y) => (
                      new ComboBoxModel(y.com_code, y.com_cnam)))}
                    onSelect={(option) => rowUpdate({ gubun: option.value })}
                  />
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 250,
                render: (x, rowUpdate, refs) => (
                  <TextBox
                    refs={refs}
                    value={x.remark}
                    onChange={(v) => rowUpdate({ remark: v })}
                  />
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={modalStore.approvalData}
            onRowFocusEvent={(item) => {
              modalStore.approvalSelected = item;
            }}
            onChange={(rows) => {
              modalStore.approvalData = rows;
            }}
          />
        </FlexLayout>
      </ModalView>
    );
  }
}
