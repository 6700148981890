import * as React from 'react';
import { IFrame } from '../../../../components/forms/IFrame';
import { PageProps } from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';

export class Nation extends React.Component<PageProps> {
  componentDidMount(): void {
    this.props.publicStore.setCurrentPage(this);
  }

  render() {
    const url = 'https://www.elevator.go.kr/main/Login.do?menuId=00000000';

    return (
      <FlexLayout
        align="left"
        justify="left">
        <IFrame src={url} />
      </FlexLayout>
    );
  }
}

