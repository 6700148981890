import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e512w_01
 * @category 당직현황
 */
export class DutyModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장명
   *
   * **data type** varchar(40)
   */
  spjangnm: string;

  /**
   * 부서명
   *
   * **data type** char(40)
   */
  divinm: string;

  /**
   * 담당자
   *
   * **data type** char(30)
   */
  pernm: string;

  /**
   * No
   *
   * **data type** number
   */
  no: string;

  /**
   * 당직일
   *
   * **data type** char(2)
   */
  plantot: string;

  /**
   * 당직일2
   *
   * **data type** char(2)
   */
  tot: string;

  /**
   * 통신일
   *
   * **data type** char(2)
   */
  planteltot: string;

  /**
   * 통신일2
   *
   * **data type** char(2)
   */
  teldaytot: string;

  /**
   * 당직(토요일)
   *
   * **data type** long
   */
  plantotweeksat: string;

  /**
   * 당직(토요일)2
   *
   * **data type** char(2)
   */
  totweeksat: string;

  /**
   * 당직(일요일)
   *
   * **data type** long
   */
  plantotweeksun: string;

  /**
   * 당직(일요일)2
   *
   * **data type** char(2)
   */
  totweeksun: string;

  /**
   * 당직(평일)
   *
   * **data type** long
   */
  plantotweek: string;

  /**
   * 당직(평일)2
   *
   * **data type** char(2)
   */
  totweek: string;

  /**
   * 21시간(토요일)
   *
   * **data type** long
   */
  plantot21: string;

  /**
   * 21시간(토요일)2
   *
   * **data type** char(2)
   */
  tot21: string;

  /**
   * 25시간(일요일)
   *
   * **data type** long
   */
  plantot25: string;

  /**
   * 25시간(일요일)2
   *
   * **data type** char(2)
   */
  tot25: string;

  /**
   * 6시간(평일)
   *
   * **data type** long
   */
  plantot6: string;

  /**
   * 6시간(평일)2
   *
   * **data type** char(2)
   */
  tot6: string;

  /**
   * 합계(일수)
   *
   * **data type** long
   */
  totplantot: string;

  /**
   * 합계(일수)2
   *
   * **data type** char(2)
   */
  totcomptot: string;

  /**
   * 합계(시간)
   *
   * **data type** long
   */
  totplantottime: string;

  /**
   * 합계(시간)2
   *
   * **data type** char(2)
   */
  totcomptottime: string;

  /**
   * 1일
   *
   * **data type** char(1)
   */
  date1: string;

  /**
   * 2일
   *
   * **data type** char(1)
   */
  date2: string;

  /**
   * 3일
   *
   * **data type** char(1)
   */
  date3: string;

  /**
   * 4일
   *
   * **data type** char(1)
   */
  date4: string;

  /**
   * 5일
   *
   * **data type** char(1)
   */
  date5: string;

  /**
   * 6일
   *
   * **data type** char(1)
   */
  date6: string;

  /**
   * 7일
   *
   * **data type** char(1)
   */
  date7: string;

  /**
   * 8일
   *
   * **data type** char(1)
   */
  date8: string;

  /**
   * 9일
   *
   * **data type** char(1)
   */
  date9: string;

  /**
   * 10일
   *
   * **data type** char(1)
   */
  date10: string;

  /**
   * 11일
   *
   * **data type** char(1)
   */
  date11: string;

  /**
   * 12일
   *
   * **data type** char(1)
   */
  date12: string;

  /**
   * 13일
   *
   * **data type** char(1)
   */
  date13: string;

  /**
   * 14일
   *
   * **data type** char(1)
   */
  date14: string;

  /**
   * 15일
   *
   * **data type** char(1)
   */
  date15: string;

  /**
   * 16일
   *
   * **data type** char(1)
   */
  date16: string;

  /**
   * 17일
   *
   * **data type** char(1)
   */
  date17: string;

  /**
   * 18일
   *
   * **data type** char(1)
   */
  date18: string;

  /**
   * 19일
   *
   * **data type** char(1)
   */
  date19: string;

  /**
   * 20일
   *
   * **data type** char(1)
   */
  date20: string;

  /**
   * 21일
   *
   * **data type** char(1)
   */
  date21: string;

  /**
   * 22일
   *
   * **data type** char(1)
   */
  date22: string;

  /**
   * 23일
   *
   * **data type** char(1)
   */
  date23: string;

  /**
   * 24일
   *
   * **data type** char(1)
   */
  date24: string;

  /**
   * 25일
   *
   * **data type** char(1)
   */
  date25: string;

  /**
   * 26일
   *
   * **data type** char(1)
   */
  date26: string;

  /**
   * 27일
   *
   * **data type** char(1)
   */
  date27: string;

  /**
   * 28일
   *
   * **data type** char(1)
   */
  date28: string;

  /**
   * 29일
   *
   * **data type** char(1)
   */
  date29: string;

  /**
   * 30일
   *
   * **data type** char(1)
   */
  date30: string;

  /**
   * 31일
   *
   * **data type** char(1)
   */
  date31: string;

  /**
   * 직위
   *
   * **data type** char(20)
   */
  rspnm: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday1: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday2: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday3: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday4: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday5: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday6: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday7: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday8: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday9: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday10: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday11: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday12: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday13: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday14: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday15: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday16: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday17: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday18: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday19: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday20: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday21: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday22: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday23: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday24: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday25: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday26: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday27: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday28: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday29: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday30: string;

  /**
   * 요일
   *
   * **data type** char(1)
   */
  weekday31: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.pernm = data.pernm || '';
    this.divinm = data.divinm || '';
    this.weekday1 = data.weekday1 || '';
    this.weekday2 = data.weekday2 || '';
    this.weekday3 = data.weekday3 || '';
    this.weekday4 = data.weekday4 || '';
    this.weekday5 = data.weekday5 || '';
    this.weekday6 = data.weekday6 || '';
    this.weekday7 = data.weekday7 || '';
    this.weekday8 = data.weekday8 || '';
    this.weekday9 = data.weekday9 || '';
    this.weekday10 = data.weekday10 || '';
    this.weekday11 = data.weekday11 || '';
    this.weekday12 = data.weekday12 || '';
    this.weekday13 = data.weekday13 || '';
    this.weekday14 = data.weekday14 || '';
    this.weekday15 = data.weekday15 || '';
    this.weekday16 = data.weekday16 || '';
    this.weekday17 = data.weekday17 || '';
    this.weekday18 = data.weekday18 || '';
    this.weekday19 = data.weekday19 || '';
    this.weekday20 = data.weekday20 || '';
    this.weekday21 = data.weekday21 || '';
    this.weekday22 = data.weekday22 || '';
    this.weekday23 = data.weekday23 || '';
    this.weekday24 = data.weekday24 || '';
    this.weekday25 = data.weekday25 || '';
    this.weekday26 = data.weekday26 || '';
    this.weekday27 = data.weekday27 || '';
    this.weekday28 = data.weekday28 || '';
    this.weekday29 = data.weekday29 || '';
    this.weekday30 = data.weekday30 || '';
    this.weekday31 = data.weekday31 || '';
    this.rspnm = data.rspnm || '';
    this.plantot = data.plantot || '';
    this.tot = data.tot || '';
    this.planteltot = data.planteltot || '';
    this.teldaytot = data.teldaytot || '';
    this.plantotweeksat = data.plantotweeksat || '';
    this.totweeksat = data.totweeksat || '';
    this.plantotweeksun = data.plantotweeksun || '';
    this.plantotweek = data.plantotweek || '';
    this.totweek = data.totweek || '';
    this.plantot21 = data.plantot21 || '';
    this.spjangnm = data.spjangnm || '';
    this.tot21 = data.tot21 || '';
    this.plantot25 = data.plantot25 || '';
    this.tot25 = data.tot25 || '';
    this.plantot6 = data.plantot6 || '';
    this.tot6 = data.tot6 || '';
    this.totplantot = data.totplantot || '';
    this.totcomptot = data.totcomptot || '';
    this.totplantottime = data.totplantottime || '';
    this.totcomptottime = data.totcomptottime || '';
    this.date1 = data.date1 || '';
    this.date2 = data.date2 || '';
    this.date3 = data.date3 || '';
    this.date4 = data.date4 || '';
    this.date5 = data.date5 || '';
    this.date6 = data.date6 || '';
    this.date7 = data.date7 || '';
    this.date8 = data.date8 || '';
    this.date9 = data.date9 || '';
    this.date10 = data.date10 || '';
    this.date11 = data.date11 || '';
    this.date12 = data.date12 || '';
    this.date13 = data.date13 || '';
    this.date14 = data.date14 || '';
    this.date15 = data.date15 || '';
    this.date16 = data.date16 || '';
    this.date17 = data.date17 || '';
    this.date18 = data.date18 || '';
    this.date19 = data.date19 || '';
    this.date20 = data.date20 || '';
    this.date21 = data.date21 || '';
    this.date22 = data.date22 || '';
    this.date23 = data.date23 || '';
    this.date24 = data.date24 || '';
    this.date25 = data.date25 || '';
    this.date26 = data.date26 || '';
    this.date27 = data.date27 || '';
    this.date28 = data.date28 || '';
    this.date29 = data.date29 || '';
    this.date30 = data.date30 || '';
    this.date31 = data.date31 || '';
    this.totweeksun = data.totweeksun || '';
    this.no = data.no || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
