import * as React from 'react';
import { inject, observer } from 'mobx-react';
import style from './Loading.module.scss';
import { Date8, Time6 } from '../../../utils/time';
import { PublicStore } from '../../../stores';
import { JoinClassName } from '../../../utils/string';

interface LoadingProps {
  remarks?: string;
  publicStore?: PublicStore;
}

interface LoadingState {
  date: string;
  time: string;
}

@inject('publicStore') @observer
export class Loading extends React.Component<LoadingProps, LoadingState> {
  timer?: NodeJS.Timeout;

  constructor(props: LoadingProps, context?: any) {
    super(props, context);
    this.state = {
      date: Date8.withKor(),
      time: Time6.withDash(),
    };
  }

  componentDidMount(): void {
    this.timer = setTimeout(() => this.tick(), 1000);
  }

  componentWillUnmount(): void {
    this.timer && clearTimeout(this.timer);
    this.timer = undefined;
  }

  tick() {
    this.setState(
      {
        date: Date8.withKor(),
        time: Time6.withDash(),
      },
      () => {
        if (this.timer) {
          this.timer = setTimeout(() => this.tick(), 1000);
        }
      },
    );
  }

  render() {
    const { publicStore } = this.props;

    return (
      <div
        id="loading-screen"
        className={JoinClassName.make([
          style.container,
          publicStore!.isPreLoading ? style.preActive : false,
          publicStore!.isLoading ? style.active : false,
        ])}
      >
        <div>
          <svg
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <rect
              fill="#e15b64"
              x="55"
              y="55"
              width="30"
              height="30"
              rx="3"
              ry="3"
            >
              <animate
                attributeName="x"
                dur="2s"
                repeatCount="indefinite"
                keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
                values="15;55;55;55;55;15;15;15;15"
                begin="-1.8333333333333333s"
              ></animate>
              <animate
                attributeName="y"
                dur="2s"
                repeatCount="indefinite"
                keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
                values="15;55;55;55;55;15;15;15;15"
                begin="-1.3333333333333333s"
              ></animate>
            </rect>
            <rect
              fill="#f8b26a"
              x="55"
              y="15"
              width="30"
              height="30"
              rx="3"
              ry="3"
            >
              <animate
                attributeName="x"
                dur="2s"
                repeatCount="indefinite"
                keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
                values="15;55;55;55;55;15;15;15;15"
                begin="-1.1666666666666667s"
              ></animate>
              <animate
                attributeName="y"
                dur="2s"
                repeatCount="indefinite"
                keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
                values="15;55;55;55;55;15;15;15;15"
                begin="-0.6666666666666666s"
              ></animate>
            </rect>
            <rect
              fill="#abbd81"
              x="15"
              y="49.6728"
              width="30"
              height="30"
              rx="3"
              ry="3"
            >
              <animate
                attributeName="x"
                dur="2s"
                repeatCount="indefinite"
                keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
                values="15;55;55;55;55;15;15;15;15"
                begin="-0.5s"
              ></animate>
              <animate
                attributeName="y"
                dur="2s"
                repeatCount="indefinite"
                keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
                values="15;55;55;55;55;15;15;15;15"
                begin="0s"
              ></animate>
            </rect>
          </svg>
        </div>
        {this.props.remarks && (
          <div className={style.remarks}>
            <p>{this.props.remarks}</p>
          </div>
        )}
        <div className={style.info}>
          <p>{this.state.date}</p>
          <p>{this.state.time}</p>
        </div>
      </div>
    );
  }
}
