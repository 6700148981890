/**
 * 모델
 * @window w_tb_e411_01
 * @category 고장처리결과등록
 */
import { computed } from 'mobx';

export class BreakCompleteDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 전분류
   *
   * **data type** varchar(2)
   */
  contg: string;

  /**
   * 처리자
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 처리자2
   *
   * **data type** varchar(10)
   */
  perid2: string;

  /**
   * 대수
   *
   * **data type** float
   */
  qty: string;

  /**
   * 전화번호
   *
   * **data type** varchar(20)
   */
  tel: string;

  /**
   * 팩스
   *
   * **data type** varchar(20)
   */
  fax: string;

  /**
   * 팩스번호
   *
   * **data type** varchar(20)
   */
  faxnum: string;

  /**
   * 분류
   *
   * **data type** char(1)
   */
  flag: string;

  /**
   * 홈페이지
   *
   * **data type** varchar(50)
   */
  homepage: string;

  /**
   * 주소
   *
   * **data type** varchar(6)
   */
  zipcode: string;

  /**
   * 주소2
   *
   * **data type** varchar(100)
   */
  address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(100)
   */
  address2: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 금액
   *
   * **data type** float
   */
  samt: string;

  /**
   * 보수료
   *
   * **data type** float
   */
  amt: string;

  /**
   * 대당금액
   *
   * **data type** varchar(?)
   */
  monamt: string;

  /**
   * 비고
   *
   * **data type** varchar(255)
   */
  remark: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * 해지사유
   *
   * **data type** varchar(100)
   */
  clsnm: string;

  /**
   * 해지상세사유
   *
   * **data type** varchar(100)
   */
  clsremark: string;

  /**
   * 비고
   *
   * **data type** text
   */
  bigo: string;

  /**
   * 구분
   *
   * **data type** varchar(3)
   */
  gubun: string;

  /**
   * 계약구분
   *
   * **data type** varchar(10)
   */
  contgubun: string;

  /**
   * 순서
   *
   * **data type** varchar(3)
   */
  seq: string;

  /**
   * 전분류
   *
   * **data type** varchar(5)
   */
  becontg: string;

  /**
   * 해지일
   *
   * **data type** varchar(8)
   */
  clsrecedate: string;

  /**
   * 처리
   *
   * **data type** varchar(1)
   */
  compflag: string;

  /**
   * 처리자부서명
   *
   * **data type** varchar(40)
   */
  divinm: string;

  /**
   * 처리자2부서명
   *
   * **data type** varchar(40)
   */
  divinm2: string;

  /**
   * 처리자
   *
   * **data type** varchar(30)
   */
  pernm: number;

  /**
   * 처리자2
   *
   * **data type** varchar(30)
   */
  pernm2: number;

  /**
   * 현장담당자
   *
   * **data type** varchar(30)
   */
  actpernm: string;

  /**
   * 현장담당자
   *
   * **data type** varchar(30)
   */
  actppernm: string;

  /**
   * 계약자
   *
   * **data type** varchar(30)
   */
  contpernm: number;

  /**
   * 담당전화번호
   *
   * **data type** varchar(30)
   */
  hp: number;

  /**
   * 유무상
   *
   * **data type** varchar(?)
   */
  mgunun: string;

  /**
   * 설치->보수일
   *
   * **data type** varchar(?)
   */
  senddate: string;

  /**
   * 호기코드
   *
   * **data type** varchar(?)
   */
  equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(?)
   */
  equpnm: string;

  /**
   * 고객메일
   *
   * **data type** varchar(?)
   */
  email: string;

  /**
   * 처리자부서코드
   *
   * **data type** varchar(?)
   */
  divicd: string;

  /**
   * 처리자부서코드2
   *
   * **data type** varchar(?)
   */
  divicd2: string;

  /**
   * 접수자
   *
   * **data type** varchar(?)
   */
  repernm: string;

  /**
   * 접수일자
   *
   * **data type** varchar(?)
   */
  recedate: string;

  /**
   * 접수시간
   *
   * **data type** varchar(?)
   */
  recetime: string;

  /**
   * 이전처리일시
   *
   * **data type** varchar(?)
   */
  berecedate: string;

  /**
   * 처리시간
   *
   * **data type** varchar(?)
   */
  berecetime: string;

  /**
   * 완료일자
   *
   * **data type** varchar(?)
   */
  compdate: string;

  /**
   * 완료일자
   *
   * **data type** varchar(?)
   */
  compnum: string;

  /**
   * 완료시간
   *
   * **data type** varchar(?)
   */
  comptime: string;

  /**
   * 도착일자
   *
   * **data type** varchar(?)
   */
  arrivdate: string;

  /**
   * 도착시간
   *
   * **data type** varchar(?)
   */
  arrivtime: string;

  /**
   * 대수
   *
   * **data type** varchar(?)
   */
  cnt: string;

  /**
   * 건수
   *
   * **data type** varchar(?)
   */
  ascnt: string;

  /**
   *  사고일시
   *
   * **data type** varchar(?)
   */
  troubledate: string;

  /**
   *  사고일시
   *
   * **data type** varchar(?)
   */
  troubletime: string;

  /**
   *  사고일시
   *
   * **data type** varchar(?)
   */
  troublesu: string;

  /**
   * 고장내용
   *
   * **data type** varchar(?)
   */
  contnm: string;

  /**
   * 고장내용상세
   *
   * **data type** varchar(?)
   */
  contremark: string;

  /**
   * 처리내용
   *
   * **data type** varchar(?)
   */
  resucd: string;

  /**
   * 처리내용
   *
   * **data type** varchar(?)
   */
  resunm: string;

  /**
   * 처리내용상세
   *
   * **data type** varchar(?)
   */
  resuremark: string;

  /**
   * 고장요인
   *
   * **data type** varchar(?)
   */
  remocd: string;

  /**
   * 고장요인
   *
   * **data type** varchar(?)
   */
  remonm: string;

  /**
   * 고장요인상세
   *
   * **data type** varchar(?)
   */
  remoremark: string;

  /**
   * 고장원인
   *
   * **data type** varchar(?)
   */
  faccd: string;

  /**
   * 고장원인
   *
   * **data type** varchar(?)
   */
  facnm: string;

  /**
   * 고장부위
   *
   * **data type** varchar(?)
   */
  gregicd: string;

  /**
   * 고장부위
   *
   * **data type** varchar(?)
   */
  greginm: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar(?)
   */
  regicd: string;

  /**
   * 고장부위상세
   *
   * **data type** varchar(?)
   */
  reginm: string;

  /**
   * 처리결과
   *
   * **data type** varchar(?)
   */
  resultcd: string;

  /**
   * 처리방법
   *
   * **data type** varchar(?)
   */
  resultnm: string;

  /**
   * 대응시간
   *
   * **data type** varchar(?)
   */
  resutime: string;

  /**
   * 처리시간
   *
   * **data type** varchar(?)
   */
  resulttime: string;

  /**
   * 진행내역
   *
   * **data type** varchar(?)
   */
  result: string;

  /**
   * 담당부서명
   *
   * **data type** varchar(?)
   */
  actdivinm: string;

  /**
   * 하단 총계 데이터
   *
   * **data type** varchar(?)
   */
  cnt_tot: string;

  /**
   * 하단 총계 데이터
   *
   * **data type** varchar(?)
   */
  equp_cnttot: string;

  /**
   * ?
   *
   * **data type** varchar(?)
   */
  elno: string;

  /**
   * ?
   *
   * **data type** varchar(?)
   */
  actperid: string;

  /**
   * ?
   *
   * **data type** varchar(?)
   */
  contents: string;

  /**
   * ?
   *
   * **data type** varchar(?)
   */
  recenum: string;

  /**
   * ?
   *
   * **data type** varchar(?)
   */
  trouble: string;

  /**
   * 사후관리사항
   *
   * **data type** varchar(?)
   */
  customer: string;

  /**
   * 에러코드
   *
   * **data type** varchar(?)
   */
  errcd: string;

  /**
   * 에러코드 옆
   *
   * **data type** varchar(?)
   */
  errseq: string;

  /**
   * 에러명
   *
   * **data type** varchar(?)
   */
  errnm: string;

  /**
   * 제작사
   *
   * **data type** varchar(?)
   */
  manucd: string;

  /**
   * 모델
   *
   * **data type** varchar(?)
   */
  mdnm: string;

  /**
   * 고장원인상세 체크
   *
   * **data type** varchar(?)
   */
  remochk: string;

  /**
   * 처리내용 상세
   *
   * **data type** varchar(?)
   */
  resuchk: string;

  /**
   * 사후관리사항 체크
   *
   * **data type** varchar(?)
   */
  custchk: string;

  /**
   * 고객요구사항 체크
   *
   * **data type** varchar(?)
   */
  chk: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  rescuetime: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  rescuenm: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  ks1flag: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  s1flag: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  attcnt: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  mpclafi: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  receequpcd: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  contcd: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  resque: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  resquedate: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  wadcnt: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  lastcnt: string;

  /**
   *
   *
   * **data type** varchar(?)
   */
  wadnot: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.email = data.email || '';
    this.equpcd = data.equpcd || '';
    this.elno = data.elno || '';
    this.equpnm = data.equpnm || '';
    this.contg = data.contg || '';
    this.perid = data.perid || '';
    this.perid2 = data.perid2 || '';
    this.qty = data.qty || '';
    this.tel = data.tel || '';
    this.fax = data.fax || '';
    this.faxnum = data.faxnum || '';
    this.flag = data.flag || '';
    this.homepage = data.homepage || '';
    this.zipcode = data.zipcode || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.samt = data.samt || '';
    this.amt = data.amt || '';
    this.monamt = data.monamt || '';
    this.remark = data.remark || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.clsnm = data.clsnm || '';
    this.clsremark = data.clsremark || '';
    this.bigo = data.bigo || '';
    this.gubun = data.gubun || '';
    this.contgubun = data.contgubun || '';
    this.seq = data.seq || '';
    this.becontg = data.becontg || '';
    this.clsrecedate = data.clsrecedate || '';
    this.compflag = data.compflag || '';
    this.divicd = data.divicd || '';
    this.divicd2 = data.divicd2 || '';
    this.divinm = data.divinm || '';
    this.divinm2 = data.divinm2 || '';
    this.pernm = data.pernm || '';
    this.pernm2 = data.pernm2 || '';
    this.actpernm = data.actpernm || '';
    this.actppernm = data.actppernm || '';
    this.contpernm = data.contpernm || '';
    this.mgunun = data.mgunun || '';
    this.hp = data.hp || '';
    this.senddate = data.senddate || '';
    this.repernm = data.repernm || '';
    this.recedate = data.recedate || '';
    this.recetime = data.recetime || '';
    this.berecedate = data.berecedate || '';
    this.berecetime = data.berecetime || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.comptime = data.comptime || '';
    this.arrivdate = data.arrivdate || '';
    this.arrivtime = data.arrivtime || '';
    this.cnt = data.cnt || '';
    this.ascnt = data.ascnt || '';
    this.contnm = data.contnm || '';
    this.contremark = data.contremark || '';
    this.resucd = data.resucd || '';
    this.resunm = data.resunm || '';
    this.resuremark = data.resuremark || '';
    this.remocd = data.remocd || '';
    this.remonm = data.remonm || '';
    this.remoremark = data.remoremark || '';
    this.faccd = data.faccd || '';
    this.facnm = data.facnm || '';
    this.gregicd = data.gregicd || '';
    this.greginm = data.greginm || '';
    this.regicd = data.regicd || '';
    this.reginm = data.reginm || '';
    this.resultcd = data.resultcd || '';
    this.resultnm = data.resultnm || '';
    this.resutime = data.resutime || '';
    this.resulttime = data.resulttime || '';
    this.result = data.result || '';
    this.actdivinm = data.actdivinm || '';
    this.cnt_tot = data.cnt_tot || '';
    this.equp_cnttot = data.equp_cnttot || '';
    this.troubledate = data.troubledate || '';
    this.troubletime = data.troubletime || '';
    this.troublesu = data.troublesu || '';
    this.actperid = data.actperid || '';
    this.contents = data.contents || '';
    this.recenum = data.recenum || '';
    this.trouble = data.trouble || '';
    this.customer = data.customer || '';
    this.errcd = data.errcd || '';
    this.errseq = data.errseq || '';
    this.errnm = data.errnm || '';
    this.manucd = data.manucd || '';
    this.mdnm = data.mdnm || '';
    this.remochk = data.remochk || '';
    this.resuchk = data.resuchk || '';
    this.custchk = data.custchk || '';
    this.chk = data.chk || '';
    this.rescuetime = data.rescuetime || '';
    this.rescuenm = data.rescuenm || '';
    this.ks1flag = data.ks1flag || '';
    this.s1flag = data.s1flag || '';
    this.attcnt = data.attcnt || '';
    this.mpclafi = data.mpclafi || '';
    this.receequpcd = data.receequpcd || '';
    this.contcd = data.contcd || '';
    this.resque = data.resque || '';
    this.resquedate = data.resquedate || '';
    this.wadcnt = data.wadcnt || '';
    this.lastcnt = data.lastcnt || '';
    this.wadnot = data.wadnot || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get elnoRegularized() {
    if (this.elno.length < 7) return this.elno;
    return `${this.elno.substr(0, 4)}-${this.elno.substr(4, 3)}`;
  }
}
