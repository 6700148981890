import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_pb404
 * @category 교육보고서
 */

export class EducationPopupModel {
  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly edunum: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly seq: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.remark = data.remark || '';
    this.seq = data.seq || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
