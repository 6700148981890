import { FaMaxcdn } from 'react-icons/all';
import { TabModel } from '../../../../models';
import { MaintenanceMonthTemplate } from './tabs/MaintenanceMonth.template';
import { Maintenance } from './Maintenance';
import { TabLayoutDataItem } from '../../../../constants';

export const MaintenanceTab: Array<TabLayoutDataItem<Maintenance>> = [
  {
    tab: new TabModel('Month', FaMaxcdn, '월'),
    template: (scope) => <MaintenanceMonthTemplate scope={scope} />,
  },
];
