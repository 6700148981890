import { FaCodeBranch } from 'react-icons/all';
import React from 'react';
import { TabLayoutDataItem } from '../../../../constants';
import { Enrollment } from './Enrollment';
import { TabModel } from '../../../../models';
import { EnrollmentTabMaterialsTemplate } from './tabs/EnrollmentTabMaterials.template';
import { EnrollmentTabDetailTemplate } from './tabs/EnrollmentTabDetail.template';
import { EnrollmentTabHistoryTemplate } from './tabs/EnrollmentTabHistory.template';
import { EnrollmentTabConferenceTemplate } from './tabs/EnrollmentTabConference.template';
import {
  CircleBadge,
} from '../../../../components';

/**
 * 화면 > 탭
 * @window w_tb_e451
 * @category 견적서등록
 */
export const generateEnrollmentTab = (enrollment: Enrollment): Array<TabLayoutDataItem<Enrollment>> => ([
  {
    tab: new TabModel('DETAIL', FaCodeBranch, '견적항목'),
    template: (scope) => <EnrollmentTabDetailTemplate scope={scope} />,
  },
  {
    tab: new TabModel('MATERIALS', FaCodeBranch, '부품내역등록'),
    template: (scope) => <EnrollmentTabMaterialsTemplate scope={scope} />,
  },
  {
    tab: new TabModel('HISTORY', FaCodeBranch, '견적이력정보'),
    template: (scope) => <EnrollmentTabHistoryTemplate scope={scope} />,
  },
  {
    tab: new TabModel('CONFERENCE', FaCodeBranch,
      <React.Fragment>
        협의사항
        {enrollment.state.dataConference?.length === 0
          ? null : <CircleBadge
            style={{
              top: 3,
              right: -8,
              left: 'initial',
            }}
          >
        <span style={{ backgroundColor: 'var(--color-red)' }}>
          {enrollment.state.dataConference?.length}
        </span>
          </CircleBadge>
        }
    </React.Fragment>),
    template: (scope) => <EnrollmentTabConferenceTemplate scope={scope} />,
  },
]);
