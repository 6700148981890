import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { BankTemplate } from './Bank.template';
import { BankModel } from './Bank.model';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface BankState {
  searchQuery: string;
  bankList: Array<BankModel>;
  focused: BankModel;
}

/**
 * 컨트롤러
 * @window w_s010
 * @category 은행코드등록
 */
export class Bank extends PageComponent<PageProps, BankState>
  implements PageToolEvents {
  updatedRows?: Array<BankModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  focusedIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      data: [],
      bankList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {},
      (params) => (
        api.retrieve(params)
      ),
      async (items, next) => {
        await this.SS({
          bankList: [...this.state.bankList, ...items.map((x: any) => new BankModel(x))],
        });
        next && next();
        await this.table.current?.update(false);
      },
      async () => {
        await this.SS({
          bankList: [],
        });
        await this.infinity?.retrieve();
        await this.table.current?.update(true);

        this.state.bankList.length > 0 && this.table.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      bankList: [],
    });

    // const index = await this.infinity?.retrieveTo('bankcd', this.state.focused?.bankcd, type) || 0;
    await this.infinity.retrieveAll();
    this.state.bankList.length > 0 && this.table.current?.setFocus(this.focusedIndex, 1);
    await this.table.current?.update(true);

    // this.state.bankList.length > 0 && this.table.current?.setFocus(0, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      bankList: [...this.state.bankList, new BankModel(data, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.bankList.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (await api.save({
      // items: this.updatedRows,
      items: this.state.bankList,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }

    await this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `${this.state.focused?.bankcd} - ${this.state.focused?.banknm}`;
    await api.delete(text, this.state.focused) && await this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.bankList.length > 0,
      '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.state.bankList.length > 0,
      '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: BankModel, index: number) {
    this.focusedIndex = index;
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: Array<BankModel>, updatedRows: Array<BankModel>) {
    this.updatedRows = updatedRows;
    this.setState({ bankList: rows });
  }

  render() {
    return (
      <BankTemplate
        scope={this}
      />
    );
  }
}
