import { CheckBox, FlexLayout } from '../../../../../components';
import { TLHF } from '../../../../../constants';
import { Enrollment } from '../Enrollment';
import { Format } from '../../../../../utils/string';

/**
 * 화면 > 탭 > 테이블 - 견적항목 > 팝업 - 견적단가검색
 * @window w_tb_da006
 * @category 수주서등록
 */
export const EnrollmentModalAmountTableHeader: TLHF<any, Enrollment> = (scope) => ([
  {
    id: 'chk',
    text: '선택',
    width: 60,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.chk === '1'}
        onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
        readonly={scope.tableDetailFocus?.costcd === x.costcd}
      />
    ),
  },
  {
    id: 'costcd',
    text: '코드',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.costcd}
      </FlexLayout>
    ),
  },
  {
    id: 'costnm',
    text: '구분',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.costnm}
      </FlexLayout>
    ),
  },
  {
    id: 'size',
    text: '규격',
    width: 150,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.size}
      </FlexLayout>
    ),
  },
  {
    id: 'unit',
    text: '단위',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.unit}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '수량',
    width: 80,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.qty}
      </FlexLayout>
    ),
  },
  {
    id: 'uamt',
    text: '단가',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.uamt, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '금액',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.samt, true)}
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '비고',
    width: 300,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
]);
