import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  TableLayout,
  SearchLayout,
  DateTextBox,
  CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { DailyChart } from './DailyChart';
import { SearchBinding } from '../../../../models';
import { Date6Calculator, Time4 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e034w_01
 * @category 일상점검표
 */
export const DailyChartTemplate: React.FC<TemplateProps<DailyChart>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 800 }}
      >
        <FlexLayout size={220}>
          <span>년월</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>현장코드</span>
          <TextBox
            value={scope.state.actcd}
            textAlign="center"
            onChange={(value) => scope.setState({ actcd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_E601_1',
              {},
              true,
              (item) => {
                scope.setState({
                  actcd: item.cd,
                  actnm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.actnm}
            weight={2}
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

      </SearchLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'chk',
              text: '출력',
              width: 50,
              render: (x, rowUpdate) => (
                <CheckBox
                  isDisabledTrackingStateChange={true}
                  value={x.chk === '1'}
                  onChange={(value) => rowUpdate({ chk: value ? '1' : '0' })}
                />
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 210,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.actnm}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '호기명',
              width: 100,
            },
            {
              id: 'evnm',
              text: '기종',
              width: 170,
              render: (x) => (
                <TextBox
                  textAlign="left"
                  value={x.evnm}
                />
              ),
            },
            {
              id: 'weekday01',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[0] }}
                  >
                    1일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[0] }}
                    >
                      {scope.state?.weekday[0]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm01}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime01 === '' ? '' : Time4.withDash(x.sttime01) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday02',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[1] }}
                  >
                    2일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[1] }}
                    >
                      {scope.state?.weekday[1]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm02}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime02 === '' ? '' : Time4.withDash(x.sttime02) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday03',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[2] }}
                  >
                    3일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[2] }}
                    >
                      {scope.state?.weekday[2]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm03}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime03 === '' ? '' : Time4.withDash(x.sttime03) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday04',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[3] }}
                  >
                    4일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[3] }}
                    >
                      {scope.state?.weekday[3]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm04}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime04 === '' ? '' : Time4.withDash(x.sttime04) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday05',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[4] }}
                  >
                    5일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[4] }}
                    >
                      {scope.state?.weekday[4]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm05}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime05 === '' ? '' : Time4.withDash(x.sttime05) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday06',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[5] }}
                  >
                    6일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[5] }}
                    >
                      {scope.state?.weekday[5]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm06}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime06 === '' ? '' : Time4.withDash(x.sttime06) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday07',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[6] }}
                  >
                    7일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[6] }}
                    >
                      {scope.state?.weekday[6]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm07}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime07 === '' ? '' : Time4.withDash(x.sttime07) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday08',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[7] }}
                  >
                    8일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[7] }}
                    >
                      {scope.state?.weekday[7]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm08}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime08 === '' ? '' : Time4.withDash(x.sttime08) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday09',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[8] }}
                  >
                    9일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[8] }}
                    >
                      {scope.state?.weekday[8]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm09}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime09 === '' ? '' : Time4.withDash(x.sttime09) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday10',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[9] }}
                  >
                    10일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[9] }}
                    >
                      {scope.state?.weekday[9]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm10}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime10 === '' ? '' : Time4.withDash(x.sttime10) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday11',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[10] }}
                  >
                    11일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[10] }}
                    >
                      {scope.state?.weekday[10]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm11}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime11 === '' ? '' : Time4.withDash(x.sttime11) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday12',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[11] }}
                  >
                    12일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[11] }}
                    >
                      {scope.state?.weekday[11]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm12}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime12 === '' ? '' : Time4.withDash(x.sttime12) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday13',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[12] }}
                  >
                    13일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[12] }}
                    >
                      {scope.state?.weekday[12]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm13}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime13 === '' ? '' : Time4.withDash(x.sttime13) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday14',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[13] }}
                  >
                    14일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[13] }}
                    >
                      {scope.state?.weekday[13]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm14}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime14 === '' ? '' : Time4.withDash(x.sttime14) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday15',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[14] }}
                  >
                    15일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[14] }}
                    >
                      {scope.state?.weekday[14]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm15}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime15 === '' ? '' : Time4.withDash(x.sttime15) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday16',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[15] }}
                  >
                    16일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[15] }}
                    >
                      {scope.state?.weekday[15]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm16}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime16 === '' ? '' : Time4.withDash(x.sttime16) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday17',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[16] }}
                  >
                    17일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[16] }}
                    >
                      {scope.state?.weekday[16]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm17}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime17 === '' ? '' : Time4.withDash(x.sttime17) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday18',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[17] }}
                  >
                    18일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[17] }}
                    >
                      {scope.state?.weekday[17]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm18}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime18 === '' ? '' : Time4.withDash(x.sttime18) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday19',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[18] }}
                  >
                    19일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[18] }}
                    >
                      {scope.state?.weekday[18]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm19}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime19 === '' ? '' : Time4.withDash(x.sttime19) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday20',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[19] }}
                  >
                    20일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[19] }}
                    >
                      {scope.state?.weekday[19]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm20}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime20 === '' ? '' : Time4.withDash(x.sttime20) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday21',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[20] }}
                  >
                    21일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[20] }}
                    >
                      {scope.state?.weekday[20]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm21}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime21 === '' ? '' : Time4.withDash(x.sttime21) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday22',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[21] }}
                  >
                   22일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[21] }}
                    >
                      {scope.state?.weekday[21]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm22}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime22 === '' ? '' : Time4.withDash(x.sttime22) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday23',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[22] }}
                  >
                    23일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[22] }}
                    >
                      {scope.state?.weekday[22]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm23}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime23 === '' ? '' : Time4.withDash(x.sttime23) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday24',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[23] }}
                  >
                    24일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[23] }}
                    >
                      {scope.state?.weekday[23]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm24}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime24 === '' ? '' : Time4.withDash(x.sttime24) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday25',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[24] }}
                  >
                    25일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[24] }}
                    >
                      {scope.state?.weekday[24]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm25}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime25 === '' ? '' : Time4.withDash(x.sttime25) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday26',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[25] }}
                  >
                    26일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[25] }}
                    >
                      {scope.state?.weekday[25]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm26}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime26 === '' ? '' : Time4.withDash(x.sttime26) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday27',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[26] }}
                  >
                    27일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[26] }}
                    >
                      {scope.state?.weekday[26]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm27}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime27 === '' ? '' : Time4.withDash(x.sttime27) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday28',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[27] }}
                  >
                    28일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[27] }}
                    >
                      {scope.state?.weekday[27]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm28}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime28 === '' ? '' : Time4.withDash(x.sttime28) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday29',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[28] }}
                  >
                    29일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[28] }}
                    >
                      {scope.state?.weekday[28]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm29}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime29 === '' ? '' : Time4.withDash(x.sttime29) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday30',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[29] }}
                  >
                    30일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[29] }}
                    >
                      {scope.state?.weekday[29]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm30}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime30 === '' ? '' : Time4.withDash(x.sttime30) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'weekday31',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    align="end"
                    style={{ color: scope.state.color[30] }}
                  >
                    31일
                  </FlexLayout>
                  {scope.state.weekday && scope.state.weekday.length > 0 ? (
                    <FlexLayout
                      align="start"
                      style={{ color: scope.state.color[30] }}
                    >
                      {scope.state?.weekday[30]?.text}
                    </FlexLayout>
                  ) : (
                    <FlexLayout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              ),
              width: 50,
              render: (x) => {
                let color = '';

                switch (x.date1) {
                  case '00':
                    color = '#FF0000';
                    break;

                  case '01':
                    color = '#000000';
                    break;

                  case '02':
                    color = '#5B1A86';
                    break;

                  case '03':
                    color = '#FF7F27';
                    break;

                  case '04':
                    color = 'var(--color-yellow)';
                    break;

                  case '05':
                    color = 'var(--color-red)';
                    break;

                  case '06':
                    color = 'var(--color-blue)';
                    break;

                  case '07':
                    color = 'var(--color-blue)';
                    break;

                  case '08':
                    color = '#000000';
                    break;

                  case '09':
                    color = '#FAF4C0';
                    break;

                  case '10':
                    color = '#7F7A2C';
                    break;

                  case '11':
                    color = '#7F7A2C';
                    break;

                  case '12':
                    color = '#FF7F27';
                    break;

                  default:
                    break;
                }

                return (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color }}
                  >
                    <FlexLayout>
                      {x.pernm31}
                    </FlexLayout>
                    <FlexLayout>
                      {x.sttime31 === '' ? '' : Time4.withDash(x.sttime31) }
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
          ]}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          data={scope.state.data}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
