import { Maintenance } from '../Maintenance';
import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';

export const MaintenanceTabMonthGridHeader: GLHF<any, Maintenance> = (scope) => ([
  {
    id: 'divinm',
    text: '부서명',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
      >
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자명',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'cnt',
    text: '현장',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '1')}
      >
        {x.cnt === '0' ? '' : x.cnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.cnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '1')}
      >
        {x.qty === '0' ? '' : x.qty}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.qty_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'planamt11',
    text: '목표금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '2-11')}
      >
        {Format.number(x.planamt11 === '0' ? '' : x.planamt11)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.planamt11_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'costcnt11',
    text: '견적건수',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#3A8080' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2-11')}
      >
        {x.costcnt11 === '0' ? '' : x.costcnt11}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#3A8080' }}
      >
        {scope.state.costcnt11_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt11',
    text: '견적금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#3A8080' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2-11')}
      >
        {Format.number(x.costamt11 === '0' ? '' : x.costamt11)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#3A8080' }}
      >
        {scope.state.costamt11_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt11',
    text: '매출(실적)',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2-11')}
      >
        {Format.number(x.misamt11 === '0' ? '' : x.misamt11)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.misamt11_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'notmisamt11',
    text: '미달성금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2-11')}
      >
        {Format.number(x.notmisamt11 === '0' ? '' : x.notmisamt11)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.notmisamt11_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'planyul11',
    text: '달성율',
    width: 67,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'darkviolet' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2-11')}
      >
        {x.planyul11}%
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'darkviolet' }}
      >
        {scope.state.planyul11_tot}%
      </FlexLayout>
    ),
  },
  {
    id: 'planamt13',
    text: '목표금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '2')}
      >
        {Format.number(x.planamt13 === '0' ? '' : x.planamt13)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.planamt13_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'costcnt13',
    text: '견적건수',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#3A8080' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2')}
      >
        {x.costcnt13 === '0' ? '' : x.costcnt13}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#3A8080' }}
      >
        {scope.state.costcnt13_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt13',
    text: '견적금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#3A8080' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2')}
      >
        {Format.number(x.costamt13 === '0' ? '' : x.costamt13)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#3A8080' }}
      >
        {scope.state.costamt13_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt13',
    text: '매출(실적)',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2')}
      >
        {Format.number(x.misamt13 === '0' ? '' : x.misamt13)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.misamt13_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'notmisamt13',
    text: '미달성금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2')}
      >
        {Format.number(x.notmisamt13 === '0' ? '' : x.notmisamt13)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.notmisamt13_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'planyul13',
    text: '달성율',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'darkviolet' }}
        onDoubleClick={() => scope.openChoiceModal(true, '2')}
      >
        {x.planyul13}%
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'darkviolet' }}
      >
        {scope.state.planyul13_tot}%
      </FlexLayout>
    ),
  },
  {
    id: 'planamt12',
    text: '목표금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '3')}
      >
        {Format.number(x.planamt12 === '0' ? '' : x.planamt12)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope.state.planamt12_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'costcnt12',
    text: '견적건수',
    width: 50,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#3A8080' }}
        onDoubleClick={() => scope.openChoiceModal(true, '3')}
      >
        {x.costcnt12 === '0' ? '' : x.costcnt12}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#3A8080' }}
      >
        {scope.state.costcnt12_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'costamt12',
    text: '견적금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#3A8080' }}
        onDoubleClick={() => scope.openChoiceModal(true, '3')}
      >
        {Format.number(x.costamt12 === '0' ? '' : x.costamt12)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: '#3A8080' }}
      >
        {scope.state.costamt12_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt12',
    text: '매출(실적)',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '3')}
      >
        {Format.number(x.misamt12 === '0' ? '' : x.misamt12)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.misamt12_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'notmisamt12',
    text: '미달성금액',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '3')}
      >
        {Format.number(x.notmisamt12 === '0' ? '' : x.notmisamt12)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.notmisamt12_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'planyul12',
    text: '달성율',
    width: 69,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'darkviolet' }}
        onDoubleClick={() => scope.openChoiceModal(true, '3')}
      >
        {x.planyul12}%
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'darkviolet' }}
      >
        {scope.state.planyul12_tot}%
      </FlexLayout>
    ),
  },
  {
    id: 'bccnt',
    text: 'B, C',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'magenta' }}
        onDoubleClick={() => scope.openChoiceModal(true, '4')}
      >
        {x.bccnt === '0' ? '' : x.bccnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'magenta' }}
      >
        {scope.state.bccnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'rececnt',
    text: '고장',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '5')}
      >
        {x.rececnt === '0' ? '' : x.rececnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.rececnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'rececompcnt',
    text: '처리',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '5')}
      >
        {x.rececompcnt === '0' ? '' : x.rececompcnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.rececompcnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'recenotcnt',
    text: '미처리',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'magenta' }}
        onDoubleClick={() => scope.openChoiceModal(true, '5')}
      >
        {x.recenotcnt === '0' ? '' : x.recenotcnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'magenta' }}
      >
        {scope.state.recenotcnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'wadcnt',
    text: '다발',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '6')}
      >
        {x.wadcnt === '0' ? '' : x.wadcnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.wadcnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'troublecnt',
    text: '사람갇힘',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '7')}
      >
        {x.troublecnt === '0' ? '' : x.troublecnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.troublecnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'kccnt',
    text: '검사',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '8')}
      >
        {x.kccnt === '0' ? '' : x.kccnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.kccnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'kc001cnt',
    text: '합격',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '9')}
      >
        {x.kc001cnt === '0' ? '' : x.kc001cnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.kc001cnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'kc002cnt',
    text: '불합격',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '10')}
      >
        {x.kc002cnt === '0' ? '' : x.kc002cnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.kc002cnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'kc003cnt',
    text: '조건부',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'darkviolet' }}
        onDoubleClick={() => scope.openChoiceModal(true, '11')}
      >
        {x.kc003cnt === '0' ? '' : x.kc003cnt}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'darkviolet' }}
      >
        {scope.state.kc003cnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'contpog',
    text: '일반',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        onDoubleClick={() => scope.openChoiceModal(true, '12')}
      >
        {x.contpog === '0' ? '' : x.contpog}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope.state.contpog_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'contfm',
    text: 'FM',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'green' }}
        onDoubleClick={() => scope.openChoiceModal(true, '13')}
      >
        {x.contfm === '0' ? '' : x.contfm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'green' }}
      >
        {scope.state.contfm_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'contlong',
    text: '다년',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '14')}
      >
        {x.contlong === '0' ? '' : x.contlong}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.contlong_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'contcls',
    text: '해지',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
        onDoubleClick={() => scope.openChoiceModal(true, '15')}
      >
        {x.contcls === '0' ? '' : x.contcls}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: 'red' }}
      >
        {scope.state.contcls_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '미수금',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'magenta' }}
        onDoubleClick={() => scope.openChoiceModal(true, '16')}
      >
        {Format.number(x.misamt === '0' ? '' : x.misamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'magenta' }}
      >
        {scope.state.misamt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '수금',
    width: 96,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
        onDoubleClick={() => scope.openChoiceModal(true, '17')}
      >
        {Format.number(x.rcvamt === '0' ? '' : x.rcvamt)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'blue' }}
      >
        {scope.state.rcvamt_tot}
      </FlexLayout>
    ),
  },
]);
