import { FaCodeBranch } from 'react-icons/all';
import { SelfCheck } from './SelfCheck';
import { TabLayoutDataItem } from '../../../../constants';
import { TabModel } from '../../../../models';
import { Tab1Template } from './tabs/tab1.template';
import { Tab2Template } from './tabs/tab2.template';

/**
 * 화면 > 탭
 * @window
 * @category 자체점검현황
 */
export const SelfCheckTab: Array<TabLayoutDataItem<SelfCheck>> = [
  {
    tab: new TabModel('1', FaCodeBranch, '계획일자별'),
    template: (scope) => <Tab1Template scope={scope} />,
  },
  {
    tab: new TabModel('2', FaCodeBranch, '점검일자별'),
    template: (scope) => <Tab2Template scope={scope} />,
  },
];
