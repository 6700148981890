/**
 * 모델
 * @window w_tb_e471w
 * @category 공사현황
 */
export default class StatusTabModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(8)
   */
  readonly actnm: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly bigo: string;

  /**
   * 매출구분
   *
   * **data type** varchar(8)
   */
  readonly billkind: string;

  /**
   * 공사금액
   *
   * **data type** varchar(8)
   */
  readonly compamt: string;

  /**
   * 공사일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 공사번호
   *
   * **data type** varchar(8)
   */
  readonly compnum: string;

  /**
   * 처리자
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   * 처리비고
   *
   * **data type** varchar(8)
   */
  readonly compremark: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly compyn: string;

  /**
   * 부서
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  readonly divinm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly gubun: string;

  /**
   * 미수잔액
   *
   * **data type** varchar(8)
   */
  readonly janamt: string;

  /**
   * 지로구분
   *
   * **data type** varchar(8)
   */
  readonly jirogubun: string;

  /**
   * 미수금
   *
   * **data type** varchar(8)
   */
  readonly misamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly offidate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly offinm: string;

  /**
   * 담당자
   *
   * **data type** varchar(8)
   */
  readonly perid: string;

  /**
   * 수금액
   *
   * **data type** varchar(8)
   */
  readonly rcvamt: string;

  /**
   * 팀장
   *
   * **data type** varchar(8)
   */
  readonly reader: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly recivstatus: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   * 금액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 인수일
   *
   * **data type** varchar(8)
   */
  readonly sudate: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 회사구분
   *
   * **data type** varchar(8)
   */
  readonly wkactcd: string;

  /**
   * 품목명
   *
   * **data type** varchar(8)
   */
  readonly pname: string;

  /**
   * 유무상
   *
   * **data type** varchar(8)
   */
  readonly myn: string;

  /**
   * 품목시리얼넘버
   *
   * **data type** varchar(8)
   */
  readonly pserial: string;

  /**
   * 수량
   *
   * **data type** varchar(8)
   */
  readonly qty: string;

  /**
   * 호기명
   *
   * **data type** varchar(8)
   */
  readonly equpnm: string;

  /**
   * 확인서수령여부
   *
   * **data type** varchar(8)
   */
  readonly receflag: string;

  /**
   * 회사구분
   *
   * **data type** varchar(8)
   */
  wkactnm: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.actnm = data.actnm || '';
    this.bigo = data.bigo || '';
    this.billkind = data.billkind || '';
    this.compamt = data.compamt || '';
    this.compdate = data.compdate || '';
    this.compnum = data.compnum || '';
    this.comppernm = data.comppernm || '';
    this.compremark = data.compremark || '';
    this.compyn = data.compyn || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.gubun = data.gubun || '';
    this.janamt = data.janamt || '';
    this.jirogubun = data.jirogubun || '';
    this.misamt = data.misamt || '';
    this.offidate = data.offidate || '';
    this.offinm = data.offinm || '';
    this.perid = data.perid || '';
    this.rcvamt = data.rcvamt || '';
    this.reader = data.reader || '';
    this.recivstatus = data.recivstatus || '';
    this.saleamt = data.saleamt || '';
    this.samt = data.samt || '';
    this.sudate = data.sudate || '';
    this.tamt = data.tamt || '';
    this.wkactcd = data.wkactcd || '';
    this.pname = data.pname || '';
    this.myn = data.myn || '';
    this.pserial = data.pserial || '';
    this.qty = data.qty || '';
    this.equpnm = data.equpnm || '';
    this.receflag = data.receflag || '';
    this.wkactnm = data.wkactnm || '';
  }
}
