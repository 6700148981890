import { computed, observable } from 'mobx';

export class PushItem {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 사업장 코드
   *
   * **data type** varchar(2)
   */
  spjangcd: string;

  /**
   * 사원코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 부서코드
   *
   * **data type** varchar(10)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(10)
   */
  divinm: string;

  /**
   * 이름
   *
   * **data type** varchar(10)
   */
  pernm: string;

  /**
   * 휴대폰번호
   *
   * **data type** varchar(20)
   */
  handphone: string;

  /**
   * 신규 유무
   *
   * **data type** char(1)
   */
  @observable chk: string;

  constructor(data: any = {}) {
    this.custcd = data?.custcd || '';
    this.spjangcd = data?.spjangcd || '';
    this.perid = data?.perid || '';
    this.pernm = data?.pernm || '';
    this.divicd = data?.divicd || '';
    this.divinm = data?.divinm || '';
    this.handphone = data?.handphone || '';
    this.chk = data?.chk || '0';
  }

  @computed
  get isChecked() {
    return this.chk === '1';
  }
}
