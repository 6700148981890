import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Sum } from '../../../../../utils/array';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { DepositModel } from '../models';
import { OneDayDeadline } from '../OneDayDeadline';

// 예금내역
const Deposit: GridLayoutHeader<DepositModel, OneDayDeadline>[] = [
  {
    id: 'misdate',
    text: '발생일자',
    width: 10,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 9,
  },
  {
    id: 'banknm',
    text: '은행',
    width: 11,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.banknm}
      </FlexLayout>
    ),
  },
  {
    id: 'accnum',
    text: '계좌번호',
    width: 11,
  },
  {
    id: 'remark',
    text: '적요',
    width: 20,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '지급처명',
    width: 13,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '입금액',
    width: 13,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '지급액',
    width: 13,
    trail: (data) => {
      const sumMijamt = Sum.all(data, 'mijamt');
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumMijamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
  },
];

export default Deposit;
