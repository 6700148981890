import * as React from 'react';
import { action } from 'mobx';
import printJS from 'print-js';
import {
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ElectronicTaxTemplate } from './ElectronicTax.template';
import {
  ElectronicTaxModel,
} from './ElectronicTax.model';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { PageComponent } from '../../../../utils/layout';
import Confirm from '../../../../utils/confirm/Confirm';
import { ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import { Date8 } from '../../../../utils/time';

interface ElectronicTaxState {
  stdate: string;
  enddate: string;
  recivstatus: string;
  funccode: string;
  regdate: string;
  cltcd: string;
  cltnm: string;
  pushTotalCheck: boolean;
  pushData: Array<ElectronicTaxModel>;

  // data
  electronicTaxList: Array<ElectronicTaxModel>;
  electronicTaxDetailList: Array<ElectronicTaxModel>;
  focusedElectronicTax?: ElectronicTaxModel;
  data: ElectronicTaxModel;

  // trail
  suprice_tot: string;
  taxamt_tot: string;
  total: string;
  mamt_tot: string;
}

/**
 * 컨트롤러
 * @window w_tb_ia090
 * @category 전자세금계산서발행
 */
export class ElectronicTax extends PageComponent<PageProps, ElectronicTaxState>
  implements PageToolEvents {
  updatedRows?: Array<ElectronicTaxModel>;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  sendChk: boolean = false;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      recivstatus: '1',
      cltcd: '',
      funccode: 'DD',
      regdate: `${year}${month}${date}`,
      electronicTaxList: [],
      electronicTaxDetailList: [],

      // trail
      suprice_tot: '0',
      taxamt_tot: '0',
      total: '0',
      mamt_tot: '0',
    };

    this.grid = React.createRef();
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onReopenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.sendChk = false;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
        recivstatus: this.state.recivstatus,
        funccode: this.state.funccode,
        regdate: this.state.regdate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          electronicTaxList: [
            ...this.state.electronicTaxList,
            ...items.map((x: any) => new ElectronicTaxModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          electronicTaxList: [],
          electronicTaxDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.electronicTaxList && this.state.electronicTaxList?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 1);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      electronicTaxList: [],
      electronicTaxDetailList: [],
      pushTotalCheck: false,
    }, async () => {
      const index = await this.infinity?.retrieveTo(['no', 'agneremail'],
        [this.state.focusedElectronicTax?.no, this.state.focusedElectronicTax?.agneremail], type, true) || 0;
      if (this.state.electronicTaxList && this.state.electronicTaxList.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 1);
        this.onRowFocusEvent(this.state.electronicTaxList[0]);
      }
      // 전체 체크
      this.SS({
        suprice_tot: this.infinity?.box?.suprice_tot || '0',
        taxamt_tot: this.infinity?.box?.taxamt_tot || '0',
        total: this.infinity?.box?.total || '0',
        mamt_tot: this.infinity?.box?.mamt_tot || '0',
      });
      await this.table.current?.update(true);
    });

    ConfirmWarning.show('확인', '거래처의 상태를 가져오는(갱신) 중입니다..');
  }

  @action
  async onMessageEvent(topic: string, message: string) {
    const { user } = this.props.publicStore;
    if (topic === `/elman/web/${user.custcd}/${user.perid}/wait`) {
      const json = JSON.parse(JSON.parse(message));
      const data = json.response;

      if (data.opcode === '52') {
        ConfirmWarning.show(data.titlebox, data.messagebox);
      } else {
        ConfirmSuccess.show(data.titlebox, data.messagebox);
      }

      await this.props.publicStore.stopLoading();
      this.onRetrieveEvent();
    }
  }

  @action
  onRowFocusEvent(item: any) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState({ focusedElectronicTax: item }, async () => {
      const data = await api.fxExec('dw_1_RowFocuschanged',
        {
          docuno: item.docuno,
        });
      if (data.items) {
        this.setState({
          electronicTaxDetailList: data.items,
        });
      } else {
        this.setState({
          electronicTaxDetailList: [],
        });
      }
      this.grid.current?.forceRepaint(true);
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.electronicTaxList?.forEach((x: any) => {
      // state가 02이거나 03이면 체크안하고 data만 push
      // state가 02, 03이 아닌 항목만 체크
      if (x.state === '02' || x.state === '03') {
        checkData.push(new ElectronicTaxModel({
          ...x,
        }));
      } else {
        checkData.push(new ElectronicTaxModel({
          ...x,
          selchk: checked === true ? '1' : '0',
        }));
      }
    });

    this.setState({
      pushTotalCheck: checked,
      electronicTaxList: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  // eslint-disable-next-line consistent-return
  async onSubmitEvent() {
    const { actionStore: api } = this.props;
    let noCheck = true; // 체크됐는지
    let noPrenm = false; // 대표자명 비었는지
    let noEmail = false; // 이메일 비었는지
    let noEmailCltnm = ''; // 이메일 빈곳 상호명

    let mainKey = false; // 발급코드 있으면 발급안되도록
    let mainKeyDate = '';
    let mainKeyCltnm = '';

    // 체크 안되있는지 확인
    this.state.electronicTaxList.forEach((x:any) => {
      if (x?.selchk === '1') {
        noCheck = false;
      }
      // 대표자명 비었을 시
      if (x?.selchk === '1' && x?.prenm.trim() === '') {
        noPrenm = true;
      }

      // email비었을 시
      if (x?.selchk === '1' && x?.agneremail.trim() === '') {
        noEmail = true;
        noEmailCltnm = x?.cltnm;
      }

      if (x?.selchk === '1' && x?.mainkey !== '') {
        mainKey = true;
        mainKeyDate = x?.spdate;
        mainKeyCltnm = x?.cltnm;
      }
    });

    // 체크 없을 시 경고
    if (noCheck) {
      ConfirmWarning.show('오류', '체크를 하나 이상 해주세요!');
      return;
    }

    // 전송버튼 여러번 누르는거 방지.
    if (this.sendChk) {
      ConfirmWarning.show('오류', '이미 전송버튼을 눌렀습니다.');
      return;
    }
    this.sendChk = true;

    // 발급코드 있을 시 경고
    if (mainKey) {
      ConfirmWarning.show('오류', `${Date8.withDash(mainKeyDate)} ${mainKeyCltnm} 이미 발급된 코드입니다.`);
      return;
    }

    // email 비었을시 경고
    if (noEmail) {
      ConfirmWarning.show('오류', `${noEmailCltnm} 공급받는자\n E-MAIL이 정의되지 않았습니다.`);
      return;
    }

    // 대표자명 비었을시 경고
    if (noPrenm) {
      ConfirmWarning.show('오류', '공급자 대표자명이 정의되지 않았습니다.');
      return;
    }

    let warning: boolean;
    if (this.state.funccode === 'DM') {
      warning = await Confirm.show('확인', '메일재전송은 이트레이드빌에서 발송하시기 바랍니다. 이트레이드빌으로 이동합니다.', ConfirmType.QUESTION);
      // eslint-disable-next-line consistent-return
      if (warning === true) return window.open('https://www.etradebill.co.kr/index.jsp?sso=ok');
      // eslint-disable-next-line consistent-return
      if (warning === false) return;
    }


    const chkData: any[] = [];
    this.state.electronicTaxList.forEach((x: any) => {
      if (x?.selchk === '1') {
        chkData.push({
          selchk: x.selchk,
          spdate: x.spdate,
          spnum: x.spnum,
        });
      }
    });

    await api.fxExec('wb_send_lazytask',
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
        recivstatus: this.state.recivstatus,
        funccode: this.state.funccode,
        regdate: this.state.regdate,
        items: chkData,
      });

    this.props.publicStore.doLoading();
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ electronicTaxList: rows });
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;

    const file = await api.fxFileRaw(
      'retrieve',
      {
        sub: 'w_popup_ia090',
        cltcd: this.state.focusedElectronicTax?.cltcd,
        spdate: this.state.focusedElectronicTax?.spdate,
        spnum: this.state.focusedElectronicTax?.spnum,
      },
    );

    printJS({
      printable: URL.createObjectURL(new Blob([file.raw!], {
        type: file.extension === 'pdf' ? 'application/pdf' : 'image/png',
      })),
      type: file.extension === 'pdf' ? 'pdf' : 'image',
      showModal: true,
    });
  }

  render() {
    return (
      <ElectronicTaxTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
