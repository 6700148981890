import { IoIosMail } from 'react-icons/all';
import { GLHF } from '../../../../../constants';
import { Badge, FlexLayout } from '../../../../../components';
import { Report } from '../Report';
import ReportModel from '../models/ReportModel';
import { Date8 } from '../../../../../utils/time';


export const Appgubuns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '101', remark: '결재', color: 'var(--color-blue)' },
  { value: '121', remark: '참조', color: 'inherit' },
  { value: '001', remark: '대기', color: 'var(--color-black)' },
  { value: '131', remark: '반려', color: 'var(--color-red)' },
  { value: '111', remark: '결재중', color: 'inherit' },
  { value: '301', remark: '전결', color: 'var(--color-blue)' },
  { value: '401', remark: '대결', color: 'var(--color-blue)' },
];


/**
 * 화면 > 그리드 레이아웃 A
 * @window w_tb_b502
 * @category 영업보고서
 */
export const ReportGridHeader: GLHF<ReportModel, Report> = (scope) => ([
  {
    id: 'state',
    text: '상태',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.state === '1' ? '#A6CC19' : 'var(--color-green)',
        }}
      >
        {x.state === '1' ? '계획' : '완료'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout>
        합 계
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: '계획일자',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.plandate)}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout justify="left">
        {scope.state.cnt_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'plannum',
    text: '번호',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.plannum}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout style={{ color: '#FFF766' }}>
        계획
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 35,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout>
        <FlexLayout
          justify="left"
          style={{ color: '#FFF766' }}
        >
          {scope.state.state1_tot}
        </FlexLayout>

        <FlexLayout
          style={{ color: 'var(--color-green)' }}
        >
          완료
        </FlexLayout>

        <FlexLayout
          justify="left"
          style={{ color: 'var(--color-green)' }}
        >
          {scope.state.state2_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'compdate',
    text: '방문일자',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.compdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'appgubun',
    text: '결재상태',
    width: 15,
    render: (x) => {
      const fp = Appgubuns.filter((y) => y.value === x.appgubun);
      const nm = fp.length > 0 ? fp[0].remark : '';
      const color = fp.length > 0 ? fp[0].color : '#000000';

      return (
        <FlexLayout
          align="center"
          justify="center"
          style={{
            color,
          }}
        >
          {nm}
          {x?.appremark && <Badge>
            <IoIosMail color="var(--color-red)" size={25} />
          </Badge>}
        </FlexLayout>
      );
    },
  },
]);
