import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { PageComponent } from '../../../../utils';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components';
import { UnitinspectTemplate } from './Unitinspect.template';
import { UnitinspectModel } from './models/Unitinspect.model';
import { ConfirmWarning } from '../../../../utils/confirm';

interface UnitinspectState {
  // 검색조건
  searchQuery: string // 검색어
  endmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  result: string; // 결과
  gubun: string; // 계약구분
  state: string; // 진행상태

  UnitinspectList: Array<UnitinspectModel>
  data: UnitinspectModel;
  focused?: UnitinspectModel;

  // 모달
  isModal: boolean;
  popupData: Array<UnitinspectModel>

  // trail
  cnt_tot: string; // 총계
  compcnt_tot: string; // 처리
  result01_tot: string; // 합격
  result02_tot: string; // 불합격
  result03_tot: string; // 조건부(합격)
}

/**
 * 컨트롤러
 * @window w_tb_e075w_01
 * @category 호기별검사현황[최종검사]
 */
export class Unitinspect extends PageComponent<PageProps, UnitinspectState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  updatedRows?: Array<any>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: '',
      endmon: '',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      result: '%',
      gubun: '1',
      state: '%',
      UnitinspectList: [],
      data: new UnitinspectModel(),

      modal: false,

      // trail
      cnt_tot: '',
      compcnt_tot: '',
      result01_tot: '',
      result02_tot: '',
      result03_tot: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        endmon: this.state.endmon,
        divicd: this.state.divicd,
        perid: this.state.perid,
        result: this.state.result,
        gubun: this.state.gubun,
        state: this.state.state,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          UnitinspectList: [...this.state.UnitinspectList, ...items],
        }, () => {
          this.grid.current?.setFocus(0);
        });
      },
      async () => {
        await this.SS({
          data: new UnitinspectModel(),
          UnitinspectList: [],
        });
        this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를 때는 기존 배열 초기화
    await this.SS({
      data: new UnitinspectModel(),
      UnitinspectList: [],
    });

    await this.infinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot || '0',
      compcnt_tot: this.infinity?.box?.compcnt_tot || '0',
      result01_tot: this.infinity?.box?.result01_tot || '0',
      result02_tot: this.infinity?.box?.result02_tot || '0',
      result03_tot: this.infinity?.box?.result03_tot || '0',
    });
  }

  @action
  async onRowFocusEvent(item:any) {
    this.setState({ data: item });
  }

  @action
  async onModalopenEvent() {
    await this.onModalRetrieveEvent();
  }

  @action
  async onModalRetrieveEvent() {
    const { actionStore: api } = this.props;

    await this.SS({
      popupData: [],
    });

    const data = await api.retrieve(
      {
        sub: 'w_popup_e073_fail',
        ...this.state.data,
      },
    );

    if (!data) {
      ConfirmWarning.show('경고', '상세 내역이 없습니다');
      return;
    }

    if (data?.items) {
      this.setState({
        isModal: true,
        popupData: data?.items,
      });
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.UnitinspectList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      endmon: this.state.endmon,
      divicd: this.state.divicd,
      perid: this.state.perid,
      result: this.state.result,
      gubun: this.state.gubun,
      state: this.state.state,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.UnitinspectList.length < 1) {
      ConfirmWarning.show('오류', '엑셀 전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      endmon: this.state.endmon,
      divicd: this.state.divicd,
      perid: this.state.perid,
      result: this.state.result,
      gubun: this.state.gubun,
      state: this.state.state,
    });
  }


  render() {
    return (
      <UnitinspectTemplate scope={this}/>
    );
  }
}
