import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { ResponseModel } from '../models';
import { Response } from '../Response';

// 대응시간
const ResponseTab: GridLayoutHeader<ResponseModel, Response>[] = [
  {
    id: 'pernm',
    text: '담당자명',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'tottime',
    text: '고장건수',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.cnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.tottime} 건
      </FlexLayout>
    ),
  },
  {
    id: 'time5',
    text: '5분이내',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time5_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.time5} 건
      </FlexLayout>
    ),
  },
  {
    id: 'timeyul5',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul5_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.timeyul5}%
      </FlexLayout>
    ),
  },
  {
    id: 'time6',
    text: '6분~15분',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time6_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.time6} 건
      </FlexLayout>
    ),
  },
  {
    id: 'timeyul6',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul6_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.timeyul6}%
      </FlexLayout>
    ),
  },
  {
    id: 'time1',
    text: '16분~30분',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time1_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.time1} 건
      </FlexLayout>
    ),
  },
  {
    id: 'timeyul1',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul1_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.timeyul1}%
      </FlexLayout>
    ),
  },
  {
    id: 'time2',
    text: '30분~1시간',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.time2} 건
      </FlexLayout>
    ),
  },
  {
    id: 'timeyul2',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.timeyul2}%
      </FlexLayout>
    ),
  },
  {
    id: 'time3',
    text: '1시간~2시간',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time3_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.time3} 건
      </FlexLayout>
    ),
  },
  {
    id: 'timeyul3',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul3_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.timeyul3}%
      </FlexLayout>
    ),
  },
  {
    id: 'time4',
    text: '2시간이상',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time4_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {x.time4} 건
      </FlexLayout>
    ),
  },
  {
    id: 'timeyul4',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul4_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.timeyul4}%
      </FlexLayout>
    ),
  },
];

export default ResponseTab;
