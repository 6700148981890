import * as React from 'react';
import { action } from 'mobx';
import printJS from 'print-js';
import { GridLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';
import { SubmitBoxTemplate } from './SubmitBox.template';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  SubmitBoxDetailModel,
  SubmitBoxModel,
} from './models';
import {
  ConfirmFail,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface SubmitBoxState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  gubun: string; // 결재구분
  papercd: string; // 문서구분

  // 데이터 객체
  focusedSubmitBox?: SubmitBoxModel;
  submitBoxList: Array<SubmitBoxModel>;

  papercds: Array<any>;

  focusedDetail?: SubmitBoxDetailModel;
  detailList: Array<SubmitBoxDetailModel>;
  url?: string;
  isReportModalVisible: boolean;
}

/*
 컨트롤러
  * @window w_tb_e080w_01
 * @category 상신함
*/

export class SubmitBox extends PageComponent<PageProps, SubmitBoxState>
  implements PageToolEvents {
  grid: React.RefObject<GridLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: pageParams.stdate || `${year}0101`, // 기간(시작)
      enddate: pageParams.enddate || `${year}${month}${date}`, // 기간(끝)
      gubun: pageParams.enddate || '%', // 결재구분
      papercd: '%', // 문서구분
      searchQuery: '',

      submitBoxList: [],
      detailList: [],
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        stdate: pageParams.stdate || this.state.stdate,
        enddate: pageParams.enddate || this.state.enddate,
        gubun: pageParams.enddate || this.state.gubun,
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ca510_620_02');

    if (!data) return;

    this.setState({ papercds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        papercd: this.state.papercd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          submitBoxList: [...this.state.submitBoxList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          submitBoxList: [],
          detailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.submitBoxList && this.state.submitBoxList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      submitBoxList: [],
      detailList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.state.submitBoxList.length > 0
      && await this.grid.current?.setFocus(0);
    });
  }

  @action
  onRowFocusEvent(item: SubmitBoxModel) {
    const { actionStore: api } = this.props;
    this.setState({ focusedSubmitBox: item },
      async () => {
        this.infinity2 = new InfinityRetrieve(
          {
            appnum: this.state.focusedSubmitBox?.appnum,
          },
          (params) => api.fxExec('dw_1_RowFocuschanged', params),
          async (items) => {
            await this.SS({
              detailList: items,
            });
            await this.detailGrid.current?.forceRepaint();
          },
          async () => {
            await this.SS({
              detailList: [],
            });

            await this.infinity2?.retrieveAll();
            if (this.state.detailList && this.state.detailList?.length > 0) {
              await this.detailGrid.current?.setFocus(0);
            }
          },
        );
        await this.infinity2?.retrieveAll();
      });
  }

  @action
  async closeReportModal() {
    this.setState({
      isReportModalVisible: false,
    }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async openReportModal() {
    const { user } = this.props.publicStore;
    const paper = this.state.focusedSubmitBox;

    if (!paper) {
      ConfirmWarning.show('오류', '결재할 문서가 없습니다');
      return;
    }

    this.setState({
      isReportModalVisible: true,
      url: `https://api2.elmansoft.com/paper/ND${paper.papercd}/view?custcd=${user.custcd}&appnum=${paper.appnum}`,
    }, () => this.modalRetrieve());
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    await api.retrieve(
      {
        sub: 'w_popup_e080',
        papercd: this.state.focusedSubmitBox?.papercd,
        appnum: this.state.focusedSubmitBox?.appnum,
        perid: user?.perid,
      },
    );
  }

  @action
  async modalPrint() {
    const { user } = this.props.publicStore;
    const paper = this.state.focusedSubmitBox;

    if (!paper) {
      ConfirmWarning.show('경고', '출력할 문서가 없습니다.');
      return;
    }

    try {
      printJS({
        printable: `https://api2.elmansoft.com/paper/ND${paper?.papercd}/image?custcd=${user.custcd}&appnum=${paper?.appnum}&print=1`,
        type: 'image',
        showModal: true,
      });
    } catch {
      ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
    }
  }

  render() {
    return (
      <SubmitBoxTemplate scope={this}/>
    );
  }
}
