import {
  MdAddCircle,
  MdDelete,
} from 'react-icons/md';
import {
  TextBox,
  DateTextBox,
  FormatNumberTextBox,
  FlexLayout,
  Button,
  ComboBox,
  ComboBoxModel,
} from '../../../../../components';
import {
  TableLayoutHeader,
} from '../../../../../constants';
import { EnrollmentHistoryModel } from '../models';
import { Enrollment } from '../Enrollment';
import { SearchBinding } from '../../../../../models';
import { Date8 } from '../../../../../utils/time';

// 계약이력정보
const History: TableLayoutHeader<EnrollmentHistoryModel, Enrollment>[] = [
  {
    id: 'delete',
    text: '삭제',
    width: 40,
    render: (_, _2, _3, scope) => (
      <FlexLayout justify="center">
        <Button
          isIcon={true}
          onClick={() => scope?.contractDelete()}
        >
          <MdDelete size={24}/>
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'attcnt',
    text: '계약서',
    width: 100,
    render: (x, _, _2, scope) => (
      <FlexLayout>
        <TextBox
          color= {x.attcnt > '0' ? 'var(--color-blue)' : ''}
          value={x.attcnt > '0' ? '있음' : '없음'}
          transparent={true}
          textAlign="center"
        />
        <Button
          isIcon={true}
          onClick={() => scope?.setState({ isVisibleAttachment2: true, tabContdate: x.contdate })}
        >
          <MdAddCircle size={24}/>
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'contdate',
    text: '계약일자',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        value={x.contdate}
        onChange={(v) => rowUpdate({ contdate: v })}
        textAlign="center"
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'yy',
    text: '년차',
    width: 70,
    render: (x) => (
      <TextBox
        value={x.yy}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'cltcd',
    text: '거래처코드',
    width: 80,
    color: 'var(--color-red)',
    render: (x, rowUpdate, _2, scope) => (
      <TextBox
        value={x.cltcd}
        transparent={true}
        textAlign="center"
        bindSearchModal={new SearchBinding(
          scope?.props.modalStore!,
          'TB_XCLIENT',
          {},
          true,
          (item) => {
            rowUpdate({
              cltcd: item.cd,
              cltnm: item.cdnm,
            });
          },
        )}
      />
    ),
  },
  {
    id: 'cltnm',
    text: '거래처명',
    width: 200,
    render: (x) => (
      <TextBox
        value={x.cltnm}
        transparent={true}
        textAlign="left"
      />
    ),
  },
  {
    id: 'contg',
    text: '계약분류',
    width: 80,
    render: (x, rowUpdate, _2, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <ComboBox
          value={x.contg}
          data={scope?.state.contgs?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
          onSelect={(option) => scope?.HistoryTabItemChanged(x, 'contg', option.value, rowUpdate)}
        >
        </ComboBox>
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 60,
    render: (x, rowUpdate, _, scope) => (
      <FlexLayout>
        <TextBox
          value={x.qty}
          textAlign="center"
          onChange={(value) => scope?.HistoryTabItemChanged(x, 'qty', value, rowUpdate)}
        >
        </TextBox>
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '계약금액',
    width: 100,
    render: (x, rowUpdate, _, scope) => (
      <FormatNumberTextBox
        char={','}
        charAt={3}
        textAlign="right"
        onChange={(value) => scope?.HistoryTabItemChanged(x, 'amt', value, rowUpdate)}
        value={x.amt}
      />
    ),
  },
  {
    id: 'addyn',
    text: '부가세',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.addyn === '0' ? '별도' : '포함'}
      </FlexLayout>
    ),
  },
  {
    id: 'stdate',
    text: '시작일자',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        value={x.stdate}
        textAlign="center"
        onChange={(v) => rowUpdate({ stdate: v })}
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'enddate',
    text: '종료일자',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        value={x.enddate}
        textAlign="center"
        onChange={(v) => rowUpdate({ enddate: v })}
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'clsdate',
    text: '해지일자',
    width: 100,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        ref={ref}
        value={x.clsdate}
        textAlign="center"
        onChange={(v) => rowUpdate({ clsdate: v })}
        format="####-##-##"
        isDisabledTrackingStateChange={true}
      />
    ),
  },
  {
    id: 'deljugi',
    text: '청구주기',
    width: 100,
    render: (x, rowUpdate, _2, scope) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <ComboBox
          value={x.deljugi}
          data={scope?.state.deljugis?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
          onSelect={(option) => scope?.HistoryTabItemChanged(x, 'deljugi', option.value, rowUpdate)}
        >
        </ComboBox>
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 100,
    render: (x, rowUpdate, _2, scope) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        <ComboBox
          value={x.contgubun}
          data={scope?.state.contgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
          onSelect={(option) => scope?.HistoryTabItemChanged(x, 'contgubun', option.value, rowUpdate)}
        >
        </ComboBox>
      </FlexLayout>
    ),
  },
  {
    id: 'boryudate',
    text: '보류시작일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.boryudate)}
      </FlexLayout>
    ),
  },
  {
    id: 'boryudate2',
    text: '보류종료일',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {Date8.withDash(x.boryudate2)}
      </FlexLayout>
    ),
  },
  {
    id: 'boryusayoo',
    text: '보류사유',
    width: 200,
    render: (x) => (
      <FlexLayout
        align="start"
        justify="center"
      >
        {x.boryusayoo}
      </FlexLayout>
    ),
  },
];

export default History;
