/**
 * 모델
 * @window w_tb_e045_03
 * @category 사전점검등록(점검일지)
 */
export default class JournalDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 판정기준
   *
   * **data type** varchar(8)
   */
  readonly aa_error: string;

  /**
   * 현장코드
   *
   * **data type** varchar(8)
   */
  readonly actcd: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 점검기종
   *
   * **data type** varchar(8)
   */
  readonly evcd: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly gkccd: string;

  /**
   * 부적합내용
   *
   * **data type** varchar(8)
   */
  readonly gresultcd: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 입력자
   *
   * **data type** varchar(8)
   */
  readonly inperid: string;

  /**
   * 순번
   *
   * **data type** varchar(8)
   */
  readonly kccd: string;

  /**
   * 순번명
   *
   * **data type** varchar(8)
   */
  readonly kcnm: string;

  /**
   * 비고
   *
   * **data type** varchar(8)
   */
  readonly remark: string;

  /**
   * 상태
   *
   * **data type** varchar(8)
   */
  readonly resultcd: string;

  /**
   * 순서
   *
   * **data type** varchar(8)
   */
  readonly seq: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  readonly spjangcd: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  readonly state: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.aa_error = data.aa_error || '';
    this.actcd = data.actcd || '';
    this.equpcd = data.equpcd || '';
    this.evcd = data.evcd || '';
    this.gkccd = data.gkccd || '';
    this.gresultcd = data.gresultcd || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.kccd = data.kccd || '';
    this.kcnm = data.kcnm || '';
    this.remark = data.remark || '';
    this.resultcd = data.resultcd || '';
    this.seq = data.seq || '';
    this.spjangcd = data.spjangcd || '';
    this.state = data.state || '';
  }
}
