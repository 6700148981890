import * as React from 'react';
import { observer } from 'mobx-react';
// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';
import { ModalStore } from '../../stores';
import {
  Button,
  FlexLayout,
  SubHeader,
  ModalView,
} from '../../components';
import 'tui-image-editor/dist/tui-image-editor.css';

interface ModalImageEditorProps {
  modalStore: ModalStore;
}

@observer
export class ModalImageEditor extends React.Component<ModalImageEditorProps> {
  render() {
    const { modalStore } = this.props;

    return (
      <ModalView
        width={1200}
        height={730}
        zIndex={-2}
        isVisible={modalStore.isVisibleImageEditor}
        onClose={() => modalStore.closeImageEditor()}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <ImageEditor
            ref={modalStore.refImageEditor}
            includeUI={{
              loadImage: {
                path: modalStore.imageSrc,
                name: 'image',
              },
              menu: ['resize', 'flip', 'rotate', 'shape', 'icon', 'text'], // 'crop',
              initMenu: 'flip',
              uiSize: {
                width: '1180px',
                height: '700px',
              },
              menuBarPosition: 'bottom',
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
          />

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => modalStore.onImageEditorSave()}>저장</Button>
              <Button onClick={() => modalStore.closeImageEditor()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    );
  }
}
