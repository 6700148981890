import { inject, observer } from 'mobx-react';
import { Component, ReactNode } from 'react';
import { PageProps } from '../../../constants';

export function withScreen<S = {}>(Children: Function) {
  @inject('routerStore', 'publicStore', 'modalStore', 'actionStore', 'waitQueueStore') @observer
  class ScreenComponent extends Component<PageProps, S> {
    storedData?: S;

    isFirstOpen: boolean = true;

    child: ReactNode;

    page: any;

    constructor(props: PageProps, context: any) {
      super(props, context);

      if (props.cacheLifecycles?.didRecover) {
        props.cacheLifecycles?.didRecover(this.componentDidRecover);
      }

      const state = this.props.publicStore.headerTabStoredState || {};
      this.storedData = Object.keys(state).length < 1 ? undefined : state;
      if (this.storedData) {
        this.isFirstOpen = false;
      }

      this.child = (
        <Children
          {...this.props}
          state={this.isFirstOpen ? false : this.storedData}
          onMount={(page: any) => {
            this.page = page;
            this.props.publicStore.setCurrentPage(page);
          }}
        />
      );
    }

    componentDidMount() {
      const { currentPage } = this.props.publicStore;
      if (this.isFirstOpen) {
        currentPage?.onFirstOpenEvent && currentPage.onFirstOpenEvent();
      } else {
        currentPage?.onReopenEvent && currentPage.onReopenEvent();
      }
    }

    componentWillUnmount() {
      delete this.child;
      this.child = null;
    }

    componentDidRecover = () => {
      this.props.publicStore.setCurrentPage(this.page);
      this.page.componentDidRecover && this.page.componentDidRecover();
    }

    render() {
      return this.child;
    }
  }
  return ScreenComponent;
}
