import * as React from 'react';
import { action } from 'mobx';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { ResisterTemplate } from './Resister.template';
import { ResisterModel } from './Resister.model';
import { InfinityRetrieve } from '../../../../models/common';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { PageComponent } from '../../../../utils';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PersonPopupModel } from '../../../maintenance/management/Enrollment/models';
import { OptionItem } from '../../../../components/forms/OptionBox/OptionBox';

export const ResisterItemChangeTypeNames = [
  'taxflag',
  'autoflag',
  'foreyn',
  'cltadres',
  'bankcd',
  'telnum',
  'agnernm',
  'agntel',
  'agneremail',
  'agnerdivinm',
];

export enum ResisterItemChangeTypes {
  TAXFLAG,
  AUTOFLAG,
  FOREYN,
  CLTADRES,
  BANKCD,
  TELNUM,
  AGNERNM,
  AGNTEL,
  AGNEREMAIL,
  AGNERDIVINM,
}

export interface TableDetailListItem {
  actcd: string;
  actnm: string;
  cltcd: string;
  custcd: string;
  autoflag: string;
  autonum: string;
  taxmail: string;
  new: string;
  qty: string;
  spjangcd: string;
  taxflag: string;
}

export interface SubCompanyListItem {
  cltcd: string;
  cltnm: string;
  spjangcd: string;
  spjangnm: string;
  saupnum: string;
  prenum: string;
  prenm: string;
  biztypenm: string;
  bizitemnm: string;
  useyn: string;
}

export interface ResisterState {
  searchQuery: string;
  longmis: string;
  clttype: string;
  resisterData: Array<ResisterModel>;
  data: ResisterModel;
  lastnewData: ResisterModel;
  tableDetailData: Array<TableDetailListItem>;
  subCompanyData: Array<SubCompanyListItem>;
  clttypes: Array<any>;
  grades: Array<any>;
  bankcds: Array<any>;
  basicAddressToggle?: boolean;
  cltcd: string;
  searchAddressModal?: boolean;
  subCompanyModal?: boolean;
  addressData: Array<any>;
  dz_flag: string;
  focused?: any;

  focuseddata?: any;

  // 세금계산서메일 모달뷰
  taxEmailDetailModal: boolean;
  taxEmailFocused?: Array<any>;

  PersonPopUpData: Array<PersonPopupModel>;
  PersonPopUpFocused: PersonPopupModel;
  saveData?: Array<ResisterModel>;

  // 계산서 체크풀면 빈값, 체크하면 메일값 다시가져오기위한 변수
  beforeTaxMail: string;
}

/**
 * 컨트롤러
 * @window w_tb_xclient
 * @category 거래처등록
 */
export class Resister extends PageComponent<PageProps, ResisterState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  updatedRows2?: Array<any>;

  grid: React.RefObject<GridLayout> = React.createRef();

  tableDetail: React.RefObject<TableLayout> = React.createRef();

  tableCheckTax: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  focusIndex?: number;

  focusedSubCompanyDataIndex: number;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const pageParams = this.props.publicStore?.getPageParams();

    this.focusedSubCompanyDataIndex = 0;

    this.state = props.state || {
      searchQuery: pageParams.searchQuery || '',
      longmis: '0',
      clttype: '%',
      basicAddressToggle: true,
      resisterData: [],
      subCompanyData: [],
      data: [],

      beforeTaxMail: '',
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    if (pageParams) {
      await this.SS({
        searchQuery: pageParams?.searchQuery || this.state.searchQuery,
      });
      await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
    }
  }

  @action
  async updateActsAutoflag(option: OptionItem) {
    await this.SS({
      data: new ResisterModel({
        ...this.state.data,
        autoflag: option.value,
      }, this.state.data.isNew),
    });

    if (option.value === '1') {
      await this.SS({
        tableDetailData: this.state.tableDetailData.map((x) => ({
          ...x,
          autoflag: '0',
        })),
      });
      this.tableDetail.current?.update(true);
    }
  }

  @action
  async open() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('open',
      {
      });

    this.setState({ dz_flag: data.dz_flag });
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 거래처타입 리스트 가져오기
    let data = await api.dropdown('wf_dd_ca510_046_01');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ grades: data.items });

    // 은행 리스트 가져오기
    data = await api.dropdown('wf_dd_s004');

    // 실패시 리턴
    if (!data) return;

    // 성공시 상태 반영
    this.setState({ bankcds: data.items });

    await this.open();
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        clttype: this.state.clttype,
        longmis: this.state.longmis,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.SS({
          resisterData: [...this.state.resisterData, ...items.map((item) => new ResisterModel(item))],
        });
        next && next();
      },
      async () => {
        await this.SS({
          resisterData: [],
        });
        await this.SS({
          resisterData: [],
          data: new ResisterModel([], false),
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화1
    const lastData = this.state.data;
    this.setState({
      resisterData: [],
      data: new ResisterModel([], false),
    }, async () => {
    });
    if (!autoLoad) return;
    const index = await this.infinity?.retrieveTo(['cltcd', 'cltnm'], [lastData.cltcd, lastData.cltnm], type, true) || 0;
    this.state.resisterData.length > index && this.grid.current?.setFocus(index);
    this.tableDetail.current?.update(true);
  }

  @action
  async onSaveEvent(isSilent: boolean = false) {
    const {
      cltcd,
      cltnm,
    } = this.state.data;

    if (!cltcd) {
      ConfirmWarning.show('오류', '거래처코드를 입력해주세요');
      return;
    }

    if (!cltnm) {
      ConfirmWarning.show('오류', '거래처명칭을 입력해주세요');
      return;
    }

    if (this.state.data.taxflag === '1' && this.state.data.taxmail.trim() === '') {
      ConfirmWarning.show('오류', '이메일을 입력해주세요.');
      return;
    }

    // taxflag 0 일시 taxmail 빈값으로 저장
    if (this.state.data.taxflag === '0') {
      this.state.data.taxmail = '';
    }

    const params = {
      items: this.state.tableDetailData,
      ...this.state.data,
      saupnum: this.state.data.saupnum.replaceAll('-', ''),
    };

    const { actionStore: api } = this.props;
    if (await api.save(
      params,
      this.state.data.isNew,
      false,
    )) {
      !isSilent && await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
    }
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '') {
      await this.SS({
        searchQuery: '',
      });
    }
    const { actionStore: api } = this.props;
    const data = await api.new();

    data && this.setState({
      resisterData: [
        new ResisterModel(data, true),
        ...this.state.resisterData,
      ],
      data: new ResisterModel(data, true),
      lastnewData: new ResisterModel(data, true),
      tableDetailData: [],
    }, async () => {
      this.grid.current?.setFocus(0);
      this.tableDetail.current?.update();
    });
  }

  @action
  async onDeleteEvent() {
    if (this.state.tableDetailData.length) {
      ConfirmWarning.show('오류', '연결된 유지보수현장이 있어 삭제할 수 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.state.data?.cltcd} - ${this.state.data.cltnm}`;
    await api.delete(text, this.state.data) && await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }


  // 종사업장 조회
  @action
  async subRetrieveEvent() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        sub: 'w_tb_xclient_spjangcd',
        cltcd: this.state.data.cltcd,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          subCompanyData: [...this.state.subCompanyData, ...items],
        });
      },
      async () => {
        await this.SS({ subCompanyData: [] });
        await this.infinity2?.retrieve();
        this.popupTable.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화1
    this.setState({
      subCompanyData: [],
    }, async () => {
      await this.infinity2?.retrieve();
      this.popupTable.current?.setFocus(0);
      this.popupTable.current?.update(false);
    });
  }

  @action
  async subSaveEvent() {
    const { actionStore: api } = this.props;

    const item = this.state.subCompanyData[this.focusedSubCompanyDataIndex];
    if (!item) {
      ConfirmWarning.show('오류', '저장할 내용이 없습니다.');
      return;
    }

    let oneChkeck = false;
    this.state.subCompanyData.forEach((x: any) => {
      if (!oneChkeck && x.useyn === '1') {
        oneChkeck = true;
      } else {
        oneChkeck = false;
      }
    });

    if (!oneChkeck) {
      ConfirmWarning.show('오류', '대표가 없거나 한 개 이상입니다.');
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_tb_xclient_spjangcd',
        items: this.state.subCompanyData,
      });

    if (data) {
      await this.SS({
        data: new ResisterModel({
          ...this.state.data,
          jspjangcd: item.spjangcd,
          jsaupnum: item.saupnum,
          jspjangnm: item.spjangnm,
        }, this.state.data.isNew),
      });

      ConfirmWarning.show('확인', '저장되었습니다.');
      await this.subRetrieveEvent();
      this.updatedRows2 = [];
    }
  }

  @action
  async subNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('new',
      {
        sub: 'w_tb_xclient_spjangcd',
        cltcd: this.state.data.cltcd,
      });

    data && this.setState({
      subCompanyData: [...this.state.subCompanyData, data],
    }, async () => {
      this.popupTable.current?.update();
      this.popupTable.current?.setFocus(this.state.subCompanyData.length);
    });
  }

  @action
  async subDeleteEvent() {
    const item = this.state.subCompanyData[this.focusedSubCompanyDataIndex];
    if (!item) {
      ConfirmWarning.show('오류', '항목이 선택되지 않았습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${item.spjangcd} - ${item.spjangnm}`;
    await api.fxDelete(
      'delete',
      text,
      {
        sub: 'w_tb_xclient_spjangcd',
        cltcd: item.cltcd,
        spjangcd: item.spjangcd,
        useyn: item.useyn,
      },
    ) && await this.subRetrieveEvent();
  }

  @action
  subRowFocusEvent(index: number) {
    this.focusedSubCompanyDataIndex = index;
  }

  @action
  onRowFocusEvent(item: ResisterModel, index: number) {
    this.focusIndex = index;

    if (this.state.data.isNew) {
      this.setState({
        data: this.state.lastnewData,
        tableDetailData: [],
      }, () => this.tableDetail.current?.update());
      return;
    }
    this.setState({
      data: item,
    }, async () => {
      const { actionStore: api } = this.props;
      const data = await api.fxExec(
        'dw_1_RowFocuschanged',
        item,
      );
      const one = new ResisterModel(data, false);
      if (data?.items) {
        this.setState({
          data: one,
          tableDetailData: data.items,
        },
        () => this.tableDetail.current?.update(false));
      } else {
        this.setState({
          data: one,
          tableDetailData: [],
        }, () => this.tableDetail.current?.update());
      }
    });
  }

  @action
  async itemChanged(item: any, type: number) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_2_itemchanged', {
      itemname: ResisterItemChangeTypeNames[type],
      data: item,
    });
    this.setState({ data });
    this.setState({ tableDetailData: data.items },
      () => this.tableDetail.current?.update(false));
  }

  @action
  async onAddressButton(value: any) {
    const message = '현장명으로 주소를 검색하시겠습니까?';
    if (AskType.YES !== await Confirm.ask('확인', message, 'Yes', 'No')) return;

    const data = await this.props.modalStore.openSearchAddress(value, value);
    await this.SS({
      data: new ResisterModel({
        ...this.state.data,
        zipcd: data.zipcode,
        oldcltadres: data.roadAddress,
        cltadres2: data.address,
        lat: data.y,
        lng: data.x,
      }, this.state.data?.isNew),
    });
  }

  @action
  AddressModal(isOpen: boolean) {
    this.setState({ searchAddressModal: isOpen });
  }

  @action
  async onActButtonEvent() {
    const { actionStore: api, publicStore } = this.props;

    if ((this.state.data.grade === '02' && this.state.tableDetailData.length > 0) || this.state.tableDetailData.length > 0) {
      if (!await Confirm.show('확인', '이미 현장등록되어있습니다 확인하시겠습니까?', ConfirmType.QUESTION)) {
        return;
      }
      publicStore.go(
        '/w_tb_e601_new',
        {
          searchQuery: this.state.tableDetailData[0].actcd,
          preMenu: 'w_tb_xclient',
        },
      );
      return;
    }

    if (this.state.data.grade === '07' && this.state.tableDetailData.length > 0) {
      if (!await Confirm.show('확인', '이미 현장등록되어있습니다 확인하시겠습니까?', ConfirmType.QUESTION)) {
        return;
      }

      publicStore.go(
        '/w_tb_e601_sulchi',
        {
          searchQuery: this.state.tableDetailData[0].actcd,
          preMenu: 'w_tb_xclient',
        },
      );
    }

    if (this.state.data.cltnm === '') {
      ConfirmWarning.show('확인', '거래처명칭을 입력하세요.');
      return;
    }

    if (this.state.data.taxmail === '' && this.state.data.taxflag !== '0') {
      ConfirmWarning.show('확인', '계산서메일을 입력하세요.');
      return;
    }

    await this.onSaveEvent(true);
    const data = await api.fxExec(
      'wb_actcd',
      {
        ...this.state.data,
        // clttype: this.state.clttype,
        // cltcd: this.state.data.cltcd,
        // grade: this.state.data.grade,
        // actflag: this.state.data.actflag,
      },
    );

    if (this.focusIndex != null) {
      await this.grid.current?.setFocus(this.focusIndex);
    }

    data.anzipcd = data.zipcode;
    // 보수현장등록 신규 생성시 state값이 0으로 되야하는데
    // 거래처등록의 state값이 01 이런식으로 나오는 현상으로 인해 강제로 0 주입
    data.state = '0';
    data && publicStore.go(
      '/w_tb_e601_new',
      {
        pageSaveData: data,
        preMenu: 'w_tb_xclient',
      },
    );
  }

  @action
  async CompanyModal(isOpen: boolean) {
    this.setState({ subCompanyModal: isOpen });
    await this.subRetrieveEvent();
  }

  @action
  async taxEmailModal(isOpen: boolean) {
    this.setState({ taxEmailDetailModal: isOpen });
    await this.taxEmailRetrieve();
  }

  @action
  async onNewCheckTaxEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_xclient_taxmail',
      cltcd: this.state.data?.cltcd,
    });

    const newModel = new PersonPopupModel(data, true);

    if (data) {
      this.setState({
        PersonPopUpData: [...this.state.PersonPopUpData || [], newModel],
        PersonPopUpFocused: newModel,
      }, () => this.tableCheckTax.current?.update(true));
      this.tableCheckTax.current?.setFocus(this.state.PersonPopUpData.length - 1, 1);
    }
  }

  @action
  async onCheckEmailSaveEvent() {
    const { actionStore: api } = this.props;

    const changeItems: any = [];

    this.state.PersonPopUpData?.forEach((x) => {
      changeItems.push({
        cltcd: x.cltcd,
        remark: x.remark,
        seq: x.seq,
        taxmail: x.taxmail,
        new: x.new,
        taxdate: x.taxdate,
      });
    });

    const data = await api.save({
      sub: 'w_tb_xclient_taxmail',
      // items: this.updatedRows2,
      items: changeItems,
    }, this.state.PersonPopUpFocused.isNew);
    if (data) {
      this.setState({
        saveData: this.updatedRows2,
      }, () => this.taxEmailRetrieve());
    }

    // 계산서메일 (필수) 입력값 바뀌게 하려고 한 거 같음
    // const changeData = {
    //   // @ts-ignore
    //   // taxmail: this.state.saveData[0]?.taxmail || '',
    //   // taxdate: Today.year(),
    //   // taxdate: this.state.PersonPopUpFocused?.taxdate,
    // };

    // this.setState({
    //   data: new ResisterModel({
    //     ...this.state.data,
    //     ...changeData,
    //   }, true),
    // });
  }

  @action
  async onCheckTaxDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_xclient_taxmail',
      cltcd: this.state.PersonPopUpFocused.cltcd,
      seq: this.state.PersonPopUpFocused.seq,
    });
    this.updatedRows2 = [];
    await this.tableCheckTax.current?.update(true);
    await this.taxEmailRetrieve();
  }

  @action
  async taxEmailRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({
      PersonPopUpData: [],
    });
    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_xclient_taxmail',
        cltcd: this.state.data.cltcd,
      });
    if (data?.items) {
      this.setState({
        PersonPopUpData: data?.items,
      });
    }

    await this.tableCheckTax.current?.update(true);
    this.tableCheckTax.current?.setFocus(0, 1);
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ resisterData: rows });
  }

  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({
      PersonPopUpData: rows2,
      tableDetailData: rows2,
    });
  }

  @action
  async subUpdatedRows(rows3: any) {
    await this.SS({
      subCompanyData: rows3,
    });
  }

  @action
  onRowFocusEvent2(item: any) {
    this.setState({
      focused: item,
      PersonPopUpFocused: new PersonPopupModel(item, item?.isNew),
    });
  }

  // Links
  // 전화번호등록
  async openTelnum() {
    if (this.state.data?.telnum === '' || this.state.data?.telnum === null) {
      ConfirmWarning.show('오류', '전화번호가 없습니다.');
      return;
    }
    const { publicStore } = this.props;
    publicStore.go('/w_tb_e911', {
      searchQuery: this.state.data?.cltnm,
    });
  }

  async onClickOpenResist(item: ResisterModel) {
    const { publicStore } = this.props;
    publicStore.go(
      '/w_tb_e601_new',
      {
        searchQuery: item.actcd,
      },
    );
  }

  @action
  async stateButton() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_2_buttonclicked', {
      itemname: 'b_state',
      data: this.state.data.state,
      cltcd: this.state.data.cltcd,
      saupnum: this.state.data.saupnum,
    });

    data && this.setState({
      data: new ResisterModel({
        ...this.state.data,
        ...data,
      }, this.state.data.isNew),
    });
  }

  render() {
    return (
      <ResisterTemplate
        scope={this}
        update={(change) => {
          this.setState({
            data: new ResisterModel({
              ...this.state.data,
              ...change,
            }, this.state.data?.isNew),
          });
        }}
        dataUpdate={(change) => this.setState(change)}
      />
    );
  }
}
