import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { PayCurrentStateModel } from './PayCurrentState.model';
import { PayCurrentStateTemplate } from './PayCurrentState.template';
import { InfinityRetrieve } from '../../../../models/common';
import { GridLayout } from '../../../../components';
import { BankItem } from '../../../human/staff/PersonnelResistration/PersonnelResistration';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

interface PayCurrentStateState {
  // 검색조건
  yearmon: string;
  spjangcd: string;
  kukcd?: Array<any>;
  searchKukcds?: string;
  restflag: string;
  name: string;
  offiyn: string;

  // data
  bankcds?: Array<BankItem>;
  data: Array<PayCurrentStateModel>;
  focused?: PayCurrentStateModel;
  pushTotalCheck: boolean;
  pushData: Array<PayCurrentStateModel>;

  // trail
  pay_basic_tot: string;
  pay_older_tot: string;
  pay_night_tot: string;
  pay_holiday_tot: string;
  over_older_pay_tot: string;
  over_holi_pay_tot: string;
  over_duty_pay_tot: string;
  pay_food_tot: string;
  pay_serallow_tot: string;
  pay_leader_tot: string;
  pay_incentive_tot: string;
  minuspay_late_tot_tot: string;
  pay_yearly_tot: string;
  tot_samt_tot: string;
  rest_pay_tot: string;
  tax_income_tot: string;
  tax_health_tot: string;
  insu_unem_tot: string;
  insu_health_tot: string;
  insu_longterm_tot: string;
  insu_national_tot: string;
  payamt_tax_tot: string;
  payamt_tax2_tot: string;
  insu_maf_tot: string;
  tax_national_tot: string;
  tax_suspense_tot: string;
  tax_other_tot: string;
  tot_tamt_tot: string;
  tot_mamt_tot: string;

  // title
  paytype: string;
  pay_tax0_01_t: string;
  pay_tax0_02_t: string;
  pay_tax0_03_t: string;
  pay_tax0_04_t: string;
  pay_tax0_05_t: string;
  pay_tax0_06_t: string;
  pay_tax0_07_t: string;
  pay_tax0_08_t: string;
  pay_tax1_01_t: string;
  pay_tax1_02_t: string;
  pay_tax1_03_t: string;
  pay_tax1_04_t: string;
  pay_tax1_05_t: string;
  pay_tax1_06_t: string;
}

/**
 * 컨트롤러
 * @window w_tb_pb300_02
 * @category 급여현황
 */
export class PayCurrentState extends PageComponent<PageProps, PayCurrentStateState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  updatedRows?: Array<PayCurrentStateModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    let year: string | number;

    today.getMonth() === 0 ? year = today.getFullYear() - 1
      : year = today.getFullYear(); // 년도

    let month: string | number;
    today.getMonth() === 0 ? month = 12
      : month = today.getMonth(); // 월

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      yearmon: `${year}${month}`,
      searchQuery: '',
      spjangcd: 'ZZ',
      offiyn: '%',
      restflag: '1',
      searchKukcds: '%',
      name: '',

      // data
      data: [],

      // trail
      pay_basic_tot: '0',
      pay_older_tot: '0',
      pay_night_tot: '0',
      pay_holiday_tot: '0',
      over_older_pay_tot: '0',
      over_holi_pay_tot: '0',
      over_duty_pay_tot: '0',
      pay_food_tot: '0',
      pay_serallow_tot: '0',
      pay_leader_tot: '0',
      pay_incentive_tot: '0',
      minuspay_late_tot_tot: '0',
      pay_yearly_tot: '0',
      tot_samt_tot: '0',
      rest_pay_tot: '0',
      tax_income_tot: '0',
      tax_health_tot: '0',
      insu_unem_tot: '0',
      insu_health_tot: '0',
      insu_longterm_tot: '0',
      insu_national_tot: '0',
      payamt_tax_tot: '0',
      payamt_tax2_tot: '0',
      insu_maf_tot: '0',
      tax_national_tot: '0',
      tax_suspense_tot: '0',
      tax_other_tot: '0',
      tot_tamt_tot: '0',
      tot_mamt_tot: '0',

      // title
      paytype: '0',
      pay_tax0_01_t: '0',
      pay_tax0_02_t: '0',
      pay_tax0_03_t: '0',
      pay_tax0_04_t: '0',
      pay_tax0_05_t: '0',
      pay_tax0_06_t: '0',
      pay_tax0_07_t: '0',
      pay_tax0_08_t: '0',
      pay_tax1_01_t: '0',
      pay_tax1_02_t: '0',
      pay_tax1_03_t: '0',
      pay_tax1_04_t: '0',
      pay_tax1_05_t: '0',
      pay_tax1_06_t: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상단의 부서구분
    const data = await api.dropdown('wf_dd_pz008');
    if (!data) return;
    this.setState({ kukcd: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        spjangcd: this.state.spjangcd,
        stmon: this.state.yearmon,
        offiyn: this.state.offiyn,
        restflag: this.state.restflag,
        kukcd: this.state.searchKukcds,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new PayCurrentStateModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
    });
    const index = await this.infinity?.retrieveTo(['no', 'perid'],
      [this.state.focused?.no, this.state.focused?.perid], type, true) || 0;
    if (this.state.data && this.state.data.length > index) {
      await this.grid.current?.setFocus(index);
      this.onRowFocusEvent(this.state.data[0]);
    }

    this.SS({
      pay_basic_tot: this.infinity?.box?.pay_basic_tot || '0',
      pay_older_tot: this.infinity?.box?.pay_older_tot || '0',
      pay_night_tot: this.infinity?.box?.pay_night_tot || '0',
      pay_holiday_tot: this.infinity?.box?.pay_holiday_tot || '0',
      over_older_pay_tot: this.infinity?.box?.over_older_pay_tot || '0',
      over_holi_pay_tot: this.infinity?.box?.over_holi_pay_tot || '0',
      over_duty_pay_tot: this.infinity?.box?.over_duty_pay_tot || '0',
      pay_food_tot: this.infinity?.box?.pay_food_tot || '0',
      pay_serallow_tot: this.infinity?.box?.pay_serallow_tot || '0',
      pay_leader_tot: this.infinity?.box?.pay_leader_tot || '0',
      pay_incentive_tot: this.infinity?.box?.pay_incentive_tot || '0',
      minuspay_late_tot_tot: this.infinity?.box?.minuspay_late_tot_tot || '0',
      pay_yearly_tot: this.infinity?.box?.pay_yearly_tot || '0',
      tot_samt_tot: this.infinity?.box?.tot_samt_tot || '0',
      rest_pay_tot: this.infinity?.box?.rest_pay_tot || '0',
      tax_income_tot: this.infinity?.box?.tax_income_tot || '0',
      tax_health_tot: this.infinity?.box?.tax_health_tot || '0',
      insu_unem_tot: this.infinity?.box?.insu_unem_tot || '0',
      insu_health_tot: this.infinity?.box?.insu_health_tot || '0',
      insu_longterm_tot: this.infinity?.box?.insu_longterm_tot || '0',
      insu_national_tot: this.infinity?.box?.insu_national_tot || '0',
      payamt_tax_tot: this.infinity?.box?.payamt_tax_tot || '0',
      payamt_tax2_tot: this.infinity?.box?.payamt_tax2_tot || '0',
      insu_maf_tot: this.infinity?.box?.insu_maf_tot || '0',
      tax_national_tot: this.infinity?.box?.tax_national_tot || '0',
      tax_suspense_tot: this.infinity?.box?.tax_suspense_tot || '0',
      tax_other_tot: this.infinity?.box?.tax_other_tot || '0',
      tot_tamt_tot: this.infinity?.box?.tot_tamt_tot || '0',
      tot_mamt_tot: this.infinity?.box?.tot_mamt_tot || '0',

      // title
      paytype: this.infinity?.box?.paytype || '0',
      pay_tax0_01_t: this.infinity?.box?.pay_tax0_01_t || '0',
      pay_tax0_02_t: this.infinity?.box?.pay_tax0_02_t || '0',
      pay_tax0_03_t: this.infinity?.box?.pay_tax0_03_t || '0',
      pay_tax0_04_t: this.infinity?.box?.pay_tax0_04_t || '0',
      pay_tax0_05_t: this.infinity?.box?.pay_tax0_05_t || '0',
      pay_tax0_06_t: this.infinity?.box?.pay_tax0_06_t || '0',
      pay_tax0_07_t: this.infinity?.box?.pay_tax0_07_t || '0',
      pay_tax0_08_t: this.infinity?.box?.pay_tax0_08_t || '0',
      pay_tax1_01_t: this.infinity?.box?.pay_tax1_01_t || '0',
      pay_tax1_02_t: this.infinity?.box?.pay_tax1_02_t || '0',
      pay_tax1_03_t: this.infinity?.box?.pay_tax1_03_t || '0',
      pay_tax1_04_t: this.infinity?.box?.pay_tax1_04_t || '0',
      pay_tax1_05_t: this.infinity?.box?.pay_tax1_05_t || '0',
      pay_tax1_06_t: this.infinity?.box?.pay_tax1_06_t || '0',
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      spjangcd: this.state.spjangcd,
      stmon: this.state.yearmon,
      offiyn: this.state.offiyn,
      restflag: this.state.restflag,
      kukcd: this.state.searchKukcds,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      spjangcd: this.state.spjangcd,
      stmon: this.state.yearmon,
      offiyn: this.state.offiyn,
      restflag: this.state.restflag,
      kukcd: this.state.searchKukcds,
    });
  }

  @action
  onRowFocusEvent(item: PayCurrentStateModel) {
    this.setState({ focused: item });
  }

  @action
  onFocusEvent() {
    this.state.data?.forEach((x: any, n) => {
      if (x.pernm.indexOf(this.state.name) !== -1) {
        this.grid.current?.setFocus(n);
      }
    });
  }

  render() {
    return (
      <PayCurrentStateTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
