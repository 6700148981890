import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  DepartmentModel,
  TermModel,
} from './models';
import { TabModel } from '../../../../models/component';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { LongReceivableTemplate } from './LongReceivable.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderDepartment,
  TabHeaderTerm,
} from './tabs';
import Today from '../../../../utils/time/Today';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Date6Calculator } from '../../../../utils/time';

export enum LongReceivableTabId {
  Term,
  Department,
}

export const LongReceivableTabTitles = [
  '기간별',
  '부서별',
];

export const LongReceivableTabModels = [
  TermModel,
  DepartmentModel,
];

export const LongReceivableTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const LongReceivableTabDataStateNames = [
  'terms',
  'departments',
];

export interface ReceivableState {
  // 기간별
  terms?: Array<TermModel>;

  // 부서별
  departments?: Array<DepartmentModel>;

  // sarch
  openChk?: Array<any>;
  focusedTab?: TabModel;
  focused?: TermModel;
  searchQuery: string;
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  yearmon: string;
  month3: number;
  pushTotalCheck: boolean;

  // modal
  textDetailModal: boolean;
  popupData: Array<TermModel>;

  // trail
  misamt_tot: string;
  rcvamt_tot: string;
  total: string;
  resuamt_tot: string;

  // popup trail
  shotmis_tot: string;
  longmis3_tot: string;
  mijamt_tot: string;
  iamt_tot: string;
  resuamt_tot2: string;
}

/**
 * 컨트롤러
 * @window w_input_da023w_03_view
 * @category 장기미수현황
 */
export class LongReceivable extends PageComponent<PageProps, ReceivableState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  tableInfinity?: InfinityRetrieve;

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = LongReceivableTabId;
    const titles = LongReceivableTabTitles;

    this.tabs = [
      new TabModel(id.Term.toString(), MdReorder, titles[id.Term]),
      new TabModel(id.Department.toString(), MdPublish, titles[id.Department]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      data: [],
      imports: [],
      popupData: [],
      searchQuery: '',
      billgubun: '%',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      resuck: '',
      pushTotalCheck: true,
      month3: 3,

      // trail
      misamt_tot: '0',
      rcvamt_tot: '0',
      total: '0',
      resuamt_tot: '0',

      // popup trail
      shotmis_tot: '0',
      longmis3_tot: '0',
      mijamt_tot: '0',
      iamt_tot: '0',
      resuamt_tot2: '0',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 기간별
      TabHeaderTerm,
      // 부서별
      TabHeaderDepartment,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 왼쪽 리스트
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ openChk: data.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });

    this.onTabChange(this.tabs[LongReceivableTabId.Term]);
    await this.onDataChange();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[LongReceivableTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${LongReceivableTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        month3: this.state.month3,
        searchQuery: this.state.searchQuery,
        billgubun: this.state.searchQuery,
        divicd: this.state.divicd,
        perid: this.state.perid,
        items: this.state.openChk,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[LongReceivableTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${LongReceivableTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        month3: this.state.month3,
        searchQuery: this.state.searchQuery,
        billgubun: this.state.searchQuery,
        divicd: this.state.divicd,
        perid: this.state.perid,
        items: this.state.openChk,
      },
    );
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => this.onDataChange());
  }

  @action
  async onDataChange() {
    // eslint-disable-next-line max-len
    const data = new Date6Calculator(this.state.enddate).add(-this.state.month3).toString() + this.state.stdate.slice(6, 8);

    this.setState({ stdate: data }, async () => {
      await this.onRetrieveEvent();
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        month3: this.state.month3,
        as_nm: this.state.searchQuery,
        billgubun: this.state.searchQuery,
        divicd: this.state.divicd,
        perid: this.state.perid,
        items: this.state.openChk,
      },
      (params) => api.fxExec(
        `${LongReceivableTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [LongReceivableTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[LongReceivableTabDataStateNames[i]],
              ...items.map((x: any) => new LongReceivableTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [LongReceivableTabDataStateNames[i]]: [],
        });
        await this.infinity?.retrieveAll();
        // @ts-ignore
        if (this.state[LongReceivableTabDataStateNames[i]] && this.state[LongReceivableTabDataStateNames[i]]?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [LongReceivableTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['actcd', 'misamt'],
        [this.state.focused?.actcd, this.state.focused?.misamt], type, true) || 0;
      // @ts-ignore
      // eslint-disable-next-line max-len
      this.state[LongReceivableTabDataStateNames[i]] && this.state[LongReceivableTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);
      this.SS({
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        rcvamt_tot: this.infinity?.box?.rcvamt_tot || '0',
        total: this.infinity?.box?.total || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  onRowFocusEvent(item: any) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ openChk: rows });
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });

    isOpen === true && this.modalRetrive();
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;

    // 현장코드 있으면 actflag '1'
    // 현장 없고 거래처만 있는경우 actflag '0'
    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_da023w_03',
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.focused?.cltcd,
        actcd: this.state.focused?.actcd,
        actflag: this.state.focused?.actcd === '' ? '0' : '1',
        items: this.state.openChk,
      });
    if (data?.items) {
      this.setState({
        popupData: data?.items || [],
        // popup trail
        shotmis_tot: data?.shotmis_tot || '0',
        longmis3_tot: data?.longmis3_tot || '0',
        mijamt_tot: data?.mijamt_tot || '0',
        iamt_tot: data?.iamt_tot || '0',
        resuamt_tot2: data?.resuamt_tot || '0',
      });
    } else {
      this.setState({
        popupData: [],
      });
    }
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck: checked,
      openChk: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @computed
  get tabIndex(): LongReceivableTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <LongReceivableTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
