import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  GridLayoutAdditionalHeader,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Goal } from './Goal';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';
import { Sum } from '../../../../utils/array';

export const Gubuns = [
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];

export const Contflags = [
  { key: '0', value: '관급' },
  { key: '1', value: '사급' },
  { key: '2', value: 'LH' },
];

/**
 * 화면
 * @window w_tb_da052w_08
 * @category 목표대비계획및실적
 */

export const GoalTemplate: React.FC<TemplateProps<Goal>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
              <span>년도</span>
              <DateTextBox
                value={scope.state.year}
                textAlign="center"
                format="####"
                onChange={(value) => setData({ year: value })}
                onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() - 1);
                      setData({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(`${scope.state.year}-01-01`);
                      date.setFullYear(date.getFullYear() + 1);
                      setData({ year: date.getFullYear().toString() },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
          </FlexLayout>

          <FlexLayout size={600}>
            <span>분기</span>
            <div>
              <OptionBox
                value={scope.state.bungi}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '1분기' },
                  { value: '2', remark: '2분기' },
                  { value: '3', remark: '3분기' },
                  { value: '4', remark: '4분기' },
                  { value: '5', remark: '월' },
                ]}
                onChange={(item) => setData({ bungi: item.value })}
                isDisabledTrackingStateChange={true}
              />
            </div>
            <FormatTextBox
              format="##"
              size={80}
              readonly={scope.state.bungi !== '5'}
              style={{ display: scope.state.bungi !== '5' ? 'none' : 'flex' }}
              value={scope.state.mon}
              onChange={(value) => setData({ mon: value })}
              textAlign="center"
            >
            </FormatTextBox>
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              textAlign="center"
              value={scope.state.divicd}
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.divinm}
              readonly={true}
              onChange={(value) => setData({ divinm: value })}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <GridLayoutAdditionalHeader
          ref={scope.additionalTitle}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
        >
          <FlexLayout
            justify="center"
            align="center"
            size={455}
          >
            목표금액(개인)
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
          >
            {Format.number(scope.state.data?.totamt11)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
          >
            {Format.number(scope.state.data?.totamt12)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
          >
            {Format.number(scope.state.data?.totamt13)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
          >
            {Format.number(scope.state.data?.totamt15)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
          >
            {Format.number(scope.state.data?.totamttot)}
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayoutAdditionalHeader
          ref={scope.additionalTitle2}
          height={Global.LAYOUT_TITLE_HEIGHT_1}
        >
          <FlexLayout
            justify="center"
            align="center"
            size={455}
            style={{ color: 'var(--color-blue)' }}
          >
            목표금액(총합)
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
            style={{ color: 'var(--color-blue)' }}
          >
            {Format.number(scope.state.data?.ttotamt11)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
            style={{ color: 'var(--color-blue)' }}
          >
            {Format.number(scope.state.data?.ttotamt12)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
            style={{ color: 'var(--color-blue)' }}
          >
            {Format.number(scope.state.data?.ttotamt13)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
            style={{ color: 'var(--color-blue)' }}
          >
            {Format.number(scope.state.data?.ttotamt15)}
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
            size={241}
            style={{ color: 'var(--color-blue)' }}
          >
            {Format.number(scope.state.data?.ttotamttot)}
          </FlexLayout>
        </GridLayoutAdditionalHeader>

        <GridLayout
          ref={scope.grid}
          headerHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
          header={[
            {
              id: 'divinm',
              text: '부서',
              width: 7,
              sum: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout>
                  합계
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '사원명',
              width: 7,
            },
            {
              id: 'divinm',
              text: '부서별그룹',
              isHidden: true,
              group: true,
              value: (x) => x.divinm,
            },
            {
              id: '',
              text: '계획실적',
              width: 3,
              sum: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실적
                  </FlexLayout>
                </FlexLayout>
              ),
              render: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    실적
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout>계획</FlexLayout>
                  <FlexLayout style={{ color: '#18E4E4' }}>실적</FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'planamt11',
              text: '유지보수',
              width: 6,
              sum: (x, data) => {
                const sumPlanamt11 = (Sum.byGroup(data, 'planamt11', x, ['divinm']));
                const sumMisamt11 = (Sum.byGroup(data, 'misamt11', x, ['divinm']));
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt11)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisamt11)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '11')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planamt11)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misamt11)}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: (data) => {
                const sumPlanamt11 = Sum.all(data, 'planamt11');
                const sumMisamt11 = Sum.all(data, 'misamt11');
                return (
                  <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumPlanamt11)}
                      </FlexLayout>
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: '#18E4E4' }}
                      >
                        {Format.number(sumMisamt11)}
                      </FlexLayout>
                    </FlexLayout>
                );
              },
            },
            {
              id: 'planyul11',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획율
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    실적율
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 3,
              sum: (x, data) => {
                const sumPlanyul11 = Math.ceil((Sum.byGroupFloat(data, 'planyul11', x, ['divinm']) / Sum.groupLength(data, 'planyul11', x, ['divinm'])) * 10) / 10;
                const sumMisyul11 = Math.ceil((Sum.byGroupFloat(data, 'misyul11', x, ['divinm']) / Sum.groupLength(data, 'misyul11', x, ['divinm'])) * 10) / 10;
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanyul11)} %
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisyul11)} %
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '11')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planyul11)} %
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misyul11)} %
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.data?.planyul11_tot}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#18E4E4' }}
                  >
                    {scope.state.data?.misyul11_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'planamt12',
              text: '수리공사',
              width: 6,
              sum: (x, data) => {
                const sumPlanamt12 = Sum.byGroup(data, 'planamt12', x, ['divinm']);
                const sumMisamt12 = Sum.byGroup(data, 'misamt12', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt12)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisamt12)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '12')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planamt12)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misamt12)}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: (data) => {
                const sumPlanamt12 = Sum.all(data, 'planamt12');
                const sumMisamt12 = Sum.all(data, 'misamt12');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt12)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#18E4E4' }}
                    >
                      {Format.number(sumMisamt12)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'planyul12',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획율
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    실적율
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 3,
              sum: (x, data) => {
                const sumPlanyul12 = Math.ceil((Sum.byGroupFloat(data, 'planyul12', x, ['divinm']) / Sum.groupLength(data, 'planyul12', x, ['divinm'])) * 10) / 10;
                const sumMisyul12 = Math.ceil((Sum.byGroupFloat(data, 'misyul12', x, ['divinm']) / Sum.groupLength(data, 'misyul12', x, ['divinm'])) * 10) / 10;
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanyul12)} %
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisyul12)} %
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '12')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planyul12)} %
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misyul12)} %
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.data?.planyul12_tot}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#18E4E4' }}
                  >
                    {scope.state.data?.misyul12_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'planamt13',
              text: '부품교체',
              width: 6,
              sum: (x, data) => {
                const sumPlanamt13 = Sum.byGroup(data, 'planamt13', x, ['divinm']);
                const sumMisamt13 = Sum.byGroup(data, 'misamt13', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt13)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisamt13)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '13')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planamt13)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misamt13)}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: (data) => {
                const sumPlanamt13 = Sum.all(data, 'planamt13');
                const sumMisamt13 = Sum.all(data, 'misamt13');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt13)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#18E4E4' }}
                    >
                      {Format.number(sumMisamt13)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'planyul13',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획율
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    실적율
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 3,
              sum: (x, data) => {
                const sumPlanyul13 = Math.ceil((Sum.byGroupFloat(data, 'planyul13', x, ['divinm']) / Sum.groupLength(data, 'planyul13', x, ['divinm'])) * 10) / 10;
                const sumMisyul13 = Math.ceil((Sum.byGroupFloat(data, 'misyul13', x, ['divinm']) / Sum.groupLength(data, 'misyul13', x, ['divinm'])) * 10) / 10;
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanyul13)} %
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisyul13)} %
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '13')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planyul13)} %
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misyul13)} %
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.data?.planyul13_tot}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#18E4E4' }}
                  >
                    {scope.state.data?.misyul13_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'planamt15',
              text: '리모델링',
              width: 6,
              sum: (x, data) => {
                const sumPlanamt15 = Sum.byGroup(data, 'planamt15', x, ['divinm']);
                const sumMisamt15 = Sum.byGroup(data, 'misamt15', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt15)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisamt15)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '15')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planamt15)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misamt15)}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: (data) => {
                const sumPlanamt15 = Sum.all(data, 'planamt15');
                const sumMisamt15 = Sum.all(data, 'misamt15');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanamt15)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#18E4E4' }}
                    >
                      {Format.number(sumMisamt15)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'planyul15',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획율
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    실적율
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 3,
              sum: (x, data) => {
                const sumPlanyul15 = Math.ceil((Sum.byGroupFloat(data, 'planyul15', x, ['divinm']) / Sum.groupLength(data, 'planyul15', x, ['divinm'])) * 10) / 10;
                const sumMisyul15 = Math.ceil((Sum.byGroupFloat(data, 'misyul15', x, ['divinm']) / Sum.groupLength(data, 'misyul13', x, ['divinm'])) * 10) / 10;
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumPlanyul15)} %
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumMisyul15)} %
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  isVertical={true}
                  onDoubleClick={() => scope.onModalEvent(true, '15')}
                >
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.planyul15)} %
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.misyul15)} %
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.data?.planyul15_tot}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#18E4E4' }}
                  >
                    {scope.state.data?.misyul15_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
            {
              id: 'tot_planamt',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    총 계획금액
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    총 실적금액
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 6,
              sum: (x, data) => {
                const sumTot_planamt = Sum.byGroup(data, 'tot_planamt', x, ['divinm']);
                const sumTot_misamt = Sum.byGroup(data, 'tot_misamt', x, ['divinm']);
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumTot_planamt)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumTot_misamt)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.tot_planamt)}
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(x.tot_misamt)}
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: (data) => {
                const sumTotPlanamt = Sum.all(data, 'tot_planamt');
                const sumTotMisamt = Sum.all(data, 'tot_misamt');
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#B818FF' }}
                    >
                      {Format.number(sumTotPlanamt)}
                    </FlexLayout>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumTotMisamt)}
                    </FlexLayout>
                  </FlexLayout>
                );
              },
            },
            {
              id: 'tot_planyul',
              text: (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획율
                  </FlexLayout>

                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    실적율
                  </FlexLayout>
                </FlexLayout>
              ),
              width: 3,
              sum: (x, data) => {
                const sumTot_planyul = Math.ceil((Sum.byGroupFloat(data, 'tot_planyul', x, ['divinm']) / Sum.groupLength(data, 'tot_planyul', x, ['divinm'])) * 10) / 10;
                const sumTot_misyul = Math.ceil((Sum.byGroupFloat(data, 'tot_misyul', x, ['divinm']) / Sum.groupLength(data, 'tot_misyul', x, ['divinm'])) * 10) / 10;
                return (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: '#7F7A2C' }}
                    >
                      {Format.number(sumTot_planyul)} %
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(sumTot_misyul)} %
                    </FlexLayout>
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#7F7A2C' }}
                  >
                    {Format.number(x.tot_planyul)} %
                  </FlexLayout>

                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {Format.number(String(x.tot_misyul))} %
                  </FlexLayout>
                </FlexLayout>
              ),
              trail: () => (
                <FlexLayout isVertical={true}>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: '#B818FF' }}
                  >
                    {scope.state.data?.tot_planyul_tot}
                  </FlexLayout>
                  <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    {scope.state.data?.tot_misyul_tot}
                  </FlexLayout>
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.goalList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <ModalView
        width={1200}
        height={600}
        isVisible={scope.state.modalState}
        onClose={() => scope.onModalEvent(false, '0')}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 10 }}
        >
          <LayoutTitle>{scope.state.focused?.pernm}의 {scope.state.modalTitle}</LayoutTitle>

          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'flag',
                  text: '구분',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.flag === '1' ? 'var(--color-blue)' : '' }}
                    >
                      {x.flag === '0' ? '계획' : '실적'}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'group',
                  text: '월별그룹',
                  isHidden: true,
                  group: true,
                  value: (x) => x.mon,
                },
                {
                  id: 'mon',
                  text: '월',
                  width: 10,
                  sum: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##"
                      value={x.mon}
                    />
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      총 {scope?.state.cnt_tot} 건
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##"
                      value={x.mon}
                    />
                  ),
                },
                {
                  id: 'actcd',
                  text: '현장코드',
                  width: 10,
                  sum: (x, data) => {
                    const sumActcd = Sum.groupLength(data, 'actcd', x, ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {sumActcd} 건
                      </FlexLayout>
                    );
                  },
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      계획 {scope?.state.flag0_tot} 건
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 18,
                  sum: (x, data) => {
                    const sumActnm = Sum.groupValue(data, 'flag', x, '0', ['group']);
                    const sumActnm2 = Sum.groupValue(data, 'flag', x, '1', ['group']);
                    return (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        <div>계획: {sumActnm} 건, &nbsp;</div>
                        <div style={{ color: 'var(--color-blue)' }}> 실적: {sumActnm2} 건</div>
                      </FlexLayout>
                    );
                  },
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      실적 {scope?.state.flag1_tot} 건
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'planamt',
                  text: '계획금액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope?.state.planamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.planamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '실적(매출)금액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {scope?.state.misamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'plan_remark',
                  text: '계획내용',
                  width: 18,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.plan_remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '실적내용',
                  width: 18,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.modalData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onModalEvent(false, '0')}>확인</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
