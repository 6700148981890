import React from 'react';
import {
  Global,
  GridLayoutHeader,
} from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Status } from '../Status';
import StatusDetailModel from '../models/StatusDetailModel';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';

type CustomGLHF<T = any, C = any> = (scope: C, type: number) => Array<GridLayoutHeader<T, C>>;

/**
 * 화면 > 그리드 레이아웃 B
 * @window w_tb_e101w_05
 * @category 월별 관리대수변동내역
 */
export const StatusDetailGridHeader: CustomGLHF<StatusDetailModel, Status> = (scope, type: number) => ([
  {
    id: 'state',
    text: '구분',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.state === '0' ? Global.STYLE_COLOR_BLUE : {}}
      >
        {x.state === '0' ? '보수' : '설치'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        align="center"
        justify="center"
      >
        합계
      </FlexLayout>
    ),
  },
  {
    id: 'contg',
    text: '계약',
    width: 10,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={{
          color: x.contg01 === '1' ? 'var(--color-blue)'
            : x.contg01 === '2' ? '#94D8F6'
              : x.contg === '03' ? 'var(--color-black)'
                : x.contg === '04' ? 'var(--color-red)'
                  : x.contg === '05' ? 'var(--color-black)'
                    : x.contg === '06' ? 'var(--color-black)'
                      : x.contg === '만료' ? 'var(--color-green)' : 'var(--color-violet)',
        }}
      >
        {
          x.contg === '01' ? '신규'
            : x.contg === '02' ? '연장'
              : x.contg === '03' ? '재계약'
                : x.contg === '04' ? '해지'
                  : x.contg === '05' ? '회수'
                    : x.contg === '06' ? '타사'
                      : x.contg === '만료' ? '만료' : '무상'
        }
      </FlexLayout>
    ),
    trail: (data) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {
          data === scope.state.dataModalExpire ? scope.state.cnt_tot
            : data === scope.state.dataModalFree ? scope.state.cnt2_tot
              : data === scope.state.dataModalNew ? scope.state.cnt3_tot : scope.state.cnt4_tot
        }
      </FlexLayout>
    ),
  },
  {
    id: 'enddate',
    text: (<React.Fragment>
      {
        type === 0 ? '만료일'
          : type === 1 ? '무상시작일'
            : type === 2 ? '계약일자' : '해지일'
      }
    </React.Fragment>),
    width: 14,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{
          color: type === 0 ? 'var(--color-green)'
            : type === 1 ? 'var(--color-violet)'
              : (type === 2 && x.contg01 === '1') ? 'var(--color-blue)'
                : (type === 2 && x.contg01 === '2') ? '#94D8F6' : 'var(--color-red)',
        }}
      >
        {
          type === 0 ? Date8.withDash(x.enddate)
            : type === 1 ? Date8.withDash(x.recedate)
              : type === 2 ? Date8.withDash(x.recedate) : Date8.withDash(x.recedate)}
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '코드',
    width: 10,
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 30,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
    trail: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x === scope.state.dataModalNew ? `유상전환 : ${scope.state.qty3_ch_tot}` : ''}
      </FlexLayout>
    ),
  },
  {
    id: 'qty',
    text: '대수',
    width: 8,
    trail: (data) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {
          data === scope.state.dataModalExpire ? scope.state.qty_tot
            : data === scope.state.dataModalFree ? scope.state.qty2_tot
              : data === scope.state.dataModalNew ? `신규: ${scope.state.qty3_new_tot}` : scope.state.qty4_tot
        }
      </FlexLayout>
    ),
  },
  {
    id: 'amt',
    text: '금액',
    width: 15,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="end"
      >
        {Format.number(x.amt)}
      </FlexLayout>
    ),
    trail: (data) => (
      <FlexLayout
        align="center"
        justify="right"
      >
        {
          data === scope.state.dataModalExpire ? scope.state.amt_tot
            : data === scope.state.dataModalFree ? scope.state.amt2_tot
              : data === scope.state.dataModalNew ? scope.state.amt3_tot : scope.state.amt4_tot
        }
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 10,
  },
  {
    id: 'contpernm',
    text: '계약자',
    width: 10,
  },
]);
