import * as React from 'react';
import { action } from 'mobx';
import { RefObject } from 'react';
import update from 'react-addons-update';
import { PageProps, PageToolEvents, RowUpdate } from '../../../../constants';
import { CalendarLayoutDataModel, InfinityRetrieve } from '../../../../models';
import ScheduleModel from './models/ScheduleModel';
import { ScheduleTemplate } from './Schedule.template';
import { PageComponent } from '../../../../utils';
import { TableLayout } from '../../../../components';
import { Date6 } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import { CalendarItem } from '../../../../utils/calendar/Calendar';

interface ScheduleState {
  stmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  actcd: string;
  actnm: string;
  equpcd: string;
  equpnm: string;

  schedules: Array<CalendarLayoutDataModel<ScheduleModel>>;
  data: Array<ScheduleModel>;

  isVisibleModal: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_e045
 * @category 사전점검스케쥴
 */
export class Schedule extends PageComponent<PageProps, ScheduleState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  table: RefObject<TableLayout> = React.createRef();

  rowsUpdated: Array<ScheduleModel> = [];

  tableFocus?: ScheduleModel;

  tableFocusIndex: number = 0;

  tabIndex: number = 0;

  dayRemarks: Record<string, { done: number, plan: number }>;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.dayRemarks = {};

    this.state = props.state || {
      stmon: Date6.make(),
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      actcd: '',
      actnm: '',
      equpcd: '',
      equpnm: '',

      schedules: [], // Calendar data
      data: [], // Modal table

      isVisibleModal: false,
    };
  }


  @action
  async onFirstOpenEvent() {
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    await this.SS({
      data: [],
    });

    const data = await api.retrieve({
      stmon: this.state.stmon || Date6.make(),
      divicd: this.state.divicd || '%',
      perid: this.state.perid || '%',
      actcd: this.state.actcd || '%',
      equpcd: this.state.equpcd || '%',
    });

    this.dayRemarks = {};

    if (data?.items) {
      data?.items?.forEach((x: any) => {
        if (!this.dayRemarks[x.plandate]) {
          this.dayRemarks[x.plandate] = { done: 0, plan: 0 };
        }

        if (x.planflag === '1') {
          this.dayRemarks[x.plandate].done += 1;
        } else {
          this.dayRemarks[x.plandate].plan += 1;
        }
      });

      this.SS({
        schedules: data.items.map((x: any) => new CalendarLayoutDataModel<ScheduleModel>(x.plandate, x)),
      });
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.schedules.length > 0, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stmon: this.state.stmon || Date6.make(),
      divicd: this.state.divicd || '%',
      perid: this.state.perid || '%',
      actcd: this.state.actcd || '%',
      equpcd: this.state.equpcd || '%',
      gubun: 'a4',
    });
  }


  // Calendar
  getItemPrefix(item: ScheduleModel): string {
    if (item.state === '1') {
      return '계획';
    }

    return '완료';
  }

  @action
  async onCalendarItemClicked(
    c: CalendarItem,
    items: Array<CalendarLayoutDataModel<ScheduleModel>>,
  ) {
    if (items.length < 1) {
      ConfirmWarning.show('오류', '계획된 점검이 없습니다');
      return;
    }

    this.tableFocusIndex = 0;
    this.tableFocus = undefined;
    this.rowsUpdated = [];

    const { actionStore: api } = this.props;
    const data = await api.retrieve({
      sub: 'w_popup_e045_callrender',
      divicd: this.state.divicd || '%',
      perid: this.state.perid || '%',
      plandate: c.data,
    });

    if (data?.items) {
      await this.SS({
        data: data.items.map((x: any) => new ScheduleModel(x)),
        isVisibleModal: true,
      });

      await this.table.current?.update(true);
      await this.table.current?.setFocus(0);
    }
  }


  // Modal
  @action
  async onRowFocusEvent(item: ScheduleModel | undefined, index: number) {
    if (!item) {
      this.tableFocus = item;
      this.tableFocusIndex = 0;
      return;
    }

    this.tableFocus = item;
    this.tableFocusIndex = index;
  }

  @action
  async onModalAdd() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      sub: 'w_popup_e045_callrender',
      plandate: this.tableFocus?.plandate,
    });

    if (data) {
      const one = new ScheduleModel(data, true);

      await this.SS({
        data: [one, ...this.state.data],
      });

      await this.table.current?.update(false);
      await this.table.current?.setFocus(0, 2);
    }
  }

  @action
  async onModalRemove() {
    if (!ConfirmWarning.assert(this.tableFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(
      this.tableFocus?.appgubun === '' || this.tableFocus?.appgubun === '131',
      '오류',
      '이미 결재가 상신되었습니다',
    )) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.tableFocus?.actnm} - ${this.tableFocus?.equpnm}`;

    if (await api.delete(text, {
      sub: 'w_popup_e045_callrender',
      actcd: this.tableFocus?.actcd,
      equpcd: this.tableFocus?.equpcd,
      seq: this.tableFocus?.seq,
    })) {
      await this.SS({
        data: update(this.state.data, {
          $splice: [[this.tableFocusIndex, 1]],
        }),
      });

      await this.table.current?.update(false);

      // 위에서 새로고침 없이 행 삭제 후 다음행 포커싱
      if (this.state.data.length) {
        const next = this.tableFocusIndex === 0 ? 0 : this.tableFocusIndex - 1;
        await this.table.current?.setFocus(next);
      } else {
        this.tableFocus = undefined;
        this.tableFocusIndex = 0;
      }
    }
  }

  @action
  async onModalSave() {
    const { actionStore: api } = this.props;
    if (await api.save(
      {
        sub: 'w_popup_e045_callrender',
        items: this.state.data,
      },
      false,
    )) {
      await this.table.current?.update(true);
    }
  }

  @action
  onChangeTable(rows: Array<any>, updatedRows: Array<any>) {
    this.rowsUpdated = updatedRows;
    this.setState({
      data: rows,
    });
  }

  @action
  async modalTableItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      ...this.state.data[this.tableFocusIndex],
      [key]: value,
      itemname: key,
      data: value,
      sub: 'w_popup_e045_callrender',
    }, this.tableFocus?.isNew);

    if (result) {
      rowUpdate(result);
    }
  }

  getItemColor(item: ScheduleModel): string {
    if (item.state === '1') {
      return 'var(--color-red)';
    }

    return 'var(--color-blue)';
  }


  render() {
    return <ScheduleTemplate
      scope={this}
    />;
  }
}
