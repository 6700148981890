
/**
 * 모델
 * @window w_tb_e101w_08
 * @category 계약마감통계
 */
export class ContractStatisticsModel {
  /**
   * 사업장코드
   *
   * **data type** varchar()
   */
  readonly spjangcd: string;

  /**
   * 사업장명
   *
   * ** data type** varchar()
   */
  readonly spjangnm: string;

  /**
   * 월
   *
   * **data type** varchar()
   */
  readonly mon: string;

  /**
   * 신규대수
   *
   * **data type** varchar()
   */
  readonly qty01: string;

  /**
   * 신규금액
   *
   * **data type** varchar()
   */
  readonly amt01: string;

  /**
   * 재계약 인상대수
   *
   * **data type** varchar()
   */
  readonly qty03_plus: string;

  /**
   * 재계약 인상금액
   *
   * **data type** varchar()
   */
  readonly amt03_plus: string;

  /**
   * 증가합계(신규 + 재게약)
   *
   * **data type** varchar()
   */
  readonly tot_plus: string;

  /**
   * 재계약 감소대수
   *
   * **data type** varchar()
   */
  readonly qty03_minus: string;

  /**
   * 재계약 감소금액
   *
   * **data type** varchar()
   */
  readonly amt03_minus: string;

  /**
   * 해지대수
   *
   * **data type** varchar()
   */
  readonly qty_cls: string;

  /**
   * 해지금액
   *
   * **data type** varchar()
   */
  readonly amt_cls: string;

  /**
   * 감소합계(재계약 + 해지)
   *
   * **data type** varchar()
   */
  readonly tot_minus: string;

  /**
   * 총증감액
   *
   * **data type** varchar()
   */
  readonly tot_amt: string;

  /**
   * chk
   *
   * **data type** varchar()
   */
  readonly chk: string;

  constructor(data: any = {}) {
    this.chk = data.chk || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.mon = data.mon || '';
    this.qty01 = data.qty01 || '';
    this.amt01 = data.amt01 || '';
    this.qty03_plus = data.qty03_plus || '';
    this.amt03_plus = data.amt03_plus || '';
    this.tot_plus = data.tot_plus || '';
    this.qty03_minus = data.qty03_minus || '';
    this.amt03_minus = data.amt03_minus || '';
    this.qty_cls = data.qty_cls || '';
    this.amt_cls = data.amt_cls || '';
    this.tot_minus = data.tot_minus || '';
    this.tot_amt = data.tot_amt || '';
  }
}
