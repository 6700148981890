/**
 * 모델
 * @window w_tb_ca642w_15
 * @category 월별 손익계산서 매입 팝업창
 */
export class MijModalModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(8)
   */
  spjangcd: string;

  /**
   * 계정코드
   *
   * **data type** varchar(8)
   */
  artcd: string;

  /**
   * 계정명
   *
   * **data type** varchar(8)
   */
  artnm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(8)
   */
  cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(8)
   */
  cltnm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  gubun: string;

  /**
   * 그룹계정 코드
   *
   * **data type** varchar(8)
   */
  gartcd: string;

  /**
   * 그룹계정명
   *
   * **data type** varchar(8)
   */
  gartnm: string;

  /**
   * 매입금액
   *
   * **data type** varchar(8)
   */
  mijamt: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  mijcltcd: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  mijcltnm: string;

  /**
   * 매입일자
   *
   * **data type** varchar(8)
   */
  mijdate: string;

  /**
   * 매입번호
   *
   * **data type** varchar(8)
   */
  mijnum: string;

  /**
   *  프로젝트명
   *
   * **data type** varchar(8)
   */
  projnm: string;

  /**
   * 프로젝트번호
   *
   * **data type** varchar(8)
   */
  projno: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  proof: string;

  /**
   * 적요
   *
   * **data type** varchar(8)
   */
  remark: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  taxreclafi: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  wkgubun: string;

  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.artcd = data.artcd || '';
    this.artnm = data.artnm || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.gartcd = data.gartcd || '';
    this.gartnm = data.gartnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.gubun = data.gubun || '';
    this.mijamt = data.mijamt || '';
    this.mijcltcd = data.mijcltcd || '';
    this.mijcltnm = data.mijcltnm || '';
    this.mijdate = data.mijdate || '';
    this.mijnum = data.mijnum || '';
    this.projnm = data.projnm || '';
    this.projno = data.projno || '';
    this.proof = data.proof || '';
    this.remark = data.remark || '';
    this.taxreclafi = data.taxreclafi || '';
    this.wkgubun = data.wkgubun || '';
  }
}
