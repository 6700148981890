import { computed } from 'mobx';

/**
 * 모델
 * 그리드 화면
 * @window w_tb_e611_type
 * @category 승강기제원정보[이력카드]
 */
export class InfoModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 호기명
   *
   * **data type** varchar(2)
   */
  equpnm: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(100)
   */
  elno: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpnm = data.equpnm || '';
    this.elno = data.elno || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
