import { GridLayoutHeader } from '../../../../../constants';
import { UserERPModel } from '../models';
import { User } from '../User';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { Sum } from '../../../../../utils/array';


// 줄 정렬을 위한 함수
// function pad(n: string | any[], width: number) {
//   // eslint-disable-next-line no-param-reassign
//   n += '';
//   return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
// }
// 가족
const UserERP: GridLayoutHeader<UserERPModel, User>[] = [
  {
    id: 'userid',
    text: 'ID',
    width: 14,
    group: true,
  },
  {
    id: 'divinm',
    text: '부서명',
    width: 14,
    group: true,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총 계
      </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '사용자명',
    width: 14,
    group: true,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'winnm',
    text: '사용내역',
    width: 40,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.winnm}
      </FlexLayout>
    ),
  },
  {
    id: 'cnt',
    text: '건수',
    width: 20,
    sum: (x, data) => {
      const sumCase = Sum.byGroup(data, 'cnt', x, ['custcd', 'userid']);
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {(sumCase)}
        </FlexLayout>
      );
    },
    trail: (data) => {
      const sumCase = Sum.all(data, 'cnt');
      return (
        <FlexLayout
          justify="center"
          align="center"
        >
          {(sumCase)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {/* {pad(x.cnt, 2)} */}
        {x.cnt}
      </FlexLayout>
    ),
  },
];

export default UserERP;
