import { TLHF } from '../../../../../constants';
import { DateTextBox, TextBox, FlexLayout } from '../../../../../components';
import { Plan } from '../Plan';
import PlanActModel from '../models/PlanActModel';
import { SearchBinding } from '../../../../../models';

/**
 * 화면 > 달력 > 좌측 현장 그리드 레이아웃
 * @window w_tb_e035
 * @category 점검계획등록
 */
export const PlanActTableHeader: TLHF<PlanActModel, Plan> = (scope) => ([
  {
    id: 'plandate',
    text: '점검일자',
    width: 25,
    render: (x) => (
      <DateTextBox
        textAlign="center"
        format="####-##-##"
        value={x.plandate}
        readonly={x.state === '2'}
        transparent={true}
        onChange={(v) => {
          scope.updateAct('plandate', v);
        }}
      />
    ),
  },
  {
    id: 'actcd',
    text: '현장코드',
    width: 16,
    color: 'var(--color-red)',
    render: (x, rowUpdate) => (
      <TextBox
        value={x.actcd}
        textAlign="center"
        onChange={(v) => rowUpdate({ actcd: v })}
        readonly={!x.isNew}
        transparent={true}
        bindSearchModal={new SearchBinding(
          scope.props.modalStore,
          'TB_E601_1',
          {},
          true,
          (item) => {
            rowUpdate({
              actcd: item.cd,
              actnm: item.cdnm,
            });
            scope.updateNewAct(rowUpdate, x.plandate, item.cd);
          },
        )}
      />
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 60,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
]);
