import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  SearchLayout,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { TaxInvoice } from './TaxInvoice';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

const billkinds = [
  { value: '1', remark: '발행' },
  { value: '0', remark: '미발행' },
];

const taxclss = [
  { value: '1', remark: '포함' },
  { value: '0', remark: '별도' },
];

const taxgubuns = [
  { value: '01', remark: '과세' },
  { value: '02', remark: '영세' },
  { value: '03', remark: '면세' },
];

/**
 * 화면
 * @window w_tb_e050w_03
 * @category 세금계산서일괄출력
 */
export const TaxInvoiceTemplate: React.FC<TemplateProps<TaxInvoice>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => setData({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={350}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={200}>
          <span>구분</span>
          <ComboBox
            value={scope.state.searchGubun}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={scope.state.gubun?.map((y) => new ComboBoxModel(y.artcd, y.artnm))}
            onSelect={(option) => setData({ searchGubun: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </SearchLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'chk',
            text: (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={scope.state.pushTotalCheck}
                  onChange={async (value) => scope
                    .updateCheckAllToggle(value)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
            width: 50,
            render: (x, rowUpdate) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin
                  value={x.chk === '1'}
                  onChange={(value) => {
                    rowUpdate({ chk: value ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>
            ),
          },
          {
            id: 'misdate',
            text: '매출일자',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.misdate)}
              </FlexLayout>
            ),
          },
          {
            id: 'misnum',
            text: '번호',
            width: 50,
          },
          {
            id: 'pubnum',
            text: '지로번호',
            width: 100,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="######-####"
                value={x.pubnum}
              />
            ),
          },
          {
            id: 'gubun',
            text: '매출분류',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope?.state.gubun?.filter((y) => y.artcd === x.gubun)[0]?.artnm}
              </FlexLayout>
            ),
          },
          {
            id: 'cltnm',
            text: '거래처명',
            width: 300,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.cltnm}
                onChange={(value) => rowUpdate({ cltnm: value })}
              />
            ),
          },
          {
            id: 'remark',
            text: '적요',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'amt',
            text: '공급가액',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.amt)}
              </FlexLayout>
            ),
          },
          {
            id: 'addamt',
            text: '부가세',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.addamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'misamt',
            text: '합계',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="right"
                align="center"
              >
                {Format.number(x.misamt)}
              </FlexLayout>
            ),
          },
          {
            id: 'billkind',
            text: '계산서발행',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {billkinds.filter((y) => y.value === x.billkind)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {Format.number(x.actnm)}
              </FlexLayout>
            ),
          },
          {
            id: 'zipcd',
            text: '우편번호',
            width: 70,
            render: (x, rowUpdate, ref) => (
              <TextBox
                textAlign="center"
                ref={ref}
                value={x.zipcd}
                onChange={(value) => rowUpdate({ zipcd: value })}
              />
            ),
          },
          {
            id: 'cltadres',
            text: '주소',
            width: 200,
            render: (x, rowUpdate, ref) => (
              <TextBox
                ref={ref}
                value={x.cltadres}
                onChange={(value) => rowUpdate({ cltadres: value })}
              />
            ),
          },
          {
            id: 'taxcls',
            text: '부가세여부',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {taxclss.filter((y) => y.value === x.taxcls)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'yyyymm',
            text: '귀속년월',
            width: 70,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##"
                value={x.yyyymm}
              />
            ),
          },
          {
            id: 'taxgubun',
            text: '과세구분',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {taxgubuns.filter((y) => y.value === x.taxgubun)[0]?.remark}
              </FlexLayout>
            ),
          },
          {
            id: 'jirogubun',
            text: '지로구분',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {billkinds.filter((y) => y.value === x.jirogubun)[0]?.remark}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.data}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
