import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  FlexLayout,
  TextBox,
  GridLayout,
  SearchLayout,
  FormatTextBox,
  DateTextBox, FormatNumberTextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { Free } from './Free';
import { SearchBinding } from '../../../../models';
import { Date6Calculator } from '../../../../utils/time';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e101w_03
 * @category 무상현장관리현황
 */
export const FreeTemplate: React.FC<TemplateProps<Free>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={290}>
          <span>설치-&gt;보수년월</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ stmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span>만료년월</span>
          <DateTextBox
            value={scope.state.endmon}
            textAlign="center"
            format="####년 ##월"
            onChange={(value) => setData({ endmon: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                setData({ endmon: new Date6Calculator(scope.state.endmon).add(-1).toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                setData({ endmon: new Date6Calculator(scope.state.endmon).add().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            weight={1.4}
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>무상담당자</span>
          <TextBox
            weight={1.4}
            value={scope.state.mupernm}
            textAlign="center"
            onChange={(value) => setData({ mupernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ muperid: item.cd, mupernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.muperid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'actcd',
            text: '현장코드',
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                합계
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 200,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.data.length}건
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'qty',
            text: '대수',
            width: 60,
            trail: (data) => {
              const sumQty = Sum.all(data, 'qty');
              return (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Format.number(sumQty)}
                </FlexLayout>
              );
            },
            render: (x) => (
              <FlexLayout
                justify="qty"
                align="center"
              >
                {x.qty}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자',
            width: 80,
          },
          {
            id: 'mupernm',
            text: '무상계약자',
            width: 80,
          },
          {
            id: 'contpernm',
            text: '계약자',
            width: 80,
          },
          {
            id: 'stdate',
            text: '시작일',
            width: 90,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.stdate}
              />
            ),
          },
          {
            id: 'enddate',
            text: '종료일',
            width: 90,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.enddate}
              />
            ),
          },
          {
            id: 'amt',
            text: '보수료',
            width: 90,
            trail: (data) => {
              const sumAmt = Sum.all(data, 'amt');
              return (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(sumAmt)}
                </FlexLayout>
              );
            },
            render: (x) => (
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={x.amt}
              />
            ),
          },
          {
            id: 'contg',
            text: '계약분류',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.contgs.filter((y) => y.code === x.contg)[0]?.cnam}
              </FlexLayout>
            ),
          },
          {
            id: 'contgubun',
            text: '계약구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.contgubuns.filter((y) => y.code === x.contgubun)[0]?.cnam}
              </FlexLayout>
            ),
          },
          {
            id: 'mgubun',
            text: '유무상',
            width: 60,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.mgubun === '0' ? '무상' : '유상'}
              </FlexLayout>
            ),
          },
          {
            id: 'senddate',
            text: '설치->보수일',
            width: 90,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.senddate}
              />
            ),
          },
          {
            id: 'address',
            text: '주소',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.address}
              </FlexLayout>
            ),
          },
          {
            id: 'actpernm',
            text: '현장담당자',
            width: 100,
          },
          {
            id: 'hp',
            text: '담당전화번호',
            width: 130,
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.data}
      />
    </FlexLayout>
  );
};
