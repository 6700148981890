import { GLHF } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { TransportationFee } from '../TransportationFee';
import { Format } from '../../../../../utils/string';
import Sum from '../../../../../utils/array/Sum';
import { TransportationFeeModel } from '../models/TransportationFee.model';

/**
 * 화면 > 탭  > 그리드
 * @window w_tb_ca640_pa105_02w
 * @category 월교통비/통행료내역
 */
export const RecentGrid: GLHF<TransportationFeeModel, TransportationFee> = (scope) => ([
  {
    id: 'divinm',
    text: '부서명',
    width: 9,
    trail: (_) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '부서별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.divinm,
  },
  {
    id: 'pernm',
    text: '사원명',
    width: 8,
  },
  {
    id: 'artnm',
    text: '항목명',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.artnm}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt05',
    text: (<FlexLayout>{`${scope?.state.bemon05.slice(0, 4)}-${scope?.state.bemon05.slice(4, 6)}`}</FlexLayout>),
    width: 10,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.besamt05_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBesamt05 = Sum.byGroup(data, 'besamt05', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBesamt05)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.besamt05)}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt04',
    text: (<FlexLayout>{`${scope?.state.bemon04.slice(0, 4)}-${scope?.state.bemon04.slice(4, 6)}`}</FlexLayout>),
    width: 10,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.besamt04_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBesamt04 = Sum.byGroup(data, 'besamt04', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBesamt04)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.besamt04)}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt03',
    text: (<FlexLayout>{`${scope?.state.bemon03.slice(0, 4)}-${scope?.state.bemon03.slice(4, 6)}`}</FlexLayout>),
    width: 10,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.besamt03_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBesamt03 = Sum.byGroup(data, 'besamt03', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBesamt03)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.besamt03)}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt02',
    text: (<FlexLayout>{`${scope?.state.bemon02.slice(0, 4)}-${scope?.state.bemon02.slice(4, 6)}`}</FlexLayout>),
    width: 10,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.besamt02_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBesamt02 = Sum.byGroup(data, 'besamt02', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBesamt02)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.besamt02)}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt01',
    text: (<FlexLayout>{`${scope?.state.bemon01.slice(0, 4)}-${scope?.state.bemon01.slice(4, 6)}`}</FlexLayout>),
    width: 10,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.besamt01_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumBesamt01 = Sum.byGroup(data, 'besamt01', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumBesamt01)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.besamt01)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: (<FlexLayout>{`${scope?.state.mon.slice(0, 4)}-${scope?.state.mon.slice(4, 6)}`}</FlexLayout>),
    width: 13,
    trail: () => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.samt_tot}
      </FlexLayout>
    ),
    sum: (x, data) => {
      const sumSamt = Sum.byGroup(data, 'samt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
]);
