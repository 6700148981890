import * as React from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import { RefObject } from 'react';
import {
  Category,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import ProposalModel from './models/ProposalModel';
import { ProposalTemplate } from './Proposal.template';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import { ConfirmFail, ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';
import {
  Date8,
  DateStabilizer,
} from '../../../../utils/time';
import { FileReader, FileSelector } from '../../../../utils/file';
import { ImageResizer } from '../../../../utils/image';

export enum ProposalImages {
  IMG,
  IMG2,
}

const imgFunctionNames = [
  'tb_pic',
  'tb_pic2',
];

// 업로드 진행바 퍼센트
const imgStatePercentNames = [
  'percentImage',
  'percentImage2',
];

interface ProposalState {
  stdate: string;
  enddate: string;
  state: string;
  searchQuery: string;

  parts: Array<any>;

  data: Array<ProposalModel>;
  content: ProposalModel;
  lastSaveData: ProposalModel;
  img: ArrayBuffer;
  img2: ArrayBuffer;
  percentImage?: number;
  percentImage2?: number;
}

/**
 * 컨트롤러
 * @window w_tb_e448
 * @category 제안서등록
 */
export class Proposal extends PageComponent<PageProps, ProposalState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  gridFocus?: ProposalModel;

  gridFocusIndex: number = 0;

  tabIndex: number = 0;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      stdate: `${Date8.make().substr(0, 6)}01`,
      enddate: Date8.make(),
      state: '%',
      searchQuery: '',

      data: [],
      content: new ProposalModel(),
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 부품파트 목록
    const data = await api.dropdown('wf_dd_ca510_013_01');
    if (!data) return;
    this.setState({ parts: data.items });
    this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        state: this.state.state,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new ProposalModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length) {
          this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.content;
    await this.SS({
      data: [],
    });

    const index = await this.infinity?.retrieveTo(['propodate', 'proponum'],
      [lastSelected?.propodate, lastSelected?.proponum], type, true) || 0;
    if (this.state.data && this.state.data.length > index) {
      this.grid.current?.setFocus(index);
    }
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const one = new ProposalModel(data, true);

      await this.SS({
        content: one,
        lastSaveData: one,
        data: [one, ...this.state.data],
      });

      await this.grid.current?.setFocus(0);
    }
  }

  @action
  async onSaveEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save(
      this.state.content,
      this.state.content.isNew,
    )) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.content.propodate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        content: new ProposalModel(),
      });
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `접수일자: ${this.gridFocus?.propodate} 제안번호: ${this.gridFocus?.proponum}`;

    if (await api.delete(text, this.state.content)) {
      await this.SS({
        data: update(this.state.data, {
          $splice: [[this.gridFocusIndex, 1]],
        }),
      });

      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  async imageRequest() {
    const { actionStore: api } = this.props;
    const blob = await api.fxBinary(
      'tb_pic_retrieve',
      {
        propodate: this.state.content.propodate,
        proponum: this.state.content.proponum,
      },
    );

    // @ts-ignore
    this.setState({
      img: blob,
    });
  }

  async imageRequest2() {
    const { actionStore: api } = this.props;
    const blob = await api.fxBinary(
      'tb_pic2_retrieve',
      {
        propodate: this.state.content.propodate,
        proponum: this.state.content.proponum,
      },
    );

    // @ts-ignore
    this.setState({
      img2: blob,
    });
  }

  async imageUpload(i: number, filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    // @ts-ignore
    this.setState({
      [imgStatePercentNames[i]]: 1,
    }, async () => {
      const tempInfo = await api.tempUpload(file, filename, (e) => {
        const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
        // @ts-ignore
        this.setState({
          [imgStatePercentNames[i]]: percent,
        });
      });

      if (await api.exec(
        Category.GENERAL,
        `${imgFunctionNames[i]}_save`,
        {
          custcd: publicStore.user.custcd,
          fileext: 'png',
          propodate: this.state.content.propodate,
          proponum: this.state.content.proponum,
          tempfile: tempInfo.data,
        },
        false,
      )) {
        await this.imageRequest();
        await this.imageRequest2();
        ConfirmSuccess.show('저장', '서버에 잘 저장했습니다.');
      }

      // @ts-ignore
      setTimeout(() => this.setState({
        [imgStatePercentNames[i]]: 0,
      }), 1000);
    });
  }

  @action
  async imageDelete(i: number) {
    const { actionStore: api } = this.props;
    if (await api.exec(
      Category.MAINTENANCE,
      `${imgFunctionNames[i]}_delete`,
      {
        propodate: this.state.content.propodate,
        proponum: this.state.content.proponum,
      },
    )) {
      await this.imageRequest();
      await this.imageRequest2();
      ConfirmSuccess.show('저장', '삭제하였습니다.');
    }
  }

  @action
  async imageSelect(i: number) {
    try {
      const files = await FileSelector.single(true);
      const base64 = await FileReader.base64(files[0]);
      const resized = await ImageResizer.byRoughSizeToFile(base64, 3145728); // 3mb
      this.imageUpload(i, files[0].name, resized);
    } catch {
      ConfirmFail.show('오류', '이미지 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      propodate: this.state.content.propodate,
      proponum: this.state.content.proponum,
      gubun: this.state.content.gubun,
      part: this.state.content.part,
    });
  }


  @action
  async onRowFocusEvent(item: ProposalModel | undefined, index: number) {
    if (!item) {
      await this.SS({ content: new ProposalModel() });
      this.gridFocus = item;
      this.gridFocusIndex = 0;
      return;
    }

    await this.SS({ content: item });
    this.gridFocus = item;
    this.gridFocusIndex = index;

    if (this.state.content.isNew) {
      await this.SS({
        content: this.state.lastSaveData,
        img: new ArrayBuffer(0),
        img2: new ArrayBuffer(0),
      });
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', this.gridFocus);

    if (data) {
      this.SS({
        content: new ProposalModel(data),
      });
      await this.imageRequest();
      await this.imageRequest2();
    }
  }

  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    this.onRowFocusEvent(this?.gridFocus, this.gridFocusIndex);
  }


  render() {
    return <ProposalTemplate
      scope={this}
    />;
  }
}
