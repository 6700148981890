import * as React from 'react';
import style from './LoaderButton.module.scss';
import { JoinClassName } from '../../../utils/string';
import { BUTTON_THEMES, ButtonActions } from '../Button/Button';

export interface LoaderButtonProps extends ButtonActions {
  className?: any;
  style?: any;
  isFull?: boolean;
  isIcon?: boolean;
  theme?: BUTTON_THEMES,
  percent?: number,
}

export class LoaderButton extends React.Component<LoaderButtonProps> {
  render() {
    const percent = (this.props.percent || 0);

    return (
      <div
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.isFull ? style.full : false,
          this.props.isIcon ? style.onlyIcon : false,
          this.props.theme === BUTTON_THEMES.RED ? style.red : false,
        ])}
      >
        <button
          onClick={() => this.props.onClick && this.props.onClick()}
          style={{
            fontSize: 12,
            ...this.props.style,
          }}
        >
          <div
            className={JoinClassName.make([
              style.button__body,
              percent > 0 ? style.loading : null,
            ])}
          >
            {this.props.children}
          </div>
        </button>

        <div
          className={style.loader__background}
          style={{
            transform: `translateY(${100 - percent}%)`,
          }}
        />

        {percent > 0 && <div
          className={style.loader}
          style={{
            color: percent < 50 ? 'var(--color-blue)' : '#FFFFFF',
          }}
        >
          {percent}%
        </div>}
      </div>
    );
  }
}
