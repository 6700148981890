import { GridLayoutHeader } from '../../../../../constants';
import { EnrollmentReceivableModel } from '../models';
import { Enrollment } from '../Enrollment';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';


// 미수내역
const Receivable: GridLayoutHeader<EnrollmentReceivableModel, Enrollment>[] = [
  {
    id: 'cltnm',
    text: '거래처명',
    width: 150,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'misdate',
    text: '발생일자',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 50,
  },
  {
    id: 'remark',
    text: '비고',
    width: 150,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '청구금액',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'rcvamt',
    text: '입금액',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.rcvamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'resuamt',
    text: '미수잔액',
    width: 80,
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.resuamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'longmisnm',
    text: '장기미수 구분',
    width: 100,
  },
];

export default Receivable;
