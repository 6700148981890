import * as React from 'react';
import { Global, TemplateProps } from '../../../../constants';
import { Enroll, EnrollItemChangeTypes } from './Enroll';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { ComboBox, ComboBoxModel } from '../../../../components/forms/ComboBox';
import { TextBox } from '../../../../components/forms/TextBox';
import { SearchBinding } from '../../../../models/common';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { Button } from '../../../../components/forms/Button';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { TableLayout } from '../../../../components/layout/TableLayout';

const gubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

/**
 * 화면
 * @window w_tb_e063
 * @category 업무결재라인등록
 */

export const EnrollTemplate: React.FC<TemplateProps<Enroll>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span>문서코드</span>
          <ComboBox
            value={scope.state.papercd}
            data={scope.state.papercds?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
            onSelect={(option) => scope.setState({ papercd: option.value },
              () => { scope.onRetrieveEvent(); })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onAllCopy()}>
          <div style={{ width: 80 }}>전체복사</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'perid',
              text: '사원코드',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.perid}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '사원명',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'papercd',
              text: '문서명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='left'
                >
                  {scope.state.papercds?.filter((y) => y.com_code === x.papercd)[0]?.com_cnam}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.enrollList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />

        <FlexLayout isVertical={true} weight={2}>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="사원코드" isNeed={true} size={200}>
              <TextBox
                value={model.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
                readonly={model.new === '0'}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model.pernm}
                weight={1.5}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서코드" size={200}>
              <ComboBox
                value={model.papercd}
                readonly={model.new !== '1'}
                data={scope.state.papercds?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                onSelect={(option) => {
                  scope.itemChanged(EnrollItemChangeTypes.PAPERCD, option.value);
                }}
              />
            </LabelBox>
            <LabelBox title="비고">
              <TextBox
                value={model.remark}
                onChange={(value) => setData({ remark: value, isChanged: 'true' })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

            <LayoutTitle size={215}>결재라인등록</LayoutTitle>
            <FlexLayout justify='right'>
              <Button
                style={{ height: '25px', width: '60px' }}
                theme={BUTTON_THEMES.BLUE}
                onClick={() => scope.onNewEvent2()}
              >
                추가
              </Button>
              <Button
                style={{ height: '25px', width: '60px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.onDeleteEvent2()}
              >
                삭제
              </Button>
            </FlexLayout>
          </FlexLayout>

          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'seq',
                text: '순번',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.seq}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcperid',
                text: '결재자코드',
                width: 100,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.kcperid}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ kcperid: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        rowUpdate({
                          kcperid: item.cd,
                          kcpernm: item.cdnm,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'kcpernm',
                text: '결재자명',
                width: 100,
              },
              {
                id: 'gubun',
                text: '구분',
                width: 50,
                render: (x, rowUpdate) => (
                  <ComboBox
                    textAlign="center"
                    style={{
                      color: x.gubun === '101'
                        ? 'var(--color-blue)'
                        : x.gubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                    value={x.gubun}
                    data={gubuns.map((y) => new ComboBoxModel(y.value, y.remark))}
                    onSelect={(option) => rowUpdate({ gubun: option.value })}
                  />
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 400,
                render: (x, rowUpdate, refs) => (
                  <TextBox
                    refs={refs}
                    value={x.remark}
                    textAlign="left"
                    onChange={(value) => rowUpdate({ remark: value })}
                  />
                ),
              },
            ]}
            data={scope.state.enrollDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
