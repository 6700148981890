import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { CashModel } from '../models';
import { OneDayDeadline } from '../OneDayDeadline';


// 현금내역
const Cash: GridLayoutHeader<CashModel, OneDayDeadline>[] = [
  {
    id: 'misdate',
    text: '발생일자',
    width: 13,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        합 계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.misdate)}
      </FlexLayout>
    ),
  },
  {
    id: 'misnum',
    text: '번호',
    width: 10,
    trail: (_, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.total}건
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '적요',
    width: 30,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'cltnm',
    text: '지급처명',
    width: 20,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.cltnm}
      </FlexLayout>
    ),
  },
  {
    id: 'misamt',
    text: '수금액',
    width: 13,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.misamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.misamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'mijamt',
    text: '지급액',
    width: 13,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.mijamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.mijamt)}
      </FlexLayout>
    ),
  },
];

export default Cash;
