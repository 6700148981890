import { computed } from 'mobx';

/**
 * 모델 - Header
 * @window w_tb_da006
 * @category 수주서등록
 */
export default class EnrollmentModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 수주일자
   *
   * **data type** varchar(8)
   */
  readonly orddate: string;

  /**
   * 수주번호
   *
   * **data type** varchar(4)
   */
  readonly ordnum: string;

  /**
   * 고객번호
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 고객명
   *
   * **data type** varchar(20)
   */
  readonly cltnm: string;

  /**
   * 납기일자
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   * 담당자명
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   * 사용자명
   *
   * **data type** varchar(10)
   */
  readonly pernm: string;

  /**
   * 부서코드
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   * 부서명
   *
   * **data type** varchar(10)
   */
  readonly divinm: string;

  /**
   * 부가세구분
   *
   * **data type** varchar(2)
   */
  readonly taxcls: string;

  /**
   * 모름
   *
   * **data type** varchar(2)
   */
  readonly setcls: string;

  /**
   * 하자보수기간
   *
   * **data type** varchar(2)
   */
  readonly givedd: string;

  /**
   * 하자보수기간 우측
   *
   * **data type** varchar(1)
   */
  readonly giveflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly domcls: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly moncls: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly sunflag: string;

  /**
   * 출고구분
   *
   * **data type** varchar(1)
   */
  readonly delflag: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly delway: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly spdesc: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly indesc: string;

  /**
   * 특이사항
   *
   * **data type** varchar(100)
   */
  readonly worknm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly estdate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly estnum: string;

  /**
   * 비고
   *
   * **data type** varchar(60)
   */
  readonly remark: string;

  /**
   * 공사자아이디
   *
   * **data type** varchar(10)
   */
  readonly compperid: string;

  /**
   * 공사자부서
   *
   * **data type** varchar(10)
   */
  readonly compdivicd: string;

  /**
   * 공사일
   *
   * **data type** varchar(8)
   */
  readonly compdate: string;

  /**
   * 상태
   *
   * **data type** varchar(3)
   */
  readonly state: string;

  /**
   * 완료구분
   *
   * **data type** varchar(1)
   */
  readonly compflag: string;

  /**
   * 작성자
   *
   * **data type** varchar(10)
   */
  readonly inperid: string;

  /**
   * 작성일
   *
   * **data type** varchar(8)
   */
  readonly indate: string;

  /**
   * 거래처번호
   *
   * **data type** varchar(20)
   */
  readonly cltnum: string;

  /**
   * 수주종류
   *
   * **data type** varchar(3)
   */
  readonly gubun: string;

  /**
   * 할인적용
   *
   * **data type** varchar(1)
   */
  readonly saleflag: string;

  /**
   * 미수여부
   *
   * **data type** varchar(1)
   */
  readonly misflag: string;

  /**
   * 공사시작일
   *
   * **data type** varchar(8)
   */
  readonly stdate: string;

  /**
   * 공사완료일
   *
   * **data type** varchar(8)
   */
  readonly enddate: string;

  /**
   * 현장코드
   *
   * **data type** varchar(10)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   * 제목
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   * 자채정구코드
   *
   * **data type** varchar(3)
   */
  readonly pumcd: string;

  /**
   * 유무상
   *
   * **data type** varchar(1)
   */
  readonly myn: string;

  /**
   * 견적일자
   *
   * **data type** varchar(8)
   */
  readonly costdate: string;

  /**
   * 견적번호
   *
   * **data type** varchar(4)
   */
  readonly costnum: string;

  /**
   * 프로젝트코드
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   * 프로젝트명
   *
   * **data type** varchar(100)
   */
  readonly projnm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly notice: string;

  /**
   * 공사자명
   *
   * **data type** varchar(8)
   */
  readonly comppernm: string;

  /**
   * 공사자 부서명
   *
   * **data type** varchar(8)
   */
  readonly compdivinm: string;

  /**
   * 작성자명
   *
   * **data type** varchar(8)
   */
  readonly inpernm: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly monrate: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  readonly sunamt: string;

  /**
   * 공급가액
   *
   * **data type** varchar(8)
   */
  readonly samt: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt: string;

  /**
   * 합계금액
   *
   * **data type** varchar(8)
   */
  readonly mamt: string;

  /**
   * 할인율
   *
   * **data type** varchar(8)
   */
  readonly saleyul: string;

  /**
   * 할인금액
   *
   * **data type** varchar(8)
   */
  readonly saleamt: string;

  /**
   * 탭1-수량
   *
   * **data type** varchar(8)
   */
  readonly qty_tot: string;

  /**
   * 탭1-금액
   *
   * **data type** varchar(8)
   */
  readonly samt_tot: string;

  /**
   * 탭1-부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt_tot: string;

  /**
   * 탭1-합계금액
   *
   * **data type** varchar(8)
   */
  readonly mamt_tot: string;

  /**
   * 탭2-금액
   *
   * **data type** varchar(8)
   */
  readonly samt_tot2: string;

  /**
   * 탭2-부가세
   *
   * **data type** varchar(8)
   */
  readonly tamt_tot2: string;

  /**
   * 탭2-견적금액
   *
   * **data type** varchar(8)
   */
  readonly mamt_tot2: string;

  /**
   * 탭3-지급율
   *
   * **data type** varchar(8)
   */
  readonly qty_tot3: string;

  /**
   * 탭3-금액
   *
   * **data type** varchar(8)
   */
  readonly mamt_tot3: string;

  /**
   * 신규 유무
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.orddate = data.orddate || '';
    this.ordnum = data.ordnum || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.deldate = data.deldate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.taxcls = data.taxcls || '';
    this.setcls = data.setcls || '';
    this.givedd = data.givedd || '01';
    this.giveflag = data.giveflag || '';
    this.domcls = data.domcls || '';
    this.moncls = data.moncls || '';
    this.sunflag = data.sunflag || '';
    this.delflag = data.delflag || '';
    this.delway = data.delway || '';
    this.spdesc = data.spdesc || '';
    this.indesc = data.indesc || '';
    this.worknm = data.worknm || '';
    this.estdate = data.estdate || '';
    this.estnum = data.estnum || '';
    this.remark = data.remark || '';
    this.compperid = data.compperid || '';
    this.compdivicd = data.compdivicd || '';
    this.compdate = data.compdate || '';
    this.state = data.state || '';
    this.compflag = data.compflag || '';
    this.inperid = data.inperid || '';
    this.indate = data.indate || '';
    this.cltnum = data.cltnum || '';
    this.gubun = data.gubun || '';
    this.saleflag = data.saleflag || '';
    this.misflag = data.misflag || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.title = data.title || '';
    this.pumcd = data.pumcd || '';
    this.myn = data.myn || '';
    this.costdate = data.costdate || '';
    this.costnum = data.costnum || '';
    this.projno = data.projno || '';
    this.projnm = data.projnm || '';
    this.notice = data.notice || '';
    this.comppernm = data.comppernm || '';
    this.compdivinm = data.compdivinm || '';
    this.inpernm = data.inpernm || '';
    this.monrate = data.monrate || '';
    this.sunamt = data.sunamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.mamt = data.mamt || '';
    this.saleyul = data.saleyul || '';
    this.saleamt = data.saleamt || '';
    this.qty_tot = data.qty_tot || '';
    this.samt_tot = data.samt_tot || '';
    this.tamt_tot = data.tamt_tot || '';
    this.mamt_tot = data.mamt_tot || '';
    this.samt_tot2 = data.samt_tot2 || '';
    this.tamt_tot2 = data.tamt_tot2 || '';
    this.mamt_tot2 = data.mamt_tot2 || '';
    this.qty_tot3 = data.qty_tot3 || '';
    this.mamt_tot3 = data.mamt_tot3 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
