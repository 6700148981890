import {
  TextBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  OptionBox,
} from '../../../../../components';
import { TableLayoutHeader } from '../../../../../constants';
import { AcademicBackgroundModel } from '../models';
import {
  PersonnelResistration,
} from '../PersonnelResistration';

// 가족
const AcademicBackground: TableLayoutHeader<AcademicBackgroundModel, PersonnelResistration>[] = [
  {
    id: 'schcd',
    text: '순번',
    width: 50,
    render: (x) => (
      <TextBox
        value={x.schcd}
        readonly={true}
        transparent={true}
        textAlign="center"
      />
    ),
  },
  {
    id: 'checkflag',
    text: '자체점검자격학력',
    width: 150,
    render: (x, rowUpdate) => (
      <ComboBox
        value={x.checkflag || ''}
        style={{
          padding: '0 0 0 6px',
          fontSize: 12,
        }}
        data={[
          { value: '4', remark: '자체점검고등학교' },
          { value: '5', remark: '자체점검전문대학교' },
          { value: '6', remark: '자체점검대학교' },
          { value: '7', remark: '기타' },
        ].map((y) => new ComboBoxModel(y.value, y.remark))}
        onSelect={(option) => rowUpdate({ checkflag: option.value })}
      />
    ),
  },
  {
    id: 'schnm',
    text: '학교명',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.schnm}
        onChange={(value) => rowUpdate({ schnm: value })}
      />
    ),
  },
  {
    id: 'majo',
    text: '전공',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.majo}
        onChange={(value) => rowUpdate({ majo: value })}
      />
    ),
  },
  {
    id: 'edate',
    text: '입학일',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        format="####-##-##"
        textAlign="center"
        ref={ref}
        value={x.edate}
        onChange={(value) => rowUpdate({ edate: value })}
      />
    ),
  },
  {
    id: 'gdate',
    text: '졸업일',
    width: 200,
    render: (x, rowUpdate, ref) => (
      <DateTextBox
        format="####-##-##"
        textAlign="center"
        ref={ref}
        value={x.gdate}
        onChange={(value) => rowUpdate({ gdate: value })}
      />
    ),
  },
  {
    id: 'igrad',
    text: '졸업구분',
    width: 300,
    render: (x, rowUpdate) => (
      <OptionBox
        value={x.igrad}
        data={[
          { value: '1', remark: '졸업' },
          { value: '2', remark: '재학' },
          { value: '3', remark: '휴학' },
          { value: '4', remark: '퇴학' },
        ]}
        onChange={(item) => rowUpdate({ igrad: item.value.toString(), isChanged: true })}
      />
    ),
  },
];

export default AcademicBackground;
