import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { MaintenanceTemplate } from './Maintenance.template';
import { MaintenanceModel } from './models/Maintenance.model';
import { ModalDataModel } from './models/ModalData.model';
import {
  InfinityRetrieve,
  TabModel,
} from '../../../../models';
import { Today } from '../../../../utils/time';
import { PageComponent } from '../../../../utils/layout';
import {
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  GridLayout,
  GridLayoutAdditionalHeader,
} from '../../../../components';

interface MaintenanceState {
  // 조회조건
  yearmon: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;

  // data
  MaintenanceList: Array<MaintenanceModel>;
  data: MaintenanceModel;
  focused: MaintenanceModel;

  // Modal
  modalData: Array<ModalDataModel>;
  choiceModal: boolean,
  choice: string;
  popupDivicd: string;
  popupDivinm: string;
  popupPerid: string;
  popupPernm: string;
  popupSearchQuery: string;
  tab2_11_modal: boolean; // tab2-11모달
  tab1and12to15_modal?: boolean; // tab1, tab12 ~ tab15 모달
  tab2to3_modal?: boolean; // tab2, tab3 모달
  tab4_modal?: boolean; // tab4 모달
  tab5to7_modal?: boolean; // tab5, tab6, tab7 모달
  tab8to11_modal?: boolean; // tab8 ~ tab11 모달
  tab16_modal?: boolean; // tab16 모달
  tab17_modal?: boolean; // tab17 모달

  // 처음화면 열 때 rowfocus 2번 방지
  firstFlag: boolean;

  // trail
  cnt_tot: string; // 관리대수-현장
  qty_tot: string; // 관리대수-대수

  planamt11_tot: string; // 유지보수-계획금액
  costcnt11_tot: string; // 유지보수-견적건수
  costamt11_tot: string; // 유지보수-견적금액
  misamt11_tot: string; // 유지보수-매출(실적)
  notmisamt11_tot: string; // 유지보수-미달성금액
  planyul11_tot: string; // 유지보수-달성율

  planamt13_tot: string; // 부품교체-계획금액
  costcnt13_tot: string; // 부품교체-견적건수
  costamt13_tot: string; // 부품교체-견적금액
  misamt13_tot: string; // 부품교체-매출(실적)
  notmisamt13_tot: string; // 부품교체-미달성금액
  planyul13_tot: string; // 부품교체-달성율

  planamt12_tot: string; // 수리공사-계획금액
  costcnt12_tot: string; // 수리공사-견적건수
  costamt12_tot: string; // 수리공사-견적금액
  misamt12_tot: string; // 수리공사-매출(실적)
  notmisamt12_tot: string; // 수리공사-미달성금액
  planyul12_tot: string; // 수리공사-달성율

  bccnt_tot: string; // 점검일지B,C

  rececnt_tot: string; // 고장-고장
  rececompcnt_tot: string; // 고장-처리
  recenotcnt_tot: string; // 고장-미처리
  wadcnt_tot: string; // 고장-다발
  troublecnt_tot: string; // 고장-사람갇힘

  kccnt_tot: string; // 검사-검사
  kc001cnt_tot: string; // 검사-합격
  kc002cnt_tot: string; // 검사-불합격
  kc003cnt_tot: string; // 검사-조건부

  contpog_tot: string; // 계약-일반
  contfm_tot: string; // 계약-FM
  contlong_tot: string; // 계약-다년
  contcls_tot: string; // 계약-해지

  misamt_tot: string; // 장기미수금-미수금
  rcvamt_tot: string; // 장기미수금-수금


  // modal trail
  popup_amt_tot: string;
  popup_cnt_tot: string;
  popup_qty_tot: string;

  popup_mis_tot: string;
  popup_misamt_tot: string;
  popup_cost_tot: string;
  popup_costamt_tot: string;
  popup_plan_tot: string;
  popup_planamt_tot: string;

  popup_ccnt_tot: string;
  popup_bcnt_tot: string;

  popup_rececnt_tot: string;
  popup_rececompcnt_tot: string;
  popup_recenotcnt_tot: string;

  popup_compcnt_tot: string;
  popup_result01_tot: string;
  popup_result02_tot: string;
  popup_result03_tot: string;

  popup_beamt_tot: string;
  popup_iamt5_tot: string;
  popup_rcvamt_tot: string;

  results: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e601w_03
 * @category 보수마감통계
 */
export class Maintenance extends PageComponent<PageProps, MaintenanceState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  modalInfinity?: InfinityRetrieve;

  grid: React.RefObject<GridLayout> = React.createRef();

  tab1and12to15_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab2_11_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab2to3_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab4_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab5to7_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab8to11_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab16_modalGrid: React.RefObject<GridLayout> = React.createRef();

  tab17_modalGrid: React.RefObject<GridLayout> = React.createRef();

  additionalMonthHeader: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  tabIndex: number = 0;

  tab: string = '';

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      // 조회조건
      yearmon: Today.yearMon(),
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',

      // data
      MaintenanceList: [],
      data: new MaintenanceModel(),

      // Modal
      modalData: [],
      choiceModal: false,
      choice: '3',
      popupSearchQuery: '',
      popupDivicd: '',
      popupDivinm: '',
      popupPerid: '',
      popupPernm: '',
      tab2_11_modal: false,
      tab1and12to15_modal: false,
      tab2to3_modal: false,
      tab4_modal: false,
      tab5to7_modal: false,
      tab8to11_modal: false,
      tab16_modal: false,
      tab17_modal: false,

      // trail
      cnt_tot: '', // 관리대수-현장
      qty_tot: '', // 관리대수-대수

      planamt11_tot: '', // 유지보수-계획금액
      costcnt11_tot: '', // 유지보수-견적건수
      costamt11_tot: '', // 유지보수-견적금액
      misamt11_tot: '', // 유지보수-매출(실적)
      notmisamt11_tot: '', // 유지보수-미달성금액
      planyul11_tot: '', // 유지보수-달성율

      planamt13_tot: '', // 부품교체-계획금액
      costcnt13_tot: '', // 부품교체-견적건수
      costamt13_tot: '', // 부품교체-견적금액
      misamt13_tot: '', // 부품교체-매출(실적)
      notmisamt13_tot: '', // 부품교체-미달성금액
      planyul13_tot: '', // 부품교체-달성율

      planamt12_tot: '', // 수리공사-계획금액
      costcnt12_tot: '', // 수리공사-견적건수
      costamt12_tot: '', // 수리공사-견적금액
      misamt12_tot: '', // 수리공사-매출(실적)
      notmisamt12_tot: '', // 수리공사-미달성금액
      planyul12_tot: '', // 수리공사-달성율

      bccnt_tot: '', // 점검일지B,C

      rececnt_tot: '', // 고장-고장
      rececompcnt_tot: '', // 고장-처리
      recenotcnt_tot: '', // 고장-미처리
      wadcnt_tot: '', // 고장-다발
      troublecnt_tot: '', // 고장-사람갇힘

      kccnt_tot: '', // 검사-검사
      kc001cnt_tot: '', // 검사-합격
      kc002cnt_tot: '', // 검사-불합격
      kc003cnt_tot: '', // 검사-조건부

      contpog_tot: '', // 계약-일반
      contfm_tot: '', // 계약-FM
      contlong_tot: '', // 계약-다년
      contcls_tot: '', // 계약-해지

      misamt_tot: '', // 장기미수금-미수금
      rcvamt_tot: '', // 장기미수금-수금


      // modal trail
      popup_amt_tot: '',
      popup_cnt_tot: '',
      popup_qty_tot: '',
      popup_mis_tot: '',
      popup_misamt_tot: '',
      popup_cost_tot: '',
      popup_costamt_tot: '',
      popup_plan_tot: '',
      popup_planamt_tot: '',
      popup_ccnt_tot: '',
      popup_bcnt_tot: '',
      popup_rececnt_tot: '',
      popup_rececompcnt_tot: '',
      popup_recenotcnt_tot: '',
      popup_compcnt_tot: '',
      popup_result01_tot: '',
      popup_result02_tot: '',
      popup_result03_tot: '',
      popup_beamt_tot: '',
      popup_iamt5_tot: '',
      popup_rcvamt_tot: '',

      results: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();

    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ca510_018_01');
    if (!data) return;
    this.setState({ results: data?.items });
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        mon: this.state.yearmon,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          MaintenanceList: [...this.state.MaintenanceList, ...items],
        }, () => {
          this.grid.current?.setFocus(0);
        });
      },
      async () => {
        await this.SS({
          data: new MaintenanceModel(),
          MaintenanceList: [],
        });
        this.infinity?.retrieve();
      },
    );

    // 상단 조회 버튼을 누를 때는 기존 배열 초기화
    await this.SS({
      data: new MaintenanceModel(),
      MaintenanceList: [],
    });

    await this.infinity?.retrieveAll();
    await this.SS({
      cnt_tot: this.infinity?.box?.cnt_tot || '',
      qty_tot: this.infinity?.box?.qty_tot || '',

      planamt11_tot: this.infinity?.box?.planamt11_tot || '',
      costcnt11_tot: this.infinity?.box?.costcnt11_tot || '',
      costamt11_tot: this.infinity?.box?.costamt11_tot || '',
      misamt11_tot: this.infinity?.box?.misamt11_tot || '',
      notmisamt11_tot: this.infinity?.box?.notmisamt11_tot || '',
      planyul11_tot: this.infinity?.box?.planyul11_tot || '',

      planamt13_tot: this.infinity?.box?.planamt13_tot || '',
      costcnt13_tot: this.infinity?.box?.costcnt13_tot || '',
      costamt13_tot: this.infinity?.box?.costamt13_tot || '',
      misamt13_tot: this.infinity?.box?.misamt13_tot || '',
      notmisamt13_tot: this.infinity?.box?.notmisamt13_tot || '',
      planyul13_tot: this.infinity?.box?.planyul13_tot || '',

      planamt12_tot: this.infinity?.box?.planamt12_tot || '',
      costcnt12_tot: this.infinity?.box?.costcnt12_tot || '',
      costamt12_tot: this.infinity?.box?.costamt12_tot || '',
      misamt12_tot: this.infinity?.box?.misamt12_tot || '',
      notmisamt12_tot: this.infinity?.box?.notmisamt12_tot || '',
      planyul12_tot: this.infinity?.box?.planyul12_tot || '',

      bccnt_tot: this.infinity?.box?.bccnt_tot || '',

      rececnt_tot: this.infinity?.box?.rececnt_tot || '',
      rececompcnt_tot: this.infinity?.box?.rececompcnt_tot || '',
      recenotcnt_tot: this.infinity?.box?.recenotcnt_tot || '',
      wadcnt_tot: this.infinity?.box?.wadcnt_tot || '',
      troublecnt_tot: this.infinity?.box?.troublecnt_tot || '',

      kccnt_tot: this.infinity?.box?.kccnt_tot || '',
      kc001cnt_tot: this.infinity?.box?.kc001cnt_tot || '',
      kc002cnt_tot: this.infinity?.box?.kc002cnt_tot || '',
      kc003cnt_tot: this.infinity?.box?.kc003cnt_tot || '',

      contpog_tot: this.infinity?.box?.contpog_tot || '',
      contfm_tot: this.infinity?.box?.contfm_tot || '',
      contlong_tot: this.infinity?.box?.contlong_tot || '',
      contcls_tot: this.infinity?.box?.contcls_tot || '',

      misamt_tot: this.infinity?.box?.misamt_tot || '',
      rcvamt_tot: this.infinity?.box?.rcvamt_tot || '',
    });
  }

  @action
  async onRefresh() {
    const { actionStore: api } = this.props;
    ConfirmWarning.show('확인', '새로고침시에 상당시간이 소요(약3분미만)됩니다!\n조금만 기다리셨다가 조회를 눌러주세요.');
    await api.fxExec(
      'wb_refresh_lazytask',
      {
        mon: this.state.yearmon,
      },
    );
    // await this.onRetrieveEvent();
  }

  @action
  async onRowFocusEvent(item: MaintenanceModel) {
    await this.setState({
      focused: new MaintenanceModel(item),
    });
  }

  @action
  async onTabChange(_: TabModel, index: number) {
    this.tabIndex = index;

    if (this.state.firstFlag) {
      await this.SS({ firstFlag: false });
    }
  }

  @action
  async openChoiceModal(isOpen: boolean, tab: string) {
    this.tab = tab;
    await this.SS({
      choiceModal: isOpen,
    });
  }

  @action
  async closeChoiceModal() {
    let divicd: string = '';
    let divinm: string = '';
    let perid: string = '';
    let pernm: string = '';

    if (this.state.choice !== '1' && this.state.choice !== '2' && this.state.choice !== '3') {
      ConfirmWarning.show('경고', '체크를 하나이상 해주세요');
      return;
    }

    if (this.state.choice === '1') {
      divicd = '';
      divinm = '';
      perid = '';
      pernm = '';
    } else if (this.state.choice === '2') {
      divicd = this.state.focused?.divicd;
      divinm = this.state.focused?.divinm;
      perid = '';
      pernm = '';
    } else if (this.state.choice === '3') {
      divicd = '';
      divinm = '';
      perid = this.state.focused?.perid;
      pernm = this.state.focused?.pernm;
    }
    await this.SS({
      popupDivicd: divicd,
      popupDivinm: divinm,
      popupPerid: perid,
      popupPernm: pernm,
      choiceModal: false,
      choice: '3',
    });
    await this.openModal(true);
  }

  @action
  async openModal(isOpen: boolean) {
    if (this.tab === '1' || this.tab === '12' || this.tab === '13' || this.tab === '14' || this.tab === '15') {
      await this.SS({ tab1and12to15_modal: isOpen });
    } else if (this.tab === '2-11') {
      await this.SS({
        tab2_11_modal: isOpen,
      });
    } else if (this.tab === '2' || this.tab === '3') {
      await this.SS({ tab2to3_modal: isOpen });
    } else if (this.tab === '4') {
      await this.SS({ tab4_modal: isOpen });
    } else if (this.tab === '5' || this.tab === '6' || this.tab === '7') {
      await this.SS({ tab5to7_modal: isOpen });
    } else if (this.tab === '8' || this.tab === '9' || this.tab === '10' || this.tab === '11') {
      await this.SS({ tab8to11_modal: isOpen });
    } else if (this.tab === '16') {
      await this.SS({ tab16_modal: isOpen });
    } else if (this.tab === '17') {
      await this.SS({ tab17_modal: isOpen });
    }
    await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    await this.SS({ modalData: [] });

    this.modalInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e601w_03',
        spjangcd: this.state.focused?.spjangcd,
        mon: this.state.yearmon,
        divicd: this.state.popupDivicd,
        perid: this.state.popupPerid,
        as_nm: this.state.popupSearchQuery,
        tab: this.tab,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          modalData: [
            ...this.state.modalData,
            ...items.map((item) => new ModalDataModel(item)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          modalData: [],
        });
        await this.modalInfinity?.retrieveAll();
      },
    );

    await this.modalInfinity?.retrieveAll();

    await this.SS({
      popup_amt_tot: this.modalInfinity?.box?.amt_tot || '',
      popup_cnt_tot: this.modalInfinity?.box?.cnt_tot || '',
      popup_qty_tot: this.modalInfinity?.box?.qty_tot || '',

      popup_mis_tot: this.modalInfinity?.box?.mis_tot || '',
      popup_misamt_tot: this.modalInfinity?.box?.misamt_tot || '',
      popup_cost_tot: this.modalInfinity?.box?.cost_tot || '',
      popup_costamt_tot: this.modalInfinity?.box?.costamt_tot || '',
      popup_plan_tot: this.modalInfinity?.box?.plan_tot || '',
      popup_planamt_tot: this.modalInfinity?.box?.planamt_tot || '',

      popup_ccnt_tot: this.modalInfinity?.box?.ccnt_tot || '',
      popup_bcnt_tot: this.modalInfinity?.box?.bcnt_tot || '',

      popup_rececnt_tot: this.modalInfinity?.box?.rececnt_tot || '',
      popup_rececompcnt_tot: this.modalInfinity?.box?.rececompcnt_tot || '',
      popup_recenotcnt_tot: this.modalInfinity?.box?.recenotcnt_tot || '',

      popup_compcnt_tot: this.modalInfinity?.box?.compcnt_tot || '',
      popup_result01_tot: this.modalInfinity?.box?.result01_tot || '',
      popup_result02_tot: this.modalInfinity?.box?.result02_tot || '',
      popup_result03_tot: this.modalInfinity?.box?.result03_tot || '',

      popup_beamt_tot: this.modalInfinity?.box?.beamt_tot || '',
      popup_iamt5_tot: this.modalInfinity?.box?.iamt5_tot || '',
      popup_rcvamt_tot: this.modalInfinity?.box?.rcvamt_tot || '',
    });

    await this.tab2_11_modalGrid.current?.setFocus(0);
    await this.tab1and12to15_modalGrid.current?.setFocus(0);
    await this.tab2to3_modalGrid.current?.setFocus(0);
    await this.tab4_modalGrid.current?.setFocus(0);
    await this.tab5to7_modalGrid.current?.setFocus(0);
    await this.tab8to11_modalGrid.current?.setFocus(0);
    await this.tab16_modalGrid.current?.setFocus(0);
    await this.tab17_modalGrid.current?.setFocus(0);
  }

  @action
  async closeModal(isOpen: boolean) {
    await this.SS({
      // choiceModal: isOpen,
      tab2_11_modal: isOpen,
      tab1and12to15_modal: isOpen,
      tab2to3_modal: isOpen,
      tab4_modal: isOpen,
      tab5to7_modal: isOpen,
      tab8to11_modal: isOpen,
      tab16_modal: isOpen,
      tab17_modal: isOpen,
      popupSearchQuery: '',
      divinm: '',
      divicd: '',
      perid: '',
      pernm: '',
    });
  }

  render() {
    return (
      <MaintenanceTemplate
        scope={this}
      />
    );
  }
}
