import * as React from 'react';
import {
  FlexLayout,
  GridLayout,
} from '../../../../../components';
import { Global, TemplateProps } from '../../../../../constants';
import { Regist } from '../Regist';
import {
  RegistTabGridSalesHeader,
} from '../headers';

/**
 * 화면 > 상세 > 탭 - 영업
 * @window w_tb_e601_sulchi
 * @category 설치현장등록
 */
export const RegistTabSales: React.FC<TemplateProps<Regist>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
      <GridLayout
        ref={scope.gridEstimate}
        header={RegistTabGridSalesHeader(scope)}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        data={scope.state.tabDataSales}
        onRowClick={(_, index) => scope.onGridSalesFocused(index)}
      />
  </FlexLayout>
);
