import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { DepartmetModel } from '../models';
import { MonthWork } from '../MonthWork';
import Format from '../../../../../utils/string/Format';
import { Sum } from '../../../../../utils/array';

const gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '기타수입' },
  { value: '18', remark: '기타환불' },
];

// 부서별 업무보고
const Departmet: GridLayoutHeader<DepartmetModel, MonthWork>[] = [
  {
    id: 'divinm',
    text: '부서명',
    width: 5,
    sum: (x) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: '#8106aa' }}
      >
        {x.divinm}
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '부서별그룹',
    isHidden: true,
    group: true,
    value: (x) => x.divinm,
  },
  {
    id: 'gubun',
    text: '구분',
    width: 6,
    sum: (x, data) => {
      const sumGubun = Sum.groupLength(data, 'gubun', x, ['divinm']);
      return (
        <FlexLayout
          justify="center"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumGubun)} 건
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        align='center'
        justify='center'
      >
        {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
      </FlexLayout>
    ),
  },
  {
    id: 'yearplansamt',
    text: '연간목표',
    width: 7,
    sum: (x, data) => {
      const sumYearplansamt = Sum.byGroup(data, 'yearplansamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumYearplansamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.yearplansamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'beplansamt',
    text: '목표',
    width: 7,
    sum: (x, data) => {
      const sumBeplansamt = Sum.byGroup(data, 'beplansamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumBeplansamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.beplansamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'besamt',
    text: '실적',
    width: 7,
    sum: (x, data) => {
      const sumBesamt = Sum.byGroup(data, 'besamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumBesamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.besamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'bechaamt',
    text: '차이',
    width: 7,
    sum: (x, data) => {
      const sumBechaamt = Sum.byGroup(data, 'bechaamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(sumBechaamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          color: parseInt(x.besamt, 10) - parseInt(x.beplansamt, 10) > 0
            ? 'var(--color-blue)'
            : 'var(--color-red)',
        }}
      >
        {Format.number(x.bechaamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'compute_27',
    text: '달성율',
    width: 5,
    sum: (x, data) => {
      const sumCompute_27 = Sum.byGroup(data, 'compute_27', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(sumCompute_27)} %
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.compute_27} %
      </FlexLayout>
    ),
  },
  {
    id: 'plansamt',
    text: '목표',
    width: 7,
    sum: (x, data) => {
      const sumPlansamt = Sum.groupLength(data, 'plansamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumPlansamt)} %
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.plansamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'samt',
    text: '현재실적',
    width: 7,
    sum: (x, data) => {
      const sumSamt = Sum.byGroup(data, 'samt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ backgroundColor: '#D4F4FA' }}
        >
          {Format.number(sumSamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ backgroundColor: '#D4F4FA' }}
      >
        {Format.number(x.samt)}
      </FlexLayout>
    ),
  },
  {
    id: 'chaamt',
    text: '차이',
    width: 7,
    sum: (x, data) => {
      const sumChaamt = Sum.byGroup(data, 'chaamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{
            color: parseInt(x.besamt, 10) - parseInt(x.beplansamt, 10) > 0
              ? 'var(--color-blue)'
              : 'var(--color-red)',
          }}
        >
          {Format.number(sumChaamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          color: parseInt(x.besamt, 10) - parseInt(x.beplansamt, 10) > 0
            ? 'var(--color-blue)'
            : 'var(--color-red)',
        }}
      >
        {Format.number(x.chaamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'compute_31',
    text: '달성율',
    width: 5,
    sum: (x, data) => {
      const sumCompute_31 = Sum.byGroup(data, 'compute_31', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(sumCompute_31)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.compute_31} %
      </FlexLayout>
    ),
  },
  {
    id: 'totplansamt',
    text: '목표',
    width: 7,
    sum: (x, data) => {
      const sumTotplansamt = Sum.groupLength(data, 'totplansamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
        >
          {Format.number(sumTotplansamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.totplansamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'totsamt',
    text: '실적',
    width: 7,
    sum: (x, data) => {
      const sumTotsamt = Sum.byGroup(data, 'totsamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: '#8106aa' }}
        >
          {Format.number(sumTotsamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(x.totsamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'totchaamt',
    text: '차이',
    width: 7,
    sum: (x, data) => {
      const sumTotchaamt = Sum.byGroup(data, 'totchaamt', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(sumTotchaamt)}
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{
          color: parseInt(x.besamt, 10) - parseInt(x.beplansamt, 10) > 0
            ? 'var(--color-blue)'
            : 'var(--color-red)',
        }}
      >
        {Format.number(x.totchaamt)}
      </FlexLayout>
    ),
  },
  {
    id: 'compute_33',
    text: '달성율',
    width: 8,
    sum: (x, data) => {
      const sumCompute_33 = Sum.byGroup(data, 'compute_33', x, ['divinm']);
      return (
        <FlexLayout
          justify="right"
          align="center"
          style={{ color: 'var(--color-blue)' }}
        >
          {Format.number(sumCompute_33)} %
        </FlexLayout>
      );
    },
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {x.compute_33} %
      </FlexLayout>
    ),
  },
];

export default Departmet;
