import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  SiteModel,
  AccountModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout, TableLayout } from '../../../../components';
import { MonthInputTemplate } from './MonthInput.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderAccount,
  TabHeaderSite,
} from './tabs';
import { Today } from '../../../../utils/time';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

export enum MonthInputTabId {
  Site,
  Account,
}

export const MonthInputTabTitles = [
  '현장별',
  '거래처별',
];

export const MonthInputTabModels = [
  SiteModel,
  AccountModel,
];

export const MonthInputTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const MonthInputTabDataStateNames = [
  'sites',
  'accounts',
];

export interface MonthInputState {
  // search
  focusedTab?: TabModel;
  focused?: SiteModel;
  year: string;
  searchQuery: string;
  pushTotalCheck: boolean;

  // data
  data?: Array<any>;

  // 현장별
  sites?: Array<SiteModel>;

  // 거래처별
  accounts?: Array<AccountModel>;

  holderName: string;

  // trail
  total: string;
  iamt_tot: string;
  mon01_tot: string;
  mon02_tot: string;
  mon03_tot: string;
  mon04_tot: string;
  mon05_tot: string;
  mon06_tot: string;
  mon07_tot: string;
  mon08_tot: string;
  mon09_tot: string;
  mon10_tot: string;
  mon11_tot: string;
  mon12_tot: string;
}

/**
 * 컨트롤러
 * @window w_input_da026w_02
 * @category 월별입금현황
 */
export class MonthInput extends PageComponent<PageProps, MonthInputState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = MonthInputTabId;
    const titles = MonthInputTabTitles;

    this.tabs = [
      new TabModel(id.Site.toString(), MdPublish, titles[id.Site]),
      new TabModel(id.Account.toString(), MdReorder, titles[id.Account]),
    ];

    // state 기본값 정의
    this.state = props.state || {
      year: Today.year(),
      searchQuery: '',
      pushTotalCheck: true,
      data: [],
      holderName: '현장으로 검색하세요',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 현장별
      TabHeaderSite,
      // 거래처별
      TabHeaderAccount,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 왼쪽 리스트
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ data: data.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });

    this.onTabChange(this.tabs[MonthInputTabId.Site]);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[MonthInputTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${MonthInputTabFunctionNames[i]}_print`,
      {
        year: this.state.year,
        as_nm: this.state.searchQuery,
        items: this.state.data,
      },
    );
  }

  @action
  async onHolderNameMake() {
    let text = '';
    switch ((this.tabIndex)) {
      case 0:
        text = '현장으로 검색하세요';
        break;

      case 1:
        text = '거래처로 검색하세요';
        break;

      default:
        text = '';
    }
    this.setState({ holderName: text });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[MonthInputTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${MonthInputTabFunctionNames[i]}_excel`,
      {
        year: this.state.year,
        as_nm: this.state.searchQuery,
        items: this.state.data,
      },
    );
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({
      focusedTab,
      // trail
      total: '0',
      iamt_tot: '0',
      mon01_tot: '0',
      mon02_tot: '0',
      mon03_tot: '0',
      mon04_tot: '0',
      mon05_tot: '0',
      mon06_tot: '0',
      mon07_tot: '0',
      mon08_tot: '0',
      mon09_tot: '0',
      mon10_tot: '0',
      mon11_tot: '0',
      mon12_tot: '0',
    }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    await this.onHolderNameMake();

    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        year: this.state.year,
        as_nm: this.state.searchQuery,
        items: this.state.data,
      },
      (params) => api.fxExec(
        `${MonthInputTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [MonthInputTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[MonthInputTabDataStateNames[i]],
              ...items.map((x: any) => new MonthInputTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [MonthInputTabDataStateNames[i]]: [],
        });

        await this.infinity?.retrieveAll();
        // @ts-ignore
        if (this.state[MonthInputTabDataStateNames[i]] && this.state[MonthInputTabDataStateNames[i]]?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [MonthInputTabDataStateNames[i]]: [],
    }, async () => {
      const index = await this.infinity?.retrieveTo(['mon06', 'iamt'],
        [this.state.focused?.mon06, this.state.focused?.iamt], type, true) || 0;
      // @ts-ignore
      if (this.state[MonthInputTabDataStateNames[i]] && this.state[MonthInputTabDataStateNames[i]].length > index) {
        await this.grid.current?.setFocus(index);
      }
      this.SS({
        total: this.infinity?.box?.total || '0',
        iamt_tot: this.infinity?.box?.iamt_tot || '0',
        mon01_tot: this.infinity?.box?.mon01_tot || '0',
        mon02_tot: this.infinity?.box?.mon02_tot || '0',
        mon03_tot: this.infinity?.box?.mon03_tot || '0',
        mon04_tot: this.infinity?.box?.mon04_tot || '0',
        mon05_tot: this.infinity?.box?.mon05_tot || '0',
        mon06_tot: this.infinity?.box?.mon06_tot || '0',
        mon07_tot: this.infinity?.box?.mon07_tot || '0',
        mon08_tot: this.infinity?.box?.mon08_tot || '0',
        mon09_tot: this.infinity?.box?.mon09_tot || '0',
        mon10_tot: this.infinity?.box?.mon10_tot || '0',
        mon11_tot: this.infinity?.box?.mon11_tot || '0',
        mon12_tot: this.infinity?.box?.mon12_tot || '0',
      });
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  onRowFocusEvent(item: SiteModel) {
    this.setState({ focused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @computed
  get tabIndex(): MonthInputTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <MonthInputTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
