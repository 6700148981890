import React from 'react';
import './react-minimap.css';

interface ChildProps {
  width: number;
  height: number;
  top: number;
  left: number;
  node: any;
}

export class Child extends React.Component<ChildProps> {
  render() {
    const {
      width,
      height,
      left,
      top,
    } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          width,
          height,
          left,
          top,
        }}
        className="minimap-children"
      />
    );
  }
}
export default Child;
