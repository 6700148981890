import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { TopWorkModel } from './TopWork.model';
import { TopWorkTemplate } from './TopWork.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

// export interface TopWorkListItem {
//   kukcd: string;
// }

interface TopWorkState {
  searchQuery: string;
  data: TopWorkModel;
  // topWorkList: Array<TopWorkListItem>;
  topWorkList: Array<TopWorkModel>;
  focused: TopWorkModel;
}

/**
 * 컨트롤러
 * @window TB_E022
 * @category 상위업무코드등록
 */
export class TopWork extends PageComponent<PageProps, TopWorkState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  // newCheck: string;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      topWorkList: [],
      data: [],
      searchQuery: '',
      focused: [],
    };
    // this.newCheck = '0';
  }

  @action
  async onFirstOpenEvent() {
    await this.open();
  }

  @action
  async open() {
    if (!this.state.searchQuery) {
      const { actionStore: api } = this.props;
      const datas = await api.fxExec(
        'open',
      );
      datas && this.setState({
        data: datas,
        searchQuery: datas.kukcd,
      });
      this.state.data.searchQuery = this.state.searchQuery;
    }
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        kukcd: this.state.data.searchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          topWorkList: [...this.state.topWorkList, ...items],
        });
      },
      async () => {
        await this.SS({
          topWorkList: [],
        });

        await this.infinity?.retrieve();
        await this.table.current?.update(true);
        this.state.topWorkList.length > 0 && await this.table.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      topWorkList: [],
    }, async () => {
      await this.infinity?.retrieve();
      this.table.current?.update(true);
      this.state.topWorkList.length > 0 && await this.table.current?.setFocus(0);
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    // this.newCheck = '1';

    if (this.state.data?.searchQuery === undefined) {
      ConfirmWarning.show('경고', '먼저 상위부서를 선택해주세요');
      return;
    }

    // let lastUpFlag = String(Number(this.state.topWorkList[this.state.topWorkList.length - 1].upflag) + 1);
    //
    // if (Number(lastUpFlag) < 10) {
    //   lastUpFlag = `00${lastUpFlag}`;
    // } else if (Number(lastUpFlag) < 100) {
    //   lastUpFlag = `0${lastUpFlag}`;
    // }
    // const lastKuknm = this.state.topWorkList[this.state.topWorkList.length - 1].kuknm;

    const data = await api.new({
      kukcd: this.state.data.searchQuery,
    });
    // data.modalCheck = '1';
    data && this.setState({
      topWorkList: [...this.state.topWorkList, new TopWorkModel(data, true)],
      focused: new TopWorkModel(data, true),
    }, async () => {
      await this.table.current?.update(true);
      await this.table.current?.setFocus(this.state.topWorkList.length - 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    // let newData: boolean;
    // if (this.newCheck === '0') {
    //   newData = false;
    // } else {
    //   newData = true;
    // }

    if (await api.save({
      items: this.updatedRows,
    }, this.state.focused?.isNew)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `상위업무코드: ${this.state.focused?.upflag} 상위업무명: ${this.state.focused?.upflagnm}`;
    await api.delete(text, this.state.focused) && this.onRetrieveEvent();
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.topWorkList?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.topWorkList?.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      as_nm: this.state.searchQuery,
    });
  }

  @action
  onRowFocusEvent(item: TopWorkModel) {
    if (item?.isNew) {
      return;
    }

    this.setState({ focused: new TopWorkModel(item, false) });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ topWorkList: rows });
  }

  render() {
    return (
      <TopWorkTemplate
        scope={this}
        update={(change) => {
          this.setState({
            data: {
              ...this.state.data,
              ...change,
            },
          });
        }}
      />
    );
  }
}
