import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  ModalView,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { KakaoTalk } from './KakaoTalk';
import { Date8 } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_KaKao_report
 * @category 카카오톡송신내역
 */

// eslint-disable-next-line no-empty-pattern
export const KakaoTalkTemplate: React.FC<TemplateProps<KakaoTalk>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24} />
            </button>)}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={225}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout weight={3}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="전송내역으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={400}>
          <span style={{ color: 'var(--color-red)' }}>* 전송내역을 더블클릭시 상세하게 표시됩니다.</span>
        </FlexLayout>
      </SearchLayout>
      <GridLayout
        header={[
          {
            id: 'resultcd',
            text: '전송여부',
            width: 5,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{ color: x.resultcd === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}>
                {x.resultcd === '1' ? '성공' : '실패'}
              </FlexLayout>
            ),
          },
          {
            id: 'indate',
            text: '전송일자',
            width: 10,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="####-##-##"
                value={x.indate}
              />
            ),
          },
          {
            id: 'intime',
            text: '전송시간',
            width: 10,
            render: (x) => (
              <FormatTextBox
                textAlign="center"
                format="##:##:##"
                value={x.intime}
              />
            ),
          },
          {
            id: 'hp',
            text: '받는이연락처',
            width: 10,
          },
          {
            id: 'msg',
            text: '전송내역',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
                onDoubleClick={
                () => {
                  scope.textModal(true);
                  scope.setState({ popupData: x.msg });
                }}>
                {x.msg}
              </FlexLayout>
            ),
          },
        ]}
        data={scope.state.kakaoTalkList}
        infinityHandler={scope.infinity}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
      />
      <ModalView
        isVisible={scope.state.kakaoDetailModal}
        onClose={() => scope.textModal(false)}
      >
        <FlexLayout
          isVertical={true}
          weight={3}
          style={{ padding: 8 }}>
          <SubHeader>
            <FlexLayout style={{ color: 'var(--color-red)' }}>
              문자 내용
            </FlexLayout>
            <div>
              <Button
                onClick={() => scope.textModal(false)}>
                확인
              </Button>
            </div>
          </SubHeader>
          <FlexLayout style={{ padding: 10, fontSize: 14, lineHeight: 2 }}>
            {scope.state.popupData}
          </FlexLayout>
          <FlexLayout
            isVertical={true}
            align='right'>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
);
