import * as React from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import {
  ExternalPageProps,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { MonthTemplate } from './Month.template';
import { InfinityRetrieve } from '../../../../models';
import { Today } from '../../../../utils/time';

interface MonthState {
  yearmon: string;
  keyData: string;
  textDetailModal: boolean;
}

/**
 * 컨트롤러
 * @window w_tb_monend
 * @category 월마감현황
 */
@inject('toolStore')
export class Month extends React.Component<PageProps, MonthState, ExternalPageProps>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      yearmon: Today.yearMon(),
      keyData: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    this.textModal(true);
  }

  @action
  textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });
  }

  async onPageMove() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        mon: this.state.yearmon,
      },
    );

    if (data) {
      this.setState({
        keyData: data?.key,
      }, async () => {
        window.open(`http://gw.dmel.co.kr/api/final/?today=${this.state.yearmon}01&spjangcd=ZZ&key=${this.state.keyData}#/dash`);
        // @ts-ignore
        this.props.toolStore.onCloseHandler();
        this.textModal(false);
      });
    }
  }

  render() {
    return (
      <MonthTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
