import * as React from 'react';
import {
  AiOutlineUpload,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  RiFolder2Line,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Order,
  OrderButtonClickedTypes,
  OrderItemChangeTypes,
} from './Order';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  Button,
  GridLayout,
  FormatTextBox,
  LabelBox,
  OptionBox,
  CheckBox,
  FormatNumberTextBox,
  LayoutTitle,
  TableLayout,
  ModalView,
  AttachmentBox,
  TelnumTextBox,
  SubHeader,
} from '../../../../components';
import {
  Date8,
  Today,
} from '../../../../utils/time';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';
import Format from '../../../../utils/string/Format';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Sum } from '../../../../utils/array';

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

/**
 * 메인화면
 * @window w_tb_ca608_01
 * @category 발주등록
 */
export const OrderTemplate: React.FC<TemplateProps<Order>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  const { user } = scope.props.publicStore;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={400}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 특이사항, 품목별비고로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.loadBillModal(true)}>
          <div style={{ width: 80 }}>자재청구서</div>
        </Button>

        <Button onClick={() => scope.onCopy()}>
          <div style={{ width: 80 }}>발주서복사</div>
        </Button>

        <Button onClick={() => scope.onSendFax()}>
          <div style={{ width: 80 }}>FAX</div>
        </Button>

        <Button onClick={() => scope.onSendEmail()}>
          <div style={{ width: 80 }}>EMAIL</div>
        </Button>

        <Button
          disabled={scope.state.isReported && scope.state.focusedOrder?.appgubun !== '001'}
          onClick={() => scope.onReport()}
        >
          <div style={{ width: 80 }}> {scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout size={442}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'baldate',
              text: '발주일자',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.baldate}
                />
              ),
            },
            {
              id: 'balnum',
              text: '번호',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.balnum}
                </FlexLayout>
              ),
            },
            {
              id: 'appgubun',
              text: '상태',
              width: 75,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={
                      () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                    }
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.orderList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />

        <FlexLayout
          weight={2}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 발주를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="발주일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.baldate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ baldate: value }, () => scope.itemChanged(OrderItemChangeTypes.BALDATE))}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.balnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="##-######-###-####"
                textAlign="center"
                value={user.papernum + model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="입고구분">
              <ComboBox
                value={model?.cdflag}
                data={scope.state.ipgubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                onSelect={(option) => setData({ cdflag: option.value },
                  () => scope.itemChanged(OrderItemChangeTypes.CDFLAG))
                }
              />
            </LabelBox>
            <LabelBox
              title="담당자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    },
                    () => scope.itemChanged(OrderItemChangeTypes.PERID, item.cd));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="발주부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.divinm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="창고"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.store}
                textAlign="center"
                onChange={(value) => setData({ store: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_CA504',
                  {},
                  true,
                  (item) => {
                    setData({
                      store: item.cd,
                      storenm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.storenm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={2}
              title="발주처"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.cltcd}
                textAlign="center"
                onChange={(value) => setData({ cltcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  {},
                  true,
                  (item) => {
                    setData({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    },
                    () => scope.itemChanged(OrderItemChangeTypes.CLTCD, item.cd));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.cltnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="발주처 담당자"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.onButtonClicked(OrderButtonClickedTypes.BALPERNM)}
                    isIcon={true}
                  >
                    <AiOutlineUpload size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.balpernm}
                textAlign="left"
                onChange={(value) => setData({ balpernm: value })}
              />
            </LabelBox>
            <LabelBox
              title="발주처 담당연락처"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.onButtonClicked(OrderButtonClickedTypes.TEL)}
                    isIcon={true}
                    >
                    <AiOutlineUpload size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TelnumTextBox
                value={model?.telnum}
                textAlign="left"
                onChange={(value) => setData({ telnum: value })}
              />
            </LabelBox>
            <LabelBox
              title="발주 Fax"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.onButtonClicked(OrderButtonClickedTypes.FAX)}
                    isIcon={true}
                  >
                    <AiOutlineUpload size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TelnumTextBox
                value={model?.faxnum}
                textAlign="left"
                onChange={(value) => setData({ faxnum: value })}
              />
            </LabelBox>
            <LabelBox
              title="발주처 Email"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.onButtonClicked(OrderButtonClickedTypes.EMAIL)}
                    isIcon={true}
                  >
                    <AiOutlineUpload size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.email}
                textAlign="left"
                onChange={(value) => setData({ email: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              size={150}
              title="부가세구분"
            >
              <OptionBox
                value={scope.state.data.taxcls}
                data={[
                  { value: '1', remark: '포함' },
                  { value: '0', remark: '별도' },
                ]}
                onChange={(item) => update!({ taxcls: item.value })}
              />
            </LabelBox>
            <LabelBox
              title="하자기간"
              size={107}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={`${model?.hagigan}일`}
                onChange={(value) => setData({ hagigan: value })}
              />
            </LabelBox>
            <LabelBox
              title="소요기간"
              size={107}
            >
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={`${model?.sogigan}일`}
                onChange={(value) => setData({ sogigan: value })}
              />
            </LabelBox>
            <LabelBox
              title="계약금"
              footer={
                <CheckBox
                  value={model?.sunflag === '1'}
                  isDisabledTrackingStateChange={true}
                  onChange={
                    (value) => setData({ sunflag: value ? '1' : '0' },
                      () => {
                        scope.itemChanged(OrderItemChangeTypes.SUNFLAG);
                      })
                  }
                />
              }
            >
              <FormatNumberTextBox
                textAlign="center"
                value={`${model?.styul}%`}
                onChange={(value) => setData({ styul: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.STYUL);
                  })
                }
                readonly={model?.sunflag === '0'}
              />
              <FormatNumberTextBox
                weight={2}
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.stamt}
                onChange={(value) => setData({ stamt: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.STAMT);
                  })
                }
                readonly={model?.sunflag === '0'}
              />
            </LabelBox>
            <LabelBox title="중도금">
              <FormatNumberTextBox
                textAlign="center"
                value={`${model?.midyul}%`}
                onChange={(value) => setData({ midyul: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.MIDYUL);
                  })
                }
                readonly={model?.sunflag === '0'}
              />
              <FormatNumberTextBox
                weight={2}
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.midamt}
                onChange={(value) => setData({ midamt: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.MIDAMT);
                  })
                }
                readonly={model?.sunflag === '0'}
              />
            </LabelBox>
            <LabelBox title="잔금">
              <FormatNumberTextBox
                textAlign="center"
                value={`${model?.endyul}%`}
                onChange={(value) => setData({ endyul: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.ENDYUL);
                  })
                }
                readonly={model?.sunflag === '0'}
              />
              <FormatNumberTextBox
                weight={2}
                char={','}
                charAt={3}
                textAlign="right"
                value={model?.endamt}
                onChange={(value) => setData({ endamt: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.ENDAMT);
                  })
                }
                readonly={model?.sunflag === '0'}
              />
            </LabelBox>
            <LabelBox title="할인(최종금액)">
              <FormatNumberTextBox
                textAlign="center"
                value={`${model?.saleyul}%`}
                onChange={(value) => setData({ saleyul: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.SALEYUL);
                  })
                }
              />
              <FormatNumberTextBox
                weight={2}
                char={','}
                charAt={3}
                textAlign="right"
                onChange={(value) => setData({ saleamt: value },
                  () => {
                    scope.itemChanged(OrderItemChangeTypes.SALEAMT);
                  })
                }
                value={model?.saleamt}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="납품장소"
              footer={
                <FlexLayout>
                  <span style={{
                    color: 'var(--color-blue)',
                  }}>
                    *미기재시(현장입고일시 하단 현장선택시 주소가 자동으로 들어갑니다)
                  </span>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.bigo}
                textAlign="left"
                onChange={(value) => setData({ bigo: value })}
              />
            </LabelBox>
            <LabelBox title="특이사항(공사명, 외부전달사항)">
              <TextBox
                value={model?.remark}
                textAlign="left"
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="내부전달사항(비고)"
              weight={4}
            >
              <TextBox
                value={model?.reporemark}
                textAlign="left"
                onChange={(value) => setData({ reporemark: value })}
              />
            </LabelBox>
            <LabelBox
              title="프로젝트"
              style={{ color: 'var(--color-red)' }}
              weight={2}
              footer={
              <FlexLayout>
                <Button
                  onClick={() => scope.onButtonClicked(OrderButtonClickedTypes.PROJNO)}
                  isIcon={true}
                >
                  <AiOutlineUpload size={17} />
                </Button>
              </FlexLayout>
            }>
              <TextBox
                textAlign="center"
                value={model.projno}
                onChange={(value) => setData({ projno: value, isChanged: 'true' })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      projno: item.projno,
                      projnm: item.projnm,
                    }, () => scope.itemChanged(OrderItemChangeTypes.PROJNO, item.projno));
                  },
                )}
              />
              <TextBox
                weight={3}
                value={model.projnm}
                onChange={(value) => setData({ projnm: value, isChanged: 'true' })}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={2}
              title="참조"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openReferSelector()}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                value={model?.refer}
                onChange={(value) => setData({ refer: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부사진"
              footer={
                <FlexLayout>
                  <Button
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.photoModal(true)}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: model?.attcnt2 > 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={model?.attcnt2 > 0 ? '있음' : '없음'}
                onChange={(value) => setData({ attcnt2: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="첨부파일"
              footer={
                <FlexLayout>
                  <Button
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.fileModal(true)}
                    isIcon={true}
                  >
                    <RiFolder2Line size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                style={{ color: model?.attcnt > 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                value={model?.attcnt > 0 ? `첨부파일 ${model?.attcnt}건 있음` : '첨부파일 없음'}
                onChange={(value) => setData({ attcnt: value })}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="작성자명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.inperid}
                textAlign="center"
                readonly={true}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.inpernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성일자">
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={model?.indate}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

        <LayoutTitle size={230}>발주품목</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 발주를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'balseq',
              text: '순번',
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.balseq}
                  onChange={(value) => rowUpdate({ balseq: value })}
                  readonly={true}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'pcode',
              text: '품목코드',
              width: 150,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pcode}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ pcode: value })}
                  onDoubleClick={async () => {
                    // @ts-ignore
                    const result = await scope.props.modalStore.openProductSelector(x.pcode);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_uamt || result.phm_mamt,
                    });
                    scope.detailItemChanged(OrderItemChangeTypes.PCODE, result.phm_pcod);
                  }}
                  onEnter={async (value) => {
                    // @ts-ignore
                    const result = await scope.props.modalStore.openProductSelector(value);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_uamt || result.phm_mamt,
                    });
                    scope.detailItemChanged(OrderItemChangeTypes.PCODE, result.phm_pcod);
                  }}
                />
              ),
            },
            {
              id: 'pname',
              text: '품목명',
              width: 250,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="left"
                  value={x.pname}
                  onChange={(value) => rowUpdate({ pname: value })}
                />
              ),
            },
            {
              id: 'psize',
              text: '규격',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="left"
                  value={x.psize}
                  onChange={(value) => rowUpdate({ psize: value })}
                />
              ),
            },
            {
              id: 'punit',
              text: '단위',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="center"
                  value={x.punit}
                  onChange={(value) => rowUpdate({ punit: value })}
                />
              ),
            },
            {
              id: 'deldate',
              text: '입고요청일',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  value={(!x.deldate) ? '' : x.deldate }
                  textAlign="center"
                  onChange={(value) => rowUpdate({ deldate: value })}
                />
              ),
            },
            {
              id: 're_ibgdate',
              text: '입고예정일',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  value={(!x.re_ibgdate) ? '' : x.re_ibgdate }
                  textAlign="center"
                  onChange={(value) => rowUpdate({ re_ibgdate: value })}
                />
              ),
            },
            {
              id: 'ibgflag',
              text: '예비자재',
              width: 50,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.ibgflag === '1'}
                  onChange={(value) => rowUpdate({ ibgflag: value ? '1' : '0' })}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'jqty',
              text: '현재고',
              width: 75,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.jqty}
                  textAlign="center"
                  onChange={
                    (value) => rowUpdate({ jqty: value })}
                />
              ),
            },
            {
              id: 'qty',
              text: '수량',
              width: 75,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.qty}
                  textAlign="center"
                  onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'uamt',
              text: '단가',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.uamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                />
              ),
            },
            {
              id: 'samt',
              text: '공급금액',
              width: 100,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumSamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                />
              ),
            },
            {
              id: 'tamt',
              text: '부가세',
              width: 100,
              trail: (data) => {
                const sumTamt = Sum.all(data, 'tamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumTamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.tamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, tamt: value }, rowUpdate, 'tamt')}
                />
              ),
            },
            {
              id: 'mamt',
              text: '합계',
              width: 100,
              trail: (data) => {
                const sumMamt = Sum.all(data, 'mamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMamt)}
                  </FlexLayout>
                );
              },
              render: (x) => (
                <FlexLayout
                  justify="end"
                  align="center"
                >
                  {Format.number(x.mamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 75,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ actcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E601_1',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        actcd: item.cd,
                        actnm: item.cdnm,
                      });
                      scope.setState({
                        actcd: item.cd,
                        actnm: item.cdnm,
                      });
                      scope.itemChanged(OrderItemChangeTypes.ACTCD, item.cd);
                    },
                  )}
                />
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actnm}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ actnm: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.orderDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.selectGubunModal}
        onClose={() => scope.selectGubunModal(false)}
        height={120}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
          justify='center'
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <LayoutTitle weight={1}>
              출력구분
            </LayoutTitle>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <LabelBox>
              <OptionBox
                weight={4}
                value={scope.state?.gubun}
                data={[
                  { value: '1', remark: '내부용-결재' },
                  { value: '0', remark: '외부용' },
                ]}
                onChange={(item) => scope.setState({ gubun: item.value })}
              />
              <Button
                theme={BUTTON_THEMES.BLUE}
                onClick={() => {
                  scope.selectGubunModal(false);
                  scope.onPrint();
                }}
              >
                확인
              </Button>
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.fileDetailModal}
        onClose={() => {
          scope.onButtonClicked(OrderButtonClickedTypes.PIC).then(() => scope.fileModal(false));
        }}
        width={1300}
      >
        <FlexLayout style={{ padding: 8 }}>
          <AttachmentBox
            isEnabledPreview={true}
            appnum={scope.state.data?.appnum}
            appnm={'발주서'}
          />
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.loadBillModal}
        onClose={() => scope.loadBillModal(false)}
        width={1000}
        height={600}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>자재청구서 불러오기</LayoutTitle>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout weight={4}>
              <span style={{
                margin: 8,
                fontSize: 12,
              }}>
                검색어
              </span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.setState({ popupSearchQuery: value })}
                onEnter={() => scope.modalRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <Button
              onClick={() => scope.modalDelete()}>
              청구내역서 삭제
            </Button>
          </SearchLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'chk',
                  text: '선택',
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.chk === '1'}
                        onChange={
                          (value) => {
                            rowUpdate({ chk: value ? '1' : '0' });
                          }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pumdate',
                  text: '청구일자',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.pumdate}
                    />
                  ),
                },
                {
                  id: 'pumnum',
                  text: '번호',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pumnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '청구자',
                  width: 75,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 225,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '동호기',
                  width: 75,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '수량',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      weight={2}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.uamt}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      weight={2}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.samt}
                    />
                  ),
                },
                {
                  id: 'title',
                  text: '제목',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.title}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupList}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout>
              <FlexLayout weight={2}>
                <LayoutTitle weight={1}>일자</LayoutTitle>
                <LabelBox weight={2.5}>
                  <DateTextBox
                    value={scope.state?.baldate}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => scope.setState({ baldate: value })}
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout weight={2.5}>
                <LayoutTitle
                  weight={1}
                  style={{ color: 'var(--color-red)' }}
                >
                  발주처
                </LayoutTitle>
                <LabelBox weight={4}>
                  <TextBox
                    value={scope.state?.cltcd}
                    textAlign="center"
                    onChange={(value) => scope.setState({ cltcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_XCLIENT',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          cltcd: item.cd,
                          cltnm: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state?.cltnm}
                    weight={3}
                    textAlign="left"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalSave()}>확인</Button>
              <Button onClick={() => scope.loadBillModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
