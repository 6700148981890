import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ContractStatistics } from './ContractStatistics';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  ModalView,
  SearchLayout,
  CheckBox,
  TableLayout,
  LayoutTitle, Button, SubHeader,
} from '../../../../components';
import { ContractStatisticsGridHeader } from './ContractStatisticsGridHeader';
import { Date8 } from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_e101w_08
 * @category 계약마감통계
 */

const states = [
  { value: '0', remark: '보수' },
  { value: '1', remark: '설치' },
  { value: '2', remark: '설치' },
  { value: '3', remark: '설치' },
];

export const ContractStatisticsTemplate: React.FC<TemplateProps<ContractStatistics>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>년도</span>
        <DateTextBox
          value={scope.state.year}
          textAlign="center"
          format="####"
          onChange={(value) => scope.setState({ year: value })}
          onEnter={(value) => scope.setState({ year: value }, () => scope.onRetrieveEvent())}
          head={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() - 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>
          )}
          trail={(
            <button
              onClick={() => {
                const date = new Date(`${scope.state.year}-01-01`);
                date.setFullYear(date.getFullYear() + 1);
                scope.setState({ year: date.getFullYear().toString() },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>
          )}
          />
      </FlexLayout>
      <FlexLayout size={80}></FlexLayout>
      <FlexLayout size={600}>
        <span style={{ color: '#8C2994' }}>* 설치시작현장일경우 유상이 된 시점에 신규에 적용됩니다(접수일자기준)</span>
      </FlexLayout>
    </SearchLayout>
    <FlexLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'spjangnm',
              text: '사업장명',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align='center'
                >
                  {x.spjangnm}
                </FlexLayout>
              ),
            },
            {
              id: 'chk',
              text: (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    value={scope.state.allChk}
                    onChange={(value) => scope.onCheckAll(value)}
                  />
                </FlexLayout>
              ),
              width: 10,
              render: (x, rowUpdate) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  <CheckBox
                    noMargin
                    value={x.chk === '1'}
                    onChange={(value) => {
                      rowUpdate({ chk: value ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.spjangData}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
        />
      </FlexLayout>

      <FlexLayout weight={4}>
        <GridLayout
          ref={scope.grid}
          header={ContractStatisticsGridHeader(scope)}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />
      </FlexLayout>

      <ModalView
        width={1200}
        height={700}
        isVisible={scope.state.modal}
        onClose={() => scope.closeModal(false)}
        zIndex={-2}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <LayoutTitle>계약마감통계</LayoutTitle>
          <FlexLayout>
            <GridLayout
              ref={scope.modalGrid}
              header={[
                {
                  id: 'spjangnm',
                  text: '사업장명',
                  width: 5,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.spjangnm}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      합계 {scope.state.total}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'state',
                  text: '구분',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: x.state === '0' ? 'var(--color-blue)' : 'var(--color-black)' }}
                    >
                      {states.filter((y) => y.value === x.state)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contg',
                  text: '계약',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        color: x.contg === '01' ? 'var(--color-blue)'
                          : x.contg === '04' ? 'var(--color-red)' : 'var(--color-black)',
                      }}
                    >
                      {scope.state.contgsData?.filter((y) => y.com_code === x.contg)[0]?.com_cnam}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actcd',
                  text: '코드',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.actcd}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 7,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '대수',
                  width: 1.6,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {scope.state.qty_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'amt',
                  text: '금액',
                  width: 2.5,
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(String(Number(x.amt).toFixed(0)))}
                    </FlexLayout>
                  ),
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {scope.state.amt_tot}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recedate',
                  text: '접수일자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: x.contg === '01' ? 'var(--color-blue)'
                            : x.contg === '04' ? 'var(--color-red)' : 'var(--color-black)',
                        }}
                    >
                      {Date8.withDash(x.recedate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'stdate',
                  text: '시작일자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                        justify="center"
                        align="center"
                    >
                      {Date8.withDash(x.stdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'enddate',
                  text: '종료일자',
                  width: 3,
                  render: (x) => (
                    <FlexLayout
                        justify="center"
                        align="center"
                    >
                      {Date8.withDash(x.enddate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                        justify="center"
                        align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'contpernm',
                  text: '계약자',
                  width: 2,
                  render: (x) => (
                    <FlexLayout
                        justify="center"
                        align="center"
                    >
                      {x.contpernm}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.modalData}
              infinityHandler={scope.modalInfinity}
            />
          </FlexLayout>
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.onExcelEvent()}>EXCEL DOWNLOAD</Button>
            <Button onClick={() => scope.closeModal(false)}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </ModalView>
    </FlexLayout>
  </FlexLayout>
);
